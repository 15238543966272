/* eslint-disable strict */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
import Select from 'react-select';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import UpdateButton from "../layouts/updatelmstates";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import {verifyext, verifycsvdata} from "../common/verifyext";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class AccidentsGrid extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          UpdateButton:UpdateButton,
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'show-m',
        rowData:[],
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        rowSelection:'multiple',
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        vehicleCap : [],
        statesList : [],
        rowIds : [],
        freight_type : 1,
        comp_code : "",
        lpNames : [],
        loading_port_code : "",
        accidentsData: [],
        transporterId : "",
        fYear : "",
        // getRowHeight: function(params) {
        //     if(typeof params.data.reason_for_accident != 'undefined'){
        //         console.log("pars1 ", (100 * Math.round(params.data.reason_for_accident.length/ 50)))
        //         console.log("pars2 ", params.data.reason_for_accident.length)
        //         console.log("pars3 ", Math.round(params.data.reason_for_accident.length/ 50))
        //         return (
        //         //params.api.getSizesForCurrentTheme().rowHeight *
        //         (100 * Math.round(params.data.reason_for_accident.length/ 50))
        //         );
        //     }
        //     else
        //     {
        //         return 30;
        //     }
        //   },
        //components : {datepicker:getDatePicker()}
    }
    this.changeFileHandler = this.changeFileHandler.bind(this);
    this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this);
    this.checkFileData = this.checkFileData.bind(this);
  }

componentDidMount(){
    loadDateTimeScript();
    var accidentsData = this.props.accidentsData;
    console.log(accidentsData,"accidentsDataInmount");
    this.setState({
        accidentsData : accidentsData,
        transporterId : this.props.transporterId,
        fYear : this.props.fYear,
        overly:'show-n',
    });
}

componentWillReceiveProps(newprops){
    var transporterId = newprops.transporterId;
    if(transporterId.value != this.state.transporterId.value)
    {
        var accidentsData = newprops.accidentsData;
        var fYear = newprops.fYear;
        this.setState({
            accidentsData : accidentsData,
            transporterId : transporterId,
            fYear : fYear,
        });
    }
    else
    {
        var accidentsData = newprops.accidentsData;
        console.log(accidentsData,"accidentsDataInprops");
        this.setState({
            accidentsData : accidentsData,
            transporterId : newprops.transporterId,
            fYear : newprops.fYear,
        });
    }
}
onLoadPageData(){
    var transporterId = this.state.transporterId;
    var fYear = this.state.fYear;
    if(transporterId !='' && transporterId !=undefined)
    {
        this.setState({
            //loadshow:"show-m",
            //ontimeSegment : "show-n",
        });
        var reqparams = {
            "transporterId":transporterId.value,
        }
        if(transporterId !='' && transporterId !=undefined)
        {
            reqparams["year"] = fYear.value;
        }
        
        redirectURL.post("/consignments/prtTptAccidentsData",reqparams)   
        .then(async (response) => {
            var accidentsData = (response.data);
            console.log("accidentsData",accidentsData);
            if(accidentsData !=undefined)
            {
                this.setState({
                    loadshow:"show-n",
                    accidentsData:accidentsData
                });
            }
            else
            {
                this.setState({
                    loadshow:"show-n",
                });
            }
        })
        .catch(function(e){
            console.log("Error ",e)
            this.setState({
                loadshow:"show-n",
            });
        })
    }
    else
    {
        this.setState({
            basicTitle:"Please select transporter.",
            basicType : "warning",
            show : true
        })
    }
}
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    this.setState({
        show: false
    });
}

onCellClicked(e)
{
    if(e.colDef.field == "delete")
    {
        var rowId = e.data._id;
        console.log(e.data._id);
        var qry = {
            rowId:rowId,
            user_name: localStorage.getItem('username'),
            email: localStorage.getItem('email'),
        }
        //var confirm = confirm("Are You Sure! Do you want really delete?");
        if (window.confirm("Are You Sure! Do you want really delete?"))
        {
            redirectURL.post("/consignments/deletePrtTptAccidentsData",qry).then((response)=>{
                console.log(response.data);
                if(response.data.message == "Success")
                {
                    this.setState({
                        basicTitle:"Deleted Successfully.",
                        basicType : "success",
                        show : true
                    })
                    this.onLoadPageData();
                }
                else
                {
                    this.setState({
                        basicTitle:"Failed to Delete.",
                        basicType : "warning",
                        show : true
                    })
                }
            }).catch((e)=>{
                console.log(e);
            })
        }        
    }
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    
}
updateLmStates(params)
{
    //console.log(params);
    if(params.source !='' && params.source !=undefined && params.destination !='' && params.destination !=undefined && params.truck_no !='' && params.truck_no !=undefined && params.date_of_accident !='' && params.date_of_accident !=undefined)
    {
        var transporter_code = this.props.transporterId;
        params["transporter_code"] = transporter_code;
        var fYear = this.props.fYear;
        params["fYear"] = fYear;
        redirectURL.post("/consignments/savePrtLspAccidentsData",params).then((response)=>{
            console.log(response);
            if(response.data.message == "Success")
            {
                this.setState({
                    basicTitle:"Updated Successfully.",
                    basicType : "success",
                    show : true
                })
                //window.location.reload();
                this.onLoadPageData();
            }
            else
            {
                this.setState({
                    basicTitle:"Failed to update.",
                    basicType : "warning",
                    show : true
                })
            }
        })
    }
    else
    {
        this.setState({
            basicTitle:"Fields Should not be Empty.",
            basicType : "warning",
            show : true
        })
    }
}
addNewRow()
{
    // console.log(this.gridApi);
    this.gridApi.insertItemsAtIndex(0,[{source:"",destination:"",truck_no:"",date_of_dispatch:"",date_of_accident:"",date_of_accident_reporting:"",location_of_accident:"",accident_category:"",driver_name:"",driver_contact:"",reason_of_accident:""}])
    // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}
onClickDataExport(){
    var params = {
        columnKeys: ['city', 'state', 'rate_cmt', 'vehicle_cap']
    };
    this.gridApi.exportDataAsCsv(params);
}
showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate"
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}
changeFileHandler = async (e) => {
    var dt = '';
    const config = {
        headers: [
            // { 
            //     name: 'id',
            //     inputName: 'id',
            //     required: false,
            // },
            { 
                name: 'city',
                inputName: 'city',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'state',
                inputName: 'state',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'rate_cmt',
                inputName: 'rate_cmt',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'vehicle_capacity',
                inputName: 'vehicle_capacity',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
        ]
    }
    var data = CSVFileValidator(e.target.files[0], config)
    .then((csvData) => {
        console.log(csvData)
        this.setState({
            csvData:csvData.data
        });
        console.log(csvData.data);
    })
    .catch(err => {})
    // console.log("e.target.files[0]",e.target.result);
    
    var out = new Promise(function(reject, resolve){
        var reader =  new FileReader();
        reader.onload = async function(e) {
            var contents = await e.target.result;
            resolve(contents);
        };
        var tt =  reader.readAsText(e.target.files[0]);
    });
    this.setState({
        file:e.target.files[0]
    });
    //this.checkFileData();
    //console.log("e.target.files[0].type ", e.target.files[0]);
    var check = verifyext(e.target.files[0].name);
    //console.log("Check ", check)
    if(check == true)
    {
        if(e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
        {
            // console.log(fileData);
            // console.log(typeof(fileData))
            
        }
        else{
            e.target.value = null;
            this.setState({
                uploadFile:'',
                show: true, 
                basicType:'danger', 
                basicTitle:'Please upload file having extensions .csv only.',
            });
        }
    }
    else{
        e.target.value = null;
        this.setState({
            uploadFile:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'Please upload file having extensions .csv only.',
        });
    }
}

checkFileData()
{
   
    var vdata = verifycsvdata(JSON.stringify(this.state.csvData));
    //console.log("verifycsvdata ", vdata)
    if(vdata == 0)
    {
        this.setState({
            csvData:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'File contains invalid data',
            uploadFile:'',
            file:""
        });
    }
}
uploadBulkFormHandler(event){
    event.preventDefault();
    var fileData = this.state.csvData;
    // Defining function to get unique values from an array
    
    // var uniqueData = fileData.filter(function(el, i, x) {
    //     return x.some(function(obj, j) {
    //         return obj.city === el.city && (x = j);
    //     }) && i == x;
    // });
    console.log(fileData,"fileData")
    var flag=0;
   // console.log("JSON.stringify(this.state.csvData) ", JSON.stringify(this.state.csvData))
    var vdata = verifycsvdata(JSON.stringify(this.state.csvData));
    //console.log("verifycsvdata ", vdata)
    if(vdata == true)
    {
        this.setState({
            csvData:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'File contains invalid data',
            uploadFile:'',
            file:""
        });
    }
    else{
        if(flag == 0)
        {
            if(fileData.length > 0)
            {
                this.setState({
                    overly:"show-m",
                    loadshow:"show-m",
                })
                var loading_port_code = this.state.loading_port_code;
                var reqParams = {
                    csvData : fileData,
                    loading_port_code : loading_port_code
                }
                reqParams["loading_port_code"] = loading_port_code;
                reqParams['user_name'] = localStorage.getItem('username');
                reqParams['email'] = localStorage.getItem('email');
                reqParams['staff_id'] = localStorage.getItem('staff_id');
                reqParams['user_department'] = localStorage.getItem('lm_department');
                reqParams['designation'] = localStorage.getItem('designation');
                reqParams['comp_code'] = this.state.comp_code;
                reqParams['user_role'] = localStorage.getItem('roles');
                reqParams['freight_type'] = this.state.freight_type;
                console.log("reqParams",reqParams)
                redirectURL.post("/lmconsignees/savebulklmfreights",reqParams).then((response)=>{
                    var resp=response.data.message;
                    console.log(resp)
                    this.setState({
                        basicTitle : resp,
                        basicType : "success",
                        show : true,
                        bulkslide:'',
                        loadshow:"show-n",
                        overly:'show-n',
                    })
                    //window.location.reload();
                    this.onLoadPageData();
                }).catch((e)=>{
                    console.log(e);
                })
            }
            else
            {
                this.setState({
                    basicTitle : "Invalid File",
                    basicType : "warning",
                    show : true,
                })
            }
            $('#upform')[0].reset();
        }
        else
        {
            $("#bulkUploadID").val();
            this.setState({
                basicTitle : "Fields Should not be Empty",
                basicType : "warning",
                show : true,
            })
            $('#upform')[0].reset();
        }
    }
};


getRowHeight(params)
{
    if(typeof params.data.reason_for_accident != 'undefined'){
        console.log("pars1 ", (100 * Math.round(params.data.reason_for_accident.length/ 100)))
        console.log("pars2 ", params.data.reason_for_accident.length)
        console.log("pars3 ", Math.round(params.data.reason_for_accident.length/ 100))
        var rhght = (100 * Math.round(params.data.reason_for_accident.length/ 100));
        if(rhght > 0)
        {
            rhght = parseInt(rhght);
        }
        else
        {
            rhght = 30;
        }
        return (
            rhght
            //"auto"
        );
    }
    else
    {
        return 30;
    }
}

  render() {
    var columnwithDefs = [
        {
            headerName: "Source",
            field: "source",
            width:150,
            filter: true,
            resizable: true,
            editable:true,            
        },
        {
            headerName: "Destination",
            field: "destination",
            width:150,
            filter: true,
            resizable: true,
            editable:true,
        },
        {
            headerName: "Truck Number",
            field: "truck_no",
            width:150,
            filter: true,
            resizable: true,
            editable:true,
        },
        {
            headerName: "Date of Dispatch",
            field: "date_of_dispatch",
            width:150,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: DateEditor,
            valueGetter:function(params){        
                if(params.data.date_of_dispatch != '' && params.data.date_of_dispatch != undefined)
                {             
                    return moment.parseZone(params.data.date_of_dispatch).format('YYYY-MM-DD HH:mm');
                }
                else
                {
                    return "";
                }
            },
        },
        {
            headerName: "Date of Accident",
            field: "date_of_accident",
            width:150,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: DateEditor,
            valueGetter:function(params){
                if(params.data.date_of_accident != '' && params.data.date_of_accident != undefined)
                {             
                    return moment.parseZone(params.data.date_of_accident).format('YYYY-MM-DD HH:mm');
                }
                else
                {
                    return "";
                }
            },
        },
        {
            headerName: "Date of Accident Reporting",
            field: "date_of_accident_reporting",
            width:180,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: DateEditor,
            valueGetter:function(params){ 
                if(params.data.date_of_accident_reporting != '' && params.data.date_of_accident_reporting != undefined)
                {             
                    return moment.parseZone(params.data.date_of_accident_reporting).format('YYYY-MM-DD HH:mm');
                }
                else
                {
                    return "";
                }
            },
        },
        {
            headerName: "Location Of Accident",
            field: "location_of_accident",
            width:180,
            filter: true,
            resizable: true,
            editable:true,
        },
        {
            headerName: "Accident Category",
            field: "accident_category",
            width:150,
            filter: true,
            resizable: true,
            editable:true,
        },
        {
            headerName: "Driver Name",
            field: "driver_name",
            width:150,
            filter: true,
            resizable: true,
            editable:true,
        },
        {
            headerName: "Driver Contact",
            field: "driver_contact",
            width:150,
            filter: true,
            resizable: true,
            editable:true,
        },
        {
            headerName: "Reason for Accident",
            field: "reason_for_accident",
            width:180,
            filter: true,
            resizable: true,
            editable:true,
            autoHeight: true,
            cellStyle: {whiteSpace: 'normal'}
        },
        {
            pinned:"left",
            headerName : "Update",
            field:"_id",
            width:150,
            resizable : true,
            editable : false,
            cellRendererSelector:function(params){       
                var rendComponent = {
                    component: 'UpdateButton'
                };
                return rendComponent;
            },
        },
        {
            pinned:"left",
            headerName : "Delete",
            field:"delete",
            width:80,
            resizable : true,
            editable : false,
            cellRenderer:function(params){       
                return '<i class="icofont icofont-ui-delete"></i>';
            },
        },
    ]
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>
            <div className="row col-xl-12" style={{margin:"0"}}>
                <h5 style={{width:"100%"}}>
                    <button className="btn btn-success" style={{float:"right"}} onClick={this.addNewRow.bind(this)}>Add Accident</button>
                </h5>
            </div>
            <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.showGridData}>    
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.accidentsData}
                    //getRowHeight={this.getRowHeight}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClicked}
                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                    />
        </div>

        
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">Bulk Upload</h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>
                    
                    <div className="form-group mt-20p">
                        <label className="">Upload File</label> 
                        <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control" required  />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-success">Submit</button>
                        <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                    </div>
                    </form>
                    <div className="form-group">
                        {/* <a className="btn btn-primary" href={require('../../assets/json/sample_lmfreights.csv')} target="_blank">Sample Template</a> */}
                    </div>		
                </div>
            </div>
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd h:i",
        changeMonth: true,
        changeYear: true,
        timepicker:true,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";


import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';

import Modal from 'react-responsive-modal';
import Counters from './railcountersComponent';
import DeliverCounters from './raildelivercountersComponent';
import DateFormater from './dateComponent';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD,getHyphenDDMMMYYYYHHMM, getYYYYMMDDHHMMSS, getDDMMMYYYYHHMMDefault, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import CSVFileValidator from 'csv-file-validator';
import CreatableSelect from 'react-select/creatable';
import Edittruckavailable from "./edittruckavailable";
import Deletetruckavailable from "./deletetruckavailable";
import Remarksaction from "./remarkstruckavailableaction";
import Repairaction from "./repairstruckavailableaction";
import TruckGrid from "./truckavailabilitygrid";
import CountUp from 'react-countup';
import { object } from 'prop-types';
import { ImageFilter9 } from 'material-ui/svg-icons';
import download from 'js-file-download';
//import * as XLSX from 'xlsx';

$.datetimepicker.setLocale('en');
 
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
//var rowdefs = []
export default class Exportsffbooking extends Component {
    
	constructor(props){
		super(props);
		this.displayData=[];
		this.state={
			pageTitle: "",
			deptcode:"",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
			eventAction: null,
			allRowData: [],
			activeFilter: null,
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			sliderBulkForceClose:"",
			tabsliderTranslate:'',
			timelineinfo:'',
			consignment_code:'',
			mapinfo:'',
			dealer:'',
			maptruckno:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
			basicTitle:'',
			rownode:'',
			leg_no:'',
			basicType:"default",

			alert1:null,
            show1: false,
			basicTitle1:'',
			basicType1:"default",

			modules: AllModules,
			filterstatess:{},
      	    columnsdatas:{},
      	    rowgroupdcols:[],
			valuecolumns:[],
			activeconsignemnt:[],
			activetrucks:[],
			transitdelays:[],
			gpsdatana:[],
			overspeedtrucks:[],
			nightdrive:[],
			open: false,
			openforceclose: false,
      	    pivotmodeis:false,
			pivotcols:[],
			defTransitCoords:'',
			routeTruck:{}, 
			sidebarSubHeader:'',
			googelRoutes:'',
			plants:[],
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true,
      	    },
      	    rowData: null,
			  maprowData:null,
			rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
                edittruckavailable:Edittruckavailable,
                deletetruckavailable:Deletetruckavailable
            },
            emptyframeworkcomponent:{
                Remarksaction:Remarksaction,
                Repairaction:Repairaction
            },

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
            rowModelType: "serverSide",
            headerHeight:60,
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				//"highlitRow": "data.gate_in_time != ''",
				//"green":"data.status >= 3"

			},
			screenpage:'',
			pagetitle:'',
			open:false,
            truckslist:[],
            truckcategories:[],
            truckcategory:{"value":"","label":""},
            truck:"",
            sliderTitle:"",
            container_requirement:[],
            slidertranslate:'',
            trucktype: 40,
            warehouse:"",
            tentitivetime:"",
			showTrucksList : "show-n",
            filteredData : [],
            startDate:"",
            endDate:"",
            defaultsDate:"",
            defaulteDate:"",
            rowid:"",
            trucktypeother:"show-n",
            othertrucktype:"",
            bulkslide:"",
            bulkslide1:"",
            bulkslide2:"",
            bulkslide3:"",
            bulkslide4:"",
            bulkslide5:"",
            bulkslide6:"",
            bulkslide7:"",
            bulkslide8:"",
            bulkslide9:"",
            bulkslide10:"",
            bulkslide11:"",
            file1:"",
            file2:"",
            file3:"",
            file4:"",
            bulkslideforcontainer:"",
            csvcontent:'',
            categories:[],
            bookingId2:'',
            bookingId1:'',
            
            trucktypes:[],
            transporters:[],
            columns:[],
            rowdefs:[],
            rowloadedtruckdefs:[],
            pinnedRegionBottomRowData:[],
            marktabactive:"btn-success",
            emptytabactive:"",
            emptytabstatusactive:"",
            markedshow:"show-m",
            emptyshow:"show-n",
            emptyrowData:[],
            urgentrowData:[],
            emptyplant:"",
            onetimeid:"",
            weeklyid:"",

            emptyrowStatusData:[],
            usermanualmodal:false,
            overly:"show-m",
            loadshow:"show-m",
            loadtruckactive:"",
            loadtruckshow:"show-n",
            rowDataLoadedTrucks:[],
            startDateLoad:"",
            endDateLoad:"",
            auto_marked_by_system:[],
            loaded_auto_marked_by_system:[],
            waiting_for_unloading:0,
            unloadtruckactive:"",
            unloadtruckshow:"show-n",
            transportercode:[],
            unloadtrucksdata:[],
            lsp_name :"",
            lsp_email:"",
            pending:"activet",
            intransit:"",
            closure:"",
            container_type:"",
			marktabactive:"",
            emptytabactive:"",
            loadtruckactive:"",
            unloadtruckactive:"",
            emptytabstatusactive:"btn-success",
            markedshow:"show-n",
            emptyshow:"show-n",
            onetimeshow:"show-m",
            urgentshow:"show-n",
            loadtruckshow:"show-n",
            unloadtruckshow:"show-n",
            request_type:1,
            pageType:1,
            urgenttabstatus:'',

            // inpending

            sob:"",
            requirement_id:'',
            container_no:'',
            container_requirement_date:'',
            container_type:'',
            dispatch_location:'',
            container_size:'',
            truck_no:'',
            incoterms:'',
            selected_row:[],
            onetimeDownloadBulkSlide:"",
            urgentDownloadBulkSlide:"",
            weeklyDownloadBulkSlide:"",
            bookingDownloadList:[],
            downlaodRowData : {},
		}
		this.trucksoptions = this.trucksoptions.bind(this);
        this.truckcategoryoptions = this.truckcategoryoptions.bind(this);
        // this.onLoadTrucksAvailable = this.onLoadTrucksAvailable.bind(this);
        this.onClickEditRecord = this.onClickEditRecord.bind(this);
        this.onClickDeleteRecord = this.onClickDeleteRecord.bind(this);
		this.onClickShowFilterData = this.onClickShowFilterData.bind(this);
        this.onClickShowFilterData2 = this.onClickShowFilterData2.bind(this);
        this.onClickShowFilterData3 = this.onClickShowFilterData3.bind(this);
        this.onClickShowFilterData4 = this.onClickShowFilterData4.bind(this);
		this.changeFileHandler = this.changeFileHandler.bind(this);
        this.createColumns = this.createColumns.bind(this);
        this.createRowData = this.createRowData.bind(this);
        this.consolidateTrucks = this.consolidateTrucks.bind(this);
        this.onChangeRemarkOptions = this.onChangeRemarkOptions.bind(this);
        this.onChangeRemarkRepairOptions = this.onChangeRemarkRepairOptions.bind(this);
        this.submitEmptyFormHandler = this.submitEmptyFormHandler.bind(this);
        this.onLoadEmptyTrucksAvailable = this.onLoadEmptyTrucksAvailable.bind(this);
        this.onLoadLoadedTrucksAvailable = this.onLoadLoadedTrucksAvailable.bind(this);
        this.markedForDeleteClicked = this.markedForDeleteClicked.bind(this);
        this.onLoadUnloadedTrucksAvailable = this.onLoadUnloadedTrucksAvailable.bind(this);
	}
    componentDidUpdate(prevProps) {
        if (prevProps.process !== this.props.process) {
            console.log("propsssssssssssss",this.props)
            // Run your code when the prop changes  
        if (this.props.process == 1){
            this.onClickTab("pending")
        }
        else if (this.props.process == 2){
            this.onClickTab("intransit")
        }
        else if (this.props.process == 3){
            this.onClickTab("closure")
        }
    }
        if (prevProps.requirement !== this.props.requirement) {
            console.log("requirementsssssssssssss",this.props)
            // Run your code when the prop changes  
        if (this.props.requirement == 1){
            this.onClickShowTabView("onetime")
        }
        else if (this.props.requirement == 2){
            this.onClickShowTabView("weekly")
        }
        else if (this.props.requirement == 3){
            this.onClickShowTabView("urgent")
        }
        }
    }

	logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
	componentDidMount(){
        // var pageUrl = this.props.match.path;
        // console.log(pageUrl,"pageUrl")
        // pageUrl = pageUrl.split("/");
        // console.log(pageUrl,"pageUrlarray")
        // var pageType = pageUrl[(pageUrl.length)-1];
        // console.log(pageType,"pageType")
        // this.onClickShowTabView(pageType);
        
        console.log("test1")
        this.setState({
            loadshow:"show-m",
        overly:"show-m"
        })
        this.onLoadPageData();
        loadDateTimeScript();
        //var sdate = moment.parseZone().subtract(1, 'days').format('YYYY-MM-DD');
        var sdate = moment.parseZone().format('YYYY-MM-DD');
        var edate = moment.parseZone().format('YYYY-MM-DD');
        this.setState({
            startDate:sdate,
            endDate:edate,
            startDateLoad:sdate,
            endDateLoad:edate,
            defaultsDate:sdate,
            defaulteDate:edate
        })
        
    };

    onLoadLoadedTrucksAvailable = async(parameters) => {
        this.setState({
            loadshow:"show-m"
        })
        if(localStorage.getItem("user_type") == "TRANSPORTER")
        {
            parameters.transportercode = JSON.parse(localStorage.getItem("transportercode"))   
        }
        else{
            parameters.transportercode = ""
        }
        await redirectURL.post("/consignments/loadedtruckavailabledata", parameters)
        .then(async (response) => {            
            var loaded_auto_marked_by_system = []
            var recrds = response.data.records;
                if(recrds.length > 0)
                {
                    recrds.map((item) => {
                        if(item.auto_marked_by_system == 1)
                        {
                            loaded_auto_marked_by_system.push(item);
                        }
                    })
                }
            this.setState({
                rowDataLoadedTrucks: response.data.records,
                loadshow:"show-n",
                loaded_auto_marked_by_system:loaded_auto_marked_by_system
            })
            await this.consolidateLoadedTrucks()

        })
        
    }
    onLoadEmptyTrucksAvailable = async(parameters) => {

        if(localStorage.getItem("user_type") == "TRANSPORTER")
        {
            parameters.transportercode = JSON.parse(localStorage.getItem("transportercode"))   
        }
        else{
            parameters.transportercode = ""
        }
        // var parameters  = {
        //     dept_code:this.state.dept_code,
        //     transportercode :transportercode
        // }
        redirectURL.post("/consignments/truckstatusavailabledata", parameters)
        .then((response) => {
            //console.log("response ", response.data.records)
            this.setState({
                emptyrowStatusData:response.data.records
            })
        })
        
     }
     onLoadPageData(){
        this.setState({
            overly:"show-n",
            loadshow:"show-n",
        });
        var supplier_code = localStorage.getItem("supplier_code");
        var email_id = localStorage.getItem('email')
        var params = {
            "pageType" : this.state.pageType,
            "request_type" : this.state.request_type
        }
        console.log(supplier_code,"421")
        if(supplier_code != undefined && supplier_code != "undefined" && supplier_code != "" && supplier_code != null){
            params.email_id = email_id
            params.supplier_code=supplier_code
        }
        console.log(params,"441")
        redirectURL.post("/consignments/getcontainerfreightforwaderupdates",params)    
        .then((response) => {
            var records = response.data;
            console.log("records", records)
            if(this.state.request_type == 1){
                this.setState({
                    emptyrowData : records,
                    overly:"show-n",
                    loadshow:"show-n",
                })
            }
            else if(this.state.request_type == 2){
                this.setState({
                    rowData : records,
                    overly:"show-n",
                    loadshow:"show-n",
                })
            }
            else if(this.state.request_type == 3){
                console.log('helllo')
                this.setState({
                    urgentrowData : records,
                    overly:"show-n",
                    loadshow:"show-n",
                })
            }
        })
    }
    containertypechangeHandler =(e)=>{
        this.setState({
            container_type:e,
        })
    }

    handleSelectChange = (selectedOption) => {
        this.setState({
            sob: selectedOption
        });
    }
    
    handleInputChange = (e) => {
        console.log(e,'e value')
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
    }
     onCellRowClickedonetime = e => {
        if (e.colDef.field == "Download"){
            var onetimeDownloadList = []
            if(e.data.container_details_file != undefined &&  e.data.container_details_file != null && e.data.container_details_file != ""){
                if(!e.colDef.statusValues.showforInprogress){
                    onetimeDownloadList.push({label:"Booking PDF", value:"container_details_file"})
                }
            }
            if(e.data.custom_clearance_file != undefined &&  e.data.custom_clearance_file != null && e.data.custom_clearance_file != ""){
                if(!e.colDef.statusValues.showforInprogress){
                    onetimeDownloadList.push({label:"Shipping Bill LEO copy", value:"custom_clearance_file"})
            }}
            onetimeDownloadList.push({label:"DGD Uploaded File", value:"dgd_file_name"});
            onetimeDownloadList.push({label:"MSDS Uploaded File", value:"msds_file_name"});
            onetimeDownloadList.push({label:"IIP Uploaded File", value:"iip_file_name"});
            if(e.data.grw_file_name != undefined &&  e.data.grw_file_name != null && e.data.grw_file_name != ""){
                onetimeDownloadList.push({label:"GR Waiver File", value:"grw_file_name"});
            }
            if(e.data.bill_of_lading_file != undefined &&  e.data.bill_of_lading_file != null && e.data.bill_of_lading_file != ""){
                if (!e.colDef.statusValues.showforClosed){
                    onetimeDownloadList.push({label:"Bill of Lading", value:"bill_of_lading_file"});
                }
            }

            var onetimeid = e.data._id
            this.setState({
                onetimeid : onetimeid,
                overly :"show-m",
                onetimeDownloadBulkSlide : "slider-translate-30p",
                bookingDownloadList:onetimeDownloadList,
                downlaodRowData : e.data
            })

        }
        if(e.colDef.field == "edit_button_pending_ontime")
        {
            {
                var onetimeid = e.data._id
                console.log(onetimeid,"onetimeid")
                this.setState({
                    onetimeid : onetimeid,
                    overly :"show-m",
                    bulkslide6 : "slider-translate-30p",
                })
            }
            // console.log(e.coldef,'e.coldef',e.data)
 
        }
        if(e.colDef.field == "edit_button_inprocess_ontime")
        {
            var onetimeid = e.data._id
            console.log(onetimeid,"onetimeid")
            this.setState({
                onetimeid : onetimeid,
                overly :"show-m",
                bulkslide7 : "slider-translate-30p",
            })
        }
        if(e.colDef.field == "onetime_update_files")
        {
            var onetimeid = e.data._id
            console.log(onetimeid,"onetimeid")
            this.setState({
                onetimeid : onetimeid,
                overly :"show-m",
                bulkslide1 : "slider-translate-30p",
            })
        }
        if(e.colDef.field == "container_details_file"){
            var fileName  = e.data.container_details_file
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimeffupdatesfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "dgd_file_name"){
            var fileName  = e.data.dgd_file_name
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimedgdfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "msds_file_name"){
            var fileName  = e.data.msds_file_name
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimemsdsfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "iip_file_name"){
            var fileName  = e.data.iip_file_name
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimeiipfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "grw_file_name"){
            var fileName  = e.data.grw_file_name
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimegrwfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "update_shipping_on_board"){
            var onetimeid = e.data._id
            this.setState({
                onetimeid : onetimeid,
                overly :"show-m",
                bulkslide1 : "slider-translate-30p",
            })
        }
        if(e.colDef.field == "upload_bill_of_lading"){
            var onetimeid = e.data._id
            // console.log(onetimeid,"onetimeid")
            this.setState({
                onetimeid : onetimeid,
                overly :"show-m",
                bulkslide3 : "slider-translate-30p",
            })
        }
        if(e.colDef.field == "ringi_data"){
            var onetimeid = e.data._id
            // console.log(onetimeid,"onetimeid")
            this.setState({
                onetimeid : onetimeid,
                overly :"show-m",
                loadshow : "show-m",
                bulkslide5 : "slider-translate-30p",
            })
            var params ={
                onetimeid : onetimeid,
                request_type : 1,
            }
            redirectURL.post("/consignments/getRingiData",params).then((response)=>{
                if(response.data.message == "Success"){
                    if(resp != "" && resp != undefined && resp != null){
                        var resp = JSON.parse(response.data.data)
                        this.setState({
                            unloadtrucksdata : resp,
                            loadshow : "show-n",
                        })
                    }else{
                        this.setState({
                            unloadtrucksdata : [],
                            loadshow : "show-n",
                        })
                    }
                }
            })
        }
        if(e.colDef.field == "bill_of_lading_file"){
            var fileName  = e.data.bill_of_lading_file
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimebolfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "custom_clearance_file"){
            var fileName  = e.data.custom_clearance_file
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimeCCfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
    }
    onCellRowClickedweekly = e => {
        if (e.colDef.field == "Download"){
            var weeklyDownloadList = []

            if(e.data.container_details_file != undefined &&  e.data.container_details_file != null && e.data.container_details_file != ""){
                if(!e.colDef.statusValues.showforInprogress){
                    weeklyDownloadList.push({label:"Booking PDF", value:"container_details_file"})
                }
            }
            if(e.data.custom_clearance_file != undefined &&  e.data.custom_clearance_file != null && e.data.custom_clearance_file != ""){
                if(!e.colDef.statusValues.showforInprogress){
                    weeklyDownloadList.push({label:"Shipping Bill LEO copy", value:"custom_clearance_file"})
            }
            }
            if(e.data.bill_of_lading_file != undefined &&  e.data.bill_of_lading_file != null && e.data.bill_of_lading_file != ""){
                if (!e.colDef.statusValues.showforClosed){
                    weeklyDownloadList.push({label:"Bill of Lading", value:"bill_of_lading_file"});
                }
            }

            var onetimeid = e.data._id
            this.setState({
                onetimeid : onetimeid,
                overly :"show-m",
                weeklyDownloadBulkSlide : "slider-translate-30p",
                bookingDownloadList:weeklyDownloadList,
                downlaodRowData : e.data
            })

        }
        if(e.colDef.field == "edit_button_pending_weekly")
        {

            var weeklyid = e.data._id
            // console.log(weeklyid,"weeklyid")
            // console.log(e.params)
            this.setState({
                weeklyid : weeklyid,
                overly :"show-m",
                bulkslide8 : "slider-translate-30p",
            })
            // console.log('hellllo')
            // var onetimeid = e.data._id
            // var shipping_on_board=e.data.shipping_on_board
            // var requirement_id = e.data.requirement_id
            // var container_no = e.data.container_no
            // var container_requirement_date = e.data.container_requirement_date
            // var container_type = e.data.container_type
            // var dispatch_location = e.data.dispatch_location
            // var container_size = e.data.container_size
            // var truck_no = e.data.truck_no
            // var date = e.data.date
            // var dispatch_port = e.data.dispatch_port
            // var destination_port = e.data.destination_port
            // var bkg = e.data.bkg
            // var vessel =e.data.vessel
            // var incoterms = e.data.incoterms

            // console.log(onetimeid,e.data,"onetimeid")
            // this.setState({
            //     onetimeid : onetimeid,

            //     overly :"show-m",
            //     bulkslide8 : "slider-translate-30p",
            //     sob:shipping_on_board,
            //     requirement_id:requirement_id,
            //     container_no:container_no,
            //     container_requirement_date:container_requirement_date,
            //     container_type:container_type,
            //     dispatch_location:dispatch_location,
            //     container_size:container_size,
            //     truck_no:truck_no,
            //     incoterms:incoterms

            // })
        }
        if(e.colDef.field == "edit_button_inprocess_weekly")
        {
            var weeklyid = e.data._id
            console.log(weeklyid,"weeklyid")
            this.setState({
                weeklyid : weeklyid,
                overly :"show-m",
                bulkslide9 : "slider-translate-30p",
            })
            // console.log('hellllo')
            // var onetimeid = e.data._id
            // var shipping_on_board=e.data.shipping_on_board
            // var requirement_id = e.data.requirement_id
            // var container_no = e.data.container_no
            // var container_requirement_date = e.data.container_requirement_date
            // var container_type = e.data.container_type
            // var dispatch_location = e.data.dispatch_location
            // var container_size = e.data.container_size
            // var truck_no = e.data.truck_no
            // var date = e.data.date
            // var dispatch_port = e.data.dispatch_port
            // var destination_port = e.data.destination_port
            // var bkg = e.data.bkg
            // var vessel =e.data.vessel
            // var incoterms = e.data.incoterms

            // console.log(onetimeid,e.data,"onetimeid")
            // this.setState({
            //     onetimeid : onetimeid,

            //     overly :"show-m",
            //     bulkslide9 : "slider-translate-30p",
            //     sob:shipping_on_board,
            //     requirement_id:requirement_id,
            //     container_no:container_no,
            //     container_requirement_date:container_requirement_date,
            //     container_type:container_type,
            //     dispatch_location:dispatch_location,
            //     container_size:container_size,
            //     truck_no:truck_no,
            //     incoterms:incoterms

            // })
        }
        if(e.colDef.field == "weekly_update_files")
        {
            var weeklyid = e.data._id
            // console.log(weeklyid,"weeklyid")
            // console.log(e.params)
            this.setState({
                weeklyid : weeklyid,
                overly :"show-m",
                bulkslide2 : "slider-translate-30p",
            })
        }
        if(e.colDef.field == "container_details_file"){
            var fileName  = e.data.container_details_file
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadweeklyffupdatesfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "update_shipping_on_board"){
            var weeklyid = e.data._id
            console.log(weeklyid,"weeklyid")
            this.setState({
                weeklyid : weeklyid,
                overly :"show-m",
                bulkslide2 : "slider-translate-30p",
            })
        }
        if(e.colDef.field == "upload_bill_of_lading"){
            var weeklyid = e.data._id
            // console.log(weeklyid,"weeklyid")
            // console.log(e.params)
            this.setState({
                weeklyid : weeklyid,
                overly :"show-m",
                bulkslide4 : "slider-translate-30p",
            }) 
        }
        if(e.colDef.field == "bill_of_lading_file"){
            var fileName  = e.data.bill_of_lading_file
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimebolfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "custom_clearance_file"){
            var fileName  = e.data.custom_clearance_file
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimeCCfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
       
    }

    onCellRowClickedUrgent = e => {
        if (e.colDef.field == "Download"){
            console.log("entered download section......")
            var urgentDownloadList = []
            if(e.data.container_details_file != undefined &&  e.data.container_details_file != null && e.data.container_details_file != ""){
                if (!e.colDef.statusValues.showforInprogress){
                    urgentDownloadList.push({label:"Booking PDF",value:"container_details_file"})
                }
            }
            if(e.data.custom_clearance_file != undefined &&  e.data.custom_clearance_file != null && e.data.custom_clearance_file != ""){
                if (!e.colDef.statusValues.showforInprogress){
                    urgentDownloadList.push({label:"Shipping Bill LEO copy", value:"custom_clearance_file"})
                }
            }
            if(e.data.bill_of_lading_file != undefined &&  e.data.bill_of_lading_file != null && e.data.bill_of_lading_file != ""){
                if (!e.colDef.statusValues.showforClosed){
                    urgentDownloadList.push({label:"Bill of Lading", value:"bill_of_lading_file"})
                }
            }
            urgentDownloadList.push({label:"DGD Uploaded File", value:"dgd_file_name"});
            urgentDownloadList.push({label:"MSDS Uploaded File", value:"msds_file_name"});
            urgentDownloadList.push({label:"IIP Uploaded File", value:"iip_file_name"});
            if(e.data.grw_file_name != undefined &&  e.data.grw_file_name != null && e.data.grw_file_name != ""){
                urgentDownloadList.push({label:"GR Waiver File", value:"grw_file_name"});
            }
        
            var onetimeid = e.data._id
            this.setState({
                onetimeid : onetimeid,
                overly :"show-m",
                urgentDownloadBulkSlide : "slider-translate-30p",
                bookingDownloadList:urgentDownloadList,
                downlaodRowData : e.data
            })

        }
        if(e.colDef.field == "edit_button_pending_urgent")
        {
            {
                var onetimeid = e.data._id
                console.log(onetimeid,"onetimeid")
                this.setState({
                    onetimeid : onetimeid,
                    overly :"show-m",
                    bulkslide10 : "slider-translate-30p",
                })
            }
            // console.log(e.coldef,'e.coldef',e.data)
            // var onetimeid = e.data._id
            // var shipping_on_board=e.data.shipping_on_board
            // var requirement_id = e.data.requirement_id
            // var container_no = e.data.container_no
            // var container_requirement_date = e.data.container_requirement_date
            // var container_type = e.data.container_type
            // var dispatch_location = e.data.dispatch_location
            // var container_size = e.data.container_size
            // var truck_no = e.data.truck_no
            // var date = e.data.date
            // var dispatch_port = e.data.dispatch_port
            // var destination_port = e.data.destination_port
            // var bkg = e.data.bkg
            // var vessel =e.data.vessel
            // var incoterms = e.data.incoterms

            // console.log(onetimeid,e.data,"onetimeid")
            // this.setState({
            //     onetimeid : onetimeid,

            //     overly :"show-m",
            //     bulkslide6 : "slider-translate-30p",
            //     sob:shipping_on_board,
            //     requirement_id:requirement_id,
            //     container_no:container_no,
            //     container_requirement_date:container_requirement_date,
            //     container_type:container_type,
            //     dispatch_location:dispatch_location,
            //     container_size:container_size,
            //     truck_no:truck_no,
            //     incoterms:incoterms

            // })
        }
       
        if(e.colDef.field == "edit_button_inprocess_urgent")
        {
            var onetimeid = e.data._id
            console.log(onetimeid,"onetimeid")
            this.setState({
                onetimeid : onetimeid,
                overly :"show-m",
                bulkslide11 : "slider-translate-30p",
            })
            // console.log(e.coldef,'e.coldef',e.data)
            // var onetimeid = e.data._id
            // var shipping_on_board=e.data.shipping_on_board
            // var requirement_id = e.data.requirement_id
            // var container_no = e.data.container_no
            // // var container_requirement_date = e.data.container_requirement_date
            // // var container_type = e.data.container_type
            // // var dispatch_location = e.data.dispatch_location
            // // var container_size = e.data.container_size
            // // var truck_no = e.data.truck_no
            // // var date = e.data.date
            // // var dispatch_port = e.data.dispatch_port
            // // var destination_port = e.data.destination_port
            // // var bkg = e.data.bkg
            // // var vessel =e.data.vessel
            // var incoterms = e.data.incoterms

            // console.log(onetimeid,e.data,"onetimeid")
            // this.setState({
            //     onetimeid : onetimeid,

            //     overly :"show-m",
            //     bulkslide7 : "slider-translate-30p",
            //     sob:shipping_on_board,
            //     requirement_id:requirement_id,
            //     container_no:container_no,
            //     // container_requirement_date:container_requirement_date,
            //     // container_type:container_type,
            //     // dispatch_location:dispatch_location,
            //     // container_size:container_size,
            //     // truck_no:truck_no,
            //     incoterms:incoterms

            // })
        }
        if(e.colDef.field == "onetime_update_files")
        {
            var onetimeid = e.data._id
            console.log(onetimeid,"onetimeid")
            this.setState({
                onetimeid : onetimeid,
                overly :"show-m",
                bulkslide1 : "slider-translate-30p",
            })
        }
        if(e.colDef.field == "container_details_file"){
            var fileName  = e.data.container_details_file
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimeffupdatesfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "dgd_file_name"){
            var fileName  = e.data.dgd_file_name
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimedgdfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "msds_file_name"){
            var fileName  = e.data.msds_file_name
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimemsdsfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "iip_file_name"){
            var fileName  = e.data.iip_file_name
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimeiipfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "grw_file_name"){
            var fileName  = e.data.grw_file_name
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimegrwfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "update_shipping_on_board"){
            var onetimeid = e.data._id
            console.log(onetimeid,"onetimeid")
            this.setState({
                onetimeid : onetimeid,
                overly :"show-m",
                bulkslide1 : "slider-translate-30p",
            })
        }
        if(e.colDef.field == "upload_bill_of_lading"){
            var onetimeid = e.data._id
            // console.log(onetimeid,"onetimeid")
            this.setState({
                onetimeid : onetimeid,
                overly :"show-m",
                bulkslide3 : "slider-translate-30p",
            })
        }
        if(e.colDef.field == "ringi_data"){
            var onetimeid = e.data._id
            // console.log(onetimeid,"onetimeid")
            this.setState({
                onetimeid : onetimeid,
                overly :"show-m",
                loadshow : "show-m",
                bulkslide5 : "slider-translate-30p",
            })
            var params ={
                onetimeid : onetimeid,
                request_type : 1,
            }
            redirectURL.post("/consignments/getRingiData",params).then((response)=>{
                if(response.data.message == "Success"){
                    if(resp != "" && resp != undefined && resp != null){
                        var resp = JSON.parse(response.data.data)
                        this.setState({
                            unloadtrucksdata : resp,
                            loadshow : "show-n",
                        })
                    }else{
                        this.setState({
                            unloadtrucksdata : [],
                            loadshow : "show-n",
                        })
                    }
                }
            })
        }
        if(e.colDef.field == "bill_of_lading_file"){
            console.log("entered download section......")

            var fileName  = e.data.bill_of_lading_file
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimebolfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "custom_clearance_file"){
            var fileName  = e.data.custom_clearance_file
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimeCCfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        // if(e.colDef.field == "container_details_file"){
        //     var fileName  = e.data.container_details_file
        //     // console.log(fileName,"496")
        //     if(fileName != ""){
        //         var reqparams ={
        //             uploadOtherFileName : fileName,
        //         }
        //         redirectURL.post("/consignments/downloadonetimeCDfile",reqparams,{
        //             responseType: 'blob'
        //         }).then((response)=>{
        //             // console.log("download response ",response)
        //             download(response.data, fileName)
                   
        //         }).catch(function(error){
        //             console.log(error);
        //         })
        //     }
        // }
    }
    onCellRowClickedRingiData = e =>{
        if(e.colDef.field == "download_ringi_file")
        {
            var fileName  = e.data.ringi_file
            console.log(e.data,"692")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadRingiDataFiles",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
    }
    onClickShowFilterData (e){
        e.preventDefault()
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        var fileData  = this.state.file1
        var onetimeid = this.state.onetimeid
        var uploaded_by = localStorage.getItem("email")
        var booking_id = this.state.bookingId1
        // console.log(booking_id,"booking_id")
            let saveData = new FormData();
            saveData.append("fileData",fileData);
            saveData.append("onetimeid",onetimeid);
            saveData.append("uploaded_by",uploaded_by);
            saveData.append("booking_id",booking_id);
            if(booking_id != undefined && booking_id != "" && booking_id != null){
                redirectURL.post("/consignments/saveOnetimeFileData",saveData,{
                    headers:{
                        'content-type': 'multipart/form-data',
                        // 'Access-Control-Allow-Origin':"*",
                        // 'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE',
                        'Access-Control-Allow-Headers': 'Origin, Content-Type'
                    }
                }).then((response)=>{
                    if(response.data.message == "Success"){
                        this.setState({
                            overly :"show-n",
                            loadshow:"show-n",
                            bulkslide1 : "",
                            file1:"",
                            show:true,
                            basicTitle:"File Uploaded Successfully",
                            basicType:'success'
                        })
                        this.onLoadPageData();
                        $("#uploadFile1").val("")
                        $("#bookingId1").val("")
                    }
                    else{
                        this.setState({
                            overly :"show-n",
                            loadshow:"show-n",
                            bulkslide1 : "",
                            file1:"",
                            show:true,
                            basicTitle:"File Not Updated",
                            basicType:'warning'
                        })
                        $("#uploadFile1").val("")
                        $("#bookingId1").val("")
                    }
                })
            }else{
                this.setState({
                    show:true,
                    basicTitle:"All fields are mandatory",
                    basicType:'warning'
                })
            }
            
        
        
    }
    onClickShowFilterData3 (e){
        e.preventDefault()
        this.setState({
            overly :"show-m",
            loadshow:"show-m"
        })
        var fileData  = this.state.file3
        var onetimeid = this.state.onetimeid
        var uploaded_by = localStorage.getItem("email")
            let saveData = new FormData();
            saveData.append("fileData",fileData);
            saveData.append("onetimeid",onetimeid);
            saveData.append("uploaded_by",uploaded_by);

            redirectURL.post("/consignments/saveOnetimeBOLFile",saveData,{
                headers:{
                    'content-type': 'multipart/form-data',
                    // 'Access-Control-Allow-Origin':"*",
                    // 'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type'
                }
            }).then((response)=>{
                if(response.data.message == "Success"){
                    this.setState({
                        overly :"show-n",
                        loadshow:"show-n",
                        bulkslide3 :"",
                        file3:"",
                        show:true,
                        basicTitle:"File Uploaded Successfully",
                        basicType:'success'
                    })
                    this.onLoadPageData();
                    $("#uploadFile3").val("")
                }
                else{
                    this.setState({
                        overly :"show-n",
                        loadshow:"show-n",
                        bulkslide3 :"",
                        show:true,
                        file3:"",
                        basicTitle:"File Not Updated",
                        basicType:'warning'
                    })
                    $("#uploadFile3").val("")
                }
            })
        
        
    }
    onClickShowFilterData4 (e){
        e.preventDefault()
        this.setState({
            overly :"show-m",
            loadshow:"show-m"
        })
        var fileData  = this.state.file4
        var weeklyid = this.state.weeklyid
        var uploaded_by = localStorage.getItem("email")
            let saveData = new FormData();
            saveData.append("fileData",fileData);
            saveData.append("weeklyid",weeklyid);
            saveData.append("uploaded_by",uploaded_by);

            redirectURL.post("/consignments/saveweeklyBOLFile",saveData,{
                headers:{
                    'content-type': 'multipart/form-data',
                    // 'Access-Control-Allow-Origin':"*",
                    // 'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type'
                }
            }).then((response)=>{
                if(response.data.message == "Success"){
                    this.setState({
                        overly :"show-n",
                        loadshow:"show-n",
                        bulkslide4 :"",
                        file4:"",
                        show:true,
                        basicTitle:"File Uploaded Successfully",
                        basicType:'success'
                    })
                    this.onLoadPageData();
                    $("#uploadFile4").val("")
                }
                else{
                    this.setState({
                        overly :"show-n",
                        loadshow:"show-n",
                        bulkslide4 :"",
                        file4:"",
                        show:true,
                        basicTitle:"File Not Updated",
                        basicType:'warning'
                    })
                    $("#uploadFile4").val("")
                }
            })
        
        
    }

    onClickShowFilterDataEdit (e){
        e.preventDefault()
        this.setState({
            overly :"show-m",
            loadshow:"show-m"
        })
        var fileData  = this.state.file4
        var weeklyid = this.state.weeklyid
        var uploaded_by = localStorage.getItem("email")
        var shipping_on_board = this.state.container_type.value
            let saveData = new FormData();
            saveData.append("fileData",fileData);
            saveData.append("weeklyid",weeklyid);
            saveData.append("uploaded_by",uploaded_by);
            saveData.append("shipping_on_board",shipping_on_board);

            redirectURL.post("/consignments/saveweeklyBOLFileEdit",saveData,{
                headers:{
                    'content-type': 'multipart/form-data',
                    // 'Access-Control-Allow-Origin':"*",
                    // 'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type'
                }
            }).then((response)=>{
                if(response.data.message == "Success"){
                    this.setState({
                        overly :"show-n",
                        loadshow:"show-n",
                        bulkslide9 :"",
                        file4:"",
                        show:true,
                        basicTitle:"File Uploaded Successfully",
                        basicType:'success'
                    })
                    this.onLoadPageData();
                    $("#uploadFile4").val("")
                }
                else{
                    this.setState({
                        overly :"show-n",
                        loadshow:"show-n",
                        bulkslide9 :"",
                        file4:"",
                        show:true,
                        basicTitle:"File Not Updated",
                        basicType:'warning'
                    })
                    $("#uploadFile4").val("")
                }
            })
        
        
    }

    onClickSubmitSOBData(e){
        e.preventDefault()
        this.setState({
            overly :"show-m",
            loadshow:"show-m"
        })
        var onetimeid = this.state.onetimeid
        var updated_by = localStorage.getItem("email")
        var shipping_on_board = this.state.container_type.value
        var params = {
            shipping_on_board : shipping_on_board,
            updated_by : updated_by,
            onetimeid : onetimeid
        }
        console.log(onetimeid,updated_by,shipping_on_board)
        if(shipping_on_board != "" && shipping_on_board != undefined && shipping_on_board != null){
            redirectURL.post("/consignments/saveOnetimeSOBData",params).then((response)=>{
                if(response.data.message == "Success"){
                            this.setState({
                                overly :"show-n",
                                loadshow:"show-n",
                                bulkslide1 : "",
                                show:true,
                                container_type:"",
                                basicTitle:"Data Uploaded Successfully",
                                basicType:'success'
                            })
                            this.onLoadPageData();
                        }
                else{
                    this.setState({
                        overly :"show-n",
                        loadshow:"show-n",
                        bulkslide1 : "",
                        show:true,
                        container_type:"",
                        basicTitle:"Data Not Updated",
                        basicType:'warning'
                    })
                }
            })
        }
        else{
            this.setState({
                overly :"show-n",
                        loadshow:"show-n",
                show:true,
                basicTitle:"All Fields are Mandatory",
                basicType:'warning'
            })
        }   
    }

    onClickSubmitSOBDataEdit(e){
        e.preventDefault()
        this.setState({
            overly :"show-m",
            loadshow:"show-m"
        })

        var fileData  = this.state.file3
        var onetimeid = this.state.onetimeid
        var uploaded_by = localStorage.getItem("email")
         var shipping_on_board = this.state.container_type.value
            let saveData = new FormData();
            saveData.append("fileData",fileData);
            saveData.append("onetimeid",onetimeid);
            saveData.append("uploaded_by",uploaded_by);
            saveData.append("shipping_on_board",shipping_on_board);
            redirectURL.post("/consignments/saveOnetimeSOBDataedit",saveData,{
                headers:{
                    'content-type': 'multipart/form-data',
                    // 'Access-Control-Allow-Origin':"*",
                    // 'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type'
                }
            }).then((response)=>{
                if(response.data.message == "Success"){
                    this.setState({
                        overly :"show-n",
                        loadshow:"show-n",
                        bulkslide7 :"",
                        file3:"",
                        show:true,
                        basicTitle:"File Uploaded Successfully",
                        basicType:'success'
                    })
                    this.onLoadPageData();
                    $("#uploadFile3").val("")
                }
                else{
                    this.setState({
                        overly :"show-n",
                        loadshow:"show-n",
                        bulkslide7 :"",
                        show:true,
                        file3:"",
                        basicTitle:"File Not Updated",
                        basicType:'warning'
                    })
                    $("#uploadFile3").val("")
                }
            })
    }

    onClickShowFilterData2 =(e)=>{
        e.preventDefault()
        this.setState({
            overly :"show-m",
            loadshow:"show-m"
        })
        var fileData  = this.state.file2
        var weeklyid = this.state.weeklyid
        var uploaded_by = localStorage.getItem("email")
        var booking_id = this.state.bookingId2
        console.log(booking_id,"booking_id")
        let saveData = new FormData();
        saveData.append("fileData",fileData);
        saveData.append("weeklyid",weeklyid);
        saveData.append("uploaded_by",uploaded_by)
        saveData.append("booking_id",booking_id)
        if(booking_id != undefined && booking_id != "" && booking_id != null){
            redirectURL.post("/consignments/saveweeklyFileData",saveData,{
                headers:{
                    'content-type': 'multipart/form-data',
                    // 'Access-Control-Allow-Origin':"*",
                    // 'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type'
                }
            }).then((response)=>{
                if(response.data.message == "Success"){
                    this.setState({
                        overly :"show-n",
                        loadshow:"show-n",
                        bulkslide2 : "",
                        file2:"",
                        show:true,
                        basicTitle:"File Uploaded Successfully",
                        basicType:'success'
                    })
                    this.onLoadPageData();
                    $("#uploadFile2").val("")
                    $("#bookingId2").val("")
                }
                else{
                    this.setState({
                        overly :"show-n",
                        loadshow:"show-n",
                        bulkslide2 : "",
                        file2:"",
                        show:true,
                        basicTitle:"File Not Updated",
                        basicType:'warning'
                    })
                    $("#uploadFile2").val("")
                    $("#bookingId2").val("")
                }
            })
        }else{
            this.setState({
                overly :"show-n",
                loadshow:"show-n",
                show:true,
                basicTitle:"All fields are mandatory",
                basicType:'warning'
            })
        }
    }
    onClickSubmitSOBData2(e){
        e.preventDefault()
        var weeklyid = this.state.weeklyid
        var updated_by = localStorage.getItem("email")
        var shipping_on_board = this.state.container_type.value
        var params = {
            shipping_on_board : shipping_on_board,
            updated_by : updated_by,
            weeklyid : weeklyid
        }
        console.log(weeklyid,updated_by,shipping_on_board)
        if(shipping_on_board != "" && shipping_on_board != undefined && shipping_on_board != null){
            redirectURL.post("/consignments/saveWeeklySOBData",params).then((response)=>{
                if(response.data.message == "Success"){
                            this.setState({
                                overly :"show-n",
                                overly :"show-n",
                        loadshow:"show-n",
                                bulkslide2 : "",
                                container_type:"",
                                show:true,
                                basicTitle:"Data Uploaded Successfully",
                                basicType:'success'
                            })
                            this.onLoadPageData();
                        }
                else{
                    this.setState({
                        overly :"show-n",
                        loadshow:"show-n",
                        bulkslide2 : "",
                        container_type:"",
                        show:true,
                        basicTitle:"Data Not Updated",
                        basicType:'warning'
                    })
                }
            })
        }
        else{
            this.setState({
                overly :"show-n",
                        loadshow:"show-n",
                show:true,
                basicTitle:"All Fields are Mandatory",
                basicType:'warning'
            })
        }   
    }
    onClickUrgentDownloadBulkSlide(field){
        this.setState({
            overly :"show-m",
            loadshow:"show-m"
        })
        const rowData = this.state.downlaodRowData

        if(field != "" && field != undefined && field != null){

            if(field == "container_details_file"){
                var fileName  = rowData.container_details_file
                // console.log(fileName,"496")
                if(fileName != ""){
                    var reqparams ={
                        uploadOtherFileName : fileName,
                    }
                    redirectURL.post("/consignments/downloadonetimeffupdatesfile",reqparams,{
                        responseType: 'blob'
                    }).then((response)=>{
                        // console.log("download response ",response)
                        download(response.data, fileName)
                        if (response.status == 200){
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"File Downloaded Successfully",
                                basicType:'success'})
                        }else{
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"Something Went Wrong",
                                basicType:'warning'
                            })
                        }
                    
                    }).catch(function(error){
                        console.log(error);
                        this.setState({
                            overly :"show-n",
                                    loadshow:"show-n",
                            show:true,
                            basicTitle:"Something Went Wrong",
                            basicType:'warning'
                        })
                    })
                }
            }
            if(field == "dgd_file_name"){
                var fileName  = rowData.dgd_file_name
                // console.log(fileName,"496")
                if(fileName != ""){
                    var reqparams ={
                        uploadOtherFileName : fileName,
                    }
                    redirectURL.post("/consignments/downloadonetimedgdfile",reqparams,{
                        responseType: 'blob'
                    }).then((response)=>{
                        // console.log("download response ",response)
                        download(response.data, fileName)
                        if (response.status == 200){
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"File Downloaded Successfully",
                                basicType:'success'})
                        }else{
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"Something Went Wrong",
                                basicType:'warning'
                            })
                        }
                    
                    }).catch(function(error){
                        console.log(error);
                        this.setState({
                            overly :"show-n",
                                    loadshow:"show-n",
                            show:true,
                            basicTitle:"Something Went Wrong",
                            basicType:'warning'
                        })
                    })
                }
            }
            if(field == "msds_file_name"){
                var fileName  = rowData.msds_file_name
                // console.log(fileName,"496")
                if(fileName != ""){
                    var reqparams ={
                        uploadOtherFileName : fileName,
                    }
                    redirectURL.post("/consignments/downloadonetimemsdsfile",reqparams,{
                        responseType: 'blob'
                    }).then((response)=>{
                        // console.log("download response ",response)
                        download(response.data, fileName)
                        if (response.status == 200){
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"File Downloaded Successfully",
                                basicType:'success'})
                        }else{
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"Something Went Wrong",
                                basicType:'warning'
                            })
                        }
                    
                    }).catch(function(error){
                        console.log(error);
                        this.setState({
                            overly :"show-n",
                                    loadshow:"show-n",
                            show:true,
                            basicTitle:"Something Went Wrong",
                            basicType:'warning'
                        })
                    })
                }
            }
            if(field == "iip_file_name"){
                var fileName  = rowData.iip_file_name
                // console.log(fileName,"496")
                if(fileName != ""){
                    var reqparams ={
                        uploadOtherFileName : fileName,
                    }
                    redirectURL.post("/consignments/downloadonetimeiipfile",reqparams,{
                        responseType: 'blob'
                    }).then((response)=>{
                        // console.log("download response ",response)
                        download(response.data, fileName)
                        if (response.status == 200){
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"File Downloaded Successfully",
                                basicType:'success'})
                        }else{
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"Something Went Wrong",
                                basicType:'warning'
                            })
                        }
                    
                    }).catch(function(error){
                        console.log(error);
                        this.setState({
                            overly :"show-n",
                                    loadshow:"show-n",
                            show:true,
                            basicTitle:"Something Went Wrong",
                            basicType:'warning'
                        })
                    })
                }
            }
            if(field == "grw_file_name"){
                var fileName  = rowData.grw_file_name
                // console.log(fileName,"496")
                if(fileName != ""){
                    var reqparams ={
                        uploadOtherFileName : fileName,
                    }
                    redirectURL.post("/consignments/downloadonetimegrwfile",reqparams,{
                        responseType: 'blob'
                    }).then((response)=>{
                        // console.log("download response ",response)
                        download(response.data, fileName)
                        if (response.status == 200){
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"File Downloaded Successfully",
                                basicType:'success'})
                        }else{
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"Something Went Wrong",
                                basicType:'warning'
                            })
                        }
                    
                    }).catch(function(error){
                        console.log(error);
                        this.setState({
                            overly :"show-n",
                                    loadshow:"show-n",
                            show:true,
                            basicTitle:"Something Went Wrong",
                            basicType:'warning'
                        })
                    })
                }
            }
            if(field == "bill_of_lading_file"){
                var fileName  = rowData.bill_of_lading_file
                console.log(fileName,"4961")
                if(fileName != ""){
                    var reqparams ={
                        uploadOtherFileName : fileName,
                    }
                    redirectURL.post("/consignments/downloadonetimebolfile",reqparams,{
                        responseType: 'blob'
                    }).then((response)=>{
                        console.log("download response",response)
                        download(response.data, fileName)
                        if (response.status == 200){
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"File Downloaded Successfully",
                                basicType:'success'})
                        }else{
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"Something Went Wrong",
                                basicType:'warning'
                            })
                        }
                    
                    }).catch(function(error){
                        console.log(error);
                        this.setState({
                            overly :"show-n",
                                    loadshow:"show-n",
                            show:true,
                            basicTitle:"Something Went Wrong",
                            basicType:'warning'
                        })
                        
                    })
                }
            }
            if(field == "custom_clearance_file"){
                var fileName  = rowData.custom_clearance_file
                // console.log(fileName,"496")
                if(fileName != ""){
                    var reqparams ={
                        uploadOtherFileName : fileName,
                    }
                    redirectURL.post("/consignments/downloadonetimeCCfile",reqparams,{
                        responseType: 'blob'
                    }).then((response)=>{
                        // console.log("download response ",response)
                        download(response.data, fileName)
                        if (response.status == 200){
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"File Downloaded Successfully",
                                basicType:'success'})
                        }else{
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"Something Went Wrong",
                                basicType:'warning'
                            })
                        }
                    
                    }).catch(function(error){
                        console.log(error);
                        this.setState({
                            overly :"show-n",
                                    loadshow:"show-n",
                            show:true,
                            basicTitle:"Something Went Wrong",
                            basicType:'warning'
                        })
                    })
                }
            }
        }else{
            this.setState({
                overly :"show-n",
                        loadshow:"show-n",
                show:true,
                basicTitle:"Something Went Wrong",
                basicType:'warning'
            })      
        }
    }
    onClickOnetimeDownloadBulkSlide(field){
        this.setState({
            overly :"show-m",
            loadshow:"show-m"
        })
        const rowData = this.state.downlaodRowData

        if(field != "" && field != undefined && field != null){



            if(field == "container_details_file"){
                var fileName  = rowData.container_details_file
                // console.log(fileName,"496")
                if(fileName != ""){
                    var reqparams ={
                        uploadOtherFileName : fileName,
                    }
                    redirectURL.post("/consignments/downloadonetimeffupdatesfile",reqparams,{
                        responseType: 'blob'
                    }).then((response)=>{
                        // console.log("download response ",response)
                        download(response.data, fileName)
                        if (response.status == 200){
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"File Downloaded Successfully",
                                basicType:'success'})
                        }else{
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"Something Went Wrong",
                                basicType:'warning'
                            })
                        }
                        
                    }).catch(function(error){
                        console.log(error);
                        this.setState({
                            overly :"show-n",
                                    loadshow:"show-n",
                            show:true,
                            basicTitle:"Something Went Wrong",
                            basicType:'warning'
                        })
                    })
                }
            }
            if(field == "dgd_file_name"){
                var fileName  = rowData.dgd_file_name
                // console.log(fileName,"496")
                if(fileName != ""){
                    var reqparams ={
                        uploadOtherFileName : fileName,
                    }
                    redirectURL.post("/consignments/downloadonetimedgdfile",reqparams,{
                        responseType: 'blob'
                    }).then((response)=>{
                        // console.log("download response ",response)
                        download(response.data, fileName)
                        if (response.status == 200){
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"File Downloaded Successfully",
                                basicType:'success'})
                        }else{
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"Something Went Wrong",
                                basicType:'warning'
                            })
                        }
                        
                    }).catch(function(error){
                        console.log(error);
                        this.setState({
                            overly :"show-n",
                                    loadshow:"show-n",
                            show:true,
                            basicTitle:"Something Went Wrong",
                            basicType:'warning'
                        })
                    })
                }
            }
            if(field == "msds_file_name"){
                var fileName  = rowData.msds_file_name
                // console.log(fileName,"496")
                if(fileName != ""){
                    var reqparams ={
                        uploadOtherFileName : fileName,
                    }
                    redirectURL.post("/consignments/downloadonetimemsdsfile",reqparams,{
                        responseType: 'blob'
                    }).then((response)=>{
                        // console.log("download response ",response)
                        download(response.data, fileName)
                        if (response.status == 200){
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"File Downloaded Successfully",
                                basicType:'success'})
                        }else{
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"Something Went Wrong",
                                basicType:'warning'
                            })
                        }
                        
                    }).catch(function(error){
                        console.log(error);
                        this.setState({
                            overly :"show-n",
                                    loadshow:"show-n",
                            show:true,
                            basicTitle:"Something Went Wrong",
                            basicType:'warning'
                        })
                    })
                }
            }
            if(field == "iip_file_name"){
                var fileName  = rowData.iip_file_name
                // console.log(fileName,"496")
                if(fileName != ""){
                    var reqparams ={
                        uploadOtherFileName : fileName,
                    }
                    redirectURL.post("/consignments/downloadonetimeiipfile",reqparams,{
                        responseType: 'blob'
                    }).then((response)=>{
                        // console.log("download response ",response)
                        download(response.data, fileName)
                        if (response.status == 200){
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"File Downloaded Successfully",
                                basicType:'success'})
                        }else{
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"Something Went Wrong",
                                basicType:'warning'
                            })
                        }
                        
                    }).catch(function(error){
                        console.log(error);
                        this.setState({
                            overly :"show-n",
                                    loadshow:"show-n",
                            show:true,
                            basicTitle:"Something Went Wrong",
                            basicType:'warning'
                        })
                    })
                }
            }
            if(field == "grw_file_name"){
                var fileName  = rowData.grw_file_name
                // console.log(fileName,"496")
                if(fileName != ""){
                    var reqparams ={
                        uploadOtherFileName : fileName,
                    }
                    redirectURL.post("/consignments/downloadonetimegrwfile",reqparams,{
                        responseType: 'blob'
                    }).then((response)=>{
                        // console.log("download response ",response)
                        download(response.data, fileName)
                        if (response.status == 200){
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"File Downloaded Successfully",
                                basicType:'success'})
                        }else{
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"Something Went Wrong",
                                basicType:'warning'
                            })
                        }
                        
                    }).catch(function(error){
                        console.log(error);
                        this.setState({
                            overly :"show-n",
                                    loadshow:"show-n",
                            show:true,
                            basicTitle:"Something Went Wrong",
                            basicType:'warning'
                        })
                    })
                }
            }
            if(field == "bill_of_lading_file"){
                var fileName  =rowData.bill_of_lading_file
                // console.log(fileName,"496")
                if(fileName != ""){
                    var reqparams ={
                        uploadOtherFileName : fileName,
                    }
                    redirectURL.post("/consignments/downloadonetimebolfile",reqparams,{
                        responseType: 'blob'
                    }).then((response)=>{
                        // console.log("download response ",response)
                        download(response.data, fileName)
                        if (response.status == 200){
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"File Downloaded Successfully",
                                basicType:'success'})
                        }else{
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"Something Went Wrong",
                                basicType:'warning'
                            })
                        }
                        
                    }).catch(function(error){
                        console.log(error);
                        this.setState({
                            overly :"show-n",
                                    loadshow:"show-n",
                            show:true,
                            basicTitle:"Something Went Wrong",
                            basicType:'warning'
                        })
                    })
                }
            }
            if(field == "custom_clearance_file"){
                var fileName  = rowData.custom_clearance_file
                // console.log(fileName,"496")
                if(fileName != ""){
                    var reqparams ={
                        uploadOtherFileName : fileName,
                    }
                    redirectURL.post("/consignments/downloadonetimeCCfile",reqparams,{
                        responseType: 'blob'
                    }).then((response)=>{
                        // console.log("download response ",response)
                        download(response.data, fileName)
                        if (response.status == 200){
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"File Downloaded Successfully",
                                basicType:'success'})
                        }else{
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"Something Went Wrong",
                                basicType:'warning'
                            })
                        }
                        
                    }).catch(function(error){
                        console.log(error);
                        this.setState({
                            overly :"show-n",
                                    loadshow:"show-n",
                            show:true,
                            basicTitle:"Something Went Wrong",
                            basicType:'warning'
                        })
                    })
                }
            }
        }else{
            this.setState({
                overly :"show-n",
                        loadshow:"show-n",
                show:true,
                basicTitle:"Something Went Wrong",
                basicType:'warning'
            })      
        }

    }
    onClickweeklyDownloadBulkSlide(field){
        this.setState({
            overly :"show-m",
            loadshow:"show-m"
        })
        const rowData = this.state.downlaodRowData

        if(field != "" && field != undefined && field != null){

            if(field == "container_details_file"){
                var fileName  = rowData.container_details_file
                // console.log(fileName,"496")
                if(fileName != ""){
                    var reqparams ={
                        uploadOtherFileName : fileName,
                    }
                    redirectURL.post("/consignments/downloadweeklyffupdatesfile",reqparams,{
                        responseType: 'blob'
                    }).then((response)=>{
                        // console.log("download response ",response)
                        download(response.data, fileName)
                        if (response.status == 200){
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"File Downloaded Successfully",
                                basicType:'success'})
                        }else{
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"Something Went Wrong",
                                basicType:'warning'
                            })
                        }   
                    }).catch(function(error){
                        console.log(error);
                        this.setState({
                            overly :"show-n",
                                    loadshow:"show-n",
                            show:true,
                            basicTitle:"Something Went Wrong",
                            basicType:'warning'
                        })
                    })
                }
            }
            if(field == "bill_of_lading_file"){
                var fileName  = rowData.bill_of_lading_file
                // console.log(fileName,"496")
                if(fileName != ""){
                    var reqparams ={
                        uploadOtherFileName : fileName,
                    }
                    redirectURL.post("/consignments/downloadonetimebolfile",reqparams,{
                        responseType: 'blob'
                    }).then((response)=>{
                        // console.log("download response ",response)
                        download(response.data, fileName)
                        if (response.status == 200){
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"File Downloaded Successfully",
                                basicType:'success'})
                        }else{
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"Something Went Wrong",
                                basicType:'warning'
                            })
                        }
                        
                    }).catch(function(error){
                        console.log(error);
                        this.setState({
                            overly :"show-n",
                                    loadshow:"show-n",
                            show:true,
                            basicTitle:"Something Went Wrong",
                            basicType:'warning'
                        })
                    })
                }
            }
            if(field == "custom_clearance_file"){
                var fileName  = rowData.custom_clearance_file
                // console.log(fileName,"496")
                if(fileName != ""){
                    var reqparams ={
                        uploadOtherFileName : fileName,
                    }
                    redirectURL.post("/consignments/downloadonetimeCCfile",reqparams,{
                        responseType: 'blob'
                    }).then((response)=>{
                        // console.log("download response ",response)
                        download(response.data, fileName)
                        if (response.status == 200){
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"File Downloaded Successfully",
                                basicType:'success'})
                        }else{
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"Something Went Wrong",
                                basicType:'warning'
                            })
                        }
                        
                    }).catch(function(error){
                        console.log(error);
                        this.setState({
                            overly :"show-n",
                                    loadshow:"show-n",
                            show:true,
                            basicTitle:"Something Went Wrong",
                            basicType:'warning'
                        })
                    })
                }
            }

        }else{
            this.setState({
                overly :"show-n",
                        loadshow:"show-n",
                show:true,
                basicTitle:"Something Went Wrong",
                basicType:'warning'
            })      
        }

    }
    markedForDeleteClicked = (e) => {
        if(e.colDef.field == "revert")
        {
            var rowId = e.data._id;
            var remarks_status = e.data.remarks_status;
            if(remarks_status == "delete")
            {
                if(rowId !=undefined && rowId !='')
                {
                    var qry = {
                        rowId : rowId
                    }
                    redirectURL.post("/consignments/revertTruckAvailable", qry)
                    .then((response) => {
                        //console.log("response ", response.data.records)
                        this.setState({
                            show:true,
                            basicTitle:"Successfully reverted",
                            basicType:"success"  
                        })
                        this.onLoadEmptyTrucksAvailable({dept_code:this.state.dept_code})
                    })
                }
            }
        }
    }
    onLoadUnloadedTrucksAvailable = async(parameters) => {
        this.setState({
            loadshow:"show-m"
        })
            if(localStorage.getItem("user_type") == "TRANSPORTER")
            {
                parameters.transportercode = localStorage.getItem("transportercode")
            }
            else{
                parameters.transportercode = ""
            }
        
        redirectURL.post("/consignments/unloadedtrucksdata", parameters)
        .then((response) => {
            console.log("unloaded trucks ", response.data.records)
            this.setState({
                unloadtrucksdata:response.data.records,
                loadshow:"show-n",

            })
        })
        
    }
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			slidertranslate:"",
			overly:'show-n',
			loadshow:'show-n'
		});
	}
	
	onCloseRouteDiv = () => {
		this.setState({
			slidertranslate:"",
			showDiv:'show-n',
			overly:'show-n'
		});
	}

	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
	}


	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            slidertranslate:'',
            bulkslide:"",
            bulkslide1:"",
            bulkslide2:"",
            bulkslide3:"",
            bulkslide4:"",
            bulkslide5:"",
            bulkslide6:"",
            bulkslide7:"",
            bulkslide8:"",
            bulkslide9:"",
            bulkslide10:"",bulkslide11:"",
            bulkslideforcontainer:"",
            showTrucksList : "show-n",
            truck:{"value":"","label":""},
            truckcategory:{"value":"","label":""},
            warehouse:{"value":"","label":""},
            trucktype: 40,
            rowid:"",
            container_type:{"value":"","label":""},
            onetimeDownloadBulkSlide:"",
            urgentDownloadBulkSlide:"",
            weeklyDownloadBulkSlide:"",
		});
		var form = document.getElementById("booking_form_1");
        if (form) {
        form.reset();
        }
        var form1 = document.getElementById("booking_form_2");
        if (form1) {
            form1.reset();
        }
        var form2  = document.getElementById("shipping_on_board_2");
        if(form2){
            form2.reset();
        }
        var form3 = document.getElementById("bill_of_lading_3")
        if(form3){
            form3.reset()
        }
        var form4 = document.getElementById("bill_of_lading_4")
        if(form4){
            form4.reset()
        }
        
	}
    hideList(){
        this.setState({
			loadshow:'show-n',
			overly:'show-n',
			showTrucksList : "show-n",
        });
    }
	initalList =(event)=>{
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckslist;
        dataset = dataset.slice(0,100);
        this.setState({
            filteredData : dataset,
            showTrucksList : "show-m",
        })
    }
    handlerForm = (event) => {
    	//console.log("Select", event.target.value);
    	// let name = event.target.name;
        // let value = event.target.value;
        // this.setState({[name]:value});
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckNoList;
        var filteredResult = dataset.filter(function(e){
            if(e != "" && e != null)
            {
                return e.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
            }
            
        });
        filteredResult = filteredResult.slice(0,100);
        this.setState({
            filteredData : filteredResult,
            showTrucksList : "show-m",
        })
    }
	closeAlert = async() => {
        await this.setState({
            show: false
        });
        //await window.location.reload();
	}
    changeTruckHandler = truck => {
        this.setState(
            { truck }
        );
        
    }
    changeCategoryHandler = truckcategory => {
        this.setState(
            { truckcategory }
        );
        
    }
    changeWarehouseHandler = warehouse =>{
        this.setState(
            { warehouse }
        );
        
    }
    changeTruckTypeHandler = trucktype =>{
        this.setState(
            { trucktype }
        );

        if(trucktype.value == "Others")
        {
            this.setState({
                trucktypeother:'show-m'
            })
        }
        else
        {
            this.setState({
                trucktypeother:'show-n'
            })
        }
        
    }
	changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
    changeHandlerforbookingId = (event) => {
        console.log(event.target.name,"Select", event.target.value);

        var name = event.target.name;
        var value = event.target.value;
        const re = /^[A-Za-z]+$/;
        // if (name == 'deptName') {
        //     if (value === "" || re.test(value)) {
        //         console.log("name succ")
        //         alert('hi')
        //         //   this.setState(value);
        //     }else{
        //         alert('dont enter the numerals')
        //     }
        // }
        this.setState({
            [name]:event.target.value
        })

        console.log(name);
    }
    trucksoptions(){
        var items = []
        return items;
    }
    truckcategoryoptions(){
        var items = [,
            {
                "value":"Delhi NCR","label":"Delhi NCR"
            },
            {
                "value":"Interplant transfer","label":"Interplant transfer"
            },
            {
                "value":"Consignee Delivery","label":"Consignee Delivery"
            },
            {
                "value":"others","label":"Others",
            }
        ]
        
        return items;
    }
    warehouseoptions(){
        let items = [];     
		//console.log(this.state.trucks);
		var consigners=[];
		if(this.state.dept_code == 'SNDG')
		{
			var keys = ["B", "F", "G", "I","M", "N"]; 
      
			// An array of values 
			var values = ["TVP Bangalore", "Gurgaon", "Gujarat", "Siliguri","Manesar", "TVP Nagpur"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}


		if(this.state.dept_code == 'LOG-PRT')
		{
			var keys = ["MSILDM", "MSILG", "MSILM", "RPDC-BAN","RPDC-GUJ", "RPDC-NAG", "RPDC-SIL"]; 
      
			// An array of values 
			var values = ["Manesar Domestic - DM01", "Gurgaon", "Manesar MTD - EDC/Bumper/TKML/Export", "RPDC Bangalore","RPDC Gujarat", "RPDC Nagpur", "RPDC Siliguri"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}


		if(this.state.dept_code == 'LOG-TNP')
		{
			var keys = ["MSIL-G","MSILME",]; 
      
			// An array of values 
			var values = ["Gurgaon" ,"Manesar"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}

		//console.log("consigners ", consigners)
		// items.push({value:'',label:''}); 
		consigners.forEach(item =>{
			items.push({value:item.code,label:item.value}); 
		});
       
        return items;
    }
    trucktypeoptions(){
        let items = [
            {value:'',label:''},
            {value:'32 feet',label:'32 ft'},
            {value:'20 feet',label:'20 ft'},
            {value:'17 feet',label:'17 ft'},
            {value:'14 feet',label:'14 ft'},
            {value:'Others',label:'Others'}
        ]; 
		
       
        return items;
    }

    onClickShowFilterLoadedTrucksData(e){
        e.preventDefault()
        var truck = this.state.truck;
        var truckcategory = this.state.truckcategory;
        var warehouse = this.state.warehouse;
        //console.log('warehouse ', warehouse)
        // var trucktype = this.state.trucktype.label;
        var trucktype = this.state.trucktype.toString()+" ft";
        if(trucktype == 'Others')
        {
            var othertrucktype = this.state.othertrucktype;
            if(othertrucktype == "")
            {
                trucktype = ""
            }
        }
        else
        {
            var othertrucktype = "";
        }
        var tentitivetime = document.getElementById("tentitivetime").value;
       // var todayDate = moment.parseZone().add(1,"days").format("YYYY-MM-DD")
    //    console.log("tentitivetime ", tentitivetime)
       var t = tentitivetime.split(" ");
       var ts = t[0].split("-")
    //    console.log("ts ", ts[2].length)
       if(ts[2].length>2)
       {
        var tm = ts[2]+"-"+ts[1]+"-"+ts[0]+" "+t[1]+":00"
       }
       else
       {
        var tm = ts[0]+"-"+ts[1]+"-"+ts[2]+" "+t[1]+":00"
       }
    //    console.log("tm ", tm)
        var todayDate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
        var tdate = moment.parseZone(new Date(todayDate)).format("YYYY-MM-DD HH:mm:ss");
        // console.log("tdate ", tdate)
        var today = moment.parseZone(tdate).format("x");
        //console.log("tentitivetime ", tentitivetime)
        var ttime = moment.parseZone(new Date(tm)).format("YYYY-MM-DD HH:mm:ss")
        // console.log("ttime ", ttime)
        var tentive = moment.parseZone(ttime).format("x")
    //    console.log("todayDate ", today)
    //   console.log("ttime ", tentive)
    
        var email = localStorage.getItem("email");
        var prt_transporter_code = localStorage.getItem("prt_transporter_code");
        var username = localStorage.getItem("username");
        var rowid = this.state.rowid;
        
        if(truck != "" && truck != null && truckcategory != "" && warehouse != "" && warehouse != "all" && trucktype != ""
                && tentitivetime != "")
        {
            if(parseInt(tentive) >= parseInt(today))
            {
                var parameters = {
                    truck_no:truck.value,
                    truck_category:truckcategory.value,
                    warehouse:warehouse.value,
                    warehouse_text:warehouse.label,
                    truck_type:trucktype,
                    othertrucktype:othertrucktype,
                    tentitivetime:tm+":00",
                    dept_code:this.state.dept_code,
                    email:email,
                    transportercode:JSON.parse(prt_transporter_code),
                    username:username,
                    rowid:rowid
                }
                
                //console.log("tentitivetime ", parameters)
                redirectURL.post("/consignments/storetruckavailabe", parameters)
                .then((response) => {
                // console.log("response ", response.data)
                    if(response.data.message != "Away From Warehouse" && response.data.message != "No GPS Data")
                    {
                        if(response.data.message == "truckexists")
                        {
                            this.setState({
                                show:true,
                                basicTitle:"Combination for Truck, Warehouse and Tentative Date Already Exists..!!",
                                basicType:'info'
                            })
                        }
                        else if(response.data.message == "success"){

                            document.getElementById("tentitivetime").value="";
                            this.setState({
                                show:true,
                                basicTitle:"Successfully saved",
                                //basicTitle:response.data.api_message,
                                basicType:'success',
                                slidertranslate:"",
                                overly:"show-n",
                                truck:{"value":"","label":""},
                                truckcategory:{"value":"","label":""},
                                warehouse:{"value":"","label":""},
                                trucktype: 40,
                                othertrucktype:"",
                                rowid:""
                            })
                            var p = {
                                dept_code:this.state.dept_code,
                                startDate:this.state.startDate,
                                endDate:this.state.endDate
                            }
                            this.onLoadTrucksAvailable(p);

                            var formdata = {
                                plant_codes:[this.state.emptyplant.value],
                                radius:100,
                                request_type:3,
                                dept_code:this.state.dept_code
                            }
                            this.submitEmptyFormHandler(formdata);
                            this.onLoadEmptyTrucksAvailable({dept_code:this.state.dept_code})

                        }
                        else
                        {
                            document.getElementById("tentitivetime").value="";
                            this.setState({
                                show:true,
                                basicTitle:"Failed",
                                basicType:'danger',
                                slidertranslate:"",
                                overly:"show-n",
                                truck:{"value":"","label":""},
                                truckcategory:{"value":"","label":""},
                                warehouse:{"value":"","label":""},
                                trucktype: 40,
                                othertrucktype:"",
                                rowid:""
                            })
                        }
                    }
                    else
                    {
                        if(response.data.message != "No GPS Data")
                        {
                            this.setState({
                                show: true,
                                basicTitle: "Truck is "+response.data.distance.toString()+"KM away from Selected Warhouse",
                                basicType: "warning"
                            })
                        }
                        else
                        {
                            this.setState({
                                show: true,
                                basicTitle: response.data.api_message,
                                basicType: "warning"
                            })
                        }
                        
                    }
                    
                })
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Tentitive Date should be greate than today",
                    basicType:'danger'
                })
            }
        }
        else{
            this.setState({
                show:true,
                basicTitle:"All * fields are mandatory",
                basicType:'danger'
            })
        }
        
    }

    onClickShowAvailabilityForm(){
        this.setState({
            slidertranslate:"slider-translate-30p",
            overly:"show-m"
        })
    }
    setConsignmentValue(e){
        console.log(e)
        if(e != null)
        {
            var st =e.value;
            var stringval = st.replace(/[^A-Z0-9]+/ig, "");
            console.log("stringval  ",stringval)
            var params = {
                truck_no : stringval,
            }
            redirectURL.post("/consignments/getTruckType", params)
            .then((response) => {
                var results = response.data;
                //console.log("response ", response.data.records)
                if(results.length > 0)
                {
                    var truck_type = results[0].truck_type;
                    if(truck_type !=undefined && truck_type !='')
                    {
                        var tt = truck_type;
                        if(truck_type == "32 feet")
                        {
                            var tt = "32 ft";
                        }
                        else if(truck_type == "20 feet")
                        {
                            var tt = "20 ft";
                        }
                        else if(truck_type == "17 feet")
                        {
                            var tt = "17 ft";
                        }
                        else if(truck_type == "14 feet")
                        {
                            var tt = "14 ft";
                        }
                        this.setState({
                            trucktype:truck_type.split(" ")[0],
                        })
                    }
                    
                }                
            })
            this.setState({
                truck : {value:stringval,label:stringval},
            });
        }
        else{
            this.setState({
                truck : e
            });
        }
        
    }

	handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDate:edate
    	});
    }
    
	handlerStartDateTimeLoad = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDateLoad:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
	
    handlerEndDateTimeLoad  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDateLoad:edate
    	});
    }
    onClickFilterData(){
        var start = this.state.startDate;
        var end = this.state.endDate;
        if(start == '' || start == "NaN-NaN-NaN")
        {
            var sdate = this.state.defaultsDate
        }
        else{
            var sdate = start
        }
        if(end == '' || end == "NaN-NaN-NaN")
        {
            var edate = this.state.defaulteDate            
        }
        else{
            var edate = end
        }
        var parameters  = {
            dept_code:this.state.dept_code,
            startDate:sdate,
            endDate:edate
        }
        this.onLoadTrucksAvailable(parameters)
        this.consolidateTrucks();
    }

    onClickLoadedTruckFilterData(){
        var start = this.state.startDateLoad;
        var end = this.state.endDateLoad;
        if(start == '' || start == "NaN-NaN-NaN")
        {
            var sdate = this.state.defaultsDate
        }
        else{
            var sdate = start
        }
        if(end == '' || end == "NaN-NaN-NaN")
        {
            var edate = this.state.defaulteDate            
        }
        else{
            var edate = end
        }
        var parameters  = {
            dept_code:this.state.dept_code,
            startDate:sdate,
            endDate:edate
        }
        this.onLoadLoadedTrucksAvailable(parameters)
        this.consolidateLoadedTrucks();
    }
    onClickUnloadedTruckFilterData(){
        var start = this.state.startDateLoad;
        var end = this.state.endDateLoad;
        if(start == '' || start == "NaN-NaN-NaN")
        {
            var sdate = this.state.defaultsDate
        }
        else{
            var sdate = start
        }
        if(end == '' || end == "NaN-NaN-NaN")
        {
            var edate = this.state.defaulteDate            
        }
        else{
            var edate = end
        }
        var parameters  = {
            dept_code:this.state.dept_code,
            startDate:sdate,
            endDate:edate
        }
        this.onLoadUnloadedTrucksAvailable(parameters)
    }

    onClickEditRecord = async (params) => {
        //console.log("Edit ", params)
        var truck = {"value":params.truck_no,"label":params.truck_no};
        var truckcategory = {"value":params.truck_category,"label":params.truck_category};
        var warehouse = {"value":params.warehouse,"label":params.warehouse_text};
        var trucktype = params.truck_type.split(" ")[0];
        var tentitivetime = moment.parseZone(params.tentitivetime).format("YYYY-MM-DD HH:mm")
        var othertrucktype = params.other_trucktype
        document.getElementById("tentitivetime").value = tentitivetime;
        if(params.truck_type == "Others")
        {
            var tothers = "show-m"
        }
        else
        {
            var tothers = "show-n"
        }
        await this.setState({
            slidertranslate:"slider-translate-30p",
            overly:"show-m",
            truck:truck,
            truckcategory:truckcategory,
            warehouse:warehouse,
            trucktype:trucktype,
            othertrucktype:othertrucktype,
            rowid:params._id,
            trucktypeother:tothers
        })
        //await this.onClickShowFilterData()
    }
    onClickDeleteRecord(params){
        console.log("Delete ", params)
        var parameters = {
            rowid:params._id
        }
        redirectURL.post("/consignments/removetruckavailable",parameters)
        .then((response) => {            
            var p = {
                dept_code:this.state.dept_code,
                startDate:this.state.startDate,
                endDate:this.state.endDate
            }
            this.onLoadTrucksAvailable(p)
            var formdata = {
                plant_codes:[this.state.emptyplant.value],
                radius:100,
                request_type:3,
                dept_code:this.state.dept_code
            }
            this.submitEmptyFormHandler(formdata);
            this.onLoadEmptyTrucksAvailable({dept_code:this.state.dept_code})
        })
    }
    onShowBulkUpload(){
		
		this.setState({
			bulkslide:"slider-translate",
			overly:'show-m'
		});
	}
    resetUpload = () => {
		this.setState({
			uploadDivWidth:'0%',
			bulkslide:'',
			showDiv:'show-n',
			overly:'show-n',
			file:''
		});
		document.getElementById("upform").reset();
    }
    
   
    changeFileHandler (e){
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'container_requirement_date',
					inputName: 'container_requirement_date',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},{ 
					name: 'stuffing_location',
					inputName: 'stuffing_location',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},{ 
					name: 'country',
					inputName: 'country',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},{ 
					name: 'type',
					inputName: 'type',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},{ 
					name: 'port_of_destination',
					inputName: 'port_of_destination',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},{ 
					name: 'incoterms',
					inputName: 'incoterms',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
		
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				resolve(contents);
			};
			 reader.readAsText(e.target.files[0]);
		});
		//console.log("Out ", out);
		// this.setState({
		// 	csvcontent:contents
		// });
		this.setState({
			file:e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		{
		}
		else{
			e.target.value = null;
			this.setState({
				uploadFile:'',
				show: true, 
				basicType:'danger', 
				basicTitle:'Please upload file having extensions .csv only.',
			});
		}
		
    }
    
    changeFileHandler1 =(e)=>{
        this.setState({
            file1:e.target.files[0]
        });
    }
    changeFileHandler2 =(e)=>{
		this.setState({
            file2:e.target.files[0]
        });
    }
    changeFileHandler3 =(e)=>{
        this.setState({
            file3:e.target.files[0]
        });
    }
    changeFileHandler4 =(e)=>{
        this.setState({
            file4:e.target.files[0]
        });
    }
    async onClickTab(pageType){
        console.log("function",pageType )
        if(pageType == "pending")
        {
            var pending = "activet";
            var intransit = "";
            var closure = "";
            var pType = 1;
        }
        else if(pageType == "intransit"){
            var pending = "";
            var intransit ="activet";
            var closure = "";
            var pType = 2;
        }
        else if(pageType == "closure")
        {
            var pending = "";
            var intransit ="";
            var closure = "activet";
            var pType = 3;
        }
        await  this.setState({
            pending:pending,
            intransit : intransit,
            closure:closure,
            pageType:pType
        })
        await this.onLoadPageData();
    }
    consolidateTrucks =async() =>{
        var start = this.state.startDate;
        var end = this.state.endDate;
        if(start == '' || start == "NaN-NaN-NaN")
        {
            var sdate = this.state.defaultsDate
        }
        else{
            var sdate = start
        }
        if(end == '' || end == "NaN-NaN-NaN")
        {
            var edate = this.state.defaulteDate            
        }
        else{
            var edate = end
        }
        var parameters={
            dept_code:this.state.dept_code,
            startDate:sdate,
            endDate:edate,
        }

        await redirectURL.post("/consignments/alltruckAvailabledata", parameters)
        .then(async (response) => {
            var records = response.data.records;
            console.log(records,"records.length")
            if(this.state.dept_code == "LOG-PRT")
            {
                var r = response.data.records;
                var dst = [];
                r.map(function(e){
                    if(dst.indexOf(e.truck_type) == -1)
                    {
                        dst.push(e.truck_type)
                    }
                })
                var truck_type_aggr = {}
                dst.map(function(e){
                    if(parseInt(e.split(" ")[0]) >= 30)
                    {
                        truck_type_aggr[e] = "32 FT"
                    }
                    else if(parseInt(e.split(" ")[0]) >= 18)
                    {
                        truck_type_aggr[e] = "20 FT"
                    }
                    else if(parseInt(e.split(" ")[0]) < 18)
                    {
                        truck_type_aggr[e] = "Small Trucks"
                    }
                })
                records = response.data.records
                var chk = await records.map(function(e){
                    e.truck_type = truck_type_aggr[e.truck_type]
                    console.log(e.truck_type)
                })
            }
            
            console.log("respnse ", records)
            var categories = [];
            if(records.length > 0)
            {
                records.map((item) => {
                    categories.push({
                        warehouse:item.warehouse,
                        warehouse_text:item.warehouse_text,
                        truck_type:item.truck_type
                    });
                // categories.push(item.truck_category);
                })
            }
            //console.log("categoriescategories ", categories)
            this.setState({
                categories:categories,
                transporters:records,
            })
            await this.createColumns();
            await this.createRowData();
            
        })
        
    }
    createColumns()
    {
        var columns=[]
        var transporters = this.state.transporters;
        columns.push({
            headerName:"Transporter Name",
            field:"transporter_name",
            width:180,
        })
        var categories = this.state.categories;
        
        
        var warehousewise = groupBy(transporters, rdata => rdata.warehouse);
        // console.log("warehousewise ", warehousewise)
        warehousewise.forEach((wares, wkeys) => {
            var defs = {
                headerName:wkeys,
                headerClass:["csgpsBg"],	
            }
            //console.log("defs ", defs)
            if(wares.length > 0)
            {
               // console.log("wares ", wares)
                var warehouses = groupBy(wares, rdata=>rdata.truck_type)
               // console.log("warehouses ", warehouses)
                var warechildern = []
                warehouses.forEach((wh, ky) => {
                    warechildern.push({
                        headerName:ky,
                        headerClass:["textAlignCenter"],
                        field:wh[0].warehouse+""+ky,
                        width:100,
                        cellClass:["textAlignCenter"],
                        cellStyle: {borderLeft: '1px solid #ccc'}
                    })
                })
            }
            defs.children = warechildern
            columns.push(defs);
        })
        columns.push({
            headerName:"Total",
            headerClass:["textAlignCenter"],
            field:"total",
            width:100,
            pinned:"right",
            cellClass:["textAlignCenter"],
            cellClass:"boxbgblue",
        })
       // console.log("columns ", columns)
        this.setState({
            columns:columns
        })
    }
    createRowData(){
        var rowdefs = []
        var totalCnt = 0
        var transporters = this.state.transporters;
        var transportername = groupBy(transporters, rdata => rdata.transporter_name);
        console.log(transportername,"transportername")
        transportername.forEach((trans, transkey) =>{
            var rows = {}
            if(transkey.includes("("))
            {
                rows.transporter_name = transkey.split("(")[0]
            }
            else
            {
                rows.transporter_name=transkey
            }
            if(rows.transporter_name != "P S LOGISTICS")
            {

                rows.transporter_name = capitalizeFirstLetter(rows.transporter_name.toLowerCase())
                var rowcnt = 0
                if(trans.length > 0)
                {
                    //console.log("trans ", trans)
                    var warehousewise = groupBy(trans, rdata => rdata.warehouse);
                    warehousewise.forEach((wares,wkey) => {
                        var warehouses = groupBy(wares, rdata=>rdata.truck_type)
                        warehouses.forEach((wh,ky) => {
                            // rows[wh[0].warehouse+""+ky] = wh.length
                            // rowcnt = rowcnt+parseInt(wh.length)
                            if(wh.length == undefined)
                            {
                                var whLen = "";
                            }
                            else{
                                var whLen = wh.length;
                            }
                            var vCnt = 0
                            if(this.state.dept_code == "LOG-PRT")
                            {
                                wh.map(function(k){
                                    if(k.vehicle_count != '' && k.vehicle_count != undefined)
                                    {
                                        vCnt = vCnt + k.vehicle_count;
                                    }
                                    else
                                    {
                                        vCnt = vCnt + 0;
                                    }
                                })
                                rows[wh[0].warehouse+""+ky] = vCnt;
                            }
                            else
                            {
                                if(wh[0].vehicle_count != '' && wh[0].vehicle_count != undefined)
                                {
                                    vCnt = wh[0].vehicle_count;
                                }
                                else
                                {
                                    vCnt =  0;
                                }
                                rows[wh[0].warehouse+""+ky] = wh[0].vehicle_count;

                            }
                            
                            
                            rowcnt = rowcnt+parseInt(vCnt)
                            
                        })
                    })
                }
            }
            totalCnt = totalCnt+parseInt(rowcnt);
            rows["total"] = rowcnt
            rowdefs.push(rows)
        })
        console.log(rowdefs,"rowdefs")
        var pinnedBottomRowData = {}
        var temp = {};
        if(rowdefs.length > 0)
        {
            var gridkeys = Object.keys(rowdefs[0]);
            gridkeys.map(function(e){
                temp[e] = 0
            })
        }
        else
        {
            var gridkeys = [];
        }
        
        gridkeys = gridkeys.filter(function(e){return e != "transporter_name"})
        rowdefs.map(function(e, index){
            gridkeys.map(function(k)
            {
                var keyval;
                if(e[k] == "")
                {
                     keyval = 0;
                }else{ keyval = e[k]}
                temp[k] = temp[k] + keyval
            })
        })
        temp['Total'] = totalCnt;
        temp['transporter_name'] = "Total"
        console.log(temp, "pinnedBottomRowData")
        this.setState({
            rowdefs:rowdefs,
            pinnedRegionBottomRowData:[temp],
            overly:"show-n",
            loadshow:"show-n",
        })
        //console.log("rowdefs ", rowdefs)
    }

    
    consolidateLoadedTrucks =async() =>{
        this.setState({
            loadshow:"show-m"
        })
        var start = this.state.startDate;
        var end = this.state.endDate;
        if(start == '' || start == "NaN-NaN-NaN")
        {
            var sdate = this.state.defaultsDate
        }
        else{
            var sdate = start
        }
        if(end == '' || end == "NaN-NaN-NaN")
        {
            var edate = this.state.defaulteDate            
        }
        else{
            var edate = end
        }
        var parameters={
            dept_code:this.state.dept_code,
            startDate:sdate,
            endDate:edate,
        }

        await redirectURL.post("/consignments/allloadedtruckAvailabledata", parameters)
        .then(async (response) => {
            var records = response.data.records;
            console.log(records,"records.length")
            if(this.state.dept_code == "LOG-PRT")
            {
                var r = response.data.records;
                var dst = [];
                r.map(function(e){
                    if(dst.indexOf(e.truck_type) == -1)
                    {
                        dst.push(e.truck_type)
                    }
                })
                var truck_type_aggr = {}
                dst.map(function(e){
                    if(parseInt(e.split(" ")[0]) >= 30)
                    {
                        truck_type_aggr[e] = "32 FT"
                    }
                    else if(parseInt(e.split(" ")[0]) >= 18)
                    {
                        truck_type_aggr[e] = "20 FT"
                    }
                    else if(parseInt(e.split(" ")[0]) < 18)
                    {
                        truck_type_aggr[e] = "Small Trucks"
                    }
                })
                records = response.data.records
                var chk = await records.map(function(e){
                    e.truck_type = truck_type_aggr[e.truck_type]
                    console.log(e.truck_type)
                })
            }
            
            console.log("respnse ", records)
            var categories = [];
            if(records.length > 0)
            {
                records.map((item) => {
                    categories.push({
                        warehouse:item.warehouse,
                        warehouse_text:item.warehouse_text,
                        truck_type:item.truck_type
                    });
                // categories.push(item.truck_category);
                })
            }
            //console.log("categoriescategories ", categories)
            this.setState({
                categories:categories,
                transporters:records,
                loadshow:"show-n"
            })
            await this.createLoadedTruckColumns();
            await this.createLoadedTruckRowData();
            
        })
        
    }
    createLoadedTruckColumns()
    {
        var columns=[]
        var transporters = this.state.transporters;
        columns.push({
            headerName:"Transporter Name",
            field:"transporter_name",
            width:180,
        })
        var categories = this.state.categories;
        
        
        var warehousewise = groupBy(transporters, rdata => rdata.warehouse);
        // console.log("warehousewise ", warehousewise)
        warehousewise.forEach((wares, wkeys) => {
            var defs = {
                headerName:wkeys,
                headerClass:["csgpsBg"],	
            }
            //console.log("defs ", defs)
            if(wares.length > 0)
            {
               // console.log("wares ", wares)
                var warehouses = groupBy(wares, rdata=>rdata.truck_type)
               // console.log("warehouses ", warehouses)
                var warechildern = []
                warehouses.forEach((wh, ky) => {
                    warechildern.push({
                        headerName:ky,
                        headerClass:["textAlignCenter"],
                        field:wh[0].warehouse+""+ky,
                        width:100,
                        cellClass:["textAlignCenter"],
                        cellStyle: {borderLeft: '1px solid #ccc'}
                    })
                })
            }
            defs.children = warechildern
            columns.push(defs);
        })
        columns.push({
            headerName:"Total",
            headerClass:["textAlignCenter"],
            field:"total",
            width:100,
            pinned:"right",
            cellClass:["textAlignCenter"],
            cellClass:"boxbgblue",
        })
       // console.log("columns ", columns)
        this.setState({
            columns:columns
        })
    }
    createLoadedTruckRowData(){
        var rowdefs = []
        var totalCnt = 0
        var transporters = this.state.transporters;
        var transportername = groupBy(transporters, rdata => rdata.transporter_name);
        console.log(transportername,"transportername")
        transportername.forEach((trans, transkey) =>{
            var rows = {}
            if(transkey.includes("("))
            {
                rows.transporter_name = transkey.split("(")[0]
            }
            else
            {
                rows.transporter_name=transkey
            }
            if(rows.transporter_name != "P S LOGISTICS")
            {

                rows.transporter_name = capitalizeFirstLetter(rows.transporter_name.toLowerCase())
                var rowcnt = 0
                if(trans.length > 0)
                {
                    //console.log("trans ", trans)
                    var warehousewise = groupBy(trans, rdata => rdata.warehouse);
                    warehousewise.forEach((wares,wkey) => {
                        var warehouses = groupBy(wares, rdata=>rdata.truck_type)
                        warehouses.forEach((wh,ky) => {
                            // rows[wh[0].warehouse+""+ky] = wh.length
                            // rowcnt = rowcnt+parseInt(wh.length)
                            if(wh.length == undefined)
                            {
                                var whLen = "";
                            }
                            else{
                                var whLen = wh.length;
                            }
                            var vCnt = 0
                            if(this.state.dept_code == "LOG-PRT")
                            {
                                wh.map(function(k){
                                    if(k.vehicle_count != '' && k.vehicle_count != undefined)
                                    {
                                        vCnt = vCnt + k.vehicle_count;
                                    }
                                    else
                                    {
                                        vCnt = vCnt + 0;
                                    }
                                })
                                rows[wh[0].warehouse+""+ky] = vCnt;
                            }
                            else
                            {
                                if(wh[0].vehicle_count != '' && wh[0].vehicle_count != undefined)
                                {
                                    vCnt = wh[0].vehicle_count;
                                }
                                else
                                {
                                    vCnt =  0;
                                }
                                rows[wh[0].warehouse+""+ky] = wh[0].vehicle_count;

                            }
                            
                            
                            rowcnt = rowcnt+parseInt(vCnt)
                            
                        })
                    })
                }
            }
            totalCnt = totalCnt+parseInt(rowcnt);
            rows["total"] = rowcnt
            rowdefs.push(rows)
        })
        console.log(rowdefs,"rowdefs")
        var pinnedBottomRowData = {}
        var temp = {};
        if(rowdefs.length > 0)
        {
            var gridkeys = Object.keys(rowdefs[0]);
            gridkeys.map(function(e){
                temp[e] = 0
            })
        }
        else
        {
            var gridkeys = [];
        }
        
        gridkeys = gridkeys.filter(function(e){return e != "transporter_name"})
        rowdefs.map(function(e, index){
            gridkeys.map(function(k)
            {
                var keyval;
                if(e[k] == "")
                {
                     keyval = 0;
                }else{ keyval = e[k]}
                temp[k] = temp[k] + keyval
            })
        })
        temp['Total'] = totalCnt;
        temp['transporter_name'] = "Total"
        console.log(temp, "pinnedBottomRowData")
        this.setState({
            rowloadedtruckdefs:rowdefs,
            pinnedRegionBottomRowData:[temp],
            overly:"show-n",
            loadshow:"show-n",
        })
        //console.log("rowdefs ", rowdefs)
    }
    async onClickShowTabView(tab){
        if(tab == "weekly")
        {
           await this.setState({
                marktabactive:"btn-success",
                emptytabactive:"",
                emptytabstatusactive:"",
                loadtruckactive:"",
                unloadtruckactive:"",
                markedshow:"show-m",
                emptyshow:"show-n",
                onetimeshow:"show-n",
                urgentshow:"show-n",
                loadtruckshow:"show-n",
                unloadtruckshow:"show-n",
                request_type:2,
                urgenttabstatus:''
            })
        }
        else if(tab == "onetime")
        {
            await this.setState({
                marktabactive:"",
                emptytabactive:"",
                loadtruckactive:"",
                unloadtruckactive:"",
                emptytabstatusactive:"btn-success",
                markedshow:"show-n",
                emptyshow:"show-n",
                onetimeshow:"show-m",
                urgentshow:"show-n",
                loadtruckshow:"show-n",
                unloadtruckshow:"show-n",
                request_type:1,
                urgenttabstatus:''
            })
        }
        else if(tab == "urgent")
        {
            await this.setState({
                marktabactive:"",
                emptytabactive:"",
                loadtruckactive:"",
                unloadtruckactive:"",
                emptytabstatusactive:"",
                markedshow:"show-n",
                emptyshow:"show-n",
                onetimeshow:"show-n",
                urgenttabstatus:"btn-success",
                urgentshow:'show-m',
                loadtruckshow:"show-n",
                unloadtruckshow:"show-n",
                request_type:3,
            })
        }  
        await this.onLoadPageData();
    }

    selectOptionsItems() {
		let items = [];     
		var consigners = [];
        //console.log("this.state.dept_code ", this.state.dept_code)
		
		if(this.state.dept_code == 'SNDG' || this.state.dept_code == 'sndg')
		{
			var keys = ["B", "F", "G", "I","M", "N","MUNDRA","MBPT"]; 
      
			// An array of values 
			var values = ["TVP Bangalore", "Gurgaon", "Gujarat", "Siliguri","Manesar", "TVP Nagpur", 'Mundra', "Mumbai Port"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}


		else if(this.state.dept_code == 'LOG-PRT' || this.state.dept_code == 'log-prt')
		{
			var keys = ["MSILG", "MSILM", "RPDC-BAN","RPDC-GUJ", "RPDC-NAG", "RPDC-SIL"]; 
      
			// An array of values 
			var values = [ "Gurgaon", "Manesar", "RPDC Bangalore","RPDC Gujarat", "RPDC Nagpur", "RPDC Siliguri"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}


		else if(this.state.dept_code == 'LOG-TNP' || this.state.dept_code == 'log-tnp')
		{
			var keys = ["MSIL-G","MSIL-M","TKM","SMG",]; 
      
			// An array of values 
			var values = ["Gurgoan","Manesar","TKM Bidadi","SMG Gujarat",]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}

		//console.log("consigners ", consigners)
		//items.push({value:'all',label:'All'}); 
		consigners.forEach(item =>{
			items.push({value:item.code,label:item.value}); 
		});
		
        return items;
    }
    changePlantCode = async emptyplant => {
		//console.log("Select", event.target.value);
		await this.setState(
			{ emptyplant },
			() => console.log(`Option selected:`, this.state.emptyplant)
          );
        
    }
    formEmptyHandler(){
        var formdata = {
            plant_codes:[this.state.emptyplant.value],
            request_type:3,
            dept_code:this.state.dept_code,
        }
        this.submitEmptyFormHandler(formdata);
    }
    submitEmptyFormHandler(formdata){
        var user = localStorage.getItem("user_type")
        var transporterCode = localStorage.getItem("transportercode");
        this.setState({
            overly : "show-m",
            loadshow : "show-m",
        })
        //console.log(transporterCode,"transporterCode")
        if(user == "TRANSPORTER")
        {
            formdata.transporterCode = transporterCode;
        }
        redirectURL.post('/consignments/emptyplantdata',formdata)
        .then((response) => {
           // console.log("empty ", response.data.data)
           
            //console.log("recordsarr ", recordsarr)
           
        })
            
    }
    onChangeRemarkOptions(params)
    {
        var truck = {"value":params.data.truck_no,"label":params.data.truck_no};
    
        this.setState({
            slidertranslate:"slider-translate-30p",
            overly:"show-m",
            truck:truck,
            warehouse:this.state.emptyplant
        })        
        
    }
    onChangeRemarkRepairOptions(params){
        var email = localStorage.getItem("email");
        var transportercode = localStorage.getItem("transportercode");
        var username = localStorage.getItem("username");

        var parameters = {
            truck_no:params.data.truck_no,
            truck_category:"",
            warehouse:this.state.emptyplant.value,
            warehouse_text:this.state.emptyplant.label,
            truck_type:"",
            othertrucktype:"",
            tentitivetime:"",
            dept_code:this.state.dept_code,
            email:email,
            transportercode:JSON.parse(transportercode),
            username:username
        }
        redirectURL.post("/consignments/storeemptytruckavailabe", parameters)
        .then(async (response) => {
           // console.log("resp ", response.data)
            if(response.data.message == "success")
            {
                
                this.setState({
                  show:true,
                  basicTitle:"Successfully update status",
                  basicType:"success"  
                })
                var paras  = {
                    dept_code:this.state.dept_code,
                    startDate:this.state.startDate,
                    endDate:this.state.endDate
                }
                this.onLoadTrucksAvailable(paras)
                var formdata = {
                    plant_codes:[this.state.emptyplant.value],
                    radius:100,
                    request_type:3,
                    dept_code:this.state.dept_code
                }
                this.submitEmptyFormHandler(formdata);
                this.onLoadEmptyTrucksAvailable({dept_code:this.state.dept_code})

            }
            else if(response.data.message == "truckexists")
            {
                this.setState({
                    show:true,
                    basicTitle:"Data already exists",
                    basicType:"info"  
                  })
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Failed",
                    basicType:"danger"  
                  })
            }
        })
    }
        
    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onClickCounter(params){
        if(params == "automark")
        {
            this.setState({
                rowData:this.state.auto_marked_by_system
            })
        }
    }
    onClickLoadedCounter(params){

        if(params == "loadautomark")
        {
            this.setState({
                rowDataLoadedTrucks:this.state.loaded_auto_marked_by_system
            })
        }
    }
    render(){
        const modalStyles  = {
            width:'1300px !important',
        }
        const {bookingDownloadList} = this.state
        const {usermanualmodal} = this.state;

		const { open } = this.state;
        if(localStorage.getItem("user_type") == "TRANSPORTER")
        {
            var showopts = false
            var showdelete = false
        }
        else{
            var showopts = false
            showdelete = false
        }
        var showforPending = ""
        if(this.state.pageType == 1){
            showforPending = false
        }else{
            showforPending = true
        }
        var showforClosed = ""
        if(this.state.pageType == 3){
            showforClosed = false
        }else{
            showforClosed = true
        }
        var showforInprogress ="" 
        if(this.state.pageType == 2){
            showforInprogress = false
        }else{
            showforInprogress = true
        }
        var columnDefs = [
        
            {
                headerName:"",
                headerTooltip:"Upload Booking Details",
                field:"weekly_update_files",
                width:40,
                hide:showforPending,
                cellRenderer: function(params){
                            let basestring = params.data.batch_name;
                            let encryptedstring = window.btoa(basestring);
                            var htmloption = '<span title="Upload Booking Details" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-upload booking-upload-btn"></i></span>';
                            return htmloption;
                        },
            },

            {
                headerName:"",
                headerTooltip:"Update Shipping On Board",
                field:"update_shipping_on_board",
                width:40,
                hide:showforInprogress,
                cellRenderer: function(params){
                            let basestring = params.data.batch_name;
                            let encryptedstring = window.btoa(basestring);
                            if(params.data.shipping_on_board != undefined && params.data.shipping_on_board != "" && params.data.shipping_on_board != null){
                                var htmloption = '<span title="Update Shipping On Board" style="cursor: not-allowed; pointer-events: none;"  class="tooltip-container"><i class="fa fa-upload booking-disable-btn"></i></span>';
                                return htmloption;
                            }else{
                                var htmloption = '<span title="Update Shipping On Board" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-upload booking-upload-data-btn"></i></span>';
                                return htmloption;
                            }
                            
                        },
                        cellClass: function(params) {
                            if ((params.data.shipping_on_board != undefined && params.data.shipping_on_board != "" && params.data.shipping_on_board != null)) {
                                return 'disabled-cell'; // Changed the class name here
                            } else {
                                return '';
                            }
                        },
        },
            {
                headerName:"",
                headerTooltip:"Upload Bill of Lading",
                field:"upload_bill_of_lading",
                width:40,
                hide:showforInprogress,
                cellRenderer: function(params){
                            let basestring = params.data.batch_name;
                            let encryptedstring = window.btoa(basestring);
                            var htmloption = '<span title="Upload Bill of Lading" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-upload booking-upload-btn"></i></span>';
                            return htmloption;
                        },
            },
            // downloads
            {
                headerName:"",
                headerTooltip:"Booking PDF",
                field:"container_details_file",
                width:40,
                // hide:showforInprogress,
                hide:true,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    if(params.data.container_details_file != undefined &&  params.data.container_details_file != null && params.data.container_details_file != ""){
                        var htmloption = '<span title="Booking PDF"  style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                        return htmloption;
                    }else{
                        var htmloption = '<span title="Booking PDF" disabled="on" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                        return htmloption;
                    }
                    
                },
            },
            {
                headerName:"",
                headerTooltip:"Shipping Bill LEO copy",
                field:"custom_clearance_file",
                width:40,
                // hide:showforInprogress,
                hide:true,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    if(params.data.custom_clearance_file != undefined &&  params.data.custom_clearance_file != null && params.data.custom_clearance_file != ""){
                        var htmloption = '<span title="Shipping Bill LEO copy" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                        return htmloption;
                    }else{
                        var htmloption = '<span title="Shipping Bill LEO copy" disabled="on" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                        return htmloption;
                    }
                    
                },
            },
            {
                headerName:"",
                headerTooltip:"Bill of Lading",
                field:"bill_of_lading_file",
                width:40,
                // hide:showforClosed,
                hide:true,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    if(params.data.bill_of_lading_file != undefined &&  params.data.bill_of_lading_file != null && params.data.bill_of_lading_file != ""){
                        var htmloption = '<span title="Bill of Lading"  style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                        return htmloption;
                    }else{
                        var htmloption = '<span title="Bill of Lading" disabled="on" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                        return htmloption;
                    }
                    
                },
            },
            {
                headerName:"",
                headerTooltip:"Download",
                statusValues:{"showforPending":showforPending,"showforInprogress":showforInprogress,"showforClosed":showforClosed},
                field:"Download",
                width:40,
                hide: showforPending ? true : true,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<span title="Download" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                    return htmloption;
                },
            },
            {
                headerName:"",
                headerTooltip:"Download",
                statusValues:{"showforPending":showforPending,"showforInprogress":showforInprogress,"showforClosed":showforClosed},
                field:"Download",
                width:40,
                hide: showforInprogress,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<span title="Download" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                    return htmloption;
                },
            },
            {
                headerName:"",
                headerTooltip:"Download",
                statusValues:{"showforPending":showforPending,"showforInprogress":showforInprogress,"showforClosed":showforClosed},
                field:"Download",
                width:40,
                hide: showforClosed,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<span title="Download" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                    return htmloption;
                },
            },
            {
                headerName:"Required Id",
                field:"requirement_id",
                width:140
            },
            {
                headerName:"Container No",
                field:"container_no",
                width:180
            },
            {
                headerName:"Booking Id",
                field:"booking_id",
                hide : showforClosed,
                width:180
            },
            {
                headerName:"Container Requirement Date",
                field:"container_requirement_date",
                width:180,
                valueGetter:function(params){
                    try{
                        if(params.data.container_requirement_date)
                        {
                            return getHyphenDDMMMYYYY(params.data.container_requirement_date);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Stuffing Location",
                field:"dispatch_location",
                width:140
            },
            {
                headerName:"Country",
                field:"destination_country",
                width:140,
            },
            {
                headerName:"Container Type",
                field:"container_type",
                width:140
            },
            {
                headerName:"Container Size",
                field:"container_size",
                width:120,
            },
            // {
            //     headerName:"Container Requirement",
            //     field:"container_requirement",
            //     width:120,
            // },
            {
                headerName:"Vehicle No",
                field:"truck_no",
                width:180
            },
            {
                headerName:"Port Of Destination",
                field:"destination_port",
                width:220,
            },
            {
                headerName:"BKG",
                field:"bkg",
                width:140
            },
            {
                headerName:"Vessel",
                field:"vessel",
                width:140
            },
            {
                headerName:"Estimated Time of Dispatch",
                field:"etd",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.etd)
                        {
                            return getHyphenDDMMMYYYY(params.data.etd);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Pickup Location",
                field:"pickup_location",
                width:140
            },
            {
                headerName:"Incoterms",
                field:"incoterms",
                width:220,
            },

            {
                headerName:"Shipping On Board",
                field:"shipping_on_board",
                width:140,
                hide:showforClosed,
            },
            {
                headerName: "Submitted Bills",
                field: "bill_with_option_ffweekly",
                width: 150,
                resizable: true,
                editable: true,
               hide:showforClosed,
                // hide:params.data.status >= Number(4),
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ["Yes" , "No"],
                },
                valueGetter: function (params) {
                    const podStatusValue = params.data.bill_with_option_ffweekly;
                  
                    if (podStatusValue === "") {
                      return " "; // Display an empty string
                    } else if (podStatusValue === "Yes") {
                      return "Yes";
                    } else if (podStatusValue === "No") {
                      return "No";
                    } else {
                      return podStatusValue; // Display the actual value if it's not empty, 0, or 1
                    }
                  },
                  valueSetter: (params) => { 
                    console.log(params.data._id,"IN ValyeSetter, data = ",params.newValue,'---');
                    if (params.data.bill_with_option_ffweekly !== params.newValue) {
                    if(params.newValue == "Yes"){
                        params.newValue = 'Yes'
                    }else{
                        params.newValue = "No"
                    }
                        let layoutdataRole = {
                            bill_with_option_ffweekly : (params.newValue),
                            ID: params.data._id,
                            type:'Weekly'
                        }
                        console.log(layoutdataRole,'layoutdataRole')
                        redirectURL.post('/consignments/billwithoptionforclouseronetime', layoutdataRole,{
                            headers:{
                                "Access-Control-Allow-Origin": "*",
                                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
                            }
                        })
                        .then((response) => {
                            
                            
                                this.onLoadPageData();

                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                        return true;
                    } else {
                        return false;
                    }
                }
                
            },
            {
            headerName: "Bill No",
            field: "upload_billno_weeklyff",
            width: 180,
            resizable: true,
            // cellEditor: 'inputCellEditor',
            hide: showforClosed,
            valueGetter: function (params) {
                if (params.data.bill_with_option_ffweekly === "Yes") {
                return params.data.upload_billno_weeklyff;
                } else {
                return "";
                }
            },
            editable: function (params) {
                return params.data.bill_with_option_ffweekly === "Yes";
                },
            valueSetter: function (params) {
                if (params.data.bill_with_option_ffweekly === "Yes") {
                console.log(params.data._id, "IN ValueSetter, data =", params.newValue, '---');
                if (params.data.upload_billno_weeklyff !== params.newValue) {
                    let layoutdataRole = {
                    upload_billno_weeklyff: params.newValue,
                    ID: params.data._id,
                    type: 'ffWeekly',
                    };
                    params.data.upload_billno_weeklyff = params.newValue
                    console.log(layoutdataRole, 'layoutdataRole');
                    redirectURL.post('/consignments/clouserbillnoweekly', layoutdataRole, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    },
                    })
                    .then((response) => {
                        // window.location.reload()
                        // this.onLoadPageData();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                    return true;
                } else {
                    return false;
                }
                } else {
                return false; // Not editable if bill_with_option_transweekly is not "Yes"
                }
            },
            
            
            }
        ]
        var listData = [] 
        this.state.filteredData.map(function(e){
            listData.push(<li className="dropdown-truck-no">{e}</li>)
        })
        var user = localStorage.getItem("user_type")
        if(user == "TRANSPORTER")
        {
            var showcol = false
        }
        else{
            var showcol = true
        }
        var showforPending = ""
        var showforurgent = ""
        if(this.state.pageType == 1){
            showforPending = false
            showforurgent = false
        }else{
            showforPending = true
            showforurgent = true
        }
        var showforClosed = ""
        if(this.state.pageType == 3){
            showforClosed = false
        }else{
            showforClosed = true
        }
        var showforInprogress ="" 
        if(this.state.pageType == 2){
            showforInprogress = false
        }else{
            showforInprogress = true
        }
    //   var showforweeklypending = ''
    //     if(this.state.request_type == 2){
    //         if(this.state.pageType == 1){
    //             showforweeklypending = false
    //         }
    //     }else {
    //         showforweeklypending = true
    //     }
        var emptyColumnDefs = [
            {
                headerName:"",
                headerTooltip:"Upload Booking Details",
                field:"onetime_update_files",
                width:40,
                hide:showforPending,
                cellRenderer: function(params){
                            let basestring = params.data.batch_name;
                            let encryptedstring = window.btoa(basestring);
                            var htmloption = '<span title="Upload Booking Details" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-upload booking-upload-btn"></i></span>';
                            return htmloption;
                        },
            },
           
            {
                headerName:"",
                headerTooltip:"Update Shipping On Board",
                field:"update_shipping_on_board",
                width:40,
                hide:showforInprogress,
                cellRenderer: function(params){
                            let basestring = params.data.batch_name;
                            let encryptedstring = window.btoa(basestring);
                            if(params.data.shipping_on_board != undefined && params.data.shipping_on_board != "" && params.data.shipping_on_board != null){
                                var htmloption = '<span title="Update Shipping On Board" style="cursor: not-allowed; pointer-events: none;" class="tooltip-container"><i class="fa fa-upload booking-disable-btn"></i></span>';
                                return htmloption;
                            }else{
                                var htmloption = '<span title="Update Shipping On Board" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-upload booking-upload-data-btn"></i></span>';
                                return htmloption;
                            }
                            
                        },
                cellClass: function(params) {
                    if ((params.data.shipping_on_board != undefined && params.data.shipping_on_board != "" && params.data.shipping_on_board != null)) {
                        return 'disabled-cell'; // Changed the class name here
                    } else {
                        return '';
                    }}
            },
            {
                headerName:"",
                headerTooltip:"Upload Bill of Lading",
                field:"upload_bill_of_lading",
                width:40,
                hide:showforInprogress,
                cellRenderer: function(params){
                            let basestring = params.data.batch_name;
                            let encryptedstring = window.btoa(basestring);
                            var htmloption = '<span title="Upload Bill of Lading" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-upload booking-upload-btn"></i></span>';
                            return htmloption;
                        },
            },
            // Downloads
            {
                headerName:"",
                headerTooltip:"Booking PDF",
                field:"container_details_file",
                width:40,
                // hide:showforInprogress,
                hide:true,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    if(params.data.container_details_file != undefined &&  params.data.container_details_file != null && params.data.container_details_file != ""){

                        var htmloption = '<span title="Booking PDF"  style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                        return htmloption;
                    }else{
                        var htmloption = '<span title="Booking PDF" disabled="on" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                        return htmloption;
                    }
                    
                },
            },
            {
                headerName:"",
                headerTooltip:"Shipping Bill LEO copy",
                field:"custom_clearance_file",
                width:40,
                // hide:showforInprogress,
                hide:true,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    if(params.data.custom_clearance_file != undefined &&  params.data.custom_clearance_file != null && params.data.custom_clearance_file != ""){
                        var htmloption = '<span title="Shipping Bill LEO copy" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                        return htmloption;
                    }else{
                        var htmloption = '<span title="Shipping Bill LEO copy" disabled="on" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                        return htmloption;
                    }
                    
                },
            },
            {
                headerName:"",
                headerTooltip:"DGD Uploaded File",
                field:"dgd_file_name",
                width:40,
                hide:true,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<span title="DGD Uploaded File" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                    return htmloption;
                },
            },
            {
                headerName:"",
                headerTooltip:"MSDS Uploaded File",
                field:"msds_file_name",
                width:40,
                hide:true,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<span title="MSDS Uploaded File" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                    return htmloption;
                },
            },
            {
                headerName:"",
                headerTooltip:"IIP Uploaded File",
                field:"iip_file_name",
                width:40,
                hide:true,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<span title="IIP Uploaded File" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                    return htmloption;
                },
            },
            {
                headerName:"",
                headerTooltip:"GR Waiver File",
                field:"grw_file_name",
                width:40,
                hide:true,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    if(params.data.grw_file_name != undefined &&  params.data.grw_file_name != null && params.data.grw_file_name != ""){
                        var htmloption = '<span title="GR Waiver File" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                        return htmloption;
                    }else{
                        var htmloption = '<span title="GR Waiver File" disabled="on" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                        return htmloption;
                    }
                    
                },
            },
            {
                headerName:"",
                headerTooltip:"Bill of Lading",
                field:"bill_of_lading_file",
                width:40,
                // hide:showforClosed,
                hide:true,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    if(params.data.bill_of_lading_file != undefined &&  params.data.bill_of_lading_file != null && params.data.bill_of_lading_file != ""){
                        if (!showforClosed){
                        }
                        var htmloption = '<span title="Bill of Lading" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                        return htmloption;
                    }else{
                        var htmloption = '<span title="Bill of Lading" style="cursor:pointer;"  disabled="on" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                        return htmloption;
                    }
                    
                },
            },
            {
                headerName:"",
                headerTooltip:"Download",
                statusValues:{"showforPending":showforPending,"showforInprogress":showforInprogress,"showforClosed":showforClosed},
                field:"Download",
                width:40,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<span title="Download" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                    return htmloption;
                },
            },
            {
                headerName:"Container Requirement Date",
                field:"container_requirement_date",
                width:200,
                valueGetter:function(params){
                    try{
                        if(params.data.container_requirement_date)
                        {
                            return getHyphenDDMMMYYYY(params.data.container_requirement_date);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Requirement ID",
                field:"requirement_id",
                width:140
            },
            {
                headerName:"Container No",
                field:"container_no",
                width:180
            },
            {
                headerName:"Booking Id",
                field:"booking_id",
                hide : showforClosed,
                width:180
            },

            {
                headerName:"Container Type",
                field:"container_type",
                width:140
            },
            {
                headerName:"Dispatch Location ",
                field:"dispatch_location",
                width:140,
            },
            {
                headerName:"Container Size(in ft)",
                field:"container_size",
                width:160,
            },
      
            {
                headerName:"Vehicle No",
                field:"truck_no",
                width:180
            },
            {
                headerName:"Date",
                field:"date",
                width:180,
                valueGetter:function(params){
                    try{
                        if(params.data.date)
                        {
                            return getHyphenDDMMMYYYY(params.data.date);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
  
            {
                headerName:"Dispatch Port",
                field:"dispatch_port",
                width:140,
            },
            {
                headerName:"Destination Port",
                field:"destination_port",
                width:140
            },
            {
                headerName:"BKG",
                field:"bkg",
                width:140
            },
            {
                headerName:"Vessel",
                field:"vessel",
                width:140
            },
            {
                headerName:"Estimated Time of Dispatch",
                field:"etd",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.etd)
                        {
                            return getHyphenDDMMMYYYY(params.data.etd);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Pickup Location",
                field:"pickup_location",
                width:140
            },         
          
            {
                headerName:"Incoterms",
                field:"incoterms",
                width:140,
            },
            {
                headerName:"Payment Terms",
                field:"payment_terms",
                width:140
            },
            {
                headerName:"Certificate of Origin",
                field:"certificate_of_origin",
                width:140
            },
            {
                headerName:"FTA(Free Trade Agreement) Required",
                field:"fta_required",
                width:170
            },
            {
                headerName:"Export Incentive",
                field:"export_incentive",
                width:140
            },

            {
                headerName:"Shipping On Board",
                field:"shipping_on_board",
                width:140,
                hide:showforClosed,
            },
            {
                headerName: "Submitted Bills",
                field: "bill_with_option_ffonetime",
                width: 150,
                resizable: true,
                editable: true,
               hide:showforClosed,
                // hide:params.data.status >= Number(4),
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ["Yes" , "No"],
                },
                valueGetter: function (params) {
                    const podStatusValue = params.data.bill_with_option_ffonetime;
                  
                    if (podStatusValue === "") {
                      return " "; // Display an empty string
                    } else if (podStatusValue === "Yes") {
                      return "Yes";
                    } else if (podStatusValue === "No") {
                      return "No";
                    } else {
                      return podStatusValue; // Display the actual value if it's not empty, 0, or 1
                    }
                  },
                  valueSetter: (params) => { 
                    console.log(params.data._id,"IN ValyeSetter, data = ",params.newValue,'---');
                    if (params.data.bill_with_option_ffonetime !== params.newValue) {
                    if(params.newValue == "Yes"){
                        params.newValue = 'Yes'
                    }else{
                        params.newValue = "No"
                    }
                        let layoutdataRole = {
                            bill_with_option_ffonetime : (params.newValue),
                            ID: params.data._id,
                            type:'oneTime'
                        }
                        console.log(layoutdataRole,'layoutdataRole')
                        redirectURL.post('/consignments/billwithoptionforclouseronetime', layoutdataRole,{
                            headers:{
                                "Access-Control-Allow-Origin": "*",
                                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
                            }
                        })
                        .then((response) => {
                            
                            
                                this.onLoadPageData();

                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                        return true;
                    } else {
                        return false;
                    }
                }
                
              },
              {
                headerName: "Bill No",
                field: "upload_billno_oneTimeff",
                width: 180,
                resizable: true,
                hide: showforClosed,
                valueGetter: function (params) {
                  if (params.data.bill_with_option_ffonetime === "Yes") {
                    return params.data.upload_billno_oneTimeff;
                  } else {
                    return "";
                  }
                },
                valueSetter: function (params) {
                  if (params.data.bill_with_option_ffonetime === "Yes") {
                    console.log(params.data._id, "IN ValueSetter, data =", params.newValue, '---');
                    if (params.data.upload_billno_oneTimeff !== params.newValue) {
                      let layoutdataRole = {
                        upload_billno_oneTimeff: params.newValue,
                        ID: params.data._id,
                        type: 'ffOneTime',
                      };
                      params.data.upload_billno_oneTimeff = params.newValue
                      console.log(layoutdataRole, 'layoutdataRole');
                      redirectURL.post('/consignments/clouserbillnoweekly', layoutdataRole, {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                        },
                      })
                        .then((response) => {
                        //     window.location.reload()
                        //   this.onLoadPageData();
                        })
                        .catch(function (error) {
                          console.log(error);
                        });
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return false; // Not editable if bill_with_option_transweekly is not "Yes"
                  }
                },
                editable: function (params) {
                  return params.data.bill_with_option_ffonetime === "Yes";
                },
              },

            // {
            //     headerName:"Remarks",
            //     field:"remarks",
            //     width:140,
            // },
            // {
            //     headerName:"FF Uploaded File",
            //     field:"container_details_file",
            //     width:180,
            //     hide:showforClosed,
            //     cellRenderer: function(params){
            //         let basestring = params.data.batch_name;
            //         let encryptedstring = window.btoa(basestring);
            //         var htmloption = '<button class="btn btn-info" style="padding-top:0px">Download</button>';
            //         return htmloption;
            //     },
            // },

            // {
            //     headerName:"Ringi Files",
            //     field:"ringi_data",
            //     width:180,
            //     cellRenderer: function(params){
            //         let basestring = params.data.batch_name;
            //         let encryptedstring = window.btoa(basestring);
            //         var htmloption = '<button class="btn btn-primary" style="padding-top:0px">Ringi Data</button>';
            //         return htmloption;
            //     },
            // },
        ];
        var UgrentColumnDefs = [    
             {
                headerName:"",
                headerTooltip:"Upload Booking Details",
                field:"onetime_update_files",
                width:40,
                hide:showforurgent,
                cellRenderer: function(params){
                            let basestring = params.data.batch_name;
                            let encryptedstring = window.btoa(basestring);
                            var htmloption = '<span title="Upload Booking Details" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-upload booking-upload-btn"></i></span>';
                            return htmloption;
                        },
            },
            {
                headerName:"",
                headerTooltip:"Update Shipping On Board",
                field:"update_shipping_on_board",
                width:40,
                hide:showforInprogress,
                cellRenderer: function(params){
                            let basestring = params.data.batch_name;
                            let encryptedstring = window.btoa(basestring);
                            if(params.data.shipping_on_board != undefined && params.data.shipping_on_board != "" && params.data.shipping_on_board != null){
                                var htmloption = '<span title="Update Shipping On Board" style="cursor: not-allowed; pointer-events: none;"  class="tooltip-container"><i class="fa fa-upload booking-disable-btn"></i></span>';
                                return htmloption;
                            }else{
                                var htmloption = '<span title="Update Shipping On Board" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-upload booking-upload-data-btn"></i></span>';
                                return htmloption;
                            }
                            
                        },
                cellClass: function(params) {
                    if ((params.data.shipping_on_board != undefined && params.data.shipping_on_board != "" && params.data.shipping_on_board != null)) {
                        return 'disabled-cell'; // Changed the class name here
                    } else {
                        return '';
                    }
                }
            },
            {
                headerName:"",
                headerTooltip:"Upload Bill of Lading",
                field:"upload_bill_of_lading",
                width:40,
                hide:showforInprogress,
                cellRenderer: function(params){
                            let basestring = params.data.batch_name;
                            let encryptedstring = window.btoa(basestring);
                            var htmloption = '<span title="Upload Bill of Lading" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-upload booking-upload-btn"></i></span>';
                            return htmloption;
                        },
            },        
            {
                headerName:"",
                headerTooltip:"Edit Shipping Date",
                field:"edit_shipping_date",
                width:40,
                hide:showforClosed,
                cellRenderer: function(params){
                        let basestring = params.data.batch_name;
                        let encryptedstring = window.btoa(basestring);
                    var htmloption = '<span title="Edit Shipping Date" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-edit booking-edit-btn"></i></span>';
                    return htmloption;
                },
            },
            // #downloads
            {
                headerName:"",
                headerTooltip:"Booking PDF",
                field:"container_details_file",
                width:40,
                // hide:showforInprogress,
                hide:true,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    if(params.data.container_details_file != undefined &&  params.data.container_details_file != null && params.data.container_details_file != ""){
                        var htmloption = '<span title="Booking PDF" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                        return htmloption;
                    }else{
                        var htmloption = '<span title="Booking PDF"  disabled="on" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                        return htmloption;
                    }
                    
                },
            },
            {
                headerName:"",
                headerTooltip:"Shipping Bill LEO copy",
                field:"custom_clearance_file",
                width:40,
                // hide:showforInprogress,
                hide:true,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    if(params.data.custom_clearance_file != undefined &&  params.data.custom_clearance_file != null && params.data.custom_clearance_file != ""){
                        var htmloption = '<span title="Shipping Bill LEO copy" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                        return htmloption;
                    }else{
                        var htmloption = '<span title="Shipping Bill LEO copy" disabled="on"  style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                        return htmloption;
                    }
                    
                },
            },
            {
                headerName:"",
                headerTooltip:"Bill of Lading",
                field:"bill_of_lading_file",
                width:40,
                // hide:showforClosed,
                hide:true,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    if(params.data.bill_of_lading_file != undefined &&  params.data.bill_of_lading_file != null && params.data.bill_of_lading_file != ""){
                        var htmloption = '<span title="Bill of Lading" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                        return htmloption;
                    }else{
                        var htmloption = '<span title="Bill of Lading" disabled="on" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                        return htmloption;
                    }
                    
                },
            },
            {
                headerName:"",
                headerTooltip:"DGD Uploaded File",
                field:"dgd_file_name",
                width:40,
                hide:true,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<span title="DGD Uploaded File" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                    return htmloption;
                },
            },
            {
                headerName:"",
                headerTooltip:"MSDS Uploaded File",
                field:"msds_file_name",
                width:40,
                hide:true,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<span title="MSDS Uploaded File" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                    return htmloption;
                },
            },
            {
                headerName:"",
                headerTooltip:"IIP Uploaded File",
                field:"iip_file_name",
                width:40,
                hide:true,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<span title="IIP Uploaded File" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                    return htmloption;
                },
            },
            {
                headerName:"",
                headerTooltip:"GR Waiver File",
                field:"grw_file_name",
                width:40,
                hide:true,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    if(params.data.grw_file_name != undefined &&  params.data.grw_file_name != null && params.data.grw_file_name != ""){
                        var htmloption = '<span title="GR Waiver File" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                        return htmloption;
                    }else{
                        var htmloption = '<span title="GR Waiver File" disabled="on" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                        return htmloption;
                    }
                    
                },
            },
            {
                headerName:"",
                headerTooltip:"Download",
                statusValues:{"showforPending":showforPending,"showforInprogress":showforInprogress,"showforClosed":showforClosed},
                field:"Download",
                width:40,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<span title="Download" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                    return htmloption;
                },
            },

            {
                headerName:"Requirement ID",
                field:"requirement_id",
                width:140
            },
            {
                headerName:"Container No",
                field:"container_no",
                width:180
            },
            {
                headerName:"Booking Id",
                field:"booking_id",
                hide : showforClosed,
                width:180
            },
            {
                headerName:"Container Requirement Date",
                field:"container_requirement_date",
                width:200,
                valueGetter:function(params){
                    try{
                        if(params.data.container_requirement_date)
                        {
                            return getHyphenDDMMMYYYY(params.data.container_requirement_date);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Container Type",
                field:"container_type",
                width:140
            },
            {
                headerName:"Dispatch Location ",
                field:"dispatch_location",
                width:140,
            },
            {
                headerName:"Container Size(in ft)",
                field:"container_size",
                width:160,
            },
            {
                headerName:"Vehicle No",
                field:"truck_no",
                width:180
            },
            {
                headerName:"Date",
                field:"date",
                width:180,
                valueGetter:function(params){
                    try{
                        if(params.data.date)
                        {
                            return getHyphenDDMMMYYYY(params.data.date);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Dispatch Port",
                field:"dispatch_port",
                width:140,
            },
            {
                headerName:"Destination Port",
                field:"destination_port",
                width:140
            },
            {
                headerName:"BKG",
                field:"bkg",
                width:140
            },
            {
                headerName:"Vessel",
                field:"vessel",
                width:140
            },
            {
                headerName:"Estimated Time of Dispatch",
                field:"etd",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.etd)
                        {
                            return getHyphenDDMMMYYYY(params.data.etd);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Pickup Location",
                field:"pickup_location",
                width:140
            },

            {
                headerName:"Incoterms",
                field:"incoterms",
                width:140,
            },
            {
                headerName:"Payment Terms",
                field:"payment_terms",
                width:140
            },
            {
                headerName:"Certificate of Origin",
                field:"certificate_of_origin",
                width:140
            },
            {
                headerName:"FTA(Free Trade Agreement) Required",
                field:"fta_required",
                width:170
            },
            {
                headerName:"Export Incentive",
                field:"export_incentive",
                width:140
            },
            {
                headerName:"Requirment Remark",
                field:"requirment_type_remark",
                width:120,
            },

            {
                headerName:"Shipping On Board",
                field:"shipping_on_board",
                width:140,
                hide:showforClosed,
            },
            {
                headerName: "Submitted Bills",
                field: "bill_with_option_ffurgent",
                width: 150,
                resizable: true,
                editable: true,
               hide:showforClosed,
                // hide:params.data.status >= Number(4),
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ["Yes" , "No"],
                },
                valueGetter: function (params) {
                    const podStatusValue = params.data.bill_with_option_ffurgent;
                  
                    if (podStatusValue === "") {
                      return " "; // Display an empty string
                    } else if (podStatusValue === "Yes") {
                      return "Yes";
                    } else if (podStatusValue === "No") {
                      return "No";
                    } else {
                      return podStatusValue; // Display the actual value if it's not empty, 0, or 1
                    }
                  },
                  valueSetter: (params) => { 
                    console.log(params.data._id,"IN ValyeSetter, data = ",params.newValue,'---');
                    if (params.data.bill_with_option_ffurgent !== params.newValue) {
                    if(params.newValue == "Yes"){
                        params.newValue = 'Yes'
                    }else{
                        params.newValue = "No"
                    }
                        let layoutdataRole = {
                            bill_with_option_ffurgent : (params.newValue),
                            ID: params.data._id,
                            type:'Urgent'
                        }
                        console.log(layoutdataRole,'layoutdataRole')
                        redirectURL.post('/consignments/billwithoptionforclouseronetime', layoutdataRole,{
                            headers:{
                                "Access-Control-Allow-Origin": "*",
                                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
                            }
                        })
                        .then((response) => {
                            
                            
                                this.onLoadPageData();

                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                        return true;
                    } else {
                        return false;
                    }
                }
                
              },
              {
                headerName: "Bill No",
                field: "upload_billno_Urgentff",
                width: 180,
                resizable: true,
                hide: showforClosed,
                valueGetter: function (params) {
                  if (params.data.bill_with_option_ffurgent === "Yes") {
                    return params.data.upload_billno_Urgentff;
                  } else {
                    return "";
                  }
                },
                valueSetter: function (params) {
                  if (params.data.bill_with_option_ffurgent === "Yes") {
                    console.log(params.data._id, "IN ValueSetter, data =", params.newValue, '---');
                    if (params.data.upload_billno_Urgentff !== params.newValue) {
                      let layoutdataRole = {
                        upload_billno_Urgentff: params.newValue,
                        ID: params.data._id,
                        type: 'ffUrgent',
                      };
                      params.data.upload_billno_Urgentff = params.newValue
                      console.log(layoutdataRole, 'layoutdataRole');
                      redirectURL.post('/consignments/clouserbillnoweekly', layoutdataRole, {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                        },
                      })
                        .then((response) => {
                        //     window.location.reload()
                        //   this.onLoadPageData();
                        })
                        .catch(function (error) {
                          console.log(error);
                        });
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return false; // Not editable if bill_with_option_transweekly is not "Yes"
                  }
                },
                editable: function (params) {
                  return params.data.bill_with_option_ffurgent === "Yes";
                },
              }


        ];
        var onetimeColumnDefs  = [
            
            {
                headerName:"",
                field:"_id",
                width:50,
                hide:showopts,
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: 'deletetruckavailable'
                    };
                    return rendComponent
                }
            },
            
            {
                headerName: "",
                field: "revert",
                width: 80,
                cellStyle: {fontWeight: 'bold',cursor: 'pointer'},
                valueGetter: function(params){
                    if(params.data.remarks_status == "delete")
                    {                        
                        return "Revert";
                    }
                    else
                    {
                        return "";
                    }
                }
            },
            {
                headerName: "Remarks",
                field: "remarks_status",
                width: 140
            },
            {
                headerName:"Truck No",
                field:"truck_no",
                width:140
            },
            {
                headerName:"Ware House Location",
                field:"warehouse_text",
                width:140
            },
            {
                headerName:"Transporter Name",
                field:"transporter_name",
                width:140
            },
            {
                headerName:"Tentative Time",
                field:"tentitivetime",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.tentitivetime)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.tentitivetime);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Created On",
                field:"created_on",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.created_on)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.created_on);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Updated On",
                field:"updated_on",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.updated_on)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.updated_on);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            }
        ]
        
        var columnLoadedTrucksDefs = [
            
            {
                headerName:"Ringi No",
                field:"ringi_desc",
                width:180,
                
            },
            {
                headerName:"",
                headerTooltip:"Ringi File",
                field:"download_ringi_file",
                width:40,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<span title="Ringi File" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                    return htmloption;
                },
            },
        ]

function generateLegends(columnDefs) {
    let legends = [];
    let legendHtml = '';

    const itemsToAdd = columnDefs.filter(columnDef => !columnDef.hide && columnDef.cellRenderer && columnDef.headerTooltip);

    itemsToAdd.forEach((columnDef, index) => {
        let iconHtml = columnDef.cellRenderer({ data: { batch_name: '' } });
        legendHtml += `<span>${iconHtml} ${columnDef.headerTooltip}</span>`;

        if (index < itemsToAdd.length - 1) { // Check if not the last item
            legendHtml += `&nbsp; | &nbsp;`; // Add separator
        }
    });

    if (legendHtml !== '') { // Only push if legendHtml is not empty
        legends.push(legendHtml);
    }

    return legends;
}

        const onetimeColumnDefsLegends =generateLegends(emptyColumnDefs);
        const urgentColumnDefsLegends = generateLegends(UgrentColumnDefs);
        const weeklyColumnDefsLegends = generateLegends(columnDefs);
        const emptyColumnDefsLegends = generateLegends(onetimeColumnDefs);
		return(
			<div className=" d-flex flex-column mt-3 container-booking-dropdowns">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>

                    <div className={"col-xl-12 col-lg-12 mb-10p"+ " "+(this.state.markedshow)} style={{ paddingLeft: '29vw' }}>
                    <div>
                        {weeklyColumnDefsLegends.map((legend, index) => (
                                <div key={index} >
                                            <span className="float-right f14 icon-container-legend" dangerouslySetInnerHTML={{ __html: legend }}></span>
                                    {index !== weeklyColumnDefsLegends.length - 1 && <div style={{ marginBottom: '10px' }}></div>} 
                                </div>
                                ))}
                    </div>
                    </div>
                    <div className={"col-xl-12 col-lg-12 mb-10p"+" "+(this.state.onetimeshow)} style={{ paddingLeft: '29vw' }}>
                    <div>
                        {onetimeColumnDefsLegends.map((legend, index) => (
                                <div key={index} >
                                            <span className="float-right f14 icon-container-legend" dangerouslySetInnerHTML={{ __html: legend }}></span>
                                    {index !== onetimeColumnDefsLegends.length - 1 && <div style={{ marginBottom: '10px' }}></div>} {/* Add space between spans */}
                                </div>
                                ))}
                        </div>
                    </div>
                    					
                    <div className={"col-xl-12 col-lg-12 mb-10p"+" "+(this.state.urgentshow)} style={{ paddingLeft: '29vw' }}>
                    <div>
                        {urgentColumnDefsLegends.map((legend, index) => (
                                <div key={index} >
                                            <span className="float-right f14 icon-container-legend" dangerouslySetInnerHTML={{ __html: legend }}></span>
                                    {index !== urgentColumnDefsLegends.length - 1 && <div style={{ marginBottom: '10px' }}></div>} {/* Add space between spans */}
                                </div>
                                ))}
                        </div>
                    </div>
                    <div className={"col-xl-12 col-lg-12 mb-10p"+" "+(this.state.emptyshow)} style={{ paddingLeft: '29vw' }}>
                    <div>
                        {emptyColumnDefsLegends.map((legend, index) => (
                                <div key={index} >
                                            <span className="float-right f14 icon-container-legend" dangerouslySetInnerHTML={{ __html: legend }}></span>
                                    {index !== emptyColumnDefsLegends.length - 1 && <div style={{ marginBottom: '10px' }}></div>} {/* Add space between spans */}
                                </div>
                                ))}
                        </div>
                    </div>

                <div> 

                    <div className={"col-xl-12 col-lg-12 "+(this.state.markedshow)}>
                            <div>
                            
                                <div id= "booking-ag-grid" className="row">
                                    <div id="myGrid2" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham ">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            onCellClicked={this.onCellRowClickedweekly}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
                                            
                                        />
                                        
                                       
                                    </div> 
                                   
                                       
                                </div>


                            </div>
                    </div>

                    {/* Empty Near Plants */}
                    
                    <div className={"col-xl-12 col-lg-12 "+(this.state.emptyshow)}>
                            <div >
                           
                                <div id= "booking-ag-grid" className="row">
                                    <div id="myGrid1" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={onetimeColumnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.emptyrowStatusData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            headerHeight={this.state.headerHeight}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
                                            onCellClicked={this.markedForDeleteClicked}
                                        />
                                        
                                       
                                    </div> 
                                   
                                       
                                </div>


                            </div>
                    </div>
                    {/* END */}
                    <div className={"col-xl-12 col-lg-12 "+(this.state.urgentshow)}>
                           
                                <div id= "booking-ag-grid" className="row">
                                    <div id="myGrid2" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={UgrentColumnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.urgentrowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.emptyframeworkcomponent}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            onCellClicked={this.onCellRowClickedUrgent}
                                            headerHeight={this.state.headerHeight}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
                                            
                                        />
                                        
                                       
                                    </div> 
                                   
                                       
                                </div>
                    </div>
 	
                    <div className={"col-xl-12 col-lg-12 "+(this.state.onetimeshow)}>
                            <div >
                                <div id= "booking-ag-grid"  className="row">
                                    <div id="myGrid2" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={emptyColumnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.emptyrowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.emptyframeworkcomponent}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            onCellClicked={this.onCellRowClickedonetime}
                                            headerHeight={this.state.headerHeight}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
                                            
                                        />
                                        
                                       
                                    </div> 
                                   
                                       
                                </div>


                            </div>
                    </div>
                    {/* END */}

                    {/* Loaded Trucks Start */}
                    <div className={"col-xl-12 col-lg-12 "+(this.state.loadtruckshow)}>
						<div className=" card">                            
							<div className="row card-body" style={{padding:"10px 30px"}}>
						 
							    <div className="row col-xl-12 col-lg-12">
									<div className="col-xl-3 col-lg-3 form-group">
										<label>From Date</label>
                                        <Datetime
                                        value={this.state.startDateLoad} 
										disableCloseOnClickOutside={false} 
										closeOnSelect={true} 
										inputProps={{ placeholder: 'From', name: 'startDate', autoComplete:'off' }}
										dateFormat="YYYY-MM-DD" 
										name="startDate" 
										onChange={this.handlerStartDateTimeLoad.bind(this)} />
										
									</div>

									<div className="col-xl-3 col-lg-3 form-group">
										<label>To Date</label>
                                        <Datetime 
                                        value={this.state.endDateLoad} 
										disableCloseOnClickOutside={false}
										closeOnSelect={true} 
										inputProps={{ placeholder: 'To', name: 'endDate', autoComplete:'off'}} 
										dateFormat="YYYY-MM-DD" 
										onChange={this.handlerEndDateTimeLoad.bind(this)} />
										
									</div>
									<div className="form-group col-xl-3 col-lg-3">
										<button type="button" className="mt-30p btn btn-success" onClick={this.onClickLoadedTruckFilterData.bind(this)}>Submit</button>
									</div>
									<div className="col-xl-3 col-lg-3 form-group">
                                       
                                        {(this.state.loadtruckshow == "show-m")?
                                            <div className="col cirlce-d cursorPointer" style={{textAlign:"center",padding:"5px 0px 0px 0px", background:"#e6e6e6"}} onClick={this.onClickLoadedCounter.bind(this,"loadautomark")}>
                                                 Auto Marked By System
                                                <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.loaded_auto_marked_by_system.length > 0)?this.state.loaded_auto_marked_by_system.length:0}/></span></h4>
                                            </div>
                                        :""}
                                        {/*  */}
										
									</div>
								</div>
							</div>
							
						 </div>
					</div>

                    <div className={"col-xl-12 col-lg-12 "+(this.state.unloadtruckshow)}>
						<div className=" card">                            
							<div className="row card-body" style={{padding:"10px 30px"}}>
						 
							    <div className="row col-xl-12 col-lg-12">
									<div className="col-xl-3 col-lg-3 form-group">
										<label>From Date</label>
                                        <Datetime
                                        value={this.state.startDateLoad} 
										disableCloseOnClickOutside={false} 
										closeOnSelect={true} 
										inputProps={{ placeholder: 'From', name: 'startDate', autoComplete:'off' }}
										dateFormat="YYYY-MM-DD" 
										name="startDate" 
										onChange={this.handlerStartDateTimeLoad.bind(this)} />
										
									</div>

									<div className="col-xl-3 col-lg-3 form-group">
										<label>To Date</label>
                                        <Datetime 
                                        value={this.state.endDateLoad} 
										disableCloseOnClickOutside={false}
										closeOnSelect={true} 
										inputProps={{ placeholder: 'To', name: 'endDate', autoComplete:'off'}} 
										dateFormat="YYYY-MM-DD" 
										onChange={this.handlerEndDateTimeLoad.bind(this)} />
										
									</div>
									<div className="form-group col-xl-3 col-lg-3">
										<button type="button" className="mt-30p btn btn-success" onClick={this.onClickUnloadedTruckFilterData.bind(this)}>Submit</button>
									</div>
								</div>
							</div>
							
						 </div>
					</div>
                    
                    {/* End */}
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box">
                        <div className="loader-box">
                            <div className="rotate dashed colored"></div>
                        </div>
                    </div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
                <div className={"slide-r "+(this.state.slidertranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                            Upload Weekly Requirement
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
                        </h4>
                    </div>
                </div>
            
                <div className={"slide-r "+(this.state.bulkslide6)} style={{overflow:"hidden"}}>
                    <h3 className="subH">Edit One Time Pending Data:
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                    </h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            
                            <form method="post" onSubmit={this.onClickShowFilterData.bind(this)} className="row col-xl-12 col-lg-12">
                            <div className="form-group mt-20p col-xl-12 col-lg-12" style={{paddingLeft:"0px",paddingRight:"27px"}}>
                                    <label className="col-xl-12 col-lg-12">Booking Id* : </label>
                                    <input type="text" name="bookingId1" id="bookingId1" className="form-control" onChange={this.changeHandlerforbookingId} required />
                                </div>
                                <div className="form-group mt-10p">
                                    <label className="">Upload File</label> 
                                    <input type="file" name="uploadFile1" id ="uploadFile1" onChange={this.changeFileHandler1}  className="form-control" required  />
                                </div>
                                {/* <div className="col-xl-12 col-lg-12 row form-group mt-20p">
                                <label className="col-xl-12 col-lg-12">Shipping on Board : </label>
                                            <Select 
                                            placeholder={"Select Shipping on board"} 
                                            closeMenuOnSelect={true} 
                                            value={this.state.container_type} 
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            onChange={this.containertypechangeHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={[{ label: "Yes", value: "Yes" },
                                            { label: "No", value: "No" }]} required
                                        />
                                </div> */}
                                <div className="form-group col-xl-3 col-lg-3">
                                        <button  type="submit" className="btn btn-success">Submit</button>
                                    </div>
                            
                        </form>
                        </div>
                    </div>
                </div>



                <div className={"slide-r "+(this.state.bulkslide7)} style={{overflow:"hidden"}}>
            <h3 className="subH">Freight Forwarder Update
            <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
            </h3>
                     
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    
                    <form method="post" onSubmit={this.onClickSubmitSOBDataEdit.bind(this)} className="row col-xl-12 col-lg-12" id="shipping_on_board_2">
                        <div className="col-xl-12 col-lg-12 row form-group mt-20p">
                        <label className="col-xl-12 col-lg-12">Shipping on Board : </label>
                                    <Select 
                                    placeholder={"Select Shipping on board"} 
                                    closeMenuOnSelect={true} 
                                    value={this.state.container_type} 
                                    className={"col-xl-12 col-lg-12 border-radius-0"}
                                    onChange={this.containertypechangeHandler.bind(this)} 
                                    style={{borderRadius:"0px"}} 
                                    options={[{ label: "Yes", value: "Yes" },
                                    { label: "No", value: "No" }]} required
                                />
                        </div>
                        <div className="form-group mt-20p">
                                    <label className="">Upload File</label> 
                                    <input type="file" name="uploadFile3" id="uploadFile3" onChange={this.changeFileHandler3}  className="form-control" required  />
                                </div>
                        <div className="form-group col-xl-3 col-lg-3">
                                <button  type="submit" className="btn btn-success">Submit</button>
                            </div>
                    
                </form>
                </div>
            </div>
        </div>

        <div className={"slide-r "+(this.state.bulkslide10)} style={{overflow:"hidden"}}>
                    <h3 className="subH">Edit One Time Pending Data:
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                    </h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            
                            <form method="post" onSubmit={this.onClickShowFilterData.bind(this)} className="row col-xl-12 col-lg-12">
                            <div className="form-group mt-20p col-xl-12 col-lg-12" style={{paddingLeft:"0px",paddingRight:"27px"}}>
                                    <label className="col-xl-12 col-lg-12">Booking Id* : </label>
                                    <input type="text" name="bookingId1" id="bookingId1" className="form-control" onChange={this.changeHandlerforbookingId} required />
                                </div>
                                <div className="form-group mt-10p">
                                    <label className="">Upload File</label> 
                                    <input type="file" name="uploadFile1" id ="uploadFile1" onChange={this.changeFileHandler1}  className="form-control" required  />
                                </div>
                                {/* <div className="col-xl-12 col-lg-12 row form-group mt-20p">
                                <label className="col-xl-12 col-lg-12">Shipping on Board : </label>
                                            <Select 
                                            placeholder={"Select Shipping on board"} 
                                            closeMenuOnSelect={true} 
                                            value={this.state.container_type} 
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            onChange={this.containertypechangeHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={[{ label: "Yes", value: "Yes" },
                                            { label: "No", value: "No" }]} required
                                        />
                                </div> */}
                                <div className="form-group col-xl-3 col-lg-3">
                                        <button  type="submit" className="btn btn-success">Submit</button>
                                    </div>
                            
                        </form>
                        </div>
                    </div>
                </div>



                <div className={"slide-r "+(this.state.bulkslide11)} style={{overflow:"hidden"}}>
            <h3 className="subH">Freight Forwarder Update
            <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
            </h3>
                     
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    
                    <form method="post" onSubmit={this.onClickSubmitSOBDataEdit.bind(this)} className="row col-xl-12 col-lg-12" id="shipping_on_board_2">
                        <div className="col-xl-12 col-lg-12 row form-group mt-20p">
                        <label className="col-xl-12 col-lg-12">Shipping on Board : </label>
                                    <Select 
                                    placeholder={"Select Shipping on board"} 
                                    closeMenuOnSelect={true} 
                                    value={this.state.container_type} 
                                    className={"col-xl-12 col-lg-12 border-radius-0"}
                                    onChange={this.containertypechangeHandler.bind(this)} 
                                    style={{borderRadius:"0px"}} 
                                    options={[{ label: "Yes", value: "Yes" },
                                    { label: "No", value: "No" }]} required
                                />
                        </div>
                        <div className="form-group mt-20p">
                                    <label className="">Upload File</label> 
                                    <input type="file" name="uploadFile3" id="uploadFile3" onChange={this.changeFileHandler3}  className="form-control" required  />
                                </div>
                        <div className="form-group col-xl-3 col-lg-3">
                                <button  type="submit" className="btn btn-success">Submit</button>
                            </div>
                    
                </form>
                </div>
            </div>
        </div>

        <div className={"slide-r "+(this.state.bulkslide8)} style={{overflow:"hidden"}}>
					<h3 className="subH">Freight Forwarder File Upload
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                    </h3>
						 	
					<div className="slide-r-body" style={{position:"relative"}}>
						
						<div className="container-fluid">
							
                            <form method="post" onSubmit={this.onClickShowFilterData2.bind(this)} className="row col-xl-12 col-lg-12">
                                <div className="form-group mt-20p col-xl-12 col-lg-12" style={{paddingLeft:"0px",paddingRight:"27px"}}>
                                    <label className="col-xl-12 col-lg-12">Booking Id* : </label>
                                    <input type="text" name="bookingId2" id="bookingId2" className="form-control" onChange={this.changeHandlerforbookingId} required />
                                </div>
                                <div className="form-group mt-10p">
                                    <label className="">Upload File</label> 
                                    <input type="file" name="uploadFile2" id="uploadFile2" onChange={this.changeFileHandler2}  className="form-control" required  />
                                </div>
                                <div className="form-group">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>
                            
                        </form>	
						</div>
					</div>
				</div>

                <div className={"slide-r "+(this.state.bulkslide9)} style={{overflow:"hidden"}}>
                <h3 className="subH">Freight Forwarder Update
                <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                </h3>
                         
                <div className="slide-r-body" style={{position:"relative"}}>
                    
                    <div className="container-fluid">
                        
                        <form method="post" onSubmit={this.onClickShowFilterDataEdit.bind(this)} className="row col-xl-12 col-lg-12">
                            <div className="col-xl-12 col-lg-12 row form-group mt-20p">
                            <label className="col-xl-12 col-lg-12">Shipping on Board : </label>
                                        <Select 
                                        placeholder={"Select Shipping on board"} 
                                        closeMenuOnSelect={true} 
                                        value={this.state.container_type} 
                                        className={"col-xl-12 col-lg-12 border-radius-0"}
                                        onChange={this.containertypechangeHandler.bind(this)} 
                                        style={{borderRadius:"0px"}} 
                                        options={[{ label: "Yes", value: "Yes" },
                                        { label: "No", value: "No" }]} required
                                    />
                            </div>	
                            <div className="form-group mt-20p">
                                    <label className="">Upload File</label> 
                                    <input type="file" name="uploadFile4" id="uploadFile4" onChange={this.changeFileHandler4}  className="form-control" required  />
                                </div>
                            <div className="form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                        
                    </form>	
                    </div>
                </div>
            </div>

                {(this.state.pageType == 1)?
                <div className={"slide-r "+(this.state.bulkslide1)} style={{overflow:"hidden"}}>
                    <h3 className="subH">Freight Forwarder File Upload
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                    </h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            
                            <form method="post" onSubmit={this.onClickShowFilterData.bind(this)} className="row col-xl-12 col-lg-12" id="booking_form_1">
                            <div className="form-group mt-20p col-xl-12 col-lg-12" style={{paddingLeft:"0px",paddingRight:"27px"}}>
                                    <label className="col-xl-12 col-lg-12">Booking Id* : </label>
                                    <input type="text" name="bookingId1" id="bookingId1" className="form-control" onChange={this.changeHandlerforbookingId} required />
                                </div>
                                <div className="form-group mt-10p">
                                    <label className="">Upload File</label> 
                                    <input type="file" name="uploadFile1" id ="uploadFile1" onChange={this.changeFileHandler1}  className="form-control" required  />
                                </div>
                                {/* <div className="col-xl-12 col-lg-12 row form-group mt-20p">
                                <label className="col-xl-12 col-lg-12">Shipping on Board : </label>
                                            <Select 
                                            placeholder={"Select Shipping on board"} 
                                            closeMenuOnSelect={true} 
                                            value={this.state.container_type} 
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            onChange={this.containertypechangeHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={[{ label: "Yes", value: "Yes" },
                                            { label: "No", value: "No" }]} required
                                        />
                                </div> */}
                                <div className="form-group col-xl-3 col-lg-3">
                                        <button  type="submit" className="btn btn-success">Submit</button>
                                    </div>
                            
                        </form>
                        </div>
                    </div>
                </div>:
            <div className={"slide-r "+(this.state.bulkslide1)} style={{overflow:"hidden"}}>
            <h3 className="subH">Freight Forwarder Update
            <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
            </h3>
                     
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    
                    <form method="post" onSubmit={this.onClickSubmitSOBData.bind(this)} className="row col-xl-12 col-lg-12" id="shipping_on_board_2">
                        <div className="col-xl-12 col-lg-12 row form-group mt-20p">
                        <label className="col-xl-12 col-lg-12">Shipping on Board : </label>
                                    <Select 
                                    placeholder={"Select Shipping on board"} 
                                    closeMenuOnSelect={true} 
                                    value={this.state.container_type} 
                                    className={"col-xl-12 col-lg-12 border-radius-0"}
                                    onChange={this.containertypechangeHandler.bind(this)} 
                                    style={{borderRadius:"0px"}} 
                                    options={[{ label: "Yes", value: "Yes" },
                                    { label: "No", value: "No" }]} required
                                />
                        </div>
                        <div className="form-group col-xl-3 col-lg-3">
                                <button  type="submit" className="btn btn-success">Submit</button>
                            </div>
                    
                </form>
                </div>
            </div>
        </div>
                }

    {/* for weekly */}
                {(this.state.pageType == 1)?
                <div className={"slide-r "+(this.state.bulkslide2)} style={{overflow:"hidden"}}>
					<h3 className="subH">Freight Forwarder File Upload!
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                    </h3>
						 	
					<div className="slide-r-body" style={{position:"relative"}}>
						
						<div className="container-fluid">
							
                            <form method="post" onSubmit={this.onClickShowFilterData2.bind(this)} className="row col-xl-12 col-lg-12" id="booking_form_2">
                                <div className="form-group mt-20p col-xl-12 col-lg-12" style={{paddingLeft:"0px",paddingRight:"27px"}}>
                                    <label className="col-xl-12 col-lg-12">Booking Id* : </label>
                                    <input type="text" name="bookingId2" id="bookingId2" className="form-control"
                                     onChange={this.changeHandlerforbookingId}
                                      required />
                                </div>
                                <div className="form-group mt-10p">
                                    <label className="">Upload File</label> 
                                    <input type="file" name="uploadFile2" id="uploadFile2" onChange={this.changeFileHandler2}  className="form-control" required  />
                                </div>
                                <div className="form-group">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>
                            
                        </form>	
						</div>
					</div>
				</div>:
                <div className={"slide-r "+(this.state.bulkslide2)} style={{overflow:"hidden"}}>
                <h3 className="subH">Freight Forwarder Update
                <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                </h3>
                         
                <div className="slide-r-body" style={{position:"relative"}}>
                    
                    <div className="container-fluid">
                        
                        <form method="post" onSubmit={this.onClickSubmitSOBData2.bind(this)} className="row col-xl-12 col-lg-12" id="shipping_on_board_2">
                            <div className="col-xl-12 col-lg-12 row form-group mt-20p">
                            <label className="col-xl-12 col-lg-12">Shipping on Board : </label>
                                        <Select 
                                        placeholder={"Select Shipping on board"} 
                                        closeMenuOnSelect={true} 
                                        value={this.state.container_type} 
                                        className={"col-xl-12 col-lg-12 border-radius-0"}
                                        onChange={this.containertypechangeHandler.bind(this)} 
                                        style={{borderRadius:"0px"}} 
                                        options={[{ label: "Yes", value: "Yes" },
                                        { label: "No", value: "No" }]} required
                                    />
                            </div>	
                            <div className="form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                        
                    </form>	
                    </div>
                </div>
            </div>
                }

            {/* for uploading Bill of Lading document one time*/}

            <div className={"slide-r "+(this.state.urgentDownloadBulkSlide)} style={{overflow:"hidden"}}>
                    <h3 className="subH">Download Screen
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                    </h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            <ui className='card p-3 col-xl-11 col-lg-10'>
                            {bookingDownloadList && bookingDownloadList.length > 0 ? (
                                        bookingDownloadList.map((files, index) => (
                                            <li className='d-flex flex-row m-2 pl-2 pr-2 justify-content-between' key={index}>
                                                <h5 className="download-content-booking">{files.label}</h5>
                                                <button className='btn btn-success' style={{borderRadius:"3px"}} onClick={() => this.onClickUrgentDownloadBulkSlide(files.value)}>Download</button>
                                            </li>
                                        ))
                                    ) : (
                                        <li className='d-flex flex-row m-2 pl-2 pr-2 justify-content-between'>
                                        <h5 className="download-content-booking">There are no downloads available for this record</h5>
                                        </li>
                                    )}
                            </ui>
                        </div>
                    </div>
            </div>
            <div className={"slide-r "+(this.state.onetimeDownloadBulkSlide)} style={{overflow:"hidden"}}>
                    <h3 className="subH">Download Screen
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                    </h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            <ui className='card p-3 col-xl-11 col-lg-10'>
                            {bookingDownloadList && bookingDownloadList.length > 0 ? (
                                        bookingDownloadList.map((files, index) => (
                                            <li className='d-flex flex-row m-2 pl-2 pr-2 justify-content-between' key={index}>
                                                <h5 className="download-content-booking">{files.label}</h5>
                                                <button className='btn btn-success' style={{borderRadius:"3px"}} onClick={() => this.onClickOnetimeDownloadBulkSlide(files.value)}>Download</button>
                                            </li>
                                        ))
                                    ) : (
                                        <li className='d-flex flex-row m-2 pl-2 pr-2 justify-content-between' >
                                        <h5 className="download-content-booking">There are no downloads available for this record</h5>
                                        </li>
                                    )}
                            </ui>
                        </div>
                    </div>
            </div>
            <div className={"slide-r "+(this.state.weeklyDownloadBulkSlide)} style={{overflow:"hidden"}}>
                    <h3 className="subH">Download Screen
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                    </h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            <ui className='card p-3 col-xl-11 col-lg-10'>
                            {bookingDownloadList && bookingDownloadList.length > 0 ? (
                                        bookingDownloadList.map((files, index) => (
                                            <li className='d-flex flex-row m-2 pl-2 pr-2 justify-content-between' key={index}>
                                                <h5 className="download-content-booking">{files.label}</h5>
                                                <button className='btn btn-success' style={{borderRadius:"3px"}} onClick={() => this.onClickweeklyDownloadBulkSlide(files.value)}>Download</button>
                                            </li>
                                        ))
                                    ) : (
                                        <li className='d-flex flex-row m-2 pl-2 pr-2 justify-content-between'>
                                        <h5 className="download-content-booking">There are no downloads available for this record</h5>
                                        </li>
                                    )}
                            </ui>
                        </div>
                    </div>
            </div>
                <div className={"slide-r "+(this.state.bulkslide3)} style={{overflow:"hidden"}}>
                    <h3 className="subH">Freight Forwarder File Upload
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                    </h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            
                            <form method="post" onSubmit={this.onClickShowFilterData3.bind(this)} className="row col-xl-12 col-lg-12" id="bill_of_lading_3">
                                <div className="form-group mt-20p">
                                    <label className="">Upload File</label> 
                                    <input type="file" name="uploadFile3" id="uploadFile3" onChange={this.changeFileHandler3}  className="form-control" required  />
                                </div>
                                <div className="form-group col-xl-3 col-lg-3">
                                        <button  type="submit" className="btn btn-success">Submit</button>
                                    </div>
                            
                        </form>
                        </div>
                    </div>
                </div>
            

                {/* for uploading Bill of Lading document weekly*/}

            <div className={"slide-r "+(this.state.bulkslide4)} style={{overflow:"hidden"}}>
                    <h3 className="subH">Freight Forwarder File Upload
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                    </h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            
                            <form method="post" onSubmit={this.onClickShowFilterData4.bind(this)} className="row col-xl-12 col-lg-12" id="bill_of_lading_4">
                                <div className="form-group mt-20p">
                                    <label className="">Upload File*</label> 
                                    <input type="file" name="uploadFile4" id="uploadFile4" onChange={this.changeFileHandler4}  className="form-control" required  />
                                </div>
                                <div className="form-group col-xl-3 col-lg-3">
                                        <button  type="submit" className="btn btn-success">Submit</button>
                                    </div>
                            
                        </form>
                        </div>
                    </div>
                </div>

                {/* End */}
                <div className={"slide-r "+(this.state.bulkslide5)} style={{overflow:"hidden"}}>
                    <h3 className="subH">Ringi Data
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                    </h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            
                        <div className="row">
                                    <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnLoadedTrucksDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.unloadtrucksdata}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            // frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            onCellClicked={this.onCellRowClickedRingiData}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
                                            
                                        />
                                        
                                        {/* {console.log(this.state.unloadtrucksdata,"unloadtruckrowData")} */}
                                    </div> 
                                   
                                       
                                </div>
                        </div>
                    </div>
                </div>
                <div className={"slide-r "+(this.state.bulkslideforcontainer)} style={{overflow:"hidden"}}>
                        <h3 className="subH">
                                        Container Details
                            <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                        </h3>
                <div className="slide-r-body" style={{position:"relative"}}>
                    {(this.state.container_requirement.length > 0) ?                         
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <table className="table table-bordered tptinfo">
                                        <thead>
                                            <tr>
                                                <th>Container Size (in ft)</th>
                                                <th>Containers Required</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.container_requirement.map((e, index) => (
                                            <tr>
                                                <td>{e.container_size}</td>
                                                <td>{e.container_required}</td>
                                            </tr>
                                             ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    : ""}
                               
                </div>
            </div>
            
                <span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
                <Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
                    <div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                        <h5 className="model-title">Truck Availability</h5>
                        <div className="col-xl-12 col-lg-12 mt-20p">
                            <div className="col-xl-12 col-lg-12">
                                This screen helps user in identifying vehicles of various transporters that are near the point of origin and can be readily used for loading in MSIL as per requirement. This feature is available only for PRT dept as per the requirement raised.
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                A truck can be marked in following ways by transporters
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <ul>
                                    <li>
                                        1. Empty Near plant
                                    </li>
                                    <li>
                                        2. Marked for Arrival
                                    </li>
                                    <li>
                                        3. Marked for Repair
                                    </li>
                                </ul>
                            </div>
                            <h6 style={{fontWeight:"bold"}}>Empty Near plant</h6>
                            <div className="col-xl-12 col-lg-12">
                                Vehicles that are marked as empty near plant are those vehicles which are not carrying any MSIL consignments and are in the vicinity of approx. 100 kms. To check the list of empty vehicles following process is to be followed
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <ul>
                                    <li>1. Select the option “Empty Near Plant”</li>
                                    <li>
                                        2. Select the plant for which details are required
                                    </li>
                                    <li>
                                        3. Click on submit button
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                Image here
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                After clicking the submit button the use will get the details of various transporters whose vehicles are empty near selected plant in the format provided below
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                Image here
                            </div>

                            <h6 style={{fontWeight:"bold"}}>Marked for Arrival</h6>
                            <div className="col-xl-12 col-lg-12">
                                This screen provides details for all those vehicles which are available for arrival in MSIL and is ready for taking next load from various MSIL locations. To check vehicles that are marked for arrival are:
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <ul>
                                    <li>1. Select the option “Marked for arrival”</li>
                                    <li>
                                        2. Select the Time frame for which  data is required ( by default it is for current date)
                                    </li>
                                    <li>
                                        3. Click on submit button
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                Image here
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                After clicking on Submit button user will be able to check the list of vehicles transporter wise and its availability for MSIL near the various MSIL locations
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                Image here
                            </div>

                            <h6 style={{fontWeight:"bold"}}>Marked for Repair</h6>
                            <div className="col-xl-12 col-lg-12">
                            If a transporter marks any vehicle under “marked for repair”, those vehicles will be listed here and will be exempted from getting any new load until marked fit from transporter’s end. One can check list of vehicles that are marked as marked for repair by clicking option Marked for repair. The user will get the list of vehicles in following manner    
                            </div>
                           
                        </div>
                    </div>
                </Modal>    


            </div>
    
              	
		);
	}
}

window.onload = function (){

    var currentTime = new Date().getTime();
    var todayDate = new Date().getDate();
    var logic = function( currentDateTime ){
        // console.log("currentDateTime ", new Date(currentDateTime).getDate())
        // console.log("todayDate ", todayDate)
        var clickdate = new Date(currentDateTime).getDate()
        if( clickdate == todayDate ){
          this.setOptions({
            minTime:currentTime,
            timepickerScrollbar: true,
            scrollInput:true
          });
        }
        else
        {
            this.setOptions({
                minTime:"00:00",
                timepickerScrollbar: true,
                scrollInput:true
            });
        }
          
      };


	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('#tentitivetime').datetimepicker({
		mask:'9999-19-39 29:59',
        format:'Y-m-d H:i',
        formatTime:"H:i A",
        minTime:currentTime,
        onChangeDateTime:logic,
        onShow:logic,
        minDate : new Date(new Date().setDate(todayDate)),
        maxDate: new Date(new Date().setDate(todayDate + 2)),
        startDate:new Date(new Date().setDate(todayDate)),
        timepickerScrollbar: true,
        scrollInput:true
	});

	$(".styles_modal__gNwvD").css({width:"450px"})
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){
    var currentTime = new Date().getTime();
    var todayDate = new Date().getDate();
    var logic = function( currentDateTime ){
        // console.log("currentDateTime ", new Date(currentDateTime).getDate())
        // console.log("todayDate ", todayDate)
        var clickdate = new Date(currentDateTime).getDate()
        if( clickdate == todayDate ){
          this.setOptions({
            minTime:currentTime,
            timepickerScrollbar: true,
            scrollInput:true
          });
        }
        else
        {
            this.setOptions({
                minTime:"00:00",
                timepickerScrollbar: true,
                scrollInput:true
            });
        }
          
      };

    var todayDate = new Date().getDate();
	$('#tentitivetime').datetimepicker({
		mask:'9999-19-39 29:59',
        format:'Y-m-d H:i',
        formatTime:"H:i A",
        minTime:currentTime,
        onChangeDateTime:logic,
        onShow:logic,
        minDate : new Date(new Date().setDate(todayDate)),
        maxDate: new Date(new Date().setDate(todayDate + 2)),
        startDate:new Date(new Date().setDate(todayDate)),
        timepickerScrollbar: true,
        scrollInput:true
	});
	
    // var index  =window.document.getElementsByTagName("script")[1]
    // var script = window.document.createElement("script")
    // script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    // script.async=true
    // script.defer = true
    // index.parentNode.insertBefore(script,index)
}


function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDays(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}

function onSetCritical(params, status){
	//console.log("onSetCritical ", params);
	//console.log("onSetCritical value ", status);
	redirectURL.post('/consignments/setCriticalStatus',{
		params:params,
		is_critical:status,
		token:window.atob(localStorage.getItem("_t")),
		userId:localStorage.getItem("userid")
	})
		.then((response) => {
		
		//var records = JSON.parse(JSON.stringify(response)).data;
		console.log(response.data);
		});
		
}
$(document).ready(function(){
    $(document).on("click",".dropdown-truck-no",function(){
        var vals = $(this).text();
         $("#inputTruck").val(vals);
         $(".trucks-dropdown").removeClass("show-m");
         $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click",function(e){
        var container = $("#inputTruck");
        if(!container.is(e.target))
        {
            try{
                if($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown")
                {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            }catch(e){}
            
        }
        
        
    })
})

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
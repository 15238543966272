import React, { useEffect, useState } from "react";

const Mapcard2 = (props) => {

  console.log("Mapcard", props);
  const [state, setState] = useState({
    loadshow: "show-n",
    mapinfo: "",
    defTransitCoords: "",
    routeLatLngs: [],
    lastVesselLocation: "",
  });


  const areCoordinatesEqual = (coord1, coord2, tolerance = 1e-6) => {
    return (
      Math.abs(coord1.lat - coord2.lat) < tolerance &&
      Math.abs(coord1.lng - coord2.lng) < tolerance
    );
  };

  const initMap = () => {
    var currentwindow;
    var arr = props.seaRoute;
    var arr2 = props.landRoute;
    var arr3 = props.destination || [];

    if (arr && arr.length > 0) {
      var lt = arr[0].lat;
      var ln = arr[0].lng;
    } else {
      var lt = 28.4519751;
      var ln = 77.0310713;
    }

    var mapOptions = {
      zoom: 0,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: true,
      rotateControl: true,
      fullscreenControl: true,
      labels: true,
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
    };

    if (arr.length === 0) {
      mapOptions["center"] = new window.google.maps.LatLng(lt, ln);
      mapOptions.zoom = 3;
    }

    // Create a grayscale style
    var grayscaleStyle = [
      {
        elementType: "geometry",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      {
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#bdbdbd",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
          {
            color: "#eeeeee",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
          {
            color: "#e5e5e5",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
          {
            color: "#dadada",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [
          {
            color: "#e5e5e5",
          },
        ],
      },
      {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [
          {
            color: "#eeeeee",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            color: "#c9c9c9",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
    ];

    var map = new window.google.maps.Map(
      document.getElementById("map"),
      mapOptions
    );
    map.setOptions({ styles: grayscaleStyle });

    var bounds = new window.google.maps.LatLngBounds();
    var bounds2 = new window.google.maps.LatLngBounds();
    if (arr.length === 0) {
      bounds.extend(new window.google.maps.LatLng(0, 30));
      bounds.extend(new window.google.maps.LatLng(60, 105));
    }
    var currentinfowindow = null;

    if (arr.length > 0 && state.routeLatLngs.length === 0) {
      if (props.subMaps) {
        var line = new window.google.maps.Polyline({
          map: map,
          strokeColor: "#0059b3",
          strokeOpacity: 1.0,
          strokeWeight: 2.5,
          icons: [
            {
              icon: {
                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                strokeColor: "#4d4d4d",
                fillColor: "#4d4d4d",
                fillOpacity: 1,
                strokeWeight: 2,
              },
              repeat: "200px",
              path: [],
            },
          ],
        });
        for (let i = 0; i < arr.length; i++) {
          var path = line.getPath().getArray();

          let latLng = new window.google.maps.LatLng(arr[i].lat, arr[i].lng);
          path.push(latLng);
          line.setPath(path);

          window.google.maps.event.addListener(
            map,
            "maptypeid_changed",
            function () {
              if (
                map.getMapTypeId() == "hybrid" ||
                map.getMapTypeId() == "satellite"
              ) {
                var line = new window.google.maps.Polyline({
                  map: map,
                  strokeColor: "#0059b3",
                  strokeOpacity: 1.0,
                  strokeWeight: 2.5,
                  //   icons: require("../../assets/icons/boat-with-containers.png"),
                  icon: {
                    path: window.google.maps.SymbolPath.CIRCLE,
                    scale: 6,
                    fillColor: "#333333",
                    fillOpacity: 1,
                    strokeWeight: 0,
                  },
                });
              } else {
                var line = new window.google.maps.Polyline({
                  map: map,
                  strokeColor: "#0059b3",
                  strokeOpacity: 1.0,
                  strokeWeight: 2.5,
                  //   icons: require("../../assets/icons/boat-with-containers.png"),
                  icon: {
                    path: window.google.maps.SymbolPath.CIRCLE,
                    scale: 6,
                    fillColor: "#333333",
                    fillOpacity: 1,
                    strokeWeight: 0,
                  },
                });
              }

              for (i = 0; i < arr.length; i++) {
                var path = line.getPath().getArray();
                let latLng = new window.google.maps.LatLng(
                  arr[i].lat,
                  arr[i].lng
                );
                path.push(latLng);
                line.setPath(path);
              }

              var x = map.getZoom();
              var c = map.getCenter();
              window.google.maps.event.trigger(map, "resize");
              map.setZoom(x);
              map.setCenter(c);
            }
          );
          bounds.extend(new window.google.maps.LatLng(arr[i].lat, arr[i].lng));
          if (props.last_event_coord.length > 0) {
            let { lat, lng } = props.last_event_coord[0];
            if (arr[i].lat === lat && arr[i].lng === lng) {
              break;
            }
          }
        }
        var line2 = new window.google.maps.Polyline({
          map: map,
          strokeColor: "#bc6c25",
          strokeOpacity: 1.0,
          strokeWeight: 2.5,
          icons: [
            {
              icon: {
                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                strokeColor: "#4d4d4d",
                fillColor: "#4d4d4d",
                fillOpacity: 1,
                strokeWeight: 2,
              },
              repeat: "200px",
              path: [],
            },
          ],
        });
        var line3 = new window.google.maps.Polyline({
          map: map,
          strokeColor: "#a064ba",
          strokeOpacity: 1.0,
          strokeWeight: 2.5,
          icons: [
            {
              icon: {
                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                strokeColor: "#4d4d4d",
                fillColor: "#4d4d4d",
                fillOpacity: 1,
                strokeWeight: 2,
              },
              repeat: "200px",
              path: [],
            },
          ],
        });
        try {
          for (let i2 = 0; i2 < arr2.length; i2++) {

            var path2 = line2.getPath().getArray();

            let latLng = new window.google.maps.LatLng(arr2[i2].lat, arr2[i2].lng);
            path2.push(latLng);
            line2.setPath(path2);

            window.google.maps.event.addListener(
              map,
              "maptypeid_changed",
              function () {
                if (
                  map.getMapTypeId() == "hybrid" ||
                  map.getMapTypeId() == "satellite"
                ) {
                  var line2 = new window.google.maps.Polyline({
                    map: map,
                    strokeColor: "#bc6c25",
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    //   icons: require("../../assets/icons/boat-with-containers.png"),
                    icon: {
                      path: window.google.maps.SymbolPath.CIRCLE,
                      scale: 6,
                      fillColor: "#333333",
                      fillOpacity: 1,
                      strokeWeight: 0,
                    },
                  });
                } else {
                  var line2 = new window.google.maps.Polyline({
                    map: map,
                    strokeColor: "#bc6c25",
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    //   icons: require("../../assets/icons/boat-with-containers.png"),
                    icon: {
                      path: window.google.maps.SymbolPath.CIRCLE,
                      scale: 6,
                      fillColor: "#333333",
                      fillOpacity: 1,
                      strokeWeight: 0,
                    },
                  });
                }

                for (var i3 = 0; i3 < arr2.length; i3++) {
                  var path2 = line2.getPath().getArray();
                  let latLng = new window.google.maps.LatLng(
                    arr2[i3].lat,
                    arr2[i3].lng
                  );
                  path2.push(latLng);
                  line2.setPath(path2);
                }

                var x = map.getZoom();
                var c = map.getCenter();
                window.google.maps.event.trigger(map, "resize");
                map.setZoom(x);
                map.setCenter(c);
              }
            );
            bounds2.extend(new window.google.maps.LatLng(arr2[i2].lat, arr2[i2].lng));
          }
        }
        catch (e) { }
        try {
          for (let i2 = 0; i2 < arr3.length; i2++) {

            var path2 = line3.getPath().getArray();

            let latLng = new window.google.maps.LatLng(arr3[i2].lat, arr3[i2].lng);
            path2.push(latLng);
            line3.setPath(path2);

            window.google.maps.event.addListener(
              map,
              "maptypeid_changed",
              function () {
                if (
                  map.getMapTypeId() == "hybrid" ||
                  map.getMapTypeId() == "satellite"
                ) {
                  var line3 = new window.google.maps.Polyline({
                    map: map,
                    strokeColor: "#a064ba",
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    //   icons: require("../../assets/icons/boat-with-containers.png"),
                    icon: {
                      path: window.google.maps.SymbolPath.CIRCLE,
                      scale: 6,
                      fillColor: "#333333",
                      fillOpacity: 1,
                      strokeWeight: 0,
                    },
                  });
                } else {
                  var line3 = new window.google.maps.Polyline({
                    map: map,
                    strokeColor: "#a064ba",
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    //   icons: require("../../assets/icons/boat-with-containers.png"),
                    icon: {
                      path: window.google.maps.SymbolPath.CIRCLE,
                      scale: 6,
                      fillColor: "#333333",
                      fillOpacity: 1,
                      strokeWeight: 0,
                    },
                  });
                }

                for (var i3 = 0; i3 < arr3.length; i3++) {
                  var path2 = line3.getPath().getArray();
                  let latLng = new window.google.maps.LatLng(
                    arr3[i3].lat,
                    arr3[i3].lng
                  );
                  path2.push(latLng);
                  line3.setPath(path2);
                }

                var x = map.getZoom();
                var c = map.getCenter();
                window.google.maps.event.trigger(map, "resize");
                map.setZoom(x);
                map.setCenter(c);
              }
            );
            bounds2.extend(new window.google.maps.LatLng(arr3[i2].lat, arr3[i2].lng));
          }
        }
        catch (e) { }

        let lastCoordString = false;
        let currentLatLong = props.last_event_coord;
        let last_locations = { lat: "", lng: "" };
        if (currentLatLong.length > 0) {
          let { lat, lng } = currentLatLong[0];
          if (lat && lng) {
            lastCoordString = true;
            last_locations["lat"] = lat;
            last_locations["lng"] = lng;
          }
        }

        //for points
        arr.forEach((each, j) => {
          let loc2 = { lat: arr[j].lat, lng: arr[j].lng };
          let mappingCoord = `${arr[j].lat}${arr[j].lng}`;
          bounds.extend(new window.google.maps.LatLng(arr[j].lat, arr[j].lng));
          var marker;
          // var infowindow = new window.google.maps.InfoWindow();
          var outerMarker;

          let dest = arr.length - 1;

          if (j === 0) {
            //at origin
            if (areCoordinatesEqual(last_locations, loc2)) {
              //means last_coords are at origin
              marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(arr[j].lat, arr[j].lng),
                icon: require("../../assets/icons/cargo_ship.png"),
                map: map,
              });
            } else {
              marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(arr[j].lat, arr[j].lng),
                icon: require("../../assets/icons/source_icon.png"),
                map: map,
              });
            }
          } else if (j === dest) {
            //at dest
            if (areCoordinatesEqual(last_locations, loc2)) {
              //means last_coords are at origin
              marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(arr[j].lat, arr[j].lng),
                icon: require("../../assets/icons/cargo_ship.png"),
                map: map,
              });

              eventListerners(
                marker,
                mappingCoord,
                props,
                // infowindow,
                currentwindow,
                map
              );
            } else {
              marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(arr[j].lat, arr[j].lng),
                icon: require("../../assets/icons/destination_icon.png"),
                map: map,
              });
            }
          } else {
            if (areCoordinatesEqual(last_locations, loc2)) {
              //means last_coords are midpoints
              marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(arr[j].lat, arr[j].lng),
                icon: require("../../assets/icons/cargo_ship.png"),
                map: map,
              });
              eventListerners(
                marker,
                mappingCoord,
                props,
                // infowindow,
                currentwindow,
                map
              );
            }
          }

          // let latestCoords = props.mapinfo.last_event_coord;
        });

        arr2.forEach((each, j) => {
          let loc2 = { lat: arr2[j].lat, lng: arr2[j].lng };
          let mappingCoord = `${arr2[j].lat}${arr2[j].lng}`;
          bounds2.extend(new window.google.maps.LatLng(arr2[j].lat, arr2[j].lng));
          var marker;
          // var infowindow = new window.google.maps.InfoWindow();
          var outerMarker;

          let dest = arr2.length - 1;

          if (j === 0) {
            //at origin
            if (areCoordinatesEqual(last_locations, loc2)) {
              //means last_coords are at origin
              marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(arr2[j].lat, arr2[j].lng),
                icon: require("../../assets/icons/cargo_ship.png"),
                map: map,
              });
            } else {
              marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(arr2[j].lat, arr2[j].lng),
                icon: require("../../assets/icons/source_icon.png"),
                map: map,
              });
            }
          } else if (j === dest) {
            //at dest
            if (areCoordinatesEqual(last_locations, loc2)) {
              //means last_coords are at origin
              marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(arr2[j].lat, arr2[j].lng),
                icon: require("../../assets/icons/cargo_ship.png"),
                map: map,
              });

              eventListerners(
                marker,
                mappingCoord,
                props,
                // infowindow,
                currentwindow,
                map
              );
            } else {
              marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(arr2[j].lat, arr2[j].lng),
                icon: require("../../assets/icons/destination_icon.png"),
                map: map,
              });
            }
          } else {
            if (areCoordinatesEqual(last_locations, loc2)) {
              //means last_coords are midpoints
              marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(arr2[j].lat, arr2[j].lng),
                icon: require("../../assets/icons/cargo_ship.png"),
                map: map,
              });
              eventListerners(
                marker,
                mappingCoord,
                props,
                // infowindow,
                currentwindow,
                map
              );
            }
          }
        });
        if (Array.isArray(arr3)) {
        arr3.forEach((each, j) => {
          let loc2 = { lat: arr3[j].lat, lng: arr3[j].lng };
          let mappingCoord = `${arr3[j].lat}${arr3[j].lng}`;
          bounds2.extend(new window.google.maps.LatLng(arr3[j].lat, arr3[j].lng));
          var marker;
          // var infowindow = new window.google.maps.InfoWindow();
          var outerMarker;

          let dest = arr3.length - 1;

          if (j === 0) {
            //at origin
            if (areCoordinatesEqual(last_locations, loc2)) {
              //means last_coords are at origin
              marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(arr3[j].lat, arr3[j].lng),
                icon: require("../../assets/icons/cargo_ship.png"),
                map: map,
              });
            } else {
              marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(arr3[j].lat, arr3[j].lng),
                icon: require("../../assets/icons/source_icon.png"),
                map: map,
              });
            }
          } else if (j === dest) {
            //at dest
            if (areCoordinatesEqual(last_locations, loc2)) {
              //means last_coords are at origin
              marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(arr3[j].lat, arr3[j].lng),
                icon: require("../../assets/icons/cargo_ship.png"),
                map: map,
              });

              eventListerners(
                marker,
                mappingCoord,
                props,
                // infowindow,
                currentwindow,
                map
              );
            } else {
              marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(arr3[j].lat, arr3[j].lng),
                icon: require("../../assets/icons/destination_icon.png"),
                map: map,
              });
            }
          } else {
            // if (areCoordinatesEqual(last_locations, loc2)) {
              //means last_coords are midpoints
              // marker = new window.google.maps.Marker({
              //   position: new window.google.maps.LatLng(arr3[j].lat, arr3[j].lng),
              //   // icon: require("../../assets/icons/cargo_ship.png"),
              //   map: map,
              // });
              // eventListerners(
              //   marker,
              //   mappingCoord,
              //   props,
              //   // infowindow,
              //   currentwindow,
              //   map
              // );
            // }
          }
        });
      }
      } else {
        for (let i = 0; i < arr.length; i++) {
          bounds.extend(new window.google.maps.LatLng(arr[i].lat, arr[i].lng));
          var marker;
          // var infowindow = new window.google.maps.InfoWindow();
          marker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(arr[i].lat, arr[i].lng),
            icon: require("../../assets/icons/cargo_ship.png"),
            map: map,
          });

          let mappingCoord = `${arr[i].lat}${arr[i].lng}`;
          eventListerners(
            marker,
            mappingCoord,
            props,
            // infowindow,
            currentwindow,
            map
          );
        }
      }
    }

    if (arr.length === 1) {
      bounds.extend(
        new window.google.maps.LatLng(arr[0].lat + 20, arr[0].lng + 30)
      );
      bounds.extend(
        new window.google.maps.LatLng(arr[0].lat - 20, arr[0].lng - 30)
      );
    }
    map.fitBounds(bounds);
  };

  loadScript(
    "https://maps.googleapis.com/maps/api/js?key=AIzaSyA08YKZGPl0ii3K-t4_guTbdtjscGgdPns&libraries=places,drawing&callback=initMap"
  );
  window.initMap = initMap;

  return (
    <div className="counters-card card pb-0" style={{ borderRadius: "10px" , height:"100%"}}>
      <div
        id="map"
        className="card mx-0 border border-gray"
        style={{
          width: "100%",
          height: "100%",
          marginBottom: "0px",
          borderRadius: "10px",
        }}
      ></div>
      <div className="legend-overlay">
        <span className="baserut">Land Route</span>
        <span className="onwardrut">Sea Route</span>
      </div>
    </div>
  );
};

export default Mapcard2;

function loadScript(url) {
  var index = window.document.getElementsByTagName("script")[0];
  var script = window.document.createElement("script");
  script.src = url;
  script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}

function eventListerners(
  marker,
  mappingCoord,
  props,
  // infowindow,
  currentwindow,
  map
) {
  window.google.maps.event.addListener(
    marker,
    "mouseover",
    (function (marker) {
      return function () {
        // let mappingCoord = `${arr[i].lat}${arr[i].lng}`;
        if (props.geoLocShipmentsMap.hasOwnProperty(mappingCoord)) {
          let contentString =
            "<div class='row justify-content-between mx-0 px-2 pt-2 mr-2 txt-info'>" +
            props.geoLocShipmentsMap[mappingCoord] +
            "</div>";
          // infowindow.setContent(contentString);
          // currentwindow = infowindow;
          // infowindow.open(map, marker);
        }
      };
    })(marker)
  );

  window.google.maps.event.addListener(
    marker,
    "mouseout",
    (function (marker) {
      return function () {
        // infowindow.setContent("");
        // currentwindow = infowindow;
        // infowindow.open(map, marker);
      };
    })(marker)
  );
}

import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import $ from "jquery";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
window.jQuery = $;
window.$ = $;
var redirectURL = require("../redirectURL");
var infoBox = require("../common/google-infowindow");
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");
var moment = require("moment");
var globForecastDay="Today";
var markers = [];
export default class FleetAvailability extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      basicTitle: '',
      basicType: "default",
      overly: "show-n",
      loadshow: "show-n",
      sidebartitle: "",
      totalData: [],
      filterType: 'All',
      rowData: [],
      scrowData:[],
      sliderRouteTranslate: '',
      totalSumedUpValues: [],
      facility: "All Facilities",
      facilitiesList: [{ "label": "All Facilities", "value": "All" },
      { "label": "Haryana", "value": "H" },
      { "label": "Bidadi", "value": "BP" },
      { "label": "Gujarat", "value": "G" },
      ]
    }
  }

 async componentDidMount() {
  var transporterCode = window.atob(this.props.match.params.transporterCode)
    var screen = "user"
    console.log(transporterCode, 'transporterCode');
    this.setState({
      loadshow: "show-m",
      overly: "show-m"
    })

    await redirectURL.post("/weather/getWeatherData").then(async(response)=>{
        console.log(response.data);
        const uniqueDAta = [...new Set(response.data.ForecastData.map(item => item.description))];
        console.log(response.data.ForecastData.length,"uniqueDAta")
        
        this.setState({
          weatherData : response.data.ForecastData,
          statesSubDivisions: response.data.statesSubDivisions
        })
       await redirectURL.post("/weather/getPlants").then((response)=>{
          
          var path = window.location.href.split("//")[1].split("/")[0];
          const filteredPlants = response.data.filter(plant => plant.plant_name !== "TKAP-Dharuhera");

          this.setState({
              weatherPlants: filteredPlants,
              forecastDay: "Today",
              path: path,
              overly: "show-m",
              loadshow: "show-m"
          },this.renderMapWeather());
          
        })
        
      }).catch(function(error){
        console.log(error);
      })

     await redirectURL.post("/weather/getFloodData").then((response)=>{
          console.log(response.data);
          this.setState({
            floodData : response.data,
            overly: "show-m",
              loadshow: "show-m"
          })
      }).catch(function(error){
          console.log(error);
      })
        var params = {
          transporter_code: transporterCode,
          "screen": screen
        };
        let fleetNos={"TVP Banglore":0,"Gurgaon":0,"Manesar":0,"Gujarat":0,"TVP Siliguri":0,"TVP Nagpur":0,"Bidadi":0}
        await redirectURL.post("/dashboard/getstockDataPlantsForLSP",params).then((response)=>{
            console.log(response.data,'response.data')
          var data = JSON.parse(response.data.trucks_data);
          console.log(data,"182");
          setTimeout(() => {
           this.setState({
            markersArr:data
           })
            console.log(this.state.markersArr,'markersarr')
            this.trucksMarking()
          }, 5000);
          var newData=data.map((each)=>{
            if (each.plant_code==="BP"){
              if (fleetNos["Bidadi"]==0){
                fleetNos["Bidadi"]=1
              }else{
                fleetNos["Bidadi"]=fleetNos["Bidadi"]+1
              } 
            }else if (each.plant_code==="B"){
                if (fleetNos["TVP Banglore"]==0){
                  fleetNos["TVP Banglore"]=1
                }else{
                  fleetNos["TVP Banglore"]=fleetNos["TVP Banglore"]+1
                }
            }else if(each.plant_code==="F"){
              if (fleetNos["Gurgaon"]==0){
                fleetNos["Gurgaon"]=1
              }else{
                fleetNos["Gurgaon"]=fleetNos["Gurgaon"]+1
              }
          // }else if(each.plant_code==="M"){
          //   if (fleetNos["Manesar"]==0){
          //     fleetNos["Manesar"]=1
          //   }else{
          //     fleetNos["Manesar"]=fleetNos["Manesar"]+1
          //   }
        }else if(each.plant_code==="G"){
          if (fleetNos["Gujarat"]==0){
            fleetNos["Gujarat"]=1
          }else{
            fleetNos["Gujarat"]=fleetNos["Gujarat"]+1
          }
      }else if(each.plant_code==="I"){
        if (fleetNos["TVP Siliguri"]==0){
          fleetNos["TVP Siliguri"]=1
        }else{
          fleetNos["TVP Siliguri"]=fleetNos["TVP Siliguri"]+1
        }
    }else if(each.plant_code==="N"){
      if (fleetNos["TVP Nagpur"]==0){
        fleetNos["TVP Nagpur"]=1
      }else{
        fleetNos["TVP Nagpur"]=fleetNos["TVP Nagpur"]+1
      }
  }
          })
        
          console.log(fleetNos,"415")
      const newFleet=Object.entries(fleetNos)
        // var newData=data.map(each=>{
        //   if (each.railyard_name=="D22 Bengaluru (Bidadi)"){
        //     return ({...each,railyard_name:"Bidadi"})
        //   }
        //   return each
        // })
      
        console.log(newFleet,"192")
      
          this.setState({

            scrowData:newFleet,
            overly: "show-m",
            loadshow: "show-m"
      
        })
      
        
          
      })

  }
  renderMapWeather = () => {  
    // this.setState({
    //   overly: "show-n",
    //       loadshow: "show-n"
    // })  	
    loadScriptWeather("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMapWeather");
    window.initMapWeather = this.initMapWeather
   }

   trucksMarking = () =>{
   
    this.renderMapWeather();
   }

   initMapWeather = () => {

    try {
      var currentwindow;
      var map_type = window.google.maps.MapTypeId.HYBRID;
      var map = new window.google.maps.Map(document.getElementById('mapweather'), {
        zoom: 5,
        center: new window.google.maps.LatLng(23.581046, 78.776465),
        mapTypeId: map_type,
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: window.google.maps.ControlPosition.TOP_RIGHT
        },
        disableDefaultUI: true,
        fullscreenControl: true,
        zoomControl: true,
        panControl: false,
        // restriction: {
        //   latLngBounds: {
        //     north: 37.084107,
        //     south: 6.753515,
        //     east: 97.402561,
        //     west: 68.111378,
        //   },
        // },
      });
  
      var filterRecDate = "";
  
      if (globForecastDay == "Today") {
        filterRecDate = moment.parseZone(new Date()).format("DD-MM-YYYY");
      } else {
        filterRecDate = moment.parseZone(new Date(+new Date() + 86400000)).format("DD-MM-YYYY");
      }
  
      if (this.state.weatherPlants.length > 0 && this.state.detailedWeatherMap == 0) {
        this.state.weatherPlants.forEach(function (params) {
          var plant = {
            url: require("../../assets/icons/" + params.icon),
            scaledSize: new window.google.maps.Size(24, 24),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(0, 0)
          };
          var coords = JSON.parse(params.coordinates);
          var marker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(coords.lat, coords.lng),
            map: map,
            icon: plant,
            title: params.plant_name
          });
  
          var cityCircle = new window.google.maps.Circle({
            strokeColor: '#9fc9b9',
            strokeOpacity: 0.3,
            strokeWeight: 2,
            fillColor: '#9fc9b9',
            fillOpacity: 0.6,
            map: map,
            center: coords,
            radius: 50 * 1000
          });
        });
      }
  
      console.log(this.state.markersArr, "640")
      this.setState({
        overly: "show-n",
        loadshow: "show-n"
      });
  
    
  
      this.state.markersArr.map((each) => {
        // Check if plant_code is not "M"
        if (each.plant_code !== "M") {
          var marker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(each.latitude, each.longitude),
            map: map,
            icon: require("../../assets/icons/truck_null.png"),
            title: each.state,
          });
          markers.push(marker);
          var contentarr = [];
          var transporter_name;
          if(localStorage.getItem("email") === 'enmoviltesttpt@gmail.com'){
            transporter_name = "Test Transporter"
          }else{
            transporter_name  = each.transporter_name;
          }
          contentarr.push({ key: "Transporter Name", value: transporter_name });
          contentarr.push({ key: "State", value: each.state });
      
          var contentString = infoBox(marker.icon, each.truck_no, contentarr, "");
      
          var infowindow = new window.google.maps.InfoWindow({
            content: contentString,
          });
          marker.addListener("click", function () {
            infowindow.setContent(contentString);
            infowindow.open(map, marker);
          });
      
          marker.setMap(map);
          window.google.maps.event.addListener(marker, "mouseout", function () {
            infowindow.close();
          });
        }
      });
      // console.log(map,'===',marker)
      const markerCluster = new MarkerClusterer({ map, markers });
     
      // var bounds = new window.google.maps.LatLngBounds();
  
      // bounds.extend(new window.google.maps.LatLng(4.150481109730182, 66.41504834827121));
      // bounds.extend(new window.google.maps.LatLng(39.351600, 66.951691));
  
      // map.fitBounds(bounds);
  
    } catch (e) { }
  }

  onCloseRouteDiv = () => {
    this.setState({
      sliderRouteTranslate: "",
      showDiv: 'show-n',
      loadshow: "show-n",
      overly: "show-n",
    });
  }
  getFleets=(each)=>{
    // console.log(each,'each123')
    if (each[0] !== "Manesar") {
    
    return(
        <>
            <tr>
                <td style={{border:"solid 2px lightgrey",padding:"4px"}}>{each[0]}</td>
                {/* <td style={{border:"solid 2px lightgrey",padding:"4px"}}>{each.vehicle_stocks}</td> */}
                <td style={{border:"solid 2px lightgrey",padding:"4px"}}>{each[1]}</td>
            </tr>
        </>
    )
    }
  }
  render() {
    const dStyles={
        width:'100%',
        // marginLeft:'20px',
        paddingLeft:'20px',
        marginRight:'0px',
        paddingRight:'0px',
        height:'550px'
    }
   console.log(this.state.scrowData,'scrowData')
    return (
     <div className='row'>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        >
        </SweetAlert>
        <div style={{ textAlign: "center", background: "#171C8F", width: '100%', margin: "5px 11px 20px -12px", borderRadius: "5px", height: '70px', paddingBottom: '25px'}}>
          <h6 class="txt-dark f40" style={{ marginTop: "0px" }}>
            <span style={{ fontSize: "20px", color: "#FFFFFF" }}>
              <span>Fleet Availability in 50 km</span>

            </span>
          </h6>
        </div>
        <div className="col-xl-12 col-lg-12">
            <h5>
              <span className="float-right f12 ">
                <a className="btn btn-danger" style={{ float: "left", marginLeft: "10%" }} href="/lspceodashboardTest">Back</a>
              </span>
            </h5>
          </div>

          <div className="col-xl-12 col-lg-12" style={{marginRight:"0px",paddingRight:"0px"}}>
           
           {/* <span style={{fontSize:"16px",fontWeight:"bold",textDecoration:"underline",textDecoration:"underline",marginLeft:"20px"}}>Fleet Status: MSIL Loading Locations:</span> */}
                       {/* <span style={{fontSize:"16px",color:"#171C8F",textDecoration:"none",fontWeight:"bold",marginLeft:"120px"}}>
                       Last Data Updated On {this.state.updated_time}
                               </span> */}
                           <div class="col-xl-12 col-sm-12 col-xs-12 col-md-12 row wdiv" style={{marginRight:"0px",paddingRight:"0px"}}>
                           <div id="mapweather" className="col-lg-12 col-xl-12" style={dStyles}></div>
                           </div>
                           <div style={{width:'195px',position:"absolute",bottom:"100px",marginLeft:"30px",backgroundColor:"white",fontSize:"15px",border:"0.5px solid dark grey"}}>
                             
                                <tabel className="tabel text-center">
                                   <tbody>
                                       <tr>
                                           <th style={{border:"solid 2px lightgrey",padding:"4px"}}>MSIL Locations</th>
                                           <th  style={{border:"solid 2px lightgrey",padding:"4px"}}>Fleet Nos.</th>
                                       </tr>
                                       <>
                                       {(this.state.scrowData).map((each)=>this.getFleets(each))}
                                       
                                      
                                   </>
                                   </tbody>
                               </tabel>
                           </div>
                           
                         </div>

        <div className={"dataLoadpage " + (this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
          <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part " + (this.state.overly)} onClick={this.onCloseRouteDiv.bind(this)}></div>


      </div>


    );
  }
}
function loadDateTimeScript() {
    // alert("timeDate");
    $(".datetimepicker_mask").datetimepicker({
      //mask:'39-19-9999 29:59',
      format: "d-m-Y",
    });
    $(".datetimepicker_date").datetimepicker({
      mask: "39-19-9999",
      format: "d-m-Y",
      timepicker: false,
    });
    var index = window.document.getElementsByTagName("script")[1];
    var script = window.document.createElement("script");
    script.src =
      "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js";
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true;
    script.defer = true;
    index.parentNode.insertBefore(script, index);
  }
function loadScriptWeather(url){
    console.log(window.screen.availHeight,
    window.screen.availWidth)
    if(window.screen.availHeight <= 1024)
    {
      $("#mapweather").removeClass("col-md-8");
      $("#weathermapgrid").removeClass("col-md-4");
      $("#mapweather").addClass("col-md-8");
      $("#weathermapgrid").addClass("col-md-8");
    }
    var index  =window.document.getElementsByTagName("script")[1]
      var script = window.document.createElement("script")
      script.src=url
      script.async=true
      script.defer = true
      index.parentNode.insertBefore(script,index)
  }
import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import Select from 'react-select';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import DrawMap from '../common/drawmap';
import CSVFileValidator from 'csv-file-validator'
import Updatemllbatchvin from "./updatemllbatchvin";
import $ from 'jquery';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenDDMMYYYY } from '../common/utils'; 
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var CELL_DIMENSION_SIZE = 90;
var googleAnalytics = require("../common/googleanalytics");

export default class MllMarkArrivalLoadingYard extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			pageTitle: "MLL Batch Vins",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            show1: false,
            basicTitle1:'',
            basicType1:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
			originalrowData: [],
			selectedTabLat:'',
			selectedTabLng:'',
			cluster_centroids: [],
			deftab:'all',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			frameworkComponents : {
                updatemllbatchvin:Updatemllbatchvin
            },
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:100,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
			overly : "show-n",
            rowData : [],
            batchno:""
        };
        this.onLoadShowBatchVins = this.onLoadShowBatchVins.bind(this);
        this.onClickUpdateItem = this.onClickUpdateItem.bind(this);
        this.changeFileHandler = this.changeFileHandler.bind(this);
        this.onClickShowUpload = this.onClickShowUpload.bind(this);
    }
    
    componentDidMount(){
        this.logPageView(); // Log page view to GA
        var batch = this.props.match.params
        if(this.props.match.params.refno != undefined)
        {
            var refno = this.props.match.params.refno
        }
        else{
            var refno = "";
        }
        this.setState({
            refno:refno
        })

        var parameters = {
            refno : refno
        }

        this.onLoadShowBatchVins(parameters);
    }

    onLoadShowBatchVins(parameters){
        redirectURL.post("/consignments/masterbatchvins",parameters)
        .then((response) => {
            console.log("response" , response.data)
            var records = response.data.records
            this.setState({
                rowData:records
            })
        })
    }
	/*Alert Popups*/
	closeAlert = async () => {
        await this.setState({
            show: false
        });
        await window.location.reload()
    }

	closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        // await window.location.reload()
    }
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    
    
	onClickHideAll(){
		this.setState({
			overly : "show-n",
            sliderStateEmailsTranslate : "",
            sliderRouteTranslate:"",
            sliderRakeTranslate:""
			
		})
	}
	
   onGridReady = params => {
		this.gridApi = params.api;
		
		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
		
    };
    
    onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderRakeTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
    }
    
    onClickUpdateItem(){
        let records = [];
        this.gridApi.forEachNode(node => records.push(node.data));
        console.log("parmas ", records)
                    
        // var check = records.map(function(e){
        //     // if(e.loading_yard_reached_on.toString().includes(" "))
        //     // {
        //         var loading_yard_reached = moment.parseZone(new Date(e.loading_yard_reached_on)).format("DD-MMM-YYYY HH:mm")
        //     // }
        //     // else
        //     // {
        //     //     var loading_yard_reached = getHyphenDDMMMYYYYHHMM(e.loading_yard_reached_on)
        //     // }
        //     var parameters = {
        //         batchno: e.batchno,
        //         rowid: e._id,
        //         loading_yard_reached_on: loading_yard_reached,
        //         username:localStorage.getItem("username"),
        //         email:localStorage.getItem("email"),
        //         userid:localStorage.getItem("userid"),
        //     }
        //     // console.log(parameters.loading_yard_reached_on)
        //     redirectURL.post("/consignments/updatemllbatchvinarrivalloadingyard",parameters)
        //     .then((response) => {
                
        //     })
        // })
        // Promise.all([check]).then(()=>{
        //     // if(response.data.status == "Success")
        //     // {
        //         this.setState({
        //             show:true,
        //             basicTitle:"Successfully update data",
        //             basicType:"success"
        //         })
        //         var parameters = {
        //             batchno : this.state.batchno
        //         }
        //         this.onLoadShowBatchVins(parameters);
        //     // }
        // })
        
        var vins = []
        var sflag = 0
        if(records.length > 0)
        {
            records.map((item) => {
                var d = moment.parseZone(new Date(item.loading_yard_reached_on+":00")).format("YYYY-MM-DD HH:mm:ss")
                // console.log("DDD ", d)
                var d1 = moment.parseZone(item.ewaybilldate).format("YYYY-MM-DD HH:mm:ss")
                // console.log("DDD1 ", d1)
                
                var s1 = moment.parseZone(d1).format("x")
                // console.log("s1 ", s1)
                // console.log("DDD1 ", d1)
                
                var s2 = moment.parseZone(d).format("x")
                // console.log("s2 ", s2)
                // console.log("DDD ", d)
                if(s2 >= s1)
                {

                }
                else
                {
                    sflag = 1
                }
                if(item.loading_yard_reached_on != "" && item.loading_yard_reached_on != undefined && item.loading_yard_reached_on != "1970-01-01 00:00:00.000Z")
                {
                    vins.push(item.vehicle_id)
                }
                
            })
        }
        // console.log("vins ", vins)
        if(sflag == 0)
        {
            var parameters = {
                rowitems:records,
                vins:vins,
                username:localStorage.getItem("username"),
                email:localStorage.getItem("email"),
                userid:localStorage.getItem("userid"),
            }
            // console.log("parameters ", parameters)
            if(vins.length > 0)
            {
                redirectURL.post("/mllconsignments/updatemllbatchvinarrivalloadingyard",parameters)
                .then((response) => {
                    // console.log("response ", response.data)
                    if(response.data.status == "success")
                    {
                        this.setState({
                            show:true,
                            basicTitle:"Successfully update records",
                            basicType:"success"
        
                        })
                    }            
                    else
                    {                
                        this.setState({
                            show:true,
                            basicTitle:"Failed to update records",
                            basicType:"danger"
        
                        })
                    }
                })
        
            }
           else{
                this.setState({
                    show1:true,
                    basicTitle1:"Please enter date and time",
                    basicType1:"danger"
    
                })
           }
        }
        else{
            this.setState({
                show1:true,
                basicTitle1:"Vins loading yard reached on should be greater than eway bill date and time",
                basicType1:"danger"

            })
        }
        
        
    }
    onClickShowUpload(){
        this.setState({
            sliderRouteTranslate:"slider-translate",
            overly:"show-m"
        })
    }

    formBulkUpdate(event){
		event.preventDefault()
		// xlsxurl();
		//jszipurl();
		// var formdata = new FormData();
		// // console.log("this.state.vinsfile[0] ", this.state.vinsfile)
		// formdata.append("Files",this.state.vinsfile)
		// formdata.append("email",localStorage.getItem("email"))
		// formdata.append("username",localStorage.getItem("username"))
		// formdata.append("userid",localStorage.getItem("userid"))
		// console.log("Formdata ", formdata)
        // var urlpath = "http://164.52.197.10/readExcelFile";
        
        var params = {
            csvcontent:this.state.csvcontent,
            userid:localStorage.getItem("userid"),
            username:localStorage.getItem("username"),
            email:localStorage.getItem("email")
        }

        redirectURL.post("/mllconsignments/mllreadloadingarrivalcsv",params)
        .then((response) => {
            // console.log("response ", response.data)
            if(response.data.gvins.length > 0)
            {
                this.setState({
                    show:true,
                    basicTitle:"Vins loading yard reached on should be greater than eway bill date and time",
                    basicType:"danger"
                })
            }
            else if(response.data.notexists.length > 0)
            {
                this.setState({
                    show:true,
                    basicTitle:"Some vins are not exists",
                    basicType:"danger"
                })
            }
            else if(response.data.notvalid.length > 0)
            {
                this.setState({
                    show:true,
                    basicTitle:"Invalid Date format",
                    basicType:"danger"
                })
            }
            else
            {
                var urlpath = "/mllconsignments/mllconsignmentsbulkupdate";
                redirectURL.post(urlpath,params)
                .then((response) => {
                    console.log("response ", response.data)
                    if(response.data.notvalid.length > 0)
                    {
                        this.setState({
                            sliderRouteTranslate:"",
                            overly:"show-n",
                            vinsfile:"",
                            show:true,
                            basicTitle:"Vehicle Ids are not exists",
                            basicType:"danger"

                        })
                        // document.getElementById("updateForm").reset();
                        var parameters = {
                            batchno : this.state.batchno
                        }
                
                        this.onLoadShowBatchVins(parameters);
                    }
                    else if(response.data.status == "Success")
                    {
                        this.setState({
                            sliderRouteTranslate:"",
                            overly:"show-n",
                            vinsfile:"",
                            show:true,
                            basicTitle:"Successfully update data",
                            basicType:"success"

                        })
                        // document.getElementById("updateForm").reset();
                        var parameters = {
                            batchno : this.state.batchno
                        }
                
                        this.onLoadShowBatchVins(parameters);

                    }
                
                    else
                    {
                        this.setState({
                            show:true,
                            basicTitle:"Failed update data",
                            basicType:"danger"

                        })
                    }
                })
            }
        })
		
    }

    
	changeFileHandler = async (e) => {
		// this.setState({
		// 	vinsfile:e.target.files[0]
		// });
        const config = {
			headers: [
				{ 
					name: 'vin_no',
					inputName: 'vin_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'loading_yard_reached_on',
					inputName: 'loading_yard_reached_on',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
    }
    
    render(){
        
		var columnwithDefs= [
            {
                headerName:"Batch No",
                field:"batchno",
                width:150,
                resizable:true,
                editable:false
            },
            {
                headerName:"VIN No",
                field:"vehicle_id",
                width:150,
                resizable:true,
                editable:false
            },
            {
                headerName:"Loading Yard",
                field:"loadingyard",
                width:150,
                resizable:true,
                editable:false
            },
            {
                headerName:"Mode Type",
                field:"modetype",
                width:120,
                resizable:true,
                editable:false
            },
            {
                headerName:"Eway Bill Date",
                field:"ewaybilldate",
                width:150,
                resizable:true,
                editable:false,
                valueGetter:function(params){
                    if(params.data.ewaybilldate != "" && params.data.ewaybilldate != undefined)
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.ewaybilldate);
                    }
                    else
                    {
                        return "";
                    }
                }
            },
            {
                headerName:"Loading Yard Reached On",
                field:"loading_yard_reached_on",
                width:180,
                resizable:true,
                editable:true,
                cellEditor:DateEditor,
                comparator: dateComparator1,
                valueGetter:function(params){
                    if(params.data.loading_yard_reached_on != "" &&
                     params.data.loading_yard_reached_on != null
                      && params.data.loading_yard_reached_on != undefined
                      && params.data.loading_yard_reached_on != "2000-01-01T00:00:00.000Z")
                    {
                        // console.log(params.data.loading_yard_reached_on)
                        if(params.data.loading_yard_reached_on.includes("T") || params.data.loading_yard_reached_on.includes("/"))
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.loading_yard_reached_on);
                        }else
                        {
                            // console.log("editeed")
                            var editeddate = params.data.loading_yard_reached_on;
                            // console.log(editeddate)
                            editeddate = moment.parseZone(editeddate).utcOffset("+05:30")._i;
                            // console.log(editeddate)
                            return editeddate
                        }
                    }
                    else
                    {
                        return "";
                    }
                }
            },
            // {
            //     headerName:"Update",
            //     field:"_id",
            //     width:140,
            //     resizable:true,
            //     editable:false,
            //     cellRendererSelector:function(params){
            //         return {
            //             component:"updatemllbatchvin"
            //         }
            //     }
            // }
        ];

        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
            
            <SweetAlert
	            show={this.state.show1}
	            type={this.state.basicType1}
	            title={this.state.basicTitle1}
	            onConfirm={this.closeAlert1}
	        >
	        </SweetAlert>
            <div className="card col-xl-12">
                <div className="card-header">
                    <h5>
                        <i className="icon-map cus-i"></i> <span> MLL Batch Vins</span>
                        <button type="button" onClick={this.onClickShowUpload} className="float-right btn btn-warning">Bulk Update</button>
                        <button type="button" onClick={this.onClickUpdateItem} className="float-right btn btn-info">Update</button>         

                    </h5>
                </div>
                <div className="card-body col-xl-12 col-lg-12">
                    <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham"}>    
                        <AgGridReact
                            modules={this.state.modules}
                            columnDefs={columnwithDefs}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.rowData}
                            enableCharts={false}
                            // autoGroupColumnDef={this.state.autoGroupColumnDef}
                            onGridReady={this.onGridReady}
                            onGridState={this.onGridState}
                            frameworkComponents={this.state.frameworkComponents}
                            statusBar={this.state.statusBar}
                            sideBar={this.state.sideBar}
                            stopEditingWhenGridLosesFocus= {true}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                            gridOptions={{
                                context: { componentParent: this }
                            }}
                            enableRangeSelection= {true}
                        />
                        {/* <button type="button" className="btn btn-warning">Update All</button> */}
                    </div>
                </div>
            </div>

            
            <div className={"dataLoadpage " +(this.state.loadshow)}>
            </div>
            <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
			
            {(this.state.sliderRouteTranslate != "")?
                
				<div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                        Upload
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                        <form method="POST" id="updateForm" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formBulkUpdate.bind(this)}>
                            <div className="form-group mt-20p">
                                <label className="">Upload File</label> 
                                <input type="file" name="uploadFile" onChange={this.changeFileHandler.bind(this)}  className="form-control" required  />
                            </div>
                            <div className="col-xl-12 col-lg-12 form-group">
                                <button type="submit" className="btn btn-success">Submit</button>
                            </div>
                        </form>

                            <div className="col-xl-12 col-lg-12 form-group">
                                <a href={require("../../assets/json/sample_bulk_mll_vins_update.csv")} target="_blank" className="btn btn-warning">Download Template</a>
                            </div>
                            
                            <div className="col-xl-12 col-lg-12 form-group">
                                <b>Note:</b> Date format should be as follows: <b style={{color:"#ff0000"}}>MM/DD/YYYY HH:mm:ss</b>
                            </div>
                        </div>
                    </div>
                </div>
            :""}
        </div>
              
        );
    }
}


function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd hh:mm",
        changeMonth: true,
        changeYear: true,
        timepicker: true,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};


function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}

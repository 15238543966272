
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const ImportsCoilsInfoReceivedAction = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onShowConsignmentExportsEventsClick(props);
    };
    

    // if(props.data.port_delay == 1)
    // {
    //     var colr = 'redfont'
    // }
    // else{
    //     if(props.data.last_status == 'Arrived at Indian Port' || props.data.last_status == 'Port In')
    //    {
    //         var colr = 'greenfont'
    //    }
    //    else{
    //         var colr = 'grayfont'
    //    }
    // }
    let colr = 'grayfont';
    if(props.data.status == 1){
        colr = 'greenfont';
    }
    return (
        <div>
            <button onClick={handleEventClick} className="custom-btn f22 label label-success" 
            title="High Seas" ><i className={"icofont icofont-ship "+(colr)}></i> &nbsp;</button>
            
        </div>
    );
};

export default ImportsCoilsInfoReceivedAction;

import React, { Component } from 'react';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import { TabContent, TabPane, Nav, NavItem, NavLink,Row, Col } from 'reactstrap';
import classnames from 'classnames';

import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import LspDashboard from './lspcarrierdocumentstab1'
import LspDocDashboardData from './lspdocumentdetailstab2'
// import MulAddLspDashboardDoc from './expform'
$.datetimepicker.setLocale('en');    
var googleAnalytics = require("../common/googleanalytics");

export default class lspCarrierTabs extends Component{

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			active_tab: '1',
            active_tab_bottom_left: '1',
            active_tab_icon: '1',
            active_tab_right_align: '1',
            active_tab_vertical: '1',
            active_tab_pill: '1',
            active_tab_pill_icon: '1',
            active_tab_dark_color: '1',
            active_tab_primary: '1',
            active_tab_secondary: '1',
			isRoleExists:0,
			adaniCheck:0,
            
        };
        
		this.toggle = this.toggle.bind(this);
	}

    logPageView = () => {
        try {
        if (googleAnalytics.page.enableGA) {
            googleAnalytics.logPageView();
            let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
            let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
            this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction,    eventFormAction: eventFormAction});
        } else {
            console.log("GA disabled...");
        }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
        componentDidMount(){
            this.logPageView(); // Log page view to GA
        // console.log("tpt_otheroem ", localStorage.getItem("roles"))
        // try{
        //     if(localStorage.getItem("roles").includes("tpt_otheroem") == true)
        //     {
        //         this.setState({
        //             isRoleExists:1
        //         })
        //     }
        //     else
        //     {   
        //         this.setState({
        //             isRoleExists:0
        //         })
        //     }
        // }
        // catch(e){

        // }
        // var tptCode = localStorage.getItem('transportercode');
        // if(tptCode.includes("T293") == true)
        // {
        //     var adaniCheck = 1;
        // }
        // else
        // {
        //     var adaniCheck = 0;
        // }
        // this.setState({
        //     adaniCheck : adaniCheck
        // });
    }

	toggle(tab) {
        if (this.state.active_tab !== tab) {
            this.setState({
                active_tab: tab
            });
        }

    }

    render(){
		
        return (
            
    		<div className="container-fluid">
				
            	<div className="row">
                    <div className="col-xl-12 col-lg-12">
							
                        <Nav tabs className={'tabs-color'}>
                            <NavItem >
                                <NavLink
                                    className={classnames({ active: this.state.active_tab === '1' })}
                                    onClick={() => { this.toggle('1'); }} >
                                        Upload Summary                                 
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.active_tab === '2' })}
                                            onClick={() => { this.toggle('2'); }} >
                                    LSP Document Details
                                </NavLink>
                            </NavItem>                           
                        </Nav>

                        <TabContent activeTab={this.state.active_tab}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col sm="12">
                                        <LspDashboard />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col sm="12">
                                        <LspDocDashboardData />
                                    </Col>
                                </Row>
                            </TabPane>
                           
                        </TabContent>
                    </div>	
				 </div>

            </div>
              
        );
    }
} 
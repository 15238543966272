import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import CountUp from 'react-countup';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

var redirectURL = require('../redirectURL');
var moment = require('moment');

export default class RailshareGrid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            frameworkComponents : {
            },
            statusBar: {
                statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                },
                {
                  statusPanel: "agTotalRowCountComponent",
                  align: "center"
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize:200,
            sideBar: {
                toolPanels: [
                  {
                  id: "columns",
                  labelDefault: "Columns",
                  labelKey: "columns",
                  iconKey: "columns",
                  toolPanel: "agColumnsToolPanel"
                  },
                  {
                  id: "filters",
                  labelDefault: "Filters",
                  labelKey: "filters",
                  iconKey: "filter",
                  toolPanel: "agFiltersToolPanel"
                  }
                ]
            },
            animateRows: true,
            rowData: null,            
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single" ,
            context: { componentParent: this },
            frameworkComponents: {
               
            },
            ///usergridstate:[],
            screenurl:"",
            screentitle:"",
            columnwithDefs:[],
            totalVins:0,
            totalPlans:0,
        };
        // this.createColumns = this.createColumns.bind(this);
        // this.createRowData = this.createRowData.bind(this);
        
    }
    componentDidMount =async() =>{
        // var clusterShareDf = this.props.clusterShareDf;
        // var totalVins = 0;
        // clusterShareDf.map(function(c){
        //     totalVins = parseInt(totalVins) + parseInt(c.vins_loaded)
        // })
        // this.setState({
        //     totalVins : totalVins,
        //     totalPlans : clusterShareDf.length
        // });
    }
    componentWillReceiveProps(newProps){
        // var clusterShareDf = this.props.clusterShareDf;
        // var totalVins = 0;
        // clusterShareDf.map(function(c){
        //     totalVins = parseInt(totalVins) + parseInt(c.vins_loaded)
        // })
        // this.setState({
        //     totalVins : totalVins,
        //     totalPlans : clusterShareDf.length
        // });
    }
    
	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	};
	

	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		    
		  
		   
     }
     
    
    render(){
    	var columnwithDefs  = [
            {
                headerName: "Plant",
                field: "origin",
                width:130,
                filter: true,
                resizable: true,           
            },
            {
                headerName: "Date",
                field: "date",
                width:130,
                filter: true,
                resizable: true,
                valueGetter: function(params){
                    if(params.data.date !=undefined && params.data.date !='')
                    {
                        return moment.parseZone(params.data.date).format("DD-MM-YYYY")
                    }
                }           
            },
            {
                headerName: "Cluster",
                field: "destination",
                width:130,
                filter: true,
                resizable: true,           
            },
            {
                headerName: "Vins",
                field: "vins_loaded",
                width:130,
                filter: true,
                resizable: true,           
            },
            {
                headerName: "Transporter Code",
                field: "transporter_code",
                width:130,
                filter: true,
                resizable: true,           
            },
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width:250,
                filter: true,
                resizable: true,           
            },
        ]
        
        return ( 
        		<div className="row">
                    <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                        <div className="card">
                            <div className="card-body n-p-0">
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        <div className="col cirlce-d">
                                            <span className="f13">Total Vins</span>
                                            <h5 className="txt-info f35 mt-20p">
                                                <span className="counter">
                                                    <CountUp end={(this.props.totalVins)?this.props.totalVins:0}/>
                                                </span>
                                            </h5>
                                            
                                        </div>

                                        <div className="col cirlce-d">
                                            <span className="f13">Total Plans</span>
                                            <h5 className="txt-info f35 mt-20p">
                                                <span className="counter">
                                                    <CountUp end={(this.props.clusterShareDf.length)?this.props.clusterShareDf.length:0}/>
                                                </span>
                                            </h5>
                                            
                                        </div>

                                    </div>                                
                                </div>
                            
                            </div>
                        </div>
                    </div>
                
                    <div className="col-xl-12 col-lg-12">
                        {/* <p>Note: Assuming 10 NMG's are planned for a month</p> */}
                        <div id="myGrid" style={{ width: "100%",height: "500px"}}  className="ag-theme-balham">
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.props.clusterShareDf}
                                enableCharts={true}
                                enableRangeSelection={true}
                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                            />
                        </div>
                    </div>
      		    </div>
                    
			);
		}
}
















import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { WidthProvider, Responsive } from "react-grid-layout";
import axios from 'axios';
import redirectURL from '../redirectURL';
import $ from "jquery";
import SweetAlert from 'react-bootstrap-sweetalert';
import input from 'material-ui/svg-icons/action/input';
import Select from 'react-select';
import Modal from 'react-responsive-modal';
import CreatableSelect from 'react-select/creatable';
import RailFinancialDelayCompenseChart from './railfinancialdelaycompenschart';

var googleAnalytics = require("../common/googleanalytics");

export default class PrtVolumeFreightFileUpload extends Component{

	constructor(props)
	{
		super(props);
		this.state={
            legs:'',
            userid: localStorage.getItem('userid'),
            pageTitle : "",
            dept_code :"",
            basicTitle :"",
            basicType : "",
            show : false,
            loadshow : "show-n",
            overly : "show-n",
            usergridstate:[],
            screenurl:"",
            screentitle:"",
            eventGridAction: "gridAction",
            selected_year_month: "",
            uploaded_file: "",
            email_ids: "",
            pageType : 1,
            active:"activet",       
            closed:"",
		};
		
    }
    componentDidMount(){
        loadDateTimeScript()
        this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

        
    }
    logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
        window.location.reload();
    }
	formHandler = (event) => {
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
                let pageTitle = this.state.pageTitle;
                let eventOptions = {
                    "category": pageTitle,
                    "action": this.state.eventGridAction,
                    "label": googleAnalytics.page.action.viewRoute,
                }
                googleAnalytics.logEvent(eventOptions);
            }
        // this.setState({
        //     overly : "show-m",
        //     loadshow : "show-m",
        //     showbtn:"show-n"
        // })
        var selected_year_month = $("#year_month_id").val();
        var email_ids = [];
        this.state.email_ids.map((e)=>{email_ids.push(e.value)})

        var uploaded_file = this.state.uploaded_file;
        var flag = true;
        if(selected_year_month == "" || uploaded_file == "" || email_ids == "")
        {
            flag = false
        }
        if(flag)
        {
            var splittedName = uploaded_file.name.split(".");
            var extension = splittedName[splittedName.length - 1];
            if(['xlsx', "xls", "XLSX", "XLS"].indexOf(extension) >= 0)
            {
                this.setState({
                    loadshow : "show-m",
                    overly : "show-m",
                });
                let formdata = new FormData();
                formdata.append("selected_year_month", selected_year_month);
                formdata.append("email_ids", email_ids);
                formdata.append("uploaded_file", uploaded_file);
                formdata.append("pageType", this.state.pageType);
                redirectURL.post("/dashboard/saveVolumeFreightUploadedFile",formdata,{
                    headers:{
                        'content-type': 'multipart/form-data'
                    }
                }).then((response)=>{
                    if(response.data.status == "Success")
                    {
                        this.setState({
                            basicTitle:"An E-Mail will be received shortly with formatted file.",
                            basicType : "success",
                            show : true,
                            loadshow : "show-n",
                            overly : "show-n",
                        });
                    }
                    else
                    {
                        this.setState({
                            basicTitle:"Unable to store data.",
                            basicType : "warning",
                            show : true,
                            loadshow : "show-n",
                            overly : "show-n",
                        });
                    }
                    
                }).catch((e)=>{
                    console.log(e);
                    this.setState({
                        loadshow : "show-n",
                        overly : "show-n",
                    });
                })
            }
            else
            {
                this.setState({
                    basicTitle: "Invalid file uploaded. Please uploade Excel file",
                    basicType: "warning",
                    show: true
                })
            }
        }
        else
        {
            this.setState({
                basicTitle: "Fields should not be empty.",
                basicType: "warning",
                show: true
            })
        }
       
    }
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
        });  
    }

    async onClickTab(pageType)
    {
        if(pageType == "active")
        {
            await this.setState({
                pageType : 1,
                active:"activet",       
                closed:"",
            });
        }
        else
        {
            await this.setState({
                pageType : 2,
                active:"",       
                closed:"activet",
            });
        }
    }   
	 render() {
        var pageTitle = ""; 
        if(this.state.pageType == 1)
        {
            pageTitle = "Volume & Freight Dashboard Data Upload Form";
        }
        else
        {
            pageTitle = "SOB Dashboard Data Upload Form";
        }
        return (
            <div className="row col-xl-12 col-lg-12">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="f16" style={{marginBottom:"10px"}}>
                    <ul className="d-tabs">
                        <li onClick={this.onClickTab.bind(this,"active")} className={"lttabs "+(this.state.active)}>
                            <button type="button" className="btn">VOL &#38; FRGT Dashboard Data Upload</button>
                        </li>

                        <li onClick={this.onClickTab.bind(this,"closed")} className={"lttabs "+(this.state.closed)}>
                            <button type="button" className="btn">SOB Dashboard Data Upload</button>
                        </li>
                    </ul>
                    <h5 style={{marginTop:"3%"}}>
                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{pageTitle}</span>    
                    </h5>
                </div>
                
                <div className="row col-xl-12 col-lg-12 card">
                    <form className=" theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler} style={{padding:"16px"}}>
                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 form-group">
                            <label>Select Month</label>
                            <input type="text" className="selected_date form-control"
                            autoComplete="newPassword"
                             onChange={(e)=>{this.setState({selected_year_month: e.target.value})}}
                             id="year_month_id" required />
                        </div>

                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 form-group">
                            <label>File to upload</label>
                            <input type="file" className="form-control"
                             onChange={(e)=>{this.setState({"uploaded_file": e.target.files[0]})}}
                             required />
                        </div>
                        
                        <div className="form-group col-xl-5 col-lg-5 col-md-5 col-sm-5">
                            <label>Send Report to (email ID's) :  </label>
                            <CreatableSelect
                                isMulti={true}
                                isClearable
                                onChange={(e)=>{this.setState({email_ids: e})}}
                            />
                        </div>
                        {/* <span class="col-xl-12" style={{color:"red"}}>
                            Note: The stoppage summary report takes time to process and hence cannot be displayed on screen.<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;Please provide your email addresses and the report will be sent to you.
                        </span> */}
                        <div className="form-group">
                            <button type="submit" className={"btn btn-success cs-btn "+this.state.showbtn}   id="save" style={{marginLeft:"13em",marginTop:"15px",width: "182px"}}>Store Data</button>
                        </div>
                    </form>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                    </div>
                    <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                        <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                    </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
                
            </div>
        );
    }
}
function loadDateTimeScript(){
	$("#year_month_id").datepicker( {
        changeMonth: true,
        changeYear: true,
        showButtonPanel: false,
        dateFormat: "yy-mm",
        onClose: function (dateText, inst) {

            //Get the selected month value
            var month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();

            //Get the selected year value
            var year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();

            //set month value to the textbox
            $(this).datepicker('setDate', new Date(year, month, 1));
        },
        beforeShow: function(input, inst) {
            $('#ui-datepicker-div').addClass("year_month_id");
        }
    }); 
}

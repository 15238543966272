
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');

 
const RailInventoryLoadingYard = (props) => {
    //console.log("props ", props)
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickLoadingYard(props);
    };
    
    return (
        <div>
            <a href="javascript:void(0)" style={{color:"#000"}} onClick={handleClick}>{props.value}</a>
        </div>
    );
};

export default RailInventoryLoadingYard;

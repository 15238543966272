'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Datetime from 'react-datetime';
import $ from 'jquery';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Modal from 'react-responsive-modal';

import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import screenShare from 'material-ui/svg-icons/communication/screen-share';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");


var globForecastDay="Today";
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=5;
var coordinates=[];
var map;
var marker;
var markers = [];
var circle;


export default class WeatherReportData extends Component {
  constructor(props) {
    super(props);
      this.state = {

        eventAction: null,
        show: false,
        modules: AllModules,
        basicTitle:'',
        basicType:"default",
        overly:"show-n",
        loadshow:"show-n",
        eventGridAction: "gridAction",
        optionlist:[],
        scrowData: [],
        scrowData2:[],
        scrowData3:[],
        rowData: [],
        rowData1:[],
        rowData2:[],
        rowData3:[],
        screenType: "",
        suppressClickEdit: false,
        defaultColDef: {
            sortable: true,
            filter: true,
           
            //editable: editAdmin,
            resizable: true
        },
        screenView: "",
        showMsg: "show-n",
        lastRunTime : "",
        weatherData : "",
        forecastDay : "",
        forecastOptions : [],
        weatherPlants : [],
        weatherRail:[],
        to_current_Date :"",
        from_current_Date:"",
        manualUpdatedTime:"",
        path : "",
            statesSubDivisions: [],
            hoveredState: "",
            weatherCondition: "",
            detailedWeatherMap: 0,
            forecastTypeClass: 0,
            last_updated_on: "",
            states_last_updated_on: "",
            district_last_updated_on: "",
            startAlert: "NA",
            endAlert: "NA",
            floodData: [],
            floodMarkers: []
        
    }
    
  }

    async componentDidMount(){

      this.setState({
        overly: "show-m",
        loadshow: "show-m"
      })

      await redirectURL.post("/weather/getWeatherRail").then((response)=>{
       
        console.log(response.data,"144")
         
         this.setState({
           weatherRail : response.data,
           
         })
        //  this.renderMapWeather();
       })

       await redirectURL.post("/dashboard/getManualUpdatedTime").then((response)=>{
       
        console.log(response.data,"120")
        
        var manualUpdatedTime= moment.parseZone(response.data[0].updated_time).format("YYYY-MM-DD HH:mm:ss")

         this.setState({
           manualUpdatedTime:manualUpdatedTime
         })
        //  this.renderMapWeather();
       })

      await redirectURL.post("/weather/getWeatherData").then((response)=>{
        console.log(response.data);
        const uniqueDAta = [...new Set(response.data.ForecastData.map(item => item.description))];
        console.log(response.data.ForecastData.length,"uniqueDAta")
        
        this.setState({
          weatherData : response.data.ForecastData,
          statesSubDivisions: response.data.statesSubDivisions
        })
         redirectURL.post("/weather/getPlants").then((response)=>{
          
          var path = window.location.href.split("//")[1].split("/")[0];
          
          this.setState({
            weatherPlants : response.data,
            forecastDay : "Today",
            path : path
          })
          this.renderMapWeather();
        })
       
        
      }).catch(function(error){
        console.log(error);
      })

      await redirectURL.post("/weather/getFloodData").then((response)=>{
          console.log(response.data);
          this.setState({
            floodData : response.data,
          })
      }).catch(function(error){
          console.log(error);
      })
      
   


        var dept_code = "";
        console.log(this.props.match.path,"path")
        var screenView = "";
        if(this.props.match.path == "/safetyreportmgmt" || this.props.match.path == "/mgmtsafetyreport")
        {
            var screen = "admin";
            if(this.props.match.path == "/mgmtsafetyreport")
            {
                screenView = "Mobile";
            }
            this.setState({
                suppressClickEdit: true,

            })
        }
        if(this.props.match.path == "/ledrailwaylogisticsscreen2" || this.props.match.path == "/scsafetyreport")
        {
            var screen = "user";

            if(this.props.match.path == "/ledrailwaylogisticsscreen2")
            {
                dept_code = "SNDG";
            }else{ dept_code = "LOG-SC"}
            this.setState({
                suppressClickEdit: false,

            })
        }
        
        console.log(screenView, "screenView")
        this.setState({
            dept_code:  dept_code,
        })
        var sort_order = {
            "Gurgaon": 1, "SMG Plant": 2, "Manesar": 3, "IMT Manesar": 4, "TVP Bangalore": 5,
            "TVP Nagpur": 6, "Siliguri": 7, "Mundra Port": 8, "Mumbai Port": 9
        }
        await redirectURL.post("/dashboard/getLedScreenWeatherData",{"screen": screen}).then((response)=>{
          console.log(response.data,"257")
          var data = response.data.data1;
         var data2=response.data.data2;
         var data3=response.data.data3;
          var weatherPlants2=this.state.weatherPlants
          var railYards=this.state.weatherRail
          console.log(weatherPlants2,data,"199")
          data=data.map((each)=>{
            if (each.railyard_name=="Bidadi"){
              let item=weatherPlants2.find((i)=>i.plant_name=="TKM-Bidadi")
              if (item!==undefined){
                if (item.weather_today=="No"||item.weather_today=="" || item.weather_today==undefined){
                  return ({...each,railyard_name:"Bidadi",weather_data:"No warning"})
                }
                return {...each,railyard_name:"Bidadi",weather_data:item.weather_today}
              }
              
            }
            if (each.railyard_name=="TVP Siliguri"){
              let item=weatherPlants2.find((i)=>i.plant_name=="TVP Siliguri")
              if (item!==undefined){
                if (item.weather_today=="No"||item.weather_today=="" || item.weather_today==undefined){
                  return ({...each,railyard_name:"TVP Siliguri",weather_data:"No warning"})
                }
                return {...each,railyard_name:"TVP Siliguri",weather_data:item.weather_today}
              }
              
            }
            
            let item=weatherPlants2.find((i)=>i.plant_name==each.railyard_name)
            if (item!==undefined){
              if (item.weather_today=="No"||item.weather_today=="" || item.weather_today==undefined){
                return ({...each,weather_data:"No warning"})
              }
              return ({...each,weather_data:item.weather_today})
            }
           
          })

          
console.log(data,data2,data3,"260");

          var railyardNames=railYards.map(each=>each.plant_name)

          data2=data2.map((each)=>{
            console.log(each.railyard_name,"238")
            if (each.railyard_name=="Becharaji Railyard"){
              let item=railYards.find((i)=>i.plant_name=="Becharaji Railyard")
              if (item!==undefined){
                if (item.weather_today=="No"||item.weather_today=="" || item.weather_today==undefined){
                  return ({...each,railyard_name:"SMG (Becharaji) Railway Yard",weather_data:"No warning"})
                }
                return {...each,railyard_name:"SMG (Becharaji) Railway Yard",weather_data:item.weather_today}
              }
              
            }

            let item=railYards.find((i)=>i.plant_name==each.railyard_name)
            if (item!==undefined){
              if (item.weather_today=="No"||item.weather_today=="" || item.weather_today==undefined){
                return ({...each,weather_data:"No warning"})
              }
              return ({...each,weather_data:item.weather_today})
            }
            return ({...each,weather_data:"No warning"})
          })

          data3=data3.map((each)=>{
            let item=railYards.find((i)=>i.plant_name==each.railyard_name)
            if (item!==undefined){
              if (item.weather_today=="No"||item.weather_today=="" || item.weather_today==undefined){
                return ({...each,weather_data:"No warning"})
              }
              return ({...each,weather_data:item.weather_today})
            }
            return ({...each,weather_data:"No warning"})
          })

       
console.log(data2,data3,"260");
         this.setState({scrowData:data,scrowData2:data2,scrowData3:data3})

        
          
      })


        await redirectURL.post("/dashboard/getSafetyReport",{"screen": screen}).then((response)=>{
          var data = response.data;
          console.log(data);
          var check = data.map(function(e){
              e['sort_order'] = sort_order[e.plant_location]
          })
          Promise.all([check]).then(()=>{
              console.log(data);
              data = data.sort(function(a, b){return a['sort_order'] - b['sort_order']})
              var snddata = data.filter(function(e){
                  return e.dept_code == "SNDG"
              });
              var scdata = data.filter(function(e){
                  return e.dept_code != "SNDG"
              });
              console.log(screen, dept_code, "detcode")
              this.setState({
                  rowData: snddata,
                  screenType: screen,
                  dept_code: dept_code,
                  screenView: screenView,


              })
          })
          
      })
      var lastRunTime = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
      this.setState({
          lastRunTime:lastRunTime,
          overly: "show-n",
          loadshow: "show-n"
      })

      // await this.countDownTimer();
      this.refresh();

}



refreshTheData =async()=>{

  this.setState({
    overly: "show-m",
    loadshow: "show-m"
  })

  await redirectURL.post("/weather/getWeatherRail").then((response)=>{
   
    console.log(response.data,"144")
     
     this.setState({
       weatherRail : response.data,
       
     })
    //  this.renderMapWeather();
   })
  await redirectURL.post("/weather/getWeatherData").then((response)=>{
    console.log(response.data);
    const uniqueDAta = [...new Set(response.data.ForecastData.map(item => item.description))];
    console.log(response.data.ForecastData.length,"uniqueDAta")
    
    this.setState({
      weatherData : response.data.ForecastData,
      statesSubDivisions: response.data.statesSubDivisions
    })
     redirectURL.post("/weather/getPlants").then((response)=>{
      
      var path = window.location.href.split("//")[1].split("/")[0];
      
      this.setState({
        weatherPlants : response.data,
        forecastDay : "Today",
        path : path
      })
      this.renderMapWeather();
    })
   
    
  }).catch(function(error){
    console.log(error);
  })

  await redirectURL.post("/weather/getFloodData").then((response)=>{
      console.log(response.data);
      this.setState({
        floodData : response.data,
      })
  }).catch(function(error){
      console.log(error);
  })
  



    var dept_code = "";
    console.log(this.props.match.path,"path")
    var screenView = "";
    if(this.props.match.path == "/safetyreportmgmt" || this.props.match.path == "/mgmtsafetyreport")
    {
        var screen = "admin";
        if(this.props.match.path == "/mgmtsafetyreport")
        {
            screenView = "Mobile";
        }
        this.setState({
            suppressClickEdit: true,

        })
    }
    if(this.props.match.path == "/ledrailwaylogisticsscreen2" || this.props.match.path == "/scsafetyreport")
    {
        var screen = "user";

        if(this.props.match.path == "/ledrailwaylogisticsscreen2")
        {
            dept_code = "SNDG";
        }else{ dept_code = "LOG-SC"}
        this.setState({
            suppressClickEdit: false,

        })
    }
    
    console.log(screenView, "screenView")
    this.setState({
        dept_code:  dept_code,
    })
    var sort_order = {
        "Gurgaon": 1, "SMG Plant": 2, "Manesar": 3, "IMT Manesar": 4, "TVP Bangalore": 5,
        "TVP Nagpur": 6, "Siliguri": 7, "Mundra Port": 8, "Mumbai Port": 9
    }
    await redirectURL.post("/dashboard/getLedScreenWeatherData",{"screen": screen}).then((response)=>{
      console.log(response.data,"257")
      var data = response.data.data1;
     var data2=response.data.data2;
     var data3=response.data.data3;
      var weatherPlants2=this.state.weatherPlants
      var railYards=this.state.weatherRail
      console.log(weatherPlants2,data,"199")
      data=data.map((each)=>{
        if (each.railyard_name=="Bidadi"){
          let item=weatherPlants2.find((i)=>i.plant_name=="TKM-Bidadi")
          if (item!==undefined){
            if (item.weather_today=="No"||item.weather_today=="" || item.weather_today==undefined){
              return ({...each,railyard_name:"Bidadi",weather_data:"No warning"})
            }
            return {...each,railyard_name:"Bidadi",weather_data:item.weather_today}
          }
          
        }
        if (each.railyard_name=="TVP Siliguri"){
          let item=weatherPlants2.find((i)=>i.plant_name=="TVP Siliguri")
          if (item!==undefined){
            if (item.weather_today=="No"||item.weather_today=="" || item.weather_today==undefined){
              return ({...each,railyard_name:"TVP Siliguri",weather_data:"No warning"})
            }
            return {...each,railyard_name:"TVP Siliguri",weather_data:item.weather_today}
          }
          
        }

        let item=weatherPlants2.find((i)=>i.plant_name==each.railyard_name)
        if (item!==undefined){
          if (item.weather_today=="No"||item.weather_today=="" || item.weather_today==undefined){
            return ({...each,weather_data:"No warning"})
          }
          return ({...each,weather_data:item.weather_today})
        }
       
      })

      
console.log(data,data2,data3,"260");

      var railyardNames=railYards.map(each=>each.plant_name)

      data2=data2.map((each)=>{
        let item=railYards.find((i)=>i.plant_name==each.railyard_name)
        if (item!==undefined){
          if (item.weather_today=="No"||item.weather_today=="" || item.weather_today==undefined){
            return ({...each,weather_data:"No warning"})
          }
          return ({...each,weather_data:item.weather_today})
        }
        return ({...each,weather_data:"No warning"})
      })

      data3=data3.map((each)=>{
        let item=railYards.find((i)=>i.plant_name==each.railyard_name)
        if (item!==undefined){
          if (item.weather_today=="No"||item.weather_today=="" || item.weather_today==undefined){
            return ({...each,weather_data:"No warning"})
          }
          return ({...each,weather_data:item.weather_today})
        }
        return ({...each,weather_data:"No warning"})
      })

   
console.log(data2,data3,"260");
     this.setState({scrowData:data,scrowData2:data2,scrowData3:data3})

    
      
  })


    await redirectURL.post("/dashboard/getSafetyReport",{"screen": screen}).then((response)=>{
      var data = response.data;
      console.log(data);
      var check = data.map(function(e){
          e['sort_order'] = sort_order[e.plant_location]
      })
      Promise.all([check]).then(()=>{
          console.log(data);
          data = data.sort(function(a, b){return a['sort_order'] - b['sort_order']})
          var snddata = data.filter(function(e){
              return e.dept_code == "SNDG"
          });
          var scdata = data.filter(function(e){
              return e.dept_code != "SNDG"
          });
          console.log(screen, dept_code, "detcode")
          this.setState({
              rowData: snddata,
              screenType: screen,
              dept_code: dept_code,
              screenView: screenView,


          })
      })
      
  })
  var lastRunTime = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
  this.setState({
      lastRunTime:lastRunTime,
      overly: "show-n",
      loadshow: "show-n"
  })


}


refresh=()=>{
  
  this.timerId=setInterval(async ()=>{
    await this.refreshTheData()
    // this.setState({loadshow:"show-n",overly:"show-n"})
  },300000)

  // this.setState({loadshow:"show-n",overly:"show-n"})
  
 
}

componentWillUnmount() {
  clearInterval(this.timerId)
}

countDownTimer = async() => {
  var lastRunTime = this.state.lastRunTime;
  var currentDate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
  // var startdate = moment.parseZone(lastRunTime).format("YYYY-MM-DD HH:mm:ss")
  var startdate = lastRunTime
  var diffreetime = moment.duration(moment(currentDate).diff(startdate));
  var runseconds = Math.round(diffreetime.asSeconds());
  console.log(runseconds,lastRunTime,currentDate,"runseconds");
  if(parseInt(runseconds) < 60)
  {
      this.startTimer();
  }
}

secondsToTime(secs){
  let hours = Math.floor(secs / (60 * 60));

  let divisor_for_minutes = secs % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);

  let divisor_for_seconds = divisor_for_minutes % 60;
  let seconds = Math.ceil(divisor_for_seconds);

  let obj = {
    "h": hours,
    "m": minutes,
    "s": seconds
  };
  return obj;
}

startTimer() {
  if (this.timer == 0 && this.state.seconds > 0) {
    this.timer = setInterval(this.countDown, 2000);
  }
}

countDown() {
  // Remove one second, set state so a re-render happens.
  console.log("called")
  let seconds = this.state.seconds - 1;
  this.setState({
    time: this.secondsToTime(seconds),
    seconds: seconds,
  });
  if(this.state.seconds == 30 || this.state.seconds == 60 || this.state.seconds == 90)
  {
      //console.log(this.state.seconds,"this.state.seconds")
      this.componentDidMount();
  }
  // Check if we're at zero.
  if (seconds == 0) { 
      clearInterval(this.timer);
      $("#timerDiv").hide();
      this.componentDidMount();
  }
}
      

    renderMapWeather = () => {    	
        loadScriptWeather("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMapWeather");
        window.initMapWeather = this.initMapWeather
       }
    
       initMapWeather = () => {
         
        try
          {
            //console.log("here slidebar")
            // $("#mapweather").empty();
            //var coordsvalues;
            var map_type = window.google.maps.MapTypeId.HYBRID;
            if(this.state.detailedWeatherMap == 1|| this.state.detailedWeatherMap == 2)
            {
              var map_type = window.google.maps.MapTypeId.ROADMAP;
            }
            var map = new window.google.maps.Map(document.getElementById('mapweather'), {
              zoom: 15,
              center: new window.google.maps.LatLng(28.497236,77.062363),
              mapTypeId: map_type,
              mapTypeControl: true,
              mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_RIGHT
              },            
              disableDefaultUI: true,
              fullscreenControl: false,
              zoomControl: true,
              panControl: false,
              
            });
            
            var filterRecDate = "";

            if(globForecastDay == "Today")
            {
              filterRecDate = moment.parseZone(new Date()).format("DD-MM-YYYY");
            }
            else
            {
              filterRecDate = moment.parseZone(new Date(+new Date() + 86400000)).format("DD-MM-YYYY");
            }

            // First Map Weather Plants Icons
            if(this.state.weatherPlants.length > 0 && this.state.detailedWeatherMap == 0){
              this.state.weatherPlants.map(function(params){
                var plant = {
                  url: require("../../assets/icons/"+params.icon), // url
                  scaledSize: new window.google.maps.Size(32,32), // scaled size
                  origin: new window.google.maps.Point(0,0), // origin
                  anchor: new window.google.maps.Point(0, 0) // anchor
                };
                var coords = JSON.parse(params.coordinates);
                // console.log(coords);
                var marker = new window.google.maps.Marker({
                  position: new window.google.maps.LatLng(coords.lat,coords.lng),
                  map: map,
                  icon: plant,
                  title : params.plant_name
                });
                

                var cityCircle = new window.google.maps.Circle({
                  strokeColor: '#9fc9b9',
                  strokeOpacity: 0.3,
                  strokeWeight: 2,
                  fillColor: '#9fc9b9',
                  fillOpacity: 0.6,
                  map: map,
                  center:coords,
                  radius: 50 * 1000
                });
              })
            }
            // var iconObject = {"heavyrain":heavyrain,"Sandstorm":Sandstorm,"cyclone":cyclone,"wind":wind,"hailstorm":hailstorm,"thunderstorm":thunderstorm}
            var bounds = new window.google.maps.LatLngBounds();
            
            // new LatLng(23.63936, 68.14712), new LatLng(28.20453, 97.34466)

            // bounds.extend(new window.google.maps.LatLng(23.63936, 68.14712));
            // bounds.extend(new window.google.maps.LatLng(28.20453, 97.34466));

            // bounds.extend(new window.google.maps.LatLng(20.474900,73.547326)); 
            bounds.extend(new window.google.maps.LatLng(20.474900,73)); 
            bounds.extend(new window.google.maps.LatLng(20.474900, 88.282247));

            // First Map
            if(this.state.weatherData.length > 0 && this.state.detailedWeatherMap == 0)
            {
              
              var filterRecords =  this.state.weatherData.filter(function(params){
                return moment.parseZone(new Date(params.forecast_date)).format("DD-MM-YYYY") == filterRecDate && params.description != "NA"
              })
              
              var district_last_updated_on = "";
              if(filterRecords.length > 0)
              {
                district_last_updated_on = filterRecords[0]['last_updated_on']
                this.setState({
                  last_updated_on: district_last_updated_on
                })
                filterRecords.map(function(params)
                {            
                  if(params.address.includes("NA") == false && params.address != "/" && params.description.includes("No warning") == false)
                  {
                    // bounds.extend(new window.google.maps.LatLng(params.latitude,params.longitude));
                    bounds.extend(new window.google.maps.LatLng(16.474900,80.547326));
                    var url="";
                    var iconName = "";
                    if(params.description.includes("Thunderstorm") == true){
                      url = require("../../assets/icons/thunderstorm.png")
                    }                    
                    else if(params.description.includes("Heavy Rain and Strong Winds") == true){
                      url = require("../../assets/icons/wind.png")
                    }
                    else if(params.description.includes("Heavy Rain") == true){
                      url = require("../../assets/icons/Heavyrain.png")
                    }
                    else if(params.description.includes("Cyclone") == true){
                      url = require("../../assets/icons/cyclone.png")
                    }
                    else if(params.description.includes("Hailstroms") == true){
                      url = require("../../assets/icons/hailstorm.png")
                    }
                    else if(params.description.includes("Sandstorm") == true || params.description.includes("Duststorm") == true){
                      url = require("../../assets/icons/sandstorm.png")
                    }
                    else
                    {
                      
                    }
                    console.log("weatherIcon", url )
                    var iconName = {
                      url: url, // url
                      scaledSize: new window.google.maps.Size(32, 32), // scaled size
                      origin: new window.google.maps.Point(0,0), // origin
                      anchor: new window.google.maps.Point(0, 0) // anchor
                    };
                    var marker = new window.google.maps.Marker({
                      position: new window.google.maps.LatLng(params.latitude,params.longitude),
                      map: map,
                      icon: iconName,
                      // title : ""+params.address+","+params.forecast_date+","+params.description
                    });
                    var infowindow = new window.google.maps.InfoWindow({
                      content: "<div class='weather-marker-tooltip'><div class='forecast-date'><label class='forecast-labels'> Forecast Date : </label> "+moment.parseZone(new Date(params.forecast_date)).format("DD-MMM-YYYY")+"</div><div class='address'> <label class='forecast-labels'>Address :</label> "+params.address+"</div><div class='weather-desc'><label class='forecast-labels'> Weather : </label> "+params.description+"</div><div class='last-updated'><label class='forecast-labels'> Last Updated On : </label> "+getHyphenDDMMMYYYYHHMM(params.last_updated_on)+"</div>"
                      });
                    window.google.maps.event.addListener(marker, 'mouseover', function() {
                      infowindow.open(map,marker);
                    });
                    window.google.maps.event.addListener(marker, 'mouseout', function() {
                      infowindow.close();
                    });
                  }
                // }
                })
                $("#mapweather").css("visibility","visible");
                $(".forecast-warning").css("display","none");
              }
              else
              {
                //$("#mapweather").css("visibility","hidden");
                $(".forecast-warning").css("display","block");
              }
              map.addListener('zoom_changed', function() {
                mapZoom=map.getZoom();
              });
            }
            // Second Map
            else if(this.state.detailedWeatherMap == 1)
            {
              var infowindows = [];
              this.state.statesSubDivisions.map((e, index)=>{
                var geofence_coordinates = JSON.parse(e.geofence_coordinates);
                // var optimizedCoords = [geofence_coordinates[0]];
                // var i = 2;
                // while(i < geofence_coordinates.length - 1)
                // {
                //   optimizedCoords.push(geofence_coordinates[i])
                //   // bounds.extend(new window.google.maps.LatLng(geofence_coordinates[i].lat,geofence_coordinates[i].lng));
                //   i = i + 1
                // }
                // optimizedCoords.push(geofence_coordinates[geofence_coordinates.length-1])
                var hex_color = '#000';
                try{
                  
                  if(globForecastDay == "Today")
                  {
                    var filterRecDate = moment.parseZone(new Date()).format("DD-MM-YYYY");
                    var forecast_info =  e.forecast_info.filter(function(params){
                      return moment.parseZone(new Date(params.forecast_date)).format("DD-MM-YYYY") == filterRecDate
                    })
                  }
                  else
                  {
                    var filterRecDate = moment.parseZone(new Date(+new Date() + 86400000)).format("DD-MM-YYYY");
                    var forecast_info =  e.forecast_info.filter(function(params){
                      return moment.parseZone(new Date(params.forecast_date)).format("DD-MM-YYYY") == filterRecDate;
                    })
                  }
                }
                catch(e){
                  var forecast_info = [{}]
                }
                var states_last_updated_on = "";
                if(forecast_info.length)
                {
                  states_last_updated_on = forecast_info[0]['last_updated_on']
                  hex_color =  forecast_info[0]['color']
                }
                this.setState({
                  last_updated_on: states_last_updated_on
                })
                var statePoly = new window.google.maps.Polygon({
                    paths: JSON.parse(e.geofence_coordinates),
                    editable: false,
                    strokeColor: "green",
                    strokeOpacity: 0.70,
                    strokeWeight: 1,
                    fillColor: hex_color,
                    fillOpacity: 1,
                    draggable:false,
                    draggingCursor:null,
                    
                });
                var weatherCondition = forecast_info[0].weather_condition;
                function iconUrl(i){
                  if(i.includes("rain"))
                      {
                        url = require("../../assets/icons/mausam - heavy rain.png")
                      }
                      else if(i.includes("cold day"))
                      {
                         url = require("../../assets/icons/mausam - cold day.png")
                      }
                      else if(i.includes("cold wave"))
                      {
                         url = require("../../assets/icons/mausam - cold wave.png")
                      }
                      else if(i.includes("dust raising"))
                      {
                        url = require("../../assets/icons/mausam - dust raising winds.png")
                      }
                      else if(i.includes("dust storm"))
                      {
                        url = require("../../assets/icons/mausam - dust storm.png")
                      }
                      else if(i.includes("fog"))
                      {
                        url = require("../../assets/icons/mausam - fog.png")
                      }
                      else if(i.includes("froast"))
                      {
                        url = require("../../assets/icons/mausam - ground froast.png")
                      }
                      else if(i.includes("hail"))
                      {
                        url = require("../../assets/icons/mausam - hailstorm.png")
                      }
                      else if(i.includes("heat wave"))
                      {
                        url = require("../../assets/icons/mausam - heat wave.png")
                      }
                      else if(i.includes("snow"))
                      {
                        url = require("../../assets/icons/mausam - heavy snow.png")
                      }
                      else if(i.includes("hot"))
                      {
                        url = require("../../assets/icons/mausam - hot day.png")
                      }
                      else if(i.includes("surface"))
                      {
                        url = require("../../assets/icons/mausam - strong surface winds.png")
                      }
                      else if(i.includes("thunderstorm"))
                      {
                        url = require("../../assets/icons/mausam - thunderstorm.png")
                      }
                      else if(i.includes("night"))
                      {
                        url = require("../../assets/icons/mausam - warm night.png")
                      }
                    return url
                }
                if(weatherCondition != undefined)
                {
                  weatherCondition = weatherCondition.toLowerCase();
                  var url="";
                  let wc = weatherCondition.split(",")
                  if (wc.length >1){
                    var lat = e.centroid_location.lat;
                    var lng = e.centroid_location.lng;
                    wc.map((i, index)=>{
                      url = iconUrl(i)
                      console.log("url",i, url, e)       

                      var iconName = {
                        url: url, // url
                        scaledSize: new window.google.maps.Size(32, 32), // scaled size
                        origin: new window.google.maps.Point(0,0), // origin
                        anchor: new window.google.maps.Point(0, 0) // anchor
                      };
                      
                      if(index > 0)
                      {
                        lat = lat + 0.2;
                        lng = lng + 0.2
                      }
                      let marker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng(lat, lng),
                        map: map,
                        icon: iconName
                      });

                    })                     
                  }
                  else{
                    url = iconUrl(weatherCondition)
                    var iconName = {
                      url: url, // url
                      scaledSize: new window.google.maps.Size(32, 32), // scaled size
                      origin: new window.google.maps.Point(0,0), // origin
                      anchor: new window.google.maps.Point(0, 0) // anchor
                    };
                    var marker = new window.google.maps.Marker({
                      position: new window.google.maps.LatLng(e.centroid_location.lat,e.centroid_location.lng),
                      map: map,
                      icon: iconName
                    });
                  }
                }
                infowindows[index] = new window.google.maps.InfoWindow({
                  content: e.geofence_name
                });
                var startAlert = "NA";
                var endAlert = "NA";
                if(e.alert_info != undefined && e.active_alert == 1)
                {
                  startAlert = getHyphenYYYYMMDDHHMMSS(e.alert_info.start_alert);
                  endAlert = getHyphenYYYYMMDDHHMMSS(e.alert_info.end_alert);
                }
                window.google.maps.event.addListener(statePoly, 'mouseover', ()=> {
                  console.log("clicked")
                  infowindows[index].open(map,statePoly);
                  this.setState({
                    hoveredState: e.geofence_name,
                    weatherCondition: forecast_info[0].weather_condition,
                    startAlert: startAlert,
                    endAlert: endAlert
                  })
                });
                  window.google.maps.event.addListener(statePoly, 'mouseout', ()=> {
                    infowindows[index].close();
                    this.setState({
                      hoveredState: "",
                      weatherCondition: "",
                      startAlert: "NA",
                      endAlert:"NA"
                    })
                });
                statePoly.setMap(map)

              })
            }
            // Third Map
            else if(this.state.detailedWeatherMap == 2)
            {
                var floodData = this.state.floodData;
                var floodMarkers = [];
                floodData.map((params)=>
                { 
                  //console.log(params)
                  var iconClr = "";        
                  if(params.current_status == "NORMAL")
                  {
                    iconClr = require("../../assets/icons/green-dot.png");
                  }
                  else if(params.current_status == "INFLOW")
                  {
                    iconClr = require("../../assets/icons/blue-dot.png");
                    //console.log(params.current_status,params.latitude,params.longitude)
                  }
                  else if(params.current_status == "WARNING")
                  {
                    iconClr = require("../../assets/icons/yellow-dot.png");
                  }
                  else if(params.current_status == "EXTREME")
                  {
                    iconClr = require("../../assets/icons/black-circle.png");
                  }
                  else
                  {
                    iconClr = require("../../assets/icons/red-dot.png");
                  }
                  bounds.extend(new window.google.maps.LatLng(16.474900,67.547326));
                  
                  var iconName = {
                    url: iconClr, // url
                    scaledSize: new window.google.maps.Size(10, 10), // scaled size
                    origin: new window.google.maps.Point(0,0), // origin
                    anchor: new window.google.maps.Point(0, 0) // anchor

                  };
                
                  var marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(params.latitude,params.longitude),
                    map: map,
                    icon: iconName,
                    // title : ""+params.address+","+params.forecast_date+","+params.description
                  });
                  floodMarkers.push({
                    "flood_marker": marker,
                    "alert": params.current_status,
                    "url": iconClr
                  })
                  var infowindow = new window.google.maps.InfoWindow({
                    content: "<div class='weather-marker-tooltip'><div class='address'> <label class='forecast-labels'>Flood Station :</label> "+params.station+"</div><div class='river'> <label class='forecast-labels'>River :</label> "+params.river+"</div><div class='river'> <label class='forecast-labels'>Division :</label> "+params.division+"</div><div class='weather-desc'><label class='forecast-labels'> Alert : </label> "+params.current_status+"</div>"
                  });
                  window.google.maps.event.addListener(marker, 'mouseover', function() {
                    infowindow.open(map,marker);
                  });
                  window.google.maps.event.addListener(marker, 'mouseout', function() {
                    infowindow.close();
                  });
                }) 
                map.addListener('zoom_changed', function() {
                  mapZoom=map.getZoom();
                });  
                this.setState({
                  floodMarkers: floodMarkers
                })            
            }
            map.fitBounds(bounds)

          }catch(e){}
          
        }
      
        forecastOption(params){
          // let forecastOptions = [];
          this.state.forecastOptions=[];
          this.state.forecastOptions.push({value:"Today",label:"Today"});
          this.state.forecastOptions.push({value:"Tomorrow",label:"Tomorrow"})
          return this.state.forecastOptions;
        }
      changeForecastData = (event) =>{
        this.setState({
          forecastDay : event.value,
          overly: "show-m",
          loadshow: "show-m"
        });
        globForecastDay = event.value;
        // console.log(event.value);
        this.renderMapWeather()
          // this.initMapWeather()
      }  
      onChangeForecastType(e){
        if(e == 2 || e==1 || e==0)
        {
          this.setState({
            overly: "show-m",
            loadshow: "show-m"
          });
            redirectURL.post("/weather/getFloodData").then((response)=>{
                console.log(response.data);
                this.setState({
                  floodData : response.data,
                  overly: "show-n",
                  loadshow: "show-n"
                })
            }).catch(function(error){
                console.log(error);
            })
        }
        this.setState({
          detailedWeatherMap: e,
          forecastTypeClass: e,
          floodWeatherMap: e,
          overly: "show-m",
          loadshow: "show-m"
        })
        console.log(e, "onchange")
        this.renderMapWeather()
      }
      onCloseRouteDiv = (event) => {
        this.props.context.onCloseRouteDiv();
      }
      ShowImage()
      {
      }
      onClickHideAll(){
        this.setState({
          overly: "show-n",
          loadshow: "show-n"
        })
      }
      changeFloodStatus(floodStatus){
        var floodMarkers = this.state.floodMarkers;
        if(floodStatus == 0)
        {
          floodMarkers.map((e)=>{
            e.flood_marker.setVisible(true);
          })
        }
        else if(floodStatus == 1){
          floodMarkers.map((e)=>{
            if (e.alert == "NORMAL")
            {
              e.flood_marker.setVisible(true);
            }
            else 
            {
              e.flood_marker.setVisible(false);
            }
          })
        }
        else if(floodStatus == 2)
        {
          floodMarkers.map((e)=>{
            if (e.alert == "INFLOW")
            {
              e.flood_marker.setVisible(true);
            }
            else 
            {
              e.flood_marker.setVisible(false);
            }
          })
        }
        else if(floodStatus == 3)
        {
          floodMarkers.map((e)=>{
            if (e.alert == "WARNING")
            {
              e.flood_marker.setVisible(true);
            }
            else 
            {
              e.flood_marker.setVisible(false);
            }
          })
        }
        else if(floodStatus == 4)
        {
          floodMarkers.map((e)=>{
            if (e.alert == "DANGER")
            {
              e.flood_marker.setVisible(true);
            }
            else 
            {
              e.flood_marker.setVisible(false);
            }
          })
        }
        
        else if(floodStatus == 5)
        {
          floodMarkers.map((e)=>{
            if (e.alert == "EXTREME")
            {
              e.flood_marker.setVisible(true);
            }
            else 
            {
              e.flood_marker.setVisible(false);
            }
          })
        }
      }

   
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}

    onGridReady = params => {
		this.gridApi = params.api;
		
		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
		
	};


    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onClickHideAll(){
        this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:"",
        
        });
        
    }

    onEditStopped(cell)
    {
        // if(cell.colDef.field == "weather_condition")
        // {
            var rowdata = cell.data;
            var query = {"_id": rowdata._id}
            query[cell.colDef.field] = cell.value;
            redirectURL.post("/dashboard/saveSafetyWeatherReport",{"rowdata": query}).then((response)=>{
                this.setState({
                    showMsg: "show-m"
                })
                setTimeout(()=>{
                    this.setState({
                        showMsg: "show-n"
                    })  
                }, 4000)
            }).catch((e)=>{console.log(e)})
        // }
        
    }
    approveSafetyReport(){
        
    }
    onCellClicked(cell)
    {
        if(cell.colDef.field == "approve")
        {
            var approved_by = localStorage.getItem("username");
            redirectURL.post("/dashboard/approveSafetyReport", {"plant_location": cell.data.plant_location,
                                                                "approved_by": approved_by}).then((response)=>{
                this.setState({
                    basicTitle: "Approval Successfull.",
                    basicType: "success",
                    show: true
                })
            }).catch((e)=>{})
        }
    }

    onFirstDataRendered = (params) => {
      params.api.sizeColumnsToFit();
    };

    render(){
       
        var hideEdit = (this.state.screenType == "admin")?false:true;
        const columnDefs1 = [
           
            {
                headerName: "MSIL Locations",
                field: "railyard_name",
                editable: true ,
                
               
            },
            // {
            //     headerName:"Vehicle Stock(Cars)",
            //     field:"vehicle_stocks",
            //     editable:false,
            //     width:105,
            // },
            // {
            //     headerName: "Weather",
            //     field: "weather",
            //     width:190,
            //     editable: hideEdit,
            //     cellEditor: 'agSelectCellEditor',
            //     cellEditorParams: {
            //         values: ['Hailstorm', "Thunderstorm", "Rain", "Dust storm during last 24 hours"],
            //     },
            // },
            {
                headerName: "Weather Forecast(Today)",
                field: "weather_data",
                editable: false,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ['Warning', "No Warning"],
                },
              
                cellStyle: params => {
                  if (params.value === 'No warning') {
                    //mark police cells as red
                    return {color: 'black',backgroundColor:"#6bd46b",fontWeight:"600"};
                }else{
                return {color:"black",backgroundColor:'orange',fontWeight:"600"};
                }}
    ,
              
                
            },
            {
                headerName: "Parked Vehicles Safe",
                field: "parked_vehicles_safety",
                editable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ['Yes', "No"],
                },
               
                cellStyle: params => {
                  if (params.value === 'No') {
                    //mark police cells as red
                    return {color: 'black',backgroundColor:"orange",fontWeight:"600"};
                }
                return {color: 'black',backgroundColor:"#6bd46b",fontWeight:"600"};
              },
              valueSetter:params=>{
                    console.log(params,params.value,"1235")
                    var updated_time=new Date()
                      var params={
                        _id:params.data._id,
                        value:params.newValue,
                        column:"parked_vehicles_safety",
                        updated_time
                      }
   
                      redirectURL.post("dashboard/updateWeatherDataOnGridOne",params).then((response)=>{
                        if(response.data.message == "Success"){
                           this.componentDidMount()
                        }
                    })

                    
              }
            },
            {
                headerName: "Infra Safe",
                field: "infrastructure_safety",
                editable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ['Yes', "No"],
                },
                // suppressSizeToFit: true ,
                cellStyle: params => {
                  if (params.value === 'No') {
                    //mark police cells as red
                    return {color: 'black',backgroundColor:"orange",fontWeight:"600"};
                }
                return {color: 'black',backgroundColor:"#6bd46b",fontWeight:"600"};
              },
              valueSetter:params=>{
                console.log(params,params.value,"1235")
                var updated_time=new Date()
                  var params={
                    _id:params.data._id,
                    value:params.newValue,
                    column:"infrastructure_safety",
                    updated_time
                  }

                  redirectURL.post("dashboard/updateWeatherDataOnGridOne",params).then((response)=>{
                    if(response.data.message == "Success"){
                       this.componentDidMount()
                    }
                })

                
          }
              
            },
            
        ]

        const columnDefs2 = [
           
            {
                headerName: "Rail Yards (Loading Yards)",
                field: "railyard_name",
                editable: true ,
               
            },
            // {
            //     headerName:"Vehicle Stock(Cars)",
            //     field:"vehicle_stocks",
            //     editable:true,
            //     width:105,
            // },
            // {
            //     headerName: "Weather",
            //     field: "weather",
            //     width:190,
            //     editable: hideEdit,
            //     cellEditor: 'agSelectCellEditor',
            //     cellEditorParams: {
            //         values: ['Hailstorm', "Thunderstorm", "Rain", "Dust storm during last 24 hours"],
            //     },
            // },
         
            {
              headerName: "Weather Forecast(Today)",
              field: "weather_data",
              editable: false,
              cellEditor: 'agSelectCellEditor',
              cellEditorParams: {
                  values: ['Warning', "No Warning"],
              },
            
              cellStyle: params => {
                if (params.value === 'No warning') {
                  //mark police cells as red
                  return {color: 'black',backgroundColor:"#6bd46b",fontWeight:"600"};
              }else{
              return {color:"black",backgroundColor:'orange',fontWeight:"600"};
              }}
  ,
            
              
          },
            {
                headerName: "Parked Vehicles Safe",
                field: "parked_vehicles_safety",
                editable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ['Yes', "No"],
                },
              
                cellStyle: params => {
                  if (params.value === 'No') {
                    //mark police cells as red
                    return {color: 'black',backgroundColor:"orange",fontWeight:"600"};
                }
                return {color: 'black',backgroundColor:"#6bd46b",fontWeight:"600"};
              },
              valueSetter:params=>{
                console.log(params,params.value,"1235")
                var updated_time=new Date()
                  var params={
                    _id:params.data._id,
                    value:params.newValue,
                    column:"parked_vehicles_safety",
                    updated_time
                  }

                  redirectURL.post("dashboard/updateWeatherDataOnGridTwo",params).then((response)=>{
                    if(response.data.message == "Success"){
                       this.componentDidMount()
                    }
                })

                
          }
            },
            {
                headerName: "Infra Safe",
                field: "infrastructure_safety",
                editable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ['Yes', "No"],
                },
                cellStyle: params => {
                  if (params.value === 'No') {
                    //mark police cells as red
                    return {color: 'black',backgroundColor:"orange",fontWeight:"600"};
                }
                return {color: 'black',backgroundColor:"#6bd46b",fontWeight:"600"};
              },
              valueSetter:params=>{
                console.log(params,params.value,"1235")
                var updated_time=new Date()
                  var params={
                    _id:params.data._id,
                    value:params.newValue,
                    column:"infrastructure_safety",
                    updated_time
                  }

                  redirectURL.post("dashboard/updateWeatherDataOnGridTwo",params).then((response)=>{
                    if(response.data.message == "Success"){
                       this.componentDidMount()
                    }
                })

                
          }
            },
            
        ]

      
        const columnDefs3 = [
           
          {
              headerName: "Rail Yards (Loading Yards)",
              field: "railyard_name",
              editable: true ,
             
          },
          // {
          //     headerName:"Vehicle Stock(Cars)",
          //     field:"vehicle_stocks",
          //     editable:true,
          //     width:105,
          // },
          // {
          //     headerName: "Weather",
          //     field: "weather",
          //     width:190,
          //     editable: hideEdit,
          //     cellEditor: 'agSelectCellEditor',
          //     cellEditorParams: {
          //         values: ['Hailstorm', "Thunderstorm", "Rain", "Dust storm during last 24 hours"],
          //     },
          // },
       
          {
            headerName: "Weather Forecast(Today)",
            field: "weather_data",
            editable: false,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Warning', "No Warning"],
            },
          
            cellStyle: params => {
              if (params.value === 'No warning') {
                //mark police cells as red
                return {color: 'black',backgroundColor:"#6bd46b",fontWeight:"600"};
            }else{
            return {color:"black",backgroundColor:'orange',fontWeight:"600"};
            }}
,
          
            
        },
          {
              headerName: "Parked Vehicles Safe",
              field: "parked_vehicles_safety",
              editable: true,
              cellEditor: 'agSelectCellEditor',
              cellEditorParams: {
                  values: ['Yes', "No"],
              },
            
              cellStyle: params => {
                if (params.value === 'No') {
                  //mark police cells as red
                  return {color: 'black',backgroundColor:"orange",fontWeight:"600"};
              }
              return {color: 'black',backgroundColor:"#6bd46b",fontWeight:"600"};
            },
            valueSetter:params=>{
              console.log(params,params.value,"1235")
              var updated_time=new Date()
                var params={
                  _id:params.data._id,
                  value:params.newValue,
                  column:"parked_vehicles_safety",
                  updated_time
                }

                redirectURL.post("dashboard/updateWeatherDataOnGridThree",params).then((response)=>{
                  if(response.data.message == "Success"){
                     this.componentDidMount()
                  }
              })

              
        }
          },
          {
              headerName: "Infra Safe",
              field: "infrastructure_safety",
              editable: true,
              cellEditor: 'agSelectCellEditor',
              cellEditorParams: {
                  values: ['Yes', "No"],
              },
              cellStyle: params => {
                if (params.value === 'No') {
                  //mark police cells as red
                  return {color: 'black',backgroundColor:"orange",fontWeight:"600"};
              }
              return {color: 'black',backgroundColor:"#6bd46b",fontWeight:"600"};
            },
            valueSetter:params=>{
              console.log(params,params.value,"1235")
              var updated_time=new Date()
                var params={
                  _id:params.data._id,
                  value:params.newValue,
                  column:"infrastructure_safety",
                  updated_time
                }

                redirectURL.post("dashboard/updateWeatherDataOnGridThree",params).then((response)=>{
                  if(response.data.message == "Success"){
                     this.componentDidMount()
                  }
              })

              
        }
          },
          
      ]

        const columnDefs = [
            {
                headerName: "",
                field: "approve",
                pinned: "right",
                cellRenderer: function(params){
                    return '<button class="btn btn-success" style="padding:1px 20px 1px 20px">Approve</button>'
                },
                hide: !hideEdit
            },
            {
                headerName: "Location",
                field: "plant_location",
                editable: false
            },
            // {
            //     headerName: "Weather",
            //     field: "weather",
            //     width:190,
            //     editable: hideEdit,
            //     cellEditor: 'agSelectCellEditor',
            //     cellEditorParams: {
            //         values: ['Hailstorm', "Thunderstorm", "Rain", "Dust storm during last 24 hours"],
            //     },
            // },
            {
                headerName: "Hailstorm / Thunderstorm / Rain / Dust storm during last 24 hours",
                field: "weather_condition",
                editable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ['Hailstorm', "Thunderstorm", "Rain", "Dust storm", "No"],
                },
            },
            {
                headerName: "Parked Vehicles Safe",
                field: "parked_vehicles_safety",
                editable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ['Yes', "No"],
                },
            },
            {
                headerName: "Infrastructure Safe",
                field: "infrastructure_safety",
                editable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ['Yes', "No"],
                },
            },
            {
                headerName: "Remarks (Give Details if any abnormality found regarding vehicles or infrastructure safety)",
                field: "remarks",
                editable: true,
                
            },
        ]
        
        

        // const scColumns = [
        //     {
        //         headerName: "",
        //         field: "approve",
        //         pinned: "right",
        //         cellRenderer: function(params){
        //             return '<button class="btn btn-success" style="padding:1px 20px 1px 20px">Approve</button>'
        //         },
        //         hide: !hideEdit
        //     },
        //     {
        //         headerName: "Sea Port",
        //         field: "plant_location",
        //         editable: false
        //     },
        //     // {
        //     //     headerName: "Weather",
        //     //     field: "weather",
        //     //     width:190,
        //     //     editable: hideEdit,
        //     //     cellEditor: 'agSelectCellEditor',
        //     //     cellEditorParams: {
        //     //         values: ['Hailstorm', "Thunderstorm", "Rain", "Dust storm during last 24 hours"],
        //     //     },
        //     // },
        //     {
        //         headerName: "Hailstorm / Thunderstorm / Rain / Dust storm during last 24 hours",
        //         field: "weather_condition",
        //         editable: hideEdit,
        //         cellEditor: 'agSelectCellEditor',
        //         cellEditorParams: {
        //             values: ['Hailstorm', "Thunderstorm", "Rain", "Dust storm", "No"],
        //         },
        //     },
        //     {
        //         headerName: "Material handled",
        //         field: "material_handled",
        //         editable: hideEdit,
                
        //     },
        //     {
        //         headerName: "Is the Material Safe ?",
        //         field: "material_safety",
        //         editable: hideEdit,
        //         cellEditor: 'agSelectCellEditor',
        //         cellEditorParams: {
        //             values: ['Yes', "No"],
        //         },
        //     },
        //     {
        //         headerName: "Remarks",
        //         field: "remarks",
        //         editable: hideEdit,
                
        //     }
        // ]
        // if(this.state.screenType == "admin")
        // {
        //     columnDefs.push({
        //         headerName: "Approved by",
        //         field: "",
        //         width: 350,
        //         valueGetter: function(params)
        //         {
        //             return "Last Approved By SND on "+getHyphenDDMMMYYYYHHMM(params.data.approved_on)
        //         }
        //     })
        //     scColumns.push({
        //         headerName: "Approved by",
        //         field: "",
        //         width: 350,
        //         valueGetter: function(params)
        //         {
        //             return "Last Approved By LOG-SC on "+getHyphenDDMMMYYYYHHMM(params.data.approved_on)
        //         }
        //     })
        // // }


        const columnwithDefs = [
          {
            headerName : "Plant Name",
            field : "plant_name",
            resizable : true,
            hide:false,
            width : 80
          },
          {
            headerName : "Forecast Today",
            field : "weather_today",
            resizable : true,
            width : 110
          },
          {
            headerName : "Forecast Tomorrow",
            field : "weather_tomorrow",
            resizable : true,
            width : 110
          }
        ]
        
        const dStyles1={
          width:'100%',
          height:'50em',
          // marginTop : "20px"
        }
        const forecastOption = [
          { value: "Today", label: "Today" },
          { value: "Tomorrow", label: "Tomorrow" },
        ];
        const colourStyles = {
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);
            console.log("stlel");
            console.log({ data, isDisabled, isFocused, isSelected });
            return {
              ...styles,
              backgroundColor: isFocused ? "blue" : null,
              color: isFocused?"white":"black",
            };
          }
        };
        var mgmtweathermap = ""
        try{
          mgmtweathermap = (this.props.match.path == "/mgmtweathermap")?"Mobile": "";
        }
        catch(e){
          
        }

      


        console.log(this.state.screen, "this.state.screen")
        return (
            <div class="container-fluid" style={(this.state.screenView == "Mobile")?{marginTop:"2em"}:{}}>   
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="card col-xl-12 col-lg-12 col-md-12">
                <div className="card-header text-center">
                    <h5 style={{border:"1px solid #ccc",padding:"1%"}}>
                    {/* <span style={{position:"relative",left:"25%",fontSize:"15px",color:"#171C8F"}}>
                        Last-Refresh-Rate : {this.state.from_current_Date}
                    </span> */}
                    <span style={{position:"relative",left:"-15%",fontSize:"15px",color:"#171C8F"}}>
                        Updated on : {this.state.lastRunTime}
                    </span>
                    <span className='' style={{position:"relative",left:"7%"}}>WEATHER REPORT DATA</span>
                    <span style={{position:"relative",right:"-15%",fontSize:"15px",color:"#171C8F"}}>
                        Manually Screen Updated On: {this.state.manualUpdatedTime}
                    </span>
                    {/* <span style={{position:"relative",left:"22%",fontSize:"15px",color:"#171C8F"}}>
                        From-Date : {this.state.from_current_Date}
                    </span>
                    <span style={{position:"relative",left:"23%",fontSize:"15px",color:"#171C8F"}}>
                        To-Date : {this.state.to_current_Date}
                    </span> */}
                    </h5>
                </div>
                    <div className='row'>
                        <div className='col-xl-12 col-lg-12'>
                        <div className="card">
                        <div className="card-body row" style={{height:"780px"}}>
                          <div className='col-xl-6 col-lg-6'>
                      
                            <div id="safetygrid" style={{ height: "320px",width: "100%",fontSize:"13px"}}  className="ag-theme-balham">
                                
                                <AgGridReact
                               headerHeight={90}
                                modules={this.state.modules}
                                columnDefs={columnDefs1}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.scrowData}
                                onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                                
                            />
                        </div>
                      
                        <div style={{marginTop
                        :"15px",marginBottom
                        :"15px"}}>
                            <div id="safetygrid" style={{ height: "400px",width: "100",fontSize:"13px"}}  className="ag-theme-balham">
                            {/* <div><h1 style={{fontSize:"13px"}}>Loading Railyards</h1></div> */}
                                <AgGridReact
                               headerHeight={90}
                                modules={this.state.modules}
                                columnDefs={columnDefs2}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.scrowData2}
                                onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                                
                            />
                        </div>
                        </div>
                        </div>
                        <div className='col-xl-6 col-lg-6'>
                            <div id="safetygrid" style={{ height: "520px",width: "100%",fontSize:"13px"}}  className="ag-theme-balham">
                            
                                <AgGridReact
                                headerHeight={90}
                                modules={this.state.modules}
                                columnDefs={columnDefs3}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.scrowData3}
                                onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                            />
                        </div>
                        </div>

                        
                        <div className={"alert alert-success "+this.state.showMsg} style={{textAlign:"center"}}><i className="fa fa-info-circle f16"></i>Selected Values are Updated !!</div>

                        </div>
                    </div>
                        </div>
                       
                     
                      
                    </div>
                   
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
            </div>
            <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
              
           </div>
        );
  }
}


function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};


        
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function timeConvert(n) {
  var num = n;
  var hours = (num / (3600));
  var rhours = Math.floor(hours);
  var minutes = (n) / (60);
  var rminutes = Math.round(minutes);
  return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
  var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

  marker.addListener('click', function() {
    
        if (currentinfowindow != null) {
          currentinfowindow.close();
          currentinfowindow = timeinfo;
          timeinfo.open(marker.get('map'), marker);
        }
        else
        {
          currentinfowindow = timeinfo;
          timeinfo.open(marker.get('map'), marker);
        }
        
        });
}


 function clearSelection() {
      if (selectedShape) {
        selectedShape.setEditable(false);
        selectedShape = null;
      }
    }

   function setSelection(shape) {
      clearSelection();
      selectedShape = shape;
      //console.log("selectedShape",selectedShape);
      shape.setEditable(true);
      
      selectColor(shape.get('fillColor') || shape.get('strokeColor'));
    }

  function deleteSelectedShape() {
    fencingLength=0;  
      if (selectedShape) {
        selectedShape.setMap(null);
      }
      // To show:
       drawingManager.setOptions({
         drawingControl: true
       });
    }

    
   function selectColor(color) {
        selectedColor = color;
        for (var i = 0; i < colors.length; ++i) {
          var currColor = colors[i];
          //console.log(colorButtons[currColor]);
          //colorButtons[currColor].style.border = currColor == color ? '1px solid #789' : '1px solid #fff';
        }

        // Retrieves the current options from the drawing manager and replaces the
        // stroke or fill color as appropriate.
        var polylineOptions = drawingManager.get('polylineOptions');
        polylineOptions.strokeColor = color;
        drawingManager.set('polylineOptions', polylineOptions);

        var rectangleOptions = drawingManager.get('rectangleOptions');
        rectangleOptions.fillColor = color;
        drawingManager.set('rectangleOptions', rectangleOptions);

        var circleOptions = drawingManager.get('circleOptions');
        circleOptions.fillColor = color;
        drawingManager.set('circleOptions', circleOptions);

        var polygonOptions = drawingManager.get('polygonOptions');
        polygonOptions.fillColor = color;
        drawingManager.set('polygonOptions', polygonOptions);
      }

  function setSelectedShapeColor(color) {
        if (selectedShape) {
          if (selectedShape.type == window.google.maps.drawing.OverlayType.POLYLINE) {
            selectedShape.set('strokeColor', color);
          } else {
            selectedShape.set('fillColor', color);
          }
        }
      }

    function makeColorButton(color) {
        var button = document.createElement('span');
        button.classNameName = 'color-button';
        button.style.backgroundColor = color;
        window.google.maps.event.addDomListener(button, 'click', function() {
          selectColor(color);
          setSelectedShapeColor(color);
        });

        return button;
      }

    function buildColorPalette() {
         var colorPalette = document.getElementById('color-palette');
         for (var i = 0; i < colors.length; ++i) {
           var currColor = colors[i];
           var colorButton = makeColorButton(currColor);
           colorPalette.appendChild(colorButton);
           colorButtons[currColor] = colorButton;
         }
         selectColor(colors[0]);
       }  
    
   function getSelectedShape(coords){
    var lt=[]
    //console.log("coordsLatlngs ",coords);
    for (let value of Object.values(coords)) {
      //console.log("Val is ",value); // John, then 30
      lt.push(value)
    }
    //console.log("Combine ",lt);
    document.getElementById('coords').value=lt;
    //return lt;
  }

  function loadScriptWeather(url){
    console.log(window.screen.availHeight,
    window.screen.availWidth)
    if(window.screen.availHeight <= 1024)
    {
      $("#mapweather").removeClass("col-md-8");
      $("#weathermapgrid").removeClass("col-md-4");
      $("#mapweather").addClass("col-md-8");
      $("#weathermapgrid").addClass("col-md-8");
    }
    var index  =window.document.getElementsByTagName("script")[1]
      var script = window.document.createElement("script")
      script.src=url
      script.async=true
      script.defer = true
      index.parentNode.insertBefore(script,index)
  }


import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import Select from 'react-select';
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import UpdateItem from './updaterow';
import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import axios from 'axios';
import NewDrawMap from '../common/newDrawMap'
import Modal from 'react-responsive-modal';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');


export default class BillingSummary extends Component {

	constructor(props){
		super(props);
		this.state = {
			currentDepartmentTitle: null,
			modules: AllModules,
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
      	    defaultColDef: {
      	        sortable: true,
      	        filter: true,
				editable: true,
				resizable: true
      		},
			rowData: [],
            originoption:{"value":"","label":"Select Origin"},
            destinationoption:{"value":"","label":"Select Destination"},
			editType: "fullRow",
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
		 
			frameworkComponents: {
				updateItem:UpdateItem,
                deleteItem:DeleteItem,
                consignmentActions:ConsignmentActions,
				//statusChange:StatusChange
			},

			rowModelType: "serverSide",
			paginationPageSize:100,
			cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters:[],
            sliderRso:"",
            sliderUpdate:"",
            transporter:"",
            truck_no:"",
            trucktype:"",
            deptcode:"",
            sources:[],
            destinations:[],
            sourceopt:"",
            oem_code:"",
            oem_name:"",
            industry_type:"",
            officer_mobile:"",
            officer_alteratemobile:"",
            officer_locations:[],
            officertype:"",
            rowid:"",
            sliderRouteTranslate:"",
            sliderBulk:"",
            file:"",            
			csvcontent:[],
            originoptions:[],
            destinationOptions:[],
            selected_period_type: {"label": "ALL", "value": "ALL"},
            billing_month:"",
            routeRowData:{},
            remarks_history_cols:[
                {
	  	          headerName: "Billing Id",
	  	          field: "billing_id",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                
		        {
	  	          headerName: "Remark",
	  	          field: "complete_remarks",
	  	          width: 180,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
	  	          headerName: "Updated Time",
	  	          field: "remark_time",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                  valueGetter:function(params){
                    try{
                        if(params.data.remark_time != '' && params.data.remark_time != undefined)
                        {
                            return getHyphenYYYYMMDDHHMMSS(params.data.remark_time)
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
                },

            ],
            remarks_data:[],

		};
        this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
        this.updateRowHandler = this.updateRowHandler.bind(this);
        this.deleteRowHandler = this.deleteRowHandler.bind(this);
	}
	getCurrentDepartmentCode(pathToMatch = null) {
		let department = null;
		let departmentName = null;
		switch(pathToMatch) {
			case "/tptoemmaster":
				department='SNDG';
				departmentName = " ";
				break;
			
			default:
				console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			currentDepartmentTitle:departmentName
		});
		return department;
	}
	componentDidMount(){
		if (googleAnalytics.page.enableGA) {
			this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

        }
        
        this.setState({
            loadshow:"show-n",
            overly:"show-n"
        })
        var s = this.props.location.search;
        var consigner_code = "";
        if(s != "")
        {
            var spl = s.split("?")
            var s1 = spl[1].split("&")
            var sorce = s1[0].split("=")
            var source = sorce[1];
            consigner_code = source;
            var srcname = s1[1].split("=")
            var sourcename = srcname[1].replace(/%20/g," ")

            var dest = s1[2].split("=")
            var destination = dest[1].replace(/%20/g," ")
            
            var period_type = s1[3].split("=")
            period_type = period_type[1].replace(/%20/g," ")

            // var gps_provider = s1[4].split("=")
            // gps_provider = gps_provider[1].replace(/%20/g," ")
            // if(gps_provider == "AXESTRACK_SND"){
            //     gps_provider = {"value":gps_provider,"label": "AXESTRACK"}
            // }
            // else
            // {
            //     gps_provider = {"value":gps_provider,"label": gps_provider}
            // }

            var truck_type = s1[5].split("=")
            truck_type = truck_type[1].replace(/%20/g," ")
            //var fromparent = this.props.match.params
            var sourceopt = source
            var sourcename = sourcename
            var destinationopt = destination
            
            this.setState({
                originoption: {"value":sourceopt,"label":sourcename},
                destinationoption: {"value":destination,"label":destination},
                selected_period_type: {"value":period_type,"label":period_type},
                // selected_provider: gps_provider,
                selected_truck_type: {"value":truck_type,"label":truck_type},
            })
            var p = {
                plant:sourceopt
            }
            redirectURL.post("/consignments/plantcoordinates", p)
            .then((response) => {
                //console.log("Ress ", response.data)
                var plantdata = response.data
                //console.log("plantcoords ", JSON.parse(plantdata[0].plant_coordinates))
                var plantcoords = JSON.parse(plantdata[0].plant_coordinates)
                this.setState({
                    plantcoords:{coord:plantcoords,name:this.state.originoption.label},
                    plantname:this.state.originoption.label,
                })
            })
            var formdata = {
                consigner_code: sourceopt,
                consignee_city: destinationopt,
                // gps_provider: gps_provider.value,
                period_type: period_type,
                truck_type: truck_type,
            }
            this.setState({
                loadshow: "show-m",
                overly: "show-m"
            })
            this.onLoadFormRequest(formdata)
        }
       
		// get department code to filter records by department
		let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
		this.setState({
			departmentCode:currentDepartmentCode
		});
		 /*Consignments List*/
         var map_plants = {"B": "TVP Bangalore","BP":"Bengaluru Primary","F": "Gurgaon", "G": "Gujarat", "I": "Siliguri", "M": "Manesar", "N": "TVP Nagpur"}
        redirectURL.post("/dashboard/getSourceFromBaseRoutesNewData")
        .then((response) => {
            // console.log("Ress ", response.data)
            var plantdata = response.data;
            var originoptions = [];
            if(plantdata.length > 0)
            {
                plantdata.map((item) => {
                if(item != undefined || item != "" || item != null){
                    originoptions.push({
                        value:item,
                        label: map_plants[item]
                    })
                }
                })
            }
            this.setState({
                originoptions: originoptions,
            })
        })	
        redirectURL.post("dashboard/getDestinationFromBaseRoutesData", {"consigner_code": consigner_code}).then((response1)=>{
            var items=[];
            var destinationarr = response1.data;
            // console.log(destinationarr,"destinationarr")
            var check = destinationarr.map((item) =>{
                items.push({
                    value:item,label:item
                })

            })
            this.setState({
               
                destinationOptions: items,                        
            })
            // redirectURL.post("dashboard/getInitialDataForBaseRouteSummaryNewData", {}).then((response2)=>{
            //     // console.log(response2.data)
            //     var lspusers_options = [];
            //     var truck_types = [];
            //     var period_types_options = [];
            //     // var period_types = sortPeriodTypes(response2.data.period_types);
            //     var period_types = response2.data.period_types;
            //     // console.log(period_types, "period_types")
            //     response2.data.lsp_users.map((e)=>{
            //         if(e != "NA")
            //         {
            //             var label = e
            //             if(e == "AXESTRACK_SND"){label = "AXESTRACK"}
            //             lspusers_options.push({"label": label, "value": e})
            //         }
            //     })
            //     response2.data.truck_types.map((e)=>{
            //         truck_types.push({"label": e, "value": e})
            //     })
            //     period_types.map((e)=>{
            //         period_types_options.push({"label": e, "value": e})
            //     })
            //     // console.log(period_types, "period_types options")
            //     // console.log("check1")
            //     this.setState({
            //         originoptions: originoptions,
            //         destinationOptions: items,
            //         lspusers_options: lspusers_options,
            //         truck_types: truck_types,
            //         period_types: period_types_options,                        
            //     })
            //     if(s === ""){
            //         this.setState({
            //             overly: "show-n",
            //             loadshow: "show-n"
            //         })
            //     }
            //     // console.log("check987")
            // })
        })  
    };
   
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }
    onShowRouteDiv = (e) => {
		if (googleAnalytics.page.enableGA) {
            let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
		// console.log("IN onShowRouteDiv, Rute params ", e)
		this.setState({
			loadshow:'show-m'
		});

	}
    onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			tabsliderTranslate:'',
            sliderRouteTranslate:'',
			showDiv:'show-n',
			sliderTranslatesidebar:"",
			sliderCommentTranslate:"",
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n',
			loadshow:'show-n',
			slideuploadeway:""
		});
	}
    onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderCommentTranslate : "",
			sliderSegmentTranslate : "",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n',
			logsSLider : ''
		});
	}
	onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
    };
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);
           
         
          
    }
    onLoadFormRequest = async(formdata) =>{ 
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        // console.log("formData1144",formdata)
      await redirectURL.post("dashboard/getBaseRouteAnalysisReport", formdata).then(async (response)=>{
            // console.log(response.data)
            if(response.data.status == "Failure")
            {
                this.setState({
                    complete_analysis: [],
                    base_routes_anlsysis: [],
                    transporters_analysis: [],
                    complete_trips: [],
                    rowData: [],
                    btn1: "btn-danger",
                    btn2: "btn-danger",
                    btn3: "btn-default",
                    btn4: "btn-default",
                    btn5: "btn-default",
                    // columnDefs: columnDefs,
                    loadshow: "show-n",
                    overly: "show-n"
                })
            }
            else
            {
                var complete_analysis = JSON.parse(response.data.complete_analysis);
                // console.log("complete_analysis",complete_analysis)
                var base_routes_anlsysis = JSON.parse(response.data.base_routes_anlsysis);
                var transporters_analysis = JSON.parse(response.data.transporters_analysis);
                // console.log("transporters_analysis",base_routes_anlsysis)
                let leastAvgDistance = 0
                let baseRouteforCounter = 0
                base_routes_anlsysis.map(e =>{
                    if(leastAvgDistance > e.avg_google_distance ){
                    }else{
                        leastAvgDistance = e.avg_google_distance
                        baseRouteforCounter = e.base_route_no
                    }
                })
                // console.log('leastAvgDistance',transporters_analysis,'baseRouteforCounter',base_routes_anlsysis)
                // for counters the calc data start
                var totalCountTransporter = transporters_analysis[(transporters_analysis.length)-1]
                var hightCount = 0
                var baseCount = 0
                var baseName = '';
                var highest_base_routes = [];
                for(let i in totalCountTransporter){
                    baseCount += 1
                    if(i.includes("Base")){
                        let hCount = totalCountTransporter[i]
                        if(hightCount <= hCount ){
                            hightCount = hCount
                            baseName = i
                            highest_base_routes.push({"base_route_no": i.split(" ")[2], "no_of_trips": hightCount})
                        }
                    }  
                }
                // console.log('highest_base_routes',highest_base_routes)
                // below loop is when there are multiple same base route no then we need to consider the route which has less avg_google_distance
                if(highest_base_routes.length > 1)
                {
                    var hightCount = 0
                    var baseName = '';
                    highest_base_routes.map((k)=>{
                        var filtered_route = base_routes_anlsysis.filter(e => e.base_route_no == parseInt(k.base_route_no));
                    
                        let hCount = filtered_route[0]['avg_google_distance']
                        if(hightCount > hCount || hightCount == 0){
                            // console.log('h1208', hCount)
                            hightCount = hCount
                            baseName = "Base Route "+k.base_route_no
                        }
                    })
                }
                
                // counters data end
              await redirectURL.post("dashboard/getCompleteTripsDataForBaseRoutesData", formdata).then(async (response)=>{
                   var data = response.data;
                    console.log(data, "complete trips")
                    await data.map((e)=>{
                        var filtereddata =  base_routes_anlsysis.filter(k =>{
                            if(k.consignment_codes.filter(value => e.consignment_codes.includes(value)).length)
                            {
                                return true;
                            }
                        })
                        if(filtereddata.length)
                        {
                            e.base_route_no = "Base Route "+filtereddata[0]['base_route_no']
                        }
                    })

                    let noOfTripsTravelled = []
                    let validTrips = []
                    try{
                        noOfTripsTravelled = complete_analysis[0].no_of_trips_travelled
                        validTrips = complete_analysis[0].valid_trips
                    }catch(e){
                        console.log("e",e)                   
                    }

                    this.setState({
                        base_routes_anlsysis: base_routes_anlsysis,
                        transporters_analysis: transporters_analysis,
                        complete_trips: data,
                        defaultCompleteTrips: data,
                        defaultTrips: data,
                        rowData: complete_analysis,
                        // columnDefs: colDefs2,
                        btn1: "btn-danger",
                        btn2: "btn-danger",
                        btn3: "btn-default",
                        btn4: "btn-default",
                        btn5: "btn-default",
                        loadshow: "show-n",
                        overly: "show-n",
                        // hideSummaryTab: false,
                        gridHeight: "160px",
                        pageTitle: "Summary",
                        complete_analysis: complete_analysis,
                        "highestBaseRouteCount": hightCount,
                        "highestBaseRouteName" :baseName,
                        "total_optimized_routes":baseCount,
                        'noOfTransporters' : (transporters_analysis.length)-1,
                        'total_no_trips_travelled': noOfTripsTravelled,
                        'total_valid_trips': validTrips,
                    })
                })
            }
            
        })
    }
    getIgnoredRoutes = (event) =>{
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        try{
            var tcheck = document.getElementById("tollcheck")
            if(tcheck.checked == true)
            {
                tcheck.checked = false;
            }
        }
        catch(e){

        }
		this.setState({
            loadshow:'show-m',         
            overly:'show-m',         
            tolllist:[]
        });

        if(this.state.originoption.value != "" && this.state.destinationoption.value != "")
        {

            var formdata = {
                consigner_code:this.state.originoption.value,
                consignee_city:this.state.destinationoption.value,
            }
            console.log('formdata',formdata)
            // this.onLoadFormRequest(formdata)
            redirectURL.post("/dashboard/getIgnoredRoutesNewData",formdata).then((response) => {
                console.log("570",response.data);
                // let responseData = response.data;
                // let truckList = []
                // responseData.trucksList.map(e => truckList.push({ value: e, label: e }))
                if(response.data.length>0){
                    this.setState({
                        ignoredRoutes: response.data,
                        rowData: response.data,
                        overly: "show-n",
                        loadshow : "show-n"
                    })
                }
                else{
                    this.setState({
                        ignoredRoutes: [],
                        rowData: [],
                        overly: "show-n",
                        loadshow : "show-n"
                    })
                }
            }).catch((e) => {
                console.log("error", e);
            })
        }
        else
        {
            this.setState({
                basicTitle: "Please select Consigner and Consignee City",
                basicType: "warning",
                show: true,
                overly: "show-n",
                loadshow: "show-n"
            })
        }
        console.log(this.state.rowData,"624")
    }
    onClickUpdateRowHandler(event)
	{
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
           let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
           let pageTitle = "Update OEM Master Data";
           if (currentDepartmentTitle) {
               pageTitle = "Update OEM Master Data" +  currentDepartmentTitle;
           }
           
           let eventOptions = {
               "category": pageTitle,
               "action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
               "label": googleAnalytics.page.action.rowUpdated,
           }
           googleAnalytics.logEvent(eventOptions);
       }
      
       if(this.state.oem_name != "" && this.state.oem_code != ""
       && this.state.industry_type != "")
       {
           var params = {
               rowID:this.state.rowid,
               oem_code:this.state.oem_code,
               oem_name:this.state.oem_name,
               industry_type:this.state.industry_type,
               transporter_code:localStorage.getItem("transportercode")
           }
           redirectURL.post("/dashboard/updateoemitem", params,{
               headers:{
                   'Content-Type': 'application/json'
               }
           }).
           then(
               (response)=>{
                   //console.log("update response is : ",response.data);
                   //console.log("Update Response ",response.data.result)
                   if(response.data.status == "success")
                   {
                       this.setState({
                           show: true,
                           basicTitle:'Successfully update data',
                           basicType:"success",
                           sliderUpdate:"",
                           overly:"show-n"
                       });
                       this.onLoadData();
                   }
                   
                   else{
                       this.setState({
                           show: true,
                           basicTitle:'Something went wrong',
                           basicType:"danger",
                       });
                   }
                   
               }
           )
           .catch(function(error){
               console.log(error);
           });
       }
       else{
           this.setState({
               show: true,
               basicTitle:'All * fields should not be empty',
               basicType:"danger",
           });
       }

    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
        console.log(event,"event");
        var d = new Date(event._d);
        // console.log('startDate',d, 'changed date', moment.parseZone(event._d).format('YYYY-MM'))
        var startdate =  moment.parseZone(event._d).format('YYYY-MM')

        this.setState({
            selection_month: startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }



    
    onClickNewAddRow(event)
	{
        event.preventDefault();
		 if (googleAnalytics.page.enableGA) {
			let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
			let pageTitle = "Add OEM Master Data";
			if (currentDepartmentTitle) {
				pageTitle = "Add OEM Master Data" +  currentDepartmentTitle;
			}
			
			let eventOptions = {
				"category": pageTitle,
				"action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
				"label": googleAnalytics.page.action.rowUpdated,
			}
			googleAnalytics.logEvent(eventOptions);
        }
        if(this.state.oem_name != "" && this.state.oem_code != ""
       && this.state.industry_type != "")
        {
           var params = {
                oem_code:this.state.oem_code,
                oem_name:this.state.oem_name,
                industry_type:this.state.industry_type,
                transporter_code:localStorage.getItem("transportercode")
            }
            var fdata = {
                    rownode:params,
                    transporter_code:localStorage.getItem("transportercode")
            }
            redirectURL.post("/dashboard/saveoemitem", params,{
                headers:{
                    'Content-Type': 'application/json'
                }
            }).
            then(
                (response)=>{
                    //console.log("update response is : ",response.data);
                    //console.log("Update Response ",response.data.result)
                    if(response.data.status == "success")
                    {
                        this.setState({
                            show: true,
                            basicTitle:'Successfully added data',
                            basicType:"success",
                            sliderRso:"",
                            overly:"show-n",
                            loadshow:"show-n"
                        });
                        this.onLoadData();
                    }
                    
                    else{
                        this.setState({
                            show: true,
                            basicTitle:'Something went wrong',
                            basicType:"danger",
                        });
                    }
                    
                }
            )
            .catch(function(error){
                console.log(error);
            });
        }
        else{
            this.setState({
                show: true,
                basicTitle:'All * fields should not be empty',
                basicType:"danger",
            });
        }

    }
    
    /*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

    
	 onAddRow() {
        // var newItem = 
        // {
        //     "truck_no":"",
        //     "truck_type":"",
        //     "transporter_code":"",
        //     "dept_code":""
        // };
        // console.log("newItem",newItem);
        // var res = this.gridApi.updateRowData({ add: [newItem] });
        this.setState({
			sliderRso:'slider-translate',
            overly:'show-m',
            oem_code:"",
            oem_name:"",
            industry_type:""
		})
        
    }
    onRowClicked(cell){
        if(cell.colDef.field == "map_routes")
        {
            console.log(cell.data,"817")
            var gateOutTime = cell.data.gate_out_time
            gateOutTime = moment.parseZone(gateOutTime).format("YYYY-MM-DD HH:mm:ss")
            var truckNo = cell.data.truck_no
            var params = {
                gate_out_time:gateOutTime,
                truck_no:truckNo,
            }
            console.log("796",params)
            redirectURL.post("/dashboard/gettingMapDataforignoredroutes", params,{
                headers:{
                    'Content-Type': 'application/json'
                }
            }).then((response)=>{
                console.log(response,"807")
                if(response.data.route_coords.length>0){
                    this.setState({
                        sliderRouteTranslate:"slider-translate-50p",
                        mapfor: "map_routes",
                        routeRowData: cell.data,
                        route_coords:eval(response.data.route_coords),
                        breaks_list: [],
                        overly:"show-m",
                        loadshow:'show-n',
                        truck_no : cell.data.truck_no
                    })
                }else{
                    this.setState({
                        show: true,
						basicTitle:'No Route Data',
						basicType:"error",
                    })
                }
            })
        }
        
        
    }
    
    
	 updateRowHandler = async(cell) => {
       
        var rowid = cell._id;
        var oem_code = cell.oem_code;
        var oem_name = cell.oem_name;
        var industry_type = cell.industry_type;
        await this.setState({
			sliderUpdate:'slider-translate',
            overly:'show-m',
            oem_code:oem_code,
            oem_name:oem_name,
            industry_type:industry_type,
            rowid:rowid
		})
        
    }


    
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            sliderRso:"",
            sliderUpdate:"",
            sliderBulk:""
		});
		
    }

    onChangeTruckType(trucktype){
		this.setState(
			{ trucktype },
			() => console.log(`Option selected:`, this.state.trucktype)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
   
//     onClickGenerateBill(){
//         this.setState({
//             overly: "show-m",
//             loadshow: "show-m"
//         })
       
//         var billing_getter = this.state.selection_month;
//         console.log(billing_getter,"billing_getter");
        
//         if(billing_getter !== "" && billing_getter !== undefined){
//             var params={                
//                 billing_month:billing_getter,              
//             }
//             console.log(params,"params");
//             redirectURL.post('/dashboard/selectingbillingmonth',params)
//             .then((response) => {

//                 var records = JSON.parse(response.data.otpt);
//                 // records = records.sort(GetSortDescOrder("billing_id"));
//                 console.log("recordsbilling ", records)
                
//                 this.setState({
//                     rowData: records,
//                     sliderBulk: "",
//                     overly: "show-n",
//                     loadshow: "show-n"
//                 });
//                 // window.location.href="/billingsummary" 
//             })
//             .catch(function (error) {
//                 console.log(error);
//             });
     
//         // }
//     }
// }
    
    onChangeTransporter(transporter){
		this.setState(
			{ transporter },
			() => console.log(`Option selected:`, this.state.transporter)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    onChangeDepartment(deptcode){
		this.setState(
			{ deptcode },
			() => console.log(`Option selected:`, this.state.deptcode)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    deleteRowHandler(cell)
	{
		//  console.log("Here",cell);
		 if (googleAnalytics.page.enableGA) {
			let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
			let pageTitle = "OEM Master Data";
			if (currentDepartmentTitle) {
				pageTitle = "OEM Master Data" +  currentDepartmentTitle;
			}
			
			let eventOptions = {
				"category": pageTitle,
				"action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
				"label": googleAnalytics.page.action.rowUpdated,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		var fdata = {
	    		rownode:cell
	    }
	    redirectURL.post("/dashboard/deleteoemitem", fdata,{
			headers:{
				'Content-Type': 'application/json'
			}
		}).
		then(
			(response)=>{
				//console.log("update response is : ",response.data);
				//console.log("Update Response ",response.data.result)
				if(response.data.status == "success")
				{
					this.setState({
						show: true,
						basicTitle:'Successfully delete item',
						basicType:"success",
                        loadshow:"show-n",
                        overly:"show-n",
                        sliderRso:""
                    });
                    this.onLoadData();
				}
				
				else{
					this.setState({
						show: true,
						basicTitle:'Something went wrong',
						basicType:"danger",
					});
				}
				
			}
		)
		.catch(function(error){
			console.log(error);
		});

    }
    changeOriginHandler = originoption => {
        this.setState(
            { originoption }
        );
        // redirectURL.post("dashboard/getDestinationFromBaseRoutes", {"consigner_code": originoption.value}).then((response)=>{
        //     var items=[];
        //     var destinationarr = response.data;
        //     var check = destinationarr.map((item) =>{
        //         items.push({
        //             value:item,label:item 
        //         })
        //     })
        //     Promise.all([check]).then(()=>{
        //         this.setState({
        //             destinationOptions: items
        //         })
        //     })
            
        // })
    }
    changeDestinationHandler = destinationoption => {
        // var formdata = {}
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['consigner_code'] = this.state.originoption.value
        // }
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['consignee_city'] = destinationoption.value
        // }
        // redirectURL.post("dashboard/getInitialDataForBaseRouteSummary", formdata).then((response2)=>{
        //     var lspusers_options = [];
        //     var truck_types = [];
        //     var period_types_options = [];
        //     response2.data.lsp_users.map((e)=>{
        //         if(e != "NA")
        //         {
        //             var label = e
        //             if(e == "AXESTRACK_SND"){label = "AXESTRACK"}
        //             lspusers_options.push({"label": label, "value": e})
        //         }
        //     })
        //     response2.data.truck_types.map((e)=>{
        //         truck_types.push({"label": e, "value": e})
        //     })
        //     var period_types = sortPeriodTypes(response2.data.period_types);
        //     period_types.map((e)=>{
        //         period_types_options.push({"label": e, "value": e})
        //     })
        //     this.setState({
        //         destinationoption,
        //         // originoptions: originoptions,
        //         // destinationOptions: items,
        //         lspusers_options: lspusers_options,
        //         truck_types: truck_types,
        //         period_types: period_types_options,
        //         // overly: "show-n",
        //         // loadshow: "show-n"
        //     })
        // })
        this.setState(
            { destinationoption }
        );
    
    }
    changePeriodTypeHandler = selected_period_type => {
        // var formdata = {}
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['consigner_code'] = this.state.originoption.value
        // }
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['consignee_city'] = this.state.destinationoption.value
        // }
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['gps_provider'] = this.state.selected_provider.value
        // }
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['period_type'] = selected_period_type.value
        // }
        // redirectURL.post("dashboard/getInitialDataForBaseRouteSummary", formdata).then((response2)=>{
        //     var truck_types = [];
        //     response2.data.truck_types.map((e)=>{
        //         truck_types.push({"label": e, "value": e})
        //     })
            this.setState({
                selected_period_type: selected_period_type,
                // truck_types: truck_types,
            })
        // })
    }
    changeTruckTypeHandler = selected_truck_type => {
        this.setState({
            selected_truck_type: selected_truck_type,
        })
    }
    onChangeSourceItem(sourceopt){
		this.setState(
			{ sourceopt },
			() => console.log(`Option selected:`, this.state.sourceopt)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    onChangeOfficerType(officertype){
		this.setState(
			{ officertype },
			() => console.log(`Option selected:`, this.state.officertype)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    selectsources(){
		let items = [];    
		try{
			if(this.state.sources.length > 0)
			{
				this.state.sources.map((item) =>{
					items.push({"value":item.geofence_name,"label":item.geofence_name})
				})
			}
		}
		catch(e){

		}		
		return items;
    }
    
    onClickShowBulk()
    {
        this.setState({
            overly:"show-m",
			sliderBulk:'slider-translate',
        })
    }
    

	changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'oem_name',
					inputName: 'oem_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'oem_code',
					inputName: 'oem_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'industry_type',
					inputName: 'industry_type',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		
	}
    onSubmitBulkUpload(event)
    {
        event.preventDefault()

        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Buk Upload",
				"action": "form",
				"label": "Buk Upload",
			}
			googleAnalytics.logEvent(eventOptions);
        }
       
        var params = {   
            csvcontent:this.state.csvcontent,
        }
		console.log("content ",this.state.csvcontent)
        console.log("Params ", params)
        redirectURL.post("/dashboard/bulkuploadoemdata", params)
        .then((response) => {
            if(response.data.status == "success")
				{
					this.setState({
						show: true,
						basicTitle:'Successfully added items',
						basicType:"success",
                        loadshow:"show-n",
                        overly:"show-n",
                        sliderBulk:"",
                        file:""
                    });
                    this.onLoadData();
				}
				
				else{
					this.setState({
						show: true,
						basicTitle:'Something went wrong',
						basicType:"danger",
					});
				}
        })
    }
    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
        var transporters = []
        var trans = this.state.transporters;
        if(trans.length > 0)
        {
            trans.map((item) => {
                transporters.push(item.value)
            })
        }
		const {usermanualmodal} = this.state;
		var hideChilds = true;
		if(this.state.departmentCode == "LOG-PRT")
		{
			hideChilds = false
		}
		const columnwithDefs = [
            {
                headerName: "",
                field: "map_routes",
                colId: "map_routes",
                width: 50,
                cellRenderer:'consignmentActions',
                filter: false,resizable: true,
                editable:false				
            },	
            {
                headerName: "Truck No",
                field: "truck_no",
                width:120,
                editable:false	
            },    
            {
                headerName: "Consignment Code",
                field: "consignment_code",
                width:120,
                editable:false	
            },
            // {
            //     "headerName": "Base Route",
            //     field: "base_route_no",
            // },
            {
                headerName: "Dealer Code",
                field: "consignee_code",
                width:120,
                editable:false	
            },
            // {
            //     headerName: "Dealer Name",
            //     field: "consignee_name",
            // },
            // {
            //     headerName: "Source",
            //     field: "consigner_code",
            // },
            // {
            //     headerName: "Destination",
            //     field: "consignee_city",
            // },   
            {
                headerName: "Invoice Time",
                field: "invoice_date",
                valueGetter: (params)=>{
                    return getHyphenYYYYMMDDHHMMSS(params.data.invoice_date)
                },
                width:140,
                editable:false	
            },
            {
                headerName: "Trip End Time",
                field: "trip_end_time",
                valueGetter: (params)=>{
                    return getHyphenYYYYMMDDHHMMSS(params.data.trip_end_time)
                },
                width:140,
                editable:false	
            },
            {
                headerName: "Transporter Code",
                field: "transporter_code",
                width:120,
                editable:false	
            },
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width:140,
                editable:false	
            },
            {
                headerName: "Origin",
                field: "consigner_code",
                width:120,
                editable:false,
                valueGetter: (params)=>{
                   if(params.data.consigner_code == "F"){
                        return "Gurgaon"
                   }
                   else if(params.data.consigner_code == "B"){
                    return "TVP Bangalore"
                   }
                   else if(params.data.consigner_code == "BP"){
                    return "Bengaluru Primary"
                   }
                   else if(params.data.consigner_code == "G"){
                    return "Gujarat"
                   }
                   else if(params.data.consigner_code == "I"){
                    return "Siliguri"
                   }
                   else if(params.data.consigner_code == "M"){
                    return "Manesar"
                   }
                   else if(params.data.consigner_code == "N"){
                    return "TVP Nagpur"
                   }
                   else{
                        return " "
                   }
                },
                "B": "TVP Bangalore","BP":"Bengaluru Primary","F": "Gurgaon", "G": "Gujarat", "I": "Siliguri", "M": "Manesar", "N": "TVP Nagpur"
            }, 
            {
                headerName: "Destination",
                field: "consignee_city",
                width:120,
                editable:false,

            },  
            {
                headerName: "Truck Type",
                field: "vehicle_mode",
                width:120,
                editable:false	
            },
            // {
            //     headerName: "Total GPS Distance (Km)",
            //     field: "total_travelled_trip_dist_km",
            // },
            // {
            //     headerName: "Distance Travelled (KM)",
            //     field: "total_trip_dist_with_google_km",
            //     valueGetter: (params)=>{
            //         var total = Math.round(parseFloat(params.data.total_trip_dist_with_google_km));
            //         // if(params.data.missing_distance_km != undefined)
            //         // {
            //         //     total += Math.round(parseFloat(params.data.missing_distance_km))
            //         // }
            //         return total;
            //     }
            // },
            // {
            //     headerName: "Route Ignored",
            //     field: "route_ignored",
            //     valueGetter: (params)=>{
            //         if(params.data.route_ignored != undefined)
            //         {
            //             return "Yes";
            //         }
            //         else
            //         {
            //             return "No";
            //         }
            //     }
            // },
            {
                headerName: "Reason",
                field: "reason",
                width: 120,
                editable:false	
                // valueGetter: (params)=>{
                //     if(params.data.club_load == 1 && params.data.route_ignored == 1)
                //     {
                //         return  "Club Load"
                //     }
                //     if(params.data.straight_lines == 1 && params.data.route_ignored == 1)
                //     {
                //         return "Due to straight lines"
                //     }
                //     else if(params.data.route_ignored != undefined)
                //     {
                //         if(params.data.away_from_plant == 1)
                //         {
                //             return "Away from Plant";
                //         }
                //         else if(params.data.expected_distance*0.8 > params.data.total_travelled_trip_dist_km)
                //         {
                //             return "Trip Distance < 80% of MSIL Approved Distance";
                //         }
                //         else{
                //             return "Trip Distance > 120% of MSIL Approved Distance";
                //         }
                //     }
                //     else
                //     {
                //         return "";
                //     }
                // }
            },
	    ];
		return(
			<div className="container-fluid">
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        > 
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
                    <div className="row col-xl-12 col-lg-12">
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Origin: </label>
                                <Select 
                                    name="originoption"
                                    closeMenuOnSelect={true} 
                                    value={this.state.originoption} 
                                    className="border-radius-0" 
                                    onChange={this.changeOriginHandler.bind(this)} 
                                    style={{borderRadius:"0px"}} 
                                    options={this.state.originoptions} required
                                />
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Destination: </label>
                                <Select 
                                    name="destinationoption"
                                    closeMenuOnSelect={true} 
                                    value={this.state.destinationoption} 
                                    className="border-radius-0" 
                                    onChange={this.changeDestinationHandler.bind(this)} 
                                    style={{borderRadius:"0px"}} 
                                    options={this.state.destinationOptions} required
                                />
                            </div>
                            {/* <div className="form-group col-xl-2 col-lg-2">
                                <label>GPS Provider: </label>
                                <Select 
                                    name="GPS Provider"
                                    closeMenuOnSelect={true} 
                                    value={this.state.selected_provider} 
                                    className="border-radius-0" 
                                    onChange={this.changeGpsProviderHandler.bind(this)} 
                                    style={{borderRadius:"0px"}} 
                                    options={this.state.lspusers_options} required
                                />
                            </div> */}
                            {/* <div className="form-group col-xl-2 col-lg-2">
                                <label>Period: </label>
                                <Select 
                                    name="Period"
                                    closeMenuOnSelect={true} 
                                    value={this.state.selected_period_type} 
                                    className="border-radius-0" 
                                    onChange={this.changePeriodTypeHandler.bind(this)} 
                                    style={{borderRadius:"0px"}} 
                                    options={this.state.period_types} required
                                />
                            </div> */}
                            {/* <div className="form-group col-xl-2 col-lg-2">
                                <label>Truck Type: </label>
                                <Select 
                                    name="Truck Type"
                                    closeMenuOnSelect={true} 
                                    value={this.state.selected_truck_type} 
                                    className="border-radius-0" 
                                    onChange={this.changeTruckTypeHandler.bind(this)} 
                                    style={{borderRadius:"0px"}} 
                                    options={this.state.truck_types} required
                                />
                            </div> */}

                            <div className="form-group col-xl-2 col-lg-2">
                                <button style={{marginTop:"30px"}} 
                                onClick={this.getIgnoredRoutes.bind(this)}
                                 type="button" className="btn btn-success">Submit</button>
                            </div>
                        </div>
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Ignored Trips</span>
                                       {/* <button type="button" onClick={this.onAddRow.bind(this)} className="btn btn-warning float-right">Add New Row</button> */}
                                        {/* <button href="/tptlogin/billingsummary" type="button" onClick={this.onClickGenerateBill.bind(this)} className="btn btn-warning float-right">Generate Bill</button> */}
                                        {/* <button type="button" onClick={() => this.onClickShowBulk(2)} className="btn btn-success float-right">View Bill</button> */}
                                </h5>
                            </div>
                            {/* <div className="col-xl-3 col-lg-3 form-group">
                                <label>Select Billing Date</label>
                                <Datetime
                                    value={this.state.billing_month}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{
                                        placeholder: '',
                                        name: 'billing_month',
                                        autoComplete: 'off'
                                    }}
                                    dateFormat="YYYY-MM-DD"
                                    name="billing_month"
                                    onChange={this.handlerStartDateTime.bind(this)}

                                />
                            </div> */}
                            {/* <div className="col-xl-3 col-lg-3 form-group">
                            <label>Select Month</label>
                                <Datetime
                                    value={this.state.billing_month} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ 
                                        placeholder: '', 
                                        name: 'billing_month', 
                                        autoComplete:'off' 
                                    }}
                                    dateFormat="YYYY-DD" 
                                    name="billing_month" 
                                    onChange={this.handlerStartDateTime.bind(this)} 

                                    />
                            </div>				   			 */}
		            		<div className="card-body">
                                <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        deleteRowHandler={this.deleteRowHandler}
                                        onCellClicked={this.onRowClicked.bind(this)}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        //updateRowHandler={this.updateRowHandler}
                                        //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				 <div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
					 <div className="slide-r-title">
				 		<h4>
                            Sidebars
                        </h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
						
				 		<div className="col-xl-12 col-lg-12">
				 		<div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg "+(this.state.alerterrshow)+" "+(this.state.alerterrmg)}>{this.state.csverrmessage}</div>
	        			
				 		</div>
				 	</div>
				 </div>
				
                
                <div className={"slide-r "+(this.state.sliderRso)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Add OEM Item
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickNewAddRow.bind(this)}>
							
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">OEM Code *</label>
								<input 
									type="text" 
									name="oem_code"
									value={this.state.oem_code}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							     
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">OEM Name *</label>
								<input 
									type="text" 
									name="oem_name"
									value={this.state.oem_name}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							     
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Industry Type *</label>
								<input 
									type="text" 
									name="industry_type"
									value={this.state.industry_type}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							                       
							
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						
                        </div>
					</div>
				</div>



                <div className={"slide-r "+(this.state.sliderUpdate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Remarks History
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickUpdateRowHandler.bind(this)}>
                        <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={this.state.remarks_history_cols}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.remarks_data}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        deleteRowHandler={this.deleteRowHandler}
                                        onCellClicked={this.onRowClicked.bind(this)}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        //updateRowHandler={this.updateRowHandler}
                                        //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
                            
						</form>

						
                        </div>
					</div>
				</div>
                {/* New Slider changes */}
                <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								View Route Data
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseUploadDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								
                                <NewDrawMap 
                                    mapFor={"geofence"}
                                    mapinfo={{"coords": this.state.route_coords,
                                            "breaks": this.state.breaks_list,
                                            "consigner_coordinates": this.state.routeRowData.consigner_coordinates,
                                            "consignee_coordinates": this.state.routeRowData.consignee_coordinates,
                                            "route_details": {"start_time": "",
                                                            "end_time": ""}}} 
                                    coords={this.state.route_coords} 
                                    consignments_list={[]}
                                    breaks={this.state.breaks_list} 
                                    truckno = {this.state.truck_no}
                                />
                                {(this.state.mapfor != "base_routes_map")?
                                    <div className="col-xl-12 col-lg-12 n-p-0">
                                        <div className="crm-numbers pb-0">
                                            <h3 className="subH">{"Details"}</h3>
                                            <div className="col-xl-12 col-lg-12 row">
                                                <div className="col route-block">
                                                    <label className="sidebar-label">Truck No</label>
                                                    <div>{this.state.routeRowData.truck_no}</div>
                                                </div>
                                                <div className="col route-block">
                                                    <label className="sidebar-label">Start Time</label>
                                                    <div>{(this.state.routeRowData.gate_out_time != '')?getDDMMYYYYHHMMSS(this.state.routeRowData.gate_out_time):"NA"}</div>
                                                </div>
                                                <div className="col route-block">
                                                    <label className="sidebar-label">End Time</label>
                                                    <div>{(this.state.routeRowData.trip_end_time != '')?getDDMMYYYYHHMMSS(this.state.routeRowData.trip_end_time):"NA"}</div>
                                                </div>
                                                {/* <div className="col route-block">
                                                    <label className="sidebar-label">Distance</label>
                                                    <div>{Math.round(this.state.routeRowData.total_trip_dist_with_google_km) +" Kms"}</div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                :""}
                                
                                
							</div>
						</div>
						
					</div>
                {/* end */}
                <div className={"slide-r "+(this.state.sliderBulk)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           View Bill
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                     
                            
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitBulkUpload.bind(this)}>
							
                            <div className="col-xl-12 col-lg-12 row form-group">
                            <div className="col-xl-12 col-lg-12 form-group">
                                <label>Select Billing Date</label>
                                <Datetime
                                    value={this.state.billing_month}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{
                                        placeholder: '',
                                        name: 'billing_month',
                                        autoComplete: 'off'
                                    }}
                                    dateFormat="YYYY-MM"
                                    name="billing_month"
                                    onChange={this.handlerStartDateTime.bind(this)}

                                />
                            </div>  
                            </div>
							 
							{/* <div className="col-xl-12 col-lg-12 c">
                            <button href="/tptlogin/billingsummary" type="button" onClick={this.onClickGenerateBill.bind(this)} className="btn btn-success">Get Bill</button>
							</div> */}
                            
						    {/* <div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_oem_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div> */}
						</form> 
                    
						
                        </div>
					</div>
				</div>
                {/* <div className={"slide-r "+(this.state.sliderBulk)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           View Bill
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                     
                            
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitBulkUpload.bind(this)}>
							
                            <div className="col-xl-12 col-lg-12 row form-group">
                            <div className="col-xl-12 col-lg-12 form-group">
                                <label>Select Billing Date</label>
                                <Datetime
                                    value={this.state.billing_month}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{
                                        placeholder: '',
                                        name: 'billing_month',
                                        autoComplete: 'off'
                                    }}
                                    dateFormat="YYYY-MM-DD"
                                    name="billing_month"
                                    onChange={this.handlerStartDateTime.bind(this)}

                                />
                            </div>  
                            </div>
							 
							<div className="col-xl-12 col-lg-12 c">
                            <button href="/tptlogin/billingsummary" type="button" onClick={this.onClickGenerateBill.bind(this)} className="btn btn-success">Generate Bill</button>
							</div>
                            
						    <div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_oem_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div> 
						</form> 
                
						
                        </div>
					</div>
				</div> */}
                


                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            

            </div>
              	
		);
	}
}
function GetSortDescOrder(prop) {    
    return function(a, b) {    
        if (a[prop] < b[prop]) {    
            return 1;    
        } else if (a[prop] > b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
}
import React, { Component } from 'react';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY  } from '../common/utils'; 
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import ExportsTrackingCountersComponent from "./exportstrackingcounterComponent";
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import TruckSidebarComponent from './consignmentSidebarComponent';

var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");

export default class SparesExportsDestinationPoints extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
    		pageTitle: "Spares Destination Ports Master",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
            modules: AllModules,
            sidebarSubHeader:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: [],
      	      
			rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,				
			groupDefaultExpanded: -1,
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:1000,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
            highseescnt:0,
            highseesarr:[],
            icdtoport:0,
            icdtoportarr:[],
            insideicdcnt:0,
            insideicdarr:[],
            insideportcnt:0,
            insideportarr:[],
            msilicdcnt:0,
            msilicdarr:[],
            originalData:[],
            polList:[],
            csvData:0
    	}
        this.onLoadDashboardData = this.onLoadDashboardData.bind(this);
		this.resetState = this.resetState.bind(this);
    }
   
    componentDidMount(){
        loadDateTimeScript();
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        })
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
        }
        
        redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			//console.log("resp ", resp)
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
        })
        
        
        this.onLoadDashboardData()
	};
    
    onLoadDashboardData()
    {
        redirectURL.post("/consignments/getSparesDestinationPorts").then((response)=>{
            var pols = response.data;
            this.setState({
                rowData:pols,
                loadshow:'show-n',
			    overly:'show-n',
            })
        }).catch((e)=>{
            console.log(e);
        })
    }
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			openUpdatedModal : false
		});
		
	}
	closeAlert = () => {
        this.setState({
            show: false
        });
    }
   

	onClickSaveGridState(){
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
        let screenpage = 'Exports Container Tracking';

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
        try{
            if(this.state.usergridstate.length > 0)
            {
                var windowstates = this.state.usergridstate;
                this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
                this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
                this.gridApi.setSortModel(windowstates[0].gridcolsort);
                this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
                
            }
        }
        catch(e){

        }
		
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

    };
    
	
    onCellRowClicked(e){
        if(e.colDef.field == "update")
        {
            var rowId = e.data._id;
            var container_number = e.data.container_no;
            if(rowId == undefined || rowId == '')
            {
                var region = e.data.region;
                var country = e.data.country;
                var port_of_discharge = e.data.port_of_discharge;
                if(region !=undefined && region !='' && country !=undefined && country !='' && port_of_discharge !=undefined && port_of_discharge !='')
                {
                    var reqparams = {
                        region : region,
                        country : country,
                        port_of_discharge : port_of_discharge,
                        cha_name : localStorage.getItem("username"),
                        cha_code : localStorage.getItem("cha_code"),
                    }
                    console.log(reqparams,"reqparams");
                    redirectURL.post("/consignments/addSparesDestinationPorts", reqparams)
                    .then((response) => {
                        if(response.data.message == "Success")
                        {
                            this.setState({
                                basicTitle:"Successfully Updated",
                                basicType : "success",
                                show : true,
                                overly:"show-n",
                                loadshow:"show-n",
                            })
                            this.onLoadDashboardData();
                        }
                        else if(response.data.message == "Exists")
                        {
                            this.setState({
                                basicTitle:"Data already exists",
                                basicType : "warning",
                                show : true
                            })
                            this.onLoadDashboardData();
                        }
                        else
                        {
                            this.setState({
                                basicTitle:"Failed to update.",
                                basicType : "warning",
                                show : true
                            })
                        }
                    })
                }
                else
                {
                    this.setState({
                        basicTitle:"Fields can not be empty.",
                        basicType : "warning",
                        show : true
                    })
                }
            }
        }
    }

    showBulkUpload(){
        this.setState({
            overly :"show-m",
            bulkslide : "slider-translate"
        })
    }
    resetUpload = () => {
        this.setState({
            bulkslide:'',
            overly:'show-n',
            file:''
        });
        document.getElementById("upform").reset();
    }
    changeFileHandler = async (e) => {
        var dt = '';
        const config = {
            headers: [
                { 
                    name: 'Container Number',
                    inputName: 'container_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Booking Id',
                    inputName: 'booking_id',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Dispatch Date From MSIL',
                    inputName: 'msil_gate_out',
                    required: false,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Export To',
                    inputName: 'export_to',
                    required: false,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },          
            ]
        }
        var data = CSVFileValidator(e.target.files[0], config)
        .then((csvData1) => {
            this.setState({
                csvData:csvData1.data
            });
            console.log(csvData1.data);
        })
        .catch(err => {})
        // console.log("e.target.files[0]",e.target.result);
        
        var out = new Promise(function(reject, resolve){
            var reader =  new FileReader();
            reader.onload = async function(e) {
                var contents = await e.target.result;
                resolve(contents);
            };
            var tt =  reader.readAsText(e.target.files[0]);
        });
        this.setState({
            file:e.target.files[0]
        });
        // console.log("e.target.files[0].type ", e.target.files[0]);
    
        if(e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
        {
            // console.log(fileData);
            // console.log(typeof(fileData))
            
        }
        else{
            e.target.value = null;
            this.setState({
                uploadFile:'',
                show: true, 
                basicType:'danger', 
                basicTitle:'Please upload file having extensions .csv only.',
            });
        }
        
    }
    
    uploadBulkFormHandler(event){
        event.preventDefault();
        var fileData = this.state.csvData;
        var flag=0;
        console.log(fileData,"fileData");
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        });
        if(flag == 0)
        {
            if(fileData.length > 0)
            {
                var reqparams = {
                    csvData : fileData,
                    cha_name : localStorage.getItem("username"),
                }
                console.log(reqparams,"reqparams");
                redirectURL.post("/consignments/bulkupdateLogScExports", reqparams).then((response)=>{
                    var resp=response.data.message;
                    console.log(resp)
                    this.setState({
                        basicTitle : resp,
                        basicType : "success",
                        show : true,
                        bulkslide:'',
                        loadshow:'show-n',
                        overly:'show-n',
                    })
                    //window.location.reload();
                    this.onLoadDashboardData();
                }).catch((e)=>{
                    console.log(e);
                })
            }
            else
            {
                this.setState({
                    basicTitle : "Invalid File",
                    basicType : "warning",
                    show : true,
                })
            }
            $('#upform')[0].reset();
        }
        else
        {
            $("#bulkUploadID").val();
            this.setState({
                basicTitle : "Fields Should not be Empty",
                basicType : "warning",
                show : true,
            })
            $('#upform')[0].reset();
        }
    };

    addNewRow()
    {
        // console.log(this.gridApi);
        this.gridApi.insertItemsAtIndex(0,[{region:"",country:"",port_of_discharge:""}])
        // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
    }
    
    render(){
        var columnwithDefs = [
            {
                headerName : "",
                field : "update",
                width:100,
                editable : false,
                pinned:"left",
                cellRenderer: function(params){
                    if(params.data._id == undefined || params.data._id =='')
                    {
                        return "<button class='btn btn-info' style='padding:0px;width:76px'>Update</button>"
                    }
                }
            },
            {
                headerName : "Region",
                field : "region",
                width:200,
                editable : true,
                resizable:true
            },
            {
                headerName:"Country",
                field:"country",
                width:200,
                editable:true,
                resizable:true,
            },
            {
                headerName:"Port of Discharge",
                field:"port_of_discharge",
                width:200,
                editable:true,
                resizable:true,
            },
            
        ]
        return (
            <div className="row">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="col-xl-12 col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span> Spares Destination Ports</span>
                                <button className="btn btn-success" style={{float:"right"}} onClick={this.addNewRow.bind(this)}>Add Destination Port</button>
                                {/* <button className="btn btn-info" style={{float:"right",marginRight:"10px"}} onClick={this.showBulkUpload.bind(this)}>Import Data</button>*/}
                            </h5>
                        </div>				   			
                        <div className="card-body">
                            <div className="col-xl-12 col-lg-12">
                                <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={false}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        components={this.state.components}
                                        rowSelection={this.state.rowSelection}
                                        suppressRowClickSelection={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        onCellClicked={this.onCellRowClicked.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
                    <h3 className="subH">Bulk Upload</h3>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler.bind(this)}>
                            
                            <div className="form-group mt-20p">
                                <label className="">Upload File</label> 
                                <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control" required  />
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-success">Submit</button>
                                <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                            </div>
                            </form>
                            <div className="form-group">
                                <a className="btn btn-primary" href={require('../../assets/json/sample_cha_container_updates.csv')} target="_blank">Sample Template</a>
                            </div>		
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        );
    }
}


function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};





import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { getDDMMYYYY, getDDMMYYYYHHMMSS } from '../common/utils'; 
var redirectURL = require('../redirectURL');

 
const ConsignmentImportsEventsAction = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onShowConsignmentImportsEventsClick(props);
    };
    //var colr = 'grayfont'
    if(props.data.sea_transit_delay == 1)
    {
        var colr = 'redfont'
    }
    else{
       /* var g1 = getDDMMYYYY(props.data.msil_current_transit_eta);
        var g2 = getDDMMYYYY(props.data.msil_initial_transit_eta);
        var s1 = g1.split("-");
        var s2 = g2.split("-");
        var gdate1 = new Date(s1[2],s1[1], s1[0]);
        var gdate2 = new Date(s2[2],s2[1], s2[0]);
        //console.log("gdate1 ", gdate1.getTime())
        //console.log(g1);
        //console.log("gdate2 ", gdate2.getTime())
        //console.log(g2);
        
        if(gdate1.getTime() > gdate2.getTime())
        {
            var colr =  'redfont'
        }
        else{
            var colr =  'bluefont'
        }
        */
       if(props.data.last_status == 'High Seas')
       {
            var colr = 'yellowfont'
       }
       else if(props.data.last_status == 'Arrived at Indian Port' || props.data.last_status == 'Port In' || props.data.last_status == 'In Transit to MSIL' || props.data.last_status == 'Port Out' || props.data.last_status == 'In ICD Ghari')
       {
            var colr = 'greenfont'
       }
       else{
        var colr = 'grayfont'
       }
    }   
   
    return (
        <div>
            <button onClick={handleEventClick} className="custom-btn f22 label label-success" 
            title="High Seas" ><i className={"icofont icofont-ship-alt "+(colr)}></i> &nbsp;</button>
            
        </div>
    );
};

export default ConsignmentImportsEventsAction;

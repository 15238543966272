'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import UpdateButton from "./updatelmstates";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class ExitStatus extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          UpdateButton:UpdateButton,
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-m",
        loadshow:"show-m",
        holidayEvents : [],
        maxNodes: [],
        telRoutes: [],
        showCalendar : "show-m",
        showGridData : "show-m",
        currentMonth : "",
        invoice_month : "",
        
    }
    this.changeFileHandler = this.changeFileHandler.bind(this);
    this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this)
}

async componentDidMount(){
    loadDateTimeScript();
    var invoice_month = moment.parseZone().format("YYYY-MM-DD");
    $(".invoice_month").val(invoice_month);
    console.log(invoice_month,"invoice_month")
    await this.setState({
        invoice_month : invoice_month
    });
    await this.onloadPageData();
}

onloadPageData(){
    var params = {
        invoice_month : this.state.invoice_month
    }
    console.log(params,"params")
    redirectURL.post("/consignments/getRailExitStatusReport",params).then((response)=>{
        console.log(response.data,"rowData");
        var rowData = response.data;
        try{
            if(['T275', 'T025', 'T305', 'T314', 'T324', 'T226'].includes(JSON.parse(localStorage.getItem("transportercode"))[0]))
            {
                rowData = rowData.filter(e =>e.transporter_code == JSON.parse(localStorage.getItem("transportercode"))[0])
            }
        }
        catch(e){}
        
        this.setState({
            rowData : rowData,
            overly:"show-n",
            loadshow:"show-n",
        })
    })
}

onClickSubmit = async () => {
    this.setState({
        loadshow : "show-m",
        overly : "show-m"
    })
    var invoice_month = $(".invoice_month").val();
    await this.setState({
        invoice_month : invoice_month
    });
    await this.onloadPageData();
}

onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
    * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
            
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    this.setState({
        show: false
    });
}

onCellClicked(e)
{
    
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    
}
updateLmStates(params)
{
    console.log(params);
    if(params.day_1 !="" || params.day_2 !="" || params.day_3 !="" || params.day_4 !="" || params.last_mile_delay_reason !="") 
    {
        params['user_name'] = localStorage.getItem('username');
        params['email'] = localStorage.getItem('email');
        redirectURL.post("/consignments/updateRailOPerationsMonitoring",params).then((response)=>{
            console.log(response);
            if(response.data.message == "success")
            {
                this.setState({
                    basicTitle:"Updated Successfully.",
                    basicType : "success",
                    show : true
                })
                //window.location.reload();
                this.onloadPageData();
            }
            else
            {
                var errMsg = response.data.message;
                this.setState({
                    basicTitle:errMsg,
                    basicType : "warning",
                    show : true
                })
            }
        })
    }
    else
    {
        this.setState({
            basicTitle:"Fields Should not be Empty.",
            basicType : "warning",
            show : true
        })
    }
}
addNewRow()
{
    // console.log(this.gridApi);
    this.gridApi.insertItemsAtIndex(0,[{vehicle_cap:"",vehicle_capacity:"",min_capacity_cmt:"",max_capacity_cmt:"",min_courier:"",nodes_per_truck:"",allowed_distance:"",telescopic_route:""}])
    // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}
onClickDataExport(){
    var params = {
        columnKeys: ['id_1', 'vehicle_cap_1', 'vehicle_capacity_1', 'min_capacity_cmt_1', 'max_capacity_cmt_1', 'min_courier_1', 'nodes_per_truck_1', 'allowed_distance_1', 'telescopic_route_1']
    };
    this.gridApi.exportDataAsCsv(params);
}
showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate"
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}
changeFileHandler = async (e) => {
    var dt = '';
    const config = {
        headers: [
            { 
                name: 'id',
                inputName: 'id',
                required: false,
            },
            { 
                name: 'Vehicle Type',
                inputName: 'vehicle_cap',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Vehicle Capacity (CMT)',
                inputName: 'vehicle_capacity',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Min Capacity (CMT)',
                inputName: 'min_capacity_cmt',
                required: false,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Max Capacity (CMT)',
                inputName: 'max_capacity_cmt',
                required: false,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Min CMT per Consignee',
                inputName: 'min_courier',
                required: false,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Maximum Nodes',
                inputName: 'nodes_per_truck',
                required: false,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Max Distance',
                inputName: 'allowed_distance',
                required: false,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Telescopic Route',
                inputName: 'telescopic_route',
                required: false,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },          
        ]
    }
    var data = CSVFileValidator(e.target.files[0], config)
    .then((csvData) => {
        this.setState({
            csvData:csvData.data
        });
        console.log(csvData.data);
    })
    .catch(err => {})
    // console.log("e.target.files[0]",e.target.result);
    
    var out = new Promise(function(reject, resolve){
        var reader =  new FileReader();
        reader.onload = async function(e) {
            var contents = await e.target.result;
            resolve(contents);
        };
        var tt =  reader.readAsText(e.target.files[0]);
    });
    this.setState({
        file:e.target.files[0]
    });
    // console.log("e.target.files[0].type ", e.target.files[0]);

    if(e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
    {
        // console.log(fileData);
        // console.log(typeof(fileData))
        
    }
    else{
        e.target.value = null;
        this.setState({
            uploadFile:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'Please upload file having extensions .csv only.',
        });
    }
    
}

uploadBulkFormHandler(event){
    event.preventDefault();
    var fileData = this.state.csvData;
    var flag=0;
    console.log(fileData,"fileData");
    
    if(flag == 0)
    {
        if(fileData.length > 0)
        {
            var loading_port_code = localStorage.getItem("loading_port_code");
            var reqParams = {
                csvData : fileData,
                loading_port_code : loading_port_code
            }
            console.log("reqParams",reqParams)
            redirectURL.post("/lmconsignees/savebulklmvehiclecapacity",reqParams).then((response)=>{
                var resp=response.data.message;
                console.log(resp)
                this.setState({
                    basicTitle : resp,
                    basicType : "success",
                    show : true,
                    bulkslide:'',
                    overly:'show-n',
                })
                //window.location.reload();
                this.onloadPageData();
            }).catch((e)=>{
                console.log(e);
            })
        }
        else
        {
            this.setState({
                basicTitle : "Invalid File",
                basicType : "warning",
                show : true,
            })
        }
        $('#upform')[0].reset();
    }
    else
    {
        $("#bulkUploadID").val();
        this.setState({
            basicTitle : "Fields Should not be Empty",
            basicType : "warning",
            show : true,
        })
        $('#upform')[0].reset();
    }
};




onSelectBulkPlants= (location) =>{
    var plants=[];
    try{
        location.map(function(e){
            plants.push(e.value);
        })
        console.log(plants);
        this.setState({
            plant_name : plants
        });
    }
    catch(e)
    {
        console.log(e);
    }
    
}

 
  render() {
    
    var columnwithDefs = [      
        {
            headerName: "Row Labels",
            field: "Row Labels",
            width:140,
            filter: true,
            resizable: true,
            editable:true,
            cellClass: (params)=>{
                if(params.data['Row Labels'] == "GRAND TOTAL")
                {
                    return "load-exit-status-total-row"
                }
            }       
        },
        {
            headerName: "MSIL-G",
            cellClass:["textAlignCenter"],
            cellStyle: {borderLeft: '1px solid #ccc'},
            headerClass:["csgpsBg"],
            children: [
                {
                    headerName: "Retrieved",
                    field: "MSIL-G_Retrieved",
                    width: 110,
                    resizable: true,
                    editable: false,
                    cellClass: (params)=>{
                        if(params.data['Row Labels'] == "GRAND TOTAL")
                        {
                            return "load-exit-status-total-row"
                        }
                    } 
                },
                {
                    headerName: "Exit Done",
                    field: "MSIL-G_Exit Done",
                    width: 110,
                    resizable: true,
                    editable: false,
                    cellClass: (params)=>{
                        if(params.data['Row Labels'] == "GRAND TOTAL")
                        {
                            return "load-exit-status-total-row"
                        }
                    } 
                },
                {
                    headerName: "Pendency",
                    field: "MSIL-G_Pendency",
                    width: 110,
                    resizable: true,
                    editable: false,
                    cellClass: (params)=>{
                        if(params.data['Row Labels'] == "GRAND TOTAL")
                        {
                            return "load-exit-status-total-row"
                        }
                    } 
                },
            ]
        },
        {
            headerName: "MSIL-M",
            cellClass:["textAlignCenter"],
            cellStyle: {borderLeft: '1px solid #ccc'},
            headerClass:["csgpsBg"],
            children: [
                {
                    headerName: "Retrieved",
                    field: "MSIL-M_Retrieved",
                    width: 110,
                    resizable: true,
                    editable: false,
                    cellClass: (params)=>{
                        if(params.data['Row Labels'] == "GRAND TOTAL")
                        {
                            return "load-exit-status-total-row"
                        }
                    } 
                },
                {
                    headerName: "Exit Done",
                    field: "MSIL-M_Exit Done",
                    width: 110,
                    resizable: true,
                    editable: false,
                    cellClass: (params)=>{
                        if(params.data['Row Labels'] == "GRAND TOTAL")
                        {
                            return "load-exit-status-total-row"
                        }
                    } 
                },
                {
                    headerName: "Pendency",
                    field: "MSIL-M_Pendency",
                    width: 110,
                    resizable: true,
                    editable: false,
                    cellClass: (params)=>{
                        if(params.data['Row Labels'] == "GRAND TOTAL")
                        {
                            return "load-exit-status-total-row"
                        }
                    } 
                },
            ]
        },
        {
            headerName: "SMG",
            cellClass:["textAlignCenter"],
            cellStyle: {borderLeft: '1px solid #ccc'},
            headerClass:["csgpsBg"],
            children: [
                {
                    headerName: "Retrieved",
                    field: "SMG_Retrieved",
                    width: 130,
                    resizable: true,
                    editable: false,
                    cellClass: (params)=>{
                        if(params.data['Row Labels'] == "GRAND TOTAL")
                        {
                            return "load-exit-status-total-row"
                        }
                    } 
                },
                {
                    headerName: "Exit Done",
                    field: "SMG_Exit Done",
                    width: 130,
                    resizable: true,
                    editable: false,
                    cellClass: (params)=>{
                        if(params.data['Row Labels'] == "GRAND TOTAL")
                        {
                            return "load-exit-status-total-row"
                        }
                    } 
                },
                {
                    headerName: "Pendency",
                    field: "SMG_Pendency",
                    width: 130,
                    resizable: true,
                    editable: false,
                    cellClass: (params)=>{
                        if(params.data['Row Labels'] == "GRAND TOTAL")
                        {
                            return "load-exit-status-total-row"
                        }
                    } 
                },
            ]
        },
    ]
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="card col-xl-12">
            <div className="card-header">
                <h5>
                    <i className="icofont icofont-dashboard-web cus-i"></i> <span>Exit Status</span>
                </h5>
            </div>
            <div className="card-body col-xl-12 col-lg-12">
                <div className="row col-xl-12 col-lg-12">
                    <form method="post" className='row col-xl-12 col-lg-12'>
                        <div className="col-xl-2 col-lg-2 form-group">
                            <label>Select Date</label>
                            <input type="text" className="invoice_month form-control" id="invoice_month" required />
                        </div>
                        
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>&nbsp;</label><br />
                            <button onClick={this.onClickSubmit.bind(this)} type="button" className="btn btn-success" autoComplete="off">Submit</button>
                        </div>
                    </form>    
                </div>
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.showGridData}>    
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClicked}
                    
                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                    />
            </div>
            </div>
        </div>

        
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">Bulk Upload</h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>
                    
                    <div className="form-group mt-20p">
                        <label className="">Upload File</label> 
                        <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control" required  />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-success">Submit</button>
                        <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                    </div>
                    </form>
                    <div className="form-group">
                        <a className="btn btn-primary" href={require('../../assets/json/sample_vehicle_details.csv')} target="_blank">Sample Template</a>
                    </div>		
                </div>
            </div>
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
    $('.invoice_month').datetimepicker({
        //mask:'39-19-9999',
        format:'Y-m-d',
        timepicker:false
    });
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import CSVFileValidator from 'csv-file-validator';
import Select from 'react-select';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault,getDDMMMYYYYDefault } from '../common/utils'; 
    import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import BatchDownload from "./batchdownload";
import { isBuffer } from 'lodash';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;
var validatedBy="";



export default class CoilStockInformation extends Component {
      constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable : false,
            width : 160,
            resizable : true
        },
        eventAction: null,
        show: false,
        basicTitle:'',
        basicType:"default",
        loadshow:'show-n',
        overly:'',
        rowdata:[],
        sliderbulkupload : "",
        rowModelhighchartoptionsType: 'enterprise',
        frameworkComponents : {
            BatchDownload : BatchDownload
        },
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:50,
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        pagetitle:"",
        eventGridAction: "gridAction",
        csvcontent : [],
        file : "",
        batch_name : "",
        cluster_breaks : "2",
        cluster_radius : "0.5",
        notification_emails : "",
        isChecked: false,
        isPopupVisible: false,
        setEditable:0,
        rowId:"",
        container_type:""
    }
  }



componentDidMount(){
    this.setState({
        loadshow : "show-m",
        overly : "show-m"
    })
    loadDateTimeScript()
    redirectURL.post('/consignments/coiltrackingrequirements').then((response)=>{
        console.log(response.data,localStorage.getItem("warehouse"),"140")
        if(localStorage.getItem("warehouse")!=undefined && localStorage.getItem("warehouse")!='undefined'){
            var warehouse=JSON.parse(localStorage.getItem("warehouse"))
        }else{
            var warehouse="undefined"
        }
    var respData=response.data

        console.log(warehouse,warehouse[0],warehouse[1],"143")
        // var resp = respData.filter((e)=>e.stock_at_warehouse == "CCA1" || e.stock_at_warehouse == "CCA2")
       
        if (warehouse[0]=="CCA1" && warehouse[1]=="CCA2"){
            var resp = respData.filter((e)=>e.stock_at_warehouse == "CCA1" || e.stock_at_warehouse == "CCA2")
           }
           else if (warehouse[0]=="TM1" && warehouse[1]=="TM2"){
            var resp = respData.filter((e)=>e.stock_at_warehouse == "TM1" || e.stock_at_warehouse == "TM2")
           }
           else if (warehouse[0]=="PATLI"){
            var resp = respData.filter((e)=>e.stock_at_warehouse == "PATLI")
           }
           else if (warehouse[0]=="HRC"){
            var resp = respData.filter((e)=>e.stock_at_warehouse == "HRC")
           }else{
            var resp=respData
           }
     
           console.log(resp,respData,"147")
        // console.log(rowdata,"155")
        this.setState({
            rowdata : resp,
            loadshow : "show-n",
            overly : "show-n"
        })
    }).catch((e)=>{
        console.log(e)
    })

}
   



closeAlert = () => {
    this.setState({
        show: false
    });
}

onClickEditPriority = () => {

    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate-30p",
    })
  
   

};

onSelectedCheckbox = async (rownode) =>{
    
    var cnf = window.confirm("Do you want to select this coil for dispatch ? ");
    if(cnf === true)
    {
      
       
        var rowId  = rownode.data._id
       console.log(rowId,'rowID---')
        this.setState({
            overly :"show-m",
            bulkslide : "slider-translate-30p",
            rowId : rowId
        })
      
    }
    else{
        
        var rowdata = this.state.rowdata;
        var records = [];
        if(rowdata.length > 0)
        {
            rowdata.map((item) => {
                item.isEditable = 0;
                records.push(item);
            })
        }
        
        this.setState({
            setEditable:0,
            rowData:records
        })
    }

}
dispatchportchangeHandler = (e) => {
    this.setState({
        container_type: e,
    })
   console.log(this.container_type,'---213')
}
onClickHideAll(){
    this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:""
        
    }); 
}
onClickShowFilterData(event){
    event.preventDefault()
    var rowId = this.state.rowId
    var destination = this.state.container_type.value
    var params ={
        destination : destination,
        rowId : rowId,
    }
    console.log(params,"257")
    if(params.destination==""||params.destination==undefined||params.destination=='undefined'){
        alert("Please Select the Destination")
    }else{
    redirectURL.post("/consignments/updateCoilstockMasterInformation",params).then((response)=>{
        if(response.data.message == "success"){
            this.setState({
                basicTitle:"Successfully Updated",
                basicType: "success",
                show :true,
                bulkslide:"",
                container_type:""
            })
            // $("#container_dateField").val("")
            this.componentDidMount()
        }
    })
}
}



render() {
    if((this.state.counter_screen == "active" && this.state.deptcode != "LOG-TNP") || this.props.match.path == "/insidemsilvicinty"){
        var checkboxSelectionval = true;
        var hideStatus = true;
        var truckNoHeader ={
            headerName: "Truck No",
            field: "truck_no",
            width: 100,
            filter: "agSetColumnFilter",
            pinned : "left",
            cellRenderer:'',resizable: true
        }
    }
    else{
        var checkboxSelectionval = false;
        var hideStatus = false;
        var truckNoHeader ={
            headerName: "Truck No",
            field: "truck_no",
            width: 100,
            filter: "agSetColumnFilter",
            cellRenderer:'',resizable: true
        }
    }
    var setEditable = this.state.setEditable;
    var enableEdit = false;
    if(setEditable === 1)
    {
        enableEdit = true;
    }
    const batchwisecolumns = [
      
        {
            headerName: "",
           
            width: 50,
           
            filter: false,resizable: true,
            headerCheckboxSelection: checkboxSelectionval,
            //headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true
            
        },
        {
            headerName : "Coil Unique No.",
            field : "coil_unique_no",
         

        },
        {
            headerName : "Stock At Warehouse",
            field : "stock_at_warehouse",
        },
        {
            headerName : "Order received from SC",
            field : "order_received_from_sc",
            width:200,
            // cellEditor: DateEditor,
            editable:true,
           
            valueGetter:function(params){
                try{
                    return getDDMMMYYYYHHMMDefault(params.data.order_received_from_sc);
                }
                catch(e){
                    return "";
                }
            },
            
        },
        {
            headerName : "Destination",
            field : "destination",
            editable:function(params){
                try {
                    if(params.data.isEditable !== undefined)
                    {
                        if(params.data.isEditable === 1)
                        {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                    else{
                        
                        return false;
                    }
                } catch (error) {
                    
                }
            },
            
        
        },
       
       
    ]
    return (
    <div class="container-fluid">
            
       
        <div className="row">
            <div className="col-xl-12 col-lg-12" >
              			   			<div className="card">
                                         <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> 
									   <span>Coil Stock Information</span>
				       			</h5>
				   			</div>
                               <div className="card-body">
               
                    <div className="row">
                        <div id="myGrid" style={{width:"100%",height:"478px"}} className="ag-theme-balham">    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={batchwisecolumns}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowdata}
                                enableCharts={false}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                rowClassRules={this.state.rowClassRules}
                                // statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                onRowSelected={this.onSelectedCheckbox.bind(this)}
                                enableRangeSelection= {true}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                />
                        </div>
                        </div>
                        
                    
                </div>
                </div>
            </div>
        </div>
        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
                    <h3 className="subH">Add Coil Stock Information
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                    </h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            
                            <form method="post" onSubmit={this.onClickShowFilterData.bind(this)} className="row col-xl-12 col-lg-12">
                                
                          
                                <div className="form-group mt-20p col-xl-12 col-lg-12" style={{paddingLeft:"0px",paddingRight:"27px"}}>
                                    <label className="col-xl-12 col-lg-12">Destination: </label>
                                    <Select 
                                            placeholder={"Destination"} 
                                            closeMenuOnSelect={true} 
                                            value={this.state.container_type} 
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            onChange={this.dispatchportchangeHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={[{ label: "MSIL", value: "MSIL" },
                                            { label: "Vendor", value: "Vendor" },
                                           ]} required
                                        />
                                </div>
                                {/* <div className="datetimepicker_date form-group mt-20p col-xl-12 col-lg-12" style={{paddingLeft:"0px",paddingRight:"27px"}}>
                                    <label className="col-xl-12 col-lg-12">Order Receiverd from SC: </label>
                                    <input type="text" name="container_date" id="container_dateField" className="datetimepicker_date form-control" readOnly />
                                </div> */}
                               
                                <div className="form-group col-xl-3 col-lg-3">
                                        <button  type="submit" className="btn btn-success">Submit</button>
                                    </div>
                            
                        </form>
                        </div>
                    </div>
                </div>
      
    </div>
    );
  }
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_date').datetimepicker({
        //mask:'39-19-9999',
        format: 'd-m-Y',
        minDate: true,
        timepicker: false,
        closeOnDateSelect: true,
        scrollMonth: false,
        scrollInput: false
    });

    $('.cargo_date').datetimepicker({
        //mask:'39-19-9999',
        format: 'd-m-Y',
        minDate: true,
        timepicker: false,
    });

    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    script.crossOrigin = "anonymous"
    index.parentNode.insertBefore(script, index)
}

// $('body').on('focus',".datetimepicker_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false
// 	});
// });

// $('body').on('focus',".cargo_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false,

// 	});
// });

$(document).on("input", ".num-cls", function() {
    this.value = this.value.replace(/\D/g,'');
})
$(document).on("input", ".mobnum-cls", function() {
    this.value = this.value.replace(/^\d{11}$/g,'');
})
$(document).on("input", ".alfa-cls", function () {
    this.value = this.value.replace(/[^a-z\s]+$/gi, '');
  });
  $(document).on("input", ".dept-cls", function () {
    this.value = this.value.replace(/[^a-z-\s]/gi, '');
  });
  $(document).on("input", ".ringi-cls", function () {
    this.value = this.value.toUpperCase();
    this.value = this.value.replace(/[^\d[A-Z]-\s]/gi, '');
  });
$(document).on("change", "#cargoDate", function (e) {
    e.preventDefault();
    var bookingDate = $("#dateField").val();
    var cargoDate = $("#cargoDate").val();
    var bookingDate = bookingDate.split("-")[2] + "-" + bookingDate.split("-")[1] + "-" + bookingDate.split("-")[0]
    var cargoDate = cargoDate.split("-")[2] + "-" + cargoDate.split("-")[1] + "-" + cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    console.log(startTime, endTime);
    if (startTime >= endTime) {
        $("#cargoDate").val("");
        alert("Date should be greater than Booking Date");
    }
})




import React, { Component } from 'react';
import { render } from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import Select from 'react-select';

import Modal from 'react-responsive-modal';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import { getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY  } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import RoutemapAction from './routemapActionComponent';
import DrawMap from '../common/drawmap';
 
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");

export default class ManageMOVEConsignments extends Component {

	constructor(props){
		super(props);
		this.state={
			pageTitle: "Move Consignments",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			sidebarSubHeader:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: [],
      	      
			rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"green":"data.status >= 3"

            },
            transporterslist:[],
            container_no:'',
            updatesearatebtn:'show-n',
            searates:'',
            pipavaContainers:[],
            mundraContainers:[],
            containerslist:[],
            errorMessage:'',
            successMessage:'',
            transporter_code:'',
			components: { datePicker: getDatePicker() },
			updatedRecords : [],
			openUpdatedModal : false,
			open:false,
            usergridstate:[],
            screenurl:"",
            screentitle:"",
            startDate:"",
            endDate:"",
            defaultsdate:"",
			defaultedate:"",
			rownode:[],
			sourceslist:[],
			consigner:[{"value":"all", "label":"All"}],
			showummove:0,
			usermanualmovemodal:false,
			fromdate : "",
			todate : "",
		}
        this.onLoadDashboardData = this.onLoadDashboardData.bind(this);
        this.resetState = this.resetState.bind(this);
        this.onClickShowUploadModal = this.onClickShowUploadModal.bind(this);
		this.onShowRouteMap = this.onShowRouteMap.bind(this);
		this.selectConsignerOptionsItems = this.selectConsignerOptionsItems.bind(this);
		this.onLoadGetConsignmentsList = this.onLoadGetConsignmentsList.bind(this);
	}
	componentDidMount(){
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
        }
        
        redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			//console.log("resp ", resp)
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
		})
		
		var todate = moment.parseZone(new Date()).subtract(30, 'days').format("YYYY-MM-DD");
        var fromdate = moment.parseZone(new Date()).format("YYYY-MM-DD")

		this.onLoadDashboardData()
		this.setState({
			deptcode : "SNDG",
			endDate : fromdate,
			startDate : todate,
		})
		//dateScript("https://code.jquery.com/ui/1.12.1/jquery-ui.js");
	};
    
    onLoadDashboardData()
    {
		 this.setState({
            loadshow:'show-m'
        })
		
		var records = []
        redirectURL.post("/consignments/getMoveConsignments")
        .then((response) => {
           console.log(response.data,"resp")
			var records = response.data;
			var sourceslistarr=[]
			if(records.length > 0)
			{
				records.map((item) => {
					sourceslistarr.push({"source":item.source})
				})
			}

			let sourceslist = [];               
			// Declare an empty object 
			let totuniqueObject = {}; 
			var totobjTitle;
			// Loop for the array elements 
			for (let i in sourceslistarr) { 

				// Extract the title 
				totobjTitle = sourceslistarr[i]['source']; 

				// Use the title as the index 
				totuniqueObject[totobjTitle] = sourceslistarr[i]['source']; 
			} 

			// Loop to push unique object into array 
			for (var a in totuniqueObject) { 
				sourceslist.push(totuniqueObject[a]); 
			} 

			
            this.setState({
				rowData:records,
				loadshow:'show-n',
				sourceslist:sourceslist
            })
        })
        
	}

	onLoadGetConsignmentsList(urlpath, parameters)
	{
		this.setState({
			loadshow:'show-m',
			overly:'show-m'
		})
		//console.log(parameters);
		redirectURL.post(urlpath, parameters)
		  .then((response) => {
            var records = response.data.consignments;
			//console.log("records", records);
			//console.log("counters", pagecounters);
			
			//console.log("records ", records.filter(e=> e.tpt_confirm_delivery == 1))
			this.setState({
				rowData:records,
				loadshow:'show-n',
				overly:'show-n',
			});
			
			
		  })
		.catch(function (error) {
		    console.log(error);
		});
	}
	
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderCommentTranslate : "",
			sliderSegmentTranslate : "",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	
    onRowSelected(event) {

	}

	onSelectTrip= triptype =>{
		this.setState(
			{ triptype },
			() => console.log(`Trip Option selected:`, this.state.triptype)
		  );
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}

	changeConsignerArrayHandler = consigner =>{
        this.setState(
	      { consigner },
	      () => console.log(`Consigner Option selected:`, this.state.consigner)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
	}

	selectConsignerOptionsItems(){
        let items = [];     
		//console.log(this.state.trucks);
		var consigners=[];
		if(this.state.deptcode == 'SNDG')
		{
			var keys = ["B", "F", "G", "I","M", "N"]; 
      
			// An array of values 
			var values = ["TVP Bangalore", "Gurgaon", "Gujarat", "Siliguri","Manesar", "TVP Nagpur"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}


		if(this.state.deptcode == 'LOG-PRT')
		{
			var keys = ["MSILDM", "MSILG", "MSILM", "RPDC-BAN","RPDC-GUJ", "RPDC-NAG", "RPDC-SIL"]; 
      
			// An array of values 
			var values = ["MSIL Domestic", "Gurgaon", "Manesar", "RPDC Bangalore","RPDC Gujarat", "RPDC Nagpur", "RPDC Siliguri"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}


		if(this.state.deptcode == 'LOG-TNP')
		{
			var keys = ["MSIL-G", "MSIL-M", "MSILMC","MSILME", "SMG"]; 
      
			// An array of values 
			var values = ["Gurgaon", "Manesar", "Casting","Power Train", "SMG Gujarat"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}

		//console.log("consigners ", consigners)
		items.push({value:'all',label:'All'}); 
		consigners.forEach(item =>{
			items.push({value:item.code,label:item.value}); 
		});
       
        return items;
	}

	onClickTripButton = async () => {

		if(this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN")
		{
			var sDate = this.state.defaultsdate;
		}
		else{
			var sDate = this.state.startDate;
		}
		if(this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN")
		{
			var eDate = this.state.defaultedate;
		}
		else{
			var eDate = this.state.endDate;
		}
		//console.log("consigner ", this.state.consigner)
		var requestparams = {
			dept_code: this.state.deptcode,
			startDate:sDate,
			endDate:eDate,
			sources:this.state.consigner
		}

		var urlpath='';
		
		urlpath = '/consignments/getFilterMoveConsignment';
		//console.log("requestparams ", requestparams)
		this.onLoadGetConsignmentsList(urlpath, requestparams)
	}

	getTruckConsignments=(event)=>{
		event.preventDefault();
		var selectedFilter = $("#selectedFilter").text();
		var inputTruckConsignment = $("#inputTruckConsignment").val();
		if(selectedFilter == "Select..." || inputTruckConsignment == "")
		{
			this.setState({
				basicTitle : "Fields Should not be Empty",
				basicType : "danger",
				show : true
			})
		}
		else
		{
			console.log(selectedFilter,inputTruckConsignment);
			
			var urlpath = "/consignments/filterMoveConsignment";
						
			redirectURL.post(urlpath,
			{"selectedFilter":selectedFilter,
			"inputTruckConsignment":inputTruckConsignment,
			"dept_code" : this.state.deptcode})
			.then((response)=>{
				this.setState({
					rowData : response.data.consignments,
					allRowData :  response.data.consignments,
					countersjson : response.data.counters,
				})
			}).catch=((e)=>
			{
				console.log(e);
			})	
		}
		
	}

	selectMovementTypeOptionsItems(){
        let items = [];     
		items.push({value:"all",label:"All"});
		items.push({value:true,label:"Local Movement"});
		items.push({value:false,label:"Non Local Movement"});
		return items;
	}

	onGetConsigners = async (event) => {

	
			
			if(this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN")
			{
				//var sDate = this.state.defaultsdate;
				var sDate = "";
			}
			else{
				var sDate = this.state.startDate;
			}
			if(this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN")
			{
				//var eDate = this.state.defaultedate;
				var eDate = "";
			}
			else{
				var eDate = this.state.endDate;
			}
		


		// var sDate=this.state.startDate;
		// var eDate = this.state.endDate;
		// var selectconsigners=[]
		// if(this.state.consigner == null)
		// {
		// 	selectconsigners = [{"value":'all', "label":'All'}]
		// }
		// else{
		// 	selectconsigners=this.state.consigner
		// }


		//console.log("this.state.movementtype ", this.state.movementtype)
		//console.log("mvmtype ", mvmtype)
		// if(this.state.triptype == null)
		// {
		// 	selecttrip = [{"value":'all', "label":'All'}]
		// }
		// else{
		// }
	
			//console.log("S1 ")
			var requestparams = {
				//consigner: selectconsigners,
				dept_code: this.state.deptcode,
				startDate:sDate,
				endDate:eDate
			}
	
		
		//console.log("requestparams ", requestparams)

		var urlpath='';
		
			urlpath = '/consignments/getFilterMoveConsignment';
		
		this.onLoadGetConsignmentsList(urlpath, requestparams)
	}

	selectConsignerOptionsItems(){
        let items = [];     
		items.push({value:'all',label:'All'}); 
		if(this.state.sourceslist.length > 0)
		{
			this.state.sourceslist.forEach(item =>{
				items.push({value:item,label:item}); 
			});
		   
		}
		
        return items;
	}

	handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDate:edate
    	});
	}

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			openUpdatedModal : false
		});
		
	}
	closeAlert = async() => {
        await this.setState({
            show: false
		});
		await window.location.reload()
    }
   

	onClickSaveGridState(){
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		console.log("Columns ", window.colState)
		console.log("Group ", window.groupState)
		console.log("Sort ", window.sortState)
		console.log("Filter ", window.filterState)
		let screenpage = 'Primary Payments Dashboard';

	

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
        try{
            if(this.state.usergridstate.length > 0)
            {
                var windowstates = this.state.usergridstate;
                this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
                this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
                this.gridApi.setSortModel(windowstates[0].gridcolsort);
                this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
                
            }
        }
        catch(e){

        }
		
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

    };
    
    onShowRouteMap(rownode){
      
        this.setState({
            loadshow:'show-m'
        })
        var d = new Date(rownode.out_date);
        var r = new Date(rownode.in_date);
		var finstance =  moment.parseZone(rownode.out_date).format('YYYY-MM-DD HH:mm:ss')
		var rinstance =  moment.parseZone(rownode.in_date).format('YYYY-MM-DD HH:mm:ss')
		var params = { 
            truck_no:rownode.truck_no,
			consignment_code:rownode.consignment_code,
			mapstartdate:finstance,
			mapenddate:rinstance
        }
        
         redirectURL.post('/consignments/moveconsignmentsmap',params)
          .then((response) => {
          
           //var records = JSON.parse(JSON.stringify(response)).data;
          //  console.log(response.data);
           this.setState({
                mapinfo:response.data,
                sliderRouteTranslate:"slider-translate-50p",
                consignment_code:rownode.truck_no,
                maptruckno:rownode.truck_no,
                routeTruck:	{"truck_no":rownode.truck_no,"startTime":response.data['route_details']['start_time'],
                    "endTime": response.data['route_details']['end_time']},
                overly:'show-m',
                loadshow:'show-n',
                rownode:rownode,
                tolls : response.data.tolls
           });
               this.renderMap();
        
               
          })
          .catch(function (error) {
            console.log(error);
          });
          
    }
    
	onClickShowUploadModal(params)
	{
		this.setState({
			open:true,
			uploadrowid:params._id
		})
	}

	onCloseModal = () => {
	   this.setState({ open: false });
	};

	
    onCloseUserManualMoveModal = () => {
        this.setState({ usermanualmovemodal: false });
    };

    onClickShowUserMoveManaul = () => {
        this.setState({ usermanualmovemodal: true });
	};
	
	render(){
		const modalStyles  = {
			width:'300px !important',
		}
		const { open } = this.state;
		const {usermanualmovemodal} = this.state;
        var maptransporter='';
		try{
			if(this.state.rownode.transporter)
			{
				///console.log("map rownode ",this.state.rownode.transporter[0])
				maptransporter=this.state.rownode.transporter[0].transporter_name;
			}
		}
		catch(e){
			console.log(e)
		}
		//console.log("Rendere ",this.state.deptcode)
		const columnwithDefs = [
            {   
                headerName: "",
                field: "_id", 
                width:50,
                cellRendererFramework:RoutemapAction,
                resizable: true
            },
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 120,
                filter: true,
                editable : false
                //headerCheckboxSelection: true,
            },
				{
					headerName : "Transporter code",
					field : "transporter_code",
                    width:120,
                    filter: true,
					editable : false
                },
                {
					headerName : "Transporter Name",
					field : "transporter_name",
                    width:200,
                    filter: true,
                    editable : false,
                    valueGetter:function(params){
                        //console.log("params ", params)
                        try{
                            return params.data.transporter[0].transporter_name;
                        }
                        catch(e){
        
                        }
                    } 
				},
                {
					headerName : "Consignment Code",
					field : "consignment_code",
                    width:120,
                    filter: true,
					editable : false
				},
                {
					headerName : "Move Number",
					field : "mov_num",
                    width:120,
                    filter: true,
					editable : false
				},
                {
					headerName : "Selection",
					field : "selection",
                    width:100,
                    filter: true,
					editable : false
				},
                // {
				// 	headerName : "VIN",
				// 	field : "chassis_number",
                //     width:200,
                //     filter: true,
				// 	editable : false,
				// 	valueGetter:function(params){
                //         //console.log("params ", params)
                //         try{
                //             return params.data.chassis_prefix+""+params.data.chassis_number;
                //         }
                //         catch(e){
        
                //         }
                //     }
				// },
                {
					headerName : "Model",
					field : "model",
                    width:120,
                    filter: true,
					editable : false
				},
                {
					headerName : "Spec",
					field : "spec",
                    width:120,
                    filter: true,
					editable : false
                },
                {
					headerName : "Source",
					field : "source",
                    width:120,
                    filter: true,
					editable : false
                },
                {
					headerName : "Out Date",
					field : "out_date",
					width:160,
					editable : false,
                    valueGetter:function(params){
                        try{
                            if(params.data.out_date != "" && params.data.out_date != undefined)
                            {
                                return getHyphenDDMMMYYYYHHMM(params.data.out_date);
                            }
                        }
                        catch(e){
                            return "";
                        }
                    }
                },
                {
					headerName : "Destination",
					field : "destination",
                    width:120,
                    filter: true,
					editable : false
                },
                {
					headerName : "In Date",
					field : "in_date",
					width:160,
					editable : false,
                    valueGetter:function(params){
                        try{
                            if(params.data.in_date != "" && params.data.in_date != undefined)
                            {
                                return getHyphenDDMMMYYYYHHMM(params.data.in_date);
                            }
                        }
                        catch(e){
                            return "";
                        }
                    }
                },
                {
					headerName : "Last City",
					field : "city",
                    width:120,
                    filter: true,
                    editable : false,
                    valueGetter:function(params){
                        console.log("params ", params)
                        try{
                            return params.data.truck[0].city;
                        }
                        catch(e){
        
                        }
                    }
                },
                {
					headerName : "Last State",
					field : "destination",
                    width:120,
                    filter: true,
                    editable : false,
                    valueGetter:function(params){
                        console.log("params ", params)
                        try{
                            return params.data.truck[0].state;
                        }
                        catch(e){
        
                        }
                    }
                },
                {
					headerName : "Last Packet Time",
					field : "timestamp",
					width:160,
					editable : false,
                    valueGetter:function(params){
                        try{
                            if(params.data.truck[0].out_date != "" && params.data.truck[0].timestamp != undefined)
                            {
                                return getHyphenDDMMMYYYYHHMM(params.data.truck[0].timestamp);
                            }
                        }
                        catch(e){
                            return "";
                        }
                    }
				},
				{
					headerName : "Gurgoan GPS Gate In",
					field : "source_gps_gate_in",
					width:180,
					editable : false,
                    valueGetter:function(params){
                        try{
                            if(params.data.source_gps_gate_in  != "" && params.data.source_gps_gate_in  != undefined)
                            {
                                return getHyphenDDMMMYYYYHHMM(params.data.source_gps_gate_in);
                            }
                        }
                        catch(e){
                            return "";
                        }
                    }
				},
				{
					headerName : "Manesar GPS Gate In",
					field : "destination_gps_gate_in",
					width:160,
					editable : false,
                    valueGetter:function(params){
                        try{
                            if(params.data.destination_gps_gate_in  != "" && params.data.destination_gps_gate_in  != undefined)
                            {
                                return getHyphenDDMMMYYYYHHMM(params.data.destination_gps_gate_in);
                            }
                        }
                        catch(e){
                            return "";
                        }
                    }
                },
				{
					headerName : "Manesar GPS Gate Out",
					field : "destination_gps_gate_out",
					width:160,
					editable : false,
                    valueGetter:function(params){
                        try{
                            if(params.data.destination_gps_gate_out  != "" && params.data.destination_gps_gate_out  != undefined)
                            {
                                return getHyphenDDMMMYYYYHHMM(params.data.destination_gps_gate_out);
                            }
                        }
                        catch(e){
                            return "";
                        }
                    }
                },
			];
		const tripoptoins = [
			{"value":"all", label:"All"},
			{"value":"S", label:"Short Haul"},
			{"value":"L", label:"Long Haul"}
		]
		return(
			<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
				<div className="row">
					<div className="col-xl-5 col-lg-6">
						<div className=" card">
							<div className="row card-body" style={{padding:"10px 30px"}}>
								<form className="row" onSubmit={this.getTruckConsignments.bind(this)}>
									<div className="form-group col-xl-6 col-lg-6">
										<label>Select Type :  </label>
										<Select 
										className="border-radius-0"
										isMulti={false}
										id="selectedFilter"
										style={{borderRadius:"0px"}} 
										options={[{label:"Truck",vaue:"Truck"},{label:"Consignments",value:"Consignments"}]} required />
									</div>
									<div className={"col-xl-6 col-lg-6 form-group"}>
										<label className="reason">Truck / Consignment No</label>
										<input type="text" name="reason" autoComplete="off" class="form-control" id="inputTruckConsignment" />
									</div>
									<div className="form-group col-xl-12 col-lg-12" style={{textAlign:"left"}}>
										<button type="submit"  className="btn btn-success">Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>
						 {/* <div className="" id="or" style={{padding:"33px 0px 0px 10px"}}>OR</div> */}
					<div className="col-xl-7 col-lg-7">
						<div className=" card">
							<div className="row card-body" style={{padding:"10px 30px"}}>
							<div className="row col-xl-12 col-lg-12">
								<div className="row col-xl-12 col-lg-12">
									
									<div className="form-group col-xl-4 col-lg-4">
										<label>Select Source: </label>
										<Select placeholder={"All"} closeMenuOnSelect={false} value={this.state.consigner} isMulti="true" className="border-radius-0" onChange={this.changeConsignerArrayHandler.bind(this)} style={{borderRadius:"0px"}} options={this.selectConsignerOptionsItems()} required />
													
									</div>
									<div className="col-xl-4 col-lg-4 form-group">
										<label>From Date</label>
										<Datetime value={this.state.startDate} 
										disableCloseOnClickOutside={false} 
										closeOnSelect={true} 
										inputProps={{ placeholder: 'From', name: 'startDate', autoComplete:'off' }}
										dateFormat="YYYY-MM-DD" 
										name="startDate" 
										value={this.state.startDate}
										onChange={this.handlerStartDateTime.bind(this)} />
										{/* <input type="text" placeholder="From Date" id="from_date" value={this.state.startDate} autoComplete="off"  className="datetimepicker_date form-control"  required /> */}

										{/* <input type="text" placeholder="From Date" id="from_date" value={this.state.startDate} autoComplete="off"  className="datetimepicker_date form-control"  required /> */}

									</div>

									<div className="col-xl-4 col-lg-4 form-group">
										<label>To Date</label>
										{/* <input type="text" placeholder="To Date" autoComplete="off" id="to_date" value={this.state.endDate}  className="datetimepicker_date form-control"  required /> */}
										<Datetime value={this.state.endDate} 
										disableCloseOnClickOutside={false}
										closeOnSelect={true} 
										inputProps={{ placeholder: 'To', name: 'endDate', autoComplete:'off'}} 
										dateFormat="YYYY-MM-DD" 
										value={this.state.endDate}
										onChange={this.handlerEndDateTime.bind(this)} />
										
									</div>
									<div className="form-group col-xl-12 col-lg-12">
										<button type="button" style={{margin:"0 auto"}} className="btn btn-success" onClick={this.onClickTripButton.bind(this)}>Submit</button>
									</div>
									
								</div>
							</div>

							
						 </div>
					</div>
				</div>
			</div>
                <div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span> {this.state.pageTitle} </span>
                                    
                                    <span className="">
                                        <button className="float-right custom-btn f14 white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                            <i className="icofont icofont-save"></i> Save Grid Layout
                                        </button>
                                        <button className="float-right custom-btn f14 white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
                                            <i className="icofont icofont-refresh"></i> Reset Default Layout
                                        </button>
                                    </span>
                                
                                </h5>
				   			</div>				   			
		            		<div className="card-body">
                                
                                <div className="row">
								    
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
                                   
										<div className="col-xl-12 col-lg-12">
											<div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
												<AgGridReact
													modules={this.state.modules}
													columnDefs={columnwithDefs}
													defaultColDef={this.state.defaultColDef}
													rowData={this.state.rowData}
													enableCharts={false}
													autoGroupColumnDef={this.state.autoGroupColumnDef}
													onGridReady={this.onGridReady}
													onGridState={this.onGridState}
													statusBar={this.state.statusBar}
													sideBar={this.state.sideBar}
													paginationPageSize={this.state.paginationPageSize}
													pagination={true}
													enableRangeSelection={true}
													components={this.state.components}
                                                    rowSelection={this.state.rowSelection}
                                                    suppressRowClickSelection={true}
                                                    onRowSelected={this.onRowSelected.bind(this)}
													gridOptions={{
														context: { componentParent: this }
													}}
													//floatingFilter={true}
													//onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
												    //onCellClicked={this.popmarker}
												/>
											</div>
                                        </div>
                                </div>
				   			</div>
				   		</div>	
				   	</div>

				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								{(this.state.consignment_code)}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawMap context={this} rownode={this.state.rownode} mapFor={"consignment"} tolls={this.state.tolls} googleroutes={this.state.googelRoutes} truckno={this.state.maptruckno} dealer={this.state.dealer} mapinfo={this.state.mapinfo} defTransitCoords={this.state.defTransitCoords} />
								{/*
								<div id="map" className="" style={{width:'100%',height:"70vh"}}></div>
								*/}
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											{(maptransporter != '')?
											<div className="col route-block">
												<label className="sidebar-label">Transporter</label>
												<div>{(!isEmpty(maptransporter))?maptransporter:""}</div>
											</div>
											
											:""}
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '')?getDDMMYYYYHHMMSS(this.state.routeTruck.startTime):"NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeTruck.endTime != '')?getDDMMYYYYHHMMSS(this.state.routeTruck.endTime):"NA"}</div>
											</div>
											
											<div className="col route-block">
												<label className="sidebar-label">GPS Distance</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?Math.round(this.state.mapinfo.route_details.distance/1000):"0"} Kms</div>
											</div>
											{(this.state.leg_no == 0 || this.state.leg_no == null)?	""
											:
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{(this.state.rownode.actual_google_leg_distance)?parseInt(this.state.rownode.actual_google_leg_distance):"0"} Kms</div>
											</div>
											}
											
											{/*<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
										*/}
										
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				:""	
				}

					<span className="usermanual f22" onClick={this.onClickShowUserMoveManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
					<Modal open={usermanualmovemodal} onClose={this.onCloseUserManualMoveModal.bind(this)} styles={modalStyles}>
						<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
							<h5 className="model-title">Move Consignments</h5>
							<div className="col-xl-12 col-lg-12 mt-20p">
								<div className="col-xl-12 col-lg-12">
								Those consignments which are dispatched from Gurgaon plant to Manesar Plant (interplant movement) are shown in this screen. All those consignments that are active in system and have MOV as cluster are shown in this screen. The trips can be searched with the filters available in the screen.
								</div>
								<div className="col-xl-12 col-lg-12">
									Image Here
								</div>
								<div className="col-xl-12 col-lg-12">
									Grid option available in system are:
								</div>
								
								<div className="col-xl-12 col-lg-12">
									<table className="table table-border">
										<tr>
											<th>
												Column
											</th>
											<th>
												Remarks
											</th>
										</tr>
										<tr>
											<td>
											Truck No
											</td>
											<td>
											vehicle details
											</td>
										</tr>
										<tr>
											<td>
											Transporter code
											</td>
											<td>
											MSIL code for transporter
											</td>
										</tr>
										<tr>
											<td>
											Transporter Name
											</td>
											<td>
											Service provider name
											</td>
										</tr>
										<tr>
											<td>
											Consignment Code
											</td>
											<td>
											Destination code
											</td>
										</tr>
										<tr>
											<td>
											Move Number
											</td>
											<td>
											MSIL parameter		
											</td>
										</tr>
										<tr>
											<td>
											Selection
											</td>
											<td>
											default name
											</td>
										</tr>
										<tr>
											<td>
											VIN
											</td>
											<td>
											MSIL parameter
											</td>
										</tr>
										<tr>
											<td>
											Model
											</td>
											<td>
											Model of consignment
											</td>
										</tr>
										<tr>
											<td>
											Spec
											</td>
											<td>
											MSIL parameter
											</td>
										</tr>
										<tr>
											<td>
											Source
											</td>
											<td>
											Origin point
											</td>
										</tr>
										<tr>
											<td>
											Out Date
											</td>
											<td>
											Gate out details	
											</td>
										</tr>
										<tr>
											<td>
											Destination
											</td>
											<td>
											Destination location
											</td>
										</tr>
										<tr>
											<td>
											In Date
											</td>
											<td>
											Gate in details
											</td>
										</tr>
										<tr>
											<td>
											Last City
											</td>
											<td>
											Last known GPS location
											</td>
										</tr>
										<tr>
											<td>
											Last State
											</td>
											<td>
											Last known GPS location
											</td>
										</tr>
										<tr>
											<td>
											Last Packet Time
											</td>
											<td>
											Data time packet
											</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
					</Modal>
            </div>
            
            
		);
	}
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}                 
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function dateScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit=="K") { dist = dist * 1.609344 }
		if (unit=="N") { dist = dist * 0.8684 }
		return dist;
	}
}
function getDatePicker() {
	function Datepicker() {}
	Datepicker.prototype.init = function(params) {
	  this.eInput = document.createElement("input");
	  //this.eInput = this.eInput.setAttribute("class","datepicker");
	  this.eInput.value = params.value;
	  console.log(this.eInput)
	  window.$(".datepicker").datepicker({ dateFormat: "dd/mm/yy" });
	};
	Datepicker.prototype.getGui = function() {
	  return this.eInput;
	};
	Datepicker.prototype.afterGuiAttached = function() {
	  this.eInput.focus();
	  this.eInput.select();
	};
	Datepicker.prototype.getValue = function() {
	  return this.eInput.value;
	};
	Datepicker.prototype.destroy = function() {};
	Datepicker.prototype.isPopup = function() {
	  return false;
	};
	return Datepicker;
  }



  function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }

function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}
function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
/* eslint-disable no-useless-concat */
/* eslint-disable no-loop-func */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
// import Select from 'react-select';
// import DeleteItem from './deleteitem';
// import EditroleAction from './editroleAction';
import { getHyphenDDMMYYYY, getHyphenDDMMMYYYY,
    // getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS
 } from '../common/utils'; 
 import download from 'js-file-download';
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
var moment = require('moment');

var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class AirCargoList extends Component{
    constructor(props){
        super(props);
        this.state={
            show : false,
            rowSelection:'multiple',
            rowData: [],
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            basicTitle: "",
            deptCode: "",
            items: [],
            source: [],
            destination: [],
            deptCodeValue: [],
            operationsvalue : [],
            fetchItemsOptions: [],
            fetchSourceOptions: [],
            fetchDestinationOptions: [],
            operationValue : [],
            overly:"show-n",
            loadshow:"show-n",
            bulkslide:"",
            frameworkComponents: {
				// updateItem:UpdateItem,
                // deleteItem:DeleteItem,
               // EditroleAction:EditroleAction,
				//statusChange:StatusChange
			},
            fetchDeptCodeOptions: [],
            paginationPageSize:50,
            defaultColDef: {
              sortable: true,
              filter: true,
              resizable: true,
              pivot : true,
              enableValue: true,
              enableRowGroup: true,
            },
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
            basicType:"default",
            gridData: [],
            operationsData: [],
            filteredoperations :[],
            rowId : "",
            sliderTitle:"",
            operation : "",
            truck_no : '',
            consignment_code : '',
            erpData: [],
            cargoData : [],
            ringiData : [],
            cc_docs : "",
            invoiceData:[],
            jobNo: '',
            preAlertFlag: false,
            erpLength : 0,
            ringiLength : 0,
            cargoLength : 0,
        }

    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    componentDidMount(){
        this.logPageView();
        this.getairCargoList(); 
    }

    getairCargoList = () => {
        redirectURL.post("/consignments/getdataforaircargoList",{}).then((response)=>{
           let results = response.data
            // console.log("data",results)
            let records = results.records;
            let erpLength = results.erpLength;
            let ringiLength = results.ringiLength;
            let cargoLength = results.cargoLength;
            this.setState({
                rowData: records,
                gridData : records,
                erpLength,
                ringiLength,
                cargoLength
            })
        })     
    }
    
    // changeHandler = event => {
    //     // console.log("event", event)    
    //     this.setState({items: event});
	// }

     // changeDestinationHandler = event => {
    //     this.setState({destination: event});
	// }

    
 
    // onShowEditMenu(params){
    //     var query = {
    //         "_id" : params.data._id
    //     }
    //     console.log(this.state.operationsData, "operationsData")
    //     redirectURL.post("/consignments/gettptuseritems",query).then((response)=>{
    //         // console.log("responseId", response.data[0])
    //         $("#add_destination").val(response.data[0].destination)
    //         $("#add_source").val(response.data[0].source);

    //         this.setState({
    //             items: {"value":response.data[0].items,"label":response.data[0].items},
    //             deptCodeValue: {"value":response.data[0].dept_code,"label":response.data[0].dept_code} ,
    //             rowId:params.data._id,
    //             sliderTitleName: "Edit User Item",
    //             bulkslide:'slider-translate',
    //             overly:'show-m',
    //         })
    //         // console.log("stateitems", this.state.items, typeof(this.state.items))
    //     }).catch((e)=>{
    //         console.log(e);
    //     })
    // }
    onCellRowClicked = e => {
        
        if(e.colDef.field == "erpData")
        {
            var erpData = JSON.parse(e.data.erpData);
            erpData.map(function(e){
                var eDate = e.erPoDate;
                if(eDate !=undefined && eDate !='')
                {
                    if(eDate.includes("T") == true)
                    {
                        eDate = eDate.split("T");
                        eDate = eDate[0];
                        eDate = eDate.split("-")[2]+"-"+eDate.split("-")[1]+"-"+eDate.split("-")[0];
                    }
                }
                e.erPoDate = eDate;
            })
            var sliderTitle = e.data.job_no+" - ERP Details";
            this.setState({
                overly :"show-m",
                erpData:erpData,
                invoiceData: [],
                bulkslide : "slider-translate-40p",
                sliderTitle : sliderTitle
            })
        }
        else if(e.colDef.field == "ringiData")
        {
            var ringiData = JSON.parse(e.data.ringiData);
            sliderTitle = e.data.job_no+" - Ringi Details";
            this.setState({
                overly :"show-m",
                ringiData:ringiData,
                invoiceData : [],
                bulkslide : "slider-translate-40p",
                sliderTitle : sliderTitle
            })
        }
        else if(e.colDef.field == "cargoData")
        {
            var cargoData = JSON.parse(e.data.cargoData);
            sliderTitle = e.data.job_no+" - Cargo Details";
            this.setState({
                overly :"show-m",
                cargoData:cargoData,
                bulkslide : "slider-translate-40p",
                sliderTitle : sliderTitle
            })
        }
        else if(e.colDef.field == "closed")
        {
            this.setState({
                loadshow:'show-m',
                overly:'show-m',
            });
            var rowId = e.data._id;
            if(rowId !=undefined && rowId !='')
            {
                var params = {
                    rowId : rowId
                }
                redirectURL.post("/consignments/closeAirCargoRequest",params)    
                .then((response) => {
                    if(response.data.message == "success")
                    {
                        this.setState({
                            basicTitle:"Successfully marked as Closed",
                            basicType : "success",
                            show : true,
                            overly:"show-n",
                            loadshow:"show-n",
                        })
                        //window.location.reload();
                        this.onLoadPageData(4);
                    }
                    else
                    {
                        this.setState({
                            basicTitle:"Failed to mark as closed.",
                            basicType : "warning",
                            show : true,
                            loadshow:'show-n',
                            overly:'show-n',
                        })
                    }
                })
                .catch(function(e){
                    console.log("Error ",e)
                })
            }
        }
        else if(e.colDef.field == "reject")
        {
            rowId = e.data._id;
            if(rowId !=undefined && rowId !='')
            {
                sliderTitle = e.data.job_no+" - Reject Reason";
                $("#reject_reason").val("");
                this.setState({
                    rejectRowId : rowId,
                    rejectedreason : 1,
                    overly :"show-m",
                    bulkslide : "slider-translate-40p",
                    sliderTitle : sliderTitle
                });
            }
        }
        else if(e.colDef.field == "invoiceData")
        {
            var invData = [];
            if(e.data.invoiceData !=undefined && e.data.invoiceData !='')
            {
                var invoiceData = JSON.parse(e.data.invoiceData);
                if(invoiceData !=undefined && invoiceData.length > 0)
                {
                    invData = invoiceData;
                }
            }
            sliderTitle = e.data.job_no+" - Pre alert documents";
            this.setState({
                overly :"show-m",
                invoiceData:invData,
                bulkslide : "slider-translate-40p",
                sliderTitle : sliderTitle
            })
        }
        else if(e.colDef.field == "other_docs")
        {
            var ringiFile = e.data.other_docs
            if(ringiFile !='')
            {
                var rngParam = {
                    ringiFile : ringiFile,
                }
                redirectURL.post("/consignments/downloadRingiFile",rngParam).then((response)=>{
                    // console.log("dowlad response ",response)
                    download(response.data, ringiFile)
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        else if(e.colDef.field == "ccData")
        {
            var ccData = [];
            if(e.data.ccData !=undefined && e.data.ccData !='')
            {
                ccData = JSON.parse(e.data.ccData);
            }
            sliderTitle = e.data.job_no+" - Custom clearance documents";
            this.setState({
                overly :"show-m",
                ccData:ccData,
                bulkslide : "slider-translate-40p",
                sliderTitle : sliderTitle,
                ccSlider : 1,
                jobNo : e.data.job_no,
            })
        }
        else if(e.colDef.field == "custom_duty_paid")
        {
            var custom_duty_paid = e.data.custom_duty_paid;
            if(custom_duty_paid !="yes")
            {
                if(e.data.ccData !=undefined && e.data.ccData !='')
                {
                    params = {
                        rowId : e.data._id,
                        custom_duty_paid : "yes",
                    }
                    redirectURL.post("/consignments/updateChaCustomDutyStatus",params).then((response)=>{
                        this.setState({
                            basicTitle:"Success",
                            basicType : "success",
                            show : true,
                            cc_docs:"",
                        });
                        window.location.reload();
                    }).catch((e)=>{
                        console.log(e);
                    })
                }
            }
        }
    }

    onClickGetData =() =>{        
        const jobNo = $("#JobNo").val();
        // console.log("job", jobNo)
        const {rowData} = this.state
        let filteredData = rowData.filter(rec => (rec.job_no == jobNo));
        this.setState({
            gridData : filteredData
        })
        if(jobNo == '' || jobNo == undefined) this.setState({gridData : rowData})

    }

    onClickDataExport = () =>{
        const {erpLength,ringiLength,cargoLength} = this.state
        let expKeys = [
            "job_no_1",
            "date_1",
            "dept_name_1",
            "indenter_name_1",
            "mobile_1",
            "indenter_email_1",
            "assign_to_ff_1",
            "counterpart_details_1",
            "pickup_date_1",
            "cargo_reciving_date_1",
            "airlines_name_1",
            "direct_flight_1",
            "port_name_1",
            "etd_port_date_1",
            "atd_port_date_1",
            "eta_transit_port_date_1",
            "ata_transit_port_date_1",
            "etd_transit_port_date_1",
            "atd_transit_port_date_1",
            "eta_igi_airport_date_1",
            "ata_igi_airport_date_1",
            "if_etd_atd_not_same_1",
            "if_atd_ata_not_same_1",
            "mawb_no_1",
            "hawb_no_1",
            "hawb_date_1",
            "no_of_pkt_1",
            "gross_wt_1",
            "chargeble_weight_1",
            "invoice_no_1",            
            "airfreight_amount_1",
            "fca_exw_amount_1",
            "airfreight_currency_1",
            "other_docs_1",
            "custom_duty_paid_1",
            "assign_to_cha_1",
            "eta_igi_airport_1",
            "ata_igi_airport_1",
            "free_trade_1",
            "benefit_certificate_1",
            "igm_date_1",
            "inward_date_1",
            "segregation_date_1",
            "boe_no_1",
            "boe_date_1",
            "status_1",
            "exam_date_1",
            "otp_date_1",
            "ooc_date_1",
            "exchange_rate_1",
            "assessable_value_1",
            "bcd_1",
            "sws_1",
            "igst_1",
            "total_custom_duty_1",
            "lpc_1",
            "interest_1",
            "meis_1",
            "duty_payable_1",
            "lpc_reason_for_delay_1",
            "bc_no_1_1",
            "bc_1_date_1",
            "bc_no_2_1",
            "bc_2_date_1",
            "tsp_charges_1",
            "demmurage_charges_1",
            "demmurage_charges_amount_1",
            "demmurage_start_date_1",
            "demmurage_end_date_1",
            "reason_for_delay_1",
            "assign_to_transporter_1",
            "airport_name_1",
            "cargo_date_1",
            "vehicle_no_1",
            "exp_delivery_at_msil_1",
            "actual_delivery_at_msil_1",
            "erpPO_1",
            "cargo_delivery_plant_1",
            "no_of_packets_1",            
            "supplier_name_1",
            "supplier_address_1",
            "supplier_contact_person_1",
            "country_1",
            "email_1",
            "phone_1",
            "delivery_terms_1",
            "pick_up_address_1",
            "port_1",
            "lc_1",
            "lc_no_1",
            "nature_1",
            "reject_reason_1",
            "pending_with_1",
            "request_type_1",
            "created_by_user_1",
            "ff_contact_person_details_1"
        ]
        for(var e=0;e<erpLength;e++)
        {
            var eIndex = e+1;
            var erpPO = "erpPoNo"+eIndex;
            var erppoDate  = "erPoDate"+eIndex;
            expKeys.push(erpPO);
            expKeys.push(erppoDate);
        }
        for(var r=0;r<ringiLength;r++)
        {
            var rIndex = r+1;
            var rngFile = "ringi_file"+rIndex;
            var rngDesc  = "ringi_desc"+rIndex;
            expKeys.push(rngFile);
            expKeys.push(rngDesc);
        }
        expKeys.push('cargo_delivery_plant_1',"no_of_packets_1");
        for(var c=0;c<cargoLength;c++)
        {
            var cIndex = c+1;
            var itemDesc = "item_description"+cIndex;
            var cgw  = "cargo_gross_weight"+cIndex;
            var dimensions  = "dimensions"+cIndex;
            expKeys.push(itemDesc);
            expKeys.push(cgw);
            expKeys.push(dimensions);
        }
        var params = {
            columnKeys: expKeys
        };
        // console.log("expke", params)

        this.gridApi.exportDataAsExcel(params);
    }

    downloadRingiFile = (ringiFile) => {
        // console.log('ringiFile',ringiFile)
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            // console.log("rngParam",rngParam)
            redirectURL.post("/consignments/downloadRingiFile",rngParam,{
                responseType: 'blob'
            }).then((response)=>{
                // console.log("dowlad response ",response)
                download(response.data, ringiFile)
            }).catch(function(error){
                console.log(error);
            })
        }
    }

    changeScreen = () =>{
        this.setState({
            sliderTitleName: "Add User Item",
            overly:"show-m",
            loadshow:"show-m",
            bulkslide : "slider-translate-30p",            
        })
	}

    previousscreen = () =>{
        this.setState({
            overly:"show-n",
            loadshow:"show-n",
            bulkslide : "",             
        })
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
	}

    onClickHideAll(){
        // $("#truckNo").val("");
        // $("#consignmentNo").val("");
        this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:"",
        // items: [],
        // source: [],
        // destination: [],
        // deptCodeValue: [],
        erpData:[],
        ringiData:[],
        cargoData:[],   
        });        
    }

    onGridReady = params => {
		this.gridApi = params.api;		
	    // console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;		   
	};
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);
    }
    changeDocFileHandler = (e) => {
        // console.log(e);
        this.setState({
            cc_docs : e.target.files[0]
        });
    }
    uploadCCFileFormHandler(event){
        event.preventDefault();
        var jobNo = this.state.jobNo;
        if(jobNo !=undefined && jobNo !='')
        {
            if(this.state.cc_docs !=undefined && this.state.cc_docs !='')
            {
                let saveData = new FormData();
                saveData.append("cc_docs", this.state.cc_docs);
                saveData.append("job_no", this.state.jobNo);
                saveData.append("username", localStorage.getItem("username"));
                redirectURL.post("/consignments/uploadChaCCDocuments",saveData,{
                    headers:{
                        'content-type': 'multipart/form-data'
                    }
                }).then((response)=>{
                    var chaRowData = this.state.chaRowData;
                    var ccDetails = [];
                    var filteredData = chaRowData.filter(rec => (rec.job_no == jobNo));
                    try{
                        if(filteredData[0].ccData !=undefined && filteredData[0].ccData !='')
                        {
                            var ccData = JSON.parse(filteredData[0].ccData);
                            for(var r=0;r<ccData.length;r++)
                            {
                                ccDetails.push({
                                    "custom_clearance_file" : ccData[r].custom_clearance_file
                                });
                            }
                        }
                    }catch(e){
                        console.log(e);
                    }
                    ccDetails.push({
                        "custom_clearance_file" : this.state.cc_docs.name
                    });
                    $("#other_docs").val("");
                    this.setState({
                        basicTitle:"Success",
                        basicType : "success",
                        show : true,
                        ccData:ccDetails,
                        cc_docs:"",
                    });
                }).catch((e)=>{
                    console.log(e);
                })
            }
            else
            {
                this.setState({
                    basicTitle:"File should not be empty",
                    basicType : "warning",
                    show : true
                });
            }
        }
        else
        {
            this.setState({
                basicTitle:"Something Went wrong, Please try again",
                basicType : "warning",
                show : true
            });
        }
    }

    render(){

        var columnwithDefs = [
        //     {            
        //     colId: "_id",
        //     field: "_id",
        //     headerName: "",
        //     width: 72,
        //     cellRenderer: function(params){
        //         let basestring = params.data._id;
        //         let encryptedstring = window.btoa(basestring);
        //         var htmloption = '<a href="/editffaircargoform/'+encryptedstring+'/2" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
        //         return htmloption;
        //     },
        //   }, 
          {
            headerName: "Job No",
            field: "job_no",
            filter: true,
            resizable: true,
            width: 130,
            pinned: 'left'
          }, {
            headerName: "Date",
            field: "date",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          if(params.data.date !=undefined && params.data.date !='')
                          {
                              return moment.parseZone(params.data.date).format("DD-MM-YYYY")
                          }
                      },
            width: 130
          }, {
            headerName: "Department Name",
            field: "dept_name",
            filter: true,
            resizable: true,
            width: 120
          }, {
            headerName: "Indenter Name",
            field: "indenter_name",
            filter: true,
            resizable: true,
            width: 130
          }, {
            headerName: "Mobile No",
            field: "mobile",
            filter: true,
            resizable: true,
            width: 100
          }, {
            headerName: "Indenter Email",
            field: "indenter_email",
            filter: true,
            resizable: true,
            width: 130
          }, {
            headerName: "Fright Forwarder",
            field: "assign_to_ff",
            filter: true,
            resizable: true,
            width: 180
          }, {
            headerName: "Counterpart Details",
            field: "counterpart_details",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          if(params.data.counterpart_details !=undefined && params.data.counterpart_details !="undefined")
                          {
                              return params.data.counterpart_details;
                          }
                      },
            width: 130
          }, {
            headerName: "Pick up date",
            field: "pickup_date",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          if(params.data.pickup_date !=undefined && params.data.pickup_date !='')
                          {
                              return moment.parseZone(params.data.pickup_date).format("DD-MM-YYYY")
                          }
                      },
            width: 130
          }, {
            headerName: "Cargo reciving date",
            field: "cargo_reciving_date",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          if(params.data.cargo_reciving_date !=undefined && params.data.cargo_reciving_date !='')
                          {
                              return moment.parseZone(params.data.cargo_reciving_date).format("DD-MM-YYYY")
                          }
                      },
            width: 130
          }, {
            headerName: "Airlines Name",
            field: "airlines_name",
            filter: true,
            resizable: true,
            width: 130
          }, {
            headerName: "Direct Flight",
            field: "direct_flight",
            filter: true,
            resizable: true,
            width: 130
          }, {
            headerName: "Port Name",
            field: "port_name",
            filter: true,
            resizable: true,
            width: 130
          }, {
            headerName: "ETD Port date",
            field: "etd_port_date",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          if(params.data.etd_port_date !=undefined && params.data.etd_port_date !='')
                          {
                              return moment.parseZone(params.data.etd_port_date).format("DD-MM-YYYY")
                          }
                      },
            width: 130
          }, {
            headerName: "ATD Port date",
            field: "atd_port_date",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          if(params.data.atd_port_date !=undefined && params.data.atd_port_date !='')
                          {
                              return moment.parseZone(params.data.atd_port_date).format("DD-MM-YYYY")
                          }
                      },
            width: 130
          }, {
            headerName: "ETA Transit Port Date",
            field: "eta_transit_port_date",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          if(params.data.eta_transit_port_date !=undefined && params.data.eta_transit_port_date !='')
                          {
                              return moment.parseZone(params.data.eta_transit_port_date).format("DD-MM-YYYY")
                          }
                      },
            width: 130
          }, {
            headerName: "ATA Transit Port Date",
            field: "ata_transit_port_date",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          if(params.data.ata_transit_port_date !=undefined && params.data.ata_transit_port_date !='')
                          {
                              return moment.parseZone(params.data.ata_transit_port_date).format("DD-MM-YYYY")
                          }
                      },
            width: 130
          }, {
            headerName: "ETD Transit Port Date",
            field: "etd_transit_port_date",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          if(params.data.etd_transit_port_date !=undefined && params.data.etd_transit_port_date !='')
                          {
                              return moment.parseZone(params.data.etd_transit_port_date).format("DD-MM-YYYY")
                          }
                      },
            width: 130
          }, {
            headerName: "ATD Transit Port Date",
            field: "atd_transit_port_date",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          if(params.data.atd_transit_port_date !=undefined && params.data.atd_transit_port_date !='')
                          {
                              return moment.parseZone(params.data.atd_transit_port_date).format("DD-MM-YYYY")
                          }
                      },
            width: 130
          }, {
            headerName: "ETA IGI Airport Date",
            field: "eta_igi_airport_date",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          if(params.data.eta_igi_airport_date !=undefined && params.data.eta_igi_airport_date !='')
                          {
                              return moment.parseZone(params.data.eta_igi_airport_date).format("DD-MM-YYYY")
                          }
                      },
            width: 130
          }, {
            headerName: "ATA IGI Airport Date",
            field: "ata_igi_airport_date",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          if(params.data.ata_igi_airport_date !=undefined && params.data.ata_igi_airport_date !='')
                          {
                              return moment.parseZone(params.data.ata_igi_airport_date).format("DD-MM-YYYY")
                          }
                      },
            width: 130
          }, {
            headerName: "IF ETD and ATD not same",
            field: "if_etd_atd_not_same",
            filter: true,
            resizable: true,
            width: 150
          }, {
            headerName: "If ATD and ATA not same",
            field: "if_atd_ata_not_same",
            filter: true,
            resizable: true,
            width: 150
          }, {
            headerName: "MAWB No",
            field: "mawb_no",
            filter: true,
            resizable: true,
            width: 130
          }, {
            headerName: "HAWB No",
            field: "hawb_no",
            filter: true,
            resizable: true,
            width: 130
          }, {
            headerName: "HAWB date",
            field: "hawb_date",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          if(params.data.hawb_date !=undefined && params.data.hawb_date !='')
                          {
                              return moment.parseZone(params.data.hawb_date).format("DD-MM-YYYY")
                          }
                      },
            width: 130
          }, {
            headerName: "No. of pkt",
            field: "no_of_pkt",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          if(params.data.no_of_pkt !=undefined && params.data.no_of_pkt !="undefined")
                          {
                              return params.data.no_of_pkt;
                          }
                      },
            width: 130
          }, {
            headerName: "Gross wt(In Kgs)",
            field: "gross_wt",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          if(params.data.gross_wt !=undefined && params.data.gross_wt !="undefined")
                          {
                              return params.data.gross_wt;
                          }
                      },
            width: 130
          }, {
            headerName: "Chargeable Weight(In Kgs)",
            field: "chargeble_weight",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          if(params.data.chargeble_weight !=undefined && params.data.chargeble_weight !="undefined")
                          {
                              return params.data.chargeble_weight;
                          }
                      },
            width: 130
          }, {
            headerName: "Invoice No",
            field: "invoice_no",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          if(params.data.invoice_no !=undefined && params.data.invoice_no !="undefined")
                          {
                              return params.data.invoice_no;
                          }
                      },
            width: 130
          }, {            
            headerName: "Pre alert documents",
            field: "invoiceData",
            filter: true,
            resizable: true,
            width: 130,
            cellRenderer: function(params){
                var htmloption = '<button class="btn btn-primary" style="padding-top:0px">View</button>';
                return htmloption;
            },
          }, {
            headerName: "Air freight Amount",
            field: "airfreight_amount",
            filter: true,
            resizable: true,
            width: 130
          }, {
            headerName: "FCA/EXW Amount",
            field: "fca_exw_amount",
            filter: true,
            resizable: true,
            width: 130
          }, {
            headerName: "Air freight Currecny",
            field: "airfreight_currency",
            filter: true,
            resizable: true,
            width: 130
          }, {
            headerName: "Any other docs",
            field: "other_docs",
            filter: true,
            resizable: true,
            width: 130
          }, {
            headerName: "BOE Documents",
            field: "ccData",
            filter: true,
            resizable: true,
            width: 150,
            cellRenderer: function(params){
                var htmloption = '<button class="btn btn-warning" style="padding-top:0px">Add/View</button>';
                return htmloption;
            },
          }, {           
            headerName: "Custom Duty Paid",
            field: "custom_duty_paid",
            filter: true,
            resizable: true,
            width: 150,
            cellRenderer: function(params){
                var htmloption = '<button class="btn btn-info" style="padding-top:0px">Mark As Paid</button>';
                if(params.data.custom_duty_paid == undefined || params.data.custom_duty_paid =='' || params.data.custom_duty_paid == 'no')
                {
                    if(params.data.ccData !=undefined && params.data.ccData !='')
                    {
                        return htmloption;
                    }
                }
                else
                {
                    return params.data.custom_duty_paid;
                }
            }
          }, {
            headerName: "CHA",
            field: "assign_to_cha",
            filter: true,
            resizable: true,
            width: 180
          }, {
            headerName: "ETA IGI Airport",
            field: "eta_igi_airport",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          try{
                              if(params.data.eta_igi_airport_date !='' && params.data.eta_igi_airport_date !=undefined)
                              {
                                  return getHyphenDDMMYYYY(params.data.eta_igi_airport_date);
                              }
                          }
                          catch(e){
                          }
                      },
            width: 120
          }, {
            headerName: "ATA IGI Airport",
            field: "ata_igi_airport",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          try{
                              if(params.data.ata_igi_airport_date !='' && params.data.ata_igi_airport_date !=undefined)
                              {
                                  return getHyphenDDMMYYYY(params.data.ata_igi_airport_date);
                              }
                          }
                          catch(e){
                          }
                      },
            width: 120
          }, {
            headerName: "Free Trade Agreement",
            field: "free_trade",
            filter: true,
            resizable: true,
            width: 180
          }, {
            headerName: "R&amp;D benefit Certificate",
            field: "benefit_certificate",
            filter: true,
            resizable: true,
            width: 180
          },
          {
            headerName: "EPCG",
            field: "epcg",
            width:180,
            filter: true,
            resizable: true,           
          },
          {
            headerName: "IGM Date",
            field: "igm_date",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          try{
                              if(params.data.igm_date !='' && params.data.igm_date !=undefined)
                              {
                                  return getHyphenDDMMYYYY(params.data.igm_date);
                              }
                          }
                          catch(e){
                          }
                      },
            width: 120
          },
          {
            headerName: "Inward Date",
            field: "inward_date",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          try{
                              if(params.data.inward_date !='' && params.data.inward_date !=undefined)
                              {
                                  return getHyphenDDMMYYYY(params.data.inward_date);
                              }
                          }
                          catch(e){
                          }
                      },
            width: 120
          }, {
            headerName: "Segregation Date",
            field: "segregation_date",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          try{
                              if(params.data.segregation_date !='' && params.data.segregation_date !=undefined)
                              {
                                  return getHyphenDDMMYYYY(params.data.segregation_date);
                              }
                          }
                          catch(e){
                          }
                      },
            width: 120
          }, {
            headerName: "BOE No",
            field: "boe_no",
            filter: true,
            resizable: true,
            width: 120
          }, {
            headerName: "BOE Date",
            field: "boe_date",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          try{
                              if(params.data.boe_date !='' && params.data.boe_date !=undefined)
                              {
                                  return getHyphenDDMMYYYY(params.data.boe_date);
                              }
                          }
                          catch(e){
                          }
                      },
            width: 120
          }, {
            headerName: "Status",
            field: "status",
            filter: true,
            resizable: true,
            width: 120
          }, {
            headerName: "Exam Date",
            field: "exam_date",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          try{
                              if(params.data.exam_date !='' && params.data.exam_date !=undefined)
                              {
                                  return getHyphenDDMMYYYY(params.data.exam_date);
                              }
                          }
                          catch(e){
                          }
                      },
            width: 120
          }, {
            headerName: "OTP Date",
            field: "otp_date",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          try{
                              if(params.data.otp_date !='' && params.data.otp_date !=undefined)
                              {
                                  return getHyphenDDMMYYYY(params.data.otp_date);
                              }
                          }
                          catch(e){
                          }
                      },
            width: 120
          }, {
            headerName: "OOC Date",
            field: "ooc_date",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          try{
                              if(params.data.ooc_date !='' && params.data.ooc_date !=undefined)
                              {
                                  return getHyphenDDMMYYYY(params.data.ooc_date);
                              }
                          }
                          catch(e){
                          }
                      },
            width: 120
          }, {
            headerName: "Exchange Rate",
            field: "exchange_rate",
            filter: true,
            resizable: true,
            width: 120
          }, {
            headerName: "Assessable Value",
            field: "assessable_value",
            filter: true,
            resizable: true,
            width: 120
          }, {
            headerName: "BCD",
            field: "bcd",
            filter: true,
            resizable: true,
            width: 120
          }, {
            headerName: "SWS",
            field: "sws",
            filter: true,
            resizable: true,
            width: 120
          }, {
            headerName: "IGST",
            field: "igst",
            filter: true,
            resizable: true,
            width: 120
          }, {
            headerName: "Total Custom Duty",
            field: "total_custom_duty",
            filter: true,
            resizable: true,
            width: 120
          }, {
            headerName: "LPC",
            field: "lpc",
            filter: true,
            resizable: true,
            width: 120
          }, {
            headerName: "Interest",
            field: "interest",
            filter: true,
            resizable: true,
            width: 120
          }, {
            headerName: "MEIS",
            field: "meis",
            filter: true,
            resizable: true,
            width: 120
          }, {
            headerName: "Duty payable",
            field: "duty_payable",
            filter: true,
            resizable: true,
            width: 120
          }, {
            headerName: "Reason For Delay",
            field: "lpc_reason_for_delay",
            filter: true,
            resizable: true,
            width: 120
          }, {
            headerName: "BC No 1",
            field: "bc_no_1",
            filter: true,
            resizable: true,
            width: 120
          }, {
            headerName: "BC-1 Date",
            field: "bc_1_date",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          try{
                              if(params.data.bc_1_date !='' && params.data.bc_1_date !=undefined)
                              {
                                  return getHyphenDDMMYYYY(params.data.bc_1_date);
                              }
                          }
                          catch(e){
                          }
                      },
            width: 120
          }, {
            headerName: "BC No 2",
            field: "bc_no_2",
            filter: true,
            resizable: true,
            width: 120
          }, {
            headerName: "BC-2 Date",
            field: "bc_2_date",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          try{
                              if(params.data.bc_2_date !='' && params.data.bc_2_date !=undefined)
                              {
                                  return getHyphenDDMMYYYY(params.data.bc_2_date);
                              }
                          }
                          catch(e){
                          }
                      },
            width: 120
          }, {
            headerName: "TSP charges",
            field: "tsp_charges",
            filter: true,
            resizable: true,
            width: 120
          }, {
            headerName: "Demmurage Charges",
            field: "demmurage_charges",
            filter: true,
            resizable: true,
            width: 120
          }, {
            headerName: "Demmurage Charges Amount",
            field: "demmurage_charges_amount",
            filter: true,
            resizable: true,
            width: 150
          }, {
            headerName: "Demmurage Start Date",
            field: "demmurage_start_date",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          try{
                              if(params.data.demmurage_start_date !='' && params.data.demmurage_start_date !=undefined)
                              {
                                  return getHyphenDDMMYYYY(params.data.demmurage_start_date);
                              }
                          }
                          catch(e){
                          }
                      },
            width: 120
          }, {
            headerName: "Demmurage End Date",
            field: "demmurage_end_date",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          try{
                              if(params.data.demmurage_end_date !='' && params.data.demmurage_end_date !=undefined)
                              {
                                  return getHyphenDDMMYYYY(params.data.demmurage_end_date);
                              }
                          }
                          catch(e){
                          }
                      },
            width: 120
          }, {
            headerName: "Reason For Delay",
            field: "reason_for_delay",
            filter: true,
            resizable: true,
            width: 120
          }, {
            headerName: "Transporter",
            field: "assign_to_transporter",
            filter: true,
            resizable: true,
            width: 180
          }, {
            headerName: "Airport Name",
            field: "airport_name",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          try{
                              if(params.data.airport_name !='' && params.data.airport_name !=undefined && params.data.airport_name !="undefined")
                              {
                                  return params.data.airport_name;
                              }
                          }
                          catch(e){
                          }
                      },
            width: 180
          }, {
            headerName: "Proposed Cargo Readiness Date",
            field: "cargo_date",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          try{
                              if(params.data.cargo_date !='' && params.data.cargo_date !=undefined)
                              {
                                  return getHyphenDDMMMYYYY(params.data.cargo_date);
                              }
                          }
                          catch(e){
                          }
                      },
            width: 180
          }, {
            headerName: "Vehicle No",
            field: "vehicle_no",
            filter: true,
            resizable: true,
            width: 130
          }, {
            headerName: "Expected Delivery at MSIL",
            field: "exp_delivery_at_msil",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          try{
                              if(params.data.exp_delivery_at_msil !='' && params.data.exp_delivery_at_msil !=undefined)
                              {
                                  return getHyphenDDMMYYYY(params.data.exp_delivery_at_msil);
                              }
                          }
                          catch(e){
                          }
                      },
            width: 150
          }, {
            headerName: "Actual Delivery at MSIL",
            field: "actual_delivery_at_msil",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          try{
                              if(params.data.actual_delivery_at_msil !='' && params.data.actual_delivery_at_msil !=undefined)
                              {
                                  return getHyphenDDMMYYYY(params.data.actual_delivery_at_msil);
                              }
                          }
                          catch(e){
                          }
                      },
            width: 120
          }, {
            headerName: "ERP",
            field: "erpPO",
            filter: true,
            resizable: true,
            width: 100
          }, {            
            headerName: "ERP Data",
            field: "erpData",
            filter: true,
            resizable: true,
            width: 100,
            cellRenderer: function(params){
                let basestring = params.data.batch_name;
                let encryptedstring = window.btoa(basestring);
                var htmloption = '<button class="btn btn-info" style="padding-top:0px">View</button>';
                return htmloption;
            },
          }, {            
            headerName: "Ringi Data",
            field: "ringiData",
            filter: true,
            resizable: true,
            width: 100,
            cellRenderer: function(params){
                let basestring = params.data.batch_name;
                let encryptedstring = window.btoa(basestring);
                var htmloption = '<button class="btn btn-danger" style="padding-top:0px">View</button>';
                return htmloption;
            },
          }, {
            headerName: "Cargo Delivery Plant",
            field: "cargo_delivery_plant",
            filter: true,
            resizable: true,
            width: 130
          }, {
            headerName: "No Of Packets",
            field: "no_of_packets",
            filter: true,
            resizable: true,
            width: 130
          }, {
            headerName: "Cargo Data",
            field: "cargoData",
            filter: true,
            resizable: true,
            width: 130,
            cellRenderer: function(params){
                let basestring = params.data.batch_name;
                let encryptedstring = window.btoa(basestring);
                var htmloption = '<button class="btn btn-primary" style="padding-top:0px">View</button>';
                return htmloption;
            },
          }, {
            headerName: "Supplier Name",
            field: "supplier_name",
            filter: true,
            resizable: true,
            width: 130
          }, {
            headerName: "Supplier Address",
            field: "supplier_address",
            filter: true,
            resizable: true,
            width: 130
          }, {
            headerName: "Supplier contact person",
            field: "supplier_contact_person",
            filter: true,
            resizable: true,
            width: 130
          }, {
            headerName: "Country",
            field: "country",
            filter: true,
            resizable: true,
            width: 130
          }, {
            headerName: "Email id",
            field: "email",
            filter: true,
            resizable: true,
            width: 130
          }, {
            headerName: "Phone No",
            field: "phone",
            filter: true,
            resizable: true,
            width: 130
          }, {
            headerName: "Delivery Terms",
            field: "delivery_terms",
            filter: true,
            resizable: true,
            width: 130
          }, {
            headerName: "Pick up address",
            field: "pick_up_address",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          try{
                              if(params.data.pick_up_address !=undefined && params.data.pick_up_address !="undefined")
                              {
                                  return params.data.pick_up_address;
                              }
                          }
                          catch(e){
          
                          }
                      },
            width: 130
          }, {
            headerName: "Place/port",
            field: "port",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          try{
                              if(params.data.port !=undefined && params.data.port !="undefined")
                              {
                                  return params.data.port;
                              }
                          }
                          catch(e){
          
                          }
                      },
            width: 130
          }, {
            headerName: "LC Available",
            field: "lc",
            filter: true,
            resizable: true,
            width: 130
          }, {
            headerName: "LC No",
            field: "lc_no",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          try{
                              if(params.data.lc_no !=undefined && params.data.lc_no !="undefined")
                              {
                                  return params.data.lc_no;
                              }
                          }
                          catch(e){
          
                          }
                      },
            width: 130
          }, {
            headerName: "Nature",
            field: "nature",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          try{
                              if(params.data.nature !=undefined && params.data.nature !="undefined")
                              {
                                  return params.data.nature;
                              }
                          }
                          catch(e){
          
                          }
                      },
            width: 130
          }, {
            headerName: "Rejected Reason",
            field: "reject_reason",
            filter: true,
            resizable: true,
            width: 150
          }, {
            headerName: "Pending With",
            field: "pending_with",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                          if(params.data.pending_with == 1)
                          {
                              return "Freight Forwarder";
                          }
                          else
                          {
                              return "CHA";
                          }
                      },
            width: 130
          }
        //   ,{
        //     cellRenderer: function(params){
        //                   let basestring = params.data._id;
        //                   let encryptedstring = window.btoa(basestring);
        //                   if(params.data.request_type == 3)
        //                   {
        //                       var htmloption = '<a href="/editffnewaircargoform/'+encryptedstring+'" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
        //                   }
        //                   else
        //                   {
        //                       var htmloption = '<a href="/editaircargoform/'+encryptedstring+'" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
        //                   }
        //                   return htmloption;
        //               },
        //     colId: "_id",
        //     field: "",
        //     headerName: "",
        //     width: 72
        //   }
        //   , {
        //     cellRenderer: function(params){
        //                   var htmloption = '<button class="btn btn-primary" style="padding:0 9px">Reject</button>';
        //                   return htmloption;
        //               },
        //     field: "reject",
        //     filter: true,
        //     headerName: "",
        //     resizable: true,
        //     width: 100
        //   }
          ,{
            field: "request_type",
            filter: true,
            headerName: "Request Type",
            resizable: true,
            valueGetter: function(params){
                          if(params.data.request_type == 1)
                          {
                              return "Booking Request";
                          }
                          else if(params.data.request_type == 2)
                          {
                              return "Prepaid Shipment";
                          }
                          else
                          {
                              return "FF New Request";
                          }
                      },
            width: 150
          }, {
            field: "created_by_user",
            filter: true,
            headerName: "Submitted By",
            resizable: true,
            width: 130
          }, {
            field: "ff_contact_person_details",
            filter: true,
            headerName: "FF Contact person Details",
            resizable: true,
            valueGetter: function(params){
                          try{
                              if(params.data.ff_contact_person_details !=undefined && params.data.ff_contact_person_details !="undefined")
                              {
                                  return params.data.ff_contact_person_details;
                              }
                          }
                          catch(e){
          
                          }
                      },
            width: 150
          }]
        var erpLength = this.state.erpLength;
    for(var e=0;e<erpLength;e++)
    {
        var eIndex = e+1;
        var erpPO = "erpPoNo"+eIndex;
        var erpPODate = "erPoDate"+eIndex;
        var erpData = {
            headerName: "ERP PO No "+"-"+eIndex,
            field: erpPO,
            hide:true,
            width:130,
            filter: true,
            resizable: true,           
        };
        columnwithDefs.push(erpData);
        var erpDate = {
            headerName: "ERP PO Date "+"-"+eIndex,
            field: erpPODate,
            hide:true,
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data[erpPODate] !=undefined && params.data[erpPODate] !='')
                {
                    return moment.parseZone(params.data[erpPODate]).format("DD-MM-YYYY")
                }
            }           
        };
        columnwithDefs.push(erpDate);
    }
    var ringiLength = this.state.ringiLength;
    for(var r=0;r<ringiLength;r++)
    {
        var rIndex = r+1;
        var ringiFile = "ringi_file"+rIndex;
        var ringiDesc = "ringi_desc"+rIndex;
        var ringiData = {
            headerName: "Ringi File"+"-"+rIndex,
            field: ringiFile,
            hide:true,
            width:130,
            filter: true,
            resizable: true,           
        };
        columnwithDefs.push(ringiData);
        var ringiDescData = {
            headerName: "Ringi No"+"-"+rIndex,
            field: ringiDesc,
            hide:true,
            width:130,
            filter: true,
            resizable: true,           
        };
        columnwithDefs.push(ringiDescData);
    }
    var cargoLength = this.state.cargoLength;
    for(var c=0;c<cargoLength;c++)
    {
        var cIndex = c+1;
        var itemDesc = "item_description"+cIndex;
        var cargogw = "cargo_gross_weight"+cIndex;
        var dimensions = "dimensions"+cIndex;
        var cargoData = {
            headerName: "Item Description"+"-"+cIndex,
            field: itemDesc,
            hide:true,
            width:130,
            filter: true,
            resizable: true,           
        };
        columnwithDefs.push(cargoData);
        var cargogrossData = {
            headerName: "Cargo Gross Weight"+"-"+cIndex,
            field: cargogw,
            hide:true,
            width:130,
            filter: true,
            resizable: true,           
        };
        columnwithDefs.push(cargogrossData);
        var cargodmData = {
            headerName: "Dimensions"+"-"+cIndex,
            field: dimensions,
            hide:true,
            width:130,
            filter: true,
            resizable: true,           
        };
        columnwithDefs.push(cargodmData);
    }


    return(
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                    >
	             </SweetAlert>
                 <div className="row">
                 <div className=" col-xl-12 col-lg-12">                    
                    <div className="card">
                        <div className="card-header space-between">
                             <h5>
                             <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Air Cargo List </span>
                             </h5>                             
                        </div>
                        
                        <div id="tptUserItemGrid" className="card-body col-sm-12"> 
                            <div className="form-theme col-xl-12 col-lg-12 row">
                                <div className="col-xl-2 col-lg-2  form-group">
                                    {/* <label>From Date</label> */}
                                    <input type="text" placeholder="Job Number" id="JobNo"
                                    // value={this.state.startDate}
                                    autocomplete="off" className="form-control" required />
                                </div>
                                <div className="col-xl-2 col-lg-2 form-group">
                                    {/* <label>&nbsp;</label><br /> */}
                                    <button type="button" className="btn btn-success" style={{padding:"5px 10px",marginRight:"2%"}} onClick={this.onClickGetData}>Search</button>                              			
                                </div>
                                <div className="col-xl-8 col-lg-8 form-group">                               
                                    <button className="btn btn-danger" style={{float:"right",marginRight:"10px"}} onClick={this.onClickDataExport}>Export Data</button>				
                                </div>
                            </div>                      
                            <div id="myGrid" style={{ height: "580px",width: "100%",}}  className="ag-theme-balham">
                                <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.gridData}
                                enableCharts={true}
                                enableRangeSelection={true}
                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                detailCellRendererParams={this.state.detailCellRendererParams}
                                stopEditingWhenGridLosesFocus={true}
                                enableCellChangeFlash={true}
                                suppressCellFlash={true}
                                rowClassRules={this.state.rowClassRules}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                masterDetail={true}
                                onCellClicked={this.onCellRowClicked}			
                                suppressRowClickSelection={true}
                                components={this.state.components}
                                rowSelection={this.state.rowSelection}
                                // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                //onCellClicked={this.onRowClicked.bind(this)}
                                //getRowHeight={this.state.getRowHeight}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                // onRowSelected={this.onRowSelection.bind(this)}
                                // suppressCellSelection= {true}
                                // overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}										
                                />
                            </div>								
                        </div>
                        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
                        <h3 className="subH">
                            {this.state.sliderTitle}
                            <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                        </h3>
                <div className="slide-r-body" style={{position:"relative"}}>
                    {(this.state.erpData.length > 0) ?                         
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <table className="table table-bordered tptinfo">
                                        <thead>
                                            <tr>
                                                <th>ERP PO No</th>
                                                <th>ERP PO Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.erpData.map((e, index) => (
                                            <tr>
                                                <td>{e.erpPoNo}</td>
                                                <td>{e.erPoDate}</td>
                                            </tr>
                                             ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    : ""}
                    {(this.state.ringiData.length > 0) ?                         
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <table className="table table-bordered tptinfo">
                                        <thead>
                                            <tr>
                                                <th>Ringi No</th>
                                                <th>Ringi (File)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.ringiData.map((e, index) => (
                                            <tr>
                                                <td>{e.ringi_desc}</td>
                                                <td style={{cursor:"pointer"}} onClick={() => this.downloadRingiFile(e.ringi_file)}>{e.ringi_file}</td>
                                            </tr>
                                             ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    : ""}
                    {(this.state.cargoData.length > 0) ?                         
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <table className="table table-bordered tptinfo">
                                        <thead>
                                            <tr>
                                                <th>Item Description</th>
                                                <th>Cargo Gross Weight</th>
                                                <th>Dimensions (cms)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.cargoData.map((e, index) => (
                                            <tr>
                                                <td>{e.item_description}</td>
                                                <td>{e.cargo_gross_weight}</td>
                                                <td>{e.dimensions}</td>
                                            </tr>
                                             ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    : ""}
                    {/* {(this.state.rejectedreason == 1) ?                         
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <form method="POST" id="rejectform" className="theme-form" onSubmit={this.rejectCargoRequest}>
                                        <div className="form-group mt-20p">
                                            <label className="">Reason</label> 
                                            <textarea name="reject_reason" id="reject_reason" className="form-control" required></textarea>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-success">Reject</button>
                                            <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    : ""} */}
                    {(this.state.jobNo != "") ?                    
                            <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadCCFileFormHandler} style={{marginTop:"2%"}}>
                                <div className="form-group">
                                    <label className="">Upload New Document</label>
                                    <input type="file" name="other_docs" id="other_docs" className="form-control" onChange={this.changeDocFileHandler} required/>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>
                    : ""}
                    {(this.state.invoiceData.length > 0) ?                         
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <table className="table table-bordered tptinfo">
                                                <thead>
                                                    <tr>
                                                        <th>Documents</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.invoiceData.map((e, index) => (
                                                    <tr>
                                                        <td style={{cursor:"pointer"}} onClick={() => this.downloadRingiFile(e.invoice_file)}>{e.invoice_file}</td>
                                                    </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            : ""}              
                </div>

                {/* <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
                        <h3 className="subH">
                            {this.state.sliderTitle}
                            <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                        </h3>
                        <div className="slide-r-body" style={{position:"relative"}}>
                            {(this.state.invoiceData.length > 0) ?                         
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <table className="table table-bordered tptinfo">
                                                <thead>
                                                    <tr>
                                                        <th>Documents</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.invoiceData.map((e, index) => (
                                                    <tr>
                                                        <td style={{cursor:"pointer"}} onClick={() => this.downloadRingiFile(e.invoice_file)}>{e.invoice_file}</td>
                                                    </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            : ""}
                        </div>
                    </div>*/}
                </div> 
                </div>
                </div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
           
        )
    }

}
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";import { getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS, 
    getHyphenYYYYMMDD, 
    getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY } from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';

import ColumnDrilldownOnTime from './columndrilldownontime';
import OnTimeColumnChart from './ontimecolumnchart';
import OnTimeLineChart from "./ontimelinechart";
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');
var redirectURL = require('../redirectURL');
var transportercolumnwithDefs =[
    {
        headerName : "Transporter Code",
        field : "transporter_code",
        editable : false,
        resizable :true,
    },
    {
        headerName : "Transporter Name",
        field : "transporter_name",
        editable : false,
        resizable :true,
    },
    {
        headerName : "Total",
        field : "Total",
        editable : false,
        resizable :true,
    },
    {
        headerName : "POD Not Processed",
        field : "POD Not Processed",
        editable : false,
        resizable :true,
    },
    // {
    //     headerName : "POD Not Processed Per",
    //     field : "POD Not Processed Per",
    //     editable : false,
    //     resizable :true,
    //     width:150
    // },
    {
        headerName : "On Time",
        field : "Ontime",
        editable : false,
        resizable :true,
    },
    // {
    //     headerName : "On Time Per",
    //     field : "Ontime_per",
    //     editable : false,
    //     resizable :true,
    //     width:100
    // },
    {
        headerName : "Delayed",
        field : "Delayed",
        editable : false,
        resizable :true,
    },
    // {
    //     headerName : "Delayed Per",
    //     field : "Delayed_per",
    //     editable : false,
    //     resizable :true,
    //     width:100
    // }
]
var consolidatedcolumnwithDefs =[
    {
        headerName : "Route ID",
        field : "route_id",
        editable : false,
        resizable :true,
        width:160
    },
    {
        headerName : "State",
        field : "consignee_state",
        editable : false,
        resizable :true,
        
    },
    {
        headerName : "Total",
        field : "Total",
        editable : false,
        resizable :true,
    },
    {
        headerName : "POD Not Processed",
        field : "POD Not Processed",
        editable : false,
        resizable :true,
    },
    // {
    //     headerName : "POD Not Processed Per",
    //     field : "POD Not Processed Per",
    //     editable : false,
    //     resizable :true,
    //     width:150
    // },
    {
        headerName : "On Time",
        field : "Ontime",
        editable : false,
        resizable :true,
        width:150
    },
    
    {
        headerName : "Delayed",
        field : "Delayed",
        editable : false,
        resizable :true,
        width:150
    },
    {
        headerName : "On Time Percentage",
        field : "Ontime_per",
        editable : false,
        resizable :true,
        width:120
    },
    // {
    //     headerName : "Delayed Per",
    //     field : "Delayed_per",
    //     editable : false,
    //     resizable :true,
    //     width:100
    // }
]
var routewisecolumnwithDefs =[
    
    {
        headerName : "Route ID",
        field : "route_id",
        editable : false,
        resizable :true,
        width:160
    },
    {
        headerName : "Total",
        field : "Total",
        editable : false,
        resizable :true,
    },
    {
        headerName : "POD Not Processed",
        field : "POD Not Processed",
        editable : false,
        resizable :true,
    },
    {
        headerName : "On Time",
        field : "Ontime",
        editable : false,
        resizable :true,
    },
    {
        headerName : "Delayed",
        field : "Delayed",
        editable : false,
        resizable :true,
    },
]
var completedatacolumnwithDefs =[
    {
        headerName : "Region",
        field : "region",
        editable : false,
        resizable :true,
        width:130
    },
    {
        headerName : "Load TVP Flag",
        field : "rail_cluster",
        editable : false,
        resizable :true,
        width:130,
        valueGetter : function(params)
        {
            if(params.data.rail_cluster == "TVPB" || params.data.rail_cluster == "TVPN" || params.data.rail_cluster == "TVP Chennai")
            {
                return params.data.rail_cluster;
            }
            else{
                return "NTVP";
            }
        }
    },
    {
        headerName : "Trip No",
        field : "consignment_code",
        editable : false,
        resizable :true,
        width:130
    },
    {
        headerName : "Load No",
        field : "load_no",
        editable : false,
        resizable :true,
        width:130
    },
    {
        headerName : "IMM For Code",
        field : "imm_for_code",
        editable : false,
        resizable :true,
        width:130
    },
    {
        headerName : "Plan Date",
        field : "load_attach_date",
        editable : false,
        resizable :true,
        width:130,
        valueGetter : function(params){
            if(params.data.load_attach_date != "")
            {
                return getHyphenDDMMMYYYYHHMM(params.data.load_attach_date);
            }
            else
            {
                return "";
            }
        },
        //filter: "agDateColumnFilter",
        comparator: dateComparator,
        // filterParams: {
        //     browserDatePicker: true,
        //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
        //     // provide comparator function
        //     comparator: function(filteredDate,cellValue,secondDate) {
        //         cellValue = cellValue.replace(/\//g,"-")
        //         if(cellValue != "" && cellValue != " "){
        //             cellValue = cellValue.split(" ")[0].split("-");
        //             cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
        //             var cellDate = new Date(cellValue);
        //             if(filteredDate.getMonth() < 10){
        //             var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
        //             }
        //             else{
        //                 var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
        //             }
        //             if(filterValue.split("-")[2].length < 2)
        //             {
        //                 var temp = filterValue
        //                 filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
        //             }
        //             var filteredDate = new Date(filterValue);
        //             cellDate = cellDate.getTime();
        //             filteredDate = filteredDate.getTime();
        //             // console.log(cellDate,filteredDate);
        //             if(cellDate === filteredDate)
        //             {
        //                 return 0;
        //             }
        //             if(cellDate < filteredDate){
        //                 return -1;
        //             }
        //             if(cellDate > filteredDate)
        //             {
        //                 return 1;
        //             }
        //         }
        //     }
        // },
    },
    {
        headerName : "Invoice No",
        field : "invoice_no",
        editable : false,
        resizable :true,
        width:130
    },
    // {
    //     headerName : "Club Load No",
    //     field : "club_load_no",
    //     editable : false,
    //     resizable :true,
    //     width:130
    // },
    {
        headerName : "Vin (Prefix & Chassis)",
        field : "vin",
        editable : false,
        resizable :true,
        width:150
    },
    {
        headerName : "Invoice Amount",
        field : "invoice_value",
        editable : false,
        resizable :true,
        width:130
    },
    {
        headerName : "Reck No",
        field : "reck_number",
        editable : false,
        resizable :true,
        width:130
    },
    {
        headerName : "For Code",
        field : "for_code",
        editable : false,
        resizable :true,
        width:130
    },
    {
        headerName : "Cluster",
        field : "rail_cluster",
        editable : false,
        resizable :true,
        width:130
    },
    // {
    //     headerName : "Truck No",
    //     field : "truck_no",
    //     editable : false,
    //     resizable :true,
    //     width:130
    // },
    {
        headerName : "Dealer Code",
        field : "consignee_code",
        editable : false,
        resizable :true,
        width:130
    },
    {
        headerName : "Invoice Date",
        field : "invoice_date",
        editable : false,
        resizable :true,
        width:130,
        valueGetter : function(params){
            if(params.data.invoice_date != "")
            {
                return getHyphenDDMMMYYYYHHMM(params.data.invoice_date);
            }
            else
            {
                return "";
            }
        },
        //filter: "agDateColumnFilter",
        comparator: dateComparator,
         // filterParams: {
        //     browserDatePicker: true,
        //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
        //     // provide comparator function
        //     comparator: function(filteredDate,cellValue,secondDate) {
        //         cellValue = cellValue.replace(/\//g,"-")
        //         if(cellValue != "" && cellValue != " "){
        //             cellValue = cellValue.split(" ")[0].split("-");
        //             cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
        //             var cellDate = new Date(cellValue);
        //             if(filteredDate.getMonth() < 10){
        //             var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
        //             }
        //             else{
        //                 var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
        //             }
        //             if(filterValue.split("-")[2].length < 2)
        //             {
        //                 var temp = filterValue
        //                 filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
        //             }
        //             var filteredDate = new Date(filterValue);
        //             cellDate = cellDate.getTime();
        //             filteredDate = filteredDate.getTime();
        //             // console.log(cellDate,filteredDate);
        //             if(cellDate === filteredDate)
        //             {
        //                 return 0;
        //             }
        //             if(cellDate < filteredDate){
        //                 return -1;
        //             }
        //             if(cellDate > filteredDate)
        //             {
        //                 return 1;
        //             }
        //         }
        //     }
        // },
    },
    {
        headerName : "Transporter Code",
        field : "transporter_code",
        editable : false,
        resizable :true,
        width:130
    },
    {
        headerName : "Truck Type",
        field : "truck_type",
        editable : false,
        resizable :true,
        width:130
    },
    {
        headerName : "Month End Flag",
        field : "month_end_flag",
        editable : false,
        resizable :true,
        width:130,
        valueGetter : function(params)
        {
            if(params.data.month_end_flag == "A")
            {
                return "Y"
            }
            else
            {
                return params.data.month_end_flag;
            }

        }
    },
    {
        headerName : "Security Date",
        field : "gate_out_time",
        editable : false,
        resizable :true,
        width:130,
        valueGetter : function(params){
            if(params.data.gate_out_time != "")
            {
                return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
            }
            else
            {
                return "";
            }
        },
        //filter: "agDateColumnFilter",
        comparator: dateComparator,
         // filterParams: {
        //     browserDatePicker: true,
        //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
        //     // provide comparator function
        //     comparator: function(filteredDate,cellValue,secondDate) {
        //         cellValue = cellValue.replace(/\//g,"-")
        //         if(cellValue != "" && cellValue != " "){
        //             cellValue = cellValue.split(" ")[0].split("-");
        //             cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
        //             var cellDate = new Date(cellValue);
        //             if(filteredDate.getMonth() < 10){
        //             var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
        //             }
        //             else{
        //                 var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
        //             }
        //             if(filterValue.split("-")[2].length < 2)
        //             {
        //                 var temp = filterValue
        //                 filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
        //             }
        //             var filteredDate = new Date(filterValue);
        //             cellDate = cellDate.getTime();
        //             filteredDate = filteredDate.getTime();
        //             // console.log(cellDate,filteredDate);
        //             if(cellDate === filteredDate)
        //             {
        //                 return 0;
        //             }
        //             if(cellDate < filteredDate){
        //                 return -1;
        //             }
        //             if(cellDate > filteredDate)
        //             {
        //                 return 1;
        //             }
        //         }
        //     }
        // },
    },
    {
        headerName : "Gate In Time",
        field : "",
        editable : false,
        resizable :true,
        width:130,
        valueGetter : function(params){
            if(params.data.rail_cluster == "TVPB" || params.data.rail_cluster == "TVPN" || params.data.rail_cluster == "TVP Chennai")
            {
                return getHyphenDDMMMYYYYHHMM(params.data.tvp_gate_in);   
            }
            else
            {
                return getHyphenDDMMMYYYYHHMM(params.data.trip_delivery_date); 
            }
            
            
        },
        //filter: "agDateColumnFilter",
        comparator: dateComparator,
         // filterParams: {
        //     browserDatePicker: true,
        //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
        //     // provide comparator function
        //     comparator: function(filteredDate,cellValue,secondDate) {
        //         cellValue = cellValue.replace(/\//g,"-")
        //         if(cellValue != "" && cellValue != " "){
        //             cellValue = cellValue.split(" ")[0].split("-");
        //             cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
        //             var cellDate = new Date(cellValue);
        //             if(filteredDate.getMonth() < 10){
        //             var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
        //             }
        //             else{
        //                 var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
        //             }
        //             if(filterValue.split("-")[2].length < 2)
        //             {
        //                 var temp = filterValue
        //                 filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
        //             }
        //             var filteredDate = new Date(filterValue);
        //             cellDate = cellDate.getTime();
        //             filteredDate = filteredDate.getTime();
        //             // console.log(cellDate,filteredDate);
        //             if(cellDate === filteredDate)
        //             {
        //                 return 0;
        //             }
        //             if(cellDate < filteredDate){
        //                 return -1;
        //             }
        //             if(cellDate > filteredDate)
        //             {
        //                 return 1;
        //             }
        //         }
        //     }
        // },
    },
    {
        headerName : "Standarad Transit Time",
        headerClass:["cellstylegridB","cellTopHeader"],
        children : [
            {
                headerName : "Standard Transit Time (Days)",
                field : "rail_std_time",
                editable : false,
                resizable :true,
                width:130,
                headerClass:"cellstylegridB",
                cellClass:'cellstylegridB'
            },
            {
                headerName : "Actual Transit Time (Days)",
                field : "trip_transit_days_stt",
                editable : false,
                resizable :true,
                width:130,
                headerClass:"cellstylegridB",
                cellClass:'cellstylegridB'
            },
            {
                headerName : "Delay Time (Days)",
                field : "trip_delay_days_stt",
                editable : false,
                resizable :true,
                width:130,
                headerClass:"cellstylegridB",
                cellClass:'cellstylegridB'
            },
            {
                headerName : "Dealer Compensation",
                field : "dealer_compensation_stt",
                editable : false,
                resizable :true,
                width:130,
                headerClass:"cellstylegridB",
                cellClass:'cellstylegridB'
            },
            {
                headerName : "Dealer Compensable",
                field : "dealer_comp_flag_stt",
                editable : false,
                resizable :true,
                width:130,
                headerClass:"cellstylegridB",
                cellClass:'cellstylegridB',
                valueGetter : function(params){
                    if(params.data.dealer_comp_flag_stt == 0)
                    {
                        return "No";
                    }
                    if(params.data.dealer_comp_flag_stt == 1)
                    {
                        return "Yes";
                    }
                }
            },
        ]
    },
    {
        headerName : "Trip Transit Time",
        headerClass:["cellstylegridG","cellTopHeader"],	
        children : [
            {
                headerName : "Trip Transit Time (Days)",
                field : "rail_trip_time",
                editable : false,
                resizable :true,
                width:130,
                headerClass:"cellstylegridG",
				cellClass:'cellstylegridG',
            },
            {
                headerName : "Actual Transit Time (Days)",
                field : "trip_transit_days_tt",
                editable : false,
                resizable :true,
                width:130,
                headerClass:"cellstylegridG",
				cellClass:'cellstylegridG',
            },
            {
                headerName : "Delay Time (Days)",
                field : "trip_delay_days_tt",
                editable : false,
                resizable :true,
                width:130,
                headerClass:"cellstylegridG",
				cellClass:'cellstylegridG',
            },
            {
                headerName : "Dealer Compensation",
                field : "dealer_compensation_tt",
                editable : false,
                resizable :true,
                width:130,
                headerClass:"cellstylegridG",
				cellClass:'cellstylegridG',
            },
            {
                headerName : "Dealer Compensable",
                field : "dealer_comp_flag_tt",
                editable : false,
                resizable :true,
                width:130,
                headerClass:"cellstylegridG",
				cellClass:'cellstylegridG',
                valueGetter : function(params){
                    if(params.data.dealer_comp_flag_tt == 0)
                    {
                        return "No";
                    }
                    if(params.data.dealer_comp_flag_tt == 1)
                    {
                        return "Yes";
                    }
                }
            },
        ]
    },
    {
        headerName : "Consigner Code",
        field : "consigner_code",
        editable : false,
        resizable :true,
        width:130
    },
    // {
    //     headerName : "POD Processed",
    //     field : "pod_recieved",
    //     editable : false,
    //     resizable :true,
    //     width:130,
    //     valueGetter : function(params)
    //     {
    //         if(params.data.pod_received == 1)
    //         {
    //             return "Yes";
    //         }
    //         else
    //         {
    //             return "No";
    //         }
    //     }
    // }
    
    
    // {
    //     headerName : "Transporter Name",
    //     field : "transporter_name",
    //     editable : false,
    //     resizable :true,
    // }, 
    
    // {
    //     headerName : "Consignee Name",
    //     field : "consignee_name",
    //     editable : false,
    //     resizable :true,
    //     width:130
    // },
    // {
    //     headerName : "POD Date",
    //     field : "trip_actual_delivery_date",
    //     editable : false,
    //     resizable :true,
    //     width:130,
    //     valueGetter : function(params){
    //         if(params.data.trip_actual_delivery_date != "")
    //         {
    //             return getHyphenDDMMMYYYYHHMM(params.data.trip_actual_delivery_date);
    //         }
    //         else
    //         {
    //             return "";
    //         }
    //     }
    // },
    
    // {
    //     headerName : "Expected Delivery Date",
    //     field : "dealer_comp_expected_eta",
    //     editable : false,
    //     resizable :true,
    //     width:130,
    //     valueGetter : function(params){
    //         if(params.data.dealer_comp_expected_eta != "")
    //         {
    //             return getHyphenDDMMMYYYYHHMM(params.data.dealer_comp_expected_eta);
    //         }
    //         else
    //         {
    //             return "";
    //         }
    //     }
    // },
    // {
    //     headerName : "POD Date",
    //     field : "trip_actual_delivery_date",
    //     editable : false,
    //     resizable :true,
    //     width:130,
    //     valueGetter : function(params){
    //         if(params.data.trip_actual_delivery_date != "")
    //         {
    //             return getHyphenDDMMMYYYYHHMM(params.data.trip_actual_delivery_date)
    //         }
    //         else
    //         {
    //             return "";
    //         }
    //     }
    // },
    // {
    //     headerName : "POD Received",
    //     field : "pod_received",
    //     editable : false,
    //     resizable :true,
    //     width:130,
    //     valueGetter : function(params){
    //         if(params.data.pod_received == 1)
    //         {
    //             return "Yes";
    //         }
    //         else
    //         {
    //             return "No";
    //         }
    //     }
    // },
    // {
    //     headerName : "Consignee City",
    //     field : "consignee_city",
    //     editable : false,
    //     resizable :true,
    //     width:130
    // },
    // {
    //     headerName : "Consignee State",
    //     field : "consignee_state",
    //     editable : false,
    //     resizable :true,
    //     width:130
    // },
    
    // {
    //     headerName : "Route ID",
    //     field : "route_id",
    //     editable : false,
    //     resizable :true,
    //     width:130
    // },
    
    
    
    // {
    //     headerName : "Trip Transit Time (Days)",
    //     field : "trip_transit_time",
    //     editable : false,
    //     resizable :true,
    //     width:130,
    // },
    
]

export default class DealerCompensation extends Component {

    constructor(props) {
        super(props);
        this.state={
            modules:AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                enablePivot: true,
                pivot : true,
                enableValue: true,
                enableRowGroup: true,
            },
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
            rowData: [],
            rowDataExcluded:[],
            paginationPageSize:50,
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            locations:[],
            transporters:[],
            transporter:{value:"all",label:"All"},
            location:{value:"all",label:"All"},
            dept_code:'',
            departmentarray:[],
            originaldepartmentarray:[],
            scatterdepartmentarray:[],
            categories:[],
            scattercategories:[],
            transporterdata:[],
            transdrilldown:[],
            transportergpsdata:[],
            transgpsdrilldown:[],
            showtranschart:'show-m',
            showgpschart:'show-n',
            loadcheck:true,
            gpsloadcheck:false,
            loadshow:'show-n',
            overly:'show-n',
            monthlydata:[],
            monthlyexpdata:[],
            chartdrildown:[],
            showexclude:0,
            startDate:'',
            endDate:'',
            defaultsdate:'',
            defaultedate:'',
            yearlydata:'',
            statewisedata:[],
            transporterdata:[],
            selectedDataType:"", 
            onTimeGrid:"",
            columnHeaders:[],
            gridOverlay: "show-n",
            gridLoader : "show-n",
            selectedDataType : {label:"Complete Data",value:"Complete Data"},
            columnHeaders : completedatacolumnwithDefs,
            usergridstate:[],
            screenurl:"",
            screentitle:"",
            pagetitle:"OnTime Dashboard",
            eventGridAction: "gridAction",
            delayCounter_stt : "",
            noDelayCounter_stt : "",
            dealer_compensation_stt : 0,
            pod_processed_counter_stt : [],
            delayCounter_tt : "",
            noDelayCounter_tt : "",
            dealer_compensation_tt : 0,
            pod_processed_counter_tt : [],
            show: false,
            basicTitle:'',
            basicType:"default",
        }
        this.onLoadShowDashboard=this.onLoadShowDashboard.bind(this);
    }

    componentDidMount(){
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        this.setState({eventAction: eventAction});
        var preqparams = {
            dept_code:"SNDG"
        }
        redirectURL.post("/consignments/plants",preqparams)    
        .then((response) => {
          var records = response.data;
          //console.log("records ", records)
          var locations = [];
          if(records.length >0)
          {
              records.map((item) => {
                if(item.plant_name == "Gurgaon" || item.plant_name == "Gujarat" || item.plant_name == "Manesar" )
                {
                    locations.push({
                        code:item.plant_code,
                        label:item.plant_name,
                    })
                }
                
              })
          }
          this.setState({
              locations:locations
          })
        })
        .catch(function(e){
            console.log("Error ",e)
        })

        redirectURL.post("/masters/transportersbydept",{
            deptcode:"SNDG"
        })    
        .then((response) => {
          var records = response.data;
          var transporters = [];
          if(records.length >0)
          {
              records.map((item) => {
                transporters.push({
                    code:item.transporter_code,
                    label:item.transporter_name,
                })
              })
          }
          this.setState({
            transporters:transporters
          })
        })
        .catch(function(e){
            console.log("Error ",e)
        });
        var sdate = moment().startOf('month').format('YYYY-MM-DD');
        var edate   = moment().endOf('month').format('YYYY-MM-DD');
        var parameters = {
            startDate : sdate,
            endDate : edate,
            transporter : [{value:"all"}],
            location : [{value:"all"}]
        };
        this.setState({
            defaultsdate : sdate,
            defaultedate :  edate,
            startDate : sdate,
            endDate : edate,
            overly:"show-m",
            loadshow : "show-m"
        })
        redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			//console.log("resp ", resp)
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
		})
        this.onLoadShowDashboard(parameters);
        
    }
logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }

    onLoadShowDashboard(parameters)
    {
        
        var reqparams = {
            startDate : parameters.startDate,
            endDate :  parameters.endDate,
            transporter : parameters.transporter,
            location : parameters.location
        }
        console.log(reqparams);
        redirectURL.post("/dashboard/getDealerCompensationData",reqparams).then((response)=>{
            var data = response.data;
            console.log("OntimeGridData",data);
            let delayCounter_stt = data.filter(rec => {
				if (rec["dealer_comp_flag_stt"]) {
					return rec["dealer_comp_flag_stt"] == 1;
				}
            });
            let delayCounter_tt = data.filter(rec => {
				if (rec["dealer_comp_flag_tt"]) {
					return rec["dealer_comp_flag_tt"] == 1;
				}
            });
            let noDelayCounter_stt = data.filter(rec => {
					return rec["dealer_comp_flag_stt"] == 0;
            });
            let noDelayCounter_tt = data.filter(rec => {
                return rec["dealer_comp_flag_tt"] == 0;
            });
            let dealer_compensation_stt = 0.00;
            let dealer_compensation_tt = 0.00;
            data.map(function(e){
                if(e.dealer_compensation_stt != "" && e.dealer_compensation_stt != undefined)
                {
                    dealer_compensation_stt = dealer_compensation_stt + e.dealer_compensation_stt;   
                }
                if(e.dealer_compensation_tt != "" && e.dealer_compensation_tt != undefined)
                {
                    dealer_compensation_tt = dealer_compensation_tt + e.dealer_compensation_tt;   
                }
            });
            let pod_processed_counter_stt = data.filter(rec =>{
                return rec['dealer_comp_flag_stt'] != undefined;
            })
            let pod_processed_counter_tt = data.filter(rec =>{
                return rec['dealer_comp_flag_tt'] != undefined;
            })
            
            this.setState({
                rowData : data,
                delayCounter_stt : delayCounter_stt,
                delayCounter_tt : delayCounter_tt,
                noDelayCounter_stt : noDelayCounter_stt,
                noDelayCounter_tt : noDelayCounter_tt,
                gridLoader : "show-n",
                gridOverlay : "show-n",
                overly : "show-n",
                loadshow : "show-n",
                dealer_compensation_tt : dealer_compensation_tt,
                dealer_compensation_stt : dealer_compensation_stt,
                pod_processed_counter_stt : pod_processed_counter_stt,
                pod_processed_counter_tt : pod_processed_counter_tt
            });

        }).catch(function(e){
            console.log("Error ", e)
        })
    }
    onSelectLocation= location =>{
		this.setState(
			{ location },
			() => console.log(`Location Option selected:`, this.state.location)
		  );
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    onSelectTransporter= transporter =>{
		this.setState(
			{ transporter },
			() => console.log(`Transporter Option selected:`, this.state.transporter)
		  );
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    locationoptions(){
        var items=[];
       // console.log("locations ",this.props.locations)
        var locations = this.state.locations;
        //console.log("locations ",locations)
        if(locations.length > 0){
            items.push({value:'all',label:'All'}); 
            locations.forEach(item =>{

                items.push({value:item.code,label:item.label}); 
            });
        }
        //console.log("Locas ", items)
        return items;
    }
    transporteroptions(){
        var items=[]
        var transporters = this.state.transporters;
        items.push({value:'all',label:'All'}); 
        if(transporters.length > 0){
            transporters.forEach(item=>{
                items.push({value:item.code,label:item.label}); 
            })
        }
        //console.log("Transps ", items)
        return items;
    }
    onClickSaveGridState(){
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		console.log("Columns ", window.colState)
		console.log("Group ", window.groupState)
		console.log("Sort ", window.sortState)
		console.log("Filter ", window.filterState)
		let screenpage = '';

		if(this.props.match.path == "/sndconsignments")
		{
			screenpage='snd active consignments';
		}
		else if(this.props.match.path == "/prtconsignments")
		{
			screenpage='prt active consignments';
		}
		else if(this.props.match.path == "/tnpconsignments")
		{
			screenpage='tnp active consignments';
		}
		else if(this.props.match.path == "/sndallconsignments")
		{
			screenpage='snd all consignments';
			
		}
		else if(this.props.match.path == "/prtallconsignments")
		{
			screenpage='prt all consignments';
		}
		else if(this.props.match.path == "/tnpallconsignments")
		{
			screenpage='tnp all consignments';
		}
		else if(this.props.match.path == "/snddeliveredconsignments")
		{
			screenpage='snd delivered consignments';
		}
		else if(this.props.match.path == "/prtdeliveredconsignments")
		{
			screenpage='prt delivered consignments';
		}
		else if(this.props.match.path == "/tnpdeliveredconsignments")
		{
			screenpage='tnp delivered consignments';
		}
		else if(this.props.match.path == "/railconsignments")
		{
			screenpage='railconsignments';
		}
		else if(this.props.match.path == "/ncrconsignments")
		{
			screenpage='ncr consignments';
		}
		else if(this.props.match.path == "/moveconsignments")
		{
			screenpage='move consignments';
		}

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
                basicType:"success",
                show : true,
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{

		if(this.state.usergridstate.length > 0)
		{
			var windowstates = this.state.usergridstate;
			this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
			this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
			this.gridApi.setSortModel(windowstates[0].gridcolsort);
			this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
		}
    }
    resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};
    handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
        var mm = (d.getMonth() +1)
        if(mm <10)
        {
            var month = "0"+mm
        }
        else{
            var month = mm
        }
         
    	var startdate = d.getFullYear()+"-"+month+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
        var mm = (d.getMonth() +1)
        if(mm <10)
        {
            var month = "0"+mm
        }
        else{
            var month = mm
        }
         
    	var edate = d.getFullYear()+"-"+month+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
        this.setState({
    		endDate:edate
    	});
    }
    onClickCounter(params)
    {
		//console.log("IN onClickCounterShowData, params = ",params);
		if (googleAnalytics.page.enableGA) {
			let eventLabel = null;
			if (params) {
				eventLabel = googleAnalytics.page.kpi[params];
				//console.log("eventLabel = ", eventLabel);
			}
			
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventCounterAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		
		if(params == "all")
		{
			this.gridApi.setRowData(this.state.rowData);
			this.gridApi.onFilterChanged();
        }
        if(params == "delay_stt")
		{
			this.gridApi.setRowData(this.state.delayCounter_stt);
			this.gridApi.onFilterChanged();
        }
        if(params == "no_delay_stt")
		{
			this.gridApi.setRowData(this.state.noDelayCounter_stt);
			this.gridApi.onFilterChanged();
        }
        if(params == "pod_processed_stt")
        {
            this.gridApi.setRowData(this.state.pod_processed_counter_stt);
            this.gridApi.onFilterChanged();
        }
        if(params == "delay_tt")
		{
			this.gridApi.setRowData(this.state.delayCounter_tt);
			this.gridApi.onFilterChanged();
        }
        if(params == "no_delay_tt")
		{
			this.gridApi.setRowData(this.state.noDelayCounter_tt);
			this.gridApi.onFilterChanged();
        }
        if(params == "pod_processed_tt")
        {
            this.gridApi.setRowData(this.state.pod_processed_counter_tt);
            this.gridApi.onFilterChanged();
        }
    }
    onClickGetLoadingData(event){
        event.preventDefault();

        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": "Filters",
            }
            googleAnalytics.logEvent(eventOptions);
        }
        this.setState({
            loadshow:'show-m',
            overly:"show-m",
            departmentarray:[],
            rowData:[],
            selectedDataType : {}
        })
        if(this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN")
        {
            var startDate = this.state.defaultsdate;
        }
        else{
            var startDate = this.state.startDate;
        }
        if(this.state.endDate == '' || this.state.endDate == "NaN-NaN-NaN")
        {
            var endDate = this.state.defaultedate;
        }
        else{
            var endDate = this.state.endDate;
        }   
        if(this.state.location == '' || this.state.location == null)
        {
            var location = [{"value": "all", "label": "All"}];
        }
        else
        {
            var location = this.state.location;
        } 
        if(this.state.transporter == '' || this.state.transporter == null)
        {
            var transporter = [{"value": "all", "label": "All"}];
        }
        else
        {
            var transporter = this.state.transporter;
        }
        
        if(Array.isArray(location) == true)
        {
            var locations = location
        }
        else{
            var locations = [location]
        }
        if(Array.isArray(transporter) == true)
        {
            var transporters = transporter
        }
        else{
            var transporters = [transporter]
        }
        var parms = {
            startDate:startDate,
            endDate:endDate,
            location:locations,
            transporter:transporters,
            dept_code:this.state.dept_code
        }
        this.onLoadShowDashboard(parms)
    }

    
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   
		
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
    }

    
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
           
		});
		
    }
    
    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    render(){
        var hideinvoice = (this.state.dept_code == 'LOG-PRT')?true:false;
        var columnwithDefs= [
            {
                headerName:"Truck No",
                field:"truck_no",
                width:150
            },
            {
                headerName:"Consignment No",
                field:"consignment_code",
                width:150
            },
            {
                headerName:"Gate Out Time",
                field:"gate_out_time",
                width:150,
                valueGetter:function(params){
                    //console.log(params.data.gate_out_time)
                    if(typeof params.data.gate_out_time != 'undefined')
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                    }
                    else{
                        return '';
                    }
                    
                },
                //filter: "agDateColumnFilter",
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// }, 
            },
            // {
            //     headerName:"Invoice Time",
            //     field:"invoice_time",
            //     width:150,
            //     valueGetter:function(params){
            //         return getDDMMYYYYHHMMSS(params.data.invoice_time)
            //     }
            // },
            
            {
                headerName:"Transporter Name",
                field:"transporter_name",
                width:180
            },
            {
                headerName:"Plant Name",
                field:"plant_location",
                width:150
            },
            {
                headerName:"Cluster",
                field:"cluster",
                width:120
            },
            {

                headerName:"Route",
                field:"route_id",
                width:120
            },
            {
                headerName:"Consignee Code",
                field:"consignee_code",
                width:150
            },
            {
                headerName:"Trip Distance",
                field:"trip_distance",
                width:150,
                valueGetter:function(params){
                    return parseInt(params.data.trip_distance)
                }
            },
            {
                headerName:"Transit Time (Days)",
                field:"transit_time_days",
                width:150,
                valueGetter:function(params){
                    return parseInt(params.data.transit_time_days)
                }
            },
            {
                headerName:"Trip Closure Time",
                field:"trip_closure_time",
                width:150,
                valueGetter:function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.trip_closure_time)
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
                // filterParams: {
                //     browserDatePicker: true,
                //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                //     // provide comparator function
                //     comparator: function(filteredDate,cellValue,secondDate) {
                //         cellValue = cellValue.replace(/\//g,"-")
                //         if(cellValue != "" && cellValue != " "){
                //             cellValue = cellValue.split(" ")[0].split("-");
                //             cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                //             var cellDate = new Date(cellValue);
                //             if(filteredDate.getMonth() < 10){
                //             var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             else{
                //                 var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             if(filterValue.split("-")[2].length < 2)
                //             {
                //                 var temp = filterValue
                //                 filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
                //             }
                //             var filteredDate = new Date(filterValue);
                //             cellDate = cellDate.getTime();
                //             filteredDate = filteredDate.getTime();
                //             // console.log(cellDate,filteredDate);
                //             if(cellDate === filteredDate)
                //             {
                //                 return 0;
                //             }
                //             if(cellDate < filteredDate){
                //                 return -1;
                //             }
                //             if(cellDate > filteredDate)
                //             {
                //                 return 1;
                //             }
                //         }
                //     }
                // },
            },
            {
                headerName:" Trip Duration ( + 0.25 days)",
                field:"trip_completion_days",
                width:150
            },
            {
                headerName:" Trip Distance Range",
                field:"standard_distance_bin",
                width:150
            },
            {
                headerName:"Standard Avg. Distance/Day",
                field:"expected_average_days",
                width:150
            },
            {
                headerName:"Actual Avg Distance/Day",
                field:"actual_average_days",
                width:150
            }
        ]

        var columnwithDefsExclude= [
            {
                headerName:"Truck No",
                field:"truck_no",
                width:150
            },
            {
                headerName:"Consignment No",
                field:"consignment_code",
                width:150
            },
            {
                headerName:"Gate Out Time",
                field:"gate_out_time",
                width:150,
                valueGetter:function(params){
                    console.log(params.data.gate_out_time)
                    if(typeof params.data.gate_out_time != 'undefined')
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                    }
                    else{
                        return '';
                    }
                    
                },
                //filter: "agDateColumnFilter",
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// }, 
            },
            // {
            //     headerName:"Invoice Time",
            //     field:"invoice_time",
            //     width:150,
            //     valueGetter:function(params){
            //         return getDDMMYYYYHHMMSS(params.data.invoice_time)
            //     }
            // },
            
            {
                headerName:"Transporter Name",
                field:"transporter_name",
                width:180
            },
            {
                headerName:"Plant Name",
                field:"plant_location",
                width:150
            },
            {
                headerName:"Cluster",
                field:"cluster",
                width:120
            },
            {
                headerName:"Route",
                field:"route_id",
                width:120
            },
            {
                headerName:"Consignee Code",
                field:"consignee_code",
                width:150
            },
            {
                headerName:"Trip Distance",
                field:"trip_distance",
                width:150,
                valueGetter:function(params){
                    return parseInt(params.data.trip_distance)
                }
            },
            {
                headerName:"Transit Time (Days)",
                field:"transit_time_days",
                width:150,
                valueGetter:function(params){
                    return parseInt(params.data.transit_time_days)
                }
            },
            {
                headerName:"Trip Closure Time",
                field:"trip_closure_time",
                width:150,
                valueGetter:function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.trip_closure_time)
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
                // filterParams: {
                //     browserDatePicker: true,
                //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                //     // provide comparator function
                //     comparator: function(filteredDate,cellValue,secondDate) {
                //         cellValue = cellValue.replace(/\//g,"-")
                //         if(cellValue != "" && cellValue != " "){
                //             cellValue = cellValue.split(" ")[0].split("-");
                //             cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                //             var cellDate = new Date(cellValue);
                //             if(filteredDate.getMonth() < 10){
                //             var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             else{
                //                 var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             if(filterValue.split("-")[2].length < 2)
                //             {
                //                 var temp = filterValue
                //                 filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
                //             }
                //             var filteredDate = new Date(filterValue);
                //             cellDate = cellDate.getTime();
                //             filteredDate = filteredDate.getTime();
                //             // console.log(cellDate,filteredDate);
                //             if(cellDate === filteredDate)
                //             {
                //                 return 0;
                //             }
                //             if(cellDate < filteredDate){
                //                 return -1;
                //             }
                //             if(cellDate > filteredDate)
                //             {
                //                 return 1;
                //             }
                //         }
                //     }
                // },
            },
            {
                headerName:" Trip Duration ( + 0.25 days)",
                field:"trip_completion_days",
                width:150
            },
            {
                headerName:" Trip Distance Range (KM)",
                field:"standard_distance_bin",
                width:150
            },
            {
                headerName:"Standard Avg. Distance/Day (KM)",
                field:"expected_average_days",
                width:150
            },
            {
                headerName:"Actual Avg Distance/Day (KM)",
                field:"actual_average_days",
                width:150
            }
        ]
        const gridoverlaystyle = {
            position: "absolute",
            background: "white",
            top: "12.2em",
            left: "1.1em",
            opacity: "0.2",
            width: "88%",
            height: "37em",
        }
        const gridloaderstyle = {
            position: "absolute",
            top: "27em",
            left: "41em"
        }
        return ( 
        		<div className="row pt-20px">
                    <SweetAlert
                        show={this.state.show}
                        type={this.state.basicType}
                        title={this.state.basicTitle}
                        onConfirm={this.closeAlert}
                    >
                    </SweetAlert>
                    <div className="row col-xl-12 col-lg-12">                        
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>Start Date: </label>
                            <Datetime value={this.state.startDate} 
                            disableCloseOnClickOutside={false} 
                            closeOnSelect={true} 
                            inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete:'off' }}
                            dateFormat="YYYY-MM-DD" 
                            name="startDate" 
                            onChange={this.handlerStartDateTime.bind(this)} />
                        </div>
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>End Date: </label>
                            <Datetime value={this.state.endDate} 
                            disableCloseOnClickOutside={false} 
                            closeOnSelect={true} 
                            inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete:'off' }}
                            dateFormat="YYYY-MM-DD" 
                            name="endDate" 
                            onChange={this.handlerEndDateTime.bind(this)} />   
                        </div>
                        <div className="form-gplantwiseloadingroup col-xl-3 col-lg-3">
                            <label>Location: </label>
                                <Select 
                                placeholder={"All"} 
                                isMulti={true} 
                                value={this.state.location} 
                                className="border-radius-0" 
                                onChange={this.onSelectLocation.bind(this)} 
                                style={{borderRadius:"0px"}} 
                                options={this.locationoptions()} required />
                        </div>
                        <div className="form-group col-xl-3 col-lg-3">
                            <label>Transporter: </label>
                                <Select placeholder={"All"} isMulti={true} value={this.state.transporter} className="border-radius-0" onChange={this.onSelectTransporter.bind(this)} style={{borderRadius:"0px"}} options={this.transporteroptions()} required />
                        </div>
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>&nbsp;</label><br />
                            <button type="button" className="btn btn-success" onClick={this.onClickGetLoadingData.bind(this)}>Submit</button>
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                        <div className="crm-numbers pb-0">
                            <div className="row">
                                {/* <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"all")}>
                                    <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Total Invoiced</span>
                                    <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.rowData != '')?this.state.rowData.length:0}/></span></h4>
                                </div> */}
                                <div className="col col-xl-2 cirlce-d cpointer p-5px" onClick={this.onClickCounter.bind(this,"all")}>
                                
                                    <div className="card n-p-0">
                                        <div className="card-header">
                                            <span className="f12">Total Invoiced</span>
                                        </div>
                                        <div className="row card-body pt-35px pb-35px">
                                            <div className="col col-xl-12 col-lg-12" onClick={this.onClickCounter.bind(this,"all")}> 
                                                <h4 className="txt-info f26"><span className="counter"><CountUp end={(this.state.rowData != '')?this.state.rowData.length:0}/></span></h4>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                <div className="col col-xl-2 cirlce-d cpointer p-5px" onClick={this.onClickCounter.bind(this,"pod_processed_stt")}>
                                    <div className="card n-p-0">
                                        <div className="card-header">
                                            <span className="f12">POD Processed</span>
                                        </div>
                                        <div className="row card-body pt-35px pb-35px">
                                            <div className="col col-xl-12 col-lg-12" onClick={this.onClickCounter.bind(this,"pod_processed_stt")}> 
                                                <h4 className="txt-info f26"><span className="counter"><CountUp end={(this.state.pod_processed_counter_stt != '')?this.state.pod_processed_counter_stt.length:0}/></span></h4>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                
                                {/* <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"pod_processed")}>
                                    <span className="f13"><i className="icofont icofont-train-line f24 txt-success"></i><br /> POD Processed</span>
                                    <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.pod_processed_counter != '')?this.state.pod_processed_counter.length:0}/></span></h4>
                                </div>
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"delay")}>
                                    <span className="f13"><i className="icofont icofont-train-line f24 txt-danger"></i><br />Delay</span>
                                    <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.delayCounter != '')?this.state.delayCounter.length:0}/></span></h4>                                   
                                </div>
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"no_delay")}>
                                    <span className="f13"><i className="icofont icofont-train-line f24 txt-success"></i><br /> No Delay</span>
                                    <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.noDelayCounter != '')?this.state.noDelayCounter.length:0}/></span></h4>                                   
                                </div>
                                <div className="col cursorPointer">
                                    <span className="f13"><i className="fa fa-inr f22 txt-info"></i><br /> Dealer Compensation (INR)</span>
                                    <h4 className="txt-info f40"><span className="counter">
                                    <CountUp
                                    end={(this.state.dealer_compensation != '')?this.state.dealer_compensation:0}
                                    /></span></h4>                                   
                                </div> */}
                                <div className={"col cpointer p-5px col-xl-4 col-lg-4"}>
                                    <div className="card n-p-0">
                                        <div className="card-header">
                                            <span className="f12">Standard Transit Time</span>
                                        </div>
                                        <div className="row card-body p-10px">    
                                            <div className="col col-xl-4 col-lg-3" onClick={this.onClickCounter.bind(this,"delay_stt")}>  
                                                <span className="f12">
                                                    Delay </span>
                                                <h4 className="txt-danger f26"><span className="counter">
                                                    <CountUp end= {(this.state.delayCounter_stt)?this.state.delayCounter_stt.length:0}/>
                                                    </span></h4>
                                            </div>
                                            <div className="col col-xl-4 col-lg-3" onClick={this.onClickCounter.bind(this,"no_delay_stt")}>  
                                                <span className="f12">
                                                    No Delay </span>
                                                <h4 className="txt-success f26"><span className="counter">
                                                    <CountUp end= {(this.state.noDelayCounter_stt)?this.state.noDelayCounter_stt.length:0}/>
                                                    </span></h4>
                                            </div>
                                            <div className="col col-xl-4 col-lg-3">  
                                                <span className="f12">
                                                <i class="fa fa-rupee txt-info"></i> Dealer Compensation  </span>
                                                <h4 className="txt-danger f26"><span className="counter">
                                                    <CountUp end= {(this.state.dealer_compensation_stt)?this.state.dealer_compensation_stt:0}/>
                                                    </span></h4>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className={"col cpointer p-5px col-xl-4 col-lg-4"}>
                                    <div className="card n-p-0">
                                        <div className="card-header">
                                            <span className="f12">Trip Transit Time</span>
                                        </div>
                                        <div className="row card-body p-10px">    
                                            
                                            {/* <div className="col col-xl-3 col-lg-3 cpointer" onClick={this.onClickCounter.bind(this,"pod_processed_tt")}>
                                                <span className="f12">
                                                    POD Processed </span>
                                                <h4 className="txt-secondary f26"><span className="counter">
                                                    <CountUp end= {(this.state.pod_processed_counter_tt != "")?this.state.pod_processed_counter_tt.length:0}/>
                                                    </span></h4>
                                            </div> */}
                                            <div className="col col-xl-4 col-lg-4" onClick={this.onClickCounter.bind(this,"delay_tt")}>  
                                                <span className="f12">
                                                    Delay </span>
                                                <h4 className="txt-danger f26"><span className="counter">
                                                    <CountUp end= {(this.state.delayCounter_tt)?this.state.delayCounter_tt.length:0}/>
                                                    </span></h4>
                                            </div>
                                            <div className="col col-xl-4 col-lg-4" onClick={this.onClickCounter.bind(this,"no_delay_tt")}>  
                                                <span className="f12">
                                                    No Delay </span>
                                                <h4 className="txt-success f26"><span className="counter">
                                                    <CountUp end= {(this.state.noDelayCounter_tt)?this.state.noDelayCounter_tt.length:0}/>
                                                    </span></h4>
                                            </div>
                                            <div className="col col-xl-4 col-lg-4">  
                                                <span className="f12">
                                                <i class="fa fa-rupee txt-info"></i>   Dealer Compensation  </span>
                                                <h4 className="txt-danger f26"><span className="counter">
                                                    <CountUp end= {(this.state.dealer_compensation_tt)?this.state.dealer_compensation_tt:0}/>
                                                    </span></h4>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                               
                    <div className="col-xl-12">
                        <div className="row" style={{marginBottom:"-23px"}}>		
                            <div className="col-xl-12">
                                <span className="layoutbtns">
                                    <button className="float-right custom-btn white btn-danger" style={{padding:"7px"}} onClick={this.onClickSaveGridState.bind(this)}>
                                        <i className="icofont icofont-save"></i> Save Grid Layout
                                    </button>
                                    <button className="float-right custom-btn white btn-info" style={{padding:"7px"}} onClick={this.resetState.bind(this)}>
                                        <i className="icofont icofont-refresh"></i> Reset Default Layout
                                    </button>
                                </span>
                            </div>
                        </div>
                        <div id="myGrid" style={{width:"100%",height:"460px",marginTop:"23px",marginBottom:"45px"}} className="ag-theme-balham">    
                        <AgGridReact
                            modules={this.state.modules}
                            columnDefs={this.state.columnHeaders}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.rowData}
                            // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                            enableCharts={true}
                            enableRangeSelection={true}
                            onGridReady={this.onGridReady}
                            onGridState={this.onGridState}
                            statusBar={this.state.statusBar}
                            sideBar={this.state.sideBar}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                            suppressRowClickSelection={true}
                            />

                        </div>
                    </div>
                    
                   
                    <div className={"grid-loader "+this.state.gridOverlay} style={gridoverlaystyle}></div>
                    <div className={"loader-box "+this.state.gridLoader} style={gridloaderstyle}><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                    
                    <div className={"dataLoadpage " +(this.state.loadshow)}></div>
                    <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                        <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                    </div>
                    <div className={"overlay "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                </div>

			);
		}
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}


function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


$(document).ready(function(){
    
    $(".loadclick").click(function(){
        var id = $(this).attr("id");
        $(".loadclick").removeClass("active");
        $("#"+id).addClass("active");
    })
})



function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}

function arrayMin(arr) {
	return arr.reduce(function (p, v) {
	  return ( p < v ? p : v );
	});
  }
  
function arrayMax(arr) {
return arr.reduce(function (p, v) {
    return ( p > v ? p : v );
});
}



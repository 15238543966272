import React, { Component } from "react";
import moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from "@ag-grid-community/react";
import Select from "react-select";
import $ from "jquery";
import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import * as Datetime from 'react-datetime';
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
var redirectURL = require("../redirectURL");

export default class EmissionTruckMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      eventFormAction: "formAction",
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        width: 160,
        resizable: true,
      },
      eventAction: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      bulkslide: "",
      bulkslide1: "",
      rowModelhighchartoptionsType: "enterprise",
      paginationPageSize: 50,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      overly: "show-n",
      loadshow: "show-n",
      file: "",
      rowData: [],
      rowId: "",
      show: false,
      basicTitle: "",
      basicType: "",
      transporter_code:'',
      // transporter_name:'',
      truck_no:'',
      vehicle_type:'',
      fuel_type:'',
    };
  }

  async componentDidMount() {
    this.setState({
        loadshow: "show-m",
        overly: "show-m"
      });
    await redirectURL
      .post("/consignments/emissiontruckmasterdataonmount")
      .then((response) => {
        var totalData = response.data
        // const filteredData = totalData.filter(row => !row.deleted);
        this.setState({
          rowData: totalData,
          loadshow: "show-n",
          overly: "show-n",
        })
      })
  }
  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      bulkslide: '',
      bulkslide1: "",
      transporter_code:'',
      // transporter_name:'',
      truck_no:'',
      vehicle_type:'',
      fuel_type:'',
    });
    $("#uploadFile").val("")
    var form = document.getElementById("initialEditDetails");
    if (form) {
      form.reset();
    }
  }
  async onCellClicked(event) {
    if (event.colDef.field === "Edit") {
      var data = event.data;
      console.log(data,'data',data.transporter_code)
      this.setState({
        rowId: data._id,
        transporter_code:data.transporter_code,
        // transporter_name:data.transporter_name,
        truck_no:data.truck_no,
        vehicle_type:data.vehicle_type,
        fuel_type:data.fuel_type,
        bulkslide1: "slider-translate-30p",
      });
      console.log(this.state.transporter_code,'family_codefamily_code')
    }
  }

  addnewmodelmasterdata() {
    var form = document.getElementById("initialAddingDetails");
    if (form) {
      form.reset();
    }
    this.setState({
      bulkslide: "slider-translate-30p",
      overly: "show-m",
    })
  }

  async onClickAddDetails(e) {
    e.preventDefault();
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    })
    var transporter_code = this.state.transporter_code;
    // var transporter_name = this.state.transporter_name;
    var truck_no = this.state.truck_no;
    var vehicle_type = this.state.vehicle_type;
    var fuel_type = this.state.fuel_type;
    var vehicle_mode = "";
    switch (vehicle_type) {
      case "TK":
      case "TK4":
        vehicle_mode = "TRUCK";
        break;
      case "TR":
      case "TR8":
      case "TR9":
      case "TRE":
        vehicle_mode = "TRAILER";
        break;
      default:
        vehicle_mode = "";
    }
    var formData = new FormData();
    formData.append('transporter_code', transporter_code)
    // formData.append('transporter_name', transporter_name)
    formData.append('truck_no', truck_no)
    formData.append('vehicle_type', vehicle_type)
    formData.append('fuel_type', fuel_type)
    formData.append('vehicle_mode', vehicle_mode);
    formData.append('updated_by', localStorage.getItem("username"))
    if (transporter_code != "" && transporter_code != undefined && truck_no != "" && truck_no != undefined && vehicle_type != "" && vehicle_type != undefined && fuel_type != "" && fuel_type != undefined) {

      await redirectURL
        .post("/consignments/addemissiontruckmasterdata", formData)
        .then((response) => {
          this.setState({
            show: true,
            loadshow: "show-n",
            overly: "show-n",
            basicType: "success",
            basicTitle: "New details added Successfully",
            bulkslide: '',
            transporter_code:'',
            // transporter_name:'',
            truck_no:'',
            vehicle_type:'',
            fuel_type:'',
          })

        })
      this.componentDidMount()
    } else {
      this.setState({
        show: true,
        basicType: "warning",
        loadshow: "show-n",
        overly: "show-n",
        basicTitle: "All (*) fields are mandatory",
      })
    }
  }

  async onClickEditDetails(e) {
    e.preventDefault();
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    })
    var transporter_code = this.state.transporter_code;
    // var transporter_name = this.state.transporter_name;
    var truck_no = this.state.truck_no;
    var vehicle_type = this.state.vehicle_type;
    var fuel_type = this.state.fuel_type;
    var vehicle_mode = "";
    switch (vehicle_type) {
      case "TK":
      case "TK4":
        vehicle_mode = "TRUCK";
        break;
      case "TR":
      case "TR8":
      case "TR9":
      case "TRE":
        vehicle_mode = "TRAILER";
        break;
      default:
        vehicle_mode = "";
    }
    // console.log("Vehicle Mode: ", vehicle_mode);
    var formData = new FormData();
    formData.append("rowId", this.state.rowId)
    formData.append('transporter_code', transporter_code)
    // formData.append('transporter_name', transporter_name)
    formData.append('truck_no', truck_no)
    formData.append('vehicle_type', vehicle_type)
    formData.append('vehicle_mode', vehicle_mode);
    formData.append('fuel_type', fuel_type)
    formData.append('updated_by', localStorage.getItem("username"))

    if (transporter_code != "" && transporter_code != undefined && truck_no != "" && truck_no != undefined && vehicle_type != "" && vehicle_type != undefined && fuel_type != "" && fuel_type != undefined) {
   
          await redirectURL
          .post("/consignments/Editemissiontruckmasterdata", formData)
          .then((response) => {
            this.setState({
              show: true,
              loadshow: "show-n",
              overly: "show-n",
              basicType: "success",
              basicTitle: "Successfully Updated",
              bulkslide1: '',
              transporter_code:'',
              // transporter_name:'',
              truck_no:'',
              vehicle_type:'',
              fuel_type:'',
            })
  
          })
        $("#uploadFile").val("")
        var form = document.getElementById("initialEditDetails");
        if (form) {
          form.reset();
        }
        this.componentDidMount()
      }else{
        this.setState({
          show: true,
          basicType: "warning",
          loadshow: "show-n",
          overly: "show-n",
          basicTitle: "All (*) fields are mandatory",
        })
      }
  }

  closeAlert = () => {
    this.setState({
      show: false,
    });
  };

  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    // console.log(name, '---1', value)
    if (name === "vehicle_type") {
      value = value.toUpperCase();
    }
    this.setState({ [name]: value });
  };
  
  render() {

    const batchwisecolumns = [
      {
        headerName: "Edit",
        field: "Edit",
        width: 110,
        resizable: true,
        editable: false,
        cellRenderer: function (params) {
        //   if (!params.data.not_planned_volumes) {
            var htmloption = '<button class="btn btn-warning" style="padding-top:0px">Edit</button>';
            return htmloption;
        //   } else {
        //     var htmloption = '<button class="btn btn-warning" disabled="on" style="padding-top:0px">Edit</button>';
        //     return htmloption;
        //   }
        },
        filter: false,
      },
    
      {
        headerName: 'TPT Code',
        field: "transporter_code",
      },
      // {
      //   headerName: 'TPT Name',
      //   field: "transporter_name",
      //   width:190,
      // },
      {
        headerName: 'Carrier No.',
        field: "truck_no",
      },
     
      {
        headerName: 'Carrier Type',
        field: "vehicle_type",
      },
      {
        headerName: 'Fuel type',
        field: "fuel_type",
      },
      {
        headerName: 'Vehicle Mode',
        field: "vehicle_mode",
      },
    ];


    return (
      <div class="container-fluid">
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
         <div>
             <h5>
              <i className="icofont icofont-vehicle-delivery-van cus-i"></i>{" "}
              <span>Truck Master</span>
              </h5>
      </div>
        <div className="card col-xl-12">
          <div className="card-header" style={{display:'flex',flexDirection:'column'}}>
        <div>
              <button className="btn btn-info" style={{ float: "right", marginRight: "10px" }} onClick={this.addnewmodelmasterdata.bind(this)}>Add New Truck Master Details</button>
        </div>
              <div className="card-body">
                <div className="row">
                  <div
                    id="myGrid"
                    style={{ width: "100%", height: "478px" }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      modules={this.state.modules}
                      columnDefs={batchwisecolumns}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.rowData}
                      enableCharts={false}
                      onGridReady={this.onGridReady2}
                      onGridState={this.onGridState}
                      frameworkComponents={this.state.frameworkComponents}
                      onCellClicked={this.onCellClicked.bind(this)}
                      sideBar={this.state.sideBar}
                      stopEditingWhenGridLosesFocus={true}
                      paginationPageSize={this.state.paginationPageSize}
                      pagination={true}
                      gridOptions={{
                        context: { componentParent: this },
                      }}
                      enableRangeSelection={true}
                    />
                  </div>
                </div>
              </div>
           
          </div>

          <div className={"slide-r " + (this.state.bulkslide)} style={{ overflow: "scroll" }}>
            <h3 className="subH">New Truck Master Details
              <span className="float-right fclose" style={{ marginRight: "12px", padding: "1px 8px", marginTop: "-2px" }} onClick={this.onClickHideAll.bind(this)}>x</span>
            </h3>
            <div className="slide-r-body" style={{ position: "relative" }}>
              <div className="container-fluid">
                <form id="initialAddingDetails"
                  onSubmit={this.onClickAddDetails.bind(this)}
                  className="row col-xl-12 col-lg-12">

                   <div className="col-xl-12 col-lg-12 form-group">
                    <label>TPT Code<span style={{ color: 'red' }}> *</span> </label>
                    <input
                      type="text"
                      value={this.state.transporter_code}
                      name="transporter_code"
                      id="transporter_code"
                      className="form-control"
                    //   onKeyPress={this.handleKeyPressCodeTrans}
                      autoComplete="off"
                      onChange={this.handleChange}
                      required
                    />
                  </div>

                  {/* <div className="col-xl-12 col-lg-12 form-group">
                  <label>TPT Name<span style={{ color: 'red' }}> *</span> </label>
                    <input
                      type="text"
                      value={this.state.transporter_name}
                      name="transporter_name"
                      id="transporter_name"
                      className="form-control"
                    //   onKeyPress={this.handleKeyPressCodeRakeId}
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </div> */}
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Carrier No.<span style={{ color: 'red' }}> *</span> </label>
                    <input
                      type="text"
                      value={this.state.truck_no}
                      name="truck_no"
                      id="truck_no"
                      className="form-control"
                    //   onKeyPress={this.handleKeyPressCodeTrans}
                      autoComplete="off"
                      onChange={this.handleChange}
                      required
                    />
                  </div>

                  <div className="col-xl-12 col-lg-12 form-group">
                  <label>Carrier Type<span style={{ color: 'red' }}> *</span> </label>
                    <input
                      type="text"
                      value={this.state.vehicle_type}
                      name="vehicle_type"
                      id="vehicle_type"
                      className="form-control"
                    //   onKeyPress={this.handleKeyPressCodeRakeId}
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-xl-12 col-lg-12 form-group">
                  <label>Fuel type<span style={{ color: 'red' }}> *</span> </label>
                    <input
                      type="text"
                      value={this.state.fuel_type}
                      name="fuel_type"
                      id="fuel_type"
                      className="form-control"
                    //   onKeyPress={this.handleKeyPressCodeRakeId}
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </div>
                 

                  <div className="form-group col-xl-3 col-lg-3">
                    <button type="submit" className="btn btn-success">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className={"slide-r " + (this.state.bulkslide1)} style={{ overflow: "scroll" }}>
            <h3 className="subH">Edit Truck Master Details
              <span className="float-right fclose" style={{ marginRight: "12px", padding: "1px 8px", marginTop: "-2px" }} onClick={this.onClickHideAll.bind(this)}>x</span>
            </h3>
            <div className="slide-r-body" style={{ position: "relative" }}>
              <div className="container-fluid">
                <form id="initialEditDetails"
                  onSubmit={this.onClickEditDetails.bind(this)}
                  className="row col-xl-12 col-lg-12">

                 
            <div className="col-xl-12 col-lg-12 form-group">
                                <label>TPT Code<span style={{ color: 'red' }}> *</span> </label>
                                <input
                                  type="text"
                                  value={this.state.transporter_code}
                                  name="transporter_code"
                                  id="transporter_code"
                                  className="form-control"
                                //   onKeyPress={this.handleKeyPressCodeTrans}
                                  autoComplete="off"
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>

                              {/* <div className="col-xl-12 col-lg-12 form-group">
                              <label>TPT Name<span style={{ color: 'red' }}> *</span> </label>
                                <input
                                  type="text"
                                  value={this.state.transporter_name}
                                  name="transporter_name"
                                  id="transporter_name"
                                  className="form-control"
                                //   onKeyPress={this.handleKeyPressCodeRakeId}
                                  autoComplete="off"
                                  onChange={this.handleChange}
                                />
                              </div> */}
                              <div className="col-xl-12 col-lg-12 form-group">
                                <label>Carrier No.<span style={{ color: 'red' }}> *</span> </label>
                                <input
                                  type="text"
                                  value={this.state.truck_no}
                                  name="truck_no"
                                  id="truck_no"
                                  className="form-control"
                                //   onKeyPress={this.handleKeyPressCodeTrans}
                                  autoComplete="off"
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>

                              <div className="col-xl-12 col-lg-12 form-group">
                              <label>Carrier Type<span style={{ color: 'red' }}> *</span> </label>
                                <input
                                  type="text"
                                  value={this.state.vehicle_type}
                                  name="vehicle_type"
                                  id="vehicle_type"
                                  className="form-control"
                                //   onKeyPress={this.handleKeyPressCodeRakeId}
                                  autoComplete="off"
                                  onChange={this.handleChange}
                                />
                              </div>
                              <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Fuel type<span style={{ color: 'red' }}> *</span> </label>
                                        <input
                                        type="text"
                                        value={this.state.fuel_type}
                                        name="fuel_type"
                                        id="fuel_type"
                                        className="form-control"
                                        //   onKeyPress={this.handleKeyPressCodeRakeId}
                                        autoComplete="off"
                                        onChange={this.handleChange}
                                        />
                                    </div>

                  <div className="form-group col-xl-3 col-lg-3">
                    <button type="submit" className="btn btn-success">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

        </div>


        <div className={"dataLoadpage " + (this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
          <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>
    );
  }
}

import React, {Component} from "react";
import ReactDom,{ render } from 'react-dom';
import axios from 'axios';
import { Redirect } from 'react-router';
import TMenus from './topmenus';


//import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

var images = require.context('../../assets/images/', true);

export default class Token extends Component {

    constructor(props) {
        super(props);
        var refur='';
        
        this.state = {
            style: {},
            email:'',
            password:'',
             loginMessage:'',
             scope:'',
             client_id:'',
             grant_type:'',
             client_secret:'',
             redirect_uri:'',
             code:'',
             erromsg:''
        };
    }

    componentDidMount() {
        setTimeout(function () {
            this.setState({style: {display:'none'}});
        }.bind(this),1000)
        console.log("Access",localStorage.getItem('zohoToken'));
    }
    
    changeHandler = (event) => {

        let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
    
 formHandlertest = (event) => {
        
        event.preventDefault();
        var formdata = new FormData(event.target);
        const fdata = {
                scope: this.state.scope,
                client_id: this.state.client_id,
                grant_type: this.state.grant_type,
                client_secret: this.state.client_secret,
                redirect_uri: this.state.redirect_uri,
                code: this.state.code
        }
         var params= {
              //  scope: "ZohoMail.accounts.READ,ZohoMail.folders.READ,ZohoMail.messages.READ,ZohoMail.Attachments.READ",
              //  client_id: "1000.AW1CCGPZCR59529ULX7MMDHBWGEADH",
              //  grant_type: "authorization_code",
              //  client_secret: "7e99ec16ec94a219297cfc6e495e8e70303dfedd92",
              //  redirect_uri: "http://autometrics.in/oauth2/callback",
                code: "1000.fc853997af229287ed7a7ab90587d7e5.cbef52268123697930af27d583881dda"   
           };
        axios.post('http://autometrics.in/oauth2/token',params,{
            headers:{
                'Content-Type': 'multipart/form-data',
                // 'Access-Control-Allow-Origin':"*",
                // 'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE, TOKEN',
                'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
               // 'authorization': 'Zoho-oauthtoken 1000.ecb6dbd17a44125ce5434f5f45148278.a54f59a27d9e9db5383f85ba3002ccf2' 
            }
        })
          .then( response => {
        	  if(response == 'INVALID_OAUTHTOKEN')
    		  {
        		  
        		  this.setState({
        			  erromsg:"Token has expired, Please use Refresh Token option"
                  });
    		  }
              localStorage.setItem('zohoToken', response.data.data.access_token);
        	  localStorage.setItem('zohoRefreshtoken', response.data.data.refresh_token);
              console.log("Access",localStorage.getItem('zohoToken'));
            //console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
        
    }
    render() {
        let style = this.state.style;
        const background = require('../../assets/images/auth-layer.png');
       
        
        return (
            <div>
                {/* Loader starts */}
                <div className="loader-wrapper" style={style}>
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Have a great day at work today <span>&#x263A;</span></h4>
                    </div>
                </div>
                {/* Loader ends */}

                {/*page-wrapper Start*/}
                <div className="page-wrapper">
                    <div className="container-fluid">
                        {/*login page start*/}
                        <div className="authentication-main" >
                            <div className="row">
                                <div className="col-md-4 p-0">
                                    <div className="auth-innerleft" style ={ { backgroundImage: "url("+background+")" } }>
                                        <div className="text-center">
                                            <img src={require('../../assets/icons/logo.png')}
                                                    className="logo-login" style={{width:'75%'}} alt=""/>
                                               <hr/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 p-0">
                                    <div className="auth-innerright">
                                        <div className="authentication-box">
                                        <TMenus />
                                            <h4>Generate Access Token</h4>
                                            
                                            <div className="card mt-4 p-4 mb-0">
                                            
                                            {this.state.erromsg}
                                            <form className="theme-form" onSubmit={this.formHandlertest} >

                                            <div className="form-group">
                                                <label className="col-form-label pt-0">code</label>
                                                <input  type="text" name='code' className="form-control form-control-lg" value={this.state.code} onChange={this.changeHandler} />
                                            </div>
                                            {/*
                                            <div className="form-group">
                                                <label className="col-form-label pt-0">grant_type</label>
                                                <input  type="text" name='grant_type' className="form-control form-control-lg" value={this.state.grant_type} onChange={this.changeHandler} />
                                            </div>
                                            <div className="form-group">
                                                <label className="col-form-label pt-0">client_id</label>
                                                <input  type="text" name='client_id' className="form-control form-control-lg" value={this.state.client_id} onChange={this.changeHandler} />
                                            </div>
                                            <div className="form-group">
                                                <label className="col-form-label pt-0">client_secret</label>
                                                <input  type="text" name='client_secret' className="form-control form-control-lg" value={this.state.client_secret} onChange={this.changeHandler} />
                                            </div>
                                            <div className="form-group">
                                                <label className="col-form-label pt-0">redirect_uri</label>
                                                <input  type="text" name='redirect_uri' className="form-control form-control-lg" value={this.state.redirect_uri} onChange={this.changeHandler} />
                                            </div>
                                             <div className="form-group">
                                                 <label className="col-form-label pt-0">Scope</label>
                                                 <input  type="text" name='scope' className="form-control form-control-lg" value={this.state.scope} onChange={this.changeHandler} />
                                             </div>
                                                */}
                                             <div className="form-group form-row mt-3">
                                                 <div className="col-md-3">
                                                     <button type="submit" className="btn btn-secondary">Test
                                                     </button>
                                                 </div>
                                             </div>
                                         </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*login page end*/}
                    </div>
                </div>
                {/*page-wrapper Ends*/}

            </div>

        )
    }
}




import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const Showlmvalue = (props) => {
//    console.log("props ", props)

    return (
        <div>
		    {(props.value != undefined)?props.value:0}
        </div>
    );
};

export default Showlmvalue;

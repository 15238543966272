import React, { useEffect } from 'react'
import { countries } from "country-flags-svg";
// import { Tooltip } from "react-tooltip";
var moment = require('moment');

const EventTab = (props) => {

  console.log(props, "EventsTab");
  const getFlagIcon = (countryName) => {
    let country_name = "";
    var srcFlag = countries.filter((eachCountry) => {
      if (eachCountry.iso2 === countryName) {
        country_name = eachCountry.name;
        return eachCountry;
      }
    });
    if (srcFlag && srcFlag[0].flag) {
      return {
        img: srcFlag[0].flag,
        name: country_name,
      };
    } else {
      return "";
    }
  };
  if (!props.eventData.events || props.eventData.events.length === 0 || props.eventData.events === undefined) {
    return (
      <></>
    )
  }
  const dataArray = []
  let temp2 = props.eventData.events.filter(e => e.searates_description != "unknown")
  temp2.sort((a, b) => {
    if (a.actual_time !== "" && b.actual_time !== "") {
      return moment(a.actual_time, 'YYYY-MM-DD hh:mm:ss A').diff(moment(b.actual_time, 'YYYY-MM-DD hh:mm:ss A'))
    }
    else if (a.actual_time !== "") {
      return -1;
    }
    else if (b.actual_time !== "") {
      return 1;
    }
    else {
      return 0;
    }
  }
  );
  console.log(temp2, "tttttttt");

  const temp = [...props.eventData.events.filter(e => e.searates_description != "unknown")].reverse()
  if (props.eventData.discharge_port == props.eventData.last_port) {

  }
  temp.forEach((each, i) => {
    let status = ""
    if (each.actual_time == '')
      status = 'active'
    else
      status = 'completed'
    if (each.location == props.eventData.first_port) {
      dataArray.push({ port: props.eventData.first_port, portType: "Origin Port", status: status, eventName: each.searates_description, actual_time: each.actual_time, current_time: each.current_time, location_code: each.location_code, estimated_time: each.estimated_time, event_group: each.event_group, searates_order_id: each.searates_order_id })
    }
    else if (each.location == props.eventData.loading_port) {
      dataArray.push({ port: props.eventData.loading_port, portType: "Ocean Port", status: status, eventName: each.searates_description, actual_time: each.actual_time, current_time: each.current_time, location_code: each.location_code, estimated_time: each.estimated_time, event_group: each.event_group, searates_order_id: each.searates_order_id })
    }
    else if (each.location == props.eventData.discharge_port) {
      dataArray.push({ port: props.eventData.discharge_port, portType: ((props.eventData.discharge_port == props.eventData.last_port) ? "Destination Port" : "Discharge Port"), status: status, eventName: each.searates_description, actual_time: each.actual_time, current_time: each.current_time, location_code: each.location_code, estimated_time: each.estimated_time, event_group: each.event_group, searates_order_id: each.searates_order_id })
    }
    else if (each.location == props.eventData.last_port) {
      dataArray.push({ port: props.eventData.last_port, portType: "Destination Port", status: status, eventName: each.searates_description, actual_time: each.actual_time, current_time: each.current_time, location_code: each.location_code, estimated_time: each.estimated_time, event_group: each.event_group, searates_order_id: each.searates_order_id })
    }
    else {
      if (i == (temp.length - 1)) {
        dataArray.push({ port: props.eventData.first_port, portType: "Origin Port", status: status, eventName: each.searates_description, actual_time: each.actual_time, current_time: each.current_time, location_code: each.location_code, estimated_time: each.estimated_time, event_group: each.event_group, searates_order_id: each.searates_order_id })
      }
      else if (i == 0) {
        dataArray.push({ port: props.eventData.last_port, portType: "Destination Port", status: status, eventName: each.searates_description, actual_time: each.actual_time, current_time: each.current_time, location_code: each.location_code, estimated_time: each.estimated_time, event_group: each.event_group, searates_order_id: each.searates_order_id })
      }
      else {
        dataArray.push({ port: each.location, portType: "Transshipment Port", status: status, eventName: each.searates_description, actual_time: each.actual_time, current_time: each.current_time, location_code: each.location_code, estimated_time: each.estimated_time, event_group: each.event_group, searates_order_id: each.searates_order_id })
      }
    }
  })
  const groupedData = dataArray.reduce((groups, item) => {
    const group = item.port;
    groups[group] = groups[group] || [];
    groups[group].push(item);
    return groups;
  }, {});
  const result = Object.entries(groupedData).map((each) => ({
    group: each[0],
    data: each[1]
  }));
  const detailsMap = new Map()
  const test = [...props.eventData.events.filter(e => e.searates_description != "unknown")]
  test.map((value, j) => {
    let previousDate = ""
    try {
      if (j == 0) {
        previousDate = "first"
      }
      else if (j > 0) {
        previousDate = test[j - 1].actual_time
      }
      const delay = (moment(value.actual_time)).diff(moment(previousDate));
      const duration = moment.duration(delay)
      const days = duration.days();
      const hours2 = duration.hours();
      let details = ""
      if (previousDate === "first") {
        details = moment(value.actual_time, "YYYY-MM-DD hh:mm:ss A").format("Do MMM, YYYY")
      }
      if (days < 0) {
        if (Math.abs(days) === 1) {
          details = Math.abs(days) + " day"
        }
        else {
          details = Math.abs(days) + " days"
        }
        if (hours2 !== 0)
          details = details + " " + Math.abs(hours2) + " hour(s)"
      }
      else if (days > 0) {
        if (Math.abs(days) === 1) {
          details = Math.abs(days) + " day"
        }
        else {
          details += Math.abs(days) + " days"
        }
        if (hours2 !== 0)
          details = details + " " + Math.abs(hours2) + " hour(s)"
      }
      else if (days === 0) {
        details = hours2 + " hour (s) " + duration.minutes() + " min (s)"
      }
      detailsMap.set(value.searates_order_id, details)
    } catch (err) {

    }
  })
  function getValue(inputString) {
    if (inputString === "")
      return ""
    if (inputString.length < 30)
      return inputString
    var words = inputString.slice(0, 35) + "...";

    return words;
  }
  return (
    <div className="card pl-4" style={{ borderRadius: "16px", display: "block", whiteSpace: "nowrap" }}>
      <div className="pt-4">
        <div className="row" style={{ paddingLeft: "150px", fontWeight: "bold", fontSize: "0.8rem" }}>
          <div className="col-4 text-left">
            Location and Events
          </div>
          <div className="col-4 text-center">
            Date & time
          </div>
          <div className="col-4">
            Duration
          </div>
        </div>
        <br />
        {
          (!dataArray || dataArray.length === 0 || dataArray === undefined) ? <></> :
            <>
              <div className='row'>
                <div className='col-1' style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                  <ProgressBar data={result} />
                </div>
                <div className='col-11'>
                  <ul style={{ paddingLeft: "0px" }}>
                    {
                      (!dataArray || dataArray.length === 0 || dataArray === undefined) ? <></> :
                        <>
                          {result.map((each, i) => {
                            let srcIcon = ""

                            if (each.data[0].location_code) {
                              srcIcon = getFlagIcon(each.data[0].location_code.slice(0, 2));
                            }
                            return (
                              <>
                                {/* <Tooltip id="info_name2" /> */}
                                <div
                                  className={`pb-3 info_name2`}
                                  data-tooltip-id="info_name2"
                                  data-tooltip-content={(each.group.length >= 30) ? (`${each.group}, (${each.data[0].portType})`) : ("")}>
                                  {srcIcon.hasOwnProperty("img") &&
                                    srcIcon.hasOwnProperty("name") ? (
                                    <>
                                      <img
                                        src={srcIcon.img}
                                        alt="SRC"
                                        className="flag_class"
                                        style={{
                                          transform: `${srcIcon.name === "China" ? "rotateX(45deg)" : ""
                                            }`,
                                        }}
                                      />&nbsp;&nbsp;&nbsp;
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  <label style={{ fontSize: "0.9rem", fontWeight: "bold" }}>
                                    {`${getValue(each.group)}, (${each.data[0].portType})`}
                                  </label>
                                  {
                                    result[i].data.map((e) => {
                                      if (each.group == e.port) {
                                        return (
                                          <>
                                            <div className={`row`} style={{ paddingLeft: "20px", paddingBottom: (result[i].data.length == 1) ? "0px" : "10px", fontSize: "0.8rem" }}>
                                              <div className="col text-left">
                                                {e.eventName}
                                              </div>
                                              <div className="col text-center">
                                                {e.actual_time ? (moment(e.actual_time, "YYYY-MM-DD hh:mm:ss A").format("Do MMM, YYYY h:mma")) : (moment(e.current_time, "YYYY-MM-DD hh:mm:ss A").format("Do MMM, YYYY  h:mma"))}
                                              </div>
                                              <div className="col">
                                                {(detailsMap.get(e.searates_order_id) == "") ? "Yet to occur" : detailsMap.get(e.searates_order_id)}
                                              </div>
                                            </div>
                                          </>
                                        )
                                      }
                                    })
                                  }
                                </div>
                              </>
                            );
                          })}
                        </>
                    }
                  </ul>
                </div>
              </div>
            </>
        }
      </div>
    </div>
  )
}

const ProgressBar = ({ data }) => {
  let ydot = 1 + (0 * 11);
  let fillColor = "#cccc"
  let line = ""
  let y2line;
  let yline;
  console.log(data, "ddddddddd");
  return (
    <svg height={`100%`} width={`100%`}>
      {
        data.map((each, i) => {
          if (i != 0) {
            ydot = y2line + 1;
          }
          if (each.data.some(obj => obj.status == "active"))
            fillColor = "#cccc"
          else
            fillColor = "#171c8f"
          return (
            <>
              <circle cx="70%" cy={`${ydot}rem`} r="1rem" stroke={fillColor} fill={fillColor} />
              {
                i == 0 ?
                  <svg x="65%" y={`${ydot - 0.5}rem`} width="14" height="20" viewBox="0 0 14 20" dominant-baseline="middle" text-anchor="middle" fill='#ffff' xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.175081 18.0289C-0.0598448 17.7793 -0.0598449 17.3377 0.184116 17.1074C0.346757 16.9538 0.54554 16.9154 1.4491 16.8962L2.52434 16.8578V8.6312C2.52434 0.66379 2.53337 0.39501 2.69601 0.231822C2.9219 0.0206379 3.76221 -0.075355 4.17785 0.0686343C4.89166 0.318216 4.78324 1.31654 4.04232 1.32614H3.78932V3.72596V6.12578H7.04214C8.83119 6.12578 10.295 6.08738 10.295 6.04899C10.295 6.00099 10.051 5.51143 9.75282 4.96427C9.45464 4.41711 9.21068 3.86035 9.21068 3.73556C9.21068 3.60117 9.45464 3.05401 9.75282 2.50685C10.051 1.96929 10.295 1.47973 10.295 1.42213C10.295 1.36454 9.70764 1.32614 8.62337 1.32614C7.06924 1.32614 6.94274 1.31654 6.77107 1.13415C6.54518 0.894173 6.53614 0.548598 6.76203 0.26062L6.93371 0.0302371H9.23779H11.5419L11.7768 0.26062C12.1472 0.625393 12.084 0.922971 11.3792 2.18048C10.4305 3.89875 10.4395 3.57237 11.2798 5.11786C12.0027 6.44256 12.1111 6.79773 11.8943 7.09531C11.6322 7.46008 11.4786 7.46968 7.59331 7.46968H3.78932V12.1733V16.877H4.7742C5.62355 16.877 5.78619 16.9058 5.99401 17.0786C6.28315 17.3185 6.31025 17.7505 6.04822 18.0289C5.87654 18.2113 5.75005 18.2209 3.11165 18.2209C0.473256 18.2209 0.346757 18.2113 0.175081 18.0289Z" fill="#ffffff" />
                    <path d="M2.71191 1.13035C2.71191 0.506077 3.21799 0 3.84227 0H9.98895C10.8674 0 11.4101 0.958199 10.9584 1.71161L10.0959 3.15016C9.84065 3.57586 9.89298 4.11825 10.2249 4.4873L10.2882 4.55774C10.9425 5.28524 10.4262 6.44397 9.44779 6.44397H3.84227C3.21799 6.44397 2.71191 5.93789 2.71191 5.31361V1.13035Z" fill="#ffffff" />
                  </svg>
                  :
                  <>
                    {
                      i == (data.length - 1) ?
                        <>
                          <svg
                            x="62%" y={`${ydot - 0.7}rem`} width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="m 10.547661,17.431165 c 2.206783,-1.768225 8.896354,-7.385981 8.896354,-10.5127749 0,-3.7443589 -4.295083,-6.77978549 -9.5933063,-6.77978549 -5.29824,0 -9.59330875,3.03542659 -9.59330875,6.77978549 0,3.1267939 6.68954985,8.7445499 8.89635485,10.5127749 0.3743778,0.299896 1.0195262,0.299896 1.3939062,0 z M 9.8527072,10.161284 c 2.5339908,0 4.5881618,-1.4517247 4.5881618,-3.242498 0,-1.7908135 -2.054171,-3.2425356 -4.5881618,-3.2425356 -2.5339199,0 -4.5880876,1.4517221 -4.5880876,3.2425356 0,1.7907733 2.0541677,3.242498 4.5880876,3.242498 z"
                              fill="#ffffff" style={{ strokeWidth: "0.72333" }} />
                            <rect style={{ fill: "#ffffff", fillRule: "evenodd", strokeWidth: "1.3" }} width="16.087515" height="1.46332046" x="1.7503217" y="18.275415" ry="0.23166023"rx="0.373339" />
                          </svg>
                        </> :
                        <text x="70%" y={`${ydot + 0.1}rem`} dominant-baseline="middle" text-anchor="middle" fill='#ffff' font-size="20">
                          ▲ 
                        </text>
                    }
                  </>
              }
              {
                data[i].data.map((value, j) => {
                  let temp = false
                  if (value.actual_time == "") {
                    line = "2,2";
                    fillColor = "#cccc"
                  } else {
                    line = "none";
                    if (value.actual_time && value.estimated_time) {
                      let timeDiff = moment(
                        value.estimated_time,
                        "YYYY-MM-DD hh:mm:ss A"
                      ).diff(moment(value.actual_time, "YYYY-MM-DD hh:mm:ss A"));
                      if (timeDiff > 0) {
                        fillColor = "#40ec1d";
                      } else if (timeDiff < 0) {
                        fillColor = "#f01515";
                      } else {
                        fillColor = "#171c8f";
                      }
                    }
                    else {
                      fillColor = "#171c8f"
                    }
                  }
                  if (j == 0) {
                    yline = (ydot + 0.8) + (j * 2.3)
                    y2line = yline + 2
                  }
                  else if (i != (data.length - 1) && j == (data[i].data.length - 1)) {
                    yline = y2line
                    y2line = yline + 2.2
                  }
                  else {
                    if (i == (data.length - 1) && j == (data[i].data.length - 1)) {
                      temp = true
                      yline = y2line
                      y2line = yline + 1.2
                    }
                    else {
                      yline = y2line
                      y2line = yline + 2
                    }
                  }
                  return (
                    <>
                      <line x1="70%" y1={`${yline}rem`} x2="70%" y2={`${y2line}rem`} stroke={fillColor} strokeWidth="3" stroke-dasharray={line} />
                      {
                        temp ?
                          <line x1="60%" y1={`${y2line}rem`} x2="80%" y2={`${y2line}rem`} stroke={fillColor} strokeWidth="3" stroke-dasharray={line} /> :
                          <></>
                      }

                    </>
                  )

                })
              }
            </>
          )

        })}
    </svg>
  )
}

export default EventTab
import React, { Component } from 'react';
import $ from "jquery";
import _ from "lodash";
import SweetAlert from 'react-bootstrap-sweetalert';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
	getHyphenDDMMMYYYYHHMM
} from '../common/utils';
var redirectURL = require('../redirectURL');

var infoBox = require('../common/google-infowindow');
var googleAnalytics = require("../common/googleanalytics");

var map;
var rad = function (x) {
	return x * Math.PI / 180;
};
var rlatitude;
var rlongitude;

export default class LedRailwayLogisticsscreen6 extends Component {

	constructor(props) {
		super(props);
		this.displayData = [];
		this.state = {
			pageTitle: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform: 'show-m',
			loadshow: 'show-n',
			showrefresh: 'show-n',
			sliderTranslate: '',
			showdata: this.displayData,
			radius: '5000',
			rlatitude: '',
			rlongitude: '',
			latitude: '',
			longitude: '',
			defaultradius: 0,
			coordinates: [],
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			contentString: "testing",
			mwidth: "col-xl-12 col-lg-12",
			alldepts: [],
			transportervalue: 'ALL',
			alltransporters: [],
			truckstatus: '0',
			kpicode: '0',
			deptcode: '',
			deptcode1: "",
			truckNoList: [],
			alltrucks: [],
			truckno: '',
			showTrucksList: "show-n",
			filteredData: [],
			triptype: { "value": 1, "label": "ALL" },
			plantLocations: [],
			totalTrucksCount: 0,
			onTripCounter: 0,
			mapdetails: [],
			path1: "",
			path2: "",
			path3: "",
			path4: "",
			currentslide: 0,
			orignalarr: [],
			temparr: [],
			sactive: "",
			pactive: "activet",
			tactive: "activet",
			ractive: "",

		};
		this.popmarker = this.popmarker.bind(this);

		this.onSlideFrame = this.onSlideFrame.bind(this);


	}
	/*Alert Popups*/
	closeAlert = () => {
		this.setState({
			show: false
		});
	}


	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}

	autororate(){ 
		console.log(localStorage.getItem("username"),'username')
		if(localStorage.getItem("username") == "LED USER"){
		 this.rotationInterval = setInterval(this.rotateScreens, 60000);
		}
	  
	  }
	 
	  componentWillUnmount() {
		 clearInterval(this.rotationInterval);
	   }
	 
	   rotateScreens = () => {
	  
		window.location.href="/ledrailwaylogisticsscreen"
	  }

	componentDidMount() {
		// console.log(this.props.match.url, 'url')
		this.logPageView(); // Log page view to GA

		this.setState({
			loadshow: 'show-m',
			coordinates: [],
			rowData: []
		});
		var dept;
		var hText = 'All';


		let pageTitle = "Trucks Locations ( " + hText + " )";
		this.setState({
			deptcode: "prt",
			deptcode1: "tnp",
			pageTitle: pageTitle,
			loadshow: 'show-m'
		});

		var formdata = {
			radius: 10000,
			latitude: "20.73568784876339",
			longitude: "79.38999444931353",
			dept_code: dept,
			transporter_code: this.state.transportervalue,
			status: this.state.truckstatus,
			trip_type: 1
		}
		rlatitude = "20.73568784876339";
		rlongitude = "79.38999444931353";
		redirectURL.post('/vicinity/getRadiusCoordinates', formdata, {
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"

			}
		})
			.then(
				(response) => {
					var markers = JSON.parse(JSON.parse(response.data.body).trucks_data);
					if (markers.length > 0) {
						this.setState({
							defaultradius: formdata.radius,
							coordinates: markers,
							totalTrucksCount: markers.length,
							mwidth: "col-xl-12 col-lg-12",
							rowData: markers,
							radius: 5000,
							loadshow: 'show-n',
							showform: 'show-n',
							showrefresh: 'show-m',
							rlatitude: "21.144644112601775",
							rlongitude: "79.08860126768066",
							alltrucks: markers,
							onTripCounter: JSON.parse(response.data.body).gps_trucks
						});
						redirectURL.post("/weather/getPlants").then((rsp) => {
							this.setState({
								plantLocations: rsp.data,
							});
							console.log(rsp.data);
							//this.renderMap();

						})

					}
					else {
						this.setState({
							loadshow: 'show-n',
							// showform:'show-m',
						});

					}
				})
			.catch(function (error) {
				console.log(error);
			});

		redirectURL.post("consignments/getAllTrucksData").then((response) => {

			try {
				var trucks = response.data.filter(function (e) { return e.dept_code == dept; })
				//console.log("trucks ", trucks)
				trucks = trucks.map(function (e) {
					if (e.truck_no != "") {
						return e.truck_no;
					}
				})
				this.setState({
					truckNoList: trucks,
					loadshow:'show-n'
				});
			} catch (e) { }

			//console.log(trucks.length);
		});
		var deptarr = ['prt', 'tnp'];
		this.setState({
			orignalarr: deptarr,
			temparr: deptarr,
			
		})


 this.autororate()
	}


	popmarker(e) {
		var lat = parseFloat(e.data.latitude);
		var lng = parseFloat(e.data.longitude);
		var data = e.data;
		map.setCenter(new window.google.maps.LatLng(lat, lng));
		map.setZoom(22);
		var markerLatlng = new window.google.maps.LatLng(lat, lng);
		var image = require('../../assets/icons/truckblue.png');
		var marker = new window.google.maps.Marker({
			position: markerLatlng,
			map: map,
			title: data.truck_no,
			icon: image
		});

		var contentarr = []
		var headerTitle = data.truck_no
		contentarr.push({ "key": "Transporter Name", "value": data.transporter_name });
		contentarr.push({ "key": "GPS Provider", "value": data.actual_lspuser });
		contentarr.push({ "key": "Last Packet Time", "value": getHyphenDDMMMYYYYHHMM(data.timestamp) });
		contentarr.push({ "key": "Speed (km/h)", "value": Math.ceil(data.speed) });
		contentarr.push({ "key": "Address", "value": data.area + ", " + data.state });

		var contentString = infoBox(image, headerTitle, contentarr, '')
		var infowindow = new window.google.maps.InfoWindow({
			content: contentString
		});

		marker.setAnimation(window.google.maps.Animation.DROP)
		marker.addListener('click', function () {
			infowindow.open(map, marker);
		});

		marker.setMap(map);


	}


	onSlideFrame = event => {

		if (event == 1) {
			var path2 = "prt"
		}
		if (event == 2) {
			var path3 = "tnp"
		}
		this.setState({

			path2: path2,
			path3: path3,

			currentslide: event
		})

	}


	render() {
		var listData = []
		try {
			this.state.filteredData.map(function (e) {
				listData.push(<li className="dropdown-truck-no">{e}</li>)
			})
		} catch (e) { console.log(e) }

		return (

			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				{this.state.alert}
				<div className="row" >
					<div className="col-xl-12 col-lg-12 whiteRow">

						<div className="row col-xl-12 col-lg-12">

							<div className={"dataLoadpage " + (this.state.loadshow)}>
							</div>
							<div className={"dataLoadpageimg " + (this.state.loadshow)}>
								<div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
							</div>

							<div className="row" >
								<div className="col-xl-12 col-lg-12 f16 mt-40p ml-30p">
									<button type="button" className="f16 btn ttabs activet" >Spare Parts</button>
										
								</div>

								{(this.state.deptcode == "prt") ?
									<div className="col-xl-6 col-lg-6 " style={{ width: '600px' }}>

										<div className="col-xl-12 col-lg-12" >


											<iframe
												style={{ overflow: "hidden", margin: "0px", width: "100%" }}
												width="100%"
												height="380px"
												frameborder="0"
												src={"/ledlivemaptruck?type=2&dept=prt&slide=1&cslide=" + (this.state.currentslide)}>

											</iframe>
										</div>
									</div>
									: ""}

								{(this.state.deptcode == "prt") ?
									<div className="col-xl-6 col-lg-6" style={{ width: '600px' }}>

										<div className="col-xl-12 col-lg-12" >

											<iframe
												style={{ overflow: "hidden", margin: "0px", width: "100%" }}
												width="100%"
												height="380px"
												frameborder="0"
												src={"/ledlivemaptruck?type=5&dept=prt&slide=1&cslide=" + (this.state.currentslide)}>

											</iframe>
										</div>
									</div>
									: ""}
								<div className="col-xl-12 col-lg-12 f16 mt-40p ml-30p">
								<button type="button" className="f16 btn ttabs activet" >Production Parts</button>
								</div>
								{(this.state.deptcode1 == "tnp") ?
									<div className="col-xl-6 col-lg-6 " style={{ width: '600px' }}>

										<div className="col-xl-12 col-lg-12" >


											<iframe
												style={{ overflow: "hidden", margin: "0px", width: "100%" }}
												width="100%"
												height="380px"
												frameborder="0"
												src={"/ledlivemaptruck?type=2&dept=tnp&slide=1&cslide=" + (this.state.currentslide)}>

											</iframe>
										</div>
									</div>
									: ""}

								{(this.state.deptcode1 == "tnp") ?
									<div className="col-xl-6 col-lg-6" style={{ width: '600px' }}>

										<div className="col-xl-12 col-lg-12" >

											<iframe
												style={{ overflow: "hidden", margin: "0px", width: "100%" }}
												width="100%"
												height="380px"
												frameborder="0"
												src={"/ledlivemaptruck?type=5&dept=tnp&slide=1&cslide=" + (this.state.currentslide)}>

											</iframe>
										</div>
									</div>
									: ""}

							</div>

						</div>
					</div>

				</div>


			</div>

		);
	}
}

$(document).ready(function () {
	$(document).on("click", ".dropdown-truck-no", function () {
		var vals = $(this).text();
		$("#inputTruck").val(vals);
		$(".trucks-dropdown").removeClass("show-m");
		$(".trucks-dropdown").addClass("show-n");
	});

	$("body").on("click", function (e) {
		var container = $("#inputTruck");
		if (!container.is(e.target)) {
			try {
				if ($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown") {
					$(".trucks-dropdown").removeClass("show-m");
					$(".trucks-dropdown").addClass("show-n");
				}
			} catch (e) { }

		}


	})
})
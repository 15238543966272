



import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 

import Highcharts from 'highcharts';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';
drilldown(Highcharts);
window.jQuery = $;
window.$ = $;

var redirectURL = require('../redirectURL');
var highchartoptions;
var criticaldata=[]
export default class RouteWiseThreeLevelChart extends Component {

    constructor(props) {
		super(props);
		this.state = {
			showgrid:'display:none',
			showtransgrid:'display:none',
			rowdata:[],
			test:"TEstng",
			drilldowndata:[]
		}

	}
	componentDidMount = async () =>{
		
		$(".grid-"+this.props.code).hide();
		$(".grid-trans-"+this.props.code).hide();
		$(".dataLoadpagesum"+this.props.code).hide()
		$(".dataLoadpageimgsum"+this.props.code).hide()
		$(".overlay-partsum"+this.props.code).hide()
		
	}
	componentWillReceiveProps (newprops){
        //if(newprops.title != this.props.dept_code)
        //{
			this.setState({
				drilldowndata:newprops.drildown
			})
            //console.log("newprops ",newprops);
		//}

		
	}
	hideGrid(params){
		//console.log(params)
		hideGrid(params)
	}
	hideTransGrid(params){
		hideTransGrid(params)
    }
    
    render(){
        // console.log("categories ",this.props.categories);
        // console.log("newprops ",this.props.series);
        const dStyles={
				width:'100%',
				height:'100%'
        }
        var drilldowncols = this.props.drilldowncols;
        var drilldownlines = this.props.drilldownlines;
        var drilldowncols1 = this.props.drilldowncols1;
        var drilldowncols2 = this.props.secondChartOEMWiseReturnColumn;
        // console.log("drilldowncols ", drilldowncols)
        // console.log("drilldownlines ", drilldownlines)
        highchartoptions = {
            chart: {
                zoomType: 'xy',
                type:"column",
                events: {
                    drilldown: function (e) {
                        if (!e.seriesOptions) {
                            var chart = this,
                                drilldowns = drilldowncols,
                                drilldowns1 = drilldowncols1,
                                drilldowns2 = drilldowncols2,
                                drilldownsLines = drilldownlines,
                                series = drilldowns[e.point.name],
                                series1 = drilldowns1[e.point.name],
                                series2 = drilldowns2[e.point.name],
                                seriesLine = drilldownsLines[e.point.name]
                                
                                //console.log("EE ",chart.xAxis[0].ticks)
                            try{
                                //chart.zoomType("y");
                                // var levels = [];
                                // e.target.drilldownLevels(function(e){
                                //     if(levels.indexOf(e.levelNumber) == -1)
                                //     {
                                //         levels.push(e.levelNumber)
                                //     }
                                // })
            
                                // console.log(chart.series[0].options._levelNumber);
                                if(this.options.series[0].name == "Total Trips")
                                {
                                    chart.addSingleSeriesAsDrilldown(e.point, series);
                                    chart.addSingleSeriesAsDrilldown(e.point, series1);
                                    chart.addSeriesAsDrilldown(e.point, seriesLine);
                                }
                                else
                                {
                                
                                    chart.addSingleSeriesAsDrilldown(e.point,series2);

                                }
                                chart.applyDrilldown();
                                
    
                            }
                            catch(e){
                                chart.applyDrilldown();
                            }
                        }
    
                    }
                }
            },
            title: {
                text: this.props.title
            },
            subtitle: {
                text: this.props.subtitle
            },
            // xAxis: [{
            //     categories:this.props.categories,
            //     crosshair: true
			// }],
			xAxis: {
				type: 'category',
				// categories: ["Total Trips", "Return Trips"],
                crosshair: true,
                labels: {
                    formatter: function() {
                        if(this.value.indexOf("_") != -1)
                        {
                            return this.value.split("_")[0].charAt(0).toUpperCase()+this.value.split("_")[0].slice(1)+" "+
                            this.value.split("_")[1].charAt(0).toUpperCase()+this.value.split("_")[1].slice(1)
                        }
                        else
                        {
                            return this.value
                        }
                    },
                    // rotation: 0.1,
                    // align: 'left',
                    // step: 10,
                    enabled: true
                },
			},
            yAxis: [
                { // Secondary yAxis
                    title: {
                        text: 'Total Trips vs Return Trips',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: '{value} ',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: true
                },
                { // Primary yAxis
                labels: {
                    format: '{value}',
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                },
                title: {
                    text: 'Return Trip Percentage',
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                }
            }],
			plotOptions: {
                column: {
                    stacking: false,
                    dataLabels: {
                        enabled: true
					},
				},
				line:{
					dataLabels:{
                        enabled:true,
                        format:'{point.y}'
					}
				}
			},
            tooltip: {
                shared: true
            },
            // legend: {
            //     layout: 'vertical',
            //     align: 'left',
            //     x: 120,
            //     verticalAlign: 'top',
            //     y: 100,
            //     floating: true,
            //     backgroundColor:
            //         Highcharts.defaultOptions.legend.backgroundColor || // theme
            //         'rgba(255,255,255,0.25)'
			// },
			 colors:[ '#f35e82','#7f84e9','#FF5370', '#f15c80', '#92A8CD','#A47D7C','#f3d800',  '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
		
            series: this.props.series,
            drilldown: {
                chartOptions: {
                    yAxis: [
                        { // Secondary yAxis
                            title: {
                                text: 'Total Trips vs Return Trips',
                                style: {
                                    color: Highcharts.getOptions().colors[0]
                                }
                            },
                            labels: {
                                format: '{value} ',
                                style: {
                                    color: Highcharts.getOptions().colors[0]
                                }
                            },
                            opposite: true
                        },
                        { // Primary yAxis
                        labels: {
                            format: '{value}',
                            style: {
                                color: Highcharts.getOptions().colors[1]
                            }
                        },
                        title: {
                            text: 'Return Trip Percentage',
                            style: {
                                color: Highcharts.getOptions().colors[1]
                            }
                        }
                    },],
                },
                
                series: [],
                // seriesLine: this.props.drilldownlines
            },
			credits:false
        };
        // console.log(highchartoptions.series)
        // console.log(this.props.drilldowncols,"drilldownCols");
        // console.log(this.props.drilldowncols1,"this.props.drilldowncols1")
        // console.log(this.props.drilldownlines,"drilldownlines")
        return ( 
			<div>
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />
			</div>
			);
		}
}


function hideGrid(id)
{
	console.log("id ",id)
	$(".grid-"+id).fadeOut()
}

function hideTransGrid(id)
{
	console.log("id ",id)
	$(".gridtrans-"+id).fadeOut()
}


function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
  } 

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

function arrayMin(arr) {
	return arr.reduce(function (p, v) {
	  return ( p < v ? p : v );
	});
  }
  
  function arrayMax(arr) {
	return arr.reduce(function (p, v) {
	  return ( p > v ? p : v );
	});
  }

// function getData(){
// 	alert("cons")
// }

$(document).ready(function(){
	
})

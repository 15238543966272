
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const Editusdrate = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props.data);
        props.context.componentParent.onClickEditData(props);
    };
    

    return (
        <div>
			<button onClick={handleClick} className="custom-btn f12 btn btn-warning" title="Edit" >Edit</button>
            
        </div>
    );
};

export default Editusdrate;
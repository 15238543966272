'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import $ from 'jquery';
import { getHyphenDDMMMYYYYHHMM} from '../common/utils'; 
import download from 'js-file-download';

// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');



export default class CarsPlanningSummary extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        loadFile : "",
        processErrMsg : "",
        processErrShow : 0,
        transactionId : "",
        comp_code : "",
        lpNames : [],
        loading_port_code : "",
        lastRunTime : "",
        lastRunRowId : "",
        time: {}, 
        seconds: 120
        //components : {datepicker:getDatePicker()}
    }
    this.onLoadPageData = this.onLoadPageData.bind(this);
    this.changeFileHandler = this.changeFileHandler.bind(this);
    this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.timer = 0;
}

logPageView = () => {
    try {
        if (googleAnalytics.page.enableGA) {
            googleAnalytics.logPageView();
            let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
            let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
            this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
        } else {
            console.log("GA disabled...");
        }
    } catch(error) {
        console.error("Error occured while logging to GA, error = ", error);
    }
}  
    
componentDidMount(){
    this.logPageView();
    $("#timerDiv").hide();
    this.onLoadPageData();
}
onLoadPageData(){
    this.setState({
        overly:"show-m",
        loadshow:"show-m",
    })
    redirectURL.post("/rakeplanning/getAllCarPlanRequests").then(async(response)=>{
        var respData = response.data;
        if(respData.length > 0)
        {
            var processed = respData[0].processed;
            if(processed == 1)
            {
                var lastRunRowId = respData[0]._id;
                var lastRunTime = respData[0].createDate;
                await this.setState({
                    lastRunRowId : lastRunRowId,
                    lastRunTime : lastRunTime,
                });
                this.countDownTimer();
            }
            else
            {                
                clearInterval(this.timer);
                this.setState({
                    seconds : 0,
                })
            }
        }
        //console.log(respData,"loadprocess");
        this.setState({
            rowData : respData,
            overly:"show-n",
            loadshow:"show-n",
        })
    }).catch((e)=>{
        console.log(e);
    })
}

countDownTimer = async() => {
    var lastRunRowId = this.state.lastRunRowId;
    var lastRunTime = this.state.lastRunTime;
    var currentDate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
    var startdate = moment.parseZone(lastRunTime).format("YYYY-MM-DD HH:mm:ss")
    var diffreetime = moment.duration(moment(currentDate).diff(startdate));
    var runseconds = Math.round(diffreetime.asSeconds());
    console.log(runseconds,lastRunTime,currentDate,lastRunRowId,"runseconds");
    if(parseInt(runseconds) < 120)
    {
        $("#timerDiv").show();
        this.startTimer();
    }
    else
    {
        $("#timerDiv").hide();
    }
}

secondsToTime(secs){
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
}

startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
}

countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });
    if(this.state.seconds == 30 || this.state.seconds == 60 || this.state.seconds == 90)
    {
        //console.log(this.state.seconds,"this.state.seconds")
        this.onLoadPageData();
    }
    // Check if we're at zero.
    if (seconds == 0) { 
        clearInterval(this.timer);
        $("#timerDiv").hide();
        this.onLoadPageData();
    }
}

showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate"
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        loadFile:''
    });
    $("#notify_email").val("");
}
fixProcess = () => {
    this.setState({
        processErrMsg : "",
        processErrShow : 0,
        loadFile:''
    });
    $("#notify_email").val("");
}
ignoreProcessError = () => {
    var transactionId = this.state.transactionId;
    console.log("transactionId",transactionId)
    var params = {
        transactionId : transactionId,
    }
    redirectURL.post("/lmconsignees/generateLoadByTransactionId",params).then((response)=>{
        //console.log(response.data,"response")
        var statusCode = response.data.status;
        if(statusCode == 0)
        {
            this.setState({                    
                basicTitle : response.data.message,
                basicType : "success",
                show : true,
            });
        }
        else
        {
            this.setState({
                processErrMsg : response.data.message,
                processErrShow : 1,
                transactionId : response.data.transactionId,
            });
        }
        //window.location.reload();
    });
}
changeFileHandler = async (e) => {
    console.log(e);
    this.setState({
        loadFile : e.target.files[0]
    });    
}

uploadBulkFormHandler(event){
    event.preventDefault();
    var flag = 0;
    let saveData = new FormData();
    if(flag == 0)
    {      
        saveData.append("loadFile", this.state.loadFile);
        var notify_email = $("#notify_email").val();
        saveData.append("notify_email", notify_email);
        var loading_port_code = this.state.loading_port_code;
        saveData.append("loading_port_code", loading_port_code)
        redirectURL.post("/lmconsignees/generateLoad",saveData).then((response)=>{
            console.log(response.data,"response")
            var statusCode = response.data.statusCode;
            if(statusCode == 0)
            {
                this.setState({
                    basicTitle : response.data.message,
                    basicType : "success",
                    show : true,
                });
            }
            else
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                });
            }
            //window.location.reload();
        });
    }
    else
    {
        this.setState({
            basicTitle : "Please upload the file",
            basicType : "warning",
            show : true,
        })
    }
};
    
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
    * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
            
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    this.setState({
        show: false
    });
}

onCellClickedGrid(e)
{
    if(e.colDef.field == "filename")
    {
        var inputFile = e.data.filename;
        console.log(inputFile)
        if(inputFile !='')
        {
            var rngParam = {
                inputFile : inputFile,
            }
            redirectURL.post("/lmconsignees/downloadInputFile",rngParam).then((response)=>{
                console.log("download response ",response)
                download(response.data, inputFile)
                if(response.data.message == "success")
                {
                    alert("success");
                }
                
            }).catch(function(error){
                console.log(error);
            })
        }
    }
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}
onChangeLoadingPlant = (e) =>{
    this.setState({
        loadshow:'show-m',
        overly:'show-m',
    })
    var selectedPlant = $("#loadingPlant").val();
    var params = {
        selectedPlant : selectedPlant,
    }
    redirectURL.post("/lmconsignees/getLoadingPlantDetails",params)    
    .then((response) => {
        var res = response.data;
        this.setState({
            loading_port_code : selectedPlant,
            comp_code : res[0].comp_code,
            loadshow:'show-n',
            overly:'show-n',
        })
        this.pageAccess();
    })
    .catch(function(e){
        console.log("Error ",e)
    })
} 
  render() {
    var showActions = false;
    var showDelete = true;
    if(this.state.pageAccess == 2)
    {
        var showActions = true;
    }
    if(this.state.pageAccess == 3)
    {
        var showDelete = false;
    }
    var columnwithDefs = [      
        {
            headerName: "Generated On",
            field: "createDate",
            width:140,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter:function(params){
                if(params.data.createdon != ''){
                return getHyphenDDMMMYYYYHHMM(params.data.createDate);
                }
                else{
                return "";
                }
            },
        },
        {
            headerName: "Transaction Id",
            field: "transaction_id",
            width:140,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Transporter Name",
            field: "transporter_name",
            width:250,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Rake Type",
            field: "rake_type",
            width:100,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter:function(params){
                if(params.data.rake_type == "old")
                {
                    return "50mm Pitch";
                }
                else{
                    return "25mm Pitch";
                }
            }
        },
        {
            headerName: "Loading Plant",
            field: "loading_plant",
            width:120,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Status",
            field: "data_processed",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter:function(params){
                if(params.data.data_processed == 0)
                {
                    return "Processing";
                }
                else if(params.data.data_processed == 1)
                {
                    return "SOLVER_COMPLETE";
                }
                else if(params.data.data_processed == 2)
                {
                    return "SOLVER_RECO_PROCESSED";
                }
                else if(params.data.data_processed == 3)
                {
                    return "SOLVER_SOB_DONE";
                }
                else if(params.data.data_processed == 4)
                {
                    return "SOLVER_INVALID_INPUT_FILE_NAME";
                }
                else if(params.data.data_processed == 5)
                {
                    return "SOLVER_NO_ASSIGNMENT_FILE";
                }
                else if(params.data.data_processed == 6)
                {
                    return "SOLVER_INVALID_CPP_EXE_FILE";
                }
                else if(params.data.data_processed == 7)
                {
                    return "SOLVER_INPUT_DEALERS_NOT_VALID";
                }
                else if(params.data.data_processed == 8)
                {
                    return "SOLVER_FIX_DROPED_DEALERS";
                }
                else if(params.data.data_processed == 9)
                {
                    return "SOLVER_VEHICLE_DETAILS_MISSING";
                }
                else if(params.data.data_processed == 10)
                {
                    return "SOLVER_INPUT_FILE_DATA_ERROR";
                }
                else if(params.data.data_processed == 11)
                {
                    return "SOLVER_INVALID_FILE_TEMPLATE";
                }
                else if(params.data.data_processed == 12)
                {
                    return "PROCESS_TIMED_OUT";
                }
            }
        },
        {
            pinned:"right",
            headerName : "Edit",
            field:"transaction_id",
            width:120,
            resizable : true,
            editable : false,
            cellRenderer:function(params){
                let basestring = params.data.transaction_id;
                let encryptedstring = window.btoa(basestring);
                var htmloption = '<a href="/carsplanning/'+encryptedstring+'" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
                if(params.data.data_processed == 1 || params.data.data_processed == 2 || params.data.data_processed == 3)
                {
                    return htmloption;
                }
                else
                {                    
                    return "N/A";
                }
            } 
        },
        // {
        //     headerName: "Remarks",
        //     field: "remarks",
        //     width:170,
        //     filter: true,
        //     resizable: true,
        //     editable:false
        // },
        {
            pinned:"left",
            headerName : "View Details",
            field:"transaction_id",
            width:120,
            resizable : true,
            editable : false,
            cellRenderer:function(params){
                let basestring = params.data.transaction_id;
                let encryptedstring = window.btoa(basestring);
                var htmloption = '<a href="/viewCarSummaryDetails/'+encryptedstring+'" class="custom-btn label label-success"><i class="icofont icofont-eye"></i> View</a>';
                if(params.data.data_processed == 1 || params.data.data_processed == 2 || params.data.data_processed == 3)
                {
                    return htmloption;
                }
                else
                {                    
                    return "N/A";
                }
            } 
        }
    ]
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="card col-xl-12">
            <div class="card-header">
                <h5>
                    <i className="icofont icofont-car cus-i"></i> <span>Car Plan Summary</span>
                    <a href="/carsplanning" className="btn btn-info" style={{float:"right"}}>Car Plan</a>
                    {(this.state.seconds > 0) ?
                    <span id="timerDiv" style={{float:"right",marginRight:"2%",marginTop:"8px"}}>Plan Will Generate in - <span style={{color:"red"}}>0{this.state.time.m} : {this.state.time.s}</span></span>
                    :""}
                </h5>
            </div>
            <div className="card-body col-xl-12 col-lg-12">
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.showGridData}>    
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClickedGrid.bind(this)}
                    />
            </div>
            </div>
        </div>
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">Imports Orders Data</h3>
            <div className="slide-r-body" style={{position:"relative"}}>                
                <div className="container-fluid">
                    {this.state.processErrShow == 0 ?
                        <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>
                        
                        <div className="form-group mt-20p">
                            <label className="">Upload File</label> 
                            <input type="file" name="uploadFile" id="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
                        </div>
                        <div className="form-group mt-20p">
                            <label className="">Notify Email Users After Process (comma seperated)</label> 
                            <textarea name="notify_email" id="notify_email" className="form-control"></textarea>
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn btn-success">Generate Load</button>
                            <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                        </div>
                        </form>
                    :
                    <div className="" style={{marginTop:"1em"}}>
                        <p style={{marginBottom:"1em"}}>{this.state.processErrMsg}</p>
                        <button type="button"  onClick={this.fixProcess.bind(this)} className="btn btn-success">Fix and Reupload</button>
                        <button type="button" onClick={this.ignoreProcessError.bind(this)} className="btn btn-default">Ignore</button>
                    </div>
                    }
                </div>
            </div>
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
        </div>

    );
  }
}
// CustomTextFilter.jsx
import React, { Component } from 'react';

class CustomTextFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValues: this.props.uniqueValues,
      searchText: '',
      selectAll: true
    };
  }

//   componentDidUpdate(prevProps) {
//     // Check if the filterText has changed
//     if (this.state.filterText !== prevProps.filterText) {
//       // Notify the parent component that the filter has changed
//       this.props.onFilterChanged(this.state.filterText, this.props.colDef.field, this.props);
//     }
//   }

    onFilterChanged = () => {
        // Custom logic when the filter text changes
        // this.setState({ filterText: this.state.filterText.toLowerCase() });
        this.props.onFilterChanged(this.state.selectedValues, this.props.colDef.field);
    };

    handleCheckboxChange = (value) => {
        // Update selected values when a checkbox is clicked
        const selectedValues = this.state.selectedValues.includes(value)
        ? this.state.selectedValues.filter((v) => v !== value)
        : [...this.state.selectedValues, value];

        this.setState({ selectedValues }, this.onFilterChanged);
    };

    handleSelectAll = (e) => {
        this.setState((prevState) => ({ selectAll: !prevState.selectAll }));
        console.log(this.state.selectAll, e,)
        if(!this.state.selectAll){
            const allValues = this.props.uniqueValues;
            this.setState({ selectedValues: allValues }, this.onFilterChanged);
        }else{
            this.setState({ selectedValues: [] }, this.onFilterChanged);
        }
        
      };

    handleSearchInputChange = (e) => {
        // Handle changes in the search input
        this.setState({ searchText: e.target.value.toLowerCase() });
    };

    render() {
        let uniqueValues = this.props.uniqueValues;
        uniqueValues = uniqueValues.filter(e => e != "")
        const filteredValues = uniqueValues.filter((value) =>{
            if(this.state.searchText != ""){
                return value.toLowerCase().includes(this.state.searchText)
            }else{return true}
        });

        return (
            <div>
                <input
                    type="text"
                    placeholder="search.."
                    value={this.state.searchText}
                    onChange={this.handleSearchInputChange}
                    className="ag-filter-filter"
                />
                <label className="ag-set-filter-item">
                    <div className="ag-filter-checkbox">
                        <input
                        type="checkbox"
                        checked={this.state.selectAll}
                        onChange={this.handleSelectAll}
                        />
                    </div>
                    <span className="ag-filter-value">Select All</span>
                </label>
                {filteredValues.map((value) => 
                
                    <label key={value} className='ag-set-filter-item'>
                        <div className='ag-filter-checkbox'>
                            <input
                                type="checkbox"
                                checked={this.state.selectedValues.includes(value)}
                                onChange={() => this.handleCheckboxChange(value)}
                            />
                        </div>
                        <span className='ag-filter-value'>{value}</span>
                    </label>
                )}
        
            </div>);
    }
}

export default CustomTextFilter;

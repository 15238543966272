import React, { Component } from 'react';
import Highcharts from 'highcharts';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';

drilldown(Highcharts);

export default class SndUnloadingColumnChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showgrid: 'display:none',
      showtransgrid: 'display:none',
      rowdata: [],
      test: 'Test',
      chart_title: this.props.title || ''
    };
  }

  componentDidMount() {
    this.initializeChart();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.title !== this.props.title) {
      this.setState({ chart_title: this.props.title });
    }
  }

  initializeChart = () => {
    const { code } = this.props;
    $(`.grid-${code}`).hide();
    $(`.grid-trans-${code}`).hide();
    $(`.dataLoadpagesum${code}`).hide();
    $(`.dataLoadpageimgsum${code}`).hide();
    $(`.overlay-partsum${code}`).hide();
  };

  handleChartClick = (event) => {
    const { context, title } = this.props;
    const pointName = event.point.name;

    if (context && typeof context.onClickGetGridData === 'function') {
      context.onClickGetGridData(title, pointName);
    }

    // Show/hide specific grids based on the chart title
    const { chart_title } = this.state;
    switch (chart_title) {
      case 'Route wise Avg. Dead Mile (Other OEM) (KM)':
        $('#redmsd').hide();
        $('#redmsdGrid').show();
        break;
      case 'Route wise avg Empty Return Trips (To MSIL) (KM)':
        $('#redmsdet').hide();
        $('#redmsdetGrid').show();
        break;
      case 'Route wise Avg. Dead Mile Distance (Summation)':
        $('#redmsds').hide();
        $('#redmsdsGrid').show();
        break;
      case 'Cumulated Wt. avg. Empty Running (To MSIL + Other OEM) (KM)':
        $('#redmsds').hide();
        $('#redmsdsGrid').show();
        break;
      default:
        break;
    }
  };

  render() {
    const { title, yaxistitle, seriesName, series, drilldownseries } = this.props;

    const highchartoptions = {
      chart: {
        type: 'column'
      },
      title: {
        text: title
      },
      subtitle: {
        text: ''
      },
      accessibility: {
        announceNewData: {
          enabled: true
        }
      },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        title: {
          text: yaxistitle
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click: this.handleChartClick
            }
          }
        },
        column: {
          dataLabels: {
            enabled: true
          },
          stacked: false,
          enableMouseTracking: true,
          cropThreshold: 500
        },
        line: {
          dataLabels: {
            enabled: true
          }
        }
      },
      tooltip: {
        formatter: function () {
          return `<span style='font-size:11px'>${this.series.name}</span><br>
                  <span style='color:${this.point.color}'>${this.point.name}</span>: <b>${this.point.y}</b></b><br>`;
        }
      },
      series: [
        {
          name: seriesName,
          colorByPoint: true,
          data: series
        }
      ],
      drilldown: drilldownseries,
      credits: false
    };

    return (
      <div>
        <HighchartsReact
          containerProps={{ style: { width: '100%', height: '100%' } }}
          highcharts={Highcharts}
          options={highchartoptions}
        />
      </div>
    );
  }
}

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";

import axios from 'axios';

import Grid from '../layouts/departmentComponent';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class ManageDepartments extends Component {

	constructor(props){
		super(props);
		this.state={
			pageTitle: "Manage Departments",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			rowData:null,
			uploadDivWidth:'0%',
			sliderTranslate:'',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default"
			
		}
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
	componentDidMount(){
		this.logPageView(); // Log page view to GA
		 /*Consignments List*/	
	    redirectURL.post('/departments/list')    
		  .then((response) => {
            var records = JSON.parse(JSON.stringify(response)).data;
            console.log("records", records);
            this.setState({
                rowData:records
            });
		  })
		  .catch(function (error) {
		    console.log(error);
            });
	};
	
	render(){
		const columnwithDefs = [
	  	        {
	  	          headerName: "Department Code",
	  	          field: "dept_code",
	  	          width: 300,
                  editable:false,
	    	      filter: "agSetColumnFilter"
		        },
		        {
		          headerName: "Department Alias Code",
                  field: "dept_code_alias",
                  width: 300,
                  filter: "agSetColumnFilter"	    	          
                },
		        {
		          headerName: "Department Name",
                  field: "dept_name",
                  width: 300,
                  filter: "agSetColumnFilter"	    	          
                },
		        
		        {
		          headerName: "Action",
		          field: "_id",
		          width: 100,
				  filter: false,
		          cellRenderer:'updateItem',
		          cellRendererParams: {
			            actionCall: "departments",
			            actionMethod:"updateDepartment"
			        },
			        editable:false
		        }
	    ];
		return(
			<div className="container-fluid">
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        > 
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pageTitle} </span>
                                </h5>
				   			</div>				   			
		            		<div className="card-body">
				   				   <Grid 
								   ashow={this.state.show} 
								   atype={this.state.basicType} 
								   atitle={this.state.basicTitle}
								   actionCall={"departments"}
									actionMethod={"updateDepartment"}  
									//table={"trucklocation"} 
									rowData={this.state.rowData} 
									columnwithDefs={columnwithDefs} 

									/>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				 <div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
					 <div className="slide-r-title">
				 		<h4>
                            Sidebars
                        </h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
						
				 		<div className="col-xl-12 col-lg-12">
				 		<div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg "+(this.state.alerterrshow)+" "+(this.state.alerterrmg)}>{this.state.csverrmessage}</div>
	        			
				 		</div>
				 	</div>
				 </div>
            </div>
              	
		);
	}
}
import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';
var redirectURL = require('../redirectURL');

export default class ConsignmentTrucksCountersComponent extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
    		records:[],
    		active_consignments:0,
    		overspeeding:0,
            night_driving:0,
            transit_delay:0,
            unloading_dealer:0,
            left_dealer:0,
            gps_data_available:0,
            nogpslifetime:0,
            deptcode:this.props.deptcode,
            active_trucks:0,
            loading_delay:0,
            unloading_delay:0,
            enroute_stoppage:0
            
    	}
    
    }
    componentWillReceiveProps(newprops){
        // if(newprops.deptcode != this.state.deptcode)
        // {
        
            var transitdelaysarr=[];
            var gpsdatanaarr=[];
            var overspeedtrucksarr=[];
            var nogpslifetimearr = [];
            var nightdrivearr=[];
            var enroutearr=[];
            var loadingarr=[];
            var unloadingarr=[];
            //console.log("newprops.gridData",newprops.gridData)
            if(newprops.gridData.length > 0)
            {
                transitdelaysarr = newprops.gridData.filter(rec => {
                    if (rec["transit_delay"]) {
                        return rec["transit_delay"] == 1;
                    }
                });
                gpsdatanaarr = newprops.gridData.filter(rec => {
                    if (rec["no_gps_data"]) {
                        return rec["no_gps_data"] == 1;
                    }
                });

                nogpslifetimearr = newprops.gridData.filter(rec => {
                    if (rec["no_gps_data_lifetime"]) {
                        return rec["no_gps_data_lifetime"] == 1;
                    }
                });               
               
                overspeedtrucksarr = newprops.gridData.filter(rec => {
                    if (rec["overspeeding_exception"]) {
                        return rec["overspeeding_exception"] == 1;
                    }
                });
                nightdrivearr = newprops.gridData.filter(rec => {
                    if (rec["nightdriving_exception"]) {
                        return rec["nightdriving_exception"] == 1;
                    }
                });
                unloadingarr = newprops.gridData.filter(rec => {
                    if (rec["unloading_delay_exception"]) {
                        return rec["unloading_delay_exception"] == 1;
                    }
                });
                loadingarr = newprops.gridData.filter(rec => {
                    if (rec["loading_delay_exception"]) {
                        return rec["loading_delay_exception"] == 1;
                    }
                });
                enroutearr = newprops.gridData.filter(rec => {
                    if (rec["enroute_exception"]) {
                        return rec["enroute_exception"] == 1;
                    }
                });
               // console.log("nogpslifetimearr ", nogpslifetimearr)
                this.setState({
                    active_trucks:newprops.gridData.length,
                    overspeeding:overspeedtrucksarr.length,
                    night_driving:nightdrivearr.length,
                    transit_delay:transitdelaysarr.length,
                    //unloading_dealer:counterdata.unloading_dealer,
                    //left_dealer:counterdata.left_dealer,
                    gps_data_available:gpsdatanaarr.length,
                    nogpslifetime:nogpslifetimearr.length,
                    //active_trucks:counterdata.active_trucks,
                    loading_delay:loadingarr.length,
                    unloading_delay:unloadingarr.length,
                    enroute_stoppage:enroutearr.length,
                });

            }
            else
            {
                this.setState({
                    active_trucks:0,
                    overspeeding:0,
                    night_driving:0,
                    transit_delay:0,
                    gps_data_available:0,
                    nogpslifetime:0,
                    loading_delay:0,
                    unloading_delay:0,
                    enroute_stoppage:0,
                });
            }


       // }
        

    }
    onClickCounter(data){
        this.props.context.onClickCounterShowData(data)
    }
    render(){
        return (
            <div className="row">

            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="card">
                    <div className="card-body">
                        <div className="crm-numbers pb-0">
                            <div className="row">
                                
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"all")}>
                                    <span className="f13"><i className="icofont icofont-truck f24 darkgreen"></i><br /> Active Trucks</span>
                                    <h4 className="darkgreen f50"><span className="counter"><CountUp end={(this.state.active_trucks != '')?this.state.active_trucks:0}/></span></h4>
                                    
                                </div>
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"transit_delay")}>
                                    <span className="f13"><i className="icofont icofont-clock-time f22 txt-danger"></i><br /> Transit Delays</span>
                                    <h4 className="txt-danger f50"><span className="counter"><CountUp end={(this.state.transit_delay != '')?this.state.transit_delay:0}/></span></h4>
                                    
                                </div>
                                <div className="col cursorPointer">
                                    <span className="f13"><i className="icofont icofont-social-google-map f24 txt-danger"></i><br /> GPS Not Available</span>
                                    <h4 className="txt-secondary f50">
                                        <span className="counter"  onClick={this.onClickCounter.bind(this,"no_gps_data")}>
                                            
                                            <CountUp className="txt-danger" end={(this.state.gps_data_available != '')?this.state.gps_data_available:0}/> 
                                        </span>
                                        <span className="f24">&nbsp;/&nbsp;</span>
                                        <span className="counter" onClick={this.onClickCounter.bind(this,"no_gps_data_lifetime")}>
                                            <CountUp  className="txt-secondary"  end={(this.state.nogpslifetime != '')?this.state.nogpslifetime:0}/>
                                        </span>  
                                    </h4>
                                </div> 
                                {/* <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"no_gps_data")}>
                                    <span className="f13"><i className="icofont icofont-social-google-map f24 txt-secondary"></i><br /> GPS Data NA</span>
                                    <h4 className="txt-secondary f50"><span className="counter"><CountUp end={(this.state.gps_data_available != '')?this.state.gps_data_available:0}/></span></h4>
                                    
                                </div> */}
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"overspeeding_exception")}>
                                    <span className="f13"><i className="fa fa-tachometer f22 txt-success"></i><br /> Overspeeding</span>
                                    <h4 className="txt-success f50"><span className="counter"><CountUp end={(this.state.overspeeding != '')?this.state.overspeeding:0}/></span></h4>
                                   
                                </div>
                                {(this.props.deptcode == 'LOG-PRT')?"":
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"nightdriving_exception")}>
                                    <span className="f13"><i className="icofont icofont-full-night f24 txt-primary"></i><br /> Night Driving</span>
                                    <h4 className="txt-primary f50"><span className="counter"><CountUp end={(this.state.night_driving != '')?this.state.night_driving:0}/></span></h4>
                                    
                                </div>
                                }
                                 {(this.props.deptcode == 'LOG-PRT')?"":
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"loading_delay_exception")}>
                                    <span className="f13"><i className="icofont icofont-truck-loaded f24 darkorange"></i><br /> Loading Delay</span>
                                    <h4 className="darkorange f50"><span className="counter"><CountUp end={(this.state.loading_delay != '')?this.state.loading_delay:0}/></span></h4>
                                </div>
                                }
                                 {(this.props.deptcode == 'LOG-PRT')?"":
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"unloading_delay_exception")}>
                                    <span className="f13"><i className="icofont icofont-vehicle-delivery-van f24 mildgreen"></i><br /> Unloading Delay</span>
                                    <h4 className="mildgreen f50"><span className="counter"><CountUp end={(this.state.unloading_delay != '')?this.state.unloading_delay:0}/></span></h4>
                                </div>
                                }
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"enroute_exception")}>
                                    <span className="f13"><i className="icofont icofont-pause f22 maroon"></i><br /> Stoppages</span>
                                    <h4 className="maroon f50"><span className="counter"><CountUp end={(this.state.enroute_stoppage != '')?this.state.enroute_stoppage:0}/></span></h4>                                   
                                </div>
                                
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            
            </div>
        );
    }
}







import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import CSVFileValidator from 'csv-file-validator';
import Map from '../common/drawmap';
import FileBase64 from 'react-file-base64';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import download from 'js-file-download';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var redirectURL  = require('../redirectURL');

export default class CreateFFCargoEvent extends Component{
    constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            csvcontent:"",
            file : "",
            loadshow:'show-n',
            loadetdshow:'show-n',
            loadatdshow:'show-n',
            overly:'show-n',
            uploadYard : "",
            uploadDispatchDate : "",
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            pagetitle:"",
            bulkslide:"",
            cargoBookingId:"",
            airCargoFFId:"",
            other_docs:"",
            file:"",
            loadshow : "show-n",
            hiddenotherdocs : "",
            hiddeninvoice : "",
            ringi_file : "",
            other_docs : "",
            erpData : [{}],
            cargoData : [{}],
            ringiData: [{}],
            airlines: [],
            deliveryTerms:"Ex Works",
            pagetype: 1,
            emailToChaTransporter:"",
            emailToChaTransporterName:""
            //components : {datepicker:getDatePicker()}
        }
        this.changeHandler = this.changeHandler.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        if (googleAnalytics.page.enableGA) {
			this.logPageView();
		}
        redirectURL.post("/consignments/getAirCargosAirlines").then((response)=>{
            this.setState({
                airlines : response.data
            });
        })
    }

    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
    }
    
closeAlert = () => {
    this.setState({
        show: false
    });
    var pagetype = this.state.pagetype;
    if(pagetype == 1)
    {
        window.location.href="/ffaircargos";
    }
    else
    {
        window.location.href="/aircargos";
    }
}
changeHandler = (event) => {
    console.log("Select", event.target.value);
    let name = event.target.name;
    console.log(name);
    let value = event.target.value;
    this.setState({[name]:value});
    if(name == "direct_flight")
    {   
        if(value == "yes")
        {
            this.setState({
                loadshow : "show-n"
            })
        }else{
            this.setState({
                loadshow : ""
            })
        }
    }
   
}
changeFileHandler = async (e) => {
    console.log(e.target.name)
    this.setState({ [e.target.name]: e.target.files[0] });
}

changeDocFileHandler = (e) => {
    console.log(e);
    this.setState({
        other_docs : e.target.files[0]
    });
    console.log(e)
}

handleAddRingiRow = () => {
    const item = {
        ringi_file: "",
    };
    this.setState({
        ringiData: [...this.state.ringiData, item]
    });
};
handleRemoveRingiRow = (idx) => {
    this.state.ringiData.splice(idx, 1);
    this.setState({ ringiData: this.state.ringiData });
    var ringiFile = $("#hiddenRingiFile_"+idx).val();
    console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/removeRingiFile",rngParam).then((response)=>{
        }).catch(function(error){
            console.log(error);
        })
    }
};

handleRemoveRingiFile = (idx) => {
    var ringiFile = $("#hiddenRingiFile_"+idx).val();
    console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/removeRingiFile",rngParam).then((response)=>{
            $(".rnFile_"+idx).html("");
            this.hide();
        }).catch(function(error){
            console.log(error);
        })
    }
}

handleDownloadRingiFile = (idx) => {
    var ringiFile = $("#hiddenRingiFile_"+idx).val();
    //console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/downloadRingiFile",rngParam,{
            responseType: 'blob'
        }).then((response)=>{
            console.log("dowlad response ",response)
            download(response.data, ringiFile)
        }).catch(function(error){
            console.log(error);
        })
    }
}

onChangeChaCode=(event)=>{
    console.log(event,"278")
    this.setState({emailToChaTransporter:event.value,emailToChaTransporterName:event.label})
}

formHandler(event){
    event.preventDefault();
    var pickupdate = $("#pickup_date").val();
    if(pickupdate == "__-__-____" || pickupdate == undefined)
    {
        var pickupdate="";
    }
    var etd_port_date = $("#etd_port_date").val();
    if(etd_port_date == "__-__-____" || etd_port_date == undefined)
    {
        var etd_port_date="";
    }
    var atd_port_date = $("#atd_port_date").val();
    if(atd_port_date == "__-__-____" || atd_port_date == undefined)
    {
        var atd_port_date="";
    }
    var eta_igi_airport_date = $("#eta_igi_airport_date").val();
    if(eta_igi_airport_date == "__-__-____" || eta_igi_airport_date == undefined)
    {
        var eta_igi_airport_date="";
    }
    var ata_igi_airport_date = $("#ata_igi_airport_date").val();
    if(ata_igi_airport_date == "__-__-____" || ata_igi_airport_date == undefined)
    {
        var ata_igi_airport_date="";
    }
    var eta_transit_port_date = $("#eta_transit_port_date").val();
    if(eta_transit_port_date == "__-__-____" || eta_transit_port_date == undefined)
    {
        var eta_transit_port_date="";
    }
    var ata_transit_port_date = $("#ata_transit_port_date").val();
    if(ata_transit_port_date == "__-__-____" || ata_transit_port_date == undefined)
    {
        var ata_transit_port_date="";
    }
    var etd_transit_port_date = $("#etd_transit_port_date").val();
    if(etd_transit_port_date == "__-__-____" || etd_transit_port_date == undefined)
    {
        var etd_transit_port_date="";
    }
    var atd_transit_port_date = $("#atd_transit_port_date").val();
    if(atd_transit_port_date == "__-__-____" || atd_transit_port_date == undefined)
    {
        var atd_transit_port_date="";
    }
    var hawb_date = $("#hawb_date").val();
    if(hawb_date == "__-__-____" || hawb_date == undefined)
    {
        var hawb_date="";
    }
    var cargo_reciving_date = $("#cargo_reciving_date").val();
    if(cargo_reciving_date == "__-__-____" || cargo_reciving_date == undefined)
    {
        var cargo_reciving_date="";
    }
    var counterpart_details = $("#counterpart_details").val();
    var airlines_name = $("#airlines_name").val();
    var direct_flight = $("#direct_flight").val();
    var port_name = $("#port_name").val();
    var if_etd_atd_not_same = $("#if_etd_atd_not_same").val();
    var if_atd_ata_not_same = $("#if_atd_ata_not_same").val();
    var mawb_no = $("#mawb_no").val();
    var hawb_no = $("#hawb_no").val();
    var no_of_pkt = $("#no_of_pkt").val();
    var gross_wt = $("#gross_wt").val();
    var chargeble_weight = $("#chargeble_weight").val();
    var invoice_no = $("#invoice_no").val();
    //var invoice_currency = $("#invoice_currency").val();
    var airfreight_amount = $("#airfreight_amount").val();
    var fca_exw_amount = $("#fca_exw_amount").val();
    var airfreight_currency = $("#airfreight_currency").val();
    var hidden_invoice_value = $("#hidden_invoice_value").val();
    var hidden_other_docs = $("#hidden_other_docs").val();

    var supplier_name = $("#supplier_name").val();
    let saveData = new FormData();
    //console.log("file ",this.state.file)
    
    var rngLength=$("#rngLength").val();
    console.log(rngLength,"rngLength")
    saveData.append("rngLength", rngLength);
    saveData.append("file", this.state.ringi_file);
    var ringiData=[];
    for(var r=0;r<rngLength;r++)
    {
        var ringiFile=$("#ringi_file_"+r).val();
        ringiFile = ringiFile.substring(ringiFile.lastIndexOf("\\") + 1, ringiFile.length);
        if(ringiFile !='')
        {
            var rngtemp = {"invoice_file":ringiFile}
            ringiData.push(rngtemp)
        }
        else
        {
            var ringiFile=$("#hiddenRingiFile_"+r).val();
            var rngtemp = {"invoice_file":ringiFile}
            ringiData.push(rngtemp)
        }
    }
    ringiData=JSON.stringify(ringiData);
    console.log(ringiData,"ringiData")
    saveData.append("other_docs", this.state.other_docs);
    for(var rn=0;rn<rngLength;rn++)
    {
        var ringiFile="ringi_file_"+rn;
        
        saveData.append(ringiFile,this.state[ringiFile]);
        console.log(this.state[ringiFile]);

        var hdringiFile = $("#hiddenRingiFile_"+rn).val();
        var hdringiName = "hiddenRingiFile_"+rn;
        saveData.append(hdringiName,hdringiFile);
        console.log(hdringiFile);
    }
    saveData.append("job_no",this.state.job_no);
    saveData.append("cargo_booking_id", this.state.cargoBookingId);
    saveData.append("pickup_date", pickupdate);
    saveData.append("counterpart_details", counterpart_details);
    saveData.append("airlines_name", airlines_name);
    saveData.append("direct_flight", direct_flight);
    saveData.append("port_name", port_name);
    saveData.append("etd_port_date", etd_port_date);
    saveData.append("atd_port_date", atd_port_date);
    saveData.append("eta_igi_airport_date", eta_igi_airport_date);
    saveData.append("ata_igi_airport_date", ata_igi_airport_date);
    saveData.append("eta_transit_port_date", eta_transit_port_date);
    saveData.append("ata_transit_port_date", ata_transit_port_date);
    saveData.append("etd_transit_port_date", etd_transit_port_date);
    saveData.append("atd_transit_port_date", atd_transit_port_date);
    saveData.append("if_etd_atd_not_same", if_etd_atd_not_same);
    saveData.append("if_atd_ata_not_same", if_atd_ata_not_same);
    saveData.append("mawb_no", mawb_no);
    saveData.append("hawb_no", hawb_no);
    saveData.append("hawb_date", hawb_date);
    saveData.append("no_of_pkt", no_of_pkt);
    saveData.append("gross_wt", gross_wt);
    saveData.append("chargeble_weight", chargeble_weight);
    saveData.append("invoice_no", invoice_no);
    saveData.append("ringiData",ringiData);
    //saveData.append("invoice_currency", invoice_currency);
    saveData.append("airfreight_amount", airfreight_amount);
    saveData.append("fca_exw_amount", fca_exw_amount);
    saveData.append("airfreight_currency", airfreight_currency);
    saveData.append("cargo_reciving_date", cargo_reciving_date);
    saveData.append("airCargoFFId", this.state.airCargoFFId);
    saveData.append("hidden_invoice_value",hidden_invoice_value);
    saveData.append("hidden_other_docs",hidden_other_docs);
    saveData.append("updated_by_email",localStorage.getItem("email"));
    saveData.append("cargo_delivery_plant",this.state.cargoDeliveryPlant);
    saveData.append("supplier_name",supplier_name);
    saveData.append("country",this.state.country);
    saveData.append("delivery_terms",this.state.deliveryTerms);
    saveData.append("pick_up_address",this.state.pickUpAddr);
    saveData.append("port",this.state.port);
    saveData.append("airport_name",this.state.airportName);
    var ff_code = localStorage.getItem("supplier_code");
    saveData.append("ff_code",ff_code);
    var ff_name = localStorage.getItem("username");
    saveData.append("ff_name",ff_name);
    saveData.append("email_to_cha_transporter",this.state.emailToChaTransporter);

    console.log(saveData,"444");
    
    redirectURL.post("/consignments/savenewffCargoData",saveData,{
        headers:{
            'content-type': 'multipart/form-data'
        }
    }).then((response)=>{   
        this.setState({
            basicTitle:"Success",
            basicType : "success",
            show : true
        });
    }).catch((e)=>{
        console.log(e);
    })
        
    // }
    // else
    // { 
    //     this.setState({
    //         basicTitle:"Fields Should not be Empty",
    //         basicType : "warning",
    //         show : true
    //     })
    // }
}



uploadFormHandler(event){
    event.preventDefault();
    
    var formData = new FormData(event.target);
    formData.append('uploadFile',this.state.file);
    
    console.log('file',formData);
    redirectURL.post("/consignments/saveffCargoData",formData,{
        headers:{
            'content-type': 'multipart/form-data'
        }
    }).then((response)=>{   
        this.setState({
            basicTitle:"Success",
            basicType : "success",
            show : true
        });
        // window.location.reload();
    }).catch((e)=>{
        console.log(e);
    })
}


render(){
        var edit_option=false 
        if (localStorage.getItem("username")=="Yamato Logistics India Pvt Ltd" || localStorage.getItem("username")=="Nanda Kishore"){
            edit_option=true
        }

        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Create New Booking Request</span>
								</h5>
				   			</div>
                            <div className="card-body pt-15px">
                                <form class="aircargo-form" onSubmit={this.formHandler.bind(this)} encType="multipart/form-data">                                    
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Cargo Delivery Plant : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="cargoDeliveryPlant" required onChange={this.changeHandler}>
                                                <option value="">-- Select Cargo Delivery Plant --</option>
                                                <option value="G">G</option>
                                                <option value="M">M</option>
                                                <option value="MPT">MPT</option>
                                                <option value="R">R</option>
                                                <option value="HO">HO</option>
                                                <option value="B">B</option>
                                            </select>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Supplier Name : </label>
                                            <input className="form-control" name="supplier_name" id="supplier_name" type="text" value={this.state.supplier_name} autoComplete='off' />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Country* : </label>
                                            <select name="country" className="form-control"  onChange={this.changeHandler} required>
                                                <option value="">Select Country</option>
                                                <option value="Algeria">Algeria</option>
                                                <option value="Australia">Australia</option>
                                                <option value="Austria">Austria</option>
                                                <option value="Belgium">Belgium</option>
                                                <option value="Canada">Canada</option>
                                                <option value="Czech Republic">Czech Republic</option>
                                                <option value="China">China</option>
                                                <option value="Denmark">Denmark</option>
                                                <option value="Dubai">Dubai</option>
                                                <option value="France">France</option>
                                                <option value="Germany">Germany</option>
                                                <option value="Hong Kong">Hong Kong</option>
                                                <option value="Hungary">Hungary</option>
                                                <option value="Indonesia">Indonesia</option>
                                                <option value="Italy">Italy</option>
                                                <option value="Japan">Japan</option>
                                                <option value="Malaysia">Malaysia</option>
                                                <option value="Myanmar">Myanmar</option>
                                                <option value="Nigeria">Nigeria</option>
                                                <option value="Netherlands">Netherlands</option>
                                                <option value="Poland">Poland</option>
                                                <option value="Romania">Romania</option>
                                                <option value="S. Korea">S. Korea</option>
                                                <option value="Serbia">Serbia</option>
                                                <option value="Singapore">Singapore</option>
                                                <option value="Slovakia">Slovakia</option>
                                                <option value="Spain">Spain</option>
                                                <option value="Sweden">Sweden</option>
                                                <option value="Switzerland">Switzerland</option>
                                                <option value="Taiwan">Taiwan</option>
                                                <option value="Thailand">Thailand</option>
                                                <option value="Turkey">Turkey</option>
                                                <option value="UK">UK</option>
                                                <option value="USA">USA</option>
                                                <option value="Vietnam">Vietnam</option>
                                            </select> 
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Delivery Terms* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="deliveryTerms"  onChange={this.changeHandler}  required>
                                                <option value="">-- Select Delivery Terms --</option>
                                                <option value="Ex Works">Ex Works</option>
                                                <option value="FCA">FCA</option>
                                                <option value="FOB">FOB</option>
                                                <option value="CIF">CIF</option>
                                            </select>
                                        </div>
                                        {(this.state.deliveryTerms == "Ex Works")?
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12">Pick Up Address* : </label>
                                                <textarea className="form-control" name="pickUpAddr"  onChange={this.changeHandler} />
                                            </div>
                                        :(this.state.deliveryTerms == "FCA")?
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12">Place / Port : </label>
                                                <input type="text" className="form-control" name="port" autoComplete='off'  onChange={this.changeHandler} />
                                            </div>
                                        :
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12">Airport Name : </label>
                                                <input autoComplete='off' type="text" className="form-control" name="airportName"  onChange={this.changeHandler} />
                                            </div>
                                        }
                                    </div>
                                    
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Airlines Name : </label>
                                            <select name="airlines_name" id="airlines_name" className="form-control"  onChange={this.changeHandler}>
                                                <option value="">Select Airlines</option>
                                                {this.state.airlines.map(function(m){
                                                    return <option value={m.airlines_name}>{m.airlines_name}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Direct Flight : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="direct_flight" id="direct_flight"  onChange={this.changeHandler}>
                                                <option value="">-- Select Option --</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No (In direct Flight)</option>
                                            </select>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Port Name: </label>
                                            <input autoComplete='off' type="text" name="port_name" id="port_name" className="form-control"  onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>

                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">ETD Port date : </label>
                                            <input autoComplete='off' type="text" name="etd_port_date" id="etd_port_date" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">ATD Port Date : </label>
                                            <input autoComplete='off' type="text" name="atd_port_date" id="atd_port_date" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-3 "+(this.state.loadshow)}>
                                            <label className="col-xl-12 col-lg-12">ETA Transit Port Date : </label>
                                            <input autoComplete='off' type="text" name="eta_transit_port_date" id="eta_transit_port_date" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-3 "+(this.state.loadshow)}>
                                            <label className="col-xl-12 col-lg-12">ATA Transit Port Date : </label>
                                            <input autoComplete='off' type="text" name="ata_transit_port_date" id="ata_transit_port_date" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-3 "+(this.state.loadshow)}>
                                            <label className="col-xl-12 col-lg-12">ETD Transit Port Date : </label>
                                            <input autoComplete='off' type="text" name="etd_transit_port_date" id="etd_transit_port_date" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-3 "+(this.state.loadshow)}>
                                            <label className="col-xl-12 col-lg-12">ATD Transit Port Date : </label>
                                            <input autoComplete='off' type="text" name="atd_transit_port_date" id="atd_transit_port_date" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>                                      
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">ETA IGI Airport Date : </label>
                                            <input  autoComplete='off' type="text" name="eta_igi_airport_date" id="eta_igi_airport_date" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">ATA IGI Airport Date : </label>
                                            <input autoComplete='off' type="text" name="ata_igi_airport_date" id="ata_igi_airport_date" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                   
                                    <div className="row col-xl-12">
                                        <div id="etdSame" className={"col-xl-6 "+(this.state.loadetdshow)}>
                                            <label className="col-xl-12 col-lg-12">IF ETD and ATD not same : </label>
                                            <textarea name="if_etd_atd_not_same" id="if_etd_atd_not_same" className="form-control"  onChange={this.changeHandler}></textarea>
                                        </div>
                                        <div id="atdSame" className={"col-xl-6 "+(this.state.loadatdshow)}>
                                            <label className="col-xl-12 col-lg-12">If ETA and ATA not same : </label>
                                            <textarea name="if_atd_ata_not_same" id="if_atd_ata_not_same" className="form-control" onChange={this.changeHandler}></textarea>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>

                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">MAWB No : </label>
                                            <input autoComplete='off' type="text" name="mawb_no" id="mawb_no" className="form-control"  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">HAWB No : </label>
                                            <input autoComplete='off' type="text" name="hawb_no" id="hawb_no" className="form-control"  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">HAWB date : </label>
                                            <input autoComplete='off' type="text" name="hawb_date" id="hawb_date" placeholder="" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">No. of pkt : </label>
                                            <input autoComplete='off' type="text" name="no_of_pkt" id="no_of_pkt" className="form-control"  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Gross wt(In Kgs) : </label>
                                            <input autoComplete='off' type="text" name="gross_wt" id="gross_wt" className="form-control" placeholder="In Kgs"  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Chargeable Weight(In Kgs) : </label>
                                            <input autoComplete='off' type="text" name="chargeble_weight" id="chargeble_weight" placeholder="in kg" className="form-control"  onChange={this.changeHandler} />
                                        </div>                                    
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Invoice No : </label>
                                            <input autoComplete='off' type="text" className="form-control" name="invoice_no" id="invoice_no"  onChange={this.changeHandler} />
                                        </div>
                                        {this.state.ringiData.map((item, idx) => (
                                        <div className="col-xl-4" key={idx}>
                                            <div className="col-xl-12">
                                                <label className="col-xl-12 col-lg-12">Pre alert documents (Invoice AWD &#38; Tracking) : </label>
                                                <input autoComplete='off' type="file" name={"ringi_file_"+idx} id={"ringi_file_"+idx} className="form-control" onChange={this.changeFileHandler} />

                                                {((item.invoice_file != "") && (item.invoice_file != undefined)) ?  
                                                <div className="row col-xl-12">
                                                    <div className="col-xl-9">
                                                        <a className={"rnFile_"+idx}>{item.invoice_file}</a> 
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile_"+idx} style={{cursor:"pointer"}} onClick={() => this.handleDownloadRingiFile(idx)}><i title="Download" className="fa fa-download"></i></p>
                                                        
                                                        {/* <a target="_blank" href={require("../../../../api/routes/uploads/"+(item.invoice_file))}>Test </a> */}
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile_"+idx} style={{cursor:"pointer"}} onClick={() => this.handleRemoveRingiFile(idx)}><i title="Delete" className="fa fa-trash"></i></p>
                                                    </div>
                                                    <input autoComplete='off' type="hidden" id={"hiddenRingiFile_"+idx} value={item.invoice_file} />
                                                </div>
                                                :
                                                ""
                                                }
                                                {/* <FileBase64
                                                onDone={ this.changeFileHandler.bind(this) } /> */}
                                            </div>
                                            <div className="col-xl-4" style={{display: (idx != 0) ? 'block' : 'none'}}>
                                                <h6 style={{marginTop:"1em"}} onClick={() => this.handleRemoveRingiRow(idx)}>Clear</h6>
                                            </div>
                                        </div>
                                        ))}
                                        <input type="hidden" id="rngLength" value={this.state.ringiData.length}/>
                                        <div class="row col-sm-12">
                                            <div className="row col-xl-12 m-top-1">
                                                <div class="col-xl-12">
                                                    <button class="btn btn-primary" type="button" onClick={this.handleAddRingiRow}>Add More Fields +</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="hr-dashed"></div>
                                        {/* <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Invoice Currency : </label>
                                            <input type="text" name="invoice_currency" id="invoice_currency" className="form-control"  onChange={this.changeHandler} />
                                        </div> */}
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Air freight Amount : </label>
                                            <input type="text" name="airfreight_amount" id="airfreight_amount" className="form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">FCA/EXW Amount : </label>
                                            <input type="text" name="fca_exw_amount" id="fca_exw_amount" className="form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Air freight Currecny : </label>
                                            <input type="text" name="airfreight_currency" id="airfreight_currency" className="form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Any other docs : </label>
                                            <input type="file" name="other_docs" id="other_docs" className="form-control" onChange={this.changeDocFileHandler} />
                                            <div id="otr_docs"></div>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    {edit_option?  <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Assign To Custom House Agent: </label>
                                            {/* <select class="form-control col-xl-12 col-lg-12" name="assignToCustomHouseAgent"  onChange={this.changeHandler}  required>
                                                <option value="">-- Select Assign To --</option>
                                                <option value="CCIPL">Continental Carriers Pvt Ltd</option>
                                                <option value="NEIPL">Nippon Express India Pvt Ltd</option>
                                                
                                                
                                            </select> */}
                                            <Select 
                                            placeholder={"-- Select Assign To --"} 
                                            name={"assignToCustomHouseAgent"}
                                            closeMenuOnSelect={true} 
                                            // value={this.state.ff_name} 
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            onChange={this.onChangeChaCode.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={[{ value: "CCIPL", label: "Continental Carriers Pvt Ltd" },
                                            { value: "NEIPL", label: "Nippon Express India Pvt Ltd" },
                                            { value: "RCLPL", label: "Robinsons Cargo & Logistics Pvt Ltd" },
                                           ]} required
                                        />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Assign To Transporter: </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="assignToTransporter"  onChange={this.changeHandler}  >
                                                <option value="">{this.state.emailToChaTransporterName}</option>
                                               
                                                
                                            </select>
                                        </div>
                                        
                                    </div>:""}
                                   
                                    <div className="row col-xl-12 col-lg-12 form-group">
                                        <input type="hidden" name="hidden_invoice_value" id="hidden_invoice_value" value={this.state.hiddeninvoice}/>
                                        <input type="hidden" name="hidden_other_docs" id="hidden_other_docs" value={this.state.hiddenotherdocs}/>
                                        <button type="submit" className="btn btn-success" style={{marginLeft:"45%",width:"192px",marginTop:"20px"}}>Submit</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>           
            </div>
        )
    }
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function cleanTruckNo(value){
    var result =  /\s/g.test(value);
    var cleanedValue="";
    try{
        if(result == true){
            cleanedValue = value.replace(/[^\w\s]/gi, '');
            cleanedValue = cleanedValue.replace(/\s/g, '').toUpperCase();
        }
        else{
            cleanedValue = value.toUpperCase();
        }
    }
    catch(e){  }
    return cleanedValue;
}


// function checkWhiteSpaces(value){
//     return /\s/g.test(value);
// }
// function removeWhiteSpace(value){
//     value = value.replace(/[^\w\s]/gi, '');
//     return value.replace(/\s/g, '').toUpperCase();
// }
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
		format:'d-m-Y',
        timepicker:false,
        onClose: function(datesel) {
            var etd_port_date = $("#etd_port_date").val();
            var atd_port_date = $("#atd_port_date").val();
            var eta_igi_airport_date = $("#eta_igi_airport_date").val();
            var ata_igi_airport_date = $("#ata_igi_airport_date").val();
            console.log(etd_port_date,"etd_port_date");
            console.log(atd_port_date,"atd_port_date");
            console.log(eta_igi_airport_date,"eta_igi_airport_date");
            console.log(ata_igi_airport_date,"ata_igi_airport_date");
            if(eta_igi_airport_date !=ata_igi_airport_date)
            {
                $("#atdSame").removeClass("show-n");
                $("#atdSame").addClass("show-m");
            }
            else
            {
                $("#atdSame").removeClass("show-m");
                $("#atdSame").addClass("show-n");
                $("#if_atd_ata_not_same").val("");
            }
            
            if(etd_port_date !=atd_port_date)
            {
                $("#etdSame").removeClass("show-n");
                $("#etdSame").addClass("show-m");
            }
            else
            {
                $("#etdSame").removeClass("show-m");
                $("#etdSame").addClass("show-n");
                $("#if_etd_atd_not_same").val("");
            }
        }
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}


import React, { Component } from "react";
import CSVFileValidator from 'csv-file-validator';
import moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MenuModule } from "@ag-grid-enterprise/menu";
import Select from "react-select";
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import $ from "jquery";
import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { color } from "highcharts";
// import { formatDate } from "@fullcalendar/react";
var redirectURL = require("../redirectURL");

export default class LSPCarrierDataIndividul extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        width: 160,
        resizable: true,
      },
      eventAction: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      // loadshow: "show-n",
      // overly: "show-n",
      rowdata: [],
      rowdataforexwork:[],
      additionalchargesdata:[],
      sliderbulkupload: "",
      rowModelhighchartoptionsType: "enterprise",
      //   frameworkComponents: {
      //     BatchDownload: BatchDownload,
      //   },
      rowGroupPanelShow: "always",
      statusBar: {
        // statusPanels: [
        //   {
        //     statusPanel: "agTotalAndFilteredRowCountComponent",
        //     align: "left",
        //   },
        //   {
        //     statusPanel: "agTotalRowCountComponent",
        //     align: "center",
        //   },
        //   { statusPanel: "agFilteredRowCountComponent" },
        //   { statusPanel: "agSelectedRowCountComponent" },
        //   { statusPanel: "agAggregationComponent" },
        // ],
      },
      paginationPageSize: 50,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      animateRows: true,
      dept_code: "",
      pagetitle: "",
      csvData: 0,
      overly: "show-n",
      loadshow: "show-n",
      pagetitle: "",
      eventGridAction: "gridAction",
      csvcontent: [],
      file: "",
      batch_name: "",
      cluster_breaks: "2",
      cluster_radius: "0.5",
      notification_emails: "",
      bulkslide1: "",
      bulkslide2: "",
      bulkslide3: "",
      bulkslide4:'',
      bulkslidebulk:'',
      newValue: "",

      rowId: "",
      show: false,
      basicTitle: "",
      basicType: "",
      transitDate: "",
      transpoterGSTDate: "",
      unloadedDate: "",
      reachedDate: "",
      SectororCountry: "",
      location: "",
      additionalcharges:'',

      AustriaId1:"",
      ChinaId1:'',
      RepublicId1:'',

      ratesUpto:'',
      exworksCharges:'',
      plantname:'',
      transporterrate:"",
      additional_charges_cfs:0,
      additional_charges_cbm:0,
      additional_charges_shipment:0,
      usdvaluedisplay:'',
      eurovaluedisplay:'',
      Anamet_Europe_BV:0,
      Baker_Hughes_Digital_Solutions_GMBH:0,
      Kamax_Czech_Republic:0,
      Kamax_Spain:0,
      csvData: [],
            bulkMarkData: [],
            bulkslide: "",
            regn_number:'',
            modevalue:'',
            tyrevalue:'',
            domevalue:'',
            ramp1value:'',
            ramp2value:'',
            ramp3value:'',
            rampU3value:'',
            rampadjust:'',
            addtionalrampValue:''
          

    };
    // this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener("click", this.handleClickOutside);
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    var params = {
      transporter_code : localStorage.getItem("transportercode")
    };

   console.log('params--',params)
    redirectURL
      .post("/consignments/lspcarrierdatastore",params)
      .then((response) => {
        console.log(response.data, "140----");
  this.setState({
    rowdata:response.data,
    loadshow: "show-n",
    overly: "show-n",
  })
    
      })
      .catch((e) => {
        console.log(e);
      });
      // loadDateTimeScript();
  }
  
  closeAlert = () => {
    this.setState({
      show: false,
    });
  };


updateState(key, value) {
  console.log(value.name,'value',key.value);
  this.setState({
    [value.name]: key.value,
  });
}

  addtionalramp(e){
    console.log(e.target.value,'valuerampadd')
    
      this.setState({
        addtionalrampValue:e.target.value
      })
   
  }
// AddusercarrierDetails() {
//   this.setState({
//       overly: "show-m",
//       bulkslide: "slider-translate-30p"
//   })
// }


OnsubmitAddData(event, field) {
    event.preventDefault();
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
console.log(this.state.modevalue,'modevalue')
    // var modevalue = this.state.modevalue
   var tyrevalue = this.state.tyrevalue
  var  domevalue = this.state.domevalue
  var  ramp1value = this.state.ramp1value
   var ramp2value = this.state.ramp2value
   var ramp3value = this.state.ramp3value
   var rampU3value = this.state.rampU3value
   var rampadjust = this.state.rampadjust
   var addtionalrampValue = this.state.addtionalrampValue

      var params = {
        // modevalue:modevalue,
        rowId:this.state.rowId,
        tyrevalue:tyrevalue,
        domevalue:domevalue,
        ramp1value:ramp1value,
        ramp2value:ramp2value,
        ramp3value:ramp3value,
        rampU3value:rampU3value,
        rampadjust:rampadjust,
        addtionalrampValue:addtionalrampValue
      };

      console.log(params, "params===");
console.log(tyrevalue == '','compare')
if(tyrevalue != '' && domevalue != '' && ramp1value  != '' && ramp2value  != '' && ramp3value != '' && rampU3value != '' && rampadjust != ''){
  redirectURL
        .post("/consignments/addlspcarrierdata", params)
        .then((response) => {
          if (response.data.message == "success") {
            this.setState({

              loadshow: "show-n",
              overly: "show-n",
              bulkslide: "",
              show:true,
              basicTitle:"Added Successfully",
              basicType:'success'
             });
           
            this.componentDidMount();
          }
        })
        .catch((e) => {
          console.log(e);
        });
      }else {
        alert('Please select all required options before submitting.')
        this.componentDidMount();
      }
  }

 
  
  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      bulkslide: "",
      bulkslide2: "",
      bulkslide3: "",
      bulkslide4:'',
      bulkslidebulk:''
    });
  }

  handleClickOutside = (event) => {
    if (!event.target.closest(".slide-r")) {
      this.onClickHideAll();
    }
  };



resetUpload = () => {
  this.setState({
    bulkslidebulk: '',
      overly: 'show-n',
      file: '',
      bulkMarkData: [],
      bulkslide: "",
  })
  $('#upform')[0].reset();
  document.getElementById("upform").reset();
}




onCellClicked(e) {
  if (e.colDef.field === "Edit") {
  console.log(e.data,'dataonclicked')
  var data = e.data;
    this.setState({
      rowId : data._id,
      regn_number:data.regn_number,
      modevalue:data.mode,
      // tyrevalue:data.tyrevalue,
      // domevalue:data.domevalue,
      // ramp1value:data.ramp1value,
      // ramp2value:data.ramp2value,
      // ramp3value:data.ramp3value,
      // rampU3value:data.rampU3value,
      // rampadjust:data.rampadjust,
      // addtionalrampValue:data.addtionalrampValue,
    
      
        overly: "show-m",
        bulkslide: "slider-translate-30p"
  
    });
  }
}



  render() {
    const batchwisecolumns = [
        {
          pinned: "left",
          headerName: "",
          field: "Edit",
          width: 120,
          resizable: true,
          editable: false,
          cellRenderer: function (params) {
            var rendComponent =
              '<button class="btn btn-arms" id="buttonStyling" style="background-color: #00c292; color: white;">Update</button>';
            return rendComponent;
          },
          filter: false,
        },
        
       
  
        {
          headerName: 'S.No',
          field: 'sno',
          width: 100,
          cellRenderer: function(params) {
            // Calculate the S.No based on the row index
            return params.node.rowIndex + 1;
          }
        },
        {
          headerName:  'Regn Number',
          field: 'regn_number',
         with:150,  
        },
        {
          headerName:'Mode',
          field: 'mode',
         with:150,
        },
        {
          headerName: 'Tyre',
          field: 'tyre_value',
         
        },
        {
          headerName: 'Dome',
          field: 'dome_value',
         
        },
        {
            headerName: 'Ramp at L1',
            field: 'rampL1_value',
           
          },
          {
            headerName: 'Ramp at L2',
            field: 'rampL2_value',
           
          },
          {
            headerName: 'Ramp at L3',
            field: 'rampL3_value',
           
          },
          {
            headerName: 'Ramp at U3',
            field: 'rampU3_value',
           
          },
        {
          headerName:  'Ramp at Adjustment',
          field: 'ramp_adjust',
          
        },
        {
          headerName:  'Any other Addtional Ramp',
          field: 'addtional_ramp',
          width:200,
          
         
        },
       
      ];

    return (
      <div class="container-fluid">
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
           <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                        </div>
                        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                        <div className={"dataLoadpage " + (this.state.loadshow)}></div>
                    <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                        <div className="loader-box">
                            <div className="loader-box">
                                <div className="rotate dashed colored">
                                </div>
                            </div>
                        </div>
                    </div>
        <div className="card col-xl-12">
          <div className="card-header">
            <h5>
            <i className="icofont icofont-vehicle-delivery-van cus-i"></i>{" "}
              <span>LSP Carrier Data User</span>
              {/* <button className="btn btn-info" style={{ float: "right", marginRight: "10px" }} onClick={this.AddusercarrierDetails.bind(this)}>ADD</button> */}
              <div className="card-body">
                <div className="row">
                  <div
                    id="myGrid"
                    style={{ width: "100%", height: "478px" }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      modules={this.state.modules}
                      columnDefs={batchwisecolumns}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.rowdata}
                      enableCharts={false}
                      onGridReady={this.onGridReady}
                      onGridState={this.onGridState}
                      frameworkComponents={this.state.frameworkComponents}
                      rowClassRules={this.state.rowClassRules}
                      sideBar={this.state.sideBar}
                      stopEditingWhenGridLosesFocus={true}
                      onCellClicked={this.onCellClicked.bind(this)}
                      paginationPageSize={this.state.paginationPageSize}
                      pagination={true}
                      gridOptions={{
                        context: { componentParent: this },
                      }}
                      // onCellClicked={this.onCellRowClicked.bind(this)}
                      enableRangeSelection={true}
                    />
                  </div>
                </div>
              </div>
            </h5>
          </div>

          {/* work in progress Fresh Incoming rakes */}
        
        </div>
    

     <div
          className={"slide-r " + this.state.bulkslide}
          style={{ overflow: "scroll" }}
        >
          <h3 className="subH">
            Add LSP Carrier Details
            <span
              className="float-right fclose"
              style={{
                marginRight: "12px",
                padding: "1px 8px",
                marginTop: "-2px",
              }}
              onClick={this.onClickHideAll.bind(this)}
            >
              x
            </span>
          </h3>
         <div className="slide-r-body" style={{ position: "static"}}>
            <div className="container-fluid">
              <form
                method="post"
                onSubmit={this.OnsubmitAddData.bind(this)}
                autocomplete="off"
                className="row col-xl-12 col-lg-12"
              >
                <div className="form-group col-xl-12 col-lg-12">
            <label className="col-xl-12 col-lg-12">
                  Regn Number
                  </label>
                  <input
                  type='text'
                    placeholder={this.state.regn_number}
                    
                  name='modevalue'
                    className={"col-xl-12 col-lg-12 border-radius-0"}
                    readOnly
                  />
                   </div>
                   <div className="form-group col-xl-12 col-lg-12">
            <label className="col-xl-12 col-lg-12">
                  Mode
                  </label>
                  <input
                  type='text'
                    placeholder={this.state.modevalue}
                    
                  name='modevalue'
                    className={"col-xl-12 col-lg-12 border-radius-0"}
                    readOnly
                  />
                   </div>
                   <div className="form-group col-xl-12 col-lg-12">
            <label className="col-xl-12 col-lg-12">
                  Select Tyre<span style={{color:'red'}}>*</span>
                  </label>
                  <Select
                    placeholder={"Tyre"}
                    closeMenuOnSelect={true}
                  name='tyrevalue'
                    // value={this.state.container_name}
                    className={"col-xl-12 col-lg-12 border-radius-0"}
                    onChange={this.updateState.bind(this)}
                    style={{ borderRadius: "0px" }}
                    
                    options={[
                      {
                        label: "Single",
                        value: "Single",
                      },
                      {
                        label: "Double",
                        value: "Double",
                      }
                    ]}
                    required
                  />
                   </div>
                   <div className="form-group col-xl-12 col-lg-12">
            <label className="col-xl-12 col-lg-12">
                  Select Dome<span style={{color:'red'}}>*</span>
                  </label>
                  <Select
                    placeholder={"Dome"}
                    closeMenuOnSelect={true}
                  name='domevalue'
                    // value={this.state.container_name}
                    className={"col-xl-12 col-lg-12 border-radius-0"}
                    onChange={this.updateState.bind(this)}
                    style={{ borderRadius: "0px" }}
                    
                    options={[
                      {
                        label: "Yes",
                        value: "Yes",
                      },
                      {
                        label: "No",
                        value: "No",
                      }
                    ]}
                    required
                  />
                   </div>
                   <div className="form-group col-xl-12 col-lg-12">
            <label className="col-xl-12 col-lg-12">
                  Ramp at L1<span style={{color:'red'}}>*</span>
                  </label>
                  <Select
                    placeholder={" Ramp at L1"}
                    closeMenuOnSelect={true}
                  name='ramp1value'
                    // value={this.state.container_name}
                    className={"col-xl-12 col-lg-12 border-radius-0"}
                    onChange={this.updateState.bind(this)}
                    style={{ borderRadius: "0px" }}
                    
                    options={[
                      {
                        label: "Yes",
                        value: "Yes",
                      },
                      {
                        label: "No",
                        value: "No",
                      }
                    ]}
                    required
                  />
                   </div>
                   <div className="form-group col-xl-12 col-lg-12">
            <label className="col-xl-12 col-lg-12">
                  Ramp at L2<span style={{color:'red'}}>*</span>
                  </label>
                  <Select
                    placeholder={" Ramp at L2"}
                    closeMenuOnSelect={true}
                  name='ramp2value'
                    // value={this.state.container_name}
                    className={"col-xl-12 col-lg-12 border-radius-0"}
                    onChange={this.updateState.bind(this)}
                    style={{ borderRadius: "0px" }}
                    
                    options={[
                      {
                        label: "Yes",
                        value: "Yes",
                      },
                      {
                        label: "No",
                        value: "No",
                      }
                    ]}
                    required
                  />
                   </div>
                   <div className="form-group col-xl-12 col-lg-12">
            <label className="col-xl-12 col-lg-12">
                  Ramp at L3<span style={{color:'red'}}>*</span>
                  </label>
                  <Select
                    placeholder={" Ramp at L3"}
                    closeMenuOnSelect={true}
                  name='ramp3value'
                    // value={this.state.container_name}
                    className={"col-xl-12 col-lg-12 border-radius-0"}
                    onChange={this.updateState.bind(this)}
                    style={{ borderRadius: "0px" }}
                    
                    options={[
                      {
                        label: "Yes",
                        value: "Yes",
                      },
                      {
                        label: "No",
                        value: "No",
                      }
                    ]}
                    required
                  />
                   </div>
                   <div className="form-group col-xl-12 col-lg-12">
            <label className="col-xl-12 col-lg-12">
                  Ramp at U3<span style={{color:'red'}}>*</span>
                  </label>
                  <Select
                    placeholder={" Ramp at U3"}
                    closeMenuOnSelect={true}
                  name='rampU3value'
                    // value={this.state.container_name}
                    className={"col-xl-12 col-lg-12 border-radius-0"}
                    onChange={this.updateState.bind(this)}
                    style={{ borderRadius: "0px" }}
                    
                    options={[
                      {
                        label: "Yes",
                        value: "Yes",
                      },
                      {
                        label: "No",
                        value: "No",
                      }
                    ]}
                    required
                  />
                   </div>
                   <div className="form-group col-xl-12 col-lg-12">
            <label className="col-xl-12 col-lg-12">
                  Ramp of Adjustment<span style={{color:'red'}}>*</span>
                  </label>
                  <Select
                    placeholder={"Ramp of Adjustment"}
                    closeMenuOnSelect={true}
                  name='rampadjust'
                    // value={this.state.container_name}
                    className={"col-xl-12 col-lg-12 border-radius-0"}
                    onChange={this.updateState.bind(this)}
                    style={{ borderRadius: "0px" }}
                    
                    options={[
                      {
                        label: "Yes",
                        value: "Yes",
                      },
                      {
                        label: "No",
                        value: "No",
                      }
                    ]}
                    required
                  />
                   </div>
                   <div className="form-group col-xl-12 col-lg-12">
            <label className="col-xl-12 col-lg-12">
                 Any other Addtional Ramp
                  </label>
                  <input
                  type= 'text'
                    placeholder={"Any other Addtional Ramp"}
                    name='addtionalrampValue'
                   id='addtionalrampValue'
                   className="form-control"
                   style={{width:'350px',height:'100px'}}
                   onChange={this.addtionalramp.bind(this)}
                   maxlength="500"
                  //  required
                  />
                   </div>
                  <div className="form-group col-xl-3 col-lg-3">
                  <button type="submit" className="btn btn-success">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

       
      </div>
    );
  }
}


function DateEditor() {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
  // create the cell
  console.log(params, "params");
  this.eInput = document.createElement("input");
  this.eInput.value = params.value;

  // https://jqueryui.com/datepicker/
  $(this.eInput).datetimepicker({
    dateFormat: "yy-mm-dd",
    timepicker: false,
    changeMonth: true,
    changeYear: true,
  });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
  return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
  this.eInput.focus();
  this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
  return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
  // but this example is simple, no cleanup, we could
  // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
  // and we could leave this method out also, false is the default
  return false;
};

function loadDateTimeScript() {
  // alert("timeDate");
  $(".datetimepicker_datew").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y H:i",
    minDate: true,
    timepicker: true,
    closeOnDateSelect: true,
    scrollMonth: false,
    scrollInput: false,
  });
  $(".datetimepicker_dateo").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y",
    minDate: true,
    timepicker: false,
    closeOnDateSelect: true,
    scrollMonth: false,
    scrollInput: false,
  });
  $(".cargo_date").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y",
    minDate: true,
    timepicker: false,
  });
}
function formatDate(dateString) {
  if(dateString != "Invalid Date" && dateString != "" && dateString != "NaN-NaN-NaN" && dateString != undefined && dateString != 'undefined' ){
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
  }
  else {
    return ""
  }
}
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 

import Highcharts from 'highcharts';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';
drilldown(Highcharts);
window.jQuery = $;
window.$ = $;

var redirectURL = require('../redirectURL');
var highchartoptions;
var criticaldata=[]
export default class ReturnTripsRouteWiseTripsGroupLineChart extends Component {

    constructor(props) {
		super(props);
		this.state = {
			showgrid:'display:none',
			showtransgrid:'display:none',
			rowdata:[],
			test:"TEstng",
			drilldowndata:[]
		}

	}
	componentDidMount = async () =>{
		
		$(".grid-"+this.props.code).hide();
		$(".grid-trans-"+this.props.code).hide();
		$(".dataLoadpagesum"+this.props.code).hide()
		$(".dataLoadpageimgsum"+this.props.code).hide()
		$(".overlay-partsum"+this.props.code).hide();
		
	}
	componentWillReceiveProps (newprops){
        //if(newprops.title != this.props.dept_code)
        //{
			this.setState({
				drilldowndata:newprops.drildown
			})
            //console.log("newprops ",newprops);
		//}

		
	}
	hideGrid(params){
		//console.log(params)
		hideGrid(params)
	}
	hideTransGrid(params){
		hideTransGrid(params)
    }
    
    render(){
        // console.log("categories ",this.props.categories);
        // console.log("newprops ",this.props.series);
        const dStyles={
				width:'100%',
				height:'100%'
        }
        var drilldowncols = this.props.drilldowncols;
		var drilldownlines = this.props.drilldownlines;
		var drilldownseriesdata = this.props.drilldown;
		var xcategories = this.props.categories
        // console.log("drilldowncols ", drilldowncols)
        // console.log("drilldownlines ", drilldownlines)
        highchartoptions = {
            chart: {
				zoomType: 'xy',
				events : {
					click: function(){
						
						var point = this;
						console.log(this);
						this.update({
							xAxis: {
							categories : xcategories	
							}
						})
					},
					
				}
            },
            title: {
                text: this.props.title
            },
            xAxis: {
				categories: this.props.categories,
				// categories: true,
				// type: 'category',
				crosshair: true
            },
            yAxis: [
                { // Secondary yAxis
                    title: {
                        text: 'Valid Trips %',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: '{value} ',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: true
				},
				{ // Primary yAxis
					labels: {
						format: '{value}',
						style: {
							color: Highcharts.getOptions().colors[1]
						}
					},
					title: {
						text: 'Total Trips vs Valid Trips',
						style: {
							color: Highcharts.getOptions().colors[1]
						}
					}
				}
            ],
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true
					},
					stacked: false
				},
				line:{
					dataLabels:{
                        enabled:true,
                        // format:'{point.y}'
					},
					stacked: false
				},
				// series: {
					series: {
						events: {
						  click: function(event) {
							var point = this;
							var chart = point.chart;
							var drilldownId = event.point.drilldown;
							var drillcategories = []
							console.log(this.name)
							if(this.name == "Return Trips")
							{
								drilldownseriesdata.map(function(e){
									if(e.id == drilldownId)
									{
										e.data.map(function(k){
											drillcategories.push(k[0])
										})
									}
								});
	
								chart.update({
									xAxis: {
									categories : drillcategories
									},
								})
							}

							// console.log(drilldown)
						  }
						}
					}
				// }
			},
            tooltip: {
                shared: true
            },
			series: this.props.series,
			drilldown: {
				"series": this.props.drilldown,
				chartOptions: {   
					yAxis: [
						{ // Primary yAxis
						labels: {
							format: '{value}',
							style: {
								color: Highcharts.getOptions().colors[1]
							}
						},
						title: {
							text: 'Compensation (Rs)',
							style: {
								color: Highcharts.getOptions().colors[1]
							}
						}
					},{ // Secondary yAxis
						title: {
							text: 'Rs/Car',
							style: {
								color: Highcharts.getOptions().colors[0]
							}
						},
						labels: {
							format: '{value} ',
							style: {
								color: Highcharts.getOptions().colors[0]
							}
						},
						opposite: true
					}],
				},
			},
            credits: false
		};
		console.log(highchartoptions.series,"route series")
        console.log(highchartoptions.drilldown,"Route wise drilldown")
        return ( 
			<div>
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />
			</div>
			);
		}
}


function hideGrid(id)
{
	console.log("id ",id)
	$(".grid-"+id).fadeOut()
}

function hideTransGrid(id)
{
	console.log("id ",id)
	$(".gridtrans-"+id).fadeOut()
}


function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
  } 

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

function arrayMin(arr) {
	return arr.reduce(function (p, v) {
	  return ( p < v ? p : v );
	});
  }
  
  function arrayMax(arr) {
	return arr.reduce(function (p, v) {
	  return ( p > v ? p : v );
	});
  }

// function getData(){
// 	alert("cons")
// }

$(document).ready(function(){
	
})

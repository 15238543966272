
import React, { Component } from 'react';
import prtemptytruckvicinity from '../assets/archivalscreenshots/prtemptytruckvicinity.png';
import prtgeofence from '../assets/archivalscreenshots/prtgeofence.png';
import couriercriticaldelayreasons from '../assets/archivalscreenshots/couriercriticaldelayreasons.png';
// import deliveredcourierconsignments from '../assets/archivalscreenshots/deliveredcourierconsignments.png';
import invalidcourierconsignments from '../assets/archivalscreenshots/invalidcourierconsignments.png';
// import activecourierconsignments from '../assets/archivalscreenshots/activecourierconsignments.png';
import prtmanuallocations from '../assets/archivalscreenshots/prtmanuallocations.png';
import prtreturnTripsDashboard from '../assets/archivalscreenshots/prtreturnTripsDashboard.png';
import prtroutedeviationadhoc from '../assets/archivalscreenshots/prtroutedeviationadhoc.png';
import prttickets from '../assets/archivalscreenshots/prttickets.png';
import prtuniquetrucktrips from '../assets/archivalscreenshots/prtuniquetrucktrips.png';
import prtunutilizedtrucks from '../assets/archivalscreenshots/prtunutilizedtrucks.png';
import prtcriticaldelayreasons from '../assets/archivalscreenshots/prtcriticaldelayreasons.png';
import prtlocalconsignments from '../assets/archivalscreenshots/prtlocalconsignments.png';
import prtturnaroundtime from '../assets/archivalscreenshots/prtturnaroundtime.png';
import prtclosetickets from '../assets/archivalscreenshots/prtclosetickets.png';
import prtvicinity from '../assets/archivalscreenshots/prtvicinity.png';
import prtemptyreturntrucks from '../assets/archivalscreenshots/prtemptyreturntrucks.png';
import prtvolumefreightupload from '../assets/archivalscreenshots/prtvolumefreightupload.png';
import sndclosetickets from '../assets/archivalscreenshots/sndclosetickets.png';
import sndcriticaldelayreasons from '../assets/archivalscreenshots/sndcriticaldelayreasons.png';
import segmentation from '../assets/archivalscreenshots/segmentation.png';
import sndtransporters from '../assets/archivalscreenshots/sndtransporters.png';
import safetyincidents from '../assets/archivalscreenshots/safetyincidents.png';
import accidentalpronezones from '../assets/archivalscreenshots/accidentalpronezones.png';
import damagedashboard from '../assets/archivalscreenshots/damagedashboard.png';
import sndconsignees from '../assets/archivalscreenshots/sndconsignees.png';
import sndnoparkingzones from '../assets/archivalscreenshots/sndnoparkingzones.png';
import carrierrunreport from '../assets/archivalscreenshots/carrierrunreport.png';
import lspcarrierdocuments from '../assets/archivalscreenshots/lspcarrierdocuments.png';
import sndconfigurations from '../assets/archivalscreenshots/sndconfigurations.png';
// import bitransitreturns from '../assets/archivalscreenshots/bitransitreturns.png';
import exitloadattachment from '../assets/archivalscreenshots/exitloadattachment.png';
import msilvesselschedule from '../assets/archivalscreenshots/msilvesselschedule.png'

export default class ScreenArchival extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prtScreens:'activet',
            sndgscreens:'',
            tableData: [
                { screenPath: "https://autometrics.in/prtemptytruckvicinity", screenImage: prtemptytruckvicinity,screenName:'Empty Truck Vicinity'},
                { screenPath: "https://autometrics.in/prtgeofence", screenImage: prtgeofence,screenName:'Geofence Incursions' },
                { screenPath: "https://autometrics.in/couriercriticaldelayreasons", screenImage: couriercriticaldelayreasons ,screenName:'Update Remarks (Critical Delays)'},
                // { screenPath: "https://autometrics.in/deliveredcourierconsignments", screenImage: deliveredcourierconsignments,screenName:' Courier Consignments (Delivered)' },
                { screenPath: "https://autometrics.in/invalidcourierconsignments", screenImage: invalidcourierconsignments,screenName:' Courier Consignments (Invalid)' },
                // { screenPath: "https://autometrics.in/activecourierconsignments", screenImage: activecourierconsignments,screenName:' Courier Consignments (Active)' },
                { screenPath: "https://autometrics.in/prtmanuallocations", screenImage: prtmanuallocations ,screenName:'Manual Location Update'},
                { screenPath: "https://autometrics.in/prtreturnTripsDashboard", screenImage: prtreturnTripsDashboard ,screenName:'Return Trips Dashboard'},
                { screenPath: "https://autometrics.in/prtroutedeviationadhoc", screenImage: prtroutedeviationadhoc ,screenName:'Route Deviation'},
                { screenPath: "https://autometrics.in/prttickets", screenImage: prttickets ,screenName:'Ticket Management System'},
                { screenPath: "https://autometrics.in/prtuniquetrucktrips", screenImage: prtuniquetrucktrips ,screenName:'Trips For Unique Trucks'},
                { screenPath: "https://autometrics.in/prtunutilizedtrucks", screenImage: prtunutilizedtrucks ,screenName:'Unutilized Trucks Report'},
                { screenPath: "https://autometrics.in/prtcriticaldelayreasons", screenImage: prtcriticaldelayreasons,screenName:'Update Remarks (Critical Delays)' },
                { screenPath: "https://autometrics.in/prtlocalconsignments", screenImage: prtlocalconsignments ,screenName:'Local Consignments'},
                { screenPath: "https://autometrics.in/prtturnaroundtime", screenImage: prtturnaroundtime,screenName:'Turn Around Time' },
                { screenPath: "https://autometrics.in/prtclosetickets", screenImage: prtclosetickets ,screenName:'Closed Tickets'},
                { screenPath: "https://autometrics.in/prtvicinity", screenImage: prtvicinity ,screenName:'Dealer Unloading Locations'},
                { screenPath: "https://autometrics.in/prtemptyreturntrucks", screenImage: prtemptyreturntrucks,screenName:'Empty Return Trucks' },
                { screenPath: "https://autometrics.in/prtvolumefreightupload", screenImage: prtvolumefreightupload ,screenName:'Volume & Freight Dashboard'},
               

            ],
            tableData2: [
              { screenPath: "https://autometrics.in/sndclosetickets", screenImage: sndclosetickets ,screenName:' Closed Tickets '},
              { screenPath: "https://autometrics.in/sndcriticaldelayreasons", screenImage: sndcriticaldelayreasons ,screenName:'Update Remarks (Critical Delays)'},
              { screenPath: "https://autometrics.in/segmentation", screenImage: segmentation ,screenName:'Segmentation'},
              { screenPath: "https://autometrics.in/msilvesselschedule", screenImage: msilvesselschedule ,screenName:'Imports Vessels Status'},
              // { screenPath: "https://autometrics.in/manage/sndtransporters", screenImage: sndtransporters ,screenName:' Manage Transporters '},
              // { screenPath: "https://autometrics.in/safetyincidents", screenImage: safetyincidents ,screenName:' Safety Incidents'},
              // { screenPath: "https://autometrics.in/accidentalpronezones", screenImage: accidentalpronezones ,screenName:'Accidental Prone Zones'},
              // { screenPath: "https://autometrics.in/damagedashboard", screenImage: damagedashboard ,screenName:'Damages Report'},
              // { screenPath: "https://autometrics.in/sndconsignees", screenImage: sndconsignees ,screenName:'Manage Consignees List'},
              // { screenPath: "https://autometrics.in/sndnoparkingzones", screenImage: sndnoparkingzones ,screenName:'No Parking Zones'},
              // { screenPath: "https://autometrics.in/carrierrunreport", screenImage: carrierrunreport ,screenName:'Carrier Run Report'},
              // { screenPath: "https://autometrics.in/lspcarrierdocuments", screenImage: lspcarrierdocuments ,screenName:'Lsp Document Input'},
              // { screenPath: "https://autometrics.in/sndconfigurations", screenImage: sndconfigurations ,screenName:'Configurations'},
            //   { screenPath: "https://autometrics.in/bitransitreturns", screenImage: bitransitreturns ,screenName:'Transit Damage Returns'},
              // { screenPath: "https://autometrics.in/exitloadattachment", screenImage: exitloadattachment ,screenName:'Load Exits'},
          ]
        };
    }

    onClickTab(clickedTab) {
        if (clickedTab == 'prtScreens') {
          var prtScreens = "activet";
          var sndgscreens = "";
        } else if (clickedTab == "sndgscreens") {
          var prtScreens = "";
          var sndgscreens = "activet";
        } 
        this.setState({
            prtScreens: prtScreens,
            sndgscreens: sndgscreens,
        })
      }

    render() {
        var { tableData } = this.state;
        var { tableData2 } = this.state;
        return (
            <div className="row">
                <div className="col-xl-12 col-lg-12">
          <div className="f16" style={{ marginBottom: "10px" }}>
            <ul className="d-tabs">

              <li onClick={this.onClickTab.bind(this, "prtScreens")} className={"lttabs " + (this.state.prtScreens)}>
                <button type="button" className="btn">PRT Screens</button>
              </li>

              <li onClick={this.onClickTab.bind(this, "sndgscreens")} className={"lttabs " + (this.state.sndgscreens)}>
                <button type="button" className="btn">SNDG Screens</button>
              </li>
            </ul>
          </div>
        </div>
                <div className="col-xl-12 col-lg-12">
                    <h5>Screen Archival</h5>
                    <div className="card">
                        <div className="card-header">
                           {this.state.prtScreens == "activet" ? <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
                                {tableData.map((item, index) => (
                                    <div key={index} style={{width:'500px',margin:'15px'}}>
                                        <h6 style={{marginBottom:'5px'}}>{item.screenName}</h6>
                                        <a href={item.screenPath} >
                                            <img src={item.screenImage} alt="Screen" style={{ width: '500px', height: 'auto' }} />
                                        </a>
                                    </div>
                                ))}
                            </div> : <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
                                {tableData2.map((item, index) => (
                                    <div key={index} style={{width:'500px',margin:'15px'}}>
                                        <h6 style={{marginBottom:'5px'}}>{item.screenName}</h6>
                                        <a href={item.screenPath} >
                                            <img src={item.screenImage} alt="Screen" style={{ width: '500px', height: 'auto' }} />
                                        </a>
                                    </div>
                                ))}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import Autosuggest from 'react-autosuggest';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import * as Datetime from 'react-datetime';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import EditItem from './editActionComponent';
import { getHyphenDDMMMYYYYHHMM } from '../common/utils';
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");


export default class GpsUptimeReport extends Component {

    constructor(props) {
        super(props);

        this.state = {
			lists:[],
			departmentCode: null,
			currentDepartmentTitle: null,
			currentAddGeoFenceLink: null,
    	    modules: AllModules,
      	      defaultColDef: {
      	        sortable: true,
				  filter: true,
				  resizable: true
      	        
      	      },
      	      rowData: null,
      	      
      	      rowModelhighchartoptionsType: 'enterprise',
      	      rowGroupPanelShow: 'always',
      	      animateRows: true,
      	      debug: true,
      	      showToolPanel: false,
      	      rowSelection: "single" ,
      	      context: { componentParent: this },
      	      frameworkComponents: {
      	    	  editItem:EditItem
      	      },
            usergridstate:[],
            screenurl:"",
            screentitle:"",
            startDate:"",
            endDate:"",
			show:false,
			basicTitle:"",
			basicType:"",
			loadshow:'show-n',
			overly:'show-n'
            
        };
	}
	getCurrentDepartmentCode(pathToMatch = null) {
		let department = "SNDG";
		let departmentName = " (Sales and Dispatch) ";
		let currentAddGeoFenceLink = "/sndaddlocation";
		switch(pathToMatch) {
			case "/sndmanuallocations":
				department='SNDG';
				departmentName = " (Sales and Dispatch) ";
				currentAddGeoFenceLink = "/sndaddlocation";
				break;
            case "/prtmanuallocations":
                department='LOG-PRT';
                departmentName = " (Spare Parts) ";
                currentAddGeoFenceLink = "/prtaddlocation";
                break;
            case "/tnpmanuallocations":
                department='LOG-TNP';
                departmentName = " (Train and Production) ";
                currentAddGeoFenceLink = "/tnpaddlocation";
                break;
            case "/scmanuallocations":
                department='LOG-SC';
                departmentName = " (Supply Chain) ";
                currentAddGeoFenceLink = "/scaddlocation";
                break;
			default:
				//console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			currentDepartmentTitle:departmentName,
			currentAddGeoFenceLink:currentAddGeoFenceLink
		});
		return department;
	}
    componentDidMount(){
		if (googleAnalytics.page.enableGA) {
			googleAnalytics.logPageView();
		}
	
		var end_time = moment.parseZone(new Date()).subtract(1,"days").format("YYYY-MM-DD")
		var start_time = moment.parseZone(new Date(end_time)).subtract(2,"days").format("YYYY-MM-DD")
		console.log(start_time,end_time,"106")
		this.setState({
			startDate:start_time,
			endDate:end_time
		})
		var params ={
			start_date:start_time,
			end_date:end_time
		}
		redirectURL.post("/consignments/getgpsuptimereport",params).
		then(
			(response)=>{
				// console.log(response.data,"205")
				let records = response.data;
			// console.log(records,"206")
			if(records != undefined && records != "" && records != null && records != "undefined" && records != "null"){
				if(records.length >0){
					this.setState({
						rowData:records,
						overly:"show-n",
						loadshow:"show-n"
					});
				}else{
					this.setState({
						overly:"show-n",
						loadshow:"show-n",
						show:true,
						basicTitle:"Data Unavailable",
						basicType:"danger"
					});
				}
			}else{
				this.setState({
					overly:"show-n",
					loadshow:"show-n",
					show:true,
					basicTitle:"Data Unavailable",
					basicType:"danger"
				});
			}
				
			}
		)
		.catch(function(error){
			console.log(error);
		});
    	// redirectURL.post("/consignments/manuallocation").
		// then(
		// 	(response)=>{
		// 		let records = (response.data) ? response.data : [];
			
		// 		//console.log("/geofence/lists records = ", records);
		// 		this.setState({
		// 			rowData:records
		// 		});
		// 	}
		// )
		// .catch(function(error){
		// 	console.log(error);
		// });
    }
   
    getDepartmentCodes = (params) => {
    	var depts = params.value;
    	var departments=[];
		var deptname='';
		
    	for(var item in depts)
    	{
    		deptname = deptname + depts[item].value +", ";
    		//console.log(depts[item].dept_code);
    		departments.push(depts[item].value);
    	}
    	//console.log("departments",deptname);
    	//console.log("departments",departments);
    	return deptname;
    }
    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	
	};
	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		    
		  
		   
	 }


     handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
	  
		var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
		if(this.state.endDate !=undefined && this.state.endDate !="")
		{
			console.log(startdate,"startdate")
			console.log(this.state.endDate,"endDate")
			var startDate = new Date(startdate);
			var endDate = new Date(this.state.endDate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			console.log(startTime,endTime);
			if(startTime > endTime)
			{
			  this.setState({
				startDate:""
			  });
			  alert("To Date should be greater than From Date");
			}
			else
			{ 	
			  this.setState({
				startDate:startdate
			  });
			}
		}
		else
		{
		  this.setState({
			startDate:startdate
		  });
		}
		  //let value = event.target.value;
		//this.setState({'startDate':value});
	}
	handlerEndDateTime  = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);    
		var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
	
		if(this.state.endDate !=undefined && this.state.endDate !="")
		{
			console.log(this.state.startDate,"startdate")
			console.log(edate,"endDate")
			var startDate = new Date(this.state.startDate);
			var endDate = new Date(edate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			console.log(startTime,endTime);
			if(startTime > endTime)
			{
			  this.setState({
				endDate:""
			  });
			  alert("To Date should be greater than From Date");
			}
			else
			{ 
			  this.setState({
				endDate:edate
			  });
			}
		}
		else
		{
			this.setState({
			  endDate:edate
			});
		}
	}
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
		});
		
	}
    onClickFilterData(){
    //    console.log(this.state.startDate,this.state.endDate,"197")
	   this.setState({
		overly :"show-m",
		loadshow : "show-m"
	   })
       let start_date=this.state.startDate
       let end_date=this.state.endDate
		let params={
			start_date:this.state.startDate,
			end_date:this.state.endDate
		}
		console.log(params,"229")
       redirectURL.post("/consignments/getgpsuptimereport",params).
		then(
			(response)=>{
				// console.log(response.data,"205")
				let records = response.data;
			// console.log(records,"206")
			if(records != undefined && records != "" && records != null && records != "undefined" && records != "null"){
				if(records.length >0){
					this.setState({
						rowData:records,
						overly:"show-n",
						loadshow:"show-n"
					});
				}else{
					this.setState({
						overly:"show-n",
						loadshow:"show-n",
						show:true,
						basicTitle:"Data Unavailable",
						basicType:"danger"
					});
				}
			}else{
				this.setState({
					overly:"show-n",
					loadshow:"show-n",
					show:true,
					basicTitle:"Data Unavailable",
					basicType:"danger"
				});
			}
				
			}
		)
		.catch(function(error){
			console.log(error);
		});
    }


    render(){
        var	columnwithDefs = [

				// {
				// 	field:'_id',
				// 	headerName:'',
				// 	width: 72,
				// 	cellRenderer:function(params){
				// 		var rowid = params.data._id;
				// 		var htmloption = '<a href="/editlocation/SNDG/'+rowid+'" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
				// 		return htmloption;
				// 	}
				// },
				{
					headerName: "Transporter Name",
					field: "transporter_name",
					width:250
				
				},
				{
					headerName: "Vehicle Count",
					field: "vehicle_count",
					width:150
					
				},
				{
					headerName: "Total Days",
					field: "total_days",
					width:150
				
				},
                {
					headerName: "Active",
					field: "active",
					width:150
				
				},
                {
					headerName: "Battery Disconnected",
					field: "battery_disconnected",
					width:160
				
				},
                {
					headerName: "Others",
					field: "inactive",
					width:150
				
				},
                {
					headerName: "Total Uptime Days",
					field: "total_uptime_days",
					width:150
				},
                {
					headerName: "Uptime%",
					field: "uptime%",
					width:150
				
				},
				
			];
		
	
		
    	
        return (
            
    		<div className="container-fluid">
            	        <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                         <div className=" card">                  
							
                <div className='col-xl-12 col-lg-12'>
						 
                         <div className="row card-body" style={{marginLeft:"10px"}}>
						 <div className="col-xl-4 col-lg-4 form-group">
                                 <label>From Date</label>
                                 <Datetime
                                 value={this.state.startDate} 
                                 disableCloseOnClickOutside={false} 
                                 closeOnSelect={true} 
                                 inputProps={{ placeholder: 'From', name: 'startDate', autoComplete:'off' }}
                                 dateFormat="YYYY-MM-DD" 
                                 name="startDate" 
                                 onChange={this.handlerStartDateTime.bind(this)} />
                                 
                             </div>

							 <div className="col-xl-4 col-lg-4 form-group">
                                 <label>To Date</label>
                                 <Datetime 
                                 value={this.state.endDate} 
                                 disableCloseOnClickOutside={false}
                                 closeOnSelect={true} 
                                 inputProps={{ placeholder: 'To', name: 'endDate', autoComplete:'off'}} 
                                 dateFormat="YYYY-MM-DD" 
                                 onChange={this.handlerEndDateTime.bind(this)} />
                                 
                             </div>
                             <div className="form-group col-xl-2 col-lg-2">
                                 <button type="button" style={{position:"absolute",right:"20px"}} className="mt-30p btn btn-success" onClick={this.onClickFilterData.bind(this)}>Submit</button>
                             </div>
                           
                         </div>
                     
                         </div>	
                         </div>
                         <div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       			<span>Gps Uptime Report </span>
				       				</h5>
				   			</div>
				   			<div className="card-body">
                               <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                    />

                                </div>
                            </div>
				   		</div>	
				   	</div>
					   <div className={"dataLoadpage " +(this.state.loadshow)}></div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>  
				 </div>
                 
            </div>
              
        );
    }
}




import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const Weatheralerts = (props) => {
    
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowWeatherData(props.data);
    };

    return (
        <div>
			<button onClick={handleRouteClick} className="custom-btn f12 btn btn-success" title="View Data">
                View Data 
            </button>
       
        </div>
    );
};

export default Weatheralerts;


/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import CreatableSelect from 'react-select/creatable';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import DrawMap from '../common/drawmap';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import {
    getDDMMYYYYHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenDDMMYYYYHHMMSS,
} from '../common/utils';
import CSVFileValidator from 'csv-file-validator';
import EditButton from "./editbutton";
import redirectURL from '../redirectURL';
import Counters from '../layouts/d22consignmentkpicountersComponent';
import KpiTransitDelayActions from '../manage/kpiTransitDelayActions';
import Notransit from '../manage/notransitComponent.js';
import kpiActions from '../manage/kpiActionsComponent';
import NoOverspeed from '../manage/nooverspeedComponent.js';
import kpiNightDriveActions from '../manage/kpiNightDriveActionsComponent';
import ConsignDigilock from '../manage/consigndigilock';
import Nonightdrive from '../manage/nonightdriveComponent.js';
import NonDigilock from '../manage/nondigilock';
import KpiLoadingDelayActions from '../manage/kpiLoadingDelayActions';
import KpiUnLoadingDelayActions from '../manage/kpiUnLoadingDelayActions';
import KpiEnrouteStoppagesActions from '../manage/kpiEnrouteStoppagesActionsComponent';
import RouteNightDrivemap from '../manage/routenightdrivemap';
import RoutemapTransitAction from '../manage/routemaptransit';
import NoGpsLifetime from '../manage/nogpslifetimeActionComponent.js';
import NoGps from '../manage/nogpsActionComponent.js';
import Gpsdata from '../manage/gpsdataaction.js';
import RoutemapNoGPSAction from '../manage/routemapnogps';
import RoutemapDigiLockAction from '../manage/routemapdigilock';
import RoutemapAction from '../manage/routemapActionComponent';
import RoutemapenrouteAction from '../manage/routemapenroute';
import LoadSummaryDrawmap from '../layouts/loadSummaryDrawmap'; 
import Modal from 'react-responsive-modal';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import { NavItem } from 'reactstrap';
import { isInteger, lte } from 'lodash';


var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class D22Consignment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            csvcontent: "",
            file: "",
            loadshow: 'show-n',
            overly: 'show-n',
            uploadYard: "",
            uploadDispatchDate: "",
            sliderTranslate: '',
            sliderRouteTranslate: '',
            sliderCommentTranslate: "",
            rowData: [],
            rowSelection: 'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize: 50,
            vehicleList: [],
            geoFenceData: "",
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            dept_code: "",
            pagetitle: "",
            bulkslide: "",
            consignee: "",
            truck_no: "",
            startDate: "",
            endDate: "",
            countersjson: "",
            // components : {datepicker:getDatePicker()},
            frameworkComponents: {
                EditButton: EditButton,
                consignmentActions: ConsignmentActions,
                kpiTransitDelayActions: KpiTransitDelayActions,
                notransit: Notransit,
                kpiActions: kpiActions,
                NoOverspeed: NoOverspeed,
                kpiNightDriveActions: kpiNightDriveActions,
                ConsignDigilock:ConsignDigilock,
                NonDigilock:NonDigilock,
                nonightdrive: Nonightdrive,
                KpiLoadingDelayActions: KpiLoadingDelayActions,
                kpiUnLoadingDelayActions: KpiUnLoadingDelayActions,
                kpiEnrouteStoppagesActions: KpiEnrouteStoppagesActions,
                noGpsLifetime:NoGpsLifetime,
                nogps:NoGps,
                gps:Gpsdata,
                RoutemapenrouteAction:RoutemapenrouteAction,
            },
            showDiv: 'show-n',
            mapinfo: "",
            dealer: "",
            consignment_code: "",
            maptruckno: "",
            routeTruck: "",
            rownode: "",
            leg_no: 0,
            rowId: "",
            csvData: [],
            d22ConsignmentData: [],
            tabOneactive: "activet",
            tabTwodelivered: '',
            tabThreeconsignmentform: '',
            activeClass: 'col-xl-6 col-lg-6 show-m',
            tabOneActiveData: "show-m",
            tabTwoDeliveredData: "show-n",
            tabThreeCreateConsgData: "show-n",
            transporterName: '',
            vehicleNo: '',
            consignerCode: '',
            gateInTime: '',
            gateOutTime: '',
            trucksList: [],
            transportersList: [],
            vin_no: '',
            gridRowData: [],
            vinIsDisabled: true,
            source: '',
            destination: '',
            sourceList: [],
            digi_lock:[],
            latLngArray : [],
            mapShow : 0,
            sliderTitle : "Bulk Upload",
			open: false,
            digiAlertsData:[]
        }
        this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
		this.onShowNoGPSRouteMap = this.onShowNoGPSRouteMap.bind(this);
        this.onClickCounterShowData = this.onClickCounterShowData.bind(this);
      
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
       
    }
    async componentDidMount() {
        this.logPageView();
        loadDateTimeScript();
        this.onLoadPageData(0)
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        let pagetitle = 'Create D22Consignments';
        var transporter_code = "";
           
        //this.onLoadPageInitialRowData()
        
        var params = {
            dept_code: 'SND22'
        }
        redirectURL.post("/consignments/getTrucksford22consignments", params).then((response) => {
            var data = response.data;
            var trucksList = [];
            let transportersList = []
            // console.log("trucks", data)
            let sourceList = []
            data.sourceData.map(item => {
                sourceList.push({ label: item, value: item })
            })
            data.trucksData.map(item => {
                trucksList.push({ label: item.truck_no, value: item.truck_no });
            });
            data.transporterData.map(e => {
                transportersList.push({ label: e.transporter_name, value: e.transporter_code });
            })
            this.setState({
                sourceList,
                pagetitle: pagetitle,
                trucksList,
                transportersList
            });
        }).catch(function (error) {
            console.log("error");
        });
    }

    onLoadPageInitialRowData = async () => {
        let reqparams = {}
        // await redirectURL.post("/consignments/getRowDataFord22consignments", reqparams).then((response) => {
        //     let responseData = response.data
        //     // console.log("responseData", responseData)
        //     var pagecounters = responseData.counters;

        //     this.setState({
        //         rowData: responseData.data,
        //         countersjson: pagecounters,
        //     })
        //     this.onLoadPageData(0)
        // })
    }
     onLoadPageData = dataType => {
        this.setState({
            loadshow:"show-m",
            overly:"show-m",
        })
        var reqparams = {
            dataType : dataType
        }
        redirectURL.post("/consignments/getRowDataFord22consignments", reqparams).then((response) => {
            let responseData = response.data
           // console.log("responseData", responseData)
            //var pagecounters = responseData.counters;

            this.setState({
                rowData: responseData.data,
                gridRowData: responseData.data,
                //countersjson: pagecounters,
                loadshow:"show-n",
                overly:"show-n",
            })
            let transit_delay = responseData.data.filter(e => e.transit_delay == 1)
            let no_gps = responseData.data.filter(e => e.no_gps_data == 1)
            let nogpslifetime = responseData.data.filter(e => e.no_gps_data_lifetime == 1)
            let overspeeding = responseData.data.filter(e => e.overspeeding_exception == 1)
            let night_drive = responseData.data.filter(e => e.nightdriving_exception == 1)
            let digi_lock = responseData.data.filter(e => e.digi_lock_alert == 1 )
            let enroute_stoppage = responseData.data.filter(e => e.enroute_exception == 1)
            let loading_delay = responseData.data.filter(e => e.loading_delay_exception == 1)
            var pagecounters = {
                transit_delay : transit_delay.length,
                no_gps : no_gps.length,
                nogpslifetime : nogpslifetime.length,
                overspeeding : overspeeding.length,
                night_drive : night_drive.length,
                digi_lock : digi_lock.length,
                enroute_stoppage : enroute_stoppage.length,
                loading_delay : loading_delay.length,
            }
           // console.log(pagecounters,"pagecounters")
            this.setState({
                countersjson: pagecounters,
            })
        })
    }

    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridReady1 = params => {
        this.gridApi1 = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi1 = params.columnApi;
    };


    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }
    onGridState1 = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi1.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi1.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi1.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi1.getFilterModel();
        this.gridApi1.setFilterModel(window.filterState);
    }

    changeSelectedConsignee = (e) => {
        // console.log("e", e)
        this.setState({
            transporterName: e,
        })
    }
    changeVehicleNo = (e) => {

        this.setState({
            vehicleNo: e,
        })
    }

    changeSource = (e) => {
        this.setState({
            source: e,
        })
    }

    changeDestination = (e) => {
        this.setState({
            destination: e,
        })
    }

    onShowTransitKpi = async (params) => {
		if(params != 0)
		{
			let eventLabel = googleAnalytics.page.action.transitDelay;
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
			//console.log("IN onShowTransitKpi, params = ",params);
			//console.log(params);
			//console.log("Consign onShowTransitKpi params = ",params);
			//console.log(params.column.colId);
			if(params != 0)
			{
				var transitedelaydata=[];
				
				await this.setState({
						
					detailCellRendererParams:{
						suppressRefresh: true,
						detailGridOptions: {
							headerHeight:50,
							columnDefs: [
								{ headerName: "",field: "_id", 
								
									cellRendererFramework:RoutemapTransitAction,
									width:50,
									suppressSizeToFit: true,
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								},
								{ headerName: "Truck No",field: "truck_no", width:100,resizable: true,
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								},
								{ headerName: "Leg Start Time",field: "leg_start", width:120,resizable: true,
									valueGetter:function(params){
										return getDDMMYYYYHHMMSS(params.data.leg_start);
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								},
								{ headerName: "Leg End Time",field: "leg_end_eta", 
								width:120,resizable: true,
									valueGetter:function(params){
										return getDDMMYYYYHHMMSS(params.data.leg_end_eta);
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								},
								{ headerName: "Exp. Leg Distance (KM)",
								field: "leg_expected_distance", width:140,resizable: true,
									valueGetter:function(params){
										return Math.round(params.data.leg_expected_distance);
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								},
								{ headerName: "Actual Leg Distance Covered (KM)",
								field: "actual_leg_distance", width:140,resizable: true,
									valueGetter:function(params){
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if(params.data.actual_leg_distance != '' && params.data.actual_leg_distance != undefined)
										{
											return Math.round(params.data.actual_leg_distance);
										}
										else{
											return 0;
										}
										
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								},							
								{ headerName: "Exp. Distance from Trip Start(KM)",
								field: "expected_distance_start_to_leg", width:140,resizable: true,
									valueGetter:function(params){
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if(params.data.expected_distance_start_to_leg != '' && params.data.expected_distance_start_to_leg != undefined)
										{
											return Math.round(params.data.expected_distance_start_to_leg);
										}
										else{
											return 0;
										}
										
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								
								},
								{ headerName: "Actual Distance from Trip Start (KM)",
								field: "actual_distance_traveled_start_to_leg", width:140,resizable: true,
									valueGetter:function(params){
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if(params.data.actual_distance_traveled_start_to_leg != '' && params.data.actual_distance_traveled_start_to_leg != undefined)
										{
											return Math.round(params.data.actual_distance_traveled_start_to_leg);
										}
										else{
											return 0;
										}
										
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								
							
								},
								
								{ 
									headerName: "Google Distance from Start (KM)",
									field: "actual_start_to_leg_google_distance", 
									width:140,
									resizable: true,
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								
							
								},
								{ 
									headerName: "GPS Data Available",
									field: "no_gps_data", 
									width:140,
									resizable: true,
									valueGetter:function(params){
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if(params.data.no_gps_data == 1)
										{
											return "Not Available";
										}
										if(params.data.no_gps_data == 0)
										{
											return "Available";
										}
										
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								
							
								},
								
								
								{ 
									headerName: "Trip Completed",
									field: "trip_completed", 
									width:140,
									resizable: true,
									valueGetter:function(params){
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if(params.data.trip_completed == 1)
										{
											return "Yes";
										}
										if(params.data.trip_completed == 2)
										{
											return "";
										}
										if(params.data.trip_completed == 0)
										{
											return "";
										}
										
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								
							
								}							
							],
							overlayNoRowsTemplate: 'No rows to show',						
						},
						getDetailRowData: function(params) {
							//console.log("Step 4 ",transitedelaydata)
							//console.log("Step 4 ",params)
							redirectURL.post('/consignments/snd22transitdelay', {
								consignment_code:params.data.consignment_code,
								consignee_code:params.data.consignee_code, 
								truck_no:params.data.truck_no
							})
							.then(async (response) =>{
								//console.log("Step 1 ",response.data)
								transitedelaydata=response.data;
								var legsarr=[]
								transitedelaydata.map((item) => {
									//if(item.trip_completed < 2)
									//{
									legsarr.push(item)
									//}
								})
								// console.log('transitedelaydata ', transitedelaydata);

								params.successCallback(legsarr);
								//console.log("Step 2 ",response.data)
								
							});
							
						}
					}
				});
				
				//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
				if(params.column.colDef.field == 'transit_delay' || params.column.colDef.field == 'no_gps_data')
				{
					params.node.setExpanded(!params.node.expanded);
					//console.log("expanded ",params.node.expanded)
					if(params.node.expanded == false)
					{
						transitedelaydata=[]
					}
				}
				else{

					params.node.setExpanded(false);
				}
				//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
				
			}
			else{
				this.setState({
				
					detailCellRendererParams:{
						detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No",field: "truck_no" },
							{ headerName: "Speed (km/h)",field: "speed" },
							{ headerName: "Reported At",field: "first_instance" }
						]
						
						},
						getDetailRowData: function(params) {
							//console.log("Child ", params);
						params.successCallback([]);
						}
					}
				});
			}
		}
		else
		{
			this.setState({
				show:true,
				basicTitle:"Transit for this consignment has not yet started",
				basicType:"info"
			});
		}
		
	}

    onShowNightDriveKpi = async (params) => {
        let eventLabel = googleAnalytics.page.action.nightDrive;
        let eventOptions = {
            "category": this.state.pagetitle,
            "action": this.state.eventAction,
            "label": eventLabel,
        }
        googleAnalytics.logEvent(eventOptions);
        //console.log("IN onShowNightDriveKpi, params = ",params);
        //console.log(e);
        //console.log("Consign Overspeed ",params);

        if (params != 0) {
            var overspeedData = [];


            //console.log("Step 3 ",overspeedData)

            await this.setState({

                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [
                            {
                                headerName: "", field: "_id",

                                cellRendererFramework: RouteNightDrivemap,
                                width: 50,
                                suppressSizeToFit: true
                            },
                            { headerName: "Truck No", field: "truck_no", width: 150, resizable: true },
                            {
                                headerName: "Traveled From", field: "first_instance", width: 180, resizable: true,
                                valueGetter: function (params) {
                                    return getDDMMYYYYHHMMSS(params.data.first_instance);
                                }
                            },
                            {
                                headerName: "Traveled Until", field: "recent_instance", resizable: true,
                                valueGetter: function (params) {
                                    return getDDMMYYYYHHMMSS(params.data.recent_instance);
                                }
                            },
                            {
                                headerName: "Distance Traveled (km)", field: "distance_from_first_instance", width: 180,
                                resizable: true,
                                valueGetter: function (params) {
                                    return Math.round(params.data.distance_from_first_instance)
                                }
                            }
                        ],
                        overlayNoRowsTemplate: 'No rows to show',
                    },
                    getDetailRowData: function (params) {
                        //console.log("Step 4 ",overspeedData)
                        redirectURL.post('/consignments/sndnightdriving', {
                            consignment_code: params.data.consignment_code,
                            gate_out_time: params.data.gate_out_time,
                            truck_no: params.data.truck_no
                        })
                            .then(async (response) => {
                                //console.log("Step 1 ",response.data)
                                overspeedData = response.data;
                                params.successCallback(overspeedData);
                                //console.log("Step 2 ",response.data)

                            });

                    }

                }
            });

            //if(params.column.colId == 'nightdriving_exception')
            if (params.column.colDef.field == 'nightdriving_exception') {

                params.node.setExpanded(!params.node.expanded);
            }
            else {

                params.node.setExpanded(false);
            }
            //console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

        }
        else {
            this.setState({

                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [
                            { headerName: "Truck No", field: "truck_no" },
                            { headerName: "Speed (km/h)", field: "speed" },
                            { headerName: "Reported At", field: "first_instance" }
                        ]

                    },
                    getDetailRowData: function (params) {
                        //console.log("Child ", params);
                        params.successCallback([]);
                    }
                }
            });
        }

    }
    
    onShowDigiKpi = async (params) => {
        let eventLabel = googleAnalytics.page.action.digi_lock;
        let eventOptions = {
            "category": this.state.pagetitle,
            "action": this.state.eventAction,
            "label": eventLabel,
        }
        googleAnalytics.logEvent(eventOptions);
        //console.log("IN onShowDigiKpi, params = ",params);
        //console.log(e);
        //console.log("Consign Overspeed ",params);

        if (params != 0) {
            var overspeedData = [];


            //console.log("Step 3 ",overspeedData)

            await this.setState({

                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [
                            {
                                headerName: "", field: "_id",

                                cellRendererFramework: RoutemapDigiLockAction,
                                width: 50,
                                suppressSizeToFit: true
                            },
                            { headerName: "Elock Name", field: "elock_name", width: 150, resizable: true },
                            {
                                headerName: "Elock Id", field: "elock_id", width: 140, resizable: true,
                               
                            },
                            {
                                headerName: "Latitude", field: "latitude", width: 140, resizable: true,
                                valueGetter:function(params){
                                    try{
                                      return params.data.latitude.toFixed(6);
                                    }
                                    catch(e){
                                      return params.data.latitude;
                                    }
                                    
                                }
                                
                                
                            },
                            {
                                headerName: "Longitude", field: "longitude", width: 140, resizable: true,
                                valueGetter:function(params){
                                    try{
                                      return params.data.longitude.toFixed(6);
                                    }
                                    catch(e){
                                      return params.data.longitude;
                                    }
                                    
                                }
                            },
                            {
                                headerName: "Speed", field: "speed", width: 100, resizable: true,
                                
                            },
                            
                            {
                                headerName: "Location", field: "location", width: 180, resizable: true,
                               
                            },
                             {
                                headerName: "Lock Status Changed", field: "lock_status_changed",width: 140,  resizable: true,
                               
                            },
                            {
                                headerName: "Device Status", field: "device_status", width: 140,
                                resizable: true,
                                
                            },
                            {
                                headerName: "Device Unlocked", field: "device_unlocked", width: 140,
                                resizable: true,
                                
                            },
                            {
                                headerName: "Request From", field: "request_from", width: 140,
                                resizable: true,
                               
                            },
                            {
                                headerName: "Command Success", field: "command_success_time", width: 140,
                                resizable: true,
                               
                            },
                            {
                                headerName: "Operation", field: "operation", width: 140,
                                
                               
                            },
                            {
                                headerName: "Operation Id", field: "operation_id", width: 140,
                                
                                
                            },
                            {
                                headerName: "Tampered Status", field: "tampered_status", width: 140,
                                resizable: true,
                               
                            },
                            {
                                headerName: "Power status xhanged", field: "power_status_changed", width: 160,
                                resizable: true,
                               
                            },
                            {
                                headerName: "Voltage", field: "voltage", width: 140,
                        
                                
                            },
                            {
                                headerName: "Power Status", field: "power_status", width: 140,
                                
                                
                            },
                            {
                                headerName: "Low Power", field: "low_power", width: 140,
                                resizable: true,
                               
                            },
                            {
                                headerName: "Alert Date", field: "log_timestamp", width: 160,
                                resizable: true,
                                valueGetter: function (params) {
                                    return getHyphenDDMMMYYYYHHMM(params.data.log_timestamp)
                                }
                            }
                        ],
                        overlayNoRowsTemplate: 'No rows to show',
                    },
                    getDetailRowData: function (params) {
                        console.log("Step 4 ",params)
                        redirectURL.post('/consignments/snd22digilock', params.data)
                            .then(async (response) => {
                                //console.log("Step 1 ",response.data)
                                overspeedData = response.data;
                                params.successCallback(overspeedData);
                                //console.log("Step 2 ",response.data)

                            });

                    }

                }
            });

            //if(params.column.colId == 'digi_lock_alert')
            if (params.column.colDef.field == 'digi_lock_alert') {

                params.node.setExpanded(!params.node.expanded);
            }
            else {

                params.node.setExpanded(false);
            }
            //console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

        }
        else {
            this.setState({

                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [
                            { headerName: "Truck No", field: "truck_no" },
                            { headerName: "Speed (km/h)", field: "speed" },
                            { headerName: "Reported At", field: "first_instance" }
                        ]

                    },
                    getDetailRowData: function (params) {
                        //console.log("Child ", params);
                        params.successCallback([]);
                    }
                }
            });
        }

    }
    onShowNightDriveRouteMap(rownode){
		// console.log("IN onShowNightDriveRouteMap, params = ",rownode);
		// console.log("nightdrivenode", rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow:'show-m'
		});
		var d = new Date(rownode.first_instance);
    	var r = new Date(rownode.recent_instance);
    	var fintance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var eintance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = { 
			truck_no:rownode.truck_no,
            first_instance:fintance,
            recent_instance:eintance,
		}
		
		 redirectURL.post('/reports/nightdriveroutes',params)
		  .then((response) => {
		  
		   //var records = JSON.parse(JSON.stringify(response)).data;
			console.log("nightroutedata",response.data);
			
		   this.setState({
				mapinfo:response.data,
				sliderRouteTranslate:"slider-translate-50p",
				consignment_code:rownode.truck_no,
				defTransitCoords: {"lat":rownode.first_lat,"lng":rownode.first_lng},
				maptruckno:rownode.truck_no,
				routeTruck:	{"truck_no":rownode.truck_no,"startTime":response.data['route_details']['start_time'],
					"endTime": response.data['route_details']['end_time']},
				loadshow:'show-n',
				overly:'show-m',
				tolls : response.data.tolls
		   });
		   	this.renderMap();
		
			   
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
		  
	}

    insertdttconsignments = (event) => {
        event.preventDefault();
        let eventLabel = googleAnalytics.page.action.insertdttconsignments;
        let eventOptions = {
            "category": this.state.pagetitle,
            "action": this.state.eventAction,
            "label": eventLabel,
        }
        // googleAnalytics.logEvent(eventOptions);

        var vin_no = [];
        if (this.state.vin_no.length != 0) {
            this.state.vin_no.map((e) => { vin_no.push(e.value) })
        }
        let gateInTime = this.state.gateInTime
        let gateOutTime = this.state.gateOutTime
        let consignmentCode = $("#consignmentCode").val();
        let quantity = $("#Quantity").val();
        let driver_name= $("#driverName").val();
        let driver_phone_no= $("#driverPhoneNumber").val();
        let dg_serial_no= $("#dgSerialNum").val();
        let transporterCode = this.state.transporterName.value;
        let transporterName = this.state.transporterName.label;
        let vehicleNo = this.state.vehicleNo.label;
        let consignerCode = this.state.source.value;
        let consignee_code = this.state.destination.value;
        if (gateInTime == '' || gateInTime == "NaN-NaN-NaN") {
            gateInTime = "";
        }
        consignmentCode = removeSplChars(consignmentCode)
        driver_name = removeSplChars(driver_name)
        driver_phone_no = removeSplChars(driver_phone_no)
        dg_serial_no = removeSplChars(dg_serial_no)
        consignmentCode = consignmentCode.toUpperCase()
        if (vin_no.length == quantity) {
            const reqparams =
            {
                consignment_code: consignmentCode,
                gate_in_time: gateInTime,
                gate_out_time: gateOutTime,
                transporter_name: transporterName,
                transporter_code: transporterCode,
                truck_no: vehicleNo,
                consigner_code: consignerCode,
                consignee_code: consignee_code,
                quantity: quantity,
                dept_code: "SND22",
                status: 2,
                vin_no: vin_no,
                driver_name: driver_name,
                driver_phone_no: driver_phone_no,
                dg_serial_no : dg_serial_no
            };
            let userId = localStorage.getItem('userid');
            if (userId != undefined && userId != '') {
                reqparams['userId'] = userId
            }
            reqparams['created_by'] = localStorage.getItem('username');
            // console.log("reqparams", reqparams);
            if (consignmentCode != "" && consignmentCode != undefined && quantity != null && quantity !== '' && transporterName !== undefined && transporterName !== "" && vehicleNo !== undefined && vehicleNo !== ""
                && consignerCode !== "" && consignerCode !== undefined && consignee_code !== undefined && consignee_code !== ""
                && gateOutTime != "__-__-____"
            ) {

                this.setState({
                    loadshow: 'show-m',
                    overly: "show-m"
                });
                redirectURL.post("/consignments/insertD22consignments", reqparams).then((response) => {
                    if (response.data.message == "success") {
                        this.onLoadPageInitialRowData()
                        this.setState({
                            basicTitle: "D22Consignment Created Successfully..!!!",
                            basicType: "success",
                            show: true,
                            rowId: "",
                            loadshow: 'show-n',
                            overly: "show-n",
                            transporterName: '',
                            vehicleNo: '',
                            source: '',
                            destination: '',
                            vin_no: ''
                        })
                        $("#consignmentCode").val(" ");
                        $("#Quantity").val(" ");
                        $("#driverName").val(" ");
                        $("#driverPhoneNumber").val(" ");
                        $("#dgSerialNum").val(" ");
                    }
                    else if (response.data.message == "D22Consignment Already Exists.. !!!") {
                        this.setState({
                            basicTitle: "D22Consignment Already Exists.. !!!",
                            basicType: "warning",
                            show: true,
                            loadshow: 'show-n',
                            overly: "show-n"
                        })
                    }
                    // console.log("",response.data);

                }).catch((e) => {
                    console.log(e);
                })
            }
            else {
                console.log("empty check")
                this.setState({
                    basicTitle: "Fields Should not be Empty",
                    basicType: "warning",
                    show: true
                })
            }


        } else {
            this.setState({
                basicTitle: "Please Enter VinNo's matching Quantity",
                basicType: "warning",
                show: true

            })
        }


    }

    quantityHandler = e => {
        // console.log("event",e)
        this.setState({
            vinIsDisabled: false
        })
    }
    onChangeVins = (e) => {
        let quantity = $("#Quantity").val();
        // console.log("e",e)
        this.setState({ vin_no: e })
        // if(e !== null){
        //     if(e.length == quantity){
        //         this.setState({ vin_no: e })
        //     }       
        //     else{
        //         this.setState({
        //             basicTitle : "Please Enter VinNo's matching Quantity",
        //             basicType : "warning",
        //             show : true
        //         })
        //     }
        // }
    }

    showBulkUpload() {
        this.setState({
            overly: "show-m",
            bulkslide: "slider-translate"
        })
    }
    resetUpload = () => {
        this.setState({
            bulkslide: '',
            overly: 'show-n',
            file: ''
        });
        document.getElementById("upform").reset();
    }

    changeFileHandler = async (e) => {
        var dt = '';
        const config = {
            headers: [
                {
                    name: 'consignmentCode',
                    inputName: 'consignment_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'gateInTime',
                    inputName: 'gate_in_time',
                    required: false,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'gateOutTime',
                    inputName: 'gate_out_time',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'transporterName',
                    inputName: 'transporter_name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'vehicleNo',
                    inputName: 'truck_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'source',
                    inputName: 'consigner_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'destination',
                    inputName: 'consignee_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'quantity',
                    inputName: 'quantity',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'status',
                    inputName: 'status',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'vinNo',
                    inputName: 'vin_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
            ]
        }

        var data = CSVFileValidator(e.target.files[0], config)
            .then((csvData) => {
                let csvList = csvData.data;
                var flag = 0;
                let promiseVar = csvList.map((e) => {
                    e['consignment_code'] = removeSplChars(e.consignment_code)
                    let vinList = (e['vin_no']).split(',')
                    if (isNaN(parseInt(e.quantity))) {
                        flag = 1;
                        this.setState({
                            basicTitle: "Please Enter Quantity as Number",
                            basicType: "warning",
                            show: true
                        })
                    }
                    else {
                        if (e.quantity != vinList.length) {
                            flag = 1;
                            this.setState({
                                basicTitle: "Please Enter VinNo's matching Quantity",
                                basicType: "warning",
                                show: true
                            })
                        }
                    }
                })
                // console.log(csvList,'csvList')
                if (flag == 0) {
                    Promise.all(([promiseVar]) => {
                        this.setState({
                            d22ConsignmentData: csvList
                        });
                    })
                }

            })
            .catch(err => {
                console.log(err, "error")
            })
        // console.log("e.target.files[0]",e.target.result);
        if (e.target.files[0] != undefined) {
            var out = new Promise(function (reject, resolve) {
                var reader = new FileReader();
                reader.onload = async function (e) {
                    var contents = await e.target.result;
                    resolve(contents);
                };
                var tt = reader.readAsText(e.target.files[0]);
            });
            this.setState({
                file: e.target.files[0]
            });
            // console.log("e.target.files[0].type ", e.target.files[0]);

            if (e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
                // console.log(fileData);
                // console.log(typeof(fileData))

            }
            else {
                e.target.value = null;
                this.setState({
                    uploadFile: '',
                    show: true,
                    basicType: 'danger',
                    basicTitle: 'Please upload file having extensions .csv only.',
                });
            }
        }

    }

    async uploadBulkFormHandler(e) {
        e.preventDefault();
        var fileData = this.state.d22ConsignmentData;
        var flag = 0;
        // console.log(fileData, "fileData");
        if (flag == 0) {
            if (fileData.length > 0) {
                // var vehicleList = this.state.vehicleList;
                // var listflag = 0;
                // var filevalid = fileData.map(async function(f){
                //     if(f.truck_no !=undefined && f.truck_no !='')
                //     {
                //         if(vehicleList.includes(f.truck_no) == false)
                //         {
                //             listflag = 1;
                //         }
                //     }
                // // })
                // Promise.all([filevalid]).then(()=>{
                //     if(listflag == 0)
                //     {
                var reqParams = {
                    csvData: fileData,
                }
                // console.log("reqParams", reqParams)
                // removeSplChars()
                this.setState({
                    loadshow: 'show-m',
                    overly: "show-m"
                });
                // redirectURL.post("/consignments/insertBulkD22Consignments", reqParams).then((response) => {
                //     var resp = response.data.message;
                //     // console.log('resp', response.data)
                //     this.setState({
                //         basicTitle: resp,
                //         basicType: "success",
                //         show: true,
                //         bulkslide: '',
                //         overly: 'show-n',
                //         file: "",
                //         loadshow: 'show-n',
                //     })
                //     //window.location.reload();
                // }).catch((e) => {
                //     console.log(e);
                // })
            }
            else {
                this.setState({
                    basicTitle: "Invalid Data",
                    basicType: "warning",
                    show: true,
                    file: "",
                })
                $('#upform')[0].reset();
            }
            // })
            // }
            // else
            // {
            //     this.setState({
            //         basicTitle : "Invalid File",
            //         basicType : "warning",
            //         show : true,
            //         file : "",
            //     })
            // }
            // $('#upform')[0].reset();
        }
        else {
            $("#bulkUploadID").val();
            this.setState({
                basicTitle: "Fields Should not be Empty",
                basicType: "warning",
                show: true,
                file: "",
            })
            $('#upform')[0].reset();
        }
    };
    closeAlert = () => {
        this.setState({
            show: false
        });
        //window.location.reload();
    }

    onClickHideAll() {
        // console.log("cliekc");
        $("#editinputform").val("")
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            bulkslide: '',
            consignee: "",
            truck_no: "",
            rowId: "",
            sliderRouteTranslate: ""
        });

    }

    onClickTab(pageType) {
        if (pageType == "active") {
            // $("#selectedFilter").val(" ");
            $("#inputTruckConsignment").val(" ");
            var tabOneactive = "activet";
            var tabTwodelivered = "";
            var tabThreeconsignmentform = "";
            var tabOneActiveData = "show-m";
            var tabTwoDeliveredData = "show-n";
            var tabThreeCreateConsgData = "show-n";
            this.onLoadPageData(0);
            var activeClass = 'col-xl-6 col-lg-6 show-m'
            this.setState({
                startDate: '',
                endDate: ''
            })
        }
        else if (pageType == "delivered") {
            // $("#selectedFilter").text("");
            $("#inputTruckConsignment").val(" ");
            tabOneactive = "";
            tabTwodelivered = "activet";
            tabThreeconsignmentform = "";
            tabOneActiveData = "show-n";
            tabTwoDeliveredData = "show-m";
            tabThreeCreateConsgData = "show-n";
            this.onLoadPageData(1);
            activeClass = 'col-xl-6 col-lg-6 show-m';
            this.setState({
                startDate: '',
                endDate: ''
            })
        }
        else if (pageType == "createConsignments") {

            loadDateTimeScript();
            tabOneactive = "";
            tabTwodelivered = "";
            tabThreeconsignmentform = "activet";
            tabOneActiveData = "show-n";
            tabTwoDeliveredData = "show-n";
            tabThreeCreateConsgData = "show-m";
            activeClass = 'col-xl-6 col-lg-6 show-n'

            // this.onLoadPageData(2);
        }

        this.setState({
            tabOneactive,
            tabTwodelivered,
            tabThreeconsignmentform,
            tabOneActiveData,
            tabTwoDeliveredData,
            tabThreeCreateConsgData,
            activeClass
        })
    }

    gateOutDateTime = event => {
        let d = new Date(event._d);
        let startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate()) + " " + d.getHours() + ":" + ((d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes()) + ":" + ((d.getSeconds() < 10) ? "0" + d.getSeconds() : d.getSeconds());

        // console.log("gateOutdate", startdate)
        this.setState({
            gateOutTime: startdate
        })
    }

    gateInDateTime = event => {
        let d = new Date(event._d);
        let startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate()) + " " + d.getHours() + ":" + ((d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes()) + ":" + ((d.getSeconds() < 10) ? "0" + d.getSeconds() : d.getSeconds());

        // console.log("gateOutdate", startdate)
        this.setState({
            gateInTime: startdate
        })
    }

    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

        this.setState({
            startDate: startdate
        });

    }

    handlerEndDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
        this.setState({
            endDate: edate
        });
    }

    getTruckConsignments = event => {
        event.preventDefault();
        const { rowData, gridRowData } = this.state
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        var selectedFilter = $("#selectedFilter").text();
        var inputTruckConsignment = $("#inputTruckConsignment").val();
        if (selectedFilter == "Select..." || inputTruckConsignment == "") {
            this.setState({
                basicTitle: "Fields Should not be Empty",
                basicType: "danger",
                show: true
            })
        }
        else {
            if (selectedFilter == 'Vin') {
                let searchItem = { 'vin_no': inputTruckConsignment }
                let gridRowData = []
                rowData.map(e => {
                    if (e['vin_no'].includes(searchItem['vin_no'])) {
                        gridRowData.push(e)
                    }
                })
                // console.log("gridRowData", gridRowData)
                this.setState({
                    gridRowData
                })
            }
            else if (selectedFilter == 'Consignments') {
                let searchItem = { 'consignment_code': inputTruckConsignment }
                let gridRowData = []
                rowData.map(e => {
                    if (e['consignment_code'].includes(searchItem['consignment_code'])) {
                        gridRowData.push(e)
                    }
                })
                // console.log("gridRowData", gridRowData)
                this.setState({
                    gridRowData
                })
            }
            else if (selectedFilter == 'Truck') {
                let searchItem = { 'truck_no': inputTruckConsignment }
                let gridRowData = []
                rowData.map(e => {
                    if (e['truck_no'].includes(searchItem['truck_no'])) {
                        gridRowData.push(e)
                    }
                })
                // console.log("gridRowData", gridRowData)
                this.setState({
                    gridRowData
                })
            }
            this.setState({
                loadshow: "show-n",
                overly: "show-n"
            })
        }

    }

    onClickTripButton = () => {
        if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
            var sDate = "";
        }
        else {
            var sDate = this.state.startDate;
        }
        if (this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN") {
            var eDate = "";
        }
        else {
            var eDate = this.state.endDate;
        }
        var requestparams = {
            startDate: sDate,
            endDate: eDate
        }
        redirectURL.post("/consignments/getRowDatatimeWiseForD22", requestparams).then(response => {
            let responseData = response.data
            // console.log("timerowData", responseData)
            this.setState({
                gridRowData: responseData
            })

        })

    }

    onClickCounterShowData = params => {
        // console.log("Counter clicked")
        // console.log("Params", params)
        const { rowData } = this.state
        if (params == 'all') {
            if(this.state.tabTwoDeliveredData === "show-m") {
                this.gridApi1.setRowData(rowData);
                this.gridApi1.onFilterChanged();
            }
            else {
                this.gridApi.setRowData(rowData);
                this.gridApi.onFilterChanged();
            }
            // this.gridApi.setRowData(rowData);
            // this.gridApi.onFilterChanged();
        }
        if (params == "transit_delay") {
            // var filterComponent = this.gridApi.getFilterInstance("transit_delay");
            // filterComponent.selectNothing();
            // filterComponent.selectValue("1");
            // if(this.state.tripvalue != undefined && this.state.tripvalue != '')
            // {
            // filterComponent.selectValue(this.state.tripvalue);
            // }
            // filterComponent.applyModel();
            var gridData = this.state.rowData
            let isFilterDataAvailable = gridData.filter(rec => {
                if (rec["transit_delay"]) {
                    return rec["transit_delay"] == 1;
                }
            });
            if(this.state.tabTwoDeliveredData === "show-m") {
                this.gridApi1.setRowData(isFilterDataAvailable);
                this.gridApi1.onFilterChanged();
            }
            else {
                this.gridApi.setRowData(isFilterDataAvailable);
                this.gridApi.onFilterChanged();
            }
        }
        else if (params == "probable_accident") {
            let isFilterDataAvailable = this.state.rowData.filter(rec => {
                if (rec["probable_accident"]) {
                    return rec["probable_accident"] == 1;
                }
            });

            if(this.state.tabTwoDeliveredData === "show-m") {
                this.gridApi1.setRowData(isFilterDataAvailable);
                this.gridApi1.onFilterChanged();
            }
            else {
                this.gridApi.setRowData(isFilterDataAvailable);
                this.gridApi.onFilterChanged();
            }
        }
        else if (params == "probable_accident_confirm") {
            let isFilterDataAvailable = this.state.rowData.filter(rec => {
                if (rec["probable_accident_confirm"]) {
                    return rec["probable_accident_confirm"] == 1;
                }
            });
            if(this.state.tabTwoDeliveredData === "show-m") {
                this.gridApi1.setRowData(isFilterDataAvailable);
                this.gridApi1.onFilterChanged();
            }
            else {
                this.gridApi.setRowData(isFilterDataAvailable);
                this.gridApi.onFilterChanged();
            }
        }
        else if (params == "no_gps_data") {

            let isFilterDataAvailable = this.state.rowData.filter(rec => {
                if (rec["no_gps_data"]) {
                    return rec["no_gps_data"] == 1;
                }
            });

            // console.log("isFilterDataAvailable ", isFilterDataAvailable)
            if(this.state.tabTwoDeliveredData === "show-m") {
                this.gridApi1.setRowData(isFilterDataAvailable);
                this.gridApi1.onFilterChanged();
            }
            else {
                this.gridApi.setRowData(isFilterDataAvailable);
                this.gridApi.onFilterChanged();
            }
        }


        else if (params == "no_gps_data_lifetime") {
            if (this.state.tripvalue != undefined && this.state.tripvalue != '') {
                this.gridApi.getFilterInstance("no_gps_data_lifetime").selectValue(this.state.tripvalue);
            }
            //this.gridApi.getFilterInstance("no_gps_data_lifetime").applyModel();
            let isFilterDataAvailable = this.state.rowData.filter(rec => {
                if (rec["no_gps_data_lifetime"]) {
                    return (rec["no_gps_data_lifetime"] == 1);
                }
            });
            // console.log("isFilterDataAvailable ", isFilterDataAvailable)
            if(this.state.tabTwoDeliveredData === "show-m") {
                this.gridApi1.setRowData(isFilterDataAvailable);
                this.gridApi1.onFilterChanged();
            }
            else {
                this.gridApi.setRowData(isFilterDataAvailable);
                this.gridApi.onFilterChanged();
            }
        }

        else if (params == "overspeeding_exception") {
            // console.log(this.gridApi1, "this.gridApi");
            // console.log("in oveerspeeding");
            // console.log("Row data: ", this.state.rowData);
            let isFilterDataAvailable = this.state.rowData.filter(rec => {
                if (rec["overspeeding_exception"]) {
                    return (rec["overspeeding_exception"] == 1);
                }
            });
            // console.log("Filtered Data:", isFilterDataAvailable);
            // console.log("Tab One Active Status: ", this.state.tabOneActiveData);
            // console.log("Tab Two Active Status: ", this.state.tabTwoDeliveredData);
            if(this.state.tabTwoDeliveredData === "show-m") {
                this.gridApi1.setRowData(isFilterDataAvailable);
                this.gridApi1.onFilterChanged();
            }
            else {
                this.gridApi.setRowData(isFilterDataAvailable);
                this.gridApi.onFilterChanged();
            }
        }
        else if (params == "nightdriving_exception") {

            let isFilterDataAvailable = this.state.rowData.filter(rec => {
                if (rec["nightdriving_exception"]) {
                    return rec["nightdriving_exception"] == 1;
                }
            });

            if(this.state.tabTwoDeliveredData === "show-m") {
                this.gridApi1.setRowData(isFilterDataAvailable);
                this.gridApi1.onFilterChanged();
            }
            else {
                this.gridApi.setRowData(isFilterDataAvailable);
                this.gridApi.onFilterChanged();
            }
        }
        else if (params == "digi_lock_alert") {

            let isFilterDataAvailable = this.state.rowData.filter(rec => {
                if (rec["digi_lock_alert"]) {
                    return rec["digi_lock_alert"] == 1;
                }
            });
            if(this.state.tabTwoDeliveredData === "show-m") {
                this.gridApi1.setRowData(isFilterDataAvailable);
                this.gridApi1.onFilterChanged();
            }
            else {
                this.gridApi.setRowData(isFilterDataAvailable);
                this.gridApi.onFilterChanged();
            }
        }
        else if (params == "loading_delay_exception") {

            let isFilterDataAvailable = this.state.rowData.filter(rec => {
                if (rec["loading_delay_exception"]) {
                    return rec["loading_delay_exception"] == 1;
                }
            });
            if(this.state.tabTwoDeliveredData === "show-m") {
                this.gridApi1.setRowData(isFilterDataAvailable);
                this.gridApi1.onFilterChanged();
            }
            else {
                this.gridApi.setRowData(isFilterDataAvailable);
                this.gridApi.onFilterChanged();
            }
        }
        else if (params == "enroute_exception") {
            var gridData = this.state.rowData
            if (this.state.tnpReturnConsignmentsVisibilty) { gridData = this.state.returnConsignments }
            let isFilterDataAvailable = gridData.filter(rec => {
                if (rec["enroute_exception"] != undefined || rec["enroute_exception"] != '') {
                    return rec["enroute_exception"] == 1;
                }
            });
            //console.log("isFilterDataAvailable Enrotue ", isFilterDataAvailable)
            if (isFilterDataAvailable.length > 0) {
                if(this.state.tabTwoDeliveredData === "show-m") {
                    this.gridApi1.setRowData(isFilterDataAvailable);
                    this.gridApi1.onFilterChanged();
                }
                else {
                    this.gridApi.setRowData(isFilterDataAvailable);
                    this.gridApi.onFilterChanged();
                }
            }
            else {
                if(this.state.tabTwoDeliveredData === "show-m") {
                    this.gridApi1.setRowData([]);
                    this.gridApi1.onFilterChanged();
                }
                else {
                    this.gridApi.setRowData([]);
                    this.gridApi.onFilterChanged();
                }
            }

        }
        else if (params == "digi_lock") {
            // var filterComponent = this.gridApi.getFilterInstance("transit_delay");
            // filterComponent.selectNothing();
            // filterComponent.selectValue("1");
            // if(this.state.tripvalue != undefined && this.state.tripvalue != '')
            // {
            // filterComponent.selectValue(this.state.tripvalue);
            // }
            // filterComponent.applyModel();
            var gridData = this.state.rowData
            let isFilterDataAvailable = gridData.filter(rec => {
                if (rec["digi_lock_alert"]) {
                    return rec["digi_lock_alert"] == 1;
                }
            });
            if(this.state.tabTwoDeliveredData === "show-m") {
                this.gridApi1.setRowData(isFilterDataAvailable);
                this.gridApi1.onFilterChanged();
            }
            else {
                this.gridApi.setRowData(isFilterDataAvailable);
                this.gridApi.onFilterChanged();
            }
        }
    }

    reloadPageData = () => {
        window.location.reload();
    }

    onCloseUploadDiv = () => {
        this.setState({
            uploadDivWidth: '0%',
            sliderTranslate: "",
            tabsliderTranslate: '',
            showDiv: 'show-n',
            sliderTranslatesidebar: "",
            sliderCommentTranslate: "",
            uploadFile: '',
            file: '',
            csvmessage: '',
            csverrmessage: '',
            overly: 'show-n',
            loadshow: 'show-n',
            slideuploadeway: ""
        });
    }

    onCloseRouteDiv = () => {
        this.setState({
            uploadDivWidth: '0%',
            sliderRouteTranslate: "",
            sliderCommentTranslate: "",
            sliderSegmentTranslate: "",
            showDiv: 'show-n',
            uploadFile: '',
            file: '',
            csvmessage: '',
            csverrmessage: '',
            overly: 'show-n'
        });
    }

    handleRouteClick = e => {
        // console.log("e",e)
        // e.stopPropagation();
        this.onCloseUploadDiv(e);
        this.onCloseRouteDiv(e);
        //    this.onShowRouteDiv();
    }

    onCellClicked = (e) => {
        // console.log("celle", e)
        if (e.colDef.field == "maproute") {
            this.setState({
                loadshow: 'show-m',
            });
            if (e.data.gate_out_time) {
                var gateouttime = moment.parseZone(e.data.gate_out_time).format('YYYY-MM-DD HH:mm:ss');
            }

            var edate = '';
            var end_date;
            if (e.data.status <= 2) {
                end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
                edate = getHyphenDDMMYYYYHHMMSS(end_date);
            }
            else if (e.data.status == 3 || e.data.status == 4) {
                if (e.data.recent_dealer_reported) {
                    end_date = e.data.recent_dealer_reported.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
                    edate = getHyphenDDMMYYYYHHMMSS(e.data.recent_dealer_reported);
                }
            }
            else if (e.data.status == 5) {
                if (e.data.left_dealer_marked) {
                    end_date = e.data.left_dealer_marked.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
                    edate = getHyphenDDMMYYYYHHMMSS(e.data.left_dealer_marked);
                }
            }
            else if (e.data.status == 6) {
                if (e.data.force_closure_time) {
                    end_date = e.data.force_closure_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
                    edate = getHyphenDDMMYYYYHHMMSS(e.data.force_closure_time);
                }
            }
            else {
                end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
                edate = getHyphenDDMMYYYYHHMMSS(end_date);
            }

            if ((e.data.transit_time) > 0) {
                var seconddate = new Date(new Date(e.data.gate_out_time).getTime() + ((e.data.transit_time + 1) * 24 * 60 * 60 * 1000));
            }
            else {
                var seconddate = new Date(new Date(e.data.gate_out_time).getTime() + 2 * 24 * 60 * 60 * 1000);
            }

            var sdate = seconddate.getFullYear() + "-" + (seconddate.getMonth() + 1) + "-" + seconddate.getDate() + " " + seconddate.getHours() + ":" + seconddate.getMinutes() + ":" + seconddate.getSeconds();

            if (!end_date) {
                end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
            }
            var reqData = {
                truck_no: e.data.truck_no,
                consignment_code: e.data.consignment_code,
                consignee_code: e.data.consignee_code,
                from_date: gateouttime,
                to_date: end_date,
                map_mode: 1
            }
            // console.log("reqData", reqData)
            redirectURL.post('/consignments/maproutesforsnd22', reqData, {
                headers: {
                    'content-type': 'application/json'
                }
            })
                .then((response) => {
                    var records = response.data;
                    // console.log("Props data ", records)

                    this.setState({
                        loadshow: 'show-m'
                    });
                    try {
                        if (records.coords.length == 0 || records.status == "failure") {
                            //console.log("records", records.coords);
                            this.setState({
                                show: true,
                                basicTitle: 'No Route data available',
                                basicType: "danger",
                                loadshow: 'show-n'
                            });
                        }
                        else {
                            if (records != '') {
                                var sdate = e.data.gate_out_time;
                                if (e.data.invoice_time) {
                                    // console.log("came into invoice time", e.invoice_time)
                                    //console.log("Step1", e.invoice_time)
                                    var sdate = e.data.invoice_time;
                                }
                                else {
                                    //console.log("Step2")
                                    if (e.data.gate_in_time) {
                                        // console.log("came into gate in time", e.gate_in_time)
                                        //console.log("Step3")
                                        var sdate = e.data.gate_in_time;
                                    }
                                    else {
                                        //console.log("Step4")
                                        if (e.data.gate_out_time) {
                                            //console.log("Step5")
                                            // console.log("came into gate out time", e.gate_out_time)
                                            var sdate = e.data.gate_out_time;
                                        }
                                    }
                                }


                                var edate = '';
                                if (e.data.status <= 4) {
                                    //var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1)*86400000);
                                    //var edatesecond = new Date(ms);
                                    end_date = moment.parseZone().format('DD/MM/YYYY HH:mm:ss')
                                    edate = getHyphenDDMMYYYYHHMMSS(end_date);
                                }
                                else if (e.data.status == 5) {
                                    if (e.data.left_dealer_marked) {
                                        edate = getHyphenDDMMYYYYHHMMSS(e.data.left_dealer_marked);
                                    }
                                    else {
                                        end_date = moment.parseZone().format('DD/MM/YYYY HH:mm:ss')
                                        edate = getHyphenDDMMYYYYHHMMSS(end_date);
                                    }
                                }
                                //console.log("End Date ", edate)
                                this.setState({
                                    sliderRouteTranslate: "slider-translate-60p",
                                    showDiv: 'show-m',
                                    mapinfo: records,
                                    dealer: e.data.consignee_code,
                                    consignment_code: "Consignment : " + e.data.consignment_code,
                                    maptruckno: e.data.truck_no,
                                    routeTruck: { "truck_no": e.data.truck_no, "startTime": sdate, "endTime": end_date },
                                    loadshow: 'show-n',
                                    sidebarSubHeader: "Consignment Information",
                                    overly: 'show-m',
                                    rownode: e,
                                    leg_no: 0,
                                    tolls: records.tolls
                                });
                            }
                        }
                    }
                    catch (e) {
                        console.log(e, "error")
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
                // console.log("reqData", reqData)
                redirectURL.post('/consignments/snd22digilogs', e.data, {
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then((response1) => {
                    var digiAlertsData = response1.data;
                    this.setState({
                        digiAlertsData:digiAlertsData
                    })
                })
        }
    }

    onDigiAlertsClicked = () => {
        this.setState({            
            open:true,
            loadshow:'show-n'
        });
    }

    onCellUpdateData = e => {
        if (e.colDef.field == 'trip_delivery_date') {
            const { tabOneactive, tabTwodelivered } = this.state
            if (e.data.trip_delivery_date !== undefined && e.data.trip_delivery_date !== '') {
                // console.log("e",e.data.trip_delivery_date)
                if (tabOneactive == 'activet') {
                    var reqparams = {
                        consignment_code: e.data.consignment_code,
                        trip_delivery_date: e.data.trip_delivery_date,
                        gate_out_time: e.data.gate_out_time,
                        status: e.data.status,
                        msil_pod_marked: 1,
                        tab_one: true
                    }
                } else {
                    reqparams = {
                        consignment_code: e.data.consignment_code,
                        trip_delivery_date: e.data.trip_delivery_date,
                        tab_two: true,
                        status: e.data.status,
                    }

                }
                redirectURL.post("/consignments/updateD22consignmentsStatus", reqparams).then(response => {
                    let responseData = response.data.message;
                    // console.log("timerowData", responseData)
                    if (responseData == "success") {

                    }
                    else {
                        this.setState({
                            basicType: "warning",
                            basicTitle: responseData,
                            show: true,
                        })
                        e.data.trip_delivery_date = "";
                        this.gridApi.refreshCells();
                    }
                })
            }
        }
    }

    onSetCritical(params, status) {
        // console.log("onSetCritical ", params);
        // console.log("onSetCritical value ", status);
        redirectURL.post('/consignments/setCriticalStatusForD22', {
            params: params,
            is_critical: status,
            token: window.atob(localStorage.getItem('_t')),
            userId: localStorage.getItem("userid"),
        })
            .then((response) => {

                //var records = JSON.parse(JSON.stringify(response)).data;
                // console.log(response.data);
            });

    }

    validDateTime = current => {
        // var tommorow = moment().add( 1, 'day' );
        return current.isBefore();
    }

    /*NO GPS DATA*/


	onShowNoGPSKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.gpsAvailable;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowNoGPSKpi, params = ",params);
		//console.log(params);
		//console.log("Consign onShowNoGPSKpi params = ",params);
		//console.log(params.column.colId);
		if(params != 0)
		{
			var transitedelaydata=[];
			
			await this.setState({
					
				detailCellRendererParams:{
					suppressRefresh: true,
					detailGridOptions: {
						columnDefs: [
							{ 
								headerName: "",
								field: "_id",
								cellRendererFramework:RoutemapNoGPSAction,
								width:50,
								suppressSizeToFit: true,
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							},
							
							{ headerName: "Truck No",field: "truck_no", width:100,resizable: true,
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							},
							{ 
								headerName: "Last Data Receieved",
								field: "last_packet_datetime", 
								width:180,
								resizable: true,
								valueGetter:function(params){
									return getDDMMYYYYHHMMSS(params.data.last_packet_datetime);
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							},
							{ 
								headerName: "Data Received on",
								field: "data_received_on", 
								width:180,
								resizable: true,
								valueGetter:function(params){
									return getDDMMYYYYHHMMSS(params.data.packet_received_on);
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
						 	}						
						],
						overlayNoRowsTemplate: 'No rows to show',					
					},
					getDetailRowData: function(params) {
						//console.log("Step 4 ",transitedelaydata)
						//console.log("Step 4 ",params)
						redirectURL.post('/consignments/snd22nogpsdata', {
							consignment_code:params.data.consignment_code, 
							truck_no:params.data.truck_no
						})
						.then(async (response) =>{
							//console.log("Step 1 ",response.data)
							transitedelaydata=response.data;
							var legsarr=[]
							transitedelaydata.map((item) => {
								//if(item.trip_completed < 2)
								//{
								legsarr.push(item)
								//}
							})
							// console.log('transitedelaydata ', transitedelaydata);

							params.successCallback(legsarr);
							//console.log("Step 2 ",response.data)
							
						});
						
					}
				}
			});
			
			//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
			if(params.column.colDef.field == 'transit_delay' || params.column.colDef.field == 'no_gps_data')
			{
				params.node.setExpanded(!params.node.expanded);
				//console.log("expanded ",params.node.expanded)
				if(params.node.expanded == false)
				{
					transitedelaydata=[]
				}
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			this.setState({
			
				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed (km/h)",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]
					  
					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}
		
    }
    
    onShowNoGPSRouteMap(rownode){
		//console.log("IN onShowNoGPSRouteMap, params = ",rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		//console.log("rownode", rownode);
		this.setState({
			loadshow:'show-m'
		})
		var fintance = rownode.last_packet_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		//var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = { 
			truck_no:rownode.truck_no,
			consignment_code:rownode.consignment_code,
			//consignee_code:rownode.consignee_code,
            first_instance:fintance,
			recent_instance:eintance,
			screen:"consignment"
		}
		 redirectURL.post('/reports/nightdriveroutes',params)
		  .then((response) => {	
		  
		   //var records = JSON.parse(JSON.stringify(response)).data;
			//console.log("Inner Grid ",response.data);
			if(response.data.coords.length == 0)
			{
				this.setState({
					show: true,
					basicTitle:'No Route data available',
					basicType:"danger",
					loadshow:'show-n'
				});
			}
			else{
				var sdate='';
				var edate='';

				if(rownode.leg_start != '' && rownode.leg_start != undefined)
				{
					sdate=rownode.leg_start;
				}
				if(rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined)
				{
					edate=getDDMMYYYYHHMMSS(rownode.leg_end_eta)
				}
			this.setState({
					mapinfo:response.data,
					dealer:rownode.consignee_code,
					sliderRouteTranslate:"slider-translate-60p",
					defTransitCoords: rownode.consignee_coordinates,
					consignment_code:"Consignment : "+rownode.truck_no,
					maptruckno:rownode.truck_no,
					routeTruck:	{"truck_no":rownode.truck_no,"startTime":sdate,"endTime": edate},
					loadshow:'show-n',
					sidebarSubHeader:"Consignment Leg Information",
					overly:'show-m',
					rownode:rownode,
					leg_no:rownode.leg_no,
					tolls : response.data.tolls

			});

			// this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
				this.renderMap();
			
			} 
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
    }
    
    onShowDigiLockRouteMap(rownode)
    {
        var lattitude = rownode.latitude;
        var longititude = rownode.longitude;
        var locat = rownode.location;
        
        console.log(lattitude,"lattitude")
        console.log(longititude,"longititude")
        var latLngArray = [];
        if(lattitude != "" && lattitude !=undefined && longititude != "" && longititude !=undefined)
        {
            latLngArray.push({
                lat : lattitude,
                lng : longititude,
                locat : locat
            });
            this.setState({
                latLngArray : latLngArray,
                mapType : 4,
                locInfo : locat,
                bulkslide : "slider-translate-60p",
                overly: "show-m",
                sliderTitle : rownode.elock_id,
                mapShow : 1
            })
        }
        else
        {
            
        } 
    }
    
    onShowOverspeedKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.overspeed;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowOverspeedKpi, params = ",params);
		//console.log(params.column.colId);
		if(params != 0)
		{
			var overspeedData=[];
			
			//console.log("Step 3 ",overspeedData)
			var propsdata = params;
			await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							{ headerName:"",field:"_id", 
								width:50,
								cellRendererFramework:RoutemapAction,
								resizable: true
							},
							{ headerName: "Truck No",field: "truck_no", filter:true,resizable: true },
							{ headerName: "Speed (km/h)",field: "speed", filter:true,resizable: true },
							{ headerName: "Reported At",field: "first_instance", filter:true,resizable: true,
								valueGetter:function(params){
									return getDDMMYYYYHHMMSS(params.data.first_instance);
								}
							},
						],
						overlayNoRowsTemplate: 'No rows to show',
														
					},
					getDetailRowData: function(param) {
						// console.log("Step 4 ",param)
						redirectURL.post('/consignments/snd22overspeed', {
							consignment_code:param.data.consignment_code, 
							truck_no:param.data.truck_no
						})
						.then(async (response) =>{
							//console.log("Step 1 ",response.data)
							overspeedData=response.data;
							param.successCallback(overspeedData);
							//console.log("Step 2 ",response.data)
							
						});
						 
					},
					masterDetail: true
				}
			});
			
			if(propsdata.column.colDef.field == 'overspeeding_exception')
			{

				propsdata.node.setExpanded(!propsdata.node.expanded);
			}
			else{

				propsdata.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			this.setState({
			
				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]
					  
					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}
		
	}

    onShowEnrouteStoppagesKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.enrouteStoppages;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowEnrouteStoppagesKpi, params = ",params);
		//console.log(e);
		//console.log("Consign Overspeed ",params);
	
		if(params != 0)
		{
			var overspeedData=[];
			

			//console.log("Step 3 ",overspeedData)
			
			await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							
							{ headerName: "",field: "_id", 
							
								cellRendererFramework:RoutemapenrouteAction,
								width:50,
								suppressSizeToFit: true,
								
							},
							{ 
								headerName: "Break Start",
								field: "stoppage_start_time", 
								width:150,
								resizable: true,
								valueGetter:function(params){
									if(params.data.stoppage_start_time != undefined)
									{
										return getDDMMYYYYHHMMSS(params.data.stoppage_start_time)
									}
									else{
										return '';
									}
									
								}
						    },
							// { 
							// 	headerName: "Break End",
							// 	field: "reset_start_time", 
							// 	width:180,
							// 	resizable: true,
							// 	valueGetter:function(params){
							// 		if(params.data.reset_start_time != undefined)
							// 		{
							// 			return getDDMMYYYYHHMMSS(params.data.reset_start_time)
							// 		}
							// 		else{
							// 			return '';
							// 		}
									
							// 	}
							// },

							{ 
								headerName: "Exception Closed On",
								field: "modified_date", 
								width:220,
								resizable: true,
								valueGetter:function(params){
									if(params.data.exception_closed ==1)
									{
											
										if(params.data.modified_date != undefined)
										{
											return getDDMMYYYYHHMMSS(params.data.modified_date)
										}
										else{
											return '';
										}
									}
									else{
										return '';
									}
									
								}
							},
							{ 
								headerName: "Break Time",
								field: "elaped_time_from_last_moved_seconds",
								width:180,
								resizable: true,
								valueGetter:function(params){
									if(params.data.exception_closed == 1)
									{
										// if(params.data.elaped_time_from_last_moved_seconds != undefined)
										// {
										// 	var seconds = params.data.elaped_time_from_last_moved_seconds;
										// 	var days = parseInt(seconds / (24 * 3600)); 
										// 	var hours = parseInt(seconds/3600);
										// 	var minutes = Math.round((seconds-(hours*3600))/60)
										// 	//return hours+" Hr(s) "+minutes+" Min(s)";
										// 	return secondsToString(seconds)
										// }
										// else{
										// 	return '';
										// }
										var sdate = moment.parseZone(params.data.stoppage_start_time).format("x");
										if(typeof params.data.reset_datetime == 'undefined')
										{
											var edate = moment.parseZone().format("x");
										}
										else
										{
											var edate = moment.parseZone(params.data.reset_datetime).format("x");
										}
										var diff = (edate-sdate)/1000;
										return secondsToString(diff);
									}
									else{
										return '';
									}
								}
							}
						],
						overlayNoRowsTemplate: 'No rows to show',					
					},
					getDetailRowData: async function(params) {
						//console.log("Step 4 ",overspeedData)
						if(params.data.is_transshipment == 1)
						{
							var oldtruck = params.data.old_truck_no
						}
						else
						{
							var oldtruck = ''
						}
						redirectURL.post('/consignments/snd22enroutes', {
							consignment_code:params.data.consignment_code, 
							truck_no:params.data.truck_no, 
							old_truck_no:oldtruck
						})
						.then(async (response) =>{
							//console.log("Step 1 ",response.data)
							overspeedData=response.data;
							params.successCallback(overspeedData);
							//console.log("Step 2 ",response.data)
							
						});
						
					}
				}
			});
			
			if(params.column.colDef.field == 'enroute_exception')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			this.setState({
			
				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed (km/h)",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]
					  
					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}
		
    }
    
    onShowEnrouteRouteMap(rownode){
		//console.log("IN onShowEnrouteRouteMap, params = ",params);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		//console.log("rownode", rownode);
		this.setState({
			loadshow:'show-m'
		})
		var fintance = rownode.stoppage_start_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		if(rownode.reset_datetime)
		{
			var eintance = rownode.reset_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		
		}
		else{
			var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		
		}
		//var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = { 
			truck_no:rownode.truck_no,
			//consignment_code:rownode.consignment_code,
			//consignee_code:rownode.consignee_code,
            first_instance:fintance,
			recent_instance:eintance,
			screen:"consignment"
		}
		 redirectURL.post('/reports/nightdriveroutes',params)
		  .then((response) => {	
		  
		   //var records = JSON.parse(JSON.stringify(response)).data;
			//console.log("Inner Grid ",response.data);
			if(response.data.coords.length == 0)
			{
				this.setState({
					show: true,
					basicTitle:'No Route data available',
					basicType:"danger",
					loadshow:'show-n'
				});
			}
			else{
				var sdate='';
				var edate='';

				if(rownode.leg_start != '' && rownode.leg_start != undefined)
				{
					sdate=rownode.leg_start;
				}
				if(rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined)
				{
					edate=getDDMMYYYYHHMMSS(rownode.leg_end_eta)
				}
			this.setState({
					mapinfo:response.data,
					dealer:rownode.consignee_code,
					sliderRouteTranslate:"slider-translate-60p",
					defTransitCoords: rownode.consignee_coordinates,
					consignment_code:"Consignment : "+rownode.truck_no,
					maptruckno:rownode.truck_no,
					routeTruck:	{"truck_no":rownode.truck_no,"startTime":sdate,"endTime": edate},
					loadshow:'show-n',
					sidebarSubHeader:"Consignment Leg Information",
					overly:'show-m',
					rownode:rownode,
					leg_no:rownode.leg_no,
					tolls : response.data.tolls

			});

			// this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
				this.renderMap();
			
			} 
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
	}

    onShowLoadingDelayKpi = async (params) => {
		
		let eventLabel = googleAnalytics.page.action.loadingDelay;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowLoadingDelayKpi, params = ",params);
		//console.log(e);
		//console.log("Consign onShowLoadingDelayKpi ",params);
		//console.log(params.column.colId);
		if(params != 0)
		{
			var overspeedData=[];

			var hideonTNP = (this.state.deptcode == 'LOG-TNP')?true:false
			var showonTNP = (this.state.deptcode == 'LOG-TNP')?false:true
			await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							
							{ 
								headerName: "Gate In Time",
								field: "gate_in_time", 
								width:180,
								resizable: true,
								hide:hideonTNP,
								valueGetter:function(params){
									
									if(params.data.gate_in_time)
									{
										//return getDDMMYYYYHHMMSS(params.data.gate_in_time);
										return params.data.gate_in_time;
									}
									else
									{
										return "NA"
									}
									
								}
							},
							
							{ 
								headerName: "Gate Out Time",
								field: "gate_out_time", 
								width:180,
								resizable: true,
								hide:hideonTNP,
								valueGetter:function(params){
								//	console.log("IN gate_out_time valueGetter, params = ", params);
										
									if(params.data.gate_out_time)
									{
										//console.log("IN gate_out_time valueGetter, IF params.data.gate_out_time = ", params.data.gate_out_time);
										//return getDDMMYYYYHHMMSS(params.data.gate_out_time);
										return params.data.gate_out_time;
									}
									else
									{
										//console.log("IN gate_out_time valueGetter, ELSE params.data.gate_out_time = ", params.data.gate_out_time);
										return "NA"
									}
								}
							},

							
							{ 
								headerName: "Gate In Time",
								field: "gate_in_time", 
								width:180,
								resizable: true,
								hide:showonTNP,
								valueGetter:function(params){
									// console.log("IN gate_out_time valueGetter, params = ", params);
										
									if(params.data.gate_in_time)
									{
										// console.log("IN gate_out_time valueGetter, IF params.data.gate_out_time = ", params.data.gate_in_time);
										//return getDDMMYYYYHHMMSS(params.data.gate_out_time);
										return params.data.gate_in_time;
									}
									else
									{
										// console.log("IN gate_out_time valueGetter, ELSE params.data.gate_out_time = ", params.data.gate_in_time);
										return "NA"
									}
								}
							},

							
							{ 
								headerName: "Gate Out Time",
								field: "gate_out_time", 
								width:180,
								resizable: true,
								hide:showonTNP,
								valueGetter:function(params){
									// console.log("IN gate_out_time valueGetter, params = ", params);
										
									if(params.data.gate_out_time)
									{
										// console.log("IN gate_out_time valueGetter, IF params.data.gate_out_time = ", params.data.gate_out_time);
										//return getDDMMYYYYHHMMSS(params.data.gate_out_time);
										return params.data.gate_out_time;
									}
									else
									{
										// console.log("IN gate_out_time valueGetter, ELSE params.data.gate_out_time = ", params.data.gate_out_time);
										return "NA"
									}
								}
							},
							{
								headerName:"Loading Time",
								field:"loading_time",
								width:180,
								
							}
													
						],
						overlayNoRowsTemplate: 'No rows to show',
						
									
					},
					getDetailRowData: function(params) {
							// console.log("Step 4 ",params)
							if(hideonTNP != true)
							{
								// console.log("Inner here")
								if(params.data.gate_in_time != undefined)
								{
									var g1 = getDDMMYYYYHHMMSS(params.data.gate_in_time);
									//var g1 = (params.data.gate_in_time) ? moment.parseZone(params.data.gate_in_time).format('DD-MM-YYYY') : "";
									var gdate1 = new Date(params.data.gate_in_time);
									var fromDate = g1;
									var secondsgate1 = moment.parseZone(new Date(params.data.gate_in_time)).format("x")
								}
								else{
									
									var g1 = getDDMMYYYYHHMMSS(params.data.inside_fence);
									var gdate1 = new Date(params.data.inside_fence);
									var fromDate = g1;
									var secondsgate1 = moment.parseZone(new Date(params.data.inside_fence)).format("x")
								
								}
								//console.log("g1 ", g1)//console.log("Step 4 ",params)
								if(params.data.gate_out_time != undefined)
								{
									var g2 = getDDMMYYYYHHMMSS(params.data.gate_out_time);
									var gdate2 = new Date(params.data.gate_out_time);
									var toField = getDDMMYYYYHHMMSS(params.data.gate_out_time);
									//console.log("gatein ", gatein)
									//console.log("gateout ", gateout)
									//var total =0 ;
									var secondsgate2 = moment.parseZone(new Date(params.data.gate_out_time)).format("x")
								
								}
								else{
									var c = new Date();
									var todaydate = moment(new Date(c)).format("x");
									var g2 = getDDMMYYYYHHMMSS(todaydate);
									var gdate2 = new Date(todaydate);
									var toField = getDDMMYYYYHHMMSS(new Date());
									var secondsgate2 = todaydate
								
								}
							}
							else
							{
								var fromDate = getDDMMYYYYHHMMSS(params.data.inside_fence);
								var secondsgate1 = moment.parseZone(new Date(params.data.inside_fence)).format("x")
								var secondsgate2 = moment.parseZone(new Date(params.data.outside_fence)).format("x")
								var toField = getDDMMYYYYHHMMSS(params.data.outside_fence);
							}
						
						//console.log("secondsg1 ", secondsg1)
						//var g2 = getDDMMYYYY(params.data.gate_out_time);

						//console.log("g1 ", g2)
						// var s1 = g1.split("-");
						// var s2 = g2.split("-");
						
						
						// var gatein = gdate1.getTime();
						// var gateout = gdate2.getTime();
						var total = secondsgate2-secondsgate1;
						//console.log("total ", total)
						var diffMs = total;
						var diffDays = Math.ceil(diffMs / 86400000); // days
						var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
						var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000); 
						// console.log(diffDays+" "+diffHrs+" "+diffMins);
						let loadingTimeDifference = null;

						var dataarr = []
							
							dataarr.push({
								"gate_in_time": fromDate,
								"gate_out_time": toField,
								//"loading_time": diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
								"loading_time": secondsToString(diffMs/1000)
							})
							// console.log("dataarr ", dataarr)
							params.successCallback(dataarr);
					}
					
				}
			});
			
			//if(params.column.colId == 'loading_delay_exception')
			
			if(params.column.colDef.field == 'loading_delay_exception')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			
		}
		
    }
    
    onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
	   this.setState({ open: false });
	 };

    render() {
        const modalStyles  = {
            width:'500px !important',
            }
        const { open } = this.state;
        var maptransporter = '';
        try {
            if (this.state.rownode.transporter) {
                ///console.log("map rownode ",this.state.rownode.transporter[0])
                maptransporter = this.state.rownode.transporter[0].transporter_name;
            }
        }
        catch (e) {
            console.log(e)
        }

        const columnwithDefs = [
            {
                headerName: "",
                field: "maproute",
                colId: "maproute",
                width: 50,
                pinned: 'left',
                // cellRenderer:'consignmentActions',
                filter: false, resizable: true,
                cellRenderer: (params) => {
                    return '<i class="icofont icofont-map-pins f25"></i>'
                }
                // cellRenderer : e =>{
                //     return(
                //     <div className="map-icon-component">
                //         <button
                //         onClick={this.handleRouteClick(e)}
                //         className="custom-btn label label-success" title="View Route"><i className="icofont icofont-map-pins f25"></i> </button>               
                //     </div>
                //     )
                // }

            },
            {
                headerName: "Transit Delay",
                field: "transit_delay",
                colId: "transit_delay",
                width: 40,
                pinned: 'left',
                pivot: false,
                cellRendererSelector:function(params){
                    if(params.data.transit_delay == 1)
                    {
                        var rendComponent = {
                            component: 'kpiTransitDelayActions'
                        };
                        return rendComponent
                    }
                    else{
                        var rendComponent = {
                            component: 'notransit'
                        };
                        return rendComponent
                    }
                },
                filter: true, resizable: true,
                hide: false
            },
            {
                headerName: "",
                field: "no_gps_data",
                colId: "no_gps_data",
                width: 40,
                pinned: 'left',
                pivot: false,
                cellRendererSelector:function(params){
                    var comp='gps';	
                    if(params.data.no_gps_data == 0 && params.data.no_gps_data_lifetime == 1)
                    {
                        comp='noGpsLifetime'
                        
                    }
                    else if(params.data.no_gps_data == 1) 
                    {
                        comp='nogps'
                    }
                    
                    else if(params.data.no_gps_data == 0)
                    {
                        comp='gps'
                    
                    }
                    var rendComponent = {
                        component:comp
                    };
                    return rendComponent
                },
                filter: true,resizable: true
            },
            {
                headerName: "Overspeed",
                field: "overspeeding_exception",
                colId: "overspeeding_exception",
                width: 40,
                pinned: 'left',
                pivot: false,
                cellRendererSelector: function (params) {
                    if (params.data.overspeeding_exception == 1) {
                        var rendComponent = {
                            component: 'kpiActions'
                        };
                        return rendComponent
                    }
                    else {
                        var rendComponent = {
                            component: 'NoOverspeed'
                        };
                        return rendComponent
                    }
                },
                filter: true,
                resizable: true,
                hide: false
            },
            {
                headerName: "Night Drive",
                field: "nightdriving_exception",
                colId: "nightdriving_exception",
                width: 40,
                pinned: 'left',
                pivot: true,
                hide: false,
                cellRendererSelector: function (params) {
                    //console.log("Except ",params.data.nightdriving_exception)
                    //console.log("Consignemnt code ", params.data.consignment_code)
                    if (params.data.nightdriving_exception != undefined && params.data.nightdriving_exception == 1) {
                        var rendComponent = {
                            component: 'kpiNightDriveActions'
                        };
                        return rendComponent
                    }
                    else {
                        var rendComponent = {
                            component: 'nonightdrive'
                            //component: 'kpiNightDriveActions'
                        };
                        return rendComponent
                    }
                },
                filter: true, resizable: true,
            },
            {
                headerName: "Loading Delay",
                field: "loading_delay_exception",
                colId: "loading_delay_exception",
                width: 40,
                pinned: 'left',
                pivot: false,
                hide: false,
                cellRendererSelector: function (params) {
                    var rendComponent = {
                        component: 'KpiLoadingDelayActions'
                    };
                    return rendComponent;
                },
                filter: true,resizable: true
            },
            {
                headerName: "Enroute Stoppages",
                field: "enroute_exception",
                colId: "enroute_exception",
                width: 40,
                pinned: 'left',
                pivot: false,
                cellRendererSelector: function (params) {

                    var rendComponent = {
                        component: 'kpiEnrouteStoppagesActions'
                    };
                    return rendComponent

                },
                filter: true, resizable: true,
                hide: false
            },
            {
                headerName: "Actions",
                field: "comment_exists",
                colId: "comment_exists",
                //colId: "_id",
                width: 40,
                pinned: 'left',
                cellRenderer: 'commentActions',
                filter: true,
                resizable: true,
                hide: true

            },
            {
                headerName: "",
                field: "tickets",
                colId: "tickets",
                //colId: "_id",
                width: 40,
                pinned: 'left',
                filter: true,
                resizable: true,
                hide: true,
                cellRendererSelector: function (params) {

                    var rendComponent = {
                        component: 'Consignmenttickets'
                    };
                    return rendComponent

                },
                

            },
            {
                headerName: "digi lock",
                field: "digi_lock_alert",
                colId: "digi_lock_alert",
                width: 40,
                pinned: 'left',
                pivot: true,
                hide: false,
                cellRendererSelector: function (params) {
                    //console.log("Except ",params.data.digi_lock_alert)
                    //console.log("Consignemnt code ", params.data.consignment_code)
                    if (params.data.digi_lock_alert != undefined && params.data.digi_lock_alert == 1) {
                        var rendComponent = {
                            component: 'ConsignDigilock'
                        };
                        return rendComponent
                    }
                    else {
                        var rendComponent = {
                            component: 'NonDigilock'
                            //component: 'ConsignDigilock'
                        };
                        return rendComponent
                    }
                },
                filter: true, resizable: true,
            },
            {
                headerName: "Consignment Code",
                field: "consignment_code",
                colId: "consignment_code",
                width: 140,
                pinned: 'left',
                editable: false,
                filter: "agSetColumnFilter", resizable: true,
                //cellRenderer : "agGroupCellRenderer"
            },
            {
                headerName: "VIN No(s)",
                field: "vin_no",
                colId: "vin_no",
                width: 120,
                pinned: "left",
                editable: false,
                resizable: true,
                hide: false
            },
            {
                headerName: "Status",
                field: "status",
                colId: "status",
                width: 120,
                pinned: 'left',
                hide: false,
                filter: "agSetColumnFilter",
                resizable: true,
                valueGetter: (params) => {
                    //console.log("GPS ", params.data.gps_data_available);
                    if (params.data.status == 1) {
                        return "Inside Plant"
                    }
                    else if (params.data.status == 2) {
                        return "In Transit"
                    }
                    // else if(params.data.dest_geofence_id != undefined && screen_type == "LOG-PRT")
                    // {
                    //     if(screen_url != "/prtconsignments")
                    //     {
                    //         return "Reached Plant Gate";
                    //     }
                    // }
                    else if (params.data.status == 3) {
                        return "Near Destination"
                    }
                    else if (params.data.status == 4) {
                        return "Reached Destination"
                    }

                    else if (params.data.status == 5) {
                        return "Left Destination"
                    }
                    else if (params.data.status == 6) {
                        return "Force Closed"
                    }
                    else {
                        return ""
                    }

                }
            },
            {
                headerName: "Original ETA",
                field: "expected_trip_end",
                colId: "expected_trip_end",
                id: "expected_trip_end",
                width: 130,
                //pinned: 'left',             
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.expected_trip_end != "" && params.data.expected_trip_end != undefined && params.data.expected_trip_end != null) {
                        return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
                    }
                    else {
                        return "";
                    }


                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,

            },
            {
                headerName: "Revised ETA",
                field: "revised_trip_end",
                colId: "revised_trip_end",
                width: 130,
                //pinned: 'left',             
                resizable: true,
                // cellClass : "dateFormat",
                valueGetter: function (params) {
                    //console.log(" ETA ", params.data)
                    if (params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined) {
                        return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
                    }
                    else {
                        return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end);
                    }

                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,


                cellClass: function (params) {
                    let hourDifference = 0;
                    if (params.data.revised_trip_end && params.data.expected_trip_end) {
                        let revisedDateOnlyMoment = moment(params.data.revised_trip_end);
                        let expectedDateOnlyMoment = moment(params.data.expected_trip_end);
                        hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours');
                        //console.log("diff= ", params.data.consignment_code, hourDifference);
                    } else {
                        //console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
                    }

                    if (hourDifference >= 1) {
                        return 'bgColorDangerMedium'
                    }

                    if (hourDifference <= -1) {
                        return 'bgColorSuccessMedium'
                    }
                    else {
                        return ''
                    }
                }
            },
            {
                headerName: "Transit Delay (Days)",
                field: "consignment_code",
                colId: "consignment_code",
                width: 150,
                filter: "agSetColumnFilter",
                cellRenderer: '',
                resizable: true,
                valueGetter: function (params) {
                    var expected = params.data.expected_trip_end;
                    if (params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined) {
                        var revised = params.data.expected_trip_end;
                    }
                    else {
                        var revised = params.data.revised_trip_end;
                    }
                    //console.log("Exp ", expected)
                    //console.log("revised ", revised)
                    var s = moment.parseZone(expected).format("x");
                    var ed = moment.parseZone(revised).format("x");
                    //console.log("Exp ", s)
                    //console.log("revised ", ed)
                    var diff = (ed - s)
                    // console.log("Diff",diff/1000)
                    var days = secondsToDays(diff)
                    //console.log("Days ", Math.round(days/1000))
                    var totaldays = Math.round(days / 1000);
                    if (totaldays > 0) {
                        return totaldays;
                    }
                    else {
                        return 0;
                    }
                    //return Math.round(days/1000);

                }
            },
            {
                headerName: "Truck No",
                field: "truck_no",
                colId: "truck_no",
                width: 120,
                pinned: "left",
                filter: "agSetColumnFilter",
                resizable: true
            },
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                colId: "transporter_name",
                width: 150,
                filter: "agSetColumnFilter",
                cellRenderer: '',
                resizable: true
            },
            {
                headerName: "Transporter Code",
                field: "transporter_code",
                colId: "transporter_code",
                width: 110,
                filter: "agSetColumnFilter",
                hide: false,
                cellRenderer: '',
                resizable: true
            },
            {
                headerName: "Last City/Town",
                field: "truck",
                colId: "truckCity",
                width: 150,
                // hide:showOnlyCol,
                valueGetter: function (params) {
                    // console.log("truckCity", params.data.truck);
                    if (params.data.hasOwnProperty('truck')) {
                        if (params.data.truck.length > 0) {
                            return params.data.truck[0].area;
                        }
                    }

                },
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Last State",
                field: "truck",
                colId: "truckState",
                width: 120,
                // hide:showOnlyCol,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true,
                valueGetter: function (params) {
                    //console.log(params.data.truck);
                    if (params.data.hasOwnProperty('truck')) {
                        if (params.data.truck.length > 0) {
                            return params.data.truck[0].state;
                        }
                    }

                }
            },

            {
                headerName: "Last Packet Time",
                field: "truck",
                colId: "truckPacketTime",
                // hide:showtimestamp,
                valueGetter: function (params) {
                    //console.log(params.data.transporter);
                    if (params.data.hasOwnProperty('truck')) {
                        if (params.data.truck.length > 0) {
                            if (params.data.truck[0].manual_timestamp == undefined) {
                                return getHyphenDDMMMYYYYHHMM(params.data.truck[0].timestamp);
                            }
                            else {
                                var s1 = moment.parseZone(params.data.truck[0].timestamp).format("x");
                                var s2 = moment.parseZone(params.data.truck[0].manual_timestamp).format("x");
                                if (s1 > s2) {
                                    return getHyphenDDMMMYYYYHHMM(params.data.truck[0].timestamp);
                                }
                                else {
                                    var tstamp = getHyphenDDMMMYYYYHHMM(params.data.truck[0].manual_timestamp);
                                    return tstamp + " (M)";
                                }
                            }
                        }
                    }

                },
                comparator: dateComparator,

                width: 150,
                //filter: "agDateColumnFilter",
                cellRenderer: '', resizable: true
            },
            {
                headerName: "GPS Provider",
                field: "truck",
                colId: "truckGPS",
                width: 150,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true,
                hide: true,
                valueGetter: function (params) {
                    //console.log(params.data.transporter);
                    if (params.data.hasOwnProperty('truck')) {
                        if (params.data.truck.length > 0) {
                            return params.data.truck[0].actual_lspuser;
                        }
                    }

                },
            },
            // {
            //     headerName: "Invoice No",
            //     field: "invoice_no",
            //     colId: "invoice_no",
            //     width: 150,
            //     resizable: true,
            //     hide: true,
            //     comparator: dateComparator,
            //     valueGetter: function (params) {
            //         if (params.data.invoice_no != '' && params.data.invoice_no != undefined) {
            //             return parseInt(params.data.invoice_no).toString();
            //         }
            //         else {
            //             return "";
            //         }
            //     },
            // },
            // {
            //     headerName: "Invoice Time",
            //     field: "invoice_time",
            //     colId: "invoice_time",
            //     width: 150,
            //     //filter: "agDateColumnFilter",
            //     cellRenderer: '', resizable: true,
            //     comparator: dateComparator,              
            //     valueGetter: function (params) {
            //         if (params.data.invoice_time != '' && params.data.invoice_time != undefined) {
            //             return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
            //         }
            //         else {
            //             return "NA";
            //         }
            //     },
            //     // hide: (this.state.tnpReturnConsignmentsVisibilty)?true:hideonlyPRT,
            //     hide: true
            // },
            {
                headerName: "Source",
                field: "consigner_code",
                colId: "consigner_code",
                width: 150,
                filter: "agSetColumnFilter",
                cellRenderer: '',
                resizable: true
            },
            {
                headerName: "Destination",
                field: "consignee_code",
                colId: "consignee_code",
                width: 150,
                filter: "agSetColumnFilter",
                cellRenderer: '',
                resizable: true,
                hide: false
            },
            {
                headerName: "Driver Name",
                field: "driver_name",
                colId: "driver_name",
                width: 120,
                filter: "agSetColumnFilter",
                resizable: true
            },
            {
                headerName: "Driver Phone No",
                field: "driver_phone_no",
                colId: "driver_phone_no",
                width: 120,
                filter: "agSetColumnFilter",
                resizable: true
            },
            {
                headerName: "DIGI Lock Serial No",
                field: "dg_serial_no",
                colId: "dg_serial_no",
                width: 140,
                filter: "agSetColumnFilter",
                resizable: true
            },
            {
                headerName: "Dist. From Destination (km)",
                field: "distance_from_dealer_location",
                colId: "distance_from_dealer_location",
                width: 150,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true,
                valueGetter: function (params) {
                    //console.log("params ", params.data);
                    if (typeof params.data.distance_from_dealer_location == 'undefined') {

                    }
                    else {
                        return Math.round(params.data.distance_from_dealer_location);
                    }

                },
                hide: false
            },
            // {
            //     headerName: "Consignee City",
            //     field: "consignee_city",
            //     colId: "consignee_city",
            //     width: 150,
            //     hide: false,
            //     filter: "agSetColumnFilter",
            //     cellRenderer: '', resizable: true
            // },
            // {
            //     headerName: "Consignee State",
            //     field: "consignee_state",
            //     colId: "consignee_state",
            //     width: 150,
            //     hide: false,
            //     filter: "agSetColumnFilter",
            //     cellRenderer: '', resizable: true
            // },
            {
                headerName: "Gate In Time",
                field: "gate_in_time",
                filter: true,
                width: 140,
                resizable: true,
                comparator: dateComparator,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time);
                },
                hide: false
            },
            {
                headerName: "GPS Gate in Time",
                field: "inside_fence",
                colId: "inside_fence",
                width: 180,
                //filter: "agDateColumnFilter",
                //cellRenderer:'dateFormater',
                comparator: dateComparator,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.inside_fence);
                },
                resizable: true,
                hide: false
            },
            {
                headerName: "Gate Out Time",
                field: "gate_out_time",
                filter: true,
                width: 140,
                resizable: true,
                comparator: dateComparator,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                },
                hide: false
            },
            {
                headerName: "GPS Gate out Time",
                field: "outside_fence",
                colId: "outside_fence",
                width: 180,
                hide: false,
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.outside_fence);
                },
                resizable: true
            },
            {
                headerName: "Transit Time (Days)",
                field: "transit_time",
                colId: "transit_time",
                width: 150,
                hide: false,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true
            },
            // {
            //     headerName: "Msil Pod Marked",
            //     field: "msil_pod_marked",
            //     colId: "msil_pod_marked",
            //     width: 100,
            //     hide: true,
            //     filter: "agSetColumnFilter",
            //     cellRenderer: '',
            //     resizable: true
            // },
            // {
            //     headerName: "Dist. Traveled last 24Hr (KM)",
            //     field: "distance_travelled_in_24_hrs_kms",
            //     colId: "distance_travelled_in_24_hrs_kms",
            //     //colId : "consignment_code",
            //     width: 120,
            //     editable: false,
            //     filter: "agSetColumnFilter", resizable: true,
            //     hide: true,
            //     valueGetter: function (params) {
            //         //console.log(params.data.distance_travelled_in_24_hrs_kms);
            //         if (params.data.distance_travelled_in_24_hrs_kms != "" && params.data.distance_travelled_in_24_hrs_kms != undefined) {
            //             return params.data.distance_travelled_in_24_hrs_kms;
            //         }
            //         else {
            //             return 0;
            //         }
            //     }
            //     //cellRenderer : "agGroupCellRenderer"
            // },
            // {
            //     headerName: "MSIL POD Date",
            //     field: "pod_date",
            //     colId: "pod_date",
            //     width: 110,
            //     valueGetter: (params) => {
            //         if (params.data.pod_date != undefined && params.data.pod_date != null) {
            //             return getHyphenDDMMMYYYYHHMM(params.data.pod_date)
            //         }
            //         else {
            //             return ""
            //         }
            //     },
            //     resizable: true,
            //     hide: false

            // },
            // {
            //     headerName: "Old Truck No",
            //     field: "old_truck_no",
            //     colId: "old_truck_no",
            //     width: 200,
            //     filter: "agSetColumnFilter",
            //     cellRenderer: '', resizable: true,
            //     hide: true
            // },
            // {
            //     headerName: "Transshipment Date",
            //     field: "transshipment_date",
            //     colId: "transshipment_date",
            //     width: 200,
            //     filter: "agSetColumnFilter",
            //     resizable: true,
            //     valueGetter: function (params) {
            //         return getHyphenDDMMMYYYYHHMM(params.data.transshipment_date);
            //     },
            //     hide: true
            // },
            {
                headerName: "Reached Destination At",
                field: "first_dealer_reported",
                colId: "first_dealer_reported",
                width: 180,
                hide: false,
                //cellRenderer:'dateFormater',
                valueGetter: function (params) {
                    if (params.data.status == 4 || params.data.status == 5) {
                        return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
                    }
                    else {
                        return "";
                    }

                },
                resizable: true,
            },
            {
                headerName: "Left Dealer At",
                field: "left_dealer_marked",
                colId: "left_dealer_marked",
                width: 180,
                hide: false,
                //cellRenderer:'dateFormater',
                valueGetter: function (params) {
                    if (params.data.status == 4 || params.data.status == 5) {
                        return getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked);
                    }
                    else {
                        return "";
                    }

                },
                resizable: true,
            },
            {
                headerName: "Expected Distance (KM)",
                field: "distance_in_km",
                colId: "distance_in_km",
                width: 200,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Quantity",
                field: "quantity",
                filter: true,
                width: 100,
                resizable: true,
            },
            {
                headerName: "Trip Delivery Date",
                field: "trip_delivery_date",
                filter: true,
                width: 140,
                resizable: true,
                editable: true,
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    console.log("params ", params);
                    if (params.data.trip_delivery_date != null && params.data.trip_delivery_date !== undefined) {
                        if (moment(params.data.trip_delivery_date, 'YYYY-MM-DD hh:mm', true).isValid() == false) {
                            return getHyphenDDMMYYYYHHMMSS(params.data.trip_delivery_date);
                        }
                        else {
                            return params.data.trip_delivery_date;
                        }

                    }
                    else {
                        return ""
                    }

                }

            },
            {
                headerName: "Critical",
                field: "is_critical",
                colId: "is_critical",
                width: 90,
                hide: true,
                cellRenderer: (params) => {
                    var input = document.createElement('input');
                    input.type = "checkbox";
                    params.value = 0;
                    var status = false;
                    if (params.data.is_critical == true) {
                        status = true;
                        input.checked = true;
                        params.value = 1;
                    }
                    input.addEventListener('click', (event) => {
                        //console.log(event);
                        params.value = !params.value;
                        //console.log(params);
                        this.onSetCritical(params.data, params.value)
                    });
                    return input;
                },
                filter: false, resizable: true

            },

        ]

        const columnwithDefsForAlerts = [            
            { headerName: "Elock Name", field: "elock_name", width: 150, resizable: true },
            {
                headerName: "Elock Id", field: "elock_id", width: 140, resizable: true,
               
            },
            {
                headerName: "Latitude", field: "latitude", width: 140, resizable: true,
                valueGetter:function(params){
                    try{
                      return params.data.latitude.toFixed(6);
                    }
                    catch(e){
                      return params.data.latitude;
                    }
                    
                }
                
                
            },
            {
                headerName: "Longitude", field: "longitude", width: 140, resizable: true,
                valueGetter:function(params){
                    try{
                      return params.data.longitude.toFixed(6);
                    }
                    catch(e){
                      return params.data.longitude;
                    }
                    
                }
            },
            {
                headerName: "Speed", field: "speed", width: 100, resizable: true,
                
            },
            
            {
                headerName: "Location", field: "location", width: 180, resizable: true,
               
            },
            {
                headerName: "Device Status", field: "device_status", width: 140,
                resizable: true,
                
            },
            {
                headerName: "Device Unlocked", field: "device_unlocked", width: 140,
                resizable: true,
                
            },
            {
                headerName: "Voltage", field: "voltage", width: 140,
                resizable: true,
               
            },
            {
                headerName: "Power Status", field: "power_status", width: 140,
                resizable: true,
               
            },
            {
                headerName: "Power Connected", field: "power_connected", width: 140,
                
               
            },
            {
                headerName: "Log Date", field: "log_timestamp", width: 160,
                resizable: true,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.log_timestamp)
                }
            }
        ]

        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>

                <div className="f16" style={{ marginBottom: "10px" }}>
                    <ul className="d-tabs">
                        <li onClick={this.onClickTab.bind(this, "active")} className={"lttabs " + (this.state.tabOneactive)}>
                            <button type="button" className="btn">Active</button>
                        </li>

                        <li onClick={this.onClickTab.bind(this, "delivered")} className={"lttabs " + (this.state.tabTwodelivered)}>
                            <button type="button" className="btn">Delivered</button>
                        </li>

                        <li onClick={this.onClickTab.bind(this, "createConsignments")} className={"lttabs " + (this.state.tabThreeconsignmentform)}>
                            <button type="button" className="btn">Create Consignments</button>
                        </li>
                        {(this.state.tabThreeCreateConsgData == "show-m") ? "" : <li
                        // className={"lttabs"}
                        >
                            <button onClick={this.reloadPageData} className="btn btn-info mr-5" style={{ float: "right" }}>Reset Filters</button>
                        </li>}
                    </ul>
                </div>
                {/* <div className="row" style={{ marginBottom: "1%" }}>
                    <div className="col-xl-12 col-lg-12">
                        <button onClick={this.reloadPageData} className="btn btn-info mr-5" style={{ float: "right" }}>Reset Filters</button>
                    </div>
                </div> */}
                <div className="row col-xl-12 col-lg-12">
                    <div className={this.state.activeClass}>
                        <div className="card" style={{ minHeight: "100px" }}>
                            <div className="row card-body" style={{ padding: '10px' }}>
                                <div className="row col-xl-12 col-lg-12" style={{ marginLeft: "0px" }}>
                                    <div className="form-group col-xl-5 col-lg-5">
                                        <label>Select Type :  </label>
                                        <Select
                                            className="border-radius-0"
                                            isMulti={false}
                                            id="selectedFilter"
                                            style={{ borderRadius: "0px" }}
                                            options={[
                                                { label: "Truck", vaue: "Truck" },
                                                { label: "Consignments", value: "Consignments" },
                                                { label: "Vin", value: "Vin" },
                                            ]} required />
                                    </div>

                                    <div className="form-group col-xl-5 col-lg-5">
                                        <label className="reason">Truck / Consignment No / Vin</label>
                                        <input type="text" name="reason" autoComplete="off" class="form-control" id="inputTruckConsignment" />
                                    </div>
                                </div>
                                <div className="form-group col-xl-2 col-lg-2">
                                    <button type="button" style={{ margin: "0 auto" }} onClick={this.getTruckConsignments} className="btn btn-success ml-3">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={this.state.activeClass}>
                        <div className="card" style={{ minHeight: "100px" }}>
                            <div className="row card-body" style={{ padding: '10px' }}>
                                <div className="row col-xl-12 col-lg-12" style={{ marginLeft: "0px" }}>
                                    <div className="form-group col-xl-5 col-lg-5">
                                        <label>From Date</label>
                                        <Datetime
                                            value={this.state.startDate}
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off' }}
                                            dateFormat="YYYY-MM-DD"
                                            name="startDate"
                                            onChange={this.handlerStartDateTime} />
                                    </div>

                                    <div className="form-group col-xl-5 col-lg-5">
                                        <label>To Date</label>
                                        <Datetime
                                            value={this.state.endDate}
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off' }}
                                            dateFormat="YYYY-MM-DD"
                                            onChange={this.handlerEndDateTime} />
                                    </div>
                                </div>
                                <div className="form-group col-xl-2 col-lg-2">
                                    <button type="button" style={{ margin: "0 auto" }} className="btn btn-success ml-3" onClick={this.onClickTripButton}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {(this.state.tabThreeCreateConsgData == "show-m") ? "" :
                        <div className="col-xl-12 col-lg-12">
                            <Counters
                                context={this}
                                countersjson={this.state.countersjson}
                                gridData={this.state.gridRowData}
                            // deptcode={this.state.deptcode}
                            />
                        </div>
                    }

                    <div className="card col-xl-12 col-lg-12">
                        <div className="card-header">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>D22 Consignments</span>
                                {/* {(this.state.tabThreeCreateConsgData == "show-m") ? <button className="btn btn-info" style={{ float: "right", marginRight: "10px" }} onClick={this.showBulkUpload.bind(this)}>Bulk Upload</button> : ''} */}
                            </h5>
                            {/* <div className={this.state.tabOneActiveData}>
                                    <button
                                        type="button"
                                        title="Bulk Upload"
                                        onClick={this.onBulkPriorityBtn}
                                        className={"float-right custom-btn white btn-danger mr-2 "}
                                        >
                                        Bulk Mark Critical
                                    </button>
                                </div> */}
                        </div>

                        <div className="card-body">

                            <div className={this.state.tabOneActiveData}                            >
                                <div id="myGrid" style={{ width: "100%", height: "478px" }} className={"ag-theme-balham"}>
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.gridRowData}
                                        enableCharts={false}
                                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        frameworkComponents={this.state.frameworkComponents}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        stopEditingWhenGridLosesFocus={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        masterDetail={true}
                                        // components={this.state.components}
                                        enableRangeSelection={true}
                                        onCellClicked={this.onCellClicked.bind(this)}
                                        onCellEditingStopped={this.onCellUpdateData}
                                    />
                                </div>
                            </div>

                            <div className={"ag-theme-balham" + this.state.tabTwoDeliveredData}>
                                {(this.state.tabTwoDeliveredData == "show-m") ?
                                    <div id="myGrid" style={{ width: "100%", height: "478px" }} className={"ag-theme-balham"}>
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.gridRowData}
                                            enableCharts={false}
                                            // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady1}
                                            onGridState={this.onGridState1}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            stopEditingWhenGridLosesFocus={true}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            masterDetail={true}
                                            // components={this.state.components}
                                            enableRangeSelection={true}
                                            onCellClicked={this.onCellClicked.bind(this)}
                                            onCellEditingStopped={this.onCellUpdateData}
                                        />
                                    </div>
                                    : ""}
                            </div>

                            <div className={"ag-theme-balham" + this.state.tabThreeCreateConsgData}>
                                {(this.state.tabThreeCreateConsgData == "show-m") ?
                                    // <div class="card row col-xl-12 col-lg-12 ">
                                    <div className="card-body col-xl-12 col-lg-12">
                                        {/* <div className="form-group">
                                                <h5>
                                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i>
                                                    <span>{this.state.pagetitle} </span>
                                                    <button className="btn btn-info" style={{ float: "right", marginRight: "10px" }} onClick={this.showBulkUpload.bind(this)}>Bulk Upload</button>
                                                </h5>
                                            </div> */}
                                        <form method="POST" id="upform" className="theme-form col-xl-12 col-lg-12" onSubmit={this.insertdttconsignments}>
                                            <div className="row">
                                                <div className="form-group col-xl-4 col-lg-4">
                                                    <label >Consignment Code : </label>
                                                    <input type="text" placeholder="Consignment Code" name="consignmentCode" id="consignmentCode" autoComplete="off" className="col-xl-12 col-lg-12 form-control col-xl-7" style={{ height: "41px", fontFamily: 'Poppins-Regular' }} required />
                                                </div>
                                                <div className="form-group col-xl-4 col-lg-4">
                                                    <label >Transporter Name : </label>
                                                    <Select
                                                        value={this.state.transporterName}
                                                        placeholder={"Select Transporter"}
                                                        // closeMenuOnSelect={true}
                                                        onChange={this.changeSelectedConsignee}
                                                        className={"border-radius-0"}
                                                        style={{ borderRadius: "0px" }}
                                                        options={this.state.transportersList}
                                                        // id="consignee_id"
                                                        required />
                                                </div>
                                                <div className="form-group col-xl-4 col-lg-4">
                                                    <label >Vehicle No : </label>
                                                    <Select
                                                        value={this.state.vehicleNo}
                                                        placeholder={"Select VehicleNo"}
                                                        // closeMenuOnSelect={true}
                                                        onChange={this.changeVehicleNo}
                                                        className={"border-radius-0"}
                                                        style={{ borderRadius: "0px" }}
                                                        options={this.state.trucksList}
                                                        // id="consignee_id"
                                                        required />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="form-group col-xl-4 col-lg-4">
                                                    <label >Source</label>
                                                    <Select
                                                        value={this.state.source}
                                                        placeholder={"Select Source"}
                                                        closeMenuOnSelect={true}
                                                        onChange={this.changeSource}
                                                        className={"border-radius-0"}
                                                        style={{ borderRadius: "0px" }}
                                                        options={this.state.sourceList}
                                                        // id="consignee_id"
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-4 col-lg-4">
                                                    <label >Destination</label>
                                                    <Select
                                                        value={this.state.destination}
                                                        placeholder={"Select Destination"}
                                                        closeMenuOnSelect={true}
                                                        onChange={this.changeDestination}
                                                        className={"border-radius-0"}
                                                        style={{ borderRadius: "0px" }}
                                                        options={this.state.sourceList}
                                                        // id="consignee_id"
                                                        required
                                                    />
                                                </div>
                                                <div className={"form-group col-xl-4 col-lg-4"}>
                                                    <label >Gate In Time</label>
                                                    {/* <input type="text" name="gate_in_time" id ="gate_in_time" autoComplete="off" className="col-xl-12 col-lg-12 form-control datetimepicker_mask " style={{height:"41px"}} /> */}
                                                    <Datetime
                                                        maxDate={moment().toDate()}
                                                        inputProps={{ placeholder: 'YYYY-MM-DD HH:MM:SS', name: 'filter_from_date', id: 'filter_from_date', autoComplete: 'off', class: 'col-xl-12 col-lg-12 form-control' }} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
                                                        style={{ height: "41px", fontFamily: 'Poppins-Regular' }} isValidDate={this.validDateTime}
                                                        onChange={this.gateInDateTime}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className={"form-group col-xl-4 col-lg-4"}>
                                                    <label >Gate Out Time</label>
                                                    {/* <input type="text" name="gate_out_time" id ="gate_out_time" autoComplete="off" className="col-xl-12 col-lg-12 form-control datetimepicker_mask" style={{height:"41px"}} required /> */}
                                                    <Datetime
                                                        inputProps={{ placeholder: 'YYYY-MM-DD HH:MM:SS', name: 'filter_from_date', id: 'filter_from_date', autoComplete: 'off', class: 'col-xl-12 col-lg-12 form-control' }}
                                                        dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
                                                        isValidDate={this.validDateTime}
                                                        style={{ height: "41px", fontFamily: 'Poppins-Regular' }}
                                                        onChange={this.gateOutDateTime}
                                                        required
                                                    />
                                                </div>
                                                <div className={"form-group col-xl-4 col-lg-4"}>
                                                    <label >Quantity</label>
                                                    <input type="text" name="Quantity" id="Quantity" placeholder="Quantity" autoComplete="off" className="col-xl-12 col-lg-12 form-control num-cls" style={{ height: "41px", fontFamily: 'Poppins-Regular' }}
                                                        onBlur={this.quantityHandler}
                                                        required />
                                                </div>
                                                <div className={"form-group col-xl-4 col-lg-4"}>
                                                    <label>Vin No:  </label>
                                                    <CreatableSelect
                                                        isMulti={true}
                                                        isClearable
                                                        placeholder="Enter Vin No"
                                                        isDisabled={this.state.vinIsDisabled}
                                                        onChange={this.onChangeVins}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-xl-4 col-lg-4">
                                                    <label >Driver Name : </label>
                                                    <input type="text" placeholder="Driver Name" name="driverName" id="driverName" autoComplete="off" className="col-xl-12 col-lg-12 form-control col-xl-7" style={{ height: "41px", fontFamily: 'Poppins-Regular' }} required />
                                                </div>
                                                <div className="form-group col-xl-4 col-lg-4">
                                                    <label >Driver Phone number : </label>
                                                    <input type="text" placeholder="Driver Phone Number" name="driverPhoneNumber" id="driverPhoneNumber" autoComplete="off" className="col-xl-12 col-lg-12 form-control col-xl-7 num-cls" style={{ height: "41px", fontFamily: 'Poppins-Regular' }} required />
                                                </div>
                                                <div className="form-group col-xl-4 col-lg-4">
                                                    <label >DIGI Lock Serial No : </label>
                                                    <input type="text" placeholder="DIGI Lock Serial No" name="dgSerialNum" id="dgSerialNum" autoComplete="off" className="col-xl-12 col-lg-12 form-control col-xl-7" style={{ height: "41px", fontFamily: 'Poppins-Regular' }} required />
                                                </div>
                                            </div>

                                            <div class="form-group col-xl-12 col-lg-12" style={{ textAlign: "center" }}>
                                                <button type="submit" class="btn btn-primary" style={{ width: "14em" }}>
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    // </div>
                                    : ""}
                            </div>

                        </div>
                    </div>

                    <div className="form-theme col-xl-12 col-lg-12 row">

                        <div className={"slide-r " + (this.state.bulkslide)} style={{ overflow: "hidden" }}>
                            <h3 className="subH">Bulk Upload</h3>
                            <div className="slide-r-body" style={{ position: "relative" }}>
                                <div className="container-fluid">
                                    <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>

                                        <div className="form-group mt-20p">
                                            <label className="">Upload File</label>
                                            <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler} className="form-control" required />
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-success">Submit</button>
                                            <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                        </div>
                                        <p style={{ color: "red" }}>Note: Format should be same as given in sample template***</p>
                                    </form>
                                    <div className="form-group">
                                        <a className="btn btn-primary" href={require('../../assets/json/sample_d22_consignments.csv')} target="_blank">Sample Template</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                        </div>

                    </div>
                    <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                </div>
               
                {this.state.sliderRouteTranslate != '' ?
                    <div className={"slide-r " + (this.state.sliderRouteTranslate)} style={{ overflow: "auto" }}>
                        <div className="slide-r-title">
                            <h4>
                                {(this.state.consignment_code)}
                                <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv} >X</span>

                            </h4>
                        </div>
                        <div className="slide-r-body" style={{ position: "relative" }}>

                            <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                                <li className="float-right" style={{listStyle: "none",position: "absolute",right: "22%",zIndex: "999999"}}>
                                    <button type="button" className="btn btn-warning btn-xs float-right" onClick={(e) => this.onDigiAlertsClicked()}> <span style={{fontSize:"14px"}}> <i className="icofont icofont-download-alt"></i> Download DIGI Logs</span></button>
                                </li>
                                <DrawMap context={this} rownode={this.state.rownode} mapFor={"consignment"} consignment_code={this.state.consignment_code} tolls={this.state.tolls} googleroutes={this.state.googelRoutes} truckno={this.state.maptruckno} dealer={this.state.dealer} mapinfo={this.state.mapinfo} defTransitCoords={this.state.defTransitCoords} />
                                {/*
                                <div id="map" className="" style={{width:'100%',height:"70vh"}}></div>
                                */}
                                <div className="col-xl-12 col-lg-12 n-p-0">
                                    <div className="crm-numbers pb-0">
                                        <h3 className="subH">{this.state.sidebarSubHeader}</h3>
                                        <div className="col-xl-12 col-lg-12 row">
                                            <div className="col route-block">
                                                <label className="sidebar-label">Truck No</label>
                                                <div>{this.state.routeTruck.truck_no}</div>
                                            </div>
                                            {(maptransporter != '') ?
                                                <div className="col route-block">
                                                    <label className="sidebar-label">Transporter</label>
                                                    <div>{(!isEmpty(maptransporter)) ? maptransporter : ""}</div>
                                                </div>

                                                : ""}
                                            <div className="col route-block">
                                                <label className="sidebar-label">Start Time</label>
                                                <div>{(this.state.routeTruck.startTime != '') ? getDDMMYYYYHHMMSS(this.state.routeTruck.startTime) : "NA"}</div>
                                            </div>
                                            <div className="col route-block">
                                                <label className="sidebar-label">End Time</label>
                                                <div>{(this.state.routeTruck.endTime != '') ? (this.state.routeTruck.endTime) : "NA"}</div>
                                            </div>

                                            <div className="col route-block">
                                                <label className="sidebar-label">GPS Distance</label>
                                                <div>{(!isEmpty(this.state.mapinfo.route_details)) ? Math.round(this.state.mapinfo.route_details.distance_without_google / 1000) : "0"} Kms</div>
                                            </div>
                                            <div className="col route-block">
                                                <label className="sidebar-label">Google Distance</label>
                                                <div>{Math.round(this.state.mapinfo.route_details.distance / 1000)} Kms</div>
                                            </div>

                                            {(this.state.leg_no == 0 || this.state.leg_no == null) ? ""
                                                :
                                                <div className="col route-block">
                                                    <label className="sidebar-label">Google Distance</label>
                                                    <div>{(this.state.rownode.actual_google_leg_distance) ? parseInt(this.state.rownode.actual_google_leg_distance) : "0"} Kms</div>
                                                </div>
                                            }

                                            {/*<div className="col route-block">
                                            <label className="sidebar-label">Start Time</label>
                                            <div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
                                            </div>
                                            <div className="col route-block">
                                            <label className="sidebar-label">End Time</label>
                                            <div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
                                            </div>
                                            */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal open={open} onClose={this.onCloseModal} styles={modalStyles} >
                            <div className={"row"} style={{ height: '100vh',padding:'5px 0px', width: '1185px', overflow: 'hidden',zIndex:'9999',}} >
                                <div className={"col-sm-12 col-lg-12 col-md-12"} style={{padding:'20px', background:'#fff'}}>
                                    {/*<div style={{position:'absolute',top:'5px',right:'10px',padding:'0px'}}>
                                        <a href={"#"} title="Close" style={{fontSize:'12px', color:'#333', textDecoration:'none'}} onClick={this.onCloseModal}>
                                            <img src={require("../../assets/icons/close.png")} style={{width:'8px',marginTop:'-2px'}} title="Close" />
                                            &nbsp;Close
                                        </a>
                                    </div>
                                    */}
                                    <div id="myGrid" style={{ height: "580px",width: "100%",marginTop:'10px'}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefsForAlerts}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.digiAlertsData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            //editType={this.state.editType}
                                            stopEditingWhenGridLosesFocus={true}
                                            floatingFilter={false}
                                            enableCellChangeFlash={true}
                                            suppressCellFlash={true}
                                            rowClassRules={this.state.rowClassRules}
                                            //onCellClicked={this.onRowClicked.bind(this)}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            masterDetail={true}
                                            overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                        />

                                            <div className={"dataLoadpage " +(this.state.loadshow)}>
                                            </div>
                                            <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                                                <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                                            </div>

                                        </div>
                                </div>
                            </div>
                    </Modal>
                    </div>
                    : ""
                }
                <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
                    <h3 className="subH">{this.state.sliderTitle}</h3>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        {(this.state.mapShow == 1) ? 
                            <LoadSummaryDrawmap context={this} coords={this.state.latLngArray} stepcoords={1} mapType={this.state.mapType} locInfo={this.state.locInfo} mapFor={"load_management"}  />
                        : ""
                        }
                    </div>
                </div>
                
                <div className={"dataLoadpage " + (this.state.loadshow)}></div>
                    <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                        <div className="loader-box">
                            <div className="loader-box">
                                <div className="rotate dashed colored">
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        )
    }
}

$(document).on("input", ".num-cls", function () {
    this.value = this.value.replace(/\D/g, '');
});

function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    // console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

// function cleanTruckNo(value){
//     var result =  /\s/g.test(value);
//     var cleanedValue="";
//     try{
//         if(result == true){
//             cleanedValue = value.replace(/[^\w\s]/gi, '');
//             cleanedValue = cleanedValue.replace(/\s/g, '').toUpperCase();
//         }
//         else{
//             cleanedValue = value.toUpperCase();
//         }
//     }
//     catch(e){  }
//     return cleanedValue;
// }


// function checkWhiteSpaces(value){
//     return /\s/g.test(value);
// }
// function removeWhiteSpace(value){
//     value = value.replace(/[^\w\s]/gi, '');
//     return value.replace(/\s/g, '').toUpperCase();
// }
function loadDateTimeScript() {
    // alert("timeDate");
    // $("#gate_in_time").onBlur(function(){
    //     alert("testttt");
    // });
    $('.datetimepicker_mask').datetimepicker({
        //mask:'39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });
    $('.datetimepicker_date').datetimepicker({
        //mask:'39-19-9999',
        format: 'd-m-Y',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}

function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    //console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}


function secondsToString(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

function removeSplChars(inputString) {
    // console.log(inputString);
    if (inputString != "" && inputString != null && inputString != undefined) {
        return inputString.replace(/[^\w]/g, "");
    }
    else {
        return "";
    }

}

function getDatePicker() {
    function Timepicker() { }
    Timepicker.prototype.init = function (params) {
        this.eInput = document.createElement("input");
        this.eInput.value = params.value;
        $(this.eInput).datetimepicker({
            datepicker: false,
            format: 'H:i'
        });
    };
    Timepicker.prototype.getGui = function () {
        return this.eInput;
    };
    Timepicker.prototype.afterGuiAttached = function () {
        this.eInput.focus();
        this.eInput.select();
    };
    Timepicker.prototype.getValue = function () {
        return this.eInput.value;
    };
    Timepicker.prototype.destroy = function () { };
    Timepicker.prototype.isPopup = function () {
        return false;
    };
    return Timepicker;
}
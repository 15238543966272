
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getDDMMYYYY, getDDMMYYYYHHMM, getHyphenDDMMYYYY } from '../common/utils';
import CountUp from 'react-countup';
import { AgGridReact } from "@ag-grid-community/react";

import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

var redirectURL = require("../redirectURL");


export default class FreightAmount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transporter_code: '',
      freight_amount: 0,
      show: false,
      basicTitle: '',
      basicType: "default",
      overly: "show-n",
      loadshow: "show-n",
      sidebartitle: "",
      rowData: [],

    }
  }

  componentDidMount() {
    const { transporterCode } = this.props.match.params;
    console.log(transporterCode, 'transporterCode');

    this.setState({
      transporter_code: transporterCode,
    });

    var params = {
      transporter_code: transporterCode
    };
    redirectURL
      .post("/consignments/fetchFrieghtAmountDetails", params)
      .then((response) => {
        // console.log(response, 'response',response.data)
        var responseData = response.data
        // console.log(responseData,'responseData')
        if (responseData != [] && responseData != undefined && responseData != '') {
          console.log('inside')
          this.setState({
            rowData: JSON.parse(responseData.freight_details),
            freight_amount: (response.data.freight_amount) || 0
          })
        }
      })

  }

  closeAlert = () => {
    this.setState({
      show: false
    });
  }
  onClickCounter(value) {}
  render() {
    const batchwisecolumns = [

      // {
      //   headerName: "Month",
      //   field: "month",
      //   width: 300,
      // },
      {
        headerName: "From Date",
        field: "batch_from",
        width: 350,
        filter:true,
        valueGetter: function (params) {
          if (params.data.batch_from != "" && params.data.batch_from != undefined) {
            return getHyphenDDMMYYYY(params.data.batch_from);
          }
          else {
            return "";
          }
        }
      },

      {
        headerName: "To Date",
        field: "batch_to",
        width: 350,
        filter:true,
        valueGetter: function (params) {
          if (params.data.batch_to != "" && params.data.batch_to != undefined) {
            return getHyphenDDMMYYYY(params.data.batch_to);
          }
          else {
            return "";
          }
        }
      },
      {
        headerName: "POD Freight Amount (Rs)",
        field: "freight_amount",
        width: 350,
        filter:true,
        valueFormatter: function (params) {
          // Check if freight amount exists
          if (params.value != null) {
            // Format the freight amount with thousand separators
            return params.value.toLocaleString('en-IN'); 
          } else {
            return ""; // Return empty string if value is null
          }
        },
      },
      ];
    return(

    <div className="row">

        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div style={{ textAlign: "center", background: "#171C8F", width: '100%', margin: "5px", borderRadius: "5px", height: '70px', paddingBottom: '25px' }}>
          <h6 class="txt-dark f40" style={{ marginTop: "0px" }}>
            <span style={{ fontSize: "20px", color: "#FFFFFF" }}>
              <span>POD Freight Payment (Rs)</span>

            </span>
          </h6>
        </div>
        <div className="col-xl-12 col-lg-12">
          <h5>

            <span className="float-right f12 ">
              <a className="btn btn-danger" style={{ float: "left", marginLeft: "5%", marginBottom: "20px" }} href="/lspceodashboard">Back</a>
            </span>
          </h5>
        </div>
        <div className="col-xl-12 col-lg-12">
          <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
            <div className="card" style={{marginRight:'-16px',marginLeft:'-16px'}}>
              <div className="card-body" style={{ padding: "10px" }}>
                <div className="crm-numbers pb-0">
                  <div className="row" >

                    <div className="col cirlce-d "  onClick={this.onClickCounter.bind(this, "gps_inst_pending")}>
                      <span className="f13">Total POD Freight Amount (Rs)</span>
                      <h4 className="txt-info f50"><span className="counter"><CountUp 
                      end={ this.state.freight_amount }
                      // end={number}
                      duration={2.75}
                      separator=","
                      decimals={0}
                      decimal="."
                      prefix=""
                      suffix=""
                      formattingFn={(value) => value.toLocaleString('en-IN')}
                       /></span></h4>
                    </div>
                  
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="card">
          <div
                    id="myGrid"
                    style={{ width: "100%", height: "478px" }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      modules={this.state.modules}
                      columnDefs={batchwisecolumns}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.rowData}
                      enableCharts={false}
                      // autoGroupColumnDef={this.state.autoGroupColumnDef}
                      onGridReady={this.onGridReady}
                      onGridState={this.onGridState}
                      paginationPageSize={this.state.paginationPageSize}
                      pagination={true}
                      gridOptions={{
                        context: { componentParent: this },
                      }}
                    />
                  </div>
          </div>
        </div>
      </div>
    )
  }
}


import React, { Component } from "react";
import CSVFileValidator from 'csv-file-validator';
import moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MenuModule } from "@ag-grid-enterprise/menu";
import Select from "react-select";
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import $ from "jquery";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { color } from "highcharts";
import { DateTime } from "@grapecity/wijmo";
// import { formatDate } from "@fullcalendar/react";
import download from 'js-file-download';
var redirectURL = require("../redirectURL");

export default class LastMileFleetMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hubMaster: '',
      transporterMaster: '',
      fleetMaster: 'activet',
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        width: 160,
        resizable: true,
      },
      eventAction: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      // loadshow: "show-n",
      // overly: "show-n",
      rowdata: [],
      rowdataforexwork: [],
      additionalchargesdata: [],
      sliderbulkupload: "",
      rowModelhighchartoptionsType: "enterprise",
      rowGroupPanelShow: "always",

      paginationPageSize: 50,

      animateRows: true,
      dept_code: "",
      pagetitle: "",
      csvData: 0,
      overly: "show-n",
      loadshow: "show-n",
      pagetitle: "",
      eventGridAction: "gridAction",
      csvcontent: [],
      files: [],
      file: "",
      batch_name: "",
      cluster_breaks: "2",
      cluster_radius: "0.5",
      notification_emails: "",
      bulkslide1: "",
      bulkslide2: "",
      rowId: "",
      show: false,
      basicTitle: "",
      basicType: "",
      bulkslide: "",
      vehicle_category: '',
      vehicle_type: '',
      vehicle_no: "",
      vehicle_no1: "",
      max_cmt_capacity: '',
      min_cmt_capacity: '',
      hiden_truck_RC: false,
      hub_location_options: [],
      hub_location: { label: "", value: "" },
      vehicle_type_options: [],
      hub_truck_master_data: []

    };
    this.editvalues = this.editvalues.bind(this);
  }

  componentDidMount() {
    // document.body.addEventListener("click", this.handleClickOutside);
    redirectURL
      .post("/consignments/lastmilefleetmaster")
      .then((response) => {
        console.log(response.data, "140");
        var hub_location_options = response.data.hubLocationOptions
        hub_location_options = hub_location_options.map((each) => ({ label: each, value: each }))
        this.setState({
          rowdata: response.data.data,
          hub_location_options
        })
        redirectURL.post("/consignments/getvehicletypedetails").then((response) => {
          var resp = response.data
          var vehicle_type_options = []
          resp.map((e) => {
            vehicle_type_options.push({
              value: e.truck_type,
              label: e.truck_type
            })
          })
          this.setState({
            vehicle_type_options: vehicle_type_options,
            hub_truck_master_data: resp
          })
        })

      })
      .catch((e) => {
        console.log(e);
      });
    //   loadDateTimeScript();
  }

  closeAlert = () => {
    this.setState({
      show: false,
    });
  };


  async OnsubmitAddData(event) {
    event.preventDefault();
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    })
    var vehicle_no1 = this.state.vehicle_no1
    vehicle_no1 = vehicle_no1.toUpperCase()
    var max_cmt_capacity = $("#max_cmt_capacity").val()
    var min_cmt_capacity = $("#min_cmt_capacity").val()
    var fileExist;
    console.log(this.state.files, 'file', this.state.files.length > 0)
    if (vehicle_no1.length <= 15 && vehicle_no1.length >= 5) {
      console.log(this.state.vehicle_type.label, 'this.state.vehicle_type.label')
      if (this.state.vehicle_type.label != "" && this.state.vehicle_type.label != undefined) {
        if (this.state.hub_location.label != "" && this.state.hub_location.label != undefined) {

          // if (max_cmt_capacity > min_cmt_capacity) {
            if (this.state.files.length > 0) {
              fileExist = 'withFile'
              var formData = new FormData();
              formData.append('fileExist', fileExist);
              // formData.append('vehicle_category',vehicle_category);
              formData.append('vehicle_type', this.state.vehicle_type.label);
              formData.append('hub_location', this.state.hub_location.label);
              formData.append("vehicle_no1", vehicle_no1);
              formData.append("max_cmt_capacity", max_cmt_capacity);
              formData.append("min_cmt_capacity", min_cmt_capacity);

              for (var x = 0; x < this.state.files.length; x++) {
                formData.append('files', this.state.files[x])
              }
            } else {
              fileExist = 'noFile'
              var formData = new FormData();
              formData.append('fileExist', fileExist);
              // formData.append('vehicle_category',vehicle_category);
              formData.append('vehicle_type', this.state.vehicle_type.label);
              formData.append('hub_location', this.state.hub_location.label);
              formData.append("vehicle_no1", vehicle_no1);
              formData.append("max_cmt_capacity", max_cmt_capacity);
              formData.append("min_cmt_capacity", min_cmt_capacity);
            }
            await redirectURL
              .post("/consignments/addlastmilefleetmaster", formData)
              .then(async (response) => {
                console.log(response.data.message, 'response.data.message', response.data)
                if (response.data.message == 'successfully updated') {
                  $("#max_cmt_capacity").val("")
                  $("#min_cmt_capacity").val("")

                  this.setState({

                    loadshow: "show-n",
                    overly: "show-n",
                    bulkslide2: "",
                    show: true,
                    basicTitle: response.data.message,
                    basicType: 'success',
                    hiden_truck_RC: false,
                    vehicle_no: '',
                    vehicle_no1: '',
                    vehicle_type: '',
                    hub_location: { label: "", value: "" }
                  });
                  $("#max_cmt_capacity").val('')
                  $("#min_cmt_capacity").val('')
                  await this.componentDidMount();
                  var form = document.getElementById("transporterDataSubmition");
                  if (form) {
                    form.reset();
                  }
                } else {
                  $("#max_cmt_capacity").val("")
                  $("#min_cmt_capacity").val("")
                  this.setState({

                    loadshow: "show-n",
                    overly: "show-n",
                    bulkslide2: "",
                    show: true,
                    basicTitle: response.data.message,
                    basicType: 'danger',
                    hiden_truck_RC: false,
                    vehicle_no: '',
                    vehicle_no1: '',
                    vehicle_type: '',
                  });
                  $("#max_cmt_capacity").val('')
                  $("#min_cmt_capacity").val('')
                  await this.componentDidMount();
                  var form = document.getElementById("transporterDataSubmition");
                  if (form) {
                    form.reset();
                  }
                }
              })
              .catch((e) => {
                console.log(e);
              });
          // } else {
          //   alert("Maximum CMT capacity should greater then Minimum CMT capacity")
          //   this.setState({
          //     loadshow: "show-n",
          //     overly: "show-n",
          //   })
          // }
        } else {
          alert("Select the Hub Location")
          this.setState({
            loadshow: "show-n",
            overly: "show-n",
          })
        }
      } else {
        alert("Select the Vehicle Type")
        this.setState({
          loadshow: "show-n",
          overly: "show-n",
        })
      }

    }
    else {
      // Clear any existing error message
      alert("Vehicle Number length should be greater than 5 and less than 15, and should only contain uppercase letters and digits.")
    }
  }



  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      bulkslide: "",
      bulkslide2: "",
      bulkslide3: "",
      bulkslide4: ''
    });
  }





  resetUpload = () => {
    this.setState({
      bulkslidebulk: '',
      overly: 'show-n',
      file: '',
      bulkMarkData: [],
      bulkslide: "",
      bulkslide2: "",
    })

  }



  async onCellClicked(e) {
    if (e.colDef.field === "Edit") {
      console.log(e.data, 'onclickvalues')
      var data = e.data;
      this.setState({
        rowId: data._id,
        vehicle_no: data.vehicle_no,

        // vehicle_category : data.vehicle_category,
        vehicle_type: ({ value: data.vehicle_type, label: data.vehicle_type }),
        hub_location: ({ value: data.hub_location, label: data.hub_location }),

        max_cmt_capacity: data.max_cmt_capacity,
        min_cmt_capacity: data.min_cmt_capacity,
        bulkslide: "slider-translate-30p",

      });
    }
    if (e.colDef.field == "rc_file") {
      var fileName = e.data.rc_file
      console.log(fileName, "496")
      if (fileName != "") {
        var reqparams = {
          uploadOtherFileName: fileName,
        }
        redirectURL.post("/consignments/downloadRCfile", reqparams, {
          responseType: 'blob'
        }).then((response) => {
          console.log("download response ", response.data, fileName[0].name)
          download(response.data, fileName[0].name)

        }).catch(function (error) {
          console.log(error);
        })
      }
    } else if (e.colDef.field == "delete") {

      var params = {
        rowId: e.data._id,
        status_to_show: "deleted",
        type: 'transporterDelete'
      }
      const confirmed = window.confirm('Are you sure you want to delete?');
      if (confirmed) {
        await redirectURL
          .post("/consignments/deletefromdata", params)
          .then(async (response) => {
            this.setState({

              loadshow: "show-n",
              overly: "show-n",
              bulkslide2: "",
              bulkslide: "",
              show: true,
              basicTitle: "Deleted Successfully",
              basicType: 'success'
            });
          })
      }
      await this.componentDidMount()
    }
  }
  showBulkUpload() {
    var form = document.getElementById("transporterDataSubmition");
    if (form) {
      form.reset();
    }
    $("#vehicle_no").val('')
    $("#vehicle_no1").val('')
    $("#vehicle_type").val('')
    this.setState({
      vehicle_no: '',
      vehicle_no1: '',
      vehicle_type: '',
      hub_location: '',
      files: [],
      max_cmt_capacity: '',
      min_cmt_capacity: '',
      bulkslide2: "slider-translate-30p",
    })
  }


  editvalues = (event) => {
    console.log("Select", event.target.value);

    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
  }

  async OnsubmitEditData(event) {
    event.preventDefault();
    console.log(this.state.vehicle_no, 'vehicle_no')
    var rowId = this.state.rowId
    var vehicle_type = this.state.vehicle_type.label
    var vehicle_no = this.state.vehicle_no
    var max_cmt_capacity = this.state.max_cmt_capacity
    var min_cmt_capacity = this.state.min_cmt_capacity
    var hub_location = this.state.hub_location.label
    var fileExist;
    if (this.state.files.length > 0) {
      fileExist = 'withFile'
      var formData = new FormData();
      formData.append('fileExist', fileExist);
      formData.append('rowId', rowId);
      formData.append('vehicle_type', vehicle_type);
      formData.append('hub_location', hub_location);
      formData.append("vehicle_no1", vehicle_no);
      formData.append("max_cmt_capacity", max_cmt_capacity);
      formData.append("min_cmt_capacity", min_cmt_capacity);

      for (var x = 0; x < this.state.files.length; x++) {
        formData.append('files', this.state.files[x])
      }
    } else {
      fileExist = 'noFile'
      var formData = new FormData();
      formData.append('fileExist', fileExist);
      formData.append('rowId', rowId);
      formData.append('vehicle_type', vehicle_type);
      formData.append("vehicle_no1", vehicle_no);
      formData.append("max_cmt_capacity", max_cmt_capacity);
      formData.append("min_cmt_capacity", min_cmt_capacity);
      formData.append('hub_location', hub_location);
    }

    await redirectURL
      .post("/consignments/editlastmilefleetmaster", formData)
      .then(async (response) => {

        this.setState({

          loadshow: "show-n",
          overly: "show-n",
          bulkslide2: "",
          bulkslide: "",
          show: true,
          basicTitle: "Uploaded Successfully",
          basicType: 'success'
        });
        this.setState({
          max_cmt_capacity: '',
          min_cmt_capacity: '',
          hub_location: { label: "", value: "" }
        })
        await this.componentDidMount();

      })
      .catch((e) => {
        console.log(e);
      });


  }

  handleKeyPressforFloat = (event) => {
    const inputChar = String.fromCharCode(event.charCode);
    // Allow only numbers and the decimal point
    if (!/^\d*\.?\d*$/.test(inputChar)) {
      event.preventDefault(); // Prevent the character from being entered
    }
  };

  handleKeyPress = (event) => {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^[A-Za-z]*$/.test(inputChar)) {
      event.preventDefault(); // Prevent the character from being entered
    }
  };
  handleKeyPressVeh = (event) => {
    const inputChar = String.fromCharCode(event.charCode);
    const inputValue = event.target.value;
    if (inputValue.length >= 15 || !/^[A-Za-z0-9]*$/.test(inputChar)) {
      event.preventDefault(); // Prevent the character from being entered
    }

  };

  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    console.log(name, 'name', value)

    this.setState({ [name]: value });
  };

  onSelectTruck = (e) => {
    console.log(e, 'truckSelection')
    var hub_truck_master_data = this.state.hub_truck_master_data
    var check_filter = hub_truck_master_data.filter((f) => f.truck_type == e.value)
    this.setState({
      vehicle_type: ({ label: e.label, value: e.value }),
      min_cmt_capacity: check_filter[0].min_cmt,
      max_cmt_capacity: check_filter[0].max_cmt,
    })
  }
  onClickTab(clickedTab) {

    if (clickedTab == 'hubmaster') {
      window.location.href = "hubmaster"
      var hubMaster = "activet";
      var transporterMaster = "";
      var fleetMaster = "";
    } else if (clickedTab == "transportermaster") {
      window.location.href = "/transportermaster"
      var hubMaster = "";
      var transporterMaster = "activet";
      var fleetMaster = "";
    } else if (clickedTab == "lastmilefleetmaster") {
      window.location.href = "lastmilefleetmaster"
      var hubMaster = "";
      var transporterMaster = "";
      var fleetMaster = "activet";
    }
    this.setState({

      hubMaster: hubMaster,
      transporterMaster: transporterMaster,
      fleetMaster: fleetMaster
    })
  }

  changeFileHandlerPOD = async (e) => {
    console.log(e.target.files, e.target.files[0], 'change')
    var files = Array.from(e.target.files);
    this.setState({
      files: files
    })

  }

  render() {
    const batchwisecolumns = [
      {
        pinned: "left",
        headerName: "Edit",
        field: "Edit",
        width: 120,
        resizable: true,
        editable: false,
        cellRenderer: function (params) {
          var rendComponent =
            '<button class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</button>';
          return rendComponent;
        },
        filter: false,
      },
      {
        pinned: "left",
        headerName: "Delete",
        field: "delete",
        width: 80,
        resizable: true,
        editable: false,
        cellRenderer: function (params) {
          return '<i class="icofont icofont-ui-delete"></i>';
        },
      },
      {
        headerName: 'Vehicle Number',
        field: 'vehicle_no',
        with: 150,
      },
      {
        headerName: 'Truck Type',
        field: 'vehicle_type',
        with: 150,
      },
      {
        headerName: 'Hub Location',
        field: 'hub_location',
        width: 200,
      },
      {
        headerName: 'Minimum CMT capacity',
        field: 'min_cmt_capacity',
        width: 200,
      },
      {
        headerName: 'Maximum CMT capacity',
        field: 'max_cmt_capacity',
        width: 200,
      },
      {
        headerName: 'Download RC',
        field: 'rc_file',
        width: 180, // Use the desired width
        cellRenderer: function (params) {
          console.log((params.data.rc_file), 'file')
          if (params.data.rc_file) {
            var htmloption = '<button class="btn btn-info" style="padding-top: 0px">Download</button>';
            return htmloption;
          } else {
            return '';
          }
        },
      }


    ];

    return (
      <div class="container-fluid">
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
        <div className="col-xl-12 col-lg-12">
          <div className="f16" style={{ marginBottom: "10px" }}>
            <ul className="d-tabs">

              <li onClick={this.onClickTab.bind(this, "hubmaster")} className={"lttabs " + (this.state.hubMaster)}>
                <button type="button" className="btn">Hub Master</button>
              </li>

              <li onClick={this.onClickTab.bind(this, "lastmilefleetmaster")} className={"lttabs " + (this.state.fleetMaster)}>
                <button type="button" className="btn">Last Mile Fleet Master</button>
              </li>
            </ul>
          </div>
        </div>

        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
          <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
        <div className={"dataLoadpage " + (this.state.loadshow)}></div>
        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
          <div className="loader-box">
            <div className="loader-box">
              <div className="rotate dashed colored">
              </div>
            </div>
          </div>
        </div>
        <div className="card col-xl-12">
          <div className="card-header">
            <h5>
              <i className="icofont icofont-Vehicle-delivery-van cus-i"></i>{" "}
              <span>Last Mile Fleet Master</span>
              <button className="btn btn-info" style={{ float: "right", marginRight: "10px" }} onClick={this.showBulkUpload.bind(this)}>ADD</button>

              <div className="card-body">
                <div className="row">
                  <div
                    id="myGrid"
                    style={{ width: "100%", height: "478px" }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      modules={this.state.modules}
                      columnDefs={batchwisecolumns}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.rowdata}
                      enableCharts={false}
                      onGridReady={this.onGridReady}
                      onGridState={this.onGridState}
                      frameworkComponents={this.state.frameworkComponents}
                      rowClassRules={this.state.rowClassRules}
                      sideBar={this.state.sideBar}
                      stopEditingWhenGridLosesFocus={true}
                      onCellClicked={this.onCellClicked.bind(this)}
                      paginationPageSize={this.state.paginationPageSize}
                      pagination={true}
                      gridOptions={{
                        context: { componentParent: this },
                      }}
                      // onCellClicked={this.onCellRowClicked.bind(this)}
                      enableRangeSelection={true}
                    />
                  </div>
                </div>
              </div>
            </h5>
          </div>


        </div>
        <div
          className={"slide1-r " + this.state.bulkslide2}
          style={{ overflow: "hidden" }}
        >
          <h3 className="subH">
            Add Last Mile Fleet Master
            <span
              className="float-right fclose"
              style={{
                marginRight: "12px",
                padding: "1px 8px",
                marginTop: "-2px",
              }}
              onClick={this.onClickHideAll.bind(this)}
            >
              x
            </span>
          </h3>
          <div className="slide-r-body" style={{ position: "relative" }}>
            <div className="container-fluid">
              <form
                method="post"
                id="transporterDataSubmition"
                onSubmit={this.OnsubmitAddData.bind(this)}
                autocomplete="off"
                className="row col-xl-12 col-lg-12"
              >
                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                    Vehicle Number*
                  </label>
                  <input
                    type="text"
                    name="vehicle_no1"
                    //  value={this.state.vehicle_no}
                    id="vehicle_no1"
                    className="form-control"
                    placeholder="Vehicle Number"
                    onKeyPress={this.handleKeyPressVeh}
                    onChange={this.handleChange}
                    required
                  /></div>


                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                    Upload RC File</label>
                  <input type="file" name="uploadFile"
                    onChange={this.changeFileHandlerPOD}
                    className="form-control" />

                </div>
                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                    Hub Location*
                  </label>
                  <Select
                    type="text"
                    // name="vehicle_type"
                    value={this.state.hub_location}
                    // id="vehicle_type"
                    closeMenuOnSelect={true}
                    className="border-radius-0"
                    placeholder="Hub Location"
                    // onKeyPress={this.handleKeyPress}
                    onChange={(e) => (this.setState({ hub_location: e }))}
                    options={this.state.hub_location_options}
                    required
                  />
                </div>

                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                    Vehicle Type*
                  </label>
                  <Select
                    type="text"
                    // name="vehicle_type"
                    value={this.state.vehicle_type}
                    // id="vehicle_type"
                    closeMenuOnSelect={true}
                    className="border-radius-0"
                    placeholder="Vehicle Type"
                    // onKeyPress={this.handleKeyPress}
                    onChange={this.onSelectTruck.bind(this)}
                    options={this.state.vehicle_type_options}
                    required
                  />
                </div>

                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                    Minimum CMT capacity
                  </label>
                  <input
                    type="text"
                    name="min_cmt_capacity"
                    id="min_cmt_capacity"
                    value={this.state.min_cmt_capacity}
                    className="form-control"
                    placeholder="Minimum CMT capacity"
                    // onKeyPress={this.handleKeyPressforFloat}
                    // onChange={this.handleChange}
                    readOnly
                  // required
                  />

                </div>
                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                    Maximum CMT capacity
                  </label>
                  <input
                    type="text"
                    name="max_cmt_capacity"
                    id="max_cmt_capacity"
                    value={this.state.max_cmt_capacity}
                    className="form-control"
                    placeholder="Maximum CMT capacity"
                    // onKeyPress={this.handleKeyPressforFloat}
                    // onChange={this.handleChange}
                    readOnly
                  // required
                  />

                </div>

                <div className="form-group col-xl-3 col-lg-3">
                  <button type="submit" className="btn btn-success">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className={"slide1-r " + this.state.bulkslide}
          style={{ overflow: "hidden" }}
        >
          <h3 className="subH">
            Edit Last Mile Fleet Master
            <span
              className="float-right fclose"
              style={{
                marginRight: "12px",
                padding: "1px 8px",
                marginTop: "-2px",
              }}
              onClick={this.onClickHideAll.bind(this)}
            >
              x
            </span>
          </h3>
          <div className="slide-r-body" style={{ position: "relative" }}>
            <div className="container-fluid">
              <form
                method="post"
                onSubmit={this.OnsubmitEditData.bind(this)}
                autocomplete="off"
                className="row col-xl-12 col-lg-12"
              >

                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                    Vehicle Number*
                  </label>
                  <input
                    type="text"
                    name="vehicle_no"
                    value={this.state.vehicle_no}
                    className="form-control"
                    placeholder="Vehicle Number"
                    onKeyPress={this.handleKeyPressVeh}
                    onChange={this.handleChange}
                    required
                  /></div>

                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                    Upload RC File</label>
                  <input type="file" name="uploadFile"
                    onChange={this.changeFileHandlerPOD}
                    className="form-control" />
                </div>
                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                    Hub Location*
                  </label>
                  <Select
                    type="text"
                    // name="vehicle_type"
                    value={this.state.hub_location}
                    // id="vehicle_type"
                    closeMenuOnSelect={true}
                    className="border-radius-0"
                    placeholder="Vehicle Type"
                    // onKeyPress={this.handleKeyPress}
                    onChange={(e) => (this.setState({ hub_location: e }))}
                    options={this.state.hub_location_options}
                    required
                  />
                </div>
                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                    Vehicle Type*
                  </label>
                  <Select
                    type="text"
                    // name="vehicle_type"
                    value={this.state.vehicle_type}
                    // id="vehicle_type"
                    closeMenuOnSelect={true}
                    className="border-radius-0"
                    placeholder="Vehicle Type"
                    // onKeyPress={this.handleKeyPress}
                    onChange={this.onSelectTruck.bind(this)}
                    options={this.state.vehicle_type_options}
                    required
                  />
                </div>

                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                    Minimum CMT capacity
                  </label>
                  <input
                    type="text"
                    name="min_cmt_capacity"
                    value={this.state.min_cmt_capacity}
                    onChange={this.editvalues}
                    className="form-control"
                    placeholder="Minimum CMT capacity"
                    onKeyPress={this.handleKeyPressforFloat}
                    readOnly
                  />
                </div>
                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                    Maximum CMT capacity
                  </label>
                  <input
                    type="text"
                    name="max_cmt_capacity"
                    value={this.state.max_cmt_capacity}
                    onChange={this.editvalues}
                    className="form-control"
                    placeholder="Maximum CMT capacity"
                    onKeyPress={this.handleKeyPressforFloat}
                    readOnly
                  />
                </div>
                <div className="form-group col-xl-3 col-lg-3">
                  <button type="submit" className="btn btn-success">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>


      </div>
    );
  }
}


function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
  // create the cell
  console.log(params, "params");
  this.eInput = document.createElement("input");
  this.eInput.value = params.value;

  // https://jqueryui.com/datepicker/
  $(this.eInput).datetimepicker({
    dateFormat: "yy-mm-dd",
    timepicker: false,
    changeMonth: true,
    changeYear: true,
  });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
  return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
  this.eInput.focus();
  this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
  return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
  // but this example is simple, no cleanup, we could
  // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
  // and we could leave this method out also, false is the default
  return false;
};


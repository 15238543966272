
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const ExportForceclose = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onClickForceCloseConsignment(props);
    };
    

    return (
        <div>
            <button onClick={handleEventClick} className="btn btn-danger custom-btn white f13" 
            title="Force Close" > Close</button>
            
        </div>
    );
};

export default ExportForceclose;

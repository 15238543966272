import React, { } from 'react';
// import ReactDOM from 'react-dom';
// var redirectURL = require('../redirectURL');

 
const lspcarrierdoc = (props) => {
//    console.log("props ", props)

    return (
        <div>
		    {(props.value === 1 )?  <i class="icofont icofont-tick-mark" style={{color : "green"}}></i> : <i class="icofont icofont-not-allowed" style={{color : "red"}}></i>}
        </div>
    );
};

export default lspcarrierdoc;

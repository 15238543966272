
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');



export default class ShowChokePoints extends Component {
    constructor(props) {
      super(props);
      this.state ={
          value:false
      }
      this.checkedChokeHandler = this.checkedChokeHandler.bind(this);
      this.clickViewData = this.clickViewData.bind(this);
    }
  
    
    checkedChokeHandler(event){
        var inputid =event.target.id;
        var checking = document.getElementById(inputid).checked
        // console.log("checking ",checking)
        // console.log("props ",this.props)
        var checkedlst = []
        var uncheckedlst = []
        if(checking == true)
        {
            uncheckedlst.push(inputid)
        }
        if(checking == false)
        {
            checkedlst.push(inputid)
        }
       // console.log("checkedlst ", checkedlst)
        event.stopPropagation();
        if(checking == true)
        {
            this.props.context.componentParent.onClickShowChokePoints(this.props);
        }
        else
        {
            this.props.context.componentParent.onClickHideChokePoints(this.props);
        }

        this.setState({
            value:!this.state.value
        })
    }
    
    clickViewData(){
        this.props.context.componentParent.onClickViewChokePointsGrid(this.props);
    }
    render() {
        //console.log("this.Defs ", this.props)
      return (
        <div className="row" style={{padding:"0 0 0 10px"}}>
            <span style={{fontSize:"11px"}}>             
                <input 
                type="checkbox" 
                id = {"ch_"+this.props.colDef.colId}
                className={"ch_"+this.props.colDef.colId}
                onClick={this.checkedChokeHandler}
                checked={this.state.value}
                /> Chokepoints <span onClick={this.clickViewData} style={{cursor:"pointer", color:"#FF0000"}} title="View Data">View</span>
            </span>
        </div>
      )
    }
  }

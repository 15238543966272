
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const ApproveButton = (props) => {
    const handleApproveClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.approveSuggestedMsilrake(props.data);
    };
    const handleRejectClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.rejectSuggestedMsilrake(props.data);
    };
    var rowdata = props.data;
    console.log(rowdata,"buttons")
    return (
        <div className="map-icon-component">
            {((rowdata.suggested_msil_rake != undefined) && (rowdata.suggested_msil_rake != "") && (rowdata.to_be_approved != 0))?
                <div>
                 <button onClick={handleApproveClick} className="btn btn-success" style={{paddingLeft:"1em",paddingRight:"1em",paddingTop:"0em"}}> &nbsp;Approve </button> / &nbsp; 
                 <button onClick={handleRejectClick} className="btn btn-warning" style={{paddingLeft:"1em",paddingRight:"1em",paddingTop:"0em"}}> &nbsp;Reject </button>    
                </div>
            :""}
            
        </div>
    );
};

export default ApproveButton;

import React, { Component } from "react";
import CSVFileValidator from 'csv-file-validator';
import moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MenuModule } from "@ag-grid-enterprise/menu";
import Select from "react-select";
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import $ from "jquery";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { color } from "highcharts";
import { DateTime } from "@grapecity/wijmo";
// import { formatDate } from "@fullcalendar/react";
var redirectURL = require("../redirectURL");

export default class TransporterMaster extends Component {
  constructor(props) {
                super(props);
                this.state = {
                  hubMaster :'',
                  transporterMaster : 'activet',
                  fleetMaster : '',
                modules: AllModules,
                defaultColDef: {
                    sortable: true,
                    filter: true,
                    editable: false,
                    width: 160,
                    resizable: true,
                },
                eventAction: null,
                show: false,
                basicTitle: "",
                basicType: "default",
                // loadshow: "show-n",
                // overly: "show-n",
                rowdata: [],
                rowdataforexwork:[],
                additionalchargesdata:[],
                sliderbulkupload: "",
                rowModelhighchartoptionsType: "enterprise",
                rowGroupPanelShow: "always",

                paginationPageSize: 50,

                animateRows: true,
                dept_code: "",
                pagetitle: "",
                csvData: 0,
                overly: "show-n",
                loadshow: "show-n",
                pagetitle: "",
                eventGridAction: "gridAction",
                csvcontent: [],
                file: "",
                batch_name: "",
                cluster_breaks: "2",
                cluster_radius: "0.5",
                notification_emails: "",
                bulkslide1: "",
                bulkslide2: "",
                rowId: "",
                show: false,
                basicTitle: "",
                basicType: "",
                bulkslide: "",
                transporter_name : '',
                transporter_code : '',
                
                transporter_code_mile : ''

    };
    this.editvalues = this.editvalues.bind(this);
  }

  componentDidMount() {
    // document.body.addEventListener("click", this.handleClickOutside);
    redirectURL
      .post("/consignments/transportermaster")
      .then((response) => {
        console.log(response.data, "140----");
  this.setState({
    rowdata:response.data
  })
    
      })
      .catch((e) => {
        console.log(e);
      });
    //   loadDateTimeScript();
  }
  
  closeAlert = () => {
    this.setState({
      show: false,
    });
  };


OnsubmitAddData(event) {
    event.preventDefault();
  var transporter_name = $("#transporter_name").val()
  var transporter_code = $("#transporter_code").val()
 
  var transporter_code_mile = $("#transporter_code_mile").val()


    var params = {
        transporter_name : transporter_name,
        transporter_code : transporter_code,
       
        transporter_code_mile : transporter_code_mile
    }

      console.log(params, "params===");


  redirectURL
    .post("/consignments/addtransportermaster", params)
    .then((response) => {
     
        this.setState({

          loadshow: "show-n",
          overly: "show-n",
          bulkslide2: "",
          show:true,
          basicTitle:"Uploaded Successfully",
          basicType:'success'
         });
       
        this.componentDidMount();
      
    })
    .catch((e) => {
      console.log(e);
    });

   
  }

 
  
  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      bulkslide: "",
      bulkslide2: "",
      bulkslide3: "",
      bulkslide4:''
    });
  }

 



resetUpload = () => {
  this.setState({
    bulkslidebulk: '',
      overly: 'show-n',
      file: '',
      bulkMarkData: [],
      bulkslide: "",
      bulkslide2: "",
  })
 
}



onCellClicked(e) {
  if (e.colDef.field === "Edit") {
console.log(e.data,'onclickvalues')
  var data = e.data;
    this.setState({
      rowId : data._id,
      transporter_name : data.transporter_name,
      transporter_code : data.transporter_code,
     
      transporter_code_mile : data.transporter_code_mile,
      bulkslide: "slider-translate-30p",
   
    });
  }
}
showBulkUpload(){
   this.setState({
    bulkslide2: "slider-translate-30p",
   })
}


editvalues = (event) => {
  console.log("Select", event.target.value);
  
  let name = event.target.name;
    let value = event.target.value;
    this.setState({[name]:value});
}

OnsubmitEditData(event) {
    event.preventDefault();
    var rowId = this.state.rowId
  var transporter_name = this.state.transporter_name
  var transporter_code = this.state.transporter_code
 
  var transporter_code_mile = this.state.transporter_code_mile


    var params = {
      rowId :rowId,
        transporter_name : transporter_name,
        transporter_code : transporter_code,
       
        transporter_code_mile : transporter_code_mile
    }

      console.log(params, "params===");


  redirectURL
    .post("/consignments/edittransportermaster", params)
    .then((response) => {
     
        this.setState({

          loadshow: "show-n",
          overly: "show-n",
          bulkslide2: "",
          bulkslide: "",
          show:true,
          basicTitle:"Uploaded Successfully",
          basicType:'success'
         });
       
        this.componentDidMount();
      
    })
    .catch((e) => {
      console.log(e);
    });

   
  }

  handleKeyPressforFloat = (event) => {
    const inputChar = String.fromCharCode(event.charCode);
    // Allow only numbers and the decimal point
    if (!/^\d*\.?\d*$/.test(inputChar)) {
      event.preventDefault(); // Prevent the character from being entered
    }
  };

  handleKeyPress = (event) => {
    const inputChar = String.fromCharCode(event.charCode);
   if (!/^[A-Za-z]*$/.test(inputChar)) {
      event.preventDefault(); // Prevent the character from being entered
    }
  };
  handleKeyPressCode = (event) => {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^[A-Z0-9]*$/.test(inputChar)) {
      event.preventDefault(); // Prevent the character from being entered
    }
  };
  handleKeyPressTransporterCode = (event) => {
    const inputChar = String.fromCharCode(event.charCode);
    const inputValue = event.target.value;
  
    if (inputValue.length === 0) {
      // Allow the first character to be an uppercase letter
      if (!/^[A-Z]*$/.test(inputChar)) {
        event.preventDefault();
      }
    } else if (inputValue.length >= 1 && inputValue.length < 4) {
      // Allow the next 3 characters to be numbers
      if (!/^[0-9]*$/.test(inputChar)) {
        event.preventDefault();
      }
    } else {
      event.preventDefault();
    }
  };
  
  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({[name]:value});
  };

  onClickTab(clickedTab){
		
		if(clickedTab == 'hubmaster'){
			window.location.href="hubmaster"
			var hubMaster = "activet";
			var transporterMaster = "";
			var fleetMaster = "";
		}else if(clickedTab == "transportermaster"){
			window.location.href="/transportermaster"
			var hubMaster = "";
			var transporterMaster = "activet";
			var fleetMaster = "";
		}else if(clickedTab == "lastmilefleetmaster"){
			window.location.href="lastmilefleetmaster"
      var hubMaster = "";
			var transporterMaster = "";
			var fleetMaster = "activet";
		}
		this.setState({
		
       hubMaster :hubMaster,
			 transporterMaster : transporterMaster,
			 fleetMaster : fleetMaster
		})
	}

  render() {
    const batchwisecolumns = [
        {
          pinned: "left",
          headerName: "",
          field: "Edit",
          width: 120,
          resizable: true,
          editable: false,
          cellRenderer: function (params) {
            var rendComponent =
              '<button class="btn btn-arms" id="buttonStyling" style="background-color: #00c292; color: white;">Edit</button>';
            return rendComponent;
          },
          filter: false,
        },
        {
          headerName:  'Transporter Name',
          field: 'transporter_name',
         with:150,  
        },
        {
          headerName:'Transporter Code',
          field: 'transporter_code',
         with:150,
        },
        
          {
            headerName:  '1st Mile Transporter Code',
            field: 'transporter_code_mile',
            width:200,
          },
       
      ];

    return (
      <div class="container-fluid">
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
        	<div className="col-xl-12 col-lg-12">
                        <div className="f16" style={{marginBottom:"10px"}}>
                            <ul className="d-tabs">
                                
                                <li onClick={this.onClickTab.bind(this,"hubmaster")} className={"lttabs "+(this.state.hubMaster)}>
                                    <button type="button" className="btn">Hub Master</button>
                                </li>

                                <li onClick={this.onClickTab.bind(this,"transportermaster")} className={"lttabs "+(this.state.transporterMaster)}>
                                    <button type="button" className="btn">Transporter Master</button>
                                </li>

                                <li onClick={this.onClickTab.bind(this,"lastmilefleetmaster")} className={"lttabs "+(this.state.fleetMaster)}>
                                    <button type="button" className="btn">Last Mile Fleet Master</button>
                                </li>
                            </ul>
                        </div>
                    </div>
           <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                        </div>
                        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                        <div className={"dataLoadpage " + (this.state.loadshow)}></div>
                    <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                        <div className="loader-box">
                            <div className="loader-box">
                                <div className="rotate dashed colored">
                                </div>
                            </div>
                        </div>
                    </div>
        <div className="card col-xl-12">
          <div className="card-header">
            <h5>
            <i className="icofont icofont-vehicle-delivery-van cus-i"></i>{" "}
              <span>Transporter Master</span>
              <button className="btn btn-info" style={{ float: "right", marginRight: "10px" }} onClick={this.showBulkUpload.bind(this)}>ADD</button>
              
              <div className="card-body">
                <div className="row">
                  <div
                    id="myGrid"
                    style={{ width: "100%", height: "478px" }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      modules={this.state.modules}
                      columnDefs={batchwisecolumns}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.rowdata}
                      enableCharts={false}
                      onGridReady={this.onGridReady}
                      onGridState={this.onGridState}
                      frameworkComponents={this.state.frameworkComponents}
                      rowClassRules={this.state.rowClassRules}
                      sideBar={this.state.sideBar}
                      stopEditingWhenGridLosesFocus={true}
                      onCellClicked={this.onCellClicked.bind(this)}
                      paginationPageSize={this.state.paginationPageSize}
                      pagination={true}
                      gridOptions={{
                        context: { componentParent: this },
                      }}
                      // onCellClicked={this.onCellRowClicked.bind(this)}
                      enableRangeSelection={true}
                    />
                  </div>
                </div>
              </div>
            </h5>
          </div>

          {/* work in progress Fresh Incoming rakes */}
        
        </div>
        <div
          className={"slide1-r " + this.state.bulkslide2}
          style={{ overflow: "hidden" }}
        >
          <h3 className="subH">
            Add Transporter Master
            <span
              className="float-right fclose"
              style={{
                marginRight: "12px",
                padding: "1px 8px",
                marginTop: "-2px",
              }}
              onClick={this.onClickHideAll.bind(this)}
            >
              x
            </span>
          </h3>
         <div className="slide-r-body" style={{ position: "relative"}}>
            <div className="container-fluid">
              <form
                method="post"
                onSubmit={this.OnsubmitAddData.bind(this)}
                autocomplete="off"
                className="row col-xl-12 col-lg-12"
              >
               <div className="form-group col-xl-12 col-lg-12">
              <label className="col-xl-12 col-lg-12">
              Transporter Name :
                  </label>
                  <input
          type="text"
          name="transporter_name"
          value={this.state.transporter_name}
          id="transporter_name"
          className="form-control"
          placeholder="Transporter Name"
          onKeyPress={this.handleKeyPress}
          onChange={this.handleChange}
          required
        />
                   </div>
              <div className="form-group col-xl-12 col-lg-12">
              <label className="col-xl-12 col-lg-12">
              Transporter Code:
                  </label>
                  <input
                    type="text"
                    name="transporter_code"
                    value={this.state.transporter_code}
                    id="transporter_code"
                    className="form-control"
                    placeholder="Transporter Code"
                    onKeyPress={this.handleKeyPressTransporterCode}
                    onChange={this.handleChange}
                    required
                  />
                   </div>
                 
                  
               
                
                
              
                  <div className="form-group col-xl-12 col-lg-12">
                <label className="col-xl-12 col-lg-12">
                1st Mile Transporter Code:
                  </label>
                  <input
                    type="text"
                    name="transporter_code_mile"
                     id="transporter_code_mile"
                     value={this.state.transporter_code_mile}
                    className="form-control"
                    placeholder="1st mile Transporter Code"
                    onKeyPress={this.handleKeyPressCode}
                    onChange={this.handleChange}
                    required
                  />

                  </div>
                  <div className="form-group col-xl-3 col-lg-3">
                  <button type="submit" className="btn btn-success">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

                        <div
          className={"slide1-r " + this.state.bulkslide}
          style={{ overflow: "hidden" }}
        >
          <h3 className="subH">
            Edit Transporter Master
            <span
              className="float-right fclose"
              style={{
                marginRight: "12px",
                padding: "1px 8px",
                marginTop: "-2px",
              }}
              onClick={this.onClickHideAll.bind(this)}
            >
              x
            </span>
          </h3>
         <div className="slide-r-body" style={{ position: "relative"}}>
            <div className="container-fluid">
            <form
                method="post"
                onSubmit={this.OnsubmitEditData.bind(this)}
                autocomplete="off"
                className="row col-xl-12 col-lg-12"
              >
               <div className="form-group col-xl-12 col-lg-12">
              <label className="col-xl-12 col-lg-12">
              Transporter Name :
                  </label>
                  <input
                    type="text"
                    name="transporter_name"
                    value={this.state.transporter_name}
                    
                    onChange={this.editvalues}
                    className="form-control"
                    placeholder="Transporter Name"
                    onKeyPress={this.handleKeyPress}
                    
                  /> 
                   </div>
              <div className="form-group col-xl-12 col-lg-12">
              <label className="col-xl-12 col-lg-12">
              Transporter Code:
                  </label>
                  <input
                    type="text"
                    name="transporter_code"
                    value={this.state.transporter_code}
                    onChange={this.editvalues}
                    className="form-control"
                    placeholder="Transporter Code"
                    onKeyPress={this.handleKeyPressTransporterCode}
                    
                  />
                   </div>
                 
                  
               
                 
                  <div className="form-group col-xl-12 col-lg-12">
                <label className="col-xl-12 col-lg-12">
                1st Mile Transporter Code:
                  </label>
                  <input
                    type="text"
                    name="transporter_code_mile"
                    value={this.state.transporter_code_mile}
                    onChange={this.editvalues}
                    className="form-control"
                    placeholder="1st mile Transporter Code"
                    onKeyPress={this.handleKeyPressCode}
                    
                  />

                  </div>
                  <div className="form-group col-xl-3 col-lg-3">
                  <button type="submit" className="btn btn-success">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

       
      </div>
    );
  }
}


function DateEditor() {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
  // create the cell
  console.log(params, "params");
  this.eInput = document.createElement("input");
  this.eInput.value = params.value;

  // https://jqueryui.com/datepicker/
  $(this.eInput).datetimepicker({
    dateFormat: "yy-mm-dd",
    timepicker: false,
    changeMonth: true,
    changeYear: true,
  });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
  return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
  this.eInput.focus();
  this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
  return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
  // but this example is simple, no cleanup, we could
  // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
  // and we could leave this method out also, false is the default
  return false;
};

// function loadDateTimeScript() {
//   // alert("timeDate");
//   $(".datetimepicker_datew").datetimepicker({
//     //mask:'39-19-9999',
//     format: "d-m-Y H:i",
//     minDate: true,
//     timepicker: true,
//     closeOnDateSelect: true,
//     scrollMonth: false,
//     scrollInput: false,
//     // onChangeDateTime: function onchangedates (e) {
//     //  console.log("Selected date and time:",e);
//     // }
//   });
//   $(".datetimepicker_dateo").datetimepicker({
//     //mask:'39-19-9999',
//     format: "d-m-Y",
//     minDate: true,
//     timepicker: false,
//     closeOnDateSelect: true,
//     scrollMonth: false,
//     scrollInput: false,
//   });
//   $(".cargo_date").datetimepicker({
//     //mask:'39-19-9999',
//     format: "d-m-Y",
//     minDate: true,
//     timepicker: false,
//   });
// }
// function formatDate(dateString) {
//   if(dateString != "Invalid Date" && dateString != "" && dateString != "NaN-NaN-NaN" && dateString != undefined && dateString != 'undefined' ){
//   const date = new Date(dateString);
//   const day = String(date.getDate()).padStart(2, '0');
//   const month = String(date.getMonth() + 1).padStart(2, '0');
//   const year = date.getFullYear();
//   return `${day}-${month}-${year}`;
//   }
//   else {
//     return ""
//   }
// }
'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Datetime from 'react-datetime';
import $ from 'jquery';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Modal from 'react-responsive-modal';

import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import screenShare from 'material-ui/svg-icons/communication/screen-share';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

export default class SafetyReport extends Component {
  constructor(props) {
    super(props);
      this.state = {

        eventAction: null,
        show: false,
        modules: AllModules,
        basicTitle:'',
        basicType:"default",
        overly:"show-n",
        loadshow:"show-n",
        eventGridAction: "gridAction",
        optionlist:[],
        scrowData: [],
        rowData: [],
        screenType: "",
        suppressClickEdit: false,
        defaultColDef: {
            sortable: true,
            filter: true,
            //editable: editAdmin,
            resizable: true
        },
        screenView: "",
        showMsg: "show-n"
        
    }
    
  }

    componentDidMount(){
        var dept_code = "";
        console.log(this.props.match.path,"path")
        var screenView = "";
        if(this.props.match.path == "/safetyreportmgmt" || this.props.match.path == "/mgmtsafetyreport")
        {
            var screen = "admin";
            if(this.props.match.path == "/mgmtsafetyreport")
            {
                screenView = "Mobile";
            }
            this.setState({
                suppressClickEdit: true,

            })
        }
        if(this.props.match.path == "/sndsafetyreport" || this.props.match.path == "/scsafetyreport")
        {
            var screen = "user";

            if(this.props.match.path == "/sndsafetyreport")
            {
                dept_code = "SNDG";
            }else{ dept_code = "LOG-SC"}
            this.setState({
                suppressClickEdit: false,

            })
        }
        
        console.log(screenView, "screenView")
        this.setState({
            dept_code:  dept_code,
        })
        var sort_order = {
            "Gurgaon": 1, "SMG Plant": 2, "Manesar": 3, "IMT Manesar": 4, "TVP Bangalore": 5,
            "TVP Nagpur": 6, "Siliguri": 7, "Mundra Port": 8, "Mumbai Port": 9
        }
        redirectURL.post("/dashboard/getSafetyReport",{"screen": screen}).then((response)=>{
            var data = response.data;
            console.log(data);
            var check = data.map(function(e){
                e['sort_order'] = sort_order[e.plant_location]
            })
            Promise.all([check]).then(()=>{
                console.log(data);
                data = data.sort(function(a, b){return a['sort_order'] - b['sort_order']})
                var snddata = data.filter(function(e){
                    return e.dept_code == "SNDG"
                });
                var scdata = data.filter(function(e){
                    return e.dept_code != "SNDG"
                });
                console.log(screen, dept_code, "detcode")
                this.setState({
                    rowData: snddata,
                    scrowData: scdata,
                    screenType: screen,
                    dept_code: dept_code,
                    screenView: screenView,


                })
            })
            
        })
    }
   
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}

    onGridReady = params => {
		this.gridApi = params.api;
		
		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
		
	};


    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onClickHideAll(){
        this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:"",
        
        });
        
    }

    onEditStopped(cell)
    {
        // if(cell.colDef.field == "weather_condition")
        // {
            var rowdata = cell.data;
            var query = {"_id": rowdata._id}
            query[cell.colDef.field] = cell.value;
            redirectURL.post("/dashboard/saveSafetyWeatherReport",{"rowdata": query}).then((response)=>{
                this.setState({
                    showMsg: "show-m"
                })
                setTimeout(()=>{
                    this.setState({
                        showMsg: "show-n"
                    })  
                }, 4000)
            }).catch((e)=>{console.log(e)})
        // }
        
    }
    approveSafetyReport(){
        
    }
    onCellClicked(cell)
    {
        if(cell.colDef.field == "approve")
        {
            var approved_by = localStorage.getItem("username");
            redirectURL.post("/dashboard/approveSafetyReport", {"plant_location": cell.data.plant_location,
                                                                "approved_by": approved_by}).then((response)=>{
                this.setState({
                    basicTitle: "Approval Successfull.",
                    basicType: "success",
                    show: true
                })
            }).catch((e)=>{})
        }
    }
    render(){
        var hideEdit = (this.state.screenType == "admin")?false:true;
        const columnDefs = [
            {
                headerName: "",
                field: "approve",
                pinned: "right",
                cellRenderer: function(params){
                    return '<button class="btn btn-success" style="padding:1px 20px 1px 20px">Approve</button>'
                },
                hide: !hideEdit
            },
            {
                headerName: "Location",
                field: "plant_location",
                editable: false
            },
            // {
            //     headerName: "Weather",
            //     field: "weather",
            //     width:190,
            //     editable: hideEdit,
            //     cellEditor: 'agSelectCellEditor',
            //     cellEditorParams: {
            //         values: ['Hailstorm', "Thunderstorm", "Rain", "Dust storm during last 24 hours"],
            //     },
            // },
            {
                headerName: "Hailstorm / Thunderstorm / Rain / Dust storm during last 24 hours",
                field: "weather_condition",
                editable: hideEdit,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ['Hailstorm', "Thunderstorm", "Rain", "Dust storm", "No"],
                },
            },
            {
                headerName: "Parked Vehicles Safe",
                field: "parked_vehicles_safety",
                editable: hideEdit,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ['Yes', "No"],
                },
            },
            {
                headerName: "Infrastructure Safe",
                field: "infrastructure_safety",
                editable: hideEdit,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ['Yes', "No"],
                },
            },
            {
                headerName: "Remarks (Give Details if any abnormality found regarding vehicles or infrastructure safety)",
                field: "remarks",
                editable: hideEdit,
                
            },
        ]
        const scColumns = [
            {
                headerName: "",
                field: "approve",
                pinned: "right",
                cellRenderer: function(params){
                    return '<button class="btn btn-success" style="padding:1px 20px 1px 20px">Approve</button>'
                },
                hide: !hideEdit
            },
            {
                headerName: "Sea Port",
                field: "plant_location",
                editable: false
            },
            // {
            //     headerName: "Weather",
            //     field: "weather",
            //     width:190,
            //     editable: hideEdit,
            //     cellEditor: 'agSelectCellEditor',
            //     cellEditorParams: {
            //         values: ['Hailstorm', "Thunderstorm", "Rain", "Dust storm during last 24 hours"],
            //     },
            // },
            {
                headerName: "Hailstorm / Thunderstorm / Rain / Dust storm during last 24 hours",
                field: "weather_condition",
                editable: hideEdit,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ['Hailstorm', "Thunderstorm", "Rain", "Dust storm", "No"],
                },
            },
            {
                headerName: "Material handled",
                field: "material_handled",
                editable: hideEdit,
                
            },
            {
                headerName: "Is the Material Safe ?",
                field: "material_safety",
                editable: hideEdit,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ['Yes', "No"],
                },
            },
            {
                headerName: "Remarks",
                field: "remarks",
                editable: hideEdit,
                
            }
        ]
        // if(this.state.screenType == "admin")
        // {
            columnDefs.push({
                headerName: "Approved by",
                field: "",
                width: 350,
                valueGetter: function(params)
                {
                    return "Last Approved By SND on "+getHyphenDDMMMYYYYHHMM(params.data.approved_on)
                }
            })
            scColumns.push({
                headerName: "Approved by",
                field: "",
                width: 350,
                valueGetter: function(params)
                {
                    return "Last Approved By LOG-SC on "+getHyphenDDMMMYYYYHHMM(params.data.approved_on)
                }
            })
        // }
        console.log(this.state.screen, "this.state.screen")
        return (
            <div class="container-fluid" style={(this.state.screenView == "Mobile")?{marginTop:"2em"}:{}}>   
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="card col-xl-12 col-lg-12 col-md-12">
                    <div className="card-header">
                        <h5 style={{float:"left", width: "87%"}}><i className="icofont icofont-ui-weather f25"></i> Safety Report</h5>
                        {(this.state.screenType == "admin" && this.state.screenView != "Mobile")?
                            <button className="btn btn-warning" style={{padding:"0px 33px 5px 44px"}} onClick={()=>{window.location.href="/managementdashboard"}}><i className="icofont icofont-left-arrow f25"></i>Go Back</button>
                            :""
                        }
                    </div>
                    <div className="card-body">
                       {((this.state.dept_code == "SNDG") || (this.state.screenType == "admin"))?
                            <div id="safetygrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    enableCharts={false}
                                    enableRangeSelection={false}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    headerHeight={150}
                                    suppressClickEdit={this.state.suppressClickEdit}
                                    sideBar={this.state.sideBar}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    enableRangeSelection={false}
                                    floatingFilter={false}
                                    onCellEditingStopped={this.onEditStopped.bind(this)}
                                    onCellClicked={this.onCellClicked.bind(this)}
                                    //onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                />
                            </div>
                       :""}
                        {((this.state.dept_code == "LOG-SC") || this.state.screenType == "admin")?
                        <div id="safetygrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={scColumns}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.scrowData}
                                enableCharts={false}
                                enableRangeSelection={false}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                statusBar={this.state.statusBar}
                                headerHeight={150}
                                sideBar={this.state.sideBar}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                enableRangeSelection={false}
                                floatingFilter={false}
                                suppressClickEdit={this.state.suppressClickEdit}
                                onCellEditingStopped={this.onEditStopped.bind(this)}
                                onCellClicked={this.onCellClicked.bind(this)}
                                //onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                            />
                        </div>
                        :""}
                        {(this.state.screenType != "admin")?
                            <div class="col-lg-12 col-xl-12">
                                {/* <button className="btn btn-success" onClick={this.approveSafetyReport.bind(this)}>Aprrove All</button> */}
                            </div>
                        :""}
                        <div className={"alert alert-success "+this.state.showMsg} style={{textAlign:"center"}}><i className="fa fa-info-circle f16"></i>Selected Values are Updated !!</div>

                    </div>
                </div>

            </div>
        );
  }
}


function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
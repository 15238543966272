
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var moment = require('moment'); 
var redirectURL = require('../redirectURL');

 
const ExportsInPortAction = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onShowConsignmentExportsEventsClick(props);
    };
    

    // if(props.data.port_delay == 1)
    // {
    //     var colr = 'redfont'
    // }
    // else{
    //     if(props.data.last_status == 'Arrived at Indian Port' || props.data.last_status == 'Port In')
    //    {
    //         var colr = 'greenfont'
    //    }
    //    else{
    //         var colr = 'grayfont'
    //    }
    // }
    let colr = 'grayfont';
    if(props.data.status == 6){
        //console.log("expected_port_in ", props.data.expected_port_in)
        if (props.data.port_entry_date && props.data.expected_port_in) 
        {
								
            var revise = props.data.expected_port_in;
            var rev = revise.split("T");
            var revisedDate = rev[0];
            //console.log("revise ", revise)

            var expectd = props.data.port_entry_date;
            var exdate = expectd.split("T");
            var expectedDate = exdate[0];

            let revisedDateOnlyMoment  = moment.parseZone(revisedDate).format("x");
            let expectedDateOnlyMoment  = moment.parseZone(expectedDate).format("x");
            //hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'); 
             //console.log("revisedDateOnlyMoment= ", revisedDateOnlyMoment); 
             //console.log("expectedDateOnlyMoment= ", expectedDateOnlyMoment);
            if(revisedDateOnlyMoment > expectedDateOnlyMoment)
            {
                colr = 'redfont';
            }
            else{
               
                colr = 'yellowfont'
                
            }
        }
        else{
            colr = 'yellowfont';
        }
        
    }
    else{
        //console.log("expected_port_in ", props.data.expected_port_in)
        if (typeof props.data.port_entry_date != 'undefined' && typeof props.data.expected_port_in != 'undefined') 
        {
								
            var revise = props.data.port_entry_date;
            var rev = revise.split("T");
            var revisedDate = rev[0];
            //console.log("revise ", revise)

            var expectd = props.data.expected_port_in;
            var exdate = expectd.split("T");
            var expectedDate = exdate[0];

            let revisedDateOnlyMoment  = moment.parseZone(revisedDate).format("x");
            let expectedDateOnlyMoment  = moment.parseZone(expectedDate).format("x");
            //hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'); 
            // console.log("revisedDateOnlyMoment= ", revisedDateOnlyMoment); 
            // console.log("expectedDateOnlyMoment= ", expectedDateOnlyMoment);
             
            if(revisedDateOnlyMoment > expectedDateOnlyMoment)
            {
                colr = 'redfont';
            }
            else{
                if(props.data.status > 6)
                {   
                    colr = 'greenfont'
                }
                else{
                    //console.log(props.data.status)
                    colr = 'grayfont'
                }
            }
        }
        else{
            //console.log(props.data.status)
            if(props.data.status > 6)
            {   
                colr = 'greenfont'
            }
            else{
                //console.log(props.data.status)
                colr = 'grayfont'
            }
        }
    }
    return (
        <div>
            <button onClick={handleEventClick} className="custom-btn f22 label label-success" 
            title="Inside Port" ><i className={"icofont icofont-ship "+(colr)}></i> &nbsp;</button>
            
        </div>
    );
};

export default ExportsInPortAction;


// import React, { Component } from 'react';
// // import { useState } from 'react';

// // import ReactDOM from 'react-dom';
// // import axios from 'axios';
// import { AgGridReact } from '@ag-grid-community/react';
// import {AllModules} from '@ag-grid-enterprise/all-modules';
// // import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
// // //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import $ from "jquery";
// import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
// import { scaleQuantile } from 'd3-scale';
// import ReactTooltip from 'react-tooltip';
// import Select from "react-select";
// import LinearGradient from './LinearGradient';
// import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
// import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
// import { getHyphenDDMMMYYYYHHMM } from '../common/utils';
// import MarkerClusterer from "@google/markerclusterer";
// import Map from './mapLocationComponent';

// import redirectURL from '../redirectURL';
// // import {
// //     Accordion,
// //     AccordionItem,
// //     AccordionItemHeading,
// //     AccordionItemButton,
// //     AccordionItemPanel,
// // } from 'react-accessible-accordion';
// // import MapComponent from './mapLocationComponent';
// var globForecastDay="Today";
// var drawingManager;
// var selectedShape;
// var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
// var selectedColor;
// var colorButtons = {};
// var fencingLength=0;
// var mapZoom=5;
// var coordinates=[];
// var map;
// var marker;
// var markers = [];
// var circle;
// var moment = require('moment');
// // Red Variants
// const COLOR_RANGE = [
//   '#ffedea',
//   '#ffcec5',
//   '#ffad9f',
//   '#ff8a75',
//   '#ff5533',
//   '#e2492d',
//   '#be3d26',
//   '#9a311f',
//   '#782618'
// ];


// // will generate random heatmap data on every call
// // const getHeatMapData = () => {
// //   return [
// //     { id: 'AP', state: 'Andhra Pradesh', value: getRandomInt() },
// //     { id: 'AR', state: 'Arunachal Pradesh', value: getRandomInt() },
// //     { id: 'AS', state: 'Assam', value: getRandomInt() },
// //     { id: 'BR', state: 'Bihar', value: getRandomInt() },
// //     { id: 'CT', state: 'Chhattisgarh', value: getRandomInt() },
// //     { id: 'GA', state: 'Goa', value: 21 },
// //     { id: 'GJ', state: 'Gujarat', value: 22 },
// //     { id: 'HR', state: 'Haryana', value: getRandomInt() },
// //     { id: 'HP', state: 'Himachal Pradesh', value: 24 },
// //     { id: 'JH', state: 'Jharkhand', value: 26 },
// //     { id: 'KA', state: 'Karnataka', value: 27 },
// //     { id: 'KL', state: 'Kerala', value: getRandomInt() },
// //     { id: 'MP', state: 'Madhya Pradesh', value: getRandomInt() },
// //     { id: 'MH', state: 'Maharashtra', value: getRandomInt() },
// //     { id: 'MN', state: 'Manipur', value: getRandomInt() },
// //     { id: 'ML', state: 'Meghalaya', value: 59 },
// //     { id: 'MZ', state: 'Mizoram', value: getRandomInt() },
// //     { id: 'NL', state: 'Nagaland', value: 59 },
// //     { id: 'OR', state: 'Odisha', value: 59 },
// //     { id: 'PB', state: 'Punjab', value: getRandomInt() },
// //     { id: 'RJ', state: 'Rajasthan', value: getRandomInt() },
// //     { id: 'SK', state: 'Sikkim', value: getRandomInt() },
// //     { id: 'TN', state: 'Tamil Nadu', value: getRandomInt() },
// //     { id: 'TG', state: 'Telangana', value: getRandomInt() },
// //     { id: 'TR', state: 'Tripura', value: 14 },
// //     { id: 'UT', state: 'Uttarakhand', value: getRandomInt() },
// //     { id: 'UP', state: 'Uttar Pradesh', value: 15 },
// //     { id: 'WB', state: 'West Bengal', value: 17 },
// //     { id: 'WB', state: 'West Bengal', value: 17 },
// //     { id: 'AN', state: 'Andaman and Nicobar Islands', value: getRandomInt() },
// //     { id: 'CH', state: 'Chandigarh', value: getRandomInt() },
// //     { id: 'DN', state: 'Dadra and Nagar Haveli', value: 19 },
// //     { id: 'DD', state: 'Daman and Diu', value: 20 },
// //     { id: 'DL', state: 'Delhi', value: 59 },
// //     { id: 'JK', state: 'Jammu and Kashmir', value: 25 },
// //     { id: 'LA', state: 'Ladakh', value: getRandomInt() },
// //     { id: 'LD', state: 'Lakshadweep', value: getRandomInt() },
// //     { id: 'PY', state: 'Puducherry', value: getRandomInt() }
// //   ];
// // };
     


// export default class StateBorderGeofenceMap extends Component { 

//     constructor(props) {
//         super(props);
//         this.state={
//             modules: AllModules,
//             truckData:this.props.truckInfo,
//             consignmentlists:this.props.consignments,
//             defaultColDef: {
//                 sortable: true,
//                 filter: true,
//                 editable: true,
//             },
//             rowData: null,
//             stateName:"States",
// 			animateRows: true,
//             state_borders_data: [],
//             overly: "show-n",
//             loadshow: "show-n"
//         };
//     }
//     componentDidMount()
//     {
//         this.setState({
//             loadshow : "show-m",
//             overly : "show-m"
//         })
//         redirectURL.post("/weather/getStateGeofences").then((response)=>{
//             this.setState({
//                 state_borders_data : response.data,
//                 loadshow : "show-n",
//                 overly : "show-n"
//             });
//             this.renderMapWeather()
//         }).catch((e)=>{})
//     }
//     renderMapWeather = () => {    	
//         loadScriptWeather("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing,visualization&callback=initMapWeather");
//         window.initMapWeather = this.initMapWeather
//     }
//     onClickHideAll(){
//         this.setState({
//             loadshow : "show-n",
//             overly : "show-n"
//         })
//     }
//     initMapWeather = () => {
//     //console.log("here slidebar")
//         this.setState({
//             loadshow : "show-m",
//             overly : "show-m"
//         })
//         // // $("#mapweather").empty();
//         // //var coordsvalues;
//         var map = new window.google.maps.Map(document.getElementById('mapweather'), {
//             zoom: 8,
//             center: new window.google.maps.LatLng(28.497236,77.062363),
//             mapTypeId: window.google.maps.MapTypeId.ROADMAP,
//             mapTypeControl: true,
//             mapTypeControlOptions: {
//                 style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
//                 position: window.google.maps.ControlPosition.TOP_RIGHT
//             },            
//             disableDefaultUI: true,
//             fullscreenControl: true,
//             zoomControl: true
//         });
            
//         // var bounds = new window.google.maps.LatLngBounds();
//         // var heatMapData = [];
//         // this.state.state_borders_data.map(function(e){
//         //     var statecoords = eval(e['state_boundary_coordinates']);
//         //     // var line=new window.google.maps.Polyline({
//         //     //     map:map,
//         //     //     strokeColor: '#000',
//         //     //     strokeOpacity: 1.0,
//         //     //     strokeWeight: 2.5,
//         //     // });
           
//         //     statecoords.map(function(k){
//         //         heatMapData.push({location: new window.google.maps.LatLng(k.lat,k.lng), weight: 1.5})
                
//         //         // var path=line.getPath().getArray();
//         //         // let latLng = new window.google.maps.LatLng(k.lat,k.lng);
//         //         // bounds.extend(new window.google.maps.LatLng(k.lat,k.lng));
//         //         // path.push(latLng);
//         //         // line.setPath(path);
//         //     })
            
//         // })
       
//         // // map.fitBounds(bounds)
//         // map = new window.google.maps.Map(document.getElementById('mapweather'), {
//         //     center: new window.google.maps.LatLng(28.497236,77.062363),
//         //     zoom: 5,
//         //     mapTypeId: 'satellite'
//         //   });
//         // var heatmap = new window.google.maps.visualization.HeatmapLayer({
//         //     data: heatMapData,
//         //     radius: 2,
//         //     dissipating: false
//         // });
//         // heatmap.setMap(map);
//         // heatmap.set('gradient',[
//         // 'rgba(255, 0, 0, 0)',
//         // 'rgba(255, 0, 0, 1)'
//         // ])
//         // map.addListener('zoom_changed', function() {
//         //     mapZoom=map.getZoom();
//         // });
//         var src = ""
//         console.log(src)
//         var kmlLayer = new window.google.maps.KmlLayer(src, {
//             suppressInfoWindows: true,
//             preserveViewport: false,
//             map: map
//           });
//           kmlLayer.addListener('click', function(event) {
//             var content = event.featureData.infoWindowHtml;
//             var testimonial = document.getElementById('capture');
//             testimonial.innerHTML = content;
//           });
//         this.setState({
//             loadshow : "show-n",
//             overly : "show-n"
//         })
//     }
//     render()
//     {
//         const dStyles={
//             width:'65%',
//             height:'600px',
//             // marginTop : "20px"
//         }
        
//         return(
//             <div classNameName="full-width-height container">
//                 <div class="card row col-xl-12 col-lg-12">
//                     <div class="card-body col-xl-12 col-lg-12">
//                     <div id="mapweather" style={dStyles}></div>
//                     </div>
//                 </div>
//                 <div className={"dataLoadpage " +(this.state.loadshow)}>
// 				</div>
// 				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
// 					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
// 				</div>  
//                 <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
//             </div>
//         )
//     }
// }

// function loadScriptWeather(url){
//     var index  =window.document.getElementsByTagName("script")[1]
//       var script = window.document.createElement("script")
//       script.src=url
//       script.async=true
//       script.defer = true
//       index.parentNode.insertBefore(script,index)
//   }
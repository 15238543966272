import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getDDMMYYYY, getDDMMYYYYHHMMSS } from '../common/utils'; 

import _ from "lodash";

import axios from 'axios';

import Grid from '../layouts/gridnosidebarComponent';
import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react';
 
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");



export default class ManagePRTTransitTimes extends Component {

	constructor(props){
		super(props);
		this.state={
			pageTitle: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			rowData:null,
			uploadDivWidth:'0%',
			sliderTranslate:'',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default"
			
		}
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
	componentDidMount(){
		this.logPageView(); // Log page view to GA
		 /*Consignments List*/	
	    redirectURL.post('/consignments/prttransittimes')    
		  .then((response) => {
            var records = JSON.parse(JSON.stringify(response)).data;
            //console.log("records", records);
            this.setState({
                rowData:records
            });
		  })
		  .catch(function (error) {
		    console.log(error);
            });
	};
	onShowUploadDiv = () => {
		console.log("Click Upload Button")
		this.setState({
			uploadDivWidth:'30%',
			sliderTranslate:"slider-translate",
			showDiv:'show-m',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	render(){
		const columnwithDefs = [
            {
                headerName: "Consigner Code",
                field: "consigner_code",
                width: 150,
                  editable:false,
                  filter: "agSetColumnFilter"
            },
            {
                headerName: "Consignee Code",
                field: "consignee_code",
                width: 150,
                  editable:false,
                  filter: "agSetColumnFilter"
            },
            {
                headerName: "Transporter Code",
                field: "transporter_code",
                width: 150,
                editable:false,
               
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 150,
                editable:false,
                
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Consignee City",
                field: "consignee_city",
                width: 150,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Transit Time",
                field: "transit_time",
                width: 150,
                editable:false,
				filter: "agSetColumnFilter",
				
            },
            {
                headerName: "Distance",
                field: "distance_in_km",
                width: 150,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Payc From",
                field: "payc_eff_from",
                width: 150,
                editable:false,
				filter: "agSetColumnFilter",
				valueGetter:function(params){
					return getDDMMYYYY(params.data.payc_eff_from)
				},
				//filter: "agDateColumnFilter",
						comparator: dateComparator1,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[1] +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
            }
	  	        
	    ];
		return(
			<div className="container-fluid">
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        > 
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Manage Transit Times </span>
								</h5>
				   			</div>				   			
		            		<div className="card-body">
				   				   <Grid 
								   ashow={this.state.show} 
								   atype={this.state.basicType} 
								   atitle={this.state.basicTitle}
								   actionCall={"manage"}
									actionMethod={"updateTruckRow"}  
									table={"trucklocation"} 
									columnyaxis={this.state.rowData} 
									columnaxis={columnwithDefs} 

									/>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				 <div className={"slide-r "+(this.state.sliderTranslate)} style={{overflow:"auto"}}>
				 	
					 <div className="slide-r-title">
				 		<h4>
                            Sidebars
                        </h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
						
				 		<div className="col-xl-12 col-lg-12">
				 		<div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg "+(this.state.alerterrshow)+" "+(this.state.alerterrmg)}>{this.state.csverrmessage}</div>
						 <Timeline lineColor={'#ddd'}>
							<TimelineItem
								key="001"
								dateText="11/2010 – Present"
								dateInnerStyle={{ background: '#76bb7f', color: '#fff' }}
								bodyContainerStyle={{
								background: '#ddd',
								padding: '20px',
								borderRadius: '8px',
								boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
								}}
							>
								<p>
								123Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
								exercitation.</p>
							</TimelineItem>
							<TimelineItem
								key="002"
								dateText="04/2009 – 11/2010"
								dateInnerStyle={{ background: '#76bb7f', color: '#fff' }}
								bodyContainerStyle={{
								background: '#ddd',
								padding: '20px',
								borderRadius: '8px',
								boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
								}}
							>
								<p>
								456Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
								exercitation.</p>
							</TimelineItem>
							<TimelineItem
								key="003"
								dateComponent={(
								<div
									style={{
									display: 'block',
									float: 'left',
									padding: '10px',
									background: 'rgb(150, 150, 150)',
									color: '#fff',
									}}
								>
									11/2008 – 04/2009
								</div>
								)}
							>
								<p>
								789Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
								exercitation.</p>
							</TimelineItem>
							<TimelineItem
								key="004"
								dateText="08/2008 – 11/2008"
								dateInnerStyle={{ background: '#76bb7f' }}
							>
								<p>
								012Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
								exercitation.</p>
							</TimelineItem>
							
							</Timeline>
							<div className="row col-xl-12 col-lg-12 mb-30p">
								<button type="button" onClick={this.onCloseUploadDiv.bind(this)} className="btn btn-default cs-btn"> Cancel </button>
							</div>
				 		</div>
				 	</div>
				 </div>
            </div>
              	
		);
	}
}

function dateComparator1(date1,date2){
	console.log(date1,date2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;	
}

function monthToComparableNumber1(date) {
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
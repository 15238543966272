import React, { useState , useEffect } from "react";

const Pagination = ({ totalItems, itemsPerPage, currentPage, setCurrentPage }) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const [visiblePages, setVisiblePages] = useState(generateVisiblePages(currentPage, totalPages));

    useEffect(() => {
        setVisiblePages(generateVisiblePages(currentPage, totalPages));
    }, [currentPage, totalPages]);

    function generateVisiblePages(current, total) {
        let pages = [];

        if (total <= 6) {
            // Show all pages if there are 6 or fewer pages
            for (let i = 1; i <= total; i++) {
                pages.push(i);
            }
        } else {
            if (current <= 3) {
                pages = [1, 2, 3, 4, "...", total];
            } else if (current > total - 3) {
                pages = [1, "...", total - 3, total - 2, total - 1, total];
            } else {
                pages = [1, "...", current - 1, current, current + 1, "...", total];
            }
        }

        return pages;
    }

    const changePage = (page) => {
        if (page === "..." || page === currentPage) return;
        setCurrentPage(page);
        setVisiblePages(generateVisiblePages(page, totalPages));
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prev) => prev + 1);
            setVisiblePages(generateVisiblePages(currentPage + 1, totalPages));
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prev) => prev - 1);
            setVisiblePages(generateVisiblePages(currentPage - 1, totalPages));
        }
    };

    return (
        <div className="pagination">
            <button
                className="pagination-btn"
                onClick={prevPage}
                disabled={currentPage === 1}
            >
                &lt;
            </button>
            {visiblePages.map((page, index) => (
                <button
                    key={index}
                    className={`pagination-btn ${page === currentPage ? "active" : ""}`}
                    onClick={() => changePage(page)}
                >
                    {page}
                </button>
            ))}
            <button
                className="pagination-btn"
                onClick={nextPage}
                disabled={currentPage === totalPages}
            >
                &gt;
            </button>
        </div>
    );
};

export default Pagination;

import React, { Component } from "react";
import BatchDownload from "./batchdownload";
import redirectURL from "../redirectURL";
import fs from "fs"; // Node.js fs module for reading files

export default class LedRailwayLogisticsscreen5 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowdata: [],
      loadshow: "show-n",
      overly: "show-n",
      htmlContent: "", // State variable to store HTML content
    };
  }

  autororate(){ 
    console.log(localStorage.getItem("username"),'username')
    if(localStorage.getItem("username") == "LED USER"){
     this.rotationInterval = setInterval(this.rotateScreens, 60000);
    }
  
  }
 
  componentWillUnmount() {
     clearInterval(this.rotationInterval);
   }
 
   rotateScreens = () => {
  
    window.location.href="/ledrailwaylogisticsscreen"
  }

  componentDidMount() {
    document.body.addEventListener("click", this.handleClickOutside);
   
    this.autororate()
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.handleClickOutside);
  }



  render() {
    const  htmlContent  = `
    <html>
    <head>
        <title>Sales & Despatch</title>
        <meta http-equiv="refresh" content="120">
        <meta content="Microsoft Visual Studio .NET 7.1" name="GENERATOR">
        <meta content="C#" name="CODE_LANGUAGE">
        <meta content="JavaScript" name="vs_defaultClientScript">
        <meta content="http://schemas.microsoft.com/intellisense/ie5" name="vs_targetSchema">
        <link href="Config/CarrerStyles.css" type="text/css" rel="stylesheet">
        <script language="javascript" src="Config\popcalendar.js"></script>
    </head>
    <body>
        <table height="100%" cellspacing="0" cellpadding="1" width="100%" border="0">
            <tbody>
                <tr valign="top">
                    <td class="status_msg" align="left" background="./Images/background.gif">
                        Last Update :
                <span id="lblref" style="text-align: left; font-family: Verdana; color: #990000">12:03:30 PM</span>
                    </td>
                    <td class="status_msg" valign="top" background="./Images/background.gif" height="20">SALES &DESPATCH
            </td>
                    <td class="ReportHeading" background="./Images/background.gif">
                        <span id="face" style="text-align: right; font-family: Verdana; color: #990000"></span>
                    </td>
                </tr>
                <tr valign="top">
                    <td class="status_msg" align="left" background="./Images/background.gif">
                        USER ID :
                <span id="lblusrId" style="text-align: left; font-family: Verdana; color: #990000">588750</span>
                    </td>
                    <td class="status_msg" valign="top" background="./Images/background.gif" height="20"></td>
                    <td class="ReportHeading" background="./Images/background.gif"></td>
                </tr>
                <tr>
                    <td width="5" colspan="3"></td>
                </tr>
                <tr height="25%">
                <tr>
                    <td style="text-transform: uppercase; font-family: Verdana; font-weight: bold" bgcolor="#66ccff">ORDER STATUS:
                </td>
                    <td style="text-transform: uppercase; font-family: Verdana; font-weight: bold" bgcolor="#66ccff">OUTBOUND LOGISTICS :
                </td>
                    <td style="text-transform: uppercase; font-family: Verdana; font-weight: bold" bgcolor="#66ccff">MOVE DETAILS :
                </td>
                </tr>
                <tr width="100%">
                    <td>
                        <table class="text1" cellspacing="0" rules="all" id="ReportGrid1" style="background-color:White;border-color:#CCCCCC;border-width:1px;border-style:None;height:100%;width:99%;border-collapse:collapse;">
                            <tr align="left" style="color:Black;background-color:#FCE0B8;font-size:9pt;font-weight:bold;">
                                <td>REGION</td>
                                <td>ORD.RECD</td>
                                <td>CAR.FORW</td>
                                <td>FUND.VAL</td>
                                <td>ORD.RECD-G</td>
                                <td>CAR.FORW-G</td>
                                <td>FUND.VAL-G</td>
                                <td>ORD.RECD-B</td>
                                <td>CAR.FORW-B</td>
                                <td>FUND.VAL-B</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="background-color:Yellow;">CENTRAL</td>
                                <td align="right">190</td>
                                <td align="right">1597</td>
                                <td align="right">605</td>
                                <td align="right">96</td>
                                <td align="right">1602</td>
                                <td align="right">373</td>
                                <td align="right">27</td>
                                <td align="right">460</td>
                                <td align="right">156</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="background-color:Yellow;">EAST</td>
                                <td align="right">68</td>
                                <td align="right">1426</td>
                                <td align="right">355</td>
                                <td align="right">39</td>
                                <td align="right">719</td>
                                <td align="right">235</td>
                                <td align="right">8</td>
                                <td align="right">169</td>
                                <td align="right">69</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="background-color:Yellow;">NORTH</td>
                                <td align="right">153</td>
                                <td align="right">1679</td>
                                <td align="right">448</td>
                                <td align="right">110</td>
                                <td align="right">1841</td>
                                <td align="right">304</td>
                                <td align="right">27</td>
                                <td align="right">486</td>
                                <td align="right">120</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="background-color:Yellow;">SOUTH</td>
                                <td align="right">57</td>
                                <td align="right">1596</td>
                                <td align="right">1367</td>
                                <td align="right">203</td>
                                <td align="right">2021</td>
                                <td align="right">1594</td>
                                <td align="right">13</td>
                                <td align="right">567</td>
                                <td align="right">453</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="background-color:Yellow;">SOUTH-EAST</td>
                                <td align="right">262</td>
                                <td align="right">1107</td>
                                <td align="right">800</td>
                                <td align="right">50</td>
                                <td align="right">806</td>
                                <td align="right">227</td>
                                <td align="right">14</td>
                                <td align="right">197</td>
                                <td align="right">132</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="background-color:Yellow;">WEST</td>
                                <td align="right">163</td>
                                <td align="right">1859</td>
                                <td align="right">850</td>
                                <td align="right">243</td>
                                <td align="right">1545</td>
                                <td align="right">444</td>
                                <td align="right">56</td>
                                <td align="right">313</td>
                                <td align="right">92</td>
                            </tr>
                            <tr style="color:#000066;background-color:Gainsboro;font-weight:bold;">
                                <td style="color:Black;">Total</td>
                                <td align="right" style="color:Black;">893</td>
                                <td align="right" style="color:Black;">9264</td>
                                <td align="right" style="color:Black;">4425</td>
                                <td align="right" style="color:Black;">741</td>
                                <td align="right" style="color:Black;">8534</td>
                                <td align="right" style="color:Black;">3177</td>
                                <td align="right" style="color:Black;">145</td>
                                <td align="right" style="color:Black;">2192</td>
                                <td align="right" style="color:Black;">1022</td>
                            </tr>
                        </table>
                    </td>
                    <td>
                        <table class="text1" cellspacing="0" rules="all" id="ReportGrid2" style="background-color:White;border-color:#CCCCCC;border-width:1px;border-style:None;height:100%;width:99%;border-collapse:collapse;">
                            <tr align="center" style="color:Black;background-color:#FCE0B8;font-size:9pt;font-weight:bold;">
                                <td>REGION</td>
                                <td>TOD.ARR.</td>
                                <td>WAIT.FLE</td>
                                <td>TOD.ARR.-G</td>
                                <td>WAIT.FLE-G</td>
                                <td>TOD.ARR.-B</td>
                                <td>WAIT.FLE-B</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="background-color:Yellow;">CENTRAL</td>
                                <td align="right">0</td>
                                <td align="right">8</td>
                                <td align="right">0</td>
                                <td align="right">0</td>
                                <td align="right">0</td>
                                <td align="right">0</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="background-color:Yellow;">EAST</td>
                                <td align="right">20</td>
                                <td align="right">80</td>
                                <td align="right">17</td>
                                <td align="right">35</td>
                                <td align="right">3</td>
                                <td align="right">24</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="background-color:Yellow;">MP</td>
                                <td align="right">17</td>
                                <td align="right">34</td>
                                <td align="right">19</td>
                                <td align="right">18</td>
                                <td align="right">1</td>
                                <td align="right">15</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="background-color:Yellow;">NORTH</td>
                                <td align="right">41</td>
                                <td align="right">161</td>
                                <td align="right">32</td>
                                <td align="right">26</td>
                                <td align="right">9</td>
                                <td align="right">30</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="background-color:Yellow;">NORTH-EAST</td>
                                <td align="right">0</td>
                                <td align="right">1</td>
                                <td align="right">1</td>
                                <td align="right">1</td>
                                <td align="right">0</td>
                                <td align="right">0</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="background-color:Yellow;">SOUTH</td>
                                <td align="right">30</td>
                                <td align="right">99</td>
                                <td align="right">20</td>
                                <td align="right">29</td>
                                <td align="right">11</td>
                                <td align="right">25</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="background-color:Yellow;">SOUTH-EAST</td>
                                <td align="right">0</td>
                                <td align="right">0</td>
                                <td align="right">0</td>
                                <td align="right">0</td>
                                <td align="right">0</td>
                                <td align="right">0</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="background-color:Yellow;">STOCKYARD</td>
                                <td align="right">13</td>
                                <td align="right">49</td>
                                <td align="right">0</td>
                                <td align="right">4</td>
                                <td align="right">1</td>
                                <td align="right">7</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="background-color:Yellow;">WEST</td>
                                <td align="right">31</td>
                                <td align="right">133</td>
                                <td align="right">26</td>
                                <td align="right">20</td>
                                <td align="right">7</td>
                                <td align="right">30</td>
                            </tr>
                            <tr style="color:#000066;background-color:Gainsboro;font-weight:bold;">
                                <td style="color:Black;">Total</td>
                                <td align="right" style="color:Black;">152</td>
                                <td align="right" style="color:Black;">565</td>
                                <td align="right" style="color:Black;">115</td>
                                <td align="right" style="color:Black;">133</td>
                                <td align="right" style="color:Black;">32</td>
                                <td align="right" style="color:Black;">131</td>
                            </tr>
                        </table>
                    </td>
                    <td valign="top">
                        <table id="main" border="0" cellpadding="0" cellspacing="0" width="100%">
                            <tr height="20%">
                                <td>
                                    <table class="text1" cellspacing="0" rules="all" id="ReportGrid3" style="background-color:White;border-color:#CCCCCC;border-width:1px;border-style:None;height:100%;width:99%;border-collapse:collapse;">
                                        <tr align="center" style="color:Black;background-color:#FCE0B8;font-size:9pt;font-weight:bold;">
                                            <td>MOVE UPDATED</td>
                                            <td>PLAN</td>
                                            <td>TRANSIT</td>
                                        </tr>
                                        <tr align="center" style="color:#000066;background-color:Yellow;">
                                            <td style="width:40%;">0</td>
                                            <td style="width:30%;">38</td>
                                            <td style="width:30%;">16</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr height="20%">
                                <td>
                                    <table class="text1" cellspacing="0" rules="all" id="ReportGrid6" style="background-color:White;border-color:#CCCCCC;border-width:1px;border-style:None;height:100%;width:99%;border-collapse:collapse;">
                                        <tr align="center" style="color:Black;background-color:#FCE0B8;font-size:9pt;font-weight:bold;">
                                            <td>HANDOVER PENDING</td>
                                            <td>HANDOVER DONE</td>
                                            <td>PENDING FOR EXIT</td>
                                        </tr>
                                        <tr align="center" style="color:#000066;background-color:Yellow;">
                                            <td style="width:40%;">16</td>
                                            <td style="width:30%;">24</td>
                                            <td style="width:30%;">153</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr height="20%">
                                <td>
                                    <table class="text1" cellspacing="0" rules="all" id="Datagrid12" style="background-color:White;border-color:#CCCCCC;border-width:1px;border-style:None;height:100%;width:99%;border-collapse:collapse;">
                                        <tr align="center" style="color:Black;background-color:#FCE0B8;font-size:9pt;font-weight:bold;">
                                            <td>MOVE EXIT FROM FAC</td>
                                            <td>GATE IN MANESAR</td>
                                            <td>TPAOUT</td>
                                        </tr>
                                        <tr align="center" style="color:#000066;background-color:Yellow;">
                                            <td style="width:40%;">0</td>
                                            <td style="width:30%;">16</td>
                                            <td style="width:20%;">0</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td style="text-transform: uppercase; font-family: Verdana; font-weight: bold" bgcolor="#66ccff" colspan="3">STOCK STATUS :
                </td>
                </tr>
                <tr valign="top" height="25%">
                    <td valign="top" colspan="3">
                        <table class="text1" cellspacing="0" rules="all" id="ReportGrid4" style="background-color:White;border-color:#CCCCCC;border-width:1px;border-style:None;height:100%;width:100%;border-collapse:collapse;">
                            <tr align="center" style="color:Black;background-color:#FCE0B8;font-size:9pt;font-weight:bold;">
                                <td>STOCKYARD</td>
                                <td>EXPORT</td>
                                <td>DOMESTIC</td>
                                <td>NH</td>
                                <td>UR</td>
                                <td>Q</td>
                                <td>OTHERS</td>
                                <td>TOTAL</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="width:12%;">BANGALORE</td>
                                <td align="center" style="width:12%;">791</td>
                                <td align="center" style="width:12%;">1708</td>
                                <td align="center" style="width:12%;">0</td>
                                <td align="center" style="width:12%;">21</td>
                                <td align="center" style="width:12%;">0</td>
                                <td align="center" style="width:12%;">0</td>
                                <td align="center" style="width:12%;">2520</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="width:12%;">GURGAON</td>
                                <td align="center" style="width:12%;">923</td>
                                <td align="center" style="width:12%;">5148</td>
                                <td align="center" style="width:12%;">59</td>
                                <td align="center" style="width:12%;">194</td>
                                <td align="center" style="width:12%;">76</td>
                                <td align="center" style="width:12%;">31</td>
                                <td align="center" style="width:12%;">6431</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="width:12%;">MANESAR</td>
                                <td align="center" style="width:12%;">1017</td>
                                <td align="center" style="width:12%;">13964</td>
                                <td align="center" style="width:12%;">10</td>
                                <td align="center" style="width:12%;">4</td>
                                <td align="center" style="width:12%;">58</td>
                                <td align="center" style="width:12%;">18</td>
                                <td align="center" style="width:12%;">15071</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="width:12%;">TPA</td>
                                <td align="center" style="width:12%;">0</td>
                                <td align="center" style="width:12%;">114</td>
                                <td align="center" style="width:12%;">0</td>
                                <td align="center" style="width:12%;">0</td>
                                <td align="center" style="width:12%;">0</td>
                                <td align="center" style="width:12%;">0</td>
                                <td align="center" style="width:12%;">114</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="width:12%;">&nbsp;</td>
                                <td align="center" style="width:12%;">723</td>
                                <td align="center" style="width:12%;">0</td>
                                <td align="center" style="width:12%;">0</td>
                                <td align="center" style="width:12%;">15</td>
                                <td align="center" style="width:12%;">0</td>
                                <td align="center" style="width:12%;">0</td>
                                <td align="center" style="width:12%;">738</td>
                            </tr>
                            <tr style="color:Black;background-color:Gainsboro;">
                                <td style="width:12%;">TOTAL</td>
                                <td align="center" style="width:12%;">3454</td>
                                <td align="center" style="width:12%;">20934</td>
                                <td align="center" style="width:12%;">69</td>
                                <td align="center" style="width:12%;">234</td>
                                <td align="center" style="width:12%;">134</td>
                                <td align="center" style="width:12%;">49</td>
                                <td align="center" style="width:12%;">24874</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="width:12%;">GUJARAT</td>
                                <td align="center" style="width:12%;">6259</td>
                                <td align="center" style="width:12%;">4270</td>
                                <td align="center" style="width:12%;">12</td>
                                <td align="center" style="width:12%;">0</td>
                                <td align="center" style="width:12%;">547</td>
                                <td align="center" style="width:12%;">36</td>
                                <td align="center" style="width:12%;">11124</td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr valign="top">
                    <td style="text-transform: uppercase; font-family: Verdana; font-weight: bold" bgcolor="#66ccff" colspan="3">PLANnING AND DISPATCH :
                </td>
                </tr>
                <tr valign="top" height="25%">
                    <td valign="top" colspan="3">
                        <table class="text1" cellspacing="0" rules="all" id="ReportGrid5" style="background-color:White;border-color:#CCCCCC;border-width:1px;border-style:None;height:100%;width:100%;border-collapse:collapse;">
                            <tr align="center" style="color:Black;background-color:#FCE0B8;font-size:9pt;font-weight:bold;">
                                <td>STOCKYARD</td>
                                <td>PLAN</td>
                                <td>ATTACH</td>
                                <td>LPD</td>
                                <td>ALLOCATION</td>
                                <td>DOM INVOICE</td>
                                <td>EXP INVOICE</td>
                                <td>PENDING FOR EXIT</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="width:10%;">BANGALORE</td>
                                <td align="center" style="width:13%;">39</td>
                                <td align="center" style="width:13%;">0</td>
                                <td align="center" style="width:13%;">76</td>
                                <td align="center" style="width:13%;">0</td>
                                <td align="center" style="width:13%;">119</td>
                                <td align="center" style="width:13%;">13</td>
                                <td align="center" style="width:13%;">168</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="width:10%;">GURGAON</td>
                                <td align="center" style="width:13%;">244</td>
                                <td align="center" style="width:13%;">20</td>
                                <td align="center" style="width:13%;">194</td>
                                <td align="center" style="width:13%;">15</td>
                                <td align="center" style="width:13%;">91</td>
                                <td align="center" style="width:13%;">138</td>
                                <td align="center" style="width:13%;">788</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="width:10%;">MANESAR</td>
                                <td align="center" style="width:13%;">142</td>
                                <td align="center" style="width:13%;">144</td>
                                <td align="center" style="width:13%;">162</td>
                                <td align="center" style="width:13%;">0</td>
                                <td align="center" style="width:13%;">263</td>
                                <td align="center" style="width:13%;">44</td>
                                <td align="center" style="width:13%;">1302</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="width:10%;">TPA</td>
                                <td align="center" style="width:13%;">42</td>
                                <td align="center" style="width:13%;">0</td>
                                <td align="center" style="width:13%;">0</td>
                                <td align="center" style="width:13%;">0</td>
                                <td align="center" style="width:13%;">0</td>
                                <td align="center" style="width:13%;">0</td>
                                <td align="center" style="width:13%;">0</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="width:10%;">&nbsp;</td>
                                <td align="center" style="width:13%;">19</td>
                                <td align="center" style="width:13%;">49</td>
                                <td align="center" style="width:13%;">14</td>
                                <td align="center" style="width:13%;">0</td>
                                <td align="center" style="width:13%;">0</td>
                                <td align="center" style="width:13%;">0</td>
                                <td align="center" style="width:13%;">0</td>
                            </tr>
                            <tr style="color:Black;background-color:Gainsboro;">
                                <td style="width:10%;">TOTAL</td>
                                <td align="center" style="width:13%;">486</td>
                                <td align="center" style="width:13%;">213</td>
                                <td align="center" style="width:13%;">446</td>
                                <td align="center" style="width:13%;">15</td>
                                <td align="center" style="width:13%;">473</td>
                                <td align="center" style="width:13%;">195</td>
                                <td align="center" style="width:13%;">2258</td>
                            </tr>
                            <tr style="color:#000066;background-color:Yellow;">
                                <td style="width:10%;">GUJARAT</td>
                                <td align="center" style="width:13%;">508</td>
                                <td align="center" style="width:13%;">66</td>
                                <td align="center" style="width:13%;">191</td>
                                <td align="center" style="width:13%;">0</td>
                                <td align="center" style="width:13%;">890</td>
                                <td align="center" style="width:13%;">90</td>
                                <td align="center" style="width:13%;">2646</td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td style="height: 9px" valign="middle" align="left" bgcolor="#cccccc" colspan="6">
                        <span id="lblstatus" class="FormStatus"></span>
                    </td>
                </tr>
                </tr>
            </tbody>
        </table>
    </body>
</html>

    `;

   

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="card">
              <div className="card-header">
                <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

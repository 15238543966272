import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import Autosuggest from 'react-autosuggest';

import Grid from '../layouts/gridActionComponent';
import Breadcrumb from '../common/breadcrumb.component';
import EditItem from '../layouts/editActionComponent';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var selectRowItemFromAgGrid='';

export default class TptGeoFence extends Component {

    constructor(props) {
        super(props);

        this.state = {
			lists:[],
			departmentCode: null,
			currentDepartmentTitle: null,
			currentAddGeoFenceLink: null,
			usermanualmodal:false,
			rowData : []
        };
	}
    componentDidMount(){
		var is_enmovil_transporter = localStorage.getItem("is_enmovil_transporter");
        if(is_enmovil_transporter == 1)
        {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
			}
			var transporter_code = "";
			if(localStorage.getItem('transportercode') !=undefined)
			{
				var tptCode = JSON.parse(localStorage.getItem("transportercode"));
				var transporter_code = tptCode[0];
			}
			var params = {
				"transporter_code" : transporter_code  
			}
			console.log(params,"paramsss")
			redirectURL.post("/geofence/tptlists",params).
			then(
				(response)=>{
					let records = (response.data) ? response.data : null;				
					console.log("/geofence/lists records = ", records);
					this.setState({
						rowData:records
					});
				}
			)
			.catch(function(error){
				console.log(error);
			});
		}
		else
		{
			window.location.href="/";
		}
    }
   
    getDepartmentCodes = (params) => {
    	var depts = params.value;
    	var departments=[];
		var deptname='';
		
    	for(var item in depts)
    	{
    		deptname = deptname + depts[item].value +", ";
    		//console.log(depts[item].dept_code);
    		departments.push(depts[item].value);
    	}
    	//console.log("departments",deptname);
    	//console.log("departments",departments);
    	return deptname;
    }
    
    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
	};
	
	onSelectionChanged() {
	    var selectedRows = this.gridApi.getSelectedRows();
	    selectRowItemFromAgGrid=selectedRows;
	  }
    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
		
		const {usermanualmodal} = this.state;
    	const columnwithDefs = [
	  	        {
	    	          headerName: "GeoFence Name",
	    	          field: "geofence_name",
	    	          width: 200,
	    	          
    	        },
	    	    {
	  	          headerName: "Geo Fence Type",
	  	          field: "geofence_type",
	  	          width: 200,
	  	          cellRenderer:function(params){	
	  	        	  var gtype= params.value.value;
					  return gtype;
				   } 
	      	          
	  	        },
		        {
		          headerName: "Notify Me",
		          field: "notify_me",
		          width: 200,
	    	          
		        },
		        {
	  	        	field:'_id',
					headerName:'',
					width: 100,
					cellRenderer:function(params){
						var geoname = params.data.geofence_name;
						var modifiedName  = geoname.replace(/ /g,"_")
						var htmloption = '<a href="/tptlogin/editTptGeofence/'+modifiedName+'" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
						return htmloption;
					} 
		        	
					//cellRenderer:['editItem', 'deleteItem']
					
    	        },
		              	       
	      ];
        return (
            
    		<div className="container-fluid">
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>Geo Fences {this.state.currentDepartmentTitle} </span>
				       				{/* <a href="/manage/sndaddgeofence" className="btn btn-success pull-right cs-btn"> <i className="icon-plus"></i> Add Geo Fence</a> */}
				       				<a href="/tptlogin/addtptgeofence" className="btn btn-success pull-right cs-btn"> <i className="icon-plus"></i> Add Geo Fence</a>
				       			</h5>
				   			</div>
				   			<div className="card-body">
							   <div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
								<AgGridReact
									modules={this.state.modules}
									columnDefs={columnwithDefs}
									defaultColDef={this.state.defaultColDef}
									rowData={this.state.rowData}
									rowSelection={this.state.rowSelection}
									enableCharts={false}
									enableRangeSelection={true}
									pagination={true}
									onGridReady={this.onGridReady}
									onGridState={this.onGridState}
									deleteRowHandler={this.deleteRowHandler}
									onSelectionChanged={this.onSelectionChanged.bind(this)}
									frameworkComponents={this.state.frameworkComponents}
								/>
							</div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
				<Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
					<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<h5 className="model-title">Geofences</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							<div className="col-xl-12 col-lg-12">
								This screen consists of all Origin points along with fixed destination points where unloading happens and which belongs to MSIL like the ports used for exports, stockyards etc.
							</div>
							
							<div className="col-xl-12 col-lg-12">	
								Image Here
							</div>
							<div className="col-xl-12 col-lg-12">
								After clicking on Add Geo fence button below screen will be prompted for user to enter required details. The entered details will be reflected over Geo Fences screen.
							</div>
							<div className="col-xl-12 col-lg-12">	
								Image Here
							</div>
							<div className="col-xl-12 col-lg-12">	
								Image Here
							</div>
						</div>
					</div>
				</Modal>
            </div>
              
        );
    }
}



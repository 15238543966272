
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const ImportsMsilAction = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onShowConsignmentImportsEventsClick(props);
    };
    
   // if(props.data.msil_transit_delay == 1)
    //{
   //     var colr = 'redfont'
   // }
   // else{
        if(props.data.last_status == 'In Transit to MSIL')
       {
            var colr = 'yellowfont'
       }
       else{
            var colr = 'grayfont'
       }
    //}
    return (
        <div>
            <button onClick={handleEventClick} className="custom-btn f22 label label-success" 
            title="MSIL In Transit" ><i className={"icofont icofont-truck-alt "+(colr)}></i> &nbsp;</button>
            
        </div>
    );
};

export default ImportsMsilAction;

'use strict';

import React, { Component } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';

import { AllModules } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CellGraphComponent from './cellGraphComponent';
import $ from 'jquery';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var CELL_DIMENSION_SIZE = 90;
export default class DashboardTemplate1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // modules: [
      //   ClientSideRowModelModule,
      //   RowGroupingModule,
      //   MenuModule,
      //   ColumnsToolPanelModule,
      //   FiltersToolPanelModule,
      //   SetFilterModule,
      //   AllModules
      // ],
      modules: AllModules,
      defaultColDef: {
        //flex: 1,
        minWidth: 50,
        filter: true,
        sortable: true,
      },
      autoGroupColumnDef: {
        headerName: 'Department',
        field: 'dept_code',
        minWidth: 50,
        width:240
      },
      rowData: null,
      popupParent: document.body,
      groupHeaderHeight: 50,
      headerHeight: 50,
      floatingFiltersHeight: 50,
      pivotGroupHeaderHeight: 30,
      pivotHeaderHeight: 50,
      rowHeight:50,
      startDate:'',
      endDate:'',
      frameworkComponents:{
        cellGraphComponent:CellGraphComponent
      },

      processChartOptions: function(params) {
        var opts = params.options;
        //console.log("opts",opts)
        opts.title.enabled = true;
        opts.title.text = 'Total Consignments Delay Meter for this period';
        opts.legend.position = 'bottom';
        opts.seriesDefaults.tooltip.renderer = function(params) {
          var titleStyle = params.color
            ? ' style="color: #333; background-color:' + params.color + '"'
            : '';
            //console.log("params.title ", params.title)
          var title = params.title
            ? '<div class="ag-chart-tooltip-title"' +
              titleStyle +
              '>' +
              params.title +
              '</div>'
            : '';
          var value = params.datum[params.yKey]
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          return (
            title +
            '<div class="ag-chart-tooltip-content" style="text-align: center">' +
            value +
            '</div>'
          );
        };
        if (opts.xAxis) {
          opts.xAxis.label.rotation = 0;
        }
        console.log("opts.yAxis ", opts.yAxis)
        if (opts.yAxis) {
          opts.yAxis.label.rotation = 0;
        }
        return opts;
      },
    };
  }


	componentDidMount(){
    var defaultdate = moment.parseZone().subtract(1, 'days').format('YYYY-MM-DD');
    this.setState({
      startDate:defaultdate,
      endDate:defaultdate,
    });
    var reqparms = {
      startDate:defaultdate,
      endDate:defaultdate
    }
        redirectURL.post("/consignments/dashboardtemplatedata", reqparms)
        .then((response) => {
          var recordsarr = response.data.message;
          var records=[];
          recordsarr.map((item) => {
            var zerodays;
            var onedays;
            var twodays;
            var activezerodays;
            var activeonedays;
            var activetwodays;
            //console.log("item.transit_delay_days ", typeof parseInt(item.transit_delay_days))
            // if(parseInt(item.transit_delay_days) == 0 || 
            // parseInt(item.transit_delay_days) == 1 ||
            // parseInt(item.transit_delay_days) == 2)
            // {
              var set;
              var status;
              var activeset;
                if(parseInt(item.transit_delay_days) == 0 && parseInt(item.set) == 1 && parseInt(item.status) == 2)
                {
                      zerodays=1
                }
      
                if(parseInt(item.transit_delay_days) == 1 && parseInt(item.set) == 1 && parseInt(item.status) == 2)
                {
                      onedays=1
                }
      
                if(parseInt(item.transit_delay_days) == 2 && parseInt(item.set) == 1 && parseInt(item.status) == 2)
                {
                      twodays=1
                }

                if(parseInt(item.set) == 1)
                {
                    set=1;
                }
                else{
                    set=0;
                }
                if(parseInt(item.status) == 2)
                {
                    status=1;
                }
                else{
                    status=0;
                }

                if(parseInt(item.set) == 2)
                {
                    activeset=1;
                }
                else{
                  activeset=0;
                }


                if(parseInt(item.transit_delay_days) == 0 && parseInt(item.set) == 2 && parseInt(item.status) == 2)
                {
                      activezerodays=1
                }
      
                if(parseInt(item.transit_delay_days) == 1 && parseInt(item.set) == 2 && parseInt(item.status) == 2)
                {
                    activeonedays=1
                }
      
                if(parseInt(item.transit_delay_days) == 2 && parseInt(item.set) == 2 && parseInt(item.status) == 2)
                {
                    activetwodays=1
                }


                var itemdata= {
                    "consigner_code": item.consigner_code,
                    "consignment_code": item.consignment_code,
                    "dept_code": item.dept_code,
                    "dept_name": item.dept_name,
                    "invoice_date": item.invoice_date,
                    "transit_delay_days_0": (typeof zerodays == 'undefined')?0:zerodays,
                    "transit_delay_days_1": (typeof onedays == 'undefined')?0:onedays,
                    "transit_delay_days_2": (typeof twodays == 'undefined')?0:twodays,
                    "uom": item.uom,
                    "set": (typeof set == 'undefined')?0:set,
                    "status": (typeof status == 'undefined')?0:status,
                    "activeset": (typeof activeset == 'undefined')?0:activeset,
                    "active_transit_delay_days_0": (typeof activezerodays == 'undefined')?0:activezerodays,
                    "active_transit_delay_days_1": (typeof activeonedays == 'undefined')?0:activeonedays,
                    "active_transit_delay_days_2": (typeof activetwodays == 'undefined')?0:activetwodays,
                };
                records.push(itemdata)
            //}
            
            
          })
          //console.log("Respons ", records)
          //updateData(records);
          this.setState({
            rowData:records
          });
        })
        .catch(function(e){
          console.log("Error ", e)
          
        })
        
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

  };

  expandAll = () => {
    this.gridApi.expandAll();
  };

  collapseAll = () => {
    this.gridApi.collapseAll();
  };

  groupRowAggNodes = nodes => {
    var result = {
        transit_delay_days_0: 0,
        transit_delay_days_1: 0,
        transit_delay_days_2: 0,
        active_transit_delay_days_0: 0,
        active_transit_delay_days_1: 0,
        active_transit_delay_days_2: 0,
        set: 0,
        status: 0,
        activeset:0
    };
    nodes.forEach(function(node) {
      var data = node.group ? node.aggData : node.data;
   // console.log("node ",data)
      //console.log("nodedata ",typeof data.active_transit_delay_days_0)
      if (typeof data.transit_delay_days_0 === 'number') {
        result.transit_delay_days_0 += data.transit_delay_days_0;
      }
      if (typeof data.transit_delay_days_1 === 'number') {
        result.transit_delay_days_1 += data.transit_delay_days_1;
      }
      if (typeof data.transit_delay_days_2 === 'number') {
        result.transit_delay_days_2 += data.transit_delay_days_2;
      }
      if (typeof data.set === 'number') {
        result.set += data.set;
      }
      if (typeof data.activeset === 'number') {
        result.activeset += data.activeset;
      }
      if (typeof data.status === 'number') {
        result.status += data.status;
      }

      if (typeof data.active_transit_delay_days_0 === 'number') {
        result.active_transit_delay_days_0 += data.active_transit_delay_days_0;
      }
      if (typeof data.active_transit_delay_days_1 === 'number') {
        result.active_transit_delay_days_1 += data.active_transit_delay_days_1;
      }
      if (typeof data.active_transit_delay_days_2 === 'number') {
        result.active_transit_delay_days_2 += data.active_transit_delay_days_2;
      }
    });
   //console.log("asda ", result)
    return result;
  };
  onFirstDataRendered = params => {
    var createRangeChartParams = {
      cellRange: {
        rowStartIndex: 0,
        rowEndIndex: 79,
        columns: ['dept_name', 'transit_delay_days_0', 'transit_delay_days_1', 'transit_delay_days_2'],
      },
      chartType: 'groupedColumn',
      chartContainer: document.querySelector('#chart'),
      aggFunc: 'sum',
    };
    params.api.createRangeChart(createRangeChartParams);

    var createRangeChartParams1 = {
      cellRange: {
        rowStartIndex: 0,
        rowEndIndex: 79,
        columns: ['dept_name', 'active_transit_delay_days_0', 'active_transit_delay_days_1', 'active_transit_delay_days_2'],
      },
      chartType: 'groupedColumn',
      chartContainer: document.querySelector('#chartactive'),
      aggFunc: 'sum',
      processChartOptions: function(params) {
        var opts = params.options;
        opts.title.enabled = true;
        opts.title.text = 'Active Consignments Delay Meter as on Date';
        opts.legend.position = 'bottom';
        //console.log("params.options.legend ",params.options.legend)
        params.options.legend.position = 'bottom';
        params.options.padding = {
          top: 20,
          left: 10,
          bottom: 30,
          right: 10,
        };
        params.options.seriesDefaults.tooltip.renderer = function(params) {
          var titleStyle = params.color
            ? ' style="color: white; background-color:' + params.color + '"'
            : '';
          var title = params.title
            ? '<div class="ag-chart-tooltip-title"' +
              titleStyle +
              '>aa' +
              params.title +
              '</div>'
            : '';
          // var value = params.datum[params.angleKey]
          //   .toString()
          //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          // return (
          //   title +
          //   '<div class="ag-chart-tooltip-content" style="text-align: center">' +
          //   value +
          //   '</div>'
          // );
        };
        return params.options;
      },
    };
    params.api.createRangeChart(createRangeChartParams1);

    // //Chart2
    //   var eContainer2 = document.querySelector('#chart2');
    //   var params2 = {
    //     cellRange: {
    //       columns: ['dept_name', 'set'],
    //     },
    //     chartType: 'pie',
    //     chartContainer: eContainer2,
    //     aggFunc: 'sum',
    //     processChartOptions: function(params) {
    //       params.options.legend.position = 'bottom';
    //       params.options.padding = {
    //         top: 20,
    //         left: 10,
    //         bottom: 30,
    //         right: 10,
    //       };
    //       params.options.seriesDefaults.tooltip.renderer = function(params) {
    //         var titleStyle = params.color
    //           ? ' style="color: white; background-color:' + params.color + '"'
    //           : '';
    //         var title = params.title
    //           ? '<div class="ag-chart-tooltip-title"' +
    //             titleStyle +
    //             '>aa' +
    //             params.title +
    //             '</div>'
    //           : '';
    //         var value = params.datum[params.angleKey]
    //           .toString()
    //           .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    //         return (
    //           title +
    //           '<div class="ag-chart-tooltip-content" style="text-align: center">' +
    //           value +
    //           '</div>'
    //         );
    //       };
    //       return params.options;
    //     },
    //   };
    //   params.api.createRangeChart(params2);


    // //Chart3
    // var eContainer3 = document.querySelector('#chart2');
    // var params3 = {
    //   cellRange: {
    //     columns: ['dept_name', 'status'],
    //   },
    //   chartType: 'pie',
    //   chartContainer: eContainer3,
    //   aggFunc: 'sum',
    //   processChartOptions: function(params) {
    //     params.options.legend.position = 'bottom';
    //     params.options.padding = {
    //       top: 20,
    //       left: 10,
    //       bottom: 30,
    //       right: 10,
    //     };
    //     params.options.seriesDefaults.tooltip.renderer = function(params) {
    //       var titleStyle = params.color
    //         ? ' style="color: white; background-color:' + params.color + '"'
    //         : '';
    //       var title = params.title
    //         ? '<div class="ag-chart-tooltip-title"' +
    //           titleStyle +
    //           '>' +
    //           params.title +
    //           '</div>'
    //         : '';
    //       var value = params.datum[params.angleKey]
    //         .toString()
    //         .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    //       return (
    //         title +
    //         '<div class="ag-chart-tooltip-content" style="text-align: center">' +
    //         value +
    //         '</div>'
    //       );
    //     };
    //     return params.options;
    //   },
    // };
    // params.api.createRangeChart(params3);
      
  };

	handlerStartDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    
    var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    
    this.setState({
      startDate:startdate
    });
    //let value = event.target.value;
      //this.setState({'startDate':value});
  }

  handlerEndDateTime  = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    
      var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
      this.setState({
      endDate:edate
    });
}
  onClickSummaryData(event){
    var reqparms = {
      startDate:this.state.startDate,
      endDate:this.state.endDate
    }
        redirectURL.post("/consignments/dashboardtemplatedata", reqparms)
        .then((response) => {
          var recordsarr = response.data.message;
          var records=[];
          recordsarr.map((item) => {
            var zerodays;
            var onedays;
            var twodays;
            var activezerodays;
            var activeonedays;
            var activetwodays;
            //console.log("item.transit_delay_days ", typeof parseInt(item.transit_delay_days))
            // if(parseInt(item.transit_delay_days) == 0 || 
            // parseInt(item.transit_delay_days) == 1 ||
            // parseInt(item.transit_delay_days) == 2)
            // {
              var set;
              var status;
              var activeset;
                if(parseInt(item.transit_delay_days) == 0 && parseInt(item.set) == 1 && parseInt(item.status) == 2)
                {
                      zerodays=1
                }
      
                if(parseInt(item.transit_delay_days) == 1 && parseInt(item.set) == 1 && parseInt(item.status) == 2)
                {
                      onedays=1
                }
      
                if(parseInt(item.transit_delay_days) == 2 && parseInt(item.set) == 1 && parseInt(item.status) == 2)
                {
                      twodays=1
                }

                if(parseInt(item.set) == 1)
                {
                    set=1;
                }
                else{
                    set=0;
                }
                if(parseInt(item.status) == 2)
                {
                    status=1;
                }
                else{
                    status=0;
                }

                if(parseInt(item.set) == 2)
                {
                    activeset=1;
                }
                else{
                  activeset=0;
                }


                if(parseInt(item.transit_delay_days) == 0 && parseInt(item.set) == 2 && parseInt(item.status) == 2)
                {
                      activezerodays=1
                }
      
                if(parseInt(item.transit_delay_days) == 1 && parseInt(item.set) == 2 && parseInt(item.status) == 2)
                {
                    activeonedays=1
                }
      
                if(parseInt(item.transit_delay_days) == 2 && parseInt(item.set) == 2 && parseInt(item.status) == 2)
                {
                    activetwodays=1
                }


                var itemdata= {
                    "consigner_code": item.consigner_code,
                    "consignment_code": item.consignment_code,
                    "dept_code": item.dept_code,
                    "dept_name": item.dept_name,
                    "invoice_date": item.invoice_date,
                    "transit_delay_days_0": (typeof zerodays == 'undefined')?0:zerodays,
                    "transit_delay_days_1": (typeof onedays == 'undefined')?0:onedays,
                    "transit_delay_days_2": (typeof twodays == 'undefined')?0:twodays,
                    "uom": item.uom,
                    "set": (typeof set == 'undefined')?0:set,
                    "status": (typeof status == 'undefined')?0:status,
                    "activeset": (typeof activeset == 'undefined')?0:activeset,
                    "active_transit_delay_days_0": (typeof activezerodays == 'undefined')?0:activezerodays,
                    "active_transit_delay_days_1": (typeof activeonedays == 'undefined')?0:activeonedays,
                    "active_transit_delay_days_2": (typeof activetwodays == 'undefined')?0:activetwodays,
                };
                records.push(itemdata)
            //}
            
            
          })
          //console.log("Respons ", records)
          //updateData(records);
          this.setState({
            rowData:records
          });
        })
        .catch(function(e){
          console.log("Error ", e)
          
        })
        
  }

  onFirstDataRenderedone = event => {
    var eContainer1 = document.querySelector('#chart');
    var params1 = {
      cellRange: {
        rowStartIndex: 0,
        rowEndIndex: 4,
        columns: ['dept_name', 'transit_delay_days_0', 'transit_delay_days_1', 'transit_delay_days_2'],
      },
      chartType: 'groupedColumn',
      chartContainer: eContainer1,
      processChartOptions: function(params) {
        params.options.seriesDefaults.tooltip.renderer = function(params) {
          var titleStyle = params.color
            ? ' style="color: white; background-color:' + params.color + '"'
            : '';
          var title = params.title
            ? '<div class="ag-chart-tooltip-title"' +
              titleStyle +
              '>' +
              params.title +
              '</div>'
            : '';
          var value = params.datum[params.yKey]
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          return (
            title +
            '<div class="ag-chart-tooltip-content" style="text-align: center">' +
            value +
            '</div>'
          );
        };
        return params.options;
      },
    };
    event.api.createRangeChart(params1);
    var eContainer2 = document.querySelector('#chart2');
    var params2 = {
      cellRange: {
        columns: ['group', 'set'],
      },
      chartType: 'pie',
      chartContainer: eContainer2,
      aggFunc: 'sum',
      processChartOptions: function(params) {
        params.options.legend.position = 'bottom';
        params.options.padding = {
          top: 20,
          left: 10,
          bottom: 30,
          right: 10,
        };
        params.options.seriesDefaults.tooltip.renderer = function(params) {
          var titleStyle = params.color
            ? ' style="color: white; background-color:' + params.color + '"'
            : '';
          var title = params.title
            ? '<div class="ag-chart-tooltip-title"' +
              titleStyle +
              '>' +
              params.title +
              '</div>'
            : '';
          var value = params.datum[params.angleKey]
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          return (
            title +
            '<div class="ag-chart-tooltip-content" style="text-align: center">' +
            value +
            '</div>'
          );
        };
        return params.options;
      },
    };
    event.api.createRangeChart(params2);
    var eContainer3 = document.querySelector('#chart3');
    var params3 = {
      cellRange: {
        columns: ['group', 'status'],
      },
      chartType: 'pie',
      chartContainer: eContainer3,
      aggFunc: 'sum',
      processChartOptions: function(params) {
        params.options.legend.position = 'bottom';
        params.options.padding = {
          top: 20,
          left: 10,
          bottom: 30,
          right: 10,
        };
        params.options.seriesDefaults.tooltip.renderer = function(params) {
          var titleStyle = params.color
            ? ' style="color: white; background-color:' + params.color + '"'
            : '';
          var title = params.title
            ? '<div class="ag-chart-tooltip-title"' +
              titleStyle +
              '>' +
              params.title +
              '</div>'
            : '';
          var value = params.datum[params.angleKey]
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          return (
            title +
            '<div class="ag-chart-tooltip-content" style="text-align: center">' +
            value +
            '</div>'
          );
        };
        return params.options;
      },
    };
    event.api.createRangeChart(params3);
  };

  getChartToolbarItems = params => {
    return [];
  };
  
  render() {
    var startdate = this.state.startDate;
    var enddate = this.state.endDate;
    let { startDate } = this.state;
    var consignmentgenerate = "Consignment Generated ( "+startDate+")";
    var columnDefs = [
      {
          headerClass:["cellTopHeaderTemplate"],
          headerName:"Department",	
          field: 'dept_name',
          rowGroup: true,
          width:220,
          cellClass:"cellTopHeaderTemplate16",
          //pivot:true,
          hide:true,
          chartDataType: 'category',
        },
        {
          field: 'consigner_code',
          rowGroup: true,
          cellClass:"cellTopHeaderTemplate14",
          hide:true,
        },
        {
          field: 'invoice_date',
          rowGroup: true,
          cellClass:"cellTopHeaderTemplate14",
          hide:true,
        },
        {
          headerName: 'UOM',
          field: 'uom',
          width:130,
          cellClass:["cellTopHeaderTemplatenb"],
          valueGetter:function(params){
           // console.log(params.node.key);
            // if(typeof params.data == 'undefined')
            // {
            //   return "Trucks";
            // }
            // else{
              if(params.node.key == "Sales & Dispatch")
              {
                return "Trucks";
              }
              else if(params.node.key == "Spare Parts (Domestic)")
              {
                return "Consignments";
              }
              else if(params.node.key == "Spare Parts (Courier)")
              {
                return "Consignments";
              }
              else if(params.node.key == "Spare Parts (Container Exports)" || params.node.key == "Spares (Container Exports)")
              {
                return "Containers";
              }
              else if(params.node.key == "Production Parts")
              {
                return "Trucks";
              }
              else if(params.node.key == "Container Imports")
              {
                return "Containers";
              }
              else if(params.node.key == "Container Exports (KDO)")
              {
                return "Containers";
              }
              else if(params.node.key == "Coil Imports")
              {
                return "Coils";
              }
            // }
            
          }
          //hide:true
        },
        
        {
          headerName: "For This Period",
          headerClass:["cellTopHeaderTemplate","cellstylegrid"],			
          children: [
                      {
                        headerName: "Total for this period",
                        headerClass:["cellstylegrid"],	
                        field: "set",
                        cellClass:["cellTopHeaderTemplate16","cellstylegrid"],
                        chartDataType: 'series',
                        width:110,
                        //hide:true
                      },
                      {
                        headerName: "Active for this period",
                        headerClass:["cellstylegrid"],	
                        field: "status",
                        width:110,
                        cellClass:["cellTopHeaderTemplate16","cellstylegrid"],
                        chartDataType: 'series',
                        //hide:true
                      },
                    ]
            },
            {
              headerName: "Delay Meter",
              headerClass:["cellTopHeaderTemplate","cellstylegrid"],			
              children: [
                      {
                        headerClass:["cellTopHeaderTemplate14","cellstylegrid"],
                        headerName:"On Time",	
                        field: 'transit_delay_days_0',
                        width:90,
                        cellClass:["cellTopHeaderTemplate16","bgColorSuccessLight","cellstylegrid"],
                        chartDataType: 'series',
                      },
                      {
                        headerClass:["cellTopHeaderTemplate14","cellstylegrid"],
                        headerName:"1 Day",	
                        field: 'transit_delay_days_1',
                        width:90,
                        cellClass:["cellTopHeaderTemplate16","bgColorDangerLight","cellstylegrid"],
                        chartDataType: 'series',
                      },
                      {
                        headerClass:["cellTopHeaderTemplate14","cellstylegrid"],
                        headerName:">1 Day",
                        field: 'transit_delay_days_2',
                        width:90,
                        cellClass:["cellTopHeaderTemplate16","bgColorDangerMedium","cellstylegrid"],
                        chartDataType: 'series',
                      }
            ]
        },
        

        {
          headerName: "As On Date",
          headerClass:["cellTopHeaderTemplate","cellstylegridG"],			
          children: [

                      {
                        headerClass:["cellstylegridG"],
                        headerName: "Active as on date",
                        field: "activeset",
                        width:100,
                        cellClass:["cellTopHeaderTemplate16","cellstylegridG"],
                        chartDataType: 'category',
                        //hide:true
                      },
                      {
                        headerClass:["cellTopHeaderTemplate14","cellstylegridG"],
                        headerName:"On Time",	
                        field: 'active_transit_delay_days_0',
                        width:90,
                        cellClass:["cellTopHeaderTemplate16","bgColorSuccessLight","cellstylegridG"],
                        chartDataType: 'series',
                      },
                      {
                        headerClass:["cellTopHeaderTemplate14","cellstylegridG"],
                        headerName:"1 Day",	
                        field: 'active_transit_delay_days_1',
                        width:90,
                        cellClass:["cellTopHeaderTemplate16","bgColorDangerLight","cellstylegridG"],
                        chartDataType: 'series',
                      },
                      {
                        headerClass:["cellTopHeaderTemplate14","cellstylegridG"],
                        headerName:">1 Day",
                        field: 'active_transit_delay_days_2',
                        width:90,
                        cellClass:["cellTopHeaderTemplate16","bgColorDangerMedium","cellstylegridG"],
                        chartDataType: 'series',
                      }
            ]
        }

        // {
        //   headerClass:["cellTopHeaderTemplate"],
        //   headerName:"Chart",
        //   width:200,
        //   field: 'dept_code',
        //   //aggFunc: 'sum',
        //   //cellClass:["cellTopHeaderTemplate16","bgColorDangerDark"],
        //   cellRendererSelector:function(params){
        //     //console.log("params ", params)
        //     return { component: 'cellGraphComponent'};
            
        //   },
        // },
    ];
    return (
      <div style={{ width: '100%', height: '100%' }}>
       <div className="row col-xl-12 col-lg-12">
          
						<div className="col-xl-2 col-lg-2 form-group">
							<label>Select Date Period:<br />From Date</label>
							<Datetime value={this.state.startDate} disableCloseOnClickOutside={false} closeOnSelect={true} inputProps={{ placeholder: 'From', name: 'startDate', autoComplete:'off' }} dateFormat="YYYY-MM-DD"  name="startDate" onChange={this.handlerStartDateTime.bind(this)} />
						</div>

						<div className="col-xl-2 col-lg-2 form-group">
							<label><br />To Date</label>
							<Datetime value={this.state.endDate} disableCloseOnClickOutside={false} closeOnSelect={true} inputProps={{ placeholder: 'To', name: 'endDate', autoComplete:'off'}} dateFormat="YYYY-MM-DD" onChange={this.handlerEndDateTime.bind(this)} />
						</div>
						<div className="form-group col-xl-2 col-lg-2">
							<label><br />&nbsp;</label><br />
							<button type="button" className="btn btn-success" onClick={this.onClickSummaryData.bind(this)}>Submit</button>
						</div>
						
				</div>

        <div
          id="myGrid"
          style={{
            height: '350px',
            width: '100%',
          }}
          className="ag-theme-balham"
        >
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            defaultColDef={this.state.defaultColDef}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            sideBar={false}
            groupUseEntireRow={false}
            enableRangeSelection={true}
            groupRowAggNodes={this.groupRowAggNodes}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            groupHeaderHeight={this.state.groupHeaderHeight}
            headerHeight={this.state.headerHeight}
            floatingFiltersHeight={this.state.floatingFiltersHeight}
            pivotGroupHeaderHeight={this.state.pivotGroupHeaderHeight}
            pivotHeaderHeight={this.state.pivotHeaderHeight}
            rowHeight={this.state.rowHeight}
            frameworkComponents={this.state.frameworkComponents}
            popupParent={this.state.popupParent}
            enableRangeSelection={true}
            enableCharts={true}
            processChartOptions={this.state.processChartOptions}
            onFirstDataRendered={this.onFirstDataRendered.bind(this)}
            getChartToolbarItems={this.getChartToolbarItems}
            suppressChartRanges={true}
          />
          <div id="chart" style={{ float: 'left', overflow: 'hidden', width:"50%",height: '300px' }}></div>
          <div id="chartactive" style={{ float: 'left', overflow: 'hidden',width:"50%", height: '300px' }}></div>
           {/* <div id="chart2" style={{ flex: '1 1 auto', overflow: 'hidden', width: '50%' }}></div>
          <div id="chart3" style={{ flex: '1 1 auto', overflow: 'hidden', width: '50%' }}></div> */}

        </div>
      </div>
    );
  }
}


// function BarChartLineRenderer() {
// }

// BarChartLineRenderer.prototype.init = function(params) {
//     var eGui = document.createElement('div');
//     this.eGui = eGui;

//     // sparklines requires the eGui to be in the dom - so we put into a timeout to allow
//     // the grid to complete it's job of placing the cell into the browser.
//     setTimeout(function() {
//       console.log(params.value);
//         var values = params.value
//             //.sort(function(a, b) { return a.transit_delay_days_0 - b.transit_delay_days_2; })
//             .map(function(datum) { return datum.AverageVolume.toFixed(); });
//         $(eGui).sparkline(values, {
//             type: 'bar',
//             barColor: 'green',
//             chartRangeMin: 1000000,
//             barWidth: 11,
//             height: CELL_DIMENSION_SIZE,
//             width: CELL_DIMENSION_SIZE
//         });
//     }, 0);
// };

// BarChartLineRenderer.prototype.getGui = function() {
//     return this.eGui;
// };
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules, AllEnterpriseModules} from '@ag-grid-enterprise/all-modules';
// import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import CSVFileValidator from 'csv-file-validator';
import Map from '../common/drawmap';
import FileBase64 from 'react-file-base64';
import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import MapComponent from './mapLocationComponent';
import Consignmentforceclose from '../manage/consignmentforceclose';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var childJson = []

export default class AddLspDetails extends Component{
        constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            csvcontent:"",
            file : "",
            loadshow:'show-n',
            overly:'show-n',
            uploadYard : "",
            uploadDispatchDate : "",
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            dept_code:"",
            bulkslide:"",
            date:"",
            deptName:"",
            indenterName:"",
            mobile:"",
            indenterEmail:"",
            erpPO:"",
            erpPoNo:[],
            erpPoDate:[],
            ringiNo:"",
            ringiDesc:"",
            cargoDeliveryPlant:"",
            itemDesc:"",
            grossWt:"",
            dimensions:"",
            cargo_chargeable_weight:"",
            supplierName:"",
            supplierAddr:"",
            supplierCnctPerson:"",
            country:"",
            emailID:"",
            cha:{"value": "Select CHA", "label": "Select CHA"},
            Chalist:[],
            freight_forwader:{"value":"Select Freight Forwader", "label":"Select Freight Forwader"},
            freight_list:[],
            email:[],
            transporter:{"value":"Select Transporter", "label":"Select Transporter"},
            transporter_list:[],
            phone:"",
            deliveryTerms:"Ex Works",
            pickUpAddr:"",
            cargoDate:"",
            LC : "",
            LCno : "",
            nature : "",
            freeTrade : "",
            benefitCert : "",
            disableERP:"disabled",
            disableLC:"disabled",
            deliveryLabel : "Pick Up Address",
            airportName:"",
            port : "",
            erpPo_date :"",
            cargo_date:"",
            container_type:"",
            container_size:[],
            dispatch_location:"",
            dispatch_port:"",
            destination_port:"",
            container_required:"",
            incoterms:"",
            payment_terms:"",
            rows: [{}],
            ringirows: [{}],
            packetrows: [{}],
            brequest:"activet",
            prepaid:"",
            track:"",
            incoterms:"",
            country:"",
            destination_port:"",
            dispatch_location:"",
            requestData:"show-m",
            trackData:"show-n",
            trackResData : 'show-n',
            ffsuppliers: [],
            requestFields:"show-m",
            prepaidFields:"show-n",
            addresstxt:"",
            request_type:1,
            pagetitle:"OneTime Container Booking",
            disableBng : "disabled"
            //components : {datepicker:getDatePicker()}
        }
        this.changeHandler = this.changeHandler.bind(this);
        this.changeHandlercondition = this.changeHandlercondition.bind(this);
        this.packetsHandler = this.packetsHandler.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
    	this.logPageView(); // Log page view to GA
        loadDateTimeScript();
        var pagetitle = "Add Lsp Details";
        $(".requestFields").attr("required",true);
        this.setState({
            pagetitle : pagetitle,
        }) 
        $(".prepaidFields").removeAttr("required",true);
        $("#no_of_packets").val(1);
        var d = moment.parseZone(new Date()).utcOffset("+05:30")._d;
        var date = moment.parseZone(d).format("DD-MM-YYYY");
        $("#dateField").val(date);
        $("#addMrERp").hide();
        redirectURL.post("/consignments/getCHAData")
        .then((reponse)=>{
            console.log(reponse.data.data,"177")
            var records = reponse.data.data;
            var cha_data = []
            if(records.length >0){
                records.map((item)=>{
                    cha_data.push({
                        value : item.supplier_code,
                        label : item.supplier_name
                    })
                   
                })
            }
            this.setState({
                Chalist:cha_data,
                freight_list:cha_data,
                transporter_list:cha_data
            })
        })
    }

    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	
    onChangePlantItem(cha) {
        this.setState(
            { cha },
            // () => console.log(`Plant Option selected:`, this.state.plant_code)
        );
    
        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }
    onChangeConsigneeItem(freight_forwader) {
        this.setState(
            { freight_forwader },
            // () => console.log(`Code  Option selected:`, this.state.consignee_code)
        );
    
        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }
    onChangeTransporterItem(transporter) {
        this.setState(
            { transporter },
            // () => console.log(`Code  Option selected:`, this.state.consignee_code)
        );
    
        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }
	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();
		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
    }
    
onClickHideAll(){
    this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:''
    });
    
}
closeAlert = () => {
    this.setState({
        show: false
    });
}
containertypechangeHandler =(e)=>{
    this.setState({
        container_type:e,
    })
}
containersizechangeHandler = (e)=>{
    if(e == null)
    {
        e = [];
    }
    this.setState({
        container_size:e,
    })
}
dispatchlocationchangeHandler =(e)=>{
    this.setState({
        dispatch_location:e,
    })
}
dispatchportchangeHandler = (e)=>{
    this.setState({
        dispatch_port:e,
    })
}
destinationportchangeHandler= (e)=>{
    this.setState({
        destination_port:e,
    })
}
containerrequiredchangeHandler= (e)=>{
    this.setState({
        container_required:e,
    })
}
incotermschangeHandler = (e)=>{
    this.setState({
       incoterms:e,
    })
}
countrychangeHandler = (e)=>{
    this.setState({
       country:e,
    })
}
paymentchangeHandler = (e)=>{
    this.setState({
        payment_terms:e,
     })
}
changeHandlercondition = (event) => {
    const inputChar = String.fromCharCode(event.charCode);
    // console.log(event.target.value,'valueeee',inputChar)
    if (!/^[A-Z\s]*$/.test(inputChar)) {
        event.preventDefault(); // Prevent the character from being entered
    }
};

changeHandler = (event) => {
    //console.log("Select", event.target.value);
    let name = event.target.name;
    let value = event.target.value;
    if(name == "driver_mobile_num" || name == "transit_days" )
    {
        if(value !="" && value !=undefined)
        {
            if(value > 0)
            {
                this.setState({[name]:value.replace(/\D/g,'')});
            }
        }
        else
        {
            this.setState({[name]:value.replace(/\D/g,'')});
        }
    }
    else
    {
        this.setState({[name]:value});
    }
    // this.setState({ [name]: value });
}
dateValidation = (currentDate) => {
    return currentDate.isBefore(moment(this.state.deliverymaxDate)) && currentDate.isAfter(moment(this.state.deliveryminDate));
};
changeFileHandler = async (e) => {
    console.log(e.target.name)
    this.setState({ [e.target.name]: e.target.files[0] });
}
formHandler(event){
    event.preventDefault();
    var eventTarget = event.target;
    this.setState({
        loadshow:"show-m",
        overly:"show-m"
})
function createShortName(name) {
    const nameArray = name.split(' '); // Split the name into an array of words
    const shortNameArray = nameArray.map(word => word.charAt(0)); // Extract the first letter of each word
    const shortName = shortNameArray.join(''); // Join the first letters back together
    return shortName;
}

var name =  $("#name").val();
var short_name = createShortName(name);
console.log($("#name").val(),'$("#name").val()',name)
    // var dispatch_location = this.state.dispatch_location.value
    // var destination_port = this.state.destination_port.value
    // var incoterms = this.state.incoterms.value
    var cha = this.state.cha.value
    // var cha_name = this.state.cha.label
    // var country  =  this.state.country.value
    var freight_forwader = this.state.freight_forwader.value
    // var freight_forwader_name = this.state.freight_forwader.label
    var transporter = this.state.transporter.value
    // var transporter_name = this.state.transporter.label
    var email = this.state.email
    var email_id =[]
        if(email.includes(",")==true){
            email_id = email.split(",")
        }
        else{
            email_id = [email]
        }
    // var ff_email_id = this.state.ff_email_id
    //     var ff_emails =[]
    //     if(ff_email_id.includes(",")==true){
    //         ff_emails = ff_email_id.split(",")
    //     }else{
    //         ff_emails = [ff_email_id]
    //     }
    // var transporter_email_id = this.state.transporter_email_id
    //     var transporter_emails = []
    //     if(transporter_email_id.includes(",")==true){
    //         transporter_emails = transporter_email_id.split(",")
    //     }else{
    //         transporter_emails = [transporter_email_id]
    //     }
    var formData ={
    // dispatch_location : dispatch_location,
    // destination_port : destination_port,
    // incoterms : incoterms,
    short_name:short_name,
    name:name,
    cha : cha,
    // cha_name : cha_name,
    freight_forwader : freight_forwader,
    // freight_forwader_name : freight_forwader_name,
    transporter : transporter,
    // transporter_name : transporter_name,
    email : email_id,
    // ff_email_id : ff_emails,
    // transporter_email_id : transporter_emails,
    // country : country
    }
    console.log(formData,"formData")
    if(name != ""  && name != undefined  && name != null && cha != ""  && cha != undefined  && cha != null && cha != "Select CHA" &&  freight_forwader != "" &&  freight_forwader != undefined &&  freight_forwader != null  && freight_forwader != "Select Freight Forwader"  && transporter != "" && transporter != undefined && transporter != null && transporter != "Select Transporter"&& email != "" && email != undefined ){
        
    redirectURL.post("/consignments/insertLspDetails",formData).then((response)=>{
        if(response.data.message == "Success"){
            this.setState({
                show: true,
                basicType: "success",
                basicTitle: "Data Successfully Updated",
                loadshow:"show-n",
                overly:"show-n"
            })
            window.location.href = "/lspdetails"
        }else{
            this.setState({
                show: true,
                basicType: "warning",
                basicTitle: "Data Not Updated",
                loadshow:"show-n",
                overly:"show-n"
            })
        }
    })

    }else{
        this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "All fields are mandatory",
            loadshow:"show-n",
            overly:"show-n"
        })
    }
   
}

handleAddRow = () => {
    const item = {
        erpPoNo: "",
        erpPoDate: "",
    };
    this.setState({
        rows: [...this.state.rows, item]
    });
};
handleRemoveRow = (idx) => {
    this.state.rows.splice(idx, 1);
    this.setState({ rows: this.state.rows });
};

handleAddRingiRow = () => {
    const item = {
        ringi_file: "",
        ringiDesc: "",
    };
    this.setState({
        ringirows: [...this.state.ringirows, item]
    });
};
handleRemoveRingiRow = (idx) => {
    this.state.ringirows.splice(idx, 1);
    this.setState({ ringirows: this.state.ringirows });
};

packetsHandler = (e)=> {
    var noOfPackets = e.target.value;
    if(parseInt(noOfPackets) >= 1)
    {
        var lespcks = parseInt(noOfPackets)-1;
        var bhanu=[{}];
        for(var n=0;n<lespcks;n++)
        {
            const item = {
                itemDesc: "",
                grossWt: "",
                dimensions: "",
                cargo_chargeable_weight:"",
            };
            this.setState({
                packetrows: [...bhanu, item]
            });
            var bhanu=[...bhanu, item];
        }
    }
    else
    {
        alert("No of packets should be greater than 0");
        $("#no_of_packets").val(1);
        const item = {
            itemDesc: "",
            grossWt: "",
            dimensions: "",
            cargo_chargeable_weight:"",
        };
        this.setState({
            packetrows: [item]
        });
    }
}

onClickTab(pageType){
    if(pageType == "brequest")
    {
        var brequest = "activet";
        var ffupdates = "";
        var track = "";
        var requestData = "show-m";
        var trackData = "show-n";
        var requestFields = "show-m";
        var prepaidFields = "show-n";
        var addresstxt = "";
        var request_type = 1;
        var pagetitle = "Booking Request (Air Imports)";
        $(".requestFields").attr("required",true);
        $(".prepaidFields").removeAttr("required",true);
    }
    else if(pageType == "prepaid")
    {
        var brequest = "";
        var prepaid = "activet";
        var track = "";
        var requestData = "show-m";
        var trackData = "show-n";
        var requestFields = "show-n";
        var prepaidFields = "show-m";
        var addresstxt = "(To be mentioned on BOE)";
        var request_type = 2;
        var pagetitle = "Booking Request For Prepaid Shipments (Air Imports)";
        $(".requestFields").removeAttr("required",true);
        $(".prepaidFields").attr("required",true);
    }
    else if(pageType == "track")
    {
        var brequest = "";
        var prepaid = "";
        var track = "activet";
        var requestData = "show-n";
        var trackData = "show-m";
        var requestFields = "show-n";
        var prepaidFields = "show-n";
        var addresstxt = "";
        var request_type = "";
        var pagetitle ="";
    }
    this.setState({
        brequest:brequest,
        prepaid:prepaid,
        track:track,
        requestData:requestData,
        trackData:trackData,
        requestFields:requestFields,
        prepaidFields:prepaidFields,
        addresstxt:addresstxt,
        request_type:request_type,
        pagetitle:pagetitle
    })
}

trackHandler(event){
    event.preventDefault();
    var indenter_email = $("#indenter_email").val();
    if(indenter_email !=undefined && indenter_email !='')
    {
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        })
        var params = {
            indenter_email : indenter_email
        }
        redirectURL.post("/consignments/getAirCargosStatusByIntender",params)    
        .then((response) => {
            var records = response.data;
            this.setState({
                rowData : records,
                trackResData : 'show-m',
                loadshow:'show-n',
                overly:'show-n',
            })
        })
        .catch(function(e){
            console.log("Error ",e)
        })
    }
    else
    {
        this.setState({
            basicTitle:"Please enter Indenter Email",
            basicType : "warning",
            show : true
        })
    }
}

render(){
    var columnwithDefs = [
        {
            headerName: "Job No",
            field: "job_no",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Date",
            field: "date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.date !=undefined && params.data.date !='')
                {
                    return moment.parseZone(params.data.date).format("DD-MM-YYYY")
                }
            }
        },
        {
            headerName: "Department Name",
            field: "dept_name",
            width:150,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Indenter Name",
            field: "indenter_name",
            width:150,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Mobile No",
            field: "mobile",
            width:100,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Indenter Email",
            field: "indenter_email",
            width:200,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Cargo Delivery Plant",
            field: "cargo_delivery_plant",
            width:200,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Supplier Name",
            field: "supplier_name",
            width:200,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "ETA IGI Airport Date",
            field: "eta_igi_airport_date",
            width:150,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.eta_igi_airport_date !=undefined && params.data.eta_igi_airport_date !="")
                {
                    return moment.parseZone(params.data.eta_igi_airport_date).format("DD-MM-YYYY") 
                }
                else
                {
                    return "N/A";
                }
            }
        },
        {
            headerName: "ATA IGI Airport Date",
            field: "ata_igi_airport_date",
            width:150,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.ata_igi_airport_date !=undefined && params.data.ata_igi_airport_date !="")
                {
                    return moment.parseZone(params.data.ata_igi_airport_date).format("DD-MM-YYYY") 
                }
                else
                {
                    return "N/A";
                }
            }
        },
        {
            headerName: "OOC Date",
            field: "ooc_date",
            width:150,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.ooc_date !=undefined && params.data.ooc_date !="")
                {
                    return moment.parseZone(params.data.ooc_date).format("DD-MM-YYYY") 
                }
                else
                {
                    return "N/A";
                }
            }
        },
        {
            headerName: "Status",
            field: "status_text",
            width:250,
            pinned:"right",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.rejected == 1)
                {
                    return "Rejected";
                }
                else if(params.data.actual_delivery_at_msil !=undefined && params.data.actual_delivery_at_msil !="")
                {
                    return "Delivered To MSIL";
                }
                else if(params.data.ooc_date !=undefined && params.data.ooc_date !="")
                {
                    return "Custom Cleared";
                }
                else if(params.data.ata_igi_airport_date !=undefined && params.data.ata_igi_airport_date !="")
                {
                    return "Flight Arrived At Destination Airport";
                }
                else if(params.data.eta_igi_airport_date !=undefined && params.data.eta_igi_airport_date !="")
                {
                    return "Flight Booked";
                }
                else if(params.data.ff_code == undefined || params.data.ff_code == '')
                {
                    return "Pending At Imports";
                }
                else
                {
                    return "Pending From Freight Forwarder";
                }
            }          
        }
    ]
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    
            		<div className={"col-xl-12 col-lg-12 "+this.state.requestData}>
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle}</span>
                                    <span>
                                        <span className="float-right f12">
                                            <a href="/lspdetails" className="btn btn-primary">Back</a>
                                        </span>
                                    </span>
								</h5>
				   			</div>
                            <div className="card-body pt-15px">
                                <form class="aircargo-form" onSubmit={this.formHandler.bind(this)} encType="multipart/form-data">
                                  
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                        <label className="col-xl-12 col-lg-12">Name* :</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            onKeyPress={this.changeHandlercondition}
                                            // value={this.state.email}
                                            name="name"
                                            id='name'
                                            autoComplete='off'
                                            required/>
                                        </div>
                                        {/* <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Destination Port* : </label>
                                            <Select 
                                            placeholder={"Select Destination Port"} 
                                            closeMenuOnSelect={true} 
                                            value={this.state.destination_port} 
                                            
                                            onChange={this.destinationportchangeHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={[{ label: "Jakarta", value: "Jakarta" },
                                            { label: "Laem Chabang", value: "Laem Chabang" },{ label: "Thilawa", value: "Thilawa" },{ label: "Yangon", value: "Yangon" },{ label: "Nagoya", value: "Nagoya"},{ label: "Shimizu", value: "Shimizu"},{ label: "Omaezaki", value: "Omaezaki"},{ label: "Apapa", value: "Apapa"},{ label: "Tema", value: "Tema"}]} required
                                        />
                                        </div> */}
                                        {/* <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12"> Incoterms to be Used* : </label>
                                            <Select 
                                            placeholder={"Select Incoterms"} 
                                            closeMenuOnSelect={true} 
                                            value={this.state.incoterms} 
                                            
                                            onChange={this.incotermschangeHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={[{ label: "FOB", value: "FOB" },
                                            { label: "CIF", value: "CIF" },
                                            { label: "CFR", value: "CFR" },
                                            { label: "DDP", value: "DDP" },
                                            { label: "DDU", value: "DDU" }]} required
                                        />
                                        </div> */}
                                        {/* <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12"> Country* : </label>
                                            <Select 
                                            placeholder={"Select Country"} 
                                            closeMenuOnSelect={true} 
                                            value={this.state.country} 
                                            
                                            onChange={this.countrychangeHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={[{ label: "Japan PXP", value: "Japan PXP" },
                                            { label: "Thailand", value: "Thailand" },
                                            { label: "Myanmar", value: "Myanmar" },
                                            { label: "Nigeria", value: "Nigeria" },
                                            { label: "Shimizu", value: "Shimizu" },
                                            { label: "Ghana", value: "Ghana" },
                                            { label: "Vietnam", value: "Vietnam" },
                                            { label: "China", value: "China" },
                                            { label: "Indonesia", value: "Indonesia" }]} required
                                        />
                                        </div> */}
                                    </div>
                                    <div class="hr-dashed"></div>                                    
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                        <label className="col-xl-12 col-lg-12">CHA* :</label>
                                        <Select
                                            placeholder={"Select CHA"}
                                            closeMenuOnSelect={true}
                                            onChange={this.onChangePlantItem.bind(this)}
                                            // className={"col-xl-12 col-lg-12 border-radius-0"}
                                            style={{ borderRadius: "0px" }}
                                            name="plant_code"
                                            value={this.state.cha}
                                            options={[{ label: "Yes", value: "Yes" },
                                            { label: "No", value: "No" },
                                           ]} />
                                        </div>
                                        <div className="col-xl-4">
                                        <label className="col-xl-12 col-lg-12">Freight Forwader* :</label>
                                            <Select
                                                placeholder={"Select Freight Forwader"}
                                                closeMenuOnSelect={true}
                                                onChange={this.onChangeConsigneeItem.bind(this)}
                                                // className={"col-xl-12 col-lg-12 border-radius-0"}
                                                style={{ borderRadius: "0px" }}
                                                name="consignee_code"
                                                value={this.state.freight_forwader}
                                                options={[{ label: "Yes", value: "Yes" },
                                                { label: "No", value: "No" },
                                               ]}/>
                                        </div>
                                        <div className="col-xl-4">
                                        <label className="col-xl-12 col-lg-12">Transporter* :</label>
                                            <Select
                                                placeholder={"Select Transporter"}
                                                closeMenuOnSelect={true}
                                                onChange={this.onChangeTransporterItem.bind(this)}
                                                // className={"col-xl-12 col-lg-12 border-radius-0"}
                                                style={{ borderRadius: "0px" }}
                                                name="consignee_code"
                                                value={this.state.transporter}
                                                options={[{ label: "Yes", value: "Yes" },
                                        { label: "No", value: "No" },
                                       ]} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>                                    
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                        <label className="col-xl-12 col-lg-12">Email Id* :</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.email}
                                            name="email"
                                            autoComplete='off'
                                            required/>
                                        </div>
                                        {/* <div className="col-xl-4">
                                        <label className="col-xl-12 col-lg-12">Enter Freight Forwader Email Id* :</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.ff_email_id}
                                            name="ff_email_id"
                                            autoComplete='off'
                                            required/>
                                        </div>
                                        
                                        <div className="col-xl-4" >
                                        <label className="col-xl-12 col-lg-12">Enter Transporter Email Id* :</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.transporter_email_id}
                                            name="transporter_email_id"
                                            autoComplete='off'
                                            required/>
                                        </div> */}
                                        
                                    </div>
                                    <h5 style={{fontWeight:"bold",color:"red",fontSize:"14px",marginTop:"2%"}}>Note: Emails should be provided in comma ' , ' delimited only</h5>
                                    <div className="row col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-success" style={{marginLeft:"45%",width:"192px",marginTop:"20px"}}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className={"col-xl-12 col-lg-12 "+this.state.trackData}>
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
								    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Booking Status</span>
								</h5>
				   			</div>
                            <div className="card-body">
                                <form className="row" onSubmit={this.trackHandler.bind(this)} encType="multipart/form-data">
                                    <div className="col-xl-4">
                                        <input type="email" name="indenter_email" id="indenter_email" className="form-control" placeholder="Indenter Email" required/>
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <button type="submit" className="btn btn-info">Track Status</button>
                                    </div>
                                </form>
                            </div>
                            <div className={"col-xl-12 col-lg-12 "+this.state.trackResData}>
                                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham"}>    
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={false}
                                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        stopEditingWhenGridLosesFocus= {true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        // components={this.state.components}
                                        enableRangeSelection= {true}
                                        // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                        />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>           
            </div>
        )
    }
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
        format:'d-m-Y',
        minDate:true,
		timepicker:false,
    });
    
    $('.cargo_date').datetimepicker({
		//mask:'39-19-9999',
        format:'d-m-Y',
        minDate:true,
		timepicker:false,
	});

    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    script.crossOrigin = "anonymous"
	index.parentNode.insertBefore(script,index)
}

// $('body').on('focus',".datetimepicker_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false
// 	});
// });

// $('body').on('focus',".cargo_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false,

// 	});
// });

$(document).on("input", ".num-cls", function() {
    this.value = this.value.replace(/\D/g,'');
});

$(document).on("change", "#cargoDate", function(e) {
    e.preventDefault();
    var bookingDate=$("#dateField").val();
    var cargoDate=$("#cargoDate").val();
    var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    console.log(startTime,endTime);
    if(startTime >= endTime)
    {
        $("#cargoDate").val("");
        alert("Date should be greater than Booking Date");
    }
})
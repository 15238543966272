/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import Select from 'react-select';
import UpdateItem from './updaterow';
import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import * as Datetime from 'react-datetime';
import CountUp from 'react-countup';
import 'react-datetime/css/react-datetime.css'
import $ from 'jquery';
import axios from 'axios';
import Modal from 'react-responsive-modal';
import 'jquery-ui/ui/widgets/datepicker';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');


export default class DelayedRakesInfo extends Component {

	constructor(props){
		super(props);
		this.state = {
			currentDepartmentTitle: null,
			modules: AllModules,
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
      	    defaultColDef: {
      	        sortable: true,
      	        filter: true,
				editable: true,
				resizable: true
      		},
			rowData: [],

			editType: "fullRow",
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
		 
			frameworkComponents: {
				updateItem:UpdateItem,
                deleteItem:DeleteItem,
				//statusChange:StatusChange
			},

			rowModelType: "serverSide",
			paginationPageSize:100,
			cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters:[],
            sliderRso:"",
            sliderUpdate:"",
            transporter:"",
            truck_no:"",
            trucktype:"",
            deptcode:"",
            sources:[],
            map_invoice_date: {},
            map_invoice_count: {},
            destinations:[],
            sourceopt:"",
            oem_code:"",
            oem_name:"",
            industry_type:"",
            officer_mobile:"",
            officer_alteratemobile:"",
            officer_locations:[],
            officertype:"",
            rowid:"",
            sliderBulk:"",
            file:"",            
			csvcontent:[],
            viewData:[],
            billing_month:"",
            reck_info:[],
            allrowData:[],
            nottrigerred:[],
            trigerred:[],
            dispatchedrakes:[],
            detailCellRendererParams:{},
            remarks_history_cols:[
                {
	  	          headerName: "Vin No",
	  	          field: "vin_list",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                },
                {
	  	          headerName: "Invoice Date",
	  	          field: "invoice_time",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                  valueGetter:function(params){
                    if(params.data.invoice_time != '' && params.data.invoice_time != undefined)
                    {
                        return getHyphenYYYYMMDDHHMMSS(params.data.invoice_time)
                    }
                    else
                    {
                        return "";
                    }
                }
                },
                {
	  	          headerName: "Consigner Code",
	  	          field: "consigner_code",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                  valueGetter:function(params){
                    if(params.data.consigner_code == "F" || params.data.consigner_code == "T"){
                        return "Gurgaon"
                        }
                        else if(params.data.consigner_code == "M" || params.data.consigner_code == "C"){
                            return "Manesar"
                        }
                        else if(params.data.consigner_code == "G"){
                            return "Gujarat"
                        }
                        else if(params.data.consigner_code == "B"){
                            return "Banglore"
                        }
                        else{
                            return " "
                        }
                }
                },
                {
	  	          headerName: "Consignee City",
	  	          field: "consignee_city",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                },
                {
	  	          headerName: "For Code",
	  	          field: "for_code",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                },
                {
                    headerName: "Region",
                    field: "region",
                    width: 150,
                    editable:false,
                    filter: "agSetColumnFilter",
                },
                {
	  	          headerName: "Loading Yard",
	  	          field: "rake_loading_point_name",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                },
                {
	  	          headerName: "Destination Yard",
	  	          field: "rake_delivery_point_name",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                },
            ],
            remarks_data:[],
            sliderConfigData:"",
            config_region_data:[],
            components: { datePicker: getDatePicker() },
		};
        
        this.updateRowHandler = this.updateRowHandler.bind(this);
        this.deleteRowHandler = this.deleteRowHandler.bind(this);
        this.onLoadData = this.onLoadData.bind(this);
	}
	getCurrentDepartmentCode(pathToMatch = null) {
		let department = null;
		let departmentName = null;
		switch(pathToMatch) {
			case "/tptoemmaster":
				department='SNDG';
				departmentName = " ";
				break;
			
			default:
				console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			currentDepartmentTitle:departmentName
		});
		return department;
	}
	componentDidMount(){
        loadDateTimeScript()
		if (googleAnalytics.page.enableGA) {
			this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

        }
        
        this.setState({
            loadshow:"show-n",
            overly:"show-n"
        })

       
		// get department code to filter records by department
		let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
		this.setState({
			departmentCode:currentDepartmentCode
		});
		 /*Consignments List*/		
	   this.onLoadData();
	  
    };
    onLoadData(){
        this.setState({
            overly: "show-m",
            loadshow: "show-m"
        })
        var edate = moment.parseZone().format('YYYY-MM-DD HH:mm');
		var sdate = moment.parseZone().subtract(10, 'days').format('YYYY-MM-DD HH:mm')
		$("#invoiceStartDate").val(sdate);
		$("#invoiceEndDate").val(edate);
        var params = {
            fromDate:sdate,
            toDate:edate
        }
        redirectURL.post('/dashboard/getdelayedrakeinfodata',params).then((response) => {
          var records = response.data.delayedData;
        console.log(records,"line 248")
          var nottrigerred = records.filter((e)=> (e.mail_sent == 0 || e.mail_sent == undefined || e.mail_sent == "") && (e.rake_loading_point_left_on == undefined))
        
          var trigerred = records.filter((e)=>e.mail_sent == 1 || e.mail_sent == 2)
          var dispatchedrakes = records.filter((e)=>e.rake_loading_point_left_on != undefined && e.rake_loading_point_left_on != "")
        //   console.log(records,"records")
        //   records = records.sort(GetSortDescOrder("billing_id"));
              this.setState({
                  rowData:records,
                  allrowData:records,
                  nottrigerred:nottrigerred,
                  trigerred:trigerred,
                  dispatchedrakes:dispatchedrakes,
                  overly: "show-n",
                  loadshow: "show-n"
              });
        })
     
    }
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }

	onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
    };
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);
           
         
          
    }

    onClickUpdateRowHandler(event)
	{
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
           let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
           let pageTitle = "Update OEM Master Data";
           if (currentDepartmentTitle) {
               pageTitle = "Update OEM Master Data" +  currentDepartmentTitle;
           }
           
           let eventOptions = {
               "category": pageTitle,
               "action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
               "label": googleAnalytics.page.action.rowUpdated,
           }
           googleAnalytics.logEvent(eventOptions);
       }
      
       if(this.state.oem_name != "" && this.state.oem_code != ""
       && this.state.industry_type != "")
       {
           var params = {
               rowID:this.state.rowid,
               oem_code:this.state.oem_code,
               oem_name:this.state.oem_name,
               industry_type:this.state.industry_type,
               transporter_code:localStorage.getItem("transportercode")
           }
           redirectURL.post("/dashboard/updateoemitem", params,{
               headers:{
                   'Content-Type': 'application/json'
               }
           }).then((response)=>{
                   //console.log("update response is : ",response.data);
                   //console.log("Update Response ",response.data.result)
                   if(response.data.status == "success")
                   {
                       this.setState({
                           show: true,
                           basicTitle:'Successfully update data',
                           basicType:"success",
                           sliderUpdate:"",
                           overly:"show-n"
                       });
                       this.onLoadData();
                   }
                   
                   else{
                       this.setState({
                           show: true,
                           basicTitle:'Something went wrong',
                           basicType:"danger",
                       });
                   }
                   
               }
           )
           .catch(function(error){
               console.log(error);
           });
       }
       else{
           this.setState({
               show: true,
               basicTitle:'All * fields should not be empty',
               basicType:"danger",
           });
       }

    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
        // console.log(event,"event");
        var d = new Date(event._d);
        // console.log('startDate',d, 'changed date', moment.parseZone(event._d).format('YYYY-MM'))
        var startdate =  moment.parseZone(event._d).format('YYYY-MM')

        this.setState({
            selection_month: startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }



    
    onClickNewAddRow(event)
	{
        event.preventDefault();
		 if (googleAnalytics.page.enableGA) {
			let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
			let pageTitle = "Add OEM Master Data";
			if (currentDepartmentTitle) {
				pageTitle = "Add OEM Master Data" +  currentDepartmentTitle;
			}
			
			let eventOptions = {
				"category": pageTitle,
				"action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
				"label": googleAnalytics.page.action.rowUpdated,
			}
			googleAnalytics.logEvent(eventOptions);
        }
        if(this.state.oem_name != "" && this.state.oem_code != ""
       && this.state.industry_type != "")
        {
           var params = {
                oem_code:this.state.oem_code,
                oem_name:this.state.oem_name,
                industry_type:this.state.industry_type,
                transporter_code:localStorage.getItem("transportercode")
            }
            var fdata = {
                    rownode:params,
                    transporter_code:localStorage.getItem("transportercode")
            }
            redirectURL.post("/dashboard/saveoemitem", params,{
                headers:{
                    'Content-Type': 'application/json'
                }
            }).then((response)=>{
                    //console.log("update response is : ",response.data);
                    //console.log("Update Response ",response.data.result)
                    if(response.data.status == "success")
                    {
                        this.setState({
                            show: true,
                            basicTitle:'Successfully added data',
                            basicType:"success",
                            sliderRso:"",
                            overly:"show-n",
                            loadshow:"show-n"
                        });
                        this.onLoadData();
                    }
                    
                    else{
                        this.setState({
                            show: true,
                            basicTitle:'Something went wrong',
                            basicType:"danger",
                        });
                    }
                    
                }
            )
            .catch(function(error){
                console.log(error);
            });
        }
        else{
            this.setState({
                show: true,
                basicTitle:'All * fields should not be empty',
                basicType:"danger",
            });
        }

    }
    
    /*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
        window.location.reload()
    }

    
	 onAddRow() {
        // var newItem = 
        // {
        //     "truck_no":"",
        //     "truck_type":"",
        //     "transporter_code":"",
        //     "dept_code":""
        // };
        // console.log("newItem",newItem);
        // var res = this.gridApi.updateRowData({ add: [newItem] });
        this.setState({
			sliderRso:'slider-translate',
            overly:'show-m',
            oem_code:"",
            oem_name:"",
            industry_type:""
		})
        
    }
    onRowClicked(cell){
        if(cell.colDef.field == "view_rail_data")
        {
            this.setState({
                overly:"show-m",
                loadshow :"show-m",
            })
            var reck_number = cell.data.reck_number;
            // console.log(reck_number,"var reck_number")
            var params ={
                "reck_number":reck_number,
            }
            redirectURL.post("/dashboard/getvinwisedata",params).then((response) => {
                var records = response.data.delayedData
                // console.log(records,"records")
                this.setState({
                    sliderUpdate:'slider-translate-60p',
                    remarks_data:records,
                    overly:"show-m",
                    loadshow :"show-n",
                })
            })
            .catch(function (error) {
                console.log(error);
            });
        }
        if(cell.colDef.field == "sent_mail"){
            // alert ("mail sending")
            var transporter_name = cell.data.transporter_name;
            var  type_of_rake = cell.data.rake_no;
            var  origin = cell.data.rake_loading_point_name;
            var  rake_number = cell.data.reck_number;
            var destination = cell.data.rake_delivery_point_name;
            var invoicing_start_date = cell.data.invoice_time;
            var sent_by = localStorage.getItem("email")
            var cc_mails = cell.data.to_mails
            var mail_sent = cell.data.mail_sent
            params ={
                transporter_name:transporter_name,
                type_of_rake:type_of_rake,
                origin:origin,
                rake_number:rake_number,
                destination:destination,
                invoicing_start_date:invoicing_start_date,
                mail_status :1,
                sent_by:sent_by,
                cc_mails:cc_mails,
                mail_sent:mail_sent,
            }
            redirectURL.post("/dashboard/sendemailfordelayedrakes",params).then((response) => {
                this.setState({
                    show: true,
					basicTitle:'Mail Successfully Sent',
					basicType:"success",
                })
                
            })
        }
        if(cell.colDef.field == "force_close"){
            var rakeLoadingpoint = cell.data.rake_loading_point_left_on;
            
            if(rakeLoadingpoint != null && rakeLoadingpoint != undefined && rakeLoadingpoint != "")
            {
                var lodingDate = rakeLoadingpoint.split(" ")[0]
                var lodingTime = rakeLoadingpoint.split(" ")[1]
                var rakeLoadingTime = lodingDate.split("/")[0]+"-"+lodingDate.split("/")[1]+"-"+lodingDate.split("/")[2]+" "+lodingTime+":00"
                rake_number = cell.data.reck_number;
                params ={
                    reck_number : rake_number,
                    rakeLoadingTime:rakeLoadingTime,
                }
                redirectURL.post("/dashboard/updaterakeloadingpointtime",params).then(response =>{
                    this.setState({
                        show: true,
					    basicTitle:'Data updated successfully',
					    basicType:"success",
                    })
                })
            }
            else{
                this.setState({
                    show: true,
					basicTitle:'Please Update Rake Dispatch Date',
					basicType:"warning",
                })
            }
        }
        if(cell.colDef.field == "history_data"){
             this.setState({
                detailCellRendererParams:{
                detailGridOptions: {
                columnDefs: [
                    {
                        headerName: "Transporter",
                        field: "transporter_name",
                        width: 170,
                      editable:false,
                      filter: "agSetColumnFilter"
                    },
                    {
                        headerName: "Type of Rake",
                        field: "rake_no",
                        width: 120,
                      editable:false,
                      filter: "agSetColumnFilter",
                    },
                    {
                        headerName: "To Mails",
                        field: "to_emails",
                        width: 160,
                      editable:false,
                      filter: "agSetColumnFilter",
                    },
                    {
                        headerName: "CC Mails",
                        field: "cc_mails",
                        width: 90,
                      editable:false,
                      filter: "agSetColumnFilter",
                    },
                    {
                        headerName: "Reck Number",
                        field: "reck_number",
                        width: 150,
                      editable:false,
                      filter: "agSetColumnFilter"
                    },
                    {
                        headerName: "Invoice Time",
                        field: "invoice_time",
                        width: 150,
                      editable:false,
                      filter: "agSetColumnFilter",
                      valueGetter:function(params){
                        if(params.data.invoice_time != '' && params.data.invoice_time != undefined)
                        {
                            return getHyphenYYYYMMDDHHMMSS(params.data.invoice_time)
                        }
                        else
                        {
                            return "";
                        }
                    }
                    },
                    {
                        headerName: "Mail Sent On",
                        field: "mail_sent_on",
                        width: 150,
                      editable:false,
                      filter: "agSetColumnFilter",
                      valueGetter:function(params){
                        if(params.data.mail_sent_on != '' && params.data.mail_sent_on != undefined)
                        {
                            return getHyphenYYYYMMDDHHMMSS(params.data.mail_sent_on)
                        }
                        else
                        {
                            return "";
                        }
                    }
                    },
                    {
                        headerName: "Mail Sent Type",
                        field: "mail_sent_type",
                        width: 150,
                      editable:false,
                      filter: "agSetColumnFilter"
                    },
                ],
                    overlayNoRowsTemplate: 'No rows to show',
                        },
                        getDetailRowData: (params)=>{
                            var formData ={
                                reck_number :params.data.reck_number
                            }
                            redirectURL.post("/dashboard/getHistoryMailData",formData).then((response)=>{
                                var resp = response.data;
                                console.log(resp,"resp")
                                // this.setState({
                                //     viewData:resp.data,
                                // })
                                params.successCallback(resp.data)
                            })
                           
                        },
                    masterDetail: true,
                    }
                })
                console.log(cell.colDef.field );
                if(cell.colDef.field == 'history_data')
                {
                    cell.node.setExpanded(!cell.node.expanded);
                }
                else{
                    cell.node.setExpanded(false);
                }
        }
    }
    
    
	 updateRowHandler = async(cell) => {
       
        var rowid = cell._id;
        var oem_code = cell.oem_code;
        var oem_name = cell.oem_name;
        var industry_type = cell.industry_type;
        await this.setState({
			sliderUpdate:'slider-translate',
            overly:'show-m',
            oem_code:oem_code,
            oem_name:oem_name,
            industry_type:industry_type,
            rowid:rowid
		})
        
    }


    
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            sliderRso:"",
            sliderUpdate:"",
            sliderBulk:"",
            sliderConfigData:""
		});
		
    }

    onChangeTruckType(trucktype){
		this.setState(
			{ trucktype },
			() => console.log(`Option selected:`, this.state.trucktype)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
  



// onCLickShowRowDetails = async (rownode) => {
// console.log(rownode , "rownode")
//  await this.setState({
//     detailCellRendererParams:{
//     detailGridOptions: {
//     columnDefs: [
//         {
//             headerName: "Transporter",
//             field: "transporter_name",
//             width: 170,
//           editable:false,
//           filter: "agSetColumnFilter"
//         },
//         {
//             headerName: "Type of Rake",
//             field: "rake_no",
//             width: 120,
//           editable:false,
//           filter: "agSetColumnFilter",
//         },
//         {
//             headerName: "To Mails",
//             field: "to_emails",
//             width: 160,
//           editable:false,
//           filter: "agSetColumnFilter",
//         },
//         {
//             headerName: "CC Mails",
//             field: "cc_mails",
//             width: 90,
//           editable:false,
//           filter: "agSetColumnFilter",
//         },
//         {
//             headerName: "Reck Number",
//             field: "reck_number",
//             width: 150,
//           editable:false,
//           filter: "agSetColumnFilter"
//         },
//         {
//             headerName: "Invoice Time",
//             field: "invoice_time",
//             width: 150,
//           editable:false,
//           filter: "agSetColumnFilter"
//         },
//         {
//             headerName: "Mail Sent On",
//             field: "mail_sent_on",
//             width: 150,
//           editable:false,
//           filter: "agSetColumnFilter"
//         },
//         {
//             headerName: "Mail Sent Type",
//             field: "mail_sent_type",
//             width: 150,
//           editable:false,
//           filter: "agSetColumnFilter"
//         },
//     ],
//         overlayNoRowsTemplate: 'No rows to show',
//             },
//             getDetailRowData: (params)=>{
//                 params.successCallback(this.state.viewData)
//             },
//         masterDetail: true,
//         }
//     })
//     console.log(rownode.colDef.field );
//     if(rownode.colDef.field == 'viewData')
//     {
//         rownode.node.setExpanded(!rownode.node.expanded);
//     }
//     else{
//         rownode.node.setExpanded(false);
//     }
//  }


    onClickGenerateBill(){
    //     this.setState({
    //         overly: "show-m",
    //         loadshow: "show-m"
    //     })
       
    //     var billing_getter = this.state.selection_month;
    //     console.log(billing_getter,"billing_getter");
        
    //     if(billing_getter !== "" && billing_getter !== undefined){
    //         var params={                
    //             billing_month:billing_getter,              
    //         }
    //         console.log(params,"params");
    //         redirectURL.post('/dashboard/selectingbillingmonth',params)
    //         .then((response) => {

    //             var records = JSON.parse(response.data.otpt);
    //             // records = records.sort(GetSortDescOrder("billing_id"));
    //             console.log("recordsbilling ", records)
                
    //             this.setState({
    //                 rowData: records,
    //                 sliderBulk: "",
    //                 overly: "show-n",
    //                 loadshow: "show-n"
    //             });
    //             // window.location.href="/billingsummary" 
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
     
    //     // }
    // }
}
    
    onChangeTransporter(transporter){
		this.setState(
			{ transporter },
			() => console.log(`Option selected:`, this.state.transporter)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    onChangeDepartment(deptcode){
		this.setState(
			{ deptcode },
			() => console.log(`Option selected:`, this.state.deptcode)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    deleteRowHandler(cell)
	{
		//  console.log("Here",cell);
		 if (googleAnalytics.page.enableGA) {
			let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
			let pageTitle = "OEM Master Data";
			if (currentDepartmentTitle) {
				pageTitle = "OEM Master Data" +  currentDepartmentTitle;
			}
			
			let eventOptions = {
				"category": pageTitle,
				"action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
				"label": googleAnalytics.page.action.rowUpdated,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		var fdata = {
	    		rownode:cell
	    }
	    redirectURL.post("/dashboard/deleteoemitem", fdata,{
			headers:{
				'Content-Type': 'application/json'
			}
		}).
		then(
			(response)=>{
				//console.log("update response is : ",response.data);
				//console.log("Update Response ",response.data.result)
				if(response.data.status == "success")
				{
					this.setState({
						show: true,
						basicTitle:'Successfully delete item',
						basicType:"success",
                        loadshow:"show-n",
                        overly:"show-n",
                        sliderRso:""
                    });
                    this.onLoadData();
				}
				
				else{
					this.setState({
						show: true,
						basicTitle:'Something went wrong',
						basicType:"danger",
					});
				}
				
			}
		)
		.catch(function(error){
			console.log(error);
		});

    }
     
    onChangeSourceItem(sourceopt){
		this.setState(
			{ sourceopt },
			() => console.log(`Option selected:`, this.state.sourceopt)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    onChangeOfficerType(officertype){
		this.setState(
			{ officertype },
			() => console.log(`Option selected:`, this.state.officertype)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    selectsources(){
		let items = [];    
		try{
			if(this.state.sources.length > 0)
			{
				this.state.sources.map((item) =>{
					items.push({"value":item.geofence_name,"label":item.geofence_name})
				})
			}
		}
		catch(e){

		}		
		return items;
    }
    
    onClickShowBulk()
    {
        this.setState({
            overly:"show-m",
			sliderBulk:'slider-translate',
        })
    }
    
    onClickRegionMails()
    {
        this.setState({
            overly:"show-m",
			loadshow :"show-m",
        })
        redirectURL.post("/dashboard/getconfigregionmails").
            then((response) => {
                var configregiondata = response.data.configmailsdata
                // console.log(configregiondata,"records")
                this.setState({
                    sliderConfigData:'slider-translate-40p',
                    config_region_data:configregiondata,
                    overly:"show-n",
                    loadshow :"show-n",
                })
            })
    }
	changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'oem_name',
					inputName: 'oem_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'oem_code',
					inputName: 'oem_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'industry_type',
					inputName: 'industry_type',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				// console.log("contents ", contents )
				
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			// console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		
	}
    onClickFilterInvoiceData = () => {
        this.setState({
            loadshow: "show-m",
            overly: "show-m",
        });
        var invoiceStartDate = $('#invoiceStartDate').val()
        var invoiceEndDate = $('#invoiceEndDate').val()
        if(invoiceStartDate != "" && invoiceStartDate != undefined && invoiceEndDate != "" && invoiceEndDate != undefined)
        {
            var reqparams = {
				fromDate : invoiceStartDate,
				toDate : invoiceEndDate,
			}
            redirectURL.post("/dashboard/getDelayedRakeInfoData",reqparams)    
			.then((response) => {
				var delayedrakeinfodata = response.data.delayedData;
                var nottrigerred = delayedrakeinfodata.filter((e)=>e.mail_sent == 0 || e.mail_sent == undefined || e.mail_sent == ""&&e.rake_loading_point_left_on == undefined && e.rake_loading_point_left_on == "")
          var trigerred = delayedrakeinfodata.filter((e)=>e.mail_sent == 1 || e.mail_sent == 2)
          var dispatchedrakes = delayedrakeinfodata.filter((e)=>e.rake_loading_point_left_on != undefined && e.rake_loading_point_left_on != "")
				// console.log(records,"records")
                this.setState({
                    rowData:delayedrakeinfodata,
                    allrowData:delayedrakeinfodata,
                    nottrigerred:nottrigerred,
                    trigerred:trigerred,
                    dispatchedrakes:dispatchedrakes,
                    loadshow: "show-n",
                    overly: "show-n",
                });
            })
        }
        else{
            this.setState({
                show: true,
                basicTitle:'Select Date',
                basicType:"danger",
            });
        }
    }
    onSubmitBulkUpload(event)
    {
        event.preventDefault()

        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Buk Upload",
				"action": "form",
				"label": "Buk Upload",
			}
			googleAnalytics.logEvent(eventOptions);
        }
       
        var params = {   
            csvcontent:this.state.csvcontent,
        }
		// console.log("content ",this.state.csvcontent)
        // console.log("Params ", params)
        redirectURL.post("/dashboard/bulkuploadoemdata", params)
        .then((response) => {
            if(response.data.status == "success")
				{
					this.setState({
						show: true,
						basicTitle:'Successfully added items',
						basicType:"success",
                        loadshow:"show-n",
                        overly:"show-n",
                        sliderBulk:"",
                        file:""
                    });
                    this.onLoadData();
				}
				
				else{
					this.setState({
						show: true,
						basicTitle:'Something went wrong',
						basicType:"danger",
					});
				}
        })
    }
    onClickTab = (counterKey) => {
        this.setState({
            rowData : this.state[counterKey]
        });
    }
    applyDatePicker = (e) => {
		console.log("Datepicker ", e);
	}
    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
        var transporters = []
        var trans = this.state.transporters;
        if(trans.length > 0)
        {
            trans.map((item) => {
                transporters.push(item.value)
            })
        }
		const {usermanualmodal} = this.state;
		var hideChilds = true;
		if(this.state.departmentCode == "LOG-PRT")
		{
			hideChilds = false
		}
		const columnwithDefs = [
                {
                    // View Remarks
                    headerName: "History",
                    field: "history_data",
                    width: 70,
                    filter: false,
                    // cellRenderer:'updateItem',
                    editable:false,
                    cellRenderer:function(params){
                        // let basestring = params.data.billingId;
                        // let encryptedstring = window.btoa(basestring);
                        var htmloption = '<i class="icofont icofont-site-map f25" aria-hidden="true"></i>';
                        //var htmloption = '<a href="/tptlogin/billingdownload/'+encryptedstring+'" class="custom-btn label label-success"><i class="fa fa-comment f15"></i>View</a>';
                        return htmloption
                    }
                },
                {
                    // View Remarks
                    headerName: "View Vin's",
                    field: "view_rail_data",
                    width: 80,
                    filter: false,
                    // cellRenderer:'updateItem',
                    editable:false,
                    cellRenderer:function(params){
                        let basestring = params.data.billingId;
                        let encryptedstring = window.btoa(basestring);
                        var htmloption = '<i class="icofont icofont-eye">View</i>';
                        //var htmloption = '<a href="/tptlogin/billingdownload/'+encryptedstring+'" class="custom-btn label label-success"><i class="fa fa-comment f15"></i>View</a>';
                        return htmloption
                    }
                },
                {
	  	          headerName: "Transporter",
	  	          field: "transporter_name",
	  	          width: 170,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
	  	          headerName: "Type of Rake",
	  	          field: "rake_no",
	  	          width: 120,
	    	      filter: "agSetColumnFilter",
                  editable: (param)=>{
                    return (param.data.rake_no == "")?true:false
                  },
                //   cellEditor: "agSelectCellEditor",
                  cellEditor: 'agRichSelectCellEditor',
                  cellEditorParams: param =>{
                    console.log("param", param)
                    if(param.data.rake_no === ""){
                        return {
                            values: ['BCACBM','NMG']
                          }
                    }
                  } ,
                //   valueGetter : p =>{
                //     console.log("pValGetter", p)
                //   },
                  valueSetter: (params) => {
                    if(params.data.rake_no == ""){
                        const oldVal = params.oldValue
                        const newVal = params.newValue
                        // console.log("params_oldVal", params.oldValue)
                        // console.log("params_newVal", params.newValue)
                        if (oldVal != newVal) {
                            // console.log("params", newVal)
                            const data = {
                                id:params.data._id,
                                newVal,
                            }
                            params.data.rake_no = params.newValue
                            redirectURL.post("/consignments/updateDelayedRakesInfoType", data,{
                                headers:{
                                    'Content-Type': 'application/json'
                                }
                            }).then((response)=>{
                                // console.log("response", response)
                                // this.setState({
    
                                // })
                            })
                        }
                    }
                  },
                },
                {
	  	          headerName: "Origin",
	  	          field: "rake_loading_point_name",
	  	          width: 160,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                },
		        {
	  	          headerName: "Rake No",
	  	          field: "reck_number",
	  	          width: 90,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                },
                {
	  	          headerName: "Destination",
	  	          field: "rake_delivery_point_name",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
                    headerName: "Report Date",
                    field: "updated_on",
                    width: 150,
                    filter: true,
                    // cellRenderer:'updateItem',
                    editable:true,
                    valueGetter:function(params){
                            if(params.data.updated_on != '' && params.data.updated_on != undefined)
                            {
                                return getHyphenYYYYMMDDHHMMSS(params.data.updated_on)
                            }
                            else
                            {
                                return "";
                            }
                        }
		        },
                {
                    headerName: "Invoicing Start Date",
                    field: "invoice_time",
                    width: 150,
                    filter: true,
                    // cellRenderer:'updateItem',
                    editable:true,
                    valueGetter:function(params){
                            if(params.data.invoice_time != '' && params.data.invoice_time != undefined)
                            {
                                return getHyphenYYYYMMDDHHMMSS(params.data.invoice_time)
                            }
                            else
                            {
                                return "";
                            }
                        }
		        },
                {
                    headerName: "No.of Cars Invoiced",
                    field: "no_of_vins",
                    width: 150,
                    filter: true,
                    // cellRenderer:'updateItem',
                    editable:true,
		        },
                {
                    headerName: "Rake Dispatch Date",
                    field: "rake_loading_point_left_on",
                    width: 150,
                    filter: true,
                    cellEditor: DateEditor,
                    editable:true,
                    valueGetter:function(params){
                            if(params.data.rake_loading_point_left_on != '' && params.data.rake_loading_point_left_on != undefined)
                            {
                                return getHyphenYYYYMMDDHHMMSS(params.data.rake_loading_point_left_on)
                            }
                            else
                            {
                                return "";
                            }
                        },
                    comparator: dateComparator1,
		        },
                {
                    headerName: "Comments",
                    field: "comments",
                    width: 120,
                    filter: true,
                    // cellRenderer:'updateItem',
                    editable:true,
                    valueSetter:(params)=>{
                        // console.log(params)
                        if(params.oldValue != params.newValue)
                        {
                            var admin_mail=localStorage.getItem('email')
                            // console.log(params,"params");
                            var data = {
                                complete_remarks:params.newValue,
                                reck_number:params.data.reck_number,
                                commented_by:admin_mail
                            }
                            params.data.complete_remarks = params.newValue;
                            // console.log(params.data.complete_remarks,"complete_remarks")
                            
                            redirectURL.post("/dashboard/updateremarksfordelayedrakes", data,{
                                headers:{
                                 'Content-Type': 'application/json'
                                }
                            }).then((response)=>{   
	                            this.onLoadData();
                            })
                        }
                    }
		        },
                // {
                //     headerName: "CC Mails",
                //     field: "to_mails",
                //     width: 120,
                //     filter: true,
                //     // cellRenderer:'updateItem',
                //     editable:true,
                //     valueSetter:(params)=>{
                //         // console.log(params)
                //         if(params.newValue != "" && params.newValue != undefined)
                //         {
                //             if(params.oldValue != params.newValue)
                //             {
                //                 var admin_mail=localStorage.getItem('email')
                //                 // console.log(params,"params");
                //                 var data = {
                //                     to_mails:params.newValue,
                //                     reck_number:params.data.reck_number,
                //                     added_by:admin_mail
                //                 }
                //                 params.data.to_mails = params.newValue;
                //                 // console.log(params.data.to_mails,"new_mails")
                //                 redirectURL.post("/dashboard/updatemailsfordelayedrakes", data,{
                //                     headers:{
                //                      'Content-Type': 'application/json'
                //                     }
                //                 }).then((response)=>{  
                //                     // this.onLoadData();
                //                 })
                //             }
                //         }
                //         // this.onLoadData();
                //     }
                // },
                {
                    headerName: "RO Mails",
                    field: "ro_emails",
                    width: 180,
                    resizable: true,
                    filter: true,
                    // cellRenderer:'updateItem',
                    editable:true,
                   
                },
                {
                    headerName: "Send Mail",
                    field: "sent_mail",
                    width:110,
                    filter: true,
                    resizable: true,
                    cellRenderer:function(params){
                        
                        if(params.data.rake_loading_point_left_on != undefined && params.data.rake_loading_point_left_on != ""){
                            var htmloption = '<button class="btn btn-success" disabled="on" style="padding:0 9px">Mail Sent</button>';
                            return htmloption;
                        } 
                        else if(params.data.mail_sent > 0){
                            var htmloption = '<button class="btn btn-warning" style="padding:0 9px">Mail Sent</button>';
                            return htmloption;
                        }
                        else if(params.data.mail_sent == 0 || params.data.mail_sent == undefined){
                            var htmloption = '<button class="btn btn-danger" style="padding:0 9px">Send Mail</button>';
                            return htmloption;
                        }
                    }
                           
                },
                {
                    headerName: "Force Closure",
                    field: "force_close",
                    width:120,
                    filter: true,
                    resizable: true,
                    cellRenderer:function(params){
                        if(params.data.rake_loading_point_left_on == undefined || params.data.rake_loading_point_left_on == null || params.data.rake_loading_point_left_on =="" ){
                            var htmloption = '<button class="btn btn-danger" style="padding:0 9px">Force Close</button>';
                            return htmloption;
                        }else{
                            var htmloption = '<button class="btn btn-danger" disabled="on" style="padding:0 9px">Force Close</button>';
                            return htmloption;
                        }
                    }
                },
                {
                    headerName: "Last Mail Sent",
                    field: "last_mail_sent_on",
                    width: 150,
                    filter: false,
                    // cellRenderer:'updateItem',
                    editable:true,
                    valueGetter:function(params){
                        if(params.data.last_mail_sent_on != '' && params.data.last_mail_sent_on != undefined)
                        {
                            return getHyphenYYYYMMDDHHMMSS(params.data.last_mail_sent_on)
                        }
                        else
                        {
                            return "";
                        }
                    }
		        },
                {
                    headerName: "Mail Sent Via",
                    field: "mail_sent",
                    width: 150,
                    filter: false,
                    // cellRenderer:'updateItem',
                    editable:true,
                    valueGetter:function(params){
                        if(params.data.mail_sent == 1){
                            return "Auto"
                        }
                        else if(params.data.mail_sent == 2){
                            return "Manual"
                        }
                        else
                        {
                            return "";
                        }
                    }
		        },
                
		        // {
                //     headerName: "Action",
                //     field: "_id",
                //     width: 100,
                //     filter: false,
                //     cellRenderer:'deleteItem',
                //     editable:false
		        // }
	    ];
        // if(this.state.rowData.rake_loading_point_left_on == "" || this.state.rowData.rake_loading_point_left_on == undefined)
        // {
        //     columnwithDefs.push({
        //             headerName: "Force Closure",
        //             field: "force_closed",
        //             width:110,
        //             filter: true,
        //             resizable: true,
        //             cellRenderer:function(params){
                        
        //                 if(params.data.rake_loading_point_left_on != undefined && params.data.rake_loading_point_left_on != ""){
        //                     var htmloption = '<button class="btn btn-success" disabled="on" style="padding:0 9px">Force Close</button>';
        //                     return htmloption;
        //                 } 
        //             }
        //     })
        // }
        const columnwithconfigemailDefs = [
            {
                headerName: `To Emails`,
                field: "to_emails",
                width: 200,
                filter: true,
                editable:true,
                valueSetter:(params)=>{
                    // console.log(params,"configmaildata")
                    if(params.newValue != "" && params.newValue != undefined)
                    {
                        if(params.oldValue != params.newValue)
                        {
                            var admin_mail=localStorage.getItem('email')
                            // console.log(params,"params");
                            var data = {
                                to_mails:params.newValue,
                                config_id:params.data._id,
                                added_by:admin_mail
                            }
                            params.data.to_mails = params.newValue;
                            // console.log(params.data.to_mails,"new_mails")
                            redirectURL.post("/dashboard/updatemailsforconfigemails", data,{
                                headers:{
                                 'Content-Type': 'application/json'
                                }
                            }).then((response)=>{  
                                // this.onLoadData();
                            })
                        }
                    }
                    // this.onLoadData();
                }
            },
            {
                headerName: `Region`,
                field: "region",
                width: 120,
                editable: false,
                filter: "agSetColumnFilter",
            },
            {
                headerName: `Remarks`,
                field: "remarks",
                width: 120,
                editable: false,
                filter: "agSetColumnFilter",
            },
    
        ]
		return(
			<div className="container-fluid">
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        > 
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
                    <div className="card">
                            <div className="card-body" id="counterCardBody">
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"allrowData")}>
                                            <span className="f13" style={{color:"#4099FF"}}>Total Rakes</span>
                                            <h4 class="txt-info f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.allrowData.length)?this.state.allrowData.length:0}/>
                                                </span>
                                            </h4>
                                        </div>
                                        <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"nottrigerred")}>
                                            <span className="f13" style={{color:"#FF5370"}}>Mail Yet to be Triggered</span>
                                            <h4 class="txt-danger f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.nottrigerred.length)?this.state.nottrigerred.length:0}/>
                                                </span>
                                            </h4>
                                        </div>
                                        <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"trigerred")}>
                                            <span className="f13" style={{color:"#F3D800"}}>Mail Manually Triggered</span>
                                            <h4 class="txt-warning f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.trigerred.length)?this.state.trigerred.length:0}/>
                                                </span>
                                            </h4>
                                        </div>
                                       
                                        <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"dispatchedrakes")}>
                                            <span className="f13" style={{color:"#00C292"}}>Dispatched Rakess</span>
                                            <h4 class="txt-success f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.dispatchedrakes.length)?this.state.dispatchedrakes.length:0}/>
                                                </span>
                                            </h4>
                                        </div>                            
                                    </div>                               
                                </div>
                            </div>
                        </div>
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Delayed Rakes Info </span>
                                       <button type="button" onClick={this.onClickRegionMails.bind(this)} className="btn btn-info float-right">Config Region Mails</button>
                                        {/* <button href="/tptlogin/billingsummary" type="button" onClick={this.onClickGenerateBill.bind(this)} className="btn btn-warning float-right">Generate Bill</button> */}
                                        {/* <button type="button" onClick={() => this.onClickShowBulk(2)} className="btn btn-success float-right">View Bill</button> */}
                                </h5>
                            </div>
                            <div className="row"  style={{marginLeft:"1em"}}>
                                <div class="col-xl-2 col-lg-2 form-group">
                                    <label>From Date</label>
                                    <input type="text" name="date" id="invoiceStartDate" className="device_filter_data form-control" />
                                </div>
                                <div class="col-xl-2 col-lg-2 form-group">
                                    <label>To Date</label>
                                    <input type="text" name="date" id="invoiceEndDate" className="device_filter_data form-control" />
                                </div>
                                <div class="col-xl-2 col-lg-2 form-group" style={{marginTop:"29px"}}>
                                    <button  class="btn btn-success" type="submit" onClick={this.onClickFilterInvoiceData.bind(this)}>Submit</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                            </div>
                            
		            		<div className="card-body">
                                <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        // rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        deleteRowHandler={this.deleteRowHandler}
                                        onCellClicked={this.onRowClicked.bind(this)}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        masterDetail={true}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}

                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        // enableCellTextSelection={true}
                                        //updateRowHandler={this.updateRowHandler}
                                        //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				 <div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
					 <div className="slide-r-title">
				 		<h4>
                            Sidebars
                        </h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
						
				 		<div className="col-xl-12 col-lg-12">
				 		<div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg "+(this.state.alerterrshow)+" "+(this.state.alerterrmg)}>{this.state.csverrmessage}</div>
	        			
				 		</div>
				 	</div>
				 </div>
				
                
                <div className={"slide-r "+(this.state.sliderRso)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Add OEM Item
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickNewAddRow.bind(this)}>
							
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">OEM Code *</label>
								<input 
									type="text" 
									name="oem_code"
									value={this.state.oem_code}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							     
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">OEM Name *</label>
								<input 
									type="text" 
									name="oem_name"
									value={this.state.oem_name}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							     
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Industry Type *</label>
								<input 
									type="text" 
									name="industry_type"
									value={this.state.industry_type}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							                       
							
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						
                        </div>
					</div>
				</div>



                <div className={"slide-r "+(this.state.sliderUpdate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Vin Information
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickUpdateRowHandler.bind(this)}>
                        <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={this.state.remarks_history_cols}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.remarks_data}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        deleteRowHandler={this.deleteRowHandler}
                                        onCellClicked={this.onRowClicked.bind(this)}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}
                                        //editType={this.state.editType}
                                        // stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        // enableCellChangeFlash={true}
                                        // suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        //updateRowHandler={this.updateRowHandler}
                                        //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
                            
						</form>

						
                        </div>
					</div>
				</div>

                <div className={"slide-r "+(this.state.sliderBulk)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           View Bill
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                     
                            
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitBulkUpload.bind(this)}>
							
                            <div className="col-xl-12 col-lg-12 row form-group">
                            <div className="col-xl-12 col-lg-12 form-group">
                                <label>Select Billing Date</label>
                                <Datetime
                                    value={this.state.billing_month}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{
                                        placeholder: '',
                                        name: 'billing_month',
                                        autoComplete: 'off'
                                    }}
                                    dateFormat="YYYY-MM"
                                    name="billing_month"
                                    onChange={this.handlerStartDateTime.bind(this)}

                                />
                            </div>  
                            </div>
							 
							<div className="col-xl-12 col-lg-12 c">
                            <button href="/tptlogin/billingsummary" type="button" onClick={this.onClickGenerateBill.bind(this)} className="btn btn-success">Get Bill</button>
							</div>
                            
						    {/* <div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_oem_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div> */}
						</form> 
                    
						
                        </div>
					</div>
				</div>
                <div className={"slide-r " + this.state.sliderConfigData}>
                    <div className="slide-r-title">
                        <h4>Config Email Data
                        <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        {/* <button className="float-right export-btn white btn-danger" onClick={() => this.onBtExport()} 
                        style={{marginRight:"1em", padding:"6px"}}> Export to Excel</button> */}
                       </h4>
                       
                    </div>
                    
                    <div className="slide-r-body" style={{ position: "relative" }}>
                    {/* <button className="export-btn white btn-danger my-2" onClick={() => this.onBtExport()}> Export to Excel</button> */}
                        {/* {this.state.trucks_without_driver} */}
                                <div
                                    id="myGrid"
                                    style={{ height: "450px", width: "100%" }}
                                    className="ag-theme-balham"
                                    >
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithconfigemailDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.config_region_data}
                                        enableCharts={false}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady1}
                                        onGridState={this.onGridState1}
                                        gridOptions={{
                                            context: { componentParent: this },
                                        }}
                                    /> 
                                </div>
                        {/* {(this.state.trucks_without_driver.map(function(e){
                            return <div className="col-xl-12 col-lg-12">
                                <h6>{e}</h6>
                            </div>
                        }))} */}
                    </div>
                </div>
                {/* <div className={"slide-r "+(this.state.sliderBulk)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           View Bill
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                     
                            
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitBulkUpload.bind(this)}>
							
                            <div className="col-xl-12 col-lg-12 row form-group">
                            <div className="col-xl-12 col-lg-12 form-group">
                                <label>Select Billing Date</label>
                                <Datetime
                                    value={this.state.billing_month}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{
                                        placeholder: '',
                                        name: 'billing_month',
                                        autoComplete: 'off'
                                    }}
                                    dateFormat="YYYY-MM-DD"
                                    name="billing_month"
                                    onChange={this.handlerStartDateTime.bind(this)}

                                />
                            </div>  
                            </div>
							 
							<div className="col-xl-12 col-lg-12 c">
                            <button href="/tptlogin/billingsummary" type="button" onClick={this.onClickGenerateBill.bind(this)} className="btn btn-success">Generate Bill</button>
							</div>
                            
						    <div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_oem_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div> 
						</form> 
                
						
                        </div>
					</div>
				</div> */}
                


                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            

            </div>
              	
		);
	}
}
function getDatePicker() {
	function Datepicker() {}
	Datepicker.prototype.init = function(params) {
	  this.eInput = document.createElement("input");
	  //this.eInput = this.eInput.setAttribute("class","datepicker");
	  this.eInput.value = params.value;
	  console.log(this.eInput)
	  window.$(".datepicker").datepicker({ dateFormat: "dd/mm/yy" });
	};
	Datepicker.prototype.getGui = function() {
	  return this.eInput;
	};
	Datepicker.prototype.afterGuiAttached = function() {
	  this.eInput.focus();
	  this.eInput.select();
	};
	Datepicker.prototype.getValue = function() {
	  return this.eInput.value;
	};
	Datepicker.prototype.destroy = function() {};
	Datepicker.prototype.isPopup = function() {
	  return false;
	};
	return Datepicker;
  }

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    console.log(params, "params")
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
function GetSortDescOrder(prop) {    
    return function(a, b) {    
        if (a[prop] < b[prop]) {    
            return 1;    
        } else if (a[prop] > b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
}
function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}
function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	//console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
    console.log(date1Number, date2Number, "comparator")
	return date1Number - date2Number;
	}	
}
function monthToComparableNumber1(date) {
	//console.log(date.length);
	//console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		format:'d-m-Y H:i'
	});
	$('.device_filter_data').datetimepicker({
		format:'Y-m-d H:i',
		timepicker:true
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../node_modules/react-resizable/css/styles.css';
import { WidthProvider, Responsive } from "react-grid-layout";
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import _ from "lodash";
import $ from "jquery";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { ImageFilter } from 'material-ui/svg-icons';
var redirectURL = require('../../redirectURL');



export default class Overspeed extends Component{

	constructor(props)
	{
		super(props);
		this.state={
            dept_code:"",
            userid:localStorage.getItem('userid'),
            email:localStorage.getItem('email'),
            modules:AllModules,
            defaultColDef: {
                sortable: true,
              filter: true,
              resizable: true,
            },
            rowData: [],
            paginationPageSize:50,
            showMsg:"show-n",
            components: { datePicker: getDatePicker() },
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
        };
        
		
    }
    componentDidMount(){
        var dept_code = this.props.deptCode;
        // var dept_code = "SNDG";
        var formdata = {
            config_type:'noentrytimes',
            dept_code : dept_code
        };
        
        redirectURL.post('/manage/kpiConfigurations',formdata, {
            headers:{
                'Content-type':'application/json'
            }
        })
        .then((response) => {
            console.log(response.data);
            this.setState({
                rowData: response.data
            })

        })
    }
    onClickedCell(cell)
    {
        if(cell.colDef.field == "update")
        {
            var morning_start = cell.data.morning_start;
            var morning_end = cell.data.morning_end;
            var evening_start = cell.data.evening_start;
            var evening_end = cell.data.evening_end;
            if(morning_start == ""){morning_start=null;}
            if(morning_end == ""){morning_end=null;}
            if(evening_start == ""){evening_start=null;}
            if(evening_end == ""){evening_end=null;}
            var rqparams = {
                id: cell.data._id,
                morning_start: morning_start,
                morning_end: morning_end,
                evening_start: evening_start,
                evening_end: evening_end,
                user_id: localStorage.getItem("userid"),
                user_email: localStorage.getItem("email"),
                config_type: "noentrytimes",
                dept_code: "LOG-TNP"
            }
            redirectURL.post("/manage/savenoentrytimes",rqparams).then((response)=>{
                console.log(response.data);
                this.setState({
                    showMsg: "show-m"
                })
                setTimeout(function(){
                    $("#update-msg").fadeTo(4000, 500).slideUp(500, function(){
                        $("#update-msg").slideUp(500);
                    });
                  },1000);
            }).catch((e)=>{
                console.log(e);
            })
        }
    }
   
	 render() {
        const days = {0:"Monday",1:"Tuesday",2:"Wednesday",3:"Thursday",4:"Friday",5:"Saturday",6:"Sunday"}
        const columnwithDefs = [
            {
                headerName: "Day",
                field: "dayofweek",
                width: "110",
                valueGetter: function(params){
                    return days[params.data.dayofweek]
                }
            },
            {
                headerName: "Plant",
                field: "plant",
                width: "110",
            },
            {
                headerName: "Morning Start",
                field: "morning_start",
                width: "110",
                editable: true,
                cellEditor: "datePicker"
            },
            {
                headerName: "Morning End",
                field: "morning_end",
                width: "110",
                editable: true,
                cellEditor: "datePicker"
            },
            {
                headerName: "Evening Start",
                field: "evening_start",
                width: "110",
                editable: true,
                cellEditor: "datePicker"
            },
            {
                headerName: "Evening End",
                field: "evening_end",
                width: "110",
                editable: true,
                cellEditor: "datePicker"
            },
            {
                headerName: "",
                field: "update",
                width: "110",
                editable: false,
                cellRenderer: function(params)
                {
                    return "<button class='btn btn-success' style='padding-top:0px'>Update</button>"
                }
            }

        ]
        return (
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <h5>No Entry Times Settings</h5>
                </div>
                <div class="col-xl-12 col-lg-12">
                    <div className={"alert alert-success "+this.state.showMsg} id="update-msg"><i className="fa fa-check-circle"></i> Updated Successfully.</div>
                    <div id="myGrid" style={{width:"100%",height:"460px"}} className="ag-theme-balham">  
                        <AgGridReact
                            modules={this.state.modules}
                            columnDefs={columnwithDefs}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.rowData}
                            // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                            enableCharts={true}
                            enableRangeSelection={true}
                            onGridReady={this.onGridReady}
                            onGridState={this.onGridState}
                            statusBar={true}
                            sideBar={false}
                            components={this.state.components}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                            gridOptions={{
                                context: { componentParent: this }
                            }}
                            suppressRowClickSelection={true}
                            stopEditingWhenGridLosesFocus={true}
                            onCellClicked={this.onClickedCell.bind(this)}
                        />

                    </div>
                </div>
            </div>
        );
    }
}


function getDatePicker() {
  function Timepicker() {}
  Timepicker.prototype.init = function(params) {
    this.eInput = document.createElement("input");
    this.eInput.value = params.value;
    $(this.eInput).datetimepicker({
        datepicker:false,
        format:'H:i'
    });
  };
  Timepicker.prototype.getGui = function() {
    return this.eInput;
  };
  Timepicker.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
  };
  Timepicker.prototype.getValue = function() {
    return this.eInput.value;
  };
  Timepicker.prototype.destroy = function() {};
  Timepicker.prototype.isPopup = function() {
    return false;
  };
  return Timepicker;
}

function timeout(){
    
}

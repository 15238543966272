import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

export default class UserLogScreenWiseChart extends Component {

    constructor(props) {
        super(props);
    }

    render(){
    	
        const dStyles={
				width:'100%',
				height:'100%'
		}
		const highchartoptions = {
            chart: {
                // type: 'packedbubble',
                // height: '700px'
                type:"column"
            },
            title: {
                text: this.props.xTitle
            },
            xAxis: {
                categories: this.props.categories,
                title: {
                    text: null
                },
                labels: {
                 enabled:true,//default is true
                 //y : 20, rotation: -45, align: 'right' 
                }

            },
            yAxis: {
                min: 0,
                title: {
                    text: this.props.yaxistitle
                }
            },
            plotOptions: {
                pointWidth:"40px",
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                },
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}'
                    },
                    cursor: 'pointer',
                    point: {
                        
                    }
                }
            },
            tooltip: {
                useHTML: true,
                pointFormat: '<b>{point.name}:</b> {point.value}'
            },
            // plotOptions: {
			// 	packedbubble: {
			// 		minSize: '60%',
			// 		maxSize: '180%',
			// 		zMin: 0,
			// 		zMax: 1000,
			// 		layoutAlgorithm: {
			// 			splitSeries: false,
			// 			gravitationalConstant: 0.02
			// 		},
			// 		dataLabels: {
			// 			enabled: true,
			// 			format: '{point.name}',
			// 			filter: {
			// 				property: 'y',
			// 				operator: '>',
			// 				value: 250
			// 			},
			// 			style: {
			// 				opacity:1,
			// 				fontSize:"13px"
			// 			// 	color: 'black',
			// 			// 	textOutline: 'none',
			// 			// 	fontWeight: 'normal'
			// 			}
			// 		},
			// 		fillOpacity:1,
			// 	}
			// },
            series: this.props.series,
            // colors:this.props.colorcodes,
            credits:false,
            // responsive: {
            //     rules: [{
            //         condition: {
            //             maxWidth: '100%'
            //         },
            //         chartOptions: {
            //             chart: {
            //                 height: '100%'
            //             },
            //             subtitle: {
            //                 text: null
            //             },
            //             navigator: {
            //                 enabled: false
            //             }
            //         }
            //     }]
            // }
        };
        
        return ( 
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />

			);
		}
}













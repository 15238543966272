/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-eval */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from "lodash";
import Modal from 'react-responsive-modal';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import DrawMap from '../common/drawmap';
import NewDrawMap from '../common/newDrawMap'
import ShowTollsList from "./showtollslist";
import TollGrid from "./deviationtollgrid";
import ChokePointsGrid from "./deviationchokepoints";
import RouteGrid from "./deviationroutedata";
import CreatableSelect from 'react-select/creatable';
import $ from 'jquery';
import { getHyphenYYYYMMDD, getHyphenYYYYMMDDHHMMSS, getDDMMYYYYHHMMSS } from '../common/utils';
import coEmissionImage from '../../../src/assets/icons/emission_counter.png' 
import positiveEmissionImage from '../../../src/assets/icons/green_arrow.png'
import negativeEmissionImage from '../../../src/assets/icons/red_arrow.png'
// import ApprovalDeviationRoute from "./approvaldeviationroute";
// import MyHeaderComponent from "./deviationheaderaction";
// import Showtolls from "./showtolls";
// import ShowChokePoints from "./showchokepoints";
// import DateDeviationRoute from "./datedeviationroute";
// import ViewDeviationRouteData from "./viewdeviationroutedata";
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var aggriddata = [];
var originaldata;
var waypoints = []
var infoBox = require('../common/google-infowindow');
var directionsDisplays = [];
var map;
var colDefs1 = [
    {
        headerName: "Source",
        field: "plant",
        valueGetter: (params)=>{
            return params.data.plant
        }
    },
    {
        headerName: "Destination",
        field: "consignee_city",
    },
    {
        headerName: "Base Routes",
        field: "no_of_base_routes",
    },
    {
        headerName: "Total Trips",
        field: "total_trips",
    },
    {
        headerName: "No. of Trips Considered",
        field: "no_of_trips_travelled",
    },
    {
        headerName: "MSIL Approved Distance (KM)",
        field: "expected_distance",
    },
    {
        headerName: "Valid Trips",
        field: "valid_trips",
    },   
    // {
    //     headerName: "Route Ignored",
    //     field: "route_ignored",
    // },
    // {
    //     headerName: "1 Straight Line",
    //     field: "st_lines_1",
    // },
    // {
    //     headerName: "2 Straight Lines",
    //     field: "st_lines_2",
    // },
    // {
    //     headerName: "3 Straight Lines",
    //     field: "st_lines_3",
    // },
    // {
    //     headerName: "4 Straight Lines",
    //     field: "st_lines_4",
    // },
    // {
    //     headerName: "5 Straight Lines",
    //     field: "st_lines_5",
    // },
    // {
    //     headerName: "> 5 Straight Lines",
    //     field: "st_lines_gt_5",
    // }
]

var colDefs2 = [
    {
        headerName: "",
        field: "checkbox_selection",
        checkboxSelection:true,
        width:60
    },
    {
        headerName: "",
        field: "base_routes_map",
        cellRenderer: (params)=>{
            return "<i class='icofont icofont-map-pins f20'></i>"
        },
        width:60
    },
    {
        headerName: "Truck No",
        field: "truck_no",
    },
    // {
    //     headerName: "Invoice No",
    //     field: "consignment_codes",
    // },
    {
        headerName: "Consignment Code",
        field: "consignment_codes",
    },
    {
        "headerName": "Base Route",
        field: "base_route_no",
    },
    {
        headerName: "Dealer Code",
        field: "consignee_code",
    },
    {
        headerName: "Dealer Name",
        field: "consignee_name",
    },
    // {
    //     headerName: "Source",
    //     field: "consigner_code",
    // },
    // {
    //     headerName: "Destination",
    //     field: "consignee_city",
    // },
    // {
    //     headerName: "Zone",
    //     field: "zone"
    // },
    // {
    //     headerName: "Invoice Time",
    //     field: "invoice_time",
    //     valueGetter: (params)=>{
    //         return getHyphenYYYYMMDDHHMMSS(params.data.invoice_time)
    //     }
    // },
    {
        headerName: "Trip End Time",
        field: "trip_end_time",
        valueGetter: (params)=>{
            return getHyphenYYYYMMDDHHMMSS(params.data.trip_end_time)
        }
    },
    {
        headerName: "Transporter Code",
        field: "transporter_code"
    },
    {
        headerName: "Transporter Name",
        field: "transporter_name",
    },
    // {
    //     headerName: "GPS Provider",
    //     field: "gps_provider",
    //     valueGetter: (params)=>{
    //         var label = params.data.gps_provider
    //         if(params.data.gps_provider == "AXESTRACK_SND"){label = "AXESTRACK"}
    //         return label
    //     }
    // },
    {
        headerName: "Truck Type",
        field: "",
        valueGetter:function(params){
            if(localStorage.getItem("email") && /enmovil\.in/.test(localStorage.getItem("email").trim().toLowerCase())){
                return params.data.truck_type
            }else{
                return params.data.vehicle_mode
            }
        }
    },
    // {
    //     headerName: "Total GPS Distance (Km)",
    //     field: "total_travelled_trip_dist_km",
    // },
    {
        headerName: "Expected Distance (KM)",
        field: "expected_distance",
    },
    {
        headerName: "Distance Travelled (KM)",
        field: "total_trip_dist_with_google_km",
        valueGetter: (params)=>{
            var total = Math.round(parseFloat(params.data.total_trip_dist_with_google_km));
            // if(params.data.missing_distance_km != undefined)
            // {
            //     total += Math.round(parseFloat(params.data.missing_distance_km))
            // }
            return total;
        }
    },
    {
        headerName: "Route Ignored",
        field: "route_ignored",
        valueGetter: (params)=>{
            if(params.data.route_ignored != undefined)
            {
                return "Yes";
            }
            else
            {
                return "No";
            }
        }
    },
    {
        headerName: "Ignored Reason",
        field: "",
        valueGetter: (params)=>{
            if(params.data.route_ignored == undefined)
            {
                return  ""
            }
            else if(params.data.force_close != undefined || params.data.force_close != "undefined" || params.data.force_close != "" )
            {
                if (params.data.force_close == 1){
                    return "Force Closed"
                }
               
            }
            var res=[]
            console.log(params.data.straight_lines,params.data.away_from_plant,params.data.not_reached_dealer,params.data.force_close,"227")

            if(params.data.straight_lines != undefined || params.data.straight_lines != "undefined" || params.data.straight_lines != "" )
            {
                if (params.data.straight_lines == 1){
                    res.push("Due to straight lines")
                }
               
            }

            // if(params.data.away_from_plant != undefined || params.data.away_from_plant != "undefined" || params.data.away_from_plant != "" )
            // {

            //     if (params.data.away_from_plant == 1){
            //         res.push("GPS Received after 5kms from plant location")
            //     }
               
            // }

            if(params.data.not_reached_dealer != undefined || params.data.not_reached_dealer != "undefined" || params.data.not_reached_dealer != ""  )
            {
                if (params.data.not_reached_dealer == 1){
                    res.push("No Reached Dealer Geofence")
                }
                
            }

            

            if (res.length==0){
                res.push("Trip distance is not in the range of 50 to 150%")
            }

            // console.log(res.join(","),"254")

            // if(params.data.straight_lines == 1 && params.data.route_ignored == 1)
            // {
            //     return "Due to straight lines"
            // }
            // else if(params.data.route_ignored != undefined)
            // {
            //     if(params.data.away_from_plant == 1)
            //     {
            //         return "GPS Received after 5kms from plant location";
            //     }
            //     else if(params.data.expected_distance*0.8 > params.data.total_travelled_trip_dist_km)
            //     {
            //         return "Trip Distance < 50% of MSIL Approved Distance";
            //     }
            //     else{
            //         return "Trip Distance > 150% of MSIL Approved Distance";
            //     }
            // }
            // else
            // {
            //     return "";
            // }
            return res.join(",")
        }
    },
    {
        headerName: "Straight lines",
        field: "no_of_straight_lines",
    },
]
if(localStorage.getItem("email") && /enmovil\.in/.test(localStorage.getItem("email").trim().toLowerCase())){
    colDefs2.push(
    {
        headerName: "Co2 Emission Standard(Kg)",
        field: "emission_kg_standard",
        width: 160,
        valueGetter:function(params){
            if(params.data.emission_kg_standard != undefined && params.data.emission_kg_standard != "" && params.data.emission_kg_standard != null){
                var returnValue = parseFloat(params.data.emission_kg_standard)
                var final_value = returnValue.toFixed(2)
                return final_value
            }
            
        }
    },
    {
        headerName: "Co2 Emission Actual(Kg)",
        field: "emission_kg_actual",
        width: 150,
        valueGetter:function(params){
            if(params.data.emission_kg_actual != undefined && params.data.emission_kg_actual != "" && params.data.emission_kg_actual != null && params.data.route_ignored == undefined){
                var returnValue = parseFloat(params.data.emission_kg_actual)
                var final_value = returnValue.toFixed(2)
                return final_value
            }
            
        }
    },
    {
        headerName: "Total Trip Payload (Tons)",
        field: "consignment_mass_tons",
        width: 180
    },
    )
}

var colDefs3 = [
    {
        headerName: "",
        field: "checkbox_selection",
        checkboxSelection:true,
        width:60,
        // cellRenderer: (params)=> {
        //     if(params.data.is_marked_base_route == 1)
        //     {
        //         return params.node.setSelected(true);
        //     }
        //     else{
        //         return params.node.setSelected(false);
        //     }
        // }
    },
    {
        headerName: "",
        field: "base_routes_map",
        cellRenderer: (params)=>{
            return "<i class='icofont icofont-map-pins f20'></i>"
        },
        width:60
    },
    {
        headerName: "MSIL Approved Route",
        field: "is_marked_base_route",
        valueGetter: (params)=>{
            if(params.data.is_marked_base_route == 1)
            {
                return "Yes"
            }
            else
            {
                return 'No'
            }
        }
    },
    {
        headerName: "Base Route",
        field: "base_route_no",
        valueGetter: (params)=>{
            return "Base Route "+params.data.base_route_no
        }
    },
    {
        headerName: "Source",
        field: "plant",
        valueGetter: (params)=>{
            return params.data.plant
        }
    },
    {
        headerName: "Destination",
        field: "consignee_city",
    },
    {
        headerName: "MSIL Approved Distance (KM)",
        field: "expected_distance",
    },
    {
        headerName: "Min. GPS Distance (KM)",
        field: "min_google_distance",
    },
    {
        headerName: "Max. GPS Distance (KM)",
        field: "max_google_distance",
    },
    {
        headerName: "Avg. GPS Distance (KM)",
        field: "avg_google_distance",
    },
    {
        headerName: "Median GPS Distance (KM)",
        field: "median_google_distance",
    },
    {
        headerName: "Google Distance (KM)",
        field: "google_distance_via_waypoints"
    },
    {
        headerName: "No. of Trips",
        field: "no_of_followers",
    },
    {
        headerName: "Route Trip %",
        field: "no_of_followers_percentage",
    }
]

var colDefs5 = [
    // {
    //     headerName: "",
    //     field: "checkbox_selection",
    //     checkboxSelection:true,
    //     width:60
    // },
    // {
    //     headerName: "",
    //     field: "map_routes",
    //     cellRenderer: (params)=>{
    //         return "<i class='icofont icofont-map-pins f20'></i>"
    //     },
    //     width:60
    // },
    {
        headerName: "Truck No",
        field: "truck_no",
    },    
    {
        headerName: "Consignment Code",
        field: "consignment_code",
    },
    // {
    //     "headerName": "Base Route",
    //     field: "base_route_no",
    // },
    {
        headerName: "Dealer Code",
        field: "consignee_code",
    },
    // {
    //     headerName: "Dealer Name",
    //     field: "consignee_name",
    // },
    // {
    //     headerName: "Source",
    //     field: "consigner_code",
    // },
    // {
    //     headerName: "Destination",
    //     field: "consignee_city",
    // },   
    {
        headerName: "Invoice Time",
        field: "invoice_date",
        valueGetter: (params)=>{
            return getHyphenYYYYMMDDHHMMSS(params.data.invoice_date)
        }
    },
    {
        headerName: "Trip End Time",
        field: "trip_end_time",
        valueGetter: (params)=>{
            return getHyphenYYYYMMDDHHMMSS(params.data.trip_end_time)
        }
    },
    {
        headerName: "Transporter Code",
        field: "transporter_code"
    },
    {
        headerName: "Transporter Name",
        field: "transporter_name",
    },    
    {
        headerName: "Truck Type",
        field: "vehicle_mode"
    },
    // {
    //     headerName: "Total GPS Distance (Km)",
    //     field: "total_travelled_trip_dist_km",
    // },
    // {
    //     headerName: "Distance Travelled (KM)",
    //     field: "total_trip_dist_with_google_km",
    //     valueGetter: (params)=>{
    //         var total = Math.round(parseFloat(params.data.total_trip_dist_with_google_km));
    //         // if(params.data.missing_distance_km != undefined)
    //         // {
    //         //     total += Math.round(parseFloat(params.data.missing_distance_km))
    //         // }
    //         return total;
    //     }
    // },
    // {
    //     headerName: "Route Ignored",
    //     field: "route_ignored",
    //     valueGetter: (params)=>{
    //         if(params.data.route_ignored != undefined)
    //         {
    //             return "Yes";
    //         }
    //         else
    //         {
    //             return "No";
    //         }
    //     }
    // },
    {
        headerName: "Reason",
        field: "reason",
        // valueGetter: (params)=>{
        //     if(params.data.club_load == 1 && params.data.route_ignored == 1)
        //     {
        //         return  "Club Load"
        //     }
        //     if(params.data.straight_lines == 1 && params.data.route_ignored == 1)
        //     {
        //         return "Due to straight lines"
        //     }
        //     else if(params.data.route_ignored != undefined)
        //     {
        //         if(params.data.away_from_plant == 1)
        //         {
        //             return "Away from Plant";
        //         }
        //         else if(params.data.expected_distance*0.8 > params.data.total_travelled_trip_dist_km)
        //         {
        //             return "Trip Distance < 80% of MSIL Approved Distance";
        //         }
        //         else{
        //             return "Trip Distance > 120% of MSIL Approved Distance";
        //         }
        //     }
        //     else
        //     {
        //         return "";
        //     }
        // }
    },  
]

export default class NewDeviationRoutes extends Component {

  state = {
            loadshow:'show-m',
            overly:'show-m',
			modules: AllModules,
        	alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
                //editable: true,
                autoHeight:true,
				resizable: true,
                width: 130
			},
			rowData: [],
            headerHeight: 60,
            rowHeight:50,
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
            showToolPanel: false,
            griddata:[],
            routeRowData:{},
			rowSelection: "single" ,
			context: { componentParent: this },
            frameworkComponents: {
                // MyHeaderComponent: MyHeaderComponent,
                // Showtolls:Showtolls,
                // ShowTollsList:ShowTollsList,
                // ApprovalDeviationRoute:ApprovalDeviationRoute,
                // DateDeviationRoute:DateDeviationRoute,
                // ViewDeviationRouteData:ViewDeviationRouteData,
                // ShowChokePoints:ShowChokePoints
            },
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            pageTitle:"Summary",
            originaldata:"",
            orignalresponse:"",
            mapinfo:"",
            originoption:{"value":"","label":"Select Origin"},
            destinationoption:{"value":"","label":"Select Destination"},
            destinationarr:[],
            selectedorigin:'',
            selecteddestination:'',
            columnDefs: colDefs1,
            checkedinputs:[],
            uncheckedinputs:[],
            noofroutes:3,
            tolls:[],
            tollshow:false,
            plantcoords:"",
            plantname:"",
            sliderTranslate:"",
            viewtolls:[],
            tollrowid:"",
            routeid:"",
            routeapprovestatus:"",
            open:false,
            route_reason:"",
            gridapi: [],
            viewroutedata:[],
            sliderRouteTranslate:"",
            sliderRouteTranslate1:"",
            sliderChokeTranslate:"",
            chokepoints:[],
            chokepointdata:[],
            originoptions:[],
            btn1: "btn-default",
            btn2: "btn-default",
            btn3: "btn-default",
            btn4: "btn-default",
            btn5 : "btn-default",
            complete_analysis: [],
            base_routes_anlsysis: [],
            transporters_analysis: [],
            complete_trips: [],
            breaks_list: [],
            route_coords: [],
            gridHeight: "160px",
            "mapfor": "",
            destinationOptions: [],
            defaultTrips: [],
            selectedRoutes: [],
            dealersList : [],
            route_coords1: [],
            breaks_list1: [],
            tripsGridApi: {},
            defaultCompleteTrips: [],
            // hideSummaryTab: false,
            lspusers_options: [],
            truck_types: [],
            period_types: [],
            selected_provider: {"label": "ALL", "value": "ALL"},
            selected_truck_type: {"label": "ALL", "value": "ALL"},
            selected_period_type: [{"label": "ALL", "value": "ALL"}],
            ignoredRouteSubmit : 0,
            ignoredRoutes: [],
            changedWaypoints: [],
            addNewWayPoints: [],
            transporterCounter : true,
            "highestBaseRouteCount": 0,
            "highestBaseRouteName" :"",
            "total_optimized_routes":0,
            total_emission_saved:0,
            'noOfTransporters' : "",
            'total_no_trips_travelled' : 0,
            'total_valid_trips' : 0,
            'fetch_next_data' : 'show-n',
            consignment_codes : [],
            next_data_count : 0,
            final_actual_count :0,
            final_standard_count :0,
            final_difference_count :0,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent",
                    statusPanelParams: {
                        // possible values are: 'count', 'sum', 'min', 'max', 'avg'
                        aggFuncs: ['sum', 'avg', "count", "min", "max"],
                      }, }
                ],
            },
  };

	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
	
    componentDidMount(){
        this.setState({
            overly: "show-m",
            loadshow: "show-m"
        })
       // console.log("this.macth ", this.props.location.search)
        var s = this.props.location.search;
        var consigner_code = "";
        if(s != "")
        {
            var spl = s.split("?")
            var s1 = spl[1].split("&")
            var sorce = s1[0].split("=")
            var source = sorce[1];
            consigner_code = source;
            var srcname = s1[1].split("=")
            var sourcename = srcname[1].replace(/%20/g," ")

            var dest = s1[2].split("=")
            var destination = dest[1].replace(/%20/g," ")
            
            var period_type = s1[3].split("=")
            period_type = period_type[1].replace(/%20/g," ")

            var periodTypesArray = period_type.split(',').map(value => ({
                label: value,
                value: value
              }));
            console.log(periodTypesArray,'period_type--')
            // var gps_provider = s1[4].split("=")
            // gps_provider = gps_provider[1].replace(/%20/g," ")
            // if(gps_provider == "AXESTRACK_SND"){
            //     gps_provider = {"value":gps_provider,"label": "AXESTRACK"}
            // }
            // else
            // {
            //     gps_provider = {"value":gps_provider,"label": gps_provider}
            // }

            var truck_type = s1[5].split("=")
            truck_type = truck_type[1].replace(/%20/g," ")
            //var fromparent = this.props.match.params
            var sourceopt = source
            var sourcename = sourcename
            var destinationopt = destination
            
            this.setState({
                originoption: {"value":sourceopt,"label":sourcename},
                destinationoption: {"value":destination,"label":destination},
                selected_period_type: periodTypesArray,
                // selected_provider: gps_provider,
                selected_truck_type: {"value":truck_type,"label":truck_type},
            })
            var p = {
                plant:sourceopt
            }
            // console.log(p,"666")
            // redirectURL.post("/consignments/plantcoordinates", p)
            // .then((response) => {
            //     console.log("Ress ", response.data)
            //     var plantdata = response.data
            //     //console.log("plantcoords ", JSON.parse(plantdata[0].plant_coordinates))
            //     var plantcoords = JSON.parse(plantdata[0].plant_coordinates)
            //     this.setState({
            //         plantcoords:{coord:plantcoords,name:this.state.originoption.label},
            //         plantname:this.state.originoption.label,
            //     })
            // })
            var valueCollection = []
            valueCollection = periodTypesArray.map(ele =>ele.value)
            // console.log(valueCollection,'valueSelected')
            var formdata = {
                consigner_code: sourceopt,
                consignee_city: destinationopt,
                // gps_provider: gps_provider.value,
                period_type: JSON.stringify(valueCollection),
                truck_type: truck_type,
            }
            this.setState({
                loadshow: "show-m",
                overly: "show-m"
            })
            console.log(formdata,"688")
            this.onLoadFormRequest(formdata)
        }
        //this.onClickShowRoute()
		// console.log("path ", this.props.match.path)
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        this.setState({eventAction: eventAction});
        redirectURL.post("/consignments/deviationconsigneesnew")
        .then((response) => {
            //console.log("Consignees ", response.data)
           var records = response.data;
            var destinationarr = []
            if(records.length > 0)
            {
                records.map((item) => {
                    if(item != "")
                    {
                        destinationarr.push(item)
                    }
                })
            }
            this.setState({
                destinationarr:destinationarr
            })
            // console.log("check321")
        })

        var map_plants = {"B": "TVP Bangalore", "H":"Haryana", "G": "Gujarat", "I": "Siliguri", "N": "TVP Nagpur","BP":"Bidadi","D":"Dobaspet"}
        redirectURL.post("/dashboard/getSourceFromBaseRoutes")
        .then((response) => {
            //console.log("Ress ", response.data)
            var plantdata = response.data;
            plantdata=plantdata.filter((each)=>each!="F")
            plantdata=plantdata.filter((each)=>each!="M")
            console.log(plantdata.includes("H"),"791")
            if (plantdata.includes("H")==false){
             plantdata.push("H")
            }
            // plantdata.push("H")
            var originoptions = [];
            if(plantdata.length > 0)
            {
                plantdata.map((item) => {
                    originoptions.push({
                        value:item,
                        label: map_plants[item]
                    })
                })
            }
            redirectURL.post("dashboard/getDestinationFromBaseRoutes", {"consigner_code": consigner_code}).then((response1)=>{
                var items=[];
                var destinationarr = response1.data;
                var check = destinationarr.map((item) =>{
                    items.push({
                        value:item,label:item
                    })
                })
                redirectURL.post("dashboard/getInitialDataForBaseRouteSummary", {}).then((response2)=>{
                    // console.log(response2.data)
                    var lspusers_options = [];
                    var truck_types = [];
                    var period_types_options = [];
                    // var period_types = sortPeriodTypes(response2.data.period_types);
                    var period_types = response2.data.period_types;
                    // console.log(period_types, "period_types")
                    if(localStorage.getItem("email") && /enmovil\.in/.test(localStorage.getItem("email").trim().toLowerCase())){
                        // console.log(localStorage.getItem("username") === "Nanda Kishore")
                        // period_types = period_types.filter((e) => {
                        //     console.log(e.includes("2023"),"check-864")
                        //     console.log(["Jan-2023", "Feb-2023", "Mar-2023"].includes(e))
                        //     if(e.includes("2023") && ["Jan-2023", "Feb-2023", "Mar-2023"].includes(e) == false){
                        //         return e
                        //     }
                        // })
                        var period_types = ["Apr-2022","May-2022","Jun-2022","Jul-2022","Aug-2022","Sep-2022","Oct-2022","Nov-2022","Dec-2022","Jan-2023","Feb-2023","Mar-2023","FY-2022Q1","FY-2022Q2","FY-2022Q3","FY-2022Q4","Apr-2023","May-2023","Jun-2023","Jul-2023","Aug-2023","Sep-2023","Oct-2023","Nov-2023","Dec-2023","FY-2023Q1","FY-2023Q2","FY-2023Q3"]
                    }
                    response2.data.lsp_users.map((e)=>{
                        if(e != "NA")
                        {
                            var label = e
                            if(e == "AXESTRACK_SND"){label = "AXESTRACK"}
                            lspusers_options.push({"label": label, "value": e})
                        }
                    })
                    response2.data.truck_types.map((e)=>{
                        truck_types.push({"label": e, "value": e})
                    })
                    period_types.map((e)=>{
                        period_types_options.push({"label": e, "value": e})
                    })
                    // console.log(period_types, "period_types options")
                    // console.log("check1")
                    this.setState({
                        originoptions: originoptions,
                        destinationOptions: items,
                        lspusers_options: lspusers_options,
                        truck_types: truck_types,
                        period_types: period_types_options,                        
                    })
                    if(s === ""){
                        this.setState({
                            overly: "show-n",
                            loadshow: "show-n"
                        })
                    }
                    // console.log("check987")
                })
            })
        })
	}   
	
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}
	onGridReady = params => {
		this.gridApi = params.api;
        this.setState({
            gridapi : params.api
        })
        this.gridColumnApi = params.columnApi;
        var sort = [
			{
			  colId: 'no_of_followers_percentage',
			  sort: 'desc',
			},
		  ];
		this.gridApi.setSortModel(sort);
        this.gridApi.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode( (node) => {
            // console.log("node here ",node)
            // node.setSelected(true);
         
        });
        
	};

    onGridReady1 = params => {
		this.gridApi1 = params.api;
        // console.log("this.gridApi", this.gridApi1, "\n params", params.api)
        this.setState({
            tripsGridApi: params.api
        })
        this.gridColumnApi1 = params.columnApi;
        if(this.state.btn3 == "btn-info"){
            var sort = [
            	{
            	  colId: 'no_of_followers_percentage',
            	  sort: 'desc',
            	},
              ];
            try{
                this.gridApi.setSortModel(sort);
            }
            catch(e){
            }
        }
        
	};
	

	onGridState = () =>{
		//  console.log(this.gridApi);		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();
		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
        //
    }

    onGridState1 = () =>{
        // console.log(this.gridApi);       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi1.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi1.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi1.getPivotColumns();                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi1.getFilterModel();
          this.gridColumnApi1.setFilterModel(window.filterState);
   }

	changeOriginHandler = originoption => {
        this.setState(
            { originoption }
        );
        // redirectURL.post("dashboard/getDestinationFromBaseRoutes", {"consigner_code": originoption.value}).then((response)=>{
        //     var items=[];
        //     var destinationarr = response.data;
        //     var check = destinationarr.map((item) =>{
        //         items.push({
        //             value:item,label:item 
        //         })
        //     })
        //     Promise.all([check]).then(()=>{
        //         this.setState({
        //             destinationOptions: items
        //         })
        //     })
            
        // })
    }

    changeDestinationHandler = destinationoption => {
        // var formdata = {}
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['consigner_code'] = this.state.originoption.value
        // }
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['consignee_city'] = destinationoption.value
        // }
        // redirectURL.post("dashboard/getInitialDataForBaseRouteSummary", formdata).then((response2)=>{
        //     var lspusers_options = [];
        //     var truck_types = [];
        //     var period_types_options = [];
        //     response2.data.lsp_users.map((e)=>{
        //         if(e != "NA")
        //         {
        //             var label = e
        //             if(e == "AXESTRACK_SND"){label = "AXESTRACK"}
        //             lspusers_options.push({"label": label, "value": e})
        //         }
        //     })
        //     response2.data.truck_types.map((e)=>{
        //         truck_types.push({"label": e, "value": e})
        //     })
        //     var period_types = sortPeriodTypes(response2.data.period_types);
        //     period_types.map((e)=>{
        //         period_types_options.push({"label": e, "value": e})
        //     })
        //     this.setState({
        //         destinationoption,
        //         // originoptions: originoptions,
        //         // destinationOptions: items,
        //         lspusers_options: lspusers_options,
        //         truck_types: truck_types,
        //         period_types: period_types_options,
        //         // overly: "show-n",
        //         // loadshow: "show-n"
        //     })
        // })
        this.setState(
            { destinationoption }
        );
    
    }
    
    changeGpsProviderHandler = selected_provider => {
        // var formdata = {}
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['consigner_code'] = this.state.originoption.value
        // }
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['consignee_city'] = this.state.destinationoption.value
        // }
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['gps_provider'] = selected_provider.value
        // }
        // redirectURL.post("dashboard/getInitialDataForBaseRouteSummary", formdata).then((response2)=>{
        //     var lspusers_options = [];
        //     var truck_types = [];
        //     var period_types_options = [];
        //     // response2.data.lsp_users.map((e)=>{
        //     //     if(e != "NA")
        //     //     {
        //     //         var label = e
        //     //         if(e == "AXESTRACK_SND"){label = "AXESTRACK"}
        //     //         lspusers_options.push({"label": label, "value": e})
        //     //     }
        //     // })
        //     response2.data.truck_types.map((e)=>{
        //         truck_types.push({"label": e, "value": e})
        //     })
        //     var period_types = sortPeriodTypes(response2.data.period_types);
        //     period_types.map((e)=>{
        //         period_types_options.push({"label": e, "value": e})
        //     })
            this.setState({
                selected_provider: selected_provider,
                // originoptions: originoptions,
                // destinationOptions: items,
                // lspusers_options: lspusers_options,
                // truck_types: truck_types,
                // period_types: period_types_options,
                // overly: "show-n",
                // loadshow: "show-n"
            })
        // })
    }
    changePeriodTypeHandlerforSingleSelect = (event) =>{
        console.log(event,"1237")
        this.setState({
            selected_period_type : {value:event.value,label:event.value}
        })
    }
    changePeriodTypeHandler = selected_period_type => {
        var selectedPeriodValues;
        if(selected_period_type != "" && selected_period_type != null && selected_period_type != undefined){
         selectedPeriodValues = selected_period_type.map(option => option);
            console.log(selectedPeriodValues,'selectedPeriodValues')
             this.setState({
                selected_period_type: selectedPeriodValues,
                // truck_types: truck_types,
            })
        }else {
            this.setState({
                selected_period_type: "",
               
            })
        }
        // })
    }

    changeTruckTypeHandler = selected_truck_type => {
        this.setState({
            selected_truck_type: selected_truck_type,
        })
    }

    getIgnoredRoutes = (event) =>{
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        try{
            var tcheck = document.getElementById("tollcheck")
            if(tcheck.checked == true)
            {
                tcheck.checked = false;
            }
        }
        catch(e){

        }
		this.setState({
            loadshow:'show-m',         
            overly:'show-m',         
            tolllist:[]
        });

        if(this.state.originoption.value != "" && this.state.destinationoption.value != "")
        {
            var valueCollection = []
            if(Array.isArray(this.state.selected_period_type) == true){
                valueCollection = this.state.selected_period_type.map(ele =>ele.value)
            }else{
                valueCollection = [this.state.selected_period_type.value]
            }
            
            console.log(valueCollection,'valuecollection')
            var formdata = {
                consigner_code:this.state.originoption.value,
                consignee_city:this.state.destinationoption.value,
                // gps_provider: this.state.selected_provider.value,
                period_type: JSON.stringify(valueCollection),
                truck_type: this.state.selected_truck_type.value,
            }
            console.log('formdata',formdata)
            // this.onLoadFormRequest(formdata)
            redirectURL.post("/dashboard/getIgnoredRoutes",formdata).then((response) => {
                // console.log("response",response.data);
                // let responseData = response.data;
                // let truckList = []
                // responseData.trucksList.map(e => truckList.push({ value: e, label: e }))
                this.setState({
                    ignoredRoutes: response.data,
                    rowData: response.data,
                    overly: "show-n",
                    loadshow : "show-n",
                
                })
            }).catch((e) => {
                console.log("error", e);
            })
        }
        else
        {
            this.setState({
                basicTitle: "Please select Consigner and Consignee City",
                basicType: "warning",
                show: true,
                overly: "show-n",
                loadshow: "show-n"
            })
        }

    }

	formHandler = (event) =>{
        event.preventDefault();
       // loadchecked()
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        try{
            var tcheck = document.getElementById("tollcheck")
            if(tcheck.checked == true)
            {
                tcheck.checked = false;
            }
        }
        catch(e){

        }
		this.setState({
            loadshow:'show-m',
            overly: 'show-m',           
            tolllist:[]
        });
        if(this.state.originoption.value != "" && this.state.destinationoption.value != "")
        {

            var valueCollection = []
            if(Array.isArray(this.state.selected_period_type) == true){
                valueCollection = this.state.selected_period_type.map(ele =>ele.value)
            }else{
                valueCollection = [this.state.selected_period_type.value]
            }
            
            console.log(valueCollection,'valuecollection')
            var formdata = {
                consigner_code:this.state.originoption.value,
                consignee_city:this.state.destinationoption.value,
                // gps_provider: this.state.selected_provider.value,
                period_type: JSON.stringify(valueCollection),
                truck_type:(this.state.selected_truck_type.value),
            }
            console.log(formdata,'formdata123')
            this.onLoadFormRequest(formdata)
        }
        else
        {
            this.setState({
                basicTitle: "Please select Consigner and Consignee City",
                basicType: "warning",
                show: true,
                overly: "show-n",
                loadshow: "show-n"
            })
        }
		
    }
    
    onLoadFormRequest = async(formdata) =>{ 
        
        
        // if (formdata.consigner_code==="H"){
        //     formdata={...formdata,consigner_code:["F","M"]}
        console.log("formData",formdata)
        // this.setState({
        //     loadshow: "show-m",
        //     overly: "show-m"
        // })
        if(!["", undefined].includes(formdata.next_data_count)){
            await redirectURL.post("dashboard/getCompleteTripsDataForBaseRoutes", formdata).then(async (response)=>{
                console.log('response.data',response.data)
                const {complete_trips,defaultCompleteTrips,defaultTrips} =  this.state
               var data = [...defaultCompleteTrips,...response.data.data];
            //    let consignment_codes = response.data.consignment_codes
                console.log(data, "complete trips")
               

                this.setState({
                   
                    complete_trips: data,
                    defaultCompleteTrips: data,
                    defaultTrips: data,
                    btn1: "btn-info",
                    btn2: "btn-info",
                    btn3: "btn-default",
                    btn4: "btn-default",
                    btn5: "btn-default",
                    loadshow: "show-n",
                    overly: "show-n",
                    // hideSummaryTab: false,
                    gridHeight: "160px",
                    pageTitle: "Summary",                    
                    // 'total_no_trips_travelled': noOfTripsTravelled,
                    // 'total_valid_trips': validTrips,
                    fetch_next_data : 'show-m',
                })
            })

        }else{
              // console.log("formData1144",formdata)
      await redirectURL.post("dashboard/getBaseRouteAnalysisReport", formdata).then(async (response)=>{
            console.log(response.data,"1183")
            if(response.data.status == "Failure")
            {
                this.setState({
                   "highestBaseRouteCount": 0,
                   "highestBaseRouteName" :"",
                   "total_optimized_routes":0,
                   total_emission_saved:0,
                   'noOfTransporters' : "",
                   'total_no_trips_travelled' : 0,
                   'total_valid_trips' : 0,
                    complete_analysis: [],
                    base_routes_anlsysis: [],
                    transporters_analysis: [],
                    complete_trips: [],
                    rowData: [],
                    btn1: "btn-info",
                    btn2: "btn-info",
                    btn3: "btn-default",
                    btn4: "btn-default",
                    btn5: "btn-default",
                    columnDefs: colDefs1,
                    loadshow: "show-n",
                    overly: "show-n",
                    final_actual_count :0,
                    final_standard_count :0,
                    final_difference_count :0,
                })
            }
            else
            {
                var complete_analysis = JSON.parse(response.data.complete_analysis);
                // console.log("complete_analysis",complete_analysis)
                var base_routes_anlsysis = JSON.parse(response.data.base_routes_anlsysis);
                var transporters_analysis = JSON.parse(response.data.transporters_analysis);
                // console.log("transporters_analysis",base_routes_anlsysis)
                let leastAvgDistance = 0
                let baseRouteforCounter = 0
                base_routes_anlsysis.map(e =>{
                    if(leastAvgDistance > e.avg_google_distance ){
                    }else{
                        leastAvgDistance = e.avg_google_distance
                        baseRouteforCounter = e.base_route_no
                    }
                })
                // console.log('leastAvgDistance',transporters_analysis,'baseRouteforCounter',base_routes_anlsysis)
                // for counters the calc data start
                var totalCountTransporter = transporters_analysis[(transporters_analysis.length)-1]
                var hightCount = 0
                var baseCount = base_routes_anlsysis.length
                var baseName = '';
                var highest_base_routes = [];
                for(let i in totalCountTransporter){
                    if(i.includes("Base")){
                        let hCount = totalCountTransporter[i]
                        if(hightCount <= hCount ){
                            hightCount = hCount
                            baseName = i
                            highest_base_routes.push({"base_route_no": i.split(" ")[2], "no_of_trips": hightCount})
                        }
                    }  
                }
                // console.log('highest_base_routes',highest_base_routes)
                // below loop is when there are multiple same base route no then we need to consider the route which has less avg_google_distance
                if(highest_base_routes.length > 1)
                {
                    var hightCount = 0
                    var baseName = '';
                    highest_base_routes.map((k)=>{
                        var filtered_route = base_routes_anlsysis.filter(e => e.base_route_no == parseInt(k.base_route_no));
                    
                        let hCount = filtered_route[0]['avg_google_distance']
                        if(hightCount > hCount || hightCount == 0){
                            console.log('h1208', hCount)
                            hightCount = k.no_of_trips
                            baseName = "Base Route "+k.base_route_no
                        }
                    })
                }
                
                // counters data end
              await redirectURL.post("dashboard/getCompleteTripsDataForBaseRoutes", formdata).then(async (response)=>{
                    console.log('response.data',response.data)
                   var data = response.data.data;
                   let consignment_codes = response.data.consignment_codes
                    console.log(data, "complete trips")
                    var final_emission_count = 0
                    var emission_kg_standard = 0
                    var emission_kg_actual = 0
                    var emission_difference = 0
                    await data.map((e)=>{
                        var filtereddata =  base_routes_anlsysis.filter(k =>{
                            if(k.consignment_codes.filter(value => e.consignment_codes.includes(value)).length)
                            {
                                return true;
                            }
                        })
                        if(filtereddata.length)
                        {
                            e.base_route_no = "Base Route "+filtereddata[0]['base_route_no']
                        }
                        if(![undefined,"undefined",null,""].includes(e.emission_kg_standard) && e.route_ignored == undefined){
                            emission_kg_standard = emission_kg_standard + e.emission_kg_standard
                        }
                        if(![undefined,"undefined",null,""].includes(e.emission_kg_actual) && e.route_ignored == undefined){
                            // console.log(e.emission_kg_actual,)
                            emission_kg_actual = emission_kg_actual + e.emission_kg_actual
                        } 
                        // console.log(emission_kg_actual,"1449")
                        emission_difference = emission_kg_actual - emission_kg_standard 
                        final_emission_count = final_emission_count + emission_difference
                    })
                    // console.log(emission_kg_actual,"1452")
                    let noOfTripsTravelled = []
                    let validTrips = []
                    try{
                        noOfTripsTravelled = complete_analysis[0].no_of_trips_travelled
                        validTrips = complete_analysis[0].valid_trips
                    }catch(e){
                        console.log("e",e)                   
                    }
                    // console.log(base_routes_anlsysis,transporters_analysis,data,complete_analysis,"1280")

                    this.setState({
                        base_routes_anlsysis: base_routes_anlsysis,
                        transporters_analysis: transporters_analysis,
                        complete_trips: data,
                        defaultCompleteTrips: data,
                        defaultTrips: data,
                        rowData: complete_analysis,
                        columnDefs: colDefs1,
                        btn1: "btn-info",
                        btn2: "btn-info",
                        btn3: "btn-default",
                        btn4: "btn-default",
                        btn5: "btn-default",
                        loadshow: "show-n",
                        overly: "show-n",
                        // hideSummaryTab: false,
                        gridHeight: "160px",
                        pageTitle: "Summary",
                        complete_analysis: complete_analysis,
                        "highestBaseRouteCount": hightCount,
                        "highestBaseRouteName" :baseName,
                        "total_optimized_routes":baseCount,
                        'noOfTransporters' : (transporters_analysis.length)-1,
                        'total_no_trips_travelled': noOfTripsTravelled,
                        'total_valid_trips': validTrips,
                        fetch_next_data : 'show-m',
                        total_emission_saved:final_emission_count,
                        final_actual_count :parseFloat(emission_kg_actual)/1000,
                        final_standard_count :parseFloat(emission_kg_standard)/1000,
                        final_difference_count :final_emission_count,
                        consignment_codes
                    })
                })
            }
            
        })
        }
      
    }

    onClickGetNextData = () =>{
        this.setState(prevState =>({
            next_data_count : prevState.next_data_count + 1000
        }))
        setTimeout(() =>{
            const {complete_analysis, base_routes_anlsysis,transporters_analysis,consignment_codes,
                defaultCompleteTrips, next_data_count} = this.state
            
            const formData = {
                consignment_codes,
                defaultCompleteTrips : defaultCompleteTrips[defaultCompleteTrips.length -1]._id, 
                next_data_count          
            }
            this.onLoadFormRequest(formData)
        }, 300)

        
    }

	origins = () =>{
        var items=[]
        items.push(         
            {value:"F", label:"Haryana"},
            {value:"G", label:"Gujarat"},
            {value:"F", label:"Gurgaon"},
            {value:"N", label:"TVP Nagpur"},
            {value:"I", label:"Silguri"},
            {value:"B", label:"TVP Banglore"},
            {value:"D", label:"Dobaspet"}
            
        );
        return items;
    }

    destinations = () =>{
        var items=[]
        if(this.state.destinationarr.length > 0)
        {
            var destinationarr = this.state.destinationarr;
            destinationarr.map((item) =>{
                items.push({
                   value:item,label:item 
                })
            })
        }
        return items;
    }


    onCloseUploadDiv = () => {
		this.setState({
			sliderTranslate:"",
            sliderRouteTranslate:'',
            sliderRouteTranslate1:'',
            sliderChokeTranslate:"",
			showDiv:'show-n',
			overly:'show-n'
		});
    }
    

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            sliderTranslate:'',
            sliderRouteTranslate:'',
            sliderRouteTranslate1:'',
            sliderChokeTranslate:""
		});
		
    }

    changeHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]:value
        });
    }

    onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
	   this.setState({ open: false });
     };
     
    onClickViewRouteData = (params) => {
        // console.log("Parmas ", params.colDef.colId)
        this.setState({
            loadshow:'show-m'
        })
        var parameters = {
            reference_route : params.value,
            email:localStorage.getItem("email"),
        }
        redirectURL.post("/consignments/newdeviationroutedata", parameters)
        .then((response) => {
            var records = response.data.records;
           // console.log("records ",records)
            if(records.length > 0)
            {
                this.setState({
                    sliderRouteTranslate:"slider-translate-50p",
                    viewroutedata:records,
                    overly:"show-m",
                    loadshow:'show-n'
                })
            }
            else
            {
                this.setState({
                    sliderRouteTranslate:"",
                    viewroutedata:records,
                    overly:"show-n",
                    loadshow:'show-n',
                    show:true,
                    basicTitle:"No data found.",
                    basicType:"danger"
                })
            }
            
        })
    }

    onClickTab = (tab)=> {
        var btns_list = ["bnt1", "btn2", "btn3", "btn4", 'btn5']
        // var to_update = {hideSummaryTab: false}
        var to_update = {}
        btns_list.map((e)=>{
            to_update[e] = (e != tab)?"btn-default":"btn-info";
        })
        if(tab == "btn1"){
            to_update["columnDefs"] = colDefs1;
            // to_update['pageTitle'] = "Summary";
            to_update['rowData'] = this.state.complete_analysis;
            to_update['complete_trips'] = this.state.defaultCompleteTrips;
            to_update['gridHeight'] = "160px";
            to_update['btn1'] = "btn-info"
            to_update['btn2'] = "btn-default"
            to_update['btn3'] = "btn-default"
            to_update['btn4'] = "btn-default"
            to_update['btn5'] = 'btn-default'
            // to_update['hideIgnoredTab'] = false;
            to_update['hideIgnoredTab'] = true;
            to_update['ignoredRouteSubmit'] = 0
            to_update['transporterCounter'] = true
            to_update['hideSummaryTab'] = true;


            try{
                this.gridApi1.deselectAll();
            }
            catch(e){}
            
            this.setState(to_update)

        }
        else if(tab == "btn2"){
            to_update["columnDefs"] = colDefs2
            to_update['pageTitle'] = "All Trips"
            to_update['complete_trips'] = this.state.defaultCompleteTrips;
            to_update['btn1'] = "btn-default"
            to_update['btn2'] = "btn-info"
            to_update['btn3'] = "btn-default"
            to_update['btn4'] = "btn-default"
            to_update['btn5'] = 'btn-default'
            to_update['gridHeight'] = "460px";
            to_update['hideIgnoredTab'] = false;
            to_update['ignoredRouteSubmit'] = 0
            to_update['transporterCounter'] = true
            to_update['hideSummaryTab'] = true;


            this.setState(to_update)
        }
        else if(tab == "btn3"){
            to_update["columnDefs"] = colDefs3
            to_update['pageTitle'] = "Base Routes"
            to_update['complete_trips'] = this.state.base_routes_anlsysis;
            to_update['btn1'] = "btn-default"
            to_update['btn2'] = "btn-default"
            to_update['btn3'] = "btn-info"
            to_update['btn4'] = "btn-default"
            to_update['btn5'] = 'btn-default'
            to_update['gridHeight'] = "460px";
            to_update['hideSummaryTab'] = true;
            to_update['hideIgnoredTab'] = false;
            to_update['ignoredRouteSubmit'] = 0
            to_update['transporterCounter'] = true

            try{
                this.gridApi1.deselectAll();
            }
            catch(e){}
            this.setState(to_update)

        }
        else if(tab == "btn4"){
            // console.log("btn4 clicked",this.state.transporters_analysis[0])
            var colDefs4 = [
                {
                    headerName: "Transporter Name",
                    field: "transporter_name",
                },
            ]
            try{
                Object.keys(this.state.transporters_analysis[0]).map((e)=>{
                    if(colDefs4.filter(k => k.headerName == e).length == 0 && e != "transporter_name"){
                        colDefs4.push({
                            "headerName": e,
                            "field": e
                        })
                    }
                })
                to_update["columnDefs"] = colDefs4
                to_update['pageTitle'] = "Transporters Info"
                to_update['rowData'] = this.state.transporters_analysis;
                to_update['btn1'] = "btn-default"
                to_update['btn2'] = "btn-default"
                to_update['btn3'] = "btn-default"
                to_update['btn4'] = "btn-info"
                to_update['btn5'] = 'btn-default'
                to_update['gridHeight'] = "460px";
                to_update['hideIgnoredTab'] = false;
                to_update['ignoredRouteSubmit'] = 0
                to_update['transporterCounter'] = true
                to_update['hideSummaryTab'] = false
                // console.log("to_update",to_update)
                this.setState(to_update)
            }
            catch(e){
                console.log("error",e)
            }
        }
        else if(tab == 'btn5'){
            
            to_update['ignoredRouteSubmit'] = 1;
            to_update['pageTitle'] = "Ignored Routes";
            to_update["columnDefs"] = colDefs5;
            to_update['gridHeight'] = "460px";
            to_update['hideSummaryTab'] = false;
            to_update['btn1'] = "btn-default"
            to_update['btn2'] = "btn-default"
            to_update['btn3'] = "btn-default"
            to_update['btn4'] = "btn-default"
            to_update['btn5'] = "btn-info"
            to_update['transporterCounter'] = false
            to_update['rowData'] = this.state.ignoredRoutes;
            this.setState(to_update)
            if(this.state.ignoredRoutes.length === 0) alert("Please Submit Details to Fetch Ignored Routes")
        }
    }
    
    onCellClicked=async (e)=>{
        if(e.colDef.field == "map_routes")
        {
            this.setState({
                overly :'show-m',
                loadshow : 'show-m'
            })
            var consignment_code = e.data.consignment_codes
            var gate_out_time = moment.parseZone(new Date(e.data.gate_out_time)).format("YYYY-MM-DD HH:mm:ss")
            var trip_end_time = moment.parseZone(new Date(e.data.trip_end_time)).format("YYYY-MM-DD HH:mm:ss")
            var formdata = {
                consignee_code:e.data.consignee_code,
                consignment_code:consignment_code[0],
                gate_out_time :gate_out_time,
                screen:"consignment",
                seconddate:trip_end_time,
                truck_no:e.data.truck_no
            }
            console.log(formdata,"1642")
            await redirectURL.post("/consignments/maproutes", formdata,{
                headers:{
                    'content-type':'application/json'
                }
            }).then((response)=>{
                console.log(response.data,"1636")
                var records = response.data;
                // console.log(response, "response")
                // var checkData = eval(response.trip_route);
                // console.log(e.data.straight_lines_info, "st lines")
                // if(e.data.straight_lines_info != "[]"){
                //     checkData = checkData.sort(function(a, b) { return a.dist_from_prev_point - b.dist_from_prev_point; });
                // }
                //  console.log(checkData,"1642")
                var saved_base_route = []
                if(saved_base_route.length == 0)
                {
                    this.setState({
                        sliderRouteTranslate:"slider-translate-50p",
                        mapfor: "map_routes",
                        routeRowData: e.data,
                        route_coords:records.coords,
                        breaks_list: [],
                        overly:"show-m",
                        loadshow:'show-n',
                        truck_no : e.data.truck_no
                    })
                }
                else
                {
                    var route_coords1 = [
                        {
                            "consignment_code": e.data.consignment_codes,
                            "coords": records.coords,
                            "breaks": []
                        },
                        {
                            "consignment_code": ["MSIL Approved Route"],
                            "coords": eval(records.saved_base_route[0]),
                            "breaks": []
                        }
                    ]
                    this.setState({
                        sliderRouteTranslate1: "slider-translate-50p",
                        mapfor: "map_routes",
                        // routeRowData: e.data,
                        dealersList: [],
                        route_coords1: route_coords1,
                        overly:"show-m",
                        loadshow:'show-n',
                        truck_no : e.data.truck_no
                    })
                }
                
            })
            
        }
        else if(e.colDef.field == "base_routes_map")
        {
            console.log("Hit-1")
            console.log(e.data,"1707")
            if (e.data.route_coords==undefined||e.data.route_coords=="undefined"){
                var rowID=e.data.base_route_id
                var tab="base_routes"
            }else{
                var rowID=e.data.s3_filename
                var tab="summary"
            }
           console.log(rowID,"1732")
            this.setState({
                overly: "show-m",
                loadshow: "show-m"
            })
    
         
                redirectURL.post("dashboard/gettripcoords",{row_id:rowID,tab:tab}).then((resp)=>{
                    console.log(resp.data,"1724")
                    var data = JSON.parse(resp.data.trip_route);
                    // console.log(eval(resp.data.trip_route),"1729")
                    // console.log(resp.data.saved_base_route,"1730")
             
                    var route_coords1 = [
                        {
                            "consignment_code": ["Base Route - "+e.data.base_route_no],
                            "coords": data,
                            "breaks": []
                        },
                        // {
                        //     "consignment_code": ["MSIL Approved Route"],
                        //     "coords": eval(resp.data.saved_base_route[0]),
                        //     "breaks": []
                        // }
                    ]
                    this.setState({
                        sliderRouteTranslate1: "slider-translate-50p",
                        mapfor: "map_routes",
                        // routeRowData: e.data,
                        dealersList: [],
                        route_coords1: route_coords1,
                        overly:"show-m",
                        loadshow:'show-n'
                    })
                // }
            })
            // })
        }
        else if(e.colDef.field == "no_of_trips_travelled")
        {
            this.setState({
                complete_trips: this.state.defaultTrips
            })
        }
        else if(e.colDef.field == "valid_trips")
        {
            var filteredTrips = this.state.defaultTrips.filter((e)=>{
                return e.route_ignored == undefined
            })
            this.setState({
                complete_trips: filteredTrips
            })
        }
        else if(e.colDef.field == "route_ignored")
        {
            // var filteredTrips = this.state.defaultTrips.filter((e)=>{
            //     return e.route_ignored == 1
            // })
            // this.setState({
            //     complete_trips: filteredTrips
            // })
        }
        else if(e.colDef.field == "st_lines_1")
        {
            var filteredTrips = this.state.defaultTrips.filter((e)=>{
                return e.no_of_straight_lines == 1
            })
            this.setState({
                complete_trips: filteredTrips
            })
        }
        else if(e.colDef.field == "st_lines_2")
        {
            var filteredTrips = this.state.defaultTrips.filter((e)=>{
                return e.no_of_straight_lines == 2
            })
            this.setState({
                complete_trips: filteredTrips
            })
        }
        else if(e.colDef.field == "st_lines_3")
        {
            var filteredTrips = this.state.defaultTrips.filter((e)=>{
                return e.no_of_straight_lines == 3
            })
            this.setState({
                complete_trips: filteredTrips
            })
        }
        else if(e.colDef.field == "st_lines_4")
        {
            var filteredTrips = this.state.defaultTrips.filter((e)=>{
                return e.no_of_straight_lines == 4
            })
            this.setState({
                complete_trips: filteredTrips
            })
        }
        else if(e.colDef.field == "st_lines_5")
        {
            var filteredTrips = this.state.defaultTrips.filter((e)=>{
                return e.no_of_straight_lines == 5
            })
            this.setState({
                complete_trips: filteredTrips
            })
        }
        else if(e.colDef.field == "st_lines_gt_5")
        {
            var filteredTrips = this.state.defaultTrips.filter((e)=>{
                return e.no_of_straight_lines > 5
            })
            this.setState({
                complete_trips: filteredTrips
            })
        }
    }

    onClickShowRouteMap = async () =>{
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        var selectedRows = [];
        this.state.tripsGridApi.getSelectedNodes().map(e => {
            // console.log(e, "check")
            selectedRows.push(e.data)
        })
        if(selectedRows.length == 0){
            this.setState({
                loadshow: "show-n",
                overly: "show-n",
                basicTitle: "Please Select Atlease One Route",
                basicType: "warning",
                show: true
            })

        }else{
            if(selectedRows.length > 3)
            {
                this.setState({
                    loadshow: "show-n",
                    overly: "show-n",
                    basicTitle: "Only 3 trips are allowed",
                    basicType: "warning",
                    show: true
                })
            }
            else
            {
                var route_coords1 = [];
                var dealersList = [];
                console.log(selectedRows,"selected");
                var ids = [];
                var newRoutes = selectedRows.map(async (each)=>{
                    if (each.route_coords==undefined||each.route_coords=="undefined"){
                        var rowID=each.base_route_id
                        var tab="base_routes"
                    }else{
                        var rowID=each.s3_filename
                        var tab="summary"
                    }
                    await redirectURL.post("dashboard/gettripcoords",{row_id:rowID,tab:tab }).then((resp)=>{
                        console.log(resp.data,"1724")
                        var data = JSON.parse(resp.data.trip_route);
                        // console.log(eval(resp.data.trip_route),"1729")
                        // console.log(resp.data.saved_base_route,"1730")
                    // if(resp.data.saved_base_route.length == 0)
                    // {
                    //     console.log("hit-1")
                    //     this.setState({
                    //         sliderRouteTranslate:"slider-translate-50p",
                    //         mapfor: "base_routes_map",
                    //         routeRowData: response.data[0],
                    //         route_coords:eval(response.data.trip_route),
                    //         breaks_list: [],
                    //         overly:"show-m",
                    //         loadshow:'show-n'
                    //     })
                    // }
                    // else
                    // {
                        route_coords1.push({
                            "consignment_code": ["Base Route - "+each.base_route_no],
                            "coords": data,
                            "breaks": []
                        })
                          
                       
                    // }
                })
                
                })

                let loadNewRoutes = await Promise.all(newRoutes)
                console.log(route_coords1,"1939")
                await this.setState({
                        sliderRouteTranslate1: "slider-translate-50p",
                        mapfor: "map_routes",
                        // routeRowData: e.data,
                        dealersList: [],
                        route_coords1: route_coords1,
                        overly:"show-m",
                        loadshow:'show-n'
                    })
            
              
                    
                
               

                // try{
                //     var waitForLoop = await selectedRows.map(async (e)=>{
                //         // console.log("waitForLoop",e)
                //         await new Promise(async (resolve,reject)=>{
                //             var consignment_codes= ''
                //             if(e.base_route_no !== undefined){
                //                 if(typeof(e.base_route_no) === 'number'){
                //                     consignment_codes = ["Base Route "+e.base_route_no]
                //                 }
                //                 else if(e.base_route_no.includes("Base")){
                //                     consignment_codes = e.base_route_no
                //                 }
                //             }
                //             else{
                //                 consignment_codes =  e.consignment_codes;
                //             }
                //             // console.log("e.base_route_no",e.base_route_no)
                //             if(e.consignment_codes == undefined)
                //             {
                //                 consignment_codes = ["Base Route "+e.base_route_no]
                //             }
                //             var breaks = e.breaks;
                //             if(e.breaks == undefined)
                //             {
                //                 breaks = []
                //             }
                //             // console.log(this.state.btn3, "btn3")
                //             if(this.state.btn3 == "btn-danger")
                //             {
                //                 ids.push(e.base_route_id)
                //                 route_coords1.push({
                //                     "consignment_code": consignment_codes,
                //                     "coords": "",
                //                     "breaks": breaks,
                //                     "base_route_id": e.base_route_id
                //                 })
                //             }
                //             else{
                //                 route_coords1.push({
                //                     "consignment_code": consignment_codes,
                //                     "coords": eval(e.route_coords),
                //                     "breaks": breaks
                //                 })
                //             }
                            
                //             if(e.consignee_coordinates != undefined)
                //             {
                //                 dealersList.push(e.consignee_coordinates)
                //             }
                //             resolve(1)
                //         })                
                //     })
                // }
                // catch(e){

                // }
                // Promise.all([waitForLoop]).then(async ()=>{
                //     var formdata = {
                //         "consigner_code":  this.state.originoption.value,
                //         "consignee_city": this.state.destinationoption.value,
                //         // "period_type": this.state.selected_period_type.value,
                //         // "gps_provider": this.state.selected_provider.value,
                //         // "truck_type": this.state.selected_truck_type.value,
                //     }
                //     redirectURL.post("dashboard/getSavedBaseRoute", formdata).then(async (respdata)=>{               
                    
                //         if(respdata.data.length){
                //             route_coords1.push({
                //                 "consignment_code": ['MSIL Approved Route'],
                //                 // "coords": eval(respdata.data[0]),
                //                 "coords": eval(respdata.data[0]),
                //                 "breaks": []
                //             })
                //         }
                //         // console.log("idsCheck", ids)
                //         // currently we are updating the state with the else sap route saved by the user later change to if condition => ids.length > 0 

                //         if(ids.length > 0)
                //         {
                //             var formdata = {
                //                 "row_id": ids
                //             }
                //             // console.log(formdata, "formdata")
                //             var waitForMap = await redirectURL.post("dashboard/getBaseRouteMapDetails", formdata).then(async (response)=>{
                //                console.log(response.data,route_coords1,"1971")
                //                 response.data.base_route_row.map((k,index)=>{
                //                     var idx = ""
                //                     route_coords1.filter((j, index)=>{
                //                         console.log(j.base_route_id, k._id, "base_cons[0]")
                //                         if(j.base_route_id == k._id)
                //                         {
                //                             idx = index
                //                         }
                //                     })
                //                     if(route_coords1[idx].consignment_code[0] != "MSIL Approved Route")
                //                     {
                //                         route_coords1[idx].coords = eval(k.route_coords)
                //                     }
                //                 })
                //                 console.log(route_coords1,"1986")
                //                 this.setState({
                //                     sliderRouteTranslate1: "slider-translate-50p",
                //                     mapfor: "map_routes",
                //                     // routeRowData: e.data,
                //                     dealersList: dealersList,
                //                     route_coords1: route_coords1,
                //                     overly:"show-m",
                //                     loadshow:'show-n'
                //                 })
                //             })
                            
                //         }
                //         else{
                //             this.setState({
                //                 sliderRouteTranslate1: "slider-translate-50p",
                //                 mapfor: "map_routes",
                //                 // routeRowData: e.data,
                //                 dealersList: dealersList,
                //                 route_coords1: route_coords1,
                //                 overly:"show-m",
                //                 loadshow:'show-n'
                //             })
                //         }
                //     })
                //     // console.log(route_coords1, "route_coords1 else")
                // })
                
            }

        }
    }

    onClickMarkAsBaseRoute = async () =>{
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        var selectedRows = [];
        this.state.tripsGridApi.getSelectedNodes().map(e => {
            // console.log(e, "check")
            selectedRows.push(e.data)
        })
        
        if(selectedRows.length > 1)
        {
            this.setState({
                loadshow: "show-n",
                overly: "show-n",
                basicTitle: "Only 1 Route is allowed to mark as base route",
                basicType: "warning",
                show: true
            })
        }
        else if(selectedRows.length == 0)
        {
            this.setState({
                loadshow: "show-n",
                overly: "show-n",
                basicTitle: "Please select a route",
                basicType: "warning",
                show: true
            })
        } 
        else
        {
            if(window.confirm("Do you really wish to mark this as MSIL Approved Route?"))
            {
                var plant_name = selectedRows[0]['plant'];
                var plant_code = this.state.originoptions.filter(e => e.label == plant_name);
                plant_code = plant_code[0]['value']
                var formdata = {
                    "base_route_id": selectedRows[0]['base_route_id'],
                    "consigner_code": plant_code,
                    "consignee_city": selectedRows[0]['consignee_city'],
                    // "gps_provider": this.state.selected_provider.value,
                    "period_type": this.state.selected_period_type.value,
                    "truck_type": this.state.selected_truck_type.value,
                    "created_time": moment.parseZone(new Date()).utcOffset("+05:30")._d,
                    "created_by": localStorage.getItem("email"),
                    "google_distance_via_waypoints": selectedRows[0]['google_distance_via_waypoints'],
                    "expected_distance": selectedRows[0]['expected_distance']
                }
                redirectURL.post("dashboard/markAsBaseRouteAnalysis", formdata).then((resp)=>{
                    if(resp.data.message == "success")
                    {
                        this.setState({
                            loadshow: "show-n",
                            overly: "show-n",
                            basicTitle: "successfuly updated",
                            basicType: "success",
                            show: true
                        })
                        var valueCollection = []
                        valueCollection = this.state.selected_period_type.map(ele =>ele.value)
                        console.log(valueCollection,'valuecollection')
                        var formdata = {
                            consigner_code:this.state.originoption.value,
                            consignee_city:this.state.destinationoption.value,
                            // gps_provider: this.state.selected_provider.value,
                            period_type: JSON.stringify(valueCollection),
                            truck_type: this.state.selected_truck_type.value,
                        }
                        this.onLoadFormRequest(formdata)
                    }
                    else
                    {
                        this.setState({
                            loadshow: "show-n",
                            overly: "show-n",
                            basicTitle: "Failed to mark the base route",
                            basicType: "warning",
                            show: true
                        })
                    }
                    
                })
            }
            else{
                this.setState({
                    loadshow: "show-n",
                    overly: "show-n",
                })
            }
        }
    }

    onClickShowClickableData = (params) =>{
        console.log("params",params)
    }

   

    render(){
        const dStyles={
            width:'100%',
            height:'600px',
        }
        // console.log(this.state.route_coords1,"2119")
        const {ignoredRouteSubmit,destinationoption,originoption,optimizedroutes,total_optimized_routes,kilometers_saved, transporterCounter,highestBaseRouteName,highestBaseRouteCount,noOfTransporters,total_no_trips_travelled,total_valid_trips,fetch_next_data,total_emission_saved,final_actual_count,final_difference_count,final_standard_count} = this.state

        var check_user = true
        if(localStorage.getItem("email") && /enmovil\.in/.test(localStorage.getItem("email").trim().toLowerCase())){
            var check_user = false
        }
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
                        <div className="row col-xl-12 col-lg-12">
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Origin: </label>
                                <Select 
                                    name="originoption"
                                    closeMenuOnSelect={true} 
                                    value={originoption} 
                                    className="border-radius-0" 
                                    onChange={this.changeOriginHandler.bind(this)} 
                                    style={{borderRadius:"0px"}} 
                                    options={this.state.originoptions} required
                                />
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Destination: </label>
                                <Select 
                                    name="destinationoption"
                                    closeMenuOnSelect={true} 
                                    value={destinationoption} 
                                    className="border-radius-0" 
                                    onChange={this.changeDestinationHandler.bind(this)} 
                                    style={{borderRadius:"0px"}} 
                                    options={this.state.destinationOptions} required
                                />
                            </div>
                            {/* <div className="form-group col-xl-2 col-lg-2">
                                <label>GPS Provider: </label>
                                <Select 
                                    name="GPS Provider"
                                    closeMenuOnSelect={true} 
                                    value={this.state.selected_provider} 
                                    className="border-radius-0" 
                                    onChange={this.changeGpsProviderHandler.bind(this)} 
                                    style={{borderRadius:"0px"}} 
                                    options={this.state.lspusers_options} required
                                />
                            </div> */}
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Period: </label>
                                {(check_user === false)?
                                    <Select 
                                    // isMulti={check_user}
                                    // isClearable
                                      name="Period"
                                      // closeMenuOnSelect={true} 
                                      value={this.state.selected_period_type} 
                                      className="border-radius-0" 
                                      onChange={this.changePeriodTypeHandlerforSingleSelect} 
                                      style={{borderRadius:"0px"}} 
                                      options={this.state.period_types} required
                                  />
                                :
                                    <CreatableSelect 
                                    isMulti={check_user}
                                    isClearable
                                      name="Period"
                                      // closeMenuOnSelect={true} 
                                      value={this.state.selected_period_type} 
                                      className="border-radius-0" 
                                      onChange={this.changePeriodTypeHandler.bind(this)} 
                                      style={{borderRadius:"0px"}} 
                                      options={this.state.period_types} required
                                  />
                                }
                                
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Truck Type: </label>
                                <Select 
                                    name="Truck Type"
                                    closeMenuOnSelect={true} 
                                    value={this.state.selected_truck_type} 
                                    className="border-radius-0" 
                                    onChange={this.changeTruckTypeHandler.bind(this)} 
                                    style={{borderRadius:"0px"}} 
                                    options={this.state.truck_types} required
                                />
                            </div>

                            <div className="form-group col-xl-2 col-lg-2">
                                <button style={{marginTop:"30px"}} 
                                onClick={ignoredRouteSubmit == 1 ? this.getIgnoredRoutes : this.formHandler.bind(this)}
                                 type="button" className="btn btn-success">Submit</button>
                            </div>
                        </div>
                        
                        {transporterCounter ? 
                        <div className="card">
                            <div className="card-body" id="counterCardBody" style={{padding:"0px"}}>
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        <div className="col cirlce-d cursorPointer" style={{padding:'10px'}}>
                                            <span className="f15"><i className="icofont icofont-map-pins f24 txt-success"></i><br /> Total Trips From <br /> {originoption.label} To {destinationoption.label}</span>
                                            <h4 className="txt-success f40"><span className="counter"><CountUp end={(total_no_trips_travelled != '')?total_no_trips_travelled:0}/></span></h4>
                                        </div>
                                        <div className="col cirlce-d cursorPointer" onClick={() => this.onClickShowClickableData("tor")} style={{padding:'10px'}}>
                                            <span className="f15"><i className="icofont icofont-map-pins f24 txt-primary"></i><br /> Valid Trips</span>
                                            <h4 className="txt-primary f40"><span className="counter"><CountUp end={(total_valid_trips != '')?total_valid_trips:0}/></span></h4>
                                        </div>
                                        {(localStorage.getItem("email") && /enmovil\.in/.test(localStorage.getItem("email").trim().toLowerCase()))?
                                        "":
                                        <div className="col cirlce-d cursorPointer" onClick={() => this.onClickShowClickableData("all")} style={{padding:'10px'}}>
                                            <span className="f15"><i className="icofont icofont-truck f24 txt-success"></i><br /> No Of Transporters</span>
                                            <h4 className="txt-success f40"><span className="counter"><CountUp end={(noOfTransporters != '')?noOfTransporters:0}/></span></h4>
                                        </div>
                                        }
                                        
                                        <div className="col cirlce-d cursorPointer" onClick={() => this.onClickShowClickableData("tor")} style={{padding:'10px'}}>
                                            <span className="f15" style={{paddingTop:"10px"}}><i className="icofont icofont-ui-browser f24 txt-primary"></i><br /> No Of Base Routes</span>
                                            <h4 className="txt-primary f40"><span className="counter"><CountUp end={(total_optimized_routes != '')?total_optimized_routes:0}/></span></h4>
                                        </div>                                        
                                        <div className="col cirlce-d cursorPointer" style={{padding:'10px'}}>
                                            <span className="f15"><i className="icofont icofont-ui-calculator f24 txt-warning"></i><br /> Most Followed Route</span>
                                            <h4 className="txt-warning f40"><span className="counter"><CountUp end={(kilometers_saved != '')?kilometers_saved:0}/></span></h4>
                                            <div className=" f16">
                                                <div className="counter txt-success-medium" onClick={() => this.onClickShowClickableData("tor")}>
                                                    Route Name : &nbsp;
                                                    {highestBaseRouteName}
                                                </div>

                                                <div className="counter txt-danger-medium" onClick={() => this.onClickShowClickableData("tor")}>
                                                    No Of Trips : &nbsp;
                                                    <CountUp end={(highestBaseRouteCount != '') ? highestBaseRouteCount : 0} />
                                                </div>
                                            </div>
                                        </div>
                                        {(localStorage.getItem("email") && /enmovil\.in/.test(localStorage.getItem("email").trim().toLowerCase()))?
                                        <div className="col cirlce-d cursorPointer" style={{backgroundColor : "#5fb1ab",padding:'10px'}}>
                                        <span className="f13"><img src={coEmissionImage} alt="emission_counter"/><br /></span>
                                        <div className='row'>
                                            {/* <div className='col-xl-6 col-lg-6'> */}
                                                <div className="col-xl-6 cirlce-d cursorPointer" style={{marginTop:"10px"}}>
                                                    <span className="f13" style={{color:"#ffffff",fontWeight:"bold"}}>Expected <span style={{fontSize:"10px"}}>(Tons)</span></span>
                                                    <h4 className="f30" style={{color:"#ffffff"}}><span className="counter">{final_standard_count.toFixed(2)}</span></h4> 
                                                </div>
                                            {/* </div> */}
                                            {/* <div className='col-xl-6 col-lg-6'> */}
                                                <div className="col-xl-6 cirlce-d cursorPointer" style={{marginTop:"10px"}}>
                                                    <span className="f13" style={{color:"#ffffff",fontWeight:"bold"}}>Actual <span style={{fontSize:"10px"}}>(Tons)</span></span>
                                                    <h4 className="f30" style={{color:"#ffffff"}}><span className="counter">{final_actual_count.toFixed(2)}
                                                    {(final_standard_count < final_actual_count)?
                                                        <img className="sustainability_icons" src={negativeEmissionImage} alt="negative_emission"/>:
                                                        <img className="sustainability_icons" src={positiveEmissionImage} alt="positive_emission"/>
                                                    }
                                                    </span></h4>
                                                </div>
                                            {/* </div> */}
                                        </div>
                                    </div>
                                        :""}
                                    </div>
                                </div>
                            </div>
                        </div> 
                        : ""}
                        <div className="button-group">
                            <button className={"btn "+this.state.btn1} onClick={this.onClickTab.bind(this, "btn1")}>Summary</button>
                            {/* <button className={"btn "+this.state.btn2} onClick={this.onClickTab.bind(this, "btn2")}>Trips</button> */}
                            <button className={"btn "+this.state.btn3} onClick={this.onClickTab.bind(this, "btn3")}>Base Routes</button>
                            <button className={"btn "+this.state.btn4} onClick={this.onClickTab.bind(this, "btn4")}>Transporters Info</button>
                            <button className={"btn "+this.state.btn5} onClick={this.onClickTab.bind(this, "btn5")}>Ignored Routes</button>
                            {/* <a style={{float: "right"}} href={'/ignoredroutes'} class="btn btn-warning" rel="noopener" >Ignored Routes</a> */}
                            {/* <a style={{float: "right"}} href={"/deviationroutes?source="+this.state.originoption.value+"&sourcename="+this.state.originoption.value+"&destination="+this.state.destinationoption.value+'&period_type='+ this.state.selected_period_type.value +'&gps_provider='+this.state.selected_provider.value+'&truck_type='+this.state.selected_truck_type.value} target="_blank" class="btn btn-warning" rel="noopener">View Base Routes</a> */}
                        </div>
		            	<div className="card">
                            {(this.state.hideSummaryTab == false) ?
                                <div className="card-header">
                                    <h5 style={{float: "left", width: "50%"}}>
                                        <i className="icon-map cus-i"></i> <span>{this.state.pageTitle}</span>
                                    </h5>
                                    {/* {(this.state.btn1 == "btn-danger")?
                                        <p style={{color: "red"}}><b>Note :</b> If there is a GPS gap of >20 Kms, that instance is considered as straight line</p>
                                    :""} */}
                                </div> : ""}
				   			<div className="card-body row">

								<div className="row col-xl-12 col-lg-12">
                                    {(this.state.hideSummaryTab == false)?                                    
                                        <div id="myGrid" style={{ height: this.state.gridHeight,width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={this.state.columnDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={false}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                enableRangeSelection={true}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                enableCellTextSelection={true}
                                                suppressCopyRowsToClipboard={true}
                                                suppressRowClickSelection={true}
                                                frameworkComponents={this.state.frameworkComponents}
                                                //onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                                onCellClicked={this.onCellClicked.bind(this)}
                                            />                                          
                                        </div>
                                    :""}                     
                                    {(this.state.btn1 == "btn-info" || this.state.btn3 == "btn-info")?
                                        <div id="myGrid" style={{ height: "360px",width: "100%"}}  className="ag-theme-balham">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                { (this.state.btn3 == "btn-info") ? "" : 
                                                (<h5 style={{float: "left", width:"90%"}}>
                                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{"Trips"}</span>
                                                    </h5>)}
                                                {(this.state.btn3 == "btn-info")?
                                                    <button className="btn btn-info" onClick={this.onClickMarkAsBaseRoute}>Mark as MSIL Approved Route</button>
                                                :""}
                                                <button className="btn btn-warning" onClick={this.onClickShowRouteMap}>Show Map</button>
                                                {/* {fetch_next_data == 'show-m' ? (
                                                <button className="btn btn-info float-right" onClick={this.onClickGetNextData}>Fetch Next Trips</button>
                                                ) : ""} */}
                                            </div>
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={(this.state.btn3 == "btn-info")?colDefs3:colDefs2}
                                                // columnDefs = {this.state.columnDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.complete_trips}
                                                enableCharts={false}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady= {this.onGridReady1} 
                                                onGridState={this.onGridState1}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                stopEditingWhenGridLosesFocus={true}
                                                pagination={true}
                                                enableRangeSelection={true}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                enableCellTextSelection={true}
                                                suppressCopyRowsToClipboard={true}
                                                suppressRowClickSelection={true}
                                                onCellClicked={this.onCellClicked.bind(this)}
                                                // onRowSelected={this.onRowSelected.bind(this)}
                                                rowSelection={"multiple"}

                                            />
                                             
                                        </div>
                                    :""}
                                    
								</div>
							   		
                            </div>
				   		</div>	
				   	</div>
				 </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

                {
                    (this.state.sliderRouteTranslate != "")?
                    <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								View Route Data
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseUploadDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								
                                <NewDrawMap 
                                    mapFor={"geofence"}
                                    mapinfo={{"coords": this.state.route_coords,
                                            "breaks": this.state.breaks_list,
                                            "consigner_coordinates": this.state.complete_trips[0]['consigner_coordinates'],
                                            "consignee_coordinates": this.state.complete_trips[0]['consignee_coordinates'],
                                            "route_details": {"start_time": "",
                                                            "end_time": ""}}} 
                                    coords={this.state.route_coords} 
                                    consignments_list={[]}
                                    breaks={this.state.breaks_list} 
                                    truckno = {this.state.truck_no}
                                />
                                {(this.state.mapfor != "base_routes_map")?
                                    <div className="col-xl-12 col-lg-12 n-p-0">
                                        <div className="crm-numbers pb-0">
                                            <h3 className="subH">{"Details"}</h3>
                                            <div className="col-xl-12 col-lg-12 row">
                                                <div className="col route-block">
                                                    <label className="sidebar-label">Truck No</label>
                                                    <div>{this.state.routeRowData.truck_no}</div>
                                                </div>
                                                <div className="col route-block">
                                                    <label className="sidebar-label">Start Time</label>
                                                    <div>{(this.state.routeRowData.gate_out_time != '')?getDDMMYYYYHHMMSS(this.state.routeRowData.gate_out_time):"NA"}</div>
                                                </div>
                                                <div className="col route-block">
                                                    <label className="sidebar-label">End Time</label>
                                                    <div>{(this.state.routeRowData.trip_end_time != '')?getDDMMYYYYHHMMSS(this.state.routeRowData.trip_end_time):"NA"}</div>
                                                </div>
                                                <div className="col route-block">
                                                    <label className="sidebar-label">Distance</label>
                                                    <div>{Math.round(this.state.routeRowData.total_trip_dist_with_google_km) +" Kms"}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :""}
                                
                                
							</div>
						</div>
						
					</div>
				:""}

                {
                    (this.state.sliderRouteTranslate1 != "")?
                    <div className={"slide-r "+(this.state.sliderRouteTranslate1)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								View Route Data
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseUploadDiv.bind(this)} >X</span>					
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>	
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">								
                                <NewDrawMap 
                                    mapFor={"multiroutedeviation"}
                                    mapinfo={{"coords": this.state.route_coords1,
                                            "consigner_coordinates": this.state.defaultCompleteTrips[0]['consigner_coordinates'],
                                            "consignee_coordinates": this.state.dealersList,
                                            "route_details": {"start_time": "",
                                                            "end_time": ""}}} 
                                    coords={this.state.route_coords} 
                                    consignments_list={[]}
                                    breaks={this.state.breaks_list} 
                                />
							</div>
						</div>
						
					</div>
				:""}

                <div className="col-xl-8 col-lg-8" id="map" style={dStyles}></div>


            </div>
              
        );
    }
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function loadchecked()
{
   // var defaultin = document.getElementById("Route 1").checked = true;
//    var checkBox = document.getElementById("Route 1").checked = true
//    var checkBox = document.getElementById("Route 2").checked = true
//    var checkBox = document.getElementById("Route 3").checked = true
    var check = document.getElementsByClassName("checklists");
    // console.log("check here ",check)
    if(check.length > 0)
    {
        for(var c=0;c<check.length;c++)
        {
            //check[c].setAttribute("checked", true)
            document.getElementById(check[c]).checked = true;
        }
    }
}
window.onload = function() {
    // var checkBox = document.getElementById("Route 1").checked = true
    // var checkBox = document.getElementById("Route 2").checked = true
    // var checkBox = document.getElementById("Route 3").checked = true
    
};

function selectAllRenderer(params) {
    var cb = document.createElement('input');
    cb.setAttribute('type', 'checkbox');

    var eHeader = document.createElement('label');
    var eTitle = document.createTextNode(params.colDef.headerName);
    eHeader.appendChild(cb);
    eHeader.appendChild(eTitle);
    params.api.selectAll();
    cb.addEventListener('change', function (e) {
       
            params.api.selectAll();
       
    });
    return eHeader; 
}

function CheckForHtmlTag(){
	var rs = document.getElementById("route_reason");
	var reg =/<(.|\n)*?>/g; 
	if (reg.test(rs.value)) {
		var ErrorText = "Oops! HTML or Script is not allowed.";		
		rs.value=''
		//alert();
		return 1;
	}
}
function SplitTime(numberOfHours){
    var Days=Math.floor(numberOfHours/24);
    var Remainder=numberOfHours % 24;
    var Hours=Math.floor(Remainder);
    var Minutes=Math.floor(60*(Remainder-Hours));
    return({"Days":Days,"Hours":Hours,"Minutes":Minutes})
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}   

function sortPeriodTypes(periods_list)
{
    var quarters_dict = {"4": ["Jan", "Feb", "Mar"], "1": ["Apr", "May", "Jun"], "2": ["Jul", "Aug", "Sep"], "3": ["Oct", "Nov", "Dec"]};
    var final_list = ["ALL"];
    var distinctYears = [];
    periods_list = periods_list.filter((e) => {
        var matches = e.match(/(\d+)/);
        // console.log(matches)
        if(matches != null)
        {
            if(matches.length)
            {
                var year = parseInt(matches[0])
                if(distinctYears.indexOf(year) < 0)
                {
                    distinctYears.push(year)
                }
            }
        }
        
        return e != "ALL"
    })
    distinctYears = distinctYears.sort()
    distinctYears.map((e)=>{
        var filteredData = periods_list.filter(k => k.includes(e.toString()))
        if(filteredData.length)
        {
            var quarters = filteredData.filter(j => j.includes("Q"));
            var resultMonths = filteredData.filter(j => j.includes("Q") == false);
            quarters = quarters.sort()
            if(quarters.length)
            {
                quarters.map(q => {
                    final_list.push(q)
                    var quarter_no = q.split("Q")[1];
                    var quarter_months = quarters_dict[quarter_no];
                    quarter_months.map(m =>{
                        var finalMonth = resultMonths.filter(r => r.includes(m));
                        if(finalMonth.length)
                        {
                            final_list.push(finalMonth[0])
                        }
                    })
                })
            }
        }
    });
    final_list = final_list.filter(e => e != "");
    return final_list
}
 
import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';
var redirectURL = require('../redirectURL');

export default class ExportsTrackingCountersComponent extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
            records:[],
            allRowDatacount:0,
            highseescnt:0,
            icdtoportcnt:0,
            insideicdcnt:0,
            insideportcnt:0,
            msilicdcnt:0,
            mgorocnt:0,
            ropocnt:0,
            podicnt:0,
            dealyCounters:0,
            avgCounters:0,
            avgMoro:0,
            avgRoro:0,
            avgmoroActual : 0,
            avgmoroPending : 0,
            avgroroActual : 0,
            avgroroPending : 0,
    	}
    
    }
   
    componentWillReceiveProps(newprops){
       this.setState({
        allRowDatacount:newprops.allRowDatacount,
        highseescnt:newprops.highseescnt,
        icdtoportcnt:newprops.icdtoportcnt,
        insideicdcnt:newprops.insideicdcnt,
        insideportcnt:newprops.insideportcnt,
        msilicdcnt:newprops.msilicdcnt,
        mgorocnt:newprops.mgorocnt,
        ropocnt:newprops.ropocnt,
        podicnt:newprops.podicnt,
        dealyCounters:newprops.dealyCounters,
        avgCounters:newprops.avgCounters,
        avgMoro:newprops.avgMoro,
        avgRoro:newprops.avgRoro,
        avgmoroActual:newprops.avgmoroActual,
        avgmoroPending:newprops.avgmoroPending,
        avgroroActual:newprops.avgroroActual,
        avgroroPending:newprops.avgroroPending,
       })

    }
    onClickCounter(data){
        this.props.context.onClickCounterShowData(data)
        //console.log(this.props.context)
    }
    render(){
        return (
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                    <div className="card">
                        <div className="card-body" style={{padding:"10px"}}>
                            <div className="crm-numbers pb-0">
                                <div className="row">
                                
                                <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this,0)}>
                                        <span className="f13"><i className="icofont icofont-vehicle-delivery-van cus-i txt-info f24"></i><br /> All</span>
                                        <h4 className="txt-info f50"><span className="counter"><CountUp end={(this.state.allRowDatacount != '')?this.state.allRowDatacount:0}/></span></h4>
                                    </div> 
                                <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this,1)}>
                                    <span className="f13"><i className="icofont icofont-van-alt f25 txt-secondary-dark"></i><br /> MSIL to ICD</span>
                                    <h4 className="txt-secondary-dark f50"><span className="counter"><CountUp end={(this.state.msilicdcnt != '')?this.state.msilicdcnt:0}/></span></h4>
                                </div> 
                                <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this,2)}>
                                    <span className="f13"><i className="icofont icofont-ui-home f24 txt-pink-light"></i><br /> Inside ICD</span>
                                    <h4 className="txt-pink-light f50"><span className="counter"><CountUp end={(this.state.insideicdcnt != '')?this.state.insideicdcnt:0}/></span></h4>
                                </div> 
                                <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this,3)}>
                                    <span className="f13"><i className="icofont icofont-train-line f22 txt-info-medium"></i><br /> ICD to Port</span>
                                    <h4 className="txt-info-medium f50"><span className="counter"><CountUp end={(this.state.icdtoportcnt != '')?this.state.icdtoportcnt:0}/></span></h4>
                                </div>  
                                <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this,4)}>
                                    <span className="f13"><i className="icofont icofont-ship f24 txt-success"></i><br /> Inside Port</span>
                                    <h4 className="txt-success f50"><span className="counter"><CountUp end={(this.state.insideportcnt != '')?this.state.insideportcnt:0}/></span></h4>
                                </div> 
                                <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this,5)}>
                                    <span className="f13"><i className="icofont icofont-ship-alt txt-info f24"></i><br /> High seas</span>
                                    <h4 className="txt-info f50"><span className="counter"><CountUp end={(this.state.highseescnt != '')?this.state.highseescnt:0}/></span></h4>
                                </div>

                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>
                {(this.state.dealyCounters) == 1 ? 
                <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                    <div className="card">
                        <div className="card-body" style={{padding:"10px"}}>
                            <div className="crm-numbers pb-0">
                                <div className="row">
                                    <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this,6)}>
                                            <span className="f13">MSIL Gate Out to Rail Out (Delayed)</span>
                                            <h4 className="txt-info f50"><span className="counter"><CountUp end={(this.state.mgorocnt != '')?this.state.mgorocnt:0}/></span></h4>
                                        </div> 
                                    <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this,7)}>
                                        <span className="f13">ICD to Port (Delayed)</span>
                                        <h4 className="txt-secondary-dark f50"><span className="counter"><CountUp end={(this.state.ropocnt != '')?this.state.ropocnt:0}/></span></h4>
                                    </div> 
                                    <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this,8)}>
                                        <span className="f13">High Seas (Delayed)</span>
                                        <h4 className="txt-pink-light f50"><span className="counter"><CountUp end={(this.state.podicnt != '')?this.state.podicnt:0}/></span></h4>
                                    </div> 
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>
                : ""}
                {(this.state.avgCounters) == 1 ? 
                <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                    <div className="crm-numbers pb-0">
                        <div className="row">
                            <div className="col cirlce-d">
                                <div className="card n-p-0">
                                    <div className="card-header">
                                        <span className="f12">MSIL Out to Rail Out (Avg. Transit Days)</span>
                                    </div>
                                    <div className="row card-body pt-15px pb-15px">
                                        <div className="col col-xl-6 col-lg-6 cpointer"> 
                                            <span className="f12">
                                                Actual </span>
                                            <h4 className="txt-info f26"><span className="counter"><CountUp end={(this.state.avgmoroActual != '')?this.state.avgmoroActual:0}/></span></h4>
                                        </div>
                                        <div className="col col-xl-6 col-lg-6">  
                                                <span className="f12">Pending Rail Out </span>
                                                <h4 className="txt-danger f26"><span className="counter">
                                                    <CountUp end= {(this.state.avgmoroPending != 0)?this.state.avgmoroPending:0}/>
                                                    </span></h4>
                                            </div>
                                    </div>
                                </div> 
                            </div>
                            <div className="col cirlce-d">
                                <div className="card n-p-0">
                                    <div className="card-header">
                                        <span className="f12">Rail Out to Port Out: (Avg. Transit Days)</span>
                                    </div>
                                    <div className="row card-body pt-15px pb-15px">
                                        <div className="col col-xl-6 col-lg-6 cpointer"> 
                                            <span className="f12">
                                                Actual &nbsp; </span>
                                            <h4 className="txt-info f26"><span className="counter"><CountUp end={(this.state.avgroroActual != '')?this.state.avgroroActual:0}/></span></h4>
                                        </div>
                                        <div className="col col-xl-6 col-lg-6">  
                                            <span className="f12">Pending Port Out </span>
                                            <h4 className="txt-danger f26"><span className="counter">
                                            <CountUp end= {(this.state.avgroroPending != 0)?this.state.avgroroPending:0}/>
                                            </span></h4>
                                        </div>
                                    </div>
                                </div> 
                            </div> 
                        </div>
                    </div>                    
                </div>
                : ""}
            </div>

            
        );
    }
}







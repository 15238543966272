import React, { Component } from 'react';
import ReactDOM from 'react-dom';
// import '../../../../node_modules/react-grid-layout/css/styles.css';
// import '../../../../node_modules/react-resizable/css/styles.css';

import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
import Modal from 'react-responsive-modal';
 
import axios from 'axios';
import Autosuggest from 'react-autosuggest';

import Breadcrumb from '../common/breadcrumb.component';
import DashboardCountersComponent from './tptdashboardCounters.component';

import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import redirectURL from '../redirectURL';
import ComponentIndex from '../common/appendComponents';
import DateFormater from '../layouts/dateComponent';
import DrawMap from '../common/drawmap';
import trucklocaction from '../layouts/trucklocaction';
import TPTtrucklocaction from '../layouts/tpttrucklocaction';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD,getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils'; 
import $ from "jquery";
import * as Datetime from 'react-datetime';

var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`)
const ResponsiveReactGridLayout = WidthProvider(Responsive);
var map;
var viewMarkersArr=[];
var moment = require('moment'); 
var googleAnalytics = require("../common/googleanalytics");
var infoBox = require('../common/google-infowindow');


class tptGpsIntegration extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			totalTrucks: [],
			uniqueActiveConsignments: null,

			totalActiveConsignments: null,
			gpsNotTodayActiveConsignments: [],
			totalActiveConsignmentsCount: 0,
			gpsNotTodayActiveConsignmentsCount: 0,
			currentDepartmentTitle: null,
			showdata : this.displayData,
			truckInfo:'',
			consignments:'',
			sndshow:'show-n',
			departmentchrtshow:'show-m',
			currenttrucklocation:'',
			truckoperates:0,
			truckgpslifetime:0,
			lspInfo:0,
			deptServes:0,
			transrecordserves:0,
			gpssendtoday:0,
			nopetransporters:0,
			gpstoday:0,
        	alldepts:[],
        	//deptvalue:'all',
        	deptvalue:null,
			sliderTranslate:'',
        	truckscnt:0,
        	trucklocations:0,
        	notrans:0,
        	trucktoday:0,
        	trucknogps:0,
            data: "grouped",
            legendPosition: "top-left",
            interpolate: "linear",
            xLabel: "M",
            yLabel: "GGGGGGGGGG",
            hidePoints: false,  
            hideLines: false,
            yMin: null,
            yMax: null,
            xMax: null,
            xMin: null,
            loadsData:[], 
            transportschart:[],
            transaxis:[],
			transyaxis:[],
			trans_no_gps_axis:[],
			trans_no_gps_yaxis:[],
            deptaxis:[],
            deptyaxis:[],
            lspaxis:[],
            lspyaxis:[],
            lsp_no_gps_axis:[],
            lsp_no_gps_yaxis:[],
            gpsintervalaxis:[],
            gpsintervalyaxis:[],
            modules: AllModules,
  	      open: false,
  	      filterstatess:{},
  	      columnsdatas:{},
  	      rowgroupdcols:[],
  	      valuecolumns:[],
  	      pivotmodeis:false,
  	      pivotcols:[],
  	      
  	      defaultColDef: {
  	        sortable: true,
			  filter: true,
			  resizable: true

			},
			frameworkComponents: {
			dateFormater:DateFormater,
			trucklocaction:trucklocaction,
			tpttrucklocaction:TPTtrucklocaction,
		},

  	      rowData: [],
  	      rowwithoutData: [],
			rowgpspacketData:null,
  	      rowModelhighchartoptionsType: 'enterprise',
  	      rowGroupPanelShow: 'always',
  	      animateRows: true,
  	      debug: true,
  	      showToolPanel: true,
  	      suppressAggFuncInHeader: true,
			statusBar: {
				statusPanels: [
				  {
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
				  },
				  {
					statusPanel: "agTotalRowCountComponent",
					align: "center"
				  },
				  { statusPanel: "agFilteredRowCountComponent" },
				  { statusPanel: "agSelectedRowCountComponent" },
				  { statusPanel: "agAggregationComponent" }
				]
			  },
			  sideBar: {
				toolPanels: [
				  {
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
				  },
				  {
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
				  }
				]
			  },
			  usergridstate:[],
			  screenurl:"",
			  screentitle:"",
			  pagetitle:"",
			  eventGridAction: "gridAction",  
			  defaulttab:"all",
			  loadshow:"show-n" ,
			  overly:"show-n",
			  ontripwithgps:0,
			  ontripwithgpsdata:[],
			  ontripgpsna:0,
			  trucksWithGpsLocation:[],
			  atdealertruckcnt:0,
			  atdealernogps:[],
			  alldealertrucks:[],
			  rowdataatdealer:[],
			  primaryontrips:[],
			  secondaryontrips:[],  
			  usermanualmodal: false,
			  mapData:[],
			  sliderRouteTranslate:'',
			  timelinesmarkers:[],
			viewtimelinemarkers:true,
			viewgoogleroutes:true,
			googleroutepath:[],
			frequency:"",
			startDate: moment.parseZone(new Date()).utcOffset("+05:30").format("YYYY-MM-DD")+" 00:00:00",
			endDate:moment.parseZone(new Date()).utcOffset("+05:30").format("YYYY-MM-DD")+" 00:00:00",
			inputTruck:"",
			showmarkers:0,
			tpttrucks:[]
		};
		this.onShowGridData = this.onShowGridData.bind(this);
		this.onClickShowTPTTruckLocation = this.onClickShowTPTTruckLocation.bind(this);
		this.onClickShowTruckLocation = this.onClickShowTruckLocation.bind(this);
	}
	
    componentDidMount(){
		//googleAnalytics.initGA();
		var tptCode = localStorage.getItem('transportercode');
		var vehicle_group = localStorage.getItem("vehicle_group");
		if(vehicle_group != "")
		{
			var tparms = {
				transporter_code:tptCode,
				region:vehicle_group
			}
			redirectURL.post("/dashboard/tpregiontrucksdata",tparms)
			.then((response) => {
			   var records = response.data.records;
				console.log("records ", records)
				this.setState({
					tpttrucks:records
				})
			})
		}
		
        var is_enmovil_transporter = localStorage.getItem("is_enmovil_transporter");
        if(is_enmovil_transporter == 1)
        {
            this.logPageView(); // Log page view to GA
            let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            this.setState({eventAction: eventAction});
            var pagetitle='GPS Integration'
            

            // Active consignments are not applicable for LOG-SC
			// let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
			
			redirectURL.post("/dashboard/devices").then((response1)=>{
				var transporterTrucks = [];
				response1.data.devices.map(function(e){
					if(tptCode.indexOf(e.transporter_code) > -1 )
					{
						transporterTrucks.push(e)
					}
				})
				// console.log(transporterTrucks,"transporterTrucks")
				this.setState({
					transporterTrucks : transporterTrucks
				});
				this.filterByDepartment();
			});
        }
        else
        {
            window.location.href="/";
        }		
	}
	

	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }
	onGridReady = params => {
			this.gridApi = params.api;
			//console.log("Grid is: ", this.gridOptionsWrapper);
			this.gridColumnApi = params.columnApi;	
	};
	
	onChartOptionsChanged(event) {
		//console.log("where is this", event)
			var chartOptions = event.chartOptions;
			//savedLegendUserPreference = chartOptions.legend;
		// savedUserPreferenceByChartType[event.chartType] = chartOptions;
	}
	onFirstDataRendered(params) {
		var createRangeChartParams = {
		cellRange: {
			columns: ["country", "sugar", "fat", "weight"]
		},
		chartContainer: document.querySelector("#myChart"),
		chartType: "bubble",
		suppressChartRanges: true
		};
	// currentChartRef = params.api.createRangeChart(createRangeChartParams);
	}
  
	onGridState = () =>{
		//console.log(this.gridApi);
		
		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		* User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();
				
		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);
			
		
		
	}
	onClickHideAll(){
		this.setState({
			overly : "show-n",
			sliderRouteTranslate:'',
		})
	}
	handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		
		var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+d.getDate()+" "+d.getHours()+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
		
		this.setState({
			startDate:getYYYYMMDDHHMMSS(d)
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerEndDateTime  = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		
		var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+d.getDate()+" "+d.getHours()+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
		this.setState({
			endDate:getYYYYMMDDHHMMSS(d)
		});
		
		
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}
	checkHandler = (event) => {
    	
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    onScatterHover(point) {     
        return `<b>Date: </b>${point.x}<br /><b>Value: </b>${point.y}`;
    }
    createSelectItems() {
        let items = [];         
        //console.log(this.state.alldepts);
        items.push(<option key='all' value='all'>All</option>);  
        this.state.alldepts.map((item, key) => {
        	//console.log("Here "+item,key);
        	items.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>);  
		});
        return items;
    } 
    handleChange(e) {
    	//console.log("V",e.target.value);
        this.setState({ deptvalue: e.target.value });
	  }
	  
	getCurrentDepartmentCode(pathToMatch = null) {
		let departmentCode = null;
		let departmentName = null;
		switch(pathToMatch) {
			case "/dashboard/snddefault":
				departmentCode='SNDG';
				departmentName = " (Sales and Dispatch) ";
				break;
			case "/dashboard/prtdefault":
				departmentCode='LOG-PRT';
				departmentName = " (Spare Parts) ";
				break;
			case "/dashboard/tnpdefault":
				departmentCode='LOG-TNP';
				departmentName = " (Train and Production) ";
				break;
			case "/dashboard/scdefault":
				departmentCode='LOG-SC';
				departmentName = " (Supply Chain) ";
				break;
			default:
				departmentName = " default ";
				console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			deptvalue:departmentCode,
			currentDepartmentTitle:departmentName,
		});
		return departmentCode;
	}
    filterByDepartment = async () => {
		this.setState({
			loadshow:'show-m',
			overly:"show-n",
        })
        var depmntCode = "";
        if(localStorage.getItem("dept_code") !=undefined)
        {            
            var depmntCodeJns = JSON.parse(localStorage.getItem("dept_code"));
            var depmntCode = depmntCodeJns[0];
        }
		let currentDepartmentCode = depmntCode;
		
		if(currentDepartmentCode == 'SNDG')
		{
			var defaulttab = this.state.defaulttab
		}
		else{
			var defaulttab = ""
		}
		
		var israil = 0;
        var userroles = localStorage.getItem("roles");
        if (userroles.indexOf("tpt_rail") > 0)
		{
			israil=1;
			var defaulttab = this.state.defaulttab
        }
        var depmntCode = "";
        if(localStorage.getItem("dept_code") !=undefined)
        {            
            var depmntCodeJns = JSON.parse(localStorage.getItem("dept_code"));
            var depmntCode = depmntCodeJns[0];
		}
		var tptCode = localStorage.getItem('transportercode');
		let layoutdata={
			token:window.atob(localStorage.getItem('_t')),
			israil:israil,
            defaulttab:defaulttab,
            deptcode:currentDepartmentCode,
            is_enmovil_transporter:localStorage.getItem('is_enmovil_transporter'),
            transporter_code:localStorage.getItem('transportercode'),
		}

		var common = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
        function getLocalStorageItem(key, defaultValue = '') {
            const item = localStorage.getItem(key);
            return (item && item !== "null" && item !== "undefined") ? item : defaultValue;
        }
        var tptCode = getLocalStorageItem('transportercode');
        var token = window.atob(localStorage.getItem('_t'))
        defaulttab = (defaulttab.length>0 ? common(defaulttab):"")
        currentDepartmentCode = (currentDepartmentCode.length > 0 ? common(currentDepartmentCode) : "");
        var is_enmovil_transporter = getLocalStorageItem('is_enmovil_transporter');
        var transporter_code = (tptCode.length > 0 ? common(tptCode) : "");

        let layoutdata01={
                    t1:token,
                    is:israil,
                    dt:defaulttab,
                    dc:currentDepartmentCode,
                    et:is_enmovil_transporter,
                    tc:transporter_code,
                }

		this.setState({ deptvalue: currentDepartmentCode});
    
		if(currentDepartmentCode == 'SNDG')
		{
			this.setState({
				sndshow:'show-m',
				departmentchrtshow:'show-n'
			});
		}
		else{
			this.setState({
				sndshow:'show-n',
				departmentchrtshow:'show-m'
			});
		}
		// Get active consignments for grid display
		
		let activeConsignmentsLayoutData = {
			token:window.atob(localStorage.getItem('_t')),
			is_enmovil_transporter:localStorage.getItem('is_enmovil_transporter'),
			transportercode:localStorage.getItem('transportercode'),
			dept_code:currentDepartmentCode,
			defaulttab:defaulttab,
			israil:israil
		}
        // console.log(activeConsignmentsLayoutData,"activeConsignmentsLayoutData")
        var userroles = localStorage.getItem("roles");
        if (userroles.indexOf("tpt_rail") >= 0)
		{
			// Counters data for "Total Trucks" and "On Trip Trucks" under "Total Trucks"
			redirectURL.post('/dashboard/getrailtrucks', activeConsignmentsLayoutData, {
				headers:{
					"Access-Control-Allow-Origin": "*",
					"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
				}
			})
			.then(async (response) => {
				console.log(response,"tptresponse")
				let result = JSON.parse(JSON.stringify(response)).data;
				
				var uqTrucks = result.uniqueTrucks;
				var transporterTrucks = this.state.transporterTrucks;
				var uqTrck = [];
				var uqTrck =await uqTrucks.filter(f =>
					transporterTrucks.some(d => d.truck_no == f.truck_no)
				);
				let totalTrucks = (uqTrck) ? uqTrck : [];
				let totalTrucksCount = (uqTrck) ? uqTrck.length : 0;

				let uniqueActiveConsignments = (result.uniqueActiveConsignments) ? result.uniqueActiveConsignments : [];
				let uniqueActiveConsignmentsCount = (result.uniqueActiveConsignments) ? result.uniqueActiveConsignments.length : 0;

				console.log("In totaltrucks, totaltrucks and count = ", result, totalTrucksCount);
				
				//totalActiveConsignmentsCount
				
				this.setState({
					totalTrucks: totalTrucks,
					truckscnt: totalTrucksCount,
					uniqueActiveConsignments: uniqueActiveConsignments,
					totalActiveConsignmentsCount: uniqueActiveConsignmentsCount,
					loadshow:'show-n'
				});
				this.onShowGridData();
			})
			.catch(function (error) {
				console.log(error);
			});


			/*Trucks with GPS Data*/
			// Counter for "Trucks with GPS Data"
			redirectURL.post('/dashboard/getrailtruckswithgpsdata', activeConsignmentsLayoutData, {
				headers:{
					"Access-Control-Allow-Origin": "*",
					"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
				}
			}) 
			.then(async (response) => {
				let result = JSON.parse(JSON.stringify(response)).data;
				console.log("In gettruckswithgpsdata, result = ", result);

				

				var twgl = result.trucksWithGpsLocation;
				var transporterTrucks = this.state.transporterTrucks;
				var trucksWithGpsLocation = [];
				// if(twgl.length > 0)
				// {
				// 	if(transporterTrucks.length > 0)
				// 	{
				// 		twgl.map(function(t){
				// 			if(transporterTrucks.indexOf(t.truck_no) > -1 )
				// 			{
				// 				trucksWithGpsLocation.push(t)
				// 			}
				// 		})
				// 		var trucksWithGpsLocationCount = trucksWithGpsLocation.length;
				// 	}
				// }

				var trucksWithGpsLocation =await twgl.filter(f =>
					transporterTrucks.some(d => d.truck_no == f.truck_no)
				);
				var trucksWithGpsLocationCount = trucksWithGpsLocation.length;

				var ontripwithgps = (result.ontripwithgps) ? result.ontripwithgps : [];
				
				let gpsDataNotReportedToday = trucksWithGpsLocation.filter(truck => {
					if (truck.timestamp) {
						let result = false;
						let consignmentDate = truck.timestamp;
						let consignmentDateOnly = consignmentDate.toString().slice(0, 10);
						let todayDateOnly = moment.parseZone().format("YYYY-MM-DD HH:mm:ss").toString().slice(0, 10);
						
							// console.log("consignmentDateOnly ", consignmentDateOnly)
							// console.log("todayDateOnly ", todayDateOnly)
						var s1 = moment.parseZone(consignmentDateOnly).format("x")
						var s2 = moment.parseZone(todayDateOnly).format("x")
						// if (consignmentDateOnly !== todayDateOnly) {
						// 	result = true;
						// }
						
						// console.log("consignmentDateOnly s1", s1)
						// 	console.log("todayDateOnly s1", s2)
						// if(s1<s2)
						// {
							result = true;
						//} 
						return result;

					} else {
						//console.log("CONSIGNMENT WITHOUT TIMESTAMP = ", consignment);
					}
				});
				
				//console.log("gpsDataNotReportedToday")
				let gpsDataNotReportedTodayCount = (trucksWithGpsLocation) ? trucksWithGpsLocation.length : 0;
				let trucksWithGpsDataCount = (trucksWithGpsLocationCount && gpsDataNotReportedTodayCount) ? trucksWithGpsLocationCount - gpsDataNotReportedTodayCount : 0;
		

				this.setState({
					trucklocations: trucksWithGpsLocation.length,
					trucksWithGpsLocation:trucksWithGpsLocation,
					ontripwithgps:ontripwithgps.length
					
				});

			})
			.catch(function (error) {
				console.log(error);
			});

		}
		else{
			// Counters data for "Total Trucks" and "On Trip Trucks" under "Total Trucks"
			redirectURL.post('/dashboard/gettrucks', activeConsignmentsLayoutData, {
				headers:{
					"Access-Control-Allow-Origin": "*",
					"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
				}
			})
			.then(async (response) => {
				let result = JSON.parse(JSON.stringify(response)).data;
				// console.log("resultgettrucks ", result)
				
				
				var uqTrucks = result.uniqueTrucks;
				var transporterTrucks = this.state.transporterTrucks;
				var uqTrck = [];
				var uqTrck =await uqTrucks.filter(f =>
					transporterTrucks.some(d => d.truck_no == f.truck_no)
				);
				var tpttrucks = this.state.tpttrucks;
				var tptunqrecords = []
				if(tpttrucks.length > 0)
				{
					await uqTrck.filter(f =>
						tpttrucks.filter((d )=> {
							if(d.truck_no == f.truck_no){									
								tptunqrecords.push(f)
							}
						})
					);
				}
				else
				{
					tptunqrecords = uqTrck
				}
				let totalTrucks = (tptunqrecords) ? tptunqrecords : [];
				let totalTrucksCount = (tptunqrecords) ? tptunqrecords.length : 0;

				let uniqueActiveConsignments = (result.uniqueActiveConsignments) ? result.uniqueActiveConsignments : [];
				let uniqueActiveConsignmentsCount = (result.uniqueActiveConsignments) ? result.uniqueActiveConsignments.length : 0;

				//console.log("In totaltrucks, totaltrucks and count = ", result, totalTrucksCount);
				
				//totalActiveConsignmentsCount
				//console.log("uniqueActiveConsignmentsCount ", uniqueActiveConsignmentsCount)
				this.setState({
					totalTrucks: totalTrucks,
					truckscnt: totalTrucksCount,
					uniqueActiveConsignments: uniqueActiveConsignments,
					totalActiveConsignmentsCount: uniqueActiveConsignmentsCount,
					//loadshow:'show-n'
				});
				this.onShowGridData();
			})
			.catch(function (error) {
				console.log(error);
			});


			/*Trucks with GPS Data*/
			// Counter for "Trucks with GPS Data"
			redirectURL.post('/dashboard/gettruckswithgpsdata', activeConsignmentsLayoutData, {
				headers:{
					"Access-Control-Allow-Origin": "*",
					"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
				}
			}) 
			.then(async (response) => {
				let result = JSON.parse(JSON.stringify(response)).data;
				//console.log("gettruckswithgpsdata, result = ", result);

				var twgl = result.trucksWithGpsLocation;
				var transporterTrucks = this.state.transporterTrucks;
				var trucksWithGpsLocation = [];
				// if(twgl.length > 0)
				// {
				// 	if(transporterTrucks.length > 0)
				// 	{
				// 		twgl.map(function(t){
				// 			if(transporterTrucks.indexOf(t.truck_no) > -1 )
				// 			{
				// 				trucksWithGpsLocation.push(t)
				// 			}
				// 		})
				// 		var trucksWithGpsLocationCount = trucksWithGpsLocation.length;
				// 	}
				// }
				var trucksWithGpsLocation =await twgl.filter(f =>
					transporterTrucks.some(d => d.truck_no == f.truck_no)
				);
				var trucksWithGpsLocationCount = trucksWithGpsLocation.length;

				var ontripwithgps = (result.ontripwithgps) ? result.ontripwithgps : [];
				
				let gpsDataNotReportedToday = trucksWithGpsLocation.filter(truck => {
					if (truck.timestamp) {
						let result = false;
						let consignmentDate = truck.timestamp;
						let consignmentDateOnly = consignmentDate.toString().slice(0, 10);
						let todayDateOnly = moment.parseZone().format("YYYY-MM-DD HH:mm:ss").toString().slice(0, 10);
						
							// console.log("consignmentDateOnly ", consignmentDateOnly)
							// console.log("todayDateOnly ", todayDateOnly)
						var s1 = moment.parseZone(consignmentDateOnly).format("x")
						var s2 = moment.parseZone(todayDateOnly).format("x")
						// if (consignmentDateOnly !== todayDateOnly) {
						// 	result = true;
						// }
						
						// console.log("consignmentDateOnly s1", s1)
						// 	console.log("todayDateOnly s1", s2)
						// if(s1<s2)
						// {
							result = true;
						//} 
						return result;

					} else {
						//console.log("CONSIGNMENT WITHOUT TIMESTAMP = ", consignment);
					}
				});
				
				//console.log("gpsDataNotReportedToday")

				
				var tpttrucks = this.state.tpttrucks;
				var tptgpsrecords = []
				if(tpttrucks.length > 0)
				{
					await trucksWithGpsLocation.filter(f =>
						tpttrucks.filter((d )=> {
							if(d.truck_no == f.truck_no){									
								tptgpsrecords.push(f)
							}
						})
					);
				}
				else
				{
					tptgpsrecords = trucksWithGpsLocation
				}
				var tptgpsrecordscnt = (tptgpsrecords) ? tptgpsrecords : 0;
				let gpsDataNotReportedTodayCount = (tptgpsrecords) ? tptgpsrecords.length : 0;
				let trucksWithGpsDataCount = (tptgpsrecordscnt && gpsDataNotReportedTodayCount) ? tptgpsrecordscnt - gpsDataNotReportedTodayCount : 0;
				
				
				this.setState({
					trucklocations: tptgpsrecords.length,
					ontripwithgpsdata:ontripwithgps,
					ontripwithgps:ontripwithgps.length,
					trucksWithGpsLocation:tptgpsrecords,
					loadshow:'show-n',
					atdealertruckcnt:result.totaldealertrucks,
					atdealernogps:result.atdealernogps,
					alldealertrucks:result.alldealertrucks
					
					
				});
				this.onShowGridData();
				
			})
			.catch(function (error) {
				console.log(error);
			});
		}
		
		if(currentDepartmentCode == 'LOG-SC')
		{
			//// Counter for "Trucks with GPS Data"
			redirectURL.post('/dashboard/getSCData', activeConsignmentsLayoutData)
			.then(async (response) => {
			//	console.log("Response SC ", response.data)
				var ontriptrucks = response.data[0].ontriptrucks;
				var nogpslgsc = response.data[0].nogpslogsc;
				var transporterTrucks = this.state.transporterTrucks;
				var nogpslogsc = [];
				// if(nogpslgsc.length > 0)
				// {
				// 	if(transporterTrucks.length > 0)
				// 	{
				// 		nogpslgsc.map(function(t){
				// 			if(transporterTrucks.indexOf(t.truck_no) > -1 )
				// 			{
				// 				nogpslogsc.push(t)
				// 			}
				// 		})
				// 	}
				// }
				var nogpslogsc =await nogpslgsc.filter(f =>
					transporterTrucks.some(d => d.truck_no == f.truck_no)
				);
				this.setState({
					totalActiveConsignmentsCount:ontriptrucks.length,
					gpsNotTodayActiveConsignments: nogpslogsc,
					gpsNotTodayActiveConsignmentsCount:nogpslogsc.length
				})
			})
			.catch(function(e){

			})

		}
		else{
            var userroles = localStorage.getItem("roles");
             if (userroles.indexOf("tpt_rail") >= 0)
			{
				// Counter for "GPS Data Not Reported Today -> On Trip Trucks"
				redirectURL.post('/dashboard/getrailGpsDataNotReportedTodayOnTripTrucks', activeConsignmentsLayoutData, {
					headers:{
						"Access-Control-Allow-Origin": "*",
						"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
					}
				})
				.then(async (response) => {
					let result = JSON.parse(JSON.stringify(response)).data;
					console.log("result = ", result);
					let twglt = (result.trucksWithGpsLocationNotToday.length) ? result.trucksWithGpsLocationNotToday : [];
					let trucksWithGpsLocationNotTodayCount = (result.trucksWithGpsLocationNotToday.length) ? result.trucksWithGpsLocationNotToday.length : 0;
					let ontripgpsna = (result.ontripgpsna) ? result.ontripgpsna : [];
					var transporterTrucks = this.state.transporterTrucks;
					var trucksWithGpsLocationNotToday = [];
					// if(twglt.length > 0)
					// {
					// 	if(transporterTrucks.length > 0)
					// 	{
					// 		twglt.map(function(t){
					// 			if(transporterTrucks.indexOf(t.truck_no) > -1 )
					// 			{
					// 				trucksWithGpsLocationNotToday.push(t)
					// 			}
					// 		})
					// 	}
					// }
					var trucksWithGpsLocationNotToday =await twglt.filter(f =>
						transporterTrucks.some(d => d.truck_no == f.truck_no)
					);
					this.setState({
						gpsNotTodayActiveConsignments: trucksWithGpsLocationNotToday,
						gpsNotTodayActiveConsignmentsCount: trucksWithGpsLocationNotToday.length,
						ontripgpsna:ontripgpsna
						
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			}
			else{
			}
			
		}
		
		
   		
		redirectURL.post('/overview/trucksWithNoTransportersDataCount', layoutdata)
 		  .then((response) => {
 			 
 		   var trucks = JSON.parse(JSON.stringify(response)).data.count;
 		  	// console.log("Truck GPS",trucks);
 		   this.setState({
 			 notrans:trucks
 		   });
 		   
 		    
 		  })
 		  .catch(function (error) {
 		    console.log(error);
 		  });	
   		
   		 
           /*Truck with gpsdata*/	
           //console.log("bhanuteja",layoutdata)
		   redirectURL.post('/overview/truckWithGPSData', layoutdata01)
		  .then(async (response) => {
			 
		   	var recs = JSON.parse(JSON.stringify(response)).data;
		   	if(recs.length > 0)
		   	{
				var transporterTrucks = this.state.transporterTrucks;
				if(transporterTrucks.length > 0)
				{
					var records = [];
					var recordsarr = [];
					// recs.map((t) => {
					// 	if(transporterTrucks.indexOf(t.truck_no) >= 0 )
					// 	{
					// 		recordsarr.push(t)
					// 	}
					// })

					var recordsa =await recs.filter(f =>
						transporterTrucks.filter((d )=> {
							if(d.truck_no == f.truck_no){
								// console.log("API ", d)
								if(d.registereddevices != undefined)
								{
									if(d.registereddevices.length > 0)
									{
										f.device_address = d.registereddevices[0].address
										f.device_city = d.registereddevices[0].city
										f.device_state = d.registereddevices[0].state
									}
								}
								f.timestamp = d.last_active_on;
								f.modified_date = d.modified_date;
								// return f;
								records.push(f)
							}
						})
					);
					var tpttrucks = this.state.tpttrucks;
					var tptrecords = []
					if(tpttrucks.length > 0)
					{
						await records.filter(f =>
							tpttrucks.filter((d )=> {
								if(d.truck_no == f.truck_no){									
									tptrecords.push(f)
								}
							})
						);
					}
					else
					{
						tptrecords = records
					}
					// consolse.log("records ", records)
					//console.log("In indexcomponent, /overview/truckWithGPSData, records = ", records);
					this.setState({
						rowData:tptrecords
					});
				}
		   	}	   
		    
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

	onShowGridData = async () =>{
		this.setState({
			rowwithoutData:[]
		});
		try{
			var totalTrucks = this.state.totalTrucks;
			if(totalTrucks.length > 0)
			{
				
				var trucksWithGpsLocation = this.state.trucksWithGpsLocation;
				if(trucksWithGpsLocation.length > 0)
				{
					var resultTrucks = _(totalTrucks) 
					.differenceBy(trucksWithGpsLocation, 'truck_no')
					.value();
					var result3 = [];
					if(resultTrucks.length > 0)
					{						
						var transporterTrucks = this.state.transporterTrucks;
						// if(transporterTrucks.length > 0)
						// {
						// 	resultTrucks.map(function(t){
						// 		if(transporterTrucks.indexOf(t.truck_no) > -1 )
						// 		{
						// 			result3.push(t)
						// 		}
						// 	})
						// }
						var result3 =await resultTrucks.filter(f =>
							transporterTrucks.filter((d )=> {
								if(d.truck_no == f.truck_no){
									if(d.registereddevices != undefined)
									{
										if(d.registereddevices.length > 0)
										{
											f.device_address = d.registereddevices[0].address
											f.device_city = d.registereddevices[0].city
											f.device_state = d.registereddevices[0].state
										}
									}
									f.timestamp = d.last_active_on;
									f.modified_date = d.modified_date;
									return f;
								}
							})
						);
					}
				}
				else{
					var result3 = []
				}
				// console.log("result3 ",result3)
				this.setState({
					rowwithoutData:result3
				});
				// console.log("result3 ",trucksWithGpsLocation);
			}
            // var userroles = localStorage.getItem("roles");
            // if (userroles.indexOf("tpt_rail") >= 0)
			// {
			// 	var uniqueActiveConsignments = this.state.uniqueActiveConsignments
			// 	var ontripwithgpsdata = this.state.ontripwithgpsdata

			// 	 //console.log("uniqueActiveConsignments ", JSON.stringify(uniqueActiveConsignments))
			// 	// console.log("ontripwithgpsdata ", ontripwithgpsdata)

			// 	var ontripnoresult =await uniqueActiveConsignments.filter(f =>
			// 		!ontripwithgpsdata.some(d => d.truck_no == f.truck_no)
			// 	);
			// 	//console.log("ontripnoresult ", JSON.stringify(ontripnoresult))
				
			// 	var primaryontrips = []
			// 	var secondaryontrips = []
			// 	if(ontripnoresult.length > 0)
			// 	{
			// 		await ontripnoresult.map((rd) => {
			// 			if(rd.consigner_code == "F" || rd.consigner_code == "M" 
			// 			|| rd.consigner_code == "G")
			// 			{
			// 				primaryontrips.push(rd)
			// 			}
			// 			else
			// 			{
			// 				secondaryontrips.push(rd)
			// 			}
			// 		})

			// 		await this.setState({
			// 			gpsNotTodayActiveConsignments:ontripnoresult,
			// 			primaryontrips:primaryontrips.length,
			// 			secondaryontrips:secondaryontrips.length
			// 		})
				
					
			// 	}
			// 	else
			// 	{
			// 		await this.setState({
			// 			gpsNotTodayActiveConsignments:[],
			// 			primaryontrips:0,
			// 			secondaryontrips:0
			// 		})
			// 	}
			// 	//console.log("onshow ontripnoresult ", ontripnoresult.length)
			// }

			var alldealertrucks = this.state.alldealertrucks
			var atdealernogps = this.state.atdealernogps

			var adngn =await alldealertrucks.filter(f =>
				atdealernogps.some(d => d.truck_no == f.truck_no)
			);
			var atdealernogpsNA = [];
			var transporterTrucks = this.state.transporterTrucks;
			var atdealernogpsNA = [];
			// if(adngn.length > 0)
			// {
			// 	if(transporterTrucks.length > 0)
			// 	{
			// 		adngn.map(function(t){
			// 			if(transporterTrucks.indexOf(t.truck_no) > -1 )
			// 			{
			// 				atdealernogpsNA.push(t)
			// 			}
			// 		})
			// 	}
			// }
			var atdealernogpsNA =await adngn.filter(f =>
				transporterTrucks.some(d => d.truck_no == f.truck_no)
			);
			await this.setState({
				rowdataatdealer:atdealernogpsNA
			})
			//console.log("atdealernogpsNA ", atdealernogpsNA)
		}
		catch(e){

		}
		//console.log("trucksWithGpsLocation ",this.state.trucksWithGpsLocation)
		//console.log("uniqueActiveConsignments ",this.state.totalTrucks)
		


		// var difftrucks = []
		// var result =await totalTrucks.filter(f =>
		// 	!trucksWithGpsLocation.some(d => d.truck_no == f.truck_no)
		//   );
		// //console.log("difftrucks ", result)
		// if(result.length > 0)
		// {

		// 	await this.setState({
		// 		rowwithoutData:result
		// 	});
		// }
		// else
		// {

		// 	await this.setState({
		// 		rowwithoutData:[]
		// 	});
		// }

		
	}
	
	/*Truck Sidebar Component Starts*/
    async onShowSidebarAquireComponent(e){
		this.setState({
			sliderTranslate:""
		});
		//console.log(e);
		if(e.colDef.field == 'truck_no')
		{
			let ComponentInfo = ComponentIndex['truck'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata1 = {
				truck_no:e.data[e.colDef.field]
			};
			//console.log("layoutdata",layoutdata);
			await redirectURL.post('/masters/truckByNo', layoutdata1)
				.then((response) => {
					//console.log("trucks response", response);
					var truckrecords = JSON.parse(JSON.stringify(response)).data;
					
					this.setState({
						truckInfo:truckrecords
					});
				})
				.catch(function (error) {
					console.log(error);
				});

				/*Trucks details by Truck No*/
			await redirectURL.post('/masters/latestConsignmentsByTruckNo', layoutdata1)
				.then((response) => {
					//console.log(response);
					var consignrecords = JSON.parse(JSON.stringify(response)).data;
					
					this.setState({
						consignments:consignrecords
					});
				})
				.catch(function (error) {
					console.log(error);
				});
			
			/*Trucks details by Truck No*/
			await redirectURL.post('/masters/latestTruckLocation', layoutdata1)
				.then((response) => {
					//console.log("HR55W4880",response);
					var currenttrucklocation = JSON.parse(JSON.stringify(response)).data;
					
					this.setState({
						currenttrucklocation:currenttrucklocation
					});
				})
				.catch(function (error) {
					console.log(error);
				});
			//console.log("currenttrucklocation",this.state.currenttrucklocation);
			//setTimeout(function(){})
			this.displayData=await (<ComponentInfo context={this} truckNo={e.data[e.colDef.field]} truckInfo={this.state.truckInfo}  consignments={this.state.consignments} currenttrucklocation={this.state.currenttrucklocation}></ComponentInfo>);
					
			this.setState({
				showdata : this.displayData,
				sliderTranslate:"slider-translate"
			});
		}

		/*Transporters Sidebar*/
		
		if(e.colDef.field == 'transporter_name')
		{
			let ComponentInfo = ComponentIndex['transporter'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata1 = {
				transporter_name:e.data[e.colDef.field]
			};
			//console.log("layoutdata",layoutdata);
			await redirectURL.post('/masters/transporterByName', layoutdata1)
				.then((response) => {
					//console.log("transporterByName response", response);
					var transporterrecords = JSON.parse(JSON.stringify(response)).data;
					var deptcodes=[];
					var transportName='';
					var transportCode='';
					transporterrecords.map(item=>{
						transportName=item.transporter_name;
						transportCode=item.transporter_code;
						deptcodes.push(item.dept_code+", ");
					});
					//console.log(transportName+","+transportCode+","+deptcodes)
					var transportData = [{
						transporter_name:transportName,
						transporter_code:transportCode,
						dept_code:deptcodes
					}]
					this.setState({
						transporterInfo:transportData
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*Total Trucks Operating*/
			await redirectURL.post('/masters/totalTrucksOperating', layoutdata1)
			.then((response) => {
				//console.log("Total Trucks Operating response", response);
				this.setState({
					truckoperates:response
				});
			})
			.catch(function (error) {
				console.log(error);
			});

			/*Total Trucks With GPS Integration Lifetime*/
			await redirectURL.post('/masters/totalTrucksGPSLifetime', layoutdata1)
			.then((response) => {
				//console.log("Total Trucks Operating response", response);
				this.setState({
					truckgpslifetime:response
				});
			})
			.catch(function (error) {
				console.log(error);
			});
				
			/*Total Trucks With GPS Integration Today*/
			await redirectURL.post('/masters/totalTrucksGPSToday', layoutdata1)
			.then((response) => {
				//console.log("Total Trucks Operating response", response);
				this.setState({
					gpstoday:response
				});
			})
			.catch(function (error) {
				console.log(error);
			});

				/*Transporters Consignment details*/
			await redirectURL.post('/masters/latestConsignmentsByTransporterName', layoutdata1)
				.then((response) => {
					//console.log(response);
					var consignrecords = JSON.parse(JSON.stringify(response)).data;
					var consignrecordslist=[];
					consignrecords.map(item=>{
						if(item.consigns.length > 0)
						{
							item.consigns.map(cons => {
								consignrecordslist.push(cons)
							})
							
						}
					});
					
					//console.log("consignrecords list", consignrecordslist);
					this.setState({
						consignments:consignrecordslist
					});
				})
				.catch(function (error) {
					console.log(error);
				});
			
			
			this.displayData=await (<ComponentInfo context={this} gpstoday={this.state.gpstoday} gpsLifetime={this.state.truckgpslifetime} transportName={e.data[e.colDef.field]} transporterInfo={this.state.transporterInfo} totalTruckOperating={this.state.truckoperates} consignments={this.state.consignments} ></ComponentInfo>);
					
			this.setState({
				showdata : this.displayData,
				sliderTranslate:"slider-translate"
			});
		}
		/*End*/
		
		/*LSP Sidebar*/
		
		if(e.colDef.field == 'actual_lspuser')
		{
			let ComponentInfo = ComponentIndex['lsp'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata2 = {
				actual_lspuser:e.data[e.colDef.field]
			};
			console.log("layoutdata2", layoutdata2);
			//Total Trucks Integrate;
			await redirectURL.post('/masters/totalTruckIntegrate', layoutdata2)
				.then((response) => {
					var lsprecords = JSON.parse(JSON.stringify(response)).data.count;
					//console.log("LSP response serveeeee", lsprecords);
					
					var result = lsprecords.reduce(function(sum, item){
						return sum = sum+item.count;
					},0);
						//console.log("result", result);
					this.setState({
						lspInfo:result
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*Department Serving*/
			await redirectURL.post('/masters/totalDeptServe', layoutdata2)
			.then((response) => {
				var deprecords = JSON.parse(JSON.stringify(response)).data;
				//console.log("LSP DeptServe response", deprecords);
				
				var deptcnt=[];
				deprecords.map(item => {
					deptcnt.push(item._id)
				});
				this.setState({
					deptServes:deptcnt.length
				});
			})
			.catch(function (error) {
				console.log(error);
			});

			/*No Of Transporters Serving*/
			await redirectURL.post('/masters/totalTransporterServe', layoutdata2)
			.then((response) => {
				var transrecords = JSON.parse(JSON.stringify(response)).data;
				//console.log("Transporter serve response", transrecords);
				var trasportcnt=[];
				transrecords.map(item => {
					trasportcnt.push(item._id)
				});
				this.setState({
					transrecordserves:trasportcnt.length
				});
			})
			.catch(function (error) {
				console.log(error);
			});

			/*No Of GPS DATA Send Today*/
			await redirectURL.post('/masters/totalGPSSendToday', layoutdata2)
			.then((response) => {
				var gpssendrecords = JSON.parse(JSON.stringify(response)).data;
				//console.log("GPS Send Data response", gpssendrecords);
				
				this.setState({
					gpssendtoday:gpssendrecords.length
				});
			})
			.catch(function (error) {
				console.log(error);
			});

			/*Total Trucks with no transporter data*/
			await redirectURL.post('/masters/trucksWithNoTransporter', layoutdata2)
			.then((response) => {
				var notransrecords = JSON.parse(JSON.stringify(response)).data;
				//console.log("No Trasnporter Data response", notransrecords);
				
				this.setState({
					nopetransporters:notransrecords.length
				});
			})
			.catch(function (error) {
				console.log(error);
			});
			
			this.displayData=await (<ComponentInfo context={this} nopetransporters={this.state.nopetransporters} gpssendtoday={this.state.gpssendtoday} transportersServes={this.state.transrecordserves} deptServes={this.state.deptServes} lspName={e.data[e.colDef.field]} lspInfo={this.state.lspInfo} ></ComponentInfo>);
					
			this.setState({
				showdata : this.displayData,
				sliderTranslate:"slider-translate"
			});
		}
		/*End*/
	} 
	onGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		//this.gridApi.sizeColumnsToFit();
	}
	/*Truck Sidebar Component End*/
	onCloseUploadDiv = () => {
		this.setState({
			sliderTranslate:"",
		});
	}

	onClickShowTab = async(tab) =>{
		await this.setState({
			defaulttab:tab
		});
		await this.filterByDepartment()
	}
	onClickShowRailTab = async(tab) => {
		await this.setState({
			defaulttab:tab
		});
		await this.filterByDepartment()
	}
	onCloseUserManualModal = () => {
		this.setState({ usermanualmodal: false });
	};

  onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
	};

	onClickShowTPTTruckLocation(e){
		this.setState({
			loadshow:'show-m',			
			overly:'show-m',
			inputTruck:e.data.truck_no
		})
        var formdata= {
			truck_no:e.data.truck_no,
			startDate: moment.parseZone(new Date()).utcOffset("+05:30").format("YYYY-MM-DD")+" 00:00:00",
			endDate:moment.parseZone(new Date()).utcOffset("+05:30").format("YYYY-MM-DD HH:mm:ss"),
			frequency:"",
			source:"tptlogin"
		}
		console.log("formdata ", formdata)
		redirectURL.post("/gmap",formdata,{
			headers:{
				'Content-Type': 'application/json',
				// 'Access-Control-Allow-Origin':"*",
				// 'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE, TOKEN',
				'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
			}
		})
		//.then(res => res.json())
		.then(
		async (result) => {
			//console.log("result ",result.data);
			var jsondata = result.data;
			 console.log(jsondata)
			if(jsondata.status == 'failure')
			{
				this.setState({
					show:true,
					basicTitle:'No GPS data available for this period',
					basicType:"danger",
					loadshow:'show-n',
					overly:"show-n",
					mapData:null,
					mapinfo:'',
				})
			}
			else{
				var coordinates = JSON.parse(jsondata.data);
				//console.log("truckno ", this.props.truckno)
				//console.log("coordinates ", coordinates)
				var dataarr=[]
				// console.log("coordinates ", coordinates)
				coordinates.coords.map((item) => {
					// console.log("item ,", item)
					
					dataarr.push({
						"truck_no":item.truck_no, 
						"lat":item.lat, 
						"lng":item.lng, 
						"speed":item.speed, 
						"stime":item.stime,
						"address":item.area,
						//"state":item.state,
						//"area":item.area,
						//"city":item.city,
						//"location_type":item.location_type,
						"dist_from_prev_point":item.dist_from_prev_point, 
						"received_on":item.received_on, 
						"time_from_prev_point":item.time_from_prev_point,
						"area" : item.area,
						"state" : item.state
					})
				});
				//console.log("Modified ", dataarr)
				this.setState({
					mapData:dataarr,
					mapinfo:JSON.parse(jsondata.data),
					loadshow:'show-n',
					overly:'show-m',
					tolls : JSON.parse(jsondata.data).tolls,
					sliderRouteTranslate:"slider-translate-75p",
				})
				this.renderMap()
		
			}
				
		}
		)
		.catch(function (error) {
			console.log(error);
		});
		
    }
    showmarkers(event)
    {
        console.log("showmarkers ", event.target.value);
        if(event.target.value == 0)
        {
            viewMarkersArr.map(function(e){
				e.setVisible(true);
            });
            this.setState({
                showmarkers : 1
            })
        }
        else
        {
			viewMarkersArr.map(function(e){
				e.setVisible(false);
            });
            this.setState({
                showmarkers : 0
            })
        }
	}
	onClickShowTruckLocation(e){
        // console.log("CLiclable ", e)
        // var lat = parseFloat(e.data.lat);
		// var lng =parseFloat(e.data.lng);
		// var data =e.data;
		// map.setCenter(new window.google.maps.LatLng(lat,lng));
		// map.setZoom(22);
		// var markerLatlng = new window.google.maps.LatLng(lat, lng);
		// //var image =  require('../../assets/icons/truckblue.png');
		// var image =  require('../../assets/icons/gmarker.png');
		
		// var marker = new window.google.maps.Marker({
		// 	position: markerLatlng,
		// 	map: map,
		// 	title:data.truck_no,
		// 	icon:image
		// });


		// var contentarr = []
		// var header = data.truck_no
		// //contentarr.push({"key":"Reached On", "value":getDDMMYYYYHHMMSS(data.reached_on)})
		// contentarr.push({"key":"City/Area", "value":data.area})
		// contentarr.push({"key":"State", "value":data.state})

		// var contentString = infoBox(marker.icon, header, contentarr,'')
			
		// var infowindow = new window.google.maps.InfoWindow({
		// 	content: contentString
		// });
		
		// marker.setAnimation(window.google.maps.Animation.DROP)
		// marker.addListener('click', function() {
		// 	infowindow.open(map, marker);
		// });
		
		// marker.setMap(map);
		
    }
	renderMap = () => {   
		
		// loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMap");
		// window.initMap = this.initMap
	}
    initMap = () => {
        //console.log("arr ",arr)
        var currentwindow;
		var dealer='';
		var tolls = this.state.tolls;
		
		var arr=[];
        var jsondata = this.state.mapinfo;
        var locations = jsondata.breaks;
        var arr = jsondata.coords;
		if(arr.length > 0 && typeof arr.length != undefined)
		{
            var lt=arr[0].lat;
            var ln=arr[0].lng;
		}
		else{
            var lt=28.4519751;
            var ln=77.0310713;
		}
        var routeinfo ;
        try{
			var mapOptions = {
				zoom: 13,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: true,
				fullscreenControl: true,
				labels:true,
				mapTypeControlOptions: {
					mapTypeIds: ['satellite', 'roadmap'],
				},
				center: new window.google.maps.LatLng(lt,ln),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP
			};
					
		}
		catch(e){
			var mapOptions = {
				zoom: 13,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: true,
				fullscreenControl: true,
				labels:true,
				mapTypeControlOptions: {
					mapTypeIds: ['satellite', 'roadmap'],
				},
				center: new window.google.maps.LatLng(28.4519751,77.0310713),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP
			};
					
		}
        try{
			map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
		}catch(e){}
		
		var bounds = new window.google.maps.LatLngBounds();
		bounds.extend(new window.google.maps.LatLng(lt,ln));
			// Create our info window content
		var currentinfowindow = null;
		var line=new window.google.maps.Polyline(
		{
			map:map,
			strokeColor: '#157254',
			strokeOpacity: 1.0,
			strokeWeight: 2.5,
			icons: [{
					// icon: {
					// 		path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
					// 		strokeColor:'#ff8c52',
					// 		fillColor:'#ff8c52',
					// 		fillOpacity:1,
					// 		strokeWeight: 2
					// },
					repeat:'100px',
					path:[]
				}]
            });
        if(arr.length > 0)
		{
            var allpoints = [];
		
			var arr1=[];
			//console.log("Total ",ratio);
			if(this.state.timelinesmarkers.length > 0)
			{
				arr1 = this.state.timelinesmarkers.filter(function (value, index, arr) {
					return (index % ratio == 0);
				} );
				
	
				//var arr1 = this.state.timelinesmarkers;
            }
            
            var arr2=[];
            var darr=[];
            if(arr.length > 0)
			{
				darr = arr.filter(function (value, index, arr) {
					//console.log("Vale ",value)
					return (value.dist_from_prev_point > 0);
				} );
				if(darr.length < 2000)
				{
					var ratio = 1;
				}
				else{
					var ratio = 20;
				}
				//console.log("darr.length ", darr.length)
				arr2 = darr.filter(function (value, index, darr) {
					return (index % ratio == 0);
				} );
				
	
				//var arr1 = this.state.timelinesmarkers;
            }
            // view markers code
            if(arr2.length > 0)
            {
                    // //console.log("allpoints ", allpoints)
                    
                    for(var a=0;a<arr2.length;a++)
                    {
                        //console.log(arr2[a],"arr2[a]");
                        var marker = new window.google.maps.Marker({
                            position: new window.google.maps.LatLng(arr2[a].lat, arr2[a].lng),
                            icon: {
                                    path: window.google.maps.SymbolPath.CIRCLE,
                                    strokeColor:'#157254',
                                    fillColor:'#157254',
                                    fillOpacity:1,
                                    strokeWeight: 5,
                                    scale:1,
                                },
                            map: map,
                            content:arr2[a]
                        });
                        marker.setVisible(false);
                        // 	//console.log("Arr ",arr1[a])
                        window.google.maps.event.addListener(marker, 'mouseover', (function(marker) {
                            return function() {
                                //console.log("Arr ",marker)
                                var contentarr = []
                            //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                            var header = "Current Status"
                            contentarr.push({"key":"Time ", "value":getDDMMYYYYHHMMSS(marker.content.stime)})
                            contentarr.push({"key":"Speed ", "value":marker.content.speed+" KMPH"})
                            
                            var contentString = infoBox("", header, contentarr,'')
                            
                            
                            infowindow.setContent(contentString);
                            currentwindow = infowindow;
                            infowindow.open(map, marker);
                            //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                            }
                        })(marker));
                        window.google.maps.event.addListener(marker, 'mouseout', function() {
                            infowindow.close();
                        });
        
                        viewMarkersArr.push(marker);
                        
                    }
            }
            var routeinfo = jsondata.route_details;
            var icon_cnt = 100
            for(var i=0;i< arr2.length-1;i++)
            {
                var locationLatLng = [];
                locationLatLng.push(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
                locationLatLng.push(new window.google.maps.LatLng(arr2[i+1].lat,arr2[i+1].lng));
                if(icon_cnt % 10 == 0)
                {
                    var lineicon = [{
                                // icon: {
                                //         path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                //         strokeColor:'#FF0000',
                                //         fillColor:'#FF0000',
                                //         fillOpacity:1,
                                //         strokeWeight: 2
                                // },
                                repeat:'100px',
                                path:[]
                            }];
                }else{lineicon=[]}
                icon_cnt = icon_cnt + 1
                var consignments_missing_route_line = new window.google.maps.Polyline({
                    map:map,
                    path : locationLatLng,
                    strokeColor: '#157254',
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    icons: lineicon
                });
                // console.log(arr2[i].msgtype)
                if(arr2[i].msgtype == "G")
                {
                    
                    consignments_missing_route_line.setOptions({
                        strokeColor : "#452a68",
                        strokeWeight: 5.5,
                    })
                }
                bounds.extend(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
            }
				
                // for (let i = 0; i < arr2.length; i++) {
                //     //if(arr2[i].speed > 0)
                //     //{
                //         var linecolr = "#157254";
                //         if(arr2[i].set  == 2)
                //         {
                //             linecolr = "#0000ff";
                //         }
                //         if(arr2[i].set  == 3)
                //         {
                //             linecolr = "#980000"
                //         }
                //         line.setOptions({strokeColor: linecolr}); 
                //     var path=line.getPath().getArray();
                            
                //     let latLng=new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng);
                //     path.push(latLng);
                //     line.setPath(path);
                        
                //     //Change line color based on map type
                //     window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
                //         if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
                //         {
                //             var line=new window.google.maps.Polyline(
                //             {
                //                 map:map,
                //                 strokeColor: '#FFFFFF',
                //                 strokeOpacity: 1.0,
                //                 strokeWeight: 2.5,
                //                 icons: [{
                //                         icon: {
                //                                 path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                //                                 strokeColor:'#ff8c52',
                //                                 fillColor:'#ff8c52',
                //                                 fillOpacity:1,
                //                                 strokeWeight: 2
                //                                 },
                //                         repeat:'100px',
                //                         path:[]
                //                     }]
                //                 });                
                //         }
                //         else
                //         {
                //             var line=new window.google.maps.Polyline(
                //             {
                //                 map:map,
                //                 strokeColor: '#157254',
                //                 strokeOpacity: 1.0,
                //                 strokeWeight: 2.5,
                //                 icons: [{
                //                         icon: {
                //                                 path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                //                                 strokeColor:'#ff8c52',
                //                                 fillColor:'#ff8c52',
                //                                 fillOpacity:1,
                //                                 strokeWeight: 2
                //                                 },
                //                         repeat:'100px',
                //                         path:[]
                //                     }]
                //                 });

                //         }

                //         for (i = 0; i < arr2.length; i++) {
                //                 var path=line.getPath().getArray();
                //                 let latLng=new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng);
                //                 path.push(latLng);
                //                 line.setPath(path);
                //                         //map.setCenter(latLng);
                //             }
                                        
                //         var x = map.getZoom();
                //         var c = map.getCenter();
                //         window.google.maps.event.trigger(map, 'resize');
                //         map.setZoom(x);
                //         map.setCenter(c);
                //     } );
                //     bounds.extend(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
                //     //}
                // }

				var infowindow = new window.google.maps.InfoWindow();
					var marker, l;

					for (l = 0; l < locations.length; l++) {
					marker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
					icon:require('../../assets/icons/cf.png'),
					map: map,
					});

					window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
						return function() {
							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time)+")</p>";
						
						var contentarr = []
						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						var header = "Break";
						try{
							if(typeof this.props.mapFor != 'undefined')
							{
                                var timeinsecs = moment.parseZone(locations[l].break_start).format("x") - moment.parseZone(locations[l].break_start).format("x")
                                contentarr.push({"key":"Break Start", "value":locations[l].break_start})
                                contentarr.push({"key":"Break End", "value":locations[l].break_end})
                                contentarr.push({"key":"Break time", "value":timeConvert(locations[l].break_time_seconds)})
							}
							else
							{
                                contentarr.push({"key":"Break Start", "value":locations[l].break_start})
                                contentarr.push({"key":"Break End", "value":locations[l].break_end})
                                contentarr.push({"key":"Break time", "value":timeConvert(locations[l].break_time_seconds)})
                            }
						}
						catch(e){
                            contentarr.push({"key":"Break Start", "value":locations[l].break_start})
                            contentarr.push({"key":"Break End", "value":locations[l].break_end})
                            contentarr.push({"key":"Break time", "value":timeConvert(locations[l].break_time_seconds)})
						}
						
						
						var contentString = infoBox(marker.icon, header, contentarr,'')
						
						infowindow.setContent(contentString);
						currentwindow = infowindow;
						infowindow.open(map, marker);
						//console.log(marker.position.toJSON());
						console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
						}
					})(marker, l));
					}
					marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
						icon:require('../../assets/icons/track_start.png'),
						map: map,	
					});
				window.google.maps.event.addListener(marker, 'click', (function(marker) {
						return function() {
							//	console.log(clusters[0])
							
							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						var contentarr = []
						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						var header = "Starting Point"
						contentarr.push({"key":"Started at", "value":routeinfo.start_time})
						
						var contentString = infoBox(marker.icon, header, contentarr,'')
							
						infowindow.setContent(contentString);
						currentwindow = infowindow;
						infowindow.open(map, marker);
						console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
						}
					})(marker));
				marker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(arr[(arr.length-1)].lat, arr[(arr.length-1)].lng),
					icon:require('../../assets/icons/truck-end.png'),
					map: map,	
				});
				window.google.maps.event.addListener(marker, 'click', (function(marker) {
						return function() {
							//	console.log(clusters[0])
							
						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
						var contentarr = []
						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						var header = "End Point"
						contentarr.push({"key":"End at", "value":routeinfo.end_time})
						
						var contentString = infoBox(marker.icon, header, contentarr,'')
							
						infowindow.setContent(contentString);	
						infowindow.setContent(contentString);
						currentwindow = infowindow;
						infowindow.open(map, marker);
						console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
						}
					})(marker));
			
            }
            try{
                if(tolls.length > 0)
                {
                    var infowindow = new window.google.maps.InfoWindow();
                    var l;
                    tolls.map(function(e,index){
                        var tollMarker = new window.google.maps.Marker({
                            position: new window.google.maps.LatLng(e.lat, e.lon),
                            icon:require('../../assets/icons/barrier.png'),
                            map: map,
                        });
                        window.google.maps.event.addListener(tollMarker, 'mouseover', (function(tollMarker, index) {
                            return function() 
                            {
                                var contentarr = []
                                var header = "Toll - "+e.name
                                contentarr.push({"key":"Address", "value":e.location})
                                var contentString = infoBox(tollMarker.icon, header, contentarr)
                                infowindow.setContent(contentString);
                                var currentwindow = infowindow;
                                infowindow.open(map, tollMarker);
                            }
                        })(tollMarker, index));
                        window.google.maps.event.addListener(tollMarker, 'mouseout', function() {
                            infowindow.close();
                            });
                    })  
                    
                }
            }catch(e){}
            
        
            map.fitBounds(bounds)

	}

	formHandler = async (event) => {
        this.setState({
			loadshow:'show-m',
			overly:'show-m',
        })
        event.preventDefault();
        var inputTruck = this.state.inputTruck;
        var dataset = this.state.truckNoList;
        
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedGenerate,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		//var formdata = new FormData(event.target);
		// console.log($("#inputTruck").val())
	
		var formdata= {
				truck_no:this.state.inputTruck,
				startDate:getHyphenYYYYMMDDHHMMSS(this.state.startDate),
				endDate:getHyphenYYYYMMDDHHMMSS(this.state.endDate),
				frequency:this.state.frequency
		}
		console.log("formdata ", formdata)
		await redirectURL.post("/gmap",formdata,{
			headers:{
				'Content-Type': 'application/json',
				// 'Access-Control-Allow-Origin':"*",
				// 'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE, TOKEN',
				'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
			}
		})
		//.then(res => res.json())
		.then(
		async (result) => {
			//console.log("result ",result.data);
			var jsondata = result.data;
			//  console.log(JSON.parse(jsondata.data))
			if(result.data.status == 'failure')
			{
				this.setState({
					show:true,
					basicTitle:'No GPS data available for this period',
					basicType:"danger",
					loadshow:'show-n',
					mapData:null,
					mapinfo:'',
				})
			}
			else{
				var coordinates = JSON.parse(jsondata.data);
				//console.log("truckno ", this.props.truckno)
				//console.log("coordinates ", coordinates)
				var dataarr=[]
				// console.log("coordinates ", coordinates)
				coordinates.coords.map((item) => {
					// console.log("item ,", item)
					dataarr.push({
						"truck_no":item.truck_no, 
						"lat":item.lat, 
						"lng":item.lng, 
						"speed":item.speed, 
						"stime":item.stime,
						"address":item.area,
						//"state":item.state,
						//"area":item.area,
						//"city":item.city,
						//"location_type":item.location_type,
						"dist_from_prev_point":item.dist_from_prev_point, 
						"received_on":item.received_on, 
						"time_from_prev_point":item.time_from_prev_point,
						"area" : item.area,
						"state" : item.state
					})
				});
				//console.log("Modified ", dataarr)
				this.setState({
					mapData:dataarr,
					mapinfo:JSON.parse(jsondata.data),
					loadshow:'show-n',
					tolls : JSON.parse(jsondata.data).tolls
				})
				this.renderMap()
		
			}
				
		}
		)
		.catch(function (error) {
			console.log(error);
		});
        
    }

    render(){
		const statedeptcode = this.state.deptvalue;
		//console.log("statedeptcode ",statedeptcode)
		var columnwithDefs= [
			{
				headerName:"",
				field:"",
				width:50,
				cellRenderer:'tpttrucklocaction'
			},
			{
			  headerName: "Truck No",
			  field: "truck_no",
			  width: 150,
			  
			  
			},
		// 	{
		// 	  headerName: "LSP Code",
		// 	  field: "transporter_code",
		// 	  width: 200,
				  
		//   },  	        
		//   {
		// 	headerName: "LSP Name",
		// 	field: "transporter_name",
		// 	width: 200,
				
		//   },
		// 	{
		// 	headerName: "Department Code",
		// 	field: "dept_code",
		// 	width: 200,
				
		//   },
		// 	{
		// 	headerName: "Actual GPS Provider",
		// 	field: "actual_lspuser",
		// 	width: 200,
		// 	valueGetter: function(params){
		// 		return "Enmovil";
		// 	}
		//   },
		
		{
			headerName: "Address",
			field: "device_address",
			width: 200,
		},
		{
			headerName: "City",
			field: "device_city",
			width: 170,
		},
		{
			headerName: "State",
			field: "device_state",
			width: 170,
		},
		//   {
		// 	  headerName: "Last Known City/Town",
		// 	  field: "city",
		// 	  width: 170,
		//   },
		//   {
		// 	headerName: "Last Known State",
		// 	field: "state",
		// 	width: 170,
		//   },
		  {
			headerName: "Travel Status",
			field: "elaped_time_from_last_moved_seconds",
			width: 180,
			valueGetter:function(params){
				try{
					if(params.data.elaped_time_from_last_moved_seconds == 0)
					{
						return "Travelling";
					}
					else{
						var mre = ConvertSeconds(params.data.elaped_time_from_last_moved_seconds)
						return "Idle ("+mre+")";
					}
				}
				catch(e){
					return "";
				}
			}
		  },
		  {
			headerName: "Distance Travelled Today (KM)",
			field: "today_distance",
			width: 200,
		  },
			{
			  headerName: "Last Packet Time",
			  field: "timestamp",
			  width: 200,
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
			  return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
			  },
			  //filter: "agDateColumnFilter",
			  comparator: dateComparator,
			//   filterParams: {
			// 	  browserDatePicker: true,
			// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
			// 	  // provide comparator function
			// 	  comparator: function(filteredDate,cellValue,secondDate) {
			// 		  if(cellValue != "" && cellValue != " "){
			// 			  cellValue = cellValue.split(" ")[0].split("-");
			// 			  cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
			// 			  var cellDate = new Date(cellValue);
			// 			  if(filteredDate.getMonth() < 10){
			// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  else{
			// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  if(filterValue.split("-")[2].length < 2)
			// 			  {
			// 				  var temp = filterValue;
			// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
			// 			  }
			// 			  var filteredDate = new Date(filterValue);
			// 			  cellDate = cellDate.getTime();
			// 			  filteredDate = filteredDate.getTime();
			// 			  // console.log(cellDate,filteredDate);
			// 			  if(cellDate === filteredDate)
			// 			  {
			// 				  return 0;
			// 			  }
			// 			  if(cellDate < filteredDate){
			// 				  return -1;
			// 			  }
			// 			  if(cellDate > filteredDate)
			// 			  {
			// 				  return 1;
			// 			  }
			// 		  }
			// 	  }
			//   },
				
		  },
		  {
			  headerName: "Data Received On",
			  field: "modified_date",
			  width: 200,
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
				  return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
			  },
			  //filter: "agDateColumnFilter",
			  comparator: dateComparator,
			  //   filterParams: {
			// 	  browserDatePicker: true,
			// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
			// 	  // provide comparator function
			// 	  comparator: function(filteredDate,cellValue,secondDate) {
			// 		  if(cellValue != "" && cellValue != " "){
			// 			  cellValue = cellValue.split(" ")[0].split("-");
			// 			  cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
			// 			  var cellDate = new Date(cellValue);
			// 			  if(filteredDate.getMonth() < 10){
			// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  else{
			// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  if(filterValue.split("-")[2].length < 2)
			// 			  {
			// 				  var temp = filterValue;
			// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
			// 			  }
			// 			  var filteredDate = new Date(filterValue);
			// 			  cellDate = cellDate.getTime();
			// 			  filteredDate = filteredDate.getTime();
			// 			  // console.log(cellDate,filteredDate);
			// 			  if(cellDate === filteredDate)
			// 			  {
			// 				  return 0;
			// 			  }
			// 			  if(cellDate < filteredDate){
			// 				  return -1;
			// 			  }
			// 			  if(cellDate > filteredDate)
			// 			  {
			// 				  return 1;
			// 			  }
			// 		  }
			// 	  }
			//   },
		  }
			 
			 
		  ];
		var columnwithoutDefs = [
			{
			  headerName: "Truck No",
			  field: "truck_no",
			  width: 150,
			  
		  },
		// 	{
		// 	  headerName: "LSP Code",
		// 	  field: "transporter_code",
		// 	  width: 200,
				  
		//   },  				  
		// 	{
		// 	headerName: "LSP Name",
		// 	field: "transporter_name",
		// 	width: 200,
				
		//   },
		// 	{
		// 	headerName: "Department Code",
		// 	field: "dept_code",
		// 	width: 200,
				
		//   },
		//   {
		// 	headerName: "Actual LSP Provider",
		// 	field: "actual_lspuser",
		// 	width: 200,
		// 	valueGetter: function(params){
		// 		return "Enmovil";
		// 	}	
		//   },
		{
			headerName: "Address",
			field: "device_address",
			width: 200,
		},
		{
			headerName: "City",
			field: "device_city",
			width: 170,
		},
		{
			headerName: "State",
			field: "device_state",
			width: 170,
		},

		//   {
		// 	  headerName: "Last Known City/Town",
		// 	  field: "area",
		// 	  width: 170,
		//   },
		//   {
		// 	headerName: "Last Known State",
		// 	field: "state",
		// 	width: 170,
		//   },
		//   {
		// 	headerName: "Distance Travelled Today (KM)",
		// 	field: "today_distance",
		// 	width: 200,
		//   },	
		  {
			  headerName: "Last Packet Time",
			  field: "timestamp",
			  width: 200,
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
			  return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
			  },
			  //filter: "agDateColumnFilter",
			  comparator: dateComparator,
			//   filterParams: {
			// 	  browserDatePicker: true,
			// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
			// 	  // provide comparator function
			// 	  comparator: function(filteredDate,cellValue,secondDate) {
			// 		  if(cellValue != "" && cellValue != " "){
			// 			  cellValue = cellValue.split(" ")[0].split("-");
			// 			  cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
			// 			  var cellDate = new Date(cellValue);
			// 			  if(filteredDate.getMonth() < 10){
			// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  else{
			// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  if(filterValue.split("-")[2].length < 2)
			// 			  {
			// 				  var temp = filterValue;
			// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
			// 			  }
			// 			  var filteredDate = new Date(filterValue);
			// 			  cellDate = cellDate.getTime();
			// 			  filteredDate = filteredDate.getTime();
			// 			  console.log(cellValue,filterValue);
			// 			  console.log(cellDate,filteredDate);
			// 			  if(cellDate === filteredDate)
			// 			  {
			// 				  return 0;
			// 			  }
			// 			  if(cellDate < filteredDate){
			// 				  return -1;
			// 			  }
			// 			  if(cellDate > filteredDate)
			// 			  {
			// 				  return 1;
			// 			  }
			// 		  }
			// 	  }
			//   },
				
		  },
		  {
			  headerName: "Data Received On",
			  field: "modified_date",
			  width: 200,
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
				  return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
			  },
			  //filter: "agDateColumnFilter",
			  comparator: dateComparator,
			  //   filterParams: {
			// 	  browserDatePicker: true,
			// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
			// 	  // provide comparator function
			// 	  comparator: function(filteredDate,cellValue,secondDate) {
			// 		  if(cellValue != "" && cellValue != " "){
			// 			  cellValue = cellValue.split(" ")[0].split("-");
			// 			  cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
			// 			  var cellDate = new Date(cellValue);
			// 			  if(filteredDate.getMonth() < 10){
			// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  else{
			// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  if(filterValue.split("-")[2].length < 2)
			// 			  {
			// 				  var temp = filterValue;
			// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
			// 			  }
			// 			  var filteredDate = new Date(filterValue);
			// 			  cellDate = cellDate.getTime();
			// 			  filteredDate = filteredDate.getTime();
			// 			  // console.log(cellDate,filteredDate);
			// 			  if(cellDate === filteredDate)
			// 			  {
			// 				  return 0;
			// 			  }
			// 			  if(cellDate < filteredDate){
			// 				  return -1;
			// 			  }
			// 			  if(cellDate > filteredDate)
			// 			  {
			// 				  return 1;
			// 			  }
			// 		  }
			// 	  }
			//   },
		  }
		  
	  ];
		var columnGpsNotTodayDefs = [
			{
			  headerName: "Truck No",
			  field: "truck_no",
			  width: 200,
			  
		  },
			{
			  headerName: "LSP Code",
			  field: "transporter_code",
			  width: 140,
				  
		  },  				  
			{
			  headerName: "LSP Name",
			  field: "transporter_name",
			  width: 200,
			//   valueGetter:function(params) {
			// 	  let transporter_name = null;
			// 	  if (params.data.transporter) {
			// 		  if (params.data.transporter.transporter_name) {
			// 			  transporter_name = params.data.transporter.transporter_name;
			// 		  }
			// 	  }
			// 	  return transporter_name;
			//   }
		  },
		  				  
		  {
			headerName: "Plant Code",
			field: "consigner_code",
			width: 200,
				
		  },
		  	
		  {
			headerName: "Consignee City",
			field: "consignee_city",
			width: 140	
		  },
		  {
			headerName: "Consignee State",
			field: "consignee_state",
			width: 140
		  },
		  
		  {
			headerName: "Cluster",
			field: "cluster",
			width: 140
		  },
		{
			headerName: "Department Code",
			field: "dept_code",
			width: 200,
				
		  },
		  {
			  headerName: "Actual LSP Provider",
			  field: "actual_lspuser",
			  width: 200,
			  valueGetter: function(params){
				return "Enmovil";
				}	
			//   valueGetter:function(params){
			// 	  if(params.data.dept_code == 'LOG-SC')
			// 	  {
			// 		return params.data.actual_lspuser;
			// 	  }
			// 	  else
			// 	  {
			// 		let actual_lspuser = null;
			// 		if (params.data.truck) {
			// 			if (params.data.truck.actual_lspuser) {
			// 				actual_lspuser = params.data.truck.actual_lspuser;
			// 			}
			// 		}
			// 		return actual_lspuser;
			// 	  }
				 
			//   }
		  },
		  {
			headerName: "Last Known City/Town",
			field: "area",
			width: 200,
			// valueGetter:function(params){
			// 	if(params.data.dept_code == 'LOG-SC')
			// 	{
			// 		return params.data.area;
			// 	}
			// 	else
			// 	{
			// 		let area = null;
			// 		if (params.data.truck) {
			// 			if (params.data.truck.area) {
			// 				area = params.data.truck.area;
			// 			}
			// 		}
			// 		return area;
			// 	}	
			// }
		  },
		  {
			  headerName: "Last Known State",
			  field: "state",
			  width: 200,
			//   valueGetter:function(params){
			// 	if(params.data.dept_code == 'LOG-SC')
			// 	{
			// 		return params.data.state;
			// 	}
			// 	else
			// 	{
			// 		let state = null;
			// 		if (params.data.truck) {
			// 			if (params.data.truck.state) {
			// 				state = params.data.truck.state;
			// 			}
			// 		}
			// 		return state;
			// 	}
			//   }
		  },
		  // {
		  //   headerName: "Last Packet Time",
		  //   field: "timestamp",
		  //   width: 200,
		  //   //cellRenderer:'dateFormater'
		  //   valueGetter:function(params){
		  // 	return getDDMMYYYYHHMMSS(params.data.timestamp)
		  //   }
		  // },
		  {
			  headerName: "Last Packet Time",
			  field: "timestamp",
			  width: 200,
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
				 // console.log(params.data)
				 try{
					if(params.data.dept_code == 'LOG-SC')
					{
						return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
					}
					else
					{
					    //return getHyphenDDMMMYYYYHHMM(params.data.fromtrucks[0].timestamp)
						return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
					}
				 }
				 catch(e){
					return '';
				 }
				
			  },
			  //filter: "agDateColumnFilter",
			  comparator: dateComparator,
			  //   filterParams: {
			// 	  browserDatePicker: true,
			// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
			// 	  // provide comparator function
			// 	  comparator: function(filteredDate,cellValue,secondDate) {
			// 		  if(cellValue != "" && cellValue != " "){
			// 			  cellValue = cellValue.split(" ")[0].split("-");
			// 			  cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
			// 			  var cellDate = new Date(cellValue);
			// 			  if(filteredDate.getMonth() < 10){
			// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  else{
			// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  if(filterValue.split("-")[2].length < 2)
			// 			  {
			// 				  var temp = filterValue;
			// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
			// 			  }
			// 			  var filteredDate = new Date(filterValue);
			// 			  cellDate = cellDate.getTime();
			// 			  filteredDate = filteredDate.getTime();
			// 			  // console.log(cellDate,filteredDate);
			// 			  if(cellDate === filteredDate)
			// 			  {
			// 				  return 0;
			// 			  }
			// 			  if(cellDate < filteredDate){
			// 				  return -1;
			// 			  }
			// 			  if(cellDate > filteredDate)
			// 			  {
			// 				  return 1;
			// 			  }
			// 		  }
			// 	  }
			//   },
				
		  },
		  {
			  headerName: "Data Received On",
			  field: "modified_date",
			  width: 200,
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
				  try{
					if(params.data.dept_code == 'LOG-SC')
					{
						return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
					}
					else
					{
					  return getHyphenDDMMMYYYYHHMM(params.data.truckloc[0].modified_date)
					
					  //return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
					}
				  }
				  catch(e){
					return "";
				  }
				
			  },
			  //filter: "agDateColumnFilter",
			  comparator: dateComparator,
			  //   filterParams: {
			// 	  browserDatePicker: true,
			// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
			// 	  // provide comparator function
			// 	  comparator: function(filteredDate,cellValue,secondDate) {
			// 		  if(cellValue != "" && cellValue != " "){
			// 			  cellValue = cellValue.split(" ")[0].split("-");
			// 			  cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
			// 			  var cellDate = new Date(cellValue);
			// 			  if(filteredDate.getMonth() < 10){
			// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  else{
			// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  if(filterValue.split("-")[2].length < 2)
			// 			  {
			// 				  var temp = filterValue;
			// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
			// 			  }
			// 			  var filteredDate = new Date(filterValue);
			// 			  cellDate = cellDate.getTime();
			// 			  filteredDate = filteredDate.getTime();
			// 			  // console.log(cellDate,filteredDate);
			// 			  if(cellDate === filteredDate)
			// 			  {
			// 				  return 0;
			// 			  }
			// 			  if(cellDate < filteredDate){
			// 				  return -1;
			// 			  }
			// 			  if(cellDate > filteredDate)
			// 			  {
			// 				  return 1;
			// 			  }
			// 		  }
			// 	  }
			//   },
		  },
		  // {
		  // 	headerName: "Data Received On",
		  // 	field: "modified_date",
		  // 	width: 200,
		  // 	//cellRenderer:'dateFormater'
		  // 	valueGetter:function(params){
		  // 		return getDDMMYYYYHHMMSS(params.data.modified_date)
		  // 	  }
		  //   }
		  
		  ];
		  var columngpspacketDefs =  [
			  {
				headerName: "GPS Provider",
				field: "_id",
				width: 200,
				
				
			  },
			  {
				headerName: "< 3 Minutes",
				field: "less_180",
				width: 200,
					
			},  	        
			{
			  headerName: "Between 3 to 5 Minutes",
			  field: "180_300",
			  width: 200,
				  
			},  	        
			{
			  headerName: "Between 5 to 10 Minutes",
			  field: "300_600",
			  width: 200,
				  
			},  	        
			{
			  headerName: "Between 10 to 15 Minutes",
			  field: "600_900",
			  width: 200,
				  
			},  	        
			{
			  headerName: "More than 15 Minutes",
			  field: "greater_900",
			  width: 120,
				  
			}
			   
		  ];

		var dealerGpsNotTodayDefs = [
			{
			  headerName: "Truck No",
			  field: "truck_no",
			  width: 200,
			  
		  },
			{
			  headerName: "LSP Code",
			  field: "transporter_code",
			  width: 200,
				  
		  },  				  
			{
			  headerName: "LSP Name",
			  field: "transporter_name",
			  width: 200,
			
		  },
		  				  
		  {
			headerName: "Plant Code",
			field: "consigner_code",
			width: 200				
		  },
		  	
		  {
			headerName: "Consignee City",
			field: "consignee_city",
			width: 140	
		  },
		  {
			headerName: "Consignee State",
			field: "consignee_state",
			width: 140
		  },
		  
		  {
			headerName: "Cluster",
			field: "cluster",
			width: 140
		  },
		{
			headerName: "Department Code",
			field: "dept_code",
			width: 200,
				
		  },
		  {
			  headerName: "Actual LSP Provider",
			  field: "actual_lspuser",
			  width: 200,
			  valueGetter: function(params){
				return "Enmovil";
			}	
		  },
		  {
			headerName: "Last Known City/Town",
			field: "area",
			width: 200,
			
		  },
		  {
			  headerName: "Last Known State",
			  field: "state",
			  width: 200,
			
		  },
		  {
			  headerName: "Last Packet Time",
			  field: "timestamp",
			  width: 200,
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
				 // console.log(params.data)
				 try{
					if(params.data.dept_code == 'LOG-SC')
					{
						return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
					}
					else
					{
					    //return getHyphenDDMMMYYYYHHMM(params.data.fromtrucks[0].timestamp)
						return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
					}
				 }
				 catch(e){
					return '';
				 }
				
			  },
			  //filter: "agDateColumnFilter",
			  comparator: dateComparator,
			
				
		  },
		  {
			  headerName: "Data Received On",
			  field: "modified_date",
			  width: 200,
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
				  try{
					if(params.data.dept_code == 'LOG-SC')
					{
						return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
					}
					else
					{
					  return getHyphenDDMMMYYYYHHMM(params.data.truckloc[0].modified_date)
					
					  //return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
					}
				  }
				  catch(e){
					return "";
				  }
				
			  },
			  //filter: "agDateColumnFilter",
			  comparator: dateComparator,
			
		  },
		  
		  ];

    	let order_cnt=[];

    	rendJsonData(this.state.loadsData);
		
        //console.log("Default",window.atob(localStorage.getItem('_t')));
        const dStyles={
				width:'100%',
				height:'100%'
		}
		const highchartoptions = {
	      		  chart: { 
	      		    type: 'column'
	      		  },
	      		  plotOptions: {
		      	      column: {
		      	          colorByPoint: true
		      	      },
		      	      series: {
		      	    	maxPointWidth: 20
		              }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
					  categories: this.state.transaxis
				   },
				   plotOptions: {
					column: {
						stacking: 'normal',
						dataLabels: {
							enabled: false
						},
						colorByPoint:true

					},
					},
	      		  series: [
	      		    {
					  name:"With GPS",
	      		      data: this.state.transyaxis
					  }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: false
	                        }
	                    }
	                }]
	      		  }
				  };
				  
		const transporterNoGPSChart = {
	      		  chart: { 
	      		    type: 'column'
	      		  },
	      		  plotOptions: {
		      	      column: {
		      	          colorByPoint: true
		      	      },
		      	      series: {
		      	    	maxPointWidth: 20
		              }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
					  categories: this.state.trans_no_gps_axis
				   },
				   plotOptions: {
					column: {
						stacking: 'normal',
						dataLabels: {
							enabled: false
						},
						colorByPoint:true

					},
					},
	      		  series: [
	      		    {
					  name:"Without GPS",
	      		      data: this.state.trans_no_gps_yaxis
					  }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: false
	                        }
	                    }
	                }]
	      		  }
				  };

        const lsphighchartoptions = {
	      		  chart: {
	      		    type: 'column'
	      		  },
	      		  plotOptions: {
		      	      column: {
		      	          colorByPoint: true
		      	      },
		      	      series: {
		      	    	maxPointWidth: 20
		              }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
	      	        categories: this.state.lspaxis
				   },
	      		  series: [
	      		    {
	      		      data: this.state.lspyaxis
	      		     //data: [64,21,33,42,52]
	      		    }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: false
	                        }
	                    }
	                }]
	      		  }
				  };
				  
		const lspNoGPShighchartoptions = {
	      		  chart: {
	      		    type: 'column'
	      		  },
	      		  plotOptions: {
		      	      column: {
		      	          colorByPoint: true
		      	      },
		      	      series: {
		      	    	maxPointWidth: 20
		              }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
	      	        categories: this.state.lsp_no_gps_axis
				   },
	      		  series: [
	      		    {
	      		      data: this.state.lsp_no_gps_yaxis
	      		     //data: [64,21,33,42,52]
	      		    }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: false
	                        }
	                    }
	                }]
	      		  }
				  };
				  				  
        const highchartoptions1 = {
	      		  chart: {
	      		    type: 'pie'
	      		  },
	      		  plotOptions: {
	      			pie: {
	    		        allowPointSelect: true,
	    		        cursor: 'pointer',
	    		        showInLegend: true,
	    		        dataLabels: {
	    		          enabled: true,
	    		          format: '{point.x:,.0f}'			    		          
	    		        }
	    		      }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
	      	        categories: this.state.deptaxis
	      	     },
	      	     legend: {
		    		  enabled: true,
		    		  labelFormatter: function() {

							//console.log("test",this.series)

			    		    var legendIndex = this.index;
			    		    var legendName = this.series.chart.axes[0].categories[legendIndex];

			    		    return legendName;
			    		  }
		    		},
	      		  series: [
	      		    {
	      		      data: this.state.deptyaxis
	      		     //data: [64,21,33,42,52]
	      		    }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: true
	                        }
	                    }
	                }]
	      		  }
	      		};
        			  
			const highchartgpsinterval = {
				chart: {
					type: 'column'
				},
				title: {
					text: ' '
				},
				xAxis: {
					categories: this.state.gpsintervalaxis
				},
				yAxis: {
					min: 0,
					
					stackLabels: {
						enabled: true,
						style: {
							fontWeight: 'bold',
							color: ( // theme
								Highcharts.defaultOptions.title.style &&
								Highcharts.defaultOptions.title.style.color
							) || 'gray'
						}
					}
				},
				legend: {
					align: 'right',
					x: -30,
					verticalAlign: 'top',
					y: 25,
					floating: true,
					backgroundColor:
						Highcharts.defaultOptions.legend.backgroundColor || 'white',
					borderColor: '#CCC',
					borderWidth: 1,
					shadow: false
				},
				tooltip: {
					headerFormat: '<b>{point.x}</b><br/>',
					pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
				},
				plotOptions: {
					column: {
						stacking: 'normal',
						dataLabels: {
							enabled: true
						}
					}
				},
				series: this.state.gpsintervalyaxis
			};
            var userroles = localStorage.getItem("roles");
			var israil = (userroles.indexOf("tpt_rail") > 0)?1:0

		const {usermanualmodal} = this.state;
		const modalStyles  = {
		width:'1300px !important',
		}

		const columnwithTPTDefs=[
			{
				headerName:"",
				field:"",
				width:50,
				cellRenderer:'trucklocaction'
			},
			{
				headerName: "Truck no",
				field: "truck_no",
				width: 100,
				filter: true,
				resizable: true
				
			},
			{
				headerName: "Latitude",
				field: "lat",
				width: 120,
				filter: true,
				resizable: true,
				valueGetter:function(params){
					return params.data.lat.toFixed(6);
				}
				
			},
			{
				headerName: "Longitude",
				field: "lng",
				width: 120,
				filter: true,
				resizable: true,
				valueGetter:function(params){
					return params.data.lng.toFixed(6);
				}
				
			},
			 {
			  headerName: "Address",
			  field: "address",
			  width: 200,
			  filter: true,
			  resizable: true,
		  },
		  {
			  headerName: "Area / City",
			  field: "area",
			  width: 130,
			  filter: true,
			  resizable: true,
		  },
		  {
			headerName: "State",
			field: "state",
			width: 130,
			filter: true,
			resizable: true,
		},
			{
				headerName: "Speed (Kms)",
				field: "speed",
				width: 80,
				filter: true,
				resizable: true
				
			},
			{
				headerName: "Packet Time",
				field: "stime",
				width: 140,
				filter: true,
				resizable: true,
				valueGetter:function(params){
					//console.log(params);
					if(params.data.stime != ""){
					  return getHyphenDDMMMYYYYHHMM(params.data.stime);
					}
					else{
						return "";
					}
					
			   },
				  comparator: dateComparator,
			},
			{
				headerName: "Received On",
				field: "received_on",
				width: 140,
				filter: true,
				resizable: true,
				valueGetter:function(params){
					console.log(params.data.received_on);
					if(params.data.received_on != "NaT")
					{
						return getHyphenDDMMMYYYYHHMM(params.data.received_on);
					}
					else{
						return "";
					}
					
				},
				comparator: dateComparator,
			},
			{
				headerName: "Time from previous point",
				field: "time_from_prev_point",
				width: 240,
				filter: true,
				resizable: true,
				valueGetter : function(params)
				{
					try{
						return ConvertSeconds(params.data.time_from_prev_point);
					}
					catch(e){
						return 0
					}
				  
				}
				
			},
			{
				headerName: "Distance from previous point (KM)",
				field: "dist_from_prev_point",
				width: 240,
				filter: true,
				resizable: true,
				valueGetter : function(params)
				{
				  return  convertMeters(params.data.dist_from_prev_point);
				}
				
			},
			
	  ]

        return (
            <div >
                {/*Container-fluid starts*/} 
                <div className="">
					
                	{/* <div style={{marginBottom:'10px'}} className="row">
						<div className="col-xl-6 col-lg-12">
                            <ul className="d-tabs">
                                <li>
                                    <a href="/tptlogin/carrierlocationonmap" className={"btn btn-default "+(this.state.defaulttab == "all"?"activetpt":"")}>Map View</a>
                                </li>
                            </ul>
                	    </div>
                	</div> */}

					 
					<DashboardCountersComponent 
					  tottrucks={this.state.truckscnt} 
					  truckgps={this.state.trucklocations} 
					  notruckgps={this.state.notrans} 
					  totalActiveConsignmentsCount={this.state.totalActiveConsignmentsCount} 
					  gpsNotTodayActiveConsignmentsCount={this.state.gpsNotTodayActiveConsignmentsCount} 
					  truckno={this.state.trucknogps}
					  gpsnotreport = {this.state.rowwithoutData.length}
					  ontripwithgps={this.state.ontripwithgps}
					  israil={israil}
					  ontripgpsna={this.state.ontripgpsna}
					  atdealertruckcnt={this.state.atdealertruckcnt}
					  atdealernogps={this.state.atdealernogps.length}
					  primaryontrips={this.state.primaryontrips}
					  secondaryontrips={this.state.secondaryontrips}
					  deptcode={this.state.deptvalue}
					   />
                    <div className="row">
						<div className="col-xl-6 col-lg-12">
	                        <div className="card height-equal equal-height-lg">
	                            <div className="card-header" style={{padding:'10px'}}>
	                                <h5>Trucks with GPS Data</h5>
	                            </div>
	                            <div className="card-body" style={{padding:'10px'}}>
	                                <div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
	    		          		          <AgGridReact
											context={this}
											onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
	    		          		            modules={this.state.modules}
	    		          		            columnDefs={columnwithDefs}
	    		          		            defaultColDef={this.state.defaultColDef}
	    		          		            rowData={this.state.rowData}
	    		          		            enableCharts={false}
	    		          		            enableRangeSelection={true}
	    		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
	    		          		            frameworkComponents={this.state.frameworkComponents}
											onGridReady={this.onGridReady}
	    		          		            onGridState={this.onGridState}
											  statusBar={this.state.statusBar}
											  //sideBar={this.state.sideBar}
											  pagination={true}
											  gridOptions={{
												context: { componentParent: this }
											}}
	    		          		          />
	    		          		        </div>
	                            </div>
	                            
	                        </div>
	                        
	                    </div>

	                    
	                    

                        <div className="col-xl-6 col-lg-12">
	                        <div className="card height-equal equal-height-lg">
	                            <div className="card-header" style={{padding:'10px'}}>
	                                <h5>GPS Data Not Reported Last 24 Hours</h5>
	                            </div>
	                            <div className="card-body" style={{padding:'10px'}}>
	                                <div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
	    		          		          <AgGridReact
	    		          		            modules={this.state.modules}
											onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
	    		          		            columnDefs={columnwithoutDefs}
	    		          		            defaultColDef={this.state.defaultColDef}
	    		          		            rowData={this.state.rowwithoutData}
	    		          		            enableCharts={false}
	    		          		            enableRangeSelection={true}
	    		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
	    		          		            frameworkComponents={this.state.frameworkComponents}
											onGridReady={this.onGridReady}
	    		          		            onGridState={this.onGridState}
											  statusBar={this.state.statusBar}
											  //sideBar={this.state.sideBar}
											  pagination={true}
											  gridOptions={{
												context: { componentParent: this }
											}}
	    		          		          />
	    		          		        </div>
	                            </div>
	                            
	                        </div>
	                        
	                    </div>



                        {/* <div className="col-xl-6 col-lg-12">
	                        <div className="card height-equal equal-height-lg">
	                            <div className="card-header" style={{padding:'10px'}}>
	                                <h5>GPS Data Not Reported Last 24 Hours (On Trip Trucks)</h5>
	                            </div>
	                            <div className="card-body" style={{padding:'10px'}}>
	                                <div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
	    		          		          <AgGridReact
	    		          		            modules={this.state.modules}
											onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
	    		          		            columnDefs={columnGpsNotTodayDefs}
	    		          		            defaultColDef={this.state.defaultColDef}
	    		          		            rowData={this.state.gpsNotTodayActiveConsignments}
	    		          		            enableCharts={false}
	    		          		            enableRangeSelection={true}
	    		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
	    		          		            frameworkComponents={this.state.frameworkComponents}
											onGridReady={this.onGridReady}
	    		          		            onGridState={this.onGridState}
											  statusBar={this.state.statusBar}
											  //sideBar={this.state.sideBar}
											  pagination={true}
	    		          		          />
	    		          		        </div>
	                            </div>
	                            
	                        </div>
	                        
	                    </div>

                        <div className="col-xl-6 col-lg-12">
	                        <div className="card height-equal equal-height-lg">
	                            <div className="card-header" style={{padding:'10px'}}>
	                                <h5>GPS Data Not Reported Last 24 Hours (At Dealer)</h5>
	                            </div>
	                            <div className="card-body" style={{padding:'10px'}}>
	                                <div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
	    		          		          <AgGridReact
	    		          		            modules={this.state.modules}
											onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
	    		          		            columnDefs={dealerGpsNotTodayDefs}
	    		          		            defaultColDef={this.state.defaultColDef}
	    		          		            rowData={this.state.rowdataatdealer}
	    		          		            enableCharts={false}
	    		          		            enableRangeSelection={true}
	    		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
	    		          		            frameworkComponents={this.state.frameworkComponents}
											onGridReady={this.onGridReady}
	    		          		            onGridState={this.onGridState}
											  statusBar={this.state.statusBar}
											  //sideBar={this.state.sideBar}
											  pagination={true}
	    		          		          />
	    		          		        </div>
	                            </div>
	                            
	                        </div>
	                        
	                    </div> */}


						{/* <div className="col-xl-12 col-lg-12 hidden">
                            <div className="card">
                                <div className="card-header">
                                    <h5>GPS Data Time Interval (Between Packets)</h5>
                                </div>
                                <div className="card-body">
                                    <div className="dashboard-chart-container sales-overview-chart">
										<div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
											<AgGridReact
												context={this}
												onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
												modules={this.state.modules}
												columnDefs={this.state.columngpspacketDefs}
												defaultColDef={this.state.defaultColDef}
												rowData={this.state.rowgpspacketData}
												enableCharts={false}
												enableRangeSelection={false}
												autoGroupColumnDef={this.state.autoGroupColumnDef}
												onGridReady={this.onGridReady}
												onGridState={this.onGridState}
												statusBar={this.state.statusBar}
												//sideBar={this.state.sideBar}
												pagination={true}
											/>
										</div>
                                    </div>
                                </div>
                            </div>
                        </div>				 */}
                    {/* <div className="col-xl-7 col-lg-7 ">
                        <div className="card">
                            <div className="card-header">
                                <h5>LSP wise trucks with GPS Data</h5>
                            </div>
                            <div className="card-body">
                                <div className="dashboard-chart-container sales-overview-chart">
                                <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={lsphighchartoptions} />

                                </div>
                            </div>
                        </div>
                    </div> */}
				
					{/* <div className={"col-xl-5 col-lg-5 "+(this.state.sndshow)} >
                        <div className="card">
                            <div className="card-header">
                                <h5>LSP wise trucks with NO GPS Data</h5>
                            </div>
                            <div className="card-body">
                                <div className="dashboard-chart-container sales-overview-chart">
                                <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={lspNoGPShighchartoptions} />

                                </div>
                            </div>
                        </div>
                    </div> */}
						
                        {/* <div className={"col-xl-5 col-lg-5 "+(this.state.departmentchrtshow)}>
                            <div className="card default-widget-count">
	                            <div className="card-header">
		                            <h5>Department wise trucks with GPS Data</h5>
		                        </div>
                                <div className="card-body" style={{paddingBottom:'15px'}}>
                                    <div className="media">
                                    <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions1} />

                                    </div>
                                </div>
                            </div>
                           
						</div> */}
						 
						{/*
                        <div className={"col-xl-12 col-lg-12 "+(this.state.departmentchrtshow)}>
                            <div className="card default-widget-count">
	                            <div className="card-header">
		                            <h5>GPS Packet Interval</h5>
		                        </div>
                                <div className="card-body" style={{paddingBottom:'15px'}}>
									<div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
	    		          		          <AgGridReact
											context={this}
											onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
	    		          		            modules={this.state.modules}
	    		          		            columnDefs={this.state.columngpspacketDefs}
	    		          		            defaultColDef={this.state.defaultColDef}
	    		          		            rowData={this.state.rowgpspacketData}
	    		          		            enableCharts={false}
	    		          		            enableRangeSelection={false}
	    		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
	    		          		            onGridReady={this.onGridReady}
	    		          		            onGridState={this.onGridState}
											  statusBar={this.state.statusBar}
											  //sideBar={this.state.sideBar}
											  pagination={true}
	    		          		          />
									</div>
									
									<div className="media">
                                    	<HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartgpsinterval} />

                                    </div>
                                </div>
                            </div>
                           
                        </div> 

						*/}
                        
{/*                     
                        <div className="col-xl-12 col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Transporter wise trucks with GPS Data</h5>
                                </div>
                                <div className="card-body">
                                    <div className="dashboard-chart-container sales-overview-chart">
                                    <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Transporter wise trucks Without GPS Data</h5>
                                </div>
                                <div className="card-body">
                                    <div className="dashboard-chart-container sales-overview-chart">
                                    <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={transporterNoGPSChart} />

                                    </div>
                                </div>
                            </div>
                        </div>	
						                     */}
	                    
	                    
						<div className={"dataLoadpage " +(this.state.loadshow)}>
						</div>
						<div className={"dataLoadpageimg " +(this.state.loadshow)}>
							<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
						</div>
						<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
						{this.state.sliderRouteTranslate != ''?
						<div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
							<div className="slide-r-title">
								<h4>
									View Route
									<span className="float-right closebtn" style={{marginRight:"25px"}} 
									onClick={this.onClickHideAll.bind(this)} >X</span>
											
								</h4>
							</div>
							<div className="container-fluid">
								<div className="row col-xl-12 col-lg-12">						
									<form style={{marginTop:"2%"}} className="row theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler}>
										<div className="col-xl-3 col-lg-3 form-group">
											<Datetime 
											inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete:'off' }} 
											dateFormat="YYYY-MM-DD" 
											timeFormat="HH:mm:ss"  
											name="startDate"
											value={this.state.startDate} 
											onChange={this.handlerStartDateTime.bind(this)} />
										</div>

										<div className="col-xl-3 col-lg-3 form-group">
											<Datetime  inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete:'off' }} 
											dateFormat="YYYY-MM-DD" 
											timeFormat="HH:mm:ss"
											name="endDate"
											value={this.state.endDate} 
											onChange={this.handlerEndDateTime.bind(this)} />
										</div>
										<div className="col-xl-3 col-lg-3 form-group">
											<input type="number" name="frequency" id="frequency" value={this.state.frequency} placeholder="Data Interval (Mins)" className="validateNumber form-control" onChange={this.checkHandler.bind(this)} />
										</div>
										<div className="col-xl-2 col-lg-2 form-group">
											<button type="submit" className="btn btn-success">Generate</button>
										</div>
									</form>
								</div>
								{(this.state.mapData != null)?
									<div className="row col-xl-12 col-lg-12">
										{/* <button type="button" className={"btn "+(this.state.actgridbtn)} onClick={this.onClickShowGrid.bind(this)}>Grid</button>
										<button type="button" className={"btn "+(this.state.actmapbtn)} onClick={this.onClickShowMap.bind(this)}>Map</button> */}
										<div className="col-xl-6 col-lg-6">
												<div id="myGrid" style={{ height: "580px",width: "100%",marginTop:'10px'}}  className={"ag-theme-balham "+(this.state.gridshow)}>
												<AgGridReact
													modules={this.state.modules}
													columnDefs={columnwithTPTDefs}
													defaultColDef={this.state.defaultColDef}
													rowData={this.state.mapData}
													enableCharts={true}
													enableRangeSelection={true}
													onGridReady={this.onGridReady}
													onGridState={this.onGridState}
													statusBar={this.state.statusBar}
													sideBar={this.state.sideBar}
													paginationPageSize={this.state.paginationPageSize}
													pagination={true}
													frameworkComponents={this.state.frameworkComponents}
													enableCellChangeFlash={true}
													suppressCellFlash={true}
													gridOptions={{
														context: { componentParent: this }
													}}
													/>


												</div>
											</div>
											{/* {(this.state.mapinfoclick != '')?this.state.mapshow */}
											<div className={"col-xl-6 col-lg-6 "} style={{padding:"10px"}}>
												{/* <input type="checkbox" name="viewtimelinemarkers" style={{marginLeft: "2em",marginBottom: "1em",}} value={this.state.showmarkers} onClick={this.showmarkers.bind(this)} /> view markers */}
												<DrawMap 
												context={this} 
												mapFor={"tpttruckgps"} 
												tolls = {this.state.tolls}
												mapinfo={this.state.mapinfo} 
												defTransitCoords={""} />
											
											</div> 
											{/* :""} */}
									</div>
											
							:""}

								
							</div>
						</div>
					:""}
                    </div>

					<div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
					 	{this.displayData}
				 	</div>


                </div>
                {/*Container-fluid Ends*/}

				<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
				<Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
					<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<h5 className="model-title">GPS Integratin</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							<div className="col-xl-12 col-lg-12">
								GPS integration report comprises of below mentioned reports
							</div>
							<div className="col-xl-12 col-lg-12">
								<ul>
									<li>
										a. GPS integration status:
										<ul>
											<li>
												<div className="col-xl-12 col-lg-12">
													This screen provides data of overall trucks that are present in system for that particular department and its current integration status regarding GPS data
												</div>
												<div className="col-xl-12 col-lg-12">
											 		Image here
												</div>
												<div className="col-xl-12 col-lg-12">
													This screen provides analytics related to GPS data vehicle wise. This screen shows data in four grids.  The details are present in all four analytics in below mentioned columns
											 		<table className="table table-border">
														<tr>
															<th>
																Column
															</th>
															<th>
																Remarks
															</th>
														</tr>
														<tr>
															<td>
																Truck No
															</td>
															<td>
																Truck number for which details are provided
															</td>
														</tr>
														<tr>
															<td>
																LSP Code
															</td>
															<td>
															Transporter code provided by MSIL
															</td>
														</tr>
														<tr>
															<td>
															LSP Name
															</td>
															<td>
															Transporter Name
															</td>
														</tr>
														<tr>
															<td>
															Department Code
															</td>
															<td>
															Department details
															</td>
														</tr>
														<tr>
															<td>
															Actual GPS Provider
															</td>
															<td>
															GPS service provider
															</td>
														</tr>
														<tr>
															<td>
															Last Known City/Town	
															</td>
															<td>
															Last known Location as per GPS data
															</td>
														</tr>
														<tr>
															<td>
															Last Known State
															</td>
															<td>
															Last known State as per GPS data
															</td>
														</tr>
														<tr>
															<td>
															Last Packet Time
															</td>
															<td>
															packet date and time sent by GPS provider
															</td>
														</tr>
														<tr>
															<td>
															Data Received On
															</td>
															<td>
															Data packet received from service provider
															</td>
														</tr>
														
													 </table>
												</div>
												<div className="col-xl-12 col-lg-12">
											 		<ul>
														 <li>
															Trucks with GPS Data
														</li>
														<li>
															GPS data not reported last 24 hours
														</li>
														<li>
															GPS data not reported in last 24 hours (On trip trucks)
														</li>
														<li>
															GPS data not reported in last 24 hours (At dealer)
														</li>
													 </ul>
												</div>
												<div className="col-xl-12 col-lg-12">
													For SND department data is available in two sections primary trips and Secondary trips. The section is divided based on movements which are done from plants for primary and from TVP for Secondary
												</div>
											</li>
										</ul>
									</li>
									<li>
										b. Truck GPS data:
										<ul>
											<li>
												<div className="col-xl-12 col-lg-12">
													This screen helps in tracking individual truck (departmental wise) in a particular time frame. The user just needs to enter the truck number and the time frame for which user wants to know the trail. 
												</div>
												<div className="col-xl-12 col-lg-12">
													Image Here
												</div>
												<div className="col-xl-12 col-lg-12">
												Once the data is filled as per required fields, Click on generate button, required details will be displayed
												</div>
											</li>
										</ul>

									</li>
									<li>
										c. Manual GPS integration:
										<ul>
											<li>
												<div className="col-xl-12 col-lg-12">
												This screen helps in adding particular location details for the vehicle which GPS data has not been provided by its respective service provider. If a user is well aware of the location it can be added in system manually by user by using this option. For adding the GPS location the user needs to add details like the vehicle number the location co-ordinates which needs to be added (can be searched from map provided) and the time at which the vehicle was present at that location. Click on save button then the details will be added for the vehicle at that particular time	
												</div>
												<div className="col-xl-12 col-lg-12">
													Image Here
												</div>
												
											</li>
										</ul>
									</li>
								</ul>
								
							</div>
						</div>
					</div>
				</Modal>  
            </div>
        );
    }
}
	function rendJsonData(data)
	{
		//console.log(data);
		var order_cnt=[];
		order_cnt=data.map( (seris) => 
			seris.key
		)
		//console.log(order_cnt)
	}

	function dateComparator(date1, date2) {
		// console.log("dateComparator1");
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		if (date1Number === null && date2Number === null) {
		  return 0;
		}
		if (date1Number === null) {
		  return -1;
		}
		if (date2Number === null) {
		  return 1;
		}
		return date1Number - date2Number;
	  }
	function monthToComparableNumber(date) {
		if (date === undefined || date === null || date.length !== 19) {
			return null;
		}
		var yearNumber = date.substring(6, 10);
		var monthNumber = date.substring(3, 5);
		var dayNumber = date.substring(0, 2);
		var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
		return result;
	}
	function loadScript(url){
		var index  =window.document.getElementsByTagName("script")[0]
		var script = window.document.createElement("script")
		script.src=url
		script.async=true
		script.defer = true
		index.parentNode.insertBefore(script,index)
	}
	function initOMS(){
		// Initializing OMS
		oms = new oms.OverlappingMarkerSpiderfier(map, {
		  //markersWontMove: true,
		  //keepSpiderfied: true,
		  //nearbyDistance: 10,
		  //circleFootSeparation: 60,
		  //legWeight: 1.5
		  markersWontMove: true,
		markersWontHide: true,
		keepSpiderfied: true,
		basicFormatEvents: true
		});
	  }
	  function timeConvert(n) {
		  var num = n;
		  var hours = (num / (3600));
		  var rhours = parseInt(hours);
		  var minutes = (num-(rhours*3600)) / (60);
		  var rminutes = Math.round(minutes);
		  return rhours + " hour(s) and " + rminutes + " minute(s).";
	  }   
	
	function ConvertMinutes(num){
		var d = Math.floor(num/1440); // 60*24
		var h = Math.floor((num-(d*1440))/60);
		var m = Math.round(num%60);
		return d+" Day(s) "+h+" Hrs "+m+" mins"
	}
	
	function ConvertSeconds(totalSeconds)
	{
		// var days = Math.floor(num/86400);
		// var hours = Math.floor(num/3600)
		// var minutes = Math.floor(num/60);
		var minutes = Math.round((totalSeconds % 3600) / 60);
		var hours = Math.round((totalSeconds % 86400) / 3600);
		var days = Math.round((totalSeconds % (86400 * 30)) / 86400);
		// let d = (new Date(t0)) - (new Date(t1));
		// let weekdays     = Math.floor(d/1000/60/60/24/7);
		// let days         = Math.floor(d/1000/60/60/24 - weekdays*7);
		// let hours        = Math.floor(d/1000/60/60    - weekdays*7*24            - days*24);
		// let minutes      = Math.ceil(d/1000/60       - weekdays*7*24*60         - days*24*60         - hours*60);
		return days+" Day(s) "+hours+" Hrs "+minutes+" mins"
	}
	function ConvertHHMMSeconds(n)
	{
		var days =Math.round(parseInt( n / (24 * 3600)));
	
		n = n % (24 * 3600);
		var hours = Math.round(parseInt(n / 3600));
	
		n %= 3600;
		var minutes = Math.round(n / 60);
	
		n %= 60;
		var seconds = Math.round(n);
	
		return days+" Day(s) "+hours+" Hrs "+minutes+" mins "+ seconds+" secs"
	}
	
	function convertMeters(metersValue)
	{
		return (metersValue/1000).toFixed(2);
	}


export default tptGpsIntegration;
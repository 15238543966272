// 'use strict';

import React, { Component, useDebugValue } from 'react';
// import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
// import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
// import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
// import { MenuModule } from '@ag-grid-enterprise/menu';
import Select from 'react-select';
// import CommentActions from "./commentsComponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
// import MarkDeliver from "./markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
// import ConsignmentActions from '../manage/ConsignmentActionsComponent';
// import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import download from 'js-file-download';
// import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import { getHyphenDDMMMYYYYHHMM} from '../common/utils';
import lspcarrierdocdetails from './lspcarrierdocdetails'

// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
// var tptCode = localStorage.getItem('transportercode');
// var userType = localStorage.getItem('user_type');

export default class LspDocDashboardData extends Component{
    constructor(props) {
        super(props);
          this.state ={
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            frameworkComponents : {
                lspcarrierdocdetails : lspcarrierdocdetails
            },
            modified_by:"",
            basicTitle:'',
            reasonforceclose:"",
            bulkslide:"",
            file:"",
            uploadFile:"",
            basicType:"default",
            overly:'show-n',
            loadshow:'show-n',
            forceCloseRowNode : "",
            forceclosedata:"",
            csvcontent:"",
            sliderForceCloseTranslate:"",
            rowData:[],
            staterowData:[],
            showDiv:"",
            uploadDivWidth:"",
            mapinfo:"",
            dealer:"",
            sliderCommentTranslate:"",
            commentsRowData:"",
            consignment_code:"",
            sliderRouteTranslate:"",
            maptruckno:"",
            routeTruck:	"",
            rownode:"",
            googelRoutes:"",
            rowSelection:'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
                statusBar:"",
            paginationPageSize:200,
            lspDocDetails:[],
            geoFenceData:"",
            sideBar: {
              toolPanels: [
                {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel"
                },
                {
                id: "filters",
                labelDefault: "Filters",
                labelKey: "filters",
                iconKey: "filter",
                toolPanel: "agFiltersToolPanel"
                }
              ]
            },
            animateRows: true,
            brequest:"activet",
            columns : [],
            lspCarrierDocs: [],
            trucksData: [],
            docTypedata: [],           
            userLoggedtype : false,
            transList:[],
            transListNo:"",
            translabel : "",
            transListVal: "",
            rowId : '',
            docNumber : '',
            docExpDate : '',
            loadFile : "",
            hidden_uploadfilenow : '',
            carrier_no : ''
          }
          
    }

    componentDidMount(){
        loadDateTimeScript();  
       this.initialloadfunction()
    }
    initialloadfunction =() =>{
        let transList = []
        let initialTransCode =""
        var userLoggedtype  = false;
        var userType = localStorage.getItem('user_type');
        
        if(userType === "TRANSPORTER")
        {
            var tptCode = localStorage.getItem('transportercode');
            userLoggedtype = true;
            let tcode = JSON.parse(tptCode)
            this.onLoadPageData(tcode[0])
            this.setState({
                userLoggedtype : userLoggedtype
            })
        }
        else
        {
            redirectURL.post("/consignments/getSNDGTransporters").then(async (response)=>{
                let sndgTrans = response.data
                // console.log("sndgTrans",sndgTrans)
                // eslint-disable-next-line array-callback-return
                sndgTrans.map(e =>{
                    transList.push({
                        label: e.transporter_name,
                        value: e.transporter_code
                    })
                })
                // transList.push({
                //     label: 'ALL',
                //     value: 'ALL'
                // })
                initialTransCode = sndgTrans[0].transporter_code;
                this.setState({
                    transListVal :{label:sndgTrans[0].transporter_name, value:sndgTrans[0].transporter_code},
                    transList,
                    userLoggedtype
                })
                this.onLoadPageData(initialTransCode) 
            })
        }        
    }

    onLoadPageData =(argument1) =>{
        // console.log(argument1,'argument1')
        let params ={}
        params["transporter_code"] = argument1
        // console.log("params",params)
        redirectURL.post("/consignments/getCarrierDocumentDetails",params).then(async (response)=>{
            let rawD = response.data.details
            let  trucksData=  response.data.trucksData;
          
            this.setState({
                lspCarrierDocs: rawD,
                trucksData: trucksData,
                docTypedata: response.data.docTypedata,
                rowData: [],
                loadshow:'show-n',
                overly:'show-n',
            })
            await this.createColumns();
            await this.createRows();
        })
    }

    createColumns = async () =>{
        var columns = [];
        columns.push({
            headerName:"Carrier No",
            field:"carrier_no",
            width:120,
            pinned: 'left'
        })
        var documnentTypes = this.state.docTypedata;
        // documnentTypes.map(e =>{
        //     e["validity_expiry"] = "";
        //     e["doc_number"] = "";
        // })
        // var trucksData = this.state.trucksData;
        // console.log("documnentTypes",documnentTypes)

        documnentTypes.map(async function(dct){
            var defs = {};
            //trucksData.map(async function(tda){ 
                    var gridField = dct.document_name;           
                    defs.headerName = dct.document_name;
                    // console.log("dct",dct)
                    defs.field =dct.document_name;
                    defs.width = 180;
                    defs.editable = false
                    defs.cellRendererSelector =  function(params){
						var rendComponent = {
							component: 'lspcarrierdocdetails'
						};
						return rendComponent;
					}
                    defs.cellStyle = function(params){
                        let currentDate = moment.parseZone(new Date(moment.parseZone(new Date()).format("YYYY-MM-DD 00:00:00")))._d;
                        if(params.data[gridField] !=='' && params.data[gridField] !==undefined)
                        {
                            let splitData = params.data[gridField].split("||")[1]
                            if(splitData !==undefined && splitData !=='')
                            {
                                splitData = splitData.split("-");
                                splitData = splitData[2]+"-"+splitData[1]+"-"+splitData[0]
                            }
                            if(params.data['validity_expiry'] !== ""){
                                
                                let ainpv = moment.parseZone(new Date(moment.parseZone(new Date(splitData)).format("YYYY-MM-DD 00:00:00")))._d;
                                let ainpvDays = moment(new Date(ainpv)).diff(moment(new Date(currentDate)), 'days');
                                if(ainpvDays <0)
                                {
                                    return  {color:'red'};
                                }
                                else if(ainpvDays >=0 && ainpvDays <= 90)
                                {
                                    return  {color:'orange'};
                                }                                                  
                            }
                        }
                    }
                    defs.resizable = true;         
                    columns.push(defs);
                    columns.push({
                        headerName:"Load File",
                        field:dct.document_name+"_loadFile",
                        width:120,
                        hide: true
                    })
                    columns.push({
                        headerName:"update",
                        field:dct.document_name+"_id",
                        width:120,
                        hide: true
                    })
               // })
        });

        this.setState({
           columns:  columns
        })
        // console.log("columns", columns)

    }

    createRows = async () =>{        
        var rowdefs = [];
        var trucksData = this.state.trucksData;
        // var docTypedata = this.state.docTypedata;
        var lspCarrierDocs = this.state.lspCarrierDocs;
        // console.log(lspCarrierDocs,"lspCarrierDocs")
        trucksData.map(async (tda) =>{
            var rows = {};
            if(lspCarrierDocs.length > 0)
            {
                var carriers = groupBy(lspCarrierDocs, rdata=>rdata.doc_name);
                // console.log(carriers,"carriers")
                carriers.forEach((cn,cnKey)=>{                    
                    // eslint-disable-next-line array-callback-return
                    cn.map((icn) => {
                        // console.log("icn",icn)
                        rows["carrier_no"] = tda;
                        if(icn.truck_no === tda)
                        {
                            //rows[icn.doc_name] = icn.is_uploaded;
                            //rows[icn.doc_number] = icn.doc_number;
                            if(icn.doc_number && icn.validity_expiry){
                               let date = icn.validity_expiry.split("-")
                                let newDate = date[2]+"-"+date[1]+"-"+date[0]
                                rows[icn.doc_name] =icn.doc_number + "||" + newDate ;
                                rows[icn.doc_number + "||" + newDate] = icn._id;
                            }
                            else {
                                rows[icn.doc_name] = ""
                            }
                            rows[icn.doc_name+"_loadFile"] = icn.loadFile;
                            
                        }
                    });
                });
            }
            await rowdefs.push(rows);
        })

        await this.setState({
            rowData : rowdefs
        });
        // console.log(rowdefs,"rowdefs")
    }
    // onCellRowClicked =e =>{
    //     console.log("edata", e.colDef.field, e.data[e.colDef.field])
    //     var clickedField = e.colDef.field;
    //     var loadFile = e.data[clickedField+"_loadFile"];
    //     // console.log(loadFile,"loadFile")
    //     let params ={
    //         loadFile : loadFile
    //     }
    //     if(params.loadFile !== "") {
    //         redirectURL.post("/consignments/downloadLspDocFile",params,{
    //             responseType: 'blob'
    //         }).then((response)=>{
    //             // console.log("dowlad response ",response)
    //             download(response.data,loadFile)
    //         }).catch(function(error){
    //             console.log(error);
    //         })
    //     }
    // }

    changeTransHandler = event =>{
        // console.log("event",event.value)
        this.setState({
            transListVal : event,
            transListNo: event.value
        })
        this.onLoadPageData(event.value)
    }

    onClickHideAll =() =>{
        this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:"",                     
        });  
    }

    updatelspvaliditydetails= params =>{
        let cellValue = params.value;
        let rowId = params.data[cellValue]
        let cellvalList = cellValue.split('||')
        let docNumber = cellvalList[0]
        let docExpDate = cellvalList[1]
        $("#documentno").val(docNumber);
        $("#validity_date").val(docExpDate);
        let key = Object.keys(params.data).find(key => params.data[key] === params.value);
        let keyname = `${key}_loadFile`
        let hidden_uploadfilenow = params.data[keyname]
        console.log("params",rowId)
        let carrier_no = params.data.carrier_no;
        console.log("docNumber",docNumber,"docNumber")
        this.setState({
            rowId,
            //docNumber,
            //docExpDate,
            hidden_uploadfilenow,
            carrier_no,
            bulkslide : "slider-translate-30p",
            overly:'show-m',
        })
    }

    changeHandler = e =>{
        console.log(e.target.value,"docNUm")
        this.setState({docNumber: e.target.value});
    }

    // changeDateHandler =() =>{
    //     let expDate = $("#validity_date").val();
    //     this.setState({
    //         docExpDate : expDate
    //     })
    // }

    changeFileHandler = async (e) => {
        // console.log("uploadfile",e);        
        this.setState({ loadFile: e.target.files[0] });      
    }

    formHandler = event =>{
        event.preventDefault()
        const {loadFile,rowId,hidden_uploadfilenow,carrier_no} = this.state    
        var docNumber = $("#documentno").val();    
        var docExpDate = $("#validity_date").val();    
        let params = {
            "rowId" : rowId,
            "docNumber" : docNumber,
            "expDate" : docExpDate,
            'loadFile' : loadFile,
            'carrier_no' : carrier_no,
            'hidden_uploadfilenow' : hidden_uploadfilenow
        }
        console.log("params",params)
        var userName = localStorage.getItem("username")
        let saveData = new FormData();
        saveData.append("loadFile", loadFile);
        saveData.append("rowId", rowId);
        saveData.append("expDate", docExpDate);
        saveData.append("docNumber", docNumber);
        saveData.append("userName", userName);
        saveData.append("hidden_uploadfilenow",hidden_uploadfilenow);
        saveData.append('carrier_no',carrier_no);
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        });
        if(docNumber !== "" && docNumber !== undefined ){
            redirectURL.post("/consignments/updatelspcarrierdocdetails",saveData).then((response)=>{                
                if(response.status === 'success'){
                    $("#documentno").val('');    
                    $("#validity_date").val('');
                    this.initialloadfunction()
                    this.setState({
                        loadFile : '',
                        loadshow:'show-n',
                        overly:'show-n',
                        basicTitle:"Success",
                        basicType : "success",
                        show : true    
                    })
                }
            })
        }
    }

    getContextMenuItems = (params) =>{
        let result =[
            {
                name: 'Download',
                action : () =>{
                    // console.log("edata", params)
                    var clickedField = params.column.colDef.field;
                    var loadFile = params.node.data[clickedField+"_loadFile"];
                    // console.log(loadFile,"loadFile")
                    let reqparams ={
                        loadFile : loadFile
                    }
                    if(reqparams.loadFile !== "") {
                        redirectURL.post("/consignments/downloadLspDocFile",reqparams,{
                            responseType: 'blob'
                        }).then((response)=>{
                            // console.log("dowlad response ",response)
                            download(response.data,loadFile)
                        }).catch(function(error){
                            console.log(error);
                        })
                    }    
                    
                },
                cssClasses: ['bold'],
            },
            'copy',
            
        ];
        return result
    }

    previousscreen =() =>{
        this.setState({
            overly:"show-n",
            loadshow:"show-n",
            bulkslide : "",             
        })
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    
    onGridReady = params => {
		this.gridApi = params.api;		
	    // console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;		   
	};
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);
    }

    render(){

        // const columnwithDefs = [
        //     {
        //         headerName: "Trucks",
        //         field: "carrier_no",          
        //         filter: true,
        //         editable: true,
        //         headerHeight: 140,
        //         width:100,
        //         resizable: true,                
        //     },
        //     {
        //         headerName: "All India National Permit",
        //         field: "doc_name",          
        //         filter: true,
        //         editable: true,
        //         width:90,
        //         headerHeight: 140,
        //         resizable: true,
        //         valueGetter : (param) =>{
        //             let paramData = param.data.doc_name
        //             if(paramData.includes("All India National Permit")) return "yes"
        //             return "No"
        //         }
        //     },
        //     {
        //         headerName: "Fitness",
        //         field: "doc_name",          
        //         filter: true,
        //         editable: true,
        //         width:90,
        //         headerHeight: 140,
        //         resizable: true,
        //         valueGetter : (param) =>{
        //             let paramData = param.data.doc_name
        //             if(paramData.includes("Fitness")) return "yes"
        //             return "No"
        //         }
        //     },
        //     {
        //         headerName: "Insurance",
        //         field: "doc_name",          
        //         filter: true,
        //         editable: true,
        //         width:90,
        //         headerHeight: 140,
        //         resizable: true,
        //         valueGetter : (param) =>{
        //             let paramData = param.data.doc_name
        //             if(paramData.includes("Insurance")) return "yes"
        //             return "No"
        //         }
        //     },
        //     {
        //         headerName: "Permit Authorization",
        //         field: "doc_name",          
        //         filter: true,
        //         editable: true,
        //         width:90,
        //         headerHeight: 140,
        //         resizable: true,
        //         valueGetter : (param) =>{
        //             let paramData = param.data.doc_name
        //             if(paramData.includes("Permit Authorization")) return "yes"
        //             return "No"
        //         }
        //     },
        //     {
        //         headerName: "PUC",
        //         field: "doc_name",          
        //         filter: true,
        //         editable: true,
        //         width:90,
        //         headerHeight: 140,
        //         resizable: true,
        //         valueGetter : (param) =>{
        //             let paramData = param.data.doc_name
        //             if(paramData.includes("PUC")) return "yes"
        //             return "No"
        //         }
        //     },
        //     {
        //         headerName: "Rajasthan Permit",
        //         field: "doc_name",          
        //         filter: true,
        //         editable: true,
        //         width:90,
        //         headerHeight: 140,
        //         resizable: true,
        //         valueGetter : (param) =>{
        //             let paramData = param.data.doc_name
        //             if(paramData.includes("Rajasthan Permit")) return "yes"
        //             return "No"
        //         }
        //     },
        //     {
        //         headerName: "RC",
        //         field: "doc_name",          
        //         filter: true,
        //         editable: true,
        //         width:90,
        //         headerHeight: 140,
        //         resizable: true,
        //         valueGetter : (param) =>{
        //             let paramData = param.data.doc_name
        //             if(paramData.includes("RC")) return "yes"
        //             return "No"
        //         }
        //     },
        //     {
        //         headerName: "Road Tax",
        //         field: "doc_name",          
        //         filter: true,
        //         editable: true,
        //         width:90,
        //         headerHeight: 140,
        //         resizable: true,
        //         valueGetter : (param) =>{
        //             let paramData = param.data.doc_name
        //             if(paramData.includes("Road Tax")) return "yes"
        //             return "No"
        //         }
        //     },
        //     {
        //         headerName: "Spl. Road Tax",
        //         field: "doc_name",          
        //         filter: true,
        //         editable: true,
        //         width:90,
        //         headerHeight: 140,
        //         resizable: true,
        //         valueGetter : (param) =>{
        //             let paramData = param.data.doc_name
        //             if(paramData.includes("Spl. Road Tax")) return "yes"
        //             return "No"
        //         }
        //     },
        //     {
        //         headerName: "Tax Clearance",
        //         field: "doc_name",          
        //         filter: true,
        //         editable: true,
        //         width:90,
        //         headerHeight: 140,
        //         resizable: true,
        //         valueGetter : (param) =>{
        //             let paramData = param.data.doc_name
        //             if(paramData.includes("Tax Clearance")) return "yes"
        //             return "No"
        //         }
        //     },
        //     {
        //         headerName: "Vehicle Particulars",
        //         field: "doc_name",          
        //         filter: true,
        //         editable: true,
        //         width:90,
        //         headerHeight: 140,
        //         resizable: true,
        //         valueGetter : (param) =>{
        //             let paramData = param.data.doc_name
        //             if(paramData.includes("Vehicle Particulars")) return "yes"
        //             return "No"
        //         }
        //     },

        // ]
        const {show,basicType,basicTitle,userLoggedtype,transList,transListVal,bulkslide,docNumber,docExpDate,} = this.state
        return(
            <div className="container-fluid">
                <SweetAlert
                    show={show}
                    type={basicType}
                    title={basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                <div className="col-xl-12 col-lg-12">

                {userLoggedtype ? "" :
                    <div className="card mt-2 p-1" style={{minHeight:"100px"}}>
                        <div className="col-xl-4 col-lg-4">
                            <label className="col-xl-12 col-lg-12" style={{font: "roboto"}}>Select Transporter: </label>
                            <Select 
                                value={transListVal} 
                                onChange={this.changeTransHandler} 
                                style={{borderRadius:"0px"}} 
                                options={transList}
                                placeholder="Select Transporter"
                                required />
                        </div>
                    </div>}

                    <div className="card">
                        <div className="card-header space-between">
                            <h5 className="float-left">
                                <i className="icon-map cus-i"></i><span> Lsp Document Input</span>
                            </h5>
                            <div>
                            {userLoggedtype ? 
                               <a href="/addlspdocdetails" className="btn btn-success pull-right cs-btn"> <i className="icon-plus"></i>Add Certificate</a> : ""}
                                <button className="btn btn-warning" id="previous-screen" onClick={this.previousscreen} style={{display:"none",padding: "9px",float: "right",width: "87px"}}><i className="fa fa-arrow-left"></i> Back</button>

                            </div> 
                        </div>
                        <div className={"slide-r "+(bulkslide)} style={{overflow:"hidden", height : '600px'}}>
                            <h3 className="subH">update Lsp Doc Details</h3>
                            <div className="slide-r-body" style={{position:"relative"}}>             
                                <form className=" theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler}>
                                <div className="row col-xl-12 pt-3">
                                        <div className="col-xl-12">
                                            <label className="col-xl-12 col-lg-12 pl-0">Document Number* : </label>
                                            <input type="text" name="documentno" id="documentno" className="form-control" required/>
                                        </div>
                                        <div className="col-xl-12">
                                            <label className="col-xl-12 col-lg-12 pl-0">Validity/Expiry Date* : </label>
                                            <input type="text" name="validity_date" id="validity_date" className="datetimepicker_date form-control" required />
                                        </div>
                                        <div className="col-xl-12">
                                            <label className="col-xl-12 col-lg-12 pl-0">Upload File</label> 
                                            <input type="file" name="loadFile" id="uploadFile" 
                                            onChange={this.changeFileHandler}  
                                            className="form-control" required  />
                                        </div>
                                        <div className="col-xl-12">                                       
                                            <input type="hidden" id="hidden_uploadfilenow"
                                            value={this.state.hidden_uploadfilenow} />
                                        </div>                                       
                                    </div>                                   
                                    <div class={"row col-sm-12"}>
                                        <div className="row col-xl-12 m-top-1">
                                            <div class="col-xl-12">
                                            <button type="submit" className="btn btn-success" style={{marginLeft:"45%",width:"192px",marginTop:"20px"}}>Submit</button>
                                            </div>
                                        </div>
                                    </div>									
				            	</form>                      
                            </div>
                        </div>
                        <div id="tptUserItemGrid" className="card-body col-sm-12">                        
                            <div id="myGrid" style={{ height: "600px",width: "100%",}}  className="ag-theme-balham">
                                <AgGridReact
                                modules={this.state.modules}
                                columnDefs= {this.state.columns}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                onCellDoubleClicked={this.updatelspvaliditydetails}
                                enableRangeSelection={true}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                getContextMenuItems={this.getContextMenuItems}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                onCellClicked={this.onCellRowClicked}			
                                enableCellChangeFlash={true}
                                suppressCellFlash={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                masterDetail={true}
                                suppressRowClickSelection={true}
                                components={this.state.components}
                                rowSelection={this.state.rowSelection}
                                // detailCellRendererParams={this.state.detailCellRendererParams}
                                // enableCharts={true}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                // stopEditingWhenGridLosesFocus={true}
                                // rowClassRules={this.state.rowClassRules}
                                //onCellClicked={this.onRowClicked.bind(this)}
                                //getRowHeight={this.state.getRowHeight}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                // onRowSelected={this.onRowSelection.bind(this)}
                                // suppressCellSelection= {true}
                                // overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}										
                                />
                            </div>								
                        </div>
                    </div>
                </div>
                </div>
                        
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll}></div>
            </div>
        )
    }
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}


function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 !== "" && date2 !== "" && date1 !== undefined && date2 !== undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 !== "" && date2 !== "" && date1 !== undefined && date2 !== undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y'
	});
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import DrawMap from './mlldrawmap';
import { getDDMMYYYY, getDDMMMYYYY,getHyphenDDMMMYYYY,
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault } from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CSVFileValidator from 'csv-file-validator'
import EditMLLConsignment from "./editmllconsignment";
import CountUp from 'react-countup';
import MLLConsignmentsChildGrid from "./mllConsignmentLegs";
import ShowRoute from "./showroute";
import ShowEtaLegs from "./showetalegs";


$.datetimepicker.setLocale('en');    
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class MLLConsignmentsComponent extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            show1: false,
            basicTitle1:'',
            basicType1:"default",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
			detailCellRendererParams:{},
            frameworkComponents: {
				editMLLConsignment:EditMLLConsignment,
				showroute:ShowRoute,
				showetalegs:ShowEtaLegs
            },
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
            context: { componentParent: this },
            rowClassRules: {
				"boxbgblue": "data.to_be_approved == 1"
			},
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:200,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            pagetitle:"",
			sliderRouteTranslate:'',
			sliderForceDestination:"",
            usergridstate:[],
            screenurl:"",
            screentitle:"",
            startDate:"",
            endDate:"",
            defaultsdate:"",
            defaultedate:"",
            file:'',
			csvcontent:[],
            sliderRakeTranslate:"",
            sliderBulkRakeTranslate:"",
            file:'',
            uploadFile:'',
            eventData:[],
			sourceopt:'',
			fnr_no:"",
			gpsdevice:"",
			sources:[],
			totalvinsarr:[],
			totalvins:0,
			atplantarr:[],
			atplant:0,
			intransitloadarr:[],
			intransitload:0,
			atloadarr:[],
			atload:0,
			rakeassignarr:[],
			rakeassign:0,
			intransitdestarr:[],
			intransitdest:0,
			atdestinationarr:[],
			atdestination:0,
			intransitdealerarr:[],
			intransitdealer:0,
			intransitrsoarr:[],
			intransitrso:0,
			atrsoarr:[],
			atrso:0,
			neardealerarr:[],
			neardealer:0,
			tripendrsoarr:[],
			tripendrso:0,
			podreceiptarr:[],
			podreceipt:0,
			lastmiledestination:"",
			desttype:"",
			destypeopt:"",
			consignees:[],
			rsos:[],
			destopts:[],
			sliderForceLastMile:"",
			sliderRso:"",
			modetype:"",
			dropdownOpen: false,
			dropdownAssetOpen: false,
			sliderLoadingYardTranslate: "",
			destinations:[],
			unloadofficers:[],
			loading_officer:"",
			batransporter:"",
			transporters:[],
			devices:[],
			sliderDeviceRetrival:"",
			retrived:"",
			retrivalon:"",
			retrivedyes:"",
			retrivedno:"",
			showaction:"show-n",
			mapinfo:[],
			sliderDealerArrival:"",
			vehicles:[],
			gpsdeviceids:[],
			mapfirstmile:[],
			mapmiddlemile:[],
			maplastmilerso:[],
			maplastmiledealr:[],
			destopt:"",
			routefor:"",
			fmile:[],
			mmile:[],
			ulmile:[],
			rsomile:[]
			
        };
        // this.editRakeData = this.editRakeData.bind(this);
        // this.deleteRakeData = this.deleteRakeData.bind(this);
        this.onClickShowMap = this.onClickShowMap.bind(this);
		this.resetState = this.resetState.bind(this);
        this.onLoadRakePlanningData = this.onLoadRakePlanningData.bind(this);
		this.changeFileHandler = this.changeFileHandler.bind(this);
		this.changeDestFileHandler = this.changeDestFileHandler.bind(this);
        this.onClickCounterShowData = this.onClickCounterShowData.bind(this);
		this.onClickEditBlock = this.onClickEditBlock.bind(this);
		this.changeLastMileFileHandler = this.changeLastMileFileHandler.bind(this);
		this.onChangeLastMilDestinationItem = this.onChangeLastMilDestinationItem.bind(this);
		this.toggle = this.toggle.bind(this);
		this.toggleAsset = this.toggleAsset.bind(this);
		this.checkRadioEvent = this.checkRadioEvent.bind(this);
		this.onClickShowMapRoute = this.onClickShowMapRoute.bind(this);
		this.onClickShowETALegs = this.onClickShowETALegs.bind(this);
	}
	
    componentDidMount(){
		loadDateTimeScript();
        var sdate = moment().startOf('month').format('YYYY-MM-DD');
        //var sdate = "2020-09-23";
        var edate   = moment().endOf('month').format('YYYY-MM-DD');
		// console.log("Match props ", this.props.match.params.refno)
		if(this.props.match.path == "/mllallconsignments")
		{
			this.setState({
				showaction:"show-n"
			})
		}
		else
		{
			var roles = localStorage.getItem("roles");
			var spl = []
			try{
				var ss = roles.split(",");
				var newArray = ss.filter(function(item) {
					if(item != "verified")
					{
						return item;
					}
					
				});
				spl = newArray;
			}	
			catch(e){

			}
			// console.log("spl ", spl)
			var actions = "show-m"
			if(localStorage.getItem("roles").indexOf("Plant") >= 0 && spl.length == 1)
			{
				this.setState({
					showaction:"show-n"
				})
			}
			else{
				if(localStorage.getItem("roles").indexOf("BA") >= 0)
				{

					this.setState({
						showaction:"show-n"
					})
				}
				else{

					this.setState({
						showaction:"show-m"
					})
				}
			}
			
		}

        if(this.props.match.params.refno != undefined)
        {
            var refno = this.props.match.params.refno
        }
        else{
            var refno = "";
        }
        this.setState({
            startDate:sdate,
            endDate:edate,
            defaultsdate:sdate,
            defaultedate:edate,
            refno:refno
        })
        var mindate =  moment.parseZone().format('YYYY-MM-DD');
        var minactdate =  moment.parseZone(new Date(mindate)).subtract(1, 'days').format('YYYY-MM-DD');
       // console.log("mindate ", mindate)
       // console.log("minactdate ", minactdate)
        this.setState({
            dminDate:mindate,
            dactminDate:minactdate
        });
		// console.log("path ", this.props.match.path)
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

        var urlpath;
        var dpt;
        
			dpt='SNDG';
        
        this.setState({
			dept_code:dpt,
		});
		
		
		// redirectURL.post("/consignments/getSNDGTransporters")
		// .then((response) => {
		// 	var records = response.data;
		// 	this.setState({
		// 		transporters:records
		// 	});
		// })

        // redirectURL.post("/mllconsignments/mllloadofficers")
		// .then((response) => {
		// 	var records = response.data.records;
		// 	var unrecordsarr = []
		// 	if(records.length > 0)
		// 	{
		// 		records.map((item) => {
		// 			if(item.officer_type == "Unloading")
		// 			{
		// 				unrecordsarr.push(item)
		// 			}
		// 		})
		// 	}
		// 	this.setState({
		// 		unloadofficers:unrecordsarr
		// 	});
		// })

		try{
			redirectURL.post("/mllconsignments/mllconsignmentanalysis",{
				refno:refno
			})
			.then((response) => {
				console.log("response analysis", response.data)
				var records = response.data.records;
				if(records.length > 0)
				{
					// console.log("records ", records)
					this.setState({
						fmile:records[0].first_mile_summary,
						mmile:records[0].middle_mile_summary,
						ulmile:records[0].last_mile_uld_to_rso_summary,
						rsomile:records[0].last_mile_rso_to_dlr_summary
					})
				}
				
			})
		}
		catch(e){

		}

		redirectURL.post("/mllconsignments/mllfirstmileretrivedevices")
		.then((response) => {
			var transporters = response.data.transporters;
			var loadofficers = response.data.loadingofficers;
			var devices = response.data.devices;
			var recordsarr = [];
			var unrecordsarr = []
			if(loadofficers.length > 0)
			{
				loadofficers.map((item) => {
					
					if(item.officer_type == "Unloading")
					{
						unrecordsarr.push(item)
					}
				})
			}

			this.setState({
				transporters:transporters,
				unloadofficers:unrecordsarr,
				devices:devices
			});
		})

        redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			//console.log("resp ", resp)
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
        })
		if(this.props.match.path == "mllallconsignments")
		{
			var parameters = {
				startdate:sdate,
				enddate:edate
			}
		}
		else
		{
			var parameters = {
				startdate:sdate,
				enddate:edate,
				refno:refno
			}
		}
        
        this.onLoadRakePlanningData(parameters)

	}
    
    onLoadRakePlanningData(parameters)
    {
		redirectURL.post("/mllconsignments/mllloaddestpoints")
		.then((response) => {
			var records = response.data.records;
			var loadings = [];
			var destinatinos = [];
			if(records.length > 0)
			{
				records.map((item) => {
					if(item.rail_loading_point == 1)
					{
						loadings.push(item)
					}
					if(item.rail_delivery_point == 1)
					{
						destinatinos.push(item)
					}
				})
			}
			this.setState({
				sources:loadings,
				destinations:destinatinos
			});
		})
       
	   redirectURL.post("/mllconsignments/mllsndconsignees")
		.then((response) => {
			var records = response.data.records;
			this.setState({
				consignees:records
			});
		})
       
	   redirectURL.post("/mllconsignments/mllrsos")
	   .then((response) => {
		   var records = response.data.records;
		   this.setState({
			   rsos:records
		   });
	   })
		redirectURL.post("/mllconsignments/mllconsignments",parameters)
		.then((response) => {
			var records = response.data.records;
			var totalvinsarr=[];
			var totalvins=0;
			var atplantarr=[];
			var atplant=0;
			var intransitloadarr=[];
			var intransitload=0;
			var atloadarr=[];
			var atload=0;
			var rakeassignarr=[];
			var rakeassign=0;
			var intransitdestarr=[];
			var intransitdest=0;
			var atdestinationarr=[];
			var atdestination=0;
			var intransitdealerarr=[];
			var intransitdealer=0;
			var intransitrsoarr=[];
			var intransitrso=0;
			var atrsoarr=[];
			var atrso=0;
			var neardealerarr=[];
			var neardealer=0;
			var tripendrsoarr = [];
			var tripendrso = 0;
			var podreceiptarr=[];
			var podreceipt=0;
			var vehicles = []
			if(records.length > 0)
			{
				records.map((item) => {
					vehicles.push({vin_no:item.vehicle_id});
					if(parseInt(item.status) == 1)
					{
						atplantarr.push(item)
					}
					if(parseInt(item.status) == 2)
					{
						intransitloadarr.push(item)
					}
					if(parseInt(item.status) == 3 && item.loading_yard_reached_on != "")
					{
						atloadarr.push(item)
					}
					if(parseInt(item.status) == 4)
					{
						rakeassignarr.push(item)
					}
					if(parseInt(item.status) == 5)
					{
						intransitdestarr.push(item)
					}
					if(parseInt(item.status) == 6)
					{
						atdestinationarr.push(item)
					}

					if(parseInt(item.status) == 7)
					{
						intransitrsoarr.push(item)
					}
					if(parseInt(item.status) == 8)
					{
						atrsoarr.push(item)
					}
					if(parseInt(item.status) == 9)
					{
						intransitdealerarr.push(item)
					}
					if(parseInt(item.status) == 10  && (item.trip_closed_at_rso == 0 || item.trip_closed_at_rso == ""
					 || item.trip_closed_at_rso == undefined))
					{
						neardealerarr.push(item)
					}
					if(parseInt(item.status) == 11)
					{
						podreceiptarr.push(item)
					}
					if(parseInt(item.status) == 10 && item.trip_closed_at_rso == 1)
					{
						tripendrsoarr.push(item)
					}
				})
			}
			this.setState({
				rowData:records,
				totalvinsarr:records,
				totalvins:records.length,
				atplantarr:atplantarr,
				atplant:atplantarr.length,
				intransitloadarr:intransitloadarr,
				intransitload:intransitloadarr.length,
				atloadarr:atloadarr,
				atload:atloadarr.length,
				rakeassignarr:rakeassignarr,
				rakeassign:rakeassignarr.length,
				intransitdestarr:intransitdestarr,
				intransitdest:intransitdestarr.length,
				atdestinationarr:atdestinationarr,
				atdestination:atdestinationarr.length,
				intransitdealerarr:intransitdealerarr,
				intransitdealer:intransitdealerarr.length,
				intransitrsoarr:intransitrsoarr,
				intransitrso:intransitrsoarr.length,
				atrsoarr:atrsoarr,
				atrso:atrsoarr.length,
				neardealerarr:neardealerarr,
				neardealer:neardealerarr.length,
				podreceiptarr:podreceiptarr,
				podreceipt:podreceiptarr.length,
				tripendrsoarr:tripendrsoarr,
				tripendrso:tripendrsoarr.length,
				vehicles:vehicles
			});
		})
       this.setState({
        loadshow:"show-n",
        overly:"show-n"
       })
    }
    
	onClickSaveGridState(){
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		// console.log("Columns ", window.colState)
		// console.log("Group ", window.groupState)
		// console.log("Sort ", window.sortState)
		// console.log("Filter ", window.filterState)
		let screenpage = '';

		screenpage='MLL Consignments';
		

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{

		if(this.state.usergridstate.length > 0)
		{
			var windowstates = this.state.usergridstate;
			try{
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
				
			}
			catch(e){

			}
			
		}
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

    };
    
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}
	/*Alert Popups*/
	closeAlert = async () => {
        await this.setState({
            show: false
        });
        // await window.location.reload()
    }

	/*Alert Popups*/
	closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        //await window.location.reload()
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
    onClickCounterShowData(param)
    {
        if(param == "approval")
        {
            this.gridApi.setRowData(this.state.to_be_approved_list);
        }
    }
	onShowUploadDiv = () => {
		//console.log("Click Upload Button")
		this.setState({
			uploadDivWidth:'30%',
			sliderTranslate:"slider-translate",
			showDiv:'show-m',
			csvmessage:'',
			csverrmessage:'',
		});
    }
    
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onClickShowRakeSidebar = (params) =>{
		//console.log("Pareas ", params)
		//ar rownode = this.gridApi.getSelectedNodes();
    
        this.setState({
            forceclosedata:params.data,
            sliderRakeTranslate:"slider-translate",
            overly:'show-m',
            formtype:"add"
        })
	
	}
	
	onClickShowLoadingYardSidebar(){
		this.setState({
            sliderLoadingYardTranslate:"slider-translate-60p",
            overly:'show-m',
        })
	}
    
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
            sliderRakeTranslate:'',
            sliderBulkRakeTranslate:"",
			bulkslide:'',
			sliderForceDestination:'',
			sliderBulkForceClose:"",
			sliderForceLastMile:"",
			sliderRso:"",
			sliderLoadingYardTranslate: "",
			sliderDeviceRetrival:"",
			sliderDealerArrival:""
		});
		
    }
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
   
    selectsources(){
		let items = [];    
		try{
			if(this.state.sources.length > 0)
			{
				this.state.sources.map((item) =>{
					items.push({"value":item._id,"label":item.geofence_name})
				})
			}
		}
		catch(e){

		}		
		return items;
	}
	
    selectdestinations(){
		let items = [];    
		try{
			if(this.state.destinations.length > 0)
			{
				this.state.destinations.map((item) =>{
					items.push({"value":item._id,"label":item.geofence_name})
				})
			}
		}
		catch(e){

		}
		
		return items;
	}
        
    gpsdevices(){
		let items = [];
		var devices = this.state.devices;
		if(devices.length > 0)
		{
			devices.map((item) => {
				items.push({"value":item.device_code,"label":item.device_code})
			})
		}    
		return items;
	}
    
    onChangeSourceItem(sourceopt){
		this.setState(
			{ sourceopt },
			() => console.log(`Option selected:`, this.state.sourceopt)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
	onChangeDestinationItem(destopt){
		this.setState(
			{ destopt },
			() => console.log(`Option selected:`, this.state.destopt)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
   
    handlerArrivalDate = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        
        var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
        
        this.setState({
            arrival_Date:startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }
    
    handlerActualArrivalDate = (event, currentDate, selectedDate) => {
       var d = new Date(event._d);
       
       var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
       
       this.setState({
        msilactualrake:startdate
       });
       //let value = event.target.value;
       //this.setState({'startDate':value});
   }

   handleEventClick= ({event}) => {
    // openAppointment is a function I wrote to open a form to edit that appointment
    //this.props.openAppointment(event.extendedProps)
        //console.log("Evernt ", event)
       // console.log(event._def.extendedProps)
        var rowid = event._def.extendedProps.rowid;
        redirectURL.post("/consignments/rakescheduleitem",{
            rowid:rowid
          })
          .then((response) => {
              var recds = response.data;
              //console.log("recds ", recds)
                if(recds.length > 0)
                {
                   // this.editRakeData(recds[0])
                }
          })
    }

    handleEventDrop = (info) => {
            if(window.confirm("Are you sure you want to change the event date?")){
                console.log('change confirmed')

                // updateAppointment is another custom method
               // this.props.updateAppointment({...info.event.extendedProps, start: info.event.start, end: info.event.end})

            } else {
                console.log('change aborted')
            }
    }

    onClickShowView(view)
    {
       // console.log("View ",view)
        if(view == "grid")
        {
            this.setState({
                gridview:'show-m',
                calcview:'show-n'
            })
        }
        if(view == "calender")
        {
            this.setState({
                gridview:'show-n',
                calcview:'show-m',
                calview:"timeGridWeek"
            })
        }
    }
    
    onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderRakeTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n',
			sliderDealerArrival:"",
		});
	}
	onClickShowMap = (params) =>{
       
    }

    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	
	};
	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
	 }

     handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDate:edate
    	});
    }
    onClickFilter = async () => {
		var sDate = this.state.startDate;
		var eDate = this.state.endDate;

		this.setState({
			startDate:sDate,
			endDate:eDate,
			loadshow:'show-m'
		});

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedSelectConsigner,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
		if(sDate != "NaN-NaN-NaN" || sDate != "")
		{
			var stdate = sDate;	
		}
		else
		{
			var stdate = this.state.defaultsdate
		}
		if(eDate != "NaN-NaN-NaN" || eDate != "")
		{
			var etdate = eDate;	
		}
		else
		{
			var etdate = this.state.defaultedate
        }
        var parameters = {
            startdate:stdate,
            enddate:etdate,
        }
        this.onLoadRakePlanningData(parameters)
    }


	changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'vin_no',
					inputName: 'vin_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		// if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		// {
		// }
		// else{
		// 	e.target.value = null;
		// 	this.setState({
		// 		uploadFile:'',
		// 		show: true, 
		// 		basicType:'danger', 
		// 		basicTitle:'Please upload file having extensions .csv only.',
		// 	});
		// }
		
	}

	
	changeDestFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'vin_no',
					inputName: 'vin_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		// if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		// {
		// }
		// else{
		// 	e.target.value = null;
		// 	this.setState({
		// 		uploadFile:'',
		// 		show: true, 
		// 		basicType:'danger', 
		// 		basicTitle:'Please upload file having extensions .csv only.',
		// 	});
		// }
		
	}

    onClickUploadRakePlanning = async (event) => {
        this.setState({
            sliderBulkRakeTranslate:"slider-translate",
            overly:'show-m'
        })
    }

    formRakeSchedule = async(event) => {
        event.preventDefault()

        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Rake Schedule",
				"action": "form",
				"label": "Rake Planning Bulk Upload",
			}
			googleAnalytics.logEvent(eventOptions);
        }
       
		// console.log("csvcontent ", this.state.csvcontent);
		var fnr_no = this.state.fnr_no;
		if(this.state.gpsdevice != "")
		{
			var gpsdevice = this.state.gpsdevice.value
        }
		else
		{
			var gpsdevice = ""
		}
		var gpsdeviceids = []
		if(this.state.gpsdeviceids.length > 0)
		{
			
			this.state.gpsdeviceids.map((item) => {
				gpsdeviceids.push(item.value)
			})
		}
		console.log("gpsdeviceids ", gpsdeviceids)
		var dest_yard_reachedon = $("#dest_yard_reachedon").val()
		console.log("dest_yard_reachedon ", dest_yard_reachedon)
		// var transporter_code  = this.state.batransporter.value;
		// var transporter_name  = this.state.batransporter.label;

		if(this.state.loading_officer != "")
		{
			var loading_officer_code =  this.state.loading_officer.value;
			var loading_officer_name =  this.state.loading_officer.label;
	
		}
		else
		{
			var loading_officer_code =  "";
			var loading_officer_name =  "";
		}
		
		
		if(this.state.sourceopt != "")
		{
			var source =  this.state.sourceopt.value;
			var source_name =  this.state.sourceopt.label;
	
		}
		else
		{
			var source =  "";
			var source_name =  "";
		}
		
		if(this.state.destopt != "")
		{
			var destination = this.state.destopt.value;
			var destination_name =this.state.destopt.label;
		}
		else
		{
			var destination = "";
			var destination_name ="";
		}

		if(source != "" && fnr_no != "" && gpsdeviceids.length > 0 && destination != ""
		 && dest_yard_reachedon != "" && this.state.csvcontent != "" 
		 && this.state.csvcontent.length != 0 && dest_yard_reachedon != "__-__-____ __:__")
		{
			
			
			var reqparams = {
				refno:this.state.refno,
				csvcontent:this.state.csvcontent,
				source:source,
				source_name:source_name,
				destination:destination,
				destination_name:destination_name,
				fnr_no:fnr_no,
				gpsdevice:gpsdeviceids,
				dest_yard_reachedon:dest_yard_reachedon,
				unloading_officer_code:loading_officer_code,
				unloading_officer_name:loading_officer_name,
				// transporter_code:transporter_code,
				// transporter_name:transporter_name,
				userid:localStorage.getItem("userid"),
				username:localStorage.getItem("username"),
				useremail:localStorage.getItem("email")
			}
			console.log("reqparams ", reqparams)
			
			redirectURL.post("/mllconsignments/verifymllrakedata",{csvcontent:this.state.csvcontent})
			.then((response) => {
				console.log("resposnedata ", response.data)
				if(response.data.status == "notexists")
				{
					this.setState({
						show:true,
						basicTitle:"Uploaded vins are not exists",
						basicType:"danger"
					})
			
				}
				else if(response.data.status == "empty")
				{
					this.setState({
						show:true,
						basicTitle:"No data found",
						basicType:"danger"
					})
			
				}
				else
				{
					if(response.data.records.length == 0)
					{
						this.setState({
							show:true,
							basicTitle:"Uploaded Vins are not marked arrival",
							basicType:"danger"
						});
					}
					else
					{
						redirectURL.post("/mllconsignments/updaterakeschedulevins", reqparams)
						.then((response) => {
							if(response.data.status == "success" && response.data.notvalid.length == 0)
							{
								$("#dest_yard_reachedon").val("")
								this.setState({
									sliderRakeTranslate:"",
									overly:'show-n',
									sourceopt:"",
									gpsdevice:"",
									csvcontent:[],
									fnr_no:"",
									loadshow:'show-n',
									uploadFile:'',
									file:'',
									show:true,
									basicTitle:"Successfully update items",
									basicType:"success"
								});
								var parameters = {
									startdate:this.state.startDate,
									enddate:this.state.endDate,
									refno:this.state.refno
								}
								this.onLoadRakePlanningData(parameters)
							}
							else
							{
								if( response.data.notvalid.length > 0)
								{
									this.setState({
										show:true,
										basicTitle:"Vins are not maked arrival at loading yard. Vins : "+response.data.notvalid,
										basicType:"danger"
									})
								}
								else
								{
									this.setState({
										show:true,
										basicTitle:"Failed to update items",
										basicType:"danger"
									})
								}
								
							}
						})
						.catch(function(e){
							console.log("Error ", e)
						})
					}
				}
				
			})
			
			
		}
		else
		{
			this.setState({
				show:true,
				basicTitle:"All * fields are mandatory",
				basicType:"danger"
			})
		}
    }

    onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
		this.gridApi.deselectAll();
	   this.setState({ open: false });
     };
    
    onClickEditBlock(params){
        console.log("params ",params)
	}
	
	
    
    onChangeDeviceItem(gpsdeviceids){
		this.setState(
			{ gpsdeviceids },
			() => {
				if(gpsdeviceids.length > 0)
				{
					if(gpsdeviceids.length > 2)
					{
						this.setState({
							show1:true,
							basicTitle1:"Maximum 2 devices are allowed",
							basicType1:"info",
							gpsdeviceids:[]
						})
					}	
				}
			}
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
	onClickForceMoveDestinationYard(){
		//console.log("Force MOve ")
		this.setState({
			sliderForceDestination:'slider-translate',
			overly:"show-m"
		});
	}
	
	formForceMoveDestinationYardHandler = async (event) =>{
		event.preventDefault();

		var dest_yard_reachedon = document.getElementById("dest_yard_reached").value;
		var dest_yard = dest_yard_reachedon.split(" ");
		var spyard = dest_yard[0].split("-");
		var destyardreachedon = spyard[2]+"-"+spyard[1]+"-"+spyard[0]+" "+dest_yard[1]+":00"
		if(dest_yard_reachedon != "" && dest_yard_reachedon != "__-__-____ __:__")
		{
			var reqparams = {
				dest_yard_reachedon:dest_yard_reachedon,
				refno:this.state.refno,
				userid:localStorage.getItem('userid'),
				username:localStorage.getItem('username'),
				email:localStorage.getItem('email'),
				csvcontent:this.state.csvcontent
			}
			redirectURL.post("/mllconsignments/verifymllrakedestdata",{csvcontent:this.state.csvcontent})
			.then((response) => {
				console.log("resposnedata ", response.data)
				if(response.data.status  == "nodata")
				{
					this.setState({
						show:true,
						basicTitle:"Invalid data uploaded",
						basicType:"danger"
					});
				}
				else if(response.data.status  == "notexists")
				{
					this.setState({
						show:true,
						basicTitle:"Uploaded Vins are not rake assigned",
						basicType:"danger"
					});
				}
				else if(response.data.status  == "success")
				{
					redirectURL.post("/mllconsignments/mllmovedestinationyardarrival", reqparams)
					.then((response) => {
						// console.log("Respone ", response.data)
						var rec = response.data;
						if(rec.notvalid.length == 0 && rec.notexists.length == 0)
						{
							this.setState({
								show: true, 
								basicType:'success', 
								basicTitle:"Successfully Uploaded.",
								uploadDivWidth:'0%',
								sliderForceDestination:'',
								overly:'show-n',
								uploadFile:'',
								loadshow:'show-n',
								file:''
							});
							var parameters = {
								startdate:this.state.startDate,
								enddate:this.state.endDate,
								refno:this.state.refno
							}
							this.onLoadRakePlanningData(parameters)
						}
						else if(rec.notvalid.length > 0)
						{
							this.setState({
								show: true, 
								basicType:'danger', 
								basicTitle:"Destination yard reached on should be greater than Dispatch date. "+rec.notvalid,
								uploadDivWidth:'0%',
								sliderForceDestination:'',
								overly:'show-n',
								uploadFile:'',
								loadshow:'show-n',
								file:''
							});
						}
						else if(rec.notexists.length > 0)
						{
							this.setState({
								show: true, 
								basicType:'danger', 
								basicTitle:rec.notexists+" are not exists",
								uploadDivWidth:'0%',
								sliderForceDestination:'',
								overly:'show-n',
								uploadFile:'',
								loadshow:'show-n',
								file:''
							});
						}
						else{
							
							
							
						}
						
					})
				}
			})
		}
		else
		{
			this.setState({
				show:true,
				basicTitle:"Please select data and time",
				basicType:"danger"
			});
		}
		
	
	}
	
    onClickCounter(data)
	{
		if(data == "all")
		{
			this.setState({
				rowData:this.state.totalvinsarr
			})
		}
		if(data == "atplant")
		{
			this.setState({
				rowData:this.state.atplantarr
			})
		}
		if(data == "intransitload")
		{
			this.setState({
				rowData:this.state.intransitloadarr
			})
		}
		if(data == "atload")
		{
			this.setState({
				rowData:this.state.atloadarr
			})
		}
		if(data == "rakeassign")
		{
			this.setState({
				rowData:this.state.rakeassignarr
			})
		}
		if(data == "intransitdest")
		{
			this.setState({
				rowData:this.state.intransitdestarr
			})
		}
		if(data == "atdestination")
		{
			this.setState({
				rowData:this.state.atdestinationarr
			})
		}
		if(data == "intransitrso")
		{
			this.setState({
				rowData:this.state.intransitrsoarr
			})
		}
		if(data == "atrso")
		{
			this.setState({
				rowData:this.state.atrsoarr
			})
		}
		if(data == "intransitdealer")
		{
			this.setState({
				rowData:this.state.intransitdealerarr
			})
		}
		if(data == "neardealer")
		{
			this.setState({
				rowData:this.state.neardealerarr
			})
		}
		if(data == "podreceipt")
		{
			this.setState({
				rowData:this.state.podreceiptarr
			})
		}
		if(data == "tripendrso")
		{
			this.setState({
				rowData:this.state.tripendrsoarr
			})
		}
	}

	
	onClickLastMileTransport(){
		//console.log("Force MOve ")
		this.setState({
			sliderForceLastMile:'slider-translate',
			overly:"show-m"
		});
	}
	
	changeLastMileFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'vin_no',
					inputName: 'vin_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		// var out = new Promise(function(reject, resolve){
		// 	var reader =  new FileReader();
		// 	reader.onload = async function(e) {
		// 		var contents = await e.target.result;
		// 		console.log("contents ", contents )
		// 		resolve(contents);
		// 	};
		// 	var tt =  reader.readAsText(e.target.files[0]);
		// 	console.log("tt ",tt)
		// });
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		// if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		// {
		// }
		// else{
		// 	e.target.value = null;
		// 	this.setState({
		// 		uploadFile:'',
		// 		show: true, 
		// 		basicType:'danger', 
		// 		basicTitle:'Please upload file having extensions .csv only.',
		// 	});
		// }
		
	}

	onChangeLastMilDestinationItem(lastmiledestination){
		this.setState(
			{ lastmiledestination },
			async () => {
				var items = []
				// console.log("lastmiledestination.value ", lastmiledestination.value)
				if(lastmiledestination.value != "")
				{
					var desttype = "";
					// console.log("lastmiledestination.value ",this.state.rsos)
					if(lastmiledestination.value == "RSO")
					{
						var rso = this.state.rsos;
						if(rso.length > 0)
						{
							rso.map((item) => {
								items.push({
									value:item.plant_code,
									label:item.plant_name
								});
							})
						}
						desttype = "Select RSO";
					}
					if(lastmiledestination.value == "Dealer")
					{
						var consignees = this.state.consignees;
						// console.log("consignees", consignees)
						if(consignees.length > 0)
						{
							consignees.map((item) => {
								items.push({
									value:item.consignee_code,
									label:item.consignee_name
								});
							})
						}
						
						// console.log("consignees items ", items)
						desttype = "Select Dealer";
					}
					
				}
				// console.log("items ", items)
				await this.setState({
					destopts:items,
					desttype:desttype
				})
			}
          );
		  
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:'',
			//   destopts:items
		  })
	}

	onChangeDestType(destypeopt){
		this.setState(
			{ destypeopt },
			() => console.log(`Option selected:`, this.state.destypeopt)
		  );
		  
		 
	}
	onChangeModeType(modetype){
		this.setState(
			{ modetype },
			() => console.log(`Option selected:`, this.state.modetype)
		  );
	}
	desttype(){
		var items = []
		if(this.state.destypeopt != "")
		{
			if(this.state.destypeopt.value == "RSO")
			{
				var rso = this.state.rsos;
				if(rso.length > 0)
				{
					rso.map((item) => {
						item.push({
							value:item.rso,
							label:item.rso
						});
					})
				}
			}
			if(this.state.destypeopt.value == "Dealer")
			{
				var consignees = this.state.consignees;
				if(consignees.length > 0)
				{
					consignees.map((item) => {
						item.push({
							value:item.consignee_code,
							label:item.consignee_name
						});
					})
				}
			}
		}
		return items;
	}

	formLastMileData(event)
	{
		event.preventDefault();
		var destypeopt = this.state.destypeopt.value;
		var destypeoptname = this.state.destypeopt.label;
		var lastmiledestination = this.state.lastmiledestination.value;
		var csvcontent = this.state.csvcontent;
		var modetype = this.state.modetype.value;
		var parameters = {
			destypeopt:destypeopt,
			destypeoptname:destypeoptname,
			lastmiledestination:lastmiledestination,
			modetype:modetype,
			csvcontent:csvcontent,
			email:localStorage.getItem("email"),
			username:localStorage.getItem("username"),
			userid:localStorage.getItem("userid")
		}
		// console.log("parameters ", parameters)

		redirectURL.post("/mllconsignments/verifymlllastmile",{csvcontent:this.state.csvcontent})
		.then((response) => {
			// console.log("resposnedata ", response.data)
			if(response.data.records.length == 0)
			{
				this.setState({
					show:true,
					basicTitle:"Uploaded Vins destination not exists",
					basicType:"danger"
				});
			}
			else
			{
				
				redirectURL.post("/mllconsignments/mlllasmileupdate", parameters)
				.then((response) => {
					// console.log("Respone ", response.data)
					var rec = response.data;
					if(rec.notvalid.length == 0)
					{
						this.setState({
							show: true, 
							basicType:'success', 
							basicTitle:"Successfully updated.",
							uploadDivWidth:'0%',
							sliderForceLastMile:'',
							overly:'show-n',
							uploadFile:'',
							loadshow:'show-n',
							file:'',
							csvcontent:""
						});
						var parameters = {
							startdate:this.state.startDate,
							enddate:this.state.endDate,
							refno:this.state.refno
						}
						this.onLoadRakePlanningData(parameters)
					}
					else if(rec.notvalid.length > 0)
					{
						this.setState({
							show: true, 
							basicType:'danger', 
							basicTitle:"Destination yard not exists. ",
							uploadDivWidth:'0%',
							sliderForceDestination:'',
							overly:'show-n',
							uploadFile:'',
							loadshow:'show-n',
							file:''
						});
					}
					else if(rec.notexists.length > 0)
					{
						this.setState({
							show: true, 
							basicType:'danger', 
							basicTitle:rec.notexists+" are not exists",
							uploadDivWidth:'0%',
							sliderForceDestination:'',
							overly:'show-n',
							uploadFile:'',
							loadshow:'show-n',
							file:''
						});
					}
					else{
						
						
						
					}
					
				})
			}
		})
	}

	onClickRSOData(){
		this.setState({
			sliderRso:'slider-translate',
			overly:'show-m',
		})
	}

	formRSOData(event){
		event.preventDefault();
		var rsoreachedon = $("#rso_reached").val();
		console.log("rsoreachedon ", rsoreachedon)
		if(rsoreachedon != "" && rsoreachedon != "__-__-____ __:__" && rsoreachedon != undefined)
		{
			var csvcontent = this.state.csvcontent;
			var parameters = {
				rsoreachedon:rsoreachedon,
				csvcontent:csvcontent,
				email:localStorage.getItem("email"),
				username:localStorage.getItem("username"),
				userid:localStorage.getItem("userid")
			}
			// console.log("parameters ", parameters)
	
			redirectURL.post("/mllconsignments/verifymllrso",{csvcontent:this.state.csvcontent})
			.then((response) => {
				// console.log("resposnedata ", response.data)
				if(response.data.status  == "nodata")
				{
					this.setState({
						show:true,
						basicTitle:"Invalid data uploaded",
						basicType:"danger"
					});
				}
				else if(response.data.status  == "notexists")
				{
					this.setState({
						show:true,
						basicTitle:"Uploaded Vins are not rake assigned",
						basicType:"danger"
					});
				}
				else if(response.data.status  == "success")
				{				
					redirectURL.post("/mllconsignments/mllrsoupdate", parameters)
					.then((response) => {
						// console.log("Respone ", response.data)
						var rec = response.data;
						$("#uploadFile").val("")
						if(rec.notvalid.length == 0)
						{
							$("#rso_reached").val("");
							this.setState({
								show: true, 
								basicType:'success', 
								basicTitle:"Successfully updated.",
								uploadDivWidth:'0%',
								sliderRso:'',
								overly:'show-n',
								uploadFile:'',
								loadshow:'show-n',
								file:'',
								csvcontent:""
							});
							var parameters = {
								startdate:this.state.startDate,
								enddate:this.state.endDate,
								refno:this.state.refno
							}
							this.onLoadRakePlanningData(parameters)
						}
						else if(rec.notvalid.length > 0)
						{
							this.setState({
								show: true, 
								basicType:'danger', 
								basicTitle:"In Transit RSO not exists. ",
								uploadDivWidth:'0%',
								sliderRso:'',
								overly:'show-n',
								uploadFile:'',
								loadshow:'show-n',
								file:''
							});
						}
						
						else{
							
							
							
						}
						
					})
				}
				else
				{
					this.setState({
						show:true,
						basicTitle:"Failed to update data",
						basicType:"danger"
					});
				}
			})
		}
		else
		{
			this.setState({
				show:true,
				basicTitle:"Please select data and time",
				basicType:"danger"
			});
		}
		
	}
	
	toggle() {

        this.setState(prevState => ({

            dropdownOpen: !prevState.dropdownOpen

        }));

	}
	
	toggleAsset() {

        this.setState(prevState => ({

            dropdownAssetOpen: !prevState.dropdownAssetOpen

        }));

	}
	transporters()
	{
		let items = [];
		try{
			if(this.state.transporters.length > 0)
			{
				this.state.transporters.map((item) =>{
					items.push({"value":item.transporter_code,"label":item.transporter_name})
				})
			}
		}
		catch(e){

		}
		
		return items;
	}
	loadingofficers()
	{
		let items = [];
		try{
			if(this.state.unloadofficers.length > 0)
			{
				this.state.unloadofficers.map((item) =>{
					items.push({"value":item.load_officer_code,"label":item.load_officer_name})
				})
			}
		}
		catch(e){

		}
		
		return items;
	}
	
	onChangeBA(batransporter){
		this.setState(
			{ batransporter },
			() => console.log(`Option selected:`, this.state.batransporter)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    onChangeLoadingOfficer(loading_officer){
		this.setState(
			{ loading_officer },
			() => console.log(`Option selected:`, this.state.loading_officer)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}

	
	onClickGPSDeviceRetrival(){
		this.setState({
			sliderDeviceRetrival:'slider-translate',
			overly:'show-m',
		})
	}

	formDeviceRetrival(event){
		event.preventDefault();
		var retrivalon = $("#retrivalon").val();
		var retrived = ""
		$(".retrivecls").each(function(){
			if($(this).is(":checked") == true)
			{
				retrived = $(this).val();
			}
		})
		if(this.state.gpsdevice != "")
		{
			var gpsdeviceid = this.state.gpsdevice.value
		}
		else{
			var gpsdeviceid = ""
		}
		var parameters = {
			retrivalon:retrivalon,
			retrived:retrived,
			gpsdeviceid:gpsdeviceid,
			email:localStorage.getItem("email"),
			username:localStorage.getItem("username"),
			userid:localStorage.getItem("userid")
		}
		console.log("parameters ", parameters)

					
		redirectURL.post("/mllconsignments/mllretrived", parameters)
		.then((response) => {
			// console.log("Respone ", response.data)
			var rec = response.data;
			if(rec.status == "success")
			{
				document.getElementById("retrivalon").value = "";
				this.setState({
					show: true, 
					basicType:'success', 
					basicTitle:"Successfully updated.",
					sliderDeviceRetrival:'',
					overly:'show-n',
					loadshow:'show-n',
					gpsdevice:""
				});
				$(".retrivecls").each(function(){
					if($(this).is(":checked") == true)
					{
						retrived = $(this).prop("checked", false);
					}
				})

			}
			else
			{
				this.setState({
					show: true, 
					basicType:'danger', 
					basicTitle:"Failed to update. ",
					sliderDeviceRetrival:'',
					overly:'show-n',
					loadshow:'show-n'
				});
			}
			
			
		})
			
	}

	
    checkRadioEvent = (event) => {
        var boolValue = event.target.value;

        if (typeof event.target.value == "string" && event.target.name != "env_show") {
            boolValue = (event.target.value.toLowerCase() === 'true');
            
        }

        if ( event.target.name == "is_group_header") {
            let displayGroupHeader = (boolValue) ? "show-m" : "show-n";
            this.setState({groupheader:displayGroupHeader});
        }
        
    	let name = event.target.name;
		//let value = event.target.value;
        this.setState({[name]:boolValue});
        if (this.state.updateData) {
            let newUpdateData = this.state.updateData;
            newUpdateData[name] = boolValue;
            this.setState({updateData:newUpdateData});
        }
    }
	

	onClickShowMapRoute(rownode)
	{
		console.log("Rownode ", rownode)
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		// var mapinfo = [
		// 	{
		// 		coords:[],
		// 		breaks:[],
		// 		route_details:{
		// 			start_time:"",
		// 			end_time:""
		// 		}
		// 	}
		// ]
		// var mp = {
		// 	"coords":[
		// 		{
		// 		"truck_no":"359510083107743",
		// 		"lat":19.941382,
		// 		"lng":73.839649,
		// 		"speed":0,
		// 		"stime":"2021-03-18 18:05:00",
		// 		"received_on":"2021-03-18 18:05:57",
		// 		"dist_from_prev_point":0.0,
		// 		"time_from_prev_point":0.0,
		// 		"msgtype":"R",
		// 		"set":1
		// 		}
		// 		],
		// 		"route_details":{
		// 		"start_time":"2021-03-18 18:05:00",
		// 		"end_time":"2021-03-20 23:58:36",
		// 		"distance":960958.134,
		// 		"distance_without_google":959864.73
		// 		},
		// 		"phase_details":[
		// 			{
		// 				"device_id":"359510083107743",
		// 				"from_date":"2021-03-14 10:11:00",
		// 				"to_date":"2021-03-21 00:00:00",
		// 				"mode_type":"Convoy",
		// 				"set":1,
		// 				"breaks":[
		// 					{
		// 					"break_start":"2021-03-18 18:05:00",
		// 					"break_end":"2021-03-18 20:10:25",
		// 					"lat":19.941382,
		// 					"lng":73.839649,
		// 					"break_time_seconds":7525
		// 					}
		// 				]
		// 			}
		// 		],
		// 		"breaks": [{"break_start": "2021-03-18 18:05:00", "break_end": "2021-03-18 20:10:25", "lat": 19.941382, "lng": 73.839649, "break_time_seconds": 7525.0}]
					
		// 	}
		// console.log("mp ", mp)
		
		// console.log("mapfirstmile ", mapfirstmile)
		
		if(rownode.data.modetype == "Convoy")
		{
			var truck_no = rownode.data.gpsdeviceid;
		}
		else
		{
			var truck_no = rownode.data.trailer_no;
		}
		var cdate = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
		if(rownode.data.ewaybilldate != "")
		{
			var fdate = moment.parseZone(rownode.data.ewaybilldate).format("YYYY-MM-DD HH:mm:ss");
		}
		else
		{
			var fdate = "";
		}
		var parameters = {
			truck_no: truck_no,
			from_date: fdate,
			to_date: cdate,
			vehicle_id: rownode.data.vehicle_id,
			bill_to_party: rownode.data.bill_to_party
		}
		redirectURL.post("/mllconsignments/mllmaproute",parameters)
		.then((response) => {
			console.log("response ", response.data)
			// console.log("records ", response.data.records)
			if(response.data.status == "success")
			{
				var mp = response.data;
				
				var phases = mp.phase_details;
				var mapfirstmile =[]
				var mapmiddlemile =[]
				var maplastmilerso =[]
				var maplastmiledealr =[]
				var breaks = []
				if(phases.length > 0)
				{
					phases.map((item) => {
						if(item.breaks.length > 0)
						{
							var allbreaks = item.breaks;
							allbreaks.map((brk) => {
								breaks.push(brk);
							})
						}
						if(item.set == "1")
						{
							mapfirstmile.push(item)
						}
						if(item.set == "2")
						{
							mapmiddlemile.push(item)
						}
						if(item.set == "3" && parseInt(item.directly_to_dealer) == 0)
						{
							maplastmilerso.push(item)
						}
						
						if(item.set == "3" && parseInt(item.directly_to_dealer) == 1)
						{
							maplastmiledealr.push(item)
						}
						try{
							if(item.set == "4")
							{
								maplastmiledealr.push(item)
							}
						}
						catch(e){

						}
						
					})
				}
				// console.log("breaks ", breaks)
				var mpdata = {}
				mpdata.coords = mp.coords;
				mpdata.route_details = mp.route_details
				mpdata.additional_details = mp.additional_details
				mpdata.breaks = breaks
				this.setState({
					overly:'show-m',
					sliderRouteTranslate:"slider-translate-98p",
					mapinfo:mpdata,
					routefor:rownode.data.vehicle_id,
					mapfirstmile:mapfirstmile,
					mapmiddlemile:mapmiddlemile,
					maplastmilerso:maplastmilerso,
					maplastmiledealr:maplastmiledealr
				})
			}
			else
			{
				this.setState({
					show1:true,
					basicTitle1:"No data found",
					basicType1:"danger"
				})
			}
		})
		
		
	}

	
	onClickDealerArrival(){
		this.setState({
			sliderDealerArrival:'slider-translate',
			overly:"show-m"
		});
	}
	
	formDealerData(event){
		event.preventDefault();
		var dealer_reached = $("#dealer_reached").val();
		var csvcontent = this.state.csvcontent;
		var dealer_reached = document.getElementById("dealer_reached").value;
		if(dealer_reached != "" && dealer_reached != "__-__-____ __:__")
		{
			var parameters = {
				dealer_reached_on:dealer_reached,
				csvcontent:csvcontent,
				email:localStorage.getItem("email"),
				username:localStorage.getItem("username"),
				userid:localStorage.getItem("userid")
			}
			// console.log("parameters ", parameters)

			redirectURL.post("/mllconsignments/verifymlldealer",{csvcontent:this.state.csvcontent})
			.then(async (response) => {
				console.log("resposnedata ", response.data)
				if(response.data.status == "empty")
				{
					this.setState({
						show:true,
						basicTitle:"No Data found in uploaded file",
						basicType:"danger"
					});
				}
				else if(response.data.status == "notexists")
				{
					this.setState({
						show:true,
						basicTitle:"Invalid Vins",
						basicType:"danger"
					});
				}
				else
				{
					var recs = response.data.records;
					var vehicles = this.state.vehicles;
					if(recs.length > 0)
					{
						// var notacceptvins =await recs.filter(f =>
						// 	!vehicles.some(d => d.vin_no == f.vin_no)
						// );
						// if(notacceptvins.length > 0)
						// {
						// 	this.setState({
						// 		show:true,
						// 		basicTitle:"Uploaded vehicle ids are not exists",
						// 		basicType:"danger"
						// 	});
						// }
						// else
						// {
							redirectURL.post("/mllconsignments/mlldealerupdate", parameters)
							.then((response) => {
								console.log("Respone ", response.data)
								var rec = response.data;
								$("#uploadFile").val("")
								
								$("#dealer_reached").val("");
								this.setState({
									show: true, 
									basicType:'success', 
									basicTitle:"Successfully updated.",
									uploadDivWidth:'0%',
									sliderDealerArrival:'',
									overly:'show-n',
									uploadFile:'',
									loadshow:'show-n',
									file:'',
									csvcontent:""
								});
								
							})
						// }
					}
					else
					{
						this.setState({
							show:true,
							basicTitle:"No Data found in uploaded file",
							basicType:"danger"
						});
					}
				}
				
				
			})
		}
		else
			{
				this.setState({
					show:true,
					basicTitle:"Please select date and time",
					basicType:"danger"
				});
			}
	}


	

	onClickShowETALegs = async(params) =>{

		console.log("Rownode ", params)
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
		var legs = [
			{
				"activity":"First Mile",
				"source" : "Plant",
				"destination" : "Loading Yard",
				"expected_eta" : getDDMMMYYYYHHMMDefault(params.data.loading_expected_eta),
				"revised_eta" : getDDMMMYYYYHHMMDefault(params.data.loading_revised_eta),
			},
			{
				"activity":"Loading",
				"source" : "Loading Yard",
				"destination" : "Loading Yard",
				"expected_eta" : getDDMMMYYYYHHMMDefault(params.data.rake_loading_expected_eta),
				"revised_eta" : getDDMMMYYYYHHMMDefault(params.data.rake_loading_revised_eta),
			},
			{
				"activity":"Middle Mile",
				"source" : "Loading Yard",
				"destination" : "Destination Yard",
				"expected_eta" : getDDMMMYYYYHHMMDefault(params.data.destination_yard_expected_eta),
				"revised_eta" : getDDMMMYYYYHHMMDefault(params.data.destination_yard_revised_eta),
			},
			{
				"activity":"Unloading",
				"source" : "Destination Yard",
				"destination" : "Destination Yard",
				"expected_eta" : getDDMMMYYYYHHMMDefault(params.data.rake_unloading_expected_eta),
				"revised_eta" : getDDMMMYYYYHHMMDefault(params.data.rake_unloading_revised_eta),
			},
			{
				"activity":"Last Mile",
				"source" : "Destination Yard",
				"destination" : "RSO",
				"expected_eta" : getDDMMMYYYYHHMMDefault(params.data.destination_rso_expected_eta),
				"revised_eta" : getDDMMMYYYYHHMMDefault(params.data.destination_rso_revised_eta),
			},
			{
				"activity":"Last Mile",
				"source" : "RSO",
				"destination" : "Dealer",
				"expected_eta" : getDDMMMYYYYHHMMDefault(params.data.rso_dealer_expected_eta),
				"revised_eta" : getDDMMMYYYYHHMMDefault(params.data.rso_dealer_revised_eta),
			},
			{
				"activity":"Last Mile",
				"source" : "Destination Yard",
				"destination" : "Dealer",
				"expected_eta" : getDDMMMYYYYHHMMDefault(params.data.destination_dealer_expected_eta),
				"revised_eta" : getDDMMMYYYYHHMMDefault(params.data.destination_dealer_revised_eta),
			}
		]

		await this.setState({
						
			detailCellRendererParams:{
				suppressRefresh: true,
				detailGridOptions: {
					headerHeight:50,
					columnDefs: [
						{
							headerName:"Activity",
							width:140,
							field:"activity",
							// cellClass:function(params){
							// 	let hourDifference = 0;
							// 	if (params.data.revised_eta && params.data.expected_eta) {
							// 		let revisedDateOnlyMoment  = moment(params.data.revised_eta);
							// 		let expectedDateOnlyMoment  = moment(params.data.expected_eta);
							// 		hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'); 
							// 		//console.log("diff= ", params.data.consignment_code, hourDifference);
							// 	} else {
							// 		//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
							// 	}
		
							// 	if(hourDifference >= 1)
							// 	{
							// 		return 'bgColorDangerMedium'
							// 	}
		
							// 	else if(hourDifference <= -1)
							// 	{
							// 		return 'bgColorSuccessMedium'
							// 	}
							// 	else{
							// 		return 'bgColorSuccessMedium'
							// 	}
							// },
						},	
						{
							headerName:"Source",
							width:140,
							field:"source",
							// cellClass:function(params){
							// 	let hourDifference = 0;
							// 	if (params.data.revised_eta && params.data.expected_eta) {
							// 		let revisedDateOnlyMoment  = moment(params.data.revised_eta);
							// 		let expectedDateOnlyMoment  = moment(params.data.expected_eta);
							// 		hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'); 
							// 		//console.log("diff= ", params.data.consignment_code, hourDifference);
							// 	} else {
							// 		//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
							// 	}
							// 	console.log("hourDifference ", hourDifference)
							// 	if(hourDifference >= 1)
							// 	{
							// 		return 'bgColorDangerMedium'
							// 	}
		
							// 	else if(hourDifference <= -1)
							// 	{
							// 		return 'bgColorSuccessMedium'
							// 	}
							// 	else{
							// 		return 'bgColorSuccessMedium'
							// 	}
							// },
						},
						{
							headerName:"Destination",
							width:140,
							field:"destination",
							// cellClass:function(params){
							// 	let hourDifference = 0;
							// 	if (params.data.revised_eta && params.data.expected_eta) {
							// 		let revisedDateOnlyMoment  = moment(params.data.revised_eta);
							// 		let expectedDateOnlyMoment  = moment(params.data.expected_eta);
							// 		hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'); 
							// 		//console.log("diff= ", params.data.consignment_code, hourDifference);
							// 	} else {
							// 		//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
							// 	}
		
							// 	if(hourDifference >= 1)
							// 	{
							// 		return 'bgColorDangerMedium'
							// 	}
		
							// 	else if(hourDifference <= -1)
							// 	{
							// 		return 'bgColorSuccessMedium'
							// 	}
							// 	else{
							// 		return 'bgColorSuccessMedium'
							// 	}
							// },
						},
						{
							headerName:"Expected ETA",
							width:140,
							field:"expected_eta",
							// cellClass:function(params){
							// 	let hourDifference = 0;
							// 	if (params.data.revised_eta && params.data.expected_eta) {
							// 		let revisedDateOnlyMoment  = moment(params.data.revised_eta);
							// 		let expectedDateOnlyMoment  = moment(params.data.expected_eta);
							// 		hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'); 
							// 		//console.log("diff= ", params.data.consignment_code, hourDifference);
							// 	} else {
							// 		//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
							// 	}
		
							// 	if(hourDifference >= 1)
							// 	{
							// 		return 'bgColorDangerMedium'
							// 	}
		
							// 	else if(hourDifference <= -1)
							// 	{
							// 		return 'bgColorSuccessMedium'
							// 	}
							// 	else{
							// 		return 'bgColorSuccessMedium'
							// 	}
							// },
						},
						{
							headerName:"Revised ETA",
							width:140,
							field:"revised_eta",
							cellClass:function(params){
								let hourDifference = 0;
								if (params.data.revised_eta && params.data.expected_eta) {
									let revisedDateOnlyMoment  = moment(params.data.revised_eta);
									let expectedDateOnlyMoment  = moment(params.data.expected_eta);
									hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'); 
									//console.log("diff= ", params.data.consignment_code, hourDifference);
								} else {
									//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
								}
		
								if(hourDifference >= 1)
								{
									return 'bgColorDangerMedium'
								}
		
								else if(hourDifference <= -1)
								{
									return 'bgColorSuccessMedium'
								}
								else{
									return 'bgColorSuccessMedium'
								}
							},
						},				
					],
					overlayNoRowsTemplate: 'No rows to show',						
				},
				getDetailRowData: function(params) {
					params.successCallback(legs);
					
				}
			}
		});
		
		//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
		if(params.column.colDef.field == 'vehicle_id')
		{
			params.node.setExpanded(!params.node.expanded);
			
		}
		else{

			params.node.setExpanded(false);
		}
		
	}

    render(){
		
		
		var columnwithDefs = [
			// {
			// 	headerName:"",
			// 	field:"",
			// 	width:100,
			// 	resizable:true,
			// 	cellRendererSelector:function(params){
            //         return {component:"editMLLConsignment"}
            //     }
			// },
			{
				headerName:"",
				field:"vehicle_id",
				width:50,
				resizable:true,
				pinned:"left",
				cellRendererSelector:function(params){
					var rendComponent = {
						component: 'showroute'
					};
					return rendComponent
				
				},
			},
			{
				headerName:"",
				field:"vehicle_id",
				width:50,
				resizable:true,
				pinned:"left",
				cellRendererSelector:function(params){
					var rendComponent = {
						component: 'showetalegs'
					};
					return rendComponent
				
				},
			},
			{
				headerName:"Vehicle ID",
				field:"vehicle_id",
				width:150,
				resizable:true,
				pinned:"left"
			},
			{
				headerName:"Master Ref No",
				field:"master_ref_no",
				width:150,
				hide:true,
				resizable:true,
				pinned:"left"
			},
			{
				headerName:"Status",
				field:"status",
				width:150,
				resizable:true,
				pinned:"left",
				valueGetter:function(params){
					// console.log()
					try{
						var status = "At Plant"
						if(parseInt(params.data.status) == 1)
						{
							var status = "At Plant";
						}
						if(parseInt(params.data.status) == 2)
						{
							var status = "In Transit To Loading Yard";
						}
						if(parseInt(params.data.status) == 3)
						{
							var status = "At Loading Yard";
						}
						if(parseInt(params.data.status) == 4)
						{
							var status = "Rake Assigned";
						}
						if(parseInt(params.data.status) == 5)
						{
							var status = "In Transit To Destination Yard";
						}
						if(parseInt(params.data.status) == 6)
						{
							var status = "At Destination Yard";
						}
						if(parseInt(params.data.status) == 7)
						{
							var status = "In Transit To RSO";
						}
						if(parseInt(params.data.status) == 8)
						{
							var status = "At RSO";
						}
						if(parseInt(params.data.status) == 9)
						{
							var status = "In Transit To Dealer";
						}
						if(parseInt(params.data.status) == 10  && (params.data.trip_closed_at_rso == 0 || params.data.trip_closed_at_rso == ""
						|| params.data.trip_closed_at_rso == undefined))
						{
							var status = "Reached Dealer";
						}
						if(parseInt(params.data.status) == 11)
						{
							var status = "POD Receipt";
						}
						
						if(parseInt(params.data.status) == 10 && params.data.trip_closed_at_rso == 1)
						{
							var status = "Trip ended at RSO";
						}
						return status;
					}
					catch(e){

					}
				}
			},




			{
				headerName:"ETA Dealer",
				field:"destination_dealer_expected_eta",
				width:160,
				resizable:true,
				pinned:"left",
				valueGetter:function(params){
					try{
						if(params.data.destination_dealer_expected_eta != "" && params.data.destination_dealer_expected_eta != undefined)
						{
							return getDDMMMYYYYHHMMDefault(params.data.destination_dealer_expected_eta);
						}
						else{
							return "";
						}
					}
					catch(e){
						return "";
					}
				}
			},
			{
				headerName:"Revised ETA Dealer",
				field:"destination_dealer_revised_eta",
				width:160,
				resizable:true,
				pinned:"left",
				// cellClass:["cellstylegrid"],
				cellClass:function(params){
					let hourDifference = 0;
					if (params.data.destination_dealer_revised_eta && params.data.destination_dealer_expected_eta) {
						let revisedDateOnlyMoment  = moment(params.data.destination_dealer_revised_eta);
						let expectedDateOnlyMoment  = moment(params.data.destination_dealer_expected_eta);
						hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'); 
						//console.log("diff= ", params.data.consignment_code, hourDifference);
					} else {
						//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
					}

					if(hourDifference >= 1)
					{
						return 'bgColorDangerMedium'
					}

					if(hourDifference <= -1)
					{
						return 'bgColorSuccessMedium'
					}
					else{
						return ''
					}
				},
				valueGetter:function(params){
					try{
						if(params.data.destination_dealer_revised_eta != "" && params.data.destination_dealer_revised_eta != undefined)
						{
							return getDDMMMYYYYHHMMDefault(params.data.destination_dealer_revised_eta);
						}
						else{
							return "";
						}
					}
					catch(e){
						return "";
					}
				}
			},



			
			{
				headerName: "<i class='icofont icofont-train-line'></i> Plant to Loading Yard",
				headerClass:["cellstylegridG","cellTopHeader"],	
				children:[
					
					
					{
						headerName:"Loading Yard",
						headerClass:["cellstylegridG"],
						field:"loadingyard",
						width:140,
						resizable:true,
						cellClass:["cellstylegridG"]
					},
					{
						headerName:"Mode",
						headerClass:["cellstylegridG"],
						field:"modetype",
						width:140,
						resizable:true,
						cellClass:["cellstylegridG"]
					},
					{
						headerName:"GPS Device ID",
						headerClass:["cellstylegridG"],
						field:"gpsdeviceid",
						width:140,
						resizable:true,
						cellClass:["cellstylegridG"]
					},
					{
						headerName:"TRC No",
						headerClass:["cellstylegridG"],
						field:"first_mile_trc_no",
						width:140,
						resizable:true,
						cellClass:["cellstylegridG"]
					},
					{
						headerName:"Eway Bill No",
						headerClass:["cellstylegridG"],
						field:"ewaybill_no",
						width:140,
						resizable:true,
						cellClass:["cellstylegridG"]
					},
					{
						headerName:"E-Way Bill Date",
						headerClass:["cellstylegridG"],
						field:"ewaybilldate",
						width:160,
						resizable:true,
						valueGetter:function(params){
							try{
								if(params.data.ewaybilldate != undefined && params.data.ewaybilldate != "")
								{
									return getDDMMMYYYYHHMMDefault(params.data.ewaybilldate);
								}
								else
								{
									return "";
								}
							}
							catch(e){
								return "";
							}
						},
						cellClass:["cellstylegridG"]
					},
					{
						headerName:"E-Way Bill Expiry Date",
						headerClass:["cellstylegridG"],
						field:"ewaybill_expiry_date",
						width:160,
						resizable:true,
						valueGetter:function(params){
							try{
								if(params.data.ewaybill_expiry_date != undefined && params.data.ewaybill_expiry_date != "")
								{
									return getDDMMMYYYYHHMMDefault(params.data.ewaybill_expiry_date);
								}
								else
								{
									return "";
								}
							}
							catch(e){
								return "";
							}
						},
						cellClass:["cellstylegridG"]
					},
					
					{
						headerName:"Driver Name",
						headerClass:["cellstylegridG"],
						field:"driver_name",
						width:140,
						resizable:true,
						cellClass:["cellstylegridG"]
					},
					
					{
						headerName:"Driver Mobile",
						headerClass:["cellstylegridG"],
						field:"driver_mobile",
						width:140,
						resizable:true,
						cellClass:["cellstylegridG"]
					},
					{
						headerName:"Batch No",
						headerClass:["cellstylegridG"],
						field:"batchno",
						width:150,
						resizable:true,
						cellClass:["cellstylegridG"]
					},
					{
						headerName:"Loading Yard Reached On",
						headerClass:["cellstylegridG"],
						field:"loading_yard_reached_on",
						width:160,
						resizable:true,
						valueGetter:function(params){
							try{
								if(params.data.loading_yard_reached_on != undefined && params.data.loading_yard_reached_on != "")
								{
									return getDDMMMYYYYHHMMDefault(params.data.loading_yard_reached_on);
								}
								else
								{
									return "";
								}
							}
							catch(e){
								return "";
							}
						},
						cellClass:["cellstylegridG"]
					},
					{
						headerName:"Expected ETA",
						headerClass:["cellstylegridG"],
						field:"loading_expected_eta",
						width:160,
						resizable:true,
						cellClass:["cellstylegridG"],
						valueGetter:function(params){
							try{
								if(params.data.loading_expected_eta != "" && params.data.loading_expected_eta != undefined)
								{
									return getDDMMMYYYYHHMMDefault(params.data.loading_expected_eta);
								}
								else{
									return "";
								}
							}
							catch(e){
								return "";
							}
						}
					},
					{
						headerName:"Revised ETA",
						headerClass:["cellstylegridG"],
						field:"loading_revised_eta",
						width:160,
						resizable:true,
						cellClass:function(params){
							let hourDifference = 0;
							if (params.data.loading_revised_eta && params.data.loading_expected_eta) {
								let revisedDateOnlyMoment  = moment(params.data.loading_revised_eta);
								let expectedDateOnlyMoment  = moment(params.data.loading_expected_eta);
								hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'); 
								//console.log("diff= ", params.data.consignment_code, hourDifference);
							} else {
								//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
							}
	
							if(hourDifference >= 1)
							{
								return 'bgColorDangerMedium'
							}
	
							if(hourDifference <= -1)
							{
								return 'bgColorSuccessMedium'
							}
							else{
								return 'cellstylegridG'
							}
						},
						valueGetter:function(params){
							try{
								if(params.data.loading_revised_eta != "" && params.data.loading_revised_eta != undefined)
								{
									return getDDMMMYYYYHHMMDefault(params.data.loading_revised_eta);
								}
								else{
									return "";
								}
							}
							catch(e){
								return "";
							}
						}
					},
					// {
					// 	headerName:"Rake Expected ETA",
					// 	headerClass:["cellstylegridG"],
					// 	field:"rake_loading_expected_eta",
					// 	width:160,
					// 	resizable:true,
					// 	cellClass:["cellstylegridG"],
					// 	valueGetter:function(params){
					// 		try{
					// 			if(params.data.rake_loading_expected_eta != "" && params.data.rake_loading_expected_eta != undefined)
					// 			{
					// 				return getDDMMMYYYYHHMMDefault(params.data.rake_loading_expected_eta);
					// 			}
					// 			else{
					// 				return "";
					// 			}
					// 		}
					// 		catch(e){
					// 			return "";
					// 		}
					// 	}
					// },
					// {
					// 	headerName:"Rake Revised ETA",
					// 	headerClass:["cellstylegridG"],
					// 	field:"rake_loading_revised_eta",
					// 	width:160,
					// 	resizable:true,
					// 	cellClass:function(params){
					// 		let hourDifference = 0;
					// 		if (params.data.rake_loading_revised_eta && params.data.rake_loading_expected_eta) {
					// 			let revisedDateOnlyMoment  = moment(params.data.rake_loading_revised_eta);
					// 			let expectedDateOnlyMoment  = moment(params.data.rake_loading_expected_eta);
					// 			hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'); 
					// 			//console.log("diff= ", params.data.consignment_code, hourDifference);
					// 		} else {
					// 			//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
					// 		}
	
					// 		if(hourDifference >= 1)
					// 		{
					// 			return 'bgColorDangerMedium'
					// 		}
	
					// 		if(hourDifference <= -1)
					// 		{
					// 			return 'bgColorSuccessMedium'
					// 		}
					// 		else{
					// 			return 'cellstylegridG'
					// 		}
					// 	},
					// 	valueGetter:function(params){
					// 		try{
					// 			if(params.data.rake_loading_revised_eta != "" && params.data.rake_loading_revised_eta != undefined)
					// 			{
					// 				return getDDMMMYYYYHHMMDefault(params.data.rake_loading_revised_eta);
					// 			}
					// 			else{
					// 				return "";
					// 			}
					// 		}
					// 		catch(e){
					// 			return "";
					// 		}
					// 	}
					// },
					
					{
						headerName:"Trailer No ",
						headerClass:["cellstylegridG"],
						field:"trailer_no",
						width:140,
						filter:"agSetColumnFilter",
						resizable: true, 
						valueGetter:function(params){
							if(params.data.trailer_no != '' && typeof params.data.trailer_no != 'undefined')
							{
								return params.data.trailer_no;
							} 
							else{
								return '';
							}
						},
						cellClass:["cellstylegridG"]
					
					},
				
					{
						headerName:"Old Trailer No ",
						headerClass:["cellstylegridG"],
						field:"old_truck_no",
						width:140,
						filter:"agSetColumnFilter",
						resizable: true, 
						valueGetter:function(params){
							if(params.data.old_truck_no != '' && typeof params.data.old_truck_no != 'undefined')
							{
								return params.data.old_truck_no;
							} 
							else{
								return '';
							}
						},
						cellClass:["cellstylegridG"]
					
					},
					{
						headerName:"Is Transshipment ",
						headerClass:["cellstylegridG"],
						field:"is_transshipment",
						width:140,
						filter:"agSetColumnFilter",
						resizable: true, 
						valueGetter:function(params){
							if(params.data.is_transshipment != '' && typeof params.data.is_transshipment != 'undefined')
							{
								if(params.data.is_transshipment == 1){
									return "Yes"
								}
								else{
									return "No";
								}
							} 
							else{
								return '';
							}
						},
						cellClass:["cellstylegridG"]
					
					},
				]
			},
			{
				headerName: "<i class='icofont icofont-train-line'></i> Loading Yard to Destination Yard",
				headerClass:["cellstylegridSk","cellTopHeader"],	
				children:[
					
					{
						headerName:"Loading Expected ETA",
						headerClass:["cellstylegridSk"],
						field:"rake_loading_expected_eta",
						width:160,
						resizable:true,
						cellClass:["cellstylegridSk"],
						valueGetter:function(params){
							try{
								if(params.data.rake_loading_expected_eta != "" && params.data.rake_loading_expected_eta != undefined)
								{
									return getDDMMMYYYYHHMMDefault(params.data.rake_loading_expected_eta);
								}
								else{
									return "";
								}
							}
							catch(e){
								return "";
							}
						}
					},
					{
						headerName:"Loading Revised ETA",
						headerClass:["cellstylegridSk"],
						field:"rake_loading_revised_eta",
						width:160,
						resizable:true,
						cellClass:function(params){
							let hourDifference = 0;
							if (params.data.rake_loading_revised_eta && params.data.rake_loading_expected_eta) {
								let revisedDateOnlyMoment  = moment(params.data.rake_loading_revised_eta);
								let expectedDateOnlyMoment  = moment(params.data.rake_loading_expected_eta);
								hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'); 
								//console.log("diff= ", params.data.consignment_code, hourDifference);
							} else {
								//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
							}
	
							if(hourDifference >= 1)
							{
								return 'bgColorDangerMedium'
							}
	
							if(hourDifference <= -1)
							{
								return 'bgColorSuccessMedium'
							}
							else{
								return 'cellstylegridSk'
							}
						},
						valueGetter:function(params){
							try{
								if(params.data.rake_loading_revised_eta != "" && params.data.rake_loading_revised_eta != undefined)
								{
									return getDDMMMYYYYHHMMDefault(params.data.rake_loading_revised_eta);
								}
								else{
									return "";
								}
							}
							catch(e){
								return "";
							}
						}
					},
					{
						headerName:"Loading Yard",
						headerClass:["cellstylegridSk"],
						field:"rake_loading_yard_name",
						width:160,
						resizable:true,
						cellClass:["cellstylegridSk"]
					},
					{
						headerName:"FNR No",
						headerClass:["cellstylegridSk"],
						field:"fnr_no",
						width:120,
						resizable:true,
						cellClass:["cellstylegridSk"]
					},
					{
						headerName:"GPS Device Id",
						headerClass:["cellstylegridSk"],
						field:"rake_gps_device_id",
						width:120,
						resizable:true,
						cellClass:["cellstylegridSk"],
						valueGetter:function(params)
						{
							try{
								var devc = params.data.rake_gps_device_id;
								if(params.data.rake_gps_device_id_2 != "" && params.data.rake_gps_device_id_2 != undefined)
								{
									devc = devc+","+params.data.rake_gps_device_id_2;
								}
								else{
									devc = devc;
								}
								return devc;
							}
							catch(e){
								return "";
							}
						}
					},
					{
						headerName:"Destination Yard",
						headerClass:["cellstylegridSk"],
						field:"rake_destination_point_name",
						width:120,
						resizable:true,
						cellClass:["cellstylegridSk"]
					},
					{
						headerName:"Destination Expected ETA",
						headerClass:["cellstylegridSk"],
						field:"destination_yard_expected_eta",
						width:160,
						resizable:true,
						cellClass:["cellstylegridSk"],
						
						valueGetter:function(params){
							try{
								if(params.data.destination_yard_expected_eta != "" && params.data.destination_yard_expected_eta != undefined)
								{
									return getDDMMMYYYYHHMMDefault(params.data.destination_yard_expected_eta);
								}
								else{
									return "";
								}
							}
							catch(e){
								return "";
							}
						}
					},
					{
						headerName:"Destination Revised ETA",
						headerClass:["cellstylegridSk"],
						field:"destination_yard_revised_eta",
						width:160,
						resizable:true,
						// cellClass:["cellstylegridSk"]
						cellClass:function(params){
							let hourDifference = 0;
							if (params.data.destination_yard_revised_eta && params.data.destination_yard_expected_eta) {
								let revisedDateOnlyMoment  = moment(params.data.destination_yard_revised_eta);
								let expectedDateOnlyMoment  = moment(params.data.destination_yard_expected_eta);
								hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'); 
								//console.log("diff= ", params.data.consignment_code, hourDifference);
							} else {
								//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
							}
	
							if(hourDifference >= 1)
							{
								return 'bgColorDangerMedium'
							}
	
							if(hourDifference <= -1)
							{
								return 'bgColorSuccessMedium'
							}
							else{
								return 'cellstylegridSk'
							}
						},
						valueGetter:function(params){
							try{
								if(params.data.destination_yard_revised_eta != "" && params.data.destination_yard_revised_eta != undefined)
								{
									return getDDMMMYYYYHHMMDefault(params.data.destination_yard_revised_eta);
								}
								else{
									return "";
								}
							}
							catch(e){
								return "";
							}
						}
					},
					{
						headerName:"Load Dispatch Date/Time",
						headerClass:["cellstylegridSk"],
						field:"expected_rake_dispatch_date",
						width:160,
						resizable:true,
						valueGetter:function(params){
							try{
								if(params.data.expected_rake_dispatch_date != "" && params.data.expected_rake_dispatch_date != undefined)
								{
									return getHyphenDDMMMYYYYHHMM(params.data.expected_rake_dispatch_date);
								}
								else
								{
									return "";
								}
							}
							catch(e){
								return "";
							}
						},
						cellClass:["cellstylegridSk"]
					},
				
					{
						headerName:"Rake Unloading Expected ETA",
						headerClass:["cellstylegridSk"],
						field:"rake_unloading_expected_eta",
						width:160,
						resizable:true,
						cellClass:["cellstylegridSk"],
						
						valueGetter:function(params){
							try{
								if(params.data.rake_unloading_expected_eta != "" && params.data.rake_unloading_expected_eta != undefined)
								{
									return getDDMMMYYYYHHMMDefault(params.data.rake_unloading_expected_eta);
								}
								else{
									return "";
								}
							}
							catch(e){
								return "";
							}
						}
					},
					{
						headerName:"Rake Unloading Revised ETA",
						headerClass:["cellstylegridSk"],
						field:"rake_unloading_revised_eta",
						width:160,
						resizable:true,
						// cellClass:["cellstylegridSk"],
						cellClass:function(params){
							let hourDifference = 0;
							if (params.data.rake_unloading_revised_eta && params.data.rake_unloading_expected_eta) {
								let revisedDateOnlyMoment  = moment(params.data.rake_unloading_revised_eta);
								let expectedDateOnlyMoment  = moment(params.data.rake_unloading_expected_eta);
								hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'); 
								//console.log("diff= ", params.data.consignment_code, hourDifference);
							} else {
								//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
							}
	
							if(hourDifference >= 1)
							{
								return 'bgColorDangerMedium'
							}
	
							if(hourDifference <= -1)
							{
								return 'bgColorSuccessMedium'
							}
							else{
								return 'cellstylegridSk'
							}
						},
						valueGetter:function(params){
							try{
								if(params.data.rake_unloading_revised_eta != "" && params.data.rake_unloading_revised_eta != undefined)
								{
									return getDDMMMYYYYHHMMDefault(params.data.rake_unloading_revised_eta);
								}
								else{
									return "";
								}
							}
							catch(e){
								return "";
							}
						}
					},
					{
						headerName:"Destination Yard Reached On ",
						headerClass:["cellstylegridSk"],
						field:"rake_delivery_point_reached_on",
						width:140,
						filter:"agSetColumnFilter",
						resizable: true, 
						valueGetter:function(params){
							if(params.data.rake_delivery_point_reached_on != '' && typeof params.data.rake_delivery_point_reached_on != 'undefined')
							{
								return getHyphenDDMMMYYYYHHMM(params.data.rake_delivery_point_reached_on);
							} 
							else{
								return '';
							}
						},
						cellClass:["cellstylegridSk"]
						
					},
				
					

				]
			},


			{
				headerName: "<i class='icofont icofont-train-line'></i> Destination Yard to RSO",
				headerClass:["cellstylegridM","cellTopHeader"],	
				children:[	
					
					
					{
						headerName:"RSO Arrival On ",
						headerClass:["cellstylegridM"],
						field:"rso_reached_on",
						width:220,
						filter:"agSetColumnFilter",
						resizable: true, 
						valueGetter:function(params){
							try{
								if(params.data.rso_reached_on != '' && typeof params.data.rso_reached_on != 'undefined')
								{
									return getHyphenDDMMMYYYYHHMM(params.data.rso_reached_on);
								} 
								else{
									return '';
								}
							}
							catch(e){
								return '';
							}
						},
						cellClass:["cellstylegridM"]
						
					},
					{
						headerName:"Mode ",
						headerClass:["cellstylegridM"],
						field:"last_mile_mode_type",
						width:120,
						filter:"agSetColumnFilter",
						resizable: true, 
						valueGetter:function(params){
							try{
								if(params.data.last_mile_mode_type != '' && typeof params.data.last_mile_mode_type != 'undefined')
								{
									return params.data.last_mile_mode_type;
								} 
								else{
									return '';
								}
							}
							catch(e){
								return '';
							}

							
						},
						cellClass:["cellstylegridM"]
						
					},
					{
						headerName:"GPS Device ID ",
						headerClass:["cellstylegridM"],
						field:"last_mile_gps_device_id",
						width:140,
						filter:"agSetColumnFilter",
						resizable: true, 
						valueGetter:function(params){
							try{
								if(params.data.last_mile_gps_device_id != '' && typeof params.data.last_mile_gps_device_id != 'undefined')
								{
									return params.data.last_mile_gps_device_id;
								} 
								else{
									return '';
								}
							}
							catch(e){
								return '';
							}

							
						},
						cellClass:["cellstylegridM"]
						
					},
					{
						headerName:"TRC No ",
						headerClass:["cellstylegridM"],
						field:"last_mile_destination_rso_trcno",
						width:140,
						filter:"agSetColumnFilter",
						resizable: true, 
						valueGetter:function(params){
							try{
								if(params.data.last_mile_destination_rso_trcno != '' && typeof params.data.last_mile_destination_rso_trcno != 'undefined')
								{
									return params.data.last_mile_destination_rso_trcno;
								} 
								else{
									return '';
								}
							}
							catch(e){
								return '';
							}

							
						},
						cellClass:["cellstylegridM"]
						
					},
					{
						headerName:"RSO Update By ",
						headerClass:["cellstylegridM"],
						field:"rso_update_username",
						width:160,
						filter:"agSetColumnFilter",
						resizable: true, 
						valueGetter:function(params){
							try{
								if(params.data.rso_update_username != '' && typeof params.data.rso_update_username != 'undefined')
								{
									return params.data.rso_update_username;
								} 
								else{
									return '';
								}
							}
							catch(e){
								return '';
							}

							
						},
						cellClass:["cellstylegridM"]
						
					},
					{
						headerName:"Expected ETA",
						headerClass:["cellstylegridM"],
						field:"destination_rso_expected_eta",
						width:160,
						resizable:true,
						cellClass:["cellstylegridM"],
						valueGetter:function(params){
							try{
								if(params.data.destination_rso_expected_eta != "" && params.data.destination_rso_expected_eta != undefined)
								{
									return getDDMMMYYYYHHMMDefault(params.data.destination_rso_expected_eta);
								}
								else{
									return "";
								}
							}
							catch(e){
								return "";
							}
						}
					},
					{
						headerName:"Revised ETA",
						headerClass:["cellstylegridM"],
						field:"destination_rso_revised_eta",
						width:160,
						resizable:true,
						// cellClass:["cellstylegridM"],
						valueGetter:function(params){
							try{
								if(params.data.destination_rso_revised_eta != "" && params.data.destination_rso_revised_eta != undefined)
								{
									return getDDMMMYYYYHHMMDefault(params.data.destination_rso_revised_eta);
								}
								else{
									return "";
								}
							}
							catch(e){
								return "";
							}
						},
						cellClass:function(params){
							let hourDifference = 0;
							if (params.data.destination_rso_revised_eta && params.data.destination_rso_expected_eta) {
								let revisedDateOnlyMoment  = moment(params.data.destination_rso_revised_eta);
								let expectedDateOnlyMoment  = moment(params.data.destination_rso_expected_eta);
								hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'); 
								//console.log("diff= ", params.data.consignment_code, hourDifference);
							} else {
								//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
							}
	
							if(hourDifference >= 1)
							{
								return 'bgColorDangerMedium'
							}
	
							if(hourDifference <= -1)
							{
								return 'bgColorSuccessMedium'
							}
							else{
								return 'cellstylegridM'
							}
						},
					},
				]
			},
			
			
			{
				headerName: "<i class='icofont icofont-train-line'></i> RSO to Dealer",
				headerClass:["cellstylegridB","cellTopHeader"],	
				children:[	
					
					
					{
						headerName:"Mode ",
						headerClass:["cellstylegridB"],
						field:"last_mile_dealer_mode_type",
						width:140,
						filter:"agSetColumnFilter",
						resizable: true, 
						valueGetter:function(params){
							if(params.data.last_mile_dealer_mode_type != '' && typeof params.data.last_mile_dealer_mode_type != 'undefined')
							{
								return params.data.last_mile_dealer_mode_type;
							} 
							else{
								return '';
							}
						},
						cellClass:["cellstylegridB"]
						
					},
					{
						headerName:"GPS DEVICE ID",
						headerClass:["cellstylegridB"],
						field:"last_mile_dealer_gps_device_id",
						width:140,
						resizable:true,
						valueGetter:function(params){
							try{
								if(params.data.last_mile_dealer_gps_device_id != "" && params.data.last_mile_dealer_gps_device_id != undefined)
								{
									return params.data.last_mile_dealer_gps_device_id;
								}
								else{
									return "";
								}
							}
							catch(e)
							{
								return "";
							}
						},
						cellClass:["cellstylegridB"]
					},
					{
						headerName:"TRC NO",
						headerClass:["cellstylegridB"],
						field:"last_mile_destination_dealer_trcno",
						width:140,
						resizable:true,
						valueGetter:function(params){
							try{
								if(parseInt(params.data.status) == 7)
								{
									if(params.data.last_mile_destination_rso_trcno != "" && params.data.last_mile_destination_rso_trcno != undefined)
									{
										return params.data.last_mile_destination_rso_trcno;
									}
									else{
										return "";
									}
								}
								else{
									if(params.data.last_mile_destination_dealer_trcno != "" && params.data.last_mile_destination_dealer_trcno != undefined)
									{
										return params.data.last_mile_destination_dealer_trcno;
									}
									else{
										if(params.data.last_mile_rso_dealer_trcno != "" && params.data.last_mile_rso_dealer_trcno != undefined)
										{
											return params.data.last_mile_rso_dealer_trcno;
										}
										else{
											return "";
										}
									}
								}
								
							}
							catch(e)
							{
								return "";
							}
						},
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Trailer No ",
						headerClass:["cellstylegridB"],
						field:"last_mile_dealer_trailer_no",
						width:140,
						filter:"agSetColumnFilter",
						resizable: true, 
						valueGetter:function(params){
							if(params.data.last_mile_dealer_trailer_no != '' && typeof params.data.last_mile_dealer_trailer_no != 'undefined')
							{
								return params.data.last_mile_dealer_trailer_no;
							} 
							else{
								return '';
							}
						},
						cellClass:["cellstylegridB"]
						
					},

					{
						headerName:"Dealer Code",
						headerClass:["cellstylegridB"],
						field:"last_mile_destination_dealer_code",
						width:120,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					{
						headerName:"Dealer Name",
						headerClass:["cellstylegridB"],
						field:"last_mile_destination_dealer_name",
						width:160,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Last Mile Dispatch Date ",
						headerClass:["cellstylegridB"],
						field:"last_mile_dispatch_date",
						width:140,
						filter:"agSetColumnFilter",
						resizable: true, 
						valueGetter:function(params){
							if(params.data.last_mile_dispatch_date != '' && typeof params.data.last_mile_dispatch_date != 'undefined')
							{
								return getHyphenDDMMMYYYYHHMM(params.data.last_mile_dispatch_date);
							} 
							else{
								return '';
							}
						},
						cellClass:["cellstylegridB"]
						
					},

					{
						headerName:"Expected ETA",
						headerClass:["cellstylegridB"],
						field:"rso_dealer_expected_eta",
						width:160,
						resizable:true,
						cellClass:["cellstylegridB"],
						valueGetter:function(params){
							try{
								if(params.data.rso_dealer_expected_eta != "" && params.data.rso_dealer_expected_eta != undefined)
								{
									return getDDMMMYYYYHHMMDefault(params.data.rso_dealer_expected_eta);
								}
								else{
									return "";
								}
							}
							catch(e){
								return "";
							}
						},
					},
					{
						headerName:"Revised ETA",
						headerClass:["cellstylegridB"],
						field:"rso_dealer_revised_eta",
						width:160,
						resizable:true,
						cellClass:["cellstylegridB"],
						valueGetter:function(params){
							try{
								if(params.data.rso_dealer_revised_eta != "" && params.data.rso_dealer_revised_eta != undefined)
								{
									return getDDMMMYYYYHHMMDefault(params.data.rso_dealer_revised_eta);
								}
								else{
									return "";
								}
							}
							catch(e){
								return "";
							}
						},
						
						cellClass:function(params){
							let hourDifference = 0;
							if (params.data.rso_dealer_revised_eta && params.data.rso_dealer_expected_eta) {
								let revisedDateOnlyMoment  = moment(params.data.rso_dealer_revised_eta);
								let expectedDateOnlyMoment  = moment(params.data.rso_dealer_expected_eta);
								hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'); 
								//console.log("diff= ", params.data.consignment_code, hourDifference);
							} else {
								//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
							}
	
							if(hourDifference >= 1)
							{
								return 'bgColorDangerMedium'
							}
	
							if(hourDifference <= -1)
							{
								return 'bgColorSuccessMedium'
							}
							else{
								return 'cellstylegridB'
							}
						},
					},
				]
			},
			
			{
				headerName: "<i class='icofont icofont-train-line'></i> Destination Yard to Dealer",
				headerClass:["cellstylegrid","cellTopHeader"],	
				children:[	
							
					{
						headerName:"Mode ",
						headerClass:["cellstylegrid"],
						field:"last_mile_dealer_mode_type",
						width:140,
						filter:"agSetColumnFilter",
						resizable: true, 
						valueGetter:function(params){
							if(params.data.last_mile_dealer_mode_type != '' && typeof params.data.last_mile_dealer_mode_type != 'undefined')
							{
								return params.data.last_mile_dealer_mode_type;
							} 
							else{
								return '';
							}
						},
						cellClass:["cellstylegrid"]
						
					},
					{
						headerName:"GPS DEVICE ID",
						headerClass:["cellstylegrid"],
						field:"last_mile_dealer_gps_device_id",
						width:140,
						resizable:true,
						valueGetter:function(params){
							try{
								if(params.data.last_mile_dealer_gps_device_id != "" && params.data.last_mile_dealer_gps_device_id != undefined)
								{
									return params.data.last_mile_dealer_gps_device_id;
								}
								else{
									return "";
								}
							}
							catch(e)
							{
								return "";
							}
						},
						cellClass:["cellstylegrid"]
					},
					{
						headerName:"TRC NO",
						headerClass:["cellstylegrid"],
						field:"last_mile_destination_dealer_trcno",
						width:140,
						resizable:true,
						valueGetter:function(params){
							try{
								if(parseInt(params.data.status) == 7)
								{
									if(params.data.last_mile_destination_rso_trcno != "" && params.data.last_mile_destination_rso_trcno != undefined)
									{
										return params.data.last_mile_destination_rso_trcno;
									}
									else{
										return "";
									}
								}
								else{
									if(params.data.last_mile_destination_dealer_trcno != "" && params.data.last_mile_destination_dealer_trcno != undefined)
									{
										return params.data.last_mile_destination_dealer_trcno;
									}
									else{
										if(params.data.last_mile_rso_dealer_trcno != "" && params.data.last_mile_rso_dealer_trcno != undefined)
										{
											return params.data.last_mile_rso_dealer_trcno;
										}
										else{
											return "";
										}
									}
								}
								
							}
							catch(e)
							{
								return "";
							}
						},
						cellClass:["cellstylegrid"]
					},
					
					{
						headerName:"Trailer No ",
						headerClass:["cellstylegrid"],
						field:"last_mile_dealer_trailer_no",
						width:140,
						filter:"agSetColumnFilter",
						resizable: true, 
						valueGetter:function(params){
							if(params.data.last_mile_dealer_trailer_no != '' && typeof params.data.last_mile_dealer_trailer_no != 'undefined')
							{
								return params.data.last_mile_dealer_trailer_no;
							} 
							else{
								return '';
							}
						},
						cellClass:["cellstylegrid"]
						
					},
					{
						headerName:"Dealer Code",
						headerClass:["cellstylegrid"],
						field:"last_mile_destination_dealer_code",
						width:120,
						resizable:true,
						cellClass:["cellstylegrid"]
					},
					{
						headerName:"Dealer Name",
						headerClass:["cellstylegrid"],
						field:"last_mile_destination_dealer_name",
						width:160,
						resizable:true,
						cellClass:["cellstylegrid"]
					},
					// {
					// 	headerName:"Last Mile Updated On ",
					// 	headerClass:["cellstylegrid"],
					// 	field:"last_mile_updated_on",
					// 	width:140,
					// 	filter:"agSetColumnFilter",
					// 	resizable: true, 
					// 	valueGetter:function(params){
					// 		if(params.data.last_mile_updated_on != '' && typeof params.data.last_mile_updated_on != 'undefined')
					// 		{
					// 			return getHyphenDDMMMYYYYHHMM(params.data.last_mile_updated_on);
					// 		} 
					// 		else{
					// 			return '';
					// 		}
					// 	},
					// 	cellClass:["cellstylegrid"]
						
					// },
					{
						headerName:"Trailer No ",
						headerClass:["cellstylegrid"],
						field:"last_mile_truck_no",
						width:140,
						filter:"agSetColumnFilter",
						resizable: true, 
						valueGetter:function(params){
							if(params.data.last_mile_truck_no != '' && typeof params.data.last_mile_truck_no != 'undefined')
							{
								return params.data.last_mile_truck_no;
							} 
							else{
								return '';
							}
						},
						cellClass:["cellstylegrid"]
						
					},
					
					{
						headerName:"Last Mile Dispatch Date ",
						headerClass:["cellstylegrid"],
						field:"last_mile_dispatch_date",
						width:140,
						filter:"agSetColumnFilter",
						resizable: true, 
						valueGetter:function(params){
							if(params.data.last_mile_dispatch_date != '' && typeof params.data.last_mile_dispatch_date != 'undefined')
							{
								return getHyphenDDMMMYYYYHHMM(params.data.last_mile_dispatch_date);
							} 
							else{
								return '';
							}
						},
						cellClass:["cellstylegrid"]
						
					},
					{
						headerName:"Expected ETA",
						headerClass:["cellstylegrid"],
						field:"destination_dealer_expected_eta",
						width:160,
						resizable:true,
						cellClass:["cellstylegrid"],
						valueGetter:function(params){
							try{
								if(params.data.destination_dealer_expected_eta != "" && params.data.destination_dealer_expected_eta != undefined)
								{
									return getDDMMMYYYYHHMMDefault(params.data.destination_dealer_expected_eta);
								}
								else{
									return "";
								}
							}
							catch(e){
								return "";
							}
						}
					},
					{
						headerName:"Revised ETA",
						headerClass:["cellstylegrid"],
						field:"destination_dealer_revised_eta",
						width:160,
						resizable:true,
						// cellClass:["cellstylegrid"],
						cellClass:function(params){
							let hourDifference = 0;
							if (params.data.destination_dealer_revised_eta && params.data.destination_dealer_expected_eta) {
								let revisedDateOnlyMoment  = moment(params.data.destination_dealer_revised_eta);
								let expectedDateOnlyMoment  = moment(params.data.destination_dealer_expected_eta);
								hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'); 
								//console.log("diff= ", params.data.consignment_code, hourDifference);
							} else {
								//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
							}
	
							if(hourDifference >= 1)
							{
								return 'bgColorDangerMedium'
							}
	
							if(hourDifference <= -1)
							{
								return 'bgColorSuccessMedium'
							}
							else{
								return 'cellstylegrid'
							}
						},
						valueGetter:function(params){
							try{
								if(params.data.destination_dealer_revised_eta != "" && params.data.destination_dealer_revised_eta != undefined)
								{
									return getDDMMMYYYYHHMMDefault(params.data.destination_dealer_revised_eta);
								}
								else{
									return "";
								}
							}
							catch(e){
								return "";
							}
						}
					},
				]
			},

			{
				headerName: "<i class='icofont icofont-train-line'></i> Invoice Data",
				headerClass:["cellstylegridB","cellTopHeader"],	
				children:[
					{
						headerName:"Source",
						headerClass:["cellstylegridB"],
						field:"source_name",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					{
						headerName:"Bill Doc",
						headerClass:["cellstylegridB"],
						field:"bill_doc",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Bill",
						headerClass:["cellstylegridB"],
						field:"bill_doc",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Group Code",
						headerClass:["cellstylegridB"],
						field:"group_code",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Subgroup",
						headerClass:["cellstylegridB"],
						field:"subgroup",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Matrial No",
						headerClass:["cellstylegridB"],
						field:"material_no",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Material Description",
						headerClass:["cellstylegridB"],
						field:"material_description",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Plant Code",
						headerClass:["cellstylegridB"],
						field:"plant_code",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Storage Location",
						headerClass:["cellstylegridB"],
						field:"storage_location",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Busniess Place",
						headerClass:["cellstylegridB"],
						field:"business_place",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Item (SD)",
						headerClass:["cellstylegridB"],
						field:"item_sd",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Bill To Party",
						headerClass:["cellstylegridB"],
						field:"bill_to_party",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Bill To Party Name",
						headerClass:["cellstylegridB"],
						field:"bill_to_party_name",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Address1",
						headerClass:["cellstylegridB"],
						field:"address1",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Address2",
						headerClass:["cellstylegridB"],
						field:"address2",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Address3",
						headerClass:["cellstylegridB"],
						field:"address3",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"City",
						headerClass:["cellstylegridB"],
						field:"city",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Pincode",
						headerClass:["cellstylegridB"],
						field:"pincode",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Contact Person",
						headerClass:["cellstylegridB"],
						field:"contact_person",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Contact Number",
						headerClass:["cellstylegridB"],
						field:"contact_number",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Ship To Party",
						headerClass:["cellstylegridB"],
						field:"ship_to_party",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Ref Doc",
						headerClass:["cellstylegridB"],
						field:"ref_doc",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Veh SR No",
						headerClass:["cellstylegridB"],
						field:"vehicle_no",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"System Status",
						headerClass:["cellstylegridB"],
						field:"system_status",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"User Status",
						headerClass:["cellstylegridB"],
						field:"user_status",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Colour",
						headerClass:["cellstylegridB"],
						field:"colour",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"TC",
						headerClass:["cellstylegridB"],
						field:"tc",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"TRANS DESP",
						headerClass:["cellstylegridB"],
						field:"trans_desp",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					
					{
						headerName:"Engine No",
						headerClass:["cellstylegridB"],
						field:"enigne_no",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Qty",
						headerClass:["cellstylegridB"],
						field:"qty",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"STA Date",
						headerClass:["cellstylegridB"],
						field:"sta_date",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Due Date",
						headerClass:["cellstylegridB"],
						field:"duedate",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"BLG Time",
						headerClass:["cellstylegridB"],
						field:"blg_time",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"],
						valueGetter:function(params){
							try{
								if(params.data.blg_time != "" && params.data.blg_time != undefined)
								{
									
									var realTime = params.data.blg_time;
									var duration = moment.duration({hours: 5, minutes: 30})
									var sub = moment(realTime, 'HH:mm:ss').subtract(duration).format();
									return moment.parseZone(sub).format("HH:mm:ss");
								
								}
								else{
									return "";
								}
							}
							catch(e){
								return "";
							}
						}
					},
					
					{
						headerName:"Lead Date",
						headerClass:["cellstylegridB"],
						field:"lead_date",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"GRDate",
						headerClass:["cellstylegridB"],
						field:"grdate",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Bill Date",
						headerClass:["cellstylegridB"],
						field:"bill_date",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Trans Mode",
						headerClass:["cellstylegridB"],
						field:"trans_mode",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Parking Time",
						headerClass:["cellstylegridB"],
						field:"parking_time",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"],
						valueGetter:function(params){
							try{
								if(params.data.parking_time != "" && params.data.parking_time != undefined)
								{
									
									var realTime = params.data.parking_time;
									var duration = moment.duration({hours: 5, minutes: 30})
									var sub = moment(realTime, 'HH:mm:ss').subtract(duration).format();
									return moment.parseZone(sub).format("HH:mm:ss");
								
								}
								else{
									return "";
								}
							}
							catch(e){
								return "";
							}
						}
					},
					
					{
						headerName:"INV Value",
						headerClass:["cellstylegridB"],
						field:"invoice_value",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Stock Type (Primary Posting)",
						headerClass:["cellstylegridB"],
						field:"stock_type",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Indentor",
						headerClass:["cellstylegridB"],
						field:"indentor",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Temp Reg. No",
						headerClass:["cellstylegridB"],
						field:"temp_reg_no",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					
					{
						headerName:"Const MTH",
						headerClass:["cellstylegridB"],
						field:"const_mth",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					{
						headerName:"Const YR",
						headerClass:["cellstylegridB"],
						field:"const_yr",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					{
						headerName:"GST INV No",
						headerClass:["cellstylegridB"],
						field:"gst_invoice_no",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					{
						headerName:"CGST",
						headerClass:["cellstylegridB"],
						field:"cgst",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					{
						headerName:"IGST",
						headerClass:["cellstylegridB"],
						field:"igst",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					{
						headerName:"SGST_UGST",
						headerClass:["cellstylegridB"],
						field:"sgst_ugst",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					{
						headerName:"Comp CESS",
						headerClass:["cellstylegridB"],
						field:"comp_cess",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					{
						headerName:"Dist CHNL",
						headerClass:["cellstylegridB"],
						field:"dist_chnl",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					{
						headerName:"Uploaded By",
						headerClass:["cellstylegridB"],
						field:"uploaded_by",
						width:150,
						resizable:true,
						cellClass:["cellstylegridB"]
					},
					{
						headerName:"Uploaded On",
						headerClass:["cellstylegridB"],
						field:"uploaded_on",
						width:150,
						resizable:true,
						valueGetter:function(params){
							try{
								return getDDMMMYYYYHHMMDefault(params.data.uploaded_on)
							}
							catch(e){
								return "";
							}
						},
						cellClass:["cellstylegridB"]
					},
				]
			}
			
		]
        const { calview } = this.state;
        const events = [
            {
                start: '2015-07-20',
                end: '2015-07-02',
                eventClasses: 'optionalEvent',
                title: 'test event',
                description: 'This is a test description of an event',
            },
            {
                start: '2015-07-19',
                end: '2015-07-25',
                title: 'test event',
                description: 'This is a test description of an event',
                data: 'you can add what ever random data you may want to use later',
            },
        ];
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
            <SweetAlert
	            show={this.state.show1}
	            type={this.state.basicType1}
	            title={this.state.basicTitle1}
	            onConfirm={this.closeAlert1}
	        >
	        </SweetAlert>
	            {this.state.alert}
				<div className="row">

					<div className="col-xl-12 col-lg-12 col-sm-12 beffect pl-0">
						<div className="card">
							<div className="card-body" id="counterCardBody">
								<div className="crm-numbers pb-0">
									<div className="row">
										<div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"all")}>
											<span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Total Vins<br /><br /><br /></span>
											<h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.totalvins != '')?this.state.totalvins:0}/></span></h4>
										</div>
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"atplant")}>
											<span className="f13"><i className="icofont icofont-truck-alt txt-danger f22"></i><br />At Plant<br /><br /><br /></span>
											<h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.atplant != '')?this.state.atplant:0}/></span></h4>
											
										</div>
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"intransitload")}>
											<span className="f13"><i className="icofont icofont-clock-time f22 txt-secondary"></i><br /> In Transit To Loading Yard</span>
											<h4 className="txt-secondary f40"><span className="counter"><CountUp end={(this.state.intransitload != '')?this.state.intransitload:0}/></span></h4>
											
										</div>
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"atload")}>
											<span className="f13"><i className="icofont icofont-fast-delivery f22 txt-warning"></i><br /> At Loading Yard<br /><br /></span>
											<h4 className="txt-warning f40"><span className="counter"><CountUp end={(this.state.atload != '')?this.state.atload:0}/></span></h4>
											
										</div>
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"rakeassign")}>
											<span className="f13"><i className="icofont icofont-toy-train f22 txt-success"></i><br /> Rake Assigned<br /><br /> </span>
											<h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.rakeassign != '')?this.state.rakeassign:0}/></span></h4>
										
										</div>
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"intransitdest")}>
											<span className="f13"><i className="icofont icofont-ui-home txt-primary f24"></i><br /> In Transit To Destination Yard</span>
											<h4 className="txt-primary f40"><span className="counter"><CountUp end={(this.state.intransitdest != '')?this.state.intransitdest:0}/></span></h4>
											
										</div>
									
										
									{/* </div>
								</div>
							
							</div>
						</div>
					</div>

                    <div className="col-xl-6 col-lg-6 col-sm-6 beffect">
						<div className="card">
							<div className="card-body" id="counterCardBody">
								<div className="crm-numbers pb-0">
									<div className="row"> */}

                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"atdestination")} >
                                            <span className="f13"><i className="icofont icofont-truck-alt f24 darkorange"></i><br /> At Destination Yard</span>
                                            <h4 className="darkorange f40"><span className="counter"><CountUp end={(this.state.atdestination != '')?this.state.atdestination:0}/></span></h4>
                                        </div>


                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"intransitrso")} >
                                            <span className="f13"><i className="icofont icofont-clock-time f24 txt-danger"></i><br /> In Transit to RSO<br /><br /></span>
                                            <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.intransitrso != '')?this.state.intransitrso:0}/></span></h4>
                                        </div>
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"atrso")}>
                                            <span className="f13"><i className="icofont icofont-ui-home txt-primary f24"></i><br /> At RSO<br /><br /><br /></span>
                                            <h4 className="txt-primary f40"><span className="counter"><CountUp end={(this.state.atrso != '')?this.state.atrso:0}/></span></h4>
                                            
                                        </div>
                                    
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"tripendrso")} >
                                            <span className="f13"><i className="icofont icofont-truck-alt f24 txt_warning"></i><br /> Trip ended at RSO <br /><br /></span>
                                            <h4 className="txt_warning f40"><span className="counter"><CountUp end={(this.state.tripendrso != '')?this.state.tripendrso:0}/></span></h4>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"intransitdealer")}>
                                            <span className="f13"><i className="icofont icofont-ui-home txt-success f24"></i><br /> In Transit To Dealer<br /><br /></span>
                                            <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.intransitdealer != '')?this.state.intransitdealer:0}/></span></h4>
                                            
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"neardealer")} >
                                            <span className="f13"><i className="icofont icofont-truck-alt f24 txt-danger"></i><br /> Reached Dealer <br /><br /></span>
                                            <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.neardealer != '')?this.state.neardealer:0}/></span></h4>
                                        </div>


                                        {/* <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"podreceipt")} >
                                            <span className="f13"><i className="icofont icofont-clock-time f24 txt-danger"></i><br /> POD Receipt<br /><br /></span>
                                            <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.podreceipt != '')?this.state.podreceipt:0}/></span></h4>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>

				<div className="row">
				{(localStorage.getItem("roles").indexOf("Loading Officer")  >= 0
				 || localStorage.getItem("roles").indexOf("MahindraAdmin")  >= 0
				 || localStorage.getItem("roles").indexOf("SITE_ADMIN")  >= 0
				 || localStorage.getItem("roles").indexOf("admin")  >= 0
				 || localStorage.getItem("roles").indexOf("prod")  >= 0)?
					<div className="col beffect n-p-0">
						<div className="card">
							<div className="card-body" style={{padding:"15px"}}>
								<h4 className="f16">First Mile</h4>
								<div className="crm-numbers pb-0">
									<div className="row">
										<div className="col cirlce-d cursorPointer">
											<span className="f13">Avg. Travel Time (Hrs)</span>
											<h4 className="txt-info f25">
												<span className="counter">
													<CountUp end={(this.state.fmile.length != 0)?this.state.fmile.avg_travel_time_hrs:0}/>
												</span>
											</h4>
										</div>
										<div className="col cursorPointer">
											<span className="f13">Avg. Break Time (Hrs)</span>
											<h4 className="txt-danger f25">
												<span className="counter">
													<CountUp end={(this.state.fmile.length != 0)?this.state.fmile.avg_break_time_hrs:0}/>
												</span>
											</h4>
											
										</div>
										<div className="col cursorPointer">
											<span className="f13"> Avg. Speed (Kmph)</span>
											<h4 className="txt-secondary f25">
												<span className="counter">
													<CountUp end={(this.state.fmile.length != 0)?this.state.fmile.avg_speed_kmph:0}/>
												</span>
											</h4>
											
										</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
					:""}
					{(localStorage.getItem("roles").indexOf("MahindraAdmin")  >= 0
				 || localStorage.getItem("roles").indexOf("SITE_ADMIN")  >= 0
				 || localStorage.getItem("roles").indexOf("admin")  >= 0
				 || localStorage.getItem("roles").indexOf("prod")  >= 0)?
					<div className="col beffect pr-0">
						<div className="card">
							<div className="card-body" id="counterCardBody">
								<h4 className="f16">Middle Mile</h4>
								<div className="crm-numbers pb-0">
									<div className="row">
										<div className="col cirlce-d cursorPointer">
											<span className="f13">Avg. Travel Time (Hrs)</span>
											<h4 className="txt-info f25">
												<span className="counter">
													<CountUp end={(this.state.mmile.length != 0)?this.state.mmile.avg_travel_time_hrs:0}/>
												</span>
											</h4>
										</div>
										<div className="col cursorPointer">
											<span className="f13">Avg. Break Time (Hrs)</span>
											<h4 className="txt-danger f25">
												<span className="counter">
													<CountUp end={(this.state.mmile.length != 0)?this.state.mmile.avg_break_time_hrs:0}/>
												</span>
											</h4>
											
										</div>
										<div className="col cursorPointer">
											<span className="f13"> Avg. Speed (Kmph)</span>
											<h4 className="txt-secondary f25">
												<span className="counter">
													<CountUp end={(this.state.mmile.length != 0)?this.state.mmile.avg_speed_kmph:0}/>
												</span>
											</h4>
											
										</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
					:""}
					{(localStorage.getItem("roles").indexOf("Unloading Officer")  >= 0
				 || localStorage.getItem("roles").indexOf("MahindraAdmin")  >= 0
				 || localStorage.getItem("roles").indexOf("SITE_ADMIN")  >= 0
				 || localStorage.getItem("roles").indexOf("admin")  >= 0
				 || localStorage.getItem("roles").indexOf("prod")  >= 0)?
					<div className="col beffect pr-0">
						<div className="card">
							<div className="card-body" id="counterCardBody">
								<h4 className="f16">Unloading Yard to RSO</h4>
								<div className="crm-numbers pb-0">
									<div className="row">
										<div className="col cirlce-d cursorPointer">
											<span className="f13">Avg. Travel Time (Hrs)</span>
											<h4 className="txt-info f25">
												<span className="counter">
													<CountUp end={(this.state.ulmile.length != 0)?this.state.ulmile.avg_travel_time_hrs:0}/>
												</span>
											</h4>
										</div>
										<div className="col cursorPointer">
											<span className="f13">Avg. Break Time (Hrs)</span>
											<h4 className="txt-danger f25">
												<span className="counter">
													<CountUp end={(this.state.ulmile.length != 0)?this.state.ulmile.avg_break_time_hrs:0}/>
												</span>
											</h4>
											
										</div>
										<div className="col cursorPointer">
											<span className="f13"> Avg. Speed (Kmph)</span>
											<h4 className="txt-secondary f25">
												<span className="counter">
													<CountUp end={(this.state.ulmile.length != 0)?this.state.ulmile.avg_speed_kmph:0}/>
												</span>
											</h4>
											
										</div>
                                    </div>
									
									{/* <ul className="list-group">
										<li class="d-flex justify-content-between align-items-center list-group-item">Avg. Travel Time
											<span class="badge badge-warning counter digits f18">999</span>
										</li>
										<li class="d-flex justify-content-between align-items-center list-group-item">Avg. Break Time
											<span class="badge badge-secondary counter digits f18">999</span>
										</li>
										<li class="d-flex justify-content-between align-items-center list-group-item">Avg. Speed
											<span class="badge badge-success counter digits f18">999</span>
										</li>
										<li class="d-flex justify-content-between align-items-center list-group-item">&nbsp;
										</li>
										<li class="d-flex justify-content-between align-items-center list-group-item">&nbsp;
										</li>
									</ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
					:""}
					{(localStorage.getItem("roles").indexOf("RSO")  >= 0
				 || localStorage.getItem("roles").indexOf("MahindraAdmin")  >= 0
				 || localStorage.getItem("roles").indexOf("SITE_ADMIN")  >= 0
				 || localStorage.getItem("roles").indexOf("admin")  >= 0
				 || localStorage.getItem("roles").indexOf("prod")  >= 0)?
					<div className="col beffect">
						<div className="card">
							<div className="card-body" id="counterCardBody">
								<h4 className="f16">RSO to Dealer</h4>
								<div className="crm-numbers pb-0">
									<div className="row">
										<div className="col cirlce-d cursorPointer">
											<span className="f13">Avg. Travel Time (Hrs)</span>
											<h4 className="txt-info f25">
												<span className="counter">
													<CountUp end={(this.state.rsomile.length != 0)?this.state.rsomile.avg_travel_time_hrs:0}/>
												</span>
											</h4>
										</div>
										<div className="col cursorPointer">
											<span className="f13">Avg. Break Time (Hrs)</span>
											<h4 className="txt-danger f25">
												<span className="counter">
													<CountUp end={(this.state.rsomile.length != 0)?this.state.rsomile.avg_break_time_hrs:0}/>
												</span>
											</h4>
											
										</div>
										<div className="col cursorPointer">
											<span className="f13"> Avg. Speed (Kmph)</span>
											<h4 className="txt-secondary f25">
												<span className="counter">
													<CountUp end={(this.state.rsomile.length != 0)?this.state.rsomile.avg_speed_kmph:0}/>
												</span>
											</h4>
											
										</div>
                                    </div>
									
									{/* <ul className="list-group">
										<li class="d-flex justify-content-between align-items-center list-group-item">Avg. Travel Time
											<span class="badge badge-warning counter digits f18">999</span>
										</li>
										<li class="d-flex justify-content-between align-items-center list-group-item">Avg. Break Time
											<span class="badge badge-secondary counter digits f18">999</span>
										</li>
										<li class="d-flex justify-content-between align-items-center list-group-item">Avg. Speed
											<span class="badge badge-success counter digits f18">999</span>
										</li>
										<li class="d-flex justify-content-between align-items-center list-group-item">&nbsp;
										</li>
										<li class="d-flex justify-content-between align-items-center list-group-item">&nbsp;
										</li>
									</ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
					:""}
					
				</div>

            	<div className="row">
                    {/* <div className="row col-xl-12 col-lg-12">
                       
                        <div className="col-xl-6 col-lg-6">
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 form-group">
                                    <Datetime value={this.state.startDate} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: 'From Date', name: 'startDate', autoComplete:'off' }}
                                    dateFormat="YYYY-MM-DD" 
                                    name="startDate" 
                                    onChange={this.handlerStartDateTime.bind(this)} />
                                    
                                </div>

                                <div className="col-xl-4 col-lg-4 form-group">
                                    <Datetime value={this.state.endDate} 
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: 'To Date', name: 'endDate', autoComplete:'off'}} 
                                    dateFormat="YYYY-MM-DD" 
                                    onChange={this.handlerEndDateTime.bind(this)} />
                                    
                                </div>
                                <div className="form-group col-xl-2 col-lg-2">
                                    <button type="button" className="btn btn-success" onClick={this.onClickFilter.bind(this)}>Submit</button>
                                </div>
                            </div>
                        </div>

                    </div> */}
                    <div className={"col-xl-12 col-lg-12 "}>
                        {/* <RakePlanCounter
                        // invoiced={this.state.invoicedcnt}
                        // exposedinvoicedcnt={this.state.exposedinvoicedcnt}
                        // planned={this.state.plannedcnt}
                        // revisedCounter={this.state.revisedCounter}
                        // tobeapprovedcounter={this.state.to_be_approved_list.length}
                        // onClickCounterShowData = {this.onClickCounterShowData}
                        /> */}
                    </div>
            		<div className="col-xl-12 col-lg-12 pl-0">
                        
		            	<div className="card">

			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>Consignments {this.state.refno}  </span>
									   <button className={"float-right btn btn-danger f14 "} style={{marginRight:"10px"}} onClick={this.onClickSaveGridState.bind(this)}>
                                        	<i className="icofont icofont-save"></i> Save Grid Layout
										</button>
										<button className={"float-right btn btn-info f14 "} onClick={this.resetState.bind(this)}>
											<i className="icofont icofont-refresh"></i> Reset Default Layout
										</button>
									   <Dropdown className={"float-right col-xl-1 col-lg-1 col-md-1 btn-primary "+(this.state.showaction)} style={{marginRight:"20px", padding:"0px"}} isOpen={this.state.dropdownOpen} toggle={this.toggle}>

										<DropdownToggle className="dropbtn" color="warning" caret>
											Actions
										</DropdownToggle>
										<DropdownMenu>
										
										{(localStorage.getItem("roles").indexOf("Loading Officer")  >= 0)?
										<span>
											<DropdownItem style={{paddingLeft:"10px"}}>
												<a href={"/mllloadingyardarrival/"+(this.state.refno)} target="_blank" className="btn" style={{color:"#333", padding:"0px"}}>
												Loading Yard Arrival
												</a>
											</DropdownItem>
											<DropdownItem onClick={this.onClickShowRakeSidebar.bind(this)} style={{color:"#333", padding:"10px"}}>
												Add Rake Schedule
											</DropdownItem>
											<DropdownItem style={{paddingLeft:"10px"}}>
												<a href="/addmllwagonchecksheet" target="_blank" className="btn" style={{color:"#333", padding:"0px"}} >
													Wagon Check Sheet
												</a>
											</DropdownItem>
											<DropdownItem style={{paddingLeft:"10px"}}>
												<a href="/addmlltrainloadchecksheet" target="_blank" className="btn" style={{color:"#333", padding:"0px"}}>
													Train Loading Check Sheet
												</a>
											</DropdownItem>
										</span>
										:""}

									{(localStorage.getItem("roles").indexOf("Unloading Officer")  >= 0)?
										<span>
											<DropdownItem onClick={this.onClickForceMoveDestinationYard.bind(this)} style={{color:"#333", padding:"10px"}}>
												Destination Yard Arrival
											</DropdownItem>
											<DropdownItem style={{color:"#333", padding:"10px"}}>
												<a href="/addmlltrainunloadchecksheet" target="_blank" style={{color:"#333"}}>
													Train Unloading Check Sheet
												</a>
											</DropdownItem>
											<DropdownItem style={{color:"#333", padding:"10px"}}>
												<a href={"/mlllastmilerso/"+(this.state.refno)} target="_blank" style={{color:"#333"}}>
													Last Mile Tranport (RSO)
												</a>
											</DropdownItem>
											
											<DropdownItem style={{color:"#333", padding:"10px"}}>
												<a href={"/mlllastmilebatch/"+(this.state.refno)} target="_blank" style={{color:"#333"}}>
													Last Mile Tranport (Dealer)
												</a>
											</DropdownItem>
										</span>
										:""}
										
										{(localStorage.getItem("roles").indexOf("RSO")  >= 0)?
										<span>
											<DropdownItem onClick={this.onClickRSOData.bind(this)} style={{color:"#333", padding:"10px"}}>
												RSO Arrival
											</DropdownItem>
											<DropdownItem style={{color:"#333", padding:"10px"}}>
												<a href={"/mlllastmilebatch/"+(this.state.refno)} target="_blank" style={{color:"#333"}}>
												Last Mile Tranport (Dealer)
												</a>
											</DropdownItem>
											</span>
										:""}

										{(localStorage.getItem("roles").indexOf("BA")  >= 0)?
										<span>
											<DropdownItem onClick={this.onClickDealerArrival.bind(this)} style={{color:"#333", padding:"10px"}}>
												Force Closure
											</DropdownItem>
											
											</span>
										:""}

								{(localStorage.getItem("roles").indexOf("RSO")  == -1
									&& localStorage.getItem("roles").indexOf("Loading Officer")  == -1
									&& localStorage.getItem("roles").indexOf("Unloading Officer")  == -1
									&& localStorage.getItem("roles").indexOf("BA")  == -1
									)?

											<span>
											<DropdownItem style={{paddingLeft:"10px"}}>
												<a href={"/mllloadingyardarrival/"+(this.state.refno)} target="_blank" className="btn" style={{color:"#333", padding:"0px"}}>
												Loading Yard Arrival
												</a>
											</DropdownItem>

											
											<DropdownItem onClick={this.onClickShowRakeSidebar.bind(this)} style={{color:"#333", padding:"10px"}}>
												Add Rake Schedule
											</DropdownItem>
											
											<DropdownItem style={{paddingLeft:"10px"}}>
												<a href="/addmllwagonchecksheet" target="_blank" className="btn" style={{color:"#333", padding:"0px"}} >
													Wagon Check Sheet
												</a>
											</DropdownItem>
											
											<DropdownItem style={{paddingLeft:"10px"}}>
												<a href="/addmlltrainloadchecksheet" target="_blank" className="btn" style={{color:"#333", padding:"0px"}}>
													Train Loading Check Sheet
												</a>
											</DropdownItem>
											
											<DropdownItem onClick={this.onClickForceMoveDestinationYard.bind(this)} style={{color:"#333", padding:"10px"}}>
												Destination Yard Arrival
											</DropdownItem>
											
											<DropdownItem style={{color:"#333", padding:"10px"}}>
												<a href="/addmlltrainunloadchecksheet" target="_blank" style={{color:"#333"}}>
													Train Unloading Check Sheet
												</a>
											</DropdownItem>
											<DropdownItem style={{color:"#333", padding:"10px"}}>
												<a href={"/mlllastmilerso/"+(this.state.refno)} target="_blank" style={{color:"#333"}}>
												Last Mile Tranport (RSO)
												</a>
											</DropdownItem>
											<DropdownItem onClick={this.onClickRSOData.bind(this)} style={{color:"#333", padding:"10px"}}>
												RSO Arrival
											</DropdownItem>
											{/* <DropdownItem onClick={this.onClickLastMileTransport.bind(this)} style={{color:"#333", padding:"10px"}}>
												Last Mile Tranport
											</DropdownItem> */}
											<DropdownItem style={{color:"#333", padding:"10px"}}>
												<a href={"/mlllastmilebatch/"+(this.state.refno)} target="_blank" style={{color:"#333"}}>
												Last Mile Tranport (Dealer)
												</a>
											</DropdownItem>
											<DropdownItem onClick={this.onClickDealerArrival.bind(this)} style={{color:"#333", padding:"10px"}}>
												Force Closure
											</DropdownItem>
											
											{/* <DropdownItem onClick={this.onClickGPSDeviceRetrival.bind(this)} style={{color:"#333", padding:"10px"}}>
												GPS Device Retrival
											</DropdownItem> */}

											</span>
											:""}
										</DropdownMenu>
										
										</Dropdown>
									
									{/* <button className="float-right f12 custom-btn btn-success white" onClick={this.onClickLastMileTransport.bind(this)} style={{marginRight:"5px"}}>
										Last Mile Tranport
									</button>
									<button className="float-right f12 custom-btn btn-warning " onClick={this.onClickRSOData.bind(this)} style={{marginRight:"5px"}}>
										RSO Arrival
									</button>
									<a href="/mlltrainunloadchecksheet" target="_blank" className="float-right f12 custom-btn btn-primary white" style={{marginRight:"5px"}}>
										Train Unloading Check Sheet
									</a>
									<button className="float-right f12 custom-btn btn-success white" onClick={this.onClickForceMoveDestinationYard.bind(this)} style={{marginRight:"5px"}}>
										Destination Yard Arrival
									</button>
                                   <a href="/mlltrainloadchecksheet" target="_blank" className="float-right f12 custom-btn btn-info white" style={{marginRight:"5px"}}>
										Train Loading Check Sheet
									</a>	
									<a href="/mllwagonchecksheet" target="_blank" className="float-right f12 custom-btn btn-danger white" style={{marginRight:"5px"}}>
										Wagon Check Sheet
									</a>		
                                   	 <button className="float-right f12 custom-btn btn-warning" onClick={this.onClickShowRakeSidebar.bind(this)} style={{marginRight:"5px"}}>
                                        Add Rake Schedule
                                    </button> */}
									
									<Dropdown className={"float-right col-xl-1 col-lg-1 col-md-1 btn-primary "} style={{marginRight:"20px", padding:"0px"}} isOpen={this.state.dropdownAssetOpen} toggle={this.toggleAsset}>

										<DropdownToggle className="dropbtn" color="danger" caret>
											Assets
										</DropdownToggle>
										<DropdownMenu>
										{(localStorage.getItem("roles").indexOf("Loading Officer")  >= 0 || 
										localStorage.getItem("roles").indexOf("Unloading Officer")  >= 0
										|| localStorage.getItem("roles").indexOf("Plant")  >= 0
										|| localStorage.getItem("roles").indexOf("BA")  >= 0
										|| localStorage.getItem("roles").indexOf("RSO")  >= 0)?
											<DropdownItem style={{color:"#333", padding:"10px"}}>
												<a href={"/mlldevicedashboard"} target="_blank" style={{color:"#333"}}>
													GPS Device Dashboard
												</a>
											</DropdownItem>
										:""}
										{(localStorage.getItem("roles").indexOf("Loading Officer")  >= 0)?
											<DropdownItem style={{color:"#333", padding:"10px"}}>
												<a href={"/mllfirstmileretrivals"} target="_blank" style={{color:"#333"}}>
													Device Retrieval and Shipping (Loading Yard)	
												</a>
											</DropdownItem>
											:""}
										{(localStorage.getItem("roles").indexOf("Plant")  >= 0)?
											<DropdownItem style={{color:"#333", padding:"10px"}}>
												<a href={"/mllhandoverretrivals"} target="_blank" style={{color:"#333"}}>
													Device Handover (Plant)	
												</a>
											</DropdownItem>
										:""}
	

										{(localStorage.getItem("roles").indexOf("Unloading Officer")  >= 0)?
											<DropdownItem style={{color:"#333", padding:"10px"}}>
												<a href={"/mllrakeretrivals"} target="_blank" style={{color:"#333"}}>
													Rake Device Retrieval (Destination Yard)
												</a>
											</DropdownItem>
										:""}
										{(localStorage.getItem("roles").indexOf("Unloading Officer")  >= 0)?
											<DropdownItem style={{color:"#333", padding:"10px"}}>
												<a href={"/mllshiptorso"} target="_blank" style={{color:"#333"}}>
													Rake Device Shipped To RSO
												</a>
											</DropdownItem>
										:""}
										
										{(localStorage.getItem("roles").indexOf("RSO")  >= 0)?
											<DropdownItem style={{color:"#333", padding:"10px"}}>
												<a href={"/mllrsoretrivals"} target="_blank" style={{color:"#333"}}>
													Rake Device Retrieval (RSO)
												</a>
											</DropdownItem>
										:""}
										{(localStorage.getItem("roles").indexOf("RSO")  >= 0)?
											<DropdownItem style={{color:"#333", padding:"10px"}}>
												<a href={"/mllshippedtosource"} target="_blank" style={{color:"#333"}}>
													Rake Device Shipped To Plant (From RSO)
												</a>
											</DropdownItem>
										:""}
										
										{(localStorage.getItem("roles").indexOf("BA")  >= 0)?
											<DropdownItem style={{color:"#333", padding:"10px"}}>
												<a href={"/mllissuedevicetoba"} target="_blank" style={{color:"#333"}}>
													Handover To BA
												</a>
											</DropdownItem>
										:""}
									
									</DropdownMenu>

										{/* For Other User Roles */}

										{(localStorage.getItem("roles").indexOf("Loading Officer")  == -1
											&& localStorage.getItem("roles").indexOf("Plant")  == -1
											&& localStorage.getItem("roles").indexOf("Unloading Officer")  == -1
											&& localStorage.getItem("roles").indexOf("BA")  == -1
											&& localStorage.getItem("roles").indexOf("RSO")  == -1
										)?
										<DropdownMenu>
											<DropdownItem style={{color:"#333", padding:"10px"}}>
												<a href={"/mlldevicedashboard"} target="_blank" style={{color:"#333"}}>
													GPS Device Dashboard
												</a>
											</DropdownItem>
											<DropdownItem style={{color:"#333", padding:"10px"}}>
												<a href={"/mllfirstmileretrivals"} target="_blank" style={{color:"#333"}}>
													Device Retrieval and Shipping (Loading Yard)	
												</a>
											</DropdownItem>
										
											<DropdownItem style={{color:"#333", padding:"10px"}}>
												<a href={"/mllhandoverretrivals"} target="_blank" style={{color:"#333"}}>
													Device Handover (Plant)	
												</a>
											</DropdownItem>
										
											<DropdownItem style={{color:"#333", padding:"10px"}}>
												<a href={"/mllrakeretrivals"} target="_blank" style={{color:"#333"}}>
													Rake Device Retrieval (Destination Yard)
												</a>
											</DropdownItem>
										
											<DropdownItem style={{color:"#333", padding:"10px"}}>
												<a href={"/mllshiptorso"} target="_blank" style={{color:"#333"}}>
													Rake Device Shipped To RSO
												</a>
											</DropdownItem>
										
										
											<DropdownItem style={{color:"#333", padding:"10px"}}>
												<a href={"/mllrsoretrivals"} target="_blank" style={{color:"#333"}}>
													Rake Device Retrieval (RSO)
												</a>
											</DropdownItem>
										
											<DropdownItem style={{color:"#333", padding:"10px"}}>
												<a href={"/mllshippedtosource"} target="_blank" style={{color:"#333"}}>
													Rake Device Shipped To Plant (From RSO)
												</a>
											</DropdownItem>
											<DropdownItem style={{color:"#333", padding:"10px"}}>
												<a href={"/mllissuedevicetoba"} target="_blank" style={{color:"#333"}}>
													Handover To BA
												</a>
											</DropdownItem>
										</DropdownMenu>
										:""}
									</Dropdown>
								</h5>
				   			</div>
				   			<div className="card-body row">

                                <div className={"row col-xl-12 col-lg-12 "}>
                                   
                                    <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            // rowClassRules={this.state.rowClassRules}
                                            frameworkComponents={this.state.frameworkComponents}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
											masterDetail={true}
											detailCellRendererParams={this.state.detailCellRendererParams}
									
										/>

                                    </div>
                                </div>
                                
							   		
                            </div>
				   		</div>	
				   	</div>
				 </div>

				
				<div className={"slide-r "+(this.state.sliderRakeTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Add Rake Schedule
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                                    
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formRakeSchedule.bind(this)}>
                                
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select Loading Yard *</label>
                                
                                <Select 
                                placeholder={"Select Loading Yard"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeSourceItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.sourceopt}
                                options={this.selectsources()} />  
                            </div>
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">FNR No *</label>
								<input 
									type="text" 
									name="fnr_no"
									value={this.state.fnr_no}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							     
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select GPS Device *</label>                                
                                <Select 
                                placeholder={"Select GPS Device"}
								closeMenuOnSelect={true}
								isMulti={true}
                                onChange={this.onChangeDeviceItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.gpsdeviceids}
                                options={this.gpsdevices()} />  
                            </div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Dispatch Date and Time *</label>
							    <input type="text" placeholder="Dispatch Date and Time" id="dest_yard_reachedon" name="dest_yard_reachedon" className="datetimepicker_mask form-control" required />
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select Destination Yard *</label>
                                
                                <Select 
                                placeholder={"Select Destination Yard"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeDestinationItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.destopt}
                                options={this.selectdestinations()} />  
                            </div>
							
							{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Select BA:</label>
								<div className="col-xl-12 col-lg-12" style={{paddingLeft:"0px"}}>
								<Select 
									placeholder={"Select BA"}
									closeMenuOnSelect={true}
									onChange={this.onChangeBA.bind(this)} 
									className={"col-xl-12 col-lg-12 border-radius-0"}
									style={{borderRadius:"0px"}}
									value={this.state.batransporter}
									options={this.transporters()} 
								/> 
								</div>
							</div> */}
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Unloading Officer:</label>
								<div className="col-xl-12 col-lg-12" style={{paddingLeft:"0px"}}>
								<Select 
									placeholder={"Select Unloading Officer"}
									closeMenuOnSelect={true}
									onChange={this.onChangeLoadingOfficer.bind(this)} 
									className={"col-xl-12 col-lg-12 border-radius-0"}
									style={{borderRadius:"0px"}}
									value={this.state.loading_officer}
									options={this.loadingofficers()} 
								/> 
								</div>
							</div>
						
                            <div className="form-group mt-20p">
								<label className="">Upload File *</label> 
								<input type="file" name="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
							</div>
                            
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/sample_mll_rake_schedule_new.csv')} target="_blank">Sample Template</a>
							
							</div>
						</form>

                        </div>
					</div>
				</div>

                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
				<div className={"slide-r "+(this.state.sliderForceDestination)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Force Move To Destination Yard
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formForceMoveDestinationYardHandler.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Destination Yard Reached On</label>
							    <input type="text" placeholder="Destination Yard Reached On " id="dest_yard_reached" name="dest_yard_reachedon" className="datetimepicker_mask form-control" required />
							</div>
							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" onChange={this.changeDestFileHandler}  className="form-control" required  />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_mll_destination_yard.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
                        </div>
					</div>
				</div>

				<div className={"slide-r "+(this.state.sliderForceLastMile)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Last Mile Transport Batch
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formLastMileData.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select Destination *</label>
                                
                                <Select 
                                placeholder={"Select Destination"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeLastMilDestinationItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.lastmiledestination}
                                options={[
									{"value":"RSO", "label":"RSO"},
									{"value":"Dealer", "label":"Dealer"}
								]} />  
                            </div>

							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">{this.state.desttype} *</label>
                                
                                <Select 
                                placeholder={this.state.desttype}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeDestType.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.destypeopt}
                                options={this.state.destopts} />  
                            </div>

							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select Mode *</label>
                                
                                <Select 
                                placeholder={"Select Mode"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeModeType.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.modetype}
                                options={[
									{"value":"Trailer","label":"Trailer"},
									{"value":"Convoy","label":"Convoy"},
								]} />  
                            </div>

							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" onChange={this.changeLastMileFileHandler}  className="form-control" required  />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_mll_rake_destination_yard_vins_new.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
                        </div>
					</div>
				</div>


				<div className={"slide-r "+(this.state.sliderRso)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Update RSO Data
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formRSOData.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">RSO Reached On</label>
							    <input type="text" placeholder="RSO Reached On " id="rso_reached" name="rso_reached" className="datetimepicker_mask form-control" required />
							</div>
							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" id="uploadFile" onChange={this.changeLastMileFileHandler}  className="form-control" required  />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_mll_rso_new.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
                        </div>
					</div>
				</div>

				<div className={"slide-r "+(this.state.sliderLoadingYardTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Consignments
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
						<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
								<MLLConsignmentsChildGrid refno={this.state.refno} />
                        </div>
					</div>
				</div>


				<div className={"slide-r "+(this.state.sliderDeviceRetrival)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           GPS Device Retrival
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formDeviceRetrival.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select GPS Device *</label>                                
                                <Select 
                                placeholder={"Select GPS Device"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeDeviceItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.gpsdevice}
                                options={this.gpsdevices()} />  
                            </div>
							<div className="form-group mt-20p">
								<label className="">Is Retrived</label> 
								<input 
									type="radio" 
									name="retrived" 
									id="retrivedyes"   
									className="retrivecls"
									value="Yes"
									required  /> Yes

								<input 
									type="radio" 
									name="retrived" 
									id="retrivedno"   
									className="retrivecls"
									value="No"
									required  /> No
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Retrival On</label>
								<input 
									type="text"
									placeholder="Retrival On " 
									id="retrivalon" 
									name="retrivalon" 
									className="datetimepicker_mask form-control" required />
							</div>
							
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

                        </div>
					</div>
				</div>


				<div className={"slide-r "+(this.state.sliderDealerArrival)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Dealer Arrival
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formDealerData.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Dealer Reached On</label>
							    <input type="text" placeholder="Dealer Reached On " id="dealer_reached" name="dealer_reached" className="datetimepicker_mask form-control" required />
							</div>
							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" id="uploadFile" onChange={this.changeLastMileFileHandler}  className="form-control" required  />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_mll_dealer_new.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
                        </div>
					</div>
				</div>


				{this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								View Route {this.state.routefor}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								{console.log("this.state.mapinfo ", this.state.mapinfo)}
								<DrawMap 
									context={this}  
									mapFor={"mllrailconsignments"} 
									mapinfo={this.state.mapinfo} 
								/>
							
							</div>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0 mt-20p">
								<div className="col-xl-3 col-lg-3">
									<div className="card-body blc-1">
										<h4 className="map-h pl-10px"><i className="icofont icofont-train-line"></i> First Mile</h4>
										<table className="table table-border map-tbl">
											
											<tr>
												<td>Truck/GPS Device</td>
												{(this.state.mapfirstmile.length > 0)?
												<td className="map-htxt"> {this.state.mapfirstmile[0].device_id}</td>
												:
												<td className="map-htxt"> </td>
												}
												
											</tr>
											<tr>
												<td>Mode</td>
												{(this.state.mapfirstmile.length > 0)?
												<td className="map-htxt"> {this.state.mapfirstmile[0].mode_type}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Start Date & Time</td>
												{(this.state.mapfirstmile.length > 0)?
												<td className="map-htxt"> {this.state.mapfirstmile[0].from_date}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>End Date & Time</td>
												{(this.state.mapfirstmile.length > 0)?
												<td className="map-htxt"> {this.state.mapfirstmile[0].to_date}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Avg. Speed (km/h)</td>
												{(this.state.mapfirstmile.length > 0)?
												<td className="map-htxt"> {this.state.mapfirstmile[0].avg_speed_kmph}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Total Break Time (hrs)</td>
												{(this.state.mapfirstmile.length > 0)?
												<td className="map-htxt"> {this.state.mapfirstmile[0].total_break_time_mins}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Total Distance (Kms)</td>
												{(this.state.mapfirstmile.length > 0)?
												<td className="map-htxt"> {this.state.mapfirstmile[0].distance}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
										</table>
									</div>
								</div>
								<div className="col-xl-3 col-lg-3">
									<div className="card-body blc-2">
										<h4 className="map-h pl-10px"><i className="icofont icofont-train-line"></i> Middle Mile</h4>
										<table className="table table-border map-tbl">
											
											<tr>
												<td>Truck/GPS Device</td>
												{(this.state.mapmiddlemile.length > 0)?
												<td className="map-htxt"> {this.state.mapmiddlemile[0].device_id}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Mode</td>
												{(this.state.mapmiddlemile.length > 0)?
												<td className="map-htxt"> {this.state.mapmiddlemile[0].mode_type}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Start Date & Time</td>
												{(this.state.mapmiddlemile.length > 0)?
												<td className="map-htxt"> {this.state.mapmiddlemile[0].from_date}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>End Date & Time</td>
												{(this.state.mapmiddlemile.length > 0)?
												<td className="map-htxt"> {this.state.mapmiddlemile[0].to_date}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Avg. Speed (km/h)</td>
												{(this.state.mapmiddlemile.length > 0)?
												<td className="map-htxt"> {this.state.mapmiddlemile[0].avg_speed_kmph}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Total Break Time (hrs)</td>
												{(this.state.mapmiddlemile.length > 0)?
												<td className="map-htxt"> {this.state.mapmiddlemile[0].total_break_time_mins}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Total Distance (Kms)</td>
												{(this.state.mapmiddlemile.length > 0)?
												<td className="map-htxt"> {this.state.mapmiddlemile[0].distance}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
										</table>
									</div>
								</div>
								<div className="col-xl-3 col-lg-3">
									<div className="card-body blc-3">
										<h4 className="map-h pl-10px"><i className="icofont icofont-ui-home"></i> Last Mile (RSO)</h4>
										<table className="table table-border map-tbl">
											
											<tr>
												<td>Truck/GPS Device</td>
												{(this.state.maplastmilerso.length > 0)?
												<td className="map-htxt"> {this.state.maplastmilerso[0].device_id}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Mode</td>
												{(this.state.maplastmilerso.length > 0)?
												<td className="map-htxt"> {this.state.maplastmilerso[0].mode_type}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Start Date & Time</td>
												{(this.state.maplastmilerso.length > 0)?
												<td className="map-htxt"> {this.state.maplastmilerso[0].from_date}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>End Date & Time</td>
												{(this.state.maplastmilerso.length > 0)?
												<td className="map-htxt"> {this.state.maplastmilerso[0].to_date}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Avg. Speed (km/h)</td>
												{(this.state.maplastmilerso.length > 0)?
												<td className="map-htxt"> {this.state.maplastmilerso[0].avg_speed_kmph}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Total Break Time (hrs)</td>
												{(this.state.maplastmilerso.length > 0)?
												<td className="map-htxt"> {this.state.maplastmilerso[0].total_break_time_mins}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Total Distance (Kms)</td>
												{(this.state.maplastmilerso.length > 0)?
												<td className="map-htxt"> {this.state.maplastmilerso[0].distance}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
										</table>
									</div>
								</div>
								<div className="col-xl-3 col-lg-3">
									<div className="card-body blc-4">
										<h4 className="map-h pl-10px"><i className="icofont icofont-user-alt-2"></i> Last Mile (Dealer)</h4>
										<table className="table table-border map-tbl">
											
											<tr>
												<td>Truck/GPS Device</td>
												{(this.state.maplastmiledealr.length > 0)?
												<td className="map-htxt"> {this.state.maplastmiledealr[0].device_id}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Mode</td>
												{(this.state.maplastmiledealr.length > 0)?
												<td className="map-htxt"> {this.state.maplastmiledealr[0].mode_type}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Start Date & Time</td>
												{(this.state.maplastmiledealr.length > 0)?
												<td className="map-htxt"> {this.state.maplastmiledealr[0].from_date}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>End Date & Time</td>
												{(this.state.maplastmiledealr.length > 0)?
												<td className="map-htxt"> {this.state.maplastmiledealr[0].to_date}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Avg. Speed (km/h)</td>
												{(this.state.maplastmiledealr.length > 0)?
												<td className="map-htxt"> {this.state.maplastmiledealr[0].avg_speed_kmph}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Total Break Time (hrs)</td>
												{(this.state.maplastmiledealr.length > 0)?
												<td className="map-htxt"> {this.state.maplastmiledealr[0].total_break_time_mins}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Total Distance (Kms)</td>
												{(this.state.maplastmiledealr.length > 0)?
												<td className="map-htxt"> {this.state.maplastmiledealr[0].distance}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				:""}


            </div>
              
        );
    }
}


window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('#dest_yard_reachedon').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('#dest_yard_reached').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$("#rso_reached").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});

	$("#retrivalon").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$("#dealer_reached").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});

	$(".styles_modal__gNwvD").css({width:"450px"})
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){

	$('#dest_yard_reached').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('#dest_yard_reachedon').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$("#rso_reached").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$("#retrivalon").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	
	$("#dealer_reached").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});

    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}


function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDays(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}



function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 

import Highcharts from 'highcharts';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';
drilldown(Highcharts);
window.jQuery = $;
window.$ = $;

var redirectURL = require('../redirectURL');
var highchartoptions;
let xcategories
var criticaldata=[];
var clickedCluster = [];

export default class EmptyReturnTripsChart extends Component {

    constructor(props) {
		super(props);
		this.state = {
			showgrid:'display:none',
			showtransgrid:'display:none',
			rowdata:[],
			test:"TEstng",
			drilldowndata:[],
            emptyReturnTripsdrilldownData:this.props.emptyReturnTripsdrilldownData
		}

	}
	componentDidMount = async () =>{
		console.log(this.props,"33333")
		$(".grid-"+this.props.code).hide();
		$(".grid-trans-"+this.props.code).hide();
		$(".dataLoadpagesum"+this.props.code).hide()
		$(".dataLoadpageimgsum"+this.props.code).hide()
		$(".overlay-partsum"+this.props.code).hide()
		
	}
	componentWillReceiveProps (newprops){
        //if(newprops.title != this.props.dept_code)
        //{
			this.setState({
				drilldowndata:newprops.drildown,
                emptyReturnTripsdrilldownData:newprops.emptyReturnTripsdrilldownData
			})
            //console.log("newprops ",newprops);
		//}

		
	}
	hideGrid(params){
		//console.log(params)
		hideGrid(params)
	}
	hideTransGrid(params){
		hideTransGrid(params)
    }
    
    render(){
        // console.log("categories ",this.props.categories);
        // console.log("newprops ",this.props.series);
        const dStyles={
				width:'100%',
				height:'100%'
        }
        console.log(this.props,"this.props")
       xcategories=this.props.emptyReturnTripsCategories
        var emptyReturnTripsdrilldownData=this.state.emptyReturnTripsdrilldownData
        emptyReturnTripsdrilldownData=emptyReturnTripsdrilldownData.filter((e)=>(e.routes.length>0))
        console.log(emptyReturnTripsdrilldownData,"empty")
        // console.log("drilldowncols ", drilldowncols)
		// console.log("drilldownlines ", drilldownlines)
		var firstLevelCategories = [];
        highchartoptions = {
            chart: {
				zoomType: 'xy',
                type:'column',
				events : {
                    drilldown: function (e) {
                        if (!e.seriesOptions) {
                            var chart = this
                            console.log(emptyReturnTripsdrilldownData, "emptyReturnTripsdrilldownData")
                            console.log(chart,e.point.name,e.point.plant_name,e.point.routes,"chart")
                            try{
                                var categories=[]
                                var column1=[]
                                var column2=[]
                                var spline=[]
                                var index=e.point.index 
                                var routes=e.point.routes
                                var categories=[]
                                // console.log(emptyReturnTripsdrilldownData2,index,"90")
                                // var filteredItem=emptyReturnTripsdrilldownData2[index]
                                // console.log(filteredItem,filteredItem.routes,"226")
                                // if(filteredItem.routes && filteredItem.routes.length>0){
                                //     var routes=filteredItem.routes
                                    routes.map((each)=>{
                                        // categories.push(each.route)
                                        column1.push([each.route,each.route_total_trips])
                                        column2.push([each.route,each.route_empty_trips])
                                        spline.push([each.route,each.route_percentage])
                                        categories.push(each.route)
                                        // column1.push({name:"Total Trips",y:each.route_total_trips})
                                        // column2.push({name:"Empty Trips",y:each.route_empty_trips})
                                        // spline.push({name:"percentage",y:each.route_percentage})
                                    })
                                // }

                                var series1={name:"Total Trips",type:"column",data:column1}
                                var series2={name:"Return Trips",type:"column",data:column2}
                                
                                var seriesLine={
                                    name:"Percentage",
                                    type:"spline",
                                    yAxis:1,
                                dataLabels:{enabled: true, format: '{point.y}%'},
                                marker: {lineWidth: 2, lineColor: '#f7a35c', fillColor: 'white'},
                                data:spline}
                                console.log(seriesLine,"seriesLine")
                                chart.addSingleSeriesAsDrilldown(e.point, series1);
                                chart.addSingleSeriesAsDrilldown(e.point, series2);
                                chart.addSeriesAsDrilldown(e.point, seriesLine);


                                chart.applyDrilldown();
    
                                chart.update({
                                    xAxis: {
                                    categories : categories
                                    },
                                })
                                console.log(column1,column2,spline,"106")
                            }catch(e){
                                console.log(e,"105")
                            }
                           

                        }},

                    click: function(e){
                        var point = this;
                        console.log(this.series,e.point,xcategories, "event");
                        // if(this.series[0]['name'] == "Total Trips")
                        // {
                            this.update({
                                xAxis: {
                                    categories :xcategories
                                }
                            })
                        // }
                        // else
                        // {
                        //     this.update({
                        //         xAxis: {
                        //             categories : firstLevelCategories	
                        //         }
                        //     })
                        // }
                        
                    },
					
				}
            },
            title: {
                text: this.props.title
            },
            xAxis: {
				categories: this.props.emptyReturnTripsCategories,
				// categories: true,
				// type: 'category',
				crosshair: true
            },
            yAxis: [
                { // Secondary yAxis
                    title: {
                        text: 'Total Trips vs Return Trips',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: '{value} ',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: true
                },
                { // Primary yAxis
                labels: {
                    format: '{value}',
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                },
                title: {
                    text: 'Return Trip Percentage',
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                }
            }]
        
            ,
            colors:[ '#f35e82','#7f84e9','#FF5370', '#f15c80', '#92A8CD','#A47D7C','#f3d800',  '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
		
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true
					},
					stacked: false
				},
				line:{
					dataLabels:{
                        enabled:true,
                        // format:'{point.y}'
					},
					stacked: false
				},
		
			},
            // tooltip: {
            //     shared: true
            // },
            tooltip: {
                shared:true,
                useHTML: true,
                headerFormat: '<table><tr><th colspan="2">{this.x}</th></tr>',
                pointFormat: '<tr><td style="color: {series.color}">{series.name} </td>' +
                    '<td style="text-align: right"><b>{point.y} </b></td></tr>',
                footerFormat: '</table>',
                valueDecimals: 2
            },
            series:this.props.emptyReturnTripsSeries,
            drilldown: {
                chartOptions: {
                    xAxis:{
                    categories:["west","east","north","south"],
                    // 	// categories: true,
                    // 	// type: 'category',
                    // 	crosshair: true
                    },
                    yAxis: [
                        { // Secondary yAxis
                            title: {
                                text: 'Total Trips vs Return Trips',
                                style: {
                                    color: Highcharts.getOptions().colors[0]
                                }
                            },
                            labels: {
                                format: '{value} ',
                                style: {
                                    color: Highcharts.getOptions().colors[0]
                                }
                            },
                            opposite: true
                        },
                        { // Primary yAxis
                        labels: {
                            format: '{value}',
                            style: {
                                color: Highcharts.getOptions().colors[1]
                            }
                        },
                        title: {
                            text: 'Return Trip Percentage',
                            style: {
                                color: Highcharts.getOptions().colors[1]
                            }
                        }
                    },],
                },
                
                series: [],
                // seriesLine: this.props.drilldownlines
            },
   
            credits: false
        };
		console.log(highchartoptions.series,"route series")
        console.log(highchartoptions.drilldown,"Route wise drilldown")
        return ( 
			<div>
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />
			</div>
			);
		}
}


function hideGrid(id)
{
	console.log("id ",id)
	$(".grid-"+id).fadeOut()
}

function hideTransGrid(id)
{
	console.log("id ",id)
	$(".gridtrans-"+id).fadeOut()
}


function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
  } 

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

function arrayMin(arr) {
	return arr.reduce(function (p, v) {
	  return ( p < v ? p : v );
	});
  }
  
  function arrayMax(arr) {
	return arr.reduce(function (p, v) {
	  return ( p > v ? p : v );
	});
  }

// function getData(){
// 	alert("cons")
// }

// $("svg").find("click","",function(){
//     console.log("clicked")
//     console.log($(".highcharts-drillup-button").text())
// })
// $(document).on('click', "rect[class='highcharts-button-box']",function() { 
//     console.log($(this).text(),"clicked");
//     if($(this).text() != "◁ Back to Return Trips")
//     {
//         console.log(clickedCluster);
//         console.log("highchart options", highchartoptions.xAxis)
//         highchartoptions.xAxis[0].setCategories(clickedCluster); 
//     }
//  });
// $('body').on('click','.highcharts-drillup-button',function(){
//     // alert('clicked')
//    })
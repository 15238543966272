"use strict";

import React, { Component, useDebugValue } from "react";
import { render } from "react-dom";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MenuModule } from "@ag-grid-enterprise/menu";
// import Consignmentforceclose from '../m';
import CSVFileValidator from "csv-file-validator";
import Select from "react-select";
import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import CountUp from "react-countup";
import SweetAlert from "react-bootstrap-sweetalert";
import * as Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import $ from "jquery";
import ColumnGroup from "./columngroupComponent";
import {
  getDDMMYYYY,
  getDDMMYYYYHHMMSS,
  getYYYYMMDD,
  getYYYYMMDDHHMMSS,
  getHyphenYYYYMMDDHHMMSS,
  getHyphenDDMMMYYYYHHMM,
  getHyphenYYYYMMDD,
  getHyphenDDMMYYYYHHMMSS,
  getHyphenDDMMYYYY,
  getDDMMMYYYYHHMMDefault,
  getDDMMMYYYYDefault,
} from "../common/utils";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import BatchDownload from "./batchdownload";
import { split } from "lodash";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require("../redirectURL");
var moment = require("moment");
var filecontent = "";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;
var validatedBy = "";

export default class GstRegisteredWarehouseCoilDispatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        width: 160,
        resizable: true,
      },
      eventAction: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      loadshow: "show-n",
      overly: "",
      rowdata: [],
      sliderbulkupload: "",
      rowModelhighchartoptionsType: "enterprise",
      frameworkComponents: {
        BatchDownload: BatchDownload,
      },
      rowGroupPanelShow: "always",
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      paginationPageSize: 50,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      animateRows: true,
      dept_code: "",
      pagetitle: "",
      csvData: 0,
      overly: "show-n",
      loadshow: "show-n",
      pagetitle: "",
      eventGridAction: "gridAction",
      csvcontent: [],
      file: "",
      batch_name: "",
      cluster_breaks: "2",
      cluster_radius: "0.5",
      notification_emails: "",
      bulkslide2: "",
      trailor_no: "",
      container_name: "",
      rowId: "",
      show: false,
      basicTitle: "",
      basicType: "",
      transitDate: "",
      transpoterGSTDate: "",
      unloadedDate: "",
      reachedDate: "",
    };
    // this.downloadbatchfile = this.downloadbatchfile.bind(this);
  }

  componentDidMount() {
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    var params = {
      screen_type: 1,
    };
    var tpt_code = localStorage.getItem("transportercode");
    if (tpt_code != undefined && tpt_code != null && tpt_code != "") {
      params.transporter_code = eval(tpt_code);
    }
    redirectURL
      .post("/consignments/coiltrackingrequirements", params)
      .then((response) => {
        console.log(response.data, "140");

        this.setState({
          rowdata: response.data,

          loadshow: "show-n",
          overly: "show-n",
        });
      })
      .catch((e) => {
        console.log(e);
      });

    loadDateTimeScript();
  }

  containertypechangeHandler = (e) => {
    this.setState({
      container_type: e,
    });
  };
  containernamechangeHandler = (e) => {
    this.setState({
      container_name: e,
    });
  };

  closeAlert = () => {
    this.setState({
      show: false,
    });
  };
  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      bulkslide: "",
    });
  }
  changeHandler = (event) => {
    //console.log("Select", event.target.value);
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
  };
  onCellRowClicked = (e) => {
    var rowId = e.data._id;
    if (e.colDef.field == "gst_registered_trailor_no") {
      this.setState({
        bulkslide: "slider-translate-30p",
        rowId: rowId,
      });
    }
  };
  destinationFromTransitdestinationfromtransitchangeHandler = (e) => {
    console.log(e, "163");
    this.setState({
      destination: e,
    });
  };

  onCellRowClicked = (e) => {
    var rowId = e.data._id;
    this.setState({
      transitDate: getDDMMMYYYYHHMMDefault(
        e.data.dispatch_date_from_transit_warehouse
      ),
    });
    this.setState({
      transpoterGSTDate: e.data.dispatch_data_gst_registered_warehouse,
    });
    // console.log(this.state.transpoterGSTDate,"transpoterGSTDate===")
    this.setState({
      reachedDate: e.data.gst_registered_dispatch_reached_date,
    });
console.log(this.state.reachedDate,"reachedDate===232")

this.setState({
  unloadedDate: e.data.gst_registered_unloaded_date,
});

    if (
      e.colDef.field == "gst_registered_trailor_no" ||
      e.colDef.field == "dispatch_data_gst_registered_warehouse" ||
      e.colDef.field == "gst_registered_warehouse_transporter_name"
    ) {
      if (
        e.data.gst_registered_trailor_no == undefined ||
        e.data.gst_registered_trailor_no == "undefined" ||
        e.data.gst_registered_trailor_no == null ||
        e.data.gst_registered_trailor_no == ""
      ) {
        this.setState({
          bulkslide: "slider-translate-30p",
          rowId: rowId,
        });
      }
    }
  };

  onClickShowFilterData(event) {
    event.preventDefault();
    var rowId = this.state.rowId;
    var trailorNumber = $("#bookingId1").val();
    var dispatchDate = $("#etd_weekly").val();
    var transporterName = this.state.container_name.value;

    if (trailorNumber.length >= 8 && trailorNumber.length <= 15) {
      var alphanumericRegex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]+$/;
      if (alphanumericRegex.test(trailorNumber)) {
        var params = {
          dispatch_data_gst_registered_warehouse: dispatchDate,
          trailor_no: trailorNumber,
          gst_registered_warehouse_transporter_name: transporterName,
          rowId: rowId,

          screen_type: "1",
        };

        var sample = params.dispatch_data_gst_registered_warehouse;
        var checkData =
          sample.split("-")[2] +
          "-" +
          sample.split("-")[1] +
          "-" +
          sample.split("-")[0];

        var hitData = moment
          .parseZone(new Date(checkData))
          .format("YYYY-MM-DD");

        var newDateTransit = moment
          .parseZone(this.state.transitDate)
          .format("YYYY-MM-DD");

        var transitData = moment
          .parseZone(new Date(newDateTransit + " 00:00:00"))
          .utcOffset("+05:30")._d;
          console.log(transitData,'transitDate====')
        var gpsData = moment
          .parseZone(new Date(hitData + " 00:00:00"))
          .utcOffset("+05:30")._d;

        if (
          transitData < gpsData ||
          transitData == "Invalid Date" ||
          transitData == null ||
          transitData == ""
        ) {
          var cnf = window.confirm("Do you Want to Submit");
          if (cnf === true) {
            console.log(params, "257");

            redirectURL
              .post("/consignments/updatetrailorCoilStockInformation", params)
              .then((response) => {
                $("#bookingId1").val("");
                if (response.data.message === "success") {
                  this.setState({
                    basicTitle: "Successfully Updated",
                    basicType: "success",
                    show: true,
                    bulkslide: "",
                    trailor_no: "",
                  });
                  this.setState({ rowId: "", container_name: "" });
                  this.componentDidMount();
                }
              });
          }
        } else {
          alert("GST Registered Date Should be Greater then Transit Date");
          $("#bookingId1").val("");
          $("#etd_weekly").val("");
          this.setState({ rowId: "", container_name: "" });

          this.onClickHideAll();
        }
      } else {
        alert(
          "Field should contain at least one alphabet, one number, and no special characters"
        );
        $("#bookingId1").val("");
        $("#etd_weekly").val("");
        this.setState({ rowId: "", container_name: "" });

        this.onClickHideAll();
      }
    } else {
      alert("Trailer Number should minimum 8 Characters maximum 15 Characters");
      $("#bookingId1").val("");
      $("#etd_weekly").val("");
      this.setState({ rowId: "", container_name: "" });

      this.onClickHideAll();
    }
  }
  render() {
  

    var setEditable = this.state.setEditable;
    var enableEdit = false;
    if (setEditable === 1) {
      enableEdit = true;
    }

    var transporterEdit = "";
    var transporterEdit2 = "";
    // console.log(this.state.reachedDate,'===+++=')
    if (localStorage.getItem("user_type") == "TRANSPORTER") {
      if (
       ( this.state.reachedDate == "" ||
        this.state.reachedDate == null) 
      ) {
        transporterEdit = true;
      } else {
        transporterEdit = false;
      }
    }
    if (localStorage.getItem("user_type") == "TRANSPORTER") {
      if (
       ( this.state.unloadedDate == "" ||
        this.state.unloadedDate == null) 
      ) {
        transporterEdit2 = true;
      } else {
        transporterEdit2 = false;
      }
    }

   
    var userType = localStorage.getItem("user_type");
    if (userType === "TRANSPORTER") {
      var tptHide = false;
    } else {
      var tptHide = true;
    }

    const batchwisecolumns = [
      {
        headerName: "Coil Unique No.",
        field: "coil_unique_no",
      },

      {
        headerName: "Stock At Warehouse",
        field: "stock_at_warehouse",
      },
      {
        headerName: "Order received from SC",
        field: "order_received_from_sc",
        valueGetter: function(params) {
          try {
            return getDDMMMYYYYHHMMDefault(params.data.order_received_from_sc);
          } catch (e) {
            return "";
          }
        },
      },
      {
        headerName: "GST Registered W/H",

        field: "destinationfromtransit",
        cellClass: function(params) {
          if (
            params.data.destinationfromtransit == "" ||
            params.data.destinationfromtransit == null
          ) {
            return "bgColorAshLight";
          }
        },
      },

      {
        headerName: "Dispatch Date from Transit W/H",
        field: "dispatch_date_from_transit_warehouse",

        editable: false,
        valueGetter: function(params) {
          try {
            return getDDMMMYYYYHHMMDefault(
              params.data.dispatch_date_from_transit_warehouse
            );
          } catch (e) {
            return "";
          }
        },
        cellClass: function(params) {
          if (
            params.data.dispatch_date_from_transit_warehouse == "" ||
            params.data.dispatch_date_from_transit_warehouse == null
          ) {
            return "bgColorAshLight";
          }
        },
      },

      {
        headerName: "Dispatch Date from GST Registered W/H",
        field: "dispatch_data_gst_registered_warehouse",
        // cellEditor: DateEditor,
        editable: false,
        valueGetter: function(params) {
          try {
            return getDDMMMYYYYHHMMDefault(
              params.data.dispatch_data_gst_registered_warehouse
            );
          } catch (e) {
            return "";
          }
        },
      },
      {
        headerName: "Trailer No.",
        field: "gst_registered_trailor_no",
        // cellEditor: AlphaNumericEditor,
        editable: false,
      },
      {
        headerName: "Transporter Name",
        field: "gst_registered_warehouse_transporter_name",
        editable: false,
      },

      {
        headerName: "Vendor/MSIL Reached Date",
        field: "gst_registered_dispatch_reached_date",
        cellEditor: DateEditor,
        hide: tptHide,
        editable: transporterEdit,
        valueGetter: function(params) {
          try {
            return getDDMMMYYYYHHMMDefault(
              params.data.gst_registered_dispatch_reached_date
            );
          } catch (e) {
            return "";
          }
        },
        valueSetter: (params) => {
          var date1 = new Date();
          var date2 = new Date(params.newValue);

          if (date2 < date1) {
            alert("Date should be greater than Current Date");
          } else {
            var params = {
              _id: params.data._id,
              value: params.newValue,
              column: "gst_registered_dispatch_reached_date",
            };

            var sample2 = params.value;

            var checkData = sample2.split(" ");

            var checkDate2 =
              checkData[0].split("/")[0] +
              "-" +
              checkData[0].split("/")[1] +
              "-" +
              checkData[0].split("/")[2];

            var reaached = moment
              .parseZone(new Date(checkDate2))
              .format("YYYY-MM-DD");

            var newDateReached = moment
              .parseZone(this.state.transpoterGSTDate)
              .format("YYYY-MM-DD");

            var GSTDate = moment
              .parseZone(new Date(newDateReached + " 00:00:00"))
              .utcOffset("+05:30")._d;

            var newReached = moment
              .parseZone(new Date(reaached + " 00:00:00"))
              .utcOffset("+05:30")._d;

            if (GSTDate < newReached) {
              var cnf = window.confirm("Do you Want to Submit");
              if (cnf === true) {
                redirectURL
                  .post("/consignments/updateCoilStockInformation", params)
                  .then((response) => {
                    if (response.data.message == "Success") {
                      this.setState({
                        basicTitle: "Successfully Updated",
                        basicType: "success",
                        show: true,
                        bulkslide: "",
                      });
                      this.componentDidMount();
                    }
                  });
              } 
            }
            
            else {
                alert(
                  "Vendor/MSIL Reached Date should be greater then Dispatch Date from GST Registered W/H"
                );
              }
            }
          // }
        },
      },
      {
        headerName: "Unloaded Date",
        field: "gst_registered_unloaded_date",
        cellEditor: DateEditor,
        hide: tptHide,
        editable: transporterEdit2,

        valueGetter: function(params) {
          try {
            return getDDMMMYYYYHHMMDefault(
              params.data.gst_registered_unloaded_date
            );
          } catch (e) {
            return "";
          }
        },

        valueSetter: (params) => {
          var date1 = new Date();
          var date2 = new Date(params.newValue);

          if (date2 < date1) {
            alert("Date should be greater than Current Date");
          } else {
            var params = {
              _id: params.data._id,
              value: params.newValue,
              column: "gst_registered_unloaded_date",
            };

            var sample2 = params.value;
            var checkData = sample2.split(" ");
            // console.log(this.state.reachedDateStorage,"insideLoop========2")
            var checkDate2 =
              checkData[0].split("/")[0] +
              "-" +
              checkData[0].split("/")[1] +
              "-" +
              checkData[0].split("/")[2];

            var reaached = moment
              .parseZone(new Date(checkDate2))
              .format("YYYY-MM-DD");
            var newDateReached = moment
              .parseZone(this.state.reachedDate)
              .format("YYYY-MM-DD");

            var reachedDate1 = moment
              .parseZone(new Date(newDateReached + " 00:00:00"))
              .utcOffset("+05:30")._d;

            var unloaded = moment
              .parseZone(new Date(reaached + " 00:00:00"))
              .utcOffset("+05:30")._d;

           console.log(this.state.reachedDate,'reachedDate1=====')
            if(this.state.reachedDate!=""&& this.state.reachedDate!=null&&this.state.reachedDate!=undefined&&this.state.reachedDate!="undefined"){
            if (reachedDate1 <= unloaded ) {
              var cnf = window.confirm("Do you Want to Submit");
              if (cnf === true) {
              redirectURL
                .post("/consignments/updateCoilStockInformation", params)
                .then((response) => {
                  if (response.data.message == "Success") {
                    this.setState({
                      basicTitle: "Successfully Updated",
                      basicType: "success",
                      show: true,
                      bulkslide: "",
                    });

                    this.componentDidMount();
                  }
                });
              }
           
          }
          else {
              alert(
                "Unloaded Date should be greater then Vendor/MSIL Reached Date"
              );
            }
          }else {
            alert("Vendor/MSIL Reached Date required")
          }
        }
        },
      },
    ];

    return (
      <div class="container-fluid">
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>

        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="card">
              <div className="card-header">
                <h5>
                  <i className="icofont icofont-vehicle-delivery-van cus-i"></i>
                  <span>GST-Registered Warehouse Coil Dispatch</span>
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div
                    id="myGrid"
                    style={{ width: "100%", height: "478px" }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      modules={this.state.modules}
                      columnDefs={batchwisecolumns}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.rowdata}
                      enableCharts={false}
                      onGridReady={this.onGridReady}
                      onGridState={this.onGridState}
                      frameworkComponents={this.state.frameworkComponents}
                      rowClassRules={this.state.rowClassRules}
                      sideBar={this.state.sideBar}
                      stopEditingWhenGridLosesFocus={true}
                      paginationPageSize={this.state.paginationPageSize}
                      pagination={true}
                      gridOptions={{
                        context: { componentParent: this },
                      }}
                      onCellClicked={this.onCellRowClicked.bind(this)}
                      enableRangeSelection={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={"slide-r " + this.state.bulkslide}
          style={{ overflow: "hidden" }}
        >
          <h3 className="subH">
            Add Trailer Number
            <span
              className="float-right fclose"
              style={{
                marginRight: "12px",
                padding: "1px 8px",
                marginTop: "-2px",
              }}
              onClick={this.onClickHideAll.bind(this)}
            >
              x
            </span>
          </h3>

          <div className="slide-r-body" style={{ position: "relative" }}>
            <div className="container-fluid">
              <form
                method="post"
                onSubmit={this.onClickShowFilterData.bind(this)}
                autocomplete="off"
                className="row col-xl-12 col-lg-12"
              >
                <div className="col-xl-12 col-lg-12 row form-group">
                  <label className="col-xl-12 col-lg-12">
                    Dispatch Date GST Registered W/H :{" "}
                  </label>
                  <input
                    type="text"
                    name="etd_weekly"
                    id="etd_weekly"
                    className="datetimepicker_datew form-control"
                    placeholder="Dispatch Date from GST Registered W/H"
                  />
                </div>
                <div
                  className="form-group mt-20p col-xl-12 col-lg-12"
                  style={{ paddingLeft: "0px", paddingRight: "27px" }}
                >
                  <label className="col-xl-12 col-lg-12">
                    GST Registered W/H Trailer No.:{" "}
                  </label>
                  <input
                    type="text"
                    name="bookingId1"
                    id="bookingId1"
                    className="form-control"
                    placeholder="Trailer Number"
                    onChange={this.dispatchportchangeHandler}
                    required
                  />
                </div>
                <div className="col-xl-12 col-lg-12 row form-group mt-20p">
                  <label className="col-xl-12 col-lg-12">
                    GST Registered Transporter Name :{" "}
                  </label>
                  <Select
                    placeholder={"Transporter Name"}
                    closeMenuOnSelect={true}
                    // id={"transporterName"}
                    // name={"transporterName"}
                    value={this.state.container_name}
                    className={"col-xl-12 col-lg-12 border-radius-0"}
                    onChange={this.containernamechangeHandler.bind(this)}
                    style={{ borderRadius: "0px" }}
                    options={[
                      {
                        label: "K M Trans Logistics",
                        value: "K M Trans Logistics",
                      },
                      {
                        label: "Arihant Trailer Service",
                        value: "Arihant Trailer Service",
                      },
                      {
                        label: "Kalptaru Logistics",
                        value: "Kalptaru Logistics",
                      },
                      {
                        label: "Rakesh Road Carrier",
                        value: "Rakesh Road Carrier",
                      },
                    ]}
                    required
                  />
                </div>

                <div className="form-group col-xl-3 col-lg-3">
                  <button type="submit" className="btn btn-success">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function AlphaNumericEditor() {}
AlphaNumericEditor.prototype.init = function(params) {
  this.eInput = document.createElement("input");
  if (params.value !== "" && params.value !== undefined) {
    this.eInput.value = params.value;
  } else {
    this.eInput.value = "";
  }

  var that = this;
  this.eInput.addEventListener("blur", () => {
    var regex = /[^a-zA-Z0-9\s]/g;
    var matches = this.eInput.value.match(regex);
    matches = matches ? matches : [];

    if (this.eInput.value == "") {
      alert("Field should not be Empty");
    }
    if (matches.length > 0) {
      alert("Field should not contain Special Characters");
    }
  });
  this.eInput.addEventListener("keypress", function(event) {
    console.log(event.key, "472");

    var regex = new RegExp("^[a-zA-Z0-9 _@-]+$");
    var key = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );
    if (!regex.test(key)) {
      that.eInput.focus();
      return false;
    }
  });
};
AlphaNumericEditor.prototype.getGui = function() {
  return this.eInput;
};

AlphaNumericEditor.prototype.getValue = function() {
  var regex = new RegExp("^[a-zA-Z0-9]+$");
  var oVal = this.eInput.value;
  if (!regex.test(oVal) == true) {
    return "";
  } else {
    console.log(this.eInput.value, "his.eInput.value");
    if (this.eInput.value !== "" && this.eInput.value !== undefined) {
      return this.eInput.value;
    } else {
      return "";
    }
  }
};
AlphaNumericEditor.prototype.destroy = function() {};

AlphaNumericEditor.prototype.isPopup = function() {
  return false;
};

function DateEditor() {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
  // create the cell
  console.log(params, "params");
  this.eInput = document.createElement("input");
  this.eInput.value = params.value;

  // https://jqueryui.com/datepicker/
  $(this.eInput).datetimepicker({
    dateFormat: "yy-mm-dd",
    timepicker: false,
    changeMonth: true,
    changeYear: true,
  });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
  return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
  this.eInput.focus();
  this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
  return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
  // but this example is simple, no cleanup, we could
  // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
  // and we could leave this method out also, false is the default
  return false;
};

function loadDateTimeScript() {
  // alert("timeDate");
  $(".datetimepicker_datew").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y",
    minDate: true,
    timepicker: false,
    closeOnDateSelect: true,
    scrollMonth: false,
    scrollInput: false,
  });
  $(".datetimepicker_dateo").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y",
    minDate: true,
    timepicker: false,
    closeOnDateSelect: true,
    scrollMonth: false,
    scrollInput: false,
  });
  $(".cargo_date").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y",
    minDate: true,
    timepicker: false,
  });
}

/* eslint-disable eqeqeq */
'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { TabContent, TabPane } from 'reactstrap';
import CKEditor from "react-ckeditor-component";
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "./updatelmstates";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CellGraphComponent from './cellGraphComponent';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { HardwareDesktopWindows } from 'material-ui/svg-icons';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from "@fullcalendar/timegrid";    
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class ImportsCoilTracking extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          consignmentforceclose:Consignmentforceclose,
          consignmentActions:ConsignmentActions,
          UpdateButton:UpdateButton,
          MarkDeliver : MarkDeliver,
          commentActions : CommentActions
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        holidayEvents : [],
        maxNodes: [],
        telRoutes: [],
        deviation: [],
        showCalendar : "show-m",
        showGridData : "show-m",
        detailCellRendererParams:{},
        overlayNoRowsTemplate:"",
        partial: "activet",
        complete: "",
        partialData: "",
        completeData: "show-n",
        showleftmailside:'show-n',
        draftlist:[],
        sendmaildiv:"col-xl-12",
        SelectedTab: '1',
        ticketEmailContent: '',
        ticketSubject:'',
        toEmail:'',
        ccEMail:'',
        ticketMailID:'',
        ship_name:'',
        voyage_no:'',
        viewmailSlider:'',
        emaillist:[],
        //components : {datepicker:getDatePicker()}
    }
    this.changeFileHandler = this.changeFileHandler.bind(this);
    this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this)
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.updateContent = this.updateContent.bind(this);
  }

componentDidMount(){
    this.loadPageData(1);
}

loadPageData(dataType){
    this.setState({
        overly:"show-m",
        loadshow:"show-m",
    })
    var params = {
        dataType:dataType
    }
    redirectURL.post("/consignments/getImportCoilTracking",params).then((response)=>{
        console.log(response.data);
        var rowData = response.data;
        this.setState({
            rowData : rowData,
            overly:"show-n",
            loadshow:"show-n",
        })
    }).catch((e)=>{
        console.log(e);
    })
}
    
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
    * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
            
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    this.setState({
        show: false
    });
}

onChangeHandler = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({[name]:value});
}

onBlur = async (evt) =>{
    console.log("onBlur event called with event info: ", evt.editor.getData());
    await this.setState({
        ticketEmailContent:  evt.editor.getData()
    })
}

afterPaste(evt){
    console.log("afterPaste event called with event info: ", evt);
    this.setState({
        ticketEmailContent:  evt.editor.getData()
    })
}

updateContent(newContent) {
    console.log("newContent ",newContent)
    this.setState({
        ticketEmailContent:  newContent.editor.getData()
    })
}

async onCellClicked(e)
{
    if(e.colDef.field == "no_of_bls")
    {
        var commentHeaders = [
			{
                headerName : "BL No",
                field : "bl_no",
                resizable : true,
                width:100,
                filter : true
            },
            {
                headerName : "Ship Name",
                field : "ship_name",
                resizable : true,
                width:120,
                filter : true
            },
            {
                headerName : "Voyage No",
                field : "voyage_no",
                width:120,
                resizable : true,
                filter : true
            },
            // {
            //     headerName : "Status",
            //     field : "bl_status",
            //     width:130,
            //     resizable : true,
            //     filter:true
            // },
            {
                headerName : "Gross Weight",
                field : "gross_weight",
                width:130,
                resizable : true,
                filter:true
            },
            {
                headerName : "No. of Coils",
                field : "no_of_coils",
                width:130,
                resizable : true,
                filter:true
            },
            {
                headerName : "No. of Coils Punched",
                field : "no_of_coils_invoiced",
                width:130,
                resizable : true,
                filter:true
            },
            // {
            //     headerName : "Partial Invoiced",
            //     field : "partial_invoiced",
            //     width:130,
            //     resizable : true,
            //     filter:true
            // }
		]
        var overspeedData=[];
			
        //console.log("Step 3 ",overspeedData)
        var params = e;
        
        var voyage_no = e.data.voyage_no;
        await this.setState({
            
            detailCellRendererParams:{
                detailGridOptions: {
                    columnDefs: commentHeaders,
                    overlayNoRowsTemplate: 'No rows to show',
                                                    
                },
                getDetailRowData:async function(params) {
                    params.successCallback([]);
                    await redirectURL.post("/consignments/getImportsCoilLegs",{
                        voyage_no : params.data.voyage_no,
                    }).then(async (response) =>{
                        console.log("Step 1 ",response.data)
                        console.log(response.data);
                        var comments = [];
                        comments=response.data;
                        params.successCallback(comments);
                        
                        
                        console.log("Step 2 ",response.data)
                        
                    }).catch(function(error){
                        console.log(error);
                    })
                        
                },
                masterDetail: true
            }
        });
        console.log(e);
        if(e.colDef.field == 'no_of_bls')
        {

            e.node.setExpanded(!e.node.expanded);
        }
        else{

            e.node.setExpanded(false);
        }
        
    }

    if(e.colDef.field == "sendEmail")
    {
        this.setState({
            overly:"show-m",
            loadshow:"show-m",
        });
        var ship_name = e.data.ship_name;
        var voyage_no = e.data.voyage_no;
        var ticketMailID = e.data._id;
        var params = {
            ship_name:ship_name,
            voyage_no:voyage_no,
        }
        redirectURL.post("/consignments/getMailForPartialInvoicedVessel",params).then((response)=>{
            console.log(response.data);
            var mailData = response.data;
            this.setState({
                ticketEmailContent : mailData.message_body,
                ticketMailID:ticketMailID,
                ship_name:ship_name,
                voyage_no:voyage_no,
                ticketSubject:ship_name+" / "+voyage_no,
                overly:"show-m",
                loadshow:"show-n",
                bulkslide : "slider-translate-60p"
            })
            
        }).catch((e)=>{
            console.log(e);
        })
    }

    if(e.colDef.field == "emailLogs")
    {
        var ship_name = e.data.ship_name;
        var voyage_no = e.data.voyage_no;
        var coilId = e.data._id;
        var params = {
            coilId:coilId,
        }
        redirectURL.post("/consignments/getImportsCoilSentMails",params).then((response)=>{
            console.log(response.data);
            var mailData = response.data;
            this.setState({
                ship_name:ship_name,
                voyage_no:voyage_no,
                overly:"show-m",
                loadshow:"show-n",
                viewmailSlider:'slider-translate-75p',
                overly:'show-m',
                showleftmailside:'show-m',
                sendmaildiv:"col-xl-9",
                emaillist:mailData,
                toEmail:'',
                ccEmail:'',
                ticketEmailContent:'',
                ticketSubject:'',
                ticketMailID:'',
                viewshow:'show-n',
            })
            
        }).catch((e)=>{
            console.log(e);
        })
    }
}

onClickDiscardAllMail(){
    var reqparams = {
        userid:localStorage.getItem("userid")
    };
    redirectURL.post("/consignments/discardAllImportCoilMail",reqparams)
    .then((response) => {
        //console.log(" response ", response)
        redirectURL.post("/consignments/discardImportCoilMail")
        .then((response) => {
            
            this.setState({					
                bulkslide:'',
                showleftmailside:'show-n',
                sendmaildiv:"col-xl-12",
                overly:'show-n',
                emailformat:[],
                toEmail:'',
                ccEmail:'',
                ticketEmailContent:'',
                ticketSubject:'',
                ticketMailID:'',
                show: true,
                basicTitle:"Successfully discarded mails",
                basicType:"success",
                totalDrafts:0
            });
            
        })
    })
    .catch(function(e){
        console.log("Error ",e)
    })
}

onClickDraftItem = (params) =>{
    //console.log("params ", params)
    var reqparams={
        rowId:params._id
    }
    redirectURL.post("/consignments/importCoilEmailInfo", reqparams)
    .then((response) => {
        console.log("Draft ", response.data)
        var records = response.data
        this.setState({
            emailformat:records,
            toEmail:records[0].toEmail,
            ccEmail:records[0].ccEMail,
            ticketEmailContent:records[0].body,
            ticketSubject:records[0].subject,
            ticketMailID:records[0]._id,
            ticketslist:records[0].ticket_numbers,
            showdiscard:'show-m'
        });
    })
}

onClickSaveMailNow = async (e) =>{
    e.preventDefault();
    var mailData = await {
        ticketId: this.state.ticketMailID,
        tickets:this.state.ticketslist,
        toEmail:this.state.toEmail,
        ccEmail:this.state.ccEmail,
        ticketEmailContent:this.state.ticketEmailContent,
        ticketSubject:this.state.ticketSubject,
        userId : localStorage.getItem('userid')
    }
    //console.log("mailData ", this.state.ticketEmailContent)
    //console.log("mailData Userid ", mailData)
    setTimeout(
        await redirectURL.post("/consignments/saveImportsCoilMail",mailData)
    .then((response) => {
        this.setState({					
            show: true,
            basicTitle:"Mail saved successfully",
            basicType:"success"
        });
        //window.location.reload();
    }), 1000);
    
}

onClickSaveAndSendMailNow(){
    console.log("this.state.toEmail ", this.state.toEmail)
    if(this.state.toEmail != "")
    {
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        });
        if(Array.isArray(this.state.toEmail))
        {
            var tickmail = this.state.toEmail;
        }
        else
        {
            var tmas = this.state.toEmail.split(",")
            var tomalarr = [];
            if(tmas.length > 0)
            {
                tmas.forEach((item) => {
                    tomalarr.push(item)
                })
            }
            var tickmail = tomalarr;
        }

        if(Array.isArray(this.state.ccEmail))
        {
            var cctickmail = this.state.ccEmail;
        }
        else
        {
            var cctickmail = [this.state.ccEmail];
        }
        var mailData = {
            ticketId: this.state.ticketMailID,
            toEmail:this.state.toEmail,
            ccEmail:this.state.ccEmail,
            ticketEmailContent:this.state.ticketEmailContent,
            ticketSubject:this.state.ticketSubject,
            //mail_escalation:this.state.mail_escalation,
            userId : localStorage.getItem('userid')
        }
        console.log("mailData ", mailData)
        //console.log("mailData Userid ", localStorage.getItem('userid'))
        redirectURL.post("/consignments/saveAndSendImportsCoilMail",mailData)
        .then((response) => {
            //console.log(" response ", response)
            this.setState({					
                bulkslide:'',
                showleftmailside:'show-n',
                sendmaildiv:"col-xl-12",
                loadshow:'show-n',
                overly:'show-n',
                emailformat:[],
                toEmail:'',
                ccEmail:'',
                ticketEmailContent:'',
                ticketSubject:'',
                ticketMailID:'',
                show: true,
                basicTitle:response.data.message,
                basicType:"success"
            });


            // redirectURL.post("/consignments/importsCoilDraftTickets")
            // .then((response) => {
            //     //console.log("response.data ", response.data)
            //     if(response.data)
            //     {
            //         this.setState({
            //             totalDrafts:response.data.length
            //         });
            //     }
            // });

            
            
        })
    }
    else{
        this.setState({					
            show: true,
            basicTitle:"To Mail Should not be empty",
            basicType:"danger"
        });
    }
}

onClickViewMailItem = (params) =>{
    console.log("params ", params)
    var reqparams={
        rowId:params._id,
    }
    redirectURL.post("/consignments/viewImportsCoilEmailData", reqparams)
    .then((response) => {
        console.log("Draft ", response.data)
        var records = response.data;
        if(records.length > 0)
        {
            this.setState({
                emailformat:records,
                toEmail:records[0].toEmail,
                ccEmail:records[0].ccEMail,
                ticketEmailContent:records[0].body,
                ticketSubject:records[0].subject,
                ticketMailID:records[0]._id,
                ticketslist:records[0].ticket_numbers,
                //showdiscard:'show-m',
                viewcols:"col-xl-3 col-md-6",
                viewshow:'show-m',
                mailresponse:response.data.mailresponse
            });
        }
    })
}

onClickDiscardMail(){
    if(Array.isArray(this.state.toEmail))
    {
        var tickmail = this.state.toEmail;
    }
    else
    {
        var tmas = this.state.toEmail.split(",")
        var tomalarr = [];
        if(tmas.length > 0)
        {
            tmas.forEach((item) => {
                tomalarr.push(item)
            })
        }
        var tickmail = tomalarr;
    }

    if(Array.isArray(this.state.ccEmail))
    {
        var cctickmail = this.state.ccEmail;
    }
    else
    {
        var cctickmail = [this.state.ccEmail];
    }
    var mailData = {
        ticketId: this.state.ticketMailID,
        tickets:this.state.ticketslist,
        toEmail:tickmail,
        ccEmail:cctickmail,
        ticketEmailContent:this.state.ticketEmailContent,
        ticketSubject:this.state.ticketSubject,
        userId : localStorage.getItem('userid')
    }
    //console.log("mailData ", mailData)
    //console.log("mailData Userid ", localStorage.getItem('userid'))
    redirectURL.post("/consignments/discardImportsCoilMail",mailData)
    .then((response) => {
        //console.log(" response ", response)
        var old = this.state.totalDrafts
        if(response.data.ok == 1)
        {
            this.setState({					
                bulkslide:'',
                showleftmailside:'show-n',
                sendmaildiv:"col-xl-12",
                overly:'show-n',
                emailformat:[],
                toEmail:'',
                ccEmail:'',
                ticketEmailContent:'',
                ticketSubject:'',
                ticketMailID:'',
                show: true,
                basicTitle:response.data.message,
                basicType:"success",
                totalDrafts:(old-1)
            });
        }
        else
        {
            this.setState({					
                bulkslide:'',
                showleftmailside:'show-n',
                sendmaildiv:"col-xl-12",
                overly:'show-n',
                emailformat:[],
                toEmail:'',
                ccEmail:'',
                ticketEmailContent:'',
                ticketSubject:'',
                ticketMailID:'',
                show: true,
                basicTitle:response.data.message,
                basicType:"danger"
            });
        }
        
        
        //window.location.reload();
    })
}

onClickHideAll(){
    this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:"",
        showleftmailside:'show-n',
        sendmaildiv:"col-xl-12",
        overly:'show-n',
        emailformat:[],
        toEmail:'',
        ccEmail:'',
        ticketEmailContent:'',
        ticketSubject:'',
        ticketMailID:'',
        viewmailSlider:"",
        viewcols:"col-xl-12 col-lg-12",
        viewshow:'show-n',
    });
    
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}
showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate"
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}
changeFileHandler = async (e) => {
    var dt = '';
    const config = {
        headers: [
            { 
                name: 'id',
                inputName: 'id',
                required: false,
            },
            { 
                name: 'Vehicle Type',
                inputName: 'vehicle_cap',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Vehicle Capacity (CMT)',
                inputName: 'vehicle_capacity',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Min Capacity (CMT)',
                inputName: 'min_capacity_cmt',
                required: false,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Max Capacity (CMT)',
                inputName: 'max_capacity_cmt',
                required: false,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Min CMT per Consignee',
                inputName: 'min_courier',
                required: false,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Maximum Nodes',
                inputName: 'nodes_per_truck',
                required: false,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Max Distance',
                inputName: 'allowed_distance',
                required: false,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Telescopic Route',
                inputName: 'telescopic_route',
                required: false,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },          
        ]
    }
    var data = CSVFileValidator(e.target.files[0], config)
    .then((csvData) => {
        this.setState({
            csvData:csvData.data
        });
        console.log(csvData.data);
    })
    .catch(err => {})
    // console.log("e.target.files[0]",e.target.result);
    
    var out = new Promise(function(reject, resolve){
        var reader =  new FileReader();
        reader.onload = async function(e) {
            var contents = await e.target.result;
            resolve(contents);
        };
        var tt =  reader.readAsText(e.target.files[0]);
    });
    this.setState({
        file:e.target.files[0]
    });
    // console.log("e.target.files[0].type ", e.target.files[0]);

    if(e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
    {
        // console.log(fileData);
        // console.log(typeof(fileData))
        
    }
    else{
        e.target.value = null;
        this.setState({
            uploadFile:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'Please upload file having extensions .csv only.',
        });
    }
    
}

uploadBulkFormHandler(event){
    event.preventDefault();
    var fileData = this.state.csvData;
    var flag=0;
    console.log(fileData,"fileData");
    
    if(flag == 0)
    {
        if(fileData.length > 0)
        {
            var loading_port_code = localStorage.getItem("loading_port_code");
            var reqParams = {
                csvData : fileData,
                loading_port_code : loading_port_code
            }
            console.log("reqParams",reqParams)
            redirectURL.post("/lmconsignees/savebulklmvehiclecapacity",reqParams).then((response)=>{
                var resp=response.data.message;
                console.log(resp)
                this.setState({
                    basicTitle : resp,
                    basicType : "success",
                    show : true,
                    bulkslide:'',
                    overly:'show-n',
                })
                //window.location.reload();
                this.onloadPageData();
            }).catch((e)=>{
                console.log(e);
            })
        }
        else
        {
            this.setState({
                basicTitle : "Invalid File",
                basicType : "warning",
                show : true,
            })
        }
        $('#upform')[0].reset();
    }
    else
    {
        $("#bulkUploadID").val();
        this.setState({
            basicTitle : "Fields Should not be Empty",
            basicType : "warning",
            show : true,
        })
        $('#upform')[0].reset();
    }
};

onClickTab(pageType){
    if(pageType == "partial")
    {
        var partial = "activet";
        var complete = "";
        var partialData = "";
        var completeData = "show-n";
        this.loadPageData(1);
    }
    else{
        var partial = "";
        var complete = "activet";
        var partialData = "show-n";
        var completeData = "";
        this.loadPageData(2);
    }
    this.setState({
        partial: partial,
        complete: complete,
        partialData: partialData,
        completeData: completeData,
    })
}
  render() {
    
    var columnwithDefs = [
        {
            headerName : "",
            field : "sendEmail",
            width:100,
            editable : false,
            pinned:"left",
            cellRenderer: function(params){
                return "<button class='btn btn-info' style='padding:0px;width:76px'>Send Mail</button>"
            }
        },
        {
            headerName : "",
            field : "emailLogs",
            width:50,
            editable : false,
            pinned:"left",
            cellRenderer: function(params){
                return "<i class='icofont icofont-envelope f20' style=''></i>"
            }
        },
        {
            headerName: "Ship Name",
            field: "ship_name",
            width:180,
            filter: true,
            resizable: true,
            editable:false,            
        },
        {
            headerName: "Voyage No",
            field: "voyage_no",
            width:130,
            filter: true,
            resizable: true,
            editable:false,            
        },
        {
            headerName: "Vessel Status",
            field: "last_status",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Gross Weigtht",
            field: "gross_weight",
            width:130,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "No. of BL's",
            field: "no_of_bls",
            width:130,
            filter: true,
            resizable: true,
            editable:false,
            cellClass:"cellstylegrid"
        },
        {
            headerName: "No. of Coils",
            field: "no_of_coils",
            width:130,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "No. of Coils Punched",
            field: "no_of_coils_invoiced",
            width:140,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Last port of Japan",
            field: "last_japan_port",
            width:130,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Last Port In / Out of Japan",
            field: "last_japan_port_in_out",
            width:130,
            filter: true,
            resizable: true,
            editable:false,
            cellStyle:function(params){
                var start = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
                var end = moment.parseZone(params.data.last_japan_port_in_out).format('YYYY-MM-DD HH:mm:ss');
                //console.log(start,end,"strtend")
                //Difference in number of days
                var diffreetime = moment.duration(moment(start).diff(end));
                var difference =Math.round(diffreetime.asDays());
                //console.log(difference,"diff")
                if(parseInt(difference) <= 15)
                {
                    return {backgroundColor: 'green'};
                }
                else if(parseInt(difference) > 15 && parseInt(difference) <= 22)
                {
                    return {backgroundColor: 'yellow'};
                }
                else if(parseInt(difference) > 22)
                {
                    return {backgroundColor: 'red'};
                }
            },
            valueGetter:function(params){
                if(params.data.last_japan_port_in_out !=undefined && params.data.last_japan_port_in_out !='')
                {
                    return getHyphenYYYYMMDDHHMMSS(params.data.last_japan_port_in_out);
                }
            }
        },
        {
            headerName: "Last Port of Japan Transit status",
            field: "last_japan_transit_status",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "ATA at Indian Port",
            field: "ata_indian_port",
            width:130,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter:function(params){
                if(params.data.ata_indian_port !=undefined && params.data.ata_indian_port !='')
                {
                    return getHyphenYYYYMMDDHHMMSS(params.data.ata_indian_port);
                }
            }
        },
        {
            headerName: "ETA at Indian Port",
            field: "expected_port_in",
            width:130,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter:function(params){
                if(params.data.expected_port_in !=undefined && params.data.expected_port_in !='')
                {
                    return getHyphenYYYYMMDDHHMMSS(params.data.expected_port_in);
                }
            }
        },
        // {
        //     headerName: "Partial Invoiced",
        //     field: "partial_invoiced",
        //     width:160,
        //     filter: true,
        //     resizable: true,
        //     editable:false,
        // }
    ]
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="card col-xl-12">
            <div className="card-header">
                <h5>
                    <i className="icofont icofont-truck-loaded cus-i"></i> <span>Imports Coil Tracking</span>
                </h5>
            </div>
            <div className="card-body col-xl-12 col-lg-12">
            <div className="f16" style={{marginBottom:"10px"}}>
                    <ul className="d-tabs">

                        <li onClick={this.onClickTab.bind(this,"partial")} className={"lttabs "+(this.state.partial)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"partial")}>Partial Invoiced</button>
                        </li>

                        <li onClick={this.onClickTab.bind(this,"complete")} className={"lttabs "+(this.state.complete)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"complete")}>Complete Invoiced</button>
                        </li>
                    </ul>
                </div>
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.showGridData}>    
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    masterDetail={true}
                    detailCellRendererParams={this.state.detailCellRendererParams}
                    overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClicked}
                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                    />
            </div>
            </div>
        </div>

        
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <div className="slide-r-body" style={{position:"relative"}}>
            <div className="container-fluid">
                {/* Inbox Start  */}
                <div className="email-wrap">
                    <div className="row">
                        <div className={"col-xl-3 col-md-6 "+(this.state.showleftmailside)}>
                            <div className="email-right-aside">
                                <div className="card email-body">
                                    <div className="pr-0 b-r-light">
                                        <div className="email-top n-p-0">
                                            <div className="row">
                                                <div className="col">
                                                    <h5>
                                                        Draft
                                                        <button type="button"
                                                            onClick={this.onClickDiscardAllMail.bind(this)}
                                                                className={"btn custom-btn text-center mb-0 mt-0 mr-5p fright "}>
                                                            <i className="fa fa-trash mr-2"></i> DISCARD ALL
                                                        </button>
                                                    </h5>
                                                </div>
                                                
                                            </div>
                                        </div>

                                        <div className="inbox">
                                            {this.state.draftlist.map((item, index) => (
                                            
                                                <div className="media cpointer brd-top" onClick={this.onClickDraftItem.bind(this,item)}>
                                                    <div className="media-body">
                                                        <h6 className="mt-0" style={{textAlign:"left"}}>
                                                            {item.subject}
                                                        </h6>
                                                    </div>
                                                </div>
                                            ))}
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={" col-md-12 "+(this.state.sendmaildiv)}>
                            <div className="email-right-aside">
                                <div className="card email-body radius-left">
                                    <div className="pl-0">
                                        <TabContent className="tab-content" activeTab={this.state.SelectedTab}>
                                            <TabPane tabId="1">
                                                    <div className="email-compose">
                                                        <div className="email-top compose-border n-p-0">
                                                            <div className="row">
                                                                <div className="col-sm-5 xl-30">
                                                                    <h4 className="mb-0">Send Mail</h4>
                                                                </div>
                                                                <div className="col-sm-7 btn-middle xl-70">
                                                                    {/* <button type="button"
                                                                        onClick={this.onClickSaveMailNow.bind(this)}
                                                                            className="btn btn-secondary btn-mail text-center mb-0 mt-0 fright">
                                                                        <i className="fa fa-save mr-2"></i> SAVE
                                                                    </button> */}
                                                                    <button type="button"
                                                                        onClick={this.onClickSaveAndSendMailNow.bind(this)}
                                                                            className="btn btn-primary btn-mail text-center mb-0 mt-0 mr-5p fright">
                                                                        <i className="fa fa-paper-plane mr-2"></i> SEND
                                                                    </button>
                                                                    {/* <button type="button"
                                                                        onClick={this.onClickDiscardMail.bind(this)}
                                                                            className={"btn btn-square btn-outline-danger-2x btn-mail red text-center mb-0 mt-0 mr-5p fright "+(this.state.showdiscard)}>
                                                                        <i className="fa fa-trash mr-2"></i> DISCARD
                                                                    </button> */}
                                                                    
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="email-wrapper">
                                                            <form className="theme-form">
                                                                <div className=" row form-group">
                                                                    <label htmlFor="exampleInputEmail1"
                                                                        className="col-form-label pt-0 col-xl-2 col-lg-2">To</label>
                                                                    <input type="email" className="form-control col-xl-10 col-lg-10"
                                                                        id="exampleInputEmail1" name="toEmail" value={this.state.toEmail}
                                                                        onChange={this.onChangeHandler} aria-describedby="emailHelp" />
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label htmlFor="exampleInputEmail1"
                                                                        className="col-form-label pt-0 col-xl-2 col-lg-2">Cc</label>
                                                                    <input type="email" className="form-control col-xl-10 col-lg-10"
                                                                        id="exampleInputEmail1" name="ccEmail" value={this.state.ccEmail}
                                                                            onChange={this.onChangeHandler} aria-describedby="emailHelp" />
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label
                                                                        htmlFor="exampleInputPassword1" className=" col-xl-2 col-lg-2">Subject</label>
                                                                    <input type="text" className="form-control col-xl-10 col-lg-10" value={this.state.ticketSubject}
                                                                        onChange={this.onChangeHandler} name="ticketSubject" id="exampleInputPassword1" />
                                                                </div>
                                                                <div className="form-group mb-0">
                                                                    <label className="text-muted">Message</label>
                                                                        <CKEditor
                                                                        min-height="500px"
                                                                            config={ {height : "400px"}}
                                                                            activeClass="p10"
                                                                            content={this.state.ticketEmailContent}
                                                                            events={{
                                                                                "blur": this.onBlur.bind(this),
                                                                                "afterPaste": this.afterPaste.bind(this),
                                                                                "updateContent": this.updateContent.bind(this)
                                                                            }}
                                                                        />
                                                                </div>
                                                                
                                                            </form>
                                                        </div>
                                                    </div>
                                            </TabPane>
                                            
                                        </TabContent>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Inbox End  */}


            </div>
            </div>
        </div>

        {/* View Mails Slider */}
        <div className={"slide-r "+(this.state.viewmailSlider)} style={{overflow:"hidden"}}>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    {/* Inbox Start  */}
                    <div className="email-wrap">
                        <div className="row">
                            <div className={(this.state.viewcols)+" "+(this.state.showleftmailside)}>
                                <div className="email-right-aside">
                                    <div className="card email-body">
                                        <div className="pr-0 b-r-light">
                                            <div className="email-top n-p-0">
                                                <div className="row">
                                                    <div className="col">
                                                        <h5>
                                                            Mails History
                                                        </h5>
                                                    </div>
                                                    
                                                </div>
                                            </div>

                                            <div className="inbox">
                                                {this.state.emaillist.map((item, index) => (
                                                    
                                                    <div className={"media cpointer brd-top "+((index%2 == 0)?"":"maileven")} onClick={this.onClickViewMailItem.bind(this,item)}>
                                                        <div className="media-body">
                                                            <h6 className="mt-0" style={{textAlign:"left"}}>
                                                                {item.subject}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                ))}
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={" col-md-12 "+(this.state.sendmaildiv)+" "+(this.state.viewshow)}>
                                <div className="email-right-aside">
                                    <div className="card email-body radius-left">
                                        <div className="pl-0">
                                            <TabContent className="tab-content" activeTab={this.state.SelectedTab}>
                                                <TabPane tabId="1">
                                                        <div className="email-top compose-border n-p-0">
                                                            <div className="row">
                                                                <div className="col-sm-12 xl-100">
                                                                    <h4 className="mb-0">
                                                                        {(this.state.ticketSubject != '')?this.state.ticketSubject:"NA"}
                                                                    </h4>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="email-compose mb-70p">
                                                            
                                                            <div className="email-wrapper mb-60p">
                                                                <form className="theme-form">
                                                                    <div className=" row form-group">
                                                                        <label htmlFor="exampleInputEmail1"
                                                                            className="col-form-label pt-0 col-xl-2 col-lg-2">To</label>
                                                                        <div className="col-xl-10 col-lg-10">
                                                                            {(this.state.toEmail != '')?this.state.toEmail:"NA"}
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label htmlFor="exampleInputEmail1"
                                                                            className="col-form-label pt-0 col-xl-2 col-lg-2">Cc</label>
                                                                        <div className="col-xl-10 col-lg-10">
                                                                            {(this.state.ccEmail != '')?this.state.ccEmail:"NA"}
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="form-group mb-0">
                                                                        <label className="text-muted">Message</label>
                                                                        <div  className="col-xl-12 col-lg-12">
                                                                            <CKEditor
                                                                                min-height="500px"
                                                                                config={ {height : "500px",width:"100%"}}
                                                                                activeClass="p10"
                                                                                content={this.state.ticketEmailContent}
                                                                                events={{
                                                                                    "blur": this.onBlur.bind(this),
                                                                                    "afterPaste": this.afterPaste.bind(this),
                                                                                    "updateContent": this.updateContent.bind(this)
                                                                                }}
                                                                            />
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                </TabPane>
                                                
                                            </TabContent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Inbox End  */}


                </div>
            </div>
        </div>
        {/* View Mails Slider */}


        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};
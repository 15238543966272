/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
// import ReactDOM from "react-dom";
// import _ from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from "@ag-grid-community/react";
import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import Select from "react-select";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import "react-vertical-timeline-component/style.min.css";
// import Modal from "react-responsive-modal";
import * as Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import $ from "jquery";
// import 'jquery-ui/ui/core';
// import 'jquery-ui/ui/widgets/datepicker';
import CSVFileValidator from "csv-file-validator";
import UpdateButton from "../layouts/updatelmstates";
import TransitReturnChart from "./transitreturnchartdashboard";
import LoadSummaryColumnChart from "../layouts/loadSummaryColumnChart";
import BiTransitChartComponent from "../layouts/bitransitchartcomponent";
import { verifyext, verifycsvdata } from "../common/verifyext";
import {
  getDDMMYYYY,
  getHyphenYYYYMMDD,
  getHyphenDDMMMYYYYHHMM,
  getHyphenDDMMMYYYY,
  getHyphenYYYYMMDDHHMMSS,
  getHyphenDDMMYYYYHHMMSS,
  getDDMMYYYYHHMMSS,
  getYYYYMMDD,
  getYYYYMMDDHHMMSS,
} from "../common/utils";
import functions from "material-ui/svg-icons/editor/functions";

var moment = require("moment");
var redirectURL = require("../redirectURL");
var googleAnalytics = require("../common/googleanalytics");
window.jQuery = $;
window.$ = $;
var googleAnalytics = require("../common/googleanalytics");

export default class BiTransitReturns extends Component {
  state = {
    rowData: [],
    modules: AllModules,
    defaultColDef: {
      sortable: true,
      filter: true,
      editable: false,
    },
    eventAction: null,
    rowSelection: "multiple",
    rowModelhighchartoptionsType: "enterprise",
    rowGroupPanelShow: "always",
    animateRows: true,
    statusBar: {
      statusPanels: [
        {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agTotalRowCountComponent",
          align: "center",
        },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    },
    paginationPageSize: 200,
    sideBar: {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
      ],
    },
    frameworkComponents: {
      UpdateButton: UpdateButton,
    },
    components: { datePicker: getDatePicker() },
    basicTitle1: "",
    basicType1: "default",
    show: false,
    basicTitle: "",
    basicType: "default",
    loadshow: "show-n",
    sliderTranslate: "",
    uploadFile: "",
    monthly_wholesale: "",
    cost_of_dismantle: "",
    file: "",
    chartActive: "btn-info",
    gridActive: "btn-defaultc",
    showGridData: "show-n",
    t_names: [],
    t_codes: [],
    t_pair: [],
    chartRowData1: [],
    chartRowData2: [],
    showChartGridData1: "show-m",
    chartColumnWithDef1: [],
    chartColumnWithDef2: [],
    distinctFyYears: [],
    transitBiChart: [],
    transitBiChart2: [],
    brm_month: '',
    chartseries2:[],
    chartseries3:[],
    chartcategories:[],
    chartcategories1:[],
    barDamageReportData :[],
    barDamageReportCategories:[],
    splineSeriesData: {},
    totalReturnsData:[],
    barCategories:[]
  };


  componentDidMount() {
    this.setState({
      loadshow: "show-n",
    });
    var params = {
      userid: this.state.userid,
      useremail: localStorage.getItem("email"),
    };
    // var year1 = moment.parseZone().format("YYYY")
    // var year2 = moment.parseZone().add(12,'months').format('YYYY')
    // console.log(year1,"line 136")
    // console.log(year2,"line 138")
    // var cDate1 = year1 +"-"+year2;
    // console.log(cDate1 ,"line 140")
    this.getTransporterList();
    this.fetchChartData();
    this.fetchGridData();
  }

  // =======get Transporter list ======= //
  getTransporterList = (param) => {
    let qry = {
      dept_code: "SNDG",
    };
    // console.log("qry", qry);
    redirectURL.post("/masters/getAllTransporters", qry).then((resp) => {
      let respData = resp.data;
      let t_names = [];
      let t_codes = [];
      let t_pair = [];
      respData.map((e) => {
        t_names.push(e.transporter_name);
        t_codes.push(e.transporter_code);
        t_pair.push({
          transporter_name: e.transporter_name,
          transporter_code: e.transporter_code,
        });
      });
      this.setState({
        t_names,
        t_codes,
        t_pair,
      });

      // console.log("response", t_pair);
    });
    redirectURL.post("/dashboard/getDistinctFyYears").then((res) => {
      let data = res.data.options;
      // console.log("data", data);
      this.setState({
        distinctFyYears: data,
      });
    });
  };
  // ========  onclick showing the side bar ======== //
  onClickShowSidebar = () => {
    this.setState({
      sliderTranslate: "slider-translate",
      overly: "show-m",
    });
  };

  onClickHideAll = () => {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      sliderTranslate: "",
    });
  };

  closeAlert = () => {
    this.setState({
      show: false,
    });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
  };

  onGridState = () => {
    //console.log(this.gridApi);
    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();
    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
    /*Get Current Pivot Columns State and Store in this.pivotColumns,
     * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
  };

  changeHandler = (event) => {
    console.log("Select", event.target.value);
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
  };

  // ====== validating the csv file on insert into input field ========== //
  changeFileHandler = async (e) => {
    var dt = "";
    const config = {
      headers: [
        {
          name: "rtgp_no",
          inputName: "rtgp_no",
          required: true,
        },
        {
          name: "trip_no",
          inputName: "trip_no",
          required: true,
        },
        {
          name: "rtgp_date",
          inputName: "rtgp_date",
          required: true,
        },
        {
          name: "bveh",
          inputName: "bveh",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "prefix",
          inputName: "prefix",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "chass_no",
          inputName: "chass_no",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "brm_month",
          inputName: "brm_month",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "reason",
          inputName: "reason",
          required: true,
        },
        {
          name: "resp",
          inputName: "resp",
          required: true,
        },
        {
          name: "resp_trtr_name",
          inputName: "resp_trtr_name",
          required: true,
        },
        {
          name: "cate",
          inputName: "cate",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "source",
          inputName: "source",
          required: true,
        },
        {
          name: "invc_amt",
          inputName: "invc_amt",
          required: true,
        },
        {
          name: "dept",
          inputName: "dept",
          required: true,
        },
        {
          name: "trtr",
          inputName: "trtr",
          required: true,
        },
        {
          name: "trtr_name",
          inputName: "trtr_name",
          required: true,
        },
        {
          name: "trtp",
          inputName: "trtp",
          required: true,
        },
        {
          name: "regn",
          inputName: "regn",
          required: true,
        },
        {
          name: "induct_date",
          inputName: "induct_date",
          required: true,
        },
        {
          name: "for",
          inputName: "for",
          required: true,
        },
        {
          name: "for_desc",
          inputName: "for_desc",
          required: true,
        },
        {
          name: "invc_date",
          inputName: "invc_date",
          required: true,
        },
        {
          name: "acc_date",
          inputName: "acc_date",
          required: true,
        },
        {
          name: "acc_time",
          inputName: "acc_time",
          required: true,
        },
        {
          name: "acc_place",
          inputName: "acc_place",
          required: true,
        },
        {
          name: "acc_state",
          inputName: "acc_state",
          required: true,
        },
        {
          name: "regn",
          inputName: "regn",
          required: true,
        },
        {
          name: "acdt_detail",
          inputName: "acdt_detail",
          required: true,
        },
        {
          name: "actual_carri",
          inputName: "actual_carri",
          required: true,
        },
        {
          name: "vehicle_type",
          inputName: "vehicle_type",
          required: true,
        },
        {
          name: "act_tCode",
          inputName: "act_tCode",
          required: true,
        },
        {
          name: "act_transporter",
          inputName: "act_transporter",
          required: true,
        },
        {
          name: "act_type",
          inputName: "act_type",
          required: true,
        },
        {
          name: "resp",
          inputName: "resp",
          required: true,
        },
        {
          name: "resp_trtr_name",
          inputName: "resp_trtr_name",
          required: true,
        },
        {
          name: "catag_date",
          inputName: "catag_date",
          required: true,
        },
        {
          name: "damage_remarks",
          inputName: "damage_remarks",
          required: true,
        },
        {
          name: "inv_freight_amt",
          inputName: "inv_freight_amt",
          required: true,
        },
        {
          name: "pod_freight_amt",
          inputName: "pod_freight_amt",
          required: true,
        },
        {
          name: "penality_amt",
          inputName: "penality_amt",
          required: true,
        },
        {
          name: "acc_status",
          inputName: "acc_status",
          required: true,
        },
        {
          name: "pod_status",
          inputName: "pod_status",
          required: true,
        },
      ]

    };
    var data = CSVFileValidator(e.target.files[0], config)
      .then((csvData) => {
        console.log(csvData);
        this.setState({
          csvData: csvData.data,
        });
        console.log("csvData", csvData.data);
      })
      .catch((err) => { });
    // console.log("e.target.files[0]",e.target.result);

    var out = new Promise(function (reject, resolve) {
      var reader = new FileReader();
      reader.onload = async function (e) {
        var contents = await e.target.result;
        resolve(contents);
      };
      var tt = reader.readAsText(e.target.files[0]);
    });
    this.setState({
      file: e.target.files[0],
    });
    //console.log("e.target.files[0].type ", e.target.files[0]);
    var check = verifyext(e.target.files[0].name);
    //console.log("Check ", check)
    if (check == true) {
      if (
        e.target.files[0].type == "" ||
        e.target.files[0].type == "text/csv" ||
        e.target.files[0].type == "application/vnd.ms-excel"
      ) {
        // console.log(fileData);
        // console.log(typeof(fileData))
      } else {
        e.target.value = null;
        this.setState({
          uploadFile: "",
          show: true,
          basicType: "danger",
          basicTitle: "Please upload file having extensions .csv only.",
        });
      }
    } else {
      e.target.value = null;
      this.setState({
        uploadFile: "",
        show: true,
        basicType: "danger",
        basicTitle: "Please upload file having extensions .csv only.",
      });
    }
  };

  // ======uploading form data  ======= //
  formUploadData = (event) => {
    event.preventDefault();
    const { file, csvData, brm_month } = this.state;
    // console.log("uploadFile",file)
    let monthly_wholesale = $('#monthly_wholesale').val()
    // let cost_of_dismantle = $('#cost_of_dismantle').val()
    // let un_returned = $('#un_returned').val()
    // console.log("csvData",csvData)
    // console.log("brm_month", brm_month)
    if (brm_month !== "" && brm_month !== undefined) {
      let saveData = new FormData();
      // saveData.append("brm_file", file);
      saveData.append("csvData", JSON.stringify(csvData));
      saveData.append('monthly_wholesale', monthly_wholesale)
      // saveData.append('cost_of_dismantle', cost_of_dismantle)
      saveData.append('brm_month', brm_month)
      this.setState({
        overly : 'show-m',
        loadshow : "show-m"
      })

      redirectURL.post("/dashboard/brmFormUploadData", saveData, {
        headers: {
          "content-type": "multipart/form-data",
          // "Access-Control-Allow-Origin": "*",
          // "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE",
          "Access-Control-Allow-Headers": "Origin, Content-Type",
        },
      }).then((res) => {
        // console.log("res",res)
        if (res.data.status == 200 || res.data.status == '200') {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: res.data.message,
            file: "",
            brm_month: '',            
          });
          this.fetchGridData();
          $('#monthly_wholesale').val(' ')
          // $('#cost_of_dismantle').val(' ')
          // $('#un_returned').val(' ')
          setTimeout(() =>{
            this.fetchChartData();
            this.getTransporterList();
          },2000)
        }
      });
    } else {
      this.setState({
        show: true,
        basicType: "warning",
        basicTitle: "Select Brm Month",
        overly : 'show-n',
        loadshow : "show-n"
      });
    }
  };
  getChartData = (e) => {
    e.preventDefault();
    this.fetchChartData();
  };
  // ======getting Chart data and their grids ======= //
  fetchChartData = async () => {
    let curentDate = moment.parseZone().format("YYYY-MM-DD");
    var selectedFilter = $("#selectedFilter").text();
    // console.log("selectedFilter", selectedFilter);
    if (selectedFilter !== "Select...") {
      var cDate1 = selectedFilter;
    } else {
      cDate1 = "2022-2023";
    }
    let cDate = "2023-03-01";

    //====gets fin year list=====//
    let fyList = getFyList(cDate);
    fyList.unshift("units");
    // console.log("fyList", fyList);

    redirectURL
      .post("/dashboard/getbrmChartgriddata", { currentDate: cDate1 })
      .then((res) => {
        console.log("chartData", res);
        try {
          let rowData = JSON.parse(res.data.Data);
          let rowData1 = JSON.parse(res.data.Data2);
          let rowDataC = JSON.parse(res.data.Data);
          rowData = rowData.filter(e => e.units !== "Cost of Dismantle")
          rowData1 = rowData1.filter(e => e.units !== "Cost of Return")
          rowDataC = rowDataC.filter(e => e.units !== "Cost of Return")
          // console.log("rowData! ", rowData);
          // console.log("rowDataC ", rowDataC);
          let colList = Object.keys(rowData[0]);
          // console.log("colList! ", colList);

          let cat_a_d = [];
          let cat_b_d = [];
          let cat_c_d = [];
          let cat_a_e = [];
          let cat_b_e = [];
          let cat_c_e = [];
          let cat_a_export=[];
          let cat_c_export=[];
          let pending_for_categorized_export=[];
          let domList = [];
          let expList = [];
          let pending_for_categorized = [];
          let un_returned = [];
          rowDataC.forEach((item, index, array) => {
            console.log("item", item, '\n index', index)
            if (index == 8) {
              if (item.units == "A Cat") {
                cat_a_d.push(Object.values(item));
              }
            } else if (index == 9) {
              if (item.units == "B Cat") {
                cat_b_d.push(Object.values(item));
              }
            } else if (index == 10) {
              if (item.units == "C Cat") {
                cat_c_d.push(Object.values(item));
              }
            } else if (index == 11) {
              if (item.units == "Pending for Categorized") {
                pending_for_categorized.push(Object.values(item));
              }
            } else if (index == 4) {
              if (item.units == "Un Returned") {
                un_returned.push(Object.values(item));
              }
            }
            else if (index == 14) {
              if (item.units == "A Cat") {
                cat_a_export.push(Object.values(item));
              }
            }
            else if (index == 15) {
              if (item.units == "C Cat") {
                cat_c_export.push(Object.values(item));
              }
            }
            else if (index == 16) {
              if (item.units == "Pending for Categorized") {
                pending_for_categorized_export.push(Object.values(item));
              }
            }
          });
          var categories = Object.keys(rowData[0]);
          categories.shift();
          console.log("658", categories);
          console.log(
            "cat_a_d",
            cat_a_d,
            "\n cat_b_d",
            cat_b_d,
            "\n cat_c_d",
            cat_c_d,
            "\n cat_a_e",
            cat_a_e,
            "\n cat_a_ex",
            cat_c_export,
            "\n cat_c_ex",
            cat_a_export,
            "\n pending_for_cat",
            pending_for_categorized_export
          );
          var cat_a_dL = cat_a_d[0].slice(1, 14);
          var cat_b_dL = cat_b_d[0].slice(1, 14);
          var cat_c_dL = cat_c_d[0].slice(1, 14);
          var cat_a_exp = cat_a_export[0].slice(1,14);
          var cat_c_exp = cat_c_export[0].slice(1,14);
          var pending_for_categorized_exp = pending_for_categorized_export[0].slice(1,14);
          // var cat_a_eL = cat_a_e[0].slice(1, 15);
          // var cat_c_eL = cat_c_e[0].slice(1, 15);
          var pending_for_categorizedL = pending_for_categorized[0].slice(1, 14);
          var un_returnedL = un_returned[0].slice(1, 14);
          // console.log(
          //   "cat_a_d",
          //   cat_a_dL,
          //   "cat_b_d",
          //   cat_b_dL,
          //   "cat_c_d",
          //   cat_c_dL,
          //   "cat_a_e"
          //   // cat_a_eL
          // );
          // console.log(
          //   "cat_c_e",
          //   // cat_c_eL,
          //   "pending_for_categorized",
          //   pending_for_categorizedL,
          //   "un_returned",
          //   un_returnedL
          // );

          var series = [
            {
              name: "A Cat",
              type: "column",
              data: cat_a_dL,
              color: "#7bcbed",
              dataLabels: {
                enabled: true,
              },
            },
            {
              name: "B Cat",
              type: "column",
              data: cat_b_dL,
              color: "#c88e60",
              dataLabels: {
                enabled: true,
              },
            },
            {
              name: "C Cat",
              type: "column",
              data: cat_c_dL,
              color: "#e2d9cb",
              dataLabels: {
                enabled: true,
              },
            },
            // {
            //   name: "B Cat(E)",
            //   type: "column",
            //   data: cat_b_e,
            //   color: "#9f7bed",
            //   dataLabels: {
            //     enabled: true,
            //   },
            // },
            // {
            //   name: "C Cat(D)",
            //   type: "column",
            //   color: "#3f0c67",
            //   data: cat_c_dL,
            //   dataLabels: {
            //     enabled: true,
            //   },
            // },
            // {
            //   name: "C Cat(E)",
            //   type: "column",
            //   color: "#de7bed",
            //   data: cat_c_eL,
            //   dataLabels: {
            //     enabled: true,
            //   },
            // },
            {
              name: "Pending For Categorized",
              type: "column",
              color: "#3f0c67",
              data: pending_for_categorizedL,
              dataLabels: {
                enabled: true,
              },
            },
            // {
            //   name: "Un Returned",
            //   type: "column",
            //   color: "#d1d143",
            //   data: un_returnedL,
            //   dataLabels: {
            //     enabled: true,
            //   },
            // },
          ];
          var transitTimesData = this.state.rowData
          console.log(transitTimesData,"763")
          let transitBiChart = [
            {
              series: [{
                  name: 'Fy 21-22',
                  colorByPoint: true,
                  data: [{
                    name: 'A category',
                    y: cat_a_dL[0],
                    color: "#7CB5EC",
                  },{
                    name: 'B category',
                    y: cat_b_dL[0],
                    color: "#434348",
                  },{
                    name: 'C category',
                    y: cat_c_dL[0],
                    color: "#90ED7D",
                  },
                  {
                    name: 'Pending For Categorized',
                    y: pending_for_categorizedL[0],
                    color: "#F7A35C",
                  }]
              }],
              series1: [{
                name: 'Fy 22-23',
                colorByPoint: true,
                data: [{
                  name: 'A category',
                  y: cat_a_dL[1],
                  color: "#7CB5EC",
                },{
                  name: 'B category',
                  y: cat_b_dL[1],
                  color: "#434348",
                },{
                  name: 'C category',
                  y: cat_c_dL[1],
                  color: "#90ED7D",
                },
                {
                  name: 'Pending For Categorized',
                  y: pending_for_categorizedL[1],
                  color: "#F7A35C",
                }]
            }],
            series2: [{
              name: 'Fy 21-22',
              colorByPoint: true,
              data: [{
                name: 'A category',
                y: cat_a_exp[0],
                color: "#7CB5EC",
              },{
                name: 'C category',
                y: cat_c_exp[0],
                color: "#90ED7D",
              },
              {
                name: 'Pending For Categorized',
                y: pending_for_categorized_exp[0],
                color: "#F7A35C",
              }]
          }],
          series3: [{
            name: 'Fy 22-23',
            colorByPoint: true,
            data: [{
              name: 'A category',
              y: cat_a_exp[1],
              color: "#7CB5EC",
            },{
              name: 'C category',
              y: cat_c_exp[1],
              color: "#90ED7D",
            },
            {
              name: 'Pending For Categorized',
              y: pending_for_categorized_exp[1],
              color: "#F7A35C",
            }]
        }],
              categories: categories,
              xtitle: "",
              ytitle: "",
              yaxistitle: "",
              chartNo: 1,
            },
          ];
          console.log('transitBiChart Series', transitBiChart)
          //====columns data ================//
          var columnDatalist  = JSON.parse(res.data.Data2)
          var filteredData = columnDatalist.filter((f)=>f.units == "Returns")
          console.log(filteredData,"filteredData")
          var barDamageReportData =[];
          var barDamageReportCategories=[];
          var datavalues = transitBiChart[0].categories
          console.log(datavalues,"823")
          datavalues.map((k)=>{
            barDamageReportCategories.push(k)
          })
          console.log(barDamageReportCategories,"827")
          this.setState({barCategories:barDamageReportCategories})
          if(filteredData.length > 0)
          {
              var keys = Object.keys(filteredData[0]);
                var barDamageReportData = [];
                keys.map((k) => {
                    if(k !="units")
                    {
                      barDamageReportCategories.push(k);
                      barDamageReportData.push({
                          "name" : k,
                          "showTooltip" : 1,
                          "y" : filteredData[0][k], 
                      })
                    }
                });
                // barDamageReportData.push(transitDamages)
              console.log(barDamageReportData,"838")
          }
          var splineSeriesData = {"name": "Return Per Lakh", type: "spline", data: []}
          columnDatalist.map((e)=>{
            if(e.units == "Return Per Lakh"){
              var keys = Object.keys(e);
              keys.map((k) => {
                if(k !="units")
                {
                  splineSeriesData['data'].push(e[k])
                }
            });
              
            }
          })
          //======columns =======//
          var columns = [];
          var columns1 = [];
          // console.log("colList", colList)
          colList.forEach((e) => {
            let width = 102;
            let name = e
            if (e == "units"){
               width = 140;
               name = "Units"
            }
            let obj = {
              headerName: name,
              field: e,
              width: width,
              filter: true,
              resizable: true,
              cellStyle: (params) => {
                let item = params.data.units;
                if (item == "Returns" || item == "Returns Per Lakh") {
                  return { background: "#f7f305", color: "#333" };
                } else if (
                  item == "Total Returns" ||
                  item == "Returns Per lakh"
                ) {
                  return { background: "#5fc768", color: "#333" };
                } else if (
                  item == "A Cat" ||
                  item == "B Cat" ||
                  item == "C Cat" ||
                  item == "Pending for Categorized"
                ) {
                  return { background: "#9be0de", color: "#333" };
                } else if (item == "Total") {
                  return { background: "#dab5e8", color: "#333" };
                }
                else if (
                  item == "Cost of Dismantle" ||
                  item == "Cost of Return"
                ) {
                  return { background: "#de5252", color: "#333" };
                }
              },
              editable: (params) => {
                let item = params.data.units;
                if (
                  item == "Pending for Categorized" ||
                  item == "Cost of Dismantle" ||
                  item == "Un Returned" ||
                  item == "Wholesale" || item == 'Total Returns'
                ) {
                  return true;
                } else {
                  return false;
                }
              },
              valueSetter: (args) => {
                let item = args.data.units;
                if (
                  item == "Pending for Categorized" ||
                  item == "Cost of Dismantle" ||
                  item == "Un Returned" ||
                  item == "Wholesale"
                ) {
                  if (args.oldValue !== args.newValue) {
                    // console.log("args", args);
                    // to update in backend on user entry in cell
                    let updateParams = {
                      field: args.data.units,
                      clickedField: args.colDef.field,
                      value: args.newValue,
                    };
                    // console.log("updateParams", updateParams);
                    redirectURL
                      .post("/dashboard/updateBrmChart1gridData", {
                        args: updateParams,
                      })
                      .then((response) => { });

                    let clickedField = args.colDef.field;
                    args.data[clickedField] = args.newValue;
                  }
                }
              },
            };
            let obj1 = {
              headerName: name,
              field: e,
              width: width,
              filter: true,
              resizable: true,
              cellStyle: (params) => {
                let item = params.data.units;
                if (item == "Returns" || item == "Returns Per Lakh") {
                  return { background: "#f7f305", color: "#333" };
                } else if (
                  item == "Total Returns" ||
                  item == "Returns Per lakh"
                ) {
                  return { background: "#5fc768", color: "#333" };
                } else if (
                  item == "A Cat" ||
                  item == "B Cat" ||
                  item == "C Cat" ||
                  item == "Pending for Categorized"
                ) {
                  return { background: "#9be0de", color: "#333" };
                } else if (item == "Total" || item == 'Un Returned') {
                  return { background: "#dab5e8", color: "#333" };
                }
                else if (
                  item == "Cost of Dismantle" ||
                  item == "Cost of Return"
                ) {
                  return { background: "#de5252", color: "#333" };
                }
              },
            };
            columns.push(obj);
            columns1.push(obj1);
          });
          console.log("splineSeriesData", splineSeriesData);
          let totalReturnsData=rowData.find(each=>each.units==="Total Returns")
          let r=Object.values(totalReturnsData)
          r.shift()
          console.log(totalReturnsData,r,"1054")
          if (rowData) {
            this.setState({
              totalReturnsData:r,
              chartRowData1: rowData,
              chartRowData2: rowData1,
              chartColumnWithDef1: columns,
              chartColumnWithDef2: columns1,
              transitBiChart,
              barDamageReportCategories:barDamageReportCategories,
              barDamageReportData:barDamageReportData,
              overly : 'show-n',
              loadshow : "show-n",
              sliderTranslate : "",
              splineSeriesData: splineSeriesData
            });
          } else {
            this.setState({
              rowData: [],
              overly : 'show-n',
              loadshow : "show-n",
              sliderTranslate : ""
            });
          }
        } catch (e) { }
      });
  };

  // ======getting grid data ======= //
  fetchGridData = async () => {
    redirectURL.post("/dashboard/getbrmgriddata", {}).then((res) => {
      if (res.data.message == "success") {
        // console.log("res",res)
        let rowData = res.data.data;
        console.log(rowData,"1005")
        var transitTimesData1 = rowData
        var chartcategories =[];
        var chartseries2 =[];
        var chartseries3 =[];
        var chartcategories1 = [];
        // let transitBiChart2 = [
        //   {
        //   series2: [{
        //     name: 'Fy 22-23',
        //     colorByPoint: true,
        //     data: []
        // }],
        //     categories: categories,
        //     xtitle: "",
        //     ytitle: "",
        //     yaxistitle: "",
        //     chartNo: 1,
        //   },
        // ];
        var filterfinancialData = rowData.filter((e)=>e.fy_year == "2021-2022")
        var causesReturns = groupBy(filterfinancialData, rData => rData.reason.toUpperCase())
        causesReturns.forEach((item,key)=>{
          chartseries2.push({
            name: key,
            y: item.length,
            "showTooltip" : 1,
          })
          chartcategories.push(key)
          this.setState({
            chartseries2:chartseries2,
            chartcategories:chartcategories,
          })
        })
        var filterfinancialData1 = rowData.filter((e)=>e.fy_year == "2022-2023")
        var causesReturns = groupBy(filterfinancialData1, rData => rData.reason.toUpperCase())
        causesReturns.forEach((item,key)=>{
          chartseries3.push({
            name: key,
            y: item.length,
            "showTooltip" : 1,
          })
          chartcategories1.push(key)
          this.setState({
            chartseries3:chartseries3,
            chartcategories1:chartcategories1,
          })
        })
        console.log(chartseries3,"1056")
        console.log(chartcategories1,"1057")

        if (rowData) {
          this.setState({
            rowData,
          });
        } else {
          this.setState({
            rowData: [],
          });
        }
      }
    });
  };

  // ======on clicking changing tabs data ======= //
  onClickTab = (params) => {
    if (params == "chart") {
      this.fetchChartData();
      this.setState({
        chartActive: "btn-info",
        gridActive: "btn-defaultc",
        showGridData: "show-n",
        showChartGridData1: "show-m",
      });
    } else if (params == "grid") {
      this.fetchGridData();
      this.setState({
        chartActive: "btn-defaultc",
        gridActive: "btn-info",
        showGridData: "show-m",
        showChartGridData1: "show-n",
      });
    }
  };

  // =======  update row data on click update ======= //
  updateLmStates = (param) => {
    console.log("param", param)
    // let brm_month = param.brm_month;
    // let c_or_return = param.cost_of_return;
    // let total_dispatch = param.total_dispatch;
    // if (
    //   brm_month == "undefined" &&
    //   brm_month == undefined &&
    //   brm_month !== ""
    // ) {
    //   this.setState({
    //     show: true,
    //     basicType: "warning",
    //     basicTitle: "Please Select Month",
    //   });
    // } else {
    //   param.brm_month_txt = brm_month;
    //   param.brm_month = param.brm_month + "-01";
    //   const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");
    //   let currentMonth = moment(param.brm_month);
    //   let endCheckMonth = moment(endOfMonth);
    //   console.log("param582", param);
    //   let reg = new RegExp('^[0-9]*$');

    //   if (endCheckMonth.diff(currentMonth, "days") < 0) {
    //     this.setState({
    //       show: true,
    //       basicType: "warning",
    //       basicTitle: "Please Select current Month or lesser Month",
    //     });
    //   } else {
    //     if (param.brm_month !== "" || param.reason !== "") {
    //       redirectURL.post("/dashboard/updateBrmTransitreturnRowData", param).then((res) => {
    //         console.log("res", res);
    //         this.setState({
    //           show: true,
    //           basicType: "success",
    //           basicTitle: "Success",
    //         });
    //       });
    //     }
    //   }
    // }
  };

  updateChartOnClick = (e) => {
    e.preventDefault()
    // console.log('params',e)
    redirectURL.post('/dashboard/onClickUpdateTransitBiChart').then(response => {
      console.log("response", response)
      if (response.data.message == 'Success Fully Updated') {
        this.fetchChartData();
      }
    })
  }

  handlerStartDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    var startdate = moment.parseZone(d).format('YYYY-MM')
    // getHyphenYYYYMMDD(d)
    if (startdate !== 'Invalid date') {
      // console.log("startdate",`${startdate}-01`)
      this.setState({
        brm_month: startdate
      });
    } else {
      this.setState({
        brm_month: ''
      });

    }
    //let value = event.target.value;
    //this.setState({'startDate':value});
  }

  render() {
    const columnwithDefs = [
      {
        headerName: "Update",
        field: "_id",
        width: 100,
        filter: true,
        resizable: true,
        cellRendererSelector: function (params) {
          var rendComponent = {
            component: "UpdateButton",
          };
          return rendComponent;
        },
      },
      {
        headerName: "Brm Month",
        field: "brm_month",
        width: 120,
        filter: true,
        resizable: true,
        // editable: true,
        // cellEditor: DateEditor,
        // cellEditor: "datePicker",
        valueGetter: (param) => {
          if (param.data.brm_month !== undefined) {
            return getHyphenDDMMMYYYY(param.data.brm_month);
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Transporter Code",
        field: "resp",
        width: 120,
        filter: true,
        resizable: true,

        // editable: true,
        // cellEditor: 'agSelectCellEditor',
        // cellEditor: 'agRichSelectCellEditor',
        // cellEditorParams: {
        //     values: this.state.t_names,
        // },
      },
      {
        headerName: "Transporter Name",
        field: "resp_trtr_name",
        width: 120,
        filter: true,
        resizable: true,
        editable: true,
        cellEditor: "agSelectCellEditor",
        // cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: this.state.t_names,
        },
        valueSetter: (params) => {
          if (params.oldValue != params.newValue) {
            let tPair = this.state.t_pair;
            tPair.map((e) => {
              if (params.newValue == e.transporter_name) {
                params.data.resp = e.transporter_code;
                params.data.resp_trtr_name = params.newValue;
              }
            });
          }
        },
      },
    
      {
        headerName: "Category",
        field: "cate",
        width: 120,
        filter: true,
        resizable: true,
        editable: true,
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: [
            "A","B", "C"
          ],
        },
      },
      {
        headerName: "Source",
        field: "source",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Reason",
        field: "reason",
        width: 120,
        filter: true,
        resizable: true,
        editable: true,
        cellEditor: "agSelectCellEditor",
        // cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: [
            "ACCIDENT",
            "FIRE INCIDENT",
            "MISHANDLING",
            "TOPPLING",
            "TRANSIT",
            "OTHERS",
            "LOADING / UNLOADING",
          ],
        },
      },
      {
        headerName: "RTGP No",
        field: "rtgp_no",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Trip No",
        field: "trip_no",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "RTGP Date",
        field: "rtgp_date",
        width: 120,
        filter: true,
        resizable: true,
        valueGetter: (param) => {
          if (param.data.rtgp_date !== "") {
            return getHyphenDDMMMYYYY(param.data.rtgp_date);
          } else {
            return "";
          }
        },
      },
      {
        headerName: "BVEH",
        field: "bveh",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Prefix",
        field: "prefix",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Chassis No",
        field: "chass_no",
        width: 120,
        filter: true,
        resizable: true,
      },

      {
        headerName: "Invoice Amount",
        field: "invc_amt",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Department",
        field: "dept",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "TRTR",
        field: "trtr",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "TRTR Name",
        field: "trtr_name",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "TRTP",
        field: "trtp",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Regn",
        field: "regn",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Induct Date",
        field: "induct_date",
        width: 120,
        filter: true,
        resizable: true,
        valueGetter: (param) => {
          if (param.data.induct_date !== "") {
            return getHyphenDDMMMYYYY(param.data.induct_date);
          } else {
            return "";
          }
        },
      },
      {
        headerName: "For",
        field: "for",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "For Desc",
        field: "for_desc",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Invoice Date",
        field: "invc_date",
        width: 120,
        filter: true,
        resizable: true,
        valueGetter: (param) => {
          if (param.data.invc_date !== "") {
            return getHyphenDDMMMYYYY(param.data.invc_date);
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Acc Date",
        field: "acc_date",
        width: 120,
        filter: true,
        resizable: true,
        valueGetter: (param) => {
          if (param.data.acc_date !== "") {
            return getHyphenDDMMMYYYY(param.data.acc_date);
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Acc Time",
        field: "acc_time",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Acc Place",
        field: "acc_place",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Acc State",
        field: "acc_state",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Region",
        field: "region",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Acdt detail",
        field: "acdt_detail",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Actual Carrier",
        field: "actual_carri",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Vehicle Type",
        field: "vehicle_type",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Actual TCode",
        field: "act_tCode",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Actual Transporter",
        field: "act_transporter",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Actual Type",
        field: "act_type",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Response",
        field: "resp",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Resp TRTR Name",
        field: "resp_trtr_name",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Category Date",
        field: "catag_date",
        width: 120,
        filter: true,
        resizable: true,
        valueGetter: (param) => {
          if (param.data.catag_date !== "") {
            return getHyphenDDMMMYYYY(param.data.catag_date);
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Damage Remarks",
        field: "damage_remarks",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Invoice Freight Amount",
        field: "inv_freight_amt",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "POD Freight Amount",
        field: "pod_freight_amt",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Penality Amount",
        field: "penality_amt",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Acc Status",
        field: "acc_status",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "POD Status",
        field: "pod_status",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Monthly Wholesale",
        field: "monthly_wholesale",
        width: 120,
        filter: true,
        resizable: true,
      },
    ];

    const {
      show,basicType,basicTitle,sliderTranslate,loadshow,chartActive,gridActive,showGridData,autoGroupColumnDef,modules,defaultColDef,
      rowData,frameworkComponents,statusBar,sideBar,paginationPageSize,components,showChartGridData1,chartColumnWithDef1,chartColumnWithDef2,
      chartRowData1,chartRowData2} = this.state;
    return (
      <div className="container-fluid">
        <SweetAlert
          show={show}
          type={basicType}
          title={basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="card">
              <div className="card-header">
                <h5>
                  <span>Transit Damage Returns</span>
                  <button
                    className={"btn btn-info"}
                    style={{ float: "right", marginRight: "10px" }}
                    onClick={this.onClickShowSidebar}
                  >
                    Upload Data
                  </button>
                </h5>
              </div>
              <div className="row card-body">
                <>
                  <ul className="row col-xl-12 col-lg-12">
                    <li>
                      <button
                        type="button"
                        onClick={() => this.onClickTab("chart")}
                        className={"btn " + chartActive}
                      >
                        Chart
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        onClick={() => this.onClickTab("grid")}
                        className={"btn " + gridActive}
                      >
                        Raw data
                      </button>
                    </li>
                  </ul>
                </>

                <div className="card-body col-xl-12 col-lg-12">
                  {/* <div id="myGrid"
                    style={{ width: "100%", height: "746px" }}
                    className={"ag-theme-balham col-xl-12 col-lg-12 " + showChartGridData1} > */}

                  <form className={"row " + showChartGridData1} onSubmit={this.getChartData}>
                    <div className={"form-group col-xl-3 col-lg-3"}>
                      <Select
                        className="border-radius-0"
                        isMulti={false}
                        id="selectedFilter"
                        // placeholder = {"Financial Year"}
                        style={{ borderRadius: "0px", }}
                        options={this.state.distinctFyYears}
                        required
                      />
                    </div>
                    <div
                      className="form-group col-xl-3 col-lg-3"
                      style={{ textAlign: "left" }}
                    >
                      <button type="submit" className="btn btn-success">
                        Submit
                      </button>
                    </div>
                  </form>

                  <div id="myGrid"
                    style={{ width: "100%", height: "774px" }}
                    className={"ag-theme-balham col-xl-12 col-lg-12 " + showChartGridData1}
                  >
                    <AgGridReact
                      modules={modules}
                      columnDefs={chartColumnWithDef1}
                      defaultColDef={defaultColDef}
                      rowData={chartRowData1}
                      enableCharts={false}
                      // enableRangeSelection={false}
                      autoGroupColumnDef={autoGroupColumnDef}
                      onGridReady={this.onGridReady}
                      onGridState={this.onGridState}
                      statusBar={statusBar}
                      sideBar={sideBar}
                      paginationPageSize={paginationPageSize}
                      pagination={true}
                      enableRangeSelection={true}
                      components={components}
                      frameworkComponents={frameworkComponents}
                      //floatingFilter={true}
                      //onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                      //onCellClicked={this.onCellClicked}
                      // stopEditingWhenGridLosesFocus={true}
                      gridOptions={{
                        context: { componentParent: this },
                      }}
                    />
                  </div>

                  {/* <button type="submit" className={"btn btn-success float-right " + showChartGridData1} onClick={this.updateChartOnClick}>
                    Update Chart
                  </button> */}
                  {/* <div className="form-group col-xl-3 col-lg-3 float-right"
                      style={{ textAlign: "left" }}>
                    </div> */}
                  {/* </div> */}

                  {/* <div className="col-xl-12 col-lg-12"> */}
                  <div
                    id="myGrid"
                    style={{ width: "100%", height: "774px", marginTop: '4em' }}
                    className={"ag-theme-balham  col-xl-12 col-lg-12 " + showChartGridData1}
                  >
                    <AgGridReact
                      modules={modules}
                      columnDefs={chartColumnWithDef2}
                      defaultColDef={defaultColDef}
                      rowData={chartRowData2}
                      enableCharts={false}
                      // enableRangeSelection={false}
                      autoGroupColumnDef={autoGroupColumnDef}
                      onGridReady={this.onGridReady}
                      onGridState={this.onGridState}
                      statusBar={statusBar}
                      sideBar={sideBar}
                      paginationPageSize={paginationPageSize}
                      pagination={true}
                      enableRangeSelection={true}
                      components={components}
                      frameworkComponents={frameworkComponents}
                      //floatingFilter={true}
                      //onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                      //onCellClicked={this.onCellClicked}
                      // stopEditingWhenGridLosesFocus={true}
                      gridOptions={{
                        context: { componentParent: this },
                      }}
                    />
                  </div>
                  {/* </div> */}

                  <div className="row">
                    <div className={"col-xl-6 col-lg-6 " + showChartGridData1}>
                      {this.state.transitBiChart.length > 0 ? (
                        this.state.transitBiChart.map((item) => (
                          <TransitReturnChart
                            chartType="pie"
                            title={"Domestic Category Wise Contribution - (FY 21-22)"}
                            subtitle={""}
                            context={this}
                            series={item.series}
                            categories={item.categories}
                            xtitle={item.xtitle}
                            ytitle={item.ytitle}
                            yaxistitle={item.yaxistitle}
                          />
                        ))
                      ) : (
                        <div
                          className="col-xl-6 col-lg-6"
                          style={{ minHeight: "350px", textAlign: "center" }}
                        >
                          <h5>Damages Report {this.state.finyear}</h5>
                          <span
                            style={{
                              position: "absolute",
                              top: "30%",
                              left: "43%",
                            }}
                          >
                            Loading
                          </span>
                        </div>
                      )}
                    </div>
                    <div className={"col-xl-6 col-lg-6 " + showChartGridData1}>
                      {this.state.transitBiChart.length > 0 ? (
                        this.state.transitBiChart.map((item) => (
                          <TransitReturnChart
                            chartType="pie"
                            title={"Domestic Category Wise Contribution - (FY 22-23)"}
                            subtitle={""}
                            context={this}
                            series={item.series1}
                            categories={item.categories}
                            xtitle={item.xtitle}
                            ytitle={item.ytitle}
                            yaxistitle={item.yaxistitle}
                          />
                        ))
                      ) : (
                        <div
                          className="col-xl-6 col-lg-6"
                          style={{ minHeight: "350px", textAlign: "center" }}
                        >
                          <h5>Damages Report {this.state.finyear}</h5>
                          <span
                            style={{
                              position: "absolute",
                              top: "30%",
                              left: "43%",
                            }}
                          >
                            Loading
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className={"col-xl-6 col-lg-6 " + showChartGridData1}>
                      {this.state.transitBiChart.length > 0 ? (
                        this.state.transitBiChart.map((item) => (
                          <TransitReturnChart
                            chartType="pie"
                            title={"Export Category Wise Contribution - (FY 21-22)"}
                            subtitle={""}
                            context={this}
                            series={item.series2}
                            categories={item.categories}
                            xtitle={item.xtitle}
                            ytitle={item.ytitle}
                            yaxistitle={item.yaxistitle}
                          />
                        ))
                      ) : (
                        <div
                          className="col-xl-6 col-lg-6"
                          style={{ minHeight: "350px", textAlign: "center" }}
                        >
                          <h5>Damages Report {this.state.finyear}</h5>
                          <span
                            style={{
                              position: "absolute",
                              top: "30%",
                              left: "43%",
                            }}
                          >
                            Loading
                          </span>
                        </div>
                      )}
                    </div>
                    <div className={"col-xl-6 col-lg-6 " + showChartGridData1}>
                      {this.state.transitBiChart.length > 0 ? (
                        this.state.transitBiChart.map((item) => (
                          <TransitReturnChart
                            chartType="pie"
                            title={"Export Category Wise Contribution - (FY 22-23)"}
                            subtitle={""}
                            context={this}
                            series={item.series3}
                            categories={item.categories}
                            xtitle={item.xtitle}
                            ytitle={item.ytitle}
                            yaxistitle={item.yaxistitle}
                          />
                        ))
                      ) : (
                        <div
                          className="col-xl-6 col-lg-6"
                          style={{ minHeight: "350px", textAlign: "center" }}
                        >
                          <h5>Damages Report {this.state.finyear}</h5>
                          <span
                            style={{
                              position: "absolute",
                              top: "30%",
                              left: "43%",
                            }}
                          >
                            Loading
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                    {/* <div className={"col-xl-12 col-lg-12 " + showChartGridData1}>
                      {this.state.barDamageReportData.length > 0 ? 
                          // <TransitReturnChart
                          //   chartType="column"
                          //   title={"Transit Damage Returns"}
                          //   subtitle={""}
                          //   context={this}
                          //   series={this.state.barDamageReportData}
                          //   categories={this.state.barDamageReportCategories}
                          //   // xtitle={item.xtitle}
                          //   // ytitle={item.ytitle}
                          //   yaxistitle={item.yaxistitle}
                          //   drilldownseries={[]}
                          // />
                          <LoadSummaryColumnChart 
                            chartType="column"
                            title={"Transit Damage Returns "}
                            subtitle={""}
                            context={this}
                            series={this.state.barDamageReportData}
                            categories={this.state.barDamageReportCategories}
                            splineSeriesData={this.state.splineSeriesData}
                            seriesName=""
                            yaxistitle=""
                            drilldownseries={[]}
                          />
                        :""}
                    </div> */}
                    <div className={"col-xl-12 col-lg-12 " + showChartGridData1}>
                      {this.state.barDamageReportData.length > 0 ? 
                          // <TransitReturnChart
                          //   chartType="column"
                          //   title={"Transit Damage Returns"}
                          //   subtitle={""}
                          //   context={this}
                          //   series={this.state.barDamageReportData}
                          //   categories={this.state.barDamageReportCategories}
                          //   // xtitle={item.xtitle}
                          //   // ytitle={item.ytitle}
                          //   yaxistitle={item.yaxistitle}
                          //   drilldownseries={[]}
                          // />
                          <BiTransitChartComponent 
                            chartType="column"
                            title={"Transit Damage Returns "}
                            subtitle={""}
                            context={this}
                            series={this.state.barDamageReportData}
                            categories={this.state.barCategories}
                            splineSeriesData={this.state.splineSeriesData}
                            totalReturnsData={this.state.totalReturnsData}
                            seriesName=""
                            yaxistitle=""
                            drilldownseries={[]}
                          />
                        :""}
                    </div>
                    <div className="row">
                        <div className={"col-xl-6 col-lg-6 " + showChartGridData1}>
                        {this.state.chartseries2.length > 0 ? (
                              <LoadSummaryColumnChart
                                chartType="pie"
                                title={"Causes of Return - (FY 21-22)"}
                                subtitle={""}
                                context={this}
                                series={this.state.chartseries2}
                                categories={this.state.chartcategories}
                                seriesName=""
                                yaxistitle=""
                                drilldownseries={[]}
                              />
                        ):""}
                        </div>
                        <div className={"col-xl-6 col-lg-6 " + showChartGridData1}>
                        {this.state.chartseries3.length > 0 ? (
                              <LoadSummaryColumnChart
                                chartType="pie"
                                title={"Causes of Return - (FY 22-23)"}
                                subtitle={""}
                                context={this}
                                series={this.state.chartseries3}
                                categories={this.state.chartcategories1}
                                seriesName=""
                                yaxistitle=""
                                drilldownseries={[]}
                              />
                        ):""}
                        </div>
                    </div>
                  <div className="card-body col-xl-12 col-lg-12">
                    <div
                      id="myGrid"
                      style={{ width: "100%", height: "700px" }}
                      className={"ag-theme-balham " + showGridData}
                    >
                      <AgGridReact
                        modules={this.state.modules}
                        columnDefs={columnwithDefs}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.rowData}
                        enableCharts={false}
                        // enableRangeSelection={false}
                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                        onGridReady={this.onGridReady}
                        onGridState={this.onGridState}
                        statusBar={this.state.statusBar}
                        sideBar={this.state.sideBar}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={true}
                        enableRangeSelection={true}
                        components={this.state.components}
                        frameworkComponents={frameworkComponents}
                        //floatingFilter={true}
                        //onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                        //onCellClicked={this.onCellClicked}
                        // stopEditingWhenGridLosesFocus={true}
                        gridOptions={{
                          context: { componentParent: this },
                        }}
                      />
                    </div>
                  </div>

                </div>

                <div
                  className={"slide-r " + sliderTranslate}
                  style={{ overflow: "auto" }}
                >
                  <div className="slide-r-title">
                    <h4>
                      Upload Data
                      <span
                        className="float-right closebtn"
                        style={{ marginRight: "25px" }}
                        onClick={this.onClickHideAll}
                      >
                        {" "}
                        X
                      </span>
                    </h4>
                  </div>
                  <div
                    className="slide-r-body"
                    style={{ position: "relative" }}
                  >
                    <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
                    <div
                      className="row"
                      style={{
                        padding: "5px 0px",
                        width: "100%",
                        zIndex: "9999",
                      }}
                    >
                      <form
                        method="POST"
                        className="theme-form col-xl-12 col-lg-12"
                        onSubmit={this.formUploadData}
                      >
                        <div className="form-group">
                          <div className="col-xl-12 col-lg-12 form-group">
                            <label>Select Brm Month*</label>
                            <Datetime
                              value={this.state.brm_month}
                              disableCloseOnClickOutside={false}
                              closeOnSelect={true}
                              inputProps={{
                                placeholder: '',
                                name: 'brm_month',
                                autoComplete: 'off'
                              }}
                              dateFormat="YYYY-MM"
                              name="brm_month"
                              required
                              onChange={this.handlerStartDateTime.bind(this)}
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="col-xl-12 col-lg-12">Monthly Wholesale* : </label>
                          <input type="text" name="monthly_wholesale" id="monthly_wholesale" className="form-control num-cls"
                            // onChange={this.changeHandler} 
                            placeholder="Monthly Wholesale" required />

                        </div>
                        {/* <div className="form-group">
                          <label className="col-xl-12 col-lg-12">Cost of Dismantle(Previous Month)* : </label>                         
                            <input type="text" name="cost_of_dismantle" id="cost_of_dismantle" className="form-control num-cls"
                            // onChange={this.changeHandler} 
                            placeholder="Cost of Dismantle" required />
                        </div> */}
                        {/* <div className="form-group">
                          <label className="col-xl-12 col-lg-12">Un Returned* : </label>                          
                            <input type="text" name="un_returned" id="un_returned" className="form-control num-cls"
                            // onChange={this.changeHandler} 
                            placeholder="Un Returned" required /> 
                        </div> */}
                        <div className="form-group mt-20p">
                          <label className="">Upload File *</label>
                          <input
                            type="file"
                            name="uploadFile"
                            id="uploadFile"
                            onChange={this.changeFileHandler}
                            className="form-control"
                            // value={this.state.file}
                            required
                          />
                        </div>

                        <div className="col-xl-12 col-lg-12 form-group">
                          <button type="submit" className="btn btn-success">
                            Submit
                          </button>
                        </div>
                        <div className="col-xl-12 col-lg-12 form-group">
                          <a
                            className="btn btn-primary"
                            href={require("../../assets/json/brm_sample_file.csv")}
                            target="_blank"
                          >
                            Sample Template
                          </a>
                          <p style={{ backgroundColor: '#bcd3f7' }}>*Note: Dates Should be of<span style={{ fontSize: '12px', fontWeight: 'bold', color: "#ff0000" }}><br />YYYY-MM-DD <br /> Format only</span></p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"dataLoadpage " + loadshow}></div>
        <div className={"dataLoadpageimg " + loadshow}>
          <div className="loader-box">
            <div class="loader-box">
              <div class="rotate dashed colored"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

function saveToLS(key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      "rgl-8",
      JSON.stringify({
        [key]: value,
      })
    );
  }
}

$(document).on("input", ".num-cls", function () {
  this.value = this.value.replace(/\D/g, "");
});

function getDatePicker() {
  function Datepicker() { }
  Datepicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.value = params.value;
    this.eInput.setAttribute("class", "dateinput");
    // window.$(this.eInput).datepicker({ dateFormat: 'dd-mm-yy' });
    window.$(this.eInput).datepicker({ dateFormat: "yy-mm" });
  };
  Datepicker.prototype.getGui = function () {
    return this.eInput;
  };
  Datepicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  Datepicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  Datepicker.prototype.destroy = function () { };
  Datepicker.prototype.isPopup = function () {
    return false;
  };
  return Datepicker;
}

function dateComparator(date1, date2) {
  // console.log("dateComparator");
  // console.log(date1.length);
  if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
    // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
  }
}

function monthToComparableNumber(date) {
  // console.log(date,date.length);
  if (date === undefined || date === null || date.length !== 16) {
    return null;
  }
  var yearNumber = date.substring(6, 10);
  var monthNumber = date.substring(3, 5);
  var dayNumber = date.substring(0, 2);
  console.log(yearNumber, monthNumber, dayNumber);
  var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
  return result;
}

function getMonthNumber(monthName) {
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var monthNum = months.indexOf(monthName) + 1;
  if (monthNum < 10) {
    monthNum = "0" + monthNum.toString();
  }
  return monthNum;
}
function dateComparator1(date1, date2) {
  //console.log(date1,date2);
  if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
    // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split("-")[1]);
    date1 = date1.replace(date1.split("-")[1], date_1);
    var date_2 = getMonthNumber(date2.split("-")[1]);
    date2 = date2.replace(date2.split("-")[1], date_2);
    var date1Number = monthToComparableNumber1(date1);
    var date2Number = monthToComparableNumber1(date2);
    console.log(date1Number, date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
  }
}

function monthToComparableNumber1(date) {
  console.log(date.length);
  console.log(date);
  if (date === undefined || date === null || date.length !== 10) {
    return null;
  }
  var yearNumber = date.substring(6, 10);
  var monthNumber = date.substring(3, 5);
  var dayNumber = date.substring(0, 2);
  var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
  return result;
}

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

function getFyList(param) {
  let month = parseInt(param.split("-")[1]);
  let year = parseInt(param.split("-")[0].slice(-2));
  console.log("year", year);
  console.log(month, "month");
  let fy_months = [
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
    "Jan",
    "Feb",
    "Mar",
  ];
  let fy_list = [];
  let prevYear = year - 1;
  let currYear = year;
  let nxtyear = year + 1;
  console.log("prevYear", prevYear, "currYear", currYear, "nxtyear", nxtyear);
  if (month < 4) {
    console.log("check1");
    fy_months.forEach((e) => {
      if (e == "Jan" || e == "Feb" || e == "Mar") {
        console.log("prev", e, currYear);
        fy_list.push(`${e}-${currYear}`);
      } else {
        console.log("later", e, prevYear);
        fy_list.push(`${e}-${prevYear}`);
      }
    });
  } else {
    console.log("check2");
    fy_months.forEach((e) => {
      if (fy_months.includes("Jan" || "Feb" || "Mar")) {
        fy_list.push(`${e}-${currYear}`);
      } else {
        fy_list.push(`${e}-${nxtyear}`);
      }
    });
  }
  return fy_list;
}

/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import CommentActions from "../layouts/commentsComponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import Consignmentforceclose from '../manage/consignmentforceclose';
import SOBchangelogs from "./sobchangelog";
import SobCompleteData from "./shareofbusinesscompletedata";
import SOBInterplantchangelogs from "./sobchangeinterplantlog";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import { getHyphenDDMMMYYYYHHMM } from '../common/utils';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");




export default class ShareOfBusinessInterPantD22 extends Component {
    state = {
        modules: AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents: {
        },
        modified_by: "",
        basicTitle: '',
        reasonforceclose: "",
        bulkslide: "",
        file: "",
        uploadFile: "",
        basicType: "default",
        forceCloseRowNode: "",
        forceclosedata: "",
        csvcontent: "",
        rowData: [],
        staterowData: [],
        showDiv: "",
        uploadDivWidth: "",
        mapinfo: "",
        dealer: "",
        sliderCommentTranslate: "",
        commentsRowData: "",
        consignment_code: "",
        sliderRouteTranslate: "",
        routeTruck: "",
        rownode: "",
        googelRoutes: "",
        rowSelection: 'multiple',
        sliderForceCloseTranslate: "",
        maptruckno: "",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
        statusBar: "",
        paginationPageSize: 200,
        geofencelist: [],
        geoFenceData: "",
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                }
            ]
        },
        animateRows: true,
        dept_code: "",
        pagetitle: "",
        markDeliverRowNode: "",
        markDeliverData: "",
        sliderMarkDeliverTranslate: "",
        csvData: 0,
        overly: "show-n",
        loadshow: "show-n",
        holidayEvents: [],
        showCalendar: "show-m",
        showGridData: "show-m",
        columnwithDefs: [],
        countersData: [],
        rowClassRules: {
            "fontBold": function (params) {
                if (params.data.transporter == "Total - Plan" || params.data.transporter == "Total - Actual") {
                    return true;
                }
                else {
                    return false;
                }
            }
        },
        plan_month: "2021-07",
        select_month: "",
        showType: "show-n",
        transportersList: [],
        sliderType: 1,
        colorArray: [],
        logHistory: [],
        last_updated_on: "",
        completeData: [],
        sourceDestination: [],
        items: [],
        emailList: [],
        source : [],
        destination : [],

        //components : {datepicker:getDatePicker()}
    }


    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    async componentDidMount() {
        this.logPageView();
        loadDateTimeScript();
        redirectURL.post("/consignments/getTNPTransportersList").then((response) => {
            var transportersList = response.data;
            this.setState({
                transportersList: transportersList
            });
        });
        var currentMonth = moment.parseZone().format("YYYY-MM");
        //console.log(currentMonth,"currentMonth")
        await this.setState({
            plan_month: currentMonth
        })
        redirectURL.post("/consignments/getSOBSources").then((response) => {
            var sourceDestination = response.data.sourceDestination;
            const source = sourceDestination.slice(0,5)
            const destination = sourceDestination.slice(6,8)
            // console.log(destination)
            // console.log("source", source)
            this.setState({
                source,
                destination,
                sourceDestination: sourceDestination
            });
        });
        redirectURL.post("/consignments/getOperationAssignDropDown").then(async (response) => {
            // var items = response.data.interplant;
            var items = response.data.d22smg;
            // console.log(items, "itemtype")
            this.setState({
                items: items,
                allItems: items,
            });
            await this.onLoadPageData();
        });
    }
     onLoadPageData = async () => {
        this.setState({
            loadshow: "show-m",
            overly: "show-m",
        })
        var plan_month = this.state.plan_month
        var params = {
            plan_month: this.state.plan_month,
            consignment_type : 'D22'
            // plan_month: "2021-12"
        }
        // console.log(params, "params")
        await redirectURL.post("/consignments/getSOBIntraLogHistory", params).then((response) => {
            var logHistory = response.data;
            var last_updated_on = "";
            if (logHistory.length > 0) {
                if (logHistory[0].updated_on != undefined && logHistory[0].updated_on != "") {
                    last_updated_on = getHyphenDDMMMYYYYHHMM(logHistory[0].updated_on);
                }
            }
            this.setState({
                logHistory: logHistory,
                last_updated_on: last_updated_on
            });
        });
        try {
            await redirectURL.post("/consignments/getShareOfBusinessForInterPlant", params).then(async (response) => {
                // console.log("interplantD22",response.data);
                var responseData = response.data.monthly_sob;
                if (responseData != undefined && responseData != '') {
                    var rowData = eval(responseData);
                    // console.log(rowData, "rowData");
                    var rpData = [];
                    var planTotal = [];
                    await rowData.map(async function (g) {
                        var rowDataKeys = Object.keys(g);
                        if (g.transporter.includes("Plan") == true) {
                            var inTransporter = g.transporter.split("-");
                            var rowTotal = 0;
                            var rowGapTotal = 0;
                            await rowDataKeys.map(async function (rdk) {
                                if (rdk != "location" && rdk != "revision" && rdk != "location_copy" && rdk != "revision_copy" && rdk != "plan_percentage" && rdk !=
                                    "transporter" && rdk != "plan_total" && rdk != "sob_check" && rdk != "theoritical_trips" && rdk != "gap" && rdk != "plan_date" && rdk != "item_type" && rdk != "item_type_copy" && rdk != "revision_updated" && rdk != "emailData" && rdk != "truck_type" && rdk != "colorCode" && rdk !== 'destination' && rdk !== "destination_copy") {
                                    var transp = g.transporter;
                                    var rptransporter = "";
                                    if (transp != undefined && transp != "") {
                                        var clkTrans = transp.split("-");
                                        var rptransporter = clkTrans[0];
                                    }
                                    var rw = {
                                        "location": g.location,
                                        "item_type": g.item_type,
                                        "transporter": rptransporter,
                                    }
                                    rw[rdk] = g[rdk]
                                    rpData.push(rw);

                                    var pkDate = moment.parseZone(new Date(rdk)).utcOffset("+05:30")._d;
                                    var ystDate = moment.parseZone(new Date()).subtract(1, 'days').utcOffset("+05:30")._d;
                                    if (pkDate <= ystDate) {
                                        rowGapTotal = parseInt(rowGapTotal) + parseInt(g[rdk]);
                                    }
                                }
                            })
                            planTotal.push({
                                "transporter": inTransporter[0],
                                "plan_total": rowGapTotal,
                                "location": g.location,
                            });
                        }
                    })
                    //console.log(rpData,"rpData")if(planTotal.length > 0)
                    //console.log(planTotal,"planTotal-componentdidmount")
                    if (planTotal.length > 0) {
                        rowData.forEach((p, index) => {
                            if (p.transporter.includes("Actual") == true) {
                                //if(clickedLocation == p.location)
                                //{
                                if (p.transporter != "Total - Plan" && p.transporter != "Total - Actual") {
                                    planTotal.map(function (apt) {
                                        //console.log(apt.location,p.location,"location-componentdidmount");
                                        if (apt.location == p.location) {
                                            var actTransporter = p.transporter.split("-");
                                            //console.log(apt.transporter,actTransporter[0],"transporter-b");
                                            if (apt.transporter == actTransporter[0]) {
                                                var act_plan_total = p.plan_total;
                                                var actual_plan_total = apt.plan_total;
                                                //console.log(act_plan_total,"act_plan_total");
                                                //console.log(actual_plan_total,"actual_plan_total");
                                                var terthe = (act_plan_total) - (actual_plan_total);
                                                //p.theoritical_trips = thter.toFixed(1);
                                                p.gap = terthe;
                                            }
                                        }
                                    })
                                }
                                //}
                            }
                        })
                    }
                    var colorArray = [];
                    if (rpData.length > 0) {
                        await rowData.map(async function (h) {
                            if (h.transporter.includes("Actual") == true) {
                                await rpData.map(async function (rp) {
                                    var transp = h.transporter;
                                    var rptransporter = "";
                                    if (transp != undefined && transp != "") {
                                        var clkTrans = transp.split("-");
                                        var rptransporter = clkTrans[0];
                                    }
                                    //console.log(rp.transporter,rptransporter,rp.location,h.location,rp.item_type,h.item_type,"comparison")
                                    if (rp.transporter == rptransporter && rp.location == h.location && rp.item_type == h.item_type) {
                                        var rpDataKeys = Object.keys(rp);
                                        await rpDataKeys.map(async function (rpk) {
                                            if (rpk != "location" && rpk != "transporter" && rpk != "item_type") {
                                                var planValue = rp[rpk];
                                                var actualValue = h[rpk];
                                                var diffValue = parseInt(actualValue) - parseInt(planValue);
                                                //console.log(diffValue,actualValue,planValue,rpk,"difference")
                                                var rpkDate = moment.parseZone(new Date(rpk)).utcOffset("+05:30")._d;
                                                var currentDate = moment.parseZone(new Date()).utcOffset("+05:30")._d;
                                                if (diffValue < 0) {
                                                    if (rpkDate <= currentDate) {
                                                        var clrArry = {
                                                            "transporter": h.transporter,
                                                            "location": h.location,
                                                            "item_type": h.item_type,
                                                            "diffValue": diffValue
                                                        }
                                                        clrArry[rpk] = "red";
                                                        colorArray.push(clrArry)
                                                    }
                                                }
                                                else if (diffValue > 0) {
                                                    if (rpkDate <= currentDate) {
                                                        var clrArry = {
                                                            "transporter": h.transporter,
                                                            "location": h.location,
                                                            "item_type": h.item_type,
                                                            "diffValue": diffValue
                                                        }
                                                        clrArry[rpk] = "green";
                                                        colorArray.push(clrArry)
                                                    }
                                                }
                                                else {
                                                    // if(rpkDate <= currentDate)
                                                    // {
                                                    //     var clrArry = {
                                                    //         "transporter" : h.transporter,
                                                    //         "location" : h.location,
                                                    //         "item_type" : h.item_type,
                                                    //         "diffValue" : diffValue
                                                    //     }
                                                    //     clrArry[rpk] = "white";
                                                    //     colorArray.push(clrArry)
                                                    // }
                                                }
                                            }
                                        });
                                    }
                                });
                            }
                        })
                    }
                    // this.setState({
                    //     colorArray : colorArray
                    // })
                    // console.log(colorArray, "colorArray")
                    var countersData = [];
                    var itemData = [];
                    if (rowData.length > 0) {                       
                        var locationGroup = groupBy(rowData, rdata => rdata.location);
                        // console.log(locationGroup, "locationGroup")
                        var lgArray = [];
                        await locationGroup.forEach(async (lg, lgkey) => {
                            var itemGroup = groupBy(lg, ijdata => ijdata.item_type);
                            //    console.log(itemGroup,"itemGroup-1")
                               itemGroup.forEach((ld,ldkey) => {   
                                    lgArray.push({
                                        locKey: lgkey,
                                        itemKey : ldkey,
                                        locLength: ld.length
                                    })
                                });  
                            
                            var locTotal = 0;
                            var locActualTotal = 0;
                            var itemTypeGroup = groupBy(lg, ldata => ldata.item_type);
                            // console.log(itemTypeGroup, "itemTypeGroup")
                            itemTypeGroup.forEach(async (ig, igkey) => {
                                ig.map(function (k) {
                                    if (igkey == "Blanks" || igkey == "Plastic") {
                                        if (k.transporter == "Nittsu - Plan") {
                                            var obj = Object.keys(k);
                                            obj.map(function (m) {
                                                if (m != "location" && m != "revision" && m != "location_copy" && m != "revision_copy" && m != "plan_percentage" && m !=
                                                    "transporter" && m != "plan_total" && m != "sob_check" && m != "theoritical_trips" && m != "gap" && m != "plan_date" && m != "item_type" && m != "item_type_copy" && m != "revision_updated" && m != "emailData" && m != "truck_type" && m != "colorCode" && m !== 'destination' && m !== 'destination_copy') {
                                                    var planDate = moment.parseZone(new Date(m)).utcOffset("+05:30")._d;
                                                    var currentDate = moment.parseZone(new Date()).utcOffset("+05:30")._d;
                                                    if (planDate <= currentDate) {
                                                        locTotal = parseInt(locTotal) + parseInt(k[m])
                                                    }
                                                }
                                            });
                                            //locTotal = parseInt(locTotal)+parseInt(k.plan_total)
                                        }
                                        else if (k.transporter == "Nittsu - Actual") {
                                            var obj = Object.keys(k);
                                            obj.map(function (m) {
                                                if (m != "location" && m != "revision" && m != "location_copy" && m != "revision_copy" && m != "plan_percentage" && m !=
                                                    "transporter" && m != "plan_total" && m != "sob_check" && m != "theoritical_trips" && m != "gap" && m != "plan_date" && m != "item_type" && m != "item_type_copy" && m != "revision_updated" && m != "emailData" && m != "truck_type" && m != "colorCode" && m !== 'destination' && m !== 'destination_copy') {
                                                    var planDate = moment.parseZone(new Date(m)).utcOffset("+05:30")._d;
                                                    var currentDate = moment.parseZone(new Date()).utcOffset("+05:30")._d;
                                                    if (planDate <= currentDate) {
                                                        locActualTotal = parseInt(locActualTotal) + parseInt(k[m])
                                                    }
                                                }
                                            });
                                            //locActualTotal = parseInt(locActualTotal)+parseInt(k.plan_total)
                                        }
                                    }
                                    else {
                                        if (k.transporter == "Total - Plan") {
                                            var obj = Object.keys(k);
                                            obj.map(function (m) {
                                                if (m != "location" && m != "revision" && m != "location_copy" && m != "revision_copy" && m != "plan_percentage" && m !=
                                                    "transporter" && m != "plan_total" && m != "sob_check" && m != "theoritical_trips" && m != "gap" && m != "plan_date" && m != "item_type" && m != "item_type_copy" && m != "revision_updated" && m != "emailData" && m != "truck_type" && m != "colorCode" && m !== 'destination' && m !== 'destination_copy') {
                                                    //console.log(m,"k[m]")
                                                    var planDate = moment.parseZone(new Date(m)).utcOffset("+05:30")._d;
                                                    var currentDate = moment.parseZone(new Date()).subtract(1, 'days').utcOffset("+05:30")._d;
                                                    if (planDate <= currentDate) {
                                                        //console.log(currentDate,planDate)
                                                        locTotal = parseInt(locTotal) + parseInt(k[m])
                                                    }
                                                }
                                            });
                                            //locTotal = parseInt(locTotal)+parseInt(k.plan_total)
                                        }
                                        else if (k.transporter == "Total - Actual") {
                                            var obj = Object.keys(k);
                                            obj.map(function (m) {
                                                if (m != "location" && m != "revision" && m != "location_copy" && m != "revision_copy" && m != "plan_percentage" && m !=
                                                    "transporter" && m != "plan_total" && m != "sob_check" && m != "theoritical_trips" && m != "gap" && m != "plan_date" && m != "item_type" && m != "item_type_copy" && m != "revision_updated" && m != "emailData" && m != "truck_type" && m != "colorCode" && m !== 'destination' && m !== 'destination_copy') {
                                                    var planDate = moment.parseZone(new Date(m)).utcOffset("+05:30")._d;
                                                    var currentDate = moment.parseZone(new Date()).subtract(1, 'days').utcOffset("+05:30")._d;
                                                    if (planDate <= currentDate) {
                                                        locActualTotal = parseInt(locActualTotal) + parseInt(k[m])
                                                    }
                                                }
                                            });
                                            //locActualTotal = parseInt(locActualTotal)+parseInt(k.plan_total)
                                        }
                                    }
                                });
                                itemData.push({
                                    itemName: igkey,
                                    locationTotal: locTotal,
                                    locActualTotal: locActualTotal,
                                });
                                locTotal = 0;
                                locActualTotal = 0;
                            });
                            var cntClass = "txt-info";
                            if (lgkey == "GGN") {
                                cntClass = "txt-danger";
                            }
                            else if (lgkey == "MSIL-M") {
                                cntClass = "txt-warning";
                            }
                            countersData.push({
                                location: lgkey,
                                itemData: itemData,
                                cntClass: cntClass
                            });
                            itemData = [];
                        });
                        var destinationGroup = groupBy(rowData, rdata => rdata.destination);
                        // console.log(destinationGroup, "destinationGroup")
                        var destiArray = [];
                        destinationGroup.forEach((ij, ijkey) => {
                            if (ijkey != "") {
                                
                               var locGroup = groupBy(ij, ijdata => ijdata.location);
                            //    console.log(locGroup,"locGroup-1")
                               locGroup.forEach((ld,ldkey) => {                                    
                                    destiArray.push({
                                        locKey: ldkey,
                                        destiKey: ijkey,
                                        destiLength: ld.length
                                        })
                                });  
                            } 
                        });
                        // console.log(destiArray,"destiArray")
                        var itemTypeGroup = groupBy(rowData, rdata => rdata.item_type);
                        var itArray = [];
                       itemTypeGroup.forEach((it, itkey) => {
                            if (itkey != "") {
                                itArray.push({
                                    itKey: itkey,
                                    itLength: it.length
                                });
                            }
                        });
                        //console.log(itArray,"itArray")
                        var columns = []
                        var defs = [];
                        var cFydata = [];
                        var keys = Object.keys(rowData[0]);
                        var wrapText = false;
                        var autoHeight = false;
                        await keys.forEach(async (z, k) => {
                            if (z != "_id" && z != "theoritical_trips") {
                                var keyVal = z;
                                var hwid = 50;
                                var pinnedLeft = "";
                                var hideField = false;
                                var isEditable = true;
                                if (z == "location") {
                                    keyVal = "Location";
                                    hwid = 60;
                                    pinnedLeft = "left";
                                    isEditable = false;
                                    hideField = true;
                                }
                                if (z == "location_copy") {
                                    keyVal = "Location";
                                    hwid = 60;
                                    pinnedLeft = "left";
                                    isEditable = false;
                                }
                                if (z == "destination") {
                                    keyVal = "Destination";
                                    hwid = 50;
                                    pinnedLeft = "left";
                                    isEditable = false;
                                    hideField = true;
                                }
                                 if (z == "destination_copy") {
                                    keyVal = "Destination";
                                    hwid = 70;
                                    pinnedLeft = "left";
                                    isEditable = false;
                                }
                                if (z == "item_type") {
                                    keyVal = "Item";
                                    hwid = 50;
                                    pinnedLeft = "left";
                                    isEditable = false;
                                    hideField = true;
                                    //wrapText = true;
                                    //autoHeight = true;
                                }
                                if (z == "item_type_copy") {
                                    keyVal = "Item Type";
                                    hwid = 200;
                                    pinnedLeft = "left";
                                    isEditable = false;
                                    //wrapText = true;
                                    //autoHeight = true;
                                }
                                if (z == "truck_type") {
                                    keyVal = "Truck Type";
                                    hwid = 80;
                                    pinnedLeft = "left";
                                    isEditable = false;
                                }
                                if (z == "plan_date") {
                                    keyVal = "Plan Date";
                                    hwid = 50;
                                    pinnedLeft = "left";
                                    isEditable = false;
                                    hideField = true;
                                }
                                if (z == "revision") {
                                    keyVal = "Revision";
                                    hwid = 40;
                                    pinnedLeft = "left";
                                    isEditable = false;
                                }
                                if (z == "plan_percentage") {
                                    keyVal = "Expected Plan (%)";
                                    hwid = 50;
                                    pinnedLeft = "left";
                                    isEditable = true;
                                }
                               
                                if (z == "transporter") {
                                    keyVal = "LSP";
                                    hwid = 120;
                                    pinnedLeft = "left";
                                    isEditable = false;
                                }
                                if (z == "plan_total") {
                                    keyVal = "Total";
                                    hwid = 50;
                                    pinnedLeft = "right";
                                    isEditable = false;
                                }
                                if (z == "sob_check") {
                                    keyVal = "SOB Check (%)";
                                    hwid = 50;
                                    pinnedLeft = "right";
                                    isEditable = false;
                                    hideField = true;
                                }
                                if (z == "theoritical_trips") {
                                    keyVal = "Theoritical trips";
                                    hwid = 60;
                                    pinnedLeft = "right";
                                    isEditable = false;
                                }
                                if (z == "gap") {
                                    keyVal = "Complaince Gap";
                                    hwid = 50;
                                    pinnedLeft = "right";
                                    isEditable = false;
                                }
                                
                                if (z != "location" && z != "revision" && z != "location_copy" && z != "revision_copy" && z != "plan_percentage" && z !=
                                    "transporter" && z != "plan_total" && z != "sob_check" && z != "theoritical_trips" && z != "gap" && z != "plan_date" && z != "item_type" && z != "item_type_copy" && z != "revision_updated" && z != "emailData" && z != "truck_type" && z != "colorCode" && z !== 'destination' && z !== 'destination_copy') {
                                    hwid = 40;
                                }
                                Promise.all([destiArray,itArray]).then(async () =>{
                                    columns.push({
                                        headerName: keyVal,
                                        field: z,
                                        width: hwid,
                                        pinned: pinnedLeft,
                                        editable: function (params) {
                                            if (params.data.transporter.includes("Actual") == true || params.data.transporter == "Total - Plan" || z == "transporter") {
                                                return false;
                                            }
                                            else {
                                                return isEditable;
                                            }
                                        },
                                        resizable: true,
                                        hide: hideField,
                                        filter: false,
                                        suppressMenu: true,
                                        sortable: false,
                                        cellClass: function (params) {
                                            if (z == "location_copy") {
                                                if (params.data[z] != "") {
                                                    return ["cellstylegridG", "pstBg"]
                                                }
                                            }
                                            if (z == "destination_copy") {
                                                if (params.data[z] != "") {
                                                    return ["cellstylegridG", "pstBg"]
                                                }
                                            }
                                            if (z == "item_type_copy") {
                                                if (params.data[z] != "") {
                                                    return ["cellstylegridM", "pstBg"]
                                                }
                                            }
                                        },
                                        cellStyle: function (params) {
                                            if (z == "transporter") {
                                                if (params.data[z] == "Total - Plan" || params.data[z] == "Total - Actual") {
                                                    return { fontWeight: "bold" }
                                                }
                                            }
                                            if (z == "location_copy" || z == "item_type_copy" || z == 'destination_copy') {
                                                if (params.data[z] != "") {
                                                    return { borderBottom: '1px solid #ccc' };
                                                }
                                            }                                        
                                            if (z == "gap") {
                                                if (params.data[z] != '' && params.data[z] != undefined) {
                                                    if (params.data[z] >= 0) {
                                                        return { backgroundColor: 'green', fontWeight: "bold" };
                                                    }
                                                    else {
                                                        return { backgroundColor: 'red', fontWeight: "bold" };
                                                    }
                                                }
                                            }
                                            if (z != "location" && z != "revision" && z != "location_copy" && z != "revision_copy" && z != "plan_percentage" && z !=
                                                "transporter" && z != "plan_total" && z != "sob_check" && z != "theoritical_trips" && z != "gap" && z != "plan_date" && z != "item_type" && z != "item_type_copy" && z != "revision_updated" && z != "emailData" && z != "truck_type" && z != "colorCode"  && z !== 'destination' && z !== 'destination_copy') {
                                                if (params.data.transporter.includes("Actual") == true) {
                                                    var clrFlag = 0;
                                                    var bgcolor = "";
                                                    if (colorArray.length > 0) {
                                                        colorArray.map(function (ca) {
                                                            if (ca.location == params.data.location && ca.item_type == params.data.item_type && ca.transporter == params.data.transporter) {
                                                                if (ca[z] != undefined && ca[z] != "") {
                                                                    //console.log("here",params.data.location,ca[z],params.data.item_type,params.data.transporter,z,"bhanuteja",ca.diffValue);
                                                                    if (ca.diffValue != 0) {
                                                                        clrFlag = 1;
                                                                        bgcolor = ca[z]
                                                                    }
                                                                }
                                                                else {
                                                                    //console.log("here",params.data.location,ca[z],params.data.item_type,params.data.transporter,z,"bhanuteja1");
                                                                }
                                                            }
                                                            else {
                                                                return { borderRight: '1px solid #ccc' };
                                                            }
                                                        })
                                                        if (clrFlag == 1) {
                                                            return { backgroundColor: bgcolor, color: "#000", borderRight: '1px solid #ccc' };
                                                        }
                                                        else {
                                                            return { color: "#000", borderRight: '1px solid #ccc' };
                                                        }
                                                        clrFlag = 0;
                                                    }
                                                    else {
                                                        return { borderRight: '1px solid #ccc' };
                                                    }
                                                }
                                                else if (params.data.transporter == "Total - Plan") {
                                                    return { backgroundColor: "#eee", borderRight: '1px solid #ccc' }
                                                }
                                                else if (isEditable == true) {
                                                    return { borderRight: '1px solid #ccc' }
                                                }
                                                else {
                                                    return { backgroundColor: "#eee", borderRight: '1px solid #ccc' }
                                                }
                                            }
                                        },
                                        rowSpan: (params) => {
                                            if (z == "location_copy") {
                                                if (params.data[z] != "") {
                                                    if (lgArray.length > 0) {
                                                        var filteredData = lgArray.filter(function (obj) {
                                                            return (obj.locKey === params.data[z] && obj.itemKey == params.data.item_type_copy);
                                                        });
                                                        // console.log("lgArray", filteredData, "lgArrayparams", params.data[z])
                                                        return filteredData[0].locLength
                                                    }
                                                }
                                            }
                                            if (z == "destination_copy") {
                                                if (params.data[z] != "") {
                                                    if (destiArray.length > 0) {
                                                        // var filteredData1 = destiArray.filter((fd1)=> fd1.destKey == params.data[z] && fd1.locKey == params.data.location_copy);
                                                        let filteredData1 = destiArray.filter(function (obj) {
                                                            return (obj.destiKey === params.data[z]  && obj.locKey == params.data.location_copy);
                                                        });
                                                        // console.log("filtered", filteredData1[0].destiLength,filteredData1[0].destination_copy,filteredData1[0].location_copy)
                                                        return filteredData1[0].destiLength;
                                                    }
                                                }
                                            }
                                            if (z == "item_type_copy") {
                                                if (params.data[z] != "") {
                                                    // console.log("itArray.length",itArray.length,itArray, )
                                                    if (itArray.length > 0) {
                                                        var filteredData1 = itArray.filter(function (obj) {
                                                            return (obj.itKey === params.data[z]);
                                                        });
                                                        return filteredData1[0].itLength
                                                    }
                                                }
                                            }
                                        },
                                        cellEditor: NumericCellEditor,
                                        wrapText: wrapText,
                                        autoHeight: autoHeight,
                                    })

                                })
                            }
                        })
                    }
                }
                this.setState({
                    countersData: countersData,
                })
                // console.log("columns tg", columns)
                // console.log("countersData - 678", countersData)
                // console.log("rows - 678", rowData)
                setTimeout(() => {
                    Promise.all([rowData,columns]).then(async ()=>{
                        this.setState({
                            columnwithDefs: columns,
                            rowData: rowData,
                            //staterowData:rowData,
                            loadshow: "show-n",
                            overly: "show-n",
                        })
                    })
                // await
                // console.log("columns - 689", columns)
                }, 2000)
            }).catch((e) => {
                console.log(e);
            })

        } catch (e) {
            console.log("error", e)
        }

        const firstdate = moment(plan_month).startOf('month').format('YYYY-MM-DD HH:mm:ss');
        const lastdate = moment(plan_month).endOf('month').format("YYYY-MM-DD HH:mm:ss");

        var reqparams = {
            month: plan_month,
            startDate: firstdate,
            endDate: lastdate,
            screen : 'D22'
        }
        // console.log("reqparams", reqparams)
        var completeData = [];
        await redirectURL.post("/consignments/getSobCompleteData", reqparams)
            .then((response) => {
                // console.log("response.data", response.data)

                response.data.map((item) => {
                    completeData.push({
                        truck_no: item.truck_no,
                        consignment_code: item.consignment_code,
                        gate_out_time: getHyphenDDMMMYYYYHHMM(item.gate_out_time),
                        consigner_code: item.consigner_code,
                        consignee_code: item.consignee_code,
                        transporter_code: item.transporter_code,
                        transporter_name: item.transporter_name,
                        items: item.items,
                        tnp_user_dept_code: item.tnp_user_dept_code
                    })
                });
                this.setState({
                    completeData: completeData,
                })
            })
    }
    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }



    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onCellClicked = async (e) => {
        //console.log(e,"clickedData")
        var updateCheck = 0;
        var clickedValue = e.value;
        var clickedField = e.colDef.field;
        var clickedLocation = e.data.location;
        var clickedTransporter = e.data.transporter;
        var clickedItemType = e.data.item_type;
        var clickedPlanDate = e.data.plan_date;
        var clickedTotal = e.data.total;
        var includeCheck = "";
        var stateRowData = this.state.staterowData;
        // console.log(stateRowData, "stateRowData")
        // console.log('if','clickedField: ->',clickedField,'clickedLocation: ->',clickedLocation,'clickedTransporter:->',clickedTransporter,'clickedItemType:->',clickedItemType,"Bhanu Teja-2")
        // stateRowData.map(function(s){
        //     if(s.location == clickedLocation && s.item_type == clickedItemType && s.transporter == clickedTransporter)
        //     {
        //         console.log(s.location,clickedLocation,"location")
        //         console.log(s.item_type,clickedItemType,"item_type")
        //         console.log(s.transporter,clickedTransporter,"transporter")
        //         console.log(clickedField,"clickedField")
        //         console.log(s[clickedField],clickedValue)
        //         if(s[clickedField] != clickedValue)
        //         {
        //             updateCheck = 1;
        //         }

        //     }
        // })
        // console.log(clickedValue, "clickedValue")
        if (clickedValue >= 0 && clickedValue != '' && clickedValue != undefined) {
            updateCheck = 1;
        }
        else {
            var clickedField = e.colDef.field;
            var clickedLocation = e.data.location;
            var clickedTransporter = e.data.transporter;
            var clickedItemType = e.data.item_type;
            var rowData = this.state.rowData;
            // console.log('clickedField: ->', clickedField, 'clickedLocation: ->', clickedLocation, 'clickedTransporter:->', clickedTransporter, 'clickedItemType:->', clickedItemType, "Bhanu Teja-2")
            rowData.map(async function (rd) {

                //console.log(rd.location,rd.transporter,rd.item_type ,"Bhanu Teja--3")
                if (rd.location == clickedLocation && rd.transporter == clickedTransporter && rd.item_type == clickedItemType) {
                    //console.log(clickedField,clickedLocation,clickedTransporter,clickedItemType,"Bhanu Teja")
                    var rowDataKeys = Object.keys(rd);
                    //console.log(rowDataKeys)
                    await rowDataKeys.map(async function (rdk) {
                        //console.log(rdk,clickedField)
                        if (rdk == clickedField) {
                            rd[rdk] = 0;
                        }
                    })
                }
            })
            clickedValue = 0;
            updateCheck = 1;
        }
        // console.log(updateCheck, "updateCheck")
        if (updateCheck == 1) {
            if (clickedTransporter != undefined && clickedTransporter != '') {
                var clkTrans = clickedTransporter.split("-");
                var includeCheck = clkTrans[1];
            }
            //console.log(clickedField,"clickedField")
            let rowData = [];
            var defTotal = 0;
            var emailData = [];
            let emailList = this.state.emailList
            var planDate = moment.parseZone(new Date(clickedField)).utcOffset("+05:30")._d;
            // console.log("planDate",planDate)
            if (clickedField != "plan_percentage") {
                let userInputVal = {
                    location: clickedLocation,
                    transporter: clickedTransporter,
                    item_type: clickedItemType,
                    plan_date: clickedField,
                    planDate: planDate,
                    plan_share: e.data[clickedField],
                    planMonth: clickedPlanDate,
                    destination: e.data.destination
                }
                if (e.data[clickedField] !== 0) {
                    emailList = [...this.state.emailList, userInputVal]
                }

                emailData.push(userInputVal);
            }
            // console.log("emailList",emailList)
            // console.log("emailData856",emailData)
            if (includeCheck != '') {
                var colTotal = 0;
                // TO UPDATE PLAN TOTAL COLOUMN TOTAL
                this.gridApi.forEachNode((e) => {
                    // console.log(e,"gridData")
                    var childIndex = e.childIndex;
                    var gridData = e.data;
                    if (e.data.transporter.includes(includeCheck) == true) {
                        if (clickedLocation == e.data.location) {
                            //made change for error where blanks and press parts dates were having duplicates
                            if (clickedTransporter == e.data.transporter && clickedItemType == e.data.item_type) {
                                // console.log(e,"gridData")
                                e.data["revision_updated"] = 1;
                                var emailCheck = 0;
                                if (emailData.length > 0) {
                                    var rowEmailData = e.data.emailData;
                                    var emailplanDate = "";
                                    if (rowEmailData != undefined && rowEmailData.length > 0) {
                                        // console.log(rowEmailData,"secondChk");
                                        // console.log(emailData,"thirdChk");
                                        var rowPlanDate = rowEmailData[0].planDate;
                                        emailplanDate = emailData[0].planDate;
                                        // console.log(emailplanDate,rowPlanDate,"fourthChk");
                                        if (emailplanDate < rowPlanDate) {
                                            emailCheck = 1;
                                        }
                                    }
                                    else {
                                        emailCheck = 1;
                                    }
                                    // console.log(emailCheck,"firstChk");
                                    if (emailCheck == 1) {
                                        var rowEDataKeys = Object.keys(gridData);
                                        rowEDataKeys.map(function (ed) {
                                            if (ed != "location" && ed != "revision" && ed != "location_copy" && ed != "revision_copy" && ed != "plan_percentage" && ed !=
                                                "transporter" && ed != "plan_total" && ed != "sob_check" && ed != "theoritical_trips" && ed != "gap" && ed != "plan_date" && ed != "item_type" && ed != "item_type_copy" && ed != "revision_updated" && ed != "emailData" && ed != "truck_type" && ed != "colorCode" && ed !== 'destination' && ed !== 'destination_copy') {
                                                var rowEMnthData = moment.parseZone(new Date(ed)).utcOffset("+05:30")._d;
                                                // console.log(rowEMnthData,"rowEMnthData");
                                                // console.log(emailData[0].planDate,"emailplanDate");
                                                if (rowEMnthData > emailData[0].planDate) {
                                                    emailData.push({
                                                        location: clickedLocation,
                                                        transporter: clickedTransporter,
                                                        item_type: clickedItemType,
                                                        plan_date: ed,
                                                        planDate: planDate,
                                                        plan_share: e.data[ed],
                                                        destination: e.data.destination
                                                    })
                                                }
                                            }
                                        });
                                        e.data["emailData"] = emailData;
                                    }
                                }
                            }

                            if (clickedField != "plan_percentage") {
                                if (e.data.transporter != "Total - Plan" && e.data.transporter != "Total - Actual") {
                                    var rowKeys = Object.keys(gridData);
                                    //console.log(e.data[clickedField],"rowKeys");
                                    colTotal = parseInt(colTotal) + parseInt(e.data[clickedField]);
                                    rowData.push(e.data)
                                }
                                else {
                                    //console.log(colTotal,"colTotal")
                                    e.data[clickedField] = colTotal;
                                    rowData.push(e.data)
                                }
                            }
                            else {
                                rowData.push(e.data)
                            }
                        }
                        else {
                            rowData.push(e.data)
                        }
                    }
                    else {
                        rowData.push(e.data)
                    }
                })
                // console.log(rowData,"introwData");
                // TO UPDATE PLAN TOTAL
                rowData.map(function (r, index) {
                    var rowDataKeys = Object.keys(r);
                    //console.log(rowDataKeys.length,"rowDataKeys");
                    var rowTotal = 0;
                    rowDataKeys.map(function (rdk) {
                        if (rdk != "location" && rdk != "revision" && rdk != "location_copy" && rdk != "revision_copy" && rdk != "plan_percentage" && rdk !=
                            "transporter" && rdk != "plan_total" && rdk != "sob_check" && rdk != "theoritical_trips" && rdk != "gap" && rdk != "plan_date" && rdk != "item_type" && rdk != "item_type_copy" && rdk != "revision_updated" && rdk != "emailData" && rdk != "truck_type" && rdk != "colorCode"  && rdk !== 'destination' && rdk !== 'destination_copy') {
                            //console.log(rdk,"Bhanu Teja")
                            //console.log(parseInt(rowTotal),parseInt(r[rdk]),"Bhanu Teja")
                            rowTotal = parseInt(rowTotal) + parseInt(r[rdk]);
                        }
                    });
                    r.plan_total = rowTotal;
                    rowTotal = 0;
                    //console.log(r.total,index)
                })
                // TO UPDATE SOB CHECK AND THEORITICAL TRIPS FOR PLAN
                var rowListData = [];
                var rowTotal = [];
                var sob_check_index = [];
                var rowPlanPercentage = [];
                var theoritical_trips_index = [];
                rowData.forEach((k, index) => {
                    if (k.transporter.includes(includeCheck) == true) {
                        if (clickedLocation == k.location) {
                            if (k.transporter != "Total - Plan" && k.transporter != "Total - Actual") {
                                rowTotal.push(k.plan_total);
                                rowPlanPercentage.push(k.plan_percentage);
                            }
                            else {
                                var allTotal = k.plan_total;
                                //console.log(rowPlanPercentage,"rowPlanPercentage");
                                rowTotal.map(function (rt, index) {
                                    if (allTotal > 0) {
                                        var sobCheck = parseInt(rt) / parseInt(allTotal) * 100;
                                        sob_check_index.push(parseFloat(sobCheck.toFixed(1)));
                                    }
                                })
                                rowPlanPercentage.map(function (rp, index) {
                                    var thetrips = parseInt(allTotal) * parseInt(rp) / 100;
                                    //console.log(parseInt(allTotal),parseInt(rp))
                                    //console.log(thetrips,"thetrips")
                                    theoritical_trips_index.push(parseFloat(thetrips.toFixed(1)))
                                })
                            }
                        }
                    }
                })
                //console.log(sob_check_index,"sob_check_index");
                var sobManualIndex = 0;
                if (sob_check_index.length > 0) {
                    rowData.map(function (b, index) {
                        if (b.transporter.includes("Actual") == true) {
                            if (clickedLocation == b.location) {
                                if (b.transporter != "Total - Plan" && b.transporter != "Total - Actual") {
                                    if (sob_check_index[sobManualIndex] != undefined) {
                                        b.sob_check = sob_check_index[sobManualIndex];
                                        //console.log(sob_check_index[sobManualIndex],"sobindex")
                                    }
                                    sobManualIndex = sobManualIndex + 1;
                                }
                            }
                        }
                    })
                }
                //console.log(theoritical_trips_index,"theoritical_trips_index");
                var ttManualIndex = 0;
                if (theoritical_trips_index.length > 0) {
                    rowData.map(function (t, index) {
                        if (t.transporter.includes(includeCheck) == true) {
                            if (clickedLocation == t.location) {
                                if (t.transporter != "Total - Plan" && t.transporter != "Total - Actual") {
                                    if (theoritical_trips_index[ttManualIndex] != undefined) {
                                        var gap = (t.plan_total) - (theoritical_trips_index[ttManualIndex]);
                                        t.theoritical_trips = theoritical_trips_index[ttManualIndex];
                                        //t.gap = gap.toFixed(1);
                                        //console.log(theoritical_trips_index[ttManualIndex],"theoiticalindex")
                                    }
                                    ttManualIndex = ttManualIndex + 1;
                                }
                            }
                        }
                    })
                }
                // TO UPDATE THEORITICAL TRIPS FOR ACTUAL AND GAP
                var planTheoritical = [];
                var planTotal = [];
                rowData.forEach((n, index) => {
                    if (clickedLocation == n.location) {
                        if (n.transporter.includes("Plan") == true) {
                            if (n.transporter != "Total - Plan" && n.transporter != "Total - Actual") {
                                var inTransporter = n.transporter.split("-");
                                var theoritical_trips = n.theoritical_trips;
                                var rowDataKeys = Object.keys(n);
                                var rowGapTotal = 0;
                                rowDataKeys.map(function (pk) {
                                    if (pk != "location" && pk != "revision" && pk != "location_copy" && pk != "revision_copy" && pk != "plan_percentage" && pk !=
                                        "transporter" && pk != "plan_total" && pk != "sob_check" && pk != "theoritical_trips" && pk != "gap" && pk != "plan_date" && pk != "item_type" && pk != "item_type_copy" && pk != "revision_updated" && pk != "emailData" && pk != "truck_type" && pk != "colorCode"  && pk !== 'destination' && pk !== 'destination_copy') {
                                        var pkDate = moment.parseZone(new Date(pk)).utcOffset("+05:30")._d;
                                        var ystDate = moment.parseZone(new Date()).subtract(1, 'days').utcOffset("+05:30")._d;
                                        if (pkDate <= ystDate) {
                                            rowGapTotal = parseInt(rowGapTotal) + parseInt(n[pk]);
                                        }
                                    }
                                });
                                planTotal.push({
                                    "transporter": inTransporter[0],
                                    "plan_total": rowGapTotal,
                                });
                            }
                        }
                    }
                })
                //console.log(planTotal,"planTotal")
                if (planTotal.length > 0) {
                    rowData.forEach((p, index) => {
                        if (p.transporter.includes("Actual") == true) {
                            if (clickedLocation == p.location) {
                                if (p.transporter != "Total - Plan" && p.transporter != "Total - Actual") {
                                    planTotal.map(function (apt) {
                                        var actTransporter = p.transporter.split("-");
                                        //console.log(apt.transporter,actTransporter[0],"transporter-b");
                                        if (apt.transporter == actTransporter[0]) {
                                            var act_plan_total = p.plan_total;
                                            var actual_plan_total = apt.plan_total;
                                            //console.log(act_plan_total,"act_plan_total");
                                            //console.log(actual_plan_total,"actual_plan_total");
                                            var terthe = (act_plan_total) - (actual_plan_total);
                                            //p.theoritical_trips = thter.toFixed(1);
                                            p.gap = terthe;
                                        }
                                    })
                                }
                            }
                        }
                        else {
                            // if(clickedLocation == p.location)
                            // {
                            //     if(p.transporter !="Total - Plan" && p.transporter !="Total - Actual")
                            //     {
                            //         planTotal.map(function(apt){
                            //             var actTransporter = p.transporter.split("-");
                            //             //console.log(apt.transporter,actTransporter[0]);
                            //             if(apt.transporter == actTransporter[0])
                            //             {
                            //                 var act_plan_total = p.plan_total;
                            //                 var actual_plan_total = apt.plan_total;
                            //                 var terthe = (actual_plan_total)-(act_plan_total);
                            //                 //console.log(act_plan_total,"act_plan_total");
                            //                 //console.log(actual_plan_total,"actual_plan_total");
                            //                 if(p.transporter.includes("Actual") == true)
                            //                 {
                            //                     //console.log(p.transporter,"p.transporter")
                            //                     p.gap = terthe.toFixed(1);
                            //                 }
                            //             }
                            //         })
                            //     }
                            // }
                        }
                    })
                }
                //console.log(rowData,"updatedrowData")
                this.setState({
                    rowData: rowData,
                    emailList
                })
                this.gridApi.refreshCells();


                // setTimeout(() => {
                // }, 1000);
            }
        }
        else {
            // await this.setState({
            //     rowData : rowData
            // })
            // await this.gridApi.refreshCells();
        }
    }

    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            bulkslide: "",

        });

    }
    addNewTransporter = (e) => {
        e.preventDefault();
        // this.setState({
        //     loadshow: 'show-m',
        //     overly: 'show-m'
        // })
        //console.log(params);
        var source = $("#add_source").val();
        var destination = $("#add_destination").val();
        const item_type = $("#add_item_type").val()
        // var oprtn = this.state.add_operation;
        // console.log('oprtn',oprtn)
        // var operation = [];
        // if (oprtn.length > 0) {
        //     oprtn.map(function (op) {
        //         operation.push(op.value);
        //     });
        // }
        //console.log(operation,"operation");
        var lsp = $("#add_lsp").val();
        var select_month = this.state.select_month;
        var sliderType = this.state.sliderType;
        // console.log(source, destination, operation, lsp, select_month, sliderType)
        if (source != '' && source != undefined && destination != '' && destination != undefined && item_type != '' && item_type != undefined && lsp != '' && lsp != undefined && select_month != "" && select_month != undefined && sliderType != "" && sliderType != undefined) {
            var params = {
                month: select_month,
                source: source,
                destination: destination,
                item_type: item_type,
                // operation: operation,
                lsp: lsp,
                sliderType: sliderType,
                consignment_type : "D22"
            }
            // console.log("params",params)
            redirectURL.post("/consignments/updateSOBInterplantTransporter", params).then((response) => {
                // console.log(response);
                if (response.data.status == "success") {
                    this.setState({
                        basicTitle: "Success",
                        basicType: "success",
                        show: true,
                        loadshow: 'show-n',
                        overly: 'show-n',
                        bulkslide: "",
                    })
                    
                    if(this.state.sliderType == 1){
                        window.location.reload();
                    }else{
                        this.onLoadPageData();
                    }
                }
                else {
                    this.setState({
                        basicTitle: "Failed.",
                        basicType: "warning",
                        show: true,
                        loadshow: 'show-n',
                        overly: 'show-n',
                    })
                }
            })
        }
        else {
            this.setState({
                basicTitle: "Fields Should not be Empty.",
                basicType: "warning",
                show: true
            })
        }
    }
    showGrid() {
        this.setState({
            showCalendar: "show-n",
            showGridData: "show-m"
        })
    }
    showBulkUpload() {
        this.setState({
            overly: "show-m",
            bulkslide: "slider-translate",
            sliderType: 1,
        })
    }
    deleteTransporter() {
        this.setState({
            overly: "show-m",
            bulkslide: "slider-translate",
            sliderType: 2,
        })
    }
    showLogData() {
        this.setState({
            overly: "show-m",
            bulkslide: "slider-translate-60p",
            sliderType: 3,
        })
    }
    showCompleteData() {
        this.setState({
            overly: "show-m",
            bulkslide: "slider-translate-60p",
            sliderType: 4,
        })
    }
    resetUpload = () => {
        this.setState({
            bulkslide: '',
            overly: 'show-n',
            file: ''
        });
        document.getElementById("upform").reset();
    }
    formAssignHandler = async (event) => {
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": googleAnalytics.page.action.formSubmittedUpdateNow,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        // this.setState({
        //     loadshow: 'show-m'  
        // });
        var itemsToUpdate = [];
        this.gridApi.forEachNode(function (rowNode, index) {
            var data = rowNode.data;
            itemsToUpdate.push(data);
        });

        await itemsToUpdate.map(e => {
            let keyList = Object.keys(e);
            // console.log(keyList, "keys")
            keyList.map(ed => {
                if (ed != "location" && ed != "revision" && ed != "location_copy" && ed != "revision_copy" && ed != "plan_percentage" && ed !=
                    "transporter" && ed != "plan_total" && ed != "sob_check" && ed != "theoritical_trips" && ed != "gap" && ed != "plan_date" && ed != "item_type" && ed != "item_type_copy" && ed != "revision_updated" && ed != "emailData" && ed != "truck_type" && ed != "colorCode"  && ed !== 'destination' && ed !== 'destination_copy') {
                    // console.log("ed",ed, '\nvalue',e[ed])
                    // console.log(e, "item row")
                    var emailData = e['emailData'];
                    // console.log(emailData, "emailData")
                    if (emailData != undefined) {
                        emailData.map((item) => {
                            if (item.plan_date == ed) {
                                item.plan_share = e[ed]
                            }
                        })
                    }

                }
            })
        })
        // console.log("itemsToUpdate",itemsToUpdate);
        var params = {
            rowData: itemsToUpdate,
            sendMail: 1,
            email: localStorage.getItem("email"),
            username: localStorage.getItem("username"),
            consignment_type : 'D22'
        }
        // console.log("params1266",params)
        redirectURL.post("/consignments/updateShareOfBusiness", params)
            .then((response) => {
                if (response.data.message == "success") {
                    // console.log(response.data.response,"response.data.response")
                    this.setState({
                        show: true,
                        basicTitle: "Data Updated Successfully",
                        basicType: "success"
                    });
                    this.onLoadPageData();
                }
                else {
                    this.setState({
                        show: true,
                        basicTitle: "Failed update the data",
                        basicType: "info"
                    })
                }
                //window.location.reload();
            })
    }


    formAssignHandlerWithOutMail = (event) => {
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": googleAnalytics.page.action.formSubmittedUpdateNow,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        this.setState({
            loadshow: 'show-m'
        });
        var itemsToUpdate = [];
        this.gridApi.forEachNode(function (rowNode, index) {
            var data = rowNode.data;
            itemsToUpdate.push(data);
        });
        // console.log(itemsToUpdate, "itemsToUpdate");
        var params = {
            rowData: itemsToUpdate,
            sendMail: 0,
            email: localStorage.getItem("email"),
            username: localStorage.getItem("username"),
            consignment_type : 'D22'
        }
        // console.log(params, "params")
        redirectURL.post("/consignments/updateShareOfBusiness", params)
            .then((response) => {
                if (response.data.message == "success") {
                    // console.log(response.data.response, "response.data.response")
                    this.setState({
                        show: true,
                        basicTitle: "Data Updated Successfully",
                        basicType: "success"
                    });
                    this.onLoadPageData();
                }
                else {
                    this.setState({
                        show: true,
                        basicTitle: "Failed update the data",
                        basicType: "info"
                    })
                }
                //window.location.reload();
            })
    }

    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var plan_month = d.getFullYear() + "-" + ((d.getMonth() < 10) ? `0${d.getMonth() + 1}` : d.getMonth()+ 1);

        this.setState({
            plan_month: plan_month
        });
    }

    handlerStartDateTimeForNewTransporter = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var select_month = d.getFullYear() + "-" + ((d.getMonth() < 10) ? `0${d.getMonth() + 1}` : d.getMonth()+ 1);

        this.setState({
            select_month: select_month
        });
    }

    filterDataByMonth = async () => {
        await this.setState({
            plan_month: this.state.plan_month,
            rowData : [],
            columnwithDefs : []
        });
        await this.onLoadPageData();
    }

    changesourceHandler = () => {
        var source = $("#add_source").val();
        var destination = $("#add_destination").val();
        // if (source != '' && destination != '') {
        //     var allItems = this.state.allItems;
        //     var items = allItems.filter(rec => rec.source == source && rec.destination == destination);
        //     this.setState({
        //         items: items
        //     });
        // }
    }

    selectOperations() {
        var items = this.state.items;
        var selArray = [];
        items.map(function (it) {
            selArray.push({ value: it.operations, label: it.operations });
        });
        return selArray;
    }
    changeOperations = async (params) => {
        //console.log(params,"selectedValue")
        await this.setState({
            add_operation: params
        })
        //console.log(this.state.add_operation,"add_operation")
    }

    changeLocationHandler = () => {
        var location = $("#add_location").val();
        var itemtype = $("#add_item_type").val();
        // if(location == "MSIL-M")
        // {
        //     $("#add_item_type").attr("required",true);
        //     this.setState({
        //         showType : "show-m"
        //     })
        // }
        // else
        // {
        //     $("#add_item_type").removeAttr("required",true);
        //     this.setState({
        //         showType : "show-n"
        //     })
        // }
    }
    render() {

        var columnwithDefs = [
            {
                headerName: "MSP Name",
                field: "msp_name",
                width: 180,
                filter: true,
                resizable: true,
                editable: true,
            },
            {
                headerName: "Lattitude",
                field: "latitude",
                width: 130,
                filter: true,
                resizable: true,
                editable: true,
                valueGetter: function (params) {
                    if (params.data.latitude != undefined && params.data.latitude != '') {
                        return params.data.latitude;
                    }
                    else {
                        return "";
                    }
                }
            },
            {
                headerName: "Longitude",
                field: "longitude",
                width: 130,
                filter: true,
                resizable: true,
                editable: true,
                valueGetter: function (params) {
                    if (params.data.longitude != undefined && params.data.longitude != '') {
                        return params.data.longitude;
                    }
                    else {
                        return "";
                    }
                }
            },
        ]
        var gridOptions = {
            defaultColDef: {
                sortable: true,
                resizable: true,
            },
            groupHeaderHeight: 75,
            headerHeight: 150,
            floatingFiltersHeight: 50,
            pivotGroupHeaderHeight: 50,
            pivotHeaderHeight: 100,
            suppressRowTransform: true
        };
        var itClass = "row col-xl-12 col-lg-12 col-sm-12"
        return (
            <div class="container-fluid" style={{ padding: "0" }}>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>

                <div className="col-xl-12 col-lg-12">
                    <div className="f16" style={{ marginBottom: "10px" }}>
                    <ul className="d-tabs">
                            <li className={"lttabs"}>
                                <a href="/shareofbusiness" style={{ color: "#000" }} type="button" className="btn">SMG SOB</a>
                            </li>

                            <li className={"lttabs"}>
                                <a href="/shareofbusinessinterplant" style={{ color: "#000" }} type="button" className="btn">Inter Plant SOB</a>
                            </li>
                            <li className={"lttabs activet"}>
                                <a href="#0" style={{ color: "#fff" }} type="button" className="btn">TKM Plan VS Actual</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row col-xl-12 col-lg-12">
                    <form method="POST" className="form-theme col-xl-12 col-lg-12 row">
                        <div className="col-xl-2 col-lg-2 form-group">
                            <label>Filter By Month</label>
                            {/* <input type="text" placeholder="Select Month" id="plan_month" autocomplete="off" className="datetimepicker_date form-control"  required /> */}
                            <Datetime
                                value={this.state.plan_month}
                                disableCloseOnClickOutside={false}
                                closeOnSelect={true}
                                inputProps={{
                                    placeholder: 'Select Month',
                                    name: 'plan_month',
                                    autoComplete: 'off'
                                }}
                                dateFormat="YYYY-DD"
                                name="plan_month"
                                onChange={this.handlerStartDateTime.bind(this)}
                            />
                        </div>
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>&nbsp;</label><br />
                            <button type="button" className="btn btn-success" style={{ padding: "5px 10px", marginRight: "2%" }} onClick={this.filterDataByMonth.bind(this)}>Submit</button>

                        </div>
                    </form>
                </div>

                <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                    <div className="card">
                        <div className="card-body n-p-0">
                            <div className="crm-numbers pb-0">
                                <div className="row">
                                    {(this.state.countersData.map((c) =>
                                        <div className="col cirlce-d">
                                            <span className="f15" style={{ color: "#db3031", fontWeight: "bold" }}>{c.location}</span>
                                            <div className="row col-xl-12 col-lg-12 col-sm-12">
                                                {(c.itemData.map((d) =>
                                                ((c.itemData.length > 1) ?
                                                    <div className="col-xl-6 col-lg-6 col-sm-6" style={{ marginTop: "5%", padding: "0" }}>
                                                        <span className="f13">{d.itemName}</span>
                                                        <div className="row col-xl-12 col-lg-12 col-sm-12" style={{ padding: "0" }}>
                                                            <div className="col-xl-6 col-lg-6 col-sm-6" style={{ padding: "0", marginTop: "5%" }}>
                                                                <span className="f11">PLAN</span>
                                                                <h5 className={"f25 mt-20p " + c.cntClass}>
                                                                    <span className="counter">
                                                                        <CountUp end={(d.locationTotal) ? d.locationTotal : 0} />
                                                                    </span>
                                                                </h5>
                                                            </div>
                                                            <div className="col-xl-6 col-lg-6 col-sm-6" style={{ padding: "0", marginTop: "5%" }}>
                                                                <span className="f11">ACTUAL</span>
                                                                <h5 className={"f25 mt-20p " + c.cntClass}>
                                                                    <span className="counter">
                                                                        <CountUp end={(d.locActualTotal) ? d.locActualTotal : 0} />
                                                                    </span>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-xl-12 col-lg-12 col-sm-12" style={{ marginTop: "5%" }}>
                                                        <span className="f13">{d.itemName}</span>
                                                        <div className="row col-xl-12 col-lg-12 col-sm-12" style={{ marginTop: "5%" }}>
                                                            <div className="col-xl-6 col-lg-6 col-sm-6">
                                                                <span className="f13">PLAN</span>
                                                                <h5 className={"f25 mt-20p " + c.cntClass}>
                                                                    <span className="counter">
                                                                        <CountUp end={(d.locationTotal) ? d.locationTotal : 0} />
                                                                    </span>
                                                                </h5>
                                                            </div>
                                                            <div className="col-xl-6 col-lg-6 col-sm-6">
                                                                <span className="f13">ACTUAL</span>
                                                                <h5 className={"f25 mt-20p " + c.cntClass}>
                                                                    <span className="counter">
                                                                        <CountUp end={(d.locActualTotal) ? d.locActualTotal : 0} />
                                                                    </span>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="card col-xl-12" style={{ padding: "0" }}>
                    <div className="card-header">
                        <h5>
                            <span>TKM Plan VS Actual</span>
                            <button type="button" style={{ float: "right", marginRight: "10px" }} onClick={this.deleteTransporter.bind(this)} className="btn btn-danger float-right">Delete Transporter</button>

                            <button type="button" style={{ float: "right", marginRight: "10px" }} onClick={this.showBulkUpload.bind(this)} className="btn btn-info float-right">Add New Transporter</button>

                            <button type="button" style={{ float: "right", marginRight: "10px" }} onClick={this.showLogData.bind(this)} className="btn btn-primary float-right">Changes Log</button>
                            <button type="button" style={{ float: "right", marginRight: "10px" }} onClick={this.showCompleteData.bind(this)} className="btn btn-warning float-right">Complete Data</button>
                        </h5>
                    </div>
                    <div className="card-body col-xl-12 col-lg-12" style={{ padding: "30px 5px 5px 5px" }}>
                        {(this.state.last_updated_on) != '' ?
                            <div className="col-xl-12 col-lg-12" style={{ height: "35px" }}>
                                <p style={{ float: "right" }}>Last Updated On : {this.state.last_updated_on}</p>
                            </div>
                            : ""}
                        <div className="col-xl-12 col-lg-12" style={{ padding: "0px" }}>
                            <div id="myGrid" style={{ width: "100%", height: "800px" }} className={"ag-theme-balham ag-vertical-header " + this.state.showGridData}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={this.state.columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    enableCharts={false}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={false}
                                    rowClassRules={this.state.rowClassRules}
                                    gridOptions={gridOptions}
                                    enableRangeSelection={true}
                                    onCellEditingStopped={this.onCellClicked}
                                // components={this.state.components}
                                //statusBar={this.state.statusBar}
                                //sideBar={this.state.sideBar}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                />
                            </div>
                        </div>
                        <button style={{ marginTop: "2%" }} type="button" onClick={this.formAssignHandlerWithOutMail} className="btn btn-primary float-right">Update Now</button>
                        <button style={{ marginTop: "2%", marginRight: "2%" }} type="button" onClick={this.formAssignHandler} className="btn btn-danger float-right">Update &#38; Send Mail</button>
                    </div>
                </div>

                {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
                <div className={"slide-r " + (this.state.bulkslide)} style={{ overflow: "hidden" }}>
                    {(this.state.sliderType == 1) ?
                        <h3 className="subH">Add Transporter</h3> : ""}
                    {(this.state.sliderType == 2) ?
                        <h3 className="subH">Delete Transporter</h3> : ""}
                    {(this.state.sliderType == 3) ?
                        <h3 className="subH">Change Log</h3> : ""}
                    {(this.state.sliderType == 4) ?
                        <h3 className="subH">Complete Data</h3> : ""}
                    <div className="slide-r-body" style={{ position: "relative" }}>

                    <div className="container-fluid">
                            {(this.state.sliderType == 1 || this.state.sliderType == 2) ?
                                <form method="POST" id="upform" className="theme-form" onSubmit={this.addNewTransporter}>
                                    <div className="form-group mt-20p">
                                        <label className="">Select Month</label>
                                        <Datetime
                                            value={this.state.select_month}
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            inputProps={{
                                                placeholder: 'Select Month',
                                                name: 'select_month',
                                                autoComplete: 'off'
                                            }}
                                            dateFormat="YYYY-DD"
                                            name="select_month"
                                            onChange={this.handlerStartDateTimeForNewTransporter.bind(this)}
                                        />
                                    </div>

                                    <div className="form-group mt-20p">
                                        <label className="">Source</label>
                                        <select name="add_source" id="add_source" className="form-control" onChange={this.changesourceHandler.bind(this)} required>
                                            <option value="">Select Source</option>
                                            {(this.state.source.map((sd) =>
                                                <option value={sd}>{sd}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="form-group mt-20p">
                                        <label className="">Destination</label>
                                        <select name="add_destination" id="add_destination" className="form-control" onChange={this.changesourceHandler.bind(this)} required>
                                            <option value="">Select Destination</option>
                                            {(this.state.destination.map((sd) =>
                                                <option value={sd}>{sd}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className={"form-group mt-20p "}>
                                        {/* <label className="">Operation</label>
                                        <Select
                                            placeholder={"Operation"}
                                            closeMenuOnSelect={true}
                                            isMulti="true"
                                            className={"border-radius-0"}
                                            style={{ borderRadius: "0px" }}
                                            options={this.selectOperations()}
                                            onChange={this.changeOperations.bind(this)}
                                        /> */}
                                        <label className="">Item Type</label>
                                        <select name="add_item_type" id="add_item_type" className="form-control" onChange={this.changeLocationHandler.bind(this)} required>
                                            <option value="">Select Item Type</option>
                                            {(this.state.items.map((it) =>
                                                <option value={it}>{it}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group mt-20p">
                                        <label className="">LSP</label>
                                        <select name="add_lsp" id="add_lsp" className="form-control" required>
                                            <option value="">Select LSP</option>
                                            {(this.state.transportersList.map((t) =>
                                                <option value={t.alias}>{t.alias}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        {(this.state.sliderType == 1) ?
                                            <button type="submit" className="btn btn-success">Submit</button> :
                                            <button type="submit" className="btn btn-success">Delete</button>
                                        }
                                        <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                    </div>
                                </form> :
                                 (this.state.sliderType == 3) ?
                                 <SOBchangelogs logHistory={this.state.logHistory}></SOBchangelogs> : <SobCompleteData completeData={this.state.completeData}>  </SobCompleteData>
                            }
                        </div>
                    </div>
                </div>

                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>

        );
    }
}

function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_date').datetimepicker({
        //mask:'39-19-9999',
        format: 'Y-m',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}
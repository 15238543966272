'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import { getHyphenDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import download from 'js-file-download';

// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class PreclosureExportsgridData extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-m",
        loadshow:"show-m",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        ffRowData : [],
        invoiceData:[]
        //components : {datepicker:getDatePicker()}
    }
}
componentDidMount(){

    var ffRowData = this.props.ffRowData;
    console.log(ffRowData,"ffRowData")
    this.setState({
        ffRowData : ffRowData,
    })
}
onRecommGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onRecommGridState = () =>{
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
    * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
            
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    invoiceData:[],
    erpData:[],
    ringiData:[],
    pickupData:[],
    rejectedreason:"",
    
    });
    
}

showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}

closeAlert = () => {
    this.setState({
        show: false
    });
}



viewDetails(e){
    if(e.colDef.field == "ringiData")
    {
        var ringiData = JSON.parse(e.data.ringiData);
        var sliderTitle = e.data.job_no+" - Payment Ringi Details";
        this.setState({
            overly :"show-m",
            ringiData:ringiData,
            bulkslide : "slider-translate-40p",
            sliderTitle : sliderTitle
        })
    }
    else if(e.colDef.field == "pickupData")
    {
        var pickupData = JSON.parse(e.data.pickupData);
        var sliderTitle = e.data.job_no+" - Cargo Details";
        this.setState({
            overly :"show-m",
            pickupData:pickupData,
            bulkslide : "slider-translate-40p",
            sliderTitle : sliderTitle
        })
    }
    else if(e.colDef.field == "closed")
    {
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        });
        var rowId = e.data._id;
        if(rowId !=undefined && rowId !='')
        {
            var params = {
                rowId : rowId
            }
            redirectURL.post("/consignments/closeAirCargoRequest",params)    
            .then((response) => {
                if(response.data.message == "success")
                {
                    this.setState({
                        basicTitle:"Successfully marked as Closed",
                        basicType : "success",
                        show : true,
                        overly:"show-n",
                        loadshow:"show-n",
                    })
                    //window.location.reload();
                    this.onLoadPageData(4);
                }
                else
                {
                    this.setState({
                        basicTitle:"Failed to mark as closed.",
                        basicType : "warning",
                        show : true,
                        loadshow:'show-n',
                        overly:'show-n',
                    })
                }
            })
            .catch(function(e){
                console.log("Error ",e)
            })
        }
    }
    else if(e.colDef.field == "reject")
    {
        var rowId = e.data._id;
        if(rowId !=undefined && rowId !='')
        {
            var sliderTitle = e.data.job_no+" - Reject Reason";
            $("#reject_reason").val("");
            this.setState({
                rejectRowId : rowId,
                rejectedreason : 1,
                overly :"show-m",
                bulkslide : "slider-translate-40p",
                sliderTitle : sliderTitle
            });
        }
    }
    else if(e.colDef.field == "file")
    {
        var ringiFile = e.value;
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            redirectURL.post("/consignments/downloadExportsRingiFile",rngParam,{
                responseType: 'blob'
            }).then((response)=>{
                console.log("dowlad response ",response)
                download(response.data, ringiFile)
            }).catch(function(error){
                console.log(error);
            })
        }
    }
    else if(e.colDef.field == "file")
    {
        var ringiFile = e.value;
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            redirectURL.post("/consignments/downloadExportsRingiFile",rngParam,{
                responseType: 'blob'
            }).then((response)=>{
                console.log("dowlad response ",response)
                download(response.data, ringiFile)
            }).catch(function(error){
                console.log(error);
            })
        }
    }
}

downloadRingiFile = (ringiFile) => {
    //console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/downloadExportsRingiFile",rngParam,{
            responseType: 'blob'
        }).then((response)=>{
            console.log("dowlad response ",response)
            download(response.data, ringiFile)
        }).catch(function(error){
            console.log(error);
        })
    }
}

  render() {
    var columnwithDefs = [      
        {
            field:'_id',
            headerName:'',
            colId:"_id",
            //pinned:"left",
            width: 72,
            cellRenderer:function(params){
                let basestring = params.data._id;
                let encryptedstring = window.btoa(basestring);
                if(params.data.pending_with == 1)
                {
                    var editUrl = "/editffaircargoexportsform";
                }
                else
                {
                    var editUrl = "/editchaaircargoexportsform";
                }
                var htmloption = '<a href="'+editUrl+'/'+encryptedstring+'/2" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
                return htmloption;
            }            
        },
        {
            headerName: "Job No",
            field: "job_no",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Invoice No",
            field: "commercial_invoice",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Pending With",
            field: "pending_with",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.pending_with == 1)
                {
                    return "Freight Forwarder";
                }
                else
                {
                    return "CHA";
                }
            }         
        },
        {
            headerName: "Fright Forwarder",
            field: "assign_to_ff",
            width:180,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "CHA",
            field: "assign_to_cha",
            width:180,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Date",
            field: "date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.date !=undefined && params.data.date !='')
                {
                    return moment.parseZone(params.data.date).format("DD-MM-YYYY")
                }
            }
        },
        {
            headerName: "Department Name",
            field: "dept_name",
            width:120,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Indenter Name",
            field: "indenter_name",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Mobile No",
            field: "mobile",
            width:100,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Indenter Email",
            field: "indenter_email",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Request Type",
            field: "request_type",
            width:150,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.request_type == 1)
                {
                    return "Booking Request";
                }
                else if(params.data.request_type == 2)
                {
                    return "Prepaid Shipment";
                }
                else
                {
                    return "FF New Request";
                }
            }           
        },
        {
            headerName: "Submitted By",
            field: "created_by_user",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Payment Ringi Data",
            field: "ringiData",
            width:100,
            filter: true,
            resizable: true,
            cellRenderer:function(params){
                let basestring = params.data.batch_name;
                let encryptedstring = window.btoa(basestring);
                var htmloption = '<button class="btn btn-danger" style="padding-top:0px">View</button>';
                return htmloption;
            }
        },
        {
            headerName: "Pick Up Plant",
            field: "pickup_plant",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "No Of Packets",
            field: "no_of_packets",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Cargo Data",
            field: "pickupData",
            width:130,
            filter: true,
            resizable: true,
            cellRenderer:function(params){
                let basestring = params.data.batch_name;
                let encryptedstring = window.btoa(basestring);
                var htmloption = '<button class="btn btn-primary" style="padding-top:0px">View</button>';
                return htmloption;
            }         
        },
        {
            headerName: "Consignee Name",
            field: "consignee_name",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Consignee Address",
            field: "consignee_address",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Consignee contact person",
            field: "consignee_contact_person",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Invoice",
            field: "file",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                return params.data.invoice_file;
            }
        },
        {
            headerName: "Packing List",
            field: "file",
            width:130,
            filter: true,
            resizable: true,    
            valueGetter: function(params){
                return params.data.packing_list_file;
            }
        },
        {
            headerName: "SQC List",
            field: "file",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                return params.data.sqc_list_file;
            }
        },
        {
            headerName: "Annex II",
            field: "file",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                return params.data.annex_file;
            }
        },
        {
            headerName: "GR wavier",
            field: "file",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                return params.data.gr_wavier_file;
            }
        },
        {
            headerName: "MSDS",
            field: "file",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                return params.data.msds_file;
            }
        },
        {
            headerName: "IIP certificate (Un packing)",
            field: "file",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                return params.data.iip_file;
            }
        },
        {
            headerName: "Country",
            field: "country",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Destination Airport",
            field: "destination_airport",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Email id",
            field: "email",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Phone No",
            field: "phone",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Delivery/Inco Terms",
            field: "delivery_terms",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Delivery address",
            field: "pick_up_address",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.pick_up_address !=undefined && params.data.pick_up_address !="undefined")
                    {
                        return params.data.pick_up_address;
                    }
                }
                catch(e){

                }
            }           
        },
        {
            headerName: "Proposed cargo readiness Date",
            field: "cargo_date",
            width:180,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.cargo_date !=undefined && params.data.cargo_date !='')
                {
                    return moment.parseZone(params.data.cargo_date).format("DD-MM-YYYY")
                }
            }           
        },
        {
            headerName: "Commercial Invoice Date",
            field: "commercial_invoice_date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.commercial_invoice_date !=undefined && params.data.commercial_invoice_date !='')
                {
                    return moment.parseZone(params.data.commercial_invoice_date).format("DD-MM-YYYY")
                }
            }             
        },
        {
            headerName: "Payment Terms",
            field: "payment_terms",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.payment_terms !=undefined && params.data.payment_terms !="undefined")
                    {
                        return params.data.payment_terms;
                    }
                }
                catch(e){

                }
            }           
        },
        {
            headerName: "Shipment Return",
            field: "shipment_return",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.shipment_return !=undefined && params.data.shipment_return !="undefined")
                    {
                        return params.data.shipment_return;
                    }
                }
                catch(e){

                }
            }          
        },
        {
            headerName: "Certificate of origin",
            field: "cert_of_origin",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.cert_of_origin !=undefined && params.data.cert_of_origin !="undefined")
                    {
                        return params.data.cert_of_origin;
                    }
                }
                catch(e){

                }
            }         
        },
        {
            headerName: "DG",
            field: "dg",
            width:130,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.dg !=undefined && params.data.dg !="undefined")
                    {
                        return params.data.dg;
                    }
                }
                catch(e){

                }
            }           
        },
        {
            headerName: "Freight Forworder",
            field: "assign_to_ff",
            width:130,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.assign_to_ff !=undefined && params.data.assign_to_ff !="undefined")
                    {
                        return params.data.assign_to_ff;
                    }
                }
                catch(e){

                }
            }           
        },
        {
            headerName: "FF Contact person Details",
            field: "ff_contact_person_details",
            width:150,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.ff_contact_person_details !=undefined && params.data.ff_contact_person_details !="undefined")
                    {
                        return params.data.ff_contact_person_details;
                    }
                }
                catch(e){

                }
            }           
        },
        {
            headerName: "Rejected Reason",
            field: "reject_reason",
            width:150,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Vehicle No",
            field: "vehicle_no",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Expected Pickup From MSIL",
            field: "exp_pickup_from_msil",
            width:150,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.exp_pickup_from_msil !='' && params.data.exp_pickup_from_msil !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.exp_pickup_from_msil);
                    }
                }
                catch(e){
                }
            }            
        }, 
        {
            headerName: "Actual Pickup From MSIL",
            field: "actual_pickup_from_msil",
            width:120,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.actual_pickup_from_msil !='' && params.data.actual_pickup_from_msil !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.actual_pickup_from_msil);
                    }
                }
                catch(e){
                }
            }            
        },
        {
            headerName: "GR No",
            field: "gr_no",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "GST No",
            field: "gst_no",
            width:130,
            filter: true,
            resizable: true,           
        }, 
        {
            headerName: "ATA IGI Airport",
            field: "ata_igi_airport_date",
            width:120,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.ata_igi_airport_date !='' && params.data.ata_igi_airport_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.ata_igi_airport_date);
                    }
                }
                catch(e){
                }
            }
        },
        {
            headerName: "Checklist Date",
            field: "checklist_filling_date",
            width:120,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.checklist_filling_date !='' && params.data.checklist_filling_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.checklist_filling_date);
                    }
                }
                catch(e){
                }
            }            
        },
        {
            headerName: "Shipping Bill No",
            field: "shipping_bill_no",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Shipping Bill Date",
            field: "shipping_bill_date",
            width:120,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.shipping_bill_date !='' && params.data.shipping_bill_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.shipping_bill_date);
                    }
                }
                catch(e){
                }
            }            
        },
        {
            headerName: "Shipping Bill",
            field: "shipping_bill",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "LEO Date",
            field: "leo_date",
            width:120,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.leo_date !='' && params.data.leo_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.leo_date);
                    }
                }
                catch(e){
                }
            }          
        },
        {
            headerName: "DBK Amount",
            field: "dbk_amount",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "RODTEP Amount",
            field: "rodtep_amount",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Demmurage Charges",
            field: "demmurage_charges",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Demmurage Charges Amount",
            field: "demmurage_charges_amount",
            width:150,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Demmurage Start Date",
            field: "demmurage_start_date",
            width:120,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.demmurage_start_date !='' && params.data.demmurage_start_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.demmurage_start_date);
                    }
                }
                catch(e){
                }
            }          
        },
        {
            headerName: "Demmurage End Date",
            field: "demmurage_end_date",
            width:120,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.demmurage_end_date !='' && params.data.demmurage_end_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.demmurage_end_date);
                    }
                }
                catch(e){
                }
            }          
        },
        {
            headerName: "Reason For Delay",
            field: "reason_for_delay",
            width:120,
            filter: true,
            resizable: true,          
        },,
        {
            headerName: "Handover date at IGI",
            field: "hand_over_date_at_igi",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.hand_over_date_at_igi !=undefined && params.data.hand_over_date_at_igi !="undefined")
                {
                    return moment.parseZone(params.data.hand_over_date_at_igi).format("DD-MM-YYYY")
                }
            }           
        },
        {
            headerName: "Final shipment receiving date",
            field: "final_shipment_receiving_date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.final_shipment_receiving_date !=undefined && params.data.final_shipment_receiving_date !='')
                {
                    return moment.parseZone(params.data.final_shipment_receiving_date).format("DD-MM-YYYY")
                }
            }         
        },
        {
            headerName: "Airway Bill No",
            field: "air_way_bill_no",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Airway bill date",
            field: "air_way_bill_date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.air_way_bill_date !=undefined && params.data.air_way_bill_date !='')
                {
                    return moment.parseZone(params.data.air_way_bill_date).format("DD-MM-YYYY")
                }
            }         
        },
        {
            headerName: "Airway bill",
            field: "file",
            width:130,
            filter: true,
            resizable: true,           
        },   
        {
            headerName: "Airline Name",
            field: "airline_name",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "ETD",
            field: "etd",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.etd !=undefined && params.data.etd !='')
                {
                    return moment.parseZone(params.data.etd).format("DD-MM-YYYY")
                }
            }            
        },
        {
            headerName: "ATD",
            field: "atd",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.atd !=undefined && params.data.atd !='')
                {
                    return moment.parseZone(params.data.atd).format("DD-MM-YYYY")
                }
            }            
        },  
        {
            headerName: "Transit Port",
            field: "transit_port",
            width:130,
            filter: true,
            resizable: true,           
        },  
        {
            headerName: "Transit Port Airline",
            field: "transit_port_airline",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "ETA Transit Port",
            field: "eta_transit_port",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.eta_transit_port !=undefined && params.data.eta_transit_port !='')
                {
                    return moment.parseZone(params.data.eta_transit_port).format("DD-MM-YYYY")
                }
            }            
        },
        {
            headerName: "ATA Transit Port",
            field: "ata_transit_port",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.ata_transit_port !=undefined && params.data.ata_transit_port !='')
                {
                    return moment.parseZone(params.data.ata_transit_port).format("DD-MM-YYYY")
                }
            }            
        },
        {
            headerName: "ETD Transit Port",
            field: "etd_transit_port",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.etd_transit_port !=undefined && params.data.etd_transit_port !='')
                {
                    return moment.parseZone(params.data.etd_transit_port).format("DD-MM-YYYY")
                }
            }            
        },
        {
            headerName: "ATD Transit Port",
            field: "atd_transit_port",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.atd_transit_port !=undefined && params.data.atd_transit_port !='')
                {
                    return moment.parseZone(params.data.atd_transit_port).format("DD-MM-YYYY")
                }
            }            
        },
        {
            headerName: "Final Port Name",
            field: "final_port_name",
            width:150,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "ETA Destination Port",
            field: "eta_destination_port",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.eta_destination_port !=undefined && params.data.eta_destination_port !='')
                {
                    return moment.parseZone(params.data.eta_destination_port).format("DD-MM-YYYY")
                }
            }            
        },
        {
            headerName: "ATA Destination Port",
            field: "ata_destination_port",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.ata_destination_port !=undefined && params.data.ata_destination_port !='')
                {
                    return moment.parseZone(params.data.ata_destination_port).format("DD-MM-YYYY")
                }
            }            
        }
    ]

        
    return (
        <div class="row">
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}
            >
            </SweetAlert>
            <div className="col-xl-12">
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham"}>
                <AgGridReact
                    modules={this.state.modules}
                    //columnDefs={columnwithDefs}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.props.ffRowData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onRecommGridReady}
                    onGridState={this.onRecommGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    onCellClicked={this.viewDetails.bind(this)}
                    // components={this.state.components}
                    />
                </div>
            </div>
            <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">
                {this.state.sliderTitle}
                <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
            </h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                {(this.state.invoiceData.length > 0) ?                         
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered tptinfo">
                                    <thead>
                                        <tr>
                                            <th>Documents</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.invoiceData.map((e, index) => (
                                        <tr>
                                            <td style={{cursor:"pointer"}} onClick={() => this.downloadRingiFile(e.invoice_file)}>{e.invoice_file}</td>
                                        </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                : ""}
            </div>
        </div>
        </div>
        

    );
  }
}
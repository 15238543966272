import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

var redirectURL = require('../redirectURL');
export default class GridComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modules: AllModules,
            defaultColDef: {
            sortable: true,
                filter: true,
                resizable: true
            
            },
            rowData: null,            
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single" ,
            context: { componentParent: this },
            frameworkComponents: {
               
            },
            ///usergridstate:[],
            screenurl:"",
            screentitle:"",
            columnwithDefs:[],
        };
        // this.createColumns = this.createColumns.bind(this);
        // this.createRowData = this.createRowData.bind(this);
        
    }
    componentDidMount =async() =>{
        // await redirectURL.post("/consignments/alltruckAvailabledata")
        // .then((response) => {
        //     var records = response.data;
        //     console.log("respnse ", records)
        //     var categories = [];
        //     if(records.length > 0)
        //     {
        //         records.map((item) => {
        //             categories.push({
        //                 category:item.truck_category,
        //                 truck_type:item.truck_type
        //             });
        //            // categories.push(item.truck_category);
        //         })
        //     }
        //     console.log("categoriescategories ", categories)
        //     this.setState({
        //         categories:categories,
        //         transporters:records
        //     })
        // })
        // await this.createColumns();
        // await this.createRowData();
    }

    // createColumns()
    // {
    //     var columns=[]
    //     var transporters = this.state.transporters;
    //     columns.push({
    //         headerName:"Transporter Name",
    //         field:"transporter_name",
    //         width:180
    //     })
    //     var categories = this.state.categories;
    //     console.log("categories ",categories)
    //     if(categories.length > 0)
    //     {
    //         // categories.map((item)=>{
    //         //     var def = {
    //         //         headerName:item
    //         //     }
    //         // })
    //     }

    // }
    // createRowData(){

    // }
	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	};
	

	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		    
		  
		   
     }
     
    // componentWillReceiveProps(newprops){
    //     console.log("newprops ", newprops)
    //     this.setState({
    //         rowData:newprops.rowdefs,
    //         columnwithDefs:newprops.columns
    //     })
    //     this.gridApi.redrawRows({ rowNodes: newprops.rowdefs });
    // }
    render(){
    	var columnwithDefs  = [
            {
                headerName:"Transporter Name",
                field:"tranporter_name",
                width:180
            }
        ]
        
        return ( 
        		<div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div id="myGrid" style={{ width: "100%"}}  className="ag-theme-balham">
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={this.props.columns}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.props.rowdefs}
                                enableCharts={true}
                                enableRangeSelection={true}
                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                paginationPageSize={this.state.paginationPageSize}
                                pinnedBottomRowData={this.props.pinnedRegionBottomRowData}
                                pagination={true}
                                frameworkComponents={this.state.frameworkComponents}
                                detailCellRendererParams={this.state.detailCellRendererParams}
                                stopEditingWhenGridLosesFocus={true}
                                enableCellChangeFlash={false}
                                rowClassRules={this.state.rowClassRules}
                                gridOptions={{
                                    context: { componentParent: this },
                                    getRowStyle: function (params) {
                                        if (params.node.rowPinned) {
                                          return { 'font-weight': 'bold','font-size':'16px' };
                                        }
                                      },
                                }}
                                domLayout = {'autoHeight'}
                                masterDetail={true}
                                rowSelection={this.state.rowSelection}
                                suppressRowClickSelection={true}
                                onPaginationChanged={this.resetPaginationSelection}
                                
                            />
                        </div>
                    </div>
      		    </div>
                    
			);
		}
}
















'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
// import Consignmentforceclose from '../m';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';

import {verifyext} from "../common/verifyext";

// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class RpAutoRakePlanning extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        // statusPanels: [
        //   {
        //   statusPanel: "agTotalAndFilteredRowCountComponent",
        //   align: "left"
        //   },
        //   {
        //   statusPanel: "agTotalRowCountComponent",
        //   align: "center"
        //   },
        //   { statusPanel: "agFilteredRowCountComponent" },
        //   { statusPanel: "agSelectedRowCountComponent" },
        //   { statusPanel: "agAggregationComponent" }
        // ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            // {
            // id: "columns",
            // labelDefault: "Columns",
            // labelKey: "columns",
            // iconKey: "columns",
            // toolPanel: "agColumnsToolPanel"
            // },
            // {
            // id: "filters",
            // labelDefault: "Filters",
            // labelKey: "filters",
            // iconKey: "filter",
            // toolPanel: "agFiltersToolPanel"
            // }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        loadFile : "",
        processErrMsg : "",
        processErrShow : "",
        transactionId : "",
        droppedList : [],
        statusCode : "",
        pinnedRegionBottomRowData:[],
        totalCars : 0,
        loadShareDetails : [],
        columns : [],
        rowdefs : [],
        rakeAvailability : [],
        noGpsRakes : [],
        //components : {datepicker:getDatePicker()}
    }
    this.onLoadPageData = this.onLoadPageData.bind(this);
    this.changeFileHandler = this.changeFileHandler.bind(this);
    this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this)
  }

  logPageView = () => {
      try {
          if (googleAnalytics.page.enableGA) {
              googleAnalytics.logPageView();
              let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
              let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
              let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
              this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
          } else {
              console.log("GA disabled...");
          }
      } catch(error) {
          console.error("Error occured while logging to GA, error = ", error);
      }
  }  
  
componentDidMount(){
    this.logPageView();
    redirectURL.post("/rakeplanning/getRakeAvailability").then((response)=>{
        this.setState({
            rakeAvailability : response.data
        })
    }).catch((e)=>{
        console.log(e);
    })
    this.onLoadPageData();
}

onLoadPageData(){
    redirectURL.post("/rakeplanning/getLoadShareConfig").then(async (response)=>{
        console.log(response.data);
        var respData = response.data;
        var totalCars = 0;
        respData.map(function(rd){
            totalCars = parseInt(totalCars)+parseInt(rd.cars)
        })
        var pinnedBottomRowData = {}
        pinnedBottomRowData['loading_cluster'] = "";
        pinnedBottomRowData['unloading_cluster'] = "Total";
        pinnedBottomRowData['cars'] = Math.round(totalCars);
        await this.setState({
            loadShareDetails : response.data,
            pinnedRegionBottomRowData : [pinnedBottomRowData],
            totalCars : totalCars
        })
        
        await this.createColumns();
        await this.createRowData();
    }).catch((e)=>{
        console.log(e);
    })
}

createColumns()
{
    var columns=[]
    var loadShareDetails = this.state.loadShareDetails;
    columns.push({
        headerName:"City",
        field:"unloading_cluster",
        width:180,
        editable:false,
    })
    var categories = this.state.categories;
    
    
    var loading_cluster = groupBy(loadShareDetails, rdata => rdata.loading_cluster);
    console.log("loading_cluster ", loading_cluster)
    //var defs = []
    loading_cluster.forEach((wares, wkeys) => {
        columns.push({
            headerName:wkeys+" Cars",
            headerClass:["textAlignCenter"],
            field:wkeys,
            width:160,
            cellClass:["textAlignCenter"],
            cellStyle: {borderLeft: '1px solid #ccc'},
            editable:true,
        })
        //columns.push(defs);
    })
    columns.push({
        headerName:"Total",
        headerClass:["textAlignCenter"],
        field:"total",
        width:100,
        pinned:"right",
        cellClass:["textAlignCenter"],
        cellClass:"boxbgblue",
        editable:false,
    })
    console.log("columns ", columns)
    this.setState({
        columns:columns
    })
}

createRowData(){
    var rowdefs = []
    var totalCnt = 0
    var loadShareDetails = this.state.loadShareDetails;
    var unloading_cluster = groupBy(loadShareDetails, rdata => rdata.unloading_cluster);
    console.log(unloading_cluster,"unloading_cluster")
    unloading_cluster.forEach((trans, transkey) =>{
        var rows = {}
        rows.unloading_cluster=transkey
        var rowcnt = 0
        if(trans.length > 0)
        {
            //console.log("trans ", trans)
            var loading_cluster = groupBy(trans, rdata => rdata.loading_cluster);
            loading_cluster.forEach((wares,wkey) => {
                //var vCnt = 0
                wares.map(function(k){
                    rows[wkey] = k.cars                
                    rowcnt = rowcnt+parseInt(k.cars)  
                });                  
            })
        }
        totalCnt = totalCnt+parseInt(rowcnt);
        rows["total"] = rowcnt
        rowdefs.push(rows)
    })
    console.log(rowdefs,"rowdefs")
    var pinnedBottomRowData = {}
    var temp = {};
    if(rowdefs.length > 0)
    {
        var gridkeys = Object.keys(rowdefs[0]);
        gridkeys.map(function(e){
            temp[e] = 0
        })
    }
    else
    {
        var gridkeys = [];
    }
    
    gridkeys = gridkeys.filter(function(e){return e != "unloading_cluster"})
    rowdefs.map(function(e, index){
        gridkeys.map(function(k)
        {
            var keyval;
            if(e[k] == "")
            {
                 keyval = 0;
            }else{ keyval = e[k]}
            temp[k] = temp[k] + keyval
        })
    })
    temp['Total'] = totalCnt;
    temp['unloading_cluster'] = "Total"
    console.log(temp, "pinnedBottomRowData")
    this.setState({
        rowData:rowdefs,
        pinnedRegionBottomRowData:[temp],
        overly:"show-n",
        loadshow:"show-n",
    })
    //console.log("rowdefs ", rowdefs)
}

onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
    * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
            
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    this.setState({
        show: false
    });
    //window.location.href="/lmloadsummary";
}

onCellClicked(e)
{
    if(e.colDef.field == "container_no")
    {
        console.log(e.data.container_no);
    }
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    $("#uploadFile").val("");
    $("#notify_email").val("");
}
updateLmStates(params)
{
    //console.log(params);
    if(params.vehicle_cap !='' && params.vehicle_cap !=undefined)
    {
        redirectURL.post("/lmconsignees/savelmvehiclecapacity",params).then((response)=>{
            console.log(response);
            if(response.data.message == "Success")
            {
                this.setState({
                    basicTitle:"Updated Successfully.",
                    basicType : "success",
                    show : true
                })
                window.location.reload();
            }
            else
            {
                this.setState({
                    basicTitle:"Failed to update.",
                    basicType : "warning",
                    show : true
                })
            }
        })
    }
    else
    {
        this.setState({
            basicTitle:"Fields Should not be Empty.",
            basicType : "warning",
            show : true
        })
    }
}
addNewRow()
{
    // console.log(this.gridApi);
    this.gridApi.insertItemsAtIndex(0,[{vehicle_cap:"",min_capacity_cmt:"",max_capacity_cmt:"",min_courier:"",nodes_per_truck:"",allowed_distance:""}])
    // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}

showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate-40p"
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        loadFile:''
    });
    $("#uploadFile").val("");
    $("#notify_email").val("");
    //document.getElementById("upform").reset();
}
fixProcess = () => {
    this.setState({
        processErrMsg : "",
        processErrShow : 0,
        loadFile:''
    });
    $("#uploadFile").val("");
    $("#notify_email").val("");
    //document.getElementById("upform").reset();
}
ignoreProcessError = () => {
    this.setState({
        loadshow:'show-m',
        overly:'show-m',
    });
    var transactionId = this.state.transactionId;
    var loading_port_code = this.state.loading_port_code;
    console.log("transactionId",transactionId)
    var params = {
        transactionId : transactionId,
        loading_port_code : loading_port_code,
    }
    redirectURL.post("/lmconsignees/generateLoadByTransactionId",params).then((response)=>{
        //console.log(response.data,"response")
        var statusCode = response.data.status;
        if(statusCode == 0 || statusCode == 1)
        {
            this.setState({                    
                basicTitle : response.data.message,
                basicType : "success",
                show : true,
                processErrMsg : "",
                processErrShow : 0,
                loadshow:'show-n',
                overly:'show-n',
                bulkslide:"",
            });
        }
        else
        {
            this.setState({
                processErrMsg : response.data.message,
                processErrShow : 1,
                transactionId : response.data.transactionId,
                loadshow:'show-n',
                overly:'show-n',
            });
        }
        //window.location.reload();
    });
}

changeFileHandler = async (e) => {
    console.log(e);
    this.setState({
        loadFile : e.target.files[0]
    });  
    
    var check = verifyext(e.target.files[0].name);
    //console.log("Check ", check)
    if(check == true)
    {
        if(e.target.files[0].type == '' ||e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
        {
            // console.log(fileData);
            // console.log(typeof(fileData))
            
        }
        else{
            e.target.value = null;
            this.setState({
                uploadFile:'',
                show: true, 
                basicType:'danger', 
                basicTitle:'Please upload file having extensions .csv only.',
            });
        }
    }
    else{
        e.target.value = null;
        this.setState({
            uploadFile:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'Please upload file having extensions .csv only.',
        });
    }
    
}

uploadBulkFormHandler = async() => {
    var rakeAvailability = this.state.rakeAvailability;
    if(rakeAvailability.length > 0)
    {
        var noGpsRakes = [];
        var currentDate = moment.parseZone().format('YYYY-MM-DD');
        rakeAvailability.map(function(r){
            if(r.active_trip == 0)
            {
                var timestamp = r.timestamp;
                if(timestamp !=undefined && timestamp !='')
                {
                    timestamp = moment.parseZone((timestamp)).format('YYYY-MM-DD');
                    if(moment(timestamp).isSame(currentDate) != true)
                    {
                        noGpsRakes.push(r);
                    }
                }
            }
        });
        console.log(noGpsRakes,"noGpsRakes")
        if(noGpsRakes.length > 0)
        {
            this.setState({
                noGpsRakes : noGpsRakes,
                overly :"show-m",
                bulkslide : "slider-translate-40p"
            });
        }
        else
        {
            this.generateRailPlan(0);
        }
    }
}

generateRailPlan = async(event) => {
    var flag = 0;
    let saveData = new FormData();
    let clusterShares = [];
    this.gridApi.forEachNode(node => clusterShares.push(node.data));
    var rowTotal = 0;
    var csdShares = [];
    var rwData = [];
    clusterShares.map(function(r,index){
        var rowKeys = Object.keys(r);
        console.log(rowKeys);
        rowKeys.map(function(rk){
            if(rk !="unloading_cluster" && rk !="total")
            {
                var depot_code = "";
                if(rk == "Gujarat")
                {
                    depot_code = "GUJ01";
                }
                else{
                    depot_code = "HAR01";
                }
                rwData.push({
                    loading_cluster : rk,
                    unloading_cluster : r.unloading_cluster,
                    depot_code : depot_code,
                    share : "",
                    cars : parseInt(r[rk]),
                });
            }
            else if(rk =="total")
            {
                rowTotal = parseInt(rowTotal)+parseInt(r[rk]);
            }
        });
    })
    console.log(rwData,"rwData")
    if(rowTotal > 0)
    {
        saveData.append("ignoreRakes", event);
        saveData.append("clusterShares", JSON.stringify(rwData));
        saveData.append("carsTotal", rowTotal);
        saveData.append("user_name", localStorage.getItem('username'))
        saveData.append("email", localStorage.getItem('email'))
        saveData.append("user_role", localStorage.getItem('roles'))
        console.log(saveData,saveData);
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        });
        redirectURL.post("/rakeplanning/generateNewRakePlan",saveData).then((response)=>{
            console.log(response,"responseFromProcess")
            var statusCode = response.data.status;
            if(statusCode == 8)
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    droppedList : response.data.droppedList,
                    statusCode : statusCode,
                    loadshow:'show-n',
                    overly:'show-n',
                });
            }
            else if(statusCode > 3 && statusCode != 8)
            {
                if(statusCode == 11)
                {
                    var processMsg = "Use the standard input data file template for uploading loads";
                }
                else
                {
                    var processMsg = response.data.message;
                }
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    statusCode : statusCode,
                    loadshow:'show-n',
                    overly:'show-n',
                });
            }
            else
            {
                this.setState({                    
                    basicTitle : response.data.message,
                    basicType : "success",
                    show : true,
                    loadshow:'show-n',
                    overly:'show-n',
                    bulkslide:"",
                });
            }
            window.location.href="/rprakeplansummary";
        });
    }
    else
    {
        this.setState({
            basicTitle : "Total number of cars should be greater than zero",
            basicType : "warning",
            show : true,
        })
    }
};




onSelectBulkPlants= (location) =>{
    var plants=[];
    try{
        location.map(function(e){
            plants.push(e.value);
        })
        console.log(plants);
        this.setState({
            plant_name : plants
        });
    }
    catch(e)
    {
        console.log(e);
    }
    
}

onCellUpdateData = async(event) => {
    let clusterShares = [];
    this.gridApi.forEachNode(node => clusterShares.push(node.data));
    var haryanaTotal = 0;
    var gujaratTotal = 0;
    var csdShares = [];
    console.log(clusterShares,"clusterShares")
    var rtTl = 0;
    clusterShares.map(function(r,index){
        var haryanaCar = 0;
        if(r.Haryana !="" && r.Haryana !=undefined && r.Haryana !=NaN)
        {
            haryanaCar = parseInt(r.Haryana)
        }
        haryanaTotal = parseInt(haryanaCar)+parseInt(haryanaTotal);
        var gujaratCar = 0;
        if(r.Gujarat !="" && r.Gujarat !=undefined && r.Gujarat !=NaN)
        {
            gujaratCar = parseInt(r.Gujarat)
        }
        gujaratTotal = parseInt(gujaratCar)+parseInt(gujaratTotal);
        r.total = parseInt(haryanaCar)+parseInt(gujaratCar);
        rtTl = parseInt(rtTl)+parseInt(haryanaCar)+parseInt(gujaratCar);
        csdShares.push({
            unloading_cluster : r.unloading_cluster,
            Haryana : r.Haryana,
            Gujarat : r.Gujarat,
            total : r.total
        })
    })
    console.log(haryanaTotal)
    var pinnedBottomRowData = {}
    pinnedBottomRowData['unloading_cluster'] = "Total";
    pinnedBottomRowData['Haryana'] = haryanaTotal;
    pinnedBottomRowData['Gujarat'] = gujaratTotal;
    pinnedBottomRowData['total'] = rtTl;
    //pinnedBottomRowData['Total'] = 0;
    //pinnedBottomRowData['cars'] = Math.round(rowTotal);
    this.gridApi.setRowData(csdShares)
    this.setState({
        pinnedRegionBottomRowData : [pinnedBottomRowData],
        //totalCars : rowTotal
    })
}
  render() {
    
    var columnwithDefs = [      
        {
            headerName: "Loading Cluster",
            field: "loading_cluster",
            width:180,
            filter: true,
            resizable: true,
            editable:false,            
        },
        {
            headerName: "Depot Code",
            field: "depot_code",
            width:180,
            filter: true,
            resizable: true,
            editable:false,
            hide:true,
        },
        {
            headerName: "Unloading Cluster",
            field: "unloading_cluster",
            width:180,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "City Code",
            field: "city_code",
            width:160,
            filter: true,
            resizable: true,
            editable:false,
            hide:true,
        },
        {
            headerName: "Share",
            field: "share",
            width:150,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: NumericCellEditor,
            hide:true,
        },
        {
            headerName: "Cars",
            field: "cars",
            width:150,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: NumericCellEditor,
        },
    ]
    
    var columnwithDefsForDropped = [      
        {
            headerName: "Dealer",
            field: "dealer_code",
            width:130,
            filter: true,
            resizable: true,
            editable:false,            
        },
        {
            headerName: "Demand",
            field: "demand",
            width:130,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Remarks",
            field: "remarks",
            width:200,
            filter: true,
            resizable: true,
            editable:false
        },
    ]
    

    var columnwithDefsForNoGps = [   
        {
            headerName: "Rake No",
            field: "rake_no",
            width:150,
            filter: true,
            resizable: true,
            editable:false,     
        },
        {
            headerName: "Rake Capacity",
            field: "rake_capacity",
            width:120,
            filter: true,
            resizable: true,
            editable:false,
        }
    ]
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="card col-xl-12">
            <div class="card-header">
                <h5>
                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Load Share Details</span>
                    <button className="btn btn-info" style={{float:"right",marginLeft:"2%"}} onClick={this.uploadBulkFormHandler.bind(this)}>Save and Generate Plan</button>
                    <a href="/rakeavailability" className="btn btn-danger" style={{float:"right"}}>Rake Availability</a>
                </h5>
            </div>
            <div className="card-body col-xl-12 col-lg-12">
                <div id="myGrid" style={{width:"100%",height:"460px"}} className={"ag-theme-balham "+this.state.showGridData}>    
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={this.state.columns}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    stopEditingWhenGridLosesFocus= {true}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={false}
                    pinnedBottomRowData={this.state.pinnedRegionBottomRowData}
                    gridOptions={{
                        context: { componentParent: this },
                        getRowStyle: function (params) {
                                if (params.node.rowPinned) {
                            return { 'font-weight': 'bold','font-size':'16px' };
                            }
                        },
                        suppressRowTransform: true,
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    //onCellClicked={this.onCellClicked}
                    onCellEditingStopped={this.onCellUpdateData.bind(this)}

                    />
            </div>
            </div>
        </div>

        
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">Rakes not having GPS Data</h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <div className="" style={{marginTop:"1em"}}>
                        <p style={{marginBottom:"1em"}}>{this.state.processErrMsg}</p>
                            <div id="myGrid" style={{width:"100%",height:"300px",marginBottom:"1em"}} className={"ag-theme-balham "+this.state.showGridData}>    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefsForNoGps}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.noGpsRakes}
                                enableCharts={false}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady1}
                                onGridState={this.onGridState1}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={false}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                enableRangeSelection= {true}
                                //onCellClicked={this.onCellClicked}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                />
                        </div>
                        
                        <a href="/sndmanuallocations" target="_blank" className="btn btn-danger">Update GPS Location</a>
                        <button type="button" style={{marginLeft:"3%"}} onClick={this.generateRailPlan.bind(this,1)} className="btn btn-success">Ignore and Generate Plan</button>                        
                    </div>
                </div>
            </div>
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
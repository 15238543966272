import React, { useRef, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const DountChart = ({ CenterText, Series, height, width, distance }) => {
    const chartComponentRef = useRef(null);

    const options = {
        chart: {
            type: 'pie',
            height: height, // Adjust height as needed
            width: width,
            events: {
                render() {
                    const chart = this;
                    const textX = chart.plotLeft + chart.plotWidth * 0.5;
                    const textY = chart.plotTop + chart.plotHeight * 0.5;

                    if (!chart.customLabel) {
                        chart.customLabel = chart.renderer
                            .text(
                                '500',
                                textX,
                                textY,
                                true // Use SVG <tspan> for line breaks
                            )
                            .css({
                                color: '#000000',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                textAlign: 'center'
                            })
                            .attr({
                                align: 'center'
                            })
                            .add();
                    } else {
                        chart.customLabel.attr({
                            x: textX,
                            y: textY
                        });
                    }
                }
            }
        },
        title: {
            text: '',
        },
        plotOptions: {
            pie: {
                innerSize: '60%',
                dataLabels: {
                    enabled: false,
                    distance: 0,  // Keep labels close to the pie slices
                    format: '{point.y:.0f}%',  // Display percentage only
                    style: {
                        color: '#000000',
                        fontSize: '12px',
                        // fontWeight: 'bold',
                        textOutline: 'none',  // Remove text outline
                    },
                    connectorPadding: 0,  // Minimize padding for connectors
                    connectorWidth: 1,
                    // showInLegend: true,
                },
            }
        },
        tooltip: {
            pointFormat: '<b>{point.y:.1f}%</b>',
            className: "dount-chart-tooltip",
            useHTML: true
        },
        series: Series,
        credits: {
            enabled: false,
        },
        legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'vertical',
            itemMarginTop: 10,
            itemMarginBottom: 10,
            symbolHeight: 10,
            symbolWidth: 10,
            symbolRadius: 5,
        },
    };

    useEffect(() => {
        if (chartComponentRef.current) {
            const chart = chartComponentRef.current.chart;
            const textX = chart.plotLeft + chart.plotWidth * 0.5;
            const textY = chart.plotTop + chart.plotHeight * 0.5;

            if (chart.customLabel) {
                chart.customLabel.attr({
                    text: `${CenterText}`,
                    x: textX,
                    y: textY
                });
            } else {
                chart.customLabel = chart.renderer
                    .text(
                        `${CenterText}`,
                        textX,
                        textY,
                        true // Use SVG <tspan> for line breaks
                    )
                    .css({
                        color: '#000000',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textAlign: 'center'
                    })
                    .attr({
                        align: 'center'
                    })
                    .add();
            }
            chart.redraw();
        }
    }, [CenterText]);   

    return (
        <div style={{ width: `${width}px`, height: `${height}px`, margin: '0 auto', position: 'relative' }}>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={chartComponentRef}
            />
        </div>
    );
};

export default DountChart;
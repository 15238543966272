import React, {Component} from "react";
import ReactDom,{ render } from 'react-dom';
import axios from 'axios';
import { Redirect } from 'react-router';
import LoginForm from './Loginform';
import LmLoginForm from './LmLoginform';

//import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

var images = require.context('../../assets/images/', true);

class Login extends Component {

    constructor(props) {
        super(props);
        var refur='';
        
        this.state = {
            style: {},
            email:'',
            password:'',
            loginMessage:'',
            is_enmovil_transporter:0,
            is_load_management:0,
        };
    }

    componentDidMount() {
        setTimeout(function () {
            this.setState({style: {display:'none'}});
        }.bind(this),1000)
        var urlPath = this.props.match.path;
        console.log(urlPath,"urlPath")
        console.log("location.hostname ", window.location.hostname)
        if(urlPath == "/tptlogin")
        {
            this.setState({
                is_enmovil_transporter:1
            });
        }
        else if(urlPath == "/optirun")
        {
            this.setState({
                is_load_management:1
            });
        }
        else if(urlPath == "/loadmanagement/login")
        {
            this.setState({
                is_load_management:1
            });
        }
    }
    
   
    render() {
        let style = this.state.style;
        if(window.location.hostname == "3.108.113.108")
        {
            var background = require('../../assets/images/login-image.jpg');
        }
        else
        {
            if(this.state.is_enmovil_transporter == 1 || window.location.hostname != "autometrics.in" || this.state.is_load_management == 1)
            {
                var background = require('../../assets/images/auth-layer.png');
            }
            else
            {
                var background = require('../../assets/images/login-image.jpg');
            }
        }
               
        console.log("window.location.hostname-",window.location.hostname)
        return (
            <div>
                {/* Loader starts */}
                <div className="loader-wrapper" style={style}>
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Have a great day at work today <span>&#x263A;</span></h4>
                    </div>
                </div>
                {/* Loader ends */}

                {/*page-wrapper Start*/}
                <div className="page-wrapper">
                    <div className="container-fluid">
                        {/*login page start*/}
                        <div className="authentication-main" >
                            {
                                (window.location.hostname == "3.108.113.108")?
                                <div className="row">                               
                                        <div className="col-md-7 p-0">
                                        <img src={require('../../assets/images/login-image.jpg')}
                                                            className="logo-login" style={{width:'125vh',height:'100vh'}} alt=""/>
                                        </div>
                                        <div className="col-md-5 p-0">
                                            <div className="auth-innerright" style={{padding:"20px"}}>
                                                <div className="authentication-box" style={{padding:"0px 30px"}}>
                                                    <div className="text-center" style={{marginBottom:"4%"}}>
                                                    <img src={require('../../assets/icons/maruti-logo-login.png')} className="logo-login" alt="" style={{width:"75%",marginBottom:"8%"}}/>
                                                        <img src={require('../../assets/icons/new-logo.png')}
                                                                className="logo-login" alt=""/>
                                                    </div>
                                                    <div className="card mt-4 p-4 mb-0">
                                                        <h4 style={{textAlign:"center"}}>
                                                            {(this.state.is_enmovil_transporter == 1)? "TRANSPORTER": ""} LOGIN
                                                        </h4>
                                                        <LoginForm is_enmovil_transporter={this.state.is_enmovil_transporter}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                :
                          ( (this.state.is_enmovil_transporter == 1 || window.location.hostname != "autometrics.in" || this.state.is_load_management == 1)?
                            <div className="row">
                                <div className="col-md-4 p-0">
                                    <div className="auth-innerleft" style ={ { backgroundImage: "url("+background+")" } }>
                                        <div className="text-center">
                                            <img src={require('../../assets/icons/logo.png')}
                                                    className="logo-login" style={{width:'75%'}} alt=""/>
                                            <hr/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 p-0">
                                    <div className="auth-innerright">
                                        <div className="authentication-box">
                                            <h4>
                                                {(this.state.is_enmovil_transporter == 1)? "TRANSPORTER": ""} LOGIN
                                            </h4>
                                            <div className="card mt-4 p-4 mb-0">
                                                <LoginForm is_enmovil_transporter={this.state.is_enmovil_transporter} is_load_management={this.state.is_load_management}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="row">                               
                                <div className="col-md-7 p-0">
                                <img src={require('../../assets/images/login-image.jpg')}
                                                    className="logo-login" style={{width:'125vh',height:'100vh'}} alt=""/>
                                </div>
                                <div className="col-md-5 p-0">
                                    <div className="auth-innerright" style={{padding:"20px"}}>
                                        <div className="authentication-box" style={{padding:"0px 30px"}}>
                                            <div className="text-center" style={{marginBottom:"4%"}}>
                                            <img src={require('../../assets/icons/maruti-logo-login.png')} className="logo-login" alt="" style={{width:"75%",marginBottom:"8%"}}/>
                                                <img src={require('../../assets/icons/new-logo.png')}
                                                        className="logo-login" alt=""/>
                                            </div>
                                            <div className="card mt-4 p-4 mb-0">
                                                <h4 style={{textAlign:"center"}}>
                                                    {(this.state.is_enmovil_transporter == 1)? "TRANSPORTER": ""} LOGIN
                                                </h4>
                                                <LoginForm is_enmovil_transporter={this.state.is_enmovil_transporter}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)                            
                            }
                        </div>
                        {/*login page end*/}
                    </div>
                </div>
                {/*page-wrapper Ends*/}

            </div>

        )
    }
}


export default Login;

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const ApproveMSILItem = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onClickApproveItem(props);
    };
    if(props.data.approve_status == 1)
    {
        var show = 0
    }
    else
    {
        var show=1
    }
    return (
        <div>
            {(show == 1)?
            <button onClick={handleEventClick} style={{color:"#FFFFFF"}} className="btn btn-danger custom-btn f12" 
            title="Approve" >Approve</button>
            :"Approved"
            }
            
        </div>
    );
};

export default ApproveMSILItem;

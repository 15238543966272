import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';

class ConsignmentsOverdueCounter extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
    		records:[],
    		totalOverdueDeliveriesCount: 0,
			oneDayOverdueDeliveriesCount: 0,
			threeDaysOverdueDeliveriesCount: 0,
			moreThanThreeDaysOverdueDeliveriesCount: 0,
    	}
    
    }
    componentDidMount(){
    	//console.log("Box ",window.atob(localStorage.getItem('_t')));
    	//console.log(this.props);
		const layoutdata={
			token:window.atob(localStorage.getItem('_t')),
		}
    		//console.log("Trucks", this.props.tottrucks);
    	//	//console.log("trucklocations", this.props.truckgps);
    		//console.log("notrans", this.props.notruckgps);
		 this.setState({
  			   totalOverdueDeliveriesCount: this.props.totalOverdueDeliveriesCount,
			oneDayOverdueDeliveriesCount: this.props.oneDayOverdueDeliveriesCount,
			threeDaysOverdueDeliveriesCount: this.props.threeDaysOverdueDeliveriesCount,
			moreThanThreeDaysOverdueDeliveriesCount: this.props.moreThanThreeDaysOverdueDeliveriesCount,
  		   });
    }
    
    render(){
        return (

            <div className="card-body"  id="consignment-overdue-component">
                <div className="u-steps row">
                    <div className="u-step col-sm-3 ">
                        <div className="u-step-desc">
                            <span className="txt-black">Total</span>
                            <h4 className="txt-info fnt2"><span className="counter"><CountUp end={this.props.totalOverdueDeliveriesCount}/></span></h4>
                        </div>
                    </div>

                    <div className="u-step col-sm-3  bgColorDangerLight">
                        <div className="u-step-desc ">
                            <span className="txt-black">1 day</span>
                            <h4 className="colorWhite fnt2"><span className="counter"><CountUp end={this.props.oneDayOverdueDeliveriesCount}/></span></h4>
                        </div>
                    </div>

                    <div className="u-step col-sm-3 bgColorDangerMedium">
                        <div className="u-step-desc ">
                            <span className="txt-black">3 days</span>
                            <h4 className="colorWhite fnt2"><span className="counter"><CountUp end={this.props.threeDaysOverdueDeliveriesCount}/></span></h4>
                        </div>
                    </div>

                    <div className="u-step col-sm-3 bgColorDangerDark">
                        <div className="u-step-desc ">
                            <span className="txt-black">&#62; 3 days</span>
                            <h4 className="colorWhite fnt2"><span className="counter"><CountUp end={this.props.moreThanThreeDaysOverdueDeliveriesCount}/></span></h4>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderCOL(){

        return (

                <div className="crm-numbers">
					<div className="row">
						<div className="col">
							<span>Total</span>
							<h4 className="txt-info"><span className="counter"><CountUp end={25.6}/></span></h4>
						</div>
						<div className="col">
							<span>Due: 1 Day</span>
							<h4 className="txt-primary"><span className="counter"><CountUp end={36.4}/></span></h4>
						</div>
						<div className="col">
							<span>Due: 3 Days</span>
							<h4 className="txt-warning"><span className="counter"><CountUp end={22.8}/></span></h4>
						</div>
						<div className="col">
							<span>Due &#62; 3 Days</span>
							<h4 className="txt-danger"><span className="counter"><CountUp end={22.8}/></span></h4>
						</div>
					</div>
				</div>
                

        );
    }
}

export default ConsignmentsOverdueCounter
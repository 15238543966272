/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import SweetAlert from 'react-bootstrap-sweetalert';
import DrawMap from '../common/drawmap';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

import NewDrawMap from '../common/newDrawMap'
import CountUp from 'react-countup';
import MyHeaderComponent from "./deviationheaderaction";
import Showtolls from "./showtolls";
import ShowTollsList from "./showtollslist";
import TollGrid from "./deviationtollgrid"
import ApprovalDeviationRoute from "./approvaldeviationroute";
import Implementroute from "./implementroute";
import $ from 'jquery';
import { TimeColsSlatsCoords } from '@fullcalendar/timegrid';
import coEmissionImage from '../../../src/assets/icons/emission_counter.png' 
import positiveEmissionImage from '../../../src/assets/icons/green_arrow.png'
import negativeEmissionImage from '../../../src/assets/icons/red_arrow.png'
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
const map_plants = {
    "B": "TVP Bangalore",
    "G": "Gujarat", "I": "Siliguri", "N": "TVP Nagpur", "BP": "Bidadi", "H": "Haryana","D":"Dobaspet"
}

export default class OptimizeDeviationRoutes extends Component {

    state = {
        loadshow: 'show-m',
        overly: 'show-m',
        modules: AllModules,
        alert: null,
        show: false,
        basicTitle: '',
        basicType: "default",
        dept_code: '',
        defaultColDef: {
            sortable: true,
            filter: true,
            //editable: true,
            autoHeight: true,
            resizable: true
        },
        rowData: [],
        headerHeight: 60,
        rowHeight: 50,
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: false,
        rowSelection: "single",
        context: { componentParent: this },
        frameworkComponents: {
            MyHeaderComponent: MyHeaderComponent,
            Showtolls: Showtolls,
            ShowTollsList: ShowTollsList,
            ApprovalDeviationRoute: ApprovalDeviationRoute,
            Implementroute: Implementroute
        },
        loadingCellRenderer: "customLoadingCellRenderer",
        loadingCellRendererParams: { loadingMessage: "One moment please..." },
        rowModelType: "serverSide",
        paginationPageSize: 1000,
        cacheBlockSize: 50,
        maxBlocksInCache: 2,
        pagetitle: "Route Deviations",
        originaldata: "",
        orignalresponse: "",
        mapinfo: "",
        originoption: { "value": "", "label": "Select Origin" },
        // destinationoption: { "value": "", "label": "Select Destination" },
        destinationarr: [],
        selectedorigin: '',
        selecteddestination: '',
        columnDefs: [],
        checkedinputs: [],
        uncheckedinputs: [],
        noofroutes: 3,
        tolls: [],
        tollshow: false,
        plantcoords: "",
        plantname: "",
        sliderTranslate: "",
        viewtolls: [],
        tollrowid: "",
        routeid: "",
        routeapprovestatus: "",
        open: false,
        route_reason: "",
        optimizedroutes: 0,
        identifiedroutes: 0,
        savingrealized: 0,
        estimateannual: 0,
        implemented: 0,
        rejected: 0,
        hold: 0,
        takenimplement: 0,
        implementroutedate: "",
        implementrouteid: '',
        primarydata: [],
        secondarydata: [],
        tabprim: "show-m",
        tabsec: "show-n",
        btnactive: "btn-success",
        btnsecactive: "",
        btnprimactive: "",
        primidentifiedroutes: "",
        primoptimizedroutes: "",
        primtaken_for_implementation: "",
        primimplemented: "",
        primrejected: "",
        primhold: "",
        secidentifiedroutes: "",
        secoptimizedroutes: "",
        sectaken_for_implementation: "",
        secimplemented: "",
        secrejected: "",
        sechold: "",
        primestimateannual: "",
        primsavingrealized: "",
        secestimateannual: "",
        secsavingrealized: "",
        clicktab: "all",
        allidentifiedroutes: "",
        alloptimizedroutes: "",
        alltaken_for_implementation: "",
        allimplemented: "",
        allrejected: "",
        allhold: "",
        allestimateannual: "",
        allsavingrealized: "",
        potential_savings_under_implementation: "",
        primary_potential_savings_under_implementation: "",
        secondary_potential_savings_under_implementation: "",
        potential_savings: "",
        alldata: [],
        originalalldata: [],
        originalprimdata: [],
        originalsecdata: [],
        plant: "",
        plants: [],
        tabOneactive: "activet",
        tabTwoActive: "",
        rowDataForDealer: [],
        tabOneData: "show-m",
        tabTwoData: "show-n",
        originoptions: [],
        destinationOptions: [],
        lspusers_options: [],
        truck_types: [],
        period_types: [],
        onSubmitDisply: false,
        selected_provider: { "label": "ALL", "value": "ALL" },
        selected_truck_type: { "label": "ALL", "value": "ALL" },
        selected_period_type: [{ "label": "ALL", "value": "ALL" }],
        originoption: { "label": "Bidadi", "value": "BP" },
        destinationoption: { "label": "ALL", "value": "ALL" },
        zonesOptions: [],
        consignee_area_options: [],
        selectedConsArea: { "label": "ALL", "value": "ALL" },
        selectedZone: { "label": "ALL", "value": "ALL" },
        total_optimized_routes: [],
        kilometers_saved: 0,
        btn1: "btn-danger",
        btn2: "btn-default",
        new_base_route_summary_data: [],
        activeBtn: 1,
        is_loading: false,
        route_coords1: [],
        flag: '',
        final_emission_count :0,
        final_actual_count :0,
        final_standard_count :0,
        final_difference_count :0
    };

    /*Alert Popups*/
    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    }

    async componentDidMount() {
        //this.onClickShowRoute()
        // console.log("path ", this.props.match.path)
        this.logPageView(); // Log page view to GA
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        this.setState({ eventAction: eventAction });
        // ms.push(
        //     {value:"F", label:"Gurgoan"},
        //     {value:"G", label:"Gujarat"},
        //     {value:"M", label:"Manesar"},
        //     {value:"N", label:"Nagpur"},
        //     {value:"I", label:"Silguri"},
        //     {value:"B", label:"Banglore"}
        var check_domain = /enmovil\.in/;
        if (localStorage.getItem("email") && check_domain.test(localStorage.getItem("email").trim().toLowerCase())) {
            await this.setState({
                selected_period_type: {
                "label": "Apr-2023", "value": "Apr-2023" 
            }})
        }
        this.setState({
            loadshow: 'show-m',
            overly: "show-m"
        })
        var map_plants = { "B": "TVP Bangalore", "G": "Gujarat", "H": "Haryana", "I": "Siliguri", "N": "TVP Nagpur", "BP": "Bidadi","D":"Dobaspet" }
        redirectURL.post("/dashboard/getSourceFromBaseRoutes")
            .then((response) => {
                //console.log("Ress ", response.data)
                var plantdata = response.data;
                // plantdata=plantdata.filter((each)=>each!="F")
                // plantdata=plantdata.filter((each)=>each!="M")
                // plantdata.push("H")
                // console.log(plantdata, "211")
                var originoptions = [{ "label": "ALL", "value": "ALL" }];
                if (plantdata.length > 0) {
                    plantdata.map((item) => {
                        originoptions.push({
                            value: item,
                            label: map_plants[item]
                        })
                    })
                }
                // console.log("optoins", originoptions)
                redirectURL.post("dashboard/getDestinationFromBaseRoutes", { "consigner_code": "" }).then((response1) => {
                    // console.log(this.state.activeBtn,'activeBtn===')
                    // if (this.state.activeBtn === 2) {
                    //     var items = [];
                    // } else if (this.state.activeBtn === 1) {
                    var items = [{ "label": "ALL", "value": "ALL" }];
                    // }
                    var destinationarr = response1.data;
                    var check = destinationarr.map((item) => {
                        items.push({
                            value: item, label: item
                        })
                    })
                    // console.log("destinationarr",items)
                    redirectURL.post("dashboard/getInitialDataForBaseRouteSummary", {}).then((response2) => {
                        // console.log(response2.data)
                        var lspusers_options = [];
                        var truck_types = [];
                        var period_types_options = [];
                        // var period_types = sortPeriodTypes(response2.data.period_types);
                        var period_types = response2.data.period_types;
                        var check_domain = /enmovil\.in/;
                        if (localStorage.getItem("email") && check_domain.test(localStorage.getItem("email").trim().toLowerCase())) {
                            // period_types = period_types.filter((e) => {
                            //     // console.log(e.includes("2023"),"check-864")
                            //     // console.log(["Jan-2023", "Feb-2023", "Mar-2023"].includes(e))
                            //     if(e.includes("2023") && ["Jan-2023", "Feb-2023", "Mar-2023"].includes(e) == false){
                            //         return e
                            //     }
                            // })
                            var period_types = ["Apr-2022","May-2022","Jun-2022","Jul-2022","Aug-2022","Sep-2022","Oct-2022","Nov-2022","Dec-2022","Jan-2023","Feb-2023","Mar-2023","FY-2022Q1","FY-2022Q2","FY-2022Q3","FY-2022Q4","Apr-2023","May-2023","Jun-2023","Jul-2023","Aug-2023","Sep-2023","Oct-2023","Nov-2023","Dec-2023","FY-2023Q1","FY-2023Q2","FY-2023Q3"]
                        }
                        // console.log(period_types, "period_types")
                        response2.data.lsp_users.map((e) => {
                            if (e != "NA") {
                                var label = e
                                if (e == "AXESTRACK_SND") { label = "AXESTRACK" }
                                lspusers_options.push({ "label": label, "value": e })
                            }
                        })
                        response2.data.truck_types.map((e) => {
                            truck_types.push({ "label": e, "value": e })
                        })
                        period_types.map((e) => {
                            period_types_options.push({ "label": e, "value": e })
                        })
                        // console.log(period_types, "period_types options")
                        //code bought from MSIL so here commented part is not required at time of inserting here
                        // var zonesOptions = [{"label": "ALL", "value": "ALL"}];
                        // response2.data.zones.map(e => zonesOptions.push({"label": e, "value": e}));
                        // var consignee_area_options = [{"label": "ALL", "value": "ALL"}];
                        // response2.data.consignee_areas.map(e => consignee_area_options.push({"label": e, "value": e}));

                        this.setState({
                            originoptions: originoptions,
                            destinationOptions: items,
                            lspusers_options: lspusers_options,
                            truck_types: truck_types,
                            period_types: period_types_options,
                            // zonesOptions: zonesOptions,
                            // consignee_area_options: consignee_area_options,
                            // overly: "show-n",
                            // loadshow: "show-n"
                        })
                        this.selectplants()
                    })


                })

            })

        // console.log('reached',this.state.activeBtn)
        if (this.state.activeBtn === 1) {
            this.onLoadShowPageData();
        }
    }

    onLoadShowPageData = async () => {
        this.setState({
            loadshow: "show-m",
            overly: "show-m",
            is_loading: false,
        })
        //   console.log(this.state.activeBtn,'active button')  
        if (this.state.activeBtn === 2) {
            this.setState({
                loadshow: "show-m",
                overly: "show-m",
                is_loading: false,
            })
            var valueCollection = []
            if (this.state.selected_period_type !== "" && this.state.selected_period_type !== undefined && this.state.selected_period_type !== 'undefined' && this.state.selected_period_type !== null) {
                if(Array.isArray(this.state.selected_period_type) == true){
                    valueCollection = this.state.selected_period_type.map(ele =>ele.value)
                }else{
                    valueCollection = [this.state.selected_period_type.value]
                }
                // console.log(valueCollection, 'valuecollection')
                var query = {
                    "truck_type": (this.state.selected_truck_type.value),
                    "period_type": JSON.stringify(valueCollection),
                    // "gps_provider": this.state.selected_provider.value,
                };


                if (["", undefined, null, "ALL"].includes(this.state.originoption.value) == false) {
                    // console.log("ch1")
                    query['consigner_code'] = this.state.originoption.value
                }
                if (["", undefined, null].includes(this.state.destinationoption.value) == false) {
                    // console.log("ch2")
                    query['consignee_city'] = JSON.stringify([this.state.destinationoption.value])
                }
                // if( query.consigner_code !== undefined && query.consigner_code !== null){

                if (query.consignee_city !== "" && query.consignee_city !== undefined && query.consignee_city !== null) {


                    // console.log(query,"query")
                    redirectURL.post("/consignments/consigneecitiescollection", query)
                        .then(async (response) => {
                            // console.log(response.data.data,'data----data')
                            const consigneeCities = response.data.data;
                            if (consigneeCities.length == 0) {
                                this.setState({
                                    // is_loading: false,
                                    // new_base_route_summary_data:[]
                                })

                            }

                            // const chunkSize = 100;
                            var totalData = [];
                            // for (let i = 0; i < consigneeCities.length; i += chunkSize) {
                            //     const chunk = consigneeCities.slice(i, i + chunkSize);

                            //             var cities = JSON.stringify(chunk)

                            //    query["consignee_city"] = cities
                            try {
                                await redirectURL.post("/consignments/newbaseroutessummarydata", query)
                                    .then((response) => {

                                        var resp = JSON.parse(response.data.data);
                                        // console.log(response.data.data, 'response.data.data', response)
                                        var checkData = eval(resp.base_routes_summary);

                                        totalData = [...totalData, ...checkData]
                                        // console.log(totalData, '346-------', checkData)

                                        if (totalData != '' || totalData != []) {
                                            // console.log(totalData, 'totalData11111')
                                            // this.setState({ is_loading: false });

                                            this.setState({
                                                new_base_route_summary_data: totalData,
                                                is_loading: false,
                                                onSubmitDisply: true,
                                                loadshow: "show-n",
                                                overly: "show-n",
                                            });
                                        }

                                    })

                            } catch (error) {
                                console.error("Request error:", error);

                            };
                            // this.setState({ is_loading: false });
                            // }        

                        })
                        .catch((error) => {
                            console.error("Request error:", error);
                        });


                } else {
                    this.setState({
                        show: true,
                        basicType: "warning",
                        basicTitle: "Select Destination",
                        loadshow: "show-n",
                        overly: "show-n",
                    })
                }
                // }else{
                //     this.setState({
                //         show: true,
                //         basicType: "warning",
                //         basicTitle: "Select Origin",
                //         loadshow: "show-n",
                //         overly: "show-n",
                //     })
                // }
            }
            else {

                this.setState({
                    show: true,
                    basicType: "warning",
                    basicTitle: "Select Period",
                    loadshow: "show-n",
                    overly: "show-n",
                })

            }
        } else if (this.state.activeBtn === 1) {
            // console.log("11111")
            var valueCollection = []
            if (this.state.selected_period_type !== "" && this.state.selected_period_type !== undefined && this.state.selected_period_type !== 'undefined' && this.state.selected_period_type !== null && this.state.selected_period_type.length !== 0) {
                let selected_period_type = this.state.selected_period_type
                selected_period_type = Array.isArray(selected_period_type)?selected_period_type:[selected_period_type]
                console.log(selected_period_type, "selected_period_type")
                valueCollection = selected_period_type.map(ele =>ele.value)
                var query = {
                    "truck_type": (this.state.selected_truck_type.value),
                    "period_type": JSON.stringify(valueCollection),
                    // "username": localStorage.getItem("username")
                    // "gps_provider": this.state.selected_provider.value,
                };
                if (localStorage.getItem("email") && /enmovil\.in/.test(localStorage.getItem("email").trim().toLowerCase())) {
                    query["username"] = "Nanda Kishore"
                }else{
                    query["username"] = localStorage.getItem("username")
                }

                if (["", undefined, null, "ALL"].includes(this.state.originoption.value) == false) {
                    // console.log("ch1")
                    query['consigner_code'] = this.state.originoption.value
                }
                if (["", undefined, null, "ALL"].includes(this.state.destinationoption.value) == false) {
                    // console.log("ch2")
                    query['consignee_city'] = (this.state.destinationoption.value)
                }
                // console.log(query, "query")
                redirectURL.post("/consignments/consigneecitiescollection", query)
                    .then(async (response) => {
                        // console.log(response.data.data, 'data----data')
                        const consigneeCities = response.data.data;
                        if (consigneeCities.length == 0) {
                            this.setState({
                                is_loading: false,
                                // new_base_route_summary_data:[]
                            })

                        }

                        // const chunkSize = 100;
                        var totalData = [];
                        // for (let i = 0; i < consigneeCities.length; i += chunkSize) {
                        //     const chunk = consigneeCities.slice(i, i + chunkSize);

                        //             var cities = JSON.stringify(chunk)

                        //    query["consignee_city"] = cities
                        try {
                            await redirectURL.post("/consignments/newbaseroutessummarydata", query)
                                .then((response) => {

                                    var resp = JSON.parse(response.data.data);
                                    // console.log(response.data.data, 'response.data.data', response)
                                    var checkData = eval(resp.base_routes_summary);

                                    totalData = [...totalData, ...checkData]
                                    // console.log(totalData, '346-------', checkData)

                                    if (totalData != '' || totalData != []) {
                                        // console.log(totalData, 'totalData11111')
                                        // this.setState({ is_loading: false });

                                        this.setState({
                                            // new_base_route_summary_data: [] ,
                                            is_loading: false,
                                            onSubmitDisply: false
                                        });
                                    }

                                })

                        } catch (error) {
                            console.error("Request error:", error);

                        };
                        this.setState({ is_loading: false });
                        // }        

                    })

                    .catch((error) => {
                        console.error("Request error:", error);
                    });

                await redirectURL.post("/consignments/newalldeviationroutes", query).then((response) => {
                    // console.log("Consignees ", JSON.parse(response.data.data))
                    var rec = JSON.parse(response.data.data);
                    // console.log(rec.optimized_routes_data, "323")
                    var records = JSON.parse(rec.optimized_routes_data);
                    // console.log("325", records)
                    var records2 = rec.sustainability_counter
                    console.log(records2,"545")
                    // data for the counters
                    let total_optimized_routes = []
                    let kilometers_saved = 0
                    let final_actual_count = 0
                    let final_standard_count = 0
                    let final_difference_count = 0
                    records.map(e => {
                        if (e.base_route_google_distance_difference < 0) {
                            total_optimized_routes.push(e)
                            kilometers_saved += Math.abs(e.base_route_google_distance_difference)
                        }
                    })
                    if(records2 != undefined && records2 != "" && records2 != "undefined" && records2 != null){
                        if(records2.length > 0){
                            records2.map((f)=>{
                                final_actual_count = final_actual_count + f.total_actual_emissions
                                final_standard_count = final_standard_count + f.total_standard_emissions
                                final_difference_count = final_difference_count + f.total_emissions_diff
                            })
                        }else{
                            final_actual_count = 0
                            final_standard_count = 0
                        }
                    }else{
                        final_actual_count = 0
                            final_standard_count = 0
                    }
                    
                    // end of data for counters
                    var identifiedroutes = rec.total_base_routes;
                    var optimizedroutes = rec.optimized_routes;
                    var taken_for_implementation = rec.taken_for_implementation;
                    var implemented = rec.implemented;
                    var rejected = rec.rejected;
                    var hold = rec.hold;
                    // var estimateannual = "--";
                    var savingrealized = "--";
                    var primidentifiedroutes = rec.primary_trips_count;
                    var primoptimizedroutes = rec.primary_trips_count;
                    var primtaken_for_implementation = rec.primary_taken_for_implementation;
                    var primimplemented = rec.primary_implemented;
                    var primrejected = rec.primary_rejected;
                    var primhold = rec.primary_hold;
                    var secidentifiedroutes = rec.secondary_total_base_routes;
                    var secoptimizedroutes = rec.secondary_trips_count;
                    var sectaken_for_implementation = rec.secondary_taken_for_implementation;
                    var secimplemented = rec.secondary_implemented;
                    var secrejected = rec.secondary_rejected;
                    var sechold = rec.secondary_hold;
                    var potential_savings_under_implementation = rec.potential_savings_under_implementation
                    var primary_potential_savings_under_implementation = rec.primary_potential_savings_under_implementation;
                    var secondary_potential_savings_under_implementation = rec.secondary_potential_savings_under_implementation;
                    var estimateannual = rec.estimated_annual_savings;
                    var primestimateannual = rec.primary_estimated_annual_savings;
                    var secestimateannual = rec.secondary_estimated_annual_savings
                    var all_savings_realized = rec.all_savings_realized;
                    var primary_savings_realized = rec.primary_savings_realized;
                    var secondary_savings_realized = rec.secondary_savings_realized;
                    var final_emission_count = 0
                    var final_actual_count2 = final_actual_count
                    var final_standard_count2 = final_standard_count
                    var final_difference_count2 = final_difference_count
                    //console.log("Recr ", records) 
                    var recordsarr = []
                    var primarydata = [];
                    var secondarydata = []
                    if (records.length > 0) {
                        records = records.sort(GetSortOrder("base_route_google_distance_difference"));
                        records.map((item) => {
                            // console.log("item ", item)
                            // if (parseInt(item.base_route_google_distance_difference) < 0) {
                            item.sourcename = map_plants[item.consigner_code];
                            recordsarr.push(item)

                            // }
                        })
                    }
                    console.log("recordsarr ", recordsarr)
                    this.setState({
                        rowData: recordsarr,
                        originalalldata: recordsarr,
                        alldata: recordsarr,
                        primarydata: primarydata,
                        secondarydata: secondarydata,
                        // overly: 'show-n',
                        // loadshow: 'show-n',
                        optimizedroutes: optimizedroutes,
                        identifiedroutes: identifiedroutes,
                        takenimplement: taken_for_implementation,
                        implemented: implemented,
                        rejected: rejected,
                        hold: hold,
                        estimateannual: estimateannual,
                        savingrealized: all_savings_realized,
                        alloptimizedroutes: optimizedroutes,
                        allidentifiedroutes: identifiedroutes,
                        alltaken_for_implementation: taken_for_implementation,
                        allimplemented: implemented,
                        allrejected: rejected,
                        allhold: hold,
                        allestimateannual: estimateannual,
                        allsavingrealized: all_savings_realized,
                        primidentifiedroutes: primidentifiedroutes,
                        primoptimizedroutes: primoptimizedroutes,
                        primtaken_for_implementation: primtaken_for_implementation,
                        primimplemented: primimplemented,
                        primrejected: primrejected,
                        primhold: primhold,
                        primestimateannual: primestimateannual,
                        primsavingrealized: primary_savings_realized,
                        secidentifiedroutes: secidentifiedroutes,
                        secoptimizedroutes: secoptimizedroutes,
                        sectaken_for_implementation: sectaken_for_implementation,
                        secimplemented: secimplemented,
                        secrejected: secrejected,
                        sechold: sechold,
                        secestimateannual: secestimateannual,
                        secsavingrealized: secondary_savings_realized,
                        potential_savings_under_implementation: potential_savings_under_implementation,
                        secondary_potential_savings_under_implementation: secondary_potential_savings_under_implementation,
                        primary_potential_savings_under_implementation: primary_potential_savings_under_implementation,
                        potential_savings: potential_savings_under_implementation,
                        'total_optimized_routes': total_optimized_routes,
                        'kilometers_saved': kilometers_saved,
                        final_standard_count : parseFloat(final_standard_count2)/1000,
                        final_actual_count : parseFloat(final_actual_count2)/1000,
                        final_difference_count : final_difference_count2
                    })
                    // console.log(this.state.new_base_route_summary_data.length,'timeout')
                    // if(this.state.new_base_route_summary_data.length == 0 && this.state.new_base_route_summary_data.length < 200){
                    //     this.setState({
                    //         overly: 'show-n',
                    //     loadshow: 'show-n',
                    //     })
                    // }else {

                    // setTimeout(() => {
                    this.setState({
                        overly: 'show-n',
                        loadshow: 'show-n',
                    })
                    //     }, 8000);
                    // }
                    // console.log(hold, "holdCount")
                })


            } else {
                this.setState({
                    show: true,
                    basicType: "warning",
                    basicTitle: "Select Period",
                    loadshow: "show-n",
                    overly: "show-n",
                    rowData: [],
                    kilometers_saved: 0,
                    identifiedroutes: 0,
                    optimizedroutes: 0,
                    total_optimized_routes: 0
                })
            }

        }
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                // console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;

        this.gridApi.addEventListener('paginationChanged', (e) => {
            //Reset rows selection based on current page
            // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode((node) => {
            // console.log("node here ",node)
            // node.setSelected(true);

        });

    };


    onGridState = () => {
        // console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);




        //
    }

    onCloseUploadDiv = () => {
        this.setState({
            sliderTranslate: "",
            sliderRouteTranslate: '',
            sliderRouteTranslate1: '',
            sliderChokeTranslate: "",
            showDiv: 'show-n',
            overly: 'show-n'
        });
    }


    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            slidertranslate: ''
        });

    }
    onClickRouteImplement = (params) => {
        //console.log("params ", params.data)
        this.setState({
            slidertranslate: "slider-translate-30p",
            overly: 'show-m',
            implementrouteid: params.data._id
        });
    }

    onCloseRouteDiv = () => {
        this.setState({
            slidertranslate: "",
            overly: 'show-n'
        });
    }

    handlerStartDateTime = (event) => {
        var d = new Date(event._d);

        var startdate = d.getFullYear() + "-" + ((d.getMonth() + 1) >= 10 ? (d.getMonth() + 1) : "0" + (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

        this.setState({
            implementroutedate: startdate
        });
    }

    onClickShowUpdateData() {
        var implementroutedate = this.state.implementroutedate;
        var implementrouteid = this.state.implementrouteid;
        var parameters = {
            effective_date: implementroutedate,
            id: implementrouteid
        }
        //console.log("parameters ", parameters)
        redirectURL.post("/consignments/setrouteimplementation", parameters)
            .then((response) => {
                this.setState({
                    show: true,
                    basicType: "success",
                    basicTitle: "Successfully update data",
                    slidertranslate: "",
                    overly: 'show-n'
                })
            }).catch((e) => {

            })

    }
    getBaseRoutesForDealer = () => {
        redirectURL.post("/consignments/alldeviationroutesForDealers",)
            .then(response => {
                let resData = JSON.parse(response.data.data)
                // console.log("response",resData)
                let optimizedRouteData = JSON.parse(resData.optimized_routes_data)
                // console.log("response",optimizedRouteData)
                let recordsarr = []

                if (optimizedRouteData.length > 0) {
                    optimizedRouteData = optimizedRouteData.sort(GetSortOrder("base_route_google_distance_difference"));
                    optimizedRouteData.map((item) => {
                        // console.log("item ", item)
                        if (parseInt(item.base_route_google_distance_difference) < 0) {
                            item.sourcename = item.consigner_name;
                            recordsarr.push(item)
                        }
                    })
                }
                this.setState({
                    rowDataForDealer: recordsarr,
                    loadshow: "show-n"
                })

            })
    }

    // onClickTab(tab) {
    //     if (tab == "all") {
    //         var plants = [];
    //         plants.push({ "value": "all", "label": "All" })
    //         plants.push({ "value": "B", "label": "TVP Bangalore" })
    //         plants.push({ "value": "haryana", "label": "Haryana" })
    //         plants.push({ "value": "G", "label": "Gujarat" })
    //         plants.push({ "value": "I", "label": "Siliguri" })
    //         plants.push({ "value": "N", "label": "TVP Nagpur" })
    //         this.setState({
    //             clicktab: tab,
    //             rowData: this.state.alldata,
    //             btnactive: "btn-success",
    //             btnprimactive: "",
    //             btnsecactive: "",
    //             optimizedroutes: this.state.alloptimizedroutes,
    //             identifiedroutes: this.state.allidentifiedroutes,
    //             takenimplement: this.state.alltaken_for_implementation,
    //             implemented: this.state.allimplemented,
    //             rejected: this.state.allrejected,
    //             hold: this.state.allhold,
    //             estimateannual: this.state.allestimateannual,
    //             savingrealized: this.state.allsavingrealized,
    //             potential_savings: this.state.potential_savings_under_implementation,
    //             plants: plants,
    //         })
    //     }
    //     if (tab == "prim") {
    //         // console.log("optimizedroutes ", this.state.primoptimizedroutes)
    //         // console.log("primimplemented ", this.state.primimplemented)
    //         // console.log("primrejected ", this.state.primrejected)
    //         // console.log("primtaken_for_implementation ", this.state.primtaken_for_implementation)
    //         var plants = [];
    //         plants.push({ "value": "all", "label": "All" })
    //         plants.push({ "value": "haryana", "label": "Haryana" })
    //         plants.push({ "value": "G", "label": "Gujarat" })
    //         this.setState({
    //             clicktab: tab,
    //             rowData: this.state.primarydata,
    //             btnactive: "",
    //             btnprimactive: "btn-success",
    //             btnsecactive: "",
    //             optimizedroutes: this.state.primoptimizedroutes,
    //             identifiedroutes: this.state.primidentifiedroutes,
    //             takenimplement: this.state.primtaken_for_implementation,
    //             implemented: this.state.primimplemented,
    //             rejected: this.state.primrejected,
    //             hold: this.state.primhold,
    //             estimateannual: this.state.primestimateannual,
    //             savingrealized: this.state.primsavingrealized,
    //             potential_savings: this.state.primary_potential_savings_under_implementation,
    //             plants: plants,
    //         })
    //     }
    //     if (tab == "second") {
    //         var plants = [];
    //         plants.push({ "value": "all", "label": "All" })
    //         plants.push({ "value": "B", "label": "TVP Bangalore" })
    //         plants.push({ "value": "I", "label": "Siliguri" })
    //         plants.push({ "value": "N", "label": "TVP Nagpur" })
    //         this.setState({
    //             clicktab: tab,
    //             rowData: this.state.secondarydata,
    //             btnactive: "",
    //             btnprimactive: "",
    //             btnsecactive: "btn-success",
    //             optimizedroutes: this.state.secoptimizedroutes,
    //             identifiedroutes: this.state.secidentifiedroutes,
    //             takenimplement: this.state.sectaken_for_implementation,
    //             implemented: this.state.secimplemented,
    //             rejected: this.state.secrejected,
    //             hold: this.state.sechold,
    //             estimateannual: this.state.secestimateannual,
    //             savingrealized: this.state.secsavingrealized,
    //             potential_savings: this.state.secondary_potential_savings_under_implementation,
    //             plants: plants,
    //         })
    //     }

    //     if(tab == "plant"){
    //         this.setState({
    //             tabOneactive : "activet",
    //             tabOneData : "show-m",
    //             tabTwoData : "show-n",
    //             tabTwoActive : ""
    //         })
    //     }
    //     if(tab == "dealer"){
    //         this.setState({
    //             tabOneactive : "",
    //             tabTwoActive : "activet",
    //             tabOneData : "show-n",
    //             tabTwoData : "show-m",
    //             loadshow: "show-m"
    //         })
    //         this.getBaseRoutesForDealer()
    //     }
    // }
    onClickShowClickableData(params) {
        // console.log("Params ", params)
        // console.log("clicktab ", this.state.clicktab)

        if (this.state.clicktab == "all") {
            var records = this.state.originalalldata;
            var takenarr = []
            var rejectarr = []
            var holdarr = []
            var implementarr = []
            if (records.length > 0) {
                records.map((item) => {
                    if (item.route_approval_status == 'approved') {
                        takenarr.push(item)
                    }
                    if (item.route_approval_status == "rejected") {
                        rejectarr.push(item)
                    }
                    if (item.route_approval_status == "hold") {
                        holdarr.push(item)
                    }
                    if (item.route_approval_status == "implement" || item.implement_status == 1) {
                        implementarr.push(item)
                    }
                })
            }
            if (params == 'all') {
                this.setState({
                    rowData: this.state.originalalldata,
                })
            }
            if (params == "taken") {
                this.setState({
                    rowData: takenarr,
                })
            }
            if (params == "reject") {
                this.setState({
                    rowData: rejectarr,
                })
            }
            if (params == "hold") {
                this.setState({
                    rowData: holdarr,
                })
            }
            if (params == "implement") {
                this.setState({
                    rowData: implementarr,
                })
            }
        }
        if (this.state.clicktab == "prim") {
            var primrecords = this.state.primarydata;
            var primtakenarr = []
            var primrejectarr = []
            var primholdarr = []
            var primimplementarr = []
            if (primrecords.length > 0) {
                primrecords.map((item) => {
                    if (item.route_approval_status == 'approved') {
                        primtakenarr.push(item)
                    }
                    if (item.route_approval_status == "rejected") {
                        primrejectarr.push(item)
                    }
                    if (item.route_approval_status == "hold") {
                        primholdarr.push(item)
                    }
                    if (item.route_approval_status == "implement" || item.implement_status == 1) {
                        primimplementarr.push(item)
                    }
                })
            }
            if (params == 'all') {
                this.setState({
                    rowData: this.state.primarydata,
                })
            }
            if (params == "taken") {
                // console.log("primtakenarr ", primtakenarr)
                this.setState({
                    rowData: primtakenarr,
                })
            }
            if (params == "reject") {
                this.setState({
                    rowData: primrejectarr,
                })
            }
            if (params == "hold") {
                this.setState({
                    rowData: primholdarr,
                })
            }
            if (params == "implement") {
                this.setState({
                    rowData: primimplementarr,
                })
            }
        }
        if (this.state.clicktab == "second") {
            var secrecords = this.state.secondarydata;
            var sectakenarr = []
            var secrejectarr = []
            var secholdarr = [];
            var secimplementarr = []
            if (secrecords.length > 0) {
                secrecords.map((item) => {
                    if (item.route_approval_status == 'approved') {
                        sectakenarr.push(item)
                    }
                    if (item.route_approval_status == "rejected") {
                        secrejectarr.push(item)
                    }
                    if (item.route_approval_status == "hold") {
                        secholdarr.push(item)
                    }
                    if (params == "hold") {
                        this.setState({
                            rowData: secholdarr,
                        })
                    }
                    if (item.route_approval_status == "implement" || item.implement_status == 1) {
                        secimplementarr.push(item)
                    }
                })
            }
            if (params == 'all') {
                this.setState({
                    rowData: this.state.secondarydata,
                })
            }
            if (params == "taken") {
                this.setState({
                    rowData: sectakenarr,
                })
            }
            if (params == "reject") {
                this.setState({
                    rowData: secrejectarr,
                })
            }
            if (params == "hold") {
                this.setState({
                    rowData: secholdarr,
                })
            }
            if (params == "implement") {
                this.setState({
                    rowData: secimplementarr,
                })
            }
        }
        if (params === 'tor') {
            this.setState({
                rowData: this.state.total_optimized_routes,
            })
        }
    }
    selectplants() {
        var plants = [];
        plants.push({ "value": "all", "label": "All" })
        plants.push({ "value": "B", "label": "TVP Bangalore" })
        plants.push({ "value": "G", "label": "Gujarat" })
        plants.push({ "value": "F", "label": "Gurgaon" })
        plants.push({ "value": "I", "label": "Siliguri" })
        plants.push({ "value": "N", "label": "TVP Nagpur" })
        plants.push({ "value": "M", "label": "Manesar" })
        plants.push({ "value": "D", "label": "Dobaspet" })
        // plants.push({ "value": "haryana", "label": "Haryana" })
        this.setState({
            plants: plants,
        });
    }
    onChangePlantItem = (plant) => {
        this.setState(
            { plant },
            // () => console.log(`Plant Option selected:`, this.state.plant)
        );
    }
    formPlantHandler = async (event) => {
        event.preventDefault()
        this.setState({
            "loadshow": "show-m",
            "overly": 'show-m',
        });
        this.onLoadShowPageData();
    }

    changeOriginHandler = originoption => {
        this.setState(
            { originoption }
        );
        redirectURL.post("dashboard/getDestinationFromBaseRoutes", { "consigner_code": originoption.value }).then((response) => {
            var items = [];
            // if (this.state.activeBtn === 1) {
                items.push({ label: "ALL", value: "ALL" })
            // }
            var destinationarr = response.data;
            var check = destinationarr.map((item) => {
                items.push({
                    value: item, label: item
                })
            })
            Promise.all([check]).then(() => {
                this.setState({
                    destinationOptions: items
                })
            })

        })
    }

    changeDestinationHandler = destinationoption => {
        // var formdata = {}
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['consigner_code'] = this.state.originoption.value
        // }
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['consignee_city'] = destinationoption.value
        // }
        // redirectURL.post("dashboard/getInitialDataForBaseRouteSummary", formdata).then((response2)=>{
        //     var lspusers_options = [];
        //     var truck_types = [];
        //     var period_types_options = [];
        //     response2.data.lsp_users.map((e)=>{
        //         if(e != "NA")
        //         {
        //             var label = e
        //             if(e == "AXESTRACK_SND"){label = "AXESTRACK"}
        //             lspusers_options.push({"label": label, "value": e})
        //         }
        //     })
        //     response2.data.truck_types.map((e)=>{
        //         truck_types.push({"label": e, "value": e})
        //     })
        //     var period_types = sortPeriodTypes(response2.data.period_types);
        //     period_types.map((e)=>{
        //         period_types_options.push({"label": e, "value": e})
        //     })
        //     this.setState({
        //         destinationoption,
        //         // originoptions: originoptions,
        //         // destinationOptions: items,
        //         lspusers_options: lspusers_options,
        //         truck_types: truck_types,
        //         period_types: period_types_options,
        //         // overly: "show-n",
        //         // loadshow: "show-n"
        //     })
        // })
        this.setState(
            { destinationoption }
        );

    }
    changeConsigneeArea(selectedConsArea) {
        this.setState({ selectedConsArea })
    }
    changeZone(selectedZone) {
        this.setState({ selectedZone })
    }
    changeGpsProviderHandler = selected_provider => {
        // var formdata = {}
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['consigner_code'] = this.state.originoption.value
        // }
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['consignee_city'] = this.state.destinationoption.value
        // }
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['gps_provider'] = selected_provider.value
        // }
        // redirectURL.post("dashboard/getInitialDataForBaseRouteSummary", formdata).then((response2)=>{
        //     var lspusers_options = [];
        //     var truck_types = [];
        //     var period_types_options = [];
        //     // response2.data.lsp_users.map((e)=>{
        //     //     if(e != "NA")
        //     //     {
        //     //         var label = e
        //     //         if(e == "AXESTRACK_SND"){label = "AXESTRACK"}
        //     //         lspusers_options.push({"label": label, "value": e})
        //     //     }
        //     // })
        //     response2.data.truck_types.map((e)=>{
        //         truck_types.push({"label": e, "value": e})
        //     })
        //     var period_types = sortPeriodTypes(response2.data.period_types);
        //     period_types.map((e)=>{
        //         period_types_options.push({"label": e, "value": e})
        //     })
        this.setState({
            selected_provider: selected_provider,
            // originoptions: originoptions,
            // destinationOptions: items,
            // lspusers_options: lspusers_options,
            // truck_types: truck_types,
            // period_types: period_types_options,
            // overly: "show-n",
            // loadshow: "show-n"
        })
        // })
    }
    changePeriodTypeHandlerforSingleSelect = (event) =>{
        console.log(event,"1237")
        this.setState({
            selected_period_type : {value:event.value,label:event.value}
        })
    }
    changePeriodTypeHandler = selected_period_type => {
        // var formdata = {}
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['consigner_code'] = this.state.originoption.value
        // }
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['consignee_city'] = this.state.destinationoption.value
        // }
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['gps_provider'] = this.state.selected_provider.value
        // }
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['period_type'] = selected_period_type.value
        // }
        // redirectURL.post("dashboard/getInitialDataForBaseRouteSummary", formdata).then((response2)=>{
        //     var truck_types = [];
        //     response2.data.truck_types.map((e)=>{
        //         truck_types.push({"label": e, "value": e})
        //     })

        // if(typeof(selected_period_type) === "object"){
        //     selected_period_type = [selected_period_type]
        // }
        var selectedPeriodValues;
        if (selected_period_type != "" && selected_period_type != null && selected_period_type != undefined) {
            selectedPeriodValues = selected_period_type.map(option => option);
            console.log(selectedPeriodValues, 'selectedPeriodValues')
            this.setState({
                selected_period_type: selectedPeriodValues,
                // truck_types: truck_types,
            })
        } else {
            this.setState({
                selected_period_type: "",
                // truck_types: truck_types,
            })
        }
    }

    changeTruckTypeHandler = selected_truck_type => {
        this.setState({
            selected_truck_type: selected_truck_type,
        })
    }
    formHandler() {

    }

    onClickTab = async (tab) => {
        // console.log(tab)
        // var { destinationOptions } = this.state
        var tab = tab
        if (tab == 1) {
            this.setState({
                btn1: "btn-danger",
                btn2: "btn-default",
                activeBtn: 1,
                onSubmitDisply: false,
                selected_truck_type: { "label": "ALL", "value": "ALL" },
                selected_period_type: [{ "label": "ALL", "value": "ALL" }],
                originoption: { "label": "Bidadi", "value": "BP" },
                destinationoption: [{ "label": "ALL", "value": "ALL" }],
            }, this.componentDidMount)


        } else {
            // destinationOptions.shift()
            // console.log(destinationOptions,destinationOptions.shift(),"1359")
            this.setState({
                btn2: "btn-danger",
                btn1: "btn-default",
                activeBtn: 2,
                // destinationOptions: destinationOptions,
                selected_truck_type: { "label": "ALL", "value": "ALL" },
                selected_period_type: [{ "label": "ALL", "value": "ALL" }],
                originoption: { "label": "ALL", "value": "ALL" },
                destinationoption: { "label": "Select City", "value": "" },
            })
        }

    }

    onCellClicked = async (e) => {
        if (e.colDef.field == "base_routes_map") {
            // console.log("Hit-1")
            // console.log(e.data, "1707")
           
                var rowID = e.data.base_route_id
                var tab = "base_routes"
            
            // console.log(rowID, "1732")
            this.setState({
                overly: "show-m",
                loadshow: "show-m"
            })


            redirectURL.post("dashboard/gettripcoords", { row_id: rowID, tab: tab }).then((resp) => {
                // console.log(resp.data, "1724")
                var data = JSON.parse(resp.data.trip_route);
                // console.log(eval(resp.data.trip_route), "1729")
                // console.log(resp.data.saved_base_route, "1730")
                // var base_no=(e.data.base_route_no).split(" ")
                // base_no=base_no[2]
                var route_coords1 = [
                    {
                        "consignment_code": ["Base Route - " + e.data.base_route_no],
                        "coords": data,
                        "breaks": []
                    },
                    // {
                    //     "consignment_code": ["MSIL Approved Route"],
                    //     "coords": eval(resp.data.saved_base_route[0]),
                    //     "breaks": []
                    // }
                ]
                this.setState({
                    sliderRouteTranslate1: "slider-translate-50p",
                    mapfor: "map_routes",
                    // routeRowData: e.data,
                    dealersList: [],
                    route_coords1: route_coords1,
                    overly: "show-m",
                    loadshow: 'show-n'
                })
                // }
            })
            // })
        }
    }

    render() {
        const columnDefs = [
            // {
            //     headerName: "",
            //     field: "",
            //     width: 110,
            //     cellRenderer: (params) => {
            //         var sourval = params.data.consigner_code;
            //         var sourcename = params.data.sourcename;
            //         var destination = params.data.consignee_city;
            //         return '<a href="/deviationroutes?source=' + sourval + '&sourcename=' + sourcename + '&destination=' + destination + '&period_type='+ this.state.selected_period_type.value +'&gps_provider='+this.state.selected_provider.value+'&truck_type='+this.state.selected_truck_type.value+'" target="_blank" style="padding:2px;width:6em;" class="btn btn-warning" rel="noopener">View Map</a>'
            //     }
            // },
            {
                headerName: "",
                field: "",
                width: 110,
                cellRenderer: (params) => {
                    var sourval = params.data.consigner_code;
                    var sourcename = params.data.sourcename;
                    var destination = params.data.consignee_city;
                    if (this.state.selected_period_type.length !== 0) {
                        var periodTypes
                        if(Array.isArray(this.state.selected_period_type)){ 
                            periodTypes = this.state.selected_period_type.map(option => option.value).join(',');
                        }else{
                            periodTypes = this.state.selected_period_type.value
                        }
                        return '<a href="/deviationanalysisdata?source=' + sourval + '&sourcename=' + sourcename + '&destination=' + destination + '&period_type=' + periodTypes + '&gps_provider=' + this.state.selected_provider.value + '&truck_type=' + this.state.selected_truck_type.value + '" target="_blank" class="btn btn-warning" style="padding:2px;width:7em;" rel="noopener">View Summary</a>'
                    }
                }
            },
            {
                headerName: "Source",
                field: "sourcename",
                width: 120,
            }, {
                headerName: "Destination",
                field: "consignee_city",
                width: 140
            },

            {
                headerName: "Total Source Destination Trips",
                field: "total_trips_btw_source_dest",
                width: 120
            },

            {
                headerName: "Valid Trips",
                field: "trips_considered_for_analysis",
                width: 120
            },
            {
                headerName: "No of Cars",
                field: "no_of_cars",
                width: 120
            },
            {
                headerName: "MSIL Approved Distance (Kms)(B)",
                field: "",
                width: 120,
                valueGetter: function (params) {
                    // console.log(params.data.expected_distance, "params.data.expected_distance")
                    return params.data.expected_distance;
                }
            },
            {
                headerName: "Min. GPS Distance (KM)",
                field: "ref_route_min_google_distance",
                width: 120
            },
            {
                headerName: "Max. GPS Distance (KM)",
                field: "ref_route_max_google_distance",
                width: 120
            },
            {
                headerName: "Average Distance (Kms)(A)",
                field: "ref_route_avg_google_distance",
                width: 100,
                valueGetter: function (params) {
                    try {
                        return params.data.ref_route_avg_google_distance
                    }
                    catch (e) {
                        return "";
                    }
                }
            },
            {
                headerName: "Median GPS Distance (KM)",
                field: "ref_route_median_google_distance",
                width: 120
            },
            {
                headerName: "Min Google Distance (KM)",
                field: "google_distance_via_waypoints",
                width: 120
            },
            {
                headerName: "Difference Distance (Kms)(A-B)",
                field: "base_route_google_distance_difference",
                width: 100,
                valueGetter: function (params) {
                    try {
                        return params.data.base_route_google_distance_difference
                    }
                    catch (e) {
                        return "";
                    }

                }
            },
            {
                headerName: "Kilometers Saved",
                field: "base_route_google_distance_difference",
                width: 100,
                valueGetter: param => {
                    try {
                        if (param.data.base_route_google_distance_difference < 0) return Math.abs(param.data.base_route_google_distance_difference)
                        return 0
                    }
                    catch (e) {
                        return "";
                    }

                }
            }
        ]
        var check_domain = /enmovil\.in/;
        if (localStorage.getItem("email") && check_domain.test(localStorage.getItem("email").trim().toLowerCase())) {
            columnDefs.push({
                headerName: "Co2 Emission Standard(Kg)",
                field: "emission_kg_standard",
                width: 150,
            },
            {
                headerName: "Co2 Emission Actual(Kg)",
                field: "emission_kg_actual",
                width: 150
            },
            {
                headerName: "Co2 Emission Difference(Kg)",
                field: "emission_kg_diff",
                width: 150,
                valueGetter:function(params){
                    if(![undefined,"undefined",null,""].includes(params.data.emission_kg_diff)){
                        var final_return_value = parseFloat(params.data.emission_kg_diff)
                        var returned_value = final_return_value.toFixed(2)
                        return returned_value
                    }
                }
            },
            {
                headerName: "Co2 Emission Intensity(Kg)",
                field: "emission_intensity_kg",
                width: 150
            })
        }
        const columnDefsForDealer = [
            {
                headerName: "",
                field: "",
                width: 110,
                cellRenderer: function (params) {
                    // var cCode = params.data.consignee_code;                    
                    // return '<a href="/deviationForDealer?consignee_code=' + cCode +'" target="_blank" class="btn btn-warning" rel="noopener">View</a>'
                    var sourval = params.data.consigner_code;
                    let sourceName = params.data.consigner_name;
                    var cCode = params.data.consignee_code;
                    return '<a href="/deviationForDealer?source=' + sourval + '&sourcename=' + sourceName + '&consignee_code=' + cCode + '" target="_blank" class="btn btn-warning" rel="noopener">View</a>'
                }
            },
            {
                headerName: "Source",
                field: "consigner_name",
                width: 120
            },
            {
                headerName: "Dealer Name",
                field: "consignee_name",
                width: 160

            },
            {
                headerName: "Total Source Destination Trips",
                field: "total_trips_btw_source_dest",
                width: 120
            },
            {
                headerName: "Trips Considered For Analysis",
                field: "trips_considered_for_analysis",
                width: 120
            },
            {
                headerName: "MSIL Approved Distance (Kms)(B)",
                field: "expected_distance",
                width: 120,
                valueGetter: (params) => {
                    return params.data.expected_distance;
                }
            }, {
                headerName: "Average Distance (Kms)(B)",
                field: "ref_route_avg_google_distance",
                width: 100,
                valueGetter: function (params) {
                    try {
                        return params.data.ref_route_avg_google_distance
                    }
                    catch (e) {
                        return "";
                    }
                }
            },
            {
                headerName: "Difference Distance (Kms)(A-B)",
                field: "base_route_google_distance_difference",
                width: 100,
                valueGetter: function (params) {
                    try {
                        return params.data.base_route_google_distance_difference
                    }
                    catch (e) {
                        return "";
                    }

                }
            }
        ]

        const { show, basicType, basicTitle, total_optimized_routes, kilometers_saved, optimizedroutes, final_emission_count, final_actual_count, final_standard_count,final_difference_count} = this.state

        var colDefs = [
            // {
            //     headerName: "",
            //     field: "checkbox_selection",
            //     checkboxSelection:true,
            //     width:60,
            //     // cellRenderer: (params)=> {
            //     //     if(params.data.is_marked_base_route == 1)
            //     //     {
            //     //         return params.node.setSelected(true);
            //     //     }
            //     //     else{
            //     //         return params.node.setSelected(false);
            //     //     }
            //     // }
            // },
            {
                headerName: "",
                field: "base_routes_map",
                cellRenderer: (params) => {
                    return "<i class='icofont icofont-map-pins f20'></i>"
                },
                width: 60
            },
            {
                headerName: "Source",
                field: "plant",
                valueGetter: (params) => {
                    return params.data.plant
                }
            },
            {
                headerName: "Destination",
                field: "consignee_city",
            },
            {
                headerName: "For Code",
                field: "for_code",
            },
            {
                headerName: "Base Route",
                field: "base_route_no",
                valueGetter: (params) => {
                    return "Base Route " + params.data.base_route_no
                }

            },
            {
                headerName: "MSIL Approved Route",
                field: "is_marked_base_route",
                valueGetter: (params) => {
                    if (params.data.is_marked_base_route == 1) {
                        return "Yes"
                    }
                    else {
                        return 'No'
                    }
                }
            },
            {
                headerName: "No. of Trips",
                field: "no_of_followers",
            },
            {
                headerName: "Route Trip %",
                field: "no_of_followers_percentage",
            },
            {
                headerName: "No of LSP Users",
                field: "Transporters"
            },
            {
                headerName: "MSIL Approved Distance (KM)",
                field: "expected_distance",
            },
            {
                headerName: "Google Distance (KM)",
                field: "google_distance_via_waypoints"
            },
            {
                headerName: "Difference Distance (Kms)(A-B)",
                field: "distance_difference"
            },
            {
                headerName: "Avg. GPS Distance (KM)",
                field: "avg_google_distance",
            },
            {
                headerName: "Median GPS Distance (KM)",
                field: "median_google_distance",
            },

            {
                headerName: "Max. GPS Distance (KM)",
                field: "max_google_distance",
            },
            {
                headerName: "Min. GPS Distance (KM)",
                field: "min_google_distance",
            },






        ]
        var check_user = true
        var check_domain = /enmovil\.in/;
        if (localStorage.getItem("email") && check_domain.test(localStorage.getItem("email").trim().toLowerCase())) {
            var check_user = false
        }

        return (

            <div className="container-fluid">
                <SweetAlert
                    show={show}
                    type={basicType}
                    title={basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    <div className="row col-xl-12 col-lg-12" style={{ marginRight: "20px" }}>
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>Origin: </label>
                            <Select
                                name="originoption"
                                closeMenuOnSelect={true}
                                value={this.state.originoption}
                                className="border-radius-0"
                                onChange={this.changeOriginHandler}
                                style={{ borderRadius: "0px" }}
                                options={this.state.originoptions} required
                            />
                        </div>
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>Destination: </label>
                            <Select
                                name="destinationoption"
                                closeMenuOnSelect={true}
                                value={this.state.destinationoption}
                                className="border-radius-0"
                                onChange={this.changeDestinationHandler}
                                style={{ borderRadius: "0px" }}
                                options={this.state.destinationOptions} required
                            />
                        </div>

                        <div className="form-group col-xl-2 col-lg-2">
                            <label>Period: </label>
                            {(check_user === false)?
                                <Select
                                    // isMulti={true}
                                    // isClearable
                                    name="Period"
                                    // closeMenuOnSelect={true} 
                                    value={this.state.selected_period_type}
                                    className="border-radius-0"
                                    onChange={this.changePeriodTypeHandlerforSingleSelect}
                                    style={{ borderRadius: "0px" }}
                                    options={this.state.period_types} required
                                />
                            :
                            <CreatableSelect
                                isMulti={true}
                                isClearable
                                name="Period"
                                // closeMenuOnSelect={true} 
                                value={this.state.selected_period_type}
                                className="border-radius-0"
                                onChange={this.changePeriodTypeHandler}
                                style={{ borderRadius: "0px" }}
                                options={this.state.period_types} required
                            />
                            }
                            
                        </div>
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>Truck Type: </label>
                            <Select
                                name="Truck Type"
                                closeMenuOnSelect={true}
                                value={this.state.selected_truck_type}
                                className="border-radius-0"
                                onChange={this.changeTruckTypeHandler}
                                style={{ borderRadius: "0px" }}
                                options={this.state.truck_types} required
                            />
                        </div>

                        <div className="form-group col-xl-2 col-lg-2">
                            <button style={{ marginTop: "30px" }} onClick={this.onLoadShowPageData} type="button" className="btn btn-success">Submit</button>
                        </div>
                    </div>


                     {(localStorage.getItem("email") && /enmovil\.in/.test(localStorage.getItem("email").trim().toLowerCase()))?"":
                      <div className="button-group" style={{ marginLeft: "15px" }}>
                      <button className={"btn " + this.state.btn1} onClick={() => this.onClickTab(1)}>Optimized Routes</button>
                      <button className={"btn " + this.state.btn2} onClick={() => this.onClickTab(2)}>New Base Routes Summary</button>
                  </div>
                     }       
                   


                    &nbsp;&nbsp;&nbsp;&nbsp;




                    {(this.state.activeBtn == 1) ?
                        <div className="col-xl-12 col-lg-12 col-sm-12" >
                            <div className="col-xl-12 col-lg-12 col-sm-12 beffect" style={{ marginLeft: "-18px" }} >

                                <div className="card">
                                    <div className="card-body" id="counterCardBody" style={{padding:"0px"}}>
                                        <div className="crm-numbers pb-0">
                                            <div className="row">
                                                <div className="col cirlce-d cursorPointer" onClick={this.onClickShowClickableData.bind(this, "all")} style={{padding:"10px"}}>
                                                    <span className="f13"><i className="icofont icofont-map-pins f24 txt-success"></i><br /> Possible Identified Routes</span>
                                                    <h4 className="txt-success f40"><span className="counter"><CountUp end={(optimizedroutes != '') ? optimizedroutes : 0} /></span></h4>
                                                </div>
                                                <div className="col cirlce-d cursorPointer" onClick={this.onClickShowClickableData.bind(this, "tor")} style={{padding:"10px"}}>
                                                    <span className="f13"><i className="icofont icofont-ui-browser f24 txt-primary"></i><br /> Total Optimized Routes</span>
                                                    <h4 className="txt-primary f40"><span className="counter"><CountUp end={(total_optimized_routes != '') ? total_optimized_routes.length : 0} /></span></h4>
                                                </div>
                                                <div className="col cirlce-d cursorPointer" style={{padding:"10px"}}>
                                                    <span className="f13"><i className="icofont icofont-ui-calculator f24 txt-warning"></i><br /> Kilometers Saved</span>
                                                    <h4 className="txt-warning f40"><span className="counter"><CountUp end={(kilometers_saved != '') ? kilometers_saved : 0} /></span></h4>
                                                </div>
                                                { 
         (localStorage.getItem("email") && /enmovil\.in/.test(localStorage.getItem("email").trim().toLowerCase())) ?
                                                <div className="col cirlce-d cursorPointer" style={{backgroundColor : "#5fb1ab",padding:'10px'}}>
                                                     <span className="f13"><img src={coEmissionImage} className="emission_image" alt="emission_counter"/><br /></span>
                                                    <div className='row'>
                                                       
                                                            {/* <div className='col-xl-6 col-lg-6'> */}
                                                                <div className="col-xl-6 col-lg-6 cirlce-d cursorPointer">
                                                                    <span className="f13" style={{color:"#ffffff",fontWeight:"bold"}}><br />Expected <span style={{fontSize:"10px"}}>(Tons)</span></span>
                                                                    <h4 className="f30" style={{color:"#ffffff"}}><span className="counter">{final_standard_count.toFixed(2)}</span></h4>
                                                                </div>
                                                            {/* </div> */}
                                                            {/* <div className='col-xl-6 col-lg-6'> */}
                                                                <div className="col-xl-6 col-lg-6 cirlce-d cursorPointer">
                                                                    <span className="f13" style={{color:"#ffffff",fontWeight:"bold"}}><br />Actual <span style={{fontSize:"10px"}}>(Tons)</span></span>
                                                                    <h4 className="f30" style={{color:"#ffffff"}}><span className="counter">{final_actual_count.toFixed(2)}
                                                                    {(final_difference_count > 0)?
                                                                        <img className="sustainability_icons" src={negativeEmissionImage} alt="negative_emission"/>:
                                                                        <img className="sustainability_icons" src={positiveEmissionImage} alt="positive_emission"/>
                                                                    }
                                                                    </span></h4>
                                                                </div>
                                                            {/* </div> */}
                                                        </div>
                                                    </div>
                                                :""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-12 col-lg-12" style={{ marginLeft: "-18px" }}>
                                <div className="card">

                                    <div className="card-header">
                                        <h5>
                                            <i className="icon-map cus-i"></i> <span>Optimized Routes  </span>
                                        </h5>
                                    </div>
                                    <div className="card-body row">
                                        <div className="f16" style={{ marginBottom: "10px" }}>
                                            <ul className="d-tabs">

                                            </ul>
                                        </div>

                                        <div className={"row col-xl-12 col-lg-12 " + (this.state.tabOneData)}>
                                            <div id="myGrid" style={{ height: "480px", width: "100%" }} className={"ag-theme-balham"}>
                                                <AgGridReact
                                                    modules={this.state.modules}
                                                    columnDefs={columnDefs}
                                                    defaultColDef={this.state.defaultColDef}
                                                    rowData={this.state.rowData}
                                                    enableCharts={false}
                                                    // enableRangeSelection={false}
                                                    autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                    onGridReady={this.onGridReady}
                                                    onGridState={this.onGridState}
                                                    statusBar={this.state.statusBar}
                                                    sideBar={this.state.sideBar}
                                                    paginationPageSize={this.state.paginationPageSize}
                                                    pagination={true}
                                                    enableRangeSelection={true}
                                                    gridOptions={{
                                                        context: { componentParent: this }
                                                    }}
                                                    frameworkComponents={this.state.frameworkComponents}
                                                    //onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                                    //onCellClicked={this.popmarker}
                                                    headerHeight={this.state.headerHeight}
                                                    rowHeight={this.state.rowHeight}
                                                />
                                            </div>

                                        </div>
                                        <div className={"row col-xl-12 col-lg-12 " + (this.state.tabTwoData)}>
                                            <div id="myGrid" style={{ height: "480px", width: "100%" }} className={"ag-theme-balham"}>
                                                <AgGridReact
                                                    modules={this.state.modules}
                                                    columnDefs={columnDefsForDealer}
                                                    defaultColDef={this.state.defaultColDef}
                                                    rowData={this.state.rowDataForDealer}
                                                    enableCharts={false}
                                                    // enableRangeSelection={false}
                                                    autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                    onGridReady={this.onGridReady}
                                                    onGridState={this.onGridState}
                                                    statusBar={this.state.statusBar}
                                                    sideBar={this.state.sideBar}
                                                    paginationPageSize={this.state.paginationPageSize}
                                                    pagination={true}
                                                    enableRangeSelection={true}
                                                    gridOptions={{
                                                        context: { componentParent: this }
                                                    }}
                                                    frameworkComponents={this.state.frameworkComponents}
                                                    //onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                                    //onCellClicked={this.popmarker}
                                                    headerHeight={this.state.headerHeight}
                                                    rowHeight={this.state.rowHeight}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div></div> :


                        <div className="col-xl-12 col-lg-12 col-sm-12" >

                            <div className="card">

                                <div className="card-header">
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>

                                        <div>
                                            <h5>
                                                <i className="icon-map cus-i"></i> <span>New Base Routes Summary</span>
                                            </h5>
                                        </div>
                                        <div style={{ color: 'red', marginRight: '25px' }}>

                                            {this.state.activeBtn !== 1 && this.state.new_base_route_summary_data != [] ? (
                                                <span>
                                                    * Note: Please select filters and click submit to display data
                                                </span>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body row">

                                    <div className={"row col-xl-12 col-lg-12 "}>
                                        <div id="myGrid" style={{ height: "480px", width: "100%" }} className={"ag-theme-balham"}>
                                            {
                                                this.state.is_loading
                                                    ?
                                                    <div className="ag-overlay-loading" style={{ height: "100%", width: "100%", textAlign: "center", fontWeight: "600", fontSize: "30px", paddingTop: "100px" }}>Loading...</div> :

                                                    <AgGridReact
                                                        modules={this.state.modules}
                                                        columnDefs={colDefs}
                                                        defaultColDef={this.state.defaultColDef}
                                                        rowData={this.state.new_base_route_summary_data}
                                                        enableCharts={false}
                                                        // enableRangeSelection={false}
                                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                        onGridReady={this.onGridReady}
                                                        onGridState={this.onGridState}
                                                        statusBar={this.state.statusBar}
                                                        sideBar={this.state.sideBar}
                                                        paginationPageSize={this.state.paginationPageSize}
                                                        pagination={true}
                                                        enableRangeSelection={true}
                                                        gridOptions={{
                                                            context: { componentParent: this }
                                                        }}
                                                        frameworkComponents={this.state.frameworkComponents}
                                                        //onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                                        onCellClicked={this.onCellClicked.bind(this)}
                                                        headerHeight={this.state.headerHeight}
                                                        rowHeight={this.state.rowHeight}
                                                    />
                                            }
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>}


                </div>


                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

                {
                    (this.state.sliderRouteTranslate1 != "") ?
                        <div className={"slide-r " + (this.state.sliderRouteTranslate1)} style={{ overflow: "auto" }}>
                            <div className="slide-r-title">
                                <h4>
                                    View Route Data
                                    <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseUploadDiv.bind(this)} >X</span>
                                </h4>
                            </div>
                            <div className="slide-r-body" style={{ position: "relative" }}>
                                <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                                    <NewDrawMap
                                        mapFor={"multiroutedeviation"}
                                        mapinfo={{
                                            "coords": this.state.route_coords1,
                                            // "consigner_coordinates": this.state.defaultCompleteTrips[0]['consigner_coordinates'],
                                            "consignee_coordinates": this.state.dealersList,
                                            "route_details": {
                                                "start_time": "",
                                                "end_time": ""
                                            }
                                        }}
                                        // coords={this.state.route_coords} 
                                        consignments_list={[]}
                                        breaks={this.state.breaks_list}
                                    />
                                </div>
                            </div>

                        </div>
                        : ""}

                <div className={"slide-r " + (this.state.slidertranslate)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            Implement Route
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12">
                            <div className="form-group col-xl-12 col-lg-12">
                                <label>Implement Route From:</label>
                                <Datetime
                                    value={this.state.implementroutedate}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{ placeholder: '', name: 'implementroutedate', autoComplete: 'off' }}
                                    dateFormat="YYYY-MM-DD"
                                    name="implementroutedate"
                                    onChange={this.handlerStartDateTime.bind(this)}
                                />
                            </div>

                            <div className="form-group col-xl-3 col-lg-3">
                                <button style={{ marginTop: "30px" }} onClick={this.onClickShowUpdateData.bind(this)} type="button" className="btn btn-success">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}



function GetSortOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}



function sortPeriodTypes(periods_list) {
    var quarters_dict = { "4": ["Jan", "Feb", "Mar"], "1": ["Apr", "May", "Jun"], "2": ["Jul", "Aug", "Sep"], "3": ["Oct", "Nov", "Dec"] };
    var final_list = ["ALL"];
    var distinctYears = [];
    periods_list = periods_list.filter((e) => {
        var matches = e.match(/(\d+)/);
        // console.log(matches)
        if (matches != null) {
            if (matches.length) {
                var year = parseInt(matches[0])
                if (distinctYears.indexOf(year) < 0) {
                    distinctYears.push(year)
                }
            }
        }

        return e != "ALL"
    })
    distinctYears = distinctYears.sort()
    distinctYears.map((e) => {
        var filteredData = periods_list.filter(k => k.includes(e.toString()))
        if (filteredData.length) {
            var quarters = filteredData.filter(j => j.includes("Q"));
            var resultMonths = filteredData.filter(j => j.includes("Q") == false);
            quarters = quarters.sort()
            if (quarters.length) {
                quarters.map(q => {
                    final_list.push(q)
                    var quarter_no = q.split("Q")[1];
                    var quarter_months = quarters_dict[quarter_no];
                    quarter_months.map(m => {
                        var finalMonth = resultMonths.filter(r => r.includes(m));
                        if (finalMonth.length) {
                            final_list.push(finalMonth[0])
                        }
                    })
                })
            }
        }
    });
    final_list = final_list.filter(e => e != "");
    return final_list
}
import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from "@ag-grid-community/react";
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import $ from "jquery";
import Select from 'react-select';
import CountUp from 'react-countup';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
var redirectURL = require("../redirectURL");
var moment = require('moment');

export default class ContainerImportsDataAdHoc extends Component {
  constructor(props) {
    super(props);
    var date2 = new Date();
    var currentDate = moment(date2).format("YYYY-MM-DD");
    var currentStart = moment(date2).subtract(4, "months").format("YYYY-MM-DD")
    this.state = {
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        width: 160,
        resizable: true,
      },
      eventAction: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      rowData: [],
      rowGroupPanelShow: "always",
      statusBar: {
      },
      paginationPageSize: 100,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      animateRows: true,
      dept_code: "",
      pagetitle: "",
      csvData: 0,
      overly: "show-n",
      loadshow: "show-n",
      pagetitle: "",
      eventGridAction: "gridAction",
      rowId: "",
      show: false,
      basicTitle: "",
      basicType: "",
      startDate: currentStart,
      endDate: currentDate,
      totalData: [],
      all: [],
      in_sea_transit: [],
      at_sea_port: [],
      in_rail_transit: [],
      at_icd: [],
      intransit_to_msil: [],
      customs_clearance_pending: [],
      delivery_order_pending: [],
      handover_pending: [],
      validity:[],
      completed:[],
      untrackable:[],
      //Counters value
      all_counter: 0,
      in_sea_transit_counter: 0,
      at_sea_port_counter: 0,
      in_rail_transit_counter: 0,
      at_icd_counter: 0,
      intransit_to_msil_counter: 0,
      customs_clearance_pending_counter: 0,
      delivery_order_pending_counter: 0,
      handover_pending_counter: 0,
      validity_counter:0,
      completed_counter:0,
      untrackable_counter:0,
      pod_types:[],
      selected_pod:[{value:"ALL",label:"ALL"}],
      selected_country:[{value:"ALL",label:"ALL"}],
      country_names:[],
      selected_criteria:{value:'eta_1st_pol',label:'ETD POL'},
      counter_type:'',
    };
  }

  async componentDidMount() {
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    })
    console.log(this.state.startDate, 'startDate', this.state.endDate)
    var params = {
      start_date: this.state.startDate,
      end_date: this.state.endDate,
      selected_criteria : this.state.selected_criteria.value
    }
    console.log(params,'params on mount')
    var data = await redirectURL.post("/consignments/filtercontainerimportsdataadhocData", params).then(async (response) => {
      var totalData = response.data;
      const portOfLoadingSet = new Set(totalData.map(ele => ele.port_of_loading).filter(Boolean));
      const portOfDischargingSet = new Set(totalData.map(ele => ele.port_of_discharging).filter(Boolean));
  
      const portOfLoading = [...portOfLoadingSet].map(ele => ({
        value: ele,
        label: ele
      }));
  
      const portOfDischarging = [...portOfDischargingSet].map(ele => ({
        value: ele,
        label: ele
      }));
      portOfLoading.unshift({ value: 'ALL', label: 'All' });
      portOfDischarging.unshift({ value: 'ALL', label: 'All' });
      // console.log(portOfLoading.sort((a, b) => a.label.localeCompare(b.label)), 'portOfLoading', portOfDischarging.sort((a, b) => a.label.localeCompare(b.label)))
      this.setState({
        country_names : portOfLoading.sort((a, b) => a.label.localeCompare(b.label)),
        pod_types : portOfDischarging.sort((a, b) => a.label.localeCompare(b.label)),
      })
      this.conterCalculation(totalData)
    })
  }

  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
    });
  }

  handlerStartDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    var month = ("0" + (d.getMonth() + 1)).slice(-2); // Adding leading zero if month is less than 10
    var day = ("0" + d.getDate()).slice(-2); // Adding leading zero if day is less than 10
    var startdate = d.getFullYear() + "-" + month + "-" + day;
    if (this.state.endDate != undefined && this.state.endDate != "") {
      var startDate = new Date(startdate);
      var endDate = new Date(this.state.endDate);
      var startTime = startDate.getTime();
      var endTime = endDate.getTime();
      if (startTime > endTime) {
        this.setState({
          show: true,
          basicTitle: 'To Date should be greater than From Date',
          basicType: "danger",
          loadshow: 'show-n'
        });
      } else {
        this.setState({
          startDate: startdate
        });
      }
    } else {
      this.setState({
        startDate: startdate
      });
    }
  }

  handlerEndDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    var month = ("0" + (d.getMonth() + 1)).slice(-2); // Adding leading zero if month is less than 10
    var day = ("0" + d.getDate()).slice(-2); // Adding leading zero if day is less than 10
    var edate = d.getFullYear() + "-" + month + "-" + day;
    if (this.state.startDate != undefined && this.state.startDate != "") {
      var startDate = new Date(this.state.startDate);
      var endDate = new Date(edate);
      var startTime = startDate.getTime();
      var endTime = endDate.getTime();
      if (startTime > endTime) {
        this.setState({
          show: true,
          basicTitle: 'To Date should be greater than From Date',
          basicType: "danger",
          loadshow: 'show-n'
        });
      } else {
        this.setState({
          endDate: edate
        });
      }
    } else {
      this.setState({
        endDate: edate
      });
    }
  }
  resetButton = () =>{
    window.location.reload()
  }
  onClickSubmitData = async () => {
    this.setState({
      overly: "show-m",
      loadshow: "show-m",
    })
    var selected_countries = []
    var selected_pod_list = []
    console.log(this.state.selected_country,'selected_country',this.state.selected_pod,'selected_pod')
    if (this.state.selected_country && this.state.selected_country.length > 0) {
      var clist1 = this.state.selected_country;
      clist1.map((item) => {
        selected_countries.push(item.value)
      })
    }
    else { 
    }
    if (this.state.selected_pod && this.state.selected_pod.length > 0) {
      var clist2 = this.state.selected_pod;
      clist2.map((item) => {
        selected_pod_list.push(item.value)
      })
    }
    else {
    }
    console.log(selected_countries,'selected_countries',selected_pod_list,'selected_pod_list',this.state.selected_criteria.value,'criteria')
    if (this.state.startDate !== undefined && this.state.startDate !== "" && this.state.startDate !== null && this.state.endDate !== undefined && this.state.endDate !== null && this.state.endDate !== "" && selected_countries !== undefined && selected_countries != [] && selected_countries !== null && selected_pod_list !== undefined && selected_pod_list != [] && selected_pod_list !== null && this.state.selected_criteria.value !== "" && this.state.selected_criteria.value !== undefined ) {
      console.log(this.state.startDate, 'startDate', this.state.endDate)
      var params = {
        start_date: this.state.startDate,
        end_date: this.state.endDate,
        selected_countries : selected_countries,
        selected_pod_list :selected_pod_list,
        selected_criteria : this.state.selected_criteria.value
      }
      var data = await redirectURL.post("/consignments/filtercontainerimportsdataadhocData", params).then(async (response) => {
        var totalData = response.data;
        this.conterCalculation(totalData)
        // this.setState({
        //   rowData: response.data,
        //   overly: "show-n",
        //   loadshow: "show-n",
        // })
      })
    } else {
      this.setState({
        show: true,
        basicTitle: 'All fields are mandatory',
        basicType: "danger",
        overly: "show-n",
        loadshow: "show-n",
      });
    }


  }
  closeAlert = () => {
    this.setState({
      show: false,
    });
  }

  onClickCounter(params) {
    console.log("IN onClickCounterShowData, Params Here ", params);
    if (params == 0) {
      var customs_clearance_pending = []
      var delivery_order_pending = []
      var handover_pending = []
      var validity = []
      var count = this.state.all.map((ele)=>{
        if (ele.coc_pending == 1) {
          customs_clearance_pending.push(ele)
        }
        if (ele.delivery_order_pending == 1 ) {
          delivery_order_pending.push(ele)
        }
        if (ele.handover_pending == 1) {
          handover_pending.push(ele)
        }
        if (ele.validity == 1) {
          validity.push(ele)
        }
      })
      this.setState({
        rowData: this.state.all,
        counter_type:"ALL",
        customs_clearance_pending: customs_clearance_pending,
        delivery_order_pending: delivery_order_pending,
        handover_pending: handover_pending,
        validity:validity,
        customs_clearance_pending_counter: customs_clearance_pending.length,
        delivery_order_pending_counter: delivery_order_pending.length,
        handover_pending_counter: handover_pending.length,
        validity_counter:validity.length,
      });
    }
    if (params == 1) {
      
      var customs_clearance_pending = []
      var delivery_order_pending = []
      var handover_pending = []
      var validity = []
      var count = this.state.in_sea_transit.map((ele)=>{
        if (ele.coc_pending == 1) {
          customs_clearance_pending.push(ele)
        }
        if (ele.delivery_order_pending == 1 ) {
          delivery_order_pending.push(ele)
        }
        if (ele.handover_pending == 1) {
          handover_pending.push(ele)
        }
        if (ele.validity == 1) {
          validity.push(ele)
        }
      })
      this.setState({
        rowData: this.state.in_sea_transit,
        counter_type:"In Sea Transit",
        validity:validity,
        validity_counter:validity.length,
        customs_clearance_pending: customs_clearance_pending,
      delivery_order_pending: delivery_order_pending,
      handover_pending: handover_pending,

      customs_clearance_pending_counter: customs_clearance_pending.length,
      delivery_order_pending_counter: delivery_order_pending.length,
      handover_pending_counter: handover_pending.length,
      });
    }
    if (params == 2) {
      var customs_clearance_pending = []
      var delivery_order_pending = []
      var handover_pending = []
      var validity =[]
      var count = this.state.at_sea_port.map((ele)=>{
        if (ele.coc_pending == 1) {
          customs_clearance_pending.push(ele)
        }
        if (ele.delivery_order_pending == 1 ) {
          delivery_order_pending.push(ele)
        }
        if (ele.handover_pending == 1) {
          handover_pending.push(ele)
        }
        if (ele.validity == 1) {
          validity.push(ele)
        }
      })
      this.setState({
        rowData: this.state.at_sea_port,
        counter_type:"At Sea Port",
        customs_clearance_pending: customs_clearance_pending,
        delivery_order_pending: delivery_order_pending,
        handover_pending: handover_pending,
        validity:validity,
        validity_counter:validity.length,
        customs_clearance_pending_counter: customs_clearance_pending.length,
        delivery_order_pending_counter: delivery_order_pending.length,
        handover_pending_counter: handover_pending.length,
      });
    }
    if (params == 3) {
      var customs_clearance_pending = []
      var delivery_order_pending = []
      var handover_pending = []
      var validity = []
      var count = this.state.in_rail_transit.map((ele)=>{
        if (ele.coc_pending == 1) {
          customs_clearance_pending.push(ele)
        }
        if (ele.delivery_order_pending == 1 ) {
          delivery_order_pending.push(ele)
        }
        if (ele.handover_pending == 1) {
          handover_pending.push(ele)
        }
        if (ele.validity == 1) {
          validity.push(ele)
        }
      })
      this.setState({
        rowData: this.state.in_rail_transit,
        counter_type:"In Rail Transit",
        customs_clearance_pending: customs_clearance_pending,
        delivery_order_pending: delivery_order_pending,
        handover_pending: handover_pending,
        validity:validity,
        validity_counter:validity.length,
        customs_clearance_pending_counter: customs_clearance_pending.length,
        delivery_order_pending_counter: delivery_order_pending.length,
        handover_pending_counter: handover_pending.length,
      });
    }
    if (params == 4) {
      var customs_clearance_pending = []
      var delivery_order_pending = []
      var handover_pending = []
      var validity = []
      var count = this.state.at_icd.map((ele)=>{
        if (ele.coc_pending == 1) {
          customs_clearance_pending.push(ele)
        }
        if (ele.delivery_order_pending == 1 ) {
          delivery_order_pending.push(ele)
        }
        if (ele.handover_pending == 1) {
          handover_pending.push(ele)
        }
        if (ele.validity == 1) {
          validity.push(ele)
        }
      })
      this.setState({
        rowData: this.state.at_icd,
        counter_type:"At ICD",
        customs_clearance_pending: customs_clearance_pending,
        delivery_order_pending: delivery_order_pending,
        handover_pending: handover_pending,
        validity:validity,
        validity_counter:validity.length,
        customs_clearance_pending_counter: customs_clearance_pending.length,
        delivery_order_pending_counter: delivery_order_pending.length,
        handover_pending_counter: handover_pending.length,
      });
    }
    if (params == 5) {
      var customs_clearance_pending = []
      var delivery_order_pending = []
      var handover_pending = []
      var validity = []
      var count = this.state.intransit_to_msil.map((ele)=>{
        if (ele.coc_pending == 1) {
          customs_clearance_pending.push(ele)
        }
        if (ele.delivery_order_pending == 1 ) {
          delivery_order_pending.push(ele)
        }
        if (ele.handover_pending == 1) {
          handover_pending.push(ele)
        }
        if (ele.validity == 1) {
          validity.push(ele)
        }
      })
      this.setState({
        rowData: this.state.intransit_to_msil,
        counter_type:"In Transit To MSIL",
        customs_clearance_pending: customs_clearance_pending,
        delivery_order_pending: delivery_order_pending,
        handover_pending: handover_pending,
        validity:validity,
        validity_counter:validity.length,
        customs_clearance_pending_counter: customs_clearance_pending.length,
        delivery_order_pending_counter: delivery_order_pending.length,
        handover_pending_counter: handover_pending.length,
      });
    }
    if (params == 6) {
      this.setState({
        rowData: this.state.customs_clearance_pending,
        counter_type:"Customs Clearance Pending"
      });
    }
    if (params == 7) {
      this.setState({
        rowData: this.state.delivery_order_pending,
        counter_type:"Delivery Order Pending"
      });
    }
    if (params == 8) {
      this.setState({
        rowData: this.state.handover_pending,
        counter_type:"Handover Pending"
      });
    }
    if (params == 9) {
      this.setState({
        rowData: this.state.completed,
        counter_type:"Completed"
      });
    }
    if (params == 10) {
      this.setState({
        rowData: this.state.untrackable,
        counter_type:"Untrackable"
      });
    }
    if (params == 11) {
      this.setState({
        rowData: this.state.validity,
        counter_type:"DO validity less than 10 days"
      });
    }
  }

  conterCalculation = (totalData) => {
    console.log(totalData.length, 'totalLength')
   
    // var all = []
    var in_sea_transit = []
    var at_sea_port = []
    var in_rail_transit = []
    var at_icd = []
    var intransit_to_msil = []
    var customs_clearance_pending = []
    var delivery_order_pending = []
    var handover_pending = []
    var validity = []
    var completed= []
    var untrackable = []

    if (totalData.length > 0) {
      var processing_data = totalData.map((ele) => {
        if (ele.last_status && ele.last_status == "in_sea_transit") {
          in_sea_transit.push(ele)
        } else if (ele.last_status && ele.last_status == "at_sea_port") {
          at_sea_port.push(ele)
        } else if (ele.last_status && ele.last_status == "in_rail_transit") {
          in_rail_transit.push(ele)
        } else if (ele.last_status && ele.last_status == "at_icd") {
          at_icd.push(ele)
        } else if (ele.last_status && ele.last_status == "intransit_to_msil") {
          intransit_to_msil.push(ele)
        }
        else if (ele.last_status && ele.last_status == "empty_returned") {
          completed.push(ele)
        }
        else if (ele.last_status == "") {
          untrackable.push(ele)
        }
        if (ele.coc_pending == 1) {
          customs_clearance_pending.push(ele)
        }
        if (ele.delivery_order_pending == 1 ) {
          delivery_order_pending.push(ele)
        }
        if (ele.handover_pending == 1) {
          handover_pending.push(ele)
        }
        if (ele.validity == 1) {
          validity.push(ele)
        }
      })

    }
    console.log(untrackable.length,'length')
    // console.log(customs_clearance_pending,'customs_clearance_pending',delivery_order_pending,'delivery_order_pending',handover_pending)
    this.setState({
      all: totalData,
      rowData: totalData,
      in_sea_transit: in_sea_transit,
      at_sea_port: at_sea_port,
      in_rail_transit: in_rail_transit,
      at_icd: at_icd,
      intransit_to_msil: intransit_to_msil,
      customs_clearance_pending: customs_clearance_pending,
      validity:validity,
      delivery_order_pending: delivery_order_pending,
      handover_pending: handover_pending,
      completed:completed,
      untrackable:untrackable,

      all_counter: totalData.length,
      in_sea_transit_counter: in_sea_transit.length,
      at_sea_port_counter: at_sea_port.length,
      in_rail_transit_counter: in_rail_transit.length,
      at_icd_counter: at_icd.length,
      intransit_to_msil_counter: intransit_to_msil.length,
      customs_clearance_pending_counter: customs_clearance_pending.length,
      validity_counter:validity.length,
      delivery_order_pending_counter: delivery_order_pending.length,
      handover_pending_counter: handover_pending.length,
      completed_counter:completed.length,
      untrackable_counter:untrackable.length,

      overly: "show-n",
      loadshow: "show-n",
    })
  }
  getCountryListItems = selected_country =>{
    this.setState(
    { selected_country },
    () => console.log(this.state.selected_country,'selected_country')
    );
}
getPodListItems = selected_pod =>{
  this.setState(
  { selected_pod },
  () => console.log(this.state.selected_pod,'selected_pod')
  );
}

getCriteriaListItems = selected_criteria =>{
  this.setState(
  { selected_criteria },
  () => console.log(this.state.selected_criteria,'selected_criteria')
  );
 
}

  render() {
    const columnDefs = [
      {
        headerName: "Inco Term",
        field: "",
        filter: true,
        width: 130,
      },
      {
        headerName: "MSIL Plant Location",
        field: "plant_name",
        filter: true,
        width: 130,
      },
      {
        headerName: "Shipper Name",
        field: "shipper_name",
        filter: true,
        width: 130,
      },
      {
        headerName: "Consignee Name",
        field: "consignee_name",
        filter: true,
        width: 130,

      },
      {
        headerName: "Booking Number",
        field: "booking_number",
        filter: true,
        width: 130,
      },
      {
        headerName: "Bill of Lading Type",
        field: "bill_of_lading_type",
        filter: true,
        width: 130,
      },
      {
        headerName: "Purchase Order No",
        field: "purchase_order_no",
        filter: true,
        width: 130,
      },
      {
        headerName: "Invoice Ref No.",
        field: "invoice_ref_no",
        filter: true,
        width: 130,
      },
      {
        headerName: "Commodity",
        field: "commodity",
        filter: true,
        width: 130,
      },
      {
        headerName: "Place of Receipt",
        field: "place_of_receipt",
        filter: true,
        width: 130,
      },
      {
        headerName: "Port of Loading",
        field: "port_of_loading",
        filter: true,
        width: 130,
      },
      {
        headerName: "Port of Discharging",
        field: "port_of_discharging",
        filter: true,
        width: 130,
      },
      {
        headerName: "Place of Delivery",
        field: "place_of_delivery",
        filter: true,
        width: 130,
      },
      {
        headerName: "Cargo Nature",
        field: "cargo_nature",
        filter: true,
        width: 130,
      },
      {
        headerName: "Gross Weight KG",
        field: "gross_weight_kg",
        filter: true,
        width: 130,
      },
      {
        headerName: "Container Type/Size",
        field: "container_type_size",
        filter: true,
        width: 130,
      },
      {
        headerName: "Container Quantity",
        field: "container_quantity",
        filter: true,
        width: 130,
      },
      {
        headerName: "Container Number",
        field: "container_no",
        filter: true,
        width: 130,
      },
      {
        headerName: "1st Vessel Name",
        field: "first_vessel_name",
        filter: true,
        width: 130,
      },
      {
        headerName: "Proforma ETD at 1st POL",
        field: "proforma_etd_at_1st_pol",
        filter: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.proforma_etd_at_1st_pol == undefined || params.data.proforma_etd_at_1st_pol === "") ? "" : getHyphenDDMMMYYYYHHMM(params.data.proforma_etd_at_1st_pol)
          } else {
            return "";
          }
        },
      },
      {
        headerName: "ETA 1st POL",
        field: "eta_1st_pol",
        filter: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.eta_1st_pol == undefined || params.data.eta_1st_pol === "") ? "" : getHyphenDDMMMYYYYHHMM(params.data.eta_1st_pol)
          } else {
            return "";
          }
        },
      },
      {
        headerName: "ATA 1st POL",
        field: "ata_1st_pol",
        filter: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.ata_1st_pol == undefined || params.data.ata_1st_pol === "") ? "" : getHyphenDDMMMYYYYHHMM(params.data.ata_1st_pol)
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Actual Vessel Unloading at T/S Port Date - Vessel Unloading at T/S Port (VD)",
        field: "actual_vessel_unloading_at_ts_port_date_vessel_unloading_at_ts_port_vd",
        filter: true,
        width: 170,
        valueGetter: function (params) {
          if (params) {
            return (params.data.actual_vessel_unloading_at_ts_port_date_vessel_unloading_at_ts_port_vd == undefined || params.data.actual_vessel_unloading_at_ts_port_date_vessel_unloading_at_ts_port_vd === "") ? "" : getHyphenDDMMMYYYYHHMM(params.data.actual_vessel_unloading_at_ts_port_date_vessel_unloading_at_ts_port_vd)
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Last Vessel Name",
        field: "last_vessel_name",
        filter: true,
        width: 130,
      },
      {
        headerName: "Actual Vessel Arrival at T/S Port Date - Vessel Arrival at T/S Port",
        field: "actual_vessel_arrival_at_ts_port_date_vessel_arrival_at_ts_port",
        filter: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.actual_vessel_arrival_at_ts_port_date_vessel_arrival_at_ts_port == undefined || params.data.actual_vessel_arrival_at_ts_port_date_vessel_arrival_at_ts_port === "") ? "" : getHyphenDDMMMYYYYHHMM(params.data.actual_vessel_arrival_at_ts_port_date_vessel_arrival_at_ts_port)
          } else {
            return "";
          }
        },
      }, {
        headerName: "Actual Vessel Departure from T/S Port Date - Vessel Departure from T/S Port",
        field: "actual_vessel_departure_from_ts_port_date_vessel_departure_from_ts_port",
        filter: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.actual_vessel_departure_from_ts_port_date_vessel_departure_from_ts_port == undefined || params.data.actual_vessel_departure_from_ts_port_date_vessel_departure_from_ts_port === "") ? "" : getHyphenDDMMMYYYYHHMM(params.data.actual_vessel_departure_from_ts_port_date_vessel_departure_from_ts_port)
          } else {
            return "";
          }
        },
      }, {
        headerName: "ETA at Last POD",
        field: "eta_at_last_pod",
        filter: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.eta_at_last_pod == undefined || params.data.eta_at_last_pod === "") ? "" : getHyphenDDMMMYYYYHHMM(params.data.eta_at_last_pod)
          } else {
            return "";
          }
        },
      }, {
        headerName: "ATA at Last POD",
        field: "ata_at_last_pod",
        filter: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.ata_at_last_pod == undefined || params.data.ata_at_last_pod === "") ? "" : getHyphenDDMMMYYYYHHMM(params.data.ata_at_last_pod)
          } else {
            return "";
          }
        },
      }, {
        headerName: "SMTP No",
        field: "",
        filter: true,
        width: 130,
      }, {
        headerName: "SMTP Handover Date",
        field: "smtp_handover_date",
        filter: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.smtp_handover_date == undefined || params.data.smtp_handover_date === "") ? "" : getHyphenDDMMMYYYYHHMM(params.data.smtp_handover_date)
          } else {
            return "";
          }
        },
      },
      {
        headerName: "BOE No.",
        field: "boe_no",
        filter: true,
        width: 130,
      },
      {
        headerName: "BOE Date",
        field: "boe_date",
        filter: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.boe_date == undefined || params.data.boe_date === "") ? "" : getHyphenDDMMMYYYYHHMM(params.data.boe_date)
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Delivery Order Validity Date",
        field: "do_validity",
        filter: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.do_validity == undefined || params.data.do_validity === "") ? "" : getHyphenDDMMMYYYYHHMM(params.data.do_validity)
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Inbound rail departure Date",
        field: "inbound_rail_departure_date_str",
        filter: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.inbound_rail_departure_date_str == undefined || params.data.inbound_rail_departure_date_str === "") ? "" : (params.data.inbound_rail_departure_date_str)
          } else {
            return "";
          }
        },
      }, {
        headerName: "Inbound rail arrival Date",
        field: "inbound_rail_arrival_date_str",
        filter: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.inbound_rail_arrival_date_str == undefined || params.data.inbound_rail_arrival_date_str === "") ? "" : (params.data.inbound_rail_arrival_date_str)
          } else {
            return "";
          }
        },
      },
      {
        headerName: "COC Date",
        field: "coc_date",
        filter: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.coc_date == undefined || params.data.coc_date === "") ? "" : getHyphenDDMMMYYYYHHMM(params.data.coc_date)
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Handover Date",
        field: "handover_date",
        filter: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.handover_date == undefined || params.data.handover_date === "") ? "" : getHyphenDDMMMYYYYHHMM(params.data.handover_date)
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Train/Truck No",
        field: "trailer_no",
        filter: true,
        width: 130,
      },
      {
        headerName: "Transit Mode",
        field: "transit_mode",
        filter: true,
        width: 130,
      },
      {
        headerName: "Dispatch from ICD",
        field: "dispatch_date",
        filter: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.dispatch_date == undefined || params.data.dispatch_date === "") ? "" : getHyphenDDMMMYYYYHHMM(params.data.dispatch_date)
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Empty container returned Date - I/B Empty Container Returned",
        field: "empty_container_returned_date_ib_empty_container_returned",
        filter: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.empty_container_returned_date_ib_empty_container_returned == undefined || params.data.empty_container_returned_date_ib_empty_container_returned === "") ? "" : getHyphenDDMMMYYYYHHMM(params.data.empty_container_returned_date_ib_empty_container_returned)
          } else {
            return "";
          }
        },
      },
      // {
      //   headerName: "ETA Place of Delivery",
      //   field: "eta_place_of_delivery",
      //   filter: true,
      //   width: 130,
      //   valueGetter: function (params) {
      //     if (params) {
      //       return (params.data.eta_place_of_delivery == undefined || params.data.eta_place_of_delivery === "") ? "" : getHyphenDDMMMYYYYHHMM(params.data.eta_place_of_delivery);
      //     } else {
      //       return "";
      //     }
      //   },
      // },
      // {
      //   headerName: "ATA Place of Delivery",
      //   field: "ata_place_of_delivery",
      //   filter: true,
      //   width: 130,
      //   valueGetter: function (params) {
      //     if (params && params.data.ata_place_of_delivery) {
      //       return getHyphenDDMMMYYYYHHMM(params.data.ata_place_of_delivery);
      //     } else {
      //       return "";
      //     }
      //   },
      // },

    ];

    return (
      <div class="container-fluid">
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
         <h5>
              <i className="icofont icofont-vehicle-delivery-van cus-i"></i>{" "}
              <span>Container Imports New</span>
              </h5>
        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
          <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
        <div className={"dataLoadpage " + (this.state.loadshow)}></div>
        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
          <div className="loader-box">
            <div className="loader-box">
              <div className="rotate dashed colored">
              </div>
            </div>
          </div>
        </div>

        <div className="row col-xl-14 col-lg-14">
        <div className="form-group col-xl-2 col-lg-2" >
						<label>Select Origin </label>
						<Select placeholder={"Origin"} closeMenuOnSelect={false} value={this.state.selected_country} isMulti="true" 
            onChange={this.getCountryListItems.bind(this)}
             style={{borderRadius:"0px"}} options={this.state.country_names} />
                                       
					</div>
					<div className="form-group col-xl-2 col-lg-2">
						<label>Select POD </label>
						<Select placeholder={"Select POD"} closeMenuOnSelect={false} value={this.state.selected_pod} isMulti="true" 
             onChange={this.getPodListItems.bind(this)} 
             style={{borderRadius:"0px"}} options={this.state.pod_types} />
                                       
					</div>
					<div className="form-group col-xl-2 col-lg-2">
						<label>Select Criteria </label>
						<Select placeholder={"Select Criteria"} closeMenuOnSelect={true} value={this.state.selected_criteria} 
             onChange={this.getCriteriaListItems.bind(this)}
              style={{borderRadius:"0px"}}  options=
                                    {[
                                    {value:'eta_1st_pol',label:'ETD POL'},
                                    {value:'ata_1st_pol',label:'ATD POL'},
                                    {value:'eta_at_last_pod',label:'ETA POD'},
                                    {value:'ata_at_last_pod',label:'ATA POD'},
                                    {value:'smtp_handover_date',label:'SMTP Handover'},
                                    {value:'inbound_rail_arrival_date',label:'ATA ICD'},
                                    {value:'dispatch_date',label:'ATD ICD'},
                                ]} />
                                       
					</div>
          <div className="col-xl-2 col-lg-2 form-group">
            <label>From Date</label>
            <Datetime value={this.state.startDate} disableCloseOnClickOutside={false} closeOnSelect={true} inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off', readOnly: true,className: 'custom-datetime-input' }} dateFormat="YYYY-MM-DD" timeFormat={false} name="startDate" onChange={this.handlerStartDateTime.bind(this)}
             isValidDate={current => {
              return current.isSameOrBefore(moment(), 'day');
            }}  />
          </div>

          <div className="col-xl-2 col-lg-2 form-group">
            <label>To Date</label>
            <Datetime value={this.state.endDate} disableCloseOnClickOutside={false} closeOnSelect={true} inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off', readOnly: true,className: 'custom-datetime-input' }} dateFormat="YYYY-MM-DD" timeFormat={false} onChange={this.handlerEndDateTime.bind(this)}
             isValidDate={current => {
              return current.isSameOrAfter(moment(new Date(this.state.startDate)), 'day');
            }} />
          </div>
          <div className="form-group">
            <label>&nbsp;</label><br />
            <button type="button" className="btn btn-success" onClick={this.onClickSubmitData.bind(this)}>Submit</button>
           
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div className="form-group">
    <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label><br />
    <a href="/containerimportsdataadhoc" title="Reset">
        <svg width="20" height="25" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9312 5.77217C11.7641 4.56989 11.2063 3.4559 10.3438 2.60179C9.48135 1.74768 8.36196 1.20084 7.15811 1.04551C5.95426 0.890172 4.73273 1.13496 3.68169 1.74216C2.63064 2.34936 1.80839 3.28529 1.3416 4.40578M1 1.67299V4.40578H3.73279M1 7.13856C1.16708 8.34084 1.72483 9.45484 2.58731 10.3089C3.4498 11.1631 4.56919 11.7099 5.77304 11.8652C6.97689 12.0206 8.19842 11.7758 9.24947 11.1686C10.3005 10.5614 11.1228 9.62545 11.5896 8.50496M11.9312 11.2377V8.50496H9.19836" stroke="black" stroke-width="1.36639" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </a>
</div>


        </div>
        <div className="card">
          <div className="card-body" id="dashboardCounterCardBody">
            <div className="crm-numbers pb-0">
              {/* {(this.state.countertype != 'deliver')? */}
              <div className="row">
                {/*ALL*/}
                <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this, 0)}>
                  <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> All</span>
                  <h5 className="txt-info f35">
                    <span className="counter greenfont">
                      <CountUp end={(this.state.all_counter) ? this.state.all_counter : 0} />
                    </span>
                  </h5>

                </div>
                <div className="col cpointer" onClick={this.onClickCounter.bind(this, 1)}>
                  <span className="f13"><i className="icofont icofont-ship f24 txt-secondary"></i><br />In Sea Transit</span>
                  <h5 className="txt-secondary f35">

                    <span className="counter" >
                    <CountUp end={(this.state.in_sea_transit_counter) ? this.state.in_sea_transit_counter : 0} />
                    </span>

                  </h5>
                </div>
                
                {/*In Sea Transit*/}
                <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this, 2)}>
                  <span className="f13"><i className="icofont icofont-ship-alt txt-success f24"></i><br /> At Sea port</span>
                  <h5 className="txt-success f35">
                    <span className="counter success" >

                    <CountUp end={(this.state.at_sea_port_counter) ? this.state.at_sea_port_counter : 0} />
                    </span>
                  </h5>

                </div>
              
                

                {/*In Tranist to ICD Garhi*/}
                <div className="col cpointer" onClick={this.onClickCounter.bind(this, 3)}>
                  <span className="f13"><i className="icofont icofont-train-line f22 txt-danger"></i><br /> In Rail Transit </span>
                  <h5 className="txt-danger f35">

                    <span className="counter purple" >
                      <CountUp end={(this.state.in_rail_transit_counter) ? this.state.in_rail_transit_counter : 0} />
                    </span>

                  </h5>
                </div>

                {/*Inside ICD Garhi*/}
                <div className="col cpointer" onClick={this.onClickCounter.bind(this, 4)}>
                  <span className="f13"><i className="icofont icofont-ui-home f24 txt-secondary"></i><br /> At ICD  </span>
                  <h5 className="txt-secondary f35">

                    <span className="counter">
                      <CountUp end={(this.state.at_icd_counter) ? this.state.at_icd_counter : 0} />
                    </span>

                  </h5>
                </div>





                {/*In Transit to MSIL*/}
                <div className="col cpointer" onClick={this.onClickCounter.bind(this, 5)}>
                  <span className="f13"><i className="icofont icofont-truck-alt f24 txt-success"></i><br /> In Transit to MSIL</span>
                  <h5 className="txt-success f35">
                    <span className="counter"><CountUp end={(this.state.intransit_to_msil_counter) ? this.state.intransit_to_msil_counter : 0} /></span></h5>

                </div>
                <div className="col cpointer" onClick={this.onClickCounter.bind(this, 9)}>
                  <span className="f13"><i className="icofont icofont-tick-mark f24 txt-success"></i><br /> Completed</span>
                  <h5 className="counter purple f30"><span className="counter"><CountUp end={(this.state.completed_counter) ? this.state.completed_counter : 0} /></span></h5>

                </div>
                <div className="col cpointer" onClick={this.onClickCounter.bind(this, 10)}>
                  <span className="f13"><i className="icofont icofont-map-pins f24 txt-danger"></i><br /> Untrackable</span>
                  <h5 className="txt-primary f30"><span className="counter"><CountUp end={(this.state.untrackable_counter) ? this.state.untrackable_counter : 0} /></span></h5>

                </div>


              </div>

            </div>
          </div></div>

          {this.state.counter_type != "Completed"?<div className="card">
          <div className="card-body" id="dashboardCounterCardBody">
            <div className="crm-numbers pb-0">
              <div className="row">
                <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this, 6)}>
                  <span className="f13"><i className="icofont icofont-law-document txt-danger f24"></i><br /> Customs Clearance Pending</span>
                  <h5 className="txt-info f35">
                    <span className="counter secondary">
                      <CountUp end={(this.state.customs_clearance_pending_counter) ? this.state.customs_clearance_pending_counter : 0} />
                    </span>
                  </h5>

                </div>
                <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this, 7)}>
                  <span className="f13"><i className="icofont icofont-delivery-time txt-danger f24"></i><br /> Delivery Order Pending</span>
                  <h5 className="txt-info f35">
                    <span className="counter secondary">

                      <CountUp end={(this.state.delivery_order_pending_counter) ? this.state.delivery_order_pending_counter : 0} />
                    </span>
                  </h5>

                </div>

                <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this, 8)}>
                  <span className="f13"><i className="icofont icofont-delivery-time txt-danger f24"></i><br /> Handover Pending</span>
                  <h5 className="txt-info f35">
                    <span className="counter secondary" >

                    <CountUp end={(this.state.handover_pending_counter) ? this.state.handover_pending_counter : 0} />
                    </span>
                  </h5>

                </div>
                <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this, 11)}>
                  <span className="f13"><i className="icofont icofont-delivery-time txt-danger f24"></i><br /> DO validity less than 10 days</span>
                  <h5 className="txt-info f35">
                    <span className="counter secondary" >

                    <CountUp end={(this.state.validity_counter) ? this.state.validity_counter : 0} />
                    </span>
                  </h5>
                </div>
                {/* <div className="col cpointer">
                  <span className="f13"><i className="icofont icofont-delivery-time f24 txt-danger"></i><br />Handover Pending</span>
                  <h5 className="txt-success f35">

                    <span className="counter" onClick={this.onClickCounter.bind(this, 8)}>
                      <CountUp end={(this.state.handover_pending_counter) ? this.state.handover_pending_counter : 0} />
                    </span>

                  </h5>
                </div> */}

              </div>

            </div>
          </div></div>
          :""
  }
        <div className="card col-xl-12">
          <div className="card-header">
            <h5>
              {/* <i className="icofont icofont-vehicle-delivery-van cus-i"></i>{" "} */}
              <span style={{marginLeft:'15px'}}>{this.state.counter_type}</span>
              </h5>
              <div className="card-body">
                <div className="row">

                  <div
                    id="myGrid"
                    style={{ width: "100%", height: "478px" }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      modules={this.state.modules}
                      columnDefs={columnDefs}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.rowData}
                      paginationPageSize={this.state.paginationPageSize}
                      pagination={true}
                      gridOptions={{
                        context: { componentParent: this },
                      }}
                    />
                  </div>
                </div>
              </div>
            
          </div>


        </div>

      </div>
    );
  }
}

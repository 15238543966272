
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');

 
const EditMLLConsignment = (props) => {
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickEditBlock(props);
    };

    return (
        <div>
          	<button onClick={handleOverspeedClick} className={"custom-btn f12 label label-success "} title="Edit" ><i className="icofont icofont-edit"></i> Edit</button>
          
        </div>
    );
};

export default EditMLLConsignment;

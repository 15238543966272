
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const ImportsPortAction = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onShowConsignmentImportsEventsClick(props);
    };
    
    //var colr = 'grayfont'
      
    if(props.data.port_delay == 1)
    {
        var colr = 'redfont'
    }
    else
    {
        if(props.data.last_status == 'Arrived at Indian Port' || props.data.last_status == 'Port In')
        {
            var colr = 'yellowfont'
        }
        else if(props.data.last_status == 'In Transit to MSIL' || props.data.last_status == 'Port Out' || props.data.last_status == 'In ICD Ghari')
        {
                var colr = 'greenfont'
        }
        else{
            var colr = 'grayfont'
        }
    }
    
    return (
        <div>
            <button onClick={handleEventClick} className="custom-btn f22 label label-success" 
            title="In Port" ><i className={"icofont icofont-ship "+(colr)}></i> &nbsp;</button>
            
        </div>
    );
};

export default ImportsPortAction;

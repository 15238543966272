'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "./updatelmstates";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CellGraphComponent from './cellGraphComponent';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { HardwareDesktopWindows } from 'material-ui/svg-icons';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from "@fullcalendar/timegrid";  
import EditroleAction from './editroleAction';  
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class LmRoles extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          consignmentforceclose:Consignmentforceclose,
          consignmentActions:ConsignmentActions,
          UpdateButton:UpdateButton,
          MarkDeliver : MarkDeliver,
          commentActions : CommentActions,
          EditroleAction:EditroleAction,
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        selectmenuoptins : [],
        menuitem : [],
        rowId : "",
        //components : {datepicker:getDatePicker()}
    }
    this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this)
  }

componentDidMount(){
    loadDateTimeScript();
    this.pageAccess();
    this.onLoadPageData();
    redirectURL.post("/lmconsignees/getLmMenus").then((response)=>{
        var menus = response.data;
        console.log(menus,"menus")
        if(menus.length > 0)
        {
            var items = []
            menus.map((item) =>{
                items.push({"value":item.code,"label":item.name})
            })
            this.setState({
                selectmenuoptins : items
            })
        }       
    }).catch((e)=>{
        console.log(e);
    })
}
onLoadPageData(){
    redirectURL.post("/lmconsignees/getLmRoles").then((response)=>{
        console.log(response.data);
        this.setState({
            rowData : response.data
        })
    }).catch((e)=>{
        console.log(e);
    })    
}


async pageAccess(){
    this.setState({
        loadshow:'show-m',
        overly:'show-m',
    })
    var currentUrl = this.props.match.path;
    var is_dept_admin = localStorage.getItem("is_department_admin");
    var role = localStorage.getItem("roles");
    if(role.indexOf("lm_site_admin") >= 0 || is_dept_admin == 1 || role.indexOf("lm_admin") >= 0)
    {
        this.setState({
            pageAccess : 3,
        })
        redirectURL.post("/lmconsignees/getLmLoadingPorts").then(async (response2)=>{
            //console.log(response1.data,"LLPN");
            var lpData = response2.data;
            var lpNames = [];
            if(lpData.length > 0)
            {
                lpData.map((item) => {
                    if(item.comp_short_name != '' && item.comp_short_name != undefined)
                    {
                        lpNames.push({
                            loading_port_code:item.loading_port_code,
                            loading_port:item.comp_short_name,
                        })
                    }
                })
                var selloading_port_code = this.state.loading_port_code;
                console.log(selloading_port_code);
                
                var comp_code = "";
                if(selloading_port_code !=undefined && selloading_port_code !='')
                {
                    var loading_port_code = selloading_port_code;
                    var filterChkCode = loading_port_code;
                    if(loading_port_code.includes("GUR") == true)
                    {
                        filterChkCode = "GUR";
                    }
                    var result1 = lpData.filter(obj => {
                        return obj.loading_port_code == filterChkCode
                    })
                    if(result1.length > 0)
                    {
                        comp_code = result1[0].comp_code;
                    }
                }
                else
                {
                    var loading_port_code = lpData[0].loading_port_code;
                    comp_code = lpData[0].comp_code;
                }
                this.setState({
                    comp_code : comp_code,
                    loading_port_code : loading_port_code,
                    loadshow:'show-n',
                    overly:'show-n',
                    lpNames:lpNames
                });
                console.log(lpData[0].loading_port_code,"lpData[0].loading_port_code")
                await this.onLoadPageData();
            }
        }).catch((e)=>{
            console.log(e);
        })
    }
    else
    {
        var query = {
            "userid" : localStorage.getItem("userid"),
            "menu_url" : currentUrl,
        }
        redirectURL.post("/lmconsignees/checkUserCurrentPageAccess",query).then(async (response)=>{
            var result = response.data;
            if(result.length > 0)
            {                
                var pageAccess = 2;
                var lpNames = [];
                await result.map(async function(item){
                    if(item.loading_port_name != '' && item.loading_port_name != undefined)
                    {
                        lpNames.push({
                            loading_port_code:item.loading_port_code,
                            loading_port:item.loading_port_name,
                        })
                    }
                })
                
                var selloading_port_code = this.state.loading_port_code;
                console.log(selloading_port_code);
                var comp_code = "";
                if(selloading_port_code !=undefined && selloading_port_code !='')
                {
                    var loading_port_code = selloading_port_code;
                    var filterChkCode = loading_port_code;
                    if(loading_port_code.includes("GUR") == true)
                    {
                        filterChkCode = "GUR";
                    }
                    var result1 = result.filter(obj => {
                        return obj.loading_port_code == filterChkCode
                    })
                    if(result1.length > 0)
                    {                        
                        if(result1[0].access !='' && result1[0].access !=undefined)
                        {
                            var pageAccess = result1[0].access;                    
                        }
                        comp_code = result1[0].comp_code;
                    }
                }
                else
                {
                    if(result[0].access !='' && result[0].access !=undefined)
                    {
                        var pageAccess = result[0].access;                    
                    }
                    var loading_port_code = result[0].loading_port_code;
                    comp_code = result[0].comp_code;
                }
                //console.log(lpNames,"lpNames")
                this.setState({
                    pageAccess : pageAccess,
                    loading_port_code : loading_port_code,
                    comp_code : result[0].comp_code,
                    lpNames : lpNames,
                    loadshow:'show-n',
                    overly:'show-n',
                })
                console.log(loading_port_code,"loading_port_code")
                await this.onLoadPageData();
            }
            else
            {
                alert("You Don't have access to this screen, Please request access")
                window.location.href="/lmpageaccess";
            }
        }).catch((e)=>{
            console.log(e);
        })
    }
}
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    this.setState({
        show: false
    });
}

onCellClicked(e)
{
    if(e.colDef.field == "delete")
    {
        var rowId = e.data._id;
        var role_name = e.data.role_alias;
        console.log(e.data._id);
        var qry = {
            rowId:rowId,
            state : e.data.state,
            comp_code: localStorage.getItem('comp_code'),
            user_name: localStorage.getItem('username'),
            email: localStorage.getItem('email'),
            designation: localStorage.getItem('designation'),
            user_department: localStorage.getItem('lm_department'),
            staff_id: localStorage.getItem('staff_id'),
            user_role: localStorage.getItem('roles'),
            role_name: role_name,
        }
        //var confirm = confirm("Are You Sure! Do you want really delete?");
        if (window.confirm("Are You Sure! Do you want really delete?"))
        {
            redirectURL.post("/lmconsignees/deleteLmRoles",qry).then((response)=>{
                console.log(response.data);
                if(response.data.message == "Success")
                {
                    this.setState({
                        basicTitle:"Deleted Successfully.",
                        basicType : "success",
                        show : true
                    })                    
                }
                else
                {
                    this.setState({
                        basicTitle:"Failed to Delete.",
                        basicType : "warning",
                        show : true
                    })
                }
                this.onLoadPageData();
            }).catch((e)=>{
                console.log(e);
            })
        }        
    }
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    
}
updateLmStates(params)
{
    //console.log(params);
    if(params.role_name !='' && params.role_name !=undefined)
    {
        params['user_name'] = localStorage.getItem('username');
        params['email'] = localStorage.getItem('email');
        params['user_department'] = localStorage.getItem('lm_department');
        params['designation'] = localStorage.getItem('designation');
        params['comp_code'] = localStorage.getItem('comp_code');
        params['staff_id'] = localStorage.getItem('staff_id');
        params['role_name'] = params.data.role_alias;
        params['user_role'] = localStorage.getItem('roles');
        console.log(params,"params")
        redirectURL.post("/lmconsignees/savelmroles",params).then((response)=>{
            console.log(response);
            if(response.data.message == "Success")
            {
                this.setState({
                    basicTitle:"Updated Successfully.",
                    basicType : "success",
                    show : true
                })
            }
            else
            {
                this.setState({
                    basicTitle:"Failed to update.",
                    basicType : "warning",
                    show : true
                })
            }
            this.onLoadPageData();
        })
    }
    else
    {
        this.setState({
            basicTitle:"Fields Should not be Empty.",
            basicType : "warning",
            show : true
        })
    }
}
addNewRow()
{
    // console.log(this.gridApi);
    this.gridApi.insertItemsAtIndex(0,[{role_name:""}])
    // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}
onClickDataExport(){
    var params = {
        columnKeys: ['role_name']
    };
    this.gridApi.exportDataAsCsv(params);
}
showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate",
        menuitem:[],
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}
onShowEditMenu(params){
    var query = {
        "_id" : params.data._id
    }
    redirectURL.post("/lmconsignees/getLmRolesById",query).then((response)=>{
        var roleInfo = response.data.roleInfo;
        if(roleInfo.length > 0)
        {
            $('#role_name').val(roleInfo[0].role_alias);
        }
        this.setState({
            menuitem : response.data.menus,
            rowId:params.data._id,
            bulkslide:'slider-translate',
            overly:'show-m',
        })
    }).catch((e)=>{
        console.log(e);
    })
}
uploadBulkFormHandler(event){
    event.preventDefault();
    var rowId = this.state.rowId;
    var roleName = $("#role_name").val();
    var menus = this.state.menuitem;    
    if(roleName !='' && roleName !=undefined && menus !='' && menus !=undefined)
    {
        var menuItems = []
        menus.map(function(item){
            menuItems.push(item.value)
        })
        var reqParams = {
            role_name : roleName,
            menus : menuItems,
            _id : rowId,
        }
        reqParams['user_name'] = localStorage.getItem('username');
        reqParams['email'] = localStorage.getItem('email');
        reqParams['user_department'] = localStorage.getItem('lm_department');
        reqParams['designation'] = localStorage.getItem('designation');
        reqParams['comp_code'] = localStorage.getItem('comp_code');
        reqParams['staff_id'] = localStorage.getItem('staff_id');
        reqParams['role_name'] = roleName;
        reqParams['user_role'] = localStorage.getItem('roles');
        console.log("reqParams",reqParams)
        redirectURL.post("/lmconsignees/savelmroles",reqParams).then((response)=>{
            var resp=response.data.message;
            console.log(resp)
            $('#role_name').val("");
            document.getElementById("upform").reset();
            if(resp == "Success")
            {
                this.setState({
                    basicTitle : resp,
                    basicType : "success",
                    show : true,
                    bulkslide:'',
                    overly:'show-n',
                    menuitem:[],
                })
            }
            else
            {
                this.setState({
                    basicTitle : resp,
                    basicType : "warning",
                    show : true,
                    menuitem:[],
                })
            }            
            this.onLoadPageData();
        }).catch((e)=>{
            console.log(e);
        })
    }
    else
    {
        this.setState({
            basicTitle : "Fields Should not be Empty",
            basicType : "warning",
            show : true,
        })
    }
};




onSelectBulkPlants= (location) =>{
    var plants=[];
    try{
        location.map(function(e){
            plants.push(e.value);
        })
        console.log(plants);
        this.setState({
            plant_name : plants
        });
    }
    catch(e)
    {
        console.log(e);
    }
    
}

onChangeMenuItem(menuitem){
    this.setState(
        { menuitem },
        () => console.log(`Menu Option selected:`, this.state.menuitem)
    );      
}

  render() {
    
    var columnwithDefs = [      
        {
            headerName: "Role",
            field: "role_alias",
            width:180,
            filter: true,
            resizable: true,
            editable:true,            
        },
        {
            headerName: "Assigned Menus",
            field: "menus",
            width:730,
            filter: true,
            resizable: true,
            editable:true,
        },
        {
            pinned:"left",
            headerName : "Edit",
            field:"_id",
            width:100,
            resizable : true,
            editable : false,
            cellRendererSelector:function(params){       
                var rendComponent = {
                    component: 'EditroleAction'
                };
                return rendComponent;
            },
        },
        {
            pinned:"left",
            headerName : "Delete",
            field:"delete",
            width:100,
            resizable : true,
            editable : false,
            cellRenderer:function(params){       
                return '<i class="icofont icofont-ui-delete"></i>';
            },
        },
    ]
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="card col-xl-12">
            <div className="card-header">
                <h5>
                    <i className="icofont icofont-site-map cus-i"></i> <span>Roles</span>
                    <button className="btn btn-success" style={{float:"right"}} onClick={this.showBulkUpload.bind(this)}>Add Role</button>
                    {/* <button className="btn btn-info" style={{float:"right",marginRight:"10px"}} onClick={this.showBulkUpload.bind(this)}>Import Data</button>
                    <button className="btn btn-danger" style={{float:"right",marginRight:"10px"}} onClick={this.onClickDataExport.bind(this)}>Export Data</button> */}
                </h5>
            </div>
            <div className="card-body col-xl-12 col-lg-12">
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.showGridData}>    
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClicked}
                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                    />
            </div>
            </div>
        </div>

        
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">Add Role</h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>
                    
                    <div className="form-group mt-20p">
                        <label className="">Role Name</label> 
                        <input type="text" name="role_name" id="role_name" className="form-control" required  />
                    </div>

                    <div className="form-group">
                        <label className="">Assign Menus</label>
                        
                        <Select 
                        placeholder={"Assign Menus"}
                        isMulti={true}
                        closeMenuOnSelect={true}
                        onChange={this.onChangeMenuItem.bind(this)} 
                        className={"border-radius-0"}
                        style={{borderRadius:"0px"}}
                        value={this.state.menuitem}
                        options={this.state.selectmenuoptins} />  
                    </div>

                    <div className="form-group">
                        <button type="submit" className="btn btn-success">Submit</button>
                        <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                    </div>
                    </form>                   
                </div>
            </div>
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
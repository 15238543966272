'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
// import Consignmentforceclose from '../m';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
//import CheckBox from "./checkboxselection";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';

import {verifyext} from "../common/verifyext";

// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class CarsPlanning extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
            //CheckBox:CheckBox,
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        // statusPanels: [
        //   {
        //   statusPanel: "agTotalAndFilteredRowCountComponent",
        //   align: "left"
        //   },
        //   {
        //   statusPanel: "agTotalRowCountComponent",
        //   align: "center"
        //   },
        //   { statusPanel: "agFilteredRowCountComponent" },
        //   { statusPanel: "agSelectedRowCountComponent" },
        //   { statusPanel: "agAggregationComponent" }
        // ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            // {
            // id: "columns",
            // labelDefault: "Columns",
            // labelKey: "columns",
            // iconKey: "columns",
            // toolPanel: "agColumnsToolPanel"
            // },
            // {
            // id: "filters",
            // labelDefault: "Filters",
            // labelKey: "filters",
            // iconKey: "filter",
            // toolPanel: "agFiltersToolPanel"
            // }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        loadFile : "",
        processErrMsg : "",
        processErrShow : "",
        transactionId : "",
        droppedList : [],
        statusCode : "",
        pinnedRegionBottomRowData:[],
        totalCars : 0,
        carDetails : [],
        excarDetails : [],
        columns : [],
        rowdefs : [],
        transportersList : [],
        lpList: [],
        selectedRows : [],
        loading_plant : "",
        pageType : 1,
        //components : {datepicker:getDatePicker()}
    }
    this.onLoadPageData = this.onLoadPageData.bind(this);
    this.changeFileHandler = this.changeFileHandler.bind(this);
    this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this)
  }

  logPageView = () => {
      try {
          if (googleAnalytics.page.enableGA) {
              googleAnalytics.logPageView();
              let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
              let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
              let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
              this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
          } else {
              console.log("GA disabled...");
          }
      } catch(error) {
          console.error("Error occured while logging to GA, error = ", error);
      }
  }  
      
async componentDidMount(){
    this.logPageView();
    var transactionId = "";
    var currentURL = window.location.pathname;
    currentURL = currentURL.split("/");
    currentURL = currentURL.pop();
    if(currentURL !="carsplanning")
    {
        transactionId = window.atob(currentURL);
    }
    console.log(currentURL,transactionId,"transactionId");
    await redirectURL.post("/rakeplanning/getRakeTransporters").then(async(response)=>{
        var transportersList = response.data;
        await this.setState({
            transportersList : transportersList,
        })
    }).catch((e)=>{
        console.log(e);
    })
    
    await redirectURL.post("/rakeplanning/getCarLoadingPlants").then(async(response)=>{
        var lpList = response.data;
        console.log(lpList,"lpList")
        await this.setState({
            lpList : lpList,
        })
        if(lpList.length > 0)
        {
            if(transactionId == "")
            {
                await this.setState({
                    loading_plant : lpList[0],
                })
                $("#loading_plant").val(lpList[0]);
                console.log(lpList[0],"respData[0].loading_plant");
                await this.onLoadPageData();
            }
        }
    }).catch((e)=>{
        console.log(e);
    })
    if(transactionId !='')
    {
        var query = {
            "transactionId" : transactionId
        }
        await redirectURL.post("/rakeplanning/getCarPlanRequestByTransactionId",query).then(async (response)=>{
            var respData = response.data;
            if(respData.length > 0)
            {
                if(respData[0].carDetails !=undefined && respData[0].carDetails !="")
                {
                    var carDetails = JSON.parse(respData[0].carDetails);
                    if(respData[0].loading_plant !=undefined && respData[0].loading_plant !='')
                    {
                        await this.setState({
                            loading_plant : respData[0].loading_plant,
                        })
                        console.log(respData[0].loading_plant,"respData[0].loading_plant");
                        $("#loading_plant").val(respData[0].loading_plant);
                        $("#loading_plant").attr("disabled",true);
                    }
                    if(respData[0].transporter_code !=undefined && respData[0].transporter_code !='')
                    {
                        $("#transporter_code").attr("disabled",true);
                        $("#rake_type").attr("disabled",true);
                        $("#transporter_code").val(respData[0].transporter_code);
                        if(respData[0].transporter_code == "APL")
                        {
                            $("#rake_type").val(respData[0].rake_type);
                            this.setState({
                                rakeDivShow : "show-m"
                            });
                        }
                        else
                        {
                            this.setState({
                                rakeDivShow : "show-n"
                            });
                        }
                    }
                    await this.setState({
                        excarDetails : carDetails,
                    })        
                    console.log(transactionId,"transactionId")
                    await this.setState({
                        transactionId : transactionId,
                    })
                    await this.onLoadPageData();
                }
            }
        });
    }
}

onLoadPageData(){
    var loading_plant = this.state.loading_plant;
    var params = {
        loading_plant : loading_plant
    }
    redirectURL.post("/rakeplanning/getLoadFactors",params).then(async (response)=>{
        //console.log(response.data);
        var respData = response.data;
        var chk = 0;
        if(this.state.transactionId !=undefined && this.state.transactionId !="")
        {
            var excarDetails = this.state.excarDetails;
            console.log(excarDetails,"excarDetails")
            if(excarDetails.length > 0)
            {
                chk = 1;
                respData.map(function(rd){
                    excarDetails.map(function(ed){
                        if(ed.car_model == rd.car_model)
                        {
                            rd.total_cars = parseInt(ed.total_cars);
                            rd.is_checked = 1;
                            rd.reco_restricted = ed.reco_restricted;
                        }
                    });
                })
                respData.map(function(rd1){
                    if(rd1.is_checked == undefined || rd1.is_checked != 1)
                    {
                        rd1.total_cars = 0;
                        rd1.reco_restricted = 0;
                    }
                })
                //console.log(respData,"respData")
            }
        }
        if(chk == 0)
        {
            respData.map(function(rd){
                rd.total_cars = 0;
                rd.reco_restricted = 0;
            })
        }
        var totalCars = 0;
        respData.map(function(rd){
            totalCars = parseInt(totalCars)+parseInt(rd.total_cars)
        })
        var pinnedBottomRowData = {}
        pinnedBottomRowData['car_model'] = "";
        pinnedBottomRowData['family_code'] = "Total";
        pinnedBottomRowData['total_cars'] = Math.round(totalCars);
        await this.setState({
            carDetails : respData,
            pinnedRegionBottomRowData : [pinnedBottomRowData],
            totalCars : totalCars
        })
        await this.updateGridData();
    }).catch((e)=>{
        console.log(e);
    })
}

updateGridData = e =>{
    this.gridApi.forEachNode(node => {
        console.log(node,"node")
        if(node.data.reco_restricted == 1){
            node.setSelected(true);
        }
    });
}

onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
    * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
            
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    this.setState({
        show: false
    });
    //window.location.href="/lmloadsummary";
}

onCellClicked(e)
{
    if(e.colDef.field == "container_no")
    {
        console.log(e.data.container_no);
    }
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    $("#uploadFile").val("");
    $("#notify_email").val("");
}
updateLmStates(params)
{
    //console.log(params);
    if(params.vehicle_cap !='' && params.vehicle_cap !=undefined)
    {
        redirectURL.post("/lmconsignees/savelmvehiclecapacity",params).then((response)=>{
            console.log(response);
            if(response.data.message == "Success")
            {
                this.setState({
                    basicTitle:"Updated Successfully.",
                    basicType : "success",
                    show : true
                })
                window.location.reload();
            }
            else
            {
                this.setState({
                    basicTitle:"Failed to update.",
                    basicType : "warning",
                    show : true
                })
            }
        })
    }
    else
    {
        this.setState({
            basicTitle:"Fields Should not be Empty.",
            basicType : "warning",
            show : true
        })
    }
}
addNewRow()
{
    // console.log(this.gridApi);
    this.gridApi.insertItemsAtIndex(0,[{vehicle_cap:"",min_capacity_cmt:"",max_capacity_cmt:"",min_courier:"",nodes_per_truck:"",allowed_distance:""}])
    // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}

showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate-40p"
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        loadFile:''
    });
    $("#uploadFile").val("");
    $("#notify_email").val("");
    //document.getElementById("upform").reset();
}
fixProcess = () => {
    this.setState({
        processErrMsg : "",
        processErrShow : 0,
        loadFile:''
    });
    $("#uploadFile").val("");
    $("#notify_email").val("");
    //document.getElementById("upform").reset();
}
ignoreProcessError = () => {
    this.setState({
        loadshow:'show-m',
        overly:'show-m',
    });
    var transactionId = this.state.transactionId;
    var loading_port_code = this.state.loading_port_code;
    console.log("transactionId",transactionId)
    var params = {
        transactionId : transactionId,
        loading_port_code : loading_port_code,
    }
    redirectURL.post("/lmconsignees/generateLoadByTransactionId",params).then((response)=>{
        //console.log(response.data,"response")
        var statusCode = response.data.status;
        if(statusCode == 0 || statusCode == 1)
        {
            this.setState({                    
                basicTitle : response.data.message,
                basicType : "success",
                show : true,
                processErrMsg : "",
                processErrShow : 0,
                loadshow:'show-n',
                overly:'show-n',
                bulkslide:"",
            });
        }
        else
        {
            this.setState({
                processErrMsg : response.data.message,
                processErrShow : 1,
                transactionId : response.data.transactionId,
                loadshow:'show-n',
                overly:'show-n',
            });
        }
        //window.location.reload();
    });
}

changeFileHandler = async (e) => {
    console.log(e);
    this.setState({
        loadFile : e.target.files[0]
    });  
    
    var check = verifyext(e.target.files[0].name);
    //console.log("Check ", check)
    if(check == true)
    {
        if(e.target.files[0].type == '' ||e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
        {
            // console.log(fileData);
            // console.log(typeof(fileData))
            
        }
        else{
            e.target.value = null;
            this.setState({
                uploadFile:'',
                show: true, 
                basicType:'danger', 
                basicTitle:'Please upload file having extensions .csv only.',
            });
        }
    }
    else{
        e.target.value = null;
        this.setState({
            uploadFile:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'Please upload file having extensions .csv only.',
        });
    }
    
}

changePlantHandler = async (e) => {
    var loading_plant = $("#loading_plant").val();
    await this.setState({
        loading_plant : loading_plant,
    })
    console.log(loading_plant,loading_plant)
    await this.onLoadPageData();
}

uploadBulkFormHandler(){
    var flag = 0;
    let saveData = new FormData();
    let clusterShares = [];
    let finalShares = [];
    this.gridApi.forEachNode(function(node){
        // var totalCars = parseInt(node.data.total_cars);
        // if(totalCars > 0)
        // {
        clusterShares.push(node.data)
        //}
    })
    // clusterShares.map(function(cst){
    //     var total_cars = parseInt(cst.total_cars);
    //     if(total_cars > 0)
    //     {
    //         finalShares.push(cst);
    //     }
    // });
    // console.log(finalShares.length,this.state.selectedRows.length)
    // this.state.selectedRows.map(function(sr){
    //     finalShares.push(sr)
    // });
    var selectedRows = this.state.selectedRows;
    clusterShares.map(function(cst){
        if(selectedRows.length == 0)
        {
            cst.reco_restricted = 0;
        }
        selectedRows.map(function(se){
            if(cst.car_model == se.car_model)
            {
                cst.reco_restricted = 1;
            }
            else
            {
                if(cst.reco_restricted !=1 || cst.reco_restricted == undefined)
                {
                    cst.reco_restricted = 0;
                }
            }
        })
    });
    console.log(clusterShares,"clusterShares")
    if(clusterShares.length > 0)
    {
        var fshares = uniqueArray2(clusterShares)
        console.log(fshares,"fshares")
        var transporter_code = $("#transporter_code").val();
        if(transporter_code == "IVC")
        {
            var rake_type = "new"
        }
        else if(transporter_code == "NYK")
        {
            var rake_type = "new"
        }
        else if(transporter_code == "JKTI")
        {
            var rake_type = "new"
        }
        else if(transporter_code == "OCPL")
        {
            var rake_type = "old"
        }
        else if(transporter_code == "ADANI")
        {
            var rake_type = "new"
        }
        else
        {
            var rake_type = $("#rake_type").val();
        }
        if(fshares.length > 0)
        {
            if(transporter_code !="" && rake_type !="")
            {
                var loading_plant = $("#loading_plant").val();
                saveData.append("clusterShares", JSON.stringify(fshares));
                saveData.append("transporter_code", transporter_code);
                saveData.append("rake_type", rake_type);
                saveData.append("loading_plant", loading_plant);
                saveData.append("user_name", localStorage.getItem('username'))
                saveData.append("email", localStorage.getItem('email'))
                saveData.append("user_role", localStorage.getItem('roles'))
                console.log(saveData,saveData);
                this.setState({
                    loadshow:'show-m',
                    overly:'show-m',
                });
                redirectURL.post("/rakeplanning/generateNewCarPlan",saveData).then((response)=>{
                    console.log(response,"responseFromProcess")
                    var statusCode = response.data.status;
                    if(statusCode == 8)
                    {
                        this.setState({
                            processErrMsg : response.data.message,
                            processErrShow : 1,
                            transactionId : response.data.transactionId,
                            droppedList : response.data.droppedList,
                            statusCode : statusCode,
                            loadshow:'show-n',
                            overly:'show-n',
                        });
                    }
                    else if(statusCode > 3 && statusCode != 8)
                    {
                        if(statusCode == 11)
                        {
                            var processMsg = "Use the standard input data file template for uploading loads";
                        }
                        else
                        {
                            var processMsg = response.data.message;
                        }
                        this.setState({
                            processErrMsg : response.data.message,
                            processErrShow : 1,
                            transactionId : response.data.transactionId,
                            statusCode : statusCode,
                            loadshow:'show-n',
                            overly:'show-n',
                        });
                    }
                    else
                    {
                        this.setState({                    
                            basicTitle : response.data.message,
                            basicType : "success",
                            show : true,
                            loadshow:'show-n',
                            overly:'show-n',
                            bulkslide:"",
                        });
                    }
                    window.location.href="/carsplansummary";
                });
            }
            else
            {
                this.setState({
                    basicTitle : "Select Transporter and Rake Type to Continue",
                    basicType : "warning",
                    show : true,
                })
            }
        }
        else
        {
            this.setState({
                basicTitle : "Something Went wrong, Please try again",
                basicType : "warning",
                show : true,
            })
        }
    }
    else
    {
        this.setState({
            basicTitle : "Please select Cars to Continue.",
            basicType : "warning",
            show : true,
        })
    }
};




onSelectBulkPlants= (location) =>{
    var plants=[];
    try{
        location.map(function(e){
            plants.push(e.value);
        })
        console.log(plants);
        this.setState({
            plant_name : plants
        });
    }
    catch(e)
    {
        console.log(e);
    }
    
}

onCellUpdateData = async(event) => {
    let clusterShares = [];
    this.gridApi.forEachNode(node => clusterShares.push(node.data));
    console.log(clusterShares,"clusterShares")
    var totalCars = 0;
    clusterShares.map(function(r,index){
        totalCars = parseInt(totalCars)+parseInt(r.total_cars)
    })
    var pinnedBottomRowData = {}
    pinnedBottomRowData['car_model'] = "";
    pinnedBottomRowData['family_code'] = "Total";
    pinnedBottomRowData['total_cars'] = Math.round(totalCars);
    this.setState({
        pinnedRegionBottomRowData : [pinnedBottomRowData],
    })
}
changeTransporterItem(){
    var transporter_code = $("#transporter_code").val();
    if(transporter_code == "APL")
    {
        this.setState({
            rakeDivShow : "show-m"
        });
    }
    else
    {
        this.setState({
            rakeDivShow : "show-n"
        });
    }
}


onRowSelection(event){
    var selectedRows=[];
    var rwCount = event.api.getSelectedNodes();
    rwCount.map((item) => {
        selectedRows.push(item.data)
    });
    console.log(selectedRows,"selectedRows")
    this.setState({
        selectedRows:selectedRows
    })
}
  render() {
    
    var columnDefs = [   
        // {
        //     pinned:"left",
        //     headerName : "Update",
        //     field:"_id",
        //     width:150,
        //     resizable : true,
        //     editable : false,
        //     cellRendererSelector:function(params){       
        //         var rendComponent = {
        //             component: 'CheckBox'
        //         };
        //         return rendComponent;
        //     },
        // }, 
        {
            headerName: "Car Name",
            field: "car_model",
            width:180,
            filter: true,
            resizable: true,
            editable:false,            
        },
        {
            headerName: "Family Code",
            field: "family_code",
            width:180,
            filter: true,
            resizable: true,
            editable:false,
            hide:false,
        },
        {
            headerName: "Total Cars",
            field: "total_cars",
            width:150,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: NumericCellEditor,
        },  
        {
            headerName: "Restrict For Recommendation",
            field: "",
            colId: "",
            width: 180,
            //pinned: 'left',
            filter: false,
            resizable: true,
            headerCheckboxSelection: false,
            //headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true
        },
    ]
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="card col-xl-12">
            {(this.state.carDetails.length > 0) ?
            <div class="card-header">
                <h5>
                    <i className="icofont icofont-car cus-i"></i> 
                    {(this.state.transactionId !=undefined && this.state.transactionId !='') ?
                    <span>Cars Configurator For Recommendations #{this.state.transactionId}</span>
                    :  
                    <span>Cars Configurator</span>}
                    <button className="btn btn-info" style={{float:"right",marginLeft:"2%"}} onClick={this.uploadBulkFormHandler.bind(this)}>Configure Cars</button>
                </h5>
            </div>
             : ""}
            <div className="card-body col-xl-12 col-lg-12">
                <div className="row col-sm-12" style={{padding:"0",marginBottom:"2%"}}>
                    <div className="col-sm-3">
                        <select className="form-control" name="transporter_code" id="transporter_code" onChange={(this.changeTransporterItem.bind(this))}>
                            <option value="">Select Transporter</option>
                            {this.state.transportersList.map(function(m){
                                return <option value={m.transporter_short_name}>{m.transporter_name}</option>
                            })}
                        </select>
                    </div>
                    <div className={"col-sm-3 "+this.state.rakeDivShow}>
                        <select className="form-control" name="rake_type" id="rake_type">
                            <option value="">Select Rake Type</option>
                            <option value="old">50mm Pitch</option>
                            <option value="new">25mm Pitch</option>
                        </select>
                    </div>
                    <div className={"col-sm-3"}>
                        <select className="form-control" name="loading_plant" id="loading_plant" onChange={this.changePlantHandler}>
                            <option value="">Select Loading Plant</option>
                            {this.state.lpList.map(function(m){
                                return <option value={m}>{m}</option>
                            })}
                        </select>
                    </div>
                </div>
                <div id="myGrid" style={{width:"100%",height:"460px"}} className={"ag-theme-balham "+this.state.showGridData}>    
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.carDetails}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    stopEditingWhenGridLosesFocus= {true}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={false}
                    pinnedBottomRowData={this.state.pinnedRegionBottomRowData}
                    gridOptions={{
                        context: { componentParent: this },
                        getRowStyle: function (params) {
                                if (params.node.rowPinned) {
                            return { 'font-weight': 'bold','font-size':'16px' };
                            }
                        },
                        suppressRowTransform: true,
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    //onCellClicked={this.onCellClicked}
                    onCellEditingStopped={this.onCellUpdateData.bind(this)}
                    rowSelection={this.state.rowSelection}
                    onRowSelected={this.onRowSelection.bind(this)}
                    suppressRowClickSelection={true}
                    />
            </div>
            </div>
        </div>

        
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">Imports Orders Data</h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>
                    <div className="form-group">
                        {(this.state.loading_port_code == "RPDCGUR") ?
                        <a className="btn btn-primary" href={require('../../assets/json/input_data_template_pick_ticket.csv')} target="_blank">Download Sample Template</a>
                        :
                        <a className="btn btn-primary" href={require('../../assets/json/input_data_template.csv')} target="_blank">Download Sample Template</a>                            
                        }
                    </div>
                    <div className="form-group mt-20p">
                        <label className="">Upload File</label> 
                        <input type="file" name="uploadFile" id="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
                    </div>                        
                    <div className="form-group mt-20p">
                        <label className="">Notify Email Users After Process (comma seperated)</label> 
                        <textarea name="notify_email" id="notify_email" className="form-control"></textarea>
                    </div>
                    <p style={{color:"red"}}>Note: Do not change the column names in template file</p>
                    <div className="form-group">
                        <button type="submit" className="btn btn-success">Generate Load</button>
                        <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                    </div>
                    </form>
                </div>
            </div>
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}


function uniqueArray2(arr) {
    var a = [];
    for (var i=0, l=arr.length; i<l; i++)
        if (a.indexOf(arr[i]) === -1 && arr[i] !== '')
            a.push(arr[i]);
    return a;
}
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';

import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
import Modal from 'react-responsive-modal';
 
import axios from 'axios';
import Autosuggest from 'react-autosuggest';
import TransporterIntegrationCounters from './transporterintegrationcounters';

import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import redirectURL from '../redirectURL';
import ComponentIndex from '../common/appendComponents';
import DateFormater from './dateComponent';
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM } from '../common/utils'; 
var moment = require('moment'); 
var googleAnalytics = require("../common/googleanalytics");
 
const ResponsiveReactGridLayout = WidthProvider(Responsive);

class TransporterIntegrationDashboardComponent extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			totalTrucks: null,
			uniqueActiveConsignments: null,

			totalActiveConsignments: null,
			gpsNotTodayActiveConsignments: null,
			totalActiveConsignmentsCount: 0,
			gpsNotTodayActiveConsignmentsCount: 0,
			currentDepartmentTitle: null,
			showdata : this.displayData,
			truckInfo:'',
			consignments:'',
			sndshow:'show-n',
			departmentchrtshow:'show-m',
			currenttrucklocation:'',
			truckoperates:0,
			truckgpslifetime:0,
			lspInfo:0,
			deptServes:0,
			transrecordserves:0,
			gpssendtoday:0,
			nopetransporters:0,
			gpstoday:0,
        	alldepts:[],
        	//deptvalue:'all',
        	deptvalue:null,
			sliderTranslate:'',
        	truckscnt:0,
        	trucklocations:0,
        	notrans:0,
        	trucktoday:0,
        	trucknogps:0,
            data: "grouped",
            legendPosition: "top-left",
            interpolate: "linear",
            xLabel: "M",
            yLabel: "GGGGGGGGGG",
            hidePoints: false,  
            hideLines: false,
            yMin: null,
            yMax: null,
            xMax: null,
            xMin: null,
            loadsData:[], 
            transportschart:[],
            transaxis:[],
			transyaxis:[],
			trans_no_gps_axis:[],
			trans_no_gps_yaxis:[],
            deptaxis:[],
            deptyaxis:[],
            lspaxis:[],
            lspyaxis:[],
            lsp_no_gps_axis:[],
            lsp_no_gps_yaxis:[],
            gpsintervalaxis:[],
            gpsintervalyaxis:[],
            modules: AllModules,
  	      open: false,
  	      filterstatess:{},
  	      columnsdatas:{},
  	      rowgroupdcols:[],
  	      valuecolumns:[],
  	      pivotmodeis:false,
  	      pivotcols:[],
  	      
  	      defaultColDef: {
  	        sortable: true,
			  filter: true,
			  resizable: true

			},
			frameworkComponents: {
			dateFormater:DateFormater
		},

  	      rowData: null,
  	      rowwithoutData: null,
			rowgpspacketData:null,
  	      rowModelhighchartoptionsType: 'enterprise',
  	      rowGroupPanelShow: 'always',
  	      animateRows: true,
  	      debug: true,
  	      showToolPanel: true,
  	      suppressAggFuncInHeader: true,
			statusBar: {
				statusPanels: [
				  {
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
				  },
				  {
					statusPanel: "agTotalRowCountComponent",
					align: "center"
				  },
				  { statusPanel: "agFilteredRowCountComponent" },
				  { statusPanel: "agSelectedRowCountComponent" },
				  { statusPanel: "agAggregationComponent" }
				]
			  },
			  sideBar: {
				toolPanels: [
				  {
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
				  },
				  {
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
				  }
				]
			  }    
        };
	}
	
    componentDidMount(){
		//googleAnalytics.initGA();
		if (googleAnalytics.page.enableGA) {
			googleAnalytics.logPageView();
		}
		// Active consignments are not applicable for LOG-SC
		//let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
		
		this.filterByDepartment();
    }
 onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;

	    
		  
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	
 };
 
onChartOptionsChanged(event) {
	  //console.log("where is this", event)
	    var chartOptions = event.chartOptions;
	    //savedLegendUserPreference = chartOptions.legend;
	   // savedUserPreferenceByChartType[event.chartType] = chartOptions;
}
onFirstDataRendered(params) {
    var createRangeChartParams = {
      cellRange: {
        columns: ["country", "sugar", "fat", "weight"]
      },
      chartContainer: document.querySelector("#myChart"),
      chartType: "bubble",
      suppressChartRanges: true
    };
   // currentChartRef = params.api.createRangeChart(createRangeChartParams);
}
  
 onGridState = () =>{
	 //console.log(this.gridApi);
	
	 /*Get  Current Columns State and Store in this.colState */
	   this.colState = this.gridColumnApi.getColumnState();

	 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
	   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

	 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
	  * User should enable the Pivot mode.*/
	   this.pivotColumns = this.gridColumnApi.getPivotColumns();
	 		   
	 /*Get Current Filter State and Store in window.filterState */
	   window.filterState = this.gridApi.getFilterModel();
	   this.gridApi.setFilterModel(window.filterState);
	    
	  
	   
 }

    onScatterHover(point) {     
        return `<b>Date: </b>${point.x}<br /><b>Value: </b>${point.y}`;
    }
    createSelectItems() {
        let items = [];         
        //console.log(this.state.alldepts);
        items.push(<option key='all' value='all'>All</option>);  
        this.state.alldepts.map((item, key) => {
        	//console.log("Here "+item,key);
        	items.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>);  
		});
        return items;
    } 
    handleChange(e) {
    	//console.log("V",e.target.value);
        this.setState({ deptvalue: e.target.value });
	  }
	  
	getCurrentDepartmentCode(pathToMatch = null) {
		let departmentCode = null;
		let departmentName = null;
		switch(pathToMatch) {
			case "/dashboard/snddefault":
				departmentCode='SNDG';
				departmentName = " (Sales and Dispatch) ";
				break;
			case "/dashboard/prtdefault":
				departmentCode='LOG-PRT';
				departmentName = " (Spare Parts) ";
				break;
			case "/dashboard/tnpdefault":
				departmentCode='LOG-TNP';
				departmentName = " (Train and Production) ";
				break;
			case "/dashboard/scdefault":
				departmentCode='LOG-SC';
				departmentName = " (Supply Chain) ";
				break;
			default:
				departmentName = " default ";
				console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			deptvalue:departmentCode,
			currentDepartmentTitle:departmentName,
		});
		return departmentCode;
	}
    filterByDepartment(){
        var currentDepartmentCode = JSON.parse(localStorage.getItem('dept_code'));
        console.log(localStorage.getItem("transportercode"))
        try{
            var transportercode = JSON.parse(localStorage.getItem("transportercode"));
        }
        catch(e){
            var transportercode = []
        }
        let layoutdata={
            deptcode:currentDepartmentCode,
            transportercode:transportercode
		}
		//console.log("layoutdata ",layoutdata)
    	/*Transport wise truck with gpsdata*/	
    	redirectURL.post('/overview/transporterWiseTrucks', layoutdata)
		  .then((response) => {
			 
		   var records = JSON.parse(JSON.stringify(response)).data;
		   //console.log(records);
		   var xaxis=[];
		   var seriesdata=[];
		   for(var rec in records)
		   {
			  // console.log(records[rec]);
			   xaxis.push(records[rec]._id);
			   seriesdata.push(parseInt(records[rec].count));
		   }
		   this.setState({
			   transportschart:records,
			   transaxis:xaxis,
			   transyaxis:seriesdata
		   });
		    
		  })
		  .catch(function (error) {
		    console.log(error);
		  });



		// Get active consignments for grid display
		let activeConsignmentsLayoutData = {
			deptcode:currentDepartmentCode,
            transportercode:transportercode
		}


		// Counters data for "Total Trucks" and "On Trip Trucks" under "Total Trucks"
		redirectURL.post('/dashboard/transportertrucks', activeConsignmentsLayoutData, {
			headers:{
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
			}
		})
		.then((response) => {
			let result = JSON.parse(JSON.stringify(response)).data;
			
			let totalTrucks = (result.uniqueTrucks) ? result.uniqueTrucks : [];
			let totalTrucksCount = (result.uniqueTrucks) ? result.uniqueTrucks.length : 0;

			let uniqueActiveConsignments = (result.uniqueActiveConsignments) ? result.uniqueActiveConsignments : [];
			let uniqueActiveConsignmentsCount = (result.uniqueActiveConsignments) ? result.uniqueActiveConsignments.length : 0;

			console.log("In totaltrucks, totaltrucks and count = ", result, totalTrucksCount);
			
			//totalActiveConsignmentsCount
			
			this.setState({
				totalTrucks: totalTrucks,
				truckscnt: totalTrucksCount,
				uniqueActiveConsignments: uniqueActiveConsignments,
				totalActiveConsignmentsCount: uniqueActiveConsignmentsCount
			});

		})
		.catch(function (error) {
			console.log(error);
		});
		if(currentDepartmentCode.indexOf('LOG-SC') >= 0)
		{
			//// Counter for "Trucks with GPS Data"
			redirectURL.post('/dashboard/transporterscdata', activeConsignmentsLayoutData)
			.then((response) => {
				console.log("Response SC ", response.data)
				var ontriptrucks = response.data[0].ontriptrucks
				var nogpslogsc = response.data[0].nogpslogsc
				this.setState({
					totalActiveConsignmentsCount:ontriptrucks.length,
					gpsNotTodayActiveConsignments: nogpslogsc,
					gpsNotTodayActiveConsignmentsCount:nogpslogsc.length
				})
			})
			.catch(function(e){

			})

		}
		else{

			// Counter for "GPS Data Not Reported Today -> On Trip Trucks"
			redirectURL.post('/dashboard/transportergpsDataNotReportedTodayOnTripTrucks', activeConsignmentsLayoutData, {
				headers:{
					"Access-Control-Allow-Origin": "*",
					"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
				}
			})
			.then((response) => {
				let result = JSON.parse(JSON.stringify(response)).data;
				console.log("In getGpsDataNotReportedTodayOnTripTrucks, result = ", result);
				let trucksWithGpsLocationNotToday = (result.trucksWithGpsLocationNotToday) ? result.trucksWithGpsLocationNotToday : [];
				let trucksWithGpsLocationNotTodayCount = (result.trucksWithGpsLocationNotToday) ? result.trucksWithGpsLocationNotToday.length : 0;
				
				this.setState({
					gpsNotTodayActiveConsignments: trucksWithGpsLocationNotToday,
					gpsNotTodayActiveConsignmentsCount: trucksWithGpsLocationNotTodayCount,
					
				});
			})
			.catch(function (error) {
				console.log(error);
			});
		}
		
		// Counter for "Trucks with GPS Data"
		redirectURL.post('/dashboard/transportertruckswithgpsdata', activeConsignmentsLayoutData, {
			headers:{
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
			}
		})
		.then((response) => {
			let result = JSON.parse(JSON.stringify(response)).data;
			console.log("In gettruckswithgpsdata, result = ", result);
			let trucksWithGpsLocation = (result.trucksWithGpsLocation) ? result.trucksWithGpsLocation : [];
			let trucksWithGpsLocationCount = (result.trucksWithGpsLocation) ? result.trucksWithGpsLocation.length : 0;

			let gpsDataNotReportedToday = trucksWithGpsLocation.filter(truck => {
				if (truck.timestamp) {
					let result = false;
					let consignmentDate = truck.timestamp;
					let consignmentDateOnly = consignmentDate.toString().slice(0, 10);
					let todayDateOnly = moment().format("YYYY-MM-DDT00:00:00.000Z").toString().slice(0, 10);
					if (consignmentDateOnly !== todayDateOnly) {
						result = true;
					} 
					return result;

				} else {
					//console.log("CONSIGNMENT WITHOUT TIMESTAMP = ", consignment);
				}
			});

			let gpsDataNotReportedTodayCount = (gpsDataNotReportedToday) ? gpsDataNotReportedToday.length : 0;
			let trucksWithGpsDataCount = (trucksWithGpsLocationCount && gpsDataNotReportedTodayCount) ? trucksWithGpsLocationCount - gpsDataNotReportedTodayCount : 0;
			
			
			// let gpsDataNotReportedTodayOnTripTrucks = [];
			// if (this.state.uniqueActiveConsignments && gpsDataNotReportedToday) {
			// 	gpsDataNotReportedTodayOnTripTrucks = this.state.uniqueActiveConsignments.filter(consignment => {
			// 		gpsDataNotReportedToday.forEach(item => {
			// 			return consignment['truck_no'] == item.truck_no;
			// 		})
					
			// 	})

			// }

			this.setState({
				trucklocations: trucksWithGpsDataCount,
				
			});

			// let gpsDataNotReportedToday = []; // for a while
			// this.setState({
			// 	totalActiveConsignments: records,
			// 	gpsNotTodayActiveConsignments: gpsDataNotReportedToday,
			// 	totalActiveConsignmentsCount: records.uniqueTrucks.length,
			// 	gpsNotTodayActiveConsignmentsCount: gpsDataNotReportedToday.length,
			// });
		})
		.catch(function (error) {
			console.log(error);
		});


    	/*Transport wise truck with gpsdata*/	
    	redirectURL.post('/overview/transporterWiseNoGPSTrucksChart', layoutdata)
		  .then((response) => {
			 
		   var records = JSON.parse(JSON.stringify(response)).data;
		   //console.log(records);
		   var xaxis=[];
		   var seriesdata=[];
		   for(var rec in records)
		   {
			  // console.log(records[rec]);
			   xaxis.push(records[rec]._id);
			   seriesdata.push(parseInt(records[rec].count));
		   }
		   this.setState({
			   transportschart:records,
               trans_no_gps_axis:xaxis,
               trans_no_gps_yaxis:seriesdata
		   });
		    
		  })
		  .catch(function (error) {
		    console.log(error);
		  });		  
  	  
		/*Department wise truck with gpsdata*/	
    	redirectURL.post('/overview/departmentWiseTrucksChart', layoutdata)
		  .then((response) => {
			 
		   var records = JSON.parse(JSON.stringify(response)).data;
		 //  console.log(records);
		   var dxaxis=[];
		   var dseriesdata=[];
		   for(var rec in records)
		   {
			   //console.log(records[rec]);
			   dxaxis.push(records[rec]._id);
			   dseriesdata.push(parseInt(records[rec].count));
		   }
		   this.setState({
			   deptaxis:dxaxis,
			   deptyaxis:dseriesdata
		   });
		    
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
		/*LSP wise truck with gpsdata*/	
    	redirectURL.post('/overview/lspWiseTruckChart', layoutdata)
  		  .then((response) => {
  			 
  		   var records = JSON.parse(JSON.stringify(response)).data;
  		   //console.log(records);
  		   var lxaxis=[];
  		   var lseriesdata=[];
  		   //console.log("lspchart",rec);
  		   for(var rec in records)
  		   {
  			   //console.log(records[rec]);
  			   lxaxis.push(records[rec]._id);
  			   lseriesdata.push(parseInt(records[rec].count));
  		   }
  		   this.setState({
  			   lspaxis:lxaxis,
  			   lspyaxis:lseriesdata
  		   });
  		    
  		  })
  		  .catch(function (error) {
  		    console.log(error);
			});
			
		/*LSP wise truck with gpsdata*/	
    	redirectURL.post('/overview/lspWiseNoGPSTruckChart', layoutdata)
  		  .then((response) => {
  			 
  		   var records = JSON.parse(JSON.stringify(response)).data;
  		   //console.log(records);
  		   var lxaxis=[];
  		   var lseriesdata=[];
  		   //console.log("lspchart",rec);
  		   for(var rec in records)
  		   {
  			   lxaxis.push(records[rec]._id);
  			   lseriesdata.push(parseInt(records[rec].count));
  		   }
  		   this.setState({
  			   lsp_no_gps_axis:lxaxis,
  			   lsp_no_gps_yaxis:lseriesdata
  		   });
  		    
  		  })
  		  .catch(function (error) {
  		    console.log(error);
  		  });			
  		  
			/*Counters Start*/
			/*
    	redirectURL.post('/overview/totalTrucksCount', layoutdata)
		  .then((response) => {
			 
		   var trucks = JSON.parse(JSON.stringify(response)).data.count;
		  // console.log("All Trucks ",trucks);
		   this.setState({
			   truckscnt:trucks
		   });
		   
		    
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  */
   	  
   	/*	
    	redirectURL.post('/overview/todaytruckdata', layoutdata)
 		  .then((response) => {
 			 
 		   var trucks = JSON.parse(JSON.stringify(response)).data.count;
 		   //console.log(trucks);
 		   this.setState({
 			 trucklocations:trucks
 		   });
 		   
 		    
 		  })
 		  .catch(function (error) {
 		    console.log(error);
 		  });
   	
*/
   		
    	redirectURL.post('/overview/trucksWithNoTransportersDataCount', layoutdata)
 		  .then((response) => {
 			 
 		   var trucks = JSON.parse(JSON.stringify(response)).data.count;
 		  // console.log("Truck GPS",trucks);
 		   this.setState({
 			 notrans:trucks
 		   });
 		   
 		    
 		  })
 		  .catch(function (error) {
 		    console.log(error);
 		  });	
   		
   		
           /*Truck with gpsdata*/	
        //   console.log("layoutdata ", layoutdata)
    	redirectURL.post('/overview/transportertruckWithGPSData', layoutdata)
		  .then((response) => {
			 
		   var records = response.data;
		   console.log(" /overview/truckWithGPSData, records = ", records);
		   this.setState({
			   rowData:records
		   });
		    
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
		  
    	redirectURL.post('/overview/transportertruckWithOutGPSData', layoutdata)
		  .then((response) => {
			 
		   var records = JSON.parse(JSON.stringify(response)).data;
		   // console.log("Truck No GPS",records);
		   this.setState({
			   rowwithoutData:records
		   });
		    
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  /*
    	redirectURL.post('/overview/trucksTodayDataCount', layoutdata)
 		  .then((response) => {
 			 
 		   var trucks = JSON.parse(JSON.stringify(response)).data.count;
 		 //  console.log("Filter", trucks);
 		   this.setState({
				trucklocations:trucks
 		   });
 		   
 		    
 		  })
 		  .catch(function (error) {
 		    console.log(error);
 		  });
		  */
	}
	
	/*Truck Sidebar Component Starts*/
    async onShowSidebarAquireComponent(e){  
		this.setState({
			sliderTranslate:""
		});
		//console.log(e);
		if(e.colDef.field == 'truck_no')
		{
			let ComponentInfo = ComponentIndex['truck'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata1 = {
				truck_no:e.data[e.colDef.field]
			};
			//console.log("layoutdata",layoutdata);
			await redirectURL.post('/masters/truckByNo', layoutdata1)
				.then((response) => {
					//console.log("trucks response", response);
					var truckrecords = JSON.parse(JSON.stringify(response)).data;
					
					this.setState({
						truckInfo:truckrecords
					});
				})
				.catch(function (error) {
					console.log(error);
				});

				/*Trucks details by Truck No*/
			await redirectURL.post('/masters/latestConsignmentsByTruckNo', layoutdata1)
				.then((response) => {
					//console.log(response);
					var consignrecords = JSON.parse(JSON.stringify(response)).data;
					
					this.setState({
						consignments:consignrecords
					});
				})
				.catch(function (error) {
					console.log(error);
				});
			
			/*Trucks details by Truck No*/
			await redirectURL.post('/masters/latestTruckLocation', layoutdata1)
				.then((response) => {
					//console.log("HR55W4880",response);
					var currenttrucklocation = JSON.parse(JSON.stringify(response)).data;
					
					this.setState({
						currenttrucklocation:currenttrucklocation
					});
				})
				.catch(function (error) {
					console.log(error);
				});
			//console.log("currenttrucklocation",this.state.currenttrucklocation);
			//setTimeout(function(){})
			this.displayData=await (<ComponentInfo context={this} truckNo={e.data[e.colDef.field]} truckInfo={this.state.truckInfo}  consignments={this.state.consignments} currenttrucklocation={this.state.currenttrucklocation}></ComponentInfo>);
					
			this.setState({
				showdata : this.displayData,
				sliderTranslate:"slider-translate"
			});
		}

		/*Transporters Sidebar*/
		
		if(e.colDef.field == 'transporter_name')
		{
			let ComponentInfo = ComponentIndex['transporter'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata1 = {
				transporter_name:e.data[e.colDef.field]
			};
			//console.log("layoutdata",layoutdata);
			await redirectURL.post('/masters/transporterByName', layoutdata1)
				.then((response) => {
					//console.log("transporterByName response", response);
					var transporterrecords = JSON.parse(JSON.stringify(response)).data;
					var deptcodes=[];
					var transportName='';
					var transportCode='';
					transporterrecords.map(item=>{
						transportName=item.transporter_name;
						transportCode=item.transporter_code;
						deptcodes.push(item.dept_code+", ");
					});
					//console.log(transportName+","+transportCode+","+deptcodes)
					var transportData = [{
						transporter_name:transportName,
						transporter_code:transportCode,
						dept_code:deptcodes
					}]
					this.setState({
						transporterInfo:transportData
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*Total Trucks Operating*/
			await redirectURL.post('/masters/totalTrucksOperating', layoutdata1)
			.then((response) => {
				//console.log("Total Trucks Operating response", response);
				this.setState({
					truckoperates:response
				});
			})
			.catch(function (error) {
				console.log(error);
			});

			/*Total Trucks With GPS Integration Lifetime*/
			await redirectURL.post('/masters/totalTrucksGPSLifetime', layoutdata1)
			.then((response) => {
				//console.log("Total Trucks Operating response", response);
				this.setState({
					truckgpslifetime:response
				});
			})
			.catch(function (error) {
				console.log(error);
			});
				
			/*Total Trucks With GPS Integration Today*/
			await redirectURL.post('/masters/totalTrucksGPSToday', layoutdata1)
			.then((response) => {
				//console.log("Total Trucks Operating response", response);
				this.setState({
					gpstoday:response
				});
			})
			.catch(function (error) {
				console.log(error);
			});

				/*Transporters Consignment details*/
			await redirectURL.post('/masters/latestConsignmentsByTransporterName', layoutdata1)
				.then((response) => {
					//console.log(response);
					var consignrecords = JSON.parse(JSON.stringify(response)).data;
					var consignrecordslist=[];
					consignrecords.map(item=>{
						if(item.consigns.length > 0)
						{
							item.consigns.map(cons => {
								consignrecordslist.push(cons)
							})
							
						}
					});
					
					//console.log("consignrecords list", consignrecordslist);
					this.setState({
						consignments:consignrecordslist
					});
				})
				.catch(function (error) {
					console.log(error);
				});
			
			
			this.displayData=await (<ComponentInfo context={this} gpstoday={this.state.gpstoday} gpsLifetime={this.state.truckgpslifetime} transportName={e.data[e.colDef.field]} transporterInfo={this.state.transporterInfo} totalTruckOperating={this.state.truckoperates} consignments={this.state.consignments} ></ComponentInfo>);
					
			this.setState({
				showdata : this.displayData,
				sliderTranslate:"slider-translate"
			});
		}
		/*End*/
		
		/*LSP Sidebar*/
		
		if(e.colDef.field == 'actual_lspuser')
		{
			let ComponentInfo = ComponentIndex['lsp'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata2 = {
				actual_lspuser:e.data[e.colDef.field]
			};
			console.log("layoutdata2", layoutdata2);
			//Total Trucks Integrate;
			await redirectURL.post('/masters/totalTruckIntegrate', layoutdata2)
				.then((response) => {
					var lsprecords = JSON.parse(JSON.stringify(response)).data.count;
					//console.log("LSP response serveeeee", lsprecords);
					
					var result = lsprecords.reduce(function(sum, item){
						return sum = sum+item.count;
					},0);
						//console.log("result", result);
					this.setState({
						lspInfo:result
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*Department Serving*/
			await redirectURL.post('/masters/totalDeptServe', layoutdata2)
			.then((response) => {
				var deprecords = JSON.parse(JSON.stringify(response)).data;
				//console.log("LSP DeptServe response", deprecords);
				
				var deptcnt=[];
				deprecords.map(item => {
					deptcnt.push(item._id)
				});
				this.setState({
					deptServes:deptcnt.length
				});
			})
			.catch(function (error) {
				console.log(error);
			});

			/*No Of Transporters Serving*/
			await redirectURL.post('/masters/totalTransporterServe', layoutdata2)
			.then((response) => {
				var transrecords = JSON.parse(JSON.stringify(response)).data;
				//console.log("Transporter serve response", transrecords);
				var trasportcnt=[];
				transrecords.map(item => {
					trasportcnt.push(item._id)
				});
				this.setState({
					transrecordserves:trasportcnt.length
				});
			})
			.catch(function (error) {
				console.log(error);
			});

			/*No Of GPS DATA Send Today*/
			await redirectURL.post('/masters/totalGPSSendToday', layoutdata2)
			.then((response) => {
				var gpssendrecords = JSON.parse(JSON.stringify(response)).data;
				//console.log("GPS Send Data response", gpssendrecords);
				
				this.setState({
					gpssendtoday:gpssendrecords.length
				});
			})
			.catch(function (error) {
				console.log(error);
			});

			/*Total Trucks with no transporter data*/
			await redirectURL.post('/masters/trucksWithNoTransporter', layoutdata2)
			.then((response) => {
				var notransrecords = JSON.parse(JSON.stringify(response)).data;
				//console.log("No Trasnporter Data response", notransrecords);
				
				this.setState({
					nopetransporters:notransrecords.length
				});
			})
			.catch(function (error) {
				console.log(error);
			});
			
			this.displayData=await (<ComponentInfo context={this} nopetransporters={this.state.nopetransporters} gpssendtoday={this.state.gpssendtoday} transportersServes={this.state.transrecordserves} deptServes={this.state.deptServes} lspName={e.data[e.colDef.field]} lspInfo={this.state.lspInfo} ></ComponentInfo>);
					
			this.setState({
				showdata : this.displayData,
				sliderTranslate:"slider-translate"
			});
		}
		/*End*/
	} 
	onGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		//this.gridApi.sizeColumnsToFit();
	}
	/*Truck Sidebar Component End*/
	onCloseUploadDiv = () => {
		this.setState({
			sliderTranslate:"",
		});
	}
    render(){

		const statedeptcode = this.state.deptvalue;
		//console.log("statedeptcode ",statedeptcode)
		var columnwithDefs= [
			{
			  headerName: "Truck No",
			  field: "truck_no",
			  width: 200,
			  
			  
			},
			{
			  headerName: "LSP Code",
			  field: "transporter_code",
			  width: 200,
				  
		  },  	        
		  {
			headerName: "LSP Name",
			field: "transporter_name",
			width: 200,
				
		  },
			{
			headerName: "Department Code",
			field: "dept_code",
			width: 200,
				
		  },
			{
			headerName: "Actual GPS Provider",
			field: "actual_lspuser",
			width: 200,
				
		  },
		  {
			  headerName: "Last Known City/Town",
			  field: "area",
			  width: 200,
		  },
		  {
			headerName: "Last Known State",
			field: "state",
			width: 200,
		  },
			{
			  headerName: "Last Packet Time",
			  field: "timestamp",
			  width: 200,
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
			  return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
			  },
			  // filter: "agDateColumnFilter",
			  comparator: dateComparator,
			  //   filterParams: {
			// 	  browserDatePicker: true,
			// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
			// 	  // provide comparator function
			// 	  comparator: function(filteredDate,cellValue,secondDate) {
			// 		  if(cellValue != "" && cellValue != " "){
			// 			  cellValue = cellValue.split(" ")[0].split("-");
			// 			  cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
			// 			  var cellDate = new Date(cellValue);
			// 			  if(filteredDate.getMonth() < 10){
			// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  else{
			// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  if(filterValue.split("-")[2].length < 2)
			// 			  {
			// 				  var temp = filterValue;
			// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
			// 			  }
			// 			  var filteredDate = new Date(filterValue);
			// 			  cellDate = cellDate.getTime();
			// 			  filteredDate = filteredDate.getTime();
			// 			  // console.log(cellDate,filteredDate);
			// 			  if(cellDate === filteredDate)
			// 			  {
			// 				  return 0;
			// 			  }
			// 			  if(cellDate < filteredDate){
			// 				  return -1;
			// 			  }
			// 			  if(cellDate > filteredDate)
			// 			  {
			// 				  return 1;
			// 			  }
			// 		  }
			// 	  }
			//   },
				
		  },
		  {
			  headerName: "Data Received On",
			  field: "modified_date",
			  width: 200,
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
				  return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
			  },
			  // filter: "agDateColumnFilter",
			  comparator: dateComparator,
			  //   filterParams: {
			// 	  browserDatePicker: true,
			// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
			// 	  // provide comparator function
			// 	  comparator: function(filteredDate,cellValue,secondDate) {
			// 		  if(cellValue != "" && cellValue != " "){
			// 			  cellValue = cellValue.split(" ")[0].split("-");
			// 			  cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
			// 			  var cellDate = new Date(cellValue);
			// 			  if(filteredDate.getMonth() < 10){
			// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  else{
			// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  if(filterValue.split("-")[2].length < 2)
			// 			  {
			// 				  var temp = filterValue;
			// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
			// 			  }
			// 			  var filteredDate = new Date(filterValue);
			// 			  cellDate = cellDate.getTime();
			// 			  filteredDate = filteredDate.getTime();
			// 			  // console.log(cellDate,filteredDate);
			// 			  if(cellDate === filteredDate)
			// 			  {
			// 				  return 0;
			// 			  }
			// 			  if(cellDate < filteredDate){
			// 				  return -1;
			// 			  }
			// 			  if(cellDate > filteredDate)
			// 			  {
			// 				  return 1;
			// 			  }
			// 		  }
			// 	  }
			//   },
		  }
			 
			 
		  ];
		var columnwithoutDefs = [
			{
			  headerName: "Truck No",
			  field: "truck_no",
			  width: 200,
			  
		  },
			{
			  headerName: "LSP Code",
			  field: "transporter_code",
			  width: 200,
				  
		  },  				  
			{
			headerName: "LSP Name",
			field: "transporter_name",
			width: 200,
				
		  },
			{
			headerName: "Department Code",
			field: "dept_code",
			width: 200,
				
		  },
		  {
			headerName: "Actual LSP Provider",
			field: "actual_lspuser",
			width: 200,
				
		  },
		  {
			  headerName: "Last Known City/Town",
			  field: "area",
			  width: 200,
		  },
		  {
			headerName: "Last Known State",
			field: "state",
			width: 200,
		  },
		  {
			  headerName: "Last Packet Time",
			  field: "timestamp",
			  width: 200,
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
			  return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
			  },
			  // filter: "agDateColumnFilter",
			  comparator: dateComparator,
			  //   filterParams: {
			// 	  browserDatePicker: true,
			// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
			// 	  // provide comparator function
			// 	  comparator: function(filteredDate,cellValue,secondDate) {
			// 		  if(cellValue != "" && cellValue != " "){
			// 			  cellValue = cellValue.split(" ")[0].split("-");
			// 			  cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
			// 			  var cellDate = new Date(cellValue);
			// 			  if(filteredDate.getMonth() < 10){
			// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  else{
			// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  if(filterValue.split("-")[2].length < 2)
			// 			  {
			// 				  var temp = filterValue;
			// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
			// 			  }
			// 			  var filteredDate = new Date(filterValue);
			// 			  cellDate = cellDate.getTime();
			// 			  filteredDate = filteredDate.getTime();
			// 			  console.log(cellValue,filterValue);
			// 			  console.log(cellDate,filteredDate);
			// 			  if(cellDate === filteredDate)
			// 			  {
			// 				  return 0;
			// 			  }
			// 			  if(cellDate < filteredDate){
			// 				  return -1;
			// 			  }
			// 			  if(cellDate > filteredDate)
			// 			  {
			// 				  return 1;
			// 			  }
			// 		  }
			// 	  }
			//   },
				
		  },
		  {
			  headerName: "Data Received On",
			  field: "modified_date",
			  width: 200,
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
				  return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
			  },
			  // filter: "agDateColumnFilter",
			  comparator: dateComparator,
			  //   filterParams: {
			// 	  browserDatePicker: true,
			// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
			// 	  // provide comparator function
			// 	  comparator: function(filteredDate,cellValue,secondDate) {
			// 		  if(cellValue != "" && cellValue != " "){
			// 			  cellValue = cellValue.split(" ")[0].split("-");
			// 			  cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
			// 			  var cellDate = new Date(cellValue);
			// 			  if(filteredDate.getMonth() < 10){
			// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  else{
			// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  if(filterValue.split("-")[2].length < 2)
			// 			  {
			// 				  var temp = filterValue;
			// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
			// 			  }
			// 			  var filteredDate = new Date(filterValue);
			// 			  cellDate = cellDate.getTime();
			// 			  filteredDate = filteredDate.getTime();
			// 			  // console.log(cellDate,filteredDate);
			// 			  if(cellDate === filteredDate)
			// 			  {
			// 				  return 0;
			// 			  }
			// 			  if(cellDate < filteredDate){
			// 				  return -1;
			// 			  }
			// 			  if(cellDate > filteredDate)
			// 			  {
			// 				  return 1;
			// 			  }
			// 		  }
			// 	  }
			//   },
		  }
		  
	  ];
		var columnGpsNotTodayDefs = [
			{
			  headerName: "Truck No",
			  field: "truck_no",
			  width: 200,
			  
		  },
			{
			  headerName: "LSP Code",
			  field: "transporter_code",
			  width: 200,
				  
		  },  				  
			{
			  headerName: "LSP Name",
			  field: "transporter_name",
			  width: 200,
			  valueGetter:function(params) {
				  let transporter_name = null;
				  if (params.data.transporter) {
					  if (params.data.transporter.transporter_name) {
						  transporter_name = params.data.transporter.transporter_name;
					  }
				  }
				  return transporter_name;
			  }
		  },
			{
			headerName: "Department Code",
			field: "dept_code",
			width: 200,
				
		  },
		  {
			  headerName: "Actual LSP Provider",
			  field: "actual_lspuser",
			  width: 200,
			  valueGetter:function(params){
				  if(params.data.dept_code == 'LOG-SC')
				  {
					return params.data.actual_lspuser;
				  }
				  else
				  {
					let actual_lspuser = null;
					if (params.data.truck) {
						if (params.data.truck.actual_lspuser) {
							actual_lspuser = params.data.truck.actual_lspuser;
						}
					}
					return actual_lspuser;
				  }
				 
			  }
		  },
		  {
			headerName: "Last Known City/Town",
			field: "area",
			width: 200,
			valueGetter:function(params){
				if(params.data.dept_code == 'LOG-SC')
				{
					return params.data.area;
				}
				else
				{
					let area = null;
					if (params.data.truck) {
						if (params.data.truck.area) {
							area = params.data.truck.area;
						}
					}
					return area;
				}	
			}
		  },
		  {
			  headerName: "Last Known State",
			  field: "state",
			  width: 200,
			  valueGetter:function(params){
				if(params.data.dept_code == 'LOG-SC')
				{
					return params.data.state;
				}
				else
				{
					let state = null;
					if (params.data.truck) {
						if (params.data.truck.state) {
							state = params.data.truck.state;
						}
					}
					return state;
				}
			  }
		  },
		  // {
		  //   headerName: "Last Packet Time",
		  //   field: "timestamp",
		  //   width: 200,
		  //   //cellRenderer:'dateFormater'
		  //   valueGetter:function(params){
		  // 	return getDDMMYYYYHHMMSS(params.data.timestamp)
		  //   }
		  // },
		  {
			  headerName: "Last Packet Time",
			  field: "timestamp",
			  width: 200,
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
				if(params.data.dept_code == 'LOG-SC')
				{
					return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
				}
				else
				{
				  return getHyphenDDMMMYYYYHHMM(params.data.truck.timestamp)
				}
			  },
			  // filter: "agDateColumnFilter",
			  comparator: dateComparator,
			  //   filterParams: {
			// 	  browserDatePicker: true,
			// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
			// 	  // provide comparator function
			// 	  comparator: function(filteredDate,cellValue,secondDate) {
			// 		  if(cellValue != "" && cellValue != " "){
			// 			  cellValue = cellValue.split(" ")[0].split("-");
			// 			  cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
			// 			  var cellDate = new Date(cellValue);
			// 			  if(filteredDate.getMonth() < 10){
			// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  else{
			// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  if(filterValue.split("-")[2].length < 2)
			// 			  {
			// 				  var temp = filterValue;
			// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
			// 			  }
			// 			  var filteredDate = new Date(filterValue);
			// 			  cellDate = cellDate.getTime();
			// 			  filteredDate = filteredDate.getTime();
			// 			  // console.log(cellDate,filteredDate);
			// 			  if(cellDate === filteredDate)
			// 			  {
			// 				  return 0;
			// 			  }
			// 			  if(cellDate < filteredDate){
			// 				  return -1;
			// 			  }
			// 			  if(cellDate > filteredDate)
			// 			  {
			// 				  return 1;
			// 			  }
			// 		  }
			// 	  }
			//   },
				
		  },
		  {
			  headerName: "Data Received On",
			  field: "modified_date",
			  width: 200,
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
				if(params.data.dept_code == 'LOG-SC')
				{
					return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
				}
				else
				{
				  return getHyphenDDMMMYYYYHHMM(params.data.truck.modified_date)
				}
			  },
			  // filter: "agDateColumnFilter",
			  comparator: dateComparator,
			  //   filterParams: {
			// 	  browserDatePicker: true,
			// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
			// 	  // provide comparator function
			// 	  comparator: function(filteredDate,cellValue,secondDate) {
			// 		  if(cellValue != "" && cellValue != " "){
			// 			  cellValue = cellValue.split(" ")[0].split("-");
			// 			  cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
			// 			  var cellDate = new Date(cellValue);
			// 			  if(filteredDate.getMonth() < 10){
			// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  else{
			// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  if(filterValue.split("-")[2].length < 2)
			// 			  {
			// 				  var temp = filterValue;
			// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
			// 			  }
			// 			  var filteredDate = new Date(filterValue);
			// 			  cellDate = cellDate.getTime();
			// 			  filteredDate = filteredDate.getTime();
			// 			  // console.log(cellDate,filteredDate);
			// 			  if(cellDate === filteredDate)
			// 			  {
			// 				  return 0;
			// 			  }
			// 			  if(cellDate < filteredDate){
			// 				  return -1;
			// 			  }
			// 			  if(cellDate > filteredDate)
			// 			  {
			// 				  return 1;
			// 			  }
			// 		  }
			// 	  }
			//   },
		  },
		  // {
		  // 	headerName: "Data Received On",
		  // 	field: "modified_date",
		  // 	width: 200,
		  // 	//cellRenderer:'dateFormater'
		  // 	valueGetter:function(params){
		  // 		return getDDMMYYYYHHMMSS(params.data.modified_date)
		  // 	  }
		  //   }
		  
		  ];
		  var columngpspacketDefs =  [
			  {
				headerName: "GPS Provider",
				field: "_id",
				width: 200,
				
				
			  },
			  {
				headerName: "< 3 Minutes",
				field: "less_180",
				width: 200,
					
			},  	        
			{
			  headerName: "Between 3 to 5 Minutes",
			  field: "180_300",
			  width: 200,
				  
			},  	        
			{
			  headerName: "Between 5 to 10 Minutes",
			  field: "300_600",
			  width: 200,
				  
			},  	        
			{
			  headerName: "Between 10 to 15 Minutes",
			  field: "600_900",
			  width: 200,
				  
			},  	        
			{
			  headerName: "More than 15 Minutes",
			  field: "greater_900",
			  width: 120,
				  
			}
			   
		  ];


    	let order_cnt=[];

    	rendJsonData(this.state.loadsData);
		
        //console.log("Default",window.atob(localStorage.getItem('_t')));
        const dStyles={
				width:'100%',
				height:'100%'
		}
		const highchartoptions = {
	      		  chart: { 
	      		    type: 'column'
	      		  },
	      		  plotOptions: {
		      	      column: {
		      	          colorByPoint: true
		      	      },
		      	      series: {
		      	    	maxPointWidth: 20
		              }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
					  categories: this.state.transaxis
				   },
				   plotOptions: {
					column: {
						stacking: 'normal',
						dataLabels: {
							enabled: false
						},
						colorByPoint:true

					},
					},
	      		  series: [
	      		    {
					  name:"With GPS",
	      		      data: this.state.transyaxis
					  }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: false
	                        }
	                    }
	                }]
	      		  }
				  };
				  
		const transporterNoGPSChart = {
	      		  chart: { 
	      		    type: 'column'
	      		  },
	      		  plotOptions: {
		      	      column: {
		      	          colorByPoint: true
		      	      },
		      	      series: {
		      	    	maxPointWidth: 20
		              }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
					  categories: this.state.trans_no_gps_axis
				   },
				   plotOptions: {
					column: {
						stacking: 'normal',
						dataLabels: {
							enabled: false
						},
						colorByPoint:true

					},
					},
	      		  series: [
	      		    {
					  name:"Without GPS",
	      		      data: this.state.trans_no_gps_yaxis
					  }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: false
	                        }
	                    }
	                }]
	      		  }
				  };

        const lsphighchartoptions = {
	      		  chart: {
	      		    type: 'column'
	      		  },
	      		  plotOptions: {
		      	      column: {
		      	          colorByPoint: true
		      	      },
		      	      series: {
		      	    	maxPointWidth: 20
		              }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
	      	        categories: this.state.lspaxis
				   },
	      		  series: [
	      		    {
	      		      data: this.state.lspyaxis
	      		     //data: [64,21,33,42,52]
	      		    }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: false
	                        }
	                    }
	                }]
	      		  }
				  };
				  
		const lspNoGPShighchartoptions = {
	      		  chart: {
	      		    type: 'column'
	      		  },
	      		  plotOptions: {
		      	      column: {
		      	          colorByPoint: true
		      	      },
		      	      series: {
		      	    	maxPointWidth: 20
		              }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
	      	        categories: this.state.lsp_no_gps_axis
				   },
	      		  series: [
	      		    {
	      		      data: this.state.lsp_no_gps_yaxis
	      		     //data: [64,21,33,42,52]
	      		    }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: false
	                        }
	                    }
	                }]
	      		  }
				  };
				  				  
        const highchartoptions1 = {
	      		  chart: {
	      		    type: 'pie'
	      		  },
	      		  plotOptions: {
	      			pie: {
	    		        allowPointSelect: true,
	    		        cursor: 'pointer',
	    		        showInLegend: true,
	    		        dataLabels: {
	    		          enabled: true,
	    		          format: '{point.x:,.0f}'			    		          
	    		        }
	    		      }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
	      	        categories: this.state.deptaxis
	      	     },
	      	     legend: {
		    		  enabled: true,
		    		  labelFormatter: function() {

							//console.log("test",this.series)

			    		    var legendIndex = this.index;
			    		    var legendName = this.series.chart.axes[0].categories[legendIndex];

			    		    return legendName;
			    		  }
		    		},
	      		  series: [
	      		    {
	      		      data: this.state.deptyaxis
	      		     //data: [64,21,33,42,52]
	      		    }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: true
	                        }
	                    }
	                }]
	      		  }
	      		};
        			  
			const highchartgpsinterval = {
				chart: {
					type: 'column'
				},
				title: {
					text: ' '
				},
				xAxis: {
					categories: this.state.gpsintervalaxis
				},
				yAxis: {
					min: 0,
					
					stackLabels: {
						enabled: true,
						style: {
							fontWeight: 'bold',
							color: ( // theme
								Highcharts.defaultOptions.title.style &&
								Highcharts.defaultOptions.title.style.color
							) || 'gray'
						}
					}
				},
				legend: {
					align: 'right',
					x: -30,
					verticalAlign: 'top',
					y: 25,
					floating: true,
					backgroundColor:
						Highcharts.defaultOptions.legend.backgroundColor || 'white',
					borderColor: '#CCC',
					borderWidth: 1,
					shadow: false
				},
				tooltip: {
					headerFormat: '<b>{point.x}</b><br/>',
					pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
				},
				plotOptions: {
					column: {
						stacking: 'normal',
						dataLabels: {
							enabled: true
						}
					}
				},
				series: this.state.gpsintervalyaxis
			};
        return (
            <div >
                {/*Container-fluid starts*/} 
                <div className="container-fluid">
                
                	<div style={{marginBottom:'10px'}} className="row">
					{(this.state.currentDepartmentTitle == 'default')?
					
						<div className="col-xl-12 col-lg-12">
							<div className="card">
							
								<div className="card-header">
									<h5>
										<span>Dashboard  </span>
									</h5>
								</div>

							</div>	
						</div>
						:""}

                		{/* <a style={{color:'#333'}} href="javascript:void(0)" className="filernow_">
                			<img style={{marginTop:'10px',float:"left",marginLeft:"5px"}} src={require("../../../assets/icons/funnel.png")} className="filernow_" /> 
                		    <span style={{marginTop:'8px',marginLeft:"5px",float:"left"}}>Filtering</span>
                	    </a>
                		<div className="col-xl-5 col-lg-5 col-sm-5 row">
                			<div className="col-xl-8 col-lg-8 col-sm-8" style={{paddingRight:'0px'}}>
                				<select refs="deptwise" className="form-control" onChange={this.handleChange.bind(this)}>
                					{this.createSelectItems()}
                				</select>
                			</div>
                			<div className="col-xl-2 col-lg-2 col-sm-2" style={{padding:"0"}}>
                				<button name="go" id="go"style={{height:"38px"}} className="btn btn-primary" onClick={this.filterByDepartment.bind(this)}>Go</button>
                		
                			</div>
                		</div> */}
                	</div>

					
                {/*
                	<div className="flitrblock shwfilt" id="filterblock">
                	</div>
                */}

                    {/* 
					<BoxRowOne tottrucks={this.state.truckscnt} truckgps={this.state.trucklocations} notruckgps={this.state.notrans} truckno={this.state.trucknogps} />
					 */} 
					<TransporterIntegrationCounters 
					  tottrucks={this.state.truckscnt} 
					  truckgps={this.state.trucklocations} 
					  notruckgps={this.state.notrans} 
					  totalActiveConsignmentsCount={this.state.totalActiveConsignmentsCount} 
					  gpsNotTodayActiveConsignmentsCount={this.state.gpsNotTodayActiveConsignmentsCount} 
					  truckno={this.state.trucknogps} />
                    <div className="row">
						<div className="col-xl-6 col-lg-12">
	                        <div className="card height-equal equal-height-lg">
	                            <div className="card-header" style={{padding:'10px'}}>
	                                <h5>Trucks with GPS Data</h5>
	                            </div>
	                            <div className="card-body" style={{padding:'10px'}}>
	                                <div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
	    		          		          <AgGridReact
											context={this}
											onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
	    		          		            modules={this.state.modules}
	    		          		            columnDefs={columnwithDefs}
	    		          		            defaultColDef={this.state.defaultColDef}
	    		          		            rowData={this.state.rowData}
	    		          		            enableCharts={false}
	    		          		            enableRangeSelection={true}
	    		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
	    		          		            frameworkComponents={this.state.frameworkComponents}
											onGridReady={this.onGridReady}
	    		          		            onGridState={this.onGridState}
											  statusBar={this.state.statusBar}
											  //sideBar={this.state.sideBar}
											  pagination={true}
	    		          		          />
	    		          		        </div>
	                            </div>
	                            
	                        </div>
	                        
	                    </div>

	                    
	                    

                        <div className="col-xl-6 col-lg-12">
	                        <div className="card height-equal equal-height-lg">
	                            <div className="card-header" style={{padding:'10px'}}>
	                                <h5>GPS Data Not Reported Today</h5>
	                            </div>
	                            <div className="card-body" style={{padding:'10px'}}>
	                                <div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
	    		          		          <AgGridReact
	    		          		            modules={this.state.modules}
											onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
	    		          		            columnDefs={columnwithoutDefs}
	    		          		            defaultColDef={this.state.defaultColDef}
	    		          		            rowData={this.state.rowwithoutData}
	    		          		            enableCharts={false}
	    		          		            enableRangeSelection={true}
	    		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
	    		          		            frameworkComponents={this.state.frameworkComponents}
											onGridReady={this.onGridReady}
	    		          		            onGridState={this.onGridState}
											  statusBar={this.state.statusBar}
											  //sideBar={this.state.sideBar}
											  pagination={true}
	    		          		          />
	    		          		        </div>
	                            </div>
	                            
	                        </div>
	                        
	                    </div>



                        <div className="col-xl-6 col-lg-12">
	                        <div className="card height-equal equal-height-lg">
	                            <div className="card-header" style={{padding:'10px'}}>
	                                <h5>GPS Data Not Reported Today (On Trip Trucks)</h5>
	                            </div>
	                            <div className="card-body" style={{padding:'10px'}}>
	                                <div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
	    		          		          <AgGridReact
	    		          		            modules={this.state.modules}
											onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
	    		          		            columnDefs={columnGpsNotTodayDefs}
	    		          		            defaultColDef={this.state.defaultColDef}
	    		          		            rowData={this.state.gpsNotTodayActiveConsignments}
	    		          		            enableCharts={false}
	    		          		            enableRangeSelection={true}
	    		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
	    		          		            frameworkComponents={this.state.frameworkComponents}
											onGridReady={this.onGridReady}
	    		          		            onGridState={this.onGridState}
											  statusBar={this.state.statusBar}
											  //sideBar={this.state.sideBar}
											  pagination={true}
	    		          		          />
	    		          		        </div>
	                            </div>
	                            
	                        </div>
	                        
	                    </div>



						{/* <div className="col-xl-12 col-lg-12 hidden">
                            <div className="card">
                                <div className="card-header">
                                    <h5>GPS Data Time Interval (Between Packets)</h5>
                                </div>
                                <div className="card-body">
                                    <div className="dashboard-chart-container sales-overview-chart">
										<div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
											<AgGridReact
												context={this}
												onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
												modules={this.state.modules}
												columnDefs={this.state.columngpspacketDefs}
												defaultColDef={this.state.defaultColDef}
												rowData={this.state.rowgpspacketData}
												enableCharts={false}
												enableRangeSelection={false}
												autoGroupColumnDef={this.state.autoGroupColumnDef}
												onGridReady={this.onGridReady}
												onGridState={this.onGridState}
												statusBar={this.state.statusBar}
												//sideBar={this.state.sideBar}
												pagination={true}
											/>
										</div>
                                    </div>
                                </div>
                            </div>
                        </div>				 */}
                    {/* <div className="col-xl-7 col-lg-7 ">
                        <div className="card">
                            <div className="card-header">
                                <h5>LSP wise trucks with GPS Data</h5>
                            </div>
                            <div className="card-body">
                                <div className="dashboard-chart-container sales-overview-chart">
                                <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={lsphighchartoptions} />

                                </div>
                            </div>
                        </div>
                    </div> */}
				
					{/* <div className={"col-xl-5 col-lg-5 "+(this.state.sndshow)} >
                        <div className="card">
                            <div className="card-header">
                                <h5>LSP wise trucks with NO GPS Data</h5>
                            </div>
                            <div className="card-body">
                                <div className="dashboard-chart-container sales-overview-chart">
                                <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={lspNoGPShighchartoptions} />

                                </div>
                            </div>
                        </div>
                    </div> */}
						
                        {/* <div className={"col-xl-5 col-lg-5 "+(this.state.departmentchrtshow)}>
                            <div className="card default-widget-count">
	                            <div className="card-header">
		                            <h5>Department wise trucks with GPS Data</h5>
		                        </div>
                                <div className="card-body" style={{paddingBottom:'15px'}}>
                                    <div className="media">
                                    <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions1} />

                                    </div>
                                </div>
                            </div>
                           
						</div> */}
						 
						{/*
                        <div className={"col-xl-12 col-lg-12 "+(this.state.departmentchrtshow)}>
                            <div className="card default-widget-count">
	                            <div className="card-header">
		                            <h5>GPS Packet Interval</h5>
		                        </div>
                                <div className="card-body" style={{paddingBottom:'15px'}}>
									<div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
	    		          		          <AgGridReact
											context={this}
											onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
	    		          		            modules={this.state.modules}
	    		          		            columnDefs={this.state.columngpspacketDefs}
	    		          		            defaultColDef={this.state.defaultColDef}
	    		          		            rowData={this.state.rowgpspacketData}
	    		          		            enableCharts={false}
	    		          		            enableRangeSelection={false}
	    		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
	    		          		            onGridReady={this.onGridReady}
	    		          		            onGridState={this.onGridState}
											  statusBar={this.state.statusBar}
											  //sideBar={this.state.sideBar}
											  pagination={true}
	    		          		          />
									</div>
									
									<div className="media">
                                    	<HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartgpsinterval} />

                                    </div>
                                </div>
                            </div>
                           
                        </div> 

						*/}
                        
{/*                     
                        <div className="col-xl-12 col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Transporter wise trucks with GPS Data</h5>
                                </div>
                                <div className="card-body">
                                    <div className="dashboard-chart-container sales-overview-chart">
                                    <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Transporter wise trucks Without GPS Data</h5>
                                </div>
                                <div className="card-body">
                                    <div className="dashboard-chart-container sales-overview-chart">
                                    <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={transporterNoGPSChart} />

                                    </div>
                                </div>
                            </div>
                        </div>	
						                     */}
	                    
	                    
                    </div>

					<div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
					 	{this.displayData}
				 	</div>


                </div>
                {/*Container-fluid Ends*/}
            </div>
        );
    }
}
	function rendJsonData(data)
	{
		//console.log(data);
		var order_cnt=[];
		order_cnt=data.map( (seris) => 
			seris.key
		)
		//console.log(order_cnt)
	}

	function dateComparator(date1, date2) {
		// console.log("dateComparator1");
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		if (date1Number === null && date2Number === null) {
		  return 0;
		}
		if (date1Number === null) {
		  return -1;
		}
		if (date2Number === null) {
		  return 1;
		}
		return date1Number - date2Number;
	  }
	function monthToComparableNumber(date) {
		if (date === undefined || date === null || date.length !== 19) {
			return null;
		}
		var yearNumber = date.substring(6, 10);
		var monthNumber = date.substring(3, 5);
		var dayNumber = date.substring(0, 2);
		var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
		return result;
	}



export default TransporterIntegrationDashboardComponent;


                                
                                
                                
                                
                                
                                
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import redirectURL from "../redirectURL";
import { countries } from "country-flags-svg";
import ProgressbarCard from "./ProgressbarCard.js";
// import DocumentsCard from "./DocumentsCard.js";
// import DocumentPreviewCard from "../DocumentPreviewCard";
import Mapcard2 from "./Mapcard2.js";
import TrackingTileImports2 from "./TrackingTileImports2.js" 
// import TrackingDetailsCard from "./TrackingDetailsCard";
// import  TrackingDetailsCard from "./TrackingDetailsTabNew";
// import EventsCard from "./EventsCard.js";
// import ExceptionsCard from "./ExceptionsCard";

// import BootstrapLoader from "./BootstrapLoader";
import EventTab from "./EventTab.js";
// import DetailsTab from "./DetailsTab.js";
// import redirectURL from "../redirectURL"
var moment = require('moment');

const ContainerTrackingImportsDetails = () => {
  //  console.log(useParams(),"mbl_number");

  let container=localStorage.getItem("each")==""?{}:JSON.parse(localStorage.getItem("each"))
  console.log(container,"ccccccccccccccccc");

  const [state, setState] = useState({
    shipmentNo: "",
    showLoader: false,
    eventsData: [],
    eventsTab: [],
    tabActive: "Container",
    containerTabCard: "show-m",
    detailsTabCard: "show-n",
    documentsTabCard: "show-n",
    eventsTabCard: "show-n",
    exceptionTabCard: "show-n",
    mapinfo: {
      coords: [
        // { lat: 8.76735, lng: 78.13425 },
        // { lat: 18.76735, lng: 88.13425 },
      ],
      coords2: [],
      breaks: [],
      route_events: [],
      last_event_coord: [],
    },
    allShipmentLocations: {
      coords: [],
      coords2: [],
      breaks: [],
      route_events: [],
    },
    container_data: [],
    all_data: [],
    bl_data: [],
    detailType: "",
    subMaps: false,
    counterData: {
      all: 0,
      origin: 0,
      in_transit: 0,
      destination: 0,
      completed: 0,
      delayed: 0,
      detained: 0,
    },
    bl_all_data: [],
    eventShow: "show-n",
    eventData: [],
    progressCardVisibility: "none",
    mapHeight: "94vh",
    shipment_unique_id: "",
    uploadedDocx: [],
    most_delayed_container_emission: "",
    active: '',
  });
  
  let geoLocShipmentsMap = {}

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  let allCoordinates = [];
  let allCoordinates2 = [];
  let {
    discharge_port_coordinates,
    latest_location,
    loading_port_coordinates,
  } = container;

    let duplicateData = [];
    let uniqueCoords = [];
    let duplicateData2 = [];
    let uniqueCoords2 = [];
    let parseCoord
    let parseCoord2
    let route2
    let allCoordinates3 = [];
    let uniqueCoords3 = [];
    let duplicateData3 = [];
    let parseCoord3
    let route3
    if (container.hasOwnProperty("searates_land_route") || container.searates_land_route!="") {
      route2 = container.searates_land_route
    }
    try {
      parseCoord = JSON.parse(route2);
      parseCoord.forEach((eachCoord) => {
        let { lat, lng } = eachCoord;
        let latlng = `${lat}${lng}`;
        if (!duplicateData.includes(latlng)) {
          uniqueCoords.push({
            lat: lat,
            lng: lng,
          });
          duplicateData.push(latlng);
        }
      });
      allCoordinates.push(...uniqueCoords);
    } catch (err) {
      allCoordinates = []
    }
    try {
      parseCoord2 = JSON.parse(container.route!=""?container.route:[]);
      parseCoord2.forEach((eachCoord) => {
        let { lat, lng } = eachCoord;
        let latlng = `${lat}${lng}`;
        if (!duplicateData2.includes(latlng)) {
          uniqueCoords2.push({
            lat: lat,
            lng: lng,
          });
          duplicateData2.push(latlng);
        }
      });
      allCoordinates2.push(...uniqueCoords2);
    } catch (err) {
      allCoordinates2 = []
    }
    if (container.hasOwnProperty("ldb_route") || container.ldb_route!="") {
      route3 = container.ldb_route
    }
    try {
      parseCoord3 = JSON.parse(route3);
      parseCoord3.forEach((eachCoord) => {
        let { lat, lng } = eachCoord;
        let latlng = `${lat}${lng}`;
        if (!duplicateData3.includes(latlng)) {
          uniqueCoords3.push({
            lat: lat,
            lng: lng,
          });
          duplicateData3.push(latlng);
        }
      });
      allCoordinates3.push(...uniqueCoords3);
    } catch (err) {
      allCoordinates3 = []
    }

  if (
    loading_port_coordinates.latitude &&
    loading_port_coordinates.longitude
  ) {
    allCoordinates2.unshift({
      lat: loading_port_coordinates.latitude,
      lng: loading_port_coordinates.longitude,
    });
  }

  //discharge at last
  if (
    discharge_port_coordinates.latitude &&
    discharge_port_coordinates.longitude
  ) {
    allCoordinates2.push({
      lat: discharge_port_coordinates.latitude,
      lng: discharge_port_coordinates.longitude,
    });
  }

  let latestCoords = [];
  if (latest_location && latest_location.latitude && latest_location.longitude) {
    latestCoords.push({
      lat: latest_location.latitude,
      lng: latest_location.longitude,
    });
  if(container.hasOwnProperty("container_no"))
  geoLocShipmentsMap[`${latest_location.latitude}${latest_location.longitude}`] = container.container_no
  }

  const showTabView = (tab) => {
    if (tab == "Details") {
      setState({
        ...state,
        tabActive: tab,
        containerTabCard: "show-n",
        eventsTabCard: "show-n",
      });
    } else if (tab == "Container") {
      setState({
        ...state,
        tabActive: "Container",
        containerTabCard: "show-m",
        eventsTabCard: "show-n",
      });
    } 
    else if (tab == "Events") {
      setState({
        ...state,
        tabActive: "Events",
        containerTabCard: "show-n",
        eventsTabCard: "show-m",
      });
    } 
    
  };


  return (
    <>
      <div className="container-fluid">
       <div style={{display:"flex",justifyContent:"space-between",marginRight:'18px'}}>
        <div>
          <ol
          className="breadcrumb"
          style={{ background: "none", height: "52px", marginLeft: "8px" }}
        >
          <li className="breadcrumb-item" style={{ height: "40px" }}>
            <Link
              to={"/container-tracking-imports"}
              style={{
                textDecoration: "none",
                position: "relative",
                top: "2px",
              }}
            >
              Container Tracking
            </Link>
          </li>
           {/* <li className="breadcrumb-item" style={{ height: "40px" }}>
              <a
                href="/container-tracking-imports"
                style={{
                  color: "#263238",
                  fontSize: "15px",
                  fontWeight: 700,
                  marginBottom: "20px",
                  paddingLeft: "10px",
                }}
              >
                Container Tracking
              </a>
            </li> */}
          {/* <li
            className="breadcrumb-item"
            aria-current="page"
            style={{
              color: "#263238",
              fontSize: "15px",
              fontWeight: 700,
              marginBottom: "20px",
              paddingLeft: "10px",
            }}
          >
            Container Details
          </li> */}
       
        </ol></div>
       <div> <span >
              <a className="btn" style={{backgroundColor:'#171c8f',color:'white',borderRadius:'5px'}}
                href="/container-tracking-imports"

              >Back</a>
            </span></div>
       </div> 
          <>
            <TrackingTileImports2
              container_data={container}
            />
          </>

        <div className="d-flex flex-row justify-content-between ml-4">
          <div className="">
            <ul className={"nav nav-tabs"}>
              <li className={"nav-item"}>
                <a
                  className={`nav-link ${state.tabActive == "Container" ? "tab-active" : ""
                    }`}
                  onClick={() => showTabView("Container")}
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}>
                  Container
                </a>
              </li>


              <li className={"nav-item"}>
                <a
                  className={`nav-link ${state.tabActive == "Events" ? "tab-active" : ""
                    }`}
                  onClick={() => showTabView("Events")}
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                >
                  Events
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={`${state.containerTabCard} mx-3 pt-3`}>
        <div className="row" style={{ width: "auto", margin: "auto" }}>
          <div className="col-7">
            <Mapcard2
              landRoute={allCoordinates}
              seaRoute={allCoordinates2}
              destination={allCoordinates3}
              last_event_coord= {latestCoords}
              subMaps={true}
              geoLocShipmentsMap={geoLocShipmentsMap}
            />
          </div>
            <div className="col-5">
              <div className="counters-card">
                <ProgressbarCard
                  eventData={container.events}
                  container_no={container.container_no}
                />
              </div>
            </div>
        </div>
      </div>

      <div className={`${state.eventsTabCard} mx-3 pt-3 mb-3`}>

        <div className="row" style={{ width: "auto", margin: "auto" }}>
          <div className= "col-12">
            <EventTab eventData={container} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContainerTrackingImportsDetails;


import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var moment = require('moment');
var redirectURL = require('../redirectURL');

 
const Remarksaction = (props) => {
   
    const handleRepairClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onChangeRemarkRepairOptions(props);
    };

    return (
        
        <span>
           
           <button type="button" onClick={handleRepairClick} className="btn btn-danger n-p-0 f12">Repair/Under Maintenance</button>
        </span>
        );
};

export default Remarksaction;

import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const RouteAnalyticsChart = ({ onColumnClick, categories, yAxis, series }) => {

    // Define handlePointClick first
    // const handlePointClick = (event) => {
    //     const { category, y } = event.point;
    //     onColumnClick({
    //         category,
    //         value: y,
    //         additionalText: 'Additional text here', // Add your additional text or data here
    //     });
    // };

    const colors = ['#12DD12', '#297601'];

    // Then map over the series
    series = series.map((e, index) => ({
        ...e,
        color: colors[index % colors.length],
        point: {
            events: {
                click: null,
            },
        }
    }));

    // Function to format values using Indian locale
    const safeToLocalString = (value) => {
        const val = Number(value);
        return val != null ? val.toLocaleString('en-IN') : 0;
    }

    const options = {
        chart: {
            type: 'column',
        },
        title: {
            text: '',
        },
        xAxis: {
            categories: categories,
            labels: {
                useHTML: true,
                formatter: function () {
                    return this.value;
                }
            }
        },
        yAxis: {
            title: {
                text: yAxis,
                useHTML: true,
                formatter: function () {
                    return this.value;
                }
            },
            type: 'logarithmic',
        },
        series: series,
        plotOptions: {
            column: {
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    // format: '{point.y}',
                    formatter: function () {
                        return safeToLocalString(this.y);  // Format the value using the safeToLocalString function
                    },
                },
            },
        },
        tooltip: {
            // pointFormat: '<b>{point.y}</b>',
            pointFormatter: function () {
                return `<b>${safeToLocalString(this.y)}</b>`;  // Format y value using the safeToLocalString function
            },
            backgroundColor: '#FFFFFF',
            useHTML: true,
        },
        legend: {
            enabled: true, // Adjust as needed
        },
        credits: {
            enabled: false,
        },
    };

    return (
        <HighchartsReact highcharts={Highcharts} options={options} />
    );
};

export default RouteAnalyticsChart;
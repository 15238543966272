import React, { Component } from 'react';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY  } from '../common/utils'; 
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import ExportsTrackingCountersComponent from "./exportstrackingcounterComponent";
import SweetAlert from 'react-bootstrap-sweetalert';

var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");

export default class CHADestinationPort extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
    		pageTitle: "CHA Update Destination Port",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
            modules: AllModules,
            sidebarSubHeader:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: [],
      	      
			rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,				
			groupDefaultExpanded: -1,
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:1000,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
            highseescnt:0,
            highseesarr:[],
            icdtoport:0,
            icdtoportarr:[],
            insideicdcnt:0,
            insideicdarr:[],
            insideportcnt:0,
            insideportarr:[],
            msilicdcnt:0,
            msilicdarr:[],
            originalData:[],
            polList:[],
            cargo_type:[],
            shippinglinecodes:[],
            userType:1,
            chanames:[],
            chaUsers:[],
            containerSizes:[],
    	}
        this.onLoadDashboardData = this.onLoadDashboardData.bind(this);
		this.resetState = this.resetState.bind(this);
    }
   
    componentDidMount(){
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
        }
        
        redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			//console.log("resp ", resp)
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
        })

        var containerTypes = ["EXIM","Domestic"];
        var nominationTypes = ["CIF","FOB"];
        var containerSizes = ["20 FT","20 FT High Cube (HC)","40 FT","40 FT High Cube (HC)"]
        this.setState({
            containerTypes : containerTypes,
            nominationTypes : nominationTypes,
            containerSizes : containerSizes
        });
        
        redirectURL.post("/consignments/getPolNames").then((response)=>{
            var pols = response.data;
            var polList = [];
            if(pols.length > 0)
            {
                pols.map((item) =>{
                    polList.push(item)
                })
            }
            this.setState({
                polList : polList,
            })
        }).catch((e)=>{
            console.log(e);
        })

        redirectURL.post("/consignments/getExportCargoTypes")
		.then((resp) => {
            var cargo_type = resp.data;
            this.setState({
                cargo_type:cargo_type,
            })
        })
        
        redirectURL.post("/consignments/getShippingLineCodes")
		.then((res) => {
            var shippinglinecodes = res.data;
            this.setState({
                shippinglinecodes:shippinglinecodes,
            })
        })

        redirectURL.post("/consignments/getLogSCChaUsers")
		.then((res) => {
            var chaUsers = res.data;
            var cUsers = [];
            chaUsers.map(function(u){
                cUsers.push(u.username);
            });
            this.setState({
                chanames:cUsers,
                chaUsers:chaUsers
            })
        })

        this.onLoadDashboardData()
	};
    
    onLoadDashboardData()
    {
        var userRole = localStorage.getItem("roles");
        if(userRole.indexOf("cha_prt_exports") >= 0)
        {
            this.setState({
                cha_code : localStorage.getItem("cha_code"),
                userType : 2,
            });
        }
        else
        {
            this.setState({
                userType : 1,
            });
        }
        var params = {
            userRole : localStorage.getItem("roles"),
            cha_code : localStorage.getItem("cha_code"),
        }
		redirectURL.post("/consignments/getActiveContainerExports",params)
		.then((response) => {
            console.log("Respons ", response.data)
            
            this.setState({
                rowData:response.data,
            })
        })
        
    }
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			openUpdatedModal : false
		});
		
	}
	closeAlert = () => {
        this.setState({
            show: false
        });
    }
   

	onClickSaveGridState(){
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
        let screenpage = 'Exports Container Tracking';

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
        try{
            if(this.state.usergridstate.length > 0)
            {
                var windowstates = this.state.usergridstate;
                this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
                this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
                this.gridApi.setSortModel(windowstates[0].gridcolsort);
                this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
                
            }
        }
        catch(e){

        }
		
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

    };
    
	logCounterClickGA(params) {
		if (googleAnalytics.page.enableGA) {
            if(params == 1)
            {
                var statusText = "MSIL to ICD";
            }
            if(params == 2)
            {
                var statusText = "Inside ICD";
            }
            if(params == 3)
            {
                var statusText = "ICD to Port";
            }
            if(params == 4)
            {
                var statusText = "Inside Port";
            }
            if(params == 5)
            {
                var statusText = "Highseess";
			}
			let eventOptions = {
				"category": this.state.pageTitle,
				"action": this.state.eventCounterAction,
				"label": statusText,
			}
			googleAnalytics.logEvent(eventOptions);
		}
    }
    
    onCellRowClicked(e){
        if(e.colDef.field == "update")
        {
            var rowId = e.data._id;
            var container_no = e.data.container_no;
            if(container_no !=undefined && container_no !='')
            {
                var chk = 1;
                var container_type = e.data.container_type;
                var shipping_line_booking_id = e.data.shipping_line_booking_id;
                //var replacement_container_no = e.data.replacement_container_no;
                var nomination_type = e.data.nomination_type;

                var export_to = e.data.export_to;
                var container_size = e.data.container_size;
                var msil_gate_out = e.data.msil_gate_out;
                var status = e.data.status;
                var cargo_type = e.data.cargo_type;
                var shipping_line_code = e.data.shipping_line_code;
                var shipping_line_booking_date = e.data.shipping_line_booking_date;
                if(this.state.userType == 2)
                {
                    var cha_name = localStorage.getItem("username");
                    var cha_code = localStorage.getItem("cha_code");
                }
                else
                {
                    var cha_code = "";
                    var cha_name = e.data.cha_name;
                    //console.log(this.state.chaUsers)
                    this.state.chaUsers.map(function(c,index){
                        
                        if(c.username == cha_name)
                        {
                            //console.log(c.username,cha_name,index)
                            cha_code = c.cha_code;
                        }
                    });
                    //console.log(cha_code)
                }
                // if(replacement_container_no !='' && replacement_container_no !=undefined)
                // {
                //     if(replacement_container_no.length == 11)
                //     {
                //         var chk = 1;
                //     }
                //     else
                //     {
                //         var chk = 0;
                //     }
                // }
                // else
                // {
                //     var chk = 1;
                // }
                if(chk == 1)
                {
                    var reqparams = {
                        rowId : rowId,
                        container_no : container_no,
                        container_type : container_type,
                        shipping_line_booking_id : shipping_line_booking_id,
                        //replacement_container_no : replacement_container_no,
                        nomination_type : nomination_type,
                        export_to : export_to,
                        container_size : container_size,
                        msil_gate_out : msil_gate_out,
                        status : status,
                        cargo_type : cargo_type,
                        shipping_line_code : shipping_line_code,
                        shipping_line_booking_date : shipping_line_booking_date,
                        cha_name : cha_name,
                        cha_code : cha_code,
                    }
                    console.log(reqparams,"reqparams");
                    redirectURL.post("/consignments/updateCHALogScExports", reqparams)
                    .then((response) => {
                        if(response.data.message == "Success")
                        {
                            this.setState({
                                basicTitle:"Successfully Updated",
                                basicType : "success",
                                show : true,
                                overly:"show-n",
                                loadshow:"show-n",
                            })
                            //window.location.reload();
                            this.onLoadDashboardData();
                        }
                        else
                        {
                            this.setState({
                                basicTitle:"Failed to update.",
                                basicType : "warning",
                                show : true
                            })
                        }
                    })
                }
                else
                {
                    this.setState({
                        //basicTitle:"Replacement Container No should be 11 Characters.",
                        basicTitle:"Something went wrong.",
                        basicType : "warning",
                        show : true
                    })
                }
            }
            else
            {
                this.setState({
                    basicTitle:"Something went wrong.",
                    basicType : "warning",
                    show : true
                })
            }
        }
    }

    
    render(){
        var hideCHA = true;
        var editCHA = false;
        if(this.state.userType == 1)
        {
            var hideCHA = false;
            var editCHA = true;
        }
        var columnwithDefs = [
            {
                headerName : "",
                field : "update",
                width:100,
                editable : false,
                pinned:"left",
                cellRenderer: function(params){
                    return "<button class='btn btn-info' style='padding:0px;width:76px'>Update</button>"
                }
            },
            {
                headerName : "Container No",
                field : "container_no",
                width:150,
                editable : false,
                pinned:"left",
                resizable:true
            },
            {
                headerName:"Dispatch Date From MSIL",
                field:"msil_gate_out",
                width:180,
                pinned:"left",
                editable:true,
                resizable:true,
                cellEditor: DateEditor,
                valueGetter:function(params){
                    //console.log("params ", params);
                    if (params.data.msil_gate_out != null)
                    {
                        if(moment(params.data.msil_gate_out,'DD-MM-YYYY hh:mm',true).isValid() == false)
                        {
                            return getHyphenDDMMMYYYY(params.data.msil_gate_out);
                        }
                        else{
                            return params.data.msil_gate_out;
                        }
                        
                    }
                    else
                    {
                        return ""
                    }
    
                }
            },
            {
                headerName : "CHA Name",
                field : "cha_name",
                width:150,
                hide:hideCHA,
                editable : editCHA,
                pinned:"left",
                resizable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: this.state.chanames,
                },
            },
            {
                headerName : "Shipping Line Booking Id",
                field : "shipping_line_booking_id",
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
                width:180,
                editable : true,
                resizable:true
            },
            {
                headerName:"Shipping Line Booking Date",
                field:"shipping_line_booking_date",
                width:160,
                editable:true,
                resizable:true,
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
                cellEditor: DateEditor,
                valueGetter:function(params){
                    //console.log("params ", params);
                    if (params.data.shipping_line_booking_date != null)
                    {
                        if(moment(params.data.shipping_line_booking_date,'DD-MM-YYYY hh:mm',true).isValid() == false)
                        {
                            return getHyphenDDMMMYYYY(params.data.shipping_line_booking_date);
                        }
                        else{
                            return params.data.shipping_line_booking_date;
                        }
                        
                    }
                    else
                    {
                        return ""
                    }
    
                }
            },
            {
                headerName:"Container Type",
                field:"container_type",
                width:130,
                editable:true,
                resizable:true,
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: this.state.containerTypes,
                },
            },
            // {
            //     headerName : "Replacement Container No",
            //     field : "replacement_container_no",
            //     headerClass:"cellstylegridY",
            //     cellClass:'cellstylegridY',
            //     width:190,
            //     editable : function(params){
            //         if(params.data.container_type == "Domestic")
            //         {
            //             return true;
            //         }
            //         else
            //         {
            //             return false;
            //         }
            //     },
            //     resizable:true,
            // },
            // {
            //     headerName : "Old Container No",
            //     field : "old_container_no",
            //     width:150,
            //     editable : false,
            //     resizable:true
            // },
            {
                headerName:"Nomination Type",
                field:"nomination_type",
                width:140,
                editable:true,
                resizable:true,
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: this.state.nominationTypes,
                },
            },
            {
                headerName:"Container Size",
                field:"container_size",
                width:150,
                editable:true,
                resizable:true,
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: this.state.containerSizes,
                },
            },
            {
                headerName:"Export To",
                field:"export_to",
                width:200,
                editable:true,
                resizable:true,
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: this.state.polList,
                },
            },
            {
                headerName:"Cargo Type",
                field:"cargo_type",
                width:150,
                editable:true,
                resizable:true,
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: this.state.cargo_type,
                },
            },
            {
                headerName:"Shipping Line Code",
                field:"shipping_line_code",
                width:150,
                editable:true,
                resizable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: this.state.shippinglinecodes,
                },
            }
        ]
        return (
            <div className="row">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="col-xl-12 col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Exports Container Updates (SC)</span>                                    
                            </h5>
                        </div>				   			
                        <div className="card-body">
                            <div className="col-xl-12 col-lg-12">
                                <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={false}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        components={this.state.components}
                                        rowSelection={this.state.rowSelection}
                                        suppressRowClickSelection={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        onCellClicked={this.onCellRowClicked.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker: false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};






import React, { Component } from 'react';
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from "react-select";
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import CriticalDalayScreen from "./criticaldelay"
var redirectURL = require("../redirectURL");
export default class OnTimeDeliveryTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ontime_performance: 0,
      ontime_count:0,
      filterType: 'All',
      totalSumedUpValues: [],
      loadshow: 'show-m',
      overly: 'show-m',
      currentMonth:'',
      facility: "All Facilities",
      facilitiesList: [{ "label": "All Facilities", "value": "All" },
      { "label": "Haryana", "value": "H" },
      { "label": "Bidadi", "value": "BP" }
        , { "label": "Gujarat", "value": "G" },
      { "label": "TVP-B", "value": "B" },
      { "label": "TVP-N", "value": "N" },
      { "label": "TVP-S", "value": "I" }]
    }

  }

  componentDidMount() {
    var transporterCode = window.atob(this.props.match.params.transporterCode)
    console.log(transporterCode, 'transporterCode');
    var ontime_performance = 0;
    var ontime_count = 0;
    const now = new Date();
    const monthIndex = now.getMonth();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const currentMonth = `${monthNames[monthIndex]}-${now.getFullYear().toString().slice(-2)}`;
    console.log("Current month:", currentMonth);

    this.setState({
      loadshow: "show-m",
      overly: "show-m",
      currentMonth: currentMonth
    })
    redirectURL
      .post("/consignments/toplspperformance")
      .then((response) => {
        ontime_performance = response.data[0].ontime_performance || 0;
        ontime_count = response.data[0].ontime_count || 0;
        this.setState({
          transporter_code: transporterCode,
          ontime_performance: ontime_performance,
          ontime_count:ontime_count
        });

        var params = {
          transporter_code: transporterCode
        };
        redirectURL
          .post("/consignments/ontimedeliveryMountData", params)
          .then((response) => {
            // console.log(response.data);
            const filteredData = response.data
              .filter(item => item.month && item.month.startsWith('FY'))
              .sort((a, b) => {
                const yearA = extractYear(a.month);
                const yearB = extractYear(b.month);
                return yearA - yearB;
              });
            const aggregatedData = filteredData.reduce((result, item) => {
              const year = item.month;
              if (!result[year]) {
                result[year] = {
                  month: year,
                  count: 0,
                  ontime_delivery: 0,
                  total_trips: 0,
                  late_trips: 0
                };
              }
              if (item.parameters && item.parameters.ontime_delivery !== null && item.parameters.ontime_delivery != 0 && item.parameters.ontime_delivery != NaN && item.parameters.ontime_delivery != "NaN") {
                result[year].ontime_delivery += item.parameters.ontime_delivery;
                result[year].total_trips += item.parameters.total_trips;
                result[year].late_trips += item.parameters.late_trips;
                result[year].count += 1;
              }
              return result;
            }, {});
            const aggregatedDataArray = Object.values(aggregatedData);
            let sortedAndFilteredArray = aggregatedDataArray.slice(-2)
            sortedAndFilteredArray = sortedAndFilteredArray.map((e) => {
              // if(e.total_trips != 0 && e.total_trips != NaN && e.total_trips != "NaN"){
              return ({
                month: e.month,
                ontime_delivery: parseFloat((e.ontime_delivery / e.count).toFixed(2)),
                total_trips: e.total_trips,
                late_trips: e.late_trips
              })
              // }
            })
            const currentFiscalYearMonthsData = {};
            var month_total_trips_delivery = 0
            var month_late_trips_delivery = 0
            response.data.forEach((item) => {
              const month = item.month;
              if (!month.startsWith('FY')) {
                if (!currentFiscalYearMonthsData[month]) {
                  currentFiscalYearMonthsData[month] = [];
                }
                if (item.parameters && item.parameters.ontime_delivery !== null && item.parameters.late_trips !== null && item.parameters.total_trips !== null) {
                  currentFiscalYearMonthsData[month].push({
                    "late_trips": item.parameters.late_trips,
                    "total_trips": item.parameters.total_trips,
                    "ontime_delivery": item.parameters.ontime_delivery
                  });
                }
              }
            });

            const monthsToExtract = DynamicSortOfMonths()
            const filteredDataArray = [];
            monthsToExtract.forEach(month => {
              if (currentFiscalYearMonthsData.hasOwnProperty(month)) {
                let total_trips = 0;
                let late_trips = 0;
                let ontime_delivery = 0
                let count = 0
                currentFiscalYearMonthsData[month].map(e => {
                  if (e.ontime_delivery != 0) {
                    total_trips = total_trips + e.total_trips;
                    late_trips = late_trips + e.late_trips;
                    ontime_delivery += e.ontime_delivery
                    count += 1
                  }

                })
                filteredDataArray.push({
                  month: month,
                  ontime_delivery: count == 0 ? 0 : parseFloat((ontime_delivery / count).toFixed(2)),
                  total_trips: total_trips,
                  late_trips: late_trips
                });
              }
            });
            const joinedArray = sortedAndFilteredArray.concat(filteredDataArray);
            joinedArray.sort((a, b) => {
              // Map for months to convert them to numeric values
              const monthMap = {
                'Jan': 1, 'Feb': 2, 'Mar': 3, 'Apr': 4, 'May': 5, 'Jun': 6,
                'Jul': 7, 'Aug': 8, 'Sep': 9, 'Oct': 10, 'Nov': 11, 'Dec': 12
              };

              // Extracting year and month from the month property
              const extractDateParts = (monthYear) => {
                if (monthYear.startsWith("FY")) {
                  // For fiscal years, assume "FY 18-19" -> treat it as the first year "2018"
                  const fiscalYear = monthYear.split(' ')[1].split('-')[0];
                  return { month: 0, year: 2000 + parseInt(fiscalYear) };
                } else {
                  const parts = monthYear.split('-');
                  return { month: monthMap[parts[0]], year: parseInt(parts[1], 10) + 2000 };
                }
              };

              const aDate = extractDateParts(a.month);
              const bDate = extractDateParts(b.month);

              // Compare years first
              if (aDate.year !== bDate.year) {
                return aDate.year - bDate.year;
              }

              // If years are the same, compare months (note fiscal year months are 0)
              return aDate.month - bDate.month;
            });

            this.setState({
              totalSumedUpValues: joinedArray,
              loadshow: 'show-n',
              overly: 'show-n',
            });
          })
      })
      .catch((error) => {
        console.error(error);
      });

  }



  newFilter = (event) => {
    this.setState({
      filterType: event.value,
      loadshow: 'show-m',
      overly: 'show-m',
    })
    var params = {
      transporter_code: this.state.transporter_code,
      plant_code: event.value,
      type: 'onTimeDelivery'
    }
    console.log(params, 'params')
    redirectURL
      .post("/consignments/onselectfacilityfilter", params)
      .then((response) => {
        console.log(response.data);
        const filteredData = response.data
          .filter(item => item.month && item.month.startsWith('FY'))
          .sort((a, b) => {
            const yearA = extractYear(a.month);
            const yearB = extractYear(b.month);
            return yearA - yearB;
          });
        const aggregatedData = filteredData.reduce((result, item) => {
          const year = item.month;
          if (!result[year]) {
            result[year] = {
              month: year,
              count: 0,
              ontime_delivery: 0,
              total_trips: 0,
              late_trips: 0
            };
          }
          if (item.parameters && item.parameters.ontime_delivery !== null && item.parameters.ontime_delivery != 0 && item.parameters.ontime_delivery != NaN && item.parameters.ontime_delivery != "NaN") {
            result[year].ontime_delivery += item.parameters.ontime_delivery;
            result[year].total_trips += item.parameters.total_trips;
            result[year].late_trips += item.parameters.late_trips;
            result[year].count += 1;
          }
          return result;
        }, {});
        const aggregatedDataArray = Object.values(aggregatedData);
        let sortedAndFilteredArray = aggregatedDataArray.slice(-2)
        sortedAndFilteredArray = sortedAndFilteredArray.map((e) => {
          // if(e.total_trips != 0 && e.total_trips != NaN && e.total_trips != "NaN"){
          return ({
            month: e.month,
            ontime_delivery: parseFloat((e.ontime_delivery / e.count).toFixed(2)),
            total_trips: e.total_trips,
            late_trips: e.late_trips
          })
          // }
        })
        const currentFiscalYearMonthsData = {};
        var month_total_trips_delivery = 0
        var month_late_trips_delivery = 0
        response.data.forEach((item) => {
          const month = item.month;
          if (!month.startsWith('FY')) {
            if (!currentFiscalYearMonthsData[month]) {
              currentFiscalYearMonthsData[month] = [];
            }
            if (item.parameters && item.parameters.ontime_delivery !== null && item.parameters.late_trips !== null && item.parameters.total_trips !== null) {
              currentFiscalYearMonthsData[month].push({
                "late_trips": item.parameters.late_trips,
                "total_trips": item.parameters.total_trips,
                "ontime_delivery": item.parameters.ontime_delivery
              });
            }
          }
        });
        // console.log(currentFiscalYearMonthsData, 'currentFiscalYearMonthsData')
        const monthsToExtract = DynamicSortOfMonths()
        const filteredDataArray = [];
        monthsToExtract.forEach(month => {
          if (currentFiscalYearMonthsData.hasOwnProperty(month)) {
            let total_trips = 0;
            let late_trips = 0;
            let ontime_delivery = 0
            let count = 0
            currentFiscalYearMonthsData[month].map(e => {
              if (e.ontime_delivery != 0) {
                total_trips = total_trips + e.total_trips;
                late_trips = late_trips + e.late_trips;
                ontime_delivery += e.ontime_delivery;
                count += 1
              }
            })
            filteredDataArray.push({
              month: month,
              ontime_delivery: count == 0 ? 0 : parseFloat((ontime_delivery / count).toFixed(2)),
              total_trips: total_trips,
              late_trips: late_trips
            });
          }
        });
        const joinedArray = sortedAndFilteredArray.concat(filteredDataArray);
        joinedArray.sort((a, b) => {
          // Map for months to convert them to numeric values
          const monthMap = {
            'Jan': 1, 'Feb': 2, 'Mar': 3, 'Apr': 4, 'May': 5, 'Jun': 6,
            'Jul': 7, 'Aug': 8, 'Sep': 9, 'Oct': 10, 'Nov': 11, 'Dec': 12
          };

          // Extracting year and month from the month property
          const extractDateParts = (monthYear) => {
            if (monthYear.startsWith("FY")) {
              // For fiscal years, assume "FY 18-19" -> treat it as the first year "2018"
              const fiscalYear = monthYear.split(' ')[1].split('-')[0];
              return { month: 0, year: 2000 + parseInt(fiscalYear) };
            } else {
              const parts = monthYear.split('-');
              return { month: monthMap[parts[0]], year: parseInt(parts[1], 10) + 2000 };
            }
          };

          const aDate = extractDateParts(a.month);
          const bDate = extractDateParts(b.month);

          // Compare years first
          if (aDate.year !== bDate.year) {
            return aDate.year - bDate.year;
          }

          // If years are the same, compare months (note fiscal year months are 0)
          return aDate.month - bDate.month;
        });
        this.setState({
          totalSumedUpValues: joinedArray,
          loadshow: 'show-n',
          overly: 'show-n',
        });
      })
      .catch((error) => {
        console.error(error);
      });

  }

  onClickCounter(data) {
  }

  handleDrilldown = (params) => {
    console.log("Here ,", params)
  }
  onClickHideAll() {
    this.setState({
      loadshow: 'show-n',
      overly: 'show-n',
      bulkslide: "",
      bulkslidebulk: '',
    });

  }
  render() {
    const dStyles = {
      width: '100%',
      height: '100%'
    }
    var OntimePerformance = this.state.ontime_performance;

    // var OntimePerformance = 100;
    // console.log(this.state.totalSumedUpValues, "test", OntimePerformance);
    // var modifiedDataFilter = this.state.totalSumedUpValues.slice(0, -1);

    const lastElement = this.state.totalSumedUpValues[this.state.totalSumedUpValues.length - 1];
    // console.log(lastElement,'lastElement',this.state.currentMonth,'---')
    if (lastElement && lastElement.month === this.state.currentMonth) {
      var modifiedDataFilter = this.state.totalSumedUpValues.slice(0, -1)
    } else {
      var modifiedDataFilter = this.state.totalSumedUpValues
    }
    console.log(modifiedDataFilter, 'modifiedDataFilter')

    const ontimeDeliveryPercentageData = modifiedDataFilter.map((ele) => {
      if (ele && ele.total_trips !== undefined && ele.late_trips !== undefined) {
        var ontime_trips = ele.total_trips - ele.late_trips;
        var percentage = ((ele.total_trips - ele.late_trips) / ele.total_trips) * 100;
        // Only calculate on-time percentages if the month isn't one of the specified fiscal years
        if (!["FY 18-19", "FY 19-20", "FY 20-21", "FY 21-22"].includes(ele.month)) {
          return { "percentage": percentage, "total_trips": ele.total_trips, "late_trips": ele.late_trips, "ontime_trips": ontime_trips };
        } else {
          // If the month is one of the specified fiscal years, just return the pre-existing on-time delivery percentage
          return { "percentage": ele.ontime_delivery };
        }
      }
    });

    console.log(modifiedDataFilter, 'modifiedDataFilter');
    console.log(ontimeDeliveryPercentageData, 'ontimeDeliveryPercentageData');

    const monthCategories = modifiedDataFilter.map((ele) => (ele && ele.month));
    var highchartoptions = {
      chart: {
        type: 'column',
        events: {
          load: function () {
            // Calculate the x position relative to the chart width
            var xPosition = this.chartWidth - 300; // Adjust the 300 based on your text length and desired margin

            this.renderer.html(
              '<div style="text-align: right;">' +
              // 'Best Performing LSP On-time % = 100<br>' +
              '<span style="margin-left: 150px;">Target: 100%</span>' +
              '</div>',
              xPosition,
              25 // You might need to adjust this as well for vertical positioning
            ).attr({
              zIndex: 5
            }).add().css({
              fontSize: 'large',

            }).align({
              align: 'right', // Right align the text
              verticalAlign: 'top', // Align it to the top
              x: -350, // Adjust this value to move text left(-) or right(+)
              y: 25 // Adjust this value to move text up(-) or down(+)
            });
          }
        }
      },
      title: {
        text: 'On-Time Delivery'
      },
      xAxis: {
        categories: monthCategories,
        crosshair: true
      },
      yAxis: {
        title: {
          text: 'ON-TIME %'
        },
        plotLines: [{
          color: '#2E75B6',
          value: 100,
          width: 2,
          zIndex: 5,
          label: {
            // text: 'Target: 100%', 
            // align: 'left',
            style: {
              color: 'gray',
              fontWeight: 'bold'
            }
          }
        }]
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="padding:0">On-Time Trips: </td>' +
          '<td style="padding:0"><b>{point.ontime_trips}</b></td></tr>' +
          '<tr><td style="padding:0">Total Trips: </td>' +
          '<td style="padding:0"><b>{point.total_trips}</b></td></tr>' +
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.2f}%</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
        className: 'custom-tooltip'
      },

      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.2f}% ',
            align: 'center',
            verticalAlign: 'bottom',
            inside: false,
          }
        }
      },
      series: [{
        name: 'On-Time Delivery',
        data: ontimeDeliveryPercentageData.map(value => ({
          y: value.percentage,
          color: value.percentage >= 100 ? '#92d864' : '#FF7F7F',
          total_trips: (value.total_trips > 0 ? value.total_trips : 0),
          ontime_trips: (value.ontime_trips > 0 ? value.ontime_trips : 0)
        })),
        showInLegend: true,
        color:'#92d864'
      }]
    };


    return (
      <div className='row'>
        <div className={"dataLoadpage " + (this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " + (this.state.loadshow)} style={{ position: "fixed", top: "43%" }}>
          <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
        <div style={{ textAlign: "center", background: "#171C8F", width: '100%', margin: "5px", borderRadius: "5px", height: '70px', paddingBottom: '25px' }}>
          <h6 class="txt-dark f40" style={{ marginTop: "0px" }}>
            <span style={{ fontSize: "20px", color: "#FFFFFF" }}>
              <span>On-Time Delivery</span>

            </span>
          </h6>
        </div>

        <div className="row card-body">
          <div className="col-xl-4">
            <label>Select Facilities</label>
            <Select
              placeholder={"All Facilities"}
              closeMenuOnSelect={true}
              value={this.state.facility.label}
              onChange={this.newFilter.bind(this)}
              style={{ borderRadius: "0px" }}
              options={this.state.facilitiesList} required
            />
          </div>
          <div className="col-xl-8 col-lg-8">
            <h6>
              <span className="float-right f12 ">
                <a className="btn btn-danger" style={{ float: "left", marginLeft: "10%" }} href="/lspceodashboardTest">Back</a>
              </span>
              <span style={{ float: "right", marginRight: "5%" }}>Best Performing LSP On-time % = {OntimePerformance}<br/>Best Performing LSPs count : {this.state.ontime_count}</span>

            </h6>
          </div>

        </div>
        <div className='card col-xl-12 col-lg-12'>
          <HighchartsReact
            containerProps={{ style: { width: '100%', height: '100%' } }}
            highcharts={Highcharts}
            style={dStyles}
            options={highchartoptions}
            onClick={this.handleDrilldown.bind(this)}
          />
        </div>
        {/* <div> */}
        {console.log(this.props.match.params, 'this.props.match.params', this.state.transporter_code)}
        {this.state.transporter_code && (
          <CriticalDalayScreen transporterCode={this.state.transporter_code} />
        )}
        {/* </div> */}
      </div>
    );
  }
}

function DynamicSortOfMonths() {
  const currentDate = new Date();
  const fiscalYearStartMonth = 3;
  let currentFiscalYear;
  if (currentDate.getMonth() < fiscalYearStartMonth) {
    currentFiscalYear = currentDate.getFullYear() - 1;
  } else {
    currentFiscalYear = currentDate.getFullYear();
  }

  const fiscalYearMonths = [];
  const monthNames = [
    "Apr", "May", "Jun", "Jul", "Aug", "Sep",
    "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"
  ];

  // Dynamically generate the order array based on the current fiscal year
  const order = [];
  for (let i = fiscalYearStartMonth; i < fiscalYearStartMonth + 12; i++) {
    const monthName = monthNames[i % 12];
    const year = (monthName === "Jan" || monthName === "Feb" || monthName === "Mar") ? currentFiscalYear + 1 : currentFiscalYear;
    order.push(`${monthName}-${String(year).substring(2)}`);
  }

  for (let month = fiscalYearStartMonth; month < fiscalYearStartMonth + 12; month++) {
    const monthName = monthNames[month % 12];
    const year = (monthName === "Jan" || monthName === "Feb" || monthName === "Mar") ? currentFiscalYear + 1 : currentFiscalYear;
    fiscalYearMonths.push(`${monthName}-${year}`);
  }

  // Sort fiscal year months based on the dynamically generated order
  fiscalYearMonths.sort((a, b) => {
    const [monthA, yearA] = a.split('-');
    const [monthB, yearB] = b.split('-');
    // Compare years first
    if (yearA !== yearB) {
      return parseInt(yearA) - parseInt(yearB);
    }
    // If years are the same, compare months
    return monthNames.indexOf(monthA) - monthNames.indexOf(monthB);
  });

  fiscalYearMonths.forEach((month, index) => {
    const [monthName, year] = month.split('-');
    const shortYear = year.substring(2);
    console.log(index, 'index', monthName, shortYear)
    fiscalYearMonths[index] = `${monthName}-${shortYear}`;
  });
  console.log(fiscalYearMonths, 'fiscalYearMonths')
  return fiscalYearMonths;
}
function extractYear(month) {
  const match = month.match(/FY (\d{2})-(\d{2})/);
  if (match) {
    // Convert to a number where '20xx' is represented as '20xx' (e.g., '2023')
    return parseInt(`20${match[1]}`, 10) * 100 + parseInt(match[2], 10);
  }
  return 0; // Default value if format is unexpected
}
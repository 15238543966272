import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../node_modules/react-resizable/css/styles.css';

import Autosuggest from 'react-autosuggest';
import ComponentIndex from '../../common/componentIndex';
import _ from "lodash";
import { WidthProvider, Responsive } from "react-grid-layout";
var redirectURL = require('../../redirectURL');

const ResponsiveReactGridLayout = WidthProvider(Responsive);


const lyts=[];


const languages = getLists();

const getSuggestions = value => {
	  const inputValue = value.trim().toLowerCase();
	  const inputLength = inputValue.length;

	  return inputLength === 0 ? [] : languages.filter(lang =>
	    lang.component_name.toLowerCase().slice(0, inputLength) === inputValue
	  );
	};

const getSuggestionValue = suggestion => suggestion.component_name;


export default class GridLayout extends Component {
 
  constructor(props) {
    super(props);

    this.state = {
      gitems: [].map(function(i, key, list) {
          return {
            i: i.toString(),
            x: i * 2,
            y: 0,
            w: 2,
            h: 2,
        t:'',
        s:1,
            //dp:'grid',
            static:false,
            add: i === (list.length - 1)
          };
      }),
      value: '',
      newCounter: 0,
      suggestions: [],
      userid:localStorage.getItem('userid')
    };
    this.onResize()
    //console.log(this.props.layouts);
    this.onDragStop = this.onDragStop.bind(this);
    this.onResizeStart = this.onResizeStart.bind(this);
    this.onResizeStop = this.onResizeStop.bind(this);
    this.onGlobalAddItem = this.onGlobalAddItem.bind(this);
    this.refrshNow = this.refrshNow.bind(this);
  }
  componentDidMount(){
		// console.log("gitems ", window.atob(localStorage.getItem('_t')));
		 const layoutdata={
			userid:this.state.userid
		}
		//console.log("vh", layoutdata);
		redirectURL.post('/layouts/userComponents', layoutdata)
		.then((response) => {
			//console.log("data",response.data.length);
			if(response.data.length > 0)
			{

			//}
				var rec=JSON.parse(JSON.stringify(response.data));
			//console.log("User ",JSON.parse(response.data[0].layout));
			//if(response.data != '0' && response.data != 0)
			//{	
        //console.log("Layout ",JSON.parse(response.data[0].layout));
				try{
          var layouts = JSON.parse(response.data[0].layout);
					layouts.map(item => {
						this.createElementViewport(item);
						this.setState({
							gitems:this.state.gitems.concat(item)
						});
					
					});
         
				}
				catch{
					
				}
      }
    })
  }

  componentDidUpdate(){
		// console.log("gitems ",window.atob(localStorage.getItem('_t')));
		 const layoutdata={
			userid:this.state.userid
		}
		//console.log("vh", layoutdata);
		redirectURL.post('/layouts/userComponents', layoutdata)
		.then((response) => {
			//console.log("data",response.data.length);
			if(response.data.length > 0)
			{

			//}
				var rec=JSON.parse(JSON.stringify(response.data));
			//console.log("User ",JSON.parse(response.data[0].layout));
			//if(response.data != '0' && response.data != 0)
			//{	
        //console.log("Layout ",JSON.parse(response.data[0].layout));
				try{
          var layouts = JSON.parse(response.data[0].layout);
					layouts.map(item => {
						this.createElementViewport(item);
						this.setState({
							gitems:this.state.gitems.concat(item)
						});
					
					});
         
				}
				catch{
					
				}
      }
    })
  }
  componentDidUpdate() { 
    setTimeout(() => { 
        this.onResize()
    }, 500)
  }
  resetLayout() {
    this.setState({ gitems: {} });
  }

  onLayoutChange(layout) {
		//  console.log("onChnage "+layout)
	   // this.props.onLayoutChange(layout);
	    this.setState({ gitems: layout });
	  }
  createElementViewport(item) {
   var el = JSON.parse(JSON.stringify(item));
   //console.log("View port here ",el)
   // console.log("View port k ",k)
   const removeStyle = {
    position: "absolute",
    right: "2px",
    top: 0,
    cursor: "pointer"
  };
  var widt="300px !important";
  const styles={
      background:"rgb(39, 130, 128)",
      padding:"5px 10px",
      color:"#fff"
      //width:widt
  }
  var tile = el.i.split("_");
  var title = tile[3];
  //console.log("herecheck",tile[0]);
  if(tile[0] == 'counters')
  {
    Object.assign(el, { static: true })
  }
  else{
    Object.assign(el, { static: false })
  }
  if(el.s == 0)
  {
    var shwlayout='show-n';
  }
  else{
    var shwlayout='show-m';
  }
  //const k = el.add ? "+" : k;
  return (
      
    <div key={el.i} className={shwlayout} style={styles} data-grid={{ w:el.w, h: (el.h), x: el.x, y: el.y, minW: 2, minH: 3 } } >

      <span className="text f12">{title}</span>
      <span className="float-right" style={{cursor:"pointer",position:"absolute",top:"0",right:"0px",padding:"3px 8px", background:"#333",zIndex:"99",boxShadow:"0px 0px 8px #333"}} onClick={this.removeLayout.bind(this,el.i)}>X</span>
  
    </div>
  );
    
  }
  
  onResize(){
    window.dispatchEvent(new Event('resize'))
  }

  removeLayout(e){
    //console.log("Remove ",e);
    this.setState({ gitems: _.reject(this.state.gitems, { i: e }) });
      var allitems=[];
      for ( var item in this.state.gitems ) {
        if ( this.state.gitems[item].i === e) {
          allitems.push(this.state.gitems[item]);
        }
        else{
          
        }
      }
     // console.log("allgitems", allgitems);
      this.onUserRemoveComponent(allitems[0]);
  }
  onUserRemoveComponent=(e) => {
	 // console.log("User Remvie", e);
	  const ldata={
				userid:localStorage.getItem('userid'),
				layout: e.i
		}
	//	console.log("user remove", ldata);
	  redirectURL.post('/layouts/remove', ldata)
		  .then( (response) => {
			
		//	console.log(response);
		    window.location.reload();
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
  }
  onDragStop(e,l,c){
    console.log(e)
		var allitems=[];
		var data = e;		
		for(var n=0;n<data.length;n++)
		{
			var title=data[n].i.toUpperCase().split("_");
			allitems[n]=data[n];
      allitems[n]['t']=title[3];
      allitems[n]['s']=title[4];
		}
      while(lyts.length > 0) {
        lyts.pop();
      }
      this.state.gitems=allitems;
      lyts.push(allitems);
     
    }


  onResizeStart(e){
    for (let t1 = 0; t1 <= 100; t1 += 10) {
      setTimeout(() => {
          window.dispatchEvent(new Event('resize'))
        }, t1)
      }
  }

onResizeStop(e,l, a){
  var allitems=[];
  var data = e;
  
  for(var n=0;n<data.length;n++)
  {
    var title=data[n].i.toUpperCase().split("_");
    
    allitems[n]=data[n];
    allitems[n]['t']=title[3];
    allitems[n]['s']=title[4];
  }
    while(lyts.length > 0) {
      lyts.pop();
    }
    this.state.gitems=allitems;
    lyts.push(allitems);
       
  }

    onSaveLayout = (e) =>{
      e.stopPropagation();
     // console.log("Lays here ",lyts[0]);
      const layoutdata={
				userid:localStorage.getItem('userid'),
				layout: JSON.stringify(lyts[0]) 
      }
     //console.log("insid",layoutdata);  
      
	    redirectURL.post('/layouts/saveNewLayout', layoutdata,{
        headers:{
          'Content-Type':'application/json'
        }
      })
        .then(function (response) {
        
          if(response.data.message == 'Success')
          {
            //this.createElementViewport
            window.location.reload();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
	    
    }
    refrshNow(){
      //this.props.contextProps.onShowViewport();
      console.log("Here Refresh")
      window.location.reload();
  }
  handleClick = (e) => {
      e.stopPropagation();
      this.props.contextProps.onCloseViewport();
  };

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    });
  };
  
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  appendComponentHere =(e) =>{
	  //console.log(e.target.id+"_"+e.target.className+"_"+e.target.getAttribute('data-name')+"_"+e.target.getAttribute('name')+"_"+e.target.getAttribute('data-show'),"hereme");
	  this.onGlobalAddItem(e);
	  this.setState({
	      suggestions: [],
	      value:''
    });
    
  }
  onGlobalAddItem = (e) => {
    //"column_chart_TransporterChart_Transporter wise trucks with GPS Data"
    //console.log("Targets",e.target);
   //console.log(e.target.id+" , "+e.target.className+" ,"+e.target.getAttribute('data-name')+" ,"+e.target.getAttribute('name'))
   const p = this.props;
   var y = _.result(p, "y") || Math.ceil(Math.random() * 4) + 1;
   this.setState({
    gitems: this.state.gitems.concat({
       i: e.target.id+"_"+e.target.className+"_"+e.target.getAttribute('data-name')+"_"+e.target.getAttribute('name')+"_"+e.target.getAttribute('data-show'),
       x: (this.state.gitems.length * 2) % (this.state.cols || 12),
       //x:0,
       y: 12, // puts it at the bottom
       w: 12,
       h: 4,
       static:false,
       t:e.target.name,
       s:1,
       //dp:e.target.className
     }),
     newCounter: this.state.newCounter + 1
   });
  // console.log("stateitmes", this.state.gitems);
   var newarry = [];
   for(var nmi in this.state.gitems)
   {
     //console.log(nmi);
     newarry.push(this.state.gitems[nmi]);
   }

  //console.log("Array Checking before ",newarry);
   let itme={
          i: e.target.id+"_"+e.target.className+"_"+e.target.getAttribute('data-name')+"_"+e.target.getAttribute('name')+"_"+e.target.getAttribute('data-show'),
          x: (this.state.gitems.length * 2) % (this.state.cols || 12),
           //x:0,
           y: 12, // puts it at the bottom
           w: 12,
           h: 4,
           static:false,
           t:e.target.getAttribute('name'),
           s:1,
           //dp:e.target.className
         };
   newarry.push(itme);
         
  //this.onUserNewAddGridAction(newarry)
}


onUserNewAddGridAction=(e) => {
  var arr=[];
  for(var i in e)
  {
    //console.log(i+","+e[i])
    var chec = Array.isArray(e[i]);
    if(chec == false)
    {
      arr.push(e[i]);
    }
  }

  //console.log("chec", arr+", "+JSON.stringify(arr)); 
  const layoutdata={
      userid:localStorage.getItem('userid'),
      layout: JSON.stringify(arr),
      //newlayout:e
  }
  //console.log(layoutdata);
  
  redirectURL.post('/layouts/saveNewLayout', layoutdata,{
    headers:{
      'Content-Type':'application/json'
    }
  })
  .then(function (response) {
   // this.refrshNow;
   arr.map((item) => {
    // console.log(item)
    //this.createElementViewport(item)
   });
   
    console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });
  
  
}

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "Search",
      value,
      onChange: this.onChange
    };
    const renderSuggestion = suggestion => (
      <div id={suggestion.component_tag} data-show={suggestion.is_show} name={suggestion.component_name} data-name={suggestion.component_identity} className={suggestion.grid_type} onClick={(e) => { e.stopPropagation(); this.appendComponentHere(e); } }>
         {suggestion.component_name}
      </div>
    );
    return (
      <div>
          {(this.props.gridlayouts != '')?
            <div className="col-xl-12 col-lg-12">
                <div className="row form-group mt-10p">
                    <div className="col-xl-10 col-lg-10">
                    <Autosuggest 
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                      getSuggestionValue={getSuggestionValue}
                      renderSuggestion={renderSuggestion}
                      inputProps={inputProps} />
                    </div>
                  </div>
                <div className="col-xl-12 col-lg-12 inner-srcn">
                <ResponsiveReactGridLayout
                className="layout"
                cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
                rowHeight={10}
                onDragStop = {this.onDragStop}
                layouts={this.props.gridlayouts}
                useCSSTransforms={true}
                context = {this}
                onResizeStart = {this.onResizeStart}
                onResizeStop = {this.onResizeStop}
                onLayoutChange={(layout, layouts) =>
                    this.onLayoutChange(layout, layouts)
                }
                useCSSTransforms={true}
                  {...this.props}
                >
                {_.map(this.state.gitems, el => this.createElementViewport(el))}
                
                </ResponsiveReactGridLayout>

                    </div>
                   <button type="button" onClick={this.onSaveLayout.bind(this)} className="btn btn-success">Save Layout</button> 
                   <button type="button" onClick={this.handleClick.bind(this)} className="btn btn-default">Cancel</button> 
            
            </div>
            :''
        }
        
      </div>
    );
  }
}
function refrshNow(){
  //window.location.reload();

}

function getLists()
{
	var arr=[];
	redirectURL.post('/layouts/gridComponents')
	.then((response) => {
		var res = JSON.stringify(response);
    let citems = JSON.parse(res).data;
    //console.log("citems",citems);
		for(var n in citems)
		{
			arr.push(citems[n]);
		}
	});

	return arr;
}
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";


import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';

import Modal from 'react-responsive-modal';
import Counters from './railcountersComponent';
import DeliverCounters from './raildelivercountersComponent';
import DateFormater from './dateComponent';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD,getHyphenDDMMMYYYYHHMM, getYYYYMMDDHHMMSS, getDDMMMYYYYHHMMDefault, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import CSVFileValidator from 'csv-file-validator';
import CreatableSelect from 'react-select/creatable';
import Edittruckavailable from "./edittruckavailable";
import Deletetruckavailable from "./deletetruckavailable";
import Remarksaction from "./remarkstruckavailableaction";
import Repairaction from "./repairstruckavailableaction";
import TruckGrid from "./truckavailabilitygrid";
import CountUp from 'react-countup';
import { object } from 'prop-types';
import { ImageFilter9 } from 'material-ui/svg-icons';
import download from 'js-file-download';
//import * as XLSX from 'xlsx';

$.datetimepicker.setLocale('en');
 
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
//var rowdefs = []
export default class ContainerBookingSystem extends Component {

	constructor(props){
		super(props);
		this.displayData=[];
		this.state={
            requirment_remark : '',
            requirementrowId:"",
			pageTitle: "",
			deptcode:"",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
			eventAction: null,
			allRowData: [],
			activeFilter: null,
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			sliderBulkForceClose:"",
			tabsliderTranslate:'',
			timelineinfo:'',
			consignment_code:'',
			mapinfo:'',
            rowIdforApprove:[],
			dealer:'',
			maptruckno:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
			basicTitle:'',
			rownode:'',
			leg_no:'',
			basicType:"default",

			alert1:null,
            show1: false,
			basicTitle1:'',
			basicType1:"default",

			modules: AllModules,
			filterstatess:{},
      	    columnsdatas:{},
      	    rowgroupdcols:[],
			valuecolumns:[],
			activeconsignemnt:[],
			activetrucks:[],
			transitdelays:[],
			gpsdatana:[],
			overspeedtrucks:[],
			nightdrive:[],
			open: false,
			openforceclose: false,
      	    pivotmodeis:false,
			pivotcols:[],
			defTransitCoords:'',
			routeTruck:{}, 
			sidebarSubHeader:'',
			googelRoutes:'',
			plants:[],
            ff_details:[],
            cha_details:[],
            tpt_details:[],
            selectedFF:'',
            selectedCHA:'',
            selectedTPT:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true,
      	    },
      	    rowData: null,
			  maprowData:null,
			rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
                edittruckavailable:Edittruckavailable,
                deletetruckavailable:Deletetruckavailable
            },
            emptyframeworkcomponent:{
                Remarksaction:Remarksaction,
                Repairaction:Repairaction
            },

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
            rowModelType: "serverSide",
            headerHeight:60,
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				//"highlitRow": "data.gate_in_time != ''",
				//"green":"data.status >= 3"

			},
			screenpage:'',
			pagetitle:'',
			open:false,
            truckslist:[],
            truckcategories:[],
            truckcategory:{"value":"","label":""},
            truck:"",
            sliderTitle:"",
            container_requirement:[],
            slidertranslate:'',
            trucktype: 40,
            warehouse:"",
            tentitivetime:"",
			showTrucksList : "show-n",
            filteredData : [],
            startDate:"",
            endDate:"",
            defaultsDate:"",
            defaulteDate:"",
            rowid:"",
            trucktypeother:"show-n",
            othertrucktype:"",
            bulkslide:"",
            file:"",
            bulkslideforcontainer:"",
            csvcontent:'',
            categories:[],
            trucktypes:[],
            transporters:[],
            columns:[],
            rowdefs:[],
            rowloadedtruckdefs:[],
            pinnedRegionBottomRowData:[],
            marktabactive:"btn-success",
            emptytabactive:"",
            emptytabstatusactive:"",
            urgenttabstatus:'',
            markedshow:"show-m",
            emptyshow:"show-n",
            emptyrowData:[],
            urgentrowData:[],
            emptyplant:"",
            onetimeshow:"show-n",
            urgentshow:"show-n",
            emptyrowStatusData:[],
            usermanualmodal:false,
            overly:"show-m",
            loadshow:"show-m",
            loadtruckactive:"",
            loadtruckshow:"show-n",
            rowDataLoadedTrucks:[],
            startDateLoad:"",
            endDateLoad:"",
            auto_marked_by_system:[],
            loaded_auto_marked_by_system:[],
            waiting_for_unloading:0,
            unloadtruckactive:"",
            unloadtruckshow:"show-n",
            transportercode:[],
            unloadtrucksdata:[],
            lsp_name :"",
            lsp_email:"",
			onetimeid:"",
            bulkslide1:"",
            bulkslide6:'',
            bulkslide7:'',
            bulkslide10:'',
            bulkslide_Approve:"",
            onetimeDownloadBulkSlide:"",
            urgentDownloadBulkSlide:"",
            weeklyDownloadBulkSlide:"",
            bookingDownloadList:[],
            downlaodRowData : {},
			email: localStorage.getItem("email"),
            showOneTimeTab: true,
            showWeeklyTab: true,
            showUrgentTab: true

		}
		this.changeFileHandler = this.changeFileHandler.bind(this);
	}
	logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
	componentDidMount(){
        var pageUrl = this.props.match.path;
        console.log(pageUrl,"pageUrl")
        pageUrl = pageUrl.split("/");
        console.log(pageUrl,"pageUrlarray")
        var pageType = pageUrl[(pageUrl.length)-1];
        console.log(pageType,"pageType")
        this.onClickShowTabView(pageType);
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })        
        loadDateTimeScript();
        if(pageType == "weekly")
        {
            this.weeklyBookingData();
        }
        else
        {
            this.oneTimeBookingData();
        }  
        this.checkUserEmail()   
    };
    oneTimeBookingData = () => {
        redirectURL.post("/consignments/onetimebookingData").then((response)=>{
            console.log(response.data,"324")
            var resp = response.data
            this.setState({
                emptyrowData : resp,
                loadshow:"show-n",
                overly:"show-n"
            })
        })
    }

    urgentBookingData = () => {
        redirectURL.post("/consignments/ugrentbookingData").then((response)=>{
            console.log(response.data,"324")
            var resp = response.data
            this.setState({
                urgentrowData : resp,
                loadshow:"show-n",
                overly:"show-n"
            })
        })
    }

    weeklyBookingData = () => {
        redirectURL.post("/consignments/weeklytimebookingData").then((response)=>{
            console.log(response.data,"324")
            var resp = response.data
            this.setState({
                rowData : resp,
                loadshow:"show-n",
                overly:"show-n"
            })
        })
    }

     onCellRowClicked = e => {
        
        if(e.colDef.field == "container_requirement")
        {
            var container_requirement = JSON.parse(e.data.container_requirement);
            container_requirement.map(function(e){
                var containerDate = e.container_requirement_date;
                if(containerDate !=undefined && containerDate !='')
                {
                    if(containerDate.includes("T") == true)
                    {
                        containerDate = containerDate.split("T");
                        containerDate = containerDate[0];
                        containerDate = containerDate.split("-")[2]+"-"+containerDate.split("-")[1]+"-"+containerDate.split("-")[0];
                    }
                }
                e.container_requirement_date = containerDate;
            })
            var sliderTitle = e.data.requirement_id+" - Container Details";
            this.setState({
                overly :"show-m",
                container_requirement:container_requirement,
                invoiceData: [],
                bulkslideforcontainer : "slider-translate-40p",
                sliderTitle : sliderTitle
            })
        }
    }
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			slidertranslate:"",
			overly:'show-n',
			loadshow:'show-n'
		});
	}
	
	onCloseRouteDiv = () => {
		this.setState({
			slidertranslate:"",
			showDiv:'show-n',
			overly:'show-n'
		});
	}

	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
	}


	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            slidertranslate:'',
            bulkslide:"",
            bulkslideforcontainer:"",
            showTrucksList : "show-n",
            truck:{"value":"","label":""},
            truckcategory:{"value":"","label":""},
            warehouse:{"value":"","label":""},
            trucktype: 40,
            rowid:"",
			bulkslide1:"",
            bulkslide6:'',
            bulkslide10:'',
            bulkslide7:'',
            bulkslide_Approve:'',
            onetimeDownloadBulkSlide:"",
            urgentDownloadBulkSlide:"",
            weeklyDownloadBulkSlide:"",
            bookingDownloadList:[],
            downlaodRowData : {},

		});
		$("#uploadFile").val("")
	}
	closeAlert = async() => {
        await this.setState({
            show: false
        });
        $("#uploadFile").val("")
        //await window.location.reload();
	}
	changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
onClickShowFilterData(e){
    e.preventDefault()
    this.setState({
        loadshow:"show-m",
        overly:"show-m"
    })
    //var csvData =  this.state.csvcontent
    var csvData =  this.state.file
    var request_type = 2;
    var uploaded_by = localStorage.getItem("email")
    var requirement_id = moment.parseZone().format("YYYYMMDDHHmmss");
    let saveData = new FormData();
    saveData.append("csvData",csvData);
    saveData.append("request_type",request_type);
    saveData.append("uploaded_by",uploaded_by);
    saveData.append("requirement_id",requirement_id);
    redirectURL.post("/consignments/weeklyBulkupload",saveData,{
        headers:{
            'content-type': 'multipart/form-data',
            // 'Access-Control-Allow-Origin':"*",
            // 'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE',
            'Access-Control-Allow-Headers': 'Origin, Content-Type'
        }
    }).then((response)=>{
            if(response.data.message == "Success"){
            this.setState({
                show: true,
                basicTitle: response.data.message,
                basicType: "success",
                slidertranslate :"",
                loadshow:"show-n",
                overly:"show-n",
                file:""
            })            
            this.weeklyBookingData();
            $("#uploadFile").val("")
        }
        else{
            this.setState({
                show: true,
                basicTitle: response.data.message,
                basicType: "warning",
                slidertranslate :"",
                loadshow:"show-n",
                overly:"show-n",
                file:""
            })
            $("#uploadFile").val("")
        }
    })
}   

    onShowBulkUpload(){
		
		this.setState({
			bulkslide:"slider-translate",
			overly:'show-m'
		});
	}
    resetUpload = () => {
		this.setState({
			uploadDivWidth:'0%',
			bulkslide:'',
			showDiv:'show-n',
			overly:'show-n',
			file:''
		});
		document.getElementById("upform").reset();
    }
    
   
    changeFileHandler (e){
		this.setState({
			file:e.target.files[0]
		});
    }
    onCellRowClickedonetime = e => {

        if (e.colDef.field == "Download"){
            var ontimeDownloadList = []

            ontimeDownloadList.push({label:"DGD Uploaded File", value:"dgd_file_name"});
            ontimeDownloadList.push({label:"MSDS Uploaded File", value:"msds_file_name"});
            ontimeDownloadList.push({label:"IIP Uploaded File", value:"iip_file_name"});
            if(e.data.grw_file_name != undefined &&  e.data.grw_file_name != null && e.data.grw_file_name != ""){
                ontimeDownloadList.push({label:"GR Waiver File", value:"grw_file_name"});
            }
        
            var onetimeid = e.data._id
            this.setState({
                onetimeid : onetimeid,
                overly :"show-m",
                onetimeDownloadBulkSlide : "slider-translate-30p",
                bookingDownloadList:ontimeDownloadList,
                downlaodRowData : e.data
            })

        }
        if(e.colDef.field == "approve_requirement_onetime"){
            console.log(e.data,"617===onetime",e.colDef.field)
            // var param = {
            //     truck_no : e.value
            // }
           var ffDetalis=[]
           var chaDetalis=[]
          var  tptDetalis=[]
            redirectURL.post("/consignments/collectScreentype").then((response)=>{
                console.log(response.data,'responseCollection',response.data.ff_details.length)
                
                if(response.data.ff_details.length > 0){
                    response.data.ff_details.map((ele)=>{
                    ffDetalis.push({value:ele.short_name,label:ele.short_name +"-"+ele.name})
                })
            }
                if(response.data.cha_details.length > 0){

                    response.data.cha_details.map((ele)=>{
                        chaDetalis.push({value:ele.short_name,label:ele.short_name+"-"+ele.name})
                    })
                }
                if(response.data.tpt_details.length > 0){
                response.data.tpt_details.map((ele)=>{
                    tptDetalis.push({value:ele.short_name,label:ele.short_name+"-"+ele.name})
                })
            }
           this.setState({
            ff_details:ffDetalis,
            cha_details:chaDetalis,
            tpt_details:tptDetalis,
            rowIdforApprove:e.data,
            overly :"show-m",
            bulkslide_Approve : "slider-translate-30p",
           })
            })    

           



            // var params = e.data

            // redirectURL.post("/consignments/approverequirementforonetime",params).then((response)=>{
            //     if(response.data.message == "Success"){
            //         this.setState({
            //             show:true,
            //             basicTitle:"Approved",
            //             basicType:"success"
            //         })
            //         this.oneTimeBookingData()
            //     }
            // })
          }
        // if(e.colDef.field == "edit_button_ontime")
        // {
        //     let basestring = params.data._id;
        //         let encryptedstring = window.btoa(basestring);
        //         let url = "/onetimecontainerbookingform/" + encryptedstring;
        //         <a href={url} ></a>
        //     // console.log(e.coldef,'e.coldef',e.data)
        //     // var onetimeid = e.data._id
        //     // var shipping_on_board=e.data.shipping_on_board
        //     // var requirement_id = e.data.requirement_id
        //     // var container_no = e.data.container_no
        //     // var container_requirement_date = e.data.container_requirement_date
        //     // var container_type = e.data.container_type
        //     // var dispatch_location = e.data.dispatch_location
        //     // var container_size = e.data.container_size
        //     // var truck_no = e.data.truck_no
        //     // var date = e.data.date
        //     // var dispatch_port = e.data.dispatch_port
        //     // var destination_port = e.data.destination_port
        //     // var bkg = e.data.bkg
        //     // var vessel =e.data.vessel
        //     // var incoterms = e.data.incoterms

        //     console.log(onetimeid,e.data,"onetimeid")
        //     // this.setState({
        //     //     onetimeid : onetimeid,

        //     //     overly :"show-m",
        //     //     bulkslide6 : "slider-translate-30p",
        //     //     sob:shipping_on_board,
        //     //     requirement_id:requirement_id,
        //     //     container_no:container_no,
        //     //     container_requirement_date:container_requirement_date,
        //     //     container_type:container_type,
        //     //     dispatch_location:dispatch_location,
        //     //     container_size:container_size,
        //     //     truck_no:truck_no,
        //     //     incoterms:incoterms

        //     // })
        // }
        if(e.colDef.field == "dgd_file_name"){
            var fileName  = e.data.dgd_file_name
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimedgdfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "msds_file_name"){
            var fileName  = e.data.msds_file_name
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimemsdsfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "iip_file_name"){
            var fileName  = e.data.iip_file_name
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimeiipfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "grw_file_name"){
            var fileName  = e.data.grw_file_name
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimegrwfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "container_details_file"){
            var fileName  = e.data.container_details_file
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadweeklyffupdatesfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "ringi_data"){
                var onetimeid = e.data._id
                // console.log(onetimeid,"onetimeid")
                this.setState({
                    onetimeid : onetimeid,
                    overly :"show-m",
                    loadshow : "show-m",
                    bulkslide1 : "slider-translate-30p",
                })
                var params ={
                    onetimeid : onetimeid,
                    request_type : 1,
                }
                redirectURL.post("/consignments/getRingiData",params).then((response)=>{
                    console.log(response.data.data)
                    if(response.data.message == "Success"){
                        console.log("check")
                       
                            console.log("check-2")
                            if(typeof(response.data.data) == "string")
                            {
                                var resp = JSON.parse(response.data.data)
                            }
                            else
                            {
                                var resp = response.data.data
                            }
                            console.log(resp,"584")
                            this.setState({
                                unloadtrucksdata : resp,
                                loadshow : "show-n",
                            })
                    }else{
                            this.setState({
                                unloadtrucksdata : [],
                                loadshow : "show-n",
                            })
                    }
                })
        }
        // if(e.colDef.field == "approve_requirement_onetime"){
        //     console.log(e.data,"617")

        //     this.setState({
        //         overly :"show-m",
        //         bulkslide_Approve : "slider-translate-30p",
        //     })


        //     // var params = e.data

        //     // redirectURL.post("/consignments/approverequirementforonetime",params).then((response)=>{
        //     //     if(response.data.message == "Success"){
        //     //         this.setState({
        //     //             show:true,
        //     //             basicTitle:"Approved",
        //     //             basicType:"success"
        //     //         })
        //     //         this.oneTimeBookingData()
        //     //     }
        //     // })
        //   }
          if(e.colDef.field == "reject_requirement_onetime"){
             console.log(e.data,"620")
             var params = e.data
            redirectURL.post("/consignments/rejectrequirementforonetime",params).then((response)=>{
                this.setState({
                    show:true,
                    basicTitle:"Rejected",
                    basicType:"success"
                })
                this.oneTimeBookingData()
            })
          }
    }
    onCellRowClickedweekly = e => {
        

        if(e.colDef.field == "weekly_update_files")
        {
            var weeklyid = e.data._id
            // console.log(weeklyid,"weeklyid")
            // console.log(e.params)
            this.setState({
                weeklyid : weeklyid,
                overly :"show-m",
                bulkslide2 : "slider-translate-30p",
            })
        }
        if(e.colDef.field == "container_details_file"){
            var fileName  = e.data.container_details_file
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadweeklyffupdatesfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "approve_requirement_weekly"){
          console.log(e.data,"649") 
          var ffDetalis=[]
          var chaDetalis=[]
         var  tptDetalis=[]
           redirectURL.post("/consignments/collectScreentype").then((response)=>{
               console.log(response.data,'responseCollection',response.data.ff_details.length)
               
               if(response.data.ff_details.length > 0){
                   response.data.ff_details.map((ele)=>{
                   ffDetalis.push({value:ele.short_name,label:ele.short_name +"-"+ele.name})
               })
           }
               if(response.data.cha_details.length > 0){

                   response.data.cha_details.map((ele)=>{
                       chaDetalis.push({value:ele.short_name,label:ele.short_name+"-"+ele.name})
                   })
               }
               if(response.data.tpt_details.length > 0){
               response.data.tpt_details.map((ele)=>{
                   tptDetalis.push({value:ele.short_name,label:ele.short_name+"-"+ele.name})
               })
           }
          this.setState({
           ff_details:ffDetalis,
           cha_details:chaDetalis,
           tpt_details:tptDetalis,
           rowIdforApprove:e.data,
           overly :"show-m",
           bulkslide_Approve : "slider-translate-30p",
          })
           })    

        }
        if(e.colDef.field == "reject_requirement_weekly"){
           console.log(e.data,"652")
           var params = e.data
          redirectURL.post("/consignments/rejectrequirementforweekly",params).then((response)=>{
            this.setState({
                show:true,
                basicTitle:"Rejected",
                basicType:"success"
            })
            this.weeklyBookingData()
          })
        }
    }
    onCellRowClickedUrgnet = e => {

        if (e.colDef.field == "Download"){
            console.log("entered download section......")
            var urgentDownloadList = []

            urgentDownloadList.push({label:"DGD Uploaded File", value:"dgd_file_name"});
            urgentDownloadList.push({label:"MSDS Uploaded File", value:"msds_file_name"});
            urgentDownloadList.push({label:"IIP Uploaded File", value:"iip_file_name"});
            if(e.data.grw_file_name != undefined &&  e.data.grw_file_name != null && e.data.grw_file_name != ""){
                urgentDownloadList.push({label:"GR Waiver File", value:"grw_file_name"});
            }
        
            var onetimeid = e.data._id
            this.setState({
                onetimeid : onetimeid,
                overly :"show-m",
                urgentDownloadBulkSlide : "slider-translate-30p",
                bookingDownloadList:urgentDownloadList,
                downlaodRowData : e.data
            })

        }
        if(e.colDef.field == "requirement_remark"){
            console.log(e.colDef)
            this.setState({
                overly :"show-m",
                requirementrowId:e.colDef._id,
                 bulkslide10 : "slider-translate-30p",
            })
        }
        if(e.colDef.field == "approve_requirement_urgent"){
            console.log(e.data,"617",e.colDef.field)
            // var param = {
            //     truck_no : e.value
            // }
            var ffDetalis=[]
            var chaDetalis=[]
           var  tptDetalis=[]
             redirectURL.post("/consignments/collectScreentype").then((response)=>{
                 console.log(response.data,'responseCollection',response.data.ff_details.length)
                 
                 if(response.data.ff_details.length > 0){
                     response.data.ff_details.map((ele)=>{
                     ffDetalis.push({value:ele.short_name,label:ele.short_name +"-"+ele.name})
                 })
             }
                 if(response.data.cha_details.length > 0){
 
                     response.data.cha_details.map((ele)=>{
                         chaDetalis.push({value:ele.short_name,label:ele.short_name+"-"+ele.name})
                     })
                 }
                 if(response.data.tpt_details.length > 0){
                 response.data.tpt_details.map((ele)=>{
                     tptDetalis.push({value:ele.short_name,label:ele.short_name+"-"+ele.name})
                 })
             }
            this.setState({
             ff_details:ffDetalis,
             cha_details:chaDetalis,
             tpt_details:tptDetalis,
             rowIdforApprove:e.data,
             overly :"show-m",
             bulkslide_Approve : "slider-translate-30p",
            })
             })    
 


            // var params = e.data

            // redirectURL.post("/consignments/approverequirementforonetime",params).then((response)=>{
            //     if(response.data.message == "Success"){
            //         this.setState({
            //             show:true,
            //             basicTitle:"Approved",
            //             basicType:"success"
            //         })
            //         this.oneTimeBookingData()
            //     }
            // })
          }
        // if(e.colDef.field == "edit_button_ontime")
        // {
        //     let basestring = params.data._id;
        //         let encryptedstring = window.btoa(basestring);
        //         let url = "/onetimecontainerbookingform/" + encryptedstring;
        //         <a href={url} ></a>
        //     // console.log(e.coldef,'e.coldef',e.data)
        //     // var onetimeid = e.data._id
        //     // var shipping_on_board=e.data.shipping_on_board
        //     // var requirement_id = e.data.requirement_id
        //     // var container_no = e.data.container_no
        //     // var container_requirement_date = e.data.container_requirement_date
        //     // var container_type = e.data.container_type
        //     // var dispatch_location = e.data.dispatch_location
        //     // var container_size = e.data.container_size
        //     // var truck_no = e.data.truck_no
        //     // var date = e.data.date
        //     // var dispatch_port = e.data.dispatch_port
        //     // var destination_port = e.data.destination_port
        //     // var bkg = e.data.bkg
        //     // var vessel =e.data.vessel
        //     // var incoterms = e.data.incoterms

        //     console.log(onetimeid,e.data,"onetimeid")
        //     // this.setState({
        //     //     onetimeid : onetimeid,

        //     //     overly :"show-m",
        //     //     bulkslide6 : "slider-translate-30p",
        //     //     sob:shipping_on_board,
        //     //     requirement_id:requirement_id,
        //     //     container_no:container_no,
        //     //     container_requirement_date:container_requirement_date,
        //     //     container_type:container_type,
        //     //     dispatch_location:dispatch_location,
        //     //     container_size:container_size,
        //     //     truck_no:truck_no,
        //     //     incoterms:incoterms

        //     // })
        // }
        if(e.colDef.field == "dgd_file_name"){
            var fileName  = e.data.dgd_file_name
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimedgdfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "msds_file_name"){
            var fileName  = e.data.msds_file_name
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimemsdsfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "iip_file_name"){
            var fileName  = e.data.iip_file_name
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimeiipfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "grw_file_name"){
            var fileName  = e.data.grw_file_name
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimegrwfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "container_details_file"){
            var fileName  = e.data.container_details_file
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadweeklyffupdatesfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "ringi_data"){
                var onetimeid = e.data._id
                // console.log(onetimeid,"onetimeid")
                this.setState({
                    onetimeid : onetimeid,
                    overly :"show-m",
                    loadshow : "show-m",
                    bulkslide1 : "slider-translate-30p",
                })
                var params ={
                    onetimeid : onetimeid,
                    request_type : 3,
                }
                redirectURL.post("/consignments/getRingiData",params).then((response)=>{
                    console.log(response.data.data)
                    if(response.data.message == "Success"){
                        console.log("check")
                       
                            console.log("check-2")
                            if(typeof(response.data.data) == "string")
                            {
                                var resp = JSON.parse(response.data.data)
                            }
                            else
                            {
                                var resp = response.data.data
                            }
                            console.log(resp,"584")
                            this.setState({
                                unloadtrucksdata : resp,
                                loadshow : "show-n",
                            })
                    }else{
                            this.setState({
                                unloadtrucksdata : [],
                                loadshow : "show-n",
                            })
                    }
                })
        }
        // if(e.colDef.field == "approve_requirement_onetime"){
        //     console.log(e.data,"617")

        //     this.setState({
        //         overly :"show-m",
        //         bulkslide_Approve : "slider-translate-30p",
        //     })


        //     // var params = e.data

        //     // redirectURL.post("/consignments/approverequirementforonetime",params).then((response)=>{
        //     //     if(response.data.message == "Success"){
        //     //         this.setState({
        //     //             show:true,
        //     //             basicTitle:"Approved",
        //     //             basicType:"success"
        //     //         })
        //     //         this.oneTimeBookingData()
        //     //     }
        //     // })
        //   }
          if(e.colDef.field == "reject_requirement_urgent"){
             console.log(e.data,"620")
             var params = e.data
            redirectURL.post("/consignments/rejectrequirementforonetime",params).then((response)=>{
                this.setState({
                    show:true,
                    basicTitle:"Rejected",
                    basicType:"success"
                })
                this.oneTimeBookingData()
            })
          }
    }
    onCellRowClickedRingiData = e =>{
        if(e.colDef.field == "download_ringi_file")
        {
            var fileName  = e.data.ringi_file
            console.log(e.data,"692")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadRingiDataFiles",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
    }
    onClickShowTabView(tab){
        if(tab == "weekly")
        {
            this.setState({
                marktabactive:"btn-success",
                emptytabactive:"",
                emptytabstatusactive:"",
                urgenttabstatus:'',
                loadtruckactive:"",
                unloadtruckactive:"",
                markedshow:"show-m",
                emptyshow:"show-n",
                onetimeshow:"show-n",
                 urgentshow:"show-n",
                loadtruckshow:"show-n",
                unloadtruckshow:"show-n"
            })
            this.weeklyBookingData();
        }
        if(tab == "onetime")
        {
            this.setState({
                marktabactive:"",
                emptytabactive:"",
                loadtruckactive:"",
                unloadtruckactive:"",
                emptytabstatusactive:"btn-success",
                urgenttabstatus:'',
                markedshow:"show-n",
                emptyshow:"show-n",
                onetimeshow:"show-m",
                urgentshow:"show-n",
                loadtruckshow:"show-n",
                unloadtruckshow:"show-n"
            })
            this.oneTimeBookingData();
        }   
        if(tab == "urgent")
        {
            this.setState({
                marktabactive:"",
                emptytabactive:"",
                loadtruckactive:"",
                unloadtruckactive:"",
                emptytabstatusactive:"",
                markedshow:"show-n",
                emptyshow:"show-n",
                onetimeshow:"show-n",
                urgenttabstatus:"btn-success",
                urgentshow:'show-m',
                loadtruckshow:"show-n",
                unloadtruckshow:"show-n"
            })
            this.urgentBookingData();
        }   
    }

    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };
    downloadTemplate = () => {
        redirectURL.post('/consignments/getTemplateForExportsWeeklyRequirement',{})
        .then((response) => {
            console.log(response.data);
            let fetchDataModified = `data:application/xlsx;base64,${response.data}`;
            let a = document.createElement("a");
            a.href = fetchDataModified;
            a.download = 'template.xlsx';
            a.click();
        });
    }
    onClickShowAvailabilityForm(){
        this.setState({
            slidertranslate:"slider-translate-30p",
            overly:"show-m"
        })
    }
    handleInputChange = (e) => {
        console.log(e,'e value')
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
    }

    onClickUpdateonetimeEdit(e){
        e.preventDefault()
        this.setState({
            overly :"show-m",
            loadshow:"show-m"
        })
        var onetimeid = this.state.onetimeid
        // var updated_by = localStorage.getItem("email")
        // var shipping_on_board = this.state.sob.value

        // var requirement_id = $("#requirement_id").val()
        var  container_no= $("#container_no").val()
        // var  container_requirement_date= $("#bulkUploadID").val()
        // var   container_type= this.state.container_type
        // var  dispatch_location= this.state.dispatch_location
        // var  container_size= this.state.container_size
        // var  truck_no= this.state.truck_no
        // var  incoterms= $("#incoterms").val()

        var params = {
            // shipping_on_board : shipping_on_board,
            // updated_by : updated_by,
            onetimeid : onetimeid,

            // requirement_id:requirement_id,
        container_no:container_no,
        // container_requirement_date:container_requirement_date,
        // container_type:container_type,
        // dispatch_location:dispatch_location,
        // container_size:container_size,
        // truck_no:truck_no,
        // incoterms:incoterms

        }
        console.log(params,'paramsparams')
        // if(shipping_on_board != "" && shipping_on_board != undefined && shipping_on_board != null){
            redirectURL.post("/consignments/editonetimedata",params).then((response)=>{
                console.log(response,'response--')
                if(response.statusText == "OK"){
                            this.setState({
                                overly :"show-n",
                                loadshow:"show-n",
                                bulkslide6 : "",
                                show:true,
                                container_type:"",
                                basicTitle:"Data Uploaded Successfully",
                                basicType:'success',
                                onetimeid:'',
                                sob:"",
                                requirement_id:'',
                                container_no:'',
                                incoterms:'',
                            })
                            $("#incoterms").val()
                            $("#container_no").val()
                            $("#requirement_id").val()
                            onetimeid = ""
                            this.oneTimeBookingData()
                        }
                else{
                    this.setState({
                        overly :"show-n",
                        loadshow:"show-n",
                        bulkslide6 : "",
                        show:true,
                        container_type:"",
                        basicTitle:"Data Not Updated",
                        basicType:'warning'
                    })
                }
            })
        // }
        // else{
        //     this.setState({
        //         overly :"show-n",
        //                 loadshow:"show-n",
        //         show:true,
        //         basicTitle:"All Fields are Mandatory",
        //         basicType:'warning'
        //     })
        // }   
    }
    onClickUpdateWeeklyEdit(e){
        e.preventDefault()
        this.setState({
            overly :"show-m",
            loadshow:"show-m"
        })
        var onetimeid = this.state.onetimeid
        // var updated_by = localStorage.getItem("email")
        // var shipping_on_board = this.state.sob.value

        // var requirement_id = $("#requirement_id").val()
        var  container_no= $("#container_no").val()
        // var  container_requirement_date= $("#bulkUploadID").val()
        // var   container_type= this.state.container_type
        // var  dispatch_location= this.state.dispatch_location
        // var  container_size= this.state.container_size
        // var  truck_no= this.state.truck_no
        // var  incoterms= $("#incoterms").val()

        var params = {
            // shipping_on_board : shipping_on_board,
            // updated_by : updated_by,
            onetimeid : onetimeid,

            // requirement_id:requirement_id,
        container_no:container_no,
        // container_requirement_date:container_requirement_date,
        // container_type:container_type,
        // dispatch_location:dispatch_location,
        // container_size:container_size,
        // truck_no:truck_no,
        // incoterms:incoterms

        }
        console.log(params,'paramsparams')
        // if(shipping_on_board != "" && shipping_on_board != undefined && shipping_on_board != null){
            redirectURL.post("/consignments/editweeklydata",params).then((response)=>{
                console.log(response,'response--')
                if(response.statusText == "OK"){
                            this.setState({
                                overly :"show-n",
                                loadshow:"show-n",
                                bulkslide7 : "",
                                show:true,
                                container_type:"",
                                basicTitle:"Data Uploaded Successfully",
                                basicType:'success',
                                onetimeid:'',
                                sob:"",
                                requirement_id:'',
                                container_no:'',
                                incoterms:'',
                            })
                            $("#incoterms").val()
                            $("#container_no").val()
                            $("#requirement_id").val()
                            onetimeid = ""
                            this.weeklyBookingData()
                        }
                else{
                    this.setState({
                        overly :"show-n",
                        loadshow:"show-n",
                        bulkslide7 : "",
                        show:true,
                        container_type:"",
                        basicTitle:"Data Not Updated",
                        basicType:'warning'
                    })
                }
            })
        // }
        // else{
        //     this.setState({
        //         overly :"show-n",
        //                 loadshow:"show-n",
        //         show:true,
        //         basicTitle:"All Fields are Mandatory",
        //         basicType:'warning'
        //     })
        // }   
    }

    handleSelectChange (e,name) {
        console.log(e,'name',name)
        this.setState({
            [e]:name.value
        });
    }
    onClickUpdateApproveStatus (event){
        this.setState({
            loadshow:"show-m",
            overly:"show-m",
        })
    event.preventDefault()
        var params = {
            ff_details : this.state.selectedFF,
            cha_details : this.state.selectedCHA,
            tpt_details : this.state.selectedTPT,
            is_approved:1,
            rowId:this.state.rowIdforApprove,
        }
        console.log(this.state.selectedFF,'this.state.selectedFF1')
        console.log(params,'params==')
        if (
            params.ff_details != '' &&
            params.cha_details != '' &&
            params.tpt_details != '' 
        ) {
    redirectURL.post('/consignments/approverequirementforonetime',params)
    .then((response) => {
        this.setState({
            basicTitle:"Successfully Updated",
            basicType : "success",
            show : true,
            bulkslide_Approve : "",
            loadshow:"show-n",
            overly:"show-n",
            selectedFF:'',
            selectedCHA:'',
            selectedTPT:''
        })
       
        this.setState({
            selectedFF: '',
            selectedCHA: '',
            selectedTPT: '',
        });
     
        window.location.reload()
        this.componentDidMount()
    })
} else {
    alert("Fields should not be Empty");
    this.setState({
        loadshow: "show-n",
        overly: "show-n",
    });
}
    }

    requirmentRemark =(e)=>{
        this.setState({
            requirment_remark:e,
        })
    }

    onClickUrgentDownloadBulkSlide(field){
        this.setState({
            overly :"show-m",
            loadshow:"show-m"
        })
        const rowData = this.state.downlaodRowData

        if(field != "" && field != undefined && field != null){

            if(field == "dgd_file_name"){
                var fileName  = rowData.dgd_file_name
                // console.log(fileName,"496")
                if(fileName != ""){
                    var reqparams ={
                        uploadOtherFileName : fileName,
                    }
                    redirectURL.post("/consignments/downloadonetimedgdfile",reqparams,{
                        responseType: 'blob'
                    }).then((response)=>{
                        // console.log("download response ",response)
                        download(response.data, fileName)
                        if (response.status == 200){
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"File Downloaded Successfully",
                                basicType:'success'})
                        }else{
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"Something Went Wrong",
                                basicType:'warning'
                            })
                        }
                    
                       
                    }).catch(function(error){
                        console.log(error);
                        this.setState({
                            overly :"show-n",
                                    loadshow:"show-n",
                            show:true,
                            basicTitle:"Something Went Wrong",
                            basicType:'warning'
                        })
                    })
                }
            }
            if(field == "msds_file_name"){
                var fileName  = rowData.msds_file_name
                // console.log(fileName,"496")
                if(fileName != ""){
                    var reqparams ={
                        uploadOtherFileName : fileName,
                    }
                    redirectURL.post("/consignments/downloadonetimemsdsfile",reqparams,{
                        responseType: 'blob'
                    }).then((response)=>{
                        // console.log("download response ",response)
                        download(response.data, fileName)
                        if (response.status == 200){
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"File Downloaded Successfully",
                                basicType:'success'})
                        }else{
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"Something Went Wrong",
                                basicType:'warning'
                            })
                        }
                    
                       
                    }).catch(function(error){
                        console.log(error);
                        this.setState({
                            overly :"show-n",
                                    loadshow:"show-n",
                            show:true,
                            basicTitle:"Something Went Wrong",
                            basicType:'warning'
                        })
                    })
                }
            }
            if(field == "iip_file_name"){
                var fileName  = rowData.iip_file_name
                // console.log(fileName,"496")
                if(fileName != ""){
                    var reqparams ={
                        uploadOtherFileName : fileName,
                    }
                    redirectURL.post("/consignments/downloadonetimeiipfile",reqparams,{
                        responseType: 'blob'
                    }).then((response)=>{
                        // console.log("download response ",response)
                        download(response.data, fileName)
                        if (response.status == 200){
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"File Downloaded Successfully",
                                basicType:'success'})
                        }else{
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"Something Went Wrong",
                                basicType:'warning'
                            })
                        }
                    
                       
                    }).catch(function(error){
                        console.log(error);
                        this.setState({
                            overly :"show-n",
                                    loadshow:"show-n",
                            show:true,
                            basicTitle:"Something Went Wrong",
                            basicType:'warning'
                        })
                    })
                }
            }
            if(field == "grw_file_name"){
                var fileName  = rowData.grw_file_name
                // console.log(fileName,"496")
                if(fileName != ""){
                    var reqparams ={
                        uploadOtherFileName : fileName,
                    }
                    redirectURL.post("/consignments/downloadonetimegrwfile",reqparams,{
                        responseType: 'blob'
                    }).then((response)=>{
                        // console.log("download response ",response)
                        download(response.data, fileName)
                        if (response.status == 200){
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"File Downloaded Successfully",
                                basicType:'success'})
                        }else{
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"Something Went Wrong",
                                basicType:'warning'
                            })
                        }
                    
                       
                    }).catch(function(error){
                        console.log(error);
                        this.setState({
                            overly :"show-n",
                                    loadshow:"show-n",
                            show:true,
                            basicTitle:"Something Went Wrong",
                            basicType:'warning'
                        })
                    })
                }
            }	
   
        }else{
            this.setState({
                overly :"show-n",
                        loadshow:"show-n",
                show:true,
                basicTitle:"Something Went Wrong",
                basicType:'warning'
            })      
        }
    }
    onClickOnetimeDownloadBulkSlide(field){
        this.setState({
            overly :"show-m",
            loadshow:"show-m"
        })
        const rowData = this.state.downlaodRowData

        if(field != "" && field != undefined && field != null){

            if(field == "dgd_file_name"){
                var fileName  = rowData.dgd_file_name
                // console.log(fileName,"496")
                if(fileName != ""){
                    var reqparams ={
                        uploadOtherFileName : fileName,
                    }
                    redirectURL.post("/consignments/downloadonetimedgdfile",reqparams,{
                        responseType: 'blob'
                    }).then((response)=>{
                        // console.log("download response ",response)
                        download(response.data, fileName)
                        if (response.status == 200){
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"File Downloaded Successfully",
                                basicType:'success'})
                        }else{
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"Something Went Wrong",
                                basicType:'warning'
                            })
                        }
                       
                    }).catch(function(error){
                        console.log(error);
                        this.setState({
                            overly :"show-n",
                                    loadshow:"show-n",
                            show:true,
                            basicTitle:"Something Went Wrong",
                            basicType:'warning'
                        })
                    })
                }
            }
            if(field == "msds_file_name"){
                var fileName  = rowData.msds_file_name
                // console.log(fileName,"496")
                if(fileName != ""){
                    var reqparams ={
                        uploadOtherFileName : fileName,
                    }
                    redirectURL.post("/consignments/downloadonetimemsdsfile",reqparams,{
                        responseType: 'blob'
                    }).then((response)=>{
                        // console.log("download response ",response)
                        download(response.data, fileName)
                        if (response.status == 200){
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"File Downloaded Successfully",
                                basicType:'success'})
                        }else{
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"Something Went Wrong",
                                basicType:'warning'
                            })
                        }
                       
                    }).catch(function(error){
                        console.log(error);
                        this.setState({
                            overly :"show-n",
                                    loadshow:"show-n",
                            show:true,
                            basicTitle:"Something Went Wrong",
                            basicType:'warning'
                        })
                    })
                }
            }
            if(field == "iip_file_name"){
                var fileName  = rowData.iip_file_name
                // console.log(fileName,"496")
                if(fileName != ""){
                    var reqparams ={
                        uploadOtherFileName : fileName,
                    }
                    redirectURL.post("/consignments/downloadonetimeiipfile",reqparams,{
                        responseType: 'blob'
                    }).then((response)=>{
                        // console.log("download response ",response)
                        download(response.data, fileName)
                        if (response.status == 200){
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"File Downloaded Successfully",
                                basicType:'success'})
                        }else{
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"Something Went Wrong",
                                basicType:'warning'
                            })
                        }
                       
                    }).catch(function(error){
                        console.log(error);
                        this.setState({
                            overly :"show-n",
                                    loadshow:"show-n",
                            show:true,
                            basicTitle:"Something Went Wrong",
                            basicType:'warning'
                        })
                    })
                }
            }
            if(field == "grw_file_name"){
                var fileName  = rowData.grw_file_name
                // console.log(fileName,"496")
                if(fileName != ""){
                    var reqparams ={
                        uploadOtherFileName : fileName,
                    }
                    redirectURL.post("/consignments/downloadonetimegrwfile",reqparams,{
                        responseType: 'blob'
                    }).then((response)=>{
                        // console.log("download response ",response)
                        download(response.data, fileName)
                        if (response.status == 200){
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"File Downloaded Successfully",
                                basicType:'success'})
                        }else{
                            this.setState({
                                overly :"show-n",
                                        loadshow:"show-n",
                                show:true,
                                basicTitle:"Something Went Wrong",
                                basicType:'warning'
                            })
                        }
                       
                    }).catch(function(error){
                        console.log(error);
                        this.setState({
                            overly :"show-n",
                                    loadshow:"show-n",
                            show:true,
                            basicTitle:"Something Went Wrong",
                            basicType:'warning'
                        })
                    })
                }
            }
        }else{
            this.setState({
                overly :"show-n",
                        loadshow:"show-n",
                show:true,
                basicTitle:"Something Went Wrong",
                basicType:'warning'
            })      
        }

    }

    onClickSubmitRequirmentRemark(e){
        e.preventDefault()
        var weeklyid = this.state.requirementrowId
        var updated_by = localStorage.getItem("email")
        var requirment_remark = this.state.requirment_remark.value
        var params = {
            requirment_remark : requirment_remark,
            updated_by : updated_by,
            weeklyid : weeklyid
        }
        console.log(weeklyid,updated_by,requirment_remark)
        if(requirment_remark != "" && requirment_remark != undefined && requirment_remark != null){
            redirectURL.post("/consignments/requirmentremarkedit",params).then((response)=>{
                if(response.data.message == "Success"){
                            this.setState({
                                overly :"show-n",
                                overly :"show-n",
                        loadshow:"show-n",
                                bulkslide10 : "",
                                requirment_remark:"",
                                show:true,
                                basicTitle:"Data Uploaded Successfully",
                                basicType:'success'
                            })
                            this.urgentBookingData();
                        }
                else{
                    this.setState({
                        overly :"show-n",
                        loadshow:"show-n",
                        bulkslide10 : "",
                        requirment_remark:"",
                        show:true,
                        basicTitle:"Data Not Updated",
                        basicType:'warning'
                    })
                }
            })
        }
        else{
            this.setState({
                overly :"show-n",
                        loadshow:"show-n",
                show:true,
                basicTitle:"All Fields are Mandatory",
                basicType:'warning'
            })
        }   
    }

    checkUserEmail() {
        const { email } = this.state;
        console.log('insideeee',email)
        if (email === "EBMS_weekly@maruti.co.in" || email === "EBMS_weekly[at]maruti[dot]co.in") {
          this.setState({
            showOneTimeTab: false,
            showWeeklyTab: true,
            showUrgentTab: false,
            marktabactive:"btn-success",
            emptytabactive:"",
            emptytabstatusactive:"",
            urgenttabstatus:'',
            loadtruckactive:"",
            unloadtruckactive:"",
            markedshow:"show-m",
            emptyshow:"show-n",
            onetimeshow:"show-n",
             urgentshow:"show-n",
            loadtruckshow:"show-n",
            unloadtruckshow:"show-n"
        })
        this.weeklyBookingData();
        } else if (email === "EBMS_ontime@maruti.co.in" || email === "EBMS_ontime[at]maruti[dot]co.in") {
          this.setState({
            showOneTimeTab: true,
            showWeeklyTab: false,
            showUrgentTab: false,
            marktabactive:"",
            emptytabactive:"",
            loadtruckactive:"",
            unloadtruckactive:"",
            emptytabstatusactive:"btn-success",
            urgenttabstatus:'',
            markedshow:"show-n",
            emptyshow:"show-n",
            onetimeshow:"show-m",
            urgentshow:"show-n",
            loadtruckshow:"show-n",
            unloadtruckshow:"show-n"
        })
        this.oneTimeBookingData();
        } else if (email === "EBMS_urgent@maruti.co.in" || email === "EBMS_urgent[at]maruti[dot]co.in") {
          this.setState({
            showOneTimeTab: false,
            showWeeklyTab: false,
            showUrgentTab: true,
            marktabactive:"",
            emptytabactive:"",
            loadtruckactive:"",
            unloadtruckactive:"",
            emptytabstatusactive:"",
            markedshow:"show-n",
            emptyshow:"show-n",
            onetimeshow:"show-n",
            urgenttabstatus:"btn-success",
            urgentshow:'show-m',
            loadtruckshow:"show-n",
            unloadtruckshow:"show-n"
        })
        this.urgentBookingData();
        } else {
          this.setState({
            showOneTimeTab: true,
            showWeeklyTab: true,
            showUrgentTab: true
          });
        }
        const { showOneTimeTab, showWeeklyTab, showUrgentTab } = this.state;
        // console.log(showOneTimeTab, showWeeklyTab, showUrgentTab ,'22222showOneTimeTab, showWeeklyTab, showUrgentTab ')
      }

    render(){
        const { showOneTimeTab, showWeeklyTab, showUrgentTab } = this.state;
        // console.log(showOneTimeTab, showWeeklyTab, showUrgentTab ,'showOneTimeTab, showWeeklyTab, showUrgentTab ')
        const modalStyles  = {
            width:'1300px !important',
        }
        const {bookingDownloadList} = this.state
        const {usermanualmodal} = this.state;

		const { open } = this.state;
        if(localStorage.getItem("user_type") == "TRANSPORTER")
        {
            var showopts = false
            var showdelete = false
        }
        else{
            var showopts = false
            showdelete = false
        }
        var showAcceptandreject = ""
        if(localStorage.getItem("username")=="Rathod AnandSingh" || localStorage.getItem("username")=="Khushal Pahuja" || localStorage.getItem("username")=="Ritesh kumarSingh" || localStorage.getItem("username")=="ASHISH." || localStorage.getItem("username")=="Hiten Chauhan" || localStorage.getItem("username")=="SandeepMathur" || localStorage.getItem("username")=="Aakash Jeengar" || localStorage.getItem("username")=="Raghvendra Gupta" || localStorage.getItem("username")=="Sudeep Kumar" || localStorage.getItem("username")=="Nanda Kishore" || localStorage.getItem("username")=="TarunKumar" || localStorage.getItem("username")=="NitinGoel" ){
            showAcceptandreject = false
        }else{
            showAcceptandreject =true
        }
        var columnDefs = [
            {
                headerName:"",
                field:"",
                colId:"_id",
                pinned:'left',
                width:80,
                // hide:showforInprogress,
                cellRenderer:function(params){
                    let basestring = params.data._id;
                    let encryptedstring = window.btoa(basestring);
                    if((params.data.booking_details_issued!=1) && 
                    (params.data.is_approved==1 )){
                        var htmloption = '<a href="#" onclick="return false;" class="custom-btn label label-success disabled-anchor-edit"><i class="icofont icofont-edit"></i> Edit</a>';
                        // console.log("success",params.data._id,params.data.requirement_id)
                    }else{
                        var htmloption = '<a href="/editweeklycontainerbookingform/'+encryptedstring+'" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
                    }
                    return htmloption;
                }
            },
            {
                headerName:"Requirement Id",
                field:"requirement_id",
                width:140
            },
            {
                headerName:"Container No",
                field:"container_no",
                width:180
            },
            {
                headerName:"Container Requirement Date",
                field:"container_requirement_date",
                width:180,
                valueGetter:function(params){
                    try{
                        if(params.data.container_requirement_date)
                        {
                            return getHyphenDDMMMYYYY(params.data.container_requirement_date);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Stuffing Location",
                field:"dispatch_location",
                width:140
            },
            {
                headerName:"Country",
                field:"destination_country",
                width:140,
            },
            {
                headerName:"Container Type",
                field:"container_type",
                width:140
            },
            {
                headerName:"Container Size",
                field:"container_size",
                width:120,
            },
            // {
            //     headerName:"Container Requirement",
            //     field:"container_requirement",
            //     width:120,
            // },
            {
                headerName:"Vehicle No",
                field:"truck_no",
                width:180
            },
            {
                headerName:"Port Of Destination",
                field:"destination_port",
                width:220,
            },
            {
                headerName:"BKG",
                field:"bkg",
                width:140
            },
            {
                headerName:"Vessel",
                field:"vessel",
                width:140
            },
            {
                headerName:"Estimated Time of Dispatch",
                field:"etd",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.etd)
                        {
                            return getHyphenDDMMMYYYY(params.data.etd);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Pickup Location",
                field:"pickup_location",
                width:140
            },
            {
                headerName:"Incoterms",
                field:"incoterms",
                width:220,
            },
          
           
            // {
            //     headerName:"FF Uploaded File",
            //     field:"container_details_file",
            //     width:180,
            //     cellRenderer: function(params){
            //         let basestring = params.data.batch_name;
            //         let encryptedstring = window.btoa(basestring);
            //         var htmloption = '<button class="btn btn-info" style="padding-top:0px">Download</button>';
            //         return htmloption;
            //     },
            // },
        ]
        if(localStorage.getItem("username")=="Rathod AnandSingh" || localStorage.getItem("username")=="Khushal Pahuja" || localStorage.getItem("username")=="Ritesh kumarSingh" || localStorage.getItem("username")=="ASHISH." || localStorage.getItem("username")=="Hiten Chauhan" || localStorage.getItem("username")=="SandeepMathur" || localStorage.getItem("username")=="Aakash Jeengar" || localStorage.getItem("username")=="Raghvendra Gupta" || localStorage.getItem("username")=="Nanda Kishore" || localStorage.getItem("username")=="TarunKumar"|| localStorage.getItem("username")=="Sudeep Kumar" || localStorage.getItem("username")=="NitinGoel" ){
            columnDefs.splice(14,0,  {
                headerName:"Approve Requirement",
                pinned:'left',
                field:"approve_requirement_weekly",
                width:130,
                // hide:showAcceptandreject,
                cellRenderer: function(params){
                    if(params.data.is_approved == 0){
                        var htmloption = '<button  cursor:"pointer" style="height: 24px; width:100px; background: #1aa81a1a 0% 0% no-repeat padding-box; border: 1.3px solid #1ab01a; border-radius: 10px; opacity: 1; text-align: center; font-family: \'Poppins-Regular\'; letter-spacing: 0px; color: #16af0d; padding: 0px; display:flex; align-items:center; justify-content:center;">Approve</button>';
                        
                        return htmloption;
                    }else{
                        var htmloption = '<button disabled="on"  cursor:"pointer" style="height: 24px; width:100px;  background: #1aa81a1a 0% 0% no-repeat padding-box; border: 1.3px solid #1ab01a; border-radius: 10px; opacity: 1; text-align: center; font-family: \'Poppins-Regular\'; letter-spacing: 0px; color: #16af0d; padding: 0px; display:flex; align-items:center; justify-content:center;">Approve</button>';
                        return htmloption;
                    }
                    
                },
                cellClass: function(params) {
                    if  (params.data.is_approved == 0){
                        return '';
                    } else {
                        
                        return 'disabled-cell';
                    }
                },
            },
            {
                headerName:"Reject Requirement",
                pinned:'left',
                field:"reject_requirement_weekly",
                width:130,
                // hide:showAcceptandreject,
                cellRenderer: function(params){
                    if(params.data.is_approved == 0){
                        var htmloption = '<button  cursor:"pointer" style="height: 24px; width:100px; background: #9005051e 0% 0% no-repeat padding-box; border: 1.3px solid #cd2a1e; border-radius: 8px; opacity: 1; text-align: center; font-family: \'Poppins-Regular\'; letter-spacing: 0px; color: #883535; padding: 0px; display:flex; align-items:center; justify-content:center;">Reject</button>';
                        return htmloption;
                    }else{
                        var htmloption = '<button disabled="on" cursor:"pointer" style="height: 24px; width:100px; background: #9005051a 0% 0% no-repeat padding-box; border: 1.3px solid #cd2a1e; border-radius: 8px; opacity: 1; text-align: center; font-family: \'Poppins-Regular\'; letter-spacing: 0px; color: #883535; padding: 0px; display:flex; align-items:center; justify-content:center;">Reject</button>';
                        return htmloption;
                    }
                },
                cellClass: function(params) {
                    if  (params.data.is_approved == 0){
                        return '';
                    } else {
                        
                        return 'disabled-cell';
                    }
                },
                
            },)
           }
        var listData = [] 
        this.state.filteredData.map(function(e){
            listData.push(<li className="dropdown-truck-no">{e}</li>)
        })
        var user = localStorage.getItem("user_type")
        if(user == "TRANSPORTER")
        {
            var showcol = false
        }
        else{
            var showcol = true
        }
        var showAcceptandreject = ""
        if(localStorage.getItem("username")=="Rathod AnandSingh" || localStorage.getItem("username")=="Khushal Pahuja" || localStorage.getItem("username")=="Ritesh kumarSingh" || localStorage.getItem("username")=="ASHISH." || localStorage.getItem("username")=="Hiten Chauhan" || localStorage.getItem("username")=="SandeepMathur" || localStorage.getItem("username")=="Aakash Jeengar"|| localStorage.getItem("username")=="Raghvendra Gupta" || localStorage.getItem("username")=="Nanda Kishore" || localStorage.getItem("username")=="TarunKumar"|| localStorage.getItem("username")=="Sudeep Kumar" || localStorage.getItem("username")=="NitinGoel"){
            showAcceptandreject = false
        }else{
            showAcceptandreject = true
        }


        var emptyColumnDefs = [
            {
                headerName:"",
                field:"",
                colId:"_id",
                pinned:'left',
                width:80,
                // hide:showforInprogress,
                cellRenderer:function(params){
                    let basestring = params.data._id;
                    let encryptedstring = window.btoa(basestring);
                    console.log(params.data.booking_details_issued,"1773")
                        if((params.data.booking_details_issued!=1) && 
                        (params.data.is_approved==1 )){
                            var htmloption = '<a href="#" onclick="return false;" class="custom-btn label label-success disabled-anchor-edit"><i class="icofont icofont-edit"></i> Edit</a>';
                            // console.log("success",params.data._id,params.data.requirement_id)
                        }else{
                            // console.log("failing",params.data._id,params.data.requirement_id)
                            var htmloption = '<a href="/editonetimecontainerbookingform/'+encryptedstring+'" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
                   
                        }
                    
                    return htmloption;
                }
            },
            // downloads
            {
                headerName:"",
                headerTooltip:"Download",
                statusValues:{},
                field:"Download",
                width:40,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<span title="Download" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                    return htmloption;
                },
            },
            {
                headerName:"DGD Uploaded File",
                field:"dgd_file_name",
                width:180,
                hide:true,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<button class="btn btn-info" style="padding-top:0px">Download</button>';
                    return htmloption;
                },
            },
            {
                headerName:"MSDS Uploaded File",
                field:"msds_file_name",
                width:180,
                hide:true,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<button class="btn btn-info" style="padding-top:0px">Download</button>';
                    return htmloption;
                },
            },
            {
                headerName:"IIP Uploaded File",
                field:"iip_file_name",
                width:180,
                hide:true,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<button class="btn btn-info" style="padding-top:0px">Download</button>';
                    return htmloption;
                },
            },
            {
                headerName:"GR Waiver File",
                field:"grw_file_name",
                width:180,
                hide:true,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    if(params.data.grw_file_name != undefined &&  params.data.grw_file_name != null && params.data.grw_file_name != ""){
                        var htmloption = '<button class="btn btn-info" style="padding-top:0px">Download</button>';
                        return htmloption;
                    }else{
                        var htmloption = '<button class="btn btn-info" disabled="on" style="padding-top:0px">Download</button>';
                        return htmloption;
                    }
                    
                },
            },
            {
                headerName:"Ringi Files",
                field:"ringi_data",
                width:130,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<button cursor:"pointer" style="height: 24px; width: 100px; background: #d2d4ff 0% 0% no-repeat padding-box; border: 0px solid #000bea; border-radius: 10px; opacity: 1; text-align: center; font-family: \'Poppins-Regular\'; font-size: 13px; letter-spacing: 0px; color: #191f95; padding: 0px; display:flex; align-items:center; justify-content:center;">Ringi Data</button>';
                    return htmloption;
                },
            },

            {
                headerName:"Requirement Id",
                field:"requirement_id",
                width:140
            },
            {
                headerName:"Container No",
                field:"container_no",
                width:180
            },
            {
                headerName:"Container Requirement Date",
                field:"container_requirement_date",
                width:200,
                valueGetter:function(params){
                    try{
                        if(params.data.container_requirement_date)
                        {
                            return getHyphenDDMMMYYYY(params.data.container_requirement_date);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Container Type",
                field:"container_type",
                width:140
            },
            {
                headerName:"Dispatch Location ",
                field:"dispatch_location",
                width:140,
            },
            {
                headerName:"Container Size(in ft)",
                field:"container_size",
                width:160,
            },
            // {
            //     headerName:"Container Requirement",
            //     field:"container_requirement",
            //     width:160,
            // },
            {
                headerName:"Vehicle No",
                field:"truck_no",
                width:180
            },
            {
                headerName:"Date",
                field:"date",
                width:180,
                valueGetter:function(params){
                    try{
                        if(params.data.date)
                        {
                            return getHyphenDDMMMYYYY(params.data.date);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Department Name",
                field:"department_name",
                width:180
            },
            {
                headerName:"Intender Name",
                field:"intender_name",
                width:180
            },
            {
                headerName:"Mobile Number",
                field:"mobile_number",
                width:180
            },
            {
                headerName:"Intender Mails",
                field:"intenderMails",
                width:180
            },
            {
                headerName:"Dispatch Port",
                field:"dispatch_port",
                width:140,
            },
            {
                headerName:"Destination Port",
                field:"destination_port",
                width:140
            },
            // {
            //     headerName:"Dispatch Date ",
            //     field:"dispatch_date",
            //     width:140,
            //     valueGetter:function(params){
            //         try{
            //             if(params.data.dispatch_date)
            //             {
            //                 return getHyphenDDMMMYYYY(params.data.dispatch_date);
            //             }
            //         }
            //         catch(e){
            //             return "";
            //         }
            //     }
            // },
            {
                headerName:"MRD at Destination",
                field:"mrd_date",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.mrd_date)
                        {
                            return getHyphenDDMMMYYYY(params.data.mrd_date);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"BKG",
                field:"bkg",
                width:140
            },
            {
                headerName:"Vessel",
                field:"vessel",
                width:140
            },
            {
                headerName:"Estimated Time of Dispatch",
                field:"etd",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.etd)
                        {
                            return getHyphenDDMMMYYYY(params.data.etd);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Pickup Location",
                field:"pickup_location",
                width:140
            },
            {
                headerName:"Incoterms",
                field:"incoterms",
                width:140,
            },
            {
                headerName:"Manual Incoterms",
                field:"manualincoterms",
                width:220,
                valueGetter:function(params){
                    try{
                        if(params.data.manualincoterms != '' && params.data.manualincoterms != undefined && params.data.manualincoterms != 'undefined' && params.data.manualincoterms != null)
                        {
                            return (params.data.manualincoterms);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Payment Terms",
                field:"payment_terms",
                width:140
            },
            {
                headerName:"Certificate of Origin",
                field:"certificate_of_origin",
                width:140
            },
            {
                headerName:"FTA(Free Trade Agreement) Required",
                field:"fta_required",
                width:170
            },
            {
                headerName:"Export Incentive",
                field:"export_incentive",
                width:140
            },
            {
                headerName:"Remarks",
                field:"remarks",
                width:140,
            },
            // {
            //     headerName:"FF Uploaded File",
            //     field:"container_details_file",
            //     width:180,
            //     cellRenderer: function(params){
            //         let basestring = params.data.batch_name;
            //         let encryptedstring = window.btoa(basestring);
            //         var htmloption = '<button class="btn btn-info" style="padding-top:0px">Download</button>';
            //         return htmloption;
            //     },
            // },

        ];
        if(localStorage.getItem("username")=="Rathod AnandSingh" || localStorage.getItem("username")=="Khushal Pahuja"  || localStorage.getItem("username")=="Ritesh kumarSingh" || localStorage.getItem("username")=="ASHISH." || localStorage.getItem("username")=="Hiten Chauhan" || localStorage.getItem("username")=="SandeepMathur" || localStorage.getItem("username")=="Aakash Jeengar" || localStorage.getItem("username")=="Raghvendra Gupta" || localStorage.getItem("username")=="Nanda Kishore" || localStorage.getItem("username")=="TarunKumar"|| localStorage.getItem("username")=="Sudeep Kumar" || localStorage.getItem("username")=="NitinGoel"){
          
            emptyColumnDefs.splice(25,0,  {
                headerName:"Approve Requirement",
                field:"approve_requirement_onetime",
                width:130,
                pinned:'left',
                // hide:showAcceptandreject,
                cellRenderer: function(params){
                    if(params.data.is_approved == 0){
                        var htmloption = '<button  cursor:"pointer" style="height: 24px; width:100px; background: #1aa81a1a 0% 0% no-repeat padding-box; border: 1.3px solid #1ab01a; border-radius: 10px; opacity: 1; text-align: center; font-family: \'Poppins-Regular\'; letter-spacing: 0px; color: #16af0d; padding: 0px; display:flex; align-items:center; justify-content:center;">Approve</button>';
                        return htmloption;
                    }else{
                        var htmloption = '<button disabled="on"  cursor:"pointer" style="height: 24px; width:100px;  background: #1aa81a1a 0% 0% no-repeat padding-box; border: 1.3px solid #1ab01a; border-radius: 10px; opacity: 1; text-align: center; font-family: \'Poppins-Regular\'; letter-spacing: 0px; color: #16af0d; padding: 0px; display:flex; align-items:center; justify-content:center;">Approve</button>';
                        return htmloption;
                    }
                    
                },
                cellClass: function(params) {
                    if  (params.data.is_approved == 0){
                        return '';
                    } else {
                        
                        return 'disabled-cell';
                    }
                },
            },
            {
                headerName:"Reject Requirement",
                field:"reject_requirement_onetime",
                width:130,
                pinned:'left',
                // hide:showAcceptandreject,
                cellRenderer: function(params){
                    if(params.data.is_approved == 0){
                        var htmloption = '<button  cursor:"pointer" style="height: 24px; width:100px; background: #9005051e 0% 0% no-repeat padding-box; border: 1.3px solid #cd2a1e; border-radius: 8px; opacity: 1; text-align: center; font-family: \'Poppins-Regular\'; letter-spacing: 0px; color: #883535; padding: 0px; display:flex; align-items:center; justify-content:center;">Reject</button>';
                        return htmloption;
                    }else{
                        var htmloption = '<button disabled="on" cursor:"pointer" style="height: 24px; width:100px; background: #9005051a 0% 0% no-repeat padding-box; border: 1.3px solid #cd2a1e; border-radius: 8px; opacity: 1; text-align: center; font-family: \'Poppins-Regular\'; letter-spacing: 0px; color: #883535; padding: 0px; display:flex; align-items:center; justify-content:center;">Reject</button>';
                        return htmloption;
                    }
                },
                cellClass: function(params) {
                    if  (params.data.is_approved == 0){
                        return '';
                    } else {
                        
                        return 'disabled-cell';
                    }
                },
            },)
           }


           
        var UrgentColumnDefs = [
            {
                headerName:"",
                field:"",
                colId:"_id",
                pinned:'left',
                width:80,
                // hide:showforInprogress,
                cellRenderer:function(params){
                    let basestring = params.data._id;
                    let encryptedstring = window.btoa(basestring);
                   
                    if((params.data.booking_details_issued!=1) && 
                    (params.data.is_approved==1 )){
                        var htmloption = '<a href="#" onclick="return false;" class="custom-btn label label-success disabled-anchor-edit"><i class="icofont icofont-edit"></i> Edit</a>';
                        // console.log("success",params.data._id,params.data.requirement_id)
                    }else{
                        var htmloption = '<a href="/editurgentcontainerbookingform/'+encryptedstring+'" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
                    }
                    return htmloption;
                }
            },
            // {
            //     headerName:"Requirment Remark",
            //     field:"requirement_remark",
            //     width:180,
            //     // hide:showforPending,
            //     cellRenderer: function(params){
            //                 var htmloption = '<button class="btn btn-warning" style="padding-top:0px">Remark</button>';
            //                 return htmloption;
            //             },
            // },

            // downloads
            {
                headerName:"DGD Uploaded File",
                field:"dgd_file_name",
                width:180,
                hide:true,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<button class="btn btn-info" style="padding-top:0px">Download</button>';
                    return htmloption;
                },
            },
            {
                headerName:"MSDS Uploaded File",
                field:"msds_file_name",
                width:180,
                hide:true,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<button class="btn btn-info" style="padding-top:0px">Download</button>';
                    return htmloption;
                },
            },
            {
                headerName:"IIP Uploaded File",
                field:"iip_file_name",
                width:180,
                hide:true,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<button class="btn btn-info" style="padding-top:0px">Download</button>';
                    return htmloption;
                },
            },
            {
                headerName:"GR Waiver File",
                field:"grw_file_name",
                width:180,
                hide:true,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    if(params.data.grw_file_name != undefined &&  params.data.grw_file_name != null && params.data.grw_file_name != ""){
                        var htmloption = '<button class="btn btn-info" style="padding-top:0px">Download</button>';
                        return htmloption;
                    }else{
                        var htmloption = '<button class="btn btn-info" disabled="on" style="padding-top:0px">Download</button>';
                        return htmloption;
                    }
                    
                },
            },
            {
                headerName:"",
                headerTooltip:"Download",
                statusValues:{},
                field:"Download",
                width:40,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<span title="Download" style="cursor:pointer;" class="tooltip-container"><i class="fa fa-download booking-download-btn"></i></span>';
                    return htmloption;
                },
            },
            {
                headerName:"Ringi Files",
                field:"ringi_data",
                width:130,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<button cursor:"pointer" style="height: 24px; width: 100px; background: #d2d4ff 0% 0% no-repeat padding-box; border: 0px solid #000bea; border-radius: 10px; opacity: 1; text-align: center; font-family: \'Poppins-Regular\'; font-size: 13px; letter-spacing: 0px; color: #191f95; padding: 0px; display:flex; align-items:center; justify-content:center;">Ringi Data</button>';
                    return htmloption;
                },
            },
            {
                headerName:"Requirement Id",
                field:"requirement_id",
                width:140
            },
            {
                headerName:"Container No",
                field:"container_no",
                width:180
            },
            {
                headerName:"Container Requirement Date",
                field:"container_requirement_date",
                width:200,
                valueGetter:function(params){
                    try{
                        if(params.data.container_requirement_date)
                        {
                            return getHyphenDDMMMYYYY(params.data.container_requirement_date);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Container Type",
                field:"container_type",
                width:140
            },
            {
                headerName:"Dispatch Location ",
                field:"dispatch_location",
                width:140,
            },
            {
                headerName:"Container Size(in ft)",
                field:"container_size",
                width:160,
            },
            // {
            //     headerName:"Container Requirement",
            //     field:"container_requirement",
            //     width:160,
            // },
            {
                headerName:"Vehicle No",
                field:"truck_no",
                width:180
            },
            {
                headerName:"Date",
                field:"date",
                width:180,
                valueGetter:function(params){
                    try{
                        if(params.data.date)
                        {
                            return getHyphenDDMMMYYYY(params.data.date);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Department Name",
                field:"department_name",
                width:180
            },
            {
                headerName:"Intender Name",
                field:"intender_name",
                width:180
            },
            {
                headerName:"Mobile Number",
                field:"mobile_number",
                width:180
            },
            {
                headerName:"Intender Mails",
                field:"intenderMails",
                width:180
            },
            {
                headerName:"Dispatch Port",
                field:"dispatch_port",
                width:140,
            },
            {
                headerName:"Destination Port",
                field:"destination_port",
                width:140
            },
            // {
            //     headerName:"Dispatch Date ",
            //     field:"dispatch_date",
            //     width:140,
            //     valueGetter:function(params){
            //         try{
            //             if(params.data.dispatch_date)
            //             {
            //                 return getHyphenDDMMMYYYY(params.data.dispatch_date);
            //             }
            //         }
            //         catch(e){
            //             return "";
            //         }
            //     }
            // },
            {
                headerName:"MRD at Destination",
                field:"mrd_date",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.mrd_date)
                        {
                            return getHyphenDDMMMYYYY(params.data.mrd_date);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"BKG",
                field:"bkg",
                width:140
            },
            {
                headerName:"Vessel",
                field:"vessel",
                width:140
            },
            {
                headerName:"Estimated Time of Dispatch",
                field:"etd",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.etd)
                        {
                            return getHyphenDDMMMYYYY(params.data.etd);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Pickup Location",
                field:"pickup_location",
                width:140
            },
            {
                headerName:"Incoterms",
                field:"incoterms",
                width:140,
            },
            {
                headerName:"Manual Incoterms",
                field:"manualincoterms",
                width:220,
                valueGetter:function(params){
                    try{
                        if(params.data.manualincoterms != '' && params.data.manualincoterms != undefined && params.data.manualincoterms != 'undefined' && params.data.manualincoterms != null)
                        {
                            return (params.data.manualincoterms);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Payment Terms",
                field:"payment_terms",
                width:140
            },
            {
                headerName:"Certificate of Origin",
                field:"certificate_of_origin",
                width:140
            },
            {
                headerName:"FTA(Free Trade Agreement) Required",
                field:"fta_required",
                width:170
            },
            {
                headerName:"Export Incentive",
                field:"export_incentive",
                width:140
            },
            {
                headerName:"Remarks",
                field:"remarks",
                width:140,
            },
            {
                headerName:"Requirment Remark",
                field:"requirment_type_remark",
                width:120,
            },
            // {
            //     headerName:"FF Uploaded File",
            //     field:"container_details_file",
            //     width:180,
            //     cellRenderer: function(params){
            //         let basestring = params.data.batch_name;
            //         let encryptedstring = window.btoa(basestring);
            //         var htmloption = '<button class="btn btn-info" style="padding-top:0px">Download</button>';
            //         return htmloption;
            //     },
            // },

        ];
        if(localStorage.getItem("username")=="Rathod AnandSingh" || localStorage.getItem("username")=="Khushal Pahuja"  || localStorage.getItem("username")=="Ritesh kumarSingh" || localStorage.getItem("username")=="ASHISH." || localStorage.getItem("username")=="Hiten Chauhan" || localStorage.getItem("username")=="SandeepMathur" || localStorage.getItem("username")=="Aakash Jeengar" || localStorage.getItem("username")=="Raghvendra Gupta" || localStorage.getItem("username")=="Nanda Kishore"  || localStorage.getItem("username")=="TarunKumar" || localStorage.getItem("username")=="Sudeep Kumar" || localStorage.getItem("username")=="NitinGoel"){
          
            UrgentColumnDefs.splice(25,0,  {
                headerName:"Approve Requirement",
                field:"approve_requirement_urgent",
                width:130,
                pinned:'left',
                // hide:showAcceptandreject,
                cellRenderer: function(params){
                    if(params.data.is_approved == 0){
                        var htmloption = '<button  cursor:"pointer" style="height: 24px; width:100px; background: #1aa81a1a 0% 0% no-repeat padding-box; border: 1.3px solid #1ab01a; border-radius: 10px; opacity: 1; text-align: center; font-family: \'Poppins-Regular\'; letter-spacing: 0px; color: #16af0d; padding: 0px; display:flex; align-items:center; justify-content:center;">Approve</button>';
                        return htmloption;
                    }else{
                        var htmloption = '<button disabled="on"  cursor:"pointer" style="height: 24px; width:100px;  background: #1aa81a1a 0% 0% no-repeat padding-box; border: 1.3px solid #1ab01a; border-radius: 10px; opacity: 1; text-align: center; font-family: \'Poppins-Regular\'; letter-spacing: 0px; color: #16af0d; padding: 0px; display:flex; align-items:center; justify-content:center;">Approve</button>';
                        return htmloption;
                    }
                    
                },
                cellClass: function(params) {
                    if  (params.data.is_approved == 0){
                        return '';
                    } else {
                        
                        return 'disabled-cell';
                    }
                },
				
            },
            {
                headerName:"Reject Requirement",
                field:"reject_requirement_urgent",
                width:130,
                pinned:'left',
                // hide:showAcceptandreject,
                cellRenderer: function(params){
                    if(params.data.is_approved == 0){
                        var htmloption = '<button  cursor:"pointer" style="height: 24px; width:100px; background: #9005051e 0% 0% no-repeat padding-box; border: 1.3px solid #cd2a1e; border-radius: 8px; opacity: 1; text-align: center; font-family: \'Poppins-Regular\'; letter-spacing: 0px; color: #883535; padding: 0px; display:flex; align-items:center; justify-content:center;">Reject</button>';
                        return htmloption;
                    }else{
                        var htmloption = '<button disabled="on" cursor:"pointer" style="height: 24px; width:100px; background: #9005051a 0% 0% no-repeat padding-box; border: 1.3px solid #cd2a1e; border-radius: 8px; opacity: 1; text-align: center; font-family: \'Poppins-Regular\'; letter-spacing: 0px; color: #883535; padding: 0px; display:flex; align-items:center; justify-content:center;">Reject</button>';
                        return htmloption;
                    }
                },
                cellClass: function(params) {
                    if  (params.data.is_approved == 0){
                        return '';
                    } else {
                        
                        return 'disabled-cell';
                    }
                },
				
                
            },)
           }
        // var onetimeColumnDefs  = [
        //     {
        //         headerName:"",
        //         field:"_id",
        //         width:50,
        //         hide:showopts,
        //         cellRendererSelector:function(params){
                    
        //             var rendComponent = {
        //                 component: 'deletetruckavailable'
        //             };
        //             return rendComponent
        //         }
        //     },
            
        //     {
        //         headerName: "",
        //         field: "revert",
        //         width: 80,
        //         cellStyle: {fontWeight: 'bold',cursor: 'pointer'},
        //         valueGetter: function(params){
        //             if(params.data.remarks_status == "delete")
        //             {                        
        //                 return "Revert";
        //             }
        //             else
        //             {
        //                 return "";
        //             }
        //         }
        //     },
        //     {
        //         headerName: "Remarks",
        //         field: "remarks_status",
        //         width: 140
        //     },
        //     {
        //         headerName:"Truck No",
        //         field:"truck_no",
        //         width:140
        //     },
        //     {
        //         headerName:"Ware House Location",
        //         field:"warehouse_text",
        //         width:140
        //     },
        //     {
        //         headerName:"Transporter Name",
        //         field:"transporter_name",
        //         width:140
        //     },
        //     {
        //         headerName:"Tentative Time",
        //         field:"tentitivetime",
        //         width:140,
        //         valueGetter:function(params){
        //             try{
        //                 if(params.data.tentitivetime)
        //                 {
        //                     return getHyphenDDMMMYYYYHHMM(params.data.tentitivetime);
        //                 }
        //             }
        //             catch(e){
        //                 return "";
        //             }
        //         }
        //     },
        //     {
        //         headerName:"Created On",
        //         field:"created_on",
        //         width:140,
        //         valueGetter:function(params){
        //             try{
        //                 if(params.data.created_on)
        //                 {
        //                     return getHyphenDDMMMYYYYHHMM(params.data.created_on);
        //                 }
        //             }
        //             catch(e){
        //                 return "";
        //             }
        //         }
        //     },
        //     {
        //         headerName:"Updated On",
        //         field:"updated_on",
        //         width:140,
        //         valueGetter:function(params){
        //             try{
        //                 if(params.data.updated_on)
        //                 {
        //                     return getHyphenDDMMMYYYYHHMM(params.data.updated_on);
        //                 }
        //             }
        //             catch(e){
        //                 return "";
        //             }
        //         }
        //     }
        // ]
        
        var columnLoadedTrucksDefs = [
            
            {
                headerName:"Ringi No",
                field:"ringi_desc",
                width:180,
                
            },
            {
                headerName:"Ringi File",
                field:"download_ringi_file",
                width:180,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<button class="btn btn-info" style="padding-top:0px">Download</button>';
                    return htmloption;
                },
            },
            
        ]
		return(
			<div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">   
                    {/* <div className="col-xl-12 col-lg-12 d-flex">
                        
                        <div className={`d-flex ${this.state.emptytabstatusactive != "" ? "align-items-center booking-active-button " : " align-items-end booking-inactive-button"}`} onClick={this.onClickShowTabView.bind(this,"onetime")}>
                            <p className={`d-flex ${this.state.emptytabstatusactive != "" ? "booking-paragraph-active" : "booking-paragraph-inactive"}`} style={{margin:"0px"}}>One Time Requirement</p>
                        </div>
                        <div  className={`d-flex ${this.state.marktabactive != "" ? "align-items-center booking-active-button " : " align-items-end booking-inactive-button"}`}  onClick={this.onClickShowTabView.bind(this,"weekly")}>
                            <p className={`d-flex ${this.state.marktabactive != "" ? "booking-paragraph-active" : "booking-paragraph-inactive"}`} style={{margin:"0px"}}>Weekly Requirement</p>
                        </div>
                        <div  className={`d-flex ${this.state.urgenttabstatus != "" ? "align-items-center booking-active-button " : " align-items-end booking-inactive-button"}`} onClick={this.onClickShowTabView.bind(this,"urgent")}>
                            <p className={`d-flex ${this.state.urgenttabstatus != "" ? "booking-paragraph-active" : "booking-paragraph-inactive"}`}  style={{margin:"0px"}}>Urgent Container Requirement</p>
                        </div>
                    </div> */}
                     <div className="col-xl-12 col-lg-12" style={{display:'flex',justifyContent:'space-between'}}>
                       <div style={{display:'flex'}}>
                        {showOneTimeTab && (
                        <div
                            className={`d-flex ${this.state.emptytabstatusactive !== "" ? "align-items-center booking-active-button " : "align-items-end booking-inactive-button"}`}
                            onClick={this.onClickShowTabView.bind(this, "onetime")}
                        >
                            <p className={`d-flex ${this.state.emptytabstatusactive !== "" ? "booking-paragraph-active" : "booking-paragraph-inactive"}`} style={{ margin: "0px" }}>
                            One Time Requirement
                            </p>
                        </div>
                        )}
                        {showWeeklyTab && (
                        <div
                            className={`d-flex ${this.state.marktabactive !== "" ? "align-items-center booking-active-button " : "align-items-end booking-inactive-button"}`}
                            onClick={this.onClickShowTabView.bind(this, "weekly")}
                        >
                            <p className={`d-flex ${this.state.marktabactive !== "" ? "booking-paragraph-active" : "booking-paragraph-inactive"}`} style={{ margin: "0px" }}>
                            Weekly Requirement
                            </p>
                        </div>
                        )}
                        {showUrgentTab && (
                        <div
                            className={`d-flex ${this.state.urgenttabstatus !== "" ? "align-items-center booking-active-button " : "align-items-end booking-inactive-button"}`}
                            onClick={this.onClickShowTabView.bind(this, "urgent")}
                        >
                            <p className={`d-flex ${this.state.urgenttabstatus !== "" ? "booking-paragraph-active" : "booking-paragraph-inactive"}`} style={{ margin: "0px" }}>
                            Urgent Container Requirement
                            </p>
                        </div>
                        )}
                      </div>
                      <div style={{display:'flex',flexDirection:'row-reverse',marginBottom:'7px'}}>
                        <a className="btn" style={{backgroundColor:'#171c8f',color:'white',borderRadius:'5px'}}
                            href="/exportcontainers"
                        >Back</a>
                    </div>
                    </div>
                    <div className={"col-xl-12 col-lg-12 "+(this.state.markedshow)} style={{borderRadius:"10px"}}>
                        <div className="card" style={{borderRadius:"10px"}}>                            
                            <div className="card-header p-2"style={{borderRadius:"10px"}}>
                                <div className='d-flex flex-row justify-content-between align-items-center p-1'>
                                        <div className='d-flex flex-row'>
                                            <i className="icofont icofont-vehicle-delivery-van cus-i" style={{color:"#171c8f"}}></i>
                                            <h1 style={{color:"#171c8f",fontWeight:"600", paddingTop:"1px",fontSize:"16px",marginBottom:"0px",paddingLeft:"4px"}}>Weekly Requirement</h1>                                   
                                        </div>
                                        <div>
                                            <span className="float-right f12">
                                                <button type="button"  className="create-booking-button" onClick={this.onClickShowAvailabilityForm.bind(this)}>Upload Requirement</button>
                                            </span>
                                        </div>
                                </div>
                            </div>
                            <div className="card-body pt-15px">
                                <div id= "booking-ag-grid" className="row" style={{borderRadius:"10px"}}>
                                    <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            onCellClicked={this.onCellRowClickedweekly}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
                                            
                                        />
                                        
                                       
                                    </div> 
                                   
                                       
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className={"col-xl-12 col-lg-12 "+(this.state.onetimeshow)} style={{borderRadius:"10px"}}>
                        <div className="card" style={{borderRadius:"10px"}}>                            
                            <div className="card-header  p-2"style={{borderRadius:"10px"}}>
                                <div className='d-flex flex-row justify-content-between align-items-center p-1'>
                                            <div className='d-flex flex-row'>
                                                <i className="icofont icofont-vehicle-delivery-van cus-i" style={{color:"#171c8f"}}></i> 
                                                <h1 style={{color:"#171c8f",fontWeight:"600", paddingTop:"1px",fontSize:"16px",marginBottom:"0px",paddingLeft:"4px"}}>One Time Requirement</h1> 
                                             </div>                                  
                                            <div>
                                                <span className="float-right f12">
                                                    <button type='button' className="create-booking-button"><a href="/onetimecontainerbookingform" style={{color:"#171c8f",fontWeight:"bold",fontFamily:"Poppins-Regular"}}>One time Requirement</a></button>
                                                </span>
                                            </div>
                                </div>
                            </div>
                            <div className="card-body pt-15px">
                                <div id= "booking-ag-grid" className="row" style={{borderRadius:"10px"}}>
                                    <div id="myGrid1" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={emptyColumnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.emptyrowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            onCellClicked={this.onCellRowClickedonetime}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
                                            
                                        />
                                        
                                       
                                    </div> 
                                   
                                       
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className={"col-xl-12 col-lg-12 "+(this.state.urgentshow)} style={{borderRadius:"10px"}}>
                        <div className="card" style={{borderRadius:"10px"}}>                            
                            <div className="card-header p-2" style={{borderRadius:"10px"}}>
                                <div className='d-flex flex-row justify-content-between align-items-center p-1'>
                                    <div className='d-flex flex-row'>
                                        <i className="icofont icofont-vehicle-delivery-van cus-i" style={{color:"#171c8f"}}></i> 
                                        <h1 style={{color:"#171c8f",fontWeight:"600", paddingTop:"1px",fontSize:"16px",marginBottom:"0px",paddingLeft:"4px"}}>Urgent Container Requirement</h1> 
                                    </div>                                  
                                        <div>
                                            <span className="float-right f12">
                                            <button type='button' className="create-booking-button"> <a href="/urgentcontainerbookingform" style={{color:"#171c8f",fontWeight:"bold",fontFamily:"Poppins-Regular"}}>Urgent Requirement</a></button>
                                            </span>
                                        </div>
                                    
                                </div>
                            </div>
                            <div className="card-body pt-15px">
                                <div id= "booking-ag-grid" className="row" style={{borderRadius:"10px"}}>
                                    <div id="myGrid1" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={UrgentColumnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.urgentrowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            onCellClicked={this.onCellRowClickedUrgnet}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
                                            
                                        />
                                        
                                       
                                    </div> 
                                   
            
                                </div>


                            </div>
                        </div>
                    </div>                       



                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box">
                        <div className="loader-box">
                            <div className="rotate dashed colored"></div>
                        </div>
                    </div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
                <div className={"slide-r "+(this.state.slidertranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                            Upload Weekly Requirement
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <form method="post" onSubmit={this.onClickShowFilterData.bind(this)} className="row col-xl-12 col-lg-12">
							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" id="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
							</div>
                            <div className="form-group">
								{/* <a className="btn btn-primary" href={require('../../assets/json/weekly_requirement_data.csv')} target="_blank">Sample Template</a> */}
                                <a className="btn btn-primary" onClick={this.downloadTemplate.bind(this)}>Sample Template</a>
                                {/* <div className="col-xl-12 col-lg-12 mt-20p" >Tentitive Time format should be <span style={{color:"red", fontSize:"13px"}}>MM/DD/YYYY HH:mm:ss</span></div> */}
							</div>	
                            <div className="form-group col-xl-3 col-lg-3">
                                <button style={{marginTop:"30px"}} type="submit" className="btn btn-success">Submit</button>
                            </div>
                           
                        </form>
                    </div>
                </div>
            

                {/* Bulk Upload */}
                <div className={"slide-r "+(this.state.bulkslide1)} style={{overflow:"hidden"}}>
                    <h3 className="subH">Ringi Data
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                    </h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            
                        <div className="row">
                                    <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnLoadedTrucksDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.unloadtrucksdata}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            // frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            onCellClicked={this.onCellRowClickedRingiData}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
                                            
                                        />
                                        
                                        {/* {console.log(this.state.unloadtrucksdata,"unloadtruckrowData")} */}
                                    </div> 
                                   
                                       
                                </div>
                        </div>
                    </div>
                </div>

                <div className={"slide-r "+(this.state.urgentDownloadBulkSlide)} style={{overflow:"hidden"}}>
                    <h3 className="subH">Urgent Requirement Downloads
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                    </h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            <ui className='card p-3 col-xl-11 col-lg-10'>
                            {bookingDownloadList && bookingDownloadList.length > 0 ? (
                                        bookingDownloadList.map((files, index) => (
                                            <li className='d-flex flex-row m-2 pl-2 pr-2 justify-content-between' key={index}>
                                                <h5 className="download-content-booking">{files.label}</h5>
                                                <button className='btn btn-success' style={{borderRadius:"3px" }} onClick={() => this.onClickUrgentDownloadBulkSlide(files.value)}>Download</button>
                                            </li>
                                        ))
                                    ) : (
                                        <li className='d-flex flex-row m-2 pl-2 pr-2 justify-content-between'>
                                        <h5 className="download-content-booking">There are no downloads available for this record</h5>
                                        </li>
                                    )}
                            </ui>
                        </div>
                    </div>
                </div>
                <div className={"slide-r "+(this.state.onetimeDownloadBulkSlide)} style={{overflow:"hidden"}}>
                        <h3 className="subH">One time Requirement Downloads
                        <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                        </h3>
                                
                        <div className="slide-r-body" style={{position:"relative"}}>
                            
                            <div className="container-fluid">
                                <ui className='card p-3 col-xl-11 col-lg-10'>
                                {bookingDownloadList && bookingDownloadList.length > 0 ? (
                                            bookingDownloadList.map((files, index) => (
                                                <li className='d-flex flex-row m-2 pl-2 pr-2 justify-content-between' key={index}>
                                                    <h5 className="download-content-booking">{files.label}</h5>
                                                    <button className='btn btn-success' style={{borderRadius:"3px"}} onClick={() => this.onClickOnetimeDownloadBulkSlide(files.value)}>Download</button>
                                                </li>
                                            ))
                                        ) : (
                                            <li className='d-flex flex-row m-2 pl-2 pr-2 justify-content-between' >
                                            <h5 className="download-content-booking">There are no downloads available for this record</h5>
                                            </li>
                                        )}
                                </ui>
                            </div>
                        </div>
                </div>

                {/* <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
					<h3 className="subH">Bulk Upload</h3>
						 	
					<div className="slide-r-body" style={{position:"relative"}}>
						
						<div className="container-fluid">
							<form id="upform" className="theme-form" onSubmit={this.uploadFormHandler}>

							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
							</div>
							<div className="form-group">
								<button type="submit" className="btn btn-success">Submit</button>
								<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
							</div>
							</form>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/Bulk_Trucks_Availability_sample.csv')} target="_blank">Sample Template</a>
                                 <div className="col-xl-12 col-lg-12 mt-20p" >Tentitive Time format should be <span style={{color:"red", fontSize:"13px"}}>MM/DD/YYYY HH:mm:ss</span></div>
							</div>		
							
						</div>
					</div>
				</div> */}
                {/* <div className={"slide-r "+(this.state.bulkslideforcontainer)} style={{overflow:"hidden"}}>
                        <h3 className="subH">
                                        Container Details
                            <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                        </h3>
                <div className="slide-r-body" style={{position:"relative"}}>
                    {(this.state.container_requirement.length > 0) ?                         
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <table className="table table-bordered tptinfo">
                                        <thead>
                                            <tr>
                                                <th>Container Size (in ft)</th>
                                                <th>Containers Required</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.container_requirement.map((e, index) => (
                                            <tr>
                                                <td>{e.container_size}</td>
                                                <td>{e.container_required}</td>
                                            </tr>
                                             ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    : ""}
                               
                </div>
            </div> */}
            
                {/* <span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
                <Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
                    <div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                        <h5 className="model-title">Truck Availability</h5>
                        <div className="col-xl-12 col-lg-12 mt-20p">
                            <div className="col-xl-12 col-lg-12">
                                This screen helps user in identifying vehicles of various transporters that are near the point of origin and can be readily used for loading in MSIL as per requirement. This feature is available only for PRT dept as per the requirement raised.
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                A truck can be marked in following ways by transporters
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <ul>
                                    <li>
                                        1. Empty Near plant
                                    </li>
                                    <li>
                                        2. Marked for Arrival
                                    </li>
                                    <li>
                                        3. Marked for Repair
                                    </li>
                                </ul>
                            </div>
                            <h6 style={{fontWeight:"bold"}}>Empty Near plant</h6>
                            <div className="col-xl-12 col-lg-12">
                                Vehicles that are marked as empty near plant are those vehicles which are not carrying any MSIL consignments and are in the vicinity of approx. 100 kms. To check the list of empty vehicles following process is to be followed
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <ul>
                                    <li>1. Select the option “Empty Near Plant”</li>
                                    <li>
                                        2. Select the plant for which details are required
                                    </li>
                                    <li>
                                        3. Click on submit button
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                Image here
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                After clicking the submit button the use will get the details of various transporters whose vehicles are empty near selected plant in the format provided below
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                Image here
                            </div>

                            <h6 style={{fontWeight:"bold"}}>Marked for Arrival</h6>
                            <div className="col-xl-12 col-lg-12">
                                This screen provides details for all those vehicles which are available for arrival in MSIL and is ready for taking next load from various MSIL locations. To check vehicles that are marked for arrival are:
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <ul>
                                    <li>1. Select the option “Marked for arrival”</li>
                                    <li>
                                        2. Select the Time frame for which  data is required ( by default it is for current date)
                                    </li>
                                    <li>
                                        3. Click on submit button
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                Image here
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                After clicking on Submit button user will be able to check the list of vehicles transporter wise and its availability for MSIL near the various MSIL locations
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                Image here
                            </div>

                            <h6 style={{fontWeight:"bold"}}>Marked for Repair</h6>
                            <div className="col-xl-12 col-lg-12">
                            If a transporter marks any vehicle under “marked for repair”, those vehicles will be listed here and will be exempted from getting any new load until marked fit from transporter’s end. One can check list of vehicles that are marked as marked for repair by clicking option Marked for repair. The user will get the list of vehicles in following manner    
                            </div>
                           
                        </div>
                    </div>
                </Modal>     */}
                               <div className={"slide-r " + (this.state.bulkslide6)} style={{ overflow: "hidden" }}>
    <h3 className="subH">Edit One Time Data:
        <span className="float-right fclose" style={{ marginRight: "12px", padding: "1px 8px", marginTop: "-2px" }} onClick={this.onClickHideAll.bind(this)}>x</span>
    </h3>
    <div className="slide-r-body" style={{ position: "relative" }}>
        <div className="container-fluid">
            <form onSubmit={this.onClickUpdateonetimeEdit.bind(this)} className="row col-xl-12 col-lg-12">
                {/* <div className="col-xl-12 col-lg-12 row form-group mt-20p">
                    <label className="col-xl-12 col-lg-12">Select shipping on board : </label>
                    <Select
                        placeholder={"Select Shipping on board"}
                        closeMenuOnSelect={true}
                        value={this.state.sob}
                        className={"col-xl-12 col-lg-12 border-radius-0"}
                        onChange={this.handleSelectChange} // Use a separate handler for react-select
                        style={{ borderRadius: "0px" }}
                        options={[
                            { label: "Yes", value: "Yes" },
                            { label: "No", value: "No" }
                        ]}
                        required
                    />
                </div> */}
                {/* <div className="col-xl-12 col-lg-12 row form-group">
                    <label className="col-xl-12 col-lg-12">Requirement ID{" "}</label>
                    <input
                        type="text"
                        value={this.state.requirement_id}
                        onChange={this.handleInputChange} // Use a separate handler for input fields
                        name="requirement_id"
                        id="requirement_id"
                        className="form-control"
                        placeholder="Requirement ID"
                    />
                </div> */}
                <div className="col-xl-12 col-lg-12 row form-group">
                    <label className="col-xl-12 col-lg-12">Container No{" "}</label>
                    <input
                        type="text"
                        value={this.state.container_no}
                        onChange={this.handleInputChange} // Use a separate handler for input fields
                        name="container_no"
                        id = "container_no"
                        className="form-control"
                        placeholder="Container No"
                    />
                    
                </div>
                {/* <div className="col-xl-12 col-lg-12 row form-group">
                    <label className="col-xl-12 col-lg-12">Incoterms{" "}</label>
                    <input
                        type="text"
                        value={this.state.incoterms}
                        onChange={this.handleInputChange} // Use a separate handler for input fields
                        name="incoterms"
                        id="incoterms"
                        className="form-control"
                        placeholder="Incoterms"
                    />
                </div> */}
                <div className="form-group col-xl-3 col-lg-3">
                    <button type="submit" className="btn btn-success">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>

<div className={"slide-r " + (this.state.bulkslide7)} style={{ overflow: "hidden" }}>
    <h3 className="subH">Edit Weekly Data:
        <span className="float-right fclose" style={{ marginRight: "12px", padding: "1px 8px", marginTop: "-2px" }} onClick={this.onClickHideAll.bind(this)}>x</span>
    </h3>
    <div className="slide-r-body" style={{ position: "relative" }}>
        <div className="container-fluid">
            <form onSubmit={this.onClickUpdateWeeklyEdit.bind(this)} className="row col-xl-12 col-lg-12">
                {/* <div className="col-xl-12 col-lg-12 row form-group mt-20p">
                    <label className="col-xl-12 col-lg-12">Select shipping on board : </label>
                    <Select
                        placeholder={"Select Shipping on board"}
                        closeMenuOnSelect={true}
                        value={this.state.sob}
                        className={"col-xl-12 col-lg-12 border-radius-0"}
                        onChange={this.handleSelectChange} // Use a separate handler for react-select
                        style={{ borderRadius: "0px" }}
                        options={[
                            { label: "Yes", value: "Yes" },
                            { label: "No", value: "No" }
                        ]}
                        required
                    />
                </div> */}
                {/* <div className="col-xl-12 col-lg-12 row form-group">
                    <label className="col-xl-12 col-lg-12">Requirement ID{" "}</label>
                    <input
                        type="text"
                        value={this.state.requirement_id}
                        onChange={this.handleInputChange} // Use a separate handler for input fields
                        name="requirement_id"
                        id="requirement_id"
                        className="form-control"
                        placeholder="Requirement ID"
                    />
                </div> */}
                <div className="col-xl-12 col-lg-12 row form-group">
                    <label className="col-xl-12 col-lg-12">Container No{" "}</label>
                    <input
                        type="text"
                        value={this.state.container_no}
                        onChange={this.handleInputChange} // Use a separate handler for input fields
                        name="container_no"
                        id = "container_no"
                        className="form-control"
                        placeholder="Container No"
                    />
                    
                </div>
                {/* <div className="col-xl-12 col-lg-12 row form-group">
                    <label className="col-xl-12 col-lg-12">Incoterms{" "}</label>
                    <input
                        type="text"
                        value={this.state.incoterms}
                        onChange={this.handleInputChange} // Use a separate handler for input fields
                        name="incoterms"
                        id="incoterms"
                        className="form-control"
                        placeholder="Incoterms"
                    />
                </div> */}
                <div className="form-group col-xl-3 col-lg-3">
                    <button type="submit" className="btn btn-success">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>

<div className={"slide-r " + (this.state.bulkslide_Approve)} style={{ overflow: "hidden" }}>
    <h3 className="subH">Approve Status
        <span className="float-right fclose" style={{ marginRight: "12px", padding: "1px 8px", marginTop: "-2px" }} onClick={this.onClickHideAll.bind(this)}>x</span>
    </h3>
    <div className="slide-r-body" style={{ position: "relative" }}>
        <div className="container-fluid">
            <form 
            onSubmit={this.onClickUpdateApproveStatus.bind(this)} 
            className="row col-xl-12 col-lg-12">
               
                <div className="col-xl-12 col-lg-12 row form-group mt-20p">
                    <label className="col-xl-12 col-lg-12">Freight Forwarder : </label>
                    <Select
                        placeholder={"Freight Forwarder"}
                        closeMenuOnSelect={true}
                        name={"ff_details"}
                        className={"col-xl-12 col-lg-12 border-radius-0"}
                        onChange={this.handleSelectChange.bind(this,"selectedFF")} // Use a separate 
                         id={"ff_details"}
                        style={{ borderRadius: "0px" }}
                        options={this.state.ff_details}
                        required
                    />
                </div>
               
                <div className="col-xl-12 col-lg-12 row form-group mt-20p">
                    <label className="col-xl-12 col-lg-12">CHA Updates : </label>
                    <Select
                        placeholder={"CHA Updates"}
                        closeMenuOnSelect={true}
                        name={"cha_details"}
                        className={"col-xl-12 col-lg-12 border-radius-0"}
                        onChange={this.handleSelectChange.bind(this,"selectedCHA")} // Use a separate  
                        id={"cha_details"}
                        style={{ borderRadius: "0px" }}
                        options={this.state.cha_details}
                        required
                    />
                </div>
                <div className="col-xl-12 col-lg-12 row form-group mt-20p">
                    <label className="col-xl-12 col-lg-12">Transporters Updates : </label>
                    <Select
                        placeholder={"Transporters Updates"}
                        closeMenuOnSelect={true}
                        name={"tpt_details"}
                        className={"col-xl-12 col-lg-12 border-radius-0"}
                        onChange={this.handleSelectChange.bind(this,"selectedTPT")} // Use a separate handler for react-select
                        id={"tpt_details"}
                        style={{ borderRadius: "0px" }}
                        options={this.state.tpt_details}
                        required
                    />
                </div>
              
                <div className="form-group col-xl-3 col-lg-3">
                    <button type="submit" className="btn btn-success">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>

<div className={"slide-r "+(this.state.bulkslide10)} style={{overflow:"hidden"}}>
                <h3 className="subH">Urgent requirement Remark
                <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                </h3>
                         
                <div className="slide-r-body" style={{position:"relative"}}>
                    
                    <div className="container-fluid">
                        
                        <form method="post" onSubmit={this.onClickSubmitRequirmentRemark.bind(this)} className="row col-xl-12 col-lg-12">
                            <div className="col-xl-12 col-lg-12 row form-group mt-20p">
                            <label className="col-xl-12 col-lg-12">Shipping on Board : </label>
                                        <Select 
                                        placeholder={"Select Shipping on board"} 
                                        closeMenuOnSelect={true} 
                                        value={this.state.container_type} 
                                        className={"col-xl-12 col-lg-12 border-radius-0"}
                                        onChange={this.requirmentRemark.bind(this)} 
                                        style={{borderRadius:"0px"}} 
                                        options={[
                                            { label: "OneTime", value: "OneTime" },
                                            { label: "Weekly", value: "Weekly" },
                                    ]} required
                                    />
                            </div>	
                            <div className="form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                        
                    </form>	
                    </div>
                </div>
            </div>

            </div>
    
              	
		);
	}
}

window.onload = function (){

    var currentTime = new Date().getTime();
    var todayDate = new Date().getDate();
    var logic = function( currentDateTime ){
        // console.log("currentDateTime ", new Date(currentDateTime).getDate())
        // console.log("todayDate ", todayDate)
        var clickdate = new Date(currentDateTime).getDate()
        if( clickdate == todayDate ){
          this.setOptions({
            minTime:currentTime,
            timepickerScrollbar: true,
            scrollInput:true
          });
        }
        else
        {
            this.setOptions({
                minTime:"00:00",
                timepickerScrollbar: true,
                scrollInput:true
            });
        }
          
      };


	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('#tentitivetime').datetimepicker({
		mask:'9999-19-39 29:59',
        format:'Y-m-d H:i',
        formatTime:"H:i A",
        minTime:currentTime,
        onChangeDateTime:logic,
        onShow:logic,
        minDate : new Date(new Date().setDate(todayDate)),
        maxDate: new Date(new Date().setDate(todayDate + 2)),
        startDate:new Date(new Date().setDate(todayDate)),
        timepickerScrollbar: true,
        scrollInput:true
	});

	$(".styles_modal__gNwvD").css({width:"450px"})
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){
    var currentTime = new Date().getTime();
    var todayDate = new Date().getDate();
    var logic = function( currentDateTime ){
        // console.log("currentDateTime ", new Date(currentDateTime).getDate())
        // console.log("todayDate ", todayDate)
        var clickdate = new Date(currentDateTime).getDate()
        if( clickdate == todayDate ){
          this.setOptions({
            minTime:currentTime,
            timepickerScrollbar: true,
            scrollInput:true
          });
        }
        else
        {
            this.setOptions({
                minTime:"00:00",
                timepickerScrollbar: true,
                scrollInput:true
            });
        }
          
      };

    var todayDate = new Date().getDate();
	$('#tentitivetime').datetimepicker({
		mask:'9999-19-39 29:59',
        format:'Y-m-d H:i',
        formatTime:"H:i A",
        minTime:currentTime,
        onChangeDateTime:logic,
        onShow:logic,
        minDate : new Date(new Date().setDate(todayDate)),
        maxDate: new Date(new Date().setDate(todayDate + 2)),
        startDate:new Date(new Date().setDate(todayDate)),
        timepickerScrollbar: true,
        scrollInput:true
	});
	
    // var index  =window.document.getElementsByTagName("script")[1]
    // var script = window.document.createElement("script")
    // script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    // script.async=true
    // script.defer = true
    // index.parentNode.insertBefore(script,index)
}


function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDays(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}

function onSetCritical(params, status){
	//console.log("onSetCritical ", params);
	//console.log("onSetCritical value ", status);
	redirectURL.post('/consignments/setCriticalStatus',{
		params:params,
		is_critical:status,
		token:window.atob(localStorage.getItem('_t')),
		userId:localStorage.getItem("userid")
	})
		.then((response) => {
		
		//var records = JSON.parse(JSON.stringify(response)).data;
		console.log(response.data);
		});
		
}
$(document).ready(function(){
    $(document).on("click",".dropdown-truck-no",function(){
        var vals = $(this).text();
         $("#inputTruck").val(vals);
         $(".trucks-dropdown").removeClass("show-m");
         $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click",function(e){
        var container = $("#inputTruck");
        if(!container.is(e.target))
        {
            try{
                if($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown")
                {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            }catch(e){}
            
        }
        
        
    })
})

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
 }
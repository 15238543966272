/* eslint-disable no-eval */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDDHHMMSS, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Select from 'react-select';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import ArrivalMap from "./arrivalMap";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import DrawMap from "../common/drawmap";
import UnloadColumnchart from './columnunloadingdashboard';
import SndUnloadingColumnChart from "./sndUnloadingColumnChart";
import TransporterChart from "./returnTripsLSPChart";
import ReturnTripsRouteWiseTripsGroupLineChart from "./columnGroupLinechart";
import SNDReturnTripsRouteWiseTripsGroupLineChart from "./sndRouteWiseThreeLevelChart";
import RouteWiseThreeLevelChart from "./routeWiseThreeLevelChart";
import EmptyReturnTripsChart from "./emptyReturnTripsChart";
var googleAnalytics = require("../common/googleanalytics");
var redirectURL = require('../redirectURL');
 
var moment = require('moment');

export default class ReturnTripsDashboardChart extends Component {

    constructor(props) {
        super(props);
        this.state={
            locations:[],
            transporters:[],
            location:'',
            transporter:'',
            startDate:'',
            endDate:"",
            modules:AllModules,
            defaultColDef: {
                sortable: true,
              filter: true,
              resizable: true,
            },
            paginationPageSize:50,
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            columnwithDefs:[{
                headerName:"Plant Name",

            }],
            sndtab:'show-n',
            prttab:'show-n',
            tnptab:'show-n',
            sndtabactive:'',
            prttabactive:'',
            tnptabactive:'',
            overly : "show-m",
            loadshow : "show-m",
            series:[],
            city_uld_data : [],
            city_dealer_uld_data : [],
            cluster_uld_data : [],
            top_10_dealer_data : [],
            seriesData : [],
            comp_category : [],
            drilldownSeriesData : {},
            rowData : [],
            rowData1 : [],
            rowData2 : [],
            rowData3 : [],
            plantRowData : [],
            plantGridData:[],
            showMapData : "show-n",
            lat : "",
            lng : "",
            distancefromdealer : "",
            no_of_unloads : "",
            showFirstLevel : "show-n",
            filteredLocationData : [], // custom drop down
            showLocationList:"show-n", // custom drop down
            filteredPlantData : [], // custom drop down
            showPlantList:"show-n", // custom drop down
            statesList : [],
            selectedState : {value: "ANDHRA PRADESH", label: "ANDHRA PRADESH"},
            cityData : [],
            transporterData : [],
            possibleUnloadData : [],
            frameworkComponents : {
                ArrivalMap : ArrivalMap
            },
            breaksSlider:"",
            selectedRow : "",
            selectedConsigneeName : "",
            dept_code : "",
            selectedTnpPlant : {"value" : "all","label":"all"},
            dealer_uld_data : [],
            selectedCompetitors : [{label : "ALL",value:"ALL"}],
            selectedTransporters : [{label: "ALL",value:"ALL"}],
            selectedZones: [{label: "ALL", value:"ALL"}],
            selectedConsigner:[{label: "ALL",value:"ALL"}],
            competitorOptions:[],
            transporterOptions:[],
            zoneOptions:[],
            consigner_location_options:[],
            show: false,
            basicType:"",
            basicTitle:"",
            sliderRouteTranslate: "",
            rownode: "",
            mapinfo:"",
            route_wise_series: [],
            route_wise_categories:[],
            thirdChartSeriesData : [],
            thirdChartDrilldownData: [],
            thirdChartCategories: [],
            secondChartSeriesData: [],
            checkSecondChartDrillDownLength : 0,
            secondChartDrilldownData: [],
            secondChartCategories: [],
            secondChartDrilldownColumn: {},
            secondChartDrilldownColumn1:{},
            secondChartDrilldownLine: {},
            secondChartOEMWiseReturnColumn:{},
            firstChartMessage : "",
            secondChartMessage: "",
            thirdChartMessage: "",
            fourthChartMessage: "",
            fifthChartMessage: "",
            sixthChartMessage: "",
            seventhChartMessage: "",
            ninthChartMessage:"",
            tenthChartMessage:"",
            eighthChartMessage: "",
            detailCellRendererParams:{},
            fifthChartSeriesData: [],
            fifthChartCategories : [],
            sixthChartSeriesData: [],
            sixthChartCategories : [],
            routeWiseDrilldown: [],
            analysis : "activet",
            analysis_data: "",
            showGrid: "show-n",
            showAnalysis: "show-m",
            competitor_geofences:[],
            gridQuery: {},
            selectedSource : [{label: "ALL",value:"ALL"}],
            seventhChartSeriesData : [],
            seventhChartCategories : [],
            ninthChartSeriesData:[],
            ninthChartCategories:[],
            tenthChartSeriesData:[],
            tenthChartCategories:[],
            eighthChartseries : [],
            eighthChartCategories: [],
            secondChartClusterWiseReturnColumn: [],
            routeWiseClusterOEMS: [],
            showgridforadmd:"show-n",
            showgridforadmdet:"show-n",
            showgridforadmds:"show-n",
            emptyReturnTripsCategories:[],
            emptyReturnTripsSeries:[],
            emptyReturnTripsdrilldownCategories:[],
            emptyReturnTripsdrilldownseries:[],
            emptyReturnTripsdrilldownData:[],
            graphTab:"activet",
            complete_trips_tab:"activet",
            valid_trips_tab:"",
            complete_trips_data:[],
            valid_trips_data:[],
            gridTab:"",
            graphTabInital:"activet",
            gridTabUnital:"",
            emptyReturnTripsRowData:[],
            ReturnTripsRowData:[],
            rowData4:[],
            statusBar: {
                statusPanels: [
                    {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                    },
                    {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
        }
        this.formatChartData = this.formatChartData.bind(this);
        this.formatSecondAndThirdChartData = this.formatSecondAndThirdChartData.bind(this);
        this.formatFourthChartData = this.formatFourthChartData.bind(this);
        this.formatFifthChartData = this.formatFifthChartData.bind(this);
        this.formatSixthChartData = this.formatSixthChartData.bind(this);
        this.formatSeventhChartData = this.formatSeventhChartData.bind(this);
        this.formatninthChartData = this.formatninthChartData.bind(this);
        this.formattenthChartData = this.formattenthChartData.bind(this);
        
    }
    closeAlert = async () => {
        await this.setState({
            show: false
        });
        // await window.location.reload();
    }
    componentWillReceiveProps(newprops)
    {
        if(newprops.transporters != this.state.transporters)
        {
            this.setState({
                transporters : newprops.transporters
            })
        }
    }
    componentDidMount(){
        loadDateTimeScript();


       


        if(this.props.match.path == "/returnTripsDashboard")
        {
            var dept_code = "SNDG"
        }
        else if(this.props.match.path == "/prtreturnTripsDashboard")
        {
            dept_code = "LOG-PRT"
        }
        else
        {
            dept_code = "LOG-TNP"
        }
        this.setState({
            dept_code: dept_code
        })
        // var from_date = moment.parseZone().subtract(2, 'months').format("YYYY-MM-DD")+" 00:00:00";
        // var to_date = moment.parseZone().format("YYYY-MM-DD")+" 23:59:59";

        var from_date = "2024-01-01 00:00:00"
        var to_date = "2024-01-31 23:59:59";

        // $(".startDate").val(moment.parseZone().subtract(2, 'months').format("DD-MM-YYYY"))
        $(".startDate").val("2024-01-01")
        $(".endDate").val("2024-01-31")

        redirectURL.post("/dashboard/getFilterOptionsForReturnDashboard", {"dept_code": dept_code}).then((response)=>{
            var competitorOptions = [];
            competitorOptions.push({"label": "ALL","value":"ALL"})
            response.data.competitors.map(function(e){
                competitorOptions.push({"label": e,"value":e})
            })

            var transporterOptions = [];
            transporterOptions.push({"label": "ALL","value":"ALL"})
            response.data.transporter_names.map(function(e){
                transporterOptions.push({"label": e,"value":e})
            })

            var consigner_location_options=[];
            consigner_location_options.push({"label": "ALL","value":"ALL"})
            response.data.consigner_locations.map(function(e){
                consigner_location_options.push({"label": e,"value":e})
            })
            var zone_options = [];
            zone_options.push({"label": "ALL","value":"ALL"})
            response.data.zones.map(function(e){
                zone_options.push({"label": e, "value": e})
            })
            this.setState({
                competitorOptions: competitorOptions,
                transporterOptions: transporterOptions,
                consigner_location_options: consigner_location_options,
                zoneOptions: zone_options,
            })
        })

        var query = {"from_date": from_date,"to_date": to_date, "dept_code": dept_code}

        this.getEmptyReturnTripsData(query)

        console.log("calling oem API",query)
        var oemAPI = redirectURL.post("/dashboard/oemlevelchartdata",query).then((response)=>{            
            if(response.data.api.message == undefined)
            {
                this.formatChartData(JSON.parse(response.data.api.competitor_list),
                                JSON.parse(response.data.api.transporter_list),JSON.parse(response.data.api.routes_wise_return_duration_df))
                console.log(response.data)
                
            }
            else
            {
                this.setState({
                    firstChartMessage : "no data",
                    eighthChartMessage: "no data",
                    // seriesData: [],
                    drilldownSeriesData : ["no data"],
                    rowData: [],
                })
            } 
        }).catch((e)=>{
            console.log(e)
        });
        var oemAPI1 = redirectURL.post("/dashboard/getRouteWiseSummaryForOEMTrips",query).then((response)=>{           
            if (response.data.api && response.data.api.grid_data) {
                    this.setState({
                        ReturnTripsRowData: JSON.parse(response.data.api.grid_data),
                    });
                } else {
                    
                }
            
        }).catch((e)=>{
            console.log(e)
        });
        // Promise.all([oemAPI]).then(()=>{
        console.log("calling Routes API ...")
        var routeWiseApi = redirectURL.post("/dashboard/routeWiseReturnTrips",query).then((response)=>{
            if(response.data.api.message == undefined)
            {
                this.formatSecondAndThirdChartData(
                    response.data.api.total_trips,
                    response.data.api.return_trips,
                    JSON.parse(response.data.api.route_wise_trips),
                    JSON.parse(response.data.api.route_wise_oem_data),
                    JSON.parse(response.data.api.route_wise_cluster_df),
                    eval(response.data.api.route_wise_column_chart),
                    eval(response.data.api.route_wise_column_chart1),
                    eval(response.data.api.route_wise_line_chart))
            }
            else
            {
                this.setState({
                    secondChartMessage : "no data",
                    thirdChartMessage : "no data",
                })
            }
        })
        // Promise.all([routeWiseApi]).then(()=>{
        console.log("calling transporter api")
        var transApi = redirectURL.post("/dashboard/transporterWiseReturnTripsChart",query).then((response)=>{
            try{
                if(response.data.api.message == undefined)
                {
                    this.formatFourthChartData(JSON.parse(response.data.api.transporter_level_return_trips),
                                                            JSON.parse(response.data.api.oem_level_return_trips))
                    this.formatSeventhChartData(JSON.parse(response.data.api.dead_mile_dst_avg))
                    this.formatninthChartData(JSON.parse(response.data.api.dead_mile_distance_for_competitor_trips))
                    this.formattenthChartData(JSON.parse(response.data.api.dead_mile_distance_for_empty_trips))
                }
                else
                {
                    this.setState({
                        fourthChartMessage : "no data",
                        seventhChartMessage: "no data",
                        ninthChartMessage: "no data",
                        tenthChartMessage: "no data",
                        // thirdChartSeriesData: [],
                        // thirdChartDrilldownData : [],
                    })
                }
                this.setState({
                    overly: "show-n",
                    loadshow: "show-n"
                })
            }
            catch(e){
                console.log(e)
            }
            
            
        })
        // Promise.all([transApi]).then(()=>{
        console.log("PRe loadin avg API....")
        var avgPreload = redirectURL.post("/dashboard/oemWiseAvgPreLoadingTime",query).then((response)=>{
                if(response.data.message == undefined)
                {
                    this.formatFifthChartData(JSON.parse(response.data.avg_pre_loading_time))
                }
                else
                {
                    this.setState({
                        fifthChartMessage: "no data"
                    })
                }
            }).catch((e)=>{
                console.log(e)
            })
        // Promise.all([avgPreload]).then(()=>{
        console.log("calling avg loadig API ....")
        redirectURL.post("/dashboard/oemWiseAvgLoadingTime",query).then((response)=>{
            console.log(response.data,"loading time")
            if(response.data.message == undefined)
            {
                this.formatSixthChartData(JSON.parse(response.data.avg_loading_time))
                
            }
            else
            {
                this.setState({
                    sixthChartMessage: "no data"
                })
            }
            this.setState({
                overly: "show-n",
                loadshow: "show-n"
            })
        }).catch((e)=>{
            console.log(e)
        })
            // })
        // })
        // })
        // })
        this.getRouteWiseCarrierRunning()
    }
    getRouteWiseCarrierRunning = async()=>{
        var from_date = $(".startDate").val()
        var to_date =$(".endDate").val()
        var params={
            from_date : from_date+" 00:00:00",
            to_date : to_date+" 23:59:59",
        }
        redirectURL.post("/dashboard/getRouteWiseCarrierRunning",params).then((response)=>{
            var resp = JSON.parse(response.data.final_data)
            console.log(resp,"438")
            this.setState({
                rowData4 : resp
            })
        })
    }


    getEmptyReturnTripsData=async(query)=>{

        var emptyReturnTripsCategories=["plant1","plant2","plant3","plant4"]
        var emptyReturnTripsSeries=[
                {
                name:"Total Trips",
                type:"column",
                data:[
                    {name: 'Total Trips', y: 7168},
                    {name: 'Total Trips', y: 6819},
                    {name: 'Total Trips', y: 10369},
                    {name: 'Total Trips', y: 17263}
                ]
                },
                {
                    name:"Return Trips",
                    type:"column",
                    data:[
                       {name: 'Return Trips', y: 3988, drilldown: 'Route1'},
                        {name: 'Return Trips', y: 2536, drilldown: 'Route2'}, 
                        {name: 'Return Trips', y: 1654, drilldown: 'Route3'},
                        {name: 'Return Trips', y: 1464, drilldown: 'Route4'}
                ]}

            ]

        // this.setState({emptyReturnTripsCategories,emptyReturnTripsSeries})
        var emptyReturnTripsdrilldownCategories=[]
        var emptyReturnTripsdrilldownseries=[]


      await redirectURL.post("/dashboard/getrouteWiseEmptyReturnChartData",query).then((response)=>{
        if(response.data.data){
        console.log(response.data.data,"response")
        var {plant_level_counts,grid_data}=response.data.data
        grid_data=JSON.parse(grid_data)
        console.log(grid_data,plant_level_counts,"466")

        var total_trips_series=[]
        var return_trips_series=[]
        var categories=[]
        var drillDownData=[]
        
        let categoriesList=["HARYANA","GUJARAT","BIDADI","TVP BANGALORE","TVP NAGPUR","TVP SILIGURI","DOBASPET"]
        let plant_level_counts_new=[]
       
        categoriesList.map((each)=>{
            let tempVal=plant_level_counts.find((item)=>item["plant_name"]==each)
            console.log(tempVal,"479")
            if(tempVal){
                plant_level_counts_new.push(tempVal)
            }

        })
        
            console.log(plant_level_counts,plant_level_counts_new,"482")
            plant_level_counts_new.map((each)=>{
            total_trips_series.push({name:"Total Trips",y:each.cons_return_trips})
            return_trips_series.push({name:"Empty Return Trips",y:each.cons_empty_return_trips,drilldown:each.plant_name,plant_name:each.plant_name,routes:each.routes})
            drillDownData.push({plant_name:each.plant_name,routes:each.routes})
            categories.push(each.plant_name)
        })

        console.log(return_trips_series,drillDownData,"return_trip_series")

        this.setState({
            emptyReturnTripsSeries:[
                {
                    name:"Total Trips",
                    type:"column",
                    data:total_trips_series
                },
                {
                    name:"Empty Return Trips",
                    type:"column",
                    data:return_trips_series
                }

            ],
            emptyReturnTripsCategories:categories,
            emptyReturnTripsdrilldownData:drillDownData,
            emptyReturnTripsRowData:grid_data
        })


    }
    })
    }

    formatChartData(competitor_wise_data,trans_wise_data, routes_wise_return_duration_df){
        this.setState({
            seriesData: [],
            drilldownSeriesData : [],
            eighthChartCategories: [],
            eighthChartseries: []
        //     comp_category: []
        })
        var comp_wise_data = [];
        var categories=[];
        var drilldown = [];
        var eighthChartseries = [];
        var eighthChartCategories = [];
        // oem chart
        competitor_wise_data.map(function(e){
            comp_wise_data.push({
                "name":e.competitor_name,
                "y":parseFloat(e.total_records),
                "trips": e.total_records,
                "drilldown":e.competitor_name,
            })
            categories.push(e.competitor_name)
        });
        comp_wise_data = comp_wise_data.sort( (a, b) => {
            return b["y"] - a["y"]
        })
        comp_wise_data = comp_wise_data.slice(0, 15)
        competitor_wise_data.map(function(e){
            var competitor_name = e.competitor_name;
    
            var matchedtransporters = trans_wise_data.filter(function(k){
                if(k.competitor_name == competitor_name)
                {
                    return k;
                }
            });
            // console.log(matchedplants);
            var data=[]
            matchedtransporters.map(function(j){
                data.push([j.transporter_name,parseFloat(j.total_records)])
            });
            data = data.sort( (a, b) => {
                return b[1] - a[1]
            })
            data = data.slice(0, 15)
            drilldown.push({
                "name" : competitor_name,
                "id": competitor_name,
                "data" : data 
            })
        });
        var routeId = {
            "W":"West",
            "N":"North",
            "S":"South",
            "E":"North East (Bidadi)",
            "Z":"North East (Haryana)",
            "P":"Export",
            "M":"Madhya Pradesh",
            "G":"North East (Gujarat)",
            "L":"Local",
        }
        // route wise avg time in hrs chart
        routes_wise_return_duration_df.map(function(e){
            eighthChartseries.push({
                "name":routeId[e.route_id],
                "showTooltip": 1,
                "y": Math.round(e.avg_return_trip_time/24),
            })
            eighthChartCategories.push(routeId[e.route_id])
        });
        eighthChartseries = eighthChartseries.sort( (a, b) => {
            return b["y"] - a["y"]
        })


        this.setState({
            seriesData: comp_wise_data,
            drilldownSeriesData : drilldown,
            comp_category: categories,
            eighthChartseries: eighthChartseries,
            eighthChartCategories: eighthChartCategories,
            overly: "show-n",
            loadshow: "show-n"
        })
        if(comp_wise_data.length == 0)
        {
            this.setState({
                firstChartMessage: "no data"
            })
        }

    }
    formatSecondAndThirdChartData(total_trips,return_trips,
                                route_wise_trips,route_wise_oem_trips, route_wise_clusters_df,
                                route_wise_column_chart,route_wise_column_chart1,
                                route_wise_line_chart)
    {
        this.setState({
            secondChartSeriesData: [],
            secondChartDrilldownData: [],
            secondChartCategories: [],
            secondChartDrilldownColumn: [],
            secondChartDrilldownColumn1: [],
            secondChartDrilldownLine: [],
            secondChartOEMWiseReturnColumn: [],
            route_wise_categories: [],
            route_wise_series: [],
            checkSecondChartDrillDownLength: 0
        })
        var secondChartSeriesData = [
            {
                name: "Total Trips",
                type: "column",
                data: [{"name":"total_trips",y: total_trips}]
            },
            {
                name: "Return Trips",
                type: "column",
                data: [{"name":"return_trips",y: return_trips,drilldown:"return_trips"}]
            },
        ];
        
        

        // third chart Data
        var route_wise_categories = [];
        var route_wise_series = [
            {"type": "column","name": "Total Trips",yAxis:1, "data":[]},
            {"type": "column","name": "Return Trips",yAxis:1, "data":[]},
            {"type": "spline","name": "Return Trips %", "data":[],dataLabels: {
                enabled: true,
                format: '{point.y}%'
            }, "marker": {
                lineWidth: 2,
                lineColor: Highcharts.getOptions().colors[3],
                fillColor: 'white'
            }},
        ];
        route_wise_trips = route_wise_trips.sort(function(a,b){
            return b['valid_trips'] - a['valid_trips']
        })
        var routeId = {
            "W":"West",
            "N":"North",
            "S":"South",
            "E":"North East (Bidadi)",
            "Z":"North East (Haryana)",
            "P":"Export",
            "M":"Madhya Pradesh",
            "G":"North East (Gujarat)",
            "L":"Local",
        }
        route_wise_trips.map(function(e){
            route_wise_categories.push(routeId[e.route_id])
            route_wise_series[0]['data'].push({name:"Total Trips",y:e.total_trips})
            route_wise_series[1]['data'].push({"name":"Return Trips","y":e.valid_trips,drilldown:e.route_id})
            route_wise_series[2]['data'].push({"name":"Return Trip %",y:Math.round(e.valid_trip_percentage)})
        });
        route_wise_line_chart[0]['return_trips']['dataLabels'] = {
            enabled: true,
            format: '{point.y}%'
        }
        route_wise_line_chart[0]['return_trips']['marker'] = {
            lineWidth: 2,
            lineColor: Highcharts.getOptions().colors[3],
            fillColor: 'white'
        }
        console.log(route_wise_series)
        
        var routes = [];
        route_wise_oem_trips.map(function(e){   
            if(routes.indexOf(e.route_id) == -1)
            {
                routes.push(e.route_id);
            }
        })
        var secondChartOEMWiseReturnColumn= []
        routes.map(function(e){
            var route_oems = [];
            route_wise_oem_trips.map(function(k){
                if(k.route_id == e)
                {
                    route_oems.push([k.competitor_name,k.valid_trips])
                }
            })
            route_oems = route_oems.sort(function(a, b) {
                return b[1] - a[1];
            });
            secondChartOEMWiseReturnColumn.push({id:e,"type": "column","data": route_oems})
        });
        // cluster
        var secondChartClusterWiseReturnColumn= [];
        var cluster_oems = [];
        routes.map(function(e){
            var route_oems = [];
            route_wise_clusters_df.map(function(k){
                if(k.route_id == e)
                {
                    route_oems.push({"name": k.cluster,"y": k.valid_trips, "drilldown": k.cluster})
                }
            })
            route_oems = route_oems.sort(function(a, b) {
                return b["y"] - a["y"];
            });
            route_oems.map(function(clusters){
                route_wise_oem_trips.map(function(cluster){
                    if(cluster['cluster'] == clusters['name'])
                    {
                        cluster_oems.push({"name": cluster.competitor_name,'y': cluster.valid_trips,
                                            "cluster": cluster['cluster'] })
                    }
                })
            });
            secondChartClusterWiseReturnColumn.push({id:e,"type": "column","data": route_oems});
        });
        var distinctClusters = [];
        cluster_oems.map(function(e){   
            if(distinctClusters.indexOf(e.cluster) == -1)
            {
                distinctClusters.push(e.cluster);
            }
        })
        var routeWiseClusterOEMS = []
        distinctClusters.map(function(e){
            var filteredclusters = cluster_oems.filter(function(k){
                return k['cluster'] == e;
            })
            routeWiseClusterOEMS.push({id:e,"type": "column","data": filteredclusters})
        })
        console.log(secondChartOEMWiseReturnColumn,"secondChartOEMWiseReturnColumn")
        this.setState({
            secondChartSeriesData: secondChartSeriesData,
            secondChartDrilldownColumn: route_wise_column_chart[0],
            secondChartDrilldownColumn1: route_wise_column_chart1[0],
            secondChartDrilldownLine: route_wise_line_chart[0],
            secondChartOEMWiseReturnColumn: secondChartOEMWiseReturnColumn,
            secondChartClusterWiseReturnColumn: secondChartClusterWiseReturnColumn,
            route_wise_categories: route_wise_categories,
            route_wise_series: route_wise_series,
            checkSecondChartDrillDownLength: 1,
            routeWiseClusterOEMS: routeWiseClusterOEMS
        })
        if(secondChartOEMWiseReturnColumn.length == 0 )
        {
            this.setState({
                thirdChartMessage: "no data"
            })
        }
        if(secondChartSeriesData.length == 0)
        {
            this.setState({
                secondChartMessage: "no data"
            })
        }
    }
    formatFourthChartData(transporter_level_data,oem_level_data)
    {
        console.log(transporter_level_data,"transporter_level_data")
        this.setState({
            thirdChartSeriesData : [],
            thirdChartDrilldownData: [],
            thirdChartCategories: [],
        //     comp_category: []
        })
        var thirdChartSeriesData = [
            {type:"column",name: "Return Trips",data: []},
            {"type": "spline","name": "Return Trips %", "data":[],dataLabels: {
                enabled: true,
                format: '{point.y}%'
            }, "marker": {
                lineWidth: 2,
                lineColor: Highcharts.getOptions().colors[3],
                fillColor: 'white'
            }},
        ];
        var thirdChartCategories=[];
        var thirdChartDrilldownData = [];
        // var total_trips = {name: "Total Trips"};
        // var return_trips = {name: "Return Trips"};
        transporter_level_data = transporter_level_data.sort( (a, b) => {
            return b["valid_trips"] - a["valid_trips"]
        })
        transporter_level_data = transporter_level_data.slice(0, 15)
        transporter_level_data.map(function(e){
            if(e.valid_trips > 0)
            {
                thirdChartSeriesData[0]['data'].push({
                    "name":e.transporter_name,
                    "y":e.valid_trips,
                    "total_trips": e.total_trips,
                    "valid_trip_percentage": e.return_trip_percentage,
                    "drilldown":e.transporter_code,
                });
                thirdChartSeriesData[1]['data'].push({
                    "name":e.transporter_name,
                    "y":e.return_trip_percentage,
                    // "drilldown":e.transporter_code,
                })
            }
            thirdChartCategories.push(e.transporter_name)
        });
        
        transporter_level_data.map(function(e){    
            var matchedtransporters = oem_level_data.filter(function(k){
                if(k.transporter_code == e.transporter_code)
                {
                    return k;
                }
            });
            // console.log(matchedplants);
            var data=[]
            matchedtransporters.map(function(j){
                data.push([j.competitor_name,j.total_records])
            });
            data = data.sort( (a, b) => {
                return b[1] - a[1]
            })
            thirdChartDrilldownData.push({
                "name" : e.transporter_code,
                "id": e.transporter_code,
                "data" : data 
            })
        });
        console.log(thirdChartSeriesData,thirdChartSeriesData.length,"1")
        console.log(thirdChartDrilldownData)
        console.log(thirdChartCategories,thirdChartCategories.length,"2")
        // console.log(categories)
        this.setState({
            thirdChartSeriesData: thirdChartSeriesData,
            thirdChartDrilldownData : thirdChartDrilldownData,
            thirdChartCategories: thirdChartCategories,
            overly: "show-n",
            loadshow: "show-n"
        })
        if(thirdChartSeriesData[0]['data'].length == 0 && 
            thirdChartSeriesData[1]['data'].length == 0)
        {
            this.setState({
                fourthChartMessage: "no data"
            })
        }
    }
    formatFifthChartData(avg_pre_loading_time)
    {
        this.setState({
            fifthChartSeriesData: [],
            fifthChartCategories : []
        })
        var series = [];
        var categories = [];
        avg_pre_loading_time.map(function(e){
            series.push({
                "name":e.comp_name,
                "y":parseFloat(e['Avg Preloading Time']),
                "trips": e.total_trips
            })
            categories.push(e.comp_name)
        });
        series = series.sort( (a, b) => {
            return b["y"] - a["y"]
        })
        series = series.filter(function(e){
            return e.y > 0
        })
        console.log(series,"fifth chart")
        this.setState({
            fifthChartSeriesData: series,
            fifthChartCategories : categories
        })
    }
    formatSixthChartData(avg_loading_time)
    {
        this.setState({
            sixthChartSeriesData: [],
            sixthChartCategories : []
        })
        var series = [];
        var categories = [];
        avg_loading_time.map(function(e){
            series.push({
                "name":e.comp_name,
                "y":parseFloat(e['Avg loading Time']),
                "trips": e.total_trips
            })
            categories.push(e.comp_name)
        });
        series = series.sort( (a, b) => {
            return b["y"] - a["y"]
        })
        series = series.filter(function(e){
            return e.y > 0
        })
        console.log(series,"6th chart")
        this.setState({
            sixthChartSeriesData: series,
            sixthChartCategories : categories
        })
    }
    formatSeventhChartData(dead_mile_dst_avg)
    {
        this.setState({
            seventhChartSeriesData: [],
        })
        var seventhChartSeriesData = [];
        var seventhChartCategories = [];
        var routeId = {
            "W":"West",
            "N":"North",
            "S":"South",
            "E":"North East (Bidadi)",
            "Z":"North East (Haryana)",
            "P":"Export",
            "M":"Madhya Pradesh",
            "G":"North East (Gujarat)",
            "L":"Local",
        }
        dead_mile_dst_avg.map(function(e){
            seventhChartSeriesData.push({
                "name": routeId[e.route_id],
                "showTooltip": 1,
                "y": Math.round(e.avg_dead_mile_distance),
            })
            seventhChartCategories.push(routeId[e.route_id])
        });
        seventhChartSeriesData = seventhChartSeriesData.sort( (a, b) => {
            return b["y"] - a["y"]
        })
        
        this.setState({
            seventhChartSeriesData: seventhChartSeriesData,
            seventhChartCategories: seventhChartCategories,
        })
        if(seventhChartSeriesData.length == 0)
        {
            this.setState({
                seventhChartMessage: "no data"
            })
        }
    }
    formatninthChartData(dead_mile_distance_for_competitor_trips)
    {
        this.setState({
            ninthChartSeriesData: [],
        })

        var ninthChartSeriesData = [];
        var ninthChartCategories = [];
        var routeId = {
            "W":"West",
            "N":"North",
            "S":"South",
            "E":"North East (Bidadi)",
            "Z":"North East (Haryana)",
            "P":"Export",
            "M":"Madhya Pradesh",
            "G":"North East (Gujarat)",
            "L":"Local",
        }
        dead_mile_distance_for_competitor_trips.map(function(e){
            ninthChartSeriesData.push({
                "name": routeId[e.route_id],
                "showTooltip": 1,
                "y": Math.round(e.avg_dead_mile_distance),
            })
            ninthChartCategories.push(routeId[e.route_id])
        });
        ninthChartSeriesData = ninthChartSeriesData.sort( (a, b) => {
            return b["y"] - a["y"]
        })
        
        this.setState({
            ninthChartSeriesData: ninthChartSeriesData,
            ninthChartCategories: ninthChartCategories,
        })
        if(ninthChartSeriesData.length == 0)
        {
            this.setState({
                ninthChartMessage: "no data"
            })
        }
    }
    formattenthChartData(dead_mile_distance_for_empty_trips)
    {
        this.setState({
            tenthChartSeriesData: [],
        })

        var tenthChartSeriesData = [];
        var tenthChartCategories = [];
        var routeId = {
            "W":"West",
            "N":"North",
            "S":"South",
            "E":"North East (Bidadi)",
            "Z":"North East (Haryana)",
            "P":"Export",
            "M":"Madhya Pradesh",
            "G":"North East (Gujarat)",
            "L":"Local",
        }
        dead_mile_distance_for_empty_trips.map(function(e){
            tenthChartSeriesData.push({
                "name": routeId[e.route_id],
                "showTooltip": 1,
                "y": Math.round(e.avg_dead_mile_distance),
            })
            tenthChartCategories.push(routeId[e.route_id])
        });
        tenthChartSeriesData = tenthChartSeriesData.sort( (a, b) => {
            return b["y"] - a["y"]
        })
        
        this.setState({
            tenthChartSeriesData: tenthChartSeriesData,
            tenthChartCategories: tenthChartCategories,
        })
        if(tenthChartSeriesData.length == 0)
        {
            this.setState({
                tenthChartMessage: "no data"
            })
        }
    }
    onGridReady = params => {
		this.gridApi = params.api;
        // var rowgrid = []
        // this.gridApi.forEachNode(function(node){
        //     rowgrid.push(node.data)
        // })
		this.gridApi.addCellRange({rowStartIndex:0,rowEndIndex:10000,columns:["dead_mile_distance_google"]})
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	onShowFullscreen() {
        $("#redmsdGrid").hide();
        $("#redmsd").show();
    }
    onShowFullscreen1() {
        $("#redmsdetGrid").hide();
        $("#redmsdet").show();
    }
    onShowFullscreen2() {
        $("#redmsdsGrid").hide();
        $("#redmsds").show();
    }
	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
    }

    
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
            overly:'show-n',
            breaksSlider : "",
            selectedRow : [],
            sliderRouteTranslate: ""
		});
		
    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
        var mm = (d.getMonth() +1)
        if(mm <10)
        {
            var month = "0"+mm
        }
        else{
            var month = mm
        }
         
    	var startdate = d.getFullYear()+"-"+month+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {

    	var d = new Date(event._d);

        var {startDate}=this.state 
        console.log(new Date(startDate),d,"date")
        var mm = (d.getMonth() +1)
        if(mm <10)
        {
            var month = "0"+mm
        }
        else{
            var month = mm
        }
         
    	var edate = d.getFullYear()+"-"+month+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
        this.setState({
    		endDate:edate
    	});
    }
    async submitForm(e){
        e.preventDefault();

        var fromdate = $(".startDate").val();
        var todate = $(".endDate").val()

        if(new Date(todate)<new Date(fromdate)){
            // alert("Todate should be greater than From Date")
            this.setState({
                basicTitle: "To Date should be greater than From Date",
                basicType: "warning",
                show: true
            })
        }else{
            console.log(fromdate,todate,new Date(fromdate),new Date(todate),"date")

            if(this.state.selectedCompetitors != null && 
                this.state.selectedCompetitors != undefined)
            {
                this.setState({
                    overly: "show-m",
                    loadshow: "show-m",
                    firstChartMessage : "",
                    secondChartMessage: "",
                    thirdChartMessage: "",
                    fourthChartMessage: "",
                    fifthChartMessage: "",
                    sixthChartMessage: "",
                    seventhChartMessage: "",
                    ninthChartMessage:"",
                    tenthChartMessage:"",
                    eighthChartMessage: "",
                    rowData1 : [],
                    rowData2 : [],
                    rowData3 : [],
                });
                $("#redmsdGrid").hide();
                $("#redmsd").show();
                $("#redmsdetGrid").hide();
                $("#redmsdet").show();
                $("#redmsdsGrid").hide();
                $("#redmsds").show();
                
                var fromdate = $(".startDate").val();
                var todate = $(".endDate").val()
                fromdate = fromdate.split("-")[0]+"-"+fromdate.split("-")[1]+"-"+fromdate.split("-")[2]
                todate = todate.split("-")[0]+"-"+todate.split("-")[1]+"-"+todate.split("-")[2]
                var query = {"from_date": fromdate+" 00:00:00",
                            "to_date": todate+" 23:59:59"}
                var competitors = []
                if(this.state.selectedCompetitors != undefined)
                {
                    var checkAllCompetitors = this.state.selectedCompetitors.filter(function(e){
                        return e.value == "ALL"
                    })
                }
                
                if(this.state.selectedCompetitors != null && 
                    this.state.selectedCompetitors != undefined && 
                    checkAllCompetitors.length==0)
                {
                    this.state.selectedCompetitors.map(function(e){
                        if(e.value != "ALL")
                        {
                            competitors.push(e.value)
                        }
                    })
                }
                var transporters = []
                if(this.state.selectedTransporters != undefined)
                {
                    var checkAllTransporters = this.state.selectedTransporters.filter(function(e){
                        return e.value == "ALL"
                    })
                }
                
                if(this.state.selectedTransporters != null && 
                    this.state.selectedTransporters != undefined && 
                    checkAllTransporters.length==0)
                {
                    this.state.selectedTransporters.map(function(e){
                        if(e.value != "ALL")
                        {
                        transporters.push(e.value)
                        }
                    })
                }
                if(this.state.dept_code == "SNDG")
                {
                    var consigner_locations = []
                    if(this.state.selectedConsigner != undefined)
                    {
                        var checkAllConsigner = this.state.selectedConsigner.filter(function(e){
                            return e.value == "ALL"
                        })
                    }
                    if(this.state.selectedConsigner != null && 
                        this.state.selectedConsigner != undefined && 
                        checkAllConsigner.length == 0)
                    {
                        this.state.selectedConsigner.map(function(e){
                            if(e.value != "ALL")
                            {
                                consigner_locations.push(e.value)
                            }
                        })
                    }
                }
                // zones
                if(this.state.dept_code == "LOG-PRT")
                {
                    var zones = []
                    if(this.state.selectedZones != undefined)
                    {
                        var checkAllZone = this.state.selectedZones.filter(function(e){
                            return e.value == "ALL"
                        })
                    }
                    if(this.state.selectedZones != null && 
                        this.state.selectedZones != undefined && 
                        checkAllZone.length == 0)
                    {
                        this.state.selectedZones.map(function(e){
                            if(e.value != "ALL")
                            {
                                zones.push(e.value)
                            }
                        })
                    }
                }
                //source
                var selectedSource = [];
                if(this.state.selectedSource != undefined)
                {
                    var checkAllSource = this.state.selectedSource.filter(function(e){
                        return e.value == "ALL"
                    })
                }
                
                if(this.state.selectedSource != null && 
                    this.state.selectedSource != undefined && 
                    checkAllSource.length == 0)
                {
                    this.state.selectedSource.map(function(e){
                        if(e.value != "ALL")
                        {
                            if(e.value == "H")
                            {
                                selectedSource.push("F")
                                selectedSource.push("M")
                            }
                            else
                            {
                                selectedSource.push(e.value)
                            }
                            
                        }
                    })
                }
                if(competitors.length){query['competitors']=JSON.stringify(competitors)}
                if(transporters.length){query['transporters']=JSON.stringify(transporters)}
                if(this.state.dept_code == "SNDG"){
                    if(consigner_locations.length){query['consigner_locations']=JSON.stringify(consigner_locations)}
                }
                if(selectedSource.length){query['consigner_code']=JSON.stringify(selectedSource)}
                if(this.state.dept_code == "LOG-PRT")
                {
                    if(zones.length){query['zones']=JSON.stringify(zones)}
                }
                query['dept_code'] = this.state.dept_code
                // console.log(query,"1149")
                redirectURL.post("/dashboard/oemlevelchartdata",query).then((response)=>{
                    this.setState({
                        overly:"show-n",
                        loadshow: "show-n"
                    })
                    console.log(response.data)
                    if(response.data.api.message == undefined)
                    {
                        this.formatChartData(JSON.parse(response.data.api.competitor_list),
                                        JSON.parse(response.data.api.transporter_list),JSON.parse(response.data.api.routes_wise_return_duration_df))
                        
                    }
                    else
                    {
                        this.setState({
                            firstChartMessage : "no data",
                            eighthChartMessage: "no data",
                            // seriesData: [],
                            // drilldownSeriesData : [],
                            rowData: [],
                        })
                    }
                }).catch((e)=>console.log(e))
                var oemAPI1 = redirectURL.post("/dashboard/getRouteWiseSummaryForOEMTrips",query).then((response)=>{           
                    if (response.data.api && response.data.api.grid_data) {
                            this.setState({
                                ReturnTripsRowData: JSON.parse(response.data.api.grid_data),
                            });
                        } else {
                            
                        }
                    
                }).catch((e)=>{
                    console.log(e)
                });
                redirectURL.post("/dashboard/routeWiseReturnTrips",query).then((response)=>{
                    console.log(response.data)
                    if(response.data.api.message == undefined)
                    {
                        this.setState({
                            drilldowncols: [],
                            drilldowncols1: [],
                            drilldownlines : []
                        })
                        this.formatSecondAndThirdChartData(response.data.api.total_trips,
                            response.data.api.return_trips,
                            JSON.parse(response.data.api.route_wise_trips),
                            JSON.parse(response.data.api.route_wise_oem_data),
                            JSON.parse(response.data.api.route_wise_cluster_df),
                            eval(response.data.api.route_wise_column_chart),
                            eval(response.data.api.route_wise_column_chart1),
                            eval(response.data.api.route_wise_line_chart))
                    }
                    else
                    {
                        this.setState({
                            secondChartMessage : "no data",
                            thirdChartMessage : "no data",
                            overly:"show-n",
                            loadshow: "show-n",
                            // secondChartSeriesData: [],
                            // secondChartDrilldownColumn: [],
                            // secondChartDrilldownColumn1: [],
                            // secondChartDrilldownLine: [],
                            // secondChartOEMWiseReturnColumn: [],
                            // route_wise_categories: [],
                            // route_wise_series: [],
                        })
                    }
                }).catch((e)=>console.log(e))
                redirectURL.post("/dashboard/transporterWiseReturnTripsChart",query).then((response)=>{
                    console.log(response.data.api,"1206")
                    if(response.data.api.message == undefined)
                    {
                        this.formatFourthChartData(JSON.parse(response.data.api.transporter_level_return_trips),
                                                    JSON.parse(response.data.api.oem_level_return_trips))
                        this.formatSeventhChartData(JSON.parse(response.data.api.dead_mile_dst_avg))
                        this.formatninthChartData(JSON.parse(response.data.api.dead_mile_distance_for_competitor_trips))
                        this.formattenthChartData(JSON.parse(response.data.api.dead_mile_distance_for_empty_trips))
                    
                    }
                    else
                    {
                        this.setState({
                            fourthChartMessage : "no data",
                            seventhChartMessage: "no data",
                            ninthChartMessage:"no data",
                            tenthChartMessage:"no data",
                            // thirdChartSeriesData: [],
                            // thirdChartDrilldownData : [],
                            overly:"show-n",
                            loadshow: "show-n"
                        })
                    }
                    
                }).catch((e)=>console.log(e))
                redirectURL.post("/dashboard/oemWiseAvgPreLoadingTime",query).then((response)=>{
                    if(response.data.message == undefined)
                    {
                        this.formatFifthChartData(JSON.parse(response.data.avg_pre_loading_time))
                    }
                    else
                    {
                        this.setState({
                            fifthChartMessage: "no data"
                        })
                    }
                }).catch((e)=>{
                    console.log(e)
                })
                redirectURL.post("/dashboard/oemWiseAvgLoadingTime",query).then((response)=>{
                    console.log(response.data,"loading time")
                    if(response.data.message == undefined)
                    {
                        console.log("filtered 6th chart data",response.data)
                        this.formatSixthChartData(JSON.parse(response.data.avg_loading_time))
                    }
                    else
                    {
                        this.setState({
                            sixthChartMessage: "no data"
                        })
                    }
                }).catch((e)=>{
                    console.log(e)
                });
    
                this.getEmptyReturnTripsData(query)
    
                this.setState({
                    overly: "show-n",
                    loadshow: "show-n"
                })
            }
            else
            {
                this.setState({
                    basicTitle: "Select atleast one competitor",
                    basicType: "warning",
                    show: true
                })
            }
        }

  
        
    }
   async onClickedCell(row){
        if(row.colDef.field == "_id")
        {
            this.setState({
                loadshow:'show-m',
                overly:'show-m'
            })
            var reqData={
                from_date: getHyphenYYYYMMDDHHMMSS(row.data.return_start_time),
                to_date: getHyphenYYYYMMDDHHMMSS(row.data.return_end_time),
                truck_no: row.data.truck_no
            }
            redirectURL.post('/consignments/returntripmap',reqData, {
                headers:{
                    'content-type':'application/json'
                }
            })
            .then((response) => {
                this.setState({
                    sliderRouteTranslate:"slider-translate-60p",
                    mapinfo:JSON.parse(response.data.data),
                    loadshow:'show-n',
                    overly:'show-m'
                })
            })
        }
        if(row.colDef.field == "show_legs")
        {
            console.log(row)
            console.log(row.node.expanded)
            let eventLabel = googleAnalytics.page.action.transitDelay;
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
        
            var transitedelaydata=[];
            
           await this.setState({
                    
                detailCellRendererParams:{
                    suppressRefresh: true,
                    detailGridOptions: {
                        headerHeight:50,
                        columnDefs: [
                            { 
                                headerName: "Truck No",
                                field: "truck_no",
                                width:100,
                                resizable: true,
                            },
                            {
                                headerName: "OEM Name",
                                field: "comp_name",
                                resizable : true,
                                width: 130
                            },
                            {
                                headerName: "OEM Gate In",
                                field: "comp_gate_in",
                                resizable : true,
                                width: 130,
                                valueGetter : function(params){
                                    return getHyphenDDMMMYYYYHHMM(params.data.oem_geofence_in)
                                }
                            },
                
                            {
                                headerName: "OEM Gate Out",
                                field: "comp_gate_out",
                                resizable : true,
                                width: 130,
                                valueGetter : function(params){
                                    return getHyphenDDMMMYYYYHHMM(params.data.oem_geofence_out)
                                }
                            },
                            // {
                            //     headerName: "OEM Location",
                            //     field: "comp_coords",
                            //     resizable : true,
                            //     width: 130,
                            // },
                            {
                                headerName: "Time at OEM Vicinity",
                                field: "oem_loading_duration",
                                resizable : true,
                                width: 130,
                                valueGetter: function(params)
                                {
                                    try{

                                        var d = Math.floor(params.data.oem_loading_duration / 86400);
                                        var h = Math.floor(params.data.oem_loading_duration % (3600*24) / 3600);
                                        var m = Math.floor(params.data.oem_loading_duration % 3600 / 60);
                                        return d.toString()+" days:"+h.toString()+" hours:"+m.toString()+" mins"
                                    }
                                    catch(e){
                                        return ""
                                    }  
                                    
                                }
                            },
                            
                            
                            // {
                            //     headerName: "Valid / Invalid Trip",
                            //     field: "valid_trip",
                            //     resizable : true,
                            //     width: 130,
                            //     valueGetter : function(params){
                            //         if(params.data.valid_trip == 1)
                            //         {
                            //             return "Yes";
                            //         }
                            //         else{
                            //             return "No";
                            //         }
                            //     }
                            // },
                            // {
                            //     headerName: "Dead Mile Distance (KM)",
                            //     field: "dead_mile_distance_google",
                            //     resizable : true,
                            //     width: 130,
                            // },
                        						
                        ],
                        overlayNoRowsTemplate: 'No rows to show',						
                    },
                    getDetailRowData: function(params) {
                        //console.log("Step 4 ",transitedelaydata)
                        var filtered_valid_trips = params.data.competitor_consignments.filter(function(e){
                            return e.valid_trip == 1
                        });
                        filtered_valid_trips = filtered_valid_trips.sort(function(a,b){
                            return b['oem_order'] - a['oem_order'] 
                        })
                        params.successCallback(filtered_valid_trips);
                        
                    }
                }
            });
            console.log("row.colDef ", row.colDef)
            //if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
            if(row.colDef.field == 'show_legs')
            {
                row.node.setExpanded(!row.node.expanded);
                //row.node.expanded = true;
                //console.log("tt ",row.node.setExpanded(!row.node.expanded))

            }
            else{

                row.node.setExpanded(false);
            }
            //console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
        }
    }
    onCloseRouteDiv = () => {
		this.setState({
			sliderRouteTranslate:"",
            overly:'show-n',
            loadshow:"show-n"
		});
    }
    onClickTab(tab){
        console.log(tab,"tab")
        if(tab == "analysis")
        {
            var analysis = "activet";
            var analysis_data = "";
            var showGrid = "show-n";
            var showAnalysis = "show-m"
        }
        if(tab == "analysis_data")
        {
            var analysis = "";
            var analysis_data = "activet";
            var showGrid = "show-m";
            var showAnalysis = "show-n";
            this.setState({
                overly: "show-m",
                loadshow: "show-m"
            });
            var fromdate = $(".startDate").val();
            var todate = $(".endDate").val()
            // fromdate = fromdate.split("-")[2]+"-"+fromdate.split("-")[1]+"-"+fromdate.split("-")[0]
            // todate = todate.split("-")[2]+"-"+todate.split("-")[1]+"-"+todate.split("-")[0]
            console.log(todate, "todate grid")
            var query = {"from_date": fromdate+" 00:00:00",
                        "to_date": todate+" 23:59:59"}
            var competitors = []
            var checkAllCompetitors = []
            if(this.state.selectedCompetitors !=undefined && this.state.selectedCompetitors !="")
            {
                checkAllCompetitors = this.state.selectedCompetitors.filter(function(e){
                    return e.value == "ALL"
                })
            }
            if(this.state.selectedCompetitors != null && 
                this.state.selectedCompetitors.length && 
                checkAllCompetitors.length==0)
            {
                this.state.selectedCompetitors.map(function(e){
                    if(e.value != "ALL")
                    {
                        competitors.push(e.value)
                    }
                })
            }
            var transporters = []
            var checkAllTransporters = []
            if(this.state.selectedTransporters !=undefined && this.state.selectedTransporters !="")
            {
                checkAllTransporters = this.state.selectedTransporters.filter(function(e){
                    return e.value == "ALL"
                })
            }
            if(this.state.selectedTransporters != null && 
                this.state.selectedTransporters.length && 
                checkAllTransporters.length==0)
            {
                this.state.selectedTransporters.map(function(e){
                    if(e.value != "ALL")
                    {
                    transporters.push(e.value)
                    }
                })
            }
            if(this.state.dept_code == "SNDG")
            {
                var consigner_locations = []
                var checkAllConsigner = []
                if(this.state.selectedConsigner !=undefined && this.state.selectedConsigner !="")
                {
                    checkAllConsigner = this.state.selectedConsigner.filter(function(e){
                        return e.value == "ALL"
                    })
                }
                if(this.state.selectedConsigner != null && 
                    this.state.selectedConsigner.length && 
                    checkAllConsigner.length == 0)
                {
                    this.state.selectedConsigner.map(function(e){
                        if(e.value != "ALL")
                        {
                            consigner_locations.push(e.value)
                        }
                    })
                }
            }
            
            // zones
            if(this.state.dept_code == "LOG-PRT")
            {
                var zones = []
                if(this.state.selectedZones != undefined)
                {
                    var checkAllZone = this.state.selectedZones.filter(function(e){
                        return e.value == "ALL"
                    })
                }
                if(this.state.selectedZones != null && 
                    this.state.selectedZones != undefined && 
                    checkAllZone.length == 0)
                {
                    this.state.selectedZones.map(function(e){
                        if(e.value != "ALL")
                        {
                            zones.push(e.value)
                        }
                    })
                }
            }  
            
            var selectedSource = [];
            var checkAllSource = [];
            if(this.state.selectedSource !=undefined && this.state.selectedSource !="")
            {
                checkAllSource = this.state.selectedSource.filter(function(e){
                    return e.value == "ALL"
                })
            }
            if(this.state.selectedSource != null && 
                this.state.selectedSource.length && 
                checkAllSource.length == 0)
            {
                this.state.selectedSource.map(function(e){
                    if(e.value != "ALL")
                    {
                        if(e.value == "H")
                        {
                            selectedSource.push("F")
                            selectedSource.push("M")
                        }
                        else
                        {
                            selectedSource.push(e.value)
                        }
                    }
                })
            }
            if(competitors.length){query['competitors']=JSON.stringify(competitors)}
            if(transporters.length){query['transporters']=JSON.stringify(transporters)}
            if(this.state.dept_code == "SNDG"){
                if(consigner_locations.length){query['consigner_locations']=JSON.stringify(consigner_locations)}
            }
            if(selectedSource.length){query['consigner_code']=JSON.stringify(selectedSource)}
            if(this.state.dept_code == "LOG-PRT")
            {
                if(zones.length){query['zones']=JSON.stringify(zones)}
            }
            var flag = 0;
            if(Object.keys(this.state.gridQuery).length == 0)
            {
                this.setState({
                    gridQuery: query
                });
                flag = 1;
                console.log("first call")
            }
            else
            {
                var gridFlag = 0;
                for(var key in query)
                {
                    if(query[key] != this.state.gridQuery[key])
                    {
                        gridFlag = 1;
                    }
                }
                if(gridFlag == 1)
                {
                    flag = 1;
                    console.log(query);
                    console.log(this.state.gridQuery)
                }
            }
            if(flag == 1)
            {
                console.log("fetching data...",query)
                query['dept_code'] = this.state.dept_code;

                redirectURL.post("/dashboard/getReturnTripsChartGridData",query).then((response)=>{
                    console.log(response.data,response.data.complete_records,"Competitive geofences")
                    var data=JSON.parse(response.data.valid_trips)
                    let valid_trips=[]
                    if(JSON.parse(data.final_data)){
                        valid_trips=JSON.parse(data.final_data)
                    }


                    console.log(data,"1836")
                    this.setState({
                        rowData: response.data.complete_records,
                        competitor_geofences: response.data.competitor_geofences,
                        complete_trips_data:response.data.complete_records,
                        valid_trips_data:valid_trips,
                        overly: "show-n",
                        loadshow: "show-n"
                    })
                }).catch((e)=>{
                    console.log(e)
                })
            }
            else
            {
                console.log("Data already exists.");
                this.setState({
                    overly: "show-n",
                    loadshow: "show-n"
                })
            }
        }

        this.setState({
            analysis : analysis,
            analysis_data: analysis_data,
            showGrid : showGrid,
            showAnalysis: showAnalysis
        })

        // if(tab == "complete_trips")
        // {
        //     var analysis = "activet";
        //     var analysis_data = "";
        //     var showGrid = "show-n";
        //     var showAnalysis = "show-m"
        //     this.setState({
        //         complete_trips_tab:"activet",
        //         valid_trips:""
        //     })
        // }
        // if(tab == "valid_trips")
        // {
        //     var analysis = "activet";
        //     var analysis_data = "";
        //     var showGrid = "show-n";
        //     var showAnalysis = "show-m"
        //     this.setState({
        //         complete_trips_tab:"",
        //         valid_trips:"activet"
        //     })
        // }
        
    }

    onClickInnerTabs=(tab)=>{
        console.log(tab,"tab")
           if(tab == "complete_trips")
        {
           
            this.setState({
                complete_trips_tab:"activet",
                valid_trips_tab:"",
                // rowData:this.state.complete_trips_data
            })
        }
        else
        {
           
            this.setState({
                complete_trips_tab:"",
                valid_trips_tab:"activet",
                // rowData:this.state.valid_trips_data
            })
        }

    }
    // END
    onClickGetGridData = (title,name) =>{
       
        
        console.log(title,"1651");
        console.log(name,"1652")
        if(title!="OEM Wise Avg Loading Time" && title!="OEM Wise Avg Pre-Loading Time"){
            this.setState({
                overly :"show-m",
                loadshow : "show-m"
            })
            var chart_title = title
            var bar_name = name
            var consigner_location={
                "West":"W",
                "North":"N",
                "South":"S",
                "North East (Bidadi)":"E",
                "North East (Haryana)":"Z",
                "Export":"P",
                "Madhya Pradesh":"M",
                "North East (Gujarat)":"G",
                "Local":"L",
            }
            var check = consigner_location[name]
            console.log(check,"1667")
            var consigner_locations = [check]
            var fromdate = $(".startDate").val();
                var todate = $(".endDate").val()
                fromdate = fromdate.split("-")[0]+"-"+fromdate.split("-")[1]+"-"+fromdate.split("-")[2]
                todate = todate.split("-")[0]+"-"+todate.split("-")[1]+"-"+todate.split("-")[2]
               var params = {"from_date": fromdate+" 00:00:00",
                            "to_date": todate+" 23:59:59"} 
            var selectedSource = [];
                if(this.state.selectedSource != undefined)
                {
                    var checkAllSource = this.state.selectedSource.filter(function(e){
                        return e.value == "ALL"
                    })
                }
                
                if(this.state.selectedSource != null && 
                    this.state.selectedSource != undefined && 
                    checkAllSource.length == 0)
                {
                    this.state.selectedSource.map(function(e){
                        if(e.value != "ALL")
                        {
                            if(e.value == "H")
                            {
                                selectedSource.push("F")
                                selectedSource.push("M")
                            }
                            else
                            {
                                selectedSource.push(e.value)
                            }
                            
                        }
                    })
                }
            var competitors = []
                if(this.state.selectedCompetitors != undefined)
                {
                    var checkAllCompetitors = this.state.selectedCompetitors.filter(function(e){
                        return e.value == "ALL"
                    })
                }
                
                if(this.state.selectedCompetitors != null && 
                    this.state.selectedCompetitors != undefined && 
                    checkAllCompetitors.length==0)
                {
                    this.state.selectedCompetitors.map(function(e){
                        if(e.value != "ALL")
                        {
                            competitors.push(e.value)
                        }
                    })
                }
            var transporters = []
                if(this.state.selectedTransporters != undefined)
                {
                    var checkAllTransporters = this.state.selectedTransporters.filter(function(e){
                        return e.value == "ALL"
                    })
                }
                
                if(this.state.selectedTransporters != null && 
                    this.state.selectedTransporters != undefined && 
                    checkAllTransporters.length==0)
                {
                    this.state.selectedTransporters.map(function(e){
                        if(e.value != "ALL")
                        {
                        transporters.push(e.value)
                        }
                    })
                }
                if(competitors.length){params['competitors']=JSON.stringify(competitors)}
                if(transporters.length){params['transporters']=JSON.stringify(transporters)}
                if(selectedSource.length){params['consigner_code']=JSON.stringify(selectedSource)}
                params.consigner_locations = JSON.stringify(consigner_locations)
                params.dept_code = "SNDG"
            console.log(params,"1679")
            try {
                redirectURL.post("dashboard/getGriddataforcharts", params).then((response) => {
                    // Check if response data is defined
                    if (response.data && response.data.api) {
                        // Parse response data if it exists
                        var resp1 = response.data.api.route_wise_avg_deadmile ? JSON.parse(response.data.api.route_wise_avg_deadmile) : null;
                        var resp2 = response.data.api.route_wise_avg_deadmile_for_empty_trips ? JSON.parse(response.data.api.route_wise_avg_deadmile_for_empty_trips) : null;
                        var resp3 = response.data.api.route_wise_avg_deadmile_summation ? JSON.parse(response.data.api.route_wise_avg_deadmile_summation) : null;
                        console.log(resp1, "1775");
                        this.setState({
                            rowData1: resp1 || [],
                            rowData2: resp2 || [],
                            rowData3: resp3 || [],
                            overly: "show-n",
                            loadshow: "show-n"
                        });
                    } else {
                        // Handle case where response data is undefined
                        this.setState({
                            rowData1: [],
                            rowData2: [],
                            rowData3: [],
                            overly: "show-n",
                            loadshow: "show-n"
                        });
                    }
                });
            } catch (e) {
                console.error("Error:", e);
            }
            
        }
      
    }

    onClickTabChange=(tab)=>{
        console.log(tab,"tab")
        if(tab=="graphTab"){
            this.setState({
                graphTab:"activet",
                gridTab:""
            })
        }else if(tab=="gridTab"){
            this.setState({
                gridTab:"activet",
                graphTab:""
            })
        }else if(tab=="graphTabInital"){
            this.setState({
                graphTabInital:"activet",
                gridTabInital:""
            })
        }else{
            this.setState({
                gridTabInital:"activet",
                graphTabInital:""
            })
        }
        
    }

    render(){
        const showSNDHeader = (this.state.dept_code == "SNDG")?false:true;
        const showPRTHeader = (this.state.dept_code == "LOG-PRT")?false:true;
        const columnwithDefs=[
            {
                headerName: "",
                field: "_id",
                cellRenderer: function(params)
                {
                    return "<i class='icofont icofont-map-pins f25'></i>";
                },
                width : 60
            },
            {
                headerName: "",
                field: "show_legs",
                cellRenderer: function(params)
                {
                    return "<i class='icofont icofont-vehicle-delivery-van cus-i f25'></i>";
                },
                width : 60
            },
            {
                headerName: "Truck No",
                field: "truck_no",
                resizable : true,
                width: 130
            },
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                resizable: true,
                width: 130
            },
            {
                headerName: "Transporter Code",
                field: "transporter_code",
                resizable : true,
                width: 110
            },
            // {
            //     headerName: "Consignment No(s)",
            //     field: "consignment_codes",
            //     resizable : true,
            //     width: 130
            // },
            {
                headerName: "Consigner Location",
                field: "consigner_code",
                resizable : true,
                width: 110,
                
            },
            {
                headerName: "Zone",
                field: "zone",
                resizable : true,
                width: 110,
                hide: showPRTHeader
            },
            {
                headerName: "Return MSIL Plant",
                field: "next_trip_consigner_code",
                resizable : true,
                width: 110
            },
            {
                headerName: "Cluster",
                field: "cluster",
                resizable : true,
                width: 110,
                hide: showSNDHeader
            },
            {
                headerName: "Route ID",
                field: "route_id",
                resizable : true,
                width: 110,
                hide: showSNDHeader
            },
            // {
            //     headerName: "No. of OEM's",
            //     field: "no_of_oems",
            //     resizable : true,
            //     width: 100,
            //     valueGetter: function(params){
            //         var filtered = params.data.competitor_consignments.filter(function(e){
            //             return e.valid_trip == 1
            //         })
            //         return filtered.length
            //     }
            // },
            {
                headerName: "Return Trip Start",
                field: "return_start_time",
                resizable : true,
                width: 130,
                valueGetter : function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.return_start_time)
                }
            },
            {
                headerName: "Return Trip End",
                field: "return_end_time",
                resizable : true,
                width: 130,
                valueGetter : function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.return_end_time)
                }
            },
            
            {
                headerName: "Distance travelled in return trip (KM)",
                field: "dead_mile_distance_google",
                resizable : true,
                width: 130,
                hide: showSNDHeader
            },
            {
                headerName: "Dead Mile Distance (KM)",
                field: "dead_mile_distance_google",
                colId: "dead_mile_distance_google",
                resizable : true,
                width: 130,
                hide: showSNDHeader
            },
            {
                headerName: "On Trip Distance",
                field: "distance_from_plant_to_dealer_google",
                // colId: "distance_from_plant_to_dealer_google",
                resizable : true,
                width: 130,
                // pinned:"right",
                // hide: false
            },
            
        ];
        var columnDefsValidTrips=[
          
            {
                headerName: "Consignment Codes",
                field: "consignment_codes",
                resizable : true,
                width: 130
            },
            {
                headerName: "Source Plants",
                field: "consigner_code",
                resizable: true,
                width: 130
            },
            {
                headerName: "Destination City",
                field: "consignee_city",
                resizable : true,
                width: 110
            },
            // {
            //     headerName: "Consignment No(s)",
            //     field: "consignment_codes",
            //     resizable : true,
            //     width: 130
            // },
            {
                headerName: "Route",
                field: "route_id",
                resizable : true,
                width: 110,
                
            },
            {
                headerName: "Cluster",
                field: "cluster",
                resizable : true,
                width: 110,
                hide: showPRTHeader
            },
            {
                headerName: "Carrier No",
                field: "truck_no",
                resizable : true,
                width: 110
            },
            {
                headerName: "LSP code",
                field: "transporter_code",
                resizable : true,
                width: 110,
            },
            {
                headerName: "LSP Name",
                field: "transporter_name",
                resizable : true,
                width: 110,

            },
            // {
            //     headerName: "No. of OEM's",
            //     field: "no_of_oems",
            //     resizable : true,
            //     width: 100,
            //     valueGetter: function(params){
            //         var filtered = params.data.competitor_consignments.filter(function(e){
            //             return e.valid_trip == 1
            //         })
            //         return filtered.length
            //     }
            // },
            {
                headerName: "Dealer Unloading Date",
                field: "return_start_time",
                resizable : true,
                width: 130,
                valueGetter : function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.return_start_time)
                }
            },
            {
                headerName: "Next OEM Travelled Date",
                field: "return_start_time",
                resizable : true,
                width: 130,
                valueGetter : function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.return_start_time)
                }
            },
            
            {
                headerName: "OEM Name",
                field: "comp_name",
                resizable : true,
                width: 130,
                hide: showSNDHeader
            },
            {
                headerName: "OEM Location",
                field: "comp_coords",
                resizable : true,
                width: 130,
                valueGetter:(params)=>{
                    let loc=JSON.parse(params.data.comp_coords)
                    return loc.lat+","+loc.lng
                }
            },
            {
                headerName: "Distance Travelled(Kms)",
                field: "dead_mile_distance_google",
                // colId: "distance_from_plant_to_dealer_google",
                resizable : true,
                width: 130,
                // pinned:"right",
                // hide: false
            },
            
        ];
        const columnwithDefs1=[
            {
                headerName: "Truck No",
                field: "truck_no",
                resizable : true,
                width: 140
            },
            {
                headerName: "Source",
                field: "consigner_code",
                resizable: true,
                width: 140,
                valueGetter:function(params){
					if(params.data.consigner_code == "BP"){
                        return "Bidadi"
                    }
                    else if(params.data.consigner_code == "F"){
                        return "Gurgaon"
                    }
                    else if(params.data.consigner_code == "M"){
                        return "Manesar"
                    }
                    else if(params.data.consigner_code == "G"){
                        return "Gujarat"
                    }else if(params.data.consigner_code == "D"){
                        return "Dobaspet"
                    }
                    else{
                        return ""
                    }
				}
            },
            {
                headerName: "Destination",
                field: "",
                resizable : true,
                width: 300,
                valueGetter:function(params){
					if(params.data.consignee_city != undefined && params.data.consignee_city != null && params.data.consignee_city != "" && params.data.consignee_name != undefined && params.data.consignee_name != null && params.data.consignee_name != "" && params.data.consignee_state != undefined && params.data.consignee_state != null && params.data.consignee_state != ""){
                        return params.data.consignee_name+" , "+params.data.consignee_city+" , "+params.data.consignee_state          
                    }
                    else{
                        return ""
                    }
				}
            },
            {
                headerName: "Nearest OEM",
                field: "comp_name",
                resizable : true,
                width: 250
            },
            {
                headerName: "Dead Mile Distance",
                field: "dead_mile_distance_google",
                resizable : true,
                width: 150
            },
            
        ];
        const columnwithDefs2=[
            {
                headerName: "Truck No",
                field: "truck_no",
                resizable : true,
                width: 130
            },
            {
                headerName: "Source",
                field: "consigner_code",
                resizable : true,
                width: 130,
                valueGetter:function(params){
					if(params.data.consigner_code == "BP"){
                        return "Bidadi"
                    }
                    else if(params.data.consigner_code == "F"){
                        return "Gurgaon"
                    }
                    else if(params.data.consigner_code == "M"){
                        return "Manesar"
                    }
                    else if(params.data.consigner_code == "G"){
                        return "Gujarat"
                    }else if(params.data.consigner_code == "D"){
                        return "Dobaspet"
                    }
                    else{
                        return ""
                    }
				}
            },
            {
                headerName: "Destination",
                field: "",
                resizable : true,
                width: 300,
                valueGetter:function(params){
                    var check1 =""
                    var check2 =""
                    var check3 = ""
					if(params.data.consignee_city != undefined && params.data.consignee_city != null && params.data.consignee_city != ""){
                        check1 = params.data.consignee_city
                    }else{
                        check1 = ""
                    }
                    if(params.data.consignee_name != undefined && params.data.consignee_name != null && params.data.consignee_name != "" ){
                        check2 = params.data.consignee_name
                    }else{
                        check2 = ""
                    }
                    if(params.data.consignee_state != undefined && params.data.consignee_state != null && params.data.consignee_state != ""){
                        check3 = params.data.consignee_state
                    }else{
                        check3 = ""
                    }
                    return check1+" , "+check2+" , "+check3
				}
            },
            {
                headerName: "Return Source",
                field: "next_trip_consigner_code",
                resizable : true,
                width: 250,
                valueGetter:function(params){
					if(params.data.next_trip_consigner_code == "BP"){
                        return "Bidadi"
                    }
                    else if(params.data.next_trip_consigner_code == "F"){
                        return "Gurgaon"
                    }
                    else if(params.data.next_trip_consigner_code == "M"){
                        return "Manesar"
                    }
                    else if(params.data.next_trip_consigner_code == "G"){
                        return "Gujarat"
                    }
                    else if(params.data.next_trip_consigner_code == "D"){
                        return "Dobaspet"
                    }
                    
                    else if(params.data.next_trip_consigner_code == "N"){
                        return "TVP-Nagpur"
                    }
                    else if(params.data.next_trip_consigner_code == "B"){
                        return "TVP-Banglore"
                    }
                    else if(params.data.next_trip_consigner_code == "I"){
                        return "Siliguri"
                    }
                    else{
                        return ""
                    }
				}
            },
            {
                headerName: "Dead Mile Distance",
                field: "dead_mile_distance_google",
                resizable : true,
                width: 150
            },
            
        ];
        const columnwithDefs3=[
            {
                headerName: "Truck No",
                field: "truck_no",
                resizable : true,
                width: 130
            },
            {
                headerName: "Source",
                field: "consigner_code",
                resizable : true,
                width: 130,
                valueGetter:function(params){
					if(params.data.consigner_code == "BP"){
                        return "Bidadi"
                    }
                    else if(params.data.consigner_code == "F"){
                        return "Gurgaon"
                    }
                    else if(params.data.consigner_code == "M"){
                        return "Manesar"
                    }
                    else if(params.data.consigner_code == "G"){
                        return "Gujarat"
                    }else if(params.data.consigner_code == "D"){
                        return "Dobaspet"
                    }
                    else{
                        return ""
                    }
				}
            },
            {
                headerName: "Destination",
                field: "",
                resizable : true,
                width: 300,
                valueGetter:function(params){
                    var check1 =""
                    var check2 =""
                    var check3 = ""
					if(params.data.consignee_city != undefined && params.data.consignee_city != null && params.data.consignee_city != ""){
                        check1 = params.data.consignee_city
                    }else{
                        check1 = ""
                    }
                    if(params.data.consignee_name != undefined && params.data.consignee_name != null && params.data.consignee_name != "" ){
                        check2 = params.data.consignee_name
                    }else{
                        check2 = ""
                    }
                    if(params.data.consignee_state != undefined && params.data.consignee_state != null && params.data.consignee_state != ""){
                        check3 = params.data.consignee_state
                    }else{
                        check3 = ""
                    }
                    return check1+" , "+check2+" , "+check3
				}
            },
            {
                headerName: "Return Source / Other OEM",
                field: "comp_name",
                resizable : true,
                width: 250,
                valueGetter:function(params){
                    if(params.data.comp_name != undefined && params.data.comp_name != null && params.data.comp_name != null ){
                        if(params.data.comp_name == "BP"){
                            return "Bidadi"
                        }
                        else if(params.data.comp_name == "F"){
                            return "Gurgaon"
                        }
                        else if(params.data.comp_name == "M"){
                            return "Manesar"
                        }
                        else if(params.data.comp_name == "G"){
                            return "Gujarat"
                        }
                        else if(params.data.comp_name == "D"){
                            return "Dobaspet"
                        }
                        else if(params.data.comp_name == "N"){
                            return "TVP-Nagpur"
                        }
                        else if(params.data.comp_name == "B"){
                            return "TVP-Banglore"
                        }
                        else if(params.data.comp_name == "I"){
                            return "Siliguri"
                        }
                        else{
                            return params.data.comp_name
                        }
                    }
                    else{
                        return ""
                    }
				}
            },
            {
                headerName: "Dead Mile Distance",
                field: "dead_mile_distance_google",
                resizable : true,
                width: 150
            },
            
        ]

        const emptyReturnTripsColumnDefs=[
            {
                headerName: "Plant",
                field: "plant_rows_span",
                resizable : true,
                // width: 130,
                // rowSpan:4,
                cellClass: function(params){
                    if(params.data.plant_rows_span.length>0)
                    {
                        return ["cellstylegridG2","pstBg"]
                    }else{return "textAlignCenter"}
                    // if(params.data.route=="Total"){
                    //     return ["cellstylegridG3","pstBg"]
                    // }else{
                    //     return ""
                    // }
                },
                rowSpan:function(params){
                    // return 4
                    console.log(params,"params")
                    if(params.data.count_rows_span){
                        return params.data.count_rows_span-1
                    }
                    return 0
                }
            },
            {
                headerName: "Route",
                field: "route",
                resizable : true,
                // width: 130
            },
            {
                headerName: "Avg. Return Trip Distance(Kms)",
                field: "avg_trip_dis",
                resizable : true,
                // width: 130
            },
            {
                headerName: "Total Trips",
                field: "total_trips",
                resizable : true,
                // width: 130
            },
            {
                headerName: "No of Return Trips",
                field: "empty_trips",
                resizable : true,
                // width: 130
            },
            {
                headerName: "% of Return Trips",
                field: "empty_trip_per",
                resizable : true,
                // width: 130
            },
        ]
        const ReturnTripsColumnDefs=[
            {
                headerName: "Plant",
                field: "plant_rows_span",
                resizable : true,
                // width: 130,
                // rowSpan:4,
                cellClass: function(params){
                    if(params.data.plant_rows_span.length>0)
                    {
                        return ["cellstylegridG2","pstBg"]
                    }else{return "textAlignCenter"}
                    // if(params.data.route=="Total"){
                    //     return ["cellstylegridG3","pstBg"]
                    // }else{
                    //     return ""
                    // }
                },
                rowSpan:function(params){
                    // return 4
                    console.log(params,"params")
                    if(params.data.count_rows_span){
                        return params.data.count_rows_span-1
                    }
                    return 0
                }
            },
            {
                headerName: "Route",
                field: "route",
                resizable : true,
                // width: 130
            },
            {
                headerName: "Avg. Dead Mile Distance(Kms)",
                field: "avg_deadmile_distance",
                resizable : true,
                // width: 130
            },
            {
                headerName: "Total Trips",
                field: "total_trips",
                resizable : true,
                // width: 130
            },
            {
                headerName: "No of Valid Trips",
                field: "valid_trips",
                resizable : true,
                // width: 130
            },
            {
                headerName: "% of Valid Trips",
                field: "valid_trip_per",
                resizable : true,
                // width: 130
            },
        ]
        const columnwithDefs4 = [
            {
                headerName: "Truck No",
                field: "truck_no",
                resizable : true,
                width: 130
            },
            {
                headerName: "Route",
                field: "route",
                resizable : true,
                width: 130
            },
            {
                headerName: "Avg Dead Mile Distance",
                field: "avg_deadmile_distance",
                resizable : true,
                width: 200,
            },
            {
                headerName: "Avg Empty Return Distance",
                field: "avg_empty_run_distance",
                resizable : true,
                width: 200,
            },
            {
                headerName: "Avg Off-Trip Distance",
                field: "avg_offtrip_distance",
                resizable : true,
                width: 200,
            },
            {
                headerName: "Avg On-Trip Distance",
                field: "avg_deadmile_distance",
                resizable : true,
                width: 200,
            }
        ]

        var gridOptions2 = {
            // Enable row spanning
            enableRowSpan: true,
        
            // Define unique row ID
            getRowNodeId: function(data) {
                console.log(data.id,data,"data.id")
                return data.avg_trip_dis; // Assuming 'id' is a unique identifier for each row
            },
        
            // Calculate row span dynamically
            getRowSpan: function(params) {
                var data = params.data;
                var previousRowData = params.previousRow ? params.previousRow.data : null;
        
                // Compare current row data with previous row data to determine if they are identical
                if (previousRowData && data.plant_name === previousRowData.plant_name) {
                    // If they are identical, return 0 to indicate that this row should be spanned
                    return 0;
                }
        
                // If not identical, return 1 to indicate that this row should not be spanned
                return 1;
            },
        
            // Other grid options...
        };
        

        var showChart=false
        var username=localStorage.getItem("username")
        if (username=="Nanda Kishore"){
            showChart=true
        }

        return ( 
        		<div className="col-xl-12 col-lg-12">
                    <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                    >
                    </SweetAlert>
                    <div className="col-xl-12 col-lg-12">		            	
                            <h5>
                                 <i className="icofont icofont-dashboard-web cus-i"></i> <span>Return Trips Dashboard</span>
                            
                            </h5>                    
                    </div>
                    <div className="col-xl-12 col-lg-12 f16" style={{marginBottom:"10px"}}>
                        <ul className="d-tabs">
                            <li onClick={this.onClickTab.bind(this,"analysis")} className={"ttabs "+(this.state.analysis)}>
                                <button type="button" className="f16 btn" onClick={this.onClickTab.bind(this,"analysis")}>Analysis</button>
                            </li>
                            {/* {(showChart)?
                                ""
                            : */}
                            <li onClick={this.onClickTab.bind(this,"analysis_data")} className={"ttabs "+(this.state.analysis_data)}>
                                <button type="button" className="f16 btn" onClick={this.onClickTab.bind(this,"analysis_data")} href="">Data</button>
                            </li>
                            {/* } */}
                        </ul>
                    </div>
                    <div className={"col-xl-12 col-lg-12 tab- pt-20px whiteRow "} style={{marginBottom:"2em"}}>		
                        
                        
                        <div className={this.state.showAnalysis}>
                        <form className={"row col-xl-12 col-lg-12 "+this.state.showAnalysis} style={{display:"flex"}} onSubmit={this.submitForm.bind(this)}>
                            <div className="form-group col-xl-1 col-lg-1" style={{padding: "0"}}>
                                <label>Start Date: </label>
                                <input type="text" autoComplete="off"  className="col-xl-12 col-lg-12 form-control startDate datetimepicker_date col-xl-7" id="" style={{height:"41px"}} />
                            </div>
                            <div className="form-group col-xl-1 col-lg-1" style={{padding: "0",marginLeft:"1em"}}>
                                <label>End Date: </label>
                                <input type="text" autoComplete="off" className="col-xl-12 col-lg-12 form-control endDate datetimepicker_date col-xl-7" id="" style={{height:"41px"}} />
                            </div>
                            <div className="col-xl-2 col-lg-3">
                                <label>Select Source: </label>
                                <Select placeholder={"All"} 
                                isMulti={true} 
                                value={this.state.selectedSource} 
                                className="border-radius-0" 
                                onChange={(e)=>{this.setState({selectedSource: e})}} 
                                style={{borderRadius:"0px"}} 
                                options={(this.state.dept_code == "SNDG")?[{"label": "ALL", value: "ALL"}, {"label": "Harayana", value: "H"}, {"label": "Gujarat", value: "G"}, {"label": "Bidadi", value: "BP"},{"label":"Dobaspet",value:"D"}]:
                                [{"label": "ALL", value: "ALL"}, {"label": "Harayana", value: "H"}, {"label": "RPDC Gujarat", value: "G"},
                                {"label": "RPDC Banglore", value: "B"}, {"label": "RPDC Nagpur", value: "N"}, {"label": "RPDC Siliguri", value: "I"}]} 
                                required />
                            </div>
                            {(this.state.dept_code == "SNDG")?
                                <div className="col-xl-2 col-lg-3">
                                    <label>Select OEM : </label>
                                    <Select placeholder={"All"} 
                                    isMulti={true} 
                                    value={this.state.selectedCompetitors} 
                                    className="border-radius-0" 
                                    onChange={(e)=>{this.setState({selectedCompetitors: e})}} 
                                    style={{borderRadius:"0px"}} 
                                    options={this.state.competitorOptions} 
                                    required />
                                </div>
                            :""}
                            
                            <div className="col-xl-2 col-lg-3">
                                <label>Select Transporter: </label>
                                <Select placeholder={"All"} 
                                isMulti={true} 
                                value={this.state.selectedTransporters} 
                                className="border-radius-0" 
                                onChange={(e)=>{this.setState({selectedTransporters: e})}} 
                                style={{borderRadius:"0px"}} 
                                options={this.state.transporterOptions} 
                                required />
                            </div>
                            
                            {(this.state.dept_code == "SNDG")?
                                <div className="col-xl-2 col-lg-3">
                                    <label>Select Route : </label>
                                    <Select placeholder={"All"} 
                                    isMulti={true} 
                                    value={this.state.selectedConsigner} 
                                    className="border-radius-0" 
                                    onChange={(e)=>{this.setState({selectedConsigner: e})}} 
                                    style={{borderRadius:"0px"}} 
                                    options={this.state.consigner_location_options} 
                                    required />
                                </div>
                            :
                                <div className="col-xl-2 col-lg-3">
                                    <label>Select Zones : </label>
                                    <Select placeholder={"All"} 
                                    isMulti={true} 
                                    value={this.state.selectedZones} 
                                    className="border-radius-0" 
                                    onChange={(e)=>{this.setState({selectedZones: e})}} 
                                    style={{borderRadius:"0px"}} 
                                    options={this.state.zoneOptions} 
                                    required />
                                </div>
                            }
                            
                            
                            <div className="form-group col-xl-1 col-lg-1" style={{paddingTop:"10px"}}>
                                <br />
                                <button type="submit" className="btn btn-success pt-10p">Submit</button>
                            </div>
                        </form>
                        <span style={{color:"red",paddingLeft:"20em"}}><b style={{color:"black"}}>Note : </b>* Trucks that have come back to MSIL plant after 
                        MSIL consignments delivery are considered for analysis</span>
                        <div className="col-xl-12 col-lg-12 mt-40p">
                        <div className="col-xl-12 col-lg-12 f16" style={{marginBottom:"10px"}}>
                                <ul className="d-tabs">
                                    <li className={"ttabs " +this.state.graphTabInital}>
                                        <button type="button" className="f16 btn" onClick={()=>this.onClickTabChange("graphTabInital")} >Graph</button>
                                    </li>
                                   
                                    <li className={"ttabs " + this.state.gridTabInital}>
                                        <button type="button" className="f16 btn"  onClick={()=>this.onClickTabChange("gridTabInital")} >Summary</button>
                                    </li>
                                </ul>
                            </div>
                            {this.state.graphTabInital === "activet" ? (
                                this.state.checkSecondChartDrillDownLength > 0 ? (
                                    <div>
                                        {this.state.secondChartMessage === "" ? (
                                            <RouteWiseThreeLevelChart 
                                                title={"Total Trips vs Other OEM Trips"}
                                                subtitle={""}
                                                context={this}
                                                series={this.state.secondChartSeriesData}
                                                categories={this.state.secondChartCategories}
                                                drilldowncols={this.state.secondChartDrilldownColumn}
                                                drilldowncols1={this.state.secondChartDrilldownColumn1}
                                                drilldownlines={this.state.secondChartDrilldownLine}
                                                secondChartOEMWiseReturnColumn={this.state.secondChartOEMWiseReturnColumn}
                                                yaxistitle={""}
                                            />
                                        ) : (
                                            <div className="col-xl-12 col-lg-12" style={{ minHeight: "350px", textAlign: "center" }}>
                                                <h5>Total Trips vs Other OEM Trips  - </h5>
                                                No Data
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="col-xl-12 col-lg-12" style={{ minHeight: "350px", textAlign: "center" }}>
                                        <h5>Total Trips vs Other OEM Trips</h5>
                                        <span style={{ position: "absolute", top: "30%", left: "43%" }}>Loading</span>
                                    </div>
                                )
                            ) : (
                                <div className='col-xl-12 col-lg-12'>
                                    <div id="myGrid" style={{ width: "100%", height: "460px" }} className="ag-theme-balham">  
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={ReturnTripsColumnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.ReturnTripsRowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={false}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={false}
                                            gridOptions={{
                                                context: { componentParent: this },
                                                suppressRowTransform: true,
                                                getRowStyle: params => {
                                                    if (params.data.route === "Total") {
                                                        return { background: 'grey', color: 'white' };
                                                    } else {
                                                        return null;
                                                    }
                                                },
                                            }}
                                            suppressRowClickSelection={true}
                                            suppressCellFlash={true}
                                            enableCellChangeFlash={true}
                                            masterDetail={true}
                                        />
                                    </div>
                                </div>
                            )}

                        </div>
                        <div className="col-xl-12 col-lg-12 mt-40p">
                            {/* {(this.state.checkSecondChartDrillDownLength > 0)? */}
                            <div className="col-xl-12 col-lg-12 f16" style={{marginBottom:"10px"}}>
                                <ul className="d-tabs">
                                    <li className={"ttabs " +this.state.graphTab}>
                                        <button type="button" className="f16 btn" onClick={()=>this.onClickTabChange("graphTab")} >Graph</button>
                                    </li>
                                   
                                    <li className={"ttabs " + this.state.gridTab}>
                                        <button type="button" className="f16 btn"  onClick={()=>this.onClickTabChange("gridTab")} >Summary</button>
                                    </li>
                                </ul>
                            </div>
                            {this.state.graphTab=="activet"? 
                                <div className='col-xl-12 col-lg-12'>
                                {/* {(this.state.secondChartMessage == "")? */}
                                    <EmptyReturnTripsChart 
                                    title= {"Plant Wise Empty Return Trips(To MSIL)"}
                                    subtitle={""}
                                    context={this}
                                    series={this.state.route_wise_series}
                                    // drilldown={this.state.emptyReturnTripsdrilldownData}
                                    // routeWiseClusterOEMS= {this.state.routeWiseClusterOEMS}
                                    // secondChartClusterWiseReturnColumn= {this.state.secondChartClusterWiseReturnColumn}
                                    // categories={this.state.route_wise_categories}
                                    yaxistitle={"Total trips vs Empty return Trips"}
                                    emptyReturnTripsCategories={this.state.emptyReturnTripsCategories}
                                    emptyReturnTripsSeries={this.state.emptyReturnTripsSeries}
                                    emptyReturnTripsdrilldownData={this.state.emptyReturnTripsdrilldownData}
                                    
                                    />
                                 {/* :
                                <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                <h5>Total Trips vs Return Trips  - </h5>No Data</div>
                                }  */}
                                </div>:
                                 <div className='col-xl-12 col-lg-12'>
                                 <div id="myGrid" style={{width:"100%",height:"460px"}} className="ag-theme-balham">  
                                 <AgGridReact
                                     modules={this.state.modules}
                                     columnDefs={emptyReturnTripsColumnDefs}
                                     defaultColDef={this.state.defaultColDef}
                                     rowData={this.state.emptyReturnTripsRowData}
                                     // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                     enableCharts={true}
                                     enableRangeSelection={true}
                                     onGridReady={this.onGridReady}
                                     onGridState={this.onGridState}
                                     statusBar={this.state.statusBar}
                                     sideBar={false}
                                     detailCellRendererParams={this.state.detailCellRendererParams}
                                     paginationPageSize={this.state.paginationPageSize}
                                     pagination={false}
                                     gridOptions={{
                                                    context: { componentParent: this },
                                                    suppressRowTransform: true,
                                                    getRowStyle: params => {
                                                        if (params.data.route== "Total") {
                                                            return { background: 'grey', color: 'white' }; // Apply styles based on condition
                                                        } else {
                                                            return null; // Return null to apply default styles
                                                        }
                                                    },
                                                    // rowClassRules: {
                                                    //     'row-red': params => params.data.value > 10
                                                    // }
                                                }}
                                     // pinnedBottomRowData={this.state.amount_payable}
                                     suppressRowClickSelection={true}
                                     suppressCellFlash={true}
                                     enableCellChangeFlash={true}
                                    //  onCellClicked={this.onClickedCell.bind(this)}
                                     masterDetail={true}
                                 />

                                </div>
                                </div>
                            }               
                            {/* :
                                <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                <h5>Total Trips vs Return Trips</h5>
                                <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                            }  */}
                        </div>
                        {/* <span style={{color:"red",paddingLeft:"20em"}}><b style={{color:"black"}}>Note : </b>* Trips originating from Haryana and Gujarat to TVPB and TVPN are separated and not included as part of the routes</span>    */}
                        { showChart ? (<div className={"row mt-40p"}>
                            <div className="col-xl-12">
                                {(this.state.drilldownSeriesData.length > 0)?
                                    <div>
                                    {(this.state.firstChartMessage == "")?
                                        <SndUnloadingColumnChart
                                        title={(this.state.dept_code == "SNDG")?"OEM wise Trips (Top 15)":"Supplier wise Trips"}
                                        subtitle={""}
                                        context={this}
                                        series={this.state.seriesData}
                                        categories={this.state.comp_category}
                                        seriesName=""
                                        yaxistitle="No. of Trips"
                                        drilldownseries={{"series":this.state.drilldownSeriesData}}
                                        // dept_code={this.state.dept_code}
                                        />
                                    :
                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                        <h5>{(this.state.dept_code == "SNDG")?"OEM  wise Trips":"Supplier  wise Trips"} - </h5><span style={{position: "relative", top: '9em'}}>No Data</span></div>
                                    }
                                    </div>
                                :
                                    (this.state.firstChartMessage == "no data")?
                                    <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                        <h5>{(this.state.dept_code == "SNDG")?"OEM  wise Trips":"Supplier  wise Trips"} - </h5><span style={{position: "relative", top: '9em'}}>No Data</span></div>
                                    :<div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                    <h5>{(this.state.dept_code == "SNDG")?"OEM  wise Trips":"Supplier  wise Trips"} </h5>
                                    <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                    
                        
                                }
                                
                            </div>
                        </div>): <div></div>}
                        { showChart ? (<div className="col-xl-12 col-lg-12 mt-40p">
                            {(this.state.secondChartOEMWiseReturnColumn.length > 0)?
                                <div>
                                {(this.state.thirdChartMessage == "")?
                                <span>
                                    {(this.state.dept_code == "SNDG")?
                                        <div>
                                            <SNDReturnTripsRouteWiseTripsGroupLineChart 
                                                title= {"Route Wise Return Trips"}
                                                subtitle={""}
                                                context={this}
                                                series={this.state.route_wise_series}
                                                drilldown={this.state.secondChartOEMWiseReturnColumn}
                                                routeWiseClusterOEMS= {this.state.routeWiseClusterOEMS}
                                                secondChartClusterWiseReturnColumn= {this.state.secondChartClusterWiseReturnColumn}
                                                categories={this.state.route_wise_categories}
                                                yaxistitle={"Total trips vs Valid Trips"}
                                            />
                                        </div>
                                    :
                                        <div>
                                            <ReturnTripsRouteWiseTripsGroupLineChart 
                                            title= {"Zone Wise Return Trips"}
                                            subtitle={""}
                                            context={this}
                                            series={this.state.route_wise_series}
                                            drilldown={this.state.secondChartOEMWiseReturnColumn}
                                            categories={this.state.route_wise_categories}
                                            yaxistitle={"Total trips vs Valid Trips"}
                                            />
                                        </div>                                   
                                    }
                                </span>
                                
                                
                                
                                :
                                <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                <h5>{(this.state.dept_code == "SNDG")?"Route":"Zone"} wise chart  - </h5><span style={{position: "relative", top: '9em'}}>No Data</span></div>
                                }
                            </div>   
                            :
                                (this.state.thirdChartMessage == "no data")?
                                <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                <h5>{(this.state.dept_code == "SNDG")?"Route":"Zone"} wise chart  - </h5><span style={{position: "relative", top: '9em'}}>No Data</span></div>
                                :
                                <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                <h5>{(this.state.dept_code == "SNDG")?"Route":"Zone"} wise Trips</h5>
                                <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                            }
                        </div>): <div></div>}
                        { showChart ? (<div className={"row mt-40p"}>
                            <div className="col-xl-12">
                                {(this.state.thirdChartDrilldownData.length > 0)?
                                    <div>
                                    {(this.state.fourthChartMessage == "")?
                                    <TransporterChart
                                    title={"Transporter Wise Return Trips (Top 15)"}
                                    subtitle={""}
                                    context={this}
                                    series={this.state.thirdChartSeriesData}
                                    categories={this.state.thirdChartCategories}
                                    seriesName=""
                                    yaxistitle="No. of Trips"
                                    drilldownseries={{"series":this.state.thirdChartDrilldownData}}
                                    // dept_code={this.state.dept_code}
                                    />
                                    :
                                    <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                    <h5>Transporter wise chart  - </h5><span style={{position: "relative", top: '9em'}}>No Data</span></div>
                                    }
                                    </div>
                                :
                                    (this.state.fourthChartMessage == "no data")?
                                    <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                    <h5>Transporter wise chart  - </h5><span style={{position: "relative", top: '9em'}}>No Data</span></div>
                                    :
                                    <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                    <h5>Transporter wise chart</h5>
                                    <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                }
                                
                            </div>
                        </div>) : <div></div> }
                        
                        {(this.state.dept_code == "SNDG")?
                            <div className={"row mt-40p"} id="redmsd">
                                <div className="col-xl-12">
                                    {(this.state.seventhChartSeriesData.length > 0)?
                                        <div>
                                        {(this.state.seventhChartMessage == "")?
                                            <SndUnloadingColumnChart
                                            title={"Route wise Avg. Dead Mile (Other OEM) (KM)"}
                                            subtitle={""}
                                            context={this}
                                            series={this.state.seventhChartSeriesData}
                                            categories={this.state.seventhChartCategories}
                                            seriesName=""
                                            yaxistitle="Avg. Distance (KM)"
                                            drilldownseries={{"series": []}}
                                            />
                                        :
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>Route wise Avg. Dead Mile (Other OEM) (KM) - </h5><span style={{position: "relative", top: '9em'}}>No Data</span></div>
                                        }
                                        </div>
                                    :
                                        (this.state.seventhChartMessage == "no data")?
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>Route wise Avg. Dead Mile (Other OEM) (KM) - </h5><span style={{position: "relative", top: '9em'}}>No Data</span></div>
                                        :
                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                        <h5>Route wise Avg. Dead Mile Distance </h5>
                                        <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                            
                                    }
                                    
                                </div>
                            </div>
                            
                        :
                        ""}
                        {(this.state.dept_code == "SNDG")?
                            <div className={"row mt-40p "+(this.state.showgridforadmd)} id="redmsdGrid">
                                
                                <div className="col-xl-12">
                                    <div>
                                            <button onClick={this.onShowFullscreen.bind(this)} type="button" className="btn btn-warning" style={{marginLeft:"93%",marginBottom:"1%"}}>Back
                                            </button>
                                    </div>
                                    <div id="myGrid" style={{width:"100%",height:"460px"}} className="ag-theme-balham">  
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs1}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData1}
                                            // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={false}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={false}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            // pinnedBottomRowData={this.state.amount_payable}
                                            suppressRowClickSelection={true}
                                            onCellClicked={this.onClickedCell.bind(this)}
                                            masterDetail={true}
                                        />

                                    </div>
                                </div>
                            </div>
                            
                        :
                        ""}
                        
                        {(this.state.dept_code == "SNDG")?
                        <div className={"row mt-40p"} id="redmsdet">
                            <div className="col-xl-12">
                                {(this.state.tenthChartSeriesData.length > 0)?
                                    <div>
                                    {(this.state.tenthChartMessage == "")?
                                        <SndUnloadingColumnChart
                                        title={"Route wise avg Empty Return Trips (To MSIL) (KM)"}
                                        subtitle={""}
                                        context={this}
                                        series={this.state.tenthChartSeriesData}
                                        categories={this.state.tenthChartCategories}
                                        seriesName=""
                                        yaxistitle="Avg. Distance (KM)"
                                        drilldownseries={{"series": []}}
                                        />
                                    :
                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                        <h5>Route wise avg Empty Return Trips (To MSIL) (KM)- </h5><span style={{position: "relative", top: '9em'}}>No Data</span></div>
                                    }
                                    </div>
                                :
                                    (this.state.tenthChartMessage == "no data")?
                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                        <h5>Route wise avg Empty Return Trips (To MSIL) (KM)- </h5><span style={{position: "relative", top: '9em'}}>No Data</span></div>
                                    :
                                    <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                    <h5>Route wise avg Empty Return Trips (To MSIL) (KM)</h5>
                                    <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                        
                                }
                                
                            </div>
                        </div>
                    :
                    ""}
                    {(this.state.dept_code == "SNDG")?
                            <div className={"row mt-40p "+(this.state.showgridforadmdet)} id="redmsdetGrid">
                                
                                <div className="col-xl-12">
                                    <div>
                                            <button onClick={this.onShowFullscreen1.bind(this)} type="button" className="btn btn-warning" style={{marginLeft:"93%",marginBottom:"1%"}}>Back
                                            </button>
                                    </div>
                                    <div id="myGrid" style={{width:"100%",height:"460px"}} className="ag-theme-balham">  
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs2}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData2}
                                            // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={false}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={false}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            pinnedBottomRowData={this.state.amount_payable}
                                            suppressRowClickSelection={true}
                                            onCellClicked={this.onClickedCell.bind(this)}
                                            masterDetail={true}
                                        />

                                    </div>
                                </div>
                            </div>
                            
                        :
                        ""}
                    {(this.state.dept_code == "SNDG")?
                            <div className={"row mt-40p"} id="redmsds">
                                <div className="col-xl-12">
                                    {(this.state.ninthChartSeriesData.length > 0)?
                                        <div>
                                        {(this.state.ninthChartMessage == "")?
                                            <SndUnloadingColumnChart
                                            title={"Cumulated Wt. avg. Empty Running (To MSIL + Other OEM) (KM)"}
                                            subtitle={""}
                                            context={this}
                                            series={this.state.ninthChartSeriesData}
                                            categories={this.state.ninthChartCategories}
                                            seriesName=""
                                            yaxistitle="Avg. Distance (KM)"
                                            drilldownseries={{"series": []}}
                                            />
                                        :
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>Cumulated Wt. avg. Empty Running (To MSIL + Other OEM) (KM)- </h5><span style={{position: "relative", top: '9em'}}>No Data</span></div>
                                        }
                                        </div>
                                    :
                                        (this.state.ninthChartMessage == "no data")?
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>Cumulated Wt. avg. Empty Running (To MSIL + Other OEM) (KM)- </h5><span style={{position: "relative", top: '9em'}}>No Data</span></div>
                                        :
                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                        <h5>Cumulated Wt. avg. Empty Running (To MSIL + Other OEM) (KM)</h5>
                                        <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                            
                                    }
                                    
                                </div>
                            </div>
                        :
                        ""}
                        {(this.state.dept_code == "SNDG")?
                            <div className={"row mt-40p "+(this.state.showgridforadmds)} id="redmsdsGrid">
                                
                                <div className="col-xl-12">
                                    <div>
                                            <button onClick={this.onShowFullscreen2.bind(this)} type="button" className="btn btn-warning" style={{marginLeft:"93%",marginBottom:"1%"}}>Back
                                            </button>
                                    </div>
                                    <div id="myGrid" style={{width:"100%",height:"460px"}} className="ag-theme-balham">  
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs3}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData3}
                                            // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={false}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={false}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            pinnedBottomRowData={this.state.amount_payable}
                                            suppressRowClickSelection={true}
                                            onCellClicked={this.onClickedCell.bind(this)}
                                            masterDetail={true}
                                        />

                                    </div>
                                </div>
                            </div>
                            
                        :
                        ""}
                        {/* <div className={"row mt-40p"}>
                            <div className="col-xl-12">
                                {(this.state.eighthChartseries.length > 0)?
                                    <div>
                                    {(this.state.eighthChartMessage == "")?
                                        <SndUnloadingColumnChart
                                        title={(this.state.dept_code == "SNDG")?"Route Wise Avg. Turn Around Time (Days)":"Zone Wise Avg. Turn Around Time (Hrs)"}
                                        subtitle={""}
                                        context={this}
                                        series={this.state.eighthChartseries}
                                        categories={this.state.eighthChartCategories}
                                        seriesName=""
                                        yaxistitle="Avg. Turn Around Time (Hrs)"
                                        drilldownseries={{"series": []}}
                                        // dept_code={this.state.dept_code}
                                        />
                                    :
                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                        <h5>{(this.state.dept_code == "SNDG")?"Route":"Zone"} Wise Avg. Turn Around Time (Days) - </h5>No Data</div>
                                    }
                                    </div>
                                :
                                    
                                    <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                    <h5>{(this.state.dept_code == "SNDG")?"Route":"Zone"} Wise Avg. Turn Around Time (Days) </h5>
                                    <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                        
                                }
                                
                            </div>
                        </div> */}{showChart?
                            <>
                        <div className={"row mt-40p"}>
                            <div className="col-xl-12">
                                {((this.state.fifthChartSeriesData.length > 0) || (this.state.fifthChartMessage == ""))?
                                    <div>
                                    
                                        <SndUnloadingColumnChart
                                        title={(this.state.dept_code == "SNDG")?"OEM Wise Avg Pre-Loading Time":"Supplier Wise Avg Pre-Loading Time"}
                                        subtitle={""}
                                        context={this}
                                        series={this.state.fifthChartSeriesData}
                                        categories={this.state.fifthChartCategories}
                                        seriesName=""
                                        yaxistitle="Avg. Pre-loading Time (Hrs)"
                                        drilldownseries={{"series": []}}
                                        // dept_code={this.state.dept_code}
                                        />
                                    
                                    </div>
                                :
                                    <span>
                                        {(this.state.fifthChartMessage == "")?
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>{(this.state.dept_code == "SNDG")?"OEM Wise Avg Pre-Loading Time":"Supplier Wise Avg Pre-Loading Time"}  - </h5>Loading Data</div>
                                        :
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>{(this.state.dept_code == "SNDG")?"OEM Wise Avg Pre-Loading Time":"Supplier Wise Avg Pre-Loading Time"}</h5>
                                            <span style={{position:"absolute",top:"30%",left:"43%"}}>No Data</span></div>
                                        }
                                    </span>
                                    
                                }
                                
                            </div>
                        </div>
                       <span style={{color:"red",paddingLeft:"18em"}}><b style={{color:"black"}}>Note : </b>* {(this.state.dept_code=="SNDG")?"OEM's":"Supplier's"} Having trips greater than {(this.state.dept_code=="SNDG")?100:10} are only considered for Pre-loading average time.</span>   <br />
                       <span style={{color:"red",paddingLeft:"20em"}}><b style={{color:"black"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>* Pre Loading time is considered from 25km from {(this.state.dept_code == "SNDG")?"OEM":"Supplier"} to Gate In.</span> 
                       </>
                       :<div></div>}  
                       {showChart?<>
                        <div className={"row mt-40p"}>
                            <div className="col-xl-12">
                                {((this.state.sixthChartSeriesData.length > 0) || (this.state.sixthChartMessage == ""))?
                                    <div>
                                        <SndUnloadingColumnChart
                                        title={(this.state.dept_code == "SNDG")?"OEM Wise Avg Loading Time":"Supplier Wise Avg Loading Time"}
                                        subtitle={""}
                                        context={this}
                                        series={this.state.sixthChartSeriesData}
                                        categories={this.state.sixthChartCategories}
                                        seriesName=""
                                        yaxistitle="Avg. loading Time (Hrs)"
                                        drilldownseries={{"series": []}}
                                        // dept_code={this.state.dept_code}
                                        />
                                    </div>
                                :
                                    
                                <span>
                                    {(this.state.sixthChartMessage == "")?
                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                        <h5>{(this.state.dept_code == "SNDG")?"OEM Wise Avg Loading Time":"Supplier Wise Avg Loading Time"}  - </h5>Loading Data</div>
                                    :
                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                        <h5>{(this.state.dept_code == "SNDG")?"OEM Wise Avg Loading Time":"Supplier Wise Avg Loading Time"}</h5>
                                        <span style={{position:"absolute",top:"30%",left:"43%"}}>No Data</span></div>
                                    }
                                </span>
                        
                                }
                                
                            </div>
                            <span style={{color:"red",paddingLeft:"18em"}}><b style={{color:"black"}}>Note : </b>* {(this.state.dept_code=="SNDG")?"OEM's":"Supplier's"} Having trips greater than {(this.state.dept_code=="SNDG")?100:10} are only considered for loading average time</span>   
                        </div>
                       </>:<div></div>}
                       <div className={"row mt-40p"}>
                            <div className="col-xl-12">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Route Wise Carrier Running</span>
                                
                                </h5>
                                <div id="myGrid" style={{width:"100%",height:"460px"}} className="ag-theme-balham">  
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs4}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData4}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={true}
                                        sideBar={false}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        suppressRowClickSelection={true}
                                        onCellClicked={this.onClickedCell.bind(this)}
                                        masterDetail={true}
                                    />

                                </div>
                            </div>
                       
                        </div>
                       
                        
                        </div>
                        {/* {!showChart ? */}
                        <div className="row">
                {this.state.analysis_data === "activet" && (
                    <React.Fragment>
                        <ul className="d-tabs" style={{ marginLeft: "17px", marginBottom: "20px" }}>
                            <li onClick={this.onClickInnerTabs.bind(this, "complete_trips")} className={"ttabs " + (this.state.complete_trips_tab)}>
                                <button type="button" className="f16 btn">Complete Trips</button>
                            </li>
                            <li onClick={this.onClickInnerTabs.bind(this, "valid_trips")} className={"ttabs " + (this.state.valid_trips_tab)}>
                                <button type="button" className="f16 btn">Valid Trips</button>
                            </li>
                        </ul>
                        {this.state.complete_trips_tab === "activet" && (
                            <div className="col-xl-12">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Complete Trips</span>
                                </h5>
                                <div style={{ width: "100%", height: "460px" }} className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.complete_trips_data}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        statusBar={true}
                                        sideBar={false}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        suppressRowClickSelection={true}
                                        onCellClicked={this.onClickedCell.bind(this)}
                                        masterDetail={true}
                                    />

                                </div>
                            </div>
                        )}
                        {this.state.valid_trips_tab === "activet" && (
                            <div className="col-xl-12">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Valid Trips</span>
                                </h5>
                                <div id="myGrid" style={{ width: "100%", height: "460px" }} className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnDefsValidTrips}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.valid_trips_data}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        statusBar={true}
                                        sideBar={false}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        suppressRowClickSelection={true}
                                        masterDetail={true}
                                    />
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                )}
            </div>

                        
                       
                                          
	                </div> 
                    
                    {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto",width:"75%"}}>
					 	<div className="slide-r-title">
							<h4>
								Return Trips
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                                <DrawMap 
                                    context={this}
                                    mapFor={"returntripsdashboard"}
                                    boundaries={this.state.boundaries}
                                    mapinfo={this.state.mapinfo}
                                    consignercords={this.state.geofences}
                                    competitor_geofences={this.state.competitor_geofences}
                                />
							</div>
						</div>
						
					</div>
				:""	
				}
                    <div className={"dataLoadpage " +(this.state.loadshow)}></div>
                    <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                        <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                    </div>
                    <div className={"overlay "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
	           </div>
			);
		}
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		// mask:'39-19-9999 29:59',
		format:'Y-m-d H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		// mask:'39-19-9999',
		format:'Y-m-d',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}


//   $(document).ready(function(){
//     $(document).on("click",".dropdown-truck-no",function(){
//         var vals = $(this).text();
//          $("#inputLocation").val(vals);
//          $(".trucks-dropdown").removeClass("show-m");
//          $(".trucks-dropdown").addClass("show-n");
//     });

//     $("body").on("click",function(e){
//         var container = $("#inputLocation");
//         var plant = $("#inputTransporter");
//         if(!container.is(e.target) && !plant.is(e.target))
//         {
//             try{
//                 if($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown")
//                 {
//                     $(".trucks-dropdown").removeClass("show-m");
//                     $(".trucks-dropdown").addClass("show-n");
//                 }
//             }catch(e){}
            
//         }
        
        
//     })
// })

// $(document).ready(function(){
//     $(document).on("click",".dropdown-transporter-no",function(){
//         var vals = $(this).text();
//          $("#inputTransporter").val(vals);
//          $(".transporter-dropdown").removeClass("show-m");
//          $(".transporter-dropdown").addClass("show-n");
//     });

//     $("body").on("click",function(e){
//         var container = $("#inputTransporter");
//         var location = $("#inputLocation");
//         if(!container.is(e.target) && !location.is(e.target))
//         {
//             try{
//                 if($(".transporter-dropdown").attr("class").includes("show-m") || $(".transporter-dropdown").attr("class") == "transporter-dropdown")
//                 {
//                     $(".transporter-dropdown").removeClass("show-m");
//                     $(".transporter-dropdown").addClass("show-n");
//                 }
//             }catch(e){}
            
//         }
        
        
//     })
// })







import React, { Component } from 'react';
import { render } from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import Select from 'react-select';
import { Typography } from '@material-ui/core'; // Import Typography for label
import { Box } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY } from '../common/utils';
import 'react-vertical-timeline-component/style.min.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import PrimaryCounters from "./primarydashboardcounters";
import ViewDownloadPayments from "./viewdownloadsecondarypayments"
import ViewPrimaryDownloadPayments from "./viewdownloadprimarysecondarypayments";;

var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");

export default class MSILDashboard extends Component {

	constructor(props) {
		super(props);
		var date2 = new Date();
		var currentDate = moment(date2).format("YYYY-MM-DD");
		var currentStart = moment(date2).subtract(10, 'days').format("YYYY-MM-DD")

		this.state = {
			pageTitle: "Secondary Payment Dashboard (MSIL)",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			uploadDivWidth: '0%',
			sliderTranslate: '',
			sliderRouteTranslate: '',
			tabsliderTranslate: '',
			loadshow: 'show-n',
			showDiv: 'show-n',
			alertshow: 'fade',
			alertmg: 'show-n',
			alerterrmg: 'show-n',
			overly: 'show-n',
			alerterrshow: 'fade',
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			modules: AllModules,
			sidebarSubHeader: '',
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true

			},
			allData: [],
			rowData: [],
			loading: false,
			percentageCalculation: 0,
			activeExportState: false,
			exportStart: '',
			exportEnd: '',
			bulkslideExport: '',
			rowSelection: 'multiple',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
			},

			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,
			groupDefaultExpanded: -1,
			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 1000,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"green": "data.status >= 3"

			},
			transporterslist: [],
			container_no: '',
			updatesearatebtn: 'show-n',
			searates: '',
			pipavaContainers: [],
			mundraContainers: [],
			containerslist: [],
			errorMessage: '',
			successMessage: '',
			transporter_code: '',
			components: { datePicker: getDatePicker() },
			updatedRecords: [],
			openUpdatedModal: false,
			usergridstate: [],
			screenurl: "",
			screentitle: "",
			startDate: currentStart,
			endDate: currentDate,
			defaultsdate: "",
			defaultedate: "",
			defaultsdate1: currentStart,
			defaultedate1: currentDate,
			zerofifteen: [],
			fifteenthirty: [],
			thirtyonefortyfive: [],
			morefortyfive: [],
			pagetype: { "value": "all", "label": "All" },
			primarytrans: [{ "value": "all", "label": "All" }],
			secondtrans: [{ "value": "all", "label": "All" }],
			primarytransporter: { "value": "all", "label": "All" },
			secondtransporter: { "value": "all", "label": "All" },
			stockzerofifteen: [],
			stockfifteenthirty: [],
			stockthirtyonefortyfive: [],
			stockmorefortyfive: [],
			zeroamt: 0,
			fifteen: 0,
			thirty: 0,
			moreforty: 0,
			stockzeroamt: 0,
			stockfifteen: 0,
			stockthirty: 0,
			stockmoreforty: 0
		}
		this.onLoadDashboardData = this.onLoadDashboardData.bind(this);
		this.resetState = this.resetState.bind(this);
		this.onFilterLoadDashboardData = this.onFilterLoadDashboardData.bind(this);
	}
	async componentDidMount() {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				// console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}

		await redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
			.then(async (resp) => {
				//console.log("resp ", resp)
				await this.setState({
					usergridstate: resp.data
				});
				await this.restoreGridStates();
			})
		var transportercode = "";
		// console.log("localStorage.getItem('transportercode') ", localStorage.getItem('transportercode'))

		var parameters = {
			pagetype: "all",
			startDate: this.state.startDate,
			endDate: this.state.endDate,
			initial: 1
		}

		this.onLoadDashboardData(parameters)
		//dateScript("https://code.jquery.com/ui/1.12.1/jquery-ui.js");
	};

	async onLoadDashboardData(parameters) {
		this.setState({
			loadshow: 'show-m',
			overlay: 'show-m',
		});
		var createdate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
		// console.log("createdate ",createdate);
		var s1 = moment.parseZone(createdate).format("x")
		//console.log("s1 ",s1)

		await redirectURL.post("/consignments/secondarypaymentscounterdata", parameters)
			.then((response) => {
				var records = []
				var zerofifteen = []
				var fifteenthirty = []
				var thirtyonefortyfive = []
				var morefortyfive = []

				var stockzerofifteen = []
				var stockfifteenthirty = []
				var stockthirtyonefortyfive = []
				var stockmorefortyfive = []
				var zeroamt = 0
				var fifteen = 0
				var thirty = 0
				var moreforty = 0

				records = response.data;
				// console.log("records1", records.length)
				//debugger
				if (records.length > 0) {
					records.map((item) => {
						var inv = moment.parseZone(item.sec_upload_date).format("YYYY-MM-DD HH:mm:ss");
						//console.log("inv ", inv);
						var s2 = moment.parseZone(inv).format("x")
						//console.log(s2);
						var diffreetime = moment.duration(moment(createdate).diff(inv));
						var delaydays = Math.round(diffreetime.asDays());
						// console.log("prim delaydays ", delaydays)
						if (item.sec_is_payment_receive == "Yes" && item.sec_status == "Payment received") {

						}
						else {

							if (delaydays >= 0 && delaydays <= 15) {
								zerofifteen.push(item)
								if (item.sec_status != "Payment received") {
									zeroamt = parseFloat(zeroamt) + parseFloat(item.sec_fret_amt)
								}

							}
							if (delaydays >= 16 && delaydays <= 30) {
								//console.log("item",item)
								fifteenthirty.push(item)
								stockfifteenthirty.push(item)
								if (item.sec_status != "Payment received") {
									fifteen = parseFloat(fifteen) + parseFloat(item.sec_fret_amt)
								}

							}
							if (delaydays >= 31 && delaydays <= 45) {
								thirtyonefortyfive.push(item)
								stockthirtyonefortyfive.push(item)
								if (item.sec_status != "Payment received") {
									thirty = parseFloat(thirty) + parseFloat(item.sec_fret_amt)
								}

							}
							if (delaydays > 45) {
								// console.log(moreforty,"285")
								morefortyfive.push(item)
								stockmorefortyfive.push(item)
								if (item.sec_status != "Payment received") {
									moreforty = parseFloat(moreforty) + parseFloat(item.sec_fret_amt)
								}

							}
						}

					})

				}

				this.setState({
					zerofifteen: zerofifteen,
					fifteenthirty: fifteenthirty,
					thirtyonefortyfive: thirtyonefortyfive,
					morefortyfive: morefortyfive,
					zeroamt: zeroamt,
					fifteen: fifteen,
					thirty: thirty,
					moreforty: moreforty,
				})

			})

		await redirectURL.post("/consignments/stockyardpaymentscounterdata", parameters)
			.then((response) => {
				var recordstock = []

				var stockzerofifteen = []
				var stockfifteenthirty = []
				var stockthirtyonefortyfive = []
				var stockmorefortyfive = []
				var zeroamt = 0
				var fifteen = 0
				var thirty = 0
				var moreforty = 0
				recordstock = response.data;
				console.log("records2", recordstock)
				//debugger
				if (recordstock.length > 0) {
					recordstock.map((item) => {

						//stockcounter
						// console.log("item.stockyard_upload_date ", typeof item.stockyard_upload_date)
						if (item.stockyard_upload_date != "") {
							// console.log("item.stockyard_upload_date ", item.stockyard_upload_date)
							// console.log("item.stockyard_is_payment_receive ", item.stockyard_is_payment_receive)

							if (item.stockyard_is_payment_receive == "Yes" && item.stockyard_status == "Payment received") {

							}
							else {
								var inv1 = moment.parseZone(item.stockyard_upload_date).format("YYYY-MM-DD HH:mm:ss");
								//console.log("inv ", inv);
								var s2 = moment.parseZone(inv1).format("x")
								//console.log(s2);
								var diffreetime1 = moment.duration(moment(createdate).diff(inv1));
								var delaydays1 = Math.round(diffreetime1.asDays());
								// console.log("delaydays1 ", delaydays1)
								if (delaydays1 >= 0 && delaydays1 <= 15) {
									stockzerofifteen.push(item)
									if (item.stockyard_status != "Payment received") {
										zeroamt = parseFloat(zeroamt) + parseFloat(item.tvp_stock_charges)
									}
								}
								if (delaydays1 >= 16 && delaydays1 <= 30) {
									stockfifteenthirty.push(item)
									if (item.stockyard_status != "Payment received") {
										fifteen = parseFloat(fifteen) + parseFloat(item.tvp_stock_charges)
									}

								}
								if (delaydays1 >= 31 && delaydays1 <= 45) {
									stockthirtyonefortyfive.push(item)
									if (item.stockyard_status != "Payment received") {
										thirty = parseFloat(thirty) + parseFloat(item.tvp_stock_charges)
									}
								}
								if (delaydays1 > 45) {
									stockmorefortyfive.push(item)
									if (item.stockyard_status != "Payment received") {
										moreforty = parseFloat(moreforty) + parseFloat(item.tvp_stock_charges)
									}
								}
							}
						}
					})

				}

				// console.log("stockzerofifteen ", stockzerofifteen.length)
				// console.log("stockfifteenthirty ", stockfifteenthirty.length)
				// console.log("stockthirtyonefortyfive ", stockthirtyonefortyfive.length)
				// console.log("stockmorefortyfive ", stockmorefortyfive.length)
				this.setState({
					stockzerofifteen: stockzerofifteen,
					stockfifteenthirty: stockfifteenthirty,
					stockthirtyonefortyfive: stockthirtyonefortyfive,
					stockmorefortyfive: stockmorefortyfive,
					stockzeroamt: zeroamt,
					stockfifteen: fifteen,
					stockthirty: thirty,
					stockmoreforty: moreforty
				})

			})

		await redirectURL.post("/consignments/secondarypaymentsdata", parameters)
			.then((response) => {
				//console.log("parameters ",parameters)
				var records = response.data.records;
				var recordsarr = []
				var primarytrans = []
				var secondtrans = []
				// var zerofifteen=[]
				// var fifteenthirty=[]
				// var thirtyonefortyfive=[]
				// var morefortyfive=[]
				var allinv = []
				console.log(records, "records3")
				//debugger
				if (records.length > 0) {
					records.map((item) => {
						if (parameters.initial == 1) {
							primarytrans.push({ value: item.prim_transporter_code, label: item.prim_transporter_name })
							secondtrans.push({ value: item.sec_transporter_code, label: item.sec_transporter_name })
						}
						var outstand_delay_days = ""
						if (item.prim_outstand_delay_days === undefined) {
							outstand_delay_days = ""
						}
						else {
							outstand_delay_days = item.prim_outstand_delay_days
						}
						var status = ""
						if (item.prim_status === undefined) {
							status = "Pending"
						}
						else {
							status = item.prim_status
						}
						var payment_date = ""
						if (item.prim_payment_date === undefined) {
							payment_date = ""
						}
						else {
							payment_date = item.prim_payment_date
						}
						allinv.push(item.invoice_date)

						var secstatus = ""
						if (item.sec_status === undefined) {
							secstatus = "Pending"
						}
						else {
							secstatus = item.sec_status
						}
						var sec_payment_date = ""
						if (item.sec_payment_date === undefined) {
							sec_payment_date = ""
						}
						else {
							sec_payment_date = item.sec_payment_date
						}

						recordsarr.push({
							"_id": item._id,
							"consignment_code": item.consignment_code,
							"load_no": item.load_no,
							"vin": item.vin,
							"invoice_date": item.invoice_date,
							"pod_date": item.pod_date,
							"imm_for_code": item.imm_for_code,
							"IMM_CITY": item.IMM_CITY,
							"for_code": item.for_code,
							"for_city": item.for_city,
							"prim_transporter_code": item.prim_transporter_code,
							"prim_transporter_name": item.prim_transporter_name,
							"truck_no": item.truck_no,
							"truck_type": item.truck_type,
							"str": item.yr_mfg,
							"N": item.new_or_old,
							"bmodel": item.bmodel,
							"quantity": item.quantity,
							"car_equi": item.car_equi,
							"tot_freight": item.tot_freight,
							"tot_dmg_amt": item.tot_dmg_amt,
							"tot_sort_amt": item.tot_sort_amt,
							"tot_penalty": item.tot_penalty,
							"tot_delay_amt": item.tot_delay_amt,
							"net_tot_fret_amt": item.net_tot_fret_amt,
							"prim_fret_amt": item.prim_fret_amt,
							"prim_dmg_amt": item.prim_dmg_amt,
							"prim_sort_amt": item.prim_sort_amt,
							"prim_penalty_amt": item.prim_penalty_amt,
							"prim_delay_amt": item.prim_delay_amt,
							"net_prim_fret_amt": item.net_prim_fret_amt,
							"tvp_stock_charges": item.tvp_stock_charges,
							"sec_load_no": item.sec_load_no,
							"sec_truck_no": item.sec_truck_no,
							"sec_transporter_name": item.sec_transporter_name,
							"sec_fret_amt": item.sec_fret_amt,
							"sec_dmg_amt": item.sec_dmg_amt,
							"sec_sort_amt": item.sec_sort_amt,
							"sec_panalty_amt": item.sec_panalty_amt,
							"sec_delay_amt": item.sec_delay_amt,
							"net_sec_fret_amt": item.net_sec_fret_amt,
							"sec_load_date": item.sec_load_date,
							"sec_transporter_code": item.sec_transporter_code,
							"prim_outstand_delay_days": outstand_delay_days,
							"prim_status": status,
							"prim_payment_date": payment_date,
							"prim_remarks": item.prim_remarks,
							"sec_remarks": item.sec_remarks,
							"sec_status": secstatus,
							"sec_payment_date": sec_payment_date,
							"prim_uploadfiles": item.prim_uploadfiles,
							"sec_uploadfiles": item.sec_uploadfiles,
							"prim_upload_date": item.prim_upload_date,
							"prim_bill_date": item.prim_bill_date,
							"sec_upload_date": item.sec_upload_date,
							"sec_bill_date": item.sec_bill_date,
							"sec_is_payment_receive": item.sec_is_payment_receive,
							"stockyard_outstand_delay_days": item.stockyard_outstand_delay_days,
							"stockyard_status": item.stockyard_status,
							"stockyard_upload_date": item.stockyard_upload_date,
							"tvp_code": item.tvp_code,
						})
					})
				}
				//	console.log("allinv ", allinv)

				let newprimaryArray = [];
				// Declare an empty object 
				let totuniqueObject = {};
				var totobjTitle;
				// Loop for the array elements 
				for (let i in primarytrans) {

					// Extract the title 
					totobjTitle = primarytrans[i]['value'];

					// Use the title as the index 
					totuniqueObject[totobjTitle] = primarytrans[i];
				}

				// Loop to push unique object into array 
				newprimaryArray.push({ value: "all", label: "All" });
				for (var a in totuniqueObject) {
					newprimaryArray.push(totuniqueObject[a]);
				}


				let newsecondArray = [];
				// Declare an empty object 
				let totsecuniqueObject = {};
				var totsecobjTitle;
				// Loop for the array elements 
				for (let j in secondtrans) {

					// Extract the title 
					totsecobjTitle = secondtrans[j]['value'];

					// Use the title as the index 
					totsecuniqueObject[totsecobjTitle] = secondtrans[j];
				}

				// Loop to push unique object into array 
				newsecondArray.push({ value: "all", label: "All" });
				for (var a in totsecuniqueObject) {
					newsecondArray.push(totuniqueObject[a]);
				}

				this.setState({
					allData: recordsarr,
					rowData: recordsarr,
					primarytrans: newprimaryArray,
					secondtrans: newsecondArray,
					loadshow: 'show-n',
					overly: 'show-n',
					// zerofifteen:zerofifteen,
					// fifteenthirty:fifteenthirty,
					// thirtyonefortyfive:thirtyonefortyfive,
					// morefortyfive:morefortyfive,
					loadshow: 'show-n',
					overlay: 'show-n',
				})
			})

	}

	onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
	};


	onGridState = () => {
		//console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);


	}

	onClickHideAll() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			sliderRouteTranslate: '',
			tabsliderTranslate: '',
			openUpdatedModal: false
		});

	}
	closeAlert = () => {
		this.setState({
			show: false
		});
	}


	onClickSaveGridState() {
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		// console.log("Columns ", window.colState)
		// console.log("Group ", window.groupState)
		// console.log("Sort ", window.sortState)
		// console.log("Filter ", window.filterState)
		let screenpage = 'Primary Payments Dashboard';



		let reqparams = {
			gridcolumns: window.colState,
			gridgroup: window.groupState,
			gridcolsort: window.sortState,
			gridcolfilter: window.filterState,
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
			.then((response) => {
				//console.log("State response ",response.data)
				this.setState({
					show: true,
					basicTitle: "Successfully saved grid layout",
					basicType: "success",
					screenurl: window.location.pathname,
					screentitle: screenpage
				})
			})
			.catch(function (e) {
				console.log("Error ", e)
			})
	}

	restoreGridStates() {
		try {
			if (this.state.usergridstate.length > 0) {
				var windowstates = this.state.usergridstate;
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

			}
		}
		catch (e) {

		}

	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
			.then((response) => {
				this.setState({
					show: true,
					basicTitle: "Successfully reset default grid layout",
					basicType: "success"
				})
			})

	};

	handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		var month = ("0" + (d.getMonth() + 1)).slice(-2); // Adding leading zero if month is less than 10
		var day = ("0" + d.getDate()).slice(-2); // Adding leading zero if day is less than 10
		var startdate = d.getFullYear() + "-" + month + "-" + day;
		if (this.state.endDate != undefined && this.state.endDate != "") {
			var startDate = new Date(startdate);
			var endDate = new Date(this.state.endDate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			if (startTime > endTime) {
				this.setState({
					show: true,
					basicTitle: 'To Date should be greater than From Date',
					basicType: "danger",
					loadshow: 'show-n'
				});
			} else {
				this.setState({
					startDate: startdate
				});
			}
		} else {
			this.setState({
				startDate: startdate
			});
		}
	}

	handlerEndDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		var month = ("0" + (d.getMonth() + 1)).slice(-2); // Adding leading zero if month is less than 10
		var day = ("0" + d.getDate()).slice(-2); // Adding leading zero if day is less than 10
		var edate = d.getFullYear() + "-" + month + "-" + day;
		if (this.state.startDate != undefined && this.state.startDate != "") {
			var startDate = new Date(this.state.startDate);
			var endDate = new Date(edate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			if (startTime > endTime) {
				this.setState({
					show: true,
					basicTitle: 'To Date should be greater than From Date',
					basicType: "danger",
					loadshow: 'show-n'
				});
			} else {
				this.setState({
					endDate: edate
				});
			}
		} else {
			this.setState({
				endDate: edate
			});
		}
	}

	handlerStartDateTimeExport = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

		this.setState({
			exportStart: startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerEndDateTimeExport = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
		this.setState({
			exportEnd: edate
		});
	}
	resetUpload = () => {
		this.setState({
			exportStart: '',
			exportEnd: '',
			bulkslideExport: '',
			overly: 'show-n',
		});
		document.getElementById("upform").reset();
	}

	onClickFilterButton = async () => {
		//console.log("onClickFilterButton ")
		this.setState({
			loadshow: 'show-m',
			overlay: 'show-m',
		});
		if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
			var sDate = this.state.defaultsdate;
		}
		else {
			var sDate = this.state.startDate;
		}
		if (this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN") {
			var eDate = this.state.defaultedate;
		}
		else {
			var eDate = this.state.endDate;
		}

		// console.log("primarytransporter ", this.state.primarytransporter)
		// console.log("secondtransporter ", this.state.secondtransporter)
		var primarytransportercode = [];
		var secondarytransportercode = [];
		if (this.state.primarytransporter != "") {
			primarytransportercode.push(this.state.primarytransporter.value);
		}
		if (this.state.secondtransporter != "") {
			secondarytransportercode.push(this.state.secondtransporter.value);
		}
		var parameters = {
			pagetype: "all",
			primary_transporter_code: primarytransportercode,
			secondary_transporter_code: secondarytransportercode,
			startDate: sDate,
			endDate: eDate
		}

		// console.log("requestparams ", parameters)
		this.onFilterLoadDashboardData2(parameters, sDate, eDate)

	}

	onClickFilterButtonToExport() {
		this.setState(
			{
				exportStart: '',
				exportEnd: '',
				overly: "show-m",
				bulkslideExport: "slider-translate",
			},
		);
	}


	async onFilterLoadDashboardData(parameters) {
		this.setState({
			loadshow: 'show-m',
			overly: 'show-m'
		})
		var createdate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
		//console.log("createdate ",createdate);
		var s1 = moment.parseZone(createdate).format("x")
		//console.log("s1 ",s1)
		// console.log(parameters,"806")
		await redirectURL.post("/consignments/secondarypaymentsbyparamsdata", parameters)
			.then((response) => {
				//console.log("parameters ",parameters)
				// console.log(records,"810")
				var records = response.data.records;
				var recordsarr = []
				var zerofifteen = []
				var fifteenthirty = []
				var thirtyonefortyfive = []
				var morefortyfive = []
				var zeroamt = 0
				var fifteen = 0
				var thirty = 0
				var moreforty = 0
				if (records.length > 0) {
					records.map((item) => {
						// console.log(moreforty,item.sec_fret_amt,"moreforty824")
						var outstand_delay_days = ""
						if (item.prim_outstand_delay_days === undefined) {
							outstand_delay_days = ""
						}
						else {
							outstand_delay_days = item.prim_outstand_delay_days
						}
						var status = ""
						if (item.prim_status === undefined) {
							status = "Pending"
						}
						else {
							status = item.prim_status
						}
						var payment_date = ""
						if (item.prim_payment_date === undefined) {
							payment_date = ""
						}
						else {
							payment_date = item.prim_payment_date
						}
						var inv = moment.parseZone(item.invoice_date).format("YYYY-MM-DD HH:mm:ss");
						//console.log("inv ", inv);
						var s2 = moment.parseZone(inv).format("x")
						//console.log(s2);
						var diffreetime = moment.duration(moment(createdate).diff(inv));
						var delaydays = Math.round(diffreetime.asDays());

						if ((delaydays < 0 || delaydays >= 0) && delaydays <= 15) {
							zerofifteen.push(item)
							if (item.sec_status != "Payment received") {
								zeroamt = parseFloat(zeroamt) + parseFloat(item.sec_fret_amt)
							}
						}
						if (delaydays >= 16 && delaydays <= 30) {
							fifteenthirty.push(item)
							if (item.sec_status != "Payment received") {
								fifteen = parseFloat(fifteen) + parseFloat(item.sec_fret_amt)
							}
						}
						if (delaydays >= 31 && delaydays <= 45) {
							thirtyonefortyfive.push(item)
							if (item.sec_status != "Payment received") {
								thirty = parseFloat(thirty) + parseFloat(item.sec_fret_amt)
							}
						}
						if (delaydays > 45) {
							morefortyfive.push(item)
							if (item.sec_status != "Payment received") {
								moreforty = parseFloat(moreforty) + parseFloat(item.sec_fret_amt)
							}
						}
						var secstatus = ""
						if (item.sec_status === undefined) {
							secstatus = "Pending"
						}
						else {
							secstatus = item.sec_status
						}
						var sec_payment_date = ""
						if (item.sec_payment_date === undefined) {
							sec_payment_date = ""
						}
						else {
							sec_payment_date = item.sec_payment_date
						}
						recordsarr.push({
							"_id": item._id,
							"consignment_code": item.consignment_code,
							"load_no": item.load_no,
							"vin": item.vin,
							"invoice_date": item.invoice_date,
							"pod_date": item.pod_date,
							"imm_for_code": item.imm_for_code,
							"IMM_CITY": item.IMM_CITY,
							"for_code": item.for_code,
							"for_city": item.for_city,
							"prim_transporter_code": item.prim_transporter_code,
							"prim_transporter_name": item.prim_transporter_name,
							"truck_no": item.truck_no,
							"truck_type": item.truck_type,
							"str": item.yr_mfg,
							"N": item.new_or_old,
							"bmodel": item.bmodel,
							"quantity": item.quantity,
							"car_equi": item.car_equi,
							"tot_freight": item.tot_freight,
							"tot_dmg_amt": item.tot_dmg_amt,
							"tot_sort_amt": item.tot_sort_amt,
							"tot_penalty": item.tot_penalty,
							"tot_delay_amt": item.tot_delay_amt,
							"net_tot_fret_amt": item.net_tot_fret_amt,
							"prim_fret_amt": item.prim_fret_amt,
							"prim_dmg_amt": item.prim_dmg_amt,
							"prim_sort_amt": item.prim_sort_amt,
							"prim_penalty_amt": item.prim_penalty_amt,
							"prim_delay_amt": item.prim_delay_amt,
							"net_prim_fret_amt": item.net_prim_fret_amt,
							"tvp_stock_charges": item.tvp_stock_charges,
							"sec_load_no": item.sec_load_no,
							"sec_truck_no": item.sec_truck_no,
							"sec_transporter_name": item.sec_transporter_name,
							"sec_fret_amt": item.sec_fret_amt,
							"sec_dmg_amt": item.sec_dmg_amt,
							"sec_sort_amt": item.sec_sort_amt,
							"sec_panalty_amt": item.sec_panalty_amt,
							"sec_delay_amt": item.sec_delay_amt,
							"net_sec_fret_amt": item.net_sec_fret_amt,
							"sec_load_date": item.sec_load_date,
							"sec_transporter_code": item.sec_transporter_code,
							"prim_outstand_delay_days": outstand_delay_days,
							"prim_status": status,
							"prim_payment_date": payment_date,
							"sec_status": secstatus,
							"sec_payment_date": sec_payment_date,
							"prim_uploadfiles": item.prim_uploadfiles,
							"sec_uploadfiles": item.sec_uploadfiles,
							"prim_upload_date": item.prim_upload_date,
							"prim_bill_date": item.prim_bill_date,
							"sec_upload_date": item.sec_upload_date,
							"sec_bill_date": item.sec_bill_date,
							"sec_is_payment_receive": item.sec_is_payment_receive,
							"sec_remarks": item.sec_remarks,
							"prim_remarks": item.prim_remarks,
							"stockyard_outstand_delay_days": item.stockyard_outstand_delay_days,
							"stockyard_status": item.stockyard_status,
							"tvp_code": item.tvp_code,
						})
					})
				}
				//	console.log("primarytrans ", primarytrans)

				this.setState({
					allData: recordsarr,
					rowData: recordsarr,
					loadshow: 'show-n',
					overly: 'show-n',
					zerofifteen: zerofifteen,
					fifteenthirty: fifteenthirty,
					thirtyonefortyfive: thirtyonefortyfive,
					morefortyfive: morefortyfive,
					zeroamt: zeroamt,
					fifteen: fifteen,
					thirty: thirty,
					moreforty: moreforty,
				})

			})

	}

	async onFilterLoadDashboardData2(parameters, sDate1, eDate2) {
		console.log(parameters, sDate1, eDate2, 'parameters,sDate1,eDate2')
		this.setState({
			loadshow: 'show-m',
			overlay: 'show-m',

		});
		parameters.startTotal = sDate1;
		parameters.endTotal = eDate2;
		console.log(parameters, 'parameters')
		var totalFetchedCount = await redirectURL.post("/consignments/TotalCountOfDataTodownloadDumpDataforMSILDashboard", parameters);

		console.log(totalFetchedCount.data.data, 'count')
		var totalDataCountStore = totalFetchedCount.data.data;

		var createdate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
		// console.log("createdate ",createdate);
		var s1 = moment.parseZone(createdate).format("x")
		//console.log("s1 ",s1)
		let allSecondaryPaymentsData = [];

		try {
			const [secondaryPaymentsCounterData, stockyardPaymentsCounterData, secondaryPaymentsData] = await Promise.all([
				this.fetchDataInChunks("/consignments/secondarypaymentscounterdataOnFilter", parameters),
				this.fetchDataInChunks("/consignments/stockyardpaymentscounterdataOnFilter", parameters),
				this.fetchDataInChunks("/consignments/secondarypaymentsdataOnFilter", parameters)
			]);

			if (secondaryPaymentsCounterData) {
				console.log(secondaryPaymentsCounterData, 'secondaryPaymentsCounterData')

				var records = []
				var zerofifteen = []
				var fifteenthirty = []
				var thirtyonefortyfive = []
				var morefortyfive = []

				var stockzerofifteen = []
				var stockfifteenthirty = []
				var stockthirtyonefortyfive = []
				var stockmorefortyfive = []
				var zeroamt = 0
				var fifteen = 0
				var thirty = 0
				var moreforty = 0

				records = secondaryPaymentsCounterData;
				// console.log("records1", records.length)
				//debugger
				if (records.length > 0) {
					records.map((item) => {
						var inv = moment.parseZone(item.sec_upload_date).format("YYYY-MM-DD HH:mm:ss");
						//console.log("inv ", inv);
						var s2 = moment.parseZone(inv).format("x")
						//console.log(s2);
						var diffreetime = moment.duration(moment(createdate).diff(inv));
						var delaydays = Math.round(diffreetime.asDays());
						// console.log("prim delaydays ", delaydays)
						if (item.sec_is_payment_receive == "Yes" && item.sec_status == "Payment received") {

						}
						else {

							if (delaydays >= 0 && delaydays <= 15) {
								zerofifteen.push(item)
								if (item.sec_status != "Payment received") {
									zeroamt = parseFloat(zeroamt) + parseFloat(item.sec_fret_amt)
								}

							}
							if (delaydays >= 16 && delaydays <= 30) {
								//console.log("item",item)
								fifteenthirty.push(item)
								stockfifteenthirty.push(item)
								if (item.sec_status != "Payment received") {
									fifteen = parseFloat(fifteen) + parseFloat(item.sec_fret_amt)
								}

							}
							if (delaydays >= 31 && delaydays <= 45) {
								thirtyonefortyfive.push(item)
								stockthirtyonefortyfive.push(item)
								if (item.sec_status != "Payment received") {
									thirty = parseFloat(thirty) + parseFloat(item.sec_fret_amt)
								}

							}
							if (delaydays > 45) {
								// console.log(moreforty,"285")
								morefortyfive.push(item)
								stockmorefortyfive.push(item)
								if (item.sec_status != "Payment received") {
									moreforty = parseFloat(moreforty) + parseFloat(item.sec_fret_amt)
								}

							}
						}

					})

				}

				this.setState({
					zerofifteen: zerofifteen,
					fifteenthirty: fifteenthirty,
					thirtyonefortyfive: thirtyonefortyfive,
					morefortyfive: morefortyfive,
					zeroamt: zeroamt,
					fifteen: fifteen,
					thirty: thirty,
					moreforty: moreforty,
				})

			}
			if (stockyardPaymentsCounterData) {
				console.log(stockyardPaymentsCounterData, 'stockyardPaymentsCounterData')
				var recordstock = []
				var stockzerofifteen = []
				var stockfifteenthirty = []
				var stockthirtyonefortyfive = []
				var stockmorefortyfive = []
				var zeroamt = 0
				var fifteen = 0
				var thirty = 0
				var moreforty = 0
				recordstock = stockyardPaymentsCounterData;
				// console.log("records2", recordstock)
				//debugger
				if (recordstock.length > 0) {
					recordstock.map((item) => {

						//stockcounter
						// console.log("item.stockyard_upload_date ", typeof item.stockyard_upload_date)
						if (item.stockyard_upload_date != "") {
							// console.log("item.stockyard_upload_date ", item.stockyard_upload_date)
							// console.log("item.stockyard_is_payment_receive ", item.stockyard_is_payment_receive)

							if (item.stockyard_is_payment_receive == "Yes" && item.stockyard_status == "Payment received") {

							}
							else {
								var inv1 = moment.parseZone(item.stockyard_upload_date).format("YYYY-MM-DD HH:mm:ss");
								//console.log("inv ", inv);
								var s2 = moment.parseZone(inv1).format("x")
								//console.log(s2);
								var diffreetime1 = moment.duration(moment(createdate).diff(inv1));
								var delaydays1 = Math.round(diffreetime1.asDays());
								// console.log("delaydays1 ", delaydays1)
								if (delaydays1 >= 0 && delaydays1 <= 15) {
									stockzerofifteen.push(item)
									if (item.stockyard_status != "Payment received") {
										zeroamt = parseFloat(zeroamt) + parseFloat(item.tvp_stock_charges)
									}
								}
								if (delaydays1 >= 16 && delaydays1 <= 30) {
									stockfifteenthirty.push(item)
									if (item.stockyard_status != "Payment received") {
										fifteen = parseFloat(fifteen) + parseFloat(item.tvp_stock_charges)
									}

								}
								if (delaydays1 >= 31 && delaydays1 <= 45) {
									stockthirtyonefortyfive.push(item)
									if (item.stockyard_status != "Payment received") {
										thirty = parseFloat(thirty) + parseFloat(item.tvp_stock_charges)
									}
								}
								if (delaydays1 > 45) {
									stockmorefortyfive.push(item)
									if (item.stockyard_status != "Payment received") {
										moreforty = parseFloat(moreforty) + parseFloat(item.tvp_stock_charges)
									}
								}
							}
						}
					})

				}
				this.setState({
					stockzerofifteen: stockzerofifteen,
					stockfifteenthirty: stockfifteenthirty,
					stockthirtyonefortyfive: stockthirtyonefortyfive,
					stockmorefortyfive: stockmorefortyfive,
					stockzeroamt: zeroamt,
					stockfifteen: fifteen,
					stockthirty: thirty,
					stockmoreforty: moreforty
				})


			}

			// Handle secondary payments data
			if (secondaryPaymentsData) {
				console.log(secondaryPaymentsData, 'secondaryPaymentsData');
				secondaryPaymentsData.forEach(data => {
					allSecondaryPaymentsData = allSecondaryPaymentsData.concat(data.records);
				});
			}
			console.log(allSecondaryPaymentsData, 'allSecondaryPaymentsData')
			if (allSecondaryPaymentsData) {
				var records = allSecondaryPaymentsData;
				var recordsarr = []
				var primarytrans = []
				var secondtrans = []
				var allinv = []
				console.log(records, "records3")
				//debugger
				if (records.length > 0) {
					records.map((item) => {

						var outstand_delay_days = ""
						if (item.prim_outstand_delay_days === undefined) {
							outstand_delay_days = ""
						}
						else {
							outstand_delay_days = item.prim_outstand_delay_days
						}
						var status = ""
						if (item.prim_status === undefined) {
							status = "Pending"
						}
						else {
							status = item.prim_status
						}
						var payment_date = ""
						if (item.prim_payment_date === undefined) {
							payment_date = ""
						}
						else {
							payment_date = item.prim_payment_date
						}
						allinv.push(item.invoice_date)

						var secstatus = ""
						if (item.sec_status === undefined) {
							secstatus = "Pending"
						}
						else {
							secstatus = item.sec_status
						}
						var sec_payment_date = ""
						if (item.sec_payment_date === undefined) {
							sec_payment_date = ""
						}
						else {
							sec_payment_date = item.sec_payment_date
						}

						recordsarr.push({
							"_id": item._id,
							"consignment_code": item.consignment_code,
							"load_no": item.load_no,
							"vin": item.vin,
							"invoice_date": item.invoice_date,
							"pod_date": item.pod_date,
							"imm_for_code": item.imm_for_code,
							"IMM_CITY": item.IMM_CITY,
							"for_code": item.for_code,
							"for_city": item.for_city,
							"prim_transporter_code": item.prim_transporter_code,
							"prim_transporter_name": item.prim_transporter_name,
							"truck_no": item.truck_no,
							"truck_type": item.truck_type,
							"str": item.yr_mfg,
							"N": item.new_or_old,
							"bmodel": item.bmodel,
							"quantity": item.quantity,
							"car_equi": item.car_equi,
							"tot_freight": item.tot_freight,
							"tot_dmg_amt": item.tot_dmg_amt,
							"tot_sort_amt": item.tot_sort_amt,
							"tot_penalty": item.tot_penalty,
							"tot_delay_amt": item.tot_delay_amt,
							"net_tot_fret_amt": item.net_tot_fret_amt,
							"prim_fret_amt": item.prim_fret_amt,
							"prim_dmg_amt": item.prim_dmg_amt,
							"prim_sort_amt": item.prim_sort_amt,
							"prim_penalty_amt": item.prim_penalty_amt,
							"prim_delay_amt": item.prim_delay_amt,
							"net_prim_fret_amt": item.net_prim_fret_amt,
							"tvp_stock_charges": item.tvp_stock_charges,
							"sec_load_no": item.sec_load_no,
							"sec_truck_no": item.sec_truck_no,
							"sec_transporter_name": item.sec_transporter_name,
							"sec_fret_amt": item.sec_fret_amt,
							"sec_dmg_amt": item.sec_dmg_amt,
							"sec_sort_amt": item.sec_sort_amt,
							"sec_panalty_amt": item.sec_panalty_amt,
							"sec_delay_amt": item.sec_delay_amt,
							"net_sec_fret_amt": item.net_sec_fret_amt,
							"sec_load_date": item.sec_load_date,
							"sec_transporter_code": item.sec_transporter_code,
							"prim_outstand_delay_days": outstand_delay_days,
							"prim_status": status,
							"prim_payment_date": payment_date,
							"prim_remarks": item.prim_remarks,
							"sec_remarks": item.sec_remarks,
							"sec_status": secstatus,
							"sec_payment_date": sec_payment_date,
							"prim_uploadfiles": item.prim_uploadfiles,
							"sec_uploadfiles": item.sec_uploadfiles,
							"prim_upload_date": item.prim_upload_date,
							"prim_bill_date": item.prim_bill_date,
							"sec_upload_date": item.sec_upload_date,
							"sec_bill_date": item.sec_bill_date,
							"sec_is_payment_receive": item.sec_is_payment_receive,
							"stockyard_outstand_delay_days": item.stockyard_outstand_delay_days,
							"stockyard_status": item.stockyard_status,
							"stockyard_upload_date": item.stockyard_upload_date,
							"tvp_code": item.tvp_code,
						})
					})
				}
				//	console.log("allinv ", allinv)

				let newprimaryArray = [];
				// Declare an empty object 
				let totuniqueObject = {};
				var totobjTitle;
				// Loop for the array elements 
				for (let i in primarytrans) {

					// Extract the title 
					totobjTitle = primarytrans[i]['value'];

					// Use the title as the index 
					totuniqueObject[totobjTitle] = primarytrans[i];
				}

				// Loop to push unique object into array 
				newprimaryArray.push({ value: "all", label: "All" });
				for (var a in totuniqueObject) {
					newprimaryArray.push(totuniqueObject[a]);
				}


				let newsecondArray = [];
				// Declare an empty object 
				let totsecuniqueObject = {};
				var totsecobjTitle;
				// Loop for the array elements 
				for (let j in secondtrans) {

					// Extract the title 
					totsecobjTitle = secondtrans[j]['value'];

					// Use the title as the index 
					totsecuniqueObject[totsecobjTitle] = secondtrans[j];
				}

				// Loop to push unique object into array 
				newsecondArray.push({ value: "all", label: "All" });
				for (var a in totsecuniqueObject) {
					newsecondArray.push(totuniqueObject[a]);
				}
				console.log(recordsarr, 'recordsarr')
				this.setState({
					allData: recordsarr,
					rowData: recordsarr,
					loadshow: 'show-n',
					overly: 'show-n',
					loadshow: 'show-n',
					overlay: 'show-n',
				})

			}



		} catch (error) {
			console.error("Error fetching data:", error);
		}

	}

	async fetchDataInChunks(endpoint, parameters) {
		const startDate = moment(parameters.startDate, 'YYYY-MM-DD');
		const endDate = moment(parameters.endDate, 'YYYY-MM-DD');
		const daysDiff = endDate.diff(startDate, 'days');

		let allRecords = [];

		let currentStartDate = startDate.clone(); // Initialize current start date

		while (currentStartDate.isBefore(endDate)) { // Continue until current start date is before end date
			const chunkEndDate = currentStartDate.clone().add(15, 'days'); // Calculate chunk end date

			// Ensure chunk end date does not exceed the overall end date
			const chunkEndDateClamped = moment.min(chunkEndDate, endDate);

			const chunkParameters = {
				...parameters,
				startDate: currentStartDate.format('YYYY-MM-DD'),
				endDate: chunkEndDateClamped.format('YYYY-MM-DD')
			};

			try {
				const response = await redirectURL.post(endpoint, chunkParameters);
				const records = response.data;

				allRecords = allRecords.concat(records);
				var allSecondaryPaymentsData = []
				// Update current start date for the next iteration
				currentStartDate = chunkEndDateClamped.clone().add(1, 'day');
			} catch (error) {
				console.error("Error fetching data:", error);
				break; // Exit loop if an error occurs
			}
		}

		return allRecords;
	}

	onClickSubmitDownloadData = async (e) => {
		e.preventDefault();
		const { exportStart, exportEnd } = this.state;
	
		if (!exportStart || !exportEnd) {
			this.setState({
				loading: false,
				activeExportState: false,
				show: true,
				basicTitle: 'Please select both start and end dates',
				basicType: "danger",
				loadshow: 'show-n',
			})
			return;
		}
	
		this.setState({
			overly: "show-n",
			bulkslideExport: "",
			loading: true,
			percentageCalculation: 0,
			activeExportState: true,
		})
		this.setState({
			overly: "show-n",
			bulkslideExport: "",
			loading: true,
			percentageCalculation: 0,
			activeExportState: true,
		})
		var primarytransportercode = [];
		var secondarytransportercode = [];
		if (this.state.primarytransporter != "") {
			primarytransportercode.push(this.state.primarytransporter.value);
		}
		if (this.state.secondtransporter != "") {
			secondarytransportercode.push(this.state.secondtransporter.value);
		}
		var parameters = {
			pagetype: "all",
			primary_transporter_code: primarytransportercode,
			secondary_transporter_code: secondarytransportercode,
			startDate: this.state.exportStart,
			endDate: this.state.exportEnd
		}
		// console.log(parameters,sDate1,eDate2,'parameters,sDate1,eDate2')

		parameters.startTotal = this.state.exportStart;
		parameters.endTotal = this.state.exportEnd;
		console.log(parameters, 'parameters')
		var totalFetchedCount = await redirectURL.post("/consignments/TotalCountOfDataTodownloadDumpDataforMSILDashboard", parameters);

		console.log(totalFetchedCount.data.data, 'count')
		var totalDataCountStore = totalFetchedCount.data.data;

		if (totalDataCountStore === 0) {
			this.setState({
				loading: false,
				activeExportState: false,
				show: true,
				basicTitle: 'No data available for the selected date range',
				basicType: "danger",
				loadshow: 'show-n',
			})
			
			return;
		}

		var createdate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
		// console.log("createdate ",createdate);
		var s1 = moment.parseZone(createdate).format("x")
		//console.log("s1 ",s1)
		let allSecondaryPaymentsData = [];

		try {
			const [secondaryPaymentsData] = await Promise.all([
				// this.fetchDataInChunks("/consignments/secondarypaymentscounterdataOnFilter", parameters),
				// this.fetchDataInChunks("/consignments/stockyardpaymentscounterdataOnFilter", parameters),
				this.fetchDataInChunks("/consignments/secondarypaymentsdataOnFilter", parameters, totalDataCountStore)
			]);

			// if (secondaryPaymentsCounterData) {
			// 	console.log(secondaryPaymentsCounterData,'secondaryPaymentsCounterData')

			// 		var records = []
			// 		var zerofifteen=[]
			// 		var fifteenthirty=[]
			// 		var thirtyonefortyfive=[]
			// 		var morefortyfive=[]

			// 		var stockzerofifteen=[]
			// 		var stockfifteenthirty=[]
			// 		var stockthirtyonefortyfive=[]
			// 		var stockmorefortyfive=[]
			// 		var zeroamt =0
			// 		var fifteen = 0
			// 		var thirty = 0
			// 		var moreforty = 0

			// 		records = secondaryPaymentsCounterData;
			// 		// console.log("records1", records.length)
			// 		//debugger
			// 		if(records.length > 0)
			// 		{
			// 			records.map((item) => {
			// 				var inv = moment.parseZone(item.sec_upload_date).format("YYYY-MM-DD HH:mm:ss");
			// 				//console.log("inv ", inv);
			// 				var s2 = moment.parseZone(inv).format("x")
			// 				//console.log(s2);
			// 				var diffreetime = moment.duration(moment(createdate).diff(inv));
			// 				var delaydays =Math.round(diffreetime.asDays());
			// 				// console.log("prim delaydays ", delaydays)
			// 				if(item.sec_is_payment_receive == "Yes" && item.sec_status == "Payment received")
			// 				{

			// 				}
			// 				else{

			// 					if(delaydays>=0 && delaydays<=15)
			// 					{
			// 						zerofifteen.push(item)
			// 						if(item.sec_status != "Payment received")
			// 						 {								 
			// 							zeroamt = parseFloat(zeroamt)+parseFloat(item.sec_fret_amt)
			// 						 }

			// 					}
			// 					if(delaydays>=16 && delaydays<=30)
			// 					{
			// 						//console.log("item",item)
			// 						fifteenthirty.push(item)
			// 						stockfifteenthirty.push(item)
			// 						if(item.sec_status != "Payment received")
			// 						 {								 
			// 							fifteen = parseFloat(fifteen)+parseFloat(item.sec_fret_amt)
			// 						 }

			// 					}
			// 					if(delaydays>=31 && delaydays<=45)
			// 					{
			// 						thirtyonefortyfive.push(item)
			// 						stockthirtyonefortyfive.push(item)
			// 						if(item.sec_status != "Payment received")
			// 						 {								 
			// 							thirty = parseFloat(thirty)+parseFloat(item.sec_fret_amt)
			// 						 }

			// 					}
			// 					if(delaydays > 45)
			// 					{
			// 						// console.log(moreforty,"285")
			// 						morefortyfive.push(item)
			// 						stockmorefortyfive.push(item)
			// 						if(item.sec_status != "Payment received")
			// 						 {								 
			// 							moreforty = parseFloat(moreforty)+parseFloat(item.sec_fret_amt)
			// 						 }

			// 					}
			// 				}

			// 			})

			// 		}

			// 		this.setState({
			// 			zerofifteen:zerofifteen,
			// 			fifteenthirty:fifteenthirty,
			// 			thirtyonefortyfive:thirtyonefortyfive,
			// 			morefortyfive:morefortyfive,
			// 			zeroamt:zeroamt,
			// 			fifteen:fifteen,
			// 			thirty:thirty,
			// 			moreforty:moreforty,
			// 		})

			// }
			// if (stockyardPaymentsCounterData) {
			// 	console.log(stockyardPaymentsCounterData,'stockyardPaymentsCounterData')
			// 		var recordstock = []
			// 		var stockzerofifteen=[]
			// 		var stockfifteenthirty=[]
			// 		var stockthirtyonefortyfive=[]
			// 		var stockmorefortyfive=[]
			// 		var zeroamt =0
			// 		var fifteen = 0
			// 		var thirty = 0
			// 		var moreforty = 0
			// 		recordstock = stockyardPaymentsCounterData;
			// 		// console.log("records2", recordstock)
			// 		//debugger
			// 		if(recordstock.length > 0)
			// 		{
			// 			recordstock.map((item) => {

			// 				//stockcounter
			// 				// console.log("item.stockyard_upload_date ", typeof item.stockyard_upload_date)
			// 				if(item.stockyard_upload_date != "")
			// 				{
			// 					// console.log("item.stockyard_upload_date ", item.stockyard_upload_date)
			// 					// console.log("item.stockyard_is_payment_receive ", item.stockyard_is_payment_receive)

			// 					if(item.stockyard_is_payment_receive == "Yes" && item.stockyard_status == "Payment received")
			// 					{

			// 					}
			// 					else{
			// 						var inv1 = moment.parseZone(item.stockyard_upload_date).format("YYYY-MM-DD HH:mm:ss");
			// 						//console.log("inv ", inv);
			// 						var s2 = moment.parseZone(inv1).format("x")
			// 						//console.log(s2);
			// 						var diffreetime1 = moment.duration(moment(createdate).diff(inv1));
			// 						var delaydays1 =Math.round(diffreetime1.asDays());
			// 						// console.log("delaydays1 ", delaydays1)
			// 						if(delaydays1>=0 && delaydays1<=15)
			// 						{
			// 							stockzerofifteen.push(item)
			// 							if(item.stockyard_status != "Payment received")
			// 							{
			// 							   zeroamt = parseFloat(zeroamt)+parseFloat(item.tvp_stock_charges)
			// 							}
			// 						}
			// 						if(delaydays1>=16 && delaydays1<=30)
			// 						{
			// 							stockfifteenthirty.push(item)
			// 							if(item.stockyard_status != "Payment received")
			// 							{
			// 								fifteen = parseFloat(fifteen)+parseFloat(item.tvp_stock_charges)
			// 							}

			// 						}
			// 						if(delaydays1>=31 && delaydays1<=45)
			// 						{
			// 							stockthirtyonefortyfive.push(item)
			// 							if(item.stockyard_status != "Payment received")
			// 							{
			// 								thirty = parseFloat(thirty)+parseFloat(item.tvp_stock_charges)
			// 							}
			// 						}
			// 						if(delaydays1 > 45)
			// 						{
			// 							stockmorefortyfive.push(item)
			// 							if(item.stockyard_status != "Payment received")
			// 							{
			// 								moreforty = parseFloat(moreforty)+parseFloat(item.tvp_stock_charges)
			// 							}
			// 						}
			// 					}
			// 				}
			// 			})

			// 		}
			// 		this.setState({
			// 			stockzerofifteen:stockzerofifteen,
			// 			stockfifteenthirty:stockfifteenthirty,
			// 			stockthirtyonefortyfive:stockthirtyonefortyfive,
			// 			stockmorefortyfive:stockmorefortyfive,
			// 			stockzeroamt:zeroamt,
			// 			stockfifteen:fifteen,
			// 			stockthirty:thirty,
			// 			stockmoreforty:moreforty
			// 		})


			// }

			// Handle secondary payments data

			console.log(secondaryPaymentsData, 'secondaryPaymentsData');
			if (secondaryPaymentsData) {
				secondaryPaymentsData.forEach(data => {
					allSecondaryPaymentsData = allSecondaryPaymentsData.concat(data.records);
				});
			}
			console.log(allSecondaryPaymentsData, 'allSecondaryPaymentsData')
			if (allSecondaryPaymentsData) {
				var records = allSecondaryPaymentsData;
				var recordsarr = []
				var primarytrans = []
				var secondtrans = []
				var allinv = []
				console.log(records, "records3")
				//debugger
				if (records.length > 0) {
					records.map((item) => {

						var outstand_delay_days = ""
						if (item.prim_outstand_delay_days === undefined) {
							outstand_delay_days = ""
						}
						else {
							outstand_delay_days = item.prim_outstand_delay_days
						}
						var status = ""
						if (item.prim_status === undefined) {
							status = "Pending"
						}
						else {
							status = item.prim_status
						}
						var payment_date = ""
						if (item.prim_payment_date === undefined) {
							payment_date = ""
						}
						else {
							payment_date = item.prim_payment_date
						}
						allinv.push(item.invoice_date)

						var secstatus = ""
						if (item.sec_status === undefined) {
							secstatus = "Pending"
						}
						else {
							secstatus = item.sec_status
						}
						var sec_payment_date = ""
						if (item.sec_payment_date === undefined) {
							sec_payment_date = ""
						}
						else {
							sec_payment_date = item.sec_payment_date
						}
						const primUploadFileName = item.prim_uploadfiles && item.prim_uploadfiles.map(file => {
							const fileNameParts = file.name.split(',');
							return fileNameParts[0].trim();
						}).join(', ');
						const secUploadFileName = item.sec_uploadfiles && item.sec_uploadfiles.map(file => {
							const fileNameParts = file.name.split(',');
							return fileNameParts[0].trim();
						}).join('- ');
						recordsarr.push({
							// "_id" : item._id,
							"consignment_code": item.consignment_code,
							"load_no": item.load_no,
							"vin": item.vin,
							"invoice_date": item.invoice_date,
							"pod_date": item.pod_date,
							"imm_for_code": item.imm_for_code,
							"IMM_CITY": item.IMM_CITY,
							"for_code": item.for_code,
							"for_city": item.for_city,
							"prim_transporter_code": item.prim_transporter_code,
							"prim_transporter_name": item.prim_transporter_name,
							"truck_no": item.truck_no,
							"truck_type": item.truck_type,
							"str": item.yr_mfg,
							"N": item.new_or_old,
							"bmodel": item.bmodel,
							"quantity": item.quantity,
							"car_equi": item.car_equi,
							"tot_freight": item.tot_freight,
							"tot_dmg_amt": item.tot_dmg_amt,
							"tot_sort_amt": item.tot_sort_amt,
							"tot_penalty": item.tot_penalty,
							"tot_delay_amt": item.tot_delay_amt,
							"net_tot_fret_amt": item.net_tot_fret_amt,
							"prim_fret_amt": item.prim_fret_amt,
							"prim_dmg_amt": item.prim_dmg_amt,
							"prim_sort_amt": item.prim_sort_amt,
							"prim_penalty_amt": item.prim_penalty_amt,
							"prim_delay_amt": item.prim_delay_amt,
							"net_prim_fret_amt": item.net_prim_fret_amt,
							"tvp_stock_charges": item.tvp_stock_charges,
							"sec_load_no": item.sec_load_no,
							"sec_truck_no": item.sec_truck_no,
							"sec_transporter_name": item.sec_transporter_name,
							"sec_fret_amt": item.sec_fret_amt,
							"sec_dmg_amt": item.sec_dmg_amt,
							"sec_sort_amt": item.sec_sort_amt,
							"sec_panalty_amt": item.sec_panalty_amt,
							"sec_delay_amt": item.sec_delay_amt,
							"net_sec_fret_amt": item.net_sec_fret_amt,
							"sec_load_date": item.sec_load_date,
							"sec_transporter_code": item.sec_transporter_code,
							"prim_outstand_delay_days": outstand_delay_days,
							"prim_status": status,
							"prim_payment_date": payment_date,
							"prim_remarks": item.prim_remarks,
							"sec_remarks": item.sec_remarks,
							"sec_status": secstatus,
							"sec_payment_date": sec_payment_date,
							"prim_uploadfiles": primUploadFileName,
							"sec_uploadfiles": secUploadFileName,
							"prim_upload_date": item.prim_upload_date,
							"prim_bill_date": item.prim_bill_date,
							"sec_upload_date": item.sec_upload_date,
							"sec_bill_date": item.sec_bill_date,
							"sec_is_payment_receive": item.sec_is_payment_receive,
							"stockyard_outstand_delay_days": item.stockyard_outstand_delay_days,
							"stockyard_status": item.stockyard_status,
							"stockyard_upload_date": item.stockyard_upload_date,
							"tvp_code": item.tvp_code,
						})
					})
				}
				//	console.log("allinv ", allinv)

				let newprimaryArray = [];
				// Declare an empty object 
				let totuniqueObject = {};
				var totobjTitle;
				// Loop for the array elements 
				for (let i in primarytrans) {

					// Extract the title 
					totobjTitle = primarytrans[i]['value'];

					// Use the title as the index 
					totuniqueObject[totobjTitle] = primarytrans[i];
				}

				// Loop to push unique object into array 
				newprimaryArray.push({ value: "all", label: "All" });
				for (var a in totuniqueObject) {
					newprimaryArray.push(totuniqueObject[a]);
				}


				let newsecondArray = [];
				// Declare an empty object 
				let totsecuniqueObject = {};
				var totsecobjTitle;
				// Loop for the array elements 
				for (let j in secondtrans) {

					// Extract the title 
					totsecobjTitle = secondtrans[j]['value'];

					// Use the title as the index 
					totsecuniqueObject[totsecobjTitle] = secondtrans[j];
				}

				// Loop to push unique object into array 
				newsecondArray.push({ value: "all", label: "All" });
				for (var a in totsecuniqueObject) {
					newsecondArray.push(totuniqueObject[a]);
				}
				console.log(recordsarr, 'recordsarr')
				this.setState({
					// allData:recordsarr,
					// rowData:recordsarr,
					loadshow: 'show-n',
					overly: 'show-n',
					loading: false,
					activeExportState: false,
				})

				let csvContent = '';
				csvContent += Object.keys(recordsarr[0]).join(",") + "\n";
				recordsarr.forEach(item => {
					const rowValues = Object.values(item).map(value => {
						if (typeof value === 'string' && value.includes(',')) {
							return `"${value}"`;
						} else {
							return value;
						}
					});
					csvContent += rowValues.join(',') + '\n';
				});

				const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

				const url = window.URL.createObjectURL(blob);
				var a = document.createElement('a');
				a.href = url;
				a.download = 'data.csv';
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);

			}



		} catch (error) {
			console.error("Error fetching data:", error);
		}

	}

	async fetchDataInChunks(endpoint, parameters, totalDataCountStore) {
		const startDate = moment(parameters.startDate, 'YYYY-MM-DD');
		const endDate = moment(parameters.endDate, 'YYYY-MM-DD');
		const daysDiff = endDate.diff(startDate, 'days');

		let allRecords = [];

		let currentStartDate = startDate.clone(); // Initialize current start date

		while (currentStartDate.isBefore(endDate)) { // Continue until current start date is before end date
			const chunkEndDate = currentStartDate.clone().add(15, 'days'); // Calculate chunk end date

			// Ensure chunk end date does not exceed the overall end date
			const chunkEndDateClamped = moment.min(chunkEndDate, endDate);

			const chunkParameters = {
				...parameters,
				startDate: currentStartDate.format('YYYY-MM-DD'),
				endDate: chunkEndDateClamped.format('YYYY-MM-DD')
			};

			try {
				const response = await redirectURL.post(endpoint, chunkParameters);
				const records = response.data;

				allRecords = allRecords.concat(records);
				var allSecondaryPaymentsData = []
				if (totalDataCountStore) {
					allRecords.forEach(data => {
						allSecondaryPaymentsData = allSecondaryPaymentsData.concat(data.records);
					});
					console.log(allSecondaryPaymentsData.length, 'allRecords.records.length', totalDataCountStore)
					var percentageCalculation = ((allSecondaryPaymentsData.length) / totalDataCountStore) * 100;
					console.log(percentageCalculation, 'percentageCalculation')
					this.setState({
						percentageCalculation: percentageCalculation
					})
				}


				// Update current start date for the next iteration
				currentStartDate = chunkEndDateClamped.clone().add(1, 'day');
			} catch (error) {
				console.error("Error fetching data:", error);
				break; // Exit loop if an error occurs
			}
		}

		return allRecords;
	}

	onClickFilterResetButton() {
		var transportercode = "";
		this.setState({
			startDate: this.state.defaultsdate1,
			endDate: this.state.defaultedate1
		})
		$("#inputTruckConsignment").val("");
		$("#selectedFilter").val("");
		// console.log("localStorage.getItem('transportercode') ", localStorage.getItem('transportercode'))
		this.setState({
			loadshow: 'show-m',
			overly: 'show-m'
		})
		var parameters = {
			pagetype: "all",
			startDate: this.state.defaultsdate1,
			endDate: this.state.defaultedate1,
			initial: 1
		}
		this.setState({
			primarytransporter: { "value": "all", "label": "All" },
			secondtransporter: { "value": "all", "label": "All" }
		})
		// console.log("requestparams ", parameters)
		this.onLoadDashboardData(parameters)
	}

	onClickUpdateData() {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedUpdateNow,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			//  loadshow:'show-m'
		});
		var rowCount = this.gridApi.getSelectedNodes();
		//console.log("rowCount ", rowCount)
		var updateitems = []
		if (rowCount.length > 0) {
			rowCount.map((item) => {
				updateitems.push(item.data)
			})
		}
		//console.log("updateitems ", updateitems)
		if (updateitems.length > 0) {
			// var paramtrs = {
			//     updatedata : updateitems,

			// }
			redirectURL.post("/consignments/updateSecondaryPaymentData", updateitems)
				.then((response) => {
					//console.log(response.data)
					if (response.data.status == 'success') {
						this.setState({
							show: true,
							basicTitle: "Successfully updated",
							basicType: "success"
						});
						this.gridApi.deselectAll();
					}
				})
		}
		else {
			this.setState({
				show: true,
				basicTitle: "No Item selected",
				basicType: "info"
			})
		}
		//redirect
	}
	onRowSelected(event) {

	}
	onSelectPrimaryOptions = primarytransporter => {
		this.setState({
			primarytransporter
		});

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	onSelectSecondOptions = secondtransporter => {
		this.setState({
			secondtransporter
		});

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}

	getTruckConsignments = (event) => {
		event.preventDefault();
		var selectedFilter = $("#selectedFilter").text();
		var inputTruckConsignment = $("#inputTruckConsignment").val();
		if (selectedFilter == "Select..." || inputTruckConsignment == "") {
			this.setState({
				basicTitle: "Fields Should not be Empty",
				basicType: "danger",
				show: true
			})
		}
		else {
			this.setState({
				loadshow: 'show-m',
				overlay: 'show-m',
			});
			var createdate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
			//console.log("createdate ",createdate);
			var s1 = moment.parseZone(createdate).format("x")
			//console.log("s1 ",s1)

			// console.log(selectedFilter,inputTruckConsignment);
			var parametes = {
				"selectedFilter": selectedFilter,
				"inputTruckConsignment": inputTruckConsignment,
			}
			redirectURL.post("/consignments/searchpaymentinfodata", parametes)
				.then((response) => {
					var records = response.data.records;
					var recordsarr = []
					var zerofifteen = []
					var fifteenthirty = []
					var thirtyonefortyfive = []
					var morefortyfive = []
					if (records.length > 0) {
						records.map((item) => {

							var outstand_delay_days = ""
							if (item.prim_outstand_delay_days === undefined) {
								outstand_delay_days = ""
							}
							else {
								outstand_delay_days = item.prim_outstand_delay_days
							}
							var status = ""
							if (item.prim_status === undefined) {
								status = "Pending"
							}
							else {
								status = item.prim_status
							}
							var payment_date = ""
							if (item.prim_payment_date === undefined) {
								payment_date = ""
							}
							else {
								payment_date = item.prim_payment_date
							}

							var secstatus = ""
							if (item.sec_status === undefined) {
								secstatus = "Pending"
							}
							else {
								secstatus = item.sec_status
							}
							var sec_payment_date = ""
							if (item.sec_payment_date === undefined) {
								sec_payment_date = ""
							}
							else {
								sec_payment_date = item.sec_payment_date
							}
							var inv = moment.parseZone(item.invoice_date).format("YYYY-MM-DD HH:mm:ss");
							//console.log("inv ", inv);
							var s2 = moment.parseZone(inv).format("x")
							//console.log(s2);
							var diffreetime = moment.duration(moment(createdate).diff(inv));
							var delaydays = Math.round(diffreetime.asDays());

							if ((delaydays < 0 || delaydays >= 0) && delaydays <= 15) {
								zerofifteen.push(item)
							}
							if (delaydays >= 16 && delaydays <= 30) {
								fifteenthirty.push(item)
							}
							if (delaydays >= 31 && delaydays <= 45) {
								thirtyonefortyfive.push(item)
							}
							if (delaydays > 45) {
								morefortyfive.push(item)
							}
							recordsarr.push({
								"_id": item._id,
								"consignment_code": item.consignment_code,
								"load_no": item.load_no,
								"vin": item.vin,
								"invoice_date": item.invoice_date,
								"pod_date": item.pod_date,
								"imm_for_code": item.imm_for_code,
								"IMM_CITY": item.IMM_CITY,
								"for_code": item.for_code,
								"for_city": item.for_city,
								"prim_transporter_code": item.prim_transporter_code,
								"prim_transporter_name": item.prim_transporter_name,
								"truck_no": item.truck_no,
								"truck_type": item.truck_type,
								"str": item.yr_mfg,
								"N": item.new_or_old,
								"bmodel": item.bmodel,
								"quantity": item.quantity,
								"car_equi": item.car_equi,
								"tot_freight": item.tot_freight,
								"tot_dmg_amt": item.tot_dmg_amt,
								"tot_sort_amt": item.tot_sort_amt,
								"tot_penalty": item.tot_penalty,
								"tot_delay_amt": item.tot_delay_amt,
								"net_tot_fret_amt": item.net_tot_fret_amt,
								"prim_fret_amt": item.prim_fret_amt,
								"prim_dmg_amt": item.prim_dmg_amt,
								"prim_sort_amt": item.prim_sort_amt,
								"prim_penalty_amt": item.prim_penalty_amt,
								"prim_delay_amt": item.prim_delay_amt,
								"net_prim_fret_amt": item.net_prim_fret_amt,
								"tvp_stock_charges": item.tvp_stock_charges,
								"sec_load_no": item.sec_load_no,
								"sec_truck_no": item.sec_truck_no,
								"sec_transporter_name": item.sec_transporter_name,
								"sec_fret_amt": item.sec_fret_amt,
								"sec_dmg_amt": item.sec_dmg_amt,
								"sec_sort_amt": item.sec_sort_amt,
								"sec_panalty_amt": item.sec_panalty_amt,
								"sec_delay_amt": item.sec_delay_amt,
								"net_sec_fret_amt": item.net_sec_fret_amt,
								"sec_load_date": item.sec_load_date,
								"sec_transporter_code": item.sec_transporter_code,
								"prim_outstand_delay_days": outstand_delay_days,
								"prim_status": status,
								"prim_payment_date": payment_date,
								"sec_status": secstatus,
								"sec_payment_date": sec_payment_date,
								"prim_uploadfiles": item.prim_uploadfiles,
								"sec_uploadfiles": item.sec_uploadfiles,
								"prim_upload_date": item.prim_upload_date,
								"prim_bill_date": item.prim_bill_date,
								"sec_upload_date": item.sec_upload_date,
								"sec_bill_date": item.sec_bill_date,
								"sec_remarks": item.sec_remarks,
								"prim_remarks": item.prim_remarks,
								"stockyard_upload_date": item.stockyard_upload_date,
								"tvp_code": item.tvp_code,
							})
						})
					}
					//	console.log("primarytrans ", primarytrans)

					$("#selectedPlantFilter").val("all");
					this.setState({
						allData: recordsarr,
						rowData: recordsarr,
						loadshow: 'show-n',
						overly: 'show-n',
						zerofifteen: zerofifteen,
						fifteenthirty: fifteenthirty,
						thirtyonefortyfive: thirtyonefortyfive,
						morefortyfive: morefortyfive,
						loadshow: 'show-n',
						overlay: 'show-n',
					})
				}).catch = ((e) => {
					console.log(e);
				})
		}

	}

	plantChangeFilter() {
		var selectedPlant = $("#selectedPlantFilter").val();
		if (selectedPlant != "all" && selectedPlant != "") {
			//console.log(selectedPlant,"selectedPlant")
			this.setState({
				loadshow: 'show-m',
				overly: 'show-m',
			})
			var allData = this.state.allData;
			//console.log(allData,"allData")
			var rowFilterData = allData.filter(function (obj) {
				return (obj.tvp_code === selectedPlant);
			});
			this.setState({
				rowData: rowFilterData,
				loadshow: 'show-n',
				overly: 'show-n',
			});
		}
		else {
			this.setState({
				loadshow: 'show-m',
				overly: 'show-m',
			})
			var allData = this.state.allData;
			this.setState({
				rowData: allData,
				loadshow: 'show-n',
				overly: 'show-n',
			});
		}
	}

	render() {
		const { loading, percentageCalculation, activeExportState } = this.state;
		//console.log("Rendere ",this.state.deptcode)
		const columnwithDefs = [

			{
				headerName: "TRIP",
				field: "consignment_code",
				width: 120,
				editable: false
			},
			{
				headerName: "LOAD_NO",
				field: "load_no",
				width: 120,
				editable: false
			},
			{
				headerName: "VIN",
				field: "vin",
				width: 150,
				editable: false
			},
			{
				headerName: "INV_DATE",
				field: "invoice_date",
				width: 160,
				editable: false,
				valueGetter: function (params) {
					try {
						if (params.data.invoice_date != "" && params.data.invoice_date != undefined) {
							return getHyphenDDMMMYYYY(params.data.invoice_date);
						}
					}
					catch (e) {
						return "";
					}
				}
			},
			{
				headerName: "POD_DATE",
				field: "pod_date",
				width: 160,
				editable: false,
				valueGetter: function (params) {
					try {
						if (params.data.pod_date != "" && params.data.pod_date != undefined) {
							return getHyphenDDMMMYYYY(params.data.pod_date);
						}
					}
					catch (e) {
						return "";
					}
				}
			},

			{
				headerName: "IMM",
				field: "imm_for_code",
				width: 120,
				editable: false
			},
			{
				headerName: "IMM_CITY",
				field: "for_city",
				width: 120,
				editable: false
			},

			{
				headerName: "FOR",
				field: "for_code",
				width: 120,
				editable: false
			},

			{
				headerName: "FOR_CITY",
				field: "for_city",
				width: 120,
				editable: false
			},
			{
				headerName: "TRTR",
				field: "prim_transporter_code",
				width: 100,
				editable: false
			},
			{
				headerName: "TRANS_NAME",
				field: "prim_transporter_name",
				width: 200,
				rowGroup: true,
				editable: false
			},
			{
				headerName: "REGN_NO",
				field: "truck_no",
				width: 120,
				editable: false
			},
			{
				headerName: "T_MODE",
				field: "truck_type",
				width: 120,
				editable: false
			},
			{
				headerName: "YR_MFG",
				field: "str",
				width: 120,
				editable: false
			},
			{
				headerName: "N",
				field: "N",
				width: 120,
				editable: false
			},

			{
				headerName: "BMODE",
				field: "bmodel",
				width: 120,
				editable: false
			},

			{
				headerName: "QTY",
				field: "quantity",
				width: 120,
				editable: false
			},
			{
				headerName: "CAR_EQUI",
				field: "car_equi",
				width: 120,
				editable: false
			},
			{
				headerName: "TOTAL FREIGHT",
				headerClass: ["cellstylegridY", "cellTopHeader"],
				children: [
					{
						headerName: "TOT_FREIGHT",
						headerClass: "cellstylegridY",
						field: "tot_freight",
						width: 120,
						editable: false,
						cellClass: "cellstylegridY"
					},
					{
						headerName: "TOT_DMG_AMT",
						headerClass: "cellstylegridY",
						field: "tot_dmg_amt",
						width: 120,
						editable: false,
						cellClass: "cellstylegridY"
					},
					{
						headerName: "TOT_SORT_AMT",
						headerClass: "cellstylegridY",
						field: "tot_sort_amt",
						width: 120,
						editable: false,
						cellClass: "cellstylegridY"
					},
					{
						headerName: "TOT_PENALTY",
						headerClass: "cellstylegridY",
						field: "tot_penalty",
						width: 120,
						editable: false,
						cellClass: "cellstylegridY"
					},
					{
						headerName: "TOT_DELAY_AMT",
						headerClass: "cellstylegridY",
						field: "tot_delay_amt",
						width: 120,
						editable: false,
						cellClass: "cellstylegridY"
					},
					{
						headerName: "NET_TOT_FRET_AMT",
						headerClass: "cellstylegridY",
						field: "net_tot_fret_amt",
						width: 120,
						editable: false,
						cellClass: "cellstylegridY"
					},
				]
			},
			{
				headerName: "PRIMARY",
				headerClass: ["cellstylegrid", "cellTopHeader"],
				children: [
					{
						headerName: "PRIM_FRET_AMT",
						headerClass: "cellstylegrid",
						field: "prim_fret_amt",
						width: 120,
						editable: false,
						cellClass: "cellstylegrid"
					},
					{
						headerName: "PRIM_DMG_AMT",
						headerClass: "cellstylegrid",
						field: "prim_dmg_amt",
						width: 120,
						editable: false,
						cellClass: "cellstylegrid"
					},
					{
						headerName: "PRIM_SORT_AMT",
						headerClass: "cellstylegrid",
						field: "prim_sort_amt",
						width: 120,
						editable: false,
						cellClass: "cellstylegrid"
					},
					{
						headerName: "PRIN_PENALTY_AMT",
						headerClass: "cellstylegrid",
						field: "prim_penalty_amt",
						width: 120,
						editable: false,
						cellClass: "cellstylegrid"
					},
					{
						headerName: "PRIM_DELAY_AMT",
						headerClass: "cellstylegrid",
						field: "prim_delay_amt",
						width: 120,
						editable: false,
						cellClass: "cellstylegrid"
					},
					{
						headerName: "NET_PRIM_FRET_AMT",
						headerClass: "cellstylegrid",
						field: "net_prim_fret_amt",
						width: 120,
						editable: false,
						cellClass: "cellstylegrid"
					},
				]
			},

			{
				headerName: "TVP_STOCK_CHARGES",
				field: "tvp_stock_charges",
				width: 120,
				editable: false
			},
			{
				headerName: "SECONDARY",
				headerClass: ["cellstylegridB", "cellTopHeader"],
				children: [
					{
						headerName: "SEC_LOAD_NO",
						headerClass: "cellstylegridB",
						field: "sec_load_no",
						width: 120,
						editable: false,
						cellClass: "cellstylegridB"
					},
					{
						headerName: "SEC_LOAD_DATE",
						headerClass: "cellstylegridB",
						field: "sec_load_date",
						width: 120,
						editable: false,
						valueGetter: function (params) {
							try {
								if (params.data.sec_load_date != "" && params.data.sec_load_date != undefined) {
									return getHyphenDDMMMYYYY(params.data.sec_load_date);
								}
							}
							catch (e) {
								return "";
							}
						},
						cellClass: "cellstylegridB"
					},
					{
						headerName: "SEC_TRUCK_NO",
						headerClass: "cellstylegridB",
						field: "sec_truck_no",
						width: 120,
						editable: false,
						cellClass: "cellstylegridB"
					},
					{
						headerName: "SEC_LSP_CODE",
						headerClass: "cellstylegridB",
						field: "sec_transporter_code",
						width: 120,
						editable: false,
						cellClass: "cellstylegridB"
					},
					{
						headerName: "SEC_TRANSPORTER_NAME",
						headerClass: "cellstylegridB",
						field: "sec_transporter_name",
						width: 180,
						rowGroup: true,
						editable: false,
						cellClass: "cellstylegridB"
					},
					{
						headerName: "SEC_FRET_AMT",
						headerClass: "cellstylegridB",
						field: "sec_fret_amt",
						width: 120,
						editable: false,
						cellClass: "cellstylegridB"
					},
					{
						headerName: "SEC_DMG_AMT",
						headerClass: "cellstylegridB",
						field: "sec_dmg_amt",
						width: 120,
						editable: false,
						cellClass: "cellstylegridB"
					},
					{
						headerName: "SEC_SORT_AMT",
						headerClass: "cellstylegridB",
						field: "sec_sort_amt",
						width: 120,
						editable: false,
						cellClass: "cellstylegridB"
					},
					{
						headerName: "SEC_PENALTY_AMT",
						headerClass: "cellstylegridB",
						field: "sec_panalty_amt",
						width: 120,
						editable: false,
						cellClass: "cellstylegridB"
					},
					{
						headerName: "SEC_DELAY_AMT",
						headerClass: "cellstylegridB",
						field: "sec_delay_amt",
						width: 120,
						editable: false,
						cellClass: "cellstylegridB"
					},
					{
						headerName: "NET_SEC_FRET_AMT",
						headerClass: "cellstylegridB",
						field: "net_sec_fret_amt",
						width: 120,
						editable: false,
						cellClass: "cellstylegridB"
					},

					{
						headerName: "Outstanding delay days",
						field: "prim_outstand_delay_days",
						width: 120,
						editable: false,
						valueGetter: function (params) {
							try {
								var currentdate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
								var inv = moment.parseZone(params.data.sec_upload_date).format("YYYY-MM-DD HH:mm:ss");

								var diffreetime = moment.duration(moment(currentdate).diff(inv));
								var delaydays = Math.round(diffreetime.asDays());
								return delaydays;
							}
							catch (e) {
								return "";
							}

						}
					},
					{
						headerName: "Secondary Status",
						field: "sec_status",
						width: 180,
						editable: false
					},
				]
			},
			{
				headerName: "STOCKYARD",
				headerClass: ["cellstylegridB", "cellTopHeader"],
				children: [
					{
						headerName: "Outstanding delay days",
						field: "stockyard_outstand_delay_days",
						width: 120,
						editable: false,
						valueGetter: function (params) {
							try {
								var currentdate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
								var inv = moment.parseZone(params.data.stockyard_upload_date).format("YYYY-MM-DD HH:mm:ss");

								var diffreetime = moment.duration(moment(currentdate).diff(inv));
								var delaydays = Math.round(diffreetime.asDays());
								return delaydays;
							}
							catch (e) {
								return "";
							}

						}
					},
					{
						headerName: "Stockyard Status",
						field: "stockyard_status",
						width: 180,
						editable: false
					},
				]
			}
		];
		var optoins = [
			{
				value: "all",
				label: "All"
			},
			{
				value: "primary",
				label: "Primary"
			},
			{
				value: "secondary",
				label: "Secondary"
			}
		]
		return (
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				{this.state.alert}
				<div className="row">
					<div className="col-xl-5 col-lg-5">
						<div className=" card">
							<div className="row card-body" style={{ padding: "10px 30px" }}>
								<form className="row" onSubmit={this.getTruckConsignments.bind(this)}>
									<div className="form-group col-xl-6 col-lg-6">
										<label>Select Type  </label>
										<Select
											className="border-radius-0"
											isMulti={false}
											id="selectedFilter"
											style={{ borderRadius: "0px" }}
											options={[{
												label: "REGN_NO",
												vaue: "REGN_NO"
											},
											{
												label: "TRIP",
												value: "TRIP"
											},
											{
												label: "LOAD_NO", value: "LOAD_NO"
											}]} required />
									</div>
									<div className={"col-xl-6 col-lg-6 form-group"}>
										<label className="reason">REGN NO / TRIP / Load No</label>
										<input type="text" name="reason" autoComplete="off" class="form-control" id="inputTruckConsignment" />
									</div>
									<div className="form-group col-xl-12 col-lg-12" style={{ textAlign: "left" }}>
										<button type="submit" className="btn btn-success">Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div className="row col-xl-7 col-lg-7">
						<div className=" card">
							<div className="row card-body" style={{ padding: "10px 30px" }}>
								<div className="col-xl-3 col-lg-3 form-group">
									<label>Primary Transporter</label>
									<Select
										placeholder={"All"}
										value={this.state.primarytransporter}
										className="border-radius-0"
										onChange={this.onSelectPrimaryOptions.bind(this)}
										style={{ borderRadius: "0px" }}
										options={this.state.primarytrans}
										required
									/>
								</div>
								<div className="col-xl-3 col-lg-3 form-group">
									<label>Secondary Transporter</label>
									<Select
										placeholder={"All"}
										value={this.state.secondtransporter}
										className="border-radius-0"
										onChange={this.onSelectSecondOptions.bind(this)}
										style={{ borderRadius: "0px" }}
										options={this.state.secondtrans}
										required
									/>
								</div>
								<div className="col-xl-3 col-lg-3 form-group">
									<label>From Date</label>
									<Datetime value={this.state.startDate}
										disableCloseOnClickOutside={false}
										closeOnSelect={true}
										inputProps={{ placeholder: 'From Date', name: 'startDate', autoComplete: 'off', readOnly: true }}
										dateFormat="YYYY-MM-DD"
										name="startDate"
										onChange={this.handlerStartDateTime.bind(this)}
										isValidDate={current => {
											return current.isSameOrBefore(moment(), 'day');
										}} />

								</div>

								<div className="col-xl-3 col-lg-3 form-group">
									<label>To Date</label>
									{/* <input type="text" placeholder="To Date" autoComplete="off" id="to_date" value={this.state.endDate}  className="datetimepicker_date form-control"  required /> */}
									<Datetime value={this.state.endDate}
										disableCloseOnClickOutside={false}
										closeOnSelect={true}
										inputProps={{ placeholder: 'To Date', name: 'endDate', autoComplete: 'off', readOnly: true }}
										dateFormat="YYYY-MM-DD"
										onChange={this.handlerEndDateTime.bind(this)}
										isValidDate={current => {
											return current.isSameOrBefore(moment(), 'day');
										}} />

								</div>
								<div className="form-group col-xl-12 col-lg-12" style={{ textAlign: "left", display: 'flex' }}>
									<div>
										<button type="button" style={{ margin: "0 auto", padding: "6px 8px" }} className="btn btn-success" onClick={this.onClickFilterButton.bind(this)} disabled={activeExportState}>Submit</button>
										&nbsp;&nbsp;&nbsp;<button type="button" style={{ margin: "0 auto", padding: "6px 8px" }} className="btn btn-info" onClick={this.onClickFilterResetButton.bind(this)} disabled={activeExportState}>Reset</button>
										&nbsp;&nbsp;&nbsp;
										<button type="button" style={{ margin: "0 auto", padding: "6px 8px" }} className="btn btn-info" onClick={this.onClickFilterButtonToExport.bind(this)} disabled={activeExportState}>Download Data</button>
									</div>
									<div style={{ marginLeft: '50px' }}>{
										activeExportState && loading && (
											<Box ml={1} position="relative" display="inline-flex">
												<CircularProgress variant="determinate" value={percentageCalculation} style={{ width: 50, height: 50 }} />
												<Box
													top={0}
													left={0}
													bottom={0}
													right={0}
													position="absolute"
													display="flex"
													alignItems="center"
													justifyContent="center"
													fontSize={20}
													fontWeight="bold"
												>
													<Typography
														variant="subtitle2"
														component="div"
														color="textSecondary"
													>
														{`${Math.round(percentageCalculation)}%`}
													</Typography>
												</Box>
											</Box>
										)}</div>
								</div>
							</div>
						</div>

					</div>
					<div className="col-xl-12 col-lg-12">
						<PrimaryCounters
							zerofifteen={this.state.zerofifteen.length}
							fifteenthirty={this.state.fifteenthirty.length}
							thirtyonefortyfive={this.state.thirtyonefortyfive.length}
							morefortyfive={this.state.morefortyfive.length}
							stockzerofifteen={parseInt(this.state.stockzerofifteen.length)}
							stockfifteenthirty={parseInt(this.state.stockfifteenthirty.length)}
							stockthirtyonefortyfive={parseInt(this.state.stockthirtyonefortyfive.length)}
							stockmorefortyfive={parseInt(this.state.stockmorefortyfive.length)}
							zeroamt={parseInt(this.state.zeroamt) + parseInt(this.state.stockzeroamt)}
							fifteen={parseInt(this.state.fifteen) + parseInt(this.state.stockfifteen)}
							thirty={parseInt(this.state.thirty) + parseInt(this.state.stockthirty)}
							moreforty={parseInt(this.state.moreforty) + parseInt(this.state.stockmoreforty)}
						/>
					</div>
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h5>
									<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span> {this.state.pageTitle} </span>

									<span className="">
										<button className="float-right custom-btn f14 white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
											<i className="icofont icofont-save"></i> Save Grid Layout
										</button>
										<button className="float-right custom-btn f14 white btn-info" style={{ marginRight: "10px" }} onClick={this.resetState.bind(this)}>
											<i className="icofont icofont-refresh"></i> Reset Default Layout
										</button>
									</span>

								</h5>
							</div>
							<div className="card-body">

								<div className="row">

									<div className={"dataLoadpage " + (this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " + (this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
									<div className="col-xl-12 col-lg-12">
										<div className="form-group col-xl-6 col-lg-6">
											<label>Filter By Plant :  </label>
											<select style={{ marginLeft: "2%", padding: "1%" }} className="form-conrol" id="selectedPlantFilter" onChange={this.plantChangeFilter.bind(this)}>
												<option value="all">ALL</option>
												<option value="NGPR">TVPN</option>
												<option value="BNGR">TVPB</option>
												<option value="SLGR">TVPS</option>
											</select>
										</div>
									</div>

									<div className="col-xl-12 col-lg-12">
										<div id="myGrid" style={{ height: "500px", width: "100%" }} className="ag-theme-balham">
											<AgGridReact
												modules={this.state.modules}
												columnDefs={columnwithDefs}
												defaultColDef={this.state.defaultColDef}
												rowData={this.state.rowData}
												enableCharts={false}
												autoGroupColumnDef={this.state.autoGroupColumnDef}
												onGridReady={this.onGridReady}
												onGridState={this.onGridState}
												statusBar={this.state.statusBar}
												sideBar={this.state.sideBar}
												paginationPageSize={this.state.paginationPageSize}
												pagination={true}
												enableRangeSelection={true}
												components={this.state.components}
												rowSelection={this.state.rowSelection}
												suppressRowClickSelection={true}
												onRowSelected={this.onRowSelected.bind(this)}
												groupDefaultExpanded={this.state.groupDefaultExpanded}
											//floatingFilter={true}
											//onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
											//onCellClicked={this.popmarker}
											/>
										</div>
									</div>
									{/* <div className="col-xl-12 col-lx-12" style={{textAlign:"right"}}>
                                            <button name="submit" onClick={this.onClickUpdateData.bind(this)} className="btn btn-success">Update Now</button>
                                        </div> */}
								</div>
							</div>
						</div>
					</div>

				</div>

				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

				<div className={"slide-r " + (this.state.bulkslideExport)} style={{ overflow: "hidden" }}>
					<h3 className="subH">Export Data</h3>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.onClickSubmitDownloadData}>

								<div className="col-xl-12 col-lg-12 form-group">
									<label>From Date</label>
									<Datetime
										value={this.state.exportStart}
										disableCloseOnClickOutside={false}
										closeOnSelect={true}
										inputProps={{name: 'startDate', autoComplete: 'off', readOnly: true }}
										dateFormat="YYYY-MM-DD"
										timeFormat={null} // Ensure timeFormat is set to null to prevent displaying time
										name="startDate"
										onChange={this.handlerStartDateTimeExport.bind(this)}
										isValidDate={current => {
											return current.isSameOrBefore(moment(), 'day');
										}}
									/>

								</div>

								<div className="col-xl-12 col-lg-12 form-group">
									<label>To Date</label>
									<Datetime
										value={this.state.exportEnd}
										disableCloseOnClickOutside={false}
										closeOnSelect={true}
										inputProps={{name: 'endDate', autoComplete: 'off', readOnly: true }}
										dateFormat="YYYY-MM-DD"
										timeFormat={null}
										onChange={this.handlerEndDateTimeExport.bind(this)}
										isValidDate={current => {
											return current.isSameOrBefore(moment(), 'day');
										}} />
								</div>

								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

		);
	}
}

function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key))
			return false;
	}
	return true;
}
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num - (rhours * 3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}

function loadScript(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}
function dateScript(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}

function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1 / 180;
		var radlat2 = Math.PI * lat2 / 180;
		var theta = lon1 - lon2;
		var radtheta = Math.PI * theta / 180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180 / Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit == "K") { dist = dist * 1.609344 }
		if (unit == "N") { dist = dist * 0.8684 }
		return dist;
	}
}
function getDatePicker() {
	function Datepicker() { }
	Datepicker.prototype.init = function (params) {
		this.eInput = document.createElement("input");
		//this.eInput = this.eInput.setAttribute("class","datepicker");
		this.eInput.value = params.value;
		//   console.log(this.eInput)
		window.$(".datepicker").datepicker({ dateFormat: "dd/mm/yy" });
	};
	Datepicker.prototype.getGui = function () {
		return this.eInput;
	};
	Datepicker.prototype.afterGuiAttached = function () {
		this.eInput.focus();
		this.eInput.select();
	};
	Datepicker.prototype.getValue = function () {
		return this.eInput.value;
	};
	Datepicker.prototype.destroy = function () { };
	Datepicker.prototype.isPopup = function () {
		return false;
	};
	return Datepicker;
}



function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
	// create the cell
	this.eInput = document.createElement('input');
	this.eInput.value = params.value;

	// https://jqueryui.com/datepicker/
	$(this.eInput).datepicker({
		dateFormat: "yy-mm-dd",
		changeMonth: true,
		changeYear: true
	});
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
	return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
	this.eInput.focus();
	this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
	return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
	// but this example is simple, no cleanup, we could
	// even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
	// and we could leave this method out also, false is the default
	return false;
};
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
		date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
		var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
		date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
		// console.log(date1,date2);
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		// console.log(date1Number,date2Number);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		return date1Number - date2Number;
	}
}

function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName) {
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
	];
	var monthNum = months.indexOf(monthName) + 1;
	if (monthNum < 10) {
		monthNum = "0" + monthNum.toString();
	}
	return monthNum;
}
function dateComparator1(date1, date2) {
	//console.log(date1,date2);
	if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split("-")[1]);
		date1 = date1.replace(date1.split("-")[1], date_1);
		var date_2 = getMonthNumber(date2.split("-")[1]);
		date2 = date2.replace(date2.split("-")[1], date_2);
		var date1Number = monthToComparableNumber1(date1);
		var date2Number = monthToComparableNumber1(date2);
		// console.log(date1Number,date2Number);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		return date1Number - date2Number;
	}
}

function monthToComparableNumber1(date) {
	// console.log(date.length);
	// console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
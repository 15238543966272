import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import DeleteItem from './deleteItem';

var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class TnpOperationAssignment extends Component{
    constructor(props){
        super(props);
        this.state={
            show : false,
            rowSelection:'multiple',
            rowData: [],
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            basicTitle: "",
            deptCodeList: [],
            deptCodeValue: "",
            smgValue: "",
            interplantValue: "",
            fetchDeptCodeOptions: [],
            fetchInterplantOptions: [],
            fetchSmgOptions: [],
            overly:"show-n",
            loadshow:"show-n",
            bulkslide:"",
            frameworkComponents: {
				// updateItem:UpdateItem,
                deleteItem:DeleteItem
				//statusChange:StatusChange
			},
            paginationPageSize:50,
            defaultColDef: {
              sortable: true,
              filter: true,
              resizable: true,
              pivot : true,
              enableValue: true,
              enableRowGroup: true,
            },
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
            basicType:"default",
            fetchItemsOptions: [],
            fetchSourceOptions: [],
            fetchDestinationOptions: [],
            items: "",
            source: "",
            destination: "",

        }
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount(){
        // console.log("tnpoprtasiign",1)
        this.getGridData();
        this.getDropDowndDetails();  

    }
    componentWillReceiveProps(){
        // console.log("tnpoprtasiign",2)
        this.getDropDowndDetails(); 
    }
    
    getGridData = () => {        
        redirectURL.post("/consignments/getuseritemmapping",{}).then((response)=>{
            let rowData = response.data
            this.setState({
                rowData
            })           
        })
    }

    getDropDowndDetails =() =>{
        redirectURL.post("/consignments/getitemssourcedestination",{}).then((response)=>{
            let {destination, items, source} = response.data
            console.log("getdetails",destination, items, source )
            let itemsList = [];
            let sourceList = [];
            let destinationList = [];
            items.map((item)=>{
                itemsList.push({
                    label:item.toUpperCase(),
					value:item.toUpperCase(),
                })
            })
            let newArray = [];               
            // Declare an empty object 
            let totuniqueObject = {}; 
            var totobjTitle;
            // Loop for the array elements 
            for (let i in itemsList) { 
    
                // Extract the title 
                totobjTitle = itemsList[i]['value']; 
    
                // Use the title as the index 
                totuniqueObject[totobjTitle] = itemsList[i]; 
            } 
    
            // Loop to push unique object into array 
            for (var a in totuniqueObject) { 
                newArray.push(totuniqueObject[a]); 
            }
            console.log(newArray,"newArray")
            source.map((item)=>{
                sourceList.push({
                    label:item,
					value:item,
                })
            })
            destination.map((item)=>{
                destinationList.push({
                    label:item,
					value:item,
                })
            })
            this.setState({
                fetchItemsOptions:newArray,
                fetchSourceOptions: sourceList,
                fetchDestinationOptions: destinationList
            })
        })
    }
    changeHandler = (event ) => {
        // console.log("event", event.target.value)        
        this.setState({items: event.target.value});
	}

    changeSourceHandler = (event ) => {
        this.setState({source: event});
	}

    changeDestinationHandler = (event ) => {
        this.setState({destination: event});
	}

    formHandler = (event) => {
        const {items, source, destination} = this.state
        event.preventDefault();
        // var letterNumber = /^[0-9a-zA-Z]+$/
        var userName = localStorage.getItem("username")
        var reqparam = {
            operation_items : items,
            source : source.value,
            destination:destination.value,
            operation_name: items + " (Transfer from -->"+ source.value + " to " + destination.value+ ")",
            userName: userName
        }
        // console.log("reqparam", reqparam)
        var sourceVal = reqparam.source;
        var destinationVal = reqparam.destination;
        var itemsVal = reqparam.operation_items
        if (itemsVal !== undefined && itemsVal !== "" && sourceVal !== undefined && destinationVal !== undefined){
            if(sourceVal && destinationVal){
                redirectURL.post("/consignments/adduseritemmapping",reqparam).then((response)=>{
                    if (response.data.status === "Data Already Exists"){
                        this.setState({
                            basicType:"warning",
                            basicTitle : "Data Already Exists",
                            show: true
                        })
                    // this.getTptUserItems();
                    }else if (response.data.status === "success"){
                        this.setState({
                            basicType:"success",
                            basicTitle : "Successfully Added",
                            show : true,
                            deptCode: "",
                            items: "",
                            source: "",
                            destination: "",
                            overly:"show-n",
                            loadshow:"show-n",
                            bulkslide : "",
                        });
                        this.getGridData();
                    }else{
                        this.setState({
                            basicType:"danger",
                            basicTitle : "Failed to Add",
                            show : true,
                        })
                    }
                })
            }else{
                this.setState({
                    basicType:"warning",
                    basicTitle : "Please enter values",
                    show: true
                })               
            }        
        }else{
            this.setState({
                basicType:"warning",
                basicTitle : "fields should not be empty",
                show : true,                
            });
        }     
    }

    handleChange(event) {       
        // let name = event.currentTarget.name;
        // let value = event.currentTarget.value;
        // this.setState({[name]:value});
    }

    deleteRowHandler = (params) =>{
        let rowData = this.state.rowData
        const rowFiltered = rowData.filter(m => m._id !== params._id);
        this.setState({ rowData :  rowFiltered});
        // console.log("rowf", rowFiltered)
        let par = {
            _id: params._id
        }
        let cnf = window.confirm("Are you sure you want to Delete?")
        if(cnf === true){
            redirectURL.post("/consignments/deleteTnpOperationAssignment",par).then((response) =>{
                let data = response.data
                // console.log("dataresponse", data)
                if (data.status === "success"){
                    this.setState({
                        basicType:"success",
                        basicTitle : "Successfully Deleted",
                        show : true,                    
                        // overly:"show-n",
                        // loadshow:"show-n",
                    })
                }else{
                    this.setState({
                        show: true,
                        basicTitle:'Something went wrong',
                        basicType:"danger",
                    });
                }    
            })
        }

    }

    onCellUpdateData = (params) =>{
        console.log("editfield", params)
        if(params.colDef.field === 'items'){
            // var req = {
            //     rowId : params.data._id,
            //     items: params.data.items,
            //     source : params.value,
            //     userName : userName


            // }
            // if(req.items !== undefined && req.items !== ""){
            //     redirectURL.post("/consignments/settptuseritems", req)
			// 	.then((response) =>{
			// 		this.setState({
            //             basicType:"success",
            //             basicTitle : "Successfully Modified",
            //             show : true
            //         });
			//     })                
            // }
        }

    }

    changeScreen = () =>{
        this.setState({
            overly:"show-m",
            loadshow:"show-m",
            bulkslide : "slider-translate-30p",
        })
		// $("#tptUserItemGrid").css("display","none");
		// $(".user-item-card").css("display","flex");
		// $("#addUserItem").css("display","none");
		// $("#previous-screen").css("display","initial");		
	}

    previousscreen = () =>{
        this.setState({
            overly:"show-n",
            loadshow:"show-n",
            bulkslide : "",
        })

        // $("#tptUserItemGrid").css("display","flex");
		// $(".user-item-card").css("display","none");
		// $("#addUserItem").css("display","block");
        // $("#previous-screen").css("display","none");
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
	}

    onClickHideAll(){
        this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:"",        
        });        
    }

    onGridReady = params => {
		this.gridApi = params.api;		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;		   
	};
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);
    }
    onCellRowClicked =e =>{
        if(e.colDef.field === "update"){
            var rowId = e.data._id;
            var userName = localStorage.getItem("username")
            let items = e.data.operation_items;
            let source = e.data.source;
            let destination = e.data.destination;

            var reqparam = {
                rowId : rowId,
                operation_items:items ,
                source : source,
                destination: destination,
                operation_name: items + " (Transfer from -->"+ source + " to " + destination,
                userName: userName
            }

            if (items !== undefined && items !== "" && source !== undefined &&source !== "" && destination !== undefined && destination !== ""){
                if(items && source && destination){
                    redirectURL.post("/consignments/updateuseritemmapping",reqparam).then((response)=>{
                        if (response.data.status === "Data Already Exists"){
                            this.setState({
                                basicType:"warning",
                                basicTitle : "Data Already Exists",
                                show: true
                            })
                        // this.getTptUserItems();
                        }else if (response.data.status === "success"){
                            this.setState({
                                basicType:"success",
                                basicTitle : "Successfully Added",
                                show : true,                                
                                overly:"show-n",
                                loadshow:"show-n",
                                bulkslide : "",
                            });
                            // this.getTptUserItems();
                        }else{
                            this.setState({
                                basicType:"danger",
                                basicTitle : "Failed to Add",
                                show : true,
                            })
                        }
                    })
                }else{
                    this.setState({
                        basicType:"warning",
                        basicTitle : "Please enter values",
                        show: true
                    })               
                }        
            }else{
                this.setState({
                    basicType:"warning",
                    basicTitle : "fields should not be empty",
                    show : true,                
                });
            }
        }
    }


    render(){
        const columnwithDefs= [
            {
                headerName : "",
                field : "update",
                width:100,
                editable : false,
                hide: true,
                pinned:"left",
                cellRenderer: function(params){
                    return "<button class='btn btn-info' style='padding:0px;width:76px'>Update</button>"
                }
            },
            {
                headerName: "View",
                field: "_id",                
                filter: true,
                width:140,
                hide: true,               
            },
            {
                headerName: "Action",
                field: "_id",
                width: 100,
                filter: false,  
                cellRenderer:'deleteItem',
                editable:false
            },
              {
                headerName: "Operations Items",
                field: "operation_items",
                filter: true,
                editable: false,
                width:180,
                resizable: true,              
            },
            {
                headerName: "Source",
                field: "source",                
                filter: true,
                editable: false,
                width:180,
                resizable: true,               
            },
            {
                headerName: "Destination",
                field: "destination",                
                filter: true,
                editable: false,
                width:180,
                resizable: true,               
            },
            {
                headerName: "Operation Name",
                field: "operation_name",        
                filter: true,
                editable: false,
                width:400,
                resizable: true,               
            },
           
        ]
        return(
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                    >
	             </SweetAlert>
                 {this.state.alert}
                 <div className="row">
                 <div className=" col-xl-12 col-lg-12">
                     <div className="card">
                         <div className="card-header space-between">
                             <h5 className="float-left">
                                <i className="icon-map cus-i"></i><span>Operation Assignment</span>
                             </h5>
                             <div>
                             <button className="btn btn-success pull-right cs-btn" id="addUserItem" onClick={this.changeScreen.bind(this)} style={{color:"white"}}> <i className="icon-plus"></i> Add Operation</button>
                            <button className="btn btn-warning" id="previous-screen" onClick={this.previousscreen.bind(this)} style={{display:"none",padding: "9px",float: "right",width: "87px"}}><i className="fa fa-arrow-left"></i> Back</button>
                             </div>                                                         
                         </div>
                         <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
                            <h3 className="subH">Assign Operation </h3>
                            <div className="slide-r-body" style={{position:"relative"}}>             
                                <form className=" theme-form col-xl-8 col-lg-8" onSubmit={this.formHandler}>
	                    			<div className="col-xl-12 col-lg-12">
                                    <div className="form-group">
					               			<label className="c-lbl">Operation Items:</label>
                                            <input 
                                                type="text"
                                                name="items"                                                
                                                className="form-control"
                                                value={this.state.items}
                                                onChange={this.changeHandler}
                                                placeholder = "Enter Operation Items"
                                                required
                                            />				               			
					               			{/* <input                                               
                                               value={this.state.items} 
                                               onChange={this.changeHandler} 
                                               style={{borderRadius:"0px", width: "80%"}} 
                                            //    options={this.state.fetchItemsOptions}
                                               placeholder = "Enter Item"
                                               required /> */}
					               	   </div>
                                        <div className="form-group">
					               			<label className="c-lbl">Source:</label>					               			
					               			<Select 
                                               value={this.state.source} 
                                               onChange={this.changeSourceHandler} 
                                               style={{borderRadius:"0px"}} 
                                               options={this.state.fetchSourceOptions}
                                               placeholder="Select Source"
                                               required />
					               	   </div>
                                        <div className="form-group">
					               			<label className="c-lbl">Destination:</label>					               			
					               			<Select 
                                               value={this.state.destination} 
                                               onChange={this.changeDestinationHandler} 
                                               style={{borderRadius:"0px"}} 
                                               options={this.state.fetchDestinationOptions}
                                               placeholder="Select Destination"
                                               required />
					               	    </div>				
										<div className="form-group">
					               			<button type="submit" className="btn btn-success cs-btn" id="saveevent">Save</button>
					               		</div>
									</div>									
				            	</form>                      
                            </div>
                        </div>
                        <div id="tptUserItemGrid" className="card-body col-sm-11">                        
									<div id="myGrid" style={{ height: "580px",width: "100%",}}  className="ag-theme-balham">
										<AgGridReact
										modules={this.state.modules}
										columnDefs={columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										// onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
										enableCharts={true}
										enableRangeSelection={true}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										frameworkComponents={this.state.frameworkComponents}
										detailCellRendererParams={this.state.detailCellRendererParams}
										stopEditingWhenGridLosesFocus={true}
										enableCellChangeFlash={true}
										suppressCellFlash={true}
										rowClassRules={this.state.rowClassRules}
										//onCellClicked={this.onRowClicked.bind(this)}
										gridOptions={{
											context: { componentParent: this }
										}}
										masterDetail={true}
										//getRowHeight={this.state.getRowHeight}
										// onCellEditingStopped={this.onCellUpdateData.bind(this)}
										onCellClicked={this.onCellRowClicked}			
										rowSelection={this.state.rowSelection}
										// onRowSelected={this.onRowSelection.bind(this)}
										suppressRowClickSelection={true}
                                        components={this.state.components}
										// suppressCellSelection= {true}
										// overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}								
										/>
									</div>								
                        </div>
                     </div>
                 </div>
                 </div>
                 <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
            
        )
    }

}
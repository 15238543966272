
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';


import Counters from '../layouts/consignmentkpicountersComponent';

import CountUp from 'react-countup';

import { AgGridReact } from "@ag-grid-community/react";

import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";

import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from "react-select";

import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';

import Highcharts from 'highcharts';

var redirectURL = require("../redirectURL");
export default class TransitDamages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      damage_performance: 0,
      filterType: 'All',
      totalSumedUpValues: [],
      facility: "All Facilities",
      loadshow: 'show-m',
      overly: 'show-m',
      damage_count:0,
      currentMonth:'',
      facilitiesList: [{ "label": "All Facilities", "value": "All" },
      { "label": "Haryana", "value": "H" },
      { "label": "Bidadi", "value": "BP" }
        , { "label": "Gujarat", "value": "G" },
      { "label": "TVP-B", "value": "B" },
      { "label": "TVP-N", "value": "N" },
      { "label": "TVP-S", "value": "I" }]
    }

  }





  componentDidMount() {
    const { transporterCode } = this.props.match.params;
    console.log(transporterCode, 'transporterCode');
    var damage_performance = 0;
    var damage_count = 0;

    const now = new Date();
    const monthIndex = now.getMonth();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
   const currentMonth = `${monthNames[monthIndex]}-${now.getFullYear().toString().slice(-2)}`;
    console.log("Current month:", currentMonth);

    this.setState({
      loadshow: "show-m",
      overly: "show-m",
      currentMonth:currentMonth
    })
    redirectURL
      .post("/consignments/toplspperformance")
      .then((response) => {
        damage_performance = response.data[0].damage_performance || 0;
        damage_count = response.data[0].damage_count || 0;

        // })
        this.setState({
          transporter_code: transporterCode,
          damage_performance: damage_performance,
          damage_count:damage_count,
          loadshow: 'show-m',
          overly: 'show-m',
        });

        var params = {
          transporter_code: transporterCode
        };
        redirectURL
          .post("/consignments/transitDamageMountData", params)
          .then((response) => {
            console.log(response.data);


            // Filter data to include only entries where the month starts with "FY"
            // const filteredData = response.data.filter(item => item.month.startsWith('FY'));
            const filteredData = response.data
            .filter(item => item.month && item.month.startsWith('FY'))
            .sort((a, b) => {
              const yearA = extractYear(a.month);
              const yearB = extractYear(b.month);
              return yearA - yearB;
            });
            // Initialize an object to store aggregated data by month
            const aggregatedData = {};

            // Aggregate data by month
            filteredData.forEach(item => {
              const month = item.month;
              if (!aggregatedData[month]) {
                aggregatedData[month] = {
                  month: month,
                  damages: 0,
                  damage_percentage: 0,
                  pod_processed: 0,
                  count: 0
                };
              }
              aggregatedData[month].damages += item.parameters.damages || 0;
              aggregatedData[month].pod_processed += item.parameters.pod_processed || 0;
              aggregatedData[month].damage_percentage += item.parameters.damage_percentage || 0;
              aggregatedData[month].count += 1
            });
            console.log(aggregatedData, 'aggregatedData')
            // Convert the aggregated data object into an array
            const aggregatedDataArray = Object.entries(aggregatedData).map(([month, values]) => ({
              month,
              damages: values.damages,
              pod_processed: values.pod_processed,
              damage_percentage: parseFloat((values.damage_percentage / values.count).toFixed(2)),
              count: values.count
            }));
            console.log(aggregatedDataArray, 'aggregatedDataArray')
            // // Filter data to include only entries where the month starts with "FY"
            // const filteredData = response.data.filter(item => item.month.startsWith('FY'));
            // // console.log(filteredData, 'filteredData');

            // // Group data by the "month" field and sum up "damages" values for each FY year
            // const aggregatedData = filteredData.reduce((result, item) => {
            //   const year = item.month; // Assuming the "month" field contains the FY year
            //   if (!result[year]) {
            //     result[year] = {
            //       month: year,
            //       damages: 0,
            //       damage_percentage:0,
            //       pod_processed: 0,
            //       count:0
            //     };
            //   }
            //   // Check if damages is not null, then add it to the sum
            //   if (item.parameters && item.parameters.damages !== null && item.parameters.damage_percentage!==0) {
            //     result[year].damages += item.parameters.damages;
            //     result[year].pod_processed += item.parameters.pod_processed;
            //     result[year].damage_percentage+=item.parameters.damage_percentage
            //     result[year].count+=1
            //   }
            //   return result;
            // }, {});
            // // console.log(aggregatedData,"aggregated data")
            // // Convert the aggregated data object into an array
            // const aggregatedDataArray = Object.values(aggregatedData);

            // // Sort the data as needed
            // aggregatedDataArray.sort((a, b) => {
            //   // Your sorting logic here
            // });
            let sortedAndFilteredArray = aggregatedDataArray.slice(-2)
            console.log(sortedAndFilteredArray, 'sortedAndFilteredArray');
            sortedAndFilteredArray = sortedAndFilteredArray.map((e) => {  return ({
                month: e.month,
                damage_percentage: parseFloat((e.damage_percentage).toFixed(2)),
                damages: e.damages,
                pod_processed: e.pod_processed,
              })
            // }
            })

            // console.log(sortedAndFilteredArray,"sortedAndFilteredArray")
            // Initialize an object to store data for individual months within the current fiscal year
            const currentFiscalYearMonthsData = {};
            var count_damages = 0
            var count_pod_processed = 0
            // Iterate through the data to accumulate values for each month within the current fiscal year
            response.data.forEach((item) => {
              const month = item.month;
              // Check if the month is within the current fiscal year ("Apr-23" to "Mar-24")
              if (!month.startsWith('FY')) {
                if (!currentFiscalYearMonthsData[month]) {
                  currentFiscalYearMonthsData[month] = [];
                }
                // Add the damages value for this month
                if (item.parameters && item.parameters.damages !== null) {
                  currentFiscalYearMonthsData[month].push({
                    "damages": item.parameters.damages,
                    "pod_processed": item.parameters.pod_processed,
                    "damage_percentage": item.parameters.damage_percentage
                  })
                  // count_damages += parseInt(item.parameters.damages)
                  // count_pod_processed += parseInt(item.parameters.pod_processed)
                  // console.log(count_damages,"117-a",count_pod_processed,"117-b")
                }
              }
            });

            // Sort the data as needed
            console.log(currentFiscalYearMonthsData, '123')

            const monthsToExtract = DynamicSortOfMonths()


            const filteredDataArray = [];
            monthsToExtract.forEach(month => {
              if (currentFiscalYearMonthsData.hasOwnProperty(month)) {
                let damages = 0;
                let pod_processed = 0;
                let damage_percentage = 0;
                let count = 0
                currentFiscalYearMonthsData[month].map(e => {
                  if (e.pod_processed != 0) {
                    damages = damages + e.damages;
                    pod_processed = pod_processed + e.pod_processed;
                    damage_percentage += e.damage_percentage;
                    count += 1
                  }
                })
                console.log(parseFloat((damage_percentage / count).toFixed(2)), 'parseFloat((damage_percentage/count).toFixed(2))')
                // damage_percentage = (pod_processed === 0) ? 0 :( 100 - (((damages) / pod_processed) * 100));
                filteredDataArray.push({
                  month: month,
                  damage_percentage: parseFloat((damage_percentage / count).toFixed(2)),
                  damages: damages,
                  pod_processed: pod_processed
                });
              }
            });

            // Print the filtered data array or use it as needed
            console.log(filteredDataArray, sortedAndFilteredArray, '143');
            const joinedArray = sortedAndFilteredArray.concat(filteredDataArray);

            console.log(joinedArray, sortedAndFilteredArray, 'joinedArray')
            joinedArray.sort((a, b) => {
              // Map for months to convert them to numeric values
              const monthMap = {
                'Jan': 1, 'Feb': 2, 'Mar': 3, 'Apr': 4, 'May': 5, 'Jun': 6,
                'Jul': 7, 'Aug': 8, 'Sep': 9, 'Oct': 10, 'Nov': 11, 'Dec': 12
              };

              // Extracting year and month from the month property
              const extractDateParts = (monthYear) => {
                if (monthYear.startsWith("FY")) {
                  // For fiscal years, assume "FY 18-19" -> treat it as the first year "2018"
                  const fiscalYear = monthYear.split(' ')[1].split('-')[0];
                  return { month: 0, year: 2000 + parseInt(fiscalYear) };
                } else {
                  const parts = monthYear.split('-');
                  return { month: monthMap[parts[0]], year: parseInt(parts[1], 10) + 2000 };
                }
              };

              const aDate = extractDateParts(a.month);
              const bDate = extractDateParts(b.month);

              // Compare years first
              if (aDate.year !== bDate.year) {
                return aDate.year - bDate.year;
              }

              // If years are the same, compare months (note fiscal year months are 0)
              return aDate.month - bDate.month;
            });

            this.setState({
              totalSumedUpValues: joinedArray,
              loadshow: 'show-n',
              overly: 'show-n',
            });
          })
      })
      .catch((error) => {
        console.error(error);
      });

  }



  newFilter = (event) => {
    this.setState({
      filterType: event.value,
      loadshow: 'show-m',
      overly: 'show-m',
    })
    var params = {
      transporter_code: this.state.transporter_code,
      plant_code: event.value,
      type: 'TransitDamage'
    }
    console.log(params, 'params')
    redirectURL
      .post("/consignments/onselectfacilityfilter", params)
      .then((response) => {
        console.log(response.data);

        // Filter data to include only entries where the month starts with "FY"
        // const filteredData = response.data.filter(item => item.month.startsWith('FY'));
        const filteredData = response.data
            .filter(item => item.month && item.month.startsWith('FY'))
            .sort((a, b) => {
              const yearA = extractYear(a.month);
              const yearB = extractYear(b.month);
              return yearA - yearB;
            });
        // Initialize an object to store aggregated data by month
        const aggregatedData = {};

        // Aggregate data by month
        filteredData.forEach(item => {
          const month = item.month;
          if (!aggregatedData[month]) {
            aggregatedData[month] = {
              month: month,
              damages: 0,
              damage_percentage: 0,
              pod_processed: 0,
              count: 0
            };
          }
          aggregatedData[month].damages += item.parameters.damages || 0;
          aggregatedData[month].pod_processed += item.parameters.pod_processed || 0;
          aggregatedData[month].damage_percentage += item.parameters.damage_percentage || 0;
          aggregatedData[month].count += 1
        });
        console.log(aggregatedData, 'aggregatedData')
        // Convert the aggregated data object into an array
        const aggregatedDataArray = Object.entries(aggregatedData).map(([month, values]) => ({
          month,
          damages: values.damages,
          pod_processed: values.pod_processed,
          damage_percentage: parseFloat((values.damage_percentage / values.count).toFixed(2)),
          count: values.count
        }));
        console.log(aggregatedDataArray, 'aggregatedDataArray')
        // console.log(aggregatedData,"aggregated data")
        // Convert the aggregated data object into an array
        // const aggregatedDataArray = Object.values(aggregatedData);

        // // Sort the data as needed
        // aggregatedDataArray.sort((a, b) => {
        //   // Your sorting logic here
        // });
        let sortedAndFilteredArray = aggregatedDataArray.slice(-2)
        // console.log(sortedAndFilteredArray, 'sortedAndFilteredArray');
        sortedAndFilteredArray = sortedAndFilteredArray.map((e) => {
          // console.log("e.pod_processed",e.pod_processed)
          // if(e.pod_processed != 0 && e.pod_processed != NaN){
          return ({
            month: e.month,
            damage_percentage: parseFloat((e.damage_percentage).toFixed(2)),
            damages: e.damages,
            pod_processed: e.pod_processed,
          })
        // }
        })

        // console.log(sortedAndFilteredArray,"sortedAndFilteredArray")
        // Initialize an object to store data for individual months within the current fiscal year
        const currentFiscalYearMonthsData = {};
        var count_damages = 0
        var count_pod_processed = 0
        // Iterate through the data to accumulate values for each month within the current fiscal year
        response.data.forEach((item) => {
          const month = item.month;
          // Check if the month is within the current fiscal year ("Apr-23" to "Mar-24")
          if (!month.startsWith('FY')) {
            if (!currentFiscalYearMonthsData[month]) {
              currentFiscalYearMonthsData[month] = [];
            }
            // Add the damages value for this month
            if (item.parameters && item.parameters.damages !== null) {
              currentFiscalYearMonthsData[month].push({
                "damages": item.parameters.damages,
                "pod_processed": item.parameters.pod_processed,
                "damage_percentage": item.parameters.damage_percentage
              })
              // count_damages += parseInt(item.parameters.damages)
              // count_pod_processed += parseInt(item.parameters.pod_processed)
              // console.log(count_damages,"117-a",count_pod_processed,"117-b")
            }
          }
        });

        // Sort the data as needed
        // console.log(currentFiscalYearMonthsData, '123')

        const monthsToExtract = DynamicSortOfMonths()


        const filteredDataArray = [];
        monthsToExtract.forEach(month => {
          if (currentFiscalYearMonthsData.hasOwnProperty(month)) {
            let damages = 0;
            let pod_processed = 0;
            let damage_percentage = 0;
            let count = 0
            currentFiscalYearMonthsData[month].map(e => {
              if (e.pod_processed != 0 && e.pod_processed != NaN) {
                damages = damages + e.damages;
                pod_processed = pod_processed + e.pod_processed;
                damage_percentage += e.damage_percentage;
                count += 1
              }
            })

            // damage_percentage = (pod_processed === 0) ? 0 :( 100 - (((damages) / pod_processed) * 100));
            // console.log(pod_processed,'pod_processedpod_processed')
            if(pod_processed != 0 && pod_processed != NaN){
              filteredDataArray.push({
                month: month,
                damage_percentage: parseFloat(damage_percentage.toFixed(2)),
                damages: damages,
                pod_processed: pod_processed
              });
            }
          }
        });

        // Print the filtered data array or use it as needed
        // console.log(filteredDataArray, '143');
        const joinedArray = sortedAndFilteredArray.concat(filteredDataArray);
        joinedArray.sort((a, b) => {
          // Map for months to convert them to numeric values
          const monthMap = {
            'Jan': 1, 'Feb': 2, 'Mar': 3, 'Apr': 4, 'May': 5, 'Jun': 6,
            'Jul': 7, 'Aug': 8, 'Sep': 9, 'Oct': 10, 'Nov': 11, 'Dec': 12
          };

          // Extracting year and month from the month property
          const extractDateParts = (monthYear) => {
            if (monthYear.startsWith("FY")) {
              // For fiscal years, assume "FY 18-19" -> treat it as the first year "2018"
              const fiscalYear = monthYear.split(' ')[1].split('-')[0];
              return { month: 0, year: 2000 + parseInt(fiscalYear) };
            } else {
              const parts = monthYear.split('-');
              return { month: monthMap[parts[0]], year: parseInt(parts[1], 10) + 2000 };
            }
          };

          const aDate = extractDateParts(a.month);
          const bDate = extractDateParts(b.month);

          // Compare years first
          if (aDate.year !== bDate.year) {
            return aDate.year - bDate.year;
          }

          // If years are the same, compare months (note fiscal year months are 0)
          return aDate.month - bDate.month;
        });

        this.setState({
          totalSumedUpValues: joinedArray,
          loadshow: 'show-n',
          overly: 'show-n',
        });
      })
      .catch((error) => {
        console.error(error);
      });

  }

  onClickCounter(data) {
    //this.props.context.onClickCounterShowData(data)

  }

  handleDrilldown = (params) => {
    console.log("Here ,", params)
  }
  onClickHideAll() {
    this.setState({
      loadshow: 'show-n',
      overly: 'show-n',
      bulkslide: "",
      bulkslidebulk: '',
    });

  }

  render() {
    const dStyles = {
      width: '100%',
      height: '100%'
    };

    const damagePerformance = this.state.damage_performance;

    console.log(this.state.totalSumedUpValues, "310");
    const lastElement = this.state.totalSumedUpValues[this.state.totalSumedUpValues.length - 1];
    // console.log(lastElement,'lastElement',this.state.currentMonth,'---')
    if (lastElement && lastElement.month === this.state.currentMonth) {   
       var totalSumedUpValues = this.state.totalSumedUpValues.slice(0, -1)
    }else{
      var totalSumedUpValues = this.state.totalSumedUpValues
    }
    const transitPercentageData = totalSumedUpValues.map((ele) => {
      if (ele.month == "FY 18-19" || ele.month == "FY 19-20" || ele.month == "FY 20-21" || ele.month == "FY 21-22") {
        return ({ "percentage": ele.damage_percentage });
      } else {
        var percentage = ((ele.damages / ele.pod_processed) * 100);
        return ({ "percentage": percentage, "damages": ele.damages, "pod_processed": ele.pod_processed });
      }
    });

    const monthCategories = this.state.totalSumedUpValues.map((ele) =>(ele && ele.month));
  
  
    var highchartoptions = {
      chart: {
        type: 'column',
        events: {
          load: function () {
            // Calculate the x position relative to the chart width
            var xPosition = this.chartWidth - 300; // Adjust the 300 based on your text length and desired margin


            this.renderer.html(
              '<div style="text-align: right;">' +
              // 'Best Performing LSP Damage %= 0<br>' +
              '<span style="margin-left: 150px;">Target: 0%</span>' +
              '</div>',
              xPosition,
              25 // You might need to adjust this as well for vertical positioning
            ).attr({
              zIndex: 5
            }).add().css({
              fontSize: 'large'
            }).align({
              align: 'right', // Right align the text
              verticalAlign: 'top', // Align it to the top
              x: -350, // Adjust this value to move text left(-) or right(+)
              y: 25 // Adjust this value to move text up(-) or down(+)
            });
          }
        }
      },
      title: {
        text: 'Damage %'
      },
      xAxis: {
        categories: monthCategories,
        crosshair: true
      },
      yAxis: {
        title: {
          text: 'Damage (%)'
        },
        plotLines: [{
          color: '#2E75B6',
          value: 0,
          width: 2,
          zIndex: 5,
          label: {
            // text: 'Target: 0%',
            // align: 'right',
            style: {
              color: 'gray',
              fontSize: 'bold'
            }
          }
        }]
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.2f}% </b></td></tr>' +
          '<tr><td style="padding:0">Damaged VINs : </td>' +
          '<td style="padding:0"><b>{point.damages}</b></td></tr>' +
          '<tr><td style="padding:0">Total VINs : </td>' +
          '<td style="padding:0"><b>{point.pod_processed}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
         className: 'custom-tooltip'
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.2f}% ',
            align: 'center',
            verticalAlign: 'bottom',
            inside: false,
          }
        }
      },
      series: [{
        name: 'Damage',
        color: "#FF7F7F",
        data: transitPercentageData.map((value, index) => {
          const roundedValue = Math.round(value.percentage * 10) / 10;
          return {
            y: value.percentage,
            color: roundedValue >= 0 ? '#FF7F7F' : '#92d864',
            damages: (value.damages > 0 ? value.damages : 0),
            pod_processed: (value.pod_processed > 0 ? value.pod_processed : 0)
          };
        }),
      }]
    };


    return (
      <div className='row'>
        {/* <div className='card col-xl-12 col-lg-12' style={{marginTop:"0px"}}>
            <div className="card-header text-center" style={{marginTop:"0px"}}>
            
            <h5 style={{ border: "1px solid #ccc", padding: "1%", fontSize:"20px" }}>
           
              <span className="" style={{position:"relative",left:"0 %"}}>On Time Delivery</span>
            </h5>
          </div>
          </div> */}
        <div className={"dataLoadpage " + (this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " + (this.state.loadshow)} style={{ position: "fixed", top: "43%" }}>
          <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
        <div style={{ textAlign: "center", background: "#171C8F", width: '100%', margin: "5px", borderRadius: "5px", height: '70px', paddingBottom: '25px' }}>
          <h6 class="txt-dark f40" style={{ marginTop: "0px" }}>
            <span style={{ fontSize: "20px", color: "#FFFFFF" }}>
              <span>Transit Damage</span>

            </span>
          </h6>
        </div>


        <div className="row card-body">

          <div className="col-xl-4">
            <label>Select Facility</label>
            <Select
              placeholder={"All Facility"}
              closeMenuOnSelect={true}

              value={this.state.facility.label}
              onChange={this.newFilter.bind(this)}
              style={{ borderRadius: "0px" }}
              options={this.state.facilitiesList} required
            />
          </div>
          <div className="col-xl-8 col-lg-8">
            <h6>

              <span className="float-right f12 ">
                <a className="btn btn-danger" style={{ float: "left", marginLeft: "10%" }} href="/lspceodashboard">Back</a>
              </span>
              <span style={{ float: "right", marginRight: "5%" }}>Best Performing LSP Damage %= {damagePerformance}<br/>Best Performing LSPs count : {this.state.damage_count}</span> 
           
            </h6>
          </div>

        </div>




        <div className='card col-xl-12 col-lg-12'>

          <HighchartsReact
            containerProps={{ style: { width: '100%', height: '100%' } }}
            highcharts={Highcharts}
            style={dStyles}
            options={highchartoptions}
            onClick={this.handleDrilldown.bind(this)}
          />
        </div>


      </div>


    );
  }
}
function DynamicSortOfMonths() {
  const currentDate = new Date();
  const fiscalYearStartMonth = 3;
  let currentFiscalYear;
  if (currentDate.getMonth() < fiscalYearStartMonth) {
    currentFiscalYear = currentDate.getFullYear() - 1;
  } else {
    currentFiscalYear = currentDate.getFullYear();
  }

  const fiscalYearMonths = [];
  const monthNames = [
    "Apr", "May", "Jun", "Jul", "Aug", "Sep",
    "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"
  ];

  // Dynamically generate the order array based on the current fiscal year
  const order = [];
  for (let i = fiscalYearStartMonth; i < fiscalYearStartMonth + 12; i++) {
    const monthName = monthNames[i % 12];
    const year = (monthName === "Jan" || monthName === "Feb" || monthName === "Mar") ? currentFiscalYear + 1 : currentFiscalYear;
    order.push(`${monthName}-${String(year).substring(2)}`);
  }

  for (let month = fiscalYearStartMonth; month < fiscalYearStartMonth + 12; month++) {
    const monthName = monthNames[month % 12];
    const year = (monthName === "Jan" || monthName === "Feb" || monthName === "Mar") ? currentFiscalYear + 1 : currentFiscalYear;
    fiscalYearMonths.push(`${monthName}-${year}`);
  }

  // Sort fiscal year months based on the dynamically generated order
  fiscalYearMonths.sort((a, b) => {
    const [monthA, yearA] = a.split('-');
    const [monthB, yearB] = b.split('-');
    // Compare years first
    if (yearA !== yearB) {
      return parseInt(yearA) - parseInt(yearB);
    }
    // If years are the same, compare months
    return monthNames.indexOf(monthA) - monthNames.indexOf(monthB);
  });

  fiscalYearMonths.forEach((month, index) => {
    const [monthName, year] = month.split('-');
    const shortYear = year.substring(2);

    console.log(index, 'index', monthName, shortYear)
    fiscalYearMonths[index] = `${monthName}-${shortYear}`;
  });
  console.log(fiscalYearMonths, 'fiscalYearMonths')
  return fiscalYearMonths;
}
function extractYear(month) {
  const match = month.match(/FY (\d{2})-(\d{2})/);
  if (match) {
    // Convert to a number where '20xx' is represented as '20xx' (e.g., '2023')
    return parseInt(`20${match[1]}`, 10) * 100 + parseInt(match[2], 10);
  }
  return 0; // Default value if format is unexpected
}
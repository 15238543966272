
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const ExportsInIcdAction = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onShowConsignmentExportsEventsClick(props);
    };
    
    if(props.data.icd_delay == 1)
    {
        var colr = 'redfont'
    }
    else{
        if(props.data.status == 4)
        {
             var colr = 'yellowfont'
        }
        else{
            if(props.data.status > 4)
            {   
                var colr = 'greenfont'
            }
            else{
                var colr = 'grayfont'
            }
        }
    }
    return (
        <div>
            <button onClick={handleEventClick} className="custom-btn f22 label label-success" 
            title="Inside ICD" ><i className={"icofont icofont-ui-home "+(colr)}></i> &nbsp;</button>
            
        </div>
    );
};

export default ExportsInIcdAction;

import React, { Component } from "react";
import moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from "@ag-grid-community/react";
import Select from "react-select";
import $ from "jquery";
import {
    AllModules,
    GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import * as Datetime from 'react-datetime';
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
var redirectURL = require("../redirectURL");

export default class EmissionStandardDistance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modules: AllModules,
            eventFormAction: "formAction",
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                width: 160,
                resizable: true,
            },
            eventAction: null,
            show: false,
            basicTitle: "",
            basicType: "default",
            bulkslide: "",
            bulkslide1: "",
            rowModelhighchartoptionsType: "enterprise",
            paginationPageSize: 50,
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel",
                    },
                ],
            },
            overly: "show-n",
            loadshow: "show-n",
            file: "",
            rowData: [],
            rowId: "",
            show: false,
            basicTitle: "",
            basicType: "",
            consigner_code: '',
            consigner_type: '',
            truck_transit_time: '',
            trailer_transit_time: '',
            distance_in_km: '',
            for_code: '',
            for_city: '',
        };
    }

    async componentDidMount() {
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        });
        await redirectURL
            .post("/consignments/emissionstandarddistancedataonmount")
            .then((response) => {
                var totalData = response.data
                // const filteredData = totalData.filter(row => !row.deleted);
                this.setState({
                    rowData: totalData,
                    loadshow: "show-n",
                    overly: "show-n",
                })
            })
    }
    onClickHideAll() {
        this.setState({
            loadshow: "show-n",
            overly: "show-n",
            bulkslide: '',
            bulkslide1: "",
            consigner_code: '',
            consigner_type: '',
            truck_transit_time: '',
            trailer_transit_time: '',
            distance_in_km: '',
            for_code: '',
            for_city: '',
        });
        $("#uploadFile").val("")
        var form = document.getElementById("initialEditDetails");
        if (form) {
            form.reset();
        }
    }
    async onCellClicked(event) {
        if (event.colDef.field === "Edit") {
            var data = event.data;
            console.log(data, 'data', data.consigner_code)
            this.setState({
                rowId: data._id,
                consigner_code: data.consigner_code,
                consigner_type: data.consigner_type,
                truck_transit_time: data.truck_transit_time,
                trailer_transit_time: data.trailer_transit_time,
                distance_in_km: data.distance_in_km,
                for_city: data.for_city,
                for_code: data.for_code,
                bulkslide1: "slider-translate-30p",
            });
        }
    }

    addnewdata() {
        var form = document.getElementById("initialAddingDetails");
        if (form) {
            form.reset();
        }
        this.setState({
            bulkslide: "slider-translate-30p",
            overly: "show-m",
        })
    }

    async onClickAddDetails(e) {
        e.preventDefault();
        this.setState({
            loadshow: "show-m",
            overly: "show-m",
        })
        var consigner_code = this.state.consigner_code;
        var consigner_type = this.state.consigner_type;
        var truck_transit_time = (this.state.truck_transit_time);
        var trailer_transit_time = (this.state.trailer_transit_time);
        var distance_in_km = Number(this.state.distance_in_km);
        var for_city = this.state.for_city;
        var for_code = this.state.for_code;

        var formData = new FormData();
        formData.append('consigner_code', consigner_code)
        formData.append('consigner_type', consigner_type)
        formData.append('truck_transit_time', truck_transit_time)
        formData.append('trailer_transit_time', trailer_transit_time)
        formData.append('distance_in_km', distance_in_km)
        formData.append('for_city', for_city)
        formData.append('for_code', for_code)


        formData.append('updated_by', localStorage.getItem("username"))
        if (consigner_code != "" && consigner_code != undefined && consigner_type != "" && consigner_type != undefined && truck_transit_time != "" && truck_transit_time != undefined && trailer_transit_time != "" && trailer_transit_time != undefined && for_city != "" && for_city != undefined && for_code != "" && for_code != undefined) {
            if(distance_in_km > 0){
            await redirectURL
                .post("/consignments/addemissionstandarddistancedata", formData)
                .then((response) => {
                    this.setState({
                        show: true,
                        loadshow: "show-n",
                        overly: "show-n",
                        basicType: "success",
                        basicTitle: "New details added Successfully",
                        bulkslide: '',
                        consigner_code: '',
                        consigner_type: '',
                        truck_transit_time: '',
                        trailer_transit_time: '',
                        distance_in_km: '',
                        for_code: '',
                        for_city: '',
                    })

                })
            this.componentDidMount()
        }else{
            this.setState({
                show: true,
                basicType: "warning",
                loadshow: "show-n",
                overly: "show-n",
                basicTitle: "Distance must be greater than 0",
            })
        }
        } else {
            this.setState({
                show: true,
                basicType: "warning",
                loadshow: "show-n",
                overly: "show-n",
                basicTitle: "All (*) fields are mandatory",
            })
        }
    }

    async onClickEditDetails(e) {
        e.preventDefault();
        this.setState({
            loadshow: "show-m",
            overly: "show-m",
        })
        var consigner_code = this.state.consigner_code;
        var consigner_type = this.state.consigner_type;
        var truck_transit_time = (this.state.truck_transit_time);
        var trailer_transit_time = (this.state.trailer_transit_time);
        var distance_in_km = Number(this.state.distance_in_km);
        var for_city = this.state.for_city;
        var for_code = this.state.for_code;


        var formData = new FormData();
        formData.append("rowId", this.state.rowId)
        formData.append('consigner_code', consigner_code)
        formData.append('consigner_type', consigner_type)
        formData.append('truck_transit_time', truck_transit_time)
        formData.append('trailer_transit_time', trailer_transit_time)
        formData.append('distance_in_km', distance_in_km)
        formData.append('for_city', for_city)
        formData.append('for_code', for_code)
        formData.append('updated_by', localStorage.getItem("username"))
        if (
            consigner_code !== "" && consigner_code !== undefined &&
            consigner_type !== "" && consigner_type !== undefined &&
            (truck_transit_time !== null && truck_transit_time !== undefined) &&
            (trailer_transit_time !== null && trailer_transit_time !== undefined) &&
            for_city !== "" && for_city !== undefined &&
            for_code !== "" && for_code !== undefined
        ) {

        if(distance_in_km > 0){
            await redirectURL
                .post("/consignments/Editemissionstandarddistancedata", formData)
                .then((response) => {
                    this.setState({
                        show: true,
                        loadshow: "show-n",
                        overly: "show-n",
                        basicType: "success",
                        basicTitle: "Successfully Updated",
                        bulkslide1: '',
                        consigner_code: '',
                        consigner_type: '',
                        truck_transit_time: '',
                        trailer_transit_time: '',
                        distance_in_km: '',
                        for_code: '',
                        for_city: '',
                    })

                })
            $("#uploadFile").val("")
            var form = document.getElementById("initialEditDetails");
            if (form) {
                form.reset();
            }
            this.componentDidMount()
        }else{
            this.setState({
                show: true,
                basicType: "warning",
                loadshow: "show-n",
                overly: "show-n",
                basicTitle: "Distance must be greater than 0",
            })
        }
        } else {
            this.setState({
                show: true,
                basicType: "warning",
                loadshow: "show-n",
                overly: "show-n",
                basicTitle: "All (*) fields are mandatory",
            })
        }
    }

    closeAlert = () => {
        this.setState({
            show: false,
        });
    };

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        // console.log(name, '---1', value)
        this.setState({ [name]: value });
    };
    handleKeyPressforFloat = (event) => {
        const inputChar = String.fromCharCode(event.charCode);
        // Allow only numbers and the decimal point
        if (!/^\d*\.?\d*$/.test(inputChar)) {
          event.preventDefault(); // Prevent the character from being entered
        }
      };
    render() {

        const batchwisecolumns = [
            {
                headerName: "Edit",
                field: "Edit",
                width: 110,
                resizable: true,
                editable: false,
                cellRenderer: function (params) {
                    //   if (!params.data.not_planned_volumes) {
                    var htmloption = '<button class="btn btn-warning" style="padding-top:0px">Edit</button>';
                    return htmloption;
                    //   } else {
                    //     var htmloption = '<button class="btn btn-warning" disabled="on" style="padding-top:0px">Edit</button>';
                    //     return htmloption;
                    //   }
                },
                filter: false,
            },

            {
                headerName: 'Consigner Code',
                field: "consigner_code",
            },
            {
                headerName: 'Consigner Type',
                field: "consigner_type",
            },
            {
                headerName: 'Truck Transit Time (Days)',
                field: "truck_transit_time",
            },

            {
                headerName: 'Trailer Transit Time (Days)',
                field: "trailer_transit_time",
            },
            {
                headerName: 'Distance In Km',
                field: "distance_in_km",
            },
            {
                headerName: 'For City',
                field: "for_city",
            },
            {
                headerName: 'For Code',
                field: "for_code",
            },

        ];


        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                ></SweetAlert>
                <div>
                </div>
                <h5>
                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i>{" "}
                    <span>Standard Distance</span></h5>
                <div className="card col-xl-12">
                    <div className="card-header" style={{ display: 'flex', flexDirection: 'column' }}>
                        <div><button className="btn btn-info" style={{ float: "right", marginRight: "10px" }} onClick={this.addnewdata.bind(this)}>Add New Standard Distance Details</button>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div
                                    id="myGrid"
                                    style={{ width: "100%", height: "478px" }}
                                    className="ag-theme-balham"
                                >
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={batchwisecolumns}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={false}
                                        onGridReady={this.onGridReady2}
                                        onGridState={this.onGridState}
                                        frameworkComponents={this.state.frameworkComponents}
                                        onCellClicked={this.onCellClicked.bind(this)}
                                        sideBar={this.state.sideBar}
                                        stopEditingWhenGridLosesFocus={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this },
                                        }}
                                        enableRangeSelection={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"slide-r " + (this.state.bulkslide)} style={{ overflow: "scroll" }}>
                        <h3 className="subH">New Standard Distance Details
                            <span className="float-right fclose" style={{ marginRight: "12px", padding: "1px 8px", marginTop: "-2px" }} onClick={this.onClickHideAll.bind(this)}>x</span>
                        </h3>
                        <div className="slide-r-body" style={{ position: "relative" }}>
                            <div className="container-fluid">
                                <form id="initialAddingDetails"
                                    onSubmit={this.onClickAddDetails.bind(this)}
                                    className="row col-xl-12 col-lg-12">

                                    <div className="col-xl-12 col-lg-12 form-group">
                                        <label>Consigner Code<span style={{ color: 'red' }}> *</span> </label>
                                        <input
                                            type="text"
                                            value={this.state.consigner_code}
                                            name="consigner_code"
                                            id="consigner_code"
                                            className="form-control"
                                            //   onKeyPress={this.handleKeyPressCodeTrans}
                                            autoComplete="off"
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </div>

                                    <div className="col-xl-12 col-lg-12 form-group">
                                        <label>Consigner Type<span style={{ color: 'red' }}> *</span> </label>
                                        <input
                                            type="text"
                                            value={this.state.consigner_type}
                                            name="consigner_type"
                                            id="consigner_type"
                                            className="form-control"
                                            //   onKeyPress={this.handleKeyPressCodeRakeId}
                                            autoComplete="off"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="col-xl-12 col-lg-12 form-group">
                                        <label>Truck Transit Time<span style={{ color: 'red' }}> *</span> </label>
                                        <input
                                            type="text"
                                            value={this.state.truck_transit_time}
                                            name="truck_transit_time"
                                            id="truck_transit_time"
                                            className="form-control"
                                            onKeyPress={this.handleKeyPressforFloat}
                                            autoComplete="off"
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </div>

                                    <div className="col-xl-12 col-lg-12 form-group">
                                        <label>Trailer Transit Time<span style={{ color: 'red' }}> *</span> </label>
                                        <input
                                            type="text"
                                            value={this.state.trailer_transit_time}
                                            name="trailer_transit_time"
                                            id="trailer_transit_time"
                                            className="form-control"
                                            onKeyPress={this.handleKeyPressforFloat}
                                            autoComplete="off"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="col-xl-12 col-lg-12 form-group">
                                        <label>Distance In Km<span style={{ color: 'red' }}> *</span> </label>
                                        <input
                                            type="text"
                                            value={this.state.distance_in_km}
                                            name="distance_in_km"
                                            id="distance_in_km"
                                            className="form-control"
                                            onKeyPress={this.handleKeyPressforFloat}
                                            autoComplete="off"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="col-xl-12 col-lg-12 form-group">
                                        <label>For City<span style={{ color: 'red' }}> *</span> </label>
                                        <input
                                            type="text"
                                            value={this.state.for_city}
                                            name="for_city"
                                            id="for_city"
                                            className="form-control"
                                            //   onKeyPress={this.handleKeyPressCodeRakeId}
                                            autoComplete="off"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="col-xl-12 col-lg-12 form-group">
                                        <label>For Code<span style={{ color: 'red' }}> *</span> </label>
                                        <input
                                            type="text"
                                            value={this.state.for_code}
                                            name="for_code"
                                            id="for_code"
                                            className="form-control"
                                            //   onKeyPress={this.handleKeyPressCodeRakeId}
                                            autoComplete="off"
                                            onChange={this.handleChange}
                                        />
                                    </div>


                                    <div className="form-group col-xl-3 col-lg-3">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className={"slide-r " + (this.state.bulkslide1)} style={{ overflow: "scroll" }}>
                        <h3 className="subH">Edit Standard Distance Details
                            <span className="float-right fclose" style={{ marginRight: "12px", padding: "1px 8px", marginTop: "-2px" }} onClick={this.onClickHideAll.bind(this)}>x</span>
                        </h3>
                        <div className="slide-r-body" style={{ position: "relative" }}>
                            <div className="container-fluid">
                                <form id="initialEditDetails"
                                    onSubmit={this.onClickEditDetails.bind(this)}
                                    className="row col-xl-12 col-lg-12">


                                    <div className="col-xl-12 col-lg-12 form-group">
                                        <label>Consigner Code<span style={{ color: 'red' }}> *</span> </label>
                                        <input
                                            type="text"
                                            value={this.state.consigner_code}
                                            name="consigner_code"
                                            id="consigner_code"
                                            className="form-control"
                                            //   onKeyPress={this.handleKeyPressCodeTrans}
                                            autoComplete="off"
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </div>

                                    <div className="col-xl-12 col-lg-12 form-group">
                                        <label>Consigner Type<span style={{ color: 'red' }}> *</span> </label>
                                        <input
                                            type="text"
                                            value={this.state.consigner_type}
                                            name="consigner_type"
                                            id="consigner_type"
                                            className="form-control"
                                            //   onKeyPress={this.handleKeyPressCodeRakeId}
                                            autoComplete="off"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="col-xl-12 col-lg-12 form-group">
                                        <label>Truck Transit Time<span style={{ color: 'red' }}> *</span> </label>
                                        <input
                                            type="text"
                                            value={this.state.truck_transit_time}
                                            name="truck_transit_time"
                                            id="truck_transit_time"
                                            className="form-control"
                                            onKeyPress={this.handleKeyPressforFloat}
                                            autoComplete="off"
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </div>

                                    <div className="col-xl-12 col-lg-12 form-group">
                                        <label>Trailer Transit Time<span style={{ color: 'red' }}> *</span> </label>
                                        <input
                                            type="text"
                                            value={this.state.trailer_transit_time}
                                            name="trailer_transit_time"
                                            id="trailer_transit_time"
                                            className="form-control"
                                            onKeyPress={this.handleKeyPressforFloat}
                                            autoComplete="off"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="col-xl-12 col-lg-12 form-group">
                                        <label>Distance In Km<span style={{ color: 'red' }}> *</span> </label>
                                        <input
                                            type="text"
                                            value={this.state.distance_in_km}
                                            name="distance_in_km"
                                            id="distance_in_km"
                                            className="form-control"
                                            onKeyPress={this.handleKeyPressforFloat}
                                            autoComplete="off"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="col-xl-12 col-lg-12 form-group">
                                        <label>For City<span style={{ color: 'red' }}> *</span> </label>
                                        <input
                                            type="text"
                                            value={this.state.for_city}
                                            name="for_city"
                                            id="for_city"
                                            className="form-control"
                                            //   onKeyPress={this.handleKeyPressCodeRakeId}
                                            autoComplete="off"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="col-xl-12 col-lg-12 form-group">
                                        <label>For Code<span style={{ color: 'red' }}> *</span> </label>
                                        <input
                                            type="text"
                                            value={this.state.for_code}
                                            name="for_code"
                                            id="for_code"
                                            className="form-control"
                                            //   onKeyPress={this.handleKeyPressCodeRakeId}
                                            autoComplete="off"
                                            onChange={this.handleChange}
                                        />
                                    </div>


                                    <div className="form-group col-xl-3 col-lg-3">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>


                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        );
    }
}

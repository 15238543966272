import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 

import Highcharts from 'highcharts';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';
drilldown(Highcharts);
window.jQuery = $;
window.$ = $;

var redirectURL = require('../redirectURL');
var criticaldata=[]
export default class TransporterChart extends Component {

    constructor(props) {
		super(props);
		this.state = {
			showgrid:'display:none',
			showtransgrid:'display:none',
			rowdata:[],
            test:"TEstng",
            
        }
        this.handleDrilldown = this.handleDrilldown.bind(this)

	}
	componentDidMount = async () =>{
		
		$(".grid-"+this.props.code).hide();
		$(".grid-trans-"+this.props.code).hide();
		$(".dataLoadpagesum"+this.props.code).hide()
		$(".dataLoadpageimgsum"+this.props.code).hide()
		$(".overlay-partsum"+this.props.code).hide()
	}
	componentWillReceiveProps (newprops){
        if(newprops.title != this.props.dept_code)
        {
            //console.log("newprops ",newprops);
        }
        
    }
    handleDrilldown = (params) => {
        console.log("Here ,", params)
    }
    render(){
        //console.log("this.props.code) ", this.props.code)
    
  
        const dStyles={
				width:'100%',
				height:'100%'
		}
		const highchartoptions = {
            chart: {
                type: 'column',
                events: {
                    // click: (function(self) {
                    // return function() {
                    //     const point = {
                    //     x: this.x,
                    //     y: this.y
                    //     };
                    //     self.handleDrilldown(point);
                    // };
                    // })(this)
                    
                }
            },
            title: {
				text: this.props.title,
				//align:'left'
            },
            subtitle: {
                text: ''
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                }
            },
            xAxis: {
                type: 'category'
            },
            yAxis: {
                title: {
                    text: this.props.yaxistitle
                }
        
            },
            legend: {
                enabled: false
            },
            // point: {
            //     events:{
            //         click:function(e)
            //         {
            //             alert("test")
            //            return this.handleDrilldown(e)
            //         }
            //     }
            // },
            plotOptions: {
                series: {
                    // stacking: 'normal',
                    "cropThreshold": 500,
                    dataLabels: {
                        enabled: true
                    },
                    enableMouseTracking: true,
                  },
                line:{
					dataLabels:{
                        enabled:true,
                        // format:'{point.y}'
					},
					stacked: false
				},
            },
        
            tooltip: {
                // shared: true,
                // headerFormat: '<span style="font-size:11px">{point.name}</span><br>',
                // pointFormat: '<span style="font-size:11px,color:{point.color}">{point.name}</span><br><span style="color:{point.color}">Return Trips</span>: <b>{point.y} {(point.unloadtext)}</b></b><br>\
                //                 <span style="color:{point.color}">Total Trips</span>: <b>{point.total_trips}</b><br>'
				// formatter:function(){
				// 	console.log(this.series)
                // }
                formatter: function(){
                    // headerFormat: '<span style="font-size:11px">{point.name}</span><br>',
                    // console.log(this.point)
                    if(this.series.type != "spline")
                    {
                        if (!this.point.drilldown) {
                            return '<span style="font-size:11px;color:'+this.series.color+'">'+this.point.name+'</span><br>\
                                <span style="color:'+this.series.color+'">Return Trips</span>: <b>'+this.y+'</b><br>'
                        }
                        else{
                            return '<span style="font-size:11px;color:'+this.series.color+'">'+this.point.name+'</span><br>\
                                <span style="color:'+this.series.color+'">Total Trips</span>: <b>'+this.point.total_trips+'</b><br>\
                                <span style="color:'+this.series.color+'">Return Trips</span>: <b>'+this.y+'</b></b><br>\
                                <span style="color:'+this.series.color+'">Return Trips %</span>: <b>'+this.point.valid_trip_percentage+'</b><br>'
                        }
                    }
                    else{return false}
                    
                }
			},
        
            series: this.props.series,
            drilldown : this.props.drilldownseries,
			credits:false
        };
        // console.log(highchartoptions.drilldown,"drilldown");
        // console.log(highchartoptions.series,"series")
        return ( 
			<div>

               <HighchartsReact
                    containerProps={{ style: { width: '100%', height: '100%' } }}
                    highcharts={Highcharts} 
                    style={dStyles} 
                    options={highchartoptions} 
                    onClick={this.handleDrilldown.bind(this)}
                />
			</div>
			);
		}
}

// function prepareSeries(series) {
//     var newSeriesArr = [];
//     series.forEach(function(series) {
//       // create new series for every point
//       series.data.forEach(function(point) {
//         var newSeries = {};
//         for (var prop in series) {
//           if (prop !== 'data') {
//             newSeries[prop] = series[prop]; // copy properties to a new series
//           }
//         }
//         newSeries.data = [point];
  
//         // eliminate duplicates in the legend
//         newSeries.showInLegend = typeof newSeriesArr.find((s) => s.name === newSeries.name) === 'object';
  
//         newSeriesArr.push(newSeries);
//       });
//     });
//     newSeriesArr.sort(function(s1, s2) {
//       return s2.data[0].y - s1.data[0].y;
//     });
//     return newSeriesArr;
//   }
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';

import CountUp from 'react-countup';
import redirectURL from '../redirectURL';
import Highcharts from 'highcharts';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';

window.jQuery = $;
window.$ = $;
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");


export default class MllDamageAnalysis extends Component{
    constructor(props) {
        super(props);
        this.state={
            modules: AllModules,
            // truckData:this.props.truckInfo,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            
            truckNumList:"",
            loadshow:'show-n',
            truckLocation:"",
            replaced_date_time:"",
            route_details:"",
            rowData:"",
            showTrucksList:"show-n",
            modules:AllModules,
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
            route_info:"",
			rowData: null,
			animateRows: true,
            consignment_details:"",
            dept_code:"",
            replacement_truck:"",
            pagetitle:"",
            showonlytransport:'show-m',
            reason:'',
            overly :"show-n",
            filteredData : [],
            sourceplants:[],
            sources:[],
            totaldevices:0,
            assignloadingyard:0,
            handovers:0,
            rakedevices:0,
            issueba:0,
            rsoretrivals:0,
            totaldevicesarr:[],
            intransitloading:0,
            atloading:0,
            handoversarr:[],
            rakedevicesarr:[],
            issuebaarr:[],
            rsoretrivalsarr:[],
            atplant:0,
            atplantarr:[],
            retrivedloading : 0,
            intransitplant : 0,
            atdestination : 0,
            retriveddest : 0,
            intransitrso : 0,
            intransitdealer : 0,
            atrso : 0,
            retrivedloadingarr : [],
            intransitloadingarr:[],
            atloadingarr:[],
            intransitplantarr : [],
            atdestinationarr : [],
            retriveddestarr : [],
            intransitrsoarr : [],
            intransitdealerarr : [],
            atrsoarr : [],
            shiptosource:0,
            shiptosourcearr:[],
            loadingplantseries:[],
            
            aocategory:[],
            aoseries:[],
            monthdamagecategory:[],
            monthdamageseries:[],
            unloadtypeseries:[],
            dcmdamagecateory:[],
            dcmdamageseries:[],
            modelseries:[],
            modelcategory:[],
            loadstartseries:[],
            loadstcategory:[],
            loadendcategory:[],
            loadendseries:[],
            inloadstartseries:[],
            unloadstcategory:[],
            unloadendcategory:[],
            unloadendseries:[]
        };
        // this.onLoadData = this.onLoadData.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        this.setState({
            //overly : "show-m",
            loadshow:'show-n',
        })

        //$('#replacement-submit').prop("disabled",true);
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        var pagetitle="Damage Analysis "
        var dept_code = "SNDG";
        var israil=0;
       
       
        this.setState({
            
            eventAction:eventAction,
            dept_code:dept_code,
            pagetitle:pagetitle
        });
      
        // var records = {
        //     "loading_plant_damage":[
        //     {
        //     "loading_plant":"CHK",
        //     "unclean":9
        //     },
        //     {
        //     "loading_plant":"HRD",
        //     "unclean":5
        //     },
        //     {
        //     "loading_plant":"KND",
        //     "unclean":13
        //     },
        //     {
        //     "loading_plant":"NSK",
        //     "unclean":5
        //     },
        //     {
        //     "loading_plant":"ZHB",
        //     "unclean":2
        //     }
        //     ],
            
        //         "month_damage":[
        //             {"month":9,"unclean":8.0},
        //             {"month":10,"unclean":5.0},
        //             {"month":11,"unclean":4.0},
        //             {"month":12,"unclean":3.0}],
        //         "AO_damage":[
        //             {"ao_":"BHUBANESHWAR","unclean":6.0},
        //             {"ao_":"GUWAHATI","unclean":5.0},
        //             {"ao_":"KOLKATA","unclean":6.0},
        //             {"ao_":"PATNA","unclean":5.0},
        //             {"ao_":"PUNE","unclean":3.0},
        //             {"ao_":"RAIPUR","unclean":4.0}],
        //         "unloading_type_damage":[
        //             {"unloading_type":"Forward","unclean":5.0},
        //             {"unloading_type":"Revers","unclean":6.0}
        //         ],
        //         "dcm_person_damage":[
        //             {"dcm_person":"NO","unclean":5.0},
        //             {"dcm_person":"YES","unclean":5.0}],
        //         "model":[
        //             {"feature":"kuv","importance":9.2486960618},
        //             {"feature":"supro-sht","importance":4.80332576},
        //             {"feature":"dcm_person_yes","importance":2.256025136},
        //             {"feature":"alfa_pass","importance":2.105305566},
        //             {"feature":"cbc_fb","importance":0.7444552787},
        //             {"feature":"ao__guwahati","importance":0.622072597},
        //             {"feature":"ao__patna","importance":0.4038582418}
        //         ],
        //         "load_start_damage":[
        //             {"loading_start_time":"12AM-6AM","unclean":7},
        //             {"loading_start_time":"6AM-12PM","unclean":5},
        //             {"loading_start_time":"12PM-7PM","unclean":4},
        //             {"loading_start_time":"7PM-12AM","unclean":4}
        //         ],
        //         "load_end_damage":[
        //             {"loading_end_time":"12AM-6AM","unclean":5},
        //             {"loading_end_time":"6AM-12PM","unclean":6},
        //             {"loading_end_time":"12PM-7PM","unclean":5},
        //             {"loading_end_time":"7PM-12AM","unclean":5}
        //         ],
        //         "unload_start_damage":[
        //             {"unloading_start_time":"12AM-6AM","unclean":5},
        //             {"unloading_start_time":"6AM-12PM","unclean":6},
        //             {"unloading_start_time":"12PM-7PM","unclean":5},
        //             {"unloading_start_time":"7PM-12AM","unclean":6}
        //         ],
        //         "unload_end_damage" :[
        //             {"unloading_end_time":"12AM-6AM","unclean":5.0},
        //             {"unloading_end_time":"6AM-12PM","unclean":6.0},
        //             {"unloading_end_time":"12PM-7PM","unclean":5.0},
        //             {"unloading_end_time":"7PM-12AM","unclean":5.0}
        //         ]
        //     }
        redirectURL.post("/mllconsignments/damageanalysis")
      .then((response) => {
          console.log("Damage analysis ", response.data)
          var records = response.data;
          var loading_plant_damage = JSON.parse(records.loading_plant_damage);
          var month_damage = JSON.parse(records.month_damage);
          var ao_damage = JSON.parse(records.AO_damage);
          var unloading_type_damage = JSON.parse(records.unloading_type_damage);
          var dcm_person_damage = JSON.parse(records.dcm_person_damage);
          var model = JSON.parse(records.model);
          var load_start_damage = JSON.parse(records.load_start_damage);
          var load_end_damage = JSON.parse(records.load_end_damage);
          var unload_start_damage = JSON.parse(records.unload_start_damage);
          var unload_end_damage = JSON.parse(records.unload_end_damage);

          var pcats = []
          var series = []
            if(loading_plant_damage.length > 0)
            {
                loading_plant_damage.map((item) => {
                    series.push({
                            name: item.loading_plant,
                            y: item.unclean
                        
                    })
                })
            
            }
            var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug","Sep", "Oct", "Nov", "Dec"]
            var mseries = []
            if(month_damage.length > 0)
            {
                month_damage.map((item) => {
                    pcats.push(months[item.month-1])
                    mseries.push({
                        name:months[item.month-1],
                        data:[item.unclean],
                        dataLabels: {
                            enabled: true
                        }
                    })
                })
            }
            // console.log("pcats ", pcats)
            var aocats = []
            var aoseries = []
            if(ao_damage.length > 0)
            {
                ao_damage.map((item) => {
                    aocats.push(item.ao_)
                    aoseries.push({
                        name:item.ao_,
                        data:[item.unclean],
                        dataLabels: {
                            enabled: true
                        }
                    })
                })
            }
            
            var unloadtypeseries = []
            if(unloading_type_damage.length > 0)
            {
                unloading_type_damage.map((item) => {
                    unloadtypeseries.push([item.unloading_type,item.unclean])
                })
            }

            var dcmcats = []
            var dcmseries = []
            if(dcm_person_damage.length > 0)
            {
                dcm_person_damage.map((item) => {
                    dcmcats.push(item.dcm_person)
                    dcmseries.push({
                        name:item.dcm_person,
                        data:[item.unclean],
                        dataLabels: {
                            enabled: true
                        }
                    })
                })
            }
            

            var modelseries = []
            var modelcategory = []
            if(model.length > 0)
            {
                model.map((item) => {
                    modelcategory.push(item.feature);
                    modelseries.push({
                        name: item.feature,
                        data: [item.importance],
                        dataLabels: {
                            enabled: true
                        }
                        
                    })
                })
            
            }

            
            var loadstcats = []
            var loadstartseries = []
            if(load_start_damage.length > 0)
            {
                load_start_damage.map((item) => {
                    loadstcats.push(item.loading_start_time)
                    loadstartseries.push({
                        name:item.loading_start_time,
                        data:[item.unclean],
                        dataLabels: {
                            enabled: true
                        }
                    })
                })
            }

            var loadendcats = []
            var loadendseries = []
            if(load_end_damage.length > 0)
            {
                load_end_damage.map((item) => {
                    loadendcats.push(item.loading_end_time)
                    loadendseries.push({
                        name:item.loading_end_time,
                        data:[item.unclean],
                        dataLabels: {
                            enabled: true
                        }
                    })
                })
            }
            
            
            
            var unloadstcats = []
            var unloadstartseries = []
            if(unload_start_damage.length > 0)
            {
                unload_start_damage.map((item) => {
                    unloadstcats.push(item.unloading_start_time)
                    unloadstartseries.push({
                        name:item.unloading_start_time,
                        data:[item.unclean],
                        dataLabels: {
                            enabled: true
                        }
                    })
                })
            }

            var unloadendcats = []
            var unloadendseries = []
            if(unload_end_damage.length > 0)
            {
                unload_end_damage.map((item) => {
                    unloadendcats.push(item.unloading_end_time)
                    unloadendseries.push({
                        name:item.unloading_end_time,
                        data:[item.unclean],
                        dataLabels: {
                            enabled: true
                        }
                    })
                })
            }

            this.setState({
                loadingplantseries:series,
                monthdamagecategory:pcats,
                monthdamageseries:mseries,
                aocategory:aocats,
                aoseries:aoseries,
                unloadtypeseries:unloadtypeseries,
                dcmdamagecateory:dcmcats,
                dcmdamageseries:dcmseries,
                modelcategory:modelcategory,
                modelseries:modelseries,
                loadstartseries:loadstartseries,
                loadstcategory:loadstcats,
                loadendseries:loadendseries,
                loadendcategory:loadendcats,
                unloadstartseries:unloadstartseries,
                unloadstcategory:unloadstcats,
                unloadendseries:unloadendseries,
                unloadendcategory:unloadendcats
            })

      })

      
    }
    
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
	}
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			showTrucksList : "show-n",
        });
        
    }


    render(){
        const dStyles={
            width:'100%',
            height:'100%'
        }
        
        var highchartoptions = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            yAxis:{
                title:"Avg. Unclean POD's"
            },
            title: {
                text: 'Damages w.r.t loading plant'
            },
            subtitle:{
                text:"Percentage Share of damages from each plant"
            },
            tooltip: {
                pointFormat: '<b>Percentage of unclean POD : </b> <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            
            legend: {
                enabled: true
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                    }
                }
            },
            colors:[ '#f35e82','#7f84e9','#f3d800', '#00f0b5', '#52f300', '#00ddf3','#f30000','#00f0b5','#3100f3', '#fff493', '#f88fa2', '#f15c80', '#92A8CD', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
            series: [{
                name: 'Avg. Unclean POD',
                colorByPoint: true,
                innerSize: '40%',
                data: this.state.loadingplantseries
            }],
			credits:false
        }
       
        // Monthly Damage
        var mdamageoptions = {
            // chart: {
            //     type: 'column'
            // },
            // title: {
            //     text: 'Monthly Damage'
            // },
            // subtitle: {
            //     text: ''
            // },
            // xAxis: {
            //     categories: this.state.monthdamagecategory
            // },
            // yAxis: {
            //     min: 0,
            //     title: {
            //         text: 'Avg. Unclean PODs'
            //     }
            // },
            // tooltip: {
            //     headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            //     pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y} %'
            // },
            // legend: {
            //     enabled: true
            // },

            chart: {
                type: 'column'
            },
            title: {
                text: 'Damages w.r.t month'
            },
            subtitle: {
                text: 'Average no. of damages in a train for a particular month'
            },
            xAxis: {
                // categories:this.state.monthdamagecategory,
                crosshair: true,
                labels: {
                    enabled:false,//default is true
                    y : 20, rotation: -45, align: 'right' 
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Avg. Unclean PODs'
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}'
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    dataLabels:{
                        enable:true,
                        format:'{point.y}'
                    }
                }
            },

            // plotOptions: {
            //     series: {
            //         borderWidth: 0,
            //         dataLabels: {
            //             enabled: true,
            //             format: '{point.y} %'
            //         }
            //     }
            // },
            colors:[ '#f35e82','#7f84e9','#f3d800', '#00f0b5', '#52f300', '#00ddf3','#f30000','#00f0b5','#3100f3', '#fff493', '#f88fa2', '#f15c80', '#92A8CD', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
            series: this.state.monthdamageseries,
            
			credits:false
        }
        // AO Damage
        var aodamageoptions = {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Damages w.r.t Area Office'
            },
            subtitle: {
                text: 'Average no. of damages in a train received by each Area Office .'
            },
            xAxis: {
                categories: this.state.aocategory,
                labels: {
                    enabled:false,//default is true
                    y : 20, rotation: -45, align: 'right' 
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Avg. Unclean PODs'
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}'
            },
            legend: {
                enabled: true
            },

            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}'
                    }
                }
            },
            // colors:[ '#f35e82','#7f84e9','#f3d800', '#00f0b5', '#52f300', '#00ddf3','#f30000','#00f0b5','#3100f3', '#fff493', '#f88fa2', '#f15c80', '#92A8CD', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
            series: this.state.aoseries,
			credits:false
        }

        //Unloading Type Damage        
        var unloadingtypeoptions = {
            chart: {
                type: 'pie',
                options3d: {
                    enabled: true,
                    alpha: 45,
                    beta: 0
                }
            },
            yAxis:{
                title:"Avg. Unclean POD's"
            },
            title: {
                text: 'Damages w.r.t Unloading type'
            },
            subtitle:{
                text:"Average no. of damages in a train w.r.t unloading type"
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.y:.1f}</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: ''
                }
            },
            
            legend: {
                enabled: true
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    depth: 35,
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}: {point.y:.1f}'
                    }
                }
            },
            colors:[ '#f35e82','#7f84e9','#f3d800', '#00f0b5', '#52f300', '#00ddf3','#f30000','#00f0b5','#3100f3', '#fff493', '#f88fa2', '#f15c80', '#92A8CD', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
                
            series: [{
                name: 'Avg. Unclean POD',
                type:"pie",
                data: this.state.unloadtypeseries
            }],
			credits:false
        }

        // DCM Person Type
        var dcmdamageoptions = {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Damages w.r.t DCM Person'
            },
            subtitle: {
                text: 'Average no. of damages in a train w.r.t presence of DCM Person.'
            },
            xAxis: {
                categories: this.state.dcmdamagecateory,
                labels: {
                    enabled:false,//default is true
                    y : 20, rotation: -45, align: 'right' 
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Avg. Unclean PODs'
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}'
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}'
                    }
                }
            },
            legend: {
                enabled: true
            },
            colors:[ '#f35e82','#7f84e9','#f3d800', '#00f0b5', '#52f300', '#00ddf3','#f30000','#00f0b5','#3100f3', '#fff493', '#f88fa2', '#f15c80', '#92A8CD', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
                
            series: this.state.dcmdamageseries,
			credits:false
        }

        // Model
        var modeloptions = {
            // sss

            chart: {
                type: 'column'
            },
            title: {
                text: 'Factors leading to Damages'
            },
            subtitle: {
                text: 'Top factors leading to Unclean PODs according to a machine learning model are mentioned in the below graph.'
            },
            xAxis: {
                categories: this.state.modelcategory,
                title:{
                    text:"Features"
                },
                labels: {
                    enabled:false,//default is true
                    y : 20, rotation: -45, align: 'right' 
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Importance'
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}'
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}'
                    }
                }
            },
            legend: {
                enabled: true
            },
            // colors:[ '#f35e82','#7f84e9','#f3d800', '#00f0b5', '#52f300', '#00ddf3','#f30000','#00f0b5','#3100f3', '#fff493', '#f88fa2', '#f15c80', '#92A8CD', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
                
            series: this.state.modelseries,
			credits:false
        }

        // Load Start Damage
        var loadstartdamageoptions = {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Damages w.r.t loading start time'
            },
            subtitle: {
                text: 'Avg. Damages occured in a train w.r.t the time at which loading has started in the loading yard.'
            },
            xAxis: {
                categories: this.state.loadstcategory,
                labels: {
                    enabled:false,//default is true
                    y : 20, rotation: -45, align: 'right' 
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Avg. Unclean PODs'
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}'
            },
            legend: {
                enabled: true
            },

            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}'
                    }
                }
            },
            // colors:[ '#f35e82','#7f84e9','#f3d800', '#00f0b5', '#52f300', '#00ddf3','#f30000','#00f0b5','#3100f3', '#fff493', '#f88fa2', '#f15c80', '#92A8CD', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
            series: this.state.loadstartseries,
			credits:false
        }

        
        // Load End Damage
        var loadenddamageoptions = {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Damages w.r.t loading end time'
            },
            subtitle: {
                text: 'Avg. Damages occured in a train w.r.t the time at which loading has ended in the loading yard.'
            },
            xAxis: {
                categories: this.state.loadendcategory,
                labels: {
                    enabled:false,//default is true
                    y : 20, rotation: -45, align: 'right' 
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Avg. Unclean PODs'
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}'
            },
            legend: {
                enabled: true
            },

            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}'
                    }
                }
            },
            // colors:[ '#f35e82','#7f84e9','#f3d800', '#00f0b5', '#52f300', '#00ddf3','#f30000','#00f0b5','#3100f3', '#fff493', '#f88fa2', '#f15c80', '#92A8CD', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
            series: this.state.loadendseries,
			credits:false
        }
        
        // Unload Start Damage
        var unloadstartdamageoptions = {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Damages w.r.t Unloading start time'
            },
            subtitle: {
                text: 'Avg. Damages occured in a train w.r.t the time at which Unloading has started in the unloading yard.'
            },
            xAxis: {
                categories: this.state.unloadstcategory,
                labels: {
                    enabled:false,//default is true
                    y : 20, rotation: -45, align: 'right' 
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Avg. Unclean PODs'
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}'
            },
            legend: {
                enabled: true
            },

            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}'
                    }
                }
            },
            // colors:['#f30000','#00f0b5','#3100f3', '#fff493', '#f88fa2',  '#f35e82','#7f84e9','#f3d800', '#00f0b5', '#52f300', '#00ddf3', '#f15c80', '#92A8CD', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
            series: this.state.unloadstartseries,
			credits:false
        }

        
        // Unload End Damage
        var unloadenddamageoptions = {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Damages w.r.t Unloading end time'
            },
            subtitle: {
                text: 'Avg. Damages occured in a train w.r.t the time at which Unloading has  ended in the unloading yard.'
            },
            xAxis: {
                categories: this.state.unloadendcategory,
                labels: {
                    enabled:false,//default is true
                    y : 20, rotation: -45, align: 'right' 
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Avg. Unclean PODs'
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}'
            },
            legend: {
                enabled: true
            },

            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}'
                    }
                }
            },
            // colors:[ '#f35e82','#7f84e9','#f3d800', '#00f0b5', '#52f300', '#00ddf3','#f30000','#00f0b5','#3100f3', '#fff493', '#f88fa2', '#f15c80', '#92A8CD', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
            series: this.state.unloadendseries,
			credits:false
        }
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                   
                    <div className=" col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
                                </h5>
                            </div>
                            <div className="row card-body">
                                <div className="row col-sm-12 col-xl-12  col-lg-12">
                                    <div className="col-xl-6 col-lg-6">
                                        <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />
                                    </div>
                                
                               
                                    <div className="col-xl-6 col-lg-6">
                                        <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={mdamageoptions} />
                                    </div>
                                
                                </div>
                                <div className="row col-sm-12 col-xl-12 col-lg-12 mt-40p">
                                    <div className="col-xl-6 col-lg-6">
                                        <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={aodamageoptions} />
                                    </div>
                                    <div className="col-xl-6 col-lg-6">
                                        <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={unloadingtypeoptions} />
                                    </div>
                                </div>
                                <div className="row col-sm-12 col-xl-12 col-lg-12 mt-40p">
                                    <div className="col-xl-6 col-lg-6">
                                        <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={dcmdamageoptions} />
                                    </div>
                               
                                    <div className="col-xl-6 col-lg-6">
                                        <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={modeloptions} />
                                    </div>
                                </div>
                                
                                <div className="row col-sm-12 col-xl-12 col-lg-12 mt-40p">
                                    <div className="col-xl-6 col-lg-6">
                                        <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={loadstartdamageoptions} />
                                    </div>
                                    
                                    <div className="col-xl-6 col-lg-6">
                                        <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={loadenddamageoptions} />
                                    </div>
                                </div>

                                <div className="row col-sm-12 col-xl-12 col-lg-12 mt-40p">
                                    <div className="col-xl-6 col-lg-6">
                                        <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={unloadstartdamageoptions} />
                                    </div>
                                    
                                    <div className="col-xl-6 col-lg-6">
                                        <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={unloadenddamageoptions} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    

                    {/* <form method="POST" className="form-theme col-xl-12 col-lg-12 row" onSubmit={this.getTruckConsignments.bind(this)}>
                       
                            <div className={"trucks-dropdown "+this.state.showTrucksList}>
                            <ul>
                                {listData}
                            </ul>
                         </div>
                         <div className="col-xl-3 col-lg-3 form-group">
								<input type="text" name="truck_no" id="inputTruck"  placeholder="Truck No" autoComplete="off" className="form-control" onFocus={this.initalList.bind(this)} onChange={this.handlerForm.bind(this)} />
	                    </div>
                        <div className="col-xl-2 col-lg-2">
                            <button type="submit" className="btn btn-success">Get Data</button>
                        </div>
                    </form> */}
                </div> 
                
                
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                    </div>
                    <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                        <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                    </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        )
    }
}

        
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}
$(document).ready(function(){
    $(document).on("click",".dropdown-truck-no",function(){
        var vals = $(this).text();
         $("#inputTruck").val(vals);
         $(".trucks-dropdown").removeClass("show-m");
         $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click",function(e){
        var container = $("#inputTruck");
        if(!container.is(e.target))
        {
            try{
                if($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown")
                {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            }catch(e){}
            
        }
        
        
    })
})


var todaydateis = moment.parseZone().format("YYYY/MM/DD")

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // console.log("todaydateis ",todaydateis)
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        minDate:todaydateis
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
// import AssignRoles from "./assignmenustorolescheckbox";
// import Check from './checkboxComponent';
// import  CheckBox  from './checkbox'
// import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
var redirectURL = require('../redirectURL');
var selectpath = '';

var selectedmenus = []
export default class ShiftTimings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu_title:'',
            loadshow:'show-n',
            overly:'show-n',
            updateForm:'show-n',
            addForm:'show-m',
            allmenus:[],
            role_name:"",
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            sliderTranslate:'',
            allmenus:[],
            allmenustree:[],
            submenuitems:[],
            
            allroles:[], 
            childconfs: [],
            childrow:[],
            selectedmenus : [],
            checked: [],
            expanded: [],
            role_name:'',
            responsemessage:'',
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
            },
            modules: AllModules,
            rowData: [],
            headerHeight: 40,
            groupHeaderHeight:60,
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
               
            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,
                
            childconfs: [],
            childrow:[],
            rowModelType: "serverSide",
            rowSelection:"single",
            paginationPageSize:100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            frameworkComponents: {
               
            },
            detailCellRendererParams:{},
            statusBar: {
                statusPanels: [
                    {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                    },
                    {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            usermanualmodal:false
            
        }
    }
    componentDidMount(){
        redirectURL.post("/consignments/getShifts").then((response)=>{
            this.setState({
                rowData : response.data
            })
            console.log(response.data);
        }).catch((e)=>
        {
            console.log(e);
        })
        
    }
    hideAlert = () => {
        this.setState({
            alert: null
        });
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    
	onClickHideAll(){
		this.setState({
			overly:'show-n',
			sliderTranslate:''
		});
		
	}
   

	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
    
        
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
           
 
	//    };
	   
	};

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
    }
    
	
    
    
    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };
    render(){
        const modalStyles  = {
            width:'1300px !important',
        }
        
        const {usermanualmodal} = this.state;

        var columnDefs = [
            {
                headerName:"Plant",
                field:"plant",
                width:100,
                filter:true,
            },
            {
                headerName:"Shop",
                field:"shop",
                width:100,
                filter:true,
            },
            {
                headerName:"Component",
                field:"component",
                width:150,
                filter:true,
            },
            {
                headerName:"Item Code",
                field:"item_code",
                width:120,
                filter:true,
            },
            {
                headerName:"Source",
                field:"source",
                width:100,
                filter:true,
            },
            {
                headerName:"A Shift",
                field:"source_a_shift",
                width:100,
                filter:true,
                editable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ['Yes', 'No'],
                },
                valueGetter : function(params){
                    if(params.data.source_a_shift == 1)
                    {
                        return "Yes";
                    }else{return "No";}
                },
                cellStyle: function(params) {
                    // console.log("params = ", params.data.is_editable, params.value, params);
                     if (params.value == 'Yes') {
                        // console.log("params = ", params.value, params.data);
                         return {backgroundColor: '#00dea7'};
                     } else {
                         return  {backgroundColor: '#d4d4d4', color:'#797979'};
                     }
                 },
                valueSetter: function(params) {
                    if (params.oldValue !== params.newValue) {
                        console.log(params.oldValue,params.newValue);
                        var alteredField = params.colDef.field;
                        var rowdata = params.data;
                        if(params.newValue == "No")
                        {
                            rowdata[alteredField] = 0;
                        }
                        else{
                            rowdata[alteredField] = 1;
                        }
                        redirectURL.post("/consignments/setShiftTimings",{"rowdata":rowdata}).then((response)=>{

                        }).catch((e)=>{
                            console.log(e);
                        });                        

                    }
                }
            },
            {
                headerName:"B Shift",
                field:"source_b_shift",
                width:100,
                filter:true,
                editable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ['Yes', 'No'],
                },
                valueGetter : function(params){
                    if(params.data.source_b_shift == 1)
                    {
                        return "Yes";
                    }else{return "No";}
                },
                cellStyle: function(params) {
                    // console.log("params = ", params.data.is_editable, params.value, params);
                     if (params.value == 'Yes') {
                        // console.log("params = ", params.value, params.data);
                         return {backgroundColor: '#00dea7'};
                     } else {
                         return  {backgroundColor: '#d4d4d4', color:'#797979'};
                     }
                 },
                valueSetter: function(params) {
                    if (params.oldValue !== params.newValue) {
                        console.log(params.oldValue,params.newValue);
                        var alteredField = params.colDef.field;
                        var rowdata = params.data;
                        if(params.newValue == "No")
                        {
                            rowdata[alteredField] = 0;
                        }
                        else{
                            rowdata[alteredField] = 1;
                        }
                        redirectURL.post("/consignments/setShiftTimings",{"rowdata":rowdata}).then((response)=>{

                        }).catch((e)=>{
                            console.log(e);
                        });                        

                    }
                }
            },
            {
                headerName:"C Shift",
                field:"source_c_shift",
                width:100,
                filter:true,
                valueGetter : function(params){
                    if(params.data.source_c_shift == 1)
                    {
                        return "Yes";
                    }else{return "No";}
                },
                editable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ['Yes', 'No'],
                },
                cellStyle: function(params) {
                    // console.log("params = ", params.data.is_editable, params.value, params);
                     if (params.value == 'Yes') {
                        // console.log("params = ", params.value, params.data);
                         return {backgroundColor: '#00dea7'};
                     } else {
                         return  {backgroundColor: '#d4d4d4', color:'#797979'};
                     }
                 },
                valueSetter: function(params) {
                    if (params.oldValue !== params.newValue) {
                        console.log(params.oldValue,params.newValue);
                        var alteredField = params.colDef.field;
                        var rowdata = params.data;
                        if(params.newValue == "No")
                        {
                            rowdata[alteredField] = 0;
                        }
                        else{
                            rowdata[alteredField] = 1;
                        }
                        redirectURL.post("/consignments/setShiftTimings",{"rowdata":rowdata}).then((response)=>{

                        }).catch((e)=>{
                            console.log(e);
                        });                        

                    }
                }
            },
            {
                headerName:"Destination",
                field:"destination",
                width:110,
                filter:true,
            },
            {
                headerName:"A Shift",
                field:"destination_a_shift",
                width:100,
                filter:true,
                editable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ['Yes', 'No'],
                },
                valueGetter : function(params){
                    if(params.data.destination_a_shift == 1)
                    {
                        return "Yes";
                    }else{return "No";}
                },
                cellStyle: function(params) {
                    // console.log("params = ", params.data.is_editable, params.value, params);
                     if (params.value == 'Yes') {
                        // console.log("params = ", params.value, params.data);
                         return {backgroundColor: '#00dea7'};
                     } else {
                         return  {backgroundColor: '#d4d4d4', color:'#797979'};
                     }
                 },
                valueSetter: function(params) {
                    if (params.oldValue !== params.newValue) {
                        console.log(params.oldValue,params.newValue);
                        var alteredField = params.colDef.field;
                        var rowdata = params.data;
                        if(params.newValue == "No")
                        {
                            rowdata[alteredField] = 0;
                        }
                        else{
                            rowdata[alteredField] = 1;
                        }
                        redirectURL.post("/consignments/setShiftTimings",{"rowdata":rowdata}).then((response)=>{

                        }).catch((e)=>{
                            console.log(e);
                        });                        

                    }
                }
            },
            {
                headerName:"B Shift",
                field:"destination_b_shift",
                width:100,
                filter:true,
                editable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ['Yes', 'No'],
                },
                valueGetter : function(params){
                    if(params.data.destination_b_shift == 1)
                    {
                        return "Yes";
                    }else{return "No";}
                },
                cellStyle: function(params) {
                    // console.log("params = ", params.data.is_editable, params.value, params);
                     if (params.value == 'Yes') {
                        // console.log("params = ", params.value, params.data);
                         return {backgroundColor: '#00dea7'};
                     } else {
                         return  {backgroundColor: '#d4d4d4', color:'#797979'};
                     }
                 },
                valueSetter: function(params) {
                    if (params.oldValue !== params.newValue) {
                        console.log(params.oldValue,params.newValue);
                        var alteredField = params.colDef.field;
                        var rowdata = params.data;
                        if(params.newValue == "No")
                        {
                            rowdata[alteredField] = 0;
                        }
                        else{
                            rowdata[alteredField] = 1;
                        }
                        redirectURL.post("/consignments/setShiftTimings",{"rowdata":rowdata}).then((response)=>{

                        }).catch((e)=>{
                            console.log(e);
                        });                        

                    }
                }
            },
            {
                headerName:"C Shift",
                field:"destination_c_shift",
                width:100,
                filter:true,
                editable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ['Yes', 'No'],
                },
                valueGetter : function(params){
                    if(params.data.destination_c_shift == 1)
                    {
                        return "Yes";
                    }else{return "No";}
                },
                cellStyle: function(params) {
                    // console.log("params = ", params.data.is_editable, params.value, params);
                     if (params.value == 'Yes') {
                        // console.log("params = ", params.value, params.data);
                         return {backgroundColor: '#00dea7'};
                     } else {
                         return  {backgroundColor: '#d4d4d4', color:'#797979'};
                     }
                 },
                valueSetter: function(params) {
                    if (params.oldValue !== params.newValue) {
                        console.log(params.oldValue,params.newValue);
                        var alteredField = params.colDef.field;
                        var rowdata = params.data;
                        if(params.newValue == "No")
                        {
                            rowdata[alteredField] = 0;
                        }
                        else{
                            rowdata[alteredField] = 1;
                        }
                        redirectURL.post("/consignments/setShiftTimings",{"rowdata":rowdata}).then((response)=>{

                        }).catch((e)=>{
                            console.log(e);
                        });                        

                    }
                }
            },
        ]
        return (
            
    	<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
        {this.state.alert}
            <div className="col-xl-12 col-lg-12">
                <h5>
                    <i className="icofont icofont-wall-clock cus-i"></i> <span>Shift Timings</span>
                </h5>
                <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
                    <AgGridReact
                        modules={this.state.modules}
                        columnDefs={columnDefs}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.rowData}
                        enableCharts={false}
                        enableRangeSelection={false}
                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                        onGridReady={this.onGridReady}
                        onGridState={this.onGridState}
                        statusBar={this.state.statusBar}
                        sideBar={this.state.sideBar}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={true}
                        frameworkComponents={this.state.frameworkComponents}
                        detailCellRendererParams={this.state.detailCellRendererParams}
                        enableRangeSelection={false}
                        //onRowSelected={this.onRowSelected.bind(this)}
                        gridOptions={{
                            context: { componentParent: this }
                        }}
                        suppressCellFlash={true}
                        masterDetail={true}
                        
                    />
                </div>
            </div>
                    

                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box">
                        <div className="loader-box"></div>
                        <div className="rotate dashed colored"></div>
                    </div>
                </div>

            <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            <span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
            <Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
                <div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                    <h5 className="model-title">Shift Calendar</h5>
                    <div className="col-xl-12 col-lg-12 mt-20p">
                        <div className="col-xl-12 col-lg-12">
                            This is available specifically for TNP department mentioning the source and destination shift timings for consignment movement and its related process
                        </div>
                        
                        <div className="col-xl-12 col-lg-12">	
                            Image Here
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
            
        )
    }
}


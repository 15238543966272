import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
// import axios from 'axios';
// import { AgGridReact } from '@ag-grid-community/react';
import {AllModules, AllEnterpriseModules} from '@ag-grid-enterprise/all-modules';
// import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
// import * as Datetime from 'react-datetime';
// import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
// import CSVFileValidator from 'csv-file-validator';
// import Map from '../common/drawmap';
// import FileBase64 from 'react-file-base64';
import redirectURL from '../redirectURL';
// import {
//     Accordion,
//     AccordionItem,
//     AccordionItemHeading,
//     AccordionItemButton,
//     AccordionItemPanel,
// } from 'react-accessible-accordion';
// import MapComponent from './mapLocationComponent';
// import Consignmentforceclose from '../manage/consignmentforceclose';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
// import datetimepicker from 'jquery-datetimepicker';
// import { Responsive } from 'react-grid-layout';
// import { string } from 'prop-types';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

export default class AddLspDashboardDoc extends Component{
    constructor(props) {
        super(props);
          this.state ={
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            frameworkComponents : {
            },
            modified_by:"",
            basicTitle:'',
            reasonforceclose:"",
            bulkslide:"",
            file:"",
            uploadFile:"",
            basicType:"default",
            loadshow:'show-n',
            forceCloseRowNode : "",
            overly:"show-n",
            forceclosedata:"",
            csvcontent:"",
            sliderForceCloseTranslate:"",
            rowData:[],
            staterowData:[],
            showDiv:"",
            uploadDivWidth:"",
            mapinfo:"",
            dealer:"",
            sliderCommentTranslate:"",
            commentsRowData:"",
            consignment_code:"",
            sliderRouteTranslate:"",
            maptruckno:"",
            routeTruck:	"",
            rownode:"",
            googelRoutes:"",
            rowSelection:'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            statusBar:"",
            paginationPageSize:200,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
              toolPanels: [
                {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel"
                },
                {
                id: "filters",
                labelDefault: "Filters",
                labelKey: "filters",
                iconKey: "filter",
                toolPanel: "agFiltersToolPanel"
                }
              ]
            },
            animateRows: true,
            brequest:"activet",
            fetchCarrierOptions: [],
            carrierNo:"",
            DocName:"",
            fetchDocOptions:[],
            documentnumber:"",
            loadFile : "",
            drivingLicence: "",
            registrationcertificate : "",
            insurance : "",
            pollution : "",
            drivingLicenceFile: "",
            registrationcertificateFile : "",
            insuranceFile : "",
            pollutionFile : "",
            transporterOptions : [],
            transporterValue : "",
            tcode: "",
            userLoggedtype: false,
          }

          this.changeTransporterHandler = this.changeTransporterHandler.bind(this)
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    async componentDidMount(){
        this.logPageView();
        loadDateTimeScript();
        await redirectURL.post("/consignments/getCarrierDocTypes").then((response)=>{
            let responseData = response.data
            // console.log("responseData",responseData)
            var docList =[]
            responseData.map(e =>{
                docList.push({"label":e.document_name, "value": e.document_name})
            })
            this.setState({
                fetchDocOptions: docList
            })
        })

        var d = moment.parseZone(new Date()).utcOffset("+05:30")._d;
        var date = moment.parseZone(d).format("YYYY-MM-DD");
        $("#dateField").val(date);
        let transList = []
        let initialTransCode =""
        var userLoggedtype  = false;
        var userType = localStorage.getItem('user_type');
        if(userType === "TRANSPORTER")
        {
            var tptCode = localStorage.getItem('transportercode');
            userLoggedtype = true;
            let tcode = JSON.parse(tptCode)
            this.getCarrierDetails(tcode[0])
            this.setState({
                userLoggedtype : userLoggedtype
            })
        }
        else
        {
            redirectURL.post("/consignments/getSNDGTransporters").then(async (response)=>{
                let sndgTrans = response.data
                // console.log("sndgTrans",sndgTrans)
                sndgTrans.map(e =>{
                    transList.push({
                        label: e.transporter_name,
                        value: e.transporter_code
                    })
                })
                initialTransCode = sndgTrans[0].transporter_code;
                this.setState({
                    transList,
                    userLoggedtype : userLoggedtype
                })
                this.getCarrierDetails(initialTransCode) 
            })
        }           
        
        // var transporterOptions =[];
        // var tptCode = localStorage.getItem('transportercode');
        // if(tptCode !==undefined && tptCode !=='')
        // {
        //     let tcode = JSON.parse(tptCode)
        //     if(tcode.length >1){            
        //         tcode.map(e=>{
        //             transporterOptions.push({"label":e, "value": e})
        //         })
        //         await this.setState({
        //             transporterOptions,
        //         })          
        //     }
        //     else{
        //         console.log("tcode", tcode[0])
        //        await this.setState({
        //             "transporterOptions" : tcode[0],
        //             tcode: tcode[0]
        //         })
        //        await this.getCarrierDetails()
        //     }

        // }
        // else
        // {
        // }
        
        // this.getCarrierDetails()
       

       
    }

    getCarrierDetails =(argument1) =>{
        // const {transporterOptions} = this.state
        // console.log("transporterValue",transporterOptions)

        // var params ={
        //     "transporter_code" : transporterOptions
        // }
        let params ={}
        params["transporter_code"] = argument1
        
        redirectURL.post("/consignments/getCarrierDetails",params).then((response)=>{
            let responseData = response.data;
            if(responseData.length>1){
                let carrierList = []
                responseData.map((item)=>{
                    carrierList.push({
                        label:item,
                        value:item,
                    })
                })
                this.setState({
                    fetchCarrierOptions: carrierList,
                }) 
            }
            // else{
            //     this.setState({
            //         basicTitle:"No Carrier Details Available",
            //         basicType : "warning",
            //         show : true 
            //     })
            // }
        }).catch((e)=>{
            console.log(e);
        })
    }

    changeCarrierHandler = (event ) => {
        this.setState({carrierNo: event});
	}
    changeTransporterHandler = async (event) => {
        this.setState({transporterValue :event, tcode : event.value ,carrierNo : ''})
        this.getCarrierDetails(event.value)
        // if(this.state.transporterOptions.length >1){
        //     await this.getCarrierDetails()
        // }
        
    }

    changeDocHandler =(event) =>{
        this.setState({DocName: event})
    }

    changeFileHandler = async (e) => {
        // console.log("uploadfile",e);        
        this.setState({ [e.target.name]: e.target.files[0] });      
    }
    changeHandler = e =>{
        // console.log("documentnumber", e.target.value)
        this.setState({
            documentnumber : e.target.value
        })
    }

    formHandler =event =>{
        event.preventDefault()
        const {carrierNo,DocName,documentnumber,loadFile,tcode} = this.state
        let date = $("#dateField").val();
        let params ={
            "carrier_no":carrierNo.value,
            "doc_name": DocName.value,
            "doc_number":documentnumber,
            "validity_expiry" : date,
            "loadFile": loadFile,
            "transporter_code" :tcode
        }
        console.log("params", params)
        var userName = localStorage.getItem("username")

        let saveData = new FormData();
        saveData.append("loadFile", loadFile);
        saveData.append("carrier_no",carrierNo.value);
        saveData.append("doc_name",DocName.value);
        saveData.append("doc_number",documentnumber);
        saveData.append("validity_expiry",date);
        saveData.append("transporter_code",tcode);
        saveData.append("userName",userName);

        // console.log("postdata", saveData)
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        });
        if(carrierNo !== undefined&&carrierNo !=="" && DocName !== undefined && DocName !== "" && documentnumber !== undefined && documentnumber !== "" && date !== undefined && date !== ""){
            redirectURL.post("/consignments/addlspCarrierDocs",saveData).then((response)=>{
                if(response.data.status==="Data Already Exists"){
                    this.setState({
                        loadshow:'show-n',
                        overly:'show-n',
                        basicTitle:"Data Already Exists",
                        basicType : "warning",
                        show : true    
                    })
                }
                else{
                    let responseData = response.data;
                    // console.log(responseData,"response")               
                    this.setState({
                        loadshow:'show-n',
                        overly:'show-n',
                        basicTitle:"Success",
                        basicType : "success",
                        show : true    
                    })
                }
            }).catch((e)=>{
                console.log(e);
            })
        }
    }

    onClickHideAll = () =>{
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            bulkslide:"",        
        });
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
	}

    render(){
        const {transList, userLoggedtype} = this.state
        return(
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card">
                        <div className="card-header space-between">
                            <h5 className="float-left">
                                <i className="icon-map cus-i"></i><span> Transporter Details</span>
                            </h5>
                            <div>
                            <a href="/lspcarrierdocuments" className="btn btn-danger pull-right cs-btn">Back</a>
                            </div>
                                         
                        </div>
                        <div className="card-body pt-15px">
                                <form class="aircargo-form" onSubmit={this.formHandler} encType="multipart/form-data">
                                    <div className="row col-xl-12">
                                    {userLoggedtype ? "" : <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Transporter: </label>
                                            <Select 
                                               value={this.state.transporterValue}
                                               onChange={this.changeTransporterHandler}
                                               style={{borderRadius:"0px"}}
                                               options={transList}
                                               placeholder="Select Transporter"
                                               required />
                                        </div>}
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Carrier No: </label>
                                            <Select 
                                               value={this.state.carrierNo} 
                                               onChange={this.changeCarrierHandler} 
                                               style={{borderRadius:"0px"}} 
                                               options={this.state.fetchCarrierOptions}
                                               placeholder="Select Carrier No"
                                               required />
                                        </div>
                                                                              	
                                    </div>                                    
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12">Doc Name* : </label>
                                                <Select 
                                                value={this.state.DocName} 
                                                onChange={this.changeDocHandler} 
                                                style={{borderRadius:"0px"}} 
                                                options={this.state.fetchDocOptions}
                                                placeholder="Select Doc Name"
                                                required />
                                            </div>                                      
                                    </div>
                                    {/* upload options */}
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Document Number* : </label>
                                            <input type="text" name="documentno" value={this.state.documentnumber} className="form-control"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Validity/Expiry Date* : </label>
                                            <input type="text" name="date" id="dateField" className="datetimepicker_date form-control" required />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Upload File</label> 
                                            <input type="file" name="loadFile" id="uploadFile" 
                                            onChange={this.changeFileHandler}  
                                            className="form-control" required />
                                        </div>                                       
                                    </div>                                   
                                    <div class={"row col-sm-12 "}>
                                        <div className="row col-xl-12 m-top-1">
                                            <div class="col-xl-12">
                                            <button type="submit" className="btn btn-success" style={{marginLeft:"45%",width:"192px",marginTop:"20px"}}>Submit</button>
                                            </div>
                                        </div>
                                    </div>                                
                                </form>
                            </div>

                    </div>
                </div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll}></div>
            </div>
        )
    }
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	// console.log(date.length);
	// console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	// console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y'
	});
	$('.datetimepicker_date').datetimepicker({
		// mask:'39-19-9999',
		format:'Y-m-d',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
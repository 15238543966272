import React, { Component } from "react";
import moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from "@ag-grid-community/react";
import Select from "react-select";
import { getHyphenDDMMMYYYY} from '../common/utils';
import $ from "jquery";
import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import DrawMap2 from '../common/drawmap2';
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import * as Datetime from 'react-datetime';
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import download from 'js-file-download';
var redirectURL = require("../redirectURL");

export default class FormPageForKownledgeBank extends Component {
    constructor(props) {
        super(props);
        this.state = {
          formdata:'',
          griddata:'activet',
          pending_approval:'',
          totalManHourSavings:0,
          totalCostSavings:0,
          name: localStorage.getItem("username"),
          // department: '',
          // division: localStorage.getItem("dept_code"),
          divisions: {
            'SND': ['SND-G', 'SND-M', 'SND-FNC','LOG-RLY','SND-PZ'],
            'WHS-GJB': ['SND-GJ','SND-B', 'TVP-B'],
            'LOG-EI': ['LOG-IMP', 'LOG-EXP'],
            'PPS': ['LOG-SPL', 'LOG-PI'],
            'DBC': ['LOG-DBC']
        },
          selectedDivision: '',
          departments: [],
          selectedDepartment: '',
          implementationDate: '',
          topic: '',
          manhourSavings: '',
          category:'',
          costSavings: '',
          benefits: '',
          Ringi_num:'',
          show: false,
        basicTitle: "",
        basicType: "default",
        overly: "show-n",
        loadshow: "show-n",
        files: [],
        rowData: [],
        rowData2:[],
        rowId: "",
        buttonType:'Save',
        category:'',
        alert: null,
     
        };
        this.gridOptions = {
          rowHeight: 40,
          headerHeight: 50,
          getRowStyle: params => ({
            backgroundColor: params.node.rowIndex % 2 === 0 ? '#ebebeb' : '#ffffff'
          }),
          getHeaderCellTemplate: function(params) {
            var eHeader = document.createElement('div');
            eHeader.className = 'bold-header';  // Apply the class here
            eHeader.innerHTML = '<div class="ag-cell-label-container" role="presentation">' +
              '<span class="ag-header-cell-label" role="presentation">' +
              '<span class="ag-header-cell-text" role="columnheader">' + params.displayName + '</span>' +
              '</span>' +
              '</div>';
            return eHeader;
          }
        };
        
        this.handleFileChange = this.handleFileChange.bind(this);
      }

      async componentDidMount() {
        try {
          console.log(this.props.match.path,'props.match.path')
          if(this.props.match.path == '/knowledgebankpendingscreen'){
            this.setState({
              formdata:'',
              griddata:'',
              pending_approval:'activet',
            })
          }
            const response = await redirectURL.post("/consignments/knowledgebankdataOnMount");
            console.log(response.data, 'response');
            
            let count1 = 0;
            let count2 = 0;
    
            var filterDataforGridData = response.data.filter((e)=>e.status == 'Approved')
            var filterDataforGridData2 = response.data.filter((e)=>e.status != 'Approved')
            filterDataforGridData.forEach(ele => {
              count1 += ele.manhourSavings;
              count2 += ele.costSavings;
            });
            this.setState({
                rowData: filterDataforGridData,
                rowData2:filterDataforGridData2,
                totalManHourSavings: count1,
                totalCostSavings: count2
            });
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error if necessary
        }
    }
    
    
      handleChange = async(e) => {
        // console.log(e.target,'target value')
        this.setState({ [e.target.name]: e.target.value });
      }
      changeCategory = async(e) => {
        console.log(e.target,'target value',e.target.name,'e.target.name',e.target.value,'e.target.value')
        this.setState({ category: e.target.value });
      }
      formHandler = async (e) => {
        e.preventDefault();
        this.setState({
          loadshow: "show-m",
          overly: "show-m",
        })
        // console.log(this.state.category,'category')
        if(this.state.buttonType == 'Edit'){
          var formData = new FormData();
          console.log('inside handler')
         var name = this.state.name;
         var department = this.state.selectedDepartment;
         var division= this.state.selectedDivision;
          var implementationDate = this.state.implementationDate;
          var topic = this.state.topic;
         var description = this.state.description;
          var manhourSavings = parseFloat(this.state.manhourSavings);
          var costSavings = parseFloat(this.state.costSavings);
          var benefits = this.state.benefits;
          var Ringi_num = this.state.Ringi_num;
          var files = this.state.files;
          var buttonType = this.state.buttonType
          var rowId = this.state.rowId
          var category = this.state.category
          formData.append('name', name)
          formData.append('department', department)
          formData.append('division', division)
          formData.append('implementationDate', implementationDate)
          formData.append('topic', topic)
          formData.append('description', description)
          formData.append('manhourSavings', manhourSavings)
          formData.append('costSavings', costSavings)
          formData.append('benefits', benefits)
          formData.append('Ringi_num', Ringi_num)
          formData.append('files', files[0])
          formData.append('buttonType', buttonType)
          formData.append('rowId', rowId)   
          formData.append('category', category) 


          await redirectURL
          .post("/consignments/knowledgebankdata",formData)
          .then((response) => {
            if (response && response.data && response.data.data === "success") {
              console.log(response.data.data, 'response data');
              this.setState({
                show: true,
                loadshow: "show-n",
                overly: "show-n",
                basicType: "success",
                basicTitle: "Edited Successfully",
                name: localStorage.getItem("username"),
                divisions: {
                  'SND': ['SND-G', 'SND-M', 'SND-FNC','LOG-RLY','SND-PZ'],
                  'WHS-GJB': ['SND-GJ','SND-B', 'TVP-B'],
                  'LOG-EI': ['LOG-IMP', 'LOG-EXP'],
                  'PPS': ['LOG-SPL', 'LOG-PI'],
                  'DBC': ['LOG-DBC']
              },
                selectedDivision: '',
                departments: [],
                selectedDepartment: '',
                implementationDate: '',
                topic: '',
                description: '',
                manhourSavings: '',
                costSavings: '',
                category:'',
                benefits: '',
                Ringi_num:'',
                files:[],
                formdata: '',
              griddata: '',
              pending_approval:'activet',
              })
              $("#uploadFile").val("")
              this.componentDidMount()
            }else{
              this.setState({
                show: true,
                loadshow: "show-n",
                overly: "show-n",
                basicType: "warning",
                basicTitle: "Something went wrong!!"
              })
            }
  
          }).catch(error => {
            console.error('Error during form submission:', error);
        })
        }else if(this.state.buttonType == "Save"){

          var formData = new FormData();
          console.log('inside handler')
         var name = this.state.name;
         var department = this.state.selectedDepartment;
         var division= this.state.selectedDivision;
          var implementationDate = this.state.implementationDate;
          var topic = this.state.topic;
         var description = this.state.description;
          var manhourSavings = parseFloat(this.state.manhourSavings);
          var costSavings = parseFloat(this.state.costSavings);
          var benefits = this.state.benefits;
          var Ringi_num = this.state.Ringi_num;
          var files = this.state.files[0]
          var buttonType = this.state.buttonType
          var category = this.state.category
  
          formData.append('name', name)
          formData.append('department', department)
          formData.append('division', division)
          formData.append('implementationDate', implementationDate)
          formData.append('topic', topic)
          formData.append('description', description)
          formData.append('manhourSavings', manhourSavings)
          formData.append('costSavings', costSavings)
          formData.append('benefits', benefits)
          formData.append('Ringi_num', Ringi_num)
          formData.append('files', files)
          formData.append('buttonType', buttonType) 
          formData.append('category', category) 
          await redirectURL
          .post("/consignments/knowledgebankdata",formData)
          .then((response) => {
            if (response && response.data && response.data.data === "success") {
              console.log(response.data.data, 'response data');
              this.setState({
                show: true,
                loadshow: "show-n",
                overly: "show-n",
                basicType: "success",
                basicTitle: "Saved Successfully",
                name: localStorage.getItem("username"),
                divisions: {
                  'SND': ['SND-G', 'SND-M', 'SND-FNC','LOG-RLY','SND-PZ'],
                  'WHS-GJB': ['SND-GJ','SND-B', 'TVP-B'],
                  'LOG-EI': ['LOG-IMP', 'LOG-EXP'],
                  'PPS': ['LOG-SPL', 'LOG-PI'],
                  'DBC': ['LOG-DBC']
              },
                selectedDivision: '',
                departments: [],
                selectedDepartment: '',
                implementationDate: '',
                topic: '',
                description: '',
                manhourSavings: '',
                category:'',
                costSavings: '',
                benefits: '',
                Ringi_num:'',
                files:[],
              })
              $("#uploadFile").val("")
            }else{
              this.setState({
                show: true,
                loadshow: "show-n",
                overly: "show-n",
                basicType: "warning",
                basicTitle: "Something went wrong!!"
              })
            }
  
          }).catch(error => {
            console.error('Error during form submission:', error);
        })
        }
    }
      
  //   handleFileChange1 = (e) => {
  //     console.log(e[0], 'first files'); // Logs the first selected files
  //     if (e.length > 0) {
  //         var files = e; // Access the first files if available
  //         this.setState({files: files }); // Set it in state
  //     }
  //     console.log(this.state.files,'filessssss')
  // }
    handleFileChange = (e) => {
      console.log(e.target.files, 'first files'); // Logs the first selected files
      if (e.target.files.length > 0) {
          var files = e.target.files; // Access the first files if available
          this.setState({files: files }); // Set it in state
      }
  }
  

    handleFileDelete = () => {
        this.setState({ files: [] });
        $("#uploadFile").val("")
    }
      closeAlert = () => {
        this.setState({
          show: false,
        });
      };

      onClickHideAll() {
        this.setState({
          loadshow: "show-n",
          overly: "show-n"
        });
      }
      clearform = async () => {
       
        if(this.state.buttonType == 'Edit'){
          this.setState({
            name: localStorage.getItem("username"),
            divisions: {
              'SND': ['SND-G', 'SND-M', 'SND-FNC','LOG-RLY','SND-PZ'],
              'WHS-GJB': ['SND-GJ','SND-B', 'TVP-B'],
              'LOG-EI': ['LOG-IMP', 'LOG-EXP'],
              'PPS': ['LOG-SPL', 'LOG-PI'],
              'DBC': ['LOG-DBC']
          },
            selectedDivision: '',
            departments: [],
            selectedDepartment: '',
            implementationDate: '',
            topic: '',
            description: '',
            manhourSavings: '',
            category:'',
            costSavings: '',
            benefits: '',
            Ringi_num:'',
            files:[],
            formdata: '',
            griddata: '',
            pending_approval:'activet'
          })
          $("#uploadFile").val("")
        }else{
          this.setState({
            name: localStorage.getItem("username"),
            divisions: {
              'SND': ['SND-G', 'SND-M', 'SND-FNC','LOG-RLY','SND-PZ'],
              'WHS-GJB': ['SND-GJ','SND-B', 'TVP-B'],
              'LOG-EI': ['LOG-IMP', 'LOG-EXP'],
              'PPS': ['LOG-SPL', 'LOG-PI'],
              'DBC': ['LOG-DBC']
          },
            selectedDivision: '',
            departments: [],
            selectedDepartment: '',
            implementationDate: '',
            topic: '',
            description: '',
            manhourSavings: '',
            category:'',
            costSavings: '',
            benefits: '',
            Ringi_num:'',
            files:[],
          })
          $("#uploadFile").val("")
        }
      }
      handleKeyPressCode = (event) => {
        const inputChar = String.fromCharCode(event.charCode);
        if (!/^[0-9.]*$/.test(inputChar)) {
          event.preventDefault(); // Prevent the character from being entered
        }
      };
      onClickTab(clickedTab) {
        console.log(clickedTab, 'clickedTab');
        let formdata = "";
        let griddata = "";
        let pending_approval = "";
        let buttonType = 'Save';
    
        if (clickedTab === 'formdata') {
          formdata = "activet";
          this.componentDidMount()
        } else if (clickedTab === "griddata") {
          griddata = "activet";
          this.componentDidMount()
        } else if (clickedTab === "pending_approval") {
          griddata = "";
          pending_approval = "activet";
          this.componentDidMount();
        }
    
        this.setState({
          formdata: formdata,
          griddata: griddata,
          pending_approval: pending_approval,
          buttonType:buttonType
        });
      }
      async onCellClicked(event) {
        if (event.colDef.field == "ppt_file") {
          var fileName = event.data.ppt_file
          console.log(fileName,"496")
          if (fileName != "") {
            var reqparams = {
              ppt_file: fileName,
            }
           await redirectURL.post("/consignments/downloadpptfile", reqparams, {
              responseType: 'blob'
            }).then((response) => {
              // console.log("download response hub",response)
              download(response.data, fileName[0].name)
    
            }).catch(function (error) {
              console.log(error);
            })
          }
        }
        if (event.colDef.field === "Edit") {
          const rowData = event.data;
          const departments = this.state.divisions[rowData.division] || [];
          const formattedDate = moment(rowData.implementationDate).format("YYYY-MM-DD");
  
          this.setState({
              buttonType: 'Edit',
              rowId: rowData._id,
              name: rowData.name,
              selectedDivision: rowData.division,
              departments: departments,
              selectedDepartment: rowData.department,
              implementationDate: formattedDate,
              topic: rowData.topic,
              description: rowData.description,
              manhourSavings: (rowData.manhourSavings != null && rowData.manhourSavings != "" && rowData.manhourSavings != undefined ? rowData.manhourSavings.toString() : ""),
              costSavings: (rowData.costSavings != null && rowData.costSavings != undefined && rowData.costSavings != "" ? rowData.costSavings.toString() : ""),
              benefits: rowData.benefits,
              Ringi_num: rowData.Ringi_num,
              category:rowData.category,
              formdata: 'activet',
              griddata: '',
              pending_approval:'',
          });
      }
      if (event.colDef.field === "approve") {
        var rowId = event.data._id
        var params = {
          rowId : rowId,
          status : "Approved",
          // is_approved : 1,
        }
        this.setState({ alert: (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, approve it!"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            // title="Are you sure?"
            onConfirm={() => {
              this.setState({ alert: null });
              redirectURL.post("/consignments/approveknowledgebankentry", params)
                .then((response) => {
                  this.setState({
                    loadshow: "show-n",
                    overly: "show-n",
                    show: true,
                    basicTitle: "Approved Successfully",
                    basicType: 'success'
                  });
                  this.componentDidMount(); 
                })
                .catch((error) => {
                  console.error("Error Approving:", error);
                });
            }}
            onCancel={() => {
              this.setState({ alert: null });
              console.log("Approving cancelled.");
            }}
          >
          Are you sure?<br/>Once Approved, you will not be able to edit or reject this data
          </SweetAlert>
        )});
    }
    if (event.colDef.field === "reject") {
      var rowId = event.data._id
      var params = {
                  rowId : rowId,
                  status : "Rejected",
                }
            this.setState({ alert: (
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, reject it!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                // title="Are you sure?"
                onConfirm={() => {
                  this.setState({ alert: null });
                  redirectURL.post("/consignments/rejectknowledgebankentry", params)
                    .then((response) => {
                      this.setState({
                        loadshow: "show-n",
                        overly: "show-n",
                        show: true,
                        basicTitle: "Rejected Successfully",
                        basicType: 'success'
                      });
                      this.componentDidMount(); 
                    })
                    .catch((error) => {
                      console.error("Error Rejecting:", error);
                    });
                }}
                onCancel={() => {
                  this.setState({ alert: null });
                  console.log("Rejection cancelled.");
                }}
              >
              Are you sure?<br/>Once Rejected, you will not be able to recover this data
              </SweetAlert>
            )});
            }
      }

      handleDivisionChange = (event) => {
        const selectedDivision = event.target.value;
        const departments = this.state.divisions[selectedDivision] || [];
        this.setState({ selectedDivision, departments, selectedDepartment: '' });
    }

    handleDepartmentChange = (event) => {
        this.setState({ selectedDepartment: event.target.value });
    }
      render() {
        const gridOptions = {
          rowHeight: 50,
      };
        const batchwisecolumns = [
          {
            headerName: 'S No.',
            valueGetter: "node.rowIndex + 1",
            width: 80,
            filter: false, 
            resizable: true
        },
        {
          headerName: "Edit",
          field: "Edit",
          width: 110,
          resizable: true,
          editable: false,
          hide:(this.state.pending_approval == "activet" ? false : true),
          cellRenderer: function(params){
            if (params.data.status === "Pending") {
              return `<button style="background: #eaeaff; border: 1px solid #3b3bff; color: #3b3bff; text-align: center; text-decoration: none; display: inline-block; font-size: 14px; margin: 4px 2px; cursor: pointer; border-radius:10px; width:90px;">Edit</button>`;
            } else {
              return `<button style="background: #ddd; border: 1px solid #aaa; color: #aaa; text-align: center; text-decoration: none; display: inline-block; font-size: 14px; margin: 4px 2px; cursor: not-allowed; border-radius:10px; width:90px;" disabled>Edit</button>`;
            }
          },
          filter: false,
        },
        {
          headerName: "Approve",
          field: "approve",
          width: 109,
          hide:(this.state.pending_approval == "activet" ? false : true),
          cellRenderer: function(params){
            if (params.data.status === "Pending") {
              return `<button style="background: #c3e6cb; border: 1px solid #28a745; color: #28a745; text-align: center; text-decoration: none; display: inline-block; font-size: 14px; margin: 4px 2px; cursor: pointer; border-radius:10px; width:90px;">Approve</button>`;
            } else {
              return `<button style="background: #ddd; border: 1px solid #aaa; color: #aaa; text-align: center; text-decoration: none; display: inline-block; font-size: 14px; margin: 4px 2px; cursor: not-allowed; border-radius:10px; width:90px;" disabled>Approve</button>`;
            }
          },
        },
      
        {
          headerName: "Reject",
          field: "reject",
          width: 109,
          hide:(this.state.pending_approval == "activet" ? false : true),
          cellRenderer: function(params){
            if (params.data.status === "Pending") {
              return `<button style="background: #f3bbc0; border: 1px solid #dc3545; color: #dc3545; text-align: center; text-decoration: none; display: inline-block; font-size: 14px; margin: 4px 2px; cursor: pointer; border-radius:10px; width:90px;">Reject</button>`;
            } else {
              return `<button style="background: #ddd; border: 1px solid #aaa; color: #aaa; text-align: center; text-decoration: none; display: inline-block; font-size: 14px; margin: 4px 2px; cursor: not-allowed; border-radius:10px; width:90px;" disabled>Reject</button>`;
            }
          },
        },
          {
            headerName: 'Name',
            field: "name",
            width: 130,
            tooltipField:"name",
            filter: false, resizable: true
    
          },
          {
            headerName: 'Department',
            field: "department",
            width: 130,
            tooltipField:'department',
            filter: false, resizable: true
    
          },
          {
            headerName: 'Implementation Date',
            field: "implementationDate",
            width: 130,
            filter: false, resizable: true,
            valueGetter: (param) => {
                  if (param.data.implementationDate !== "" && param.data.implementationDate !== undefined) {
                    return getHyphenDDMMMYYYY(param.data.implementationDate);
                  } else {
                    return "";
                  }
                },
    
          },
          {
            headerName: 'Division',
            field: "division",
            width: 130,
            tooltipField:'division',
            filter: false, resizable: true
    
          },
          {
            headerName: 'Topic',
            field: "topic",
            width: 130,
            tooltipField:"topic",
            filter: false, resizable: true
    
          },
          {
            headerName: 'Breif Description',
            field: "description",
            width: 130,
            tooltipField:"description",
            filter: false, resizable: true
    
          },
          {
            headerName: 'Ringi Number',
            field: "Ringi_num",
            width: 130,
            tooltipField:'Ringi_num',
            filter: false, resizable: true
    
          },
          {
            headerName: 'Category',
            field: "category",
            width: 130,
            tooltipField:'category',
            filter: false, resizable: true
    
          },
          {
            headerName: 'Manhour savings/annum (Hrs/yr)',
            field: "manhourSavings",
            width: 130,
            tooltipField:'manhourSavings',
            filter: false, resizable: true
    
          },
    
          {
            headerName: 'Cost savings/annum (Million Rs/yr)',
            field: "costSavings",
            width: 130,
            tooltipField:'costSavings',
            filter: false, resizable: true
    
          },
          {
            headerName: 'Qualitative benefits',
            field: "benefits",
            width: 130,
            tooltipField:"benefits",
            filter: false, resizable: true
    
          },
          {
            headerName: 'Details',
            field: "ppt_file",
            width: 130,
            filter: false,
            resizable: true,
            cellRenderer: function (params) {
              if (params.data.ppt_file && params.data.ppt_file.length > 0) {
                var htmloption = '<button style="background: #74AB8E33 0% 0% no-repeat padding-box; border: 1px solid #3C7E5B99; color: #3C7E5B;  text-align: center; text-decoration: none; display: inline-block; font-size: 14px; margin: 4px 2px; cursor: pointer; border-radius:10px;">Download</button>';
                return htmloption;
              } else {
                return '';
              }
            }
        },
        {
          headerName: 'Status',
          field:'status',
          width:150,
          filter:false,
          tooltipField:'status',
          hide:(this.state.pending_approval == "activet" ? false : true),
          valueGetter : function(params)
			  {
          if(params.data.status == "Pending"){
            return  'Pending for Approval'
          }else{
            return params.data.status
          }
			  }
        }
         
          
        ];
        var styleForInput = {
            border: '1px solid #DADEE2',width: '100%', padding: '8px', borderRadius: '7px'
        }
        var fontStyle = {
            textAlign: "left",
            font: "normal normal medium 14px/20px Public Sans",
            letterSpacing: "0px",
            color: "#5A697D",
            opacity: '1',
        }
       
        return (
            <div class="container-fluid" style={{marginTop:'-50px'}}>
            <SweetAlert
              show={this.state.show}
              type={this.state.basicType}
              title={this.state.basicTitle}
              onConfirm={this.closeAlert}
            ></SweetAlert>
             <div>
              {this.state.alert}
            </div>
            <h6 style={{ marginBottom: '20px',marginTop:'20px', marginLeft:'35px', textAlign: "left",
            font: "normal normal 600 20px/20px Public Sans",
            color: "#245B53",
            letterSpacing: "0px",
            opacity: '1' }}>Logistics Knowledge Bank</h6>
            <div className="col-xl-12 col-lg-12" style={{marginLeft:'20px'}}>
            <div className="f16" style={{ marginBottom: "10px" }}>
                      <ul className="d-tabs">
                        <li onClick={() => this.onClickTab("griddata")} className={"lttabs " + (this.state.griddata)}>
                          <button type="button" className="btn">Knowledge Dashboard</button>
                        </li>
                        <li onClick={() => this.onClickTab("formdata")} className={"lttabs " + (this.state.formdata)}>
                          <button type="button" className="btn">Input Data</button>
                        </li>
                        <li onClick={() => this.onClickTab("pending_approval")} className={"lttabs " + (this.state.pending_approval)}>
                          <button type="button" className="btn">Pending Approval</button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {(this.state.griddata  == "activet")?
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'space-around',marginTop:'20px'}}>
                  <div style={{marginRight:'30px',font: "normal normal 600 20px/20px Public Sans",
            color: "#245B53"}}>{getCurrentFiscalYear()}</div>
                  <div style={{display:'flex',flexDirection:'row'}}>
                    <h5 style={{marginRight:'10px',font: "normal normal 600 20px/20px Public Sans",
            color: "#245B53"}}>Total cost saving/Annum (Million Rs):
                    </h5>
                    <h5 style={{marginRight:'40px',font: "normal normal 600 20px/20px Public Sans",
            color: "#245B53"}}>{this.state.totalCostSavings > 0 ? this.state.totalCostSavings : 0}
                    </h5>
                 </div>
                 <div style={{display:'flex',flexDirection:'row'}}>
                    <h5 style={{marginRight:'10px',font: "normal normal 600 20px/20px Public Sans",
            color: "#245B53"}}>Manhour savings/annum (hrs):
                    </h5>
                    <h5 style={{font: "normal normal 600 20px/20px Public Sans",
            color: "#245B53",}}>{this.state.totalManHourSavings > 0 ? this.state.totalManHourSavings : 0}
                    </h5>
                   </div>
                  </div> : ""}
            {this.state.formdata == "activet" ? <form onSubmit={this.formHandler}>
          <div style={{ padding: '20px', fontFamily: 'Arial' }}>
           
            <div className="col-xl-12 col-lg-12 ">
            <div className="card">
            
            <div className="card-body">
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <div style={{ flex: 1, paddingRight: '10px' }}>
                  <label style={fontStyle}>Name<span style={{ color: 'red' }}> *</span></label><br />
                  <input className="col-lg-10 col-sm-10"
                    type="text"
                    name="name"
                    readOnly
                    autocomplete="off"
                    value={this.state.name}
                    onChange={this.handleChange}
                    style={styleForInput}
                    required />
                </div>
                <div style={{ flex: 1, paddingRight: '10px' }}>
                    <label>Division<span style={{ color: 'red' }}> *</span></label><br />
                    <select className="col-lg-10 col-sm-10"
                        name="division"
                        value={this.state.selectedDivision}
                        onChange={this.handleDivisionChange}
                        style={{ border: '1px solid #DADEE2', width: '100%', padding: '13px', borderRadius: '7px' }}
                        required>
                        <option value="">Select</option>
                        {Object.keys(this.state.divisions).map(key => (
                            <option key={key} value={key}>{key}</option>
                        ))}
                    </select>
                </div>
                <div style={{ flex: 1, paddingRight: '10px' }}>
                    <label>Select Department<span style={{ color: 'red' }}> *</span></label><br />
                    <select className="col-lg-10 col-sm-10"
                        name="department"
                        value={this.state.selectedDepartment}
                        onChange={this.handleDepartmentChange}
                        style={{ border: '1px solid #DADEE2', width: '100%', padding: '13px', borderRadius: '7px' }}
                        required>
                        <option value="">Select</option>
                        {this.state.departments.map(department => (
                            <option key={department} value={department}>{department}</option>
                        ))}
                    </select>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ flex: 1, marginBottom: '10px' }}>
                <label style={fontStyle}>Implementation Date<span style={{ color: 'red' }}> *</span></label><br />
                <input
                  className="col-lg-10 col-sm-10"
                  type="date"
                  autoComplete="off"
                  name="implementationDate"
                  value={this.state.implementationDate}
                  onChange={this.handleChange}
                  style={styleForInput}
                  required
                  onKeyDown={(e) => e.preventDefault()}  // This prevents typing in the field
                  onFocus={(e) => e.target.blur()}       // Optional: This will blur the field if clicked, removing keyboard focus
                />
              </div>
              <div style={{ flex: 1 , marginBottom: '10px' }}>
                <label style={fontStyle}>Topic<span style={{ color: 'red' }}> *</span></label><br />
                <input className="col-lg-10 col-sm-10"
                  type="text"
                  name="topic"
                  autocomplete="off"
                  value={this.state.topic}
                  onChange={this.handleChange}
                  style={styleForInput}
                  required/>
              </div>
              <div style={{ flex: 1 , marginBottom: '10px' }}>
                <label style={fontStyle}>Ringi Number</label><br />
                <input className="col-lg-10 col-sm-10"
                  type="text"
                  name="Ringi_num"
                  autocomplete="off"
                  value={this.state.Ringi_num}
                  onChange={this.handleChange}
                  style={styleForInput}
                />
              </div>
              </div>
              <div>
                    <label>Category<span style={{ color: 'red' }}> *</span></label><br />
                    
                       <select className={"col-xl-4 col-lg-4 form-control border-radius-0"}
                       placeholder={"Category"}
                       closeMenuOnSelect={true}
                      value={this.state.category}
                       style={{borderRadius:"7px",width:'28%',border:'1px solid rgb(218, 222, 226)'}}
                       onChange={this.changeCategory}
                    ><option value="">--Select--</option>
                    <option value="Quality Improvement">Quality Improvement</option>
                    <option value="5S">5S</option>
                    <option value="Safety">Safety</option>
                    <option value="Financial/ Tax related">Financial/ Tax related</option>
                    <option value="System/ Process improvement">System/ Process improvement</option>
                    <option value="Customer Satisfaction">Customer Satisfaction</option>
                    <option value="Improvement at LSP / Supplier end">Improvement at LSP / Supplier end</option> </select>
                </div>
              <div style={{ marginBottom: '10px' }}>
                <label style={fontStyle}>Brief Description<span style={{ color: 'red' }}> *</span></label><br />
                <textarea 
                  type="text"
                  name="description"
                  autocomplete="off"
                  value={this.state.description}
                  onChange={this.handleChange}
                  style={{ border: '1px solid #DADEE2',width: '95%', padding: '8px', borderRadius: '7px'}}
                  required/>
              </div>
              <h6 style={{ marginBottom: '20px', textAlign: "left",
            font: "normal normal 600 20px/20px Public Sans",
            letterSpacing: "0px",
            color: "#245B53",
            opacity: '1', }}>Benefits</h6>
              <div class="hr-dashed" style={{marginTop:'-10px'}}></div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <div style={{ flex: 1, paddingRight: '10px' }}>
                  <label style={fontStyle}>Manhour savings/annum (Hrs/yr)<span style={{ color: 'red' }}> *</span></label><br />
                  <input className="col-lg-10 col-sm-10"
                    type="text"
                    autocomplete="off"
                    onKeyPress={this.handleKeyPressCode}
                    name="manhourSavings"
                    value={this.state.manhourSavings}
                    onChange={this.handleChange}
                    style={styleForInput}
                    required
                  />
                </div>
                <div style={{ flex: 1, paddingRight: '10px' }}>
                  <label style={fontStyle}>Cost savings/annum (Million Rs/yr)<span style={{ color: 'red' }}> *</span></label><br />
                  <input className="col-lg-10 col-sm-10"
                    type="text"
                    name="costSavings"
                    autocomplete="off"
                    onKeyPress={this.handleKeyPressCode}
                    value={this.state.costSavings}
                    onChange={this.handleChange}
                    style={styleForInput}
                    required
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <label style={fontStyle}>Qualitative Benefits<span style={{ color: 'red' }}> *</span></label><br />
                  <input className="col-lg-10 col-sm-10"
                    type="text"
                    name="benefits"
                    autocomplete="off"
                    value={this.state.benefits}
                    onChange={this.handleChange}
                    style={styleForInput}
                    required
                  />
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
             
                  {/* <div>
                        <label>Upload PPT/Doc*</label>
                  <div style={{display:'flex',flexDirection:'row'}}>
                        <input type="file" onChange={this.handleFileChange} name="uploadFile" id='uploadFile' style={{ display: 'block', marginBottom: '10px' }} required/>
                        {this.state.files.length>0 && (
                            <div>
                                <span>{this.state.files.name}</span>
                                <button onClick={this.handleFileDelete} style={{ marginLeft: '10px', cursor: 'pointer',backgroundColor: '#05428C', color: 'white', border: 'none',borderRadius:'30px' }}>X</button>
                            </div>
                        )}
                  </div>
                </div> */}
                 <div>
                {this.state.buttonType == "Edit" ? <label>Upload PPT/Doc</label> :
                <label>Upload PPT/Doc<span style={{ color: 'red' }}> *</span> </label> 
                }
           <div style={{display:'flex',flexDirection:'row'}}>
               { this.state.buttonType == "Edit" ? <input type="file" onChange={this.handleFileChange} name="uploadFile" id='uploadFile' style={{ display: 'block', marginBottom: '10px' }} /> :<input type="file" onChange={this.handleFileChange} name="uploadFile" id='uploadFile' style={{ display: 'block', marginBottom: '10px' }} required/> }
                 {this.state.files.length>0 && (
                     <div>
                         <span>{this.state.files.name}</span>
                         <button onClick={this.handleFileDelete} style={{ marginLeft: '10px', cursor: 'pointer',backgroundColor: '#05428C', color: 'white', border: 'none',borderRadius:'30px' }}>X</button>
                     </div>
                 )}
           </div>
          
                 <div >
               <a href={require("../../assets/json/Best Practice Template.pptx")} target="_blank">
           <i className="fa fa-download" ></i>
           Sample Template</a>
           </div><h6 style={{color:'red'}} >Note : please use the template provided below to elaborate the solution</h6>
         </div>
            <div style={{marginRight:'90px'}}>
              <button type="submit" style={{width:'134px',float:'right', padding: '10px 20px', margin: '10px 0', backgroundColor: '#05428C', color: 'white', border: 'none', cursor: 'pointer',borderRadius:'7px' }}>
                Save
              </button>
                 <button type="button" onClick={this.clearform} style={{width:'134px',float:'right', padding: '10px 20px', margin: '10px 10px', border:"3px solid #05428C", color: '#05428C', border: 'none', cursor: 'pointer',borderRadius:'7px'  }}>
                Cancel
              </button>
            </div>
              </div>
            </div>
            </div>
            </div>
          </div>
          </form> :
           <div className="col-xl-12 col-lg-12">
           <div className="card-body">
                <div className="row">
                  <div
                    id="myGrid"
                    style={{ width: "100%", height: "478px" }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                          modules={AllModules}
                          columnDefs={batchwisecolumns}
                          defaultColDef={{
                              resizable: true,
                              filter: true,
                          }}
                          rowData={this.state.griddata === "activet" ? this.state.rowData : this.state.rowData2}
                          gridOptions={this.gridOptions}
                          onGridReady={this.onGridReady}
                          onCellClicked={this.onCellClicked.bind(this)}
                          paginationPageSize={10}
                          pagination={true}
                          className="custom-ag-grid" // Add a custom class for more specific styling
                          style={{ 
                              height: '500px', 
                              width: '100%', 
                              border: 'none !important',
                               // Remove borders
                              backgroundColor: 'transparent !important', // Remove header background color
                          }}
                      />
                  </div>
                </div>
                </div>
                </div>
                }
         
          <div className={"dataLoadpage " + (this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
          <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>
       
          
        );
      }
      
    }

    function getCurrentFiscalYear() {
      const currentDate = new Date();
      const fiscalYearStartMonth = 3; // Fiscal year starts in April
      let currentFiscalYear;
    
      // Determine the fiscal year based on the current date
      if (currentDate.getMonth() < fiscalYearStartMonth) {
        currentFiscalYear = currentDate.getFullYear() - 1;
      } else {
        currentFiscalYear = currentDate.getFullYear();
      }
    
      // Calculate the fiscal year ending
      const nextFiscalYear = currentFiscalYear + 1;
    
      // Format as "FY YY-YY"
      const fiscalYearString = `FY ${currentFiscalYear.toString().slice(2)}-${nextFiscalYear.toString().slice(2)}`;
    
      console.log(fiscalYearString); // This will log something like "FY 24-25"
      return fiscalYearString;
    }
'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import UpdateButton from "./updatelmstates";
import UpdateTransitButton from "./updatetransitbutton";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenDDMMYYYY } from '../common/utils';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var CELL_DIMENSION_SIZE = 90;
var googleAnalytics = require("../common/googleanalytics");

export default class RakeStockStatus extends Component {

    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            pageTitle: "Empty Truck Vicinity",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            showform: 'show-m',
            loadshow: 'show-n',
            showrefresh: 'show-n',
            sliderTranslate: '',
            showdata: this.displayData,
            modules: AllModules,
            radius: '15',
            rlatitude: '',
            rlongitude: '',
            latitude: '',
            longitude: '',
            defaultradius: 0,
            coordinates: [],
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            contentString: "testing",
            mwidth: "col-xl-12 col-lg-12",
            dept_code: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                //editable: true,
                resizable: true
            },
            rowData: [],
            originalrowData: [],
            selectedTabLat: '',
            selectedTabLng: '',
            cluster_centroids: [],
            deftab: 'all',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single",
            context: { componentParent: this },
            frameworkComponents: {
                UpdateButton: UpdateButton,
                UpdateTransitButton: UpdateTransitButton,
            },
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            rowModelType: "serverSide",
            paginationPageSize: 50,
            cacheBlockSize: 50,
            maxBlocksInCache: 2,

            showPlant: 'show-n',
            showAddr: 'show-n',
            request_type: '',
            view_type: '',
            tabplants: [],
            clickabletab: '',
            plantlist: [],
            stateFormData: "",
            sliderStateEmailsTranslate: "",
            overly: "show-n",
            rowData: [],
            rakeslist: [],
            loadingpoints: [],
            destinationpoints: [],
            userType: "",
            pinnedRegionBottomRowData: [],
            pinnedTransitBottomRowData: [],
            stockData: [],
            totalStock: 0,
            totalSales: 0,
            totalDispatches: 0,
            railShare: 0,
            totalFySales: 0,
            totalFyDispatches: 0,
            railFyShare: 0,
            pendingForDispatchStock: 0,
            transitStock: 0,
            unloadingUpdatedDate: "",
            intransitUpdatedDate: "",
            screenView: ""
        };
        this.getLatestValues = this.getLatestValues.bind(this)
    }
    /*Alert Popups*/
    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount() {
        loadDateTimeScript();
        this.logPageView(); // Log page view to GA
        var screenView = "";
        if (this.props.match.path == "/railstockstatus") {
            var userType = "management";

        }
        if (this.props.match.path == "/rakestockstatus") {
            var userType = "user";
        }

        if (this.props.match.path == "/railstock") {
            var userType = "management";
            screenView = "Mobile";

        }
        var fiscalyear = "";
        var today = new Date();
        if ((today.getMonth() + 1) <= 3) {
            fiscalyear = (today.getFullYear() - 1) + "-" + today.getFullYear()
        } else {
            fiscalyear = today.getFullYear() + "-" + (today.getFullYear() + 1)
        }
        this.setState({
            userType: userType,
            fiscalyear: fiscalyear,
            screenView: screenView
        });
        if (userType == "management") {
            setInterval(() => {
                console.log("Updating Sales..!!")
                this.getLatestValues()
            }, 60000)
        }
        this.getLatestValues();
        this.railunloadingterminals(userType);
        this.railstockintransit(userType);
        this.loadingAndDestinationPorts();

    }
    getLatestValues() {
        redirectURL.post("/consignments/getLatestRailStatusValues").then((response) => {
            console.log(response.data)
            this.setState({
                totalSales: response.data.total_sales_till_yesterday,
                totalDispatches: response.data.total_rail_sales_till_yesterday,
                railShare: response.data.rail_share,
                totalFySales: response.data.total_sales_in_fy,
                totalFyDispatches: response.data.total_rail_sales_in_fy,
                railFyShare: response.data.total_fy_rail_share
            })
        }).catch((e) => { console.log(e) })
    }
    railunloadingterminals = async (userType) => {
        var params = {
            "userType": userType,
        }
        redirectURL.post("/consignments/getRailUnLoadingTerminals", params).then(async (response) => {
            var rowData = response.data.records;
            var total_vehicles = 0;
            var dispatched_vehicles = 0;
            var pending_dispatched_vehicles = 0;
            var unloadingUpdatedDate = "";
            if (rowData.length > 0) {
                if (rowData[0].approval_done_at != '' && rowData[0].approval_done_at != undefined) {
                    var unloadingUpdatedDate = moment.parseZone(rowData[0].approval_done_at).format("DD-MM-YYYY HH:mm");
                }
            }
            await rowData.map(async function (e) {
                if (e.approval_done_at != '' && e.approval_done_at != undefined) {
                    var unloadingUpdatedDate = e.approval_done_at;
                }
                total_vehicles = parseInt(total_vehicles) + parseInt(e.total_vehicles);
                dispatched_vehicles = parseInt(dispatched_vehicles) + parseInt(e.dispatched_vehicles);
                pending_dispatched_vehicles = parseInt(pending_dispatched_vehicles) + parseInt(e.pending_dispatched_vehicles);
            })
            var pinnedBottomRowData = {}
            pinnedBottomRowData['_id'] = "Total";
            pinnedBottomRowData['destination'] = "";
            pinnedBottomRowData['transporter_name'] = "Total";
            pinnedBottomRowData['total_vehicles'] = total_vehicles;
            pinnedBottomRowData['dispatched_vehicles'] = dispatched_vehicles;
            pinnedBottomRowData['pending_dispatched_vehicles'] = pending_dispatched_vehicles;

            this.setState({
                pinnedRegionBottomRowData: [pinnedBottomRowData],
                pendingForDispatchStock: pending_dispatched_vehicles,
                unloadingUpdatedDate: unloadingUpdatedDate,
            })
            //console.log(unloadingUpdatedDate,"unloadingUpdatedDate")
            // redirectURL.post("/consignments/getRailStockStatusCounters",params).then((response)=>{
            //     var statusData = response.data.records;
            //     if(statusData[0].total_fy_sales_till_yesterday !='' && statusData[0].total_fy_sales_till_yesterday !=undefined)
            //     {
            //         var totalFySales = statusData[0].total_fy_sales_till_yesterday;
            //     }
            //     else
            //     {
            //         var totalFySales = 0;
            //     }
            //     if(statusData[0].total_fy_rail_sales_till_yesterday !='' && statusData[0].total_fy_rail_sales_till_yesterday !=undefined)
            //     {
            //         var totalFyDispatches = statusData[0].total_fy_rail_sales_till_yesterday;
            //     }
            //     else
            //     {
            //         var totalFyDispatches = 0;
            //     }
            //     if(statusData[0].rail_fy_share !='' && statusData[0].rail_fy_share !=undefined)
            //     {
            //         var railFyShare = statusData[0].rail_fy_share;
            //     }
            //     else
            //     {
            //         var railFyShare = 0;
            //     }
            //     this.setState({
            //         totalSales : statusData[0].total_sales_till_yesterday,
            //         totalDispatches : statusData[0].total_rail_sales_till_yesterday,
            //         railShare : statusData[0].rail_share,
            //         totalFySales : totalFySales,
            //         totalFyDispatches : totalFyDispatches,
            //         railFyShare : railFyShare,
            //     });
            // }).catch((e)=>{
            //     console.log(e);
            // })
            this.setState({
                rowData: rowData,
            })
        }).catch((e) => {
            console.log(e);
        })
    }

    railstockintransit(userType) {
        var params = {
            "userType": userType,
        }
        redirectURL.post("/consignments/getRailStockInTransit", params).then((response) => {
            var stockData = response.data.records;
            var no_of_cars = 0;
            var intransitUpdatedDate = "";
            if (stockData.length > 0) {
                if (stockData[0].approval_done_at != '' && stockData[0].approval_done_at != undefined) {
                    var intransitUpdatedDate = moment.parseZone(stockData[0].approval_done_at).format("DD-MM-YYYY HH:mm");
                }
            }
            stockData.map(function (s) {
                if (s.approval_done_at != '' && s.approval_done_at != undefined) {
                    var intransitUpdatedDate = s.approval_done_at;
                }
                no_of_cars = parseInt(no_of_cars) + s.no_of_cars;
            })
            var pinnedTransitBottomRowData = {};
            pinnedTransitBottomRowData['_id'] = "Total";
            pinnedTransitBottomRowData['source_plant'] = "Total";
            pinnedTransitBottomRowData['no_of_cars'] = no_of_cars;

            this.setState({
                pinnedTransitBottomRowData: [pinnedTransitBottomRowData],
                transitStock: no_of_cars,
            })
            this.setState({
                stockData: stockData,
                intransitUpdatedDate: intransitUpdatedDate,
            })
        }).catch((e) => {
            console.log(e);
        })
    }

    loadingAndDestinationPorts() {
        redirectURL.post("/consignments/getloadingAndDestinationPorts").then((response) => {
            console.log(response.data, "respui");
            var loadPorts = response.data.loadingpoints;
            var loadingpoints = [];
            if (loadPorts.length > 0) {
                loadPorts.map((item) => {
                    loadingpoints.push(item.geofence_name)
                })
            }
            var destPorts = response.data.destinationpoints;
            var destinationpoints = [];
            if (destPorts.length > 0) {
                destPorts.map((item1) => {
                    destinationpoints.push(item1.geofence_name)
                })
            }
            this.setState({
                loadingpoints: loadingpoints,
                destinationpoints: destinationpoints
            })
        }).catch((e) => {
            console.log(e);
        })
    }

    onClickHideAll() {
        this.setState({
            overly: "show-n",
            sliderStateEmailsTranslate: "",

        })
    }

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };

    onUnLoadingGridReady = params => {
        this.gridApi1 = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi1 = params.columnApi;
    };


    updateLmStates(params) {
        params['userid'] = localStorage.getItem('userid');
        params['email'] = localStorage.getItem('email');
        params['username'] = localStorage.getItem('username');

        if (params.destination != '' && params.destination != undefined && params.transporter_name != '' && params.transporter_name != undefined && params.total_vehicles != '' && params.total_vehicles != undefined && params.dispatched_vehicles != '' && params.dispatched_vehicles != undefined && params.pending_dispatched_vehicles != '' && params.pending_dispatched_vehicles != undefined && params.rake_arrival_time != '' && params.rake_arrival_time != undefined) {
            if (params.rake_arrival_time != "invalid date") {
                redirectURL.post("/consignments/updateRakeUnloadingTerminals", params).then((response) => {
                    console.log(response);
                    if (response.data.message == "Success") {
                        this.setState({
                            basicTitle: "Updated Successfully.",
                            basicType: "success",
                            show: true
                        })
                        this.railunloadingterminals(this.state.userType);
                    }
                    else {
                        this.setState({
                            basicTitle: "Failed to update.",
                            basicType: "warning",
                            show: true
                        })
                    }
                })
            }
            else {
                this.setState({
                    basicTitle: "Rake Arrival Time Should be valid.",
                    basicType: "warning",
                    show: true
                })
            }
        }
        else {
            this.setState({
                basicTitle: "All fields are mandatory.",
                basicType: "warning",
                show: true
            })
        }
    }

    updateTransit(params) {
        params['userid'] = localStorage.getItem('userid');
        params['email'] = localStorage.getItem('email');
        params['username'] = localStorage.getItem('username');
        if (params.rake_type != '' && params.rake_type != undefined && params.no_of_cars != '' && params.no_of_cars != undefined && params.loading_point != '' && params.loading_point != undefined) {
            if (params.rake_arrival_time != "invalid date") {
                redirectURL.post("/consignments/updateRakeStockInTransitStatus", params).then((response) => {
                    console.log(response);
                    if (response.data.message == "Success") {
                        this.setState({
                            basicTitle: "Updated Successfully.",
                            basicType: "success",
                            show: true
                        })
                        this.railstockintransit(this.state.userType);
                    }
                    else {
                        this.setState({
                            basicTitle: "Failed to update.",
                            basicType: "warning",
                            show: true
                        })
                    }
                })
            }
            else {
                this.setState({
                    basicTitle: "Rake Arrival Time Should be valid.",
                    basicType: "warning",
                    show: true
                })
            }
        }
        else {
            this.setState({
                basicTitle: "All fields are mandatory.",
                basicType: "warning",
                show: true
            })
        }
    }

    approveAllData(type) {
        var params = {};
        params['userid'] = localStorage.getItem('username');
        params['email'] = localStorage.getItem('email');
        params['username'] = localStorage.getItem('username');
        params['type'] = type;
        redirectURL.post("/consignments/approveRakeStatusData", params).then((response) => {
            console.log(response);
            if (response.data.message == "Success") {
                this.setState({
                    basicTitle: "Approved.",
                    basicType: "success",
                    show: true
                })
                this.railstockintransit(this.state.userType);
            }
            else {
                this.setState({
                    basicTitle: "Failed to approve.",
                    basicType: "warning",
                    show: true
                })
            }
        })
    }

    addNewRow() {
        // console.log(this.gridApi);
        this.gridApi1.insertItemsAtIndex(0, [{ destination: "", transporter_name: "", total_vehicles: "", dispatched_vehicles: "", pending_dispatched_vehicles: "", rake_arrival_time: "", remarks: "" }])
        // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
    }

    addNewTransitRow() {
        // console.log(this.gridApi);
        this.gridApi.insertItemsAtIndex(0, [{ rake_type: "", no_of_cars: "", source_plant: "", loading_point: "", delivery_point: "", last_know_location: "", rake_arrival_time: "", remarks: "" }])
        // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
    }

    onCellClicked(row) {
        if (row.colDef.field == "delete") {
            var rowId = row.data._id;
            //console.log(row.data._id);
            var qry = {
                rowId: rowId,
                collection: "railunloadingterminals"
            }
            //var confirm = confirm("Are You Sure! Do you want really delete?");
            if (window.confirm("Are You Sure! Do you want really delete?")) {
                redirectURL.post("/consignments/deleteRakeStockStatus", qry).then((response) => {
                    console.log(response.data);
                    if (response.data.message == "Success") {
                        this.setState({
                            basicTitle: "Deleted Successfully.",
                            basicType: "success",
                            show: true
                        })
                        this.railunloadingterminals(this.state.userType);
                    }
                    else {
                        this.setState({
                            basicTitle: "Failed to Delete.",
                            basicType: "warning",
                            show: true
                        })
                    }
                })
            }
        }
    }

    onCellClickedInTransit(row) {
        if (row.colDef.field == "delete") {
            var rowId = row.data._id;
            //console.log(row.data._id);
            var qry = {
                rowId: rowId,
                collection: "railstockintransit"
            }
            //var confirm = confirm("Are You Sure! Do you want really delete?");
            if (window.confirm("Are You Sure! Do you want really delete?")) {
                redirectURL.post("/consignments/deleteRakeStockStatus", qry).then((response) => {
                    console.log(response.data);
                    if (response.data.message == "Success") {
                        this.setState({
                            basicTitle: "Deleted Successfully.",
                            basicType: "success",
                            show: true
                        })
                        this.railstockintransit(this.state.userType);
                    }
                    else {
                        this.setState({
                            basicTitle: "Failed to Delete.",
                            basicType: "warning",
                            show: true
                        })
                    }
                })
            }
        }
    }

    render() {
        if (this.state.userType == "management") {
            var showTransporter = true;
            var editField = false;
        }
        else {
            var showTransporter = false;
            var editField = true;
        }
        var columnwithDefs = [
            {
                headerName: "LSP Rake Number",
                field: "rake_no",
                width: 140,
                filter: true,
                resizable: true,
              },
            {
                headerName: "Rake ID",
                field: "rake_id",
                width: 120,
                filter: true,
                resizable: true,
                editable: editField,
                sortable: true,
            },
            {
                headerName: "Destination",
                field: "destination",
                width: 120,
                filter: true,
                resizable: true,
                editable: editField,
                sortable: true,
            },
            {
                headerName: "Cluster",
                field: "rail_cluster",
                width: 120,
                filter: true,
                resizable: true,
                editable: editField,
                sortable: true,
            },
            {
                headerName: "LSP",
                field: "transporter_name",
                width: 160,
                filter: true,
                resizable: true,
                editable: editField,
                sortable: true,
            },
            {
                headerName: "Total Vehicles",
                field: "total_vehicles",
                width: 120,
                filter: true,
                resizable: true,
                editable: editField,
                sortable: true,
                cellEditor: NumericCellEditor,
            },
            {
                headerName: "No. of Cars Dispatched",
                field: "dispatched_vehicles",
                width: 120,
                filter: true,
                resizable: true,
                editable: editField,
                sortable: true,
                cellEditor: NumericCellEditor,
            },
            {
                headerName: "Pending For Dispatch",
                field: "pending_dispatched_vehicles",
                width: 120,
                filter: true,
                resizable: true,
                editable: editField,
                sortable: true,
                cellEditor: NumericCellEditor,
            },
            {
                headerName: "Rake Arrival Time",
                field: "rake_arrival_time",
                width: 140,
                filter: true,
                resizable: true,
                editable: editField,
                sortable: true,
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.rake_arrival_time != "" && params.data.rake_arrival_time != undefined) {
                            return moment.parseZone(params.data.rake_arrival_time).format('DD-MM-YYYY HH:mm');
                        }
                    }
                    catch (e) {
                        return "";
                    }
                }
            },
            {
                headerName: "Last Packet Time",
                field: "timestamp",
                width: 140,
                valueGetter: function (params) {
                    if (params.data.timestamp != "" && params.data.timestamp != undefined) {
                        return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
                    } else {
                        return ""
                    }
                }
            },
            {
                headerName: "Remarks",
                field: "remarks",
                width: 230,
                filter: true,
                resizable: true,
                editable: editField,
                sortable: true,
            },
            {
                pinned: "left",
                headerName: "Update",
                field: "_id",
                width: 120,
                resizable: true,
                hide: showTransporter,
                cellRendererSelector: function (params) {
                    if (params.data._id != "Total") {
                        var rendComponent = {
                            component: 'UpdateButton'
                        };
                        return rendComponent;
                    }
                },
                valueGetter: function (params) {
                    if (params.data._id == "Total") {
                        return "";
                    }
                }
            },
            {
                pinned: "left",
                headerName: "Delete",
                field: "delete",
                width: 80,
                resizable: true,
                editable: false,
                hide: showTransporter,
                cellRenderer: function (params) {
                    if (params.data._id != "Total") {
                        return '<i class="icofont icofont-ui-delete"></i>';
                    }
                },
            },
        ];

        var columnwithStatusDefs = [
            {
                headerName: "Rake ID",
                field: "rake_id",
                width: 120,
                filter: true,
                resizable: true,
                editable: editField,
                sortable: true,
            },
            {
                headerName: "Rake Type",
                field: "rake_type",
                width: 120,
                filter: true,
                resizable: true,
                editable: editField,
                sortable: true,
            },
            {
                headerName: "Rake ETA",
                field: "rake_eta",
                width: 120,
                filter: true,
                resizable: true,
                editable: editField,
                sortable: true,
                valueGetter: function (params) {
                    if (params.data.rake_eta != '' && params.data.rake_eta != undefined) {
                        return moment.parseZone(params.data.rake_eta).format('DD-MM-YYYY HH:mm');
                    }
                    else {
                        return "";
                    }
                }
            },
            {
                headerName: "No. of Cars",
                field: "no_of_cars",
                width: 100,
                filter: true,
                resizable: true,
                editable: editField,
                sortable: true,
                cellEditor: NumericCellEditor,
            },
            {
                headerName: "Source Plant",
                field: "source_plant",
                width: 100,
                filter: true,
                resizable: true,
                editable: editField,
                sortable: true,
            },
            {
                headerName: "Loading station",
                field: "loading_point",
                width: 150,
                filter: true,
                resizable: true,
                editable: editField,
                sortable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: this.state.loadingpoints,
                },
            },
            {
                headerName: "Destination",
                field: "delivery_point",
                width: 150,
                filter: true,
                resizable: true,
                editable: editField,
                sortable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: this.state.destinationpoints,
                },
            },
            {
                headerName: "Present Location",
                field: "last_know_location",
                width: 150,
                filter: true,
                resizable: true,
                editable: editField,
                sortable: true,
            },
            {
                headerName: "Rake Device Name",
                field: "rake_no",
                width: 120,
                filter: true,
                resizable: true,
                editable: editField,
                sortable: true,
            },
            {
                headerName: "Rake Departure Time",
                field: "rake_arrival_time",
                width: 140,
                filter: true,
                resizable: true,
                editable: editField,
                sortable: true,
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    if (params.data.rake_arrival_time != '' && params.data.rake_arrival_time != undefined) {
                        return moment.parseZone(params.data.rake_arrival_time).format('DD-MM-YYYY HH:mm');
                    }
                    else {
                        return "";
                    }
                }
            },
            {
                headerName: "Distance From Destination (KM's)",
                field: "distance_in_kms",
                width: 140,
                filter: true,
                resizable: true,
                editable: editField,
                sortable: true,
            },
            {
                headerName: "Last Packet Time",
                field: "timestamp",
                width: 140,
                valueGetter: function (params) {
                    if (params.data.timestamp != "" && params.data.timestamp != undefined) {
                        return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
                    } else {
                        return ""
                    }
                }
            },
            {
                headerName: "Remarks",
                field: "remarks",
                width: 150,
                filter: true,
                resizable: true,
                editable: editField,
                sortable: true,
            },
            {
                pinned: "left",
                headerName: "Update",
                field: "_id",
                width: 180,
                resizable: true,
                hide: showTransporter,
                cellStyle: { fontSize: '15px' },
                cellRendererSelector: function (params) {
                    if (params.data._id != "Total") {
                        var rendComponent = {
                            component: 'UpdateTransitButton'
                        };
                        return rendComponent;
                    }
                    else {
                        return "Total In Transit Stock";
                    }
                },
                valueGetter: function (params) {
                    if (params.data._id == "Total") {
                        return "Total In Transit Stock";
                    }
                }
            },
            {
                pinned: "left",
                headerName: "Delete",
                field: "delete",
                width: 80,
                resizable: true,
                editable: false,
                hide: showTransporter,
                cellRenderer: function (params) {
                    if (params.data._id != "Total") {
                        return '<i class="icofont icofont-ui-delete"></i>';
                    }
                },
            },
        ];
        return (

            <div className={(this.state.screenView == "Mobile") ? "" : "container-fluid"} style={(this.state.screenView == "Mobile") ? { marginTop: "2em" } : {}}>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className={(this.state.screenView == "Mobile") ? "card" : "card col-xl-12"}>
                    <div className="card-header">
                        <h5>
                            <i className="icon-map cus-i"></i> <span>Rail Stock Status</span>
                            {(this.state.userType == "management" && this.state.screenView != "Mobile") ?
                                <a href="/managementdashboard" className="btn btn-danger" style={{ float: "right", marginLeft: "2%" }}>Back</a>
                                :
                                ""
                                // <button className="btn btn-success" style={{float:"right"}} onClick={this.approveAllData.bind(this)}>Approve All</button>
                            }
                        </h5>
                    </div>
                    <div className={(this.state.screenView == "Mobile") ? "card-body" : "card-body col-xl-12 col-lg-12"}>
                        <h4 style={(this.state.screenView == "Mobile") ? { marginBottom: "14%" } : { marginBottom: "1%" }}>
                            Stock at Unloading Terminals
                            <span style={{ paddingLeft: "10em", fontSize: "14px" }}>{"Last Update By LOG-TNP On : " + this.state.unloadingUpdatedDate}</span>
                            {(this.state.userType == "management") ? "" :
                                <span>
                                    <button className="btn btn-success" style={{ float: "right" }} onClick={this.approveAllData.bind(this, "unloading")}>Approve</button>

                                    <button className="btn btn-info" style={{ float: "right", marginRight: "2%" }} onClick={this.addNewRow.bind(this)}>Add New Row</button>
                                </span>
                            }
                        </h4>
                        <div id="myGrid" style={{ width: "100%", height: "250px" }} className={"ag-theme-balham"}>
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onUnLoadingGridReady}
                                onGridState={this.onUnloadingGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus={true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={false}
                                pinnedBottomRowData={this.state.pinnedRegionBottomRowData}
                                gridOptions={{
                                    context: { componentParent: this },
                                    getRowStyle: function (params) {
                                        if (params.node.rowPinned) {
                                            return { 'font-weight': 'bold', 'font-size': '16px' };
                                        }
                                    },
                                }}
                                // components={this.state.components}
                                enableRangeSelection={true}
                                onCellClicked={this.onCellClicked.bind(this)}
                            // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                            />
                        </div>
                    </div>
                    <div className={(this.state.screenView == "Mobile") ? "card-body" : "card-body col-xl-12 col-lg-12"}>
                        <h4 style={(this.state.screenView == "Mobile") ? { marginBottom: "14%" } : { marginBottom: "1%" }}>
                            Stock In Transit
                            <span style={{ paddingLeft: "22em", fontSize: "14px" }}>{"Last Update By LOG-TNP On : " + this.state.intransitUpdatedDate} </span>
                            {(this.state.userType == "management") ? "" :
                                <span>
                                    <button className="btn btn-success" style={{ float: "right" }} onClick={this.approveAllData.bind(this, "intransit")}>Approve</button>
                                    <button className="btn btn-info" style={{ float: "right", marginRight: "2%" }} onClick={this.addNewTransitRow.bind(this)}>Add New Row</button>
                                </span>
                            }
                        </h4>
                        <div id="myGrid" style={{ width: "100%", height: "350px" }} className={"ag-theme-balham"}>
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithStatusDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.stockData}
                                enableCharts={false}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus={true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={false}
                                pinnedBottomRowData={this.state.pinnedTransitBottomRowData}
                                gridOptions={{
                                    context: { componentParent: this },
                                    getRowStyle: function (params) {
                                        if (params.node.rowPinned) {
                                            return { 'font-weight': 'bold', 'font-size': '16px' };
                                        }
                                    },
                                }}
                                // components={this.state.components}
                                enableRangeSelection={true}
                                onCellClicked={this.onCellClickedInTransit.bind(this)}
                            // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                            />
                        </div>
                    </div>
                    <div className="card-body col-xl-12 col-lg-12">
                        <table className="table table-bordered cust-tbl cust-td">
                            <tr>
                                <td>Total Stock (Rail In Transit + Pending For Last Mile Dispatch)</td>
                                <td>{parseInt(this.state.pendingForDispatchStock) + parseInt(this.state.transitStock)}</td>
                            </tr>
                        </table>
                    </div>

                    <div className="card-body col-xl-12 col-lg-12">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6">
                                <h4 className="tss-tl">Current Month <span>(Till Previous Day)</span></h4>
                                <table className="table table-bordered cust-tbl">
                                    <thead>
                                        <tr>
                                            <th>{"Total Sales"}</th>
                                            <th>{"Total Dispatches Thru Rail"}</th>
                                            <th>{"Rail Share % "}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{this.state.totalSales}</td>
                                            <td>{this.state.totalDispatches}</td>
                                            <td>{this.state.railShare}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-xl-6 col-lg-6">
                                <h4 className="tss-tl">Current Financial Year <span>(Till Previous Day)</span></h4>
                                <table className="table table-bordered cust-tbl">
                                    <thead>
                                        <tr>
                                            <th>{"Total Sales"}</th>
                                            <th>{"Total Dispatches Thru Rail"}</th>
                                            <th>{"Rail Share %"}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{this.state.totalFySales}</td>
                                            <td>{this.state.totalFyDispatches}</td>
                                            <td>{this.state.railFyShare}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>


                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

            </div>

        );
    }
}


function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src = url
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber1(date) {
    console.log(date.length);
    console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    console.log(yearNumber, monthNumber, dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        //mask:'39-19-9999 29:59',
        format: 'd-m-Y'
    });
    $('.datetimepicker_date').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd hh:mm",
        changeMonth: true,
        changeYear: true,
        timepicker: true,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    // console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

import React, { Component } from 'react';
import { render } from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import Select from 'react-select';
import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import Modal from 'react-responsive-modal';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY } from '../common/utils';
import 'react-vertical-timeline-component/style.min.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import PrimaryCounters from "./primarydashboardcounters";
import ViewPrimaryDownloadPayments from "./viewdownloadprimarysecondarypayments";
import ViewDownloadPayments from "./viewdownloadsecondarypayments";
import ViewPrimaryStockDownloadPayments from "./viewstockardprimaryfiles";
import ViewStockyardDownloadPayments from "./viewdownloadstockyardpayments";
import PrimaryBillAcceptReject from "./primarybillacceptreject";
import UploadFile from "./uploadsecondarypaymentfile";
import StockUploadFile from "./uploadstockpaymentfile";
import StockPrimBillAcceptReject from "./stockyardprimbillacceptreject";
import download from 'js-file-download';
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");

export default class PrimaryDashboard extends Component {

	constructor(props) {
		super(props);
		var date2 = new Date();
		var currentDate = moment(date2).format("YYYY-MM-DD");
		var currentStart = moment(date2).subtract(10, 'days').format("YYYY-MM-DD")
		this.state = {
			pageTitle: "Primary Dashboard",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			uploadDivWidth: '0%',
			sliderTranslate: '',
			sliderRouteTranslate: '',
			tabsliderTranslate: '',
			loadshow: 'show-n',
			showDiv: 'show-n',
			alertshow: 'fade',
			alertmg: 'show-n',
			alerterrmg: 'show-n',
			overly: 'show-n',
			alerterrshow: 'fade',
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			showreload: false,
			basicTitlereload: '',
			basicTypereload: "default",
			modules: AllModules,
			sidebarSubHeader: '',
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true

			},
			allData: [],
			rowData: [],
			loading: false,
			activeExportState: false,
			percentageCalculation: 0,
			exportStart: '',
			exportEnd: '',
			bulkslideExport: '',
			rowSelection: 'multiple',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
				PrimaryBillAcceptReject: PrimaryBillAcceptReject,
				StockPrimBillAcceptReject: StockPrimBillAcceptReject,
				UploadFile: UploadFile,
				StockUploadFile: StockUploadFile
			},
			groupDefaultExpanded: -1,
			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,

			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 1000,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"green": "data.status >= 3"

			},
			transporterslist: [],
			container_no: '',
			updatesearatebtn: 'show-n',
			searates: '',
			pipavaContainers: [],
			mundraContainers: [],
			containerslist: [],
			errorMessage: '',
			successMessage: '',
			transporter_code: '',
			components: { datePicker: getDatePicker() },
			updatedRecords: [],
			open: false,
			stockopen: false,
			rejectopen: false,
			openUpdatedModal: false,
			usergridstate: [],
			screenurl: "",
			screentitle: "",
			startDate: currentStart,
			endDate: currentDate,
			defaultsdate1: currentStart,
			defaultedate1: currentDate,
			defaultsdate: "",
			defaultedate: "",
			zerofifteen: [],
			fifteenthirty: [],
			thirtyonefortyfive: [],
			morefortyfive: [],
			uploadrowid: '',
			files: [],
			upload_bill_date: "",
			rejectdata: "",
			remarks: '',
			prim_stock_upload_bill_date: "",
			stockrejectopen: false,
			stockrejectdata: "",
			stockremarks: "",
			stockzerofifteen: [],
			stockfifteenthirty: [],
			stockthirtyonefortyfive: [],
			stockmorefortyfive: [],
			zeroamt: 0,
			fifteen: 0,
			thirty: 0,
			moreforty: 0,
			stockzeroamt: 0,
			stockfifteen: 0,
			stockthirty: 0,
			stockmoreforty: 0,
			clicked_pod_date: "",
			bulkslide: "",
			bulkRowIds: [],
			selectedItems: [],
			uploadType:''
		}
		this.onLoadDashboardData = this.onLoadDashboardData.bind(this);
		this.getFilterTruckConsignments = this.getFilterTruckConsignments.bind(this);
		this.resetState = this.resetState.bind(this);
		this.onClickShowUploadModal = this.onClickShowUploadModal.bind(this);
		this.onClickShowStockUploadModal = this.onClickShowStockUploadModal.bind(this);
		this.onClickAcceptSecondaryBill = this.onClickAcceptSecondaryBill.bind(this);
		this.onClickRejectSecondaryBill = this.onClickRejectSecondaryBill.bind(this);
		this.onClickRejectSecondaryBillModal = this.onClickRejectSecondaryBillModal.bind(this);
		this.onClickStockRejectPrimaryModal = this.onClickStockRejectPrimaryBill.bind(this);
		this.onClickStockRejectPrimaryBillModal = this.onClickStockRejectPrimaryBillModal.bind(this);
		this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
	}
	componentDidMount() {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}

		redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
			.then(async (resp) => {
				//console.log("resp ", resp)
				await this.setState({
					usergridstate: resp.data
				});
				await this.restoreGridStates();
			})
		var transportercode = "";
		// console.log("localStorage.getItem('transportercode') ", localStorage.getItem('transportercode'))
		if (localStorage.getItem('transportercode') === undefined) {
			transportercode = "";
		}
		else {
			transportercode = localStorage.getItem('transportercode');
		}
		var parameters = {
			pagetype: "primary",
			transporter_code: transportercode,
			startDate: this.state.startDate,
			endDate: this.state.endDate
		}

		this.onLoadDashboardData(parameters)
		//dateScript("https://code.jquery.com/ui/1.12.1/jquery-ui.js");
	};

	async onLoadDashboardData(parameters) {

		this.setState({
			loadshow: 'show-m',
			overly: 'show-m',
		})
		var createdate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
		//console.log("createdate ",createdate);
		var s1 = moment.parseZone(createdate).format("x")
		//console.log("s1 ",s1)
		await redirectURL.post("/consignments/secondarypaymentscounterdata", parameters)
			.then((response) => {
				var records = []
				var zerofifteen = []
				var fifteenthirty = []
				var thirtyonefortyfive = []
				var morefortyfive = []

				var stockzerofifteen = []
				var stockfifteenthirty = []
				var stockthirtyonefortyfive = []
				var stockmorefortyfive = []
				var zeroamt = 0
				var fifteen = 0
				var thirty = 0
				var moreforty = 0

				records = response.data;
				console.log("records bhanu", records.length)
				if (records.length > 0) {
					records.map((item) => {
						var inv = moment.parseZone(item.sec_upload_date).format("YYYY-MM-DD HH:mm:ss");
						//console.log("inv ", inv);
						var s2 = moment.parseZone(inv).format("x")
						//console.log(s2);
						var diffreetime = moment.duration(moment(createdate).diff(inv));
						var delaydays = Math.round(diffreetime.asDays());
						console.log("prim delaydays ", delaydays)
						if (item.sec_is_payment_receive == "Yes" && item.sec_status == "Payment received") {

						}
						else {

							if (delaydays >= 0 && delaydays <= 15) {
								zerofifteen.push(item)
								if (item.sec_status != "Payment received") {
									zeroamt = parseFloat(zeroamt) + parseFloat(item.sec_fret_amt)
								}

							}
							if (delaydays >= 16 && delaydays <= 30) {
								//console.log("item",item)
								fifteenthirty.push(item)
								stockfifteenthirty.push(item)
								if (item.sec_status != "Payment received") {
									fifteen = parseFloat(fifteen) + parseFloat(item.sec_fret_amt)
								}

							}
							if (delaydays >= 31 && delaydays <= 45) {
								thirtyonefortyfive.push(item)
								stockthirtyonefortyfive.push(item)
								if (item.sec_status != "Payment received") {
									thirty = parseFloat(thirty) + parseFloat(item.sec_fret_amt)
								}

							}
							if (delaydays > 45) {
								morefortyfive.push(item)
								stockmorefortyfive.push(item)
								if (item.sec_status != "Payment received") {
									moreforty = parseFloat(moreforty) + parseFloat(item.sec_fret_amt)
								}

							}
						}

					})

				}

				this.setState({
					zerofifteen: zerofifteen,
					fifteenthirty: fifteenthirty,
					thirtyonefortyfive: thirtyonefortyfive,
					morefortyfive: morefortyfive,
					zeroamt: zeroamt,
					fifteen: fifteen,
					thirty: thirty,
					moreforty: moreforty
				})

			})

		await redirectURL.post("/consignments/stockyardpaymentscounterdata", parameters)
			.then((response) => {
				var recordstock = []

				var stockzerofifteen = []
				var stockfifteenthirty = []
				var stockthirtyonefortyfive = []
				var stockmorefortyfive = []
				var zeroamt = 0
				var fifteen = 0
				var thirty = 0
				var moreforty = 0
				recordstock = response.data;
				console.log("recordstock ", recordstock)
				if (recordstock.length > 0) {
					recordstock.map((item) => {

						//stockcounter
						console.log("item.stockyard_upload_date ", typeof item.stockyard_upload_date)
						if (item.stockyard_upload_date != "") {
							// console.log("item.stockyard_upload_date ", item.stockyard_upload_date)
							// console.log("item.stockyard_is_payment_receive ", item.stockyard_is_payment_receive)

							if (item.stockyard_is_payment_receive == "Yes" && item.stockyard_status == "Payment received") {

							}
							else {
								var inv1 = moment.parseZone(item.stockyard_upload_date).format("YYYY-MM-DD HH:mm:ss");
								//console.log("inv ", inv);
								var s2 = moment.parseZone(inv1).format("x")
								//console.log(s2);
								var diffreetime1 = moment.duration(moment(createdate).diff(inv1));
								var delaydays1 = Math.round(diffreetime1.asDays());
								console.log("delaydays1 ", delaydays1)
								if (delaydays1 >= 0 && delaydays1 <= 15) {
									stockzerofifteen.push(item)
									if (item.stockyard_status != "Payment received") {
										zeroamt = parseFloat(zeroamt) + parseFloat(item.tvp_stock_charges)
									}
								}
								if (delaydays1 >= 16 && delaydays1 <= 30) {
									stockfifteenthirty.push(item)
									if (item.stockyard_status != "Payment received") {
										fifteen = parseFloat(fifteen) + parseFloat(item.tvp_stock_charges)
									}

								}
								if (delaydays1 >= 31 && delaydays1 <= 45) {
									stockthirtyonefortyfive.push(item)
									if (item.stockyard_status != "Payment received") {
										thirty = parseFloat(thirty) + parseFloat(item.tvp_stock_charges)
									}
								}
								if (delaydays1 > 45) {
									stockmorefortyfive.push(item)
									if (item.stockyard_status != "Payment received") {
										moreforty = parseFloat(moreforty) + parseFloat(item.tvp_stock_charges)
									}
								}
							}
						}
					})

				}

				// console.log("stockzerofifteen ", stockzerofifteen.length)
				// console.log("stockfifteenthirty ", stockfifteenthirty.length)
				// console.log("stockthirtyonefortyfive ", stockthirtyonefortyfive.length)
				// console.log("stockmorefortyfive ", stockmorefortyfive.length)
				this.setState({
					stockzerofifteen: stockzerofifteen,
					stockfifteenthirty: stockfifteenthirty,
					stockthirtyonefortyfive: stockthirtyonefortyfive,
					stockmorefortyfive: stockmorefortyfive,
					stockzeroamt: zeroamt,
					stockfifteen: fifteen,
					stockthirty: thirty,
					stockmoreforty: moreforty
				})

			})
		await redirectURL.post("/consignments/secondarypaymentsdata", parameters)
			.then((response) => {
				console.log("printRowDbData", response.data)
				var records = response.data.records;
				var recordsarr = []

				try {
					if (records.length > 0) {
						records.map((item) => {
							var outstand_delay_days = ""
							if (item.prim_outstand_delay_days === undefined) {
								outstand_delay_days = ""
							}
							else {
								outstand_delay_days = item.prim_outstand_delay_days
							}
							var status = ""
							if (item.prim_status === undefined) {
								status = "Pending"
							}
							else {
								status = item.prim_status
							}
							var payment_date = ""
							if (item.prim_payment_date === undefined) {
								payment_date = ""
							}
							else {
								payment_date = item.prim_payment_date
							}

							var stockyard_outstand_delay_days = ""
							if (item.stockyard_outstand_delay_days === undefined) {
								stockyard_outstand_delay_days = ""
							}
							else {
								stockyard_outstand_delay_days = item.stockyard_outstand_delay_days
							}
							var stockyard_status = ""
							if (item.stockyard_status === undefined) {
								stockyard_status = "Pending"
							}
							else {
								stockyard_status = item.stockyard_status
							}

							var secstatus = ""
							if (item.sec_status === undefined) {
								secstatus = "Pending"
							}
							else {
								secstatus = item.sec_status
							}
							var sec_payment_date = ""
							if (item.sec_payment_date === undefined) {
								sec_payment_date = ""
							}
							else {
								sec_payment_date = item.sec_payment_date
							}
							recordsarr.push({
								"_id": item._id,
								"consignment_code": item.consignment_code,
								"load_no": item.load_no,
								"vin": item.vin,
								"invoice_date": item.invoice_date,
								"pod_date": item.pod_date,
								"imm_for_code": item.imm_for_code,
								"IMM_CITY": item.IMM_CITY,
								"for_code": item.for_code,
								"for_city": item.for_city,
								"prim_transporter_code": item.prim_transporter_code,
								"prim_transporter_name": item.prim_transporter_name,
								"truck_no": item.truck_no,
								"truck_type": item.truck_type,
								"yr_mfg": item.yr_mfg,
								"N": item.new_or_old,
								"bmodel": item.bmodel,
								"quantity": item.quantity,
								"car_equi": item.car_equi,
								"tot_freight": item.tot_freight,
								"tot_dmg_amt": item.tot_dmg_amt,
								"tot_sort_amt": item.tot_sort_amt,
								"tot_penalty": item.tot_penalty,
								"tot_delay_amt": item.tot_delay_amt,
								"net_tot_fret_amt": item.net_tot_fret_amt,
								"prim_fret_amt": item.prim_fret_amt,
								"prim_dmg_amt": item.prim_dmg_amt,
								"prim_sort_amt": item.prim_sort_amt,
								"prim_penalty_amt": item.prim_penalty_amt,
								"prim_delay_amt": item.prim_delay_amt,
								"net_prim_fret_amt": item.net_prim_fret_amt,
								"tvp_stock_charges": item.tvp_stock_charges,
								"sec_load_no": item.sec_load_no,
								"sec_truck_no": item.sec_truck_no,
								"sec_transporter_name": item.sec_transporter_name,
								"sec_fret_amt": item.sec_fret_amt,
								"sec_dmg_amt": item.sec_dmg_amt,
								"sec_sort_amt": item.sec_sort_amt,
								"sec_panalty_amt": item.sec_panalty_amt,
								"sec_delay_amt": item.sec_delay_amt,
								"net_sec_fret_amt": item.net_sec_fret_amt,
								"sec_load_date": item.sec_load_date,
								"sec_transporter_code": item.sec_transporter_code,
								"SEC_TPT_NAME": item.sec_transporter_name.trim().toUpperCase(),
								"prim_outstand_delay_days": outstand_delay_days,
								"prim_status": status,
								"prim_payment_date": payment_date,
								"sec_status": secstatus,
								"sec_payment_date": sec_payment_date,
								"prim_uploadfiles": item.prim_uploadfiles,
								"sec_uploadfiles": item.sec_uploadfiles,
								"prim_upload_date": item.prim_upload_date,
								"prim_bill_date": item.prim_bill_date,
								"prim_remarks": item.prim_remarks,
								"sec_remarks": item.sec_remarks,
								"sec_upload_date": item.sec_upload_date,
								"sec_bill_date": item.sec_bill_date,
								"sec_is_payment_receive": item.sec_is_payment_receive,
								"stockyard_outstand_delay_days": stockyard_outstand_delay_days,
								"stockyard_status": stockyard_status,
								"stockyard_payment_date": item.stockyard_payment_date,
								"stockyard_uploadfiles": item.stockyard_uploadfiles,
								"stockyard_bill_date": item.stockyard_bill_date,
								"stockyard_upload_date": item.stockyard_upload_date,
								"stockyard_is_payment_receive": item.stockyard_is_payment_receive,
								"prim_stock_uploadfiles": item.prim_stock_uploadfiles,
								"prim_stock_bill_date": item.prim_stock_bill_date,
								"prim_stock_upload_date": item.prim_stock_upload_date,
								"prim_stock_remarks": item.prim_stock_remarks,
								"stockyard_remarks": item.stockyard_remarks,
								"tvp_code": item.tvp_code,
							})
						})
					}
				}
				catch (e) {

				}
				console.log("printRowData", recordsarr)
				this.setState({
					allData: recordsarr,
					rowData: recordsarr,
					loadshow: 'show-n',
					overly: 'show-n',
					// zerofifteen:zerofifteen,
					// fifteenthirty:fifteenthirty,
					// thirtyonefortyfive:thirtyonefortyfive,
					// morefortyfive:morefortyfive
				})
			})

	}

	onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
	};


	onGridState = () => {
		//console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);


	}

	onClickHideAll() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			sliderRouteTranslate: '',
			tabsliderTranslate: '',
			openUpdatedModal: false
		});

	}
	closeAlert = async () => {
		await this.setState({
			show: false
		});
		// await window.location.reload()
	}

	closeAlertReload = async () => {
		await this.setState({
			showreload: false
		});
		//await window.location.reload()
	}

	onClickSaveGridState() {
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		console.log("Columns ", window.colState)
		console.log("Group ", window.groupState)
		console.log("Sort ", window.sortState)
		console.log("Filter ", window.filterState)
		let screenpage = 'Primary Payments Dashboard';



		let reqparams = {
			gridcolumns: window.colState,
			gridgroup: window.groupState,
			gridcolsort: window.sortState,
			gridcolfilter: window.filterState,
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
			.then((response) => {
				//console.log("State response ",response.data)
				this.setState({
					show: true,
					basicTitle: "Successfully saved grid layout",
					basicType: "success",
					screenurl: window.location.pathname,
					screentitle: screenpage
				})
			})
			.catch(function (e) {
				console.log("Error ", e)
			})
	}

	restoreGridStates() {
		try {
			if (this.state.usergridstate.length > 0) {
				var windowstates = this.state.usergridstate;
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

			}
		}
		catch (e) {

		}

	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
			.then((response) => {
				this.setState({
					show: true,
					basicTitle: "Successfully reset default grid layout",
					basicType: "success"
				})
			})

	};



	resetUpload = () => {
		this.setState({
			bulkslide: '',
			overly: 'show-n',
			exportStart: '',
			uploadType:'',
			upload_bill_date: "",
			exportEnd: '',
			bulkslideExport: '',
			file: ''
		});
		document.getElementById("upform").reset();
	}

	handlerBillDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

		this.setState({
			upload_bill_date: startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}


	handlerStockBillDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

		this.setState({
			prim_stock_upload_bill_date: startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}


	handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		var month = ("0" + (d.getMonth() + 1)).slice(-2); // Adding leading zero if month is less than 10
		var day = ("0" + d.getDate()).slice(-2); // Adding leading zero if day is less than 10
		var startdate = d.getFullYear() + "-" + month + "-" + day;
		if (this.state.endDate != undefined && this.state.endDate != "") {
			var startDate = new Date(startdate);
			var endDate = new Date(this.state.endDate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			if (startTime > endTime) {
				this.setState({
					show: true,
					basicTitle: 'To Date should be greater than From Date',
					basicType: "danger",
					loadshow: 'show-n'
				});
			} else {
				this.setState({
					startDate: startdate
				});
			}
		} else {
			this.setState({
				startDate: startdate
			});
		}
	}

	handlerEndDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		var month = ("0" + (d.getMonth() + 1)).slice(-2); // Adding leading zero if month is less than 10
		var day = ("0" + d.getDate()).slice(-2); // Adding leading zero if day is less than 10
		var edate = d.getFullYear() + "-" + month + "-" + day;
		if (this.state.startDate != undefined && this.state.startDate != "") {
			var startDate = new Date(this.state.startDate);
			var endDate = new Date(edate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			if (startTime > endTime) {
				this.setState({
					show: true,
					basicTitle: 'To Date should be greater than From Date',
					basicType: "danger",
					loadshow: 'show-n'
				});
			} else {
				this.setState({
					endDate: edate
				});
			}
		} else {
			this.setState({
				endDate: edate
			});
		}
	}
	handlerStartDateTimeExport = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

		this.setState({
			exportStart: startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerEndDateTimeExport = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
		this.setState({
			exportEnd: edate
		});
	}

	onClickFilterButton = async () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": "Date Filter Action",
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
			var sDate = this.state.defaultsdate;
		}
		else {
			var sDate = this.state.startDate;
		}
		if (this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN") {
			var eDate = this.state.defaultedate;
		}
		else {
			var eDate = this.state.endDate;
		}

		var transportercode = "";
		// console.log("localStorage.getItem('transportercode') ", localStorage.getItem('transportercode'))
		if (localStorage.getItem('transportercode') === undefined) {
			transportercode = "";
		}
		else {
			transportercode = localStorage.getItem('transportercode');
		}
		var parameters = {
			pagetype: "primary",
			transporter_code: transportercode,
			startDate: sDate,
			endDate: eDate
		}

		// console.log("requestparams ", parameters)
		this.onLoadDashboardData(parameters)
	}

	onClickFilterResetButton() {
		var transportercode = "";
		this.setState({
			startDate: this.state.defaultsdate1,
			endDate: this.state.defaultedate1
		})
		// console.log("localStorage.getItem('transportercode') ", localStorage.getItem('transportercode'))
		if (localStorage.getItem('transportercode') === undefined) {
			transportercode = "";
		}
		else {
			transportercode = localStorage.getItem('transportercode');
		}
		var parameters = {
			pagetype: "primary",
			transporter_code: transportercode,
			startDate: this.state.defaultsdate1,
			endDate: this.state.defaultedate1

		}

		// console.log("requestparams ", parameters)
		this.onLoadDashboardData(parameters)
	}

	onClickUpdateData() {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedUpdateNow,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			//  loadshow:'show-m'
		});
		var rowCount = this.gridApi.getSelectedNodes();
		//console.log("rowCount ", rowCount)
		var updateitems = []
		if (rowCount.length > 0) {
			rowCount.map((item) => {
				updateitems.push(item.data)
			})
		}
		console.log("updateitems ", updateitems)
		if (updateitems.length > 0) {
			// var paramtrs = {
			//     updatedata : updateitems,

			// }
			redirectURL.post("/consignments/updateSecondaryPaymentDataNew", updateitems)
				.then((response) => {
					//console.log(response.data)
					if (response.data.status == 'success') {
						this.setState({
							show: true,
							basicTitle: "Successfully updated",
							basicType: "success"
						});
						var transportercode = "";
						// console.log("localStorage.getItem('transportercode') ", localStorage.getItem('transportercode'))
						if (localStorage.getItem('transportercode') === undefined) {
							transportercode = "";
						}
						else {
							transportercode = localStorage.getItem('transportercode');
						}
						var parameters = {
							pagetype: "primary",
							transporter_code: transportercode,
							startDate: this.state.startDate,
							endDate: this.state.endDate
						}

						this.onLoadDashboardData(parameters)
						this.gridApi.deselectAll();
					}
				})
		}
		else {
			this.setState({
				show: true,
				basicTitle: "No Item selected",
				basicType: "info"
			})
		}
		//redirect
	}
	onClickBulkUpdateData() {
		var rowCount = this.gridApi.getSelectedNodes();
		//console.log("rowCount ", rowCount)
		var updateitems = []
		if (rowCount.length > 0) {
			rowCount.map((item) => {
				updateitems.push(item.data)
			})
		}
		//console.log("updateitems ", updateitems)
		if (updateitems.length > 0) {
			this.setState({
				overly: "show-m",
				bulkslide: "slider-translate",
				selectedItems: updateitems
			})
		}
		else {
			this.setState({
				show: true,
				basicTitle: "No Item selected",
				basicType: "info"
			})
		}
	}


	bulkchangeFileHandler = async (e) => {
		console.log(e.target.files)
		var bulkfiles = Array.from(e.target.files);
		this.setState({
			bulkfiles: bulkfiles
		})
	}
	plantChangeFilter() {
		var selectedPlant = $("#selectedPlantFilter").val();
		if (selectedPlant != "all" && selectedPlant != "") {
			//console.log(selectedPlant,"selectedPlant")
			this.setState({
				loadshow: 'show-m',
				overly: 'show-m',
			})
			var allData = this.state.allData;
			//console.log(allData,"allData")
			var rowFilterData = allData.filter(function (obj) {
				return (obj.tvp_code === selectedPlant);
			});
			this.setState({
				rowData: rowFilterData,
				loadshow: 'show-n',
				overly: 'show-n',
			});
		}
		else {
			this.setState({
				loadshow: 'show-m',
				overly: 'show-m',
			})
			var allData = this.state.allData;
			this.setState({
				rowData: allData,
				loadshow: 'show-n',
				overly: 'show-n',
			});
		}
	}
	onRowSelected(event) {

	}

	getTruckConsignments = (event) => {
		event.preventDefault();
		var selectedFilter = $("#selectedFilter").text();
		var inputTruckConsignment = $("#inputTruckConsignment").val();
		if (selectedFilter == "Select..." || inputTruckConsignment == "") {
			this.setState({
				basicTitle: "Fields Should not be Empty",
				basicType: "danger",
				show: true
			})
		}
		else {
			this.getFilterTruckConsignments();
		}

	}

	getFilterTruckConsignments() {
		var selectedFilter = $("#selectedFilter").text();
		var inputTruckConsignment = $("#inputTruckConsignment").val();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": "Search record by " + selectedFilter,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow: 'show-m',
			overly: 'show-m',
		})
		var createdate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
		//console.log("createdate ",createdate);
		var s1 = moment.parseZone(createdate).format("x")
		//console.log("s1 ",s1)

		console.log(selectedFilter, inputTruckConsignment);
		var parametes = {
			"selectedFilter": selectedFilter,
			"inputTruckConsignment": inputTruckConsignment,
		}
		redirectURL.post("/consignments/searchpaymentinfodata", parametes)
			.then((response) => {
				var records = response.data.records;
				console.log(records, "searchpaymentinfodata")
				var recordsarr = []
				var zerofifteen = []
				var fifteenthirty = []
				var thirtyonefortyfive = []
				var morefortyfive = []
				if (records.length > 0) {
					records.map((item) => {

						var outstand_delay_days = ""
						if (item.prim_outstand_delay_days === undefined) {
							outstand_delay_days = ""
						}
						else {
							outstand_delay_days = item.prim_outstand_delay_days
						}
						var status = ""
						if (item.prim_status === undefined) {
							status = "Pending"
						}
						else {
							status = item.prim_status
						}
						var payment_date = ""
						if (item.prim_payment_date === undefined) {
							payment_date = ""
						}
						else {
							payment_date = item.prim_payment_date
						}

						var stockyard_outstand_delay_days = ""
						if (item.stockyard_outstand_delay_days === undefined) {
							stockyard_outstand_delay_days = ""
						}
						else {
							stockyard_outstand_delay_days = item.stockyard_outstand_delay_days
						}
						var stockyard_status = ""
						if (item.stockyard_status === undefined) {
							stockyard_status = "Pending"
						}
						else {
							stockyard_status = item.stockyard_status
						}
						var inv = moment.parseZone(item.sec_upload_date).format("YYYY-MM-DD HH:mm:ss");
						//console.log("inv ", inv);
						var s2 = moment.parseZone(inv).format("x")
						//console.log(s2);
						var diffreetime = moment.duration(moment(createdate).diff(inv));
						var delaydays = Math.round(diffreetime.asDays());

						if ((delaydays < 0 || delaydays >= 0) && delaydays <= 15) {
							zerofifteen.push(item)
						}
						if (delaydays >= 16 && delaydays <= 30) {
							fifteenthirty.push(item)
						}
						if (delaydays >= 31 && delaydays <= 45) {
							thirtyonefortyfive.push(item)
						}
						if (delaydays > 45) {
							morefortyfive.push(item)
						}


						var secstatus = ""
						if (item.sec_status === undefined) {
							secstatus = "Pending"
						}
						else {
							secstatus = item.sec_status
						}
						var sec_payment_date = ""
						if (item.sec_payment_date === undefined) {
							sec_payment_date = ""
						}
						else {
							sec_payment_date = item.sec_payment_date
						}



						recordsarr.push({
							"_id": item._id,
							"consignment_code": item.consignment_code,
							"load_no": item.load_no,
							"vin": item.vin,
							"invoice_date": item.invoice_date,
							"pod_date": item.pod_date,
							"imm_for_code": item.imm_for_code,
							"IMM_CITY": item.IMM_CITY,
							"for_code": item.for_code,
							"for_city": item.for_city,
							"prim_transporter_code": item.prim_transporter_code,
							"prim_transporter_name": item.prim_transporter_name,
							"truck_no": item.truck_no,
							"truck_type": item.truck_type,
							"yr_mfg": item.yr_mfg,
							"N": item.new_or_old,
							"bmodel": item.bmodel,
							"quantity": item.quantity,
							"car_equi": item.car_equi,
							"tot_freight": item.tot_freight,
							"tot_dmg_amt": item.tot_dmg_amt,
							"tot_sort_amt": item.tot_sort_amt,
							"tot_penalty": item.tot_penalty,
							"tot_delay_amt": item.tot_delay_amt,
							"net_tot_fret_amt": item.net_tot_fret_amt,
							"prim_fret_amt": item.prim_fret_amt,
							"prim_dmg_amt": item.prim_dmg_amt,
							"prim_sort_amt": item.prim_sort_amt,
							"prim_penalty_amt": item.prim_penalty_amt,
							"prim_delay_amt": item.prim_delay_amt,
							"net_prim_fret_amt": item.net_prim_fret_amt,
							"tvp_stock_charges": item.tvp_stock_charges,
							"sec_load_no": item.sec_load_no,
							"sec_truck_no": item.sec_truck_no,
							"sec_transporter_name": item.sec_transporter_name,
							"sec_fret_amt": item.sec_fret_amt,
							"sec_dmg_amt": item.sec_dmg_amt,
							"sec_sort_amt": item.sec_sort_amt,
							"sec_panalty_amt": item.sec_panalty_amt,
							"sec_delay_amt": item.sec_delay_amt,
							"net_sec_fret_amt": item.net_sec_fret_amt,
							"sec_load_date": item.sec_load_date,
							"sec_transporter_code": item.sec_transporter_code,
							"SEC_TPT_NAME": item.sec_transporter_name.trim().toUpperCase(),
							"prim_outstand_delay_days": outstand_delay_days,
							"prim_status": status,
							"prim_payment_date": payment_date,
							"sec_status": secstatus,
							"sec_payment_date": sec_payment_date,
							"prim_uploadfiles": item.prim_uploadfiles,
							"sec_uploadfiles": item.sec_uploadfiles,
							"prim_upload_date": item.prim_upload_date,
							"prim_bill_date": item.prim_bill_date,
							"prim_remarks": item.prim_remarks,
							"sec_remarks": item.sec_remarks,
							"sec_upload_date": item.sec_upload_date,
							"sec_bill_date": item.sec_bill_date,
							"sec_is_payment_receive": item.sec_is_payment_receive,
							"stockyard_outstand_delay_days": stockyard_outstand_delay_days,
							"stockyard_status": stockyard_status,
							"stockyard_payment_date": item.stockyard_payment_date,
							"stockyard_uploadfiles": item.stockyard_uploadfiles,
							"stockyard_bill_date": item.stockyard_bill_date,
							"stockyard_upload_date": item.stockyard_upload_date,
							"stockyard_is_payment_receive": item.stockyard_is_payment_receive,
							"prim_stock_uploadfiles": item.prim_stock_uploadfiles,
							"prim_stock_bill_date": item.prim_stock_bill_date,
							"prim_stock_upload_date": item.prim_stock_upload_date,
							"prim_stock_remarks": item.prim_stock_remarks,
							"stockyard_remarks": item.stockyard_remarks,
							"tvp_code": item.tvp_code,
						})
					})
				}
				//	console.log("primarytrans ", primarytrans)

				$("#selectedPlantFilter").val("all");

				this.setState({
					allData: recordsarr,
					rowData: recordsarr,
					loadshow: 'show-n',
					overly: 'show-n',
					zerofifteen: zerofifteen,
					fifteenthirty: fifteenthirty,
					thirtyonefortyfive: thirtyonefortyfive,
					morefortyfive: morefortyfive
				})
			}).catch = ((e) => {
				console.log(e);
			})
	}

	changeFileHandler = async (e) => {
		console.log(e.target.files)
		var files = Array.from(e.target.files);
		this.setState({
			files: e.target.files
		})
	}

	changeHandler = (event) => {
		//console.log("Select", event.target.value);
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}


	formHandler(event) {
		//	event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": "Form Action",
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var formdata = new FormData()
		formdata.append("rowid", this.state.uploadrowid)
		formdata.append("pagetype", "primary")
		formdata.append("billdate", this.state.upload_bill_date)
		//console.log("this.state.files ", this.state.files)
		for (var x = 0; x < this.state.files.length; x++) {
			//console.log("this.state.files[x] ", this.state.files[x])
			formdata.append('file', this.state.files[x])
		}
		formdata.append('upload_bill_date', this.state.upload_bill_date)
		formdata.append('email', localStorage.getItem("email"))
		formdata.append('username', localStorage.getItem("username"))
		formdata.append('screen', "primary")
		var files = {
			rowid: this.state.uploadrowid,
			files: this.state.files
		}
		//console.log("formdata", formdata)
		if (this.state.upload_bill_date != "" && this.state.upload_bill_date != "NaN-NaN-NaN") {
			var upload_bill_date = moment.parseZone(new Date(this.state.upload_bill_date)).utcOffset("+05:30")._d
			var clicked_pod_date = this.state.clicked_pod_date;
			console.log(upload_bill_date, "upload_bill_date")
			console.log(clicked_pod_date, "clicked_pod_date")
			var dateCheck = moment(upload_bill_date).isAfter(moment(clicked_pod_date));
			console.log(dateCheck, "dateCheck")
			if (dateCheck == true) {
				var currentDate = moment();
				console.log(currentDate, "currentDate")
				dateCheck = moment(upload_bill_date).isSameOrBefore(moment(currentDate));
				console.log(dateCheck, "dateCheck")
			}
			if (dateCheck == true) {
				redirectURL.post("/consignments/uploadsecondarypaymentsdocsdata", formdata, {
					headers: {
						"Content-Type": "application/x-www-form-urlencoded"
					}
				})
					.then((response) => {
						//console.log("Res ", response.data)
						if (response.data.status == "success") {
							this.setState({
								showreload: true,
								basicTitlereload: "Successfully upload files",
								basicTypereload: "success",
								open: false,
								uploadrowid: "",
								upload_bill_date: "",
								files: [],
								loadshow: 'show-n',
								overly: 'show-n',
							})
							this.componentDidMount()
						}
						else {
							this.setState({
								show: true,
								basicTitle: "Failed to upload",
								basicType: "danger"
							})
						}
						var transportercode = "";
						// console.log("localStorage.getItem('transportercode') ", localStorage.getItem('transportercode'))
						if (localStorage.getItem('transportercode') === undefined) {
							transportercode = "";
						}
						else {
							transportercode = localStorage.getItem('transportercode');
						}
						var parameters = {
							pagetype: "primary",
							transporter_code: transportercode,
							startDate: this.state.startDate,
							endDate: this.state.endDate
						}

						var selectedFilter = $("#selectedFilter").text();
						var inputTruckConsignment = $("#inputTruckConsignment").val();
						if (selectedFilter == "Select..." || inputTruckConsignment == "") {
							this.onLoadDashboardData(parameters)
						}
						else {
							this.getFilterTruckConsignments();
						}
					})
			}
			else {
				this.setState({
					show: true,
					basicTitle: "Bill Date should be greater than POD and should not be ahead of Current Date",
					basicType: "danger"
				})
			}
		}
		else {
			this.setState({
				show: true,
				basicTitle: "Bill Date is mandatory",
				basicType: "danger"
			})
		}

	}

	uploadBulkFormHandler(event) {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": "Form Action",
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var formdata = new FormData()
		var selectedItems = this.state.selectedItems;
		console.log(selectedItems, "selectedItems")
		formdata.append("pagetype", "primary")
		formdata.append("billdate", this.state.upload_bill_date)
		//formdata.append("files", this.state.files)

		for (var x = 0; x < this.state.bulkfiles.length; x++) {
			formdata.append('file', this.state.bulkfiles[x])
		}

		formdata.append('upload_bill_date', this.state.upload_bill_date)
		formdata.append('email', localStorage.getItem("email"))
		formdata.append('username', localStorage.getItem("username"))
		formdata.append('screen', "primary")
		formdata.append('uploadType', this.state.uploadType.value)

		// var files = {
		// 	rowid:this.state.uploadrowid,
		// 	files:this.state.files,
		// 	pagetype:"stockyard"
		// }
		// console.log("files", files)
		if (this.state.upload_bill_date != "" && this.state.upload_bill_date != "NaN-NaN-NaN") {
			var upload_bill_date = moment.parseZone(new Date(this.state.upload_bill_date)).utcOffset("+05:30")._d;
			var finalRowItems = [];
			selectedItems.map(function (si) {
				var clicked_pod_date = si.pod_date;
				console.log(upload_bill_date, "upload_bill_date")
				console.log(clicked_pod_date, "clicked_pod_date")
				var dateCheck = moment(upload_bill_date).isAfter(moment(clicked_pod_date));
				console.log(dateCheck, "dateCheck")
				if (dateCheck == true) {
					var currentDate = moment();
					console.log(currentDate, "currentDate")
					dateCheck = moment(upload_bill_date).isSameOrBefore(moment(currentDate));
					console.log(dateCheck, "dateCheck")
				}
				if (dateCheck == true) {
					finalRowItems.push(si._id);
				}
			});
			if (finalRowItems.length > 0) {
				formdata.append("rowid", finalRowItems)
				redirectURL.post("/consignments/bulkuploadsecondarypaymentsdocsdata", formdata, {
					headers: {
						"Content-Type": "application/x-www-form-urlencoded"
					}
				})
					.then((response) => {
						//console.log("Res ", response.data)
						if (response.data.status == "success") {
							this.setState({
								// showreload:true,
								basicTitlereload: "File uploaded successfully",
								basicTypereload: "success",
								open: false,
								uploadrowid: "",
								upload_bill_date: "",
								uploadType:'',
								files: [],
								bulkslide: "",
								selectedItems: [],
								loadshow: 'show-n',
								overly: 'show-n',
							})
							this.componentDidMount()
						}
						else {
							this.setState({
								show: true,
								basicTitle: "Failed to upload",
								basicType: "danger"
							})
						}
					})

				var transportercode = "";
				// console.log("localStorage.getItem('transportercode') ", localStorage.getItem('transportercode'))
				if (localStorage.getItem('transportercode') === undefined) {
					transportercode = "";
				}
				else {
					transportercode = localStorage.getItem('transportercode');
				}
				var parameters = {
					pagetype: "stockyard",
					transporter_code: transportercode,
					startDate: this.state.startDate,
					endDate: this.state.endDate
				}

				var selectedFilter = $("#selectedFilter").text();
				var inputTruckConsignment = $("#inputTruckConsignment").val();
				if (selectedFilter == "Select..." || inputTruckConsignment == "") {
					this.onLoadDashboardData(parameters)
				}
				else {
					this.getFilterTruckConsignments()
				}
			}
			else {
				this.setState({
					show: true,
					basicTitle: "Bill Date should be greater than POD and should not be ahead of Current Date",
					basicType: "danger"
				})
			}
		}
		else {
			this.setState({
				show: true,
				basicTitle: "Bill Date is mandatory",
				basicType: "danger"
			})
		}
	}

	onClickShowUploadModal(params) {
		//console.log("params ", params)
		this.setState({
			open: true,
			uploadrowid: params._id,
			clicked_pod_date: params.pod_date
		})
	}
	onClickShowStockUploadModal(params) {
		this.setState({
			stockopen: true,
			uploadrowid: params._id
		})
	}

	formHandlerStockyard(event) {
		//	event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": "Form Action",
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var formdata = new FormData()
		formdata.append("rowid", this.state.uploadrowid)
		formdata.append("pagetype", "primary")
		formdata.append("billdate", this.state.prim_stock_upload_bill_date)
		//console.log("this.state.files ", this.state.files)
		for (var x = 0; x < this.state.files.length; x++) {
			//console.log("this.state.files[x] ", this.state.files[x])
			formdata.append('file', this.state.files[x])
		}
		formdata.append('upload_bill_date', this.state.prim_stock_upload_bill_date)
		formdata.append('email', localStorage.getItem("email"))
		formdata.append('username', localStorage.getItem("username"))
		formdata.append('screen', "primary")
		var files = {
			rowid: this.state.uploadrowid,
			files: this.state.files
		}
		//console.log("formdata", formdata)
		if (this.state.prim_stock_upload_bill_date != "" && this.state.prim_stock_upload_bill_date != "NaN-NaN-NaN") {
			redirectURL.post("/consignments/uploadstockyardpaymentsdocumentsdata", formdata, {
				headers: {
					"Content-Type": "application/x-www-form-urlencoded"
				}
			})
				.then((response) => {
					//console.log("Res ", response.data)
					if (response.data.status == "success") {
						this.setState({
							showreload: true,
							basicTitlereload: "Successfully upload files",
							basicTypereload: "success",
							stockopen: false,
							uploadrowid: "",
							upload_bill_date: "",
							files: []
						})
					}
					else {
						this.setState({
							show: true,
							basicTitle: "Failed to upload",
							basicType: "danger"
						})
					}
					var transportercode = "";
					// console.log("localStorage.getItem('transportercode') ", localStorage.getItem('transportercode'))
					if (localStorage.getItem('transportercode') === undefined) {
						transportercode = "";
					}
					else {
						transportercode = localStorage.getItem('transportercode');
					}
					var parameters = {
						pagetype: "primary",
						transporter_code: transportercode,
						startDate: this.state.startDate,
						endDate: this.state.endDate
					}

					this.onLoadDashboardData(parameters)
				})
		}
		else {
			this.setState({
				show: true,
				basicTitle: "Bill Date is mandatory",
				basicType: "danger"
			})
		}

	}

	onCloseModal = () => {
		this.setState({
			open: false,
			rejectopen: false,
			stockopen: false,
			stockrejectopen: false
		});
	};


	onClickAcceptSecondaryBill(params) {
		//console.log("Accet sec bill ", params)
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": "Reject Action",
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var parameters = {
			rowid: params.data._id,
			prim_approve_status: 1,
			screen: "primary",
			sec_status: params.data.sec_status,
			email: localStorage.getItem("email"),
			username: localStorage.getItem("username")
		}
		redirectURL.post("/consignments/acceptrejectsecondarybillbyprimarydata", parameters)
			.then((response) => {
				this.setState({
					show: true,
					basicTitle: "Approved Secondary Bill",
					basicType: "success",

					rejectopen: false,
					rejectdata: "",
					remarks: ""
				})
			})
	}
	onClickRejectSecondaryBillModal(params) {

		this.setState({
			rejectopen: true,
			rejectdata: params.data
		})
	}
	onClickRejectSecondaryBill() {
		var data = this.state.rejectdata;
		var remarks = this.state.remarks;
		//console.log("Reject sec bill ", params)
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": "Reject Action",
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var parameters = {
			rowid: data._id,
			prim_approve_status: 0,
			screen: "primary",
			sec_status: data.sec_status,
			remarks: remarks,
			email: localStorage.getItem("email"),
			username: localStorage.getItem("username")
		}
		redirectURL.post("/consignments/acceptrejectsecondarybillbyprimarydata", parameters)
			.then((response) => {
				this.setState({
					show: true,
					basicTitle: "Rejected Secondary Bill",
					basicType: "success",
					remarks: "",
					rejectopen: false
				})
				var transportercode = "";
				// console.log("localStorage.getItem('transportercode') ", localStorage.getItem('transportercode'))
				if (localStorage.getItem('transportercode') === undefined) {
					transportercode = "";
				}
				else {
					transportercode = localStorage.getItem('transportercode');
				}
				var parameters = {
					pagetype: "primary",
					transporter_code: transportercode,
					startDate: this.state.startDate,
					endDate: this.state.endDate
				}

				var selectedFilter = $("#selectedFilter").text();
				var inputTruckConsignment = $("#inputTruckConsignment").val();
				if (selectedFilter == "Select..." || inputTruckConsignment == "") {
					this.onLoadDashboardData(parameters)
				}
				else {
					this.getFilterTruckConsignments();
				}
			})
	}

	onClickStockRejectPrimaryBillModal(params) {
		this.setState({
			stockrejectopen: true,
			stockrejectdata: params.data
		})
	}
	onClickStockRejectPrimaryBill() {
		var data = this.state.stockrejectdata;
		var remarks = this.state.stockremarks;
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": "Reject Stockyard Action",
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		//console.log("Reject sec bill ", params)
		var parameters = {
			rowid: data._id,
			prim_stock_approve_status: 0,
			screen: "primary",
			stockyard_status: data.stockyard_status,
			remarks: remarks,
			email: localStorage.getItem("email"),
			username: localStorage.getItem("username")
		}
		redirectURL.post("/consignments/acceptrejectstockbillbyprimarydata", parameters)
			.then((response) => {
				this.setState({
					show: true,
					basicTitle: "Rejected Stockyard Bill",
					basicType: "success",
					stockremarks: "",
					stockrejectopen: false,
					stockrejectdata: ""
				})
				var transportercode = "";
				// console.log("localStorage.getItem('transportercode') ", localStorage.getItem('transportercode'))
				if (localStorage.getItem('transportercode') === undefined) {
					transportercode = "";
				}
				else {
					transportercode = localStorage.getItem('transportercode');
				}
				var parameters = {
					pagetype: "primary",
					transporter_code: transportercode,
					startDate: this.state.startDate,
					endDate: this.state.endDate
				}

				this.onLoadDashboardData(parameters)
			})
	}
	downloadFiles(e) {
		if (e.colDef.field == "sec_uploadfiles" || e.colDef.field == "prim_uploadfiles" || e.colDef.field == "stockyard_uploadfiles" || e.colDef.field == "prim_stock_uploadfiles") {
			if (e.colDef.field == "sec_uploadfiles") {
				var secUpload = e.data.sec_uploadfiles;
			}
			if (e.colDef.field == "prim_uploadfiles") {
				var secUpload = e.data.prim_uploadfiles;
			}
			if (e.colDef.field == "stockyard_uploadfiles") {
				var secUpload = e.data.stockyard_uploadfiles;
			}
			if (e.colDef.field == "prim_stock_uploadfiles") {
				var secUpload = e.data.prim_stock_uploadfiles;
			}
			if (secUpload != undefined && secUpload != "") {
				var downloadFile = secUpload[0].name;
				if (downloadFile != undefined && downloadFile != '') {
					var rngParam = {
						downloadFile: downloadFile,
					}
					redirectURL.post("/consignments/downloadSecondaryFile", rngParam, {
						responseType: 'blob'
					}).then((response) => {
						console.log("dowlad response ", response)
						download(response.data, downloadFile)
					}).catch(function (error) {
						console.log(error);
					})
				}
			}
		}
	}

	onClickDownloadData() {
		this.setState(
			{
				exportStart: '',
				exportEnd: '',
				overly: "show-m",
				bulkslideExport: "slider-translate",
			},
		);
	}

	onClickSubmitDownloadData = async (e) => {
		e.preventDefault();
		const { exportStart, exportEnd } = this.state;
	
		if (!exportStart || !exportEnd) {
			this.setState({
				loading: false,
				activeExportState: false,
				show: true,
				basicTitle: 'Please select both start and end dates',
				basicType: "danger",
				loadshow: 'show-n',
			})
			return;
		}
		this.setState({
			overly: "show-n",
			bulkslideExport: "",
			loading: true,
			percentageCalculation: 0,
			activeExportState: true,
		})

		var transportercode = "";
		// console.log("localStorage.getItem('transportercode') ", localStorage.getItem('transportercode'))
		if (localStorage.getItem('transportercode') === undefined) {
			transportercode = "";
		}
		else {
			transportercode = localStorage.getItem('transportercode');
		}
		let sDate = moment(exportStart, 'YYYY-MM-DD');
		let eDate = moment(exportEnd, 'YYYY-MM-DD');
		var allRecords = [];

		var countQuery = {
			pagetype: "primary",
			transporter_code: transportercode,
			startTotal: exportStart,
			endTotal: exportEnd
		};
		var totalFetchedCount = await redirectURL.post("/consignments/TotalCountOfDataTodownloadDumpData", countQuery);

		console.log(totalFetchedCount.data.data, 'count')
		var totalDataCountStore = totalFetchedCount.data.data;
		if (totalDataCountStore === 0) {
			this.setState({
				loading: false,
				activeExportState: false,
				show: true,
				basicTitle: 'No data available for the selected date range',
				basicType: "danger",
				loadshow: 'show-n',
			})
			
			return;
		}
		for (let start = sDate.clone(); start.isSameOrBefore(eDate);) {
			// Calculate the end date for the current interval
			let nextEnd = moment.min(start.clone().add(15, 'days'), eDate);

			const queryStartDate = start.format("YYYY-MM-DD");
			const queryEndDate = nextEnd.format('YYYY-MM-DD');

			var parameters = {
				pagetype: "primary",
				transporter_code: transportercode,
				startDate: queryStartDate,
				endDate: queryEndDate,

			};

			try {
				console.log(parameters, 'parameters')
				// Make API call and wait for response
				const response = await redirectURL.post("/consignments/downloadDumpData", parameters);
				var records = response.data;
				console.log(response.data)

				allRecords = allRecords.concat(records);
				var percentageCalculation = ((allRecords.length) / totalDataCountStore) * 100;
				console.log(percentageCalculation, 'percentageCalculation')
				this.setState({
					percentageCalculation: percentageCalculation
				})

			} catch (error) {
				console.error("Error fetching data:", error);
			}

			// Move start date to the next interval
			start = nextEnd.clone().add(1, 'day');

			// Check if the next interval exceeds the end date
			if (!start.isSameOrBefore(eDate)) {
				break;
			}
		}
		var recordsarr = [];
		if (allRecords.length > 0) {
			allRecords.map((item) => {
				var outstand_delay_days = ""
				if (item.prim_outstand_delay_days === undefined) {
					outstand_delay_days = ""
				}
				else {
					outstand_delay_days = item.prim_outstand_delay_days
				}
				var status = ""
				if (item.prim_status === undefined) {
					status = "Pending"
				}
				else {
					status = item.prim_status
				}
				var payment_date = ""
				if (item.prim_payment_date === undefined) {
					payment_date = ""
				}
				else {
					payment_date = item.prim_payment_date
				}

				var stockyard_outstand_delay_days = ""
				if (item.stockyard_outstand_delay_days === undefined) {
					stockyard_outstand_delay_days = ""
				}
				else {
					stockyard_outstand_delay_days = item.stockyard_outstand_delay_days
				}
				var stockyard_status = ""
				if (item.stockyard_status === undefined) {
					stockyard_status = "Pending"
				}
				else {
					stockyard_status = item.stockyard_status
				}

				var secstatus = ""
				if (item.sec_status === undefined) {
					secstatus = "Pending"
				}
				else {
					secstatus = item.sec_status
				}
				var sec_payment_date = ""
				if (item.sec_payment_date === undefined) {
					sec_payment_date = ""
				}
				else {
					sec_payment_date = item.sec_payment_date
				}
				const primUploadFileName = item.prim_uploadfiles && item.prim_uploadfiles.map(file => {
					const fileNameParts = file.name.split(',');
					return fileNameParts[0].trim();
				}).join(', ');
				const secUploadFileName = item.sec_uploadfiles && item.sec_uploadfiles.map(file => {
					const fileNameParts = file.name.split(',');
					return fileNameParts[0].trim();
				}).join('- ');
				const StockyardUploadFileName = item.stockyard_uploadfiles && item.stockyard_uploadfiles.map(file => {
					const fileNameParts = file.name.split(',');
					return fileNameParts[0].trim();
				}).join('- ');
				const primStockyardUploadFileName = item.prim_stock_uploadfiles && item.prim_stock_uploadfiles.map(file => {
					const fileNameParts = file.name.split(',');
					return fileNameParts[0].trim();
				}).join('- ');

				recordsarr.push({
					// "_id" : item._id,
					"consignment_code": item.consignment_code,
					"load_no": item.load_no,
					"vin": item.vin,
					"invoice_date": item.invoice_date,
					"pod_date": item.pod_date,
					"imm_for_code": item.imm_for_code,
					"IMM_CITY": item.IMM_CITY,
					"for_code": item.for_code,
					"for_city": item.for_city,
					"prim_transporter_code": item.prim_transporter_code,
					"prim_transporter_name": item.prim_transporter_name,
					"truck_no": item.truck_no,
					"truck_type": item.truck_type,
					"yr_mfg": item.yr_mfg,
					"N": item.new_or_old,
					"bmodel": item.bmodel,
					"quantity": item.quantity,
					"car_equi": item.car_equi,
					"tot_freight": item.tot_freight,
					"tot_dmg_amt": item.tot_dmg_amt,
					"tot_sort_amt": item.tot_sort_amt,
					"tot_penalty": item.tot_penalty,
					"tot_delay_amt": item.tot_delay_amt,
					"net_tot_fret_amt": item.net_tot_fret_amt,
					"prim_fret_amt": item.prim_fret_amt,
					"prim_dmg_amt": item.prim_dmg_amt,
					"prim_sort_amt": item.prim_sort_amt,
					"prim_penalty_amt": item.prim_penalty_amt,
					"prim_delay_amt": item.prim_delay_amt,
					"net_prim_fret_amt": item.net_prim_fret_amt,
					"tvp_stock_charges": item.tvp_stock_charges,
					"sec_load_no": item.sec_load_no,
					"sec_truck_no": item.sec_truck_no,
					"sec_transporter_name": item.sec_transporter_name,
					"sec_fret_amt": item.sec_fret_amt,
					"sec_dmg_amt": item.sec_dmg_amt,
					"sec_sort_amt": item.sec_sort_amt,
					"sec_panalty_amt": item.sec_panalty_amt,
					"sec_delay_amt": item.sec_delay_amt,
					"net_sec_fret_amt": item.net_sec_fret_amt,
					"sec_load_date": item.sec_load_date,
					"sec_transporter_code": item.sec_transporter_code,
					"SEC_TPT_NAME": item.sec_transporter_name.trim().toUpperCase(),
					"prim_outstand_delay_days": outstand_delay_days,
					"prim_status": status,
					"prim_payment_date": payment_date,
					"sec_status": secstatus,
					"sec_payment_date": sec_payment_date,
					"prim_uploadfiles": primUploadFileName,
					"sec_uploadfiles": secUploadFileName,
					"prim_upload_date": item.prim_upload_date,
					"prim_bill_date": item.prim_bill_date,
					"prim_remarks": item.prim_remarks,
					"sec_remarks": item.sec_remarks,
					"sec_upload_date": item.sec_upload_date,
					"sec_bill_date": item.sec_bill_date,
					"sec_is_payment_receive": item.sec_is_payment_receive,
					"stockyard_outstand_delay_days": stockyard_outstand_delay_days,
					"stockyard_status": stockyard_status,
					"stockyard_payment_date": item.stockyard_payment_date,
					"stockyard_uploadfiles": StockyardUploadFileName,
					"stockyard_bill_date": item.stockyard_bill_date,
					"stockyard_upload_date": item.stockyard_upload_date,
					"stockyard_is_payment_receive": item.stockyard_is_payment_receive,
					"prim_stock_uploadfiles": primStockyardUploadFileName,
					"prim_stock_bill_date": item.prim_stock_bill_date,
					"prim_stock_upload_date": item.prim_stock_upload_date,
					"prim_stock_remarks": item.prim_stock_remarks,
					"stockyard_remarks": item.stockyard_remarks,
					"tvp_code": item.tvp_code,
				})
			})
		}

		let csvContent = '';
		csvContent += Object.keys(recordsarr[0]).join(",") + "\n";

		// recordsarr.forEach(item => {
		// 	csvContent += Object.values(item).join(",") + "\n"; // Add rows
		// });
		recordsarr.forEach(item => {
			const rowValues = Object.values(item).map(value => {
				if (typeof value === 'string' && value.includes(',')) {
					return `"${value}"`;
				} else {
					return value;
				}
			});
			csvContent += rowValues.join(',') + '\n';
		});

		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = 'data.csv';
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(url);
		document.body.removeChild(a);
		this.setState({
			loading: false,
			activeExportState: false
		})


	}
	onChangePaymentType = (selectedOptions) => {
		// console.log(selectedOptions,'selectedOptions')
		this.setState({
			uploadType: selectedOptions
		})
	}
	render() {
		const { loading, percentageCalculation, activeExportState } = this.state;
		const modalStyles = {
			width: '300px !important',
		}
		const { open } = this.state;
		const { rejectopen } = this.state;
		const { stockopen } = this.state;
		const { stockrejectopen } = this.state;
		//console.log("Rendere ",this.state.deptcode)
		const columnwithDefs = [
			{
				headerName: "",
				field: "_id",
				width: 40,
				pinned: "left",
				resizable: true,
				//headerCheckboxSelection: true,
				checkboxSelection: true

			},
			{
				headerName: "TRIP",
				field: "consignment_code",
				width: 120,
				editable: false,
				pinned: "left",
			},
			{
				headerName: "LOAD_NO",
				field: "load_no",
				width: 120,
				editable: false,
				pinned: "left",
			},
			{
				headerName: "VIN",
				field: "vin",
				width: 150,
				editable: false
			},
			{
				headerName: "INV_DATE",
				field: "invoice_date",
				width: 160,
				editable: false,
				valueGetter: function (params) {
					try {
						if (params.data.invoice_date != "" && params.data.invoice_date != undefined) {
							return getHyphenDDMMMYYYY(params.data.invoice_date);
						}
					}
					catch (e) {
						return "";
					}
				}
			},
			{
				headerName: "POD_DATE",
				field: "pod_date",
				width: 160,
				editable: false,
				valueGetter: function (params) {
					try {
						if (params.data.pod_date != "" && params.data.pod_date != undefined) {
							return getHyphenDDMMMYYYY(params.data.pod_date);
						}
					}
					catch (e) {
						return "";
					}
				}
			},
			{
				headerName: "FOR_CITY",
				field: "for_city",
				width: 120,
				editable: false
			},
			{
				headerName: "TRTR",
				field: "prim_transporter_code",
				width: 100,
				editable: false
			},
			{
				headerName: "TRANS_NAME",
				field: "prim_transporter_name",
				width: 200,
				editable: false
			},
			{
				headerName: "REGN_NO",
				field: "truck_no",
				width: 120,
				editable: false
			},
			{
				headerName: "Chassis_No",
				field: "Chassis_No",
				width: 120,
				editable: false
			},
			{
				headerName: "QTY",
				field: "quantity",
				width: 120,
				editable: false
			},
			{
				headerName: "TOTAL FREIGHT",
				headerClass: ["cellstylegridY", "cellTopHeader"],
				children: [
					{
						headerName: "TOT_FREIGHT",
						headerClass: "cellstylegridY",
						cellClass: "cellstylegridY",
						field: "tot_freight",
						width: 120,
						editable: false
					},
					{
						headerName: "TOT_DMG_AMT",
						field: "tot_dmg_amt",
						headerClass: "cellstylegridY",
						cellClass: "cellstylegridY",
						width: 120,
						editable: false
					},
					{
						headerName: "TOT_SORT_AMT",
						field: "tot_sort_amt",
						headerClass: "cellstylegridY",
						cellClass: "cellstylegridY",
						width: 120,
						editable: false
					},
					{
						headerName: "TOT_PENALTY",
						field: "tot_penalty",
						headerClass: "cellstylegridY",
						cellClass: "cellstylegridY",
						width: 120,
						editable: false
					},
					{
						headerName: "TOT_DELAY_AMT",
						field: "tot_delay_amt",
						headerClass: "cellstylegridY",
						cellClass: "cellstylegridY",
						width: 120,
						editable: false
					},
					{
						headerName: "NET_TOT_FRET_AMT",
						field: "net_tot_fret_amt",
						headerClass: "cellstylegridY",
						cellClass: "cellstylegridY",
						width: 120,
						editable: false
					},
				]
			},
			{
				headerName: "PRIMARY",
				headerClass: ["cellstylegrid", "cellTopHeader"],
				children: [
					{
						headerName: "PRIM_FRET_AMT",
						headerClass: "cellstylegrid",
						cellClass: "cellstylegrid",
						field: "prim_fret_amt",
						width: 120,
						editable: false
					},
					{
						headerName: "PRIM_DMG_AMT",
						headerClass: "cellstylegrid",
						cellClass: "cellstylegrid",
						field: "prim_dmg_amt",
						width: 120,
						editable: false
					},
					{
						headerName: "PRIM_SORT_AMT",
						headerClass: "cellstylegrid",
						cellClass: "cellstylegrid",
						field: "prim_sort_amt",
						width: 120,
						editable: false
					},
					{
						headerName: "PRIN_PENALTY_AMT",
						headerClass: "cellstylegrid",
						cellClass: "cellstylegrid",
						field: "prim_penalty_amt",
						width: 120,
						editable: false
					},
					{
						headerName: "PRIM_DELAY_AMT",
						headerClass: "cellstylegrid",
						cellClass: "cellstylegrid",
						field: "prim_delay_amt",
						width: 120,
						editable: false
					},
					{
						headerName: "NET_PRIM_FRET_AMT",
						headerClass: "cellstylegrid",
						cellClass: "cellstylegrid",
						field: "net_prim_fret_amt",
						width: 120,
						editable: false
					}
				]
			},
			{
				headerName: "TVP_STOCK_CHARGES",
				field: "tvp_stock_charges",
				width: 120,
				editable: false
			},
			{
				headerName: "SECONDARY",
				headerClass: ["cellstylegridB", "cellTopHeader"],
				children: [
					{
						headerName: "SEC_LOAD_NO",
						headerClass: "cellstylegridB",
						cellClass: "cellstylegridB",
						field: "sec_load_no",
						width: 120,
						editable: false
					},
					{
						headerName: "SEC_LOAD_DATE",
						headerClass: "cellstylegridB",
						cellClass: "cellstylegridB",
						field: "sec_load_date",
						width: 120,
						editable: false,
						valueGetter: function (params) {
							try {
								if (params.data.sec_load_date != "" && params.data.sec_load_date != undefined) {
									return getHyphenDDMMMYYYY(params.data.sec_load_date);
								}
							}
							catch (e) {
								return "";
							}
						}
					},
					{
						headerName: "SEC_TRUCK_NO",
						headerClass: "cellstylegridB",
						cellClass: "cellstylegridB",
						field: "sec_truck_no",
						width: 120,
						editable: false
					},
					{
						headerName: "SEC_LSP_CODE",
						headerClass: "cellstylegridB",
						cellClass: "cellstylegridB",
						field: "sec_transporter_code",
						width: 120,
						editable: false
					},
					{
						headerName: "SEC_LSP_NAME",
						headerClass: "cellstylegridB",
						cellClass: "cellstylegridB",
						field: "SEC_TPT_NAME",
						rowGroup: true,
						width: 120,
						editable: false,
						filter: true
					},
					{
						headerName: "SEC_FRET_AMT",
						headerClass: "cellstylegridB",
						cellClass: "cellstylegridB",
						field: "sec_fret_amt",
						width: 120,
						editable: false
					},
					{
						headerName: "SEC_DMG_AMT",
						headerClass: "cellstylegridB",
						cellClass: "cellstylegridB",
						field: "sec_dmg_amt",
						width: 120,
						editable: false
					},
					{
						headerName: "SEC_SORT_AMT",
						headerClass: "cellstylegridB",
						cellClass: "cellstylegridB",
						field: "sec_sort_amt",
						width: 120,
						editable: false
					},
					{
						headerName: "SEC_PENALTY_AMT",
						headerClass: "cellstylegridB",
						cellClass: "cellstylegridB",
						field: "sec_panalty_amt",
						width: 120,
						editable: false
					},
					{
						headerName: "SEC_DELAY_AMT",
						headerClass: "cellstylegridB",
						cellClass: "cellstylegridB",
						field: "sec_delay_amt",
						width: 120,
						editable: false
					},
					{
						headerName: "NET_SEC_FRET_AMT",
						headerClass: "cellstylegridB",
						cellClass: "cellstylegridB",
						field: "net_sec_fret_amt",
						width: 120,
						editable: false
					}
				]
			},
			{
				headerName: "Outstanding delay days",
				field: "prim_outstand_delay_days",
				width: 120,
				editable: true,
				valueGetter: function (params) {
					try {
						var currentdate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
						var inv = moment.parseZone(params.data.sec_upload_date).format("YYYY-MM-DD HH:mm:ss");

						var diffreetime = moment.duration(moment(currentdate).diff(inv));
						var delaydays = Math.round(diffreetime.asDays());
						return delaydays;
					}
					catch (e) {
						return "";
					}

				}
			},

			{
				headerName: "Status",
				field: "sec_status",
				width: 160,
				editable: false
			},
			// {
			// 	headerName : "Invoice Raised On",
			// 	field : "sec_payment_date",
			// 	width:160,
			//     editable : false,
			//     cellEditor: DateEditor,
			//     valueGetter:function(params){
			//         //console.log("params ", params);
			//         try{
			//             if(params.data.sec_payment_date != "" && params.data.sec_payment_date != undefined)
			//             {
			//                 return getHyphenDDMMMYYYY(params.data.sec_payment_date);
			//             }
			//         }
			//         catch(e){
			//             return "";
			//         }

			//     }
			// },

			{
				headerName: "Secondary Bills",
				field: "sec_uploadfiles",
				width: 120,
				editable: false,
				//cellRendererFramework:ViewDownloadPayments
				valueGetter: function (params) {
					try {
						if (params.data.sec_uploadfiles.length > 0) {
							return params.data.sec_uploadfiles[0].name;
						}
					}
					catch (e) {

					}
				}
			},

			{
				headerName: "Secondary Bill Date",
				field: "sec_bill_date",
				width: 120,
				editable: false,
				valueGetter: function (params) {
					try {
						return getHyphenDDMMMYYYY(params.data.sec_bill_date);
					}
					catch (e) {
						return "";
					}
				}

			},
			{
				headerName: "Payment Receive",
				field: "sec_is_payment_receive",
				width: 120,
				editable: false,
				//cellRenderer: "genderCellRenderer",
				cellEditor: "agRichSelectCellEditor",
				cellEditorParams: function (params) {
					if (params.data.sec_uploadfiles != "" && params.data.sec_uploadfiles != undefined
						&& params.data.prim_uploadfiles != "" && params.data.prim_uploadfiles != undefined) {
						var attr = {
							cellHeight: 30,
							values: ['Yes', 'No']
						}
						return attr;
					}
					else {
						return {};
					}

				},
				valueGetter: function (params) {
					//console.log("params ", params)
					try {
						if (params.data.sec_is_payment_receive != "" && params.data.sec_is_payment_receive != undefined) {
							return params.data.sec_is_payment_receive;
						}
						else {
							return "";
						}
					}
					catch (e) {
						return "";
					}
				}
			},
			{
				headerName: "Primary Reject/Remarks",
				field: "prim_remarks",
				width: 160,
				editable: false,
				cellRendererSelector: function (params) {
					try {
						//console.log("params.data.sec_uploadfiles.length ", params.data.sec_uploadfiles.length)
						if (params.data.sec_uploadfiles != undefined && params.data.sec_uploadfiles.length > 0
							&& params.data.sec_status == "Invoice Raised") {
							// console.log("params.data.consignment_code ", params.data.consignment_code)
							// console.log("params.data.sec_uploadfiles ", params.data.sec_uploadfiles)
							var rendComponent = {
								component: 'PrimaryBillAcceptReject'
							};
							return rendComponent
						}
						else {
							if (params.data.prim_remarks != "" && params.data.prim_remarks != undefined) {
								return params.data.prim_remarks;
							}
							else {
								return "";
							}
							// var rendComponent = {
							// 	component: 'PrimaryBillAcceptReject'
							// };
							// return rendComponent
							//return "";
						}
					}
					catch (e) {
						return "";
					}
				},
			},
			// {
			// 	headerName : "Primary Status",
			// 	field : "prim_status",
			// 	width:120,
			// 	editable : true
			// },
			{
				headerName: "Primary Payment Updated On",
				field: "prim_upload_date",
				width: 160,
				editable: true,
				cellEditor: DateEditor,
				valueGetter:function(params){
				    //console.log("params ", params);
				    try{
				        if(params.data.prim_upload_date != "" && params.data.prim_upload_date != undefined)
				        {
				            return getHyphenDDMMMYYYY(params.data.prim_upload_date);
				        }
				    }
				    catch(e){
				        return "";
				    }

				}
			},


			{
				headerName: "File",
				field: "",
				width: 160,
				editable: false,
				//cellEditor: DateEditor,
				cellRendererSelector: function (params) {
					try {
						//console.log("params.data.sec_uploadfiles.length ", params.data.sec_uploadfiles.length)
						if (params.data.sec_uploadfiles != undefined && params.data.sec_uploadfiles.length > 0) {
							var rendComponent = {
								component: 'UploadFile'
							};
							return rendComponent
						}
						else {
							return "";
						}
					}
					catch (e) {
						return "";
					}
				}
			},
			{
				headerName: "Primary Payment Proof",
				field: "prim_uploadfiles",
				width: 120,
				editable: false,
				// cellRendererFramework:ViewPrimaryDownloadPayments,
				valueGetter: function (params) {
					try {
						if (params.data.prim_uploadfiles.length > 0) {
							return params.data.prim_uploadfiles[0].name;
						}
					}
					catch (e) {

					}
				}
			},
			{
				headerName: "Primary Bill Date",
				field: "prim_bill_date",
				width: 120,
				editable: false,
				valueGetter: function (params) {
					try {
						return getHyphenDDMMMYYYY(params.data.prim_bill_date);
					}
					catch (e) {
						return "";
					}
				}

			},

			{
				headerName: "Secondary Remarks",
				field: "sec_remarks",
				width: 200,
				editable: false,
				cellClass: ["wraptext"]
			},
			{
				headerName: "STOCKYARD",
				headerClass: ["cellstylegridB", "cellTopHeader"],
				children: [
					{
						headerName: "Outstanding delay days",
						headerClass: "cellstylegridB",
						cellClass: "cellstylegridB",
						field: "stockyard_outstand_delay_days",
						width: 120,
						editable: true,
						valueGetter: function (params) {
							try {
								var createdate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
								var inv = moment.parseZone(params.data.stockyard_upload_date).format("YYYY-MM-DD HH:mm:ss");
								var diffreetime = moment.duration(moment(createdate).diff(inv));
								var delaydays = Math.round(diffreetime.asDays());
								return delaydays;
							}
							catch (e) {
								return "";
							}
						}
					},
					{
						headerName: "Status",
						headerClass: "cellstylegridB",
						cellClass: "cellstylegridB",
						field: "stockyard_status",
						width: 120,
						editable: true
					},

					{
						headerName: "Stockyard Bill",
						field: "stockyard_uploadfiles",
						width: 120,
						editable: false,
						// cellRendererFramework:ViewStockyardDownloadPayments,
						valueGetter: function (params) {
							try {
								if (params.data.stockyard_uploadfiles.length > 0) {
									return params.data.stockyard_uploadfiles[0].name;
								}
							}
							catch (e) {

							}
						}
					},


					{
						headerName: "Invoice Raised On",
						headerClass: "cellstylegridB",
						cellClass: "cellstylegridB",
						field: "stockyard_upload_date",
						width: 120,
						editable: true,
						cellEditor: DateEditor,
						valueGetter: function (params) {
							//console.log("params ", params);
							try {
								if (params.data.stockyard_upload_date != "" && params.data.stockyard_upload_date != undefined) {
									return getHyphenDDMMMYYYY(params.data.stockyard_upload_date);
								}
							}
							catch (e) {
								return "";
							}

						}
					},

					{
						headerName: "Reject/Remarks",
						field: "prim_stockyard_bill_accept",
						width: 160,
						editable: false,
						cellRendererSelector: function (params) {
							try {
								//console.log("params.data.prim_uploadfiles ", params.data.prim_uploadfiles)
								if (params.data.stockyard_uploadfiles != undefined && params.data.stockyard_uploadfiles.length > 0
									&& params.data.stockyard_status == "Invoice Raised") {

									var rendComponent = {
										component: 'StockPrimBillAcceptReject'
									};
									return rendComponent
								}
								else {
									return "";
								}
							}
							catch (e) {
								return "";
							}

						},
					},
					{
						headerName: "File",
						field: "",
						width: 160,
						editable: false,
						cellEditor: DateEditor,
						//	cellRendererFramework:StockUploadFile
						cellRendererSelector: function (params) {
							try {
								//console.log("params.data.sec_uploadfiles.length ", params.data.sec_uploadfiles.length)
								if (params.data.stockyard_uploadfiles != undefined && params.data.stockyard_uploadfiles.length > 0) {
									var rendComponent = {
										component: 'StockUploadFile'
									};
									return rendComponent
								}
								else {
									return "";
								}
							}
							catch (e) {
								return "";
							}
						}
					},

					{
						headerName: "Primary Payment Proof",
						field: "prim_stock_uploadfiles",
						width: 120,
						editable: false,
						// cellRendererFramework:ViewPrimaryStockDownloadPayments,
						valueGetter: function (params) {
							try {
								if (params.data.prim_stock_uploadfiles.length > 0) {
									return params.data.prim_stock_uploadfiles[0].name;
								}
							}
							catch (e) {

							}
						}
					},


					{
						headerName: "Stockyard Remarks",
						field: "stockyard_remarks",
						width: 200,
						editable: false,
						cellClass: ["wraptext"]
					},
					{
						headerName: "Primary Bill Date",
						field: "prim_stock_bill_date",
						width: 120,
						editable: false,
						valueGetter: function (params) {
							try {
								return getHyphenDDMMMYYYY(params.data.prim_stock_bill_date);
							}
							catch (e) {
								return "";
							}
						}

					}
				]
			}
		];

		return (
			<div className="col-xl-12 col-lg-12">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				<SweetAlert
					show={this.state.showreload}
					type={this.state.basicTypereload}
					title={this.state.basicTitlereload}
					onConfirm={this.closeAlertReload}
				>
				</SweetAlert>
				{this.state.alert}
				<div className="row">
					<div className="col-xl-5 col-lg-5">
						<div className=" card">
							<div className="row card-body" style={{ padding: "10px 30px" }}>
								<form className="row" onSubmit={this.getTruckConsignments.bind(this)}>
									<div className="form-group col-xl-6 col-lg-6">
										<label>Select Type   </label>
										<Select
											className="border-radius-0"
											isMulti={false}
											id="selectedFilter"
											style={{ borderRadius: "0px" }}
											options={[{
												label: "REGN_NO",
												vaue: "REGN_NO"
											},
											{
												label: "TRIP",
												value: "TRIP"
											},
											{
												label: "LOAD_NO", value: "LOAD_NO"
											}]} required />
									</div>
									<div className={"col-xl-6 col-lg-6 form-group"}>
										<label className="reason">REGN NO / TRIP / Load No</label>
										<input type="text" name="reason" autoComplete="off" class="form-control" id="inputTruckConsignment" />
									</div>
									<div className="form-group col-xl-12 col-lg-12" style={{ textAlign: "left" }}>
										<button type="submit" className="btn btn-success">Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div className="row col-xl-7 col-lg-7">
						<div className=" card">
							<div className="row card-body" style={{ padding: "10px 30px" }}>
								<div className="col-xl-6 col-lg-6 form-group">
									<label>From Date</label>
									<Datetime value={this.state.startDate}
										disableCloseOnClickOutside={false}
										closeOnSelect={true}
										inputProps={{ placeholder: 'From Date', name: 'startDate', autoComplete: 'off', readOnly: true }}
										dateFormat="YYYY-MM-DD"
										name="startDate"
										timeFormat={null}
										onChange={this.handlerStartDateTime.bind(this)}
										isValidDate={current => {
											return current.isSameOrBefore(moment(), 'day');
										}} />

								</div>

								<div className="col-xl-6 col-lg-6 form-group">
									<label>To Date</label>
									{/* <input type="text" placeholder="To Date" autoComplete="off" id="to_date" value={this.state.endDate}  className="datetimepicker_date form-control"  required /> */}
									<Datetime value={this.state.endDate}
										disableCloseOnClickOutside={false}
										closeOnSelect={true}
										inputProps={{ placeholder: 'To Date', name: 'endDate', autoComplete: 'off', readOnly: true }}
										dateFormat="YYYY-MM-DD"
										timeFormat={null}
										onChange={this.handlerEndDateTime.bind(this)}
										isValidDate={current => {
											return current.isSameOrBefore(moment(), 'day');
										}} />

								</div>
								<div className="form-group col-xl-12 col-lg-12" style={{ textAlign: "left", display: 'flex' }}>
									<div>
										<button type="button" style={{ margin: "0 auto" }} className="btn btn-success" disabled={activeExportState} onClick={this.onClickFilterButton.bind(this)}>Submit</button>
										&nbsp;<button type="button" style={{ margin: "0 auto" }} className="btn btn-info" disabled={activeExportState} onClick={this.onClickFilterResetButton.bind(this)}>Reset</button>
										&nbsp;&nbsp;
										<button type="button" style={{ margin: "0 auto" }} className="btn btn-info" onClick={this.onClickDownloadData.bind(this)} disabled={activeExportState}>Download Data</button>
									</div>
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									<div style={{ marginLeft: '50px' }}>{
										activeExportState && loading && (
											<Box ml={1} position="relative" display="inline-flex">
												<CircularProgress variant="determinate" value={percentageCalculation} style={{ width: 56, height: 56 }} />
												<Box
													top={0}
													left={0}
													bottom={0}
													right={0}
													position="absolute"
													display="flex"
													alignItems="center"
													justifyContent="center"
													fontSize={20}
													fontWeight="bold"
												>
													<Typography
														variant="h6"
														component="div"
														color="textSecondary"
													>
														{`${Math.round(percentageCalculation)}%`}
													</Typography>
												</Box>
											</Box>
										)}</div>


								</div>
							</div>
						</div>

					</div>
					<div className="col-xl-12 col-lg-12">
						<PrimaryCounters
							zerofifteen={this.state.zerofifteen.length}
							fifteenthirty={this.state.fifteenthirty.length}
							thirtyonefortyfive={this.state.thirtyonefortyfive.length}
							morefortyfive={this.state.morefortyfive.length}
							stockzerofifteen={parseInt(this.state.stockzerofifteen.length)}
							stockfifteenthirty={parseInt(this.state.stockfifteenthirty.length)}
							stockthirtyonefortyfive={parseInt(this.state.stockthirtyonefortyfive.length)}
							stockmorefortyfive={parseInt(this.state.stockmorefortyfive.length)}
							zeroamt={parseInt(this.state.zeroamt) + parseInt(this.state.stockzeroamt)}
							fifteen={parseInt(this.state.fifteen) + parseInt(this.state.stockfifteen)}
							thirty={parseInt(this.state.thirty) + parseInt(this.state.stockthirty)}
							moreforty={parseInt(this.state.moreforty) + parseInt(this.state.stockmoreforty)}
						/>
					</div>
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h5>
									<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span> {this.state.pageTitle} </span>

									<span className="">
										<button className="float-right custom-btn f14 white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
											<i className="icofont icofont-save"></i> Save Grid Layout
										</button>
										<button className="float-right custom-btn f14 white btn-info" style={{ marginRight: "10px" }} onClick={this.resetState.bind(this)}>
											<i className="icofont icofont-refresh"></i> Reset Default Layout
										</button>
									</span>

								</h5>
							</div>
							<div className="card-body">

								<div className="row">

									<div className={"dataLoadpage " + (this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " + (this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
									<div className="col-xl-12 col-lg-12">
										<div className="form-group col-xl-6 col-lg-6">
											<label>Filter By Plant :  </label>
											<select style={{ marginLeft: "2%", padding: "1%" }} className="form-conrol" id="selectedPlantFilter" onChange={this.plantChangeFilter.bind(this)}>
												<option value="all">ALL</option>
												<option value="NGPR">TVPN</option>
												<option value="BNGR">TVPB</option>
												<option value="SLGR">TVPS</option>
											</select>
										</div>
									</div>
									<div className="col-xl-12 col-lg-12">
										<div id="myGrid" style={{ height: "500px", width: "100%" }} className="ag-theme-balham">
											<AgGridReact
												modules={this.state.modules}
												columnDefs={columnwithDefs}
												defaultColDef={this.state.defaultColDef}
												rowData={this.state.rowData}
												enableCharts={false}
												autoGroupColumnDef={this.state.autoGroupColumnDef}
												onGridReady={this.onGridReady}
												onGridState={this.onGridState}
												statusBar={this.state.statusBar}
												sideBar={this.state.sideBar}
												paginationPageSize={this.state.paginationPageSize}
												pagination={true}
												enableRangeSelection={true}
												components={this.state.components}
												rowSelection={this.state.rowSelection}
												suppressRowClickSelection={true}
												onRowSelected={this.onRowSelected.bind(this)}
												frameworkComponents={this.state.frameworkComponents}
												gridOptions={{
													context: { componentParent: this }
												}}
												groupDefaultExpanded={this.state.groupDefaultExpanded}
												//floatingFilter={true}
												groupSelectsChildren={true}
												//onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
												onCellClicked={this.downloadFiles.bind(this)}
											/>
										</div>
									</div>
									<div className="col-xl-12 col-lx-12" style={{ textAlign: "right" }}>
										<button name="submit" style={{ marginRight: "2%" }} onClick={this.onClickBulkUpdateData.bind(this)} className="btn btn-info">Bulk Upload</button>
										<button name="submit" onClick={this.onClickUpdateData.bind(this)} className="btn btn-success">Update Now</button>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>

				<Modal open={open} onClose={this.onCloseModal} styles={modalStyles}>
					<div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', }} >

						<div className="col-xl-12 col-lg-12" style={{ marginLeft: "22px" }}>
							<form method="POST" className="theme-form col-xl-12 col-lg-12" enctype="multipart/form-data" onSubmit={this.formHandler.bind(this)}>

								<div className="form-group mt-20p">
									<label className="">Payment Date</label>
									<Datetime value={this.state.upload_bill_date}
										disableCloseOnClickOutside={false}
										closeOnSelect={true}
										inputProps={{ placeholder: 'Payment Date', name: 'upload_bill_date', autoComplete: 'off' }}
										dateFormat="YYYY-MM-DD"
										name="upload_bill_date"
										onChange={this.handlerBillDateTime.bind(this)} />

								</div>
								<div className="form-group mt-20p">
									<label className="">Upload File</label>
									<input type="file" name="uploadFile[]" multiple={true} onChange={this.changeFileHandler.bind(this)} className="form-control" required />
								</div>
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="button" onClick={this.formHandler.bind(this)} className="btn btn-success">Submit</button>
								</div>
								<br /><br /><br />
							</form>
						</div>

					</div>
				</Modal>

				<Modal open={rejectopen} onClose={this.onCloseModal} styles={modalStyles}>
					<div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', }} >

						<div className="col-xl-12 col-lg-12" style={{ marginLeft: "22px" }}>
							<form method="POST" className="theme-form col-xl-12 col-lg-12" enctype="multipart/form-data" onSubmit={this.formHandler.bind(this)}>
								<div className="form-group mt-20p">
									<label className="">Remarks</label>
									<textarea className="col-xl-12 col-lg-12" rows="8" name="remarks" value={this.state.remarks} onChange={this.changeHandler.bind(this)}></textarea>
								</div>

								<div className="col-xl-12 col-lg-12 form-group">
									<button type="button" onClick={this.onClickRejectSecondaryBill.bind(this)} className="btn btn-success">Submit</button>
								</div>
								<br /><br /><br />
							</form>
						</div>

					</div>
				</Modal>


				<Modal open={stockopen} onClose={this.onCloseModal} styles={modalStyles}>
					<div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', }} >

						<div className="col-xl-12 col-lg-12" style={{ marginLeft: "22px" }}>
							<form method="POST" className="theme-form col-xl-12 col-lg-12" enctype="multipart/form-data">

								<div className="form-group mt-20p">
									<label className="">Payment Date</label>
									<Datetime value={this.state.prim_stock_upload_bill_date}
										disableCloseOnClickOutside={false}
										closeOnSelect={true}
										inputProps={{ placeholder: 'Payment Date', name: 'prim_stock_upload_bill_date', autoComplete: 'off' }}
										dateFormat="YYYY-MM-DD"
										name="prim_stock_upload_bill_date"
										onChange={this.handlerStockBillDateTime.bind(this)} />

								</div>
								<div className="form-group mt-20p">
									<label className="">Upload File</label>
									<input type="file" name="stockuploadFile[]" multiple={true} onChange={this.changeFileHandler.bind(this)} className="form-control" required />
								</div>
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="button" onClick={this.formHandlerStockyard.bind(this)} className="btn btn-success">Submit</button>
								</div>
								<br /><br /><br />
							</form>
						</div>

					</div>
				</Modal>


				<Modal open={stockrejectopen} onClose={this.onCloseModal} styles={modalStyles}>
					<div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', }} >

						<div className="col-xl-12 col-lg-12" style={{ marginLeft: "22px" }}>
							<form method="POST" className="theme-form col-xl-12 col-lg-12" enctype="multipart/form-data" onSubmit={this.formHandler.bind(this)}>
								<div className="form-group mt-20p">
									<label className="">Remarks</label>
									<textarea className="col-xl-12 col-lg-12" rows="8" name="stockremarks" value={this.state.stockremarks} onChange={this.changeHandler.bind(this)}></textarea>
								</div>

								<div className="col-xl-12 col-lg-12 form-group">
									<button type="button" onClick={this.onClickStockRejectPrimaryBill.bind(this)} className="btn btn-success">Submit</button>
								</div>
								<br /><br /><br />
							</form>
						</div>

					</div>
				</Modal>
				{/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
				<div className={"slide-r " + (this.state.bulkslide)} style={{ overflow: "hidden" }}>
					<h3 className="subH">Bulk Upload</h3>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>

							<div className="form-group mt-20p">
									<label>Bulk Upload for :  </label>
									<Select
										className="border-radius-0"
										isMulti={false}
										id="uploadType"
										value={this.state.uploadType}
										style={{ borderRadius: "0px" }}
										onChange={this.onChangePaymentType}
										options={[
											{
												label: "Secondary",
												value: "Secondary_bulk"
											},
											{
												label: "Stockyard",
												value: "Stockyard_bulk"
											}
										]} required />
								</div>
								<div className="form-group mt-20p">
									<label className="">Bill Date</label>
									<Datetime value={this.state.upload_bill_date}
										disableCloseOnClickOutside={false}
										closeOnSelect={true}
										inputProps={{ placeholder: 'Bill Date', name: 'upload_bill_date', autoComplete: 'off' }}
										dateFormat="YYYY-MM-DD"
										name="upload_bill_date"
										onChange={this.handlerBillDateTime.bind(this)} />
								</div>
								<div className="form-group mt-20p">
									<label className="">Upload File</label>
									<input type="file" name="uploadFile[]" multiple={true} onChange={this.bulkchangeFileHandler.bind(this)} className="form-control" required />
								</div>

								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

				<div className={"slide-r " + (this.state.bulkslideExport)} style={{ overflow: "hidden" }}>
					<h3 className="subH">Export Data</h3>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.onClickSubmitDownloadData}>

							<div className="col-xl-12 col-lg-12 form-group">
									<label>From Date</label>
									<Datetime
										value={this.state.exportStart}
										disableCloseOnClickOutside={false}
										closeOnSelect={true}
										inputProps={{  name: 'startDate', autoComplete: 'off', readOnly: true }}
										dateFormat="YYYY-MM-DD"
										timeFormat={null} // Ensure timeFormat is set to null to prevent displaying time
										name="startDate"
										onChange={this.handlerStartDateTimeExport.bind(this)}
										isValidDate={current => {
											return current.isSameOrBefore(moment(), 'day');
										}}
									/>

								</div>

								<div className="col-xl-12 col-lg-12 form-group">
									<label>To Date</label>
									<Datetime
										value={this.state.exportEnd}
										disableCloseOnClickOutside={false}
										closeOnSelect={true}
										inputProps={{  name: 'endDate', autoComplete: 'off', readOnly: true }}
										dateFormat="YYYY-MM-DD"
										timeFormat={null}
										onChange={this.handlerEndDateTimeExport.bind(this)}
										isValidDate={current => {
											return current.isSameOrBefore(moment(), 'day');
										}} />
								</div>

								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

		);
	}
}

function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key))
			return false;
	}
	return true;
}
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num - (rhours * 3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}

function loadScript(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}
function dateScript(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}

function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1 / 180;
		var radlat2 = Math.PI * lat2 / 180;
		var theta = lon1 - lon2;
		var radtheta = Math.PI * theta / 180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180 / Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit == "K") { dist = dist * 1.609344 }
		if (unit == "N") { dist = dist * 0.8684 }
		return dist;
	}
}
function getDatePicker() {
	function Datepicker() { }
	Datepicker.prototype.init = function (params) {
		this.eInput = document.createElement("input");
		//this.eInput = this.eInput.setAttribute("class","datepicker");
		this.eInput.value = params.value;
		console.log(this.eInput)
		window.$(".datepicker").datepicker({ dateFormat: "dd/mm/yy" });
	};
	Datepicker.prototype.getGui = function () {
		return this.eInput;
	};
	Datepicker.prototype.afterGuiAttached = function () {
		this.eInput.focus();
		this.eInput.select();
	};
	Datepicker.prototype.getValue = function () {
		return this.eInput.value;
	};
	Datepicker.prototype.destroy = function () { };
	Datepicker.prototype.isPopup = function () {
		return false;
	};
	return Datepicker;
}



function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
	// create the cell
	this.eInput = document.createElement('input');
	this.eInput.value = params.value;

	// https://jqueryui.com/datepicker/
	$(this.eInput).datepicker({
		dateFormat: "yy-mm-dd",
		changeMonth: true,
		changeYear: true
	});
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
	return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
	this.eInput.focus();
	this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
	return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
	// but this example is simple, no cleanup, we could
	// even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
	// and we could leave this method out also, false is the default
	return false;
};
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
		date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
		var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
		date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
		// console.log(date1,date2);
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		// console.log(date1Number,date2Number);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		return date1Number - date2Number;
	}
}

function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName) {
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
	];
	var monthNum = months.indexOf(monthName) + 1;
	if (monthNum < 10) {
		monthNum = "0" + monthNum.toString();
	}
	return monthNum;
}
function dateComparator1(date1, date2) {
	//console.log(date1,date2);
	if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split("-")[1]);
		date1 = date1.replace(date1.split("-")[1], date_1);
		var date_2 = getMonthNumber(date2.split("-")[1]);
		date2 = date2.replace(date2.split("-")[1], date_2);
		var date1Number = monthToComparableNumber1(date1);
		var date2Number = monthToComparableNumber1(date2);
		console.log(date1Number, date2Number);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		return date1Number - date2Number;
	}
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import CSVFileValidator from 'csv-file-validator';
import Select from 'react-select';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault,getDDMMMYYYYDefault } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import BatchDownload from "./batchdownload";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;
var validatedBy="";



export default class TransitWHCoilDispatch extends Component {

      constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable : false,
            width : 160,
            resizable : true
        },
        eventAction: null,
        show: false,
        basicTitle:'',
        basicType:"default",
        loadshow:'show-n',
        overly:'',
        rowdata:[],
        sliderbulkupload : "",
        rowModelhighchartoptionsType: 'enterprise',
        frameworkComponents : {
            BatchDownload : BatchDownload,
             AlphaNumericEditor:  AlphaNumericEditor,

        },
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:50,
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        pagetitle:"",
        eventGridAction: "gridAction",
        csvcontent : [],
        file : "",
        batch_name : "",
        cluster_breaks : "2",
        cluster_radius : "0.5",
        notification_emails : "",
        setEditable:0,
        destination :"",
        container_name:"",
        show:false,
        basicTitle:"",
        basicType:""
    // this.downloadbatchfile = this.downloadbatchfile.bind(this);
  }

      }

  componentDidMount(){
    this.setState({
        loadshow : "show-m",
        overly : "show-m"
    })
    var params = {
        "screen_type":2
    }
    var tpt_code = localStorage.getItem("transportercode")
    if(tpt_code != undefined && tpt_code != null && tpt_code != ""){
        params.transporter_code = eval(tpt_code)
    }
    console.log(params,"145")
    redirectURL.post('/consignments/coiltrackingrequirements',params).then((response)=>{
        console.log(response.data,"140")
        
        this.setState({
            rowdata : response.data,
            loadshow : "show-n",
            overly : "show-n"
        })
    }).catch((e)=>{
        console.log(e)
    })
    loadDateTimeScript()
}
   

destinationchangeHandler = (e) => {
    console.log(e,"163")
    this.setState({
        destination: e,
    })
    console.log(this.state.destination.label,"167")
}
containernamechangeHandler= (e) => {
    this.setState({
        container_name: e,
    })
}
closeAlert = async() => {
    await this.setState({
        show: false
    });
  
}
changeHandler = (event) => {
 
    let name = event.target.name;
    let value = event.target.value;
    this.setState({[name]:value});
}

closeAlert = () => {
    this.setState({
        show: false
    });
}
onCellRowClicked=(e)=>{

    var rowId  = e.data._id;
    if(e.colDef.field=="dispatch_date_from_transit_warehouse"|| e.colDef.field=="destinationfromtransit")
    {
        if(e.data.dispatch_date_from_transit_warehouse == undefined || e.data.dispatch_date_from_transit_warehouse == "undefined" || e.data.dispatch_date_from_transit_warehouse == null || e.data.dispatch_date_from_transit_warehouse == "" ||
        e.data.destinationfromtransit == undefined || e.data.destinationfromtransit == "undefined" || e.data.destinationfromtransit == null || e.data.destinationfromtransit == ""  ){
            this.setState({
                bulkslide:"slider-translate-30p",
                rowId : rowId
            })
        }
    }
}
dispatchportchangeHandler = (e) => {
    this.setState({
        container_type: e,
    })
}


onClickShowFilterData(event) {
    event.preventDefault();
    var rowId = this.state.rowId;
 
    var dispatchDate= $("#etd_weekly").val();
    var destinationFromTransit=this.state.destination.value;
  
   
 

  
 
        var params = {
            destinationfromtransit:destinationFromTransit,
            dispatch_date_from_transit_warehouse:dispatchDate,

          rowId: rowId,
          screen_type: "2",

        };
  
        console.log(params, "257");
        var cnf = window. confirm("Do you Want to Submit")
        if(cnf === true)
        {
        redirectURL.post("/consignments/updatetrailorCoilStockInformation", params).then((response) => {
          $("#bookingId1").val("");
          if (response.data.message === "success") {
            this.setState({
              basicTitle: "Successfully Updated",
              basicType: "success",
              show: true,
              bulkslide: "",
              trailor_no: ""
            });
            this.componentDidMount();
          }
        });
        $("#etd_weekly").val("");
        this.onClickHideAll();
  
  }
}
  

  
  
  
  
  


onClickHideAll(){
    this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:""
        
    }); 
}

render() {
    var setEditable = this.state.setEditable;
    var enableEdit = false;
    if(setEditable === 1)
    {
        enableEdit = true;
    }
    var transporterEdit = ""
    if(localStorage.getItem("user_type")=="TRANSPORTER"){
        transporterEdit = true
    }
    else{
        transporterEdit = false
    }
    var transporternonedit = ""
    if(localStorage.getItem("user_type")=="TRANSPORTER"){
        transporternonedit = false
    }else{
        transporternonedit = true
    }
    const batchwisecolumns = [
       
        {
            headerName : "Coil Unique No.",
            field : "coil_unique_no",
           

        },
      
        {
            headerName : "Stock At Warehouse",
            field : "stock_at_warehouse",
          
        },
        {
            headerName : "Order received from SC",
            field : "order_received_from_sc",
            valueGetter:function(params){
                try{
                    return getDDMMMYYYYHHMMDefault(params.data.order_received_from_sc);
                }
                catch(e){
                    return "";
                }
            },
        },
        {
            headerName : "GST Registered W/H",

            field : "destinationfromtransit",
        },
        {
            headerName : "Dispatch Date from Transit W/H",
            field : "dispatch_date_from_transit_warehouse",
            cellEditor: DateEditor,
            editable:false,
           
            valueGetter:function(params){
                try{
                    return getDDMMMYYYYHHMMDefault(params.data.dispatch_date_from_transit_warehouse);
                }
                catch(e){
                    return "";
                }
            },
       
                
        },
      
    ]
    return (
    <div class="container-fluid">
        <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
      
        <div className="row">
            <div className="col-xl-12 col-lg-12" >
              			   			
                <div className="card" >
                <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> 
									   <span>Transit Warehouse Coil Dispatch</span>
				       			</h5>
				   			</div>
                               <div className="card-body">
                    <div className="row">
                        <div id="myGrid" style={{width:"100%",height:"478px"}} className="ag-theme-balham">    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={batchwisecolumns}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowdata}
                                enableCharts={false}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}                               
                                frameworkComponents={this.state.frameworkComponents}
                                rowClassRules={this.state.rowClassRules}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                onCellClicked={this.onCellRowClicked.bind(this)}
                                enableRangeSelection= {true}
                                // onCellClicked={this.onCellRowClicked.bind(this)}
                                />
                        </div>
                        
                        
                    </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
                    <h3 className="subH">Add Trailer Number
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                    </h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            
                            <form method="post" onSubmit={this.onClickShowFilterData.bind(this)} autocomplete="off" className="row col-xl-12 col-lg-12">
                                
                             <div  className="col-xl-12 col-lg-12 row form-group mt-20p">
                                <label className="col-xl-12 col-lg-12">GST registered W/H : </label>
                                            <Select 
                                            placeholder={"GST registered W/H"} 
                                            closeMenuOnSelect={true} 
                                            value={this.state.destination} 
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            onChange={this.destinationchangeHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={[{ label: "HRC", value: "HRC" },
                                            { label: "Patli", value: "Patli" }]} required
                                        />
                                </div>

                                
                              
                                {/* <div className="form-group mt-20p col-xl-12 col-lg-12" style={{paddingLeft:"0px",paddingRight:"27px"}}>
                                    <label className="col-xl-12 col-lg-12">Trailer Number: </label>
                                    <input type="text" name="bookingId1" id="bookingId1"   className="form-control" placeholder="Trailer Number" onChange={this.dispatchportchangeHandler} required />
                                </div> */}
                                <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12"> Dispatch Date from Transit W/H : </label>
                                            <input type="text" name="etd_weekly" id="etd_weekly"  className="datetimepicker_datew form-control" placeholder='Dispatch Date from Transit W/H' />
								</div>
                                {/* <div  className="col-xl-12 col-lg-12 row form-group mt-20p">
                                <label className="col-xl-12 col-lg-12">Transit W/H Transporter Name : </label>
                                            <Select 
                                            placeholder={"Transit W/H Transporter Name"} 
                                            closeMenuOnSelect={true} 
                                            // id={"transporterName"}
                                            // name={"transporterName"}
                                            value={this.state.container_name} 
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            onChange={this.containernamechangeHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={[{ label: "K M Trans Logistics", value: "K M Trans Logistics" },
                                            { label: "Arihant Trailer Service", value: "Arihant Trailer Service" },{ label: "Kalptaru Logistics", value: "Kalptaru Logistics"},
                                            { label: "Rakesh Road Carrier", value: "Rakesh Road Carrier" }]} required
                                        />

                                </div> */}
                               
                                <div className="form-group col-xl-3 col-lg-3">
                                        <button  type="submit" className="btn btn-success">Submit</button>
                                    </div>
                            
                        </form>
                        </div>
                    </div>
                </div>

    </div>
    );
  }
}
function AlphaNumericEditor() {}
AlphaNumericEditor.prototype.init = function(params) {
 this.eInput = document.createElement('input');
 if(params.value !== "" && params.value !== undefined)
 {
    this.eInput.value = params.value;
 }
 else{
    
    this.eInput.value = "";
    
 }
 
  var that = this;
  this.eInput.addEventListener('blur',()=>{
    var regex = /[^a-zA-Z0-9\s]/g;
  var matches = (this.eInput.value).match(regex);
  matches=matches? matches:[]

    if(this.eInput.value==""){
       
        alert("Field should not be Empty")
        
    }
    if (matches.length>0){
      
        alert("Field should not contain Special Characters")
    }
  })
 this.eInput.addEventListener('keypress', function(event) {
   
    console.log(event.key,"472")


 var regex = new RegExp("^[a-zA-Z0-9 _@-]+$");
 var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
 if (!regex.test(key)) {
 that.eInput.focus();
 return false;
 }
 });
};
AlphaNumericEditor.prototype.getGui = function() {
 return this.eInput;
};

AlphaNumericEditor.prototype.getValue = function() {
 var regex = new RegExp("^[a-zA-Z0-9]+$");
 var oVal = this.eInput.value;
 if (!regex.test(oVal) == true) {
    return "";
 }
 else{
    console.log(this.eInput.value,"his.eInput.value")
    if(this.eInput.value !== "" && this.eInput.value !== undefined)
    {
        return this.eInput.value;
    }
    else{
        return "";
    }
  
 }
};

AlphaNumericEditor.prototype.destroy = function() {};

AlphaNumericEditor.prototype.isPopup = function() {
   return false;
};


function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    console.log(params, "params")
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;


    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker: false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_datew').datetimepicker({
		//mask:'39-19-9999',
        format:'d-m-Y',
        minDate:true,
		timepicker:false,
        closeOnDateSelect: true,
        scrollMonth: false,
        scrollInput: false
    });
    $('.datetimepicker_dateo').datetimepicker({
		//mask:'39-19-9999',
        format:'d-m-Y',
        minDate:true,
		timepicker:false,
        closeOnDateSelect: true,
        scrollMonth: false,
        scrollInput: false
    });
    $('.cargo_date').datetimepicker({
		//mask:'39-19-9999',
        format:'d-m-Y',
        minDate:true,
		timepicker:false,
	});


}
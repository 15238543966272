

import { color } from "highcharts";
import { white } from "material-ui/styles/colors";
import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import Exportsffbooking from "../../layouts/exportsFfBookings";
import Exportschabooking from "../../layouts/exportsChaBookings";
import ExportsTransporterbooking from "../../layouts/exportsTransporterBookings";
var redirectURL = require('../../redirectURL');


const Exportbookings = () => { 
    const [activeCounter, setActiveCounter] = useState(1);
    const [requirementTypeFilterOptions, setrequirementTypeFilterOptions] = useState([]);
    const [loaderoptions, setloaderoptions] = useState({overly :"show-n",loadshow:"show-n"})

    var [selectProcessVal, setSelectProcessval] = useState({
        label: 'Pending',
        value: '1'
    })
    var [requirementTypeVal, setrequirementTypeVal] = useState({
        label: 'One Time Requirement',
        value: '1'
    })

    const showSubcounters = (counter) => {
        setActiveCounter(counter.id);
        setSelectProcessval({label: 'Pending',value: '1'})
        setrequirementTypeVal({label: "One Time Requirement",value: '1'})
    };
    const selectProcessFilterChange = (counter) => {
        setSelectProcessval(counter)
    };
    const requirementTypeFilterChange = (counter) => {
        setrequirementTypeVal(counter)
    };
    const selectProcessOptions = [
        {id: 1,
        label: "Pending",
        value: "1"
        },
        {id: 2,
            label: "In-Progress",
            value: "2"
        },
        {id: 3,
            label: "Closure",
            value:"3"
        }
    ]
    const selectProcessOptionsTransporter = [
        {id: 1,
        label: "Pending",
        value: "1"
        },
        {id: 3,
            label: "Closure",
            value:"3"
        }
    ]

 
    let subCounters1 = useRef([
        {
        id:1,
        name: "Freight Forwarder",
        activeimage:require("../../../assets/images/containertracking/ibwhiteicon.png"),
        inactiveimage: require("../../../assets/images/containertracking/ibblueicon_colored.png")
        },
        {
        id:2,
        name: "Customs House Agent",
        activeimage: require("../../../assets/images/containertracking/CHAwhiteicon.png"),
        inactiveimage:require("../../../assets/images/containertracking/CHAblueicon_colored.png")
        },
        {
        id:3,
        name: "Transporter",
        activeimage: require("../../../assets/images/containertracking/transportericon.png"),
        inactiveimage:require("../../../assets/images/containertracking/transporterblueicon_colored.png")
    }
    ])
    
    const selectStyles = {
        control: (provided, state) => ({
            ...provided,
            fontSize: '0.8rem',
            borderRadius: '11px',
            height: '30px',
            width: state.selectProps.width,
            textAlign: 'center',
            borderColor: '#DADEE2',
            boxShadow: 'none',
            '&:hover': {
                borderColor: '#171c8f',
            },
        }),
        option: (provided, state) => ({
            ...provided,
            width: state.selectProps.width,
            color: state.isSelected ? 'white' : '#171c8f',
            backgroundColor: state.isSelected ? '#171c8f' : '#fff',
            ':active': {
                backgroundColor: state.isSelected ? '#171c8f' : '#fff',
            },
            ':hover': {
                backgroundColor: state.isSelected ? '#171c8f' : '#d0d3eb',
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#3333333',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'auto', // Allow menu width to adjust to content
        }),
    };

    useEffect(() => {
        let data  = []
        setloaderoptions({overly :"show-m",loadshow:"show-m"})
        redirectURL.post("/consignments/getEntireContainersData")
       .then((response) => {
           if (response.status == 200){
               data = response.data.data
               setloaderoptions({overly :"show-n",loadshow:"show-n"})

               if (activeCounter == 1) {
                var onetimePendingCount = data.filter(item => (
                    (
                        (!item.container_details_file || item.container_details_file === "") &&
                        item.request_type == 1 &&
                        ![0, 2].includes(item.is_approved)
                    )
                )).length
    
                var onetimeInprogressCount = data.filter(item => (
                    item.container_details_file &&
                    !item.bill_of_lading_file &&
                    item.request_type == 1 &&
                    ![0, 2].includes(item.is_approved)
                )).length
    
                var weeklyPendingCount = data.filter(item => (
                    (
                        (!item.container_details_file || item.container_details_file === "") &&
                        item.request_type == 2 &&
                        ![0, 2].includes(item.is_approved)
                    )
                )).length
    
                var weeklyInprogressCount = data.filter(item => (
                    item.container_details_file &&
                    !item.bill_of_lading_file &&
                    item.request_type == 2 &&
                    ![0, 2].includes(item.is_approved)
                )).length
    
                var urgentPendingCount = data.filter(item => (
                    (
                        (!item.container_details_file || item.container_details_file === "") &&
                        item.request_type == 3 &&
                        ![0, 2].includes(item.is_approved)
                    )
                )).length
    
                var urgentInprogressCount = data.filter(item => (
                    item.container_details_file &&
                    !item.bill_of_lading_file &&
                    item.request_type == 3 &&
                    ![0, 2].includes(item.is_approved)
                )).length
    
    
                } else if (activeCounter == 2) {
                    var onetimePendingCount = data.filter(item => (
                        (
                            (!item.container_no || item.container_no === "") && item.container_details_file &&
                            item.request_type == 1 &&
                            ![0, 2].includes(item.is_approved)
                        )
                    )).length
        
                    var onetimeInprogressCount = data.filter(item => (
                        item.container_no &&
                        !item.custom_clearance_file &&
                        item.request_type == 1 &&
                        ![0, 2].includes(item.is_approved)
                    )).length
        
                    var weeklyPendingCount = data.filter(item => (
                        (
                            (!item.container_no || item.container_no === "") && item.container_details_file &&
                            item.request_type == 2 &&
                            ![0, 2].includes(item.is_approved)
                        )
                    )).length
        
                    var weeklyInprogressCount = data.filter(item => (
                        item.container_no &&
                        !item.custom_clearance_file &&
                        item.request_type == 2 &&
                        ![0, 2].includes(item.is_approved)
                    )).length
        
                    var urgentPendingCount = data.filter(item => (
                        (
                            (!item.container_no || item.container_no === "") && item.container_details_file &&
                            item.request_type == 3 &&
                            ![0, 2].includes(item.is_approved)
                        )
                    )).length
        
                    var urgentInprogressCount = data.filter(item => (
                        item.container_no &&
                        !item.custom_clearance_file &&
                        item.request_type == 3 &&
                        ![0, 2].includes(item.is_approved)
                    )).length
        
                } else if (activeCounter == 3) {
                    var onetimePendingCount = data.filter(item => (
                        (
                            (!item.truck_no || item.truck_no === "") && item.container_details_file &&
                            item.container_no && item.request_type == 1 &&
                            ![0, 2].includes(item.is_approved)
                        )
                    )).length
        
                    var onetimeInprogressCount = data.filter(item => (
                        (
                            ![ "", NaN, undefined ].includes(item.truck_no) && item.container_details_file &&
                            item.container_no && item.request_type == 1 &&
                            ![0, 2].includes(item.is_approved)
                        )
                    )).length
        
                    var weeklyPendingCount = data.filter(item => (
                        (
                            (!item.truck_no || item.truck_no === "") && item.container_details_file &&
                            item.container_no && item.request_type == 2 &&
                            ![0, 2].includes(item.is_approved)
                        )
                    )).length
        
                    var weeklyInprogressCount = data.filter(item => (
                        (
                            ![ "", NaN, undefined ].includes(item.truck_no) && item.container_details_file &&
                            item.container_no && item.request_type == 2 &&
                            ![0, 2].includes(item.is_approved)
                        )
                    )).length
        
                    var urgentPendingCount = data.filter(item => (
                        (
                            (!item.truck_no || item.truck_no === "") && item.container_details_file &&
                            item.container_no && item.request_type == 3 &&
                            ![0, 2].includes(item.is_approved)
                        )
                    )).length
        
                    var urgentInprogressCount = data.filter(item => (
                        (
                            ![ "", NaN, undefined ].includes(item.truck_no) && item.container_details_file &&
                            item.container_no && item.request_type == 3 &&
                            ![0, 2].includes(item.is_approved)
                        )
                    )).length
                }
                if (activeCounter == 1 || activeCounter == 2){
                        setrequirementTypeFilterOptions([
                            {id: 1,
                            label: `One Time Requirement (${onetimePendingCount}/${onetimeInprogressCount})`,
                            value: "1"
                            },
                            {id: 2,
                                label: `Weekly Requirement (${weeklyPendingCount}/${weeklyInprogressCount})`,
                                value: "2"
                            },
                            {id: 3,
                                label: `Urgent Requirement (${urgentPendingCount}/${urgentInprogressCount})`,
                                value:"3"
                            }
                        ])
                    }
                else{
                    setrequirementTypeFilterOptions([
                        {id: 1,
                        label: `One Time Requirement (${onetimePendingCount})`,
                        value: "1"
                        },
                        {id: 2,
                            label: `Weekly Requirement (${weeklyPendingCount})`,
                            value: "2"
                        },
                        {id: 3,
                            label: `Urgent Requirement (${urgentPendingCount})`,
                            value:"3"
                        }
                    ])

                }
                }
            else{
                setloaderoptions({overly :"show-m",loadshow:"show-m"})
                setrequirementTypeFilterOptions([
                    {id: 1,
                    label: `One Time Requirement (${0}/${0})`,
                    value: "1"
                    },
                    {id: 2,
                        label: `Weekly Requirement (${0}/${0})`,
                        value: "2"
                    },
                    {id: 3,
                        label: `Urgent Requirement (${0}/${0})`,
                        value:"3"
                    }
                ])
            }
       })

    }, [activeCounter]); // Run this effect whenever activeCounter changes

    return( 
    <div class="card d-flex flex-row">
        <div className={"dataLoadpageimg " +(loaderoptions.loadshow)}>
                <div className="loader-box">
                        <div className="loader-box">
                            <div className="rotate dashed colored"></div>
                        </div>
                </div>
        </div>
        <div className={"overlay-part "+(loaderoptions.overly)} ></div>

         <div className="d-flex  flex-column ml-1 mt-3 mb-3" style={{width:"12%" , height:"auto", backgroundColor:"#fff", borderRadius:"10px"}}>   
            {subCounters1.current.map((counter) => (
                <div class= "counters d-flex flex-column align-item-center justify-content-center pr-2 pl-2"
                    key={counter.id}
                    style={{
                        // boxShadow: "0 1px 11px 0 rgb(0 0 0 / 10%)",
                        backgroundColor: activeCounter === counter.id ? '#FFFFFF' : '#FFFFFF',
                        borderBottom: (counter.id == 1 || counter.id == 2 ) ? "1px solid #CCCCCC" : "0px solid #808080",
                        color: activeCounter == counter.id ? '#171c8f' : '#333333' ,
                        cursor: "pointer",

                    }}
                    onClick={() => showSubcounters(counter)}
                >
                <div class= "d-flex flex-row justify-content-left align-items-center">
                    {/* <img
                        src={activeCounter === counter.id ? counter.activeimage : counter.inactiveimage}
                        alt="Icon"
                        style={{ height: '35%', margin: '6px' }}
                    /> */}
                    <h5 className="sptile-tracking"
                    style={{
                        fontWeight:activeCounter === counter.id ? 'bold' : "",
                    }}
                     >
                        {counter.name}
                    </h5>

                </div>
                </div>
            ))}
        </div>
        <div className= "d-flex  flex-column mr-3 mt-3 mb-3 p-3" style={{width:"88%", backgroundColor:"#F4F4F8",borderRadius:"10px"}}>
            <div className="container-booking-dropdowns d-flex flex-row" >
            <div className="m-3 p-2 col-xl-3 col-lg-3">
                    <h5 className="select-text">Requirement Type</h5>
                    <div>
                    <Select
                            closeMenuOnSelect={true}
                            name="requirementTypeFilter"
                            className="SelectProessFilter-css"
                            styles={selectStyles}
                            onChange={requirementTypeFilterChange}
                            options={requirementTypeFilterOptions}
                            value={requirementTypeVal}
                            width = "240px"
                        />

                    </div>
                    
                </div>

                <div className="m-3 p-2 col-xl-2 col-lg-2">
                    <h5 className="select-text">Status</h5>
                    <Select
                            closeMenuOnSelect={true}
                            name="SelectProessFilter"
                            className="SelectProessFilter-css"
                            styles={selectStyles}
                            onChange={selectProcessFilterChange}
                            options={activeCounter == 3 ? selectProcessOptionsTransporter : selectProcessOptions }
                            value={selectProcessVal}
                            width = "180px"
                        />
                

                </div>
                
            </div>
            <div styles={{backgroundColor:white, border:"0px white solid", borderRadius:"10px"}}>
                {console.log("active",activeCounter)}
                {activeCounter == 1 && <Exportsffbooking user={activeCounter} process={selectProcessVal.value} requirement={requirementTypeVal.value} />}
                {activeCounter == 2 && <Exportschabooking user={activeCounter} process={selectProcessVal.value} requirement={requirementTypeVal.value} />}
                {activeCounter == 3 && <ExportsTransporterbooking user={activeCounter} process={selectProcessVal.value} requirement={requirementTypeVal.value} />}

            </div>
        </div>
    </div>
    )  
}

 
export default Exportbookings
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";

import axios from 'axios';

import Grid from '../layouts/gridnosidebarComponent';
import Modal from 'react-responsive-modal';
import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react';
 
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");


export default class ManageSNDConsignments extends Component {

	constructor(props){
		super(props);
		this.state={ 
			pageTitle: "Manage Consignees List",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			rowData:null,
			uploadDivWidth:'0%',
			sliderTranslate:'',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			usermanualmodal:false
			
		}
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}

	componentDidMount(){
		 /*Consignments List*/	
		 this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

	    redirectURL.post('/consignments/snd')    
		  .then((response) => {
            var records = JSON.parse(JSON.stringify(response)).data;
            console.log("records", records);
            this.setState({
                rowData:records
            });
		  })
		  .catch(function (error) {
		    console.log(error);
            });
	};
	onShowUploadDiv = () => {
		console.log("Click Upload Button")
		this.setState({
			uploadDivWidth:'30%',
			sliderTranslate:"slider-translate",
			showDiv:'show-m',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };
    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
		
		const {usermanualmodal} = this.state;
		const columnwithDefs = [
	  	        {
	  	          headerName: "Consignee Name",
	  	          field: "consignee_name",
	  	          width: 150,
	    	          editable:false,
	    	          filter: "agSetColumnFilter"
		        },
		        {
	  	          headerName: "Consignee Code",
	  	          field: "consignee_code",
	  	          width: 150,
	    	          editable:false,
	    	          filter: "agSetColumnFilter"
				},
				{
	  	          	headerName: "Latitude",
	  	          	field: "coordinates",
	  	          	width: 150,
					editable:false,
					valueGetter:function(params){
						try{
							var corrds = params.data.coordinates;
							var cordinates = corrds.replace(/'/g, '"')
							return JSON.parse(cordinates).lat;
						}
						catch(e){
							return {"lat":0, "lng":0};
							
						}
						
					},
					filter: "agSetColumnFilter"
		        },
				{
	  	          	headerName: "Longitude",
	  	          	field: "coordinates",
	  	          	width: 150,
					editable:false,
					valueGetter:function(params){
						try{
							var corrds = params.data.coordinates;
							var cordinates = corrds.replace(/'/g, '"')
							return JSON.parse(cordinates).lng;
						}
						catch(e){
							return {"lat":0, "lng":0};
						}
						
					},
					filter: "agSetColumnFilter"
		        },
				{
	  	          	headerName: "For Code",
	  	          	field: "for_code",
	  	          	width: 150,
					editable:false,
					filter: "agSetColumnFilter"
		        },
				{
	  	          	headerName: "Consignee City",
	  	          	field: "consignee_city",
	  	          	width: 150,
					editable:false,
					filter: "agSetColumnFilter"
		        },
				{
	  	          	headerName: "Address 1",
	  	          	field: "consignee_addr1",
	  	          	width: 150,
					editable:false,
					filter: "agSetColumnFilter"
		        },
				{
	  	          	headerName: "Address 2",
	  	          	field: "consignee_addr2",
	  	          	width: 150,
					editable:false,
					filter: "agSetColumnFilter"
		        },
				{
	  	          	headerName: "Address 3",
	  	          	field: "consignee_addr3",
	  	          	width: 150,
					editable:false,
					filter: "agSetColumnFilter"
		        }
	  	        
	    ];
		return(
			<div className="container-fluid">
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        > 
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pageTitle} </span>
									{/*
									<button type="button" onClick={this.onShowUploadDiv.bind(this)} className="btn btn-success pull-right cs-btn"> <i className="icofont icofont-upload-alt"></i> Testing</button>
				       				*/}
								</h5>
				   			</div>				   			
		            		<div className="card-body">
				   				   <Grid 
								   ashow={this.state.show} 
								   atype={this.state.basicType} 
								   atitle={this.state.basicTitle}
								   actionCall={"manage"}
									actionMethod={"updateTruckRow"}  
									table={"trucklocation"} 
									columnyaxis={this.state.rowData} 
									columnaxis={columnwithDefs} 

									/>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				 <div className={"slide-r "+(this.state.sliderTranslate)} style={{overflow:"auto"}}>
				 	
					 <div className="slide-r-title">
				 		<h4>
                            Sidebars
                        </h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
						
				 		<div className="col-xl-12 col-lg-12">
				 		<div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg "+(this.state.alerterrshow)+" "+(this.state.alerterrmg)}>{this.state.csverrmessage}</div>
						 <Timeline lineColor={'#ddd'}>
							<TimelineItem
								key="001"
								dateText="11/2010 – Present"
								dateInnerStyle={{ background: '#76bb7f', color: '#fff' }}
								bodyContainerStyle={{
								background: '#ddd',
								padding: '20px',
								borderRadius: '8px',
								boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
								}}
							>
								<p>
								123Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
								exercitation.</p>
							</TimelineItem>
							<TimelineItem
								key="002"
								dateText="04/2009 – 11/2010"
								dateInnerStyle={{ background: '#76bb7f', color: '#fff' }}
								bodyContainerStyle={{
								background: '#ddd',
								padding: '20px',
								borderRadius: '8px',
								boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
								}}
							>
								<p>
								456Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
								exercitation.</p>
							</TimelineItem>
							<TimelineItem
								key="003"
								dateComponent={(
								<div
									style={{
									display: 'block',
									float: 'left',
									padding: '10px',
									background: 'rgb(150, 150, 150)',
									color: '#fff',
									}}
								>
									11/2008 – 04/2009
								</div>
								)}
							>
								<p>
								789Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
								exercitation.</p>
							</TimelineItem>
							<TimelineItem
								key="004"
								dateText="08/2008 – 11/2008"
								dateInnerStyle={{ background: '#76bb7f' }}
							>
								<p>
								012Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
								exercitation.</p>
							</TimelineItem>
							
							</Timeline>
							<div className="row col-xl-12 col-lg-12 mb-30p">
								<button type="button" onClick={this.onCloseUploadDiv.bind(this)} className="btn btn-default cs-btn"> Cancel </button>
							</div>
				 		</div>
				 	</div>
				 </div>

				 
				<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
				<Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
					<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<h5 className="model-title">Consignees List</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							<div className="col-xl-12 col-lg-12">
								This is the master which contains the details of consignee locations. This data consists of details like address,  geofence  co-ordinates, MSIL internal markings to identify the location 	
							</div>
							
							<div className="col-xl-12 col-lg-12">
								<ul>
									<li>
										<span style={{fontWeight:"bold"}}>1. For SND Dept:</span>
										<div className="col-xl-12 col-lg-12">
											<table className="table table-border">
												<tr>
													<th>Column</th>
													<th>
														Remarks
													</th>
												</tr>
												<tr>
													<td>
													Consignee Name
													</td>
													<td>
													Name of Consignee
													</td>
												</tr>
												<tr>
													<td>
													Consignee Code
													</td>
													<td>
													Code provided by MSIL
													</td>
												</tr>
												<tr>
													<td>
													Latitude
													</td>
													<td>
													GPS co-ordinates
													</td>
												</tr>
												<tr>
													<td>
													Longitude
													</td>
													<td>
													GPS co-ordinates
													</td>
												</tr>
												<tr>
													<td>
													For Code
													</td>
													<td>
													Code provided by MSIL
													</td>
												</tr>
												<tr>
													<td>
													Consignee City
													</td>
													<td>
													City of consignee point
													</td>
												</tr>
												<tr>
													<td>
													Address 1
													</td>
													<td>
													address of consignee 
													</td>
												</tr>
												<tr>
													<td>
													Address 2
													</td>
													<td>
													address of consignee 
													</td>
												</tr>
												<tr>
													<td>
													Address 3
													</td>
													<td>
													address of consignee 
													</td>
												</tr>
											</table>
										</div>
									</li>
									<li>
									<span style={{fontWeight:"bold"}}>2. For PRT Dept:</span>
										<div className="col-xl-12 col-lg-12">
											<table className="table table-border">
												<tr>
													<th>Column</th>
													<th>
														Remarks
													</th>
												</tr>
												<tr>
													<td>
													Consignee Name
													</td>
													<td>
													Name of Consignee
													</td>
												</tr>
												<tr>
													<td>
													Consignee Code
													</td>
													<td>
													Code provided by MSIL
													</td>
												</tr>
												<tr>
													<td>
													Latitude
													</td>
													<td>
													GPS co-ordinates
													</td>
												</tr>
												<tr>
													<td>
													Longitude
													</td>
													<td>
													GPS co-ordinates
													</td>
												</tr>
												
												<tr>
													<td>
													Address 
													</td>
													<td>
													Address of Destination
													</td>
												</tr>
												<tr>
													<td>
													Radius
													</td>
													<td>
													Geofence radius of destination
													</td>
												</tr>
												<tr>
													<td>
													Unloading Time
													</td>
													<td>
													Unloading time allocated by MSIL 
													</td>
												</tr>
											</table>
										</div>
									</li>
									<li>
										<span style={{fontWeight:"bold"}}>3. For TNP Dept:</span>
										<div className="col-xl-12 col-lg-12">
										This feature is not available for TNP department as of now
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</Modal>
            </div>
              	
		);
	}
}
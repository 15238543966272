/* eslint-disable no-loop-func */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import NewDrawMap from '../common/newDrawMap'
import { Map, InfoWindow, Marker, GoogleApiWrapper, Polygon, Polyline } from 'google-maps-react';

import SweetAlert from 'react-bootstrap-sweetalert';
import _, { join } from "lodash";
import Select from 'react-select';
import { getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import $ from 'jquery';
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var map;
var colorPickers = ["#000000", "blue", "green", "brown"]
var plottedPolylines= []
var pathcolor = ["#157254", "blue", "red"]
var directionsDisplays = [];
var waypoints = [];
var markersArray;
var infoBox = require('../common/google-infowindow');


export default class BidhadiSourceDestinationMap extends Component {
    state={
        loadshow:'show-n',
        overly:'show-n',
        destination : {},
        source : {
            "label": "Bidadi",
            "value": "Bidadi"
        },
        // sourceOptions : [],
        destinationOptions : [],
        show : false, 
        route_coords : [],
        mapfor : '',
        loadMap : 0,
        city_fences:[],
        pagetitle : "Bidadi To Destination Route Trip Analysis",
        tripRoutes : [],
        gps_distance_kms1:[],
        gps_distance_kms2:[],
        gps_distance_kms3:[],
        gpsdata1:[],
        gpsdata2:[],
        gpsdata3:[]
    }

    componentDidMount(){
        this.onLoadPageData(1)
        this.renderMap()
    }

    onLoadPageData = (args) =>{
        this.setState({
            overly: 'show-m',
            loadshow : 'show-m'
        })
        if(args === 1){
            var params = {
                type : args
            }
            console.log('testing')
        }else{
            params = args
        }
        redirectURL.post("/dashboard/getBidhadiSourceDestinationData",params ).then(response =>{
            console.log("response", response.data)
            var gpsdistancekms = response.data.fetchData
            console.log(gpsdistancekms,"gpsdistancekms")
            if(gpsdistancekms!="" && gpsdistancekms!=undefined)
            {
                var gpsdata1 = 0
                if(gpsdistancekms.length)
                {
                    if(["", null, undefined, NaN].includes(gpsdistancekms[0].ref_route_avg_gps_distance) == false) 
                    {
                        gpsdata1 = gpsdistancekms[0].ref_route_avg_gps_distance
                    }
                }

                var gpsdata2 = 0
                if(gpsdistancekms.length > 1)
                {
                if(["", null, undefined,"undefined", NaN].includes(gpsdistancekms[1].ref_route_avg_gps_distance) == false) 
                    {
                       gpsdata2 = gpsdistancekms[1].ref_route_avg_gps_distance
                    }
                }

                var gpsdata3 = 0
                if(gpsdistancekms.length > 2)
                {
                if(["", null, undefined,"undefined", NaN].includes(gpsdistancekms[2].ref_route_avg_gps_distance) == false) 
                    {
                       gpsdata3 = gpsdistancekms[2].ref_route_avg_gps_distance
                    }
                }

                // var gpsdata2 = gpsdistancekms[1].ref_route_avg_gps_distance
                // var gpsdata3 = gpsdistancekms[2].ref_route_avg_gps_distance
            }
           
            console.log(gpsdata1,"gpsdata1")
            // if(gpsdistancekms!="" && gpsdistancekms!=undefined)
            // {
            //     gpsdistancekms.map((e) => {
            //         console.log(e.ref_route_avg_gps_distance,"datagps")
            //         gpsdata .push(e.ref_route_avg_gps_distance)
            //     })
            // }
            
            if(args  === 1){
                // let sourceList = response.data.otpt.sList;
                let destinationList = response.data.otpt.dList
                this.setState({
                    // sourceOptions : sourceList,
                    destinationOptions : destinationList,
                    gps_distance_kms1:gpsdata1,
                    gps_distance_kms2:gpsdata2,
                    gps_distance_kms3:gpsdata3,
                    overly: 'show-n',
                    loadshow : 'show-n'
                })
            }else{
                console.log(response.data.fetchData, "response.data.fetchData")
                this.setState({
                    route_coords : response.data.fetchData,
                    loadMap : 1,
                    city_fences: response.data.fences,
                    gps_distance_kms1:gpsdata1,
                    gps_distance_kms2:gpsdata2,
                    gps_distance_kms3:gpsdata3,
                    overly: 'show-n',
                    loadshow : 'show-n'
                })
                this.renderMap()
            }

        })

    }

    renderMap = () => {
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
		this.enableCheckBoxes()

	}

    initMap = () =>{
        const {route_coords} = this.state
        var arr = route_coords;
        // console.log(arr, "arr")
        var lt = 12.7969;
		var ln = 77.3838;
        

        
        try {
			var mapOptions = {
                    labels: true,
                    fullscreenControl: true,
                    zoom: 7,
                    panControl: true,
                    zoomControl: true,
                    mapTypeControl: true,
                    scaleControl: true,
                    streetViewControl: true,
                    overviewMapControl: true,
                    rotateControl: true,
                    mapTypeControlOptions: {
                        mapTypeIds: ['satellite', 'roadmap'],
                    },
                    center: new window.google.maps.LatLng(lt,ln),
                    mapTypeId: window.google.maps.MapTypeId.ROADMAP
			};

		}
		catch (e) {
			mapOptions = {
				zoom: 13,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: true,
				fullscreenControl: true,
				labels: true,
				mapTypeControlOptions: {
					mapTypeIds: ['satellite', 'roadmap'],
				},
				center: new window.google.maps.LatLng(28.4519751, 77.0310713),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP
			};

		}
		try {
			// console.log("map load check")
			map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
		} catch (e) {
			console.log(e, "map")
		}
        var bounds = new window.google.maps.LatLngBounds();
		bounds.extend(new window.google.maps.LatLng(lt, ln));
        if(arr.length > 0 ){
            var arr2 = [];
            var darr = [];
            var bdadiTripsList = []
            arr.map(e =>{
                // console.log("mapE",e)
                    var temp = eval(e['route_coords'])
                    bdadiTripsList.push(temp)
                    if(temp !==  ''){
                        darr = temp.filter( (value, index,temp) => {
                            return (value.dist_from_prev_point > 0);
                        });
                    }
                    if (darr.length < 2000) {
                        var ratio = 1;
                    }
                    else {
                         ratio = 20;
                    }
                    //console.log("darr.length ", darr.length)
                    arr2.push(darr.filter(function (value, index, darr) {
                        return (index % ratio == 0);
                    }));
            })
            // console.log("ararrat",arr2)
        
                var routeinfo = bdadiTripsList;
                plottedPolylines = [];
                // console.log(arr, "arrCheck")
                this.setState({
                    tripRoutes : arr
                })
                arr2.map((e, index)=>{
                    // console.log("error",e)
                    this.plotMultiColorPolyline(e, map, bounds,index)
                })
                var infowindow = new window.google.maps.InfoWindow();
                var marker, l;
                var z;
                for(z=0;z<arr2.length;z++){
                        // var locations = this.props.mapinfo.coords[z]['breaks']
                        var route_wise_coords = arr2[z]
                        // start location
                        // console.log("route_ise",route_wise_coords)
                        if(route_wise_coords !== undefined){
                            if(route_wise_coords[0].lat !== undefined && route_wise_coords[0].lng !== undefined ){
                                marker = new window.google.maps.Marker({
                                    position: new window.google.maps.LatLng(route_wise_coords[0].lat, route_wise_coords[0].lng),
                                    icon: require('../../assets/icons/track_start.png'),
                                    map: map,
                                });
                            }
                            // console.log("marker780",marker)
                            if(marker !== undefined){
                                window.google.maps.event.addListener(marker, 'click', (function (marker) {
                                    // console.log("checking")
                                    return function () {
                                        //	console.log(clusters[0])	
                                        //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                                        var contentarr = []
                                        //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                                        var header = "Starting Point"
                                        contentarr.push({ "key": "Started at", "value": route_wise_coords[0].timestamp })		
                                        var contentString = infoBox(marker.icon, header, contentarr, '')		
                                        infowindow.setContent(contentString);
                                        // currentwindow = infowindow;
                                        infowindow.open(map, marker);
                                        // console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
                                    }
                                })(marker));
                            }
                            // end location
                            // console.log("route_wise_coords",route_wise_coords[(route_wise_coords.length - 1)])
                            marker = new window.google.maps.Marker({
                                position: new window.google.maps.LatLng(route_wise_coords[(route_wise_coords.length - 1)].lat,
                                                                                            route_wise_coords[(route_wise_coords.length - 1)].lng),
                                icon: require('../../assets/icons/truck-end.png'),
                                map: map,
                            });
                            window.google.maps.event.addListener(marker, 'click', (function (marker) {
                                return function () {
                                    //	console.log(clusters[0])	
                                    //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
                                    var contentarr = []
                                    //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                                    var header = "End Point"
                                    contentarr.push({ "key": "End at", "value": route_wise_coords[(route_wise_coords.length - 1)].timestamp })
                                    var contentString = infoBox(marker.icon, header, contentarr, '')	
                                    infowindow.setContent(contentString);
                                    infowindow.setContent(contentString);
                                    // currentwindow = infowindow;
                                    infowindow.open(map, marker);
                                    // console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
                                }
                            })(marker));
                        }
                }
        
        // view markers code
        try{
            // plant icon 
            
            let plantLat = route_wise_coords[0].lat;
            let plantLng = route_wise_coords[0].lng;

            let plantMarker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(plantLat, plantLng),
                icon: require('../../assets/icons/business.png'),
                map
            })
            window.google.maps.event.addListener(plantMarker, 'mouseover', (function (plantMarker, index) {
                return function () {
                    var contentarr = []
                    var header = "Plant"
                    // contentarr.push({"key":"Code", "value":consignee_code})
                    var contentString = infoBox(plantMarker.icon, header, contentarr)
                    infowindow.setContent(contentString);
                    var currentwindow = infowindow;
                    infowindow.open(map, plantMarker);
                }
            })(plantMarker,));
            window.google.maps.event.addListener(plantMarker, 'mouseout', function () {
                infowindow.close();
            });
            
            // dealer icon							
            this.props.mapinfo.consignee_coordinates.map((q)=>{
                let dealerLat = JSON.parse(q).lat;
                let dealerLng = JSON.parse(q).lng;

                let dealerMarker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(dealerLat, dealerLng),
                    icon: require('../../assets/icons/business.png'),
                    map
                })
                window.google.maps.event.addListener(dealerMarker, 'mouseover', (function (dealerMarker, index) {
                    return function () {
                        var contentarr = []
                        var header = ""
                        contentarr.push({"key":"", "value":"Dealer"})
                        var contentString = infoBox(dealerMarker.icon, header, contentarr)
                        infowindow.setContent(contentString);
                        var currentwindow = infowindow;
                        infowindow.open(map, dealerMarker);
                    }
                })(dealerMarker,));
                window.google.maps.event.addListener(dealerMarker, 'mouseout', function () {
                    infowindow.close();
                });
            })

        }
        catch(e){
            console.log("marker issue", e)
        }
        var city_coordinates = JSON.parse(this.state.city_fences[0].city_coordinates)
        // console.log(city_coordinates.lat, typeof(city_coordinates), "e.city_coordinates")
        var markerLatlng1 = new window.google.maps.LatLng(city_coordinates.lat, city_coordinates.lng);
        // console.log("markerLatlng", markerLatlng1)
        var circle = new window.google.maps.Circle({
            strokeColor: '#FF0000',
            strokeOpacity: 0.7,
            strokeWeight: 1,
            fillColor: '#FF0000',
            fillOpacity: 0.25,
            map: map,
            center: markerLatlng1,
            radius: this.state.city_fences[0]["radius_km"]*1000
        });
    }

    }

    plotMultiColorPolyline(arr2, map, bounds, route_no=null) {
		// console.log(arr2, "check coords")
		arr2 = arr2.filter(e => e.lat != NaN)
		// console.log(arr2, "checking route")		
	
        if (arr2.length <= 1000) {
            var icon_cnt = 60;
            var icon_divisible = 60;
        }
        else {
            // console.log("stp2")
            icon_cnt = 500;
            icon_divisible = 500;
        }
	
		// console.log(arr2, "coords")
		var route_polylines = [];
		for (var i = 0; i < arr2.length - 1; i++) {
			var locationLatLng = [];			
				locationLatLng.push(new window.google.maps.LatLng(arr2[i].lat, arr2[i].lng));
				locationLatLng.push(new window.google.maps.LatLng(arr2[i + 1].lat, arr2[i + 1].lng));			
			
			var lineicon = [];
			// if(["multiroutedeviation", "geofence"].includes(this.props.mapFor) == false)
			// {
			// 	if (icon_cnt % icon_divisible == 0) {
			// 		var lineicon = [{
			// 			icon: {
			// 				path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
			// 				strokeColor: '#FF0000',
			// 				fillColor: '#FF0000',
			// 				fillOpacity: 1,
			// 				strokeWeight: 2
			// 			},
			// 			repeat: '100px',
			// 			path: []
			// 		}];
			// 	} 
			// }			
			icon_cnt = icon_cnt + 1
			var consignments_missing_route_line = new window.google.maps.Polyline({
				map: map,
				path: locationLatLng,
				strokeColor: '#157254',
				strokeOpacity: 1.0,
				strokeWeight: 2.5,
				icons: lineicon
			});
			route_polylines.push(consignments_missing_route_line)
			var is_straight_line = 0
			if(arr2[i].dist_from_prev_point >= 20000)
			{
				is_straight_line = 1
			}
			if (arr2[i].msgtype == "G") {
				consignments_missing_route_line.setOptions({
					strokeColor: "#452a68",
					strokeWeight: 5.5,
				})
			}
            consignments_missing_route_line.setOptions({
                strokeColor: colorPickers[route_no],
                strokeWeight: 3.5,
            })
			if(is_straight_line)
			{
				consignments_missing_route_line.setOptions({
					strokeColor: "red",
					strokeWeight: 3.5,
				})
			}
			bounds.extend(new window.google.maps.LatLng(arr2[i].lat, arr2[i].lng));
		}
		if(arr2.length !== 0) plottedPolylines.push(route_polylines)
	}

    formHandler = e =>{
        e.preventDefault()
        const {source, destination} = this.state
        if(source.value !== "" && source.value !== undefined && destination.value !== "" && destination.value !== undefined){
            let reqParam = {
               'consigner_code': source.value,'consignee_city': destination.value
            }
            this.onLoadPageData(reqParam)

        }else{
            this.setState({
                loadshow: 'show-n',
                overly: 'show-n',
                basicTitle:"Select Source and Destination",
                basicType : "warning",
                show : true
            });
        }

    }

    onSelectSource = source =>{
        this.setState({source})
    }

    onSelectDestination = destination =>{
        this.setState({destination})
    }

    onClickHideAll = () => {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
        });
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
	}

    showMultiMarkers = (route_no) =>{
		// console.log(plottedPolylines, "checking polyline")
		if(plottedPolylines.length !== 0){
			if(plottedPolylines[route_no] === 'Approved_Route'){
				let directionsDisplay = this.state.directionsDisplay;
				if (directionsDisplay.getMap() != null){
					directionsDisplay.setMap(null);
				}else{
					// console.log(this.state.mapObject, "mapObject")
					let directionsDisplay = new window.google.maps.DirectionsRenderer(this.state.mapObject);
					this.setState({directionsDisplay})
				};
			}else{
				plottedPolylines[route_no].map((e)=>{
					// console.log(e, "polyline")				
					var idname = route_no+"_check"
					$("#"+idname).prop("checked",!e.getVisible())
					e.setVisible(!e.getVisible())				
					// if(!e.getVisible())
					// {
					// 	$("#"+this.props.mapinfo.coords[route_no].consignment_code[0]+"_check").removeAttr("checked")
					// }
					// else{
					// 	$("#"+this.props.mapinfo.coords[route_no].consignment_code[0]+"_check").attr("checked", true)
					// }
				})
			}
		}else{

		}
	}
    enableCheckBoxes = () =>{
		[0,1,2, 3].map((e)=>{
			try{
				var idname = e+"_check"
				$("#"+idname).prop("checked",true)
			}
			catch(e){
				console.log(e, "error in enabling check boxes")
			}
		})
	}

    render(){
        const {show,basicType,basicTitle,source,sourceOptions,destination,destinationOptions,loadshow,overly,pagetitle,tripRoutes,route_coords,loadMap} = this.state
        return(
            <div className="container-fluid">
                <SweetAlert
					show={show}
					type={basicType}
					title={basicTitle}
					onConfirm={this.closeAlert}>
	        	</SweetAlert>
                <div className='row card'>
                    <div className="card-header">
                        <h5>
                            <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{pagetitle} </span>									
                        </h5>
                    </div>	
                    <form className="row theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler}>
                        <div className="form-group col-xl-4 col-lg-4">
                            <label>Source: </label>
                            <Select placeholder={"All"} value={source} className="border-radius-0" 
                            // onChange={this.onSelectSource} 
                            style={{borderRadius:"0px"}} 
                            // options={sourceOptions} 
                            // required
                             />
                        </div>
                        <div className="form-group col-xl-4 col-lg-4">
                            <label>Destination: </label>
                            <Select placeholder={"All"} value={destination} className="border-radius-0" onChange={this.onSelectDestination} style={{borderRadius:"0px"}} options={destinationOptions} required />
                        </div>           
                        <div className="col-xl-2 col-lg-2 form-group" style={{marginTop : '28px'}}>
                            <button type="submit" className="btn btn-success">Submit</button>
                        </div>
                    </form>
                </div>
                <div className='row card'>
                    {/* <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                        <NewDrawMap 
                            mapFor={"bidadiTrips"}
                            mapinfo={{"coords": route_coords,
                                    "consigner_coordinates": route_coords[0]['source'],
                                    "consignee_coordinates": route_coords[0]['destination'],
                                    "route_details": {"start_time": "",
                                                    "end_time": ""}}} 
                            coords={route_coords} 
                            consignments_list={[]}
                            // breaks={this.state.breaks_list} 
                        />
                        <div className="col-xl-12 col-lg-12 n-p-0">
                             <div className="crm-numbers pb-0">
                                <h3 className="subH">{"Details"}</h3>
                                <div className="col-xl-12 col-lg-12 row">
                                    <div className="col route-block">
                                        <label className="sidebar-label">Truck No</label>
                                        <div>{route_coords[0].truck_no}</div>
                                    </div>
                                    <div className="col route-block">
                                        <label className="sidebar-label">Start Time</label>
                                        <div>{(this.state.routeRowData.invoice_time != '')?getDDMMYYYYHHMMSS(this.state.routeRowData.invoice_time):"NA"}</div>
                                    </div>
                                    <div className="col route-block">
                                        <label className="sidebar-label">End Time</label>
                                        <div>{(this.state.routeRowData.trip_end_time != '')?getDDMMYYYYHHMMSS(this.state.routeRowData.trip_end_time):"NA"}</div>
                                    </div>
                                    <div className="col route-block">
                                        <label className="sidebar-label">Distance</label>
                                        <div>{Math.round(this.state.routeRowData.total_trip_dist_with_google_km) +" Kms"}</div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>  */}
                    <div className="card-body col-xl-12 col-lg-12">
                        {/* map checkboxes start */}
                        <ul>
                            {(route_coords.map((e, index) =>{
                                return <li className="float-left" key={index}>
                                            <input type="checkbox" id={index+"_check"} onClick={this.showMultiMarkers.bind(this, index)} /> <span style={{ fontSize: "14px" }}><i className='fa fa-circle' style={{color: colorPickers[index]}}></i>{`Route${index + 1}`}</span> &nbsp;&nbsp;
                                        </li>
                            }))}
                        </ul>
                        {/* map checkboxes end */}

                        {/* map div start */}
                        <div id="map" className="" style={{ width: '100%', height: "70vh" }}></div>
                        {/* map div end */}

                        <span>
						{(route_coords.map((e, index) =>{
							return <b style={{ "paddingRight": "10px" }}><i class="fa fa-minus fa-2x" style={{ color: colorPickers[index], }}></i>Route {index+1} </b>
						}))}
						{/* <i class="fa fa-minus fa-2x" style={{ color: "yellow", }}></i> <b style={{ "paddingRight": "10px" }}>Missing Coords To City Center </b>
						<i class="fa fa-minus fa-2x" style={{ color: "red", }}></i> <b style={{ "paddingRight": "10px" }}>Straight Lines </b> */}
					</span>
                    </div>
                    <div className="col-xl-12 col-lg-12 n-p-0">
                        <div className="crm-numbers pb-0">
                        <h3 className="subH" style={{textAlign:"center"}}>Route Distances</h3>
                            <div className="col-xl-12 col-lg-12 row">
                                <div className="col route-block">
                                    <label className="sidebar-label">Route 1</label>
                                    <div>{ this.state.gps_distance_kms1} Kms</div>
                                </div>
                                <div className="col route-block">
                                    <label className="sidebar-label">Route 2</label>
                                    <div>{this.state.gps_distance_kms2} Kms</div>
                                </div>
                                <div className="col route-block">
                                    <label className="sidebar-label">Route 3</label>
                                    <div>{this.state.gps_distance_kms3} Kms</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (loadshow)}></div>
                <div className={"dataLoadpageimg " + (loadshow)}>
                        <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (overly)} onClick={this.onClickHideAll}></div>

            </div>
        )
    }

}

function loadScript(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}
'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import ProgressBar from "@ramonak/react-progress-bar";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import CountUp from 'react-countup';
import UpdateButton from "./updatelmstates";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import LedScreenPieChartComponent from './ledscreenpirchartcomponent';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent = "";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;
var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`)
var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords = [];
var map;
var doOnce = true;
var rad = function (x) {
    return x * Math.PI / 180;
};
var rlatitude;
var rlongitude;

var fencingLength = 0;
var geoFencingArea;




export default class LedRailwayLogisticsscreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            start_year: "",
            end_year: "",
            showform: 'show-m',
            loadshow: 'show-n',
            showrefresh: 'show-n',
            sliderTranslate: '',
            showdata: this.displayData,
            modules: AllModules,
            radius: '15',
            rlatitude: '',
            rlongitude: '',
            latitude: '',
            longitude: '',
            defaultradius: 0,
            coordinates: [],
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            contentString: "testing",
            mwidth: "col-xl-12 col-lg-12",
            dept_code: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                //editable: true,
                resizable: true
            },
            rowData: [],

            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single",
            context: { componentParent: this },
            loadshow: "show-n",
            overly: "show-n",
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            rowModelType: "serverSide",
            paginationPageSize: 50,
            cacheBlockSize: 50,
            maxBlocksInCache: 2,
            totalSales: 0,
            totalDispatches: 0,
            railShare: 0,
            month_target: 0,
            actual_till_date: 0,
            achievement: 0,
            balance_days: 0,
            daily_target: 0,
            current_rail_dispatch_fy: 0,
            pending_for_exit: 0,
            Pending_for_rail_out: 0,
            rail_in_transit: 0,
            last_mile: 0,
            delivered: 0,
            otwrtsr: 0,
            otwrtrtt: 0,
            otwrtrrat: 0,
            achievementColor: "orange",
            pagetitle: "Rake Positions",
            plan_month: "",
            statecheck: [],
            chartSeriesData: [],
            chartSeriesCategories: [],
            to_current_Date: "",
            from_current_Date: "",
            completed_days: 0,
            intransitRakeList: [],
            seconds: 90,
            lastRunTime: "",
            alignAchievementText: "",
            currentScreenIndex: 0
        }
        // this.popmarker=this.popmarker.bind(this);
        this.goBack = this.goBack.bind(this);
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
        this.timer = 0;
        this.screens = [
            '/ledrailwaylogisticsscreen',
            '/ledrailwaylogisticsscreen2',
            '/ledrailwaylogisticsscreen3',
            //   '/ledrailwaylogisticsscreen4'
        ];
    }
    autororate() {
        console.log(localStorage.getItem("username"), 'username')
        if (localStorage.getItem("username") == "LED USER") {
            this.rotationInterval = setInterval(this.rotateScreens, 60000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.rotationInterval);
    }

    rotateScreens = () => {

        window.location.href = "/ledrailwaylogisticsscreen2"
    }

    async componentDidMount() {
        // console.log("path ", this.props.match.path)
        // this.logPageView(); // Log page view to GA
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })

        await redirectURL.post("/consignments/rakeIntransitData").then((response) => {
            var checkData = response.data
            console.log(checkData, "146")
            this.setState({
                intransitRakeList: checkData,
            })
        })
        var from_currrent_date = moment.parseZone(new Date()).format("MM-YYYY")
        var check_Date = "01" + "/" + from_currrent_date.split("-")[0] + "/" + from_currrent_date.split("-")[1]
        var curentdate = moment.parseZone(new Date()).format("DD/MM/YYYY")
        this.setState({
            from_current_Date: check_Date,
            to_current_Date: curentdate,
        })
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        this.setState({ eventAction: eventAction });
        var currentMonth = moment.parseZone().format("YYYY-MM");
        //console.log(currentMonth,"currentMonth")
        await this.setState({
            plan_month: currentMonth
        })
        await this.getStagewiseRailSummaryData();
        await this.onLoadPageData();
        await this.renderMapDefault();
        await this.getChartDataforLedScreen();

        var urlpath;
        var dpt;
        if (this.props.match.path == "/ledrailwaylogisticsscreen") {
            urlpath = '/consignments/sndrakes';
            dpt = 'SNDG';
        }
        if (this.props.match.path == "/tnprakes") {
            urlpath = '/consignments/sndrakes';
            dpt = 'LOG-TNP';
        }
        this.setState({
            dept_code: dpt,
        });
        var transporter_code = "";
        if (localStorage.getItem("transportercode") != undefined && localStorage.getItem("transportercode") != "undefined" && localStorage.getItem("transportercode") != "" && localStorage.getItem("transportercode") != null && localStorage.getItem("transportercode") != "null") {
            transporter_code = JSON.parse(localStorage.getItem("transportercode"));
        }
        redirectURL.post(urlpath, { dept_code: dpt, transporter_code: transporter_code })
            .then((response) => {
                var rakesData = response.data.message;
                console.log(response.data.message)
                console.log(rakesData, "139");
                rakesData = JSON.parse(rakesData);
                //  console.log("truckinfo,",rakesData);
                rakesData = rakesData.filter((e) => e["is_active"] == 1)
                //  console.log(rakesData,"194")

                //  console.log(intransitRakeData,"198")
                this.setState({
                    rowData: rakesData,
                });
                // console.log(this.state.rowData);
                var dCode = this.state.dept_code;
                if (rakesData.length > 0) {
                    //console.log("UPpcase Dept ", dCode);
                    var markers = [];
                    rakesData.map((item) => {
                        markers.push(item);
                    });
                    console.log("Markers ", markers);
                    this.setState({
                        // defaultradius:formdata.radius,
                        coordinates: markers,
                        mwidth: "col-xl-12 col-lg-12",
                        rowData: markers,
                        // radius:formdata.radius,
                        loadshow: 'show-n',
                        showform: 'show-n',
                        showrefresh: 'show-m',
                        rlatitude: rakesData[0].latitude,
                        rlongitude: rakesData[0].longitude,
                    });


                    var coords = markers;
                    /*oms = new oms.OverlappingMarkerSpiderfier(map, {
                        markersWontMove: true,
                        markersWontHide: true,
                        keepSpiderfied: true,
                        basicFormatEvents: true
                    });*/
                    var p1 = { lat: parseFloat(rakesData[0].latitude), lng: parseFloat(rakesData[0].longitude) };
                    coords.map((marker) => {
                        //console.log("marker", marker);
                        var p2 = { lat: marker.latitude, lng: marker.longitude };
                        //console.log("Distace is ", this.getDistance(p1,p2));
                        //console.log(marker.distance +"<= "+ response.data.radius)
                        var distance = this.getDistance(p1, p2);
                        //console.log(window.google.maps.geometry.spherical.computeDistanceBetween (p1, p2))
                        // if (distance <= formdata.radius)
                        // {
                        allcords.push(marker);
                        // }

                    });
                    window.initMap = this.initMap
                    this.renderMap();
                }
                else {
                    this.setState({
                        show: true, basicType: 'success', basicTitle: "No Data found.",
                        loadshow: 'show-n',
                        showform: 'show-m',
                    });

                }
            });
        await this.getLatestValues();
        var lastRunTime = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
        await this.setState({
            lastRunTime: lastRunTime,
        })
        await this.countDownTimer();
        this.autororate()
    }
    onLoadPageData() {
        this.setState({
            loadshow: "show-m",
            overly: "show-m",
        })
        var plan_month = this.state.plan_month
        var params = {
            plan_month: plan_month
        }
        redirectURL.post("/consignments/getRailTargetByMonthforLED", params).then((response) => {
            console.log("response", response.data);
            var railTargetData = response.data.railtarget;
            console.log(railTargetData, "260")
            if (railTargetData.length > 0) {
                var month_target = railTargetData[0].month_target;
                $("#month_target").val(month_target);
                var working_days = railTargetData[0].working_days;
                var selectedDays = []
                working_days.map(function (w) {
                    if (w == '' || w == undefined) {
                        return '';
                    }
                    else {
                        selectedDays.push(moment.parseZone(w)._d);
                    }
                    // console.log(moment.parseZone(w)._d,"bhanu")
                });
                this.setState({
                    selectedDays: selectedDays
                });
            }
            var actualTillDate = response.data.actualTillDate;

            console.log(actualTillDate, "280")
            var totalDispatches = response.data.totalDispatches;
            var railDispatchesShare = response.data.railDispatchesShare;
            var current_dispatch_fy = response.data.current_dispatch_fy;
            var current_rail_dispatch_fy = response.data.current_rail_dispatch_fy;
            var current_rail_share = response.data.currentrailDispatchesShare;
            try {
                railDispatchesShare = railDispatchesShare.toFixed(2)
            }
            catch (e) {

            }
            try {
                current_rail_share = current_rail_share.toFixed(2)
            }
            catch (e) {

            }
            var month_target = 0;
            var working_days = 0;
            var daily_target = 0;
            var balance_days = 0;
            var completed_days = 0;
            var compledted_till_date = 0;
            var achievement = 0;
            var balance_to_be_planned = 0;
            var achievementColor = "red";
            var target_revised = 0;
            var actual_till_date_calc = 0;
            var achievement_percentage_calc = 0
            if (railTargetData.length > 0) {
                month_target = parseInt(railTargetData[0].month_target);
                // console.log(month_target,"341")
                actual_till_date_calc = parseInt(actualTillDate)
                achievement_percentage_calc = (actual_till_date_calc / month_target) * 100
                working_days = (railTargetData[0].working_days).length;
                if (month_target > 0 && working_days > 0) {
                    daily_target = Math.ceil(parseInt(month_target) / parseInt(working_days));
                    console.log(daily_target, "345")
                    var wkDates = railTargetData[0].working_days;
                    var workingDates = [];
                    var balanceDates = [];
                    var completedDates = [];
                    wkDates.map(function (wkd) {
                        workingDates.push(moment.parseZone(new Date(wkd)).format("YYYY-MM-DD") + " 00:00:00");
                    });
                    //console.log(workingDates,"workingDates")
                    var currentDate = moment.parseZone().format("YYYY-MM-DD") + " 00:00:00";
                    currentDate = currentDate.split("+")[0];
                    // console.log(currentDate,"currentDate")
                    wkDates.map(function (wde) {
                        var comparision = moment(wde).isSameOrAfter(currentDate);
                        // console.log("moment(wde)",moment(wde))
                        // console.log("comparision",comparision)
                        if (comparision == true) {
                            balanceDates.push(wde);
                        }
                        else {
                            completedDates.push(wde);
                        }
                    });
                    //console.log(balanceDates,"balanceDates")
                    balance_days = balanceDates.length;
                    completed_days = completedDates.length;
                    var daily_asking_rate = (month_target - actual_till_date_calc) / balance_days
                    daily_asking_rate = Math.round(daily_asking_rate)
                    var current_daily_dispatches = actual_till_date_calc / completed_days
                    current_daily_dispatches = Math.round(current_daily_dispatches)
                    compledted_till_date = parseInt(daily_target * completed_days);
                    console.log(compledted_till_date, "376")
                    balance_to_be_planned = parseInt(month_target) - parseInt(actualTillDate);
                    var balanceTillDate = parseInt(compledted_till_date) - parseInt(actualTillDate);
                    // achievement = (actualTillDate / month_target) * 100;
                    achievement = (actualTillDate / month_target) * 100; //made change 
                    if (achievement > 20) {
                        var alignAchievementText = "right"
                    } else {
                        var alignAchievementText = "left"
                    }
                    // console.log(actualTillDate,typeof(actualTillDate),"381")
                    console.log(compledted_till_date, "382")
                    console.log(achievement, "383")
                    // console.log(month_target,typeof(month_target),"384")
                    if (current_daily_dispatches > daily_asking_rate) {
                        achievementColor = "green"
                    } else {
                        achievementColor = "orange"
                    }

                }
            }
            // console.log(achievement,"397")
            this.setState({
                month_target: month_target,
                working_days: working_days,
                daily_target: daily_asking_rate,
                balance_days: balance_days,
                completed_days: completed_days,
                compledted_till_date: compledted_till_date,
                actual_till_date: actualTillDate,
                achievement: achievement,
                achievementColor: achievementColor,
                balance_to_be_planned: balance_to_be_planned,
                target_revised: target_revised,
                total_dispatches: totalDispatches,
                alignAchievementText: alignAchievementText,
                // rail_share: railDispatchesShare, //changes made as per shankar mail
                current_dispatch_fy: current_dispatch_fy,
                current_rail_dispatch_fy: current_daily_dispatches,
                current_rail_share: current_rail_share,
            })
        }).catch((e) => {
            console.log(e);
        })
    }
    getStagewiseRailSummaryData() {
        // var dd  = moment.parseZone(new Date()).format("MMM-YYYY")
        // console.log(dd,"478")
        // var params = {
        //     invoice_month : dd,
        // }
        redirectURL.post("/consignments/getStageWiseSummaryData").then((response) => {
            console.log(response.data, "483")
            this.setState({
                start_year: response.data.start_year,
                end_year: response.data.end_year,
                pending_for_exit: response.data.pending_for_exit,
                Pending_for_rail_out: response.data.pending_for_railout,
                rail_in_transit: response.data.rail_in_transit,
                last_mile: response.data.last_mile,
                delivered: response.data.delivered,
            })
        })
    }

    getChartDataforLedScreen() {
        var dd = moment.parseZone(new Date()).format("MMM-YYYY")
        // console.log(dd,"478")
        var params = {
            invoice_month: dd,
        }
        var plan_month = this.state.plan_month
        var params2 = {
            plan_month: plan_month
        }

        redirectURL.post("/consignments/getOnlyPieChartDataforLedScreen", params2).then((res) => {
            console.log(res.data)

            const categoriesData = (res.data.pieChartDataOnly).filter((each) => each.y !== 0)
            const categories = categoriesData.map((each) => (each.name))
            console.log(categories, "467")

            this.setState({
                chartSeriesData: categoriesData
                ,
                chartSeriesCategories: categories,
            })

        })


        redirectURL.post("/consignments/getPieChartDataforLedScreen", params).then((response) => {
            console.log(response.data, "502")
            var resp = response.data.pieChartData
            // var chartSeriesData =[];
            // var chartSeriesCategories =[];
            var filtermonthlyData = resp.filter((e) => e["Invoice Month"] == dd)
            var otwrtsr = response.data.otwrtsr
            var otwrtrtt = response.data.otwrtrtt
            var otwrtrrat = response.data.otwrtrrat


            this.setState({
                otwrtsr: otwrtsr,
                otwrtrtt: otwrtrtt,
                otwrtrrat: otwrtrrat,
            })
        })
    }


    getLatestValues() {
        var params = {
            screen_type: "LED"
        }

        redirectURL.post("/consignments/getLatestRailStatusValuesToday", params).then((response) => {
            console.log(response.data, "214")
            var total_share_value = parseInt(response.data.total_sales_till_today)
            var total_dispatches_tr = this.state.actual_till_date
            var rail_share_percentage = (total_dispatches_tr / total_share_value) * 100
            rail_share_percentage = rail_share_percentage.toFixed(2)
            this.setState({
                totalSales: response.data.total_sales_till_today,
                totalDispatches: response.data.total_rail_sales_till_today,
                railShare: rail_share_percentage,
                loadshow: 'show-n',
                overly: 'show-n',
            })
        }).catch((e) => { console.log(e) })
    }
    goBack() {
        window.location.reload();
    }
    renderMapDefault = () => {

        loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMapDefault");
        window.initMapDefault = this.initMapDefault
    }
    renderMap = () => {
        loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMap");
        window.initMap = this.initMap
    }
    initMapDefault = () => {
        if (this.state.rowData.length <= 0) {
            var lt = 28.4519751;
            var ln = 77.0310713;
        }
        else {
            var lt = this.state.rowData[0].latitude;
            var ln = this.state.rowData[0].longitude;
        }

        map = new window.google.maps.Map(document.getElementById('map_canvas'), {
            zoom: 13,
            center: new window.google.maps.LatLng(lt, ln),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            /*mapTypeControl: true,
            gestureHandling: 'greedy',
            mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_RIGHT
              },            
            disableDefaultUI: true,
            zoomControl: true*/
        });
        var markers = [];


        circle = new window.google.maps.Circle({
            map: map
        });

        marker = new window.google.maps.Marker({
            map: map,
            draggable: true,
            animation: window.google.maps.Animation.DROP,
            //position: {lat: 28.48, lng: 77.06}
        });

        marker.addListener('click', function () {
            marker.setAnimation(window.google.maps.Animation.BOUNCE)
        });

        window.google.maps.event.addListener(map, 'click', function (event) {

            document.getElementById("latitude").value = event.latLng.lat();
            document.getElementById("longitude").value = event.latLng.lng();
            marker.setPosition(event.latLng);

        });
        circle.bindTo('center', marker, 'position');



        var eventtrigger = document.getElementsByClassName('truck');

        // var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
        //map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);

    }
    initMap = () => {
        var contentdata = this.state.contentString;
        console.log("initmap funciton");
        console.log(this.state.rowData);
        if (this.state.rowData.length == 0) {
            var lt = 28.4519751;
            var ln = 77.0310713;
        }
        else {
            var lt = this.state.rowData[0].latitude;
            var ln = this.state.rowData[0].longitude;
        }
        map = new window.google.maps.Map(document.getElementById('map_canvas'), {
            zoom: 11,
            center: new window.google.maps.LatLng(lt, ln),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,

        });


        var markers = [];

        var bounds = new window.google.maps.LatLngBounds();

        circle = new window.google.maps.Circle({
            map: map
        });

        circle.bindTo('center', marker, 'position');
        var markerLatlng1;
        //console.log("Radius ", this.state.radius)
        //console.log("state Lat ",this.state.rlatitude)
        if (this.state.rlatitude != '' && this.state.rlongitude != '') {
            //var geoFencingObj = allcords;
            //console.log(parseFloat(this.state.rlatitude),parseFloat(this.state.rlongitude));
            markerLatlng1 = new window.google.maps.LatLng(this.state.rlatitude, this.state.rlongitude);
            //console.log("markerLatlng", markerLatlng1)
            var circle = new window.google.maps.Circle({
                strokeColor: '#71caab',
                strokeOpacity: 0.7,
                strokeWeight: 1,
                fillColor: '#71caab',
                fillOpacity: 0.25,
                map: map,
                center: markerLatlng1,
                //radius: Math.sqrt(this.state.radius) * 10000
            });

        }

        //console.log("allcords initmap ", allcords)
        if (doOnce) {
            // initOMS();
            doOnce = false;
        }
        allcords.map((marker) => {
            // console.log("marker ", marker)
            var image = require('../../assets/icons/traindeliver.png');
            var contentarr = []
            var headerTitle = marker.truck_no
            contentarr.push({ "key": "Transporter Name", "value": marker.transporter_name });
            contentarr.push({ "key": "GPS Provider", "value": marker.actual_lspuser });
            contentarr.push({
                "key": "Origin Location", "value": marker.rake_loading_point_name
                    + "  " + getHyphenDDMMMYYYYHHMM(marker.expected_rake_dispatch_date)
            });
            contentarr.push({
                "key": "Destination Location", "value": marker.rake_delivery_point_name
                    + "  " + getHyphenDDMMMYYYYHHMM(marker.eta_rail_middle_mile)
            });
            contentarr.push({
                "key": "Current Location", "value": marker.area + "," + marker.state
                    + "  " + getHyphenDDMMMYYYYHHMM(marker.timestamp)
            });
            if (marker.average_speed > 14.6) {
                contentarr.push({ "key": "Status", "value": "Ontime" });
            } else {
                contentarr.push({ "key": "Status", "value": "Delayed" });
            }

            contentarr.push({ "key": "Average Speed", "value": marker.average_speed });
            var contentString = infoBox(image, headerTitle, contentarr, '')


            //var contentString = "Here "+marker.truck_no;
            var infowindow = new window.google.maps.InfoWindow({
                content: contentString
            });

            var markerLatlng = new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude));

            if (marker.average_speed > 14.6) {
                var image = require('../../assets/icons/train-ontime-1.png');
            } else {
                var image = require('../../assets/icons/train-delay.png');
            }

            var mark = new window.google.maps.Marker({
                position: markerLatlng,
                map: map,
                title: marker.truck_no,
                icon: image
            });
            mark.addListener('click', function () {
                infowindow.open(map, mark);
            });
            mark.setMap(map);
            // bounds.extend(new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude)));
            bounds.extend(new window.google.maps.LatLng(20.474900, 73));
            bounds.extend(new window.google.maps.LatLng(20.474900, 88.282247));

            map.fitBounds(bounds);
        });
        var eventtrigger = document.getElementsByClassName('truck');

        // var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
        //map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);

    }
    toggleBounce() {
        if (marker.getAnimation() !== null) {
            marker.setAnimation(null);
        } else {
            marker.setAnimation(window.google.maps.Animation.BOUNCE);
        }

    }
    getDistance = (p1, p2) => {
        //console.log("p1",p1);
        //console.log("p2",p2);
        var R = 6378137; // Earth’s mean radius in meter
        var dLat = rad(p2.lat - p1.lat);
        var dLong = rad(p2.lng - p1.lng);
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d / 1000; // returns the distance in meter
    };
    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            bulkslide: "",

        });
    }

    countDownTimer = async () => {
        var lastRunTime = this.state.lastRunTime;
        var currentDate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
        // var startdate = moment.parseZone(lastRunTime).format("YYYY-MM-DD HH:mm:ss")
        var startdate = lastRunTime
        var diffreetime = moment.duration(moment(currentDate).diff(startdate));
        var runseconds = Math.round(diffreetime.asSeconds());
        console.log(runseconds, lastRunTime, currentDate, "runseconds");
        if (parseInt(runseconds) < 60) {
            this.startTimer();
        }
    }

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    startTimer() {
        if (this.timer == 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 20000);
        }
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });
        if (this.state.seconds == 30 || this.state.seconds == 60 || this.state.seconds == 90) {
            //console.log(this.state.seconds,"this.state.seconds")
            this.componentDidMount();
        }
        // Check if we're at zero.
        if (seconds == 0) {
            clearInterval(this.timer);
            $("#timerDiv").hide();
            this.componentDidMount();
        }
    }
    render() {
        const dStyles = {
            width: '100%',
            marginLeft: '0px',
            paddingLeft: '0px',
            height: '850px'
        }
        const AchievementColor = {
            background: this.state.achievementColor,
            color: "black"
        }
        console.log(this.state.chartSeriesData, this.state.chartSeriesCategories, "829")
        return (
            <div class="container-fluid" >
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="card col-xl-12">
                    <div className="card-header text-center">
                        <div className='top-header-led1'>
                            {/* <span style={{position:"relative",left:"25%",fontSize:"15px",color:"#171C8F"}}>
                        Last-Refresh-Rate : {this.state.from_current_Date}
                        </span> */}
                            <span className='font-color'>
                                Updated on : {this.state.lastRunTime}
                            </span>
                            <span className='main-title'>RAILWAY LOGISTICS</span>
                            <span className='font-color'>
                                From Date : {this.state.from_current_Date}
                            </span>
                            <span className='font-color'>
                                To Date : {this.state.to_current_Date}
                            </span>
                        </div>
                    </div>
                    {/* {Main Body} */}
                    <div className='row'>
                        <div className='col-xl-7 col-lg-7'>
                            <div className="card-header">
                                <h5>
                                    <span className='railway_dispatches_header'>Railway Dispatches</span>
                                </h5>
                            </div>
                            <div className="col-xl-12 col-lg-12 led1_table1">
                                <table className="table table-bordered rail-stage-tbl">
                                    <thead className='led_table_header'>
                                        <tr>
                                            <th>{"Total Sales"}</th>
                                            <th>{"Total Dispatches Thru Rail"}</th>
                                            <th>{"Rail Share % "}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='led_table_header'>
                                        <tr>
                                            <td>{this.state.totalSales}</td>
                                            <td>{this.state.actual_till_date}</td>
                                            <td>{this.state.railShare > 0 ? this.state.railShare : 0}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='row'>
                                <div className="col-xl-6 col-lg-6 led1_table2">
                                    <table className='table table-bordered rail-stage-tbl'>
                                        <tbody className='led_table_header'>
                                            <tr>
                                                <th>Month Rail Target</th>
                                                <td>
                                                    {this.state.month_target}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>% Achievement</th>
                                                <td style={{ padding: 0 }}><ProgressBar className='progress_bar_led' height="42px" labelAlignment={this.state.alignAchievementText} labelColor="black" borderRadius="0px" bgColor="green" completed={this.state.achievement.toFixed(2)} /></td>
                                            </tr>
                                            {/* <tr>
                                                <th>Achieved (Till Date)</th>
                                                <td>{this.state.actual_till_date}</td>
                                            </tr> */}
                                            <tr>
                                                <th>Daily Asking Rate</th>
                                                <td>{this.state.daily_target}</td>
                                            </tr>
                                            <tr>
                                                <th>Current Daily Dispatches</th>
                                                <td style={AchievementColor}>{this.state.current_rail_dispatch_fy}</td>
                                            </tr>
                                            <tr>
                                                <th>Balance Days</th>
                                                {/* <td>{this.state.current_dispatch_fy.toLocaleString('en-IN', {maximumFractionDigits:2})}</td> */}
                                                <td>{this.state.balance_days}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='col-xl-6 col-lg-6 led1_pirchart'>
                                    <LedScreenPieChartComponent
                                        chartType="pie"
                                        title={"Cars By plant"}
                                        subtitle={""}
                                        context={this}
                                        series={this.state.chartSeriesData}
                                        categories={this.state.chartSeriesCategories}
                                        seriesName=""
                                        yaxistitle="Orders For Load Plan"
                                        drilldownseries={[]}
                                        innerSize="50%"
                                        format='<b>{point.name}-{point.y}</b><br>{point.percentage:.1f} %'

                                    />
                                </div>
                            </div>
                            <div className="row" >
                                <div className='col-xl-12 col-lg-12' style={{ padding: "5px 15px 0px 15px", marginTop: "0.5%" }}>
                                    <div className="card-header" style={{ fontSize: "0.05em !important", border: "1px solid #ccc", textAlign: "center", color: "#ffffff", backgroundColor: "#171C8F" }}>
                                        <div>
                                            <span className='counter-led1_header'>Stagewise Summary For {this.state.start_year} - {this.state.end_year} </span>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-body" id="counterCardBody">
                                            <div className="crm-numbers pb-0">
                                                <div className="row">
                                                    <div className="col cirlce-d cpointer">
                                                        <span className='counter-text'>Pending for Exit</span>
                                                        <h4 class="txt-dark f40" style={{ marginTop: "0px" }}>
                                                            <span class="counter" style={{ fontSize: "30px" }}>
                                                                <CountUp end={(this.state.pending_for_exit) ? this.state.pending_for_exit : 0} />
                                                            </span>
                                                        </h4>
                                                    </div>
                                                    <div className="col cirlce-d cpointer">
                                                        <span className='counter-text'>Pending for Rail Out</span>
                                                        <h4 class="txt-dark f40">
                                                            <span class="counter" style={{ fontSize: "30px", paddingTop: "0px", marginTop: "0px" }}>
                                                                <CountUp end={(this.state.Pending_for_rail_out) ? this.state.Pending_for_rail_out : 0} />
                                                            </span>
                                                        </h4>
                                                    </div>
                                                    <div className="col cirlce-d cpointer">
                                                        <span className='counter-text'>Rail in Transit</span>
                                                        <h4 class="txt-dark f40" style={{ marginTop: "0px" }}>
                                                            <span class="counter" style={{ fontSize: "30px" }}>
                                                                <CountUp end={(this.state.rail_in_transit) ? this.state.rail_in_transit : 0} />
                                                            </span>
                                                        </h4>
                                                    </div>

                                                    <div className="col cirlce-d cpointer">
                                                        <span className='counter-text'>In Last Mile</span>
                                                        <h4 class="txt-dark f40" style={{ marginTop: "0px" }}>
                                                            <span class="counter" style={{ fontSize: "30px" }}>
                                                                <CountUp end={(this.state.last_mile) ? this.state.last_mile : 0} />
                                                            </span>
                                                        </h4>
                                                    </div>
                                                    <div className="col cirlce-d cpointer">
                                                        <span className='counter-text'>Delivered</span>
                                                        <h4 class="txt-dark f40" style={{ marginTop: "0px" }}>
                                                            <span class="counter" style={{ fontSize: "30px" }}>
                                                                <CountUp end={(this.state.delivered) ? this.state.delivered : 0} />
                                                            </span>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="row" >
                                <div className='col-xl-4 col-lg-4' style={{ padding: "0px 15px 10px 15px" }}>
                                    <div className="card">
                                        <div className="card-header" style={{border: "1px solid #ccc", textAlign: "center", color: "#ffffff", backgroundColor: "#171C8F" }}>
                                            <div>
                                                <span className='counter-led1_header2'>Ontime(%) w.r.t STD Road</span>
                                            </div>
                                        </div>
                                        <SemiCircleProgressBar height="25px" stroke="#118DFF" borderRadius="0px" strokeWidth="20" percentage={this.state.otwrtsr} background="#F3F2F1" showPercentValue />
                                    </div>
                                </div>
                                <div className='col-xl-4 col-lg-4'>
                                    <div className="card">
                                        <div className="card-header" style={{border: "1px solid #ccc", textAlign: "center", color: "#ffffff", backgroundColor: "#171C8F" }}>
                                            <div>
                                                <span className='counter-led1_header2'>Ontime(%) w.r.t Road Trip TT</span>
                                            </div>
                                        </div>
                                        <SemiCircleProgressBar height="25px" stroke="#118DFF" borderRadius="0px" strokeWidth="20" background="#F3F2F1" percentage={this.state.otwrtrtt} showPercentValue />
                                    </div>
                                </div>
                                <div className='col-xl-4 col-lg-4'>
                                    <div className="card">
                                        <div className="card-header" style={{border: "1px solid #ccc", textAlign: "center", color: "#ffffff", backgroundColor: "#171C8F" }}>
                                            <div>
                                                <span className='counter-led1_header2'>Ontime(%) w.r.t Rail Trip TT</span>
                                            </div>
                                        </div>
                                        <SemiCircleProgressBar height="25px" stroke="#118DFF" borderRadius="0px" strokeWidth="20" background="#F3F2F1" percentage={this.state.otwrtrrat} showPercentValue />
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className='col-xl-5 col-lg-5'>
                            <div className="card-header">
                                <h5>
                                    <span className='intransit_rake_status_header'>In - Transit Rake Status </span>
                                </h5>
                            </div>
                            <div className="col-xl-12 col-lg-12" style={{ padding: "0px 0px 0px 0px" }}>
                                <div id="map_canvas" className={this.state.mwidth} style={dStyles}></div>
                                <div className="col-sm-12 col-xs-12 col-md-12 map-legends row three" style={{ marginTop: "10px", position: "absolute", backgroundColor: "rgba(255,255,255,0.75)", width: "200px", bottom: "24px", left: "27px", padding: "15px", borderRadius: "7px" }}>
                                    {/* <div className="col-xl-12 col-lg-12"> */}
                                    <div className="col-6">
                                        <img style={{ width: "24px", height: "24px", marginLeft: "15px" }} src={require('../../assets/icons/train-ontime-1.png')}></img> <figcaption>Ontime</figcaption>
                                    </div>
                                    <div className="col-6">
                                        <img style={{ width: "24px", height: "24px", marginLeft: "8px" }} src={require('../../assets/icons/train-delay.png')}></img> <figcaption>Delay</figcaption>
                                    </div>
                                    {/* </div> */}
                                </div>




                            </div>
                        </div>

                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>


        );
    }
}

function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src = url
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function initOMS() {
    // Initializing OMS
    oms = new oms.OverlappingMarkerSpiderfier(map, {
        //markersWontMove: true,
        //keepSpiderfied: true,
        //nearbyDistance: 10,
        //circleFootSeparation: 60,
        //legWeight: 1.5
        markersWontMove: true,
        markersWontHide: true,
        keepSpiderfied: true,
        basicFormatEvents: true
    });
}
function monthToComparableNumber1(date) {
    console.log(date.length);
    console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}
// popmarker(e)
// 	{
// 		console.log("OnClick Table ",e.data)
// 		var lat = parseFloat(e.data.latitude);
// 		var lng =parseFloat(e.data.longitude);
// 		var data =e.data;
// 		map.setCenter(new window.google.maps.LatLng(lat,lng));
// 		map.setZoom(17);
// 		var markerLatlng = new window.google.maps.LatLng(lat, lng);
// 		//var image =  require('../../assets/icons/truckblue.png');
// 		var image =  require('../../assets/icons/traindeliver.png');
// 		/*if(data.overspeeding_exception == 1)
// 		{
// 			var image =  require('../../assets/icons/truck_overspeed.png');
// 		}

// 		if(data.nightdriving_exception == 1)
// 		{
// 			var image =  require('../../assets/icons/truck-end.png');
// 		}*/


// 		var image =  require('../../assets/icons/traindeliver.png');
// 		var marker = new window.google.maps.Marker({
// 			position: markerLatlng,
// 			map: map,
// 			title:data.truck_no,
// 			icon:image
// 		});


// 		var contentarr = []
// 		var headerTitle = e.data.truck_no
// 		contentarr.push({"key":"Transporter Name", "value":e.data.transporter_name});
// 		contentarr.push({"key":"GPS Provider", "value":e.data.actual_lspuser});
// 		contentarr.push({"key":"Last Packet Time", "value":getHyphenDDMMMYYYYHHMM(e.data.timestamp)});
// 		contentarr.push({"key":"Speed (km/h)", "value":e.data.speed});
// 		contentarr.push({"key":"Address", "value":e.data.area+","+e.data.state});

// 		var contentString = infoBox(image, headerTitle, contentarr, '')
// 		/*
// 		var contentString = "Truck No: "+data.truck_no+"<br />Name: "+data.transporter_name+" LSP Name:"+data.lsp_name+"<br />Department:" + data.dept_code+"<br />Address:"+data.address;
// 		var contentString = "<table><tr><th>Status: </th><td style='text-align: right'>"+truckText+"</td></tr><tr><th>Distance from Geofence: </th><td style='text-align: right'>"+data.distancel
// 		contentString = contentString + " (KM)</td></tr><th>Truck No: </th><td style='text-align: right'>"+data.truck_no+"</td></tr><tr><th>Name: </th><td style='text-align: right'>";
// 		contentString = contentString + data.transporter_name + "</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+data.lsp_name+"</td></tr><tr><th>Department:</th>";
// 		contentString = contentString + "<td style='text-align: right'>"+data.dept_code+"</td></tr><tr><th>Address:</th><td style='text-align: right'>"+data.address+"</td></tr></table>";
// 			*/	
// 		var infowindow = new window.google.maps.InfoWindow({
// 			content: contentString
// 		});

// 		marker.setAnimation(window.google.maps.Animation.DROP)
// 		marker.addListener('click', function() {
// 			infowindow.open(map, marker);
// 		});

// 		marker.setMap(map);


// 	}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}


function loadDateTimeScript() {
    // alert("timeDate");
    $("#invoice_month").datepicker({
        changeMonth: true,
        changeYear: true,
        showButtonPanel: false,
        dateFormat: "yy-mm",
        onClose: function (dateText, inst) {

            //Get the selected month value
            var month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();

            //Get the selected year value
            var year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();

            //set month value to the textbox
            $(this).datepicker('setDate', new Date(year, month, 1));
        },
        beforeShow: function (input, inst) {
            $('#ui-datepicker-div').addClass("invoice_month");
        }
    });
}

function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker: false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    // console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};
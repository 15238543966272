/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import CSVFileValidator from 'csv-file-validator';
import Map from '../common/drawmap';
import FileBase64 from 'react-file-base64';
import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import MapComponent from './mapLocationComponent';
import Consignmentforceclose from '../manage/consignmentforceclose';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import download from 'js-file-download';
import { func } from 'prop-types';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
var path = require('path');

export default class EditSeaImports extends Component{
    constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            csvcontent:"",
            file : "",
            loadshow:'show-n',
            overly:'show-n',
            uploadYard : "",
            uploadDispatchDate : "",
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            pagetitle:"Edit Booking Request",
            bulkslide:"",
            benefitCert:"",
            date:"",
            deptName:"",
            indenter_name:"",
            mobile:"",
            indenter_email:"",
            erpPO:"",
            erpPoNo:"",
            erpPoDate:"",
            ringiNo:"",
            ringi_no:"",
            item_desc:"",
            gross_weight:"",
            dimensions_of_package:"",
            gross_cargo_volume:"",
            size_of_container:"",
            no_of_containers:"",
            material_requirement_date:"",
            shipment_type : "",
            cargo_type : "",
            type_of_container : "",
            msds_file : "",
            handling_type : "",
            type_of_special_handling : "",
            cargoDeliveryPlant:"",
            item_description:"",
            dimensions:"",
            supplier_name:"",
            supplier_address:"",
            supplier_contact_person:"",
            country:"",
            emailID:"",
            phone:"",
            delivery_terms:"Ex Works",
            pick_up_address:"",
            cargoDate:"",
            LC : "",
            LCno : "",
            nature : "",
            freeTrade : "",
            deliveryLabel : "Pick Up Address",
            airportName:"",
            hiddenRingiFile:"",
            msds_docs:"",
            port : "",
            ringi_file : "",
            uploaded_ringi_file : "",
            airport_name : "",
            erpData : [{}],
            ringiData : [{}],
            ffsuppliers: [],
            chasuppliers: [],
            packetrows: [{}],
            requestFields:"show-m",
            prepaidFields:"show-n",
            addresstxt:"",
            request_type:1,
            fcl_or_lcl:'',
            fclFields:"show-m",
            showTransportation : "show-n",
            showChaAssignDiv : 'show-n',
            showFFAssignDiv:"show-n",
            disableBng : "disabled",

            technical_write_up_of_product :'',
            hsn_code_by_shipper:'',
            material_composition:'',
            photograph:'',
            end_use_of_item:"",
            technical_write_up_of_product_file:[],
            technical_write_up_of_product_file_name:'',
            photograph_file:[],
            photograph_name:"",
            //components : {datepicker:getDatePicker()}
        }
        // this.changeHandler = this.changeHandler.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        if (googleAnalytics.page.enableGA) {
			this.logPageView();
		}

		var inputEventval = window.atob(this.props.match.params.eventname);
		// console.log
		
		var reqparams = {
            eventID : inputEventval,
        }

        
        redirectURL.post("/consignments/seaimportsuppliers").then((response)=>{
            var ffsuppliers = response.data.ffsuppliers;
            var chasuppliers = response.data.chasuppliers;
            // console.log(ffsuppliers,"ffsuppliers")
            // console.log(chasuppliers,"chasuppliers")
            this.setState({
                ffsuppliers : ffsuppliers,
                chasuppliers : chasuppliers,
            })
            redirectURL.post("/consignments/getSeaImportsByRequestId",reqparams).then((response)=>{
                // console.log(response.data);
                this.setState({erpSel : "",erpSel1 : "",cdpSel : "",cdpSel1 : "",cdpSel2 : "",cdpSel3 : "",cdpSel4 : "",gwSel : "",gwSel1 : "",dtSel : "",dtSel1 : "",dtSel2 : "",lcSel : "",lcSel1 : "",natSel : "",natSel1 : "",ftSel : "",ftSel1 : "",bcSel : "",bcSel1 : "",acSel : "",acSel1 : "",acSel2 : "",acSel3 : "",achSel : "",achSel1 : "",});
                if(response.data[0].erpPO == "yes")
                {
                    this.setState({erpSel : "selected",disableERP:""});
                }
                else if(response.data[0].erpPO == "no")
                {
                    this.setState({erpSel1 : "selected",disableERP:"disabled",});
                }
                if(response.data[0].lc == "yes")
                {
                    this.setState({disableLC:""});
                }
                else if(response.data[0].lc == "no")
                {
                    this.setState({disableLC:"disabled"});
                }
                var erpData=[{}];
                if(response.data[0].erpData !=undefined && response.data[0].erpData !='')
                {
                    var erpData = JSON.parse(response.data[0].erpData);
                    erpData.map(function(e){
                        var eDate = e.erPoDate;
                        if(eDate !=undefined && eDate !='')
                        {
                            if(eDate.includes("T") == true)
                            {
                                eDate = eDate.split("T");
                                eDate = eDate[0];
                                eDate = eDate.split("-")[2]+"-"+eDate.split("-")[1]+"-"+eDate.split("-")[0];
                            }
                        }
                        e.erPoDate = eDate;
                    })
                }
                var ringiData=[{}];
                if(response.data[0].ringiData !=undefined && response.data[0].ringiData !='')
                {
                    var ringiData = JSON.parse(response.data[0].ringiData);
                }
                var containersData=[{}];
                if(response.data[0].reqContainersData !='' && response.data[0].reqContainersData !=undefined)
                {
                    var containersData = JSON.parse(response.data[0].reqContainersData);
                }
                var date ="";
                if(response.data[0].date !=undefined && response.data[0].date !='')
                {
                    date = response.data[0].date;
                    if(date.includes("T") == true)
                    {
                        date = date.split("T");
                        date = date[0];
                        date = date.split("-")[2]+"-"+date.split("-")[1]+"-"+date.split("-")[0];
                    }
                }
                var material_requirement_date ="";
                if(response.data[0].material_requirement_date !=undefined && response.data[0].material_requirement_date !='')
                {
                    material_requirement_date = response.data[0].material_requirement_date;
                    if(material_requirement_date.includes("T") == true)
                    {
                        material_requirement_date = material_requirement_date.split("T");
                        material_requirement_date = material_requirement_date[0];
                        material_requirement_date = material_requirement_date.split("-")[2]+"-"+material_requirement_date.split("-")[1]+"-"+material_requirement_date.split("-")[0];
                    }
                }
                $("#material_requirement_date").val(material_requirement_date);
                var cargo_date ="";
                if(response.data[0].cargo_date !=undefined && response.data[0].cargo_date !='')
                {
                    cargo_date = response.data[0].cargo_date;
                    if(cargo_date.includes("T") == true)
                    {
                        cargo_date = cargo_date.split("T");
                        cargo_date = cargo_date[0];
                        cargo_date = cargo_date.split("-")[2]+"-"+cargo_date.split("-")[1]+"-"+cargo_date.split("-")[0];
                    }
                }
                // var eta_igi_airport_date = "";
                // if(response.data[0].eta_igi_airport_date !=undefined && response.data[0].eta_igi_airport_date !='')
                // {
                //     eta_igi_airport_date = response.data[0].eta_igi_airport_date;
                //     if(eta_igi_airport_date.includes("T") == true)
                //     {
                //         eta_igi_airport_date = eta_igi_airport_date.split("T");
                //         eta_igi_airport_date = eta_igi_airport_date[0];
                //         eta_igi_airport_date = eta_igi_airport_date.split("-")[2]+"-"+eta_igi_airport_date.split("-")[1]+"-"+eta_igi_airport_date.split("-")[0];
                //     }
                // }
                $("#size_of_container").val(response.data[0].size_of_container);
                $("#type_of_container").val(response.data[0].type_of_container);
                $("#shipment_type").val(response.data[0].shipment_type);
                $("#cargo_type").val(response.data[0].cargo_type);
                $("#handling_type").val(response.data[0].handling_type);
                $("#cargo_delivery_plant").val(response.data[0].cargo_delivery_plant);
                if(response.data[0].invoice_type !=undefined && response.data[0].invoice_type !="")
                {
                    $("#invoice_type").val(response.data[0].invoice_type);
                }
                if(response.data[0].order_type !=undefined && response.data[0].order_type !="")
                {
                    $("#order_type").val(response.data[0].order_type);
                }
                $("#remarks").val(response.data[0].remarks);
                if(response.data[0].shipment_reference_no !=undefined && response.data[0].shipment_reference_no !="")
                {
                    $("#shipment_reference_no").val(response.data[0].shipment_reference_no);
                }
                $("#delivery_terms").val(response.data[0].delivery_terms);
                $("#lc").val(response.data[0].lc);
                $("#nature").val(response.data[0].nature);
                $("#free_trade").val(response.data[0].free_trade);
                $("#benefit_certificate").val(response.data[0].benefit_certificate);
                $("#epcg").val(response.data[0].epcg);
                var containersData=[{}];
                if(response.data[0].reqContainersData !='' && response.data[0].reqContainersData !=undefined)
                {
                    var containersData = JSON.parse(response.data[0].reqContainersData);
                }
                this.setState({
                    event : response.data[0].event,
                    editedEventID : response.data[0]._id,
                    oldEvent : response.data[0].event,
                    airport_name : response.data[0].airport_name,
                    benefit_certificate : response.data[0].benefit_certificate,
                    assign_to_ff : response.data[0].assign_to_ff,
                    assign_to_cha : response.data[0].assign_to_cha,
                    assign_to_transporter : response.data[0].assign_to_transporter,
                    cargo_date : cargo_date,
                    cargo_delivery_plant : response.data[0].cargo_delivery_plant,
                    country : {"label": response.data[0].country,"value" : response.data[0].country},
                    date : date,
                    material_requirement_date:material_requirement_date,
                    delivery_terms : response.data[0].delivery_terms,
                    dept_name : response.data[0].dept_name,
                    email : response.data[0].email,
                    erpPO : response.data[0].erpPO,
                    erpData : erpData,
                    item_desc : response.data[0].item_desc,
                    gross_weight : response.data[0].gross_weight,
                    dimensions_of_package : response.data[0].dimensions_of_package,
                    gross_cargo_volume : response.data[0].gross_cargo_volume,
                    size_of_container : response.data[0].size_of_container,
                    type_of_container : response.data[0].type_of_container,
                    no_of_containers : response.data[0].no_of_containers,
                    shipment_type : response.data[0].shipment_type,
                    cargo_type : response.data[0].cargo_type,
                    handling_type : response.data[0].handling_type,
                    free_trade : response.data[0].free_trade,
                    indenter_email : response.data[0].indenter_email,
                    indenter_name : response.data[0].indenter_name,
                    job_no : response.data[0].job_no,
                    lc : response.data[0].lc,
                    lc_no : response.data[0].lc_no,
                    port_of_dispatch : response.data[0].port_of_dispatch,
                    mobile : response.data[0].mobile,
                    nature : response.data[0].nature,
                    no_of_packets : response.data[0].no_of_packets,
                    phone : response.data[0].phone,
                    port : response.data[0].port,
                    pick_up_address : response.data[0].pick_up_address,
                    ringiData : ringiData,
                    packetrows : containersData,
                    supplier_address : response.data[0].supplier_address,
                    supplier_contact_person : response.data[0].supplier_contact_person,
                    supplier_name : response.data[0].supplier_name,
                    //eta_igi_airport_date : eta_igi_airport_date,
                    //ff_contact_person_details : response.data[0].ff_contact_person_details,
                    //fta : response.data[0].fta,
                    request_type : response.data[0].request_type,
                    fcl_or_lcl: response.data[0].fcl_or_lcl,

                    technical_write_up_of_product :response.data[0].technical_write_up_of_product,
                    hsn_code_by_shipper:response.data[0].hsn_code_by_shipper,
                    material_composition:response.data[0].material_composition,
                    photograph_name:response.data[0].photograph,
                    end_use_of_item:response.data[0].end_use_of_item,
                    technical_write_up_of_product_file_name:response.data[0].technical_write_up_of_product_file,
                })
                // if(response.data[0].fta !=undefined && response.data[0].fta !="")
                // {
                //     $("#fta").val(response.data[0].fta);
                // }
                if(response.data[0].msds_file != undefined && response.data[0].msds_file !='')
                {
                    this.setState({msds_docs:response.data[0].msds_file})
                }
                var request_type = response.data[0].request_type;
                if(request_type == 3)
                {
                    // console.log(request_type,"request_type")
                    var requestFields = "show-n";
                    var prepaidFields = "show-m";
                    var addresstxt = "(To be mentioned on BOE)";
                    $(".requestFields").removeAttr("required",true);
                    $(".prepaidFields").attr("required",true);
                    var pagetitle = "Edit Booking Request (Prepaid)";
                }
                else if(request_type == 1)
                {
                    // console.log(request_type,"request_type")
                    var requestFields = "show-m";
                    var prepaidFields = "show-n";
                    var fclFields = "show-m";
                    var pagetitle = "Booking Request (Sea Imports - FCL)";
                    $(".requestFields").attr("required",true);
                    $(".prepaidFields").removeAttr("required",true);
                    $(".fclFields").attr("required",true);
                    $("#cargo_type").removeAttr("disabled",true);
                    this.setState({
                        cargo_type : ""
                    });
                    var pagetitle = "Edit Booking Request (Sea Imports - FCL)";
                }
                else
                {
                    var fclFields = "show-n";
                    var pagetitle = "Booking Request (Sea Imports - LCL)";
                    $(".requestFields").attr("required",true);
                    $(".prepaidFields").removeAttr("required",true);
                    $(".fclFields").removeAttr("required",true);
                    // $("#cargo_type").val("Non Hazardous");
                    //enabling the cargo_type editable so user can change values
                    $("#cargo_type").removeAttr("disabled",true);
                    // $("#cargo_type").attr("disabled",true);
                    this.setState({
                        cargo_type : ""
                    });
                    var pagetitle = "Edit Booking Request (Sea Imports - LCL)";
                }
                this.setState({
                    requestFields:requestFields,
                    prepaidFields:prepaidFields,
                    fclFields:fclFields,
                    pagetitle:pagetitle,
                })
                containersData.map(function(p,index){
                    // console.log(p,index,"pIndex")
                    $("#type_of_container_"+index).val(p.containerType);
                    $("#size_of_container_"+index).val(p.containerSize);
                    $("#shipment_type_"+index).val(p.shipmentIs);
                    $("#handling_type_"+index).val(p.handlingType);
                    var splHandling = "";
                    try{
                        if(p.splHandling !=undefined)
                        {
                            splHandling = p.splHandling
                        }
                    }
                    catch(e){
                        console.log(e)
                    }
                    $("#type_of_special_handling_"+index).val(splHandling);
                });
                if(response.data[0].ff_code != undefined && response.data[0].ff_code !='')
                {
                    // console.log(response.data[0].ff_code,"ff_code")
                    $("#assign_to_ff").val(response.data[0].ff_code);
                }            
                if(response.data[0].cha_code != undefined && response.data[0].cha_code !='')
                {
                    $("#assign_to_cha").val(response.data[0].cha_code);
                }            
                if(response.data[0].transporter_code != undefined && response.data[0].transporter_code !='')
                {
                    $("#assign_to_transporter").val(response.data[0].transporter_code);
                }
                // $("#assign_to_ff").val(response.data[0].assign_to_ff);
                // $("#assign_to_cha").val(response.data[0].assign_to_cha);
                // $("#assign_to_transporter").val(response.data[0].assign_to_transporter);
            }).catch(function(error){
                console.log(error);
            })
        }).catch((e)=>{
            console.log(e);
        })		
    }

    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
    }
    
onClickHideAll(){
    this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:''
    });
    
}
closeAlert = () => {
    this.setState({
        show: false
    });
}
changeHandler = (event) => {
    console.log(event.target.name,"Select", event.target.value);
    let name = event.target.name;
    // console.log(name);
    let value = event.target.value;
    this.setState({[name]:value});
    if(name == 'is_cha_available'){
        if(value == "yes")
        {
            this.setState({
                showChaAssignDiv : "show-m",
            })
            $("#assign_to_transporter").attr("required",true);
        }
        else{
            this.setState({
                showChaAssignDiv : "show-n",
            })
            $("#assign_to_transporter").val("");
            $("#assign_to_transporter").removeAttr("required",true);
        }
    }
    if(name == 'is_ff_available'){
        if(value == "yes")
        {
            this.setState({
                showFFAssignDiv : "show-m",
            })
            // $("#assign_to_transporter").attr("required",true);
        }
        else{
            this.setState({
                showFFAssignDiv : "show-n",
            })
            // $("#assign_to_transporter").val("");
            // $("#assign_to_transporter").removeAttr("required",true);
        }
    }
    if(name == "erpPO")
    {   
        if(value == "yes")
        {
            this.setState({
                disableERP : ""
            })
            $("#erpPoNo").val(this.state.erpPoNo);
            $("#erpPoDate").val(this.state.erpPo_date);
        }else{
            this.setState({
                disableERP : "disabled",
                erpPoNo : "",
                erpPo_date : "",
            })
            $("#erpPoNo").val("");
            $("#erpPoDate").val("");
        }
    }
    if (name === "hsn_code_by_shipper") {
        // Allow only digits and limit length to 8
        const validatedValue = value.replace(/[^0-9]/g, '').substring(0, 8);
        this.setState({ [name]: validatedValue });
    } else {
        this.setState({ [name]: value });
    }
    if(name == "LC")
    {
        if(value == "yes")
        {
            this.setState({
                disableLC : "",
            })
            // $("#LCno").val(this.state.erpPo_date);
        }else{
            this.setState({
                disableLC : "disabled",
                lc_no : ""
            })
        }
    }
    if(name == "is_transportation_available")
    {
        if(value == "yes")
        {
            this.setState({
                showTransportation : "show-m",
            })
            $("#assign_to_transporter").attr("required",true);
        }
        else{
            this.setState({
                showTransportation : "show-n",
            })
            $("#assign_to_transporter").val("");
            $("#assign_to_transporter").removeAttr("required",true);
        }
    }
    if(name == "cargoDeliveryPlant")
    {   
        if(value == "Bangalore")
        {
            $("#invoice_type").prop("required",true);
            $("#order_type").prop("required",true);
            $("#invoice_type").val("");
            $("#order_type").val("");
            this.setState({
                disableBng : ""
            })
        }else{
            $("#invoice_type").removeProp("required");
            $("#order_type").removeProp("required");
            this.setState({
                disableBng : "disabled"
            })
        }
    }
}
changeFileHandler = async (e) => {
    // console.log(e.target.name)
    this.setState({ [e.target.name]: e.target.files[0] });
}
changeFileHandler1 = (e) => {
    const file = e.target.files[0];  // Get the first file from the FileList object
    console.log(e.target.name,file,e.target, 'check target file');
    if (file) {
        this.setState({ photograph_name:file.name,photograph_file:file });  // Store only the file name
    } else {
        this.setState({ photograph_name:"",photograph_file:[]});  // Reset if no file is selected
    }
}
changeFileHandler2 = (e) => {
    const file = e.target.files[0];  // Get the first file from the FileList object
    console.log(e.target.name,file,e.target, 'check target file');
    if (file) {
        this.setState({ technical_write_up_of_product_file_name:file.name,technical_write_up_of_product_file:file });  // Store only the file name
    } else {
        this.setState({ technical_write_up_of_product_file_name:"",technical_write_up_of_product_file:[]});  // Reset if no file is selected
    }
}
formHandler = (event) =>{
    event.preventDefault();
    this.setState({
        loadshow:'show-m',
        overly:'show-m',
    });
    var eventTarget = event.target;
    var request_type = this.state.request_type;
    //console.log(this.state.file,"this.state.file");
    if($("#dateField").val() == "__-__-____")
    {
        var date = "";
    }
    else{
        var date = $("#dateField").val();
    }
    if($("#material_requirement_date").val() == "__-__-____")
        {
            var material_requirement_date = "";
        }
        else{
            var material_requirement_date = $("#material_requirement_date").val();
        }
    if($("#erpPoDate").val() == "__-__-____")
    {
        var erpPo_date = "";
    }
    else{
        var erpPo_date = $("#erpPoDate").val();
    }
    if($("#setDate").val() == "__-__-____")
    {
        var cargo_date = "";
    }
    else{
        var cargo_date = $("#setDate").val();
    }
    //var eta_igi_airport_date=$("#eta_igi_airport_date").val();
    var erpLength=$("#erpLength").val();
    var erpData=[];
    for(var i=0;i<erpLength;i++)
    {
        var erpno=$("#erpPoNo_"+i).val();
        var erpDate=$("#erpPoDate_"+i).val();
        if(erpno !='' || erpDate !='')
        {
            var erptemp = {"erpPoNo":erpno,"erPoDate":erpDate}
            erpData.push(erptemp)
        }
    }
    var rngLength=$("#rngLength").val();
    var ringiData=[];
    for(var r=0;r<rngLength;r++)
    {
        var ringiFile=$("#ringi_file_"+r).val();
        var ringiDesc=$("#ringiDesc_"+r).val();
        if(ringiFile !='')
        {
            ringiFile = ringiFile.substring(ringiFile.lastIndexOf("\\") + 1, ringiFile.length);
            var rngtemp = {"ringi_file":ringiFile,"ringi_desc":ringiDesc}
            ringiData.push(rngtemp)
        }
        else
        {
            var ringiFile=$("#hiddenRingiFile_"+r).val();
            var rngtemp = {"ringi_file":ringiFile,"ringi_desc":ringiDesc}
            ringiData.push(rngtemp)
        }
    }
    var pktLength=$("#no_of_packets").val();
    ringiData=JSON.stringify(ringiData);
    erpData=JSON.stringify(erpData);

    // console.log('ringiData',ringiData);

    var dept_name=$("#dept_name").val();
    var indenter_name=$("#indenter_name").val();
    var mobile=$("#mobile").val();
    var indenter_email=$("#indenter_email").val();
    var erpPO=$("#erpPO").val();
    var erpPoNo=$("#erpPoNo").val();
    var ringi_no=$("#ringi_no").val();
    var item_desc=$("#item_desc").val();
    var gross_weight=$("#gross_weight").val();
    var dimensions_of_package=$("#dimensions_of_package").val();
    var gross_cargo_volume=$("#gross_cargo_volume").val();
    var size_of_container=$("#size_of_container").val();
    var no_of_containers=$("#no_of_containers").val();
    var material_requirement_date=$("#material_requirement_date").val();
    var shipment_type=$("#shipment_type").val();
    var cargo_type=$("#cargo_type").val();
    var msds_file=$("#msds_file").val();
    var type_of_container=$("#type_of_container").val();
    var handling_type=$("#handling_type").val();
    var type_of_special_handling=$("#type_of_special_handling").val();
    var cargo_delivery_plant=$("#cargo_delivery_plant").val();
    var supplier_name=$("#supplier_name").val();
    var supplier_address=$("#supplier_address").val();
    var supplier_contact_person=$("#supplier_contact_person").val();
    var country=this.state.country.value;
    //console.log(country);
    var email=$("#email").val();
    var phone=$("#phone").val();
    var delivery_terms=$("#delivery_terms").val();
    var pick_up_address=$("#pick_up_address").val();
    var port=$("#port").val();
    var airport_name=$("#airport_name").val();
    var lc=$("#lc").val();
    var lc_no=$("#lc_no").val();
    var port_of_dispatch=$("#port_of_dispatch").val();
    var nature=$("#nature").val();
    var free_trade=$("#free_trade").val();
    var benefit_certificate=$("#benefit_certificate").val();
    var epcg=$("#epcg").val();
    var ff_code = "";
    var assign_to_ff = "";
    // if(request_type !=2)
    // {
        var ff_code=$("#assign_to_ff").val();
        var assign_to_ff=$("#assign_to_ff").find('option:selected').text();
        if(assign_to_ff =="-- Select Assign To --")
        {
            assign_to_ff = "";
        }
    //}
    var cha_code=$("#assign_to_cha").val();
    var assign_to_cha=$("#assign_to_cha").find('option:selected').text();
    if(assign_to_cha =="-- Select Assign To --")
    {
        assign_to_cha = "";
    }
    var is_transportation_available=$("#is_transportation_available").val();
    var is_cha_available = $("#is_cha_available").val()
    var transporter_code=$("#assign_to_transporter").val();
    var assign_to_transporter=$("#assign_to_transporter").find('option:selected').text();
    if(assign_to_transporter =="-- Select Assign To --")
    {
        assign_to_transporter = "";
    }
    var no_of_packets=$("#no_of_packets").val();
    var invoice_type = $("#invoice_type").val();
    var order_type = $("#order_type").val();

    let photograph
    let photo_file_uploaded=0
    if(this.state.photograph_file.name){
        photograph=this.state.photograph_file
        photo_file_uploaded=1
    }else{
        photograph=this.state.photograph_name
    }
    console.log(this.state.photograph_file,this.state.photograph_name,photograph,"710")
    
    let technical_write_up_of_product_file
    let technical_write_up_of_product_file_uploaded=0
    if(this.state.technical_write_up_of_product_file.name){
        technical_write_up_of_product_file=this.state.technical_write_up_of_product_file
        technical_write_up_of_product_file_uploaded=1
    }else{
        technical_write_up_of_product_file=this.state.technical_write_up_of_product_file_name
    }
    console.log(this.state.technical_write_up_of_product_file_name,technical_write_up_of_product_file,"710555")
    

    var formfields = {
        date : date,
        material_requirement_date:material_requirement_date,
        dept_name : dept_name,
        indenter_name : indenter_name,
        mobile : mobile,
        indenter_email : indenter_email,
        erpPO : erpPO,
        cargo_delivery_plant : cargo_delivery_plant,
        // invoice_type : invoice_type,
        // order_type : order_type,
        supplier_name : supplier_name,
        supplier_address : supplier_address,
        supplier_contact_person : supplier_contact_person,
        country : country,
        email : email,
        phone : phone,
        delivery_terms : delivery_terms,
        pick_up_address : pick_up_address,
        port : port,
        airport_name : airport_name,
        cargo_date : cargo_date,
        lc : lc,
        lc_no : lc_no,
        port_of_dispatch : port_of_dispatch,
        nature : nature,
        free_trade : free_trade,
        benefit_certificate : benefit_certificate,
        ff_code : ff_code,
        cha_code : cha_code,
        transporter_code : transporter_code,
        assign_to_ff : assign_to_ff,
        assign_to_cha : assign_to_cha,
        assign_to_transporter : assign_to_transporter,

        technical_write_up_of_product :this.state.technical_write_up_of_product,
        hsn_code_by_shipper:this.state.hsn_code_by_shipper,
        material_composition:this.state.material_composition,
        photograph:photograph,
        end_use_of_item:this.state.end_use_of_item,
        technical_write_up_of_product_file:technical_write_up_of_product_file,
    }
    
    //console.log(formfields);
    var flag= 0;
    var emailflag = 0;
    for(let key in formfields)
    {
        // console.log(key,formfields[key]);editedEventID
        // if(key != "lc_no" && key != "pick_up_address" && key != "port" && key != "airport_name" && key != "erpPoNo" && key != "erpPo_date" && key !="ringi_file" && key !="hiddenRingiFile" && key !="ringiNo_base64")
        // {
        //     console.log(key)
        //     if(formfields[key].replace(/\s/g,"").length == 0)
        //     {
        //         console.log(key,formfields[key]);
        //         flag = 1;
        //     }
        // }
        if(key == "intender_email")
        {   
            //console.log(formfields[key].split("@"))
            if(formfields[key].split("@")[1] != "maruti.co.in")
            {
                emailflag = 1;
            }
        }
    }
    
    if(flag == 0 && emailflag == 0)
    {
        // console.log(ringiNo)
        let saveData = new FormData();
        saveData.append("request_type", this.state.request_type);

        var pktLength=$("#no_of_containers").val();
        var containersData=[];
        for(var p=0;p<pktLength;p++)
        {
            var containerType=$("#type_of_container_"+p).val();
            var containerSize=$("#size_of_container_"+p).val();
            var shipmentIs=$("#shipment_type_"+p).val();
            var handlingType=$("#handling_type_"+p).val();
            var splHandling=$("#type_of_special_handling_"+p).val();

            if(containerType !='' || containerSize !='')
            {
                var temp1 = {"containerType":containerType,"containerSize":containerSize,"shipmentIs":shipmentIs,"handlingType":handlingType,"splHandling":splHandling}
                containersData.push(temp1)
            }
        }
        containersData=JSON.stringify(containersData);
        saveData.append("rngLength", rngLength);
        for(var rn=0;rn<rngLength;rn++)
        {
            var ringiFile="ringi_file_"+rn;
            
            saveData.append(ringiFile,this.state[ringiFile]);
            // console.log(this.state[ringiFile]);

            var hdringiFile = $("#hiddenRingiFile_"+rn).val();
            var hdringiName = "hiddenRingiFile_"+rn;
            saveData.append(hdringiName,hdringiFile);
            // console.log('hdringiFile',hdringiFile);
        }
        var remarks = $("#remarks").val();
        var shipment_reference_no = $("#shipment_reference_no").val();
        var hsn_code_by_shipper = $("#hsn_code_by_shipper").val();
        saveData.append("date",date);
        saveData.append("material_requirement_date",material_requirement_date);
        saveData.append("dept_name",dept_name);
        saveData.append("indenter_name",indenter_name);
        saveData.append("mobile",mobile);
        saveData.append("indenter_email",indenter_email);
        saveData.append("erpPO",erpPO);
        saveData.append("erpData",erpData);
        saveData.append("ringiData",ringiData);
        saveData.append("item_desc",item_desc);
        saveData.append("gross_weight",gross_weight);
        saveData.append("dimensions_of_package",dimensions_of_package);
        saveData.append("gross_cargo_volume",gross_cargo_volume);
        saveData.append("no_of_containers",pktLength);
        saveData.append("containersData",containersData);
        saveData.append("cargo_type",cargo_type);
        saveData.append("msds_file",msds_file);
        saveData.append("cargo_delivery_plant",cargo_delivery_plant);
        saveData.append("invoice_type",invoice_type);
        saveData.append("order_type",order_type);
        saveData.append("remarks",remarks);
        saveData.append("shipment_reference_no",shipment_reference_no);
        saveData.append("supplier_name",supplier_name);
        saveData.append("supplier_address",supplier_address);
        saveData.append("supplier_contact_person",supplier_contact_person);
        saveData.append("country",country);
        saveData.append("email",email);
        saveData.append("phone",phone);
        saveData.append("delivery_terms",delivery_terms);
        saveData.append("pick_up_address",pick_up_address);
        saveData.append("port",port);
        saveData.append("airport_name",airport_name);
        saveData.append("cargo_date",cargo_date);
        saveData.append("lc",lc);
        saveData.append("lc_no",lc_no);
        saveData.append("port_of_dispatch",port_of_dispatch);
        saveData.append("nature",nature);
        saveData.append("free_trade",free_trade);
        saveData.append("benefit_certificate",benefit_certificate);
        saveData.append("epcg",epcg);
        saveData.append("ff_code",ff_code);
        saveData.append("assign_to_ff",assign_to_ff);
        saveData.append("cha_code",cha_code);
        saveData.append("transporter_code",transporter_code);
        saveData.append("assign_to_cha",assign_to_cha);
        saveData.append("is_transportation_available",is_transportation_available);
        saveData.append("assign_to_transporter",assign_to_transporter);
        saveData.append("editedEventID",this.state.editedEventID);
        saveData.append("hiddenRingiFile",this.state.ringi_file);
        saveData.append("job_no",this.state.job_no);
        saveData.append('is_cha_available',is_cha_available)
        saveData.append("fcl_or_lcl",this.state.fcl_or_lcl);
        //saveData.append("ff_contact_person_details",this.state.ff_contact_person_details);
        //saveData.append("fta",this.state.fta);
        saveData.append("updated_by",localStorage.getItem("email"));
        saveData.append("updated_by_user",localStorage.getItem("username"));
        // console.log('saveData',saveData);
        saveData.append("technical_write_up_of_product",this.state.technical_write_up_of_product);
        saveData.append("hsn_code_by_shipper",hsn_code_by_shipper);
        saveData.append("material_composition",this.state.material_composition);
        saveData.append("photograph",photograph);
        saveData.append("end_use_of_item",this.state.end_use_of_item);
        saveData.append("technical_write_up_of_product_file",technical_write_up_of_product_file);
        saveData.append("photo_file_uploaded",this.state.photo_file_uploaded);
        // saveData.append("technical_write_up_of_product_file_uploaded",this.state.technical_write_up_of_product_file_uploaded);
        redirectURL.post("/consignments/updateseaimportsFormData",saveData,{
            headers:{
                'content-type': 'multipart/form-data'
            }
        }).then((response)=>{   
            this.setState({
                basicTitle:"Success",
                basicType : "success",
                show : true,
                loadshow:'show-n',
                overly:'show-n',
            });
            window.location.reload();
        }).catch((e)=>{
            console.log(e);
        })
        
    }
    else
    {
        if(emailflag == 0)
        {
            this.setState({
                basicTitle:"Fields Should not be Empty",
                basicType : "warning",
                loadshow:'show-n',
                overly:'show-n',
                show : true
            })
        }
        else{
            this.setState({
                basicTitle:"Invalid Email. Must be maruti.co.in",
                basicType : "warning",
                loadshow:'show-n',
                overly:'show-n',
                show : true
            })
        }
        
    }
}

handleAddRow = () => {
    const item = {
        erpPoNo: "",
        erpPoDate: "",
    };
    this.setState({
        erpData: [...this.state.erpData, item]
    });
};
handleRemoveRow = (idx) => {
    this.state.erpData.splice(idx, 1);
    this.setState({ erpData: this.state.erpData });
};

handleAddRingiRow = () => {
    const item = {
        ringi_file: "",
        ringiDesc: "",
    };
    this.setState({
        ringiData: [...this.state.ringiData, item]
    });
};
handleRemoveRingiRow = (idx) => {
    this.state.ringiData.splice(idx, 1);
    this.setState({ ringiData: this.state.ringiData });
    var ringiFile = $("#hiddenRingiFile_"+idx).val();
    // console.log('ringiFile',ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/removeSeaExportsRingiFile",rngParam).then((response)=>{
        }).catch(function(error){
            console.log(error);
        })
    }
};

handleRemoveRingiFile = (idx) => {
    var ringiFile = $("#hiddenRingiFile_"+idx).val();
    // console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/removeSeaExportsRingiFile",rngParam).then((response)=>{
            $(".rnFile_"+idx).html("");
            this.hide();
        }).catch(function(error){
            console.log(error);
        })
    }
}

handleDownloadRingiFile = (idx) => {
    var ringiFile = $("#hiddenRingiFile_"+idx).val();
    //console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/downloadSeaImportsRingiFile",rngParam,{
            responseType: 'blob'
        }).then((response)=>{
            // console.log("dowlad response ",response)
            download(response.data, ringiFile)
        }).catch(function(error){
            console.log(error);
        })
    }
}

packetsHandler = (e)=> {
    var noOfPackets = e.target.value;
    if(parseInt(noOfPackets) >= 1)
    {
        this.setState({
            no_of_containers : noOfPackets
        })
        var lespcks = parseInt(noOfPackets)-1;
        var bhanu=[{}];
        for(var n=0;n<lespcks;n++)
        {
            const item = {
                containerType: "",
                containerSize: "",
                shipmentIs: "",
                handlingType: "",
                splHandling:"",
            };
            this.setState({
                packetrows: [...bhanu, item]
            });
            var bhanu=[...bhanu, item];
        }
    }
    else
    {
        alert("No of containers should be greater than 0");
        $("#no_of_containers").val(this.state.no_of_containers);
        const item = {
            containerType: "",
            containerSize: "",
            shipmentIs: "",
            handlingType: "",
            splHandling:"",
        };
        this.setState({
            packetrows: [item]
        });
    }
}

countryoptions(){
    var items=[];
    items.push({label:"Algeria",value:"Algeria"})
    items.push({label:"Australia",value:"Australia"})
    items.push({label:"Austria",value:"Austria"})
    items.push({label:"Belgium",value:"Belgium"})
    items.push({label:"Canada",value:"Canada"})
    items.push({label:"Czech Republic",value:"Czech Republic"})
    items.push({label:"China",value:"China"})
    items.push({label:"Denmark",value:"Denmark"})
    items.push({label:"Dubai",value:"Dubai"})
    items.push({label:"France",value:"France"})
    items.push({label:"Germany",value:"Germany"})
    items.push({label:"Hong Kong",value:"Hong Kong"})
    items.push({label:"Hungary",value:"Hungary"})
    items.push({label:"Indonesia",value:"Indonesia"})
    items.push({label:"Italy",value:"Italy"})
    items.push({label:"Japan",value:"Japan"})
    items.push({label:"Malaysia",value:"Malaysia"})
    items.push({label:"Myanmar",value:"Myanmar"})
    items.push({label:"Nigeria",value:"Nigeria"})
    items.push({label:"Netherlands",value:"Netherlands"})
    items.push({label:"Poland",value:"Poland"})
    items.push({label:"Romania",value:"Romania"})
    items.push({label:"S. Korea",value:"S. Korea"})
    items.push({label:"Serbia",value:"Serbia"})
    items.push({label:"Singapore",value:"Singapore"})
    items.push({label:"Slovakia",value:"Slovakia"})
    items.push({label:"Spain",value:"Spain"})
    items.push({label:"Sweden",value:"Sweden"})
    items.push({label:"Switzerland",value:"Switzerland"})
    items.push({label:"Taiwan",value:"Taiwan"})
    items.push({label:"Thailand",value:"Thailand"})
    items.push({label:"Turkey",value:"Turkey"})
    items.push({label:"UK",value:"UK"})
    items.push({label:"USA",value:"USA"})
    items.push({label:"Vietnam",value:"Vietnam"})
    return items;
}

onSelectLocation= country =>{
    this.setState(
        { country },
        // () => console.log(`Location Option selected:`, this.state.country)
      );
}

handlerDateTime = (event,id) => {
    // console.log(event,id)
    var d = new Date(event._d);
    
    var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    var erpData = this.state.erpData;
    erpData.map(function(e,index){
        var eDate = e.erPoDate;
        // console.log(index,id,"bhanu")
        if(index == id)
        {
            e.erPoDate = startdate;
        }        
    })
    this.setState({
        erpData : erpData
    });
    //let value = event.target.value;
    //this.setState({'startDate':value});
}
handleRemoveFilewrite =  (filesName,rowId) => {
    console.log('infunction',filesName,rowId)
    this.setState({technical_write_up_of_product_file_name:''})
    console.log(filesName)
    if(filesName !='')
    {
        var rngParam = {
          rowId:rowId,
          type:'technical_write_up_of_product_file'
        }
        redirectURL.post("/consignments/removefilesea",rngParam).then((response)=>{ 
        }).catch(function(error){
            console.log(error);
        })
    }
}
handleRemoveFile2 = (fileName, rowId) => {
    this.setState({ photograph_name: '' });  // Reset the photograph state
    console.log(fileName);
    if (fileName !== '') {
        const rngParam = {
            rowId: rowId,
            type: 'photograph'
        };
        redirectURL.post("/consignments/removefilesea", rngParam).then((response) => {
            // Handle response if needed
        }).catch(function (error) {
            console.log(error);
        });
    }
}
changeHandlerForDate = (event) => {
    console.log(event.target.name,"Select", event.target.value);
    let name = event.target.name;
    // console.log(name);
    let value = event.target.value;
    this.setState({[name]:value});
}

render(){
        
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle}</span>
                                    <a className="btn btn-danger float-right" href="/seaimports">Back</a>
								</h5>
				   			</div>
                               
                            <div className="card-body pt-15px">
                                <form class="aircargo-form" onSubmit={this.formHandler} encType="multipart/form-data">
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Date : </label>
                                            <input type="text" name="date" id="dateField" value={this.state.date} className="datetimepicker_date form-control" required onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Department Name : </label>
                                            <input type="text" name="dept_name" id="dept_name" className="form-control" value={this.state.dept_name} required onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Indenter Name : </label>
                                            <input type="text" name="indenter_name" id="indenter_name" className="form-control" value={this.state.indenter_name} required onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Mobile No : </label>
                                            <input type="tel" name="mobile" id="mobile" className="form-control" pattern="[0-9]{10}" value={this.state.mobile} required onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Indenter Email ID : </label>
                                            <input type="email" name="indenter_email" id="indenter_email" className="form-control" value={this.state.indenter_email} required onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">ERP PO : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="erpPO" id="erpPO" required onChange={this.changeHandler}>
                                                <option value="">-- Select ERP PO --</option>
                                                <option selected={this.state.erpSel} value="yes">Yes</option>
                                                <option selected={this.state.erpSel1} value="no">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    {this.state.erpData.map((item, idx) => (
                                    <div className="row col-xl-12" key={idx}>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">ERP PO No : </label>
                                            <input type="text" name={"erpPoNo_"+idx} id={"erpPoNo_"+idx}className="form-control" value={item.erpPoNo} disabled={this.state.disableERP} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">ERP PO Date : </label>
                                            <input type="text" name={"erpPoDate_"+idx} id={"erpPoDate_"+idx}className="datetimepicker_date form-control"  value={item.erPoDate} disabled={this.state.disableERP} />
                                            {/* <Datetime 
                                                disableCloseOnClickOutside={false} 
                                                closeOnSelect={true} 
                                                timeFormat={false}
                                                minDate={true}
                                                inputProps={{ placeholder: 'Date', name: "erpPoDate_"+idx, id : "erpPoDate_"+idx, autoComplete:'off', disabled:this.state.disableERP }} 
                                                dateFormat="DD-MM-YYYY"
                                                //format="y-MM-dd"
                                                //isValidDate={this.validation}
                                                name={"erpPoDate_"+idx}
                                                id={"erpPoDate_"+idx}
                                                value={item.erPoDate}
                                                onChange={this.handlerDateTime.bind(this,idx)}
                                            /> */}
                                        </div>
                                        <div className="col-xl-4" style={{display: (idx != 0) ? 'block' : 'none'}}>
                                            <h6 style={{marginTop:"2.5em"}} onClick={() => this.handleRemoveRow(idx)}>Clear</h6>
                                        </div>
                                    </div>
                                    ))}
                                    <input type="hidden" id="erpLength" value={this.state.erpData.length}/>
                                    <div class="row col-sm-12">
                                        <div className="row col-xl-12 input_fields_wrap m-top-1" id="addMrERp">
                                            <div class="col-xl-12">
                                                <button class="btn btn-primary" type="button" onClick={this.handleAddRow}>Add More Fields +</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    {this.state.ringiData.map((item, idx) => (
                                    <div className={"row col-xl-12 "} key={idx}>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Ringi Attachment: </label>
                                            <input type="file" name={"ringi_file_"+idx} id={"ringi_file_"+idx} className="form-control" onChange={this.changeFileHandler} />

                                            {((item.ringi_file != "") && (item.ringi_file != undefined)) ?  
                                            <div className="row col-xl-12">
                                                <div className="col-xl-9">
                                                     <a className={"rnFile_"+idx}>{item.ringi_file}</a> 
                                                </div>
                                                <div className="col-xl-1">
                                                    <p className={"rnFile_"+idx} style={{cursor:"pointer"}} onClick={() => this.handleDownloadRingiFile(idx)}><i title="Download" className="fa fa-download"></i></p>
                                                    
                                                </div>
                                                <div className="col-xl-1">
                                                    <p className={"rnFile_"+idx} style={{cursor:"pointer"}} onClick={() => this.handleRemoveRingiFile(idx)}><i title="Delete" className="fa fa-trash"></i></p>
                                                </div>
                                                <input type="hidden" id={"hiddenRingiFile_"+idx} value={item.ringi_file} />
                                            </div>
                                            :
                                            ""
                                            }
                                            
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Ringi No : </label>
                                            <input type="text" name={"ringiDesc_"+idx} id={"ringiDesc_"+idx} className="form-control" value={item.ringi_desc} onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4" style={{display: (idx != 0) ? 'block' : 'none'}}>
                                            <h6 style={{marginTop:"2.5em",cursor:"pointer"}} onClick={() => this.handleRemoveRingiRow(idx)}>Clear</h6>
                                        </div>
                                    </div>
                                    ))}
                                    <input type="hidden" id="rngLength" value={this.state.ringiData.length}/>
                                    <div class={"row col-sm-12 "}>
                                        <div className="row col-xl-12 m-top-1">
                                            <div class="col-xl-12">
                                                <button class="btn btn-primary" type="button" onClick={this.handleAddRingiRow}>Add More Fields +</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class={"hr-dashed "}></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Item Description* : </label>
                                            <input type="text" name={"item_desc"} id={"item_desc"}className="form-control" value={this.state.item_desc} onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Cargo Gross Weight (Metric Tons)* : </label>
                                            <input type="text" name={"gross_weight"} id={"gross_weight"}className="form-control" value={this.state.gross_weight} onChange={this.changeHandler} required/>
                                        </div>
                                        <div className={"col-xl-3"}>
                                            <label className="col-xl-12 col-lg-12">Volume (CBM)* : </label>
                                            <input type="text" name={"dimensions_of_package"} id={"dimensions_of_package"} placeholder="Volume (CBM)" className="form-control"  value={this.state.dimensions_of_package} onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-3 "+this.state.fclFields}>
                                            <label className="col-xl-12 col-lg-12">Gross Cargo Volume (CBM)* : </label>
                                            <input type="text" name={"gross_cargo_volume"} id={"gross_cargo_volume"} className="form-control fclFields" value={this.state.gross_cargo_volume}  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Material Requirement Date* : </label>
                                            <input type="text" name="material_requirement_date" id="material_requirement_date"  className="datetimepicker_date form-control" required  />
                                        </div>
                                    </div>
                                    <div class={"hr-dashed "+this.state.fclFields}></div>

                                    <div className="row col-xl-12">                                    
                                        <div className={"col-xl-3 "+this.state.fclFields}>
                                            <label className="col-xl-12 col-lg-12">No Of Containers required* : </label>
                                            <input type="text" name="no_of_containers" id="no_of_containers" value={this.state.no_of_containers} className="form-control num-cls fclFields" onBlur={this.packetsHandler}/>
                                        </div>
                                       
                                    </div>

                                    {this.state.packetrows.map((item, idx) => (
                                    <div className="row col-xl-12" key={idx}>
                                        <div className={"col-xl-3 "+this.state.fclFields}>
                                            <label className="col-xl-12 col-lg-12">Size Of Container required* : </label>
                                            <select class="form-control col-xl-12 col-lg-12 fclFields" name={"size_of_container_"+idx} id={"size_of_container_"+idx} onChange={this.changeHandler}  required>
                                                <option value="">-- Select Size --</option>
                                                <option value="20 FT">20 FT</option>
                                                <option value="40 FT">40 FT</option>
                                            </select>
                                        </div>
                                        <div className={"col-xl-3 "+this.state.fclFields}>
                                            <label className="col-xl-12 col-lg-12">Type Of Container* : </label>
                                            <select class="form-control col-xl-12 col-lg-12 fclFields" name={"type_of_container_"+idx} id={"type_of_container_"+idx} onChange={this.changeHandler}  required>
                                                <option value="">-- Select Type --</option>
                                                <option value="GP">General Purpose (GP)</option>
                                                <option value="FR">Flat Rack (FR)</option>
                                                <option value="HC">High Cube (HC)</option>
                                                <option value="OT">Open Top (OT)</option>
                                                <option value="others">Others</option>
                                            </select>
                                        </div>
                                        <div className={"col-xl-3 "+this.state.fclFields}>
                                            <label className="col-xl-12 col-lg-12">Shipment is : </label>
                                            <select class="form-control col-xl-12 col-lg-12 fclFields" name={"shipment_type_"+idx} id={"shipment_type_"+idx} onChange={this.changeHandler}  required>
                                                <option value="">-- Select Shipment is --</option>
                                                <option value="In Gauge">In Gauge</option>
                                                <option value="Out Gauge">Out Gauge</option>
                                            </select>
                                        </div>  <div className={"col-xl-3"}>
                                            <label className="col-xl-12 col-lg-12">Handling Type* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name={"handling_type_"+idx} id={"handling_type_"+idx} onChange={this.changeHandler}  required>
                                                <option value="">-- Select Handling Type --</option>
                                                <option value="Normal">Normal</option>
                                                <option value="Special">Special</option>
                                            </select>
                                        </div>
                                        {(this.state["handling_type_"+idx] == "Special")?
                                            <div className="col-xl-3">
                                                <label className="col-xl-12 col-lg-12"> Type of Special Handling* : </label>
                                                <input className="form-control tsplh" name={"type_of_special_handling_"+idx}id={"type_of_special_handling_"+idx} onChange={this.changeHandler} required/>
                                            </div>
                                        :""
                                        }
                                    </div>
                                    ))}
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className={"col-xl-3"}>
                                            <label className="col-xl-12 col-lg-12">Cargo Type* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="cargo_type" id="cargo_type" onChange={this.changeHandler}  required>
                                                <option value="">-- Select Cargo Type --</option>
                                                <option value="Hazardous">Hazardous</option>
                                                <option value="Non Hazardous">Non Hazardous</option>
                                            </select>
                                        </div>
                                        {(this.state.cargo_type == "Hazardous")?
                                            <div className="col-xl-3">
                                                <label className="col-xl-12 col-lg-12">MSDS* : </label>
                                                <input type="file" name={"msds_file"} id={"msds_file"} className="form-control requestFields" onChange={this.changeFileHandler} required />
                                                {((this.state.msds_docs != "") && (this.state.msds_docs != undefined)) ?  
                                                <div id="msds_docs" className="row">
                                                    <div className="col-xl-9">
                                                        <a className={"rnFile"}>{this.state.msds_docs}</a> 
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile"} style={{cursor:"pointer"}} onClick={() => this.handleDownloadFile(this.state.msds_docs)}><i title="Download" className="fa fa-download"></i></p>
                                                    </div>
                                                    <div className="col-xl-1">
                                                        {/* <p className={"rnFile"} style={{cursor:"pointer"}} onClick={() => this.handleRemoveFile(this.state.msds_docs,"msds_docs")}><i title="Delete" className="fa fa-trash"></i></p> */}
                                                    </div>
                                                    <input type="hidden" id={"hidden_msds_file"} value={this.state.msds_docs} />
                                                </div>
                                                :
                                                ""
                                            }
                                            </div>
                                        :""
                                        }
                                        <div className={"col-xl-3"}>
                                            <label className="col-xl-12 col-lg-12">Delivery in MSIL Plant* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="cargo_delivery_plant" id="cargo_delivery_plant" required onChange={this.changeHandler}>
                                                <option value="">-- Delivery in MSIL Plant --</option>
                                                <option value="MSIL-G">MSIL-G</option>
                                                <option value="MPT Manesar">MPT Manesar</option>
                                                <option value="Manesar Vehicle Plant">Manesar Vehicle Plant</option>
                                                <option value="HO">HO</option>
                                                <option value="MSIL-Rohtak">MSIL-Rohtak</option>
                                                <option value="Bangalore">Bangalore</option>
                                                <option value="MSIL-Kharkhoda">MSIL-Kharkhoda</option>
                                            </select>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Remarks : </label>
                                            <input type="text" name="remarks" id="remarks" className="form-control"  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">PO or Shipment reference No : </label>
                                            <input type="text" name="shipment_reference_no" id="shipment_reference_no" className="form-control"  onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Invoice Type* : </label>
                                            <select type="text" name="invoice_type" id="invoice_type" className="form-control" disabled={this.state.disableBng} onChange={this.changeHandler}>
                                                <option value="">Select Type</option>
                                                <option value="SMC">SMC</option>
                                                <option value="NSMC">NSMC</option>
                                                <option value="CONSUMABLE">CONSUMABLE</option>
                                            </select>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Order Type* : </label>
                                            <select type="text" name="order_type" id="order_type" className="form-control" disabled={this.state.disableBng}  onChange={this.changeHandler}>
                                                <option value="">Select Type</option>
                                                <option value="Regular">Regular</option>
                                                <option value="SPO">SPO</option>
                                                <option value="CONSUMABLE">CONSUMABLE</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Supplier Name : </label>
                                            <input type="text" name="supplier_name" id="supplier_name" value={this.state.supplier_name} className="form-control" required onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Supplier Address {this.state.addresstxt}* : </label>
                                            <textarea name="supplier_address" id="supplier_address" value={this.state.supplier_address} className="form-control" required onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Supplier Contact Person : </label>
                                            <input type="text" name="supplier_contact_person" id="supplier_contact_person" value={this.state.supplier_contact_person} placeholder="" className="form-control requestFields" required onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Country : </label>
                                           <Select  
                                           placeholder={"Select Country"} 
                                           isMulti={false} 
                                           value={this.state.country} 
                                           onChange={this.onSelectLocation.bind(this)} className="border-radius-0" 
                                           style={{borderRadius:"0px"}} 
                                           options={this.countryoptions()} 
                                           required />
                                        </div>
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Email ID : </label>
                                            <input type="email" name="email" id="email" className="form-control requestFields" value={this.state.email} required onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Phone No : </label>
                                            <input type="text" name="phone" id="phone" placeholder="" className="form-control requestFields" value={this.state.phone} required onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">In co Terms : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="delivery_terms" id="delivery_terms" required onChange={this.changeHandler}>
                                                <option value="">-- Select Delivery Terms --</option>
                                                <option value="FOB">FOB</option>
                                                <option value="CIF">CIF</option>
                                                <option value="DAP">DAP</option>
                                                <option value="DDP">DDP</option>
                                                <option value="DDU">DDU</option>
                                                <option value="Ex Works">Ex Works</option>
                                            </select>
                                        </div>
                                        {(this.state.delivery_terms == "Ex Works") || (this.state.dtSel == "Ex Works")?
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12">Pick Up Address : </label>
                                                <textarea className="form-control" name="pick_up_address" id="pick_up_address" value={this.state.pick_up_address} required onChange={this.changeHandler} />
                                            </div>
                                        :(this.state.delivery_terms == "FCA") || (this.state.dtSel1 == "FCA")?
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12">Place / Port : </label>
                                                <input type="text" className="form-control" name="port" id="port" value={this.state.port} required onChange={this.changeHandler} />
                                            </div>
                                        :""
                                        }
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Proposed Cargo Readiness Date : </label>
                                            <input type="text" name="setDate" id="setDate" className="cargo_date form-control requestFields" value={this.state.cargo_date} required onChange={this.changeHandler} />
                                        </div>
                                        {/* <div className={"col-xl-4 "+this.state.prepaidFields}>
                                            <label className="col-xl-12 col-lg-12">ETA IGI Airport Date* : </label>
                                            <input type="text" name="eta_igi_airport_date" id="eta_igi_airport_date" className="datetimepicker_date form-control prepaidFields" value={this.state.eta_igi_airport_date} onChange={this.changeHandler} required/>
                                        </div> */}
                                    </div>
                                    <div class={"hr-dashed "+this.state.requestFields}></div>
                                    <div className="row col-xl-12">
                                        
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">LC Available : </label>
                                            <select class="form-control col-xl-12 col-lg-12 requestFields" name="lc" id="lc" required onChange={this.changeHandler}>
                                                <option value="">-- Select LC --</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">LC No : </label>
                                            <input type="text" name="lc_no" id="lc_no" className="form-control requestFields" value={this.state.lc_no} disabled={this.state.disableLC}  onChange={this.changeHandler} />
                                        </div>

                                        
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Port Of Dispatch* : </label>
                                            <input type="text" name="port_of_dispatch" id="port_of_dispatch" className="form-control requestFields" value={this.state.port_of_dispatch} onChange={this.changeHandler} required/>
                                        </div>

                                        {/* <div className={"col-xl-4 "+this.state.prepaidFields}>
                                            <label className="col-xl-12 col-lg-12">FF Contact person Details* : </label>
                                            <input type="text" name="ff_contact_person_details" className="form-control prepaidFields" value={this.state.ff_contact_person_details} onChange={this.changeHandler} />
                                        </div> */}
                                    </div>
                                    <div class={"hr-dashed "}></div>
                                    <div className="row col-xl-12">
                                        <div className={"col-xl-3 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Nature Of Shipment : </label>
                                            <select class="form-control col-xl-12 col-lg-12 requestFields" name="nature" id="nature" required onChange={this.changeHandler}>
                                                <option value="">-- Select Nature --</option>
                                                <option selected={this.state.natSel} value="capital">Capital</option>
                                                <option selected={this.state.natSel1} value="revenue">Revenue</option>
                                            </select>
                                        </div>
                                        <div className={"col-xl-3"}>
                                            <label className="col-xl-12 col-lg-12">Free Trade Agreement : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="free_trade" id="free_trade" required onChange={this.changeHandler}>
                                                <option value="">-- Select Free Trade Agreement --</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                        <div className={"col-xl-3 "}>
                                            <label className="col-xl-12 col-lg-12">R&D Benefit Certificate : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="benefit_certificate" id="benefit_certificate" required onChange={this.changeHandler}>
                                                <option value="">-- Select Benefit Certificate --</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">EPCG* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="epcg"  id="epcg" onChange={this.changeHandler} required>
                                                <option value="">-- Select Type --</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                       
                                    </div>
                                    <div class={"hr-dashed "}></div>
                                    <div className="row col-xl-12">
                                    {(this.state.request_type != 3) ?
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Assign Frieght Forwarder: </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="is_ff_available" id="is_ff_available" required onChange={this.changeHandler}>
                                                <option value="">-- Select Option --</option>
                                                <option value={"yes"}>Yes</option>
                                                <option value={"no"}>No</option>
                                            </select>
                                        </div>
                                        : ""}
                                        
                                        <div className={"col-xl-3 "+this.state.showFFAssignDiv}>
                                            <label className="col-xl-12 col-lg-12">Assign To Freight Forwarder: </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="assign_to_ff" id="assign_to_ff" required onChange={this.changeHandler}>
                                                <option value="">-- Select Assign To --</option>
                                                {this.state.ffsuppliers.map(function(item){
                                                    return <option value={item.supplier_code}>{item.supplier_name}</option>
                                                })}
                                            </select>
                                        </div>
                                       
                                        {(this.state.request_type = 1) ?
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Assign Custom House Agent: </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="is_cha_available" id="is_cha_available" required onChange={this.changeHandler}>
                                                <option value="">-- Select Option --</option>
                                                <option value={"yes"}>Yes</option>
                                                <option value={"no"}>No</option>
                                            </select>
                                        </div>
                                        : ""}
                                        <div className={"col-xl-3 " + this.state.showChaAssignDiv}>
                                            <label className="col-xl-12 col-lg-12">Assign To Custom House Agent: </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="assign_to_cha" id="assign_to_cha" onChange={this.changeHandler}>
                                                <option value="">-- Select Assign To --</option>
                                                {this.state.chasuppliers.map(function(item){
                                                    return <option value={item.supplier_code}>{item.supplier_name}</option>
                                                })}
                                            </select>
                                        </div>
                                        
                                        {(this.state.request_type = 1) ?
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Is Transportation Available : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="is_transportation_available" id="is_transportation_available" required onChange={this.changeHandler}>
                                                <option value="">-- Select Option --</option>
                                                <option value={"yes"}>Yes</option>
                                                <option value={"no"}>No</option>
                                            </select>
                                        </div>
                                        : ""}
                                        <div className={"col-xl-3 "+this.state.showTransportation}>
                                            <label className="col-xl-12 col-lg-12">Assign To Transporter: </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="assign_to_transporter" id="assign_to_transporter" onChange={this.changeHandler} >
                                                <option value="">-- Select Assign To --</option>
                                                {this.state.chasuppliers.map(function(item){
                                                    return <option value={item.supplier_code}>{item.supplier_name}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Technical Write up of Product* : </label>
                                            <textarea name="technical_write_up_of_product" className="form-control" value={this.state.technical_write_up_of_product} onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Technical Write up of Product (File) : </label>
                                            <input type="file" name={"technical_write_up_of_product_file"} id={"technical_write_up_of_product_file"} className="form-control" onChange={this.changeFileHandler2}  /> 

                                            {this.state.technical_write_up_of_product_file_name && 
                                                <div className="row col-xl-12">
                                                    <div className="col-xl-10">
                                                        <p>{this.state.technical_write_up_of_product_file_name}</p> {/* Displaying the file name stored in the state */}
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p style={{ cursor: "pointer" }}
                                                        onClick={() => this.handleRemoveFilewrite(this.state.technical_write_up_of_product_file_name, this.state.editedEventID)}>
                                                            <i title="Delete" className="fa fa-trash"></i>
                                                        </p>
                                                    </div>
                                                </div>
                                            }                                    
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">FIX HSN code set by Shipper :</label>
                                            <input
                                                type="text"
                                                name="hsn_code_by_shipper"
                                                value={this.state.hsn_code_by_shipper}
                                                id="hsn_code_by_shipper"
                                                className="form-control"
                                                // pattern="^[0-9]{8}$"
                                                // title="HSN Code should be 8 digits"
                                                onChange={this.changeHandler}
                                                
                                            />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Photograph (File) :</label>
                                            <input
                                                type="file"
                                                name="photograph_file"
                                                id="photograph_file"
                                                className="form-control"
                                                onChange={this.changeFileHandler1}
                                            />
                                            {this.state.photograph_name && 
                                                <div className="row col-xl-12">
                                                    <div className="col-xl-10">
                                                        <p>{this.state.photograph_name}</p> {/* Displaying the file name stored in the state */}
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p style={{ cursor: "pointer" }}
                                                        onClick={() => this.handleRemoveFile2(this.state.photograph_name, this.state.editedEventID)}>
                                                            <i title="Delete" className="fa fa-trash"></i>
                                                        </p>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Material composition* : </label>
                                            <textarea name="material_composition" className="form-control"  value={this.state.material_composition} onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">End Use of the item* : </label>
                                            <textarea name="end_use_of_item" className="form-control" value={this.state.end_use_of_item}  onChange={this.changeHandler} required/>
                                        </div>
                                    </div>
                                    <div className="row col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-success" style={{marginLeft:"45%",width:"192px",marginTop:"20px"}}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>  
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>            
            </div>
        )
    }
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function cleanTruckNo(value){
    var result =  /\s/g.test(value);
    var cleanedValue="";
    try{
        if(result == true){
            cleanedValue = value.replace(/[^\w\s]/gi, '');
            cleanedValue = cleanedValue.replace(/\s/g, '').toUpperCase();
        }
        else{
            cleanedValue = value.toUpperCase();
        }
    }
    catch(e){  }
    return cleanedValue;
}


// function checkWhiteSpaces(value){
//     return /\s/g.test(value);
// }
// function removeWhiteSpace(value){
//     value = value.replace(/[^\w\s]/gi, '');
//     return value.replace(/\s/g, '').toUpperCase();
// }
function loadDateTimeScript(){
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
        format:'d-m-Y',
        minDate:false,
		timepicker:false,
    });
    
    $('.cargo_date').datetimepicker({
		//mask:'39-19-9999',
        format:'d-m-Y',
        minDate:false,
		timepicker:false,
    });
    
    
	$('.readiness_date').datetimepicker({
		//mask:'39-19-9999',
        format:'d-m-Y',
        minDate:false,
		timepicker:false,
    });
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}

$(document).on("input", ".num-cls", function() {
    this.value = this.value.replace(/\D/g,'');
});

// $(document).on("change", "#cargoDate", function(e) {
//     e.preventDefault();
//     var bookingDate=$("#dateField").val();
//     var cargoDate=$("#cargoDate").val();
//     var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
//     var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]

//     var startDate = new Date(bookingDate);
//     var endDate = new Date(cargoDate);
//     var startTime = startDate.getTime();
//     var endTime = endDate.getTime();
//     console.log(startTime,endTime);
//     if(startTime >= endTime)
//     {
//         $("#cargoDate").val("");
//         alert("Date should be greater than Booking Date");
//     }
// })


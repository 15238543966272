
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var moment = require('moment');
var redirectURL = require('../redirectURL');

 
const ExportsMsilToIcdAction = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onShowConsignmentExportsEventsClick(props);
    };
    
   // if(props.data.msil_transit_delay == 1)
    //{
   //     var colr = 'redfont'
   // }
   // else{
       //console.log("props.data = ", props.data);
        
	 
       if(props.data.status == 3)
       {
            if(props.data.expected_icd_arraival_date != '' && typeof props.data.expected_icd_arraival_date != 'undefined')
            {
                var revise = props.data.expected_icd_arraival_date;
                var rev = revise.split("T");
                var revisedDate = rev[0];
    
                var expectd = props.data.icd_arrival_date;
                var exdate = expectd.split("T");
                var expectedDate = exdate[0];
    
                let revisedDateOnlyMoment  = moment.parseZone(revisedDate).format("x");
                let expectedDateOnlyMoment  = moment.parseZone(expectedDate).format("x");
               //console.log(props.data.container_no+" ===  "+revisedDateOnlyMoment +">"+ expectedDateOnlyMoment)
                if(revisedDateOnlyMoment > expectedDateOnlyMoment)
                {
                    var colr = 'redfont'
                }
                else{
                    if(props.data.status > 3)
                    {
                        var colr = 'greenfont'
                    }
                    else{
                        var colr = 'yellowfont'
                    }
                }
            }
            else{
                if(props.data.status > 3)
                {
                    var colr = 'greenfont'
                }
                else{
                    var colr = 'yellowfont'
                }
            }
            
       }
       else{
            if(props.data.status > 3)
            {
                var colr = 'greenfont'
            }
            else{
                var colr = 'grayfont'
            }
       }
    //}
    return (
        <div>
            <button 
                onClick={handleEventClick} 
                className="custom-btn f22 label label-success" 
                title="MSIL to ICD" >
                    <i className={"icofont icofont-van-alt f25 "+(colr)}></i> &nbsp;
            </button>
            
        </div>
    );
};

export default ExportsMsilToIcdAction;

'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "./updatelmstates";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CellGraphComponent from './cellGraphComponent';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { HardwareDesktopWindows } from 'material-ui/svg-icons';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from "@fullcalendar/timegrid";  
import EditroleAction from './editroleAction';  
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class LmPageAccess extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: false,
            editable: true,
        },
        eventAction: null,
        show: false,
        lpcshow: false,
        frameworkComponents : {
          consignmentforceclose:Consignmentforceclose,
          consignmentActions:ConsignmentActions,
          UpdateButton:UpdateButton,
          MarkDeliver : MarkDeliver,
          commentActions : CommentActions,
          EditroleAction:EditroleAction,
        },
        modified_by:"",
        basicTitle:'',
        lpcbasicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        lpcbasicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        selectmenuoptins : [],
        selectlpoptins : [],
        menuitem : "",
        lpitem : "",
        rowId : "",
        partial: "activet",
        complete: "",
        rejected: "",
        revoked: "",
        accesslog:"",
        pageData:"show-m",
        accessData:"show-n",
        dataType:0,
        accessRowData:[],
        //components : {datepicker:getDatePicker()}
    }
    this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this)
  }

componentDidMount(){
    loadDateTimeScript();
    this.onLoadPageData(0);
    redirectURL.post("/lmconsignees/getLmMenus").then((response)=>{
        var menus = response.data;
        console.log(menus,"menus")
        if(menus.length > 0)
        {
            var items = []
            menus.map((item) =>{
                items.push({"value":item.code,"label":item.name})
            })
            this.setState({
                selectmenuoptins : items
            })
        }       
    }).catch((e)=>{
        console.log(e);
    })
    
    redirectURL.post("/lmconsignees/getLmLoadingPorts").then((response)=>{
        var menus = response.data;
        console.log(menus,"menus")
        if(menus.length > 0)
        {
            var items = []
            menus.map((item) =>{
                items.push({"value":item.loading_port_code,"label":item.comp_short_name})
            })
            this.setState({
                selectlpoptins : items
            })
        }       
    }).catch((e)=>{
        console.log(e);
    })
}
onLoadPageData(dataType){
    var query = {
        "userid" : localStorage.getItem("userid"),
        'staff_id' : localStorage.getItem('staff_id'),
        'lm_department' : localStorage.getItem('lm_department'),
        "is_department_admin" : localStorage.getItem("is_department_admin"),
    }
    redirectURL.post("/lmconsignees/getLmUserPageAccess",query).then((response)=>{
        console.log(response.data);
        var rowdata = response.data;
        rowdata = rowdata.filter(function(e){return e.status == dataType})
        this.setState({
            rowData : rowdata,
            dataType : dataType
        })
    }).catch((e)=>{
        console.log(e);
    })    
}
onLoadCompleteAccessLogData(){
    var query = {
        "userid" : localStorage.getItem("userid"),
        "user_email" : localStorage.getItem("email"),
        "staff_id" : localStorage.getItem("staff_id"),
    }
    redirectURL.post("/lmconsignees/getLmUserAccessActivities",query).then((response)=>{
        console.log(response.data);
        var accessRowData = response.data;
        this.setState({
            accessRowData : accessRowData,
        })
    }).catch((e)=>{
        console.log(e);
    })
}
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    this.setState({
        show: false,
        lpcshow: false,
    });
}

lpccloseAlert = () => {
    this.setState({
        show: false,
        lpcshow: false,
    });
    window.location.href="/lmloadingportaccess";
}

onCellClicked(e)
{
    if(e.colDef.field == "approve" || e.colDef.field == "reject" || e.colDef.field == "revoke")
    {
        var rowId = e.data._id;
        var status = e.data.status;
        var screenName = e.data.menu_name;
        var loading_port_code = e.data.loading_port_code;
        var request_id = e.data.request_id;
        if(e.colDef.field == "approve")
        {
            var changeStatus = 1;
            var basicTitle = "Page access approved";
        }
        else if(e.colDef.field == "reject")
        {
            var changeStatus = 2;
            var basicTitle = "Page access rejected";
        }
        else if(e.colDef.field == "revoke")
        {
            var changeStatus = 3;
            var basicTitle = "Page access revoked";
        }
        //console.log(e.data._id);
        var qry = {
            rowId:rowId,
            userid: localStorage.getItem('userid'),
            user_name: localStorage.getItem('username'),
            email: localStorage.getItem('email'),
            user_department: localStorage.getItem('lm_department'),
            staff_id: localStorage.getItem('staff_id'),
            designation: localStorage.getItem('designation'),
            user_role: localStorage.getItem('roles'),
            req_user_name: e.data.username,
            menu_name: e.data.menu_name,
            access: e.data.access,
            status: changeStatus,
            loading_port_code: loading_port_code,
            request_id: request_id,
            approved_screen: screenName
        }
        //var confirm = confirm("Are You Sure! Do you want really delete?");
        
        redirectURL.post("/lmconsignees/approvePageAccess",qry).then((response)=>{
            //console.log(response.data);
            if(response.data.message == "Success")
            {
                this.setState({
                    basicTitle: basicTitle,
                    basicType : "success",
                    show : true
                })                    
            }
            else
            {
                this.setState({
                    basicTitle:"Failed. Try Again",
                    basicType : "warning",
                    show : true
                })
            }
            var dataType = this.state.dataType;
            this.onLoadPageData(dataType);
        }).catch((e)=>{
            console.log(e);
        })
            
    }
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    
}
updateLmStates(params)
{
    //console.log(params);
    if(params.role_name !='' && params.role_name !=undefined)
    {
        params['user_name'] = localStorage.getItem('username');
        params['email'] = localStorage.getItem('email');
        params['user_department'] = localStorage.getItem('lm_department');
        params['staff_id'] = localStorage.getItem('staff_id');
        params['designation'] = localStorage.getItem('designation');
        params['comp_code'] = params.comp_code;
        params['user_role'] = localStorage.getItem('roles');
        redirectURL.post("/lmconsignees/savelmroles",params).then((response)=>{
            console.log(response);
            if(response.data.message == "Success")
            {
                this.setState({
                    basicTitle:"Updated Successfully.",
                    basicType : "success",
                    show : true
                })
            }
            else
            {
                this.setState({
                    basicTitle:"Failed to update.",
                    basicType : "warning",
                    show : true
                })
            }
            var dataType = this.state.dataType;
            this.onLoadPageData(dataType);
        })
    }
    else
    {
        this.setState({
            basicTitle:"Fields Should not be Empty.",
            basicType : "warning",
            show : true
        })
    }
}
addNewRow()
{
    // console.log(this.gridApi);
    this.gridApi.insertItemsAtIndex(0,[{role_name:""}])
    // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}
onClickDataExport(){
    var params = {
        columnKeys: ['role_name']
    };
    this.gridApi.exportDataAsCsv(params);
}
showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate",
        menuitem:"",
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}
onShowEditMenu(params){
    var query = {
        "_id" : params.data._id
    }
    redirectURL.post("/lmconsignees/getLmRolesById",query).then((response)=>{
        var roleInfo = response.data.roleInfo;
        if(roleInfo.length > 0)
        {
            $('#role_name').val(roleInfo[0].role_alias);
        }
        this.setState({
            menuitem : response.data.menus,
            rowId:params.data._id,
            bulkslide:'slider-translate',
            overly:'show-m',
        })
    }).catch((e)=>{
        console.log(e);
    })
}
uploadBulkFormHandler(event){
    event.preventDefault();
    var rowId = this.state.rowId;
    var access = $("#access").val();
    var menus = this.state.menuitem;
    var loading_port_code = this.state.lpitem; 
    console.log(menus,"menus")   
    if(menus !='' && menus !=undefined && loading_port_code !=undefined && loading_port_code !='')
    {
        
        var reqParams = {
            menu_name : menus.label,
            menu_code : menus.value,
            loading_port_code : loading_port_code.value,
            loading_port_name : loading_port_code.label,
            access : access,
            userid : localStorage.getItem("userid"),
        }
        reqParams['user_name'] = localStorage.getItem('username');
        reqParams['email'] = localStorage.getItem('email');
        reqParams['staff_id'] = localStorage.getItem('staff_id');
        reqParams['dept_head_id'] = localStorage.getItem('dept_head_id');
        reqParams['report_manager_id'] = localStorage.getItem('report_manager_id');
        reqParams['user_department'] = localStorage.getItem('lm_department');
        reqParams['designation'] = localStorage.getItem('designation');
        reqParams['comp_code'] = localStorage.getItem('comp_code');
        reqParams['user_role'] = localStorage.getItem('roles');
        reqParams['staff_id'] = localStorage.getItem('staff_id');
        console.log("reqParams",reqParams)
        redirectURL.post("/lmconsignees/saveLmUserPageAccess",reqParams).then((response)=>{
            var resp=response.data.message;
            console.log(resp)
            $('#access').val("");
            document.getElementById("upform").reset();
            if(resp == "Success")
            {
                this.setState({
                    basicTitle : resp,
                    basicType : "success",
                    show : true,
                    bulkslide:'',
                    overly:'show-n',
                    menuitem:"",
                })
            }
            else
            {
                this.setState({
                    basicTitle : resp,
                    basicType : "warning",
                    show : true,
                    menuitem:"",
                })
            }            
            var dataType = this.state.dataType;
            this.onLoadPageData(dataType);
        }).catch((e)=>{
            console.log(e);
        })
    }
    else
    {
        this.setState({
            basicTitle : "Fields Should not be Empty",
            basicType : "warning",
            show : true,
        })
    }
};




onSelectBulkPlants= (location) =>{
    var plants=[];
    try{
        location.map(function(e){
            plants.push(e.value);
        })
        console.log(plants);
        this.setState({
            plant_name : plants
        });
    }
    catch(e)
    {
        console.log(e);
    }
    
}

onChangeMenuItem(menuitem){
    this.setState(
        { menuitem },
        () => console.log(`Menu Option selected:`, this.state.menuitem)
    );    
}
onChangeLpItem(lpitem){
    this.setState(
        { lpitem },
        () => console.log(`LP Option selected:`, this.state.lpitem)
    );
}
onClickTab(pageType){
    if(pageType == "partial")
    {
        var partial = "activet";
        var complete = "";
        var rejected = "";
        var revoked = "";
        var accesslog = "";
        var pageData = "show-m";
        var accessData = "show-n";
        this.onLoadPageData(0);
    }
    else if(pageType == "complete")
    {
        var partial = "";
        var complete = "activet";
        var rejected = "";
        var revoked = "";
        var accesslog = "";
        var pageData = "show-m";
        var accessData = "show-n";
        this.onLoadPageData(1);
    }
    else if(pageType == "rejected")
    {
        var partial = "";
        var complete = "";
        var rejected = "activet";
        var revoked = "";
        var accesslog = "";
        var pageData = "show-m";
        var accessData = "show-n";
        this.onLoadPageData(2);
    }
    else if(pageType == "revoked")
    {
        var partial = "";
        var complete = "";
        var rejected = "";
        var revoked = "activet";
        var accesslog = "";
        var pageData = "show-m";
        var accessData = "show-n";
        this.onLoadPageData(3);
    }
    else if(pageType ="accesslog")
    {
        var partial = "";
        var complete = "";
        var rejected = "";
        var revoked = "";
        var pageData = "show-n";
        var accesslog = "activet";
        var accessData = "show-m";
        this.onLoadCompleteAccessLogData();
    }
    this.setState({
        partial: partial,
        complete: complete,
        rejected: rejected,
        revoked: revoked,
        accesslog: accesslog,
        pageData: pageData,
        accessData: accessData,
    })
}
  render() {
    var hideUser = true;
    var dataType = this.state.dataType;
    var hideApprove = true;
    var hideReject = true;
    var hideRevoke = true;
    var hidecompCode = true;
    if(localStorage.getItem("is_department_admin") == 1)
    {
        var hidecompCode = false;
        var hideUser = false;
        if(dataType == 0)
        {
            var hideReject = false;
            var hideApprove = false;
        }
        else if(dataType == 1)
        {
            var hideRevoke = false;
        }
    }
    var columnwithDefs = [      
        {
            headerName: "User Name",
            field: "username",
            width:160,
            filter: false,
            resizable: true,
            editable:false,
            suppressMenu:true,
            hide:hideUser          
        },
        {
            headerName: "Email",
            field: "email",
            width:180,
            filter: false,
            resizable: true,
            editable:false,
            suppressMenu:true,
            hide: hideUser          
        },
        {
            headerName: "Screen Name",
            field: "menu_name",
            width:180,
            filter: false,
            resizable: true,
            suppressMenu:true,
            editable:false,            
        },
        {
            headerName: "Loading Port",
            field: "loading_port_name",
            width:100,
            filter: false,
            resizable: true,
            suppressMenu:true,
            editable:false,            
        },
        {
            headerName: "Access Type",
            field: "access",
            width:100,
            filter: false,
            resizable: true,
            suppressMenu:true,
            editable:false,
            valueGetter : function(params){
                if(params.data.access == 1)
                {
                    return "Add / Modify";
                }
                else if(params.data.access == 2)
                {
                    return "View";
                }
                else
                {
                    return "Full Access";
                }
            }
        },
        {
            headerName: "Requested On",
            field: "request_date",
            width:120,
            filter: false,
            resizable: true,
            editable:false,
            suppressMenu:true,
            valueGetter:function(params){
                if(params.data.request_date != ''){
                    return getHyphenDDMMMYYYYHHMM(params.data.request_date);
                }
                else{
                    return "";
                }
            },
        },
        {
            headerName: "Status",
            field: "status",
            width:120,
            filter: false,
            resizable: true,
            editable:false,
            suppressMenu:true,
            valueGetter : function(params){
                if(params.data.status == 0)
                {
                    return "Requested";
                }
                else if(params.data.status == 1)
                {
                    return "Approved";
                }
                else if(params.data.status == 2)
                {
                    return "Rejected";
                }
                else if(params.data.status == 3)
                {
                    return "Revoked";
                }
            }
        },
        {
            pinned: "left",
            headerName: "Action",
            field: "approve",
            width:140,
            filter: false,
            resizable: true,
            editable:false,
            cellClass : "cellRvrt",
            hide: hideApprove,
            suppressMenu:true,
            valueGetter : function(params){
                if(params.data.status == 0)
                {
                    return "Approve";
                }
                else
                {
                    return "N/A";
                }
            }
        },
        {
            pinned: "left",
            headerName: "Action",
            field: "reject",
            width:140,
            filter: false,
            resizable: true,
            editable:false,
            cellClass : "cellRvrt",
            hide: hideReject,
            suppressMenu:true,
            valueGetter : function(params){
                if(params.data.status == 0)
                {
                    return "Reject";
                }
            }
        },
        {
            pinned: "left",
            headerName: "Action",
            field: "revoke",
            width:140,
            filter: false,
            resizable: true,
            editable:false,
            cellClass : "cellRvrt",
            hide: hideRevoke,
            suppressMenu:true,
            valueGetter : function(params){
                if(params.data.status == 1)
                {
                    return "Revoke";
                }
            }
        }
    ]
    
    var columnwithAccessDefs = [
        {
            headerName: "Request Id",
            field: "request_id",
            width:100,
            filter: false,
            resizable: true,
            editable: false,  
            suppressMenu:true,         
        },
        {
            headerName: "Requested By",
            field: "requested_user",
            width:200,
            filter: false,
            resizable: true,
            editable: false, 
            suppressMenu:true,          
        },
        {
            headerName: "Requested Date",
            field: "requested_user",
            width:150,
            filter: false,
            resizable: true,
            editable: false,
            suppressMenu:true,
            valueGetter:function(params){
                if(params.data.requested_on != ''){
                    return getHyphenDDMMMYYYYHHMM(params.data.requested_on);
                }
                else{
                    return "";
                }
            },         
        },
        {
            headerName: "Company Code",
            field: "comp_code",
            width:100,
            filter: false,
            resizable: true,
            editable:false,
            suppressMenu:true,
            //hide:hidecompCode        
        },
        {
            headerName: "Screen",
            field: "approved_screen",
            width:150,
            filter: false,
            resizable: true,
            editable: false, 
            suppressMenu:true,          
        },
        {
            headerName: "Status",
            field: "access_status",
            width:150,
            filter: false,
            resizable: true,
            editable: false,  
            suppressMenu:true,         
        },
        // {
        //     headerName: "Activity",
        //     field: "activity",
        //     width:350,
        //     filter: false,
        //     resizable: true,
        //     editable: false,           
        // },
        {
            headerName: "Activity Date",
            field: "activity_date",
            width:150,
            filter: false,
            resizable: true,
            editable: false,
            suppressMenu:true,
            valueGetter:function(params){
                if(params.data.activity_date != ''){
                    return getHyphenDDMMMYYYYHHMM(params.data.activity_date);
                }
                else{
                    return "";
                }
            },   
        },
    ];

    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <SweetAlert
            show={this.state.lpcshow}
            type={this.state.lpcbasicType}
            title={this.state.lpcbasicTitle}
            onConfirm={this.lpccloseAlert}
        >
        </SweetAlert>

        <div className="card col-xl-12">
            <div className="card-header">
                
                {(localStorage.getItem("is_department_admin") == 1) ? 
                    <h5>
                        <i className="icofont icofont-page cus-i"></i> <span>Page Access Requests</span>
                    </h5> : 
                    <h5>
                        <i className="icofont icofont-page cus-i"></i> <span>Request For Page Access</span>
                        <button className="btn btn-success" style={{float:"right"}} onClick={this.showBulkUpload.bind(this)}>Request Page Access</button>
                    </h5>
                }
            </div>
            <div className="card-body col-xl-12 col-lg-12">
                <div className="f16" style={{marginBottom:"10px"}}>
                    <ul className="d-tabs">

                        <li onClick={this.onClickTab.bind(this,"partial")} className={"lttabs "+(this.state.partial)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"partial")}>Pending</button>
                        </li>

                        <li onClick={this.onClickTab.bind(this,"complete")} className={"lttabs "+(this.state.complete)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"complete")}>Approved</button>
                        </li>

                        <li onClick={this.onClickTab.bind(this,"rejected")} className={"lttabs "+(this.state.rejected)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"rejected")}>Rejected</button>
                        </li>

                        <li onClick={this.onClickTab.bind(this,"revoked")} className={"lttabs "+(this.state.revoked)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"revoked")}>Revoked</button>
                        </li>
                        {(localStorage.getItem("is_department_admin") == 1) ? 
                        <li onClick={this.onClickTab.bind(this,"accesslog")} className={"lttabs "+(this.state.accesslog)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"accesslog")}>Complete Access Log</button>
                        </li>
                        : ""}
                    </ul>
                </div>
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.pageData}>    
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={true}
                    sideBar={false}
                    suppressMenu={true}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClicked}
                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                    />
                </div>
                <div id="myGrid1" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.accessData}>    
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithAccessDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.accessRowData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={true}
                    sideBar={false}
                    suppressMenu={true}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                    />
                </div>
            </div>
        </div>

        
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">Request For Page Access</h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>
                    
                        <div className="form-group">
                            <label className="">Screens</label>
                            <Select 
                            placeholder={"Select Screen"}
                            closeMenuOnSelect={true}
                            onChange={this.onChangeMenuItem.bind(this)} 
                            className={"border-radius-0"}
                            style={{borderRadius:"0px"}}
                            value={this.state.menuitem}
                            options={this.state.selectmenuoptins} />  
                        </div>

                        <div className="form-group">
                            <label className="">Loading Port</label>
                            <Select 
                            placeholder={"Select Loading Port"}
                            closeMenuOnSelect={true}
                            onChange={this.onChangeLpItem.bind(this)} 
                            className={"border-radius-0"}
                            style={{borderRadius:"0px"}}
                            value={this.state.lpitem}
                            options={this.state.selectlpoptins} />  
                        </div>

                        <div className="form-group mt-20p">
                            <label>Request Access</label>
                            <select name="access" id="access" className="form-control" required>
                                <option value="">Access Type</option>
                                <option value="1">Add/Edit</option>
                                <option value="2">View</option>
                                <option value="3">Full Access</option>
                            </select>
                        </div>

                    <div className="form-group">
                        <button type="submit" className="btn btn-success">Submit</button>
                        <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                    </div>
                    </form>                   
                </div>
            </div>
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules, AllEnterpriseModules } from '@ag-grid-enterprise/all-modules';
// import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import CSVFileValidator from 'csv-file-validator';
import Map from '../common/drawmap';
import FileBase64 from 'react-file-base64';
import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import MapComponent from './mapLocationComponent';
import Consignmentforceclose from '../manage/consignmentforceclose';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import fastForward from 'material-ui/svg-icons/av/fast-forward';
import m from 'highcharts-drilldown';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var childJson = []

export default class CustomsClearenceForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            csvcontent: "",
            file: "",
            loadshow: 'show-n',
            overly: 'show-n',
            uploadYard: "",
            uploadDispatchDate: "",
            rowData: "",
            rowSelection: 'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize: 50,
            geofencelist: [],
            geoFenceData: "",
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            dept_code: "",
            bulkslide: "",
            date: "",
            deptName: "",
            indenterName: "",
            mobile: "",
            indenterEmail: "",
            erpPO: "",
            erpPoNo: [],
            erpPoDate: [],
            ringiNo: "",
            ringiDesc: "",
            cargoDeliveryPlant: "",
            itemDesc: "",
            grossWt: "",
            dimensions: "",
            cargo_chargeable_weight: "",
            supplierName: "",
            supplierAddr: "",
            supplierCnctPerson: "",
            country: "",
            emailID: "",
            phone: "",
            deliveryTerms: "Ex Works",
            pickUpAddr: "",
            cargoDate: "",
            LC: "",
            LCno: "",
            nature: "",
            freeTrade: "",
            benefitCert: "",
            disableERP: "disabled",
            disableLC: "disabled",
            deliveryLabel: "Pick Up Address",
            airportName: "",
            port: "",
            erpPo_date: "",
            cargo_date: "",
            container_type: "",
            container_size: [],
            dispatch_location: "",
            dispatch_port: "",
            destination_port: "",
            other_destination_port: "",
            container_required: "",
            incoterms: "",
            certificateOfOrigin: "",
            fta: "",
            exportIncentive: "",
            payment_terms: "",
            file1: "",
            file2: "",
            file3: "",
            file4: "",
            rows: [{}],
            ringirows: [{}],
            packetrows: [{}],
            brequest: "activet",
            prepaid: "",
            track: "",
            requestData: "show-m",
            trackData: "show-n",
            trackResData: 'show-n',
            ffsuppliers: [],
            requestFields: "show-m",
            prepaidFields: "show-n",
            addresstxt: "",
            request_type: 1,
            pagetitle: "OneTime Container Booking",
            disableBng: "disabled",
            lspallocationdetails: [],
            showUpopts: "",
            showUpload: "show-n",
            field1:"",
            field2:"",
            field3:"",
            field4:"",
            field5:"",
            importValue1:"",
            importValue2:"",
            importDuty1:"",
            importDuty2:"",
            lpc1:"",
            lpc2:"",
            lastRunTime:""
            //components : {datepicker:getDatePicker()}
        }
        this.packetsHandler = this.packetsHandler.bind(this);
    }

    onChangeFiled=(event)=>{
        
        this.setState({field1:event.target.value})
    }

    onChangeQuery=(event)=>{
        this.setState({field2:event.target.value})
    }

    onChangeAssessment=(event)=>{
        this.setState({field3:event.target.value})
    }
    onChangeCharge=(event)=>{
        this.setState({field4:event.target.value})
    }
    onChangePayment=(event)=>{
        this.setState({field5:event.target.value})
    }

    onChangeImportValue1=(event)=>{
        this.setState({importValue1:event.target.value})
    }
    onChangeImportValue2=(event)=>{
        this.setState({importValue2:event.target.value})
    }

    onChangeImportDuty1=(event)=>{
        this.setState({importDuty1:event.target.value})
    }

    onChangeImportDuty2=(event)=>{
        this.setState({importDuty2:event.target.value})
    }

    onChangeLpc1=(event)=>{
        this.setState({lpc1:event.target.value})
    }

    onChangeLpc2=(event)=>{
        this.setState({lpc2:event.target.value})
    }


    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                var eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                var eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                var eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount() {
        // this.logPageView(); // Log page view to GA
        // loadDateTimeScript();
        // var pagetitle = "One Time Container Booking";
        // $(".requestFields").attr("required", true);
        // this.setState({
        //     pagetitle: pagetitle,
        // })
        // var d = moment.parseZone(new Date()).utcOffset("+05:30")._d;
        // var date = moment.parseZone(d).format("DD-MM-YYYY");
        // $("#dateField").val(date);
        // redirectURL.post("/consignments/fetchLspDetailsData")
        //     .then((response) => {
        //         var records = response.data.data
        //         if (records.length > 0) {
        //             this.setState({
        //                 lspallocationdetails: records,
        //             })
        //         }
        //     })

            redirectURL.post("/consignments/getledscreendata")
            .then( async (response) => {
                console.log("185", response)
                var resp = response.data
                var lastRunTime = moment.parseZone(resp[0].last_updated_on).format("YYYY-MM-DD HH:mm:ss")
                await this.setState({
                    field1:resp[0].filed_for_current_fy,
            field2:resp[0].pending_for_query,
            field3:resp[0].pending_for_assessment,
            field4:resp[0].pending_for_out_of_charge,
            field5:resp[0].pending_for_duty_payment,
            importValue1:resp[0].import_value_for_current_fy,
            importValue2:resp[0].import_value_for_current_month,
            importDuty1:resp[0].import_duty_for_current_fy,
            importDuty2:resp[0].import_duty_for_current_month,
            lpc1:resp[0].lpc1,
            lpc2:resp[0].lpc2,
                  lastRunTime:lastRunTime
                });
                
            })
    }

    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };


    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            bulkslide: ''
        });

    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    containertypechangeHandler = (e) => {
        if (e.value.includes("Non") == false) {
            $("#DGD_file").attr("required", true);
            $("#MSDS_file").attr("required", true);
            $("#IIP_file").attr("required", true);
            var showUpopts = "";
        }
        else {
            $("#DGD_file").removeAttr("required", true);
            $("#MSDS_file").removeAttr("required", true);
            $("#IIP_file").removeAttr("required", true);
            var showUpopts = "show-n";
        }
        this.setState({
            container_type: e,
            showUpopts: showUpopts
        })
    }
    containersizechangeHandler = (e) => {
        if (e == null) {
            e = [];
        }
        this.setState({
            container_size: e,
        })
    }
    dispatchlocationchangeHandler = (e) => {
        this.setState({
            dispatch_location: e,
        })
    }
    dispatchportchangeHandler = (e) => {
        this.setState({
            dispatch_port: e,
        })
    }
    destinationportchangeHandler = (e) => {
        this.setState({
            destination_port: e,
        })
    }
    containerrequiredchangeHandler = (e) => {
        this.setState({
            container_required: e,
        })
    }
    incotermschangeHandler = (e) => {
        this.setState({
            incoterms: e,
        })
    }
    certificateOfOriginchangeHandler = (e) => {
        this.setState({
            certificateOfOrigin: e,
        })
    }
    ftachangeHandler = (e) => {
        this.setState({
            fta: e,
        })
    }
    exportIncentivechangeHandler = (e) => {
        this.setState({
            exportIncentive: e,
        })
    }
    paymentchangeHandler = (e) => {
        if (e.value.includes("Free of Charge") == false) {
            var showUpload = "show-n"
            $("#GRW_file").removeAttr("required", true);

        } else {
            var showUpload = "show-m"
            $("#GRW_file").attr("required", true);
        }
        this.setState({
            payment_terms: e,
            showUpload: showUpload
        })
    }
    changeHandler1 = (event) => {
        console.log("Select", event.target.value);
        let name = event.target.name;
        console.log(name);
        let value = event.target.value;
        this.setState({ [name]: value });
        if (name == "erpPO") {
            if (value == "yes") {
                $("#addMrERp").show();
                this.setState({
                    disableERP: ""
                })
            } else {
                $("#addMrERp").hide();
                this.setState({
                    disableERP: "disabled"
                })
            }
        }
        if (name == "LC") {
            if (value == "yes") {
                this.setState({
                    disableLC: ""
                })
                //$("#LCno").prop("required",true);
            } else {
                this.setState({
                    disableLC: "disabled"
                })
                //$("#LCno").removeAttr("required",true);
            }
        }
        if (name == "cargoDeliveryPlant") {
            if (value == "B") {
                $("#invoice_type").val(null);
                $("#order_type").val(null);
                $("#invoice_type").prop("required", true);
                $("#order_type").prop("required", true);
                this.setState({
                    disableBng: ""
                })
            } else {
                $("#invoice_type").removeProp("required");
                $("#order_type").removeProp("required");
                this.setState({
                    disableBng: "disabled"
                })
            }
        }
        // if(name == "deliveryTerms")
        // {
        //     $(".dterms").removeAttr("required",true);
        //     if(value == "Ex Works")
        //     {
        //         $("#pickUpAddr").prop("required",true);
        //     }
        //     else if(value == "FCA")
        //     {
        //         $("#port").prop("required",true);
        //     }
        //     else if(value == "FOB")
        //     {
        //         $("#airportName").prop("required",true);
        //     }
        // }
    }
    changeHandler = (event) => {
        console.log("Select", event.target.value);

        var name = event.target.name;
        var value = event.target.value;
        const re = /^[A-Za-z]+$/;
        // if (name == 'deptName') {
        //     if (value === "" || re.test(value)) {
        //         console.log("name succ")
        //         alert('hi')
        //         //   this.setState(value);
        //     }else{
        //         alert('dont enter the numerals')
        //     }
        // }

        console.log(name);
    }
    dateValidation = (currentDate) => {
        return currentDate.isBefore(moment(this.state.deliverymaxDate)) && currentDate.isAfter(moment(this.state.deliveryminDate));
    };
    changeFileHandler = async (e) => {
        console.log(e.target.name)
        this.setState({ [e.target.name]: e.target.files[0] });
    }
    changeFileHandler1 = (e) => {
        this.setState({
            file1: e.target.files[0]
        });
    }
    changeFileHandler2 = (e) => {
        this.setState({
            file2: e.target.files[0]
        });
    }
    changeFileHandler3 = (e) => {
        this.setState({
            file3: e.target.files[0]
        });
    }
    changeFileHandler4 = (e) => {
        this.setState({
            file4: e.target.files[0]
        });
    }
    async formHandler(event) {
        event.preventDefault();
        this.setState({
            loadshow : "show-m",
            overly : "show-m"
        })
        
              const qry={
                field1:this.state.field1,
            field2:this.state.field2,
            field3:this.state.field3,
            field4:this.state.field4,
            field5:this.state.field5,
            importValue1:this.state.importValue1,
            importValue2:this.state.importValue2,
            importDuty1:this.state.importDuty1,
            importDuty2:this.state.importDuty2,
            lpc1:this.state.lpc1,
            lpc2:this.state.lpc2
}

              console.log(qry,"792")
              redirectURL.post("/consignments/setledscreendata",qry).then((response)=>{
                console.log(response)
                if(response.data.message == "Success"){
                    this.setState({
                        loadshow : "show-n",
                        overly : "show-n"
                    })
                    window.location.href="/ledrailwaylogisticsscreen4"
                }
              })
              
           
    }

    handleAddRow = () => {
        const item = {
            erpPoNo: "",
            erpPoDate: "",
        };
        this.setState({
            rows: [...this.state.rows, item]
        });
    };
    handleRemoveRow = (idx) => {
        this.state.rows.splice(idx, 1);
        this.setState({ rows: this.state.rows });
    };

    handleAddRingiRow = () => {
        const item = {
            ringi_file: "",
            ringiDesc: "",
        };
        this.setState({
            ringirows: [...this.state.ringirows, item]
        });
    };
    handleRemoveRingiRow = (idx) => {
        this.state.ringirows.splice(idx, 1);
        this.setState({ ringirows: this.state.ringirows });
    };

    packetsHandler = (e) => {
        var noOfPackets = e.target.value;
        if (parseInt(noOfPackets) >= 1) {
            var lespcks = parseInt(noOfPackets) - 1;
            var bhanu = [{}];
            for (var n = 0; n < lespcks; n++) {
                const item = {
                    itemDesc: "",
                    grossWt: "",
                    dimensions: "",
                    cargo_chargeable_weight: "",
                };
                this.setState({
                    packetrows: [...bhanu, item]
                });
                var bhanu = [...bhanu, item];
            }
        }
        else {
            alert("No of packets should be greater than 0");
            $("#no_of_packets").val(1);
            const item = {
                itemDesc: "",
                grossWt: "",
                dimensions: "",
                cargo_chargeable_weight: "",
            };
            this.setState({
                packetrows: [item]
            });
        }
    }

    onClickTab(pageType) {
        if (pageType == "brequest") {
            var brequest = "activet";
            var ffupdates = "";
            var track = "";
            var requestData = "show-m";
            var trackData = "show-n";
            var requestFields = "show-m";
            var prepaidFields = "show-n";
            var addresstxt = "";
            var request_type = 1;
            var pagetitle = "Booking Request (Air Imports)";
            $(".requestFields").attr("required", true);
            $(".prepaidFields").removeAttr("required", true);
        }
        else if (pageType == "prepaid") {
            var brequest = "";
            var prepaid = "activet";
            var track = "";
            var requestData = "show-m";
            var trackData = "show-n";
            var requestFields = "show-n";
            var prepaidFields = "show-m";
            var addresstxt = "(To be mentioned on BOE)";
            var request_type = 2;
            var pagetitle = "Booking Request For Prepaid Shipments (Air Imports)";
            $(".requestFields").removeAttr("required", true);
            $(".prepaidFields").attr("required", true);
        }
        else if (pageType == "track") {
            var brequest = "";
            var prepaid = "";
            var track = "activet";
            var requestData = "show-n";
            var trackData = "show-m";
            var requestFields = "show-n";
            var prepaidFields = "show-n";
            var addresstxt = "";
            var request_type = "";
            var pagetitle = "";
        }
        this.setState({
            brequest: brequest,
            prepaid: prepaid,
            track: track,
            requestData: requestData,
            trackData: trackData,
            requestFields: requestFields,
            prepaidFields: prepaidFields,
            addresstxt: addresstxt,
            request_type: request_type,
            pagetitle: pagetitle
        })
    }

    trackHandler(event) {
        event.preventDefault();
        var indenter_email = $("#indenter_email").val();
        if (indenter_email != undefined && indenter_email != '') {
            this.setState({
                loadshow: 'show-m',
                overly: 'show-m',
            })
            var params = {
                indenter_email: indenter_email
            }
            redirectURL.post("/consignments/getAirCargosStatusByIntender", params)
                .then((response) => {
                    var records = response.data;
                    this.setState({
                        rowData: records,
                        trackResData: 'show-m',
                        loadshow: 'show-n',
                        overly: 'show-n',
                    })
                })
                .catch(function (e) {
                    console.log("Error ", e)
                })
        }
        else {
            this.setState({
                basicTitle: "Please enter Indenter Email",
                basicType: "warning",
                show: true
            })
        }
    }

    render() {
        var columnwithDefs = [
            {
                headerName: "Job No",
                field: "job_no",
                width: 130,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Date",
                field: "date",
                width: 130,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.date != undefined && params.data.date != '') {
                        return moment.parseZone(params.data.date).format("DD-MM-YYYY")
                    }
                }
            },
            {
                headerName: "Department Name",
                field: "dept_name",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Indenter Name",
                field: "indenter_name",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Mobile No",
                field: "mobile",
                width: 100,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Indenter Email",
                field: "indenter_email",
                width: 200,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Cargo Delivery Plant",
                field: "cargo_delivery_plant",
                width: 200,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Supplier Name",
                field: "supplier_name",
                width: 200,
                filter: true,
                resizable: true,
            },
            {
                headerName: "ETA IGI Airport Date",
                field: "eta_igi_airport_date",
                width: 150,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.eta_igi_airport_date != undefined && params.data.eta_igi_airport_date != "") {
                        return moment.parseZone(params.data.eta_igi_airport_date).format("DD-MM-YYYY")
                    }
                    else {
                        return "N/A";
                    }
                }
            },
            {
                headerName: "ATA IGI Airport Date",
                field: "ata_igi_airport_date",
                width: 150,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.ata_igi_airport_date != undefined && params.data.ata_igi_airport_date != "") {
                        return moment.parseZone(params.data.ata_igi_airport_date).format("DD-MM-YYYY")
                    }
                    else {
                        return "N/A";
                    }
                }
            },
            {
                headerName: "OOC Date",
                field: "ooc_date",
                width: 150,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.ooc_date != undefined && params.data.ooc_date != "") {
                        return moment.parseZone(params.data.ooc_date).format("DD-MM-YYYY")
                    }
                    else {
                        return "N/A";
                    }
                }
            },
            {
                headerName: "Status",
                field: "status_text",
                width: 250,
                pinned: "right",
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.rejected == 1) {
                        return "Rejected";
                    }
                    else if (params.data.actual_delivery_at_msil != undefined && params.data.actual_delivery_at_msil != "") {
                        return "Delivered To MSIL";
                    }
                    else if (params.data.ooc_date != undefined && params.data.ooc_date != "") {
                        return "Custom Cleared";
                    }
                    else if (params.data.ata_igi_airport_date != undefined && params.data.ata_igi_airport_date != "") {
                        return "Flight Arrived At Destination Airport";
                    }
                    else if (params.data.eta_igi_airport_date != undefined && params.data.eta_igi_airport_date != "") {
                        return "Flight Booked";
                    }
                    else if (params.data.ff_code == undefined || params.data.ff_code == '') {
                        return "Pending At Imports";
                    }
                    else {
                        return "Pending From Freight Forwarder";
                    }
                }
            }
        ]
        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    {/* <div className="col-xl-12 col-lg-12">
                        <div className="f16" style={{marginBottom:"10px"}}>
                            <ul className="d-tabs">
                                
                                <li onClick={this.onClickTab.bind(this,"brequest")} className={"lttabs "+(this.state.brequest)}>
                                    <button type="button" className="btn">Air Imports</button>
                                </li>

                                <li onClick={this.onClickTab.bind(this,"prepaid")} className={"lttabs "+(this.state.prepaid)}>
                                    <button type="button" className="btn">Air Prepaid Shipments</button>
                                </li>

                                <li onClick={this.onClickTab.bind(this,"track")} className={"lttabs "+(this.state.track)}>
                                    <button type="button" className="btn">Booking Status</button>
                                </li>
                            </ul>
                        </div>
                    </div> */}
                    <div className={"col-xl-12 col-lg-12 " + this.state.requestData}>
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <span>Custom Clearance</span>
                                    <span style={{position:"relative",right:"-35%",fontSize:"15px",color:"#171C8F"}}>
                        Last updated on : {this.state.lastRunTime}
                    </span>
                                    <span>
                                        {/* <span className="float-right f12">
                                            <a href="/containerbookingsystem/onetime" className="btn btn-primary">Back</a>
                                        </span> */}
                                    </span>
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                            <form class="aircargo-form" onSubmit={this.formHandler.bind(this)} encType="multipart/form-data">
                                    <div className="row col-xl-12">
                                        
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Filed for current FY* : </label>
                                            <input autoComplete="off" type="number" value={this.state.field1} className="form-control" onChange={this.onChangeFiled} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Pending under Query* : </label>
                                            <input autoComplete="off" type="number" value={this.state.field2} className="form-control" onChange={this.onChangeQuery} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Pending for Assessment* : </label>
                                            <input autoComplete="off" type="number" value={this.state.field3} className="form-control" onChange={this.onChangeAssessment}/>
                                        </div>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Pending for Out of Charge*: </label>
                                            <input autoComplete="off" type="number" value={this.state.field4}className="form-control"
                                             onChange={this.onChangeCharge}/>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Pending for Duty Payment* : </label>
                                            <input autoComplete="off" type="number" value={this.state.field5} className="form-control" onChange={this.onChangePayment}  />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Import Value For Current FY*: </label>
                                            <input autoComplete="off" type="number" name="mobile" id="mobile" className="form-control" value={this.state.importValue1}
                                             onChange={this.onChangeImportValue1} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Import Value For Current Month* : </label>
                                            <input autoComplete="off" type="number" value={this.state.importValue2} className="form-control" onChange={this.onChangeImportValue2} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Import Duty For Current FY*: </label>
                                            <input autoComplete="off" type="number" value={this.state.importDuty1}  className="form-control"
                                             onChange={this.onChangeImportDuty1} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Import Duty For Current Month*: </label>
                                            <input autoComplete="off" type="number" value={this.state.importDuty2} className="form-control" onChange={this.onChangeImportDuty2}/>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">LPC and Interest For Current FY*: </label>
                                            <input autoComplete="off" type="number"  className="form-control" value={this.state.lpc1}
                                             onChange={this.onChangeLpc1} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">LPC and Interest For Current Month*: </label>
                                            <input autoComplete="off" type="number" className="form-control" value={this.state.lpc2}
                                             onChange={this.onChangeLpc2} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-success" style={{ marginLeft: "45%", width: "192px", marginTop: "20px" }}>Submit</button>
                                    </div>
                                    </form>

                                {/* <form class="aircargo-form" onSubmit={this.formHandler.bind(this)} encType="multipart/form-data">
                                    <div className="row col-xl-12">
                                        
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Filed for current FY* : </label>
                                            <input type="text" name="deptName" id="deptName" className="form-control dept-cls" onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Pending under Query* : </label>
                                            <input type="text" name="indenterName" id="indenterName" className="form-control alfa-cls" onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Pending for Assessment* : </label>
                                            <input type="text" name="indenterName" id="indenterName" className="form-control alfa-cls" onChange={this.changeHandler} required />
                                        </div>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Pending for Out of Charge*: </label>
                                            <input type="number" name="mobile" id="mobile" className="form-control mobnum-cls"
                                             onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Pending for Duty Payment* : </label>
                                            <input type="text" name="indenterEmail" id="indenterEmail" className="form-control" onChange={this.changeHandler} required />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Pending for Out of Charge*: </label>
                                            <input type="number" name="mobile" id="mobile" className="form-control mobnum-cls"
                                             onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Pending for Duty Payment* : </label>
                                            <input type="text" name="indenterEmail" id="indenterEmail" className="form-control" onChange={this.changeHandler} required />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Pending for Out of Charge*: </label>
                                            <input type="number" name="mobile" id="mobile" className="form-control mobnum-cls"
                                             onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Pending for Duty Payment* : </label>
                                            <input type="text" name="indenterEmail" id="indenterEmail" className="form-control" onChange={this.changeHandler} required />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Pending for Out of Charge*: </label>
                                            <input type="number" name="mobile" id="mobile" className="form-control mobnum-cls"
                                             onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Pending for Duty Payment* : </label>
                                            <input type="text" name="indenterEmail" id="indenterEmail" className="form-control" onChange={this.changeHandler} required />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    {this.state.ringirows.map((item, idx) => (
                                        <div className={"row col-xl-12 "} key={idx}>
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12">Ringi No (File)* : </label>
                                                <input type="file" name={"ringi_file_" + idx} id={"ringi_file_" + idx} className="form-control requestFields" onChange={this.changeFileHandler} required />
                                                <FileBase64
                                            onDone={ this.changeFileHandler.bind(this) } />
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12">Ringi No* : </label>
                                                <input type="text" name={"ringiDesc_" + idx} id={"ringiDesc_" + idx} className="form-control ringi-cls" onChange={this.changeHandler1} required />
                                            </div>
                                            <div className="col-xl-4" style={{ display: (idx != 0) ? 'block' : 'none' }}>
                                                <h6 style={{ marginTop: "2.5em" }} onClick={() => this.handleRemoveRingiRow(idx)}>Clear</h6>
                                            </div>
                                        </div>
                                    ))}
                                    <input type="hidden" id="rngLength" value={this.state.ringirows.length} />
                                    <div class={"row col-sm-12 "}>
                                        <div className="row col-xl-12 m-top-1">
                                            <div class="col-xl-12">
                                                <button class="btn btn-primary" type="button" onClick={this.handleAddRingiRow}>Add More Fields +</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class={"hr-dashed "}></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Import Value For Current FY* : </label>
                                            <input type="text" name="consigneeName" id="consigneeName" className="form-control alfa-cls" onChange={this.changeHandler} required />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Import Value For Current Month* : </label>
                                            <Select
                                                placeholder={"Select Container Type"}
                                                closeMenuOnSelect={true}
                                                value={this.state.container_type}
                                                onChange={this.containertypechangeHandler.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={[{ label: "Haz Dry", value: "Haz Dry" },
                                                { label: "Haz High Cube", value: "Haz High Cube" }, { label: "Non Haz Dry", value: "Non Haz Dry" }, { label: "Non Haz High Cube", value: "Non Haz High Cube" }]} required
                                            />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Container Size* : </label>
                                            <Select
                                                placeholder={"Select container Size"}
                                                closeMenuOnSelect={true}
                                                value={this.state.container_size}

                                                isMulti={true}
                                                onChange={this.containersizechangeHandler.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={[{ label: "20 Ft", value: "20" },
                                                { label: "40 Ft", value: "40" }]} required
                                            />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Dispatch Location* : </label>
                                            <Select
                                                placeholder={"Select Dispatch Location"}
                                                closeMenuOnSelect={true}
                                                value={this.state.dispatch_location}

                                                onChange={this.dispatchlocationchangeHandler.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={[{ label: "Gurgaon", value: "Gurgaon" },
                                                { label: "Manesar", value: "Manesar" }, { label: "KDO Gujarat", value: "KDO Gujarat" }, { label: "SND Gujarat", value: "SND Gujarat" }, { label: "RPDC Bengaluru", value: "RPDC Bengaluru" }, { label: "MSIL Bidadi", value: "MSIL Bidadi" }]} required
                                            />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Dispatch Port* : </label>
                                            <Select
                                                placeholder={"Select Dispatch Port"}
                                                closeMenuOnSelect={true}
                                                value={this.state.dispatch_port}

                                                onChange={this.dispatchportchangeHandler.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={[{ label: "Pipavav", value: "Pipavav" },
                                                { label: "Mundra", value: "Mundra" }, { label: "Nhava Sheva", value: "Nhava Sheva" }, { label: "Chennai", value: "Chennai" }, { label: "Kattupali", value: "Kattupali" }, { label: "ICD Garhi", value: "ICD Garhi" }]} required
                                            />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Destination Port* : </label>
                                            <Select
                                                placeholder={"Select Destination Port"}
                                                closeMenuOnSelect={true}
                                                value={this.state.destination_port}

                                                onChange={this.destinationportchangeHandler.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={[{ label: "Jakarta", value: "Jakarta" },
                                                { label: "Laem Chabang", value: "Laem Chabang" }, { label: "Thilawa", value: "Thilawa" }, { label: "Yangon", value: "Yangon" }, { label: "Nagoya", value: "Nagoya" }, { label: "Shimizu", value: "Shimizu" }, { label: "Omaezaki", value: "Omaezaki" }, { label: "Apapa", value: "Apapa" }, { label: "Tema", value: "Tema" }, { label: "Other", value: "Other" }]} required
                                            />
                                        </div>
                                        {(this.state.destination_port.value == "Other") ?
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12">Other Destination Port* : </label>
                                                <input type="text" name="other_destination_port" id="other_destination_port" className="form-control alfa-cls" onChange={this.changeHandler} required />
                                            </div> : ""
                                        }

                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
{/* 
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12"> Dispatch Date* : </label>
                                            <input type="text" name="dispatch_date" id="dispatch_dateField" className="datetimepicker_date form-control" readOnly />
                                        </div> */}
                                        {/* <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12"> MRD at Destination* : </label>
                                            <input type="text" name="mrd_date" id="mrd_dateField" className="datetimepicker_date form-control" readOnly />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12"> Container Requirement Date* : </label>
                                            <input type="text" name="container_date" id="container_dateField" className="datetimepicker_date form-control" readOnly />
                                        </div>
                                        {this.state.container_size.map((item, idx) => (
                                            <div className="col-xl-4" key={idx}>
                                                <label className="col-xl-12 col-lg-12"> No. of Container required For  {item.label}*: </label>
                                                <input type="text" name={"container_required_" + idx} id={"container_required_" + idx} className="form-control num-cls" onChange={this.changeHandler} required />
                                            </div>
                                        ))} */}
                                    {/* </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12 col-lg-12">

                                        <div className="col-xl-4 col-lg-4">
                                            <label className="col-xl-12 col-lg-12">DGD if Haz Container required<span className={this.state.showUpopts}>*</span> : </label>
                                            <input type="file" name="DGD_file" id="DGD_file" className="form-control requestFields" onChange={this.changeFileHandler1} />
                                            <FileBase64
                                            onDone={ this.changeFileHandler.bind(this) } />
                                        </div>
                                        <div className="col-xl-4 col-lg-4">
                                            <label className="col-xl-12 col-lg-12">MSDS of the Cargo<span className={this.state.showUpopts}>*</span> : </label>
                                            <input type="file" name="MSDS_file" id="MSDS_file" className="form-control requestFields" onChange={this.changeFileHandler2} />
                                            <FileBase64
                                            onDone={ this.changeFileHandler.bind(this) } />
                                        </div>
                                        <div className="col-xl-4 col-lg-4">
                                            <label className="col-xl-12 col-lg-12">IIP certificate of the Cargo<span className={this.state.showUpopts}>*</span> : </label>
                                            <input type="file" name="IIP_file" id="IIP_file" className="form-control requestFields" onChange={this.changeFileHandler3} />
                                            <FileBase64
                                            onDone={ this.changeFileHandler.bind(this) } />
                                        </div>
                                    </div>

                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12"> Incoterms to be Used* : </label>
                                            <Select
                                                placeholder={"Select Incoterms"}
                                                closeMenuOnSelect={true}
                                                value={this.state.incoterms}

                                                onChange={this.incotermschangeHandler.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={[{ label: "FOB", value: "FOB" },
                                                { label: "CIF", value: "CIF" },
                                                { label: "CFR", value: "CFR" },
                                                { label: "DDP", value: "DDP" },
                                                { label: "DDU", value: "DDU" }]} required
                                            />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12"> Payment Terms* : </label>
                                            <Select
                                                placeholder={"Select Payment Terms"}
                                                closeMenuOnSelect={true}
                                                value={this.state.payment_terms}

                                                onChange={this.paymentchangeHandler.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={[{ label: "Free of Charge", value: "Free of Charge" },
                                                { label: "Payment", value: "Payment" }]} required
                                            />
                                        </div>
                                        <div className={"col-xl-4 col-lg-4 " + this.state.showUpload}>
                                            <label className="col-xl-12 col-lg-12">GR waiver-In case of free of charge* : </label>
                                            <input type="file" name="GRW_file" id="GRW_file" className="form-control requestFields" onChange={this.changeFileHandler4} />
                                            <FileBase64
                                            onDone={ this.changeFileHandler.bind(this) } />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Remarks* : </label>
                                            <input type="text" name="remarks" id="remarks" className="form-control alfa-cls" onChange={this.changeHandler} required />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12"> certificate of Origin* : </label>
                                            <Select
                                                placeholder={"Select Certificate of Origin"}
                                                closeMenuOnSelect={true}
                                                value={this.state.certificateOfOrigin}

                                                onChange={this.certificateOfOriginchangeHandler.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={[{ label: "Yes", value: "Yes" },
                                                { label: "No", value: "No" }]} required
                                            />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12"> FTA(Free Trade Agreement) Required* : </label>
                                            <Select
                                                placeholder={"Select FTA Required"}
                                                closeMenuOnSelect={true}
                                                value={this.state.fta}

                                                onChange={this.ftachangeHandler.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={[{ label: "Yes", value: "Yes" },
                                                { label: "No", value: "No" }]} required
                                            />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12"> Export Incentive* : </label>
                                            <Select
                                                placeholder={"Select Export Incentive"}
                                                closeMenuOnSelect={true}
                                                value={this.state.exportIncentive}

                                                onChange={this.exportIncentivechangeHandler.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={[{ label: "Yes", value: "Yes" },
                                                { label: "No", value: "No" }]} required
                                            />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-success" style={{ marginLeft: "45%", width: "192px", marginTop: "20px" }}>Submit</button>
                                    </div>
                                </form> */} 
                            </div>
                        </div>
                    </div>
                    {/* <div className={"col-xl-12 col-lg-12 " + this.state.trackData}>
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Booking Status</span>
                                </h5>
                            </div>
                            <div className="card-body">
                                <form className="row" onSubmit={this.trackHandler.bind(this)} encType="multipart/form-data">
                                    <div className="col-xl-4">
                                        <input type="email" name="indenter_email" id="indenter_email" className="form-control" placeholder="Indenter Email" required />
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <button type="submit" className="btn btn-info">Track Status</button>
                                    </div>
                                </form>
                            </div>
                            <div className={"col-xl-12 col-lg-12 " + this.state.trackResData}>
                                <div id="myGrid" style={{ width: "100%", height: "478px" }} className={"ag-theme-balham"}>
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={false}
                                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        stopEditingWhenGridLosesFocus={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        // components={this.state.components}
                                        enableRangeSelection={true}
                                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        )
    }
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_date').datetimepicker({
        //mask:'39-19-9999',
        format: 'd-m-Y',
        minDate: true,
        timepicker: false,
        closeOnDateSelect: true,
        scrollMonth: false,
        scrollInput: false
    });

    $('.cargo_date').datetimepicker({
        //mask:'39-19-9999',
        format: 'd-m-Y',
        minDate: true,
        timepicker: false,
    });

    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    script.crossOrigin = "anonymous"
    index.parentNode.insertBefore(script, index)
}

// $('body').on('focus',".datetimepicker_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false
// 	});
// });

// $('body').on('focus',".cargo_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false,

// 	});
// });

$(document).on("input", ".num-cls", function() {
    this.value = this.value.replace(/\D/g,'');
})
$(document).on("input", ".mobnum-cls", function() {
    this.value = this.value.replace(/^\d{11}$/g,'');
})
$(document).on("input", ".alfa-cls", function () {
    this.value = this.value.replace(/[^a-z\s]+$/gi, '');
  });
  $(document).on("input", ".dept-cls", function () {
    this.value = this.value.replace(/[^a-z-\s]/gi, '');
  });
  $(document).on("input", ".ringi-cls", function () {
    this.value = this.value.toUpperCase();
    this.value = this.value.replace(/[^\d[A-Z]-\s]/gi, '');
  });
$(document).on("change", "#cargoDate", function (e) {
    e.preventDefault();
    var bookingDate = $("#dateField").val();
    var cargoDate = $("#cargoDate").val();
    var bookingDate = bookingDate.split("-")[2] + "-" + bookingDate.split("-")[1] + "-" + bookingDate.split("-")[0]
    var cargoDate = cargoDate.split("-")[2] + "-" + cargoDate.split("-")[1] + "-" + cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    console.log(startTime, endTime);
    if (startTime >= endTime) {
        $("#cargoDate").val("");
        alert("Date should be greater than Booking Date");
    }
})
import React, { Component } from "react";
import axios from 'axios';
import {Redirect} from 'react-router-dom';
import redirectURL from '../redirectURL'; // Assuming redirectURL is an axios instance

class EnmUserLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {successLogin:0};
    }

    async componentDidMount() {
        const token = this.props.match.params.token;
        var ppwwdd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		var usrtoken = ppwwdd(token)
        const query = {
            token: token
        };
        try {
            const response = await redirectURL.post("/authenticateUserToken", query);
            console.log("After login ", response);

            if (response.status === 200) {
                if (response.data.auth) {
                    if (response.data.otpAuthentication === 1) {
                        this.setState({
                            otpAuthentication: response.data.otpAuthentication,
                            otpMessage: "OTP has been sent to your Email",
                            userData: response.data,
                            successLogin: 0,
                        });
                    } else {
                        this.setState({
                            "successLogin" : 1,
                        })
                        await redirectURL.post("/manage/saveuserlogged", {
                            token: response.data.token,
                            userId: response.data.userid
                        });
                        
                        localStorage.setItem('_t', window.btoa(response.data.token));
                        localStorage.setItem('userid', response.data.userid);
                        localStorage.setItem('dept_code', JSON.stringify(response.data.user.dept_code));
                        localStorage.setItem('is_admin', response.data.user.is_admin);
                        localStorage.setItem('is_dept_admin', response.data.user.is_dept_admin);
                        localStorage.setItem('is_global', response.data.user.is_global);
                        localStorage.setItem('firstname', response.data.user.firstname);
                        localStorage.setItem('username', response.data.user.username);
                        localStorage.setItem('lastname', response.data.user.lastname);
                        // localStorage.setItem('pc', response.data.user.plant_code || "HZ");
                        localStorage.setItem('email', response.data.user.email.replace("[at]", "@").replace("[dot]", "."));
                        localStorage.setItem('user_type', response.data.user.user_type);
                        // localStorage.setItem('plant_code', response.data.user.plant_code || "");
                        localStorage.setItem('roles', response.data.user.roles);
                        localStorage.setItem("successLogin",this.state.successLogin)
						localStorage.setItem('usermenus', JSON.stringify(response.data.menus));
						//console.log("response.data.usermenucodes ", response.data.usermenucodes);
						localStorage.setItem('usermenucodes', JSON.stringify(response.data.usermenucodes));
                        // localStorage.setItem('actual_roles', response.data.user.roles);
                        sessionStorage.setItem('ref', 1);

                        this.setState({
                            username: '',
                            email: '',
                            password: '',
                            loginMessage: response.data.msg
                        });
                    }
                } else {
                    this.setState({
                        username: '',
                        email: '',
                        password: '',
                        loginMessage: response.data.msg
                    });
                }
            } else {
                this.setState({
                    username: '',
                    email: '',
                    password: '',
                    loginMessage: response.data.msg
                });
            }
        } catch (error) {
            console.error("Error authenticating user:", error);
        }
    }

    getUserDashboardUrl(pathToMatch = null) {
 
		var uroles = localStorage.getItem("roles");
		var arr = []
				
		
		// redirectURL.post("/menus/roleinfo",{role:uroles})
		// .then((res) => {
		// 	//console.log("repson ", response.data)
		// 	var rec = res.data
		// 	if(rec.length > 0)
		// 	{
		// 		rec.map((item) => {
		// 			if(item.dashboard_url != '' && item.dashboard_url != undefined)
		// 			{
		// 				arr.push(item.dashboard_url)
		// 			}
		// 		})
				
		// 	}
		// 	else
		// 	{
		// 		arr.push("/dashboard/default")
		// 	}
		// 	if(arr.length > 0)
		// 	{
				
		// 		this.setState({
		// 			landingpage:arr[0]
		// 		});
		// 	}
		// 	else
		// 	{
		// 		this.setState({
		// 			landingpage:"/dashboard/default"
		// 		});
		// 	}
		// 	//return dashboardUrl;
		// })

		
		let dashboardUrl = "dashboard/default";
		let currentDepartmentCode = null;
		let departmentCode = JSON.parse(localStorage.getItem("dept_code"));

		//console.log("IN render, departmentCode ", departmentCode);
		if (Array.isArray(departmentCode)) {
			currentDepartmentCode = departmentCode[0]; 
		} else {
			currentDepartmentCode = departmentCode; 
		}
		var userroles = localStorage.getItem("roles");
		//console.log("IN render, currentDepartmentCode ", currentDepartmentCode);
		//console.log("localStorage roles ", localStorage.getItem("roles"))
		if(localStorage.getItem("is_enmovil_transporter") == 1)
		{
			if (userroles.indexOf("tpt_vrc") >= 0)
			{
				dashboardUrl="/tptlogin/viewcarrierroute";
			}
			else if (userroles.indexOf("tpt_subuser_railsummary") >= 0)
			{
				dashboardUrl="/tptlogin/tptrailsummary";
			}
			else
			{
				dashboardUrl="/tptlogin/gpsintegrationstatus";
			}
		}
		else if(this.props.is_load_management == 1)
		{
			var userroles = localStorage.getItem("roles");
			dashboardUrl="/lmwelcome";
		}
		else
		{			
			console.log(currentDepartmentCode,"currentDepartmentCode")
			switch(currentDepartmentCode) {
				case "SNDG":
					//dashboardUrl="/dashboard/snddefault";
					if(localStorage.getItem("user_type") == 'TRANSPORTER')
					{
						var userroles = localStorage.getItem("roles");
						if (userroles.indexOf("tpt_rail") >= 0)
						{
							dashboardUrl="/railconsignments";
						}
						else if(userroles.indexOf("auto_rake_planning") >= 0)
						{
							dashboardUrl="/rprakeplansummary";
						}
						else if(userroles.indexOf("lsp_carriers_users") >= 0)
						{
							dashboardUrl="/lspcarrierdatauser"
						}
						else
						{
							dashboardUrl="/sndtransporterconsignments";
						}
					}
					else if(localStorage.getItem("is_sales_type") == 1)
					{
						dashboardUrl="/snddispatches";
					}
					else{
						var userroles = localStorage.getItem("roles");
						try{

							if (userroles.indexOf("AG2") >= 0)
							{
								dashboardUrl="/sndconsignments";
							}
							else if (userroles.indexOf("led") >= 0)
							{
								dashboardUrl="/livemaptrucklocation";
							}
							else if (userroles.indexOf("GPSUSER") >= 0)
							{
								dashboardUrl="/mlltruckgps";
							}
							else if (userroles.indexOf("Plant") >= 0 || userroles.indexOf("RSO") >= 0
							|| userroles.indexOf("Loading Officer") >= 0
							|| userroles.indexOf("Unloading Officer") >= 0
							|| userroles.indexOf("BA") >= 0)
							{
								dashboardUrl="/mllsummary";
							}
							else if (userroles.indexOf("rail") >= 0)
							{
								dashboardUrl="/railconsignments";
							}
							else if(userroles.indexOf("auto_rake_planning") >= 0)
							{
								dashboardUrl="/rprakeplansummary";
							}
							else if(userroles.indexOf("secpayments") >= 0)
						{
							dashboardUrl="/msildashboard";
						}
						else if(userroles.indexOf("truck_gps_data_sndg") >= 0)
						{
							dashboardUrl="/trucksgps"
						}
							else{
								dashboardUrl="/snddashboardtemplate";
							}
						}
						catch(e){
							dashboardUrl="/snddashboardtemplate";
						}
					}
					break;
				case "LOG-PRT":
					//dashboardUrl="/dashboard/prtdefault";
					if(localStorage.getItem("user_type") == 'TRANSPORTER')
					{
						var userroles = localStorage.getItem("roles");
						if (userroles.indexOf("tpt_rail") >= 0)
						{
							dashboardUrl="/railconsignments";
						}
						else if (userroles.indexOf("tpt_courier") >= 0)
						{
							dashboardUrl="/activecourierconsignments";
						}

						else if (userroles.indexOf("tpt_alloybars") >= 0)
						{
							dashboardUrl="/alloybarsComponent";
						}
						else if(userroles.indexOf("actl") >= 0){
							dashboardUrl="/actlcontainers";
						}
						else if(userroles.indexOf("cha_prt_exports") >= 0)
						{
							dashboardUrl="/exports/transhipmentconsignments"
						}
						else if(userroles.indexOf("ff_air_exports_cargo") >= 0)
						{
							dashboardUrl="/ffaircargoexports";
						}
						else
						{
							dashboardUrl="/prttransporterconsignments";
						}
					}
					else if (localStorage.getItem("roles") == "PACC")
					{
						dashboardUrl="/prtallconsignments";
					}
					else{
						dashboardUrl="/prtdashboardtemplate";
					}
					
					break;
				case "LOG-TNP":
					//dashboardUrl="/dashboard/tnpdefault";
					var userroles = localStorage.getItem("roles");
					if(userroles.indexOf("TNP DDVM") > 0)
					{
						dashboardUrl="/managementdashboard";
					}
					else if(userroles.indexOf("tnp_rp") >= 0)
					{
						dashboardUrl="/returnemptypallets";
					}
					else
					{
						dashboardUrl="/tnpconsignments";
					}
					break;
				case "LOG-SC":
					//dashboardUrl="/dashboard/scdefault";
					if(localStorage.getItem("user_type") == 'TRANSPORTER')
					{
						var userroles = localStorage.getItem("roles");
						console.log(userroles,"userroles")
						if (userroles.indexOf("tpt_alloybars") >= 0)
						{
							dashboardUrl="/alloybarsComponent";
						}

						else if(userroles.indexOf("actl") >= 0){
							dashboardUrl="/actlcontainers";
						}
						else if(userroles.indexOf("lcl_containers") >= 0){
							dashboardUrl="/lclimports";
						}
						else if(userroles.indexOf("fcl_dsr") >= 0){
							dashboardUrl="/importsshipments";
						}
						else if (userroles.indexOf("sc_container_alloys") >= 0)
						{
							dashboardUrl="/imports/consignmentsnew";
						}
						else if(userroles.indexOf("air_cargo_form") >= 0)
						{
							dashboardUrl="/aircargoform";
						}
						else if(userroles.indexOf("ff_air_cargo") >= 0)
						{
							dashboardUrl="/ffaircargos";
						}
						else if(userroles.indexOf("cha_air_cargo") >= 0)
						{
							dashboardUrl="/chaaircargos";
						}
						else if(userroles.indexOf("transportation_air_exports_cargo") >= 0)
						{
							dashboardUrl="/transportationaircargoexports";
						}
						else if(userroles.indexOf("cha_air_exports_cargo") >= 0)
						{
							dashboardUrl="/chaaircargoexports";
						}
						else if(userroles.indexOf("ff_air_exports_cargo") >= 0)
						{
							dashboardUrl="/ffaircargoexports";
						}
						else if(userroles.indexOf("air_cargo_exports_form") >= 0 || userroles.indexOf("air_exports_form") >= 0)
						{
							dashboardUrl="/aircargoexportsform";
						}
						else if(userroles.indexOf("sea_imports_form") >= 0)
						{
							dashboardUrl="/seaimportsform";
						}
						else if(userroles.indexOf("seaimports_form") >= 0)
						{
							dashboardUrl="/seaimportsform";
						}
						else if(userroles.indexOf("ff_sea_imports") >= 0)
						{
							dashboardUrl="/ffseaimports";
						}
						else if(userroles.indexOf("cha_sea_imports") >= 0)
						{
							dashboardUrl="/chaseaimports";
						}
						else if(userroles.indexOf("cha_prt_exports") >= 0)
						{
							dashboardUrl="/exports/transhipmentconsignments";
						}
						else if(userroles.indexOf("Customs Coils") >= 0)
						{
							dashboardUrl="/chacoils";
						}
						else if(userroles.indexOf("fcl_dsr") >= 0){
							dashboardUrl="/importsshipments";
						}
						else if(userroles.indexOf("fcl_dsr") >= 0)
						{
                            dashboardUrl="/importsshipments";
                        }
						else if(userroles.indexOf("ff_exports_tracking") >= 0)
						{
                            dashboardUrl="/containerbookingffupdates";
                        }
						else if(userroles.indexOf("transporter_exports_tracking") >= 0)
						{
                            dashboardUrl="/containerbookingtransporterupdates";
                        }
						else if(userroles.indexOf("container_booking_system") >= 0)
						{
                            dashboardUrl="/containerbookingsystem/onetime";
                        }
						else if(userroles.indexOf("cc_form_screen") >= 0)
						{
                            dashboardUrl="/customsclearenceform";
                        }
						else if(userroles.indexOf("onetime_booking_system") >= 0)
						{
                            dashboardUrl="/onetimecontainerbooking";
                        }
						else if(userroles.indexOf("weekly_booking_system") >= 0)
						{
                            dashboardUrl="/weeklycontainerbooking";
                        }
						else if(userroles.indexOf("coils_tracking_transporter") >= 0)
						{
                            dashboardUrl="/gstregisteredwarehousecoildispatch";
                        }
						else if(userroles.indexOf("fms_role_buyers") >= 0)
						{
							dashboardUrl="/fcsbuyers"
						}
						else
						{
							dashboardUrl="/imports/consignmentsnew";
						}
					}
					else{
						var userroles = localStorage.getItem("roles");
						console.log(userroles,"userroles")
						if (userroles.indexOf("sc_ppc") >= 0)
						{
							dashboardUrl="/imports/consignmentsnew";
						}
						else if (userroles.indexOf("LOG-SC Exports") >= 0)
						{
							dashboardUrl="/exports/deliveredconsignments";
						}
						else if(userroles.indexOf("actl") >= 0){
							dashboardUrl="/actlcontainers";
						}
						else if (userroles.indexOf("sc_container_alloys") >= 0)
						{
							dashboardUrl="/imports/consignmentsnew";
						}
						else if(userroles.indexOf("air_cargo_form") >= 0)
						{
							dashboardUrl="/aircargoform";
						}
						else if(userroles.indexOf("ff_air_cargo") >= 0)
						{
							dashboardUrl="/ffaircargos";
						}
						else if(userroles.indexOf("cha_air_cargo") >= 0)
						{
							dashboardUrl="/chaaircargos";
						}
						else if(userroles.indexOf("transportation_air_exports_cargo") >= 0)
						{
							dashboardUrl="/transportationaircargoexports";
						}
						else if(userroles.indexOf("cha_air_exports_cargo") >= 0)
						{
							dashboardUrl="/chaaircargoexports";
						}
						else if(userroles.indexOf("ff_air_exports_cargo") >= 0)
						{
							dashboardUrl="/ffaircargoexports";
						}
						else if(userroles.indexOf("air_cargo_exports_form") >= 0 || userroles.indexOf("air_exports_form") >= 0)
						{
							dashboardUrl="/aircargoexportsform";
						}
						else if(userroles.indexOf("sea_imports_form") >= 0)
						{
							dashboardUrl="/seaimportsform";
						}
						else if(userroles.indexOf("seaimports_form") >= 0)
						{
							dashboardUrl="/seaimportsform";
						}
						else if(userroles.indexOf("cha_sea_imports") >= 0)
						{
							dashboardUrl="/chaseaimports";
						}
						else if(userroles.indexOf("ff_sea_imports") >= 0)
						{
							dashboardUrl="/ffseaimports";
						}
						else if(userroles.indexOf("Customs Coils") >= 0)
						{
							dashboardUrl="/chacoils";
						}
						else if(userroles.indexOf("ff_exports_tracking") >= 0)
						{
                            dashboardUrl="/containerbookingffupdates";
                        }
						else if(userroles.indexOf("transporter_exports_tracking") >= 0)
						{
                            dashboardUrl="/containerbookingtransporterupdates";
                        }
						else if(userroles.indexOf("container_booking_system") >= 0)
						{
                            dashboardUrl="/containerbookingsystem/onetime";
                        }
						else if(userroles.indexOf("cc_form_screen") >= 0)
						{
                            dashboardUrl="/customsclearenceform";
                        }
						else if(userroles.indexOf("onetime_booking_system") >= 0)
						{
                            dashboardUrl="/onetimecontainerbooking";
                        }
						else if(userroles.indexOf("weekly_booking_system") >= 0)
						{
                            dashboardUrl="/weeklycontainerbooking";
                        }
						else if(userroles.indexOf("coils_tracking_role_sc_bd") >= 0)
						{
							dashboardUrl="/coilstockinformation";
						}
						else if(userroles.indexOf("coils_tracking_role_other") >= 0)
						{
							dashboardUrl="/coilstockinformation";
						}
						else if(userroles.indexOf("fms_role_buyers") >= 0)
						{
							dashboardUrl="/fcsbuyers"
						}else if(userroles.indexOf("fms_role") >=0)
						{
							dashboardUrl = "/fcsmaster"
						}
						else
						{
							dashboardUrl="/imports/consignmentsnew";
						}
					}

					
					break;
				case "SUP-CHN":
					//dashboardUrl="/dashboard/scdefault";
					dashboardUrl="/imports/consignmentsnew";
					break;
				case "Customs Coils":
					//dashboardUrl="/dashboard/prtdefault";
					dashboardUrl="/chacoils";
					break;
				case "Customs Containers":
					//dashboardUrl="/dashboard/prtdefault";
					dashboardUrl="/chanhavasheva";
					break;
				case "AD":
					//dashboardUrl="/dashboard/scdefault";
					var userroles = localStorage.getItem("roles");
					if (userroles.indexOf("SITE_ADMIN") >= 0 || userroles.indexOf("ENM_ADMIN") >= 0 || userroles.indexOf("SR MGMT") >= 0 || userroles.indexOf("MIS FORECAST") >= 0)
						{
							dashboardUrl="/managementdashboard";
						}
					else if (userroles.indexOf("lm_admin") >= 0 || userroles.indexOf("lm_user") >= 0)
						{
							dashboardUrl="/lmloadsummary";
						}
					else if (userroles.indexOf("MahindraAdmin") >= 0)
					{
						dashboardUrl="/mllsummary";
					}
					else
					{
						dashboardUrl="/livetrucksinfo";
					}
					break;
				case "LM":
					var userroles = localStorage.getItem("roles");
					//var usermenus = localStorage.getItem("usermenus");
					// if (userroles.indexOf("lm_admin") >= 0 || userroles.indexOf("lm_user") >= 0)
					// {
						
					// }
					if (userroles.indexOf("lm_site_admin") >= 0)
					{
						dashboardUrl="/lmdepartments";
					}
					else
					{
						dashboardUrl="/lmwelcome";
					}
					break;
				case "SND DDVM":
					//dashboardUrl="/dashboard/snddefault";
					dashboardUrl="/dashboardsummary";
					break;
				case "TNP DDVM":
					//dashboardUrl="/dashboard/snddefault";
					dashboardUrl="/dashboardsummary";
					break;				
				case "PRT DDVM":
					//dashboardUrl="/dashboard/snddefault";
					dashboardUrl="/dashboardsummary";
					break;		
				case "SC DDVM":
					//dashboardUrl="/dashboard/snddefault";
					dashboardUrl="/dashboardsummary";
					break;
				default:
					let isTmsUser = false;
					currentDepartmentCode = currentDepartmentCode.trim();
					if (currentDepartmentCode !== "SA") {
						// TMS users don't belong to any department
						let userMenuCodes = JSON.parse(localStorage.getItem("usermenucodes"));
						if (Array.isArray(userMenuCodes)) {
							isTmsUser = userMenuCodes.includes("tms");
						} else if (userMenuCodes == "tms") {
							isTmsUser = true; 
						}
					} else {
						console.log("IN default, ELSE, currentDepartmentCode = ", currentDepartmentCode);
					}
					console.log("isTmsUser ",isTmsUser)
					if (isTmsUser) {
						if(localStorage.getItem("user_type") == "DCTUSER"){
							 dashboardUrl="/dctattendancelogs";
						}else{
							dashboardUrl="/ticketmanagementsystem";
						}
					} else {
						dashboardUrl="/dashboard/default";
					}
					
					console.log("IN getUserDashboardUrl, default, params = ", departmentCode);
			}
		}
		console.log("dashboardUrl Login", dashboardUrl)
		return dashboardUrl;
	}

	cipher = salt => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
		const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
	
		return text => text.split('')
			.map(textToChars)
			.map(applySaltToChar)
			.map(byteHex)
			.join('');
	}
    render() {
		if(localStorage.getItem('_t') != '' && localStorage.getItem('_t') != null)
		{
			var successLogin = this.state.successLogin;
            console.log(successLogin,"successLogin")
			if(successLogin == 1)
			{
				let dashboardUrl = this.getUserDashboardUrl();
				console.log(dashboardUrl,"dashboardUrl1143");
				//let dashboardUrl = this.state.landingpage;
				// return <Redirect to="dashboard/snddefault" push={true} />

				return <Redirect to={dashboardUrl} push={true} />
			}
		}
        return (
            <div>
                {/* Loader starts */}
                <div className="loader-wrapper">
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Authenticating... <span>&#x263A;</span></h4>
                    </div>
                </div>
                {/* Loader ends */}
            </div>
        );
    }
}

export default EnmUserLogin;
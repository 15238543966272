import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';

class VicinityCounter extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
    		records:[],
    		type1: 0,
			type2: 0,
			type3: 0,
			type4: 0,
    	}
    this.onClickCounter = this.onClickCounter.bind(this);
    }
    componentDidMount(){
    	//console.log("Box ",window.atob(localStorage.getItem('_t')));
    	//console.log(this.props);
		const layoutdata={
			token:window.atob(localStorage.getItem('_t')),
		}
    		//console.log("Trucks", this.props.tottrucks);
    	//	//console.log("trucklocations", this.props.truckgps);
    		//console.log("notrans", this.props.notruckgps);
		 this.setState({
            type1:this.props.type1,
            type2:this.props.type2,
            type3:this.props.type3,
            type4:this.props.type4,
  		});
    }
    onClickCounter(type){
        this.props.context.onClickCounterShowData(type)
    }
    render(){

        return (

                <div className="card crm-numbers">
					<div className="row card-body">
						<div className="col" style={{cursor:"pointer"}} onClick={this.onClickCounter.bind(this,"type1")}>
							<span>0 - 60 Kms</span>
							<h4 className="txt-info fnt2"><span className="counter"><CountUp end={this.props.type1}/></span></h4>
						</div>
						<div className="col" style={{cursor:"pointer"}} onClick={this.onClickCounter.bind(this,"type2")}>
							<span>60 - 200 Kms</span>
							<h4 className="txt-success-dark fnt2"><span className="counter"><CountUp end={this.props.type2}/></span></h4>
						</div>                        
						<div className="col" style={{cursor:"pointer"}} onClick={this.onClickCounter.bind(this,"type3")}>
							<span>200 - 300 Kms</span>
							<h4 className="txt-success-medium fnt2"><span className="counter"><CountUp end={this.props.type3}/></span></h4>
						</div>
						<div className="col" style={{cursor:"pointer"}} onClick={this.onClickCounter.bind(this,"type4")}>
							<span>300 - 400 Kms</span>
							<h4 className="txt-danger fnt2"><span className="counter"><CountUp end={this.props.type4}/></span></h4>
						</div>
					</div>
				</div>
                

        );
    }
}

export default VicinityCounter
'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import * as Datetime from 'react-datetime';
import $ from 'jquery';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Modal from 'react-responsive-modal';
import UpdateWellnessdata from "./updatewellnessdata";
import UpdateKalyansathidata from "./updatekalyansathidata";
import CSVFileValidator from 'csv-file-validator'
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

export default class ManagementDashboard extends Component {
  constructor(props) {
    super(props);
      this.state = {
        pageTitle: "",
        eventGridAction: "gridAction",
        eventCounterAction: "counterAction",
        eventFormAction: "formAction",
        uploadDivWidth:'0%',
        sliderTranslate:'',
        loadshow:'show-n',
        showDiv:'show-n',
        alertshow:'fade',
        alertmg:'show-n',
        alerterrmg:'show-n',
        overly:'show-n',
        alerterrshow:'fade',
        alert:null,
        show: false,
        basicTitle:'',
        basicType:"default",
        modules: AllModules,
        sidebarSubHeader:'',
        defaultColDef: {
              sortable: true,
            filter: true,
            resizable: true

        },
        rowData: [],
        rowData1: [],
        rowData2: [],
        rowData3: [],
        rowSelection:'multiple',
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
        overlayNoRowsTemplate: "",
        frameworkComponents: {
            UpdateWellnessdata:UpdateWellnessdata
        },
        frameworkComponents1: {
            UpdateKalyansathidata:UpdateKalyansathidata
        },

        animateRows: true,
        debug: true,
        showToolPanel: false,
        uppressAggFuncInHeader: true,				
        groupDefaultExpanded: -1,
        childconfs: [],
        childrow:[],
        rowModelType: "serverSide",
        paginationPageSize:1000,
        cacheBlockSize: 10,
        maxBlocksInCache: 1,
        statusBar: {
            statusPanels: [
                {
                statusPanel: "agTotalAndFilteredRowCountComponent",
                align: "left"
                },
                {
                statusPanel: "agTotalRowCountComponent",
                align: "center"
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" }
            ]
        },
        sideBar: {
            toolPanels: [
                {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel"
                },
                {
                id: "filters",
                labelDefault: "Filters",
                labelKey: "filters",
                iconKey: "filter",
                toolPanel: "agFiltersToolPanel"
                },
            ]
        },
        headerHeight:60,
        detailCellRendererParams:{},
        rowClassRules: {
            "highlitRow": "data.transit_delay == 1",
            "green":"data.status >= 3"

        },
        ismgnt:0,
        csvcontent:[],
        file:"",
        last_updated_on:"",
        screenView: ""
    }
    this.onClickApproveData = this.onClickApproveData.bind(this);
    this.onClickApproveData1 = this.onClickApproveData1.bind(this);
    this.onClickApproveData2 = this.onClickApproveData2.bind(this);
    this.onClickApproveData3 = this.onClickApproveData3.bind(this);
    this.onLoadShowData = this.onLoadShowData.bind(this);
    this.onClickUpdateItem = this.onClickUpdateItem.bind(this);
    this.addNewRow = this.addNewRow.bind(this);
    this.addNewRow1 = this.addNewRow1.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
    
  }

    componentDidMount = async () =>{
        try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
        }
        var dept_code="";
        var pagetile = '';
        var screenView = "";
        var ismgnt = 0;
        if(this.props.match.path == "/divisionhealthreport")
        {
            dept_code = "";
            pagetile = "SND Division Health Report";
            ismgnt = 1;
        }
        if(this.props.match.path == "/mgmtdivisionhealthreport")
        {
            dept_code = "";
            pagetile = "SND Division Health Report";
            ismgnt = 1;
            screenView = "Mobile";
        }
        if(this.props.match.path == "/snddivisionhealthreport")
        {
            dept_code = "SNDG";
            pagetile = "SND Division Health Report";
            ismgnt = 0;
        }
        if(this.props.match.path == "/prtdivisionhealthreport")
        {
            dept_code = "LOG-PRT";
            pagetile = "LOG-PRT Division Health Report";
            ismgnt = 0;
        }
        if(this.props.match.path == "/tnpdivisionhealthreport")
        {
            dept_code = "LOG-TNP";
            pagetile = "LOG-TNP Division Health Report";
            ismgnt = 0;
        }
        if(this.props.match.path == "/scdivisionhealthreport")
        {
            dept_code = "LOG-SC";
            pagetile = "LOG-SC Division Health Report";
            ismgnt = 0;
        }

       await this.setState({
            dept_code:dept_code,
            pageTitle:pagetile,
            ismgnt:ismgnt,
            screenView: screenView
        })

        var parameters = {
            dept_code:dept_code
        }
        await this.onLoadShowData(parameters)
    }
    
    
    onLoadShowData(parameters){
        var urlpath="/consignments/divisionhealthdata";
        console.log("this.state.ismgnt ", this.state.ismgnt)
        if(this.state.ismgnt == 1)
        {
            var urlpath="/consignments/managementdivisionhealthdata";
        }
        else
        {
            var urlpath="/consignments/divisionhealthdata";
        }
        redirectURL.post(urlpath, parameters)
        .then((response) => {
            // console.log("response ", response.data)
            var records = response.data.records
            if(this.state.ismgnt == 0)
            {
                var rowdata = [
                    {
                        "apptype":"wellness",
                        "apptype_dept_update":"",
                        "manpower":"",
                        "response_receive":"",
                        "response_percent":"",
                        "no_symptoms":"",
                        "response_symptom":"",
                        "containment_zone":"",
                        "remarks":""
                    }
                ]
                
                var rowdata1 = [
                    {
                        "apptype":"kalyansathi",
                        "apptype_dept_update":"",
                        "manpower":"",
                        "response_receive":"",
                        "response_percent":"",
                        "no_symptoms":"",
                        "response_symptom":"",
                        "containment_zone":"",
                        "remarks":""
                    }
                ]
            }
            else{
                var rowdata = []
                
                var rowdata1 = []
            }
            if(records.length > 0)
            {
                var last_updated_on = getHyphenDDMMMYYYYHHMM(records[0].last_updated_on);
                this.setState({
                    last_updated_on:last_updated_on
                })
                var welldata = []
                var ksdata = []
                var welldata1 = []
                var ksdata1 = []
                var staff1=0;
                var resrec1=0;
                var resper1=0;
                var nosym1=0;
                var wsym1=0;
                var czn1=0;
                
                var staff2=0;
                var resrec2=0;
                var resper2=0;
                var nosym2=0;
                var wsym2=0;
                var czn2=0;

                
                var staff3=0;
                var resrec3=0;
                var resper3=0;
                var nosym3=0;
                var wsym3=0;
                var czn3=0;
                
                var staff4=0;
                var resrec4=0;
                var resper4=0;
                var nosym4=0;
                var wsym4=0;
                var czn4=0;

                var c1=0;
                var c2=0;
                var c3=0;
                var c4=0;
                records.map((item) =>{
                    if(item.apptype == "wellness")
                    {
                        if(item.dept_code == "SNDG")
                        {
                            welldata.push(item)
                            if(item.manpower != undefined && item.manpower != "")
                            {
                                var mn = item.manpower
                            }
                            else{
                                var mn = 0;
                            }
                            staff1=parseInt(staff1)+parseInt(mn);
                            if(item.response_receive != undefined && item.response_receive != "")
                            {
                                var rr = item.response_receive;
                            }
                            else
                            {
                                var rr = 0
                            }
                            resrec1=parseInt(resrec1)+parseInt(rr);
                            if(item.response_percent != undefined && item.response_percent != "")
                            {
                                var rp = item.response_percent;
                            }
                            else
                            {
                                var rp = 0;
                            }
                            resper1=parseInt(resper1)+(parseInt(rp));
                            if(item.no_symptoms != undefined && item.no_symptoms != "")
                            {
                                var ns = item.no_symptoms;
                            }
                            else
                            {
                                var ns = 0;
                            }
                            nosym1=parseInt(nosym1)+parseInt(ns);
                            if(item.response_symptom != undefined && item.response_symptom != "")
                            {
                                var rs = item.response_symptom;
                            }
                            else
                            {
                                var rs = 0;
                            }
                            wsym1=parseInt(wsym1)+parseInt(rs);
                            if(item.containment_zone != undefined && item.containment_zone != "")
                            {
                                var cz = item.containment_zone;
                            }
                            else
                            {
                                var cz = 0;
                            }
                            czn1=parseInt(czn1)+parseInt(cz);
                            c1=c1+1
                        }
                        else{
                            if(this.state.ismgnt == 0)
                            {
                                if(item.dept_code == "SNDG")
                                {
                                    welldata.push(item)
                                }
                            }
                        }
                    }
                    else
                    {
                        if(item.dept_code == "SNDG")
                        {
                            ksdata.push(item)
                            if(item.manpower != undefined && item.manpower != "")
                            {
                                var mn2 = item.manpower
                            }
                            else{
                                var mn2 = 0;
                            }
                            staff2=parseInt(staff2)+parseInt(mn2);
                            if(item.response_receive != undefined && item.response_receive != "")
                            {
                                var rr2 = item.response_receive;
                            }
                            else
                            {
                                var rr2 = 0
                            }
                            resrec2=parseInt(resrec2)+parseInt(rr2);
                            if(item.response_percent != undefined && item.response_percent != "")
                            {
                                var rp2 = item.response_percent;
                            }
                            else
                            {
                                var rp2 = 0;
                            }
                            resper2=parseInt(resper2)+(parseInt(rp2));
                            if(item.no_symptoms != undefined && item.no_symptoms != "")
                            {
                                var ns2 = item.no_symptoms;
                            }
                            else
                            {
                                var ns2 = 0;
                            }
                            nosym2=parseInt(nosym2)+parseInt(ns2);
                            if(item.response_symptom != undefined && item.response_symptom != "")
                            {
                                var rs2 = item.response_symptom;
                            }
                            else
                            {
                                var rs2 = 0;
                            }
                            wsym2=parseInt(wsym2)+parseInt(rs2);
                            if(item.containment_zone != undefined && item.containment_zone != "")
                            {
                                var cz2 = item.containment_zone;
                            }
                            else
                            {
                                var cz2 = 0;
                            }
                            czn2=parseInt(czn2)+parseInt(cz2);
                            c2=c2+1
                        }
                        else{
                            if(this.state.ismgnt == 0)
                            {
                                if(item.dept_code == "SNDG")
                                {
                                    ksdata.push(item)
                                }
                            }
                        }
                        
                    }
                    if(item.dept_code != "SNDG")
                    {
                        if(item.apptype == "wellness")
                        {
                            welldata1.push(item)
                            // staff3=parseInt(staff3)+parseInt(item.manpower);
                            // resrec3=parseInt(resrec3)+parseInt(item.response_receive);
                            // resper3=parseInt(resper3)+(parseInt(item.response_percent));
                            // nosym3=parseInt(nosym3)+parseInt(item.no_symptoms);
                            // wsym3=parseInt(wsym3)+parseInt(item.response_symptom);
                            // czn3=parseInt(czn3)+parseInt(item.containment_zone);
                            // c3=c3+1

                            if(item.manpower != undefined && item.manpower != "")
                            {
                                var mn3 = item.manpower
                            }
                            else{
                                var mn3 = 0;
                            }
                            staff3=parseInt(staff3)+parseInt(mn3);
                            if(item.response_receive != undefined && item.response_receive != "")
                            {
                                var rr3 = item.response_receive;
                            }
                            else
                            {
                                var rr3 = 0
                            }
                            resrec3=parseInt(resrec3)+parseInt(rr3);
                            if(item.response_percent != undefined && item.response_percent != "")
                            {
                                var rp3 = item.response_percent;
                            }
                            else
                            {
                                var rp3 = 0;
                            }
                            resper3=parseInt(resper3)+(parseInt(rp3));
                            if(item.no_symptoms != undefined && item.no_symptoms != "")
                            {
                                var ns3 = item.no_symptoms;
                            }
                            else
                            {
                                var ns3 = 0;
                            }
                            nosym3=parseInt(nosym3)+parseInt(ns3);
                            if(item.response_symptom != undefined && item.response_symptom != "")
                            {
                                var rs3 = item.response_symptom;
                            }
                            else
                            {
                                var rs3 = 0;
                            }
                            wsym3=parseInt(wsym3)+parseInt(rs3);
                            if(item.containment_zone != undefined && item.containment_zone != "")
                            {
                                var cz3 = item.containment_zone;
                            }
                            else
                            {
                                var cz3 = 0;
                            }
                            czn3=parseInt(czn3)+parseInt(cz3);
                            c3=c3+1
                        }
                        else
                        {
                            ksdata1.push(item)
                            // staff4=parseInt(staff4)+parseInt(item.manpower);
                            // resrec4=parseInt(resrec4)+parseInt(item.response_receive);
                            // resper4=parseInt(resper4)+(parseInt(item.response_percent));
                            // nosym4=parseInt(nosym4)+parseInt(item.no_symptoms);
                            // wsym4=parseInt(wsym4)+parseInt(item.response_symptom);
                            // czn4=parseInt(czn4)+parseInt(item.containment_zone);

                            if(item.manpower != undefined && item.manpower != "")
                            {
                                var mn4 = item.manpower
                            }
                            else{
                                var mn4 = 0;
                            }
                            staff4=parseInt(staff4)+parseInt(mn4);
                            if(item.response_receive != undefined && item.response_receive != "")
                            {
                                var rr4 = item.response_receive;
                            }
                            else
                            {
                                var rr4 = 0
                            }
                            resrec4=parseInt(resrec4)+parseInt(rr4);
                            if(item.response_percent != undefined && item.response_percent != "")
                            {
                                var rp4 = item.response_percent;
                            }
                            else
                            {
                                var rp4 = 0;
                            }
                            resper4=parseInt(resper4)+(parseInt(rp4));
                            if(item.no_symptoms != undefined && item.no_symptoms != "")
                            {
                                var ns4 = item.no_symptoms;
                            }
                            else
                            {
                                var ns4 = 0;
                            }
                            nosym4=parseInt(nosym4)+parseInt(ns4);
                            if(item.response_symptom != undefined && item.response_symptom != "")
                            {
                                var rs4 = item.response_symptom;
                            }
                            else
                            {
                                var rs4 = 0;
                            }
                            wsym4=parseInt(wsym4)+parseInt(rs4);
                            if(item.containment_zone != undefined && item.containment_zone != "")
                            {
                                var cz4 = item.containment_zone;
                            }
                            else
                            {
                                var cz4 = 0;
                            }
                            czn4=parseInt(czn4)+parseInt(cz4);

                            c4=c4+1
                            
                        }
                    }
                })
                if(welldata.length > 0)
                {
                    if(this.state.ismgnt == 1)
                    {
                        // console.log("resper1 ", c1)
                        // if(resper1 > 1)
                        // {
                        //     var rper1 = (100-resper1)
                        // }
                        // else{
                        //     var rper1 = 100
                        // }
                        var rper1 = (resper1/c1)
                        welldata.push({
                            "apptype_dept_update":"Total",
                            "manpower":staff1,
                            "response_receive":resrec1,
                            "response_percent":Math.round(rper1),
                            "no_symptoms":nosym1,
                            "response_symptom":wsym1,
                            "containment_zone":czn1
                        })
                    }
                    // console.log("welldata ", welldata)
                    this.setState({
                        rowData:welldata
                    })
                }
                else
                {
                    this.setState({
                        rowData:rowdata
                    })
                }
                if(ksdata.length > 0)
                {
                    if(this.state.ismgnt == 1)
                    {
                        // if(resper2 > 1)
                        // {
                        //     var rper2 = (100-resper2)
                        // }
                        // else{
                        //     var rper2 = 100
                        // }
                        var rper2 = (resper2/c2)
                        ksdata.push({
                            "apptype_dept_update":"Total",
                            "manpower":staff2,
                            "response_receive":resrec2,
                            "response_percent":Math.round(rper2),
                            "no_symptoms":nosym2,
                            "response_symptom":wsym2,
                            "containment_zone":czn2
                        })
                    }
                    this.setState({
                        rowData1:ksdata
                    })
                }
                else
                {
                    this.setState({
                        rowData1:rowdata1
                    })
                }
                // console.log("welldata1 ", welldata1)
                if(welldata1.length > 0)
                {
                    if(this.state.ismgnt == 1)
                    {
                        // if(resper3 > 1)
                        // {
                        //     var rper3 = (100-resper3)
                        // }
                        // else{
                        //     var rper3 = 100
                        // }
                        var rper3 = (resper3/c3)
                        welldata1.push({
                            "apptype_dept_update":"Total",
                            "manpower":staff3,
                            "response_receive":resrec3,
                            "response_percent":Math.round(rper3),
                            "no_symptoms":nosym3,
                            "response_symptom":wsym3,
                            "containment_zone":czn3
                        })
                    }
                    this.setState({
                        rowData2:welldata1
                    })
                }
                else
                {
                    this.setState({
                        rowData2:[]
                    })
                }
                if(ksdata1.length > 0)
                {
                    if(this.state.ismgnt == 1)
                    {
                        // if(resper4 > 1)
                        // {
                        //     var rper4 = (100-resper4)
                        // }
                        // else{
                        //     var rper4 = 100
                        // }
                        var rper4 = (resper4/c4)
                        ksdata1.push({
                            "apptype_dept_update":"Total",
                            "manpower":staff4,
                            "response_receive":resrec4,
                            "response_percent":Math.round(rper4),
                            "no_symptoms":nosym4,
                            "response_symptom":wsym4,
                            "containment_zone":czn4
                        })
                    }
                    this.setState({
                        rowData3:ksdata1
                    })
                }
                else
                {
                    this.setState({
                        rowData3:[]
                    })
                }
                
            }
            else
            {
                
                this.setState({
                    rowData:rowdata,
                    rowData1:rowdata1
                })
            }
        })
    }

	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}


    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}

    onGridReady1 = params => {
		this.gridApi1 = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi1 = params.columnApi;
	};
	

	onGridState1 = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState1 = this.gridColumnApi1.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState1 = this.gridColumnApi1.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns1 = this.gridColumnApi1.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState1 = this.gridApi1.getFilterModel();
		   this.gridApi1.setFilterModel(window.filterState1);
		   
		   
    }
    onGridReady2 = params => {
		this.gridApi2 = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi2 = params.columnApi;
	};
	

	onGridState2 = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState2 = this.gridColumnApi2.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState2 = this.gridColumnApi2.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns2 = this.gridColumnApi2.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState2 = this.gridApi2.getFilterModel();
		   this.gridApi2.setFilterModel(window.filterState2);
		   
		   
    }
    
    onGridReady3 = params => {
		this.gridApi3 = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi3 = params.columnApi;
	};
	

	onGridState3 = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState3 = this.gridColumnApi3.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState3 = this.gridColumnApi3.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns3 = this.gridColumnApi3.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState3 = this.gridApi3.getFilterModel();
		   this.gridApi3.setFilterModel(window.filterState3);
		   
		   
    }
    
    addNewRow(){
        const row = {
            "apptype":"wellness",
            "apptype_dept_update":"",
            "manpower":"",
            "response_receive":"",
            "response_percent":"",
            "no_symptoms":"",
            "response_symptom":"",
            "containment_zone":"",
            "remarks":""
        }
        if(this.state.rowData.length > 0)
        {
            var lastindex = this.state.rowData.length
        }
        else{
            var lastindex = 0
        }
        
        this.gridApi.insertItemsAtIndex(lastindex,[row])
       
    }
    
    addNewRow1(){
        const row = {
            "apptype":"kalyansathi",
            "apptype_dept_update":"",
            "manpower":"",
            "response_receive":"",
            "response_percent":"",
            "no_symptoms":"",
            "response_symptom":"",
            "containment_zone":"",
            "remarks":""
        }
        if(this.state.rowData1.length > 0)
        {
            var lastindex = this.state.rowData1.length
        }
        else{
            var lastindex = 0
        }
        this.gridApi1.insertItemsAtIndex(lastindex,[row])
       
    }
    removeRow(rownode){
        console.log("rownode ", rownode)
        const selectedRow = this.gridApi.getFocusedCell()
        const id = this.gridOptions.rowData[selectedRow.rowIndex].i

        this.gridOptions.rowData.splice(selectedRow.rowIndex, 1)
        this.gridApi.setRowData(this.gridOptions.rowData)
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            sliderTranslate:"",
        });
    }
    changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				
                { 
					name: 'apptype',
					inputName: 'apptype',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'apptype_dept_update',
					inputName: 'apptype_dept_update',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'manpower',
					inputName: 'manpower',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'response_receive',
					inputName: 'response_receive',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'response_percent',
					inputName: 'response_percent',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'no_symptoms',
					inputName: 'no_symptoms',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'response_symptom',
					inputName: 'response_symptom',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'containment_zone',
					inputName: 'containment_zone',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'remarks',
					inputName: 'remarks',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                
                
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				// console.log("contents ", contents )
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			// console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		{
		}
		else{
			e.target.value = null;
			this.setState({
				uploadFile:'',
				show: true, 
				basicType:'danger', 
				basicTitle:'Please upload file having extensions .csv only.',
			});
		}
		
	}


    onClickShowUploadForm(){
        this.setState({
            sliderTranslate:"slider-translate",
			overly:'show-m'
        })
    }

    
	uploadBulkFormHandler(event){
		event.preventDefault();
		
		if(this.state.file != '')
		{
			var csvdd = this.state.file;
			var formData = new FormData();
			formData.append('uploadFile',this.state.file);
			formData.append("userId", localStorage.getItem('userid'));
			formData.append("csvcontent",this.state.csvcontent);
			formData.append("dept_code",this.state.dept_code);
			var rparams = {
				uploadFile:this.state.file,
				userid:localStorage.getItem('userid'),
				email:localStorage.getItem('email'),
				username:localStorage.getItem('username'),
                csvcontent:this.state.csvcontent,
                dept_code:this.state.dept_code
			}
			this.setState({
				loadshow:'show-m'
            }); 
            // console.log("rparams ",rparams)
			redirectURL.post("/consignments/uploadbulkdivisionreport", rparams)
			.then(
				(response)=>{
                    if(response.data.status == "fail")
                    {
                        this.setState({
                            show:true,
                            basicTitle:"Department Code should be "+this.state.dept_code+" in uploaded file",
                            basicType:"danger"

                        })
                    }
                    else
                    {
                        document.getElementById("upform").reset();
                        this.setState({
                            show:true,
                            basicTitle:"Successfully uploaded file",
                            basicType:"success",
                            loadshow:"show-n",
                            overly:"show-n",
                            sliderTranslate:""
                        })
                        var parameters = {
                            dept_code:this.state.dept_code
                        }
                        this.onLoadShowData(parameters)
                    }
				}
			)
			.catch(function(error){
				console.log(error);
			});
		}
		
	}


    onClickUpdateItem(params){
        // console.log("update ", params.data)
        var parameters = {
            rowitem:params.data,
            userid:localStorage.getItem("userid"),
            email:localStorage.getItem("email"),
            username:localStorage.getItem("username"),
            dept_code:this.state.dept_code
        }
        redirectURL.post("/consignments/updatedivisionhealthitem",parameters)
        .then((response) => {
        //    console.log("Update res ", response.data)
            if(response.data.message == "Success")
            {
                this.setState({
                    show:true,
                    basicTitle:"Successfully update item",
                    basicType:"success"
                })
                var parameters = {
                    dept_code:this.state.dept_code
                }
                this.onLoadShowData(parameters)
            }
            else
            {
                this.setState({
                    show:true,
                    basicTitle:"Something went wrong",
                    basicType:"danger"
                })
            }
        })
    }

    onClickApproveData = (event) =>{
        event.preventDefault();

        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": googleAnalytics.page.action.formSubmitted
            }
            googleAnalytics.logEvent(eventOptions);
        }

        this.setState({
            loadshow:'show-m',
        });
        let records = [];
        this.gridApi.forEachNode(node => records.push(node.data));
        // console.log("records ", records)
        var parameters = {
            rowitems:records,
            userid:localStorage.getItem("userid"),
            email:localStorage.getItem("email"),
            username:localStorage.getItem("username"),
            dept_code:"SNDG"
        }
        redirectURL.post("/consignments/approvealldivisionhealthitems",parameters)
        .then((response) => {
            //console.log("ApproveAll res ", response.data)
            if(response.data.message == "Success")
            {
                this.setState({
                    show:true,
                    basicTitle:"Successfully approved items",
                    basicType:"success",
                    loadshow:"show-n",
                    overly:"show-n"
                })
                var parameters = {
                    dept_code:this.state.dept_code
                }
                this.onLoadShowData(parameters)
            }
            else
            {
                this.setState({
                    show:true,
                    basicTitle:"Something went wrong",
                    basicType:"danger",
                    loadshow:"show-n",
                    overly:"show-n"
                })
            }
        })
    }

    onClickApproveData1 = (event) =>{
        event.preventDefault();

        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": googleAnalytics.page.action.formSubmitted
            }
            googleAnalytics.logEvent(eventOptions);
        }

        this.setState({
            loadshow:'show-m',
        });
        let records = [];
        this.gridApi1.forEachNode(node => records.push(node.data));
        // console.log("records ", records)
        var parameters = {
            rowitems:records,
            userid:localStorage.getItem("userid"),
            email:localStorage.getItem("email"),
            username:localStorage.getItem("username"),
            dept_code:"SNDG"
        }
        redirectURL.post("/consignments/approvealldivisionhealthitems",parameters)
        .then((response) => {
            //console.log("ApproveAll res ", response.data)
            if(response.data.message == "Success")
            {
                this.setState({
                    show:true,
                    basicTitle:"Successfully approved items",
                    basicType:"success",
                    loadshow:"show-n",
                    overly:"show-n"
                })
                var parameters = {
                    dept_code:this.state.dept_code
                }
                this.onLoadShowData(parameters)
            }
            else
            {
                this.setState({
                    show:true,
                    basicTitle:"Something went wrong",
                    basicType:"danger",
                    loadshow:"show-n",
                    overly:"show-n"
                })
            }
        })
    }
    
    onClickApproveData2 = (event) =>{
        event.preventDefault();

        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": googleAnalytics.page.action.formSubmitted
            }
            googleAnalytics.logEvent(eventOptions);
        }

        this.setState({
            loadshow:'show-m',
        });
        let records = [];
        this.gridApi2.forEachNode(node => records.push(node.data));
        // console.log("records ", records)
        var parameters = {
            rowitems:records,
            userid:localStorage.getItem("userid"),
            email:localStorage.getItem("email"),
            username:localStorage.getItem("username"),
            dept_code:"LOG-PRT"
        }
        redirectURL.post("/consignments/approvealldivisionhealthitems",parameters)
        .then((response) => {
            //console.log("ApproveAll res ", response.data)
            if(response.data.message == "Success")
            {
                this.setState({
                    show:true,
                    basicTitle:"Successfully approved items",
                    basicType:"success",
                    loadshow:"show-n",
                    overly:"show-n"
                })
                var parameters = {
                    dept_code:this.state.dept_code
                }
                this.onLoadShowData(parameters)
            }
            else
            {
                this.setState({
                    show:true,
                    basicTitle:"Something went wrong",
                    basicType:"danger",
                    loadshow:"show-n",
                    overly:"show-n"
                })
            }
        })
    }
    
    onClickApproveData3 = (event) =>{
        event.preventDefault();

        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": googleAnalytics.page.action.formSubmitted
            }
            googleAnalytics.logEvent(eventOptions);
        }

        this.setState({
            loadshow:'show-m',
        });
        let records = [];
        this.gridApi3.forEachNode(node => records.push(node.data));
        // console.log("records ", records)
        var parameters = {
            rowitems:records,
            userid:localStorage.getItem("userid"),
            email:localStorage.getItem("email"),
            username:localStorage.getItem("username"),
            dept_code:"LOG-PRT"
        }
        redirectURL.post("/consignments/approvealldivisionhealthitems",parameters)
        .then((response) => {
            //console.log("ApproveAll res ", response.data)
            if(response.data.message == "Success")
            {
                this.setState({
                    show:true,
                    basicTitle:"Successfully approved items",
                    basicType:"success",
                    loadshow:"show-n",
                    overly:"show-n"
                })
                var parameters = {
                    dept_code:this.state.dept_code
                }
                this.onLoadShowData(parameters)
            }
            else
            {
                this.setState({
                    show:true,
                    basicTitle:"Something went wrong",
                    basicType:"danger",
                    loadshow:"show-n",
                    overly:"show-n"
                })
            }
        })
    }
    onClickApproveAllData = (event) =>{
        event.preventDefault();

        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": googleAnalytics.page.action.formSubmitted
            }
            googleAnalytics.logEvent(eventOptions);
        }

        this.setState({
            loadshow:'show-m',
        });
        let allrecords = [];
        this.gridApi.forEachNode(node => allrecords.push(node.data));
        this.gridApi1.forEachNode(node => allrecords.push(node.data));
        this.gridApi2.forEachNode(node => allrecords.push(node.data));
        this.gridApi3.forEachNode(node => allrecords.push(node.data));
        console.log("allrecords ", allrecords)
        var parameters = {
            rowitems:allrecords,
            userid:localStorage.getItem("userid"),
            email:localStorage.getItem("email"),
            username:localStorage.getItem("username"),
            dept_code:"LOG-PRT"
        }
        redirectURL.post("/consignments/approvealllogisticshealthitems",parameters)
        .then((response) => {
            //console.log("ApproveAll res ", response.data)
            if(response.data.message == "Success")
            {
                this.setState({
                    show:true,
                    basicTitle:"Successfully approved items",
                    basicType:"success",
                    loadshow:"show-n",
                    overly:"show-n"
                })
                var parameters = {
                    dept_code:this.state.dept_code
                }
                this.onLoadShowData(parameters)
            }
            else
            {
                this.setState({
                    show:true,
                    basicTitle:"Something went wrong",
                    basicType:"danger",
                    loadshow:"show-n",
                    overly:"show-n"
                })
            }
        })

    }
    resetUpload = () => {
		this.setState({
			sliderTranslate:'',
			showDiv:'show-n',
			overly:'show-n',
			file:''
		});
		document.getElementById("upform").reset();
    }
    onClickUpdateGrid1(){
        this.setState({
            loadshow:"show-m"
        })
        var selecteditems = []
        this.gridApi.forEachLeafNode( (node) => {
            //console.log("NODE ALL ", node)
            selecteditems.push(node.data)
        })
        // console.log("selecteditems ", selecteditems)
        var parameters = {
            rowitems : selecteditems,
            userid:localStorage.getItem("userid"),
            email:localStorage.getItem("email"),
            username:localStorage.getItem("username"),
            dept_code:this.state.dept_code
        }
        redirectURL.post("/consignments/updatealldivisionhealthdata",parameters)
        .then((response) => {
            console.log(response.data)
            if(response.data.status == "success")
            {
                this.setState({
                    show:true,
                    basicTitle:"Successfully update data",
                    basicType:"success",
                    loadshow:"show-n"
                })
            }
            else
            {
                this.setState({
                    show:true,
                    basicTitle:"Failed to update data",
                    basicType:"danger",
                    loadshow:"show-n"
                })
            }
        })
    }
    onClickUpdateGrid2(){
        this.setState({
            loadshow:"show-n"
        })
        var selecteditems = []
        this.gridApi1.forEachLeafNode( (node) => {
            //console.log("NODE ALL ", node)
            selecteditems.push(node.data)
        })
        // console.log("selecteditems ", selecteditems)
        var parameters = {
            rowitems : selecteditems,
            userid:localStorage.getItem("userid"),
            email:localStorage.getItem("email"),
            username:localStorage.getItem("username"),
            dept_code:this.state.dept_code
        }
        redirectURL.post("/consignments/updatealldivisionhealthdata",parameters)
        .then((response) => {
            console.log(response.data)
            if(response.data.status == "success")
            {
                this.setState({
                    show:true,
                    basicTitle:"Successfully update data",
                    basicType:"success",
                    loadshow:"show-n"
                })
            }
            else
            {
                this.setState({
                    show:true,
                    basicTitle:"Failed to update data",
                    basicType:"danger",
                    loadshow:"show-n"
                })
            }
        })
    }
    
    onClickUpdateGrid3(){
        this.setState({
            loadshow:"show-n"
        })
        var selecteditems = []
        this.gridApi2.forEachLeafNode( (node) => {
            //console.log("NODE ALL ", node)
            selecteditems.push(node.data)
        })
        // console.log("selecteditems ", selecteditems)
        var parameters = {
            rowitems : selecteditems,
            userid:localStorage.getItem("userid"),
            email:localStorage.getItem("email"),
            username:localStorage.getItem("username"),
            dept_code:this.state.dept_code
        }
        redirectURL.post("/consignments/updatealldivisionhealthdata",parameters)
        .then((response) => {
            console.log(response.data)
            if(response.data.status == "success")
            {
                this.setState({
                    show:true,
                    basicTitle:"Successfully update data",
                    basicType:"success",
                    loadshow:"show-n"
                })
            }
            else
            {
                this.setState({
                    show:true,
                    basicTitle:"Failed to update data",
                    basicType:"danger",
                    loadshow:"show-n"
                })
            }
        })
    }
    
    onClickUpdateGrid4(){
        this.setState({
            loadshow:"show-n"
        })
        var selecteditems = []
        this.gridApi3.forEachLeafNode( (node) => {
            //console.log("NODE ALL ", node)
            selecteditems.push(node.data)
        })
        // console.log("selecteditems ", selecteditems)
        var parameters = {
            rowitems : selecteditems,
            userid:localStorage.getItem("userid"),
            email:localStorage.getItem("email"),
            username:localStorage.getItem("username"),
            dept_code:this.state.dept_code
        }
        redirectURL.post("/consignments/updatealldivisionhealthdata",parameters)
        .then((response) => {
            console.log(response.data)
            if(response.data.status == "success")
            {
                this.setState({
                    show:true,
                    basicTitle:"Successfully update data",
                    basicType:"success",
                    loadshow:"show-n"
                })
            }
            else
            {
                this.setState({
                    show:true,
                    basicTitle:"Failed to update data",
                    basicType:"danger",
                    loadshow:"show-n"
                })
            }
        })
    }
    
    render(){
        var hideupdate = (this.state.ismgnt == 1)?true:false;
        var edite = (this.state.ismgnt == 0)?true:false;
        var columnwithDefs = [           
            {
                headerName : "Wellness Mitra App Update",
                field : "apptype_dept_update",
                width:120,
                editable : edite,
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
                
            },
            {
                headerName : "MSIL Staff",
                field : "manpower",
                width:110,
                editable : edite,
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            
            {
                headerName : "Response Received",
                field : "response_receive",
                width:100,
                editable : edite,
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            
            {
                headerName : "Response %",
                field : "response_percent",
                width:100,
                editable : false,
                valueGetter:function(params){
                    return params.data.response_percent;
                },
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            
            {
                headerName : "No Symptoms",
                field : "no_symptoms",
                width:100,
                editable : edite,
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            
            {
                headerName : "Response with Symptoms (any one)",
                field : "response_symptom",
                width:100,
                editable : false,
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            
            {
                headerName : "Containment Zone",
                field : "containment_zone",
                width:120,
                editable : edite,
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            
            {
                headerName : "Remarks",
                field : "remarks",
                width:240,
                editable : edite,
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            // {
            //     headerName : "action",
            //     field : "remarks",
            //     width:240,
            //     editable:false,
            //     hide:hideupdate,
            //     cellRendererSelector:function(params){
            //         return {component:"UpdateWellnessdata"}
            //     } 
            // }
        ]
        
        var columnwithDefs1 = [           
            {
                headerName : "Kalyan Sathi App Update ",
                field : "apptype_dept_update",
                width:120,
                editable : edite,
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            {
                headerName : "Outsourced Manpower",
                field : "manpower",
                width:110,
                editable : edite,
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            
            {
                headerName : "Response Received",
                field : "response_receive",
                width:100,
                editable : edite,
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            
            {
                headerName : "Response %",
                field : "response_percent",
                width:100,
                editable : false,
                valueGetter:function(params){
                    
                    return params.data.response_percent;
                    
                },
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            
            {
                headerName : "No Symptoms",
                field : "no_symptoms",
                width:100,
                editable : edite,
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            
            {
                headerName : "Response with Symptoms (any one)",
                field : "response_symptom",
                width:100,
                editable : false,
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            
            {
                headerName : "Containment Zone",
                field : "containment_zone",
                width:120,
                editable : edite,
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            
            {
                headerName : "Remarks",
                field : "remarks",
                width:240,
                editable : edite
            },
            // {
            //     headerName : "action",
            //     field : "",
            //     width:240,
            //     editable:false,
            //     hide:hideupdate,
            //     cellRendererSelector:function(params){
            //         return {component:"UpdateKalyansathidata"}
            //     } 
            // }
        ]

        var columnwithDefs2 = [           
            {
                headerName : "Wellness Mitra App Update",
                field : "apptype_dept_update",
                width:120,
                editable : edite,
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            {
                headerName : "MSIL Staff",
                field : "manpower",
                width:110,
                editable : edite,
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            
            {
                headerName : "Response Received",
                field : "response_receive",
                width:100,
                editable : edite,
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            
            {
                headerName : "Response %",
                field : "response_percent",
                width:100,
                editable : false,
                valueGetter:function(params){
                    return params.data.response_percent;
                },
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            
            {
                headerName : "No Symptoms",
                field : "no_symptoms",
                width:100,
                editable : edite,
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            
            {
                headerName : "Response with Symptoms (any one)",
                field : "response_symptom",
                width:100,
                editable : false,
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            
            {
                headerName : "Containment Zone",
                field : "containment_zone",
                width:120,
                editable : edite,
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            
            {
                headerName : "Remarks",
                field : "remarks",
                width:240,
                editable : edite,
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            // {
            //     headerName : "action",
            //     field : "",
            //     width:140,
            //     editable:edite,
            //     hide:hideupdate,
            //     cellRendererSelector:function(params){
            //         return {component:"UpdateWellnessdata"}
            //     } 
            // }
        ]
        
        var columnwithDefs3 = [           
            {
                headerName : "Kalyan Sathi App Update ",
                field : "apptype_dept_update",
                width:120,
                editable : edite,
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            {
                headerName : "Outsourced Manpower",
                field : "manpower",
                width:110,
                editable : edite,
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            
            {
                headerName : "Response Received",
                field : "response_receive",
                width:100,
                editable : edite,
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            
            {
                headerName : "Response %",
                field : "response_percent",
                width:100,
                editable : false,
                valueGetter:function(params){
                    return params.data.response_percent;
                },
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            
            {
                headerName : "No Symptoms",
                field : "no_symptoms",
                width:100,
                editable : edite,
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            
            {
                headerName : "Response with Symptoms (any one)",
                field : "response_symptom",
                width:100,
                editable : false,
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            
            {
                headerName : "Containment Zone",
                field : "containment_zone",
                width:120,
                editable : edite,
                cellClass:function(params)
                {
                    if(params.data.apptype_dept_update == "Total")
                    {
                        return "fbold";
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            
            {
                headerName : "Remarks",
                field : "remarks",
                width:240,
                editable : edite
            },
            
            // {
            //     headerName : "action",
            //     field : "",
            //     width:140,
            //     editable:false,
            //     hide:hideupdate,
            //     cellRendererSelector:function(params){
            //         return {component:"UpdateKalyansathidata"}
            //     } 
            // }
        ]
        return (
            <div className="container-fluid" style={(this.state.screenView == "Mobile")?{marginTop: "2em"}:{}}>
				<SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
	        </SweetAlert>
	            {this.state.alert}
				<div className="row">
					<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span> {this.state.pageTitle} </span>
                                    {(this.state.ismgnt == 0)?
                                    <span className="">
                                        {/* <button className="float-right custom-btn f14 white btn-secondary ml-10p" onClick={this.addNewRow}>
                                            <i className="icofont icofont-plus"></i> Add New Row
                                        </button> */}
                                        <button className="float-right custom-btn f14 white btn-danger" onClick={this.onClickShowUploadForm.bind(this)}>
                                            <i className="icofont icofont-upload"></i> Bulk Upload
                                        </button>
                                    </span>
                                    :""}

                                    {(this.state.ismgnt == 1 && this.state.screenView != "Mobile")?
                                        <span className="float-right custom-btn f14 "> 
                                            <a className="btn btn-danger" style={{float:"left",marginLeft:"10%"}} href="/managementdashboard">Back</a>
                                        </span>
                                    :""}
                                    
                                    
                                </h5>
				   			</div>				   			
		            		<div className="card-body">                                
                                <div className="row">								    
									<div className={"dataLoadpage " +(this.state.loadshow)}></div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box">
                                            <div class="loader-box">
                                                <div class="rotate dashed colored"></div>
                                            </div>
                                        </div>
									</div>
                                    
                                    <div className="col-xl-12 col-lg-12">
                                        {/* {(this.state.ismgnt == 1 && this.state.last_updated_on != "")? */}
                                            <span className="float-right custom-btn f14 "> 
                                                Last Update On: {this.state.last_updated_on}
                                            </span>
                                    {/* :""} */}
                                    </div>
                                    {(this.state.ismgnt == 0)?
                                    <div className="col-xl-12 col-lx-12" style={{textAlign:"right"}}>
                                        <button name="submit" onClick={this.onClickUpdateGrid1.bind(this)} className="btn btn-warning">Update All</button>
                                    </div>
                                    :""}
                                    <div className="col-xl-12 col-lg-12">
                                   
                                        <div id="myGrid" style={{ height: "300px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnwithDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={false}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                enableRangeSelection={true}
                                                components={this.state.components}
                                                rowSelection={this.state.rowSelection}
                                                suppressRowClickSelection={true}
                                                frameworkComponents={this.state.frameworkComponents}
                                                groupDefaultExpanded={this.state.groupDefaultExpanded}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                headerHeight={this.state.headerHeight}
                                            />
                                        </div>
                                    </div>
                                    
                                    {/* {(this.state.ismgnt == 0)?
                                    <div className="col-xl-12 col-lg-12 mt-20p">
                                        <button className="float-right custom-btn f14 white btn-secondary" onClick={this.addNewRow1}>
                                            <i className="icofont icofont-plus"></i> Add New Row
                                        </button>
                                    </div>
                                    :""} */}
                                    {(this.state.ismgnt == 0)?
                                    <div className="col-xl-12 col-lx-12 mt-20p" style={{textAlign:"right"}}>
                                        <button name="submit" onClick={this.onClickUpdateGrid2.bind(this)} className="btn btn-warning">Update All</button>
                                    </div>
                                    :""}
                                    <div className="col-xl-12 col-lg-12">
                                        
                                        <div id="myGrid1" style={{ height: "300px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnwithDefs1}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData1}
                                                enableCharts={false}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady1}
                                                onGridState={this.onGridState1}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                enableRangeSelection={true}
                                                components={this.state.components}
                                                rowSelection={this.state.rowSelection}
                                                suppressRowClickSelection={true}
                                                frameworkComponents={this.state.frameworkComponents1}
                                                groupDefaultExpanded={this.state.groupDefaultExpanded}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                headerHeight={this.state.headerHeight}
                                            />
                                        </div>
                                    </div>
                                    
                                    {/* {(this.state.ismgnt == 0)?
                                    <div className="col-xl-12 col-lx-12" style={{textAlign:"right"}}>
                                        <button name="submit" onClick={this.onClickApproveData1.bind(this)} className="btn btn-success">Approve</button>
                                    </div>
                                    :""} */}
                                    <div className="col-xl-12 col-lg-12">
                                        <h5>
                                            <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span> PPS Division Health Report </span>
                                            {(this.state.ismgnt == 0)?
                                                <button name="submit" onClick={this.onClickUpdateGrid3.bind(this)} className="btn btn-warning float-right">Update All</button>
                                            :""}
                                        </h5>
                                    </div>
                                    
                                    <div className="col-xl-12 col-lg-12">
                                        <div id="myGrid2" style={{ height: "300px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnwithDefs2}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData2}
                                                enableCharts={false}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady2}
                                                onGridState={this.onGridState2}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                enableRangeSelection={true}
                                                components={this.state.components}
                                                rowSelection={this.state.rowSelection}
                                                suppressRowClickSelection={true}
                                                frameworkComponents={this.state.frameworkComponents}
                                                groupDefaultExpanded={this.state.groupDefaultExpanded}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                headerHeight={this.state.headerHeight}
                                            />
                                        </div>
                                        
                                    </div>
                                    <div className="col-xl-12 col-lg-12">
                                        {(this.state.ismgnt == 0)?                                            
                                            <button name="submit" onClick={this.onClickUpdateGrid4.bind(this)} className="btn btn-warning float-right">Update All</button>
                                        :""}
                                    </div>
                                    <div className="col-xl-12 col-lg-12">
                                        <div id="myGrid3" style={{ height: "300px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnwithDefs3}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData3}
                                                enableCharts={false}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady3}
                                                onGridState={this.onGridState3}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                enableRangeSelection={true}
                                                components={this.state.components}
                                                rowSelection={this.state.rowSelection}
                                                suppressRowClickSelection={true}
                                                frameworkComponents={this.state.frameworkComponents1}
                                                groupDefaultExpanded={this.state.groupDefaultExpanded}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                headerHeight={this.state.headerHeight}
                                            />
                                        </div>
                                        {/* {(this.state.ismgnt == 0)?
                                            <div className="col-xl-12 col-lx-12" style={{textAlign:"right"}}>
                                                <button name="submit" onClick={this.onClickApproveData3.bind(this)} className="btn btn-success">Approve</button>
                                            </div>
                                            :""} */}
                                        <div className="row">
                                            <div className="col-xl-12 col-lx-12" style={{textAlign:"right"}}>
                                            
                                            {(this.state.ismgnt == 0)?
                                            
                                                <button name="submit" onClick={this.onClickApproveAllData.bind(this)} className="btn btn-success float-right">Approve All</button>
                                            
                                            :""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
				   			</div>
				   		</div>	
				   	</div>
					   
				 </div>
				{/* Bulk Assign Excel Upload */}
				<div className={"slide-r "+(this.state.sliderTranslate)} style={{overflow:"hidden"}}>
					<h3 className="subH">Bulk Upload</h3>
						 	
					<div className="slide-r-body" style={{position:"relative"}}>
						
						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>

							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
							</div>
							<div className="form-group">
								<button type="submit" className="btn btn-success">Submit</button>
								<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
							</div>
							</form>
							
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/sample_division_health_bulk_upload.csv')} target="_blank">Sample Template</a>
							
							</div>		
						</div>
					</div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        );
  }
}

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS } from '../common/utils';

import CountUp from 'react-countup';
import redirectURL from '../redirectURL';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");


export default class MLLDeviceDashboard extends Component{
    constructor(props) {
        super(props);
        this.state={
            modules: AllModules,
            // truckData:this.props.truckInfo,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            
            truckNumList:"",
            loadshow:'show-n',
            truckLocation:"",
            replaced_date_time:"",
            route_details:"",
            rowData:"",
            showTrucksList:"show-n",
            modules:AllModules,
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
            route_info:"",
			rowData: null,
			animateRows: true,
            consignment_details:"",
            dept_code:"",
            replacement_truck:"",
            pagetitle:"",
            showonlytransport:'show-m',
            reason:'',
            overly :"show-n",
            filteredData : [],
            sourceplants:[],
            sources:[],
            totaldevices:0,
            assignloadingyard:0,
            handovers:0,
            rakedevices:0,
            issueba:0,
            rsoretrivals:0,
            totaldevicesarr:[],
            intransitloading:0,
            atloading:0,
            handoversarr:[],
            rakedevicesarr:[],
            issuebaarr:[],
            rsoretrivalsarr:[],
            atplant:0,
            atplantarr:[],
            retrivedloading : 0,
            intransitplant : 0,
            atdestination : 0,
            retriveddest : 0,
            intransitrso : 0,
            intransitdealer : 0,
            atrso : 0,
            retrivedloadingarr : [],
            intransitloadingarr:[],
            atloadingarr:[],
            intransitplantarr : [],
            atdestinationarr : [],
            retriveddestarr : [],
            intransitrsoarr : [],
            intransitdealerarr : [],
            atrsoarr : [],
            shiptosource:0,
            shiptosourcearr:[]
        };
        this.onLoadData = this.onLoadData.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        this.setState({
            //overly : "show-m",
            loadshow:'show-m',
        })
        //$('#replacement-submit').prop("disabled",true);
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        var pagetitle="GPS Device Dashboard "
        var dept_code = "SNDG";
        var israil=0;
       
       
        this.setState({
            
            eventAction:eventAction,
            dept_code:dept_code,
            pagetitle:pagetitle
        });
      
       this.onLoadData()

    }
    onLoadData(){
        redirectURL.post("/mllconsignments/mllalldeviceconsignments")
        .then((response) => {
            //console.log("recp ", response.data)
            var records = response.data.records
            console.log("records ", records)
            var intransitloading = []
            var handovers = []
            var rakedevices = []
            var issueba = []
            var rsoretrivals = []
            var atloading =[]
            var atplant = []
            var retrivedloading = []
            var intransitplant = []
            var atdestination = []
            var retriveddest = []
            var intransitrso = []
            var intransitdealer = []
            var atrso = []
            var atloading = []
            var shiptosource = []
            if(records.length > 0)
            {
                records.map((item) => {
                    
                    if(parseInt(item.status) == 1)
                    {
                        atplant.push(item)
                    }
                    if(parseInt(item.status) == 2)
                    {
                        intransitloading.push(item)
                    }
                    if(parseInt(item.status) == 3)
                    {
                        atloading.push(item)
                    }
                    if(parseInt(item.status) == 4)
                    {
                        retrivedloading.push(item)
                    }
                    if(parseInt(item.status) == 5)
                    {
                        intransitplant.push(item)
                    }
                    if(parseInt(item.status) == 7)
                    {
                        rakedevices.push(item)
                    }
                    if(parseInt(item.status) == 8)
                    {
                        atdestination.push(item)
                    }
                    if(parseInt(item.status) == 9)
                    {
                        retriveddest.push(item)
                    }
                    if(parseInt(item.status) == 10)
                    {
                        issueba.push(item)
                    }
                    
                    if(parseInt(item.status) == 11 || parseInt(item.status) == 12)
                    {
                        intransitrso.push(item)
                    }
                    // if(parseInt(item.status) == 12)
                    // {
                    //     intransitdealer.push(item)
                    // }
                    if(parseInt(item.status) == 13)
                    {
                        atrso.push(item)
                    }
                    if(parseInt(item.status) == 14)
                    {
                        rsoretrivals.push(item)
                    }
                    if(parseInt(item.status) == 15)
                    {
                        shiptosource.push(item)
                    }
                })
            }
            this.setState({
                rowData:records,
                totaldevices:records.length,
                totaldevicesarr:records,
                atplant:atplant.length,
                atplantarr:atplant,
                intransitloading:intransitloading.length,
                intransitloadingarr:intransitloading,
                atloading:atloading.length,
                atloadingarr:atloading,
                handovers:handovers.length,
                handoversarr:handovers,
                rakedevices:rakedevices.length,
                rakedevicesarr:rakedevices,
                issueba:issueba.length,
                issuebaarr:issueba,
                rsoretrivals:rsoretrivals.length,
                rsoretrivalsarr:rsoretrivals,
                retrivedloading:retrivedloading.length,
                retrivedloadingarr:retrivedloading,
                intransitplant:intransitplant.length,
                intransitplantarr:intransitplant,
                atdestination:atdestination.length,
                atdestinationarr:atdestination,
                retriveddest:retriveddest.length,
                retriveddestarr:retriveddest,
                intransitrso:intransitrso.length,
                intransitrsoarr:intransitrso,
                intransitdealer:intransitdealer.length,
                intransitdealerarr:intransitdealer,
                atrso:atrso.length,
                atrsoarr:atrso,
                shiptosource:shiptosource.length,
                shiptosourcearr:shiptosource,
                overly:"show-n",
                loadshow:"show-n"
            })
        })
    }

    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
	}
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			showTrucksList : "show-n",
        });
        
    }

    
    onClickCounter(data)
	{
		if(data == "all")
		{
			this.setState({
				rowData:this.state.totaldevicesarr
			})
        }
        if(data == "atplant")
        {
            this.setState({
                rowData: this.state.atplantarr
            })
        }
		if(data == "intransitloading")
		{
			this.setState({
				rowData:this.state.intransitloadingarr
			})
        }
		if(data == "atloading")
		{
			this.setState({
				rowData:this.state.atloadingarr
			})
        }
        
		if(data == "retrivedloading")
		{
			this.setState({
				rowData:this.state.retrivedloadingarr
			})
        }
		if(data == "intransitplant")
		{
			this.setState({
				rowData:this.state.intransitplantarr
			})
        }
		if(data == "atdestination")
		{
			this.setState({
				rowData:this.state.atdestinationarr
			})
        }
        
		if(data == "rakedevices")
		{
			this.setState({
				rowData:this.state.rakedevicesarr
			})
		}
		if(data == "retriveddest")
		{
			this.setState({
				rowData:this.state.retriveddestarr
			})
		}
		if(data == "issueba")
		{
			this.setState({
				rowData:this.state.issuebaarr
			})
		}
		if(data == "intransitrso")
		{
			this.setState({
				rowData:this.state.intransitrsoarr
			})
		}
		if(data == "atrso")
		{
			this.setState({
				rowData:this.state.atrsoarr
			})
		}
		if(data == "rsoretrivals")
		{
			this.setState({
				rowData:this.state.rsoretrivalsarr
			})
		}
        if(data == "shiptosource")
        {
            this.setState({
				rowData:this.state.shiptosourcearr
			})
        }
	}


    render(){
        const columnwithDefs = [
            {
                headerName: "Device ID",
                field: "device_code",
                width:160,
                filter: true,
                resizable: true,
                editable:false,
                cellClass:function(params){
                    try{
                        
                        if(params.data.last_data_received_on != undefined && params.data.last_data_received_on != "")
                        {
                            var s1 = moment.parseZone().format("x")
                            var s2 = moment.parseZone(params.data.last_data_received_on).format("x")
                            // console.log("S1 ", s1)
                            // console.log("S2 ", s2)
                            var convertosecs = (s1-s2)/1000
                            var hours = Math.floor(convertosecs / 60 / 60);
                            // console.log("hours ", hours)
                            if(hours > 24)
                            {
                                return "bgColorDangerMedium";
                            }
                            else
                            {
                                return "";
                            }
                        }
                        else
                        {
                            return "bgColorDangerMedium";
                        }
                        
                        
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName: "Status",
                field: "status",
                width:180,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    if(parseInt(params.data.status) == 1)
                    {
                        return "Received At Plant";
                    }
                    if(parseInt(params.data.status) == 2)
                    {
                        return "In Transit to Loading Yard";
                    }
                    if(parseInt(params.data.status) == 3)
                    {
                        return "At Loading Yard";
                    }
                    if(parseInt(params.data.status) == 4)
                    {
                        return "Retrieved at Loading Yard";
                    }
                    if(parseInt(params.data.status) == 5)
                    {
                        return "In Transit to Plant";
                    }
                    // if(parseInt(params.data.status) == 6)
                    // {
                    //     return "In Rake";
                    // }
                    if(parseInt(params.data.status) == 7)
                    {
                        return "In Rake";
                    }
                    if(parseInt(params.data.status) == 8)
                    {
                        return "At Destination Yard";
                    }
                    if(parseInt(params.data.status) == 9)
                    {
                        return "Retrieved at Destination Yard";
                    }
                    if(parseInt(params.data.status) == 10)
                    {
                        return "Shipped To RSO";
                    }
                    if(parseInt(params.data.status) == 11)
                    {
                        return "In Transit to RSO";
                    }
                    if(parseInt(params.data.status) == 12)
                    {
                        return "In Transit to Dealer";
                    }
                    if(parseInt(params.data.status) == 13)
                    {
                        return "At RSO";
                    }
                    if(parseInt(params.data.status) == 14)
                    {
                        return "Retrieved at RSO";
                    }
                    if(parseInt(params.data.status) == 15)
                    {
                        return "Shipped To Source From RSO";
                    }
                },
                cellClass:function(params){
                    try{
                        if(params.data.last_data_received_on != undefined && params.data.last_data_received_on != "")
                        {
                            var s1 = moment.parseZone().format("x")
                            var s2 = moment.parseZone(params.data.last_data_received_on).format("x")
                            // console.log("S1 ", s1)
                            // console.log("S2 ", s2)
                            var convertosecs = (s1-s2)/1000
                            var hours = Math.floor(convertosecs / 60 / 60);
                            // console.log("hours ", hours)
                            if(hours > 24)
                            {
                                return "bgColorDangerMedium";
                            }
                            else
                            {
                                return "";
                            }
                        }
                        else
                        {
                            return "bgColorDangerMedium";
                        }
                        
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName: "Location",
                field: "status",
                width:180,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    try{
                        if(parseInt(params.data.status) == 1)
                        {
                            return "Nasik"
                        }
                        else if(parseInt(params.data.status) == 13)
                        {
                            return "Guwahati";
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                },
                cellClass:function(params){
                    try{
                        if(params.data.last_data_received_on != undefined && params.data.last_data_received_on != "")
                        {
                            var s1 = moment.parseZone().format("x")
                            var s2 = moment.parseZone(params.data.last_data_received_on).format("x")
                            // console.log("S1 ", s1)
                            // console.log("S2 ", s2)
                            var convertosecs = (s1-s2)/1000
                            var hours = Math.floor(convertosecs / 60 / 60);
                            // console.log("hours ", hours)
                            if(hours > 24)
                            {
                                return "bgColorDangerMedium";
                            }
                            else
                            {
                                return "";
                            }
                        }
                        else
                        {
                            return "bgColorDangerMedium";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName: "Device Owner",
                field: "device_owner",
                width:180,
                filter: true,
                resizable: true,
                editable:false,
                cellClass:function(params){
                    try{
                        if(params.data.last_data_received_on != undefined && params.data.last_data_received_on != "")
                        {
                            var s1 = moment.parseZone().format("x")
                            var s2 = moment.parseZone(params.data.last_data_received_on).format("x")
                            // console.log("S1 ", s1)
                            // console.log("S2 ", s2)
                            var convertosecs = (s1-s2)/1000
                            var hours = Math.floor(convertosecs / 60 / 60);
                            // console.log("hours ", hours)
                            if(hours > 24)
                            {
                                return "bgColorDangerMedium";
                            }
                            else
                            {
                                return "";
                            }
                        }
                        else
                        {
                            return "bgColorDangerMedium";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            // 
            {
                headerName: "Last Date Received On",
                field: "last_data_received_on",
                width:180,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    try{
                        if(params.data.last_data_received_on != "" && params.data.last_data_received_on != undefined)
                        {
                            return getHyphenDDMMYYYYHHMMSS(params.data.last_data_received_on);
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                },
                cellClass:function(params){
                    try{
                        if(params.data.last_data_received_on != undefined && params.data.last_data_received_on != "")
                        {
                            var s1 = moment.parseZone().format("x")
                            var s2 = moment.parseZone(params.data.last_data_received_on).format("x")
                            // console.log("S1 ", s1)
                            // console.log("S2 ", s2)
                            var convertosecs = (s1-s2)/1000
                            var hours = Math.floor(convertosecs / 60 / 60);
                            // console.log("hours ", hours)
                            if(hours > 24)
                            {
                                return "bgColorDangerMedium";
                            }
                            else
                            {
                                return "";
                            }
                        }
                        else
                        {
                            return "bgColorDangerMedium";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
        ]
        var listData = [] 
        try{
            this.state.filteredData.map(function(e){
                listData.push(<li key={e} className="dropdown-truck-no">{e}</li>)
                })
        }catch(e){}
        
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-sm-12 beffect n-p-0">
						<div className="card">
							<div className="card-body" id="counterCardBody">
								<div className="crm-numbers pb-0">
									<div className="row">
										<div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"all")}>
											<span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Total Devices<br /><br /><br /></span>
											<h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.totaldevices != '')?this.state.totaldevices:0}/></span></h4>
										</div>
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"atplant")}>
											<span className="f13"><i className="icofont icofont-ui-home txt-primary f24"></i><br /> Received At Plant<br /><br /><br /></span>
											<h4 className="txt-primary f40"><span className="counter"><CountUp end={(this.state.atplant != '')?this.state.atplant:0}/></span></h4>
											
										</div>
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"intransitloading")}>
											<span className="f13"><i className="icofont icofont-truck-alt txt-danger f22"></i><br />In Transit to Loading Yard <br /></span>
											<h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.intransitloading != '')?this.state.intransitloading:0}/></span></h4>
											
										</div>
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"atloading")}>
											<span className="f13"><i className="icofont icofont-clock-time f22 txt-secondary"></i><br /> At Loading Yard<br /><br /></span>
											<h4 className="txt-secondary f40"><span className="counter"><CountUp end={(this.state.atloading != '')?this.state.atloading:0}/></span></h4>
											
										</div>
                                        
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"retrivedloading")}>
											<span className="f13"><i className="icofont icofont-ui-home txt-primary f24"></i><br /> Retrieved at Loading Yard<br /></span>
											<h4 className="txt-primary f40"><span className="counter"><CountUp end={(this.state.retrivedloading != '')?this.state.retrivedloading:0}/></span></h4>
											
										</div>
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"intransitplant")}>
											<span className="f13"><i className="icofont icofont-fast-delivery f22 txt-info"></i><br /> In Transit to Plant<br /><br /><br /></span>
											<h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.intransitplant != '')?this.state.intransitplant:0}/></span></h4>
											
										</div>
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"rakedevices")}>
											<span className="f13"><i className="icofont icofont-fast-delivery f22 txt-warning"></i><br /> In Rake<br /><br /><br /><br /></span>
											<h4 className="txt-warning f40"><span className="counter"><CountUp end={(this.state.rakedevices != '')?this.state.rakedevices:0}/></span></h4>
											
										</div>
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"atdestination")}>
											<span className="f13"><i className="icofont icofont-fast-delivery f22 txt-danger"></i><br /> At Destination Yard<br /><br /></span>
											<h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.atdestination != '')?this.state.atdestination:0}/></span></h4>
											
										</div>
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"retriveddest")}>
											<span className="f13"><i className="icofont icofont-fast-delivery f22 txt-danger"></i><br /> Retrieved at Destination Yard</span>
											<h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.retriveddest != '')?this.state.retriveddest:0}/></span></h4>
											
										</div>
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"issueba")}>
											<span className="f13"><i className="icofont icofont-toy-train f22 txt-success"></i><br /> Shipped To RSO <br /><br /><br /></span>
											<h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.issueba != '')?this.state.issueba:0}/></span></h4>
										
										</div>
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"intransitrso")}>
											<span className="f13"><i className="icofont icofont-toy-train f22 txt-primary"></i><br /> In Transit to RSO/Dealer<br /><br /> </span>
											<h4 className="txt-primary f40"><span className="counter"><CountUp end={(this.state.intransitrso != '')?this.state.intransitrso:0}/></span></h4>
										
										</div>
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"atrso")}>
											<span className="f13"><i className="icofont icofont-toy-train f22 txt-info"></i><br /> At RSO<br /><br /><br /> <br /></span>
											<h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.atrso != '')?this.state.atrso:0}/></span></h4>
										
										</div>
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"rsoretrivals")}>
											<span className="f13"><i className="icofont icofont-ui-home txt-warning f24"></i><br /> Retrieved at RSO<br /><br /><br /></span>
											<h4 className="txt-warning f40"><span className="counter"><CountUp end={(this.state.rsoretrivals != '')?this.state.rsoretrivals:0}/></span></h4>
											
										</div>
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"shiptosource")}>
											<span className="f13"><i className="icofont icofont-fast-delivery f22 txt-danger"></i><br /> Shipped To Source <br /><br /></span>
											<h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.shiptosource != '')?this.state.shiptosource:0}/></span></h4>
											
										</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=" col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
                                </h5>
                            </div>
                            <div className="card-body">
                                <div className="col-xl-12 col-lg-12">
                                {/* {this.state.rowData != null ? */}
                                    <div id="myGrid" style={{width:"100%",height:"360px"}} className="ag-theme-balham">    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        rowSelection={this.state.rowSelection}
                                        // frameworkComponents={this.state.frameworkComponents}
                                        // detailCellRendererParams={this.state.detailCellRendererParams}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        //floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        // rowClassRules={this.state.rowClassRules}
                                        //onCellClicked={this.onRowClicked.bind(this)}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        suppressRowClickSelection={true}
                                        />

                                    </div>
                                    
                                    <div className="col-xl-12 col-lg-12">
                                        <b>Note:</b> Devices that did not transmit data in the last 24 hours are highlighted in red.
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <form method="POST" className="form-theme col-xl-12 col-lg-12 row" onSubmit={this.getTruckConsignments.bind(this)}>
                       
                            <div className={"trucks-dropdown "+this.state.showTrucksList}>
                            <ul>
                                {listData}
                            </ul>
                         </div>
                         <div className="col-xl-3 col-lg-3 form-group">
								<input type="text" name="truck_no" id="inputTruck"  placeholder="Truck No" autoComplete="off" className="form-control" onFocus={this.initalList.bind(this)} onChange={this.handlerForm.bind(this)} />
	                    </div>
                        <div className="col-xl-2 col-lg-2">
                            <button type="submit" className="btn btn-success">Get Data</button>
                        </div>
                    </form> */}
                </div> 
                
                
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                    </div>
                    <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                        <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                    </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        )
    }
}

        
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}
$(document).ready(function(){
    $(document).on("click",".dropdown-truck-no",function(){
        var vals = $(this).text();
         $("#inputTruck").val(vals);
         $(".trucks-dropdown").removeClass("show-m");
         $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click",function(e){
        var container = $("#inputTruck");
        if(!container.is(e.target))
        {
            try{
                if($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown")
                {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            }catch(e){}
            
        }
        
        
    })
})


var todaydateis = moment.parseZone().format("YYYY/MM/DD")

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // console.log("todaydateis ",todaydateis)
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        minDate:todaydateis
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules, AllEnterpriseModules } from '@ag-grid-enterprise/all-modules';
// import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import CSVFileValidator from 'csv-file-validator';
import Map from '../common/drawmap';
import FileBase64 from 'react-file-base64';
import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import MapComponent from './mapLocationComponent';
import Consignmentforceclose from '../manage/consignmentforceclose';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import fastForward from 'material-ui/svg-icons/av/fast-forward';
import m from 'highcharts-drilldown';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var childJson = []

export default class OneTimeContainerBookingFrom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            manualInco:false,
            basicTitle: '',
            basicType: "default",
            csvcontent: "",
            file: "",
            loadshow: 'show-n',
            overly: 'show-n',
            uploadYard: "",
            uploadDispatchDate: "",
            rowData: "",
            rowSelection: 'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize: 50,
            geofencelist: [],
            geoFenceData: "",
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            dept_code: "",
            bulkslide: "",
            date: "",
            deptName: "",
            indenterName: "",
            mobile: "",
            indenterEmail: "",
            erpPO: "",
            erpPoNo: [],
            erpPoDate: [],
            ringiNo: "",
            ringiDesc: "",
            cargoDeliveryPlant: "",
            itemDesc: "",
            grossWt: "",
            dimensions: "",
            cargo_chargeable_weight: "",
            supplierName: "",
            supplierAddr: "",
            supplierCnctPerson: "",
            country: "",
            emailID: "",
            phone: "",
            deliveryTerms: "Ex Works",
            pickUpAddr: "",
            cargoDate: "",
            LC: "",
            LCno: "",
            nature: "",
            freeTrade: "",
            benefitCert: "",
            disableERP: "disabled",
            disableLC: "disabled",
            deliveryLabel: "Pick Up Address",
            airportName: "",
            port: "",
            erpPo_date: "",
            cargo_date: "",
            container_type: "",
            container_size: [],
            dispatch_location: "",
            dispatch_port: "",
            destination_port: "",
            other_destination_port: "",
            container_required: "",
            incoterms: "",
            certificateOfOrigin: "",
            fta: "",
            exportIncentive: "",
            payment_terms: "",
            file1: "",
            file2: "",
            file3: "",
            file4: "",
            rows: [{}],
            ringirows: [{}],
            packetrows: [{}],
            brequest: "activet",
            prepaid: "",
            track: "",
            requestData: "show-m",
            trackData: "show-n",
            trackResData: 'show-n',
            ffsuppliers: [],
            requestFields: "show-m",
            prepaidFields: "show-n",
            addresstxt: "",
            request_type: 1,
            pagetitle: "OneTime Container Booking",
            disableBng: "disabled",
            lspallocationdetails: [],
            showUpopts: "",
            showUpload: "show-n",
            selected_country:"",
            filteredincoterms:[],
			filteredispatchLocations: [],
            filteredestinationPort:[],
            filteredCountries:[
                {label:"Japan",value:"Japan"},
                {label:"China",value:"China"},
                {label:"Ghana",value:"Ghana"},
                {label:"Hungary",value:"Hungary"},
                {label:"Indonesia",value:"Indonesia"},
                {label:"Myanmar",value:"Myanmar"},
                {label:"Nigeria",value:"Nigeria"},
                {label:"Thailand",value:"Thailand"},
                {label:"Vietnam",value:"Vietnam"}
            ]
            //components : {datepicker:getDatePicker()}
        }
        this.packetsHandler = this.packetsHandler.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                var eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                var eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                var eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    async componentDidMount() {
        this.logPageView(); // Log page view to GA
        loadDateTimeScript();
        var pagetitle = "One Time Container Booking";
        $(".requestFields").attr("required", true);
        this.setState({
            pagetitle: pagetitle,
        })
        var d = moment.parseZone(new Date()).utcOffset("+05:30")._d;
        var date = moment.parseZone(d).format("DD-MM-YYYY");
        $("#dateField").val(date);
        redirectURL.post("/consignments/fetchLspDetailsData")
            .then((response) => {
                var records = response.data.data
                if (records.length > 0) {
                    this.setState({
                        lspallocationdetails: records,
                    })
                }
            })
        await redirectURL.post("/consignments/getLspDetailsforexports").then((response)=>{
            // console.log(response.data,"216")
            var destinationPorts = response.data.destinationPort;
				var filteredestinationPort = [];
				if (destinationPorts.length > 0) {
					destinationPorts.map(function (cl) {
						filteredestinationPort.push({
							label: cl,
							value: cl,
						});
					});
				}
				var dispatchLocations = response.data.dispatchLocation;
				var filteredispatchLocations = [];
				if (dispatchLocations.length > 0) {
					dispatchLocations.map(function (t) {
						filteredispatchLocations.push({
							label: t,
							value: t,
						});
					});
				}
                var incoterm = response.data.incoterms;
				var filteredincoterms = [];
				if (incoterm.length > 0) {
					incoterm.map(function (e) {
						filteredincoterms.push({
							label: e,
							value: e,
						});
					});
                    filteredincoterms.push({
                        label: "other",
                        value: 'other',
                    });
				}
				this.setState({
					filteredincoterms: filteredincoterms,
					filteredispatchLocations: filteredispatchLocations,
                    filteredestinationPort: filteredestinationPort
				});
        })
    }

    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };


    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            bulkslide: ''
        });

    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    containertypechangeHandler = (e) => {
        if (e.value.includes("Non") == false) {
            $("#DGD_file").attr("required", true);
            $("#MSDS_file").attr("required", true);
            $("#IIP_file").attr("required", true);
            var showUpopts = "";
        }
        else {
            $("#DGD_file").removeAttr("required", true);
            $("#MSDS_file").removeAttr("required", true);
            $("#IIP_file").removeAttr("required", true);
            var showUpopts = "show-n";
        }
        this.setState({
            container_type: e,
            showUpopts: showUpopts
        })
    }
    containersizechangeHandler = (e) => {
        if (e == null) {
            e = [];
        }
        this.setState({
            container_size: e,
        })
    }
    dispatchlocationchangeHandler = (e) => {
        this.setState({
            dispatch_location: e,
        })
    }
    newFilter = (filterType, selOption) => {
		console.log(filterType, selOption)
        if(selOption.value == "other"){
         this.setState({
            manualInco : true,
            [filterType]: selOption
         })  
        }else{

            this.setState({ [filterType]: selOption ,
                manualInco : false
            });
        }
	}
    dispatchportchangeHandler = (e) => {
        this.setState({
            dispatch_port: e,
        })
    }
    destinationportchangeHandler = (e) => {
        this.setState({
            destination_port: e,
        })
    }
    containerrequiredchangeHandler = (e) => {
        this.setState({
            container_required: e,
        })
    }
    incotermschangeHandler = (e) => {
        this.setState({
            incoterms: e,
        })
    }
    certificateOfOriginchangeHandler = (e) => {
        this.setState({
            certificateOfOrigin: e,
        })
    }
    ftachangeHandler = (e) => {
        this.setState({
            fta: e,
        })
    }
    exportIncentivechangeHandler = (e) => {
        this.setState({
            exportIncentive: e,
        })
    }
    paymentchangeHandler = (e) => {
        if (e.value.includes("Free of Charge") == false) {
            var showUpload = "show-n"
            $("#GRW_file").removeAttr("required", true);

        } else {
            var showUpload = "show-m"
            $("#GRW_file").attr("required", true);
        }
        this.setState({
            payment_terms: e,
            showUpload: showUpload
        })
    }
    changeHandler1 = (event) => {
        console.log("Select", event.target.value);
        let name = event.target.name;
        console.log(name);
        let value = event.target.value;
        this.setState({ [name]: value });
        if (name == "erpPO") {
            if (value == "yes") {
                $("#addMrERp").show();
                this.setState({
                    disableERP: ""
                })
            } else {
                $("#addMrERp").hide();
                this.setState({
                    disableERP: "disabled"
                })
            }
        }
        if (name == "LC") {
            if (value == "yes") {
                this.setState({
                    disableLC: ""
                })
                //$("#LCno").prop("required",true);
            } else {
                this.setState({
                    disableLC: "disabled"
                })
                //$("#LCno").removeAttr("required",true);
            }
        }
        if (name == "cargoDeliveryPlant") {
            if (value == "B") {
                $("#invoice_type").val(null);
                $("#order_type").val(null);
                $("#invoice_type").prop("required", true);
                $("#order_type").prop("required", true);
                this.setState({
                    disableBng: ""
                })
            } else {
                $("#invoice_type").removeProp("required");
                $("#order_type").removeProp("required");
                this.setState({
                    disableBng: "disabled"
                })
            }
        }
        // if(name == "deliveryTerms")
        // {
        //     $(".dterms").removeAttr("required",true);
        //     if(value == "Ex Works")
        //     {
        //         $("#pickUpAddr").prop("required",true);
        //     }
        //     else if(value == "FCA")
        //     {
        //         $("#port").prop("required",true);
        //     }
        //     else if(value == "FOB")
        //     {
        //         $("#airportName").prop("required",true);
        //     }
        // }
    }
    changeHandler = (event) => {
        console.log("Select", event.target.value);

        var name = event.target.name;
        var value = event.target.value;
        const re = /^[A-Za-z]+$/;
        // if (name == 'deptName') {
        //     if (value === "" || re.test(value)) {
        //         console.log("name succ")
        //         alert('hi')
        //         //   this.setState(value);
        //     }else{
        //         alert('dont enter the numerals')
        //     }
        // }

        console.log(name);
    }
    dateValidation = (currentDate) => {
        return currentDate.isBefore(moment(this.state.deliverymaxDate)) && currentDate.isAfter(moment(this.state.deliveryminDate));
    };
    changeFileHandler = async (e) => {
        console.log(e.target.name)
        this.setState({ [e.target.name]: e.target.files[0] });
    }
    changeFileHandler1 = (e) => {
        this.setState({
            file1: e.target.files[0]
        });
    }
    changeFileHandler2 = (e) => {
        this.setState({
            file2: e.target.files[0]
        });
    }
    changeFileHandler3 = (e) => {
        this.setState({
            file3: e.target.files[0]
        });
    }
    changeFileHandler4 = (e) => {
        this.setState({
            file4: e.target.files[0]
        });
    }
    async formHandler(event) {
        event.preventDefault();
        this.setState({
            loadshow : "show-m",
            overly : "show-m"
        })
        var eventTarget = event.target;
        console.log(this.state.file, "this.state.file");
        var container_date = $("#container_dateField").val();
        var dateField = $("#dateField").val();
        var department_name = $('#deptName').val();
        var intender_name = $("#indenterName").val();
        var mobile_number = $("#mobile").val();
        var consignee_name = $("#consigneeName").val();
        var certificate_of_origin = this.state.certificateOfOrigin.value;
        var fta_required = this.state.fta.value;
        var export_incentive = this.state.exportIncentive.value;
        var intender_email = $("#indenterEmail").val();
        var manualincoterms = $("#manualincoterms").val();
        var intenderMails = []
        if (intender_email.includes(",") == true) {
            intenderMails = intender_email.split(",")
        } else {
            intenderMails = [intender_email]
        }
        var rngLength = $("#rngLength").val();
        var ringiData = [];
        for (var r = 0; r < rngLength; r++) {
            var ringiFile = $("#ringi_file_" + r).val();
            ringiFile = ringiFile.substring(ringiFile.lastIndexOf("\\") + 1, ringiFile.length);
            var ringiDesc = $("#ringiDesc_" + r).val();
            if (ringiFile != '' || ringiDesc != '') {
                var rngtemp = { "ringi_file": ringiFile, "ringi_desc": ringiDesc }
                ringiData.push(rngtemp)
            }
        }
        ringiData = JSON.stringify(ringiData);
        var container_type = this.state.container_type.value;
        // var dispatch_date = $('#dispatch_dateField').val();
        var mrd_date = $('#mrd_dateField').val();
        var dispatch_location = this.state.dispatch_location.value;
        var dispatch_port = this.state.dispatch_port.value;
        var country=this.state.selected_country.value
        var destination_port = this.state.destination_port.value;
        if (destination_port == "Other") {
            var destination_port = $('#other_destination_port').val();
        }
        var incoterms = this.state.incoterms.value;
        var payment_terms = this.state.payment_terms.value;
        var remarks = $('#remarks').val();
        var container_size = this.state.container_size;
        var created_by = localStorage.getItem("email")
        var request_type = 1;
        var dgd_file = this.state.file1
        var msds_file = this.state.file2
        var iip_file = this.state.file3
        var grw_file = this.state.file4
        var containerSize = [];
        console.log($("#container_required_"+0).val(),'$("#container_required_"+0).val()')
        if($("#container_required_"+0).val() == '0' || $("#container_required_"+0).val() == 0){
            this.setState({
                loadshow : "show-n",
                overly : "show-n"
            })
            return alert("No. of Container required should be greater than 0")
           
        }else{

         
        for (var i = 0; i < container_size.length; i++) {
            containerSize.push({
                "container_size": container_size[i].value,
                "container_required": $("#container_required_" + i).val()
            })
        }
        var requirement_id = moment.parseZone().format("YYYYMMDDHHmmss");
        var lspallocationdetails = this.state.lspallocationdetails;
        var flag = 1
        var errorMessage = ""
        var mismatchData = await lspallocationdetails.filter((s) => s.dispatch_location == dispatch_location && s.destination_port == destination_port && s.incoterms == incoterms);
        // console.log(mismatchData,"463")
        var date = container_date.split("-")
        var containerDate = date[2] + "-" + date[1] + "-" + date[0] + " 00:00:00"
        // if (mismatchData.length == 0) {
        //     var compare_container_date = moment.parseZone(new Date(containerDate)).utcOffset("+05:30")._d
        //     var currentDate = moment.parseZone(new Date()).add(29, 'days').utcOffset("+05:30")._d;
        //     // if (compare_container_date > currentDate) {
        //     //     console.log("success -- mismatch")
        //     //     flag = 1
        //     // }
        //     // else {
        //     //     console.log("fail -- mismatch")
        //     //     flag = 0
        //     //     errorMessage = "Container Requirement Date Should be Greater than 30 days"
        //     // }
        // }
        // else
        const invalidEmails = intenderMails.filter(email => !validateEmail(email.trim()));

        if (invalidEmails.length > 0) {
            // Invalid email detected, show alert or handle accordingly
            alert("Invalid email address detected!");
            this.setState({
                loadshow : "show-n",
                overly : "show-n"
            })
            return
             // Prevent further processing if there are invalid emails
        }
            if (container_type == "Non Haz Dry" || container_type == "Non Haz High Cube") {
                var compare_container_date = moment.parseZone(new Date(containerDate)).utcOffset("+05:30")._d
                var currentDate = moment.parseZone(new Date()).add(3, 'days').utcOffset("+05:30")._d;
                if (compare_container_date > currentDate) {
                    console.log("success -- Haz")
                    flag = 1
                }
                else {
                    console.log("fail -- Haz")
                    flag = 0
                    errorMessage = "Container Requirement Date Should be Greater than 4 days"
                }
            }
            if (container_type == "Haz High Cube" || container_type == "Haz Dry") {
                var compare_container_date = moment.parseZone(new Date(containerDate)).utcOffset("+05:30")._d
                var currentDate = moment.parseZone(new Date()).add(9, 'days').utcOffset("+05:30")._d;
                if (compare_container_date > currentDate) {
                    console.log("success -- Haz")
                    flag = 1
                }
                else {
                    console.log("fail -- Haz")
                    flag = 0
                    errorMessage = "Container Requirement Date Should be Greater than 10 days"
                }
            }
            if (incoterms == "DDU" || incoterms == "DDP") {
                var compare_container_date = moment.parseZone(new Date(containerDate)).utcOffset("+05:30")._d
                var currentDate = moment.parseZone(new Date()).add(29, 'days').utcOffset("+05:30")._d;
                if (compare_container_date > currentDate) {
                    console.log("success -- Incoterms")
                    flag = 1
                }
                else {
                    console.log("fail -- Incoterms")
                    flag = 0
                    errorMessage = "Container Requirement Date Should be Greater than 30 days"
                }
            }
            if (destination_port == "Other") {
                var compare_container_date = moment.parseZone(new Date(containerDate)).utcOffset("+05:30")._d
                var currentDate = moment.parseZone(new Date()).add(29, 'days').utcOffset("+05:30")._d;
                if (compare_container_date > currentDate) {
                    console.log("success -- Other Destination Port")
                    flag = 1
                }
                else {
                    console.log("fail -- Other Destination Port")
                    flag = 0
                    errorMessage = "Container Requirement Date Should be Greater than 30 days"
                }
            }
            // else if (container_type.includes("Dry") === true) {
            //     var compare_container_date = moment.parseZone(new Date(containerDate)).utcOffset("+05:30")._d
            //     var currentDate = moment.parseZone(new Date()).add(4, 'days').utcOffset("+05:30")._d;
            //     if (compare_container_date > currentDate) {
            //         console.log("success -- Dry")
            //         flag = 1
            //     }
            //     else {
            //         console.log("fail -- Dry")
            //         flag = 0
            //         errorMessage = "Container Requirement Date Should be Greater than 4 days"
            //     }
            // }
            
        
        var fileFlag = 1
        if (container_type.includes("Non") == false) {
            if (dgd_file != "" && dgd_file != undefined && dgd_file != null && msds_file != "" && msds_file != undefined && msds_file != null && iip_file != "" && iip_file != undefined && iip_file != null) {
                fileFlag = 1
            }
            else {
                fileFlag = 0
                errorMessage = "Files Should be Uploaded"
            }
        }
        var grwfileflag = 1
        if (payment_terms.includes("Free of Charge")) {
            if (grw_file != "" && grw_file != undefined && grw_file != null) {
                grwfileflag = 1
            }
            else {
                grwfileflag = 0
                errorMessage = "GR waiver Should be Uploaded"
            }
        }
        var countryFlag=1 
        
        console.log(country,"713")
        if(!country){
            console.log(this.state.selected_country,"715")
            countryFlag=0
            errorMessage="Select Country"
        }
        // console.log(flag,"check")
        if (flag == 1 && fileFlag == 1 && grwfileflag == 1 && countryFlag==1) {
            // var params = {
            //     requirement_id : requirement_id,
            //     container_requirement_date : container_date,
            //     container_type : container_type,
            //     container_data : containerSize,
            //     dispatch_location : dispatch_location,
            //     dispatch_port : dispatch_port,
            //     destination_port : destination_port,
            //     dispatch_date : dispatch_date,
            //     mrd_date : mrd_date,
            //     incoterms : incoterms,
            //     payment_terms : payment_terms,
            //     remarks : remarks,
            //     created_by : created_by,
            //     request_type : request_type,
            // }
            // console.log(params,"params")
            var saveData = new FormData();
            // saveData.append("csvData",csvData);
            saveData.append("dgd_file", dgd_file);
            saveData.append("msds_file", msds_file);
            saveData.append("iip_file", iip_file);
            saveData.append("grw_file", grw_file);
            saveData.append("requirement_id", requirement_id);
            saveData.append("dateField", dateField);
            saveData.append("department_name", department_name);
            saveData.append("intender_name", intender_name);
            saveData.append("mobile_number", mobile_number);
            saveData.append("consignee_name", consignee_name);
            saveData.append("certificate_of_origin", certificate_of_origin);
            saveData.append("fta_required", fta_required);
            saveData.append("export_incentive", export_incentive);
            saveData.append("intenderMails", intenderMails);
            saveData.append("rngLength", rngLength);
            for (var rn = 0; rn < rngLength; rn++) {
                var ringiFile = "ringi_file_" + rn;

                saveData.append(ringiFile, this.state[ringiFile]);
                console.log(this.state[ringiFile]);
            }
            saveData.append("ringiData", ringiData);
            saveData.append("container_requirement_date", container_date);
            saveData.append("container_type", container_type);
            saveData.append("container_data", JSON.stringify(containerSize));
            saveData.append("dispatch_location", dispatch_location);
            saveData.append("dispatch_port", dispatch_port);
            saveData.append("destination_port", destination_port);
            // saveData.append("dispatch_date", dispatch_date);
            saveData.append("mrd_date", mrd_date);
            saveData.append("incoterms", incoterms);
            saveData.append("payment_terms", payment_terms);
            saveData.append("remarks", remarks);
            saveData.append("created_by", created_by);
            saveData.append("request_type", request_type);
            saveData.append("manualincoterms", manualincoterms);
            saveData.append("country", country);

            if (container_date != "" && container_date != undefined && container_date != null && mrd_date != "" && mrd_date != undefined && mrd_date != null && container_type != "" && container_type != undefined && container_type != null && dispatch_location != "" && dispatch_location != undefined && dispatch_location != null && dispatch_port != "" && dispatch_port != undefined && dispatch_port != null && destination_port != "" && destination_port != undefined && destination_port != null && incoterms != "" && incoterms != undefined && incoterms != null && payment_terms != "" && payment_terms != undefined && payment_terms != null && remarks != "" && container_size != "" && dateField != "" && department_name != "" && intender_name != "" && mobile_number != "" && intenderMails != "" && consignee_name != "" && certificate_of_origin != "" && certificate_of_origin != undefined && certificate_of_origin != null && fta_required != null && fta_required != undefined && fta_required != "" && export_incentive != "" && export_incentive != undefined && export_incentive != null) {
                redirectURL.post("/consignments/insertOnetimeBookingData", saveData, {
                    headers: {
                        'content-type': 'multipart/form-data',
                        // 'Access-Control-Allow-Origin': "*",
                        // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DEvarE',
                        'Access-Control-Allow-Headers': 'Origin, Content-Type'
                    }
                })
                    .then((response) => {
                        if (response.data.message == "Success") {
                            this.setState({
                                basicTitle: "Date Successfully updated",
                                basicType: "success",
                                show: true,
                                loadshow : "show-n",
                                overly : "show-n"
                            })
                            window.location.href = "/containerbookingsystem/onetime"
                        } else {
                            this.setState({
                                basicTitle: "Date not updated",
                                basicType: "warning",
                                show: true,
                                loadshow : "show-n",
                                overly : "show-n"
                            })
                        }
                    })
            } else {
                this.setState({
                    basicTitle: "All fields are mandatory",
                    basicType: "warning",
                    show: true,
                    loadshow : "show-n",
                    overly : "show-n"
                })
            }
        }
        else {
            // console.log("hit")
            this.setState({
                basicTitle: errorMessage,
                basicType: "danger",
                show: true,
                loadshow : "show-n",
                overly : "show-n"
            })
        }
    }
    }
    handleAddRow = () => {
        const item = {
            erpPoNo: "",
            erpPoDate: "",
        };
        this.setState({
            rows: [...this.state.rows, item]
        });
    };
    handleRemoveRow = (idx) => {
        this.state.rows.splice(idx, 1);
        this.setState({ rows: this.state.rows });
    };

    handleAddRingiRow = () => {
        const item = {
            ringi_file: "",
            ringiDesc: "",
        };
        this.setState({
            ringirows: [...this.state.ringirows, item]
        });
    };
    handleRemoveRingiRow = (idx) => {
        this.state.ringirows.splice(idx, 1);
        this.setState({ ringirows: this.state.ringirows });
    };

    packetsHandler = (e) => {
        var noOfPackets = e.target.value;
        if (parseInt(noOfPackets) >= 1) {
            var lespcks = parseInt(noOfPackets) - 1;
            var bhanu = [{}];
            for (var n = 0; n < lespcks; n++) {
                const item = {
                    itemDesc: "",
                    grossWt: "",
                    dimensions: "",
                    cargo_chargeable_weight: "",
                };
                this.setState({
                    packetrows: [...bhanu, item]
                });
                var bhanu = [...bhanu, item];
            }
        }
        else {
            alert("No of packets should be greater than 0");
            $("#no_of_packets").val(1);
            const item = {
                itemDesc: "",
                grossWt: "",
                dimensions: "",
                cargo_chargeable_weight: "",
            };
            this.setState({
                packetrows: [item]
            });
        }
    }

    onClickTab(pageType) {
        if (pageType == "brequest") {
            var brequest = "activet";
            var ffupdates = "";
            var track = "";
            var requestData = "show-m";
            var trackData = "show-n";
            var requestFields = "show-m";
            var prepaidFields = "show-n";
            var addresstxt = "";
            var request_type = 1;
            var pagetitle = "Booking Request (Air Imports)";
            $(".requestFields").attr("required", true);
            $(".prepaidFields").removeAttr("required", true);
        }
        else if (pageType == "prepaid") {
            var brequest = "";
            var prepaid = "activet";
            var track = "";
            var requestData = "show-m";
            var trackData = "show-n";
            var requestFields = "show-n";
            var prepaidFields = "show-m";
            var addresstxt = "(To be mentioned on BOE)";
            var request_type = 2;
            var pagetitle = "Booking Request For Prepaid Shipments (Air Imports)";
            $(".requestFields").removeAttr("required", true);
            $(".prepaidFields").attr("required", true);
        }
        else if (pageType == "track") {
            var brequest = "";
            var prepaid = "";
            var track = "activet";
            var requestData = "show-n";
            var trackData = "show-m";
            var requestFields = "show-n";
            var prepaidFields = "show-n";
            var addresstxt = "";
            var request_type = "";
            var pagetitle = "";
        }
        this.setState({
            brequest: brequest,
            prepaid: prepaid,
            track: track,
            requestData: requestData,
            trackData: trackData,
            requestFields: requestFields,
            prepaidFields: prepaidFields,
            addresstxt: addresstxt,
            request_type: request_type,
            pagetitle: pagetitle
        })
    }

    trackHandler(event) {
        event.preventDefault();
        var indenter_email = $("#indenter_email").val();
        if (indenter_email != undefined && indenter_email != '') {
            this.setState({
                loadshow: 'show-m',
                overly: 'show-m',
            })
            var params = {
                indenter_email: indenter_email
            }
            redirectURL.post("/consignments/getAirCargosStatusByIntender", params)
                .then((response) => {
                    var records = response.data;
                    this.setState({
                        rowData: records,
                        trackResData: 'show-m',
                        loadshow: 'show-n',
                        overly: 'show-n',
                    })
                })
                .catch(function (e) {
                    console.log("Error ", e)
                })
        }
        else {
            this.setState({
                basicTitle: "Please enter Indenter Email",
                basicType: "warning",
                show: true
            })
        }
    }

    render() {
        var columnwithDefs = [
            {
                headerName: "Job No",
                field: "job_no",
                width: 130,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Date",
                field: "date",
                width: 130,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.date != undefined && params.data.date != '') {
                        return moment.parseZone(params.data.date).format("DD-MM-YYYY")
                    }
                }
            },
            {
                headerName: "Department Name",
                field: "dept_name",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Indenter Name",
                field: "indenter_name",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Mobile No",
                field: "mobile",
                width: 100,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Indenter Email",
                field: "indenter_email",
                width: 200,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Cargo Delivery Plant",
                field: "cargo_delivery_plant",
                width: 200,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Supplier Name",
                field: "supplier_name",
                width: 200,
                filter: true,
                resizable: true,
            },
            {
                headerName: "ETA IGI Airport Date",
                field: "eta_igi_airport_date",
                width: 150,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.eta_igi_airport_date != undefined && params.data.eta_igi_airport_date != "") {
                        return moment.parseZone(params.data.eta_igi_airport_date).format("DD-MM-YYYY")
                    }
                    else {
                        return "N/A";
                    }
                }
            },
            {
                headerName: "ATA IGI Airport Date",
                field: "ata_igi_airport_date",
                width: 150,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.ata_igi_airport_date != undefined && params.data.ata_igi_airport_date != "") {
                        return moment.parseZone(params.data.ata_igi_airport_date).format("DD-MM-YYYY")
                    }
                    else {
                        return "N/A";
                    }
                }
            },
            {
                headerName: "OOC Date",
                field: "ooc_date",
                width: 150,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.ooc_date != undefined && params.data.ooc_date != "") {
                        return moment.parseZone(params.data.ooc_date).format("DD-MM-YYYY")
                    }
                    else {
                        return "N/A";
                    }
                }
            },
            {
                headerName: "Status",
                field: "status_text",
                width: 250,
                pinned: "right",
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.rejected == 1) {
                        return "Rejected";
                    }
                    else if (params.data.actual_delivery_at_msil != undefined && params.data.actual_delivery_at_msil != "") {
                        return "Delivered To MSIL";
                    }
                    else if (params.data.ooc_date != undefined && params.data.ooc_date != "") {
                        return "Custom Cleared";
                    }
                    else if (params.data.ata_igi_airport_date != undefined && params.data.ata_igi_airport_date != "") {
                        return "Flight Arrived At Destination Airport";
                    }
                    else if (params.data.eta_igi_airport_date != undefined && params.data.eta_igi_airport_date != "") {
                        return "Flight Booked";
                    }
                    else if (params.data.ff_code == undefined || params.data.ff_code == '') {
                        return "Pending At Imports";
                    }
                    else {
                        return "Pending From Freight Forwarder";
                    }
                }
            }
        ]
        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    {/* <div className="col-xl-12 col-lg-12">
                        <div className="f16" style={{marginBottom:"10px"}}>
                            <ul className="d-tabs">
                                
                                <li onClick={this.onClickTab.bind(this,"brequest")} className={"lttabs "+(this.state.brequest)}>
                                    <button type="button" className="btn">Air Imports</button>
                                </li>

                                <li onClick={this.onClickTab.bind(this,"prepaid")} className={"lttabs "+(this.state.prepaid)}>
                                    <button type="button" className="btn">Air Prepaid Shipments</button>
                                </li>

                                <li onClick={this.onClickTab.bind(this,"track")} className={"lttabs "+(this.state.track)}>
                                    <button type="button" className="btn">Booking Status</button>
                                </li>
                            </ul>
                        </div>
                    </div> */}
                    <div className={"col-xl-12 col-lg-12 " + this.state.requestData} style={{borderRadius:"10px"}}>
                        <div className="card"  style={{borderRadius:"10px"}}>
                            <div className="card-header"  style={{borderRadius:"10px"}}>
                                <div className='d-flex' style={{paddingLeft:"8.5px", paddingTop:"7.5px"}}>
                                    <a href="/containerbookingsystem/onetime" >
                                        <i className="fa fa-arrow-left" style={{color:"#171c8f",fontSize:"20px", width:"20px"}}></i>
                                    </a>
                                    <h1 style={{color:"#171c8f",fontWeight:"600", paddingTop:"1px",fontSize:"16px",marginBottom:"0px",paddingLeft:"10px"}}>{this.state.pagetitle}</h1>                                   
                                </div>

                            </div>
                            <div className="card-body pt-15px">
                                <form class="aircargo-form" onSubmit={this.formHandler.bind(this)} encType="multipart/form-data">
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">Date </label>
                                            <input type="text"  name="dateField" id="dateField" className="datetimepicker_date form-input-box form-control" disabled />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Department Name </label>
                                            <input type="text" name="deptName" id="deptName" className="form-control form-control form-input-box" onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Indenter Name</label>
                                            <input type="text" name="indenterName" id="indenterName" className="form-control alfa-cls form-input-box" onChange={this.changeHandler} required />
                                        </div>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Mobile No</label>
                                            <input type="number" name="mobile" id="mobile" className="form-control mobnum-cls form-input-box"
                                             onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Indenter Email ID</label>
                                            <input type="text" name="indenterEmail" id="indenterEmail" className="form-control form-input-box" onChange={this.changeHandler} required />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    {this.state.ringirows.map((item, idx) => (
                                        <div className={"row col-xl-12 "} key={idx}>
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12 form-input-text">*Ringi No (File) : </label>
                                                <input type="file" name={"ringi_file_" + idx} id={"ringi_file_" + idx} className="form-control requestFields form-input-box" onChange={this.changeFileHandler} required />
                                                {/* <FileBase64
                                            onDone={ this.changeFileHandler.bind(this) } /> */}
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12 form-input-text">*Ringi No</label>
                                                <input type="text" name={"ringiDesc_" + idx} id={"ringiDesc_" + idx} className="form-control ringi-cls form-input-box" onChange={this.changeHandler1} required />
                                            </div>
                                            {idx == 0 &&
                                            (<div class="col-xl-4 " style={{display:"flex", alignItems:"end", paddingBottom:"6px"}}>
                                                <div className=''>
                                                <button class="btn btn-booking" style={{ display: (idx != 0) ? 'none' : 'block',cursor:'pointer' ,backgroundColor:"#171c8f !important", height:"35px"}} type="button" onClick={this.handleAddRingiRow}>+ Add More Fields</button>
                                                </div>
                                            </div>)}
                                            {idx != 0 &&           
                                            (<div className="col-xl-4 form-input-text" style={{ display: (idx != 0) ? 'block' : 'none',cursor:'pointer' }}>
                                                <h6 style={{ marginTop: "2.5em" }} onClick={() => this.handleRemoveRingiRow(idx)}>Clear</h6>
                                            </div>) }
                                        </div>
                                    ))}
                                    <input type="hidden" id="rngLength" value={this.state.ringirows.length} />
                                    {/* <div class={"row col-sm-4"}>
                                        <div className="row col-xl-12 m-top-1">
                                            <div class="col-xl-4">
                                                <button class="btn btn-primary" style={{backgroundColor:"#171c8f !important"}} type="button" onClick={this.handleAddRingiRow}>Add More Fields +</button>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div class={"hr-dashed "}></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Consingee Name</label>
                                            <input type="text" name="consigneeName" id="consigneeName" className="form-control alfa-cls form-input-box" onChange={this.changeHandler} required />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Container Type</label>
                                            <Select
                                                placeholder={"Select Container Type"}
                                                closeMenuOnSelect={true}
                                                value={this.state.container_type}
                                                onChange={this.containertypechangeHandler.bind(this)}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderRadius: "6px",
                                                        backgroundColor: "#fff",
                                                        boxShadow: "0 0 8px #d9d9d9",
                                                        border: "1px solid #d1d3e2",
                                                    }),

                                                }}
                                                options={[{ label: "Haz Dry", value: "Haz Dry" },
                                                { label: "Haz High Cube", value: "Haz High Cube" }, { label: "Non Haz Dry", value: "Non Haz Dry" }, { label: "Non Haz High Cube", value: "Non Haz High Cube" }]} required
                                            />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Container Size</label>
                                            <Select
                                                placeholder={"Select container Size"}
                                                closeMenuOnSelect={true}
                                                value={this.state.container_size}

                                                isMulti={true}
                                                onChange={this.containersizechangeHandler.bind(this)}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderRadius: "6px",
                                                        backgroundColor: "#fff",
                                                        boxShadow: "0 0 8px #d9d9d9",
                                                        border: "1px solid #d1d3e2",
                                                    }),

                                                }}
                                                options={[{ label: "20 Ft", value: "20" },
                                                { label: "40 Ft", value: "40" }]} required
                                            />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Dispatch Location</label>
                                            <Select
                                                placeholder={"Select Dispatch Location"}
                                                closeMenuOnSelect={true}
                                                value={this.state.dispatch_location}

                                                onChange={this.newFilter.bind(this,"dispatch_location")}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderRadius: "6px",
                                                        backgroundColor: "#fff",
                                                        boxShadow: "0 0 8px #d9d9d9",
                                                        border: "1px solid #d1d3e2",
                                                    }),

                                                }}
                                                options={this.state.filteredispatchLocations} required
                                            />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Dispatch Port</label>
                                            <Select
                                                placeholder={"Select Dispatch Port"}
                                                closeMenuOnSelect={true}
                                                value={this.state.dispatch_port}

                                                onChange={this.dispatchportchangeHandler.bind(this)}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderRadius: "6px",
                                                        backgroundColor: "#fff",
                                                        boxShadow: "0 0 8px #d9d9d9",
                                                        border: "1px solid #d1d3e2",
                                                    }),
                                                }}
                                                options={[{ label: "Pipavav", value: "Pipavav" },
                                                { label: "Mundra", value: "Mundra" }, { label: "Nhava Sheva", value: "Nhava Sheva" }, { label: "Chennai", value: "Chennai" }, { label: "Kattupali", value: "Kattupali" }, { label: "ICD Garhi", value: "ICD Garhi" }]} required
                                            />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Destination Port</label>
                                            <Select
                                                placeholder={"Select Destination Port"}
                                                closeMenuOnSelect={true}
                                                value={this.state.destination_port}

                                                onChange={this.newFilter.bind(this,"destination_port")}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderRadius: "6px",
                                                        backgroundColor: "#fff",
                                                        boxShadow: "0 0 8px #d9d9d9",
                                                        border: "1px solid #d1d3e2",
                                                    }),
                                                }}
                                                options={this.state.filteredestinationPort} required
                                            />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Country</label>
                                            <Select
                                                placeholder={"Select Country"}
                                                closeMenuOnSelect={true}
                                                value={this.state.selected_country}

                                                onChange={this.newFilter.bind(this,"selected_country")}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderRadius: "6px",
                                                        backgroundColor: "#fff",
                                                        boxShadow: "0 0 8px #d9d9d9",
                                                        border: "1px solid #d1d3e2",
                                                    }),

                                                }}
                                                options={this.state.filteredCountries} required
                                            />
                                        </div>
                                        {(this.state.destination_port.value == "Other") ?
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12 form-input-text">*Other Destination Port</label>
                                                <input type="text" name="other_destination_port" id="other_destination_port" className="form-control alfa-cls form-input-box" onChange={this.changeHandler} required />
                                            </div> : ""
                                        }

                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
{/* 
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12"> Dispatch Date* : </label>
                                            <input type="text" name="dispatch_date" id="dispatch_dateField" className="datetimepicker_date form-control" readOnly />
                                        </div> */}
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*MRD at Destination</label>
                                            <input type="text" name="mrd_date" id="mrd_dateField" className="datetimepicker_date form-control form-input-box" readOnly />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Container Requirement Date</label>
                                            <input type="text" name="container_date" id="container_dateField" className="datetimepicker_date form-control form-input-box" readOnly />
                                        </div>
                                        {this.state.container_size.map((item, idx) => (
                                            <div className="col-xl-4" key={idx}>
                                                <label className="col-xl-12 col-lg-12 form-input-text">*No. of Container required For  {item.label}</label>
                                                <input type="text" name={"container_required_" + idx} id={"container_required_" + idx} className="form-control num-cls form-input-box" onChange={this.changeHandler} required />
                                            </div>
                                        ))}
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12 col-lg-12">

                                        <div className="col-xl-4 col-lg-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*DGD if Haz Container required<span className={this.state.showUpopts}></span></label>
                                            <input type="file" name="DGD_file" id="DGD_file" className="form-control requestFields form-input-box" onChange={this.changeFileHandler1} />
                                            {/* <FileBase64
                                            onDone={ this.changeFileHandler.bind(this) } /> */}
                                        </div>
                                        <div className="col-xl-4 col-lg-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*MSDS of the Cargo<span className={this.state.showUpopts}></span></label>
                                            <input type="file" name="MSDS_file" id="MSDS_file" className="form-control requestFields form-input-box" onChange={this.changeFileHandler2} />
                                            {/* <FileBase64
                                            onDone={ this.changeFileHandler.bind(this) } /> */}
                                        </div>
                                        <div className="col-xl-4 col-lg-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*IIP certificate of the Cargo<span className={this.state.showUpopts}></span></label>
                                            <input type="file" name="IIP_file" id="IIP_file" className="form-control requestFields form-input-box" onChange={this.changeFileHandler3} />
                                            {/* <FileBase64
                                            onDone={ this.changeFileHandler.bind(this) } /> */}
                                        </div>
                                    </div>

                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Incoterms to be Used</label>
                                            <Select
                                                placeholder={"Select Incoterms"}
                                                closeMenuOnSelect={true}
                                                value={this.state.incoterms}

                                                onChange={this.newFilter.bind(this,"incoterms")}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderRadius: "6px",
                                                        backgroundColor: "#fff",
                                                        boxShadow: "0 0 8px #d9d9d9",
                                                        border: "1px solid #d1d3e2",
                                                    }),
                                                }}
                                                options={this.state.filteredincoterms} required
                                            />
                                        </div>
                                        {
                                            this.state.manualInco == true ? 
                                              <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">Incoterms</label>
                                            <input type="text" name="manualincoterms" id="manualincoterms" className="form-control alfa-cls form-input-box" 
                                            onChange={this.changeHandler}
                                             required />
                                        </div>
                                        : ""
                                        }
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Payment Terms</label>
                                            <Select
                                                placeholder={"Select Payment Terms"}
                                                closeMenuOnSelect={true}
                                                value={this.state.payment_terms}

                                                onChange={this.paymentchangeHandler.bind(this)}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderRadius: "6px",
                                                        backgroundColor: "#fff",
                                                        boxShadow: "0 0 8px #d9d9d9",
                                                        border: "1px solid #d1d3e2",
                                                    }),
                                                }}
                                                options={[{ label: "Free of Charge", value: "Free of Charge" },
                                                { label: "Payment", value: "Payment" }]} required
                                            />
                                        </div>
                                        <div className={"col-xl-4 col-lg-4" + this.state.showUpload}>
                                            <label className="col-xl-12 col-lg-12 form-input-text">*GR waiver-In case of free of charge</label>
                                            <input type="file" name="GRW_file" id="GRW_file" className="form-control requestFields form-input-box" onChange={this.changeFileHandler4} />
                                            {/* <FileBase64
                                            onDone={ this.changeFileHandler.bind(this) } /> */}
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Remarks</label>
                                            <input type="text" name="remarks" id="remarks" className="form-control alfa-cls form-input-box" onChange={this.changeHandler} required />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Certificate of Origin</label>
                                            <Select
                                                placeholder={"Select Certificate of Origin"}
                                                closeMenuOnSelect={true}
                                                value={this.state.certificateOfOrigin}

                                                onChange={this.certificateOfOriginchangeHandler.bind(this)}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderRadius: "6px",
                                                        backgroundColor: "#fff",
                                                        boxShadow: "0 0 8px #d9d9d9",
                                                        border: "1px solid #d1d3e2",
                                                    }),
                                                }}
                                                options={[{ label: "Yes", value: "Yes" },
                                                { label: "No", value: "No" }]} required
                                            />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*FTA(Free Trade Agreement)</label>
                                            <Select
                                                placeholder={"Select FTA Required"}
                                                closeMenuOnSelect={true}
                                                value={this.state.fta}

                                                onChange={this.ftachangeHandler.bind(this)}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderRadius: "6px",
                                                        backgroundColor: "#fff",
                                                        boxShadow: "0 0 8px #d9d9d9",
                                                        border: "1px solid #d1d3e2",
                                                    }),
                                                }}
                                                options={[{ label: "Yes", value: "Yes" },
                                                { label: "No", value: "No" }]} required
                                            />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Export Incentive</label>
                                            <Select
                                                placeholder={"Select Export Incentive"}
                                                closeMenuOnSelect={true}
                                                value={this.state.exportIncentive}

                                                onChange={this.exportIncentivechangeHandler.bind(this)}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderRadius: "6px",
                                                        backgroundColor: "#fff",
                                                        boxShadow: "0 0 8px #d9d9d9",
                                                        border: "1px solid #d1d3e2",
                                                    }),
                                                }}
                                                options={[{ label: "Yes", value: "Yes" },
                                                { label: "No", value: "No" }]} required
                                            />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-booking" style={{ marginLeft: "45%", width: "192px", marginTop: "20px" }}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className={"col-xl-12 col-lg-12 " + this.state.trackData}>
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Booking Status</span>
                                </h5>
                            </div>
                            <div className="card-body">
                                <form className="row" onSubmit={this.trackHandler.bind(this)} encType="multipart/form-data">
                                    <div className="col-xl-4">
                                        <input type="email" name="indenter_email" id="indenter_email" className="form-control" placeholder="Indenter Email" required />
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <button type="submit" className="btn btn-info">Track Status</button>
                                    </div>
                                </form>
                            </div>
                            <div className={"col-xl-12 col-lg-12 " + this.state.trackResData}>
                                <div id="myGrid" style={{ width: "100%", height: "478px" }} className={"ag-theme-balham"}>
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={false}
                                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        stopEditingWhenGridLosesFocus={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        // components={this.state.components}
                                        enableRangeSelection={true}
                                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        )
    }
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_date').datetimepicker({
        //mask:'39-19-9999',
        format: 'd-m-Y',
        minDate: true,
        timepicker: false,
        closeOnDateSelect: true,
        scrollMonth: false,
        scrollInput: false
    });

    $('.cargo_date').datetimepicker({
        //mask:'39-19-9999',
        format: 'd-m-Y',
        minDate: true,
        timepicker: false,
    });

    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    script.crossOrigin = "anonymous"
    index.parentNode.insertBefore(script, index)
}

// $('body').on('focus',".datetimepicker_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false
// 	});
// });

// $('body').on('focus',".cargo_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false,

// 	});
// });

$(document).on("input", ".num-cls", function() {
    this.value = this.value.replace(/\D/g,'');
})
$(document).on("input", ".mobnum-cls", function() {
    this.value = this.value.replace(/^\d{11}$/g,'');
})
$(document).on("input", ".alfa-cls", function () {
    this.value = this.value.replace(/[^a-z\s]+$/gi, '');
  });
//   $(document).on("input", ".dept-cls", function () {
//     this.value = this.value.replace(/[^a-z-\s]/gi, '');
//   });
  $(document).on("input", ".ringi-cls", function () {
    this.value = this.value.toUpperCase();
    this.value = this.value.replace(/[^\d[A-Z]-\s]/gi, '');
  });
$(document).on("change", "#cargoDate", function (e) {
    e.preventDefault();
    var bookingDate = $("#dateField").val();
    var cargoDate = $("#cargoDate").val();
    var bookingDate = bookingDate.split("-")[2] + "-" + bookingDate.split("-")[1] + "-" + bookingDate.split("-")[0]
    var cargoDate = cargoDate.split("-")[2] + "-" + cargoDate.split("-")[1] + "-" + cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    console.log(startTime, endTime);
    if (startTime >= endTime) {
        $("#cargoDate").val("");
        alert("Date should be greater than Booking Date");
    }
})
const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email.trim());
};
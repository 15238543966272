/* eslint-disable no-script-url */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { Link, IndexLink } from 'react-router-dom';
import Fullscreen from "react-full-screen";
import WeatherMapComponent from "../../layouts/weathermap";
// Import custom components
import Search from './search.component';
import UserMenu from './user-menu.component';
import Notification from './notification.component';
import Language from './language.component';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import $ from "jquery";
import StarIcon from './StarIconComponent';
import { withRouter } from 'react-router-dom';
import FavoritesListIcon from './FavoritesListIcon';
import SweetAlert from "react-bootstrap-sweetalert";
var redirectURL = require('../../redirectURL');

const RemoveFavourite = require("../../../assets/icons/remove_favourite_icon.svg");
const FavouriteFilled = require("../../../assets/icons/favourite_filled_icon.svg");

class Header extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            modules: AllModules,
            sidebar: false,
            topbar: false,
            navMenus: false,
            outerb: 'show-m',
            outerb1: 'show-n',
            overly: "show-n",
            sliderRouteTranslate: "",
            sliderTranslate: "",
            pbodyc: 'pbdy',
            tpmenu: require('../../../assets/icons/TOP-NAV.png'),
            sTop: 'tp40',
            mTop: 'tp10',
            showAlert: "show-m",
            tmenu: '',
            searchbar: false,
            loginType: "",
            userroles: "",
            departmentData: [],
            detailedWeatherMap: 0,
            selectedSlideInTable: "",
            favorites: [],
            showDeleteConfirm: false,  // To control SweetAlert visibility
            currentFavorite: null,     // To store the favorite being removed
        }
    }

    goFull = () => {
        if ((document.fullScreenElement && document.fullScreenElement !== null) ||
            (!document.mozFullScreen && !document.webkitIsFullScreen)) {
            if (document.documentElement.requestFullScreen) {
                document.documentElement.requestFullScreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullScreen) {
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    openCloseSidebar = () => {
        if (this.state.sidebar) {
            this.setState({ sidebar: false })
            document.querySelector(".page-body-wrapper").classList.add('sidebar-close');
        } else {
            this.setState({ sidebar: true })
            document.querySelector(".page-body-wrapper").classList.remove('sidebar-close');
        }
    }

    toggle() {
        this.setState(prevState => ({
            navMenus: !prevState.navMenus
        }));
    }


    openCloseTopbar = () => {
        if (this.state.topbar) {
            this.setState({
                topbar: false,
                tpmenu: require('../../../assets/icons/TOP-NAV.png'),
                outerb: "show-m",
                outerb1: "show-n",
                sTop: "40px",
                tmenu: '',
                mTop: 'tp10',

            })
            document.querySelector(".page-body").classList.remove('pbdy');
            document.querySelector(".page-main-header").classList.remove('topbar-close');
            document.querySelector('.page-sidebar').classList.remove("tp0");
            document.querySelector('.page-sidebar').classList.add("tp40");
        } else {
            this.setState({
                topbar: true,
                tpmenu: require('../../../assets/icons/topnav-blue.png'),
                outerb: "show-n",
                outerb1: "show-m",
                sTop: "0px",
                tmenu: '',
                mTop: 'tp40 r42',
            })
            document.querySelector(".page-body").classList.add('pbdy');
            document.querySelector(".page-main-header").classList.add('topbar-close');
            document.querySelector('.page-sidebar').classList.remove("tp40");
            document.querySelector('.page-sidebar').classList.add("tp0");
        }
    }


    componentWillMount() {
        var contentwidth = window.innerWidth;
        if ((contentwidth) <= 991) {
            this.setState({ sidebar: false })
        }
    }

    componentDidMount() {
        //console.log("Header Sidebar :", this.state.sidebar);
        var contentwidth = window.innerWidth;
        if ((contentwidth) <= 991) {
            document.querySelector(".page-body-wrapper").classList.add('sidebar-close');
        }
        this.setState({
            loginType: localStorage.getItem("user_type"),
            userroles: localStorage.getItem("roles"),
        })
        var loading_port_code = localStorage.getItem("loading_port_code");
        if (loading_port_code != undefined) {
            $("#loadingPlant").val(loading_port_code)
        }
        console.log(localStorage.getItem('dept_code'), "deptCode")
        console.log(localStorage.getItem('is_enmovil_transporter'), "is_enmovil_transporter")
        this.fetchFavorites();
    }

    fetchFavorites = async () => {
        const userId = localStorage.getItem('userid');
        try {
            const response = await redirectURL.post('/dashboard/getFavorites', { userId });
            if (response && response.data && Array.isArray(response.data.favorites)) {
                this.setState({ favorites: response.data.favorites });
            }
        } catch (error) {
            console.error('Error fetching favorites:', error);
        }
    };

    // Update the favorites state directly with the API response after adding or removing a favorite
    updateFavorites = (updatedFavorites) => {
        this.setState({ favorites: updatedFavorites });
    };

    changeHandler = (e) => {
        this.setState({
            sliderRouteTranslate: "slider-translate-60p",
            overly: "show-m",
        })
    }
    onChangeLoadingPlant = (e) => {
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m',
        })
        var selectedPlant = $("#loadingPlant").val();
        var params = {
            selectedPlant: selectedPlant,
        }
        redirectURL.post("/lmconsignees/getLoadingPlantDetails", params)
            .then((response) => {
                var res = response.data;
                this.setState({
                    loadshow: 'show-n',
                    overly: 'show-n',
                })
                localStorage.setItem('loading_port_code', selectedPlant);
                localStorage.setItem('comp_code', res[0].comp_code);
                window.location.reload();
            })
            .catch(function (e) {
                console.log("Error ", e)
            })
    }
    onClickHideAll = (event) => {
        this.setState({
            sliderRouteTranslate: "",
            sliderTranslate: "",
            overly: "show-n"
        })
    }
    closeMenuAlert() {
        this.setState({
            showAlert: "show-n"
        })
    }
    onCloseRouteDiv = (event) => {
        this.setState({
            sliderRouteTranslate: "",
            overly: "show-n"
        })
    }
    onShowUploadDiv = () => {
        //console.log("Click DCT Users Button")
        var requestParam = {
            userType: "DCTUSER"
        }
        redirectURL.post("/consignments/getUsersByUserType", requestParam)
            .then(async(response) => {
                var departments = response.data;
                var dctUsers = departments
                //console.log("records",records)
                if (departments.length > 0) {
                    redirectURL.post("/consignments/getUserLoginDetails", { "departments": dctUsers }).then((response) => {
                        console.log("response", response.data)
                        this.setState({
                            departmentData: response.data,
                            loadshow: 'show-n',
                            overly: 'show-n',
                        })
                    }).catch((e) => {
                        console.log(e);
                    })
                }
                else {
                    this.setState({
                        loadshow: 'show-n',
                        overly: 'show-n',
                    })
                }
                this.setState({
                    uploadDivWidth: '30%',
                    sliderTranslate: "slider-translate",
                    showDiv: 'show-m',
                });
            })
            .catch(function (e) {
                console.log("Error ", e)
            })

    }

    onCloseUploadDiv = () => {
        this.setState({
            uploadDivWidth: '0%',
            sliderTranslate: "",
            showDiv: 'show-n',
            selectedSlideInTable: "",
            overly: "show-n"
        });
    }

    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        var sort = [
            {
                colId: 'username',
                sort: 'asc',
            },
        ];
        this.gridApi.setSortModel(sort);
    };

    // Open slide-in modal for favorites
    onClickShowFavorites = async () => {
        const userId = localStorage.getItem('userid'); // Assuming userId is stored in localStorage

        await redirectURL
            .post("/dashboard/getFavorites", { userId }) // Call the getFavorites API
            .then((response) => {
                const { screen } = this.props;
                if (!response || !response.data || !Array.isArray(response.data.favorites)) {
                    console.error("Invalid response or favorites data.");
                    return;
                }
                console.log("Favorites:", response.data.favorites);  // Debugging log
                console.log("Current screen:", screen);  // Debugging log

                // Check if the screenPath matches the current screen in the favorites array
                const isFavorited = response.data.favorites.some(fav => fav.screenPath === screen);

                this.setState({
                    isFavorited: isFavorited, // Update favorite status
                    favorites: response.data.favorites,
                    loadshow: 'show-n',
                    overly: 'show-n',
                });
            })
            .catch(error => {
                console.error("Error fetching favorites:", error);
            });
        this.setState({
            uploadDivWidth: '30%',
            sliderTranslate: "slider-translate",
            showDiv: 'show-m',
            overly: "show-m",
        });
    }

    // Trigger SweetAlert to confirm removal of a favorite
    confirmDeleteFavorite = (screenPath, screenName) => {
        this.setState({
            showDeleteConfirm: true,
            currentFavorite: { screenPath, screenName }, // Store current favorite details
        });
    };

    removeFavorite = async () => {
        const userId = localStorage.getItem('userid'); // Assuming userId is stored in localStorage
        const { currentFavorite } = this.state;
        
        if (!currentFavorite) {
            console.error("No favorite selected for removal.");
            return;
        }

        const { screenPath, screenName } = currentFavorite;
    
        try {
            // Show loading state
            this.setState({ loading: true });
    
            // Call the addOrRemoveFavorite API to remove the favorite
            await redirectURL
                .post("/dashboard/addOrRemoveFavorite", { userId, screenPath, screenName }) // Use the addOrRemoveFavorite API
                .then((response) => {
                    if (!response || !response.data || !Array.isArray(response.data.favorites)) {
                        console.error("Invalid response or favorites data.");
                        return;
                    }

                    this.updateFavorites(response.data.favorites);
                })
                .catch(error => {
                    console.error("Error removing favorite:", error);
                });
        } finally {
            // Hide loading state
            this.setState({ loading: false, showDeleteConfirm: false, currentFavorite: null });
        }
    };
    
    // Function to render table data using map
    renderSlideInTableData() {
        const { selectedSlideInTable, favorites } = this.state;
        const cellStyle2 = {
            padding: '8px 14px',
            border: '1px solid #ddd',
            textAlign: 'center',
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        };

        if (selectedSlideInTable === 'favourites_list') {
            // let rowColor = "";

            // Map over the favorites array to create table rows
            const rows = favorites.map((item, index) => {
                // rowColor = index % 2 === 0 ? 'white' : '#F5F5F5';
                const {
                    screenPath,
                    screenName
                } = item; // Extracting screenPath and screenName from the favorite item

                return (
                    <tr key={index} style={{ backgroundColor: "white" }}>
                        <td style={cellStyle2}>
                            <Link to={screenPath} className="favourite_link">
                                <p>{screenName}</p>
                            </Link>
                            {/* <img
                                src={RemoveFavourite}
                                alt=""
                                width={"17px"}
                                style={{marginRight: "20px", cursor: "pointer"}}
                                // onClick={() => this.removeFavorite(screenPath, screenName)}  // Handle delete button click
                                onClick={() => this.confirmDeleteFavorite(screenPath, screenName)}  // Trigger SweetAlert on click
                            /> */}
                        </td>  {/* Display screenName */}
                    </tr>
                );
            });

            return rows;

        } else {
            return null;
        }
    }

    renderSlideInTable = () => {
        const { selectedSlideInTable } = this.state;

        const headerStyle = {
            backgroundColor: '#cdfff1',
            color: 'black',
            padding: '8px',
            border: '1px solid #ddd',
            display: "flex",
            alignItems: "center",
            gap: "5px"
        };

        switch (selectedSlideInTable) {
            case 'favourites_list':
                return (
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th style={headerStyle}>
                                    <img
                                        src={FavouriteFilled}
                                        alt=""
                                        width={"20px"}
                                    />
                                    <p>Favourites</p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderSlideInTableData()}
                        </tbody>
                    </table>
                );
            default:
                return <p>No table selected</p>;
        }
    };

    setSlideInTable = (value) => {
        // console.log("Selected Value: ", value);
        this.setState({
            selectedSlideInTable: value
        }, () => {
            this.onClickShowFavorites();
        })
    }

    render() {
        const { location } = this.props;  // Get the current screen path
        const { showDeleteConfirm, loading } = this.state;
        // console.log("Location: ", location);
        var columnwithDefsForDCT = [
            {
                headerName: "User Name",
                field: "username",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                sortable: true,
            },
            {
                headerName: "Email",
                field: "email",
                width: 250,
                filter: true,
                resizable: true,
                editable: false,
            },
            {
                headerName: "Phone",
                field: "phone",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
            },
            {
                headerName: "Online/Offline",
                field: "loginStatus",
                width: 140,
                filter: true,
                resizable: true,
                editable: false,
                cellRenderer: function (params) {
                    if (params.data.loginStatus == 1) {
                        return '<i class="fa fa-circle" style="color:green" </i>';
                    }
                    else {
                        return '<i class="fa fa-circle" style="color:yellow" </i>';
                    }
                },
            }
        ]
        const SearchIcon = require('../../../assets/images/dashboard/search.png');
        var searchStyle = {};
        if (localStorage.getItem('dept_code') == 'SNDG' || localStorage.getItem('dept_code') == '"SNDG"') {
            if (localStorage.getItem('roles').indexOf("Plant") >= 0 || localStorage.getItem('roles').indexOf("RSO") >= 0
                || localStorage.getItem('roles').indexOf("Loading Officer") >= 0
                || localStorage.getItem('roles').indexOf("Unloading Officer") >= 0
                || localStorage.getItem('roles').indexOf("BA") >= 0) {
                var dept = ''

            }
            else {
                var dept = 'Sales and Dispatch'

            }
        }
        if (localStorage.getItem('dept_code') == 'LOG-PRT' || localStorage.getItem('dept_code') == '"LOG-PRT"') {
            var dept = 'Spare Parts'
        }
        if (localStorage.getItem('dept_code') == 'LOG-TNP' || localStorage.getItem('dept_code') == '"LOG-TNP"') {
            var dept = 'Train and Production'
        }
        if (localStorage.getItem('dept_code') == 'LOG-SC' || localStorage.getItem('dept_code') == '"LOG-SC"') {
            var dept = 'Supply Chain'
        }
        var noPadStyle = {};
        if (localStorage.getItem('is_enmovil_transporter') != 1 && localStorage.getItem('is_load_management') != 1) {
            var noPadStyle = { "padding": "0px" };
        }
        let userType = localStorage.getItem('user_type') == 'TECHUSER'
        return (
            <>
            <div className={"overlay-part " + (this.state.overly)} onClick={this.onCloseRouteDiv.bind(this)}></div>
            <div className="page-main-header cus-main-header" id="pheader">
                <div className="main-header-left">
                    <div className="logo-wrapper">
                        <a href="/">
                            {(localStorage.getItem("is_enmovil_transporter") == 1 || window.location.hostname == "3.7.143.97") ?
                                <span>
                                    <img src={require('../../../assets/icons/logo.png')} alt="" />
                                    <span className="logo-caption">Powered by Enmovil &reg;</span>
                                </span>
                                :
                                <span>
                                    {(localStorage.getItem("is_load_management") == 1) ?
                                        <span>
                                            <img src={require('../../../assets/icons/optirun_v2.png')} alt="" />
                                            <span className="logo-caption">Powered by Enmovil &reg;</span>
                                        </span>
                                        :
                                        <img src={require('../../../assets/icons/new-logo.png')} alt="" />
                                    }
                                </span>
                            }
                        </a>
                    </div>
                </div>
                <div className="main-header-right row" style={noPadStyle}>
                    <div className="mobile-sidebar">
                        {/*<div className="media-body text-right switch-sm">
                            <label className="switch">
                                <input type="checkbox" id="sidebar-toggle" defaultChecked={this.state.sidebar} onClick={this.openCloseSidebar}  />
                                <span className="switch-state"></span>
                            </label>
                        </div>
                        */}
                    </div>
                    <div className="nav-right col">
                        <ul className={"nav-menus " + (this.state.navMenus ? 'open' : '')} >
                            <li style={{ fontSize: "20px" }}>
                                {dept}
                                {/* {(localStorage.getItem("loadingPointName") !=undefined) ? 
                                    <span>
                                    {(JSON.parse(localStorage.getItem("loadingPointName"))).length == 1 ?
                                    <span style={{fontSize:"16px"}}>Loading Plant : {(JSON.parse(localStorage.getItem("loadingPointName"))[0].loading_port)}</span>
                                    :
                                    <span style={{fontSize:"16px"}}>Loading Plant : 
                                        <select id="loadingPlant" onChange={this.onChangeLoadingPlant.bind(this)}>
                                            {JSON.parse(localStorage.getItem("loadingPointName")).map(function(obj){
                                                return <option value={obj.loading_port_code}>{obj.loading_port}</option>
                                            })}                                           
                                        </select>
                                    </span>
                                    }
                                    </span>
                                :""} */}
                            </li>
                            {((localStorage.getItem('is_load_management') == 1)) ? " " :
                                <li>
                                    <Search />
                                </li>
                            }

                            <li>
                                <a href="/" className="txt-dec-none clr333">
                                    <i className="icofont icofont-home"></i>
                                </a>
                            </li>

                            <li>
                                <StarIcon screen={(location && location.pathname) ? location.pathname : "" } favorites={this.state.favorites} updateFavorites={this.updateFavorites} />
                            </li>

                            <li>
                                <FavoritesListIcon setSlideInTable={this.setSlideInTable} />
                            </li>

                            {((localStorage.getItem('is_load_management') == 1)) ? " " :
                                <li>
                                    <a href="#" className="" onClick={this.changeHandler.bind(this)}>
                                        <i className="icofont icofont-ui-weather" style={{ color: "#000" }}></i>
                                    </a>
                                </li>
                            }

                            {((this.state.loginType != "TRANSPORTER") && (localStorage.getItem('is_load_management') != 1)) ?
                                <li>
                                    <a href="#" className="txt-dec-none clr333" onClick={this.onShowUploadDiv.bind(this)}>
                                        <i className="icofont icofont-support"></i>
                                    </a>
                                </li>
                                : ""
                            }

                            <Notification />
                            {((this.state.loginType == "TRANSPORTER") || (localStorage.getItem('is_load_management') == 1)) ? "" :
                                <li>
                                    <a href="http://3.6.131.222/mantis/mantisbt-2.25.2/manage_overview_page.php" target="_blank" className="btn btn-warning">Report Issue</a>
                                    {/* <a href="/mantis" target="_blank" className="btn btn-warning">Report Issue</a> */}
                                </li>
                            }
                            {(localStorage.getItem('is_load_management') == 1) ?
                                <li>
                                    <a title="Help" target="_blank" href={require('../../../assets/json/Enmovil_User_Guide_OptiRun_v0.20.pdf')}>
                                        <i className="icofont icofont-question-square" style={{ color: "#000" }}></i>
                                    </a>
                                </li>
                                : ""}
                            <UserMenu />
                        </ul>

                        <div className="d-lg-none mobile-toggle" onClick={() => this.toggle()}>
                            <i className="icon-more"></i>
                        </div>
                    </div>
                    {(window.location.hostname == "3.7.143.97") ?
                        "" :
                        <div className="">
                            {(localStorage.getItem("is_enmovil_transporter") != 1) ?

                                userType ? "" :
                                    <img src={require('../../../assets/icons/maruti-logo.png')}
                                        className="logo-login" alt="" />


                                : ""}
                        </div>
                    }

                </div>
                {this.state.sliderRouteTranslate != '' ?
                    <WeatherMapComponent context={this}
                        sliderValue={this.state.sliderRouteTranslate}
                        onCloseRouteDiv={this.onCloseRouteDiv.bind(this)}
                        detailedWeatherMap={this.state.detailedWeatherMap}
                    />
                    : ""
                }
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                <a href={"javascript:void(0)"} className={(this.state.tmenu)} id="tmenu">
                    <div id="outer" className={(this.state.outerb)}><div id="inner">&nbsp;</div></div>
                    <div id="outer1" className={(this.state.outerb1)}><div id="sinner">&nbsp;</div></div>
                    <img src={this.state.tpmenu} className={"topmenu " + (this.state.mTop)} defaultChecked={this.state.topbar} onClick={this.openCloseTopbar} alt="" />
                </a>
                {/* { (localStorage.getItem("roles").indexOf("led") >= 0)?"":
                <div className={this.state.showAlert}>
                    <span className="close-menu-alert closebtn" onClick={this.closeMenuAlert.bind(this)} >X</span>
                    <span className="nav-left-arrow"><i className="fa fa-caret-left fa fa-2x"></i></span>
                    <div className="nav-tooltip">
                        <span>Click here to see Menu </span>
                    </div>
                </div>
                
                } */}
                {
                    this.state.selectedSlideInTable === "favourites_list" ? (<div className={"slide-r " + (this.state.sliderTranslate)} style={{ width: "40%" }}>
                    <div className="slide-r-title">
                        <h4>
                            List of Favourites
                            <span onClick={this.onCloseUploadDiv.bind(this)} className={"btn btn-warning c-btn pull-right " + (this.state.sliderTranslate)}>X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className={"dataLoad " + (this.state.loadshow)}>
                            <div style={{ position: "absolute", left: "38%" }}>
                                <div class="loader-box">
                                    <div class="loader-box">
                                        <div class="rotate dashed colored"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                            <div className="favourites_table_container" style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                {this.renderSlideInTable()}
                                <button className="btn btn-danger" style={{ marginTop: "20px", marginRight: "20px", alignSelf: "center" }} onClick={this.onCloseUploadDiv.bind(this)}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>) : (<div className={"slide-r " + (this.state.sliderTranslate)} style={{ width: "55%" }}>
                    <div className="slide-r-title">
                        <h4>
                            DCT Users
                            <span onClick={this.onCloseUploadDiv.bind(this)} className={"btn btn-warning c-btn pull-right " + (this.state.sliderTranslate)}>X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className={"dataLoad " + (this.state.loadshow)}>
                            <div style={{ position: "absolute", left: "38%" }}>
                                <div class="loader-box">
                                    <div class="loader-box">
                                        <div class="rotate dashed colored"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-12 col-lg-12">
                            <div id="myGrid" style={{ width: "100%", height: "478px" }} className="ag-theme-balham">
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefsForDCT}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.departmentData}
                                    enableCharts={false}
                                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    // components={this.state.components}
                                    enableRangeSelection={true}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                />

                            </div>
                        </div>
                    </div>
                </div>)
                }
                
            </div>
            {/* SweetAlert for delete confirmation */}
            {showDeleteConfirm && (
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes, remove it!"
                        confirmBtnBsStyle="danger"
                        onConfirm={this.removeFavorite}   // Call removeFavorite if confirmed
                        onCancel={() => this.setState({ showDeleteConfirm: false })}  // Hide SweetAlert if canceled
                        focusCancelBtn
                    >
                        Are you sure you want to remove this screen from favorites?
                    </SweetAlert>
                )}
            </>
        );
    }
}
$(document).ready(function () {
    $("#sidebar-toggle").click(function () {
        $(".nav-left-arrow").css("display", "none");
        $(".nav-tooltip").css("display", "none");
        $(".close-menu-alert").css("display", "none");
    });
    $(".sidebaropen-icon").click(function () {
        setTimeout(function () {
            $(".nav-left-arrow").css("display", "block");
            $(".nav-tooltip").css("display", "block");
            $(".close-menu-alert").css("display", "block");
        }, 1000)

    });
})


export default withRouter(Header)

import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
var redirectURL = require("../redirectURL");
export default class OperatingClusters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      basicTitle: '',
      basicType: "default",
      overly: "show-n",
      loadshow: "show-n",
      sidebartitle: "",
      totalData: [],
      filterType: 'All',
      rowData: [],
      sliderRouteTranslate: '',
      totalSumedUpValues: [],
      facility: "All Facilities",
      facilitiesList: [{ "label": "All Facilities", "value": "All" },
      { "label": "Haryana", "value": "H" },
      { "label": "Bidadi", "value": "BP" },
      { "label": "Gujarat", "value": "G" },
      ]
    }
  }

  componentDidMount() {
    var transporterCode = window.atob(this.props.match.params.transporterCode)
    console.log(transporterCode, 'transporterCode');
    this.setState({
      loadshow: "show-m",
      overly: "show-m"
    })
        var params = {
          transporter_code: transporterCode
        };
        redirectURL
          .post("/dashboard/operatingClustersMountData", params)
          .then((response) => {
            console.log(response.data.data,"totalData",response.data.data.BIDADI)
            this.setState({
              totalData : response.data.data,
              loadshow: "show-n",
              overly: "show-n"
            })
          
      })
      .catch((error) => {
        console.error(error);
      });

  }

//   newFilter = (event) => {
//     this.setState({
//       filterType: event.value,
//       loadshow: 'show-m',
//       overly: 'show-m',
//     })

//     var plantCode = event.value;



//     var total;
//     console.log(plantCode, 'plantCode')
//     var params = {
//       plant_code: plantCode,
//       transporter_code: this.state.transporter_code
//     };
//     console.log(params, 'params')
//     redirectURL
//       .post("/consignments/invoicetoexitfilter", params)
//       .then((response) => {
//         console.log(response.data);


//         const filteredData = response.data
//           .filter(item => item.month && item.month.startsWith('FY'))
//           .sort((a, b) => {
//             const yearA = extractYear(a.month);
//             const yearB = extractYear(b.month);
//             return yearA - yearB;
//           });


//         const aggregatedData = {}

//         filteredData.forEach((ele) => {
//           const year = ele.month;
//           const [month, yearRange] = year.split(' ');
//           if (!aggregatedData[year]) {
//             aggregatedData[year] = {
//               month: year,
//               data: [],
//             };
//           }

//           aggregatedData[year].data.push(ele.invoice_to_exit_hrs);
//         });

//         const aggregatedDataArray = Object.values(aggregatedData);
//         // console.log(aggregatedDataArray,'aggregatedDataArray')

//         aggregatedDataArray.sort((a, b) => {

//         });
//         const sortedAndFilteredArray = aggregatedDataArray.filter(item => item.month === 'FY 24-25');

//         // console.log(sortedAndFilteredArray, 'sortedAndFilteredArray');


//         const currentFiscalYearMonthsData = {};

//         response.data.forEach((item) => {
//           const month = item.month;

//           if (!month.startsWith('FY')) {

//             if (!currentFiscalYearMonthsData[month]) {
//               currentFiscalYearMonthsData[month] = [];
//             }
//             // Add the TOTAL value for this month
//             if (item.invoice_to_exit_hrs && item.invoice_to_exit_hrs !== null) {
//               currentFiscalYearMonthsData[month].push(item.invoice_to_exit_hrs);
//             }
//           }
//         });


//         const monthsToExtract = DynamicSortOfMonths()


//         const filteredDataArray = [];
//         monthsToExtract.forEach(month => {
//           if (currentFiscalYearMonthsData.hasOwnProperty(month)) {
//             if (currentFiscalYearMonthsData[month].length > 0) {
//               filteredDataArray.push({
//                 month: month,
//                 data: currentFiscalYearMonthsData[month]
//               });
//             }
//           }
//         });


//         const joinedArray = sortedAndFilteredArray.concat(filteredDataArray);

//         this.setState({
//           totalSumedUpValues: joinedArray,
//           loadshow: 'show-n',
//           overly: 'show-n',
//         });
//       })
//       .catch((error) => {
//         console.error(error);
//       });

//   }


//   onClickCounter(data) {
//     //this.props.context.onClickCounterShowData(data)

//   }

//   handleDrilldown = (params) => {
//     console.log("Here ,", params)
//   }

//   filterDataByCategory = async (category) => {
//     this.setState({ loadshow: 'show-m', overly: 'show-m' }); // Show loading state
//     console.log(category, 'category')
//     var params = {
//       category: category,
//       transporter_code: this.props.match.params.transporterCode,
//       consigner_code: this.state.filterType
//     }
//     console.log(params, 'paramssssss')
//     try {
//       const response = await redirectURL.post("/consignments/getInvoiceexitdatalspceo", params);
//       console.log(response, 'response', response.data.data)
//       if (response.data.data.length > 0) {
//         this.setState({
//           totalData: response.data.data,
//           rowData: response.data.data,
//           sliderRouteTranslate: 'slider-translate-75p',
//           loadshow: 'show-n',
//           overly: 'show-n'
//         });
//         console.log(this.state.rowData, 'rowData')
//       } else {
//         this.setState({
//           show: true,
//           basicTitle: "No data to show",
//           basicType: "warning",
//           loadshow: 'show-n',
//           overly: 'show-n'
//         })
//       }
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       this.setState({ loadshow: 'show-n', overly: 'show-n' }); // Hide loading state on error
//     }
//   }

  onCloseRouteDiv = () => {
    this.setState({
      sliderRouteTranslate: "",
      showDiv: 'show-n',
      loadshow: "show-n",
      overly: "show-n",
    });
  }
//   closeAlert = () => {
//     this.setState({
//       show: false
//     });
//   }
  render() {
    const cellStyle = {
      // padding: '8px',
      border: '1px solid #000',
      // textAlign: 'center'
  };
    return (
     <div className='row'>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        >
        </SweetAlert>
        <div style={{ textAlign: "center", background: "#171C8F", width: '100%', margin: "5px 11px 20px -12px", borderRadius: "5px", height: '70px', paddingBottom: '25px'}}>
          <h6 class="txt-dark f40" style={{ marginTop: "0px" }}>
            <span style={{ fontSize: "20px", color: "#FFFFFF" }}>
              <span>Operating Clusters</span>

            </span>
          </h6>
        </div>
        <div className="col-xl-12 col-lg-12">
            <h5>
              <span className="float-right f12 ">
                <a className="btn btn-danger" style={{ float: "left", marginLeft: "10%" }} href="/lspceodashboardTest">Back</a>
              </span>
            </h5>
          </div>
        <div className="card col-xl-12 col-lg-12" style={{marginTop:'10px'}}>
        <table className="table" style={{width:'80%',margin:'80px',marginLeft:'160px'}}>
  <tr style={{ backgroundColor: 'white' }}>
    <th style={cellStyle}>OPERATING CLUSTERS (GURGAON)</th>
    <td style={cellStyle}>{this.state.totalData.GURGAON && this.state.totalData.GURGAON.length > 0 ? this.state.totalData.GURGAON.join(', ') : 'No data'}</td>
  </tr>
  <tr style={{ backgroundColor: '#F5F5F5' }}>
    <th style={cellStyle}>OPERATING CLUSTERS (GUJARAT)</th>
    <td style={cellStyle}>{this.state.totalData.GUJARAT && this.state.totalData.GUJARAT.length > 0 ? this.state.totalData.GUJARAT.join(', ') : 'No data'}</td>
  </tr>
  <tr style={{ backgroundColor: 'white' }}>
    <th style={cellStyle}>OPERATING CLUSTERS (BIDADI)</th>
    <td style={cellStyle}>{this.state.totalData.BIDADI && this.state.totalData.BIDADI.length > 0 ? this.state.totalData.BIDADI.join(', ') : 'No data'}</td>
  </tr>
  <tr style={{ backgroundColor: '#F5F5F5' }}>
    <th style={cellStyle}>OPERATING CLUSTERS (TVPB)</th>
    <td style={cellStyle}>{this.state.totalData.TVPB && this.state.totalData.TVPB.length > 0 ? this.state.totalData.TVPB.join(', ') : 'No data'}</td>
  </tr>
  <tr style={{ backgroundColor: 'white' }}>
    <th style={cellStyle}>OPERATING CLUSTERS (TVPN)</th>
    <td style={cellStyle}>{this.state.totalData.TVPN && this.state.totalData.TVPN.length > 0 ? this.state.totalData.TVPN.join(', ') : 'No data'}</td>
  </tr>
  <tr style={{ backgroundColor: '#F5F5F5' }}>
    <th style={cellStyle}>OPERATING CLUSTERS (TVPS)</th>
    <td style={cellStyle}>{this.state.totalData.TVPS && this.state.totalData.TVPS.length > 0 ? this.state.totalData.TVPS.join(', ') : 'No data'}</td>
  </tr>
</table>

</div>

        <div className={"dataLoadpage " + (this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
          <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part " + (this.state.overly)} onClick={this.onCloseRouteDiv.bind(this)}></div>


      </div>


    );
  }
}


function DynamicSortOfMonths() {
  const currentDate = new Date();
  const fiscalYearStartMonth = 3;
  let currentFiscalYear;
  if (currentDate.getMonth() < fiscalYearStartMonth) {
    currentFiscalYear = currentDate.getFullYear() - 1;
  } else {
    currentFiscalYear = currentDate.getFullYear();
  }

  const fiscalYearMonths = [];
  const monthNames = [
    "Apr", "May", "Jun", "Jul", "Aug", "Sep",
    "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"
  ];

  // Dynamically generate the order array based on the current fiscal year
  const order = [];
  for (let i = fiscalYearStartMonth; i < fiscalYearStartMonth + 12; i++) {
    const monthName = monthNames[i % 12];
    const year = (monthName === "Jan" || monthName === "Feb" || monthName === "Mar") ? currentFiscalYear + 1 : currentFiscalYear;
    order.push(`${monthName}-${String(year).substring(2)}`);
  }

  for (let month = fiscalYearStartMonth; month < fiscalYearStartMonth + 12; month++) {
    const monthName = monthNames[month % 12];
    const year = (monthName === "Jan" || monthName === "Feb" || monthName === "Mar") ? currentFiscalYear + 1 : currentFiscalYear;
    fiscalYearMonths.push(`${monthName}-${year}`);
  }

  // Sort fiscal year months based on the dynamically generated order
  fiscalYearMonths.sort((a, b) => {
    const [monthA, yearA] = a.split('-');
    const [monthB, yearB] = b.split('-');
    // Compare years first
    if (yearA !== yearB) {
      return parseInt(yearA) - parseInt(yearB);
    }
    // If years are the same, compare months
    return monthNames.indexOf(monthA) - monthNames.indexOf(monthB);
  });

  fiscalYearMonths.forEach((month, index) => {
    const [monthName, year] = month.split('-');
    const shortYear = year.substring(2);
    console.log(index, 'index', monthName, shortYear)
    fiscalYearMonths[index] = `${monthName}-${shortYear}`;
  });
  console.log(fiscalYearMonths, 'fiscalYearMonths')
  return fiscalYearMonths;
}
function extractYear(month) {
  const match = month.match(/FY (\d{2})-(\d{2})/);
  if (match) {
    // Convert to a number where '20xx' is represented as '20xx' (e.g., '2023')
    return parseInt(`20${match[1]}`, 10) * 100 + parseInt(match[2], 10);
  }
  return 0; // Default value if format is unexpected
}
'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "./updateshipmentDSR";
import DeleteButton from "./deleteshipmentDSR";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CellGraphComponent from './cellGraphComponent';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { HardwareDesktopWindows } from 'material-ui/svg-icons';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from "@fullcalendar/timegrid";    
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;


export default class ImportsShipments extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          consignmentforceclose:Consignmentforceclose,
          consignmentActions:ConsignmentActions,
          UpdateButton:UpdateButton,
          MarkDeliver : MarkDeliver,
          commentActions : CommentActions,
          DeleteButton:DeleteButton
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        plants:[],
        plant_name:"",
        countryCodes:[],
        compCodes:[],
        incoTerms:[],
        containerTypes:[],
        loadingPorts:[],
        dischargePorts:[],
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m"
        //components : {datepicker:getDatePicker()}
    }
    this.changeFileHandler = this.changeFileHandler.bind(this);
    this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this)
    this.deleteImportShipmentDSR = this.deleteImportShipmentDSR.bind(this);
    this.onLoadShowData = this.onLoadShowData.bind(this);
  }

componentDidMount(){
    loadDateTimeScript();
    this.onLoadShowData();

    redirectURL.post("/consignments/getloadingports")    
    .then((response) => {
        var countryCodes = response.data;
        this.setState({
            countryCodes : countryCodes,
        })
    }).catch((e)=>{
        console.log(e);
    })

    redirectURL.post("/consignments/getcompanycodes")    
    .then((response) => {
        var compCodes = response.data;
        //console.log("records ", records)
        this.setState({
            compCodes : compCodes,
        })
    }).catch((e)=>{
        console.log(e);
    })

    redirectURL.post("/consignments/getImportTerms")    
    .then((response) => {
        var incoTerms = response.data;
        //console.log("records ", records)
        var incoTermsarr= []
        if(incoTerms.length > 0)
        {
            incoTerms.map((item) => {
                if(item == "FOB" || item == "EXW")
                {
                    incoTermsarr.push(item)
                }
            })
        }
        this.setState({
            incoTerms : incoTermsarr,
        })
    }).catch((e)=>{
        console.log(e);
    })

    redirectURL.post("/consignments/getContainerTypes")    
    .then((response) => {
        var containerTypes = response.data;
        //console.log("records ", records)
        this.setState({
            containerTypes : containerTypes,
        })
    }).catch((e)=>{
        console.log(e);
    })

    redirectURL.post("/consignments/getLoadingPortsNames")    
    .then((response) => {
        var loadingPorts = response.data;
        //console.log("records ", records)
        this.setState({
            loadingPorts : loadingPorts,
        })
    }).catch((e)=>{
        console.log(e);
    })

    redirectURL.post("/consignments/getDischargePortsNames")    
    .then((response) => {
        var dischargePorts = response.data;
        dischargePorts.push("Chennai","Kattupalli");
        //console.log("records ", records)
        this.setState({
            dischargePorts : dischargePorts,
        })
    }).catch((e)=>{
        console.log(e);
    })
}
    
onLoadShowData(){
    var useremail = localStorage.getItem("email");
    var username = localStorage.getItem("username");
    var email = useremail.split("@")
    var parameters = {
        ffname : email[1],
        username:username
    }
    redirectURL.post("/consignments/getImportsShipmentDSR",parameters).then((response)=>{
        console.log(response.data);
        this.setState({
            rowData : response.data
        })
    }).catch((e)=>{
        console.log(e);
    })
}
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
    * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
            
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
}

closeAlert = () => {
    this.setState({
        show: false
    });
}

onCellClicked(e)
{
    if(e.colDef.field == "container_no")
    {
        console.log(e.data.container_no);
    }
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    
}
updateImportShipmentDSR(params)
{
    //console.log(params);
    if(params.comp_code !='' && params.supplier_code !="" && params.country_code !="" && params.invoice_no !="" && params.invoice_date !="" && params.inco_terms !="" && params.type_of_shipment !="" && params.container_size !="" && params.ff_code !="" && params.shipping_line_code !="" && params.mbl_No !="" && params.hbl_no !="" && params.bl_date !="" && params.container_nos !="" && params.port_of_loading !="" && params.port_of_discharge !="" && params.final_destination !="" && params.etd_pol !="" && params.eta_pod !="")
    {
       //console.log(setter)
        if(params.container_nos !='')
        {
            if(params.container_nos.length == 11)
            {
                if (params.container_nos.match(/[^a-zA-Z0-9]/g)) {
                    var setter=2
                }
                else
                {
                    var setter=1;
                }
            } 
            else
            {
                var setter=0;
            }
        }
        else
        {
            var setter=1;
        }
        //console.log(setter)
        if(setter == 1)
        {
            params.email=localStorage.getItem("email");
            params.username=localStorage.getItem("username");
            
            redirectURL.post("/consignments/saveimportsShipmentDSR",params).then((response)=>{
                console.log(response);
                if(response.data.message == "Success")
                {
                    this.setState({
                        basicTitle:"Updated Successfully.",
                        basicType : "success",
                        show : true
                    })
                    this.onLoadShowData();
                    //window.location.reload();
                }
                else
                {
                    this.setState({
                        basicTitle:"Failed to update data.",
                        basicType : "warning",
                        show : true
                    })
                }
            })
        }
        else if(setter == 2)
        {
            this.setState({
                basicTitle:"Special characters are not allowed in Container No",
                basicType : "warning",
                show : true
            })
        }
        else if(setter == 0)
        {
            this.setState({
                basicTitle:"Container No should be 11 characters",
                basicType : "warning",
                show : true
            })
        }
    }
    else
    {
        this.setState({
            basicTitle:"Fill up the Mandatory Fields.",
            basicType : "warning",
            show : true
        })
    }
}

deleteImportShipmentDSR(params)
{
    var cnf = window.confirm("Are you sure you want to delete item?")
    if(cnf == true)
    {
        console.log(params);
        var query = {
            rowid:params._id,
            container_nos:params.container_nos,
            hbl_no:params.hbl_no,
            rowdata:params,
            useremail:localStorage.getItem("email"),
            username:localStorage.getItem("username")
        }
        redirectURL.post("/consignments/removeimportsShipmentDSR",query)
        .then((response)=>{
            console.log("response ", response.data)
            this.onLoadShowData();
        })
    }
    
}
addNewRow()
{
    // console.log(this.gridApi);
    this.gridApi.insertItemsAtIndex(0,[{comp_code:"",supplier_code:"",country_code:"",invoice_no:"",invoice_date:"",inco_terms:"",type_of_shipment:"",container_size:"",ff_code:"",shipping_line_code:"",mbl_No:"",hbl_no:"",bl_date:"",container_nos:"",port_of_loading:"",port_of_discharge:"",final_destination:"",etd_pol:"",eta_pod:"",atd_pol:"",ata_pod:"",mode:"",rail_road_atd:"",remarks:""}])
    // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}

showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate"
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}
changeFileHandler = async (e) => {
    var dt = '';
    const config = {
        headers: [
            { 
                name: 'holiday_date',
                inputName: 'holiday_date',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'holiday_name',
                inputName: 'holiday_name',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            
        ]
    }
    var data = CSVFileValidator(e.target.files[0], config)
    .then((csvData) => {
        this.setState({
            csvData:csvData.data
        });
        console.log(csvData.data);
    })
    .catch(err => {})
    // console.log("e.target.files[0]",e.target.result);
    
    var out = new Promise(function(reject, resolve){
        var reader =  new FileReader();
        reader.onload = async function(e) {
            var contents = await e.target.result;
            resolve(contents);
        };
        var tt =  reader.readAsText(e.target.files[0]);
    });
    this.setState({
        file:e.target.files[0]
    });
    // console.log("e.target.files[0].type ", e.target.files[0]);

    if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
    {
        // console.log(fileData);
        // console.log(typeof(fileData))
        
    }
    else{
        e.target.value = null;
        this.setState({
            uploadFile:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'Please upload file having extensions .csv only.',
        });
    }
    
}

uploadBulkFormHandler(event){
    event.preventDefault();
    var fileData = this.state.csvData;
    var flag=0;
    var plantName= this.state.plant_name;
    var holiday_date_flag = 0;
    console.log(plantName);
    console.log(fileData,"fileData");
    fileData.map(function(e,index){
        if(index != 0)
        {
            
            if(e.holiday_date == "" || e.holiday_name == "")
            {
                flag = 1;
                console.log(e);
            }
            console.log(e.holiday_date,"e.holiday_date")
            if(flag == 0)
            {
                var holidaydate = e.holiday_date;
                console.log(holidaydate);
                if(holidaydate.indexOf("/"))
                {
                    holidaydate = holidaydate.split("/");
                    if(holidaydate[0].length !== 2 || holidaydate[1].length !== 2 || holidaydate[2].length !== 4)
                    {
                        holiday_date_flag = 1;
                    }
                    
                    if(holiday_date_flag == 0)
                    {
                        e.holiday_date = holidaydate[2]+"-"+holidaydate[1]+"-"+holidaydate[0];
                    }
                }
                else if(holidaydate.indexOf("-"))
                {
                    holidaydate = holidaydate.split("-");
                    if(holidaydate[0].length !== 2 || holidaydate[1].length !== 2 || holidaydate[2].length !== 4)
                    {
                        holiday_date_flag = 1;
                    }
                    // if(holiday_date_flag == 0)
                    // {
                    //     holidaytime = holidaytime.split(":");
                    //     if(holidaytime[0].length !== 2 || holidaytime[1].length !== 2 || holidaytime[2].length !== 2)
                    //     {
                    //         holiday_date_flag = 1;
                    //     }
                    // }
                    if(holiday_date_flag == 0)
                    {
                        e.holiday_date = holidaydate[2]+"-"+holidaydate[1]+"-"+holidaydate[0];
                    }
                }
                else{}

                
            }
            
            
        }
    });
    if(flag == 0 && holiday_date_flag == 0)
    {
        var reqparams = {
            "fileData" : fileData,
            "dept_code" : this.state.dept_code,
            "plantName" : plantName
        }
        console.log(reqparams);
        redirectURL.post("/consignments/updateBulkHolidays",reqparams).then((response)=>{
            this.setState({
                basicTitle : "Uploaded SuccessFully",
                basicType : "success",
                plant_name : "",
                show : true,
            });
            window.location.reload();
        });
    }
    else
    {
        if(flag == 1)
        {
            this.setState({
                basicTitle : "Fields Should not be Empty",
                basicType : "warning",
                show : true,
            })
        }
        if(holiday_date_flag == 1)
        {
            this.setState({
                basicTitle : "Incorrect Date Time Format in Holiday Date",
                basicType : "warning",
                show : true,
            })
        }
       
        
    }
};
locationoptions(){
    var items=[];
   // console.log("locations ",this.props.locations)
    var locations = this.state.plants;
    //console.log("locations ",locations)
    if(locations.length > 0){
        // items.push({value:'all',label:'All'}); 
        locations.forEach(item =>{
            items.push({value:item.label,label:item.label}); 
        });
    }
    //console.log("Locas ", items)
    return items;
}

onSelectLocation= (location) =>{
    var plants=location.value;
    try{
        // location.map(function(e){
            // plants.push(e.value);
        // })
        console.log(plants);
        this.setState({
            plant_name : plants
        });
        var params = {
            plants : plants,
            dept_code : this.state.dept_code
        }
        redirectURL.post("/consignments/getHolidays",params).then((response)=>{
            console.log(response.data);
            var events = response.data;
            var holidayEvents = [];
            events.map(function(e){
                holidayEvents.push({"title":e.holiday_name,"date":e.holiday_date,"rowid":e._id})
            })
            this.setState({
                rowData : response.data,
                holidayEvents :holidayEvents
            })
        }).catch((e)=>{
            console.log(e);
        })
    }
    catch(e)
    {
        console.log(e);
    }
    
}

onSelectBulkPlants= (location) =>{
    var plants=[];
    try{
        location.map(function(e){
            plants.push(e.value);
        })
        console.log(plants);
        this.setState({
            plant_name : plants
        });
    }
    catch(e)
    {
        console.log(e);
    }
    
}

 
  render() {
    
    var columnwithDefs = [
        {
            headerName : "Update",
            field:"_id",
            width:130,
            resizable : true,
            editable : false,
            cellRendererSelector:function(params){       
                var rendComponent = {
                    component: 'UpdateButton'
                };
                return rendComponent;
            },
        },
        
        {
            headerName : "Delete",
            field:"_id",
            width:130,
            resizable : true,
            editable : false,
            cellRendererSelector:function(params){       
                var rendComponent = {
                    component: 'DeleteButton'
                };
                return rendComponent;
            },
        },      
        {
            headerName: "Company Code",
            field: "comp_code",
            width:130,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: this.state.compCodes,
            },       
        },

        {
            headerName: "Supplier Code",
            field: "supplier_code",
            width:180,
            filter: true,
            resizable: true,
            editable:true
        },
         
        {
            headerName: "Country Code",
            field: "country_code",
            width:130,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: this.state.countryCodes,
            },
        },
        {
            headerName: "Invoice No",
            field: "invoice_no",
            width:130,
            filter: true,
            resizable: true,
            editable:true
        },         
        {
            headerName: "Invoice Date",
            field: "invoice_date",
            width:130,
            resizable: true,
            editable:true,
            cellEditor: DateEditor,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.invoice_date != null)
                {
                    if(moment(params.data.invoice_date,'DD-MM-YYYY',true).isValid() == false)
                    {
                        return getHyphenDDMMMYYYY(params.data.invoice_date);
                    }
                    else{
                        return params.data.invoice_date;
                    }
                    
                }
                else
                {
                    return ""
                }

            },
            comparator: dateComparator1,
        },
        {
            headerName: "Inco Terms",
            field: "inco_terms",
            width:130,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: this.state.incoTerms,
            },
        },         
        {
            headerName: "Type of Shipment",
            field: "type_of_shipment",
            width:130,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ["FCL"],
            },
        },
        {
            headerName: "Container Size",
            field: "container_size",
            width:200,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: this.state.containerTypes,
            },
        },
        {
            headerName: "FF Name",
            field: "ff_code",
            width:130,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: [localStorage.getItem("username")],
            },
        },
        {
            headerName: "Shipping Line Code",
            field: "shipping_line_code",
            width:130,
            filter: true,
            resizable: true,
            editable:true
        },
        {
            headerName: "Mbl No",
            field: "mbl_No",
            width:150,
            filter: true,
            resizable: true,
            editable:true
        },
        {
            headerName: "Hbl No",
            field: "hbl_no",
            width:130,
            filter: true,
            resizable: true,
            editable:true
        },
        {
            headerName: "Bl Date",
            field: "bl_date",
            width:130,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: DateEditor,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.bl_date != null)
                {
                    if(moment(params.data.bl_date,'DD-MM-YYYY',true).isValid() == false)
                    {
                        return getHyphenDDMMMYYYY(params.data.bl_date);
                    }
                    else{
                        return params.data.bl_date;
                    }
                    
                }
                else
                {
                    return ""
                }

            },
            comparator: dateComparator1,
        },
        {
            headerName: "Container No",
            field: "container_nos",
            width:130,
            filter: true,
            resizable: true,
            editable:true
        },
        {
            headerName: "Port of Loading",
            field: "port_of_loading",
            width:180,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: this.state.loadingPorts,
            },
        },   
        {
            headerName: "Port of Discharge",
            field: "port_of_discharge",
            width:130,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: this.state.dischargePorts,
            },
        },
        {
            headerName: "Final Destination",
            field: "final_destination",
            width:130,
            filter: true,
            resizable: true,
            editable:true
        },
        {
            headerName: "Etd Pol",
            field: "etd_pol",
            width:130,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: DateEditor,
            valueGetter:function(params){
                try{
                    if(params.data.etd_pol != '' && params.data.etd_pol != undefined)
                    {
                        return getHyphenDDMMMYYYY(params.data.etd_pol);
                    }
                }
                catch(e){
                    return "";
                }
            }
        },
        {
            headerName: "Atd Pol",
            field: "atd_pol",
            width:130,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: DateEditor,
            valueGetter:function(params){
                try{
                    if(params.data.atd_pol != '' && params.data.atd_pol != undefined)
                    {
                        return getHyphenDDMMMYYYY(params.data.atd_pol);
                    }
                }
                catch(e){
                    return "";
                }
            }
        },
        {
            headerName: "Eta Pod",
            field: "eta_pod",
            width:130,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: DateEditor,
            valueGetter:function(params){
                try{
                    if(params.data.eta_pod != '' && params.data.eta_pod != undefined)
                    {
                        return getHyphenDDMMMYYYY(params.data.eta_pod);
                    }
                }
                catch(e){
                    return "";
                }
            }
        },
        {
            headerName: "Ata Pod",
            field: "ata_pod",
            width:130,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: DateEditor,
            valueGetter:function(params){
                try{
                    if(params.data.ata_pod != '' && params.data.ata_pod != undefined)
                    {
                        return getHyphenDDMMMYYYY(params.data.ata_pod);
                    }
                }
                catch(e){
                    return "";
                }
            }
        },
        {
            headerName: "Mode",
            field: "mode",
            width:130,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ["RAIL","ROAD"],
            },
        },
        {
            headerName: "Rail/Road ATD",
            field: "rail_road_atd",
            width:130,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: DateEditor,
            valueGetter:function(params){
                try{
                    if(params.data.rail_road_atd != '' && params.data.rail_road_atd != undefined)
                    {
                        return getHyphenDDMMMYYYY(params.data.rail_road_atd);
                    }
                }
                catch(e){
                    return "";
                }
            }
            
        },
        {
            headerName: "Remarks",
            field: "remarks",
            width:130,
            filter: true,
            resizable: true,
            editable:true
        },
        
        
        {
            headerName: "Created By",
            field: "updated_by_username",
            width:180,
            filter: true,
            resizable: true,
            editable:false
        },
        
        {
            headerName: "Created on",
            field: "updated_on",
            width:180,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.updated_on != null)
                {
                    if(moment(params.data.updated_on,'DD-MM-YYYY',true).isValid() == false)
                    {
                        return getHyphenDDMMMYYYY(params.data.updated_on);
                    }
                    else{
                        return params.data.updated_on;
                    }
                    
                }
                else
                {
                    return ""
                }

            },
        } 
    ]
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="card col-xl-12">
            <div className="card-header">
                {/* <h5 style={{width:"75.5%",float:"left"}}>
                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Holiday Calendar </span>
                </h5> */}
                <button className="btn btn-success" style={{float:"right"}} onClick={this.addNewRow.bind(this)}>Add New Import shipment DSR</button>
                {/* <button className="btn btn-info" onClick={this.showBulkUpload.bind(this)}>Bulk Exports</button> */}
            </div>
            <div className="card-body col-xl-12 col-lg-12">
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.showGridData}>    
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    //onCellClicked={this.onCellClicked}
                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                    />
            </div>
            </div>
        </div>

        
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">Bulk Exports</h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>
                    <div className="form-group mt-20p">
                        <label>Select Plant: </label>
                        <Select 
                        placeholder={"All"} 
                        isMulti={true} 
                        id="plantname"
                        // value={this.state.location} 
                        className="border-radius-0" 
                        onChange={this.onSelectBulkPlants.bind(this)} style={{borderRadius:"0px"}} 
                        options={this.locationoptions()} required />
                    </div>
                    <div className="form-group mt-20p">
                        <label className="">Upload File</label> 
                        <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control" required  />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-success">Submit</button>
                        <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                    </div>
                    </form>
                    <div className="form-group">
                        <p style={{color:"#ff0000"}}>*Note: All fields related to Manual exports are mandatory and Should not be empty.</p>
                        <p><b>Bulk Exports rules:</b></p>

                        <p>All field headers must be same as the Headers given in template.</p>
                        <p ><b style={{color:"#ff0000"}}>Holiday Date Format :</b> DD/MM/YYYY</p>
                    

                    </div>
                    <div className="form-group">
                        <a className="btn btn-primary" href={require('../../assets/json/sample_holidays.csv')} target="_blank">Sample Template</a>
                    </div>
                    <div className="form-group">
                        {/* <a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a> */}
                    
                    </div>		
                </div>
            </div>
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const ExportsIcdToMsilAction = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onShowConsignmentExportsEventsClick(props);
    };
    
   // if(props.data.msil_transit_delay == 1)
    //{
   //     var colr = 'redfont'
   // }
   // else{
   // console.log("props.data = ", props.data);
        if(props.data.status == 1)
       {
            var colr = 'yellowfont'
       }
       else{
            if(props.data.status > 1)
            {
                var colr = 'greenfont'
            }
            else{
                var colr = 'grayfont'
            }
            
       }
    //}
    return (
        <div>
            <button 
                onClick={handleEventClick} 
                className="custom-btn f22 label label-success" 
                title="ICD to MSIL" >
                    <i className={"icofont icofont-truck-alt "+(colr)}></i> &nbsp;
            </button>
        </div>
    );
};

export default ExportsIcdToMsilAction;

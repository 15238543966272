import React, { Component } from "react";
import CountUp from "react-countup";
import axios from "axios";
var redirectURL = require("../redirectURL");

export default class UniqueScreenlogsCounters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      records: [],
      allRowDatacount: 0,
      totalLoads: 0,
      retrivalyettostart: 0,
      retrivalinprogress: 0,
      retrivalcomplete: 0,
      gatein: 0,
      invoiced: 0,

      uniqueScreen:0,
      accessedScreen:0,
      notAccessedScreen:0,
    };
  }

  componentWillReceiveProps(newprops) {
    console.log("Newporps ", newprops);
    this.setState({
      uniqueScreen: newprops.uniqueScreen,
      accessedScreen: newprops.accessedScreen,
      notAccessedScreen: newprops.notAccessedScreen,
      // logTNPfailureCount: newprops.logTNPfailureCount,
      // logPRTsucessCount: newprops.logPRTsucessCount,
      // logPRTfailureCount: newprops.logPRTfailureCount,
    });
  }
  onClickCounter(status) {
    console.log(status,'status--')
    this.props.context.onClickCounterShowData(status);
  }
  render() {
    var hideRetrievals = 0;
    var cardCol = "col-xl-2";
    if (this.state.screen == "exitloads") {
      var gtcol = "col-xl-6";
      var clcol = "col-xl-4";
      cardCol = "col-xl-4";
      hideRetrievals = 1;
    } else {
      var gtcol = "col-xl-12";
      var clcol = "col-xl-2";
    }
    return (
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
          <div className="crm-numbers pb-0">
            <div
              style={{ justifyContent: "space-around", marginRight: "50px" }}
              className="row"
            >
              <div className={"col cpointer p-5px " + clcol}>
                <div
                  style={{ width: "300px", height: "150px" }}
                  className="card "
                >
                  <div className="card-header">
                    <span className="f16">UNIQUE</span>
                  </div>
                  <div className="row card-body p-10px">
                    <div
                      className="col cursorPointer"
                      onClick={this.onClickCounter.bind(this, "unique")}
                    >
                      {/* <span className="f12">Sucess </span> */}
                      <h4 className="txt-info f40">
                        <span className="counter">
                          <CountUp
                            end={
                              this.state.uniqueScreen
                                ? this.state.uniqueScreen
                                : 0
                            }
                          />
                        </span>
                      </h4>
                    </div>
                    {/* <div
                      className="col col-xl-6 col-lg-6"
                      onClick={this.onClickCounter.bind(this, "SNDG","Failure")}
                    >
                      <span className="f12">Failure </span>
                      <h4 className="txt-primary f26">
                        <span className="counter">
                          <CountUp
                            end={
                              this.state.sndgfailureCount
                                ? this.state.sndgfailureCount
                                : 0
                            }
                          />
                        </span>
                      </h4>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className={"col cpointer p-5px " + clcol}>
                <div
                  style={{ width: "300px", height: "150px" }}
                  className="card n-p-0"
                >
                  <div className="card-header">
                    <span className="f16">Accessed</span>
                  </div>
                  <div className="row card-body p-10px">
                    <div
                      className="col cursorPointer"
                      onClick={this.onClickCounter.bind(this,'accessed')}
                    >
                      {/* <span className="f12">Sucess </span> */}
                      <h4 className="txt-info f40">
                        <span className="counter">
                          <CountUp
                            end={
                              this.state.accessedScreen
                                ? this.state.accessedScreen
                                : 0
                            }
                          />
                        </span>
                      </h4>
                    </div>
                    {/* <div
                      className="col col-xl-6 col-lg-6"
                      onClick={this.onClickCounter.bind(this, "LOG-TNP","Failure")}
                    >
                      <span className="f12">Failure </span>
                      <h4 className="txt-primary f26">
                        <span className="counter">
                          <CountUp
                            end={
                              this.state.logTNPfailureCount
                                ? this.state.logTNPfailureCount
                                : 0
                            }
                          />
                        </span>
                      </h4>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className={"col cpointer p-5px " + clcol}>
                <div
                  style={{ width: "300px", height: "150px" }}
                  className="card n-p-0"
                >
                  <div className="card-header">
                    <span className="f16">Not Accessed</span>
                  </div>
                  <div className="row card-body p-10px">
                    <div
                      className="col cursorPointer"
                      onClick={this.onClickCounter.bind(this, 'not_accessed')}
                    >
                      {/* <span className="f12">Sucess </span> */}
                      <h4 className="txt-info f40">
                        <span className="counter">
                          <CountUp
                            end={
                              this.state.notAccessedScreen
                                ? this.state.notAccessedScreen
                                : 0
                            }
                          />
                        </span>
                      </h4>
                    </div>
                    {/* <div
                      className="col col-xl-6 col-lg-6"
                      onClick={this.onClickCounter.bind(this, "LOG-PRT","Failure")}
                    >
                      <span className="f12">Failure </span>
                      <h4 className="txt-primary f26">
                        <span className="counter">
                          <CountUp
                            end={
                              this.state.logPRTfailureCount
                                ? this.state.logPRTfailureCount
                                : 0
                            }
                          />
                        </span>
                      </h4>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const ArrivalMap = (props) => {
    
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowRouteDiv(props.data);
    };
    return (
        <div className="map-icon-component">
			<button onClick={handleRouteClick} className="custom-btn label label-success" title="View Route"><i className="icofont icofont-map-pins f25"></i> </button>
        </div>
    );
};

export default ArrivalMap;

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import SobGrid from '../layouts/sobgrid';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "./updatelmstates";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CellGraphComponent from './cellGraphComponent';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { HardwareDesktopWindows } from 'material-ui/svg-icons';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from "@fullcalendar/timegrid";    
import {verifyext, verifycsvdata} from "../common/verifyext";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var CELL_DIMENSION_SIZE = 90;

var moment = require('moment');

export default class PRTSobGrid extends Component{

	constructor(props)
	{
		super(props);
		this.state={
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            frameworkComponents : {
            consignmentforceclose:Consignmentforceclose,
            consignmentActions:ConsignmentActions,
            UpdateButton:UpdateButton,
            MarkDeliver : MarkDeliver,
            commentActions : CommentActions
            },
            modified_by:"",
            basicTitle:'',
            reasonforceclose:"",
            bulkslide:"",
            file:"",
            uploadFile:"",
            basicType:"default",
            loadshow:'show-n',
            forceCloseRowNode : "",
            forceclosedata:"",
            csvcontent:"",
            sliderForceCloseTranslate:"",
            overly:'',
            rowData:[],
            bulkslide:"",
            showDiv:"",
            uploadDivWidth:"",
            mapinfo:"",
            dealer:"",
            sliderCommentTranslate:"",
            commentsRowData:"",
            consignment_code:"",
            sliderRouteTranslate:"",
            maptruckno:"",
            routeTruck:	"",
            rownode:"",
            googelRoutes:"",
            file:"",
            rowSelection:'multiple',
            sliderForceCloseTranslate:"",
            maptruckno:"",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
                statusBar: {
            statusPanels: [
            {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
            },
            {
            statusPanel: "agTotalRowCountComponent",
            align: "center"
            },
            { statusPanel: "agFilteredRowCountComponent" },
            { statusPanel: "agSelectedRowCountComponent" },
            { statusPanel: "agAggregationComponent" }
            ]
                },
            paginationPageSize:200,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
            toolPanels: [
                {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel"
                },
                {
                id: "filters",
                labelDefault: "Filters",
                labelKey: "filters",
                iconKey: "filter",
                toolPanel: "agFiltersToolPanel"
                }
            ]
            },
            animateRows: true,
            legs:'',
            userid: localStorage.getItem('userid'),
            pageTitle : "",
            dept_code :"",
            basicTitle :"",
            basicType : "",
            show : false,
            loadshow : "show-n",
            overly : "show-n",
            usergridstate:[],
            screenurl:"",
            screentitle:"",
            eventGridAction: "gridAction",
            selected_year_month: "",
            uploaded_file: "",
            email_ids: "",
            pageType : 1,
            dataupload:"activet", 
            roadTab:"",
            courierTab:"",      
            annexure2a_road:"",
            annexure2b_road:"",      
            annexure2a_courier:"",
            annexure2b_courier:"",
            datauploaddiv:"show-m",
            roaddiv:"show-n",
            courierdiv:"show-n",
            annexure2aData:[],
            annexure2bData:[],
            columnwithDefs:[],
            columnwithDefsFor2b:[],
            annexure2a_road_grid:"",
            annexure2b_road_grid:"",
            annexure2a_courier_grid:"",
            annexure2b_courier_grid:"",
            from_date:moment.parseZone().format("YYYY-MM-DD"),
            bulkslide:"",
            approvedFile:"",
            approvedType:"",
		};
		
        this.changeFileHandler = this.changeFileHandler.bind(this);
        this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
    }
    componentDidMount(){
        loadDateTimeScript()
    }
    

    closeAlert = () => {
        this.setState({
            show: false
        });        
        window.location.reload();
    }
	formHandler = (event) => {
        event.preventDefault();
        // this.setState({
        //     overly : "show-m",
        //     loadshow : "show-m",
        //     showbtn:"show-n"
        // })
        var selected_year_month = $("#year_month_id").val();
        // var email_ids = [];
        // this.state.email_ids.map((e)=>{email_ids.push(e.value)})

        var uploaded_file = this.state.uploaded_file;
        var flag = true;
        if(selected_year_month == "" || uploaded_file == "")
        {
            flag = false
        }
        if(flag)
        {
            var splittedName = uploaded_file.name.split(".");
            var extension = splittedName[splittedName.length - 1];
            if(['xlsx', "xls", "XLSX", "XLS"].indexOf(extension) >= 0)
            {
                this.setState({
                    loadshow : "show-m",
                    overly : "show-m",
                });
                let formdata = new FormData();
                formdata.append("selected_year_month", selected_year_month);
                //formdata.append("email_ids", email_ids);
                formdata.append("uploaded_file", uploaded_file);
                formdata.append("pageType", "weekly");
                redirectURL.post("/dashboard/saveAchievementUploadedFile",formdata,{
                    headers:{
                        'content-type': 'multipart/form-data'
                    }
                }).then((response)=>{
                    if(response.data.status == "Success")
                    {
                        this.setState({
                            basicTitle:"Data is processing, Please check after 5 minutes",
                            basicType : "success",
                            show : true,
                            loadshow : "show-n",
                            overly : "show-n",
                            from_date : selected_year_month
                        });
                    }
                    else
                    {
                        this.setState({
                            basicTitle:"Unable to store data.",
                            basicType : "warning",
                            show : true,
                            loadshow : "show-n",
                            overly : "show-n",
                        });
                    }
                    
                }).catch((e)=>{
                    console.log(e);
                    this.setState({
                        loadshow : "show-n",
                        overly : "show-n",
                    });
                })
            }
            else
            {
                this.setState({
                    basicTitle: "Invalid file uploaded. Please uploade Excel file",
                    basicType: "warning",
                    show: true
                })
            }
        }
        else
        {
            this.setState({
                basicTitle: "Fields should not be empty.",
                basicType: "warning",
                show: true
            })
        }
       
    }
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
            overly:'show-n',
            bulkslide:"",
        });  
    }

    getSOBAcheivementData = async () =>{
        this.setState({
            loadshow : "show-m",
            overly : "show-m",
        });
        var query = {
            from_date : this.state.from_date+" 00:00:00",
            pageType : "weekly"
        }
        redirectURL.post("/dashboard/getSOBAcheivementFormattedData",query).then(async (response)=>{
            var roadAnnexure2a = response.data.annexure_2a_road;
            roadAnnexure2a.map(function(r){
                delete r["BD"];
                delete r["DELY"];
                delete r["GATI"];
                delete r["SAFEX"];
                delete r["TCI"];
            });
            var courierAnnexure2a = response.data.annexure_2a_courier;
            courierAnnexure2a.map(function(r){
                delete r["AUTO"];
                delete r["BFC"];
                delete r["ESL"];
                delete r["NSL"];
                delete r["ARL"];
                delete r["CHM"];
                delete r["KPL"];
                delete r["KTL"];
                delete r["UR"];
                delete r["SAL"];
                delete r["IRC"];
            });
            var roadAnnexure2b = response.data.annexure_2b_road;
            roadAnnexure2b.map(function(r){
                delete r["BD_actual"];
                delete r["BD_approve"];
                delete r["DELY_actual"];
                delete r["DELY_approve"];
                delete r["GATI_actual"];
                delete r["GATI_approve"];
                delete r["SAFEX_actual"];
                delete r["SAFEX_approve"];
                delete r["TCI_actual"];
                delete r["TCI_approve"];
            });
            var courierAnnexure2b = response.data.annexure_2b_courier;
            courierAnnexure2b.map(function(r){
                delete r["AUTO_actual"];
                delete r["AUTO_approve"];
                delete r["BFC_actual"];
                delete r["BFC_approve"];
                delete r["ESL_actual"];
                delete r["ESL_approve"];
                delete r["NSL_actual"];
                delete r["NSL_approve"];
                delete r["ARL_actual"];
                delete r["ARL_approve"];
                delete r["CHM_actual"];
                delete r["CHM_approve"];
                delete r["KPL_actual"];
                delete r["KPL_approve"];
                delete r["KTL_actual"];
                delete r["KTL_approve"];
                delete r["UR_actual"];
                delete r["UR_approve"];
                delete r["SAL_actual"];
                delete r["SAL_approve"];
                delete r["IRC_actual"];
                delete r["IRC_approve"];
            });
            this.setState({
                roadAnnexure2a : roadAnnexure2a,
                courierAnnexure2a : courierAnnexure2a,
                roadAnnexure2b : roadAnnexure2b,
                courierAnnexure2b : courierAnnexure2b,
                loadshow : "show-n",
                overly : "show-n",
            });
            if(this.state.pageType == 2 || this.state.pageType == 4)
            {
                await this.createAnnexure2aGrid(roadAnnexure2a,"road");
            }
            if(this.state.pageType == 3 || this.state.pageType == 6)
            {
                await this.createAnnexure2aGrid(courierAnnexure2a,"courier");
            }
            if(this.state.pageType == 5)
            {
                await this.createAnnexure2bGrid(roadAnnexure2b,"road");
            }
            if(this.state.pageType == 7)
            {
                await this.createAnnexure2bGrid(courierAnnexure2b,"courier");
            }
        }).catch((e)=>{
            console.log(e);
            this.setState({
                loadshow : "show-n",
                overly : "show-n",
            });
        })
    }

    onGridReady = params => {
        this.gridApi1 = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };

    onGridState = () => {
        //console.log(this.gridApi1);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi1.getFilterModel();
        this.gridApi1.setFilterModel(window.filterState);
    }
    async onClickDataExport(){
        console.log(this.gridApi1)
        let expKeys = [];
        var allrows = []
        this.gridApi1.forEachNode((node, index) => {
            //console.log("node ",node)
            allrows.push(node.data)
        })
        if(allrows.length > 0)
        {
            var keys = Object.keys(allrows[0]);
            await keys.forEach(async (z, k) => {
                if(z !="data_type")
                {
                    expKeys.push(z);
                }
            })
        }
        console.log(expKeys)
        if(expKeys.includes("area"))
        var params ={
            columnKeys: expKeys
        }
        this.gridApi1.exportDataAsExcel(params);   
    }    

    resetUpload = () => {
        this.setState({
            bulkslide:'',
            overly:'show-n',
            loadFile:''
        });
    }
    changeFileHandler = async (e) => {
        console.log(e);
        this.setState({
            approvedFile : e.target.files[0]
        });    
    }
    changeFileHandler = async (e) => {
        var dt = '';
        const config = {
            headers: [
                // { 
                //     name: 'id',
                //     inputName: 'id',
                //     required: false,
                // },
                { 
                    name: 'city',
                    inputName: 'city',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'state',
                    inputName: 'state',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'rate_cmt',
                    inputName: 'rate_cmt',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'vehicle_capacity',
                    inputName: 'vehicle_capacity',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
            ]
        }
        var data = CSVFileValidator(e.target.files[0], config)
        .then((csvData) => {
            console.log(csvData)
        })
        .catch(err => {})
        // console.log("e.target.files[0]",e.target.result);
        
        var out = new Promise(function(reject, resolve){
            var reader =  new FileReader();
            reader.onload = async function(e) {
                var contents = await e.target.result;
                resolve(contents);
            };
            var tt =  reader.readAsText(e.target.files[0]);
        });
        this.setState({
            approvedFile : e.target.files[0]
        });
        var check = verifyext(e.target.files[0].name);
        if(check == true)
        {
            if(e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
            {
                // console.log(fileData);
                // console.log(typeof(fileData))
                
            }
            else{
                e.target.value = null;
                this.setState({
                    approvedFile:'',
                    show: true, 
                    basicType:'danger', 
                    basicTitle:'Please upload file having extensions .csv only.',
                });
            }
        }
        else{
            e.target.value = null;
            this.setState({
                approvedFile:'',
                show: true, 
                basicType:'danger', 
                basicTitle:'Invalid file, Please follow the template.',
            });
        }
    }
    uploadBulkFormHandler(event){
        event.preventDefault();
        var flag = 0;
        let saveData = new FormData();
        if(flag == 0)
        {      
            saveData.append("approvedFile", this.state.approvedFile);
            var approved_date = $("#approved_date").val();
            saveData.append("approved_date", approved_date);
            saveData.append("approvedType", this.state.approvedType)
            saveData.append("pageType", "weekly")
            redirectURL.post("/dashboard/saveApprovedUploadedFile",saveData,{
                headers:{
                    'content-type': 'multipart/form-data'
                }
            }).then((response)=>{
                if(response.data.status == "Success")
                {
                    this.setState({
                        basicTitle:"Success",
                        basicType : "success",
                        show : true,
                        loadshow : "show-n",
                        overly : "show-n",
                        from_date : approved_date
                    });
                }
                else
                {
                    this.setState({
                        basicTitle:"Unable to store data.",
                        basicType : "warning",
                        show : true,
                        loadshow : "show-n",
                        overly : "show-n",
                    });
                }
                
            }).catch((e)=>{
                console.log(e);
                this.setState({
                    loadshow : "show-n",
                    overly : "show-n",
                });
            })
        }
        else
        {
            this.setState({
                basicTitle : "Please upload the file",
                basicType : "warning",
                show : true,
            })
        }
    };
	 render() {
        var gridOptions = {
            defaultColDef: {
              sortable: true,
              resizable: true,
              filer: true,
            },
            //groupHeaderHeight: 75,
            //headerHeight: 150,
            //floatingFiltersHeight: 50,
            //pivotGroupHeaderHeight: 50,
            //pivotHeaderHeight: 100,
            suppressRowTransform: true
          };
        return (
            <div className="row col-xl-12 col-lg-12">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                    <div className="col-sm-6">
                        {(this.props.pageType == 5 || this.props.pageType == 7) ?
                        <p style={{color:"red",fontWeight:"700",margin:"1% 0",fontSize:"15px"}}>Note : All values are in %</p>
                        :""}
                    </div>
                    <div className="col-sm-6">
                        <h5>
                            <button className="btn btn-danger" style={{float:"right",marginRight:"10px"}} onClick={this.onClickDataExport.bind(this)}>Export Data</button>  
                        </h5>
                    </div>
                    <div id="annexure2a_road_grid" style={{ width: "100%", height: this.props.gridHeight}} className={"ag-theme-balham"}>
                        <AgGridReact
                            modules={this.state.modules}
                            columnDefs={this.props.columnwithDefsFor2b}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.props.roadAnnexure2b}
                            enableCharts={false}
                            // autoGroupColumnDef={this.state.autoGroupColumnDef}
                            onGridReady={this.onGridReady}
                            onGridState={this.onGridState}
                            frameworkComponents={this.state.frameworkComponents}
                            detailCellRendererParams={this.state.detailCellRendererParams}
                            stopEditingWhenGridLosesFocus= {true}
                            paginationPageSize={this.state.paginationPageSize}
                        
                            pagination={false}
                            rowClassRules={this.state.rowClassRules}
                            gridOptions={gridOptions}
                            components={this.state.components}
                            enableRangeSelection= {true}
                            onCellEditingStopped={this.onCellClicked}
                            // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                        />
                       
                    </div>
                    
                </div>

                
        );
    }
}
function loadDateTimeScript(){
	$("#year_month_id").datepicker( {
        changeMonth: true,
        changeYear: true,
        showButtonPanel: false,
        dateFormat: "yy-mm-dd",
        beforeShow: function(input, inst) {
            $('#ui-datepicker-div').addClass("year_month_id");
        }
    }); 
}

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
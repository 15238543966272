import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../node_modules/react-resizable/css/styles.css';
import { WidthProvider, Responsive } from "react-grid-layout";
import axios from 'axios';
import _ from "lodash";
var redirectURL = require('../../redirectURL');

export default class UnusualEnroute extends Component{

	constructor(props)
	{
		super(props);
		this.state={
            time:'',
            distance:'',
          	userid:localStorage.getItem('userid'),
		};
		
    }
    componentDidMount(){
        var formdata = {
            userid:this.state.userid,
            kpitype:'unusualenroute'
        };
        redirectURL.post('/manage/kpiConfigurations',formdata, {
            headers:{
                'Content-type':'application/json'
            }
        })
        .then((response) => {
            console.log(response.data);
            if(response.data.length > 0)
            {
                this.setState({
                    time:(response.data[0].threshold_time/60),
                    distance:response.data[0].distance_travel
                });
            }
        })
    }
    changeInputHandler = (event) => {
        const re = /^[0-9\\:\b]+$/;
        if(re.test(event.target.value))
        {
            let name = event.target.name;
            let value = event.target.value;
            this.setState({[name]:value});
        }
    }
	formHandler = (event) => {
        event.preventDefault();
        var formdata = {
            userid:this.state.userid,
            dept_code:"LOG-PRT",
            kpitype:"unusualenroute",
            distance_travel:this.state.distance,
            threshold_time:(this.state.time*60)
        }
        console.log(formdata)
        redirectURL.post('/manage/saveKPIConfigurations',formdata, {
            headers:{
                'Content-type':'application/json'
            }
        })
        .then((response) => {
            var res = response.data;
            if(res.result.result.ok == 1)
            {
                this.props.context.showAlert();
            }
            else{
                this.props.context.showErrorAlert();
            }
        })
    }
	 render() {
        return (
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <h5>Unusual Enroute Stoppages</h5>
                </div>
                <form className=" theme-form col-xl-6 col-lg-6" onSubmit={this.formHandler}>
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            <label className="c-lbl">Minimum Travel Distance for Ticket Closure*:</label>
                            <input type="text" name="distance" value={this.state.distance} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Mininum Travel Distance" />
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            <label className="c-lbl">Time (Hrs)*:</label>
                            <input type="text" name="time" value={this.state.time} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Time" />
                        </div>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-success cs-btn" id="save">Save</button>
                    </div>
                </form>
            </div>
        );
    }
}


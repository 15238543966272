import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { WidthProvider, Responsive } from "react-grid-layout";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import redirectURL from '../redirectURL';
import $ from "jquery";
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import {verifyext, verifycsvdata} from "../common/verifyext";

var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");

export default class SobAchievement extends Component{

	constructor(props)
	{
		super(props);
		this.state={
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize: 50,
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            legs:'',
            userid: localStorage.getItem('userid'),
            pageTitle : "",
            dept_code :"",
            basicTitle :"",
            basicType : "",
            show : false,
            loadshow : "show-n",
            overly : "show-n",
            usergridstate:[],
            screenurl:"",
            screentitle:"",
            eventGridAction: "gridAction",
            selected_year_month: "",
            uploaded_file: "",
            email_ids: "",
            pageType : 1,
            dataupload:"activet", 
            roadTab:"",
            courierTab:"",      
            annexure2a_road:"",
            annexure2b_road:"",      
            annexure2a_courier:"",
            annexure2b_courier:"",
            datauploaddiv:"show-m",
            roaddiv:"show-n",
            courierdiv:"show-n",
            annexure2aData:[],
            annexure2bData:[],
            columnwithDefs:[],
            columnwithDefsFor2b:[],
            annexure2a_road_grid:"",
            annexure2b_road_grid:"",
            annexure2a_courier_grid:"",
            annexure2b_courier_grid:"",
            from_date:moment.parseZone().format("YYYY-MM-DD"),
            pageTitle:"Upload Adhoc SOB",
            bulkslide:"",
            approvedFile:"",
            approvedType:"",
		};
		
        this.changeFileHandler = this.changeFileHandler.bind(this);
        this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }  
            
    componentDidMount(){
        this.logPageView();
        loadDateTimeScript()
        this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});
        this.getSOBAcheivementData();
    }
    logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
        window.location.reload();
    }
	formHandler = (event) => {
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
                let pageTitle = this.state.pageTitle;
                let eventOptions = {
                    "category": pageTitle,
                    "action": this.state.eventGridAction,
                    "label": googleAnalytics.page.action.viewRoute,
                }
                googleAnalytics.logEvent(eventOptions);
            }
        //var selected_year_month = $("#year_month_id").val();

        var uploaded_file = this.state.uploaded_file;
        var flag = true;
        // if(selected_year_month == "" || uploaded_file == "")
        // {
        //     flag = false
        // }
        if(flag)
        {
            var splittedName = uploaded_file.name.split(".");
            var extension = splittedName[splittedName.length - 1];
            if(['xlsx', "xls", "XLSX", "XLS"].indexOf(extension) >= 0)
            {
                this.setState({
                    loadshow : "show-m",
                    overly : "show-m",
                });
                let formdata = new FormData();
                //formdata.append("selected_year_month", selected_year_month);
                //formdata.append("email_ids", email_ids);
                formdata.append("uploaded_file", uploaded_file);
                formdata.append("pageType", "adhoc");
                redirectURL.post("/dashboard/saveAchievementUploadedFile",formdata,{
                    headers:{
                        'content-type': 'multipart/form-data'
                    }
                }).then((response)=>{
                    if(response.data.status == "Success")
                    {
                        this.setState({
                            basicTitle:"Data is processing, Please check after 5 minutes",
                            basicType : "success",
                            show : true,
                            loadshow : "show-n",
                            overly : "show-n",
                            //from_date : selected_year_month
                        });
                    }
                    else
                    {
                        this.setState({
                            basicTitle:"Unable to store data.",
                            basicType : "warning",
                            show : true,
                            loadshow : "show-n",
                            overly : "show-n",
                        });
                    }
                    
                }).catch((e)=>{
                    console.log(e);
                    this.setState({
                        loadshow : "show-n",
                        overly : "show-n",
                    });
                })
            }
            else
            {
                this.setState({
                    basicTitle: "Invalid file uploaded. Please uploade Excel file",
                    basicType: "warning",
                    show: true
                })
            }
        }
        else
        {
            this.setState({
                basicTitle: "Fields should not be empty.",
                basicType: "warning",
                show: true
            })
        }
       
    }
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
            overly:'show-n',
            bulkslide:"",
        });  
    }

    async onClickTab(pageType)
    {
        if(pageType == "dataupload")
        {
            await this.setState({
                pageType : 1,
                dataupload:"activet",       
                roadTab:"",
                courierTab:"",
                datauploaddiv:"show-m",
                roaddiv:"show-n",
                courierdiv:"show-n",
                pageTitle:"Upload Adhoc SOB"
            });
        }
        else if(pageType == "road")
        {
            await this.setState({
                pageType : 2,
                dataupload:"",  
                roadTab:"activet",
                courierTab:"",      
                annexure2a_road:"activet",
                annexure2b_road:"",      
                annexure2a_courier:"",
                annexure2b_courier:"",
                datauploaddiv:"show-n",
                roaddiv:"show-m",
                courierdiv:"show-n",
                annexure2a_road_grid:"show-m",
                annexure2b_road_grid:"show-n",
                annexure2a_courier_grid:"show-n",
                annexure2b_courier_grid:"show-n",
                pageTitle:"Road - Volume"
            });
            this.createAnnexure2aGrid(this.state.roadAnnexure2a,"road");
        }
        else if(pageType == "courier")
        {
            await this.setState({
                pageType : 3,
                dataupload:"",   
                roadTab:"",
                courierTab:"activet",
                annexure2a_road:"",
                annexure2b_road:"",      
                annexure2a_courier:"activet",
                annexure2b_courier:"",
                datauploaddiv:"show-n",
                roaddiv:"show-n",
                courierdiv:"show-m",
                annexure2a_road_grid:"show-n",
                annexure2b_road_grid:"show-n",
                annexure2a_courier_grid:"show-m",
                annexure2b_courier_grid:"show-n",
                pageTitle:"Courier - Volume"
            });
            this.createAnnexure2aGrid(this.state.courierAnnexure2a,"courier");
        }
        else if(pageType == "annexure2a_road")
        {
            await this.setState({
                pageType : 4,
                dataupload:"",   
                roadTab:"activet",
                courierTab:"",      
                annexure2a_road:"activet",
                annexure2b_road:"",      
                annexure2a_courier:"",
                annexure2b_courier:"",
                datauploaddiv:"show-n",
                roaddiv:"show-m",
                courierdiv:"show-n",
                annexure2a_road_grid:"show-m",
                annexure2b_road_grid:"show-n",
                annexure2a_courier_grid:"show-n",
                annexure2b_courier_grid:"show-n",
                pageTitle:"Road - Volume"
            });
            this.createAnnexure2aGrid(this.state.roadAnnexure2a,"road");
        }
        else if(pageType == "annexure2b_road")
        {
            await this.setState({
                pageType : 5,
                dataupload:"",   
                roadTab:"activet",
                courierTab:"",      
                annexure2a_road:"",
                annexure2b_road:"activet",      
                annexure2a_courier:"",
                annexure2b_courier:"",
                datauploaddiv:"show-n",
                roaddiv:"show-m",
                courierdiv:"show-n",
                annexure2a_road_grid:"show-n",
                annexure2b_road_grid:"show-m",
                annexure2a_courier_grid:"show-n",
                annexure2b_courier_grid:"show-n",
                pageTitle:"Road - Summary"
            });
            this.createAnnexure2bGrid(this.state.roadAnnexure2b,"road");
        }
        else if(pageType == "annexure2a_courier")
        {
            await this.setState({
                pageType : 6,
                dataupload:"",   
                roadTab:"",
                courierTab:"activet",      
                annexure2a_road:"",
                annexure2b_road:"",      
                annexure2a_courier:"activet",
                annexure2b_courier:"",
                datauploaddiv:"show-n",
                roaddiv:"show-n",
                courierdiv:"show-m",
                annexure2a_road_grid:"show-n",
                annexure2b_road_grid:"show-n",
                annexure2a_courier_grid:"show-m",
                annexure2b_courier_grid:"show-n",
                pageTitle:"Courier - Volume"
            });
            this.createAnnexure2aGrid(this.state.courierAnnexure2a,"courier");
        }
        else if(pageType == "annexure2b_courier")
        {
            await this.setState({
                pageType : 7,
                dataupload:"",   
                roadTab:"",
                courierTab:"activet",      
                annexure2a_road:"",
                annexure2b_road:"",      
                annexure2a_courier:"",
                annexure2b_courier:"activet",
                datauploaddiv:"show-n",
                roaddiv:"show-n",
                courierdiv:"show-m",
                annexure2a_road_grid:"show-n",
                annexure2b_road_grid:"show-n",
                annexure2a_courier_grid:"show-n",
                annexure2b_courier_grid:"show-m",
                pageTitle:"Courier - Summary"
            });
            this.createAnnexure2bGrid(this.state.courierAnnexure2b,"courier");
        }
    }   

    getSOBAcheivementData = async () =>{
        this.setState({
            loadshow : "show-m",
            overly : "show-m",
        });
        var query = {
            //from_date : this.state.from_date+" 00:00:00",
            pageType : "adhoc"
        }
        redirectURL.post("/dashboard/getSOBAcheivementFormattedData",query).then(async (response)=>{
            var roadAnnexure2a = response.data.annexure_2a_road;
            roadAnnexure2a.map(function(r){
                delete r["BD"];
                delete r["DELY"];
                delete r["GATI"];
                delete r["SAFEX"];
                delete r["TCI"];
            });
            var courierAnnexure2a = response.data.annexure_2a_courier;
            courierAnnexure2a.map(function(r){
                delete r["AUTO"];
                delete r["BFC"];
                delete r["ESL"];
                delete r["NSL"];
                delete r["ARL"];
                delete r["CHM"];
                delete r["KPL"];
                delete r["KTL"];
                delete r["UR"];
                delete r["SAL"];
                delete r["IRC"];
            });
            var roadAnnexure2b = response.data.annexure_2b_road;
            roadAnnexure2b.map(function(r){
                delete r["BD_actual"];
                delete r["BD_approve"];
                delete r["DELY_actual"];
                delete r["DELY_approve"];
                delete r["GATI_actual"];
                delete r["GATI_approve"];
                delete r["SAFEX_actual"];
                delete r["SAFEX_approve"];
                delete r["TCI_actual"];
                delete r["TCI_approve"];
            });
            var courierAnnexure2b = response.data.annexure_2b_courier;
            courierAnnexure2b.map(function(r){
                delete r["AUTO_actual"];
                delete r["AUTO_approve"];
                delete r["BFC_actual"];
                delete r["BFC_approve"];
                delete r["ESL_actual"];
                delete r["ESL_approve"];
                delete r["NSL_actual"];
                delete r["NSL_approve"];
                delete r["ARL_actual"];
                delete r["ARL_approve"];
                delete r["CHM_actual"];
                delete r["CHM_approve"];
                delete r["KPL_actual"];
                delete r["KPL_approve"];
                delete r["KTL_actual"];
                delete r["KTL_approve"];
                delete r["UR_actual"];
                delete r["UR_approve"];
                delete r["SAL_actual"];
                delete r["SAL_approve"];
                delete r["IRC_actual"];
                delete r["IRC_approve"];
            });
            this.setState({
                roadAnnexure2a : roadAnnexure2a,
                courierAnnexure2a : courierAnnexure2a,
                roadAnnexure2b : roadAnnexure2b,
                courierAnnexure2b : courierAnnexure2b,
                loadshow : "show-n",
                overly : "show-n",
            });
            if(this.state.pageType == 2 || this.state.pageType == 4)
            {
                await this.createAnnexure2aGrid(roadAnnexure2a,"road");
            }
            if(this.state.pageType == 3 || this.state.pageType == 6)
            {
                await this.createAnnexure2aGrid(courierAnnexure2a,"courier");
            }
            if(this.state.pageType == 5)
            {
                await this.createAnnexure2bGrid(roadAnnexure2b,"road");
            }
            if(this.state.pageType == 7)
            {
                await this.createAnnexure2bGrid(courierAnnexure2b,"courier");
            }
        }).catch((e)=>{
            console.log(e);
            this.setState({
                loadshow : "show-n",
                overly : "show-n",
            });
        })
    }

    createAnnexure2aGrid = async(annexure2aData,gridType) => {
        if(annexure2aData.length > 0)
        {
            await annexure2aData.map(async function(g){
                var rowDataKeys = Object.keys(g);
                await rowDataKeys.map(async function(rdk){
                    if(rdk !="actual_total_volume" && rdk !="area_of_operation" && rdk !="destination" && rdk !="source")
                    {

                    }
                });
            })
            var keys = Object.keys(annexure2aData[0]);
            var wrapText = false;
            var autoHeight = false;
            var columns = [];
            if(annexure2aData.length > 0)
            {
                var operationGroup = groupBy(annexure2aData, rdata => rdata.area_of_operation);
                console.log(operationGroup,"operationGroup")
                var lgArray = [];
                await operationGroup.forEach((lg, lgkey) =>{
                    lgArray.push({
                        locKey : lgkey,
                        locLength : lg.length
                    });
                });
            }
            await keys.forEach(async (z, k) => {          
                var keyVal=z;
                var hwid = 70;
                var pinnedLeft = "";
                var hideField = false;
                var isEditable=true;
                if(z == "area_of_operation")
                {
                    keyVal = "Areas of Operation";
                    hwid = 250;
                    pinnedLeft = "left";
                    isEditable = false;
                    hideField = true;
                }
                if(z == "area_of_operation_copy")
                {
                    keyVal = "Areas of Operation";
                    hwid = 300;
                    pinnedLeft = "left";
                    isEditable = false;
                }
                if(z == "source")
                {
                    keyVal = "Source";
                    hwid = 130;
                    pinnedLeft = "left";
                    isEditable = false;
                }
                if(z == "destination")
                {
                    keyVal = "Destination";
                    hwid = 150;
                    pinnedLeft = "left";
                    isEditable = false;
                }
                if(z == "mode_type")
                {
                    keyVal = "Mode";
                    hwid = 100;
                    isEditable = false;
                }
                if(z == "actual_total_volume")
                {
                    if(this.state.pageType == 7)
                    {
                        keyVal = "Total Volume(KGs)";
                        hwid = 130;
                        pinnedLeft = "right";
                        isEditable = false;
                    }
                    else
                    {
                        keyVal = "Total Volume(CMT)";
                        hwid = 130;
                        pinnedLeft = "right";
                        isEditable = false;
                    }
                }
                if(z == "Total")
                {
                    if(this.state.pageType == 3 || this.state.pageType == 6)
                    {
                        keyVal = "Total Volume(KGs)";
                        hwid = 200;
                        pinnedLeft = "left";
                    }
                    else if(this.state.pageType == 7)
                    {
                        keyVal = "Total Volume(KGs)";
                        hwid = 130;
                        pinnedLeft = "right";
                    }
                    else
                    {
                        keyVal = "Total Volume(CMT)";
                        hwid = 130;
                        pinnedLeft = "right";
                    }
                    isEditable = false;
                }
                if(z == "data_type")
                {
                    hideField = true;
                }
                if(z == "row_span")
                {
                    hideField = true;
                }
                columns.push({
                    headerName:keyVal,
                    field:z,
                    width:hwid,
                    pinned:pinnedLeft,
                    editable: false,
                    resizable:true,
                    hide:hideField,
                    filter:false,
                    suppressMenu:true,
                    sortable:false,
                    cellClass: function(params){
                        if(z == "area_of_operation_copy")
                        {
                            if(params.data[z] != "")
                            {
                                return ["cellstylegridG","pstBg"]
                            }
                        }
                    },
                    cellStyle: function(params){
                        if(z == "area_of_operation_copy")
                        {
                            if(params.data[z] != "")
                            {
                                return {borderBottom: '1px solid #ccc'};
                            }
                        }
                        
                    },
                    rowSpan: (params)=>{
                        if(z == "area_of_operation_copy")
                        {    
                            if(params.data[z] != "")
                            {
                                if(gridType == "courier" && this.state.pageType == 3 || this.state.pageType == 6)
                                {
                                    return params.data.row_span;
                                }
                                else
                                { 
                                    if(lgArray.length > 0)
                                    {
                                        try{
                                        var filteredData = lgArray.filter(function(obj) {
                                            return (obj.locKey === params.data[z]);
                                        });
                                        console.log(filteredData,params.data[z],"filteredData")
                                        if(filteredData[0].locLength > 0)
                                        {
                                            return filteredData[0].locLength
                                        }
                                        }
                                        catch(e)
                                        {

                                        }
                                    }
                                }
                            }
                        }
                    },
                    cellEditor: NumericCellEditor,
                    wrapText: wrapText,
                    autoHeight: autoHeight,
                })
            })
            console.log(annexure2aData,"annexure2aData")
            if(gridType == "road")
            {
                this.setState({
                    roadAnnexure2a : annexure2aData
                });
            }
            else
            {
                this.setState({
                    courierAnnexure2a : annexure2aData
                });
            }
            this.setState({
                columnwithDefs:columns,
                loadshow : "show-n",
                overly : "show-n",
            });
        }
    }
    createAnnexure2bGrid = async(annexure2bData,gridType) => {
        if(annexure2bData.length > 0)
        {
            await annexure2bData.map(async function(g){
                var rowDataKeys = Object.keys(g);
                await rowDataKeys.map(async function(rdk){
                    if(rdk !="actual_total_volume" && rdk !="area_of_operation" && rdk !="destination" && rdk !="source")
                    {

                    }
                });
            })
            var keys = Object.keys(annexure2bData[0]);
            var wrapText = false;
            var autoHeight = false;
            var columns = [];
            await keys.forEach(async (z, k) => {          
                var keyVal=z;
                var hwid = 70;
                var pinnedLeft = "";
                var hideField = false;
                var isEditable=true;
                
                if(z == "mode_type")
                {
                    keyVal = "Mode";
                    hwid = 120;
                    isEditable = false;
                }
                else if(z == "area_of_operation")
                {
                    keyVal = "Areas of Operation";
                    hwid = 270;
                    pinnedLeft = "left";
                    isEditable = false;
                }
                else if(z == "actual_total_volume")
                {
                    if(this.state.pageType == 7)
                    {
                        keyVal = "Total Volume(KGs)";
                        hwid = 130;
                        pinnedLeft = "right";
                        isEditable = false;
                    }
                    else
                    {
                        keyVal = "Total Volume(CMT)";
                        hwid = 130;
                        pinnedLeft = "right";
                        isEditable = false;
                    }
                }
                else
                {
                    var hedName = (z.split("_")[1]).toUpperCase();
                    keyVal = z.split("_")[0]+" "+hedName;
                    hwid = 120;
                    isEditable = false;
                }
                columns.push({
                    headerName:keyVal,
                    field:z,
                    width:hwid,
                    pinned:pinnedLeft,
                    editable: false,
                    resizable:true,
                    hide:hideField,
                    filter:false,
                    suppressMenu:true,
                    sortable:false,
                    cellEditor: NumericCellEditor,
                    wrapText: wrapText,
                    autoHeight: autoHeight,
                })
            })
            console.log(annexure2bData,"annexure2bData")
            if(gridType == "road")
            {
                this.setState({
                    roadAnnexure2b : annexure2bData
                });
            }
            else
            {
                this.setState({
                    courierAnnexure2b : annexure2bData
                });
            }
            this.setState({
                columnwithDefsFor2b:columns,
                loadshow : "show-n",
                overly : "show-n",
            });
        }
    }
    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };

    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onGridReady1 = params => {
		this.gridApi1 = params.api;
		
	    console.log("Grid is: ", params);
	    this.gridColumnApi1 = params.columnApi;
	
		
	};
	

	onGridState1 = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState1 = this.gridColumnApi1.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState1 = this.gridColumnApi1.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns1 = this.gridColumnApi1.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState1 = this.gridApi1.getFilterModel();
		   this.gridApi1.setFilterModel(window.filterState1);
		   
		   
    }
    
    onGridReady2 = params => {
		this.gridApi1 = params.api;
		
	    console.log("Grid is: ", params);
	    this.gridColumnApi1 = params.columnApi;
	
		
	};
	

	onGridState2 = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState1 = this.gridColumnApi1.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState1 = this.gridColumnApi1.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns1 = this.gridColumnApi1.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState1 = this.gridApi1.getFilterModel();
		   this.gridApi1.setFilterModel(window.filterState1);
		   
		   
    }
    
    onGridReady3 = params => {
		this.gridApi1 = params.api;
		
	    console.log("Grid is: ", params);
	    this.gridColumnApi1 = params.columnApi;
	
		
	};
	

	onGridState3 = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState1 = this.gridColumnApi1.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState1 = this.gridColumnApi1.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns1 = this.gridColumnApi1.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState1 = this.gridApi1.getFilterModel();
		   this.gridApi1.setFilterModel(window.filterState1);
		   
		   
	}

    onClickDataExport(){
        console.log(this.gridApi,this.gridApi1)
        if(this.state.pageType == 3)
        {
            this.gridApi.exportDataAsCsv();
        }
        else
        {
            this.gridApi1.exportDataAsCsv();
        }
    }
    

    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

        this.setState({
            from_date: startdate
        });

    }
    openApprovedForm (pageType){
        console.log(pageType)
        this.setState({
            approvedType:pageType,
            overly :"show-m",
            bulkslide : "slider-translate"
        })
    }
    resetUpload = () => {
        this.setState({
            bulkslide:'',
            overly:'show-n',
            loadFile:''
        });
    }
    changeFileHandler = async (e) => {
        // if(e.target.files[0].type == 'xlsx' || e.target.files[0].type == 'application/vnd.ms-excel')
        // {
            console.log(e);
            this.setState({
                approvedFile : e.target.files[0]
            });
        // }
        // else{
        //     e.target.value = null;
        //     this.setState({
        //         approvedFile:'',
        //         show: true, 
        //         basicType:'danger', 
        //         basicTitle:'Please upload file having extensions .xlsx only.',
        //     });
        // }    
    }
    // changeFileHandler = async (e) => {
    //     var dt = '';
    //     const config = {
    //         headers: [
    //             // { 
    //             //     name: 'id',
    //             //     inputName: 'id',
    //             //     required: false,
    //             // },
    //             { 
    //                 name: 'Area of Operation',
    //                 inputName: 'Area of Operation',
    //                 required: true,
    //                 requiredError: function (headerName, rowNumber, columnNumber) {
    //                     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
    //                 }
    //             },
    //             { 
    //                 name: 'Transporter Code',
    //                 inputName: 'Transporter Code',
    //                 required: true,
    //                 requiredError: function (headerName, rowNumber, columnNumber) {
    //                     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
    //                 }
    //             },
    //             { 
    //                 name: 'Approved Volume',
    //                 inputName: 'Approved Volume',
    //                 required: true,
    //                 requiredError: function (headerName, rowNumber, columnNumber) {
    //                     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
    //                 }
    //             }
    //         ]
    //     }
    //     var data = CSVFileValidator(e.target.files[0], config)
    //     .then((csvData) => {
    //         this.setState({
    //             csvData:csvData.data
    //         });
    //         console.log(csvData.data);
    //     })
    //     .catch(err => {})
    //     // console.log("e.target.files[0]",e.target.result);
        
    //     var out = new Promise(function(reject, resolve){
    //         var reader =  new FileReader();
    //         reader.onload = async function(e) {
    //             var contents = await e.target.result;
    //             resolve(contents);
    //         };
    //         var tt =  reader.readAsText(e.target.files[0]);
    //     });
    //     this.setState({
    //         approvedFile : e.target.files[0]
    //     });
    //     var check = verifyext(e.target.files[0].name);
    //     if(check == true)
    //     {
    //         if(e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
    //         {
    //             // console.log(fileData);
    //             // console.log(typeof(fileData))
                
    //         }
    //         else{
    //             e.target.value = null;
    //             this.setState({
    //                 approvedFile:'',
    //                 show: true, 
    //                 basicType:'danger', 
    //                 basicTitle:'Please upload file having extensions .csv only.',
    //             });
    //         }
    //     }
    //     else{
    //         e.target.value = null;
    //         this.setState({
    //             approvedFile:'',
    //             show: true, 
    //             basicType:'danger', 
    //             basicTitle:'Invalid file, Please follow the template.',
    //         });
    //     }
    // }
    uploadBulkFormHandler(event){
        event.preventDefault();
        var flag = 0;
        let saveData = new FormData();
        if(flag == 0)
        {      
            saveData.append("approvedFile", this.state.approvedFile);
            //var approved_date = $("#approved_date").val();
            //saveData.append("approved_date", approved_date);
            saveData.append("approvedType", this.state.approvedType)
            saveData.append("pageType", "adhoc")
            //saveData.append("uploadedData", JSON.stringify(this.state.csvData));
            redirectURL.post("/dashboard/saveApprovedUploadedFile",saveData,{
                headers:{
                    'content-type': 'multipart/form-data'
                }
            }).then((response)=>{
                if(response.data.message == "success")
                {
                    this.setState({
                        basicTitle:"Success",
                        basicType : "success",
                        show : true,
                        loadshow : "show-n",
                        overly : "show-n",
                        //from_date : approved_date
                    });
                }
                else
                {
                    this.setState({
                        basicTitle:"Unable to store data.",
                        basicType : "warning",
                        show : true,
                        loadshow : "show-n",
                        overly : "show-n",
                    });
                }
                
            }).catch((e)=>{
                console.log(e);
                this.setState({
                    loadshow : "show-n",
                    overly : "show-n",
                });
            })
        }
        else
        {
            this.setState({
                basicTitle : "Please upload the file",
                basicType : "warning",
                show : true,
            })
        }
    };
	 render() {
        var gridOptions = {
            defaultColDef: {
              sortable: true,
              resizable: true,
            },
            //groupHeaderHeight: 75,
            //headerHeight: 150,
            //floatingFiltersHeight: 50,
            //pivotGroupHeaderHeight: 50,
            //pivotHeaderHeight: 100,
            suppressRowTransform: true
          };
        return (
            <div className="row col-xl-12 col-lg-12">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="f16" style={{marginBottom:"10px"}}>
                    <ul className="d-tabs">
                        <li onClick={this.onClickTab.bind(this,"dataupload")} className={"lttabs "+(this.state.dataupload)}>
                            <button type="button" className="btn">Adhoc SOB</button>
                        </li>

                        <li onClick={this.onClickTab.bind(this,"road")} className={"lttabs "+(this.state.roadTab)}>
                            <button type="button" className="btn">Road</button>
                        </li>

                        <li onClick={this.onClickTab.bind(this,"courier")} className={"lttabs "+(this.state.courierTab)}>
                            <button type="button" className="btn">Courier</button>
                        </li>
                    </ul>
                    <h5 style={{marginTop:"3%"}}>
                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pageTitle}</span>  
                        {/* <button className="btn btn-danger" style={{float:"right",marginRight:"10px"}} onClick={this.onClickDataExport.bind(this)}>Export Data</button>   */}
                    </h5>
                </div>
                {/* {(this.state.pageType !=1) ?
                <div className="row col-xl-12 col-lg-12">
                    <div className="form-group col-xl-2 col-lg-2">
                        <Datetime
                            value={this.state.from_date}
                            disableCloseOnClickOutside={false}
                            closeOnSelect={true}
                            inputProps={{ placeholder: 'From', name: 'from_date', autoComplete: 'off' }}
                            dateFormat="YYYY-MM-DD"
                            name="from_date"
                            onChange={this.handlerStartDateTime} />
                    </div>
                    <div className="form-group col-xl-2 col-lg-2">
                        <button type="button" style={{ margin: "0 auto" }} className="btn btn-success ml-3" onClick={this.getSOBAcheivementData}>Submit</button>
                    </div>
                </div>
                 : ""} */}
                <div className={"row col-xl-12 col-lg-12 card "+this.state.datauploaddiv}>
                    <form className=" theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler} style={{padding:"16px"}}>
                        {/* <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 form-group">
                            <label>Select Date</label>
                            <input type="text" className="selected_date form-control"
                            autoComplete="newPassword"
                             onChange={(e)=>{this.setState({selected_year_month: e.target.value})}}
                             id="year_month_id" required />
                        </div> */}

                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 form-group">
                            <label>File to upload</label>
                            <input type="file" className="form-control"
                             onChange={(e)=>{this.setState({"uploaded_file": e.target.files[0]})}}
                             required />
                        </div>
                        <div className="form-group">
                            <button type="submit" className={"btn btn-success cs-btn "+this.state.showbtn}   id="save" style={{marginLeft:"13em",marginTop:"15px",width: "182px"}}>Store Data</button>
                        </div>
                    </form>
                </div>
                
                <div className={"row col-xl-12 col-lg-12 card "+this.state.roaddiv} style={{paddingTop:"2%"}}>
                    <div className="row col-sm-12" style={{marginBottom:"10px"}}>
                        <div className="col-sm-9" style={{paddingLeft:"0"}}>
                            <ul className="d-tabs f16">

                                <li onClick={this.onClickTab.bind(this,"annexure2a_road")} className={"lttabs "+(this.state.annexure2a_road)}>
                                    <button type="button" className="btn">Volume</button>
                                </li>

                                <li onClick={this.onClickTab.bind(this,"annexure2b_road")} className={"lttabs "+(this.state.annexure2b_road)}>
                                    <button type="button" className="btn">Summary</button>
                                </li>
                            </ul>
                        </div>
                        {(this.state.pageType == 5) ?
                        <div className="col-sm-3">
                            <button type="button" style={{float:"right"}} className="btn btn-info" onClick={this.openApprovedForm.bind(this,"road")}>Upload Approved Data</button>
                        </div>
                        :""}
                    </div>
                    {(this.state.pageType == 5 || this.state.pageType == 7) ?
                    <div className="row col-sm-12" style={{marginBottom:"10px"}}>
                        <p style={{color:"red",fontWeight:"700",margin:"1% 0",fontSize:"15px"}}>Note : All values are in %</p>
                    </div>
                    :""}
                    <div id="myGrid" style={{ width: "100%", height: "2000px" }} className={"ag-theme-balham "+this.state.annexure2a_road_grid}>
                        <AgGridReact
                            modules={this.state.modules}
                            columnDefs={this.state.columnwithDefs}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.roadAnnexure2a}
                            enableCharts={false}
                            // autoGroupColumnDef={this.state.autoGroupColumnDef}
                            onGridReady={this.onGridReady}
                            onGridState={this.onGridState}
                            frameworkComponents={this.state.frameworkComponents}
                            detailCellRendererParams={this.state.detailCellRendererParams}
                            stopEditingWhenGridLosesFocus= {true}
                            paginationPageSize={this.state.paginationPageSize}
                        
                            pagination={false}
                            rowClassRules={this.state.rowClassRules}
                            gridOptions={gridOptions}
                            // components={this.state.components}
                            enableRangeSelection= {true}
                            onCellEditingStopped={this.onCellClicked}
                            // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                        />
                    </div>
                    <div id="myGrid1" style={{ width: "100%", height: "478px" }} className={"ag-theme-balham "+this.state.annexure2b_road_grid}>
                        <AgGridReact
                            modules={this.state.modules}
                            columnDefs={this.state.columnwithDefsFor2b}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.roadAnnexure2b}
                            enableCharts={false}
                            // autoGroupColumnDef={this.state.autoGroupColumnDef}
                            onGridReady={this.onGridReady1}
                            onGridState={this.onGridState1}
                            frameworkComponents={this.state.frameworkComponents}
                            detailCellRendererParams={this.state.detailCellRendererParams}
                            stopEditingWhenGridLosesFocus= {true}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={false}
                            rowClassRules={this.state.rowClassRules}
                            gridOptions={gridOptions}
                            // components={this.state.components}
                            enableRangeSelection= {true}
                            onCellEditingStopped={this.onCellClicked}
                        />
                    </div>
                </div>

                <div className={"row col-xl-12 col-lg-12 card "+this.state.courierdiv} style={{paddingTop:"2%"}}>
                    <div className="row col-sm-12" style={{marginBottom:"10px"}}>
                        <div className="col-sm-9" style={{paddingLeft:"0"}}>
                            <ul className="d-tabs f16">

                                <li onClick={this.onClickTab.bind(this,"annexure2a_courier")} className={"lttabs "+(this.state.annexure2a_courier)}>
                                    <button type="button" className="btn">Volume</button>
                                </li>

                                <li onClick={this.onClickTab.bind(this,"annexure2b_courier")} className={"lttabs "+(this.state.annexure2b_courier)}>
                                    <button type="button" className="btn">Summary</button>
                                </li>
                            </ul>
                        </div>
                        {(this.state.pageType == 7) ?
                        <div className="col-sm-3">
                            <button type="button" style={{float:"right"}} className="btn btn-info" onClick={this.openApprovedForm.bind(this,"courier")}>Upload Approved Data</button>
                        </div>
                        :""}
                    </div>
                    {(this.state.pageType == 5 || this.state.pageType == 7) ?
                    <div className="row col-sm-12" style={{marginBottom:"10px"}}>
                        <p style={{color:"red",fontWeight:"700",margin:"1% 0",fontSize:"15px"}}>Note : All values are in %</p>
                    </div>
                    :""}
                    <div id="myGrid2" style={{ width: "100%", height: "2000px" }} className={"ag-theme-balham "+this.state.annexure2a_courier_grid}>
                        <AgGridReact
                            modules={this.state.modules}
                            columnDefs={this.state.columnwithDefs}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.courierAnnexure2a}
                            enableCharts={false}
                            // autoGroupColumnDef={this.state.autoGroupColumnDef}
                            onGridReady={this.onGridReady2}
                            onGridState={this.onGridState2}
                            frameworkComponents={this.state.frameworkComponents}
                            detailCellRendererParams={this.state.detailCellRendererParams}
                            stopEditingWhenGridLosesFocus= {true}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={false}
                            rowClassRules={this.state.rowClassRules}
                            gridOptions={gridOptions}
                            // components={this.state.components}
                            enableRangeSelection= {true}
                            onCellEditingStopped={this.onCellClicked}
                            // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                        />
                    </div>
                    <div id="myGrid3" style={{ width: "100%", height: "478px" }} className={"ag-theme-balham "+this.state.annexure2b_courier_grid}>
                        <AgGridReact
                            modules={this.state.modules}
                            columnDefs={this.state.columnwithDefsFor2b}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.courierAnnexure2b}
                            enableCharts={false}
                            // autoGroupColumnDef={this.state.autoGroupColumnDef}
                            onGridReady={this.onGridReady3}
                            onGridState={this.onGridState3}
                            frameworkComponents={this.state.frameworkComponents}
                            detailCellRendererParams={this.state.detailCellRendererParams}
                            stopEditingWhenGridLosesFocus= {true}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={false}
                            rowClassRules={this.state.rowClassRules}
                            gridOptions={gridOptions}
                            // components={this.state.components}
                            enableRangeSelection= {true}
                            onCellEditingStopped={this.onCellClicked}
                        />
                    </div>
                </div>

                <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
                    <h3 className="subH">Upload Approved Data</h3>
                    <div className="slide-r-body" style={{position:"relative"}}>                
                        <div className="container-fluid">
                            <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>
                                
                                {/* <div className="form-group mt-20p">
                                    <label>Select Date</label>
                                    <input type="text" className="selected_date form-control"
                                    onChange={(e)=>{this.setState({approved_date: e.target.value})}}
                                    id="approved_date" required />
                                </div> */}
                                <div className="form-group mt-20p">
                                    <label className="">Upload File</label> 
                                    <input type="file" name="uploadFile" id="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
                                </div>
                                
                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                    <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                </div>
                            </form>
                            <div className="form-group">
                            <a className="btn btn-primary" href={require('../../assets/json/approved_sob_sample_upload.xlsx')} target="_blank">Sample Template</a>
                        </div>	
                        </div>
                    </div>
                </div>

                <div className={"dataLoadpage " +(this.state.loadshow)}>
                    </div>
                    <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                        <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                    </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
                
            </div>
        );
    }
}
function loadDateTimeScript(){
	$("#year_month_id").datepicker( {
        changeMonth: true,
        changeYear: true,
        showButtonPanel: false,
        dateFormat: "yy-mm-dd",
        beforeShow: function(input, inst) {
            $('#ui-datepicker-div').addClass("year_month_id");
        }
    });  
    $(".selected_date").datepicker( {
        changeMonth: true,
        changeYear: true,
        showButtonPanel: false,
        dateFormat: "yy-mm-dd",
        beforeShow: function(input, inst) {
            $('#ui-datepicker-div').addClass("year_month_id");
        }
    });
}

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "./updateManualExportsBtn";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CellGraphComponent from './cellGraphComponent';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { HardwareDesktopWindows } from 'material-ui/svg-icons';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;
export default class ManualExports extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          consignmentforceclose:Consignmentforceclose,
          consignmentActions:ConsignmentActions,
          UpdateButton:UpdateButton,
          MarkDeliver : MarkDeliver,
          commentActions : CommentActions
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:50,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n"
        
        //components : {datepicker:getDatePicker()}
    }
    this.changeFileHandler = this.changeFileHandler.bind(this);
    this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
  }

componentDidMount(){
    loadDateTimeScript();
    redirectURL.post("/exports/getManulExports").then((response)=>{
        console.log(response.data);
        this.setState({
            rowData : response.data
        })
    }).catch((e)=>{
        console.log(e);
    })
}
    
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    this.setState({
        show: false
    });
}


formAssignHandler(params){
    var allGridRows = []
    this.gridApi.forEachNode(function(rowNode, index) {
        allGridRows.push(rowNode.data);
    });
    
    var flag=0;
    allGridRows = allGridRows.filter(function(e){return e.inserted != 1});
    console.log(allGridRows);
    var reqparams = {
        rowData : allGridRows
    }
    // for(var i=0;i<allGridRows.length;i++)
    // {
        
    //     for (let key in allGridRows[i]) {
    //         if(allGridRows[i][key] == null || allGridRows[i][key] == "" || allGridRows[i][key] == undefined);
    //         {
    //             console.log(key,allGridRows[i][key]);
    //             flag = 1;
    //             break;
    //         }
    //     }
    //     if(flag == 1)
    //     {
    //         break;
    //     }
    // }
    // if(flag == 0)
    // {
        redirectURL.post("/exports/insertManualExports",reqparams).then((response)=>{
            if(response.data.message != "Failure")
            {
                this.setState({
                    basicTitle:"Inserted Successfully.",
                    basicType : "success",
                    show : true
                })
                window.location.reload();
            }
            else{
                this.setState({
                    basicTitle:"Fields Should not be Empty",
                    basicType : "warning",
                    show : true
                })
            }
            
        }).catch((e)=>{
            console.log(e);
        })
    // }
    // else{
    //     this.setState({
    //         basicTitle:"Fields Should not be Empty",
    //         basicType : "warning",
    //         show : true
    //     })
    // }
    
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    
}
updateManualExports(params)
{
    console.log(params);
    
    redirectURL.post("/exports/updateManualExports",{"rowData":params}).then((response)=>{
        console.log(response);
        if(response.data.message == "Success")
        {
            this.setState({
                basicTitle:"Updated Successfully.",
                basicType : "success",
                show : true
            })
            // window.location.reload();
        }
        else
        {
            this.setState({
                basicTitle:"Fields Should not be Empty.",
                basicType : "warning",
                show : true
            })
        }
        
    })
    
}
addNewRow()
{
    // console.log(this.gridApi);
    this.gridApi.updateRowData({add:[{container_no:"",vehicle_number:"",consigner_code:"",consignee_code:"",
                                    gate_in_time:"",
                                    gate_out_time:"",
                                }]});
}
showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate"
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}
changeFileHandler = async (e) => {
    var dt = '';
    const config = {
        headers: [
            { 
                name: 'container_no',
                inputName: 'container_no',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'vehicle_number',
                inputName: 'vehicle_number',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'consigner_code',
                inputName: 'consigner_code',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'consignee_code',
                inputName: 'consignee_code',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            // { 
            //     name: 'current_waypoint_code',
            //     inputName: 'current_waypoint_code',
            //     required: true,
            //     requiredError: function (headerName, rowNumber, columnNumber) {
            //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            //     }
            // },
            // { 
            //     name: 'next_way_point_code',
            //     inputName: 'next_way_point_code',
            //     required: true,
            //     requiredError: function (headerName, rowNumber, columnNumber) {
            //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            //     }
            // },
            // { 
            //     name: 'vehicle_mode',
            //     inputName: 'vehicle_mode',
            //     required: true,
            //     requiredError: function (headerName, rowNumber, columnNumber) {
            //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            //     }
            // },
            { 
                name: 'gate_in_time',
                inputName: 'gate_in_time',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'gate_out_time',
                inputName: 'gate_out_time',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            // { 
            //     name: 'status_to_display',
            //     inputName: 'status_to_display',
            //     required: true,
            //     requiredError: function (headerName, rowNumber, columnNumber) {
            //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            //     }
            // },
        ]
    }
    var data = CSVFileValidator(e.target.files[0], config)
    .then((csvData) => {
        this.setState({
            csvData:csvData.data
        });
        console.log(csvData.data);
    })
    .catch(err => {})
    // console.log("e.target.files[0]",e.target.result);
    
    var out = new Promise(function(reject, resolve){
        var reader =  new FileReader();
        reader.onload = async function(e) {
            var contents = await e.target.result;
            resolve(contents);
        };
        var tt =  reader.readAsText(e.target.files[0]);
    });
    this.setState({
        file:e.target.files[0]
    });
    // console.log("e.target.files[0].type ", e.target.files[0]);

    if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
    {
        // console.log(fileData);
        // console.log(typeof(fileData))
        
    }
    else{
        e.target.value = null;
        this.setState({
            uploadFile:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'Please upload file having extensions .csv only.',
        });
    }
    
}

uploadBulkFormHandler(event){
    event.preventDefault();
    var fileData = this.state.csvData;
    var flag=0;
    var gate_in_flag = 0;
    var gate_out_flag = 0;
    fileData.map(function(e,index){
        if(index != 0)
        {
            
            if(e.container_no == "" || 
            e.vehicle_number == "" || 
            e.consigner_code == "" || 
            e.consignee_code == "" || 
            e.gate_in_time == "" || e.gate_out_time == "")
            {
                flag = 1;
                console.log(e);
            }
            if(flag == 0)
            {
                var gateindate = e.gate_in_time.split(" ")[0];
                var gateintime = e.gate_in_time.split(" ")[1];
                var gateintime1 = gateintime
                if(gateindate.indexOf("/"))
                {
                    gateindate = gateindate.split("/");
                    if(gateindate[0].length !== 2 || gateindate[1].length !== 2 || gateindate[2].length !== 4)
                    {
                        gate_in_flag = 1;
                    }
                    if(gate_in_flag == 0)
                    {
                        gateintime = gateintime.split(":");
                        if(gateintime[0].length !== 2 || gateintime[1].length !== 2 || gateintime[2].length !== 2)
                        {
                            gate_in_flag = 1;
                        }
                    }
                    if(gate_in_flag == 0)
                    {
                        console.log(gateindate[2]+"-"+gateindate[1]+"-"+gateindate[0]+" "+gateintime1)
                        e.gate_in_time = moment.parseZone(new Date(gateindate[2]+"-"+gateindate[1]+"-"+gateindate[0]+" "+gateintime1)).utcOffset("+05:30")._d
                    }
                }
                else if(gateindate.indexOf("-"))
                {
                    gateindate = gateindate.split("-");
                    if(gateindate[0].length !== 2 || gateindate[1].length !== 2 || gateindate[2].length !== 4)
                    {
                        gate_in_flag = 1;
                    }
                    if(gate_in_flag == 0)
                    {
                        gateintime = gateintime.split(":");
                        if(gateintime[0].length !== 2 || gateintime[1].length !== 2 || gateintime[2].length !== 2)
                        {
                            gate_in_flag = 1;
                        }
                    }
                    if(gate_in_flag == 0)
                    {
                        e.gate_in_time = moment.parseZone(new Date(gateindate[2]+"-"+gateindate[1]+"-"+gateindate[0]+" "+gateintime1)).utcOffset("+05:30")._d
                    }
                }
                else{}

                var gateoutdate = e.gate_out_time.split(" ")[0];
                var gateouttime = e.gate_out_time.split(" ")[1];
                var gateouttime1 = gateouttime
                if(gateoutdate.indexOf("/"))
                {
                    gateoutdate = gateoutdate.split("/");
                    if(gateoutdate[0].length !== 2 || gateoutdate[1].length !== 2 || gateoutdate[2].length !== 4)
                    {
                        gate_out_flag = 1;
                    }
                    if(gate_out_flag == 0)
                    {
                        gateouttime = gateouttime.split(":");
                        if(gateouttime[0].length !== 2 || gateouttime[1].length !== 2 || gateouttime[2].length !== 2)
                        {
                            gate_out_flag = 1;
                        }
                    }
                    if(gate_out_flag == 0)
                    {
                        e.gate_out_time = moment.parseZone(new Date(gateoutdate[2]+"-"+gateoutdate[1]+"-"+gateoutdate[0]+" "+gateouttime1)).utcOffset("+05:30")._d
                    }
                }
                else if(gateoutdate.indexOf("-"))
                {
                    gateoutdate = gateoutdate.split("-");
                    if(gateoutdate[0].length !== 2 || gateoutdate[1].length !== 2 || gateoutdate[2].length !== 4)
                    {
                        gate_out_flag = 1;
                    }
                    if(gate_out_flag == 0)
                    {
                        gateouttime = gateouttime.split(":");
                        if(gateouttime[0].length !== 2 || gateouttime[1].length !== 2 || gateouttime[2].length !== 2)
                        {
                            gate_out_flag = 1;
                        }
                    }
                    if(gate_out_flag == 0)
                    {
                        e.gate_out_time = moment.parseZone(new Date(gateoutdate[2]+"-"+gateoutdate[1]+"-"+gateoutdate[0]+" "+gateouttime1)).utcOffset("+05:30")._d
                    }
                }
            }
            
            
        }
    });
    if(flag == 0 && gate_out_flag == 0 && gate_in_flag == 0)
    {
        var reqparams = {
            "fileData" : fileData
        }
        console.log(reqparams.fileData);
        redirectURL.post("/exports/updateBulkManualExports",reqparams).then((response)=>{
            this.setState({
                basicTitle : "Uploaded SuccessFully",
                basicType : "success",
                show : true,
            });
            window.location.reload();
        });
    }
    else
    {
        if(flag == 1)
        {
            this.setState({
                basicTitle : "Fields Should not be Empty",
                basicType : "warning",
                show : true,
            })
        }
        if(gate_out_flag == 1)
        {
            this.setState({
                basicTitle : "Incorrect Date Time Format in Gate Out Time",
                basicType : "warning",
                show : true,
            })
        }
        if(gate_in_flag == 1)
        {
            this.setState({
                basicTitle : "Incorrect Date Time Format in Gate In Time",
                basicType : "warning",
                show : true,
            })
        }
        
    }
};


 
  render() {
    
    var columnwithDefs = [       
        {
            headerName: "Container No",
            field: "container_no",
            width:130,
            filter: true,
            resizable: true,
            editable:true
        },
        {
            headerName: "Vehicle Number",
            field: "vehicle_number",
            width:130,
            filter: true,
            resizable: true,
            editable:true
        },
        {
            headerName: "Consigner Code",
            field: "consigner_code",
            width:130,
            filter: true,
            resizable: true,
            editable:true
        },
        {
            headerName: "Consignee Code",
            field: "consignee_code",
            width:130,
            filter: true,
            resizable: true,
            editable:true
        },
        // {
        //     headerName: "Current Way Point Code",
        //     field: "current_way_point_code",
        //     width:130,
        //     filter: true,
        //     resizable: true,
        //     editable:true
        // },
        // {
        //     headerName: "Next Way Point Code",
        //     field: "next_way_point_code",
        //     width:120,
        //     filter: true,
        //     resizable: true,
        //     editable:true
        // },
        // {
        //     headerName: "Vehicle Mode",
        //     field: "vehicle_mode",
        //     width:130,
        //     filter: true,
        //     resizable: true,
        //     editable:true
        // },
        {
            headerName: "Gate In Time",
            field: "gate_in_time",
            width:130,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: DateEditor,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.gate_in_time != null)
                {
                    if(moment(params.data.gate_in_time,'DD-MM-YYYY hh:mm',true).isValid() == false)
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time);
                    }
                    else{
                        return params.data.gate_in_time;
                    }
                    
                }
                else
                {
                    return ""
                }

            }
        },
        {
            headerName: "Gate Out Time",
            field: "gate_out_time",
            width:130,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: DateEditor,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.gate_out_time != null)
                {
                    if(moment(params.data.gate_out_time,'DD-MM-YYYY hh:mm',true).isValid() == false)
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                    }
                    else{
                        return params.data.gate_out_time;
                    }
                    
                }
                else
                {
                    return ""
                }

            }
        },
        // {
        //     headerName: "Status To be Displayed",
        //     field: "status_to_display",
        //     width:130,
        //     filter: true,
        //     resizable: true,
        //     editable:true,
            
        // },
        {
            headerName : "Update",
            field:"_id",
            width:130,
            resizable : true,
            editable : false,
            cellRendererSelector:function(params){	
                    // if(params.data.consignment_code != "" && params.data.consignment_code != null && params.data.consignment_code != undefined)
                // {
                    var rendComponent = {
                        component: 'UpdateButton'
                    };
                    return rendComponent;
                // }
                // else
                // {
                //     return "";
                // }		
                
            },
        }
    ]
    return (
      <div class="container-fluid">
          <h5 style={{width:"74.5%",float:"left"}}>
              <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Manual Exports </span>
          </h5>
          <button className="btn btn-success" onClick={this.addNewRow.bind(this)}>Add New Container</button>
          <button className="btn btn-info" onClick={this.showBulkUpload.bind(this)}>Bulk Exports</button>
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>
        
        <div id="myGrid" style={{width:"100%",height:"478px"}} className="ag-theme-balham">    
            <AgGridReact
                modules={this.state.modules}
                columnDefs={columnwithDefs}
                defaultColDef={this.state.defaultColDef}
                rowData={this.state.rowData}
                enableCharts={false}
                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                onGridReady={this.onGridReady}
                onGridState={this.onGridState}
                frameworkComponents={this.state.frameworkComponents}
                statusBar={this.state.statusBar}
                sideBar={this.state.sideBar}
                stopEditingWhenGridLosesFocus= {true}
                paginationPageSize={this.state.paginationPageSize}
                pagination={true}
                gridOptions={{
                    context: { componentParent: this }
                }}
                // components={this.state.components}
                enableRangeSelection= {true}
                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                />
        </div>
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">Bulk Exports</h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>

                    <div className="form-group mt-20p">
                        <label className="">Upload File</label> 
                        <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control" required  />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-success">Submit</button>
                        <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                    </div>
                    </form>
                    <div className="form-group">
                        <p style={{color:"#ff0000"}}>*Note: All fields related to Manual exports are mandatory and Should not be empty.</p>
                        <p><b>Bulk Exports rules:</b></p>

                        <p>All field headers must be same as the Headers given in template.</p>
                        <p ><b style={{color:"#ff0000"}}>Gate In Time & Gate Out Time Format :</b> DD-MM-YYYY HH:MM:SS</p>
                    

                    </div>
                    <div className="form-group">
                        <a className="btn btn-primary" href={require('../../assets/json/Bulk_Manual_Exports_Sample.csv')} target="_blank">Sample Template</a>
                    </div>
                    <div className="form-group">
                        {/* <a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a> */}
                    
                    </div>		
                </div>
            </div>
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}


function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
import React, { Component } from "react";
import CSVFileValidator from 'csv-file-validator';
import moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MenuModule } from "@ag-grid-enterprise/menu";
import Select from "react-select";
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import $ from "jquery";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { color } from "highcharts";
import { DateTime } from "@grapecity/wijmo";
import CreatableSelect from 'react-select/creatable';
// import { formatDate } from "@fullcalendar/react";
var redirectURL = require("../redirectURL");

export default class HubMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hubMaster: 'activet',
      transporterMaster: '',
      fleetMaster: '',
      tpt_options: [],
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        width: 160,
        resizable: true,
      },
      eventAction: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      // loadshow: "show-n",
      // overly: "show-n",
      rowdata: [],
      rowdataforexwork: [],
      additionalchargesdata: [],
      sliderbulkupload: "",
      rowModelhighchartoptionsType: "enterprise",
      rowGroupPanelShow: "always",
      hub_consigner_code:'',
      paginationPageSize: 50,

      animateRows: true,
      dept_code: "",
      pagetitle: "",
      csvData: 0,
      overly: "show-n",
      loadshow: "show-n",
      pagetitle: "",
      eventGridAction: "gridAction",
      csvcontent: [],
      file: "",
      batch_name: "",
      cluster_breaks: "2",
      cluster_radius: "0.5",
      notification_emails: "",
      bulkslide1: "",
      bulkslide2: "",
      rowId: "",
      show: false,
      basicTitle: "",
      basicType: "",
      bulkslide: "",
      hub_location: '',
      hub_code: '',
      hub_tpt_code: '',
      parent_hub_tpt_code: '',
      latitude: '',
      longitude: '',
      transporter_code: [],
      hub_serving_city: [],
      citiesList: [],
      hubServingCitiesList: [],
      transporterOptions: [],
      consignerCodeOptions:[]

    };
    this.editvalues = this.editvalues.bind(this);
  }

  async componentDidMount() {
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    })
    // document.body.addEventListener("click", this.handleClickOutside);
    await redirectURL
      .post("/consignments/getoptionsforhubtpt")
      .then((response) => {
        // console.log(response.data, "140----tpt options");
        var optionsCollection = []
        var tpt_options = response.data.map((e) => {
          optionsCollection.push({ value: e.hub_tpt_code, label: e.hub_tpt_code })
        })
        this.setState({
          tpt_options: optionsCollection
        })
      })
      .catch((e) => {
        console.log(e);
      });

    await redirectURL
      .post("/consignments/hubmaster")
      .then((response) => {
        // console.log(response.data, "140----", response.data.consignee_cities);

        this.setState({
          rowdata: response.data,
          // citiesList:citiesList
        })


      })
      .catch((e) => {
        console.log(e);
      });

      var consignerCodeOptions =[]
      await redirectURL
      .post("/consignments/getConsigerCodeOptions")
      .then((response) => {
        // console.log(response.data, "consignerCodeOptions", response.data);


        var optionsforconsigner = response.data.map((e)=>{
          consignerCodeOptions.push({label:e,value:e})
        })
        console.log(consignerCodeOptions,'consignerCodeOptions')
        this.setState({
          consignerCodeOptions:consignerCodeOptions
          // rowdata: response.data,
          // citiesList:citiesList
        })


      })
      .catch((e) => {
        console.log(e);
      });

    await redirectURL
      .post("/consignments/gettransporteroptions")
      .then((response) => {

        console.log(response, "response117")
        var transporterOptions = (response.data).map((each) => ({ label: each.transporter_name, value: each.transporter_code }))
        console.log(transporterOptions, "126")
        this.setState({
          transporterOptions,
          // citiesList:citiesList
        })
      })
      .catch((e) => {
        console.log(e);
      });

    redirectURL.post("/consignments/getConsignmentsNewFilterKeys", { dept_code: 'LOG-PRT' })
      .then((resp) => {
        var cities = resp.data.cities;
        console.log(cities, 'cities--', cities)
        var filterCities = [];
        var city = cities
        city.map((ele) => {
          filterCities.push({ value: ele, label: ele })
        })

        console.log(filterCities, 'filterCities');
        this.setState({
          hubServingCitiesList: filterCities,
          loadshow: "show-n",
          overly: "show-n",
        });
      });






    //   loadDateTimeScript();
  }

  async onLoadData(){
      this.setState({
        loadshow: "show-m",
        overly: "show-m",
      })
   
  
      await redirectURL
        .post("/consignments/hubmaster")
        .then((response) => {
          // console.log(response.data, "140----", response.data.consignee_cities);
  
          this.setState({
            rowdata: response.data,
            // citiesList:citiesList
          })
  
  
        })
        .catch((e) => {
          console.log(e);
        });
        this.setState({
          loadshow: "show-n",
          overly: "show-n",
        })
     
    }

  closeAlert = () => {
    this.setState({
      show: false,
    });
  };


  async OnsubmitAddData(event) {
    event.preventDefault();
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    })
    // const selectedValues = this.state.hub_tpt_code.map(option => option.value);
    //  const selectedValuesString = selectedValues.join(',');

    const selectedValuesTrans = this.state.transporter_code.map(option => option.value);
    const selectedValuesStringTransString = selectedValuesTrans.join(',');

    const selectedValuesCities = this.state.hub_serving_city.map(option => option.value);
    const selectedValuesStringCitiesString = selectedValuesCities.join(',');

    var hub_location = $("#hub_location").val()
    var hub_code = $("#hub_code").val()
    var hub_tpt_code = this.state.hub_tpt_code.value
    var parent_hub_tpt_code = this.state.parent_hub_tpt_code.value
    var latitude = $("#latitude").val()
    var longitude = $("#longitude").val()
    var transporter_code = selectedValuesStringTransString
    var hub_serving_city = selectedValuesStringCitiesString
    var hub_consigner_code = this.state.hub_consigner_code.value

    if (hub_tpt_code != undefined && hub_tpt_code != "" && transporter_code != undefined && transporter_code != "" && hub_serving_city != undefined && hub_serving_city != "" && hub_consigner_code !== undefined && hub_consigner_code !== "" ) {


      var params = {
        hub_location: hub_location,
        hub_code: hub_code,
        hub_tpt_code: hub_tpt_code,
        latitude: latitude,
        longitude: longitude,
        transporter_code: transporter_code,
        hub_serving_city: hub_serving_city,
        hub_consigner_code:hub_consigner_code,
        created_by : localStorage.getItem("email")
      }

      if (parent_hub_tpt_code != "" && hub_tpt_code != undefined) {
        params["parent_hub_tpt_code"] = parent_hub_tpt_code
      }

      console.log(params, "params===");


      await redirectURL
        .post("/consignments/Addhubmaster", params)
        .then(async (response) => {
          if (response.data.message == 'successfully updated') {

            this.setState({

              loadshow: "show-n",
              overly: "show-n",
              bulkslide2: "",
              show: true,
              basicTitle: response.data.message,
              basicType: 'success',
              transporter_code: [],
              hub_tpt_code: '',
              hub_serving_city: [],
              hub_consigner_code:''

            });
            $("#hub_location").val('')
            $("#hub_code").val('')
            $("#latitude").val('')
            $("#longitude").val('')
            this.setState({
              hub_location: "",
              hub_code: "",
              latitude: "",
              longitude: "",
              transporter_code: [],
              hub_tpt_code: '',
              parent_hub_tpt_code: '',
              hub_serving_city: [],
              hub_consigner_code:''
            });
            await this.onLoadData();

          } else {

            this.setState({

              loadshow: "show-n",
              overly: "show-n",
              bulkslide2: "",
              show: true,
              basicTitle: response.data.message,
              basicType: 'danger',
              // transporter_code:[],
              // hub_tpt_code:'',
              // hub_serving_city:[]

            });

          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      alert("All fields are required!")
      this.setState({
        loadshow: "show-n",
        overly: "show-n",
      })
    }

  }



  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      bulkslide: "",
      bulkslide2: "",
      bulkslide3: "",
      bulkslide4: ''
    });
  }





  resetUpload = () => {
    this.setState({
      bulkslidebulk: '',
      overly: 'show-n',
      file: '',
      bulkMarkData: [],
      bulkslide: "",
      bulkslide2: "",
    })

  }



  async onCellClicked(e) {
    if (e.colDef.field === "Edit") {
      console.log(e.data, 'onclickvalues');
      var data = e.data;
      const transporterCodes = data.transporter_code.split(',').map(code => ({
        value: code,
        label: code
      }));
      const servingCities = data.hub_serving_city.split(',').map(city => ({
        value: city,
        label: city
      }));

      this.setState({
        rowId: data._id,
        hub_location: data.hub_location,
        hub_code: data.hub_code,
        hub_tpt_code: ({ value: data.hub_tpt_code, label: data.hub_tpt_code }),
        parent_hub_tpt_code: ({ value: data.parent_hub_tpt_code, label: data.parent_hub_tpt_code }),
        latitude: data.latitude,
        longitude: data.longitude,
        transporter_code: transporterCodes,
        hub_serving_city: servingCities,
        hub_consigner_code : ({label:data.hub_consigner_code,value:data.hub_consigner_code}),
        bulkslide: "slider-translate-30p",
      });
    }

    else if (e.colDef.field == "delete") {

      var params = {
        rowId: e.data._id,
        status_to_show: "deleted",
        type: 'hubDelete'
      }
      const confirmed = window.confirm('Are you sure you want to delete?');
      if (confirmed) {
        await redirectURL
          .post("/consignments/deletefromdata", params)
          .then(async (response) => {
            this.setState({

              loadshow: "show-n",
              overly: "show-n",
              bulkslide2: "",
              bulkslide: "",
              show: true,
              basicTitle: "Deleted Successfully",
              basicType: 'success'
            });
          })
      }
      await this.onLoadData()
    }
  }
  showBulkUpload() {
    $("#hub_location").val('')
    $("#hub_code").val('')
    $("#latitude").val('')
    $("#longitude").val('')
    this.setState({
      hub_location: "",
      hub_code: "",
      latitude: "",
      longitude: "",
      parent_hub_tpt_code: '',
      transporter_code: [],
      hub_tpt_code: '',
      hub_serving_city: [],
      hub_consigner_code:'',
      bulkslide2: "slider-translate-30p",
    })
  }


  editvalues = (event) => {
    console.log("Select", event.target.value);

    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
  }

  async OnsubmitEditData(event) {
    event.preventDefault();
    if (this.state.transporter_code.length > 0) {
      var selectedValuesTrans = this.state.transporter_code.map(option => option.value);
      var selectedValuesStringTransString = selectedValuesTrans.join(',');
    }
    if (this.state.hub_serving_city.length > 0) {
      var selectedValuesCities = this.state.hub_serving_city.map(option => option.value);
      var selectedValuesStringCitiesString = selectedValuesCities.join(',');
    }

    var rowId = this.state.rowId
    var hub_location = this.state.hub_location
    var hub_code = this.state.hub_code
    var hub_tpt_code = this.state.hub_tpt_code.value
    var parent_hub_tpt_code = this.state.parent_hub_tpt_code.value
    var latitude = this.state.latitude
    var longitude = this.state.longitude
    var transporter_code = selectedValuesStringTransString
    var hub_serving_city = selectedValuesStringCitiesString
    var hub_consigner_code = this.state.hub_consigner_code.value


    var params = {
      rowId: rowId,
      hub_location: hub_location,
      hub_code: hub_code,
      hub_tpt_code: hub_tpt_code,
      latitude: latitude,
      longitude: longitude,
      transporter_code: transporter_code,
      hub_serving_city: hub_serving_city,
      parent_hub_tpt_code: parent_hub_tpt_code,
      hub_consigner_code : hub_consigner_code
    }

    console.log(params, "params===");


    await redirectURL
      .post("/consignments/Edithubmaster", params)
      .then(async (response) => {

        this.setState({

          loadshow: "show-n",
          overly: "show-n",
          bulkslide2: "",
          bulkslide: "",
          show: true,
          basicTitle: "Uploaded Successfully",
          basicType: 'success'
        });
        this.setState({
          hub_location: "",
          hub_code: '',
          hub_tpt_code: '',
          parent_hub_tpt_code: '',
          latitude: '',
          longitude: '',
          transporter_code: [],
          hub_serving_city: [],
          hub_consigner_code:''
        })
        await this.onLoadData();

      })
      .catch((e) => {
        console.log(e);
      });


  }

  handleKeyPressforFloat = (event) => {
    const inputChar = String.fromCharCode(event.charCode);
    // Allow only numbers and the decimal point
    if (!/^\d*\.?\d*$/.test(inputChar)) {
      event.preventDefault(); // Prevent the character from being entered
    }
  };

  handleKeyPress = (event) => {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^[A-Za-z]*$/.test(inputChar)) {
      event.preventDefault(); // Prevent the character from being entered
    }
  };
  handleKeyPressCode = (event) => {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^[A-Z0-9]*$/.test(inputChar)) {
      event.preventDefault(); // Prevent the character from being entered
    }
  };
  handleKeyPressCodeTrans = (event) => {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^[A-Z0-9,]*$/.test(inputChar)) {
      event.preventDefault(); // Prevent the character from being entered
    }
  };

  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
  };

  onClickTab(clickedTab) {

    if (clickedTab == 'hubmaster') {
      window.location.href = "hubmaster"
      var hubMaster = "activet";
      var transporterMaster = "";
      var fleetMaster = "";
    } else if (clickedTab == "transportermaster") {
      window.location.href = "/transportermaster"
      var hubMaster = "";
      var transporterMaster = "activet";
      var fleetMaster = "";
    } else if (clickedTab == "lastmilefleetmaster") {
      window.location.href = "lastmilefleetmaster"
      var hubMaster = "";
      var transporterMaster = "";
      var fleetMaster = "activet";
    }
    this.setState({

      hubMaster: hubMaster,
      transporterMaster: transporterMaster,
      fleetMaster: fleetMaster
    })
  }
  handleChangeselect = async (selectedOptions) => {
    console.log(selectedOptions, 'selected options');

    const selectedValue = selectedOptions.value.toUpperCase();
    const matchingOptions = this.state.tpt_options.filter(option => option.value === selectedValue);

    console.log(matchingOptions, 'matchingOptions')
    if (matchingOptions.length === 0) {
      var params = { hub_tpt_code: selectedValue }
      await redirectURL
        .post("/consignments/addNewOptionToTPT", params)
        .then((response) => {

        })
    }


    await this.setState(
      { hub_tpt_code: {value:selectedValue,label:selectedValue} },
      () => console.log(`Selected Options:`, this.state.hub_tpt_code)
    );

    await redirectURL
    .post("/consignments/getoptionsforhubtpt")
    .then((response) => {
      // console.log(response.data, "140----tpt options");
      var optionsCollection = []
      var tpt_options = response.data.map((e) => {
        optionsCollection.push({ value: e.hub_tpt_code, label: e.hub_tpt_code })
      })
      this.setState({
        tpt_options: optionsCollection
      })
    })
    .catch((e) => {
      console.log(e);
    });

  }
  handleChangeselectParentTpt = (selectedOptions) => {
    console.log(selectedOptions, 'selected options'); // Log the selected options
    this.setState(
      { parent_hub_tpt_code: selectedOptions },
      () => console.log(`Selected Options:`, this.state.hub_tpt_code)
    );
  } 
  handleChangeselectConsignerCode = (selectedOptions) => {
    console.log(selectedOptions, 'selected options'); // Log the selected options
    this.setState(
      { hub_consigner_code: selectedOptions },
      () => console.log(`Selected Options:`, this.state.hub_consigner_code)
    );
  } 
  handleChangeselecttrans = async (selectedOptions) => {
    // Validate each selected option
    if(selectedOptions != null && selectedOptions != undefined && selectedOptions != ""){

      const validatedOptions = selectedOptions.map((option) => {
        const validatedValue = option.value.toUpperCase();
        return { value: validatedValue, label: validatedValue };
      });
    
      // Check if a new option is entered
      const newOptions = validatedOptions.filter((option) => !this.state.tpt_options.some((existingOption) => existingOption.value === option.value));
    
      // If new options are entered, handle them
      if (newOptions.length > 0) {
       
      
        const params = { hub_tpt_code: newOptions.map((option) => option.value) };
        await redirectURL.post("/consignments/addNewOptionToTPTForTransporter", params);
      }
    
      // Update the state with the validated options
      this.setState(
        { transporter_code: validatedOptions },
        () => console.log(`Selected Options:`, this.state.transporter_code)
      );
  
      await redirectURL
      .post("/consignments/getoptionsforhubtpt")
      .then((response) => {
        // console.log(response.data, "140----tpt options");
        var optionsCollection = []
        var tpt_options = response.data.map((e) => {
          optionsCollection.push({ value: e.hub_tpt_code, label: e.hub_tpt_code })
        })
        this.setState({
          tpt_options: optionsCollection
        })
      })
      .catch((e) => {
        console.log(e);
      });
    }else{
      this.setState({
        transporter_code:''
      })
    }
  };
  
  handleChangeselectCitites = (selectedOptions) => {
    console.log(selectedOptions, 'selected options000'); // Log the selected options
    this.setState(
      { hub_serving_city: selectedOptions },
      () => console.log(`Selected Options:000`, this.state.hub_serving_city)
    );
  }

  render() {
    // console.log(this.state.hubServingCitiesList, 'this.state.hubServingCitiesList')
    const batchwisecolumns = [
      {
        pinned: "left",
        headerName: "Edit",
        field: "Edit",
        width: 120,
        resizable: true,
        editable: false,
        cellRenderer: function (params) {
          var rendComponent =
            '<button class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</button>';
          return rendComponent;
        },
        filter: false,
      },
      {
        pinned: "left",
        headerName: "Delete",
        field: "delete",
        width: 80,
        resizable: true,
        editable: false,
        cellRenderer: function (params) {
          return '<i class="icofont icofont-ui-delete"></i>';
        },
      },
      {
        headerName: 'Hub Location',
        field: 'hub_location',
        with: 150,
      },
      {
        headerName: 'Hub Code',
        field: 'hub_code',
        with: 150,
      },
      {
        headerName: 'Parent TPT Code',
        field: 'parent_hub_tpt_code',
        width: 150,
      },
      {
        headerName: 'Hub TPT Code',
        field: 'hub_tpt_code',
        width: 150,
      },
      {
        headerName: 'Primary Consigner code',
        field: 'hub_consigner_code',
        width: 150,
      },
      {
        headerName: 'Latitude',
        field: 'latitude',
        width: 150,
      },
      {
        headerName: 'Longitude',
        field: 'longitude',
        width: 150,
      },
      {
        headerName: '1st Mile Transporter Code',
        field: 'transporter_code',
        width: 150,
      },
      {
        headerName: 'Hub Serving Cities ',
        field: 'hub_serving_city',
        width: 150,
      },

    ];

    return (
      <div class="container-fluid">
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
        <div className="col-xl-12 col-lg-12">
          <div className="f16" style={{ marginBottom: "10px" }}>
            <ul className="d-tabs">

              <li onClick={this.onClickTab.bind(this, "hubmaster")} className={"lttabs " + (this.state.hubMaster)}>
                <button type="button" className="btn">Hub Master</button>
              </li>

              <li onClick={this.onClickTab.bind(this, "lastmilefleetmaster")} className={"lttabs " + (this.state.fleetMaster)}>
                <button type="button" className="btn">Last Mile Fleet Master</button>
              </li>
            </ul>
          </div>
        </div>
        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
          <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
        <div className={"dataLoadpage " + (this.state.loadshow)}></div>
        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
          <div className="loader-box">
            <div className="loader-box">
              <div className="rotate dashed colored">
              </div>
            </div>
          </div>
        </div>
        <div className="card col-xl-12">
          <div className="card-header">
            <h5>
              <i className="icofont icofont-vehicle-delivery-van cus-i"></i>{" "}
              <span>Hub Master</span>
              <button className="btn btn-info" style={{ float: "right", marginRight: "10px" }} onClick={this.showBulkUpload.bind(this)}>ADD</button>

              <div className="card-body">
                <div className="row">
                  <div
                    id="myGrid"
                    style={{ width: "100%", height: "478px" }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      modules={this.state.modules}
                      columnDefs={batchwisecolumns}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.rowdata}
                      enableCharts={false}
                      onGridReady={this.onGridReady}
                      onGridState={this.onGridState}
                      frameworkComponents={this.state.frameworkComponents}
                      rowClassRules={this.state.rowClassRules}
                      sideBar={this.state.sideBar}
                      stopEditingWhenGridLosesFocus={true}
                      onCellClicked={this.onCellClicked.bind(this)}
                      paginationPageSize={this.state.paginationPageSize}
                      pagination={true}
                      gridOptions={{
                        context: { componentParent: this },
                      }}
                      // onCellClicked={this.onCellRowClicked.bind(this)}
                      enableRangeSelection={true}
                    />
                  </div>
                </div>
              </div>
            </h5>
          </div>

          {/* work in progress Fresh Incoming rakes */}

        </div>
        <div
          className={"slide1-r " + this.state.bulkslide2}
          style={{ overflow: "scroll" }}
        >
          <h3 className="subH">
            Add Hub Master
            <span
              className="float-right fclose"
              style={{
                marginRight: "12px",
                padding: "1px 8px",
                marginTop: "-2px",
              }}
              onClick={this.onClickHideAll.bind(this)}
            >
              x
            </span>
          </h3>
          <div className="slide-r-body" style={{ position: "relative" }}>
            <div className="container-fluid">
              <form
                method="post"
                onSubmit={this.OnsubmitAddData.bind(this)}
                autocomplete="off"
                className="row col-xl-12 col-lg-12"
              >
                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                    Hub Location*
                  </label>
                  <input
                    type="text"
                    name="hub_location"
                    // value={this.state.hub_location}
                    id="hub_location"
                    className="form-control"
                    placeholder="Hub Location"
                    onKeyPress={this.handleKeyPress}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                    Hub Code*
                  </label>
                  <input
                    type="text"
                    name="hub_code"
                    // value={this.state.hub_code}
                    id="hub_code"
                    className="form-control"
                    placeholder="Hub Code"
                    onKeyPress={this.handleKeyPressCode}
                    onChange={this.handleChange}
                    required
                  />
                </div>


                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                    Parent Hub TPT Code*
                  </label>
                  <Select
                    placeholder="Parent Hub TPT Code"
                    // isMulti={true}
                    closeMenuOnSelect={true}
                    // type="text"
                    name="hub_tpt_code"
                    value={this.state.parent_hub_tpt_code}
                    id="parent_hub_tpt_code"
                    className="border-radius-0"
                    // onKeyPress={this.handleKeyPressCode}
                    onChange={this.handleChangeselectParentTpt}
                    options={this.state.transporterOptions}
                    required

                  />
                </div>
                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                    Hub TPT Code*
                  </label>
                  <CreatableSelect
                    placeholder="Hub TPT Code"
                    // isMulti={true}
                    closeMenuOnSelect={true}
                    // type="text"
                    name="hub_tpt_code"
                    value={this.state.hub_tpt_code}
                    id="hub_tpt_code"
                    className="border-radius-0"
                    // onKeyPress={this.handleKeyPressCode}
                    onChange={this.handleChangeselect}
                    options={this.state.tpt_options}
                    required

                  />
                </div>
                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                  Primary Consigner code*
                  </label>
                  <Select
                    placeholder="Primary Consigner code"
                    // isMulti={true}
                    closeMenuOnSelect={true}
                    // type="text"
                    name="hub_consigner_code"
                    value={this.state.hub_consigner_code}
                    id="hub_consigner_code"
                    className="border-radius-0"
                    // onKeyPress={this.handleKeyPressCode}
                    onChange={this.handleChangeselectConsignerCode}
                    options={this.state.consignerCodeOptions}
                    required

                  />
                </div>
                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                    Latitude*
                  </label>
                  <input
                    type="text"
                    name="latitude"
                    // value={this.state.latitude}
                    id="latitude"
                    className="form-control"
                    placeholder="Latitude"
                    onKeyPress={this.handleKeyPressforFloat}
                    onChange={this.handleChange}
                    required
                  />
                </div>

                <div className="form-group col-xl-12 col-lg-12">
                  <label>
                    Longitude*
                  </label>
                  <input
                    type="text"
                    name="longitude"
                    // value={this.state.longitude}
                    id="longitude"
                    className="form-control"
                    placeholder="Longitude"
                    onKeyPress={this.handleKeyPressforFloat}
                    onChange={this.handleChange}
                    required
                  />

                </div>
                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                    1st Mile Transporter Code*
                  </label>
                  <CreatableSelect
                    // type="text"
                    name="transporter_code"
                    id="transporter_code"
                    isMulti={true}
                    closeMenuOnSelect={true}
                    value={this.state.transporter_code}
                    className="border-radius-0"
                    placeholder="1st mile Transporter Code"
                    // onKeyPress={this.handleKeyPressCodeTrans}
                    onChange={this.handleChangeselecttrans}
                    required
                    options={this.state.tpt_options}
                  />

                </div>
                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                    Hub Serving Cities*
                  </label>
                  <Select
                    // type="text"
                    name="hub_serving_city"
                    id="hub_serving_city"
                    isMulti={true}
                    closeMenuOnSelect={true}
                    value={this.state.hub_serving_city}
                    className="border-radius-0"
                    placeholder="Hub Serving Cities "
                    // onKeyPress={this.handleKeyPressCodeTrans}
                    onChange={this.handleChangeselectCitites}
                    required
                    options={this.state.hubServingCitiesList}
                  />

                </div>
                <div className="form-group col-xl-3 col-lg-3">
                  <button type="submit" className="btn btn-success">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className={"slide1-r " + this.state.bulkslide}
          style={{ overflow: "hidden" }}
        >
          <h3 className="subH">
            Edit Hub Master
            <span
              className="float-right fclose"
              style={{
                marginRight: "12px",
                padding: "1px 8px",
                marginTop: "-2px",
              }}
              onClick={this.onClickHideAll.bind(this)}
            >
              x
            </span>
          </h3>
          <div className="slide-r-body" style={{ position: "relative" }}>
            <div className="container-fluid">
              <form
                method="post"
                onSubmit={this.OnsubmitEditData.bind(this)}
                autocomplete="off"
                className="row col-xl-12 col-lg-12"
              >
                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                    Hub Location*
                  </label>
                  <input
                    type="text"
                    name="hub_location"
                    value={this.state.hub_location}

                    onChange={this.editvalues}
                    className="form-control"
                    placeholder="Hub Location"
                    onKeyPress={this.handleKeyPress}

                  />
                </div>
                <div className="form-group col-xl-12 col-lg-12">
                  <label>
                    Hub Code*
                  </label>
                  <input
                    type="text"
                    name="hub_code"
                    value={this.state.hub_code}
                    onChange={this.editvalues}
                    className="form-control"
                    placeholder="Hub Code"
                    onKeyPress={this.handleKeyPressCode}

                  />
                </div>
                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                    Parent Hub TPT Code*
                  </label>
                  <Select
                    placeholder="Parent Hub TPT Code"
                    // isMulti={true}
                    closeMenuOnSelect={true}
                    // type="text"
                    name="hub_tpt_code"
                    value={this.state.parent_hub_tpt_code}
                    id="parent_hub_tpt_code"
                    className="border-radius-0"
                    // onKeyPress={this.handleKeyPressCode}
                    onChange={this.handleChangeselectParentTpt}
                    options={this.state.transporterOptions}
                    required

                  />
                </div>

                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                    Hub TPT Code*
                  </label>
                  <CreatableSelect
                    placeholder="Hub TPT Code"
                    // isMulti={true}
                    closeMenuOnSelect={true}
                    // type="text"
                    name="hub_tpt_code"
                    value={this.state.hub_tpt_code}
                    id="hub_tpt_code"
                    className="border-radius-0"
                    // onKeyPress={this.handleKeyPressCode}
                    onChange={this.handleChangeselect}
                    options={this.state.tpt_options}
                    required

                  />
                </div>
                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                  Primary Consigner code*
                  </label>
                  <Select
                    placeholder="Primary Consigner code"
                    // isMulti={true}
                    closeMenuOnSelect={true}
                    // type="text"
                    name="hub_consigner_code"
                    value={this.state.hub_consigner_code}
                    id="hub_consigner_code"
                    className="border-radius-0"
                    // onKeyPress={this.handleKeyPressCode}
                    onChange={this.handleChangeselectConsignerCode}
                    options={this.state.consignerCodeOptions}
                    required

                  />
                </div>
                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                    Latitude*
                  </label>
                  <input
                    type="text"
                    name="latitude"
                    value={this.state.latitude}
                    onChange={this.editvalues}
                    className="form-control"
                    placeholder="Latitude"
                    onKeyPress={this.handleKeyPressforFloat}

                  />
                </div>

                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                    Longitude*
                  </label>
                  <input
                    type="text"
                    name="longitude"
                    value={this.state.longitude}
                    onChange={this.editvalues}
                    className="form-control"
                    placeholder="Longitude"
                    onKeyPress={this.handleKeyPressforFloat}

                  />

                </div>
                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                    1st Mile Transporter Code*
                  </label>
                  <CreatableSelect
                    // type="text"
                    name="transporter_code"
                    id="transporter_code"
                    isMulti={true}
                    closeMenuOnSelect={true}
                    value={this.state.transporter_code}
                    className="border-radius-0"
                    placeholder="1st mile Transporter Code"
                    // onKeyPress={this.handleKeyPressCodeTrans}
                    onChange={this.handleChangeselecttrans}
                    required
                    options={this.state.tpt_options}
                  />

                </div>
                <div className="form-group col-xl-12 col-lg-12">
                  <label >
                    Hub Serving Cities*
                  </label>
                  <Select
                    // type="text"
                    name="hub_serving_city"
                    id="hub_serving_city"
                    isMulti={true}
                    closeMenuOnSelect={true}
                    value={this.state.hub_serving_city}
                    className="border-radius-0"
                    placeholder="Hub Serving Cities "
                    // onKeyPress={this.handleKeyPressCodeTrans}
                    onChange={this.handleChangeselectCitites}
                    required
                    options={this.state.hubServingCitiesList}
                  />

                </div>
                <div className="form-group col-xl-3 col-lg-3">
                  <button type="submit" className="btn btn-success">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>


      </div>
    );
  }
}


function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
  // create the cell
  console.log(params, "params");
  this.eInput = document.createElement("input");
  this.eInput.value = params.value;

  // https://jqueryui.com/datepicker/
  $(this.eInput).datetimepicker({
    dateFormat: "yy-mm-dd",
    timepicker: false,
    changeMonth: true,
    changeYear: true,
  });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
  return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
  this.eInput.focus();
  this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
  return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
  // but this example is simple, no cleanup, we could
  // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
  // and we could leave this method out also, false is the default
  return false;
};



import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var moment = require('moment');
var redirectURL = require('../redirectURL');

 
const Remarksaction = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onChangeRemarkOptions(props);
        
    };
   
    return (
        
        <span>
            {/* <select onChange={handleClick} style={{width: "115px",height:"22px", marginTop: "2px"}}>
                <option value="">Select</option>
                <option value="available">Truck available for loading</option>
                <option value="repair">Truck Repair/Under Maintenance</option>
            </select> */}
            <button type="button" onClick={handleClick} className="btn btn-warning n-p-0 f12">Available for loading</button>
           </span>
        );
};

export default Remarksaction;

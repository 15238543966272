'use strict';

import React, { Component } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';

import { AllModules } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CellGraphComponent from './cellGraphComponent';
import $ from 'jquery';
import Consignmentforceclose from '../manage/consignmentforceclose';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var CELL_DIMENSION_SIZE = 90;
export default class DepartmentDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // modules: [
      //   ClientSideRowModelModule,
      //   RowGroupingModule,
      //   MenuModule,
      //   ColumnsToolPanelModule,
      //   FiltersToolPanelModule,
      //   SetFilterModule,
      //   AllModules
      // ],
      sliderRouteTranslate:'',
      serires:[],
      categories:[],
      activeserires:[],
      activecategories:[],
			loadshow:'show-n',
      overly:'show-n',
      leftTitle:'Delay Meter for selected period',
      leftSubTitle:'',
      activeleftTitle:'Delay Meter as on Date(Cummulative)',
      activeleftSubTitle:'',
      sidebartitle:"",
      modules: AllModules,
      defaultColDef: {
        //flex: 1,
        minWidth: 50,
        filter: true,
        sortable: true,
      },
      autoGroupColumnDef: {
        headerName: 'Department',
        field: 'dept_name',
        minWidth: 50,
        width:240,
        cellRendererParams: {
          suppressCount: true,
          //checkbox: true,
        },
        comparator: function(valueA, valueB) {
          console.log("valueA ", valueA)
          if (valueA == null || valueB == null) return valueA - valueB;
          if (!valueA.substring || !valueB.substring) return valueA - valueB;
          if (valueA.length < 1 || valueB.length < 1) return valueA - valueB;
          return strcmp(
            valueA.substring(1, valueA.length),
            valueB.substring(1, valueB.length)
          );
        },
      },
      originalchartdata:[],
			detailCellRendererParams:{},
      rowData: null,
      popupParent: document.body,
      groupHeaderHeight: 50,
      headerHeight: 70,
      floatingFiltersHeight: 50,
      pivotGroupHeaderHeight: 30,
      pivotHeaderHeight: 50,
      rowHeight:50,
      startDate:'',
      endDate:'',
      showlegs:[],
      legcolumns:[],
      legrows:null,
      frameworkComponents:{
        cellGraphComponent:CellGraphComponent
      },
      // defaultGroupSortComparator: function(nodeA, nodeB) {
      //   if (parseInt(nodeA.key) < parseInt(nodeB.key)) {
      //       return -1;
      //     } else if (parseInt(nodeA.key) > parseInt(nodeB.key)) {
      //       return 1;
      //     } else {
      //       return 0;
      //     }
       
      // },
      searchkey:''
    };
    this.onSearchValue = this.onSearchValue.bind(this);
  }

	componentDidMount(){
    var defaultdate = moment.parseZone().subtract(1, 'days').format('YYYY-MM-DD');
    this.setState({
      startDate:defaultdate,
      endDate:defaultdate,
      loadshow:'show-m',
      overly:'show-m',
    });
    var reqparms = {
      startDate:defaultdate,
      endDate:defaultdate
    }
        redirectURL.post("/consignments/dashboardtemplatedata", reqparms)
        .then((response) => {
          var recordsarr = response.data.message;
          var records=[];
          recordsarr.map((item) => {
            var zerodays;
            var onedays;
            var twodays;
            var activezerodays;
            var activeonedays;
            var activetwodays;
            //console.log("item.transit_delay_days ", typeof parseInt(item.transit_delay_days))
             // if(parseInt(item.transit_delay_days) == 0 || 
            // parseInt(item.transit_delay_days) == 1 ||
            // parseInt(item.transit_delay_days) == 2)
            // {
              var set;
              var status;
              var activeset;
              var consoletxt = parseInt(item.transit_delay_days)+" "+item.dept_name+" "+item.status+" "+item.set
              //console.log("container Imports ", consoletxt)
                if(parseInt(item.transit_delay_days) == 0 && parseInt(item.set) == 1 && (parseInt(item.status) == 2 || item.status == "In ICD Ghari"))
                {
                      zerodays=1
                }
      
                if(parseInt(item.transit_delay_days) == 1 && parseInt(item.set) == 1 && (parseInt(item.status) == 2 || item.status == "In ICD Ghari"))
                {
                      onedays=1
                }
      
                if(parseInt(item.transit_delay_days) == 2 && parseInt(item.set) == 1 && (parseInt(item.status) == 2 || item.status == "In ICD Ghari"))
                {
                      twodays=1
                }

                if(parseInt(item.set) == 1 && parseInt(item.status) != -1)
                {
                    set=1;
                }
                else{
                    set=0;
                }
                if(parseInt(item.status) != -1)
                {
                  if(parseInt(item.status) == 2 && parseInt(item.set) == 1)
                  {
                      status=1;
                  }
                  else{
                      status=0;
                  }
                }
                if(parseInt(item.set) == 2 && parseInt(item.status) != -1)
                {
                    activeset=1;
                }
                else{
                  activeset=0;
                }

                
                if(parseInt(item.transit_delay_days) == 0 && parseInt(item.set) == 2 && (parseInt(item.status) == 2 || item.status == "In ICD Ghari"))
                {
                      activezerodays=1
                }
      
                if(parseInt(item.transit_delay_days) == 1 && parseInt(item.set) == 2 && (parseInt(item.status) == 2 || item.status == "In ICD Ghari"))
                {
                    activeonedays=1
                }
      
                if(parseInt(item.transit_delay_days) == 2 && parseInt(item.set) == 2 && (parseInt(item.status) == 2 || item.status == "In ICD Ghari"))
                {
                    activetwodays=1
                }
                //console.log("item.delivery_order ", item.delivery_order)
                if(item.dept_name != "-1" && item.dept_name != "NA")
                {
                    //console.log("item.consigner_code ", item.consigner_code)
                    var itemdata= {
                      "consigner_code": item.consigner_code,
                      "consignment_code": item.consignment_code,
                      "dept_code": item.dept_code,
                      "dept_name": item.dept_name,
                      "invoice_date": item.invoice_date,
                      "transit_delay_days_0": (typeof zerodays == 'undefined' || zerodays == -1)?0:zerodays,
                      "transit_delay_days_1": (typeof onedays == 'undefined' || onedays == -1)?0:onedays,
                      "transit_delay_days_2": (typeof twodays == 'undefined' || twodays == -1)?0:twodays,
                      "uom": item.uom,
                      "delivery_order": item.delivery_order,
                      "set": (typeof set == 'undefined')?0:set,
                      "status": (typeof status == 'undefined')?0:status,
                      "extra_details" :item.extra_details,
                      "activeset": (typeof activeset == 'undefined')?0:activeset,
                      "active_transit_delay_days_0": (typeof activezerodays == 'undefined' ||   activezerodays == -1)?0:activezerodays,
                      "active_transit_delay_days_1": (typeof activeonedays == 'undefined' || activeonedays == -1)?0:activeonedays,
                      "active_transit_delay_days_2": (typeof activetwodays == 'undefined' || activetwodays == -1)?0:activetwodays,
                    };
                    records.push(itemdata)
              }
                
            
            
            
          })
          //console.log("Respons ", JSON.stringify(recordsarr))
          //updateData(records);
          this.setState({
            rowData:records,
            loadshow:'show-n',
            overly:'show-n',
          });
        })
        .catch(function(e){
          console.log("Error ", e)
          
        })
        
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.params = params;
    this.gridColumnApi = params.columnApi;
    

  };

  onGridState = () =>{
    console.log("params.api ",this.gridApi)
    var childrows = this.gridApi.rowModel.rowsToDisplay
    var category = [];
    var serires1 = [];
    var serires2 = [];
    var serires3 = [];

    var activecategory = [];
    var activeserires1 = [];
    var activeserires2 = [];
    var activeserires3 = [];
    if(childrows.length > 0)
    {
      childrows.map((item) => {
        category.push(item.key);
        serires1.push(item.aggData.transit_delay_days_0);
        
        serires2.push(item.aggData.transit_delay_days_1);
        
        serires3.push(item.aggData.transit_delay_days_2);

        activecategory.push(item.key);
        activeserires1.push(item.aggData.active_transit_delay_days_0);
        
        activeserires2.push(item.aggData.active_transit_delay_days_1);
        
        activeserires3.push(item.aggData.active_transit_delay_days_2);
      })  
    }
    this.setState({
      serires:[{
        "name":"On Time",
        data:serires1,
        dataLabels: {
          enabled: false,
          color: '#00f0b5'
        }
      },{
        "name":"1 Day",
        data:serires2,
        dataLabels: {
          enabled: false,
          color: '#fff493'
        }
      },{
        "name":">1 Day",
        data:serires2,
        dataLabels: {
          enabled: false,
          color: '#f88fa2'
        }
      }],
      categories:category,
      
      activeserires:[{
        "name":"On Time",
        data:activeserires1
      },{
        "name":"1 Day",
        data:activeserires2
      },{
        "name":">1 Day",
        data:activeserires3
      }],
      activecategories:category
    })

    this.gridApi.groupRowRendererParams = {
      suppressCount: true
    };
      
 }

  expandAll = () => {
    this.gridApi.expandAll();
  };

  collapseAll = () => {
    this.gridApi.collapseAll();
  };

  groupRowAggNodes = nodes => {
    var result = {
        transit_delay_days_0: 0,
        transit_delay_days_1: 0,
        transit_delay_days_2: 0,
        active_transit_delay_days_0: 0,
        active_transit_delay_days_1: 0,
        active_transit_delay_days_2: 0,
        set: 0,
        status: 0,
        activeset:0
    };
    var st=[];
    //console.log("se ",nodes)
    nodes.forEach(function(node) {
      var data = node.group ? node.aggData : node.data;
      // console.log("node ",data)
      // console.log("nodedata ",typeof data.active_transit_delay_days_0)
      if (typeof data.transit_delay_days_0 === 'number') {
        result.transit_delay_days_0 += data.transit_delay_days_0;
      }
      if (typeof data.transit_delay_days_1 === 'number') {
        result.transit_delay_days_1 += data.transit_delay_days_1;
      }
      if (typeof data.transit_delay_days_2 === 'number') {
        result.transit_delay_days_2 += data.transit_delay_days_2;
      }
      
      if (typeof data.set === 'number') {
        if(data.set != -1)
        {
          result.set += data.set;
        }
      }
      if (typeof data.activeset === 'number') {
        if(data.activeset != -1)
        {
          result.activeset += data.activeset;
        }
      }
      if (typeof data.status === 'number') {
        if(data.status != -1 && data.status != 0)
        {
          result.status += data.status;
          st.push("a")
        }
        
      }

      if (typeof data.active_transit_delay_days_0 === 'number') {
        result.active_transit_delay_days_0 += data.active_transit_delay_days_0;
      }
      if (typeof data.active_transit_delay_days_1 === 'number') {
        result.active_transit_delay_days_1 += data.active_transit_delay_days_1;
      }
      if (typeof data.active_transit_delay_days_2 === 'number') {
        result.active_transit_delay_days_2 += data.active_transit_delay_days_2;
      }
    });
    //console.log(st.length)
    //console.log("asda ", result)
    return result;
  };
  onFirstDataRendered = params => {
    console.log("onFIrst chart,", params)
    var childrows = params.api.rowModel.rowsToDisplay
    var category = [];
    var serires1 = [];
    var serires2 = [];
    var serires3 = [];

    var activecategory = [];
    var activeserires1 = [];
    var activeserires2 = [];
    var activeserires3 = [];
    if(childrows.length > 0)
    {
      childrows.map((item) => {
        category.push(item.key);
        serires1.push(item.aggData.transit_delay_days_0);
        
        serires2.push(item.aggData.transit_delay_days_1);
        
        serires3.push(item.aggData.transit_delay_days_2);

        activecategory.push(item.key);
        activeserires1.push(item.aggData.active_transit_delay_days_0);
        
        activeserires2.push(item.aggData.active_transit_delay_days_1);
        
        activeserires3.push(item.aggData.active_transit_delay_days_2);
      })  
    }
    var originalchartdata = [
      {
        "series1":serires1,
        "series2":serires2,
        "series3":serires3,
        "category":category,
        "activeseries1":activeserires1,
        "activeseries2":activeserires2,
        "activeseries3":activeserires3,
        "activecategory":activecategory,
      }
    ]
    var ltitle = "Delay Meter for selected period";
    var stitle = "";
    var altitle = "Delay Meter as on Date(Cummulative)";
    var astitle = "";
    this.setState({
      originalchartdata:originalchartdata,
      serires:[{
        "name":"On Time",
        data:serires1,
        dataLabels: {
          enabled: false,
          color: '#00f0b5'
        }
      },{
        "name":"1 Day",
        data:serires2,
        dataLabels: {
          enabled: false,
          color: '#fff493'
        }
      },{
        "name":">1 Day",
        data:serires2,
        dataLabels: {
          enabled: false,
          color: '#f88fa2'
        }
      }],
      categories:category,
      
      activeserires:[{
        "name":"On Time",
        data:activeserires1
      },{
        "name":"1 Day",
        data:activeserires2
      },{
        "name":">1 Day",
        data:activeserires3
      }],
      activecategories:category,
      
      leftSubTitle:stitle,
      activeleftSubTitle:astitle,
    })
  
  };

	handlerStartDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    
    var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    
    this.setState({
      startDate:startdate
    });
    //let value = event.target.value;
      //this.setState({'startDate':value});
  }

  handlerEndDateTime  = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    
      var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
      this.setState({
      endDate:edate
    });
}
  onClickSummaryData(event){
    this.setState({
      loadshow:'show-m',
      overly:'show-m',
    });
    var reqparms = {
      startDate:this.state.startDate,
      endDate:this.state.endDate
    }
        redirectURL.post("/consignments/dashboardtemplatedata", reqparms)
        .then((response) => {
          var recordsarr = response.data.message;
          var records=[];
          recordsarr.map((item) => {
            var zerodays;
            var onedays;
            var twodays;
            var activezerodays;
            var activeonedays;
            var activetwodays;
            //console.log("item.transit_delay_days ", typeof parseInt(item.transit_delay_days))
             // if(parseInt(item.transit_delay_days) == 0 || 
            // parseInt(item.transit_delay_days) == 1 ||
            // parseInt(item.transit_delay_days) == 2)
            // {
              var set;
              var status;
              var activeset;
              var consoletxt = parseInt(item.transit_delay_days)+" "+item.dept_name+" "+item.status+" "+item.set
              //console.log("container Imports ", consoletxt)
                if(parseInt(item.transit_delay_days) == 0 && parseInt(item.set) == 1 && (parseInt(item.status) == 2 || item.status == "In ICD Ghari"))
                {
                      zerodays=1
                }
      
                if(parseInt(item.transit_delay_days) == 1 && parseInt(item.set) == 1 && (parseInt(item.status) == 2 || item.status == "In ICD Ghari"))
                {
                      onedays=1
                }
      
                if(parseInt(item.transit_delay_days) == 2 && parseInt(item.set) == 1 && (parseInt(item.status) == 2 || item.status == "In ICD Ghari"))
                {
                      twodays=1
                }

                if(parseInt(item.set) == 1 && parseInt(item.status) != -1)
                {
                    set=1;
                }
                else{
                    set=0;
                }
                if(parseInt(item.status) != -1)
                {
                  if(parseInt(item.status) == 2 && parseInt(item.set) == 1)
                  {
                      status=1;
                  }
                  else{
                      status=0;
                  }
                }
                if(parseInt(item.set) == 2 && parseInt(item.status) != -1)
                {
                    activeset=1;
                }
                else{
                  activeset=0;
                }

                
                if(parseInt(item.transit_delay_days) == 0 && parseInt(item.set) == 2 && (parseInt(item.status) == 2 || item.status == "In ICD Ghari"))
                {
                      activezerodays=1
                }
      
                if(parseInt(item.transit_delay_days) == 1 && parseInt(item.set) == 2 && (parseInt(item.status) == 2 || item.status == "In ICD Ghari"))
                {
                    activeonedays=1
                }
      
                if(parseInt(item.transit_delay_days) == 2 && parseInt(item.set) == 2 && (parseInt(item.status) == 2 || item.status == "In ICD Ghari"))
                {
                    activetwodays=1
                }
                //console.log("item.delivery_order ", item.delivery_order)
                if(item.dept_name != "-1" && item.dept_name != "NA")
                {
                  var itemdata= {
                    "consigner_code": item.consigner_code,
                    "consignment_code": item.consignment_code,
                    "dept_code": item.dept_code,
                    "dept_name": item.dept_name,
                    "invoice_date": item.invoice_date,
                    "transit_delay_days_0": (typeof zerodays == 'undefined' || zerodays == -1)?0:zerodays,
                    "transit_delay_days_1": (typeof onedays == 'undefined' || onedays == -1)?0:onedays,
                    "transit_delay_days_2": (typeof twodays == 'undefined' || twodays == -1)?0:twodays,
                    "uom": item.uom,
                    "delivery_order": item.delivery_order,
                    "extra_details" :item.extra_details,
                    "set": (typeof set == 'undefined')?0:set,
                    "status": (typeof status == 'undefined')?0:status,
                    "activeset": (typeof activeset == 'undefined')?0:activeset,
                    "active_transit_delay_days_0": (typeof activezerodays == 'undefined' ||   activezerodays == -1)?0:activezerodays,
                    "active_transit_delay_days_1": (typeof activeonedays == 'undefined' || activeonedays == -1)?0:activeonedays,
                    "active_transit_delay_days_2": (typeof activetwodays == 'undefined' || activetwodays == -1)?0:activetwodays,
                };
                records.push(itemdata)
              }
            
            
          })
          //console.log("Respons ", records)
          //updateData(records);
          this.setState({
            rowData:records,
            loadshow:'show-n',
            overly:'show-n',
          });

          this.onFirstDataRendered(this.params)
        })
        .catch(function(e){
          console.log("Error ", e)
          
        })
  }

  
  getChartToolbarItems = params => {
    return [];
  };
  onRowGroupOpened = async event => {
    console.log("rowModel ",this.gridApi.rowModel);
    var rowmodels = this.gridApi.rowModel.rowsToDisplay;
    var flagone = [];
    
    await rowmodels.map((item) => {
      if(item.expanded == false)
      {
        
      }
      else{
        flagone.push(1)
      }
    })
    console.log("flag ",flagone)

    console.log("checkc ", event.node)
    // if (event.node.expanded) {
    //   var expandedNode = event.node;
    //   event.api.forEachNode(function(node) {
    //       if (node.group && node.expanded) {
    //           var contract = true;
    //           var pointer = expandedNode;
    //           while (pointer) {
    //               if (pointer===node) {
    //                   contract = false;
    //               }
    //               pointer = pointer.parent;
    //           }
    //           if (contract) {
    //               node.setExpanded(false);
    //           }
    //       }
    //   });
    // }
    //console.log("sss ", event.node.rowIndex)
    //console.log("le ",event.node.childrenAfterGroup.length)

    if(flagone.length == 0)
    {
      var originalchartdata = this.state.originalchartdata;
      //console.log("originalchartdata ",originalchartdata)
      this.setState({
        serires:[{
          "name":"On Time",
          data:originalchartdata[0].series1
        },{
          "name":"1 Day",
          data:originalchartdata[0].series2
        },{
          "name":">1 Day",
          data:originalchartdata[0].series3
        }],
        categories:originalchartdata[0].category,
        
        activeserires:[{
          "name":"On Time",
          data:originalchartdata[0].activeseries1
        },{
          "name":"1 Day",
          data:originalchartdata[0].activeseries2
        },{
          "name":">1 Day",
          data:originalchartdata[0].activeseries3
        }],
        activecategories:originalchartdata[0].activecategory,
        leftSubTitle:"",
        activeleftSubTitle:"",
      });
    }
    else{
        var childrows = event.node.childrenAfterGroup;
        console.log("event.node ",event.node)
        var category = [];
        var serires1 = [];
        var serires2 = [];
        var serires3 = [];
        var activecategory = [];
        var activeserires1 = [];
        var activeserires2 = [];
        var activeserires3 = [];
        var sflag=0;
        if(childrows.length > 0)
        {
          var sn=0;
          childrows.map((item) => {
            
            
            if(typeof item.aggData != "undefined")
            {
              //console.log("adas ",item)
              category.push(item.key);
              serires1.push(item.aggData.transit_delay_days_0);
              
              serires2.push(item.aggData.transit_delay_days_1);
              
              serires3.push(item.aggData.transit_delay_days_2);

              activecategory.push(item.key);
              activeserires1.push(item.aggData.active_transit_delay_days_0);
              
              activeserires2.push(item.aggData.active_transit_delay_days_1);
              
              activeserires3.push(item.aggData.active_transit_delay_days_2);
            }
            else{
              if(item.parent.expanded == true)
              {
                if(sn == 0)
                {
                 // console.log("adas ",item)
                  
                  category.push(item.parent.key);
                  serires1.push(item.parent.aggData.transit_delay_days_0);
                  
                  serires2.push(item.parent.aggData.transit_delay_days_1);
                  
                  serires3.push(item.parent.aggData.transit_delay_days_2);
    
                  activecategory.push(item.parent.key);
                  activeserires1.push(item.parent.aggData.active_transit_delay_days_0);
                  
                  activeserires2.push(item.parent.aggData.active_transit_delay_days_1);
                  
                  activeserires3.push(item.parent.aggData.active_transit_delay_days_2);
                }
              }
              else
              {
                sflag=1
                // category.push(item.parent.parent.key);
                // serires1.push(item.parent.parent.aggData.transit_delay_days_0);
                
                // serires2.push(item.parent.parent.aggData.transit_delay_days_1);
                
                // serires3.push(item.parent.parent.aggData.transit_delay_days_2);
  
                // activecategory.push(item.parent.parent.key);
                // activeserires1.push(item.parent.parent.aggData.active_transit_delay_days_0);
                
                // activeserires2.push(item.parent.parent.aggData.active_transit_delay_days_1);
                
                // activeserires3.push(item.parent.parent.aggData.active_transit_delay_days_2);

              }
              
            
            }
            sn=sn+1;
          })  
        }
        //console.log("sflag=",sflag)
        if(sflag == 1)
        {
          var subchildrows = await event.node.parent.childrenAfterGroup;
          if(subchildrows.length > 0)
          {
            //console.log("subchildrows ",subchildrows)
            await subchildrows.map((item) => {
              category.push(item.key);
              serires1.push(item.aggData.transit_delay_days_0);
              
              serires2.push(item.aggData.transit_delay_days_1);
              
              serires3.push(item.aggData.transit_delay_days_2);

              activecategory.push(item.key);
              activeserires1.push(item.aggData.active_transit_delay_days_0);
              
              activeserires2.push(item.aggData.active_transit_delay_days_1);
              
              activeserires3.push(item.aggData.active_transit_delay_days_2);
            });
          }
        }
        await this.setState({
          serires:[{
            "name":"On Time",
            data:serires1
          },{
            "name":"1 Day",
            data:serires2
          },{
            "name":">1 Day",
            data:serires2
          }],
          categories:category,
          
          activeserires:[{
            "name":"On Time",
            data:activeserires1
          },{
            "name":"1 Day",
            data:activeserires2
          },{
            "name":">1 Day",
            data:activeserires3
          }],
          activecategories:category,
          leftSubTitle:event.node.key,
          activeleftSubTitle:event.node.key,
        });
    }
    

  }

  onFilterTextBoxChanged(eValue) {
    console.log("eValue ",eValue.target.value)
    this.onSearchValue()
    //this.gridApi.setQuickFilter(eValue);
  }

  onSearchValue() {
    //console.log("eValue ",eValue)
    //this.gridApi.setQuickFilter(eValue);
  }

  onShowLegs = async(params) =>{
    console.log("cell ",params);
    var txt='';
    var htxt = '';
    if(params.colDef.field == 'transit_delay_days_0' || params.colDef.field == 'transit_delay_days_1'
  || params.colDef.field == 'transit_delay_days_2')
    {
      htxt="For selected period ";
    }

    if(params.colDef.field == 'active_transit_delay_days_0' || params.colDef.field == 'active_transit_delay_days_0'
  || params.colDef.field == 'active_transit_delay_days_0')
    {
      htxt="Cummulative as on date ";
    }

    if(params.node.parent.id != "ROOT_NODE_ID")
    {
      txt=htxt+" ("+params.node.parent.key+" - "+params.node.key+" - "+params.colDef.headerName+")"
    }
    else{
      txt=htxt+" ("+params.node.key+" - "+params.colDef.headerName+")"
    }
    var extraarr = [];


    if(params.colDef.field == 'set')
    {
      if(params.node.allLeafChildren.length > 0)
      {
        await params.node.allLeafChildren.map((itemdata) => {
            var item = itemdata.data;
            extraarr.push(item.extra_details)
            
          })
      }
   
    }


    if(params.colDef.field == 'status')
    {
      if(params.node.allLeafChildren.length > 0)
      {
        await params.node.allLeafChildren.map((itemdata) => {
            var item = itemdata.data;
            extraarr.push(item.extra_details)
            
          })
      }
   
    }


    if(params.colDef.field == 'activeset')
    {
      if(params.node.allLeafChildren.length > 0)
      {
        await params.node.allLeafChildren.map((itemdata) => {
            var item = itemdata.data;
            extraarr.push(item.extra_details)
            
          })
      }
   
    }

    if(params.colDef.field == 'transit_delay_days_0')
    {
      if(params.node.allLeafChildren.length > 0)
      {
        await params.node.allLeafChildren.map((itemdata) => {
          var item = itemdata.data;
          //console.log("S3",item)
          var tt = item.extra_details+" "+item.set+"" +item.status;
          //console.log("S4 extra_details", tt)
            if(parseInt(item.transit_delay_days_0) == 1)
            {
              extraarr.push(item.extra_details)
            }
          })
      }
      
    }

    if(params.colDef.field == 'transit_delay_days_1')
    {
      
      if(params.node.allLeafChildren.length > 0)
      {
        await params.node.allLeafChildren.map((itemdata) => {
          var item = itemdata.data;
              if(parseInt(item.transit_delay_days_1) == 1){
                 extraarr.push(item.extra_details)
              }
    
          })
      }
   
    }

    if(params.colDef.field == 'transit_delay_days_2')
    {
      
      if(params.node.allLeafChildren.length > 0)
      {
        await params.node.allLeafChildren.map((itemdata) => {
          var item = itemdata.data;
              if(parseInt(item.transit_delay_days_2) == 2)
              {
                 extraarr.push(item.extra_details)
              }
          })
      }
   
    }


    if(params.colDef.field == 'active_transit_delay_days_0')
    {
      if(params.node.allLeafChildren.length > 0)
      {
        await params.node.allLeafChildren.map((itemdata) => {
          var item = itemdata.data;
         // console.log("S3",item)
          var tt = item.extra_details+" "+item.set+"" +item.status;
          //console.log("S4 extra_details", tt)
          if(parseInt(item.active_transit_delay_days_0) == 1)
          {
            extraarr.push(item.extra_details)
          }
          })
      }
   
    }
      

    if(params.colDef.field == 'active_transit_delay_days_1')
    {
      if(params.node.allLeafChildren.length > 0)
      {
        await params.node.allLeafChildren.map((itemdata) => {
          var item = itemdata.data;
          //console.log("S3",item)
          var tt = item.extra_details+" "+item.set+"" +item.status;
          //console.log("S4 extra_details", tt)
            if(parseInt(item.active_transit_delay_days_1) == 1)
            {
              extraarr.push(item.extra_details)
            }
          })
      }
   
    }

    if(params.colDef.field == 'active_transit_delay_days_2')
    {
      if(params.node.allLeafChildren.length > 0)
      {
        await params.node.allLeafChildren.map((itemdata) => {
          var item = itemdata.data;
          //console.log("S3",item)
          var tt = item.extra_details+" "+item.set+"" +item.status;
          //console.log("S4 extra_details", tt)
            if(parseInt(item.active_transit_delay_days_2) == 1)
            {
              extraarr.push(item.extra_details)
            }
          })
      }
      
    }
   // console.log("extraarr ",extraarr)
    var legarr = [];
    extraarr.map((item) => {
      //console.log("arr ",item)
      var oData = JSON.parse(item);
      oData.map((iData) =>{
        //console.log("iData " ,iData)
        legarr.push(iData)
      })
      //
    })
      var legcolumns = [
        { 
          headerName: "Truck No",
          field: "truck_no", 
          width:150,
          resizable: true,
        },
        { 
          headerName: "Consignment Code",
          field: "consignment_code", 
          width:150,
          resizable: true,
        },
        { 
          headerName: "Consignee Code",
          field: "consignee_code", 
          width:150,
          resizable: true,
        },
        { 
          headerName: "Consigner Code",
          field: "consigner_code", 
          width:150,
          resizable: true
        },
        { 
          headerName: "Original ETA",
          field: "expected_trip_end", 
          width:150,
          resizable: true,
          valueGetter:function(params){
            if(params.data.expected_trip_end != '')
            {
              return getDDMMYYYYHHMMSS(params.data.expected_trip_end);
            }
          }
        },
        { 
          headerName: "Revised ETA",
          field: "revised_trip_end", 
          width:150,
          resizable: true,
          valueGetter:function(params){
            if(params.data.revised_trip_end != '')
            {
              return getDDMMYYYYHHMMSS(params.data.revised_trip_end);
            }
          }
        },
        { 
          headerName: "Invoice Time",
          field: "invoice_time", 
          width:150,
          resizable: true,
          valueGetter:function(params){
            if(params.data.invoice_time != '')
            {
              return getDDMMYYYYHHMMSS(params.data.invoice_time);
            }
          }
        },
        // { 
        //   headerName: "Transit Time",
        //   field: "transit_time", 
        //   width:150,
        //   resizable: true,

        // },
        // { 
        //   headerName: "Transit Delay",
        //   field: "transit_delay", 
        //   width:150,
        //   resizable: true,
        // },
        { 
          headerName: "Gate In Time",
          field: "gate_in_time", 
          width:150,
          resizable: true,
          valueGetter:function(params){
            if(params.data.gate_in_time != '')
            {
              return getDDMMYYYYHHMMSS(params.data.gate_in_time);
            }
          }
        },
        { 
          headerName: "Gate Out Time",
          field: "gate_out_time", 
          width:150,
          resizable: true,
          valueGetter:function(params){
            if(params.data.gate_out_time != '')
            {
              return getDDMMYYYYHHMMSS(params.data.gate_out_time);
            }
          }
        },
        { 
          headerName: "Cluster",
          field: "cluster", 
          width:150,
          resizable: true
        }

      ]

      if(legarr.length > 0)
      {
        var legsrows = legarr
      }
      else{
        var legsrows = null
      }
     // console.log("legsrows ", legsrows)
        this.setState({
          sliderRouteTranslate:'slider-translate-75p',
          loadshow:'show-n',
          overly:'show-m',
          legcolumns:legcolumns,
          legrows:legsrows,
          sidebartitle:txt
        });
   
  }
  onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            sliderRouteTranslate:'',
            sliderTicketTranslate:'',
			tabsliderTranslate:'',
			inboxSlider:''
		});
		
	}
  onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
    }

  render() {
    //console.log("rowData ",this.state.rowData)
    var startdate = this.state.startDate;
    var enddate = this.state.endDate;
    let { startDate } = this.state;
    var consignmentgenerate = "Consignment Generated ( "+startDate+")";
    var columnDefs = [
      {
          headerClass:["cellTopHeaderTemplate"],
          headerName:"Department",	
          field: 'dept_name',
          rowGroup: true,
          width:300,
          cellClass:"cellTopHeaderTemplate16",
          //pivot:true,
          hide:true,
          chartDataType: 'category'
        },
        {
          field: 'consigner_code',
          rowGroup: true,
          cellClass:"cellTopHeaderTemplate14",
          hide:true,
          chartDataType: 'category',
        },
        {
          field: 'invoice_date',
          //rowGroup: true,
          cellClass:"cellTopHeaderTemplate14",
          hide:true,
          chartDataType: 'category',
        },
        {
          field: 'consignment_code',
          //rowGroup: true,
          cellClass:"cellTopHeaderTemplate14",
          hide:true,
          chartDataType: 'category',
        },
        {
          headerName: 'UOM',
          field: 'uom',
          width:120,
          cellClass:["cellTopHeaderTemplatenb"],
          chartDataType: 'excluded',
          valueGetter:function(params){
           // console.log(params.node.key);
            // if(typeof params.data == 'undefined')
            // {
            //   return "Trucks";
            // }
            // else{
              if(params.node.key == "Sales & Dispatch")
              {
                return "Trucks";
              }
              else if(params.node.key == "Spare Parts (Domestic)")
              {
                return "Consignments";
              }
              else if(params.node.key == "Spare Parts (Courier)")
              {
                return "Consignments";
              }
              else if(params.node.key == "Spare Parts (Container Exports)" || params.node.key == "Spares (Container Exports)")
              {
                return "Containers";
              }
              else if(params.node.key == "Production Parts")
              {
                return "Trucks";
              }
              else if(params.node.key == "Container Imports")
              {
                return "Containers";
              }
              else if(params.node.key == "Container Exports (KDO)")
              {
                return "Containers";
              }
              else if(params.node.key == "Coil Imports")
              {
                return "Coils";
              }
            // }
            
          }
          //hide:true
        },
        
        {
          headerName: "For This Period",
          headerClass:["cellTopHeaderTemplateH","cellstylegrid"],			
          children: [
                      {
                        headerName: "Total for selected period",
                        headerClass:["cellstylegrid"],	
                        field: "set",
                        cellClass:["cellTopHeaderTemplate16","cellstylegrid","cpointer"],
                       // chartDataType: 'series',
                        width:110,
                        chartDataType: 'excluded',
                        //hide:true
                      },
                      {
                        headerName: "Active for selected period",
                        headerClass:["cellstylegrid"],	
                        field: "status",
                        width:110,
                        chartDataType: 'excluded',
                        cellClass:["cellTopHeaderTemplate16","cellstylegrid","cpointer"],
                        //chartDataType: 'series',
                        //hide:true
                      },
                    ]
            },
            {
              headerName: "Delay Meter",
              headerClass:["cellTopHeaderTemplateH","cellstylegrid"],			
              children: [
                      {
                        headerClass:["cellTopHeaderTemplate14","cellstylegrid"],
                        headerName:"On Time",	
                        field: 'transit_delay_days_0',
                        width:90,
                        cellClass:["cellTopHeaderTemplate16","bgColorSuccessLight","cellstylegrid","cpointer"],
                        chartDataType: 'series',
                      },
                      {
                        headerClass:["cellTopHeaderTemplate14","cellstylegrid"],
                        headerName:"1 Day",	
                        field: 'transit_delay_days_1',
                        width:80,
                        cellClass:["cellTopHeaderTemplate16","cellstylegridDY","cellstylegrid","cpointer"],
                        chartDataType: 'series',
                      },
                      {
                        headerClass:["cellTopHeaderTemplate14","cellstylegrid","cpointer"],
                        headerName:">1 Day",
                        field: 'transit_delay_days_2',
                        width:80,
                        cellClass:["cellTopHeaderTemplate16","bgColorDangerMedium","cellstylegrid","cpointer"],
                        chartDataType: 'series',
                      }
            ]
        },
        

        {
          headerName: "Cummulative As On Date",
          headerClass:["cellTopHeaderTemplateH","cellstylegridB"],			
          children: [

                      {
                        headerClass:["cellstylegridB"],
                        headerName: "Active as on date (Cummulative)",
                        field: "activeset",
                        width:140,
                        cellClass:["cellTopHeaderTemplate16","cellstylegridB","cpointer"],
                        chartDataType: 'category',
                        //hide:true
                      },

                      {
                        headerClass:["cellTopHeaderTemplate14","cellstylegridB"],
                        headerName:"On Time",	
                        field: 'active_transit_delay_days_0',
                        width:90,
                        cellClass:["cellTopHeaderTemplate16","bgColorSuccessLight","cellstylegridB","cpointer"],
                        chartDataType: 'series',
                      },
                      {
                        headerClass:["cellTopHeaderTemplate14","cellstylegridB"],
                        headerName:"1 Day",	
                        field: 'active_transit_delay_days_1',
                        width:80,
                        cellClass:["cellTopHeaderTemplate16","cellstylegridDY","cellstylegridB","cpointer"],
                        chartDataType: 'series',
                      },
                      {
                        headerClass:["cellTopHeaderTemplate14","cellstylegridB"],
                        headerName:">1 Day",
                        field: 'active_transit_delay_days_2',
                        width:80,
                        cellClass:["cellTopHeaderTemplate16","bgColorDangerMedium","cellstylegridB","cpointer"],
                        chartDataType: 'series',
                      }
            ],
            
        },
        // {
        //   headerClass:["cellTopHeaderTemplate"],
        //   headerName:"order",
        //   width:150,
        //   field: 'delivery_order',
        //   sort:"asc",
        //   sortable:true,
        //   //hide:true
        // },
    ];
    return (
      <div style={{ width: '100%', height: '100%' }}>
       <div className="row col-xl-12 col-lg-12">
          
						<div className="col-xl-2 col-lg-2 form-group">
							<label>Select Date Period:<br />From Date</label>
							<Datetime value={this.state.startDate} disableCloseOnClickOutside={false} closeOnSelect={true} inputProps={{ placeholder: 'From', name: 'startDate', autoComplete:'off' }} dateFormat="YYYY-MM-DD"  name="startDate" onChange={this.handlerStartDateTime.bind(this)} />
						</div>

						<div className="col-xl-2 col-lg-2 form-group">
							<label><br />To Date</label>
							<Datetime value={this.state.endDate} disableCloseOnClickOutside={false} closeOnSelect={true} inputProps={{ placeholder: 'To', name: 'endDate', autoComplete:'off'}} dateFormat="YYYY-MM-DD" onChange={this.handlerEndDateTime.bind(this)} />
						</div>
						<div className="form-group col-xl-2 col-lg-2">
							<label><br />&nbsp;</label><br />
							<button type="button" className="btn btn-success" onClick={this.onClickSummaryData.bind(this)}>Submit</button>
						</div>
						
{/* 
						<div className="form-group col-xl-2 col-lg-2">
              <input type="text" name="searchkey" onChange={this.onFilterTextBoxChanged.bind(this)} value={this.state.searchkey} />
            </div> */}
				</div>

        <div
          id="myGrid"
          style={{
            height: '550px',
            width: '100%'
          }}
          className="ag-theme-balham"
        >
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            defaultColDef={this.state.defaultColDef}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            sideBar={false}
            groupUseEntireRow={false}
            enableRangeSelection={true}
            groupRowAggNodes={this.groupRowAggNodes}
            onGridReady={this.onGridReady}
            onGridState={this.onGridState}
            rowData={this.state.rowData}
            groupHeaderHeight={this.state.groupHeaderHeight}
            headerHeight={this.state.headerHeight}
            floatingFiltersHeight={this.state.floatingFiltersHeight}
            pivotGroupHeaderHeight={this.state.pivotGroupHeaderHeight}
            pivotHeaderHeight={this.state.pivotHeaderHeight}
            rowHeight={this.state.rowHeight}
            frameworkComponents={this.state.frameworkComponents}
            popupParent={this.state.popupParent}
            enableRangeSelection={true}
            enableCharts={true}
            processChartOptions={this.state.processChartOptions}
            onFirstDataRendered={this.onFirstDataRendered.bind(this)}
            getChartToolbarItems={this.getChartToolbarItems}
            suppressChartRanges={true}
            //defaultGroupSortComparator={this.state.defaultGroupSortComparator}
            onRowGroupOpened={this.onRowGroupOpened.bind(this)}
            //suppressCount={true}
            onCellClicked={this.onShowLegs.bind(this)}
            detailCellRendererParams={this.state.detailCellRendererParams}
            masterDetail={true}
          />
           {/*<div id="chart" style={{ float: 'left', overflow: 'hidden', width:"50%",height: '500px' }}></div>
          <div id="chartactive" style={{ float: 'left', overflow: 'hidden',width:"50%", height: '500px' }}></div>
           <div id="chart2" style={{ flex: '1 1 auto', overflow: 'hidden', width: '50%' }}></div>
          <div id="chart3" style={{ flex: '1 1 auto', overflow: 'hidden', width: '50%' }}></div> */}
            
        </div>
      <div className="row mt-20p"> 
        <div className="col-xl-6 col-lg-6"> 
              <ColumnGroup 
              series={this.state.serires}
              categories={this.state.categories}
              title={this.state.leftTitle}
              subtitle={this.state.leftSubTitle}
            />
          </div>
          <div className="col-xl-6 col-lg-6"> 
              <ColumnGroup 
              series={this.state.activeserires}
              categories={this.state.activecategories}
              title={this.state.activeleftTitle}
              subtitle={this.state.activeleftSubTitle}
            />
          </div>
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}></div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
			
          
        <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
							{this.state.sidebartitle}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                
                <div
                id="myGrid"
                style={{
                  height: '600px',
                  width: '100%'
                }}
                className="ag-theme-balham"
              >
              
                <AgGridReact
                  modules={this.state.modules}
                  columnDefs={this.state.legcolumns}
                  defaultColDef={this.state.defaultColDef}
                  rowData={this.state.legrows}
                  paginationPageSize={this.state.paginationPageSize}
									pagination={true}
                />
              
              </div>

              </div>
            </div>
        </div>
      </div>
    );
  }
}

function strcmp(a, b) {
  return a < b ? -1 : a > b ? 1 : 0;
}
function yAxisLabelFormatter(params) {
    var n = params.value;
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
}
// function BarChartLineRenderer() {
// }

// BarChartLineRenderer.prototype.init = function(params) {
//     var eGui = document.createElement('div');
//     this.eGui = eGui;

//     // sparklines requires the eGui to be in the dom - so we put into a timeout to allow
//     // the grid to complete it's job of placing the cell into the browser.
//     setTimeout(function() {
//       console.log(params.value);
//         var values = params.value
//             //.sort(function(a, b) { return a.transit_delay_days_0 - b.transit_delay_days_2; })
//             .map(function(datum) { return datum.AverageVolume.toFixed(); });
//         $(eGui).sparkline(values, {
//             type: 'bar',
//             barColor: 'green',
//             chartRangeMin: 1000000,
//             barWidth: 11,
//             height: CELL_DIMENSION_SIZE,
//             width: CELL_DIMENSION_SIZE
//         });
//     }, 0);
// };

// BarChartLineRenderer.prototype.getGui = function() {
//     return this.eGui;
// };
import React, {Component} from "react";
import ReactDom,{ render } from 'react-dom';
import axios from 'axios';
import { Redirect } from 'react-router';
import TMenus from './topmenus';

//import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

var images = require.context('../../assets/images/', true);

export default class Accounts extends Component {

    constructor(props) {
        super(props);
        var refur='';
        
        this.state = {
            style: {},
            email:'',
            password:'',
             loginMessage:'',
             scope:'',
             client_id:'',
             grant_type:'',
             client_secret:'',
             redirect_uri:'',
             code:'',
             userdetails:[],
             erromsg:''
        };
    }

    componentDidMount() {
        setTimeout(function () {
            this.setState({style: {display:'none'}});
        }.bind(this),1000)
       // console.log("Access",localStorage.getItem('zohoToken'));
       // console.log("Refresh Access",localStorage.getItem('zohoRefreshtoken'));
        var params = {
        		token:localStorage.getItem('zohoToken')
        }
        axios.post('http://autometrics.in/oauth2/myaccount',params,{
            headers:{
                'authorization': 'multipart/form-data',
                // 'Access-Control-Allow-Origin':"*",
                // 'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE, TOKEN',
                'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
               // 'authorization': 'Zoho-oauthtoken 1000.ecb6dbd17a44125ce5434f5f45148278.a54f59a27d9e9db5383f85ba3002ccf2' 
            }
        })
          .then( response => {
             // console.log(response);
        	  if(response == 'INVALID_OAUTHTOKEN')
    		  {
        		  
        		  this.setState({
        			  erromsg:"Token has expired, Please use Refresh Token option"
                  });
    		  }
              this.setState({
            	  userdetails:response.data.data.data[0]
              });
            //console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
    }
    
    changeHandler = (event) => {

        let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
    
 formHandlertest = (event) => {
        
        event.preventDefault();
        var formdata = new FormData(event.target);
        const fdata = {
                scope: this.state.scope,
                client_id: this.state.client_id,
                grant_type: this.state.grant_type,
                client_secret: this.state.client_secret,
                redirect_uri: this.state.redirect_uri,
                code: this.state.code
        }
         var params= {
                scope: "ZohoMail.accounts.READ,ZohoMail.folders.READ,ZohoMail.messages.READ,ZohoMail.Attachments.READ",
                client_id: "1000.AW1CCGPZCR59529ULX7MMDHBWGEADH",
                grant_type: "authorization_code",
                client_secret: "7e99ec16ec94a219297cfc6e495e8e70303dfedd92",
                redirect_uri: "http://autometrics.in/oauth2/callback",
                code: "1000.fc853997af229287ed7a7ab90587d7e5.cbef52268123697930af27d583881dda"   
           };
        axios.post('http://autometrics.in/oauth2/token',params,{
            headers:{
                'Content-Type': 'multipart/form-data',
                // 'Access-Control-Allow-Origin':"*",
                // 'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE, TOKEN',
                'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
               // 'authorization': 'Zoho-oauthtoken 1000.ecb6dbd17a44125ce5434f5f45148278.a54f59a27d9e9db5383f85ba3002ccf2' 
            }
        })
          .then( response => {
              //console.log(response);
              
            //console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
        
    }
    render() {
        let style = this.state.style;
        const background = require('../../assets/images/auth-layer.png');
       console.log("Details",this.state.userdetails.emailAddress)
       var emailID = '';
       for(var e in this.state.userdetails.emailAddress)
	   {
    	   console.log("Email", this.state.userdetails.emailAddress[e])
    	   emailID=this.state.userdetails.emailAddress[e].mailId;
	   }
       const accountID = this.state.userdetails.accountId;
       const accountName = this.state.userdetails.accountName;
       
       
        return (
            <div>
                {/* Loader starts */}
                <div className="loader-wrapper" style={style}>
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Have a great day at work today <span>&#x263A;</span></h4>
                    </div>
                </div>
                {/* Loader ends */}

                {/*page-wrapper Start*/}
                <div className="page-wrapper">
                    <div className="container-fluid">
                        {/*login page start*/}
                        <div className="authentication-main" >
                            <div className="row">
                                <div className="col-md-4 p-0">
                                    <div className="auth-innerleft" style ={ { backgroundImage: "url("+background+")" } }>
                                        <div className="text-center">
                                            <img src={require('../../assets/icons/logo.png')}
                                                    className="logo-login" style={{width:'75%'}} alt=""/>
                                               <hr/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 p-0">
                                    <div className="auth-innerright">
                                        <div className="authentication-box">
                                        	<TMenus />
                                            <h4>My Account</h4>
                                            
                                            <div className="card mt-4 p-4 mb-0">
                                            {this.state.erromsg}
                                            	<table>
                                            		<tr>
                                            			<th>Name</th>
                                            			<th>Email</th>
                                            			<th>View Messages</th>
                                            		</tr>
                                            		<tr>
                                            			<td>{accountName}</td>
                                            			<td>{emailID}</td>
                                            			<td>
                                            				<a href={"/zoho/folders/"+accountID} className="label label-success">View Folders</a>
                                            			</td>
                                            		</tr>
                                            	</table>
                                            	
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*login page end*/}
                    </div>
                </div>
                {/*page-wrapper Ends*/}

            </div>

        )
    }
}




/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import CSVFileValidator from 'csv-file-validator';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import TrainingsGrid from "../transporter/transportertrainingsgrid";
import FleetGrid from "../transporter/transporterfleetgrid";
import TransitGrid from "../transporter/transportertransitgrid";
import CarrierGrid from "../transporter/transportercarriergrid";
import SecondaryGrid from "../transporter/transportersecondarygrid";
import StockGrid from "../transporter/transporterstockgrid";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenDDMMYYYY } from '../common/utils'; 
import { first } from 'lodash';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var CELL_DIMENSION_SIZE = 90;
var googleAnalytics = require("../common/googleanalytics");
const fy_cols = ['FY 20-21', "Apr'21", "May'21", "Jun'21", "Jul'21", "Aug'21", "Sep'21", "Oct'21", "Nov'21", "Dec'21", "Jan'22", "Feb'22", "Mar'22", "FY 21-22"]

export default class RailLspPerformanceCard extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			pageTitle: "Transporter Performance Dashboard",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				// editable: true,
				resizable: true
			},
            defaultColDefNew: {
				sortable: true,
				filter: true,
				editable: true,
				resizable: true
			},
            defaultColDefInvoice: {
				sortable: true,
				filter: true,
				editable: true,
				resizable: true
			},
			rowData: [],
			originalrowData: [],
			selectedTabLat:'',
			selectedTabLng:'',
			cluster_centroids: [],
			deftab:'all',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			frameworkComponents : {
            },
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            last_approval_date: "",
			showPlant:'show-n',
			showAddr:'show-n',
            request_type:'',
            gridHeaderName: "",
			view_type:'',
			tabplants:[],
			clickabletab:'',
			plantlist:[],
			stateFormData : "",
			sliderStateEmailsTranslate : "",
			overly : "show-n",
            rakeslist : [],
            loadingpoints:[],
            destinationpoints:[],
            userType:"",
            pinnedRegionBottomRowData:[],
            pinnedTransitBottomRowData:[],
            stockData:[],
            frgData:[],
            totalStock:0,
            totalSales:0,
            totalDispatches:0,
            railShare:0,
            recordsLength:1,
            sliderbulkupload : "",
            csvcontent: "",
            rowClassRules : {
                "colorForDangerLight" : function(params){
                    if(params.data.parameters == "growth")
                    {
                        return true;
                    }
                    else{
                        return false;
                    }
                } 
            },
            screenView: "",
            input_funds_received: "",
            input_funds_available: "",
            transportersList: [],
            fiscalyear: "",
            financialyears:"",
            fascalyears:"",
            columnwithDefs:[],
            transporterId: {"label": "APL LOGISTICS", "value": "T275"},
            fYear:{
                "label":"2023-2024",
                "value":"2023-2024",
            },
            transporterInfo:[],
            truckInfo:[],
            nonrepofleet:[],
            trainingsData:[],
            idtrData:[],
            fleetData:[],
            damageData:[],
            carrierData:[],
            ontimeData:[],
            transitData:[],
            pendingData:[],
            paymentData:[],
            srminfo:[],
            fleetDeployedRowData:[],
            fleetDeployedCols: [],
            monthlyInvoicedRowData: [],
            monthlyVolInvoiceRowData: [],
            monthlyVolAllRakesRowData: [],
            monthlyPlantExitForGGNRowData: [],
            monthlyPlantExitForMSRRowData:[],
            monthlyPlantExitForGUJRowData: [],
            lastInvoicingGGNRowData: [],
            lastInvoicingGUJRowData: [],
            lastMileRowData: [],
            monthlyInvoiceData:[],
            rakewisevolumedata:[],
            volumeColumnDefs:[],
            rakewisevolumedatanmg:[],
            rakeColumnDefs:[],
            monthwiseggndata:[],
            ggnColumnDefs:[],
            monthwisemnsrdata:[],
            mnsrColumnDefs:[],
            monthwisegujaratdata:[],
            monthwisebangloredata:[],
            gujaratColumnDefs:[],
            bangloreColumnDefs:[],
            lastinvoiceggndata:[],
            ggnmsnrColumnDefs:[],
            lastinvoicegujaratdata:[],
            lastinvoicebengalurudata:[],
            gujColumnDefs:[],
            bnrColumnDefs:[],
            lastmileperformancedata:[],
            lastMileColumnDefs:[],
            transporterOptions:[{value:"T025",label:"ORIENTAL CARRIERS PVT.LTD"},
            {value:"T275",label:"APL LOGISTICS"},{value:"T305",label:"IVC LOGISTICS LIMITED"},{value:"T314",label:"JOSHI KONOIKE TPT. & INFRA. PVT LTD"},{value:"T324",label:"NYK ADANI"}],
            approvalStatus:0,
        };
        this.createColumns = this.createColumns.bind(this);
    }
    
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
        window.location.reload();
    }

	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount(){
        loadDateTimeScript();
        this.logPageView(); // Log page view to GA

        var fiscalyear = "";
        var today = new Date();
        if ((today.getMonth() + 1) <= 3) {
            fiscalyear = (today.getFullYear() - 1) + "-" + today.getFullYear()
        } else {
            fiscalyear = today.getFullYear() + "-" + (today.getFullYear() + 1)
        }
        redirectURL.post("/consignments/getRailLspFY").then((response)=>{
            var financialyears=[];
            response.data.map((e)=>{
                financialyears.push({label: e, value: e})
            });
            this.setState({
                financialyears: financialyears
            })
        })
        this.onPageLoadData();
        this.getApprovalStatus();
        // redirectURL.post("/consignments/getAllTransporters").then((response)=>{
        //     var transporters = response.data.transporters; 
        //     var financialyears = response.data.financialyears; 
        //     var tlist = [];
        //     transporters.map(function(t){
        //         // var transporterCode = eval(t.transporter_code);
        //         // if(transporterCode !=undefined && transporterCode !='')
        //         // {
        //             tlist.push({
        //                 "value":t.transporter_code,
        //                 "label":t.transporter_name,
        //             });
        //         // }
        //     });
        //     var flist = [];
        //     financialyears.map(function(f){
        //         var year = f;
        //         if(year !=undefined && year !='' && year !="2018-2019")
        //         {
        //             flist.push({
        //                 "value":year,
        //                 "label":year,
        //             });
        //         }
        //     });
        //     // flist.push({
        //     //     "value":"2020-2021",
        //     //     "label":"2020-2021",
        //     // });
        //     // flist.push({
        //     //     "value":fiscalyear,
        //     //     "label":fiscalyear,
        //     // });
        //     this.setState({
        //         transportersList : tlist,
        //         financialyears : flist
        //     });
        // })
        // .catch(function(e){
        //     console.log("Error ",e)
        // }) 

        // var yrSpt = fiscalyear.split("-"); 
        // var from = "Apr "+yrSpt[0];
        // var to = "Mar "+yrSpt[1];
        // this.getMonths(from,to);
        // this.onClickTransporter();
        
    }
    
    onPageLoadData(){
        this.setState({
            overly: "show-m",
            loadshow: "show-m"
        })
        this.onCreateFleetRowData();
        this.onCreateMonthlyInvoicedRowData();
        // this.onCreateMonthlyVolInvoiceRowData();
        // this.onCreateMonthlyVolAllRakesRowData();
        // this.onCreateMonthlyPlantExitForGGNRowData();
        // this.onCreateMonthlyPlantExitForMSRRowData();
        // this.onCreateMonthlyPlantExitForGUJRowData();
        // this.onCreateLastInvoicingGGNRowData();
        // this.onCreateLastInvoicingGUJRowData();
        // this.onCreateLastMileRowData();
        this.onCreateRakeWiseVolumeData();
        this.onCreateMonthWisePlanExitsData();
        this.onCreateLastInvoiceRakesData();
        this.onCreateLastMilePerformanceData();
    }

    onCreateFleetRowData(){
        // var fleetDeployedRowData = [
        //     {
        //         "lsp": "APL",
        //         "ggn_msr": "78",
        //         "smg": "20",
        //         "kolkata": "11",
        //         "guwahati": "50",
        //         "indore": "10",
        //         "sanand": "20",
        //         "nagpur": "10",
        //         "chennai": "20",
        //         "irugur": "10"
        //     }
        // ];
        redirectURL.post("/consignments/getRailLspCardData", {"transporter_code": this.state.transporterId.value}).then((resp)=>{
            this.setState({
                fleetDeployedRowData: resp.data
            })
        })
        
    }

    onCreateMonthlyInvoicedRowData(){
        // var monthlyInvoicedRowData = [
        //     {
        //         "month": "Apr'21",
        //         "invoiced": 10417,
        //         "damages": 79,
        //         "damage_percent": "0.76%"
        //        },
        //        {
        //         "month": "May'21",
        //         "invoiced": 1365,
        //         "damages": 10,
        //         "damage_percent": "0.73%"
        //        },
        //        {
        //         "month": "Jun'21",
        //         "invoiced": 7196,
        //         "damages": 65,
        //         "damage_percent": "0.90%"
        //        },
        //        {
        //         "month": "Jul'21",
        //         "invoiced": 9976,
        //         "damages": 74,
        //         "damage_percent": "0.74%"
        //        },
        //        {
        //         "month": "Aug'21",
        //         "invoiced": 8835,
        //         "damages": 35,
        //         "damage_percent": "0.40%"
        //        },
        //        {
        //         "month": "Sep'21",
        //         "invoiced": 3318,
        //         "damages": 32,
        //         "damage_percent": "0.96%"
        //        },
        //        {
        //         "month": "Oct'21",
        //         "invoiced": 6947,
        //         "damages": 40,
        //         "damage_percent": "0.58%"
        //        },
        //        {
        //         "month": "Nov'21",
        //         "invoiced": 10485,
        //         "damages": 67,
        //         "damage_percent": "0.64%"
        //        },
        //        {
        //         "month": "Dec'21",
        //         "invoiced": 6632,
        //         "damages": 59,
        //         "damage_percent": "0.89%"
        //        },
        //        {
        //         "month": "Total",
        //         "invoiced": 65171,
        //         "damages": 461,
        //         "damage_percent": "0.71%"
        //        }
        // ];
        // var monthlyInvoiceData =[]
        var params = {
            "transporterId":this.state.transporterId.value,
            "fy_year": this.state.fYear.value
        }
        redirectURL.post("/consignments/getmonthlyinvoicedata",params).then((response) => {
            var monthlyinvoicedata = response.data
            var totalInvoice = 0
            var totalDamage = 0
            var totalDamagePercent =0
            monthlyinvoicedata.map((e) => {
                totalInvoice = totalInvoice + parseInt(e.invoice_count)
                totalDamage = totalDamage + parseInt(e.damage)
            })
            if(totalInvoice != 0 && totalDamage != 0)
            {
                totalDamagePercent = ((parseInt(totalDamage) / totalInvoice)*100).toFixed(2)
                
            }
            console.log(totalDamagePercent,"totalDamagePercent")
            monthlyinvoicedata.push({month:"total",invoice_count:totalInvoice,damage:totalDamage,damage_percent:totalDamagePercent})
            console.log(monthlyinvoicedata,"monthlyInvoiceData")

            this.setState({
                monthlyInvoicedRowData:monthlyinvoicedata
            })
        })
        
    }
    onCreateRakeWiseVolumeData(){
        var params = {
            "transporterId":this.state.transporterId.value,
            "fy_year": this.state.fYear.value
        }
        console.log(params,"params")
        redirectURL.post("/consignments/getrakewisevolumedata",params).then((response) => {
            var rakewisevolumedata = JSON.parse(response.data.dedicated_rakes);
            console.log(response.data, "response");
            console.log(rakewisevolumedata,"rakewisevolumedata")
            var volumeColumnDefs = createColumns(rakewisevolumedata,"MONTH WISE VOLUME PERFORMANCE BCACBM");
            var rakewisevolumedatanmg = JSON.parse(response.data.non_dedicated_rakes)
            var rakeColumnDefs = createColumns(rakewisevolumedatanmg,"RAKE WISE MONTHLY VOLUME PERFORMANCE BCACBM & NMG");
            this.setState({
                rakewisevolumedata:rakewisevolumedata,
                volumeColumnDefs:volumeColumnDefs,
                rakewisevolumedatanmg:rakewisevolumedatanmg,
                rakeColumnDefs:rakeColumnDefs
            })
        })
        
    }
    onCreateMonthWisePlanExitsData(){
        var params = {
            "transporterId":this.state.transporterId.value,
            "fy_year": this.state.fYear.value
        }
        console.log(params,"params")
        redirectURL.post("/consignments/getmonthwiseplanexitdata",params).then((response) => {
            var monthwiseggndata = JSON.parse(response.data.gurgaon_exits);
            console.log(response.data, "response");
            console.log(monthwiseggndata,"monthwiseggndata")
            var ggnColumnDefs = createColumns(monthwiseggndata,"MONTH WISE PLANT EXIT PERFORMANCE FOR GGN PLANT");
            var monthwisemnsrdata = JSON.parse(response.data.manesar_exits)
            var mnsrColumnDefs = createColumns(monthwisemnsrdata,"MONTH WISE PLANT EXIT PERFORMANCE FOR MNSR PLANT");
            var monthwisegujaratdata = JSON.parse(response.data.gujarat_exits)
            var monthwisebangloredata = JSON.parse(response.data.bengaluru_exits)
            console.log(monthwisebangloredata,"monthwisebangloredata")
            var gujaratColumnDefs = createColumns(monthwisegujaratdata,"MONTH WISE PLANT EXIT PERFORMANCE FOR GUJARAT PLANT");
            var bangloreColumnDefs = createColumns(monthwisebangloredata,"MONTH WISE PLANT EXIT PERFORMANCE FOR BENGALURU PLANT");
            this.setState({
                monthwiseggndata:monthwiseggndata,
                ggnColumnDefs:ggnColumnDefs,
                monthwisemnsrdata:monthwisemnsrdata,
                mnsrColumnDefs:mnsrColumnDefs,
                monthwisegujaratdata:monthwisegujaratdata,
                gujaratColumnDefs:gujaratColumnDefs,
                bangloreColumnDefs:bangloreColumnDefs,
                monthwisebangloredata:monthwisebangloredata,
            })
        })
        
    }
    onCreateLastInvoiceRakesData(){
        var params = {
            "transporterId":this.state.transporterId.value,
            "fy_year": this.state.fYear.value
        }
        console.log(params,"params")
        redirectURL.post("/consignments/getalstinvoicingrakes",params).then((response) => {
            var lastinvoiceggndata = JSON.parse(response.data.gurgaon_exits);
            console.log(response.data, "response");
            console.log(lastinvoiceggndata,"lastinvoiceggndata")
            var ggnmsnrColumnDefs = createColumns(lastinvoiceggndata,"LAST INVOICING TO RAKE DEPARTURE PERFORMANCE FROM GGN/MNSR PLANT");
            var lastinvoicegujaratdata = JSON.parse(response.data.gujarat_exits)
            var lastinvoicebengalurudata = JSON.parse(response.data.bangalore_exits)
            var gujColumnDefs = createColumns(lastinvoicegujaratdata,"LAST INVOICING TO RAKE DEPARTURE PERFORMANCE FROM GUJ PLANT")
            var bnrColumnDefs = createColumns(lastinvoicebengalurudata,"LAST INVOICING TO RAKE DEPARTURE PERFORMANCE FROM BENGALURU PLANT")
            this.setState({
                lastinvoiceggndata:lastinvoiceggndata,
                ggnmsnrColumnDefs:ggnmsnrColumnDefs,
                lastinvoicegujaratdata:lastinvoicegujaratdata,
                gujColumnDefs:gujColumnDefs,
                bnrColumnDefs:bnrColumnDefs,
                lastinvoicebengalurudata:lastinvoicebengalurudata,
            })
        })
        
    }
    onCreateLastMilePerformanceData(){
        var params = {
            "transporterId":this.state.transporterId.value,
            "fy_year":  this.state.fYear.value
        }
        console.log(params,"params")
        redirectURL.post("/consignments/getlastmileperformancedata",params).then((response) => {
            try{
                var lastmileperformancedata = JSON.parse(response.data.last_mile_data);
                console.log(response.data, "response");
                console.log(lastmileperformancedata,"rakewisevolumedata")
                var lastMileColumnDefs = createColumns(lastmileperformancedata,"LAST MILE PERFORMANCE (IN DAYS)",);
                this.setState({
                    lastmileperformancedata:lastmileperformancedata,
                    lastMileColumnDefs:lastMileColumnDefs,
                    overly: "show-n",
                    loadshow: "show-n"
                })
            }catch(e){
                console.log("error",e)
            }
           
        })
        
    }
    // onCreateMonthlyVolAllRakesRowData(){
    //     var monthlyVolAllRakesRowData = [
    //         {
    //          "plant":"GURGAON / MANESAR",
    //          "parameter": "BCACBM",
    //          "FY 20-21": 65056,
    //          "Apr'21": 7862,
    //          "May'21": 501,
    //          "Jun'21": 3895,
    //          "Jul'21": 3458,
    //          "Aug'21": 5041,
    //          "Sep'21": 1463,
    //          "Oct'21": 4127,
    //          "Nov'21": 4671,
    //          "Dec'21": 2368,
    //          "Jan'22": 3855,
    //          "Feb'22": 5552,
    //          "Mar'22": 2614,
    //          "FY 21-22": 45407
    //         },
    //         {
    //         "plant":"GURGAON / MANESAR",
    //          "parameter": "NMG",
    //          "FY 20-21": 1476,
    //          "Apr'21": 125,
    //          "May'21": "",
    //          "Jun'21": 250,
    //          "Jul'21": 1495,
    //          "Aug'21": 872,
    //          "Sep'21": 1460,
    //          "Oct'21": 930,
    //          "Nov'21": 1536,
    //          "Dec'21": 982,
    //          "Jan'22": 1557,
    //          "Feb'22": 1382,
    //          "Mar'22": 1657,
    //          "FY 21-22": 12246
    //         },
    //         {
    //         "plant":"Gujarat",
    //          "parameter": "BCACBM",
    //          "FY 20-21": 24744,
    //          "Apr'21": 2430,
    //          "May'21": 864,
    //          "Jun'21": 3051,
    //          "Jul'21": 4898,
    //          "Aug'21": 2797,
    //          "Sep'21": 270,
    //          "Oct'21": 1890,
    //          "Nov'21": 4278,
    //          "Dec'21": 3282,
    //          "Jan'22": 4590,
    //          "Feb'22": 5940,
    //          "Mar'22": 4861,
    //          "FY 21-22": 39151
    //         },
    //         {
    //         "plant":"Gujarat",
    //          "parameter": "NMG",
    //          "FY 20-21": "",
    //          "Apr'21": "",
    //          "May'21": "",
    //          "Jun'21": "",
    //          "Jul'21": 125,
    //          "Aug'21": 125,
    //          "Sep'21": 125,
    //          "Oct'21": 0,
    //          "Nov'21": "",
    //          "Dec'21": "",
    //          "Jan'22": "",
    //          "Feb'22": "",
    //          "Mar'22": "",
    //          "FY 21-22": 375
    //         }
    //        ];
    //     this.setState({
    //         monthlyVolAllRakesRowData: monthlyVolAllRakesRowData
    //     })
    // }

    // onCreateMonthlyVolInvoiceRowData(){
    //     var monthlyVolInvoiceRowData = [
    //         {
    //         "plant": "Guragoan / Manesar",
    //         "parameter": "Volume",
    //          "FY 20-21": 66532,
    //          "Apr'21": 7862,
    //          "May'21": 501,
    //          "Jun'21": 3895,
    //          "Jul'21": 3458,
    //          "Aug'21": 5041,
    //          "Sep'21": 1463,
    //          "Oct'21": 4127,
    //          "Nov'21": 4671,
    //          "Dec'21": 2368,
    //          "Jan'22": 3855,
    //          "Feb'22": 5552,
    //          "Mar'22": 4271,
    //          "FY 21-22": 47064
    //         },
    //         {
    //         "plant": "Guragoan / Manesar",
    //         "parameter": "MoM Growth",
    //          "FY 20-21": "",
    //          "Apr'21": "",
    //          "May'21": "",
    //          "Jun'21": "",
    //          "Jul'21": "-11%",
    //          "Aug'21": "46%",
    //          "Sep'21": "-71%",
    //          "Oct'21": "182%",
    //          "Nov'21": "135",
    //          "Dec'21": "-49%",
    //          "Jan'22": "63%",
    //          "Feb'22": "44%",
    //          "Mar'22": "-23%",
    //          "FY 21-22": ""
    //         },
    //         {
    //         "plant": "Gujarat",
    //         "parameter": "Volume",
    //          "FY 20-21": 24744,
    //          "Apr'21": 2430,
    //          "May'21": 864,
    //          "Jun'21": 3051,
    //          "Jul'21": 4898,
    //          "Aug'21": 2797,
    //          "Sep'21": 270,
    //          "Oct'21": 1890,
    //          "Nov'21": 4278,
    //          "Dec'21": 3282,
    //          "Jan'22": 4590,
    //          "Feb'22": 5940,
    //          "Mar'22": 4861,
    //          "FY 21-22": 39151
    //         },
    //         {
    //         "plant": "Gujarat",
    //         "parameter": "MoM Growth",
    //          "FY 20-21": "",
    //          "Apr'21": "",
    //          "May'21": "-64%",
    //          "Jun'21": "253%",
    //          "Jul'21": "61%",
    //          "Aug'21": "-43%",
    //          "Sep'21": "-90%",
    //          "Oct'21": "600%",
    //          "Nov'21": "126%",
    //          "Dec'21": "-23%",
    //          "Jan'22": "40%",
    //          "Feb'22": "29%",
    //          "Mar'22": "-18%",
    //          "FY 21-22": ""
    //         }
    //     ]
    //     this.setState({
    //         monthlyVolInvoiceRowData:monthlyVolInvoiceRowData
    //     })
    // }

    // onCreateMonthlyPlantExitForGGNRowData(){
    //     var monthlyPlantExitForGGNRowData = [
    //          {
    //           "exit_parameter": "Avg. Invoicing/Day",
    //           "Apr'21": 80,
    //           "May'21": 10,
    //           "Jun'21": 40,
    //           "Jul'21": 49,
    //           "Aug'21": 69,
    //           "Sep'21": 64,
    //           "Oct'21": 103,
    //           "Nov'21": 121,
    //           "Dec'21": 103,
    //           "Jan'22": 86,
    //           "Feb'22": 118,
    //           "Mar'22": 72.8,
    //           "FY 21-22": "76%"
    //          },
    //          {
    //           "exit_parameter": "Avg.Exit/Day",
    //           "Apr'21": 76,
    //           "May'21": 10,
    //           "Jun'21": 43,
    //           "Jul'21": 37,
    //           "Aug'21": 74,
    //           "Sep'21": 64,
    //           "Oct'21": 103,
    //           "Nov'21": 108,
    //           "Dec'21": 102,
    //           "Jan'22": 82,
    //           "Feb'22": 113,
    //           "Mar'22": 81.7,
    //           "FY 21-22": "745"
    //          },
    //          {
    //           "exit_parameter": "Exit Rate",
    //           "Apr'21": "95%",
    //           "May'21": "100%",
    //           "Jun'21": "108%",
    //           "Jul'21": "76%",
    //           "Aug'21": "107%",
    //           "Sep'21": "100%",
    //           "Oct'21": "100%",
    //           "Nov'21": "89%",
    //           "Dec'21": "99%",
    //           "Jan'22": "95%",
    //           "Feb'22": "96%",
    //           "Mar'22": "112%",
    //           "FY 21-22": "98%"
    //          }
    //     ]
            
            
    //     this.setState({
    //         monthlyPlantExitForGGNRowData: monthlyPlantExitForGGNRowData
    //     })
    // }

    // onCreateMonthlyPlantExitForMSRRowData(){
    //     var monthlyPlantExitForMSRRowData = [
    //         {
    //             "exit_parameter": "Avg. Invoicing/Day",
    //             "Apr'21": 121,
    //             "May'21": 4,
    //             "Jun'21": 65,
    //             "Jul'21": 84,
    //             "Aug'21": 122,
    //             "Sep'21": 34,
    //             "Oct'21": 59,
    //             "Nov'21": 85,
    //             "Dec'21": 100,
    //             "Jan'22": 89,
    //             "Feb'22": 130,
    //             "Mar'22": 64.77,
    //             "FY 21-22": "80%"
    //         },
    //         {
    //             "exit_parameter": "Avg.Exit/Day",
    //             "Apr'21": 120,
    //             "May'21": 4,
    //             "Jun'21": 65,
    //             "Jul'21": 77,
    //             "Aug'21": 128,
    //             "Sep'21": 34,
    //             "Oct'21": 59,
    //             "Nov'21": 73,
    //             "Dec'21": 98,
    //             "Jan'22": 86,
    //             "Feb'22": 119,
    //             "Mar'22": 77.6,
    //             "FY 21-22": "78%"
    //         },
    //         {
    //             "exit_parameter": "Exit Rate",
    //             "Apr'21": "99%",
    //             "May'21": "100%",
    //             "Jun'21": "100%",
    //             "Jul'21": "92%",
    //             "Aug'21": "105%",
    //             "Sep'21": "100%",
    //             "Oct'21": "100%",
    //             "Nov'21": "86%",
    //             "Dec'21": "98%",
    //             "Jan'22": "97%",
    //             "Feb'22": "92%",
    //             "Mar'22": "120%",
    //             "FY 21-22": "98%"
    //         }
    //     ]
    //     this.setState({
    //         monthlyPlantExitForMSRRowData: monthlyPlantExitForMSRRowData
    //     })
    // }

    // onCreateMonthlyPlantExitForGUJRowData(){
    //     var monthlyPlantExitForGUJRowData = [
    //         {
    //             "exit_parameter": "Avg. Invoicing/Day",
    //          "Apr'21": 67,
    //          "May'21": 23,
    //          "Jun'21": 97,
    //          "Jul'21": 146,
    //          "Aug'21": 94,
    //          "Sep'21": 13,
    //          "Oct'21": 60,
    //          "Nov'21": 142,
    //          "Dec'21": 219,
    //          "Jan'22": 148,
    //          "Feb'22": 212,
    //          "Mar'22": 156.8,
    //          "FY 21-22": "115%"
    //         },
    //         {
    //             "exit_parameter": "Avg.Exit/Day",
    //          "Apr'21": 63,
    //          "May'21": 21,
    //          "Jun'21": 103,
    //          "Jul'21": 130,
    //          "Aug'21": 109,
    //          "Sep'21": 13,
    //          "Oct'21": 60,
    //          "Nov'21": 135,
    //          "Dec'21": 160,
    //          "Jan'22": 148,
    //          "Feb'22": 184,
    //          "Mar'22": 182,
    //          "FY 21-22": "109%"
    //         },
    //         {
    //             "exit_parameter": "Exit Rate",
    //          "Apr'21": "94%",
    //          "May'21": "91%",
    //          "Jun'21": "106%",
    //          "Jul'21": "89%",
    //          "Aug'21": "115%",
    //          "Sep'21": "100%",
    //          "Oct'21": "100%",
    //          "Nov'21": "95%",
    //          "Dec'21": "73%",
    //          "Jan'22": "100%",
    //          "Feb'22": "86%",
    //          "Mar'22": "116%",
    //          "FY 21-22": "95%"
    //         }
    //        ]
    //     this.setState({
    //         monthlyPlantExitForGUJRowData: monthlyPlantExitForGUJRowData
    //     })
    // }

    // onCreateLastInvoicingGGNRowData(){
    //     var lastInvoicingGGNRowData = [
    //         {
    //         "parameter": "Last Invoicing to Rake Departure",
    //          "Apr'21": 1,
    //          "May'21": 2,
    //          "Jun'21": 1.5,
    //          "Jul'21": 1.8,
    //          "Aug'21": 1.5,
    //          "Sep'21": 2,
    //          "Oct'21": 1.5,
    //          "Nov'21": 1.8,
    //          "Dec'21": 2.6,
    //          "Jan'22": 2.1,
    //          "Feb'22": 2,
    //          "Mar'22": 2.36,
    //          "FY 21-22": "2"
    //         }
    //        ];
    //     this.setState({
    //         lastInvoicingGGNRowData: lastInvoicingGGNRowData
    //     })
    // }
    // onCreateLastInvoicingGUJRowData(){
    //     var lastInvoicingGUJRowData = [
    //         {
    //         "parameter": "Last Invoicing to Rake Departure",
    //         "Apr'21": 1,
    //         "May'21": 1.5,
    //         "Jun'21": 1.8,
    //         "Jul'21": 2,
    //         "Aug'21": 2.8,
    //         "Sep'21": 1,
    //         "Oct'21": 1.5,
    //         "Nov'21": 2,
    //         "Dec'21": 3,
    //         "Jan'22": 2.2,
    //         "Feb'22": 1.9,
    //         "Mar'22": 1.6,
    //         "FY 21-22": "2"
    //         }
    //        ];
    //     this.setState({
    //         lastInvoicingGUJRowData: lastInvoicingGUJRowData
    //     })
    // }
    // onCreateLastMileRowData(){
    //     var lastMileRowData = [
    //         {
    //          "destinations": "Guwahati",
    //          "Apr'21": 2,
    //          "May'21": 1,
    //          "Jun'21": 1,
    //          "Jul'21": 2,
    //          "Aug'21": 4,
    //          "Sep'21": 3,
    //          "Oct'21": 2,
    //          "Nov'21": 4,
    //          "Dec'21": 1,
    //          "Jan'22": 2,
    //          "Feb'22": 1,
    //          "Mar'22": 1,
    //          "FY 21-22": 2
    //         },
    //         {
    //          "destinations": "Nagpur",
    //          "Apr'21": 1,
    //          "May'21": "",
    //          "Jun'21": 3,
    //          "Jul'21": 1,
    //          "Aug'21": 2,
    //          "Sep'21": 1,
    //          "Oct'21": 1,
    //          "Nov'21": 1,
    //          "Dec'21": 1,
    //          "Jan'22": 1,
    //          "Feb'22": 1,
    //          "Mar'22": "",
    //          "FY 21-22": 2
    //         },
    //         {
    //          "destinations": "Kolkata",
    //          "Apr'21": 2,
    //          "May'21": "",
    //          "Jun'21": 2,
    //          "Jul'21": 3,
    //          "Aug'21": 1,
    //          "Sep'21": "",
    //          "Oct'21": 2,
    //          "Nov'21": "",
    //          "Dec'21": 1,
    //          "Jan'22": 1,
    //          "Feb'22": 1,
    //          "Mar'22": 0,
    //          "FY 21-22": 2
    //         },
    //         {
    //          "destinations": "Indore",
    //          "Apr'21": 2,
    //          "May'21": "",
    //          "Jun'21": 2,
    //          "Jul'21": 2,
    //          "Aug'21": "",
    //          "Sep'21": "",
    //          "Oct'21": "",
    //          "Nov'21": "",
    //          "Dec'21": "",
    //          "Jan'22": "",
    //          "Feb'22": 1,
    //          "Mar'22": 0,
    //          "FY 21-22": 2
    //         },
    //         {
    //          "destinations": "Irugur",
    //          "Apr'21": 1,
    //          "May'21": 1,
    //          "Jun'21": "",
    //          "Jul'21": 1,
    //          "Aug'21": "",
    //          "Sep'21": "",
    //          "Oct'21": 1,
    //          "Nov'21": 1,
    //          "Dec'21": 1,
    //          "Jan'22": 1,
    //          "Feb'22": 1,
    //          "Mar'22": 0,
    //          "FY 21-22": 1
    //         },
    //         {
    //          "destinations": "Agartala",
    //          "Apr'21": 1,
    //          "May'21": "",
    //          "Jun'21": 1,
    //          "Jul'21": 1,
    //          "Aug'21": 1,
    //          "Sep'21": "",
    //          "Oct'21": 1,
    //          "Nov'21": 1,
    //          "Dec'21": 1,
    //          "Jan'22": 1,
    //          "Feb'22": 1,
    //          "Mar'22": "",
    //          "FY 21-22": 1
    //         },
    //         {
    //          "destinations": "Chennai",
    //          "Apr'21": 1,
    //          "May'21": "",
    //          "Jun'21": 1,
    //          "Jul'21": 1,
    //          "Aug'21": 1,
    //          "Sep'21": "",
    //          "Oct'21": 1,
    //          "Nov'21": "",
    //          "Dec'21": 1,
    //          "Jan'22": 1,
    //          "Feb'22": 1,
    //          "Mar'22": "",
    //          "FY 21-22": 1
    //         },
    //         {
    //          "destinations": "Bangalore",
    //          "Apr'21": 2,
    //          "May'21": 3,
    //          "Jun'21": 1,
    //          "Jul'21": 1,
    //          "Aug'21": 1,
    //          "Sep'21": 1,
    //          "Oct'21": 1,
    //          "Nov'21": 1,
    //          "Dec'21": 1,
    //          "Jan'22": "",
    //          "Feb'22": 1,
    //          "Mar'22": 0,
    //          "FY 21-22": 1
    //         },
    //         {
    //          "destinations": "Sanand",
    //          "Apr'21": 1,
    //          "May'21": "",
    //          "Jun'21": 3,
    //          "Jul'21": 4,
    //          "Aug'21": 3,
    //          "Sep'21": 2,
    //          "Oct'21": 1,
    //          "Nov'21": 1,
    //          "Dec'21": 2,
    //          "Jan'22": "",
    //          "Feb'22": 1,
    //          "Mar'22": 0,
    //          "FY 21-22": 2
    //         },
    //         {
    //          "destinations": "Detroj",
    //          "Apr'21": "",
    //          "May'21": "",
    //          "Jun'21": "",
    //          "Jul'21": 3,
    //          "Aug'21": 2,
    //          "Sep'21": 2,
    //          "Oct'21": 2,
    //          "Nov'21": 2,
    //          "Dec'21": 1,
    //          "Jan'22": 1,
    //          "Feb'22": 1,
    //          "Mar'22": "",
    //          "FY 21-22": 2
    //         },
    //         {
    //          "destinations": "Hyderabad",
    //          "Apr'21": "",
    //          "May'21": "",
    //          "Jun'21": "",
    //          "Jul'21": 1,
    //          "Aug'21": "",
    //          "Sep'21": "",
    //          "Oct'21": "",
    //          "Nov'21": "",
    //          "Dec'21": "",
    //          "Jan'22": 1,
    //          "Feb'22": "",
    //          "Mar'22": "",
    //          "FY 21-22": 1
    //         }
    //        ];
    //     console.log(lastMileRowData, "lastMileRowData")
    //     this.setState({
    //         lastMileRowData: lastMileRowData
    //     })
    // }

    getMonths(from,to){
        var monthNames = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

        var arr = [];
        var datFrom = new Date('1 ' + from);
        var datTo = new Date('1 ' + to);
        var fromYear =  datFrom.getFullYear();
        var toYear =  datTo.getFullYear();
        var diffYear = (12 * (toYear - fromYear)) + datTo.getMonth();

        for (var i = datFrom.getMonth(); i <= diffYear; i++) {
            arr.push(monthNames[i%12] + " " + Math.floor(fromYear+(i/12)).toString().substr(-2));
        }        
        this.setState({
            fascalyears : arr
        });
        console.log(arr,"months");
    }
    
    onChangeTransporter(transporterId){
        this.setState(
            { transporterId },
            () => console.log(`transporter Option selected:`, this.state.transporterId)
        );
    }
    onChangeFy(fYear){
        this.setState(
            { fYear },
            () => console.log(`fYear Option selected:`, this.state.fYear)
        );
    }
    onClickTransporter(){
       this.onPageLoadData();
    }
    createColumns()
    {
        var columns=[]
        var defs=[];
        var keys = Object.keys(this.state.rowData[0]);
        const rowLength = this.state.rowData.length;
        var cnt = 0;
        var cFydata = [];
        keys.forEach((z, k) => {
            var hideCol = false;
            if(z == "plant_code")
            {
                var hideCol = true;
            }
            if(z == "plant")
            {
                var keyVal = "PLANT";
                var hwid = "150";
                var pinnedLeft = "left";
                var isEditable=false;
            }
            else if(z == "parameters")
            {
                var keyVal = "PARAMETERS";
                var hwid = "130";
                var pinnedLeft = "left";
                var isEditable=false;
            }
            else
            {
                var keyVal = z;
                var hwid = "90";
                var pinnedLeft="";
                var isEditable=true;
            }
            var check = 1;
            if(z.includes("FY") == true)
            {
                console.log(z,cnt,"includesFY");
                if(cnt == 0)
                {
                    check = 1;
                    pinnedLeft = "left";
                }
                else
                {
                    check = 0;
                    cFydata.push({
                        headerName:keyVal,
                        field:z,
                        width:hwid,
                        pinned:pinnedLeft,
                        editable:isEditable,
                        hide:hideCol,
                        valueGetter:function(params){
                            if(params.data[z] == "target")
                            {
                                return "Target";
                            }
                            else if(params.data[z] == "achievemnet")
                            {
                                return "Achievement";
                            }
                            else if(params.data[z] == "gap")
                            {
                                return "Gap";
                            }
                            else if(params.data[z] == "target_percentage")
                            {
                                return "% Target Achmnt";
                            }
                            else if(params.data[z] == "growth")
                            {
                                return "Growth (%)";
                            }
                            else
                            {
                                return params.data[z];
                            }
                        },
                        cellClass: function(params){
                            if(z == "plant")
                            {
                                if(params.data[z] != "")
                                {
                                    return ["cellstylegridY","pstBg"]
                                }
                            }
                        },
                        cellStyle: params => {
                            if(z == "plant")
                            {
                                if(params.data[z] != "" && params.data[z] !="GUJARAT")
                                {
                                    return {borderBottom: '1px solid #ccc'};
                                }
                                return null;
                            }
                            return null;                    
                        },
                        rowSpan: function(params){
                            if(z == "plant")
                            {
                                if(params.data[z] != "")
                                {
                                    return 5;
                                }
                            }
                        },
                        cellEditor: NumericCellEditor,
                    })
                }
                cnt = parseInt(cnt)+1;
            }  
            if(check == 1)
            { 
                columns.push({
                    headerName:keyVal,
                    field:z,
                    width:hwid,
                    pinned:pinnedLeft,
                    editable:isEditable,
                    hide:hideCol,
                    valueGetter:function(params){
                        if(params.data[z] == "target")
                        {
                            return "Target";
                        }
                        else if(params.data[z] == "achievemnet")
                        {
                            return "Achievement";
                        }
                        else if(params.data[z] == "gap")
                        {
                            return "Gap";
                        }
                        else if(params.data[z] == "target_percentage")
                        {
                            return "% Target Achmnt";
                        }
                        else if(params.data[z] == "growth")
                        {
                            return "Growth (%)";
                        }
                        else
                        {
                            return params.data[z];
                        }
                    },
                    cellClass: function(params){
                        if(z == "plant")
                        {
                            if(params.data[z] != "")
                            {
                                return ["cellstylegridY","pstBg"]
                            }
                        }
                    },
                    cellStyle: params => {
                        if(z == "plant")
                        {
                            if(params.data[z] != "" && params.data[z] !="GUJARAT")
                            {
                                return {borderBottom: '1px solid #ccc'};
                            }
                            return null;
                        }
                        return null;                    
                    },
                    rowSpan: function(params){
                        if(z == "plant")
                        {
                            if(params.data[z] != "")
                            {
                                return 5;
                            }
                        }
                    },
                    cellEditor: NumericCellEditor,
                })
            }
        })          
        if(cFydata.length > 0)
        {
            columns.push(cFydata[0]);
        }
        console.log("columns ", columns)
        this.setState({
            columnwithDefs:columns,
            achievement: "activet",
        })
    }
    createColumns1()
    {
        var columns=[]
        var defs=[];
        var fascalyears = this.state.fascalyears;
        columns.push({
            headerName:"PLANT",
            field:"plant_name",
            width:120,
        })
        columns.push({
            headerName:"PARAMETER",
            field:"parameter",
            width:120,
        })
        
        fascalyears.forEach((z, k) => {
            var fymnth = z.replace(" ", "-");
            columns.push({
                headerName:fymnth,
                field:fymnth,
                width:90,
                //cellEditor: NumericCellEditor,
            })
        })          
       
        console.log("columns ", columns)
        this.setState({
            columnwithDefs:columns
        })
    }
    createRows(){
        var rowData =this.state.rowData;
        var rowdefs = []
        // console.log("transdata ", transdata)
        var mntKeys = [];
        rowData.forEach((mnt,index) =>{
            mntKeys.push(mnt.month);
        })
        
    }

    onLoadTrainingsData(){
        var transporterId = this.state.transporterId;
        var fYear = this.state.fYear;
        if(transporterId !='' && transporterId !=undefined)
        {
            this.setState({
                loadshow:"show-m",
            });
            var reqparams = {
                "transporterId":transporterId.value,
            }
            if(transporterId !='' && transporterId !=undefined)
            {
                reqparams["year"] = fYear.value;
            }
            redirectURL.post("/consignments/getTransporterDrivingTrainings",reqparams)    
            .then(async (response) => {
                var trainingsData = eval(response.data.targets_list);
                console.log("trainingsData",trainingsData);
                if(trainingsData !=undefined)
                {
                    this.setState({
                        loadshow:"show-n",
                        trainingsSegment : "show-m",
                        trainingsData : trainingsData,
                    });
                }
                else
                {
                    this.setState({
                        loadshow:"show-n",
                        trainingsSegment : "show-m",
                        trainingsData : [],
                    });
                }
            })
            .catch(function(e){
                console.log("Error ",e)
            })
        }
        else
        {
            this.setState({
                basicTitle:"Please select transporter.",
                basicType : "warning",
                show : true
            })
        }
    }

    onLoadIdtrData(){
        var transporterId = this.state.transporterId;
        var fYear = this.state.fYear;
        if(transporterId !='' && transporterId !=undefined)
        {
            this.setState({
                loadshow:"show-m",
            });
            var reqparams = {
                "transporterId":transporterId.value,
                "driving_type":"idtr"
            }
            if(transporterId !='' && transporterId !=undefined)
            {
                reqparams["year"] = fYear.value;
            }
            redirectURL.post("/consignments/getTransporterDrivingTrainings",reqparams)    
            .then(async (response) => {
                var trainingsData = eval(response.data.targets_list);
                console.log("trainingsData",trainingsData);
                if(trainingsData !=undefined)
                {
                    this.setState({
                        loadshow:"show-n",
                        idtrSegment : "show-m",
                        idtrData : trainingsData,
                    });
                }
                else
                {
                    this.setState({
                        loadshow:"show-n",
                        idtrSegment : "show-m",
                        idtrData : [],
                    });
                }
            })
            .catch(function(e){
                console.log("Error ",e)
            })
        }
        else
        {
            this.setState({
                basicTitle:"Please select transporter.",
                basicType : "warning",
                show : true
            })
        }
    }

    onLoadFleetData(){
        var transporterId = this.state.transporterId;
        var fYear = this.state.fYear;
        if(transporterId !='' && transporterId !=undefined)
        {
            this.setState({
                loadshow:"show-m",
            });
            var reqparams = {
                "transporterId":transporterId.value,
                "driving_type":"idtr"
            }
            if(transporterId !='' && transporterId !=undefined)
            {
                reqparams["year"] = fYear.value;
            }
            redirectURL.post("/consignments/getTransporterFleetPerformance",reqparams)    
            .then(async (response) => {
                var fleetData = eval(response.data.targets_list);
                console.log("fleetData",fleetData);
                if(fleetData !=undefined)
                {
                    this.setState({
                        loadshow:"show-n",
                        fleetSegment : "show-m",
                        fleetData : fleetData,
                    });
                }
                else
                {
                    this.setState({
                        loadshow:"show-n",
                        fleetSegment : "show-m",
                        fleetData : [],
                    });
                }
            })
            .catch(function(e){
                console.log("Error ",e)
            })
        }
        else
        {
            this.setState({
                basicTitle:"Please select transporter.",
                basicType : "warning",
                show : true
            })
        }
    }

    onLoadDamageData(){
        var transporterId = this.state.transporterId;
        var fYear = this.state.fYear;
        if(transporterId !='' && transporterId !=undefined)
        {
            this.setState({
                loadshow:"show-m",
            });
            var reqparams = {
                "transporterId":transporterId.value,
                "driving_type":"idtr"
            }
            if(transporterId !='' && transporterId !=undefined)
            {
                reqparams["year"] = fYear.value;
            }
            redirectURL.post("/consignments/getTransporterDamagePerformance",reqparams)    
            .then(async (response) => {
                var damageData = eval(response.data.targets_list);
                console.log("damageData",damageData);
                if(damageData !=undefined)
                {
                    this.setState({
                        loadshow:"show-n",
                        damageSegment : "show-m",
                        damageData : damageData,
                    });
                }
                else
                {
                    this.setState({
                        loadshow:"show-n",
                        damageSegment : "show-m",
                        damageData : [],
                    });
                }
            })
            .catch(function(e){
                console.log("Error ",e)
            })
        }
        else
        {
            this.setState({
                basicTitle:"Please select transporter.",
                basicType : "warning",
                show : true
            })
        }
    }

    onLoadCarrierData(){
        var transporterId = this.state.transporterId;
        var fYear = this.state.fYear;
        if(transporterId !='' && transporterId !=undefined)
        {
            this.setState({
                loadshow:"show-m",
            });
            var reqparams = {
                "transporterId":transporterId.value,
                "driving_type":"idtr"
            }
            if(transporterId !='' && transporterId !=undefined)
            {
                reqparams["year"] = fYear.value;
            }
            redirectURL.post("/consignments/getTransporterDailyRunningCarrier",reqparams)    
            .then(async (response) => {
                var carrierData = eval(response.data.targets_list);
                console.log("carrier",carrierData);
                if(carrierData !=undefined)
                {
                    this.setState({
                        loadshow:"show-n",
                        carrierSegment : "show-m",
                        carrierData : carrierData,
                    });
                }
                else
                {
                    this.setState({
                        loadshow:"show-n",
                        carrierSegment : "show-m",
                        carrierData : [],
                    });
                }
            })
            .catch(function(e){
                console.log("Error ",e)
            })
        }
        else
        {
            this.setState({
                basicTitle:"Please select transporter.",
                basicType : "warning",
                show : true
            })
        }
    }

    onLoadOntimeData(){
        var transporterId = this.state.transporterId;
        var fYear = this.state.fYear;
        if(transporterId !='' && transporterId !=undefined)
        {
            this.setState({
                loadshow:"show-m",
            });
            var reqparams = {
                "transporterId":transporterId.value,
                "driving_type":"idtr"
            }
            if(transporterId !='' && transporterId !=undefined)
            {
                reqparams["year"] = fYear.value;
            }
            redirectURL.post("/consignments/getTransporterOntimePerformance",reqparams)    
            .then(async (response) => {
                var ontimeData = eval(response.data.targets_list);
                console.log("ontimeData",ontimeData);
                if(ontimeData !=undefined)
                {
                    this.setState({
                        loadshow:"show-n",
                        ontimeSegment : "show-m",
                        ontimeData : ontimeData,
                    });
                }
                else
                {
                    this.setState({
                        loadshow:"show-n",
                        ontimeSegment : "show-m",
                        ontimeData : [],
                    });
                }
            })
            .catch(function(e){
                console.log("Error ",e)
            })
        }
        else
        {
            this.setState({
                basicTitle:"Please select transporter.",
                basicType : "warning",
                show : true
            })
        }
    }

    onLoadTransitData(){
        var transporterId = this.state.transporterId;
        var fYear = this.state.fYear;
        if(transporterId !='' && transporterId !=undefined)
        {
            this.setState({
                loadshow:"show-m",
            });
            var reqparams = {
                "transporterId":transporterId.value,
                "driving_type":"idtr"
            }
            if(transporterId !='' && transporterId !=undefined)
            {
                reqparams["year"] = fYear.value;
            }
            redirectURL.post("/consignments/getTransporterTransitData",reqparams)    
            .then(async (response) => {
                var transitData = eval(response.data.targets_list);
                console.log("transitData",transitData);
                if(transitData !=undefined)
                {
                    this.setState({
                        loadshow:"show-n",
                        transitSegment : "show-m",
                        transitData : transitData,
                    });
                }
                else
                {
                    this.setState({
                        loadshow:"show-n",
                        transitSegment : "show-m",
                        transitData : [],
                    });
                }
            })
            .catch(function(e){
                console.log("Error ",e)
            })
        }
        else
        {
            this.setState({
                basicTitle:"Please select transporter.",
                basicType : "warning",
                show : true
            })
        }
    }

    onLoadPendingData(){
        var transporterId = this.state.transporterId;
        var fYear = this.state.fYear;
        if(transporterId !='' && transporterId !=undefined)
        {
            this.setState({
                loadshow:"show-m",
            });
            var reqparams = {
                "transporterId":transporterId.value,
                "driving_type":"idtr"
            }
            if(transporterId !='' && transporterId !=undefined)
            {
                reqparams["year"] = fYear.value;
            }
            redirectURL.post("/consignments/getTransporterPendingPods",reqparams)    
            .then(async (response) => {
                var pendingData = eval(response.data.targets_list);
                console.log("pendingData",pendingData);
                if(pendingData !=undefined)
                {
                    this.setState({
                        loadshow:"show-n",
                        pendingSegment : "show-m",
                        pendingData : pendingData,
                    });
                }
                else
                {
                    this.setState({
                        loadshow:"show-n",
                        pendingSegment : "show-m",
                        pendingData : [],
                    });
                }
            })
            .catch(function(e){
                console.log("Error ",e)
            })
        }
        else
        {
            this.setState({
                basicTitle:"Please select transporter.",
                basicType : "warning",
                show : true
            })
        }
    }

    onLoadPaymentData(){
        var transporterId = this.state.transporterId;
        if(transporterId !='' && transporterId !=undefined)
        {
            this.setState({
                loadshow:"show-m",
            });
            var reqparams = {
                "transporterId":transporterId.value,
                "fy": this.state.fYear.value
            }
            redirectURL.post("/consignments/getTransporterSecondaryPayments",reqparams)    
            .then(async (response) => {
                var paymentData = eval(response.data.targets_list);
                console.log("paymentData",paymentData);
                if(paymentData !=undefined)
                {
                    this.setState({
                        loadshow:"show-n",
                        paymentSegment : "show-m",
                        paymentData : paymentData,
                    });
                }
                else
                {
                    this.setState({
                        loadshow:"show-n",
                        paymentSegment : "show-m",
                        paymentData : [],
                    });
                }
            })
            .catch(function(e){
                console.log("Error ",e)
            })
        }
        else
        {
            this.setState({
                basicTitle:"Please select transporter.",
                basicType : "warning",
                show : true
            })
        }
    }

	onClickHideAll(){
        $(".selected_date").val("")

		this.setState({
			overly : "show-n",
			sliderStateEmailsTranslate : "",
			sliderbulkupload: "",
            sliderTranslatebulkupload: "",
            uploadedFile: [],
            csvcontent: []
		})
	}
	
   onGridReady = params => {
		this.gridApi = params.api;
		
		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
		
    };

    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }
    
    onClickTab(pageType){
        if(pageType == "achievement")
        {
            var rowData = this.state.rowData;
            if(rowData.length == 0)
            {
                this.onClickTransporter();
            }
            var achievement = "activet";
            var trainings = "";
            var idtr = "";
            var fleet = "";
            var damage = "";
            var carrier = "";
            var ontime = "";
            var transit = "";
            var pending = "";
            var payment = "";
            var achievementSegment = "show-m";
            var trainingsSegment = "show-n";
            var idtrSegment = "show-n";
            var fleetSegment = "show-n";
            var damageSegment = "show-n";
            var carrierSegment = "show-n";
            var ontimeSegment = "show-n";
            var transitSegment = "show-n";
            var pendingSegment = "show-n";
            var paymentSegment = "show-n";
            var seniorManagmentInfo = "";
            var stockSegment = "show-n";
        }
        if(pageType == "trainings")
        {
            var trainingsData = this.state.trainingsData;
            if(trainingsData.length == 0)
            {
                this.onLoadTrainingsData();
            }
            var achievement = "";
            var trainings = "activet";
            var idtr = "";
            var fleet = "";
            var damage = "";
            var carrier = "";
            var ontime = "";
            var transit = "";
            var pending = "";
            var payment = "";
            var achievementSegment = "show-n";
            var trainingsSegment = "show-m";
            var idtrSegment = "show-n";
            var fleetSegment = "show-n";
            var damageSegment = "show-n";
            var carrierSegment = "show-n";
            var ontimeSegment = "show-n";
            var transitSegment = "show-n";
            var pendingSegment = "show-n";
            var paymentSegment = "show-n";
            var seniorManagmentInfo = "";
            var stockSegment = "show-n";
        }
        if(pageType == "idtr")
        {
            var idtrData = this.state.idtrData;
            if(idtrData.length == 0)
            {
                this.onLoadIdtrData();
            }
            var achievement = "";
            var trainings = "";
            var idtr = "activet";
            var fleet = "";
            var damage = "";
            var carrier = "";
            var ontime = "";
            var transit = "";
            var pending = "";
            var payment = "";
            var achievementSegment = "show-n";
            var trainingsSegment = "show-n";
            var idtrSegment = "show-m";
            var fleetSegment = "show-n";
            var damageSegment = "show-n";
            var carrierSegment = "show-n";
            var ontimeSegment = "show-n";
            var transitSegment = "show-n";
            var pendingSegment = "show-n";
            var paymentSegment = "show-n";
            var seniorManagmentInfo = "";
            var stockSegment = "show-n";
        }
        if(pageType == "fleet")
        {
            var fleetData = this.state.fleetData;
            if(fleetData.length == 0)
            {
                this.onLoadFleetData();
            }
            var achievement = "";
            var trainings = "";
            var idtr = "";
            var fleet = "activet";
            var damage = "";
            var carrier = "";
            var ontime = "";
            var transit = "";
            var pending = "";
            var payment = "";
            var achievementSegment = "show-n";
            var trainingsSegment = "show-n";
            var idtrSegment = "show-n";
            var fleetSegment = "show-m";
            var damageSegment = "show-n";
            var carrierSegment = "show-n";
            var ontimeSegment = "show-n";
            var transitSegment = "show-n";
            var pendingSegment = "show-n";
            var paymentSegment = "show-n";
            var seniorManagmentInfo = "";
            var stockSegment = "show-n";
        }
        if(pageType == "damage")
        {
            var damageData = this.state.damageData;
            if(damageData.length == 0)
            {
                this.onLoadDamageData();
            }
            var achievement = "";
            var trainings = "";
            var idtr = "";
            var fleet = "";
            var damage = "activet";
            var carrier = "";
            var ontime = "";
            var transit = "";
            var pending = "";
            var payment = "";
            var achievementSegment = "show-n";
            var trainingsSegment = "show-n";
            var idtrSegment = "show-n";
            var fleetSegment = "show-n";
            var damageSegment = "show-m";
            var carrierSegment = "show-n";
            var ontimeSegment = "show-n";
            var transitSegment = "show-n";
            var pendingSegment = "show-n";
            var paymentSegment = "show-n";
            var seniorManagmentInfo = "";
            var stockSegment = "show-n";
        }
        if(pageType == "carrier")
        {
            var carrierData = this.state.carrierData;
            if(carrierData.length == 0)
            {
                this.onLoadCarrierData();
            }
            var achievement = "";
            var trainings = "";
            var idtr = "";
            var fleet = "";
            var damage = "";
            var carrier = "activet";
            var ontime = "";
            var transit = "";
            var pending = "";
            var payment = "";
            var achievementSegment = "show-n";
            var trainingsSegment = "show-n";
            var idtrSegment = "show-n";
            var fleetSegment = "show-n";
            var damageSegment = "show-n";
            var carrierSegment = "show-m";
            var ontimeSegment = "show-n";
            var transitSegment = "show-n";
            var pendingSegment = "show-n";
            var paymentSegment = "show-n";
            var seniorManagmentInfo = "";
            var stockSegment = "show-n";
        }
        if(pageType == "ontime")
        {
            var ontimeData = this.state.ontimeData;
            if(ontimeData.length == 0)
            {
                this.onLoadOntimeData();
            }
            var achievement = "";
            var trainings = "";
            var idtr = "";
            var fleet = "";
            var damage = "";
            var carrier = "";
            var ontime = "activet";
            var transit = "";
            var pending = "";
            var payment = "";
            var achievementSegment = "show-n";
            var trainingsSegment = "show-n";
            var idtrSegment = "show-n";
            var fleetSegment = "show-n";
            var damageSegment = "show-n";
            var carrierSegment = "show-n";
            var ontimeSegment = "show-m";
            var transitSegment = "show-n";
            var pendingSegment = "show-n";
            var paymentSegment = "show-n";
            var seniorManagmentInfo = "";
            var stockSegment = "show-n";
        }
        if(pageType == "transit")
        {
            var transitData = this.state.transitData;
            if(transitData.length == 0)
            {
                this.onLoadTransitData();
            }
            var achievement = "";
            var trainings = "";
            var idtr = "";
            var fleet = "";
            var damage = "";
            var carrier = "";
            var ontime = "";
            var transit = "activet";
            var pending = "";
            var payment = "";
            var achievementSegment = "show-n";
            var trainingsSegment = "show-n";
            var idtrSegment = "show-n";
            var fleetSegment = "show-n";
            var damageSegment = "show-n";
            var carrierSegment = "show-n";
            var ontimeSegment = "show-n";
            var transitSegment = "show-m";
            var pendingSegment = "show-n";
            var paymentSegment = "show-n";
            var seniorManagmentInfo = "";
            var stockSegment = "show-n";
        }
        if(pageType == "pending")
        {
            var pendingData = this.state.pendingData;
            if(pendingData.length == 0)
            {
                this.onLoadPendingData();
            }
            var achievement = "";
            var trainings = "";
            var idtr = "";
            var fleet = "";
            var damage = "";
            var carrier = "";
            var ontime = "";
            var transit = "";
            var pending = "activet";
            var payment = "";
            var achievementSegment = "show-n";
            var trainingsSegment = "show-n";
            var idtrSegment = "show-n";
            var fleetSegment = "show-n";
            var damageSegment = "show-n";
            var carrierSegment = "show-n";
            var ontimeSegment = "show-n";
            var transitSegment = "show-n";
            var pendingSegment = "show-m";
            var paymentSegment = "show-n";
            var seniorManagmentInfo = "";
            var stockSegment = "show-n";
        }
        if(pageType == "payments")
        {
            var paymentData = this.state.paymentData;
            if(paymentData.length == 0)
            {
                this.onLoadPaymentData();
            }
            var achievement = "";
            var trainings = "";
            var idtr = "";
            var fleet = "";
            var damage = "";
            var carrier = "";
            var ontime = "";
            var transit = "";
            var pending = "";
            var payment = "activet";
            var achievementSegment = "show-n";
            var trainingsSegment = "show-n";
            var idtrSegment = "show-n";
            var fleetSegment = "show-n";
            var damageSegment = "show-n";
            var carrierSegment = "show-n";
            var ontimeSegment = "show-n";
            var transitSegment = "show-n";
            var pendingSegment = "show-n";
            var paymentSegment = "show-m";
            var seniorManagmentInfo = "";
            var stockSegment = "show-n";
        }
        if(pageType == "freight")
        {
            var achievement = "";
            var trainings = "";
            var idtr = "";
            var fleet = "";
            var damage = "";
            var carrier = "";
            var ontime = "";
            var transit = "";
            var pending = "";
            var payment = "";
            var achievementSegment = "show-n";
            var trainingsSegment = "show-n";
            var idtrSegment = "show-n";
            var fleetSegment = "show-n";
            var damageSegment = "show-n";
            var carrierSegment = "show-n";
            var ontimeSegment = "show-n";
            var transitSegment = "show-n";
            var pendingSegment = "show-n";
            var paymentSegment = "show-n";
            var seniorManagmentInfo = "activet";
            var stockSegment = "show-m";
        }
        this.setState({
            achievement:achievement,
            trainings:trainings,
            idtr:idtr,
            fleet : fleet,
            damage : damage,
            carrier : carrier,
            ontime : ontime,
            transit : transit,
            pending : pending,
            payments : payment,
            achievementSegment:achievementSegment,
            trainingsSegment:trainingsSegment,
            idtrSegment:idtrSegment,
            fleetSegment:fleetSegment,
            damageSegment:damageSegment,
            carrierSegment:carrierSegment,
            ontimeSegment:ontimeSegment,
            transitSegment:transitSegment,
            pendingSegment:pendingSegment,
            paymentSegment:paymentSegment,
            stockSegment: stockSegment,
            seniorManagmentInfo: seniorManagmentInfo
        })
    }

    submitPerformance(){
        this.setState({
            //overly:"show-m",
            //loadshow:"show-m",
        });
        var allrows = []
        this.gridApi.forEachNode((node, index) => {
            //console.log("node ",node)
            allrows.push(node.data)
        })
        console.log("allrows ", allrows)
        if(allrows.length > 0)
        {       
            allrows.forEach((item) => {

            })
        }
    }

    showBulkUploadSlider(uploadedFor){
        var selectedPlant = {}
        if(uploadedFor == 3)
        {
            selectedPlant = {"label": "HARYANA", "value": "HARYANA"}
        }
        else
        {
            selectedPlant = {"label": "TVP Banglore", value: "TVPB"}
        }
        this.setState({
            overly: "show-m",
            uploadedFor: uploadedFor,
            sliderTranslatebulkupload: "slider-translate-40p",
            toggleMore: false,
            selectedPlant: selectedPlant
        })
    }

    onCellPerformanceUpdateData(e){
        console.log(e,"clickedData")
        var month = e.colDef.field;
        var plant_code = e.data.plant_code;
        var transporter_code = this.state.transporterId.value;
        var year = this.state.fYear.value;
        var parameter = e.data.parameters;
        var editedValue = e.value;
        if(editedValue !=undefined && editedValue !='')
        {
            var params = {
                "year" : year,
                "month" : month,
                "plant_code" : plant_code,
                "transporter_code" : transporter_code,
                "parameter" : parameter,
                "editedValue" : editedValue
            }
            console.log(params,"params")
            redirectURL.post("/consignments/saveTransporterPerformance",params)    
            .then(async (response) => {

            })
            .catch(function(e){
                console.log("Error ",e)
            })
        }
    }

    saveReviewDateForReportingFleet(e)
    {
        console.log(e.target.innerText)
        redirectURL.post("/consignments/saveReviewDate", {"review_date": e.target.innerText,
                                                          "transporter_code": this.state.transporterId.value}).then((response)=>{

        })
    }

    changeFileHandler = async (e) => {
        var config = {}
        if(this.state.uploadedFor == 1)
        {
            config = {
                headers: [
                    { 
                        name: 'TPT CODE',
                        inputName: 'TPT CODE',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'GURGAON/MANESAR',
                        inputName: 'GURGAON/MANESAR',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'GUJARAT',
                        inputName: 'GUJARAT',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'BANGALORE',
                        inputName: 'BANGALORE',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'NAGPUR',
                        inputName: 'NAGPUR',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'SILIGURI',
                        inputName: 'SILIGURI',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'Target',
                        inputName: 'Target',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    }
                ]
            }
        }
        else if(this.state.uploadedFor == 2)
        {
            var config = {
                headers: [
                    { 
                        name: 'TPT CODE',
                        inputName: 'TPT CODE',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'Target',
                        inputName: 'Target',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'Total',
                        inputName: 'Total',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    }
                ]
            }
        }
        else if(this.state.uploadedFor == 3)
        {
            var config = {
                headers: [
                    { 
                        name: 'TPT CODE',
                        inputName: 'TPT CODE',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'TPT NAME',
                        inputName: 'TPT NAME',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'TRANSIT RETURNS',
                        inputName: 'TRANSIT RETURNS',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    }
                ]
            }
        }
        else if(this.state.uploadedFor == 4)
        {
            var config = {
                headers: [
                    { 
                        name: 'TPT CODE',
                        inputName: 'TPT CODE',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'CARRIERS INSPECTED',
                        inputName: 'CARRIERS INSPECTED',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'AVERAGE GPA',
                        inputName: 'AVERAGE GPA',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'CARRIERS ON HOLD',
                        inputName: 'CARRIERS ON HOLD',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'GATE ENTRY CANCELLED',
                        inputName: 'GATE ENTRY CANCELLED',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                ]
            }
        }
		
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
            console.log(csvData.data, "cs data")
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
		});
		
		this.setState({
			uploadedFile: e.target.files[0]
		});
	}

    onSubmitUploadForm(e){
        e.preventDefault();
        var filename = this.state.uploadedFile.name.split(".")
        if(filename[filename.length-1].toLowerCase() == "csv")
        {
            this.setState({
                loadshow: "show-m",
                overly: "show-m",
                sliderTranslatebulkupload: ""
            })
            var fileData = this.state.csvcontent;
            var bulkUploadDate = $(".selected_date").val();
            console.log(bulkUploadDate);
            console.log(fileData, this.state.uploadedFile)
            // var selectedFY = this.state.fYear.value;
            if(fileData.length){
                var formdata = {
                    "uploadedData": JSON.stringify(fileData),
                    "month": bulkUploadDate,
                    "data_type": this.state.uploadedFor
                }
                if(this.state.uploadedFor == 3 || this.state.uploadedFor == 4)
                {
                    formdata['plant_name'] = this.state.selectedPlant.value
                }
                console.log(formdata, "formdata")
                redirectURL.post("/consignments/bulkUploadLspCard", formdata).then((response)=>{
                    this.setState({
                        loadshow: "show-n",
                        overly: "show-n",
                    })

                    if(response.data.status == "Success")
                    {
                        this.setState({
                            basicTitle: "Uploaded Successfully.",
                            basicType: "success",
                            show: true
                        })
                    }
                    else
                    {
                        this.setState({
                            basicTitle: "Failed to upload",
                            basicType: "warning",
                            show: true
                        })
                    }
                })
            }
            else{
                this.setState({
                    basicTitle: "No data found in the csv",
                    basicType: "warning",
                    show: true
                })
            }
        }
        else
        {
            this.setState({
                basicTitle: "Only csv files are accepted",
                basicType: "warning",
                show: true
            })
        }
    }

    onBlurBusinessSince(e){
        console.log(e.target.innerText, e.target, "changed text")
        var transporterCode = this.state.transporterId.value
        var transporterName = this.state.transporterId.label
        var businessSince = e.target.innerText

        var params = {
            transporter_code:transporterCode,
            transporter_name:transporterName,
            business_since : businessSince
        }
        redirectURL.post("/consignments/saveraillspperformancecard", params,{
            headers:{
                'Content-Type': 'application/json'
            }
        }).then((response)=>{
        })

    }
    onBlurOperationRouteGGN(e){
        console.log(e.target.innerText, e.target, "changed text")
        var transporterCode = this.state.transporterId.value
        var transporterName = this.state.transporterId.label
        var operatingRouteGgn = e.target.innerText

        var params = {
            transporter_code:transporterCode,
            transporter_name:transporterName,
            operating_route_from_GGN :operatingRouteGgn
        }
        redirectURL.post("/consignments/saveraillspperformancecard", params,{
            headers:{
                'Content-Type': 'application/json'
            }
        }).then((response)=>{
        })

    }
    onBlurManagerName(e){
        console.log(e.target.innerText, e.target, "changed text")
        var transporterCode = this.state.transporterId.value
        var transporterName = this.state.transporterId.label
        var managerName = e.target.innerText

        var params = {
            transporter_code:transporterCode,
            transporter_name:transporterName,
            manager_name :managerName,
        }
        redirectURL.post("/consignments/saveraillspperformancecard", params,{
            headers:{
                'Content-Type': 'application/json'
            }
        }).then((response)=>{
        })

    }
    onBlurOperationRouteGUJ(e){
        console.log(e.target.innerText, e.target, "changed text")
        var transporterCode = this.state.transporterId.value
        var transporterName = this.state.transporterId.label
        var operatingRouteGuj = e.target.innerText

        var params = {
            transporter_code:transporterCode,
            transporter_name:transporterName,
            operating_route_from_GUJ : operatingRouteGuj
        }
        redirectURL.post("/consignments/saveraillspperformancecard", params,{
            headers:{
                'Content-Type': 'application/json'
            }
        }).then((response)=>{
        })

    }
    HandleToggleMore() {
        this.setState({
            toggleMore:!this.state.toggleMore
        })
	}
    getApprovalStatus = () =>{
        var approval_time= moment.parseZone().format("YYYY-MM-DD");
        var approval_time_getter = approval_time.split("-");
        var approval_year = approval_time_getter[0];
        var approval_month = approval_time_getter[1];
        var approval_month_year = approval_year + "-" + approval_month;
        var tpt_code = this.state.transporterId.value;
        var params = {
            tpt_code : tpt_code,
            approval_month_year : approval_month_year,
        }
        redirectURL.post("/consignments/getraillspapprovalstatus", params).then((response)=>{
            this.setState({
                approvalStatus: response.data
            })
            if(response.data.length == 0){
                $('#send_for_approval').removeAttr('disabled');
                $('#approval').prop('disabled', true);
            }
            else if(response.data[0]['approval_status'] != 2){
                $('#send_for_approval').prop('disabled', true);
                $('#approval').removeAttr('disabled');
            }  
                 
            else
            {
                $('#send_for_approval').prop('disabled', true);
                $('#approval').prop('disabled', true);
            }  
        })

        
    }
    onClickSendForApproval = () =>{
        var approval_time= moment.parseZone().format("YYYY-MM-DD");
        var approval_time_getter = approval_time.split("-");
        var approval_year = approval_time_getter[0];
        var approval_month = approval_time_getter[1];
        var approval_month_year = approval_year + "-" + approval_month;
        var useremail = localStorage.getItem('email');
        var fy_year = this.state.fYear.value;
        var tpt_code = this.state.transporterId.value;
        var approval_status = 1;
        this.setState({
            loadshow: "show-m",
            overly: "show-m",
        })
        var params = {
            transporter_code:tpt_code,
            financial_year:fy_year,
            user_email:useremail,
            approval_status:approval_status,
            approval_month_year:approval_month_year,
        }
        // console.log(params,"send for approval");
        redirectURL.post("/consignments/raillspsendforapproval", params)
            .then((response)=>{
                this.setState({
                    loadshow: "show-n",
                    overly: "show-n",
                    show:true,
                    basicTitle:"Sent For Approval",
                    basicType:"success",
                })
              
            })
        }

    onClickApproval = (approval) =>{
        var approval_time= moment.parseZone().format("YYYY-MM-DD");
        var approval_time_getter = approval_time.split("-");
        var approval_year = approval_time_getter[0];
        var approval_month = approval_time_getter[1];
        var approval_month_year = approval_year + "-" + approval_month;
        var useremail = localStorage.getItem('email');
        var fy_year = this.state.fYear.value;
        var tpt_code = this.state.transporterId.value;
        var approval_status = 2;
        this.setState({
            loadshow: "show-m",
            overly: "show-m",
        })
        var params = {
            transporter_code:tpt_code,
            financial_year:fy_year,
            user_email:useremail,
            approval_status:approval_status,
            approval_month_year:approval_month_year,
            button_type: approval
        }
        // console.log(params,"send for approval");
        redirectURL.post("/consignments/raillspsendforapproval", params)
            .then((response)=>{
                this.setState({
                    loadshow: "show-n",
                    overly: "show-n",
                    show:true,
                    basicTitle:"Approved",
                    basicType:"success",
                })
               
            })
    }
   
    render(){
        const fleetDeployedCols = [
            {
                headerName: "Fleet Deployed for First / Last Mile Operation",
                headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                children: [
                    {
                        headerName: "",
                        headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                        children: [
                            {
                                headerName: "LSP",
                                field: "transporter_code",
                                width: "150",
                                headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                            }
                        ]
                    },
                    {
                        headerName: "First Mile",
                        headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                        children: [
                            {
                                headerName: "GGN / MSR",
                                field: "ggn_msr",
                                width: "140",
                                editable:true,
                                headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                                valueSetter:(params)=>{
                                    console.log(params)
                                    if(params.oldValue != params.newValue)
                                    {
                                        var data = {
                                            transporter_code:this.state.transporterId.value,
                                            ggn_msr:params.newValue,
                                        }
                                        params.data.ggn_msr = params.newValue;
                                        redirectURL.post("/consignments/saveraillspperformancecard", data,{
                                            headers:{
                                                'Content-Type': 'application/json'
                                            }
                                        }).then((response)=>{
                                        })
                                    }
                                }

                            },
                            {
                                headerName: "SMG",
                                field: "smg",
                                width: "140",
                                editable:true,
                                headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                                valueSetter:(params)=>{
                                    console.log(params)
                                    if(params.oldValue != params.newValue)
                                    {
                                        var data = {
                                            transporter_code:this.state.transporterId.value,
                                            smg:params.newValue,
                                        }
                                        params.data.smg = params.newValue;
                                        redirectURL.post("/consignments/saveraillspperformancecard", data,{
                                            headers:{
                                                'Content-Type': 'application/json'
                                            }
                                        }).then((response)=>{
                                        })
                                    }
                                }
                            }
                        ]
                    },
                    {
                        headerName: "Last Mile",
                        headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                        children: [
                            {
                                headerName: "Kolkata",
                                field: "kolkata",
                                width: "140",
                                editable:true,
                                headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                                valueSetter:(params)=>{
                                    console.log(params)
                                    if(params.oldValue != params.newValue)
                                    {
                                        var data = {
                                            transporter_code:this.state.transporterId.value,
                                            kolkata:params.newValue,
                                        }
                                        params.data.kolkata = params.newValue;
                                        redirectURL.post("/consignments/saveraillspperformancecard", data,{
                                            headers:{
                                                'Content-Type': 'application/json'
                                            }
                                        }).then((response)=>{
                                        })
                                    }
                                }
                            },
                            {
                                headerName: "Guwahati",
                                field: "guwahati",
                                width: "140",
                                editable:true,
                                headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                                valueSetter:(params)=>{
                                    console.log(params)
                                    if(params.oldValue != params.newValue)
                                    {
                                        var data = {
                                            transporter_code:this.state.transporterId.value,
                                            guwahati:params.newValue,
                                        }
                                        params.data.guwahati = params.newValue;
                                        redirectURL.post("/consignments/saveraillspperformancecard", data,{
                                            headers:{
                                                'Content-Type': 'application/json'
                                            }
                                        }).then((response)=>{
                                        })
                                    }
                                }
                            },
                            {
                                headerName: "Indore",
                                field: "indore",
                                width: "140",
                                editable:true,
                                headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                                valueSetter:(params)=>{
                                    console.log(params)
                                    if(params.oldValue != params.newValue)
                                    {
                                        var data = {
                                            transporter_code:this.state.transporterId.value,
                                            indore:params.newValue,
                                        }
                                        params.data.indore = params.newValue;
                                        redirectURL.post("/consignments/saveraillspperformancecard", data,{
                                            headers:{
                                                'Content-Type': 'application/json'
                                            }
                                        }).then((response)=>{
                                        })
                                    }
                                }
                            },
                            {
                                headerName: "Sanand",
                                field: "sanand",
                                width: "140",
                                editable:true,
                                headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                                valueSetter:(params)=>{
                                    console.log(params)
                                    if(params.oldValue != params.newValue)
                                    {
                                        var data = {
                                            transporter_code:this.state.transporterId.value,
                                            sanand:params.newValue,
                                        }
                                        params.data.sanand = params.newValue;
                                        redirectURL.post("/consignments/saveraillspperformancecard", data,{
                                            headers:{
                                                'Content-Type': 'application/json'
                                            }
                                        }).then((response)=>{
                                        })
                                    }
                                }
                            },
                            {
                                headerName: "Nagpur",
                                field: "nagpur",
                                width: "140",
                                editable:true,
                                headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                                valueSetter:(params)=>{
                                    console.log(params)
                                    if(params.oldValue != params.newValue)
                                    {
                                        var data = {
                                            transporter_code:this.state.transporterId.value,
                                            nagpur:params.newValue,
                                        }
                                        params.data.nagpur = params.newValue;
                                        redirectURL.post("/consignments/saveraillspperformancecard", data,{
                                            headers:{
                                                'Content-Type': 'application/json'
                                            }
                                        }).then((response)=>{
                                        })
                                    }
                                }
                            },
                            {
                                headerName: "Chennai",
                                field: "chennai",
                                width: "140",
                                editable:true,
                                headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                                valueSetter:(params)=>{
                                    console.log(params)
                                    if(params.oldValue != params.newValue)
                                    {
                                        var data = {
                                            transporter_code:this.state.transporterId.value,
                                            chennai:params.newValue,
                                        }
                                        params.data.chennai = params.newValue;
                                        redirectURL.post("/consignments/saveraillspperformancecard", data,{
                                            headers:{
                                                'Content-Type': 'application/json'
                                            }
                                        }).then((response)=>{
                                        })
                                    }
                                }
                            },
                            {
                                headerName: "Irugur",
                                field: "irugur",
                                width: "140",
                                editable:true,
                                headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                                valueSetter: (params)=>{
                                    console.log(params)
                                    if(params.oldValue != params.newValue)
                                    {
                                        var data = {
                                            transporter_code:this.state.transporterId.value,
                                            irugur:params.newValue,
                                        }
                                        params.data.irugur = params.newValue;
                                        redirectURL.post("/consignments/saveraillspperformancecard", data,{
                                            headers:{
                                                'Content-Type': 'application/json'
                                            }
                                        }).then((response)=>{
                                        })
                                    }
                                }
                            }
                        ]
                    }
                ]

            }
        ]

        var monthlyInvoicedCols = [
            {
                headerName: "MONTH WISE DAMAGE %",
                headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                children: [{
                        headerName: "Month",
                        field: "month",
                        width:200,
                        // headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                    },
                    {
                        headerName: "Invoiced",
                        field: "invoice_count",
                        width:200,
                        // headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                    },
                    {
                        headerName: "DMG",
                        field: "damage",
                        width:200,
                        editable:true,
                        valueSetter: (params)=>{
                            console.log(params)
                            if(params.oldValue != params.newValue)
                            {
                                var damage_percent = 0
                                if(params.data.invoice_count != 0 && params.newValue != 0)
                                {
                                    console.log(params.newValue, params.data.invoice_count)
                                    damage_percent = ((parseInt(params.newValue) / params.data.invoice_count)*100).toFixed(2)
                                    console.log(damage_percent,"damage_percent")
                                }
                                params.data.damage = params.newValue;
                                // params.data.damage_percent = damage_percent;
                                var monthlyInvoicedRowData = [];
                                var total_damage = 0;
                                var total_damage_percent = 0;
                                this.state.monthlyInvoicedRowData.map((e)=>{
                                    if(e.month == params.data.month)
                                    {
                                        e.damage_percent = damage_percent;
                                    }
                                    if(e.month != "total")
                                    {
                                        total_damage += parseInt(e.damage);
                                    }
                                    else{
                                        e.damage = total_damage;
                                        console.log(total_damage,"total_damage")
                                        if(e.invoice_count != 0 && total_damage != 0)
                                        {
                                            e.damage_percent = ((parseInt(total_damage) / e.invoice_count)*100).toFixed(2)
                                        }
                                        else
                                        {
                                            e.damage_percent = 0
                                        }
                                    }
                                    monthlyInvoicedRowData.push(e)
                                })
                                // console.log(monthlyInvoicedRowData, "monthlyInvoicedRowData")
                                this.setState({
                                    monthlyInvoicedRowData: monthlyInvoicedRowData
                                })
                                var data = {
                                    transporter_code:this.state.transporterId.value,
                                    month:params.data.month,
                                    damage:params.newValue,
                                    damage_percent: damage_percent
                                }
                                console.log(data, "params")
                                redirectURL.post("/consignments/saverailinvoicedata", data,{
                                    headers:{
                                        'Content-Type': 'application/json'
                                    }
                                }).then((response)=>{
                                })
                            }
                        }
                        // headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                    },
                    {
                        headerName: "Damage %",
                        field: "damage_percent",
                        width:200,
                        // valueGetter: (params)=>{
                        //     var damage_percent = 0
                        //     if(params.data.invoice_count != 0 && params.data.damage != 0)
                        //     {
                        //         damage_percent = Ma((params.data.damage / params.data.invoice_count)*100, 2)
                        //     }
                        //     return damage_percent
                        // },
                        // valueSetter: (params)=>{
                        //     var damage_percent = 0
                        //     if(params.data.invoice_count != 0 && params.data.damage != 0)
                        //     {
                        //         damage_percent = Math.round((params.data.damage / params.data.invoice_count)*100,2)
                        //     }
                        //     return damage_percent
                        // }
                        // headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                    }
                ]
            },
            
        ]

        // const fy_cols = ['FY 21-22', "Apr'22", "May'22", "Jun'22", "Jul'22", "Aug'22", "Sep'22", "Oct'22", "Nov'22", "Dec'22", "Jan'23", "Feb'23", "Mar'23", "FY 22-23"]
        
        var monthlyVolPerformanceCols = [
            {
                "headerName": "MONTH WISE VOLUME PERFORMANCE BCACBM",
                headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                children: [
                    {
                        headerName: "Plant",
                        field: "plant",
                        width: 120,
                    },
                    {
                        headerName: "Parameter",
                        field: "parameter",
                        width: 120,
                    },
                ]
            }
        ]
        var monthlyAllRakesPerformanceCols = [
            {
                "headerName": "RAKE WISE MONTHLY VOLUME PERFORMANCE BCACBM & NMG",
                headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                children: [
                    {
                        headerName: "Plant",
                        field: "plant",
                        width: 120,
                    },
                    {
                        headerName: "Parameter",
                        field: "parameter",
                        width: 120,
                    },
                ]
            }
        ]
        var monthlyPlantExitForGGN = [
            {
                "headerName": "MONTH WISE PLANT EXIT PERFORMANCE FOR GGN PLANT",
                headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                children: [
                    {
                        headerName: "Exit Parameters",
                        field: "exit_parameter",
                        width: 120,
                    },
                ]
            }
        ]
        var monthlyPlantExitForMSR = [
            {
                "headerName": "MONTH WISE PLANT EXIT PERFORMANCE FOR MNSR PLANT",
                headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                children: [
                    {
                        headerName: "Exit Parameters",
                        field: "exit_parameter",
                        width: 120,
                    },
                ]
            }
        ]
        var monthlyPlantExitForGUJ = [
            {
                "headerName": "MONTH WISE PLANT EXIT PERFORMANCE FOR GUJARAT PLANT",
                headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                children: [
                    {
                        headerName: "Exit Parameters",
                        field: "exit_parameter",
                        width: 120,
                    },
                ]
            }
        ]
        var lastInvoicingGGN = [
            {
                "headerName": "LAST INVOICING TO RAKE DEPARTURE PERFORMANCE FROM GGN/MNSR PLANT",
                headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                children: [
                    {
                        headerName: "Parameter",
                        field: "parameter",
                        width: 120,
                    },
                ]
            }
        ]
        var lastInvoicingGUJ = [
            {
                "headerName": "LAST INVOICING TO RAKE DEPARTURE PERFORMANCE FROM GUJ PLANT",
                headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                children: [
                    {
                        headerName: "Parameter",
                        field: "parameter",
                        width: 120,
                    },
                ]
            }
        ]
        var lastMilePerfCols = [
            {
                "headerName": "LAST MILE PERFORMANCE (IN DAYS)",
                headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
                children: [
                    {
                        headerName: "Destinations",
                        field: "destinations",
                        width: 120,
                    },
                ]
            }
        ]
        fy_cols.map((e)=>{
            monthlyVolPerformanceCols[0].children.push({
                headerName: e,
                field: e,
                width: 120
            })
            monthlyAllRakesPerformanceCols[0].children.push({
                headerName: e,
                field: e,
                width: 120
            })
            if(e != "FY 20-21")
            {
                monthlyPlantExitForGGN[0].children.push({
                    headerName: e,
                    field: e,
                    width: 120
                })
                monthlyPlantExitForMSR[0].children.push({
                    headerName: e,
                    field: e,
                    width: 120
                })
                monthlyPlantExitForGUJ[0].children.push({
                    headerName: e,
                    field: e,
                    width: 120
                })
                lastInvoicingGGN[0].children.push({
                    headerName: e,
                    field: e,
                    width: 120
                })
                lastInvoicingGUJ[0].children.push({
                    headerName: e,
                    field: e,
                    width: 120
                })
                lastMilePerfCols[0].children.push({
                    headerName: e,
                    field: e,
                    width: 120
                })
            }
            
        })
        var username = localStorage.getItem("username");
        // userEmail = userEmail.replace("[at]","@");
        // userEmail = userEmail.replace("[dot]",".");
        // console.log(userEmail,"userEmail");
        return (
            
    		<div className="container-fluid" style={(this.state.screenView == "Mobile")?{marginTop:"2em"}:{}}>
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>

            <div className="card col-xl-12">
                <div className="card-header">
                    <h5 className="float-left row">
                        <i className="icon-map cus-i"></i> <span>Transporter Performance Dashboard</span>
                    </h5>
                        <span className="layoutbtns float-right">
                            {(username == "Sankar Malla" || username == "Pramod Bauddh")?
                            <button className="float-right btn btn-danger" id="send_for_approval" disabled="" style={{marginLeft:"10px"}} onClick={this.onClickSendForApproval}>
                               Send For Approval
                            </button>:""}
                            
                            { (username == "AdilFaruqi" || username == "Pankaj Jha")?
                            <button className="float-right btn btn-info" id="approval" onClick={this.onClickApproval.bind(this, "approval")}>
                               Approve
                            </button>:""
                            }

                        {(username == "Sankar Malla")?
                            <button className="float-right btn btn-danger" id="send_for_approval" disabled="" style={{marginLeft:"10px"}} onClick={this.onClickApproval.bind(this, "MSIL")}>
                               Send Email
                            </button>:""}
                        </span>
                    <div className="float-right">
                        <div className="dropdown">
                            {/* <button
                                type="button" 
                                title="Send EMail"
                                onClick={() => this.HandleToggleMore()}
                                className={"btn se-ico btn-warning dropdown-toggle text-muted "+(this.state.showMailBtnb)}
                                type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                Bulk Upload
                            </button> */}
                            <div className={`dropdown-menu ${this.state.toggleMore?'show':''}`}
                                aria-labelledby="dropdownMenuButton">
                                <a className="dropdown-item" onClick={this.showBulkUploadSlider.bind(this, 1)}>Driver Trainings - DEC</a>
                                <a className="dropdown-item" onClick={this.showBulkUploadSlider.bind(this, 2)}>Driver Trainings - IDTR</a>
                                <a className="dropdown-item" onClick={this.showBulkUploadSlider.bind(this, 3)}>Transit Returns</a>
                                <a className="dropdown-item" onClick={this.showBulkUploadSlider.bind(this, 4)}>Sec Carrier Inspections</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body col-xl-12 col-lg-12">
                    <div class="row theme-form">
                        <div class={" col-xl-4 col-lg-4 form-group"}>
                            <label className="col-xl-12 col-lg-12">Select Transporter </label>
                            <Select 
                            placeholder={"Transporter"}
                            closeMenuOnSelect={true}
                            onChange={(e)=>{this.setState({"transporterId": e})}}
                            className={"col-xl-12 col-lg-12 border-radius-0"}
                            style={{borderRadius:"0px"}}
                            // value={this.state.transporterId}
                            // options={this.state.transportersList}
                            value={this.state.transporterId}
                            options={this.state.transporterOptions}
                             />
                        </div>
                        <div class={" col-xl-4 col-lg-4 form-group"}>
                            <label className="col-xl-12 col-lg-12">Select Financial Year </label>
                            <Select 
                            placeholder={"Financial Year"}
                            closeMenuOnSelect={true}
                            onChange={(e)=>{this.setState({"fYear": e})}}
                            className={"col-xl-12 col-lg-12 border-radius-0"}
                            style={{borderRadius:"0px"}}
                            value={this.state.fYear}
                            options={this.state.financialyears}
                            />
                        </div>
                        <div class="col-xl-2 col-lg-2 form-group">
                            <label>&nbsp;</label>
                            <br />
                            <button type="submit" onClick={this.onClickTransporter.bind(this)} class="btn btn-success">Get Data</button>&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                    </div>
                    <div className="row">
                        
                        <div className="col-xl-12 col-lg-12 col-sm-12 col-md-12">
                            <table className="table table-bordered tptinfo">
                                <tr>
                                    <th style={{width: "30%"}}>LSP CODE</th>
                                    <td>{this.state.transporterId.value}</td>
                                    <th>No. of Rakes</th>
                                    {(this.state.fleetDeployedRowData.length > 0)?
                                        <td>{this.state.fleetDeployedRowData[0].no_of_rakes}</td>
                                    :<td>""</td>}
                                    
                                </tr>
                                <tr>
                                    <th style={{width: "30%"}}>LSP NAME</th>
                                    <td colSpan={3}>{this.state.transporterId.label}</td>
                                </tr>
                                <tr>
                                    <th style={{width: "30%"}}>MANAGER'S NAME</th>
                                    
                                    <td colSpan={3} contenteditable = "true" onBlur={this.onBlurManagerName.bind(this)}>{(this.state.fleetDeployedRowData.length > 0)?this.state.fleetDeployedRowData[0].manager_name:""}</td>
                                </tr>
                                <tr>
                                    <th style={{width: "30%"}}>OPERATING ROUTE (From GGN / MSR)</th>
                                    <td colSpan={3} contenteditable = "true" onBlur={this.onBlurOperationRouteGGN.bind(this)}>{(this.state.fleetDeployedRowData.length)?this.state.fleetDeployedRowData[0].operating_route_from_GGN:""}</td>
                                </tr>
                                <tr>
                                    <th style={{width: "30%"}}>OPERATING ROUTE (From Guj)</th>
                                    <td colSpan={3}  contenteditable = "true" onBlur={this.onBlurOperationRouteGUJ.bind(this)}>{(this.state.fleetDeployedRowData.length)?this.state.fleetDeployedRowData[0].operating_route_from_GUJ:""}</td>
                                </tr>
                                <tr>
                                    <th style={{width: "30%"}}>Doing Business Since</th>
                                    <td colSpan={3} contenteditable = "true" onBlur={this.onBlurBusinessSince.bind(this)}>{(this.state.fleetDeployedRowData.length)?this.state.fleetDeployedRowData[0].business_since:""}</td>
                                </tr>
                            </table>
                        </div>

                        <div className='col-xl-12 col-lg-12 col-sm-12 col-md-12 mt-20p'>
                            <div id="fleetDeployedGrid" style={{ width: "100%", height: "200px"}} className={"ag-theme-balham "}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={fleetDeployedCols}
                                    defaultColDef={this.state.defaultColDefNew}
                                    rowData={this.state.fleetDeployedRowData}
                                    enableCharts={false}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    enableRangeSelection={true}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                />
                            </div>
                        </div>

                        <div className='col-xl-12 col-lg-12 col-sm-12 col-md-12 mt-20p'>
                            <div id="fleetDeployedGrid" style={{ width: "100%", height: "350px"}} className={"ag-theme-balham "}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={monthlyInvoicedCols}
                                    defaultColDef={this.state.defaultColDefInvoice}
                                    rowData={this.state.monthlyInvoicedRowData}
                                    enableCharts={false}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    enableRangeSelection={true}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                />
                            </div>
                        </div>

                        <div className='col-xl-12 col-lg-12 col-sm-12 col-md-12 mt-20p'>
                            <div id="fleetDeployedGrid" style={{ width: "100%", height: "280px"}} className={"ag-theme-balham "}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={this.state.volumeColumnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rakewisevolumedata}
                                    enableCharts={false}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    enableRangeSelection={true}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                />
                            </div>
                        </div>

                        <div className='col-xl-12 col-lg-12 col-sm-12 col-md-12 mt-20p'>
                            <div id="fleetDeployedGrid" style={{ width: "100%", height: "280px"}} className={"ag-theme-balham "}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={this.state.rakeColumnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rakewisevolumedatanmg}
                                    enableCharts={false}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    enableRangeSelection={true}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                />
                            </div>
                        </div>
                        
                        <div className='col-xl-12 col-lg-12 col-sm-12 col-md-12 mt-20p'>
                            <div id="fleetDeployedGrid" style={{ width: "100%", height: "250px"}} className={"ag-theme-balham "}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={this.state.ggnColumnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.monthwiseggndata}
                                    enableCharts={false}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    enableRangeSelection={true}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                />
                            </div>
                        </div>

                        <div className='col-xl-12 col-lg-12 col-sm-12 col-md-12 mt-20p'>
                            <div id="fleetDeployedGrid" style={{ width: "100%", height: "250px"}} className={"ag-theme-balham "}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={this.state.mnsrColumnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.monthwisemnsrdata}
                                    enableCharts={false}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    enableRangeSelection={true}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                />
                            </div>
                        </div>

                        <div className='col-xl-12 col-lg-12 col-sm-12 col-md-12 mt-20p'>
                            <div id="fleetDeployedGrid" style={{ width: "100%", height: "250px"}} className={"ag-theme-balham "}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={this.state.gujaratColumnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.monthwisegujaratdata}
                                    enableCharts={false}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    enableRangeSelection={true}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-xl-12 col-lg-12 col-sm-12 col-md-12 mt-20p'>
                            <div id="fleetDeployedGrid" style={{ width: "100%", height: "250px"}} className={"ag-theme-balham "}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={this.state.bangloreColumnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.monthwisebangloredata}
                                    enableCharts={false}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    enableRangeSelection={true}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-xl-12 col-lg-12 col-sm-12 col-md-12 mt-20p'>
                            <div id="fleetDeployedGrid" style={{ width: "100%", height: "200px"}} className={"ag-theme-balham "}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={this.state.ggnmsnrColumnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.lastinvoiceggndata}
                                    enableCharts={false}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    enableRangeSelection={true}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                />
                            </div>
                        </div>

                        <div className='col-xl-12 col-lg-12 col-sm-12 col-md-12 mt-20p'>
                            <div id="fleetDeployedGrid" style={{ width: "100%", height: "200px"}} className={"ag-theme-balham "}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={this.state.gujColumnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.lastinvoicegujaratdata}
                                    enableCharts={false}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    enableRangeSelection={true}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-xl-12 col-lg-12 col-sm-12 col-md-12 mt-20p'>
                            <div id="fleetDeployedGrid" style={{ width: "100%", height: "200px"}} className={"ag-theme-balham "}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={this.state.bnrColumnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.lastinvoicebengalurudata}
                                    enableCharts={false}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    enableRangeSelection={true}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-xl-12 col-lg-12 col-sm-12 col-md-12 mt-20p'>
                            <div id="fleetDeployedGrid" style={{ width: "100%", height: "450px"}} className={"ag-theme-balham "}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={this.state.lastMileColumnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.lastmileperformancedata}
                                    enableCharts={false}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    enableRangeSelection={true}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                />
                            </div>
                        </div>

                    </div>
                    
                   
                </div>
                
            </div>
            <div className={"slide-r "+(this.state.sliderTranslatebulkupload)} style={{overflow:"auto"}}>
                <div className="slide-r-title">
                    <h4>
                        Bulk Upload
                        <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                                
                    </h4>
                </div>
                <div className="slide-r-body" style={{position:"relative"}}>
                    <div className="container-fluid">
                        <form method="POST" id="upform" className="theme-form" onSubmit={this.onSubmitUploadForm.bind(this)}>
                            <div className="form-group mt-20p">
                                <label>Select Month</label>
                                <input type="text" className="selected_date form-control" id="dispatch_dashboard_date" required autoComplete="off" />
                            </div>
                            
                            <div className="form-group mt-20p">
                                <label className="">Upload File</label> 
                                <input type="file" name="uploadFile" onChange={this.changeFileHandler.bind(this)}  className="form-control" required  />
                            </div>

                            {(this.state.uploadedFor == 3)?
                                <div className="form-group mt-20p">
                                    <Select 
                                        isMulti={false}
                                        options={
                                                [{"label": "HARYANA", "value": "HARYANA"},
                                                {"label": "SMG", value: "SMG"}]
                                            }
                                        value={this.state.selectedPlant}
                                        class="form-control"
                                        onChange={(e)=>{this.setState({"selectedPlant": e})}}
                                        closeMenuOnSelect={true}
                                    />
                                </div>
                            :""}

                            {(this.state.uploadedFor == 4)?
                                <div className="form-group mt-20p">
                                    <Select 
                                        isMulti={false}
                                        options={
                                                [{"label": "TVP Banglore", "value": "TVPB"},
                                                {"label": "TVP Nagpur", value: "TVPN"}]
                                            }
                                        value={this.state.selectedPlant}
                                        class="form-control"
                                        onChange={(e)=>{this.setState({"selectedPlant": e})}}
                                        closeMenuOnSelect={true}
                                    />
                                </div>
                            :""}


                            <div className="form-group">
                                <button type="submit" className="btn btn-success">Submit</button>
                            </div>
                        </form>
                        
                        <div className="form-group">
                            {(this.state.uploadedFor == 1)?
                                <a className="btn btn-primary" href={require('../../assets/json/Driver Trainings DEC.csv')} target="_blank">Sample Template</a>

                            :""}
                            {(this.state.uploadedFor == 2)?
                                <a className="btn btn-primary" href={require('../../assets/json/Driver Trainings IDTR.csv')} target="_blank">Sample Template</a>
                            :""}
                            {(this.state.uploadedFor == 3)?
                                <a className="btn btn-primary" href={require('../../assets/json/bulk_upload_transit_returns.csv')} target="_blank">Sample Template</a>
                            :""}
                            {(this.state.uploadedFor == 4)?
                                <a className="btn btn-primary" href={require('../../assets/json/bulk_upload_fleet_performance.csv')} target="_blank">Sample Template</a>
                            :""}
                        </div>
                        <div className="form-group mt-20 col-lx-12 col-lg-12 col-md-12 col-sm-12">
                                <h6 style={{color:"red"}}>Note: <br />
                                * Please use the attached sample to upload Data<br />
                                * Do not alter the header names.</h6>
                        </div>		
                    </div>
                </div>
            </div>
            
            <div className={"dataLoadpage " +(this.state.loadshow)}>
            </div>
            <div className={"dataLoadpageimg " +(this.state.loadshow)} style={{position:"fixed", top: "43%"}}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				
            </div>
              
        );
    }
}


function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	// $('.datetimepicker_mask').datetimepicker({
	// 	//mask:'39-19-9999 29:59',
	// 	format:'d-m-Y'
	// });
	// $('.datetimepicker_date').datetimepicker({
	// 	mask:'39-19-9999',
	// 	format:'d-m-Y',
	// 	timepicker:false
	// });
    // var index  =window.document.getElementsByTagName("script")[1]
    // var script = window.document.createElement("script")
    // script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	// //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	// script.async=true
    // script.defer = true
    // index.parentNode.insertBefore(script,index)
    $("#dispatch_dashboard_date").datepicker( {
        changeMonth: true,
        changeYear: true,
        showButtonPanel: false,
        dateFormat: "mm-yy",
        onClose: function (dateText, inst) {

            //Get the selected month value
            var month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();

            //Get the selected year value
            var year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();

            //set month value to the textbox
            $(this).datepicker('setDate', new Date(year, month, 1));
        },
        beforeShow: function(input, inst) {
            $('#ui-datepicker-div').addClass("dispatch_dashboard_date");
        }
    });
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function rowdSpan(params) {
    if (params.data.loading_point) {
      return 11;
    } else {
      return 1;
    }
}


function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};


function createColumns(rowData, gridTitle){
    console.log(rowData)
    var firstRow = rowData[0];
    var columnDefs = [];
    Object.keys(firstRow).map((e)=>{
        columnDefs.push({
            headerName: e,
            field: e,
            width: 130,
            resizable: true,
            sortable: true
        })
    })
    var masterCols = [
        {"headerName": gridTitle,
        children: columnDefs,
        headerClass:["cellStyleGridHeader", "ag-grid-text-center"],
        }
    ]
    return masterCols;
}
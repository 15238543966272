import React, { Component } from "react";
import CSVFileValidator from 'csv-file-validator';
import moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MenuModule } from "@ag-grid-enterprise/menu";
import Select from "react-select";
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import $ from "jquery";
import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import DrawMap2 from '../common/drawmap2';
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import * as Datetime from 'react-datetime';
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import "@ag-grid-community/core/dist/styles/ag-grid.css";

import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { color } from "highcharts";
import download from 'js-file-download';
import { reverse } from "lodash";
// import { formatDate } from "@fullcalendar/react";
var redirectURL = require("../redirectURL");
var infoBox = require('../common/google-infowindow');

var googleAnalytics = require("../common/googleanalytics");
var map;
var markersArray;
var viewMarkersArr = [];
export default class RakeTrackingScreen extends Component {
  constructor(props) {
    super(props);
    var date2 = new Date();
    var currentDateTime = moment(date2).format("YYYY-MM-DD");
    var startDate1 = moment(date2).subtract(90, 'days').format("YYYY-MM-DD")
    this.state = {
      modules: AllModules,
      eventFormAction: "formAction",
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        width: 160,
        resizable: true,
      },
      eventAction: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      bulkslide: "",
      bulkslide1: "",
      bulkslide2: "",
      bulkslide3: "",
      bulkslide4: "",
      Main_rowid: "",
      slider_header: "",
      slider_type: "",
      files: [],
      files2: [],
      timelinesmarkers: [],
      truck_no: "",
      rake_name: [{ label: '', value: '' }],
      rake_names: [],
      sliderRouteTranslate: '',
      rownode: '',
      baseRouteCoords: [],
      stabling_end_map: "",
      stabling_start_map: "",
      filterlspData: [],
      lsp_name_filter: ({ value: "ALL", label: "ALL" }),
      doc_number: '',

      type: [],
      mapinfo: '',
      force_major: [],
      msil_rake_id: '',
      arrival_at_vicinity: '',
      stabling_start: '',
      stabling_end: '',
      mr_rr_value: '',
      lsp_remarks: "",

      sliderbulkupload: "",
      rowModelhighchartoptionsType: "enterprise",
      frameworkComponents: {
        consignmentActions: ConsignmentActions,
      },
      rowGroupPanelShow: "always",
      statusBar: {

      },
      paginationPageSize: 50,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      startDate: startDate1,
      endDate: currentDateTime,
      animateRows: true,
      dept_code: "",
      pagetitle: "",
      csvData: 0,
      overly: "show-n",
      loadshow: "show-n",
      pagetitle: "",
      eventGridAction: "gridAction",
      csvcontent: [],
      file: "",
      batch_name: "",
      cluster_breaks: "2",
      cluster_radius: "0.5",
      notification_emails: "",

      bulkslidebulk: '',
      newValue: "",
      rowData: [],
      rowId: "",
      show: false,
      basicTitle: "",
      basicType: "",

    };
    this.changeFileHandlerPOD = this.changeFileHandlerPOD.bind(this);
    // this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
    const currentDate = new Date();

    // Get the current month as a two-digit string (e.g., '01' for January)
    const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  }
  onChangeRakeName = (rake_name) => {
    this.setState(
      { rake_name },
      () => console.log(`Rake Name selected:`, this.state.rake_name)
    );

  }
  async componentDidMount() {
    loadDateTimeScript();
    // console.log('hellllo')
    // document.body.addEventListener("click", this.handleClickOutside);
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    var params = {
      start_date: this.state.startDate,
      end_date: this.state.endDate,
      //  lsp_name : this.state.lsp_name_filter.value
    }
    if (localStorage.getItem("transportercode") != undefined && localStorage.getItem("transportercode") != null && localStorage.getItem("transportercode") != "") {
      params.lsp_code = localStorage.getItem("transportercode")
    }
    await redirectURL
      .post("/consignments/totalraketrackingData", params)
      .then(async (response) => {
        var lsp_name = []
        lsp_name.push({ value: "ALL", label: "ALL" })
        // console.log(response.data, "140----");
        var lspnameData = response.data.lsp_names.map((ele) => {
          lsp_name.push({ value: ele, label: ele })
        })
        this.setState({
          rowData: reverse(response.data.data),
          filterlspData: lsp_name,
          mapinfo: response.data,
          loadshow: "show-n",
          overly: "show-n",
        })
      }).catch((e) => {
        console.log(e);
      });

    await redirectURL.post("/consignments/stablingrakenames", {
      transportercode: localStorage.getItem("transportercode")
    })
      .then(async (resp) => {
        // console.log(resp.data,"New Change")
        var allrakes = []
        try {
          if (resp.data.data.length > 0) {
            resp.data.data.map((item) => {
              // console.log("Rake Name", item)
              allrakes.push({ value: item.truck_no, label: item.truck_no })
            })
          }
        }
        catch (e) {
          allrakes = resp.data.rakes
        }

        //console.log("allrakes ",allrakes)
        await this.setState({
          rake_names: allrakes
        });
      })


  }
  onShowRouteDiv = (e) => { }
  closeAlert = () => {
    this.setState({
      show: false,
    });
  };


  updateState(key, value) {
    // console.log(value.name,'value',key.value);
    this.setState({
      [value.name]: key.value,
    });
  }




  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      bulkslide: "",
      bulkslide1: "",
      bulkslide2: "",
      bulkslide3: "",
      bulkslide4: '',
      bulkslidebulk: '',
      arrival_at_vicinity: '',
      stabling_start: '',
      stabling_end: '',
    });
    $("#uploadFile").val("")
    $("#reason").val("")
  }
  reset() {
    window.location.reload()
  }

  // handleClickOutside = (event) => {
  //   if (!event.target.closest(".slide-r")) {
  //     this.onClickHideAll();
  //   }
  // };



  resetUpload = () => {
    this.setState({
      bulkslidebulk: '',
      overly: 'show-n',
      file: '',
      bulkMarkData: [],
      // bulkslide: "",
      // bulkslide1: "",
    })
    $('#upform')[0].reset();
    document.getElementById("upform").reset();
  }


  async onCellClicked(event) {
    if (event.colDef.field == "approve_button") {
      if (event.data.update_msil_check == 1 || event.data.update_msil_check == "1") {
        this.setState({
          bulkslide3: "slider-translate-30p",
          overly: "show-n",
          slider_type: "approve",
          Main_rowid: event.data._id,
          slider_header: "Update Approve Details"
        })
      } else {
        this.setState({
          show: true,
          basicTitle: "Please Update Required Information",
          basicType: "warning"
        })
      }
    }
    if (event.colDef.field == "reject_button") {
      if (event.data.update_msil_check == 1 || event.data.update_msil_check == "1") {
        this.setState({
          bulkslide3: "slider-translate-30p",
          overly: "show-n",
          slider_type: "reject",
          Main_rowid: event.data._id,
          slider_header: "Update Reject Details"
        })
      } else {
        this.setState({
          show: true,
          basicTitle: "Please Update Required Information",
          basicType: "warning"
        })
      }
    }
    if (event.colDef.field == "edit") {
      // console.log(event.data,'editvalues')
      var edit_arrival_at_vicinity = moment.parseZone(new Date(event.data.arrival_at_vicinity)).format("DD-MM-YYYY")
      var edit_stabling_end = moment.parseZone(new Date(event.data.stabling_end)).format("DD-MM-YYYY")
      var edit_stabling_start = moment.parseZone(new Date(event.data.stabling_start)).format("DD-MM-YYYY")
      this.setState({
        rowId: event.data._id,
        doc_number: event.data.doc_number,
        rake_name: ({ value: event.data.rake_name, label: event.data.rake_name }),
        type: ({ value: event.data.type, label: event.data.type }),
        msil_rake_id: event.data.msil_rake_id,
        mr_rr_value: event.data.mr_rr_value,
        lsp_remarks: event.data.lsp_remarks,
        bulkslide1: "slider-translate-30p",
        overly: "show-m",
        arrival_at_vicinity : moment.parseZone(new Date(event.data.arrival_at_vicinity)).format("YYYY-MM-DD"),
        stabling_start : moment.parseZone(new Date(event.data.stabling_start)).format("YYYY-MM-DD"),
        stabling_end : moment.parseZone(new Date(event.data.stabling_end)).format("YYYY-MM-DD")
      })
      // $("#edit_arrival_at_vicinity").val(edit_arrival_at_vicinity)
      // $("#edit_stabling_start").val(edit_stabling_start)
      // $("#edit_stabling_end").val(edit_stabling_end)
      console.log(this.state.stabling_start,'start',this.state.stabling_end)
    }
    if (event.colDef.field == "update") {
      this.setState({
        rowId: event.data._id,
        doc_number: event.data.doc_number,
        rake_name: event.data.rake_name,
        type: ({ value: event.data.type, label: event.data.type }),
        msil_rake_id: event.data.msil_rake_id,
        arrival_at_vicinity: formatDate(event.data.arrival_at_vicinity),
        stabling_start: formatDate(event.data.stabling_start),
        stabling_end: formatDate(event.data.stabling_end),
        mr_rr_value: event.data.mr_rr_value,
        lsp_remarks: event.data.lsp_remarks,
        bulkslide2: "slider-translate-30p",
        overly: "show-m"
      })
    }
    if (event.colDef.field == "stabling_memo_ir") {
      var fileName = event.data.stabling_memo_ir
      // console.log(fileName,"496")
      if (fileName != "") {
        var reqparams = {
          uploadOtherFileName: fileName,
        }
        redirectURL.post("/consignments/downloadmemoIRfile", reqparams, {
          responseType: 'blob'
        }).then((response) => {
          // console.log("download response hub",response)
          download(response.data, fileName[0].name)

        }).catch(function (error) {
          console.log(error);
        })
      }
    }
    if (event.colDef.field == "mm_rr_memo") {
      var fileName = event.data.mm_rr_memo
      // console.log(fileName,"496")
      if (fileName != "") {
        var reqparams = {
          uploadOtherFileName: fileName,
        }
        redirectURL.post("/consignments/downloadmemoIRfile", reqparams, {
          responseType: 'blob'
        }).then((response) => {
          // console.log("download response hub",response)
          download(response.data, fileName[0].name)

        }).catch(function (error) {
          console.log(error);
        })
      }
    }
    if (event.colDef.field == "_id") {
      // console.log('hello in id')

      this.setState({
        // sliderRouteTranslate:"slider-translate-60p",
        //   showDiv:'show-m',
        rake_name: event.data.rake_name,
        // truck_no:"ALVA16",
        stabling_start_map: event.data.stabling_start,
        stabling_end_map: event.data.stabling_end
      })
      this.formHandler()
    }
  }
  onCloseUploadDiv = () => {
    this.setState({
      uploadDivWidth: '0%',
      sliderTranslate: "",
      tabsliderTranslate: '',
      showDiv: 'show-n',
      sliderTranslatesidebar: "",
      sliderCommentTranslate: "",
      uploadFile: '',
      file: '',
      csvmessage: '',
      csverrmessage: '',
      overly: 'show-n',
      loadshow: 'show-n',
      slideuploadeway: ""
    });
  }
  handlerStartDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    var month = ("0" + (d.getMonth() + 1)).slice(-2);
    var day = ("0" + d.getDate()).slice(-2);
    var startdate = d.getFullYear() + "-" + month + "-" + day;
    if (this.state.endDate != undefined && this.state.endDate != "") {
      // console.log(startdate,"startdate")
      // console.log(this.state.endDate,"endDate")
      var startDate = new Date(startdate);
      var endDate = new Date(this.state.endDate);
      var startTime = startDate.getTime();
      var endTime = endDate.getTime();
      // console.log(startTime,endTime);
      if (startTime > endTime) {
        
        this.setState({
					show: true,
					basicTitle: 'To Date should be greater than From Date',
					basicType: "danger",
					loadshow: 'show-n'
				});
      }
      else {
        this.setState({
          startDate: startdate
        });
      }
    }
    else {
      this.setState({
        startDate: startdate
      });
    }
    //let value = event.target.value;
    //this.setState({'startDate':value});
  }
  handlerEndDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    var month = ("0" + (d.getMonth() + 1)).slice(-2);
    var day = ("0" + d.getDate()).slice(-2);
    var edate = d.getFullYear() + "-" + month + "-" + day;

    if (this.state.endDate != undefined && this.state.endDate != "") {
      // console.log(this.state.startDate,"startdate")
      // console.log(edate,"endDate")
      var startDate = new Date(this.state.startDate);
      var endDate = new Date(edate);
      var startTime = startDate.getTime();
      var endTime = endDate.getTime();
      // console.log(startTime,endTime);
      if (startTime > endTime) {
        this.setState({
					show: true,
					basicTitle: 'To Date should be greater than From Date',
					basicType: "danger",
					loadshow: 'show-n'
				});
      }
      else {
        this.setState({
          endDate: edate
        });
      }
    }
    else {
      this.setState({
        endDate: edate
      });
    }
  }


  onloadpagedata1 = async (event) => {
    // console.log(this.state.startDate,this.state.endDate,'date Console')
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    var lsp_name = this.state.lsp_name_filter.value
    var startDate = this.state.startDate
    var endDate = this.state.endDate
    var transporter_code = localStorage.getItem("transportercode")
    var params = {
      start_date: this.state.startDate,
      end_date: this.state.endDate,
      lsp_name: this.state.lsp_name_filter.value
    }
    if (transporter_code != undefined && transporter_code != null && transporter_code != "") {
      params.lsp_code = transporter_code
    }
    redirectURL
      .post("/consignments/filterforrequestdate", params)
      .then((response) => {
        if (response.data.message == "success") {
          this.setState({
            rowData: reverse(response.data.filterData),
            loadshow: "show-n",
            overly: "show-n",
          })
        } else {
          this.setState({
            rowData: [],
            loadshow: "show-n",
            overly: "show-n",
            basicTitle: response.data.message,
            basicType: "warning",
            show: true
          })
        }

      })

  }

  AddLSPMSILDetails() {
    var form = document.getElementById("initialAddingDetails");
    if (form) {
      form.reset();
    }
    this.setState({
      rake_name: [],
      type: [],
      arrival_at_vicinity: '',
      stabling_start: '',
      stabling_end: '',
      bulkslide: "slider-translate-30p",
      overly: "show-m"
    })
  }

  handleSelectChange(e, name) {
    // console.log(e,'name',name.value)
    this.setState({
      [e]: name
    });
  }
  editchanges(e) {
    // console.log(e.target.name,'name',e.target.value)
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  async onClickUpdateDetails(e) {
    e.preventDefault();
    var formData = new FormData();
    formData.append('files', this.state.files[0])
    formData.append('files2', this.state.files2[0])
    var doc_number = $("#doc_number").val()
    var rake_name = this.state.rake_name.value
    var type = this.state.type.value
    var msil_rake_id = $("#msil_rake_id").val()
    var arrival_at_vicinity = formatDate(this.state.arrival_at_vicinity)
    var stabling_start = formatDate(this.state.stabling_start)
    var stabling_end = formatDate(this.state.stabling_end)
    var mr_rr_value = $("#mr_rr_value").val()
    var lsp_remarks = $("#lsp_remarks").val()

    console.log((arrival_at_vicinity),this.state.arrival_at_vicinity,'arrival_at_vicinity',stabling_start,'stabling_start',stabling_end)
    formData.append('doc_number', doc_number)
    formData.append('rake_name', rake_name)
    formData.append('type', type)
    formData.append('msil_rake_id', msil_rake_id)
    formData.append('arrival_at_vicinity', arrival_at_vicinity)
    formData.append('stabling_start', stabling_start)
    formData.append('stabling_end', stabling_end)
    formData.append('mr_rr_value', mr_rr_value)

    formData.append('lsp_remarks', lsp_remarks)

    formData.append('updated_by', localStorage.getItem("username"))


    if (arrival_at_vicinity != "" && arrival_at_vicinity != undefined && stabling_start != "" && stabling_start != undefined && stabling_end != "" && stabling_end != undefined && type != "" && type != undefined && this.state.files[0] !== undefined && this.state.files[0] !== "" && this.state.files[0] != [] && this.state.files[0] != null && this.state.files2[0] != undefined && this.state.files2[0] != "" && this.state.files2[0] != [] && this.state.files2[0] != null) {

      // console.log(formData,'paramsonSubmit')
      var rowId;
      await redirectURL
        .post("/consignments/updateRakeTracking", formData)
        .then(async (response) => {
          // console.log( response.data.data.insertedId,' response.data')
          if (response.data.message == "success") {
            rowId = response.data.data.insertedId

            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "Successfully Updated",
              bulkslide: '',
              type: [],
              files: [],
              files2: [],
              arrival_at_vicinity: '',
              
              stabling_end: '',
            })
            $("#uploadFile2").val("")

            await redirectURL
              .post("/consignments/fetchingRackNumber", { msil_rake_id: msil_rake_id })
              .then(async (response) => {
                //     console.log(response.data.sndloadingdata, 'responseLoading');
                // console.log(response.data.sndloadingdata[0].imm_for_code,response.data.sndloadingdata[0].region,response.data.sndloadingdata[0].transporter_code,response.data.sndloadingdata[0].transporter_name,'details')
                // Check if the response data exists and is an array
                if (Array.isArray(response.data.sndloadingdata) && response.data.sndloadingdata.length > 0) {
                  const dataArray = response.data.sndloadingdata;
                  var firstInvoiceDate = dataArray[0].invoice_date; // First index of invoice_date
                  var lastInvoiceDate = dataArray[dataArray.length - 1].invoice_date; // Last index of invoice_date
                //  console.log(type,'typetypetype',this.state.stabling_start)
                  if(type == "Dedicated"){
                   var para = {
                    start_date1 : formatDate1(this.state.stabling_start),
                    end_date1 : formatDate1(lastInvoiceDate)
                   } 
                  }else{
                    var para = {
                      start_date1 : formatDate1(firstInvoiceDate),
                      end_date1 : formatDate1(lastInvoiceDate)
                    }
                   
                  }
                  // console.log(para,'para',type)
                 await redirectURL
                  .post("/consignments/calculatingRakeStablingDaysandAmount",para)
                  .then(async (resp1) => {
                    // console.log(resp1,'resp111111')
                  var stabling_days = resp1.data.data.stabling_days
                  var claimed_amount = resp1.data.data.claimed_amount
                  // console.log('First Invoice Date:', resp1.data.data.claimed_amount);
                  // console.log('Last Invoice Date:', lastInvoiceDate);

                  // Perform actions with firstInvoiceDate and lastInvoiceDate as needed
                  var fetchedData = {
                    rowId: rowId,
                    truck_no: response.data.sndloadingdata[0].truck_no,
                    imm_for_code: response.data.sndloadingdata[0].imm_for_code,
                    region: response.data.sndloadingdata[0].region,
                    transporter_code: response.data.sndloadingdata[0].transporter_code,
                    transporter_name: response.data.sndloadingdata[0].transporter_name,
                    invoicing_start: firstInvoiceDate,
                    invoicing_end: lastInvoiceDate,
                    claimed_amount:claimed_amount,
                    stabling_days:stabling_days
                  }
                  // console.log(fetchedData,'fetchedData')
                  redirectURL
                    .post("/consignments/fetchedRackData", fetchedData)
                    .then(async (response) => {

                    })
                  })
                  $("#doc_number").val('')
                  this.setState({ rake_name: [{ label: '', value: '' }], arrival_at_vicinity: '',
                  stabling_start: '',
                  stabling_end: '', })
                  $("#rake_name").val('')
                  $("#msil_rake_id").val('')
                  // $("#arrival_at_vicinity").val('')
                  // $("#stabling_start").val('')
                  // $("#stabling_end").val('')
                  $("#mr_rr_value").val('')
                  $("#lsp_remarks").val('')
                  $("#uploadFile").val("")
                  await this.componentDidMount()
                }
              
              });
          } else {
            this.setState({
              show: true,
              basicType: "warning",
              basicTitle: response.data.message,
              bulkslide: '',
              arrival_at_vicinity: '',
              stabling_start: '',
              stabling_end: '',
              type: [],
              files: [],
              files2: [],
            })
          }
        })


      $("#doc_number").val('')
      this.setState({ rake_name: [{ label: '', value: '' }] })
      $("#rake_name").val('')
      $("#msil_rake_id").val('')
      // $("#arrival_at_vicinity").val('')
      // $("#stabling_start").val('')
      // $("#stabling_end").val('')
      $("#mr_rr_value").val('')
      $("#lsp_remarks").val('')
      $("#uploadFile").val("")
      await this.componentDidMount()
      var form = document.getElementById("initialAddingDetails");
      if (form) {
        form.reset();
      }
    } else {
      alert("All Fields are mandatory!")
    }

  }

  async onClickEditDetails(e) {
    e.preventDefault();
    var doc_number = this.state.doc_number
    var rake_name = this.state.rake_name.value
    var type = this.state.type.value
    var msil_rake_id = this.state.msil_rake_id
    var arrival_at_vicinity = this.state.arrival_at_vicinity
    var stabling_start = this.state.stabling_start
    var stabling_end = this.state.stabling_end
    var mr_rr_value = this.state.mr_rr_value
    var lsp_remarks = this.state.lsp_remarks


    var params = {
      doc_number: doc_number,
      rake_name: rake_name,
      type: type,
      msil_rake_id: msil_rake_id,
      arrival_at_vicinity: formatDate1(arrival_at_vicinity),
      stabling_start: formatDate1(stabling_start),
      stabling_end: formatDate1(stabling_end),
      mr_rr_value: mr_rr_value,
      lsp_remarks: lsp_remarks,
      rowId: this.state.rowId
    }
    if (arrival_at_vicinity != "" && arrival_at_vicinity != undefined && stabling_start != "" && stabling_start != undefined && stabling_end != "" && stabling_end != undefined && type != "" && type != undefined) {

      // console.log(params,'paramsonSubmitedit')
      var rowId;
      await redirectURL
        .post("/consignments/EditRakeTracking", params)
        .then(async (response) => {
          // console.log( response.data,' response.data in edit')
          //  rowId = response.data.data.insertedId
          if (response.data.message == "success") {
            this.setState({
              // rowData : response.data,
              show: true,
              basicType: "success",
              basicTitle: "Successfully Updated",
              bulkslide1: '',
              bulkslide2: '',
              type: [],

            })
          } else {
            this.setState({
              // rowData : response.data,
              show: true,
              basicType: "warning",
              basicTitle: response.data.message,
              bulkslide1: '',
              bulkslide2: '',
              type: [],

            })
          }

          await redirectURL
            .post("/consignments/fetchingRackNumber", { msil_rake_id: this.state.msil_rake_id })
            .then(async (response) => {
              //     console.log(response.data.sndloadingdata, 'responseLoading');
              // console.log(response.data.sndloadingdata[0].imm_for_code,response.data.sndloadingdata[0].region,response.data.sndloadingdata[0].transporter_code,response.data.sndloadingdata[0].transporter_name,'details')
              // Check if the response data exists and is an array
              if (Array.isArray(response.data.sndloadingdata) && response.data.sndloadingdata.length > 0) {
                const dataArray = response.data.sndloadingdata;
                var firstInvoiceDate = dataArray[0].invoice_date; // First index of invoice_date
                var lastInvoiceDate = dataArray[dataArray.length - 1].invoice_date; // Last index of invoice_date
                // console.log(this.state.stabling_start,'this.state.stabling_start')
                if(type == "Dedicated"){
                  var para = {
                   start_date1 :formatDate1(this.state.stabling_start),
                   end_date1 : formatDate1(lastInvoiceDate)
                  } 
                 }else{
                  var para = {
                    start_date1 : formatDate1(firstInvoiceDate),
                    end_date1 : formatDate1(lastInvoiceDate)
                  }
                  
                 }
                 console.log(para,'para',type)
                 redirectURL
                 .post("/consignments/calculatingRakeStablingDaysandAmount",para)
                 .then(async (resp1) => {
                  //  console.log(resp1,'resp111111')
                   var stabling_days = resp1.data.data.stabling_days
                   var claimed_amount = resp1.data.data.claimed_amount
                   
                // console.log('First Invoice Date:', resp1.data.data.claimed_amount);
                // console.log('Last Invoice Date:', lastInvoiceDate);

                // Perform actions with firstInvoiceDate and lastInvoiceDate as needed
                var fetchedData = {
                  rowId: this.state.rowId,
                  truck_no: response.data.sndloadingdata[0].truck_no,
                  imm_for_code: response.data.sndloadingdata[0].imm_for_code,
                  region: response.data.sndloadingdata[0].region,
                  transporter_code: response.data.sndloadingdata[0].transporter_code,
                  transporter_name: response.data.sndloadingdata[0].transporter_name,
                  invoicing_start: firstInvoiceDate,
                  invoicing_end: lastInvoiceDate,
                  claimed_amount:claimed_amount,
                  stabling_days:stabling_days
                }
                // console.log(fetchedData,'fetchedData')
                redirectURL
                  .post("/consignments/fetchedRackData", fetchedData)
                  .then(async (response) => {

                  })
                })
                await this.componentDidMount()
              }
            });
          await this.componentDidMount()
        })

    } else {
      alert("All Fields are mandatory!")
    }

  }

  async onClickUpdateTransporterDetails(e) {
    e.preventDefault();
    // var msil_verifier = $("#msil_verifier").val()
    var after_approval_amount = $("#after_approval_amount").val()
    var force_major = this.state.force_major.value
    var msil_remarks = $("#msil_remarks").val()


    var params = {
      msil_verifier: localStorage.getItem("username"),
      after_approval_amount: after_approval_amount,
      force_major: force_major,
      msil_remarks: msil_remarks,
      rowId: this.state.rowId

    }
    if (after_approval_amount != "" && after_approval_amount != undefined && force_major != "" && force_major != undefined && msil_remarks != "" && msil_remarks != undefined) {

      // console.log(params,'paramsonSubmit')
      var rowId;
      await redirectURL
        .post("/consignments/updateTransporterRakeTracking", params)
        .then(async (response) => {

          this.setState({
            bulkslide1: '',
            bulkslide2: '',
            force_major: [],
          })
          // $("#msil_verifier").val('')
          $("#after_approval_amount").val('')
          $("#msil_remarks").val('')
          await this.componentDidMount()
        })

    } else {
      alert("All Fields are mandatory!")
    }

  }
  onClickApproveandRejectDetails = (e) => {
    e.preventDefault()
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    })
    var reason = $("#reason").val()
    if (this.state.slider_type == "approve") {
      var cnf = window.confirm("Are you sure! Do you really want to approve?")
      if (cnf == true) {
        var params = {
          rowId: this.state.Main_rowid,
          approve_reject_status: "Approved",
          approve_value: 1,
          type: "approve_button",
          approved_by: localStorage.getItem("username"),
          buttons_enable: false,
          reason: reason
        }
        redirectURL.post("/consignments/updateRakeTracking1", params)
          .then(async (response) => {
            await this.componentDidMount()
            this.setState({
              loadshow: "show-n",
              overly: "show-n",
              bulkslide3: "",
              basicTitle: "Approved Successfully",
              basicType: "success",
              show: true
            })
            $("#reason").val("")
          })
      } else {
        this.setState({
          loadshow: "show-n",
          overly: "show-n",
          bulkslide3: "",
        })
        $("#reason").val("")
      }

    } else {
      var cnf = window.confirm("Are you sure! Do you really want to reject?")
      if (cnf == true) {
        var params = {
          rowId: this.state.Main_rowid,
          approve_reject_status: "Rejected",
          reject_value: 1,
          type: "reject_button",
          rejected_by: localStorage.getItem("username"),
          buttons_enable: false,
          reason: reason
        }
        redirectURL.post("/consignments/updateRakeTracking1", params)
          .then(async (response) => {
            await this.componentDidMount()
            this.setState({
              loadshow: "show-n",
              overly: "show-n",
              bulkslide3: "",
              basicTitle: "Rejected Successfully",
              basicType: "success",
              show: true
            })
            $("#reason").val("")
          })
      } else {
        this.setState({
          loadshow: "show-m",
          overly: "show-m",
          bulkslide3: "",
        })
        $("#reason").val("")
      }

    }
  }

  changeFileHandlerPOD = async (e) => {
    // console.log(e.target.files,e.target.files[0],'change')
    var files = Array.from(e.target.files);
    this.setState({
      files: files
    })
  }
  changeFileHandlerPOD2 = async (e) => {
    // console.log(e.target.files,e.target.files[0],'change')
    var files = Array.from(e.target.files);
    this.setState({
      files2: files
    })
  }
  changeHandler = lsp_name_filter => {
    this.setState(
      { lsp_name_filter },
      () => console.log(`Movement Option selected:`, this.state.lsp_name_filter)
    );

  }


  onCloseRouteDiv = () => {
    this.setState({
      uploadDivWidth: '0%',
      sliderRouteTranslate: "",
      sliderCommentTranslate: "",
      sliderSegmentTranslate: "",
      showDiv: 'show-n',
      uploadFile: '',
      file: '',
      csvmessage: '',
      csverrmessage: '',
      overly: 'show-n',
      logsSLider: ''
    });
  }

  formHandler = async () => {
    this.setState({
      loadshow: 'show-m'
    })
    if (googleAnalytics.page.enableGA) {
      // let pageTitle = this.state.pageTitle;
      let eventOptions = {
        "category": "Rake Tracking",
        "action": this.state.eventFormAction,
        "label": googleAnalytics.page.action.formSubmittedGenerate,
      }
      googleAnalytics.logEvent(eventOptions);
    }
    // console.log($("#inputTruck").val())

    var formdata = {
      truck_no: this.state.rake_name,
      startDate: getHyphenYYYYMMDDHHMMSS(this.state.stabling_start_map),
      // startDate:"2023-11-01 00:00:00",
      endDate: getHyphenYYYYMMDDHHMMSS(this.state.stabling_end_map),
      frequency: this.state.frequency
    }
    // console.log("formdata88888 ", formdata)
    await redirectURL.post("/gmap", formdata, {
      headers: {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin':"*",
        // 'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE, TOKEN',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
      }
    })
      //.then(res => res.json())
      .then(
        async (result) => {
          //  console.log("result ",result.data);
          var jsondata = result.data;
          //  console.log(JSON.parse(jsondata.data))
          if (result.data.status == 'failure') {
            this.setState({
              show: true,
              basicTitle: 'No GPS data available for this period',
              basicType: "danger",
              loadshow: 'show-n',
              // rowData:null,
              // mapinfo:'',
            })
          }
          else {
            var coordinates = JSON.parse(jsondata.data);
            //console.log("truckno ", this.props.truckno)
            //console.log("coordinates ", coordinates)
            var dataarr = []
            //console.log("coordinates ", coordinates)
            coordinates.coords.map((item) => {

              dataarr.push({
                "truck_no": item.truck_no,
                "lat": item.lat,
                "lng": item.lng,
                "speed": item.speed,
                "stime": item.stime,
                //"state":item.state,
                //"area":item.area,
                //"city":item.city,
                //"location_type":item.location_type,
                "dist_from_prev_point": item.dist_from_prev_point,
                "received_on": item.received_on,
                "time_from_prev_point": item.time_from_prev_point,
                "area": item.area,
                "state": item.state
              })
            });
            //console.log("Modified ", dataarr)
            this.setState({
              // rowData:dataarr,
              mapinfo: JSON.parse(jsondata.data),
              loadshow: 'show-n',
              tolls: JSON.parse(jsondata.data).tolls,
              sliderRouteTranslate: "slider-translate-60p",


            })
            this.renderMap()

          }

        }
      )
      .catch(function (error) {
        console.log(error);
      });
  }
  renderMap = () => {
    // console.log("calling render map")
    loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMap");
    window.initMap = this.initMap
  }
  initMap = () => {
    //console.log("arr ",arr)
    var currentwindow;
    var dealer = '';
    var tolls = this.state.tolls;

    var arr = [];
    var jsondata = this.state.mapinfo;
    var locations = jsondata.breaks;
    var arr = jsondata.coords;
    if (arr.length > 0 && typeof arr.length != undefined) {
      var lt = arr[0].lat;
      var ln = arr[0].lng;
    }
    else {
      var lt = 28.4519751;
      var ln = 77.0310713;
    }
    var routeinfo;
    try {
      var mapOptions = {
        zoom: 4.8,
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true,
        labels: true,
        mapTypeControlOptions: {
          mapTypeIds: ['satellite', 'roadmap'],
        },
        center: new window.google.maps.LatLng(20.5937, 78.9629),
        mapTypeId: window.google.maps.MapTypeId.ROADMAP
      };

    }
    catch (e) {
      var mapOptions = {
        zoom: 13,
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true,
        labels: true,
        mapTypeControlOptions: {
          mapTypeIds: ['satellite', 'roadmap'],
        },
        center: new window.google.maps.LatLng(28.4519751, 77.0310713),
        mapTypeId: window.google.maps.MapTypeId.ROADMAP
      };

    }
    try {
      map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
    } catch (e) { }

    var bounds = new window.google.maps.LatLngBounds();
    bounds.extend(new window.google.maps.LatLng(lt, ln));
    // Create our info window content
    var currentinfowindow = null;
    var line = new window.google.maps.Polyline(
      {
        map: map,
        strokeColor: '#157254',
        strokeOpacity: 1.0,
        strokeWeight: 2.5,
        icons: [{
          // icon: {
          // 		path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          // 		strokeColor:'#ff8c52',
          // 		fillColor:'#ff8c52',
          // 		fillOpacity:1,
          // 		strokeWeight: 2
          // },
          repeat: '100px',
          path: []
        }]
      });
    if (arr.length > 0) {
      var allpoints = [];

      var arr1 = [];
      //console.log("Total ",ratio);
      if (this.state.timelinesmarkers.length > 0) {
        arr1 = this.state.timelinesmarkers.filter(function (value, index, arr) {
          return (index % ratio == 0);
        });


        //var arr1 = this.state.timelinesmarkers;
      }

      var arr2 = [];
      var darr = [];
      if (arr.length > 0) {
        darr = arr.filter(function (value, index, arr) {
          //console.log("Vale ",value)
          return (value.dist_from_prev_point > 0);
        });
        if (darr.length < 2000) {
          var ratio = 1;
        }
        else {
          var ratio = 20;
        }
        //console.log("darr.length ", darr.length)
        arr2 = darr.filter(function (value, index, darr) {
          return (index % ratio == 0);
        });


        //var arr1 = this.state.timelinesmarkers;
      }
      // view markers code
      if (arr2.length > 0) {
        markersArray = arr2;
        // //console.log("allpoints ", allpoints)

        for (var a = 0; a < arr2.length; a++) {
          //console.log(arr2[a],"arr2[a]");
          var marker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(arr2[a].lat, arr2[a].lng),
            icon: {
              path: window.google.maps.SymbolPath.CIRCLE,
              strokeColor: '#157254',
              fillColor: '#157254',
              fillOpacity: 1,
              strokeWeight: 5,
              scale: 1,
            },
            map: map,
            content: arr2[a]
          });
          marker.setVisible(false);
          // 	//console.log("Arr ",arr1[a])
          window.google.maps.event.addListener(marker, 'mouseover', (function (marker) {
            return function () {
              //console.log("Arr ",marker)
              var contentarr = []
              //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
              var header = "Current Status"
              contentarr.push({ "key": "Time ", "value": getDDMMYYYYHHMMSS(marker.content.stime) })
              contentarr.push({ "key": "Speed ", "value": marker.content.speed + " KMPH" })

              var contentString = infoBox("", header, contentarr, '')


              infowindow.setContent(contentString);
              currentwindow = infowindow;
              infowindow.open(map, marker);
              //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
            }
          })(marker));
          window.google.maps.event.addListener(marker, 'mouseout', function () {
            infowindow.close();
          });

          viewMarkersArr.push(marker);

        }
      }
      var routeinfo = jsondata.route_details;
      var icon_cnt = 100
      for (var i = 0; i < arr2.length - 1; i++) {
        var locationLatLng = [];
        locationLatLng.push(new window.google.maps.LatLng(arr2[i].lat, arr2[i].lng));
        locationLatLng.push(new window.google.maps.LatLng(arr2[i + 1].lat, arr2[i + 1].lng));
        if (icon_cnt % 10 == 0) {
          var lineicon = [{
            // icon: {
            //         path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            //         strokeColor:'#FF0000',
            //         fillColor:'#FF0000',
            //         fillOpacity:1,
            //         strokeWeight: 2
            // },
            repeat: '100px',
            path: []
          }];
        } else { lineicon = [] }
        icon_cnt = icon_cnt + 1
        var consignments_missing_route_line = new window.google.maps.Polyline({
          map: map,
          path: locationLatLng,
          strokeColor: '#157254',
          strokeOpacity: 1.0,
          strokeWeight: 2.5,
          icons: lineicon
        });
        // console.log(arr2[i].msgtype)
        if (arr2[i].msgtype == "G") {

          consignments_missing_route_line.setOptions({
            strokeColor: "#452a68",
            strokeWeight: 5.5,
          })
        }
        bounds.extend(new window.google.maps.LatLng(arr2[i].lat, arr2[i].lng));
      }



      var infowindow = new window.google.maps.InfoWindow();
      var marker, l;

      for (l = 0; l < locations.length; l++) {
        marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
          icon: require('../../assets/icons/cf.png'),
          map: map,
        });

        window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
          return function () {
            //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time)+")</p>";

            var contentarr = []
            //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
            var header = "Break";
            try {
              if (typeof this.props.mapFor != 'undefined') {
                var timeinsecs = moment.parseZone(locations[l].break_start).format("x") - moment.parseZone(locations[l].break_start).format("x")
                contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
                contentarr.push({ "key": "Break End", "value": locations[l].break_end })
                contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
              }
              else {
                contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
                contentarr.push({ "key": "Break End", "value": locations[l].break_end })
                contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
              }
            }
            catch (e) {
              contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
              contentarr.push({ "key": "Break End", "value": locations[l].break_end })
              contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
            }


            var contentString = infoBox(marker.icon, header, contentarr, '')

            infowindow.setContent(contentString);
            currentwindow = infowindow;
            infowindow.open(map, marker);
            //console.log(marker.position.toJSON());
            // console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
          }
        })(marker, l));
      }
      marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
        icon: require('../../assets/icons/track_start.png'),
        map: map,
      });
      window.google.maps.event.addListener(marker, 'click', (function (marker) {
        return function () {
          //	console.log(clusters[0])

          //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
          var contentarr = []
          //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
          var header = "Starting Point"
          contentarr.push({ "key": "Started at", "value": routeinfo.start_time })

          var contentString = infoBox(marker.icon, header, contentarr, '')

          infowindow.setContent(contentString);
          currentwindow = infowindow;
          infowindow.open(map, marker);
          // console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
        }
      })(marker));
      marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
        icon: require('../../assets/icons/traindeliver.png'),
        map: map,
      });
      window.google.maps.event.addListener(marker, 'click', (function (marker) {
        return function () {
          //	console.log(clusters[0])

          //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
          var contentarr = []
          //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
          var header = "End Point"
          contentarr.push({ "key": "End at", "value": routeinfo.end_time })

          var contentString = infoBox(marker.icon, header, contentarr, '')

          infowindow.setContent(contentString);
          infowindow.setContent(contentString);
          currentwindow = infowindow;
          infowindow.open(map, marker);
          // console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
        }
      })(marker));

    }
    try {
      if (tolls.length > 0) {
        var infowindow = new window.google.maps.InfoWindow();
        var l;
        tolls.map(function (e, index) {
          var tollMarker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(e.lat, e.lon),
            icon: require('../../assets/icons/barrier.png'),
            map: map,
          });
          window.google.maps.event.addListener(tollMarker, 'mouseover', (function (tollMarker, index) {
            return function () {
              var contentarr = []
              var header = "Toll - " + e.name
              contentarr.push({ "key": "Address", "value": e.location })
              var contentString = infoBox(tollMarker.icon, header, contentarr)
              infowindow.setContent(contentString);
              var currentwindow = infowindow;
              infowindow.open(map, tollMarker);
            }
          })(tollMarker, index));
          window.google.maps.event.addListener(tollMarker, 'mouseout', function () {
            infowindow.close();
          });
        })

      }
    } catch (e) { }


    // map.fitBounds(bounds)

  }
  onGridReady = params => {
    this.gridApi = params.api;

    // //console.log("Grid is: ", params);
    // this.gridColumnApi2 = params.columnApi;

  };

  handleKeyPressCode = (event) => {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^[0-9]*$/.test(inputChar)) {
      event.preventDefault(); // Prevent the character from being entered
    }
  };

  handlerStartDateTime1 = async (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    var month = ("0" + (d.getMonth() + 1)).slice(-2);
    var day = ("0" + d.getDate()).slice(-2);
    var startdate = d.getFullYear() + "-" + month + "-" + day;
    // console.log(startdate, 'startdate1')
    await this.setState({
      arrival_at_vicinity: startdate,
    }, () => console.log(this.state.arrival_at_vicinity, 'arrival_at_vicinity'));

  }
  handlerStablingStartTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    var month = ("0" + (d.getMonth() + 1)).slice(-2);
    var day = ("0" + d.getDate()).slice(-2);
    var startdate = d.getFullYear() + "-" + month + "-" + day;
		if (this.state.stabling_end != undefined && this.state.stabling_end != "") {
			
			var startDate = new Date(startdate);
			var endDate = new Date(this.state.stabling_end);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			// console.log(startTime,endTime);
			if (startTime > endTime) {
				// this.setState({
				// 	startDate:""
				// });
				this.setState({
					show: true,
					basicTitle: 'End Date should be greater than Start Date',
					basicType: "danger",
					loadshow: 'show-n'
				});
			}
			else {
				this.setState({
					stabling_start: startdate
				});
			}
		}
		else {
			this.setState({
				stabling_start: startdate
			});
		}
	}

  handlerStablingEndTime = async (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    var month = ("0" + (d.getMonth() + 1)).slice(-2);
    var day = ("0" + d.getDate()).slice(-2);
    var edate = d.getFullYear() + "-" + month + "-" + day;
    await this.setState({
      stabling_end: edate
    }, () => console.log(this.state.stabling_end, 'stabling_end'));
  }
  render() {
    const rowClassRules = {

      'status-approved': (params) => {
        // console.log(params.data,"41")

        return (params.data.approve_reject_status == "Approved")
      },

      'status-rejected': params => params.data.approve_reject_status == "Rejected",

      'status-empty': params => (params.data.approve_reject_status == "" || params.data.approve_reject_status == undefined),
    }
    //  if((localStorage.getItem("username") == "Nanda Kishore") || (localStorage.getItem("user_type") == "TRANSPORTER" && (localStorage.getItem('transportercode') != 'undefined' && localStorage.getItem('transportercode') != undefined))) {
    var button_name = "Edit"
    //  } 
    const batchwisecolumns = [
      {
        headerName: 'GPS Location',
        field: "_id",
        colId: "_id",
        width: 80,
        pinned: 'left',
        cellRenderer: 'consignmentActions',
        filter: false, resizable: true

      },
      {
        headerName: 'Doc Number',
        width: 150,
        field: 'doc_number',
        resizable: true,

      },
      {
        headerName: 'Rake Name',
        field: 'rake_name',
        with: 150,
        resizable: true,

      },
      {
        headerName: 'Type',
        field: 'type',
        width: 150,
        hide: false,
        resizable: true,

      },
      {
        headerName: 'MSIL rake Id',
        field: 'msil_rake_id',
        resizable: true,
      },
      {
        headerName: 'Request date & time',
        field: 'request_date',
        valueGetter: (param) => {
          if (param.data.request_date !== "") {
            return getHyphenDDMMMYYYYHHMM(param.data.request_date);
          } else {
            return "";
          }
        },

      },
      {
        headerName: 'IMM code',
        field: 'imm_code',

      },

      {
        headerName: 'LSP Name',
        field: 'lsp_name',

      },
      {
        headerName: 'LSP Code',
        field: 'lsp_code',

      },
      {
        headerName: 'Arrival at Vicinity',
        field: 'arrival_at_vicinity',
        width: 150,
        resizable: true,
        valueGetter: (param) => {
          if (param.data.arrival_at_vicinity !== "") {
            return getHyphenDDMMMYYYY(param.data.arrival_at_vicinity);
          } else {
            return "";
          }
        },

      },
      {
        headerName: 'Stabling Start',
        field: 'stabling_start',
        width: 150,
        resizable: true,
        width: 150,
        valueGetter: (param) => {
          if (param.data.stabling_start !== "") {
            return getHyphenDDMMMYYYY(param.data.stabling_start);
          } else {
            return "";
          }
        },

      },
      {
        headerName: 'Stabling End',
        field: 'stabling_end',

        resizable: true,
        width: 150,
        valueGetter: (param) => {
          if (param.data.stabling_end !== "") {
            return getHyphenDDMMMYYYY(param.data.stabling_end);
          } else {
            return "";
          }
        },

      },
      {
        headerName: 'Stabling Memo from IR',
        field: 'stabling_memo_ir',
        cellRenderer: function (params) {

          if (params.data.stabling_memo_ir != 0) {
            // console.log((params.data.pod_file),'pod_file')
            if (params.data.stabling_memo_ir) {
              var htmloption = '<button class="btn btn-info" style="padding-top: 0px">Download</button>';
              return htmloption;
            } else {
              return '';
            }
          } else {
            return ""
          }
        }


      },
      {
        headerName: 'MR/RR',
        field: 'mr_rr_value',
        width: 120,
        resizable: true,

      },
      {
        headerName: 'MR/RR Document',
        field: 'mm_rr_memo',
        cellRenderer: function (params) {

          if (params.data.mm_rr_memo != 0) {
            // console.log((params.data.pod_file),'pod_file')
            if (params.data.mm_rr_memo) {
              var htmloption = '<button class="btn btn-info" style="padding-top: 0px">Download</button>';
              return htmloption;
            } else {
              return '';
            }
          } else {
            return ""
          }
        }


      },
      {
        headerName: 'Invoicing Start',
        field: 'invoicing_start',

        resizable: true,
        width: 150,
        valueGetter: (param) => {
          if (param.data.invoicing_start !== "") {
            return getHyphenDDMMMYYYYHHMM(param.data.invoicing_start);
          } else {
            return "";
          }
        },
      },
      {
        headerName: 'Invoicing End',
        field: 'invoicing_end',

        resizable: true,
        width: 150,
        valueGetter: (param) => {
          if (param.data.invoicing_end !== "") {
            return getHyphenDDMMMYYYYHHMM(param.data.invoicing_end);
          } else {
            return "";
          }
        },

      },
      {
        headerName: 'Stabling Days',
        field: 'stabling_days',
        width: 120,
        resizable: true,
        valueGetter: (param) => {
          const stablingDays = param.data.stabling_days;

          if (stablingDays !== "") {
            const stablingDaysValue = Number(stablingDays);
            if (stablingDaysValue > 0) {
              
              return stablingDaysValue;
            } else {
              return 0;
            }
          } else {
            return 0;
          }
        }
       
      //   cellRenderer:  (params) => {
      //     try {
      //         const invoicing_end = moment.parseZone(new Date(params.data.invoicing_end)).utcOffset("+00:00").format("YYYY-MM-DD");
      //         let stabling_start;
      //         let number_of_days = 0;
      
      //         if (params.data.type === "Dedicated") {
      //             stabling_start = moment.parseZone(new Date(params.data.stabling_start)).utcOffset("+00:00").format("YYYY-MM-DD");
      //         } else {
      //             stabling_start = moment.parseZone(new Date(params.data.invoicing_start)).utcOffset("+00:00").format("YYYY-MM-DD");
      //         }
      //          computeStablingDays(stabling_start,invoicing_end,params.data._id)
      //         // const start = moment(stabling_start);
      //         // const end = moment(invoicing_end);
      
      //         // const diffInDays = end.diff(start, 'days');
      
      //         // let daysWithoutSunday = 0;
      
      //         // if (diffInDays > 0) {
      //         //     for (let i = 0; i <= diffInDays; i++) {
      //         //         const currentDate = moment(stabling_start).add(i, 'days');
      //         //         if (currentDate.day() !== 0) {
      //         //             daysWithoutSunday++;
      //         //         }
      //         //     }
      //         // }
      
      //         // number_of_days = daysWithoutSunday;
      
      // //         if (parseInt(number_of_days) > 0 && !isNaN(number_of_days)) {
      // //             const layoutdataRole = {
      // //                 totaldayscalculated: parseInt(number_of_days),
      // //                 rowId: params.data._id,
      // //                 type: 'stabling_days'
      // //             };
      // // console.log(layoutdataRole,'layoutdataRole')
      // //             const response = await redirectURL.post('/consignments/updateRakeTracking1', layoutdataRole, {
      // //                 headers: {
      // //                     "Access-Control-Allow-Origin": "*",
      // //                     "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
      // //                 }
      // //             });
      
      // //             console.log('UpdateRakeTracking1 response:', response.data);
      
      // //             return number_of_days;
      // //         } else {
      // //             return "";
      // //         }
      //     } catch (error) {
      //         console.error('Error in cellRenderer:', error);
      //         return "";
      //     }
      // }
      
      

      },
      {
        headerName: 'Initiated By',
        field: 'initiated_by',
        width: 120,


      },
      {
        headerName: 'Claim amount by system',
        field: 'claim_amount',
        width: 120,
        valueGetter: (param) => {
          const stablingDays = param.data.stabling_days;

          if (stablingDays !== "") {
            const stablingDaysValue = Number(param.data.claim_amount);
            if (stablingDaysValue >= 0) {
              // 630*27
              return  stablingDaysValue;
            } else {
              return 0;
            }
          } else {
            return 0;
          }
        }

      },
      {
        headerName: 'Claim amount by LSP',
        field: 'claim_amount_by_lsp',
        width: 150,
        filter: true,
        resizable: true,
        editable: (params) => (params.data.approve_reject_status !== "Approved" && params.data.approve_reject_status !== "Rejected"),
        valueSetter: (params) => {
          const newValue = params.newValue;

          // Check if the entered value is a valid number
          if (/^\d+(\.\d+)?$/.test(newValue)) {
            params.data.claim_amount_by_lsp = parseFloat(newValue);

            var paramsForBackend = {
              rowId: params.data._id,
              claim_amount_by_lsp: parseFloat(newValue),
              type: "Claim amount by LSP"
            };

            if (newValue !== "") {
              try {
                redirectURL.post("/consignments/updatingClaimAmountByLSP", paramsForBackend);
                // this.componentDidMount();
                this.gridApi.redrawRows();
              } catch (error) {
                console.error(error);
              }
            }
          } else {
            // Reset the value to an empty string and show an alert
            params.data.claim_amount_by_lsp = "";
            alert('Please enter number.');
          }

          return false;
        },
        onCellValueChanged: (event) => {
          const newValue = event.newValue;

          // Check if the entered value is a valid number
          if (!/^\d+(\.\d+)?$/.test(newValue)) {
            // Reset the value to an empty string and show an alert
            event.node.setDataValue('claim_amount_by_lsp', "");
            alert('Please enter number.');
          }
        }
      },

      {
        headerName: 'MSIL Verifier',
        field: 'msil_verifier',
        width: 120,


      },
      {
        headerName: 'After Approval amount',
        field: 'after_approval_amount',
        width: 150,


      },
      {
        headerName: 'Approved/Rejected',
        field: 'approve_reject_status',
        width: 150,


      },
      {
        headerName: 'Force major (Y/N)',
        field: 'force_major',
        width: 120,
        resizable: true,

      },
      {
        headerName: 'MSIL Remarks',
        field: 'msil_remarks',
        width: 120,
        resizable: true,

      },
      {
        headerName: 'LSP Remarks',
        field: 'lsp_remarks',
        width: 150,
        resizable: true,

      },
      {
        headerName: 'Approver Comments',
        field: 'reason',
        width: 150,
        editable: false,
        valueGetter: function (params) {
          return params.data.reason;
        },
      },

    ];
    if (localStorage.getItem("user_type") == "TRANSPORTER" || localStorage.getItem("username") == "Nanda Kishore") {
      batchwisecolumns.splice(0, 0,
        {
          headerName: "",
          field: "edit",
          width: 93,
          cellRenderer: function (params) {
            if ((params.data.approve_reject_status != "Approved") && (params.data.approve_reject_status != "Rejected")) {
              var htmloption = '<button class="btn btn-warning" style="padding-top:0px">' + button_name + '</button>';
              return htmloption;
            } else {
              var htmloption = '<button class="btn button-disable" disabled="on">' + button_name + '</button>';
              return htmloption;
            }
          },
        },
      )
    }
    if (localStorage.getItem("user_type") == "ORGUSER" || localStorage.getItem("username") == "Nanda Kishore") {
      batchwisecolumns.splice(1, 0,
        {
          headerName: "",
          field: "update",
          width: 115,
          cellRenderer: function (params) {
            if ((params.data.approve_reject_status != "Approved") && (params.data.approve_reject_status != "Rejected")) {
              var htmloption = '<button class="btn btn-primary" style="padding-top:0px">Update</button>';
              return htmloption;
            } else {
              var htmloption = '<button class="btn button-disable" disabled="on">Update</button>';
              return htmloption;
            }
          },
        },
      )
    }
    if (localStorage.getItem("username") == "Nanda Kishore" || localStorage.getItem("username") == "Jashmeesh Singh" || localStorage.getItem("username") == "AdilFaruqi") {
      batchwisecolumns.splice(14, 0, {
        headerName: "Approve",
        pinned: 'right',
        field: "approve_button",
        width: 115,
        // hide:showAcceptandreject,
        cellRenderer: function (params) {
          if (params.data.buttons_enable == true) {
            var htmloption = '<button class="btn btn-success" style="padding-top:0px">Approve</button>';
            return htmloption;
          } else {
            var htmloption = '<button class="btn button-disable" disabled="on">Approve</button>';
            return htmloption;
          }
        },
      },
        {
          headerName: "Reject",
          pinned: 'right',
          field: "reject_button",
          width: 110,
          // hide:showAcceptandreject,
          cellRenderer: function (params) {
            if (params.data.buttons_enable == true) {

              var htmloption = '<button class="btn btn-danger" style="padding-top:0px">Reject</button>';
              return htmloption;
            } else {
              var htmloption = '<button class="btn button-disable" disabled="on">Reject</button>';
              return htmloption;
            }
          },
        },)
    }

    const checkTrasnporter = localStorage.getItem("user_type")
    // console.log(checkTrasnporter,"gzdgdas") 
    return (
      <div class="container-fluid">
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>

        <div className='col-xl-12 col-lg-12' style={{ paddingBottom: "15px" }}>

          <div className="row card-body" style={{ marginLeft: "0px", paddingBottom: "0px" }}>
            {(checkTrasnporter == "TRANSPORTER") ?
              "" :
              <div className="col-xl-3 col-lg-3 form-group">
                <label>LSP Name  </label>
                <Select placeholder={"All"} closeMenuOnSelect={true} value={this.state.lsp_name_filter} className="border-radius-0" onChange={this.changeHandler.bind(this)} style={{ borderRadius: "0px" }} options={this.state.filterlspData} required />

              </div>
            }

            <div className="col-xl-3 col-lg-3 form-group">
              <label>From Date </label>
              <Datetime
                value={this.state.startDate}
                disableCloseOnClickOutside={false} 
                closeOnSelect={true} 
                timeFormat={false}
                inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off',readOnly:true,className: 'custom-datetime-input' }}
                dateFormat="YYYY-MM-DD"
                name="startDate"
                onChange={this.handlerStartDateTime.bind(this)}
                isValidDate={current => {
                  return current.isSameOrBefore(moment(), 'day');
                }}
                required
              />
            </div>
            <div className="col-xl-3 col-lg-3 form-group">
              <label>To Date </label>
              <Datetime
                value={this.state.endDate}
                disableCloseOnClickOutside={false}
                closeOnSelect={true} 
                timeFormat={false}
                inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off',readOnly:true,className: 'custom-datetime-input' }}
                dateFormat="YYYY-MM-DD"
                onChange={this.handlerEndDateTime.bind(this)}
                isValidDate={current => {
                  return current.isSameOrAfter(moment(new Date(this.state.startDate)), 'day');
                }}
                required
              />
            </div>
            <div className="form-group col-xl-2 col-lg-2">

              <button type="button" style={{ position: "absolute", borderRadius: "5px" }} className="mt-30p btn btn-success" onClick={this.onloadpagedata1.bind(this)}>Submit</button>

            </div>
            <div> <button className="mt-30p btn btn-warning"
              style={{ right: "20px" }} onClick={this.reset.bind(this)}>Reset</button></div>

          </div>

        </div>
        <div className="card col-xl-12">
          <div className="card-header">
            <h5>
              <i className="icofont icofont-vehicle-delivery-van cus-i"></i>{" "}
              <span>Rake Stabling Tracking Screen</span>
              {(localStorage.getItem("username") == "Nanda Kishore") || (localStorage.getItem("user_type") == "TRANSPORTER" && (localStorage.getItem('transportercode') != 'undefined' && localStorage.getItem('transportercode') != undefined))
                ?
                <button className="btn btn-info" style={{ float: "right", marginRight: "10px" }} onClick={this.AddLSPMSILDetails.bind(this)}>ADD</button>
                : ""}
              <div className="card-body">
                <div className="row">
                  <div
                    id="myGrid"
                    style={{ width: "100%", height: "478px" }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      modules={this.state.modules}
                      columnDefs={batchwisecolumns}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.rowData}
                      enableCharts={false}
                      onGridReady={this.onGridReady}
                      onGridState={this.onGridState}
                      frameworkComponents={this.state.frameworkComponents}
                      rowClassRules={rowClassRules}
                      sideBar={this.state.sideBar}
                      stopEditingWhenGridLosesFocus={true}
                      onCellClicked={this.onCellClicked.bind(this)}
                      paginationPageSize={this.state.paginationPageSize}
                      pagination={true}
                      gridOptions={{
                        context: { componentParent: this },
                      }}
                      // onCellClicked={this.onCellRowClicked.bind(this)}
                      enableRangeSelection={true}
                    />
                  </div>
                </div>
              </div>
            </h5>
          </div>

          {/* work in progress Fresh Incoming rakes */}

        </div>

        <div className={"slide-r " + (this.state.bulkslide)} style={{ overflow: "scroll" }}>
          <h3 className="subH">Add Details 
            <span className="float-right fclose" style={{ marginRight: "12px", padding: "1px 8px", marginTop: "-2px" }} onClick={this.onClickHideAll.bind(this)}>x</span>
          </h3>
          <div className="slide-r-body" style={{ position: "relative" }}>
            <div className="container-fluid">
              <form id="initialAddingDetails"
                onSubmit={this.onClickUpdateDetails.bind(this)}
                className="row col-xl-12 col-lg-12">

                <div className="col-xl-12 col-lg-12 form-group">
                  <label>Doc Number <span style={{ color: 'red' }}>*</span></label>
                  <input
                    type="text"
                    // value={this.state.requirement_id}
                    // onChange={this.handleInputChange} // Use a separate handler for input fields
                    name="doc_number"
                    id="doc_number"
                    className="form-control"
                    placeholder="Doc Number"
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="col-xl-12 col-lg-12 form-group">
                  <label>Rake Name <span style={{ color: 'red' }}>*</span></label>
                  <Select
                    value={this.state.rake_name}
                    placeholder={"Rake Name"}
                    closeMenuOnSelect={true}
                    onChange={this.onChangeRakeName.bind(this)}
                    style={{ borderRadius: "0px" }}
                    options={this.state.rake_names}
                    required
                  />
                </div>
                <div className="col-xl-12 col-lg-12 form-group">
                  <label>Type <span style={{ color: 'red' }}>*</span></label>
                  <Select
                    placeholder={"Select Type"}
                    closeMenuOnSelect={true}
                    value={this.state.type}
                    // className={"col-xl-12 col-lg-12 border-radius-0"}
                    onChange={this.handleSelectChange.bind(this, 'type')} // Use a separate handler for react-select
                    style={{ borderRadius: "0px" }}
                    options={[
                      { label: "Dedicated", value: "Dedicated" },
                      { label: "Non-Dedicated", value: "Non-Dedicated" }
                    ]}
                    required
                  />
                </div>
                <div className="col-xl-12 col-lg-12 form-group">
                  <label>MSIL Rake ID <span style={{ color: 'red' }}>*</span></label>
                  <input
                    type="text"
                    name="msil_rake_id"
                    id="msil_rake_id"
                    onKeyPress={this.handleKeyPressCode}
                    className="form-control"
                    placeholder="MSIL Rake ID"
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="col-xl-12 col-lg-12 form-group">
                  <label> Arrival at Vicinity <span style={{ color: 'red' }}>*</span></label>
                  {/* <input type="text" name="arrival_at_vicinity" id="arrival_at_vicinity" className="datetimepicker_datew form-control" readOnly autoComplete="off" required /> */}

                  <Datetime 
                            value={this.state.arrival_at_vicinity}
                            disableCloseOnClickOutside={false}
                            closeOnSelect={true}
                            inputProps={{ 
                              name: 'arrival_at_vicinity', 
                              autoComplete: 'off', 
                              readOnly: true, 
                              className: 'custom-datetime-input'
                            }}
                            dateFormat="DD-MM-YYYY"
                            timeFormat={false}
                            onChange={this.handlerStartDateTime1.bind(this)}
                          />
                </div>
                <div className="col-xl-12 col-lg-12 form-group">
                  <label> Stabling Start <span style={{ color: 'red' }}>*</span></label>
                  {/* <input type="text" name="stabling_start" id="stabling_start" className="datetimepicker_datew form-control" readOnly autoComplete="off" required /> */}
                  <Datetime value={this.state.stabling_start}
                      disableCloseOnClickOutside={false}
                      closeOnSelect={true}
                      inputProps={{ name: 'stabling_start', autoComplete: 'off', readOnly: true ,className: 'custom-datetime-input'}}
                      dateFormat="DD-MM-YYYY"
                      timeFormat={false}
                      onChange={this.handlerStablingStartTime.bind(this)}
                      isValidDate={current => {
                        return current.isSameOrBefore(moment(), 'day');
                      }} />
               
                </div>
                <div className="col-xl-12 col-lg-12 form-group">
                  <label> Stabling End <span style={{ color: 'red' }}>*</span></label>
                  {/* <input type="text" name="stabling_end" id="stabling_end" className="datetimepicker_datew form-control" readOnly autoComplete="off" required /> */}
                  <Datetime value={this.state.stabling_end}
                      disableCloseOnClickOutside={false}
                      closeOnSelect={true}
                      inputProps={{ name: 'stabling_end', autoComplete: 'off', readOnly: true, className: 'custom-datetime-input' }}
                      dateFormat="DD-MM-YYYY"
                      timeFormat={false}
                      onChange={this.handlerStablingEndTime.bind(this)}
                      isValidDate={current => {
                        return current.isSameOrAfter(moment(new Date(this.state.stabling_start)), 'day');
                      }} />

                </div>
                <div className="col-xl-12 col-lg-12 form-group">
                  <label>MR/RR <span style={{ color: 'red' }}>*</span></label>
                  <input
                    type="text"
                    name="mr_rr_value"
                    id="mr_rr_value"
                    className="form-control"
                    placeholder="MR/RR"
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="col-xl-12 col-lg-12 form-group">
                  <label>Upload Stabling Memo Document <span style={{ color: 'red' }}>*</span></label>
                  <input type="file" name="uploadFile" id='uploadFile' onChange={this.changeFileHandlerPOD} className="form-control" />
                </div>
                <div className="col-xl-12 col-lg-12 form-group">
                  <label>Upload MR/RR Document <span style={{ color: 'red' }}>*</span></label>
                  <input type="file" name="uploadFile2" id='uploadFile2' onChange={this.changeFileHandlerPOD2} className="form-control" />
                </div>
                <div className="col-xl-12 col-lg-12 form-group">
                  <label>LSP Remarks <span style={{ color: 'red' }}>*</span></label>
                  <input
                    type="text"
                    name="lsp_remarks"
                    id="lsp_remarks"
                    className="form-control"
                    placeholder="LSP Remarks"
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="form-group col-xl-3 col-lg-3">
                  <button type="submit" className="btn btn-success">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className={"slide-r " + (this.state.bulkslide1)} style={{ overflow: "scroll" }}>
          <h3 className="subH">Edit Details 
            <span className="float-right fclose" style={{ marginRight: "12px", padding: "1px 8px", marginTop: "-2px" }} onClick={this.onClickHideAll.bind(this)}>x</span>
          </h3>
          <div className="slide-r-body" style={{ position: "relative" }}>
            <div className="container-fluid">
              <form
                onSubmit={this.onClickEditDetails.bind(this)}
                className="row col-xl-12 col-lg-12">

                <div className="col-xl-12 col-lg-12 form-group">
                  <label>Doc Number <span style={{ color: 'red' }}>*</span></label>
                  <input
                    type="text"
                    value={this.state.doc_number}
                    // onChange={this.handleInputChange} // Use a separate handler for input fields
                    name="doc_number"
                    // id="doc_number"
                    onChange={this.editchanges.bind(this)}
                    className="form-control"
                    placeholder="Doc Number"
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="col-xl-12 col-lg-12 form-group">
                  <label>Rake Name <span style={{ color: 'red' }}>*</span></label>
                  <Select
                    value={this.state.rake_name}
                    placeholder={"Rake Name"}
                    closeMenuOnSelect={true}
                    onChange={this.onChangeRakeName.bind(this)}
                    style={{ borderRadius: "0px" }}
                    options={this.state.rake_names}
                  />
                </div>
                <div className="col-xl-12 col-lg-12 form-group">
                  <label>Type <span style={{ color: 'red' }}>*</span></label>
                  <Select
                    placeholder={"Select Type"}
                    closeMenuOnSelect={true}
                    value={this.state.type}
                    onChange={this.handleSelectChange.bind(this, 'type')} // Use a separate handler for react-select
                    style={{ borderRadius: "0px" }}
                    options={[
                      { label: "Dedicated", value: "Dedicated" },
                      { label: "Non-Dedicated", value: "Non-Dedicated" }
                    ]}
                    required
                  />
                </div>
                <div className="col-xl-12 col-lg-12 form-group">
                  <label>MSIL Rake ID <span style={{ color: 'red' }}>*</span></label>
                  <input
                    type="text"
                    name="msil_rake_id"
                    value={this.state.msil_rake_id}
                    onChange={this.editchanges.bind(this)}
                    // id="msil_rake_id"
                    onKeyPress={this.handleKeyPressCode}
                    className="form-control"
                    placeholder="MSIL Rake ID"
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="col-xl-12 col-lg-12 form-group">
                  <label> Arrival at Vicinity <span style={{ color: 'red' }}>*</span></label>
                  {/* <input type="text" name="edit_arrival_at_vicinity" id="edit_arrival_at_vicinity" className="datetimepicker_datew form-control" readOnly autoComplete="off" required /> */}
                  <Datetime 
                            value={this.state.arrival_at_vicinity}
                            disableCloseOnClickOutside={false}
                            closeOnSelect={true}
                            inputProps={{ 
                              name: 'arrival_at_vicinity', 
                              autoComplete: 'off', 
                              readOnly: true, 
                              className: 'custom-datetime-input'
                            }}
                            dateFormat="YYYY-MM-DD"
                            timeFormat={false}
                            onChange={this.handlerStartDateTime1.bind(this)}
                          />
                </div>
                <div className="col-xl-12 col-lg-12 form-group">
                  <label> Stabling Start <span style={{ color: 'red' }}>*</span></label>
                  {/* <input type="text" name="edit_stabling_start" id="edit_stabling_start" className="datetimepicker_datew form-control" readOnly autoComplete="off" required /> */}
                  <Datetime value={this.state.stabling_start}
                      disableCloseOnClickOutside={false}
                      closeOnSelect={true}
                      inputProps={{ name: 'stabling_start', autoComplete: 'off', readOnly: true ,className: 'custom-datetime-input'}}
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      onChange={this.handlerStablingStartTime.bind(this)}
                      isValidDate={current => {
                        return current.isSameOrBefore(moment(), 'day');
                      }} />
                </div>
                <div className="col-xl-12 col-lg-12 form-group">
                  <label> Stabling End <span style={{ color: 'red' }}>*</span></label>
                  {/* <input type="text" name="edit_stabling_end" id="edit_stabling_end" className="datetimepicker_datew form-control" readOnly autoComplete="off" required /> */}
                  <Datetime value={this.state.stabling_end}
                      disableCloseOnClickOutside={false}
                      closeOnSelect={true}
                      inputProps={{ name: 'stabling_end', autoComplete: 'off', readOnly: true, className: 'custom-datetime-input' }}
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      onChange={this.handlerStablingEndTime.bind(this)}
                      isValidDate={current => {
                        return current.isSameOrAfter(moment(new Date(this.state.stabling_start)), 'day');
                      }} />

                </div>
                <div className="col-xl-12 col-lg-12 form-group">
                  <label>MR/RR <span style={{ color: 'red' }}>*</span></label>
                  <input
                    type="text"
                    name="mr_rr_value"
                    onChange={this.editchanges.bind(this)}
                    value={this.state.mr_rr_value}
                    // id="mr_rr_value"
                    className="form-control"
                    placeholder="MR/RR"
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="col-xl-12 col-lg-12 form-group">
                  <label>LSP Remarks <span style={{ color: 'red' }}>*</span></label>
                  <input
                    type="text"
                    value={this.state.lsp_remarks}
                    onChange={this.editchanges.bind(this)}
                    name="lsp_remarks"
                    // id="lsp_remarks"
                    className="form-control"
                    placeholder="LSP Remarks"
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="form-group col-xl-3 col-lg-3">
                  <button type="submit" className="btn btn-success">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className={"slide-r " + (this.state.bulkslide2)} style={{ overflow: "scroll" }}>
          <h3 className="subH">Update Details
            <span className="float-right fclose" style={{ marginRight: "12px", padding: "1px 8px", marginTop: "-2px" }} onClick={this.onClickHideAll.bind(this)}>x</span>
          </h3>
          <div className="slide-r-body" style={{ position: "relative" }}>
            <div className="container-fluid">
              <form
                onSubmit={this.onClickUpdateTransporterDetails.bind(this)}
                className="row col-xl-12 col-lg-12">


                <div className="col-xl-12 col-lg-12 form-group">
                  <label>After Approval Amount :{" "}</label>
                  <input
                    type="text"
                    // value={this.state.rake_name}
                    name="after_approval_amount"
                    id="after_approval_amount"
                    // onChange={this.editchanges.bind(this)}
                    className="form-control"
                    placeholder="After Approval Amount"
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="col-xl-12 col-lg-12 form-group">
                  <label>Force Major : </label>
                  <Select
                    placeholder={"Force Major"}
                    closeMenuOnSelect={true}
                    value={this.state.force_major}
                    onChange={this.handleSelectChange.bind(this, 'force_major')} // Use a separate handler for react-select
                    style={{ borderRadius: "0px" }}
                    options={[
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" }
                    ]}
                    required
                  />
                </div>
                <div className="col-xl-12 col-lg-12 form-group">
                  <label>MSIL Remark :{" "}</label>
                  <input
                    type="text"
                    name="msil_remarks"
                    // value={this.state.msil_rake_id}
                    // onChange={this.editchanges.bind(this)}
                    id="msil_remarks"
                    className="form-control"
                    placeholder="MSIL Remark"
                    autoComplete="off"
                    required
                  />
                </div>

                <div className="form-group col-xl-3 col-lg-3">
                  <button type="submit" className="btn btn-success">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className={"slide-r " + (this.state.bulkslide3)} style={{ overflow: "scroll" }}>
          <h3 className="subH">{this.state.slider_header}
            <span className="float-right fclose" style={{ marginRight: "12px", padding: "1px 8px", marginTop: "-2px" }} onClick={this.onClickHideAll.bind(this)}>x</span>
          </h3>
          <div className="slide-r-body" style={{ position: "relative" }}>
            <div className="container-fluid">
              <form
                onSubmit={this.onClickApproveandRejectDetails.bind(this)}
                className="row col-xl-12 col-lg-12">

                <div className="col-xl-12 col-lg-12 form-group">
                  <label>Reason</label>
                  <input
                    type="text"
                    name="reason"
                    // value={this.state.msil_rake_id}
                    // onChange={this.editchanges.bind(this)}
                    id="reason"
                    className="form-control"
                    placeholder="Reason"
                    autoComplete="off"
                    required
                  />
                </div>

                <div className="form-group col-xl-3 col-lg-3">
                  <button type="submit" className="btn btn-success">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>


        {this.state.sliderRouteTranslate != '' ?
          <div className={"slide-r " + (this.state.sliderRouteTranslate)} style={{ overflow: "auto" }}>
            <div className="slide-r-title">
              <h4>
                {"Rake Tracking"}
                <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>

              </h4>
            </div>
            <div className="slide-r-body" style={{ position: "relative" }}>
              {/* {console.log('this.state.rowData.consigner_coordinates',this.state.rownode.consigner_coordinates)} */}
              <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                <DrawMap2
                  context={this}
                  rownode={this.state.rownode}
                  mapFor={"consignment"}
                  tolls={this.state.tolls}
                  googleroutes={this.state.googelRoutes}
                  truckno={this.state.truck_no}
                  dealer={this.state.dealer}
                  mapinfo={this.state.mapinfo}
                  deptcode={this.state.deptcode}
                  defTransitCoords={this.state.defTransitCoords}
                  baseRouteCoords={this.state.baseRouteCoords}
                  geofence_coordinates={this.state.geofence_coordinates}
                  parking_coordinates={this.state.parking_coordinates}
                />

                <div className="col-xl-12 col-lg-12 n-p-0 mt-3">
                  <div className="crm-numbers pb-0">
                    {/* <h3 className="subH">{this.state.sidebarSubHeader}</h3> */}
                    <div className="col-xl-12 col-lg-12 row">
                      <div className="col route-block">
                        <label className="sidebar-label">Rake Name</label>
                        <div>{this.state.rake_name}</div>
                      </div>
                      <div className="col route-block">
                        <label className="sidebar-label">Stabling Start Time</label>
                        <div>{(!isEmpty(this.state.stabling_start_map)) ? getDDMMYYYY(this.state.stabling_start_map) : "-"}</div>
                      </div>
                      <div className="col route-block">
                        <label className="sidebar-label">Stabling End Time</label>
                        <div>{(!isEmpty(this.state.stabling_end_map)) ? getDDMMYYYY(this.state.stabling_end_map) : "-"}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div> : ""}
        <div className={"dataLoadpage " + (this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
          <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>
    );
  }
}



function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
  this.eInput = document.createElement('input');
  this.eInput.value = params.value;

  // Get today's date and format it as dd-mm-yy
  var today = new Date();

  // https://jqueryui.com/datepicker/
  $(this.eInput).datetimepicker({
    dateFormat: "dd-mm-yy",
    changeMonth: true,
    changeYear: true,
    // maxDate: today // Set the maxDate option to today's date
  });
};


// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
  return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
  this.eInput.focus();
  this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
  return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
  // but this example is simple, no cleanup, we could
  // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
  // and we could leave this method out also, false is the default
  return false;
};

function loadDateTimeScript() {
  // alert("timeDate");
  $(".datetimepicker_datew").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y",
    // minDate: true,
    timepicker: false,
    closeOnDateSelect: true,
    scrollMonth: false,
    scrollInput: false,
  });
  $(".datetimepicker_dateo").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y",
    minDate: true,
    timepicker: false,
    closeOnDateSelect: true,
    scrollMonth: false,
    scrollInput: false,
  });
  $(".cargo_date").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y",
    minDate: true,
    timepicker: false,
  });
}
function formatDate(dateString) {
  if (dateString != "Invalid Date" && dateString != "" && dateString != "NaN-NaN-NaN" && dateString != undefined && dateString != 'undefined') {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  else {
    return ""
  }
}
function formatDate1(dateString) {
  if (dateString && dateString !== "Invalid Date" && dateString !== "" && dateString !== "NaN-NaN-NaN" && dateString !== 'undefined') {
    const datePart = dateString.split("T")[0]; // Get only the date part
    return datePart;
  } else {
    return "";
  }
}

function dateComparator1(date1, date2) {
  //console.log(date1,date2);
  if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
    // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split("-")[1]);
    date1 = date1.replace(date1.split("-")[1], date_1);
    var date_2 = getMonthNumber(date2.split("-")[1]);
    date2 = date2.replace(date2.split("-")[1], date_2);
    var date1Number = monthToComparableNumber1(date1);
    var date2Number = monthToComparableNumber1(date2);
    //console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    // console.log(date1Number, date2Number, "comparator")
    return date1Number - date2Number;
  }
}
function monthToComparableNumber1(date) {
  //console.log(date.length);
  //console.log(date);
  if (date === undefined || date === null || date.length !== 10) {
    return null;
  }
  var yearNumber = date.substring(6, 10);
  var monthNumber = date.substring(3, 5);
  var dayNumber = date.substring(0, 2);
  var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
  return result;
}
function getMonthNumber(monthName) {
  var months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May',
    'Jun', 'Jul', 'Aug', 'Sep',
    'Oct', 'Nov', 'Dec'
  ];
  var monthNum = months.indexOf(monthName) + 1;
  if (monthNum < 10) {
    monthNum = "0" + monthNum.toString();
  }
  return monthNum;
}
function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}
function loadScript(url) {
  var index = window.document.getElementsByTagName("script")[0]
  var script = window.document.createElement("script")
  script.src = url
  script.async = true
  script.defer = true
  index.parentNode.insertBefore(script, index)
}
function timeConvert(n) {
  var num = n;
  var hours = (num / (3600));
  var rhours = parseInt(hours);
  var minutes = (num - (rhours * 3600)) / (60);
  var rminutes = Math.round(minutes);
  return rhours + " hour(s) and " + rminutes + " minute(s).";
}
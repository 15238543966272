
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';


import Counters from '../layouts/consignmentkpicountersComponent';

import CountUp from 'react-countup';

import { AgGridReact } from "@ag-grid-community/react";

import {
    AllModules,
    GridOptionsWrapper,
  } from "@ag-grid-enterprise/all-modules";
  
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from "react-select";

import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';

import Highcharts from 'highcharts';


export default class FreightPayments extends Component {
    constructor(props){
    	super(props);
    	this.state={
           facility:"All Facilities",
           facilitiesList:[{"label":"All Facilities","value":"All Facilities"},
           {"label":"Haryana","value":"Haryana"},
           {"label":"Bidadi","value":"Bidadi"}
           ,{"label":"GUJ","value":"GUJ"},
           {"label":"TVP-B","value":"TVP-B"},
           {"label":"TVP-N","value":"TVP-N"},
           {"label":"TVP-S","value":"TVP-S"}]
    	}
    
    }

    componentDidMount(){
        console.log("hiii")
    }
    
    newFilter=(event)=>{
       console.log(event)
    }

    onClickCounter(data){
        //this.props.context.onClickCounterShowData(data)
    
    }

    handleDrilldown = (params) => {
        console.log("Here ,", params)
    }

    render(){

        const dStyles={
            width:'100%',
            height:'100%'
    }

      var highchartoptions={
        chart: {
            type: 'column'
        },
        title: {
            text: 'Freight Payment'
        },
        
        xAxis: {
            categories: [
                "FY 18-19",
                "FY 19-20",
                "FY 20-21",
                "Apr'21",
                "May'21",
                "Jun'21",
                "Jul'21",
                "Aug'21",
                "Sept'21"
            ],
            crosshair: true
        },
        yAxis: {
            
            title: {
                text: 'Freight Amount (In Million)'
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f} Mn</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: [ {
            // name: 'Actual Payment Year Wise',
            // data: [41,38,43,0,0,0,0,0,0],
            // color: '#7030A0'
        }, {
            // name: 'Actual Payment',
            // data: [0,0,0,1.9,2.1,2.3,1.2,2.5,3],
            // color: '#A9D18E'
    
        }]}

        return (
            <div className='row'>
                {/* <div className='card col-xl-12 col-lg-12' style={{marginTop:"0px"}}>
            <div className="card-header text-center" style={{marginTop:"0px"}}>
            
            <h5 style={{ border: "1px solid #ccc", padding: "1%", fontSize:"20px" }}>
           
              <span className="" style={{position:"relative",left:"0 %"}}>Freight Payments</span>
            </h5>
          </div>
          </div> */}
              <div   style={{textAlign: "center",background: "#171C8F",width:'100%',margin:"5px",borderRadius:"5px",height:'70px',paddingBottom:'25px'}}>
                                   <h6 class="txt-dark f40" style={{marginTop:"0px"}}>
                                        <span   style={{fontSize:"20px",color:"#FFFFFF"}}>
                                            <span>Freight Payments</span>
                                        
                                        </span>
                                    </h6>
                                </div>


          <div className="row card-body">

                <div className="col-xl-4">
                        

                    <Select
                    placeholder={"Select Facility"}
                    closeMenuOnSelect={true}

                    value={this.state.facility}
                     onChange={this.newFilter.bind(this)} 
                     style={{ borderRadius: "0px" }}
                    options={this.state.facilitiesList} required
                    />
                </div>
                <div className="col-xl-8 col-lg-8">		            	
                  <h5>
                        
                        <span className="float-right f12 "> 
                              <a className="btn btn-danger" style={{float:"left",marginLeft:"10%"}} href="/lspceodashboard">Back</a>
                        </span>
                  </h5>                    
              </div>
                
            </div>
          

            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="card">
                    <div className="card-body" id="dashboardCounterCardBody">
                        <div className="crm-numbers pb-0">
                           
                            <div className="row">
                               
                                <div className="col " style={{cursor:'pointer'}} onClick={this.onClickCounter.bind(this,0)}>
                                    <span className="f13">
                                        {/* <i className="icofont icofont-truck cus-i txt-info f24"></i> */}
                                        <br />Pending Freight Amount
                                    </span>
                                    <h4 className="txt-info f50">
                                        <span className="counter">
                                            <CountUp end={0}/>
                                        </span>
                                    </h4>
                                     
                                </div> 
                                <div className="col " style={{cursor:'pointer'}} onClick={this.onClickCounter.bind(this,1)}>
                                    <span className="f13">
                                        {/* <i className="icofont icofont-truck-loaded f22 txt-primary-dark"></i> */}
                                        <br /> Pending Freight Amount {new Date().toLocaleString('default', { month: 'long' })} Month
                                    </span>
                                    <h4 className="txt-primary f50">
                                        <span className="counter">
                                            <CountUp end= {(this.state.trucksWithGpsDataCount) ? this.state.trucksWithGpsDataCount : 0}/>
                                        </span>
                                    </h4>
                                  
                                </div>
                                <div className="col " style={{cursor:'pointer'}} onClick={this.onClickCounter.bind(this,1)}>
                                    <span className="f13">
                                        {/* <i className="icofont icofont-truck-loaded f22 txt-primary-dark"></i> */}
                                        <br /> Pending Freight Amount {getNextMonthName()} Month
                                    </span>
                                    <h4 className="txt-secondary-medium f50">
                                        <span className="counter">
                                            <CountUp end= {(this.state.trucksWithGpsDataCount) ? this.state.trucksWithGpsDataCount : 0}/>
                                        </span>
                                    </h4>
                                  
                                </div>
                                

                               

                            </div>

                            
                           
                            
                        </div>
                       
                    </div>
                </div>
            </div>
           
            <div className='col-xl-12 col-lg-12'>

<HighchartsReact
     containerProps={{ style: { width: '100%', height: '100%' } }}
     highcharts={Highcharts} 
     style={dStyles} 
     options={highchartoptions} 
     onClick={this.handleDrilldown.bind(this)}
 />
</div>
            
          
           </div>
            

        );
    }
}
function getNextMonthName() {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 1); // Set the date one month ahead
    return currentDate.toLocaleString('default', { month: 'long' });
}
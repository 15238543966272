import React, { Component } from 'react';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import {getDDMMMYYYYHHMMDefault,getHyphenDDMMYYYYHHMMSS  } from '../common/utils'; 
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import ExportsTrackingLedComponent from "./exportstrackingledComponent";
import SweetAlert from 'react-bootstrap-sweetalert';
import UpdateButton from "./updatelmstates";
 import ForceCloseButton from "./exportsforcecloseaction";
 import LedScreenPieChartComponent from './ledscreenpirchartcomponent';
 import Modal from 'react-responsive-modal';
// import { Modal } from "react-responsive-modal";
// import "react-responsive-modal/styles.css";




var redirectURL = require('../redirectURL');
var moment = require('moment');


var googleAnalytics = require("../common/googleanalytics");

export default class LedRailwayLogisticsscreen4 extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
            financialYear:"",
            prevYear:"",
            currentMonth:"",
    		pageTitle: "Exports Container Tracking",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
            modules: AllModules,
            sidebarSubHeader:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: [],
              field1:"",
              field2:"",
              field3:"",
              field4:"",
              field5:"",
              importValue1:"",
              importValue2:"",
              importDuty1:"",
              importDuty2:"",
              lpc1:"",
              lpc2:"",
      	      
			rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            frameworkComponents: {
                UpdateButton:UpdateButton,
                ForceCloseButton:ForceCloseButton
            },

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,				
			groupDefaultExpanded: -1,
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:1000,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
            highseescnt:0,
            highseesarr:[],
            icdtoport:0,
            icdtoportarr:[],
            insideicdcnt:0,
            insideicdarr:[],
            insideportcnt:0,
            insideportarr:[],
            msilicdcnt:0,
            msilicdarr:[],
            originalData:[],
            polList:[],
            mgorocnt:0,
            mgoroarr:[],
            ropocnt:0,
            ropoarr:[],
            podicnt:0,
            podiarr:[],
            rowid:"",
            selectcontainerno:"",
            lastRunTime : "",
            categoryList:[],
            categoryData:[],
            open:false,
            // currentScreenIndex: 3
    	}
        this.onLoadDashboardData = this.onLoadDashboardData.bind(this);
		this.resetState = this.resetState.bind(this);
        this.onClickCounterShowData = this.onClickCounterShowData.bind(this);
        this.onClickForceClose = this.onClickForceClose.bind(this);
        // this.screens = [
        //     '/ledrailwaylogisticsscreen',
        //     '/ledrailwaylogisticsscreen2',
        //     '/ledrailwaylogisticsscreen3',
        //     '/ledrailwaylogisticsscreen4'
        //   ];
    }
   
    // autororate(){ 
    //     this.rotationInterval = setInterval(this.rotateScreens, 20000);
    //     this.props.history.push(`${process.env.PUBLIC_URL}${this.screens[this.state.currentScreenIndex]}`);
    //   }
     
    //   componentWillUnmount() {
    //      clearInterval(this.rotationInterval);
    //    }
     
    //    rotateScreens = () => {
    //     const nextScreenIndex = (this.state.currentScreenIndex + 1) % this.screens.length;
      
    //     if (nextScreenIndex === 0) {
    //       this.props.history.push(`${process.env.PUBLIC_URL}${this.screens[0]}`);
    //     } else {
    //       this.props.history.push(`${process.env.PUBLIC_URL}${this.screens[nextScreenIndex]}`);
    //     }
      
    //     this.setState({
    //       currentScreenIndex: nextScreenIndex
    //     });
    //   }

    async componentDidMount(){
        var token  = localStorage.getItem("_t")
        var email = localStorage.getItem("email")
        // if(token != undefined && token != null && token != "" && email != undefined && email != null && email != ""){
        //     await redirectURL.post("/consignments/chekingLoggedinUser",{"token":token,"email":email})
        //     .then(async(response)=>{
        //         if(response.data.message == "Send to login page"){
        //             window.location.href = "/"
        //         }else{
                await this.getFinancialYear()
                await this.getThirdSunday()
                try {
                    if (googleAnalytics.page.enableGA) {
                        googleAnalytics.logPageView();
                        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
                    } else {
                        console.log("GA disabled...");
                    }
                } catch(error) {
                    console.error("Error occured while logging to GA, error = ", error);
                }
                
                // await redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
                // .then( async (resp) => {
                //     console.log("resp ", resp)
                //     await this.setState({
                //         usergridstate:resp.data
                //     });
                //     await this.restoreGridStates();
                // })

                await redirectURL.post("/consignments/getledscreendata")
                .then( async (response) => {
                    console.log("185", response)
                    var resp = response.data
                    await this.setState({
                        field1:resp[0].filed_for_current_fy,
                        field2:resp[0].pending_for_query,
                        field3:resp[0].pending_for_assessment,
                        field4:resp[0].pending_for_out_of_charge,
                        field5:resp[0].pending_for_duty_payment,
                        importValue1:resp[0].import_value_for_current_fy,
                        importValue2:resp[0].import_value_for_current_month,
                        importDuty1:resp[0].import_duty_for_current_fy,
                        importDuty2:resp[0].import_duty_for_current_month,
                        lpc1:resp[0].lpc1,
                        lpc2:resp[0].lpc2,
                        lastUpdated:resp[0].last_updated_on
                    });
                    
                })
                
                await redirectURL.post("/consignments/getPolNames").then((response)=>{
                    var pols = response.data;
                    var polList = [];
                    if(pols.length > 0)
                    {
                        pols.map((item) =>{
                            polList.push(item)
                        })
                    }
                    this.setState({
                        polList : polList,
                    })
                }).catch((e)=>{
                    console.log(e);
                })

                await this.onLoadDashboardData()
                var lastRunTime = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
                this.setState({
                    lastRunTime:lastRunTime,
                    overly: "show-n",
                    loadshow: "show-n"
                })
    //         }
    //     })
    //  } else{
    //     window.location.href ="/"
    // }

// this.autororate()
};

    addDays = (date,days) => {
        console.log(typeof(date),'typeoff')
        if(date != " " && date != null && date != undefined && date != 'Invalid Date'){
        console.log("hellllo",date,days)

        let date2 = new Date(date);
        const newDate = new Date(date2.getTime() + (days * 24 * 60 * 60 * 1000)); // add the specified number of days to the date

        const isoNewDate = newDate.toISOString();
        console.log(date2,newDate,isoNewDate,"235")
        return isoNewDate
            }
        }
    onCloseModal = () => {
        console.log("closed")

        this.setState({
            open: false,
            });
        };

        openModal = () => {

            this.setState({
            
             open: true,
            
         });
            
         };
    

    getThirdSunday=()=>{
                // Create a new Date object with the desired date
        let date = new Date('2023-03-30');

        // Convert the date to the ISO format using toISOString() method
        let isoDate = date.toISOString();

        console.log(isoDate,"256");

       

        // Get the day name using the toLocaleString() method
        let dayName = date.toLocaleString('en-us', { weekday: 'long' });

        console.log(dayName,"255");
        let today = new Date();
        console.log(today,"251")
        // Calculate the date of the third Sunday
        let thirdSunday = new Date(today.getFullYear(), today.getMonth(), 1 + (21 - new Date(today.getFullYear(), today.getMonth(), 1).getDay()));

        // If the third Sunday has already passed this month, add a month to get the next occurrence
        if (today > thirdSunday) {
        thirdSunday.setMonth(thirdSunday.getMonth() + 1);
        }

        // Format the date as desired
        let formattedDate = thirdSunday.toDateString();
        console.log(formattedDate,today,"262");
    }


   
    
   

    onLoadDashboardData()
    {
        var params = {
            dept_code : "LOG-SC",
        }
		redirectURL.post("/consignments/exportscontainertracking",params)
		.then((response) => {
            console.log("Respons ", response.data)
            var records = response.data;
            var highseesarr=[];
            var icdtoportarr=[];
            var insideicdarr=[];
            var insideportarr=[];
            var msilicdarr=[];
            var mgoroarr=[];
            var ropoarr=[];
            var podiarr=[];
            if(records.length > 0)
            {
                 records.map((item) => {
                    if(item.status == "High Seas")
                    {
                        highseesarr.push(item)
                    }
                    if(item.status == "Inside Port")
                    {
                        insideportarr.push(item)
                    }
                    if(item.status == "ICD to Port")
                    {
                        icdtoportarr.push(item)
                    }
                    if(item.status == "Inside ICD")
                    {
                        insideicdarr.push(item)
                    }
                    if(item.status == "MSIL to ICD")
                    {
                        msilicdarr.push(item)
                    }
                    if(item.exports_to == undefined || item.exports_to == "")
                    {
                        if(item.manual_searates_pod != undefined && item.manual_searates_pod != "")
                        {
                            item.destination_port = item.manual_searates_pod
                        }
                    }
                    if(item.dest_in_transit_delay == 1)
                    {
                        podiarr.push(item)
                    }
                    else if(item.port_out_transit_delay == 1)
                    {
                        ropoarr.push(item)
                    }
                    else if(item.rail_out_transit_delay == 1)
                    {
                        mgoroarr.push(item)
                    }
                })
            }

            const newList=[]
            const transshipmentPortArr = []
            const newcategoryList=records.map((each)=>{
                if((each.destination_country)!=="" || (each.destination_country)!=="undefined"){
                    if(each.destination_country.length > 0){
                        transshipmentPortArr.push(each.destination_country)
                    }
                    return each.destination_country
            }})

            // console.log(transshipmentPortArr,"257")
            const pieCategories=[...(new Set(transshipmentPortArr))]
            // console.log(pieCategories,"250")
            const elementCount = {};
            for (var element of transshipmentPortArr) {
                if (elementCount[element]) {
                 elementCount[element] += 1;
                } else {
                 elementCount[element] = 1;
                }
               }

            let pieData=[]

            for (let element of pieCategories){
                pieData.push({name:element,y:elementCount[element], showTooltip: 1})
            }

            // console.log(pieData,"337")

            // let newDate=new Date()
            // console.log(this.addDays(newDate,10),"384")
            let highSeasDelayed=[]
            
            let newHighseaDelay = highseesarr.filter((each) => each.destination_port === "Jakarta" || each.destination_port === "Tema");
            console.log(newHighseaDelay, "267");
            
            newHighseaDelay = newHighseaDelay.map((each) => {
              if (each.destination_port === "Jakarta") {

                console.log(typeof(new Date(each.revised_actual_dispatch_from_indian_port)),each.revised_actual_dispatch_from_indian_port,(each.revised_actual_dispatch_from_destination_port),"413")

                const { revised_actual_dispatch_from_indian_port, revised_actual_dispatch_from_destination_port } = each;

                if (revised_actual_dispatch_from_destination_port) {
                let [datePart, timePart] = revised_actual_dispatch_from_destination_port.split(" ");
                let [day, month, year] = datePart.split("-");
                let [hours, minutes, seconds] = timePart.split(":");
                let dateObject = new Date(year, month - 1, day, hours, minutes, seconds);
                var isoFormat = dateObject.toISOString();
                console.log(isoFormat,'isoformat')
            
                let expected_dispatch_date = this.addDays(new Date(revised_actual_dispatch_from_indian_port), 33);
                console.log((new Date(expected_dispatch_date).valueOf())<(new Date(isoFormat).valueOf()), "391---");
                if (new Date(isoFormat).valueOf() > new Date(expected_dispatch_date).valueOf()) {
                  highSeasDelayed.push(each);
                }
            }
              } else {
                const { revised_actual_dispatch_from_indian_port, revised_actual_dispatch_from_destination_port } = each;

                if (revised_actual_dispatch_from_destination_port) {
                    let [datePart, timePart] = revised_actual_dispatch_from_destination_port.split(" ");
                    let [day, month, year] = datePart.split("-");
                    let [hours, minutes, seconds] = timePart.split(":");
                    let dateObject = new Date(year, month - 1, day, hours, minutes, seconds);
                    var isoFormat = dateObject.toISOString();
                    console.log(isoFormat,'isoformat')
          
                    let expected_dispatch_date =  this.addDays(new Date(revised_actual_dispatch_from_indian_port), 40);
                console.log(new Date(expected_dispatch_date).valueOf(), new Date(isoFormat).valueOf(), "391");
                if (new Date(isoFormat).valueOf() > new Date(expected_dispatch_date).valueOf()) {
                  highSeasDelayed.push(each);
                }
                }
            }
            });
            
            console.log(highSeasDelayed,"400")
            if(highSeasDelayed.length==undefined || highSeasDelayed.length=='undefined'){
                highSeasDelayed.length=0;
            }

            console.log(records,"412")

            this.setState({
                rowData:records,
                originalData:records,
                highseesarr:highseesarr,
                insideportarr:insideportarr,
                icdtoportarr:icdtoportarr,
                insideicdarr:insideicdarr,
                msilicdarr:msilicdarr,
                highseescnt:highseesarr.length,
                insideportcnt:insideportarr.length,
                icdtoportcnt:icdtoportarr.length,
                insideicdcnt:insideicdarr.length,
                msilicdcnt:msilicdarr.length,
                mgorocnt:mgoroarr.length,
                mgoroarr:mgoroarr,
                ropocnt:ropoarr.length,
                ropoarr:ropoarr,
                podicnt:highSeasDelayed.length,
                podiarr:highSeasDelayed,
                chartSeriesCategories:pieCategories,
                chartSeriesData:pieData
            })
        })
        
    }
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			openUpdatedModal : false
		});
		
	}
	closeAlert = () => {
        this.setState({
            show: false
        });
    }
   

	onClickSaveGridState(){
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
        let screenpage = 'Exports Container Tracking';

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
        try{
            if(this.state.usergridstate.length > 0)
            {
                var windowstates = this.state.usergridstate;
                this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
                this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
                this.gridApi.setSortModel(windowstates[0].gridcolsort);
                this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
                
            }
        }
        catch(e){

        }
		
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

    };
    
	logCounterClickGA(params) {
		if (googleAnalytics.page.enableGA) {
            if(params == 1)
            {
                var statusText = "MSIL to ICD";
            }
            if(params == 2)
            {
                var statusText = "Inside ICD";
            }
            if(params == 3)
            {
                var statusText = "ICD to Port";
            }
            if(params == 4)
            {
                var statusText = "Inside Port";
            }
            if(params == 5)
            {
                var statusText = "Highseess";
			}
			let eventOptions = {
				"category": this.state.pageTitle,
				"action": this.state.eventCounterAction,
				"label": statusText,
			}
			googleAnalytics.logEvent(eventOptions);
		}
	}

    onClickCounterShowData(params){
        this.logCounterClickGA(params);
       // console.log("params ", params)
        // Log Counter Actions for Google Analytics
        if(params == 0)
        {
            this.setState({
                rowData:this.state.originalData
            })
        }
        if(params == 1)
        {
            this.setState({
                rowData:this.state.msilicdarr
            })
        }
        if(params == 2)
        {
            this.setState({
                rowData:this.state.insideicdarr
            })
        }
        if(params == 3)
        {
            this.setState({
                rowData:this.state.icdtoportarr
            })
        }
        if(params == 4)
        {
            this.setState({
                rowData:this.state.insideportarr
            })
        }
        if(params == 5)
        {
            this.setState({
                rowData:this.state.highseesarr
            })
        }
        if(params == 6)
        {
            this.setState({
                rowData:this.state.mgoroarr
            })
        }
        if(params == 7)
        {
            this.setState({
                rowData:this.state.ropoarr
            })
        }
        if(params == 8)
        {
            this.setState({
                rowData:this.state.podiarr
            })
        }
        
    }
    
    onCellRowClicked(e){
        // if(e.colDef.field == "not_log_sc")
        // {
        //     var container_number = e.data.container_number;
        //     if(container_number !=undefined && container_number !='')
        //     {
        //         var reqparams = {
        //             container_number : container_number,
        //             department_changed_by : localStorage.getItem("username"),
        //             department_changed_user_email : localStorage.getItem("email"),
        //         }
        //         console.log(reqparams);
        //         if (window.confirm("Are You Sure! Do you want really proceed?"))
        //         {
        //             redirectURL.post("/consignments/changeLogScExportsDepartment", reqparams)
        //             .then((response) => {
        //                 if(response.data.message == "Success")
        //                 {
        //                     this.setState({
        //                         basicTitle:"Successfully marked as Not LOG-SC",
        //                         basicType : "success",
        //                         show : true,
        //                         overly:"show-n",
        //                         loadshow:"show-n",
        //                     })
        //                     //window.location.reload();
        //                     this.onLoadDashboardData();
        //                 }
        //                 else
        //                 {
        //                     this.setState({
        //                         basicTitle:"Failed to mark as Not LOG-SC.",
        //                         basicType : "warning",
        //                         show : true
        //                     })
        //                 }
        //             })
        //         }
        //     }
        //     else
        //     {
        //         this.setState({
        //             basicTitle:"Something went wrong.",
        //             basicType : "warning",
        //             show : true
        //         })
        //     }
        // }
        // if(e.colDef.field == "update")
        // {
        //     if(e.data.destination_port == '' || e.data.destination_port == undefined)
        //     {
                
        //     }
        // }
    }
    // updateLmStates(e)
    // {
    //     console.log(e,"update")
    //     var container_number = e.container_number;
    //     if(container_number !=undefined && container_number !='')
    //     {
    //         var export_to = e.export_to;
    //         if(export_to !=undefined && export_to !='')
    //         {
    //             var reqparams = {
    //                 container_number : container_number,
    //                 export_to : export_to,
    //             }
    //             console.log(reqparams);
    //             redirectURL.post("/consignments/updateLogScExports", reqparams)
    //             .then((response) => {
    //                 if(response.data.message == "Success")
    //                 {
    //                     this.setState({
    //                         basicTitle:"Successfully Updated",
    //                         basicType : "success",
    //                         show : true,
    //                         overly:"show-n",
    //                         loadshow:"show-n",
    //                     })
    //                     //window.location.reload();
    //                     this.onLoadDashboardData();
    //                 }
    //                 else
    //                 {
    //                     this.setState({
    //                         basicTitle:"Failed to update.",
    //                         basicType : "warning",
    //                         show : true
    //                     })
    //                 }
    //             })
    //         }
    //         else
    //         {
    //             this.setState({
    //                 basicTitle:"Exports To field can not br empty.",
    //                 basicType : "warning",
    //                 show : true
    //             })
    //         }
    //     }
    //     else
    //     {
    //         this.setState({
    //             basicTitle:"Something went wrong.",
    //             basicType : "warning",
    //             show : true
    //         })
    //     }
    // }
    onClickForceClose(params)
    {
        this.setState({
            loadshow:"show-m"
        })
        var cnf = window.confirm("Are you sure you want force close this item?")
        if(cnf == true)
        {
            // console.log("Params ", params)
            var parameters = {
                rowid:params._id,
                container_no:params.container_no
            }
            redirectURL.post("/consignments/exportsforceclose",parameters)
            .then((response) => {
                // console.log("response ", response.data)
                if(response.data.status == "success")
                {
                    this.setState({
                        show:true,
                        basicTitle:"Successfully force closed item",
                        basicType:"success",
                        loadshow:"show-n"
                    })
                }
                else
                {
                    this.setState({
                        show:true,
                        basicTitle:"Failed to force closed item",
                        basicType:"danger",
                        loadshow:"show-n"
                    })
                }
            })
        }
        else
        {

        }
        
    }

    getFinancialYear=()=>{
        let month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
        const today = new Date();
        const year = today.getFullYear();
        const isAprilOrLater = today.getMonth() >= 3;
        const financialYear = isAprilOrLater ? `${year}-${year + 1}` : `${year - 1}-${year}`;
        const prevYear=financialYear-1

        const currentMonth=today.get
        // console.log(currentMonth,financialYear,prevYear,"ii")
        this.setState({financialYear,prevYear,currentMonth:month[today.getMonth()]})
  
    }

    onClickModal=()=>{
        this.setState({open:true})
        console.log("clicked")
    }

    onFirstDataRendered = (params) => {
        params.api.sizeColumnsToFit();
      };
  


    render(){
    //   const chartSeriesCategories=['JAKARTA', 'Shimizu', 'osaka', 'Laem Chabang', 'Nagoya', 'Jakarta', 'Japan', 'Thailand']
    //   const chartSeriesData=[ 
    //     {name: 'Jakartha', y: 10, showTooltip: 1},
    //     {name: 'Shimizu', y: 6, showTooltip: 1},
    //     {name: 'osaka', y: 1, showTooltip: 1},
    //     {name: 'Laem Chabang', y: 3, showTooltip: 1},
    //     {name: 'Nagoya', y: 7, showTooltip: 1},
       
    //     {name: 'Japan', y: 1, showTooltip: 1},
    //     {name: 'Thailand', y:1, showTooltip: 1}]
        console.log(this.state.rowData)


        const modalStyles  = {
            width:'500px !important',
            }
    const { open } = this.state;
    // var columnwithDefs = [
    //     // {
    //     //     headerName : "",
    //     //     field : "not_log_sc",
    //     //     width:170,
    //     //     editable : false,
    //     //     pinned:"left",
    //     //     cellRenderer: function(params){
    //     //         return "<button class='btn btn-danger' style='padding:0px;width:146px'>Not LOG-SC Container</button>"
    //     //     }
    //     // },
    //     // {
    //     //     headerName : "",
    //     //     field : "update",
    //     //     width:100,
    //     //     editable : false,
    //     //     pinned:"left",
    //     //     cellRendererSelector: function(params){
    //     //         var rendComponent = {
    //     //             component: 'UpdateButton'
    //     //         };
    //     //         if(params.data.export_to == '' || params.data.export_to == undefined)
    //     //         {
    //     //             return rendComponent;
    //     //         }
    //     //     }
    //     // },
    //     {
    //         headerName : "Container No",
    //         field : "container_number",
    //         width:150,
    //         editable : false,
    //         pinned:"left",
    //         resizable:true
    //     },
    //     {
    //         headerName:"Export To",
    //         field:"export_to",
    //         width:150,
    //         editable:true,
    //         pinned:"left",
    //         resizable:true,
    //         cellEditor: 'agSelectCellEditor',
    //         cellEditorParams: {
    //             values: this.state.polList,
    //         },
    //     },
    //     // {
    //     //     headerName:"ICD Gate Out",
    //     //     field:"icd_gate_out",
    //     //     width:180,
    //     //     editable:false,
    //     //     resizable:true,
    //     //     pinned:"left",
    //     //     valueGetter:function(params){
    //     //         try{
    //     //             if(params.data.icd_gate_out != "" && params.data.icd_gate_out != undefined)
    //     //             {
    //     //                 return moment.parseZone(params.data.icd_gate_out).format('DD-MMM-YYYY');
    //     //             }
    //     //             else
    //     //             {
    //     //                 return "";
    //     //             }
    //     //         }
    //     //         catch(e)
    //     //         {
    //     //             return "";
    //     //         }
    //     //     }
    //     // },
    //     {
    //         headerName:"Dispatch Date From MSIL",
    //         field:"dispatch_date_from_msil",
    //         width:180,
    //         editable:false,
    //         pinned:"left",
    //         resizable:true,
    //         valueGetter:function(params){
    //             try{
    //                 if(params.data.dispatch_date_from_msil != "" && params.data.dispatch_date_from_msil != undefined)
    //                 {
    //                     return getDDMMMYYYYHHMMDefault(params.data.dispatch_date_from_msil);
    //                 }
    //                 else
    //                 {
    //                     return "";
    //                 }
    //             }
    //             catch(e)
    //             {
    //                 return "";
    //             }
    //         }
    //     },
    //     {
    //         headerName:"Rail Out",
    //         field:"railout_date",
    //         width:180,
    //         editable:false,
    //         resizable:true,
    //         cellStyle: function(params){
    //             if(params.data.rail_out_transit_delay == 1)
    //             {
    //                 return {background:"#EA4C46"}
    //             }
    //         },
    //         valueGetter:function(params){
    //             try{
    //                 if(params.data.railout_date != undefined && params.data.railout_date != "")
    //                 {
    //                     if(params.data.railout_date.includes("T") == true)
    //                     {
    //                         return getHyphenDDMMYYYYHHMMSS(params.data.railout_date);
    //                     }
    //                     else
    //                     {
    //                         return (params.data.railout_date);
    //                     }
    //                 }
    //                 else
    //                 {
    //                     return "";
    //                 }
    //             }
    //             catch(e)
    //             {
    //                 return "";
    //             }
    //         }
    //     },
    //     {
    //         headerName:"Expected Rail Out(MSIL TT)",
    //         field:"expected_eta_railout",
    //         width:200,
    //         editable:false,
    //         resizable:true,
    //         valueGetter:function(params){
    //             try{
    //                 if(params.data.expected_eta_railout != undefined && params.data.expected_eta_railout != "")
    //                 {
    //                     if(params.data.expected_eta_railout.includes("T") == true)
    //                     {
    //                         return getHyphenDDMMYYYYHHMMSS(params.data.expected_eta_railout);
    //                     }
    //                     else
    //                     {
    //                         return (params.data.expected_eta_railout);
    //                     }
    //                 }
    //                 else
    //                 {
    //                     return "";
    //                 }
    //             }
    //             catch(e)
    //             {
    //                 return "";
    //             }
    //         }
    //     },
    //     {
    //         headerName:"Port Entry",
    //         field:"port_entry_date",
    //         width:180,
    //         editable:false,
    //         resizable:true,
    //         valueGetter:function(params){
    //             try{
    //                 if(params.data.port_entry_date != "" && params.data.port_entry_date != undefined)
    //                 {
    //                     return getDDMMMYYYYHHMMDefault(params.data.port_entry_date);
    //                 }
    //                 else
    //                 {
    //                     return "";
    //                 }
    //             }
    //             catch(e)
    //             {
    //                 return "";
    //             }
    //         }
    //     },
    //     {
    //         headerName:"Status",
    //         field:"status",
    //         width:120,
    //         editable:false,
    //         resizable:true
    //     },
    //     {
    //         headerName:"Transhipment",
    //         field:"is_transshipment",
    //         width:120,
    //         editable:false,
    //         resizable:true,
    //         hide:true,
    //         valueGetter:function(params){
    //             if(params.data.is_transshipment != undefined && params.data.is_transshipment !='')
    //             {
    //                 if(params.data.is_transshipment == 1)
    //                 {
    //                     return "Yes";
    //                 }
    //                 else
    //                 {
    //                     return "No"
    //                 }
    //             }
    //             else
    //             {
    //                 return "No"
    //             }
    //         }
    //     },
    //     {
    //         headerName:"Old Container No",
    //         field:"old_container_no",
    //         hide:true,
    //         width:120,
    //         editable:false,
    //         resizable:true,
    //         valueGetter:function(params){
    //             if(params.data.is_transshipment != undefined && params.data.is_transshipment !='')
    //             {
    //                 if(params.data.is_transshipment == 1)
    //                 {
    //                     return params.data.old_container_no;
    //                 }
    //             }
    //         }
    //     },
    //     {
    //         headerName:"Shipping Line Booking Id",
    //         field:"shipping_line_booking_id",
    //         width:150,
    //         editable:false,
    //         resizable:true
    //     },
    //     {
    //         headerName:"Container Type",
    //         field:"container_type",
    //         width:150,
    //         editable:false,
    //         resizable:true
    //     },
    //     {
    //         headerName:"Nomination Type",
    //         field:"nomination_type",
    //         width:150,
    //         editable:false,
    //         resizable:true
    //     },
    //     {
    //         headerName:"CHA NAME",
    //         field:"cha_name",
    //         width:150,
    //         editable:false,
    //         resizable:true
    //     },
    //     {
    //         headerName:"Requirement ID",
    //         field:"requirement_id",
    //         width:150,
    //         editable:false,
    //         resizable:true
    //     },
    //     {
    //         headerName:"Shipping On Board",
    //         field:"shipping_on_board",
    //         width:150,
    //         editable:false,
    //         resizable:true
    //     },
    //     {
    //         headerName:"Bill Of Lading",
    //         field:"bill_of_lading",
    //         width:150,
    //         editable:false,
    //         resizable:true
    //     },
    //     {
    //         headerName: "Loading",
    //         headerClass:["cellstylegridY","cellTopHeader"],			
    //         children: [
    //             {
    //                 headerName:"Loading Port",
    //                 headerClass:"cellstylegridY",
    //                 field:"loading_port",
    //                 width:180,
    //                 editable:false,
    //                 resizable:true,
    //                 cellClass:'cellstylegridY'
    //             },
    //             {
    //                 headerName:"Loading Vessel",
    //                 headerClass:"cellstylegridY",
    //                 field:"loading_vessel",
    //                 width:180,
    //                 editable:false,
    //                 resizable:true,
    //                 cellClass:'cellstylegridY'
    //             },
    //             {
    //                 headerName:"Booking Id",
    //                 headerClass:"cellstylegridY",
    //                 field:"booking_id",
    //                 width:140,
    //                 editable:false,
    //                 resizable:true,
    //                 cellClass:'cellstylegridY'
    //             },
    //             {
    //                 headerName:"Planned Dispatch From Indian Port",
    //                 headerClass:"cellstylegridY",
    //                 field:"planned_dispatch_from_indian_port",
    //                 width:180,
    //                 editable:false,
    //                 resizable:true,
    //                 cellClass:'cellstylegridY',
    //                 valueGetter:function(params){
    //                     try{
    //                         if(params.data.planned_dispatch_from_indian_port != undefined && params.data.planned_dispatch_from_indian_port != "")
    //                         {
    //                             if(params.data.planned_dispatch_from_indian_port.includes("T") == true)
    //                             {
    //                                 return getHyphenDDMMYYYYHHMMSS(params.data.planned_dispatch_from_indian_port);
    //                             }
    //                             else
    //                             {
    //                                 return (params.data.planned_dispatch_from_indian_port);
    //                             }
    //                         }
    //                         else
    //                         {
    //                             return "";
    //                         }
    //                     }
    //                     catch(e)
    //                     {
    //                         return "";
    //                     }
    //                 }
    //             },
    //             {
    //                 headerName:"Revised/Actual Dispatch from Indian Port",
    //                 headerClass:"cellstylegridY",
    //                 field:"revised_actual_dispatch_from_indian_port",
    //                 width:180,
    //                 editable:false,
    //                 resizable:true,
    //                 cellClass:'cellstylegridY',
    //                 cellStyle: function(params){
    //                     if(params.data.port_out_transit_delay == 1)
    //                     {
    //                         return {background:"#EA4C46"}
    //                     }
    //                 },
    //                 valueGetter:function(params){
    //                     try{
    //                         if(params.data.revised_actual_dispatch_from_indian_port != undefined && params.data.revised_actual_dispatch_from_indian_port != "")
    //                         {
    //                             if(params.data.revised_actual_dispatch_from_indian_port.includes("T") == true)
    //                             {
    //                                 return getHyphenDDMMYYYYHHMMSS(params.data.revised_actual_dispatch_from_indian_port);
    //                             }
    //                             else
    //                             {
    //                                 return (params.data.revised_actual_dispatch_from_indian_port);
    //                             }
    //                         }
    //                         else
    //                         {
    //                             return "";
    //                         }
    //                     }
    //                     catch(e)
    //                     {
    //                         return "";
    //                     }
    //                 }
    //             },
    //             {
    //                 headerName:"Expected ETA Port Out(MSIL TT)",
    //                 headerClass:"cellstylegridY",
    //                 field:"expected_eta_port_out",
    //                 width:200,
    //                 editable:false,
    //                 resizable:true,
    //                 cellClass:'cellstylegridY',
    //                 valueGetter:function(params){
    //                     try{
    //                         if(params.data.expected_eta_port_out != undefined && params.data.expected_eta_port_out != "")
    //                         {
    //                             if(params.data.expected_eta_port_out.includes("T") == true)
    //                             {
    //                                 return getHyphenDDMMYYYYHHMMSS(params.data.expected_eta_port_out);
    //                             }
    //                             else
    //                             {
    //                                 return (params.data.expected_eta_port_out);
    //                             }
    //                         }
    //                         else
    //                         {
    //                             return "";
    //                         }
    //                     }
    //                     catch(e)
    //                     {
    //                         return "";
    //                     }
    //                 }
    //             },
    //         ]
    //     },
    //     {
    //         headerName: "Transshipment",
    //         headerClass:["cellstylegridB","cellTopHeader"],			
    //         children: [
    //             {
    //                 headerName:"Transshipment Port",
    //                 headerClass:"cellstylegridB",
    //                 field:"transshipment_port",
    //                 width:180,
    //                 editable:false,
    //                 resizable:true,
    //                 cellClass:'cellstylegridB'
    //             },
    //             {
    //                 headerName:"Planned Arrival at Singapore",
    //                 headerClass:"cellstylegridB",
    //                 field:"planned_dispatch_from_transshipment_port",
    //                 width:180,
    //                 editable:false,
    //                 resizable:true,
    //                 cellClass:'cellstylegridB',
    //                 valueGetter:function(params){
    //                     try{
    //                         if(params.data.planned_dispatch_from_transshipment_port != undefined && params.data.planned_dispatch_from_transshipment_port != "")
    //                         {
    //                             if(params.data.planned_dispatch_from_transshipment_port.includes("T") == true)
    //                             {
    //                                 return getHyphenDDMMYYYYHHMMSS(params.data.planned_dispatch_from_transshipment_port);
    //                             }
    //                             else
    //                             {
    //                                 return (params.data.planned_dispatch_from_transshipment_port);
    //                             }
    //                         }
    //                         else
    //                         {
    //                             return "";
    //                         }
    //                     }
    //                     catch(e)
    //                     {
    //                         return "";
    //                     }
    //                 }
    //             },
    //             {
    //                 headerName:"Revised /Actual  arrival at Singapore",
    //                 headerClass:"cellstylegridB",
    //                 field:"revised_actual_dispatch_from_transshipment_port",
    //                 width:180,
    //                 editable:false,
    //                 resizable:true,
    //                 cellClass:'cellstylegridB',
    //                 valueGetter:function(params){
    //                     try{
    //                         if(params.data.revised_actual_dispatch_from_transshipment_port != undefined && params.data.revised_actual_dispatch_from_transshipment_port != "")
    //                         {
    //                             if(params.data.revised_actual_dispatch_from_transshipment_port.includes("T") == true)
    //                             {
    //                                 return getHyphenDDMMYYYYHHMMSS(params.data.revised_actual_dispatch_from_transshipment_port);
    //                             }
    //                             else
    //                             {
    //                                 return (params.data.revised_actual_dispatch_from_transshipment_port);
    //                             }
    //                         }
    //                         else
    //                         {
    //                             return "";
    //                         }
    //                     }
    //                     catch(e)
    //                     {
    //                         return "";
    //                     }
    //                 }
    //             },
    //             {
    //                 headerName:"Transshipment Vessel",
    //                 headerClass:"cellstylegridB",
    //                 field:"transshipment_vessel",
    //                 width:180,
    //                 editable:false,
    //                 resizable:true,
    //                 cellClass:'cellstylegridB'
    //             },
    //             {
    //                 headerName:"Planned ETD Singapore",
    //                 headerClass:"cellstylegridB",
    //                 field:"planned_etd_from_transshipment_port",
    //                 width:180,
    //                 editable:false,
    //                 resizable:true,
    //                 cellClass:'cellstylegridB',
    //                 valueGetter:function(params){
    //                     try{
    //                         if(params.data.planned_etd_from_transshipment_port != undefined && params.data.planned_etd_from_transshipment_port != "")
    //                         {
    //                             if(params.data.planned_etd_from_transshipment_port.includes("T") == true)
    //                             {
    //                                 return getHyphenDDMMYYYYHHMMSS(params.data.planned_etd_from_transshipment_port);
    //                             }
    //                             else
    //                             {
    //                                 return (params.data.planned_etd_from_transshipment_port);
    //                             }
    //                         }
    //                         else
    //                         {
    //                             return "";
    //                         }
    //                     }
    //                     catch(e)
    //                     {
    //                         return "";
    //                     }
    //                 }
    //             },
    //             {
    //                 headerName:"Revised/Actual ETD Singapore",
    //                 headerClass:"cellstylegridB",
    //                 field:"revised_actual_etd_from_transshipment_port",
    //                 width:180,
    //                 editable:false,
    //                 resizable:true,
    //                 cellClass:'cellstylegridB',
    //                 valueGetter:function(params){
    //                     try{
    //                         if(params.data.revised_actual_etd_from_transshipment_port != undefined && params.data.revised_actual_etd_from_transshipment_port != "")
    //                         {
    //                             if(params.data.revised_actual_etd_from_transshipment_port.includes("T") == true)
    //                             {
    //                                 return getHyphenDDMMYYYYHHMMSS(params.data.revised_actual_etd_from_transshipment_port);
    //                             }
    //                             else
    //                             {
    //                                 return (params.data.revised_actual_etd_from_transshipment_port);
    //                             }
    //                         }
    //                         else
    //                         {
    //                             return "";
    //                         }
    //                     }
    //                     catch(e)
    //                     {
    //                         return "";
    //                     }
    //                 }
    //             }
    //         ]
    //     },
    //     {
    //         headerName: "Destination",
    //         headerClass:["cellstylegridG","cellTopHeader"],			
    //         children: [
    //             {
    //                 headerName:"Planned arrival at Destination Port",
    //                 headerClass:"cellstylegridG",
    //                 field:"planned_dispatch_from_destination_port",
    //                 width:180,
    //                 editable:false,
    //                 resizable:true,
    //                 cellClass:'cellstylegridG',
    //                 valueGetter:function(params){
    //                     try{
    //                         if(params.data.planned_dispatch_from_destination_port != undefined && params.data.planned_dispatch_from_destination_port != "")
    //                         {
    //                             if(params.data.planned_dispatch_from_destination_port.includes("T") == true)
    //                             {
    //                                 return getHyphenDDMMYYYYHHMMSS(params.data.planned_dispatch_from_destination_port);
    //                             }
    //                             else
    //                             {
    //                                 return (params.data.planned_dispatch_from_destination_port);
    //                             }
    //                         }
    //                         else
    //                         {
    //                             return "";
    //                         }
    //                     }
    //                     catch(e)
    //                     {
    //                         return "";
    //                     }
    //                 }
    //             },
    //             {
    //                 headerName:"Revised/Actual  arrival at Destination Port",
    //                 headerClass:"cellstylegridG",
    //                 field:"revised_actual_dispatch_from_destination_port",
    //                 width:180,
    //                 editable:false,
    //                 resizable:true,
    //                 cellClass:'cellstylegridG',
    //                 cellStyle: function(params){
    //                     if(params.data.dest_in_transit_delay == 1)
    //                     {
    //                         return {background:"#EA4C46"}
    //                     }
    //                 },
    //                 valueGetter:function(params){
    //                     try{
    //                         if(params.data.revised_actual_dispatch_from_destination_port != undefined && params.data.revised_actual_dispatch_from_destination_port != "")
    //                         {
    //                             if(params.data.revised_actual_dispatch_from_destination_port.includes("T") == true)
    //                             {
    //                                 return getHyphenDDMMYYYYHHMMSS(params.data.revised_actual_dispatch_from_destination_port);
    //                             }
    //                             else
    //                             {
    //                                 return (params.data.revised_actual_dispatch_from_destination_port);
    //                             }
    //                         }
    //                         else
    //                         {
    //                             return "";
    //                         }
    //                     }
    //                     catch(e)
    //                     {
    //                         return "";
    //                     }
    //                 }
    //             },
    //             {
    //                 headerName:"Expected ETA Destination In(MSIL TT)",
    //                 headerClass:"cellstylegridG",
    //                 field:"expected_eta_dest_in",
    //                 width:200,
    //                 editable:false,
    //                 resizable:true,
    //                 cellClass:'cellstylegridG',
    //                 valueGetter:function(params){
    //                     try{
    //                         if(params.data.expected_eta_dest_in != undefined && params.data.expected_eta_dest_in != "")
    //                         {
    //                             if(params.data.expected_eta_dest_in.includes("T") == true)
    //                             {
    //                                 return getHyphenDDMMYYYYHHMMSS(params.data.expected_eta_dest_in);
    //                             }
    //                             else
    //                             {
    //                                 return (params.data.expected_eta_dest_in);
    //                             }
    //                         }
    //                         else
    //                         {
    //                             return "";
    //                         }
    //                     }
    //                     catch(e)
    //                     {
    //                         return "";
    //                     }
    //                 }
    //             },
    //             {
    //                 headerName:"Destination Arrival Status",
    //                 headerClass:"cellstylegridG",
    //                 field:"destination_arrival_status",
    //                 width:180,
    //                 editable:false,
    //                 resizable:true,
    //                 cellClass:'cellstylegridG'
    //             },
    //             {
    //                 headerName : "Force Close",
    //                 field : "",
    //                 width:100,
    //                 editable : false,
    //                 cellRendererSelector: function(params){
    //                     var rendComponent = {
    //                         component: 'ForceCloseButton'
    //                     };
    //                     return rendComponent;
                        
    //                 }
    //             }

    //         ]
    //     }
    // ]
  var columnwithDefs=[
            {
                headerName : "Container No",
                field : "container_number",
                width:150,
                editable : false,
                pinned:"left",
                resizable:true
            },
            {
            headerName:"Export To",
                field:"destination_port",
                width:150,
                editable:true,
                pinned:"left",
                resizable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: this.state.polList,
                }
            },
                {
                                    headerName:"Planned arrival at Destination Port",
                                    headerClass:"cellstylegridG",
                                    field:"planned_dispatch_from_destination_port",
                                    width:180,
                                    editable:false,
                                    resizable:true,
                                    cellClass:'cellstylegridG',
                                    valueGetter:function(params){
                                        try{
                                            if(params.data.planned_dispatch_from_destination_port != undefined && params.data.planned_dispatch_from_destination_port != "")
                                            {
                                                if(params.data.planned_dispatch_from_destination_port.includes("T") == true)
                                                {
                                                    return getHyphenDDMMYYYYHHMMSS(params.data.planned_dispatch_from_destination_port);
                                                }
                                                else
                                                {
                                                    return (params.data.planned_dispatch_from_destination_port);
                                                }
                                            }
                                            else
                                            {
                                                return "";
                                            }
                                        }
                                        catch(e)
                                        {
                                            return "";
                                        }
                                    }
                                },
                                {
                                    headerName:"Revised/Actual  arrival at Destination Port",
                                    headerClass:"cellstylegridG",
                                    field:"revised_actual_dispatch_from_destination_port",
                                    width:180,
                                    editable:false,
                                    resizable:true,
                                    cellClass:'cellstylegridG',
                                    cellStyle: function(params){
                                        if(params.data.dest_in_transit_delay == 1)
                                        {
                                            return {background:"#EA4C46"}
                                        }
                                    },
                                    valueGetter:function(params){
                                        try{
                                            if(params.data.revised_actual_dispatch_from_destination_port != undefined && params.data.revised_actual_dispatch_from_destination_port != "")
                                            {
                                                if(params.data.revised_actual_dispatch_from_destination_port.includes("T") == true)
                                                {
                                                    return getHyphenDDMMYYYYHHMMSS(params.data.revised_actual_dispatch_from_destination_port);
                                                }
                                                else
                                                {
                                                    return (params.data.revised_actual_dispatch_from_destination_port);
                                                }
                                            }
                                            else
                                            {
                                                return "";
                                            }
                                        }
                                        catch(e)
                                        {
                                            return "";
                                        }
                                    }
                                },
                                {
                                    headerName:"Expected ETA Destination In(MSIL TT)",
                                    headerClass:"cellstylegridG",
                                    field:"expected_eta_dest_in",
                                    width:200,
                                    editable:false,
                                    resizable:true,
                                    cellClass:'cellstylegridG',
                                    valueGetter:function(params){
                                        try{
                                            if(params.data.expected_eta_dest_in != undefined && params.data.expected_eta_dest_in != "")
                                            {
                                                if(params.data.expected_eta_dest_in.includes("T") == true)
                                                {
                                                    return getHyphenDDMMYYYYHHMMSS(params.data.expected_eta_dest_in);
                                                }
                                                else
                                                {
                                                    return (params.data.expected_eta_dest_in);
                                                }
                                            }
                                            else
                                            {
                                                return "";
                                            }
                                        }
                                        catch(e)
                                        {
                                            return "";
                                        }
                                    }
                                },
            
  ]
       
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>


                <Modal open={open} onClose={this.onCloseModal} styles={modalStyles} >
	                <div className={"row"} style={{ height: '580px',padding:'5px 0px', width: '1185px', overflow: 'hidden',zIndex:'9999',}} >
	                	<div className={"col-sm-8 col-lg-8 col-md-8"} style={{padding:'20px', background:'#fff'}}>
	                		{/* <div style={{position:'absolute',top:'5px',right:'10px',padding:'0px'}}>
	                			<a href={"#"} title="Close" style={{fontSize:'12px', color:'#333', textDecoration:'none'}} onClick={this.onCloseModal}>
	                				<img src={require("../../assets/icons/close.png")} style={{width:'8px',marginTop:'-2px'}} title="Close" />
	                				 &nbsp;Close
	                			</a>
	                		</div>
							 */}
                              <div >
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span> Export Summary </span>                                    
                            </h5>
                        </div>	
	                		<div id="myGrid" style={{ height: "580px",width: "1185px",marginTop:'10px'}}  className="ag-theme-balham">
                            <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={false}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        frameworkComponents={this.state.frameworkComponents}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        components={this.state.components}
                                        rowSelection={this.state.rowSelection}
                                        suppressRowClickSelection={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        onCellClicked={this.onCellRowClicked.bind(this)}
                                        
                                    />

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>

		          		        </div>
	                	</div>
	                </div>
	           </Modal>	

                <div className="card col-xl-12">
                <div className="card-header text-center">
            <h5 style={{ border: "1px solid #ccc", padding: "1%", fontSize:"20px" }}>
              <span className="" style={{position:"relative",left:"9%"}}>EXPORT LOGISTICS</span>
              <span style={{position:"relative",right:"-35%",fontSize:"15px",color:"#171C8F"}}>
                        Updated on : {this.state.lastRunTime}
                    </span>
            </h5>
          </div>
          <div className='row'>
            <div className='col-xl-12 col-lg-12' style={{textAlign:"center",marginBottom:"5px",marginTop:"5px"}}>
          <span style={{fontSize:"17px",fontWeight:"bold",border:"1px solid black",textAlign:"center",padding:"5px"}}>Export-Containers</span>
          </div>
          <div onClick={this.onClickModal} className='col-xl-8 col-lg-8'>
            
                    <ExportsTrackingLedComponent
                        context={this}
                        highseescnt={this.state.highseescnt}
                        insideportcnt={this.state.insideportcnt}
                        icdtoportcnt={this.state.icdtoportcnt}
                        insideicdcnt={this.state.insideicdcnt}
                        msilicdcnt={this.state.msilicdcnt}
                        allRowDatacount={this.state.originalData.length}
                        dealyCounters={1}
                        mgorocnt={this.state.mgorocnt}
                        ropocnt={this.state.ropocnt}
                        podicnt={this.state.podicnt}
                        avgCounters={0}
                    />
                </div>
                <div className='col-xl-4 col-lg-4'>
                <LedScreenPieChartComponent
                                    chartType="pie"
                                    title={"Country wise(TEUs)"}
                                    subtitle={""}
                                    context={this}
                                    series={this.state.chartSeriesData}
                                    categories={this.state.chartSeriesCategories}
                                    seriesName=""
                                    yaxistitle="Orders For Load Plan"
                                    drilldownseries={[]}
                                    innerSize="0%"
                                    format='<b>{point.name}-{point.y}</b>'
                                    
                            />
                </div>
                </div>
                <div className='col-xl-12 col-lg-12' style={{textAlign:"center",marginBottom:"10px"}}>
                <span style={{position:"relative",right:"30%",fontSize:"15px",color:"#171C8F"}}>
                        Last updated on : {moment.parseZone(this.state.lastUpdated).format("YYYY-MM-DD HH:mm:ss")}
                    </span>
          <span style={{fontSize:"17px",fontWeight:"bold",border:"1px solid black",position:"relative",right:"10%",padding:"5px"}}>Customs Clearence</span>
          </div>
                <div className="col-xl-12 col-lg-12">
              <table className="table table-bordered  text-center" style={{fontSize:"15px",borderColor:"#ccc"}}>
                
                    <tr style={{backgroundColor:"#ffffc9",borderColor:"#ccc"}}>
                    
                    <th style={{borderColor:"#ccc"}} colSpan={5}>Sea Import Bills Of Entry Status(Nos.)</th>
                    </tr>
                    <tr style={{backgroundColor:"#ffffc9",borderColor:"#ccc"}}>
                        <th style={{borderColor:"#ccc"}}>Filed for current FY</th>
                        <th style={{borderColor:"#ccc"}}>Pending under<br/> Query</th>
                        <th style={{borderColor:"#ccc"}}>Pending for<br/> Assessment</th>
                        <th style={{borderColor:"#ccc"}}>Pending for Out<br/> of Charge</th>
                        <th style={{borderColor:"#ccc"}}>Pending for<br/> Duty Payment</th>
                    </tr>
                    <tr>
                        <td style={{borderColor:"#ccc"}}>{this.state.field1}</td>
                        <td style={{borderColor:"#ccc"}}>{this.state.field2}</td>
                        <td style={{borderColor:"#ccc"}}>{this.state.field3}</td>
                        <td style={{borderColor:"#ccc"}}>{this.state.field4}</td>
                        <td style={{borderColor:"#ccc"}}>{this.state.field5}</td>
                    </tr>
                </table>
                </div>
                <div className="row col-xl-12 col-lg-12" style={{marginTop:"10px",marginBottom:"10px",paddingLeft:"10px"}} >
                  <div className="col-xl-4 col-lg-4" >
                <table className="table table-bordered rail-stage-tbl text-center" style={{fontSize:"15px"}}>
                
                <tr>
                
                <th colSpan={2}>Import Value(MRs)</th>
                </tr>
                <tr>
                    <th>FY {this.state.financialYear}</th>
                   <td>{this.state.importValue1}</td>
                </tr>
                <tr>
                    <th>{this.state.currentMonth}</th>
                    <td>{this.state.importValue2}</td>
                    
                </tr>
            </table>
            </div>
            <div className="col-xl-4 col-lg-4" >
                <table className="table table-bordered rail-stage-tbl text-center" style={{fontSize:"15px",marginLeft:"30px"}}>
                
                <tr>
                
                <th colSpan={2}>Import Duty(MRs)</th>
                </tr>
                <tr>
                    <th>FY {this.state.financialYear}</th>
                   <td>{this.state.importDuty1}</td>
                </tr>
                <tr>
                    <th>{this.state.currentMonth}</th>
                    <td>{this.state.importDuty2}</td>
                    
                </tr>
            </table>
            </div>
            <div className="col-xl-4 col-lg-4" >
                <table className="table table-bordered rail-stage-tbl text-center" style={{fontSize:"15px",marginLeft:"40px"}}>
                
                <tr>
                
                <th colSpan={2}>LPC & interest(MRs)</th>
                </tr>
                <tr>
                    <th>FY {this.state.financialYear}</th>
                   <td>{this.state.lpc1}</td>
                </tr>
                <tr>
                    <th>{this.state.currentMonth}</th>
                    <td>{this.state.lpc2}</td>
                    
                </tr>
            </table>
            </div>
                </div>
                </div>
            </div>
        );
    }
}







import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';

import UpdateItem from './updatetransporteritem';

import redirectURL from '../redirectURL';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");


export default class MLLHandOverRetrivals extends Component{
    constructor(props) {
        super(props);
        this.state={
            modules: AllModules,
            // truckData:this.props.truckInfo,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            
            truckNumList:"",
            loadshow:'show-n',
            truckLocation:"",
            replaced_date_time:"",
            route_details:"",
            rowData:"",
            showTrucksList:"show-n",
            modules:AllModules,
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            frameworkComponents: {
				updateItem:UpdateItem
			},

            route_info:"",
			rowData: null,
			animateRows: true,
            consignment_details:"",
            dept_code:"",
            replacement_truck:"",
            pagetitle:"",
            showonlytransport:'show-m',
            reason:'',
            overly :"show-n",
            filteredData : [],
            sourceplants:[],
            sources:[]
        };
        this.onLoadData = this.onLoadData.bind(this);
        this.updateRowHandler = this.updateRowHandler.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        this.setState({
            //overly : "show-m",
            loadshow:'show-m',
        })
        //$('#replacement-submit').prop("disabled",true);
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        var pagetitle="Device Handover (Plant) "
        var dept_code = "SNDG";
        var israil=0;
       
       
        this.setState({
            
            eventAction:eventAction,
            dept_code:dept_code,
            pagetitle:pagetitle
        });
        redirectURL.post("/consignments/mllplants")
		.then((response) => {
            var records = response.data.records;
            var sourceplants=[]
            var destplants=[]
            if(records.length > 0)
            {
                records.map((item) => {
                    if(item.location_type == "Plant")
                    {
                        sourceplants.push(item.plant_name)
                    }
                    destplants.push(item.plant_name)
                })
            }
			this.setState({
                sources:records,
                sourceplants:sourceplants,
                destplants:destplants
			});
		})
       this.onLoadData()

    }
    onLoadData(){
        redirectURL.post("/mllconsignments/mllhandoverretrivals")
        .then((response) => {
            console.log("recp ", response.data.records)
            var records = response.data.records
            this.setState({
                rowData:records,
                overly:"show-n",
                loadshow:"show-n"
            })
        })
    }

    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
	}
    selectTruckNum(){
        let arr=[];
        try{
            this.state.truckNumList.forEach(item =>{
            //console.log("pod ",item)
            arr.push({value:item,label:item}); 
        });
        return arr;
        }
        catch(e){

        }
        
    }
    getTruckConsignments(params){
        params.preventDefault()
        console.log("params",params)
        this.setState({
            rowData:[]
        })
        try{
            if(params != null){
                console.log("@ss")
                
                var trucks=[];
                
                trucks.push($("#inputTruck").val())
                console.log(trucks,params);
                var reqParams = {
                    truck_no:trucks
                }
                //console.log("Trucks ",reqParams) 
                redirectURL.post("/consignments/getTruckConsignments",reqParams)
                .then((response) =>{
                    console.log(response.data);
                    this.setState({
                        rowData:response.data.consignments,
                        loadshow:'show-n'
                    })
                }).catch(function (error){
                    console.log(error);
                });
                // loadDateTimeScript();
                $('#replacement-submit').prop("disabled",false);
            }
        }
        catch(e){

        }
    }
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
    onRowSelection(event){
		var containerslist=[];
		//console.log("rowselect", event)
		var rwCount = event.api.getSelectedNodes();
		console.log("rwCount ", rwCount)
		//if(event.node.selected == true)
		//{
			//var containerlist=[];
			console.log("rowselect", event.data)
			var rowCount =  event.data
			//console.log("rowCount ", rowCount)
			
			rwCount.map((item) => {
				containerslist.push(item.data.truck_no)
			});
			
			this.setState({
				containerslist:containerslist
			})
			 console.log("checkedlist ", containerslist)
		// }
		// else{
		// 	if(containerslist.length > 0)
		// 	{
		// 		containerslist=[]
		// 		this.setState({
		// 			containerslist:containerslist
		// 		})
		// 	}
		// }
		
    }
    
    
    updateRowHandler(cell)
	{
        if (googleAnalytics.page.enableGA) {
            let pageTitle = "Rake Device Handover (Plant)";
                       
            let eventOptions = {
                "category": pageTitle,
                "action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
                "label": googleAnalytics.page.action.rowUpdated,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        var truckData = [cell];
       
        if(truckData.length > 0)
        {
            var reqParams = {
                truckData : truckData,
                email:localStorage.getItem("email"),
                username : localStorage.getItem("username"),
                userid : localStorage.getItem("userid")
                
            } 
            //console.log("reqParams " , reqParams)
            redirectURL.post("/mllconsignments/savemllhandoverretrival",reqParams)
            .then((response)=>{
                // console.log(response.data.statusMessage);
                // if(response.data.statusMessage == "success"){
                    this.setState({
                        show:true,
                        basicTitle:"Successfully update data .",
                        basicType:"success",
                        loadshow : "show-n",
                        overly : "show-n"
                    });
                    
                    //window.location.reload();
                // }
            }).catch(function (error){
                console.log(error);
            });
        }
        else
        {
            this.setState({
                show:true,
                basicTitle:"Please check the checkbox",
                basicType:"info",
                loadshow : "show-n",
                overly : "show-n"
            });
        }
        
    }

    
    closeAlert = async () => {
        await this.setState({
            show: false
        });
        await window.location.reload();
    }
    // AUTOMCOMPLETE DROPDOWN
    initalList =(event)=>{
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckNumList;
        dataset = dataset.slice(0,100);
        this.setState({
            filteredData : dataset,
            showTrucksList : "show-m",
        })
    }
    handlerForm = (event) => {
    	//console.log("Select", event.target.value);
    	// let name = event.target.name;
        // let value = event.target.value;
        // this.setState({[name]:value});
        try
        {
            $(".trucks-dropdown").removeClass("show-n");
            var dataset = this.state.truckNumList;
            var filteredResult = dataset.filter(function(e){
                if(e != "" && e != null)
                {
                    return e.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
                }
                
            });
            filteredResult = filteredResult.slice(0,100);
            this.setState({
                filteredData : filteredResult,
                showTrucksList : "show-m",
            })
        }catch(e){}
        
    }
    onClickTruck(event)
    {
         $("#inputTruck").val($(this).text());
         console.log($("#inputTruck").val());
    }
    hideList(){
        this.setState({
			loadshow:'show-n',
			overly:'show-n',
			showTrucksList : "show-n",
        });
    }
    // AUTOCOMPLETE DROPDOWN END
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			showTrucksList : "show-n",
        });
        
    }
    render(){
        const columnwithDefs = [
            {
                headerName: "Action",
                field: "_id",
                width: 100,
                filter: false,
                pinned:"left",
                cellRenderer:'updateItem',
                editable:false
            },
            // {
            //     headerName: "Vin No",
            //     field: "deviceplaceat",
            //     pinned: 'left',
            //     filter: true,
            //     resizable: true,
            //     editable:false,
            //     headerCheckboxSelection: false,
            //     checkboxSelection: true
            // },
           
            {
                headerName: "GPS Device",
                field: "device_code",
                width:140,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "GPS Device Owner",
                field: "device_owner",
                width:140,
                filter: true,
                resizable: true,
                editable:false,
                // valueGetter:function(params)
                // {
                //     try{
                //         return params.data.deviceinfo[0].device_owner;
                //     }
                //     catch(e){
                //         return "";
                //     }
                // }
            },
            
            {
                headerName: "Shipped From",
                field: "gps_device_received_remarks",
                width:240,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    try{
                        if(params.data.consignment.length > 0)
                        {
                            if( params.data.consignment[0].gpsdevice_status == 4 ||  params.data.consignment[0].gpsdevice_status == 5)
                            {
                                return "Loading Yard";
                            }
                            else if( params.data.consignment[0].gpsdevice_status == 14)
                            {
                                return "From RSO";
                            }
                            else
                            {
                                return "";
                            }
                        }
                        else
                        {
                            if( params.data.status == 4 ||  params.data.status == 5)
                            {
                                return "Loading Yard";
                            }
                            else if( params.data.status == 14)
                            {
                                return "From RSO";
                            }
                            else
                            {
                                return "";
                            }
                        }
                        
                    }
                    catch(e){
                        return "";
                    }
                    
                }
            
            },
            {
                headerName: "Update By",
                field: "first_mile_retrival_username",
                width:240,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    try{
                        if(params.data.consignment.length > 0)
                        {
                            if( params.data.consignment[0].gpsdevice_status == 4 ||  params.data.consignment[0].gpsdevice_status == 5)
                            {
                                return  params.data.consignment[0].first_mile_retrival_username;
                            }
                            else if( params.data.consignment[0].gpsdevice_status == 15)
                            {
                                return  params.data.consignment[0].ship_to_plant_rso_retrival_username;
                            }
                            else
                            {
                                return "";
                            }
                        }
                        else
                        {
                            
                            return "";
                            
                        }
                        
                    }
                    catch(e){
                        return "";
                    }
                    
                }
            
            },
            {
                headerName: "Update On",
                field: "first_mile_retrival_on",
                width:240,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    try{
                        if(params.data.consignment.length > 0)
                        {
                            if( params.data.consignment[0].gpsdevice_status == 4 ||  params.data.consignment[0].gpsdevice_status == 5)
                            {
                                return getDDMMYYYYHHMMSS( params.data.consignment[0].first_mile_retrival_on);
                            }
                            else if( params.data.consignment[0].gpsdevice_status == 15)
                            {
                                return getDDMMYYYYHHMMSS( params.data.consignment[0].gps_device_ship_to_plant_rso_on);
                            }
                            else
                            {
                                return "";
                            }
                        }
                        else
                        {
                            return "";
                        }
                        
                    }
                    catch(e){
                        return "";
                    }
                    
                }
            
            },
            {
                headerName: "Received Plant",
                field: "gps_device_received_plant",
                width:140,
                resizable: true,
                cellEditor: 'agSelectCellEditor',   
                cellEditorParams: {
                    values: this.state.sourceplants,
                }   
            },
            {
                headerName: "Received On",
                field: "gps_device_receivedon",
                width:140,
                editable:true,
                resizable: true,
                cellEditor: DateEditor,
                valueGetter:function(params){
                    try{
                        //console.log("params ", params);
                        if ( params.data.consignment[0].gps_device_receivedon != null)
                        {
                            if(moment( params.data.consignment[0].gps_device_receivedon,'DD-MM-YYYY hh:mm',true).isValid() == false)
                            {
                                return getDDMMYYYYHHMMSS( params.data.consignment[0].gps_device_receivedon);
                            }
                            else{
                                return  params.data.consignment[0].gps_device_receivedon;
                            }
                            
                        }
                        else
                        {
                            return ""
                        }
                    }
                    catch(e){
                        return "";
                    }
                    

                }  
            },
            {
                 headerName: "Received By",
                 field: "gps_device_receivedby",
                 width:140,
                 resizable: true,
                 editable:true
             
             },
             {
                  headerName: "Remarks",
                  field: "gps_device_received_remarks",
                  width:240,
                  resizable: true,
                  editable:true
              
              },

        ]
        var listData = [] 
        try{
            this.state.filteredData.map(function(e){
                listData.push(<li key={e} className="dropdown-truck-no">{e}</li>)
                })
        }catch(e){}
        
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    <div className=" col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
                                </h5>
                            </div>
                            <div className="card-body">
                                <div className="col-xl-12 col-lg-12">
                                {/* {this.state.rowData != null ? */}
                                    <div id="myGrid" style={{width:"100%",height:"360px"}} className="ag-theme-balham">    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        rowSelection={this.state.rowSelection}
                                        frameworkComponents={this.state.frameworkComponents}
                                        // detailCellRendererParams={this.state.detailCellRendererParams}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        //floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        // rowClassRules={this.state.rowClassRules}
                                        //onCellClicked={this.onRowClicked.bind(this)}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        onRowSelected={this.onRowSelection.bind(this)}
                                        suppressRowClickSelection={true}
                                        />

                                    </div>

                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div> 
                
                
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                    </div>
                    <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                        <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                    </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        )
    }
}

        
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}
$(document).ready(function(){
    $(document).on("click",".dropdown-truck-no",function(){
        var vals = $(this).text();
         $("#inputTruck").val(vals);
         $(".trucks-dropdown").removeClass("show-m");
         $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click",function(e){
        var container = $("#inputTruck");
        if(!container.is(e.target))
        {
            try{
                if($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown")
                {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            }catch(e){}
            
        }
        
        
    })
})

var todaydateis = moment.parseZone().format("YYYY/MM/DD")

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // console.log("todaydateis ",todaydateis)
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        minDate:todaydateis
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

import React, { useState, useEffect, useRef } from "react";
// import { Link, redirect, useNavigate } from "react-router-dom";
import MainTrackingCountersImports from "./mainCountersImports.js";
import SweetAlert from 'react-bootstrap-sweetalert';
import CreateShipmentIcon from "../../assets/icons/create-shipment-icon.svg";
import CargoShipBlack from "../../assets/icons/cargo-ship-black.svg";
import SearchIcon from "../../assets/icons/search-icon.svg";
import FilterIcon from "../../assets/icons/filter-icon.svg";
import SortIcon from "../../assets/icons/sort-icon.svg";
import GridViewActive from "../../assets/icons/grid-view-white.svg";
import GridViewInactive from "../../assets/icons/grid-view-blue.svg";
import TileViewActive from "../../assets/icons/tile-view-white.svg";
import TileViewInactive from "../../assets/icons/tile-view-blue.svg";
import DownloadIcon from "../../assets/icons/download-icon.svg";
import TrackingTile from "./ContainerTileImports.js";
import redirectURL from "../redirectURL";
import EximGridImports from "./EximGridImports.js";
import Modal from "react-responsive-modal";
import Select from "react-select";
import { countries } from "country-flags-svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import BootstrapLoader from "./BootstrapLoader";
import ReactTooltip from 'react-tooltip';
import LocationBlue from "../../assets/icons/location-blue.svg";
import LocationWhite from "../../assets/icons/location-white.svg";
import ShipmentBlue from "../../assets/icons/shipment-blue.svg";
import ShipmentWhite from "../../assets/icons/shipment-white.svg";
import EmissionCloud from '../../assets/icons/co2-1.svg'
import InfoIcon from '../../assets/icons/info.png';
import IoSettingsOutline from "../../assets/icons/setting-icon.svg";
import FlagBlue from "../../assets/icons/flag-blue.svg";
import FlagWhite from "../../assets/icons/flag-white.svg";
import FaGlobeBlue from "../../assets/icons/globe-blue-icon.svg";
import FaGlobe from "../../assets/icons/globe-icon.svg";
var moment = require('moment');


const containerTrackingImports = () => {
    //   const navigate = useNavigate();
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [openSubCounterChoise, setOpenSubCounterChoise] = useState(false)
    const [container_data_Temission, setContainerTemission] = useState("")
    let emissionIcon = EmissionCloud;
    const [ULIPData, setULIPData] = useState([]);
    const [gridLoader, setGridLoader] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [show, setShow] = useState(false)
    const [basicType, setBasicType] = useState("default")
    const [basicTitle, setBasicTitle] = useState()


    const counters = useRef([
        {
            id: 1,
            title: "Origin",
            imgSrc: LocationBlue,
            activeImgSrc: LocationWhite,
            subCounters: [
                { label: "In-Transit to Consignor", value: "in_transit_to_consignee" },
                { label: "At Consignor", value: "at_consignee" },
                { label: "In-transit to first port", value: "in_transit_to_first_port" },
                { label: "At Port(other than POL)", value: "at_port" },
                { label: "In-transit at POL", value: "in_transit_to_pol" },
                { label: "At POL", value: "at_pol" }
            ]
        },
        {
            id: 2,
            title: "In Transit",
            imgSrc: ShipmentBlue,
            activeImgSrc: ShipmentWhite,
            subCounters: [
                { label: "High Seas", value: "high_seas" },
                { label: "At Transhipment Port", value: "at_transshipment" },
                // {label:"At POD",value:""}, 
                // {label:"In-Transit to Final Destination",value:""}
            ]
        },
        {
            id: 3, 
            title: "Destination",
            imgSrc: FlagBlue,
            activeImgSrc: FlagWhite,
            subCounters: [
                { label: "At POD", value: "at_pod" },
                { label: "In-Transit to Final Port", value: "in_transit_to_final_port" },
                { label: "At ICD", value: "at_final_port" },
                { label: "Out For Delivery", value: "out_for_final_delivery" },
                { label: "At Customer", value: "at_customer" }
            ]
        },
        {
            id: 4,
            title: "API",
            imgSrc: FlagBlue,
            activeImgSrc: FlagWhite,
            subCounters: [
                { label: "Serates", value: "serates" }
            ]
        }
    ])
    const sub_counter = ["in_transit_to_consignee", "at_consignee", "in_transit_to_first_port", "at_port", "in_transit_to_pol", "at_pol", "high_seas", "at_transshipment", "at_pod", "in_transit_to_final_port", "at_final_port", "out_for_final_delivery", "at_customer"]
    const subcountersCheckbox = useRef(["in_transit_to_consignee", "at_consignee", "in_transit_to_first_port", "at_port", "in_transit_to_pol", "at_pol", "high_seas", "at_transshipment", "at_pod", "in_transit_to_final_port", "at_final_port", "out_for_final_delivery", "at_customer", "serates"]);
    const [checkedCheckboxes, setCheckedCheckboxes] = useState(["in_transit_to_consignee", "at_consignee", "in_transit_to_first_port", "at_port", "in_transit_to_pol", "at_pol", "high_seas", "at_transshipment", "at_pod", "in_transit_to_final_port", "at_final_port", "out_for_final_delivery", "at_customer", "serates"]);
    const [subCounters, setSubCounters] = useState(counters.current[0])
    const [state, setState] = useState({
        showLoader: false,
        bl_new_shipments: [],
        bl_untrackable: [],
        container_new_shipments: [],
        container_untrackable: [],
        containerShippingLines: [],
        blShippingLines: [],
        shippingLines: [],
        shippingLine: "",
        tempContainerData: [],
        filterText: "",
        trackingData: [],
        container_data: [],
        all_container_data: [],
        cummulativeBalEmf: "",
        balContainerEmf: "",
        cummulativeContainerEmf: "",
        containerEmf: "",
        loadshow: "show-m",
        overly: "show-m",
        isTileView: true,
        filterModeType: { value: "container_number", label: "Container"},
        modeOptions: [
            // { value: "all", label: "ALL" },
            { value: "container_number", label: "Container No" },
            { value: "mbl_number", label: "BL Number"},
            { value: "first_vessel_name", label: "First Vessel Name"},
            { value: "last_vessel_name", label: "Last Vessel Name"},
        ],
        searchInput: "",
        tilesPerPage: { value: "50", label: "50" },
        tilesPerPageOptions: [
            // { value: "ALL", label: "ALL" },
            // { value: "10", label: "10" },
            // { value: "20", label: "20" },
            // { value: "30", label: "30" },
            // { value: "40", label: "40" },
            { value: "50", label: "50" },
            // { value: "60", label: "60" },
            { value: "100", label: "100" },
        ],
        showSortCard: "show-n",
        showFilterCard: "show-n",
        rowData: [],
        originRegionOptions: [],
        originRegion: "",
        destinationRegionOptions: [],
        destinationRegion: "",
        container_org_options: [],
        container_dest_options: [],
        bl_org_options: [],
        bl_dest_options: [],
        overly: "show-n",
        documentsTranslate: "",
        eventName: "all",
        container_detention_data: [],
        delayed_containers_data: []
    });

    const [sortCardClass, setSortCardClass] = useState("sortCardHeight_all");

    const openFilterModal2 = () => {
        setOpenFilterModal(true)
    };
    const handleCheckboxChange = (option) => {
        if (checkedCheckboxes.includes(option)) {
            setCheckedCheckboxes(checkedCheckboxes.filter(item => item !== option));
        } else {
            setCheckedCheckboxes([...checkedCheckboxes, option]);
        }
    }
    const applySubCounters = async (e) => {
        subcountersCheckbox.current = [...checkedCheckboxes]
        const counters = new Array();
        sub_counter.forEach((each) => {
            if (subcountersCheckbox.current.includes(each)) {
                counters.push(each)
            }
        })
        await redirectURL
            .post("/consignments/saveSubCounterOptions", { email: localStorage.getItem("email"), counters: counters })
            .then((resp) => {
                if (resp.data.status === "success") {
                    setShow(true);
                    setBasicType("success");
                    setBasicTitle(resp.data.message);
                }
                else {

                    setShow(true);
                    setBasicType("danger");
                    setBasicTitle("Failed to update the Sub-Counters option");
                }
            })
            .catch((e) => {
                setShow(true);
                setBasicType("danger");
                setBasicTitle("Failed to update the Sub-Counters option");
                console.log(
                    "error message while updating customer reference for product",
                    e
                );
            });
        setOpenSubCounterChoise(false)
    }
    const closeModal2 = () => {
        setOpenSubCounterChoise(false)
    }
    const closeModal = () => {
        clearAllFilters();
        // let containerData = state.all_container_data;
        setState({
            ...state,
            // container_data: containerData,
            destinationRegion: "",
            originRegion: "",
        });
        setOpenFilterModal(false)
        setFromDate("");
        setToDate("");
    };

    const getCountryName = (isoCode) => {
        let countryName = "";
        countries.forEach((each) => {
            if (each.iso2 === isoCode) {
                countryName = each.name;
            }
        });
        return countryName;
    };


    const closeAlert = () => {
        setShow(false);
    };

    const [containerCounters, setContainerCounters] = useState({
        all: 0,
        origin: 0,
        in_transit: 0,
        destination: 0,
        completed: 0,
        delayed: 0,
        detained: 0,
    });

    const [movementType, setMovementType] = useState("");

    const handleOptionChange = (event) => {
        setMovementType(event.target.value);
    };

    const [filterTypes, setFilterTypes] = useState({
        shippingdate: "show-m",
        originregion: "show-m",
        destinationregion: "show-m",
        shippingline: "show-m",
        movementtype: "show-m",
    });
    const selectRef = useRef(null);
    const selectRef1 = useRef(null);

    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isMenuOpen1, setMenuOpen1] = useState(false);

    useEffect(() => {
        // console.log(movementType);
        // if (state.filterText) {
        // }
        let filters = [
            "shippingdate",
            "originregion",
            "destinationregion",
            "shippingline",
            "movementtype",
        ];
        let updateFilters = {};
        filters.forEach((each) => {
            if (each.includes(state.filterText.toLowerCase().split(" ").join(""))) {
                updateFilters[each] = "show-m";
            } else {
                updateFilters[each] = "show-n";
            }
        });
        setFilterTypes(updateFilters);
        document.addEventListener('mousedown', handleClickOutside);
    }, [state.filterText]);

    const handleClickOutside = (event) => {
        if (selectRef.current && !selectRef.current.contains(event.target)) {
            // Clicked outside the select component
            setMenuOpen(false);
        }

        if (selectRef1.current && !selectRef1.current.contains(event.target)) {
            // Clicked outside the select component
            setMenuOpen1(false);
        }
    };

    const handleMenuOpen = () => {
        // console.log("SA")
        setMenuOpen(true);
    };

    const handleMenuClose = () => {
        setMenuOpen(false);
    };


    const handleMenuOpen1 = () => {
        // console.log("DD")
        setMenuOpen1(true);
    };

    const handleMenuClose1 = () => {
        setMenuOpen1(false);
    };

    const filterDataFromFilters = () => {

        let mainCounter = localStorage.getItem("current_counter")
        let subCounter = localStorage.getItem("sub_counter");

        setOpenFilterModal(false)
        let containerData;

        if (subCounter === "")
            containerData = getfilterDataByCounter("main", mainCounter)
        else
            containerData = getfilterDataByCounter("sub", subCounter)
        let filteredData = [];

        //Movement Type
        if (movementType) {
            if (filteredData.length > 0) {
                filteredData = filteredData.filter((each) => {
                    if (each.movement_type && each.movement_type === movementType) {
                        return each;
                    }
                });
            } else {
                filteredData = containerData.filter((each) => {
                    if (each.movement_type && each.movement_type === movementType) {
                        return each;
                    }
                });
            }
            if (filteredData.length == 0) {
                setState({
                    ...state,
                    container_data: filteredData
                })
                return
            }
        }

        //if only fromDate and no toDate then filter all from or after created_date:"2023-11-15T05:12:56.460Z"
        if (fromDate && !toDate) {
            if (filteredData.length > 0) {
                filteredData = filteredData.filter((each) => {
                    if (
                        moment
                            .parseZone(fromDate)
                            .isSameOrBefore(moment.parseZone(each.created_date))
                    ) {
                        return each;
                    }
                });
            } else {
                filteredData = containerData.filter((each) => {
                    if (
                        moment
                            .parseZone(fromDate)
                            .isSameOrBefore(moment.parseZone(each.created_date))
                    ) {
                        return each;
                    }
                });
            }
            if (filteredData.length == 0) {
                setState({
                    ...state,
                    container_data: filteredData
                })
                return
            }
        }

        if (!fromDate && toDate) {
            if (filteredData.length > 0) {
                filteredData = filteredData.filter((each) => {
                    if (
                        moment
                            .parseZone(toDate)
                            .isSameOrAfter(moment.parseZone(each.created_date))
                    ) {
                        return each;
                    }
                });
            } else {
                filteredData = containerData.filter((each) => {
                    if (
                        moment
                            .parseZone(toDate)
                            .isSameOrAfter(moment.parseZone(each.created_date))
                    ) {
                        return each;
                    }
                });
            }
            if (filteredData.length == 0) {
                setState({
                    ...state,
                    container_data: filteredData
                })
                return
            }
        }

        // fromDate <= created_date <= toDate
        if (fromDate && toDate) {
            if (filteredData.length > 0) {
                filteredData = filteredData.filter((each) => {
                    if (
                        moment
                            .parseZone(fromDate)
                            .isSameOrBefore(moment.parseZone(each.created_date)) &&
                        moment
                            .parseZone(toDate)
                            .isSameOrAfter(moment.parseZone(each.created_date))
                    ) {
                        return each;
                    }
                });
            } else {
                filteredData = containerData.filter((each) => {
                    if (
                        moment
                            .parseZone(fromDate)
                            .isSameOrBefore(moment.parseZone(each.created_date)) &&
                        moment
                            .parseZone(toDate)
                            .isSameOrAfter(moment.parseZone(each.created_date))
                    ) {
                        return each;
                    }
                });
            }
            if (filteredData.length == 0) {
                setState({
                    ...state,
                    container_data: filteredData
                })
                return
            }
        }

        //Origin Region
        if (state.originRegion && state.originRegion.hasOwnProperty("value")) {
            if (filteredData.length > 0) {
                filteredData = filteredData.filter((each) => {
                    if (
                        each.loading_port == state.originRegion.value
                    ) {
                        return each;
                    }
                });
            } else {
                filteredData = containerData.filter((each) => {
                    if (
                        each.loading_port == state.originRegion.value
                    ) {
                        return each;
                    }
                });
            }
            if (filteredData.length == 0) {
                setState({
                    ...state,
                    container_data: filteredData
                })
                return
            }
        }

        //Destination Region
        if (
            state.destinationRegion &&
            state.destinationRegion.hasOwnProperty("value")
        ) {
            if (filteredData.length > 0) {
                filteredData = filteredData.filter((each) => {
                    if (
                        each.discharge_port ==
                        state.destinationRegion.value
                    ) {
                        return each;
                    }
                });
            } else {
                filteredData = containerData.filter((each) => {
                    if (
                        each.discharge_port ==
                        state.destinationRegion.value
                    ) {
                        return each;
                    }
                });
            }
            if (filteredData.length == 0) {
                setState({
                    ...state,
                    container_data: filteredData
                })
                return
            }
        }

        //Shipping Line
        if (state.shippingLine && state.shippingLine.hasOwnProperty("value")) {
            if (filteredData.length > 0) {
                filteredData = filteredData.filter((each) => {
                    if (each.shipping_line === state.shippingLine.value) {
                        return each;
                    }
                });
            } else {
                filteredData = containerData.filter((each) => {
                    if (each.shipping_line === state.shippingLine.value) {
                        return each;
                    }
                });
            }
            if (filteredData.length == 0) {
                setState({
                    ...state,
                    container_data: filteredData
                })
                return
            }
        }
        setState({
            ...state,
            container_data: filteredData,
            originRegion:'',
            destinationRegion:''
        });

    };

    const getfilterDataByCounter = (counterType, eventName) => {

        let containerDetentionData = [];

        if (eventName === "0-5") {
            let container_detention_data =
                state.container_detention_data;
            container_detention_data.forEach((each) => {
                let { slab_name } = each;
                if (slab_name === "0-5") {
                    containerDetentionData.push(each);
                }
            });
            return containerDetentionData;
        }
        if (eventName === "6-10") {
            let container_detention_data =
                state.container_detention_data;
            container_detention_data.forEach((each) => {
                let { slab_name } = each;
                if (slab_name === "6-10") {
                    containerDetentionData.push(each);
                }
            });
            return containerDetentionData;
        }

        if (eventName === "11-15") {
            let container_detention_data =
                state.container_detention_data;
            container_detention_data.forEach((each) => {
                let { slab_name } = each;
                if (slab_name === "11-15") {
                    containerDetentionData.push(each);
                }
            });
            return containerDetentionData;
        }
        if (eventName === "16-21") {
            let container_detention_data =
                state.container_detention_data;
            container_detention_data.forEach((each) => {
                let { slab_name } = each;
                if (slab_name === "16-21") {
                    containerDetentionData.push(each);
                }
            });
            return containerDetentionData;
        }
        if (eventName === ">21") {
            let container_detention_data =
                state.container_detention_data;
            container_detention_data.forEach((each) => {
                let { slab_name } = each;
                if (slab_name === ">21") {
                    containerDetentionData.push(each);
                }
            });
            return containerDetentionData;
        }

        if (eventName === "detained") {
            return state.container_detention_data;
        }

        if (eventName === "delayed") {
            return state.delayed_containers_data;
        }
        //container level filter
        if (eventName === "all") {
            return state.all_container_data;
        }
        let filteredData = [];
        let containerData = state.all_container_data;
        //main card level sort
        if (counterType === "main") {
            filteredData = containerData.filter((each) => {
                let { event_group } = each.last_event_data;
                if (eventName === "in_transit") {
                    if (
                        event_group === "in_transit" ||
                        event_group === "transshipment"
                    ) {
                        return each;
                    }
                }
                if (eventName === "destination" && event_group === "destination") {
                    return each
                }
                if (eventName === "origin" && event_group === "origin") {
                    return each
                }
                if (eventName === "completed" && event_group === "completed") {
                    return each;
                }
                // if (eventName.toUpperCase() === "UNTRACKABLE" && each.status === "UNTRACKABLE") {
                //     return each
                // }
            });

            if(eventName.toUpperCase() === "UNTRACKABLE"){
                filteredData=state.container_untrackable
            }
        } else {
            filteredData = containerData.filter((each) => {
                let { event_code, sub_event_group } = each.last_event_data;
                if (sub_event_group === eventName) {
                    return each;
                }
            });
        }
        return filteredData;

    };

    const clearAllFilters = () => {

        let mainCounter = localStorage.getItem("current_counter")
        let subCounter = localStorage.getItem("sub_counter");
        if (subCounter === "")
            filterDataByCounter("main", mainCounter)
        else
            filterDataByCounter("sub", subCounter)
        setFromDate("");
        setToDate("");
        setState({
            ...state,
            // container_data: state.all_container_data,
            destinationRegion: "",
            originRegion: "",
        });
    };

    const [eventName, setEventName] = useState("all")

    const [blCounters, setBLCounters] = useState({
        all: 0,
        origin: 0,
        in_transit: 0,
        destination: 0,
        completed: 0,
        delayed: 0,
        detained: 0,
    });

    const sortTabHeight = useRef(0)

    const filterDataByCounter = (counterType, eventName) => {
        console.log(eventName,'eventName',state)
        if (eventName !== "all")
            sortTabHeight.current = "313px"
        else
            sortTabHeight.current = "191px"

        setEventName(eventName);

        setState({
            ...state,
            counterType: counterType,
            eventName: eventName,
            showLoader: true,
        })
        if (eventName != "all") {
            setSortCardClass("sortCardHeight_others");
        }
        let all_slabs = ["0-5", "6-10", "11-15", "16-21", ">21"];

        let containerDetentionData = [];

        if (eventName === "0-5") {
            let container_detention_data =
                state.container_detention_data;
            container_detention_data.forEach((each) => {
                let { slab_name } = each;
                if (slab_name === "0-5") {
                    containerDetentionData.push(each);
                }
            });
            setState({
                ...state,
                container_data: containerDetentionData,
                eventName: "0-5",
            });
            return;
        }
        if (eventName === "6-10") {
            let container_detention_data =
                state.container_detention_data;
            container_detention_data.forEach((each) => {
                let { slab_name } = each;
                if (slab_name === "6-10") {
                    containerDetentionData.push(each);
                }
            });
            setState({
                ...state,
                container_data: containerDetentionData,
                eventName: "6-10",
            });
            return;
        }

        if (eventName === "11-15") {
            let container_detention_data =
                state.container_detention_data;
            container_detention_data.forEach((each) => {
                let { slab_name } = each;
                if (slab_name === "11-15") {
                    containerDetentionData.push(each);
                }
            });
            setState({
                ...state,
                container_data: containerDetentionData,
                eventName: "11-15",
            });
            return;
        }
        if (eventName === "16-21") {
            let container_detention_data =
                state.container_detention_data;
            container_detention_data.forEach((each) => {
                let { slab_name } = each;
                if (slab_name === "16-21") {
                    containerDetentionData.push(each);
                }
            });
            setState({
                ...state,
                container_data: containerDetentionData,
                eventName: "16-21",
            });
            return;
        }
        if (eventName === ">21") {
            let container_detention_data =
                state.container_detention_data;
            container_detention_data.forEach((each) => {
                let { slab_name } = each;
                if (slab_name === ">21") {
                    containerDetentionData.push(each);
                }
            });

            setState({
                ...state,
                container_data: containerDetentionData,
                eventName: ">21",
            });
            return;
        }

        if (eventName === "detained") {
            setState({
                ...state,
                container_data: state.container_detention_data,
                eventName: "detained",
            });
            return;
        }

        if (eventName === "delayed") {
            setState({
                ...state,
                container_data: state.delayed_containers_data,
                eventName: "delay",
                // showLoader: false,
            });
            // setSortCardClass("sortCardHeight_all");
            return;
        }
        //container level filter
        if (eventName === "all") {
            setState({
                ...state,
                container_data: state.all_container_data,
                eventName: "all",
                // showLoader: false,
            });
            return;
        }
        let filteredData = [];
        let containerData = state.all_container_data;
        //main card level sort
        if (counterType === "main") {
            console.log(counterType,'type',eventName,state.container_untrackable,'state.container_untrackable')
            filteredData = containerData.filter((each) => {
                let { event_group } = each.last_event_data;
                if (eventName === "in_transit") {
                    if (
                        event_group === "in_transit" ||
                        event_group === "transshipment"
                    ) {
                        return each;
                    }
                }
                if (eventName === "destination" && event_group === "destination") {
                    return each
                }
                if (eventName === "origin" && event_group === "origin") {
                    return each
                }
                if (eventName === "completed" && event_group === "completed") {
                    return each;
                }
                // if (eventName.toUpperCase() === "UNTRACKABLE" && each.status === "UNTRACKABLE") {
                //     return each
                // }
            });

            if(eventName.toUpperCase() === "UNTRACKABLE"){
                filteredData=state.container_untrackable
            }
        } else {
            filteredData = containerData.filter((each) => {
                let { event_code, sub_event_group } = each.last_event_data;
                if (sub_event_group === eventName) {
                    return each;
                }
            });
        }
        setState({
            ...state,
            container_data: filteredData
        });
    };


    const trackingData = () => {

        setState({
            ...state,
            loadshow: "show-m",
            overly: "show-m",
        });

        redirectURL
            .post("/consignments/getAllTrackingDataImports")
            .then((resp) => {
                console.log("getTrackingData data", resp.data);

                if (resp.data.status === "success") {
                    let {
                        container_data
                    } = resp.data;
                    container_data.forEach((e, i) => {
                        // Default values for last_event_data
                        if (e.last_event_data == undefined) {
                            container_data[i]["last_event_data"] = {
                                "container_no": "",
                                "location": "",
                                "searates_description": "",
                                "shipment_no": "",
                                "actual_time": "",
                                "api_provider": "",
                                "booking_no": "",
                                "created_date": "",
                                "current_time": "",
                                "estimated_time": "",
                                "event_code": "",
                                "event_group": "",
                                "event_name": "",
                                "has_cargo": false,
                                "hbl_number": "",
                                "location_code": "",
                                "mbl_number": "",
                                "searates_event_type": "",
                                "searates_order_id": 0,
                                "searates_status": "",
                                "shipping_line": "",
                                "status": "",
                                "sub_event_group": "",
                                "updated_date": "",
                                "loading_port": "",
                                "first_vessel_name": "",
                                "last_vessel_name": ""
                            };
                        }
                
                        // Default values for events
                        if (e.events == undefined || e.events.length === 0) {
                            container_data[i]["events"] = [{
                                "container_no": "",
                                "location": "",
                                "searates_description": "",
                                "shipment_no": "",
                                "actual_time": "",
                                "api_provider": "",
                                "booking_no": "",
                                "created_date": "",
                                "current_time": "",
                                "estimated_time": "",
                                "event_code": "",
                                "event_group": "",
                                "event_name": "",
                                "has_cargo": false,
                                "hbl_number": "",
                                "location_code": "",
                                "mbl_number": "",
                                "searates_event_type": "",
                                "searates_order_id": 0,
                                "searates_status": "",
                                "shipping_line": "",
                                "status": "",
                                "sub_event_group": "",
                                "updated_date": "",
                                "loading_port": "",
                                "first_vessel_name": "",
                                "last_vessel_name": ""
                            }];
                        }
                    });
                
                    container_data.map((e) => {
                        if(e.last_event_data == undefined){
                            console.log('undefined error catched')
                        }
                    })

                    let container_org_options = [];
                    let container_dest_options = [];


                    let containerAllCounters = {
                        all: 0,
                        origin: 0,
                        in_transit: 0,
                        destination: 0,
                        completed: 0,
                        delayed: 0,
                        detained: 0,
                    };


                    let containerAllCount = 0; //count for all trackable containers i.e which have mbl_number
                    let containerUnTrackable = 0; //count for all untrackable containers i.e which have no mbl_number
                    let untrackableContainers = []; //data of untrackable containers with no mbl_number
                    let trackableContainers = []; //data of untrackable containers with no mbl_number
                    let container_detention_data = []
                    let delayed_containers_data = []

                    let containerOrgCountries = [];
                    let containerDestCountries = [];

                    let containerShippingLines = [];
                    let containerShippingDuplicates = [];

                    //container_data filtering
                    let totalContainerEmission = 0;
                    container_data.forEach((each) => {
                        let {
                            loading_port_code,
                            loading_port,
                            discharge_port_code,
                            discharge_port,
                            shipping_line,
                            slab_name,
                            carrier_scac_name,
                            last_event_data,
                        } = each;
                        let delay_text = ""
                        let delay_state = ""

                        try {
                            if (each.last_port_eta != "" && each.last_port_ata != "") {
                                const delay = (moment(each.last_port_eta)).diff(moment(each.last_port_ata));
                                const duration = moment.duration(delay)
                                const days = duration.days();
                                const hours2 = duration.hours();
                                if (each.last_port_eta === "" || each.last_port_ata === "") {
                                    delay_state = "NA"
                                    delay_text = "NA"
                                }
                                if (days < 0) {
                                    if (Math.abs(days) === 1) {
                                        delay_text += "Delay by " + Math.abs(days) + " day"
                                        delay_state = "Delay"
                                    }
                                    else {
                                        delay_text += "Delay by " + Math.abs(days) + " days"
                                        delay_state = "Delay"
                                    }
                                    if (hours2 !== 0)
                                        delay_text = delay_text + " " + Math.abs(hours2) + " hour(s)"
                                }
                                else if (days > 0) {
                                    if (Math.abs(days) === 1) {
                                        delay_text += "Early by " + Math.abs(days) + " day"
                                        delay_state = "Early"
                                    }
                                    else {
                                        delay_text += "Early by " + Math.abs(days) + " days"
                                        delay_state = "Early"
                                    }
                                    if (hours2 !== 0)
                                        delay_text = delay_text + " " + Math.abs(hours2) + " hour(s)"
                                }
                                else {
                                    if (days === 0) {
                                        if (hours2 < 0) {
                                            delay_text += "Delay by " + Math.abs(hours2) + " hour(s)"
                                            delay_state = "Delay"
                                        }
                                        else if (hours2 > 0) {
                                            delay_text += "Early by " + Math.abs(hours2) + " hour(s)"
                                            delay_state = "Early"
                                        }
                                        else if (hours2 === 0)
                                            delay_text += "On Time"
                                    }
                                }
                            }
                            else {
                                delay_state = "NA"
                                delay_text = "NA"
                            }
                        } catch (err) {
                            console.log(err);
                        }

                        each["delay_state"] = delay_state
                        each["delay_text"] = delay_text

                        // let originCode = loading_port_code.slice(0, 2);
                        // let originCountry = getCountryName(originCode);
                        let originCountry = loading_port;

                        if (!containerShippingDuplicates.includes(shipping_line)) {
                            containerShippingLines.push({
                                label: carrier_scac_name,
                                value: shipping_line,
                            });
                            containerShippingDuplicates.push(shipping_line);
                        }
                        if (each.hasOwnProperty("force_close") && each.force_close === 1) {
                            last_event_data.status = "COMPLETED"
                            last_event_data.event_group = "completed"
                            last_event_data.event_name = "Force Closed"
                            last_event_data.sub_event_group = "empty_return"
                            each.delay_status = "Delay"
                        }
                        // event_name: 'Discharge from Vessel'   ||   event_code: "dischargeFromVessel" ||   event_group: "in_transit"
                        let { event_name, event_code, event_group, sub_event_group } =
                            each.last_event_data;

                        //to remove duplicate insertion
                        if (!containerOrgCountries.includes(originCountry)) {
                            container_org_options.push({
                                label: originCountry,
                                value: originCountry,
                            });
                            containerOrgCountries.push(originCountry);
                        }

                        let destinationCode = discharge_port_code.slice(0, 2);
                        let destinationCountry = discharge_port;

                        //to remove duplicate insertion
                        if (!containerDestCountries.includes(destinationCountry)) {
                            container_dest_options.push({
                                label: destinationCountry,
                                value: destinationCountry,
                            });
                            containerDestCountries.push(destinationCountry);
                        }
                        //
                        if (each.status == "UNTRACKABLE" || (each.loading_port == "" && each.discharge_port == "")) {
                            untrackableContainers.push(each);
                        }
                        else {
                            containerAllCount++;
                            trackableContainers.push(each);
                        }
                        // at main level
                        if (
                            event_group === "transshipment" || event_group === "in_transit") {
                            if (containerAllCounters.hasOwnProperty("in_transit")) {
                                containerAllCounters["in_transit"] =
                                    containerAllCounters["in_transit"] + 1; //increase count of main counter by 1 if already exists
                            } else {
                                containerAllCounters["in_transit"] = 1;
                            }
                        } else {
                            if (containerAllCounters.hasOwnProperty(event_group)) {
                                containerAllCounters[event_group] =
                                    containerAllCounters[event_group] + 1; //increase count of main counter by 1 if already exists
                            } else {
                                containerAllCounters[event_group] = 1;
                            }
                        }

                        //at sub_level
                        if (sub_event_group) {
                            let sublevel_key = "";
                            if (
                                event_group === "transshipment" ||
                                event_group === "in_transit"
                            ) {
                                sublevel_key = "in_transit" + "_" + sub_event_group;
                            } else {
                                sublevel_key = event_group + "_" + sub_event_group;
                            }
                            if (containerAllCounters.hasOwnProperty(sublevel_key)) {
                                containerAllCounters[sublevel_key] =
                                    containerAllCounters[sublevel_key] + 1; //increase count of main counter by 1 if already exists
                            } else {
                                containerAllCounters[sublevel_key] = 1;
                            }
                        }

                        if (
                            containerAllCounters.hasOwnProperty("detained" + "_" + slab_name)
                        ) {
                            containerAllCounters["detained" + "_" + slab_name] =
                                containerAllCounters["detained" + "_" + slab_name] + 1;
                        } else {
                            containerAllCounters["detained" + "_" + slab_name] = 1;
                        }


                        if (delay_state === "Delay") {
                            delayed_containers_data.push(each)
                        }

                    });



                    containerAllCounters["containersTrackable"] = containerAllCount;
                    // containerAllCounters["containersUnTrackable"] = containerUnTrackable;
                    containerAllCounters["all"] = containerAllCount + untrackableContainers.length
                    containerAllCounters["untrackable"] = untrackableContainers.length;
                    containerAllCounters["delayed"] = delayed_containers_data.length;

                    setContainerCounters(containerAllCounters);

                    try {
                        const searchParams = new URLSearchParams(window.location.search);
                        const container = searchParams.get("id");
                        let container_find = trackableContainers.find(e => e.container_no == container)
                        if (container_find != undefined) {

                            let delay_text = "";
                            let delay_state = ""
                            try {
                                const delay = (moment(container_find.last_port_eta)).diff(moment(container_find.last_port_ata));
                                const duration = moment.duration(delay)
                                const days = duration.days();
                                const hours2 = duration.hours();
                                if (container_find.last_port_eta === "" || container_find.last_port_ata === "") {
                                    delay_state = "NA"
                                    delay_text = "NA"
                                }
                                if (days < 0) {
                                    if (Math.abs(days) === 1) {
                                        delay_text += "Delay by " + Math.abs(days) + " day"
                                        delay_state = "Delay"
                                    }
                                    else {
                                        delay_text += "Delay by " + Math.abs(days) + " days"
                                        delay_state = "Delay"
                                    }
                                    if (hours2 !== 0)
                                        delay_text = delay_text + " " + Math.abs(hours2) + " hour(s)"
                                }
                                else if (days > 0) {
                                    if (Math.abs(days) === 1) {
                                        delay_text += "Early by " + Math.abs(days) + " day"
                                        delay_state = "Early"
                                    }
                                    else {
                                        delay_text += "Early by " + Math.abs(days) + " days"
                                        delay_state = "Early"
                                    }
                                    if (hours2 !== 0)
                                        delay_text = delay_text + " " + Math.abs(hours2) + " hour(s)"
                                }
                                else {
                                    if (days === 0) {
                                        if (hours2 < 0) {
                                            delay_text += "Delay by " + Math.abs(hours2) + " hour(s)"
                                            delay_state = "Delay"
                                        }
                                        else if (hours2 > 0) {
                                            delay_text += "Early by " + Math.abs(hours2) + " hour(s)"
                                            delay_state = "Early"
                                        }
                                        else if (hours2 === 0) {
                                            delay_text += "On Time"
                                            delay_state = "On Time"
                                        }
                                    }
                                }
                                container_find["delay_text"] = delay_text
                                container_find["delay_state"] = delay_state
                            } catch (err) {

                            }
                            localStorage.setItem("each", JSON.stringify(container_find))
                            window.location.href = "/tracking_details_imports"
                        }
                    } catch (err) {

                    }
                     trackableContainers = [...trackableContainers, ...untrackableContainers];
                    setState({
                        ...state,
                        tilesPerPage: { value: 50, label: "50" },
                        tilesPerPageOptions: [
                            // { value: (containerAllCount + untrackableContainers.length), label: "ALL" },
                            // { value: "10", label: "10" },
                            // { value: "20", label: "20" },
                            // { value: "30", label: "30" },
                            // { value: "40", label: "40" },
                            { value: "50", label: "50" },
                            // { value: "60", label: "60" },
                            { value: "100", label: "100" },
                        ],

                        all_container_data: trackableContainers,
                        trackableContainers: trackableContainers,
                        untrackableContainers,
                        container_data: trackableContainers,
                        loadshow: "show-n",
                        overly: "show-n",
                        originRegionOptions: container_org_options,
                        destinationRegionOptions: container_dest_options,
                        containerShippingLines: containerShippingLines,
                        delayed_containers_data,
                        container_detention_data,
                        container_untrackable: untrackableContainers,
                    });
                }
            })
            .catch((err) => {
                console.log("getTrackingData err", err);
            });
    };

    const handleView = (viewType) => {
        setState({
            ...state,
            showSortCard: "show-n",
        });

        if (viewType === "tileView") {
            setState({
                ...state,
                isTileView: true,
            });
        } else {
            setState({
                ...state,
                isTileView: false,
            });
        }
    };

    const filterDataFromSearchBox = () => {

        let mainCounter = localStorage.getItem("current_counter")
        let subCounter = localStorage.getItem("sub_counter");

        let containerData;
        if (subCounter === "")
            containerData = getfilterDataByCounter("main", mainCounter)
        else
            containerData = getfilterDataByCounter("sub", subCounter)

        if (state.searchInput === "") {

            let mainCounter = localStorage.getItem("current_counter")
            let subCounter = localStorage.getItem("sub_counter");
            if (subCounter === "")
                filterDataByCounter("main", mainCounter)
            else
                filterDataByCounter("sub", subCounter)
            return
        }
        // if (state.filterModeType.value === "all") {
        //     if (state.searchInput) {
        //         let searchInput = state.searchInput.toUpperCase().trim();
        //           let container_filteredData = containerData.filter((each) => {
        //             if (
        //                 (each.mbl_number && each.mbl_number.toUpperCase().includes(searchInput)) ||
        //                 (each.container_no && each.container_no.toUpperCase().includes(searchInput)) 
        //                 // ||
        //                 // (each.invoice_no && each.invoice_no.toUpperCase().includes(searchInput)) ||
        //                 // (each.po_no && each.po_no.toUpperCase().includes(searchInput))
        //             ) {
        //                 return each;
        //             }
        //         });
        //         setState({
        //             ...state,
        //             container_data: container_filteredData
        //         });
        //     } else {
        //         setState({
        //             ...state,
        //             container_data: containerData
        //         });
        //     }
        // } else
         if (state.filterModeType.value === "mbl_number") {
            if (state.searchInput) {
                let container_filteredData = containerData.filter((each) => {
                    if (
                        each.mbl_number.includes(state.searchInput.toUpperCase().trim())
                    ) {
                        return each;
                    }
                });
                setState({
                    ...state,
                    container_data: container_filteredData
                });
            } else {
                setState({
                    ...state,
                    container_data: containerData
                });
            }
        } else if (state.filterModeType.value === "container_number") {
            if (state.searchInput) {
                let container_filteredData = containerData.filter((each) => {
                    if (
                        each.container_no.includes(state.searchInput.toUpperCase().trim())
                    ) {
                        return each;
                    }
                });
                setState({
                    ...state,
                    container_data: container_filteredData
                });
            } else {
                setState({
                    ...state,
                    container_data: containerData
                });
            }
        } else if (state.filterModeType.value === "invoice_no") {
            if (state.searchInput) {
                let container_filteredData = containerData.filter((each) => {
                    if (
                        each.invoice_no &&
                        each.invoice_no
                            .toLowerCase()
                            .includes(state.searchInput.toLowerCase().trim())
                    ) {
                        return each;
                    }
                });
                setState({
                    ...state,
                    container_data: container_filteredData
                });
            }
        } else if (state.filterModeType.value === "po_no") {
            if (state.searchInput) {
                let container_filteredData = containerData.filter((each) => {
                    if (
                        each.po_no &&
                        each.po_no
                            .toLowerCase()
                            .includes(state.searchInput.toLowerCase().trim())
                    ) {
                        return each;
                    }
                });
                setState({
                    ...state,
                    container_data: container_filteredData
                });
            }
        }else if (state.filterModeType.value === "first_vessel_name") {
            if (state.searchInput) {
                let container_filteredData = containerData.filter((each) => {
                    if (
                        each.first_vessel_name &&
                        each.first_vessel_name
                            .toLowerCase()
                            .includes(state.searchInput.toLowerCase().trim())
                    ) {
                        return each;
                    }
                });
                setState({
                    ...state,
                    container_data: container_filteredData
                });
            }
        } else if (state.filterModeType.value === "last_vessel_name") {
            if (state.searchInput) {
                let container_filteredData = containerData.filter((each) => {
                    if (
                        each.last_vessel_name &&
                        each.last_vessel_name
                            .toLowerCase()
                            .includes(state.searchInput.toLowerCase().trim())
                    ) {
                        return each;
                    }
                });
                setState({
                    ...state,
                    container_data: container_filteredData
                });
            }
        }
    };

    const trackingDetails = () => {

        filterDataFromSearchBox();

    };

    useEffect(() => {
        if (state.searchInput == "")
            filterDataFromSearchBox();
    }, [state.searchInput]);


    const handleFilterOptions = (selOption, dropdownName) => {
        let mainCounter = localStorage.getItem("current_counter")
        let subCounter = localStorage.getItem("sub_counter");
        if (dropdownName.name === "tilesPerPage" && selOption.label === "ALL") {

            setOpenFilterModal(false)
            let contData;
            if (subCounter === "")
                contData = getfilterDataByCounter("main", mainCounter)
            else
                contData = getfilterDataByCounter("sub", subCounter)
            setState({
                ...state,
                tilesPerPage: {
                    "value": state.all_container_data.length,
                    "label": "ALL"
                },
                bl_data: contData
            });

        }
        else {
            setState({
                ...state,
                [dropdownName.name]: selOption,
            });
        }
    };

    const openDocumentsSlider = () => {
        setState({
            ...state,
            overly: "show-m",
            documentsTranslate: "slider-translate",
        });
    };


    function timeoutLoader() {
        setGridLoader(false)
    }
    const sortData = (dataType) => {

        var defnew = 0;
        setGridLoader(true)
        if (localStorage.getItem("current_counter") == "all") {

            defnew = state.container_new_shipments.length;
        }
        if (dataType === "last_tracking_time") {

            let containerData = state.container_data;
            containerData.sort((a, b) =>
                moment(b.tracking_updated_at).diff(moment(a.tracking_updated_at))
            );
            setState({
                ...state,
                container_data: containerData,
                showSortCard: "show-n",
            });
            setTimeout(timeoutLoader, 500);
        } else if (dataType === "last_activity_update") {
            let containerData = state.container_data;
            containerData.sort((a, b) =>
                moment(a.last_event_data.actual_time).diff(
                    moment(b.last_event_data.actual_time)
                )
            );
            setTimeout(timeoutLoader, 500);
            setState({
                ...state,
                container_data: containerData,
                showSortCard: "show-n",
            });

        } else {
            let containerData = state.container_data;
            containerData.sort(
                (
                    a,
                    b
                ) =>
                    moment(b.last_port_ata ? b.last_port_ata : b.last_port_eta).diff(
                        moment(a.last_port_ata ? a.last_port_ata : a.last_port_eta)
                    )
            );
            setTimeout(timeoutLoader, 500);
            setState({
                ...state,
                container_data: containerData,
                showSortCard: "show-n",
            });
        }
    };
    useEffect(() => {
        localStorage.setItem("current_counter", "all");
        localStorage.setItem("sub_counter", "");
        trackingData();
        const promise2 = new Promise(function (myResolve, myReject) {
            redirectURL.post("/consignments/getSubCounterOptions", { email: localStorage.getItem("email") })
                .then(response => {
                    myResolve(response.data);
                })
                .catch(error => {
                    myReject(error);
                });
        });
        promise2.then(
            function (data) {
                if (data && Array.isArray(data.data) && data.data.length > 0) {
                    const firstItem = data.data[0];
                    if (firstItem.hasOwnProperty("counters") && Array.isArray(firstItem.counters)) {
                        setCheckedCheckboxes(firstItem.counters);
                        subcountersCheckbox.current = firstItem.counters;
                    }
                } else {
                    console.error("No valid data or empty array received from the API.");
                }
            },
            function (error) {
                console.error("Error in API call:", error);
            }
        );
    }, []);
    
    const CustomInput = React.forwardRef((props, ref) => (
        <input style={{backgroundColor:'white',width:'260px'}}
            ref={ref}
            {...props}
            readOnly
            className="form-control"
        />
    ));


    const handleFromDate = (date) => {
        setFromDate(date);
    };

    const handleToDate = (date) => {
        let cdate = new Date(date)
        cdate.setHours(23)
        cdate.setMinutes(59)
        cdate.setSeconds(59)
        setToDate(cdate);
        setFromDate((prevFromDate) => (prevFromDate > cdate ? cdate : prevFromDate));
    };

    return (
        <div>
            {/* <BootstrapLoader showLoader={state.showLoader} /> */}
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            ></SweetAlert>
            <h5>
              {/* <i className="icofont icofont-vehicle-delivery-van cus-i"></i>{" "} */}
              <span style={{marginLeft:'20px'}}>Container Tracking</span>
              </h5>
            <Modal open={openFilterModal} onClose={closeModal}
                center
                classNames={{ modal: "subcounter-modal-size" }}>
                <div className="" style={{ height: "57px" }}>
                    <h5 className="modal-heading" style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px" }}>Filters</h5>
                </div>

                <div className="px-2 mb-4">

                    <div
                        className={`${filterTypes.shippingdate} mt-2 col-3 px-2`}
                        id="shippingdate"
                    >
                          <div className="row">
                            <div className="col-5">
                                <label htmlFor="" style={{ width: "375%" }}>
                                    Shipment From Date
                                </label>
                                <DatePicker
                                    selected={fromDate}
                                    onChange={handleFromDate}
                                    dateFormat={"yyyy/MM/dd"}
                                    selectsStart
                                    startDate={fromDate}
                                    endDate={toDate}
                                    maxDate={toDate ? toDate : null}
                                    wrapperClassName="datepick datepick-filter"
                                    customInput={<CustomInput />}
                                />
                            </div>
                            <div className="col-5" style={{ position: "absolute", left: "307px" }}>
                                <label htmlFor="" style={{ width: "375%" }}>
                                    Shipment To Date
                                </label>
                                <DatePicker
                                    selected={toDate}
                                    onChange={handleToDate}
                                    dateFormat={"yyyy/MM/dd"}
                                    selectsEnd
                                    startDate={fromDate}
                                    endDate={toDate}
                                    minDate={fromDate ? fromDate : null}
                                    wrapperClassName="datepick datepick-filter"
                                    customInput={<CustomInput />}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <div style={{ flex: 1, padding: "10px", border: "1px" }}>
                            <div
                                id="originregion">
                                <label for="">Port of Origin</label>
                                <Select
                                    closeMenuOnSelect={true}
                                    name="originRegion"
                                    className="border-radius-0"
                                    onChange={handleFilterOptions}
                                    options={state.originRegionOptions}
                                    value={state.originRegion}
                                    maxMenuHeight={window.innerHeight * 0.26}
                                />
                            </div>
                        </div>
                        <div style={{ flex: 1, padding: "10px", border: "1px" }}>
                            <div
                                id="destinationregion"
                            >
                                <label for="">Port of Destination</label>
                                <Select
                                    closeMenuOnSelect={true}
                                    name="destinationRegion"
                                    className="border-radius-0"
                                    onChange={handleFilterOptions}
                                    options={state.destinationRegionOptions}
                                    value={state.destinationRegion}
                                    required
                                    maxMenuHeight={window.innerHeight * 0.26}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="p-2">
                        {/* <input
                            type="text"
                            name=""
                            id=""
                            className="search_input"
                            style={{ width: "44%" }}
                            value={state.filterText}
                            onChange={(e) => {
                                setState({ ...state, filterText: e.target.value });
                            }}
                        /> */}
                        {/* <img
                            src={SearchIcon}
                            className="filter_search_icon"
                        /> */}
                        <button
                            className="btn filter_button mr-2"
                            onClick={clearAllFilters}
                            style={{
                                backgroundColor: "#171c8f",
                                borderColor: "#171c8f",
                                color: "#ffffff" // Optionally set the text color to white
                              }}
                        >
                            Clear Filters
                        </button>
                        <button
                            className="btn filter_button mr-2"
                            style={{
                                backgroundColor: "#171c8f",
                                borderColor: "#171c8f",
                                color: "#ffffff" // Optionally set the text color to white
                              }}
                            disabled={
                                fromDate ||
                                    state.originRegion ||
                                    state.destinationRegion ||
                                    state.shippingLine ||
                                    toDate ||
                                    movementType
                                    ? null
                                    : "disabled"
                            }
                            onClick={filterDataFromFilters}
                        >
                            Apply Filters
                        </button>
                    </div>


                </div>
            </Modal>
            <Modal open={openSubCounterChoise}
                center
                onClose={closeModal2}
                classNames={{ modal: "subcounter-modal-size" }}>
                <div className="mx-4 mt-5 row align-content-center justify-content-center"
                    style={{
                        cursor: "pointer",
                        height: "54px",
                        background: "#e4edfc",
                        borderRadius: "12px",
                        whiteSpace: "nowrap"
                    }}>
                    <div className="col-3" style={{
                        color: (subCounters.id == 1) ? "#fff" : "#171c8f",
                        background: (subCounters.id == 1) ? "#171c8f" : "#00000000",
                        borderRadius: "12px",
                    }}>
                        <button className="col"
                            style={{
                                color: (subCounters.id == 1) ? "#fff" : "black",
                                background: "#00000000",
                                height: "54px",
                                border: "none",
                                borderRadius: "12px",
                                fontWeight: "bold",
                                outline: "none",
                                cursor:"pointer"
                            }}
                            onClick={() => setSubCounters(counters.current[0])}>
                            <img src={(subCounters.id == 1) ? subCounters.activeImgSrc : counters.current[0].imgSrc} />&nbsp; Origin
                        </button>
                    </div>
                    <div className="col-3" style={{
                        color: (subCounters.id == 2) ? "#fff" : "#171c8f",
                        background: (subCounters.id == 2) ? "#171c8f" : "#00000000",
                        borderRadius: "12px",
                        paddingLeft: "3px"
                    }}>
                        <button className="col"
                            style={{
                                color: (subCounters.id == 2) ? "#fff" : "black",
                                background: "#00000000",
                                height: "54px",
                                border: "none",
                                borderRadius: "12px",
                                fontWeight: "bold",
                                outline: "none",
                                cursor:"pointer"
                            }}
                            onClick={() => setSubCounters(counters.current[1])}>
                            <img src={(subCounters.id == 2) ? subCounters.activeImgSrc : counters.current[1].imgSrc} style={{ maxHeight: "35px", maxWidth: "30px" }} />&nbsp; In-transit
                        </button>
                    </div>
                    <div className="col-3" style={{
                        color: (subCounters.id == 3) ? "#fff" : "#171c8f",
                        background: (subCounters.id == 3) ? "#171c8f" : "#00000000",
                        borderRadius: "12px",
                        paddingLeft: "2px"
                    }}>
                        <button className="col"
                            style={{
                                color: (subCounters.id == 3) ? "#fff" : "black",
                                background: "#00000000",
                                height: "54px",
                                border: "none",
                                borderRadius: "12px",
                                fontWeight: "bold",
                                outline: "none",
                                cursor:"pointer"
                            }}
                            onClick={() => setSubCounters(counters.current[2])}>
                            <img src={(subCounters.id == 3) ? subCounters.activeImgSrc : counters.current[2].imgSrc} />&nbsp; Destination
                        </button>
                    </div>
                    <div className="col-3" style={{
                        color: (subCounters.id == 4) ? "#fff" : "#171c8f",
                        background: (subCounters.id == 4) ? "#171c8f" : "#00000000",
                        borderRadius: "12px",
                    }}>
                        <button className="col"
                            style={{
                                color: (subCounters.id == 4) ? "#fff" : "black",
                                background: "#00000000",
                                height: "54px",
                                border: "none",
                                borderRadius: "12px",
                                fontWeight: "bold",
                                outline: "none",
                                cursor:"pointer"
                            }}
                            onClick={() => setSubCounters(counters.current[3])}>
                            {(subCounters.id == 4) ?
                                // <FaGlobe size={38} style={{ color: "#ffff" }} /> 
                                <img src={FaGlobe} />
                                :
                                // <FaGlobe size={38} style={{ color: "#171c8f" }} />
                                <img src={FaGlobeBlue} />
                            }
                            &nbsp; ULIP
                        </button>
                    </div>
                </div>
                <div className="row mx-5 my-4 row align-content-left justify-content-left" style={{ height: "12vw" }}>
                    {
                        subCounters.subCounters.map((item, index) => (
                            <div key={item.value} className="col-6 my-2" style={{ fontSize: "medium" }}><input type="checkbox" disabled={(subCounters.id === 4) ? true : false} style={{ transform: "scale(1.3)" }} value={item.value} checked={checkedCheckboxes.includes(item.value)} onChange={() => handleCheckboxChange(item.value)} />&nbsp;&nbsp;&nbsp;{item.label}</div>
                        ))
                    }
                </div>
                <div className="row mx-4 mb-5 row align-content-center justify-content-center">
                    <div className="col-6">
                        <button className="col"
                            style={{
                                color: "#171c8f",
                                background: "#fff",
                                border: "2px solid #171c8f",
                                borderRadius: "12px",
                                height: "35px",
                                cursor:"pointer"
                            }}
                            onClick={() => closeModal2()}
                        >{(subCounters.id == 4) ? "Close" : "Cancel"}
                        </button>
                    </div>
                    <div className={(subCounters.id == 4) ? "d-none" : "col-6"}>
                        <button className="col"
                            style={{
                                color: "#fff",
                                background: "#171c8f",
                                border: "none",
                                borderRadius: "12px",
                                height: "35px",
                                cursor:"pointer"
                            }}
                            onClick={applySubCounters}
                        >Apply
                        </button></div>
                </div>
            </Modal>
            <div className={"dataLoadpage " + (state.loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (state.loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part " + (state.overly)}></div>
            <div className="container-fluid">

                {/* <div>
                    <span style={{ fontSize: "1.5vw", paddingLeft: "1rem" }}>Container Tracking</span>
                    <img
                        className=""
                        src={emissionIcon}
                        width="40px"
                        style={{ marginLeft: "20px" }}
                    ></img>
                    <span style={{ fontSize: "14px", color: "#A1A2A6", marginLeft: "4px" }}>
                        {
                            container_data_Temission
                        }
                    </span> */}
                {/* <div>
                    <ReactTooltip />
                    <img
                        src={InfoIcon}
                        className="info_card_tracking_header"
                        // data-id="info_card_tracking_header"
                        alt="" width="15px"
                        data-tip="Port to Port emission calculation based on GLEC framework using default factors."
                    />
                </div>  */}
                {/* <div className="px-3" style={{ display: "flex", justifyContent: "space-between" }}>

                    <div className="mr-3 px-4 mb-2">
                        <h5>
                            <img src={CargoShipBlack} style={{width:"2rem"}}></img>&nbsp;&nbsp; <span> Container Tracking </span>
                        </h5>
                    </div>
                    <div className="mr-4">

                        <button
                            className="d-flex justify-content-center align-items-center mr-3 px-4 mb-2"
                            style={{
                                border: "none", background: "#fff0", color: "#171c8f",
                                outline: "none",
                                cursor:"pointer"
                            }}
                            onClick={() => {
                                setOpenSubCounterChoise(true)
                            }}>
                            <img
                                src={IoSettingsOutline} />
                        </button>
                    </div>
                </div> */}
                <div
                    className="card col-lg-12 col-md-12 pt-4"
                    style={{ borderRadius: "18px" }}
                >
                    <div>
                        <MainTrackingCountersImports
                            counters={containerCounters}
                            filterDataByCounter={filterDataByCounter}
                            subCountersTab={subcountersCheckbox.current}
                            clearAllFilters={clearAllFilters}
                        />
                    </div>

                    <div className="d-flex flex-row mb-5 pl-3 mt-4 flex-wrap">
                        <div className="d-flex flex-row">

                            <div className="region_card mr-3" ref={selectRef} style={{ width: "8rem" }}>
                                <Select
                                    menuIsOpen={isMenuOpen}
                                    onMenuOpen={handleMenuOpen}
                                    onMenuClose={handleMenuClose}
                                    closeMenuOnSelect={true}
                                    name="filterModeType"
                                    className="border-radius-0"
                                    onChange={handleFilterOptions}
                                    options={state.modeOptions}
                                    value={state.filterModeType}
                                    required
                                />
                            </div>
                            <div
                                    className="d-flex"
                                    style={{ position: "relative", marginRight: "20px" }}
                                >
                                    <input
                                        style={{
                                            border: "1px solid #cccccc",
                                            borderRadius: "11px",
                                            height: "38px",
                                            outline: "none",
                                            paddingLeft: "6px",
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                filterDataFromSearchBox();
                                            }
                                        }}
                                        onChange={(e) => {
                                            const newValue = e.target.value;
                                            setState({
                                                ...state,
                                                searchInput: newValue,
                                            });
                                        }}
                                        value={state.searchInput}
                                    />
                                    {state.searchInput && (
                                        <span
                                            onClick={() => {
                                                setState({
                                                    ...state,
                                                    searchInput: "",
                                                }, () => {
                                                    filterDataFromSearchBox(); 
                                                });
                                            }}
                                            style={{
                                                position: "absolute",
                                                right: "30px", // Adjust position as needed
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                                cursor: "pointer",
                                                color: "#999", // Light gray color for the X icon
                                                fontSize: "16px",
                                            }}
                                        >
                                            &times;
                                        </span>
                                    )}

                                    <img
                                        src={SearchIcon}
                                        className="search_icon"
                                        onClick={trackingDetails}
                                        style={{
                                            position: "absolute",
                                            right: "5px", // Adjust the position as needed
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>
                            <button
                                className="d-flex px-3 py-2 justify-content-center align-items-center"
                                style={{
                                    background: "white",
                                    borderRadius: "11px",
                                    border: "1px solid #171c8f",
                                    height: "38px",
                                    cursor:"pointer"
                                }}
                            >
                                <div className="d-flex flex-row" onClick={openFilterModal2}>
                                    <img src={FilterIcon}></img>
                                    <div
                                        style={{
                                            fontSize: "10px",
                                            fontWeight: 700,
                                            color: "#171c8f",
                                            marginLeft: "10px",
                                        }}
                                    >
                                        Filters
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div className="d-flex flex-row ml-4">
                            <div className="d-flex flex-row">
                                <div className="d-flex flex-row">
                                    <button
                                        className="d-flex flex-row justify-content-center align-items-center px-3"
                                        style={{
                                            background: state.isTileView ? "#171c8f" : "white",
                                            borderTopLeftRadius: "11px",
                                            borderBottomLeftRadius: "11px",
                                            border: "1px solid #171c8f",
                                            height: "38px",
                                            cursor:"pointer"
                                        }}
                                        onClick={() => {
                                            handleView("tileView");
                                        }}
                                    >
                                        <img
                                            src={state.isTileView ? TileViewActive : TileViewInactive}
                                        />
                                        <div
                                            style={{
                                                color: state.isTileView ? "white" : "#171c8f",
                                                fontSize: "10px",
                                                fontWeight: 700,
                                                marginLeft: "10px",
                                                lineHeight: "12px",
                                            }}
                                        >
                                            Tile
                                            <br />
                                            View
                                        </div>
                                    </button>

                                    <button
                                        className="d-flex flex-row justify-content-center align-items-center px-3"
                                        style={{
                                            background: !state.isTileView ? "#171c8f" : "white",
                                            borderTopRightRadius: "11px",
                                            borderBottomRightRadius: "11px",
                                            border: "1px solid #171c8f",
                                            height: "38px",
                                            cursor:"pointer"
                                        }}
                                        onClick={() => {
                                            handleView("gridView");
                                        }}
                                    >
                                        <img
                                            src={
                                                !state.isTileView ? GridViewActive : GridViewInactive
                                            }
                                        />
                                        <div
                                            style={{
                                                color: !state.isTileView ? "white" : "#171c8f",
                                                fontSize: "10px",
                                                fontWeight: 700,
                                                marginLeft: "10px",
                                                lineHeight: "12px",
                                            }}
                                        >
                                            Grid
                                            <br />
                                            View
                                        </div>
                                    </button>
                                </div>
                               {state.isTileView ? <div
                                    className="d-flex flex-column ml-3"
                                    style={{ position: "relative" }}
                                >
                                    <label
                                        style={{
                                            fontSize: "10px",
                                            color: "#171c8f",
                                            position: "absolute",
                                            top: "-25px",
                                        }}
                                    >
                                        Tiles per page
                                    </label>
                                    <div className="tilesPageSelector" ref={selectRef1} style={{ width: "5rem" }}>
                                        <Select
                                            menuIsOpen={isMenuOpen1}
                                            onMenuOpen={handleMenuOpen1}
                                            onMenuClose={handleMenuClose1}
                                            closeMenuOnSelect={true}
                                            className="border-radius-0"
                                            name="tilesPerPage"
                                            onChange={handleFilterOptions}
                                            options={state.tilesPerPageOptions}
                                            value={state.tilesPerPage}
                                            required
                                        />
                                    </div>
                                </div>
                                :
                                ""
                                }
                            </div>
                        </div>
                    </div>
                    {state.isTileView ? (
                        <div>
                            <div>
                                <TrackingTile
                                    container_untrackable={state.container_untrackable}
                                    container_data={state.container_data}
                                    trackingDataFun={trackingData}
                                    container_new_shipments={state.container_new_shipments}
                                    eventName={eventName}
                                    tilesPerPage={state.tilesPerPage}
                                />
                            </div>
                        </div>
                    ) : (
                        <div>
                            {
                                <EximGridImports
                                    container_untrackable={state.container_untrackable}
                                    container_new_shipments={state.container_new_shipments}
                                    container_data={state.container_data}
                                    paginationSize={state.tilesPerPage}
                                />
                            }
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default containerTrackingImports;

import React, { Component } from 'react';
import CountUp from 'react-countup';


export default class TransporterIntegrationCounters extends Component {
    constructor(props){
    	super(props);
    	this.state={
            records:[],
            totalTrucksCount:0,
            trucksWithGpsDataCount:0,
            trucksWithNoTransportersCount:0,
            gpsDataNotReportedTodayCount:0,
            totalActiveConsignmentsCount:0,
            gpsNotTodayActiveConsignmentsCount:0,
            totalInactiveConsignmentsCount:0
    	}
    
    }
    componentWillReceiveProps(newprops){
        let gpsDataNotReportedToday = newprops.tottrucks - newprops.truckgps;
        let gpsDataNotReportedTodayCount = (gpsDataNotReportedToday) ? gpsDataNotReportedToday : 0;
        let totalInactiveConsignmentsCount = newprops.tottrucks - newprops.totalActiveConsignmentsCount;
        totalInactiveConsignmentsCount = (totalInactiveConsignmentsCount >= 0) ? totalInactiveConsignmentsCount : 0;
        this.setState({
            totalTrucksCount:newprops.tottrucks,
            trucksWithGpsDataCount:newprops.truckgps,
            gpsDataNotReportedTodayCount:gpsDataNotReportedTodayCount,
            trucksWithNoTransportersCount:newprops.notruckgps,
            totalActiveConsignmentsCount:newprops.totalActiveConsignmentsCount,
            gpsNotTodayActiveConsignmentsCount:newprops.gpsNotTodayActiveConsignmentsCount,
            totalInactiveConsignmentsCount:totalInactiveConsignmentsCount,
        })
    }
    onClickCounter(data){
        //this.props.context.onClickCounterShowData(data)
    }
    render(){
        return (
            <div className="row">

            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="card">
                    <div className="card-body" id="dashboardCounterCardBody">
                        <div className="crm-numbers pb-0">
                            <div className="row">
                               
                                <div className="col cirlce-d " onClick={this.onClickCounter.bind(this,0)}>
                                    <span className="f13">
                                        <i className="icofont icofont-truck cus-i txt-info f24"></i>
                                        <br /> Total Trucks
                                    </span>
                                    <h4 className="txt-info f50">
                                        <span className="counter">
                                            <CountUp end={(this.state.totalTrucksCount != '')?this.state.totalTrucksCount:0}/>
                                        </span>
                                    </h4>
                                     <div className=" f20">
                                        <div className="counter txt-success-medium">
                                        On Trip Trucks: &nbsp;
                                            <CountUp end={(this.state.totalActiveConsignmentsCount != '')?this.state.totalActiveConsignmentsCount:0}/>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col " onClick={this.onClickCounter.bind(this,1)}>
                                    <span className="f13">
                                        <i className="icofont icofont-truck-loaded f22 txt-primary-dark"></i>
                                        <br /> Trucks with GPS Data
                                    </span>
                                    <h4 className="txt-primary f50">
                                        <span className="counter">
                                            <CountUp end= {(this.state.trucksWithGpsDataCount) ? this.state.trucksWithGpsDataCount : 0}/>
                                        </span>
                                    </h4>
                                </div>

                                <div className="col " onClick={this.onClickCounter.bind(this,3)}>
                                    <span className="f13">
                                        <i className="icofont icofont-social-google-map  f24 txt-pink-light"></i>
                                        <br /> GPS Data Not Reported Today
                                    </span>
                                    <h4 className="txt-pink-medium f50">
                                        <span className="counter">
                                            <CountUp end={(this.state.gpsDataNotReportedTodayCount) ? this.state.gpsDataNotReportedTodayCount : 0}/>
                                        </span>
                                    </h4>
                                    <div className=" f20">
                                        <div className="counter txt-success-medium">
                                        On Trip Trucks: &nbsp;
                                            <CountUp end={(this.state.gpsNotTodayActiveConsignmentsCount != '')?this.state.gpsNotTodayActiveConsignmentsCount:0}/>
                                        </div>
                                        {/* <div className="counter txt-danger-medium">
                                            Inactive: &nbsp;
                                            <CountUp end={(this.state.totalInactiveConsignmentsCount != '')?this.state.totalInactiveConsignmentsCount:0}/>
                                        </div> */}
                                    </div>
                                </div>
{/* 
                                <div className="col " onClick={this.onClickCounter.bind(this,4)}>
                                    <span className="f13">
                                        <i className="icofont icofont-truck-alt f22 txt-info-medium"></i>
                                        <br />Trucks with No Transporters
                                    </span>
                                    <h4 className="txt-info-dark f50">
                                        <span className="counter">
                                            <CountUp end={(this.state.trucksWithNoTransportersCount) ? this.state.trucksWithNoTransportersCount : 0}/>
                                        </span>
                                    </h4>
                                </div> */}


                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
           
            </div>
        );
    }
}







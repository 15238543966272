import React, { useEffect } from "react";
import "../../assets/css/ProgressbarCard.css";
import LocationBlue from "../../assets/icons/location-blue.svg";
var moment = require('moment');

const ProgressbarCard = (props) => {
  console.log("ProgressbarCard", props);
  let temp2 = props.eventData.map((e, i) => {
    if (!e.hasOwnProperty("actual_time")) {
      e["actual_time"] = ""
    }
    return e
  })
  let temp=temp2.filter(e=>e.searates_description!=="unknown")
  let last_active_event = null;

// Iterate over the list in reverse order and find the last ID with non-empty actual_time
  for (let i = temp.length - 1; i >= 0; i--) {
      if (temp[i].actual_time !== "") {
        last_active_event = temp[i]._id;
          break;
      }
  }

  if (Array.isArray(temp)) {
    temp.sort((a, b) => {
      if (a.actual_time !== "" && b.actual_time !== "") {
        return moment(a.actual_time, 'Do MMM, YYYY').diff(moment(b.actual_time, 'Do MMM, YYYY'))
      }
      else if (a.actual_time !== "") {
        return -1;
      }
      else if (b.actual_time !== "") {
        return 1;
      }
      else {
        return 0;
      }
    }
    );
  }
  useEffect(() => { }, [props]);
  return (
    <div className="card p-3" style={{ borderRadius: "10px" , height:"100%", marginBottom:"0px"}}>
      {/* Heading Part */}
      <div class="pl-4">
        <label htmlFor="" style={{fontWeight:"bold", fontSize:"20px"}}>
          Events Timeline
        </label>
        {/* <label htmlFor="" className="d-block fw-bolder">
          {props.container_no ? props.container_no : "NA"}
        </label> */}
      </div>

      {/* Progress Bar */}
      <div className="wrapper pl-3 pt-4">
        <ul className="StepProgress">
          {
            (!props.eventData || props.eventData.length === 0 || props.eventData === undefined) ? <></> :
              <>
                {temp.map((each, i) => {
                  let classname = "";
                  let idname = "";
                  let event_group="NA"
                  if(each.event_group!=""){
                    event_group= each.event_group
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (char) => char.toUpperCase())  
                  }                
                  if (each.actual_time == "") {
                    //for active events only current class is enough
                    if (i=== 0){
                      classname = classname + "start";
                      classname = classname + " current";
                    }
                    else if (i === temp.length -1){
                      classname = classname + " end";
                      classname = classname + " current";
                    } 
                    else{
                      classname = classname + " current";

                    }
                  } else {
                    if (each._id == last_active_event){
                      classname = classname + "last-actual-active"
                    }
                    if (i=== 0){
                      classname = classname + "start";
                    }
                    else if (i === temp.length -1){
                      classname = classname + " end";
                    } 
                    // for finished events we set is-done which gives blue color
                    classname = classname + " is-done";

                    //for finished events again we have to determine delay / early and then assign green or red color
                    if (each.statusCode === "Delay") {
                      classname = classname + " late-event";
                    } else if (each.statusCode === "Early") {
                      classname = classname + " early-event";
                    }

                    if (each.is_last_event) {
                      idname = "last-event";
                    }
                  }
                  return (
                      <div
                      className={`StepProgress-item ${classname}`}
                      id={idname ? idname : ""}
                      key={`${each.searates_description}-${i}`}
                      style={{ fontFamily:"Poppins-Regular"}}
                    >
                      <strong>{`${each.searates_description}, ${event_group}, ${each.location}`}</strong>
                      {(each.actual_time!="")?moment(each.actual_time).utcOffset(0).format("YYYY-MM-DD h:mma"):(each.estimated_time!=""?moment(each.estimated_time).utcOffset(0).format("YYYY-MM-DD  h:mma"):"NA")}
                    </div>
                  );
                })}
              </>
          }
        </ul>
      </div>
    </div>
  );
};

export default ProgressbarCard;

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM , getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import DrawMap from "../common/drawmap";
import Map from '../common/drawmap';

import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import MapComponent from './mapLocationComponent';
import Consignmentforceclose from '../manage/consignmentforceclose';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import ArrivalMap from "./arrivalMap";
import LoadArrivalCounters from "./loadArrivalCounter";

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
var map;
var dept_code;
var infoBox = require('../common/google-infowindow');

export default class ReturnTripsBreakClusters extends Component{
    constructor(props) {
        super(props);
        this.state={
            modules: AllModules,
            // truckData:this.props.truckInfo,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable: true
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadshow:'show-n',
            overly : "show-n",
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
            route_info:"",
			rowData: [],
			animateRows: true,
            consignment_details:"",
            dept_code: "",
            detailCellRendererParams: {},
            breakCount: "",
            id: "",
            plants_coords : [],
            rowClassRules : {
                "bgColorDangerLight" : function(params){
                    if(params.data.left_without_load == 1)
                    {
                        return true;
                    }
                    else{
                        return false;
                    }
                } 
            },
        };
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        this.setState({
            loadshow : "show-m",
            overly : "show-m"
        })
        if(this.props.match.path == "/sndreturnrtripclusters")
        {
            dept_code = "SNDG"
        }
        else if(this.props.match.path == "/prtreturnrtripclusters")
        {
            dept_code = "LOG-PRT"
        }
        else
        {
            dept_code = "LOG-TNP"
        }
        redirectURL.post("/consignments/getReturnTripsClusters", {"dept_code": dept_code,"breakCount": 0}).then((response)=>{
            this.setState({
                rowData: response.data.breaks,
                plants_coords: response.data.plants,
                dept_code: dept_code
            })
             this.renderMap()

        }).catch((e)=>{
            console.log(e)
        })

    }
    

    onClickHideAll(){
		this.setState({
            sliderRouteTranslate:"",
			overly:'show-n',
			loadshow:'show-n',
		});
		
    }
    
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        var sort = [
			{
			  colId: 'no_of_breaks',
			  sort: 'desc',
			},
		  ];
		this.gridApi.setSortModel(sort);
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
	}
    
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    renderMap = () =>
    {    	
    loadScriptWeather("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMap");
    window.initMap = this.initMap
    }

    initMap = () => {
        
        var bounds = new window.google.maps.LatLngBounds();
        if(this.state.lat != "" && this.state.long != "")
        {
            map = new window.google.maps.Map(document.getElementById('oemMap'), {
                zoom: 8,
                mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                center: new window.google.maps.LatLng(28.497236,77.062363),
                mapTypeControl: true,
                mapTypeControlOptions: {
                    style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: window.google.maps.ControlPosition.TOP_RIGHT
                },            
                disableDefaultUI: true,
                fullscreenControl: true,
                zoomControl: true
            });
            
            // console.log(this.state.rowData.length)
            this.state.rowData.map(function(e, index){
                    bounds.extend(new window.google.maps.LatLng(e.lat,e.lng));
                    var breakMarker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng(e.lat,e.lng),
                        map: map,
                        icon: require("../../assets/icons/truck_7_32.png"),
                    });
                    var infowindow = new window.google.maps.InfoWindow();
                    //var l;
                    window.google.maps.event.addListener(breakMarker, 'mouseover', (function(breakMarker) {
                        return function() 
                        {
                            var contentarr = []
                            var header = "Break - "+(index+1).toString()
                            contentarr.push({"key":"No. of breaks", "value": e.no_of_breaks})
                            var contentString = infoBox(breakMarker.icon, header, contentarr)
                            infowindow.setContent(contentString);
                            var currentwindow = infowindow;
                            infowindow.open(map, breakMarker);
                        }
                    })(breakMarker));
                    window.google.maps.event.addListener(breakMarker, 'mouseout', function() {
                        infowindow.close();
                        });
            })
            this.state.plants_coords.map(function(e, index){
                bounds.extend(new window.google.maps.LatLng(JSON.parse(e.plant_coordinates).lat,JSON.parse(e.plant_coordinates).lng));
                var breakMarker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(JSON.parse(e.plant_coordinates).lat,JSON.parse(e.plant_coordinates).lng),
                    map: map,
                    icon: require("../../assets/icons/business.png"),
                });
                var infowindow = new window.google.maps.InfoWindow();
                //var l;
                window.google.maps.event.addListener(breakMarker, 'mouseover', (function(breakMarker) {
                    return function() 
                    {
                        var contentarr = []
                        var header = "Plant"
                        contentarr.push({"key":"Plant", "value": e.plant_name})
                        var contentString = infoBox(breakMarker.icon, header, contentarr)
                        infowindow.setContent(contentString);
                        var currentwindow = infowindow;
                        infowindow.open(map, breakMarker);
                    }
                })(breakMarker));
                window.google.maps.event.addListener(breakMarker, 'mouseout', function() {
                    infowindow.close();
                    });
            })
                
            map.fitBounds(bounds)
        }
        
        this.setState({
            overly : "show-n",
            loadshow : "show-n",
        })
        
    }
   async onCellClicked(row){
        if(row.colDef.field == "showbreaks")
        {
            console.log(row.node.expanded);
            let eventLabel = googleAnalytics.page.action.transitDelay;
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
        
            var transitedelaydata=[];
            var bounds = new window.google.maps.LatLngBounds();
            bounds.extend(new window.google.maps.LatLng(parseFloat(row.data.lat),parseFloat(row.data.lng)));
            map.fitBounds(bounds)
            map.setZoom(20)
           
            map.fitBounds(bounds)
        }
        

    }
    onRowClicked(row){
        console.log(row)
    }
    resetZoom(){
        this.renderMap()
    }
    getBreaks(){
        var breakCount = this.state.breakCount;
        if(breakCount == "")
        {
            breakCount = "null";
        }
        var id = this.state.id;
        if(id == "")
        {
            id = "null"
        }
        redirectURL.post("/consignments/getReturnTripsClusters", {dept_code: this.state.dept_code, breakCount: breakCount, id: id}).then(
        (response)=>{
            this.setState({
                rowData: response.data.breaks,
                plants_coords: response.data.plants,
            });
            this.renderMap()
        }).catch((e)=>{console.log(e)})
    }
    updatebreakName(row)
    {
        redirectURL.post("/consignments/updateBreakName",{"break_name":row.value, "_id": row.data._id, dept_code: this.state.dept_code}).then((response)=>{

        }).catch((e)=>{console.log(e)})
    }
    render(){
        const hidebreakname = (this.state.dept_code == "SNDG")?true:false;
        const columnwithDefs = [
            {
                headerName : "",
                field : "showbreaks",
                colId: "showbreaks",
                filter : true,
                resizable :true,
                width:60,
                editable:false,
                cellRenderer: function(params)
                {
                    return "<i class='icofont icofont-map-pins f25'></i>";
                },
            },
            {
                headerName : "Valid?",
                field : "is_valid",
                colId: "is_valid",
                filter : true,
                resizable :true,
                width:80,
                cellRenderer: function(params) { 
                    if(params.data.is_valid == 1)
                    {
                        return '<input type="checkbox" class="validCheckbox" id='+params.data._id+' checked="checked" />';

                    }
                    else
                    {
                        return '<input type="checkbox" class="validCheckbox" id='+params.data._id+' />';

                    }
                }
            },
            {
                headerName : "Break Name",
                field: "break_name",
                colId: "break_name",
                editable : true,
                width: 130, 
                hide : hidebreakname
            },
            {
                headerName : "Break Latitude",
                field: "lat",
                colId: "lat",
                width: 130, 
            },
            {
                headerName : "Break Longitude",
                field: "lng",
                colId: "lng",
                width: 130, 
            },
            {
                headerName : "No of breaks",
                field: "no_of_breaks",
                colId: "no_of_breaks",
                width: 130, 
            },
            {
                headerName: "Area / State",
                field: "area",
                colId: "area",
                valueGetter : function(params)
                {
                    return params.data.area+" / "+params.data.state
                }
            }
        ]
        const dStyles={
            width:'100%',
            height:'550px'
        }
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row col-xl-12 col-lg-12">
                    <div className="form-group col-xl-3 col-lg-3">
                        <label>Enter Break ID : </label>
                        <input className="form-control" type="text" onChange={(e)=>{this.setState({id: e.target.value})}} ></input>
                    </div>
                    <div className="form-group col-xl-2 col-lg-2">
                        <label>Enter No. of Breaks : </label>
                        <input className="form-control" type="number" onChange={(e)=>{this.setState({breakCount: e.target.value})}} ></input>
                    </div>
                    <div className="form-group col-xl-4 col-lg-4">
                        <label><br /></label><br />
                        <button className="btn btn-success" onClick={this.getBreaks.bind(this)}>Submit</button> 
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-7 col-lg-7">
                        <div className="row card">
                            <div className="col-sm-12 col-lg-12 card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.dept_code} Return Trips Break Clusters</span>
                                </h5>
                            </div>
                            <div id="oemMap" className="" style={dStyles}></div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-5">
                        <div className="col-sm-5 col-sm-5 card-body pt-15px " >
                            <button className="btn btn-info" onClick={this.resetZoom.bind(this)}>reset zoom</button>
                            <div id="myGrid" style={{ height: "580px",width: "47em"}}  className="ag-theme-balham">
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    enableCharts={true}
                                    enableRangeSelection={true}
                                    autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    rowClassRules={this.state.rowClassRules}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    frameworkComponents={this.state.frameworkComponents}
                                    detailCellRendererParams={this.state.detailCellRendererParams}
                                    // stopEditingWhenGridLosesFocus={true}
                                    enableCellChangeFlash={false}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    masterDetail={true}
                                    autoHeight={true}
                                    onCellClicked={this.onCellClicked.bind(this)}
                                    onRowClicked={this.onRowClicked.bind(this)}
                                    onCellEditingStopped={this.updatebreakName.bind(this)}
                                    // rowSelection={this.state.rowSelection}
                                    // suppressRowClickSelection={true}
                                    
                                />
                                    
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className={"dataLoadpage " +(this.state.loadshow)}></div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        )
    }
}

function loadScriptWeather(url){
    var index  =window.document.getElementsByTagName("script")[1]
      var script = window.document.createElement("script")
      script.src=url
      script.async=true
      script.defer = true
      index.parentNode.insertBefore(script,index)
  }
        
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}

$(document).on("click","#bounce_break",function(e){
    var location = $(this).val();
    var bounds = new window.google.maps.LatLngBounds();
    bounds.extend(new window.google.maps.LatLng(parseFloat(location.split(",")[0]),parseFloat(location.split(",")[1])));
    map.fitBounds(bounds)
    map.setZoom(20)
})

$(document).on("click",".validCheckbox",function(e){
    console.log($(this).prop("id"), dept_code)
    if($(this).prop("checked"))
    {
        var is_valid = 1;
    }
    else
    {var is_valid=0;}
    redirectURL.post("/consignments/setIsValid",{"is_valid": is_valid, "dept_code": dept_code, "_id": $(this).prop("id")}).then(()=>{
        console.log("done");
    })
})
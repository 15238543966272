import React, { Component } from 'react';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Select from 'react-select';
import UserLogDashboardChart from "./userlogdashboardChart";
import UserLogScreenWiseChart from "./userlogscreenwisechart";
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
    
export default class ForecastDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadshow:'show-n',
            overly:'show-n',
            forecastdata:[],
            categories:[],
            forecastlinedata:[],
            cmn:1
            
        }
        this.loadForecastData = this.loadForecastData.bind(this);
    }

    componentWillMount(){
        // var year = moment.parseZone().format("YYYY");
        // this.setState({
        //     year:year
        // })
        // var parameters = {
        //     user_type:["ORGUSER"],
        //     year:year
        // }
        // this.loadForecastData(parameters)
    }
    
    componentDidMount(){
       
        var parameters = {
            
        }
        this.loadForecastData(parameters)
        var q = ["04","05","06","07","08","09","10","11","12","01","02","03"];
        var c = moment.parseZone().format("MM");
        var s = q.indexOf(c);
        this.setState({
            cmn:s
        })
    }
    

    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            bulkslide:"",
        
        });
        
    }
    loadForecastData(parameters)
    {
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        })
        redirectURL.post("/dashboard/forecastdata",parameters)
        .then((response) => {
            // console.log("response ", response.data);
            var records = response.data;
            var forecastdata=[];
            var forecastval=[];
            var actualval=[];
            var targetedval=[];
            var cats=[]
            var forecastlinedata = [];
            var forecastconfdata = [];
            var cmval = parseInt(this.state.cmn);
            console.log('cmval ', cmval)
            if(records.length > 0)
            {
                
                records = records.sort(GetSortAscOrder("order"));
                records.map((item) => {
                    // console.log("item", item.month_target);
                    cats.push(item.month_name+" "+item.year);
                    forecastval.push(parseInt(item.forecast_value));
                    forecastconfdata.push(parseInt(item.forecast_value));
                    forecastlinedata.push(item.forecast_value)
                    actualval.push(parseInt(item.actual));
                    targetedval.push(parseInt(item.month_target));
                })
            }
            forecastdata.push({
                name: "Forecast",
                data: forecastval,
              },
              {
                name: "Actual",
                data: actualval,
              },
              {
                name: "Target",
                data: targetedval,
              })

              var forecastconfdataseries = [];
              forecastconfdataseries.push({
                    name: 'Forecast',
                    data: forecastlinedata,
                    zoneAxis: 'x',
                    zones: [{
                        value: cmval
                    }, {
                        dashStyle: 'dot'
                    }]
              })
// console.log("forecastdata ",forecastdata)
            this.setState({
                loadshow:'show-n',
                overly:'show-n',
                forecastdata:forecastdata,
                categories:cats,
                forecastlinedata:forecastconfdataseries
            })
        })
    }
    
    render() {
        const dStyles={
            width:'100%',
            height:'100%'
        }
        var mcolorcodes =  ['#F02A5A', '#4F8BF0','#92C144', '#A24341','#1A9392', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce','#92A8CD','#A47D7C', '#B5CA92','#2b908f'];

        const highchartoptions = {
            chart: {
              type: 'column'
            },
            plotOptions: {
                column: {
                    // colorByPoint: true
                },
                series: {
                  maxPointWidth: 20
              }
            },
            title:{
                text:"Month Wise Rail Load Forecast"
            },
          xAxis: {
            // type:'category'
            categories:this.state.categories
           },
        yAxis: {
            min: 0,
            title: {
                text: "No of vins"
            }
        },
          legend: {
            enabled: true
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                // colorByPoint:true,
                dataLabels: {
                    enabled: true,
                    format: '{point.y}'
                }
            }
        },
        series:this.state.forecastdata,
          colors:mcolorcodes,
            credits:false,
            responsive: {
            rules: [{
                condition: {
                    maxWidth: '100%'
                },
                chartOptions: {
                    chart: {
                        height: '100%'
                    },
                    subtitle: {
                        text: null
                    },
                    navigator: {
                        enabled: false
                    }
                }
            }]
            }
          };

    const highchartoptions1 = {
        chart: {
            type: 'spline'
        },
        title: {
            text: 'Monthly Forecast'
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            categories: this.state.categories,
            accessibility: {
                description: 'Months of the year'
            }
        },
        yAxis: {
            title: {
                text: 'No of vins'
            },
            labels: {
                formatter: function () {
                    return this.value;
                }
            }
        },
        tooltip: {
            crosshairs: true,
            shared: true
        },
        plotOptions: {
            spline: {
                marker: {
                    radius: 4,
                    lineColor: '#666666',
                    lineWidth: 1
                }
            }
        },
        // series: [{
        //     name: 'Forecast',
        //     marker: {
        //         symbol: 'square'
        //     },
        //     data: this.state.forecastlinedata,
        //     zoneAxis: 'x',
        //     zones: [{
        //         value: 8
        //     }, {
        //         dashStyle: 'dot'
        //     }]
    
        // }]
        series:this.state.forecastlinedata
    };
    return (
        <div class="container-fluid">
            <div className="card">
                <div className="card-header">
                    <h5>
                        <i className="icofont icofont-pie cus-i"></i> <span>Forecast Dashboard</span>
                    </h5>
                </div>
                <div className="card-body">
                    <div className="row mt-40p">
                        <div className="col-sm-12 mt-40p mb-40p">
                            <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />
                        </div>

                        <div className="col-sm-12 mt-40p mb-40p">
                            <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions1} />
                        </div>
                       
                    </div>
                   
                </div>
            </div>
            <div className={"dataLoadpage " +(this.state.loadshow)}></div>
            <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
        </div>

        );
    }
}

function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
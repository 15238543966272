"use strict";

import React, { Component, useDebugValue } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { render } from "react-dom";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MenuModule } from "@ag-grid-enterprise/menu";
// import Consignmentforceclose from '../m';
import CSVFileValidator from "csv-file-validator";
import Select from "react-select";
import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import CountUp from "react-countup";
import SweetAlert from "react-bootstrap-sweetalert";
import * as Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import $ from "jquery";
import ColumnGroup from "./columngroupComponent";
import {
  getDDMMYYYY,
  getDDMMYYYYHHMMSS,
  getYYYYMMDD,
  getYYYYMMDDHHMMSS,
  getHyphenYYYYMMDDHHMMSS,
  getHyphenDDMMMYYYYHHMM,
  getHyphenYYYYMMDD,
  getHyphenDDMMYYYYHHMMSS,
  getHyphenDDMMYYYY,
  getDDMMMYYYYHHMMDefault,getDDMMMYYYYDefault} from "../common/utils";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import BatchDownload from "./batchdownload";
import { isBuffer } from "lodash";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require("../redirectURL");
var moment = require("moment");
var filecontent = "";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;
var validatedBy = "";

export default class CoilStockInformationScreen2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        width: 160,
        resizable: true,
      },
      eventAction: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      loadshow: "show-n",
      overly: "",
      rowdata: [],
      sliderbulkupload: "",
      rowModelhighchartoptionsType: "enterprise",
      frameworkComponents: {
        BatchDownload: BatchDownload,
      },
      rowGroupPanelShow: "always",
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      paginationPageSize: 50,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      animateRows: true,
      dept_code: "",
      pagetitle: "",
      csvData: 0,
      overly: "show-n",
      loadshow: "show-n",
      pagetitle: "",
      eventGridAction: "gridAction",
      csvcontent: [],
      file: "",
      batch_name: "",
      cluster_breaks: "2",
      cluster_radius: "0.5",
      notification_emails: "",
      isChecked: false,
      isPopupVisible: false,
      setEditable: 0,
      rowId: "",
      container_type: "",
    };
  }

  componentDidMount() {
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    // loadDateTimeScript()
    redirectURL
      .post("/consignments/coiltrackingrequirements")
      .then((response) => {
        // var totalData=response.data
        var storage = response.data.filter((ele) => {
          return !(
            ele.destination == "" ||
            ele.destination == undefined ||
            ele.destination == "undefined" ||
            ele.destination == null
          );
        });

        
        storage=storage.map((ele)=>{
          if(ele.stock_at_warehouse=="HRC"||ele.stock_at_warehouse=="Patli"){
            return ({
              unique_no:ele.coil_unique_no,
              stock_at_warehouse:ele.stock_at_warehouse,
              order_received_from_sc:ele.order_received_from_sc,
              destination:ele.destination,
              dispatch_date:ele.dispatch_data_gst_registered_warehouse,
              trailor_no:ele.gst_registered_trailor_no,
              transporter_name:ele.gst_registered_warehouse_transporter_name,
              destination_reached_date:ele.gst_registered_dispatch_reached_date,
              unloaded_date:ele.gst_registered_unloaded_date
            })
          }
                   

          else if(ele.stock_at_warehouse=="CCA1"||ele.stock_at_warehouse=="CCA2"||ele.stock_at_warehouse=="TM1"||ele.stock_at_warehouse=="TM2")
          {
            return ({
              unique_no:ele.coil_unique_no,
              stock_at_warehouse:ele.stock_at_warehouse,
              order_received_from_sc:ele.order_received_from_sc,
              destination:ele.destination,
              dispatch_date:ele.dispatch_data_gst_registered_warehouse,
              dispatch_date_transit:ele.dispatch_date_from_transit_warehouse,
              trailor_no:ele.gst_registered_trailor_no,
              transporter_name:ele.gst_registered_warehouse_transporter_name,
              destination_reached_date:ele.gst_registered_dispatch_reached_date,
              unloaded_date:ele.gst_registered_unloaded_date
              
            })
          }
        })
        console.log(storage, "storage----");
        // console.log(total)

        this.setState({
          rowdata: storage,
          loadshow: "show-n",
          overly: "show-n",
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  closeAlert = () => {
    this.setState({
      show: false,
    });
  };

  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      bulkslide: "",
    });
  }

  render() {
    const batchwisecolumns = [
      {
        headerName: "Coil Unique No.",
        field: "unique_no",
      },
      {
        headerName: "Stock At Warehouse",
        field: "stock_at_warehouse",
      },
      {
        headerName: "Order received from SC",
        field: "order_received_from_sc",
        valueGetter:function(params){
            try{
                return getDDMMMYYYYHHMMDefault(params.data.order_received_from_sc);
            }
            catch(e){
                return "";
            }
        },
      },
      {
        headerName: "Destination",
        field: "destination",
      },
      {
        headerName : "Dispatch Date from Transit W/H",
        field : "dispatch_date_transit",
        valueGetter:function(params){
          try{
              return getDDMMMYYYYHHMMDefault(params.data.dispatch_date_transit);
          }
          catch(e){
              return "";
          }
      },
        // cellEditor: DateEditor,
        editable:false,
        cellClass:function(params){
          if(params.data.dispatch_date_transit == ""||params.data.dispatch_date_transit==null)
          {
            return 'bgColorAshLight';
          }
        }
      },
      {
        headerName : "Dispatch Date from GST Registered W/H",
        field : "dispatch_date",
        // cellEditor: DateEditor,
        editable:false,
        valueGetter:function(params){
          try{
              return getDDMMMYYYYHHMMDefault(params.data.dispatch_date);
          }
          catch(e){
              return "";
          }
      },
       
 
    },
      {
        headerName: "Trailer No.",
        field: "trailor_no",
        editable: false,
      },
      {
        headerName: "Transporter Name",
        field: "transporter_name",
        editable: false,
      },
    //   {
    //     headerName : "Transit W/H Destination Reached Date",
    //     field : "transit_dispatch_reached_date",
    //     // cellEditor: DateEditor,
    //     editable:false,
       
  
    // },
    // {
    //     headerName : "Transit W/H Unloaded Date",
    //     field : "transit_unloaded_date",
    //     // cellEditor: DateEditor,
    //     editable:false,
       
      
    //   },
    {
        headerName : "Vendor/MSIL Reached Date",
        field : "destination_reached_date",
        // cellEditor: DateEditor,
        editable:false,
        valueGetter:function(params){
          try{
              return getDDMMMYYYYHHMMDefault(params.data.destination_reached_date);
          }
          catch(e){
              return "";
          }
      },
      },
      {
        headerName : "Unloaded Date",
        field : "unloaded_date",
        // cellEditor: DateEditor,
        editable:false,
        valueGetter:function(params){
          try{
              return getDDMMMYYYYHHMMDefault(params.data.unloaded_date);
          }
          catch(e){
              return "";
          }
      },

      }
     
    ];
    return (
      <div class="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="card">
              <div className="card-header">
                <h5>
                  <i className="icofont icofont-vehicle-delivery-van cus-i"></i>
                  <span>Coils Tracking</span>
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div
                    id="myGrid"
                    style={{ width: "100%", height: "478px" }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      modules={this.state.modules}
                      columnDefs={batchwisecolumns}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.rowdata}
                      enableCharts={false}
                      // autoGroupColumnDef={this.state.autoGroupColumnDef}
                      onGridReady={this.onGridReady}
                      onGridState={this.onGridState}
                      frameworkComponents={this.state.frameworkComponents}
                      rowClassRules={this.state.rowClassRules}
                      // statusBar={this.state.statusBar}
                      sideBar={this.state.sideBar}
                      stopEditingWhenGridLosesFocus={true}
                      paginationPageSize={this.state.paginationPageSize}
                      pagination={true}
                      gridOptions={{
                        context: { componentParent: this },
                      }}
                      // components={this.state.components}
                      //   onRowSelected={this.onSelectedCheckbox.bind(this)}
                      enableRangeSelection={true}
                      // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

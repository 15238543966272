import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../node_modules/react-resizable/css/styles.css';
import { WidthProvider, Responsive } from "react-grid-layout";
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import _ from "lodash";
import $ from "jquery";
var redirectURL = require('../../redirectURL');



export default class Overspeed extends Component{

	constructor(props)
	{
		super(props);
		this.state={
            min_unloading_duration:"",
            dept_code:"",
            short_haul:"",
            long_haul:"",
            userid:localStorage.getItem('userid'),
            email:localStorage.getItem('email'),
        };
        
		
    }
    componentDidMount(){
        // var dept_code = "SNDG";
        var dept_code = this.props.deptCode;
        var formdata = {
            config_type:'unloadingdelay',
            dept_code : dept_code
        };
        
        redirectURL.post('/manage/kpiConfigurations',formdata, {
            headers:{
                'Content-type':'application/json'
            }
        })
        .then((response) => {
            console.log(response.data);
            if(response.data.length > 0)
            {
                if(dept_code != "LOG-TNP")
                {
                    this.setState({
                        min_unloading_duration : response.data[0].min_unloading_delay_duration,
                        dept_code : dept_code
                    });
                }
                else{
                    this.setState({
                        short_haul : response.data[0].short_haul_min_duration_delay,
                        long_haul : response.data[0].long_haul_min_duration_delay,
                        dept_code : dept_code
                    });
                    $("#short-haul-id").val(Number(response.data[0].short_haul_min_duration_delay));
                    $("#long-haul-id").val(Number(response.data[0].long_haul_min_duration_delay))
                }
            }
            else
            {
                if(dept_code != "LOG-TNP")
                {
                    this.setState({
                        min_unloading_duration : "",
                        dept_code : dept_code
                    });
                }
                else
                {
                    this.setState({
                        short_haul : "",
                        long_haul : "",
                        dept_code : dept_code
                    });
                    $("short-haul-id").val("");
                    $("long-haul-id").val("");
                }
            }
            console.log(typeof(this.state.duration),this.state.distance,this.state.nightRules);

        })
    }
   
	formHandler = (event) => {
        event.preventDefault(); 
        
        if(this.state.dept_code != "LOG-TNP"){
            var min_unloading_duration = document.getElementById("minDelayID").value;
            var formdata = {
                userid:this.state.userid,
                user_email:localStorage.getItem('email'),
                dept_code:this.state.dept_code,
                config_type:"unloadingdelay",
                min_unloading_delay_duration:min_unloading_duration,
                // threshold_time:(this.state.time*60)
            }
        }
        else{
            
            var formdata = {
                userid:this.state.userid,
                user_email:localStorage.getItem('email'),
                dept_code:this.state.dept_code,
                config_type:"unloadingdelay",
                short_haul_min_duration_delay : $("#short-haul-id").val(),
                long_haul_min_duration_delay : $("#long-haul-id").val()
                // threshold_time:(this.state.time*60)
            }
        }
        
        console.log(formdata)
        redirectURL.post('/manage/saveULDKPIConfigurations',formdata, {
            headers:{
                'Content-type':'application/json'
            }
        })
        .then((response) => {
            var res = response.data;
            if(res.result.result.ok == 1)
            {
                this.props.context.showAlert();
            }
            else{
                this.props.context.showErrorAlert();
            }
        })
    }
    checkDuration = (event) =>{
        this.setState({
            // distance : event.target.value,
            min_unloading_duration : event.target.value
        })
    }
    changeDept(params){
        this.setState({
            dept_code:params.value
        })
    }

    selectDept(){
        let arr=[];
        try{
            arr.push({value:"SNDG",label:"Sales and Dispatch"});
            arr.push({value:"LOG-PRT",label:"Spare Parts"});
            arr.push({value:"LOG-TNP",label:"Train and Production"});
            return arr;
        }
        catch(e){

        } 
    }
	 render() {
         let nightDrivingRules;
        return (
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <h5>UnLoading Delay Settings</h5>
                </div>
                <form className=" theme-form col-xl-6 col-lg-6" onSubmit={this.formHandler}>
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            {/* <label>Select Department : </label>
                            <Select 
                                placeholder={"Department"}
                                closeMenuOnSelect={true}
                                // isMulti="true"
                                // onChange={this.getRailConsginments.bind(this)} 
                                className={"border-radius-0"}
                                style={{borderRadius:"0px"}}
                                options={this.selectDept()}
                                onChange={this.changeDept.bind(this)}
                             />     */}
                        </div>        
                    </div>
                    {/* {this.state.dept_code} */}
                    {(this.state.dept_code != 'LOG-TNP')?
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            <label className="c-lbl">Minimum Unloading Delay Duration (Hours) * : </label>
                            <input type="number" value = {this.state.min_unloading_duration} id="minDelayID" className="form-control validateNumber" autoComplete="off" onChange={this.checkDuration.bind(this)} required />
                        </div>
                    </div>
                    :
                        <div>
                            <div className="form-group">
                                <label className="c-lbl">Short Haul (S): </label><br />
                                <label>Minimum Unloading Delay Duration (Hours) * : </label>
                                <input type="number" id="short-haul-id" className="form-control validateNumber" autoComplete="off"  required />
                            </div>
                            <div className="form-group">
                                <label className="c-lbl">Long Haul (S): </label><br />
                                <label>Minimum Unloading Delay Duration (Hours) * : </label>
                                <input type="number" id="long-haul-id" className="form-control validateNumber" autoComplete="off" required />
                            </div>
                        </div>
                    }
                    <div className="form-group">
                        <button type="submit" className="btn btn-success cs-btn" id="save">Save</button>
                    </div>
                </form>
            </div>
        );
    }
}
$(document).ready(function(){
    $(document).on('keyup',".validateNumber",function(){
        // alert("click");
        if(isNaN($(this).val()) == true){
            $(this).val($(this).val().slice(0,-1));
        }
    });
    $(document).on("keyup",".validateEmail",function(e){
        if(e.which === 32){
            $(this).val($(this).val()+", ");
        }
    });
    $(document).on("keyup","#minDelayID",function(){
        if($(this).val() == 0){
            // alert("Value Should be Greater than 0");
            // $(".error-msg").empty();
            // $(".error-msg").append("Value Should be greater than 0");
            $(this).val(" ");
        }
    })
});


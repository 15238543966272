import React, { Component } from "react";
import CSVFileValidator from 'csv-file-validator';
import moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MenuModule } from "@ag-grid-enterprise/menu";
import Select from "react-select";
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import $ from "jquery";
import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { color } from "highcharts";
// import { formatDate } from "@fullcalendar/react";
var redirectURL = require("../redirectURL");

export default class LSPCarrierDataAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        width: 160,
        resizable: true,
      },
      eventAction: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      // loadshow: "show-n",
      // overly: "show-n",
      rowdata: [],
      rowdataforexwork:[],
      additionalchargesdata:[],
      sliderbulkupload: "",
      rowModelhighchartoptionsType: "enterprise",
      //   frameworkComponents: {
      //     BatchDownload: BatchDownload,
      //   },
      rowGroupPanelShow: "always",
      statusBar: {
        // statusPanels: [
        //   {
        //     statusPanel: "agTotalAndFilteredRowCountComponent",
        //     align: "left",
        //   },
        //   {
        //     statusPanel: "agTotalRowCountComponent",
        //     align: "center",
        //   },
        //   { statusPanel: "agFilteredRowCountComponent" },
        //   { statusPanel: "agSelectedRowCountComponent" },
        //   { statusPanel: "agAggregationComponent" },
        // ],
      },
      paginationPageSize: 50,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      animateRows: true,
      dept_code: "",
      pagetitle: "",
      csvData: 0,
      overly: "show-n",
      loadshow: "show-n",
      pagetitle: "",
      eventGridAction: "gridAction",
      csvcontent: [],
      file: "",
      batch_name: "",
      cluster_breaks: "2",
      cluster_radius: "0.5",
      notification_emails: "",
      bulkslide1: "",
      bulkslide2: "",
      bulkslide3: "",
      bulkslide4:'',
      bulkslidebulk:'',
      newValue: "",

      rowId: "",
      show: false,
      basicTitle: "",
      basicType: "",
      transitDate: "",
      transpoterGSTDate: "",
      unloadedDate: "",
      reachedDate: "",
      SectororCountry: "",
      location: "",
      additionalcharges:'',

      AustriaId1:"",
      ChinaId1:'',
      RepublicId1:'',

      ratesUpto:'',
      exworksCharges:'',
      plantname:'',
      transporterrate:"",
      additional_charges_cfs:0,
      additional_charges_cbm:0,
      additional_charges_shipment:0,
      usdvaluedisplay:'',
      eurovaluedisplay:'',
      Anamet_Europe_BV:0,
      Baker_Hughes_Digital_Solutions_GMBH:0,
      Kamax_Czech_Republic:0,
      Kamax_Spain:0,
      csvData: [],
            bulkMarkData: [],
            bulkslide: "",

            modevalue:'',
            tyrevalue:'',
            domevalue:'',
            ramp1value:'',
            ramp2value:'',
            ramp3value:'',
            rampU3value:'',
            rampadjust:'',
            addtionalrampValue:'',
            lspCode:[],
            lspCodeCollect:[],
          

    };
    this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
  }

  componentDidMount = async()=> {
    document.body.addEventListener("click", this.handleClickOutside);
 this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    var lspCodeCollect = []
  await  redirectURL
      .post("/consignments/lspcarrierdatastore")
      .then((response) => {
        // console.log(response.data, "140----");

        response.data.map((ele)=>{
          // console.log(ele.lsp_code,'employee_name')
          lspCodeCollect.push(ele.lsp_code)
      })
// console.log(lspCodeCollect,'lspCodeCollect:[]')
  this.setState({
    lspCodeCollect:lspCodeCollect,
    rowdata:response.data,
    loadshow: "show-n",
    overly: "show-n",
  })
    // console.log(this.state.lspCodeCollect,'in state data')
      })
      .catch((e) => {
        console.log(e);
      });
      // loadDateTimeScript();
  }
  
  closeAlert = () => {
    this.setState({
      show: false,
    });
  };


updateState(key, value) {
  console.log(value.name,'value',key.value);
  this.setState({
    [value.name]: key.value,
  });
}

  addtionalramp(e){
    console.log(e.target.value,'valuerampadd')
    
      this.setState({
        addtionalrampValue:e.target.value
      })
   
  }
  showBulkUpload() {
    this.setState({
        overly: "show-m",
        bulkslidebulk: "slider-translate"
    })
  }


OnsubmitAddData(event, field) {
    event.preventDefault();
console.log(this.state.modevalue,'modevalue')
    var modevalue = this.state.modevalue
   var tyrevalue = this.state.tyrevalue
  var  domevalue = this.state.domevalue
  var  ramp1value = this.state.ramp1value
   var ramp2value = this.state.ramp2value
   var ramp3value = this.state.ramp3value
   var rampU3value = this.state.rampU3value
   var rampadjust = this.state.rampadjust
   var addtionalrampValue = this.state.addtionalrampValue

      var params = {
        modevalue:modevalue,
        tyrevalue:tyrevalue,
        domevalue:domevalue,
        ramp1value:ramp1value,
        ramp2value:ramp2value,
        ramp3value:ramp3value,
        rampU3value:rampU3value,
        rampadjust:rampadjust,
        addtionalrampValue:addtionalrampValue
      };

      console.log(params, "params===");

      redirectURL
        .post("/consignments/addlspcarrierdata", params)
        .then((response) => {
          if (response.data.message == "success") {
            this.setState({

              loadshow: "show-n",
              overly: "show-n",
              bulkslide: "",
              show:true,
              basicTitle:"File Added Successfully",
              basicType:'success'
             });
           
            this.componentDidMount();
          }
        })
        .catch((e) => {
          console.log(e);
        });
   
  }
changeConsignerArrayHandler = lspCode =>{
  this.setState(
  { lspCode },
  () => {console.log(`lspCode Option selected:`, this.state.lspCode)
  if(this.state.lspCode == null){
  this.componentDidMount()
  }
}
  );
  
  this.setState({
errorMessage:'',
successMessage:''
  })
}
 
selectConsignerOptionsItems() {
  let items = [];
  const lspCodelist = [];
  const uniqueNames = Array.from(new Set(this.state.lspCodeCollect));

  uniqueNames.forEach(name => {
    console.log(name,'in selection')
    lspCodelist.push({ "code": name, "value": name });
  });

  // items.push({ value: 'all', label: 'All' });

  lspCodelist.forEach(item => {
    items.push({ value: item.code, label: item.value });
  });

  return items;
}
  

async uploadBulkFormHandler(e) {
  e.preventDefault();
  var dataType = this.state.dataType;
  var fileData = this.state.bulkMarkData;
  var flag = 0;

  fileData.map((ele) => {
    console.log(ele, '697----')
    if (ele.lsp_code == "") {
      flag = 1;
    } else if (ele.lsp_name == "") {
      flag = 1;
    } else if (ele.regn_number == "") {
      flag = 1;
    } else if (ele.mode == "") {
      flag = 1;
    }
  })

  if (flag == 0) {
    if (fileData.length > 0) {
    
   
        var reqParams = {
          csvData: fileData,
        }
        console.log(reqParams, 'paramsforbulk')

        this.setState({
          loadshow: 'show-m',
          overly: "show-m"
        });
      
        redirectURL.post("/consignments/bulkuploadlspcarriers", reqParams)
          .then((response) => {
            var resp = response.data.message;
            console.log('resp09876', response.data)

            if (response.data.message == "success") {
              this.setState({
                basicTitle1: resp,
                loadshow: 'show-n',
                overly: 'show-n',
                basicType1: "success",
                basicTitle: "Successfully Uploaded",
                basicType: "success",
                show1: true,
                show: true,
                bulkslidebulk: '',
                file: "",
                bulkMarkData: []
              })
            }

            this.componentDidMount()
            $('#upform')[0].reset();
          })
          .catch((e) => {
            console.log(e);
          });
       
     
    } else {
      this.setState({
        basicTitle: "Invalid Data",
        basicType: "warning",
        show: true,
        file: "",
        bulkMarkData: []
      });
      $('#upform')[0].reset();
    }
  } else {
    $("#bulkUploadID").val();
    this.setState({
      basicTitle: "Fields Should not be Empty",
      basicType: "warning",
      show: true,
      file: "",
      bulkMarkData: []
    });
    $('#upform')[0].reset();
  }
};

onSubmitFilter = async () => {

  this.setState({
      loadshow : "show-m",
      overly : "show-m"
  })

  var lspCodelist = []

  if(this.state.lspCode == null || this.state.lspCode == "")
  {
      // lspCodelist.push("all")
  }
  else
  {
      var clist= this.state.lspCode;
      clist.map((item) => {
        lspCodelist.push(item.value)
      })
  }

  var requestparams = {
     
      lsp_code:lspCodelist,
  }
 console.log(requestparams,'requestparams')
 if(lspCodelist.length != 0){
  await redirectURL.post("/consignments/filterlspcarriersdata",requestparams).then((response)=>{
      if(response.data.length>0){
        console.log(response.data,'response--fetched')
        this.setState({
          loadshow : "show-n",
          overly : "show-n",
          rowdata:response.data,
          // filterData:response.data,
        } )
        // this.OnlineStatusFilterShow()
      }else{
        console.log(response.data,'response--not fetched')
        this.setState({
          rowdata:[]
        })
      }
  })
}else {
  alert("Please select LSP Code")
  this.setState({
    startDate : '',
    endDate : ''
  })
  this.componentDidMount()
}
  

}
  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      bulkslide: "",
      bulkslide2: "",
      bulkslide3: "",
      bulkslide4:'',
      bulkslidebulk:''
    });
  }

  handleClickOutside = (event) => {
    if (!event.target.closest(".slide-r")) {
      this.onClickHideAll();
    }
  };

  changeFileHandler = async (e) => {
    var dt = '';
    const config = {
        headers: [
            {
                name: 'LSP Code',
                inputName: 'lsp_code',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            {
              name: 'LSP Name',
              inputName: 'lsp_name',
              required: true,
              requiredError: function (headerName, rowNumber, columnNumber) {
                  return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
              }
          },
          {
            name: 'Regn Number',
            inputName: 'regn_number',
            required: true,
            requiredError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            }
        },
        {
          name: 'Mode',
          inputName: 'mode',
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
          }
      },
         
        ]
    }
  
    var data = CSVFileValidator(e.target.files[0], config)
        .then((csvData) => {
            let csvList = csvData.data;
            var flag = 0;
         
            if (flag == 0) {
                    this.setState({
                        bulkMarkData: csvList
                    });
            }
  
        })
        .catch(err => {
            console.log(err, "error")
        })
    // console.log("e.target.files[0]",e.target.result);
    if (e.target.files[0] != undefined) {
        var out = new Promise(function (reject, resolve) {
            var reader = new FileReader();
            reader.onload = async function (e) {
                var contents = await e.target.result;
                resolve(contents);
            };
            var tt = reader.readAsText(e.target.files[0]);
        });
        this.setState({
            file: e.target.files[0]
        });
        // console.log("e.target.files[0].type ", e.target.files[0]);
  
        if (e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
            // console.log(fileData);
            // console.log(typeof(fileData))
  
        }
        else {
            e.target.value = null;
            this.setState({
                uploadFile: '',
                show: true,
                basicType: 'danger',
                basicTitle: 'Please upload file having extensions .csv only.',
            });
        }
    }
  
  }

resetUpload = () => {
  this.setState({
    bulkslidebulk: '',
      overly: 'show-n',
      file: '',
      bulkMarkData: [],
      bulkslide: "",
  })
  $('#upform')[0].reset();
  document.getElementById("upform").reset();
}




// onCellClicked(e) {
//   if (e.colDef.field === "Edit") {
//   console.log(e.data,'dataonclicked')
//   var data = e.data;
//     this.setState({
//       rowId : data._id,
//       dispatch_time_from_hub:data.dispatch_time_from_hub,
//       vehicle_type:data.vehicle_type,
//       vehicle_no:data.vehicle_no,
//       reporting_time_at_consignees:data.reporting_time_at_consignees,
//       final_reporting_time:data.final_reporting_time,
//       delivery_time:data.delivery_time,
//       final_delivery_time:data.final_delivery_time,
//       final_pod:data.final_pod,
//       // bulkslide: "slider-translate-30p",
   
//     });
//   }
// }



  render() {
    const batchwisecolumns = [
        // {
        //   pinned: "left",
        //   headerName: "",
        //   field: "Add",
        //   width: 120,
        //   resizable: true,
        //   editable: false,
        //   cellRenderer: function (params) {
        //     var rendComponent =
        //       '<button class="btn btn-arms" id="buttonStyling" style="background-color: #00c292; color: white;">ADD</button>';
        //     return rendComponent;
        //   },
        //   filter: false,
        // },
        
       
  
        {
          headerName: 'S.No',
          field: 'sno',
          width: 100,
          cellRenderer: function(params) {
            // Calculate the S.No based on the row index
            return params.node.rowIndex + 1;
          }
        },
        {
          headerName:  'LSP Code',
          field: 'lsp_code',
         with:150,  
        },
        {
          headerName:  'LSP Name',
          field: 'lsp_name',
         with:150,  
        },
        {
          headerName:  'Regn Number',
          field: 'regn_number',
         with:150,  
        },
        {
          headerName:'Mode',
          field: 'mode',
         with:150,
        },
        {
          headerName: 'Tyre',
          field: 'tyre_value',
         
        },
        {
          headerName: 'Dome',
          field: 'dome_value',
         
        },
        {
            headerName: 'Ramp at L1',
            field: 'rampL1_value',
           
          },
          {
            headerName: 'Ramp at L2',
            field: 'rampL2_value',
           
          },
          {
            headerName: 'Ramp at L3',
            field: 'rampL3_value',
           
          },
          {
            headerName: 'Ramp at U3',
            field: 'rampU3_value',
           
          },
        {
          headerName:  'Ramp at Adjustment',
          field: 'ramp_adjust',
          
        },
        {
          headerName:  'Any other Addtional Ramp',
          field: 'addtional_ramp',
          width:200,
          
         
        },
       
      ];

    return (
      <div class="container-fluid">
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
           <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                        </div>
                        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                        <div className={"dataLoadpage " + (this.state.loadshow)}></div>
                    <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                        <div className="loader-box">
                            <div className="loader-box">
                                <div className="rotate dashed colored">
                                </div>
                            </div>
                        </div>
                    </div>
        <div className="card col-xl-12">
          <div className="card-header">
            <h5>
            <i className="icofont icofont-vehicle-delivery-van cus-i"></i>{" "}
              <span>LSP Carrier Data Admin</span>
              </h5>
              <button className="btn btn-info" style={{ float: "right", marginRight: "10px" }} onClick={this.showBulkUpload.bind(this)}>Bulk Upload</button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div style={{display:'flex'}}>    <div className="form-group col-xl-4 col-lg-4">
                        <label>LSP Code: </label>
                        <Select 
                            // placeholder={"All"} 
                            closeMenuOnSelect={true} 
                            value={this.state.lspCode} 
                            isMulti="true" 
                            className="border-radius-0" 
                            onChange={this.changeConsignerArrayHandler.bind(this)} 
                            style={{borderRadius:"0px",width:'150px'}} 
                            options={this.selectConsignerOptionsItems()} 
                            required 
                        />
                                    
                    </div>
                    <div className="form-group col-xl-2 col-lg-2">
                        <label>&nbsp;</label><br />
                        <button type="button" className="btn btn-success"
                         onClick={this.onSubmitFilter.bind(this)}
                         >Submit</button>
                    </div> </div>
              <div className="card-body">
                <div className="row">
                  <div
                    id="myGrid"
                    style={{ width: "100%", height: "478px" }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      modules={this.state.modules}
                      columnDefs={batchwisecolumns}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.rowdata}
                      enableCharts={false}
                      onGridReady={this.onGridReady}
                      onGridState={this.onGridState}
                      frameworkComponents={this.state.frameworkComponents}
                      rowClassRules={this.state.rowClassRules}
                      sideBar={this.state.sideBar}
                      stopEditingWhenGridLosesFocus={true}
                      // onCellClicked={this.onCellClicked.bind(this)}
                      paginationPageSize={this.state.paginationPageSize}
                      pagination={true}
                      gridOptions={{
                        context: { componentParent: this },
                      }}
                      // onCellClicked={this.onCellRowClicked.bind(this)}
                      enableRangeSelection={true}
                    />
                  </div>
                </div>
              </div>
           
          </div>

          {/* work in progress Fresh Incoming rakes */}
        
        </div>
    

        <div className={"slide-r " + (this.state.bulkslidebulk)} style={{ overflow: "hidden" }}>
                            <h3 className="subH">Bulk Upload</h3>
                            <div className="slide-r-body" style={{ position: "relative" }}>
                                <div className="container-fluid">
                                    <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>

                                        <div className="form-group mt-20p">
                                            <label className="">Upload File</label>
                                            <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler} className="form-control" required />
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-success">Submit</button>
                                            <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                        </div>
                                        <p style={{ color: "red" }}>Note: Format should be same as given in sample template***</p>
                                    </form>
                                    <div className="form-group">
                                        <a className="btn btn-primary" href={require('../../assets/json/lspcarrierdata.csv')} target="_blank">Sample Template</a>
                                    </div>
                                </div>
                            </div>
                        </div>

       
      </div>
    );
  }
}


function DateEditor() {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
  // create the cell
  console.log(params, "params");
  this.eInput = document.createElement("input");
  this.eInput.value = params.value;

  // https://jqueryui.com/datepicker/
  $(this.eInput).datetimepicker({
    dateFormat: "yy-mm-dd",
    timepicker: false,
    changeMonth: true,
    changeYear: true,
  });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
  return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
  this.eInput.focus();
  this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
  return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
  // but this example is simple, no cleanup, we could
  // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
  // and we could leave this method out also, false is the default
  return false;
};

function loadDateTimeScript() {
  // alert("timeDate");
  $(".datetimepicker_datew").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y H:i",
    minDate: true,
    timepicker: true,
    closeOnDateSelect: true,
    scrollMonth: false,
    scrollInput: false,
  });
  $(".datetimepicker_dateo").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y",
    minDate: true,
    timepicker: false,
    closeOnDateSelect: true,
    scrollMonth: false,
    scrollInput: false,
  });
  $(".cargo_date").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y",
    minDate: true,
    timepicker: false,
  });
}
function formatDate(dateString) {
  if(dateString != "Invalid Date" && dateString != "" && dateString != "NaN-NaN-NaN" && dateString != undefined && dateString != 'undefined' ){
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
  }
  else {
    return ""
  }
}
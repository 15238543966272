import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDDHHMMSS, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Select from 'react-select';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import ArrivalMap from "./arrivalMap";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import DrawMap from "../common/drawmap";
import TransitAnalyticsMap from "./transitAnalyticsMap";
import TransitAnalyticsColumnChart from "./transitAnalyticsColumnChart";
// import { input } from '../../../../api/routes/logger';
var googleAnalytics = require("../common/googleanalytics");
var redirectURL = require('../redirectURL');
 
var moment = require('moment');

export default class ReturnTripsDashboardChart extends Component {

    constructor(props) {
        super(props);
        this.state={
            startDate:'',
            endDate:"",
            modules:AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                width: 130
            },
            rowData: [],
            paginationPageSize:50,
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            overly : "show-m",
            loadshow : "show-m",
            rowData : [],
            plantRowData : [],
            lat : "",
            lng : "",
            breaksSlider:"",
            selectedRow : "",
            dept_code : "",
            show: false,
            basicType:"",
            basicTitle:"",
            sliderRouteTranslate: "",
            rownode: "",
            mapinfo:"",
            selectedRoutes : [{label: "ALL",value:"ALL"}],
            routeOptions:[],
            plantOptions:[],
            transporterOptions: [],
            selectedPlants: [{label: "ALL",value:"ALL"}],
            selectedTransporters: [{label: "ALL",value:"ALL"}],
            loadingTimeChartData: [],
            unLoadingTimeChartData: [],
            tripDurationChartData: [],
            tatChartData: [],
            avg_break_duration_data: [],
            loadingGrid: "show-n",
            loadingChart: "show-m",
            unloadingChart: "show-m",
            unloadingGrid: "show-n",
            gridQuery: {},
            loadingChartGrid: [],
            unloadingChartGrid: [],
            firstCall : 0,
            showFilters: "show-unloading-dashboard",
            breakRowData: []
        }
        this.formatChartData = this.formatChartData.bind(this);
        this.formatRouteWiseBreakDuration = this.formatRouteWiseBreakDuration.bind(this);
    }
    closeAlert = async () => {
        await this.setState({
            show: false
        });
        // await window.location.reload();
    }
    componentDidMount(){
        
        loadDateTimeScript();
        var SND_PLANT_NAMES = {"B": "TVP Bangalore", "F": "Gurgaon", "G": "Gujarat", "I": "Siliguri",
        "M": "Manesar", "N": "TVP Nagpur", "D": "Dobaspet"}
        var to_date = moment.parseZone().format("YYYY-MM-DD")+" 23:59:59";
        var from_date = moment.parseZone().subtract(3, 'months').format("YYYY-MM-DD")+" 00:00:00";
        // var from_date = "2020-05-01 00:00:00";
        $(".startDate").val(moment.parseZone().subtract(3, 'months').format("DD-MM-YYYY"))
        $(".endDate").val(moment.parseZone().format("DD-MM-YYYY"))
        var query = {"from_date": from_date,"to_date": to_date}
        this.setState({
            gridQuery: query
        })
        redirectURL.post("/dashboard/transitAnalyticsData",query).then((response)=>{ 
            var routeOptions = [{"label": "ALL", value: "ALL"}]
            response.data['routeOptions'].map(function(e){
                routeOptions.push({"label": e, value: e})
            });

            var transporterOptions = [{"label": "ALL", value: "ALL"}]
            response.data['transporterOptions'].map(function(e){
                transporterOptions.push({"label": e, value: e})
            });

            var plantOptions = [{"label": "ALL", value: "ALL"}]
            response.data['plantOptions'].map(function(e){
                plantOptions.push({"label": SND_PLANT_NAMES[e], value: e})
            });
            let alt = response.data['API'].avg_loading_time;
            let apld = response.data['API'].avg_plant_loading_duration;
            let alld = response.data['API'].avg_lsp_loading_duration;
            let aut = response.data['API'].avg_unloading_time;
            let apud = response.data['API'].avg_plant_unloading_duration;
            let alud = response.data['API'].avg_lsp_unloading_duration;
            let atd = response.data['API'].avg_trip_duration;
            let aptd = response.data['API'].avg_plant_trip_duration;
            let altd = response.data['API'].avg_lsp_trip_duration;
            let atat = response.data['API'].avg_turn_around_time;
            let aptatd = response.data['API'].avg_plant_tat_duration;
            let altatd = response.data['API'].avg_lsp_tat_duration;
            let abd = response.data['API'].avg_break_duration;
            if (alt !== undefined && alt !== "" && apld !== undefined && apld !== "" && alld !== undefined && alld!== ""){
                var loadingTimeChartData = this.formatChartData(JSON.parse(alt),
                                                                JSON.parse(apld),
                                                                JSON.parse(alld));
            }else{
                 loadingTimeChartData = []
            }
            if(aut !== undefined && aut !== "" && apud !== undefined && apud !== ""  && alud !== undefined && alud !== ""){
                var unLoadingTimeChartData = this.formatChartData(JSON.parse(aut),
                                                                JSON.parse(apud),
                                                                JSON.parse(alud));
            }
            else{
                 unLoadingTimeChartData = []
            }
            if(atd !== undefined && atd !== "" && aptd !== undefined && aptd !== "" && altd !== undefined && altd !== ""){
                var tripDurationChartData = this.formatChartData(JSON.parse(atd),
                                                                JSON.parse(aptd),
                                                                JSON.parse(altd));
            }else{
                 tripDurationChartData = []
            }
            if (atat !== undefined && atat !== "" && aptatd !== undefined && aptatd !== "" && altatd !== undefined && altatd !== ""){
                var tatChartData = this.formatChartData(JSON.parse(response.data['API'].avg_turn_around_time),
                                                                JSON.parse(response.data['API'].avg_plant_tat_duration),
                                                                JSON.parse(response.data['API'].avg_lsp_tat_duration));
            }else{
                 tatChartData = []
            }
            if (abd !== undefined && abd !== ""){
                var avg_break_duration = this.formatRouteWiseBreakDuration(JSON.parse(response.data['API'].avg_break_duration))
            }else{
                avg_break_duration = []
            }
            this.setState({
                overly: "show-n",
                loadshow: "show-n",
                routeOptions: routeOptions,
                plantOptions: plantOptions,
                transporterOptions: transporterOptions,
                loadingTimeChartData: loadingTimeChartData,
                unLoadingTimeChartData: unLoadingTimeChartData,
                tripDurationChartData: tripDurationChartData,
                tatChartData: tatChartData,
                avg_break_duration_data: avg_break_duration
            });
        }).catch((e)=>{
            console.log(e)
        });
        
    }
    formatChartData(chartdata, plant_chart, lsp_chart){
        var categories = [];
        var seriesData = [];
        var drilldown = [];
        var consigners = [];
        chartdata.map(function(e){
            categories.push(e['route_id']);
            var keys = Object.keys(e);
            seriesData.push({
                name: e['route_id'],
                y: e[keys[1]],
                drilldown: e.route_id,
                hour_format: e['hour_format']
            });
            var firstDrillDownData = [];

            plant_chart.map(function(k){
                if(k.route_id == e.route_id)
                {
                    firstDrillDownData.push({"name": k.consigner_code, "y": k['Avg. Duration'], "drilldown": k.consigner_code+"_"+k.route_id,
                                            "hour_format": k.hour_format})
                    var secondDrillDownData = [];
                    lsp_chart.map(function(l){
                        if(l.route_id == e.route_id && l.consigner_code == k.consigner_code)
                        {
                            secondDrillDownData.push({"name": l.transporter_name, "y": l['Avg. Duration'], hour_format: l['hour_format']})
                        }
                    })
                    if(secondDrillDownData.length > 0)
                    {
                        secondDrillDownData = secondDrillDownData.sort( (a, b) => {
                            return b["y"] - a["y"]
                        })
                        drilldown.push({"id": k.consigner_code+"_"+k.route_id, "data": secondDrillDownData })
                    }
                }
                
            })
            if(firstDrillDownData.length > 0)
            {
                firstDrillDownData = firstDrillDownData.sort( (a, b) => {
                    return b["y"] - a["y"]
                })
                drilldown.push({"id": e.route_id, "data": firstDrillDownData })
            }
        });
        console.log(drilldown, "drilldown")
        seriesData = seriesData.sort( (a, b) => {
            return b["y"] - a["y"]
        })
        return [{"categories": categories, "series": seriesData, "drilldown": drilldown}];
    }
    formatRouteWiseBreakDuration(chartdata){
        var categories = [];
        var seriesData = [];
        var drilldown =[];
        chartdata.map(function(e){
            categories.push(e.route_id);
            seriesData.push({name: e.route_id, y: e['Avg. Break Time'], drilldown: e.route_id,  hour_format: e['hour_format']});
            drilldown.push({
                id: e.route_id,
                data: [{name: "Near State Border",y: e['Avg. State Border Time'], hour_format: e['state_hour_format']},
                        {name: "Near Tolls",y: e['Avg. Toll Time'], hour_format: e['toll_hour_format']}]
            })
        })
        return [{"categories": categories, "series": seriesData, "drilldown": drilldown}]
    }
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
    }

    
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
            overly:'show-n',
            breaksSlider : "",
            selectedRow : [],
            sliderRouteTranslate: ""
		});
		
    }
    onCloseRouteDiv(){
        this.setState({
            overly:'show-n',
            sliderRouteTranslate: ""
		});
    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
        var mm = (d.getMonth() +1)
        if(mm <10)
        {
            var month = "0"+mm
        }
        else{
            var month = mm
        }
         
    	var startdate = d.getFullYear()+"-"+month+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
        var mm = (d.getMonth() +1)
        if(mm <10)
        {
            var month = "0"+mm
        }
        else{
            var month = mm
        }
         
    	var edate = d.getFullYear()+"-"+month+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
        this.setState({
    		endDate:edate
    	});
    }
    async submitForm(e){
        e.preventDefault();
        if($(".startDate").val() != "" && 
            $(".endDate").val() != "")
        {
            this.setState({
                overly: "show-m",
                loadshow: "show-m",
                loadingTimeChartData: [],
                unLoadingTimeChartData: [],
                tripDurationChartData: [],
                tatChartData: [],
                avg_break_duration_data: []
            });

            var fromdate = $(".startDate").val();
            var todate = $(".endDate").val();

            fromdate = fromdate.split("-")[2]+"-"+fromdate.split("-")[1]+"-"+fromdate.split("-")[0]
            todate = todate.split("-")[2]+"-"+todate.split("-")[1]+"-"+todate.split("-")[0]

            var query = {"from_date": fromdate+" 00:00:00",
                        "to_date": todate+" 23:59:59"}
            var routes = []
            if(this.state.selectedRoutes != undefined)
            {
                var checkAllCompetitors = this.state.selectedRoutes.filter(function(e){
                    return e.value == "ALL"
                })
            }
            
            if(this.state.selectedRoutes != null && 
                this.state.selectedRoutes != undefined && 
                checkAllCompetitors.length==0)
            {
                this.state.selectedRoutes.map(function(e){
                    if(e.value != "ALL")
                    {
                        routes.push(e.value)
                    }
                })
            }
            var plants = []
            if(this.state.selectedPlants != undefined)
            {
                var checkAllPlants = this.state.selectedPlants.filter(function(e){
                    return e.value == "ALL"
                })
            }
            
            if(this.state.selectedPlants != null && 
                this.state.selectedPlants != undefined && 
                checkAllPlants.length==0)
            {
                this.state.selectedPlants.map(function(e){
                    if(e.value != "ALL")
                    {
                        plants.push(e.value)
                    }
                })
            }
            var transporters = []
            if(this.state.selectedTransporters != undefined)
            {
                var checkAllTransporters = this.state.selectedTransporters.filter(function(e){
                    return e.value == "ALL"
                })
            }
            
            if(this.state.selectedTransporters != null && 
                this.state.selectedTransporters != undefined && 
                checkAllTransporters.length==0)
            {
                this.state.selectedTransporters.map(function(e){
                    if(e.value != "ALL")
                    {
                        transporters.push(e.value)
                    }
                })
            }
           
            if(routes.length){query['routes']=JSON.stringify(routes)};
            if(transporters.length){query['transporters']=JSON.stringify(transporters)};
            if(plants.length){query['plants']=JSON.stringify(plants)};
            this.setState({
                gridQuery: query,
            })
            redirectURL.post("/dashboard/transitAnalyticsData",query).then((response)=>{ 
                var routeOptions = [{"label": "ALL", value: "ALL"}]
                response.data['routeOptions'].map(function(e){
                    routeOptions.push({"label": e, value: e})
                });
                var loadingTimeChartData = this.formatChartData(JSON.parse(response.data['API'].avg_loading_time),
                                                            JSON.parse(response.data['API'].avg_plant_loading_duration),
                                                            JSON.parse(response.data['API'].avg_lsp_loading_duration));
                console.log("Loading Time", loadingTimeChartData);
                var unLoadingTimeChartData = this.formatChartData(JSON.parse(response.data['API'].avg_unloading_time),
                                                                JSON.parse(response.data['API'].avg_plant_unloading_duration),
                                                                JSON.parse(response.data['API'].avg_lsp_unloading_duration));
                console.log("unloading Time", unLoadingTimeChartData);
                var tripDurationChartData = this.formatChartData(JSON.parse(response.data['API'].avg_trip_duration),
                                                                JSON.parse(response.data['API'].avg_plant_trip_duration),
                                                                JSON.parse(response.data['API'].avg_lsp_trip_duration));
                console.log("Trip Duration", tripDurationChartData);
                var tatChartData = this.formatChartData(JSON.parse(response.data['API'].avg_turn_around_time),
                                                                JSON.parse(response.data['API'].avg_plant_tat_duration),
                                                                JSON.parse(response.data['API'].avg_lsp_tat_duration));
                console.log("TAT Chart", tatChartData);
                var avg_break_duration = this.formatRouteWiseBreakDuration(JSON.parse(response.data['API'].avg_break_duration))
                console.log(avg_break_duration, "avg_break_duration")
                this.setState({
                    overly: "show-n",
                    loadshow: "show-n",
                    loadingTimeChartData: loadingTimeChartData,
                    unLoadingTimeChartData: unLoadingTimeChartData,
                    tripDurationChartData: tripDurationChartData,
                    tatChartData: tatChartData,
                    avg_break_duration_data: avg_break_duration
                });
            }).catch((e)=>{
                console.log(e)
            });
            
        }
        
    }
    displayLoadingData(statevar, statevar1, gridvar){
        
        this.setState({[statevar]: "show-m", [statevar1]: "show-n"})
        console.log(statevar, statevar.includes("Grid"))
        if(statevar.includes("Grid"))
        {
            this.setState({
                showFilters: "show-n"
            })
            var fromdate = $(".startDate").val();
            var todate = $(".endDate").val();

            fromdate = fromdate.split("-")[2]+"-"+fromdate.split("-")[1]+"-"+fromdate.split("-")[0]
            todate = todate.split("-")[2]+"-"+todate.split("-")[1]+"-"+todate.split("-")[0]

            var query = {"from_date": fromdate+" 00:00:00",
                        "to_date": todate+" 23:59:59"}
            var routes = []
            if(this.state.selectedRoutes != undefined)
            {
                var checkAllCompetitors = this.state.selectedRoutes.filter(function(e){
                    return e.value == "ALL"
                })
            }
            
            if(this.state.selectedRoutes != null && 
                this.state.selectedRoutes != undefined && 
                checkAllCompetitors.length==0)
            {
                this.state.selectedRoutes.map(function(e){
                    if(e.value != "ALL")
                    {
                        routes.push(e.value)
                    }
                })
            }
            var plants = []
            if(this.state.selectedPlants != undefined)
            {
                var checkAllPlants = this.state.selectedPlants.filter(function(e){
                    return e.value == "ALL"
                })
            }
            
            if(this.state.selectedPlants != null && 
                this.state.selectedPlants != undefined && 
                checkAllPlants.length==0)
            {
                this.state.selectedPlants.map(function(e){
                    if(e.value != "ALL")
                    {
                        plants.push(e.value)
                    }
                })
            }
            var transporters = []
            if(this.state.selectedTransporters != undefined)
            {
                var checkAllTransporters = this.state.selectedTransporters.filter(function(e){
                    return e.value == "ALL"
                })
            }
            
            if(this.state.selectedTransporters != null && 
                this.state.selectedTransporters != undefined && 
                checkAllTransporters.length==0)
            {
                this.state.selectedTransporters.map(function(e){
                    if(e.value != "ALL")
                    {
                        transporters.push(e.value)
                    }
                })
            }
            
            if(routes.length){query['routes']=JSON.stringify(routes)};
            if(transporters.length){query['transporters']=JSON.stringify(transporters)};
            if(plants.length){query['plants']=JSON.stringify(plants)};

            var flag = 0;
            if(this.state.gridQuery.length == query.length)
            {
                for(var key in query)
                {
                    if(query[key] != this.state.gridQuery[key])
                    {
                        flag = 1;
                    }
                }
            }
            else
            {
                flag = 1;
            }
            if(statevar.includes("loading")){
                query['dataType'] = 1
            }
            else if(statevar.includes("unloading"))
            {
                query['dataType'] = 2
            }
            else if(statevar.includes("trip"))
            {
                query['dataType'] = 3
            }
            else if(statevar.includes("turn"))
            {
                query['dataType'] = 4
            }
            if(flag == 1 || this.state.firstCall == 0)
            {   
                this.setState({
                    loadshow: "show-m",
                    overly: "show-m"
                })
                redirectURL.post("/dashboard/getTransitAnalyticsGrid",query).then((response)=>{
                    console.log(response.data)
                    this.setState({
                        "rowData" : response.data,
                        loadingChartGrid: response.data,
                        firstCall: 1,
                        loadshow: "show-n",
                        overly: "show-n"
                    })
                })      
            }
        }
        else
        {
            this.setState({
                showFilters: "show-unloading-dashboard"
            })
        }
        
    }
    onCellClicked(row){
        console.log(row)
        if(row.colDef.field == "map")
        {
            this.setState({
                overly: "show-m",
                loadshow: "show-m"
            })
            var query = {
                truck_no: row.data.truck_no,
                gate_out_time: getHyphenYYYYMMDDHHMMSS(row.data.prev_gate_out_time),
                seconddate: getHyphenYYYYMMDDHHMMSS(row.data.return_start_time)
            }
            redirectURL.post('/consignments/maproutes',query, {
                headers:{
                    'content-type':'application/json'
                }
            })
            .then((response) => {
                
                var records = response.data;
                this.setState({
                    mapinfo: records,
                    sliderRouteTranslate: "slider-translate-90p",
                    breakRowData: row.data.breaks,
                    overly: "show-n",
                    loadshow: "show-n"
                })
            })
        }
        
    }

    
    render(){
        const gridColumns = [
            {
                headerName: "",
                field: "map",
                width: 70,
                cellRenderer: function(params){
                    return '<i class="icofont icofont-map-pins f25"></i>'
                }
            },
            {
                headerName: "Truck No",
                field : "truck_no",
            },
            {
                headerName: "Transporter Code",
                field : "transporter_code",
            },
            {
                headerName: "Transporter Name",
                field : "transporter_name",
            },
            {
                headerName: "Consignment Codes",
                field : "consignment_codes",
            },
            {
                headerName: "Gate Out Time",
                field : "prev_gate_out_time",
                valueGetter: function(params){
                    try{
                        return getHyphenDDMMMYYYYHHMM(params.data.prev_gate_out_time)
                    }catch(e){}
                },

            },
            {
                headerName: "Trip End Time",
                field : "return_start_time",
                valueGetter: function(params){
                    try{
                        return getHyphenDDMMMYYYYHHMM(params.data.return_start_time)
                    }catch(e){}
                }
            },
            {
                headerName: "Loading Time (Hrs)",
                field : "loading_delay_in_hrs",
                valueGetter: function(e){
                    return secondsToString(Math.round(e.data.loading_delay_in_hrs*3600))
                }
            },
            {
                headerName: "Trip Duration (Hrs)",
                field: "trip_duration_in_secs",
                valueGetter: function(e){
                    return secondsToString(Math.round(e.data.trip_duration_in_secs))
                }
            },
            {
                headerName: "Unloading Time (Hrs)",
                field : "unloading_time_in_hrs",
                valueGetter: function(e){
                    return secondsToString(Math.round(e.data.unloading_time_in_hrs*3600))
                }
            },
            {
                headerName: "Turn Around Time (days)",
                field : "turn_around_time_in_secs",
                valueGetter: function(e){
                    return secondsToString(Math.round(e.data.turn_around_time_in_secs))
                }
            },
            {
                headerName: "Cluster",
                field : "cluster",
            },
            {
                headerName: "Route ID",
                field : "route_id",
            },
            
            // {
            //     headerName: "Transshipment",
            //     field : "is_transshipment",
            // },
            
            {
                headerName: "Next Trip Consignments",
                field : "next_trip_consignment_codes",
            },
            {
                headerName: "Next Trip Consigner Codes",
                field : "next_trip_consigner_code",
            },
            {
                headerName: "Next Trip Cluster",
                field : "next_trip_cluster",
            },
            {
                headerName: "Next Trip Route ID",
                field : "next_trip_route_id",
            },
        ]
        const breakcolumns = [
            {
                headerName: "Break Start",
                field : "break_start",
                valueGetter: function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.break_start)
                }
            },
            {
                headerName: "Break End",
                field : "break_end",
                valueGetter: function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.break_end)
                }
            },
            {
                headerName: "Break Time (Hrs)",
                field : "break_time_hrs",
            },
            {
                headerName: "Area / State",
                field : "",
                valueGetter: function(params){
                    var address=""
                    if(params.data.area != null && params.data.area != undefined){
                        address = params.data.area
                    }
                    if(params.data.state != null && params.data.state != undefined){
                        address = address + "/"+ params.data.state
                    }
                    return address
                }
            },
            {
                headerName: "Near State Border",
                field : "near_state_border",
                valueGetter: function(params){
                    if(params.data.near_state_border == 1){
                        return "Yes";
                    }
                    else{
                        return "No";
                    }
                }
            },
            {
                headerName: "State Border Name",
                field : "state_border_name"
            },
            {
                headerName: "Near Toll",
                field : "near_toll",
                valueGetter: function(params){
                    if(params.data.near_toll == 1){
                        return "Yes";
                    }
                    else{
                        return "No";
                    }
                }
            },
            {
                headerName: "Toll Name",
                field : "toll_name"
            },
        ]
        return ( 
        		<div className="col-xl-12 col-lg-12">
                    <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                    >
                    </SweetAlert>
                    <div className="col-xl-12 col-lg-12">		            	
                            <h5>
                                 <i className="icofont icofont-dashboard-web cus-i"></i> <span>Transit Analytics Dashboard</span>
                            
                            </h5>                    
                    </div>
                        
                    <form className={"row col-xl-12 col-lg-12 "+this.state.showFilters} onSubmit={this.submitForm.bind(this)}>
                        <div className="form-group col-xl-2 col-lg-2" style={{padding: "0"}}>
                            <label>Start Date: </label>
                            <input type="text" autoComplete="off"  className="col-xl-12 col-lg-12 form-control startDate datetimepicker_date col-xl-7" id="" style={{height:"41px"}} />
                        </div>
                        <div className="form-group col-xl-2 col-lg-2" style={{padding: "0",marginLeft:"1em"}}>
                            <label>End Date: </label>
                            <input type="text" autoComplete="off" className="col-xl-12 col-lg-12 form-control endDate datetimepicker_date col-xl-7" id="" style={{height:"41px"}} />
                        </div>
                        <div className="col-xl-2 col-lg-2">
                            <label>Select Routes : </label>
                            <Select placeholder={"All"} 
                            isMulti={true} 
                            value={this.state.selectedRoutes} 
                            className="border-radius-0" 
                            onChange={(e)=>{this.setState({selectedRoutes: e})}} 
                            style={{borderRadius:"0px"}} 
                            options={this.state.routeOptions} 
                            required />
                        </div>
                        <div className="col-xl-2 col-lg-2">
                            <label>Select Plants : </label>
                            <Select placeholder={"All"} 
                            isMulti={true} 
                            value={this.state.selectedPlants} 
                            className="border-radius-0" 
                            onChange={(e)=>{this.setState({selectedPlants: e})}} 
                            style={{borderRadius:"0px"}} 
                            options={this.state.plantOptions} 
                            required />
                        </div>
                        <div className="col-xl-2 col-lg-2">
                            <label>Select Transporters : </label>
                            <Select placeholder={"All"} 
                            isMulti={true} 
                            value={this.state.selectedTransporters} 
                            className="border-radius-0" 
                            onChange={(e)=>{this.setState({selectedTransporters: e})}} 
                            style={{borderRadius:"0px"}} 
                            options={this.state.transporterOptions} 
                            required />
                        </div>
                        
                        <div className="form-group col-xl-1 col-lg-1" style={{paddingTop:"10px"}}>
                            <br />
                            <button type="submit" className="btn btn-success pt-10p">Submit</button>
                        </div>
                    </form>
                    <button className="btn btn-primary" onClick={this.displayLoadingData.bind(this, "loadingChart", "loadingGrid","loadingChartGrid")}>Chart Data</button>
                    <button className="btn btn-success" onClick={this.displayLoadingData.bind(this, "loadingGrid", "loadingChart","loadingChartGrid")}>Grid Data</button>
                    <div className ={"card col-xl-12 col-lg-12 "}>
                        <div className={"card-body "+this.state.loadingChart}>
                            <div className={"row mt-40p "}>
                                {this.state.loadingTimeChartData.map((chart)=>{
                                    return <div className="col-xl-12">
                                                {(chart.drilldown.length > 0)?
                                                    <div>
                                                        <TransitAnalyticsColumnChart
                                                        title={"Route Wise Avg. Loading Time (Hours)"}
                                                        subtitle={""}
                                                        context={this}
                                                        series={[
                                                            {
                                                                name: this.props.seriesName,
                                                                colorByPoint: true,
                                                                data: chart.series
                                                            }
                                                        ]}
                                                        categories={chart.categories}
                                                        seriesName=""
                                                        yaxistitle="Avg. loading Time (Hrs)"
                                                        drilldownseries={{"series": chart.drilldown}}
                                                        // dept_code={this.state.dept_code}
                                                        />
                                                    </div>
                                                :
                                                    
                                                    <span>
                                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                        <h5>Route Wise Avg. Loading Time.</h5>
                                                        <span style={{position:"absolute",top:"30%",left:"43%"}}>No Data</span></div>
                                                    </span>
                                        
                                                }
                                                
                                            </div>
                                })}
                            
                            </div>
                            
                            <div className={"row mt-40p "}>
                                {this.state.unLoadingTimeChartData.map((chart)=>{
                                    return  <div className="col-xl-12">
                                                {(chart.drilldown.length > 0)?
                                                    <div>
                                                        <TransitAnalyticsColumnChart
                                                        title={"Route Wise Avg. Unloading Time  (Hours)"}
                                                        subtitle={""}
                                                        context={this}
                                                        series={[
                                                            {
                                                                name: this.props.seriesName,
                                                                colorByPoint: true,
                                                                data: chart.series
                                                            }
                                                        ]}
                                                        categories={chart.categories}
                                                        seriesName=""
                                                        yaxistitle="Avg. Unloading Time (Hrs)"
                                                        drilldownseries={{"series": chart.drilldown}}
                                                        // dept_code={this.state.dept_code}
                                                        />
                                                    </div>
                                                :
                                                    
                                                    <span>
                                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                        <h5>Route Wise Avg. Unloading Time.</h5>
                                                        <span style={{position:"absolute",top:"30%",left:"43%"}}>No Data</span></div>
                                                    </span>
                                        
                                                }
                                                
                                            </div>
                                })}
                            
                            </div>
                           
                            <div className={"row mt-40p"}>
                                {this.state.tripDurationChartData.map((chart)=>{
                                    return  <div className="col-xl-12">
                                                {(chart.drilldown.length > 0)?
                                                    <div>
                                                        <TransitAnalyticsColumnChart
                                                        title={"Route Wise Avg. Trip Duration Time  (Hours)"}
                                                        subtitle={""}
                                                        context={this}
                                                        series={[
                                                            {
                                                                name: this.props.seriesName,
                                                                colorByPoint: true,
                                                                data: chart.series
                                                            }
                                                        ]}
                                                        categories={chart.categories}
                                                        seriesName=""
                                                        yaxistitle="Avg. Trip Duration Time (Hrs)"
                                                        drilldownseries={{"series": chart.drilldown}}
                                                        // dept_code={this.state.dept_code}
                                                        />
                                                    </div>
                                                :
                                                    
                                                    <span>
                                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                        <h5>Route Wise Avg. Trip Duration Time.</h5>
                                                        <span style={{position:"absolute",top:"30%",left:"43%"}}>No Data</span></div>
                                                    </span>
                                        
                                                }
                                                
                                            </div>
                                })}
                            
                            </div>
                            <div className={"row mt-40p"}>
                                {this.state.tatChartData.map((chart)=>{
                                    return <div className="col-xl-12">
                                                {(chart.drilldown.length > 0)?
                                                    <div>
                                                        <TransitAnalyticsColumnChart
                                                        title={"Route Wise Avg. Turn Around Time (Days)"}
                                                        subtitle={""}
                                                        context={this}
                                                        series={[
                                                            {
                                                                name: this.props.seriesName,
                                                                colorByPoint: true,
                                                                data: chart.series
                                                            }
                                                        ]}
                                                        categories={chart.categories}
                                                        seriesName=""
                                                        showHourFormat={0}
                                                        yaxistitle="Avg. Turn Around Time (Days)"
                                                        drilldownseries={{"series": chart.drilldown}}
                                                        // dept_code={this.state.dept_code}
                                                        />
                                                    </div>
                                                :
                                                    
                                                    <span>
                                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                        <h5>Route Wise Avg. Turn Around Time.</h5>
                                                        <span style={{position:"absolute",top:"30%",left:"43%"}}>No Data</span></div>
                                                    </span>
                                        
                                                }
                                                
                                            </div>
                                })}
                            
                            </div>
                            {(this.state.avg_break_duration_data.length > 0)?

                                <div className={"row mt-40p"}>
                                    {this.state.avg_break_duration_data.map((chart)=>{
                                        return <div className="col-xl-12">
                                                    <div>
                                                        <TransitAnalyticsColumnChart
                                                        title={"Route Wise Avg. Break Duration  (Hours)"}
                                                        subtitle={""}
                                                        context={this}
                                                        series={[
                                                            {
                                                                name: this.props.seriesName,
                                                                colorByPoint: true,
                                                                data: chart.series
                                                            }
                                                        ]}
                                                        categories={chart.categories}
                                                        seriesName=""
                                                        yaxistitle="Avg. Break Duration (Hrs)"
                                                        drilldownseries={{"series": chart.drilldown}}
                                                        // dept_code={this.state.dept_code}
                                                        />
                                                    </div>
                                                </div>
                                    })}
                                
                                </div>
                                :
                                                        
                                <span>
                                    <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                    <h5>Route Wise Avg. Turn Around Time.</h5>
                                    <span style={{position:"absolute",top:"30%",left:"43%"}}>No Data</span></div>
                                </span>
                            }
                        </div>
                        <div className={"card-body "+this.state.loadingGrid}>
                            {/* <div className="col-xl-3 col-lg-3" style={{float: "left",width: "19em",marginLeft: "1em"}}>
                                <Select 
                                isMulti={false} 
                                // value={this.state.selectedGridData} 
                                placeholder="Complete Data"
                                className="border-radius-0" 
                                onChange={(e)=>{
                                    this.setState({
                                        // selectedGridData: e
                                        loadshow: "show-m",
                                        overly: "show-m"
                                    })
                                    var columnkey = ""
                                    if(e.value == "Avg. Loading Duration"){columnkey = "loading_delay_in_hrs"}
                                    if(e.value == "Avg. Unloading Duration"){columnkey = "unloading_time_in_hrs"}
                                    if(e.value == "Avg. Trip Duration"){columnkey = "trip_duration_in_secs"}
                                    if(e.value == "Avg. Turn Around Time"){columnkey = "turn_around_time_in_secs"}
                                    if(e.value != "Complete Data")
                                    {
                                        var filteredData = this.state.loadingChartGrid.filter(function(k){
                                            return k[columnkey] > 0
                                        });
                                    }else{var filteredData = this.state.rowData}
                                    
                                    this.setState({loadshow: "show-n", "overly": "show-n","rowData": filteredData })
                                }} 
                                style={{borderRadius:"0px"}} 
                                options={[{"label": "Complete Data", value: "Complete Data"},
                                {"label": "Avg. Loading Duration", value: "Avg. Loading Duration"},
                                {"label": "Avg. Unloading Duration", value: "Avg. Unloading Duration"},
                                {"label": "Avg. Trip Duration", value: "Avg. Trip Duration"},
                                {"label": "Avg. Turn Around Time", value: "Avg. Turn Around Time"}]} 
                                    />
                            </div> */}
                            <h5 style={{paddingTop:"0.3em"}}> &nbsp;Complete Data</h5>
                            <div className={"row mt-20p "} style={{marginLeft: "1em"}}>
                                <div className="col-xl-12 col-lg-12">
                                    <div id="myGrid" style={{width:"100%",height:"460px"}} className="ag-theme-balham">    
                                        <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={gridColumns}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        rowSelection={this.state.rowSelection}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        onCellClicked={this.onCellClicked.bind(this)}
                                        suppressRowClickSelection={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.sliderRouteTranslate != ''?
                        <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
                            <div className="slide-r-title">
                                <h4>
                                    Map Route
                                    <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
                                            
                                </h4>
                            </div>
                            <div className="slide-r-body" style={{position:"relative"}}>
                                {/* <div className="row col-xl-6 col-lg-6 ml-0p n-p-0"> */}
                                    <TransitAnalyticsMap 
                                        context={this}
                                        mapFor={"truck"}
                                        mapinfo={this.state.mapinfo}
                                        breakRowData={this.state.breakRowData}
                                        breakcolumns={breakcolumns}
                                    />  
                                {/* </div> */}
                                
                            </div>
                            
                        </div>
                    :""	
                    }
                                            
                    <div className={"dataLoadpage " +(this.state.loadshow)}></div>
                    <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                        <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                    </div>
                    <div className={"overlay "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
	           </div>
			);
		}
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}


function hrsFormat(hrvalue){
    var hrs = Number(hrvalue)
    var mins = Number((hrvalue - hrs)*60)

        if( mins <= 9){
            mins = "0" + (mins).toString()
        }

        if( mins == 0)
        {
            mins = "00"

        }

        return hrs.toString() + ":" + mins.toString()
}
function secondsToString(seconds)
{
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules, AllEnterpriseModules} from '@ag-grid-enterprise/all-modules';
// import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD,getHyphenDDMMMYYYYHHMM, getYYYYMMDDHHMMSS, getDDMMMYYYYHHMMDefault, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS } from '../common/utils'; 
import CSVFileValidator from 'csv-file-validator';
import Map from '../common/drawmap';
import FileBase64 from 'react-file-base64';
import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import MapComponent from './mapLocationComponent';
import Consignmentforceclose from '../manage/consignmentforceclose';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var childJson = []

export default class ContainerExportTracking extends Component{
        constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            csvcontent:"",
            file : "",
            loadshow:'show-n',
            overly:'show-n',
            uploadYard : "",
            uploadDispatchDate : "",
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            dept_code:"",
            bulkslide:"",
            benefitCert:"",
            date:"",
            deptName:"",
            indenterName:"",
            mobile:"",
            indenterEmail:"",
            erpPO:"",
            erpPoNo:[],
            erpPoDate:[],
            ringiNo:"",
            ringiDesc:"",
            item_desc:"",
            gross_weight:"",
            dimensions_of_package:"",
            gross_cargo_volume:"",
            size_of_container:"",
            no_of_containers:"",
            shipment_type : "",
            cargo_type : "",
            type_of_container : "",
            handling_type : "",
            type_of_special_handling : "",
            cargoDeliveryPlant:"",
            itemDesc:"",
            grossWt:"",
            dimensions:"",
            cargo_chargeable_weight:"",
            supplierName:"",
            supplierAddr:"",
            supplierCnctPerson:"",
            country:"",
            emailID:"",
            phone:"",
            deliveryTerms:"Ex Works",
            pickUpAddr:"",
            cargoDate:"",
            setDate:"",
            LC : "",
            LCno : "",
            nature : "",
            freeTrade : "",
            disableERP:"disabled",
            disableLC:"disabled",
            deliveryLabel : "Pick Up Address",
            airportName:"",
            port : "",
            erpPo_date :"",
            cargo_date:"",
            rows: [{}],
            ringirows: [{}],
            packetrows: [{}],
            brequest:"activet",
            prepaid:"",
            track:"",
            requestData:"show-n",
            trackData:"show-m",
            trackResData : 'show-n',
            ffsuppliers: [],
            requestFields:"show-n",
            prepaidFields:"show-n",
            addresstxt:"",
            fclFields:"show-n",
            lclFields:"show-n",
            request_type:1,
            pagetitle:"Booking Request (Sea Imports - FCL)",
            msds_file : "",
            shipment_reference_no : "",
            disableBng : "disabled"
            //components : {datepicker:getDatePicker()}
        }
        this.changeHandler = this.changeHandler.bind(this);
        this.packetsHandler = this.packetsHandler.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        this.logPageView();
        loadDateTimeScript();
        var pagetitle = "Booking Request (Sea Imports - FCL)";
        $(".requestFields").attr("required",true);
        this.setState({
            pagetitle : pagetitle,
        }) 
        $(".prepaidFields").removeAttr("required",true);
        $("#no_of_packets").val(1);
        var d = moment.parseZone(new Date()).utcOffset("+05:30")._d;
        var date = moment.parseZone(d).format("DD-MM-YYYY");
        $("#dateField").val(date);
        $("#addMrERp").hide();
        redirectURL.post("/consignments/aircargosuppliers").then((response)=>{
            var ffsuppliers = response.data.ffsuppliers;
            console.log(ffsuppliers,"ffsuppliers")
            this.setState({
                ffsuppliers : ffsuppliers,
            }) 
        }).catch((e)=>{
            console.log(e);
        })
    }

    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
    }
    
onClickHideAll(){
    this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:''
    });
    
}
closeAlert = () => {
    this.setState({
        show: false
    });
}

changeHandler = (event) => {
    console.log("Select", event.target.value);
    let name = event.target.name;
    console.log(name);
    let value = event.target.value;
    this.setState({[name]:value});
    if(name == "erpPO")
    {   
        if(value == "yes")
        {
            $("#addMrERp").show();
            this.setState({
                disableERP : ""
            })
        }else{
            $("#addMrERp").hide();
            this.setState({
                disableERP : "disabled"
            })
        }
    }
    if(name == "LC")
    {
        if(value == "yes")
        {
            this.setState({
                disableLC : ""
            })
            //$("#LCno").prop("required",true);
        }else{
            this.setState({
                disableLC : "disabled"
            })
            //$("#LCno").removeAttr("required",true);
        }
    }
    if(name == "cargoDeliveryPlant")
    {   
        if(value == "Bangalore")
        {
            $("#invoice_type").prop("required",true);
            $("#order_type").prop("required",true);
            $("#invoice_type").val("");
            $("#order_type").val("");
            this.setState({
                disableBng : ""
            })
        }else{
            $("#invoice_type").removeProp("required");
            $("#order_type").removeProp("required");
            this.setState({
                disableBng : "disabled"
            })
        }
    }
    // if(name == "deliveryTerms")
    // {
    //     $(".dterms").removeAttr("required",true);
    //     if(value == "Ex Works")
    //     {
    //         $("#pickUpAddr").prop("required",true);
    //     }
    //     else if(value == "FCA")
    //     {
    //         $("#port").prop("required",true);
    //     }
    //     else if(value == "FOB")
    //     {
    //         $("#airportName").prop("required",true);
    //     }
    // }
}
dateValidation = (currentDate) => {
    return currentDate.isBefore(moment(this.state.deliverymaxDate)) && currentDate.isAfter(moment(this.state.deliveryminDate));
};
changeFileHandler = async (e) => {
    console.log(e.target.name)
    this.setState({ [e.target.name]: e.target.files[0] });
}
formHandler(event){
    event.preventDefault();
    var eventTarget = event.target;
    //console.log(this.state.file,"this.state.file");
    var request_type = this.state.request_type;
    var dateField=$("#dateField").val();
    //console.log(dateField);
    if(dateField == "__-__-____")
    {
        dateField = "";
    }
    
    var erPoDate=$("#erpPoDate").val();
    if(erPoDate == "__-__-____")
    {
        erPoDate = "";
    }
    
    var setDate=$("#setDate").val();
    if(setDate == "__-__-____")
    {
        setDate = "";
    }
     
    var eta_igi_airport_date=$("#eta_igi_airport_date").val();

    var erpLength=$("#erpLength").val();
    var erpData=[];
    for(var i=0;i<erpLength;i++)
    {
        var erpno=$("#erpPoNo_"+i).val();
        var erpDate=$("#erpPoDate_"+i).val();
        if(erpno !='' || erpDate !='')
        {
            if(erpDate !=undefined)
            {
                erpDate = erpDate.split("-")[2]+"-"+erpDate.split("-")[1]+"-"+erpDate.split("-")[0];
                erpDate = moment.parseZone(new Date(erpDate+" 00:00:00")).utcOffset("+05:30")._d
            }
            var erptemp = {"erpPoNo":erpno,"erPoDate":erpDate}
            erpData.push(erptemp)
        }
    }
    var rngLength=$("#rngLength").val();
    var ringiData=[];
    for(var r=0;r<rngLength;r++)
    {
        var ringiFile=$("#ringi_file_"+r).val();
        ringiFile = ringiFile.substring(ringiFile.lastIndexOf("\\") + 1, ringiFile.length);
        var ringiDesc=$("#ringiDesc_"+r).val();
        if(ringiFile !='' || ringiDesc !='')
        {
            var rngtemp = {"ringi_file":ringiFile,"ringi_desc":ringiDesc}
            ringiData.push(rngtemp)
        }
    }
    ringiData=JSON.stringify(ringiData);
    erpData=JSON.stringify(erpData);
    var invoice_type = $("#invoice_type").val();
    var order_type = $("#order_type").val();
    var formfields = {
        date : dateField,
        dept_name : this.state.deptName,
        indenter_name : this.state.indenterName,
        mobile : $("#mobile").val(),
        indenter_email : this.state.indenterEmail,
        erpPo_date : erPoDate,
        cargo_delivery_plant : this.state.cargoDeliveryPlant,
        // invoice_type : invoice_type,
        // order_type : order_type,
        supplier_name : this.state.supplierName,
        supplier_address : this.state.supplierAddr,
        supplier_contact_person : this.state.supplierCnctPerson,
        country : this.state.country,
        email : this.state.emailID,
        phone : this.state.phone,
        delivery_terms : this.state.deliveryTerms,
        pick_up_address : this.state.pickUpAddr,
        port : this.state.port,
        airport_name : this.state.airportName,
        cargo_date : setDate,
        lc : this.state.LC,
        lc_no : this.state.LCno,
        nature : this.state.nature,
        free_trade : this.state.freeTrade,
        benefit_certificate : this.state.benefitCert,
        port_of_dispatch : this.state.port_of_dispatch,
    }
    
    var flag= 0;
    var emailflag = 0;
    for(let key in formfields)
    {
        if(request_type == 1)
        {
            if(key != "no_of_packets" && key != "erpPO" && key != "lc" && key !="lc_no" && key !="erpPo_date" && key !="port" && key !="airport_name" && key !="mobile" && key !="lc no" && key !="pick_up_address")
            {
                if(formfields[key] !=undefined && formfields[key] !='')
                {                
                    if(formfields[key].replace(/\s/g,"").length == 0)
                    {
                        flag = 1;
                        console.log(key,formfields[key]);
                    }
                }
                else
                {
                    flag = 1;
                    console.log(key,formfields[key]);
                }
            }
            else if(key == "indenter_email")
            {   
                // console.log(formfields[key].split("@"))
                if(formfields[key].split("@")[1] != "maruti.co.in") 
                {
                    emailflag = 1;
                }
            }
            else
            {
            }
        }
        else
        {
            if(key == "indenter_email")
            {   
                // console.log(formfields[key].split("@"))
                if(formfields[key].split("@")[1] != "maruti.co.in") 
                {
                    emailflag = 1;
                }
            }
        }
    }
    if(flag == 0)
    {
        if(emailflag == 0)
        {
            this.setState({
                loadshow:'show-m',
                overly:'show-m',
            })
            // console.log(ringiNo)
            let saveData = new FormData();

            var pktLength=$("#no_of_containers").val();
            var containersData=[];
            for(var p=0;p<pktLength;p++)
            {
                var containerType=$("#type_of_container_"+p).val();
                var containerSize=$("#size_of_container_"+p).val();
                var shipmentIs=$("#shipment_type_"+p).val();
                var handlingType=$("#handling_type_"+p).val();
                var splHandling=$("#type_of_special_handling_"+p).val();

                if(containerType !='' || containerSize !='')
                {
                    var temp1 = {"containerType":containerType,"containerSize":containerSize,"shipmentIs":shipmentIs,"handlingType":handlingType,"splHandling":splHandling}
                    containersData.push(temp1)
                }
            }
            containersData=JSON.stringify(containersData);

            saveData.append("request_type", request_type);
            saveData.append("rngLength", rngLength);
            for(var rn=0;rn<rngLength;rn++)
            {
                var ringiFile="ringi_file_"+rn;
                
                saveData.append(ringiFile,this.state[ringiFile]);
                console.log(this.state[ringiFile]);
            }
            var gross_weight = $("#gross_weight").val();
            saveData.append("date",dateField);
            saveData.append("dept_name",this.state.deptName);
            saveData.append("indenter_name",this.state.indenterName);
            saveData.append("mobile",$("#mobile").val());
            saveData.append("indenter_email",this.state.indenterEmail);
            saveData.append("erpPO",this.state.erpPO);
            saveData.append("erpData",erpData);
            saveData.append("ringiData",ringiData);
            saveData.append("item_desc",this.state.item_desc);
            saveData.append("gross_weight",gross_weight);
            saveData.append("dimensions_of_package",this.state.dimensions_of_package);
            saveData.append("gross_cargo_volume",this.state.gross_cargo_volume);
            saveData.append("no_of_containers",pktLength);
            saveData.append("containersData",containersData);
            saveData.append("cargo_type",this.state.cargo_type);
            saveData.append("msds_file",this.state.msds_file);
            saveData.append("cargo_delivery_plant",this.state.cargoDeliveryPlant);
            saveData.append("invoice_type",invoice_type);
            saveData.append("order_type",order_type);
            saveData.append("remarks",this.state.remarks);
            saveData.append("shipment_reference_no",this.state.shipment_reference_no);
            saveData.append("supplier_name",this.state.supplierName);
            saveData.append("supplier_address",this.state.supplierAddr);
            saveData.append("supplier_contact_person",this.state.supplierCnctPerson);
            saveData.append("country",this.state.country);
            saveData.append("email",this.state.emailID);
            saveData.append("phone",this.state.phone);
            saveData.append("delivery_terms",this.state.deliveryTerms);
            saveData.append("pick_up_address",this.state.pickUpAddr);
            saveData.append("port",this.state.port);
            saveData.append("airport_name",this.state.airportName);
            saveData.append("cargo_date",setDate);
            saveData.append("lc",this.state.LC);
            saveData.append("lc_no",this.state.LCno);
            saveData.append("port_of_dispatch",this.state.port_of_dispatch);
            saveData.append("nature",this.state.nature);
            saveData.append("free_trade",this.state.freeTrade);
            saveData.append("benefit_certificate",this.state.benefitCert);
            saveData.append("epcg",this.state.epcg);
            saveData.append("eta_igi_airport_date",eta_igi_airport_date);
            //var ff_code=$("#assign_to_ff").val();
            //var assign_to_ff=$("#assign_to_ff_custom").val();
            //saveData.append("ff_code",ff_code);
            saveData.append("assign_to_ff",this.state.assign_to_ff_custom);
            saveData.append("ff_contact_person_details",this.state.ff_contact_person_details);
            saveData.append("created_by",localStorage.getItem("email"));
            saveData.append("created_by_user",localStorage.getItem("username"));
            // saveData.append("ringiNo",this.state.ringiNo);
            
            
            // var saveData={
            //     "formfields" : formfields,
            //     "ringiNo" : this.state.ringiNo,  
            // }
            
            redirectURL.post("/consignments/seaimportsFormData",saveData,{
                headers:{
                    'content-type': 'multipart/form-data',
                    'Access-Control-Allow-Origin':"*",
                    'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type'
                }
            }).then((response)=>{   
                this.setState({
                    basicTitle:"Success",
                    basicType : "success",
                    show : true,
                    loadshow:'show-n',
                    overly:'show-n',
                });
                window.location.reload();
            }).catch((e)=>{
                console.log(e);
            })
        }
        else{
            this.setState({
                basicTitle:"Invalid Email. Must be maruti.co.in",
                basicType : "warning",
                show : true
            })
        }        
    }
    else if(flag == 1)
    {
        this.setState({
            basicTitle:"Fields Should not be Empty",
            basicType : "warning",
            show : true
        })
    }
    else if(flag == 3)
    {
        this.setState({
            basicTitle:"Mobile Number should be 10 Digits",
            basicType : "warning",
            show : true
        })
    }
}

handleAddRow = () => {
    const item = {
        erpPoNo: "",
        erpPoDate: "",
    };
    this.setState({
        rows: [...this.state.rows, item]
    });
};
handleRemoveRow = (idx) => {
    this.state.rows.splice(idx, 1);
    this.setState({ rows: this.state.rows });
};

handleAddRingiRow = () => {
    const item = {
        ringi_file: "",
        ringiDesc: "",
    };
    this.setState({
        ringirows: [...this.state.ringirows, item]
    });
};
handleRemoveRingiRow = (idx) => {
    this.state.ringirows.splice(idx, 1);
    this.setState({ ringirows: this.state.ringirows });
};

packetsHandler = (e)=> {
    var noOfPackets = e.target.value;
    if(parseInt(noOfPackets) >= 1)
    {
        var lespcks = 1;
        if(noOfPackets > 1)
        {
            lespcks = parseInt(noOfPackets);
        }
        var pktarray=[];
        for(var n=0;n<lespcks;n++)
        {
            const item = {
                containerType: "",
                containerSize: "",
                shipmentIs: "",
                handlingType: "",
                splHandling:"",
            };
            pktarray.push(item);
        }
        this.setState({
            packetrows: pktarray
        });
        console.log(pktarray,"pktarray")
    }
    else
    {
        alert("No of containers should be greater than 0");
        $("#no_of_containers").val(1);
        const item = {
            containerType: "",
            containerSize: "",
            shipmentIs: "",
            handlingType: "",
            splHandling:"",
        };
        this.setState({
            packetrows: [item]
        });
    }
}

onClickTab(pageType){
    if(pageType == "brequest")
    {
        var brequest = "activet";
        var lclrequest = "";
        var track = "";
        var requestData = "show-m";
        var trackData = "show-n";
        var requestFields = "show-m";
        var prepaidFields = "show-n";
        var addresstxt = "";
        var request_type = 1;
        var fclFields = "show-m";
        var lclFields = "show-n";
        var pagetitle = "Booking Request (Sea Imports - FCL)";
        $(".requestFields").attr("required",true);
        $(".prepaidFields").removeAttr("required",true);
        $(".fclFields").attr("required",true);
        $(".lclFields").removeAttr("required",true);
        $("#cargo_type").removeAttr("disabled",true);
        this.setState({
            cargo_type : ""
        });
    }
    else if(pageType == "lclrequest")
    {
        var brequest = "";
        var lclrequest = "activet";
        var track = "";
        var requestData = "show-m";
        var trackData = "show-n";
        var requestFields = "show-m";
        var prepaidFields = "show-n";
        var addresstxt = "";
        var request_type = 2;
        var fclFields = "show-n";
        var lclFields = "show-m";
        var pagetitle = "Booking Request (Sea Imports - LCL)";
        $(".requestFields").attr("required",true);
        $(".prepaidFields").removeAttr("required",true);
        $(".fclFields").removeAttr("required",true);
        $(".lclFields").attr("required",true);
        $("#cargo_type").val("Non Hazardous");
        // $("#cargo_type").attr("disabled",true);
        this.setState({
            cargo_type : "Non Hazardous"
        });
    }
    else if(pageType == "prepaid")
    {
        var brequest = "";
        var lclrequest = "";
        var prepaid = "activet";
        var track = "";
        var requestData = "show-m";
        var trackData = "show-n";
        var requestFields = "show-n";
        var prepaidFields = "show-m";
        var addresstxt = "(To be mentioned on BOE)";
        var request_type = 3;
        var pagetitle = "Booking Request For Prepaid Shipments (Sea Imports)";
        $(".requestFields").removeAttr("required",true);
        $(".prepaidFields").attr("required",true);
    }
    else if(pageType == "track")
    {
        var brequest = "";
        var lclrequest = "";
        var prepaid = "";
        var track = "activet";
        var requestData = "show-n";
        var trackData = "show-m";
        var requestFields = "show-n";
        var prepaidFields = "show-n";
        var addresstxt = "";
        var request_type = "";
        var pagetitle ="";
    }
    this.setState({
        brequest:brequest,
        lclrequest:lclrequest,
        prepaid:prepaid,
        track:track,
        requestData:requestData,
        trackData:trackData,
        requestFields:requestFields,
        prepaidFields:prepaidFields,
        addresstxt:addresstxt,
        request_type:request_type,
        fclFields:fclFields,
        lclFields:lclFields,
        pagetitle:pagetitle
    })
}

trackHandler(event){
    event.preventDefault();
    var container_no = $("#container_no").val();
    if(container_no !=undefined && container_no !='')
    {
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        })
        var params = {
            container_no : container_no
        }
        console.log(params,"788")
        redirectURL.post("/consignments/getContainerDatabyFilter",params)    
        .then((response) => {
            var records = response.data;
            this.setState({
                rowData : records,
                trackResData : 'show-m',
                loadshow:'show-n',
                overly:'show-n',
            })
        })
        .catch(function(e){
            console.log("Error ",e)
        })
    }
    else
    {
        this.setState({
            basicTitle:"Please enter Indenter Email",
            basicType : "warning",
            show : true
        })
    }
}

render(){
    var columnwithDefs = [
        {
            headerName: "Container No",
            field: "container_no",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "MSIL Dispatch Date",
            field: "msil_gate_out",
            width:180,
            filter: true,
            resizable: true, 
            valueGetter:function(params){
                try{
                    if(params.data.msil_gate_out)
                    {
                        return getYYYYMMDD(params.data.msil_gate_out);
                    }
                }
                catch(e){
                    return "";
                }
            }          
        },
        {
            headerName: "ICD Gate in Date",
            field: "icd_arrival_date",
            width:180,
            filter: true,
            resizable: true,
            valueGetter:function(params){
                try{
                    if(params.data.icd_arrival_date)
                    {
                        return getYYYYMMDD(params.data.icd_arrival_date);
                    }
                }
                catch(e){
                    return "";
                }
            }           
        },
        {
            headerName: "Custom Clearance Status",
            field: "handover_date",
            width:180,
            filter: true,
            resizable: true,
            valueGetter:function(params){
                if(params.data.handover_date != undefined && params.data.handover_date != null && params.data.handover_date != "")
                {
                    return "Delivered"
                }else{
                    return "Pending"
                }
            }

        },
        {
            headerName: "Rail Out Status",
            field: "railout_date",
            width:160,
            filter: true,
            resizable: true,
            valueGetter:function(params){
                if(params.data.railout_date != undefined && params.data.railout_date != null && params.data.railout_date != "")
                {
                    return "Delivered"
                }else{
                    return "Pending"
                }
        }

        },
        {
            headerName: "Reason For Delay",
            field: "reason_for_delay",
            width:140,
            filter: true,
            resizable: true,           
        },
    ]
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="f16" style={{marginBottom:"10px"}}>
                            <ul className="d-tabs">
                                
                                {/* <li onClick={this.onClickTab.bind(this,"brequest")} className={"lttabs "+(this.state.brequest)}>
                                    <button type="button" className="btn">Sea Imports - FCL</button>
                                </li> */}
                                
                                {/* <li onClick={this.onClickTab.bind(this,"lclrequest")} className={"lttabs "+(this.state.lclrequest)}>
                                    <button type="button" className="btn">Sea Imports - LCL</button>
                                </li> */}

                                {/* <li onClick={this.onClickTab.bind(this,"prepaid")} className={"lttabs "+(this.state.prepaid)}>
                                    <button type="button" className="btn">Air Prepaid Shipments</button>
                                </li> */}

                                {/* <li onClick={this.onClickTab.bind(this,"track")} className={"lttabs "+(this.state.track)}>
                                    <button type="button" className="btn">Booking Status</button>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    <div className={"col-xl-12 col-lg-12 "+this.state.trackData}>
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
								    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Container Status</span>
								</h5>
				   			</div>
                            <div className="card-body">
                                <form className="row" onSubmit={this.trackHandler.bind(this)} encType="multipart/form-data">
                                    <div className="col-xl-4">
                                        <input type="text" name="container_no" id="container_no" className="form-control" placeholder="Container No" required/>
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <button type="submit" className="btn btn-info">Track Status</button>
                                    </div>
                                </form>
                            </div>
                            <div className={"col-xl-12 col-lg-12 "+this.state.trackResData}>
                                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham"}>    
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={false}
                                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        stopEditingWhenGridLosesFocus= {true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        // components={this.state.components}
                                        enableRangeSelection= {true}
                                        // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                        />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>           
            </div>
        )
    }
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
        format:'d-m-Y',
        minDate:true,
		timepicker:false,
    });
    
    $('.cargo_date').datetimepicker({
		//mask:'39-19-9999',
        format:'d-m-Y',
        minDate:false,
		timepicker:false,
    });
    
    
	$('.readiness_date').datetimepicker({
		//mask:'39-19-9999',
        format:'d-m-Y',
        minDate:false,
		timepicker:false,
    });

    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    script.crossOrigin = "anonymous"
	index.parentNode.insertBefore(script,index)
}

$(document).on("input", ".num-cls", function() {
    this.value = this.value.replace(/\D/g,'');
});
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';

import axios from 'axios';
import Multiselect from 'multiselect-dropdown-react';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class AddLclImports extends Component {
 
    constructor(props) {
        super(props);

        this.state = {
			pageTitle: "Add Lcl Imports",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
        	erroMessage:'',
            successMessage:'',
            show: false,
            basicTitle:'',
            basicType:"default", 	      
        };

	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount = async () =>{
        loadDateTimeScript();
    	if (googleAnalytics.page.enableGA) {
			this.logPageView();
		}
    	await redirectURL.post('/overview/departments')
    	.then((response) => {
    		//console.log(response.data);
    		this.setState({
    			departments:response.data
    		});
    	})
    	.catch(function(error){
    		console.log(error)
    	});
    }

    changeInputHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
        window.location.reload();
    }
    
	formHandler = (event) => {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmitted,
			}
			googleAnalytics.logEvent(eventOptions);
        }
        var booking_no = $("#booking_no").val();
        var po_no = $("#po_no").val();
        var po_date = $("#po_date").val();
        var invoice_no = $("#invoice_no").val();
        var invoice_date = $("#invoice_date").val();
        var lc_no = $("#lc_no").val();
        var lc_date = $("#lc_date").val();
        var shipper_name = $("#shipper_name").val();
        var pol = $("#pol").val();
        var country = $("#country").val();
        var final_destination_port = $("#final_destination_port").val();
        var inco_terms = $("#inco_terms").val();
        var pick_dt = $("#pick_dt").val();
        var vessel_name = $("#vessel_name").val();
        var voy_no = $("#voy_no").val();
        var hbl_no = $("#hbl_no").val();
        var bl_dt = $("#bl_dt").val();
        var obl_no = $("#obl_no").val();
        var container_no = $("#container_no").val();
        var g_wt = $("#g_wt").val();
        var no_of_pkt = $("#no_of_pkt").val();
        var mesurment = $("#mesurment").val();
        var type_of_shift = $("#type_of_shift").val();
        var cut_off = $("#cut_off").val();
        var etd_pol = $("#etd_pol").val();
        var eta_pod = $("#eta_pod").val();
        var d_recd_date = $("#d_recd_date").val();
        var d_validity = $("#d_validity").val();
        var gigm_no = $("#gigm_no").val();
        var gigm_date = $("#gigm_date").val();
        var boe_no = $("#boe_no").val();
        var boe_date = $("#boe_date").val();
        var ooc_date = $("#ooc_date").val();
        var original_boe_to_msil = $("#original_boe_to_msil").val();
        var ff_job_no = $("#ff_job_no").val();
        var ff_custom_clearance = $("#ff_custom_clearance").val();
        var ff_bill_no = $("#ff_bill_no").val();
        var ff_bill_date = $("#ff_bill_date").val();
        var ff_bill_amount = $("#ff_bill_amount").val();
        var ff_bill_handover_date = $("#ff_bill_handover_date").val();
        var custom_clearance_tiger_bill_no = $("#custom_clearance_tiger_bill_no").val();
        var ccl_amount = $("#ccl_amount").val();
        var ccl_bill_date = $("#ccl_bill_date").val();
        var ccl_ho_date = $("#ccl_ho_date").val();
        var transportation_bill_no = $("#transportation_bill_no").val();
        var amount = $("#amount").val();
        var bill_date = $("#bill_date").val();
        var ho_date = $("#ho_date").val();
        //var formdata1 = new FormData(event.target);
        var params = {
            "booking_no" : booking_no,
            "po_no" : po_no,
            "invoice_no" : invoice_no,
            "invoice_date" : invoice_date,
            "lc_no" : lc_no,
            "lc_date" : lc_date,
            "shipper_name" : shipper_name,
            "pol" : pol,
            "country" : country,
            "final_destination_port" : final_destination_port,
            "inco_terms" : inco_terms,
            "pick_dt" : pick_dt,
            "vessel_name" : vessel_name,
            "voy_no" : voy_no,
            "hbl_no" : hbl_no,
            "bl_dt" : bl_dt,
            "obl_no" : obl_no,
            "container_no" : container_no,
            "g_wt" : g_wt,
            "no_of_pkt" : no_of_pkt,
            "mesurment" : mesurment,
            "type_of_shift" : type_of_shift,
            "cut_off" : cut_off,
            "d_recd_date" : d_recd_date,
            "d_validity" : d_validity,
            "gigm_no" : gigm_no,
            "gigm_date" : gigm_date,
            "boe_no" : boe_no,
            "boe_date" : boe_date,
            "ooc_date" : ooc_date,
            "original_boe_to_msil" : original_boe_to_msil,
            "ff_job_no" : ff_job_no,
            "ff_custom_clearance" : ff_custom_clearance,
            "ff_bill_no" : ff_bill_no,
            "ff_bill_date" : ff_bill_date,
            "ff_bill_amount" : ff_bill_amount,
            "ff_bill_handover_date" : ff_bill_handover_date,
            "custom_clearance_tiger_bill_no" : custom_clearance_tiger_bill_no,
            "ccl_amount" : ccl_amount,
            "ccl_bill_date" : ccl_bill_date,
            "ccl_ho_date" : ccl_ho_date,
            "transportation_bill_no" : transportation_bill_no,
            "amount" : amount,
            "bill_date" : bill_date,
            "ho_date" : ho_date,
        };
        console.log(params);
        redirectURL.post("/consignments/savelclimports",params).then((response) => {
            this.setState({
                basicTitle:"Success",
                basicType : "success",
                show : true
            });
            //window.location.reload();
        })
	}  
    
    render(){
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>{this.state.pageTitle}</span>
				       			</h5>
				   			</div>
			   				<div className="row card-body">
				   			
							<form className="theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler}>
                                <div className="row col-xl-12">
	                    			<div className="col-xl-4 col-lg-4">
					               		<div className="form-group">
					               			<label className="c-lbl">Booking No *:</label>
					               			<input type="text" name="booking_no" id="booking_no" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Booking No" required/>
					               		</div>
				               		</div>
                                    <div className="col-xl-4 col-lg-4">
					               		<div className="form-group">
					               			<label className="c-lbl">PO No :</label>
					               			<input type="text" name="po_no" id="po_no" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="PO No" />
					               		</div>
				               		</div>
                                    <div className="col-xl-4 col-lg-4">
					               		<div className="form-group">
					               			<label className="c-lbl">PO Date :</label>
					               			<input type="text" name="po_date" id="po_date" onChange={this.changeInputHandler.bind(this)} className="form-control datetimepicker_date" placeholder="PO No" />
					               		</div>
				               		</div>
                                </div>

                                <div class="hr-dashed"></div>

                                <div className="row col-xl-12">
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">Invoice No:</label>
					               			<input type="text" name="invoice_no" id="invoice_no" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Invoice No" />
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">Invoice Date:</label>
					               			<input type="text" name="invoice_date" id="invoice_date" onChange={this.changeInputHandler.bind(this)} className="form-control datetimepicker_date" placeholder="Select Date" />
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">LC No:</label>
					               			<input type="text" name="lc_no" id="lc_no" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="LC No" />
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">LC Date:</label>
					               			<input type="text" name="lc_date" id="lc_date" onChange={this.changeInputHandler.bind(this)} className="form-control datetimepicker_date" placeholder="Select Date" />
					               		</div>
				               		</div>
                                </div>

                                <div class="hr-dashed"></div>
                                
                                <div className="row col-xl-12">
	                    			<div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">Shipper Name:</label>
					               			<input type="text" name="shipper_name" id="shipper_name" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Shipper Name" />
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
                                            <label className="c-lbl">POL:</label>
                                            <input type="text" name="pol" id="pol" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="POL" />
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
                                            <label className="c-lbl">Country:</label>
                                            <input type="text" name="country" id="country" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Country" />
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
                                            <label className="c-lbl">Final Destination Port:</label>
                                            <input type="text" name="final_destination_port" id="final_destination_port" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Final Destination Port" />
					               		</div>
				               		</div>
                                </div>

                                <div class="hr-dashed"></div>

                                <div className="row col-xl-12">
	                    			<div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">Inco Terms:</label>
					               			<input type="text" name="inco_terms" id="inco_terms" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Inco Terms" />
					               		</div>
				               		</div>
                                    {/* <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
                                            <label className="c-lbl">Pick Dt.(if EXW):</label>
                                            <input type="text" name="pick_dt" id="pick_dt" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="POL" />
					               		</div>
				               		</div> */}
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
                                            <label className="c-lbl">Vessel Name:</label>
                                            <input type="text" name="vessel_name" id="vessel_name" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Vessel Name" />
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
                                            <label className="c-lbl">Voy No:</label>
                                            <input type="text" name="voy_no" id="voy_no" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Voy No" />
					               		</div>
				               		</div>
                                </div>

                                <div class="hr-dashed"></div>

                                <div className="row col-xl-12">
	                    			<div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">HBL NO:</label>
					               			<input type="text" name="hbl_no" id="hbl_no" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="HBL NO" />
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
                                            <label className="c-lbl">BL Dt:</label>
                                            <input type="text" name="bl_dt" id="bl_dt" onChange={this.changeInputHandler.bind(this)} className="form-control datetimepicker_date" placeholder="BL Dt" />
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
                                            <label className="c-lbl">OBL NO:</label>
                                            <input type="text" name="obl_no" id="obl_no" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="OBL NO" />
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
                                            <label className="c-lbl"> Container Nos:</label>
                                            <input type="text" name="container_no" id="container_no" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Container Nos" />
					               		</div>
				               		</div>
                                </div>

                                <div class="hr-dashed"></div>

                                <div className="row col-xl-12">
	                    			<div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">G.Wt (Kg):</label>
					               			<input type="text" name="g_wt" id="g_wt" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="G.Wt (Kg)" />
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
                                            <label className="c-lbl">No. of Pkt:</label>
                                            <input type="text" name="no_of_pkt" id="no_of_pkt" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="No. of Pkt" />
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
                                            <label className="c-lbl">Mesurment (CBM):</label>
                                            <input type="text" name="mesurment" id="mesurment" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Mesurment (CBM)" />
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
                                            <label className="c-lbl"> Type of Shipt (FCL/LCL):</label>
                                            <select name="type_of_shift" id="type_of_shift" onChange={this.changeInputHandler.bind(this)} className="form-control">
                                                <option value="">Select Option</option>
                                                <option value="FCL">FCL</option>
                                                <option value="LCL">LCL</option>
                                            </select>
					               		</div>
				               		</div>
                                </div>

                                <div class="hr-dashed"></div>

                                <div className="row col-xl-12">	                    			
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">CUT-OFF:</label>
					               			<input type="text" name="cut_off" id="cut_off" onChange={this.changeInputHandler.bind(this)} className="form-control datetimepicker_date" placeholder="Select Date" />
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">ETD-POL:</label>
					               			<input type="text" name="etd_pol" id="etd_pol" onChange={this.changeInputHandler.bind(this)} className="form-control datetimepicker_date" placeholder="Select Date" />
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">ETA-POD:</label>
					               			<input type="text" name="eta_pod" id="eta_pod" onChange={this.changeInputHandler.bind(this)} className="form-control datetimepicker_date" placeholder="Select Date" />
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">D/O Recd. Dt.:</label>
					               			<input type="text" name="d_recd_date" id="d_recd_date" onChange={this.changeInputHandler.bind(this)} className="form-control datetimepicker_date" placeholder="Select Date" />
					               		</div>
				               		</div>
                                </div>

                                <div class="hr-dashed"></div>

                                <div className="row col-xl-12">	                    			
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">D/O Validity:</label>
					               			<input type="text" name="d_validity" id="d_validity" onChange={this.changeInputHandler.bind(this)} className="form-control datetimepicker_date" placeholder="Select Date" />
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">GIGM NO:</label>
					               			<input type="text" name="gigm_no" id="gigm_no" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="GIGM NO" />
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">GIGM Date:</label>
					               			<input type="text" name="gigm_date" id="gigm_date" onChange={this.changeInputHandler.bind(this)} className="form-control datetimepicker_date" placeholder="Select Date" />
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">BOE No:</label>
					               			<input type="text" name="boe_no" id="boe_no" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="BOE No" />
					               		</div>
				               		</div>
                                </div>

                                <div class="hr-dashed"></div>

                                <div className="row col-xl-12">	                    			
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">BOE Date:</label>
					               			<input type="text" name="boe_date" id="boe_date" onChange={this.changeInputHandler.bind(this)} className="form-control datetimepicker_date" placeholder="Select Date" />
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">OOC date:</label>
					               			<input type="text" name="ooc_date" id="ooc_date" onChange={this.changeInputHandler.bind(this)} className="form-control datetimepicker_date" placeholder="Select Date" />
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">Original BOE submission to MSIL:</label>
					               			<input type="text" name="original_boe_to_msil" id="original_boe_to_msil" onChange={this.changeInputHandler.bind(this)} className="form-control datetimepicker_date" placeholder="Original BOE submission to MSIL" />
					               		</div>
				               		</div>
                                </div>

                                <div class="hr-dashed"></div>

                                <div className="row col-xl-12">
                                    <div className="col-xl-4 col-lg-4">
					               		<div className="form-group">
					               			<label className="c-lbl">FF JOB NO:</label>
					               			<input type="text" name="ff_job_no" id="ff_job_no" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="FF JOB NO" />
					               		</div>
				               		</div>
                                    <div className="col-xl-4 col-lg-4">
					               		<div className="form-group">
					               			<label className="c-lbl">FF Job N. Custom Clearance:</label>
					               			<input type="text" name="ff_custom_clearance" id="ff_custom_clearance" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="FF JOB N. Custom Clearance" />
					               		</div>
				               		</div>
                                    <div className="col-xl-4 col-lg-4">
					               		<div className="form-group">
					               			<label className="c-lbl"> FF Bill No:</label>
					               			<input type="text" name="ff_bill_no" id="ff_bill_no" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="FF Bill No" />
					               		</div>
				               		</div>
                                </div>

                                <div class="hr-dashed"></div>

                                <div className="row col-xl-12">
                                    <div className="col-xl-4 col-lg-4">
					               		<div className="form-group">
					               			<label className="c-lbl">FF Bill Date:</label>
					               			<input type="text" name="ff_bill_date" id="ff_bill_date" onChange={this.changeInputHandler.bind(this)} className="form-control datetimepicker_date" placeholder="Select Date" />
					               		</div>
				               		</div>
                                    <div className="col-xl-4 col-lg-4">
					               		<div className="form-group">
					               			<label className="c-lbl">FF BILL AMOUNT:</label>
					               			<input type="text" name="ff_bill_amount" id="ff_bill_amount" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="FF BILL AMOUNT" />
					               		</div>
				               		</div>
                                    <div className="col-xl-4 col-lg-4">
					               		<div className="form-group">
					               			<label className="c-lbl">FF Bill Handover date:</label>
					               			<input type="text" name="ff_bill_handover_date" id="ff_bill_handover_date" onChange={this.changeInputHandler.bind(this)} className="form-control datetimepicker_date" placeholder="Select Date" />
					               		</div>
				               		</div>
                                </div>

                                <div class="hr-dashed"></div>

                                {/* <div className="row col-xl-12">	                    			
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl"> Custom Clearance Tiger Bill no :</label>
                                            <input type="text" name="custom_clearance_tiger_bill_no" id="custom_clearance_tiger_bill_no" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Custom Clearance Tiger Bill no" />
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">CCL Amount:</label>
					               			<input type="text" name="ccl_amount" id="ccl_amount" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="CCL Amount" />
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">CCL BILL DATE :</label>
					               			<input type="text" name="ccl_bill_date" id="ccl_bill_date" onChange={this.changeInputHandler.bind(this)} className="form-control datetimepicker_date" placeholder="Select Date" />
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">CCL H/O date :</label>
					               			<input type="text" name="ccl_ho_date" id="ccl_ho_date" onChange={this.changeInputHandler.bind(this)} className="form-control datetimepicker_date" placeholder="Select Date" />
					               		</div>
				               		</div>
                                </div>

                                <div class="hr-dashed"></div>

                                <div className="row col-xl-12">	                    			
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl"> TRANSPORTATION BILL NO :</label>
                                            <input type="text" name="transportation_bill_no" id="transportation_bill_no" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="TRANSPORTATION BILL NO" />
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">Amount:</label>
					               			<input type="text" name="amount" id="amount" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Amount" />
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">BILL DATE :</label>
					               			<input type="text" name="bill_date" id="bill_date" onChange={this.changeInputHandler.bind(this)} className="form-control datetimepicker_date" placeholder="Select Date" />
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<label className="c-lbl">H/O date :</label>
					               			<input type="text" name="ho_date" id="ho_date" onChange={this.changeInputHandler.bind(this)} className="form-control datetimepicker_date" placeholder="Select Date" />
					               		</div>
				               		</div>

                                <div class="hr-dashed"></div> */}

                                <div className="row col-xl-12">
                                    <div className="col-xl-3 col-lg-3">
					           			
                                        <div className="form-group">
					               			<button type="submit" className="btn btn-success cs-btn" id="saveGeo">Submit</button>
					               			{/* <a href="/manage/geofences" className="btn btn-info cs-btn">Cancel</a> */}
					               			
					               		</div>
									</div>
                                </div>
				           		</form>
			           		</div>
		           		</div>
	           		</div>
                </div>
            </div>
        );
    }
}
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
        format:'d-m-Y',
        minDate:true,
		timepicker:false,
    });
}
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../node_modules/react-resizable/css/styles.css';
import { WidthProvider, Responsive } from "react-grid-layout";
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import _ from "lodash";
import $ from "jquery";
var redirectURL = require('../../redirectURL');



export default class Overspeed extends Component{

	constructor(props)
	{
		super(props);
		this.state={
            min_loading_duration:"",
            dept_code:"",
            userid:localStorage.getItem('userid'),
            email:localStorage.getItem('email'),
        };
        
		
    }
    componentDidMount(){
        var dept_code = this.props.deptCode;
        // var dept_code = "SNDG";
        var formdata = {
            config_type:'loadingdelay',
            dept_code : dept_code
        };
        
        redirectURL.post('/manage/kpiConfigurations',formdata, {
            headers:{
                'Content-type':'application/json'
            }
        })
        .then((response) => {
            console.log(response.data);
            if(response.data.length > 0)
            {
                this.setState({
                    min_loading_duration : response.data[0].min_loading_delay_duration,
                    dept_code : dept_code
                });
            }
            else
            {
                this.setState({
                    min_loading_duration : "",
                    dept_code : dept_code
                });
            }
            console.log(typeof(this.state.duration),this.state.distance,this.state.nightRules);

        })
    }
   
	formHandler = (event) => {
        event.preventDefault(); 
        var min_loading_duration = document.getElementById("minDelayID").value;
        var formdata = {
            userid:this.state.userid,
            user_email:localStorage.getItem('email'),
            dept_code:this.state.dept_code,
            config_type:"loadingdelay",
            min_loading_delay_duration:min_loading_duration,
            // threshold_time:(this.state.time*60)
        }
        console.log(formdata)
        redirectURL.post('/manage/saveLDKPIConfigurations',formdata, {
            headers:{
                'Content-type':'application/json'
            }
        })
        .then((response) => {
            var res = response.data;
            if(res.result.result.ok == 1)
            {
                this.props.context.showAlert();
            }
            else{
                this.props.context.showErrorAlert();
            }
        })
    }
    checkDuration = (event) =>{
        this.setState({
            // distance : event.target.value,
            min_loading_duration : event.target.value
        })
    }
    changeDept(params){
        this.setState({
            dept_code:params.value
        })
    }

    selectDept(){
        let arr=[];
        try{
            arr.push({value:"SNDG",label:"Sales and Dispatch"});
            arr.push({value:"LOG-PRT",label:"Spare Parts"});
            arr.push({value:"LOG-TNP",label:"Train and Production"});
            return arr;
        }
        catch(e){

        } 
    }
	 render() {
         let nightDrivingRules;
        return (
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <h5>Loading Delay Settings</h5>
                </div>
                <form className=" theme-form col-xl-6 col-lg-6" onSubmit={this.formHandler}>
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            {/* <label>Select Department : </label>
                            <Select 
                                placeholder={"Department"}
                                closeMenuOnSelect={true}
                                // isMulti="true"
                                // onChange={this.getRailConsginments.bind(this)} 
                                className={"border-radius-0"}
                                style={{borderRadius:"0px"}}
                                options={this.selectDept()}
                                onChange={this.changeDept.bind(this)}
                             />     */}
                        </div>        
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            <label className="c-lbl">Minimum Loading Delay Duration (Minutes) * : </label>
                            <input type="number" value = {this.state.min_loading_duration} id="minDelayID" className="form-control validateNumber" autoComplete="off" onChange={this.checkDuration.bind(this)} required />
                        </div>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-success cs-btn" id="save">Save</button>
                    </div>
                </form>
            </div>
        );
    }
}
$(document).ready(function(){
    $(document).on('keyup',".validateNumber",function(){
        // alert("click");
        if(isNaN($(this).val()) == true){
            $(this).val($(this).val().slice(0,-1));
        }
    });
    $(document).on("keyup",".validateEmail",function(e){
        if(e.which === 32){
            $(this).val($(this).val()+", ");
        }
    });
    $(document).on("keyup","#minDelayID",function(){
        if($(this).val() == 0){
            // alert("Value Should be Greater than 0");
            // $(".error-msg").empty();
            // $(".error-msg").append("Value Should be greater than 0");
            $(this).val(" ");
        }
    })
});


import React, { Component } from 'react';
import './NewsBanner.css';

var redirectURL = require('../../redirectURL');

class NewsBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPaused: false,
            newsList: [],
            animationDuration: '20s', // Default animation duration
        };
        this.newsContainerRef = React.createRef();
    }

    componentDidMount() {
        this.fetchNews();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.newsList !== this.state.newsList) {
            this.calculateAnimationDuration();
        }
    }

    fetchNews = async () => {
        redirectURL.post('/dashboard/getAllNews').then((response) => {
            // console.log("News: ", response.data);
            this.setState({
                newsList: response.data,
            });
        });
    };

    calculateAnimationDuration = () => {
        if (this.newsContainerRef.current) {
            const newsContainerWidth = this.newsContainerRef.current.scrollWidth;
            const speed = 100; // Pixels per second
            const duration = newsContainerWidth / speed; // Calculate duration in seconds

            this.setState({
                animationDuration: `${duration}s`,
            });
        }
    };

    handleMouseEnter = () => {
        this.setState({ isPaused: true });
    };

    handleMouseLeave = () => {
        this.setState({ isPaused: false });
    };

    render() {
        const { newsList, isPaused, animationDuration } = this.state;

        return (
            <div className="news-banner">
                <div
                    className={`news-container ${isPaused ? 'paused' : ''}`}
                    ref={this.newsContainerRef}
                    style={{ animationDuration }}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                >
                    {/* Repeat the news items twice to create a seamless scrolling effect */}
                    {[...newsList, ...newsList].map((news, index) => (
                        <>
                            <a
                                key={index}
                                href={news.news_link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="news-item"
                            >
                                {news.news_title}
                            </a>
                        </>
                    ))}
                </div>
            </div>
        );
    }
}

export default NewsBanner;


import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const ImportsRailAction = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onShowConsignmentImportsTransitRailEventsClick(props);
    };
    //var colr = 'grayfont'
    // if(props.data.container_no == 'TGHU0165559')
    // {
    //     console.log("props.data.rail_transit_delay ", props.data.rail_transit_delay)
    // }
    
    if(props.data.rail_transit_delay == 1)
    {
        var colr = 'redfont'
    }
    else{
        if(props.data.last_status == 'Port Out')
        {
            var colr = 'yellowfont'
        }
        else if(props.data.last_status == 'In Transit to MSIL' ||  props.data.last_status == 'In ICD Ghari')
        {
                var colr = 'greenfont'
        }
        else{
            var colr = 'grayfont'
        }
    } 
   
    return (
        <div>
            <button onClick={handleEventClick} className="custom-btn f22 label label-success" 
            title="In transit to ICD Garhi" ><i className={"icofont icofont-train-line "+(colr)}></i> &nbsp;</button>
            
        </div>
    );
};

export default ImportsRailAction;

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import CountUp from 'react-countup';


import _ from "lodash";

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
//import Grid from '../layouts/gridnosidebarComponent';

import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import KpiTransitDelayActions from './kpiTransitDelayActions';
import Notransit from './notransitComponent.js';
import ConsignmentActions from './routemapComponent';
import CommentActions from "../layouts/commentsComponent";
import ConsignmentNoActions from './routemapNoComponent';
import ConsignmentImportsEventsAction from './consignmentImportsEventsAction';
import ConsignmentImportsNoEventsAction from './consignmentimportsnoEventAction';
import $ from "jquery";
import ImportsPortAction from './importsportaction';
import ImportsRailAction from './importsrailaction';
import ImportsMsilAction from './importsMsilAction';
import ImportsWarehouseAction from './importswarehouseaction';
import ImportsEmptyContainerAction from './importsemptycontaineraction';
import Forceclose from './importsforcecloseaction'
import Counters from '../layouts/consignmentimportscountersComponent';
import CounterSet from '../layouts/importconsignmentcountersetsComponent.js';
import 'react-vertical-timeline-component/style.min.css';
import { getDDMMYYYY,getYYYYMMDDHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY } from '../common/utils'; 
import DrawMap from '../common/drawmap';
import Breadcrumb from '../common/breadcrumb.component';
import Modal from 'react-responsive-modal';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';

var moment = require('moment');
 
var redirectURL = require('../redirectURL');

var infoBox = require('../common/google-infowindow');

const fs = require('fs');
var googleAnalytics = require("../common/googleanalytics");

 
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var geoFencingArea;

export default class ManageImportsConsignments extends Component {

	constructor(props){
		super(props);
		this.state={
			pageTitle: "Container Imports (Delivered)",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			statusTexts: [],
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			timelineinfo:'',
			consignment_code:'',
			mapinfo:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			alerterrshow:'fade',
			overly:'show-n',
			alert:null,
			sliderCommentTranslate:'',
			commentsRowData:"",
			show: false,
			open: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			filterstatess:{},
      	    columnsdatas:{},
      	    rowgroupdcols:[],
			  valuecolumns:[],
			  totalrows:[],
      	    pivotmodeis:false,
			pivotcols:[],
			defTransitCoords:'',
			allRowData:0,
			highseascount:0,
			highseasinfo:'',
			inportinfo:'',
			transittoicdinfo:'',
			inicdinfo:'',
			inmsilinfo:'',
			inportcount:0,
			intransittoicdcount:0,
			inicdcount:0,
			transitmsilcount:0,
			emptyreturned:0,

			highseaslistcount:0,
			highseaslistinfo:'',
			inportlistinfo:'',
			transittoicdlistinfo:'',
			inicdlistinfo:'',
			inmsillistinfo:'',
			inportlistcount:0,
			intransittoicdlistcount:0,
			inicdlistcount:0,
			transitmsillistcount:0,
			emptyreturnedlist:0,

      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true,
				

      	    },
      	    rowData: null,
			headerHeight: 40,
			groupHeaderHeight:60,
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  frameworkComponents: {
                customLoadingCellRenderer: CustomLoadingCellRenderer,
				consignmentActions:ConsignmentActions,
				commentActions : CommentActions,
				consignmentNoActions:ConsignmentNoActions,
				ConsignmentImportsEventsAction: ConsignmentImportsEventsAction,
				ConsignmentImportsNoEventsAction:ConsignmentImportsNoEventsAction,
				importsPortAction:ImportsPortAction,
				importsRailAction:ImportsRailAction,
				importsMsilAction:ImportsMsilAction,
				importsWarehouseAction:ImportsWarehouseAction,
				importsEmptyContainerAction:ImportsEmptyContainerAction,
				forceclose:Forceclose
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:100,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"cellStyleGrid":"columnApi.columnController.columnDefs[0].headerName == 'High Sea Details'",
				//"d-none": "data.last_status == 'Empty Returned'"
			},
				
			boe_filing_delay_total:0,
            boe_filing_delay_lessthree:0,
            boe_filing_delay_greaterthree:0,
    		icd_detention_delay_first:0,
            icd_detention_delay_second:'',
			icd_detention_delay_third:0,
			msil_gatein_date:'',
			msil_gateout_date:'',
			empty_return_date:'',
			forceclose_remarks:'',
			forcedata:[],
			emptydisable:false,
			podlist:[],
			pod:'',
			startDate:'',
			endDate:'',
			criteria:'',
			errStyCriteria:'',
			errStyPod:'',
			errStyPol:'',
			errStyStartDate:'',
			errStyEndDate:'',
			pollist:[],
			pol:'',
			countryorigin:[],
			country_code:'',
			usergridstate:[],
			screenurl:"",
			screentitle:""
		}
	
		this.onShowRouteMap = this.onShowRouteMap.bind(this);
		this.compare_dates = this.compare_dates.bind(this);
		this.changeHandler=this.changeHandler.bind(this);
		this.onShowCommentsDiv = this.onShowCommentsDiv.bind(this);
		
	}
	setStatusTexts = () => {
		let statusTexts = {
			"1": "High Seas",
			"2": "In Port",
			"3": "In Transit to ICD Garhi",
			"4": "Inside ICD Garhi",
			"5": "In Transit to MSIL",
		}
		this.setState({
			statusTexts:statusTexts,
		});
		
	}

	componentDidMount(){
		loadDateTimeScript();
		this.setStatusTexts(); // set status texts
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
		var userroles = localStorage.getItem('roles')
		if(userroles.indexOf("sc_ppc") >= 0)
		{
			this.setState({
				sideBar:false
			});
		}
		else{
			this.setState({
				sideBar:{
					toolPanels: [
						{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
						},
						{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
						}
					]
				}
			})
		}
		this.setState({
				loadshow : "show-m",
				overly : "show-m"
		})
		redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			await this.setState({
				usergridstate:resp.data,
			});
			await this.restoreGridStates();
		})

		redirectURL.post("importscoils/pods")
		.then((response) =>{
			this.setState({
				podlist:response.data
			});
		})
		.catch(function(e){
			console.log("Error ", e)
		})


		redirectURL.post("importscoils/pols")
		.then((response) =>{
			this.setState({
				pollist:response.data
			});
		})
		.catch(function(e){
			console.log("Error ", e)
		})
		
		 /*Consignments List*/	
		var urlpath;
		var dpt;
		// if(this.props.match.path == "/imports/consignmentsbeforemar")
		// {
		// 	urlpath = '/imports/consignmentsbeforemar';
		// }
		
		// else
		// {
			urlpath = '/imports/deliveredcontainers';

		//}

	    redirectURL.post(urlpath)
		    .then((response) => {
				var recordsarr = response.data;
				//console.log("IN componentDidMount records = ", records);
				// find container_no from all records
				//const containerNumbersFromImportsConsignments = records.map(obj => obj.container_no);
				var containerNumbersFromImportsConsignments = [];
				var recordset = [];
				recordsarr.map(obj =>{
					// var condatapod = moment("2020-02-29").format("x");
					// var atapodcnv = new Date(moment(obj.ata_pod).format("YYYY-MM-DD"));
					// var seaatapodcnv = new Date(moment(obj.searates_ata_pod).format("YYYY-MM-DD"));
					// var atapod = moment(atapodcnv).format("x");
					// var seaatapod = moment(seaatapodcnv).format("x");
					// if(this.props.match.path == "/imports/consignmentsbeforemar")
					// {
					// 	if(obj.searates_ata_pod == null)
					// 	{
					// 		if( (obj.ata_pod != 'NaN' && obj.ata_pod != 'Awaited' && obj.ata_pod != undefined))
					// 		{
								
					// 			if(atapod <= condatapod){
					// 				//console.log("Yes")
					// 				containerNumbersFromImportsConsignments.push(obj.container_no)
					// 				records.push(obj);
					// 			}
					// 		}
					// 	}
					// 	else{
					// 		if( (obj.searates_ata_pod != 'NaN' && obj.searates_ata_pod != 'Awaited' && obj.searates_ata_pod != undefined))
					// 		{
								
					// 			if( seaatapod <= condatapod){
					// 				//console.log("Yes")
					// 				containerNumbersFromImportsConsignments.push(obj.container_no)
					// 				records.push(obj);
					// 			}
					// 		}
					// 	}
					// }
					// else{
						
					// 		if(obj.searates_ata_pod == null)
					// 		{
					// 			if( (obj.ata_pod != 'NaN' && obj.ata_pod != 'Awaited' && obj.ata_pod != undefined))
					// 			{
									
					// 				if(atapod > condatapod){
					// 					//console.log("Yes")
					// 					containerNumbersFromImportsConsignments.push(obj.container_no)
					// 					records.push(obj);
					// 				}
					// 			}
					// 		}
					// 		else{
					// 			if( (obj.searates_ata_pod != 'NaN' && obj.searates_ata_pod != 'Awaited' && obj.searates_ata_pod != undefined))
					// 			{
									
					// 				if( seaatapod > condatapod){
					// 					//console.log("Yes")
					// 					containerNumbersFromImportsConsignments.push(obj.container_no)
					// 					records.push(obj);
					// 				}
					// 			}
					// 		}
							 containerNumbersFromImportsConsignments.push(obj.container_no)
							 recordset.push(obj);
					//}
				});
				
				//console.log("containerNumbersFromImportsConsignments = ", containerNumbersFromImportsConsignments);
				// prepare query to fetch records for matching container numbers
				let queryByContainerNumbers = {
					"container_no": { $in: containerNumbersFromImportsConsignments}
				};
				//fetch data from inlandcontainerimports
				redirectURL.post('/consignments/getinlandcontainerimportstrackingdetails', {"queryByContainerNumbers":queryByContainerNumbers})
				.then((inlandResponse) => {
					var inlandRecords = inlandResponse.data;
					
					//console.log("IN componentDidMount inlandRecords = ", inlandRecords);
					const inlandRecordsRewritten = inlandRecords.map(obj => {
						// prefix keys of inlandRecords to avoid any overwrite to consignment records
						Object.entries(obj).forEach(
							([key, value]) => {
								let newKey = 'inland_'+key;
								 	obj[newKey] = value;
									//console.log("IN componentDidMount MAPPING obj INNER = ", key, newKey, obj);
									delete(obj[key]) ; // remove original key after renaming
							}
						);

						//console.log("IN componentDidMount MAPPING obj = ", obj);
						return obj;

					});

					// Need a blank object with new keys to merge assign null values to grid columns
					// for container numbers found in importsconsignments but not found a match in inlandcontainerimports
					//console.log("inland",inlandRecordsRewritten[0])
					const inlandRecordsRewrittenBlank = Object.entries(inlandRecordsRewritten[0]).forEach(
						([key, value]) => {
							let blankObj = {};
							let newKey = 'inland_'+key;
							blankObj[newKey] = null; // create a blank object with same new keys
						}
					);
					//console.log("IN componentDidMount MAPPED inlandRecords = ", inlandRecords);
					//console.log("IN componentDidMount MAPPED inlandRecordsRewrite = ", inlandRecordsRewrite);
					// merge both results to prepare grid columns
					for (let i = 0; i < recordset.length; i++) {
						for (let j = 0; j < inlandRecordsRewritten.length; j++) {
							if ((recordset[i].container_no === inlandRecordsRewritten[j].inland_container_no) && 
							(recordset[i].hbl_no === inlandRecordsRewritten[j].inland_bill_of_lading)) 
							{
								recordset[i] = Object.assign(recordset[i], inlandRecordsRewritten[j]);
							} else {
								// assign null values for no match
								recordset[i] = Object.assign(recordset[i], inlandRecordsRewrittenBlank);
							}
						}
					}

					var records = []
					recordset.map((item) => {
						// if (item.searates_pod != "Nhava Sheva" 
						// && item.searates_pod != "NHAVA SHEVA" 
						// && item.discharge_port != "Nhava Sheva" 
						// && item.discharge_port != "NHAVA SHEVA")
						// {
							if((item.inland_empty_return_date != "" || item.inland_empty_return_date != undefined) 
							&& (item.inland_gps_msil_gate_out != "" || item.inland_gps_msil_gate_out != undefined))
							{
								records.push(item);
							}
							//records.push(item);
						// }
					})
					

					//console.log("IN componentDidMount MOD records = ", records);
					var highseasarr=[];
					var inportarr=[];
					var intransittoicdarr=[];
					var inicdarr=[];
					var transitmsilarr=[];

					var highseaslistarr=[];
					var inportlistarr=[];
					var intransittoicdlistarr=[];
					var inicdlistarr=[];
					var transitmsillistarr=[];

					

					var highseasdelayarr=[];
					var highseasnodelayarr=[];
					var inportdelayarr = [];
					var inportnodelayarr = [];
					var intransittoicddelayarr = [];
					var intransittoicdnodelayarr = [];
					var inicddelayarr = [];
					var inicdnodelayarr = [];
					var transitmsildelayarr = [];
					var transitmsilnodelayarr = [];


					var highseasdelaylistarr=[];
					var highseasnodelaylistarr=[];
					var inportdelaylistarr = [];
					var inportnodelaylistarr = [];
					var intransittoicddelaylistarr = [];
					var intransittoicdnodelaylistarr = [];
					var inicddelaylistarr = [];
					var inicdnodelaylistarr = [];
					var transitmsildelaylistarr = [];
					var transitmsilnodelaylistarr = [];

					var boe_filing_delay_totalarr = [];
					var boe_filing_delay_lessthreearr= [] ;
					var boe_filing_delay_greaterthreearr = [];
					var icd_detention_delay_firstarr = [];
					var icd_detention_delay_secondarr = [];
					var icd_detention_delay_thirdarr = [];
					var icd_detention_delay_lessfivearr = [];


					var boe_filing_delay_totallistarr = [];
					var boe_filing_delay_lessthreelistarr= [] ;
					var boe_filing_delay_greaterthreelistarr = [];
					var icd_detention_delay_firstlistarr = [];
					var icd_detention_delay_secondlistarr = [];
					var icd_detention_delay_thirdlistarr = [];
					var icd_detention_delay_lessfivelistarr = [];

					var emptyarr=[];
					var emptylistarr=[];
					let newArray = []; 
              
					// Declare an empty object 
					let uniqueObject = {}; 
					var objTitle;
					// Loop for the array elements 
					for (let i in records) { 
			
						// Extract the title 
						objTitle = records[i]['container_no']; 
			
						// Use the title as the index 
						uniqueObject[objTitle] = records[i]; 
					} 
					
					// Loop to push unique object into array 
					for (var a in uniqueObject) { 
						newArray.push(uniqueObject[a]); 
					} 
					
					//console.log("newArray ", newArray)
					//console.log("newArray ", newArray.length)
					newArray.map((item) => {
						if(item.last_status == 'High Seas')
						{
							highseasarr.push(item)
							if(item.sea_transit_delay == 1)
							{
								highseasdelayarr.push(item)
							}
							else{
								highseasnodelayarr.push(item);
							}
						}
						if(item.last_status == "Port In" || item.last_status == 'Arrived at Indian Port')
						{
							inportarr.push(item)
							if(item.port_delay == 1)
							{
								inportdelayarr.push(item)
							}
							else{
								inportnodelayarr.push(item)
							}
						}
						if(item.last_status == "Port Out")
						{
							intransittoicdarr.push(item)
							if(item.rail_transit_delay == 1)
							{
								intransittoicddelayarr.push(item)
							}
							else{
								intransittoicdnodelayarr.push(item)
							}
						}
						if(item.last_status == "In ICD Ghari" || item.last_status == "In ICD")
						{
							inicdarr.push(item)
							if(item.icd_delay == 1)
							{
								inicddelayarr.push(item);
							}
							else{
								inicdnodelayarr.push(item);
							}
						}
						if(item.last_status == "In Transit to MSIL")
						{
							transitmsilarr.push(item)
						}
						//emptyreturned

						if(item.last_status == "Empty Returned")
						{
							emptylistarr.push(item)
						}

						/*BOE Filing Delay*/
						if(item.boe_filing_delay > 1)
						{
							boe_filing_delay_totalarr.push(item);
						}

						if(item.boe_filing_delay > 1 && item.boe_filing_delay <= 3)
						{
							boe_filing_delay_lessthreearr.push(item);
						}

						if(item.boe_filing_delay > 3)
						{
							boe_filing_delay_greaterthreearr.push(item);
						}

						/*Overdue Deliveries from ICD*/

						if(item.inland_grfl_arrival_date && item.inland_dispatch_date == undefined)
						{
							var c = new Date();
							var curdate = moment(c).format('YYYY-MM-DD HH:mm:ss')
							//console.log(curdate);
							var gdate= moment(item.inland_grfl_arrival_date).format('YYYY-MM-DD HH:mm:ss');
							//console.log("gdate ", gdate)

							var date1 = new Date(gdate);
							var date2 = new Date(curdate);
							var timediff = date2 - date1;
							try{
								var tdays = Math.floor(timediff / (1000*60*60*24)); 
								//console.log("Totals days ", tdays)
								if(tdays >= 5 && tdays <= 14)
								{
									icd_detention_delay_firstarr.push(item);
								}
								if(tdays >= 15 && tdays <= 21)
								{
									icd_detention_delay_secondarr.push(item);
								}

								if(tdays > 21)
								{
									icd_detention_delay_thirdarr.push(item);
								}


								if(tdays < 5)
								{
									icd_detention_delay_lessfivearr.push(item);
								}
							}
							catch(e){
								
							}
							
						}
					});

					
					var countryoriginarr=[];
					records.map((item) => {
						countryoriginarr.push(item.country_code);
						if(item.last_status == 'High Seas')
						{
							highseaslistarr.push(item)
							if(item.sea_transit_delay == 1)
							{
								highseasdelaylistarr.push(item)
							}
							else{
								highseasnodelaylistarr.push(item);
							}
						}
						if(item.last_status == "Port In" || item.last_status == 'Arrived at Indian Port')
						{
							inportlistarr.push(item)
							if(item.port_delay == 1)
							{
								inportdelaylistarr.push(item)
							}
							else{
								inportnodelaylistarr.push(item)
							}
						}
						if(item.last_status == "Port Out")
						{
							intransittoicdlistarr.push(item)
							if(item.rail_transit_delay == 1)
							{
								intransittoicddelaylistarr.push(item)
							}
							else{
								intransittoicdnodelaylistarr.push(item)
							}
						}
						if(item.last_status == "In ICD Ghari" || item.last_status == "In ICD")
						{
							inicdlistarr.push(item)
							if(item.icd_delay == 1)
							{
								inicddelaylistarr.push(item);
							}
							else{
								inicdnodelaylistarr.push(item);
							}
						}
						if(item.last_status == "In Transit to MSIL")
						{
							transitmsillistarr.push(item)
						}
						if(item.last_status == "Empty Returned")
						{
							emptyarr.push(item)
						}

						/*BOE Filing Delay*/
						if(item.boe_filing_delay > 1)
						{
							boe_filing_delay_totallistarr.push(item);
						}

						if(item.boe_filing_delay > 1 && item.boe_filing_delay <= 3)
						{
							boe_filing_delay_lessthreelistarr.push(item);
						}

						if(item.boe_filing_delay > 3)
						{
							boe_filing_delay_greaterthreelistarr.push(item);
						}

						/*Overdue Deliveries from ICD*/

						if(item.inland_grfl_arrival_date && item.inland_dispatch_date == undefined)
						{
							var c = new Date();
							var curdate = moment(c).format('YYYY-MM-DD HH:mm:ss')
							//console.log(curdate);
							var gdate= moment(item.inland_grfl_arrival_date).format('YYYY-MM-DD HH:mm:ss');
							//console.log("gdate ", gdate)

							var date1 = new Date(gdate);
							var date2 = new Date(curdate);
							var timediff = date2 - date1;
							try{
								var tdays = Math.floor(timediff / (1000*60*60*24)); 
								//console.log("Totals days ", tdays)
								if(tdays >= 5 && tdays <= 14)
								{
									icd_detention_delay_firstlistarr.push(item);
								}
								if(tdays >= 15 && tdays <= 21)
								{
									icd_detention_delay_secondlistarr.push(item);
								}

								if(tdays > 21)
								{
									icd_detention_delay_thirdlistarr.push(item);
								}


								if(tdays < 5)
								{
									icd_detention_delay_lessfivelistarr.push(item);
								}
							}
							catch(e){
								
							}
							
						}
					});

					var allcountryorigins = countryoriginarr.filter( function onlyUnique(value, index, self) { 
						return self.indexOf(value) === index;
					} )
					//console.log("highseasarr ", records)
					//console.log("icd_detention_delay_lessfivearr ", icd_detention_delay_lessfivearr)
					this.setState({
						countryorigin:allcountryorigins,
						rowData:records,
						allRowData:records,
						totalrows:newArray,
						highseascount:highseaslistarr,

						highseasinfo:{
							"total":highseasarr,
							"delays":highseasdelayarr,
							"nodelays":highseasnodelayarr,
						},
						inportcount:inportarr,
						inportinfo:{
							"total":inportarr,
							"delays":inportdelayarr,
							"nodelays":inportnodelayarr,
						},
						intransittoicdcount:intransittoicdlistarr,
						transittoicdinfo:{
							"total":intransittoicdarr,
							"delays":intransittoicddelayarr,
							"nodelays":intransittoicdnodelayarr,
						},
						inicdcount:inicdarr,
						inicdinfo:{
							"total":inicdarr,
							"delays":inicddelayarr,
							"nodelays":inicdnodelayarr,
						},
						transitmsilcount:transitmsilarr,
						emptyreturned:emptylistarr,
						emptyreturnedlist:emptylistarr,
						highseaslistcount:highseaslistarr,
						highseaslistinfo:{
							"total":highseaslistarr,
							"delays":highseasdelaylistarr,
							"nodelays":highseasnodelaylistarr,
						},
						inportlistcount:inportlistarr,
						inportlistinfo:{
							"total":inportlistarr,
							"delays":inportdelaylistarr,
							"nodelays":inportnodelaylistarr,
						},
						intransittoicdlistcount:intransittoicdlistarr,
						transittoicdlistinfo:{
							"total":intransittoicdlistarr,
							"delays":intransittoicddelaylistarr,
							"nodelays":intransittoicdnodelaylistarr,
						},
						inicdlistcount:inicdlistarr,
						inicdlistinfo:{
							"total":inicdlistarr,
							"delays":inicddelaylistarr,
							"nodelays":inicdnodelaylistarr,
						},
						transitmsillistcount:transitmsillistarr,

						boe_filing_delay_total:boe_filing_delay_totalarr,
						boe_filing_delay_lessthree:boe_filing_delay_lessthreearr,
						boe_filing_delay_greaterthree:boe_filing_delay_greaterthreearr,
						icd_detention_delay_first:icd_detention_delay_firstarr,
						icd_detention_delay_second:icd_detention_delay_secondarr,
						icd_detention_delay_third:icd_detention_delay_thirdarr,

						boe_filing_delay_totallist:boe_filing_delay_totallistarr,
						boe_filing_delay_lessthreelist:boe_filing_delay_lessthreelistarr,
						boe_filing_delay_greaterthreelist:boe_filing_delay_greaterthreelistarr,
						icd_detention_delay_firstlist:icd_detention_delay_firstlistarr,
						icd_detention_delay_secondlist:icd_detention_delay_secondlistarr,
						icd_detention_delay_thirdlist:icd_detention_delay_thirdlistarr,
						loadshow : "show-n",
					    overly : "show-n"
					});
					//console.log("inportinfo ", this.state.boe_filing_delay_total.length)
				})
				.catch(function(error) {
					console.log(error);
				});
  
		    })
		    .catch(function(error) {
		    	console.log(error);
            });
	};
	
	


	
	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
	}
	onShowCommentsDiv = (e) =>{
		var reqparams = {
			consignment_code : e.container_no,
			dept_code : "LOG-SC",
		}
		redirectURL.post("/consignments/getCommentLogs",reqparams).then((response)=>{
			console.log(response.data);
			this.setState({
				commentsRowData : response.data.data
			})
		}).catch(function(error){
			console.log(error);
		})
		this.setState({
			sliderCommentTranslate:"slider-translate-60p",
			showDiv:'show-m',
			loadshow:'show-n',
			overly:'show-m',
		});
	}
	renderMap = () => {    	
		//loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMap");
		//window.initMap = this.initMap
	}
	
	initMap = () => {
    	//console.log(this.state.allmarkers);
    	var currentwindow;
    	var jsondata = this.state.mapinfo;
    	var locations = jsondata.points;
        var arr = jsondata.route;
		//console.log("Here",arr);
		if(arr.length > 0)
		{
			var lt=arr[0].lat;
			var ln=arr[0].lng;
		}
		else{
			//console.log("defTransitCoords ", JSON.parse(this.state.defTransitCoords));
			var lt=JSON.parse(this.state.defTransitCoords).lat;
			var ln=JSON.parse(this.state.defTransitCoords).lng;
		}
    	 var mapOptions = {
    			zoom: 7,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: true,
				fullscreenControl: true,
    	          labels:true,
    	          mapTypeControlOptions: {
    	            mapTypeIds: ['hybrid', 'roadmap'],
    	          },
    	          center: new window.google.maps.LatLng(lt,ln),
    	          mapTypeId: window.google.maps.MapTypeId.ROADMAP
    	        };
    	        
    	        var map = new window.google.maps.Map(document.getElementById('map'),
    	            mapOptions);

				var bounds = new window.google.maps.LatLngBounds();
				bounds.extend(new window.google.maps.LatLng(lt,ln));
    			 // Create our info window content
    			var currentinfowindow = null;
    	       var line=new window.google.maps.Polyline(
    	              {
    	                map:map,
    									strokeColor: '#157254',
    									strokeOpacity: 1.0,
    									strokeWeight: 2.5,
    	                		icons: [{
    	                          icon: {
    	                                  path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                  strokeColor:'#ff8c52',
    	                                  fillColor:'#ff8c52',
    	                                  fillOpacity:1,
    									  strokeWeight: 2
    	                                },
    	                          repeat:'100px',
    	                          path:[]
    	                       }]
    	                 });

			if(arr.length > 0)
			{	
    		  for (let i = 0; i < arr.length; i++) {
    				var path=line.getPath().getArray();
    					
    						let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
    						path.push(latLng);
    	          			line.setPath(path);
    					

    				  
    		  
    	          //Change line color based on map type
    	        window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
    	            if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
    	            {
    	                var line=new window.google.maps.Polyline(
    	                  {
    	                    map:map,
    	                    strokeColor: '#FFFFFF',
    	                    strokeOpacity: 1.0,
    	                    strokeWeight: 2.5,
    	                    icons: [{
    	                              icon: {
    	                                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                      strokeColor:'#ff8c52',
    	                                      fillColor:'#ff8c52',
    	                                      fillOpacity:1,
    	                                      strokeWeight: 2
    	                                    },
    	                              repeat:'100px',
    	                              path:[]
    	                           }]
    	                     });                
    	            }
    	            else
    	            {
    	                var line=new window.google.maps.Polyline(
    	                  {
    	                    map:map,
    	                    strokeColor: '#157254',
    	                    strokeOpacity: 1.0,
    	                    strokeWeight: 2.5,
    	                    icons: [{
    	                              icon: {
    	                                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                      strokeColor:'#ff8c52',
    	                                      fillColor:'#ff8c52',
    	                                      fillOpacity:1,
    	                                      strokeWeight: 2
    	                                    },
    	                              repeat:'100px',
    	                              path:[]
    	                           }]
    	                     });

    	            }

    	              /*for (i = 0; i < arr.length; i++) {
    	                    var path=line.getPath().getArray();
    	                    let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
    						path.push(latLng);
    	          			line.setPath(path);
    	                            //map.setCenter(latLng);
						  }
						*/
    	                            
    	             var x = map.getZoom();
    	             var c = map.getCenter();
    	             window.google.maps.event.trigger(map, 'resize');
    	             map.setZoom(x);
					 map.setCenter(c);
					 
					 var path=line.getPath().getArray();
					let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
					path.push(latLng);
					line.setPath(path);
				} );
				
				bounds.extend(new window.google.maps.LatLng(arr[i].lat,arr[i].lng));
    		  }

		  var infowindow = new window.google.maps.InfoWindow();
			var marker, l;
			  //console.log("locations.length ", locations.length)
			  var lastorigin='';
			if(locations.length > 0)
			{
				for (l = 0; l < locations.length; l++) {
					if(arr[0].lat != locations[l].coordinates.lat && arr[0].lng != locations[l].coordinates.lmg)
					{
						marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(locations[l].coordinates.lat, locations[l].coordinates.lng),
						icon:require('../../assets/icons/boat.png'),
						map: map,
						});

						window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
							return function() {

								var contentarr = []
								var headerTitle = locations[l].name+", "+locations[l].country;
								//var contentString = "<p class='maptitle'>"+"<B> "+locations[l].name+", "+locations[l].country+"</B></p>";
								var contentStringData='';
								if(locations[l].events.length > 0)
								{
									contentStringData = contentStringData+"<table class='mt-8p table table-border' style='background:#f5f5f5;border:1px solid #dee2e6'>";
									contentStringData = contentStringData + "<thead><tr><th>Date</th><th>Event</th><th>Transport Mode</th></tr></thead><tbody>";
									for(var e=0;e<locations[l].events.length;e++)
									{
										var typemode;
										if(locations[l].events[e].type == 1){
											typemode='Rail/Truck';
										}
										if(locations[l].events[e].type == 2){
											typemode='Air';
										}
										if(locations[l].events[e].type == 3){
											typemode='Sea';
										}
										if(locations[l].events[e].type == 4){
											typemode='Rail/Truck';
										}
										var d = locations[l].events[e].event_datetime_ist;
										var dat = d.split(" ");				
										
										contentStringData = contentStringData + "<tr><td class='custb'><span class='tbmap'><i class='icofont icofont-disc'></i> <span class='tdmapsp'>"+ dat[0];
										contentStringData = contentStringData + "</span></td><td>" + locations[l].events[e].description + "</span></td><td style='text-align: right'>" + typemode + "</span></td></tr>";
										
				
									}
									contentStringData = contentStringData+"</tbody></table>";
									
								}
								contentarr.push(contentStringData);
							//lastorigin=contentString;
							//console.log("1", headerTitle)
							var contentString = infoBox(marker.icon, headerTitle, contentarr, 'table')
				

							infowindow.setContent(contentString);
							currentwindow = infowindow;
							infowindow.open(map, marker);
							//console.log(marker.position.toJSON());
							//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
							}
						})(marker, l));
					}
					else{
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(locations[l].coordinates.lat, locations[l].coordinates.lng),
							icon:require('../../assets/icons/start_port.png'),
							map: map,
							});
	
							window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
								return function() {
									//var contentString = "<p class='maptitle'>"+"<B> "+locations[l].name+", "+locations[l].country+"</B></p>";
									var contentarr = []
									var headerTitle = locations[l].name+", "+locations[l].country;
									var contentStringData='';
									if(locations[l].events.length > 0)
									{
										contentStringData = contentStringData+"<table class='mt-8p table table-border' style='background:#f5f5f5;border:1px solid #dee2e6'>";
										contentStringData = contentStringData + "<thead><tr><th>Date</th><th>Event</th><th>Transport Mode</th></tr></thead><tbody>";
										for(var e=0;e<locations[l].events.length;e++)
										{
											var typemode;
											if(locations[l].events[e].type == 1){
												typemode='Rail/Truck';
											}
											if(locations[l].events[e].type == 2){
												typemode='Air';
											}
											if(locations[l].events[e].type == 3){
												typemode='Sea';
											}
											if(locations[l].events[e].type == 4){
												typemode='Rail/Truck';
											}
											var d = locations[l].events[e].event_datetime_ist;
											var dat = d.split(" ");											
											
											contentStringData = contentStringData + "<tr><td class='custb'><span class='tbmap'><i class='icofont icofont-disc'></i> <span class='tdmapsp'>"+ dat[0];
											contentStringData = contentStringData + "</span></td><td>" + locations[l].events[e].description + "</span></td><td style='text-align: right'>" + typemode + "</span></td></tr>";
	
										}
										contentStringData = contentStringData+"</tbody></table>";
										
									}
									contentarr.push(contentStringData);	
									//console.log("2", headerTitle)
									var contentString = infoBox(marker.icon, headerTitle, contentarr, 'table')
					
								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								//console.log(marker.position.toJSON());
								//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
								}
							})(marker, l));
					}
				}
			}
			if (jsondata.hasOwnProperty('last_known_location') == true && jsondata.last_known_location != null)
			{
		   	    marker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(jsondata.last_known_location.lat, jsondata.last_known_location.lng),
					icon:require('../../assets/icons/crane.png'),
					map: map,	
				 
			  });
			

		   window.google.maps.event.addListener(marker, 'click', (function(marker) {
		        return function() {
		        	//console.log("jsondata", jsondata);
					var contentarr = []
					var headerTitle = "Last known location";
					var contentStringData='';
					var typemode;
					if(jsondata.last_event.type == 1){
						typemode='Rail/Truck';
					}
					if(jsondata.last_event.type == 2){
						typemode='Air';
					}
					if(jsondata.last_event.type == 3){
						typemode='Sea';
					}
					if(jsondata.last_event.type == 4){
						typemode='Rail/Truck';
					}
					var d = jsondata.last_event.event_datetime_ist;
					var dat = d.split(" ");
					
					var contentStringData = contentStringData+"<table class='mt-8p table table-border' style='background:#f5f5f5;border:1px solid #dee2e6'>";
					contentStringData = contentStringData + "<thead><tr><th>Last Updated Date</th><th>Transport Mode</th></tr></thead><tbody>";
					
					contentStringData = contentStringData + "<tr><td class='custb'><span class='tbmap'><i class='icofont icofont-disc'></i> <span class='tdmapsp'>"+ jsondata.last_known_time;
					contentStringData = contentStringData + "</span></td><td style='text-align: right'>" + typemode + "</td></tr>";
					contentStringData = contentStringData+"</tbody></table>";	
					
					contentarr.push(contentStringData);	
					var contentString = infoBox(marker.icon, headerTitle, contentarr, 'table')
					

				  infowindow.setContent(contentString);
				  currentwindow = infowindow;
				  infowindow.open(map, marker);
				 // console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
				}
			})(marker));
			}
		}
		map.fitBounds(bounds);

    }



	
	onShowRouteMap(rownode){
		//console.log("rownode", rownode);
		if (googleAnalytics.page.enableGA) {
			let eventOptions = {
				"category": this.state.pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		if(rownode != 0)
		{
			var params = { 
				//container_no:"FSCU9842333"
				container_no:rownode.container_no
			}
			this.setState({
				loadshow:'show-m'
			})
			 redirectURL.post('/imports/containersearates',params)
			  .then((response) => {
			  
			   var records = JSON.parse(response.data.body);
				if(response.data.statusCode == 'Failure')
				{
					this.setState({
						show: true, 
						basicType:'danger', 
						basicTitle:"No data found",
							
					});
				}
				else{
					console.log(records);
					this.setState({
						mapinfo:records,
						sliderRouteTranslate:"slider-translate-75p",
						consignment_code:rownode.container_no,
						show: false,
						overly:'show-m'
					});
					//this.renderMap();
					
				}
				this.setState({
					loadshow:'show-n',
				})
			  /* this.setState({
					mapinfo:response.data,
					sliderRouteTranslate:"slider-translate-50p",
					consignment_code:rownode.truck_no
			   });
				   this.renderMap();
			
				*/
			  })
			  .catch(function (error) {
				console.log(error);
			  });
			  
		}
		
	}
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			showDiv:'show-n',
			sliderCommentTranslate:"",
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
	closeAlert = () => {
        this.setState({
			show: false,
			overly:'show-n'
        });
	}
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			sliderCommentTranslate:"",
			tabsliderTranslate:''
		});
		
	}
	compare_dates = (date1,date2) => {
		if (date1>date2) return 1; 
	 }


	getStatusText(status) {
		let consignmentStatus = null;
		switch(status) {
			case 1:
				//console.log("CASE 1 = ", status);
				consignmentStatus = this.state.statusTexts[status];
				break;
			case 2:
				consignmentStatus = this.state.statusTexts[status];
			case 3:
				consignmentStatus = this.state.statusTexts[status];
				break;
			case 4:
				consignmentStatus = this.state.statusTexts[status];
				break;
			case 5:
				consignmentStatus = this.state.statusTexts[status];
				break;
			default:
				console.log("CASE Default = ", status);
				consignmentStatus = "All"
				break;
		}

		//console.log("CASE consignmentStatus = ", consignmentStatus);
		
		return consignmentStatus;
	}
	logCounterClickGA(params) {
		if (googleAnalytics.page.enableGA) {
			let statusText = this.getStatusText(params);
			let eventOptions = {
				"category": this.state.pageTitle,
				"action": this.state.eventCounterAction,
				"label": statusText,
			}
			googleAnalytics.logEvent(eventOptions);
		}
	}

	logBoeFilingDelayCounterClickGA(params) {
		if (googleAnalytics.page.enableGA) {
			let statusText = "BOE Filing Delay";
			if (params == 1) {
				statusText = statusText + " < 3 Days";
			} else if (params == 2) {
				statusText = statusText + " > 3 Days";
			} else {
				statusText = statusText + " Total";
			}
			let eventOptions = {
				"category": this.state.pageTitle,
				"action": this.state.eventCounterAction,
				"label": statusText,
			}
			googleAnalytics.logEvent(eventOptions);
		}
	}

	logOverdueDeliveriesIcdCounterClickGA(params) {
		if (googleAnalytics.page.enableGA) {
			let statusText = "Overdue Deliveries from ICD";
			if (params == 1) {
				statusText = statusText + " 15 - 21 days";
			} else if (params == 2) {
				statusText = statusText + " > 21 days";
			} else {
				statusText = statusText + " 5 - 14 days";
			}
			let eventOptions = {
				"category": this.state.pageTitle,
				"action": this.state.eventCounterAction,
				"label": statusText,
			}
			googleAnalytics.logEvent(eventOptions);
		}
	}

	onClickCounterShowData(params){
		console.log("IN onClickCounterShowData, Params Here ", params);
		this.logCounterClickGA(params); // Log Counter Actions for Google Analytics
		if(params == 0)
		{
			this.setState({
				rowData:this.state.allRowData
			});
		}
		if(params == 1)
		{
			this.setState({
				rowData:this.state.highseaslistcount
			});
		}
		if(params == 2)
		{
			this.setState({
				rowData:this.state.inportlistcount
			});
		}
		if(params == 3)
		{
			this.setState({
				rowData:this.state.intransittoicdlistcount
			});
		}
		if(params == 4)
		{
			this.setState({
				rowData:this.state.inicdlistcount
			});
		}
		if(params == 5)
		{
			this.setState({
				rowData:this.state.transitmsillistcount
			});
		}
		if(params == 6)
		{
			this.setState({
				rowData:this.state.emptyreturned
			});
		}
		
	}

	onClickSubCounterShowData(params){
		console.log("IN onClickSubCounterShowData, Params Here ", params);
		this.logCounterClickGA(params); // Log Counter Actions for Google Analytics
		if(params == 0)
		{
			this.setState({
				rowData:this.state.allRowData
			});
		}
		if(params == 1)
		{
			this.setState({
				rowData:this.state.highseaslistinfo.delays
			});
		}
		if(params == 2)
		{
			this.setState({
				rowData:this.state.inportlistinfo.delays
			});
		}
		if(params == 3)
		{
			this.setState({
				rowData:this.state.transittoicdlistinfo.delays
			});
		}
		if(params == 4)
		{
			this.setState({
				rowData:this.state.inicdlistinfo.delays
			});
		}
		if(params == 5)
		{
			this.setState({
				rowData:this.state.transitmsillistcount
			});
		}
	}

	/*BOE Filling Delay Counters Data*/
	onClickBOECounterShowData(params){
		console.log("IN onClickBOECounterShowData, Params Here ", params);
		this.logBoeFilingDelayCounterClickGA(params); // Log Counter Actions for Google Analytics
		if(params == 0)
		{
			this.setState({
				rowData:this.state.boe_filing_delay_total
			});
		}
		if(params == 1)
		{
			this.setState({
				rowData:this.state.boe_filing_delay_lessthree
			});
		}
		if(params == 2)
		{
			this.setState({
				rowData:this.state.boe_filing_delay_greaterthree
			});
		}
		
	}
	/*Overdue Deliveries from ICD*/
	onClickICDCounterShowData(params){
		console.log("IN onClickICDCounterShowData, Params Here ", params);
		this.logOverdueDeliveriesIcdCounterClickGA(params);
		if(params == 0)
		{
			this.setState({
				rowData:this.state.icd_detention_delay_first
			});
		}
		if(params == 1)
		{
			this.setState({
				rowData:this.state.icd_detention_delay_second
			});
		}
		if(params == 2)
		{
			this.setState({
				rowData:this.state.icd_detention_delay_third
			});
		}
		
	}

	onClickForceCloseConsignment(params){
		console.log("Force Closed ",params.data.inland_destuffing_date)
		if(params.data.inland_destuffing_date == '' || params.data.inland_destuffing_date == undefined)
		{
			var destuffingDate = '';
			var disable = false;
		}
		else{
			var destuffingDate = getYYYYMMDD(params.data.inland_destuffing_date);
			var disable = true;
		}
		this.setState({
			open:true,
			empty_return_date:destuffingDate,
			forcedata:params.data,
			emptydisable:disable

		});
	}

	onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
	   this.setState({ open: false });
	 };

	 changeHandler(event){
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]:value
        });
    }
    handlerGateinDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	//var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	var startdate = moment(d).format("YYYY-MM-DD");
		var startdateseconds = moment(d).format("x");
		//console.log("startdate ", startdate);
		//console.log("startdateseconds ", startdateseconds);
    	this.setState({
    		msil_gatein_date:startdate
		});
		if(this.state.msil_gateout_date != '')
		{
			var getout = moment(this.state.msil_gateout_date).format("x");
			if(getout > startdateseconds)
			{

			}
			else{
				this.setState({
					show: true,
					basicTitle:"MSIL Gateout Date should be greater than MSIL Gatein Date",
					basicType:"danger",
					msil_gateout_date:'',
					msil_gatein_date:''
				});
			}
		}
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }

    handlerGateoutDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	//var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
		var startdate = moment(d).format("YYYY-MM-DD");
		var startdateseconds = moment(d).format("x");
		//console.log("startdate ", startdate);
		//console.log("startdateseconds ", startdateseconds);
    	this.setState({
    		msil_gateout_date:startdate
		});
		var forcedata = this.state.forcedata;
		if(forcedata.inland_destuffing_date != '')
		{
			if(this.state.empty_return_date != '')
			{
				var getin = moment(this.state.empty_return_date).format("x");
				if(getin >= startdateseconds)
				{

				}
				else{
					this.setState({
						show: true,
						basicTitle:"MSIL Gateout Date should be less than or equals to Emapty Return Date",
						basicType:"danger",
						msil_gateout_date:'',
						msil_gatein_date:''
					});
				}
			}
		}
		if(this.state.msil_gatein_date != '')
		{
			var getin = moment(this.state.msil_gatein_date).format("x");
			if(startdateseconds > getin)
			{

			}
			else{
				this.setState({
					show: true,
					basicTitle:"MSIL Gateout Date should be greater than MSIL Gatein Date",
					basicType:"danger",
					msil_gateout_date:'',
					msil_gatein_date:''
				});
			}
		}
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
    handlerEmptyReturnon = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	//var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
		var startdate = moment(d).format("YYYY-MM-DD");
		var startdateseconds = moment(d).format("x");
		//console.log("startdate ", startdate);
		//console.log("startdateseconds ", startdateseconds);
    	this.setState({
    		empty_return_date:startdate
		});
		var forcedata = this.state.forcedata;
		
		if(this.state.msil_gateout_date != '')
		{
			var getout = moment(this.state.msil_gateout_date).format("x");
			if(startdateseconds >= getout)
			{

			}
			else{
				this.setState({
					show: true,
					basicTitle:"Empty Return Date should be greater than MSIL Gateout Date",
					basicType:"danger",
					msil_gateout_date:''
				});
			}
		}


    	//let value = event.target.value;
        //this.setState({'startDate':value});
	}
	formHandler(event){
		event.preventDefault();
		var forcedata = this.state.forcedata;
		var fdata = {
			container_no:forcedata.container_no,
			msil_gatein_date:this.state.msil_gatein_date,
			msil_gateout_date:this.state.msil_gateout_date,
			empty_return_date:this.state.empty_return_date,
			forceclose_remarks:this.state.forceclose_remarks,
			userId : localStorage.getItem('userid'),
			forcecloseflag:1

		}
		redirectURL.post("imports/storeForceCloseData", fdata)
		.then((response) => {
			this.setState({
				show: true,
				basicTitle:"Success",
				basicType:"success",
				msil_gatein_date:'',
				msil_gateout_date:'',
				empty_return_date:'',
				forceclose_remarks:'',
				open:false
			});
		})
		.catch(function(e){
			console.log("Error ",e)
		});
		//console.log("Form data is ", fdata)
	}


	/*Filters*/

	getCountryListItems = country_code =>{
        this.setState(
	      { country_code },
	      () => console.log(this.state.country_code)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
	}


	selectCountryOptionsItems(){
        let items = [];     
		//items.push({value:'all',label:'All'}); 
		this.state.countryorigin.forEach(item =>{
			//console.log("item ",typeof item);
			if(typeof item != 'undefined')
			{
				items.push({value:item,label:item});
			}
			 
		}); 
        return items;
	}
	getPolListItems = pol =>{
        this.setState(
	      { pol },
	      () => console.log(this.state.pol)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
	}


	selectPolOptionsItems(){
        let items = [];     
		//items.push({value:'all',label:'All'}); 
		this.state.pollist.forEach(item =>{
			//console.log("pod ",item)
			items.push({value:item,label:item}); 
		}); 
        return items;
	}


	getPodListItems = pod =>{
        this.setState(
	      { pod },
	      () => console.log(this.state.pod)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
	}


	selectPodOptionsItems(){
        let items = [];     
		//items.push({value:'all',label:'All'}); 
		this.state.podlist.forEach(item =>{
			//console.log("pod ",item)
			items.push({value:item,label:item}); 
		});
        //items.push({value:"DEENDAYAL",label:"DEENDAYAL"}); 
        return items;
	}

	getCriteriaListItems = criteria =>{
        this.setState(
	      { criteria },
	      () => console.log(this.state.criteria)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
	}


	selectCriteriaOptionsItems(){
        let items = [
			//{value:'all',label:'All'},
			{value:'msil_initial_transit_eta',label:'Initial ETA'},
			{value:'msil_current_transit_eta',label:'Revised ETA'},
			{value:'etd_pol',label:'ETD PoL'},
			{value:'atd_pol',label:'ATD PoL'},
			{value:'eta_pod',label:'ETA PoD'},
			{value:'ata_pod',label:'ATA PoD'},
			{value:'smtp_handover_date',label:'SMTP Handover'},
			{value:'grfl_arrival_date',label:'ICD Arrival'},
			{value:'dispatch_date',label:'Dispatch from ICD/Port'},
			//{value:'msil_gate_in',label:'MSIL Gate In'},
			{value:'empty_return_date',label:'Empty Return On'}
			
		];      
		
        
        return items;
	}
	
	
	handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		var month = ("0" + (d.getMonth() + 1)).slice(-2); // Adding leading zero if month is less than 10
		var day = ("0" + d.getDate()).slice(-2); // Adding leading zero if day is less than 10
		var startdate = d.getFullYear() + "-" + month + "-" + day;
		if (this.state.endDate != undefined && this.state.endDate != "") {
			var startDate = new Date(startdate);
			var endDate = new Date(this.state.endDate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			if (startTime > endTime) {
				// console.log('inside fromdate')
				this.setState({
					show: true,
					basicTitle: 'To Date should be greater than From Date',
					basicType: "danger",
					loadshow: 'show-n'
				});
			} else {
				// console.log('outside enddate')
				this.setState({
					startDate: startdate
				});
			}
		}
		 else {
			this.setState({
				startDate: startdate,
			});
		}
    }

    handlerEndDateTime  = (event) => {
    	var d = new Date(event._d);
		var month = ("0" + (d.getMonth() + 1)).slice(-2); // Adding leading zero if month is less than 10
		var day = ("0" + d.getDate()).slice(-2); // Adding leading zero if day is less than 10
		var edate = d.getFullYear() + "-" + month + "-" + day;
		if (this.state.startDate != undefined && this.state.startDate != "") {
			var startDate = new Date(this.state.startDate);
			var endDate = new Date(edate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			if (startTime > endTime) {
				// console.log('inside')
				this.setState({
					show: true,
					basicTitle: 'To Date should be greater than From Date',
					basicType: "danger",
					loadshow: 'show-n'
				});
			} else {
				// console.log('outside')
				this.setState({
					endDate: edate
				});
			}
		} 
		else {
			this.setState({
				endDate: edate
			});
		}
	}

	onClickGetData = (event) =>{
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Filters",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
				loadshow : "show-m",
				overly : "show-m"
		});
		var eror=0;
		// console.log("Start Date: ", this.state.startDate);
		// console.log("End Date: ", this.state.endDate);
		// console.log("Criteria: ", this.state.criteria);
		if(this.state.criteria != null && this.state.criteria != "")
		{
			// console.log("Inside If block criteria")
			// this.setState({
			// 	errStyCriteria:'errorStyles',
			// 	show: true, 
			// 	basicType:'danger', 
			// 	basicTitle:"Criteria should not be null",
			// });
			// eror=1;
			// var criteria = {value:"all", label:"All"}
			var criteria = this.state.criteria
			if(this.state.startDate == '')
			{
				this.setState({
					errStyStartDate:'errorStyles',
					show: true, 
					basicType:'danger', 
					basicTitle:"From Date should not be null",
				});
				
				eror=1;
			}
			else{
				this.setState({
					errStyStartDate:''
				});
			}

			if(this.state.endDate == '')
			{
				this.setState({
					errStyEndDate:'errorStyles',
					show: true, 
					basicType:'danger', 
					basicTitle:"To Date should not be null",
				});
				eror=1;
				
			}
			else{
				this.setState({
					errStyEndDate:''
				});
			}
			
		}
		// else{
		// 	this.setState({
		// 		errStyCriteria:''
		// 	});
		// 	var criteria = this.state.criteria
		// }


		if(this.state.startDate != '' || this.state.endDate != '')
		{
			if(this.state.criteria == null || this.state.criteria == "")
			{
				// console.log("2. Inside If block criteria")
				this.setState({
					errStyCriteria:'errorStyles',
					show: true, 
					basicType:'danger', 
					basicTitle:"Criteria should not be null",
				});
				eror=1;
			}
		}

		if(this.state.pod == null || this.state.pod == "")
		{
			// this.setState({
			// 	errStyPod:'errorStyles',
			// 	show: true, 
			// 	basicType:'danger', 
			// 	basicTitle:"POD should not be null",
			// });
			// eror=1;
			var pod = [{value:"all", label:"All"}]
		}
		else{
			this.setState({
				errStyPod:''
			});
			var pod = this.state.pod
		}

		// if(this.state.pol == null || this.state.pol == "")
		// {
		// 	// this.setState({
		// 	// 	errStyPol:'errorStyles',
		// 	// 	show: true, 
		// 	// 	basicType:'danger', 
		// 	// 	basicTitle:"POL should not be null",
		// 	// });
		// 	// eror=1;
		// 	var pol = [{value:"all", label:"All"}]
		// }
		// else{
		// 	this.setState({
		// 		errStyPol:''
		// 	});
		// 	var pol = this.state.pol
		// }
		
		if(this.state.country_code == null || this.state.country_code == "")
		{
			// this.setState({
			// 	errStyPol:'errorStyles',
			// 	show: true, 
			// 	basicType:'danger', 
			// 	basicTitle:"POL should not be null",
			// });
			// eror=1;
			var country_code = [{value:"all", label:"All"}]
		}
		else{
			this.setState({
				errStyPol:''
			});
			var country_code = this.state.country_code
		}
		var sDate = this.state.startDate;
		var eDate = this.state.endDate;
		console.log(sDate,eDate,"sDate,eDate");
		// if(sDate != "" && eDate != ""){
		// if(sDate.split("-")[2].length == 4){
		// sDate = sDate.split("-")[2]+"-"+sDate.split("-")[1]+"-"+sDate.split("-")[0]
		// }
		// if(eDate.split("-")[2].length == 4){
		// eDate = eDate.split("-")[2]+"-"+eDate.split("-")[1]+"-"+eDate.split("-")[0]
		// }

		// sDate = moment(new Date(sDate)).format("Y-MM-DD");
		// eDate = moment(new Date(eDate)).format("Y-MM-DD");
		this.setState({
			startDate:moment(sDate),
			endDate:moment(eDate),
			loadshow:'show-m'
		});
		// }
		// console.log("Error Code: ", eror);
		if(eror == 0)
		{
			var fdata ={
				pod:pod,
				//pol:pol,
				country_code:country_code,
				criteria:criteria,
				startDate:sDate,
				endDate:eDate,
				page:"filter"
	
			}
			console.log(fdata);
			redirectURL.post("/imports/flterdeliveredconsignments",fdata)
			.then((response) =>{
				//console.log("Response ", response.data)
				var recordsarr = response.data;
				if(recordsarr.length > 0)
				{
					var containerNumbersFromImportsConsignments = [];
					var records = [];
					recordsarr.map(obj =>{
			
						containerNumbersFromImportsConsignments.push(obj.container_no)
						records.push(obj);
						//}
					});
					let queryByContainerNumbers = {
						"container_no": { $in: containerNumbersFromImportsConsignments}
					};
					//fetch data from inlandcontainerimports
					redirectURL.post('/consignments/getinlandcontainerimportstrackingdetails', {"queryByContainerNumbers":queryByContainerNumbers})
					.then((inlandResponse) => {
						var inlandRecords = inlandResponse.data;
						
						//console.log("IN componentDidMount inlandRecords = ", inlandRecords);
						const inlandRecordsRewritten = inlandRecords.map(obj => {
							// prefix keys of inlandRecords to avoid any overwrite to consignment records
							Object.entries(obj).forEach(
								([key, value]) => {
									let newKey = 'inland_'+key;
										obj[newKey] = value;
										//console.log("IN componentDidMount MAPPING obj INNER = ", key, newKey, obj);
										delete(obj[key]) ; // remove original key after renaming
								}
							);

							//console.log("IN componentDidMount MAPPING obj = ", obj);
							return obj;

						});

						// Need a blank object with new keys to merge assign null values to grid columns
						// for container numbers found in importsconsignments but not found a match in inlandcontainerimports
						//console.log("inland",inlandRecordsRewritten[0])
						const inlandRecordsRewrittenBlank = Object.entries(inlandRecordsRewritten[0]).forEach(
							([key, value]) => {
								let blankObj = {};
								let newKey = 'inland_'+key;
								blankObj[newKey] = null; // create a blank object with same new keys
							}
						);
						//console.log("IN componentDidMount MAPPED inlandRecords = ", inlandRecords);
						//console.log("IN componentDidMount MAPPED inlandRecordsRewrite = ", inlandRecordsRewrite);
						// merge both results to prepare grid columns
						for (let i = 0; i < records.length; i++) {
							for (let j = 0; j < inlandRecordsRewritten.length; j++) {
								if ((records[i].container_no === inlandRecordsRewritten[j].inland_container_no) && 
							(records[i].hbl_no === inlandRecordsRewritten[j].inland_bill_of_lading)) 
							{
								records[i] = Object.assign(records[i], inlandRecordsRewritten[j]);
							} else {
									// assign null values for no match
									records[i] = Object.assign(records[i], inlandRecordsRewrittenBlank);
								}
							}
						}
						//console.log("IN componentDidMount MOD records = ", records);
						var highseasarr=[];
						var inportarr=[];
						var intransittoicdarr=[];
						var inicdarr=[];
						var transitmsilarr=[];

						var highseaslistarr=[];
						var inportlistarr=[];
						var intransittoicdlistarr=[];
						var inicdlistarr=[];
						var transitmsillistarr=[];

						

						var highseasdelayarr=[];
						var highseasnodelayarr=[];
						var inportdelayarr = [];
						var inportnodelayarr = [];
						var intransittoicddelayarr = [];
						var intransittoicdnodelayarr = [];
						var inicddelayarr = [];
						var inicdnodelayarr = [];
						var transitmsildelayarr = [];
						var transitmsilnodelayarr = [];


						var highseasdelaylistarr=[];
						var highseasnodelaylistarr=[];
						var inportdelaylistarr = [];
						var inportnodelaylistarr = [];
						var intransittoicddelaylistarr = [];
						var intransittoicdnodelaylistarr = [];
						var inicddelaylistarr = [];
						var inicdnodelaylistarr = [];
						var transitmsildelaylistarr = [];
						var transitmsilnodelaylistarr = [];

						var boe_filing_delay_totalarr = [];
						var boe_filing_delay_lessthreearr= [] ;
						var boe_filing_delay_greaterthreearr = [];
						var icd_detention_delay_firstarr = [];
						var icd_detention_delay_secondarr = [];
						var icd_detention_delay_thirdarr = [];
						var icd_detention_delay_lessfivearr = [];


						var boe_filing_delay_totallistarr = [];
						var boe_filing_delay_lessthreelistarr= [] ;
						var boe_filing_delay_greaterthreelistarr = [];
						var icd_detention_delay_firstlistarr = [];
						var icd_detention_delay_secondlistarr = [];
						var icd_detention_delay_thirdlistarr = [];
						var icd_detention_delay_lessfivelistarr = [];

						var emptyarr=[];
						var emptylistarr=[];
						let newArray = []; 
				
						// Declare an empty object 
						let uniqueObject = {}; 
						var objTitle;
						// Loop for the array elements 
						for (let i in records) { 
				
							// Extract the title 
							objTitle = records[i]['container_no']; 
				
							// Use the title as the index 
							uniqueObject[objTitle] = records[i]; 
						} 
						
						// Loop to push unique object into array 
						for (var a in uniqueObject) { 
							newArray.push(uniqueObject[a]); 
						} 
						
						//console.log("newArray ", newArray)
						//console.log("newArray ", newArray.length)
						newArray.map((item) => {
							//console.log("item.last_status ", item.last_status)
							if(item.last_status == 'High Seas')
							{
								highseasarr.push(item)
								if(item.sea_transit_delay == 1)
								{
									highseasdelayarr.push(item)
								}
								else{
									highseasnodelayarr.push(item);
								}
							}
							if(item.last_status == "Port In" || item.last_status == 'Arrived at Indian Port')
							{
								inportarr.push(item)
								if(item.port_delay == 1)
								{
									inportdelayarr.push(item)
								}
								else{
									inportnodelayarr.push(item)
								}
							}
							if(item.last_status == "Port Out")
							{
								intransittoicdarr.push(item)
								if(item.rail_transit_delay == 1)
								{
									intransittoicddelayarr.push(item)
								}
								else{
									intransittoicdnodelayarr.push(item)
								}
							}
							if(item.last_status == "In ICD Ghari" || item.last_status == "In ICD")
							{
								inicdarr.push(item)
								if(item.icd_delay == 1)
								{
									inicddelayarr.push(item);
								}
								else{
									inicdnodelayarr.push(item);
								}
							}
							if(item.last_status == "In Transit to MSIL")
							{
								transitmsilarr.push(item)
							}
							//emptyreturned

							if(item.last_status == "Empty Returned")
							{
								emptylistarr.push(item)
							}

							/*BOE Filing Delay*/
							if(item.boe_filing_delay > 1)
							{
								boe_filing_delay_totalarr.push(item);
							}

							if(item.boe_filing_delay > 1 && item.boe_filing_delay <= 3)
							{
								boe_filing_delay_lessthreearr.push(item);
							}

							if(item.boe_filing_delay > 3)
							{
								boe_filing_delay_greaterthreearr.push(item);
							}

							/*Overdue Deliveries from ICD*/

							if(item.inland_grfl_arrival_date && item.inland_dispatch_date == undefined)
							{
								var c = new Date();
								var curdate = moment(c).format('YYYY-MM-DD HH:mm:ss')
								//console.log(curdate);
								var gdate= moment(item.inland_grfl_arrival_date).format('YYYY-MM-DD HH:mm:ss');
								//console.log("gdate ", gdate)

								var date1 = new Date(gdate);
								var date2 = new Date(curdate);
								var timediff = date2 - date1;
								try{
									var tdays = Math.floor(timediff / (1000*60*60*24)); 
									//console.log("Totals days ", tdays)
									if(tdays >= 5 && tdays <= 14)
									{
										icd_detention_delay_firstarr.push(item);
									}
									if(tdays >= 15 && tdays <= 21)
									{
										icd_detention_delay_secondarr.push(item);
									}

									if(tdays > 21)
									{
										icd_detention_delay_thirdarr.push(item);
									}


									if(tdays < 5)
									{
										icd_detention_delay_lessfivearr.push(item);
									}
								}
								catch(e){
									
								}
								
							}
						});
						//console.log('emptylistarr ',emptylistarr)
						//console.log("records filter", records)

						records.map((item) => {
							if(item.last_status == 'High Seas')
							{
								highseaslistarr.push(item)
								if(item.sea_transit_delay == 1)
								{
									highseasdelaylistarr.push(item)
								}
								else{
									highseasnodelaylistarr.push(item);
								}
							}
							if(item.last_status == "Port In" || item.last_status == 'Arrived at Indian Port')
							{
								inportlistarr.push(item)
								if(item.port_delay == 1)
								{
									inportdelaylistarr.push(item)
								}
								else{
									inportnodelaylistarr.push(item)
								}
							}
							if(item.last_status == "Port Out")
							{
								intransittoicdlistarr.push(item)
								if(item.rail_transit_delay == 1)
								{
									intransittoicddelaylistarr.push(item)
								}
								else{
									intransittoicdnodelaylistarr.push(item)
								}
							}
							if(item.last_status == "In ICD Ghari" || item.last_status == "In ICD")
							{
								inicdlistarr.push(item)
								if(item.icd_delay == 1)
								{
									inicddelaylistarr.push(item);
								}
								else{
									inicdnodelaylistarr.push(item);
								}
							}
							if(item.last_status == "In Transit to MSIL")
							{
								transitmsillistarr.push(item)
							}
							if(item.last_status == "Empty Returned")
							{
								emptyarr.push(item)
							}

							/*BOE Filing Delay*/
							if(item.boe_filing_delay > 1)
							{
								boe_filing_delay_totallistarr.push(item);
							}

							if(item.boe_filing_delay > 1 && item.boe_filing_delay <= 3)
							{
								boe_filing_delay_lessthreelistarr.push(item);
							}

							if(item.boe_filing_delay > 3)
							{
								boe_filing_delay_greaterthreelistarr.push(item);
							}

							/*Overdue Deliveries from ICD*/

							if(item.inland_grfl_arrival_date && item.inland_dispatch_date == undefined)
							{
								var c = new Date();
								var curdate = moment(c).format('YYYY-MM-DD HH:mm:ss')
								//console.log(curdate);
								var gdate= moment(item.inland_grfl_arrival_date).format('YYYY-MM-DD HH:mm:ss');
								//console.log("gdate ", gdate)

								var date1 = new Date(gdate);
								var date2 = new Date(curdate);
								var timediff = date2 - date1;
								try{
									var tdays = Math.floor(timediff / (1000*60*60*24)); 
									//console.log("Totals days ", tdays)
									if(tdays >= 5 && tdays <= 14)
									{
										icd_detention_delay_firstlistarr.push(item);
									}
									if(tdays >= 15 && tdays <= 21)
									{
										icd_detention_delay_secondlistarr.push(item);
									}

									if(tdays > 21)
									{
										icd_detention_delay_thirdlistarr.push(item);
									}


									if(tdays < 5)
									{
										icd_detention_delay_lessfivelistarr.push(item);
									}
								}
								catch(e){
									
								}
								
							}
						});


						//console.log("emptylistarr ", emptylistarr)
						//console.log("icd_detention_delay_lessfivearr ", icd_detention_delay_lessfivearr)
						this.setState({
							loadshow : "show-n",
					        overly : "show-n",
							rowData:records,
							allRowData:records,
							totalrows:newArray,
							highseascount:highseaslistarr,

							highseasinfo:{
								"total":highseasarr,
								"delays":highseasdelayarr,
								"nodelays":highseasnodelayarr,
							},
							inportcount:inportarr,
							inportinfo:{
								"total":inportarr,
								"delays":inportdelayarr,
								"nodelays":inportnodelayarr,
							},
							intransittoicdcount:intransittoicdlistarr,
							transittoicdinfo:{
								"total":intransittoicdarr,
								"delays":intransittoicddelayarr,
								"nodelays":intransittoicdnodelayarr,
							},
							inicdcount:inicdarr,
							inicdinfo:{
								"total":inicdarr,
								"delays":inicddelayarr,
								"nodelays":inicdnodelayarr,
							},
							transitmsilcount:transitmsilarr,
							emptyreturned:emptylistarr,
							emptyreturnedlist:emptylistarr,
							highseaslistcount:highseaslistarr,
							highseaslistinfo:{
								"total":highseaslistarr,
								"delays":highseasdelaylistarr,
								"nodelays":highseasnodelaylistarr,
							},
							inportlistcount:inportlistarr,
							inportlistinfo:{
								"total":inportlistarr,
								"delays":inportdelaylistarr,
								"nodelays":inportnodelaylistarr,
							},
							intransittoicdlistcount:intransittoicdlistarr,
							transittoicdlistinfo:{
								"total":intransittoicdlistarr,
								"delays":intransittoicddelaylistarr,
								"nodelays":intransittoicdnodelaylistarr,
							},
							inicdlistcount:inicdlistarr,
							inicdlistinfo:{
								"total":inicdlistarr,
								"delays":inicddelaylistarr,
								"nodelays":inicdnodelaylistarr,
							},
							transitmsillistcount:transitmsillistarr,

							boe_filing_delay_total:boe_filing_delay_totalarr,
							boe_filing_delay_lessthree:boe_filing_delay_lessthreearr,
							boe_filing_delay_greaterthree:boe_filing_delay_greaterthreearr,
							icd_detention_delay_first:icd_detention_delay_firstarr,
							icd_detention_delay_second:icd_detention_delay_secondarr,
							icd_detention_delay_third:icd_detention_delay_thirdarr,

							boe_filing_delay_totallist:boe_filing_delay_totallistarr,
							boe_filing_delay_lessthreelist:boe_filing_delay_lessthreelistarr,
							boe_filing_delay_greaterthreelist:boe_filing_delay_greaterthreelistarr,
							icd_detention_delay_firstlist:icd_detention_delay_firstlistarr,
							icd_detention_delay_secondlist:icd_detention_delay_secondlistarr,
							icd_detention_delay_thirdlist:icd_detention_delay_thirdlistarr
						});
					
				
				
						//console.log("inportinfo ", this.state.boe_filing_delay_total.length)
					})
					.catch(function(error) {
						console.log(error);
					});
				}
				else{
					this.setState({
						loadshow : "show-n",
					    overly : "show-n",
						rowData:[],
						allRowData:[],
						totalrows:[],
						highseascount:0,

						highseasinfo:{
							"total":0,
							"delays":0,
							"nodelays":0,
						},
						inportcount:0,
						inportinfo:{
							"total":0,
							"delays":0,
							"nodelays":0,
						},
						intransittoicdcount:0,
						transittoicdinfo:{
							"total":0,
							"delays":0,
							"nodelays":0,
						},
						inicdcount:0,
						inicdinfo:{
							"total":0,
							"delays":0,
							"nodelays":0,
						},
						transitmsilcount:0,

						highseaslistcount:0,
						highseaslistinfo:{
							"total":0,
							"delays":0,
							"nodelays":0,
						},
						inportlistcount:0,
						inportlistinfo:{
							"total":0,
							"delays":0,
							"nodelays":0,
						},
						intransittoicdlistcount:0,
						transittoicdlistinfo:{
							"total":0,
							"delays":0,
							"nodelays":0,
						},
						inicdlistcount:0,
						inicdlistinfo:{
							"total":0,
							"delays":0,
							"nodelays":0,
						},
						transitmsillistcount:0,
						emptyreturned:0,
						emptyreturnedlist:0,
						boe_filing_delay_total:0,
						boe_filing_delay_lessthree:0,
						boe_filing_delay_greaterthree:0,
						icd_detention_delay_first:0,
						icd_detention_delay_second:0,
						icd_detention_delay_third:0,

						boe_filing_delay_totallist:0,
						boe_filing_delay_lessthreelist:0,
						boe_filing_delay_greaterthreelist:0,
						icd_detention_delay_firstlist:0,
						icd_detention_delay_secondlist:0,
						icd_detention_delay_thirdlist:0
					});
				}

			})
			.catch(function(e){
				console.log("Error ",e)
			})
		}
		else{
			this.setState({
				show: true, 
				loadshow : "show-n",
				overly : "show-n",
				basicType:'danger', 
				basicTitle:"Fields are mandatory",
			})
		}
	}
	

	onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		
		let screenpage = 'delivered containers';
		
		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
		try{


			if(this.state.usergridstate.length > 0)
			{
				var windowstates = this.state.usergridstate;
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
			}
		}
		catch(e){}
	}

	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};

	render(){
		const modalStyles  = {
			width:'500px !important',
			}
	const { open } = this.state;
	var userroles = localStorage.getItem('roles')
	let hideppc=(userroles.indexOf("sc_ppc") >= 0) ? true : false;

		const columnwithDefs = [
			
			{
				headerName: "",
				headerClass:["cellstylegrid","cellTopHeader"],			
				children: [
					{
						headerName: "",
						//headerClass:"cellstylegrid",
						field: "map_available",
						width: 50,
						pinned: 'left',
						
						cellRendererSelector:function(params){
							var map_available =0
							if(params.data.hasOwnProperty('map_available'))
							{
								if (params.data.map_available == 1)
								{
									map_available=1
								}
							}

							if(params.data.hasOwnProperty('rail_map_available'))
							{
								if (params.data.rail_map_available == 1)
								{
									map_available=1
								}
							}
							
							if(params.data.hasOwnProperty('inland_dispatch_date'))
							{
								map_available=1
							}

							if (map_available == 1)
							{
								return { component: 'consignmentActions'}
							}
							else
							{
								return { component: 'consignmentNoActions'}	
							}
						},
						filter: true,
						resizable: true,
						// cellStyle:function(params){
						// 	return {background:"rgb(241, 251, 255)",color:"#333"}
						// }
						
						//cellClass: function(params) { return 'cellStyleGrid'; }
					},
					{
						headerName: "Actions",
						field: "_id",
						colId: "_id",
						width: 40,
						pinned: 'left',
						cellRenderer:'commentActions',
						filter: false,resizable: true
						
					},
					{
						headerName: "",
						//headerClass:"cellstylegrid",
						field: "sea_transit_delay",
						width: 40,
						pinned: 'left',
						cellRendererSelector:function(params){
							//console.log("params ", params)
							return { component: 'ConsignmentImportsEventsAction'};
							
						},
						
						filter: true,resizable: true,
						// cellStyle:function(params){
						// 	return {background:"rgb(241, 251, 255)",color:"#333"}
						// }
					},
					
					{
						headerName: "",
						//headerClass:"cellstylegrid",
						field: "port_delay",
						width: 40,
						pinned: 'left',
						cellRendererSelector:function(params){
							return { component: 'importsPortAction'};
						},
						filter: true,resizable: true,
						// cellStyle:function(params){
						// 	return {background:"rgb(241, 251, 255)",color:"#333"}
						// }
					},
					{
						headerName: "",
						//headerClass:"cellstylegrid",
						field: "rail_transit_delay",
						width: 40,
						pinned: 'left',
						cellRendererSelector:function(params){
							return { component: 'importsRailAction'};
						},
						filter: true,resizable: true,
						// cellStyle:function(params){
						// 	return {background:"rgb(241, 251, 255)",color:"#333"}
						// }
					},
					{
						headerName: "",
						//headerClass:"cellstylegrid",
						field: "icd_delay",
						width: 40,
						pinned: 'left',
						cellRendererSelector:function(params){
							return { component: 'importsWarehouseAction'};
						},
						filter: true,resizable: true,
						// cellStyle:function(params){
						// 	return {background:"rgb(241, 251, 255)",color:"#333"}
						// }
					},
					
					{
						headerName: "",
						//headerClass:"cellstylegrid",
						field: "msil_transit_delay",
						width: 40,
						pinned: 'left',
						cellRendererSelector:function(params){
							return { component: 'importsMsilAction'};
						},
						filter: true,resizable: true,
						// cellStyle:function(params){
						// 	return {background:"rgb(241, 251, 255)",color:"#333"}
						// }
					},
					
					/*{
						headerName: "",
						headerClass:"cellstylegrid",
						field: "_id",
						width: 40,
						pinned: 'left',
						cellRendererSelector:function(params){
							return { component: 'importsEmptyContainerAction'};
						},
						filter: true,resizable: true,
						cellStyle:function(params){
							return {background:"rgb(241, 251, 255)",color:"#333"}
						}
					},*/
					{
						headerName: "Container No",
						//headerClass:"cellstylegrid",
						field: "container_no",
						width: 120,
						pinned: 'left',
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						//cellClass:'cellstylegrid'
					},
					{ 
						headerName: "Initial ETA ICD/MSIL",
						//headerClass:"cellstylegrid",
						field: "msil_initial_transit_eta",
						width: 180,
						pinned: 'left',
						editable:false,
						resizable: true,
						hide:hideppc,
						valueGetter:function(params){
							//console.log("params ", params);
							//console.log("s1", params.data.container_no)
							if (params.data.discharge_port == "Nhava Sheva")
							{
							
								return getHyphenDDMMMYYYY(params.data.msil_initial_transit_eta);
							}
							else
							{
								
								return getHyphenDDMMMYYYY(params.data.icd_initial_transit_eta);
								
							}

						},
						
						comparator: dateComparator1,
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			// if(Number(filterValue.split("-")[2]) < 10 ){
						// 			// 	filterValue = filterValue.split("-")[0]+"-"+filterValue.split("-")[1]+"-"+"0"+filterValue.split("-")[2];
						// 			// }
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },

					},
					{
						headerName: "Revised ETA ICD/MSIL",
						//headerClass:"cellstylegrid",
						field: "msil_current_transit_eta",
						width: 180,
						pinned: 'left',
						editable:false,
						hide:hideppc,
						resizable: true,
						// cellClass:function(params){
						// 	var g1 = getDDMMYYYY(params.data.msil_current_transit_eta);
						// 	var g2 = getDDMMYYYY(params.data.msil_initial_transit_eta);
						// 	var s1 = g1.split("-");
						// 	var s2 = g2.split("-");
						// 	var gdate1 = new Date(s1[2],s1[1], s1[0]);
						// 	var gdate2 = new Date(s2[2],s2[1], s2[0]);
						// 	//console.log("gdate1 ", gdate1.getTime())
						// 	//console.log(g1);
						// 	//console.log("gdate2 ", gdate2.getTime())
						// 	//console.log(g2);
							
						// 	if(gdate1.getTime() > gdate2.getTime())
						// 	{
						// 		return 'bgColorDangerMedium'
						// 	}

						// 	if(gdate1.getTime() < gdate2.getTime())
						// 	{
						// 		return 'bgColorSuccessLight'
						// 	}
						// 	// else{
						// 	// 	return 'cellstylegrid'
						// 	// }
							
						// },
						// valueGetter:function(params){
						// 	//console.log("params ", params);
						// 	if (params.data.msil_current_transit_eta != null)
						// 	{
						// 		return getDDMMYYYY(params.data.msil_current_transit_eta);
						// 	}
						// 	else
						// 	{
						// 		return "NA"
						// 	}

						// }

						valueGetter:function(params){
							//console.log("params ", params);
							//console.log("s1")
							if (params.data.discharge_port != "Nhava Sheva")
							{
								//console.log("s2", typeof params.data.inland_grfl_arrival_date)
								if (params.data.inland_grfl_arrival_date != "" && typeof params.data.inland_grfl_arrival_date != "undefined")
								{
									//console.log("s3", params.data.inland_grfl_arrival_date)
									return getHyphenDDMMMYYYY(new Date(params.data.inland_grfl_arrival_date));
								}
								else
								{
									//console.log("s4")
									return getHyphenDDMMMYYYY(params.data.icd_current_transit_eta);
								}
								//return params.data.msil_initial_transit_eta;
							}
							else
							{
								//console.log("s4")
								return getHyphenDDMMMYYYY(params.data.msil_current_transit_eta);
								
							}

						},
						
						comparator: dateComparator1,
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
						cellClass:function(params){
							//console.log("params.data.inland_grfl_arrival_date ", params.data.container_no+"=="+params.data.msil_current_transit_eta)
							var edate='';
							
							if (params.data.discharge_port != "Nhava Sheva")
							{
								
								if (params.data.inland_grfl_arrival_date != "" && typeof params.data.inland_grfl_arrival_date != "undefined")
								{
									
									edate=moment.parseZone(params.data.inland_grfl_arrival_date).format("x");
								
								}
								else
								{
									
									if(params.data.icd_current_transit_eta != '')
									{
										
										edate=moment.parseZone(params.data.icd_current_transit_eta).format("x");
								
									}
									else
									{
										
										edate=''
									}
								}
								//return params.data.msil_initial_transit_eta;
							}
							else
							{
								
								if(params.data.msil_current_transit_eta != '')
								{
									
									edate=moment.parseZone(params.data.msil_current_transit_eta).format("x");
								
								}
								else
								{
									
									edate='';
								}
							}
							var sdate;
							if (params.data.discharge_port == "Nhava Sheva")
							{
								sdate=moment.parseZone(params.data.msil_initial_transit_eta).format("x");
								//return params.data.msil_initial_transit_eta;
							}
							else
							{
								sdate=moment.parseZone(params.data.icd_initial_transit_eta).format("x");
								
							}
							if(edate != '')
							{
								var g1 = edate;
								var g2 = sdate;
								//console.log("g1", g1);
								//console.log("g2", g2);
								if(g1 > g2)
								{
									return 'bgColorDangerMedium'
								}

								if(g1 < g2)
								{
									return 'bgColorSuccessLight'
								}
							}
							else{
								return '';
							}
						}

					},
					{ 
						headerName: "Initial ETA-MSIL",
						//headerClass:"cellstylegrid",
						field: "msil_initial_transit_eta",
						width: 120,
						editable:false,
						resizable: true,
						hide:hideppc,
						//cellClass:'cellstylegrid',
						valueGetter:function(params){
							//console.log("params ", params);
							if (params.data.msil_initial_transit_eta != null)
							{
								return getHyphenDDMMMYYYY(params.data.msil_initial_transit_eta);
								//return params.data.msil_initial_transit_eta;
							}
							else
							{
								return "NA"
							}

						},
						
						comparator: dateComparator1,
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
					{
						headerName: "Revised ETA-MSIL",
						//headerClass:"cellstylegrid",
						field: "msil_current_transit_eta",
						width: 120,
						editable:false,
						hide:hideppc,
						resizable: true,
						
						valueGetter:function(params){
							//console.log("params ", params);
							if (params.data.msil_current_transit_eta != null)
							{
								return getHyphenDDMMMYYYY(params.data.msil_current_transit_eta);
							}
							else
							{
								return "NA"
							}

						},
						
						comparator: dateComparator1,
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
					// {
					// 	headerName: "Latest Status",
					// 	//headerClass:"cellstylegrid",
					// 	field: "last_status",
					// 	width: 150,
					// 	pinned: 'left',
					// 	editable:false,
					// 	filter: "agSetColumnFilter",resizable: true,
					// 	//cellClass:'cellstylegrid',
					// 	valueGetter:function(params){
					// 		//console.log("params ", params);
					// 		if (params.data.last_status != null)
					// 		{
					// 			if(params.data.last_status == 'Port In')
					// 			{
					// 				return "Arrived at Indian Port";
					// 			}
					// 			else if(params.data.last_status == 'In Transit to MSIL')
					// 			{

					// 				return "MSIL In Transit";
					// 			}
					// 			else if(params.data.last_status == 'In ICD')
					// 			{

					// 				return "In ICD";
					// 			}
					// 			else{
					// 				return params.data.last_status;
					// 			}
								
					// 		}
					// 		else
					// 		{
					// 			return "NA"
					// 		}

					// 	}
					// }
				]
			},
			{
				headerName: "<i class='icofont icofont-ship-alt'></i> Origin Sea Port to Indian Sea Port",
				headerClass:["cellstylegrid","cellTopHeader"],			
				children: [
					{
						headerName: "Supplier",
						headerClass:"cellstylegrid",
						field: "supplier_code",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						cellClass:'cellstylegrid'
					},
					{
						headerName: "Country Origin",
						headerClass:"cellstylegrid",
						field: "country_code",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						cellClass:'cellstylegrid'
					},
					{
						headerName: "PoL",
						headerClass:"cellstylegrid",
						field: "",
						width: 100,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						cellClass:'cellstylegrid',
						valueGetter:function(params){
							//console.log("params ", params);
							if (params.data.searates_pol != null)
							{
								return params.data.searates_pol;
							}
							else
							{
								return params.data.loading_port;
							}

						}
					},
					{
						headerName: "PoD",
						headerClass:"cellstylegrid",
						field: "",
						width: 100,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						cellClass:'cellstylegrid',
						valueGetter:function(params){
							//console.log("params ", params);
							if (params.data.searates_pod != null)
							{
								return params.data.searates_pod;
							}
							else
							{
								return params.data.discharge_port;
							}
						}
					},
					{
						headerName: "MBL No",
						headerClass:"cellstylegrid",
						field: "mbl_no",
						width: 120,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						cellClass:'cellstylegrid'
					},
					{
						headerName: "HBL No",
						headerClass:"cellstylegrid",
						field: "hbl_no",
						width: 120,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						cellClass:'cellstylegrid'
					},
					{
						headerName: "BL Date",
						headerClass:"cellstylegrid",
						field: "bl_date",
						width: 100,
						editable:false,
						resizable: true,
						hide:hideppc,
						cellClass:'cellstylegrid',
						
						valueGetter:function(params){
							//console.log("params ", params);
							if (params.data.msil_current_transit_eta != null)
							{
								return getHyphenDDMMMYYYY(params.data.bl_date);
							}
							else
							{
								return "NA"
							}

						},
						comparator: dateComparator1,
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},	
					{
						headerName: "Container Size",
						headerClass:"cellstylegrid",
						field: "container_size",
						width: 100,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						cellClass:'cellstylegrid'
					},	
					{
						headerName: "Inco Terms",
						headerClass:"cellstylegrid",
						field: "inco_terms",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						cellClass:'cellstylegrid'
					},			
					{
						headerName: "Shipping Line Code",
						headerClass:"cellstylegrid",
						field: "shipping_line_code",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						cellClass:'cellstylegrid'
					},		
					{
						headerName: "FF Code",
						headerClass:"cellstylegrid",
						field: "freight_forwarder_code",
						width: 150,
						editable:false,
						hide:hideppc,
						filter: "agSetColumnFilter",resizable: true,
						cellClass:'cellstylegrid'
					},
					{
						headerName: "Invoice Number",
						headerClass:"cellstylegrid",
						field: "invoice_no",
						width: 150,
						editable:false,
						hide:hideppc,
						filter: "agSetColumnFilter",resizable: true,
						cellClass:'cellstylegrid'
					},
					{
						headerName: "ETD PoL",
						headerClass:"cellstylegrid",
						field: "",
						width: 100,
						editable:false,
						resizable: true,
						cellClass:'cellstylegrid',
						valueGetter:function(params){
							//console.log("ETD PoL ", params.data.searates_etd_pol);
							if (params.data.searates_etd_pol == null)
							{
								return getHyphenDDMMMYYYY(params.data.etd_pol);
							}
							else
							{
								return getHyphenDDMMMYYYY(params.data.searates_etd_pol);
							}
						},
						
						comparator: dateComparator1,
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
					{
						headerName: "ATD PoL",
						headerClass:"cellstylegrid",
						field: "",
						width: 100,
						editable:false,
						resizable: true,
						cellClass:'cellstylegrid',
						valueGetter:function(params){
							//console.log("params ", params);
							if (params.data.searates_atd_pol == null)
							{
								return getHyphenDDMMMYYYY(params.data.atd_pol);
							}
							else
							{
								return getHyphenDDMMMYYYY(params.data.searates_atd_pol);
							}
						},
						
						comparator: dateComparator1,
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
					{
						headerName: "ETA PoD",
						headerClass:"cellstylegrid",
						field: "",
						width: 100,
						editable:false,
						resizable: true,
						cellClass:'cellstylegrid',
						valueGetter:function(params){
							//console.log("params ", params);
							if (params.data.sea_initial_transit_eta == null)
							{
								return getHyphenDDMMMYYYY(params.data.eta_pod);
							}
							else
							{
								return getHyphenDDMMMYYYY(params.data.sea_initial_transit_eta);
							}
						},
						
						comparator: dateComparator1,
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
					{
						headerName: "ATA PoD",
						headerClass:"cellstylegrid",
						field: "",
						width: 100,
						editable:false,
						resizable: true,
						cellClass:'cellstylegrid',
						valueGetter:function(params){
							//console.log("params ", params);
							if (params.data.searates_ata_pod == null)
							{
								return getHyphenDDMMMYYYY(params.data.ata_pod);
							}
							else
							{
								var fromdate = new Date(params.data.searates_ata_pod);
								//console.log("fromdate",fromdate);
								var from_utc = fromdate.getTime() * 1000;
								var current_dt = new Date().getTime() * 1000;
								//console.log("" from_utc)
								if (from_utc < current_dt)
								{
									return getHyphenDDMMMYYYY(params.data.searates_ata_pod);
								}
								else{
									return "";
								}
							}
						},
						
						comparator: dateComparator1,
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},	
				],
			},
			{
				headerName: "<i class='icofont icofont-map-pins'></i> Indian Sea Port to ICD",
				headerClass:["cellstylegridB","cellTopHeader"],
				children: [				
					{
						headerName: "SMTP Handover",
						field: "inland_smtp_handover_date",
						width: 150,
						editable:false,
						resizable: true,
						hide:hideppc,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB',
						valueGetter:function(params){
							//console.log("params ", params);
							if (params.data.inland_smtp_handover_date != null)
							{
								return getHyphenDDMMMYYYY(params.data.inland_smtp_handover_date);
							}
						},
						
						comparator: dateComparator1,
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
					{
						headerName: "BOE No",
						field: "inland_boe_no",
						width: 100,
						editable:false,
						hide:hideppc,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "BOE Date",
						field: "inland_boe_date",
						width: 100,
						editable:false,
						resizable: true,
						hide:hideppc,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB',
						valueGetter:function(params){
							//console.log("params ", params);
							if (params.data.inland_boe_date == '1753-01-01')
							{
								return "";
							}
							else
							{
								if (params.data.inland_boe_date != null){
									return getHyphenDDMMMYYYY(params.data.inland_boe_date);
								}
								else{
									return "";
								}
							}
						},
						
						comparator: dateComparator1,
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
					{
						headerName: "Departure to ICD",
						field: "inland_departure_to_grfl_date",
						width: 150,
						editable:false,
						resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB',
						valueGetter:function(params){
							//console.log("params ", params);
							if (params.data.inland_departure_to_grfl_date != null)
							{
								return getHyphenDDMMMYYYY(params.data.inland_departure_to_grfl_date);
							}
						},
						
						comparator: dateComparator1,
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},

					{
						headerName: "Transit Mode",
						field: "inland_transit_mode",
						width: 100,
						editable:false,
						hide:hideppc,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					{
						headerName: "Train/Truck No",
						field: "inland_line_voyage_no",
						width: 100,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
					},
					
					{
						headerName: "ICD Arrival",
						field: "inland_grfl_arrival_date",
						width: 150,
						editable:false,
						resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB',
						valueGetter:function(params){
							//console.log("params ", params);
							if (params.data.inland_grfl_arrival_date != null)
							{
								return getHyphenDDMMMYYYY(params.data.inland_grfl_arrival_date);
							}
						},
						
						comparator: dateComparator1,
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
					{
						headerName: "COC Date",
						field: "inland_coc_date",
						width: 150,
						editable:false,
						hide:hideppc,
						resizable: true,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB',
						valueGetter:function(params){
							//console.log("params ", params);
							if (params.data.inland_coc_date != null)
							{
								return getHyphenDDMMMYYYY(params.data.inland_coc_date);
							}
						},
						
						comparator: dateComparator1,
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},					
					{
						headerName: "Handover to ICD/TPT",
						field: "inland_handover_date",
						width: 180,
						editable:false,
						resizable: true,
						hide:hideppc,
						headerClass:"cellstylegridB",
						cellClass:'cellstylegridB',
						valueGetter:function(params){
							//console.log("params ", params);
							if (params.data.inland_handover_date != null)
							{
								return getHyphenDDMMMYYYY(params.data.inland_handover_date);
							}
						},
						
						comparator: dateComparator1,
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
				]
			},
			{
				headerName: "<i class='icofont icofont-map-pins'></i> ICD/Port to MSIL",
				headerClass:["cellstylegridG","cellTopHeader"],
				children: [	
					{
						headerName: "Dispatch from ICD",
						field: "inland_dispatch_date",
						width: 180,
						editable:false,
						resizable: true,
						headerClass:"cellstylegridG",
						cellClass:'cellstylegridG',
						valueGetter:function(params){
							//console.log("params ", params);
							if (params.data.inland_dispatch_date != null)
							{
								return getHyphenDDMMMYYYY(params.data.inland_dispatch_date);
							}
						},
						
						comparator: dateComparator1,
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
					{
						headerName: "Transporter",
						field: "inland_transporter_name",
						width: 150,
						editable:false,
						hide:hideppc,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridG",
						cellClass:'cellstylegridG',
						valueGetter:function(params){
							//console.log("params ", params);
							if (params.data.searates_pod == "Nhava Sheva")
							{
								return params.data.inland_transporter_name;
							}
							else
							{
								return "GRFL";
							}
						}
					},
					{
						headerName: "Trailer No",
						field: "inland_trailer_no",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridG",
						cellClass:'cellstylegridG'
					},
					{
						headerName: "MSIL Gate In",
						field: "inland_msil_gate_in",
						width: 150,
						//hide:hideppc,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridG",
						cellClass:'cellstylegridG',
						valueGetter:function(params){
							try{
								if(params.data.inland_msil_gate_in != '')
								{
									return getHyphenDDMMMYYYYHHMM(params.data.inland_msil_gate_in)
								}
								else{
									return "";
								}
							}
							catch(e){
								return "";
							}
						},
						comparator: dateComparator1,
						// change the date format to DD-MM-YYYY to sort and filter the columns
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
					{
						headerName: "MSIL Gate Out",
						field: "",
						width: 150,
						hide:hideppc,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridG",
						cellClass:'cellstylegridG',
						
						comparator: dateComparator1,
						// Change the date format to DD-MM-YYYY to sort and filter the columns
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
					{
						headerName: "GPS MSIL Gate In",
						field: "gps_msil_gate_in",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridG",
						cellClass:'cellstylegridG',
						
						valueGetter:function(params){
							try{
								if(params.data.gps_msil_gate_in != '')
								{
									return getHyphenDDMMMYYYYHHMM(params.data.gps_msil_gate_in)
								}
								else{
									return "";
								}
							}
							catch(e){
								return "";
							}
						},
						comparator: dateComparator1,
					},
					{
						headerName: "GPS MSIL Gate Out",
						field: "gps_msil_gate_out",
						width: 150,
						editable:false,
						hide:hideppc,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridG",
						cellClass:'cellstylegridG',
						
						
						valueGetter:function(params){
							try{
								if(params.data.gps_msil_gate_out != '')
								{
									return getHyphenDDMMMYYYYHHMM(params.data.gps_msil_gate_out)
								}
								else{
									return "";
								}
							}
							catch(e){
								return "";
							}
						},
						comparator: dateComparator1,
						
					},
					{
						headerName: "DO Validity",
						field: "inland_do_validity",
						width: 150,
						editable:false,
						resizable: true,
						hide:hideppc,
						headerClass:"cellstylegridG",
						cellClass:'cellstylegridG',
						valueGetter:function(params){
							//console.log("params ", params);
							if (params.data.inland_do_validity == '1753-01-01')
							{
								return "";
							}
							else
							{
								if (params.data.inland_do_validity != null){
									return getHyphenDDMMMYYYY(params.data.inland_do_validity);
								}
								else{
									return "";
								}
							}

						},
						
						comparator: dateComparator1,
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
					{
						headerName: "Empty Return On",
						field: "inland_empty_return_date",
						width: 150,
						editable:false,
						resizable: true,
						hide:hideppc,
						headerClass:"cellstylegridG",
						cellClass:'cellstylegridG',
						valueGetter:function(params){
							//console.log("params ", params);
							if (params.data.inland_empty_return_date != null)
							{
								return getHyphenDDMMMYYYY(params.data.inland_empty_return_date);
							}
						},
						
						comparator: dateComparator1,
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					}
					,
					{
						headerName: "Force Close",
						field: "force_closed",
						width: 150,
						editable:false,
						hide:hideppc,
						filter: "agSetColumnFilter",resizable: true,
						headerClass:"cellstylegridG",
						cellClass:'cellstylegridG',
						// cellRendererSelector:function(params){
						// 	//console.log("params ", params)
						// 	return { component: 'forceclose'};
							
						// },
						valueGetter:function(params){
							if(parseInt(params.data.force_closed) == 1)
							{
								return "Yes";
							}
							else{
								return "No";
							}
						}
						
					}
				]
			},	
				
				
		];
		const commentColumns = [
			{
			  headerName : "Truck No",
			  field : "truck_no",
			  resizable : true,
			  width:100,
			  filter : true
			},
			{
			  headerName : "Consignment Code",
			  field : "consignment_code",
			  resizable : true,
			  width:120,
			  filter : true
			},
			{
			  headerName : "User Type",
			  field : "user_type",
			  width:100,
			  resizable : true,
			  filter : true,
			  valueGetter : function(params){
				if(params.data.user_type == "ORGUSER"){
				  return "MSIL"
				}
				if(params.data.user_type == "DCTUSER"){
				  return "DCT"
				} 
				if(params.data.user_type == "TRANSPORTER"){
				  return "TRANSPORTER"
				} 
			  }
			},
			{
			  headerName : "Comment",
			  field : "comment",
			  width:250,
			  cellClass: ['wraptext'],
			  resizable : true,
			  filter : true
			},
			{
			  headerName : "Commented By",
			  field : "username",
			  width:150,
			  resizable : true,
			  filter:true
			},
		  
			{
			  headerName : "Commented Date",
			  field : "comment_date",
			  resizable : true,
			  filter : true,
			  width:150,
			  valueGetter : function(params){
				if(params.data.comment_date != ""){
				  return getHyphenDDMMMYYYYHHMM(params.data.comment_date);
				}
			  }
			}
		
		  ]
		const dStyles={
			width:this.props.width,
			height:'500px',
			marginLeft:this.props.marginLeft,

		}
		if(this.state.highseasinfo.delays != '' && this.state.highseasinfo.delays != undefined)
		{
			console.log("delays ", this.state.highseasinfo.delays.length)
		}
		return(
			<div className="container-fluid">
				
				<Breadcrumb parent="Container" title="Imports" />
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
				
				<div className="row col-xl-12 col-lg-12">
					<form method="POST" className="form-theme col-xl-12 col-lg-12 row">
					<div className="form-group col-xl-2 col-lg-2">
						<label>Select POD: </label>
						<Select placeholder={"Select POD"} closeMenuOnSelect={false} value={this.state.pod} isMulti="true" className={"border-radius-0 "+(this.state.errStyPod)} onChange={this.getPodListItems.bind(this)} style={{borderRadius:"0px"}} options={this.selectPodOptionsItems()} />
                                       
					</div>
					<div className="form-group col-xl-2 col-lg-2" style={{borderRight:"1px solid #aaa"}}>
						<label>Select Country Origin: </label>
						<Select placeholder={"Country Origin"} closeMenuOnSelect={false} value={this.state.country_code} isMulti="true" className={"border-radius-0 "+(this.state.errStyPol)} onChange={this.getCountryListItems.bind(this)} style={{borderRadius:"0px"}} options={this.selectCountryOptionsItems()} />
                                       
					</div>
					{/* <div className="form-group col-xl-2 col-lg-2" style={{borderRight:"1px solid #aaa"}}>
						<label>Select POL: </label>
						<Select placeholder={"Select POL"} closeMenuOnSelect={false} value={this.state.pol} isMulti="true" className={"border-radius-0 "+(this.state.errStyPol)} onChange={this.getPolListItems.bind(this)} style={{borderRadius:"0px"}} options={this.selectPolOptionsItems()} />
                                       
					</div> */}
					<div className="form-group col-xl-2 col-lg-2">
						<label>Select Criteria: </label>
						<Select placeholder={"Select Criteria"} closeMenuOnSelect={false} value={this.state.criteria} className={"border-radius-0 "+(this.state.errStyCriteria)} onChange={this.getCriteriaListItems.bind(this)} style={{borderRadius:"0px"}} options={this.selectCriteriaOptionsItems()} />
                                       
					</div>
					<div className="col-xl-2 col-lg-2 form-group">
						<label>From Date</label>
						{/* <input type="text" placeholder="From Date" id="from_date" value={this.state.startDate} autocomplete="off" className="datetimepicker_date form-control"  required /> */}
						<Datetime className={this.state.errStyStartDate} value={this.state.startDate} disableCloseOnClickOutside={false} closeOnSelect={true} inputProps={{ placeholder: 'From', name: 'startDate', autoComplete:'off', required:true }} dateFormat="YYYY-MM-DD"  name="startDate" timeFormat={null} 
						isValidDate={current => {
							return current.isSameOrBefore(moment(), 'day');
						}}
						onChange={this.handlerStartDateTime.bind(this)} />
					</div>

					<div className="col-xl-2 col-lg-2 form-group">
						<label>To Date</label>
						{/* <input type="text" placeholder="To Date" id="to_date" value={this.state.endDate}  className="datetimepicker_date form-control" autoComplete="off" required /> */}
						<Datetime className={this.state.errStyEndDate} value={this.state.endDate} disableCloseOnClickOutside={false} closeOnSelect={true} inputProps={{ placeholder: 'To', name: 'endDate', autoComplete:'off', required:true}} dateFormat="YYYY-MM-DD" timeFormat={null}
						isValidDate={current => {
							return current.isSameOrAfter(moment(new Date(this.state.startDate)), 'day');
						}}
						onChange={this.handlerEndDateTime.bind(this)} />
					</div>
					
					<div className="form-group col-xl-2 col-lg-2">
						<label>&nbsp;</label><br />
						<button type="button" className="btn btn-success" style={{padding:"5px 10px"}} onClick={this.onClickGetData.bind(this)}>Submit</button>
						<a href="/imports/deliveredContainers" className="btn btn-primary" style={{padding:"5px 10px"}}>Reset</a>
					
					</div>
					</form>
				</div>
				{(userroles.indexOf("sc_ppc") >= 0)?"":
				<Counters 
					context={this}
					countertype={"deliver"}
					highseascount={this.state.highseascount.length}
					highseasinfo={this.state.highseasinfo}
					inportcount={this.state.inportcount.length}
					inportinfo = {this.state.inportinfo}
					intransiticdcount={this.state.intransittoicdcount.length}
					intransittoicdinfo = {this.state.transittoicdinfo}
					inicdcount={this.state.inicdcount.length}
					inicdinfo = {this.state.inicdinfo}
					transitmsilcount={this.state.transitmsilcount.length}
					allRowDatacount={this.state.totalrows.length}
					emptyreturned={this.state.emptyreturnedlist.length}
				/>

				}
				
				{/* <CounterSet 
					context={this}
					boe_filing_delay_total={this.state.boe_filing_delay_total.length}
					boe_filing_delay_lessthree={this.state.boe_filing_delay_lessthree.length}
					boe_filing_delay_greaterthree={this.state.boe_filing_delay_greaterthree.length}
					icd_detention_delay_first={this.state.icd_detention_delay_first.length}
					icd_detention_delay_second={this.state.icd_detention_delay_second.length}
					icd_detention_delay_third={this.state.icd_detention_delay_third.length}
					new_screen = "0"
				/> */}
            	<div className="row">					
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> 
									   <span> {this.state.pageTitle} </span>
									{/* <span className="float-right f13">
										<i className="icofont icofont-ship-alt f20"></i> - High Seas &nbsp; &nbsp;
										<i className="icofont icofont-ship f20"></i> - In Port &nbsp; &nbsp;
										<i className="icofont icofont-train-line f20"></i> - In transit to ICD Garhi &nbsp; &nbsp;
										<i className="icofont icofont-ui-home f20"></i> - Inside ICD Garhi &nbsp; &nbsp;
										<i className="icofont icofont-truck-alt f24"></i> - MSIL In Transit&nbsp; &nbsp;
										/<i className="icofont icofont-vehicle-delivery-van f20"></i> - Empty Return &nbsp; &nbsp;
									</span> */}

									<span className="layoutbtns float-right">
										<button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
											<i className="icofont icofont-save"></i> Save Grid Layout
										</button>
										<button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
											<i className="icofont icofont-refresh"></i> Reset Default Layout
										</button>
									</span>
								</h5>
				   			</div>				   			
		            		<div className="card-body">
								
								 <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={columnwithDefs}
		          		            defaultColDef={this.state.defaultColDef}
		          		            rowData={this.state.rowData}
		          		            enableCharts={true}
		          		            enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
		          	                sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									//editType={this.state.editType}
									stopEditingWhenGridLosesFocus={true}
									floatingFilter={false}
									enableCellChangeFlash={true}
									suppressCellFlash={true}
									rowClassRules={this.state.rowClassRules}
									//onCellClicked={this.onRowClicked.bind(this)}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}
									headerHeight={this.state.headerHeight}
									groupHeaderHeight={this.state.groupHeaderHeight}
		          		          />

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div>

		          		        </div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				
				
				 {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								Container Routes #{this.state.consignment_code}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12">
								<img src={require('../../assets/icons/start_port.png')} className="mapico2" /> - Starting Point &nbsp;
								<img src={require('../../assets/icons/boat.png')} className="mapico1" /> - Port/Change Over location &nbsp; 
								<img src={require('../../assets/icons/crane.png')} className="mapico" /> - Last Known location &nbsp;
								
								<DrawMap mapFor={"importsconsignment"} mapinfo={this.state.mapinfo} defTransitCoords={this.state.defTransitCoords} />
									{/*
								<div id="map" className="" style={{width:'100%',height:"90vh",marginTop:"15px"}}></div>
									*/}
							</div>
						</div>
					</div>
				:""	
				}
				{this.state.sliderCommentTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderCommentTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								Consignment Comments
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>	
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							<div id="myGrid1" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={commentColumns}
		          		            defaultColDef={this.state.defaultColDef}
									rowData={this.state.commentsRowData}
		          		            enableCharts={true}
		          		            enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
									// sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
							
		          		          />
									
									

		          		    </div>
						</div>
						
					</div>
				:""	
				}
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
				<Modal open={open} onClose={this.onCloseModal} styles={modalStyles} >
	                <div className={"row"} style={{padding:'5px 0px', width: '400px', overflow: 'hidden',zIndex:'9999',}} >
						<form className="col-xl-12 clo-lg-12 theme-form" method="post" onSubmit={this.formHandler.bind(this)}>
						
						<div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
							<div className="form-group">
								<label className="col-form-label">MSIL Gatein Date:</label>
								<Datetime 
								value={this.state.msil_gatein_date} 
								disableCloseOnClickOutside={false} 
								closeOnSelect={true} 
								inputProps={{ placeholder: 'MSIL Gatein Date', name: 'msil_gatein_date', autoComplete:'off' }}
								 dateFormat="YYYY-MM-DD"  
								 name="msil_gatein_date" 
								 onChange={this.handlerGateinDateTime.bind(this)}
								  />
							</div>
						</div>
						<div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
							<div className="form-group">
								<label className="col-form-label">MSIL Gateout Date:</label>
								<Datetime 
								value={this.state.msil_gateout_date} 
								disableCloseOnClickOutside={false} 
								closeOnSelect={true} 
								inputProps={{ placeholder: 'MSIL Gateout Date', name: 'msil_gateout_date', autoComplete:'off' }}
								 dateFormat="YYYY-MM-DD"  
								 name="msil_gateout_date" 
								 onChange={this.handlerGateoutDateTime.bind(this)}
								  />
							</div>
						</div>
						<div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
							<div className="form-group">
								<label className="col-form-label">Empty Return Date:</label>
								{(this.state.emptydisable == true)?
								<div >
									<div className="form-control">
									{this.state.empty_return_date}
									</div>
									<input type="hidden" placeholder="Empty Return Date"  className="form-control" readonly name="empty_return_date" autoComplete='off' onChange={this.changeHandler} value={this.state.empty_return_date} />
								 </div>
								 :
								<Datetime 
								value={this.state.empty_return_date} 
								disableCloseOnClickOutside={false} 
								closeOnSelect={true} 
								inputProps={{ placeholder: 'Empty Return Date', name: 'empty_return_date', autoComplete:'off' }}
								 dateFormat="YYYY-MM-DD"  
								 name="empty_return_date" 
								 onChange={this.handlerEmptyReturnon.bind(this)}
								 
								  />
								}
							</div>
						</div>
						<div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
							<div className="form-group">
								<label className="col-form-label">Remarks:</label>
								<textarea required id="forceclose_remarks" className="form-control" rows="6" name="forceclose_remarks" value={this.state.forceclose_remarks} onChange={this.changeHandler} placeholder="Enter Remarks"/>
							</div>
						</div>
						<div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
							<div className="form-group">
								<button type="submit" className="btn btn-success" name="submit">Submit</button>						
							</div>
							
						</div>
						</form>
					</div>
					<div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				</Modal>

            </div>
              	
		);
    }


    
    onShowConsignmentImportsEventsClick = async (params) => {
		//console.log(e);
		console.log("IN onShowConsignmentImportsEventsClick params ",params);
		if (googleAnalytics.page.enableGA) {
			let eventLabel = null;
			if (params) {
				let columnFieldName = (params.colDef) ? params.colDef.field : null;
				//console.log("columnFieldName = ", columnFieldName);
				eventLabel = googleAnalytics.page.colDef.importsConsignments[columnFieldName];
				//console.log("eventLabel = ", eventLabel);
			}
			
			let eventOptions = {
				"category": this.state.pageTitle,
				"action": this.state.eventGridAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
		}
	//http://localhost:3001/trackBlDeepSea/track?sealine=HPG&container=FSCU9842333&apiKey=O1TX-DPHE-WYN6-53MI
		if(params != 0)
		{
			var eventsData=[];
			// await redirectURL.post('trackBlDeepSea/getcontainertrackingdetails', {
            //     container: params.data.container_no,
            //     sealine: params.data.shipping_line_code,
            //     apiKey: "O1TX-DPHE-WYN6-53MI"
            // })
			// .then(async (response) =>{
			// 	console.log("Step 11 ",response.data)
				
				
			// 	eventsData = await parseEventsData(response.data);
			// 	//params.successCallback(eventsData);
					
			// 	console.log("Step 12 ",response.data)
				
			// });

			//console.log("Step 3 ",eventsData)
			
			await this.setState({
				detailCellRenderer: "<div>Test</div>",
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [							
							{ headerName: "Event",field: "description", filter:true,
							valueGetter:function(params){
								var desc = params.data.description;
								var modifiedDesc = desc.replace(/Ghari/g,"Garhi")
								return modifiedDesc;
							},
							cellStyle: {'white-space': 'normal'},
							autoHeight:true,
								cellClass:function(params){
									//console.log("In ", params)
									if(params.data.event_completed == 1 && params.data.leg_delayed == 0)
									{
										return 'green';
									}
									else if(params.data.event_completed == 1 && params.data.leg_delayed == 1)
									{
										return 'green';
									}
									else if(params.data.event_completed == 0 && params.data.leg_delayed == 1)
									{
										return '';
									}
									else{
										return '';
									}
								},resizable: false
							},
							
							{ headerName: "Place",field: "pod", filter:true,
								cellClass:function(params){
									if(params.data.event_completed == 1 && params.data.leg_delayed == 0)
									{
										return 'green';
									}
									else if(params.data.event_completed == 1 && params.data.leg_delayed == 1)
									{
										return 'green';
									}
									else if(params.data.event_completed == 0 && params.data.leg_delayed == 1)
									{
										return '';
									}
									else{
										return '';
									}
								},resizable: true
							 },	
							{ 
								headerName: "Initial Estimated Date", field: "initial_event_date", filter:true ,
								cellClass:function(params){
									if(params.data.event_completed == 1 && params.data.leg_delayed == 0)
									{
										return 'green';
									}
									else if(params.data.event_completed == 1 && params.data.leg_delayed == 1)
									{
										return 'green';
									}
									else if(params.data.event_completed == 0 && params.data.leg_delayed == 1)
									{
										return '';
									}
									else{
										return '';
									}
								},
								valueGetter:function(params){
									return getDDMMYYYY(params.data.initial_event_date);
								},resizable: true,
								
							},
							{ 
								headerName: "Previous Revised Date", field: "previous_event_date", filter:true ,
								cellClass:function(params){
									if(params.data.event_completed == 1 && params.data.leg_delayed == 0)
									{
										return 'green';
									}
									else if(params.data.event_completed == 1 && params.data.leg_delayed == 1)
									{
										return 'green';
									}
									else if(params.data.event_completed == 0 && params.data.leg_delayed == 1)
									{
										return '';
									}
									else{
										return '';
									}
								},
								valueGetter:function(params){
									return getDDMMYYYY(params.data.previous_event_date);
								},resizable: true
							},						
							{ 
								headerName: "Actual Event Date", field: "actual_event_date", filter:true ,
								cellClass:function(params){
									if(params.data.event_completed == 1 && params.data.leg_delayed == 0)
									{
										return 'green';
									}
									else if(params.data.event_completed == 1 && params.data.leg_delayed == 1)
									{
										return 'green';
									}
									else if(params.data.event_completed == 0 && params.data.leg_delayed == 1)
									{
										return '';
									}
									else{
										return '';
									}
								},
								valueGetter:function(params){
									return getDDMMYYYY(params.data.actual_event_date);
								},resizable: true,
								//comparator: dateComparator,
								//sort: "asc"
							},{ 
								headerName: "Trip Stage", field: "trip_stage", filter:true ,
								cellClass:function(params){
									if(params.data.event_completed == 1 && params.data.leg_delayed == 0)
									{
										return 'green';
									}
									else if(params.data.event_completed == 1 && params.data.leg_delayed == 1)
									{
										return 'green';
									}
									else if(params.data.event_completed == 0 && params.data.leg_delayed == 1)
									{
										return '';
									}
									else{
										return '';
									}
								},resizable: true,
								valueGetter:function(params){
									if(params.data.trip_stage == 1)
									{
										return 'High Seas';
									}

									if(params.data.trip_stage == 2)
									{
										return 'Port In';
									}

									if(params.data.trip_stage == 3)
									{
										return 'Port Out';
									}

									if(params.data.trip_stage == 4)
									{
										return 'ICD In';
									}

									if(params.data.trip_stage == 5)
									{
										return 'ICD Out';
									}
								}
							}
							
						],
										
					},
					getDetailRowData: async function(params) {
						//console.log("Step 4 eventsData =",eventsData);
						//console.log("Step 5 params = ",params);
						redirectURL.post('/trackBlDeepSea/imports', {
							container: params.data.container_no,
							sealine: params.data.shipping_line_code,
							apiKey: "O1TX-DPHE-WYN6-53MI"
						})
						.then(async (response) =>{
							//console.log("Event Step 1 ",response.data)
							//eventsData = await parseEventsData(response.data);
							var nonempty = [];
							var emptyrows = [];
							response.data.map((item) => {
								if(item.hasOwnProperty("actual_event_date"))
								{
									nonempty.push(item)
								}
								else{
									emptyrows.push(item);
								}
							})
							nonempty.sort(function(a, b) {
								var c = new Date(a.actual_event_date);
								var d = new Date(b.actual_event_date);
								return c-d;
							});
							var records = nonempty.concat(emptyrows);
							//console.log("Records ", records);
							params.successCallback(records);
						});
					},

					
					masterDetail: true,
				}
			});
			
			if(params.column.colId == 'sea_transit_delay' || params.column.colId == 'port_delay' || params.column.colId == 'rail_transit_delay' || params.column.colId == 'icd_delay' || params.column.colId == '_id' || params.column.colId == 'msil_transit_delay')
			{
				//console.log("detailCellRendererParams 1111 = ", this.state.detailCellRendererParams, params.column.colId);
				params.node.setExpanded(!params.node.expanded);
			}
			else{
				//console.log("detailCellRendererParams 2222 = ", this.state.detailCellRendererParams, params.column.colId);
				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		} 
		
	}
}
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
                   
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = Math.floor(hours);
	var minutes = (n) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
	var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

	marker.addListener('click', function() {
		
				if (currentinfowindow != null) {
					currentinfowindow.close();
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				else
				{
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				
        });
}

function parseEventsData2 (jsonData) {
    console.info('parsing data IN parseEventsData');
    console.info('parsing data IN parseConsignemntDetailsSafexpress', jsonData);
   
    let points = jsonData.points;
   // console.log('points = ', points);
    let eventList = [];
    let eventInfo = {};
    points.forEach(function(point) {
        eventInfo.name = point.name;
        eventInfo.country = point.country;
      // traversing events
      if (point.events) {
        console.log('coming in events');
        point.events.forEach(function(eventObj) { 
            eventInfo.event_datetime_ist = eventObj.event_datetime_ist;
            eventInfo.description = eventObj.description;
        });
        eventList.push(eventInfo);
      } else {
        console.log('coming in ELSE point.events ');

      }
      
    });
    console.info('parsing points');

    return eventList;
  }
  
async function parseEventsData (jsonData) {
//console.info('parsing data IN parseEventsData');
//console.info('parsing data IN parseConsignemntDetailsSafexpress', jsonData);

let points = jsonData.points;
// console.log('points = ', points);
let eventList = [];
let eventInfo = [];

points.forEach(function(point) {
	let pointName = point.name;
	let pointCountry = point.country;
	let pointTransportTypeName = point.transport_type_name;
	let pointTransportType = point.type;
	if (pointTransportType === 1 || pointTransportType === 4) {
		pointTransportTypeName = "Rail/Truck";
	}
	if (!pointTransportTypeName) {
		pointTransportTypeName = "Data unavailable"
	}
	// traversing events
	if (point.events) {
		//console.log('coming in events');
		point.events.forEach(function(eventObj) { 
			let eventDatetime = eventObj.event_datetime_ist;
			let eventDescription = eventObj.description;
			
			let initial_event_datetime_ist = eventObj.initial_event_datetime_ist;
            let previous_event_datetime_ist = eventObj.previous_event_datetime_ist;
			let actual_event_datetime_ist	= eventObj.actual_event_datetime_ist;
			

			eventInfo = {
				"name": pointName,
				"country": pointCountry,
				"event_datetime_ist": eventDatetime,
				"description": eventDescription,
				"transport_type_name": pointTransportTypeName,
				"initial_event_datetime_ist": initial_event_datetime_ist,
				"previous_event_datetime_ist": previous_event_datetime_ist,
				"actual_event_datetime_ist": actual_event_datetime_ist,
			}
			eventList.push(eventInfo);
		});
	} else {
		console.log('coming in ELSE point.events '); // do nothing
	}
});
//console.log('eventList', eventList);
return eventList;
}


function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }

function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}
function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined )
	{
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split("-")[1]);
		date1 = date1.replace(date1.split("-")[1],date_1);
		var date_2 = getMonthNumber(date2.split("-")[1]);
		date2 = date2.replace(date2.split("-")[1],date_2);
		var date1Number = monthToComparableNumber1(date1);
		var date2Number = monthToComparableNumber1(date2);
		if (date1Number === null && date2Number === null) {
		return 0;
		}
		if (date1Number === null) {
		return -1;
		}
		if (date2Number === null) {
		return 1;
		}
		return date1Number - date2Number;
	}
	else
	{
		return 0;
	}
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
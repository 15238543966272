/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _, { isNumber } from 'lodash';
import axios from 'axios';
import Select from 'react-select';
import * as Datetime from 'react-datetime';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getHyphenDDMMMYYYYHHMM } from '../common/utils';
import DamagesColumnChart from "./damagesColumnChart"
import $ from "jquery";
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class DamageDashboard extends Component{
        constructor(props){
        super(props);
        this.state = {
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadshow:'show-n',
            overly:'show-n',
            dept_code:"",
            pageTitle:"Rail",
            month:[],
            year:[],
            startDate:'',
            endDate:'',
            open:false,
            rowData: [],
            defaultColDef: {
                sortable: true,
              filter: true,
              resizable: true

            },              
            rowSelection:'multiple',
                rowModelhighchartoptionsType: 'enterprise',
                rowGroupPanelShow: 'always',
                overlayNoRowsTemplate: "",
                frameworkComponents: {
            },

            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,
            childconfs: [],
            childrow:[],
            rowModelType: "serverSide",
            paginationPageSize:50,
            cacheBlockSize: 10,
            modules: AllModules,
            maxBlocksInCache: 1,
            userType: "",
            statusBar: {
                statusPanels: [
                    {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                    },
                    {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                    },
                    {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            firstChartArr: [],
            secondChartArr: [],
            thirdChartArr: [],
            fourthChartArr: [],
            fifthChartArr: [],
            sixthChartArr: [],
            seventhChartArr: [],
            showRoadCharts: "show-m",
            roadChartsActive: "activet",
            railChartsActive: "",
            railFirstChartArr: [],
            railSecondChartArr: [],
            railThirdChartArr: [],
            railFourthChartArr: [],
            railFifthChartArr: [],
            railSixthChartArr: [],
            selecetdType: {"label": "Invoice", "value": "invoice"},
            currFy: "",
            screen_type: "regular",
            tab_one: "Road Damages",
            tab_two: "Rail Damages"
        }

        this.loadFirstChart = this.loadFirstChart.bind(this);
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                //console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
     componentDidMount = async () => {
        if(this.props.match.path == "/raildamagedashboard")
        {
            this.setState({
                screen_type: "rail",
                tab_one: "Rail Damages",
                tab_two: "LSP Rail Damages"
            })
        }
        var fy = ""
        var currMonth = moment.parseZone(new Date()).utcOffset("+05:30").format("MM-YYYY");
        var month = moment.parseZone(new Date()).utcOffset("+05:30").format("MM");
        var year = moment.parseZone(new Date()).utcOffset("+05:30").format("YYYY");
        // var fy;
        
        if(month > 4)
        {
            fy = year+"-"+(parseInt(year)+1).toString()
        }
        else
        {
            fy = (parseInt(year) - 1).toString()+"-"+year
        }
        // console.log(fy, "fy")
        await this.setState({
            currFy : fy
        })
        this.getAllChartData("invoice")
    }
    getAllChartData(date_type)
    {
        this.loadFirstChart(date_type);
        this.loadSecondChart(date_type);
        this.laodThirdChart(date_type);
        this.loadFourthChart(date_type);
        this.loadFifthchart(date_type);
        this.loadSixthChart(date_type);
        this.loadSeventhChart(date_type);
        this.loadRailFirstChart(date_type);
        this.loadRailSecondChart(date_type);
        this.loadRailThirdChart(date_type);
        this.loadRailFourthChart(date_type);
        this.loadRailFifthChart(date_type);
        this.loadRailSixthChart(date_type);
    }

    loadFirstChart(date_type){
        redirectURL.post("/dashboard/getTotalTransitDamageData", {"fy": this.state.currFy,
                     "mode": "total", "date_type": date_type, "screen_type": this.state.screen_type}).then((response)=>{
            var records = response.data;
            var total_transit_damage = [];
            var firstChartArr = [];
            var categories = []

            records.map(function(e){
                if(e.month_flag == 0)
                {
                    categories.push(e.month)
                    total_transit_damage.push({"name": e.month,
                        "y": e.damage_percent,
                        "total_count": e.total_count,
                        "damages_count": e.damages_count
                    })
                }
                
            })
            var monthkeys = ["Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Jan","Feb","Mar"];
            monthkeys.map(function(k){
                records.map(function(e){
                    if(e.month_flag == 1 && e.month.includes(k))
                    {
                        categories.push(e.month)
                        total_transit_damage.push({"name": e.month,
                            "y": e.damage_percent,
                            "total_count": e.total_count,
                            "damages_count": e.damages_count
                        })
                    }
                   
                })
            })
            
            
            var series = [
                {
                    name: 'Total Transit Damage',
                    type: 'column',
                    data: total_transit_damage,
                    color: "#bacee5",
                    dataLabels: {
                        enabled: true
                    }
                }
                
            ]

            firstChartArr.push({
                series : series,
                subtitle: "",
                categories: categories,
                xtitle:"",
                ytitle:"Damage %",
                yaxistitle:"",
                chartNo: 1
            })
            // console.log(firstChartArr, "firstChartArr")
            this.setState({
                firstChartArr: firstChartArr
            })
        })
    }
    loadSecondChart(date_type)
    {
        redirectURL.post("/dashboard/getTotalTransitDamageData", {"fy": this.state.currFy,
                                    "mode": "plant", "date_type": date_type, "screen_type": this.state.screen_type}).then((response)=>{
            var records = response.data;
            // console.log(records)
            var secondChartArr = [];
            var categories = []
            var ggn = [];
            var guj = []
            var dobaspet = []
            records.map(function(e){
                if(e.month_flag == 0)
                {
                    if(e.plant_name == 'Gurgaon')
                    {
                        categories.push(e.month)
                        ggn.push({"name": e.month,
                            "y": e.damage_percent,
                            "total_count": e.total_count,
                            "damages_count": e.damages_count})
                    }else if(e.plan == "Dobaspet"){
                        dobaspet.push({"name": e.month,
                            "y": e.damage_percent,
                            "total_count": e.total_count,
                            "damages_count": e.damages_count})
                    }
                    else
                    {
                        guj.push({"name": e.month,
                            "y": e.damage_percent,
                            "total_count": e.total_count,
                            "damages_count": e.damages_count})
                    }
                    
                }
                
            })
            var monthkeys = ["Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Jan","Feb","Mar"];
            monthkeys.map(function(k){
                records.map(function(e){
                    if(e.month_flag == 1 && e.month.includes(k))
                    {
                        if(e.plant_name == 'Gurgaon')
                        {
                            categories.push(e.month)
                            ggn.push({"name": e.month,
                                "y": e.damage_percent,
                                "total_count": e.total_count,
                                "damages_count": e.damages_count})
                        }
                        else if(e.plant_name == 'Dobaspet')
                        {
                            dobaspet.push({"name": e.month,
                                "y": e.damage_percent,
                                "total_count": e.total_count,
                                "damages_count": e.damages_count})
                        }
                        else
                        {
                            guj.push({"name": e.month,
                                "y": e.damage_percent,
                                "total_count": e.total_count,
                                "damages_count": e.damages_count})
                        }
                    }
                    
                    
                })
            })
            
            var series = [
                {
                    name: 'Gurgaon',
                    type: 'column',
                    data: ggn,
                    color: "#bacee5",
                    dataLabels: {
                        enabled: true
                    },
                    tooltip: {shared: false}
                },
                {
                    name: 'Gujarat',
                    type: 'column',
                    data: guj,
                    color: "#fbe5d6",
                    dataLabels: {
                        enabled: true
                    },
                    tooltip: {shared: false}
                },
                {
                    name: 'Dobaspet',
                    type: 'column',
                    data: dobaspet,
                    color: "#ed7d31",
                    dataLabels: {
                        enabled: true
                    },
                    tooltip: {shared: false}
                }
                
            ]

            secondChartArr.push({
                series : series,
                subtitle: "",
                categories: categories,
                xtitle:"",
                ytitle:"Damage %",
                yaxistitle:"",
            })
            // console.log(secondChartArr, "secondChartArr")
            this.setState({
                secondChartArr: secondChartArr
            })
        })
    }
    laodThirdChart(date_type){
        redirectURL.post("/dashboard/getTotalTransitDamageData", {"fy": this.state.currFy,
                                     "mode": "domestic", "date_type": date_type, "screen_type": this.state.screen_type}).then((response)=>{
            var records = response.data;
            var domestic_transit_damage = [];
            var thirdChartArr = [];
            var categories = []

            records.map(function(e){
                if(e.month_flag == 0)
                {
                    categories.push(e.month)
                    domestic_transit_damage.push({"name": e.month,
                        "y": e.damage_percent,
                        "total_count": e.total_count,
                        "damages_count": e.damages_count
                    })
                }
                
            })
            var monthkeys = ["Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Jan","Feb","Mar"];
            monthkeys.map(function(k){
                records.map(function(e){
                    if(e.month_flag == 1 && e.month.includes(k))
                    {
                        categories.push(e.month)
                        domestic_transit_damage.push({"name": e.month,
                            "y": e.damage_percent,
                            "total_count": e.total_count,
                            "damages_count": e.damages_count
                        })
                    }
                   
                })
            })
            
            
            var series = [
                {
                    name: 'DMG %',
                    type: 'column',
                    data: domestic_transit_damage,
                    color: "#bacee5",
                    dataLabels: {
                        enabled: true
                    }
                }
                
            ]

            thirdChartArr.push({
                series : series,
                subtitle: "",
                categories: categories,
                xtitle:"",
                ytitle:"Damage %",
                yaxistitle:"",
                chartNo: 1
            })
            // console.log(thirdChartArr, "thirdChartArr")
            this.setState({
                thirdChartArr: thirdChartArr
            })
        })
    }
    loadFourthChart(date_type){
        redirectURL.post("/dashboard/getTotalTransitDamageData", {"fy": this.state.currFy, "mode": "exports", 
                                                    "date_type": date_type, "screen_type": this.state.screen_type}).then((response)=>{
            var records = response.data;
            var exports_transit_damage = [];
            var fourthChartArr = [];
            var categories = []

            records.map(function(e){
                if(e.month_flag == 0)
                {
                    categories.push(e.month)
                    exports_transit_damage.push({"name": e.month,
                        "y": e.damage_percent,
                        "total_count": e.damages_count,
                        "damages_count": e.total_count
                    })
                }
                
            })
            var monthkeys = ["Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Jan","Feb","Mar"];
            monthkeys.map(function(k){
                records.map(function(e){
                    if(e.month_flag == 1 && e.month.includes(k))
                    {
                        categories.push(e.month)
                        exports_transit_damage.push({"name": e.month,
                            "y": e.damage_percent,
                            "total_count": e.total_count,
                            "damages_count": e.damages_count
                        })
                    }
                   
                })
            })
            
            
            var series = [
                {
                    name: 'DMG %',
                    type: 'column',
                    data: exports_transit_damage,
                    color: "#fbe5d6",
                    dataLabels: {
                        enabled: true
                    }
                }
                
            ]

            fourthChartArr.push({
                series : series,
                subtitle: "",
                categories: categories,
                xtitle:"",
                ytitle:"Damage %",
                yaxistitle:"",
                chartNo: 1
            })
            // console.log(fourthChartArr, "fourthChartArr")
            this.setState({
                fourthChartArr: fourthChartArr
            })
        })
    }
    loadFifthchart(date_type)
    {
        redirectURL.post("/dashboard/getTotalTransitDamageData", {"fy": this.state.currFy, 
                                "mode": "route", "date_type": date_type,"screen_type": this.state.screen_type}).then((response)=>{
            var records = response.data;
            var route_transit_damage = [];
            var fifthChartArr = [];
            var categories = []

            
            records.map(function(e){
                categories.push(e.route_name)
                route_transit_damage.push({"name": e.route_name,
                    "y": e.damage_percent,
                    "total_count": e.total_count,
                    "damages_count": e.damages_count
                })
            })
            
            route_transit_damage = route_transit_damage.sort((a, b) => b.y - a.y);
// console.log(route_transit_damage,'route_transit_damage')
            const averageYByNames = {};

            route_transit_damage.forEach(obj => {
              if (averageYByNames[obj.name]) {
                averageYByNames[obj.name].sumY += obj.y;
                if(obj.y != 0){

                    averageYByNames[obj.name].count += 1;
                }        
                  } else {
                averageYByNames[obj.name] = {
                  sumY: obj.y,
                  count: 1,
                  total_count: obj.total_count,
                  damages_count: obj.damages_count
                };
              }
            });
            
            // console.log(averageYByNames, 'averageYByNames');
            
            const result = Object.keys(averageYByNames).map(name => {
                const averageY = (averageYByNames[name].sumY / averageYByNames[name].count).toFixed(2);
                return {
                name: name,
                y: parseFloat(averageY),
                total_count: averageYByNames[name].total_count,
                damages_count: averageYByNames[name].damages_count
              };
            });
            
            // console.log(result, 'resultssss');

            var series = [
                {
                    name: 'Route %',
                    type: 'column',
                    data: result,
                    color: "#ed7d31",
                    dataLabels: {
                        enabled: true
                    }
                }
                
            ]

            fifthChartArr.push({
                series : series,
                subtitle: "",
                categories: categories,
                xtitle:"",
                ytitle:"Damage %",
                yaxistitle:"",
                chartNo: 1
            })
            // console.log(fifthChartArr, "fifthChartArr")
            this.setState({
                fifthChartArr: fifthChartArr
            })
        })
    }
    loadSixthChart(date_type){
        redirectURL.post("/dashboard/getTotalTransitDamageData", {"fy": this.state.currFy,
                                 "mode": "cluster", "date_type": date_type,"screen_type": this.state.screen_type}).then((response)=>{
            var records = response.data;
            // console.log(records,'recordsssssssss')
            var cluster_transit_damage = [];
            var sixthChartArr = [];
            var categories = []

            
            records.map(function(e){
                categories.push(e.cluster)
                cluster_transit_damage.push({"name": e.cluster,
                    "y": e.damage_percent,
                    "total_count": e.total_count,
                    "damages_count": e.damages_count
                })
            })
            
            cluster_transit_damage = cluster_transit_damage.sort((a,b) => b.y - a.y);
            cluster_transit_damage = cluster_transit_damage.slice(0,5)

            const averageYByNames = {};

            cluster_transit_damage.forEach(obj => {
              if (averageYByNames[obj.name]) {
                averageYByNames[obj.name].sumY += obj.y;
                if(obj.y != 0){

                    averageYByNames[obj.name].count += 1;
                }           
               } else {
                averageYByNames[obj.name] = {
                  sumY: obj.y,
                  count: 1,
                  total_count: obj.total_count,
                  damages_count: obj.damages_count
                };
              }
            });
            
            // console.log(averageYByNames, 'averageYByNames');
            
            const result = Object.keys(averageYByNames).map(name => {
                const averageY = (averageYByNames[name].sumY / averageYByNames[name].count).toFixed(2);
                return {
                name: name,
                y: parseFloat(averageY),
                total_count: averageYByNames[name].total_count,
                damages_count: averageYByNames[name].damages_count
              };
            });
            
            // console.log(result, 'resultssss');

            var series = [
                {
                    name: 'Cluster %',
                    type: 'column',
                    data: result,
                    color: "#8faadc",
                    dataLabels: {
                        enabled: true
                    }
                }
                
            ]
            
            sixthChartArr.push({
                series : series,
                subtitle: "",
                categories: categories,
                xtitle:"",
                ytitle:"Damage %",
                yaxistitle:"",
                chartNo: 1
            })
            // console.log(sixthChartArr, "sixthChartArr")
            this.setState({
                sixthChartArr: sixthChartArr
            })
        })
    }
    loadSeventhChart(date_type){
        redirectURL.post("/dashboard/getTotalTransitDamageData", {"fy": this.state.currFy,
                                 "mode": "model", "date_type": date_type,"screen_type": this.state.screen_type}).then((response)=>{
            var records = response.data;
            var model_transit_damage = [];
            var seventhChartArr = [];
            var categories = []

            
            records.map(function(e){
                categories.push(e.sbvh_name)
                model_transit_damage.push({"name": e.sbvh_name,
                    "y": e.damage_percent,
                    "total_count": e.total_count,
                    "damages_count": e.damages_count
                })
            })
            
            model_transit_damage = model_transit_damage.sort((a, b) => b.y - a.y);
            // console.log(model_transit_damage,'model_transit_damage')
            const averageYByNames = {};

            model_transit_damage.forEach(obj => {
              if (averageYByNames[obj.name]) {
                averageYByNames[obj.name].sumY += obj.y;
                if(obj.y != 0){

                    averageYByNames[obj.name].count += 1;
                }
              } else {
                averageYByNames[obj.name] = {
                  sumY: obj.y,
                  count: 1,
                  total_count: obj.total_count,
                  damages_count: obj.damages_count
                };
              }
            });
            
            // console.log(averageYByNames, 'averageYByNames');
            
            const result = Object.keys(averageYByNames).map(name => {
                const averageY = (averageYByNames[name].sumY / averageYByNames[name].count).toFixed(2);
                return {
                name: name,
                y: parseFloat(averageY),
                total_count: averageYByNames[name].total_count,
                damages_count: averageYByNames[name].damages_count
              };
            });
            
            // console.log(result, 'resultssss');

            var series = [
                {
                    name: 'SBVH Models',
                    type: 'column',
                    data: result,
                    color: "#8faadc",
                    dataLabels: {
                        enabled: true
                    }
                }
                
            ]
            
            seventhChartArr.push({
                series : series,
                subtitle: "",
                categories: categories,
                xtitle:"",
                ytitle:"Damage %",
                yaxistitle:"",
                chartNo: 1
            })
            // console.log(seventhChartArr, "seventhChartArr")
            this.setState({
                seventhChartArr: seventhChartArr
            })
        })
    }
    loadRailFirstChart(date_type){
        redirectURL.post("/dashboard/getTotalRailTransitDamageData", {"fy": this.state.currFy, 
                                        "transporter_name": "APL LOGISTICS","date_type": date_type}).then((response)=>{
            var records = response.data;
            var apl_transit_damage = [];
            var railFirstChartArr = [];
            var categories = []
            var cats = []
            records.map(function(e){
                if(e.month_flag == 0)
                {
                    categories.push(e.month)
                    apl_transit_damage.push({"name": e.month,
                        "y": e.damage_percent,
                        "total_count": e.total_count,
                        "damages_count": e.damages_count
                    })
                }
                
            })
            var monthkeys = ["Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Jan","Feb","Mar"];
            monthkeys.map(function(k){
                records.map(function(e){
                    if(e.month_flag == 1 && e.month.includes(k))
                    {
                       
                        cats.push({month:e.month,order:parseInt(e.month_yr)})
                        apl_transit_damage.push({"name": e.month,
                            "y": e.damage_percent,
                            "total_count": e.total_count,
                            "damages_count": e.damages_count
                        })
                    }
                   
                })
            })
            // console.log("cats",cats)

            var ctsc= cats.sort(GetSortOrder("order"));
            if(ctsc.length > 0)
            {
                ctsc.map((e) => {
                    categories.push(e.month)
                })
            }
            // console.log("categories",categories)
            var series = [
                {
                    name: 'DMG %',
                    type: 'column',
                    data: apl_transit_damage,
                    color: "#bacee5",
                    dataLabels: {
                        enabled: true
                    }
                }
                
            ]

            railFirstChartArr.push({
                series : series,
                subtitle: "",
                categories: categories,
                xtitle:"",
                ytitle:"Damage %",
                yaxistitle:"",
                chartNo: 1
            })
            // console.log(railFirstChartArr, "railFirstChartArr")
            this.setState({
                railFirstChartArr: railFirstChartArr
            })
        })
    }
    loadRailSecondChart(date_type){
        redirectURL.post("/dashboard/getTotalRailTransitDamageData", {"fy": this.state.currFy, 
                                        "transporter_name": "ORIENTAL CARRIERS PVT.LTD","date_type": date_type}).then((response)=>{
            var records = response.data;
            var ocpl_transit_damage = [];
            var railSecondChartArr = [];
            var categories = []
            let cats = []
            records.map(function(e){
                if(e.month_flag == 0)
                {
                    categories.push(e.month)
                    ocpl_transit_damage.push({"name": e.month,
                        "y": e.damage_percent,
                        "total_count": e.total_count,
                        "damages_count": e.damages_count
                    })
                }
                
            })
            var monthkeys = ["Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Jan","Feb","Mar"];
            monthkeys.map(function(k){
                records.map(function(e){
                    if(e.month_flag == 1 && e.month.includes(k))
                    {
                        cats.push({month:e.month,order:parseInt(e.month_yr)})
                        ocpl_transit_damage.push({"name": e.month,
                            "y": e.damage_percent,
                            "total_count": e.total_count,
                            "damages_count": e.damages_count
                        })
                    }
                   
                })
            })
            var ctsc= cats.sort(GetSortOrder("order"));
            if(ctsc.length > 0)
            {
                ctsc.map((e) => {
                    categories.push(e.month)
                })
            }
            
            var series = [
                {
                    name: 'DMG %',
                    type: 'column',
                    data: ocpl_transit_damage,
                    color: "#fbe5d6",
                    dataLabels: {
                        enabled: true
                    }
                }
                
            ]

            railSecondChartArr.push({
                series : series,
                subtitle: "",
                categories: categories,
                xtitle:"",
                ytitle:"Damage %",
                yaxistitle:"",
                chartNo: 1
            })
            // console.log(railSecondChartArr, "railSecondChartArr")
            this.setState({
                railSecondChartArr: railSecondChartArr
            })
        })
    }
    loadRailThirdChart(date_type){
        redirectURL.post("/dashboard/getTotalRailTransitDamageData", {"fy": this.state.currFy, 
                                        "transporter_name": "IVC LOGISTICS LIMITED","date_type": date_type}).then((response)=>{
            var records = response.data;
            var ivc_transit_damage = [];
            var railThirdChartArr = [];
            var categories = []
            let cats = []   
            records.map(function(e){
                if(e.month_flag == 0)
                {
                    categories.push(e.month)
                    ivc_transit_damage.push({"name": e.month,
                        "y": e.damage_percent,
                        "total_count": e.total_count,
                        "damages_count": e.damages_count
                    })
                }
                
            })
            var monthkeys = ["Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Jan","Feb","Mar"];
            monthkeys.map(function(k){
                records.map(function(e){
                    if(e.month_flag == 1 && e.month.includes(k))
                    {
                        cats.push({month:e.month,order:parseInt(e.month_yr)})
                        ivc_transit_damage.push({"name": e.month,
                            "y": e.damage_percent,
                            "total_count": e.total_count,
                            "damages_count": e.damages_count
                        })
                    }
                   
                })
            })
            
            var ctsc= cats.sort(GetSortOrder("order"));
            if(ctsc.length > 0)
            {
                ctsc.map((e) => {
                    categories.push(e.month)
                })
            }
            
            var series = [
                {
                    name: 'DMG %',
                    type: 'column',
                    data: ivc_transit_damage,
                    color: "#bacee5",
                    dataLabels: {
                        enabled: true
                    }
                }
                
            ]

            railThirdChartArr.push({
                series : series,
                subtitle: "",
                categories: categories,
                xtitle:"",
                ytitle:"Damage %",
                yaxistitle:"",
                chartNo: 1
            })
            // console.log(railThirdChartArr, "railThirdChartArr")
            this.setState({
                railThirdChartArr: railThirdChartArr
            })
        })
    }
    loadRailFourthChart(date_type){
        redirectURL.post("/dashboard/getTotalRailTransitDamageData", {"fy": this.state.currFy, 
                                        "transporter_name": "JOSHI KONOIKE TPT. & INFRA. PVT LTD","date_type": date_type}).then((response)=>{
            var records = response.data;
            var jkti_transit_damage = [];
            var railFourthChartArr = [];
            var categories = []
            let cats = []
            records.map(function(e){
                if(e.month_flag == 0)
                {
                    categories.push(e.month)
                    jkti_transit_damage.push({"name": e.month,
                        "y": e.damage_percent,
                        "total_count": e.total_count,
                        "damages_count": e.damages_count
                    })
                }
                
            })
            var monthkeys = ["Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Jan","Feb","Mar"];
            monthkeys.map(function(k){
                records.map(function(e){
                    if(e.month_flag == 1 && e.month.includes(k))
                    {
                        cats.push({month:e.month,order:parseInt(e.month_yr)})
                        jkti_transit_damage.push({"name": e.month,
                            "y": e.damage_percent,
                            "total_count": e.total_count,
                            "damages_count": e.damages_count
                        })
                    }
                   
                })
            })

            var ctsc= cats.sort(GetSortOrder("order"));
            if(ctsc.length > 0)
            {
                ctsc.map((e) => {
                    categories.push(e.month)
                })
            }            
            
            var series = [
                {
                    name: 'DMG %',
                    type: 'column',
                    data: jkti_transit_damage,
                    color: "#fbe5d6",
                    dataLabels: {
                        enabled: true
                    }
                }
                
            ]

            railFourthChartArr.push({
                series : series,
                subtitle: "",
                categories: categories,
                xtitle:"",
                ytitle:"Damage %",
                yaxistitle:"",
                chartNo: 1
            })
            // console.log(railFourthChartArr, "railFourthChartArr")
            this.setState({
                railFourthChartArr: railFourthChartArr
            })
        })
    }
    loadRailFifthChart(date_type){
        redirectURL.post("/dashboard/getTotalRailTransitDamageData", {"fy": this.state.currFy, 
                                        "transporter_name": "ADANI LOGISTICS LTD","date_type": date_type}).then((response)=>{
            var records = response.data;
            var adani_transit_damage = [];
            var railFifthChartArr = [];
            var categories = []
            let cats = []
            records.map(function(e){
                if(e.month_flag == 0)
                {
                    categories.push(e.month)
                    adani_transit_damage.push({"name": e.month,
                        "y": e.damage_percent,
                        "total_count": e.total_count,
                        "damages_count": e.damages_count
                    })
                }
                
            })
            var monthkeys = ["Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Jan","Feb","Mar"];
            monthkeys.map(function(k){
                records.map(function(e){
                    if(e.month_flag == 1 && e.month.includes(k))
                    {
                        cats.push({month:e.month,order:parseInt(e.month_yr)})
                        adani_transit_damage.push({"name": e.month,
                            "y": e.damage_percent,
                            "total_count": e.total_count,
                            "damages_count": e.damages_count
                        })
                    }
                   
                })
            })
            
            var ctsc= cats.sort(GetSortOrder("order"));
            if(ctsc.length > 0)
            {
                ctsc.map((e) => {
                    categories.push(e.month)
                })
            }
            
            var series = [
                {
                    name: 'DMG %',
                    type: 'column',
                    data: adani_transit_damage,
                    color: "#bacee5",
                    dataLabels: {
                        enabled: true
                    }
                }
                
            ]

            railFifthChartArr.push({
                series : series,
                subtitle: "",
                categories: categories,
                xtitle:"",
                ytitle:"Damage %",
                yaxistitle:"",
                chartNo: 1
            })
            // console.log(railFifthChartArr, "railFifthChartArr")
            this.setState({
                railFifthChartArr: railFifthChartArr
            })
        })
    }
    loadRailSixthChart(date_type){
        redirectURL.post("/dashboard/getTotalRailTransitDamageData", {"fy": this.state.currFy, 
                                        "transporter_name": "ADANI NYK AUTO LOGISTICS SOLU P LTD","date_type": date_type}).then((response)=>{
            var records = response.data;
            var adani_nyk_transit_damage = [];
            var railSixthChartArr = [];
            var categories = []
            let cats = []
            records.map(function(e){
                if(e.month_flag == 0)
                {
                    categories.push(e.month)
                    adani_nyk_transit_damage.push({"name": e.month,
                        "y": e.damage_percent,
                        "total_count": e.total_count,
                        "damages_count": e.damages_count
                    })
                }
                
            })
            var monthkeys = ["Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Jan","Feb","Mar"];
            monthkeys.map(function(k){
                records.map(function(e){
                    if(e.month_flag == 1 && e.month.includes(k))
                    {
                        cats.push({month:e.month,order:parseInt(e.month_yr)})
                        adani_nyk_transit_damage.push({"name": e.month,
                            "y": e.damage_percent,
                            "total_count": e.total_count,
                            "damages_count": e.damages_count
                        })
                    }
                   
                })
            })
            var ctsc= cats.sort(GetSortOrder("order"));
            if(ctsc.length > 0)
            {
                ctsc.map((e) => {
                    categories.push(e.month)
                })
            }            
            
            var series = [
                {
                    name: 'DMG %',
                    type: 'column',
                    data: adani_nyk_transit_damage,
                    color: "#fbe5d6",
                    dataLabels: {
                        enabled: true
                    }
                }
                
            ]

            railSixthChartArr.push({
                series : series,
                subtitle: "",
                categories: categories,
                xtitle:"",
                ytitle:"Damage %",
                yaxistitle:"",
                chartNo: 1
            })
            // console.log(railSixthChartArr, "railSixthChartArr")
            this.setState({
                railSixthChartArr: railSixthChartArr
            })
        })
    }

    onGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
    };
    
	onGridState = () =>{
        this.colState = this.gridColumnApi.getColumnState();
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    
    
    

    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n'
        });
    }
    
    onRowSelected(event) {

    }
    closeAlert = async() => {
        await this.setState({
            show: false
        });
        $("#targetform")[0].reset();
    }

    showTab(tabno)
    {
        if(tabno == 1)
        {
            this.setState({
                showRoadCharts: "show-m",
                roadChartsActive: "activet",
                railChartsActive: ""
            })
        }
        if(tabno == 2)
        {
            this.setState({
                showRoadCharts: "",
                roadChartsActive: "",
                railChartsActive: "activet"
            })
        }
    }
    onClickChangeType(){
        this.setState({
            firstChartArr: [],
            secondChartArr: [],
            thirdChartArr: [],
            fourthChartArr: [],
            fifthChartArr: [],
            sixthChartArr: [],
            seventhChartArr: [],
            railFirstChartArr: [],
            railSecondChartArr: [],
            railThirdChartArr: [],
            railFourthChartArr: [],
            railFifthChartArr: [],
            railSixthChartArr: [],
        })
        this.getAllChartData(this.state.selecetdType.value)
    }
    
    render(){
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="row col-xl-12 col-lg-12" style={{marginTop:"1em", marginBottom: "2em"}}>
                            <div className="col-xl-3 col-lg-3">
                                <label>Select Type : </label>
                                <Select 
                                    closeMenuOnSelect={true} 
                                    value={this.state.selecetdType} 
                                    className="border-radius-0" 
                                    onChange={(e)=>{this.setState({selecetdType: e})}} 
                                    style={{borderRadius:"0px"}} 
                                    options={[{"label": "Invoice", "value": "invoice"},
                                    {"label": "POD", "value": "pod"}]} required
                                />
                            </div>
                            <div className="col-xl-2 col-lg-2">
                                <br />
                                <button style={{marginTop:"9px"}} className="btn btn-success" onClick={this.onClickChangeType.bind(this)}>Submit</button>
                            </div>
                        </div>
                        <button className={"btn btn-default "+this.state.roadChartsActive} onClick={this.showTab.bind(this,1)}>{this.state.tab_one}</button>
                        <button className={"btn btn-default "+this.state.railChartsActive} onClick={this.showTab.bind(this,2)}>{this.state.tab_two}</button>
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-pie-chart cus-i"></i> <span>Damages Report</span>
                                </h5>
                            </div>
                            {(this.state.showRoadCharts == "show-m")?
                                <div className="card-body pt-15px row">
                                    <div className="row col-xl-6 col-lg-6 col-sm-6">
                                        {(this.state.firstChartArr.length > 0)?
                                                ((this.state.firstChartArr[0].message != "no data")?
                                                    (this.state.firstChartArr.map(item => 
                                                        <DamagesColumnChart 
                                                            title={"Transit Damage %"}
                                                            subtitle={item.subtitle}
                                                            categories={item.categories}
                                                            context={this}
                                                            series={item.series}
                                                            xtitle={item.xtitle}
                                                            ytitle={item.ytitle}
                                                            yaxistitle={item.yaxistitle}
                                                        />))
                                                    :
                                                    <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                        <h5>Trailer Dispatches FY {this.state.finyear}</h5>No Data</div>
                                                )
                                                
                                            :
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>Trailer Dispatches  {this.state.finyear}</h5>
                                            <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                        }
                                    </div>
                                    <div className="row col-xl-6 col-lg-6 col-sm-6">
                                        {(this.state.secondChartArr.length > 0)?
                                                ((this.state.secondChartArr[0].message != "no data")?
                                                    (this.state.secondChartArr.map(item => 
                                                        <DamagesColumnChart 
                                                            title={"Plant wise Damage % - GGN/GUJ/DOBASPET"}
                                                            subtitle={item.subtitle}
                                                            context={this}
                                                            categories={item.categories}
                                                            series={item.series}
                                                            xtitle={item.xtitle}
                                                            ytitle={item.ytitle}
                                                            yaxistitle={item.yaxistitle}
                                                        />))
                                                    :
                                                    <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                        <h5>Trailer Dispatches FY {this.state.finyear}</h5>No Data</div>
                                                )
                                                
                                            :
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>Trailer Dispatches  {this.state.finyear}</h5>
                                            <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                        }
                                    </div>
                                    <div className="row col-xl-6 col-lg-6 col-sm-6">
                                        {(this.state.thirdChartArr.length > 0)?
                                                ((this.state.thirdChartArr[0].message != "no data")?
                                                    (this.state.thirdChartArr.map(item => 
                                                        <DamagesColumnChart 
                                                            title={"Domestic Damage %"}
                                                            subtitle={item.subtitle}
                                                            context={this}
                                                            categories={item.categories}
                                                            series={item.series}
                                                            xtitle={item.xtitle}
                                                            ytitle={item.ytitle}
                                                            yaxistitle={item.yaxistitle}
                                                        />))
                                                    :
                                                    <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                        <h5>Domestic Damage % </h5>No Data</div>
                                                )
                                                
                                            :
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>Domestic Damage %</h5>
                                            <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                        }
                                    </div>
                                    <div className="row col-xl-6 col-lg-6 col-sm-6">
                                        {(this.state.fourthChartArr.length > 0)?
                                                ((this.state.fourthChartArr[0].message != "no data")?
                                                    (this.state.fourthChartArr.map(item => 
                                                        <DamagesColumnChart 
                                                            title={"Exports Damage %"}
                                                            subtitle={item.subtitle}
                                                            context={this}
                                                            categories={item.categories}
                                                            series={item.series}
                                                            xtitle={item.xtitle}
                                                            ytitle={item.ytitle}
                                                            yaxistitle={item.yaxistitle}
                                                        />))
                                                    :
                                                    <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                        <h5>Exports Damage % </h5>No Data</div>
                                                )
                                                
                                            :
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>Exports Damage %</h5>
                                            <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                        }
                                    </div>
                                    <div className="row col-xl-6 col-lg-6 col-sm-6">
                                        {(this.state.fifthChartArr.length > 0)?
                                                ((this.state.fifthChartArr[0].message != "no data")?
                                                    (this.state.fifthChartArr.map(item => 
                                                        <DamagesColumnChart 
                                                            title={"Route Damage %"}
                                                            subtitle={item.subtitle}
                                                            context={this}
                                                            categories={item.categories}
                                                            series={item.series}
                                                            xtitle={item.xtitle}
                                                            ytitle={item.ytitle}
                                                            yaxistitle={item.yaxistitle}
                                                        />))
                                                    :
                                                    <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                        <h5>Route Damage % </h5>No Data</div>
                                                )
                                                
                                            :
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>Route Damage %</h5>
                                            <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                        }
                                    </div>
                                    <div className="row col-xl-6 col-lg-6 col-sm-6">
                                        {(this.state.sixthChartArr.length > 0)?
                                                ((this.state.sixthChartArr[0].message != "no data")?
                                                    (this.state.sixthChartArr.map(item => 
                                                        <DamagesColumnChart 
                                                            title={"Top 5 Cluster Damage %"}
                                                            subtitle={item.subtitle}
                                                            context={this}
                                                            categories={item.categories}
                                                            series={item.series}
                                                            xtitle={item.xtitle}
                                                            ytitle={item.ytitle}
                                                            yaxistitle={item.yaxistitle}
                                                        />))
                                                    :
                                                    <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                        <h5>Top 5 Cluster Damage % </h5>No Data</div>
                                                )
                                                
                                            :
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>Top 5 Cluster Damage %</h5>
                                            <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                        }
                                    </div>
                                    <div className="row col-xl-12 col-lg-12 col-sm-12">
                                        {(this.state.seventhChartArr.length > 0)?
                                                ((this.state.seventhChartArr[0].message != "no data")?
                                                    (this.state.seventhChartArr.map(item => 
                                                        <DamagesColumnChart 
                                                            title={"Model Wise Damage %"}
                                                            subtitle={item.subtitle}
                                                            context={this}
                                                            categories={item.categories}
                                                            series={item.series}
                                                            xtitle={item.xtitle}
                                                            ytitle={item.ytitle}
                                                            yaxistitle={item.yaxistitle}
                                                        />))
                                                    :
                                                    <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                        <h5>Top 5 Cluster Damage % </h5>No Data</div>
                                                )
                                                
                                            :
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>Top 5 Cluster Damage %</h5>
                                            <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                        }
                                    </div>

                                    
                                </div>
                            :""}
                            {(this.state.showRoadCharts == "")?
                                <div className="card-body pt-15px row">
                                    <div className="row col-xl-6 col-lg-6 col-sm-6">
                                        {(this.state.railFirstChartArr.length > 0)?
                                                ((this.state.railFirstChartArr[0].message != "no data")?
                                                    (this.state.railFirstChartArr.map(item => 
                                                        <DamagesColumnChart 
                                                            title={"APL Damage %"}
                                                            subtitle={item.subtitle}
                                                            categories={item.categories}
                                                            context={this}
                                                            series={item.series}
                                                            xtitle={item.xtitle}
                                                            ytitle={item.ytitle}
                                                            yaxistitle={item.yaxistitle}
                                                        />))
                                                    :
                                                    <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                        <h5>APL Damage %</h5>No Data</div>
                                                )
                                                
                                            :
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>APL Damage %</h5>
                                            <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                        }
                                    </div>
                                    <div className="row col-xl-6 col-lg-6 col-sm-6">
                                        {(this.state.railSecondChartArr.length > 0)?
                                                ((this.state.railSecondChartArr[0].message != "no data")?
                                                    (this.state.railSecondChartArr.map(item => 
                                                        <DamagesColumnChart 
                                                            title={"OCPL Damage %"}
                                                            subtitle={item.subtitle}
                                                            categories={item.categories}
                                                            context={this}
                                                            series={item.series}
                                                            xtitle={item.xtitle}
                                                            ytitle={item.ytitle}
                                                            yaxistitle={item.yaxistitle}
                                                        />))
                                                    :
                                                    <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                        <h5>OCPL Damage %</h5>No Data</div>
                                                )
                                                
                                            :
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>OCPL Damage %</h5>
                                            <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                        }
                                    </div>
                                    <div className="row col-xl-6 col-lg-6 col-sm-6">
                                        {(this.state.railThirdChartArr.length > 0)?
                                                ((this.state.railThirdChartArr[0].message != "no data")?
                                                    (this.state.railThirdChartArr.map(item => 
                                                        <DamagesColumnChart 
                                                            title={"IVC Damage %"}
                                                            subtitle={item.subtitle}
                                                            categories={item.categories}
                                                            context={this}
                                                            series={item.series}
                                                            xtitle={item.xtitle}
                                                            ytitle={item.ytitle}
                                                            yaxistitle={item.yaxistitle}
                                                        />))
                                                    :
                                                    <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                        <h5>IVC Damage %</h5>No Data</div>
                                                )
                                                
                                            :
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>IVC Damage %</h5>
                                            <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                        }
                                    </div>
                                    <div className="row col-xl-6 col-lg-6 col-sm-6">
                                        {(this.state.railFourthChartArr.length > 0)?
                                                ((this.state.railFourthChartArr[0].message != "no data")?
                                                    (this.state.railFourthChartArr.map(item => 
                                                        <DamagesColumnChart 
                                                            title={"JKTI Damage %"}
                                                            subtitle={item.subtitle}
                                                            categories={item.categories}
                                                            context={this}
                                                            series={item.series}
                                                            xtitle={item.xtitle}
                                                            ytitle={item.ytitle}
                                                            yaxistitle={item.yaxistitle}
                                                        />))
                                                    :
                                                    <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                        <h5>JKTI Damage %</h5>No Data</div>
                                                )
                                                
                                            :
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>JKTI Damage %</h5>
                                            <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                        }
                                    </div>
                                    <div className="row col-xl-6 col-lg-6 col-sm-6">
                                        {(this.state.railFifthChartArr.length > 0)?
                                                ((this.state.railFifthChartArr[0].message != "no data")?
                                                    (this.state.railFifthChartArr.map(item => 
                                                        <DamagesColumnChart 
                                                            title={"ADANI Damage %"}
                                                            subtitle={item.subtitle}
                                                            categories={item.categories}
                                                            context={this}
                                                            series={item.series}
                                                            xtitle={item.xtitle}
                                                            ytitle={item.ytitle}
                                                            yaxistitle={item.yaxistitle}
                                                        />))
                                                    :
                                                    <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                        <h5>APL Damage %</h5>No Data</div>
                                                )
                                                
                                            :
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>ADANI Damage %</h5>
                                            <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                        }
                                    </div>
                                    <div className="row col-xl-6 col-lg-6 col-sm-6">
                                        {(this.state.railSixthChartArr.length > 0)?
                                                ((this.state.railSixthChartArr[0].message != "no data")?
                                                    (this.state.railSixthChartArr.map(item => 
                                                        <DamagesColumnChart 
                                                            title={"ADANI-NYK Damage %"}
                                                            subtitle={item.subtitle}
                                                            categories={item.categories}
                                                            context={this}
                                                            series={item.series}
                                                            xtitle={item.xtitle}
                                                            ytitle={item.ytitle}
                                                            yaxistitle={item.yaxistitle}
                                                        />))
                                                    :
                                                    <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                        <h5>ADANI-NYK Damage %</h5>No Data</div>
                                                )
                                                
                                            :
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>ADANI-NYK Damage %</h5>
                                            <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                        }
                                    </div>
                                </div>
                            :""}
                            
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box">
                        <div className="loader-box">
                            <div className="rotate dashed colored"></div>
                        </div>
                    </div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            

            </div>
    )}       
} 

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}


function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
  } 


function GetSortASCOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
  } 
  function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../node_modules/react-resizable/css/styles.css';
import { WidthProvider, Responsive } from "react-grid-layout";
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import _ from "lodash";
import $ from "jquery";
var redirectURL = require('../../redirectURL');



export default class Overspeed extends Component{

	constructor(props)
	{
		super(props);
		this.state={
            sameDayDelay:"",
            delayDuration:"",
            sourcetoparkingdelayDuration:0,
            parkingtodestdelayDuration:0,
            criticalConsignment : "",
            criticalConsignmentCC : "",
            dept_code:"",
            userid:localStorage.getItem('userid'),
            email:localStorage.getItem('email'),
            trans_options: [],
            show:false,
            basicTitle:"",
            basicType:"default",
            sourcetoparkingdelay:"",
            parkingtodestinationdelay:"",
        };
    }
    componentDidMount(){
        redirectURL.post("/consignments/transporters").then((resp)=>{
            var transporters_list = resp.data;
            transporters_list = transporters_list.filter(e => e.dept_code == "LOG-TNP")
            var trans_options = [];
            transporters_list.map((e)=>{
                trans_options.push({"label": e.transporter_name, "value": e.transporter_code})
            })
            this.setState({
                trans_options: trans_options
            })
        })
    }
    onchangetransportercode(e){
    this.setState({"transporter_code":e.value}) 
    var transporterCode = e.value;
    // console.log("transporter code from the selector",transporterCode);
    var config_type = "transittimes";
    var params ={
        transporter_code : transporterCode,
        config_type : config_type,
    }
    redirectURL.post('/manage/getdataforkpitransittimes',params,{
        headers:{
            'Content-type':'application/json'
        }
    })
        .then((response) => {
            // console.log(response,"responseforthevalue");
            this.setState({
                sourcetoparkingdelay:response.data[0].source_to_parking_delay_DurationID,
                parkingtodestinationdelay:response.data[0].parking_to_dest_delay_DurationID,
            })
        })

    }
   
	formHandler = (event) => {
        event.preventDefault(); 
       
        var sourcetoparkingdelayDurationID = document.getElementById("sourcetoparkingdelayDurationID").value;
        // console.log(sourcetoparkingdelayDurationID,"sourcetoparkingdelayDurationID");
        var parkingtodestdelayDurationID = document.getElementById("parkingtodestdelayDurationID").value;
        // console.log(parkingtodestdelayDurationID,"parkingtodestdelayDurationID")
        var transporter_code = this.state.transporter_code;
        // console.log("transporter_code for the transtitimes",transporter_code);
        var formdata = {
            userid:this.state.userid,
            user_email:localStorage.getItem('email'),
            dept_code:"LOG-TNP",
            sourcetoparkingdelayDurationID:sourcetoparkingdelayDurationID,
            parkingtodestdelayDurationID:parkingtodestdelayDurationID,
            config_type:"transittimes",
            transporter_code:transporter_code,
            // threshold_time:(this.state.time*60)
        }
        // console.log(formdata)
        redirectURL.post('/manage/saveTTKPIConfigurations',formdata, {
            headers:{
                'Content-type':'application/json'
            }
        })
        .then((response) => {
            this.setState({
                basicTitle: "Success",
                basicType: "success",
                show: true
            })
        })
        
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    checkDuration1 = (event) =>{
        this.setState({
            // distance : event.target.value,
            sourcetoparkingdelay: event.target.value
        })
    }
    checkDuration2 = (event) =>{
        this.setState({
            // distance : event.target.value,
           parkingtodestinationdelay : event.target.value
        })
    }
    criticalConsignmentCC = (event)=>{
        this.setState({
            criticalConsignmentCC : event.target.value
        })
    }
    criticalConsignment = (event)=>{
        this.setState({
            // distance : event.target.value,
            criticalConsignment : event.target.value
        })
    }
    checkInput = (event) =>{
        this.setState({
            distance : event.target.value,
            // duration : event.target.value
        })
    }
    changeDept(params){
        this.setState({
            dept_code:params.value
        })
    }

    selectDept(){
        let arr=[];
        try{
            arr.push({value:"SNDG",label:"Sales and Dispatch"});
            arr.push({value:"LOG-PRT",label:"Spare Parts"});
            arr.push({value:"LOG-TNP",label:"Train and Production"});
            return arr;
        }
        catch(e){

        } 
    }
	 render() {
         let nightDrivingRules;
        return (
            <div className="row">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                ></SweetAlert>
                <div className="col-xl-12 col-lg-12">
                    <h5>Transit Delay Settings</h5>
                </div>
                <form className=" theme-form col-xl-8 col-lg-6" onSubmit={this.formHandler}>
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            <label>Select Department : </label>
                            <Select 
                                placeholder={"Transporter"}
                                closeMenuOnSelect={true}
                                // isMulti="true"
                                // onChange={this.getRailConsginments.bind(this)} 
                                className={"border-radius-0"}
                                style={{borderRadius:"0px"}}
                                options={this.state.trans_options}
                                onChange={this.onchangetransportercode.bind(this)}
                             />    
                        </div>      
                        {/* fajk   */}
                    </div>
                    
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            <label className="c-lbl">Minimum Delay Duration (Hours) Source to Parking *:</label>
                            <input type="number" name="duration" min="0" max="25000" id="sourcetoparkingdelayDurationID" className="form-control validateNumber" placeholder="Duration in Hours" autoComplete="off"
                             onChange={this.checkDuration1.bind(this)} required value={this.state.sourcetoparkingdelay} />
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            <label className="c-lbl">Minimum Delay Duration (Hours) Parking to Destination *:</label>
                            <input type="number" name="duration" min="0" max="25000" id="parkingtodestdelayDurationID" className="form-control validateNumber" placeholder="Duration in Hours" autoComplete="off" onChange={this.checkDuration2.bind(this)} required value={this.state.parkingtodestinationdelay}  />
                        </div>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-success cs-btn" id="save">Save</button>
                    </div>
                </form>
            </div>
        );
    }
}
$(document).ready(function(){
    $(document).on('keyup',".validateNumber",function(){
        // alert("click");
        if(isNaN($(this).val()) == true){
            $(this).val($(this).val().slice(0,-1));
        }
    });
    $(document).on("keyup",".validateEmail",function(e){
        alert($(this).val());
        if(e.which === 32){
            $(this).val($(this).val()+", ");
        }
    });
    $(document).on("keyup","#MSILCCID",function(e){
        // alert($(this).val());
        if(e.which === 32){
            $(this).val($(this).val()+", ");
        }

    });
    $(document).on("keyup","#MSILID",function(e){
        // alert($(this).val());
        if(e.which === 32){
            $(this).val($(this).val()+", ");
        }

    });
    $(document).on("keyup","#sourcetoparkingdelayDurationID",function(){
        if($(this).val() == 0){
            // alert("Value Should be Greater than 0");
            // $(".error-msg").empty();
            // $(".error-msg").append("Value Should be greater than 0");
            $(this).val(" ");
        }
    })
    $(document).on("keyup","#parkingtodestdelayDurationID",function(){
        if($(this).val() == 0){
            // alert("Value Should be Greater than 0");
            // $(".error-msg").empty();
            // $(".error-msg").append("Value Should be greater than 0");
            $(this).val(" ");
        }
    })
});


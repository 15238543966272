import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from "@ag-grid-community/react";
import Select from "react-select";
import { getHyphenDDMMMYYYY, getHyphenDDMMMYYYYHHMM, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS } from '../common/utils';
import $ from "jquery";
import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import DrawMap2 from '../common/drawmap2';
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import * as Datetime from 'react-datetime';
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import download from 'js-file-download';
var redirectURL = require("../redirectURL");
var moment = require('moment');
export default class MSIBOperationsMonitoring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      eventFormAction: "formAction",
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        width: 160,
        resizable: true,
      },
      eventAction: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      bulkslide: "",
      bulkslide1: "",
      files: [],
      filesName: '',
      alert: null,
      sliderbulkupload: "",
      rowModelhighchartoptionsType: "enterprise",
      frameworkComponents: {
        consignmentActions: ConsignmentActions,
      },
      paginationPageSize: 50,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      overly: "show-n",
      loadshow: "show-n",
      file: "",
      rowData: [],
      rowId: "",
      show: false,
      basicTitle: "",
      basicType: "",

      // restriction_area: '',
      // restriction_area_options: [],
      // restricted_type: '',
      // no_of_rakes_impacted: '',
      msil_rake_id: '',
      empty_rake_gate_in: '',
      rake_placement: '',
      rake_release: '',
      engine_on_train: '',
      rake_departure: '',
      rake_arrival_at_destination: '',
      last_mile_start: '',
      last_mile_end: '',
      calendarKey: 0,

      // restriction_start_date: '',
      // restriction_end_date: '',
      // informed_restriction_date: ''

    };
  }

  async componentDidMount() {
    loadDateTimeScript();
    var transportercode = "";
		if (localStorage.getItem('transportercode') === undefined) {
			transportercode = "";
		}
		else {
			transportercode = localStorage.getItem('transportercode');
		}
    var params = {
      transporter_code : transportercode,
    }
    await redirectURL
      .post("/consignments/msiboperationmonitoringdataonmount",params)
      .then((response) => {
        var totalData = response.data
        const filteredData = totalData.filter(row => !row.deleted);
        this.setState({
          rowData: filteredData
        })
      })

  }

  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      bulkslide: '',
      bulkslide1: "",
    });
    $("#uploadFile").val("")
    var form = document.getElementById("initialEditDetails");
    if (form) {
      form.reset();
    }
  }

  addMsibDetails() {
    var form = document.getElementById("initialAddingDetails");
    if (form) {
      form.reset();
    }
    this.setState({
      rake_name: [],
      type: [],
      bulkslide: "slider-translate-30p",
      overly: "show-m",

      msil_rake_id: '',
      empty_rake_gate_in: '',
      rake_placement: '',
      rake_release: '',
      engine_on_train: '',
      rake_departure: '',
      rake_arrival_at_destination: '',
      last_mile_start: '',
      last_mile_end: ''
    })
    $("#engine_on_train").val("")
  }

  onClickAddDetails = async (e) => {
    e.preventDefault();
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });

    const {
      empty_rake_gate_in,
      rake_placement,
      rake_release,
      engine_on_train,
      rake_departure,
      rake_arrival_at_destination,
      last_mile_start,
      last_mile_end,
      msil_rake_id,
    } = this.state;

    // Check if all fields are filled
    if (
      !msil_rake_id || !empty_rake_gate_in || !rake_placement || !rake_release ||
      !engine_on_train || !rake_departure || !rake_arrival_at_destination ||
      !last_mile_start || !last_mile_end
    ) {
      this.setState({
        show: true,
        basicType: "warning",
        loadshow: "show-n",
        overly: "show-n",
        basicTitle: "All (*) fields are mandatory",
      });
      return;
    }

    // Check if dates follow the correct logical order
    const isValidOrder = (prevDate, nextDate, prevLabel, nextLabel) => {
      if (moment(nextDate).isBefore(moment(prevDate))) {
        this.setState({
          show: true,
          basicType: "warning",
          loadshow: "show-n",
          overly: "show-n",
          basicTitle: `${nextLabel} must be greater than ${prevLabel}.`,
        });
        return false;
      }
      return true;
    };

    // Validate each date sequentially
    if (!isValidOrder(empty_rake_gate_in, rake_placement, 'Empty Rake Gate In', 'Rake Placement')) return;
    if (!isValidOrder(rake_placement, rake_release, 'Rake Placement', 'Rake Release')) return;
    if (!isValidOrder(rake_release, engine_on_train, 'Rake Release', 'Engine on Train')) return;
    if (!isValidOrder(engine_on_train, rake_departure, 'Engine on Train', 'Rake Departure')) return;
    if (!isValidOrder(rake_departure, rake_arrival_at_destination, 'Rake Departure', 'Rake Arrival at Destination')) return;
    if (!isValidOrder(rake_arrival_at_destination, last_mile_start, 'Rake Arrival at Destination', 'Last Mile Start')) return;
    if (!isValidOrder(last_mile_start, last_mile_end, 'Last Mile Start', 'Last Mile End')) return;

    // All checks passed, proceed with API call
    const params = {
      empty_rake_gate_in,
      rake_placement,
      rake_release,
      engine_on_train,
      rake_departure,
      rake_arrival_at_destination,
      last_mile_start,
      last_mile_end,
      msil_rake_id,
      updated_by: localStorage.getItem("username"),
    };

    try {
      const response = await redirectURL.post("/consignments/addmsiboperationmonitoring", params);
      this.setState({
        show: true,
        loadshow: "show-n",
        overly: "show-n",
        basicType: "success",
        basicTitle: "New details added Successfully",
        bulkslide: '',
        msil_rake_id: '',
        empty_rake_gate_in: '',
        rake_placement: '',
        rake_release: '',
        engine_on_train: '',
        rake_departure: '',
        rake_arrival_at_destination: '',
        last_mile_start: '',
        last_mile_end: '',
      });

      // Reload the component to update the data
      this.componentDidMount();
    } catch (error) {
      console.error("Error adding MSIB details:", error);
      this.setState({
        show: true,
        basicType: "danger",
        loadshow: "show-n",
        overly: "show-n",
        basicTitle: "Failed to add details, please try again.",
      });
    }
  };

  onClickEditDetails = async (e) => {
    e.preventDefault();
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });

    const {
      rowId,
      empty_rake_gate_in,
      rake_placement,
      rake_release,
      engine_on_train,
      rake_departure,
      rake_arrival_at_destination,
      last_mile_start,
      last_mile_end,
      msil_rake_id
    } = this.state;

    // Check if all fields are filled
    // if (
    //   !msil_rake_id || !empty_rake_gate_in || !rake_placement || !rake_release ||
    //   !engine_on_train || !rake_departure || !rake_arrival_at_destination ||
    //   !last_mile_start || !last_mile_end
    // ) {
    //   this.setState({
    //     show: true,
    //     basicType: "warning",
    //     loadshow: "show-n",
    //     overly: "show-n",
    //     basicTitle: "All (*) fields are mandatory",
    //   });
    //   return;
    // }

    // Check if dates follow the correct logical order
    // const isValidOrder = (prevDate, nextDate, prevLabel, nextLabel) => {
    //   if (moment(nextDate).isBefore(moment(prevDate))) {
    //     this.setState({
    //       show: true,
    //       basicType: "warning",
    //       loadshow: "show-n",
    //       overly: "show-n",
    //       basicTitle: `${nextLabel} must be greater than ${prevLabel}.`,
    //     });
    //     return false;
    //   }
    //   return true;
    // };

    // Validate each date sequentially
    // if (!isValidOrder(empty_rake_gate_in, rake_placement, 'Empty Rake Gate In', 'Rake Placement')) return;
    // if (!isValidOrder(rake_placement, rake_release, 'Rake Placement', 'Rake Release')) return;
    // if (!isValidOrder(rake_release, engine_on_train, 'Rake Release', 'Engine on Train')) return;
    // if (!isValidOrder(engine_on_train, rake_departure, 'Engine on Train', 'Rake Departure')) return;
    // if (!isValidOrder(rake_departure, rake_arrival_at_destination, 'Rake Departure', 'Rake Arrival at Destination')) return;
    // if (!isValidOrder(rake_arrival_at_destination, last_mile_start, 'Rake Arrival at Destination', 'Last Mile Start')) return;
    // if (!isValidOrder(last_mile_start, last_mile_end, 'Last Mile Start', 'Last Mile End')) return;

    // All checks passed, proceed with API call
    const params = {
      rowId,
      empty_rake_gate_in,
      rake_placement,
      rake_release,
      engine_on_train,
      rake_departure,
      rake_arrival_at_destination,
      last_mile_start,
      last_mile_end,
      msil_rake_id,
      Edited_by: localStorage.getItem("username"),
    };

    try {
      const response = await redirectURL.post("/consignments/Editmsiboperationmonitoring", params);
      this.setState({
        show: true,
        loadshow: "show-n",
        overly: "show-n",
        basicType: "success",
        basicTitle: "Edited Successfully",
        bulkslide1: '',
        msil_rake_id: '',
        empty_rake_gate_in: '',
        rake_placement: '',
        rake_release: '',
        engine_on_train: '',
        rake_departure: '',
        rake_arrival_at_destination: '',
        last_mile_start: '',
        last_mile_end: '',
      });

      // Reload the component to update the data
      this.componentDidMount();
    } catch (error) {
      console.error("Error editing MSIB details:", error);
      this.setState({
        show: true,
        basicType: "danger",
        loadshow: "show-n",
        overly: "show-n",
        basicTitle: "Failed to edit details, please try again.",
      });
    }
  }

  async onCellClicked(event) {
    if (event.colDef.field == "delete") {
      const params = {
        rowId: event.data._id,
        type: 'msibdelete'
      };

      this.setState({
        alert: (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            // title="Are you sure?"
            onConfirm={() => {
              this.setState({ alert: null });
              redirectURL.post("/consignments/msibDeleteRowData", params)
                .then((response) => {
                  this.setState({
                    loadshow: "show-n",
                    overly: "show-n",
                    show: true,
                    basicTitle: "Deleted Successfully",
                    basicType: 'success'
                  });
                  this.componentDidMount();
                })
                .catch((error) => {
                  console.error("Error deleting:", error);
                });
            }}
            onCancel={() => {
              this.setState({ alert: null });
              console.log("Deletion cancelled.");
            }}
          >
            Are you sure?<br />Once deleted, you will not be able to recover this data
          </SweetAlert>
        )
      });
    }
    if (event.colDef.field === "Edit") {
      var data = event.data;

      this.setState({
        rowId: data._id,

        // Safely format dates using UTC to prevent local timezone offset
        empty_rake_gate_in: data.empty_rake_gate_in ? moment.utc(data.empty_rake_gate_in).format("YYYY-MM-DD HH:mm:ss") 
                          : (data.auto_empty_rake_gate_in ? moment.utc(data.auto_empty_rake_gate_in).format("YYYY-MM-DD HH:mm:ss") : ""),
        
        rake_placement: data.rake_placement ? moment.utc(data.rake_placement).format("YYYY-MM-DD HH:mm:ss"): "",
        
        rake_release: data.rake_release ? moment.utc(data.rake_release).format("YYYY-MM-DD HH:mm:ss") 
                      : "",
        
        engine_on_train: data.engine_on_train ? moment.utc(data.engine_on_train).format("YYYY-MM-DD HH:mm:ss") 
                          : (data.auto_engine_on_train ? moment.utc(data.auto_engine_on_train).format("YYYY-MM-DD HH:mm:ss") : ""),
        
        rake_departure: data.rake_departure ? moment.utc(data.rake_departure).format("YYYY-MM-DD HH:mm:ss") 
                         : (data.auto_rake_departure ? moment.utc(data.auto_rake_departure).format("YYYY-MM-DD HH:mm:ss") : ""),
        
        rake_arrival_at_destination: data.rake_arrival_at_destination ? moment.utc(data.rake_arrival_at_destination).format("YYYY-MM-DD HH:mm:ss") 
                                     : (data.auto_rake_arrival_at_destination ? moment.utc(data.auto_rake_arrival_at_destination).format("YYYY-MM-DD HH:mm:ss") : ""),
        
        last_mile_start: data.last_mile_start ? moment.utc(data.last_mile_start).format("YYYY-MM-DD HH:mm:ss") 
                         : (data.auto_last_mile_start ? moment.utc(data.auto_last_mile_start).format("YYYY-MM-DD HH:mm:ss") : ""),
        
        last_mile_end: data.last_mile_end ? moment.utc(data.last_mile_end).format("YYYY-MM-DD HH:mm:ss") 
                       : (data.auto_last_mile_end ? moment.utc(data.auto_last_mile_end).format("YYYY-MM-DD HH:mm:ss") : ""),
    
        msil_rake_id: data.msil_rake_id || "",
        bulkslide1: "slider-translate-30p",
      });

      console.log(this.state.empty_rake_gate_in, 'empty_rake_gate_in');
      $("#uploadFile").val(""); // Reset file input
    }
  }

  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    // console.log(name, '---1', value)
    this.setState({ [name]: value });
  };

  handleChangeRakeId = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    // console.log(name, '---1', value)
    this.setState({ [name]: value });
  };

  closeAlert = () => {
    this.setState({
      show: false,
    });
  };

  onGridReady2 = params => {
    this.gridApi2 = params.api;

  };

  getValidDateRange = (minDate) => {
    return (currentDate) => {
      return currentDate.isSameOrAfter(minDate); // Only allow dates after or equal to minDate
    };
  };

  handleKeyPressCodeRakeId = (event) => {
    const currentValue = event.target.value;
    const inputChar = String.fromCharCode(event.charCode);
    // if ((currentValue + inputChar).length > 4) {
    //     event.preventDefault(); 
    // }
    if (!/^[0-9,]*$/.test(inputChar)) {
      event.preventDefault();
    }
  };

  formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  };

  handleDateChange = (date, field) => {
    const formattedDate = this.formatDate(date);
    this.setState({ [field]: formattedDate });
  };

  render() {

    const batchwisecolumns = [
      {
        headerName: 'S No.',
        valueGetter: "node.rowIndex + 1",
        width: 80,
        filter: false,
        resizable: true
      },
      {
        headerName: "Edit",
        field: "Edit",
        width: 110,
        resizable: true,
        editable: false,
        cellRenderer: function (params) {
          if (!params.data.not_planned_volumes) {
            var htmloption = '<button class="btn btn-warning" style="padding-top:0px">Edit</button>';
            return htmloption;
          } else {
            var htmloption = '<button class="btn btn-warning" disabled="on" style="padding-top:0px">Edit</button>';
            return htmloption;
          }
        },
        filter: false,
      },
      //   {
      //     headerName: "Action",
      //     field: "delete",
      //     width: 95,
      //     resizable: true,
      //     editable: false,
      //     cellRenderer: function (params) {
      //         // Assuming requested_date_time is stored in each row's data and is in ISO format
      //         const requestedDateTime = params.data.requested_date_time;
      //         const currentTime = new Date();
      //         const requestedDate = new Date(requestedDateTime);

      //         // Calculate the difference in hours
      //         const diffHours = (currentTime - requestedDate) / (1000 * 60 * 60);

      //         if (diffHours > 24) {
      //             // More than 24 hours old, disable the delete button
      //             return '<i class="icofont icofont-ui-delete" style="color: #ccc; cursor: not-allowed;"></i>';
      //         } else {
      //             // Less than 24 hours old, active delete button
      //             return '<i class="icofont icofont-ui-delete" style="cursor: pointer;"></i>';
      //         }
      //     },
      // },
      {
        headerName: "Action",
        field: "delete",
        width: 110,
        resizable: true,
        editable: false,
        cellRenderer: function (params) {
          const requestedDateTime = params.data.requested_date_time;
          const currentTime = new Date();
          const requestedDate = new Date(requestedDateTime);

          // Calculate the difference in hours
          const diffHours = (currentTime - requestedDate) / (1000 * 60 * 60);

          if (diffHours < 24) {
            var htmloption = '<button class="btn btn-primary" style="padding-top:0px">Delete</button>';
            return htmloption;
          } else {
            var htmloption = '<button class="btn btn-primary" disabled="on" style="padding-top:0px">Delete</button>';
            return htmloption;
          }
        },
      },


      {
        headerName: 'Requesting Date & Time',
        field: "requested_date_time",
        valueGetter: (param) => {
          if (param.data.requested_date_time !== "") {
            return getHyphenDDMMMYYYYHHMM(param.data.requested_date_time);
          } else {
            return "";
          }
        },
      },
      {
        headerName: 'TRTR Code',
        field: "transporter_code",

      },
      {
        headerName: 'Transporter Name',
        field: "transporter_name",
      },
      {
        headerName: 'MSIL Rake id',
        field: "msil_rake_id",
      },
      {
        headerName: 'Invoice Start',
        field: "invoice_start_date",
        valueGetter: (param) => {
          if (param.data.invoice_start_date !== "" && param.data.invoice_start_date !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.invoice_start_date);
          } else if (param.data.auto_invoice_start_date !== "" && param.data.auto_invoice_start_date !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.auto_invoice_start_date);
          }
          else{
            return "";
          }
        },
      },
      {
        headerName: 'Invoice End',
        field: "invoice_end_date",
        valueGetter: (param) => {
          if (param.data.invoice_end_date !== "" && param.data.invoice_end_date !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.invoice_end_date);
          }
           else if (param.data.auto_invoice_end_date !== "" && param.data.auto_invoice_end_date !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.auto_invoice_end_date);
          }
          else{
            return "";
          }
        },
      },
      {
        headerName: 'Empty Rake Gate In',
        field: "empty_rake_gate_in",
        valueGetter: (param) => {
          if (param.data.empty_rake_gate_in !== "" && param.data.empty_rake_gate_in !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.empty_rake_gate_in);
          }
           else if (param.data.auto_empty_rake_gate_in !== "" && param.data.auto_empty_rake_gate_in !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.auto_empty_rake_gate_in);
          }else{
            return "";
          }
        },
      },
      {
        headerName: 'Rake Placement',
        field: "rake_placement",
        valueGetter: (param) => {
          if (param.data.rake_placement !== "" && param.data.rake_placement !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.rake_placement);
          } else {
            return "";
          }
        },
      },
      {
        headerName: 'Rake Gate in to Rake Placement (in Hrs)',
        field: "rake_gate_in_to_rake_placement",
        valueGetter: (param) => {
          // Destructuring the required fields
          const { empty_rake_gate_in, auto_empty_rake_gate_in, rake_placement } = param.data;
      
          // Determine the empty rake gate in time (prefer manual, fallback to auto)
          const rakeGateIn = empty_rake_gate_in || auto_empty_rake_gate_in;
      
          if (rakeGateIn && rake_placement) {
            // Parsing the dates
            const rakeGateInDate = new Date(rakeGateIn);
            const rakePlacementDate = new Date(rake_placement);
      
            // Check if both dates are valid
            if (!isNaN(rakeGateInDate.getTime()) && !isNaN(rakePlacementDate.getTime())) {
              // Calculate the difference in milliseconds
              const diffMs = rakePlacementDate - rakeGateInDate;
      
              // Convert the difference to hours and minutes
              const diffHours = Math.floor(diffMs / (1000 * 60 * 60)); // Convert to hours
              const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60)); // Get remaining minutes
      
              return `${String(diffHours).padStart(2, '0')}:${String(diffMinutes).padStart(2, '0')}`; // HH:MM format
            } else {
              console.error('Invalid Date(s):', rakeGateIn, rake_placement);
            }
          }

          return ""; // Return empty string if dates are invalid or not available
        }
      },

      {
        headerName: 'Rake Release',
        field: "rake_release",
        valueGetter: (param) => {
          if (param.data.rake_release !== "" && param.data.rake_release !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.rake_release);
          } else {
            return "";
          }
        },
      },
      {
        headerName: 'Rake placement to release (in Hrs)',
        field: "rake_placement_to_release",
        valueGetter: (param) => {
          const { rake_release, rake_placement } = param.data;

          if (rake_release && rake_placement) {
            // Parse the dates
            const rakeReleaseDate = new Date(rake_release);
            const rakePlacementDate = new Date(rake_placement);

            // Check if both dates are valid
            if (!isNaN(rakeReleaseDate.getTime()) && !isNaN(rakePlacementDate.getTime())) {
              // Calculate the difference in milliseconds
              const diffMs = rakeReleaseDate - rakePlacementDate;

              // Convert the difference to hours and minutes
              const diffHours = Math.floor(diffMs / (1000 * 60 * 60)); // Convert to hours
              const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60)); // Get remaining minutes

              // Return the difference in HH:MM format
              return `${String(diffHours).padStart(2, '0')}:${String(diffMinutes).padStart(2, '0')}`; // HH:MM format
            } else {
              console.error('Invalid Date(s):', rake_release, rake_placement);
            }
          }

          return ""; // Return empty string if dates are invalid or not available
        }
      },
      {
        headerName: 'Engine on Train',
        field: "engine_on_train",
        valueGetter: (param) => {
          if (param.data.engine_on_train !== "" && param.data.engine_on_train !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.engine_on_train);
          } else  if (param.data.auto_engine_on_train !== "" && param.data.auto_engine_on_train !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.auto_engine_on_train);
          }else{
            return "";
          }
        },
      },
      {
        headerName: 'Rake release to Engine on train (in Hrs)',
        field: "rake_release_to_engine_on_train",
        valueGetter: (param) => {
          // Destructuring the required fields
          const { engine_on_train, auto_engine_on_train, rake_release } = param.data;
      
          // Determine the engine on train time (prefer manual, fallback to auto)
          const engineOnTrain = engine_on_train || auto_engine_on_train;
      
          if (engineOnTrain && rake_release) {
            // Parse the dates
            const engineOnTrainDate = new Date(engineOnTrain);
            const rakeReleaseDate = new Date(rake_release);
      
            // Check if both dates are valid
            if (!isNaN(engineOnTrainDate.getTime()) && !isNaN(rakeReleaseDate.getTime())) {
              // Calculate the difference in milliseconds
              const diffMs = engineOnTrainDate - rakeReleaseDate;
      
              // Convert the difference to hours and minutes
              const diffHours = Math.floor(diffMs / (1000 * 60 * 60)); // Convert to hours
              const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60)); // Get remaining minutes
      
              // Return the difference in HH:MM format
              return `${String(diffHours).padStart(2, '0')}:${String(diffMinutes).padStart(2, '0')}`; // HH:MM format
            } else {
              console.error('Invalid Date(s):', engineOnTrain, rake_release);
            }
          }
      
          return ""; // Return empty string if dates are invalid or not available
        }
      },
      {
        headerName: 'Rake Departure',
        field: "rake_departure",
        valueGetter: (param) => {
          if (param.data.rake_departure !== "" && param.data.rake_departure !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.rake_departure);
          } else if (param.data.auto_rake_departure !== "" && param.data.auto_rake_departure !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.auto_rake_departure);
          }else{
            return "";
          }
        },
      },
      {
        headerName: 'Rake Arrival at Destination',
        field: "rake_arrival_at_destination",
        valueGetter: (param) => {
          if (param.data.rake_arrival_at_destination !== "" && param.data.rake_arrival_at_destination !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.rake_arrival_at_destination);
          } else if (param.data.auto_rake_arrival_at_destination !== "" && param.data.auto_rake_arrival_at_destination !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.auto_rake_arrival_at_destination);
          }else {
            return "";
          }
        },
      },
      {
        headerName: 'Rake arrival to rake departure (in Days)',
        field: "rake_arrival_to_rake_departure",
        valueGetter: (param) => {
          // Destructuring manual and auto fields
          const { 
            rake_arrival_at_destination, 
            auto_rake_arrival_at_destination, 
            rake_departure, 
            auto_rake_departure 
          } = param.data;
      
          // Prefer manual fields first, fall back to auto fields
          const arrivalAtDestination = rake_arrival_at_destination || auto_rake_arrival_at_destination;
          const departureDate = rake_departure || auto_rake_departure;
      
          // Check if both values are available
          if (arrivalAtDestination && departureDate) {
            // Parse the dates
            const arrivalDateObj = new Date(arrivalAtDestination);
            const departureDateObj = new Date(departureDate);
      
            // Check if both dates are valid
            if (!isNaN(arrivalDateObj.getTime()) && !isNaN(departureDateObj.getTime())) {
              // Calculate the difference in milliseconds
              const diffMs = arrivalDateObj - departureDateObj; // Difference in milliseconds
      
              // Convert the difference to days
              const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24)); // Convert to days
      
              // Return the difference in days
              return diffDays; 
            } else {
              console.error('Invalid Date(s):', arrivalAtDestination, departureDate);
            }
          }
      
          return ""; // Return empty string if dates are invalid or not available
        }
      },
      {
        headerName: 'Last Mile Start',
        field: "last_mile_start",
        valueGetter: (param) => {
          if (param.data.last_mile_start !== "" && param.data.last_mile_start !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.last_mile_start);
          } else if (param.data.auto_last_mile_start !== "" && param.data.auto_last_mile_start !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.auto_last_mile_start);
          }else{
            return "";
          }
        },
      },
      {
        headerName: 'Last Mile End',
        field: "last_mile_end",
        valueGetter: (param) => {
          if (param.data.last_mile_end !== "" && param.data.last_mile_end !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.last_mile_end);
          } else if (param.data.auto_last_mile_end !== "" && param.data.auto_last_mile_end !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.auto_last_mile_end);
          }else{
            return "";
          }
        },
      },

    ];


    return (
      <div class="container-fluid">
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
        <div>
          {this.state.alert}
        </div>

        <div className="card col-xl-12">
          <div className="card-header">
            <h5>
              <i className="icofont icofont-vehicle-delivery-van cus-i"></i>{" "}
              <span>MSIB Operations Monitoring Screen</span>
              <button className="btn btn-info" style={{ float: "right", marginRight: "10px" }} onClick={this.addMsibDetails.bind(this)}>Add New MSIB Details</button>
              <div className="card-body">
                <div className="row">
                  <div
                    id="myGrid"
                    style={{ width: "100%", height: "478px" }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      modules={this.state.modules}
                      columnDefs={batchwisecolumns}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.rowData}
                      enableCharts={false}
                      onGridReady={this.onGridReady2}
                      onGridState={this.onGridState}
                      frameworkComponents={this.state.frameworkComponents}
                      onCellClicked={this.onCellClicked.bind(this)}
                      sideBar={this.state.sideBar}
                      stopEditingWhenGridLosesFocus={true}
                      paginationPageSize={this.state.paginationPageSize}
                      pagination={true}
                      gridOptions={{
                        context: { componentParent: this },
                      }}
                      enableRangeSelection={true}
                    />
                  </div>
                </div>
              </div>
            </h5>
          </div>

          <div className={"slide-r " + (this.state.bulkslide)} style={{ overflow: "scroll" }}>
            <h3 className="subH">New MSIB Operations Monitoring Details
              <span className="float-right fclose" style={{ marginRight: "12px", padding: "1px 8px", marginTop: "-2px" }} onClick={this.onClickHideAll.bind(this)}>x</span>
            </h3>
            <div className="slide-r-body" style={{ position: "relative" }}>
              <div className="container-fluid">
                <form id="initialAddingDetails" onSubmit={this.onClickAddDetails} className="row col-xl-12 col-lg-12">

                  {/* MSIL Rake ID */}
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>MSIL Rake Id<span style={{ color: 'red' }}> *</span> </label>
                    <input
                      type="text"
                      value={this.state.msil_rake_id}
                      name="msil_rake_id"
                      id="msil_rake_id"
                      className="form-control"
                      onChange={this.handleChangeRakeId}
                      autoComplete="off"
                    />
                  </div>

                  {/* Empty Rake Gate In */}
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Empty Rake Gate In<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime
                      value={this.state.empty_rake_gate_in}
                      onChange={(date) => this.handleDateChange(date, 'empty_rake_gate_in')}
                      closeOnSelect={false}
                      inputProps={{
                        name: 'empty_rake_gate_in',
                        autoComplete: 'off',
                        readOnly: true,
                        className: 'custom-datetime-input'
                      }}
                      dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
                    />
                  </div>

                  {/* Rake Placement */}
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Rake Placement<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime
                      value={this.state.rake_placement}
                      onChange={(date) => this.handleDateChange(date, 'rake_placement')}
                      closeOnSelect={false}
                      inputProps={{
                        name: 'rake_placement',
                        autoComplete: 'off',
                        readOnly: true,
                        className: 'custom-datetime-input'
                      }}
                      dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
                    />
                  </div>

                  {/* Rake Release */}
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Rake Release<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime
                      value={this.state.rake_release}
                      onChange={(date) => this.handleDateChange(date, 'rake_release')}
                      closeOnSelect={false}
                      inputProps={{
                        name: 'rake_release',
                        autoComplete: 'off',
                        readOnly: true,
                        className: 'custom-datetime-input'
                      }}
                      dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
                    />
                  </div>

                  {/* Engine on Train */}
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Engine on Train<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime
                      value={this.state.engine_on_train}
                      onChange={(date) => this.handleDateChange(date, 'engine_on_train')}
                      closeOnSelect={false}
                      inputProps={{
                        name: 'engine_on_train',
                        autoComplete: 'off',
                        readOnly: true,
                        className: 'custom-datetime-input'
                      }}
                      dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
                    />
                  </div>

                  {/* Rake Departure */}
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Rake Departure<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime
                      value={this.state.rake_departure}
                      onChange={(date) => this.handleDateChange(date, 'rake_departure')}
                      closeOnSelect={false}
                      inputProps={{
                        name: 'rake_departure',
                        autoComplete: 'off',
                        readOnly: true,
                        className: 'custom-datetime-input'
                      }}
                      dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
                    />
                  </div>

                  {/* Rake Arrival at Destination */}
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Rake Arrival at Destination<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime
                      value={this.state.rake_arrival_at_destination}
                      onChange={(date) => this.handleDateChange(date, 'rake_arrival_at_destination')}
                      closeOnSelect={false}
                      inputProps={{
                        name: 'rake_arrival_at_destination',
                        autoComplete: 'off',
                        readOnly: true,
                        className: 'custom-datetime-input'
                      }}
                      dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
                    />
                  </div>

                  {/* Last Mile Start */}
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Last Mile Start<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime
                      value={this.state.last_mile_start}
                      onChange={(date) => this.handleDateChange(date, 'last_mile_start')}
                      closeOnSelect={false}
                      inputProps={{
                        name: 'last_mile_start',
                        autoComplete: 'off',
                        readOnly: true,
                        className: 'custom-datetime-input'
                      }}
                      dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
                    />
                  </div>

                  {/* Last Mile End */}
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Last Mile End<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime
                      value={this.state.last_mile_end}
                      onChange={(date) => this.handleDateChange(date, 'last_mile_end')}
                      closeOnSelect={false}
                      inputProps={{
                        name: 'last_mile_end',
                        autoComplete: 'off',
                        readOnly: true,
                        className: 'custom-datetime-input'
                      }}
                      dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
                    />
                  </div>

                  <div className="form-group col-xl-3 col-lg-3">
                    <button type="submit" className="btn btn-success">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className={"slide-r " + (this.state.bulkslide1)} style={{ overflow: "scroll" }}>
            <h3 className="subH">Edit MSIB Operations Monitoring Details
              <span className="float-right fclose" style={{ marginRight: "12px", padding: "1px 8px", marginTop: "-2px" }} onClick={this.onClickHideAll.bind(this)}>x</span>
            </h3>
            <div className="slide-r-body" style={{ position: "relative" }}>
              <div className="container-fluid">
                <form id="initialAddingDetails"
                  onSubmit={this.onClickEditDetails.bind(this)}
                  className="row col-xl-12 col-lg-12">

                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>MSIL Rake Id<span style={{ color: 'red' }}> *</span> </label>
                    <input
                      type="text"
                      value={this.state.msil_rake_id}
                      name="msil_rake_id"
                      id="msil_rake_id"
                      className="form-control"
                      onKeyPress={this.handleKeyPressCodeRakeId}
                      autoComplete="off"
                      onChange={this.handleChangeRakeId}
                    />
                  </div>

                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Empty Rake Gate In<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime
                      value={this.state.empty_rake_gate_in}
                      onChange={(date) => this.handleDateChange(date, 'empty_rake_gate_in')}
                      closeOnSelect={false}
                      inputProps={{
                        name: 'empty_rake_gate_in',
                        autoComplete: 'off',
                        readOnly: true,
                        className: 'custom-datetime-input'
                      }}
                      dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
                    />
                  </div>


                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Rake Placement<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime
                      value={this.state.rake_placement}
                      disableCloseOnClickOutside={false}
                      onChange={(date) => this.handleDateChange(date, 'rake_placement')}
                      closeOnSelect={false}
                      inputProps={{ name: 'rake_placement', autoComplete: 'off', readOnly: true, className: 'custom-datetime-input' }}
                      dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
                    />

                  </div>

                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Rake Release<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime value={this.state.rake_release}
                      // disableCloseOnClickOutside={false}
                      onChange={(date) => this.handleDateChange(date, 'rake_release')}
                      closeOnSelect={false}
                      inputProps={{ name: 'rake_release', autoComplete: 'off', readOnly: true, className: 'custom-datetime-input' }}
                      dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
                    />
                  </div>

                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Engine on Train<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime value={this.state.engine_on_train}
                      // disableCloseOnClickOutside={false}
                      onChange={(date) => this.handleDateChange(date, 'engine_on_train')}
                      closeOnSelect={false}
                      inputProps={{ name: 'engine_on_train', autoComplete: 'off', readOnly: true, className: 'custom-datetime-input' }}
                      dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
                    />
                  </div>
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Rake Departure<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime value={this.state.rake_departure}
                      onChange={(date) => this.handleDateChange(date, 'rake_departure')}
                      // disableCloseOnClickOutside={false}
                      closeOnSelect={false}
                      inputProps={{ name: 'rake_departure', autoComplete: 'off', readOnly: true, className: 'custom-datetime-input' }}
                      dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
                    />
                  </div>
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Rake Arrival at Destination<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime value={this.state.rake_arrival_at_destination}
                      onChange={(date) => this.handleDateChange(date, 'rake_arrival_at_destination')}
                      // disableCloseOnClickOutside={false}
                      closeOnSelect={false}
                      inputProps={{ name: 'rake_arrival_at_destination', autoComplete: 'off', readOnly: true, className: 'custom-datetime-input' }}
                      dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
                    />
                  </div>
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Last Mile Start<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime value={this.state.last_mile_start}
                      // disableCloseOnClickOutside={false}
                      onChange={(date) => this.handleDateChange(date, 'last_mile_start')}
                      closeOnSelect={false}
                      inputProps={{ name: 'last_mile_start', autoComplete: 'off', readOnly: true, className: 'custom-datetime-input' }}
                      dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
                    />

                  </div>

                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Last Mile End<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime value={this.state.last_mile_end}
                      // disableCloseOnClickOutside={false}
                      onChange={(date) => this.handleDateChange(date, 'last_mile_end')}
                      closeOnSelect={false}
                      inputProps={{ name: 'last_mile_end', autoComplete: 'off', readOnly: true, className: 'custom-datetime-input' }}
                      dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
                    />
                  </div>

                  <div className="form-group col-xl-3 col-lg-3">
                    <button type="submit" className="btn btn-success">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>


        </div>


        <div className={"dataLoadpage " + (this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
          <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>
    );
  }
}



function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
  this.eInput = document.createElement('input');
  this.eInput.value = params.value;

  // Get today's date and format it as dd-mm-yy
  var today = new Date();

  // https://jqueryui.com/datepicker/
  $(this.eInput).datetimepicker({
    dateFormat: "dd-mm-yy",
    changeMonth: true,
    changeYear: true,
    timepicker: true,
    // maxDate: today // Set the maxDate option to today's date
  });
};


// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
  return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
  this.eInput.focus();
  this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
  return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
  // but this example is simple, no cleanup, we could
  // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
  // and we could leave this method out also, false is the default
  return false;
};
function loadDateTimeScript() {
  // alert("timeDate");
  $(".datetimepicker_datew").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y",
    // minDate: true,
    timepicker: false,
    closeOnDateSelect: true,
    scrollMonth: false,
    scrollInput: false,
  });
  $(".datetimepicker_dateo").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y",
    minDate: true,
    timepicker: false,
    closeOnDateSelect: true,
    scrollMonth: false,
    scrollInput: false,
  });
  $(".cargo_date").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y",
    minDate: true,
    timepicker: false,
  });
}
function formatDate(dateString) {
  if (dateString != "Invalid Date" && dateString != "" && dateString != "NaN-NaN-NaN" && dateString != undefined && dateString != 'undefined') {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }
  else {
    return ""
  }
}


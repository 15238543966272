import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import axios from 'axios';
import ComponentIndex from './settingsFormComponent';
 
var redirectURL = require('../redirectURL');

var googleAnalytics = require("../common/googleanalytics");
export default class Configurations extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			showdata:this.displayData,
			activetab:'',
			alert:null,
            show: false,
			basicTitle:'',
			dept_code : "",
            basicType:"default",
			usergridstate:[],
			screenurl:"",
			screentitle:"",
			pagetitle:"Settings",
				eventGridAction: "gridAction",
		};
		this.onClickGetForm = this.onClickGetForm.bind(this);
    }
    componentDidMount(){
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

		var dept_code = "";
		if(this.props.match.path == "/sndconfigurations"){
			dept_code = "SNDG"
		}
    	if(this.props.match.path == "/prtconfigurations"){
			dept_code = "LOG-PRT"
		}
		if(this.props.match.path == "/tnpconfigurations"){
			dept_code = "LOG-TNP"
		}
		if(this.props.match.path == "/scconfigurations"){
			dept_code = "LOG-SC"
		}
		this.setState({
			dept_code : dept_code
		})
	}
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }
   async onClickGetForm(event){

	if (googleAnalytics.page.enableGA) {
		let pageTitle = this.state.pagetitle;
		let eventOptions = {
			"category": pageTitle,
			"action": this.state.eventGridAction,
			"label": "Form Action",
		}
		googleAnalytics.logEvent(eventOptions);
	}
		//console.log("field", event.target.id);
		var field = event.target.id;
		this.setState({
			activetab:field
		}); 
		
		let ComponentInfo = ComponentIndex[field];
		
		this.displayData=await (<ComponentInfo context={this} deptCode = {this.state.dept_code} ></ComponentInfo>);
				
		this.setState({
			showdata : this.displayData,
		});
		
	} 
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
	}
	
	showAlert = () => {
        this.setState({
			show: true, basicType:'success', basicTitle:"Success",
						
        });
	}
	showErrorAlert = () => {
        this.setState({
			show: true, basicType:'danger', basicTitle:"Error",
						
        });
	}

	hideAlert = () => {
        this.setState({
            alert: null
        });
    }

    render(){
    	
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				<i className="icon-settings cus-i"></i> <span>Configurations </span>
				       			</h5>
				   			</div>
				
				   			<div className="card-body row">
								<div className="col-xl-3 col-lg-4">
									<div className="tree ">
										<ul>
											<li><span><a data-toggle="collapse" href="#Web" aria-expanded="false" aria-controls="Web"><i className="collapsed"><i className="fa fa-cogs"></i></i>
											<i className="expanded"><i className="fa fa-folder-open"></i></i> Settings</a></span>
											<div id="Web" className="collapse show">
												<ul>
													{/* <li>
														<span className={(this.state.activetab=='ontimedeliver')?"active":""}>
															<i className="fa fa-external-link"></i>
															<a href="javascript:void(0);" onClick={this.onClickGetForm.bind(this)} id="ontimedeliver"> On time delivery</a>
														</span>
													</li>
													<li>
														<span className={(this.state.activetab=='routedeviate')?"active":""}>
															<i className="fa fa-external-link"></i>
															<a href="javascript:void(0);" onClick={this.onClickGetForm.bind(this)} id="routedeviate"> Route deviation</a>
														</span>
													</li> */}
													<li>
														<span className={(this.state.activetab=='nightdriving')?"active":""}>
															<i className="fa fa-external-link"></i>
															<a href="javascript:void(0);" onClick={this.onClickGetForm.bind(this)} id="nightdriving"> Night Driving</a>
														</span>
													</li>
													<li>
														<span className={(this.state.activetab=='overspeed')?"active":""}>
															<i className="fa fa-external-link"></i>
															<a href="javascript:void(0);" onClick={this.onClickGetForm.bind(this)} id="overspeed"> Overspeed</a>
														</span>
													</li>
													<li>
														<span className={(this.state.activetab=='nogpsdata')?"active":""}>
															<i className="fa fa-external-link"></i>
															<a href="javascript:void(0);" onClick={this.onClickGetForm.bind(this)} id="nogpsdata"> No Gps Data</a>
														</span>
													</li>
													<li>
														<span className={(this.state.activetab=='enroute')?"active":""}>
															<i className="fa fa-external-link"></i>
															<a href="javascript:void(0);" onClick={this.onClickGetForm.bind(this)} id="enroute"> Enroute Stoppages</a>
														</span>
													</li>
													{/* <li>
														<span className={(this.state.activetab=='transitdelay')?"active":""}>
															<i className="fa fa-external-link"></i>
															<a href="javascript:void(0);" onClick={this.onClickGetForm.bind(this)} id="transitdelay"> Transit Delay</a>
														</span>
													</li> */}
													{(this.state.dept_code != "LOG-PRT")?
													<div>
													<li>
														<span className={(this.state.activetab=='loadingdelay')?"active":""}>
															<i className="fa fa-external-link"></i>
															<a href="javascript:void(0);" onClick={this.onClickGetForm.bind(this)} id="loadingdelay"> Loading Delay</a>
														</span>
													</li>
													<li>
														<span className={(this.state.activetab=='unloadingdelay')?"active":""}>
															<i className="fa fa-external-link"></i>
															<a href="javascript:void(0);" onClick={this.onClickGetForm.bind(this)} id="unloadingdelay"> Unloading Delay</a>
														</span>
													</li>
													</div>
													:""}
													{(this.state.dept_code != "LOG-TNP")?
														<div>
													<li>
													
														<span className={(this.state.activetab=='dealerradius')?"active":""}>
															<i className="fa fa-external-link"></i>
															<a href="javascript:void(0);" onClick={this.onClickGetForm.bind(this)} id="dealerradius"> Dealer Radius</a>
														</span>
														

													</li>
													</div>
													:""}
													{(this.state.dept_code == "LOG-TNP")?
														<div>
															<li>
																<span className={(this.state.activetab=='noentrytimes')?"active":""}>
																	<i className="fa fa-external-link"></i>
																	<a href="javascript:void(0);" onClick={this.onClickGetForm.bind(this)} id="noentrytimes"> No Entry Times</a>
																</span>
															</li>
														</div>
													:""}
													{(this.state.dept_code == "LOG-TNP")?
														<div>
															<li>
																<span className={(this.state.activetab=='transittimes')?"active":""}>
																	<i className="fa fa-external-link"></i>
																	<a href="javascript:void(0);" onClick={this.onClickGetForm.bind(this)} id="transittimes"> D22 Transit Times</a>
																</span>
															</li>
														</div>
													:""}
													{(this.state.dept_code == "LOG-TNP")?
														<div>
															<li>
																<span className={(this.state.activetab=='truckavailabilitydistance')?"active":""}>
																	<i className="fa fa-external-link"></i>
																	<a href="javascript:void(0);" onClick={this.onClickGetForm.bind(this)} id="truckavailabilitydistance"> Truck Availability Distance</a>
																</span>
															</li>
														</div>
													:""}
													{(this.state.dept_code == "LOG-TNP")?
														<div>
															<li>
																<span className={(this.state.activetab=='tnpdealerradius')?"active":""}>
																	<i className="fa fa-external-link"></i>
																	<a href="javascript:void(0);" onClick={this.onClickGetForm.bind(this)} id="tnpdealerradius"> Parking Radius</a>
																</span>
															</li>
														</div>
													:""}
													{(this.state.dept_code == "LOG-TNP")?
														<div>
															<li>
																<span className={(this.state.activetab=='tnpreturndealerradius')?"active":""}>
																	<i className="fa fa-external-link"></i>
																	<a href="javascript:void(0);" onClick={this.onClickGetForm.bind(this)} id="tnpreturndealerradius"> Return Dealer Radius</a>
																</span>
															</li>
														</div>
													:""}

													{/* <li>
														<span className={(this.state.activetab=='localmovement')?"active":""}>
															<i className="fa fa-external-link"></i>
															<a href="javascript:void(0);" onClick={this.onClickGetForm.bind(this)} id="localmovement"> Local Movement</a>
														</span>
													</li>

													<li>
														<span className={(this.state.activetab=='unusualenroute')?"active":""}>
															<i className="fa fa-external-link"></i>
															<a href="javascript:void(0);" onClick={this.onClickGetForm.bind(this)} id="unusualenroute"> Unusual en-route stoppages</a>
														</span>
													</li> */}
												</ul>
											</div>
											</li>
										</ul>

									</div>
								</div>

								<div className="col-xl-8 col-lg-8">
										{this.state.showdata}
								</div>
                            </div>
				   		</div>	
				   	</div>
				 </div>
            </div>
              
        );
    }
}



import React, { Component } from "react";
import CSVFileValidator from 'csv-file-validator';
import moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MenuModule } from "@ag-grid-enterprise/menu";
import Select from "react-select";
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import $ from "jquery";
import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import DrawMap2 from '../common/drawmap2';
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import * as Datetime from 'react-datetime';
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import "@ag-grid-community/core/dist/styles/ag-grid.css";

import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { color } from "highcharts";
import download from 'js-file-download';
import { reverse } from "lodash";
// import { formatDate } from "@fullcalendar/react";
var redirectURL = require("../redirectURL");
var infoBox = require('../common/google-infowindow');

var googleAnalytics = require("../common/googleanalytics");
var map;
var markersArray;
var viewMarkersArr = [];
export default class TransporterGPSReview extends Component {
  constructor(props) {
    super(props);
    var date2 = new Date();
    var currentDateTime = moment(date2).format("YYYY-MM-DD");
    var startDate1 = moment(date2).subtract(90, 'days').format("YYYY-MM-DD")
    this.state = {
      modules: AllModules,
      eventFormAction: "formAction",
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        width: 160,
        resizable: true,
      },
      rowSelection: "single",
      eventAction: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      bulkslide: "",
      bulkslide1: "",
      bulkslide2: "",
      bulkslide3: "",
      bulkslide4: "",
      Main_rowid: "",
      slider_header: "",
      slider_type: "",
      files: [],
      files2: [],
      timelinesmarkers: [],
      truck_no: "",
      rake_name: [{ label: '', value: '' }],
      rake_names: [],
      sliderRouteTranslate: '',
      rownode: '',
      baseRouteCoords: [],
      stabling_end_map: "",
      stabling_start_map: "",
      filterlspData: [],
      lsp_name_filter: ({ value: "ALL", label: "ALL" }),
      doc_number: '',

      type: [],
      mapinfo: '',
      force_major: [],
      msil_rake_id: '',
      arrival_at_vicinity: '',
      stabling_start: '',
      stabling_end: '',
      mr_rr_value: '',
      lsp_remarks: "",

      sliderbulkupload: "",
      rowModelhighchartoptionsType: "enterprise",
      frameworkComponents: {
        consignmentActions: ConsignmentActions,
      },
      rowGroupPanelShow: "always",
      statusBar: {

      },
      paginationPageSize: 50,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      startDate: startDate1,
      endDate: currentDateTime,
      animateRows: true,
      dept_code: "",
      pagetitle: "",
      csvData: 0,
      overly: "show-n",
      loadshow: "show-n",
      pagetitle: "",
      eventGridAction: "gridAction",
      csvcontent: [],
      file: "",
      batch_name: "",
      cluster_breaks: "2",
      cluster_radius: "0.5",
      notification_emails: "",

      bulkslidebulk: '',
      newValue: "",
      rowData: [],
      allrowData:[],
      rowId: "",
      show: false,
      basicTitle: "",
      basicType: "",
      month:"",
      end_of_month:"",
      showButton:"",
      allRowData:[],
      gps_provider:"",
      access_control:0
    };
  }
  async componentDidMount() {
    loadDateTimeScript();
    var review_month = moment.parseZone(new Date()).format("YYYY-MM")
    var end_of_month = moment.parseZone(new Date()).endOf('month').format("YYYY-MM-DD")
    var month  = moment.parseZone(new Date(review_month+"-01 00:00:00")).utcOffset("+05:30")._d
    var endOfMonth  = moment.parseZone(new Date(end_of_month+" 23:59:59")).utcOffset("+05:30")._d
    if(endOfMonth > month){
      var showButton = "true" 
    }else{
      var showButton = "false" 
    }  
    this.setState({
      month:review_month,
      end_of_month:end_of_month,
      showButton : showButton
    })
    var params={
      review_month:review_month
    }
    if(localStorage.getItem("transportercode") != undefined && localStorage.getItem("transportercode") != null && localStorage.getItem("transportercode") != "" && localStorage.getItem("transportercode") != "undefined"){
      var tpt_code = JSON.parse(localStorage.getItem("transportercode"))
      params.transporter_code = tpt_code[0]
    }
    await redirectURL.post("/dashboard/getTransportersgpsreviewData",params).then((response)=>{
        var resp = response.data.data
        this.setState({
            rowData:resp,
            allRowData:resp,
            gps_provider:response.data.gps_provider
        })
    })
    await this.getIntialAccessControl(review_month)
  }
  getIntialAccessControl =(review_month)=>{
    var params ={
      "review_month" :review_month 
    }
    redirectURL.post("/dashboard/getInitialAccessControlData",params).then((response)=>{
        var resp = response.data.data
        if(resp.length > 0){
          this.setState({
            access_control:1
          })
        }else{
          this.setState({
            access_control:0
          })
        }
    })

  }
  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
    });
  }

  reset() {
    window.location.reload()
  }

  async onCellClicked(event) {
  }
 
  onGridReady = params => {
    this.gridApi = params.api;
    // //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
  };
  onGridState = () => {
    //console.log(this.gridApi);
    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();
    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
    /*Get Current Pivot Columns State and Store in this.pivotColumns,
     * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
}
getfilterReviewData=async(event)=>{
  event.preventDefault()
 
}
handlerStartDateTime = async(event, currentDate, selectedDate) => {
    this.setState({
      overly: 'show-m',
      loadshow: 'show-m',
    });
      var d = new Date(event._d);
      var selDay = (d.getMonth() + 1);
      if(parseInt(selDay) < 10)
      {
          selDay = "0"+selDay; 
      }
      var plan_month = d.getFullYear() + "-" + selDay;
      var end_of_month = moment.parseZone(new Date()).endOf('month').format("YYYY-MM-DD")
      var endOfMonth = moment.parseZone(new Date(end_of_month+" 23:59:59")).utcOffset("+05:30")._d
      var main_plan_month = moment.parseZone(new Date(plan_month+"-01 00:00:00")).utcOffset("+05:30")._d
      if(endOfMonth > main_plan_month){
        var showButton = "true"
      }else{
        var showButton = "false"
      }
      this.setState({
        month: plan_month,
        showButton : showButton
    })
    var params = {
        "review_month":plan_month
    };
    if(localStorage.getItem("transportercode") != undefined && localStorage.getItem("transportercode") != null && localStorage.getItem("transportercode") != "" && localStorage.getItem("transportercode") != "undefined"){
      var tpt_code = JSON.parse(localStorage.getItem("transportercode"))
      params.transporter_code = tpt_code[0]
    }
      // console.log(params,"311")
      await redirectURL.post("/dashboard/getTransportersgpsreviewData",params).then((response)=>{
          var resp = response.data.data
          this.setState({
              rowData:resp,
              allRowData:resp,
              loadshow:"show-n",
              overly:"show-n"
          })
      })
      this.getIntialAccessControl(plan_month)
}

renderMonthContent = (month, shortMonth, longMonth, day) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

    return <span title={tooltipText}>{shortMonth}</span>;
}
  render() {

    const columnWithDefs = [];
    columnWithDefs.push({
            headerName : "Parameters",
            field: "check_parameters",
            width: 120,
            filter: true,
            cellClass: function(params){
                if(params.data.check_parameters !=""){
                    if(params.data.check_parameters == "HARDWARE"){
                        return ["cellstylegridY","pstBg"]
                    }
                    else if(params.data.check_parameters == "SOFTWARE"){
                        return ["cellstylegridM","pstBg"]
                    }
                    else if(params.data.check_parameters == "FIELD SERVICE"){
                        return ["cellstylegridSk","pstBg"]
                    }
                        
                }
                return null
            },
            rowSpan: function(params){
                if(params.data.check_parameters != ""){
                    if(params.data.check_parameters == "HARDWARE"){
                        return 5;
                    }else if(params.data.check_parameters == "SOFTWARE"){
                        return 6
                    }else if(params.data.check_parameters == "FIELD SERVICE"){
                        return 4
                    }
                }
                return 1
            },
        },
        {
            headerName : "Category",
            field: "category",
            width: 180,
            filter: true,
            // cellClass: function(params){
            //     return ["cellstylegridY","pstBg"]
            // },
        },
        {
            headerName : "Description",
            field: "description",
            width: 300,
            filter: true,
            // cellClass: function(params){
            //     return ["cellstylegridY","pstBg"]
            // },
        },
        {
            headerName : "Ratings",
            field: "ratings",
            width: 120,
            filter: true,
            // cellClass: function(params){
            //     return ["cellstylegridY","pstBg"]
            // },
        },
        {
            headerName : "Remarks",
            field: "remarks",
            width: 120,
            filter: true,
            // cellClass: function(params){
            //     return ["cellstylegridY","pstBg"]
            // },
        },
    )
    var month = window.btoa(this.state.month)
    const addFormPath = "/transportergpsreviewscreenform/"+month
    const allrowData = this.state.allRowData
    return (
      <div class="container-fluid">
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
        <div className="row card-body" style={{display:"flex",justifyContent:"space-between"}}>
            {/* <form method="POST" id="upform" className="theme-form col-xl-12 col-lg-12" onSubmit={this.getfilterReviewData.bind(this)}> */}
                {/* <div className="row"> */}
                <div className="col-xl-12 col-lg-12 form-group">
                  <label style={{fontSize:"16px"}}>GPS PROVIDER : </label>
                  <span style={{fontSize:"16px",fontWeight:"bold",color:"#FF0000"}}> {this.state.gps_provider}</span>
                </div>
                  <div className="col-xl-2 col-lg-2 form-group">
                        <label>Month<span style={{color:"#FF0000"}}>*</span></label>
                        {/* <input type="text" placeholder="Select Month" id="plan_month" autocomplete="off" className="datetimepicker_date form-control"  required /> */}
                        <Datetime
                            value={this.state.month}
                            disableCloseOnClickOutside={false}
                            closeOnSelect={true}
                            inputProps={{
                                placeholder: 'Select Month',
                                name: 'month',
                                autoComplete: 'off',
                                readOnly: true
                            }}
                            renderMonthContent={this.renderMonthContent.bind(this)}
                            dateFormat="YYYY-MM"
                            name="month"
                            onChange={this.handlerStartDateTime.bind(this)}
                        />
                  </div>
                  {/* <div class="form-group col-xl-3 col-lg-3" style={{marginTop:"31px"}}>
                          <button type="submit" class="btn btn-success">
                              Submit
                          </button> */}
                  {/* </div> */}
                {/* </div> */}
                    
            {/* </form>    */}
        </div>
        <div className="card col-xl-12">
          <div className="card-header">
            <h5>
              <i className="icofont icofont-vehicle-delivery-van cus-i"></i>{" "}
              <span>Transporter Review for GPS Service Provider</span>
              {(localStorage.getItem("user_type") == "TRANSPORTER" && (localStorage.getItem('transportercode') != 'undefined' && localStorage.getItem('transportercode') != undefined))
                ?
                  (allrowData.length > 0 )?
                  "":(this.state.access_control == 1)?
                  <a href={addFormPath} className="btn btn-info" style={{ float: "right", marginRight: "10px" }} >ADD</a>:
                  <a href={addFormPath} className="btn btn-info disabled" disabled={true} style={{ float: "right", marginRight: "10px" }} >ADD</a>
                : ""
                }
              <div className="card-body">
                <div className="row">
                  <div
                    id="myGrid"
                    style={{ width: "100%", height: "500px" }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      modules={this.state.modules}
                      columnDefs={columnWithDefs}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.rowData}
                      rowSelection={this.state.rowSelection}
                      enableCharts={false}
                      onGridReady={this.onGridReady}
                      onGridState={this.onGridState}
                      frameworkComponents={this.state.frameworkComponents}
                    //   rowClassRules={rowClassRules}
                      sideBar={this.state.sideBar}
                      stopEditingWhenGridLosesFocus={true}
                      onCellClicked={this.onCellClicked.bind(this)}
                    //   paginationPageSize={this.state.paginationPageSize}
                    //   pagination={true}
                      enableCellChangeFlash={true}
                      suppressCellFlash={true}
                      gridOptions={{
                        context: { componentParent: this },
                        suppressRowTransform: true, 
                      }}
                      // onCellClicked={this.onCellRowClicked.bind(this)}
                      enableRangeSelection={true}
                    />
                  </div>
                </div>
              </div>
            </h5>
          </div>

          {/* work in progress Fresh Incoming rakes */}

        </div>
        <div className={"dataLoadpage " + (this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
          <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>
    );
  }
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_date').datetimepicker({
		format:'Y-m',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}



function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd hh:mm",
        changeMonth: true,
        changeYear: true,
        timepicker: true,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

import React, { Component } from 'react';
import CSVFileValidator from 'csv-file-validator';
import ReactDOM from 'react-dom';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import $ from "jquery";
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import Counters from '../layouts/consignmentkpicountersComponent';

import CountUp from 'react-countup';

import { AgGridReact } from "@ag-grid-community/react";

import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";

import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

var redirectURL = require('../redirectURL');

export default class FleetDetailsTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebartitle: "",
      sliderRouteTranslate: '',
      records: [],
      overly: "show-n",
      loadshow: "show-n",
      rowDatafornonreporting: [],
      rowDataforInspCarriers: [],
      totalTrucksCount: 0,
      trucksWithGpsDataCount: 0,
      trucksWithNoTransportersCount: 0,
      gpsDataNotReportedTodayCount: 0,
      totalActiveConsignmentsCount: 0,
      gpsNotTodayActiveConsignmentsCount: 0,
      totalInactiveConsignmentsCount: 0,
      ontripwithgps: 0,
      ontripwithnogps24hrs: 0,
      israil: 0,
      ontripgpsna: 0,
      railtotlaontrip: 0,
      atdealertruckcnt: 0,
      atdealernogps: 0,
      primaryontrips: 0,
      secondaryontrips: 0,
      deptcode: '',
      counterString: "",
      msil_fleet: [],
      msil_fleet_truck_type: '',
      new_carriers: [],
      new_carriers_truck_type: '',
      old_carriers: [],
      old_carriers_truck_type: '',
      exp_carriers_in_curr_mon: [],
      exp_carriers_in_curr_mon_truck_type: '',
      exp_carriers_in_nxt_mon: [],
      exp_carriers_in_nxt_mon_truck_type: '',

      carriers_insp: 0,
      carriers_onhold: 0,
      yesterday_carriers_insp: 0,
      yesterday_carriers_onhold: 0,
      sndnonreporting_data: 0,
      gps_intallation_pending_data: 0,


      msil_fleet_trucks: [],
      new_carriers_trucks: [],
      old_carriers_trucks: [],
      exp_carriers_in_curr_mon_trucks: [],
      exp_carriers_in_nxt_mon_trucks: [],
      carriers_onhold_trucks: [],
      carriers_insp_trucks: [],
      yesterday_carriers_onhold_trucks: [],
      yesterday_carriers_insp_trucks: [],
      sndnonreporting_data_trucks: [],
      gps_installation_pending_trucks: [],
      bulkslidebulk: '',
      LoiData: [],
      rowGroupPanelShow: "always",
      show: false,
      basicTitle: "",
      basicType: "default",
      statusBar: {
        // statusPanels: [
        //   {
        //     statusPanel: "agTotalAndFilteredRowCountComponent",
        //     align: "left",
        //   },
        //   {
        //     statusPanel: "agTotalRowCountComponent",
        //     align: "center",
        //   },
        //   { statusPanel: "agFilteredRowCountComponent" },
        //   { statusPanel: "agSelectedRowCountComponent" },
        //   { statusPanel: "agAggregationComponent" },
        // ],
      },
      paginationPageSize: 50,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
    }

  }

  async componentDidMount() {
   var transporterCode = window.atob(this.props.match.params.transporterCode)
    console.log(transporterCode, 'transporterCode');

    this.setState({
      transporter_code: transporterCode,
      overly: "show-m",
      loadshow: "show-m",
    })
    var params = {
      transporter_code: transporterCode
    };

    // console.log(params, 'params');
    const LOIData = await redirectURL.post("/consignments/getloidata");
    // console.log(LOIData.data, 'LOIData', LOIData)
    if (transporterCode != "ALL") {
      var filteredData = LOIData.data.filter(item => item.transporter_code === transporterCode);
    } else {
      var filteredData = LOIData.data;
    }
    
    this.setState({
      LoiData: filteredData
    })
    try {

      const response = await redirectURL.post("/consignments/getFleetDetailsForLSPCEO", params);

      var responseData = response.data.data;
      console.log('API Response:', response.data.data);

      console.log('Response Data:', (responseData));
      var msil_fleet = responseData
      var new_carriers = responseData.filter((e) => e.new_old == "New")
      var old_carriers = responseData.filter((f) => f.new_old == "Old")
      var exp_carriers_in_curr_mon = response.data.data1
      var exp_carriers_in_nxt_mon = response.data.data2


      var counterString = "";
      const uniqueTruckTypes = new Set(msil_fleet.map(item => item["truck_type"]));
      var i;
      console.log(uniqueTruckTypes)
      uniqueTruckTypes.forEach(function (truck_type) {
        console.log(truck_type, "truck_type")
        let truck_type_count = msil_fleet.filter(e => e.truck_type == truck_type).length;
        counterString += ", " + truck_type + ":" + truck_type_count;
      })
      counterString = counterString.slice(1);
      console.log(counterString, "counterString")


      var new_carriers_truck_type = "";
      const uniqueTruckTypes1 = new Set(new_carriers.map(item => item["truck_type"]));
      var i;
      console.log(uniqueTruckTypes1)
      uniqueTruckTypes1.forEach(function (truck_type) {
        console.log(truck_type, "truck_type")
        let truck_type_count = new_carriers.filter(e => e.truck_type == truck_type).length;
        new_carriers_truck_type += ", " + truck_type + ":" + truck_type_count;
      })
      new_carriers_truck_type = new_carriers_truck_type.slice(1);



      var old_carriers_truck_type = "";
      const uniqueTruckTypes2 = new Set(old_carriers.map(item => item["truck_type"]));
      var i;
      console.log(uniqueTruckTypes2)
      uniqueTruckTypes2.forEach(function (truck_type) {
        console.log(truck_type, "truck_type")
        let truck_type_count = old_carriers.filter(e => e.truck_type == truck_type).length;
        old_carriers_truck_type += ", " + truck_type + ":" + truck_type_count;
      })
      old_carriers_truck_type = old_carriers_truck_type.slice(1);

      var exp_carriers_in_curr_mon_truck_type = "";
      const uniqueTruckTypes3 = new Set(exp_carriers_in_curr_mon.map(item => item["truck_type"]));
      var i;
      console.log(uniqueTruckTypes3)
      uniqueTruckTypes3.forEach(function (truck_type) {
        console.log(truck_type, "truck_type")
        let truck_type_count = exp_carriers_in_curr_mon.filter(e => e.truck_type == truck_type).length;
        exp_carriers_in_curr_mon_truck_type += ", " + truck_type + ":" + truck_type_count;
      })
      exp_carriers_in_curr_mon_truck_type = exp_carriers_in_curr_mon_truck_type.slice(1);

      var exp_carriers_in_nxt_mon_truck_type = "";
      const uniqueTruckTypes4 = new Set(exp_carriers_in_nxt_mon.map(item => item["truck_type"]));
      var i;
      console.log(uniqueTruckTypes4)
      uniqueTruckTypes4.forEach(function (truck_type) {
        console.log(truck_type, "truck_type")
        let truck_type_count = exp_carriers_in_nxt_mon.filter(e => e.truck_type == truck_type).length;
        exp_carriers_in_nxt_mon_truck_type += ", " + truck_type + ":" + truck_type_count;
      })
      exp_carriers_in_nxt_mon_truck_type = exp_carriers_in_nxt_mon_truck_type.slice(1);


      await this.setState({
        msil_fleet: msil_fleet,//msil_fleet
        counterString: counterString,
        msil_fleet_trucks: msil_fleet,

        new_carriers: new_carriers,//new_carriers
        new_carriers_truck_type: new_carriers_truck_type,//new_carriers_truck_type
        new_carriers_trucks: new_carriers,//new_carriers_trucks

        old_carriers: old_carriers,//old_carriers
        old_carriers_truck_type: old_carriers_truck_type,//old_carriers_truck_type
        old_carriers_trucks: old_carriers,//old_carriers_trucks

        exp_carriers_in_curr_mon: exp_carriers_in_curr_mon,//exp_carriers_in_curr_mon
        exp_carriers_in_curr_mon_truck_type: exp_carriers_in_curr_mon_truck_type,
        exp_carriers_in_curr_mon_trucks: exp_carriers_in_curr_mon,//exp_carriers_in_curr_mon_trucks

        exp_carriers_in_nxt_mon: exp_carriers_in_nxt_mon,//exp_carriers_in_nxt_mon
        exp_carriers_in_nxt_mon_truck_type: exp_carriers_in_nxt_mon_truck_type,
        exp_carriers_in_nxt_mon_trucks: exp_carriers_in_nxt_mon,//exp_carriers_in_nxt_mon_trucks
      })
    } catch (error) {
      console.log(error);
    }
    try {
      const response1 = await redirectURL.post("/consignments/getFleetDetails2ForLSPCEO", params);
      var carriers_onhold = response1.data.carriers_on_load_month
      var yesterday_carriers_onhold = response1.data.carriers_on_load_yesterday
      var carriers_insp = response1.data.carriers_inspected_month
      var yesterday_carriers_insp = response1.data.carriers_inspected_yesterday
      var sndnonreporting_data = response1.data.non_reporting_carriers
      var gps_installation_pending_data = response1.data.gps_installation_pending
      this.setState({
        carriers_onhold: carriers_onhold,//carriers_onhold
        carriers_onhold_trucks: carriers_onhold,//carriers_onhold_trucks

        carriers_insp: carriers_insp,//carriers_insp
        carriers_insp_trucks: carriers_insp,//carriers_insp_trucks

        yesterday_carriers_onhold: yesterday_carriers_onhold,//yesterday_carriers_onhold
        yesterday_carriers_onhold_trucks: yesterday_carriers_onhold,//yesterday_carriers_onhold_trucks

        yesterday_carriers_insp: yesterday_carriers_insp,//yesterday_carriers_insp
        yesterday_carriers_insp_trucks: yesterday_carriers_insp,//yesterday_carriers_insp_trucks

        sndnonreporting_data: sndnonreporting_data,//sndnonreporting_data
        sndnonreporting_data_trucks: sndnonreporting_data,//sndnonreporting_data_trucks

        gps_installation_pending_data: gps_installation_pending_data,//gps_installation_pending_data
        gps_installation_pending_trucks: gps_installation_pending_data,//gps_installation_pending_trucks

        overly: "show-n",
        loadshow: "show-n",
      })
    }
    catch (error) {
      console.log(error);
    }
  }

  onCloseRouteDiv = () => {
    this.setState({
      uploadDivWidth: '0%',
      sliderRouteTranslate: "",
      sliderCriticalTranslate: '',
      showDiv: 'show-n',
      uploadFile: '',
      file: '',
      csvmessage: '',
      csverrmessage: '',
      overly: 'show-n'
    });
  }



  onClickCounter(data) {
    //this.props.context.onClickCounterShowData(data)
    console.log(data, 'count')
    if (data == "msil_fleet_trucks") {
      this.setState({
        sidebartitle: "MSIL FLEET",
        rowData: this.state.msil_fleet_trucks
      })
    } else if (data == "new_carriers_trucks") {
      this.setState({
        sidebartitle: "New Carriers Trucks",
        rowData: this.state.new_carriers_trucks
      })
    } else if (data == "old_carriers_trucks") {
      this.setState({
        sidebartitle: "Old Carriers Trucks",
        rowData: this.state.old_carriers_trucks
      })
    } else if (data == "exp_carriers_in_curr_mon_trucks") {
      this.setState({
        sidebartitle: "Expiry Carriers in Current Month Trucks",
        rowData: this.state.exp_carriers_in_curr_mon_trucks
      })
    } else if (data == "exp_carriers_in_nxt_mon_trucks") {
      this.setState({
        sidebartitle: "Expiry Carriers in Next Month Trucks",
        rowData: this.state.exp_carriers_in_nxt_mon_trucks
      })
    } else if (data == "carriers_onhold_trucks") {
      this.setState({
        sidebartitle: "Carriers OnHold Trucks",
        rowData: this.state.carriers_onhold_trucks
      })
    }
    else if (data == "yesterday_carriers_onhold_trucks") {
      this.setState({
        sidebartitle: "Yesterday Carriers OnHold Trucks",
        rowData: this.state.yesterday_carriers_onhold_trucks
      })
    } else if (data == "carriers_insp_trucks") {
      this.setState({
        sidebartitle: "Carriers Inspected Trucks",
        rowData: this.state.carriers_insp_trucks
      })
    } else if (data == "yesterday_carriers_insp_trucks") {
      this.setState({
        sidebartitle: "Yesterday Carriers Inspected Trucks",
        rowData: this.state.yesterday_carriers_insp_trucks
      })
    } else if (data == "sndnonreporting_data_trucks") {
      this.setState({
        sidebartitle: "Non-Reporting Carriers",
        rowData: this.state.sndnonreporting_data_trucks
      })
    } else if (data == "gps_installation_pending_trucks") {
      this.setState({
        sidebartitle: "GPS Installation Pending",
        rowData: this.state.gps_installation_pending_trucks
      })
    }
    this.setState({
      sliderRouteTranslate: 'slider-translate-75p',
      loadshow: 'show-n',
      overly: 'show-m'
    })
  }

  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",

      bulkslidebulk: ''
    });
  }

  showUpload_loi() {
    this.setState({
      overly: "show-m",
      bulkslidebulk: "slider-translate"
    })
  }

  changeFileHandler = async (e) => {
    var dt = '';
    const config = {
      headers: [
        {
          name: 'loi_number',
          inputName: 'loi_number',
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
          }
        },
        {
          name: 'loi_issue_date',
          inputName: 'loi_issue_date',
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
          }
        },
        {
          name: 'no_of_carriers',
          inputName: 'no_of_carriers',
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
          }
        },
        {
          name: 'loi_type',
          inputName: 'loi_type',
          required: false,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
          }
        },
        {
          name: 'expiry_date',
          inputName: 'expiry_date',
          required: false,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
          }
        },
      ]
    }

    var data = CSVFileValidator(e.target.files[0], config)
      .then((csvData) => {
        let csvList = csvData.data;
        var flag = 0;

        if (flag == 0) {
          this.setState({
            bulkMarkData: csvList
          });
        }

      })
      .catch(err => {
        console.log(err, "error")
      })
    // console.log("e.target.files[0]",e.target.result);
    if (e.target.files[0] != undefined) {
      var out = new Promise(function (reject, resolve) {
        var reader = new FileReader();
        reader.onload = async function (e) {
          var contents = await e.target.result;
          resolve(contents);
        };
        var tt = reader.readAsText(e.target.files[0]);
      });
      this.setState({
        file: e.target.files[0]
      });
      // console.log("e.target.files[0].type ", e.target.files[0]);

      if (e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
        // console.log(fileData);
        // console.log(typeof(fileData))

      }
      else {
        e.target.value = null;
        this.setState({
          uploadFile: '',
          show: true,
          basicType: 'danger',
          basicTitle: 'Please upload file having extensions .csv only.',
        });
      }
    }

  }
  resetUpload = () => {
    this.setState({
      bulkslidebulk: '',
      overly: 'show-n',
      file: '',
      bulkMarkData: []
    })
    $('#upform')[0].reset();
    document.getElementById("upform").reset();
  }

  async uploadBulkFormHandler(e) {
    e.preventDefault();
    var dataType = this.state.dataType;
    var fileData = this.state.bulkMarkData;
    var flag = 0;

    fileData.map((ele) => {
      console.log(ele, '697----')
      if (ele.loi_number == "") {
        flag = 1;
      } else if (ele.loi_issue_date == "") {
        flag = 1;
      } else if (ele.no_of_carriers == "") {
        flag = 1;
      } else if (ele.loi_type == "") {
        flag = 1;
      } else if (ele.expiry_date == "") {
        flag = 1;
      }
    })

    if (flag == 0) {
      if (fileData.length > 0) {
        var dateObject = new Date();
        var day = String(dateObject.getDate()).padStart(2, '0');
        var month = String(dateObject.getMonth() + 1).padStart(2, '0');
        var year = dateObject.getFullYear();
        var formattedDate = `${day}-${month}-${year}`;


        var reqParams = {
          csvData: fileData,
        }
        console.log(reqParams, 'paramsforbulk')

        this.setState({
          loadshow: 'show-m',
          overly: "show-m"
        });

        redirectURL.post("/consignments/bulkuploadloistatus", reqParams)
          .then((response) => {

            //   console.log(response,'resp09876', JSON.parse(response.config.data[0].csvData))
            //   this.setState({
            //     LoiData:JSON.parse(response.config.data)
            //   })

            this.setState({
              loadshow: 'show-n',
              overly: 'show-n',
              basicType: "success",
              basicTitle: "Successfully Uploaded",
              show: true,
              bulkslidebulk: '',
            })


            this.componentDidMount()
          })
          .catch((e) => {
            console.log(e);
          });


      } else {
        this.setState({
          basicTitle: "Invalid Data",
          basicType: "warning",
          show: true,
          file: "",
          bulkMarkData: []
        });
        $('#upform')[0].reset();
      }
    } else {
      $("#bulkUploadID").val();
      this.setState({
        basicTitle: "Fields Should not be Empty",
        basicType: "warning",
        show: true,
        file: "",
        bulkMarkData: []
      });
      $('#upform')[0].reset();
    }
  };
  closeAlert = () => {
    this.setState({
      show: false,
    });
  };

  render() {






    const columnwithDefs = [
      // {
      //   headerName: "Transporter Code",
      //   field: "transporter_code",

      //   filter: true,
      //   resizable: true,

      // },
       {
        headerName: "LOI Number",
        field: "loi_number",

        filter: true,
        resizable: true,

      },
      {
        headerName: "LOI Issue Date",
        field: "loi_issue_date",

        filter: true,
        resizable: true,
        valueGetter: (param) => {
          if (param.data.loi_issue_date !== "") {
            return getHyphenDDMMMYYYY(param.data.loi_issue_date);
          } else {
            return "";
          }
        },

      },
      {
        headerName: "No. Of Carriers",
        field: "no_of_carriers",

        filter: true,
        resizable: true,

      },
      {
        headerName: "LOI Type",
        field: "loi_type",

        filter: true,
        resizable: true,

      },
      {
        headerName: "Expiry Date",
        field: "expiry_date",

        filter: true,
        resizable: true,
        valueGetter: (param) => {
          if (param.data.expiry_date !== "") {
            return getHyphenDDMMMYYYY(param.data.expiry_date);
          } else {
            return "";
          }
        },

      },]

    const counterCoulumDef = [
      {
        headerName: "Truck Numbers",
        field: "truck_no",
        filter: true,
        resizable: true,
      },
      {
        headerName: "Truck Type",
        field: "truck_type",
        filter: true,
        resizable: true,
      },
      {
        headerName: "Carrier Status",
        field: "new_old",
        filter: true,
        resizable: true,
      },
      {
        headerName: "Last Inspection Date",
        field: "inspection_date",
        filter: true,
        resizable: true,
        valueGetter: (param) => {
          if (param.data.inspection_date !== "") {
            return getHyphenDDMMMYYYY(param.data.inspection_date);
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Last Loading Date",
        field: "last_loading_date",
        filter: true,
        resizable: true,
        valueGetter: (param) => {
          if (param.data.last_loading_date !== "") {
            return getHyphenDDMMMYYYY(param.data.last_loading_date);
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Expiry Date",
        field: "expiry",
        filter: true,
        resizable: true,
        valueGetter: (param) => {
          if (param.data.expiry !== "") {
            return getHyphenDDMMMYYYY(param.data.expiry);
          } else {
            return "";
          }
        },
      }


    ]
    if(localStorage.getItem("email") === "enmoviltesttpt@gmail.com"){
      columnwithDefs.splice(1,0,  {
        headerName: "Transporter Code",
        field: "",
        filter: true,
        resizable: true,
         })
        }else{
          columnwithDefs.splice(1,0,  {
            headerName: "Transporter Code",
            field: "transporter_code",
            filter: true,
            resizable: true,
                })
        }

    return (
      <div className='row' style={{justifyContent:'center'}}>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
          <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
        <div className={"dataLoadpage " + (this.state.loadshow)}></div>
        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
          <div className="loader-box">
            <div className="loader-box">
              <div className="rotate dashed colored">
              </div>
            </div>
          </div>
        </div>
        <div className='card col-xl-12 col-lg-12' style={{ marginTop: "0px", background: '#171C8F' }}>
          <div className="card-header text-center" style={{ marginTop: "0px", background: '#171C8F' }}>

            <h5 style={{ padding: "1%", fontSize: "20px" }}>
              {/* <span style={{position:"relative",left:"-30%",fontSize:"15px",color:"#171C8F"}}>
                        Updated on : {this.state.lastRunTime}
                    </span> */}
              <span className="" style={{ color: '#FFFFFF', position: "relative", left: "0 %" }}>FLEET DETAILS</span>
            </h5>
          </div>
        </div>

        <div className="col-xl-12 col-lg-12">


          <h5>

            <span className="float-right f12 ">
              <a className="btn btn-danger" style={{ float: "left", marginLeft: "10%", marginBottom: "20px" }}
                href="/lspceodashboardTest"

              >Back</a>
            </span>
            {/* <button style={{float:"right",marginLeft:"10%",marginBottom:"20px"}}  className="btn btn-success"
                        onClick={this.showUpload_loi.bind(this)}
                        >LOI Bulk Upload</button> */}



          </h5>
        </div>

        <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
          <div className="card">
            <div className="card-body" id="dashboardCounterCardBody">
              <div className="crm-numbers pb-0">

                <div className="row">

                  <div style={{ cursor: 'pointer' }} className="col cirlce-d " onClick={this.onClickCounter.bind(this, 'msil_fleet_trucks')}>
                    <span className="f13">
                      {/* <i className="icofont icofont-truck cus-i txt-info f24"></i> */}
                      <br /> MSIL Fleet
                    </span>
                    <h4 className="txt-info f50">
                      <span className="counter">
                        <CountUp end={(this.state.msil_fleet.length) ? this.state.msil_fleet.length : 0} />
                      </span>
                    </h4>
                    <br />
                    <div>
                      <p>{this.state.counterString}</p>
                    </div>


                  </div>
                  <div className="col " style={{ cursor: 'pointer' }} onClick={this.onClickCounter.bind(this, 'new_carriers_trucks')}>
                    <span className="f13">
                      {/* <i className="icofont icofont-truck-loaded f22 txt-primary-dark"></i> */}
                      <br /> New Carriers
                    </span>
                    <h4 className="txt-primary f50">
                      <span className="counter">
                        <CountUp end={(this.state.new_carriers.length) ? this.state.new_carriers.length : 0} />
                      </span>
                    </h4>
                    <br />
                    <div>
                      <p>{this.state.new_carriers_truck_type}</p>
                    </div>
                  </div>
                  <div className="col " style={{ cursor: 'pointer' }} onClick={this.onClickCounter.bind(this, "old_carriers_trucks")}>
                    <span className="f13">
                      {/* <i className="icofont icofont-truck-loaded f22 txt-primary-dark"></i> */}
                      <br /> Old Carriers
                    </span>
                    <h4 className="txt-secondary-medium f50">
                      <span className="counter">
                        <CountUp end={(this.state.old_carriers.length) ? this.state.old_carriers.length : 0} />
                      </span>
                    </h4>
                    <br />
                    <div>
                      <p>{this.state.old_carriers_truck_type}</p>
                    </div>
                  </div>
                  <div className="col" style={{ cursor: 'pointer' }} onClick={this.onClickCounter.bind(this, 'exp_carriers_in_curr_mon_trucks')}>
                    <span className="f13">
                      <br /> Expiring Carriers in {new Date().toLocaleString('default', { month: 'long' })} Month
                    </span>
                    <h4 className="txt-danger-medium f50">
                      <span className="counter">
                        <CountUp end={(this.state.exp_carriers_in_curr_mon.length) ? this.state.exp_carriers_in_curr_mon.length : 0} />
                      </span>
                    </h4>
                    <br />
                    <div>
                      <p>{this.state.exp_carriers_in_curr_mon_truck_type}</p>
                    </div>
                  </div>

                  <div className="col " style={{ cursor: 'pointer' }} onClick={this.onClickCounter.bind(this, 'exp_carriers_in_nxt_mon_trucks')}>
                    <span className="f13">
                      {/* <i className="icofont icofont-truck-loaded f22 txt-primary-dark"></i> */}
                      <br /> Expiring Carriers in {getNextMonthName()} Month
                    </span>
                    <h4 className="txt-warning-medium f50">
                      <span className="counter">
                        <CountUp end={(this.state.exp_carriers_in_nxt_mon.length) ? this.state.exp_carriers_in_nxt_mon.length : 0} />
                      </span>
                    </h4>
                    <br />
                    <div>
                      <p>{this.state.exp_carriers_in_nxt_mon_truck_type}</p>
                    </div>
                  </div>



                </div>




              </div>

            </div>
          </div>
        </div>



        <div className="card col-xl-5 col-lg-5">
          <div className="card-header text-center" style={{ marginTop: "0px" }}>

            <h5 style={{ padding: "1%", fontSize: "15px", background: '#171C8F' }}>
              {/* <span style={{position:"relative",left:"-30%",fontSize:"15px",color:"#171C8F"}}>
                        Updated on : {this.state.lastRunTime}
                    </span> */}
              <span className="" style={{ position: "relative", left: "0 %", color: '#FFFFFF' }}>FLEET PERFORMANCE</span>
            </h5>
          </div>
          <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
            <div >
              <div className="card-body" >
                <div className="crm-numbers pb-0">

                  <div className="row">

                    <div className="col cirlce-d " style={{ cursor: 'pointer' }} >
                      <span className="f13" >
                        {/* <i className="icofont icofont-truck cus-i txt-info f24"></i> */}
                        <br /> Carriers On Hold<br />Yesterday/MTD
                      </span>
                      <h4 className="txt-info f50">
                        <span className="counter" onClick={this.onClickCounter.bind(this, 'yesterday_carriers_onhold_trucks')}>
                          <CountUp end={(this.state.yesterday_carriers_onhold != '') ? this.state.yesterday_carriers_onhold.length : 0} />
                        </span>
                        <span className="f24">&nbsp;/&nbsp;</span>
                        <span className="counter" onClick={this.onClickCounter.bind(this, 'carriers_onhold_trucks')}>
                          <CountUp className="txt-secondary" end={(this.state.carriers_onhold != '') ? this.state.carriers_onhold.length : 0} />
                        </span>
                      </h4>

                    </div>
                    <div className="col " style={{ cursor: 'pointer' }}>
                      <span className="f13">
                        {/* <i className="icofont icofont-truck-loaded f22 txt-primary-dark"></i> */}
                        <br />Carriers Inspected <br />Yesterday/MTD
                      </span>
                      <h4 className="txt-primary f50">
                        <span className="counter" onClick={this.onClickCounter.bind(this, 'yesterday_carriers_insp_trucks')}>
                          <CountUp end={(this.state.yesterday_carriers_insp) ? this.state.yesterday_carriers_insp.length : 0} />
                        </span>
                        <span className="f24">&nbsp;/&nbsp;</span>
                        <span className="counter" onClick={this.onClickCounter.bind(this, 'carriers_insp_trucks')}>
                          <CountUp className="txt-secondary" end={(this.state.carriers_insp != '') ? this.state.carriers_insp.length : 0} />
                        </span>
                      </h4>

                    </div>




                  </div>
                  <div className="row">

                    <div className="col cirlce-d " style={{ cursor: 'pointer' }} onClick={this.onClickCounter.bind(this, 'sndnonreporting_data_trucks')}>
                      <span className="f13">
                        {/* <i className="icofont icofont-truck cus-i txt-info f24"></i> */}
                        <br /> Non-Reporting Carriers<br /> for 60 Days
                      </span>
                      <h4 className="txt-info f50">
                        <span className="counter">
                          <CountUp end={(this.state.sndnonreporting_data != '') ? this.state.sndnonreporting_data.length : 0} />
                        </span>
                      </h4>

                    </div>
                    <div className="col " style={{ cursor: 'pointer' }} onClick={this.onClickCounter.bind(this, 'gps_installation_pending_trucks')}>
                      <span className="f13">
                        {/* <i className="icofont icofont-truck-loaded f22 txt-primary-dark"></i> */}
                        <br /> GPS Installation<br />Pending
                      </span>
                      <h4 className="txt-primary f50">
                        <span className="counter">
                          <CountUp end={(this.state.gps_installation_pending_data) ? this.state.gps_installation_pending_data.length : 0} />
                          {/* <CountUp end={0}/> */}
                        </span>
                      </h4>

                    </div>




                  </div>




                </div>

              </div>
            </div>
          </div>

        </div>
        <div className="card col-xl-6 col-lg-6" style={{ marginLeft: "50px" }}>
          <div className="card-header text-center" style={{ marginTop: "0px", width: '100%' }}>

            <h5 style={{ background: '#171C8F', padding: "1%", fontSize: "15px", width: '100%' }}>
              {/* <span style={{position:"relative",left:"-30%",fontSize:"15px",color:"#171C8F"}}>
                        Updated on : {this.state.lastRunTime}
                    </span> */}
              <span className="" style={{ position: "relative", color: '#FFFFFF' }}>LOI STATUS</span>
            </h5>
          </div>
          <div
            id="myGrid"
            style={{ width: "100%", height: "100%", fontSize: "10px", marginBottom: "15px" }}
            className={"ag-theme-balham"}
          >


            <AgGridReact

              columnDefs={columnwithDefs}
              headerHeight={80}

              rowData={this.state.LoiData}
              enableCharts={false}
              onGridReady={this.onGridReady}
              onGridState={this.onGridState}
              frameworkComponents={this.state.frameworkComponents}
              rowClassRules={this.state.rowClassRules}
              sideBar={this.state.sideBar}
              stopEditingWhenGridLosesFocus={true}
              // onCellClicked={this.onCellClicked.bind(this)}
              paginationPageSize={this.state.paginationPageSize}
              pagination={true}
              gridOptions={{
                context: { componentParent: this },
              }}
              // onCellClicked={this.onCellRowClicked.bind(this)}
              enableRangeSelection={true}
            />
          </div>

        </div>

        <div className={"slide-r " + (this.state.sliderRouteTranslate)} style={{ overflow: "auto" }}>
          <div className="slide-r-title">
            <h4>
              {this.state.sidebartitle}
              <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>

            </h4>
          </div>
          <div className="slide-r-body" style={{ position: "relative" }}>

            <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">

              <div
                id="myGrid"
                style={{
                  height: '600px',
                  width: '100%',
                }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  modules={this.state.modules}
                  columnDefs={counterCoulumDef}
                  defaultColDef={this.state.defaultColDef}
                  rowData={this.state.rowData}
                  paginationPageSize={this.state.paginationPageSize}
                  pagination={true}
                // onCellEditingStopped={this.updateDelayRemarks.bind(this)}
                />
              </div>

            </div>
          </div>
        </div>

        <div className={"slide-r " + (this.state.bulkslidebulk)} style={{ overflow: "hidden" }}>
          <h3 className="subH">Bulk Upload</h3>
          <div className="slide-r-body" style={{ position: "relative" }}>
            <div className="container-fluid">
              <form method="POST" id="upform" className="theme-form"
                onSubmit={this.uploadBulkFormHandler.bind(this)}
              >

                <div className="form-group mt-20p">
                  <label className="">Upload File</label>
                  <input type="file" name="uploadFile" id="bulkUploadID"
                    onChange={this.changeFileHandler} className="form-control" required />
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-success">Submit</button>
                  <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                </div>
                <p style={{ color: "red" }}>Note: Format should be same as given in sample template***</p>
              </form>
              <div className="form-group">
                <a className="btn btn-primary" href={require('../../assets/json/loi_status.csv')} target="_blank">Sample Template</a>
              </div>
            </div>
          </div>
        </div>
      </div>


    );
  }
}
function getNextMonthName() {
  const currentDate = new Date();
  const nextMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
  return nextMonthDate.toLocaleString('default', { month: 'long' });
}
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import CSVFileValidator from 'csv-file-validator';
import Map from '../common/drawmap';
import FileBase64 from 'react-file-base64';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import save from 'material-ui/svg-icons/content/save';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var redirectURL  = require('../redirectURL');

export default class EditTransporterCargoEvent extends Component{
    constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            csvcontent:"",
            file : "",
            loadshow:'show-n',
            overly:'show-n',
            uploadYard : "",
            uploadDispatchDate : "",
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            pagetitle:"",
            bulkslide:"",
            cargoBookingId:"",
            airCargoTransporterId:"",
            other_docs:"",
            file:"",
            loadshow : "show-n",
            hiddenotherdocs : "",
            hiddeninvoice : "",
            pagetype : 1,

            technical_write_up_of_product :'',
            hsn_code_by_shipper:'',
            material_composition:'',
            photograph:'',
            end_use_of_item:"",
            //components : {datepicker:getDatePicker()}
        }
        this.changeHandler = this.changeHandler.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        if (googleAnalytics.page.enableGA) {
			this.logPageView();
		}

        var inputEventval = window.atob(this.props.match.params.eventname);
        var pagetype = this.props.match.params.pagetype;
        console.log(pagetype,"pagetype")
        this.setState({
            pagetype : pagetype
        })
		// console.log
		var cargoBookingId="";
		var reqparams = {
            eventID : inputEventval,
        }

        redirectURL.post("/consignments/getAirCargosByRequestId",reqparams).then((response)=>{
            console.log(response.data);
            var cargoBookingId=response.data[0]._id;
			this.setState({
				event : response.data[0].event,
				cargoBookingId : response.data[0]._id,
                job_no : response.data[0].job_no,

                free_trade : response.data[0].free_trade,
                benefit_certificate : response.data[0].benefit_certificate,
                epcg: response.data[0].epcg,
                technical_write_up_of_product :response.data[0].technical_write_up_of_product,
                hsn_code_by_shipper:response.data[0].hsn_code_by_shipper,
                material_composition:response.data[0].material_composition,
                photograph:response.data[0].photograph,
                end_use_of_item:response.data[0].end_use_of_item,
            })
            var resparams = {
                cargoBookingId : cargoBookingId,
            }
            //console.log(resparams)
            
                var exp_delivery_at_msil ="";
                if(response.data[0].exp_delivery_at_msil !=undefined && response.data[0].exp_delivery_at_msil !='')
                {
                    exp_delivery_at_msil = response.data[0].exp_delivery_at_msil;
                    if(exp_delivery_at_msil.includes("T") == true)
                    {
                        exp_delivery_at_msil = exp_delivery_at_msil.split("T");
                        exp_delivery_at_msil = exp_delivery_at_msil[0];
                        exp_delivery_at_msil = exp_delivery_at_msil.split("-")[2]+"-"+exp_delivery_at_msil.split("-")[1]+"-"+exp_delivery_at_msil.split("-")[0];
                    }
                }
                var actual_delivery_at_msil ="";
                if(response.data[0].actual_delivery_at_msil !=undefined && response.data[0].actual_delivery_at_msil !='')
                {
                    actual_delivery_at_msil = response.data[0].actual_delivery_at_msil;
                    if(actual_delivery_at_msil.includes("T") == true)
                    {
                        actual_delivery_at_msil = actual_delivery_at_msil.split("T");
                        actual_delivery_at_msil = actual_delivery_at_msil[0];
                        actual_delivery_at_msil = actual_delivery_at_msil.split("-")[2]+"-"+actual_delivery_at_msil.split("-")[1]+"-"+actual_delivery_at_msil.split("-")[0];
                    }
                }
                $("#vehicle_no").val(response.data[0].vehicle_no);
                $("#exp_delivery_at_msil").val(exp_delivery_at_msil);
                $("#actual_delivery_at_msil").val(actual_delivery_at_msil);
                $('#ewaybillno').val(response.data[0].ewaybillno)

            
        }).catch(function(error){
            console.log(error);
		})
        
       
    }

    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
    }
    
closeAlert = () => {
    this.setState({
        show: false
    });
    var pagetype = this.state.pagetype;
    if(pagetype == 1)
    {
        window.location.href="/transportationaircargos";
    }
    else
    {
        window.location.href="/aircargos";
    }
}
changeHandler = (event) => {
    console.log("Select", event.target.value);
    let name = event.target.name;
    console.log(name);
    let value = event.target.value;
    //this.setState({[name]:value});
}
changeFileHandler = (e) => {
    console.log(e);
    this.setState({
        file : e.target.files[0]
    });
    console.log(e)
}

changeDocFileHandler = (e) => {
    console.log(e);
    this.setState({
        other_docs : e.target.files[0]
    });
    console.log(e)
}

formHandler(event){
    event.preventDefault();
    
    var exp_delivery_at_msil = $("#exp_delivery_at_msil").val();
    var actual_delivery_at_msil = $("#actual_delivery_at_msil").val();
   
    var vehicle_no = $("#vehicle_no").val();
    var eway_billno = $("#ewaybillno").val();

    let saveData = new FormData();
  
    saveData.append("job_no",this.state.job_no);
    saveData.append("cargo_booking_id", this.state.cargoBookingId);
    
    saveData.append("exp_delivery_at_msil", exp_delivery_at_msil);
    saveData.append("actual_delivery_at_msil", actual_delivery_at_msil);
    saveData.append("vehicle_no", vehicle_no);
    saveData.append("ewaybillno", eway_billno);
    saveData.append("airCargoTransporterId", this.state.airCargoTransporterId);
    saveData.append("updated_by", localStorage.getItem("email"));
    saveData.append("updated_user_id", localStorage.getItem("userid"));

    saveData.append("epcg",this.state.epcg);
    saveData.append("benefit_certificate",this.state.benefit_certificate);
    saveData.append("free_trade",this.state.free_trade);
    //console.log(this.state.airCargoFFId);
    saveData.append("technical_write_up_of_product",this.state.technical_write_up_of_product);
    saveData.append("hsn_code_by_shipper",this.state.hsn_code_by_shipper);
    saveData.append("material_composition",this.state.material_composition);
    saveData.append("photograph",this.state.photograph);
    saveData.append("end_use_of_item",this.state.end_use_of_item);
    

    console.log(saveData);
    redirectURL.post("/consignments/savetransporterCargoData",saveData,{
        headers:{
            'content-type': 'multipart/form-data'
        }
    }).then((response)=>{   
        this.setState({
            basicTitle:"Success",
            basicType : "success",
            show : true
        });
    }).catch((e)=>{
        console.log(e);
    })
        
    // }
    // else
    // { 
    //     this.setState({
    //         basicTitle:"Fields Should not be Empty",
    //         basicType : "warning",
    //         show : true
    //     })
    // }
}



uploadFormHandler(event){
    event.preventDefault();
    
    var formData = new FormData(event.target);
    formData.append('uploadFile',this.state.file);
    
    console.log('file',formData);
    redirectURL.post("/consignments/saveffCargoData",formData,{
        headers:{
            'content-type': 'multipart/form-data'
        }
    }).then((response)=>{   
        this.setState({
            basicTitle:"Success",
            basicType : "success",
            show : true
        });
        // window.location.reload();
    }).catch((e)=>{
        console.log(e);
    })
}


render(){
        
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Job No: {this.state.job_no}</span>
								</h5>
				   			</div>
                            <div className="card-body pt-15px">
                                <form class="aircargo-form" onSubmit={this.formHandler.bind(this)} encType="multipart/form-data">
                                    
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Vehicle No : </label>
                                            <input type="text" name="vehicle_no" id="vehicle_no" className="form-control" disabled={this.state.disableERP} required onChange={this.changeHandler}/>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Expected Delivery at MSIL : </label>
                                            <input type="text" name="exp_delivery_at_msil" id="exp_delivery_at_msil" className="datetimepicker_date form-control" disabled={this.state.disableERP} onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Actual Delivery at MSIL : </label>
                                            <input type="text" name="actual_delivery_at_msil" id="actual_delivery_at_msil" className="datetimepicker_date form-control" disabled={this.state.disableERP} onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">E-way Bill Number : </label>
                                            <input type="text" name="ewaybillno" id="ewaybillno" className="form-control" disabled={this.state.ewaybillno} onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div className="row col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-success" style={{marginLeft:"45%",width:"192px",marginTop:"20px"}}>Submit</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>           
            </div>
        )
    }
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function cleanTruckNo(value){
    var result =  /\s/g.test(value);
    var cleanedValue="";
    try{
        if(result == true){
            cleanedValue = value.replace(/[^\w\s]/gi, '');
            cleanedValue = cleanedValue.replace(/\s/g, '').toUpperCase();
        }
        else{
            cleanedValue = value.toUpperCase();
        }
    }
    catch(e){  }
    return cleanedValue;
}


// function checkWhiteSpaces(value){
//     return /\s/g.test(value);
// }
// function removeWhiteSpace(value){
//     value = value.replace(/[^\w\s]/gi, '');
//     return value.replace(/\s/g, '').toUpperCase();
// }
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}


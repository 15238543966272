
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';


import Counters from '../layouts/consignmentkpicountersComponent';

import CountUp from 'react-countup';

import { AgGridReact } from "@ag-grid-community/react";

import {
    AllModules,
    GridOptionsWrapper,
  } from "@ag-grid-enterprise/all-modules";
  
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from "react-select";

import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';

import Highcharts from 'highcharts';
import { Redirect } from 'react-router-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');


export default class DriverTrainingsTest extends Component {
    constructor(props){
    	super(props);
    	this.state={
           facility:"All Facilities",
           facilitiesList:[{"label":"All Facilities","value":"All Facilities"},
           {"label":"Haryana","value":"Haryana"},
           {"label":"Bidadi","value":"Bidadi"}
           ,{"label":"GUJ","value":"GUJ"},
           {"label":"TVP-B","value":"TVP-B"},
           {"label":"TVP-N","value":"TVP-N"},
           {"label":"TVP-S","value":"TVP-S"}],
           transporterCode:"",
           loadshow:'show-n',
            overly:'show-n',
            drivertrainingidtr_target : 0,
            drivertrainingidtr_achievement : 0,
            drivertrainingdec_target : 0,
            drivertrainingdec_achievement : 0,
            chart1category : [],
            chart1target : [],
            chart1achievement : [],
            chart2category : [],
            chart2target : [],
            chart2achievement : [],
            presentMonth:''
    	}
       
    
    }

    async componentDidMount(){
        var months = DynamicSortOfMonths()
        var presentMonth = moment(new Date()).format("MMM-YY");
        // console.log(presentMonth,'presentMonth')
        this.setState({
            overly:"show-m",
            loadshow:"show-m",
            presentMonth:presentMonth
        })
        var transporterCode = window.atob(this.props.match.params.transporterCode)
        console.log(transporterCode,"check-12345")
        this.setState({
            transporterCode : transporterCode
        })
        await this.onloadgetcountervalues(transporterCode)
        await this.onloadgetfirstchartdata(transporterCode)
        await this.onloadgetsecondchartdata(transporterCode)
        // console.log(checkpath,"48")
    }
    onloadgetcountervalues =(tptCode)=>{
        var params ={
            transporter_code : tptCode
        }
        redirectURL.post("/consignments/getdrivertrainingscounterdata",params).then((response)=>{
            this.setState({
                drivertrainingidtr_target : response.data.drivertrainingidtr_target,
                drivertrainingidtr_achievement : response.data.drivertrainingidtr_achievement,
                drivertrainingdec_target : response.data.drivertrainingdec_target,
                drivertrainingdec_achievement : response.data.drivertrainingdec_achievement,
               
            })
        })
    }
    onloadgetfirstchartdata = (tptcode) => {
        var params = {
            transporter_code: tptcode
        };
        var months = DynamicSortOfMonths();
    
        redirectURL.post("/consignments/getdrivertrainingsfirstchart", params)
            .then((response) => {
                const sortedData = response.data.getcategoryData
                    .map((month, index) => {
                        if(this.state.presentMonth !== response.data.getcategoryData[index]){
                        if (response.data.getgraphstargets[index] >= 0 || response.data.getgraphsachievements[index] >= 0) {
                            return {
                                month,
                                target: response.data.getgraphstargets[index],
                                achievement: response.data.getgraphsachievements[index],
                            };
                        }
                      }
                        return null; // Return null if values are not greater than 0
                    })
                    .filter(item => item !== null) // Filter out null values
                    .sort((a, b) => {
                        const aIndex = months.indexOf(a.month);
                        const bIndex = months.indexOf(b.month);
                        return aIndex - bIndex;
                    });
    
                const sortedTargets = sortedData.map((item) => item.target);
                const sortedAchievements = sortedData.map((item) => item.achievement);
    
                this.setState({
                    chart1category: sortedData.map((item) => item.month),
                    chart1target: sortedTargets,
                    chart1achievement: sortedAchievements
                });
            });
    };
    
  
    onloadgetsecondchartdata = (tptcode) => {
        var params = {
            transporter_code: tptcode
        };
        var months = DynamicSortOfMonths();
    
        redirectURL.post("/consignments/getdrivertrainingssecondchart", params)
            .then((response) => {
                  
                const sortedData = response.data.getcategoryData
                    .map((month, index) => {
                        if(this.state.presentMonth !== response.data.getcategoryData[index]){
                        if (response.data.getgraphstargets[index] >= 0 || response.data.getgraphsachievements[index] >= 0) {
                            return {
                                month,
                                target: response.data.getgraphstargets[index],
                                achievement: response.data.getgraphsachievements[index],
                            };
                        }
                    }
                        return null; // Return null if values are not greater than 0
                    })
                    .filter(item => item !== null) // Filter out null values
                    .sort((a, b) => {
                        const aIndex = months.indexOf(a.month);
                        const bIndex = months.indexOf(b.month);
                        return aIndex - bIndex;
                    });
    
                const sortedTargets = sortedData.map((item) => item.target);
                const sortedAchievements = sortedData.map((item) => item.achievement);
    
                this.setState({
                    chart2category: sortedData.map((item) => item.month),
                    chart2target: sortedTargets,
                    chart2achievement: sortedAchievements,
                    loadshow: "show-n",
                    overly: "show-n"
                });
            });
    };
    
    
    newFilter=(event)=>{
       console.log(event)
    }

    onClickCounter(data){
        //this.props.context.onClickCounterShowData(data)
        
    }

    handleDrilldown = (params) => {
        console.log("Here ,", params)
    }
    handleDrilldown1 = (params) => {
        console.log("Here ,", params)
    }
    onClickHideAll(){
        this.setState({
        loadshow:'show-n',
        overly:'show-n',
        });
    }

    render(){

        const dStyles={
            width:'100%',
            height:'100%'
    }

      var highchartoptions={
        chart: {
            type: 'column'
        },
        title: {
            text: 'IDTR Driver Trainings'
        },
        
        xAxis: {
            categories: this.state.chart1category,
            crosshair: true
        },
        yAxis: {
            
            title: {
                text: 'TOTAL'
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.0f}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                dataLabels: {
                  enabled: true, // Enable data labels
                  format: '{point.y:.0f} ', // Format for data labels
                  align: 'center', // Align data labels to center
                  verticalAlign: 'bottom', // Align data labels to the top of the bars
                  inside: false, // Display data labels outside the bars
                }
              }
        },
        series: [ {
            name: 'Target',
            data: this.state.chart1target,
            color:'#396BC5'
    
        }, {
            name: 'Achievement',
            data: this.state.chart1achievement,
            color:'#F57E31'
    
        }]}
        const dStyles2={
            width:'100%',
            height:'100%'
    }

      var highchartoptions2={
        chart: {
            type: 'column'
        },
        title: {
            text: 'DEC Driver Trainings'
        },
        
        xAxis: {
            categories: this.state.chart2category,
            crosshair: true
        },
        yAxis: {
            
            title: {
                text: 'Target vs Achievement'
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.0f}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                dataLabels: {
                  enabled: true, // Enable data labels
                  format: '{point.y:.0f} ', // Format for data labels
                  align: 'center', // Align data labels to center
                  verticalAlign: 'bottom', // Align data labels to the top of the bars
                  inside: false, // Display data labels outside the bars
                }
              }
        },
        series: [ {
            name: 'Target',
            data: this.state.chart2target,
            color:'#396BC5'
    
        }, {
            name: 'Achievement',
            data: this.state.chart2achievement,
            color:'#F57E31'
    
        }]}

        return (
            <div className='row'>
                {/* <div className='card col-xl-12 col-lg-12' style={{marginTop:"0px"}}>
            <div className="card-header text-center" style={{marginTop:"0px"}}>
            
            <h5 style={{ border: "1px solid #ccc", padding: "1%", fontSize:"20px" }}>
           
              <span className="" style={{position:"relative",left:"0 %"}}>Driver Trainings</span>
            </h5>
          </div>
          </div> */}
              <div   style={{textAlign: "center",background: "#171C8F",width:'100%',margin:"5px",borderRadius:"5px",height:'70px',paddingBottom:'25px'}}>
                                   <h6 class="txt-dark f40" style={{marginTop:"0px"}}>
                                        <span   style={{fontSize:"20px",color:"#FFFFFF"}}>
                                            <span>Driver Trainings</span>
                                        
                                        </span>
                                    </h6>
                                </div>


          <div className="row card-body">

                <div className="col-xl-11 col-lg-11">
                    
                </div>
                <div className="col-xl-1 col-lg-1">		            	
                  <h5>
                        
                        <span className="float-right f12 "> 
                              <a className="btn btn-danger" style={{float:"left",marginLeft:"10%"}} href="/lspceodashboardTest">Back</a>
                        </span>
                  </h5>                    
              </div>
                
            </div>
            {/* <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="card">
                    <div className="card-body" id="dashboardCounterCardBody">
                        <div className="crm-numbers pb-0">
                           
                             <div className="row">
                                <div className="col " onClick={this.onClickCounter.bind(this,1)}>
                                    <span className="f13">
                                       
                                        <br /> IDTR Driver Trainings Target / Achievement {new Date().toLocaleString('default', { month: 'long' })} Month
                                    </span>
                                    <h4 className="txt-primary f50">
                                        <span className="counter">
                                            <CountUp end= {(this.state.drivertrainingidtr_achievement) ? this.state.drivertrainingidtr_achievement : 0}/>
                                        </span>
                                    <span className="f50">&nbsp;/&nbsp;</span>
                                            <span className="counter" onClick={this.onClickCounter.bind(this,"no_gps_data_lifetime")}>
                                                <CountUp end= {(this.state.drivertrainingidtr_target) ? this.state.drivertrainingidtr_target : 0}/>
                                            </span> 
                                        </h4>
                                  
                                </div>
                                <div className="col "  onClick={this.onClickCounter.bind(this,1)}>
                                    <span className="f13">
                                        
                                        <br /> DEC Driver Training Target / Achievement {new Date().toLocaleString('default', { month: 'long' })} Month
                                    </span>
                                    <h4 className="txt-secondary f50">
                                        <span className="counter">
                                            <CountUp end= {(this.state.drivertrainingdec_achievement) ? this.state.drivertrainingdec_achievement : 0}/>
                                        </span>
                                    
                                    
                                    <span className="f50">&nbsp;/&nbsp;</span>
                                   
                                     
                                            <span className="counter" onClick={this.onClickCounter.bind(this,"no_gps_data_lifetime")}>
                                                <CountUp end= {(this.state.drivertrainingdec_target) ? this.state.drivertrainingdec_target : 0}/>
                                            </span> 
                                    </h4> 
                                </div>
                            </div> 
                        </div>
                       
                    </div>
                </div>

            </div> */}
            <div className='col-xl-12 col-lg-12'>

<HighchartsReact
     containerProps={{ style: { width: '100%', height: '100%' } }}
     highcharts={Highcharts} 
     style={dStyles} 
     options={highchartoptions} 
     onClick={this.handleDrilldown.bind(this)}
 />
</div>
<div className='col-xl-12 col-lg-12'>

<HighchartsReact
     containerProps={{ style: { width: '100%', height: '100%' } }}
     highcharts={Highcharts} 
     style={dStyles2} 
     options={highchartoptions2} 
     onClick={this.handleDrilldown1.bind(this)}
 />
</div>
<div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>   
          
           </div>
            

        );
    }
}
// const monthsAndYears = [
//     'Apr-23', 'May-23', 'Jun-23', 'Jul-23', 'Aug-23', 'Sep-23',
//     'Oct-23', 'Nov-23', 'Dec-23', 'Jan-24', 'Feb-24', 'Mar-24'
// ];

function DynamicSortOfMonths () {
    const currentDate = new Date();
    const fiscalYearStartMonth = 3;
    let currentFiscalYear;
    if (currentDate.getMonth() < fiscalYearStartMonth) {
      currentFiscalYear = currentDate.getFullYear() - 1;
    } else {
      currentFiscalYear = currentDate.getFullYear();
    }
  
    const fiscalYearMonths = [];
    const monthNames = [
      "Apr", "May", "Jun", "Jul", "Aug", "Sep",
      "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"
    ];
  
    // Dynamically generate the order array based on the current fiscal year
    const order = [];
    for (let i = fiscalYearStartMonth; i < fiscalYearStartMonth + 12; i++) {
      const monthName = monthNames[i % 12];
      const year = (monthName === "Jan" || monthName === "Feb" || monthName === "Mar") ? currentFiscalYear + 1 : currentFiscalYear;
      order.push(`${monthName}-${String(year).substring(2)}`);
    }
  
    for (let month = fiscalYearStartMonth; month < fiscalYearStartMonth + 12; month++) {
      const monthName = monthNames[month % 12];
      const year = (monthName === "Jan" || monthName === "Feb" || monthName === "Mar") ? currentFiscalYear + 1 : currentFiscalYear;
      fiscalYearMonths.push(`${monthName}-${year}`);
    }
  
    // Sort fiscal year months based on the dynamically generated order
    fiscalYearMonths.sort((a, b) => {
      const [monthA, yearA] = a.split('-');
      const [monthB, yearB] = b.split('-');
      // Compare years first
      if (yearA !== yearB) {
        return parseInt(yearA) - parseInt(yearB);
      }
      // If years are the same, compare months
      return monthNames.indexOf(monthA) - monthNames.indexOf(monthB);
    });
  
    fiscalYearMonths.forEach((month, index) => {
      const [monthName, year] = month.split('-');
      const shortYear = year.substring(2);
    
    //   console.log(index, 'index', monthName, shortYear)
      fiscalYearMonths[index] = `${monthName}-${shortYear}`;
    });
    console.log(fiscalYearMonths, 'fiscalYearMonths')
    return fiscalYearMonths;
  }
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
// eslint-disable-next-line strict
'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import UpdateButton from "../layouts/updatelmstates";

// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent = "";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class EnSafeAlertLogs extends Component {
    state = {
        modules: AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents: {
            UpdateButton: UpdateButton,
        },
        modified_by: "",
        basicTitle: '',
        reasonforceclose: "",
        uploadFile: "",
        basicType: "default",
        loadshow: 'show-n',
        forceCloseRowNode: "",
        forceclosedata: "",
        csvcontent: "",
        rowData: [],
        bulkslide: "",
        showDiv: "",
        uploadDivWidth: "",
        mapinfo: "",
        dealer: "",
        sliderCommentTranslate: "",
        commentsRowData: "",
        consignment_code: "",
        sliderRouteTranslate: "",
        routeTruck: "",
        rownode: "",
        googelRoutes: "",
        file: "",
        rowSelection: 'multiple',
        sliderForceCloseTranslate: "",
        maptruckno: "",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" }
            ]
        },
        paginationPageSize: 200,
        geofencelist: [],
        geoFenceData: "",
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                }
            ]
        },
        animateRows: true,
        dept_code: "",
        pagetitle: "",
        markDeliverRowNode: "",
        markDeliverData: "",
        sliderMarkDeliverTranslate: "",
        csvData: 0,
        overly: "show-n",
        holidayEvents: [],
        showCalendar: "show-m",
        showGridData: "show-m",
        loadFile: "",
        processErrMsg: "",
        processErrShow: "",
        transactionId: "",
        droppedList: [],
        statusCode: "",
        transportersList: [],
        deviceList: [],
        startDate : moment.parseZone().subtract(1, 'days').format('YYYY-MM-DD'), 
        endDate : moment.parseZone().format('YYYY-MM-DD'),
        //components : {datepicker:getDatePicker()}
    }


    componentDidMount() {
        loadDateTimeScript();
        redirectURL.post("/consignments/getDevicesIdDropDown").then((response) => {
            console.log("getDevicesIdDropDown", response.data);
            let deviceList = []
            response.data.map(e => deviceList.push({ value: e, label: e }))
            console.log(deviceList, "deviceList")
            this.setState({
                deviceList
            })
        }).catch((e) => {
            console.log(e);
        })
    }

    onLoadPageData = () => {
        redirectURL.post("/consignments/getEnsafeDevicesList").then((response) => {
            console.log(response.data, "tdata");
            this.setState({
                rowData: response.data
            })
        }).catch((e) => {
            console.log(e);
        })
    }

    
    onClickSummaryData = () => {
        const { device_id, startDate, endDate } = this.state
        try{
            let params = {
                'device_id':device_id.value
                , startDate, endDate
            }
            console.log("params", params)        
            redirectURL.post("/consignments/getEnsafeDevicesListFromApi", params).then((response) => {
                console.log(response.data, "tdata");
                this.setState({
                    rowData: response.data.digi_data
                })
            }).catch((e) => {
                console.log(e);
            })
        }
        catch(e){
            console.log("e",e)
        }
        // this.onLoadPageData();

    }

    // onChangeTransporter(transporterId) {
    //     this.setState(
    //         { transporterId },
    //         () => console.log(`transporter Option selected:`, this.state.transporterId)
    //     );
    // }

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }



    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onCellClicked = (e) => {
        if (e.colDef.field == "container_no") {
            console.log(e.data.container_no);
        }
    }

    onClickHideAll = () => {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            bulkslide: "",

        });
        $("#uploadFile").val("");
        $("#notify_email").val("");
    }

    // updateLmStates(params)
    // {
    //     this.setState({
    //         loadshow:'show-n',
    //         overly:'show-n',
    //     });
    //     redirectURL.post("/consignments/savetransporterinfo",params).then((response)=>{
    //         console.log(response);
    //         if(response.data.message == "success")
    //         {
    //             this.setState({
    //                 basicTitle:"Updated Successfully.",
    //                 basicType : "success",
    //                 show : true
    //             })
    //             this.setState({
    //                 basicTitle:"Updated Successfully.",
    //                 basicType : "success",
    //                 show : true,
    //                 loadshow:'show-n',
    //                 overly:'show-n',
    //             });
    //             this.onLoadPageData();
    //         }
    //         else
    //         {
    //             this.setState({
    //                 basicTitle:"Failed to update.",
    //                 basicType : "warning",
    //                 show : true
    //             })
    //         }
    //     })
    // }

    addNewRow() {
        // console.log(this.gridApi);
        this.gridApi.insertItemsAtIndex(0, [{ vehicle_cap: "", min_capacity_cmt: "", max_capacity_cmt: "", min_courier: "", nodes_per_truck: "", allowed_distance: "" }])
        // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
    }
    showGrid() {
        this.setState({
            showCalendar: "show-n",
            showGridData: "show-m"
        })
    }

    // showBulkUpload(){
    //     this.setState({
    //         overly :"show-m",
    //         bulkslide : "slider-translate-30p"
    //     })
    // }

    // resetUpload = () => {
    //     this.setState({
    //         bulkslide:'',
    //         overly:'show-n',
    //         transporterId:''
    //     });
    //     $("#fleet_size").val("");
    //     $("#owner_name").val("");
    //     $("#manger_name").val("");
    //     $("#gur_op_clusters").val("");
    //     $("#guj_op_clusters").val("");
    //     $("#tvpb_op_clusters").val("");
    //     $("#tvpn_op_clusters").val("");
    //     $("#sil_op_clusters").val("");
    // }


    // changeFileHandler = async (e) => {
    //     console.log(e);
    //     this.setState({
    //         loadFile : e.target.files[0]
    //     });    
    // }

    // uploadBulkFormHandler = (event) =>{
    //     event.preventDefault();   
    //     var transporterId = this.state.transporterId;
    //     if(transporterId !=undefined && transporterId !='')
    //     {
    //         var transporter_code = transporterId.value;
    //         var transporter_name = transporterId.label;
    //         var fleet_size = $("#fleet_size").val();
    //         var owner_name = $("#owner_name").val();
    //         var manger_name = $("#manger_name").val();
    //         var gur_op_clusters = $("#gur_op_clusters").val();
    //         var guj_op_clusters = $("#guj_op_clusters").val();
    //         var tvpb_op_clusters = $("#tvpb_op_clusters").val();
    //         var tvpn_op_clusters = $("#tvpn_op_clusters").val();
    //         var sil_op_clusters = $("#sil_op_clusters").val();
    //         var params = {
    //             transporter_code : transporter_code,
    //             transporter_name : transporter_name,
    //             fleet_size : fleet_size,
    //             owner_name : owner_name,
    //             manger_name : manger_name,
    //             gur_op_clusters : gur_op_clusters,
    //             guj_op_clusters : guj_op_clusters,
    //             tvpb_op_clusters : tvpb_op_clusters,
    //             tvpn_op_clusters : tvpn_op_clusters,
    //             sil_op_clusters : sil_op_clusters,
    //         }
    //         console.log(params,"params");
    //         this.setState({
    //             loadshow:'show-m',
    //             overly:'show-m',
    //         });
    //         redirectURL.post("/consignments/savetransporterinfo",params).then((response)=>{
    //             if(response.data.message == "success")
    //             {
    //                 this.setState({
    //                     basicTitle:"Updated Successfully.",
    //                     basicType : "success",
    //                     show : true,
    //                     loadshow:'show-n',
    //                     overly:'show-n',
    //                     bulkslide:"",
    //                 });
    //                 this.resetUpload();
    //                 this.onLoadPageData();
    //             }
    //             else
    //             {
    //                 this.setState({                    
    //                     basicTitle : "Transporter Data already exists",
    //                     basicType : "warning",
    //                     show : true,
    //                     loadshow:'show-n',
    //                     overly:'show-n',
    //                 });
    //             }
    //         });
    //     }
    //     else
    //     {
    //         this.setState({                    
    //             basicTitle : "Please transporter to continue",
    //             basicType : "warning",
    //             show : true,
    //         });
    //     }
    // };




    // onSelectBulkPlants= (location) =>{
    //     var plants=[];
    //     try{
    //         location.map(function(e){
    //             plants.push(e.value);
    //         })
    //         console.log(plants);
    //         this.setState({
    //             plant_name : plants
    //         });
    //     }
    //     catch(e)
    //     {
    //         console.log(e);
    //     }

    // }

    changeDeviceHandler = params => {
        console.log("params",params)
        this.setState({
            device_id: params
        })
    }

    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

        this.setState({
            startDate: startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }

    handlerEndDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
        this.setState({
            endDate: edate
        });
    }

    render() {
        var columnwithDefs = [
            {
                headerName: "Device Name",
                field: "elock_name",
                width: 130,
                filter: true,
                resizable: true,
                editable: false,
            },
            {
                headerName: "Device Id",
                field: "elock_id",
                width: 130,
                filter: true,
                resizable: true,
                editable: false,
            },
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 130,
                filter: true,
                resizable: true,
                editable: false,
                valueGetter: param => {
                    let data = param.data.truck_no
                    if (data) return data
                    return "Not Assigned"
                }
            },
            {
                headerName: "Latitude",
                field: "latitude",
                width: 130,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Longitude",
                field: "longitude",
                width: 130,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Location",
                field: "location",
                width: 200,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Lock Status",
                field: "device_status",
                width: 130,
                filter: true,
                resizable: true,
                editable: false,
            },
            {
                headerName: "Unlock Type",
                field: "report_reason",
                width: 130,
                filter: true,
                resizable: true,
                editable: false,
                valueGetter: param => {
                    let value = param.data.report_reason;
                    if (value == "G") return "WEB"
                    else if (value == "C") return "RFID"
                    else if (value == "7") return "SMS"
                    // else if(value == "1" || value == 1) return "LOCK"
                    // else if(value == "0" || value == 0) return "UNLOCK"
                }

            },
            {
                headerName: "Seal Status",
                field: "seal_status",
                width: 130,
                filter: true,
                resizable: true,
                editable: false,
                valueGetter: param => {
                    let data = param.data.seal_status
                    if (data == 1) return "Seal"
                    return "UnSeal"
                }
            },
            {
                headerName: "Tamper Status",
                field: "tamper_status",
                width: 130,
                filter: true,
                resizable: true,
                editable: false,
                valueGetter: param => {
                    let data = param.data.tamper_status
                    if (data == 1) return "Device Tampered"
                    return "Device Ok"
                }
            },
            // {
            //     headerName: "Device Status",
            //     field: "device_status",
            //     width:130,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            {
                headerName: "Voltage",
                field: "voltage",
                width: 100,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Battery Status",
                field: "power_status",
                width: 130,
                filter: true,
                resizable: true,
                editable: false,
                valueGetter: (param) => {
                    let powerStatus = (param.data.power_status).toString()
                    if (powerStatus.includes('.') == true) {
                        return parseFloat(param.data.power_status).toFixed(2);
                    } else {
                        return powerStatus
                    }
                }
            },
            {
                headerName: "Unlocked By",
                field: "unlocked_by",
                width: 150,
                filter: true,
                resizable: true,
                editable: false
            },

            {
                headerName: "Time Stamp",
                field: "created_date",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                valueGetter: function (params) {
                    return getHyphenDDMMYYYYHHMMSS(params.data.created_date)
                }
            },
        ]
        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>

                <div className="card col-xl-12">
                    <div class="card-header">
                        <h5>
                            <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Digital Lock Logs</span>

                            {/* <button className="btn btn-info" style={{float:"right"}} onClick={this.showBulkUpload}>Add Data</button> */}
                        </h5>
                    </div>
                    <div className="card-body col-xl-12 col-lg-12">
                        <div className="row">
                            <div className="form-group mt-20p col-xl-3 col-lg-3">
                                <label className="">Select Device Id</label>
                                <Select
                                    placeholder={"Select Device"}
                                    closeMenuOnSelect={true}
                                    className={"border-radius-0"}
                                    style={{ borderRadius: "0px" }}
                                    options={this.state.deviceList}
                                    onChange={this.changeDeviceHandler}
                                />
                            </div>
                            <div className="form-group mt-20p col-xl-3 col-lg-3">
                                <label>From Date</label>
                                <Datetime value={this.state.startDate} disableCloseOnClickOutside={false} closeOnSelect={true} inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off' }} dateFormat="YYYY-MM-DD" name="startDate" onChange={this.handlerStartDateTime} />
                            </div>

                            <div className="form-group mt-20p col-xl-3 col-lg-3">
                                <label>To Date</label>
                                <Datetime value={this.state.endDate} disableCloseOnClickOutside={false} closeOnSelect={true} inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off' }} dateFormat="YYYY-MM-DD" onChange={this.handlerEndDateTime} />
                            </div>
                            <div className="form-group mt-20p col-xl-3 col-lg-3">
                                <label><br />&nbsp;</label>
                                <button type="button" className="btn btn-success" onClick={this.onClickSummaryData} style={{marginTop:'30px'}}>Submit</button>
                            </div>
                        </div>
                        <div id="myGrid" style={{ width: "100%", height: "478px" }} className={"ag-theme-balham " + this.state.showGridData}>
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus={true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                enableRangeSelection={true}
                            //onCellClicked={this.onCellClicked}
                            // onCellEditingStopped={this.onCellUpdateData}
                            />
                        </div>
                    </div>
                </div>


                {/* <button className="btn btn-success" onClick={this.formAssignHandler} style={{marginLeft:"91em"}}>Insert Exports</button> */}
                <div className={"slide-r " + (this.state.bulkslide)} style={{ overflow: "hidden" }}>
                    <h3 className="subH">Add Transporters Data</h3>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="container-fluid">
                            <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>
                                <div className="form-group">
                                    <Select
                                        placeholder={"Select Transporter"}
                                        closeMenuOnSelect={true}
                                        onChange={this.onChangeTransporter}
                                        className={"border-radius-0"}
                                        style={{ borderRadius: "0px" }}
                                        options={this.state.transportersList}
                                    />
                                </div>
                                <div className="form-group">
                                    <input type="text" name="fleet_size" id="fleet_size" placeholder="Fleet Size" className="form-control" required />
                                </div>
                                <div className="form-group">
                                    <input type="text" name="owner_name" id="owner_name" placeholder="Owner Name" className="form-control" required />
                                </div>
                                <div className="form-group">
                                    <input type="text" name="manager_name" id="manager_name" placeholder="Manager Name" className="form-control" required />
                                </div>
                                <div className="form-group">
                                    <input type="text" name="gur_op_clusters" id="gur_op_clusters" placeholder="Operating Clusters (GURGAON)" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <input type="text" name="guj_op_clusters" id="guj_op_clusters" placeholder="Operating Clusters (GUJARAT)" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <input type="text" name="tvpb_op_clusters" id="tvpb_op_clusters" placeholder="Operating Clusters (TVPB)" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <input type="text" name="tvpn_op_clusters" id="tvpn_op_clusters" placeholder="Operating Clusters (TVPN)" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <input type="text" name="sil_op_clusters" id="sil_op_clusters" placeholder="Operating Clusters (SILIGURI)" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Update Data</button>
                                    <button type="button" onClick={this.resetUpload} className="btn btn-default">Cancel</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll}></div>
            </div>

        );
    }
}

function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber1(date) {
    console.log(date.length);
    console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    console.log(yearNumber, monthNumber, dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        //mask:'39-19-9999 29:59',
        format: 'd-m-Y'
    });
    $('.datetimepicker_date').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker: false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    // console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import CreatableSelect from 'react-select/creatable';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import DrawMap from '../common/drawmap';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import {
    getDDMMYYYYHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenDDMMYYYYHHMMSS,
} from '../common/utils';
import CSVFileValidator from 'csv-file-validator';
import EditButton from "./editbutton";
import redirectURL from '../redirectURL';
import Counters from '../layouts/d22consignmentkpicountersComponent';
import KpiTransitDelayActions from '../manage/kpiTransitDelayActions';
import Notransit from '../manage/notransitComponent.js';
import kpiActions from '../manage/kpiActionsComponent';
import NoOverspeed from '../manage/nooverspeedComponent.js';
import kpiNightDriveActions from '../manage/kpiNightDriveActionsComponent';
import EwayInvoice from '../manage/ewayinvoice';
import Nonightdrive from '../manage/nonightdriveComponent.js';
import NonDigilock from '../manage/nondigilock';
import KpiLoadingDelayActions from '../manage/kpiLoadingDelayActions';
import KpiUnLoadingDelayActions from '../manage/kpiUnLoadingDelayActions';
import KpiEnrouteStoppagesActions from '../manage/kpiEnrouteStoppagesActionsComponent';
import RouteNightDrivemap from '../manage/routenightdrivemap';
import RoutemapTransitAction from '../manage/routemaptransit';
import NoGpsLifetime from '../manage/nogpslifetimeActionComponent.js';
import NoGps from '../manage/nogpsActionComponent.js';
import Gpsdata from '../manage/gpsdataaction.js';
import RoutemapNoGPSAction from '../manage/routemapnogps';
import RoutemapDigiLockAction from '../manage/routemapdigilock';
import RoutemapAction from '../manage/routemapActionComponent';
import RoutemapenrouteAction from '../manage/routemapenroute';
import LoadSummaryDrawmap from '../layouts/loadSummaryDrawmap'; 
import Modal from 'react-responsive-modal';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import { NavItem } from 'reactstrap';
import { isInteger, lte } from 'lodash';


var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class EwayBillMonitoringForCourier extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            show1: false,
            basicTitle1: '',
            basicType1: "default",
            csvcontent: "",
            file: "",
            loadshow: 'show-n',
            overly: 'show-n',
            uploadYard: "",
            uploadDispatchDate: "",
            sliderTranslate: '',
            sliderRouteTranslate: '',
            sliderCommentTranslate: "",
            rowData: [],
            rowSelection: 'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize: 50,
            vehicleList: [],
            geoFenceData: "",
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            dept_code: "",
            pagetitle: "E-Way Bill Monitoring",
            bulkslide: "",
            consignee: "",
            truck_no: "",
            startDate: "",
            endDate: "",
            countersjson: "",
            //components : {datepicker:getDatePicker()},
            frameworkComponents: {
                EditButton: EditButton,
                consignmentActions: ConsignmentActions,
                kpiTransitDelayActions: KpiTransitDelayActions,
                notransit: Notransit,
                kpiActions: kpiActions,
                NoOverspeed: NoOverspeed,
                kpiNightDriveActions: kpiNightDriveActions,
                EwayInvoice:EwayInvoice,
                NonDigilock:NonDigilock,
                nonightdrive: Nonightdrive,
                KpiLoadingDelayActions: KpiLoadingDelayActions,
                kpiUnLoadingDelayActions: KpiUnLoadingDelayActions,
                kpiEnrouteStoppagesActions: KpiEnrouteStoppagesActions,
                noGpsLifetime:NoGpsLifetime,
                nogps:NoGps,
                gps:Gpsdata,
                RoutemapenrouteAction:RoutemapenrouteAction,
            },
            showDiv: 'show-n',
            mapinfo: "",
            dealer: "",
            consignment_code: "",
            maptruckno: "",
            routeTruck: "",
            rownode: "",
            leg_no: 0,
            rowId: "",
            csvData: [],
            bulkMarkData: [],
            tabOneactive: "activet",
            tabTwodelivered: '',
            tabThreeconsignmentform: '',
            activeClass: 'col-xl-6 col-lg-6 show-m',
            tabOneActiveData: "show-m",
            tabTwoDeliveredData: "show-n",
            tabThreeCreateConsgData: "show-n",
            transporterName: '',
            vehicleNo: '',
            consignerCode: '',
            gateInTime: '',
            gateOutTime: '',
            trucksList: [],
            transportersList: [],
            vin_no: '',
            gridRowData: [],
            vinIsDisabled: true,
            source: '',
            destination: '',
            sourceList: [],
            digi_lock:[],
            latLngArray : [],
            mapShow : 0,
            sliderTitle : "Bulk Upload",
			open: false,
            digiAlertsData:[],
            dataType : 0,
            activeCnt : 0,
            expiredCnt : 0,
            expiringtomorrow : 0,
            expiringtoday : 0,
            consigners : []
        }
        this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
		this.onShowNoGPSRouteMap = this.onShowNoGPSRouteMap.bind(this);
        this.selectConsignerOptionsItems = this.selectConsignerOptionsItems.bind(this);
        this.getTruckConsignments = this.getTruckConsignments.bind(this);
      
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
       
    }
    async componentDidMount() {
        this.logPageView();
        loadDateTimeScript();
        redirectURL.post("/consignments/getUniqueCourierConsignerCodes").then(async(response) => {
            // console.log("responseData", responseData)
             var respData = response.data;
             this.setState({
                consigners: respData,
             })
         })
        this.onLoadPageData(0)
    }
    onLoadPageData = dataType => {
        this.setState({
            loadshow:"show-m",
            overly:"show-m",
        })
        var reqparams = {
            dataType : dataType
        }
        var startDate = "";
        var endDate = "";
        if(dataType == 0)
        {
            var startDate = moment.parseZone().subtract(3, 'days').format('YYYY-MM-DD');
            reqparams.startDate = startDate;
            var endDate = moment.parseZone().format('YYYY-MM-DD');
            reqparams.endDate = endDate;
        }
        else
        {
            var startDate = moment.parseZone().subtract(10, 'days').format('YYYY-MM-DD');
            reqparams.startDate = startDate;
            var endDate = moment.parseZone().format('YYYY-MM-DD');
            reqparams.endDate = endDate;
        }
        if(localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !='')
        {
            reqparams.transporter_code = JSON.parse(localStorage.getItem("transportercode"));
        }
        redirectURL.post("/consignments/getPRTEwayBillCourierConsignments", reqparams).then(async(response) => {
           // console.log("responseData", responseData)
            var gridData = response.data;
            await this.setState({
                rowData: gridData,
                gridRowData: gridData,
                //countersjson: pagecounters,
                loadshow:"show-n",
                overly:"show-n",
                startDate : startDate,
                endDate : endDate,
            })
            await this.loadCountersData();
        })
    }
    loadCountersData = async() => {
        var gridData = this.state.rowData;
        var activeData = [];
        var expiringtomorrowData = [];
        var expiringtodayData = [];
        var expiredData = [];
        await gridData.map(function(e){
            if(e.eway_bill_extended_validity_date !=undefined && e.eway_bill_extended_validity_date !='')
            {
                var validityDate = e.eway_bill_extended_validity_date;
                var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
                var d2 = moment.parseZone(new Date(validityDate)).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
                var ms = moment(d2,"YYYY-MM-DD HH:mm:ss").diff(moment(d1,"YYYY-MM-DD HH:mm:ss"));
                var d = moment.duration(ms);
                var difference = parseFloat(d.asDays());
                if(difference > 0 && difference <= 1)
                {
                    expiringtodayData.push(e);
                }
                else if(difference > 1 && difference <= 2)
                {
                    expiringtomorrowData.push(e);
                }
                else if(difference <= 0)
                {
                    expiredData.push(e);
                }
                else{
                    activeData.push(e);
                }
            }
            else
            {
                if(e.eway_bill_expiry_date !=undefined && e.eway_bill_expiry_date !='')
                {
                    var validityDate = e.eway_bill_expiry_date;
                    var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
                    var d2 = moment.parseZone(new Date(validityDate)).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
                    var ms = moment(d2,"YYYY-MM-DD HH:mm:ss").diff(moment(d1,"YYYY-MM-DD HH:mm:ss"));
                    var d = moment.duration(ms);
                    var difference = parseFloat(d.asDays());
                    if(difference > 0 && difference <= 1)
                    {
                        expiringtodayData.push(e);
                    }
                    else if(difference > 1 && difference <= 2)
                    {
                        expiringtomorrowData.push(e);
                    }
                    else if(difference <= 0)
                    {
                        expiredData.push(e);
                    }
                    else{
                        activeData.push(e);
                    }
                }
            }
        });
        await this.setState({
            activeData : activeData,
            expiringtomorrowData : expiringtomorrowData,
            expiringtodayData : expiringtodayData,
            expiredData : expiredData,
            activeCnt : activeData.length,
            expiredCnt : expiredData.length,
            expiringtomorrow : expiringtomorrowData.length,
            expiringtoday : expiringtodayData.length
        });
    }
    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridReady1 = params => {
        this.gridApi1 = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi1 = params.columnApi;
    };


    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }
    onGridState1 = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi1.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi1.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi1.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi1.getFilterModel();
        this.gridApi1.setFilterModel(window.filterState);
    }

    changeSelectedConsignee = (e) => {
        // console.log("e", e)
        this.setState({
            transporterName: e,
        })
    }
    changeVehicleNo = (e) => {

        this.setState({
            vehicleNo: e,
        })
    }

    changeSource = (e) => {
        this.setState({
            source: e,
        })
    }

    changeDestination = (e) => {
        this.setState({
            destination: e,
        })
    }

    onShowTransitKpi = async (params) => {
		if(params != 0)
		{
			let eventLabel = googleAnalytics.page.action.transitDelay;
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
			//console.log("IN onShowTransitKpi, params = ",params);
			//console.log(params);
			//console.log("Consign onShowTransitKpi params = ",params);
			//console.log(params.column.colId);
			if(params != 0)
			{
				var transitedelaydata=[];
				
				await this.setState({
						
					detailCellRendererParams:{
						suppressRefresh: true,
						detailGridOptions: {
							headerHeight:50,
							columnDefs: [
								{ headerName: "",field: "_id", 
								
									cellRendererFramework:RoutemapTransitAction,
									width:50,
									suppressSizeToFit: true,
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								},
								{ headerName: "Truck No",field: "truck_no", width:100,resizable: true,
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								},
								{ headerName: "Leg Start Time",field: "leg_start", width:120,resizable: true,
									valueGetter:function(params){
										return getDDMMYYYYHHMMSS(params.data.leg_start);
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								},
								{ headerName: "Leg End Time",field: "leg_end_eta", 
								width:120,resizable: true,
									valueGetter:function(params){
										return getDDMMYYYYHHMMSS(params.data.leg_end_eta);
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								},
								{ headerName: "Exp. Leg Distance (KM)",
								field: "leg_expected_distance", width:140,resizable: true,
									valueGetter:function(params){
										return Math.round(params.data.leg_expected_distance);
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								},
								{ headerName: "Actual Leg Distance Covered (KM)",
								field: "actual_leg_distance", width:140,resizable: true,
									valueGetter:function(params){
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if(params.data.actual_leg_distance != '' && params.data.actual_leg_distance != undefined)
										{
											return Math.round(params.data.actual_leg_distance);
										}
										else{
											return 0;
										}
										
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								},							
								{ headerName: "Exp. Distance from Trip Start(KM)",
								field: "expected_distance_start_to_leg", width:140,resizable: true,
									valueGetter:function(params){
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if(params.data.expected_distance_start_to_leg != '' && params.data.expected_distance_start_to_leg != undefined)
										{
											return Math.round(params.data.expected_distance_start_to_leg);
										}
										else{
											return 0;
										}
										
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								
								},
								{ headerName: "Actual Distance from Trip Start (KM)",
								field: "actual_distance_traveled_start_to_leg", width:140,resizable: true,
									valueGetter:function(params){
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if(params.data.actual_distance_traveled_start_to_leg != '' && params.data.actual_distance_traveled_start_to_leg != undefined)
										{
											return Math.round(params.data.actual_distance_traveled_start_to_leg);
										}
										else{
											return 0;
										}
										
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								
							
								},
								
								{ 
									headerName: "Google Distance from Start (KM)",
									field: "actual_start_to_leg_google_distance", 
									width:140,
									resizable: true,
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								
							
								},
								{ 
									headerName: "GPS Data Available",
									field: "no_gps_data", 
									width:140,
									resizable: true,
									valueGetter:function(params){
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if(params.data.no_gps_data == 1)
										{
											return "Not Available";
										}
										if(params.data.no_gps_data == 0)
										{
											return "Available";
										}
										
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								
							
								},
								
								
								{ 
									headerName: "Trip Completed",
									field: "trip_completed", 
									width:140,
									resizable: true,
									valueGetter:function(params){
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if(params.data.trip_completed == 1)
										{
											return "Yes";
										}
										if(params.data.trip_completed == 2)
										{
											return "";
										}
										if(params.data.trip_completed == 0)
										{
											return "";
										}
										
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								
							
								}							
							],
							overlayNoRowsTemplate: 'No rows to show',						
						},
						getDetailRowData: function(params) {
							//console.log("Step 4 ",transitedelaydata)
							//console.log("Step 4 ",params)
							redirectURL.post('/consignments/snd22transitdelay', {
								consignment_code:params.data.consignment_code,
								consignee_code:params.data.consignee_code, 
								truck_no:params.data.truck_no
							})
							.then(async (response) =>{
								//console.log("Step 1 ",response.data)
								transitedelaydata=response.data;
								var legsarr=[]
								transitedelaydata.map((item) => {
									//if(item.trip_completed < 2)
									//{
									legsarr.push(item)
									//}
								})
								// console.log('transitedelaydata ', transitedelaydata);

								params.successCallback(legsarr);
								//console.log("Step 2 ",response.data)
								
							});
							
						}
					}
				});
				
				//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
				if(params.column.colDef.field == 'transit_delay' || params.column.colDef.field == 'no_gps_data')
				{
					params.node.setExpanded(!params.node.expanded);
					//console.log("expanded ",params.node.expanded)
					if(params.node.expanded == false)
					{
						transitedelaydata=[]
					}
				}
				else{

					params.node.setExpanded(false);
				}
				//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
				
			}
			else{
				this.setState({
				
					detailCellRendererParams:{
						detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No",field: "truck_no" },
							{ headerName: "Speed (km/h)",field: "speed" },
							{ headerName: "Reported At",field: "first_instance" }
						]
						
						},
						getDetailRowData: function(params) {
							//console.log("Child ", params);
						params.successCallback([]);
						}
					}
				});
			}
		}
		else
		{
			this.setState({
				show:true,
				basicTitle:"Transit for this consignment has not yet started",
				basicType:"info"
			});
		}
		
	}

    onShowNightDriveKpi = async (params) => {
        let eventLabel = googleAnalytics.page.action.nightDrive;
        let eventOptions = {
            "category": this.state.pagetitle,
            "action": this.state.eventAction,
            "label": eventLabel,
        }
        googleAnalytics.logEvent(eventOptions);
        //console.log("IN onShowNightDriveKpi, params = ",params);
        //console.log(e);
        //console.log("Consign Overspeed ",params);

        if (params != 0) {
            var overspeedData = [];


            //console.log("Step 3 ",overspeedData)

            await this.setState({

                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [
                            {
                                headerName: "", field: "_id",

                                cellRendererFramework: RouteNightDrivemap,
                                width: 50,
                                suppressSizeToFit: true
                            },
                            { headerName: "Truck No", field: "truck_no", width: 150, resizable: true },
                            {
                                headerName: "Traveled From", field: "first_instance", width: 180, resizable: true,
                                valueGetter: function (params) {
                                    return getDDMMYYYYHHMMSS(params.data.first_instance);
                                }
                            },
                            {
                                headerName: "Traveled Until", field: "recent_instance", resizable: true,
                                valueGetter: function (params) {
                                    return getDDMMYYYYHHMMSS(params.data.recent_instance);
                                }
                            },
                            {
                                headerName: "Distance Traveled (km)", field: "distance_from_first_instance", width: 180,
                                resizable: true,
                                valueGetter: function (params) {
                                    return Math.round(params.data.distance_from_first_instance)
                                }
                            }
                        ],
                        overlayNoRowsTemplate: 'No rows to show',
                    },
                    getDetailRowData: function (params) {
                        //console.log("Step 4 ",overspeedData)
                        redirectURL.post('/consignments/sndnightdriving', {
                            consignment_code: params.data.consignment_code,
                            gate_out_time: params.data.gate_out_time,
                            truck_no: params.data.truck_no
                        })
                            .then(async (response) => {
                                //console.log("Step 1 ",response.data)
                                overspeedData = response.data;
                                params.successCallback(overspeedData);
                                //console.log("Step 2 ",response.data)

                            });

                    }

                }
            });

            //if(params.column.colId == 'nightdriving_exception')
            if (params.column.colDef.field == 'nightdriving_exception') {

                params.node.setExpanded(!params.node.expanded);
            }
            else {

                params.node.setExpanded(false);
            }
            //console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

        }
        else {
            this.setState({

                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [
                            { headerName: "Truck No", field: "truck_no" },
                            { headerName: "Speed (km/h)", field: "speed" },
                            { headerName: "Reported At", field: "first_instance" }
                        ]

                    },
                    getDetailRowData: function (params) {
                        //console.log("Child ", params);
                        params.successCallback([]);
                    }
                }
            });
        }

    }
    
    onShowDigiKpi = async (params) => {
        let eventLabel = googleAnalytics.page.action.digi_lock;
        let eventOptions = {
            "category": this.state.pagetitle,
            "action": this.state.eventAction,
            "label": eventLabel,
        }
        googleAnalytics.logEvent(eventOptions);
        //console.log("IN onShowDigiKpi, params = ",params);
        //console.log(e);
        //console.log("Consign Overspeed ",params);

        if (params != 0) {
            var overspeedData = [];


            //console.log("Step 3 ",overspeedData)

            await this.setState({

                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [
                            { headerName: "Invoice No", field: "invoice_no", width: 150, resizable: true },
                            {
                                headerName: "Eway Bill No", field: "eway_bill_no", width: 180, resizable: true,
                               
                            },
                            {
                                headerName: "Eway Bill Date", field: "eway_bill_date", width: 180,
                                resizable: true,
                                valueGetter: function (params) {
                                    return getHyphenDDMMMYYYYHHMM(params.data.eway_bill_date)
                                }
                            },
                            {
                                headerName: "Eway Bill Expiry Date", field: "eway_bill_expiry_date", width: 180,
                                resizable: true,
                                valueGetter: function (params) {
                                    return getHyphenDDMMMYYYYHHMM(params.data.eway_bill_expiry_date)
                                }
                            }
                        ],
                        overlayNoRowsTemplate: 'No rows to show',
                    },
                    getDetailRowData: function (params) {
                        console.log("Step 4 ",params)
                        params.data.data_type = 2;
                        redirectURL.post('/consignments/ewayBillInvoiceData', params.data)
                            .then(async (response) => {
                                //console.log("Step 1 ",response.data)
                                overspeedData = response.data;
                                params.successCallback(overspeedData);
                                //console.log("Step 2 ",response.data)

                            });

                    }

                }
            });

            //if(params.column.colId == 'invoice_wise_legs')
            if (params.column.colDef.field == 'invoice_wise_legs') {

                params.node.setExpanded(!params.node.expanded);
            }
            else {

                params.node.setExpanded(false);
            }
            //console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

        }
        else {
            this.setState({

                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [
                            { headerName: "Invoice No", field: "invoice_no", width: 150, resizable: true },
                            {
                                headerName: "Eway Bill No", field: "eway_bill_no", width: 180, resizable: true,
                               
                            },
                            {
                                headerName: "Eway Bill Date", field: "eway_bill_date", width: 180,
                                resizable: true,
                                valueGetter: function (params) {
                                    return getHyphenDDMMMYYYYHHMM(params.data.eway_bill_date)
                                }
                            },
                            {
                                headerName: "Eway Bill Expiry Date", field: "eway_bill_expiry_date", width: 180,
                                resizable: true,
                                valueGetter: function (params) {
                                    return getHyphenDDMMMYYYYHHMM(params.data.eway_bill_expiry_date)
                                }
                            }
                        ]

                    },
                    getDetailRowData: function (params) {
                        //console.log("Child ", params);
                        params.successCallback([]);
                    }
                }
            });
        }

    }
    onShowNightDriveRouteMap(rownode){
		// console.log("IN onShowNightDriveRouteMap, params = ",rownode);
		// console.log("nightdrivenode", rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow:'show-m'
		});
		var d = new Date(rownode.first_instance);
    	var r = new Date(rownode.recent_instance);
    	var fintance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var eintance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = { 
			truck_no:rownode.truck_no,
            first_instance:fintance,
            recent_instance:eintance,
		}
		
		 redirectURL.post('/reports/nightdriveroutes',params)
		  .then((response) => {
		  
		   //var records = JSON.parse(JSON.stringify(response)).data;
			console.log("nightroutedata",response.data);
			
		   this.setState({
				mapinfo:response.data,
				sliderRouteTranslate:"slider-translate-50p",
				consignment_code:rownode.truck_no,
				defTransitCoords: {"lat":rownode.first_lat,"lng":rownode.first_lng},
				maptruckno:rownode.truck_no,
				routeTruck:	{"truck_no":rownode.truck_no,"startTime":response.data['route_details']['start_time'],
					"endTime": response.data['route_details']['end_time']},
				loadshow:'show-n',
				overly:'show-m',
				tolls : response.data.tolls
		   });
		   	this.renderMap();
		
			   
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
		  
	}

    insertdttconsignments = (event) => {
        event.preventDefault();
        let eventLabel = googleAnalytics.page.action.insertdttconsignments;
        let eventOptions = {
            "category": this.state.pagetitle,
            "action": this.state.eventAction,
            "label": eventLabel,
        }
        // googleAnalytics.logEvent(eventOptions);

        var vin_no = [];
        if (this.state.vin_no.length != 0) {
            this.state.vin_no.map((e) => { vin_no.push(e.value) })
        }
        let gateInTime = this.state.gateInTime
        let gateOutTime = this.state.gateOutTime
        let consignmentCode = $("#consignmentCode").val();
        let quantity = $("#Quantity").val();
        let driver_name= $("#driverName").val();
        let driver_phone_no= $("#driverPhoneNumber").val();
        let dg_serial_no= $("#dgSerialNum").val();
        let transporterCode = this.state.transporterName.value;
        let transporterName = this.state.transporterName.label;
        let vehicleNo = this.state.vehicleNo.label;
        let consignerCode = this.state.source.value;
        let consignee_code = this.state.destination.value;
        if (gateInTime == '' || gateInTime == "NaN-NaN-NaN") {
            gateInTime = "";
        }
        consignmentCode = removeSplChars(consignmentCode)
        driver_name = removeSplChars(driver_name)
        driver_phone_no = removeSplChars(driver_phone_no)
        dg_serial_no = removeSplChars(dg_serial_no)
        consignmentCode = consignmentCode.toUpperCase()
        if (vin_no.length == quantity) {
            const reqparams =
            {
                consignment_code: consignmentCode,
                gate_in_time: gateInTime,
                gate_out_time: gateOutTime,
                transporter_name: transporterName,
                transporter_code: transporterCode,
                truck_no: vehicleNo,
                consigner_code: consignerCode,
                consignee_code: consignee_code,
                quantity: quantity,
                dept_code: "SND22",
                status: 2,
                vin_no: vin_no,
                driver_name: driver_name,
                driver_phone_no: driver_phone_no,
                dg_serial_no : dg_serial_no
            };
            let userId = localStorage.getItem('userid');
            if (userId != undefined && userId != '') {
                reqparams['userId'] = userId
            }
            reqparams['created_by'] = localStorage.getItem('username');
            // console.log("reqparams", reqparams);
            if (consignmentCode != "" && consignmentCode != undefined && quantity != null && quantity !== '' && transporterName !== undefined && transporterName !== "" && vehicleNo !== undefined && vehicleNo !== ""
                && consignerCode !== "" && consignerCode !== undefined && consignee_code !== undefined && consignee_code !== ""
                && gateOutTime != "__-__-____"
            ) {

                this.setState({
                    loadshow: 'show-m',
                    overly: "show-m"
                });
                redirectURL.post("/consignments/insertD22consignments", reqparams).then((response) => {
                    if (response.data.message == "success") {
                        this.onLoadPageInitialRowData()
                        this.setState({
                            basicTitle: "D22Consignment Created Successfully..!!!",
                            basicType: "success",
                            show: true,
                            rowId: "",
                            loadshow: 'show-n',
                            overly: "show-n",
                            transporterName: '',
                            vehicleNo: '',
                            source: '',
                            destination: '',
                            vin_no: ''
                        })
                        $("#consignmentCode").val(" ");
                        $("#Quantity").val(" ");
                        $("#driverName").val(" ");
                        $("#driverPhoneNumber").val(" ");
                        $("#dgSerialNum").val(" ");
                    }
                    else if (response.data.message == "D22Consignment Already Exists.. !!!") {
                        this.setState({
                            basicTitle: "D22Consignment Already Exists.. !!!",
                            basicType: "warning",
                            show: true,
                            loadshow: 'show-n',
                            overly: "show-n"
                        })
                    }
                    // console.log("",response.data);

                }).catch((e) => {
                    console.log(e);
                })
            }
            else {
                console.log("empty check")
                this.setState({
                    basicTitle: "Fields Should not be Empty",
                    basicType: "warning",
                    show: true
                })
            }


        } else {
            this.setState({
                basicTitle: "Please Enter VinNo's matching Quantity",
                basicType: "warning",
                show: true

            })
        }


    }

    quantityHandler = e => {
        // console.log("event",e)
        this.setState({
            vinIsDisabled: false
        })
    }
    onChangeVins = (e) => {
        let quantity = $("#Quantity").val();
        // console.log("e",e)
        this.setState({ vin_no: e })
        // if(e !== null){
        //     if(e.length == quantity){
        //         this.setState({ vin_no: e })
        //     }       
        //     else{
        //         this.setState({
        //             basicTitle : "Please Enter VinNo's matching Quantity",
        //             basicType : "warning",
        //             show : true
        //         })
        //     }
        // }
    }

    showBulkUpload() {
        this.setState({
            overly: "show-m",
            bulkslide: "slider-translate"
        })
    }
    resetUpload = () => {
        this.setState({
            bulkslide: '',
            overly: 'show-n',
            file: '',
            bulkMarkData: []
        })
        $('#upform')[0].reset();
        document.getElementById("upform").reset();
    }

    changeFileHandler = async (e) => {
        var dt = '';
        const config = {
            headers: [
                {
                    name: 'eway_bill_no',
                    inputName: 'eway_bill_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'eway_bill_extended_validity_date',
                    inputName: 'eway_bill_extended_validity_date',
                    required: false,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
            ]
        }

        var data = CSVFileValidator(e.target.files[0], config)
            .then((csvData) => {
                let csvList = csvData.data;
                var flag = 0;
                // let promiseVar = csvList.map((e) => {
                //     e['consignment_code'] = removeSplChars(e.consignment_code)
                //     let vinList = (e['vin_no']).split(',')
                //     if (isNaN(parseInt(e.quantity))) {
                //         flag = 1;
                //         this.setState({
                //             basicTitle: "Please Enter Quantity as Number",
                //             basicType: "warning",
                //             show: true
                //         })
                //     }
                //     else {
                //         if (e.quantity != vinList.length) {
                //             flag = 1;
                //             this.setState({
                //                 basicTitle: "Please Enter VinNo's matching Quantity",
                //                 basicType: "warning",
                //                 show: true
                //             })
                //         }
                //     }
                // })
                // console.log(csvList,'csvList')
                if (flag == 0) {
                        this.setState({
                            bulkMarkData: csvList
                        });
                }

            })
            .catch(err => {
                console.log(err, "error")
            })
        // console.log("e.target.files[0]",e.target.result);
        if (e.target.files[0] != undefined) {
            var out = new Promise(function (reject, resolve) {
                var reader = new FileReader();
                reader.onload = async function (e) {
                    var contents = await e.target.result;
                    resolve(contents);
                };
                var tt = reader.readAsText(e.target.files[0]);
            });
            this.setState({
                file: e.target.files[0]
            });
            // console.log("e.target.files[0].type ", e.target.files[0]);

            if (e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
                // console.log(fileData);
                // console.log(typeof(fileData))

            }
            else {
                e.target.value = null;
                this.setState({
                    uploadFile: '',
                    show: true,
                    basicType: 'danger',
                    basicTitle: 'Please upload file having extensions .csv only.',
                });
            }
        }

    }

    async uploadBulkFormHandler(e) {
        e.preventDefault();
        var dataType = this.state.dataType;
        var fileData = this.state.bulkMarkData;
        var flag = 0;
        // console.log(fileData, "fileData");
        if (flag == 0) {
            if (fileData.length > 0) {
                var reqParams = {
                    csvData: fileData,
                    user_email: localStorage.getItem("email"),
                    user_name: localStorage.getItem("username"),
                    page_type: 2
                }
                this.setState({
                    loadshow: 'show-m',
                    overly: "show-m"
                });
                redirectURL.post("/consignments/bulkUpdateEwayBillExtendedValidity", reqParams).then((response) => {
                    var resp = response.data.message;
                    // console.log('resp', response.data)
                    this.setState({
                        basicTitle1: resp,
                        basicType1: "success",
                        show1: true,
                        bulkslide: '',
                        overly: 'show-n',
                        file: "",
                        loadshow: 'show-n',
                        bulkMarkData: []
                    })
                    this.onLoadPageData(dataType);
                    $('#upform')[0].reset();
                    //window.location.reload();
                }).catch((e) => {
                    console.log(e);
                })
            }
            else {
                this.setState({
                    basicTitle: "Invalid Data",
                    basicType: "warning",
                    show: true,
                    file: "",
                    bulkMarkData: []
                })
                $('#upform')[0].reset();
            }
        }
        else {
            $("#bulkUploadID").val();
            this.setState({
                basicTitle: "Fields Should not be Empty",
                basicType: "warning",
                show: true,
                file: "",
                bulkMarkData: []
            })
            $('#upform')[0].reset();
        }
    };
    closeAlert = () => {
        this.setState({
            show: false
        });
        //window.location.reload();
    }
    closeAlert1 = () => {
        this.setState({
            show1: false
        });
        var dataType = this.state.dataType;
        this.onLoadPageData(dataType);
        //window.location.reload();
    }

    onClickHideAll() {
        // console.log("cliekc");
        $("#editinputform").val("")
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            bulkslide: '',
            consignee: "",
            truck_no: "",
            rowId: "",
            sliderRouteTranslate: ""
        });

    }

    onClickTab(pageType) {
        if (pageType == "active") {
            $("#selectedFilter").val("");
            $("#inputTruckConsignment").val(" ");
            var tabOneactive = "activet";
            var tabTwodelivered = "";
            var tabThreeconsignmentform = "";
            var tabOneActiveData = "show-m";
            var tabTwoDeliveredData = "show-n";
            var tabThreeCreateConsgData = "show-n";
            this.onLoadPageData(0);
            var activeClass = 'col-xl-6 col-lg-6 show-m'
            this.setState({
                dataType:0,
                startDate: '',
                endDate: '',
                modetype: {value:"all",label:"All"},
                consigner: [{value:"all",label:"All"}],
            })
        }
        else if (pageType == "delivered") {
            $("#selectedFilter").val("");
            $("#inputTruckConsignment").val(" ");
            tabOneactive = "";
            tabTwodelivered = "activet";
            tabThreeconsignmentform = "";
            tabOneActiveData = "show-n";
            tabTwoDeliveredData = "show-m";
            tabThreeCreateConsgData = "show-n";
            this.onLoadPageData(1);
            activeClass = 'col-xl-6 col-lg-6 show-m';
            var sdate = moment.parseZone().subtract(3, 'days').format('YYYY-MM-DD');
            var edate = moment.parseZone().format('YYYY-MM-DD');
            this.setState({
                dataType:1,
                startDate: sdate,
                endDate: edate,
                modetype: {value:"all",label:"All"},
                consigner: [{value:"all",label:"All"}],
            })
        }
        else if (pageType == "createConsignments") {

            loadDateTimeScript();
            tabOneactive = "";
            tabTwodelivered = "";
            tabThreeconsignmentform = "activet";
            tabOneActiveData = "show-n";
            tabTwoDeliveredData = "show-n";
            tabThreeCreateConsgData = "show-m";
            activeClass = 'col-xl-6 col-lg-6 show-n'

            // this.onLoadPageData(2);
        }

        this.setState({
            tabOneactive,
            tabTwodelivered,
            tabThreeconsignmentform,
            tabOneActiveData,
            tabTwoDeliveredData,
            tabThreeCreateConsgData,
            activeClass
        })
    }

    gateOutDateTime = event => {
        let d = new Date(event._d);
        let startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate()) + " " + d.getHours() + ":" + ((d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes()) + ":" + ((d.getSeconds() < 10) ? "0" + d.getSeconds() : d.getSeconds());

        // console.log("gateOutdate", startdate)
        this.setState({
            gateOutTime: startdate
        })
    }

    gateInDateTime = event => {
        let d = new Date(event._d);
        let startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate()) + " " + d.getHours() + ":" + ((d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes()) + ":" + ((d.getSeconds() < 10) ? "0" + d.getSeconds() : d.getSeconds());

        // console.log("gateOutdate", startdate)
        this.setState({
            gateInTime: startdate
        })
    }

    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

        this.setState({
            startDate: startdate
        });

    }

    handlerEndDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
        this.setState({
            endDate: edate
        });
    }

    getTruckConsignments=(event)=>{
		this.setState({
			loadshow : "show-m",
			overly : "show-m"
		})
		event.preventDefault();
		var selectedFilter = $("#selectedFilter").val();
		var inputTruckConsignment = $("#inputTruckConsignment").val();
		if(selectedFilter == "" || inputTruckConsignment == "")
		{
			this.setState({
				basicTitle : "Fields Should not be Empty",
				basicType : "danger",
				show : true,
				loadshow : "show-n",
				overly : "show-n"
			})
		}
		else
		{
			var transporter_code = "";
			var transportercode = localStorage.getItem('transportercode');
			if(transportercode !=undefined && transportercode !="undefined" && transportercode !="")
			{
				var transporter_code = JSON.parse(transportercode);
				//var transporter_code = tptCode[0];
			}
			var parameters = {
				"selectedFilter":selectedFilter,
				"inputTruckConsignment":inputTruckConsignment,
                "transporter_code":transporter_code,
                "dataType" : this.state.dataType
			}
			redirectURL.post("/consignments/filterPRTEwayBillCourierConsignments",parameters)
			.then(async(response)=>{
				var gridData = response.data;
				// console.log(gridData,"gridData")
				await this.setState({
					rowData: gridData,
                    gridRowData: gridData,
					loadshow : "show-n",
					overly : "show-n",
                    modetype: {value:"all",label:"All"},
                    consigner: [{value:"all",label:"All"}],
				})
                await this.loadCountersData();
			}).catch=((e)=>
			{
				console.log(e);
			})	
		}
		
	}

    reloadPageData = () => {
        var dataType = this.state.dataType;
        $("#selectedFilter").val("");
        $("#inputTruckConsignment").val("");var startDate = "";
        var endDate = moment.parseZone().format('YYYY-MM-DD');
        if(dataType == 0)
        {
            var startDate = moment.parseZone().subtract(3, 'days').format('YYYY-MM-DD');
            this.setState({
                startDate: startDate,
                endDate: endDate,
                modetype: {value:"all",label:"All"},
                consigner: [{value:"all",label:"All"}],
            })
        }
        else
        {
            var startDate = moment.parseZone().subtract(10, 'days').format('YYYY-MM-DD');
            this.setState({
                startDate: startDate,
                endDate: endDate,
                modetype: {value:"all",label:"All"},
                consigner: [{value:"all",label:"All"}],
            })
        }
        this.onLoadPageData(dataType); 
    }

    onCloseUploadDiv = () => {
        this.setState({
            uploadDivWidth: '0%',
            sliderTranslate: "",
            tabsliderTranslate: '',
            showDiv: 'show-n',
            sliderTranslatesidebar: "",
            sliderCommentTranslate: "",
            uploadFile: '',
            file: '',
            csvmessage: '',
            csverrmessage: '',
            overly: 'show-n',
            loadshow: 'show-n',
            slideuploadeway: ""
        });
    }

    onCloseRouteDiv = () => {
        this.setState({
            uploadDivWidth: '0%',
            sliderRouteTranslate: "",
            sliderCommentTranslate: "",
            sliderSegmentTranslate: "",
            showDiv: 'show-n',
            uploadFile: '',
            file: '',
            csvmessage: '',
            csverrmessage: '',
            overly: 'show-n'
        });
    }

    handleRouteClick = e => {
        // console.log("e",e)
        // e.stopPropagation();
        this.onCloseUploadDiv(e);
        this.onCloseRouteDiv(e);
        //    this.onShowRouteDiv();
    }

    onCellClicked = (e) => {
        // console.log("celle", e)
        if (e.colDef.field == "maproute") {
            this.setState({
                loadshow: 'show-m',
            });
            if (e.data.gate_out_time) {
                var gateouttime = moment.parseZone(e.data.gate_out_time).format('YYYY-MM-DD HH:mm:ss');
            }

            var edate = '';
            var end_date;
            if (e.data.status <= 2) {
                end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
                edate = getHyphenDDMMYYYYHHMMSS(end_date);
            }
            else if (e.data.status == 3 || e.data.status == 4) {
                if (e.data.recent_dealer_reported) {
                    end_date = e.data.recent_dealer_reported.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
                    edate = getHyphenDDMMYYYYHHMMSS(e.data.recent_dealer_reported);
                }
            }
            else if (e.data.status == 5) {
                if (e.data.left_dealer_marked) {
                    end_date = e.data.left_dealer_marked.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
                    edate = getHyphenDDMMYYYYHHMMSS(e.data.left_dealer_marked);
                }
            }
            else if (e.data.status == 6) {
                if (e.data.force_closure_time) {
                    end_date = e.data.force_closure_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
                    edate = getHyphenDDMMYYYYHHMMSS(e.data.force_closure_time);
                }
            }
            else {
                end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
                edate = getHyphenDDMMYYYYHHMMSS(end_date);
            }

            if ((e.data.transit_time) > 0) {
                var seconddate = new Date(new Date(e.data.gate_out_time).getTime() + ((e.data.transit_time + 1) * 24 * 60 * 60 * 1000));
            }
            else {
                var seconddate = new Date(new Date(e.data.gate_out_time).getTime() + 2 * 24 * 60 * 60 * 1000);
            }

            var sdate = seconddate.getFullYear() + "-" + (seconddate.getMonth() + 1) + "-" + seconddate.getDate() + " " + seconddate.getHours() + ":" + seconddate.getMinutes() + ":" + seconddate.getSeconds();

            if (!end_date) {
                end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
            }
            var reqData = {
                truck_no: e.data.truck_no,
                consignment_code: e.data.consignment_code,
                consignee_code: e.data.consignee_code,
                from_date: gateouttime,
                to_date: end_date,
                map_mode: 1
            }
            // console.log("reqData", reqData)
            redirectURL.post('/consignments/maproutesforsnd22', reqData, {
                headers: {
                    'content-type': 'application/json'
                }
            })
                .then((response) => {
                    var records = response.data;
                    // console.log("Props data ", records)

                    this.setState({
                        loadshow: 'show-m'
                    });
                    try {
                        if (records.coords.length == 0 || records.status == "failure") {
                            //console.log("records", records.coords);
                            this.setState({
                                show: true,
                                basicTitle: 'No Route data available',
                                basicType: "danger",
                                loadshow: 'show-n'
                            });
                        }
                        else {
                            if (records != '') {
                                var sdate = e.data.gate_out_time;
                                if (e.data.invoice_time) {
                                    // console.log("came into invoice time", e.invoice_time)
                                    //console.log("Step1", e.invoice_time)
                                    var sdate = e.data.invoice_time;
                                }
                                else {
                                    //console.log("Step2")
                                    if (e.data.gate_in_time) {
                                        // console.log("came into gate in time", e.gate_in_time)
                                        //console.log("Step3")
                                        var sdate = e.data.gate_in_time;
                                    }
                                    else {
                                        //console.log("Step4")
                                        if (e.data.gate_out_time) {
                                            //console.log("Step5")
                                            // console.log("came into gate out time", e.gate_out_time)
                                            var sdate = e.data.gate_out_time;
                                        }
                                    }
                                }


                                var edate = '';
                                if (e.data.status <= 4) {
                                    //var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1)*86400000);
                                    //var edatesecond = new Date(ms);
                                    end_date = moment.parseZone().format('DD/MM/YYYY HH:mm:ss')
                                    edate = getHyphenDDMMYYYYHHMMSS(end_date);
                                }
                                else if (e.data.status == 5) {
                                    if (e.data.left_dealer_marked) {
                                        edate = getHyphenDDMMYYYYHHMMSS(e.data.left_dealer_marked);
                                    }
                                    else {
                                        end_date = moment.parseZone().format('DD/MM/YYYY HH:mm:ss')
                                        edate = getHyphenDDMMYYYYHHMMSS(end_date);
                                    }
                                }
                                //console.log("End Date ", edate)
                                this.setState({
                                    sliderRouteTranslate: "slider-translate-60p",
                                    showDiv: 'show-m',
                                    mapinfo: records,
                                    dealer: e.data.consignee_code,
                                    consignment_code: "Consignment : " + e.data.consignment_code,
                                    maptruckno: e.data.truck_no,
                                    routeTruck: { "truck_no": e.data.truck_no, "startTime": sdate, "endTime": end_date },
                                    loadshow: 'show-n',
                                    sidebarSubHeader: "Consignment Information",
                                    overly: 'show-m',
                                    rownode: e,
                                    leg_no: 0,
                                    tolls: records.tolls
                                });
                            }
                        }
                    }
                    catch (e) {
                        console.log(e, "error")
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
                // console.log("reqData", reqData)
                redirectURL.post('/consignments/snd22digilogs', e.data, {
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then((response1) => {
                    var digiAlertsData = response1.data;
                    this.setState({
                        digiAlertsData:digiAlertsData
                    })
                })
        }
    }

    onDigiAlertsClicked = () => {
        this.setState({            
            open:true,
            loadshow:'show-n'
        });
    }

    onCellUpdateData = e => {
        if (e.colDef.field == 'eway_bill_extended_validity_date') {
            var dataType = this.state.dataType;
            if (e.data.eway_bill_extended_validity_date !== undefined && e.data.eway_bill_extended_validity_date !== '') {
                var eway_bill_extended_validity_date = e.data.eway_bill_extended_validity_date;
                var validityDate = e.data.eway_bill_expiry_date;
                var d1 = moment.parseZone(new Date(eway_bill_extended_validity_date)).format("YYYY-MM-DD HH:mm:ss")
                var d2 = moment.parseZone(new Date(validityDate)).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
                var ms = moment(d1,"YYYY-MM-DD HH:mm:ss").diff(moment(d2,"YYYY-MM-DD HH:mm:ss"));
                var d = moment.duration(ms);
                var difference = parseFloat(d.asDays());
                console.log(difference,d1,d2)
                // console.log("e",e.data.eway_bill_extended_validity_date)
                if (dataType == 0) {
                    if(difference > 0)
                    {
                        var reqparams = {
                            consignment_code: e.data.consignment_code,
                            eway_bill_extended_validity_date: eway_bill_extended_validity_date,
                            user_email: localStorage.getItem("email"),
                            user_name: localStorage.getItem("username"),
                            page_type: 2
                        }
                        redirectURL.post("/consignments/updateEwayBillExtendedValidity", reqparams).then(response => {
                            let responseData = response.data.message;
                            // console.log("timerowData", responseData)
                            if (responseData == "success") {
                                this.setState({
                                    basicType: "success",
                                    basicTitle: "Success",
                                    show: true,
                                })
                                this.onLoadPageData(dataType);
                            }
                            else {
                                this.setState({
                                    basicType: "warning",
                                    basicTitle: responseData,
                                    show: true,
                                })
                                e.data.eway_bill_extended_validity_date = "";
                                this.gridApi.refreshCells();
                            }
                        })
                    }
                    else
                    {
                        this.setState({
                            basicType: "warning",
                            basicTitle: "E-Way Extended Validity Time should be greater than E-Way Bill Expiry Time",
                            show: true,
                        })
                        e.data.eway_bill_extended_validity_date = "";
                        this.gridApi.refreshCells();
                    }
                }
            }
        }
    }

    onSetCritical(params, status) {
        // console.log("onSetCritical ", params);
        // console.log("onSetCritical value ", status);
        redirectURL.post('/consignments/setCriticalStatusForD22', {
            params: params,
            is_critical: status,
            token: window.atob(localStorage.getItem('_t')),
            userId: localStorage.getItem("userid"),
        })
            .then((response) => {

                //var records = JSON.parse(JSON.stringify(response)).data;
                // console.log(response.data);
            });

    }

    validDateTime = current => {
        // var tommorow = moment().add( 1, 'day' );
        return current.isBefore();
    }

    /*NO GPS DATA*/


	onShowNoGPSKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.gpsAvailable;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowNoGPSKpi, params = ",params);
		//console.log(params);
		//console.log("Consign onShowNoGPSKpi params = ",params);
		//console.log(params.column.colId);
		if(params != 0)
		{
			var transitedelaydata=[];
			
			await this.setState({
					
				detailCellRendererParams:{
					suppressRefresh: true,
					detailGridOptions: {
						columnDefs: [
							{ 
								headerName: "",
								field: "_id",
								cellRendererFramework:RoutemapNoGPSAction,
								width:50,
								suppressSizeToFit: true,
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							},
							
							{ headerName: "Truck No",field: "truck_no", width:100,resizable: true,
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							},
							{ 
								headerName: "Last Data Receieved",
								field: "last_packet_datetime", 
								width:180,
								resizable: true,
								valueGetter:function(params){
									return getDDMMYYYYHHMMSS(params.data.last_packet_datetime);
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							},
							{ 
								headerName: "Data Received on",
								field: "data_received_on", 
								width:180,
								resizable: true,
								valueGetter:function(params){
									return getDDMMYYYYHHMMSS(params.data.packet_received_on);
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
						 	}						
						],
						overlayNoRowsTemplate: 'No rows to show',					
					},
					getDetailRowData: function(params) {
						//console.log("Step 4 ",transitedelaydata)
						//console.log("Step 4 ",params)
						redirectURL.post('/consignments/snd22nogpsdata', {
							consignment_code:params.data.consignment_code, 
							truck_no:params.data.truck_no
						})
						.then(async (response) =>{
							//console.log("Step 1 ",response.data)
							transitedelaydata=response.data;
							var legsarr=[]
							transitedelaydata.map((item) => {
								//if(item.trip_completed < 2)
								//{
								legsarr.push(item)
								//}
							})
							// console.log('transitedelaydata ', transitedelaydata);

							params.successCallback(legsarr);
							//console.log("Step 2 ",response.data)
							
						});
						
					}
				}
			});
			
			//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
			if(params.column.colDef.field == 'transit_delay' || params.column.colDef.field == 'no_gps_data')
			{
				params.node.setExpanded(!params.node.expanded);
				//console.log("expanded ",params.node.expanded)
				if(params.node.expanded == false)
				{
					transitedelaydata=[]
				}
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			this.setState({
			
				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed (km/h)",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]
					  
					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}
		
    }
    
    onShowNoGPSRouteMap(rownode){
		//console.log("IN onShowNoGPSRouteMap, params = ",rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		//console.log("rownode", rownode);
		this.setState({
			loadshow:'show-m'
		})
		var fintance = rownode.last_packet_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		//var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = { 
			truck_no:rownode.truck_no,
			consignment_code:rownode.consignment_code,
			//consignee_code:rownode.consignee_code,
            first_instance:fintance,
			recent_instance:eintance,
			screen:"consignment"
		}
		 redirectURL.post('/reports/nightdriveroutes',params)
		  .then((response) => {	
		  
		   //var records = JSON.parse(JSON.stringify(response)).data;
			//console.log("Inner Grid ",response.data);
			if(response.data.coords.length == 0)
			{
				this.setState({
					show: true,
					basicTitle:'No Route data available',
					basicType:"danger",
					loadshow:'show-n'
				});
			}
			else{
				var sdate='';
				var edate='';

				if(rownode.leg_start != '' && rownode.leg_start != undefined)
				{
					sdate=rownode.leg_start;
				}
				if(rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined)
				{
					edate=getDDMMYYYYHHMMSS(rownode.leg_end_eta)
				}
			this.setState({
					mapinfo:response.data,
					dealer:rownode.consignee_code,
					sliderRouteTranslate:"slider-translate-60p",
					defTransitCoords: rownode.consignee_coordinates,
					consignment_code:"Consignment : "+rownode.truck_no,
					maptruckno:rownode.truck_no,
					routeTruck:	{"truck_no":rownode.truck_no,"startTime":sdate,"endTime": edate},
					loadshow:'show-n',
					sidebarSubHeader:"Consignment Leg Information",
					overly:'show-m',
					rownode:rownode,
					leg_no:rownode.leg_no,
					tolls : response.data.tolls

			});

			// this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
				this.renderMap();
			
			} 
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
    }
    
    onShowDigiLockRouteMap(rownode)
    {
        var lattitude = rownode.latitude;
        var longititude = rownode.longitude;
        var locat = rownode.location;
        
        console.log(lattitude,"lattitude")
        console.log(longititude,"longititude")
        var latLngArray = [];
        if(lattitude != "" && lattitude !=undefined && longititude != "" && longititude !=undefined)
        {
            latLngArray.push({
                lat : lattitude,
                lng : longititude,
                locat : locat
            });
            this.setState({
                latLngArray : latLngArray,
                mapType : 4,
                locInfo : locat,
                bulkslide : "slider-translate-60p",
                overly: "show-m",
                sliderTitle : rownode.elock_id,
                mapShow : 1
            })
        }
        else
        {
            
        } 
    }
    
    onShowOverspeedKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.overspeed;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowOverspeedKpi, params = ",params);
		//console.log(params.column.colId);
		if(params != 0)
		{
			var overspeedData=[];
			
			//console.log("Step 3 ",overspeedData)
			var propsdata = params;
			await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							{ headerName:"",field:"_id", 
								width:50,
								cellRendererFramework:RoutemapAction,
								resizable: true
							},
							{ headerName: "Truck No",field: "truck_no", filter:true,resizable: true },
							{ headerName: "Speed (km/h)",field: "speed", filter:true,resizable: true },
							{ headerName: "Reported At",field: "first_instance", filter:true,resizable: true,
								valueGetter:function(params){
									return getDDMMYYYYHHMMSS(params.data.first_instance);
								}
							},
						],
						overlayNoRowsTemplate: 'No rows to show',
														
					},
					getDetailRowData: function(param) {
						// console.log("Step 4 ",param)
						redirectURL.post('/consignments/snd22overspeed', {
							consignment_code:param.data.consignment_code, 
							truck_no:param.data.truck_no
						})
						.then(async (response) =>{
							//console.log("Step 1 ",response.data)
							overspeedData=response.data;
							param.successCallback(overspeedData);
							//console.log("Step 2 ",response.data)
							
						});
						 
					},
					masterDetail: true
				}
			});
			
			if(propsdata.column.colDef.field == 'overspeeding_exception')
			{

				propsdata.node.setExpanded(!propsdata.node.expanded);
			}
			else{

				propsdata.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			this.setState({
			
				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]
					  
					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}
		
	}

    onShowEnrouteStoppagesKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.enrouteStoppages;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowEnrouteStoppagesKpi, params = ",params);
		//console.log(e);
		//console.log("Consign Overspeed ",params);
	
		if(params != 0)
		{
			var overspeedData=[];
			

			//console.log("Step 3 ",overspeedData)
			
			await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							
							{ headerName: "",field: "_id", 
							
								cellRendererFramework:RoutemapenrouteAction,
								width:50,
								suppressSizeToFit: true,
								
							},
							{ 
								headerName: "Break Start",
								field: "stoppage_start_time", 
								width:150,
								resizable: true,
								valueGetter:function(params){
									if(params.data.stoppage_start_time != undefined)
									{
										return getDDMMYYYYHHMMSS(params.data.stoppage_start_time)
									}
									else{
										return '';
									}
									
								}
						    },
							// { 
							// 	headerName: "Break End",
							// 	field: "reset_start_time", 
							// 	width:180,
							// 	resizable: true,
							// 	valueGetter:function(params){
							// 		if(params.data.reset_start_time != undefined)
							// 		{
							// 			return getDDMMYYYYHHMMSS(params.data.reset_start_time)
							// 		}
							// 		else{
							// 			return '';
							// 		}
									
							// 	}
							// },

							{ 
								headerName: "Exception Closed On",
								field: "modified_date", 
								width:220,
								resizable: true,
								valueGetter:function(params){
									if(params.data.exception_closed ==1)
									{
											
										if(params.data.modified_date != undefined)
										{
											return getDDMMYYYYHHMMSS(params.data.modified_date)
										}
										else{
											return '';
										}
									}
									else{
										return '';
									}
									
								}
							},
							{ 
								headerName: "Break Time",
								field: "elaped_time_from_last_moved_seconds",
								width:180,
								resizable: true,
								valueGetter:function(params){
									if(params.data.exception_closed == 1)
									{
										// if(params.data.elaped_time_from_last_moved_seconds != undefined)
										// {
										// 	var seconds = params.data.elaped_time_from_last_moved_seconds;
										// 	var days = parseInt(seconds / (24 * 3600)); 
										// 	var hours = parseInt(seconds/3600);
										// 	var minutes = Math.round((seconds-(hours*3600))/60)
										// 	//return hours+" Hr(s) "+minutes+" Min(s)";
										// 	return secondsToString(seconds)
										// }
										// else{
										// 	return '';
										// }
										var sdate = moment.parseZone(params.data.stoppage_start_time).format("x");
										if(typeof params.data.reset_datetime == 'undefined')
										{
											var edate = moment.parseZone().format("x");
										}
										else
										{
											var edate = moment.parseZone(params.data.reset_datetime).format("x");
										}
										var diff = (edate-sdate)/1000;
										return secondsToString(diff);
									}
									else{
										return '';
									}
								}
							}
						],
						overlayNoRowsTemplate: 'No rows to show',					
					},
					getDetailRowData: async function(params) {
						//console.log("Step 4 ",overspeedData)
						if(params.data.is_transshipment == 1)
						{
							var oldtruck = params.data.old_truck_no
						}
						else
						{
							var oldtruck = ''
						}
						redirectURL.post('/consignments/snd22enroutes', {
							consignment_code:params.data.consignment_code, 
							truck_no:params.data.truck_no, 
							old_truck_no:oldtruck
						})
						.then(async (response) =>{
							//console.log("Step 1 ",response.data)
							overspeedData=response.data;
							params.successCallback(overspeedData);
							//console.log("Step 2 ",response.data)
							
						});
						
					}
				}
			});
			
			if(params.column.colDef.field == 'enroute_exception')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			this.setState({
			
				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed (km/h)",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]
					  
					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}
		
    }
    
    onShowEnrouteRouteMap(rownode){
		//console.log("IN onShowEnrouteRouteMap, params = ",params);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		//console.log("rownode", rownode);
		this.setState({
			loadshow:'show-m'
		})
		var fintance = rownode.stoppage_start_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		if(rownode.reset_datetime)
		{
			var eintance = rownode.reset_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		
		}
		else{
			var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		
		}
		//var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = { 
			truck_no:rownode.truck_no,
			//consignment_code:rownode.consignment_code,
			//consignee_code:rownode.consignee_code,
            first_instance:fintance,
			recent_instance:eintance,
			screen:"consignment"
		}
		 redirectURL.post('/reports/nightdriveroutes',params)
		  .then((response) => {	
		  
		   //var records = JSON.parse(JSON.stringify(response)).data;
			//console.log("Inner Grid ",response.data);
			if(response.data.coords.length == 0)
			{
				this.setState({
					show: true,
					basicTitle:'No Route data available',
					basicType:"danger",
					loadshow:'show-n'
				});
			}
			else{
				var sdate='';
				var edate='';

				if(rownode.leg_start != '' && rownode.leg_start != undefined)
				{
					sdate=rownode.leg_start;
				}
				if(rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined)
				{
					edate=getDDMMYYYYHHMMSS(rownode.leg_end_eta)
				}
			this.setState({
					mapinfo:response.data,
					dealer:rownode.consignee_code,
					sliderRouteTranslate:"slider-translate-60p",
					defTransitCoords: rownode.consignee_coordinates,
					consignment_code:"Consignment : "+rownode.truck_no,
					maptruckno:rownode.truck_no,
					routeTruck:	{"truck_no":rownode.truck_no,"startTime":sdate,"endTime": edate},
					loadshow:'show-n',
					sidebarSubHeader:"Consignment Leg Information",
					overly:'show-m',
					rownode:rownode,
					leg_no:rownode.leg_no,
					tolls : response.data.tolls

			});

			// this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
				this.renderMap();
			
			} 
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
	}

    onShowLoadingDelayKpi = async (params) => {
		
		let eventLabel = googleAnalytics.page.action.loadingDelay;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowLoadingDelayKpi, params = ",params);
		//console.log(e);
		//console.log("Consign onShowLoadingDelayKpi ",params);
		//console.log(params.column.colId);
		if(params != 0)
		{
			var overspeedData=[];

			var hideonTNP = (this.state.deptcode == 'LOG-TNP')?true:false
			var showonTNP = (this.state.deptcode == 'LOG-TNP')?false:true
			await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							
							{ 
								headerName: "Gate In Time",
								field: "gate_in_time", 
								width:180,
								resizable: true,
								hide:hideonTNP,
								valueGetter:function(params){
									
									if(params.data.gate_in_time)
									{
										//return getDDMMYYYYHHMMSS(params.data.gate_in_time);
										return params.data.gate_in_time;
									}
									else
									{
										return "NA"
									}
									
								}
							},
							
							{ 
								headerName: "Gate Out Time",
								field: "gate_out_time", 
								width:180,
								resizable: true,
								hide:hideonTNP,
								valueGetter:function(params){
								//	console.log("IN gate_out_time valueGetter, params = ", params);
										
									if(params.data.gate_out_time)
									{
										//console.log("IN gate_out_time valueGetter, IF params.data.gate_out_time = ", params.data.gate_out_time);
										//return getDDMMYYYYHHMMSS(params.data.gate_out_time);
										return params.data.gate_out_time;
									}
									else
									{
										//console.log("IN gate_out_time valueGetter, ELSE params.data.gate_out_time = ", params.data.gate_out_time);
										return "NA"
									}
								}
							},

							
							{ 
								headerName: "Gate In Time",
								field: "gate_in_time", 
								width:180,
								resizable: true,
								hide:showonTNP,
								valueGetter:function(params){
									// console.log("IN gate_out_time valueGetter, params = ", params);
										
									if(params.data.gate_in_time)
									{
										// console.log("IN gate_out_time valueGetter, IF params.data.gate_out_time = ", params.data.gate_in_time);
										//return getDDMMYYYYHHMMSS(params.data.gate_out_time);
										return params.data.gate_in_time;
									}
									else
									{
										// console.log("IN gate_out_time valueGetter, ELSE params.data.gate_out_time = ", params.data.gate_in_time);
										return "NA"
									}
								}
							},

							
							{ 
								headerName: "Gate Out Time",
								field: "gate_out_time", 
								width:180,
								resizable: true,
								hide:showonTNP,
								valueGetter:function(params){
									// console.log("IN gate_out_time valueGetter, params = ", params);
										
									if(params.data.gate_out_time)
									{
										// console.log("IN gate_out_time valueGetter, IF params.data.gate_out_time = ", params.data.gate_out_time);
										//return getDDMMYYYYHHMMSS(params.data.gate_out_time);
										return params.data.gate_out_time;
									}
									else
									{
										// console.log("IN gate_out_time valueGetter, ELSE params.data.gate_out_time = ", params.data.gate_out_time);
										return "NA"
									}
								}
							},
							{
								headerName:"Loading Time",
								field:"loading_time",
								width:180,
								
							}
													
						],
						overlayNoRowsTemplate: 'No rows to show',
						
									
					},
					getDetailRowData: function(params) {
							// console.log("Step 4 ",params)
							if(hideonTNP != true)
							{
								// console.log("Inner here")
								if(params.data.gate_in_time != undefined)
								{
									var g1 = getDDMMYYYYHHMMSS(params.data.gate_in_time);
									//var g1 = (params.data.gate_in_time) ? moment.parseZone(params.data.gate_in_time).format('DD-MM-YYYY') : "";
									var gdate1 = new Date(params.data.gate_in_time);
									var fromDate = g1;
									var secondsgate1 = moment.parseZone(new Date(params.data.gate_in_time)).format("x")
								}
								else{
									
									var g1 = getDDMMYYYYHHMMSS(params.data.inside_fence);
									var gdate1 = new Date(params.data.inside_fence);
									var fromDate = g1;
									var secondsgate1 = moment.parseZone(new Date(params.data.inside_fence)).format("x")
								
								}
								//console.log("g1 ", g1)//console.log("Step 4 ",params)
								if(params.data.gate_out_time != undefined)
								{
									var g2 = getDDMMYYYYHHMMSS(params.data.gate_out_time);
									var gdate2 = new Date(params.data.gate_out_time);
									var toField = getDDMMYYYYHHMMSS(params.data.gate_out_time);
									//console.log("gatein ", gatein)
									//console.log("gateout ", gateout)
									//var total =0 ;
									var secondsgate2 = moment.parseZone(new Date(params.data.gate_out_time)).format("x")
								
								}
								else{
									var c = new Date();
									var todaydate = moment(new Date(c)).format("x");
									var g2 = getDDMMYYYYHHMMSS(todaydate);
									var gdate2 = new Date(todaydate);
									var toField = getDDMMYYYYHHMMSS(new Date());
									var secondsgate2 = todaydate
								
								}
							}
							else
							{
								var fromDate = getDDMMYYYYHHMMSS(params.data.inside_fence);
								var secondsgate1 = moment.parseZone(new Date(params.data.inside_fence)).format("x")
								var secondsgate2 = moment.parseZone(new Date(params.data.outside_fence)).format("x")
								var toField = getDDMMYYYYHHMMSS(params.data.outside_fence);
							}
						
						//console.log("secondsg1 ", secondsg1)
						//var g2 = getDDMMYYYY(params.data.gate_out_time);

						//console.log("g1 ", g2)
						// var s1 = g1.split("-");
						// var s2 = g2.split("-");
						
						
						// var gatein = gdate1.getTime();
						// var gateout = gdate2.getTime();
						var total = secondsgate2-secondsgate1;
						//console.log("total ", total)
						var diffMs = total;
						var diffDays = Math.ceil(diffMs / 86400000); // days
						var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
						var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000); 
						// console.log(diffDays+" "+diffHrs+" "+diffMins);
						let loadingTimeDifference = null;

						var dataarr = []
							
							dataarr.push({
								"gate_in_time": fromDate,
								"gate_out_time": toField,
								//"loading_time": diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
								"loading_time": secondsToString(diffMs/1000)
							})
							// console.log("dataarr ", dataarr)
							params.successCallback(dataarr);
					}
					
				}
			});
			
			//if(params.column.colId == 'loading_delay_exception')
			
			if(params.column.colDef.field == 'loading_delay_exception')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			
		}
		
    }
    
    onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
	   this.setState({ open: false });
	 };

    changeMovementArrayHandler = movementtype => {
		this.setState(
			{ movementtype },
			// () => console.log(`Movement Option selected:`, this.state.movementtype)
		);
		
	}
	changeModeHandler = modetype => {
        this.setState(
			{ modetype },
			// () => console.log(`Movement Option selected:`, this.state.modetype)
		);
    }
	selectMovementTypeOptionsItems(){
        let items = [];     
		items.push({value:"all",label:"All"});
		items.push({value:true,label:"Local Movement"});
		items.push({value:false,label:"Non Local Movement"});
		return items;
	}
	selectModeOptionsItems(){
        let items = [];     
		items.push({value:"all",label:"All"});
		items.push({value:"AIR COURIER",label:"AIR COURIER"});
		items.push({value:"SURFACE COURIER",label:"SURFACE COURIER"});
		items.push({value:"SEA EXPORT",label:"SEA EXPORT"});
		return items;
	}
    changeConsignerArrayHandler = consigner =>{
        this.setState(
	      { consigner },
	    //   () => console.log(`Consigner Option selected:`, this.state.consigner)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
	}
	changeConsigneeArrayHandler = consignee =>{
        this.setState(
	      { consignee },
	    //   () => console.log(`Consignee Option selected:`, this.state.consignee)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
	}
    selectConsignerOptionsItems(){
        let items = [];    
		var consigners=[];
        var keys = this.state.consigners; 
        
        // Object created 
        var obj = {}; 
        
        // Using loop to insert key 
        // value in Object 
        for(var i = 0; i < keys.length; i++){ 
            consigners.push({"code":keys[i],"value":keys[i]}); 
        }
        items.push({value:'all',label:'All'}); 
		consigners.forEach(item =>{
			items.push({value:item.code,label:item.value}); 
		});
        return items;
	}
    newFilter=(filterType,selOption)=>{
		// console.log(filterType,selOption)
		this.setState({ [filterType]: selOption });
	}
    onGetConsigners = async (event) => {
		
        if(this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN")
        {
            //var sDate = this.state.defaultsdate;
            var sDate = "";
        }
        else{
            var sDate = this.state.startDate;
        }
        if(this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN")
        {
            //var eDate = this.state.defaultedate;
            var eDate = "";
        }
        else{
            var eDate = this.state.endDate;
        }		

		var selectconsigners=[]
		if(this.state.consigner == null)
		{
			selectconsigners = [{"value":'all', "label":'All'}]
		}
		else{
			selectconsigners=this.state.consigner
		}

        var selectmodetype = [];
        if(this.state.modetype == null)
        {
            selectmodetype = [{"value":'all', "label":'All'}]
        }
        else
        {
            selectmodetype = [this.state.modetype];
        }
		
        var requestparams = {
            consigner: selectconsigners,
            startDate:sDate,
            endDate:eDate,
            modetype:selectmodetype
        }
		// console.log("requestparamsCheck", requestparams)
		this.setState({
            loadshow:"show-m",
            overly:"show-m",
        })
        requestparams.dataType = this.state.dataType;
        if(localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !="")
        {
            requestparams.transporter_code = JSON.parse(localStorage.getItem("transportercode"))
        }
        redirectURL.post("/consignments/getFilterPRTEwayBillCourierConsignments", requestparams).then(async(response) => {
            var gridData = response.data;
            console.log("gridData", gridData)
            $("#selectedFilter").val("");
            $("#inputTruckConsignment").val("");
            await this.setState({
                rowData: gridData,
                gridRowData: gridData,
                //countersjson: pagecounters,
                loadshow:"show-n",
                overly:"show-n",
            })            
            await this.loadCountersData();
        })
	}
    async onclickCounter(counterType)
    {
        this.setState({
            loadshow : "show-m",
            overly : "show-m",
        });
        var rowData = [];
        if(counterType == "1")
        {
            rowData = this.state.activeData;
        }
        else if(counterType == "2")
        {
            rowData = this.state.expiringtomorrowData;
        }
        else if(counterType == "3")
        {
            rowData = this.state.expiringtodayData;
        }
        else if(counterType == "4")
        {
            rowData = this.state.expiredData;
        }
        else
        {
            rowData = this.state.rowData
        }
        this.setState({
            gridRowData : rowData,
            loadshow : "show-n",
            overly : "show-n",
        });
        //this.gridApi.setRowData(rowData);
    }
    render() {
        const modalStyles  = {
            width:'500px !important',
            }
        const { open } = this.state;
        var maptransporter = '';
        try {
            if (this.state.rownode.transporter) {
                ///console.log("map rownode ",this.state.rownode.transporter[0])
                maptransporter = this.state.rownode.transporter[0].transporter_name;
            }
        }
        catch (e) {
            console.log(e)
        }
        var dataType = this.state.dataType;
        const columnwithDefs = [
            {
                headerName: "invoice",
                field: "invoice_wise_legs",
                colId: "invoice_wise_legs",
                width: 40,
                pinned: 'left',
                pivot: true,
                hide: false,
                cellRendererSelector: function (params) {
                    var rendComponent = {
                        component: 'EwayInvoice'
                    };
                    return rendComponent
                },
                filter: true, resizable: true,
            },
            {
                headerName: "Consignment Code",
                field: "consignment_code",
                colId: "consignment_code",
                width: 160,
                pinned: 'left',
                editable: false,
                filter: "agSetColumnFilter", resizable: true,
                //cellRenderer : "agGroupCellRenderer"
            },
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                colId: "transporter_name",
                width: 230,
                filter: "agSetColumnFilter",
                cellRenderer: '',
                resizable: true
            },
            {
                headerName: "Transporter Code",
                field: "transporter_code",
                colId: "transporter_code",
                width: 150,
                filter: "agSetColumnFilter",
                hide: false,
                cellRenderer: '',
                resizable: true
            },
            {
                headerName: "Source",
                field: "consigner_code",
                colId: "consigner_code",
                width: 130,
                filter: "agSetColumnFilter",
                cellRenderer: '',
                resizable: true
            },
            {
                headerName: "Destination",
                field: "consignee_code",
                colId: "consignee_code",
                width: 130,
                filter: "agSetColumnFilter",
                cellRenderer: '',
                resizable: true,
                hide: false
            },
            {
                headerName: "Consignee City",
                field: "consignee_city",
                colId: "consignee_city",
                width: 150,
                hide: false,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Consignee State",
                field: "consignee_state",
                colId: "consignee_state",
                width: 150,
                hide: false,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Vehicle Mode",
                field: "vehicle_mode",
                colId: "vehicle_mode",
                width: 150,
                hide: false,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Invoice Time",
                field: "invoice_time",
                colId: "invoice_time",
                width: 150,
                hide: false,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true,
                valueGetter: function (params) {
                    if (params.data.invoice_time != "" && params.data.invoice_time != undefined && params.data.invoice_time != null) {
                        return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
                    }
                    else {
                        return "";
                    }
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
            },
            {
                headerName: "Invoice No",
                field: "invoice_no",
                colId: "invoice_no",
                width: 150,
                hide: false,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true
            },
            {
                headerName: "MGPA Time",
                field: "mgpa_date_time",
                colId: "mgpa_date_time",
                width: 150,
                hide: false,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true,
                valueGetter: function (params) {
                    if (params.data.mgpa_date_time != "" && params.data.mgpa_date_time != undefined && params.data.mgpa_date_time != null) {
                        return getHyphenDDMMMYYYYHHMM(params.data.mgpa_date_time);
                    }
                    else {
                        return "";
                    }
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
            },
            {
                headerName: "E-Way Bill No",
                field: "eway_bill_no",
                colId: "eway_bill_no",
                width: 130,
                hide: false,
                pinnded: "right",
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true
            },
            {
                headerName: "E-Way Bill Date",
                field: "eway_bill_date",
                colId: "eway_bill_date",
                filter: true,
                width: 140,
                resizable: true,
                pinned: "right",
                comparator: dateComparator,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.eway_bill_date);
                },
                hide: false
            },
            {
                headerName: "E-Way Bill Expiry Date",
                field: "eway_bill_expiry_date",
                colId: "eway_bill_expiry_date",
                filter: true,
                width: 150,
                resizable: true,
                comparator: dateComparator,
                pinned: "right",
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.eway_bill_expiry_date);
                },
                hide: false
            },
            {
                headerName: "E-Way Extended Validity Time",
                field: "eway_bill_extended_validity_date",
                //colId: "eway_bill_extended_validity_date",
                filter: true,
                width: 150,
                resizable: true,
                pinned: "right",
                comparator: dateComparator,
                cellEditor: DateEditor,
                valueGetter:function(params){
                    if(params.data.eway_bill_extended_validity_date != '' && params.data.eway_bill_extended_validity_date != undefined)
                    {
                        if(params.data.eway_bill_extended_validity_date.includes("T") == true)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.eway_bill_extended_validity_date);
                        }
                        else{
                            return params.data.eway_bill_extended_validity_date;
                        }
                    }
                    else
                    {
                        return ""
                    }
                },
                editable: function(params){
                    if(dataType == 0)
                    {
                        if(params.data.eway_bill_extended_validity_date !=undefined && params.data.eway_bill_extended_validity_date !='')
                        {
                            var validityDate = params.data.eway_bill_extended_validity_date;
                            var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
                            var d2 = moment.parseZone(new Date(validityDate)).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
                            var ms = moment(d2,"YYYY-MM-DD HH:mm:ss").diff(moment(d1,"YYYY-MM-DD HH:mm:ss"));
                            var d = moment.duration(ms);
                            var difference = parseFloat(d.asDays());
                            if(difference > 0)
                            {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                        else
                        {
                            if(params.data.eway_bill_expiry_date !=undefined && params.data.eway_bill_expiry_date !='')
                            {
                                var validityDate = params.data.eway_bill_expiry_date;
                                var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
                                var d2 = moment.parseZone(new Date(validityDate)).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
                                var ms = moment(d2,"YYYY-MM-DD HH:mm:ss").diff(moment(d1,"YYYY-MM-DD HH:mm:ss"));
                                var d = moment.duration(ms);
                                var difference = parseFloat(d.asDays());
                                if(difference > 0)
                                {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                            else{
                                return false;
                            }
                        }
                    }
                    else{
                        return false;
                    }
                }
            },
            {
                headerName: "E-Way Bill Status",
                field: "eway_bill_status",
                colId: "eway_bill_status",
                width: 130,
                hide: false,
                pinned: "right",
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true,
                valueGetter: function(params){
                    if(params.data.eway_bill_extended_validity_date !=undefined && params.data.eway_bill_extended_validity_date !='')
                    {
                        var validityDate = params.data.eway_bill_extended_validity_date;
                        var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
                        var d2 = moment.parseZone(new Date(validityDate)).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
                        var ms = moment(d2,"YYYY-MM-DD HH:mm:ss").diff(moment(d1,"YYYY-MM-DD HH:mm:ss"));
                        var d = moment.duration(ms);
                        var difference = parseFloat(d.asDays());
                        if(difference > 0 && difference <= 1)
                        {
                            return "Expiring Today";
                        }
                        else if(difference > 1 && difference <= 2)
                        {
                            return "Expiring Tomorrow";
                        }
                        else if(difference <= 0)
                        {
                            return "Expired";
                        }
                        else{
                            return "Active";
                        }
                    }
                    else
                    {
                        if(params.data.eway_bill_expiry_date !=undefined && params.data.eway_bill_expiry_date !='')
                        {
                            var validityDate = params.data.eway_bill_expiry_date;
                            var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
                            var d2 = moment.parseZone(new Date(validityDate)).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
                            var ms = moment(d2,"YYYY-MM-DD HH:mm:ss").diff(moment(d1,"YYYY-MM-DD HH:mm:ss"));
                            var d = moment.duration(ms);
                            var difference = parseFloat(d.asDays());
                            if(difference > 0 && difference <= 1)
                            {
                                return "Expiring Today";
                            }
                            else if(difference > 1 && difference <= 2)
                            {
                                return "Expiring Tomorrow";
                            }
                            else if(difference <= 0)
                            {
                                return "Expired";
                            }
                            else{
                                return "Active";
                            }
                        }
                        else{
                            return "N/A";
                        }
                    }
                }
            },
        ]

        
        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <SweetAlert
                    show={this.state.show1}
                    type={this.state.basicType1}
                    title={this.state.basicTitle1}
                    onConfirm={this.closeAlert1}
                >
                </SweetAlert>

                <div className="row col-sm-12 f16" style={{ marginBottom: "10px" }}>
                    <ul className="col-sm-10 d-tabs">
                        <li onClick={this.onClickTab.bind(this, "active")} className={"lttabs " + (this.state.tabOneactive)}>
                            <button type="button" className="btn">Active</button>
                        </li>

                        <li onClick={this.onClickTab.bind(this, "delivered")} className={"lttabs " + (this.state.tabTwodelivered)}>
                            <button type="button" className="btn">Delivered</button>
                        </li>
                    </ul>
                    <div className="col-sm-2">
                        <button type="button" className="btn btn-danger" onClick={this.reloadPageData.bind(this)}>Reset All Filters</button>
                    </div>
                </div>
                {/* <div className="row" style={{ marginBottom: "1%" }}>
                    <div className="col-xl-12 col-lg-12">
                        <button onClick={this.reloadPageData} className="btn btn-info mr-5" style={{ float: "right" }}>Reset Filters</button>
                    </div>
                </div> */}
                <div className="row col-xl-12 col-lg-12">
                    <div className={"col-xl-4 col-lg-4"}>
                        <div className="card" style={{ minHeight: "100px" }}>
                            <div className="row card-body" style={{ padding: '10px' }}>
                                <div className="row col-xl-12 col-lg-12" style={{ marginLeft: "0px" }}>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label>Select Type :  </label>
                                        {/* <Select
                                            className="border-radius-0"
                                            isMulti={false}
                                            id="selectedFilter"
                                            style={{ borderRadius: "0px" }}
                                            options={[
                                                //{ label: "Truck", vaue: "Truck" },
                                                { label: "Consignments", value: "Consignments" },
                                                { label: "Invoice No", value: "invoice_no" },
                                                { label: "E-Way Bill No", value: "eway_bill_no" },
                                            ]} required /> */}
                                        <select name="selectedFilter" id="selectedFilter" class="form-control">
                                            <option value="">Select</option>
                                            <option value="Consignments">Consignments</option>
                                            <option value="Invoice No">Invoice No</option>
                                            <option value="E-Way Bill No">E-Way Bill No</option>
                                        </select>
                                    </div>

                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className="reason">Consignment / Invoice / E-Way Bill No</label>
                                        <input type="text" name="reason" autoComplete="off" class="form-control" id="inputTruckConsignment" />
                                    </div>
                                </div>
                                <div className="form-group col-xl-2 col-lg-2">
                                    <button type="button" style={{ margin: "0 auto" }} onClick={this.getTruckConsignments.bind(this)} className="btn btn-success ml-3">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"col-xl-8 col-lg-8"}>
                        <div className="card" style={{ minHeight: "100px" }}>
                            <div className="row card-body" style={{ padding: '10px' }}>
                                <div className="row col-xl-12 col-lg-12" style={{ marginLeft: "0px" }}>
                                    {/* <div className="form-group col-xl-2 col-lg-2 n-p-0">
                                        <label>Select Type: </label>
                                        <Select placeholder={"All"} closeMenuOnSelect={true} value={this.state.movementtype} className="border-radius-0" onChange={this.changeMovementArrayHandler.bind(this)} style={{borderRadius:"0px"}} options={this.selectMovementTypeOptionsItems()} required />
                                    </div> */}
                                    <div className="form-group col-xl-4 col-lg-4">
                                        <label>Select Consigner: </label>
                                        <Select placeholder={"All"} closeMenuOnSelect={false} value={this.state.consigner} isMulti="true" className="border-radius-0" onChange={this.changeConsignerArrayHandler.bind(this)} style={{borderRadius:"0px"}} options={this.selectConsignerOptionsItems()} required />
                                    </div>
                                    <div className="col-xl-3 col-lg-3 form-group">
                                        <label>From Date</label>
                                        {/* <input type="text" placeholder="From Date" id="from_date" value={this.state.startDate} autoComplete="off"  className="datetimepicker_date form-control"  required /> */}
                                        <Datetime 
                                        value={this.state.startDate} 
                                        disableCloseOnClickOutside={false} 
                                        closeOnSelect={true} 
                                        inputProps={{ placeholder: 'From', name: 'startDate', autoComplete:'off' }} 
                                        dateFormat="YYYY-MM-DD"  
                                        name="startDate" 
                                        onChange={this.handlerStartDateTime.bind(this)} />
                                    </div>

                                    <div className="col-xl-3 col-lg-3 form-group">
                                        <label>To Date</label>
                                        {/* <input type="text" placeholder="To Date" id="to_date" value={this.state.endDate} autoComplete="off"  className="datetimepicker_date form-control"  required /> */}
                                        <Datetime 
                                        value={this.state.endDate} 
                                        disableCloseOnClickOutside={false} 
                                        closeOnSelect={true} 
                                        inputProps={{ placeholder: 'To', name: 'endDate', autoComplete:'off'}} 
                                        dateFormat="YYYY-MM-DD" 
                                        onChange={this.handlerEndDateTime.bind(this)} />
                                    </div>

                                    <div className="form-group col-xl-3 col-lg-3">
                                        <label>Mode: </label>
                                        <Select placeholder={"All"} closeMenuOnSelect={true} value={this.state.modetype} className="border-radius-0" onChange={this.changeModeHandler.bind(this)} style={{borderRadius:"0px"}} options={this.selectModeOptionsItems()} required />
                                    </div>
                                </div>
                                {/* <div className="row col-xl-12 col-lg-12" style={{marginLeft:"0px", padding:"0px"}}>
                                    <div className="form-group col-xl-3 col-lg-3">
                                        <label>Mode: </label>
                                        <Select placeholder={"All"} closeMenuOnSelect={true} value={this.state.modetype} className="border-radius-0" onChange={this.changeModeHandler.bind(this)} style={{borderRadius:"0px"}} options={this.selectModeOptionsItems()} required />
                                    </div>
									<div className="form-group col-xl-3 col-lg-3">
										<label>Consignment City :  </label>
										<Select 
										className="border-radius-0"
										isMulti={false}
										id="selectedCity"
										style={{borderRadius:"0px"}}
										value={this.state.flCity}
										onChange={this.newFilter.bind(this,"flCity")}
										options={this.state.filterCities}
										/>
									</div>
									<div className="form-group col-xl-3 col-lg-3">
										<label>Consignment State :  </label>
										<Select 
										className="border-radius-0"
										isMulti={false}
										id="selectedState"
										style={{borderRadius:"0px"}}
										value={this.state.flState}
										onChange={this.newFilter.bind(this,"flState")}
										options={this.state.filterStates}
										/>
									</div>
									<div className="form-group col-xl-3 col-lg-3">
										<label>Transporter :  </label>
										<Select 
										className="border-radius-0"
										isMulti={false}
										id="selectedTransporter"
										style={{borderRadius:"0px"}} 
										value={this.state.flTransporter}
										onChange={this.newFilter.bind(this,"flTransporter")}
										options={this.state.filterTransporters}
										/>
									</div>
								</div> */}
                                <div className="form-group col-xl-2 col-lg-2">
                                    <button type="button" style={{ margin: "0 auto" }} className="btn btn-success ml-3" onClick={this.onGetConsigners}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-sm-12 beffect" style={{padding:"0"}}>
                        <div className="card">
                            <div className="card-body n-p-0">
                                <div className="crm-numbers pb-0">
                                    <div className="row col-xl-12 col-lg-12 col-sm-12">
                                        <div className="col cirlce-d">
                                            <span className="f15" style={{color:"#db3031"}}>All</span>
                                            <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{padding:"5",marginTop:"5%"}} onClick={this.onclickCounter.bind(this,"5")}>
                                                <h5 className={"f25 mt-20p"} style={{color:"#4099ff"}}>
                                                    <span className="counter">
                                                        <CountUp end={(this.state.rowData.length)?this.state.rowData.length:0}/>
                                                    </span>
                                                </h5> 
                                            </div>
                                        </div>
                                        <div className="col cirlce-d">
                                            <span className="f15" style={{color:"#db3031"}}>Active</span>
                                            <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{padding:"0",marginTop:"5%"}} onClick={this.onclickCounter.bind(this,"1")}>
                                                <h5 className={"f25 mt-20p"} style={{color:"#008000"}}>
                                                    <span className="counter">
                                                        <CountUp end={(this.state.activeCnt)?this.state.activeCnt:0}/>
                                                    </span>
                                                </h5> 
                                            </div>
                                        </div>
                                        <div className="col cirlce-d">
                                            <span className="f15" style={{color:"#db3031"}}>Expiring Tomorrow </span>
                                                <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{padding:"0",marginTop:"5%"}} onClick={this.onclickCounter.bind(this,"2")}>
                                                    <h5 className={"f25 mt-20p"} style={{color:"#FFFF00"}}>
                                                        <span className="counter">
                                                            <CountUp end={(this.state.expiringtomorrow)?this.state.expiringtomorrow:0}/>
                                                        </span>
                                                    </h5> 
                                                </div>
                                        </div>                                
                                        <div className="col cirlce-d">
                                            <span className="f15" style={{color:"#db3031"}}>Expiring Today</span>
                                            <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{padding:"0",marginTop:"5%"}} onClick={this.onclickCounter.bind(this,"3")}>
                                                <h5 className={"f25 mt-20p"} style={{color:"#FFA500"}}>
                                                    <span className="counter">
                                                        <CountUp end={(this.state.expiringtoday)?this.state.expiringtoday:0}/>
                                                    </span>
                                                </h5> 
                                            </div>
                                        </div>
                                        <div className="col cirlce-d">
                                            <span className="f15" style={{color:"#db3031"}}>Expired</span>
                                            <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{padding:"0",marginTop:"5%"}} onClick={this.onclickCounter.bind(this,"4")}>
                                                <h5 className={"f25 mt-20p"} style={{color:"#FF0000"}}>
                                                    <span className="counter">
                                                        <CountUp end={(this.state.expiredCnt)?this.state.expiredCnt:0}/>
                                                    </span>
                                                </h5> 
                                            </div>
                                        </div>
                                    </div>                                
                                </div>                                
                            </div>
                        </div>
                    </div>

                    <div className="card col-xl-12 col-lg-12">
                        <div className="card-header">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>E-Way Bill Monitoring - Courier</span>
                                {(this.state.dataType == 0) ?
                                <button className="btn btn-info" style={{ float: "right", marginRight: "10px" }} onClick={this.showBulkUpload.bind(this)}>Bulk Upload</button>
                                :""}
                            </h5>
                            {/* <div className={this.state.tabOneActiveData}>
                                    <button
                                        type="button"
                                        title="Bulk Upload"
                                        onClick={this.onBulkPriorityBtn}
                                        className={"float-right custom-btn white btn-danger mr-2 "}
                                        >
                                        Bulk Mark Critical
                                    </button>
                                </div> */}
                        </div>

                        <div className="card-body">

                            <div className={this.state.tabOneActiveData}                            >
                                <div id="myGrid" style={{ width: "100%", height: "478px" }} className={"ag-theme-balham"}>
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.gridRowData}
                                        enableCharts={false}
                                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        frameworkComponents={this.state.frameworkComponents}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        stopEditingWhenGridLosesFocus={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        masterDetail={true}
                                        // components={this.state.components}
                                        enableRangeSelection={true}
                                        onCellClicked={this.onCellClicked.bind(this)}
                                        onCellEditingStopped={this.onCellUpdateData}
                                    />
                                </div>
                            </div>

                            <div className={"ag-theme-balham" + this.state.tabTwoDeliveredData}>
                                {(this.state.tabTwoDeliveredData == "show-m") ?
                                    <div id="myGrid" style={{ width: "100%", height: "478px" }} className={"ag-theme-balham"}>
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.gridRowData}
                                            enableCharts={false}
                                            // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady1}
                                            onGridState={this.onGridState1}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            stopEditingWhenGridLosesFocus={true}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            masterDetail={true}
                                            // components={this.state.components}
                                            enableRangeSelection={true}
                                            onCellClicked={this.onCellClicked.bind(this)}
                                            onCellEditingStopped={this.onCellUpdateData}
                                        />
                                    </div>
                                    : ""}
                            </div>

                        </div>
                    </div>

                    <div className="form-theme col-xl-12 col-lg-12 row">

                        <div className={"slide-r " + (this.state.bulkslide)} style={{ overflow: "hidden" }}>
                            <h3 className="subH">Bulk Upload</h3>
                            <div className="slide-r-body" style={{ position: "relative" }}>
                                <div className="container-fluid">
                                    <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>

                                        <div className="form-group mt-20p">
                                            <label className="">Upload File</label>
                                            <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler} className="form-control" required />
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-success">Submit</button>
                                            <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                        </div>
                                        <p style={{ color: "red" }}>Note: Format should be same as given in sample template***</p>
                                    </form>
                                    <div className="form-group">
                                        <a className="btn btn-primary" href={require('../../assets/json/sample_bulk_e_way_bill_extended_validity_time.csv')} target="_blank">Sample Template</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                        </div>

                    </div>
                    <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                </div>
                
                <div className={"dataLoadpage " + (this.state.loadshow)}></div>
                    <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                        <div className="loader-box">
                            <div className="loader-box">
                                <div className="rotate dashed colored">
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        )
    }
}

$(document).on("input", ".num-cls", function () {
    this.value = this.value.replace(/\D/g, '');
});



function loadDateTimeScript() {
    // alert("timeDate");
    // $("#gate_in_time").onBlur(function(){
    //     alert("testttt");
    // });
    $('.datetimepicker_mask').datetimepicker({
        //mask:'39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });
    $('.datetimepicker_date').datetimepicker({
        //mask:'39-19-9999',
        format: 'd-m-Y',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        timepicker: false,
        changeMonth: true,
		changeYear: true,
		minDate:"0"
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    var inpVal = this.eInput.value.split(' ');
    return inpVal[0]+" 23:59";
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}

function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    //console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}


function secondsToString(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

function removeSplChars(inputString) {
    // console.log(inputString);
    if (inputString != "" && inputString != null && inputString != undefined) {
        return inputString.replace(/[^\w]/g, "");
    }
    else {
        return "";
    }

}

function getDatePicker() {
    function Timepicker() { }
    Timepicker.prototype.init = function (params) {
        this.eInput = document.createElement("input");
        this.eInput.value = params.value;
        $(this.eInput).datetimepicker({
            datepicker: false,
            format: 'H:i'
        });
    };
    Timepicker.prototype.getGui = function () {
        return this.eInput;
    };
    Timepicker.prototype.afterGuiAttached = function () {
        this.eInput.focus();
        this.eInput.select();
    };
    Timepicker.prototype.getValue = function () {
        return this.eInput.value;
    };
    Timepicker.prototype.destroy = function () { };
    Timepicker.prototype.isPopup = function () {
        return false;
    };
    return Timepicker;
}
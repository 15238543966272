var moment = require('moment');
//let now = moment();
//console.log(moment.parseZone("2020-02-17T10:51:55.000Z").format());
//console.log(moment.parseZone("2020-02-17T10:51:55.000Z").format("DD-MM-YYYY hh:mm:ss"))
var getDDMMYYYY = function(date_string) {
    if(date_string == '' || date_string == undefined)
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('DD/MM/YYYY');
    }
}
exports.getDDMMYYYY = getDDMMYYYY;

var getDDMMMYYYY = function(date_string) {
    if(date_string == '' || date_string == undefined)
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('DD/MMM/YYYY');
    }
}
exports.getDDMMMYYYY = getDDMMMYYYY;


var getDDMMYYYYHHMM = function(date_string) {
    if(date_string == '' || date_string == undefined)
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('DD/MM/YYYY hh:mm');
    }
}
exports.getDDMMYYYYHHMM = getDDMMYYYYHHMM;


var getDDMMYYYYHHMMSS = function(date_string) {
    //console.log("date_string ", date_string);
    if(date_string == '' || date_string == undefined)
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('DD/MM/YYYY HH:mm:ss');
    }
    
}
exports.getDDMMYYYYHHMMSS = getDDMMYYYYHHMMSS;


var getYYYYMMDD = function(date_string) {
    if(date_string == '' || date_string == undefined)
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('YYYY/MM/DD');
    }
}
exports.getYYYYMMDD = getYYYYMMDD;

var getYYYYMMDDHHMMSS = function(date_string) {
    if(date_string == '' || date_string == undefined)
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('YYYY/MM/DD HH:mm:ss');
    }
}
exports.getYYYYMMDDHHMMSS = getYYYYMMDDHHMMSS;

var getHyphenYYYYMMDDHHMMSS = function(date_string) {
    if(date_string == '' || date_string == undefined)
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('YYYY-MM-DD HH:mm:ss');
    }
}
exports.getHyphenYYYYMMDDHHMMSS = getHyphenYYYYMMDDHHMMSS;

var getHyphenYYYYMMDDHHMM = function(date_string) {
    if(date_string == '' || date_string == undefined)
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('YYYY-MM-DD HH:mm');
    }
}
exports.getHyphenYYYYMMDDHHMM = getHyphenYYYYMMDDHHMM;


var getHyphenYYYYMMDD = function(date_string) {
    if(date_string == '' || date_string == undefined)
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('YYYY-MM-DD');
    }
}
exports.getHyphenYYYYMMDD = getHyphenYYYYMMDD;

var getHyphenDDMMYYYYHHMMSS = function(date_string) {
    //console.log("date_string ", date_string);
    if(date_string == '' || date_string == undefined)
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('DD-MM-YYYY HH:mm:ss');
    }
    
}
exports.getHyphenDDMMYYYYHHMMSS = getHyphenDDMMYYYYHHMMSS;

var getHyphenDDMMMYYYYHHMM = function(date_string) {
    //console.log("date_string ", date_string);
    if(date_string == '' || date_string == undefined)
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('DD-MMM-YYYY HH:mm');
    }
    
}
exports.getHyphenDDMMMYYYYHHMM = getHyphenDDMMMYYYYHHMM;




var getHyphenDDMMYYYY = function(date_string) {
    if(date_string == '' || date_string == undefined)
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('DD-MM-YYYY');
    }
}
exports.getHyphenDDMMYYYY = getHyphenDDMMYYYY;

var getHyphenDDMMMYYYY = function(date_string) {
    if(date_string == '' || date_string == undefined)
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('DD-MMM-YYYY');
    }
}
exports.getHyphenDDMMMYYYY = getHyphenDDMMMYYYY;


var getDDMMMYYYYDefault = function(date_string) {
    //console.log("date_string ", date_string);
    //var d = date_string.split(" ")

    if(date_string == '1970-01-01' || date_string == undefined || date_string == "")
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('DD-MMM-YYYY');
    }
    
}
exports.getDDMMMYYYYDefault = getDDMMMYYYYDefault;


// var getDDMMMYYYYHHMMDefault = function(date_string) {
//     //var d = date_string.split(" ")

//    if(date_string == '' || date_string == undefined)
//     {
//         return '';
//     }
//     else{
//         return moment.parseZone(date_string).format('DD-MMM-YYYY HH:mm');
//     }
    
// }
// exports.getHyphenDDMMMYYYYHHMM = getDDMMMYYYYHHMMDefault;

var getDDMMMYYYYHHMMDefault = function(date_string) {
    //console.log("date_string ", date_string);
    if(date_string == '' || date_string == undefined || date_string == "1970-01-01T00:00:00.000Z")
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('DD-MMM-YYYY HH:mm');
    }
    
}
exports.getDDMMMYYYYHHMMDefault = getDDMMMYYYYHHMMDefault;

var convertSecondsToHHMM = function(seconds) {
    if(seconds == "0" || seconds == "")
    {
        return "";
    }
    else{
        seconds = Number(seconds);
        var hh = Math.floor((seconds / 3600));
        var hrr = Math.floor(seconds - (hh*3600));
        var mm = Math.floor(hrr/60)
        if(mm < 10)
        {
            var mins = "0"+mm;
        }
        else
        {
            var mins = mm;
        }

        if(hh < 10)
        {
            var hrs = "0"+hh;
        }
        else
        {
            var hrs = hh;
        }
        return hrs+":"+mins;
    }
}

exports.convertSecondsToHHMM = convertSecondsToHHMM;





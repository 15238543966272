
import React, { Component, useDebugValue } from "react";

import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from "react-bootstrap-sweetalert";
import "react-datetime/css/react-datetime.css";
import TvpManagementDashboardPieChart from "./tvpmanagementdashboardpiechart"
var redirectURL = require("../redirectURL");


export default class TVPManagementDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showform: "show-m",
            loadshow: "show-n",
            defaultradius: 0,
            coordinates: [],
            alert: null,
            show: false,
            basicTitle: "",
            basicType: "default",
            contentString: "testing",
            mwidth: "col-xl-12 col-lg-12",
            dept_code: "",
            defaultColDef: {


                sortable: true,
                filter: true,
                //editable: true,
                resizable: true,
            },
            rowData: [],
            overall_contribution: [],
            context: { componentParent: this },
            loadshow: "show-n",
            overly: "show-n",
            paginationPageSize: 50,
            stockData: [],
            nth_day_counters: {},
            // fund_data: {},
            currentDateTime: this.getCurrentFormattedDateTime(),
            fleetData: {},
            isModalOpen: false,
            selectedRowData: {},
            sidebartitle: "",
            sliderRouteTranslate: '',
            selectedSlideInTable: "",
            regionWiseData: [],
            fundedStockTableData: [],
            agedStockTableData: [],
            regionStockData: [],
            regionTransitData: [],
            regionTotalData: [],
            mtdTableData: [],
        };

    }

    componentDidMount() {
        try {
            this.fetchData();
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    fetchData = async () => {
        try {
            this.setState({
                loadshow: "show-m",
                overly: "show-m",
            });

            // Run all API calls concurrently using Promise.all
            await Promise.all([
                this.getMtdData(),
                this.getRegionWiseData(),
                this.getTVPFundedStockDashboardData(),
                this.getTVPAgedStockDashboardData(),
                this.getTVPStockDashboardData(),
                this.getTwentyKmInsideVicinityCount()
            ]);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            this.setState({
                loadshow: "show-n",
                overly: "show-n",
            })
        }
    };

    getTVPStockDashboardData = async () => {
        await redirectURL
                .post("/consignments/getTVPStockDashboarddata")
                .then((response) => {
                    console.log(response.data, 'data response', response.data)
                    var stock_and_mtd_status = response.data.data.stock_and_mtd_status;
                    var nth_day_counters = response.data.data.nth_day_counters
                    // var fund_data = response.data.data.fund_data
                    var overall = response.data.data.overall_contribution
                    let overall_contribution = overall.map(item => ({
                        name: item.plant,
                        y: item.percentage,
                        value: item.count
                    }));

                    overall_contribution.push({
                        name: "Kharkhoda",
                        y: 0,
                        value: 0
                    })

                    // console.log(stock_and_mtd_status, 'stock_and_mtd_status', nth_day_counters, 'nth_day_counters', overall_contribution, 'response.data.data.overall_contribution')
                    this.setState({
                        stockData: stock_and_mtd_status,
                        nth_day_counters: nth_day_counters,
                        // fund_data: fund_data,
                        overall_contribution: overall_contribution
                    })
                })
            this.timerId = setInterval(() => {
                this.setState({ currentDateTime: this.getCurrentFormattedDateTime() });
            }, 1800000); // 1800000 milliseconds = 30 minutes
    }

    getTwentyKmInsideVicinityCount = async () => {
        await redirectURL
            .post("/consignments/getfiftykminsidevicinitycount")
            .then((response) => {
                console.log(response.data.data, 'total data for vicinity code')
                this.setState({
                    fleetData: response.data.data
                })
            })
    }

    getMtdData = async () => {
        await redirectURL
            .post("/dashboard/getMtdData")
            .then((response) => {
                // console.log("Response Data for MTD: ", response.data);
                const parsedData = JSON.parse(response.data.data);
                if (parsedData.stock_mtd_status) {
                    const mtdTableData = parsedData.stock_mtd_status;
                    this.setState({
                        mtdTableData: mtdTableData,
                    })
                }
            })
    }

    getRegionWiseData = async () => {
        await redirectURL
            .post("/dashboard/getRegionWiseData")
            .then((response) => {
                // console.log("Response Data for MTD: ", response.data);
                const parsedData = JSON.parse(response.data.data);
                if (parsedData.result_list) {
                    const regionWiseData = parsedData.result_list;
                    const regionStockData = parsedData.stock_data;
                    const regionTransitData = parsedData.transit_data;
                    const regionTotalData = parsedData.total_count;

                    // console.log("Stock Data: ", regionStockData);
                    // console.log("Transit Data: ", regionTransitData);
                    // console.log("Total Data: ", regionTotalData);
                    this.setState({
                        regionWiseData: regionWiseData,
                        regionStockData: regionStockData,
                        regionTransitData: regionTransitData,
                        regionTotalData: regionTotalData,
                    })
                }
            })
    }

    getTVPFundedStockDashboardData = async () => {
        await redirectURL
            .post("/dashboard/getTVPFundedStockDashboardData")
            .then((response) => {
                // console.log("Response Data for MTD: ", response.data);
                const parsedData = JSON.parse(response.data.data);
                if (parsedData.region_wise_count) {
                    const fundedStockData = parsedData.region_wise_count;
                    this.setState({
                        fundedStockTableData: fundedStockData,
                    })
                }
            })
    }

    getTVPAgedStockDashboardData = async () => {
        await redirectURL
            .post("/dashboard/getTVPAgedStockDashboardData")
            .then((response) => {
                // console.log("Response Data for MTD: ", response.data);
                const parsedData = JSON.parse(response.data.data);
                if (parsedData.region_wise_data) {
                    const agedStockData = parsedData.region_wise_data;
                    this.setState({
                        agedStockTableData: agedStockData,
                    })
                }
            })
    }

    getCurrentFormattedDateTime() {
        const now = new Date();
        return now.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        }) + ' ' + now.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit'
        });
    }

    componentWillUnmount() {
        clearInterval(this.timerId);
    }


    getUtilizationColor(utilization) {
        if (utilization < 60) return '#20D32E';  // Light green
        if (utilization >= 60 && utilization < 90) return '#FF9E04';  // Orange
        if (utilization >= 90) return '#FF0505';  // Tomato red
        return '#FFFFFF'; // Default color set to white
    }

    renderTableData() {
        const cellStyle = {
            padding: '8px',
            border: '1px solid #000',
        };

        const cellStyle2 = {
            padding: '8px',
            border: '1px solid #000',
            fontWeight: 'bold',
        };

        const clickableCellStyle = {
            padding: '8px',
            border: '1px solid #000',
            backgroundColor: 'lightblue',
            cursor: 'pointer'
        };

        const cellStyle1 = {
            padding: '8px',
            border: '1px solid #000',
            width: '200px'
        };

        let rowColor = "";
        let progressBarStyle = progressBarStyle = {
            width: `${Math.min(100, Math.max(0, 0))}%`,
            backgroundColor: this.getUtilizationColor(0),
            height: '8px',
            borderRadius: '20px'
        };

        const rows = this.state.stockData.map((stock, index) => {
            rowColor = index % 2 === 0 ? 'white' : '#F5F5F5';
            const { vehicle_location, capacity, physical_count, utilization, transit_count, funded_stock_count, pending_for_exit_count, factory_invoice_monthly, tvp_receipt_monthly, tvp_exit_monthly, aging_count, unloading_hours } = stock;
            const progressBarStyle = {
                width: `${Math.min(100, Math.max(0, utilization))}%`,
                backgroundColor: this.getUtilizationColor(utilization),
                height: '8px',
                borderRadius: '20px'
            };

            return (
                <tr key={index} style={{ backgroundColor: rowColor }}>
                    <td style={cellStyle2}>{vehicle_location}</td>
                    <td style={cellStyle}>{safeToLocalString(capacity)}</td>
                    <td style={cellStyle}>{safeToLocalString(physical_count)}</td>
                    <td style={cellStyle1}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                        }}>
                            <div style={{
                                width: '100%',
                                backgroundColor: '#000',
                                borderRadius: '5px',
                                marginRight: '8px',
                            }}>
                                <div style={progressBarStyle}></div>
                            </div>
                            <span>{utilization != null ? `${parseFloat(utilization).toFixed(1)}%` : '0%'}</span>
                        </div>
                    </td>
                    <td style={cellStyle}>{safeToLocalString(transit_count)}</td>
                    <td style={cellStyle}>{safeToLocalString(unloading_hours)}</td>
                    <td style={clickableCellStyle} onClick={() => this.handleTdClick("funded_physical_stocks", stock, index)}>{safeToLocalString(funded_stock_count)}</td>
                    <td style={cellStyle}>{safeToLocalString(pending_for_exit_count)}</td>
                    <td style={clickableCellStyle} onClick={() => this.handleTdClick("aged_stock", stock, index)}>{safeToLocalString(aging_count)}</td>
                    <td style={cellStyle}>{safeToLocalString(factory_invoice_monthly)}</td>
                    <td style={cellStyle}>{safeToLocalString(tvp_receipt_monthly)}</td>
                    <td style={cellStyle}>{safeToLocalString(tvp_exit_monthly)}</td>
                </tr>
            );
        });

        return rows;
    }

    // Function to render table data using map
    renderSlideInTableData() {
        const { selectedSlideInTable, rowData } = this.state;
        const cellStyle = {
            padding: '8px',
            border: '1px solid #000',
            textAlign: 'center'
        };
        const cellStyle2 = {
            padding: '8px',
            border: '1px solid #000',
            fontWeight: 'bold',
            textAlign: 'center'
        };

        if (selectedSlideInTable === 'mtd_status') {
            let totalReceiptRail = 0;
            let totalReceiptRoad = 0;
            let totalFundReceiptCount = 0;
            let totalPendingForExit = 0;
            let totalTvpExitCount = 0;
            // let rowColor = "";

            let currentRowIndex = 0;

            const rows = rowData.map((item, index) => {
                const rowColor = currentRowIndex % 2 === 0 ? 'white' : '#F5F5F5';

                currentRowIndex++;  // Increment row index for the next row

                const {
                    vehicle_location,
                    receipt_rail,
                    receipt_road,
                    fund_receipt_count,
                    pending_for_exit,
                    tvp_exit_count,
                } = item;

                // Accumulate totals
                totalReceiptRail += Number(receipt_rail);
                totalReceiptRoad += Number(receipt_road);
                totalFundReceiptCount += Number(fund_receipt_count);
                totalPendingForExit += Number(pending_for_exit);
                totalTvpExitCount += Number(tvp_exit_count);

                return (
                    <tr key={index} style={{ backgroundColor: rowColor }}>
                        <td style={cellStyle2}>{vehicle_location}</td>
                        <td style={cellStyle}>{safeToLocalString(receipt_rail)}</td>
                        <td style={cellStyle}>{safeToLocalString(receipt_road)}</td>
                        <td style={cellStyle}>{safeToLocalString(fund_receipt_count)}</td>
                        <td style={cellStyle}>{safeToLocalString(pending_for_exit)}</td>
                        <td style={cellStyle}>{safeToLocalString(tvp_exit_count)}</td>
                    </tr>
                );
            });

            // Add the Kharkhoda row before total
            const kharkhodaRowColor = currentRowIndex % 2 === 0 ? 'white' : '#F5F5F5';
            rows.push(
                <tr key={"Kharkhoda"} style={{ backgroundColor: kharkhodaRowColor }}>
                    <td style={cellStyle2}>TVP-Kharkhoda</td>
                    <td style={cellStyle}>{0}</td>
                    <td style={cellStyle}>{0}</td>
                    <td style={cellStyle}>{0}</td>
                    <td style={cellStyle}>{0}</td>
                    <td style={cellStyle}>{0}</td>
                </tr>
            );

            currentRowIndex++;

            // Add the total row at the end
            const totalRowColor = currentRowIndex % 2 === 0 ? 'white' : '#F5F5F5';
            rows.push(
                <tr key="total" style={{ backgroundColor: totalRowColor }}>
                    <td style={cellStyle2}>Total</td>
                    <td style={cellStyle}>{safeToLocalString(totalReceiptRail)}</td>
                    <td style={cellStyle}>{safeToLocalString(totalReceiptRoad)}</td>
                    <td style={cellStyle}>{safeToLocalString(totalFundReceiptCount)}</td>
                    <td style={cellStyle}>{safeToLocalString(totalPendingForExit)}</td>
                    <td style={cellStyle}>{safeToLocalString(totalTvpExitCount)}</td>
                </tr>
            );

            return rows;
        } else if (selectedSlideInTable === 'funded_physical_stocks') {
            let totalLessThan24Hrs = 0;
            let totalLessThan48Hrs = 0;
            let totalGreaterThan48Hrs = 0;
            // let rowColor = "";

            let currentRowIndex = 0;

            const rows = rowData.map((item, index) => {
                // rowColor = index % 2 === 0 ? 'white' : '#F5F5F5';
                const rowColor = currentRowIndex % 2 === 0 ? 'white' : '#F5F5F5';  // Set row color based on currentRowIndex

                currentRowIndex++;  // Increment row index for the next row

                const { location, less_than_24_Hrs, lessthan_48_Hrs, greater_than_48_Hrs } = item;

                // Accumulate totals
                totalLessThan24Hrs += Number(less_than_24_Hrs) || 0;
                totalLessThan48Hrs += Number(lessthan_48_Hrs) || 0;
                totalGreaterThan48Hrs += Number(greater_than_48_Hrs) || 0;

                return (
                    <tr key={index} style={{ backgroundColor: rowColor }}>
                        <td style={cellStyle2}>{location || ""}</td>
                        <td style={cellStyle}>{safeToLocalString(less_than_24_Hrs)}</td>
                        <td style={cellStyle}>{safeToLocalString(lessthan_48_Hrs)}</td>
                        <td style={cellStyle}>{safeToLocalString(greater_than_48_Hrs)}</td>
                    </tr>
                );
            });

            // Add the Kharkhoda row before total
            const kharkhodaRowColor = currentRowIndex % 2 === 0 ? 'white' : '#F5F5F5';
            rows.push(
                <tr key={"Kharkhoda"} style={{ backgroundColor: kharkhodaRowColor }}>
                    <td style={cellStyle2}>TVP-Kharkhoda</td>
                    <td style={cellStyle}>{0}</td>
                    <td style={cellStyle}>{0}</td>
                    <td style={cellStyle}>{0}</td>
                </tr>
            );

            currentRowIndex++;  // Increment row index for the next row (Kharkhoda row)

            // Add the total row at the end
            const totalRowColor = currentRowIndex % 2 === 0 ? 'white' : '#F5F5F5';
            rows.push(
                <tr key="total" style={{ backgroundColor: totalRowColor }}>
                    <td style={cellStyle2}>Total</td>
                    <td style={cellStyle}>{safeToLocalString(totalLessThan24Hrs)}</td>
                    <td style={cellStyle}>{safeToLocalString(totalLessThan48Hrs)}</td>
                    <td style={cellStyle}>{safeToLocalString(totalGreaterThan48Hrs)}</td>
                </tr>
            );

            return rows;
        } else if (selectedSlideInTable === 'aged_stock') {
            let totalUpTo5Days = 0;
            let totalUpTo20Days = 0;
            let totalUpTo30Days = 0;
            let totalUpTo58Days = 0;
            let totalVintage58Days = 0;
            // let rowColor = "";

            let currentRowIndex = 0;
            
            const rows = rowData.map((item, index) => {
                // rowColor = index % 2 === 0 ? 'white' : '#F5F5F5';
                const rowColor = currentRowIndex % 2 === 0 ? 'white' : '#F5F5F5';  // Set row color based on currentRowIndex

                currentRowIndex++;  // Increment row index for the next row

                const { location, up_to_5_days, up_to_20_days, up_to_30_days, up_to_58_days, vintage_58_days } = item;

                // Accumulate totals
                totalUpTo5Days += Number(up_to_5_days) || 0;
                totalUpTo20Days += Number(up_to_20_days) || 0;
                totalUpTo30Days += Number(up_to_30_days) || 0;
                totalUpTo58Days += Number(up_to_58_days) || 0;
                totalVintage58Days += Number(vintage_58_days) || 0;

                return (
                    <tr key={index} style={{ backgroundColor: rowColor }}>
                        <td style={cellStyle2}>{location || ""}</td>
                        <td style={cellStyle}>{safeToLocalString(up_to_5_days)}</td>
                        <td style={cellStyle}>{safeToLocalString(up_to_20_days)}</td>
                        <td style={cellStyle}>{safeToLocalString(up_to_30_days)}</td>
                        <td style={cellStyle}>{safeToLocalString(up_to_58_days)}</td>
                        <td style={cellStyle}>{safeToLocalString(vintage_58_days)}</td>
                    </tr>
                );
            });

            // Add the Kharkhoda row before total
            const kharkhodaRowColor = currentRowIndex % 2 === 0 ? 'white' : '#F5F5F5';
            rows.push(
                <tr key={"Kharkhoda"} style={{ backgroundColor: kharkhodaRowColor }}>
                    <td style={cellStyle2}>TVP-Kharkhoda</td>
                    <td style={cellStyle}>{0}</td>
                    <td style={cellStyle}>{0}</td>
                    <td style={cellStyle}>{0}</td>
                    <td style={cellStyle}>{0}</td>
                    <td style={cellStyle}>{0}</td>
                </tr>
            );

            currentRowIndex++;

            // Add the total row at the end
            const totalRowColor = currentRowIndex % 2 === 0 ? 'white' : '#F5F5F5';
            rows.push(
                <tr key="total" style={{ backgroundColor: totalRowColor }}>
                    <td style={cellStyle2}>Total</td>
                    <td style={cellStyle}>{safeToLocalString(totalUpTo5Days)}</td>
                    <td style={cellStyle}>{safeToLocalString(totalUpTo20Days)}</td>
                    <td style={cellStyle}>{safeToLocalString(totalUpTo30Days)}</td>
                    <td style={cellStyle}>{safeToLocalString(totalUpTo58Days)}</td>
                    <td style={cellStyle}>{safeToLocalString(totalVintage58Days)}</td>
                </tr>
            );

            return rows;
        } else {
            return null;
        }
    }

    // Function to render the dynamic table inside the slider based on the table type

    renderSlideInTable = () => {
        const { selectedSlideInTable } = this.state;

        const headerStyle = {
            backgroundColor: '#373db9',
            color: 'white',
            padding: '8px',
            border: '1px solid #000',
            textAlign: 'center',
        };

        const headerStyle2 = {
            backgroundColor: '#373db9',
            color: 'white',
            padding: '8px',
            border: '1px solid #000',
            textAlign: 'center',
            fontWeight: "100"
        };

        switch (selectedSlideInTable) {
            case 'funded_physical_stocks':
                return (
                    <table style={{ width: '100%', border: "1px solid #000000" }}>
                        <thead>
                            <tr>
                                <th rowSpan={2} style={headerStyle}>
                                    Stock Yard Location
                                </th>
                                <th colSpan={3} style={headerStyle}>
                                    Funded Physical Stock
                                </th>
                            </tr>
                            <tr>
                                <th style={headerStyle2}>&lt;= 24 Hrs</th>
                                <th style={headerStyle2}>&lt;= 48 Hrs</th>
                                <th style={headerStyle2}>&gt;= 48 Hrs</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderSlideInTableData()}
                        </tbody>
                    </table>
                );

            case 'aged_stock':
                return (
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th rowSpan={2} style={headerStyle}>
                                    Stock Yard Location
                                </th>
                                <th colSpan={5} style={headerStyle}>
                                    Aged Stock
                                </th>
                            </tr>
                            <tr>
                                <th style={headerStyle2}>Up to 5 Days</th>
                                <th style={headerStyle2}>6 - 20 Days</th>
                                <th style={headerStyle2}>21 - 30 Days</th>
                                <th style={headerStyle2}>31 - 58 Days</th>
                                <th style={headerStyle2}>Vintage &gt; 58 Days</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderSlideInTableData()}
                        </tbody>
                    </table>
                );

            case 'mtd_status':
                return (
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th rowSpan={2} style={headerStyle}>
                                    Stock Yard Location
                                </th>
                                <th colSpan={5} style={headerStyle}>
                                    MTD Data
                                </th>
                            </tr>
                            <tr>
                                <th style={headerStyle2}>Receipt Rail</th>
                                <th style={headerStyle2}>Receipt Road</th>
                                <th style={headerStyle2}>Fund Receipt</th>
                                <th style={headerStyle2}>Pending for Exit</th>
                                <th style={headerStyle2}>Exit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderSlideInTableData()}
                        </tbody>
                    </table>
                );

            case 'region_wise_stock':
                return (
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '40px', justifyContent: 'center', textAlign: "center" }}>
                        {this.renderRegionWiseTables()}
                    </div>
                );

            default:
                return <p>No table selected</p>;
        }
    };

    renderRegionWiseTables = () => {
        const { regionWiseData: regionData } = this.state;

        const headerStyle = {
            backgroundColor: '#373db9',
            color: 'white',
            padding: '8px',
            border: '1px solid #000',
            textAlign: 'center',
            fontWeight: 'bold',
        };

        const headerStyleYellow = {
            // backgroundColor: 'lightyellow',
            color: 'black',
            padding: '8px',
            border: '1px solid #000',
            textAlign: 'center',
            fontWeight: 'bold',
        };

        const headerStyleGreen = {
            // backgroundColor: '#e2f0d9',
            color: 'black',
            padding: '8px',
            border: '1px solid #000',
            textAlign: 'center',
            fontWeight: 'bold',
        };

        const cellStyle = {
            padding: '8px',
            border: '1px solid #000',
        };

        return regionData.map((region, index) => {
            const totalRows = Math.max(region["arena_channel"].length, region["nexa_channel"].length); // Calculate the total number of rows for this region

            return (
                <table key={index} style={{ width: 'calc(45% - 20px)', marginBottom: '20px' }}>
                    <thead>
                        <tr>
                            <th rowSpan={2} style={headerStyle}>Stock Yard Location</th>
                            <th colSpan={2} style={headerStyle}>Arena Channel</th>
                            <th colSpan={2} style={headerStyle}>Nexa Channel</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Loop through Arena and Nexa Channels */}
                        {this.renderChannelRows(region, totalRows)}
                        {/* Total Row */}
                        <tr>
                            {/* <td style={cellStyle}></td> */}
                            <td style={headerStyleYellow}>Total</td> {/* Total text under the Arena Stage column */}
                            <td style={cellStyle}>{safeToLocalString(region["total_arena"])}</td> {/* Total sum under the Arena Count column */}
                            <td style={headerStyleYellow}>Total</td> {/* Total text under the Nexa Stage column */}
                            <td style={cellStyle}>{safeToLocalString(region["total_nexa"])}</td> {/* Total sum under the Nexa Count column */}
                        </tr>
                    </tbody>
                </table>
            );
        });
    };

    renderChannelRows = (region, totalRows) => {
        const cellStyle = {
            padding: '8px',
            border: '1px solid #000',
        };

        const headerStyle = {
            backgroundColor: 'white',
            color: 'black',
            padding: '8px',
            border: '1px solid #000',
            textAlign: 'center',
            fontWeight: 'bold',
        };

        const headerStyle2 = {
            // backgroundColor: 'lightyellow',
            color: 'black',
            padding: '8px',
            border: '1px solid #000',
            textAlign: 'center',
            fontWeight: 'bold',
        };

        const headerStyleGreen = {
            // backgroundColor: '#e2f0d9',
            color: 'black',
            padding: '8px',
            border: '1px solid #000',
            textAlign: 'center',
            fontWeight: 'bold',
        };

        let currentRowIndex = 0;
        
        const rows = [];
        const maxLength = totalRows;
        
        for (let i = 0; i < maxLength; i++) {
            const rowColor = currentRowIndex % 2 === 0 ? 'white' : '#F5F5F5';
            const arena = region["arena_channel"][i] || {};
            const nexa = region["nexa_channel"][i] || {};
            const arenaKey = Object.keys(arena)[0] || '';
            const arenaValue = arena[arenaKey] || '';
            const nexaKey = Object.keys(nexa)[0] || '';
            const nexaValue = nexa[nexaKey] || '';

            rows.push(
                <tr key={i} style={{ backgroundColor: rowColor }}>
                    {/* Only add the vehicle_location in the first row, and apply rowSpan */}
                    {i === 0 && (
                        <td rowSpan={maxLength + 1} style={headerStyleGreen}>
                            {region.vehicle_location}
                        </td>
                    )}
                    <td style={headerStyle2}>{arenaKey}</td>
                    <td style={cellStyle}>{safeToLocalString(arenaValue)}</td>
                    <td style={headerStyle2}>{nexaKey}</td>
                    <td style={cellStyle}>{safeToLocalString(nexaValue)}</td>
                </tr>
            );
            currentRowIndex++;  // Increment row index for the next row
        }

        return rows;
    };

    onClickHideAll() {
        this.setState({
            loadshow: "show-n",
            overly: "show-n",
        });
    }

    handleTdClick = (tableName, stock, index) => {
        // console.log("Table Name: ", tableName);
        if (tableName === "funded_physical_stocks" && this.state.fundedStockTableData.length > 0) {
            this.setState({
                sidebartitle: "Funded Physical Stock",
                rowData: this.state.fundedStockTableData,
                sliderRouteTranslate: 'slider-translate-75p',
                loadshow: 'show-n',
                overly: 'show-m',
                selectedSlideInTable: tableName,
            })
        } else if (tableName === "aged_stock" && this.state.agedStockTableData.length > 0) {
            this.setState({
                sidebartitle: "Aged Stock",
                rowData: this.state.agedStockTableData,
                sliderRouteTranslate: 'slider-translate-75p',
                loadshow: 'show-n',
                overly: 'show-m',
                selectedSlideInTable: tableName,
            })
        } else if (tableName === "mtd_status" && this.state.mtdTableData.length > 0) {
            this.setState({
                sidebartitle: "MTD Data",
                rowData: this.state.mtdTableData,
                sliderRouteTranslate: 'slider-translate-75p',
                loadshow: 'show-n',
                overly: 'show-m',
                selectedSlideInTable: tableName,
            })
        } else if (tableName === "region_wise_stock" && this.state.regionWiseData.length > 0) {
            this.setState({
                sidebartitle: "Region-wise Status",
                rowData: this.state.regionWiseData,
                sliderRouteTranslate: 'slider-translate-75p',
                loadshow: 'show-n',
                overly: 'show-m',
                selectedSlideInTable: tableName,
            })
        }
    };

    onCloseRouteDiv = () => {
        this.setState({
            sliderRouteTranslate: "",
            showDiv: 'show-n',
            overly: 'show-n'
        });
    }

    render() {
        const tableStyle = {
            width: '100%',
            borderCollapse: 'collapse',
            textAlign: 'center',
            marginTop: '20px',
        };

        const headerStyle = {
            backgroundColor: 'white',
            color: 'black',
            padding: '8px',
            border: '1px solid #000',
        };

        const headerStyle3 = {
            backgroundColor: 'lightblue',
            color: 'black',
            padding: '8px',
            border: '1px solid #000',
            cursor: 'pointer'
        };

        const tableStyle2 = {
            width: '100%',
            textAlign: 'center',
            borderCollapse: 'collapse',
        };

        const headerStyle2 = {
            backgroundColor: 'white',
            color: 'black',
            padding: '8px',
            border: '1px solid #000',
            fontWeight: 'bold'
        };

        const cellStyle2 = {
            padding: '8px',
            backgroundColor: 'white',
            border: '1px solid #000',
        };
        const {
            Bangalore,
            Nagpur,
            Siliguri,
            Kharkoda,
        } = this.state.fleetData
        const { receipt_rail, receipt_road, fund_receipt_count, pending_for_exit, tvp_exit_count } = this.state.nth_day_counters;
        // const { planned_vin_count, stock_count, total_funded_stock, transit_count, unplanned_vin_count } = this.state.fund_data;

        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                ></SweetAlert>
                {/* Modal component */}
                <div className={"slide-r " + (this.state.sliderRouteTranslate)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            {this.state.sidebartitle}
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>

                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                            <div className="modal-table-container" style={{ display: "flex", flexDirection: "column", width: "100%", height: "100vh", overflowY: "auto" }}>
                                {this.renderSlideInTable()}
                                <button className="btn btn-danger" style={{ marginTop: "20px", marginRight: "20px", alignSelf: "center" }} onClick={this.onCloseRouteDiv.bind(this)}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                <div className={"dataLoadpage " + (this.state.loadshow)}></div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box">
                        <div className="loader-box">
                            <div className="rotate dashed colored">
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="card col-xl-12">
                    <div className="card-header"> */}
                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '-30px 10px -15px 10px' }}>
                    <div style={{ flexDirection: 'row' }}>
                        <h5 style={{ color: '#245B53', fontSize: "x-large" }}>Transit Vehicle Park Dashboard
                        </h5>
                    </div>
                    <div style={{ flexDirection: 'row' }}>
                        <div style={{
                            backgroundColor: '#4C5B9F',
                            color: 'white',
                            padding: '6px 12px',
                            borderRadius: '20px',
                            fontSize: '14px',
                            display: 'inline-block',
                            fontFamily: 'Arial, sans-serif',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 25 18">
                                <g id="Layer_2" data-name="Layer 2" transform="translate(-1 -1)">
                                    <path id="Path_316" data-name="Path 316" d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm3.707,14.707a1,1,0,0,1-1.414,0l-3-3A1,1,0,0,1,11,12V6a1,1,0,0,1,2,0v5.586l2.707,2.707a1,1,0,0,1,0,1.414Z" fill="#fff" />
                                </g>
                            </svg> {this.state.currentDateTime}
                        </div>
                    </div>
                </div>
                <div style={{ margin: '10px' }}>
                    {/* <h1 style={{ textAlign: 'center' }}>TVP Management Dashboard</h1> */}
                    <table id="dashboard" style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={headerStyle} rowSpan={2}>Stock Yard Location</th>
                                <th style={headerStyle2} colSpan={8}>Stock Status (YTD)</th>
                                <th style={headerStyle2} colSpan={3}>Stock Status (MTD) </th>
                            </tr>

                            <tr>
                                <th style={headerStyle}>Capacity</th>
                                <th style={headerStyle}>Physical Stock</th>
                                <th style={headerStyle}>Utilization %</th>
                                <th style={headerStyle}>Transit Stock</th>
                                <th style={headerStyle}>Waiting for Unloading</th>
                                <th style={headerStyle3} onClick={() => this.handleTdClick("funded_physical_stocks")}>View Funded Stock</th>
                                <th style={headerStyle}>Pending for Exit</th>
                                <th style={headerStyle3} onClick={() => this.handleTdClick("aged_stock")}>View Ageing</th>
                                <th style={headerStyle}>Factory Invoice</th>
                                <th style={headerStyle}>TVP Receipt</th>
                                <th style={headerStyle}>TVP Exit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTableData()}
                        </tbody>
                    </table>
                    <h5 style={{ marginTop: '15px', color: '#245B53', marginBottom: '10px', fontSize: "x-large" }}>MTD</h5>

                    <div style={{ display: 'flex', justifyContent: 'space-around', height: '140px', margin: '-10px' }}>
                        <div onClick={() => this.handleTdClick("mtd_status")} style={{ cursor: 'pointer', width: '100%', margin: '10px', background: 'radial-gradient(circle at top,#656BF5,#171C8F)', overflow: 'hidden', borderRadius: '10px', color: 'white', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                            <div style={{ textAlign: 'start' }}>
                                <h6>Receipt Rail</h6>
                                <h4>{receipt_rail ? receipt_rail : 0}</h4>
                            </div><div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25.26" height="32" viewBox="0 0 25.26 32">
                                    <g id="underground" transform="translate(-61.359)" opacity="0.79">
                                        <g id="Layer_1_21_" transform="translate(61.359)">
                                            <path id="Path_309" data-name="Path 309" d="M86.453,29.969l-4.691-8.543A2.779,2.779,0,0,0,84.072,18.7V6.092A6.1,6.1,0,0,0,77.98,0H70a6.171,6.171,0,0,0-6.162,6.162V18.7a2.725,2.725,0,0,0,2.311,2.731l-4.621,8.543a1.379,1.379,0,0,0,.56,1.891,1.041,1.041,0,0,0,.63.14,1.355,1.355,0,0,0,1.19-.7l.42-.7H83.652l.42.7a1.3,1.3,0,0,0,1.19.7,2.044,2.044,0,0,0,.63-.14A1.379,1.379,0,0,0,86.453,29.969Zm-6.582-6.3H68.107L69.3,21.5H78.68Zm-.21-4.691a2.031,2.031,0,1,1,2.031-2.031A2.079,2.079,0,0,1,79.661,18.976ZM66.286,6.932a3.9,3.9,0,0,1,3.851-3.921H77.77a3.96,3.96,0,0,1,3.921,3.921v3.361a1.346,1.346,0,0,1-1.4,1.33h-12.6a1.33,1.33,0,0,1-1.33-1.33l-.07-3.361Zm2.031,7.912a2.031,2.031,0,1,1-2.031,2.031A2.032,2.032,0,0,1,68.317,14.845ZM65.446,28.5l1.54-2.731h14l1.54,2.731Z" transform="translate(-61.359)" fill="#fff" />
                                        </g>
                                    </g>
                                </svg>
                            </div>

                        </div>
                        <div onClick={() => this.handleTdClick("mtd_status")} style={{ cursor: 'pointer', width: '100%', margin: '10px', textAlign: 'center', background: 'radial-gradient(circle at top,#FFA500,#D36B1F)', overflow: 'hidden', borderRadius: '10px', color: 'white', display: 'flex', justifyContent: 'space-around', alignItems: 'center', alignItems: 'center' }}>

                            <div style={{ textAlign: 'start' }}>
                                <h6>Receipt Road</h6>
                                <h4>{receipt_road ? receipt_road : 0}</h4>
                            </div><div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="39.128" height="30.098" viewBox="0 0 39.128 30.098">
                                    <g id="road-with-broken-line" transform="translate(0 -52.552)" opacity="0.799">
                                        <g id="Group_243" data-name="Group 243" transform="translate(0 52.552)">
                                            <path id="Path_310" data-name="Path 310" d="M94.607,52.552q.05,1.249.1,2.5h-1.62q.05-1.249.1-2.5H89.245L81.32,82.65H91.978q.05-1.249.1-2.5h3.637q.051,1.249.1,2.5h10.658l-7.925-30.1Zm-1.67,6.236h1.921q.139,3.472.279,6.944H92.658Q92.8,62.26,92.937,58.788Zm-.708,17.626q.139-3.472.279-6.943h2.779q.14,3.472.279,6.943Z" transform="translate(-74.333 -52.552)" fill="#fff" />
                                            <path id="Path_311" data-name="Path 311" d="M297.65,52.552h-1.342q5.021,15.049,10.042,30.1h3.628Z" transform="translate(-270.85 -52.552)" fill="#fff" />
                                            <path id="Path_312" data-name="Path 312" d="M12.328,52.552,0,82.65H3.629l10.041-30.1Z" transform="translate(0 -52.552)" fill="#fff" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div onClick={() => this.handleTdClick("mtd_status")} style={{ cursor: 'pointer', width: '100%', margin: '10px', textAlign: 'center', background: 'radial-gradient(circle at top,#16419D,#0BB782)', overflow: 'hidden', borderRadius: '10px', color: 'white', display: 'flex', justifyContent: 'space-around', alignItems: 'center', alignItems: 'center' }}>
                            <div style={{ textAlign: 'start' }}>
                                <h6>Fund Receipt</h6>
                                <h4>{fund_receipt_count ? fund_receipt_count : 0}</h4>
                            </div><div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="33.334" viewBox="0 0 30 33.334">
                                    <g id="Layer_2" data-name="Layer 2" transform="translate(-3 -2)" opacity="0.583">
                                        <path id="Path_313" data-name="Path 313" d="M28,2H8A5,5,0,0,0,3,7V31.667a3.333,3.333,0,0,0,4.767,3.017c.4-.2.75-.417,1.117-.633a3.917,3.917,0,0,1,2.45-.783,3.967,3.967,0,0,1,2.467.783A7.15,7.15,0,0,0,18,35.333a7.133,7.133,0,0,0,4.2-1.283,3.95,3.95,0,0,1,2.467-.783,3.883,3.883,0,0,1,2.45.783,11.983,11.983,0,0,0,1.117.617,3.333,3.333,0,0,0,4.767-3V7a5,5,0,0,0-5-5ZM18,25.333H11.333a1.667,1.667,0,1,1,0-3.333H18a1.667,1.667,0,0,1,0,3.333Zm6.667-6.667H11.333a1.667,1.667,0,1,1,0-3.333H24.667a1.667,1.667,0,0,1,0,3.333Zm0-6.667H11.333a1.667,1.667,0,1,1,0-3.333H24.667a1.667,1.667,0,0,1,0,3.333Z" transform="translate(0)" fill="#fff" />
                                    </g>
                                </svg>
                            </div>

                        </div>
                        <div onClick={() => this.handleTdClick("mtd_status")} style={{ cursor: 'pointer', width: '100%', margin: '10px', textAlign: 'center', background: 'radial-gradient(circle at top,#1C49B3,#4E7FFF)', overflow: 'hidden', borderRadius: '10px', color: 'white', display: 'flex', justifyContent: 'space-around', alignItems: 'center', alignItems: 'center' }}>

                            <div style={{ textAlign: 'start' }}>
                                <h6>Pending for Exit</h6>
                                <h4>{pending_for_exit ? pending_for_exit : 0}</h4>
                            </div><div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="31.832" height="31.834" viewBox="0 0 31.832 31.834">
                                    <g id="Group_247" data-name="Group 247" transform="translate(-1073 -699.333)">
                                        <g id="logout" transform="translate(1073 699.333)" opacity="0.58">
                                            <g id="Group_245" data-name="Group 245" transform="translate(0)">
                                                <g id="Group_244" data-name="Group 244">
                                                    <path id="Path_314" data-name="Path 314" d="M311.842,154.137a1.341,1.341,0,0,0-.288-.432l-3.978-3.978A1.326,1.326,0,0,0,305.7,151.6l1.715,1.715h-7.409a1.326,1.326,0,0,0,0,2.653h7.409l-1.715,1.715a1.326,1.326,0,1,0,1.876,1.876l3.978-3.978a1.314,1.314,0,0,0,.288-.432A1.328,1.328,0,0,0,311.842,154.137Z" transform="translate(-280.11 -140.054)" fill="#fff" />
                                                    <path id="Path_315" data-name="Path 315" d="M22.563,18.57A1.326,1.326,0,0,0,21.237,19.9v6.632H15.931V5.306a1.328,1.328,0,0,0-.946-1.271L10.379,2.653H21.237V9.285a1.326,1.326,0,1,0,2.653,0V1.326A1.326,1.326,0,0,0,22.563,0H1.34A1.182,1.182,0,0,0,1.2.025a1.31,1.31,0,0,0-.562.2C.612.24.576.241.549.263.538.271.534.285.523.293A1.314,1.314,0,0,0,.17.712,1.083,1.083,0,0,0,.133.824a1.258,1.258,0,0,0-.1.31.826.826,0,0,0,0,.115c0,.027-.019.05-.019.077V27.855a1.325,1.325,0,0,0,1.066,1.3l13.264,2.653a1.234,1.234,0,0,0,.26.027,1.327,1.327,0,0,0,1.326-1.326V29.181h6.632a1.326,1.326,0,0,0,1.326-1.326V19.9A1.326,1.326,0,0,0,22.563,18.57Z" transform="translate(-0.014)" fill="#fff" />
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Ikon" transform="translate(1075.425 708.379)" opacity="0.576">
                                            <path id="Path_17" data-name="Path 17" d="M15.288,14.761h-.982v-.785a4.589,4.589,0,0,0-2.532-4.44.717.717,0,0,1,0-1.33,4.589,4.589,0,0,0,2.532-4.44V2.982h.982a.491.491,0,0,0,0-.982H4.491a.491.491,0,0,0,0,.982h.982v.785A4.589,4.589,0,0,0,8,8.206a.717.717,0,0,1,0,1.33,4.589,4.589,0,0,0-2.532,4.44v.785H4.491a.491.491,0,0,0,0,.982h10.8a.491.491,0,0,0,0-.982Zm-8.834,0v-.785a3.638,3.638,0,0,1,1.93-3.535,1.7,1.7,0,0,0,0-3.139,3.638,3.638,0,0,1-1.93-3.535V2.982h6.871v.785A3.638,3.638,0,0,1,11.395,7.3a1.7,1.7,0,0,0,0,3.139,3.638,3.638,0,0,1,1.93,3.535v.785Zm6.38-.982a.491.491,0,0,1-.491.491H7.436a.491.491,0,0,1-.491-.491,2.945,2.945,0,1,1,5.889,0Z" transform="translate(-4 -2)" fill="#2f5ed1" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div onClick={() => this.handleTdClick("mtd_status")} style={{ cursor: 'pointer', width: '100%', margin: '10px', textAlign: 'center', background: 'radial-gradient(circle at top,#0CA343,#027D14)', overflow: 'hidden', borderRadius: '10px', color: 'white', display: 'flex', justifyContent: 'space-around', alignItems: 'center', alignItems: 'center' }}>

                            <div style={{ textAlign: 'start' }}>
                                <h6>Exit</h6>
                                <h4>{tvp_exit_count ? tvp_exit_count : 0}</h4>
                            </div><div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="33.332" height="33.334" viewBox="0 0 33.332 33.334">
                                    <g id="logout" transform="translate(-0.014)" opacity="0.58">
                                        <g id="Group_245" data-name="Group 245" transform="translate(0.014)">
                                            <g id="Group_244" data-name="Group 244">
                                                <path id="Path_314" data-name="Path 314" d="M312.462,154.363a1.4,1.4,0,0,0-.3-.453L308,149.745a1.389,1.389,0,1,0-1.964,1.964l1.8,1.8h-7.759a1.389,1.389,0,1,0,0,2.778h7.759l-1.8,1.8A1.389,1.389,0,1,0,308,160.043l4.165-4.165a1.376,1.376,0,0,0,.3-.453A1.39,1.39,0,0,0,312.462,154.363Z" transform="translate(-279.235 -139.616)" fill="#fff" />
                                                <path id="Path_315" data-name="Path 315" d="M23.626,19.445a1.389,1.389,0,0,0-1.389,1.389v6.945H16.681V5.556a1.39,1.39,0,0,0-.99-1.331L10.867,2.778h11.37V9.723a1.389,1.389,0,0,0,2.778,0V1.389A1.389,1.389,0,0,0,23.626,0H1.4A1.238,1.238,0,0,0,1.26.026,1.372,1.372,0,0,0,.671.232C.64.251.6.253.574.275.563.283.558.3.547.307A1.376,1.376,0,0,0,.178.746,1.134,1.134,0,0,0,.139.863a1.317,1.317,0,0,0-.11.325.865.865,0,0,0,0,.121c0,.028-.019.053-.019.081V29.167a1.388,1.388,0,0,0,1.117,1.361L15.02,33.306a1.292,1.292,0,0,0,.272.028,1.39,1.39,0,0,0,1.389-1.389V30.556h6.945a1.389,1.389,0,0,0,1.389-1.389V20.834A1.389,1.389,0,0,0,23.626,19.445Z" transform="translate(-0.014)" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: "20px", display: 'flex', justifyContent: "space-between" }}>
                        {/* Additional Table 1 */}
                        <div style={{ flexDirection: 'column', width: '100%' }}>
                            {/* <div >
                                <table style={tableStyle2}>
                                    <thead>
                                        <tr>
                                            <th style={headerStyle2} colSpan={2}>Fund Receipt (YTD)</th>
                                            <th style={headerStyle2} colSpan={4}>Funded Stock Status (YTD)</th>
                                        </tr>
                                    </thead>
                                    <thead>
                                        <tr>
                                            <th style={headerStyle2} colSpan={1}>Stock</th>
                                            <th style={headerStyle2} colSpan={1}>Transit</th>
                                            <th style={headerStyle2} colSpan={1}>Planned</th>
                                            <th style={headerStyle2} colSpan={1}>Unplanned</th>
                                            <th style={headerStyle2} colSpan={1}>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={cellStyle2}>{stock_count}</td>
                                            <td style={cellStyle2}>{transit_count}</td>
                                            <td style={cellStyle2}>{planned_vin_count}</td>
                                            <td style={cellStyle2}>{unplanned_vin_count}</td>
                                            <td style={cellStyle2}>{total_funded_stock}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> */}
                            <div>
                                <table style={{ width: "100%", textAlign: "center", cursor: "pointer" }} onClick={() => this.handleTdClick("region_wise_stock")}>
                                    <thead>
                                        <tr>
                                            <th style={headerStyle2} colSpan={1}>Region-wise</th>
                                            <th style={headerStyle2} colSpan={2}>Arena Channel</th>
                                            <th style={headerStyle2} colSpan={2}>Nexa Channel</th>
                                            <th style={headerStyle2} colSpan={1}>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={headerStyle2}>Stock</td>
                                            <td style={cellStyle2} colSpan={2}>{(this.state.regionStockData[0] && this.state.regionStockData[0].in_stock_arena_count) ? safeToLocalString(this.state.regionStockData[0].in_stock_arena_count) : 0}</td>
                                            <td style={cellStyle2} colSpan={2}>{(this.state.regionStockData[0] && this.state.regionStockData[0].in_stock_nexa_count) ? safeToLocalString(this.state.regionStockData[0].in_stock_nexa_count) : 0}</td>
                                            <td style={cellStyle2}>{(this.state.regionStockData[0] && this.state.regionStockData[0].in_stock_arena_nexa_count) ? safeToLocalString(this.state.regionStockData[0].in_stock_arena_nexa_count) : 0}</td>
                                        </tr>
                                        <tr>
                                            <td style={headerStyle2}>Transit</td>
                                            <td style={cellStyle2} colSpan={2}>{(this.state.regionTransitData[0] && this.state.regionTransitData[0].transit_stock_arena_count) ? safeToLocalString(this.state.regionTransitData[0].transit_stock_arena_count) : 0}</td>
                                            <td style={cellStyle2} colSpan={2}>{(this.state.regionTransitData[0] && this.state.regionTransitData[0].transit_stock_nexa_count) ? safeToLocalString(this.state.regionTransitData[0].transit_stock_nexa_count) : 0}</td>
                                            <td style={cellStyle2} colSpan={2}>{(this.state.regionTransitData[0] && this.state.regionTransitData[0].transit_arena_nexa_count) ? safeToLocalString(this.state.regionTransitData[0].transit_arena_nexa_count) : 0}</td>
                                        </tr>
                                        <tr>
                                            <td style={headerStyle2}>Total</td>
                                            <td style={cellStyle2} colSpan={2}>{(this.state.regionTotalData[0] && this.state.regionTotalData[0].total_arena_count) ? safeToLocalString(this.state.regionTotalData[0].total_arena_count) : 0}</td>
                                            <td style={cellStyle2} colSpan={2}>{(this.state.regionTotalData[0] && this.state.regionTotalData[0].total_nexa_count) ? safeToLocalString(this.state.regionTotalData[0].total_nexa_count) : 0}</td>
                                            <td style={cellStyle2} colSpan={2}>{(this.state.regionTotalData[0] && this.state.regionTotalData[0].total_aren_nexa_count) ? safeToLocalString(this.state.regionTotalData[0].total_aren_nexa_count) : 0}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            {/* Additional Table 2 */}
                            <div style={{ marginTop: "20px" }}>
                                <table style={tableStyle2}>
                                    <thead>
                                        <tr>
                                            <th style={headerStyle2} colSpan={4}>Fleet Availability (20 km Vicinity) (N<sup>th</sup> Day)</th>
                                        </tr>
                                    </thead>
                                    <thead>
                                        <tr>
                                            <th style={headerStyle2} colSpan={1}>TVP-Bangalore</th>
                                            <th style={headerStyle2} colSpan={1}>TVP-Nagpur</th>
                                            <th style={headerStyle2} colSpan={1}>TVP-Siliguri</th>
                                            <th style={headerStyle2} colSpan={1}>TVP-Kharkoda</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={cellStyle2}>{Bangalore ? safeToLocalString(Bangalore.length) : 0}</td>
                                            <td style={cellStyle2}>{Nagpur ? safeToLocalString(Nagpur.length) : 0}</td>
                                            <td style={cellStyle2}>{Siliguri ? safeToLocalString(Siliguri.length) : 0}</td>
                                            <td style={cellStyle2}>{Kharkoda ? safeToLocalString(Kharkoda.length) : 0}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div style={{ marginRight: '-15px' }}>
                            {/* Pie Chart */}
                            <div className="col-md-12">
                                <TvpManagementDashboardPieChart
                                    chartType="pie"
                                    title="OVERALL CONTRIBUTION"
                                    yaxistitle=""
                                    series={this.state.overall_contribution}
                                    innerSize="50%"
                                    seriesName="Contribution"
                                // Provide drilldownseries prop if necessary
                                // drilldownseries={...}
                                />
                            </div>
                        </div>
                        {/* </div>
                        </div> */}
                    </div></div ></div >
        );
    }

}
function safeToLocalString(value) {
    const val = Number(value);
    return val != null ? val.toLocaleString('en-IN') : 0;
}



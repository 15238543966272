/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from "react";
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Badge,
    Table,
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Form,
    CustomInput,
    FormGroup,
    Label,
    Input,
    Button,
    //Button,
    //CardLink,
    //CardFooter,
    //CardText,
} from "reactstrap";

// import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from "@ag-grid-enterprise/all-modules";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-balham.css";

//import { StaticCardData } from "../cards/staticCardData";
// import { AdvancedCardData } from "../cards/advancedCardData";
// import Carousel from 'react-bootstrap/Carousel'

// Styling
// import { data, map, param } from "jquery";
// import Calendar from 'react-calendar';
// import Icofont from 'react-icofont';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import * as Datetime from 'react-datetime';
// import { compose } from "redux";
// import { update } from "lodash";
// import datetimepicker from 'jquery-datetimepicker';
import Select from "react-select";
import $ from "jquery";
import "react-datetime/css/react-datetime.css";
import SweetAlert from "react-bootstrap-sweetalert";

var redirectURL = require("../redirectURL");
var moment = require("moment");
window.jQuery = $;
window.$ = $;
export default class ELockConfigurations extends Component {
    state = {
        modules: AllModules,
        pageTitle: "Configure Alerts",
        defaultColDef: {
            width: 170,
            wrtable: true,
            filter: true,
            resizable: true,
            wrapText: false,
            autoHeight: true,
        },
        frameworkComponents: {},
        fmComp: {},
        rowData: [],
        updateData: [],
        animateRows: true,
        debug: true,
        customizer: false,
        detailscustomizer: false,
        showToolPanel: false,
        uppressAggFuncInHeader: true,
        rowHeight: 250,
        childconfs: [],
        childrow: [],
        rowModelType: "serverSide",
        paginationPageSize: 100,
        cacheBlockSize: 10,
        maxBlocksInCache: 1,
        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left",
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center",
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" },
            ],
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                },
            ],
        },
        detailCellRendererParams: {},
        loadshow: "show-m",
        overly: "show-m",
        show: false,
        basicTitle: "",
        basicType: "",
        periodicity_slot: [],
        emailRowData: [],
        devices: [],
        filteredDevices: [],
        showDeviceList: "show-n",
        process: { value: "set", label: "Set" },
        aisoption: "",
        communication: "",
        datavalue1: "",
        optionValue: null,
        is_required: 'true',
        modeValue:null,
        
    };

    componentDidMount() {
        this.getDevicesList()
    }

    getDevicesList = () => {
        var org_id = localStorage.getItem("org_id");
        var params = {
            org_id: org_id,
        };
        redirectURL.post("/consignments/getElockDevicesList", params).then((response) => {
            var records = response.data;
            let deviceList = []
            records.map(e => deviceList.push({ value: e, label: e }))
            this.setState({
                loadshow: "show-n",
                overly: "show-n",
                devices: deviceList,
            });
        });
    };

    onClickHideAll() {
        this.setState({
            loadshow: "show-n",
            overly: "show-n",
            customizer: false,
            detailscustomizer: false,
        });
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        var sort = [
            {
                colId: "loading_started_on",
                sort: "desc",
            },
        ];
        this.gridApi.setSortModel(sort);
    };

    closeAlert = () => {
        this.setState({
            show: false,
        });
        // window.location.reload();
    };

    initalDeviceList = (event) => {
        $(".device-dropdown").removeClass("show-n");
        var devices = this.state.devices;
        // var alldevices = []
        // var alldevices =devices.filter(f =>
        //     !this.state.registerdevices.some(d => d.device_id == f.device_id)
        // );
        var dataset = devices;
        dataset = dataset.slice(0, 100);
        this.setState({
            filteredDevices: dataset,
            showDeviceList: "show-m",
        });
    };

    handlerDeviceForm = (event) => {
        $(".device-dropdown").removeClass("show-n");
        var alldevices = [];
        var devices = this.state.devices;
        // var alldevices =devices.filter(f =>
        //     !this.state.registerdevices.some(d => d.device_id == f.device_id)
        // );
        var dataset = devices;
        var filteredResult = dataset.filter(function (e) {
            if (
                e.device_id != null &&
                e.device_id != undefined &&
                e.device_id != ""
            ) {
                return e.device_id
                    .toString()
                    .toLowerCase()
                    .includes(
                        $("#inputDevice")
                            .val()
                            .toLocaleLowerCase()
                    );
            }
        });
        filteredResult = filteredResult.slice(0, 100);
        this.setState({
            filteredDevices: filteredResult,
            showDeviceList: "show-m",
        });
    };
    onChangeProcess(process) {
        this.setState({ process }, () =>
            console.log(`Process Option selected:`, this.state.process)
        );
    }

    onChangeDeviceId = (device_id) => {
        console.log('device_id', device_id)
        this.setState({ device_id });
    }

    onChangeModeVal = modeValue => {
        // console.log('onChangeModeVal',modeValue)
        this.setState({
            modeValue
        })
    }

    onChangeOption = optionValue => {
        // console.log('optionValue',optionValue)
        if (optionValue.value == 'f_reset') {
            this.setState({
                is_required: false,
                optionValue
            })
        } else {
            this.setState({
                optionValue,
                is_required: 'true'
            })
        }
    }
    changeHandler = (event) => {
        //console.log("Select", event.target.value);
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    };

    updateConfigurations = (event) => {
        event.preventDefault();
        var dataValue = $("#dataValue").val();
        const { device_id, optionValue, modeValue } = this.state
        var mod_val = ''
        if(modeValue !== null) {
            mod_val = modeValue.value
        }
        var parameters = {
            device_id: device_id.value,
            optionValue: optionValue.value,
            modeValue : mod_val,
            dataValue,
        };
        parameters['user_name'] = localStorage.getItem('username');
        parameters['email'] = localStorage.getItem('email');

        // console.log("Parameters ", parameters);
        redirectURL.post("/consignments/saveDeviceConfigurations", parameters).then((response) => {
            // console.log("response ", response.data);
            if (response.data.message == "Success") {
                this.setState({
                    show: true,
                    basicType: "success",
                    basicTitle: "Successfuly saved item",
                    device_id: null,
                    optionValue: null,
                });
                $("#dataValue").val("");
            }
            if (response.data.message == "fail") {
                this.setState({
                    show: true,
                    basicType: "danger",
                    basicTitle: "Failed to save item",
                    device_id: null,
                    optionValue: null,
                });
                $("#dataValue").val("");
            }
        });
    }

    render() {
        // var listDevices = [];
        // this.state.devices.map(function (e) {
        //     listDevices.push(<li className="dropdown-device">{e.device_id}</li>);
        // });
        var options = [
            { value: "sms_pw", label: "Set SMS Password" },
            { value: "nrm_tx", label: "Set Normal Data Transmission Interval" },
            { value: "hb_tx", label: "Set Heart Beat Data Transmission Interval" },
            { value: "f_reset", label: "Factory Reset" },
        ];

        const mode = [
            { value: "set", label: "Set" },
            { value: "get", label: "Get" },

        ]
        const { show, basicType, basicTitle, device_id, devices, optionValue, is_required, modeValue } = this.state
        return (
            <div class="container-fluid">
                <SweetAlert
                    show={show}
                    type={basicType}
                    title={basicTitle}
                    onConfirm={this.closeAlert}
                ></SweetAlert>

                <div className="card col-xl-12">
                    <div className="card-header">
                        <h5>
                            <i className="icofont icofont-lock cus-i"></i>{" "}
                            <span>Digi Lock Configurations</span>
                        </h5>
                    </div>
                    <div className="card-body pt-25px col-xl-12 col-lg-12">
                        <form method="POST" id="configurations" className="aircargo-form" onSubmit={this.updateConfigurations}>
                            <div className="row col-xl-12">
                                <div className="col-xl-4">
                                    <label htmlFor="device_id" className="col-xl-12 col-lg-12 pl-10px">Device Id</label>
                                    <Select
                                        closeMenuOnSelect={true}
                                        className={"col-xl-12 col-lg-12 border-radius-0"}
                                        name="device_id"
                                        id="device_id"
                                        onChange={this.onChangeDeviceId}
                                        value={device_id}
                                        options={devices}
                                    />
                                </div>
                                <div className="col-xl-4">
                                    <label htmlFor="modeValue" className="col-xl-12 col-lg-12 pl-10px">Mode</label>
                                    <Select
                                        closeMenuOnSelect={true}
                                        className={"col-xl-12 col-lg-12 border-radius-0"}
                                        name="modeValue"
                                        id="modeValue"
                                        onChange={this.onChangeModeVal}
                                        value={modeValue}
                                        options={mode}
                                    />
                                </div>
                            </div>
                            <div className="row col-xl-12">
                                <div className="col-xl-4">
                                    <label htmlFor="optionValue" className="col-xl-12 col-lg-12 pl-10px">Options</label>
                                    <Select
                                        closeMenuOnSelect={true}
                                        className={"col-xl-12 col-lg-12 border-radius-0"}
                                        name="optionValue"
                                        id="optionValue"
                                        onChange={this.onChangeOption}
                                        value={optionValue}
                                        options={options}
                                    />
                                </div>
                                <div className="col-xl-4">
                                    <label className="col-xl-12 col-lg-12">Data value* : </label>
                                    <input type="text" name="dataValue" id="dataValue" className="form-control pt-5px"
                                        // onChange={this.changeHandler} 
                                        required={is_required} />
                                </div>

                            </div>

                            <div className="form-group" style={{ paddingLeft: '30px', marginTop: '20px' }}>
                                <button type="submit" className="btn btn-success">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function loadDateTimeScript() {
    // alert("timeDate");
    $(".datetimepicker_mask").datetimepicker({
        //mask:'39-19-9999 29:59',
        format: "d-m-Y",
    });

    $(".datetimepicker_date").datetimepicker({
        mask: "39-19-9999",
        format: "d-m-Y",
        timepicker: false,
    });

    var index = window.document.getElementsByTagName("script")[1];
    var script = window.document.createElement("script");
    script.src =
        "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js";
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true;
    script.defer = true;
    index.parentNode.insertBefore(script, index);
}

function DateEditor() { }
// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement("input");
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        format: "H:i",
        timeFormat: "H:i",
        timepicker: true,
        datepicker: false,
        defaultTime: true,
        showApplyButton: true,
        step: 30,
    });
};
// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};
// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};
// returns the new value after editing
DateEditor.prototype.getValue = function () {
    // console.log(this.eInput.value);
    return this.eInput.value;
};
// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};
// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

$(document).ready(function () {
    $(document).on("click", ".dropdown-truck-no", function () {
        var vals = $(this).text();
        $("#inputTruck").val(vals);
        $("#trucks-dropdown").removeClass("show-m");
        $("#trucks-dropdown").addClass("show-n");
    });

    $("body").on("click", function (e) {
        var container = $("#inputTruck");
        if (!container.is(e.target)) {
            try {
                if (
                    $("#trucks-dropdown")
                        .attr("class")
                        .includes("show-m") ||
                    $("#trucks-dropdown").attr("class") == "trucks-dropdown"
                ) {
                    $("#trucks-dropdown").removeClass("show-m");
                    $("#trucks-dropdown").addClass("show-n");
                }
            } catch (e) { }
        }
    });

    $(document).on("click", ".dropdown-device", function () {
        var vals = $(this).text();
        $("#inputDevice").val(vals);
        $("#device-dropdown").removeClass("show-m");
        $("#device-dropdown").addClass("show-n");
    });

    $("body").on("click", function (e) {
        var container = $("#inputDevice");
        if (!container.is(e.target)) {
            try {
                if (
                    $("#device-dropdown")
                        .attr("class")
                        .includes("show-m") ||
                    $("#device-dropdown").attr("class") == "device-dropdown"
                ) {
                    $("#device-dropdown").removeClass("show-m");
                    $("#device-dropdown").addClass("show-n");
                }
            } catch (e) { }
        }
    });
});

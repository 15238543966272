
import React from 'react';
import {Redirect} from 'react-router-dom';
export default class DealerAppStatic extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  
  render() {
    
      return (
       
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12" style={{textAlign:"center",marginTop:"40px"}}>
                    <h4>Please install app by clicking link</h4> <br /><a className="btn btn-warning" href="https://play.google.com/store/apps/details?id=in.enmovil.autometrics" target="_blank">Click here</a>
                </div>
            </div>
        </div>
      
      );
    }   
}
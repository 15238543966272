import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Select from 'react-select';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import ArrivalMap from "./arrivalMap";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import UnloadColumnchart from './columnunloadingdashboard';
import SndUnloadingColumnChart from "./sndUnloadingColumnChart";
import TATColumnChart from "./TATColumnChart";
var googleAnalytics = require("../common/googleanalytics");
var redirectURL = require('../redirectURL');
 
var moment = require('moment');

export default class PRTTurnAroundTime extends Component {

    constructor(props) {
        super(props);
        this.state={
            locations:[],
            transporters:[],
            location:'',
            transporter:'',
            startDate:moment.parseZone().subtract(30, 'days').format("YYYY-MM-DD"),
            endDate:moment.parseZone().format("YYYY-MM-DD"),
            modules:AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                enableValue: true,
                enableRowGroup: true,
                enablePivot: true
            },
            rowData: [],
            paginationPageSize:50,
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            columnwithDefs:[{
                headerName:"Plant Name",

            }],
            sndtab:'show-n',
            prttab:'show-n',
            tnptab:'show-n',
            sndtabactive:'',
            prttabactive:'',
            tnptabactive:'',
            overly : "show-n",
            loadshow : "show-n",
            series:[],
            city_uld_data : [],
            city_dealer_uld_data : [],
            cluster_uld_data : [],
            top_10_dealer_data : [],
            cityWiseSeriesData : [],
            cityWiseCategory : [],
            unloaddrilldown : {},
            rowData : [],
            plantRowData : [],
            plantGridData:[],
            showMapData : "show-n",
            lat : "",
            lng : "",
            distancefromdealer : "",
            no_of_unloads : "",
            showFirstLevel : "show-n",
            filteredLocationData : [], // custom drop down
            showLocationList:"show-n", // custom drop down
            filteredPlantData : [], // custom drop down
            showPlantList:"show-n", // custom drop down
            statesList : [],
            selectedState : {value: "ANDHRA PRADESH", label: "ANDHRA PRADESH"},
            cityData : [],
            transporterData : [],
            possibleUnloadData : [],
            frameworkComponents : {
                ArrivalMap : ArrivalMap
            },
            breaksSlider:"",
            selectedRow : "",
            selectedConsigneeName : "",
            dept_code : "",
            selectedTnpPlant : {"value" : "all","label":"all"},
            dealer_uld_data : [],
            plant_wise_data:[],
            trans_wise_data:[],
            plant_trans_wise_data:[],
            truck_wise_data:[],
            plantwisechartseries:[],
            plantwisedrilldown:[],
            transwisechartseries:[],
            transwisedrilldown:[],
            selected_local_loads : {value:2,"label":"ALL"},
            complete_data : [],
            states_list : [],
            selected_state : [{"label":"ALL","value":"ALL"}]
        }
        this.formatChartData = this.formatChartData.bind(this);
        this.formatTransporterChartData = this.formatTransporterChartData.bind(this);
    }
    componentWillReceiveProps(newprops)
    {
        if(newprops.transporters != this.state.transporters)
        {
            this.setState({
                transporters : newprops.transporters
            })
        }
    }
    componentDidMount(){
        this.setState({
            loadshow : "show-m",
            overly : "show-m"
        })
        
        var dept_code = "LOG-PRT";
        this.setState({
            dept_code : dept_code
        })
        
        // var startDate = "2020-05-01 00:00:00";
        var startDate = moment.parseZone().subtract(30, 'days').format("YYYY-MM-DD")+" 00:00:00";
        var endDate = moment.parseZone().format("YYYY-MM-DD")+" 23:59:59";
    
        var reqparams = {
            from_date : startDate,
            to_date : endDate,
            local_loads : this.state.selected_local_loads.value
            // dept_code : dept_code
        }
        redirectURL.post("/consignments/consigneestates",{dept_code:"LOG-PRT"}).then((response)=>{
            var states = [];
            states.push({"label":"ALL","value":"ALL"})
            response.data.map(function(e){
                if(e != "")
                {
                    states.push({"label":e,"value":e})
                }
                
            })
            this.setState({
                states_list : states
            })
        }).catch((e)=>{
            console.log(e)
        })
        redirectURL.post("/dashboard/getTurnAroundTimeData",{"formdata":reqparams}).then((resp)=>{
            // console.log("plant_wise_data",JSON.parse(resp.data.plant_wise_data))
            console.log("trans_wise_data",JSON.parse(resp.data.trans_wise_data))
            // console.log("plant_trans_wise_data",JSON.parse(resp.data.plant_trans_wise_data))
            // console.log("truck_wise_data",JSON.parse(resp.data.truck_wise_data))
            this.setState({
                plant_wise_data:JSON.parse(resp.data.plant_wise_data),
                trans_wise_data:JSON.parse(resp.data.trans_wise_data),
                plant_trans_wise_data:JSON.parse(resp.data.plant_trans_wise_data),
                rowData:JSON.parse(resp.data.truck_wise_data),
                complete_data : JSON.parse(resp.data.complete_data),
            });
            this.formatChartData()
        }).catch((e)=>{
            console.log(e)
        })
    }

    
    
    formatChartData()
    {
        // plant wise top level data
        var plantwisechartseriesdata=[];
        var plantdrilldownseries=[];
        var plant_trans_wise = this.state.plant_trans_wise_data;
        this.state.plant_wise_data.map(async function(e){
            var plantwisedrilldowndata=[];
            plantwisechartseriesdata.push({
                name : e.plant_location,
                y : Math.round(e.average_tat),
                drilldown : e.plant_location,
                trips : e.no_of_trucks 
            })
            var filteredplant = plant_trans_wise.filter(function(k){
                return e.plant_location == k.plant_location
            });
            await filteredplant.map(function(j){
                plantwisedrilldowndata.push([j.transporter_name,Math.round(j.average_tat)])
            });
            plantdrilldownseries.push({
                name : e.plant_location,
                id : e.plant_location,
                data : plantwisedrilldowndata,

            })
        });
        this.formatTransporterChartData()
        
        this.setState({
            plantwisechartseries : plantwisechartseriesdata,
            plantwisedrilldown : {series:plantdrilldownseries},
        })
        
    }
    async formatTransporterChartData(){
        var transwisechartseriesdata = []
        var transdrilldownseries=[];
        var plant_trans_wise = this.state.plant_trans_wise_data;
        console.log(this.state.trans_wise_data);
        const seriesdata =  this.state.trans_wise_data.map(async function(e,index){
            var transwisedrilldowndata=[];
           await transwisechartseriesdata.push({
                name : e.transporter_name,
                y : Math.round(e.average_tat),
                drilldown : e.transporter_name,
                trips : e.no_of_trucks 
            })
            var filteredplant = plant_trans_wise.filter(function(k){
                return e.transporter_name == k.transporter_name
            });
            await filteredplant.map(function(j){
                transwisedrilldowndata.push([j.plant_location,Math.round(j.average_tat)])
            });
           await transdrilldownseries.push({
                name : e.transporter_name,
                id : e.transporter_name,
                data : transwisedrilldowndata,
            });
        })
        // console.log(transwisechartseriesdata,"chart series");
        Promise.all(seriesdata).then(()=>{
            this.setState({
                transwisechartseries : transwisechartseriesdata,
                transwisedrilldown : {series:transdrilldownseries},
                overly : "show-n",
                loadshow : "show-n"
            });
            console.log(transwisechartseriesdata,"chart")
            console.log(transdrilldownseries,"drilldown")
        }) 
    }
    
    
    onClickLoadDept(deptcode){
        this.setState({
            dept_code:deptcode
        })
    }
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
    }

    onShowRouteDiv(params)
    {
        console.log(params);
        this.setState({
            breaksSlider : "slider-translate-60p",
            selectedRow : params,
            selectedConsigneeName : params.consignee_name,
            overly : "show-m",
            lat : "",
            lng : ""
        });
        this.renderMapWeather()
    }
    

    renderMapWeather = () => {    	
        loadScriptWeather("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMapWeather");
        window.initMapWeather = this.initMapWeather
    }
    initMapWeather = () => 
    {
        try
        {
        
            var coordsvalues;
            var map = new window.google.maps.Map(document.getElementById('plantmap'), {
                zoom: 8,
                center: new window.google.maps.LatLng(28.497236,77.062363),
                mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                mapTypeControl: true,
                mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_RIGHT
                },            
                disableDefaultUI: true,
                fullscreenControl: true,
                zoomControl: true
            });
            var bounds = new window.google.maps.LatLngBounds();
            var plantRowData = this.state.selectedRow;
            var plant = {
                url: require("../../assets/icons/business.png"), // url
                scaledSize: new window.google.maps.Size(32,32), // scaled size
                origin: new window.google.maps.Point(0,0), // origin
                anchor: new window.google.maps.Point(0, 0) // anchor
              };
            // plantRowData.map(function(e){
                console.log(plantRowData);
                var coords = JSON.parse(plantRowData.consignee_coordinates);
                var plantmarker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(coords.lat,coords.lng),
                map: map,
                icon: plant,
                title : plantRowData.consignee_name
                });
                var infowindow2 = new window.google.maps.InfoWindow({
                content: "<div class='weather-marker-tooltip'><div class='forecast-date'><label class='forecast-labels'> Consignee Code : </label> "+plantRowData.consignee_code+"</div><div class='address'> <label class='forecast-labels'>Consignee Name :</label> "+plantRowData.consignee_name+"</div></div>"
                });
                window.google.maps.event.addListener(plantmarker, 'mouseover', function() {
                infowindow2.open(map,plantmarker);
                });
                window.google.maps.event.addListener(plantmarker, 'mouseout', function() {
                infowindow2.close();
                });
                bounds.extend(new window.google.maps.LatLng(coords.lat,coords.lng));
            // });
            var breakIcon = {
                url: require("../../assets/icons/delivery_truck.png"), // url
                scaledSize: new window.google.maps.Size(38, 38), // scaled size
                origin: new window.google.maps.Point(0,0), // origin
                anchor: new window.google.maps.Point(0, 0) // anchor
            };
            
            var breaks = plantRowData.frequent_break_locations;
            var markersArr=[];
            breaks.map(function(e,index){

                console.log(e)
                markersArr[index]  = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(e.lat,e.lng),
                    map: map,
                    icon: breakIcon,
                    // title : ""+params.address+","+params.forecast_date+","+params.description
                });
                try{
                    let infowindow3 = new window.google.maps.InfoWindow({
                        content: "<div class='weather-marker-tooltip'><div class='forecast-date'><label class='forecast-labels'> No. of Unloads : </label> "+e.no_of_breaks+"</div><div class='address'> <label class='forecast-labels'>Distance From Dealer :</label> "+e.distance_from_dealer_location_km.toFixed(2)+"</div></div>"
                        });
                    window.google.maps.event.addListener(markersArr[index], 'mouseover', function() {
                    infowindow3.open(map,markersArr[index]);
                    });
                    window.google.maps.event.addListener(markersArr[index], 'mouseout', function() {
                    infowindow3.close();
                    });
                }catch(e){
                    console.log(e);
                }
                
                bounds.extend(new window.google.maps.LatLng(e.lat,e.lng));
            })
                
            if(this.state.lat != "" && this.state.lng != "")
            {
                
                // var breakmarker = new window.google.maps.Marker({
                //     position: new window.google.maps.LatLng(this.state.lat,this.state.lng),
                //     map: map,
                //     icon: breakIcon,
                //     // title : ""+params.address+","+params.forecast_date+","+params.description
                // });
                // var infowindow = new window.google.maps.InfoWindow({
                //     content: "<div class='weather-marker-tooltip'><div class='forecast-date'><label class='forecast-labels'> No. of Unloads : </label> "+this.state.no_of_unloads+"</div><div class='address'> <label class='forecast-labels'>Distance From Dealer :</label> "+this.state.distancefromdealer+"</div></div>"
                //     });
                // window.google.maps.event.addListener(breakmarker, 'mouseover', function() {
                // infowindow.open(map,breakmarker);
                // });
                // window.google.maps.event.addListener(breakmarker, 'mouseout', function() {
                // infowindow.close();
                // });
                var bounds = new window.google.maps.LatLngBounds();
                // bounds.extend(new window.google.maps.LatLng(coords.lat,coords.lng));
                bounds.extend(new window.google.maps.LatLng(this.state.lat,this.state.lng));
            }
            // this.setState({
            //     breaksSlider : "slider-translate-70p",
            // })
            map.fitBounds(bounds)
        }catch(e){
            console.log(e);
        }
          
    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
        var mm = (d.getMonth() +1)
        if(mm <10)
        {
            var month = "0"+mm
        }
        else{
            var month = mm
        }
         
    	var startdate = d.getFullYear()+"-"+month+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
        var mm = (d.getMonth() +1)
        if(mm <10)
        {
            var month = "0"+mm
        }
        else{
            var month = mm
        }
         
    	var edate = d.getFullYear()+"-"+month+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
        this.setState({
    		endDate:edate
    	});
    }
    onSelectLoads(local_loads)
    {
        this.setState({
            selected_local_loads : local_loads
        })
    }
    onSelectState = state =>{
        
        try{
            var states = [];
            state.map(function(e){
                states.push(e)
            })
            this.setState({
                selected_state : states,
            });
        }catch(e){} 
    }
    onSelectTNPPlant = plant =>{
        console.log(plant);
        if(plant.value != "all")
        {
            var plant = plant;
        }
        else
        {
            var plant = "";
        }
        this.setState({
            selectedTnpPlant : plant
        })
    }
    onSelectLocation= location =>{
		this.setState(
			{ location },
			() => console.log(`Transporter Option selected:`, this.state.location)
          );
          this.setState({
            filteredPlantData : []
          });
        console.log(location);
        var selectedValue = location.value;
        var transportersData = this.state.transporterData;
        var transportersData = transportersData.filter(function(e){
            if(e.consignee_city == selectedValue)
            {
                return e.consignee_name+"-"+e.consignee_code;
            }
        });
        var filtered=[];
        transportersData.map(function(e){
            filtered.push(e.consignee_name+"-"+e.consignee_code);
        })
        filtered = [...new Set(filtered)]

        this.setState({
            transporters : filtered
        })
    }
    onSelectTransporter (transporter){
		this.setState(
			{ transporter },
			() => console.log(`Transporter Option selected:`, this.state.transporter)
		  );
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
          })
        this.setState({
            transporter : {"label":transporter.value,"value":transporter.value}
        })
    }
    stateOptions(){
        var items=[];
       // console.log("locations ",this.props.locations)
        var locations = this.state.statesList;
        //console.log("locations ",locations)
        items.push({"value":"all",label:"All"})
        if(locations.length > 0){
            locations.map(item =>{
                if(item != "" && item != null)
                {
                    items.push({value:item,label:item});
                }
                 
            });
        }
        //console.log("Locas ", items)
        return items;
    }
    tnpPlantOptions(){
        var items=[];
        items.push({"value":"all",label:"all"});
        items.push({"value":"MSIL-G",label:"Gurgaon"});
        items.push({"value":"MSIL-M",label:"Manesar"});
        items.push({"value":"MSILMC",label:"Casting"});
        items.push({"value":"MSILME",label:"Power Train"});
        items.push({"value":"SMG",label:"Gujarat"});
        items.push({"value":"MUL",label:"SMIPL"});
        return items;
    }
    locationoptions(){
        var items=[];
       // console.log("locations ",this.props.locations)
        var locations = this.state.locations;
        //console.log("locations ",locations)
        items.push({"value":"all",label:"All"})
        if(locations.length > 0){
            locations.map(item =>{
                if(item != "" && item != null)
                {
                    items.push({value:item.for_city,label:item.for_city});
                }
                 
            });
        }
        //console.log("Locas ", items)
        return items;
    }
    transporteroptions(){
        var items=[]
        var transporters = this.state.transporters;
        items.push({value:'all',label:'All'}); 
        if(transporters.length > 0){
            transporters.forEach(item=>{
                items.push({value:item,label:item}); 
            })
        }
        //console.log("Transps ", items)
        return items;
    }
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
            overly:'show-n',
            breaksSlider : "",
            selectedRow : [],
		});
		
    }
    onClickGetLoadingData(event){
        event.preventDefault();
        this.setState({
            loadshow:'show-m',
            overly:"show-m",
            rowData : [],
            plantwisedrilldown:[],
            transwisedrilldown:[]
        });
        var startDate = this.state.startDate;
        var endDate = this.state.endDate;
        if(startDate != "NaN-NaN-NaN" && startDate != "" && endDate != "NaN-NaN-NaN" && endDate != "" )
        {
            startDate = startDate+" 00:00:00";
            endDate = endDate+" 23:59:59";
        }
        else{
            startDate = "2020-05-01 00:00:00";
            endDate = moment.parseZone().format("YYYY-MM-DD")+" 23:59:59";
        }


        if(this.state.selected_state.length && this.state.selected_state.indexOf("ALL") == -1)
        {
            var states = []
            console.log("this.state.selected_state ", this.state.selected_state)
            this.state.selected_state.map((item) => {
                if(item.value != 'ALL')
                {
                    states.push(item.value)
                }                
            })
            
            console.log("states ", states)
            if(states.length > 0)
            {
                var reqparams = {
                    from_date : startDate,
                    to_date : endDate,
                    local_loads : this.state.selected_local_loads.value,
                    states : JSON.stringify(states)
                }
            }
            else{
                var reqparams = {
                    from_date : startDate,
                    to_date : endDate,
                    local_loads : this.state.selected_local_loads.value
                }
            }
        }
        else
        {
            var reqparams = {
                from_date : startDate,
                to_date : endDate,
                local_loads : this.state.selected_local_loads.value,
            }
        }
        
        console.log(reqparams);
        redirectURL.post("/dashboard/getTurnAroundTimeData",{"formdata":reqparams}).then((resp)=>{
            console.log(resp.data)
            this.setState({
                plant_wise_data:JSON.parse(resp.data.plant_wise_data),
                trans_wise_data:JSON.parse(resp.data.trans_wise_data),
                plant_trans_wise_data:JSON.parse(resp.data.plant_trans_wise_data),
                rowData:JSON.parse(resp.data.truck_wise_data),
                complete_data :JSON.parse(resp.data.complete_data),
            });
            this.formatChartData()
        }).catch((e)=>{
            console.log(e)
        })
    }
    onClickGetTNPData()
    {
        this.setState({
            rowData : [],
            cityWiseSeriesData : [],
            loadshow : "show-m",
            overly : "show-m"
        });
        var consignee_codes=[];
        console.log(this.state.selectedTnpPlant);
        if(this.state.selectedTnpPlant.value != 'all' && this.state.selectedTnpPlant != null && this.state.selectedTnpPlant != "")
        {
            consignee_codes.push(this.state.selectedTnpPlant.value);
            consignee_codes = JSON.stringify(consignee_codes);
        }
        else{
            consignee_codes = ""
        }
        var startDate = this.state.startDate;
        var endDate = this.state.endDate;
        if(startDate != "NaN-NaN-NaN" && startDate != "" && endDate != "NaN-NaN-NaN" && endDate != "" )
        {
            startDate = startDate+" 00:00:00";
            endDate = endDate+" 23:59:59";
        }
        else{
            startDate = "2020-05-01 00:00:00";
            endDate = moment.parseZone().format("YYYY-MM-DD")+" 23:59:59";
        }
        var reqparams = {
            from_date : startDate,
            to_date : endDate,
            consignee_codes : consignee_codes,
            dept_code : this.state.dept_code
        }
        redirectURL.post("/dashboard/getSndUnloadChartData",{"formdata":reqparams}).then((resp)=>{
            var dealer_uld_data = JSON.parse(resp.data.chartdata.dealer_uld_data);
            var top_10_dealer_data = JSON.parse(resp.data.chartdata.top_10_dealer_data);
            this.setState({
                dealer_uld_data : dealer_uld_data,
                rowData : top_10_dealer_data,
                loadshow : "show-n",
                overly : "show-n"
            });
            this.formatTNPData();
        }).catch((e)=>{
            console.log(e);
        })
    }
    showLocation(params){
        this.setState({
            lat : params.data.lat,
            lng : params.data.lng,
            distancefromdealer : params.data.distance_from_dealer_location_km.toFixed(2)+" KM",
            no_of_unloads : params.data.no_of_breaks,
        });
        this.initMapWeather()
    }
    // CUSTOM AUTOMCOMPLETE DROPDOWN
    initialList =(event)=>{
        console.log("intital List")
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.locations;
        dataset = dataset.slice(0,100);
        console.log(dataset);
        this.setState({
            filteredLocationData : dataset,
            showLocationList : "show-m",
        })
    }
    initialPlantList=(event)=>{
        $(".transporter-dropdown").removeClass("show-n");
        var dataset = this.state.transporters;
        dataset = dataset.slice(0,100);
        this.setState({
            filteredPlantData : dataset,
            showPlantList : "show-m",
        })
    }
    handlerForm = (event) => {
        console.log("handler form");
        // let name = event.target.name;
        // let value = event.target.value;
        // this.setState({[name]:value});
        try
        {
            $(".trucks-dropdown").removeClass("show-n");
            var dataset = this.state.locations;
            var filteredResult = dataset.filter(function(e){
                if(e != "" && e != null)
                {
                    return e.toString().toLowerCase().includes($("#inputLocation").val().toLocaleLowerCase());
                }
                
            });
            filteredResult = filteredResult.slice(0,100);
            if(filteredResult.length > 0 )
            {
                this.setState({
                    filteredLocationData : filteredResult.sort(),
                    showLocationList : "show-m",
                })
            }
            else{
                this.setState({
                    filteredLocationData : filteredResult.sort(),
                    showLocationList : "show-n",
                })
            }
            
        }catch(e){}
        
    }
    
    handlerPlantForm = (event) => {
        //console.log("Select", event.target.value);
        // let name = event.target.name;
        // let value = event.target.value;
        // this.setState({[name]:value});
        try
        {
            $(".transporter-dropdown").removeClass("show-n");
            var dataset = this.state.transporters;
            var filteredResult = dataset.filter(function(e){
                if(e != "" && e != null)
                {
                    return e.toString().toLowerCase().includes($("#inputTransporter").val().toLocaleLowerCase());
                }
                
            });
            filteredResult = filteredResult.slice(0,100);
            if(filteredResult.length > 0 )
            {
                this.setState({
                    filteredPlantData : filteredResult.sort(),
                    showPlantList : "show-m",
                })
            }
            else{
                this.setState({
                    filteredPlantData : filteredResult.sort(),
                    showPlantList : "show-n",
                })
            }
            
        }catch(e){}
        
    }

    // END
    render(){
        //console.log("this.state.locations ",this.state.locations)
   
        var taburl = "/snddashboardtemplate";
        var loadingurl='';
        var transiturl='';
        var transporturl='';
        if(this.state.dept_code == "SNDG")
        {
            taburl="/snddashboardtemplate";
            loadingurl=`${process.env.PUBLIC_URL}/sndloadingdashboard`
            transiturl="/sndtransitanalytics";
            transporturl="/sndtransportdashboard";
        }
        if(this.state.dept_code == "LOG-TNP")
        {
            taburl="/tnpdashboardtemplate";
            loadingurl="/tnploadingdashboard";
            transiturl="/tnptransitanalytics";
            transporturl="/tnptransportdashboard";
        }
        if(this.state.dept_code == "LOG-PRT")
        {
            taburl="/prtdashboardtemplate";
            loadingurl="/prtloadingdashboard";
            transiturl="/prttransitanalytics";
            transporturl="/prttransportdashboard";
        }
        if(this.state.dept_code == "LOG-SC")
        {
            taburl="/scdashboardtemplate";
        }
        
        var  hideCity = (this.state.dept_code == "LOG-TNP")?true:false;
        const columnwithDefs=[
            {
                headerName : "Truck No",
                field : "truck_no",
            },
            // {
            //     headerName : "Consignment Codes",
            //     field : "consignment_codes",
            //     width :350,
            //     valueGetter : function(params)
            //     {
            //         return joinConsignments(params.data.consignment_codes);
            //     }
            // },
            {
                headerName : "Transporter Name",
                field : "transporter_name",
             
            },
            {
                headerName : "Transporter Code",
                field : "parent_transporter_code",
                
            },
            {
                headerName : "No. of Trips",
                field :"no_of_trips",
                // width :100,
                filter : true,
                editable : false,
                resizable : true,
            },
            {
                headerName : "Truck Turn Around Time (Days)",
                field : "average_tat",
                filter : true,
                editable : false,
                resizable : true,
                valueGetter : function(params)
                {
                    return Math.round(params.data.average_tat);
                }
            }
        ]
        const complete_column_defs = [
            {
                headerName : "Truck No",
                field : "truck_no",
                width :130
            },
            {
                headerName : "Consignment Codes",
                field : "consignment_codes",
                width :300,
                valueGetter : function(params)
                {
                    return joinConsignments(params.data.consignment_codes);
                }
            },
            {
                headerName : "Transporter Code",
                field : "parent_transporter_code",
                width :130
            },

            {
                headerName : "Transporter Name",
                field : "transporter_name",
                width :130
            },
            {
                headerName : "Gate in Time",
                field : "gate_in_time",
                width :130,
                valueGetter : function(params)
                {
                    return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time)
                },
                comparator: dateComparator,
                //filter: "agDateColumnFilter",
                // filterParams: {
                //     // browserDatePicker: true,
                //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                //     // provide comparator function
                //     comparator: function(filteredDate,cellValue,secondDate) {
                //         cellValue = cellValue.replace(/\//g,"-")
                //         if(cellValue != "" && cellValue != " "){
                //             cellValue = cellValue.split(" ")[0].split("-");
                //             cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                //             var cellDate = new Date(cellValue);
                //             if(filteredDate.getMonth() < 10){
                //             var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             else{
                //                 var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             if(filterValue.split("-")[2].length < 2)
                //             {
                //                 var temp = filterValue
                //                 filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
                //             }
                //             var filteredDate = new Date(filterValue);
                //             cellDate = cellDate.getTime();
                //             filteredDate = filteredDate.getTime();
                //             console.log(cellDate,filteredDate);
                //             if(cellDate === filteredDate)
                //             {
                //                 return 0;
                //             }
                //             if(cellDate < filteredDate){
                //                 return -1;
                //             }
                //             if(cellDate > filteredDate)
                //             {
                //                 return 1;
                //             }
                //         }
                //     }
                // },
            },
            {
                headerName : "Gate out Time",
                field : "gate_out_time",
                width :130,
                valueGetter : function(params)
                {
                    return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time)
                },
                comparator: dateComparator,
                //filter: "agDateColumnFilter",
                // filterParams: {
                //     // browserDatePicker: true,
                //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                //     // provide comparator function
                //     comparator: function(filteredDate,cellValue,secondDate) {
                //         cellValue = cellValue.replace(/\//g,"-")
                //         if(cellValue != "" && cellValue != " "){
                //             cellValue = cellValue.split(" ")[0].split("-");
                //             cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                //             var cellDate = new Date(cellValue);
                //             if(filteredDate.getMonth() < 10){
                //             var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             else{
                //                 var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             if(filterValue.split("-")[2].length < 2)
                //             {
                //                 var temp = filterValue
                //                 filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
                //             }
                //             var filteredDate = new Date(filterValue);
                //             cellDate = cellDate.getTime();
                //             filteredDate = filteredDate.getTime();
                //             console.log(cellDate,filteredDate);
                //             if(cellDate === filteredDate)
                //             {
                //                 return 0;
                //             }
                //             if(cellDate < filteredDate){
                //                 return -1;
                //             }
                //             if(cellDate > filteredDate)
                //             {
                //                 return 1;
                //             }
                //         }
                //     }
                // },
            },
            {
                headerName : "Consigner Code",
                field : "consigner_code",
                width :130
            },
            {
                headerName : "Consignee Code",
                field : "consignee_code",
                width :130
            },
            {
                headerName : "Consignee Name",
                field : "consignee_name",
                width :130
            },
            {
                headerName : "Consignee State",
                field : "consignee_state",
                width :130
            },
            {
                headerName : "Consignee City",
                field : "consignee_city",
                width :130
            },
            
            
        ]
        const dStyles={
            width:'100%',
            height:'450px',
            // marginTop : "20px"
        }
        
        // AUTOCOMPLETE 
        var listData = [] 
        try{
            this.state.filteredLocationData.map(function(e){
                listData.push(<li key={e} className="dropdown-truck-no">{e}</li>)
                })
        }catch(e){}
        var plantListData = [] 
        try{
            this.state.filteredPlantData.map(function(e){
                plantListData.push(<li key={e} className="dropdown-transporter-no">{e}</li>)
                })
        }catch(e){}
        //END


        if(this.state.dept_code == "LOG-PRT")
        {
         var dailyurl = "/prtdailyrunningdashboard";
        }
        else
        {
          var dailyurl = "/dailyrunningdashboard";
        }
        return ( 
        		<div className="col-xl-12 col-lg-12">
                    
                    <div className={"col-xl-12 col-lg-12 tab- pt-20px whiteRow"}>		
                        <div className="col-xl-12 col-lg-12">		            	
                            <h5>
                                 <i className="icofont icofont-dashboard-web cus-i"></i> <span>Turn Around Time</span>
                            </h5>                    
                        </div>
                        {/* <div className=" col-xl-12 col-lg-12 mt-35p">
                            <UnloadColumnchart 
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            //locations={this.state.locations}
                            //transporters={this.state.transporters}
                            dept_code={this.state.dept_code}
                            />
                        </div> */}
                        <div className=" col-xl-12 col-lg-12 mt-35p">
                            <div className="row col-xl-12 col-lg-12">
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>Select Load Type: </label>
                                    <Select placeholder={"All"} 
                                        isMulti={false} 
                                        value={this.state.selected_local_loads} 
                                        className="border-radius-0" 
                                        onChange={this.onSelectLoads.bind(this)} 
                                        style={{borderRadius:"0px"}} 
                                        options={[{value:2,"label":"ALL"},{value:1,"label":"Local Loads"},{value:0,"label":"Non Local Loads"}]} 
                                        required />
                                </div> 
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>Select State : </label>
                                    <Select placeholder={"ALL"} 
                                        isMulti={true} 
                                        className="border-radius-0" 
                                        onChange={this.onSelectState.bind(this)} 
                                        style={{borderRadius:"0px"}} 
                                        options={this.state.states_list} 
                                        required />
                                </div>                   
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>Start Date: </label>
                                    <Datetime value={this.state.startDate} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete:'off' }}
                                    dateFormat="YYYY-MM-DD" 
                                    name="startDate" 
                                    onChange={this.handlerStartDateTime.bind(this)} />
                                </div>
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>End Date: </label>
                                    <Datetime value={this.state.endDate} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete:'off' }}
                                    dateFormat="YYYY-MM-DD" 
                                    name="endDate" 
                                    onChange={this.handlerEndDateTime.bind(this)} />   
                                </div>
                                {/* <div className="form-gplantwiseloadingroup col-xl-2 col-lg-2">
                                    <label>Select State : </label>
                                        <Select placeholder={"All"} 
                                        isMulti={false} 
                                        value={this.state.selectedState} 
                                        className="border-radius-0" 
                                        onChange={this.onSelectState.bind(this)} 
                                        style={{borderRadius:"0px"}} 
                                        options={this.stateOptions()} 
                                        required />
                                </div>
                                <div className="form-gplantwiseloadingroup col-xl-2 col-lg-2">
                                    <label>Select City: </label>
                                        <Select placeholder={"All"} 
                                        isMulti={false} 
                                        value={this.state.location} 
                                        className="border-radius-0" 
                                        onChange={this.onSelectLocation.bind(this)} 
                                        style={{borderRadius:"0px"}} 
                                        options={this.locationoptions()} 
                                        required />
                                </div>

                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>Choose Dealer : </label>
                                    <Select 
                                    placeholder={"All"} 
                                    isMulti={false} 
                                    value={this.state.transporter} 
                                    className="border-radius-0" 
                                    onChange={this.onSelectTransporter.bind(this)} 
                                    style={{borderRadius:"0px"}} 
                                    options={this.transporteroptions()} required />
                                    
                                </div> */}
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>&nbsp;</label><br />
                                    <button type="button" className="btn btn-success" onClick={this.onClickGetLoadingData.bind(this)}>Submit</button>
                                </div>
                            </div> 
                        </div>
                        <div className={"row mt-40p "}>
                            <div className="col-xl-12">
                                {(Object.values(this.state.plantwisedrilldown).length > 0)?
                                    <SndUnloadingColumnChart
                                    title={"Avg. Turn Around Time / Plant"}
                                    subtitle={""}
                                    context={this}
                                    series={this.state.plantwisechartseries}
                                    seriesName={"Avg. Turn Around Time"}
                                    categories={[]}
                                    yaxistitle="Avg. Turn Around Time (Days)"
                                    drilldownseries={this.state.plantwisedrilldown}
                                    // dept_code={this.state.dept_code}
                                    />
                                :""}
                            </div>
                        </div>
                        <div className={"row mt-40p "}>
                            <div className="col-xl-12">
                                {(Object.values(this.state.transwisedrilldown).length > 0)?
                                <TATColumnChart
                                title={"Avg. Turn Around Time / Transporter"}
                                subtitle={""}
                                context={this}
                                series={this.state.transwisechartseries}
                                seriesName={"Avg. Turn Around Time"}
                                categories={[]}
                                yaxistitle="Avg. Turn Around Time (Days)"
                                drilldownseries={this.state.transwisedrilldown}
                                // dept_code={this.state.dept_code}
                                />
                                :""}
                            </div>
                        </div>
                            
                        
                        <div className={"row mt-40p "}>
                            <div className="col-xl-12">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Truck Wise Data</span>
                                
                                </h5>
                                <div id="myGrid" style={{width:"100%",height:"460px"}} className="ag-theme-balham">  
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        suppressRowClickSelection={true}
                                    />

                                </div>
                            </div>
                        </div>

                        <div className={"row mt-40p "}>
                            <div className="col-xl-12">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>All Trips Data</span>
                                
                                </h5>
                                <div id="myGrid" style={{width:"100%",height:"460px"}} className="ag-theme-balham">  
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={complete_column_defs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.complete_data}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        suppressRowClickSelection={true}
                                    />

                                </div>
                            </div>
                        </div>
                                            
	                </div> 

                    <div className={"dataLoadpage " +(this.state.loadshow)}></div>
                    <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                        <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                    </div>
                    <div className={"overlay "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
	           </div>
			);
		}
}



function loadScriptWeather(url){
    var index  =window.document.getElementsByTagName("script")[1]
      var script = window.document.createElement("script")
      script.src=url
      script.async=true
      script.defer = true
      index.parentNode.insertBefore(script,index)
  }

//   $(document).ready(function(){
//     $(document).on("click",".dropdown-truck-no",function(){
//         var vals = $(this).text();
//          $("#inputLocation").val(vals);
//          $(".trucks-dropdown").removeClass("show-m");
//          $(".trucks-dropdown").addClass("show-n");
//     });

//     $("body").on("click",function(e){
//         var container = $("#inputLocation");
//         var plant = $("#inputTransporter");
//         if(!container.is(e.target) && !plant.is(e.target))
//         {
//             try{
//                 if($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown")
//                 {
//                     $(".trucks-dropdown").removeClass("show-m");
//                     $(".trucks-dropdown").addClass("show-n");
//                 }
//             }catch(e){}
            
//         }
        
        
//     })
// })

// $(document).ready(function(){
//     $(document).on("click",".dropdown-transporter-no",function(){
//         var vals = $(this).text();
//          $("#inputTransporter").val(vals);
//          $(".transporter-dropdown").removeClass("show-m");
//          $(".transporter-dropdown").addClass("show-n");
//     });

//     $("body").on("click",function(e){
//         var container = $("#inputTransporter");
//         var location = $("#inputLocation");
//         if(!container.is(e.target) && !location.is(e.target))
//         {
//             try{
//                 if($(".transporter-dropdown").attr("class").includes("show-m") || $(".transporter-dropdown").attr("class") == "transporter-dropdown")
//                 {
//                     $(".transporter-dropdown").removeClass("show-m");
//                     $(".transporter-dropdown").addClass("show-n");
//                 }
//             }catch(e){}
            
//         }
        
        
//     })
// })


function convertFloatPrecision(value)
{
    return value.toFixed(2)
}

function joinConsignments(consignments)
{
    consignments = consignments.map(function(e){return e}).join(", ");
    return consignments;
}

function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}
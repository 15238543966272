"use strict";

import React, { Component, useDebugValue } from "react";
import { render } from "react-dom";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MenuModule } from "@ag-grid-enterprise/menu";
import Select from "react-select";
import DrawMap from "../common/drawmap";
import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import MarkDeliver from "../layouts/markDeliveredButton.js";
import ProgressBar from "@ramonak/react-progress-bar";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from "react-bootstrap-sweetalert";
import CSVFileValidator from "csv-file-validator";
import CountUp from "react-countup";
import UpdateButton from "./updatelmstates";
import * as Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import $ from "jquery";
import LedScreenPieChartComponent from "./ledscreenpirchartcomponent";
import ColumnGroup from "./columngroupComponent";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

import {
  getDDMMYYYY,
  getHyphenYYYYMMDD,
  getHyphenDDMMMYYYYHHMM,
  getHyphenDDMMMYYYY,
  getHyphenYYYYMMDDHHMMSS,
  getHyphenDDMMYYYYHHMMSS,
  getDDMMYYYYHHMMSS,
  getYYYYMMDD,
  getYYYYMMDDHHMMSS,
} from "../common/utils";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var drawingManager;
var redirectURL = require("../redirectURL");
var moment = require("moment");
var filecontent = "";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;
var infoBox = require("../common/google-infowindow");
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var marker;
var markers = [];
var circle;
var selectlat;
var selectlng;
var allcords = [];
var map;
var doOnce = true;
var rad = function(x) {
  return (x * Math.PI) / 180;
};
var rlatitude;
var rlongitude;
var globForecastDay="Today";
var mapZoom=5;
var fencingLength = 0;
var geoFencingArea;
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};

export default class LedRailwayLogisticsscreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showform: "show-m",
      loadshow: "show-n",
      showrefresh: "show-n",
      sliderTranslate: "",
      showdata: this.displayData,
      modules: AllModules,
      radius: "15",
      rlatitude: "",
      rlongitude: "",
      latitude: "",
      longitude: "",
      defaultradius: 0,
      coordinates: [],
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      contentString: "testing",
      mwidth: "col-xl-12 col-lg-12",
      dept_code: "",
      defaultColDef: {
        
        
        sortable: true,
        filter: true,
        //editable: true,
        resizable: true,
      },
      rowData: [],

      rowModelhighchartoptionsType: "enterprise",
      rowGroupPanelShow: "always",
      animateRows: true,
      debug: true,
      showToolPanel: false,
      rowSelection: "single",
      context: { componentParent: this },
      loadshow: "show-n",
      overly: "show-n",
      loadingCellRenderer: "customLoadingCellRenderer",
      loadingCellRendererParams: { loadingMessage: "One moment please..." },
      rowModelType: "serverSide",
      paginationPageSize: 50,
      cacheBlockSize: 50,
      maxBlocksInCache: 2,
      totalSales: 0,
      totalDispatches: 0,
      railShare: 0,
      month_target: 0,
      actual_till_date: 0,
      achievement: 0,
      balance_days: 0,
      daily_target: 0,
      current_rail_dispatch_fy: 0,
      pending_for_exit: 0,
      Pending_for_rail_out: 0,
      rail_in_transit: 0,
      last_mile: 0,
      delivered: 0,
      otwrtsr: 0,
      otwrtrtt: 0,
      otwrtrrat: 0,
      pagetitle: "Rake Positions",
      plan_month: "",
      stockData: [],
      statecheck: [],
      chartSeriesData: [],
      chartSeriesCategories: [],
      to_current_Date: "",
      from_current_Date: "",
      completed_days: 0,
      intransitRakeList: [],
      invoice_status_data:[],
      export_achievement:0,
      domestic_achievement:0,
      total_achievement:0,
      domestic_monthly_target:70000,
      export_monthly_target:15000,
      total_monthly_target:0,
      total_monthly_achievement:0,
      remaining_days:1,
      stock_yard_list:[],
      stock_status_data:[],
      lastRunTime : "",
      gridHeaderName: "",
      detailedWeatherMap:0,
      scrowData:[],
      updated_time:"",
      markersArr:[],
      open: false,
      currentScreenIndex: 2
    };
  
  }


  autororate(){ 
    console.log(localStorage.getItem("username"),'username')
    if(localStorage.getItem("username") == "LED USER"){
     this.rotationInterval = setInterval(this.rotateScreens, 60000);
    }
  
  }
 
  componentWillUnmount() {
     clearInterval(this.rotationInterval);
   }
 
   rotateScreens = () => {
  
    window.location.href="/ledrailwaylogisticsscreen6"
  }

  getLatestValues() {
    redirectURL
      .post("/consignments/getLatestRailStatusValuesToday")
      .then((response) => {
        // console.log(response.data,"214")
        var total_share_value = parseInt(
          response.data.total_sales_till_today
        );
        var total_dispatches_tr = this.state.actual_till_date;
        var rail_share_percentage =
          (total_dispatches_tr / total_share_value) * 100;
        rail_share_percentage = rail_share_percentage.toFixed(2);
        this.setState({
          totalSales: response.data.total_sales_till_today,
          totalDispatches: response.data.total_rail_sales_till_today,
          railShare: rail_share_percentage,
          // loadshow: "show-n",
          // overly: "show-n",
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  logPageView = () => {
    try {
      if (googleAnalytics.page.enableGA) {
        googleAnalytics.logPageView();
        let eventGridAction =
          googleAnalytics.page.actionType.gridAction +
          " - " +
          window.location.pathname;
        let eventCounterAction =
          googleAnalytics.page.actionType.counterAction +
          " - " +
          window.location.pathname;
        let eventFormAction =
          googleAnalytics.page.actionType.formAction +
          " - " +
          window.location.pathname;
        this.setState({
          eventGridAction: eventGridAction,
          eventCounterAction: eventCounterAction,
          eventFormAction: eventFormAction,
        });
      } else {
        console.log("GA disabled...");
      }
    } catch (error) {
      console.error("Error occured while logging to GA, error = ", error);
    }
  };

  async componentDidMount() {
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    var token  = window.atob(localStorage.getItem('_t'))
    var email = localStorage.getItem("email")
    // if(token != undefined && token != null && token != "" && email != undefined && email != null && email != ""){
    //   await redirectURL.post("/consignments/chekingLoggedinUser",{"token":token,"email":email})
    //   .then(async(response)=>{
    //       if(response.data.message == "Send to login page"){
    //           window.location.href = "/"
    //       }else{
      await redirectURL.post("/consignments/getexportachievement").then((response)=>{
        console.log(response.data.data,"222");
        var total1=this.state.export_monthly_target+this.state.domestic_monthly_target
        var total2=response.data.data.export+response.data.data.domestic
        console.log(total2,response.data.data.export)
        this.setState({
          export_achievement:response.data.data.export,
          domestic_achievement:response.data.data.domestic,
          total_monthly_target:total1,
          total_monthly_achievement:total2,
          updated_time:getHyphenDDMMMYYYYHHMM(response.data.data.update_time)
        })
        
      }).catch(function(error){
          console.log(error);
      })
      


         await redirectURL.post("/consignments/getstockyardDatafromsndplanningstatus").then(async(response)=>{
            console.log(response.data,"251")
          
            const newData=(response.data).map((each)=>{
              if (each.plant_name==="Bangalore"){
                var stock_capacity =(each.stock/8000)*100
                const newEach={...each,capacity:8000,plant_name:"BANGLORE",stock_capacity_data:parseFloat(stock_capacity.toFixed(1))}
                return newEach 
              }else if (each.plant_name==="Gurgaon"){
                var stock_capacity =(each.stock/6050)*100
                const newEach={...each,capacity:6050,plant_name:"GURGAON + TPA",stock_capacity_data:parseFloat(stock_capacity.toFixed(1))}
                return newEach 
              }else if (each.plant_name==="Gujarat"){
                var stock_capacity =(each.stock/22000)*100
                const newEach={...each,capacity:22000,plant_name:"GUJARAT",stock_capacity_data:parseFloat(stock_capacity.toFixed(1))}
                return newEach 
              }else if (each.plant_name==="Manesar"){
                var stock_capacity =(each.stock/23400)*100
                const newEach={...each,capacity:23400,plant_name:"MANESAR",stock_capacity_data:parseFloat(stock_capacity.toFixed(1))}
                return newEach 
              }else{
                return each
              }
            })

            const rrr=newData.filter(each=>(each.plant_name!=="Siliguri"))

            console.log(rrr,newData,"247");

            this.setState({stock_yard_list:rrr})
            console.log(this.state.stock_yard_list)
        }).catch(function(error){
            console.log(error);
        })

          await redirectURL.post("/consignments/getmonthlytargets").then((response)=>{
            console.log(response.data,"256");

            this.setState({domestic_monthly_target:response.data[0].monthly_domestic_target,export_monthly_target:response.data[0].monthly_export_target,total_monthly_target:response.data[0].total_target})
            
          }).catch(function(error){
            console.log(error);
          })
    


              var plan_month =moment.parseZone().format("YYYY-MM");
              var params = {
                  plan_month: plan_month
              }

             await redirectURL.post("/consignments/getRailTargetByMonthforLED", params).then((response) => {
                //console.log("response", response.data);
                var railTargetData = response.data.railtarget;
                var wkDates = railTargetData[0].working_days;
                var workingDates = [];
                var balanceDates = [];
                var completedDates = [];
                wkDates.map(function (wkd) {
                    workingDates.push(moment.parseZone(new Date(wkd)).format("YYYY-MM-DD") + " 00:00:00");
                });
                //console.log(workingDates,"workingDates")
                var currentDate = moment.parseZone().format("YYYY-MM-DD") + " 00:00:00";
                currentDate = currentDate.split("+")[0];
                // console.log(currentDate,"currentDate")
                wkDates.map(function (wde) {
                    var comparision = moment(wde).isSameOrAfter(currentDate);
                    // console.log("moment(wde)",moment(wde))
                    // console.log("comparision",comparision)
                    if (comparision == true) {
                        balanceDates.push(wde);
                    }
                    else {
                        completedDates.push(wde);
                    }
                });
                
                this.setState({remaining_days:balanceDates.length})
                console.log(completedDates.length,"260")
              
            }).catch((e) => {
                console.log(e);
            })


        await redirectURL.post("/weather/getWeatherData").then(async(response)=>{
          console.log(response.data);
          const uniqueDAta = [...new Set(response.data.ForecastData.map(item => item.description))];
          console.log(response.data.ForecastData.length,"uniqueDAta")
          
          this.setState({
            weatherData : response.data.ForecastData,
            statesSubDivisions: response.data.statesSubDivisions
          })
         await redirectURL.post("/weather/getPlants").then((response)=>{
            
            var path = window.location.href.split("//")[1].split("/")[0];
            const filteredPlants = response.data.filter(plant => plant.plant_name !== "TKAP-Dharuhera");

            this.setState({
                weatherPlants: filteredPlants,
                forecastDay: "Today",
                path: path,
                overly: "show-m",
                loadshow: "show-m"
            });
            this.renderMapWeather();
          })
          
        }).catch(function(error){
          console.log(error);
        })

       await redirectURL.post("/weather/getFloodData").then((response)=>{
            console.log(response.data);
            this.setState({
              floodData : response.data,
            })
        }).catch(function(error){
            console.log(error);
        })
        

        
        var dept_code = "";
        console.log(this.props.match.path,"path")
        var screenView = "";
      
        if(this.props.match.path == "/ledrailwaylogisticsscreen3")
        {
            var screen = "user";

            if(this.props.match.path == "/ledrailwaylogisticsscreen3")
            {
                dept_code = "SNDG";
            }else{ dept_code = "LOG-SC"}
            this.setState({
                suppressClickEdit: false,

            })
        }
        
                      // console.log(screenView, "screenView")
                      this.setState({
                          dept_code:  dept_code,
                          overly: "show-m",
                          loadshow: "show-m"
                      })
                      var sort_order = {
                          "Gurgaon": 1, "SMG Plant": 2, "Manesar": 3, "IMT Manesar": 4, "TVP Bangalore": 5,
                          "TVP Nagpur": 6, "Siliguri": 7, "Mundra Port": 8, "Mumbai Port": 9
                      }

                      let fleetNos={"TVP Banglore":0,"Gurgaon":0,"Manesar":0,"Gujarat":0,"TVP Siliguri":0,"TVP Nagpur":0,"Bidadi":0}
                      await redirectURL.post("/dashboard/getstockDataPlants",{"screen": screen}).then((response)=>{
                        var data = JSON.parse(response.data.trucks_data);
                        console.log(data,"182");
                        setTimeout(() => {
                         this.setState({
                          markersArr:data
                         })
                          console.log(this.state.markersArr,'markersarr')
                          this.trucksMarking()
                        }, 5000);
                        var newData=data.map((each)=>{
                          if (each.plant_code==="BP"){
                            if (fleetNos["Bidadi"]==0){
                              fleetNos["Bidadi"]=1
                            }else{
                              fleetNos["Bidadi"]=fleetNos["Bidadi"]+1
                            } 
                          }else if (each.plant_code==="B"){
                              if (fleetNos["TVP Banglore"]==0){
                                fleetNos["TVP Banglore"]=1
                              }else{
                                fleetNos["TVP Banglore"]=fleetNos["TVP Banglore"]+1
                              }
                          }else if(each.plant_code==="F"){
                            if (fleetNos["Gurgaon"]==0){
                              fleetNos["Gurgaon"]=1
                            }else{
                              fleetNos["Gurgaon"]=fleetNos["Gurgaon"]+1
                            }
                        // }else if(each.plant_code==="M"){
                        //   if (fleetNos["Manesar"]==0){
                        //     fleetNos["Manesar"]=1
                        //   }else{
                        //     fleetNos["Manesar"]=fleetNos["Manesar"]+1
                        //   }
                      }else if(each.plant_code==="G"){
                        if (fleetNos["Gujarat"]==0){
                          fleetNos["Gujarat"]=1
                        }else{
                          fleetNos["Gujarat"]=fleetNos["Gujarat"]+1
                        }
                    }else if(each.plant_code==="I"){
                      if (fleetNos["TVP Siliguri"]==0){
                        fleetNos["TVP Siliguri"]=1
                      }else{
                        fleetNos["TVP Siliguri"]=fleetNos["TVP Siliguri"]+1
                      }
                  }else if(each.plant_code==="N"){
                    if (fleetNos["TVP Nagpur"]==0){
                      fleetNos["TVP Nagpur"]=1
                    }else{
                      fleetNos["TVP Nagpur"]=fleetNos["TVP Nagpur"]+1
                    }
                }
                        })
                      
                        console.log(fleetNos,"415")
                    const newFleet=Object.entries(fleetNos)
                      // var newData=data.map(each=>{
                      //   if (each.railyard_name=="D22 Bengaluru (Bidadi)"){
                      //     return ({...each,railyard_name:"Bidadi"})
                      //   }
                      //   return each
                      // })
                    
                      // console.log(newData,"192")
                    
                        this.setState({

                          scrowData:newFleet,
                          overly: "show-m",
                          loadshow: "show-m"
                    
                      })
                    
                      
                        
                    })
                    

                      // redirectURL.post("/dashboard/getSafetyReport",{"screen": screen}).then((response)=>{
                      //     var data = response.data;
                      //     console.log(data);
                      //     var check = data.map(function(e){
                      //         e['sort_order'] = sort_order[e.plant_location]
                      //     })
                      //     Promise.all([check]).then(()=>{
                      //         console.log(data);
                      //         data = data.sort(function(a, b){return a['sort_order'] - b['sort_order']})
                      //         var snddata = data.filter(function(e){
                      //             return e.dept_code == "SNDG"
                      //         });
                      //         var scdata = data.filter(function(e){
                      //             return e.dept_code != "SNDG"
                      //         });
                      //         console.log(screen, dept_code, "detcode")
                      //         this.setState({
                      //             rowData: snddata,
                      //             scrowData: scdata,
                      //             screenType: screen,
                      //             dept_code: dept_code,
                      //             screenView: screenView,


                      //         })
                      //     })
                          
                      // })


                    await this.getLatestValues();

                    loadDateTimeScript();
                    this.logPageView(); // Log page view to GA
                    if (this.props.match.path == "/sndplanningstatusmgmt") {
                      var userType = "management";
                    }
                    if (this.props.match.path == "/ledrailwaylogisticsscreen3") {
                      var userType = "user";
                    }
                    var screenView = "";
                    if (this.props.match.path == "/mgmtplanningstatus") {
                      var screenView = "Mobile";
                      var userType = "management";
                    }
                    this.setState({
                      userType: userType,
                      screenView: screenView,
                    });
                    this.sndPlanningStatus(userType);



                    await redirectURL.post("/dashboard/getstockDataforPlants").then((response)=>{
                      var data = response.data;
                      // console.log(data,"346");
                    
                      this.setState({
                        invoice_status_data:data
                })

                    // console.log(this.state.invoice_status_data)
                      
                  })


                  var lastRunTime = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
                      // this.setState({
                      //     lastRunTime:lastRunTime,
                      //     overly: "show-n",
                      //     loadshow: "show-n"
                      // })
      //             }
      //     })
      // }else{
      //   window.location.href = "/"
      // }

 this.autororate()
 }


  renderMapWeather = () => {  
    // this.setState({
    //   overly: "show-n",
    //       loadshow: "show-n"
    // })  	
    loadScriptWeather("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMapWeather");
    window.initMapWeather = this.initMapWeather
   }

   trucksMarking = () =>{
   
    this.renderMapWeather();
   }

   initMapWeather = () => {

    try {
      var currentwindow;
      var map_type = window.google.maps.MapTypeId.HYBRID;
      var map = new window.google.maps.Map(document.getElementById('mapweather'), {
        zoom: 8,
        center: new window.google.maps.LatLng(23.581046, 78.776465),
        mapTypeId: map_type,
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: window.google.maps.ControlPosition.TOP_RIGHT
        },
        disableDefaultUI: true,
        fullscreenControl: true,
        zoomControl: true,
        panControl: false,
        restriction: {
          latLngBounds: {
            north: 37.084107,
            south: 6.753515,
            east: 97.402561,
            west: 68.111378,
          },
        },
      });
  
      var filterRecDate = "";
  
      if (globForecastDay == "Today") {
        filterRecDate = moment.parseZone(new Date()).format("DD-MM-YYYY");
      } else {
        filterRecDate = moment.parseZone(new Date(+new Date() + 86400000)).format("DD-MM-YYYY");
      }
  
      if (this.state.weatherPlants.length > 0 && this.state.detailedWeatherMap == 0) {
        this.state.weatherPlants.forEach(function (params) {
          var plant = {
            url: require("../../assets/icons/" + params.icon),
            scaledSize: new window.google.maps.Size(24, 24),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(0, 0)
          };
          var coords = JSON.parse(params.coordinates);
          var marker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(coords.lat, coords.lng),
            map: map,
            icon: plant,
            title: params.plant_name
          });
  
          var cityCircle = new window.google.maps.Circle({
            strokeColor: '#9fc9b9',
            strokeOpacity: 0.3,
            strokeWeight: 2,
            fillColor: '#9fc9b9',
            fillOpacity: 0.6,
            map: map,
            center: coords,
            radius: 50 * 1000
          });
        });
      }
  
      console.log(this.state.markersArr, "640")
      this.setState({
        overly: "show-n",
        loadshow: "show-n"
      });
  
    
  
      this.state.markersArr.map((each) => {
        // Check if plant_code is not "M"
        if (each.plant_code !== "M") {
          var marker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(each.latitude, each.longitude),
            map: map,
            icon: require("../../assets/icons/truck_null.png"),
            title: each.state,
          });
          markers.push(marker);
          var contentarr = [];
          contentarr.push({ key: "Transporter Name", value: each.transporter_name });
          contentarr.push({ key: "State", value: each.state });
      
          var contentString = infoBox(marker.icon, each.truck_no, contentarr, "");
      
          var infowindow = new window.google.maps.InfoWindow({
            content: contentString,
          });
          marker.addListener("click", function () {
            infowindow.setContent(contentString);
            infowindow.open(map, marker);
          });
      
          marker.setMap(map);
          window.google.maps.event.addListener(marker, "mouseout", function () {
            infowindow.close();
          });
        }
      });
      // console.log(map,'===',marker)
      const markerCluster = new MarkerClusterer({ map, markers });
     
      var bounds = new window.google.maps.LatLngBounds();
  
      bounds.extend(new window.google.maps.LatLng(4.150481109730182, 66.41504834827121));
      bounds.extend(new window.google.maps.LatLng(39.351600, 66.951691));
  
      map.fitBounds(bounds);
  
    } catch (e) { }
  }
  onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
	   this.setState({ open: false });
	 };
    changeForecastData = (event) =>{
        this.setState({
          forecastDay : event.value,
          overly: "show-m",
          loadshow: "show-m"
        });
        globForecastDay = event.value;
        // console.log(event.value);
        this.renderMapWeather()
          // this.initMapWeather()
      } 
    //   onChangeForecastType(e){
    //     if(e == 1)
    //     {
    //       this.setState({
    //         overly: "show-m",
    //         loadshow: "show-m"
    //       });
    //         redirectURL.post("/weather/getFloodData").then((response)=>{
    //             console.log(response.data);
    //             this.setState({
    //               floodData : response.data,
    //               overly: "show-n",
    //               loadshow: "show-n"
    //             })
    //         }).catch(function(error){
    //             console.log(error);
    //         })
    //     }
    //     this.setState({
    //       detailedWeatherMap: e,
    //       forecastTypeClass: e,
    //       floodWeatherMap: e,
    //       overly: "show-m",
    //       loadshow: "show-m"
    //     })
    //     console.log(e, "onchange")
    //     this.renderMapWeather()
    //   }


      onChangeDomesticMonthlyTarget=(event)=>{
        // console.log(typeof(parseInt(event.target.value)))
        // if (Number.isInteger(event.target.value)){
        var total1=parseInt(event.target.value)+parseInt(this.state.export_monthly_target)
        
        this.setState({domestic_monthly_target:event.target.value,total_monthly_target:total1})
        // }
      }


      keyDownDt=(event)=>{
        if (event.key==="Enter"){
          event.target.blur();
          const qry={monthly_domestic_target:parseInt(event.target.value),monthly_export_target:parseInt(this.state.export_monthly_target),total_target:parseInt(this.state.total_monthly_target),user_name:localStorage.getItem("username")}
          redirectURL.post("/consignments/setmonthlytargets",qry).then((response)=>{
            console.log(response)
          }).catch(function(error){
            console.log(error);
          })
        }
        console.log(event.key,event.keyCode,"920")
      }

      keyDownEt=(event)=>{
        if (event.key==="Enter"){
          event.target.blur();
          const qry={monthly_domestic_target:parseInt(this.state.domestic_monthly_target),monthly_export_target:parseInt(event.target.value),total_target:parseInt(this.state.total_monthly_target),user_name:localStorage.getItem("username")}
          redirectURL.post("/consignments/setmonthlytargets",qry).then((response)=>{
            console.log(response)
          }).catch(function(error){
            console.log(error);
          })
        }
        console.log(event.key,event.keyCode,"920")
      }


      onChangeExportMonthlyTarget=(event)=>{
        // console.log(event.key,event.keyCode,"920")
       
        var total2=parseInt(event.target.value)+parseInt(this.state.domestic_monthly_target)
        // console.log(typeof(event.target.value))
        // console.log(typeof(this.state.export_monthly_target))
        
        this.setState({export_monthly_target:event.target.value,total_monthly_target:total2})
        
      }

  onClickShowBulkUpload() {
    this.setState({
      sliderbulkupload: "slider-translate-40p",
      input_funds_received: "",
      input_funds_available: "",
      csvcontent: [],
      overly: "show-m",
    });
  }
  sndPlanningStatus(userType) {
    var params = {
      userType: userType,
    };
    redirectURL.post("/consignments/getsndPlanningStatus",params).then((response)=>{
      var stockData = response.data.records;
      stockData=stockData.filter((each)=>each.plant_name!="Siliguri")
      console.log(stockData,"197")
      stockData.map(function(e, index){
          if(index != 0){delete e['funds_available']; delete e['funds_received'] }
      })
      var keyslist = Object.keys(stockData[0]);
      var total_dict = {};
      keyslist.map(function(key){
          if(key != "plant_name" && key != "plant_code" && key != "approved_by" && key != "approval_done_at" 
              && key != "approver_email" && key != "funds_available" && key != "funds_received")
          {
              var temp = 0;
              stockData.map(function(e){
                  temp = temp + e[key]
              });
              total_dict[key] = temp
          }
      })
      total_dict['plant_name'] = "Total";
      total_dict['funds_available'] = "total-"+stockData[0]['funds_available'].toString()
      total_dict['funds_received'] = "total-"+stockData[0]['funds_received'].toString()
      console.log(total_dict)
      stockData.push(total_dict)
      if(userType == "user")
      {
          this.setState({
              gridHeaderName: "Last Data Updated On  "+getHyphenDDMMMYYYYHHMM(stockData[0]['report_generated_at'])
          })
      }
      this.setState({
          stockData : stockData,
          last_approval_date: stockData[0]['approval_done_at'],
          recordsLength : response.data.records.length
      })
  }).catch((e)=>{
      console.log(e);
  })
    // redirectURL
    //   .post("/consignments/getsndPlanningStatus", params)
    //   .then((response) => {
    //     var stockData = response.data.records;
    //     console.log(stockData,"994")
    //     stockData.map(function(e, index) {
    //       if (index != 0) {
    //         delete e["funds_available"];
    //         delete e["funds_received"];
    //       }
    //     });
    //     var keyslist = Object.keys(stockData[0]);
    //     var total_dict = {};
    //     keyslist.map(function(key) {
    //       if (
    //         key != "plant_name" &&
    //         key != "plant_code" &&
    //         key != "approved_by" &&
    //         key != "approval_done_at" &&
    //         key != "approver_email" &&
    //         key != "funds_available" &&
    //         key != "funds_received"
    //       ) {
    //         var temp = 0;
    //         stockData.map(function(e) {
    //           temp = temp + e[key];
    //         });
    //         total_dict[key] = temp;
    //       }
    //     });
    //     total_dict["plant_name"] = "Total";
    //     total_dict["funds_available"] =
    //       "total-" + stockData[0]["funds_available"].toString();
    //     total_dict["funds_received"] =
    //       "total-" + stockData[0]["funds_received"].toString();
    //     console.log(total_dict);
    //     stockData.push(total_dict);
    //     if (userType == "user") {
    //       this.setState({
    //         gridHeaderName:
    //           "Last Data Updated On  " +
    //           getHyphenDDMMMYYYYHHMM(stockData[0]["report_generated_at"]),
    //       });
    //     }
    //     this.setState({
    //       stockData: stockData,
    //       last_approval_date: stockData[0]["approval_done_at"],
    //       recordsLength: response.data.records.length,
    //     });
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  }

  onClickHideAll() {
    this.setState({
      overly: "show-n",
      loadshow: "show-n",
      sliderStateEmailsTranslate: "",
      sliderbulkupload: "",
    });
  }

  onGridReady = (params) => {
    this.gridApi = params.api;

    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
  };

  updateLmStates(params) {
    params["userid"] = localStorage.getItem("userid");
    params["email"] = localStorage.getItem("email");
    params["username"] = localStorage.getItem("username");
    redirectURL
      .post("/consignments/updateRakeUnloadingTerminals", params)
      .then((response) => {
        console.log(response);
        if (response.data.message == "Success") {
          this.setState({
            basicTitle: "Updated Successfully.",
            basicType: "success",
            show: true,
          });
          this.railunloadingterminals(this.state.userType);
        } else {
          this.setState({
            basicTitle: "Failed to update.",
            basicType: "warning",
            show: true,
          });
        }
      });
  }

  approveAllData() {
    var params = {};
    params["userid"] = localStorage.getItem("username");
    params["email"] = localStorage.getItem("email");
    params["username"] = localStorage.getItem("username");
    redirectURL
      .post("/consignments/approvePlanningStatusData", params)
      .then((response) => {
        console.log(response);
        if (response.data.message == "Success") {
          this.setState({
            basicTitle: "Approved.",
            basicType: "success",
            show: true,
          });
          window.location.reload();
        } else {
          this.setState({
            basicTitle: "Failed to approve.",
            basicType: "warning",
            show: true,
          });
        }
      });
  }
  updateAllRows() {
    let rowData = [];
    this.gridApi.forEachNode((node) => rowData.push(node.data));
    console.log(rowData);
    var originalRowData = rowData;

    var check = originalRowData.map(function(e, index) {
      if (index != originalRowData.length - 1) {
        if (e.funds_available == undefined) {
          e["funds_available"] = parseInt(
            originalRowData[0]["funds_available"]
          );
        }
        if (e.funds_received == undefined) {
          e["funds_received"] = parseInt(originalRowData[0]["funds_received"]);
        }
        var updatedata = e;
        var flag = 0;

        for (var key in updatedata) {
          if (
            key != "plant_name" &&
            key != "plant_code" &&
            key != "report_generated_at" &&
            key != "approved_by" &&
            key != "approval_done_at" &&
            key != "approver_email"
          ) {
            if (
              updatedata[key] == "" ||
              updatedata[key] == undefined ||
              updatedata[key] == null
            ) {
              updatedata[key] = 0;
            }
            if (isNaN(parseInt(updatedata[key])) == false) {
              updatedata[key] = parseInt(updatedata[key]);
            } else {
              flag = 1;
              break;
            }
          }
        }

        // if(flag == 0)
        // {
        redirectURL
          .post("/consignments/updatePlanningStatusPlantData", {
            formdata: updatedata,
          })
          .then((response) => {
            //         this.setState({
            //             basicType : "success",
            //             basicTitle: "Updated Successfully",
            //             show: true
            //         })
            //         window.location.reload();
          })
          .catch((e) => console.log(e));

        // }
        // else
        // {
        //     this.setState({
        //         basicType : "warning",
        //         basicTitle: "Invalid Values Entered",
        //         show: true
        //     })
        // }
      }
    });
    Promise.all([check]).then(() => {
      this.setState({
        basicType: "success",
        basicTitle: "Updated Successfully",
        show: true,
      });
      window.location.reload();
    });
  }
  onCellClicked(cell) {
    if (cell.colDef.field == "update" && cell.data.plant_name != "Total") {
      var originalRowData = this.state.stockData;
      if (cell.data.funds_available == undefined) {
        cell.data["funds_available"] = parseInt(
          originalRowData[0]["funds_available"]
        );
      }
      if (cell.data.funds_received == undefined) {
        cell.data["funds_received"] = parseInt(
          originalRowData[0]["funds_received"]
        );
      }
      var updatedata = cell.data;
      var flag = 0;
      console.log(updatedata);
      for (var key in updatedata) {
        if (
          key != "plant_name" &&
          key != "plant_code" &&
          key != "report_generated_at" &&
          key != "approved_by" &&
          key != "approval_done_at" &&
          key != "approver_email"
        ) {
          if (
            updatedata[key] == "" ||
            updatedata[key] == undefined ||
            updatedata[key] == null
          ) {
            updatedata[key] = 0;
          }
          if (isNaN(parseInt(updatedata[key])) == false) {
            updatedata[key] = parseInt(updatedata[key]);
          } else {
            flag = 1;
            break;
          }
        }
      }
      console.log(updatedata);

      console.log(flag, "flag");
      if (flag == 0) {
        redirectURL
          .post("/consignments/updatePlanningStatusPlantData", {
            formdata: updatedata,
          })
          .then((response) => {
            this.setState({
              basicType: "success",
              basicTitle: "Updated Successfully",
              show: true,
            });
            window.location.reload();
          })
          .catch((e) => console.log(e));
      } else {
        this.setState({
          basicType: "warning",
          basicTitle: "Invalid Values Entered",
          show: true,
        });
      }
    }
  }
  changeFileHandler = async (e) => {
    const config = {
      headers: [
        {
          name: "plant_name",
          inputName: "plant_name",
          required: true,
          requiredError: function(headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "stock",
          inputName: "stock",
          required: true,
          requiredError: function(headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "q_hold_stock",
          inputName: "q_hold_stock",
          required: true,
          requiredError: function(headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "dispatchable_stock",
          inputName: "dispatchable_stock",
          required: true,
          requiredError: function(headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "retrieval",
          inputName: "retrieval",
          required: true,
          requiredError: function(headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },

        {
          name: "vehicles_planned",
          inputName: "vehicles_planned",
          required: true,
          requiredError: function(headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "vehicles_invoiced",
          inputName: "vehicles_invoiced",
          required: true,
          requiredError: function(headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "vehicles_exit",
          inputName: "vehicles_exit",
          required: true,
          requiredError: function(headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "opening_pending_exit",
          inputName: "opening_pending_exit",
          required: true,
          requiredError: function(headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "current_pending_exit",
          inputName: "current_pending_exit",
          required: true,
          requiredError: function(headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
      ],
    };
    var data = CSVFileValidator(e.target.files[0], config)
      .then((csvData) => {
        console.log(csvData);
        this.setState({
          csvcontent: csvData.data,
        });
      })
      .catch((err) => {});

    this.setState({
      file: e.target.files[0],
    });

    // if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
    // {

    // }
    // else{
    //     e.target.value = null;
    //     this.setState({
    //         uploadFile:'',
    //         file:"",
    //         show: true,
    //         basicType:'danger',
    //         basicTitle:'Please upload file having extensions .csv only.',
    //     });
    // }
  
  };

  // getStockYardsData=()=>{
  //   const data=this.state.stock_yard_list
  //   const newData=data.map((each)=>{
  //     return (<tr>
  //       <th>{each.plant_name}</th>
  //       <td>0</td>
  //       <td>{each.stock}</td>
  //       <td style={{ padding: 0 }}>
  //         <ProgressBar
  //           height="42px"
  //           borderRadius="0px"
  //           bgColor="#4099ff"
  //           completed={60}
  //         />
  //       </td>
  //       <td>0</td>
  //       <td style={{ padding: 0 }}>
  //         <ProgressBar
  //           height="42px"
  //           borderRadius="0px"
  //           bgColor="#4099ff"
  //           completed={"0"}
  //         />
  //       </td>
  //       <td>0</td>
  //       <td>0</td>
  //       <td>0</td>
  //     </tr>)
  //   })
  //   console.log(newData,"1486")
  //   return newData
  // }


  uploadBulkForceFormHandler(event) {
    event.preventDefault();
    var funds_available = this.state.input_funds_available;
    var funds_received = this.state.input_funds_received;
    var check = this.state.csvcontent.map(function(updatedata) {
      updatedata["funds_available"] = funds_available;
      updatedata["funds_received"] = funds_received;
      for (var key in updatedata) {
        if (
          key != "plant_name" &&
          key != "plant_code" &&
          key != "report_generated_at" &&
          key != "approved_by" &&
          key != "approval_done_at" &&
          key != "approver_email"
        ) {
          if (
            updatedata[key] == "" ||
            updatedata[key] == undefined ||
            updatedata[key] == null
          ) {
            updatedata[key] = 0;
          }
          if (isNaN(parseInt(updatedata[key])) == false) {
            updatedata[key] = parseInt(updatedata[key]);
          }
        }
      }
      console.log(updatedata);
      redirectURL
        .post("/consignments/bulkUpdatePlanningStatusPlantData", {
          formdata: updatedata,
        })
        .then((response) => {})
        .catch((e) => console.log(e));
    });
    Promise.all([check]).then(() => {
      this.setState({
        basicType: "success",
        basicTitle: "Updated Successfully",
        show: true,
      });
      window.location.reload();
    });
  }


  onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  getFleets=(each)=>{
    // console.log(each,'each123')
    if (each[0] !== "Manesar") {
    
    return(
        <>
            <tr>
                <td style={{border:"solid 2px lightgrey",padding:"4px"}}>{each[0]}</td>
                {/* <td style={{border:"solid 2px lightgrey",padding:"4px"}}>{each.vehicle_stocks}</td> */}
                <td style={{border:"solid 2px lightgrey",padding:"4px"}}>{each[1]}</td>
            </tr>
        </>
    )
    }
  }


  render() {

    // const total = this.state.scrowData.reduce((sum, each) => sum + each[1], 0);

    let data=this.state.stock_yard_list
    data=data.filter(each=>each!=="undefined")
    console.log(data,"1560")
    // const newData=data.map((each)=>{
    //   console.log(each,"1561")
    //   return (<tr>
    //     <th>{each.plant_name}</th>
    //     <td>0</td>
    //     <td>{each.stock}</td>
    //     <td style={{ padding: 0 }}>
    //       <ProgressBar
    //         height="42px"
    //         borderRadius="0px"
    //         bgColor="#4099ff"
    //         completed={each.stock_capacity_data}
    //       />
    //     </td>
    //     <td>0</td>
    //     <td style={{ padding: 0 }}>
    //       <ProgressBar
    //         height="42px"
    //         borderRadius="0px"
    //         bgColor="#4099ff"
    //         completed={"0"}
    //       />
    //     </td>
    //     <td>0</td>
    //     <td>0</td>
    //     <td>0</td>
    //   </tr>)
    // })
    // console.log(newData)

    const dStyles={
        width:'100%',
        marginLeft:'20px',
        paddingLeft:'20px',
        marginRight:'0px',
        paddingRight:'0px',
        height:'350px'
    }

    if (this.state.userType == "management") {
      var showTransporter = true;
      var editField = false;
    } else {
      var showTransporter = false;
      var editField = true;
    }
    const rowLength = this.state.stockData.length;


var columnwithDefs = [
    // {
    //     headerName: "update",
    //     field: "update",
    //     width: 130,
    //     cellRenderer: function(params){
    //         if(params.data.plant_name != "Total")
    //         {
    //             return "<button class='btn btn-success' style='padding:0px 25px 5px 27px'>update</button>"
    //         }
    //         else
    //         {
    //             return ""
    //         }
    //     },
    //     hide: showTransporter
    // },
    {
      headerName: "Location",
      field: "plant_name",
      width:69,
      suppressSizeToFit: true,
      filter: true,
      resizable: true,
      editable: false,
      sortable: true,
      cellStyle: { "border-right-color": "#e2e2e2" },
      cellClass: function(params) {
        if (params.data.plant_name != "Total") {
          return ["cellstylegridB", "cellBorder"];
        } else {
          return "";
        }
      },
    },
    {
      headerName: "Funds Available(CR)",
      field: "funds_available",
      width:81,
      suppressSizeToFit: true,
      filter: true,
      resizable: true,
      editable: editField,
      sortable: true,
      enableCellChangeFlash: true,
      cellClass: function(params) {
        if (params.data.plant_name != "Total") {
          return ["cellstylegridY", "pstBg"];
        } else {
          return "textAlignCenter";
        }
      },
      valueGetter: function(params) {
        if (params.data.funds_available != undefined) {
          var funds_available = params.data.funds_available.toString();
        } else {
          var funds_available = params.data.funds_available;
        }
        if (funds_available != undefined) {
          if (funds_available.includes("total")) {
            return funds_available.split("-")[1];
          } else {
            return funds_available;
          }
        } else {
          return "";
        }
      },
      rowSpan: function(params) {
        var check = true;
        if (params.data.funds_available != undefined) {
          check = params.data.funds_available.toString().includes("total");
        } else {
          check = false;
        }
        console.log(rowLength,params.data.funds_available,"1345")
        if (params.data.funds_available != undefined && check == false) {
          return 4;
        } else {
          return 0;
        }
      },
    },
    {
      headerName: "Funds Received(CR)",
      field: "funds_received",
      width:78,
      suppressSizeToFit: true,
      filter: true,
      resizable: true,
      enableCellChangeFlash: true,
      editable: editField,
      sortable: true,
      cellClass: function(params) {
        if (params.data.plant_name != "Total") {
          return ["bglightblue", "pstBg"];
        } else {
          return "textAlignCenter";
        }
      },
      valueGetter: function(params) {
        if (params.data.funds_received != undefined) {
          var funds_received = params.data.funds_received.toString();
        } else {
          var funds_received = params.data.funds_received;
        }
        if (funds_received != undefined) {
          if (funds_received.includes("total")) {
            return funds_received.split("-")[1];
          } else {
            return funds_received;
          }
        } else {
          return "";
        }
      },
      rowSpan: function(params) {
        var check = true;
        if (params.data.funds_received != undefined) {
          check = params.data.funds_received.toString().includes("total");
        } else {
          check = false;
        }
        if (params.data.funds_received != undefined && check == false) {
          return 4;
        } else {
          return 0;
        }
      },
    },
    {
      headerName: "Retrieval",
      field: "retrieval",
     
      filter: true,
      resizable: true,
      editable: editField,
      sortable: true,
      cellClass: function(params) {
        if (params.data.plant_name != "Total") {
          return ["", "cellBorder"];
        } else {
          return "";
        }
      },
    },
    {
      headerName: "Vehicles Planned",
      field: "vehicles_planned",
      
      filter: true,
      resizable: true,
      editable: editField,
      sortable: true,
      cellClass: function(params) {
        if (params.data.plant_name != "Total") {
          return ["", "cellBorder"];
        } else {
          return "";
        }
      },
    },
    {
      headerName: "Vehicles Invoiced",
      field: "vehicles_invoiced",
      
      filter: true,
      resizable: true,
      editable: editField,
      sortable: true,
      cellClass: function(params) {
        if (params.data.plant_name != "Total") {
          return ["", "cellBorder"];
        } else {
          return "";
        }
      },
    },
    {
      headerName: "Vehicles Exit",
      field: "vehicles_exit",
     
      filter: true,
      resizable: true,
      editable: editField,
      sortable: true,
      cellClass: function(params) {
        if (params.data.plant_name != "Total") {
          return ["", "cellBorder"];
        } else {
          return "";
        }
      },
    },
    {
      headerName: "Opening Pending Exit",
      field: "opening_pending_exit",
     
      filter: true,
      resizable: true,
      editable: editField,
      sortable: true,
      cellClass: function(params) {
        if (params.data.plant_name != "Total") {
          return ["", "cellBorder"];
        } else {
          return "";
        }
      },
    },
    {
      headerName: "Current Pending Exit",
      field: "current_pending_exit",
    
      filter: true,
      resizable: true,
      editable: editField,
      sortable: true,
      cellClass: function(params) {
        if (params.data.plant_name != "Total") {
          return ["", "cellBorder"];
        } else {
          return "";
        }
      },
    },
  ];


    var achievement2=(this.state.export_achievement/this.state.export_monthly_target)*100
    var achievement1=(this.state.domestic_achievement/this.state.domestic_monthly_target)*100
    var achievement3=((this.state.total_monthly_achievement/this.state.total_monthly_target)*100)

    var overall_rate2=(this.state.export_monthly_target-this.state.export_achievement)/this.state.remaining_days
    var overall_rate1=(this.state.domestic_monthly_target-this.state.domestic_achievement)/this.state.remaining_days
// console.log(this.state.domestic_monthly_target,'===',this.state.domestic_achievement,'---',this.state.remaining_days,'+++++')
    var overall_rate3=(this.state.total_monthly_target-this.state.total_monthly_achievement)/this.state.remaining_days
console.log(this.state.total_monthly_target,'===',this.state.total_monthly_achievement,'---',this.state.remaining_days,'+++++')
   const {domestic_monthly_target}=this.state
   const {export_monthly_target}=this.state
  console.log(achievement1,achievement2,achievement3,"1854")
    return (
      <div  style={{marginRight:'-50px'}} >
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
        <div className="card col-xl-12" style={{margin:'-40px 0px -10px -40px'}}>
          <div className="card-header text-center">
            
            <h5 style={{ border: "1px solid #ccc", padding: "1%", fontSize:"20px" }}>
            {/* <span style={{position:"relative",left:"-30%",fontSize:"15px",color:"#171C8F"}}>
                        Updated on : {this.state.lastRunTime}
                    </span> */}
              <span className="" style={{position:"relative",left:"0 %"}}>SALES AND DISPATCH</span>
            </h5>
          </div>

          < div className="row" style={{paddingTop:"20px"}}>
          
            
          <div className="col-xl-6 col-lg-6">
            <span style={{fontSize:"16px",fontWeight:"bold",textDecoration:"underline"}}>Invoice Status:</span>
            <span style={{fontSize:"16px",color:"#171C8F",textDecoration:"none",fontWeight:"bold",marginLeft:"250px"}}>
            Last Data Updated On {this.state.updated_time}
                    </span>
            <div
              className="col-xl-12 col-lg-12"
              style={{ padding: "0px 0px 0px 0px",marginRight:"0px" }}
            >
             <table className="table table-bordered rail-stage-tbl text-center" style={{ fontSize: "14px" }}>
  <tr>
    <th rowSpan={2} style={{ width: "60px" }}>{"Parameters"}</th>
    <th colSpan={3} style={{ width: "60px" }}>{"Dispatch Summary (CARS)"}</th>
  </tr>
  <tr>
    <th style={{ width: '60px' }}>{"Domestic"}</th>
    <th style={{ width: '60px' }}>{"Export"}</th>
    <th style={{ width: '60px' }}>{"Total"}</th>
  </tr>

  <tr>
    <th style={{ width: '60px' }}>Monthly Target</th>
    <td style={{ width: '60px' }}>{domestic_monthly_target}</td>
    <td style={{ width: '60px' }}>{export_monthly_target}</td>
    <td style={{ width: '60px' }}>{this.state.total_monthly_target}</td>
  </tr>

  <tr>
    <th style={{ width: '60px' }}>Achievement</th>
    <td style={{ width: '60px' }}>{this.state.domestic_achievement}</td>
    <td style={{ width: '60px' }}>{this.state.export_achievement}</td>
    <td style={{ width: '60px' }}>{this.state.total_monthly_achievement}</td>
  </tr>

  <tr>
    <th style={{ width: '60px' }}>%Achieved Till Date</th>
    <td style={{ width: '60px', padding: 0 }}>
      <ProgressBar
        height="42px"
        borderRadius="0px"
        bgColor="#4099ff"
        labelAlignment={achievement1 > 20 ? "right" : "left"}
        labelColor={achievement1 > 20 ? "white" : "black"}
        completed={achievement1.toFixed(1)}
      />
    </td>
    <td style={{ width: '60px', padding: 0 }}>
      <ProgressBar
        height="42px"
        borderRadius="0px"
        bgColor="#4099ff"
        labelAlignment={achievement2 > 20 ? "right" : "left"}
        labelColor={achievement1 > 20 ? "white" : "black"}
        completed={achievement2.toFixed(1)}
      />
    </td>
    <td style={{ width: '60px', padding: 0 }}>
      <ProgressBar
        height="42px"
        borderRadius="0px"
        bgColor="#4099ff"
        labelAlignment={achievement3 > 20 ? "right" : "left"}
        labelColor={achievement1 > 20 ? "white" : "black"}
        completed={achievement3.toFixed(1)}
      />
    </td>
  </tr>

  <tr>
    <th style={{ width: '60px' }}>Asking Rate Overall</th>
    <td style={{ width: '60px' }}>{overall_rate1.toFixed(0)}</td>
    <td style={{ width: '60px' }}>{overall_rate2.toFixed(0)}</td>
    <td style={{ width: '60px' }}>{overall_rate3.toFixed(0)}</td>
  </tr>
</table>

            </div>
          </div>  
          

          
            
            <div className="col-xl-6 col-lg-6">
            <span style={{fontSize:"16px",fontWeight:"bold",textDecoration:"underline",width:"100%"}}>Planning Status:
            </span>
            <span style={{fontSize:"16px",color:"#171C8F",textDecoration:"none",fontWeight:"bold",marginLeft:"250px"}}>
                        {this.state.gridHeaderName}
                    </span>
           
           
            <div
              id="myGrid"
              style={{ width: "100%", height: "250px",fontSize:"12px",marginBottom:"15px" }}
              className={"ag-theme-balham"}
            >
               
              
              <AgGridReact
                        modules={this.state.modules}
                        columnDefs={columnwithDefs}
                        headerHeight={80}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.stockData}
                        enableCharts={false}
                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                        onGridReady={this.onGridReady}
                        onGridState={this.onGridState}
                        frameworkComponents={this.state.frameworkComponents}
                        statusBar={this.state.statusBar}
                        sideBar={this.state.sideBar}
                        stopEditingWhenGridLosesFocus= {true}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={false}
                        rowClassRules={this.state.rowClassRules}
                        pinnedBottomRowData={this.state.pinnedRegionBottomRowData}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        gridOptions={{
                            context: { componentParent: this },
                            getRowStyle: function (params) {
                                if (params.node.rowPinned) {
                                  return { 'font-weight': 'bold','font-size':'8px' };
                                }
                            },
                            suppressRowTransform: true,
                        }}
                        // components={this.state.components}
                        enableRangeSelection= {true}
                        // onCellClicked={this.onCellClicked.bind(this)}
                        // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                        />
            </div>
            
            </div>

            <div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
            

            <div className="col-xl-12 col-lg-12 row" style={{marginBottom:"0px",paddingBottom:"0px"}}>

           
          <div className="col-xl-6 col-lg-6">
          <div className="col-xl-12 col-lg-12" style={{marginLeft:"0px",paddingLeft:"0px",marginRight:"0px",paddingRight:"0px"}}>
              <span style={{fontSize:"16px",fontWeight:"bold",textDecoration:"underline"}}>Stock Status:</span>
              <span style={{fontSize:"16px",color:"#171C8F",textDecoration:"none",fontWeight:"bold",marginLeft:"250px"}}>
                        {this.state.gridHeaderName}
                    </span>
                    <table className="table table-bordered rail-stage-tbl text-center" style={{ fontSize: "14px" }}>
                      <thead>
                        <tr>
                          <th>Location</th>
                          <th>Capacity</th>
                          <th>Stock</th>
                          <th>Stockyard Utilization</th>
                          <th>DISPATCHABLE STOCK</th>
                          <th>Q HOLD</th>
                          <th>OTHER HOLD</th>
                        </tr>
                      </thead>
                      <tbody>
    {data.map((each) => {
      let o_hold = each.stock - each.dispatchable_stock - each.q_hold_stock;
      return (
        <tr key={each.plant_name}>
          <td style={{fontWeight:"bold"}}>{each.plant_name}</td>
          <td>{each.capacity}</td>
          <td>{each.stock}</td>
          <td style={{ padding: 0 }}>
            <ProgressBar
              height="42px"
              borderRadius="0px"
              bgColor="#4099ff"
              completed={each.stock_capacity_data}
            />
          </td>
          <td>{each.dispatchable_stock}</td>
          <td>{each.q_hold_stock}</td>
          <td>{o_hold}</td>
        </tr>
      );
    })}
    <tr>
      <td style={{fontWeight:"bold"}}>TOTAL</td>
      <td>{data.reduce((sum, each) => sum + each.capacity, 0)}</td>
      <td>{data.reduce((sum, each) => sum + each.stock, 0)}</td>
      <td>&nbsp;</td>
      <td>{data.reduce((sum, each) => sum + each.dispatchable_stock, 0)}</td>
      <td>{data.reduce((sum, each) => sum + each.q_hold_stock, 0)}</td>
      <td>
        {data.reduce(
          (sum, each) => sum + (each.stock - each.dispatchable_stock - each.q_hold_stock),
          0
        )}
      </td>
    </tr>
  </tbody>
</table>


            </div>
            <div className="col-xl-12 col-lg-12" style={{marginLeft:"0px",paddingLeft:"0px",marginRight:"0px",paddingRight:"0px"}}>
          {/* <div className="col-xl-12 col-lg-12" style={{marginLeft:"0px",paddingLeft:"0px",marginRight:"0px",paddingRight:"0px"}}>
          
          <h5 style={{fontSize:"13px",fontWeight:"bold",textDecoration:"underline"}}>Transit Vehicle Park (TVP):</h5>
          
          <table className="table table-bordered rail-stage-tbl text-center" style={{marginBottom:"20px",fontSize:"9px"}}>
                  <tr style={{fontSize:"12px"}}>
                      <th  rowSpan={2}>Stock Yard Loction</th>
                      <th colSpan={2}>Realtime Status</th>
                      <th colSpan={4}>MTD Status</th>
                  </tr>
                  <tr>
                      <th>Stock</th>
                      <th>Transit</th>
                      <th>Factory Invoice</th>
                      <th>Receipt</th>
                      <th>Fund Validation</th>
                      <th>Exit</th>
                  </tr>
                  <tr>
                      <th>TVP banglore</th>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                  </tr>
                  <tr>
                      <th>TVP Nagpur</th>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                  </tr>
                  <tr>
                      <th>TVP Siliguri</th>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                  </tr>
                  
              </table>
              </div> */}
              {/* <div className="col-xl-12 col-lg-12">
              <table className="table table-bordered rail-stage-tbl text-center" style={{fontSize:"9px"}}>
                
                    <tr>
                    <th colSpan={2}>Fund Reciept</th>
                    <th colSpan={3}>Funded Stock Status</th>
                    </tr>
                    <tr>
                        <th>Stock</th>
                        <th>Transit</th>
                        <th>Planned</th>
                        <th>Unplanned</th>
                        <th>Total</th>
                    </tr>
                    <tr>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                </table>
                </div> */}
                </div>
                </div>
                {/* <DrawMap></DrawMap> */}
            <div className="col-xl-6 col-lg-6" style={{marginRight:"0px",paddingRight:"0px"}}>
            {/* <h5 style={{fontSize:"13px",fontWeight:"bold",textDecoration:"underline",marginLeft:"20px"}}>Fleet Status: MSIL Loading Locations
</h5>          */}
<span style={{fontSize:"16px",fontWeight:"bold",textDecoration:"underline",textDecoration:"underline",marginLeft:"20px"}}>Fleet Status: MSIL Loading Locations:</span>
            <span style={{fontSize:"16px",color:"#171C8F",textDecoration:"none",fontWeight:"bold",marginLeft:"120px"}}>
            Last Data Updated On {this.state.updated_time}
                    </span>
                <div class="col-xl-12 col-sm-12 col-xs-12 col-md-12 row wdiv" style={{marginRight:"0px",paddingRight:"0px"}}>
                <div id="mapweather" className="col-lg-12 col-xl-12" style={dStyles}></div>
                </div>
                <div style={{position:"absolute",bottom:"100px",marginLeft:"30px",backgroundColor:"white",fontSize:"8px",border:"0.5px solid dark grey"}}>
                    
                    {/* <tabel className="tabel text-center">
                        <tr>
                            <th style={{border:"solid 2px lightgrey",padding:"4px"}}>MSIL Locations</th>
                        <th  style={{border:"solid 2px lightgrey",padding:"4px"}}>Fleet nos</th>
                        </tr>
                        <tr>
                            <td style={{border:"solid 2px lightgrey",padding:"4px"}}>Gurgaon</td>
                            <td style={{border:"solid 2px lightgrey",padding:"4px"}}>325</td>
                        </tr>
                        <tr>
                            <td style={{border:"solid 2px lightgrey",padding:"4px"}}>Gurgaon</td>
                            <td style={{border:"solid 2px lightgrey",padding:"4px"}}>325</td>
                        </tr>
                        <tr>
                            <td style={{border:"solid 2px lightgrey",padding:"4px"}}>Gurgaon</td>
                            <td style={{border:"solid 2px lightgrey",padding:"4px"}}>325</td>
                        </tr>

                        <tr>
                            <td style={{border:"solid 2px lightgrey",padding:"4px"}}>Gurgaon</td>
                            <td style={{border:"solid 2px lightgrey",padding:"4px"}}>325</td>
                        </tr>
                        <tr>
                            <td style={{border:"solid 2px lightgrey",padding:"4px"}}>Gurgaon</td>
                            <td style={{border:"solid 2px lightgrey",padding:"4px"}}>325</td>
                        </tr>
                        <tr>
                            <td style={{border:"solid 2px lightgrey",padding:"4px"}}>Gurgaon</td>
                            <td style={{border:"solid 2px lightgrey",padding:"4px"}}>325</td>
                        </tr>
                        <tr>
                            <td style={{border:"solid 2px lightgrey",padding:"5px"}}>Gurgaon</td>
                            <td style={{border:"solid 2px lightgrey",padding:"5px"}}>325</td>
                        </tr>
                    </tabel> */}
                     <tabel className="tabel text-center">
                        <tbody>
                            <tr>
                                <th style={{border:"solid 2px lightgrey",padding:"4px"}}>MSIL Locations</th>
                                <th  style={{border:"solid 2px lightgrey",padding:"4px"}}>Fleet nos</th>
                            </tr>
                            <>
                            {(this.state.scrowData).map((each)=>this.getFleets(each))}
                            
                            {/* <tr>
                               
                        <th style={{ border: "solid 2px lightgrey", padding: "4px" }}>Total</th>
                        <th style={{ border: "solid 2px lightgrey", padding: "4px" }}>{total}</th>
                                {/* <th  style={{border:"solid 2px lightgrey",padding:"4px"}}>Fleet nos</th> 
                            </tr> */}
                        </>
                        </tbody>
                    </tabel>
                </div>
                {/* <div className="col-xl-12 col-lg-12">
              <table className="table table-bordered rail-stage-tbl text-center" style={{fontSize:"9px",marginTop:"15px"}}>
                
                    <tr>
                    <th colSpan={2}>Fund Reciept</th>
                    <th colSpan={3}>Funded Stock Status</th>
                    </tr>
                    <tr>
                        <th>Stock</th>
                        <th>Transit</th>
                        <th>Planned</th>
                        <th>Unplanned</th>
                        <th>Total</th>
                    </tr>
                    <tr>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                </table>
                </div> */}
              </div>
              {/* <div className="col-xl-6 col-lg-6">
          <div className="col-xl-12 col-lg-12">
          
          <h5 style={{fontSize:"17px",fontWeight:"bold",textDecoration:"underline"}}>Transit Vehicle Park (TVP):</h5>
          
          <table className="table table-bordered rail-stage-tbl text-center" style={{marginBottom:"20px",fontSize:"9px"}}>
                  <tr style={{fontSize:"12px"}}>
                      <th  rowSpan={2}>Stock Yard Loction</th>
                      <th colSpan={2}>Realtime Status</th>
                      <th colSpan={4}>MTD Status</th>
                  </tr>
                  <tr>
                      <th>Stock</th>
                      <th>Transit</th>
                      <th>Factory Invoice</th>
                      <th>Receipt</th>
                      <th>Fund Validation</th>
                      <th>Exit</th>
                  </tr>
                  <tr>
                      <th>TVP banglore</th>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                  </tr>
                  <tr>
                      <th>TVP banglore</th>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                  </tr>
                  <tr>
                      <th>TVP banglore</th>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                  </tr>
                  
              </table>
              </div>
              <div className="col-xl-12 col-lg-12">
              <table className="table table-bordered rail-stage-tbl text-center" style={{fontSize:"9px"}}>
                
                    <tr>
                    <th colSpan={2}>Fund Reciept</th>
                    <th colSpan={3}>Funded Stock Status</th>
                    </tr>
                    <tr>
                        <th>Stock</th>
                        <th>Transit</th>
                        <th>Planned</th>
                        <th>Unplanned</th>
                        <th>Total</th>
                    </tr>
                    <tr>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                </table>
                </div>
                </div> */}
              </div>
            
            
            </div>
          </div>
        </div>
     
    );
  }
}

function loadDateTimeScript() {
  // alert("timeDate");
  $(".datetimepicker_mask").datetimepicker({
    //mask:'39-19-9999 29:59',
    format: "d-m-Y",
  });
  $(".datetimepicker_date").datetimepicker({
    mask: "39-19-9999",
    format: "d-m-Y",
    timepicker: false,
  });
  var index = window.document.getElementsByTagName("script")[1];
  var script = window.document.createElement("script");
  script.src =
    "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js";
  //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
  script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}


function loadScriptWeather(url){
    console.log(window.screen.availHeight,
    window.screen.availWidth)
    if(window.screen.availHeight <= 1024)
    {
      $("#mapweather").removeClass("col-md-8");
      $("#weathermapgrid").removeClass("col-md-4");
      $("#mapweather").addClass("col-md-8");
      $("#weathermapgrid").addClass("col-md-8");
    }
    var index  =window.document.getElementsByTagName("script")[1]
      var script = window.document.createElement("script")
      script.src=url
      script.async=true
      script.defer = true
      index.parentNode.insertBefore(script,index)
  }
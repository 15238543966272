import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MenuModule } from "@ag-grid-enterprise/menu";
import Select from "react-select";
import $ from "jquery";

import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
var redirectURL = require("../redirectURL");

export default class Lclfgrieghtmanagementsystemscreen2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        width: 160,
        resizable: true,
      },
      eventAction: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      loadshow: "show-n",
      overly: "",
      rowdata: [],
      sliderbulkupload: "",
      rowModelhighchartoptionsType: "enterprise",
      //   frameworkComponents: {
      //     BatchDownload: BatchDownload,
      //   },
      rowGroupPanelShow: "always",
      statusBar: {
        // statusPanels: [
        //   {
        //     statusPanel: "agTotalAndFilteredRowCountComponent",
        //     align: "left",
        //   },
        //   {
        //     statusPanel: "agTotalRowCountComponent",
        //     align: "center",
        //   },
        //   { statusPanel: "agFilteredRowCountComponent" },
        //   { statusPanel: "agSelectedRowCountComponent" },
        //   { statusPanel: "agAggregationComponent" },
        // ],
      },
      paginationPageSize: 50,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      animateRows: true,
      dept_code: "",
      pagetitle: "",
      csvData: 0,
      overly: "show-n",
      loadshow: "show-n",
      pagetitle: "",
      eventGridAction: "gridAction",
      csvcontent: [],
      file: "",
      batch_name: "",
      cluster_breaks: "2",
      cluster_radius: "0.5",
      notification_emails: "",
      bulkslide: "",
      trailor_no: "",

      rowId: "",
      show: false,
      basicTitle: "",
      basicType: "",
      transitDate: "",
      transpoterGSTDate: "",
      unloadedDate: "",
      reachedDate: "",
      SectororCountry: "",
      location: "",
      stateMetricTon:'',
      stateCBM:'',
      stateSectororCountry:'',
      stateLocation:'',

      returnResponse:'',
      determining_factor:'',
      ocean_freight:'',
      shipment_charges:'',
      cbm_charges:'',
      cfs_charges:'',
      transportation_rates:'',
      net_logistics_cost:'',
      CBMValue:''


    };
    // this.downloadbatchfile = this.downloadbatchfile.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener("click", this.handleClickOutside);
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    var params = {

    };

    redirectURL
      .post("/consignments/totalfmsinputsfrombuyers", params)
      .then((response) => {
        console.log(response.data, "totalfmsinputsfrombuyers");

        const reversedData = [...response.data].reverse();
        console.log(reversedData,'reversedData---')
        this.setState({
          rowdata: reversedData,

          loadshow: "show-n",
          overly: "show-n",
        });
      })
      .catch((e) => {
        console.log(e);
      });

  
  }

  selectCountryOptionsItems(){
    var countries = ["Austria", "China", "Czech Republic", "France", "Germany", "Hong Kong", "Hungary", "Indonesia", "Italy", "Japan", "Malaysia", "Netherlands", "South Korea", "Spain", "Singapore", "Taiwan",
     "Thailand", "UK", "USA", "Kamax, Czech Republic", "Kamax, Spain", "Anamet Europe BV", "Baker Hughes Digital Solutions GMBH"];
    let items = [];     
    //items.push({value:'all',label:'All'}); 
    countries.forEach(item =>{
        items.push({value:item,label:item});
    }); 
    return items;
}

  containernamechangeHandler = (e) => {
    this.setState({
      SectororCountry: e,
    });
  };

  containerlocationchangeHandler = (e) => {
    this.setState({
      location: e,
    });
  };

  addNewRow=()=>{
    // var rowId = e.data._id;
    this.setState({
      bulkslide: "slider-translate-30p",
      // rowId: rowId,
    });
  }

  closeAlert = () => {
    this.setState({
      show: false,
    });
  };
  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      bulkslide: "",
    });
  }
  handleClickOutside = (event) => {
    if (!event.target.closest(".slide-r")) {
      this.onClickHideAll();
    }
  };
  // onCellRowClicked = (e) => {
  //   var rowId = e.data._id;
  //   // if (e.colDef.field == "gst_registered_trailor_no") {
  //   this.setState({
  //     bulkslide: "slider-translate-30p",
  //     rowId: rowId,
  //   });
  //   // }
  // };
  CBMchangeHandler(event){
    console.log(event.target.value,"107")
    event.preventDefault();
    if(parseFloat(event.target.value)>=9){
      alert("Net Volume in CBM should not be greater then 8")
      $("#bookingId1").val("")
    }else{
      $("#bookingId1").val(event.target.value)
    }
    // console.log(this.state.CBMValue,"208")
  }

  onClickShowFilterData(event) {
    event.preventDefault();
    this.setState({
      loadshow :"show-m",
      overly:"show-m"
    })
    // var rowId = this.state.rowId;
    var MetricTon = $("#etd_weekly").val();
    var CBM = $("#bookingId1").val();
    var SectororCountry = this.state.SectororCountry.value;
    var Location = this.state.location.value;

    
// console.log(rowId,MetricTon,CBM,SectororCountry,Location,'data-----')
   var params = {
     
      net_weight:MetricTon,
      net_volume:CBM,
      country:SectororCountry,
      plant:Location,
      FOB:"FOB",
    };

 
    console.log(params, "257");

  var cnf = window.confirm("Are you sure you want to submit the form?");
  if (cnf === true) {
    if (/^\d*\.?\d+$/.test(CBM)) {
    if (/^\d*\.?\d+$/.test(MetricTon)) {
    redirectURL
      .post("/consignments/getFmsRates", params)
      .then((response) => {
        console.log(response,"224")
        console.log(response.data.status,'response.data.status')
        if(response.data.status == "success" ){
          
          this.setState({
            // loadshow:"show-n",
            // overly:"show-n",
            basicTitle: "Successfully Updated",
            basicType: "success",
            show: true,
            bulkslide: "",
          })
          this.onClickHideAll();
          this.componentDidMount();
      
          $("#bookingId1").val("");
          $("#etd_weekly").val("");
          this.setState({ rowId: "", SectororCountry: "", Location: "" });
         
        }else {
          alert(response.data.status)
          this.onClickHideAll();
          this.componentDidMount();
          $("#bookingId1").val("");
          $("#etd_weekly").val("");
        }
      
      })
      .catch((e) => {
        console.log(e);
      });
    
  } else {
    this.componentDidMount();
    // window.location.reload();
    alert("Enter Numeric Value!");
  }
} else {
  this.componentDidMount();
  // window.location.reload();
  alert("Enter Numeric Value!");
}
}
}
  handleInputChange(e) {
    //   var rowId = e.data;
    //   console.log('hell
      // console.log(e,'e')
    this.setState({
        bulkslide: "slider-translate-30p",
        // rowId: rowId,
      });

    
    // var newValue = event.target.textContent.trim();
    // if (/^\d*\.?\d+$/.test(newValue)){
    // var params={
    //   country:field,
    //   ocean_freight:Number(event.target.textContent)
    // }
    // console.log(params,'params===')
    
        // redirectURL
        // .post("/consignments/updatefmsrates",params)
        // .then((response) => {
        //   console.log(response.data, "140");
    
        //   this.setState({
        //     rowdata: response.json().data,
    
        //     loadshow: "show-n",
        //     overly: "show-n",
        //   });
        // })
        // .catch((e) => {
        //   console.log(e);
        // });
    //   }else{
        
    //     alert("Enter Numeric Value")
    //     this.componentDidMount()
    //   }

   }


  render() {
    const batchwisecolumns = [
      {
        headerName: "Net Weight in Metric Ton (MT)",
        field: "net_weight",
      },

      {
        headerName: "Net Volume in CBM",
        field: "net_volume",
      },
      {
        headerName: "Determining Factor",
        field: "determining_factor",
      },
      {
        headerName: "Sector or Country",

        field: "country",
      },
      {
        headerName: "USD To INR",

        field: "usd_inr",
      },
      {
        headerName: "EURO To INR",

        field: "euro_inr",
      },

      {
        headerName: "Inco Term",
        field: "inco_term",
      },

      {
        headerName: "Delivery Plant",
        field: "plant",
      },
      {
        headerName: "Ocean Freight Applicable in USD",
        field: "ocean_freight",
        // cellEditor: AlphaNumericEditor,
        editable: false,
      },
      {
        headerName: "Extra Charges/Shipment(In INR)",
        field: "shipment_charges",
        editable: false,
      },

      {
        headerName: "Extra Charges/CBM(In INR)",
        field: "cbm_charges",
      },
      {
        headerName: "Transportation Charges/Shipment(In INR)",
        field: "transportation_rates",
      },
      {
        headerName: "CFS Charges/CBM(In INR)",
        field: "cfs_charges",
      },
      {
        headerName: "Net Logistics Cost(In INR)",
        field: "net_logistics_cost",
      },
    ];
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
          ></SweetAlert>

        <div className="row">
          {/* <div className="col-xl-12 col-lg-12"> */}
            <div className="card" style={{ width: "100%" }}>
              <div className="card-header">
                <h5>
                  <i className="icofont icofont-vehicle-delivery-van cus-i"></i>
                  <span>Freight Calculation System Buyers</span>
                </h5>
                <button className="btn btn-success" style={{float:"right"}} onClick={this.addNewRow.bind(this)}>Create New Request </button>
              </div>

              <div>
              
              </div>
              {/* <div className="row col-xl-12 col-lg-12" >
            <div className="col-xl-12 col-lg-12">
              <table
                className="table table-bordered rail-stage-tbl"
                style={{ width: "100%", fontSize: "13px" }}
              >
             <thead>  <tr>
                    <th>Net Weight in Metric Ton (MT)</th>
                    <td onClick={() => this.handleInputChange(this)} >
                        {this.state.stateMetricTon}</td>
                </tr></thead> 
                <tr>
                    <th>Net Volume in CBM</th>
                    <td onClick={() => this.handleInputChange(this)}>{this.state.stateCBM}</td>
                </tr>
                <tr>
                    <th>Determining Factor</th>
                    <td onClick={() => this.handleInputChange(this)}>{this.state.returnResponse.determining_factor}</td>
                </tr>
                <tr>
                    <th>Sector or Country</th>
                    <td onClick={() => this.handleInputChange(this)}>{this.state.stateSectororCountry}</td>
                </tr>
                <tr>
                    <th>In co Country</th>
                    <td onClick={() => this.handleInputChange(this)}>FOB</td>
                </tr>
                <tr>
                    <th>Plant at which delivery is required (MSIL-G, Manesar)</th>
                    <td onClick={() => this.handleInputChange(this)}>{this.state.stateLocation}</td>
                </tr>
                <tr>
                    <th>Ocean Freight Applicable in USD</th>
                    <td onClick={() => this.handleInputChange(this)}>{this.state.returnResponse.ocean_freight}</td>
                </tr>
                <tr>
                    <th>Extra charges/Shipment</th>
                    <td onClick={() => this.handleInputChange(this)}>{this.state.returnResponse.shipment_charges}</td>
                </tr>
                <tr>
                    <th>Extra Charges/CBM</th>
                    <td onClick={() => this.handleInputChange(this)}>{this.state.returnResponse.cbm_charges}</td>
                </tr>
                <tr>
                    <th>Transportation Charges/Shipment</th>
                    <td onClick={() => this.handleInputChange(this)}>{this.state.returnResponse.transportation_rates}</td>
                </tr>
                <tr>
                    <th>CFS Charges/Shipment</th>
                    <td onClick={() => this.handleInputChange(this)}>{this.state.returnResponse.cfs_charges}</td>
                </tr>
                <tr>
                    <th>Net Logistics Cost</th>
                    <td onClick={() => this.handleInputChange(this)}>{this.state.returnResponse.net_logistics_cost}</td>
                </tr>
               

              </table>
              </div>
              </div> */}


            
              <div className="card-body">
                <div className="row">
                  <div
                    id="myGrid"
                    style={{ width: "100%", height: "478px" }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      modules={this.state.modules}
                      columnDefs={batchwisecolumns}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.rowdata}
                      enableCharts={false}
                      onGridReady={this.onGridReady}
                      onGridState={this.onGridState}
                      frameworkComponents={this.state.frameworkComponents}
                      rowClassRules={this.state.rowClassRules}
                      sideBar={this.state.sideBar}
                      stopEditingWhenGridLosesFocus={true}
                      paginationPageSize={this.state.paginationPageSize}
                      pagination={true}
                      gridOptions={{
                        context: { componentParent: this },
                      }}
                      // onCellClicked={this.onCellRowClicked.bind(this)}
                      enableRangeSelection={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          {/* </div> */}
        </div>
        <div
          className={"slide-r " + this.state.bulkslide}
          style={{ overflow: "hidden" }}
        >
          <h3 className="subH">
          Inputs from Buyers
            <span
              className="float-right fclose"
              style={{
                marginRight: "12px",
                padding: "1px 8px",
                marginTop: "-2px",
              }}
              onClick={this.onClickHideAll.bind(this)}
            >
              x
            </span>
          </h3>

          <div className="slide-r-body" style={{ position: "relative" }}>
            <div className="container-fluid">
              <form
                method="post"
                onSubmit={this.onClickShowFilterData.bind(this)}
                autocomplete="off"
                className="row col-xl-12 col-lg-12"
              >
                <div className="col-xl-12 col-lg-12 row form-group">
                  <label className="col-xl-12 col-lg-12">
                    Net Weight in Metric Ton (MT) :{" "}
                  </label>
                  <input
                    type="text"
                    name="etd_weekly"
                    id="etd_weekly"
                    className="datetimepicker_datew form-control"
                    placeholder="Net Weight in Metric Ton (MT)"
                  />
                </div>
                <div
                  className="form-group mt-20p col-xl-12 col-lg-12"
                  style={{ paddingLeft: "0px", paddingRight: "27px" }}
                >
                  <label className="col-xl-12 col-lg-12">
                    Net Volume in CBM :{" "}
                  </label>
                  <input
                    type="text"
                    name="bookingId1"
                    id="bookingId1"
                    className="form-control"
                    placeholder="Net Volume in CBM"
                      onChange={this.CBMchangeHandler}
                    required
                  />
                </div>
                <div className="col-xl-12 col-lg-12 row form-group mt-20p">
                  <label className="col-xl-12 col-lg-12">
                    Sector or Country{" "}
                  </label>
                  <Select
                    placeholder={"Sector or Country"}
                    closeMenuOnSelect={true}
                    // id={"transporterName"}
                    // name={"transporterName"}
                    value={this.state.container_name}
                    className={"col-xl-12 col-lg-12 border-radius-0"}
                    onChange={this.containernamechangeHandler.bind(this)}
                    style={{ borderRadius: "0px" }}
                    options={this.selectCountryOptionsItems()}
                    
                    required
                  />
                </div>
                <div className="col-xl-12 col-lg-12 row form-group mt-20p">
                  <label className="col-xl-12 col-lg-12">
                  Plant{" "}
                  </label>
                  <Select
                    placeholder={"Plant"}
                    closeMenuOnSelect={true}
                    // id={"transporterName"}
                    // name={"transporterName"}
                    value={this.state.container_name}
                    className={"col-xl-12 col-lg-12 border-radius-0"}
                    onChange={this.containerlocationchangeHandler.bind(this)}
                    style={{ borderRadius: "0px" }}
                    options={[
                      {
                        label: "Gurgaon",
                        value: "Haryana",
                      },
                      {
                        label: "Manesar",
                        value: "Haryana",
                      },
                      {
                        label: "Rohtak",
                        value: "Rohtak",
                      },
                    ]}
                    required
                  />
                </div>

                <div className="form-group col-xl-3 col-lg-3">
                  <button type="submit" className="btn btn-success">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// function loadDateTimeScript() {
//     // alert("timeDate");
//     $(".datetimepicker_datew").datetimepicker({
//       //mask:'39-19-9999',
//       format: "d-m-Y",
//       minDate: true,
//       timepicker: false,
//       closeOnDateSelect: true,
//       scrollMonth: false,
//       scrollInput: false,
//     });
//     $(".datetimepicker_dateo").datetimepicker({
//       //mask:'39-19-9999',
//       format: "d-m-Y",
//       minDate: true,
//       timepicker: false,
//       closeOnDateSelect: true,
//       scrollMonth: false,
//       scrollInput: false,
//     });
//     $(".cargo_date").datetimepicker({
//       //mask:'39-19-9999',
//       format: "d-m-Y",
//       minDate: true,
//       timepicker: false,
//     });
//   }

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getHyphenDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import CSVFileValidator from 'csv-file-validator';
import Map from '../common/drawmap';
import FileBase64 from 'react-file-base64';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import save from 'material-ui/svg-icons/content/save';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var redirectURL  = require('../redirectURL');

export default class EditFFCargoEvent extends Component{
    constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            csvcontent:"",
            file : "",
            loadshow:'show-n',
            overly:'show-n',
            uploadYard : "",
            uploadDispatchDate : "",
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            pagetitle:"",
            bulkslide:"",
            cargoBookingId:"",
            airCargoChaId:"",
            other_docs:"",
            file:"",
            loadshow : "show-n",
            hiddenotherdocs : "",
            hiddeninvoice : "",
            pagetype : 1,
            //components : {datepicker:getDatePicker()}
        }
        this.changeHandler = this.changeHandler.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        if (googleAnalytics.page.enableGA) {
			this.logPageView();
		}

        var inputEventval = window.atob(this.props.match.params.eventname);
        var pagetype = this.props.match.params.pagetype;
        console.log(pagetype,"pagetype")
        this.setState({
            pagetype : pagetype
        })
		// console.log
		var cargoBookingId="";
		var reqparams = {
            eventID : inputEventval,
        }

        redirectURL.post("/consignments/getAirCargosByRequestId",reqparams).then((response)=>{
            console.log(response.data);
            var cargoBookingId=response.data[0]._id;
			this.setState({
				event : response.data[0].event,
				cargoBookingId : response.data[0]._id,
                job_no : response.data[0].job_no,
            })
            var resparams = {
                cargoBookingId : cargoBookingId,
            }
            //console.log(resparams)
            
            var igm_date ="";
                if(response.data[0].igm_date !=undefined && response.data[0].igm_date !='')
                {
                    igm_date = response.data[0].igm_date;
                    if(igm_date.includes("T") == true)
                    {
                        igm_date = igm_date.split("T");
                        igm_date = igm_date[0];
                        igm_date = igm_date.split("-")[2]+"-"+igm_date.split("-")[1]+"-"+igm_date.split("-")[0];
                    }
                }
                var inward_date ="";
                if(response.data[0].inward_date !=undefined && response.data[0].inward_date !='')
                {
                    inward_date = response.data[0].inward_date;
                    if(inward_date.includes("T") == true)
                    {
                        inward_date = inward_date.split("T");
                        inward_date = inward_date[0];
                        inward_date = inward_date.split("-")[2]+"-"+inward_date.split("-")[1]+"-"+inward_date.split("-")[0];
                    }
                }
                var exam_date ="";
                if(response.data[0].exam_date !=undefined && response.data[0].exam_date !='')
                {
                    exam_date = response.data[0].exam_date;
                    if(exam_date.includes("T") == true)
                    {
                        exam_date = exam_date.split("T");
                        exam_date = exam_date[0];
                        exam_date = exam_date.split("-")[2]+"-"+exam_date.split("-")[1]+"-"+exam_date.split("-")[0];
                    }
                }
                var segregation_date ="";
                if(response.data[0].segregation_date !=undefined && response.data[0].segregation_date !='')
                {
                    segregation_date = response.data[0].segregation_date;
                    if(segregation_date.includes("T") == true)
                    {
                        segregation_date = segregation_date.split("T");
                        segregation_date = segregation_date[0];
                        segregation_date = segregation_date.split("-")[2]+"-"+segregation_date.split("-")[1]+"-"+segregation_date.split("-")[0];
                    }
                }
                var boe_date ="";
                if(response.data[0].boe_date !=undefined && response.data[0].boe_date !='')
                {
                    boe_date = response.data[0].boe_date;
                    if(boe_date.includes("T") == true)
                    {
                        boe_date = boe_date.split("T");
                        boe_date = boe_date[0];
                        boe_date = boe_date.split("-")[2]+"-"+boe_date.split("-")[1]+"-"+boe_date.split("-")[0];
                    }
                }
                var otp_date ="";
                if(response.data[0].otp_date !=undefined && response.data[0].otp_date !='')
                {
                    otp_date = response.data[0].otp_date;
                    if(otp_date.includes("T") == true)
                    {
                        otp_date = otp_date.split("T");
                        otp_date = otp_date[0];
                        otp_date = otp_date.split("-")[2]+"-"+otp_date.split("-")[1]+"-"+otp_date.split("-")[0];
                    }
                }
                var ooc_date ="";
                if(response.data[0].ooc_date !=undefined && response.data[0].ooc_date !='')
                {
                    ooc_date = response.data[0].ooc_date;
                    if(ooc_date.includes("T") == true)
                    {
                        ooc_date = ooc_date.split("T");
                        ooc_date = ooc_date[0];
                        ooc_date = ooc_date.split("-")[2]+"-"+ooc_date.split("-")[1]+"-"+ooc_date.split("-")[0];
                    }
                }
                var bc_1_date ="";
                if(response.data[0].bc_1_date !=undefined && response.data[0].bc_1_date !='')
                {
                    bc_1_date = response.data[0].bc_1_date;
                    if(bc_1_date.includes("T") == true)
                    {
                        bc_1_date = bc_1_date.split("T");
                        bc_1_date = bc_1_date[0];
                        bc_1_date = bc_1_date.split("-")[2]+"-"+bc_1_date.split("-")[1]+"-"+bc_1_date.split("-")[0];
                    }
                }
                var bc_2_date ="";
                if(response.data[0].bc_2_date !=undefined && response.data[0].bc_2_date !='')
                {
                    bc_2_date = response.data[0].bc_2_date;
                    if(bc_2_date.includes("T") == true)
                    {
                        bc_2_date = bc_2_date.split("T");
                        bc_2_date = bc_2_date[0];
                        bc_2_date = bc_2_date.split("-")[2]+"-"+bc_2_date.split("-")[1]+"-"+bc_2_date.split("-")[0];
                    }
                }
                var demmurage_start_date ="";
                if(response.data[0].demmurage_start_date !=undefined && response.data[0].demmurage_start_date !='')
                {
                    demmurage_start_date = response.data[0].demmurage_start_date;
                    if(demmurage_start_date.includes("T") == true)
                    {
                        demmurage_start_date = demmurage_start_date.split("T");
                        demmurage_start_date = demmurage_start_date[0];
                        demmurage_start_date = demmurage_start_date.split("-")[2]+"-"+demmurage_start_date.split("-")[1]+"-"+demmurage_start_date.split("-")[0];
                    }
                }
                var demmurage_end_date ="";
                if(response.data[0].demmurage_end_date !=undefined && response.data[0].demmurage_end_date !='')
                {
                    demmurage_end_date = response.data[0].demmurage_end_date;
                    if(demmurage_end_date.includes("T") == true)
                    {
                        demmurage_end_date = demmurage_end_date.split("T");
                        demmurage_end_date = demmurage_end_date[0];
                        demmurage_end_date = demmurage_end_date.split("-")[2]+"-"+demmurage_end_date.split("-")[1]+"-"+demmurage_end_date.split("-")[0];
                    }
                }
                // var exp_delivery_at_msil ="";
                // if(response.data[0].exp_delivery_at_msil !=undefined && response.data[0].exp_delivery_at_msil !='')
                // {
                //     exp_delivery_at_msil = response.data[0].exp_delivery_at_msil;
                //     if(exp_delivery_at_msil.includes("T") == true)
                //     {
                //         exp_delivery_at_msil = exp_delivery_at_msil.split("T");
                //         exp_delivery_at_msil = exp_delivery_at_msil[0];
                //         exp_delivery_at_msil = exp_delivery_at_msil.split("-")[2]+"-"+exp_delivery_at_msil.split("-")[1]+"-"+exp_delivery_at_msil.split("-")[0];
                //     }
                // }
                // var actual_delivery_at_msil ="";
                // if(response.data[0].actual_delivery_at_msil !=undefined && response.data[0].actual_delivery_at_msil !='')
                // {
                //     actual_delivery_at_msil = response.data[0].actual_delivery_at_msil;
                //     if(actual_delivery_at_msil.includes("T") == true)
                //     {
                //         actual_delivery_at_msil = actual_delivery_at_msil.split("T");
                //         actual_delivery_at_msil = actual_delivery_at_msil[0];
                //         actual_delivery_at_msil = actual_delivery_at_msil.split("-")[2]+"-"+actual_delivery_at_msil.split("-")[1]+"-"+actual_delivery_at_msil.split("-")[0];
                //     }
                // }
                if(response.data[0].ata_igi_airport_date !=undefined && response.data[0].ata_igi_airport_date !='')
                {
                    var ata_igi_airport_date = getHyphenDDMMYYYY(response.data[0].ata_igi_airport_date);
                    $("#ata_igi_airport").val(ata_igi_airport_date);
                }
                $("#igm_date").val(igm_date);
                $("#inward_date").val(inward_date);
                if(response.data[0].status == "Exam" || response.data[0].status == "RMS")
                {
                    $('#status option[value='+response.data[0].status+']').attr("selected", "selected");
                }
                if(response.data[0].status == "Exam")
                {
                    this.setState({
                        loadshow : ""
                    })
                    $("#exam_date").val(exam_date);
                }else{
                    this.setState({
                        loadshow : "show-n"
                    })
                    $("#exam_date").val("");
                }
                $("#segregation_date").val(segregation_date);
                $("#boe_no").val(response.data[0].boe_no);
                $("#boe_date").val(boe_date);
                
                $("#otp_date").val(otp_date);
                $("#ooc_date").val(ooc_date);
                $("#exchange_rate").val(response.data[0].exchange_rate);
                $("#assessable_value").val(response.data[0].assessable_value);
                $("#bcd").val(response.data[0].bcd);
                $("#sws").val(response.data[0].sws);
                $("#igst").val(response.data[0].igst);
                $("#total_custom_duty").val(response.data[0].total_custom_duty);
                $("#lpc").val(response.data[0].lpc);
                $("#interest").val(response.data[0].interest);
                $("#meis").val(response.data[0].meis);
                $("#duty_payable").val(response.data[0].duty_payable);
                $("#lpc_reason_for_delay").val(response.data[0].lpc_reason_for_delay);
                $("#bc_no_1").val(response.data[0].bc_no_1);
                $("#bc_1_date").val(bc_1_date);
                $("#bc_no_2").val(response.data[0].bc_no_2);
                $("#bc_2_date").val(bc_2_date);
                $("#tsp_charges").val(response.data[0].tsp_charges);
                $("#demmurage_charges").val(response.data[0].demmurage_charges);
                if(response.data[0].demmurage_charges == "yes")
                {
                    $("#rsndel").show();
                    $("#reason_for_delay").val(response.data[0].reason_for_delay);
                }
                else
                {
                    $("#rsndel").hide();
                    $("#reason_for_delay").val("");
                }
                if(response.data[0].lpc > 0 || response.data[0].interest > 0)
                {
                    $("#lpcdel").show();
                    $("#lpc_reason_for_delay").val(response.data[0].lpc_reason_for_delay);
                }
                else
                {
                    $("#lpcdel").hide();
                    $("#lpc_reason_for_delay").val("");
                }
                $("#demmurage_charges_amount").val(response.data[0].demmurage_charges_amount);
                $("#demmurage_start_date").val(demmurage_start_date);
                $("#demmurage_end_date").val(demmurage_end_date);
                
                // $("#vehicle_no").val(response.data[0].vehicle_no);
                // $("#exp_delivery_at_msil").val(exp_delivery_at_msil);
                // $("#actual_delivery_at_msil").val(actual_delivery_at_msil);
                // $("#hidden_invoice_value").val(response.data[0].invoice_value);
                // $("#hidden_other_docs").val(response.data[0].other_docs);

        }).catch(function(error){
            console.log(error);
		})
        
       
    }

    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
    }
    
closeAlert = () => {
    this.setState({
        show: false
    });
    var pagetype = this.state.pagetype;
    if(pagetype == 1)
    {
        window.location.href="/chaaircargos";
    }
    else
    {
        window.location.href="/aircargos";
    }
}
changeHandler = (event) => {
    console.log("Select", event.target.value);
    let name = event.target.name;
    console.log(name);
    let value = event.target.value;
    //this.setState({[name]:value});
    if(name == "status")
    {   
        if(value == "Exam")
        {
            this.setState({
                loadshow : ""
            })
        }else{
            this.setState({
                loadshow : "show-n"
            })
            $("#exam_date").val("");
        }
    }
    if(name == "demmurage_charges")
    {
        if(value == "yes")
        {
            $("#rsndel").show();
        }
        else
        {
            $("#rsndel").hide();
            $("#reason_for_delay").val("");
        }
    }
    if(name == "lpc" || name == "interest")
    {
        var lpc = $("#lpc").val();
        var interest = $("#interest").val();
        if(lpc > 0 || interest > 0)
        {
            $("#lpcdel").show();
        }
        else
        {
            $("#lpcdel").hide();
            $("#lpc_reason_for_delay").val("");
            
        }
    }
    if(name == "demmurage_charges")
    {
        if(value == "no")
        {
            $("#demmurage_charges_amount").val(0);
        }
    }
    if(name == "demmurage_charges_amount")
    {
        var demmurage_charges = $("#demmurage_charges").val();
        if(demmurage_charges == "no")
        {
            $("#demmurage_charges_amount").val(0);
        }
    }
}
changeFileHandler = (e) => {
    console.log(e);
    this.setState({
        file : e.target.files[0]
    });
    console.log(e)
}

changeDocFileHandler = (e) => {
    console.log(e);
    this.setState({
        other_docs : e.target.files[0]
    });
    console.log(e)
}

formHandler(event){
    event.preventDefault();
    var igm_date = $("#igm_date").val();
    if(igm_date == "__-__-____" || igm_date == undefined)
    {
        var igm_date="";
    }
    var segregation_date = $("#segregation_date").val();
    if(segregation_date == "__-__-____" || segregation_date == undefined)
    {
        var segregation_date="";
    }
    var demmurage_start_date = $("#demmurage_start_date").val();
    if(demmurage_start_date == "__-__-____" || demmurage_start_date == undefined)
    {
        var demmurage_start_date="";
    }
    var exam_date = $("#exam_date").val();
    if(exam_date == "__-__-____" || exam_date == undefined)
    {
        var exam_date="";
    }
    var otp_date = $("#otp_date").val();
    if(otp_date == "__-__-____" || otp_date == undefined)
    {
        var otp_date="";
    }
    var ooc_date = $("#ooc_date").val();
    if(ooc_date == "__-__-____" || ooc_date == undefined)
    {
        var ooc_date="";
    }
    var bc_1_date = $("#bc_1_date").val();
    if(bc_1_date == "__-__-____" || bc_1_date == undefined)
    {
        var bc_1_date="";
    }
    var boe_date = $("#boe_date").val();
    if(boe_date == "__-__-____" || boe_date == undefined)
    {
        var boe_date="";
    }
    var bc_2_date = $("#bc_2_date").val();
    if(bc_2_date == "__-__-____" || bc_2_date == undefined)
    {
        var bc_2_date="";
    }
    var hawb_date = $("#hawb_date").val();
    if(hawb_date == "__-__-____" || hawb_date == undefined)
    {
        var hawb_date="";
    }
    var inward_date = $("#inward_date").val();
    if(inward_date == "__-__-____" || inward_date == undefined)
    {
        var inward_date="";
    }
    var ata_igi_airport = $("#ata_igi_airport").val();
    var status = $("#status").val();
    var boe_no = $("#boe_no").val();
    var demmurage_end_date = $("#demmurage_end_date").val();
    //var exp_delivery_at_msil = $("#exp_delivery_at_msil").val();
    //var actual_delivery_at_msil = $("#actual_delivery_at_msil").val();
    var eta_transit_port = $("#eta_transit_port").val();
    var exchange_rate = $("#exchange_rate").val();
    var assessable_value = $("#assessable_value").val();
    var bcd = $("#bcd").val();
    var sws = $("#sws").val();
    var igst = $("#igst").val();
    var total_custom_duty = $("#total_custom_duty").val();
    var lpc = $("#lpc").val();
    var interest = $("#interest").val();
    var meis = $("#meis").val();
    var duty_payable = $("#duty_payable").val();
    var lpc_reason_for_delay = $("#lpc_reason_for_delay").val();
    var bc_no_1 = $("#bc_no_1").val();
    var tsp_charges = $("#tsp_charges").val();
    var demmurage_charges = $("#demmurage_charges").val();
    var demmurage_charges_amount = $("#demmurage_charges_amount").val();
    var bc_no_2 = $("#bc_no_2").val();
    var reason_for_delay = $("#reason_for_delay").val();  
    //var vehicle_no = $("#vehicle_no").val();

    let saveData = new FormData();
  
    saveData.append("job_no",this.state.job_no);
    saveData.append("cargo_booking_id", this.state.cargoBookingId);
    saveData.append("igm_date", igm_date);
    //saveData.append("ata_igi_airport", ata_igi_airport);
    saveData.append("status", status);
    saveData.append("boe_no", boe_no);
    saveData.append("segregation_date", segregation_date);
    saveData.append("demmurage_start_date", demmurage_start_date);
    saveData.append("demmurage_end_date", demmurage_end_date);
    //saveData.append("exp_delivery_at_msil", exp_delivery_at_msil);
    //saveData.append("actual_delivery_at_msil", actual_delivery_at_msil);
    saveData.append("exam_date", exam_date);
    saveData.append("otp_date", otp_date);
    saveData.append("eta_transit_port", eta_transit_port);
    saveData.append("ooc_date", ooc_date);
    saveData.append("exchange_rate", exchange_rate);
    saveData.append("bc_1_date", bc_1_date);
    saveData.append("assessable_value", assessable_value);
    saveData.append("boe_date", boe_date);
    saveData.append("bcd", bcd);
    saveData.append("bc_2_date", bc_2_date);
    saveData.append("sws", sws);
    saveData.append("igst", igst);
    saveData.append("total_custom_duty", total_custom_duty);
    saveData.append("lpc", lpc);
    saveData.append("hawb_date", hawb_date);
    saveData.append("interest", interest);
    saveData.append("meis", meis);
    saveData.append("duty_payable", duty_payable);
    saveData.append("lpc_reason_for_delay", lpc_reason_for_delay);
    saveData.append("bc_no_1", bc_no_1);
    saveData.append("tsp_charges", tsp_charges);
    saveData.append("demmurage_charges", demmurage_charges);
    saveData.append("demmurage_charges_amount", demmurage_charges_amount);
    saveData.append("bc_no_2", bc_no_2);
    saveData.append("inward_date", inward_date);
    saveData.append("reason_for_delay", reason_for_delay);
    //saveData.append("vehicle_no", vehicle_no);
    saveData.append("airCargoChaId", this.state.airCargoChaId);
    saveData.append("updated_by", localStorage.getItem("email"));
    saveData.append("updated_user_id", localStorage.getItem("userid"));

    console.log(saveData);
    redirectURL.post("/consignments/savechaCargoData",saveData,{
        headers:{
            'content-type': 'multipart/form-data'
        }
    }).then((response)=>{  
        console.log(response) 
        this.setState({
            basicTitle:"Success",
            basicType : "success",
            show : true
        });
    }).catch((e)=>{
        console.log(e);
    })
        
    // }
    // else
    // { 
    //     this.setState({
    //         basicTitle:"Fields Should not be Empty",
    //         basicType : "warning",
    //         show : true
    //     })
    // }
}



uploadFormHandler(event){
    event.preventDefault();
    
    var formData = new FormData(event.target);
    formData.append('uploadFile',this.state.file);
    
    console.log('file',formData);
    redirectURL.post("/consignments/saveffCargoData",formData,{
        headers:{
            'content-type': 'multipart/form-data'
        }
    }).then((response)=>{   
        this.setState({
            basicTitle:"Success",
            basicType : "success",
            show : true
        });
        // window.location.reload();
    }).catch((e)=>{
        console.log(e);
    })
}


render(){
        
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Job No: {this.state.job_no}</span>
								</h5>
				   			</div>
                            <div className="card-body pt-15px">
                                <form class="aircargo-form" onSubmit={this.formHandler.bind(this)} encType="multipart/form-data">
                                    <div className="row col-xl-12">                                        
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">ATA IGI Airport : </label>
                                            <input type="text" name="ata_igi_airport" id="ata_igi_airport" className="form-control"  onChange={this.changeHandler} disabled/>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">IGM Date : </label>
                                            <input type="text" name="igm_date" id="igm_date" className="datetimepicker_date form-control"  onChange={this.changeHandler} />
                                        </div>    
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Inward Date : </label>
                                            <input type="text" name="inward_date" id="inward_date" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Segregation Date : </label>
                                            <input type="text" name="segregation_date" id="segregation_date" className="datetimepicker_date form-control" disabled={this.state.disableERP}  onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">BOE No: </label>
                                            <input type="text" name="boe_no" id="boe_no" className="form-control"  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">BOE Date : </label>
                                            <input type="text" name="boe_date" id="boe_date" className="datetimepicker_date form-control" disabled={this.state.disableERP}  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Status : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="status" id="status"  onChange={this.changeHandler}>
                                                <option value="">-- Select Option --</option>
                                                <option value="RMS">RMS</option>
                                                <option value="Exam">Exam</option>
                                            </select>
                                        </div>
                                        <div className={"col-xl-3 "+(this.state.loadshow)}>
                                            <label className="col-xl-12 col-lg-12">Exam Date : </label>
                                            <input type="text" name="exam_date" id="exam_date" className="datetimepicker_date form-control" disabled={this.state.disableERP}  onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">OTP Date : </label>
                                            <input type="text" name="otp_date" id="otp_date" className="datetimepicker_date form-control" disabled={this.state.disableERP}  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">OOC Date : </label>
                                            <input type="text" name="ooc_date" id="ooc_date" className="datetimepicker_date form-control" disabled={this.state.disableERP}  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Exchange rate : </label>
                                            <input type="text" name="exchange_rate" id="exchange_rate" className="form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Assessable Value: </label>
                                            <input type="text" name="assessable_value" id="assessable_value" className="form-control" onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">BCD : </label>
                                            <input type="text" name="bcd" id="bcd" className="form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">SWS : </label>
                                            <input name="sws" id="sws" className="form-control"  onChange={this.changeHandler}/>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">IGST : </label>
                                            <input name="igst" id="igst" className="form-control" onChange={this.changeHandler}/>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Total Custom Duty : </label>
                                            <input type="text" name="total_custom_duty" id="total_custom_duty" className="form-control"  onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">LPC : </label>
                                            <input type="text" name="lpc" id="lpc" className="form-control"  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Interest : </label>
                                            <input type="text" name="interest" id="interest" className="form-control"  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">MEIS : </label>
                                            <input type="text" name="meis" id="meis" className="form-control" placeholder="" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Duty payable : </label>
                                            <input type="text" name="duty_payable" id="duty_payable" className="form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4" id="lpcdel">
                                            <label className="col-xl-12 col-lg-12">Reason For Delay : </label>
                                            <textarea name="lpc_reason_for_delay" id="lpc_reason_for_delay" className="form-control" disabled={this.state.disableERP}  onChange={this.changeHandler}></textarea>
                                        </div>                                    
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">BC No 1 : </label>
                                            <input type="text" className="form-control" name="bc_no_1" id="bc_no_1"  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">BC-1 Date : </label>
                                            <input type="text" name="bc_1_date" id="bc_1_date" className="datetimepicker_date form-control" disabled={this.state.disableERP}  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">BC No 2 : </label>
                                            <input type="text" name="bc_no_2" id="bc_no_2" className="form-control" onChange={this.changeHandler} />
                                        </div>                                       
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">BC-2 Date : </label>
                                            <input type="text" name="bc_2_date" id="bc_2_date" className="datetimepicker_date form-control" disabled={this.state.disableERP}  onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>

                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">TSP charges : </label>
                                            <input type="text" name="tsp_charges" id="tsp_charges" className="form-control"  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Demmurage Charges : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="demmurage_charges" id="demmurage_charges"  onChange={this.changeHandler}>
                                                <option value="">-- Select Option --</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Demmurage Charges Amount : </label>
                                            <input type="text" name="demmurage_charges_amount" id="demmurage_charges_amount" className="form-control" onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Demmurage Start Date : </label>
                                            <input type="text" name="demmurage_start_date" id="demmurage_start_date" className="datetimepicker_date form-control" disabled={this.state.disableERP}  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Demmurage End Date : </label>
                                            <input type="text" name="demmurage_end_date" id="demmurage_end_date" className="datetimepicker_date form-control" disabled={this.state.disableERP}  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4" id="rsndel">
                                            <label className="col-xl-12 col-lg-12">Reason For Delay : </label>
                                            <textarea name="reason_for_delay" id="reason_for_delay" className="form-control" disabled={this.state.disableERP}  onChange={this.changeHandler}></textarea>
                                        </div>
                                    </div>
                                    <div className="row col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-success" style={{marginLeft:"45%",width:"192px",marginTop:"20px"}}>Submit</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>           
            </div>
        )
    }
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function cleanTruckNo(value){
    var result =  /\s/g.test(value);
    var cleanedValue="";
    try{
        if(result == true){
            cleanedValue = value.replace(/[^\w\s]/gi, '');
            cleanedValue = cleanedValue.replace(/\s/g, '').toUpperCase();
        }
        else{
            cleanedValue = value.toUpperCase();
        }
    }
    catch(e){  }
    return cleanedValue;
}


// function checkWhiteSpaces(value){
//     return /\s/g.test(value);
// }
// function removeWhiteSpace(value){
//     value = value.replace(/[^\w\s]/gi, '');
//     return value.replace(/\s/g, '').toUpperCase();
// }
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}


import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getHyphenDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import CSVFileValidator from 'csv-file-validator';
import Map from '../common/drawmap';
import FileBase64 from 'react-file-base64';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import save from 'material-ui/svg-icons/content/save';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var redirectURL  = require('../redirectURL');

export default class EditChaSeaImports extends Component{
    constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            csvcontent:"",
            file : "",
            loadshow:'show-n',
            overly:'show-n',
            uploadYard : "",
            uploadDispatchDate : "",
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            pagetitle:"",
            bulkslide:"",
            cargoBookingId:"",
            airCargoChaId:"",
            other_docs:"",
            file:"",
            loadshow : "show-n",
            hiddenotherdocs : "",
            hiddeninvoice : "",
            pagetype : 1,
            //components : {datepicker:getDatePicker()}
        }
        this.changeHandler = this.changeHandler.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        if (googleAnalytics.page.enableGA) {
			this.logPageView();
		}

        var inputEventval = window.atob(this.props.match.params.eventname);
        var pagetype = this.props.match.params.pagetype;
        console.log(pagetype,"pagetype")
        this.setState({
            pagetype : pagetype
        })
		// console.log
		var cargoBookingId="";
		var reqparams = {
            eventID : inputEventval,
        }

        redirectURL.post("/consignments/getSeaImportsByRequestId",reqparams).then((response)=>{
            console.log(response.data);
            var cargoBookingId=response.data[0]._id;
			this.setState({
				event : response.data[0].event,
				cargoBookingId : response.data[0]._id,
                job_no : response.data[0].job_no,
            })
            var resparams = {
                cargoBookingId : cargoBookingId,
            }
            //console.log(resparams)
            
            var gigm_date ="";
            if(response.data[0].gigm_date !=undefined && response.data[0].gigm_date !='')
            {
                var gigm_date = getHyphenDDMMYYYY(response.data[0].gigm_date);
                $("#gigm_date").val(gigm_date);
            }
            if(response.data[0].ligm_date !=undefined && response.data[0].ligm_date !='')
            {
                var ligm_date = getHyphenDDMMYYYY(response.data[0].ligm_date);
                $("#ligm_date").val(ligm_date);
            }
            if(response.data[0].boe_date !=undefined && response.data[0].boe_date !='')
            {
                var boe_date = getHyphenDDMMYYYY(response.data[0].boe_date);
                $("#boe_date").val(boe_date);
            }
            if(response.data[0].exam_date !=undefined && response.data[0].exam_date !='')
            {
                var exam_date = getHyphenDDMMYYYY(response.data[0].exam_date);
                $("#exam_date").val(exam_date);
            }
            if(response.data[0].ooc_date !=undefined && response.data[0].ooc_date !='')
            {
                var ooc_date = getHyphenDDMMYYYY(response.data[0].ooc_date);
                $("#ooc_date").val(ooc_date);
            }
            if(response.data[0].rms_status == "Exam" || response.data[0].rms_status == "RMS")
            {
                $('#rms_status option[value='+response.data[0].rms_status+']').attr("selected", "selected");
            }
            if(response.data[0].rms_status == "Exam")
            {
                this.setState({
                    loadshow : ""
                })
            }else{
                this.setState({
                    loadshow : "show-n"
                })
                $("#exam_date").val("");
            }
            if(response.data[0].gigm_no !=undefined && response.data[0].gigm_no !='')
            {
                $("#gigm_no").val(response.data[0].gigm_no);
            }
            if(response.data[0].ligm_no !=undefined && response.data[0].ligm_no !='')
            {
                $("#ligm_no").val(response.data[0].ligm_no);
            }
            if(response.data[0].boe_no !=undefined && response.data[0].boe_no !='')
            {
                $("#boe_no").val(response.data[0].boe_no);
            }
            if(response.data[0].lpc !=undefined && response.data[0].lpc !='')
            {
                $("#lpc").val(response.data[0].lpc);
            }
            if(response.data[0].lpc_amount !=undefined && response.data[0].lpc_amount !='')
            {
                $("#lpc_amount").val(response.data[0].lpc_amount);
            }
            if(response.data[0].lpc_reason_for_delay !=undefined && response.data[0].lpc_reason_for_delay !='')
            {
                $("#lpc_reason_for_delay").val(response.data[0].lpc_reason_for_delay);
            }
            if(response.data[0].custom_duty_amount !=undefined && response.data[0].custom_duty_amount !='')
            {
                $("#custom_duty_amount").val(response.data[0].custom_duty_amount);
            }
            if(response.data[0].social_welfare !=undefined && response.data[0].social_welfare !='')
            {
                $("#social_welfare").val(response.data[0].social_welfare);
            }
            if(response.data[0].igst !=undefined && response.data[0].igst !='')
            {
                $("#igst").val(response.data[0].igst);
            }
            if(response.data[0].any_other_duty !=undefined && response.data[0].any_other_duty !='')
            {
                $("#any_other_duty").val(response.data[0].any_other_duty);
            }
            if(response.data[0].custom_duty !=undefined && response.data[0].custom_duty !='')
            {
                $("#custom_duty").val(response.data[0].custom_duty);
            }
        }).catch(function(error){
            console.log(error);
		})
        
       
    }

    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
    }
    
closeAlert = () => {
    this.setState({
        show: false
    });
    var pagetype = this.state.pagetype;
    if(pagetype == 1)
    {
        window.location.href="/chaseaimports";
    }
    else
    {
        window.location.href="/seaimports";
    }
}
changeHandler = (event) => {
    console.log("Select", event.target.value);
    let name = event.target.name;
    console.log(name);
    let value = event.target.value;
    //this.setState({[name]:value});
    if(name == "rms_status")
    {   
        if(value == "Exam")
        {
            this.setState({
                loadshow : ""
            })
        }else{
            this.setState({
                loadshow : "show-n"
            })
            $("#exam_date").val("");
        }
    }
    if(name == "lpc")
    {
        var lpc = $("#lpc").val();
        if(lpc == "Yes")
        {
            $(".lpcdel").show();
            $("#lpc_amount").attr("required",true);
            $("#lpc_reason_for_delay").attr("required",true);
        }
        else
        {
            $(".lpcdel").hide();
            $("#lpc_amount").val("");
            $("#lpc_reason_for_delay").val("");   
            $("#lpc_amount").removeAttr("required",true);
            $("#lpc_reason_for_delay").removeAttr("required",true);         
        }
    }
}
changeFileHandler = (e) => {
    console.log(e);
    this.setState({
        file : e.target.files[0]
    });
    console.log(e)
}

changeDocFileHandler = (e) => {
    console.log(e);
    this.setState({
        other_docs : e.target.files[0]
    });
    console.log(e)
}

formHandler(event){
    event.preventDefault();
    var gigm_date = $("#gigm_date").val();
    if(gigm_date == "__-__-____" || gigm_date == undefined)
    {
        var gigm_date="";
    }
    var ligm_date = $("#ligm_date").val();
    if(ligm_date == "__-__-____" || ligm_date == undefined)
    {
        var ligm_date="";
    }
    var boe_date = $("#boe_date").val();
    if(boe_date == "__-__-____" || boe_date == undefined)
    {
        var boe_date="";
    }
    var exam_date = $("#exam_date").val();
    if(exam_date == "__-__-____" || exam_date == undefined)
    {
        var exam_date="";
    }
    var ooc_date = $("#ooc_date").val();
    if(ooc_date == "__-__-____" || ooc_date == undefined)
    {
        var ooc_date="";
    }
    var gigm_no = $("#gigm_no").val();
    var ligm_no = $("#ligm_no").val();
    var boe_no = $("#boe_no").val();
    var rms_status = $("#rms_status").val();
    var lpc = $("#lpc").val();
    var lpc_amount = $("#lpc_amount").val();
    var lpc_reason_for_delay = $("#lpc_reason_for_delay").val();
    var custom_duty_amount = $("#custom_duty_amount").val();
    var social_welfare = $("#social_welfare").val();
    var igst = $("#igst").val();
    var any_other_duty = $("#any_other_duty").val();
    var custom_duty = $("#custom_duty").val();
    //var vehicle_no = $("#vehicle_no").val();

    let saveData = new FormData();
  
    saveData.append("job_no",this.state.job_no);
    saveData.append("cargo_booking_id", this.state.cargoBookingId);
    saveData.append("gigm_no", gigm_no);
    saveData.append("gigm_date", gigm_date);
    saveData.append("ligm_no", ligm_no);
    saveData.append("ligm_date", ligm_date);
    saveData.append("boe_no", boe_no);
    saveData.append("boe_date", boe_date);
    saveData.append("rms_status", rms_status);
    saveData.append("exam_date", exam_date);
    saveData.append("ooc_date", ooc_date);
    saveData.append("lpc", lpc);
    saveData.append("lpc_amount", lpc_amount);
    saveData.append("lpc_reason_for_delay", lpc_reason_for_delay);
    saveData.append("custom_duty_amount", custom_duty_amount);
    saveData.append("social_welfare", social_welfare);
    saveData.append("igst", igst);
    saveData.append("any_other_duty", any_other_duty);
    saveData.append("custom_duty", custom_duty);
    saveData.append("updated_by", localStorage.getItem("email"));
    saveData.append("updated_user_id", localStorage.getItem("userid"));

    console.log(saveData);
    redirectURL.post("/consignments/savechaseaimports",saveData,{
        headers:{
            'content-type': 'multipart/form-data'
        }
    }).then((response)=>{  
        console.log(response) 
        this.setState({
            basicTitle:"Success",
            basicType : "success",
            show : true
        });
    }).catch((e)=>{
        console.log(e);
    })
}

render(){
        
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Job No: {this.state.job_no}</span>
								</h5>
				   			</div>
                            <div className="card-body pt-15px">
                                <form class="aircargo-form" onSubmit={this.formHandler.bind(this)} encType="multipart/form-data">
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">GIGM No : </label>
                                            <input type="text" name="gigm_no" id="gigm_no" className="form-control"  onChange={this.changeHandler} />
                                        </div>    
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">GIGM Date : </label>
                                            <input type="text" name="gigm_date" id="gigm_date" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">LIGM NO : </label>
                                            <input type="text" name="ligm_no" id="ligm_no" className="form-control"   onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">LIGM Date : </label>
                                            <input type="text" name="ligm_date" id="ligm_date" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">BOE No: </label>
                                            <input type="text" name="boe_no" id="boe_no" className="form-control"  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">BOE Date : </label>
                                            <input type="text" name="boe_date" id="boe_date" className="datetimepicker_date form-control" disabled={this.state.disableERP}  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Type : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="rms_status" id="rms_status"  onChange={this.changeHandler}>
                                                <option value="">-- Select Option --</option>
                                                <option value="RMS">RMS</option>
                                                <option value="Exam">Exam</option>
                                            </select>
                                        </div>
                                        <div className={"col-xl-3 "+(this.state.loadshow)}>
                                            <label className="col-xl-12 col-lg-12">Exam Date : </label>
                                            <input type="text" name="exam_date" id="exam_date" className="datetimepicker_date form-control" disabled={this.state.disableERP}  onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">OOC Date : </label>
                                            <input type="text" name="ooc_date" id="ooc_date" className="datetimepicker_date form-control" disabled={this.state.disableERP}  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">LPC : </label>
                                            <select name="lpc" id="lpc" className="form-control"  onChange={this.changeHandler}>
                                                <option value="">Select Option</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>
                                        <div className="col-xl-3 lpcdel">
                                            <label className="col-xl-12 col-lg-12">Amount : </label>
                                            <textarea name="lpc_amount" id="lpc_amount" className="form-control" onChange={this.changeHandler}></textarea>
                                        </div>
                                        <div className="col-xl-3 lpcdel">
                                            <label className="col-xl-12 col-lg-12">Reason : </label>
                                            <textarea name="lpc_reason_for_delay" id="lpc_reason_for_delay" className="form-control"   onChange={this.changeHandler}></textarea>
                                        </div> 
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Basic Custom Duty Amount : </label>
                                            <input name="custom_duty_amount" id="custom_duty_amount" className="form-control"  onChange={this.changeHandler}/>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Social Welfare Charges : </label>
                                            <input name="social_welfare" id="social_welfare" className="form-control"  onChange={this.changeHandler}/>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">IGST : </label>
                                            <input name="igst" id="igst" className="form-control" onChange={this.changeHandler}/>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Any other Duty : </label>
                                            <input type="text" name="any_other_duty" id="any_other_duty" className="form-control"  onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Total Custom Duty Paid : </label>
                                            <input type="text" className="form-control" name="custom_duty" id="custom_duty"  onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-success" style={{marginLeft:"45%",width:"192px",marginTop:"20px"}}>Submit</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>           
            </div>
        )
    }
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function cleanTruckNo(value){
    var result =  /\s/g.test(value);
    var cleanedValue="";
    try{
        if(result == true){
            cleanedValue = value.replace(/[^\w\s]/gi, '');
            cleanedValue = cleanedValue.replace(/\s/g, '').toUpperCase();
        }
        else{
            cleanedValue = value.toUpperCase();
        }
    }
    catch(e){  }
    return cleanedValue;
}


// function checkWhiteSpaces(value){
//     return /\s/g.test(value);
// }
// function removeWhiteSpace(value){
//     value = value.replace(/[^\w\s]/gi, '');
//     return value.replace(/\s/g, '').toUpperCase();
// }
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}


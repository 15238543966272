/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "@grapecity/wijmo.styles/wijmo.css";
import * as pdf from "@grapecity/wijmo.pdf";
import * as wijmo from "@grapecity/wijmo";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';

var moment = require('moment');

export default class ExportPDF extends Component {

    constructor(props) {
        super(props);
        // this.colWidth = 130;
        this.rowHeight = 50;
        this.state =
        {
            rowData: [],
            pdfColumns: [],
            adaniCheck: 0,
            addressColumn: "",
            colWidth: null

        };
    }

    componentDidMount() {
        // console.log(this.props,"cnsole")
        var rowData = this.props.rowData;
        var pdfColumns = this.props.pdfColumns;

        console.log(rowData,pdfColumns,"34")
        var addressColumnWidth = 300
        // console.log("pdfrowData", rowData)
        if (addressColumnWidth == "undefined" || addressColumnWidth == "" || addressColumnWidth == null) {
            this.setState({
                colWidth: 130
            })
        } else {
            this.setState({
                colWidth: addressColumnWidth
            })
        }
        this.setState({
            rowData: rowData,
            pdfColumns: pdfColumns
        })
        // console.log("rowDataTest: => ", rowData)
    }

    componentWillReceiveProps(newprops) {
        // console.log("receivedProps",newprops.rowData)
        var rowData = newprops.rowData;
        var pdfColumns = newprops.pdfColumns;
        var addressColumnWidth = newprops.addressColumnWidth
        console.log(rowData,pdfColumns,"34")
        this.setState({
            rowData: rowData,
            pdfColumns: pdfColumns
        })
    }

    exportPDF() {
        let doc = new pdf.PdfDocument({
            header: {
                declarative: {
                    text: this.props.pagetitle,
                    font: new pdf.PdfFont('times', 20),
                    brush: '#bfc1c2'
                }
            },
            footer: { declarative: { text: '\t&[Page] of &[Pages]' } },
            lineGap: 2,
            pageSettings: {
                margins: {
                    left: 10,
                    right: 10,
                    top: 10,
                    bottom: 10
                },
                size: "A2"
            },
            pageAdded: function (s, e) {
                let image = require('../../assets/icons/logo.png');
                let enmovilImage = require('../../assets/icons/enmovil.png');
                //
                s.drawImage(image, null, null, {
                    width: 150,
                    height: 30,
                    stretchProportionally: true,
                    align: pdf.PdfImageHorizontalAlign.Center
                });
                s.drawImage(enmovilImage, 1000, 0, {
                    width: 100,
                    height: 30,
                    stretchProportionally: true,
                    align: pdf.PdfImageHorizontalAlign.Right
                });
            },
            ended: (sender, args) => pdf.saveBlob(args.blob, 'Document.pdf')
           
        });


        doc.footer.drawText("Report generated on DLP by Enmovil Solutions", null, null, {
            align: pdf.PdfTextHorizontalAlign.Right
        });
        var consignees = this.state.rowData;
        // console.log("cCheck=:",this.state.rowData)
        // console.log(consignees,"consignees")

        consignees.map(function (c) {

            try {
                if (c.invoice_time != undefined && c.invoice_time != '') {
                    c.invoice_time = moment.parseZone(c.invoice_time).format('YYYY-MM-DD HH:mm:ss');
                }
                else {
                    c.invoice_time = "";
                }
            }
            catch (e) {
                c.invoice_time = "";
            }

            try {
                if (c.gate_in_time != undefined && c.gate_in_time != '') {
                    c.gate_in_time = moment.parseZone(c.gate_in_time).format('YYYY-MM-DD HH:mm:ss');
                }
                else {
                    c.gate_in_time = "";
                }
            }
            catch (e) {
                c.gate_in_time = "";
            }
            try {
                if (c.gate_out_time != undefined && c.gate_out_time != '') {
                    c.gate_out_time = moment.parseZone(c.gate_out_time).format('YYYY-MM-DD HH:mm:ss');
                }
                else {
                    c.gate_out_time = "";
                }
            }
            catch (e) {
                c.gate_out_time = "";
            }
            try {
                if (c.recent_dealer_reported != undefined && c.recent_dealer_reported != '') {
                    c.recent_dealer_reported = moment.parseZone(c.recent_dealer_reported).format('YYYY-MM-DD HH:mm:ss');
                }
                else {
                    c.recent_dealer_reported = "";
                }
            }
            catch (e) {
                c.recent_dealer_reported = "";
            }
            try {
                if (c.left_dealer_marked != undefined && c.left_dealer_marked != '') {
                    c.left_dealer_marked = moment.parseZone(c.left_dealer_marked).format('YYYY-MM-DD HH:mm:ss');
                }
                else {
                    c.left_dealer_marked = "";
                }
            }
            catch (e) {
                c.left_dealer_marked = "";
            }

     

        });
        if (consignees.length > 60 ) {
            var totalPages = (consignees.length / 60).toString();
            var lastRemainder = parseInt(totalPages.split(".")[1])
            totalPages = parseInt(totalPages.split(".")[0])
            //console.log(totalPages,"totalPages")
            //console.log(lastRemainder,"lastRemainder")
            var endPage = 60
            var startPage = 0
            while (consignees.length !== 0) {
                let sen = consignees.splice(0, 60)
                this.$_drawEmployee(doc, sen);
            }
            // for (var i = 0; i < totalPages; i++) {
            //     //console.log(startPage, endPage);
            //     var sen = consignees.slice(startPage, endPage);
            //     this.$_drawEmployee(doc, sen);

            //     if ((i + 1) == totalPages) {
            //         var sen1 = consignees.slice(endPage + 1, lastRemainder);
            //         this.$_drawEmployee(doc, sen1);
            //     }
            //     else {

            //         startPage = endPage + 1;
            //         endPage = endPage + 75;
            //     }

            // }
        }
        else {
            this.$_drawEmployee(doc, consignees);
        }

        // consignees.forEach((employee, i, arr) => {
        //     this.$_drawEmployee(doc, employee);
        //     if (i < arr.length - 1) {
        //         doc.addPage();
        //     }
        // });

        doc.end();
        window.location.reload()
    }
    //
    $_drawEmployee(doc, consignees) {
        //let tot = employee.expenses.totals;
        let expenses = consignees;
        // console.log(expenses, "expenses")

        if (expenses.length > 0) {
            let columns = this.state.pdfColumns, bold = new pdf.PdfFont('times', 40, 'normal', 'bold'),
                colWidth = this.state.colWidth, rowHeight = this.rowHeight;

            let y = doc.y;

            doc.moveDown(2);

            // * draw table *
            doc.saveState();

            y = 0;
            let scale = doc.width / (columns.length * colWidth), docY = doc.y;

            if (scale > 1) {
                scale = 1;
            }

            doc.scale(scale, scale, new wijmo.Point(0, docY));
            doc.translate(0, docY);

            // header
            this.$_renderRow(doc, y, columns, column => column.header, null, bold, '#fad9cd');

            y += rowHeight;

            // body

            expenses.map((item, index) => {
                //this.$_checkLineAvailable(doc);  
                this.$_renderRow(doc, y, columns, column => item[column.binding], column => column.format  ,bold = new pdf.PdfFont('times', 30, 'normal'));
                y += rowHeight;
            });

            doc.addPage(doc.currentPageSettings);
            doc.footer.drawText("Report generated on enmovil.io by Enmovil Solutions", null, null, {
                align: pdf.PdfTextHorizontalAlign.Right
            });

        }
    }
    //
    $_checkLineAvailable(doc) {
        if (doc.height - doc.y < doc.lineHeight() + doc.lineGap) {
            doc.addPage();
        }
    }
    //
    $_renderRow(doc, y, values, valueGetter, formatGetter, font, brush) {
        let colWidth = this.state.colWidth, rowHeight = this.rowHeight;
        //
        values.forEach((v, idx) => {
            let x = idx * colWidth;
            //
            doc.paths
                .rect(x, y, colWidth, rowHeight)
                //.fill(brush || '#eaeaea')
                .stroke();
            //
            let value = valueGetter != null ? valueGetter(v) : v || '';
            let format = formatGetter != null ? formatGetter(v) : '';
            //
            if (value !== 'Total') {
                value = wijmo.changeType(value, wijmo.DataType.String, format);
            }
            //
            doc.drawText(value, x + 2, y + 2, {
                font: font,
                height: rowHeight,
                width: colWidth,
                align: pdf.PdfTextHorizontalAlign.Center
            });
        });
        doc.moveDown(5);
    }

    render() {
        return (
            <button className="custom-btn btn btn-danger" style={{ float: "left", marginRight: "10px" }} onClick={this.exportPDF.bind(this)}>Export To PDF</button>

        )
    }
}


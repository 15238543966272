import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import $ from "jquery";
import _ from "lodash";
import MarkerClusterer from "@google/markerclusterer";
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import CountUp from 'react-countup';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
// import { ChartMenu } from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import RoutemapAction from '../manage/routemapActionComponent';

import {
    getDDMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenYYYYMMDD,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault
} from '../common/utils';
var redirectURL = require('../redirectURL');

var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");
var breakmarkers=[]
var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords = [];
var map;
var mapZoom = 5;
var rad = function (x) {
    return x * Math.PI / 180;
};
var rlatitude;
var rlongitude;
var bounds;
var fencingLength = 0;
var geoFencingArea;
export default class CatalyticMissingBreakClusters extends Component {

    constructor(props) {
        super(props);
        console.log(this.props.match.url)
        this.displayData = [];
        this.state = {
            pageTitle: "",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            defaultColDef: { sortable: true },
            showform: 'show-m',
            loadshow: 'show-n',
            showrefresh: 'show-n',
            sliderTranslate: '',
            showdata: this.displayData,
            radius: '5000',
            rlatitude: '',
            rlongitude: '',
            latitude: '',
            longitude: '',
            defaultradius: 0,
            coordinates: [],
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            contentString: "testing",
            mwidth: "col-xl-12 col-lg-12",
            alldepts: [],
            transportervalue: 'ALL',
            alltransporters: [],
            truckstatus: '0',
            kpicode: '0',
            deptcode: '',
            truckNoList: [],
            alltrucks: [],
            truckno: '',
            showTrucksList: "show-n",
            filteredData: [],
            triptype: { "value": 1, "label": "ALL" },
            plantLocations: [],
            totalTrucksCount: 0,
            onTripCounter: 0,
            breaksData: [],
            breaksClusterData: [],
            breaksClusterDataCopy: [],
            tolls: [],
            plants: [],
            selectedTrucks : [],
            selectedState: { "label": "Haryana", "value": "Haryana" },
            modules: AllModules,
            detailCellRendererParams: {},
            truckslist : [],
            pageName : '',
            frameworkComponents : {
                RoutemapAction : RoutemapAction
            }
        };
        this.onShowRouteMap = this.onShowRouteMap.bind(this);
        this.catamissBreaks = this.catamissBreaks.bind(this);
        this.onClickShowAllBreaks = this.onClickShowAllBreaks.bind(this);
        this.onClickShowEvaluatedBreaks = this.onClickShowEvaluatedBreaks.bind(this);
    }
    /*Alert Popups*/
    closeAlert = () => {
        this.setState({
            show: false
        });
    }


    hideAlert = () => {
        this.setState({
            alert: null
        });
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    componentDidMount() {
        //console.log(this.props.match.)
        this.logPageView(); // Log page view to GA

        this.setState({
            loadshow: 'show-m',
            coordinates: [],
            rowData: [],
            pageName: 'All'
        });
        var parameters = {
            pagetype:"all",
        }
        this.catamissBreaks(parameters);

    }

    catamissBreaks(parameters){
        redirectURL.post("/vicinity/getBreakClusterInitialData", parameters).then((response) => {
            var trucks = [];
            response.data.trucks.map(function(e){
                trucks.push({"label":e,"value":e})
            })
            this.setState({
                tolls: response.data.tolls,
                plants: response.data.plants,
                truckslist : trucks,
                loadshow: "show-n",
                overly: "show-n"
            });
            var state = this.state.selectedState.value;
            redirectURL.post("/vicinity/getCatalyticClusters", parameters).then((response) => {
                console.log(response.data.break_clusters);
                console.log(response.data.breaks)
                this.setState({
                    breaksClusterDataCopy : response.data.break_clusters,
                    breaksClusterData : response.data.break_clusters,
                    breaksData: response.data.breaks,
                    overly: "show-n",
                    loadshow: "show-n"
                })
                this.renderMap();
            }).catch((e) => {
                console.log(e);
            });
        }).catch((e) => {
            console.log(e);
        })
    }

    onClickShowAllBreaks()
    {
        this.setState({
            loadshow: "",
            overly: "",
            pageName: 'All'
        });
        var parameters = {
            pagetype:"all",
        }
        this.catamissBreaks(parameters);
    }

    onClickShowEvaluatedBreaks()
    {
        this.setState({
            loadshow: "",
            overly: "",
            pageName: 'Exclusive'
        });
        var parameters = {
            pagetype:"evaluated",
        }
        this.catamissBreaks(parameters);
    }

    renderMap = () => {

        loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMap");
        window.initMap = this.initMap
    }

    initMap = () => {
        bounds = new window.google.maps.LatLngBounds();
        map = new window.google.maps.Map(document.getElementById('breaks_cluster_map'), {
            zoom: 5,
            center: new window.google.maps.LatLng(28.4595, 77.0266),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_RIGHT
            },
            disableDefaultUI: true,
            fullscreenControl: true,
            zoomControl: true
        });
        // if(this.state.tolls.length > 0)
        // {
        //     var infowindow = new window.google.maps.InfoWindow();
        //     var l;
        //     var selectedState = this.state.selectedState.value;
        //     this.state.tolls.map(function(e,index){
        //         if(e.state != undefined)
        //         {
        //             if(e.state.toLowerCase() == selectedState.toLowerCase())
        //             {
        //                 bounds.extend(new window.google.maps.LatLng(e.lat,e.lon));
        //                 var tollMarker = new window.google.maps.Marker({
        //                     position: new window.google.maps.LatLng(e.lat, e.lon),
        //                     icon:require('../../assets/icons/barrier.png'),
        //                     map: map,
        //                 });
        //                 window.google.maps.event.addListener(tollMarker, 'click', (function(tollMarker, index) {
        //                     return function() 
        //                     {
        //                         var contentarr = []
        //                         var header = "Toll - "+e.name
        //                         contentarr.push({"key":"Address", "value":e.location})
        //                         var contentString = infoBox(tollMarker.icon, header, contentarr)
        //                         infowindow.setContent(contentString);
        //                         var currentwindow = infowindow;
        //                         infowindow.open(map, tollMarker);
        //                     }
        //                 })(tollMarker, index));
        //                 window.google.maps.event.addListener(tollMarker, 'mouseout', function() {
        //                     infowindow.close();
        //                     });
        //             }
        //         }


        //     })  

        // }
        if (this.state.plants.length > 0) {
            this.state.plants.map(function (params) {
                var coords = JSON.parse(params.coordinates);
                bounds.extend(new window.google.maps.LatLng(coords.lat, coords.lng));
                var plant = {
                    url: require("../../assets/icons/" + params.icon), // url
                    scaledSize: new window.google.maps.Size(32, 32), // scaled size
                    origin: new window.google.maps.Point(0, 0), // origin
                    anchor: new window.google.maps.Point(0, 0) // anchor
                };

                // console.log(coords);
                var marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(coords.lat, coords.lng),
                    map: map,
                    icon: plant,
                    title: params.plant_name
                });
                var cityCircle = new window.google.maps.Circle({
                    strokeColor: '#9fc9b9',
                    strokeOpacity: 0.3,
                    strokeWeight: 2,
                    fillColor: '#9fc9b9',
                    fillOpacity: 0.6,
                    map: map,
                    center: coords,
                    radius: 5000
                });
            })
        }
        try {
            var infowindow = new window.google.maps.InfoWindow();
            breakmarkers=[]
            this.state.breaksClusterData.map(function (breaks, index) {
                bounds.extend(new window.google.maps.LatLng(breaks.lat, breaks.lng));
                var breaksmarker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(breaks.lat, breaks.lng),
                    map: map,
                    icon: require("../../assets/icons/cf.png"),
                    // title : ""+params.address+","+params.forecast_date+","+params.description
                });
                breakmarkers.push(breaksmarker);
                window.google.maps.event.addListener(breaksmarker, 'click', (function (breaksmarker, index) {
                    return function () {
                        var contentarr = []
                        var header = breaks.area
                        contentarr.push({ "key": "No. of breaks", "value": breaks.no_of_breaks })
                        contentarr.push({ "key": "Latitude", "value": breaks.lat })
                        contentarr.push({ "key": "Longitude", "value": breaks.lng })
                        if (breaks.near_toll == 1) {
                            contentarr.push({ "key": "Near Toll", "value": "" })
                            contentarr.push({ "key": "Toll Name", "value": breaks.toll_name })
                            contentarr.push({ "key": "Distance From Toll (KM)", "value": breaks.distance_from_toll })
                        }
                        if (breaks.near_state_border == 1) {
                            contentarr.push({ "key": "Near State Border", "value": "" })
                            contentarr.push({ "key": "State Border Name", "value": breaks.state_border_name })
                            contentarr.push({ "key": "Distance From State Border (KM)", "value": breaks.distance_from_state_border })
                        }
                        var contentString = infoBox(breaksmarker.icon, header, contentarr)
                        infowindow.setContent(contentString);
                        var currentwindow = infowindow;
                        infowindow.open(map, breaksmarker);
                    }
                })(breaksmarker, index));
                window.google.maps.event.addListener(breaksmarker, 'mouseout', function () {
                    infowindow.close();
                });
                // breaksmarker.setAnimation(window.google.maps.Animation.BOUNCE);
                // setTimeout(function(){ breaksmarker.setAnimation(null); }, 750);

            })
        } catch (e) {
            console.log(e, "Breaks data is not found");
        }
        map.addListener('zoom_changed', function () {
            mapZoom = map.getZoom();
        });
        map.fitBounds(bounds)
    }

    setSeletectedTrucks(event) {
        this.setState({
            selectedTrucks : event
        })
    }
    getTrucksClusters() {
        this.setState({
            overly: "show-m",
            loadshow: "show-m"
        })
        var truckslist = [];
        if(this.state.selectedTrucks != "" && this.state.selectedTrucks != null && this.state.selectedTrucks != undefined)
        {
            this.state.selectedTrucks.map(function(e){
                truckslist.push(e.value);
            })
        }
        redirectURL.post("/vicinity/filterTrucksBreaksCluster", { "truckslist": truckslist }).then((response) => {
            var filteredCluster = response.data;
            console.log(filteredCluster)
            var rowdata = this.state.breaksClusterDataCopy
            filteredCluster = rowdata.filter(function(e){
                if(filteredCluster.includes(e.cluster))
                {
                    return e;
                }
            })
            this.gridApi.setRowData(filteredCluster)
            this.setState({
                // trucks: response.data,
                breaksClusterData : filteredCluster,
                overly: "show-n",
                loadshow: "show-n"
            })
            this.renderMap();
            
        }).catch((e) => {
            console.log(e);
        });

    }
    async focusBreakPoint(event) {
        if (event.colDef.field == "_id") {
            var breaksdata = this.state.breaksData;
            var initialBounds = bounds;
            bounds = new window.google.maps.LatLngBounds();
            console.log("step 1")
            var filterData = await breaksdata.filter(function (e) {
                return e.cluster == event.data.cluster;
            });
            
            await filterData.map(function(e){
                bounds.extend(new window.google.maps.LatLng(e.lat, e.lng));
            });
            map.fitBounds(bounds);
            map.setZoom(13)
            var breaksheaders = [
                // {
                //     headerName: "",
                //     field: "",
                //     resizable: true,
                //     width: 70,
                //     filter: true,
                //     cellRendererFramework : RoutemapAction
                // },
                {
                    headerName: "Latitude",
                    field: "lat",
                    resizable: true,
                    width: 100,
                    filter: true
                },
                {
                    headerName: "Longitude",
                    field: "lng",
                    resizable: true,
                    width: 100,
                    filter: true
                },
                {
                    headerName: "Truck No",
                    field: "truck_no",
                    resizable: true,
                    width: 100,
                    filter: true
                },
                {
                    headerName: "Consignment Codes",
                    field: "consignment_codes",
                    resizable: true,
                    width: 150,
                    filter: true,
                    valueGetter: function (params) {
                        if(params.data.consignment_codes != undefined && params.data.consignment_codes != "")
                        {   
                            return params.data.consignment_codes.join(", ")
                        }
                        
                    }
                },
                {
                    headerName: "Transporter Code",
                    field: "transporter_code",
                    resizable: true,
                    width: 130,
                    filter: true
                },
                {
                    headerName: "Transporter Name",
                    field: "transporter_name",
                    resizable: true,
                    width: 130,
                    filter: true
                },
                {
                    headerName: "Vehicle Mode",
                    field: "vehicle_mode",
                    resizable: true,
                    width: 130,
                    filter: true
                },
                {
                    headerName: "Consigner code",
                    field: "consigner_code",
                    resizable: true,
                    width: 130,
                    filter: true
                },
                {
                    headerName: "Consginee Codes",
                    field: "consignee_code",
                    resizable: true,
                    width: 130,
                    filter: true,
                    valueGetter : function(params)
                    {
                        if(params.data.consignee_code != undefined && params.data.consignee_code != "")
                        {
                            return params.data.consignee_code.join(", ")
                        }
                    }
                },
                {
                    headerName: "Consginee Names",
                    field: "consignee_name",
                    resizable: true,
                    width: 130,
                    filter: true,
                    valueGetter : function(params)
                    {
                        if(params.data.consignee_name != undefined && params.data.consignee_name != "")
                        {
                            return params.data.consignee_name.join(", ")
                        }
                    }
                },
                {
                    headerName: "Consginee City",
                    field: "consignee_city",
                    resizable: true,
                    width: 130,
                    filter: true,
                    valueGetter : function(params)
                    {
                        if(params.data.consignee_city != undefined && params.data.consignee_city != "")
                        {
                            return params.data.consignee_city.join(", ")
                        }
                    }
                },
                {
                    headerName: "Consginee States",
                    field: "consignee_state",
                    resizable: true,
                    width: 130,
                    filter: true,
                    valueGetter : function(params)
                    {
                        if(params.data.consignee_state != undefined && params.data.consignee_state != "")
                        {
                            return params.data.consignee_state.join(", ")
                        }
                    }
                },
                {
                    headerName : "Cluster ID",
                    field : "cluster_id",
                    resizable : true,
                    width : 110,
                    filter : true,
                },
                {
                    headerName : "route ID",
                    field : "route_id",
                    resizable : true,
                    width : 110,
                    filter : true
                },
                {
                    headerName: "Area / City",
                    field: "area",
                    resizable: true,
                    width: 130,
                    filter: true
                },
                {
                    headerName: "State",
                    field: "state",
                    resizable: true,
                    width: 130,
                    filter: true
                },
                // {
                //     headerName: "Break No",
                //     field: "break_number",
                //     resizable: true,
                //     width: 100,
                //     filter: true
                // },
                {
                    headerName: "Break Start",
                    field: "break_start",
                    resizable: true,
                    width: 130,
                    filter: true,
                    valueGetter: function (parameter) {
                        return getHyphenDDMMMYYYYHHMM(parameter.data.break_start)
                    }
                },
                {
                    headerName: "Break End",
                    field: "break_end",
                    resizable: true,
                    width: 130,
                    filter: true,
                    valueGetter: function (parameter) {
                        return getHyphenDDMMMYYYYHHMM(parameter.data.break_end)
                    }

                },
                {
                    headerName: "Break Time",
                    field: "break_time_mins",
                    resizable: true,
                    width: 130,
                    filter: true,
                    valueGetter: function (params) {
                        return ConvertMinutes(params.data.break_time_mins)
                    }
                },
                {
                    headerName: "Distance From Plant",
                    field: "distance_from_plant",
                    resizable: true,
                    width: 110,
                    filter: true
                },
                {
                    headerName: "Distance From Dealer",
                    field: "distance_from_dealer",
                    resizable: true,
                    width: 110,
                    filter: true
                },
                {
                    headerName: "Near Toll",
                    field: "near_toll",
                    resizable: true,
                    width: 110,
                    filter: true,
                    valueGetter: function (params) {
                        if (params.data.near_toll) {
                            return "Yes";
                        }
                        else {
                            return "No";
                        }
                    }
                },
                {
                    headerName: "Near State Border",
                    field: "near_state_border",
                    resizable: true,
                    width: 110,
                    filter: true,
                    valueGetter: function (params) {
                        if (params.data.near_state_border) {
                            return "Yes";
                        }
                        else {
                            return "No";
                        }
                    }
                },

            ]
            //console.log("Step 3 ",overspeedData)
            var propsdata = event;
            console.log(filterData,"step2")
            await this.setState({
                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: breaksheaders,
                        overlayNoRowsTemplate: 'No rows to show',
                        refreshStrategy: 'everything',
                    },
                    getDetailRowData:async function (param) {
                        var insidefilterData = await breaksdata.filter(function (e) {
                            return e.cluster == param.data.cluster;
                        });
                        console.log("inside filterData ", insidefilterData)
                        param.successCallback(insidefilterData);
                        // param.successCallback([]);
                        // // await param.successCallback(filterData);
                        // await redirectURL.post("/vicinity/filterBreaksCluster",{cluster:event.data.cluster}).then(async (response) =>{
                        //     console.log(response.data,"cluster data");
                        //     param.successCallback(response.data);                            
                        // }).catch(function(error){
                        //     console.log(error);
                        // })
                    },
                    masterDetail: true
                }
            });
            if (propsdata.colDef.field == '_id') {
                propsdata.node.setExpanded(!propsdata.node.expanded);
                if(propsdata.node.expanded == false)
                {
                    map.setZoom(5)
                }
                else
                {
                    breakmarkers.map(function(e){
                        e.setAnimation(window.google.maps.Animation.BOUNCE);
                        setTimeout(function(){ e.setAnimation(null); }, 750);
                    })
                }
            }
            else {
                propsdata.node.setExpanded(false);
            }

           
        }
    }
    resetZoom() {
        // map.fitBounds(bounds)
        map.setZoom(5)
    }
    rowSelection(){
        console.log("row")
    }
    onShowRouteMap(data){
        map.setCenter(new window.google.maps.LatLng(data.lat,data.lng));
        map.setZoom(22)
    }
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
		var sort = [
			{
			  colId: 'no_of_breaks',
			  sort: 'desc',
			},
		  ];
		this.gridApi.setSortModel(sort);
	};
	
    render() {

        const dStyles = {
            width: '90%',
            marginLeft: '0px',
            paddingLeft: '0px',
            height: '500px'
        }
        const columnwithDefs = [
            {
                headerName: "",
                field: "_id",
                colId: '_id',
                resizable: true,
                filter: true,
                editable: false,
                width: 60,
                cellRenderer: function (params) {
                    return "<i class='icofont icofont-coffee-cup cus-i'></i>"
                }
            },
            {
                headerName: "No. of Breaks",
                field: "no_of_breaks",
                colId: 'no_of_breaks',
                resizable: true,
                width: 120,
                filter: true,
                editable: false
            },
            {
                headerName: "Area / City",
                field: "area",
                colId: 'area',
                resizable: true,
                filter: true,
                editable: false
            },
            {
                headerName: "State",
                colId: 'state',
                field: "state",
                resizable: true,
                filter: true,
                editable: false
            },
            {
                headerName: "Latitude",
                field: "lat",
                colId: 'lat',
                resizable: true,
                width: 120,
                filter: true,
                editable: false
            },
            {
                headerName: "Longitude",
                field: "lng",colId: 'lng',
                resizable: true,
                width: 120,
                filter: true,
                editable: false
            },
            {
                headerName : "Near Toll",
                field : "near_toll",
                resizable : true,
                width : 120,
                filter : true,
                editable : false,
                valueGetter : function(params){
                    if(params.data.near_toll)
                    {
                        return "Yes";
                    }
                    else
                    {
                        return "No"
                    }
                }
            },
            {
                headerName: "Toll Name",
                field: "toll_name",
                colId: 'toll_name',
                resizable: true,
                width: 150,
                filter: true,
                editable: false
            },
            {
                headerName: "Distance from Toll (KM)",
                field: "distance_from_toll",
                colId: 'distance_from_toll',
                resizable: true,
                width: 150,
                filter: true,
                editable: false
            },
            {
                headerName : "Near State Border",
                field : "near_state_border",
                resizable : true,
                width : 120,
                filter : true,
                editable : false,
                valueGetter : function(params){
                    if(params.data.near_state_border)
                    {
                        return "Yes";
                    }
                    else
                    {
                        return "No"
                    }
                }
            },
            {
                headerName: "State Border Name",
                field: "state_border_name",
                colId: 'state_border_name',
                resizable: true,
                width: 150,
                filter: true,
                editable: false
            },
            {
                headerName: "Distance from State Border (KM)",
                field: "distance_from_state_border",
                colId: 'distance_from_state_border',
                resizable: true,
                width: 150,
                filter: true,
                editable: false
            },
            
            // {
            //     headerName : "Plant Name",
            //     field : "plant_name",
            //     resizable : true,
            //     width : 150,
            //     filter : true,
            //     editable : false
            // },
            // {
            //     headerName : "Near Dealer",
            //     field : "near_dealer",
            //     resizable : true,
            //     filter : true,
            //     width : 120,
            //     editable : false,
            //     valueGetter : function(params){
            //         if(params.data.near_dealer == 1)
            //         {
            //             return "Yes";
            //         }
            //         else if(params.data.near_dealer == 0)
            //         {
            //             return "No"
            //         }
            //     }
            // },
            // {
            //     headerName : "Dealer Name",
            //     field : "dealer_name",
            //     resizable : true,
            //     width : 150,
            //     filter : true,
            //     editable : false
            // },
            // {
            //     headerName : "Near Competitor",
            //     field : "near_competitor",
            //     resizable : true,
            //     filter : true,
            //     width : 120,
            //     editable : false,
            //     valueGetter : function(params){
            //         if(params.data.near_competitor == 1)
            //         {
            //             return "Yes";
            //         }
            //         else if(params.data.near_competitor == 0)
            //         {
            //             return "No"
            //         }
            //     }
            // },
            // {
            //     headerName : "Competitor Name",
            //     field : "competitor_name",
            //     resizable : true,
            //     width : 150,
            //     filter : true,
            //     editable : false
            // },

        ]


        return (

            <div className="container-fluid mb-60p">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row mb-20p">
                    <div className="col-xl-4 col-lg-3">
                        <button className="btn btn-info" style={{marginRight: 20}} onClick={this.onClickShowAllBreaks.bind(this)}>All</button>
                        <button className="btn btn-danger" onClick={this.onClickShowEvaluatedBreaks.bind(this)}>Exclusive</button>
                    </div>
                </div>
                <div className="row mb-20p">
                    <Select placeholder="Select Consignments"
                    isMulti={true}
                    className="col-xl-3 col-lg-3"
                    closeMenuOnSelect={false}
                    onChange={this.setSeletectedTrucks.bind(this)} 
                    options={this.state.truckslist} 
                    required/>
                    <button className="btn btn-success col-xl-1 col-lg-1" onClick={this.getTrucksClusters.bind(this)}>Submit</button>
                </div>
                <div className="row">
                    <div className="col-xl-12 col-lg-12" style={{ textAlign: "right" }}>
                        <button className="btn btn-success" onClick={this.resetZoom.bind(this)}>Reset Zoom</button>
                    </div>
                    <div className="col-xl-4 col-lg-4">
                        <div className="ag-theme-balham" id="mygrid" style={{ width: "100%", height: "95%" }}>
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.breaksClusterData}
                                detailCellRendererParams={this.state.detailCellRendererParams}
                                masterDetail={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                enableRangeSelection={true}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                paginationPageSize={this.state.paginationPageSize}
                                frameworkComponents={this.state.frameworkComponents}
                                pagination={true}
                                rowSelection="single"
                                onRowClicked={this.rowSelection.bind(this)}
                                onCellClicked={this.focusBreakPoint.bind(this)}
                            />
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-8">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-coffee-cup cus-i"></i> <span> Catalytic Missing Break Clusters ({this.state.pageName})</span>
                                </h5>
                            </div>
                            <div className="card-body row">
                                <div className="row col-xl-12 col-lg-12">
                                    <div id="breaks_cluster_map" className={this.state.mwidth} style={dStyles}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* LOADER AND OVERLY */}
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
            </div>

        );
    }
}


function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src = url
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function getPolygonCoords() {

    var len = geoFencingArea.getPath().getLength();
    console.log(len);
    var htmlStr = "";
    var coordinates = [];
    for (var i = 0; i < len; i++) {
        // var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
        // coordinates.push()
        var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
        var spliting = strlatlng.split(",");
        var latlngd = { "lat": parseFloat(spliting[0]), "lng": parseFloat(spliting[1]) };
        coordinates.push(latlngd);
        // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
        //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
        //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
    }
    // console.log("htmlStr", coordinates);

}


$(document).ready(function () {
    $(document).on("click", ".dropdown-truck-no", function () {
        var vals = $(this).text();
        $("#inputTruck").val(vals);
        $(".trucks-dropdown").removeClass("show-m");
        $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click", function (e) {
        var container = $("#inputTruck");
        if (!container.is(e.target)) {
            try {
                if ($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown") {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            } catch (e) { }

        }


    })
})


function ConvertMinutes(num) {
    var d = Math.floor(num / 1440); // 60*24
    var h = Math.floor((num - (d * 1440)) / 60);
    var m = Math.round(num % 60);
    return d + " Day(s) " + h + " Hrs " + m + " mins"
}
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _, { includes, join, reverse, split } from "lodash";


import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
//import Grid from '../layouts/gridnosidebarComponent';
import Grid from '../layouts/gridComponent';
import CSVFileValidator from 'csv-file-validator'
import ConsignmentActions from './ConsignmentActionsComponent';
import CommentActions from '../layouts/commentsComponent';
import SegmentAction from "../layouts/segmentAction";
import KpiActions from './kpiActionsComponent';
import KpiDeviationActions from './kpiDeviationActionsComponent';
import SegmentSideBar from "../layouts/segmentComponent";
import KpiTransitDelayActions from './kpiTransitDelayActions';
import Nooverspeed from './nooverspeedComponent.js';
import Notransit from './notransitComponent.js';
import RoutemapAction from './routemapActionComponent';
import RoutemapTransitAction from './routemaptransit';
import RoutemapNoGPSAction from './routemapnogps';
import RouteNightDrivemap from './routenightdrivemap';
import RoutemapenrouteAction from './routemapenroute';
import KpiNightDriveActions from './kpiNightDriveActionsComponent';
import Nonightdrive from './nonightdriveComponent.js';
import KpiEnrouteStoppagesActions from './kpiEnrouteStoppagesActionsComponent';
import KpiLoadingDelayActions from './kpiLoadingDelayActions';
import KpiUnLoadingDelayActions from './kpiUnLoadingDelayActions';
import Noenroutes from './noenroutesComponent.js';
import Gps from './gpsActionComponent.js';
import Gpsdata from './gpsdataaction.js';
import NoGps from './nogpsActionComponent.js';
import NoGpsLifetime from './nogpslifetimeActionComponent.js';
import Consignmentforceclose from './consignmentforceclose.js';
import Select from 'react-select';
import ForceCloseSideBar from '../layouts/forceclosuresidebarcomponent.js';
import Modal from 'react-responsive-modal';
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import AppendComponentIndex from '../common/appendComponents';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
//import Counters from '../layouts/consignmentcountersComponent';
import Counters from '../layouts/hubconsignmentscounterscomponent';
import DateFormater from '../layouts/dateComponent';
import {
	getDDMMYYYY,
	getDDMMYYYYHHMMSS,
	getYYYYMMDD,
	getYYYYMMDDHHMMSS,
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDDHHMM,
	getHyphenYYYYMMDD,
	getHyphenDDMMYYYYHHMMSS,
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault
} from '../common/utils';
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
import ConsignmentDrawMap from '../common/consignmentdrawmap';
import ComponentIndex from '../common/appendComponents';
import CustomDateComponent from '../common/dateFilterComponent';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import ProbableAction from "../layouts/probableDataComponent";
import Consignmenttickets from "./consignmenttickets";
import CustomTextFilter from "./defaultFilterComponent.js"

import download from 'js-file-download';

$.datetimepicker.setLocale('en');
var infoBox = require('../common/google-infowindow');

var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

const isValidDate = (current) => {
	// Disable future days
	return current.isBefore(Datetime.moment());
};

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749', '#F73E3E', '#B7B3B3'];
var selectedColor;
var colorButtons = {};
var fencingLength = 0;
var mapZoom = 12;
var coordinates = [];
var geoFencingArea;
var containerslist = [];
export default class HubConsignments extends Component {

	constructor(props) {
		super(props);
		this.displayData = [];
		this.state = {
			deptcode: "",
			idRow: "",
			idRowlast: '',
			commentValue: "",
			vehicle_type_from_lastmile: '',
			collecting_total_query: {},
			collecting_total_query_main_grid: {},
			collecting_total_main_grid_data_onclick:{},
			files: [],
			samplefleetvalue: '',
			samplepod1stmile: 0,
			samplelastmilepod: 0,
			instant_pod_first_mile: [{}],
			instant_pod_last_mile: [{}],
			hubServingCities: [],
			gate_out_time_for_compare:'',
			counterTypelabelName: '',
			hubServinginitialData: [],
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			fleetavalibility: [{ value: "Fleet Mater", label: "Other" }],
			eventAction: null,
			forceCloseRowNode: "",
			remark_for_over_time: '',
			remark_for_over_time_main_grid: '',
			remark_from_overall_transit_time: '',
			remark_for_delay_value_state:false,
			allRowData: [],
			totallastmilevehicledetails: [],
			rowIndexcount: 0,
			rowIndexcount2: 0,
			activeFilter: null,
			uploadDivWidth: '0%',
			sliderTranslate: '',
			sliderRouteTranslate: '',
			sliderCommentTranslate: "",
			tabsliderTranslate: '',
			timelineinfo: '',
			consignment_code: '',
			sliderSegmentTranslate: "",
			mapinfo: '',
			commentsRowData: [],
			dealer: '',
			maptruckno: '',
			loadshow: 'show-n',
			showDiv: 'show-n',
			alertshow: 'fade',
			alertmg: 'show-n',
			alerterrmg: 'show-n',
			overly: 'show-n',
			alerterrshow: 'fade',
			alert: null,
			show: false,
			basicTitle: '',
			rownode: '',
			leg_no: '',
			remarkCheck:false,
			basicType: "default",
			modules: AllModules,
			filterstatess: {},
			columnsdatas: {},
			rowgroupdcols: [],
			valuecolumns: [],
			activeconsignemnt: [],
			activetrucks: [],
			transitdelays: [],
			gpsdatana: [],
			overspeedtrucks: [],
			nightdrive: [],
			open: false,
			openforceclose: false,
			pivotmodeis: false,
			pivotcols: [],
			defTransitCoords: '',
			routeTruck: {},
			sidebarSubHeader: '',
			last_fleet_availabilityID: '',
			last_fleet_availabilityBulk: '',
			hubmasterlocationlist: [],
			googelRoutes: '',
			plants: [],
			legs_columndefs: [],
			totaldataforcount: [],
			statusList: ["MSIL-G", "MSIL-M", "MSILMC", "MSILME", "SMG", "MUL", "TKM", "TKAP-DHR"],
			bulkPrioritySlide: "",
			hideTransporterBtns: "show-m",
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true,
				pivot: true,
				enableValue: true,
				enableRowGroup: true,

			},
			showlastmiledata: false,
			rowData: [],
			all: "activet",
			intransit: "",
			delivered: "",
			transporterhub: "",
			istransporter: false,
			hubOwner: 'activet',
			maxTruckCount: 0,
			countNoGPSDatalifeTime: 0,
			countNoGPSData: 0,
			maprowData: null,
			commentValue: '',
			commentValuelastmile: '',
			rowSelection: 'multiple',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
				consignmentActions: ConsignmentActions,
				commentActions: CommentActions,
				SegmentAction: SegmentAction,
				kpiActions: KpiActions,
				kpiDeviationActions: KpiDeviationActions,
				kpiTransitDelayActions: KpiTransitDelayActions,
				nooverspeed: Nooverspeed,
				notransit: Notransit,
				kpiNightDriveActions: KpiNightDriveActions,
				nonightdrive: Nonightdrive,
				kpiEnrouteStoppagesActions: KpiEnrouteStoppagesActions,
				noenroutes: Noenroutes,
				kpiLoadingDelayActions: KpiLoadingDelayActions,
				kpiUnLoadingDelayActions: KpiUnLoadingDelayActions,
				dateFormater: DateFormater,
				gps: Gpsdata,
				nogps: NoGps,
				noGpsLifetime: NoGpsLifetime,
				RoutemapenrouteAction: RoutemapenrouteAction,
				customLoadingCellRenderer: CustomLoadingCellRenderer,
				consignmentforceclose: Consignmentforceclose,
				customDateComponent: CustomDateComponent,
				ProbableAction: ProbableAction,
				Consignmenttickets: Consignmenttickets
			},

			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,
			showFiltersForLongHaulScreenTab: 'show-m',
			childconfs: [],
			childrow: [],
			files_last: [],
			rowModelType: "serverSide",
			paginationPageSize: 1000,
			cacheBlockSize: 10,
			filterText: "",
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
			triptype: { "value": 'all', "label": 'All' },
			tripvalue: '',
			consigner: [{ "value": 'all', "label": 'All' }],
			consignee: [{ "value": 'all', "label": 'All' }],
			originalData: [],
			isCounterDisplay: 1,
			screenpage: '',
			pagetitle: 'Active Consignments',
			countersjson: '',
			originalcountersjson: '',
			consigneecoords: '',
			consigner_coordinates: '',
			containerslist: [],
			dateStarted: '',
			dateEnded: '',
			forceclosedata: '',
			reached_dealer_time: moment.parseZone().format('YYYY-MM-DD'),
			reachedhh: '',
			reachedmm: '',
			reachedss: '00',
			left_dealer_time: moment.parseZone().format('YYYY-MM-DD'),
			lefthh: '',
			leftmm: '',
			leftss: '00',
			invoice_time: '',
			invoicehh: '',
			invoicemm: '',
			segementConsignmentCode: "",
			counter_screen: "",
			priority_counter_display: 0,
			invoicess: '00',
			reasonforceclose: '',
			sliderTranslatesidebar: '',
			consignment_details: '',
			startDatetime: '',
			endDatetime: '',
			tolls: [],
			legs_cmt_data: [],
			selected_row_from_grid: {},
			totalDataOnAddButtonClick : {},
			selected_row_from_modal_grid: {},
			geofence_coordinates: [],
			columnwithDefsForLastMile: [],
			rowDataForLastMile: [],
			excelStyles: [
				{
					id: "dateFormat",
					dataType: 'dateTime',
					numberFormat: { format: "yyyy-mm-dd" }
				}
			],
			getRowHeight: function (params) {
				if (typeof params.data.dct_comment != 'undefined') {
					//console.log("pars ", params.data.dct_comment.length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.dct_comment.length / 50))
					);
				}
				if (typeof params.data.vin_list != 'undefined') {
					//console.log("pars ", params.data.vin_list.toString().length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.vin_list.toString().length / 50))
					);
				}
				else if (typeof params.data.force_closure_reason != 'undefined') {
					//console.log("pars ", params.data.dct_comment.length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.force_closure_reason.length / 50))
					);
				}
				else {
					return 30;
				}
			},
			totalhubData: [],
			defaultsdate: '',
			defaultedate: '',
			movementtype: { "value": "all", "label": "All" },
			usergridstate: [],
			screenurl: "",
			screentitle: "",
			showumncr: 0,
			showummove: 0,
			usermanualncrmodal: false,
			usermanualmovemodal: false,
			slideuploadeway: "",
			bulksliderforcomment: '',
			bulksliderforcommentlast: '',
			bulksliderforcommentlastforadditionaltrucks: '',
			bulksliderforaddingmultipletrucks: "",
			cancelled_data: [],
			pod_received_data: [],
			trip_closed_by_pod: [],
			returnConsignments: [],
			tnpButtonTitle: "",
			tnpReturnConsignmentsVisibilty: false,
			consBtn: "btn-danger",
			returnConsBtn: "btn-default",
			longHaulBtn: "btn-default",
			truckReportedDate: [],
			filterCities: [],
			filterStates: [],
			filterClusters: [],
			filterTransporters: [],
			beforeNewFilterRowData: [],
			beforeNewFiltercountersjson: [],
			lastmilevehicletypelist: [],
			lastmilevehiclenolist: [],
			flCity: "",
			flState: "",
			flCluster: "",
			flTransporter: "",
			vin: "",
			main_cmt: "",
			distinctTnpUserDeptCodes: [],
			baseRouteCoords: [],
			logsSLider: '',
			dateStarted: '',
			dateEnded: '',
			showFiltersForPartLevelScreenTab: 'show-n',
			parking_coordinates: [],
			startDate: '',
			endDate: '',
			last_cmt: "",
			openLastMileModal: false,
			additional_last_mile_truck: {
				last_fleet_availability: "",
				vehicle_type_from_lastmile: "",
				vehicle_no_from_lastmile: "",
				dispatch_time_from_hub_from_lastmile: "",
				reporting_time_at_consignees_from_lastmile: "",
				delivery_time_from_lastmile: "",
				pod_status_value_last_tmile: "",
				pod_comment_lastmile: "",
				file_pod_last: "",

			},
			additional_last_mile_truck_slider: "",
			additional_last_mile_truck_slider_for_edit: "",
			last_mile_consignments: []
		}
		this.changeSegmentHandler = this.changeSegmentHandler.bind(this);
		this.selectConsignerOptionsItems = this.selectConsignerOptionsItems.bind(this);
		this.onClickForceClose = this.onClickForceClose.bind(this);
		this.changeHandler = this.changeHandler.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.onLoadGetConsignmentsList = this.onLoadGetConsignmentsList.bind(this);
		this.changeFileHandlerPOD = this.changeFileHandlerPOD.bind(this);
		this.onClickCounterShowData = this.onClickCounterShowData.bind(this);
		this.getUserGridState = this.getUserGridState.bind(this);
		this.uploadPODfristmile = this.uploadPODfristmile.bind(this);
		this.uploadPODlastmile = this.uploadPODlastmile.bind(this);


	}

	componentDidCatch(error, info) {
		console.log("Error here ", error)
		console.log("Error Info here ", info)
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
	async componentDidMount() {
		this.setState({
			loadshow: 'show-m'
		})

		await redirectURL
		.post("/consignments/hubmaster")
		.then((response) => {
		//   console.log(response.data, "140----total Data", response.data);
  
		  this.setState({
			totalhubData: response.data,
			// citiesList:citiesList
		  })
  
		})
		
		let api_params = {
			"user_type": localStorage.getItem('user_type'),
			"user_email": localStorage.getItem('email')
		}
		await redirectURL.post("/consignments/getLSPHubTransporterCode", api_params).then((lsp_response) => {
			if (lsp_response.data.hub_transporter_code != undefined && localStorage.getItem('user_type') == "TRANSPORTER") {
				localStorage.setItem("hub_transporter_code", lsp_response.data.hub_transporter_code)
			}
			if (localStorage.getItem('user_type') != "TRANSPORTER") {
				this.setState({
					istransporter: false,
					transporterhub: ""
				});
			} else if (localStorage.getItem("hub_transporter_code") == undefined) {
				// console.log('insidemount')
				this.setState({
					hubOwner: "",
					istransporter: true,
					transporterhub: "activet"
				});
			}
			redirectURL.post("/consignments/lastmilevehiclelist").then((response) => {
				let responseData = response.data;
				const vehicleTypes = Array.from(new Set(responseData.map(vehicle => vehicle.vehicle_type)))
					.map(vehicleType => ({ value: vehicleType, label: vehicleType }));

				// vehicleTypes.unshift({value:'',label:''})
				// console.log(responseData, 'responseData');
				const vehicleNoList = responseData.map(vehicle => vehicle.vehicle_no);
				// console.log(vehicleNoList, 'vehicleNoList');


				// Store the comma-separated values in the state
				this.setState({
					lastmilevehicletypelist: vehicleTypes,
					lastmilevehiclenolist: vehicleNoList,
					totallastmilevehicledetails: responseData
				});

			})
			const transporterCodesString = localStorage.getItem("hub_transporter_code");
			var transporterCodesArray;
			console.log(transporterCodesArray,'const transporterCodesArray')
			if(transporterCodesString){
				 transporterCodesArray = transporterCodesString.split(',');
			}
			
			if (this.state.hubOwner == "activet") {
				this.gethubcitiesandlocationsdata({
					"hub_transporter_code": transporterCodesArray,
					"tab_type": "last_mile"
				})
			} else {
				this.gethubcitiesandlocationsdata({
					"hub_transporter_code": transporterCodesArray,
					"tab_type": "first_mile"
				})
			}

			this.initComponent();

		})

	}
	initComponent() {
		loadDateTimeScript();
		// console.log(this.props.match.path,'this.props.match.path')
		if (this.props.match.path == '/prtdeliveredconsignmentshub') {
			this.setState({
				all: "",
				intransit: '',
				delivered: 'activet',

			})
		} else if (this.props.match.path == '/prtconsignmentshub') {

			this.setState({
				all: "",
				intransit: 'activet',
				delivered: ''
			})
		} else {
			this.setState({
				all: "activet",
				intransit: '',
				delivered: '',
			})
		}
		var edate = moment.parseZone().format('YYYY-MM-DD');
		if (this.props.match.path.includes("deliveredconsignments") || this.props.match.path.includes("allconsignments")) {
			var sdate = moment.parseZone().subtract(10, 'days').format('YYYY-MM-DD');
		}
		else {
			var sdate = moment.parseZone().subtract(10, 'days').format('YYYY-MM-DD');
		}


		// var sdate = '2020-03-01';

		var edatetime = moment.parseZone().format('DD-MM-YYYY HH:mm');
		var sdatetime = moment.parseZone().format('DD-MM-YYYY HH:mm');
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({ eventAction: eventAction });
		//console.log("Props ", this.props.match)
		this.setState({
			startDate: sdate,
			endDate: edate,
			defaultsdate: sdate,
			defaultedate: edate,
			startDatetime: sdatetime,
			endDatetime: edatetime
		});
		var urlpath;
		var dpt;
		var isCounterDisplay = 1;
		var screenpage = '';
		var title = '';
		var transporter_code = localStorage.getItem('transportercode');
		if (transporter_code != undefined && transporter_code != "undefined" && transporter_code != "") {
			var tptCode = JSON.parse(transporter_code);
			transporter_code = tptCode[0];
		}
		var consignData = {}


		if (this.props.match.path == "/prtconsignmentshub") {
			urlpath = '/consignments/prtconsignmentshub';
			dpt = 'LOG-PRT';
			title = 'Active Consignments (Spare Parts)';
			// console.log(sdate,edate,'(Spare Parts)-----')
			var consignData = {
				dept_code: dpt,
				startDate: sdate,
				endDate: edate,
				consigner: this.state.consigner,
				movement: [this.state.movementtype]
			}
			this.setState({
				startDate: "",
				endDate: "",
				counter_screen: "active",
				priority_counter_display: 1,
			});
			this.setState({
				dateStarted: sdate,
				dateEnded: edate,
				startDate: sdate,
				endDate: edate
			})
		}

		else if (this.props.match.path == "/prtallconsignmentshub") {
			urlpath = '/consignments/allprtconsignmentshub';
			dpt = 'LOG-PRT';
			isCounterDisplay = 1;
			screenpage = 'all';
			title = 'All Consignments (Spare Parts)';
			// console.log(sdate,edate,'-----')
			var consignData = {
				dept_code: dpt,
				startDate: sdate,
				endDate: edate,
				consigner: this.state.consigner,
				movement: [this.state.movementtype],
				screen: "all"
			}
			this.setState({
				dateStarted: sdate,
				dateEnded: edate,
				startDate: sdate,
				endDate: edate
			})
		}

		else if (this.props.match.path == "/prtdeliveredconsignmentshub") {
			urlpath = '/consignments/deliveredprtconsignmentshub';
			dpt = 'LOG-PRT';
			isCounterDisplay = 1;
			screenpage = 'deliver';
			title = 'Delivered Consignments (Spare Parts)';
			var consignData = {
				dept_code: dpt,
				startDate: sdate,
				endDate: edate,
				consigner: this.state.consigner,
				movement: [this.state.movementtype]
			}
			this.setState({
				dateStarted: sdate,
				dateEnded: edate,
				startDate: sdate,
				endDate: edate
			})
		}


		this.setState({
			deptcode: dpt,
			isCounterDisplay: isCounterDisplay,
			screenpage: screenpage,
			pagetitle: title
		});


		redirectURL.post("/consignments/getConsignmentsNewFilterKeys", { dept_code: dpt })
			.then((resp) => {
				var cities = resp.data.cities;
				var filterCities = [];
				if (cities != undefined) {
					if (cities.length > 0) {
						cities.map(function (c) {
							filterCities.push({
								label: c,
								value: c,
							});
						});
					}
				}
				var states = resp.data.states;
				var filterStates = [];
				if (states != undefined) {
					if (states.length > 0) {
						states.map(function (s) {
							filterStates.push({
								label: s,
								value: s,
							});
						});
					}
				}
				var clusters = resp.data.clusters;
				var filterClusters = [];
				if (clusters != undefined) {
					if (clusters.length > 0) {
						clusters.map(function (cl) {
							filterClusters.push({
								label: cl,
								value: cl,
							});
						});
					}
				}
				var transporters = resp.data.transporters;
				var filterTransporters = [];
				if (transporters != undefined) {
					if (transporters.length > 0) {
						transporters.map(function (t) {
							filterTransporters.push({
								label: t,
								value: t,
							});
						});
					}
					filterTransporters.unshift({ label: "ALL", value: "" })
				}
				this.setState({
					// filterCities:filterCities,
					filterStates: filterStates,
					filterClusters: filterClusters,
					filterTransporters: filterTransporters,
				});
			})

		//Use following code after ACL enabl
		// redirectURL.post("/consignments/plants",{dept_code:dpt})
		// .then((resp) => {			
		// 		this.setState({
		// 			plants:resp.data
		// 		});
		// })
		// Function for common Data
		if ((localStorage.getItem("user_type") != "TRANSPORTER" ||
			localStorage.getItem("hub_transporter_code") != undefined) && this.state.transporterhub != "activet") {
			// console.log("hub consignments data", consignData)
			this.onLoadGetConsignmentsList(urlpath, consignData);
		}
		if (this.state.istransporter == true || (localStorage.getItem("hub_transporter_code") == undefined)) {
			// console.log("transporter consignmentdata", consignData)
			// Function for Transporter Login
			this.transportertranckingData(urlpath, consignData);
		}
		this.getUserGridState(title)
	};

	getUserGridState = (pagetitle) => {
		let params = { userId: localStorage.getItem("userid"), screenurl: window.location.pathname }
		// if(pagetitle !== ""  && pagetitle !== undefined) params.pagetitle = pagetitle
		redirectURL.post("/consignments/usergridstates", params).then(async (resp) => {
			// console.log("resp ", resp)
			this.setState({
				usergridstate: resp.data
			});
			this.restoreGridStates();
		})
	}

	compareObjects(obj1, obj2) {
		// Compare by name first
		if (obj1.truck_no < obj2.truck_no) return -1;
		if (obj1.truck_no > obj2.truck_no) return 1;

		// If names are equal, compare by age
		if (obj1.consignment_code < obj2.consignment_code) return -1;
		if (obj1.consignment_code > obj2.consignment_code) return 1;

		// Objects are equal
		return 0;
	}


	onLoadGetConsignmentsList(urlpath, parameters) {
		// this function is for common data 

		// console.log(urlpath,'----------++++++++++',parameters)
		this.setState({
			loadshow: 'show-m',
			overly: 'show-m'
		})
		// if(localStorage.getItem("region") != undefined && localStorage.getItem("region") != "")
		// {
		// 	parameters.region = localStorage.getItem("region");
		// 	parameters.sales_type = localStorage.getItem("sales_type")
		// }
		if (localStorage.getItem("hub_transporter_code") != undefined && localStorage.getItem("hub_transporter_code") != null && localStorage.getItem("hub_transporter_code") != "" && localStorage.getItem("hub_transporter_code") != "undefined") {
			parameters.type10 = "admin_tracking"
			parameters.hub_transporter_code = localStorage.getItem("hub_transporter_code")
		}
		if (localStorage.getItem("prt_transporter_code") != undefined &&
			localStorage.getItem("prt_transporter_code") != null &&
			localStorage.getItem("prt_transporter_code") != "" &&
			localStorage.getItem("prt_transporter_code") != "undefined") {
			parameters.parent_transporter_code = localStorage.getItem("prt_transporter_code")
		}
		var totaldataforcount = 0;
		console.log(parameters, urlpath, "982")
		redirectURL.post(urlpath, parameters).then((response) => {
			// console.log(response, "response");
			var records = (response.data.consignments);

			records = records.sort((a, b) => {
				// First, sort by gate_out_time (descending)
				const dateA = new Date(a.gate_out_time);
				const dateB = new Date(b.gate_out_time);

				if (dateA > dateB) {
					return -1;
				} else if (dateA < dateB) {
					return 1;
				} else {
					// If gate_out_time is the same, sort by consignment_code (ascending)
					if (a.consignment_code < b.consignment_code) {
						return -1;
					} else if (a.consignment_code > b.consignment_code) {
						return 1;
					} else {
						return 0;
					}
				}
			});

			this.setState({
				totaldataforcount: records
			})

			var truckCount = {};
			var serial_number = 0;

			// Sort the array of objects
			// records.sort(this.compareObjects);
			// console.log(records, 'totaldataforcount---');

			this.state.totaldataforcount.map((record) => {
				var truckNo = record.truck_no;
				var gate_out_time = moment.parseZone(new Date(record.gate_out_time)).format("YYYY-MM-DD HH")
				let uniqueKey = truckNo + "_" + gate_out_time;
				//   console.log(uniqueKey, "uniqueKey")
				serial_number++;
				record.serial_number = serial_number;
				// console.log(serial_number,'serial_number')
				// If the truck number is already encountered, update the count
				if (truckCount[uniqueKey]) {
					truckCount[uniqueKey]++;
				} else {
					// If it's the first occurrence, set the count to 1
					truckCount[uniqueKey] = 1;
					record.original_truck_no = record.truck_no;
				}

				// Update the record with the truck_count and mark duplicates as empty string
				//   record.truck_count = truckCount[truckNo];

				// For duplicates, set truck_no to an empty string
				if (truckCount[uniqueKey] > 1) {
					record.original_truck_no = record.truck_no
					record.truck_no = "";
					// console.log(truckCount[truckNo],'truckCount[truckNo]')

				}
			});

			this.setState({
				// maxTruckCount: Math.max(...Object.values(truckCount))
				maxTruckCount: truckCount
			});

			// console.log("Maximum truck count:", this.state.maxTruckCount);

			// console.log(records,'truckNumber---');

			var pagecounters = response.data.counters;
			pagecounters['deviation_flag'] = records.filter(e => e.deviation_flag == 1)
			var returnConsignments = (response.data.returnConsignments != undefined) ? response.data.returnConsignments : []
			pagecounters.cancelled_count = "Not Applicable"
			// console.log("records", records);
			var cancelled_data = []
			var pod_received_data = [];
			var trip_closed_by_pod = [];
			// console.log("counters --", records);
			// records = records.sort(GetSortDescOrder("_id"));
			if (records.length > 0) {

				// if(
				//     this.props.match.path == "/sndallconsignments" 
				// || this.props.match.path == "/prtallconsignments" 
				// || this.props.match.path == "/tnpallconsignments"
				// )
				// {

				if (this.props.match.path == "/prtallconsignments") {
					cancelled_data = records.filter(function (e) {
						return e.cancelled == 1
					})
					pagecounters.cancelled_count = cancelled_data.length
					pod_received_data = records.filter(function (e) {
						return e.pod_received == 1
					})
					pagecounters.pod_received_data = pod_received_data.length
					trip_closed_by_pod = records.filter(function (e) {
						return e.trip_closed_by_pod == 1
					})
					// console.log(trip_closed_by_pod, "trip_closed_by_pod")
					pagecounters.trip_closed_by_pod = trip_closed_by_pod.length
				}


				else if (this.props.match.path == "/prtdeliveredconsignments") {
					pod_received_data = records.filter(function (e) {
						return e.pod_received == 1
					})
					pagecounters.pod_received_data = pod_received_data.length
					trip_closed_by_pod = records.filter(function (e) {
						return e.trip_closed_by_pod == 1
					})
					pagecounters.trip_closed_by_pod = trip_closed_by_pod.length
					cancelled_data = records.filter(function (e) {
						return e.cancelled == 1
					})
					pagecounters.cancelled_count = cancelled_data.length

					records = records.filter(rec => rec.status > 2)
				}
				// }
				else {

					// records = records.filter(rec => rec.status >= 1)
					if (this.props.match.path == "/prtconsignmentshub") {
						pod_received_data = records.filter(function (e) {
							return e.pod_received == 1
						})
						pagecounters.pod_received_data = pod_received_data.length
						trip_closed_by_pod = records.filter(function (e) {
							return e.trip_closed_by_pod == 1
						})
						pagecounters.trip_closed_by_pod = trip_closed_by_pod.length
					}
				}
			}
			var trucksReported = [];
			if (this.props.match.path == "/prtconsignmentshub" || this.props.match.path == "/prtdeliveredconsignmentshub" || this.props.match.path == "/prtallconsignmentshub") {
				// console.log(records,'records23')
				trucksReported = records.filter(function (e) {
					return (e.truck_reporting_date !== undefined && e.truck_reporting_date !== "")
				})
			}

			var gridData = records;
			// console.log(records,'records24')

			// console.log(gridData,'gridDatagridData')
			this.setState({
				rowData: gridData,
				counterTypelabelName: '',
				allRowData: gridData,
				originalData: gridData,
				beforeNewFilterRowData: gridData,
				loadshow: 'show-n',
				overly: 'show-n',
				countersjson: pagecounters,
				originalcountersjson: pagecounters,
				beforeNewFiltercountersjson: pagecounters,
				cancelled_data: cancelled_data,
				trip_closed_by_pod: trip_closed_by_pod,
				pod_received_data: pod_received_data,
				returnConsignments: returnConsignments,
				consBtn: "btn-danger",
				returnConsBtn: "btn-default",
				longHaulBtn: "btn-default",
				truckReportedDate: trucksReported
			});
			Promise.all([]).then(() => {
				let columns = []
				this.gridApi.forEachNode(node => {
					if (columns.length == 0) {
						columns = Object.keys(node.data)
					}
				})
				// console.log(columns,"4614")
				// columns.map((columnDef) => {
				// console.log(columnDef,"4672")
				const columnName = "transporter_code";
				// console.log(columnName, "columnName")
				const updatedUniqueValues = this.getColumnUniqueValues(columnName);
				// console.log(updatedUniqueValues, "updatedUniqueValues")
				// console.log(this.gridApi.getFilterInstance(columnName),"4676")
				if (this.gridApi.getFilterInstance(columnName) != undefined && this.gridApi.getFilterInstance(columnName) != null) {
					// console.log("check-1")
					this.gridApi.getFilterInstance(columnName).setModel({
						onFilterChanged: this.onFilterChanged,
						uniqueValues: updatedUniqueValues,
					});
					this.gridApi.onFilterChanged();
					// console.log("check-2")
				}
				//   });
			})
		})
			.catch(function (error) {
				console.log(error);
			});
	}
	// function for transporter login
	transportertranckingData(urlpath, parameters) {
		// console.log("transporter tracking")
		// console.log(urlpath,'transportertranckingData',parameters)
		this.setState({
			loadshow: 'show-m',
			overly: 'show-m'
		})
		// if(localStorage.getItem("hub_transporter_code") != undefined && localStorage.getItem("hub_transporter_code") != null && localStorage.getItem("hub_transporter_code") != "" && localStorage.getItem("hub_transporter_code") != "undefined"){
		// 	parameters.hub_transporter_code = localStorage.getItem("hub_transporter_code")
		// }
		if (localStorage.getItem("prt_transporter_code") != undefined &&
			localStorage.getItem("prt_transporter_code") != null &&
			localStorage.getItem("prt_transporter_code") != "" &&
			localStorage.getItem("prt_transporter_code") != "undefined") {
			parameters.type10 = "transporter_tracking"
			parameters.parent_transporter_code = localStorage.getItem("prt_transporter_code")
		}
		var totaldataforcount = 0;
		// console.log(parameters,"982----")
		redirectURL.post(urlpath, parameters)
			.then((response) => {
				// console.log(response, "response");
				var records = response.data.consignments;
				if (records.length) {
					let accessibleCities = []
					this.state.hubServingCities.map((e) => accessibleCities.push(e.value))
					records = records.filter(e => accessibleCities.includes(e.consignee_city))
					console.log("before filtered records..!!")
				}
				// console.log("filtered records..!!")
				records = records.sort((a, b) => {
					// First, sort by gate_out_time (descending)
					const dateA = new Date(a.gate_out_time);
					const dateB = new Date(b.gate_out_time);

					if (dateA > dateB) {
						return -1;
					} else if (dateA < dateB) {
						return 1;
					} else {
						// If gate_out_time is the same, sort by consignment_code (ascending)
						if (a.consignment_code < b.consignment_code) {
							return -1;
						} else if (a.consignment_code > b.consignment_code) {
							return 1;
						} else {
							return 0;
						}
					}
				});

				this.setState({
					totaldataforcount: records
				})


				var truckCount = {};
				var serial_number = 0;

				// Sort the array of objects
				// records.sort(this.compareObjects);
				// console.log(records, 'totaldataforcount---');

				this.state.totaldataforcount.map((record) => {
					var truckNo = record.truck_no;
					var gate_out_time = moment.parseZone(new Date(record.gate_out_time)).format("YYYY-MM-DD HH")
					let uniqueKey = truckNo + "_" + gate_out_time;
					serial_number++;
					record.serial_number = serial_number;
					if (truckCount[uniqueKey]) {
						truckCount[uniqueKey]++;
					} else {
						truckCount[uniqueKey] = 1;
						record.original_truck_no = record.truck_no;
					}

					if (truckCount[uniqueKey] > 1) {
						record.original_truck_no = record.truck_no;
						record.truck_no = "";
						// console.log(truckCount[truckNo],'truckCount[truckNo]')

					}
				});


				this.setState({
					// maxTruckCount: Math.max(...Object.values(truckCount))
					maxTruckCount: truckCount
				});

				// console.log("Maximum truck count:", this.state.maxTruckCount);


				// console.log(records,'truckNumber---');

				var pagecounters = response.data.counters;
				pagecounters['deviation_flag'] = records.filter(e => e.deviation_flag == 1)
				var returnConsignments = (response.data.returnConsignments != undefined) ? response.data.returnConsignments : []
				pagecounters.cancelled_count = "Not Applicable"
				// console.log("records", records);
				var cancelled_data = []
				var pod_received_data = [];
				var trip_closed_by_pod = [];
				//console.log("counters", pagecounters);
				// records = records.sort(GetSortDescOrder("_id"));
				if (records.length > 0) {
					if (this.props.match.path == "/prtallconsignments") {
						cancelled_data = records.filter(function (e) {
							return e.cancelled == 1
						})
						pagecounters.cancelled_count = cancelled_data.length
						pod_received_data = records.filter(function (e) {
							return e.pod_received == 1
						})
						pagecounters.pod_received_data = pod_received_data.length
						trip_closed_by_pod = records.filter(function (e) {
							return e.trip_closed_by_pod == 1
						})
						// console.log(trip_closed_by_pod, "trip_closed_by_pod")
						pagecounters.trip_closed_by_pod = trip_closed_by_pod.length
					}

					else if (this.props.match.path == "/prtdeliveredconsignments") {
						pod_received_data = records.filter(function (e) {
							return e.pod_received == 1
						})
						pagecounters.pod_received_data = pod_received_data.length
						trip_closed_by_pod = records.filter(function (e) {
							return e.trip_closed_by_pod == 1
						})
						pagecounters.trip_closed_by_pod = trip_closed_by_pod.length
						cancelled_data = records.filter(function (e) {
							return e.cancelled == 1
						})
						pagecounters.cancelled_count = cancelled_data.length

						records = records.filter(rec => rec.status > 2)
					}
					// }
					else {

						// records = records.filter(rec => rec.status >= 1)
						if (this.props.match.path == "/prtconsignmentshub") {
							pod_received_data = records.filter(function (e) {
								return e.pod_received == 1
							})
							pagecounters.pod_received_data = pod_received_data.length
							trip_closed_by_pod = records.filter(function (e) {
								return e.trip_closed_by_pod == 1
							})
							pagecounters.trip_closed_by_pod = trip_closed_by_pod.length
						}
					}
				}
				var trucksReported = [];
				if (this.props.match.path == "/prtconsignmentshub" || this.props.match.path == "/prtdeliveredconsignmentshub" || this.props.match.path == "/prtallconsignmentshub") {
					trucksReported = records.filter(function (e) {
						return (e.truck_reporting_date !== undefined && e.truck_reporting_date !== "")
					})
				}

				var gridData = records;

				// var selectedCity = $("#selectedCity").text();
				// var selectedState = $("#selectedState").text();
				// var selectedCluster = $("#selectedCluster").text();
				// var selectedTransporter = $("#selectedTransporter").text();
				// // console.log(selectedCity,selectedState,selectedCluster,selectedTransporter,"filters")
				// //console.log(filterRowData,"0")
				// if(selectedCity !=undefined && selectedCity !='' && selectedCity !="Select...")
				// {
				// 	gridData = gridData.filter(e => e.consignee_city == selectedCity);
				// 	// console.log(gridData,"1")
				// }
				// if(selectedState !=undefined && selectedState !='' && selectedState !="Select...")
				// {
				// 	gridData = gridData.filter(e => e.consignee_state == selectedState);
				// }
				// if(selectedCluster !=undefined && selectedCluster !='' && selectedCluster !="Select...")
				// {
				// 	gridData = gridData.filter(e => e.cluster == selectedCluster);
				// }
				// if(selectedTransporter !=undefined && selectedTransporter !='' && selectedTransporter !="Select...")
				// {
				// 	gridData = gridData.filter(e => e.transporter_name == selectedTransporter);
				// }
				this.setState({
					rowData: gridData,
					counterTypelabelName: '',
					allRowData: gridData,
					originalData: gridData,
					beforeNewFilterRowData: gridData,
					loadshow: 'show-n',
					overly: 'show-n',
					countersjson: pagecounters,
					originalcountersjson: pagecounters,
					beforeNewFiltercountersjson: pagecounters,
					cancelled_data: cancelled_data,
					trip_closed_by_pod: trip_closed_by_pod,
					pod_received_data: pod_received_data,
					returnConsignments: returnConsignments,
					consBtn: "btn-danger",
					returnConsBtn: "btn-default",
					longHaulBtn: "btn-default",
					truckReportedDate: trucksReported
				});
				Promise.all([]).then(() => {
					let columns = []
					this.gridApi.forEachNode(node => {
						if (columns.length == 0) {
							columns = Object.keys(node.data)
						}
					})
					// columns.forEach((columnDef) => {
					const columnName = "transporter_code";
					// console.log(columnName, "columnName")
					const updatedUniqueValues = this.getColumnUniqueValues(columnName);
					// console.log(updatedUniqueValues, "updatedUniqueValues")
					if (this.gridApi.getFilterInstance(columnName) != undefined && this.gridApi.getFilterInstance(columnName) != null) {
						this.gridApi.getFilterInstance(columnName).setModel({
							onFilterChanged: this.onFilterChanged,
							uniqueValues: updatedUniqueValues,
						});
						//   });
						this.gridApi.onFilterChanged();
					}
				})

			})
			.catch(function (error) {
				console.log(error);
			});
	}

	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderTranslate: "",
			tabsliderTranslate: '',
			showDiv: 'show-n',
			sliderTranslatesidebar: "",
			sliderCommentTranslate: "",
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n',
			loadshow: 'show-n',
			slideuploadeway: ""
		});
	}

	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderRouteTranslate: "",
			sliderCommentTranslate: "",
			sliderSegmentTranslate: "",
			showDiv: 'show-n',
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n',
			logsSLider: ''
		});
	}



	onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;


		if (this.state.deptcode == 'LOG-PRT') {
			// console.log("Step 1 ")
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception", "loading_delay_exception", "unloading_delay_exception", "trip_type"], false);

			this.gridColumnApi.setColumnsVisible(["trip_type"], false);
		}

		else if ((this.props.match.path == "/sndconsignments")
			|| (this.props.match.path == "/prtconsignmentshub")
			|| (this.props.match.path == "/tnpconsignments")) {
			this.gridColumnApi.setColumnsVisible(["unloading_delay_exception"], false);

		}
		else { }



	};
	onGridReady2 = params => {
		this.gridApi2 = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi2 = params.columnApi;

	};


	onGridState = () => {
		//console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);



	}
	onGridState2 = () => {
		//console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState2 = this.gridColumnApi2.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState2 = this.gridColumnApi2.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		 * User should enable the Pivot mode.*/
		this.pivotColumns2 = this.gridColumnApi2.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi2.getFilterModel();
		this.gridApi2.setFilterModel(window.filterState);



	}
	onClickHideAll() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			slideuploadeway: '',
			bulksliderforcomment: '',
			bulksliderforcommentlast: '',
			bulksliderforcommentlastforadditionaltrucks: '',
			sliderRouteTranslate: '',
			sliderSegmentTranslate: "",
			tabsliderTranslate: '',
			sliderTranslatesidebar: '',
			sliderForceCloseTranslate: '',
			sliderCommentTranslate: "",
			bulkPrioritySlide: "",
			last_cmt: "",
			last_fleet_availabilityBulk: "",
			additional_last_mile_truck_slider: "",
			additional_last_mile_truck_slider_for_edit: "",
			remark_for_over_time: '',
			remark_for_over_time_main_grid: "",
			additional_last_mile_truck: {
				last_fleet_availability: "",
				vehicle_type_from_lastmile: "",
				vehicle_no_from_lastmile: "",
				dispatch_time_from_hub_from_lastmile: "",
				reporting_time_at_consignees_from_lastmile: "",
				delivery_time_from_lastmile: "",
				pod_status_value_last_tmile: "",
				pod_comment_lastmile: "",
				file_pod_last: "",
				last_cmt: ""
			}
		});
		$("#last_cmt").val('')
		$("#files_last").val('')
		$("#uploadFiletype").val('')
		$("#commentValuelastmile").val('')
		var form = document.getElementById("form-pod-first-mile");
		if (form) {
			form.reset();
		}
	}

	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onClickCounterShowData(params) {
		// console.log("IN onClickCounterShowData, params = ",params);
		if (googleAnalytics.page.enableGA) {
			let eventLabel = null;
			if (params) {
				eventLabel = googleAnalytics.page.kpi[params];
				// console.log("eventLabel = ", eventLabel);
				if (eventLabel == "GPS Data NA") {
					eventLabel = "GPS Not Available"
				}
				if (params == 'no_gps_data_lifetime') {
					eventLabel = "GPS Data Not Adequate"
				}

				this.setState({
					counterTypelabelName: eventLabel
				})
			}

			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventCounterAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
		}


		if (params == "transit_delay") {
			var gridData = this.state.rowData
			if (this.state.tnpReturnConsignmentsVisibilty) { gridData = this.state.returnConsignments }
			let isFilterDataAvailable = gridData.filter(rec => {
				if (rec["transit_delay"]) {
					return rec["transit_delay"] == 1;
				}
			});
			isFilterDataAvailable = this.sortAndArrangeRowSpan(isFilterDataAvailable);
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "truck_reporting_date") {
			// console.log(this.state.truckReportedDate,"trkfilter");		
			this.gridApi.setRowData(this.state.truckReportedDate);
			this.gridApi.onFilterChanged();
		}
		else if (params == "probable_accident") {
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["probable_accident"]) {
					return rec["probable_accident"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "probable_accident_confirm") {
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["probable_accident_confirm"]) {
					return rec["probable_accident_confirm"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "is_critical") {
			if (this.state.tripvalue != undefined && this.state.tripvalue != '') {
				//filterComponent.selectValue(this.state.tripvalue);
			}
			// filterComponent.applyModel();
			var gridData = this.state.rowData
			if (this.state.tnpReturnConsignmentsVisibilty) { gridData = this.state.returnConsignments }
			let isFilterDataAvailable = gridData.filter(rec => {
				if (rec["is_critical"]) {
					return rec["is_critical"] == true;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "is_priority") {
			if (this.state.tripvalue != undefined && this.state.tripvalue != '') {
				//filterComponent.selectValue(this.state.tripvalue);
			}
			// filterComponent.applyModel();
			var gridData = this.state.rowData
			if (this.state.tnpReturnConsignmentsVisibilty) { gridData = this.state.returnConsignments }
			let isFilterDataAvailable = gridData.filter(rec => {
				if (rec["is_priority"]) {
					return rec["is_priority"] == true;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}

		else if (params == "no_gps_data") {
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["no_gps_data"]) {
					return rec["no_gps_data"] == 1;
				}
			});

			// console.log("isFilterDataAvailable ",isFilterDataAvailable)
			isFilterDataAvailable = this.sortAndArrangeRowSpan(isFilterDataAvailable);
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "no_gps_data_lifetime") {
			if (this.state.tripvalue != undefined && this.state.tripvalue != '') {
				this.gridApi.getFilterInstance("no_gps_data_lifetime").selectValue(this.state.tripvalue);
			}
			//this.gridApi.getFilterInstance("no_gps_data_lifetime").applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["no_gps_data_lifetime"]) {
					return (rec["no_gps_data_lifetime"] == 1);
				}
			});
			// console.log("isFilterDataAvailable ",isFilterDataAvailable)
			isFilterDataAvailable = this.sortAndArrangeRowSpan(isFilterDataAvailable);
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}

		else if (params == "nightdriving_exception") {
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["nightdriving_exception"]) {
					return rec["nightdriving_exception"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}

		else if (params == "overspeeding_exception") {
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["overspeeding_exception"]) {
					return rec["overspeeding_exception"] == 1;
				}
			});
			// console.log(isFilterDataAvailable);
			isFilterDataAvailable = this.sortAndArrangeRowSpan(isFilterDataAvailable);
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "nightdriving_exception") {
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["nightdriving_exception"]) {
					return rec["nightdriving_exception"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "unloading_delay_exception") {
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["unloading_delay_exception"]) {
					return rec["unloading_delay_exception"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "loading_delay_exception") {
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["loading_delay_exception"]) {
					return rec["loading_delay_exception"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "enroute_exception") {
			var gridData = this.state.rowData
			if (this.state.tnpReturnConsignmentsVisibilty) { gridData = this.state.returnConsignments }
			let isFilterDataAvailable = gridData.filter(rec => {
				if (rec["enroute_exception"] != undefined || rec["enroute_exception"] != '') {
					return rec["enroute_exception"] == 1;
				}
			});
			//console.log("isFilterDataAvailable Enrotue ", isFilterDataAvailable)
			if (isFilterDataAvailable.length > 0) {

				isFilterDataAvailable = this.sortAndArrangeRowSpan(isFilterDataAvailable);
				this.gridApi.setRowData(isFilterDataAvailable);
				this.gridApi.onFilterChanged();
			}
			else {
				this.gridApi.setRowData([]);
				this.gridApi.onFilterChanged();
			}

		}
		else if (params == "cancelled") {
			this.gridApi.setRowData(this.state.cancelled_data)
		}
		else if (params == "pod") {
			this.gridApi.setRowData(this.state.pod_received_data)
		}
		else if (params == "trip_closed") {
			this.gridApi.setRowData(this.state.trip_closed_by_pod)
		}
		else if (params == "deviation") {
			// this.gridApi.setRowData(this.state.originalcountersjson.deviation_flag)
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["deviation_flag"]) {
					return rec["deviation_flag"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else {
			let isFilterDataAvailable = this.sortAndArrangeRowSpan(this.state.allRowData);
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}

	}

	onSelectTrip = triptype => {
		this.setState(
			{ triptype },
			// () => console.log(`Trip Option selected:`, this.state.triptype)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}

	changeConsignerArrayHandler = consigner => {
		this.setState(
			{ consigner },
			//   () => console.log(`Consigner Option selected:`, this.state.consigner)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	changeConsigneeArrayHandler = consignee => {
		this.setState(
			{ consignee },
			//   () => console.log(`Consignee Option selected:`, this.state.consignee)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	changeMovementArrayHandler = movementtype => {
		this.setState(
			{ movementtype, flCity: [] },
			//   () => console.log(`Movement Option selected:`, this.state.movementtype)
		);
		redirectURL.post("/consignments/hubmasterdata").then((response) => {
			let responseData = response.data;
			//   console.log(responseData, 'responseDataHub', this.state.movementtype.value, this.state.movementtype);

			// Iterate through responseData to find a match for the selected movementtype
			if (this.state.movementtype.value == "all") {
				this.setState({
					hubServingCities: this.state.hubServinginitialData
				});
			} else {
				const selectedHubLocation = responseData.find(record => record.hub_location === this.state.movementtype.label);

				if (selectedHubLocation) {
					const hubServingCitiesArray = selectedHubLocation.hub_serving_city.split(',').map(city => {
						return { value: city.trim(), label: city.trim() };
					});

					this.setState({
						hubServingCities: hubServingCitiesArray
					});

					// console.log('Matching hub_serving_city:', hubServingCitiesArray);
				} else {
					// console.log('No matching record found for the selected movementtype');
				}
			}
		})

	}
	newFilterservingCity = flCity => {
		this.setState(
			{ flCity },
			// () => console.log(`Consignee Option selected:flCity`, this.state.flCity)
		);
	}
	selectConsignerOptionsItems() {
		let items = [];
		//console.log(this.state.trucks);
		var consigners = [];

		if (this.state.deptcode == 'LOG-PRT') {
			var keys = ["MSILDM", "MSILG", "MSILM", "RPDC-BAN", "RPDC-GUJ", "RPDC-NAG", "RPDC-SIL"];

			// An array of values 
			var values = ["MSIL Domestic", "Gurgaon", "Manesar", "RPDC Bangalore", "RPDC Gujarat", "RPDC Nagpur", "RPDC Siliguri"];

			// Object created 
			var obj = {};

			// Using loop to insert key 
			// value in Object 
			for (var i = 0; i < keys.length; i++) {
				consigners.push({ "code": keys[i], "value": values[i] });
			}
		}

		items.push({ value: 'all', label: 'All' });
		consigners.forEach(item => {
			items.push({ value: item.code, label: item.value });
		});

		return items;
	}
	gethubcitiesandlocationsdata = (formcheck) => {
		// console.log(formcheck,"dafdfadfadfadfadf")
		redirectURL.post("/consignments/hubmasterdata", formcheck).then((response) => {
			let responseData = response.data
			// console.log(responseData,'responseDataHub')
			const hubLocations = responseData.map(vehicle => vehicle.hub_location);
			// console.log(hubLocations, 'hubLocations');
			const citiesArrays = responseData
				.filter(record => record.hub_serving_city && record.hub_location.length > 0)
				.map(record => record.hub_serving_city.split(','));

			const flattenedCities = citiesArrays.flat();
			const uniqueCitiesSet = new Set(flattenedCities);
			const uniqueCitiesArray = Array.from(uniqueCitiesSet);
			const result = uniqueCitiesArray.map(city => ({
				value: city,
				label: city
			}));

			result.unshift({ value: "ALL", label: "ALL" });
			//   console.log(result,'resultresult');

			this.setState({
				hubServingCities: result,
				hubServinginitialData: result,
				hubmasterlocationlist: responseData,
				//   lastmilevehiclenolist:vehicleNoList
			});
		})
	}
	selectMovementTypeOptionsItems() {
		let items = [];
		items.push({ value: "all", label: "All" });
		var formdata = {}
		if (localStorage.getItem("hub_transporter_code") != undefined && localStorage.getItem("hub_transporter_code") != null && localStorage.getItem("hub_transporter_code") != "") {
			formdata.hub_transporter_code = localStorage.getItem("hub_transporter_code")
		}

		this.state.hubmasterlocationlist.map((ele) => {

			items.push({ value: ele.hub_serving_city, label: ele.hub_location });
		})
		// items.push({value:true,label:"Local Movement"});
		// items.push({value:false,label:"Non Local Movement"});
		return items;
	}
	/*Venkatesh Jalla : New code for filters*/
	onGetConsigners = async (event) => {

		var transporter_code = localStorage.getItem('transportercode')
		if (transporter_code != undefined && transporter_code != "" && transporter_code != null) {
			transporter_code = transporter_code
		}

		if (this.props.match.path == "/sndconsignments" || this.props.match.path == "/prtconsignmentshub"
			|| this.props.match.path == "/tnpconsignments") {

			if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
				//var sDate = this.state.defaultsdate;
				var sDate = "";
			}
			else {
				var sDate = this.state.startDate;
			}
			if (this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN") {
				//var eDate = this.state.defaultedate;
				var eDate = "";
			}
			else {
				var eDate = this.state.endDate;
			}

		}
		else {
			if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
				var sDate = this.state.defaultsdate;
			}
			else {
				var sDate = this.state.startDate;
			}
			if (this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN") {
				var eDate = this.state.defaultedate;
			}
			else {
				var eDate = this.state.endDate;
			}
		}
		let dCheck = dateCheck(sDate, eDate)
		if (dCheck === 1) {
			this.setState({
				basicTitle: "From Date should be less than To Date",
				basicType: "danger",
				show: true,
				loadshow: "show-n",
				overly: "show-n",
				startDate: ""
			})
			return null
		}

		var selectconsigners = []
		if (this.state.consigner == null) {
			selectconsigners = [{ "value": 'all', "label": 'All' }]
		}
		else {
			selectconsigners = this.state.consigner
		}

		var selectconsignees = []
		if (this.state.consignee == null) {
			selectconsignees = [{ "value": 'all', "label": 'All' }]
		}
		else {
			selectconsignees = this.state.consignee
		}

		var hub_cities = []
		// console.log(this.state.movementtype,"afdgfgerdfd")
		if (this.state.movementtype != undefined) {
			if (this.state.movementtype.value != undefined && this.state.movementtype.value != null && this.state.movementtype.value != "") {
				if (this.state.movementtype == null || this.state.movementtype.value.toLowerCase() == "all") {
					// console.log(this.state.flCity,'null')
					if (this.state.flCity != null && this.state.flCity != '') {
						if (this.state.flCity.filter(e => e.value == "ALL").length == 0) {
							hub_cities = this.state.flCity;
						}
					}
				} else {
					if (this.state.flCity == null || this.state.flCity == "") {
						hub_cities = this.state.hubServingCities
					}
					else if (this.state.flCity.filter(e => e.value == "ALL").length) {
						hub_cities = this.state.hubServingCities
					} else {
						hub_cities = this.state.flCity
					}
				}
			} else {
				hub_cities = []
			}
		} else {
			hub_cities = []
		}
		// else{
		// }

		//console.log("this.state.movementtype ", this.state.movementtype)
		//console.log("mvmtype ", mvmtype)		// }
		let consignmentState = this.state.flState
		if (this.state.deptcode == 'LOG-PRT') {
			//console.log("S2 ")
			var requestparams = {
				consigner: selectconsigners,
				dept_code: this.state.deptcode,
				startDate: sDate,
				endDate: eDate,
				// movement:mvmtype,
				consigneeCity: hub_cities
			}

		}

		let transporter = this.state.flTransporter
		if (transporter !== '' && transporter !== undefined) {
			requestparams['transporter'] = transporter.value
		}
		// console.log("requestparamsCheck", requestparams)

		var urlpath = '';

		if (this.props.match.path == "/prtconsignmentshub") {
			urlpath = '/consignments/prtconsignmentshub';

		}

		else if (this.props.match.path == "/prtallconsignmentshub") {
			urlpath = '/consignments/allprtconsignmentshub';
			requestparams['screen'] = "all";
		}

		else if (this.props.match.path == "/prtdeliveredconsignmentshub") {
			urlpath = '/consignments/deliveredprtconsignmentshub';
		}
		// console.log(this.state.hubOwner,"fdsfgsfgsf")
		if (this.state.hubOwner == "activet") {
			// console.log("gadadga")
			this.onLoadGetConsignmentsList(urlpath, requestparams)
		} else {
			// console.log("dagdgd")
			this.transportertranckingData(urlpath, requestparams);
		}

	}
	getTruckConsignments = (event) => {
		this.setState({
			loadshow: "show-m",
			overly: "show-m"
		})
		event.preventDefault();
		var selectedFilter = $("#selectedFilter").text();
		var inputTruckConsignment = $("#inputTruckConsignment").val();
		if (selectedFilter == "Select..." || inputTruckConsignment == "") {
			this.setState({
				basicTitle: "Fields Should not be Empty",
				basicType: "danger",
				show: true,
				loadshow: "show-n",
				overly: "show-n"
			})
		}
		else {
			// console.log(selectedFilter,inputTruckConsignment);
			var urlpath = ""
			if (this.props.match.path == "/prtconsignmentshub") {
				urlpath = "/consignments/filterByConsignmentHub";
			}
			if (this.props.match.path == "/prtdeliveredconsignmentshub") {
				urlpath = "/consignments/filterDeliveredByConsignmentHub";
			}

			if (this.props.match.path == "/prtallconsignmentshub") {
				urlpath = "/consignments/filterAllByConsignmentHub";
			}

			var transporter_code = "";
			var transportercode = localStorage.getItem('transportercode');
			if (transportercode != undefined && transportercode != "undefined" && transportercode != "") {
				var tptCode = JSON.parse(transportercode);
				var transporter_code = tptCode[0];
			}
			var parameters = {
				"selectedFilter": selectedFilter,
				"inputTruckConsignment": inputTruckConsignment,
				"dept_code": this.state.deptcode,
				"transporter_code": transporter_code,
				"returnConsignments": this.state.tnpReturnConsignmentsVisibilty,
				"screenType": "HubScreen",
				"prt_transporter_code": localStorage.getItem("prt_transporter_code"),
				"hub_transporter_code": localStorage.getItem("hub_transporter_code")
			}
			if (localStorage.getItem("is_sales_type") == 1) {
				parameters.sales_type = localStorage.getItem("sales_type");
				parameters.region = localStorage.getItem("region");
			}
			if (this.state.tnpReturnConsignmentsVisibilty == true) {
				parameters.tnpReturnConsignmentsVisibilty = 1
			}
			redirectURL.post(urlpath, parameters)
				.then((response) => {
					var counters = response.data.counters
					if (this.state.tnpReturnConsignmentsVisibilty) {
						counters.transit_delay = [{ "transit_delay_count": this.state.returnConsignments.filter(e => e.transit_delay == 1).length }];
						counters.enroute_stoppage = [{ "enroute_exception_count": this.state.returnConsignments.filter(e => e.enroute_exception == 1).length }];
						counters.criticalCount = [{ "criticalCount": this.state.returnConsignments.filter(e => e.is_critical == true).length }];
					}
					counters.deviation_flag = [{ "deviation_flag": response.data.consignments.filter(e => e.deviation_flag == true).length }];
					counters.pod_received_data = response.data.consignments.filter(e => e.pod_received == 1).length

					// console.log(response, "response");
					var records = (response.data.consignments);
					// records.sort(this.compareObjects);
					//  records = records.sort((a, b) => {
					// 	const dateA = new Date(a.gate_out_time);
					// 	const dateB = new Date(b.gate_out_time);
					// 	console.log(dateA,dateB,'date-----AB')
					// 	return dateB - dateA;
					//   });
					records = records.sort((a, b) => {
						// First, sort by gate_out_time (descending)
						const dateA = new Date(a.gate_out_time);
						const dateB = new Date(b.gate_out_time);

						if (dateA > dateB) {
							return -1;
						} else if (dateA < dateB) {
							return 1;
						} else {
							// If gate_out_time is the same, sort by consignment_code (ascending)
							if (a.consignment_code < b.consignment_code) {
								return -1;
							} else if (a.consignment_code > b.consignment_code) {
								return 1;
							} else {
								return 0;
							}
						}
					});

					this.setState({
						totaldataforcount: records
					})


					var truckCount = {};
					var serial_number = 0;

					// Sort the array of objects
					// records.sort(this.compareObjects);
					// console.log(records, 'totaldataforcount---');

					this.state.totaldataforcount.map((record) => {
						var truckNo = record.truck_no;
						var gate_out_time = moment.parseZone(new Date(record.gate_out_time)).format("YYYY-MM-DD HH")
						let uniqueKey = truckNo + "_" + gate_out_time;
						//   console.log(uniqueKey, "uniqueKey")
						serial_number++;
						record.serial_number = serial_number;
						// console.log(serial_number,'serial_number')
						// If the truck number is already encountered, update the count
						if (truckCount[uniqueKey]) {
							truckCount[uniqueKey]++;
						} else {
							// If it's the first occurrence, set the count to 1
							truckCount[uniqueKey] = 1;
							record.original_truck_no = record.truck_no;
						}

						// Update the record with the truck_count and mark duplicates as empty string
						//   record.truck_count = truckCount[truckNo];

						// For duplicates, set truck_no to an empty string
						if (truckCount[uniqueKey] > 1) {
							record.original_truck_no = record.truck_no
							record.truck_no = "";
							// console.log(truckCount[truckNo],'truckCount[truckNo]')

						}
					});


					this.setState({
						// maxTruckCount: Math.max(...Object.values(truckCount))
						maxTruckCount: truckCount
					});

					// console.log("Maximum truck count:", this.state.maxTruckCount);

					this.setState({
						rowData: records,
						counterTypelabelName: '',
						allRowData: records,
						countersjson: counters,
						loadshow: "show-n",
						overly: "show-n"
					})
				}).catch = ((e) => {
					console.log(e);
				})
		}

	}

	sortAndArrangeRowSpan = (grid_row_data) => {
		grid_row_data.map(row => {
			row.truck_no = row.original_truck_no;
		})
		grid_row_data = grid_row_data.sort((a, b) => {
			// First, sort by gate_out_time (descending)
			const dateA = new Date(a.gate_out_time);
			const dateB = new Date(b.gate_out_time);

			if (dateA > dateB) {
				return -1;
			} else if (dateA < dateB) {
				return 1;
			} else {
				// If gate_out_time is the same, sort by consignment_code (ascending)
				if (a.consignment_code < b.consignment_code) {
					return -1;
				} else if (a.consignment_code > b.consignment_code) {
					return 1;
				} else {
					return 0;
				}
			}
		});
		var truckCount = {};
		var serial_number = 1;
		grid_row_data.map((record) => {
			var truckNo = record.truck_no;
			var gate_out_time = moment.parseZone(new Date(record.gate_out_time)).format("YYYY-MM-DD HH")
			let uniqueKey = truckNo + "_" + gate_out_time;
			//   console.log(uniqueKey, "uniqueKey")
			serial_number++;
			record.serial_number = serial_number;
			// console.log(serial_number,'serial_number')f
			// If the truck number is already encountered, update the count
			if (truckCount[uniqueKey]) {
				truckCount[uniqueKey]++;
			} else {
				// If it's the first occurrence, set the count to 1
				truckCount[uniqueKey] = 1;
				record.original_truck_no = record.truck_no;
			}

			// Update the record with the truck_count and mark duplicates as empty string
			// record.truck_count = truckCount[truckNo];

			// For duplicates, set truck_no to an empty string
			if (truckCount[uniqueKey] > 1) {
				record.original_truck_no = record.truck_no
				record.truck_no = "";
				// console.log(truckCount[truckNo],'truckCount[truckNo]')

			}
		});

		// console.log("Maximum truck count:", this.state.maxTruckCount);

		this.setState({
			maxTruckCount: truckCount,
			loadshow: "show-n",
			overly: "show-n"
		}, () => {
			// Call the API method after the state has been updated
			this.gridApi.redrawRows();
		})
		return grid_row_data
	}

	// onKeyPress(e) {
	// 	console.log(e, "==", e.target);
	// 	var value = e.target.value;
	// 	const regex = /^[0-9\-\: ]+$/;
	  
	// 	if (!regex.test(value)) {
	// 	  // If the entered value does not match the allowed characters
	// 	  if (![null, undefined, ""].includes(value)) {
	// 		value = value.slice(0, -1);
	// 	  }
	// 	  e.target.value = value; // Clear the input value
	// 	} else if (value.length > 19) {
	// 	  // If the length is greater than 19 characters (e.g., "2024-01-24 00:00:00")
	// 	  e.target.value = value.slice(0, 19); // Trim to the allowed length
	// 	}
	//   }
	
	// handleKeyDown = (filter,e) => {
	// 	// Handle Backspace key
	// 	console.log(filter,e,"print")
	// 	if (e.key === 'Backspace') {
	// 		// Prevent the default behavior (deleting the entire date)
	// 		e.preventDefault();

	// 		// Your custom logic for Backspace handling
	// 		const newValue = e.target.value.slice(0, -1);
	// 		e.target.value = newValue;
	// 		console.log(e.target.value,'e.target.value',(e.target.value == ""))
	// 		if(e.target.value == ""){
	// 			var { additional_last_mile_truck } = this.state
	// 			additional_last_mile_truck[filter] = ""
	// 			this.setState({ additional_last_mile_truck })
	// 		}
	// 	}
	// };

	onKeyPress(e) {
		const value = e.target.value;
		const cursorPosition = e.target.selectionStart;
		const regex = /^[0-9\-\: ]+$/;
	  
		if (!regex.test(e.key)) {
		  e.preventDefault();
		} else {
		  const newValue =
			value.slice(0, cursorPosition) +
			e.key +
			value.slice(cursorPosition);
	  
		  // Update the value and handle invalid characters
		  if (!/^\d{0,4}(\-\d{0,2}(\-\d{0,2}(\s\d{0,2}(\:\d{0,2}(\:\d{0,2})?)?)?)?)?$/.test(newValue)) {
			e.preventDefault();
		  } else {
			e.target.value = newValue;
	  
			// Set the cursor position back to where it was
			e.target.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
		  }
		}
	  }
	  handleKeyDown(filter, e) {
		// Handle Backspace key
		if (e.key === 'Backspace') {
		  e.preventDefault();
	  
		  const cursorPosition = e.target.selectionStart;
		  const selectionEnd = e.target.selectionEnd;
		  const value = e.target.value;
	  
		  // Check if the entire value is selected
		  if (cursorPosition === 0 && selectionEnd === value.length) {
			// Use e.persist() to make sure the event object is not recycled
			e.persist();
	  
			// Use the callback function in setState to ensure the state is updated correctly
			this.setState((prevState) => {
			  const updatedState = { ...prevState };
			  updatedState.additional_last_mile_truck[filter] = "";
			  return updatedState;
			});
		  } else {
			// Your existing logic for Backspace handling
			const newValue =
			  value.slice(0, cursorPosition - 1) +
			  value.slice(cursorPosition);
	  
			e.target.value = newValue;
	  
			// Set the cursor position back to where it was
			e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
		  }
		}
	  }
	  
	  

	handlerStartDateTimeNew = (filter, event) => {
		
		var d = new Date(event._d);
		console.log(d, "d")
		if (d == "Invalid Date") {
			// var { additional_last_mile_truck } = this.state
			// additional_last_mile_truck[filter] = ""
			// this.setState({ additional_last_mile_truck })
			// console.log("alert",additional_last_mile_truck[filter])
		} else {
			if(filter === "reporting_time_at_consignees_from_lastmile"){

				// console.log(d,filter,"4815",this.state.gate_out_time_for_compare)
	
				var split_String_gateOut = this.state.gate_out_time_for_compare.split("T")
					let date = split_String_gateOut[0]
					let time = split_String_gateOut[1].split(".")[0]
				var	gate_out_time1 = date + " " + time
	
					var reporting_time_at_consignees_from_lastmile = (moment.parseZone(new Date(d)).utcOffset("+05:30")._d)
	
					var gate_out_time = (moment.parseZone(new Date(gate_out_time1)).utcOffset("+05:30")._d)
	
				// console.log(reporting_time_at_consignees_from_lastmile , '-----' , gate_out_time)
	
					const timeDiff2 = reporting_time_at_consignees_from_lastmile - gate_out_time; // Difference in milliseconds
					
					const hours2 = Math.floor(timeDiff2 / (1000 * 60 * 60)); // Convert milliseconds to hours
					
					const minutes2 = Math.floor((timeDiff2 % (1000 * 60 * 60)) / (1000 * 60));
					
					var transit_overall_time = hours2 + "." + minutes2
	
	
					// console.log(gate_out_time,'gate_out_timegate_out_time',transit_overall_time,'transit_overall_time')
					if (Number(transit_overall_time) > 48) {
						this.setState({
							remarkCheck : false
						})
						console.log("above 48 hours")
						for (const hub of this.state.totalhubData) {
							// Split hub_serving_city into an array of cities
							const servingCities = hub.hub_serving_city.split(',');
						
							// Check if consignee_city and consigner_code match with the hub
							if (
								servingCities.includes(this.state.totalDataOnAddButtonClick.consignee_city) &&
								hub.hub_consigner_code === this.state.totalDataOnAddButtonClick.consigner_code
							) {
								// console.log("hello");
								this.setState({
									remarkCheck: true
								});
								break;
							}
						}
						$("#remark_for_delay_legs").val("")
						this.setState({
							remark_for_delay_value_state : true
						});
					}else{
						this.setState({
							remark_for_delay_value_state:false,
							remarkCheck : false
						})
					}

			}

			var { additional_last_mile_truck } = this.state
			additional_last_mile_truck[filter] = d
			this.setState({ additional_last_mile_truck })
		}

	}

	validateDispatchTime = (filter, validateWith, event) => {
		console.log(event, typeof(event))
		if(typeof(event) == "string"){
			const regex = /^[0-9\-\: ]+$/;
			if(!regex.test(event)){
				event = "Invalid Date"
			}
			else{
				if(!event.includes(":")){
					event = "Invalid Date"
				}else{					
					event = new Date(event)
				}
			}
		}
		// console.log("event", event)
		if(event != "Invalid Date"){
			var { additional_last_mile_truck, selected_row_from_grid } = this.state
			// console.log(filter, validateWith,"check001",additional_last_mile_truck[filter])
			if (selected_row_from_grid[validateWith]) {
				if (moment.parseZone(new Date(selected_row_from_grid[validateWith])).utcOffset("-05:30")._d >= moment.parseZone(new Date(additional_last_mile_truck[filter]))._d) {

					// eslint-disable-next-line no-restricted-globals
					var onConfirm = confirm("Dispatch Time Should be greater than Actual Arrival Time");

					// if(onConfirm){
					var { additional_last_mile_truck } = this.state
					additional_last_mile_truck[filter] = ""
					this.setState({ additional_last_mile_truck })
					// console.log("alert")
					// }
				}
			}
		}
		else{
			var { additional_last_mile_truck } = this.state
			additional_last_mile_truck[filter] = ""
			this.setState({ additional_last_mile_truck });
			event = ""
			this.setState({
				basicTitle: "Please enter date with correct Format (YYYY-MM-DD HH:MM)",
				basicType: "warning",
				show: true
			})
		}
	}
	validateReportingTime = (filter, validateWith, event) => {
		var { additional_last_mile_truck, selected_row_from_grid } = this.state
		// console.log(new Date(additional_last_mile_truck["dispatch_time_from_hub_from_lastmile"]),'check1')
		if (new Date(additional_last_mile_truck["dispatch_time_from_hub_from_lastmile"]) != "Invalid Date") {

			if (new Date(additional_last_mile_truck["reporting_time_at_consignees_from_lastmile"]) != "Invalid Date") {

			// console.log(new Date(additional_last_mile_truck[filter]),'selected',new Date(additional_last_mile_truck["reporting_time_at_consignees_from_lastmile"]),'===',new Date(additional_last_mile_truck[filter]) <= new Date(additional_last_mile_truck["dispatch_time_from_hub_from_lastmile"]))

			if (new Date(additional_last_mile_truck[filter]) <= new Date(additional_last_mile_truck["dispatch_time_from_hub_from_lastmile"])) {

				// console.log('inside the 3logic',new Date(additional_last_mile_truck[filter]) <= new Date(additional_last_mile_truck["dispatch_time_from_hub_from_lastmile"]))
				// console.log(new Date(additional_last_mile_truck[filter]) ,"====", new Date(additional_last_mile_truck["reporting_time_at_consignees_from_lastmile"]),'++++',new Date(additional_last_mile_truck[filter]) <= new Date(additional_last_mile_truck["reporting_time_at_consignees_from_lastmile"]))
				
				

					// console.log('in side 4th logic',new Date(additional_last_mile_truck[filter]) <= new Date(additional_last_mile_truck["reporting_time_at_consignees_from_lastmile"]))
					
					if (filter == "reporting_time_at_consignees_from_lastmile") {
						var time = "Reporting"
					} else {
						var time = "Delivery"
					}
					var { additional_last_mile_truck } = this.state
					additional_last_mile_truck[filter] = ""
					this.setState({
						show: true,
						basicTitle: `${time} Time Should Be Greater Than Dispatch Time from Hub`,
						basicType: "danger",
						loadshow: 'show-n',
						additional_last_mile_truck
					});
				}
				if(filter === "delivery_time_from_lastmile"){
					if (new Date(additional_last_mile_truck[filter]) <= new Date(additional_last_mile_truck["reporting_time_at_consignees_from_lastmile"])){
						var { additional_last_mile_truck } = this.state
						additional_last_mile_truck[filter] = ""
						this.setState({
							show: true,
							basicTitle: `Delivery Time Should Be Greater Than Reporting Time from Hub`,
							basicType: "danger",
							loadshow: 'show-n',
							additional_last_mile_truck
						});
				}
				}
		}else {
			var { additional_last_mile_truck } = this.state
				additional_last_mile_truck[filter] = ""
			this.setState({
				show: true,
				basicTitle: 'Select Reporting Date',
				basicType: "danger",
				loadshow: 'show-n'
			});
		}
		} else {
			var { additional_last_mile_truck } = this.state
				additional_last_mile_truck[filter] = ""
			this.setState({
				show: true,
				basicTitle: 'Select Dispatch Date',
				basicType: "danger",
				loadshow: 'show-n'
			});
		}
	}
	
	handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
		if (this.state.endDate != undefined && this.state.endDate != "") {
			// console.log(startdate,"startdate")
			// console.log(this.state.endDate,"endDate")
			var startDate = new Date(startdate);
			var endDate = new Date(this.state.endDate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			// console.log(startTime,endTime);
			if (startTime > endTime) {
				// this.setState({
				// 	startDate:""
				// });
				this.setState({
					show: true,
					basicTitle: 'To Date should be greater than From Date',
					basicType: "danger",
					loadshow: 'show-n'
				});
			}
			else {
				this.setState({
					startDate: startdate
				});
			}
		}
		else {
			this.setState({
				startDate: startdate
			});
		}
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerEndDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())

		if (this.state.endDate != undefined && this.state.endDate != "") {
			// console.log(this.state.startDate,"startdate")
			// console.log(edate,"endDate")
			var startDate = new Date(this.state.startDate);
			var endDate = new Date(edate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			// console.log(startTime,endTime);
			if (startTime > endTime) {
				// this.setState({
				// 	endDate:""
				// });
				this.setState({
					show: true,
					basicTitle: 'To Date should be greater than From Date',
					basicType: "danger",
					loadshow: 'show-n'
				});
			}
			else {
				this.setState({
					endDate: edate
				});
			}
		}
		else {
			this.setState({
				endDate: edate
			});
		}
	}

	onRowSelection(event) {
		var containerslist = [];
		//console.log("rowselect", event)
		var rwCount = event.api.getSelectedNodes();
		// console.log("rwCount ", rwCount)
		//if(event.node.selected == true)
		//{
		//var containerlist=[];
		// console.log("rowselect", event.data)
		var rowCount = event.data
		//console.log("rowCount ", rowCount)

		rwCount.map((item) => {
			containerslist.push(item.data.truck_no)
		});

		this.setState({
			containerslist: containerslist
		})
		//  console.log("checkedlist ", containerslist)
		// }
		// else{
		// 	if(containerslist.length > 0)
		// 	{
		// 		containerslist=[]
		// 		this.setState({
		// 			containerslist:containerslist
		// 		})
		// 	}
		// }

	}
	onOpenModal = () => {
		this.setState({ open: true });
	};

	onCloseModal = () => {
		this.gridApi.deselectAll();
		this.setState({ open: false, openLastMileModal: false });
	};
	changeSegmentHandler = (e) => {
		//  alert(e.consignment_code);
		this.setState({
			sliderSegmentTranslate: "slider-translate-60p",
			segementConsignmentCode: e.consignment_code,
			overly: "show-m"
		})
	}
	onClickForceClose = (params) => {
		//console.log("Pareas ", params)
		this.setState({
			forceclosedata: params.data,
			forceCloseRowNode: params,
			sliderForceCloseTranslate: "slider-translate-40p",
			overly: 'show-m',
			reasonforceclose: ""
		})
	}
	handleInput(val) {
		this.setState({ reasonforceclose: val[1], selectedReason: val[0] });
		// console.log(val)
	}
	changeHandler = (event) => {
		//console.log("Select", event.target.value);
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}

	onClickSaveGridState = () => {
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		let screenpage = '';
		if (this.props.match.path == "/prtconsignmentshub") {
			screenpage = 'prt active consignments';
		}
		else if (this.props.match.path == "/prtallconsignmentshub") {
			screenpage = 'prt all consignments';
		}
		else if (this.props.match.path == "/prtdeliveredconsignmentshub") {
			screenpage = 'prt delivered consignments';
		}

		let reqparams = {
			gridcolumns: window.colState,
			gridgroup: window.groupState,
			gridcolsort: window.sortState,
			gridcolfilter: window.filterState,
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: screenpage,
			// pagetitle : this.state.pagetitle
		}
		// console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
			.then((response) => {
				//console.log("State response ",response.data)
				this.setState({
					show: true,
					basicTitle: "Successfully saved grid layout",
					basicType: "success",
					screenurl: window.location.pathname,
					screentitle: screenpage
				})
			})
			.catch(function (e) {
				console.log("Error ", e)
			})
	}

	restoreGridStates = () => {

		if (this.state.usergridstate.length > 0) {
			var windowstates = this.state.usergridstate;
			this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
			this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
			this.gridApi.setSortModel(windowstates[0].gridcolsort);
			this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

		}
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: this.state.screenpage,
			// pagetitle : this.state.pagetitle
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
			.then((response) => {
				this.setState({
					show: true,
					basicTitle: "Successfully reset default grid layout",
					basicType: "success"
				})
			})

	};
	resetFilters=()=>{
		window.location.reload()
		// console.log(this.state.beforeNewFilterRowData,)
		// this.setState({
		// 	rowData : this.state.beforeNewFilterRowData
		// })
		// this.gridApi.setRowData(this.state.allRowData)
	}

	resetUpload = () => {
		this.setState({
			slideuploadeway: '',
			bulksliderforcomment: '',
			bulksliderforcommentlast: '',
			bulksliderforcommentlastforadditionaltrucks: '',
			overly: 'show-n',
			file: '',
			showDiv: 'show-n',
			uploadDivWidth: '0%',
			bulkPrioritySlide: '',
			bulkForceCloseSlide: '',
		});
	}
	onCellModalClicked = async (cell) => {
		// console.log(cell.node.rowIndex,"5915")
		if (cell.node.rowIndex !== 0) {
			if (cell.colDef.field == "edit_last_mile") {
				// console.log(cell.data,this.state.openLastMileModal,this.state.additional_last_mile_truck,"5712")
				var { additional_last_mile_truck } = this.state
				additional_last_mile_truck = cell.data
				additional_last_mile_truck.pod_status_value_last_tmile = ""
				this.setState({
					additional_last_mile_truck_slider_for_edit: "slider-translate",
					additional_last_mile_truck: additional_last_mile_truck,
					openLastMileModal: false

				});
			}

			if (cell.colDef.field == "pod_file_lastmile") {
				console.log(cell.data.pod_status_value_last_tmile, "5978")
				if (cell.data.pod_status_value_last_tmile != 0) {
					var fileName = cell.data.pod_file_lastmile
					var instantfileName = this.state.instant_pod_last_mile
					// console.log(fileName,"496",instantfileName)
					if (fileName != "") {
						var reqparams = {
							uploadOtherFileName: fileName,
							instantType: 'POD Last Download'
						}
						redirectURL.post("/consignments/downloadfilepodlast", reqparams, {
							responseType: 'blob'
						}).then((response) => {
							// console.log("download response ",response)
							download(response.data, fileName[0].name)

						}).catch(function (error) {
							console.log(error);
						})
					} else if (fileName == "") {
						if (instantfileName != '') {
							var reqparams = {
								uploadOtherFileName: instantfileName[0].name,
								instantType: 'Instant POD Last Download'
							}

							// console.log('in side instant',reqparams.uploadOtherFileName)
							redirectURL.post("/consignments/downloadfilepodlast", reqparams, {
								responseType: 'blob'
							}).then((response) => {
								// console.log("download response hub",response)
								download(response.data, instantfileName[0].name)

							}).catch(function (error) {
								console.log(error);
							})
						}
					}
				}

			}
		}


	}

	onCellClicked = async (cell) => {

		this.setState({ selected_row_from_grid: cell.data })
		var legs_columndefs = [

			{
				headerName: "Consignment Code",
				field: "consignment_code",

				width: 120,

				editable: false,

				resizable: true,
				//cellRenderer : "agGroupCellRenderer"
			},
			{
				headerName: "CMT",
				field: "volume",
				width: 80,
				editable: (params) => {

					if (params.node.rowIndex == 0) {
						return false
					} else {
						return ([undefined, "", null].includes(localStorage.getItem("hub_transporter_code"))
							&& localStorage.getItem("user_type") == "TRANSPORTER" || ((this.props.match.path == "/prtallconsignmentshub") || (this.props.match.path == "/prtdeliveredconsignmentshub")))
							? false : ((this.state.transporterhub == "activet") ? false : true)
					}

				},
				resizable: true,
				valueGetter: (params) => {
					if (params.data.volume == undefined) {
						var total_volume = 0
						var total_row_data = this.state.rowDataForLastMile
						total_row_data.map((e) => {
							if (e.volume != undefined) {
								total_volume = parseFloat(e.volume) + total_volume
							}
						})
						console.log(this.state.main_cmt, "check-6043", total_volume)
						return (parseFloat(this.state.main_cmt) - parseFloat(total_volume)).toFixed(2)
					} else {
						return params.data.volume
					}

				},
				valueSetter: (params) => {
					console.log(params, "6073")
					const newValue = params.newValue
					const oldValue = params.oldValue
					var legs_cmt_data = this.state.legs_cmt_data
					var legs_cmt = 0
					if (legs_cmt_data != undefined && legs_cmt_data != "" && legs_cmt_data != null) {
						legs_cmt_data.map((e) => {
							if (e.volume != undefined && e.volume != null && e.volume != "") {
								legs_cmt = e.volume + legs_cmt
							}
						})
					}
					console.log(this.state.main_cmt, "6086", legs_cmt)
					var final_cmt = parseFloat(this.state.main_cmt) - parseFloat(legs_cmt)
					console.log(final_cmt, "6087")
					if (parseFloat(newValue) != parseFloat(oldValue)) {
						if (parseFloat(final_cmt) > parseFloat(newValue)) {
							params.data.volume = newValue
							var query = {
								rowId: this.state.selected_row_from_grid._id,
								vehicle_no_from_lastmile: params.data.vehicle_no_from_lastmile,
								// reporting_time_at_consignees_from_lastmile:getYYYYMMDDHHMMSS(params.data.reporting_time_at_consignees_from_lastmile),
								volume: parseFloat(newValue),
								field: "last_mile_cmt",
								// transporter_code : data.transporter_code
								type: "Last Mile CMT"
							}
							// console.log(query,"check-6041")


							if (newValue != "") {
								try {

									// console.log(newValue,"5896")

									redirectURL.post("/consignments/updatinglastmilelegsdataforadditionalvehicle", query).then((response) => {
										var resp = response.data.data
										console.log(resp, "6111")
										var additional_last_mile_data = resp[0].additional_last_mile_truck
										console.log(additional_last_mile_data, "6113")
										this.gridApi2.redrawRows();
										this.setState({
											legs_cmt_data: additional_last_mile_data
										})
									});
									// this.initComponent();
								} catch (error) {
									console.error(error);
								}
							}
							return true;
						} else {
							this.setState({
								basicTitle: "Please Enter value less than the Total CMT",
								basicType: "danger",
								show: true
							})
						}
					} else {
						this.setState({
							basicTitle: "Please Enter New Value",
							basicType: "danger",
							show: true
						})
					}
				}
				//cellRenderer : "agGroupCellRenderer"
			},
			{
				headerName: 'Dispatch time from Hub*',
				field: 'dispatch_time_from_hub_from_lastmile',
				width: 150,
				resizable: true,
				filter: true,
				// filterFramework: CustomTextFilter, // Use your custom filter component
				// filterParams: {
				// 	onFilterChanged: this.onFilterChanged,
				// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
				// },
				editable: (params) => {

					if (params.node.rowIndex == 0) {
						return false
					} else {
						return ([undefined, "", null].includes(localStorage.getItem("hub_transporter_code"))
							&& localStorage.getItem("user_type") == "TRANSPORTER" || ((this.props.match.path == "/prtallconsignmentshub") || (this.props.match.path == "/prtdeliveredconsignmentshub")))
							? false : ((this.state.transporterhub == "activet") ? false : true)
					}

				},
				// hide:this.state.istransporter,
				cellEditor: DateEditor,
				width: 150,
				valueGetter: (param) => {
					if (param.data.dispatch_time_from_hub_from_lastmile !== "" && param.data.dispatch_time_from_hub_from_lastmile != undefined && param.data.dispatch_time_from_hub_from_lastmile != null) {
						let arrival_time = param.data.dispatch_time_from_hub_from_lastmile;
						if (typeof (arrival_time) == "string") {
							if (!arrival_time.includes("T") && (arrival_time.includes("-") || arrival_time.includes("/"))) {
								let splitArray = arrival_time.split(" ");
								let splitDate = ""
								if (arrival_time.includes("-")) {
									splitDate = splitArray[0].split("-")
								} else if (arrival_time.includes("/")) {
									splitDate = splitArray[0].split("/")
								}
								if (splitDate != "" && splitDate[0].length < 4) {
									arrival_time = splitDate[2] + "/" + splitDate[1] + "/" + splitDate[0] + " " + splitArray[1]
								}
							}
						}
						return getHyphenDDMMMYYYYHHMM(arrival_time);

					} else {
						return "";
					}
				},
				comparator: dateComparator1,
				valueSetter: (params) => {
					const newValue = params.newValue;
					// console.log(newValue,"dafdfadfadfadfadf", moment.parseZone(new Date(newValue)).utcOffset("+00:00")._d) 
					// console.log(this.state.selected_row_from_grid.actual_arrival_at_hub,"6025")
					if (moment.parseZone(new Date(newValue)).utcOffset("+00:00")._d >
						moment.parseZone(new Date(this.state.selected_row_from_grid.actual_arrival_at_hub)).utcOffset("-05:30")._d) {
						// console.log("check-1")
						const data = params.data;
						params.data.dispatch_time_from_hub_from_lastmile = moment.parseZone(new Date(newValue)).utcOffset("+00:00")._d
						// console.log('helllllo',newValue,'---',data);
						var query = {
							rowId: this.state.selected_row_from_grid._id,
							vehicle_no_from_lastmile: params.data.vehicle_no_from_lastmile,
							// reporting_time_at_consignees_from_lastmile:getYYYYMMDDHHMMSS(params.data.reporting_time_at_consignees_from_lastmile),
							dispatch_time_from_hub_from_lastmile: moment.parseZone(new Date(newValue)).utcOffset("+00:00")._d,
							field: "dispatch_time_from_hub_from_lastmile",
							// transporter_code : data.transporter_code
							type: "Dispatch time from HUB"
						}
						// console.log(query,"check-6041")


						if (newValue != "") {
							try {

								// console.log(newValue,"5896")

								redirectURL.post("/consignments/updatinglastmilelegsdataforadditionalvehicle", query).then((response) => {
									// console.log(response.data,"Gdagadgadg")
									// if(response.data.transit_time_last_mile != "" && response.data.transit_time_last_mile != undefined && response.data.transit_time_last_mile != null){
									// 	params.data.transit_last_mile=response.data.transit_time_last_mile
									// }
									this.gridApi2.redrawRows();
								});
								// this.initComponent();
							} catch (error) {
								console.error(error);
							}
						}
						return true;
					} else {
						// console.log("check-2")
						if (![undefined, null, ""].includes(newValue) && ![undefined, null, ""].includes(this.state.selected_row_from_grid.actual_arrival_at_hub)) {
							this.setState({
								show: true,
								basicTitle: 'Dispatch Time from Hub should be greater than Actual Arrival Time At Hub',
								basicType: "danger",
								loadshow: 'show-n'
							});
						}
					}
				}


			},
			{
				headerName: "Last Mile Fleet Availability*",
				field: "last_fleet_availability",
				width: 150,
				// hide:this.state.istransporter,
				filter: true,
				// filterFramework: CustomTextFilter, // Use your custom filter component
				// filterParams: {
				// 	onFilterChanged: this.onFilterChanged,
				// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
				// },
				resizable: true,
				editable: ((params) => {
					if (params.node.rowIndex == 0) {
						return false
					} else {
						return ([undefined, "", null].includes(localStorage.getItem("hub_transporter_code"))
							&& localStorage.getItem("user_type") == "TRANSPORTER" ||
							((this.props.match.path == "/prtallconsignmentshub") ||
								(this.props.match.path == "/prtdeliveredconsignmentshub"))) ? false : ((this.state.transporterhub == "activet") ? false : true)

					}
				}),

				cellEditor: 'agSelectCellEditor',
				cellEditorParams: {
					values: ["Fleet Master", "Other"],

				},
				valueGetter: function (params) {
					const podStatusValue = params.data.last_fleet_availability;

					if (podStatusValue === "") {
						return " "; // Display an empty string
					} else if (podStatusValue === "Fleet Master") {
						return "Fleet Master";
					} else if (podStatusValue === "Other") {
						return "Other";
					} else {
						return podStatusValue; // Display the actual value if it's not empty, 0, or 1
					}
				},
				valueSetter: (params) => {

					const newValue = params.newValue;
					// console.log("IN ValyeSetter, data = fleet ",params.newValue,'---');
					// if (params.data.last_fleet_availability == params.newValue) {
					params.data.last_fleet_availability = params.newValue == "(Blank)" ? "" : params.newValue


					var query = {
						rowId: this.state.selected_row_from_grid._id,
						vehicle_no_from_lastmile: params.data.vehicle_no_from_lastmile,
						last_fleet_availability: (params.newValue),
						field: "last_fleet_availability"
					}

					if (newValue != "") {
						try {

							// console.log(newValue,"5896")

							redirectURL.post("/consignments/updatinglastmilelegsdataforadditionalvehicle", query).then((response) => {
								// console.log(response.data,"Gdagadgadg")
								// if(response.data.transit_time_last_mile != "" && response.data.transit_time_last_mile != undefined && response.data.transit_time_last_mile != null){
								// 	params.data.transit_last_mile=response.data.transit_time_last_mile
								// }
								this.gridApi2.redrawRows();
							});
							// this.initComponent();
						} catch (error) {
							console.error(error);
						}
					}
					if (params.newValue == "(Blank)") {
						params.data.vehicle_no_from_lastmile = ""
						params.data.vehicle_type_from_lastmile = ""
					}

					return true;

				},

			},
			{
				headerName: 'Last Mile Vehicle Type*',
				field: 'vehicle_type_from_lastmile',
				// hide:this.state.istransporter,
				width: 150,
				resizable: true,
				filter: true,
				// filterFramework: CustomTextFilter, // Use your custom filter component
				// filterParams: {
				// 	onFilterChanged: this.onFilterChanged,
				// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
				// },
				editable: (params => {

					if (params.node.rowIndex == 0) {
						return false
					} else {
						return (([undefined, "", null].includes(localStorage.getItem("hub_transporter_code"))
							&& localStorage.getItem("user_type") == "TRANSPORTER" ||
							((this.props.match.path == "/prtallconsignmentshub") ||
								(this.props.match.path == "/prtdeliveredconsignmentshub"))) ? false : ((this.state.transporterhub == "activet") ? false : true))

					}

				}),

				cellEditorSelector: params => {

					if (params.data.last_fleet_availability === 'Other') {
						return {
							component: "agTextCellEditor",
						}
					}

					if (params.data.last_fleet_availability === 'Fleet Master') {
						return {
							component: 'agSelectCellEditor',
							params: {
								values: [...this.state.lastmilevehicletypelist.map(vehicle => vehicle.value)],
							}
						}
					}
					return undefined
				},

				valueGetter: (params) => params.data.vehicle_type_from_lastmile,
				valueSetter: (params) => {

					params.data.vehicle_type_from_lastmile = params.newValue == "(Blank)" ? "" : params.newValue

					const newValue = params.newValue;
					//   console.log(params, 'params', params.data,newValue);
					//   params.data.vehicle_type_from_lastmile = params.newValue

					var query = {
						rowId: this.state.selected_row_from_grid._id,
						vehicle_no_from_lastmile: params.data.vehicle_no_from_lastmile,
						vehicle_type_from_lastmile: (params.newValue) == "(Blank)" ? "" : (params.newValue),
						field: "vehicle_type_from_lastmile"
					}

					if (newValue != "") {
						try {

							//   console.log(newValue,"5896")

							redirectURL.post("/consignments/updatinglastmilelegsdataforadditionalvehicle", query).then((response) => {
								//   console.log(response.data,"Gdagadgadg")
								// if(response.data.transit_time_last_mile != "" && response.data.transit_time_last_mile != undefined && response.data.transit_time_last_mile != null){
								// 	params.data.transit_last_mile=response.data.transit_time_last_mile
								// }
								this.gridApi2.redrawRows();
							});
							// this.initComponent();
						} catch (error) {
							console.error(error);
						}
					}
					return true;
				}
			},
			{
				headerName: 'Last Mile Vehicle No*',
				field: 'vehicle_no_from_lastmile',
				width: 150,
				// hide:this.state.istransporter,
				filter: true,
				// filterFramework: CustomTextFilter, // Use your custom filter component
				// filterParams: {
				// 	onFilterChanged: this.onFilterChanged,
				// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
				// },									
				editable: (params => {

					if (params.node.rowIndex == 0) {
						return false
					} else {
						return (([undefined, "", null].includes(localStorage.getItem("hub_transporter_code"))
							&& localStorage.getItem("user_type") == "TRANSPORTER" ||
							((this.props.match.path == "/prtallconsignmentshub") ||
								(this.props.match.path == "/prtdeliveredconsignmentshub"))) ? false : ((this.state.transporterhub == "activet") ? false : true))

					}

				}),

				cellEditorSelector: params => {

					if (params.data.last_fleet_availability === 'Other') {
						return {
							component: "agTextCellEditor",
						}
					}

					if (params.data.last_fleet_availability === 'Fleet Master') {
						console.log(this.state.totallastmilevehicledetails, 'totallastmilevehicledetails', this.state.lastmilevehiclenolist);

						const filteredVehicleNumbers = this.state.totallastmilevehicledetails
							.filter(ele => ele.vehicle_type === params.data.vehicle_type_from_lastmile)
							.map(vehicle => vehicle.vehicle_no);

						return {
							component: 'agSelectCellEditor',
							params: {
								values: filteredVehicleNumbers
							}
						};
					}

					return undefined;


				},

				valueGetter: (params) => params.data.vehicle_no_from_lastmile,
				valueSetter: (params) => {

					console.log(params.node.rowIndex, params.newValue, "newValue")

					params.data.vehicle_no_from_lastmile = params.newValue == "(Blank)" ? "" : params.newValue

					const newValue = params.newValue;
					console.log(params, 'params', params.data, newValue);
					//   params.data.vehicle_type_from_lastmile = params.newValue

					var query = {
						rowId: this.state.selected_row_from_grid._id,
						vehicle_no_from_lastmile: params.data.vehicle_no_from_lastmile,
						rowIndex: params.node.rowIndex - 1,
						field: "vehicle_no_from_lastmile"
					}

					if (newValue != "") {
						try {

							console.log(newValue, "5896")

							redirectURL.post("/consignments/updatinglastmilelegsdataforadditionalvehicle", query).then((response) => {
								console.log(response.data, "Gdagadgadg")
								// if(response.data.transit_time_last_mile != "" && response.data.transit_time_last_mile != undefined && response.data.transit_time_last_mile != null){
								// 	params.data.transit_last_mile=response.data.transit_time_last_mile
								// }
								this.gridApi2.redrawRows();
							});
							// this.initComponent();
						} catch (error) {
							console.error(error);
						}
					}
					return true;
				}
			},
			{
				headerName: "POD-Last Mile*",
				field: "pod_status_value_last_tmile",
				width: 150,
				// hide:this.state.istransporter,
				resizable: true,
				filter: true,
				// filterFramework: CustomTextFilter, // Use your custom filter component
				// filterParams: {
				// 	onFilterChanged: this.onFilterChanged,
				// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
				// },
				editable: ((params) => {

					if (params.node.rowIndex == 0) {
						return false
					} else {
						return ([undefined, "", null].includes(localStorage.getItem("hub_transporter_code"))
							&& localStorage.getItem("user_type") == "TRANSPORTER" ||
							((this.props.match.path == "/prtallconsignmentshub") ||
								(this.props.match.path == "/prtdeliveredconsignmentshub"))) ? false : ((this.state.transporterhub == "activet") ? false : true)

					}
				}


				),
				// hide: function (params) {

				// 	return params.data.status >= 4;
				//   },
				// hide:params.data.status >= Number(4),
				cellEditor: 'agSelectCellEditor',
				cellEditorParams: {
					values: ["(Blank)", "Ok", "Not Ok"],
				},
				valueGetter: function (params) {
					const podStatusValue = params.data.pod_status_value_last_tmile;

					if (podStatusValue === "") {
						return " "; // Display an empty string
					} else if (podStatusValue === 0) {
						return "Ok";
					} else if (podStatusValue === 1) {
						return "Not Ok";
					} else {
						return podStatusValue; // Display the actual value if it's not empty, 0, or 1
					}
				},

				valueSetter: (params) => {
					// params.data.pod_status_value_last_tmile = params.newValue
					this.setState({
						rowIndexcount: params.node.rowIndex,
						commentValuelastmile: ''
					})
					// console.log("IN ValyeSetter, data = ",params.newValue,'---');
					if (params.data.pod_status_value_last_tmile !== params.newValue) {

						params.data.pod_status_value_last_tmile = params.newValue
						if (params.newValue === "Not Ok") {
							params.data.pod_status_value_last_tmile = 1
							params.newValue = 1;
							// Set the slider and overlay when "Not Ok"
							this.setState({

								bulksliderforcommentlastforadditionaltrucks: "slider-translate",
								overly: 'show-m',
								selected_row_from_modal_grid: {
									rowId: this.state.selected_row_from_grid._id,
									vehicle_no_from_lastmile: params.data.vehicle_no_from_lastmile
								}
							});
						}
						else if (params.newValue === "Ok") {

							const newValue = params.newValue;
							params.data.pod_status_value_last_tmile = 0
							// console.log(params, 'params', params.data,newValue);
							//   params.data.vehicle_type_from_lastmile = params.newValue
							params.data.comment_lastmile = ""
							params.data.pod_file_lastmile = ""
							var query = {
								rowId: this.state.selected_row_from_grid._id,
								vehicle_no_from_lastmile: params.data.vehicle_no_from_lastmile,
								pod_status_value_last_tmile: 0,
								field: "pod_status_value_last_tmile"
							}

							if (newValue != "") {
								try {

									//   console.log(newValue,"5896")

									redirectURL.post("/consignments/updatinglastmilelegsdataforadditionalvehicle", query).then((response) => {
										//   console.log(response.data,"Gdagadgadg")
										// if(response.data.transit_time_last_mile != "" && response.data.transit_time_last_mile != undefined && response.data.transit_time_last_mile != null){
										// 	params.data.transit_last_mile=response.data.transit_time_last_mile
										// }
										this.gridApi2.redrawRows();
									});
									// this.initComponent();
								} catch (error) {
									console.error(error);
								}
							}
							return true;
						}
						else if (params.newValue === "(Blank)") {

							const newValue = params.newValue;
							params.data.pod_status_value_last_tmile = ""
							// console.log(params, 'params', params.data,newValue);
							//   params.data.vehicle_type_from_lastmile = params.newValue
							params.data.comment_lastmile = ""
							params.data.pod_file_lastmile = ""
							var query = {
								rowId: this.state.selected_row_from_grid._id,
								vehicle_no_from_lastmile: params.data.vehicle_no_from_lastmile,
								pod_status_value_last_tmile: "",
								field: "pod_status_value_last_tmile"
							}

							if (newValue != "") {
								try {

									//   console.log(newValue,"5896")

									redirectURL.post("/consignments/updatinglastmilelegsdataforadditionalvehicle", query).then((response) => {
										//   console.log(response.data,"Gdagadgadg")
										// if(response.data.transit_time_last_mile != "" && response.data.transit_time_last_mile != undefined && response.data.transit_time_last_mile != null){
										// 	params.data.transit_last_mile=response.data.transit_time_last_mile
										// }
										this.gridApi2.redrawRows();
									});
									// this.initComponent();
								} catch (error) {
									console.error(error);
								}
							}
							return true;
						}
						else {
						}
						try {
							this.gridApi2.redrawRows();
						}
						catch (e) { }
						//   params.api.refreshCells({columns : ["comment_lastmile"]});
						return true;
					}
				}

			},
			{
				headerName: "Last Mile Comment",
				field: "comment_lastmile",
				width: 180,
				filter: true,
				// filterFramework: CustomTextFilter, // Use your custom filter component
				// filterParams: {
				// 	onFilterChanged: this.onFilterChanged,
				// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
				// },
				resizable: true,
				// hide:this.state.istransporter,

				cellRenderer: function (params) {
					// console.log(params.data.pod_status_value_last_tmile,params.data,"6429")
					if (params.data.pod_status_value_last_tmile == 1) {
						return params.data.comment_lastmile;
					} else {
						return "";
					}
				},
			},
			{
				headerName: 'POD Last Mile Download',
				field: 'pod_file_lastmile',
				// hide:this.state.istransporter,
				width: 180, // Use the desired width
				cellRenderer: function (params) {
					//   console.log((params.data.pod_file_lastmile,params.data.pod_status_value_last_tmile),'6606')
					if (params.data.pod_status_value_last_tmile != 0 && params.data.pod_status_value_last_tmile != "Ok") {
						if (params.data.pod_file_lastmile) {
							// var htmloption = '<a class="custom-btn label label-success"><i class="fa fa-download"></i>'+" "+params.data.pod_file_lastmile[0].name +'</a>';
							var htmloption = '<a class="custom-btn label label-success"><i class="fa fa-download"></i>' + " " + "Download" + '</a>';
							return htmloption;
						} else {
							return '';
						}
					} else {
						return ""
					}
				}
			},
			{
				headerName: 'Reporting time at consignee*',
				field: 'reporting_time_at_consignees_from_lastmile',
				width: 150,
				filter: true,
				// filterFramework: CustomTextFilter, // Use your custom filter component
				// filterParams: {
				// 	onFilterChanged: this.onFilterChanged,
				// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
				// },
				resizable: true,
				editable: ((params) => {

					if (params.node.rowIndex == 0) {
						return false
					} else {
						return ([undefined, "", null].includes(localStorage.getItem("hub_transporter_code"))
							&& localStorage.getItem("user_type") == "TRANSPORTER" ||
							((this.props.match.path == "/prtallconsignmentshub") ||
								(this.props.match.path == "/prtdeliveredconsignmentshub"))) ? false : ((this.state.transporterhub == "activet") ? false : true)

					}

				}),
				// hide:this.state.istransporter,

				cellEditor: DateEditor,
				valueGetter: (param) => {
					if (param.data.reporting_time_at_consignees_from_lastmile !== "" && param.data.reporting_time_at_consignees_from_lastmile != undefined && param.data.reporting_time_at_consignees_from_lastmile != null) {
						let arrival_time = param.data.reporting_time_at_consignees_from_lastmile;
						if (typeof (arrival_time) == "string") {
							if (!arrival_time.includes("T") && (arrival_time.includes("-") || arrival_time.includes("/"))) {
								let splitArray = arrival_time.split(" ");
								let splitDate = ""
								if (arrival_time.includes("-")) {
									splitDate = splitArray[0].split("-")
								} else if (arrival_time.includes("/")) {
									splitDate = splitArray[0].split("/")
								}
								if (splitDate != "" && splitDate[0].length < 4) {
									arrival_time = splitDate[2] + "/" + splitDate[1] + "/" + splitDate[0] + " " + splitArray[1]
								}
							}
						}
						return getHyphenDDMMMYYYYHHMM(arrival_time);

					} else {
						return "";
					}
				},
				comparator: dateComparator1,
				valueSetter: (params) => {
					const newValue = params.newValue;
					var report_time = moment.parseZone(new Date(newValue)).utcOffset("+00:00")._d;
					var dispatch_time_from_hub_from_lastmile = params.data.dispatch_time_from_hub_from_lastmile;
					var gate_out_time = this.state.selected_row_from_grid.gate_out_time;
					//   console.log("report time",report_time, "dispatch time", dispatch_time_from_hub_from_lastmile,"gate out time", gate_out_time)
					if (dispatch_time_from_hub_from_lastmile != undefined && dispatch_time_from_hub_from_lastmile != "" && dispatch_time_from_hub_from_lastmile != null && dispatch_time_from_hub_from_lastmile != "undefined") {
						if (typeof (dispatch_time_from_hub_from_lastmile) == "string") {
							if (dispatch_time_from_hub_from_lastmile.includes("T")) {
								var split_String = dispatch_time_from_hub_from_lastmile.split("T")
								let date = split_String[0]
								let time = split_String[1].split(".")[0]
								dispatch_time_from_hub_from_lastmile = date + " " + time
							}
						}
					}
					if (gate_out_time != undefined && gate_out_time != null && gate_out_time != "" && gate_out_time != "undefined") {
						if (typeof (gate_out_time) == "string") {
							if (gate_out_time.includes("T")) {
								var split_String = gate_out_time.split("T")
								let date = split_String[0]
								let time = split_String[1].split(".")[0]
								gate_out_time = date + " " + time
							}
						}
					}

					var dispatch_time_from_hub_from_lastmile = moment.parseZone(new Date(dispatch_time_from_hub_from_lastmile)).utcOffset("+00:00")._d;
					var gate_out_time = moment.parseZone(new Date(gate_out_time)).utcOffset("+00:00")._d;

				
					if (dispatch_time_from_hub_from_lastmile < report_time) {
						const data = params.data;
						var { selected_row_from_grid } = this.state
						
						const timeDiff = report_time - dispatch_time_from_hub_from_lastmile; // Difference in milliseconds
						const timeDiff2 = report_time - gate_out_time; // Difference in milliseconds
						const hours2 = Math.floor(timeDiff2 / (1000 * 60 * 60)); // Convert milliseconds to hours
						const minutes2 = Math.floor((timeDiff2 % (1000 * 60 * 60)) / (1000 * 60));
						const hours = Math.floor(timeDiff / (1000 * 60 * 60)); // Convert milliseconds to hours
						const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
						var transit_time_last_mile = hours + "." + minutes
						var transit_overall_time = hours2 + "." + minutes2


						params.data.transit_last_mile = transit_time_last_mile
						params.data.transit_overall_time = transit_overall_time
						// console.log('helllllo',newValue,'---',data);
						var query = {
							rowId: this.state.selected_row_from_grid._id,
							vehicle_no_from_lastmile: params.data.vehicle_no_from_lastmile,
							reporting_time_at_consignees_from_lastmile: newValue,
							transit_time_last_mile: transit_time_last_mile,
							transit_overall_time: transit_overall_time,
							field: "reporting_time_at_consignees_from_lastmile",
							// transporter_code : data.transporter_code
							type: "Dispatch time from HUB"
						}

						query["transit_last_mile"] = transit_time_last_mile
						query["transit_overall_time"] = transit_overall_time

						if (transit_overall_time > 48) {
							this.setState({
								remarkCheck : false
							})
							
							for (const hub of this.state.totalhubData) {
								const servingCities = hub.hub_serving_city.split(',');
								
								if (
									servingCities.includes(this.state.collecting_total_main_grid_data_onclick.consignee_city) &&
									hub.hub_consigner_code === this.state.collecting_total_main_grid_data_onclick.consigner_code
								) {
									// console.log("hello in legs");
									this.setState({
										remarkCheck: true
									});
									break;
								}
							}
							if(this.state.remarkCheck == true){

								$("#remark_from_overall_transit_time").val("")
							this.setState({
								id_for_remark_transit_overall: params.data._id,
								remark_for_over_time: "slider-translate",
								overly: 'show-m',
								rowIndexcount2: params.node.rowIndex,
								collecting_total_query: query,
							});
							}else{
								$("#remark_from_overall_transit_time").val("")
								this.setState({
									id_for_remark_transit_overall: params.data._id,
									remark_for_over_time: "slider-translate",
									overly: 'show-m',
									rowIndexcount2: params.node.rowIndex,
									collecting_total_query: query,
								});
								params.data.reporting_time_at_consignees_from_lastmile = params.newValue
								if (newValue != "") {
									try {
	
										//   console.log(newValue,"5896")
	
										redirectURL.post("/consignments/updatinglastmilelegsdataforadditionalvehicle", query).then((response) => {
											
											this.gridApi2.redrawRows();
										});
										// this.initComponent();
									} catch (error) {
										console.error(error);
									}
								}
							}
							// console.log("above 48 hours",params.node.rowIndex,params.node)
							
						} else {

							params.data.reporting_time_at_consignees_from_lastmile = params.newValue
							if (newValue != "") {
								try {

									//   console.log(newValue,"5896")

									redirectURL.post("/consignments/updatinglastmilelegsdataforadditionalvehicle", query).then((response) => {
										
										this.gridApi2.redrawRows();
									});
									// this.initComponent();
								} catch (error) {
									console.error(error);
								}
							}
							return true;
						}
					} else {
						//   console.log("check-2")
						if (![undefined, null, ""].includes(newValue) && ![undefined, null, ""].includes(dispatch_time_from_hub_from_lastmile)) {
							this.setState({
								show: true,
								basicTitle: 'Reporting Time should be greater than Dispatch Time from Hub',
								basicType: "danger",
								loadshow: 'show-n'
							});
						}
					}
				}

			},
			{
				headerName: 'Delivery time at Consignee*',
				field: 'delivery_time_from_lastmile',
				width: 150,
				resizable: true,
				filter: true,
				// filterFramework: CustomTextFilter, // Use your custom filter component
				// filterParams: {
				// 	onFilterChanged: this.onFilterChanged,
				// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
				// },
				editable: ((params) => {
					if (params.node.rowIndex == 0) {
						return false
					} else {
						return ([undefined, "", null].includes(localStorage.getItem("hub_transporter_code"))
							&& localStorage.getItem("user_type") == "TRANSPORTER" ||
							((this.props.match.path == "/prtallconsignmentshub") ||
								(this.props.match.path == "/prtdeliveredconsignmentshub"))) ? false : ((this.state.transporterhub == "activet") ? false : true)

					}
				}

				),
				// hide:this.state.istransporter,

				cellEditor: DateEditor,
				valueGetter: (param) => {
					if (param.data.delivery_time_from_lastmile !== "" && param.data.delivery_time_from_lastmile != undefined && param.data.delivery_time_from_lastmile != null) {
						let arrival_time = param.data.delivery_time_from_lastmile;
						if (typeof (arrival_time) == "string") {
							if (!arrival_time.includes("T") && (arrival_time.includes("-") || arrival_time.includes("/"))) {
								let splitArray = arrival_time.split(" ");
								let splitDate = ""
								if (arrival_time.includes("-")) {
									splitDate = splitArray[0].split("-")
								} else if (arrival_time.includes("/")) {
									splitDate = splitArray[0].split("/")
								}
								if (splitDate != "" && splitDate[0].length < 4) {
									arrival_time = splitDate[2] + "/" + splitDate[1] + "/" + splitDate[0] + " " + splitArray[1]
								}
							}
						}
						return getHyphenDDMMMYYYYHHMM(arrival_time);

					} else {
						return "";
					}
				},
				comparator: dateComparator1,
				valueSetter: (params) => {
					const newValue = params.newValue;

					var reporting_time_at_consignees_from_lastmile = params.data.reporting_time_at_consignees_from_lastmile;
					if (reporting_time_at_consignees_from_lastmile != undefined && reporting_time_at_consignees_from_lastmile != null && reporting_time_at_consignees_from_lastmile != "" && reporting_time_at_consignees_from_lastmile != "undefined") {
						if (typeof (reporting_time_at_consignees_from_lastmile) == "string") {
							if (reporting_time_at_consignees_from_lastmile.includes("T")) {
								var split_String = reporting_time_at_consignees_from_lastmile.split("T")
								let date = split_String[0]
								let time = split_String[1].split(".")[0]
								reporting_time_at_consignees_from_lastmile = date + " " + time
							}
						}
					}

					if (moment.parseZone(new Date(newValue)).utcOffset("+00:00")._d >=
						moment.parseZone(new Date(reporting_time_at_consignees_from_lastmile)).utcOffset("+00:00")._d) {
						//   console.log("check-1")
						const data = params.data;
						var { selected_row_from_grid } = this.state
						// params.data.delivery_time_from_lastmile = params.newValue
						// console.log('helllllo',newValue,'---',data);
						var query = {
							rowId: this.state.selected_row_from_grid._id,
							vehicle_no_from_lastmile: params.data.vehicle_no_from_lastmile,
							// reporting_time_at_consignees_from_lastmile:getYYYYMMDDHHMMSS(params.data.reporting_time_at_consignees_from_lastmile),
							delivery_time_from_lastmile: newValue,
							field: "delivery_time_from_lastmile",
							// transporter_code : data.transporter_code
							type: "Dispatch time from HUB"
						}
						var delivery_time_from_lastmile = moment.parseZone(new Date(params.newValue)).utcOffset("+00:00")._d
						if (!params.data.reporting_time_at_consignees_from_lastmile) {
							var dispatch_time_from_hub_from_lastmile = (moment.parseZone(new Date(params.data.dispatch_time_from_hub_from_lastmile)).utcOffset("-05:30")._d)
							const timeDiff = delivery_time_from_lastmile - dispatch_time_from_hub_from_lastmile; // Difference in milliseconds
							const hours = Math.floor(timeDiff / (1000 * 60 * 60)); // Convert milliseconds to hours
							const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
							var transit_time_last_mile = hours + "." + minutes
							query.transit_last_mile = transit_time_last_mile
							params.data.transit_last_mile = transit_time_last_mile
						}
						var gate_out_time = (moment.parseZone(new Date(selected_row_from_grid.gate_out_time)).utcOffset("-05:30")._d)
						const timeDiff2 = delivery_time_from_lastmile - gate_out_time; // Difference in milliseconds
						const hours2 = Math.floor(timeDiff2 / (1000 * 60 * 60)); // Convert milliseconds to hours
						const minutes2 = Math.floor((timeDiff2 % (1000 * 60 * 60)) / (1000 * 60));
						// var transit_overall_time = hours2 + "." + minutes2
						// params.data.transit_overall_time = transit_overall_time

						params.data.delivery_time_from_lastmile = params.newValue
						if (newValue != "") {
							try {

								console.log(newValue, query, "5896")

								redirectURL.post("/consignments/updatinglastmilelegsdataforadditionalvehicle", query).then((response) => {
									//   console.log(response.data,"Gdagadgadg")
									// if(response.data.transit_time_last_mile != "" && response.data.transit_time_last_mile != undefined && response.data.transit_time_last_mile != null){
									// 	params.data.transit_last_mile=response.data.transit_time_last_mile
									// }
									this.gridApi2.redrawRows();
								});
								// this.initComponent();
							} catch (error) {
								console.error(error);
							}
						}
						return true;


					} else {
						// console.log("check-2")
						if (![undefined, null, ""].includes(newValue) && ![undefined, null, ""].includes(reporting_time_at_consignees_from_lastmile)) {
							this.setState({
								show: true,
								basicTitle: 'Delivery Time at Consignee should be greater than or equal to Reporting time at Consignee',
								basicType: "danger",
								loadshow: 'show-n'
							});
						}
					}
				}

			},
			
			{
				headerName: "Transit time-Last Mile(Hrs.Min)",
				field: "transit_last_mile",
				width: 150,
				resizable: true,
				filter: true,
				// filterFramework: CustomTextFilter, // Use your custom filter component
				// filterParams: {
				// 	onFilterChanged: this.onFilterChanged,
				// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
				// },
			},
			{
				headerName: "Overall transit time(Hrs.Min)",
				field: "transit_overall_time",
				width: 150,
				resizable: true,
				filter: true,
				// filterFramework: CustomTextFilter, // Use your custom filter component
				// filterParams: {
				// 	onFilterChanged: this.onFilterChanged,
				// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
				// },

			},
			{
				headerName: "Remark",
				field: "remark_for_overall_transit_time_delay_legs",
				width: 150,
				resizable: true,
				editable: (params) => {
					if (params.node.rowIndex == 0) {
						return false
					} else {
					console.log(params.data.remark_for_overall_transit_time_delay_legs, "3518")
					var checkForRemarkEdit = false
					if (params.data.remark_for_overall_transit_time_delay_legs != undefined && params.data.remark_for_overall_transit_time_delay_legs != null && params.data.remark_for_overall_transit_time_delay_legs != '') {
						checkForRemarkEdit = true
					}else{
						checkForRemarkEdit = false
					}
					return ([undefined, "", null].includes(localStorage.getItem("hub_transporter_code"))
						&& localStorage.getItem("user_type") == "TRANSPORTER" ||
						((this.props.match.path == "/prtallconsignmentshub") ||
							(this.props.match.path == "/prtdeliveredconsignmentshub"))) ? false : ((this.state.transporterhub == "activet") ? false : checkForRemarkEdit)
				}

				},
				filter: true,
				valueGetter: function (params) {
					if (params.data.transit_overall_time > 48) {

						return params.data.remark_for_overall_transit_time_delay_legs
					} else {
						params.data.remark_for_overall_transit_time_delay_legs = ""
						return ""
					}
				},
				valueSetter: (params) => {
					params.data.remark_for_overall_transit_time_delay_legs = params.newValue
					// console.log(params,'params',params.data)
					const remark_for_overall_transit_time_delay_legs = params.newValue;
					const data = params.data;
					var params = {
						rowId: this.state.selected_row_from_grid._id,
						vehicle_no_from_lastmile: params.data.vehicle_no_from_lastmile,
						remark_for_overall_transit_time_delay_legs: remark_for_overall_transit_time_delay_legs,
						type: "Edit for Remark for Legs"
					}
					// console.log(params,'params for backend')
					if (remark_for_overall_transit_time_delay_legs != "") {
						try {
							redirectURL.post("/consignments/updatinglastmilelegsdataforadditionalvehicle", params);
							// this.initComponent();
						} catch (error) {
							console.error(error);
						}
					}
					return false;
				}
			}
		]
		// console.log(cell.data.pod_status_value_last_tmile,cell.data,"6695")
		this.setState({ legs_columndefs })

		if (cell.colDef.field == "show_last_mile_trucks") {
			if (cell.data.additional_last_mile_truck) {
				// console.log("show_last_mile_trucks")
				// console.log(cell.data.additional_last_mile_truck,"show_last_mile_trucks")
				// fetch legs from consignments collection and set cell data (write a common function so that it can be used after submitting new truck)

				var reqparams = {
					_id: cell.data._id
				}
				// console.log(reqparams,"reqparams")
				await redirectURL.post("/consignments/getlatestlastmiledata", reqparams).then((response) => {

					//    console.log(response.data,"6525")
					var additional_last_mile_truck = (response.data.data)
					var legs_data = []
					if (additional_last_mile_truck[0].additional_last_mile_truck != undefined && additional_last_mile_truck[0].additional_last_mile_truck != "" && additional_last_mile_truck[0].additional_last_mile_truck != null) {
						if (additional_last_mile_truck[0].additional_last_mile_truck.length > 0) {
							legs_data = additional_last_mile_truck[0].additional_last_mile_truck
						} else {
							legs_data = []
						}
					} else {
						legs_data = []
					}
					var extra_legs_data = []
					response.data.data.map((e) => {
						extra_legs_data.push({
							"consignment_code": e.consignment_code,
							"last_fleet_availability": e.last_fleet_availability,
							"vehicle_type_from_lastmile": e.vehicle_type_from_lastmile,
							"vehicle_no_from_lastmile": e.vehicle_no_from_lastmile,
							"dispatch_time_from_hub_from_lastmile": e.dispatch_time_from_hub_from_lastmile,
							"reporting_time_at_consignees_from_lastmile": e.reporting_time_at_consignees_from_lastmile,
							"delivery_time_from_lastmile": e.delivery_time_from_lastmile,
							"transit_last_mile": e.transit_last_mile,
							"transit_overall_time": e.transit_overall_time,
							"comment_lastmile": e.pod_comment_lastmile,
							"pod_status_value_last_tmile": e.pod_status_value_last_tmile,
							"pod_file_lastmile": e.file_pod_last,
							"primary_truck": 1,
							"remark_for_overall_transit_time_delay_legs" : e.remark_for_overall_transit_time_delay_main_grid
						})
					})
					var final_legs_data = extra_legs_data.concat(legs_data)
					//    console.log(final_legs_data,"6662")
					this.setState({
						openLastMileModal: true,
						columnwithDefsForLastMile: legs_columndefs,
						rowDataForLastMile: final_legs_data,
						bulksliderforcommentlastforadditionaltrucks: "",
						main_cmt: cell.data.volume,
						legs_cmt_data: cell.data.additional_last_mile_truck,
						collecting_total_main_grid_data_onclick : cell.data

					});
				}).catch(function (error) {
					console.log(error);
				})
			}



		}
		if (cell.colDef.field == "additional_last_mile_truck") {
			if (cell.data.dispatch_time_from_hub_from_lastmile) {
				// console.log("additional_last_mile_truck")
				$("#remark_for_delay_legs").val("")
				this.setState({
					idRow: cell.data._id,
					gate_out_time_for_compare : cell.data.gate_out_time,
					main_cmt: cell.data.volume,
					totalDataOnAddButtonClick : cell.data,
					legs_cmt_data: cell.data.additional_last_mile_truck,
					additional_last_mile_truck_slider: "slider-translate",
					overly: 'show-m',
					remark_for_delay_value_state:false,
					remarkCheck : false
				})
			}

		}
		if (cell.colDef.field == "comment") {
			// console.log(cell.data._id,'id')
			if (cell.data.pod_1st_mile == 1) {
				var form = document.getElementById("form-pod-first-mile");
				if (form) {
					form.reset();
				}
				this.setState({
					idRow: cell.data._id,
					bulksliderforcomment: "slider-translate",
					overly: 'show-m'
				})
			}
		}
		if (cell.colDef.field == "file_pod_frist") {
			if (cell.data.pod_1st_mile !== 0 && cell.data.pod_1st_mile !== "0" && cell.data.pod_1st_mile !== "") {

				var fileName = cell.data.pod_file
				var instantfileName = this.state.instant_pod_first_mile
				// console.log(fileName,"496",instantfileName)
				if (fileName != "") {
					var reqparams = {
						uploadOtherFileName: fileName,
						instantType: 'POD First Download'
					}
					redirectURL.post("/consignments/downloadpodfile", reqparams, {
						responseType: 'blob'
					}).then((response) => {
						// console.log("download response hub",response)
						download(response.data, fileName[0].name)

					}).catch(function (error) {
						console.log(error);
					})

				} else if (fileName == "") {
					if (instantfileName != '') {
						var reqparams = {
							uploadOtherFileName: instantfileName[0].name,
							instantType: 'Instant POD First Download'
						}

						// console.log('in side instant',reqparams.uploadOtherFileName)
						redirectURL.post("/consignments/downloadpodfile", reqparams, {
							responseType: 'blob'
						}).then((response) => {
							// console.log("download response hub",response)
							download(response.data, instantfileName[0].name)

						}).catch(function (error) {
							console.log(error);
						})
					}
				}

			}


		}
		if (cell.colDef.field == "file_pod_last") {
			if (cell.data.pod_status_value_last_tmile !== 0) {
				var fileName = cell.data.file_pod_last
				var instantfileName = this.state.instant_pod_last_mile
				// console.log(fileName,"496",instantfileName)
				if (fileName != "") {
					var reqparams = {
						uploadOtherFileName: fileName,
						instantType: 'POD Last Download'
					}
					redirectURL.post("/consignments/downloadfilepodlast", reqparams, {
						responseType: 'blob'
					}).then((response) => {
						// console.log("download response ",response)
						download(response.data, fileName[0].name)

					}).catch(function (error) {
						console.log(error);
					})
				} else if (fileName == "") {
					if (instantfileName != '') {
						var reqparams = {
							uploadOtherFileName: instantfileName[0].name,
							instantType: 'Instant POD Last Download'
						}

						// console.log('in side instant',reqparams.uploadOtherFileName)
						redirectURL.post("/consignments/downloadfilepodlast", reqparams, {
							responseType: 'blob'
						}).then((response) => {
							// console.log("download response hub",response)
							download(response.data, instantfileName[0].name)

						}).catch(function (error) {
							console.log(error);
						})
					}
				}
			} else {
				// console.log("not coming")
			}

		}

		if (cell.colDef.field == "delete") {
			var rowId = cell.data._id;
			var consigner_code = cell.data.consigner_code;
			var qry = {
				rowId: rowId,
				rowData: cell.data,
				counterTypelabelName: '',
				user_id: localStorage.getItem('userid'),
				user_name: localStorage.getItem('username'),
				user_email: localStorage.getItem('email'),
			}
			if (consigner_code == "SMG") {
				if (window.confirm("Are You Sure, Consignment and related details will be deleted")) {
					// console.log(rowId,"rowId")
					redirectURL.post("/consignments/deleteReturnPallets", qry).then((response) => {
						// console.log("responseData:",response.data);
						if (response.data.message == "Success") {
							this.setState({
								basicTitle: "Deleted Successfully.",
								basicType: "success",
								show: true
							})
							window.location.reload();
						}
						else {
							this.setState({
								basicTitle: "Failed to Delete.",
								basicType: "warning",
								show: true
							})
						}
					}).catch((e) => {
						console.log(e);
					})
				}
			}
		}

	}

	onClickTab(clickedTab) {

		if (clickedTab == 'all') {
			window.location.href = "prtallconsignmentshub"
			var all = "activet";
			var intransit = "";
			var delivered = "";
			var hubOwner = "activet";
		} else if (clickedTab == "intransit") {
			window.location.href = "/prtconsignmentshub"
			var all = "";
			var intransit = "activet";
			var delivered = "";
			var hubOwner = "activet";
		} else if (clickedTab == "delivered") {
			window.location.href = "prtdeliveredconsignmentshub"
			var all = "";
			var intransit = "";
			var delivered = "activet";
			var hubOwner = "activet";
		}
		this.setState({
			all: all,
			intransit: intransit,
			delivered: delivered,
			hubOwner: hubOwner
		})
	}

	onFilterChanged = (filterText, column_name) => {
		// Update the filter text in the state
		this.setState({ filterText });
		filterText = filterText.map(e => {
			if (e != undefined) {
				return e.toString().toLowerCase()
			} else {
				return e
			}
		});

		let filteredData = this.state.rowData.filter((row) => {
			// Check if any column value includes the filter text
			//   for(const eachkey in row){
			if (![null, undefined].includes(row[column_name])) {
				if (column_name == "truck_no") {
					row.truck_no = row.original_truck_no;
				}
				if (filterText.includes(row[column_name].toString().toLowerCase())) {
					return row
				}
				// }
			}
			else if (filterText.includes(undefined) && row[column_name] == undefined) {
				return row
			}
			else if (filterText.includes(null) && row[column_name] == null) {
				return row
			}
		});
		if (filterText == "") {
			filteredData = this.state.allRowData;
		}
		filteredData = this.sortAndArrangeRowSpan(filteredData)
		// Update the grid with the filtered data
		this.gridApi.setRowData(filteredData);
		// // Get the current column state
		// const columnState = this.gridColumnApi.getColumnState();
		// // Find the index of the column in the column state array
		// const columnIndex = columnState.findIndex((col) => col.colId === column_name);
		// if (columnIndex !== -1) {
		//   // Update the filterActive property in the column state
		//   columnState[columnIndex].filterActive = true;

		//   // Set the updated column state
		//   this.gridColumnApi.setColumnState(columnState);
		//   this.gridApi.redrawRows();
		// }

	};

	onClickTabShow(clickedTab) {
		// console.log(clickedTab,'clickedTab==')
		var transporterCodesString = localStorage.getItem("hub_transporter_code");
		var transporterCodesArray;
		if(transporterCodesString){
			 transporterCodesArray = transporterCodesString.split(',');
		}		
		if (clickedTab == 'hubOwner') {
			this.setState({
				movementtype: [],
				flCity: []
			})
			var hubOwner = "activet";
			var transporterhub = "";
			var istransporter = false
			this.gethubcitiesandlocationsdata({
				"hub_transporter_code": transporterCodesArray,
				"tab_type": "last_mile"
			})
		} else {
			this.setState({
				movementtype: [],
				flCity: []
			})
			var hubOwner = "";
			var transporterhub = "activet";
			var istransporter = true
			this.gethubcitiesandlocationsdata({
				"hub_transporter_code": transporterCodesArray,
				"tab_type": "first_mile"
			})

		}
		this.setState({
			hubOwner: hubOwner,
			istransporter: istransporter,
			transporterhub: transporterhub
		}, this.componentDidMount)


	}


	getResetConsignments = () => {
		// console.log("clicked")
		const { allRowData } = this.state
		// console.log("longHaulRowData",longHaulRowData)
		this.setState({
			rowData: allRowData,
			loadshow: "show-n",
			overly: "show-n"
		})
	}

	onSetCritical(params, status) {
		// console.log("onSetCritical ", params);
		// console.log("onSetCritical value ", status);
		redirectURL.post('/consignments/setCriticalStatus', {
			params: params,
			is_critical: status,
			token: window.atob(localStorage.getItem('_t')),
			userId: localStorage.getItem("userid"),
			returnConsignment: this.state.tnpReturnConsignmentsVisibilty,

		})
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				// console.log(response.data);
			});

	}

	getNewFilterConsignmnets = (event) => {
		this.setState({
			loadshow: "show-m",
			overly: "show-m"
		})
		event.preventDefault();
		var selectedCity = $("#selectedCity").text();
		var selectedState = $("#selectedState").text();
		var selectedCluster = $("#selectedCluster").text();
		var selectedTransporter = $("#selectedTransporter").text();
		var beforeNewFilterRowData = this.state.rowData;
		var filterRowData = this.state.rowData;
		// console.log(selectedCity,selectedState,selectedCluster,selectedTransporter,"Bhanu Teja")
		//console.log(filterRowData,"0")
		if (selectedCity != undefined && selectedCity != '' && selectedCity != "Select...") {
			filterRowData = filterRowData.filter(e => e.consignee_city == selectedCity);
			// console.log(filterRowData,"1")
		}
		if (selectedState != undefined && selectedState != '' && selectedState != "Select...") {
			filterRowData = filterRowData.filter(e => e.consignee_state == selectedState);
		}
		if (selectedCluster != undefined && selectedCluster != '' && selectedCluster != "Select...") {
			filterRowData = filterRowData.filter(e => e.cluster == selectedCluster);
		}
		if (selectedTransporter != undefined && selectedTransporter != '' && selectedTransporter != "Select...") {
			filterRowData = filterRowData.filter(e => e.transporter_name == selectedTransporter);
		}
		var conCodes = [];
		filterRowData.map(function (f) {
			conCodes.push(f.consignment_code);
		});
		redirectURL.post('/consignments/getConsignmentsNewFilterCounters', {
			consignmentCodes: conCodes,
			filterType: 1,
		}).then((response) => {
			//var records = JSON.parse(JSON.stringify(response)).data;
			// console.log(response.data.counters,"filter counters");
			var pagecounters = response.data.counters
			// console.log(filterRowData,"final")
			var beforeNewFiltercountersjson = this.state.countersjson;
			this.setState({
				rowData: filterRowData,
				counterTypelabelName: '',
				allRowData: filterRowData,
				beforeNewFilterRowData: beforeNewFilterRowData,
				beforeNewFiltercountersjson: beforeNewFiltercountersjson,
				countersjson: pagecounters,
				loadshow: "show-n",
				overly: "show-n"
			})
			this.gridApi.refreshCells();
		});
	}

	ClearNewFilter = () => {
		this.setState({
			loadshow: "show-m",
			overly: "show-m"
		})
		this.setState({
			flCity: "",
			flState: "",
			flCluster: "",
			flTransporter: ""
		});
		this.setState({
			rowData: this.state.beforeNewFilterRowData,
			counterTypelabelName: '',
			allRowData: this.state.beforeNewFilterRowData,
			countersjson: this.state.beforeNewFiltercountersjson,
			loadshow: "show-n",
			overly: "show-n"
		});
		this.gridApi.refreshCells();
	}

	newFilter = (filterType, selOption) => {
		// console.log(filterType,selOption)
		this.setState({ [filterType]: selOption });
	}
	reloadPageData = () => {
		window.location.reload();
	}
	handleChange = event => {
		const result = event.target.value.replace(/[^a-z0-9]/gi, '');
		$("#inputTruckConsignment").val(result);
	};
	handleChange1 = event => {
		const result = event.target.value.replace(/[^a-z0-9-]/gi, '');
		$("#inputPartNumber").val(result);
	};
	handleChange2 = event => {
		const result = event.target.value.replace(/[^a-z0-9-]/gi, '');
		$("#inputTruckNumber").val(result);
	};

	changeFileHandlerPOD = async (e) => {
		// console.log(e.target.files,e.target.files[0],'change')
		var files = Array.from(e.target.files);
		this.setState({
			files: files
		})
	}
	changeFileHandlerPODlast = async (e) => {
		// console.log(e.target.files,e.target.files[0],'change')
		var files = Array.from(e.target.files);
		this.setState({
			files_last: files
		})
	}


	uploadPODfristmile = async (event) => {
		event.preventDefault();
		// console.log(this.state.files,'hellllo',$("#commentValue").val(),this.state.idRow)
		var commentValue = $("#commentValue").val()
		if (this.state.files != '') {
			// console.log("this.state.file ",this.state.file)
			var csvdd = this.state.files;


			// console.log("event ",event.target.uploadFile.value);
			var formData = new FormData();
			// formData.append('uploadFile',csvdd);
			formData.append('rowId', this.state.idRow);
			formData.append('comment', commentValue);
			formData.append('pod_1st_mile', this.state.samplepod1stmile);

			// formData.append("userId", localStorage.getItem('userid'));
			// formData.append("csvcontent",this.state.csvcontent);
			for (var x = 0; x < this.state.files.length; x++) {
				formData.append('files', this.state.files[x])

			}



			var rparams = {
				uploadFile: this.state.files,
				userId: localStorage.getItem('userid'),
				rowId: (this.state.idRow)
				// csvcontent:this.state.csvcontent
			}
			//console.log('file',formData);
			//console.log('rparams',rparams);
			this.setState({
				instant_pod_first_mile: csvdd,
				loadshow: 'show-m'
			});
			await redirectURL.post("/consignments/uploadpodcomment", formData)
				.then(
					(response) => {
						//console.log(response);
						// console.log(response.data);
						//document.getElementById("upform").reset();
						if (response.data.status == 'failure') {
							if (response.data.message.empty != "")
								this.setState({
									//csverrmessage:response.data.status,
									show: true,
									basicType: 'danger',
									basicTitle: "Failed",
									bulksliderforcomment: '',
									overly: 'show-n',
									showDiv: 'show-n',
									loadshow: 'show-n',
									uploadFile: '',

									files: []
								});

						}
						else {
							//console.log("here")
							this.setState({
								show: true,
								basicType: 'success',
								basicTitle: "Successfully Uploaded.",
								bulksliderforcomment: '',
								overly: 'show-n',
								showDiv: 'show-n',
								uploadFile: '',
								loadshow: 'show-n',
								files: [],
								commentValue: ""
							});
							$("#uploadFile").val('')
							$("#commentValue").val('')

							var form = document.getElementById("form-pod-first-mile");
							if (form) {
								form.reset();
							}
							// console.log(this.state.rowIndexcount,this.gridApi,'this.state.rowIndex')
							const clickedRowNode = this.gridApi.getRowNode(this.state.rowIndexcount);
							// console.log(clickedRowNode,'clickedRowNode pod check',this.state.rowIndexcount)
							if (clickedRowNode) {
							let updatedRowData = []
							this.gridApi.forEachNode(node => {
								// console.log(node, "node")
								if (node.rowIndex == this.state.rowIndexcount) {
									// console.log(node.rowIndex ,"== pod first mile",this.state.rowIndexcount)
									node.data = {
										...node.data, "pod_comment": commentValue,
										"pod_file": this.state.files, pod_1st_mile: this.state.samplepod1stmile
									};
								}
								updatedRowData.push(node.data)
							});
							
							// Update the rowData in the grid
							this.gridApi.setRowData(updatedRowData);
							this.gridApi.ensureIndexVisible(this.state.rowIndexcount, 'middle');
							//   Do NOT setState or set the state directly on the grid or clickedRowNode
							}

							// this.componentDidMount()

						}
						this.setState({
							files: []
						})

					}
				)
				.catch(function (error) {
					console.log(error);
				});
		}

	}
	onChangelastmile = (event) => {
		// console.log("hii",event,event.target,event.target.value,event.value)
		this.setState({
			commentValuelastmile: event.target.value
		})

	}
	uploadPODlastmile(event) {
		event.preventDefault();
		// console.log(this.state.files_last,'hellllo',$("#commentValue").val(),this.state.idRowlast)
		var commentValue = $("#commentValuelastmile").val()
		if (this.state.files_last != '') {
			// console.log("this.state.file ",this.state.file)
			var csvdd = this.state.files_last;


			// console.log("event ",event.target.uploadFile.value);
			var formData = new FormData();
			// formData.append('uploadFile',csvdd);
			formData.append('rowId', this.state.idRowlast);
			formData.append('comment_lastmile', commentValue);
			formData.append('pod_status_value_last_tmile', this.state.samplelastmilepod);
			// formData.append("userId", localStorage.getItem('userid'));
			// formData.append("csvcontent",this.state.csvcontent);
			for (var x = 0; x < this.state.files_last.length; x++) {
				formData.append('files_last', this.state.files_last[x])
			}



			var rparams = {
				uploadFile: this.state.files_last,
				userId: localStorage.getItem('userid'),
				rowId: (this.state.idRowlast)
				// csvcontent:this.state.csvcontent
			}
			//console.log('file',formData);
			//console.log('rparams',rparams);
			this.setState({
				instant_pod_last_mile: csvdd,
				loadshow: 'show-m'
			});
			redirectURL.post("/consignments/uploadpodcommentlastPOD", formData)
				.then(
					(response) => {
						//console.log(response);
						// console.log(response.data);
						//document.getElementById("upform").reset();
						if (response.data.status == 'failure') {
							if (response.data.message.empty != "")
								this.setState({
									//csverrmessage:response.data.status,
									show: true,
									basicType: 'danger',
									basicTitle: "Failed",
									bulksliderforcommentlast: '',
									bulksliderforcommentlastforadditionaltrucks: '',
									overly: 'show-n',
									showDiv: 'show-n',
									loadshow: 'show-n',
									uploadFile: '',
									files_last: '',
									files_last: [],

								});

						}
						else {
							//console.log("here")
							this.setState({
								show: true,
								basicType: 'success',
								basicTitle: "Successfully Uploaded.",
								bulksliderforcommentlast: '',
								bulksliderforcommentlastforadditionaltrucks: '',
								overly: 'show-n',
								showDiv: 'show-n',
								uploadFile: '',
								loadshow: 'show-n',
								files_last: '',
								files_last: [],
								commentValuelastmile: ""
							});
							$("#files_last").val('')
							$("#uploadFiletype").val('')
							$("#commentValuelastmile").val('')

							document.getElementById("uploadFiletype").value = ""
							var form = document.getElementById("form-pod-last-mile");
							if (form) {
								form.reset();
							}

							const clickedRowNode = this.gridApi.getRowNode(this.state.rowIndexcount);
							// console.log(clickedRowNode,'clickedRowNode 4306',this.state.rowIndexcount)
							if (clickedRowNode) {
							let updatedRowData = []
							this.gridApi.forEachNode(node => {
								// console.log(node, "node", this.state.rowIndexcount)
								if (node.rowIndex == this.state.rowIndexcount) {
									node.data = {
										...node.data, "pod_comment_lastmile": commentValue,
										"file_pod_last": this.state.files_last, "pod_status_value_last_tmile": this.state.samplelastmilepod
									};
								}
								updatedRowData.push(node.data)
							});
							this.gridApi.setRowData(updatedRowData);
							this.gridApi.ensureIndexVisible(this.state.rowIndexcount, 'middle');
							}
							// this.componentDidMount()
							
						}
						this.setState({
							files_last: []
						})

					}
				)
				.catch(function (error) {
					console.log(error);
				});
		}

	}

	onEditAdditionalLastMileTruck = async (event) => {
		event.preventDefault();
		var { additional_last_mile_truck, selected_row_from_grid } = this.state
		// console.log(additional_last_mile_truck,selected_row_from_grid,"6475")
		var formData = new FormData();


		formData.append("_id", selected_row_from_grid._id)
		formData.append("consignment_code", selected_row_from_grid.consignment_code)
		formData.append("last_fleet_availability", additional_last_mile_truck.last_fleet_availability)
		formData.append("vehicle_type_from_lastmile", additional_last_mile_truck.vehicle_type_from_lastmile)
		formData.append("vehicle_no_from_lastmile", additional_last_mile_truck.vehicle_no_from_lastmile)
		formData.append("dispatch_time_from_hub_from_lastmile", (new Date(additional_last_mile_truck.dispatch_time_from_hub_from_lastmile)))
		formData.append("reporting_time_at_consignees_from_lastmile", (new Date(additional_last_mile_truck.reporting_time_at_consignees_from_lastmile)))
		formData.append("delivery_time_from_lastmile", (new Date(additional_last_mile_truck.delivery_time_from_lastmile)))

		// console.log(formData,"formData")



		if (additional_last_mile_truck.reporting_time_at_consignees_from_lastmile && additional_last_mile_truck.dispatch_time_from_hub_from_lastmile) {

			const reporting_time_at_consignees_from_lastmile = new Date(additional_last_mile_truck.reporting_time_at_consignees_from_lastmile);
			const dispatch_time_from_hub_from_lastmile = new Date(additional_last_mile_truck.dispatch_time_from_hub_from_lastmile);

			// console.log(reporting_time_at_consignees_from_lastmile,dispatch_time_from_hub_from_lastmile,"")
			var transit_last_mile = (reporting_time_at_consignees_from_lastmile - dispatch_time_from_hub_from_lastmile)  // Convert milliseconds to hours
			var hours = Math.floor(transit_last_mile / (1000 * 60 * 60));
			var minutes = Math.floor((transit_last_mile % (1000 * 60 * 60)) / (1000 * 60));
			transit_last_mile = hours + "." + minutes

			//   console.log(transit_last_mile,"transit_last_mile")
			formData.append("transit_last_mile", transit_last_mile)

		} else if (additional_last_mile_truck.delivery_time_from_lastmile && additional_last_mile_truck.dispatch_time_from_hub_from_lastmile) {

			const delivery_time_from_lastmile = new Date(additional_last_mile_truck.delivery_time_from_lastmile);
			const dispatch_time_from_hub_from_lastmile = new Date(additional_last_mile_truck.dispatch_time_from_hub_from_lastmile);

			// console.log(delivery_time_from_lastmile,dispatch_time_from_hub_from_lastmile,"")
			var transit_last_mile = (delivery_time_from_lastmile - dispatch_time_from_hub_from_lastmile)  // Convert milliseconds to hours
			var hours = Math.floor(transit_last_mile / (1000 * 60 * 60));
			var minutes = Math.floor((transit_last_mile % (1000 * 60 * 60)) / (1000 * 60));
			transit_last_mile = hours + "." + minutes

			//   console.log(transit_last_mile,"transit_last_mile")
			formData.append("transit_last_mile", transit_last_mile)

		}

		if (additional_last_mile_truck.reporting_time_at_consignees_from_lastmile && selected_row_from_grid.gate_out_time) {

			const gate_out_time = new Date(getHyphenYYYYMMDDHHMMSS(selected_row_from_grid.gate_out_time));
			const reporting_time_at_consignees_from_lastmile = new Date(additional_last_mile_truck.reporting_time_at_consignees_from_lastmile);

			// console.log(reporting_time_at_consignees_from_lastmile,gate_out_time,"6726")
			var transit_overall_time = (reporting_time_at_consignees_from_lastmile - gate_out_time); // Convert milliseconds to hours

			var hours = Math.floor(transit_overall_time / (1000 * 60 * 60));
			var minutes = Math.floor((transit_overall_time % (1000 * 60 * 60)) / (1000 * 60));
			transit_overall_time = hours + "." + minutes

			//   console.log(transit_overall_time,gate_out_time,reporting_time_at_consignees_from_lastmile,"transit_overall_time")
			formData.append("transit_overall_time", transit_overall_time)

		} else if (additional_last_mile_truck.delivery_time_from_lastmile && selected_row_from_grid.gate_out_time) {

			const gate_out_time = new Date(getHyphenYYYYMMDDHHMMSS(selected_row_from_grid.gate_out_time));
			const delivery_time_from_lastmile = new Date(additional_last_mile_truck.delivery_time_from_lastmile);

			// console.log(delivery_time_from_lastmile,gate_out_time,"")
			var transit_overall_time = (delivery_time_from_lastmile - gate_out_time); // Convert milliseconds to hours
			var hours = Math.floor(transit_overall_time / (1000 * 60 * 60));
			var minutes = Math.floor((transit_overall_time % (1000 * 60 * 60)) / (1000 * 60));
			transit_overall_time = hours + "." + minutes

			// console.log(transit_overall_time,"transit_overall_time")
			formData.append("transit_overall_time", transit_overall_time)

		}



		if (additional_last_mile_truck.pod_status_value_last_tmile == "Not Ok") {

			var commentValue = $("#commentValuelastmile").val()
			if (this.state.files_last != '') {
				var csvdd = this.state.files_last;

				// params["comment_lastmile"]=commentValue

				// formData.append('uploadFile',csvdd);
				// formData.append('rowId',this.state.idRowlast);
				formData.append('comment_lastmile', commentValue);
				formData.append('pod_status_value_last_tmile', this.state.samplelastmilepod);
				// formData.append("userId", localStorage.getItem('userid'));
				// formData.append("csvcontent",this.state.csvcontent);
				for (var x = 0; x < this.state.files_last.length; x++) {
					formData.append('files_last', this.state.files_last[x])
				}

				// console.log(formData,"6507")

				var rparams = {
					uploadFile: this.state.files_last,
					userId: localStorage.getItem('userid'),
					rowId: (this.state.idRowlast)
					// csvcontent:this.state.csvcontent
				}

			}

		}

		await redirectURL.post("/consignments/editadditionallastmiletrucks", formData)
			.then(
				(response) => {
					//console.log(response);
					// console.log(response.data);
					//document.getElementById("upform").reset();
					if (response.data.status == 'failure') {
						if (response.data.message.empty != "")
							this.setState({
								//csverrmessage:response.data.status,
								show: true,
								basicType: 'danger',
								basicTitle: "Failed",
								bulksliderforcommentlast: '',
								overly: 'show-n',
								showDiv: 'show-n',
								loadshow: 'show-n',
								uploadFile: '',
								files_last: '',
								files_last: [],

							});

					}
					else {
						//console.log("here")
						this.setState({
							show: true,
							basicType: 'success',
							basicTitle: "Successfully Uploaded.",
							bulksliderforcommentlast: '',
							overly: 'show-n',
							showDiv: 'show-n',
							uploadFile: '',
							loadshow: 'show-n',
							files_last: '',
							files_last: [],
						});
						$("#files_last").val('')
						$("#uploadFiletype").val('')
						$("#commentValuelastmile").val('')

					}
					this.setState({
						files_last: []
					})

				}
			)
			.catch(function (error) {
				console.log(error);
			});

		// console.log("onSubmitAdditionalLastMileTruck",this.state.additional_last_mile_truck)
	}

	onChangeValue = (event) => {
		// console.log("hii",event,event.target,event.target.value,event.value)
		this.setState({
			commentValue: event.target.value
		})

	}

	handleChangeselectvehicletype = (event) => {
		// console.log(event, 'selected options fleet',event.target.value); // Log the selected options
		this.setState(
			{ vehicle_type_from_lastmile: event.target.value }
			//   () => console.log(`Selected Options:`, this.state.vehicle_type_from_lastmile)
		);
	}

	getColumnUniqueValues = (column) => {
		return [...new Set(this.state.rowData.map((row) => row[column]))];
	};

	lastmilemastervehicle(event) {
		event.preventDefault();
		this.setState({
			loadshow: "show-m",
			overly: "show-m",
		});

		const inputValue = $("#lastmilemastervehicle").val();

		// Regular expression for validation
		const regex = /^[A-Za-z0-9]{5,15}$/;

		if (!regex.test(inputValue)) {
			// Input does not match the required format
			this.setState({
				loadshow: "show-n",
				overly: "show-n",
				show: true,
				basicTitle: 'Invalid Vehicle Number. It should be 5 to 15 characters with uppercase letters and numbers only.',
				basicType: "danger",
				loadshow: 'show-n'
			})
			return
			// this.componentDidMount()
		}

		// console.log(this.state.files, 'hello',this.state.vehicle_type_from_lastmile, this.state.last_fleet_availabilityID);

		var lastmilemastervehicle = inputValue.toUpperCase();
		if (this.state.vehicle_type_from_lastmile != '' && this.state.vehicle_type_from_lastmile != undefined) {

			var vehicle_type_value = this.state.vehicle_type_from_lastmile
			var query = {
				rowId: this.state.last_fleet_availabilityID,
				lastmilemastervehicle: lastmilemastervehicle,
				vehicle_type_from_lastmile: this.state.vehicle_type_from_lastmile,
				type: "lastmilemastervehicle"
			}


			redirectURL.post("/consignments/updatinglastmilelegsdata", query)
				.then((response) => {
					// Handle response

					if (response.data.status === 'failure') {
						// Handle failure case
						this.setState({
							loadshow: "show-n",
							overly: "show-n",
						});
					} else {
						// Handle success case

						$("#lastmilemastervehicle").val("")
						this.setState({
							vehicle_type_from_lastmile: '',
							last_fleet_availabilityBulk: '',
							loadshow: "show-n",
							overly: "show-n",
						})
						const clickedRowNode = this.gridApi.getRowNode(this.state.rowIndexcount);
						// console.log(clickedRowNode,'clickedRowNode 4591',this.state.rowIndexcount)
						if (clickedRowNode) {
							let updatedRowData = []
							this.gridApi.forEachNode(node => {
								// console.log(node, "node")
								if (node.rowIndex == this.state.rowIndexcount) {
									// console.log(node.rowIndex ,"==", this.state.rowIndexcount)
									node.data = {
										...node.data,
										"vehicle_no_from_lastmile": lastmilemastervehicle,
										"vehicle_type_from_lastmile": vehicle_type_value,
										last_fleet_availability: this.state.samplefleetvalue
									};
								}
								updatedRowData.push(node.data)
							});
							// const updatedRowData = this.gridApi.getRowData().map((row, index) => {
							// if (index === this.state.rowIndexcount) {
							// 	return { ...row, name: 'Updated Name' };
							// }
							// return row;
							// });

							// Update the rowData in the grid
							this.gridApi.setRowData(updatedRowData);
							this.gridApi.ensureIndexVisible(this.state.rowIndexcount, 'middle');
							//   Do NOT setState or set the state directly on the grid or clickedRowNode
						}
						// this.componentDidMount();
						inputValue = ""

					}
				})
				.catch(function (error) {
					console.log(error);
				});
		} else {
			this.setState({
				loadshow: "show-n",
				overly: "show-n",
				show: true,
				basicTitle: 'Select the Vehicle Type',
				basicType: "danger",
				loadshow: 'show-n'
			})
			this.componentDidMount()
		}
	}


	onSubmitAdditionalLastMileTruck = async (event) => {
		event.preventDefault();
		var { additional_last_mile_truck, selected_row_from_grid } = this.state
		// console.log(additional_last_mile_truck,selected_row_from_grid,"6475")
		var last_cmt = $("#last_cmt").val()
		var remark_for_delay_legs = $("#remark_for_delay_legs").val()
		if (new Date(additional_last_mile_truck.dispatch_time_from_hub_from_lastmile) == "Invalid Date") {
			// this.setState({})
			this.setState({
				show: true,
				basicTitle: 'Select Valid Dispatch Date',
				basicType: "danger",
				loadshow: 'show-n'
			});
		} else {

			var formData = new FormData();

			if (additional_last_mile_truck.pod_status_value_last_tmile == "Ok") {
				var pod_status_value_last_tmile = 1
			} else {
				var pod_status_value_last_tmile = 0
			}


			formData.append("_id", selected_row_from_grid._id)
			formData.append("consignment_code", selected_row_from_grid.consignment_code)
			formData.append("last_fleet_availability", additional_last_mile_truck.last_fleet_availability)
			formData.append("vehicle_type_from_lastmile", additional_last_mile_truck.vehicle_type_from_lastmile)
			formData.append("vehicle_no_from_lastmile", additional_last_mile_truck.vehicle_no_from_lastmile)
			formData.append("dispatch_time_from_hub_from_lastmile", (new Date(additional_last_mile_truck.dispatch_time_from_hub_from_lastmile)))
			formData.append("volume", last_cmt)
			formData.append("reporting_time_at_consignees_from_lastmile", (new Date(additional_last_mile_truck.reporting_time_at_consignees_from_lastmile)))
			formData.append("delivery_time_from_lastmile", (new Date(additional_last_mile_truck.delivery_time_from_lastmile)))
			formData.append("remark_for_overall_transit_time_delay_legs", remark_for_delay_legs)

			// console.log(formData,"formData")



			if (additional_last_mile_truck.reporting_time_at_consignees_from_lastmile && additional_last_mile_truck.dispatch_time_from_hub_from_lastmile) {

				const reporting_time_at_consignees_from_lastmile = new Date(additional_last_mile_truck.reporting_time_at_consignees_from_lastmile);
				const dispatch_time_from_hub_from_lastmile = new Date(additional_last_mile_truck.dispatch_time_from_hub_from_lastmile);

				// console.log(reporting_time_at_consignees_from_lastmile,dispatch_time_from_hub_from_lastmile,"")
				var transit_last_mile = (reporting_time_at_consignees_from_lastmile - dispatch_time_from_hub_from_lastmile)  // Convert milliseconds to hours
				var hours = Math.floor(transit_last_mile / (1000 * 60 * 60));
				var minutes = Math.floor((transit_last_mile % (1000 * 60 * 60)) / (1000 * 60));
				transit_last_mile = hours + "." + minutes

				//   console.log(transit_last_mile,"transit_last_mile")
				formData.append("transit_last_mile", transit_last_mile)

			} else if (additional_last_mile_truck.delivery_time_from_lastmile && additional_last_mile_truck.dispatch_time_from_hub_from_lastmile) {

				const delivery_time_from_lastmile = new Date(additional_last_mile_truck.delivery_time_from_lastmile);
				const dispatch_time_from_hub_from_lastmile = new Date(additional_last_mile_truck.dispatch_time_from_hub_from_lastmile);

				// console.log(delivery_time_from_lastmile,dispatch_time_from_hub_from_lastmile,"")
				var transit_last_mile = (delivery_time_from_lastmile - dispatch_time_from_hub_from_lastmile)  // Convert milliseconds to hours
				var hours = Math.floor(transit_last_mile / (1000 * 60 * 60));
				var minutes = Math.floor((transit_last_mile % (1000 * 60 * 60)) / (1000 * 60));
				transit_last_mile = hours + "." + minutes

				//   console.log(transit_last_mile,"transit_last_mile")
				formData.append("transit_last_mile", transit_last_mile)

			}

			if (additional_last_mile_truck.reporting_time_at_consignees_from_lastmile && selected_row_from_grid.gate_out_time) {

				const gate_out_time = new Date(getHyphenYYYYMMDDHHMMSS(selected_row_from_grid.gate_out_time));
				const reporting_time_at_consignees_from_lastmile = new Date(additional_last_mile_truck.reporting_time_at_consignees_from_lastmile);

				// console.log(reporting_time_at_consignees_from_lastmile,gate_out_time,"6726")
				var transit_overall_time = (reporting_time_at_consignees_from_lastmile - gate_out_time); // Convert milliseconds to hours

				var hours = Math.floor(transit_overall_time / (1000 * 60 * 60));
				var minutes = Math.floor((transit_overall_time % (1000 * 60 * 60)) / (1000 * 60));
				transit_overall_time = hours + "." + minutes

				//   console.log(transit_overall_time,gate_out_time,reporting_time_at_consignees_from_lastmile,"transit_overall_time")
				formData.append("transit_overall_time", transit_overall_time)

			} else if (additional_last_mile_truck.delivery_time_from_lastmile && selected_row_from_grid.gate_out_time) {

				const gate_out_time = new Date(getHyphenYYYYMMDDHHMMSS(selected_row_from_grid.gate_out_time));
				const delivery_time_from_lastmile = new Date(additional_last_mile_truck.delivery_time_from_lastmile);

				// console.log(delivery_time_from_lastmile,gate_out_time,"")
				var transit_overall_time = (delivery_time_from_lastmile - gate_out_time); // Convert milliseconds to hours
				var hours = Math.floor(transit_overall_time / (1000 * 60 * 60));
				var minutes = Math.floor((transit_overall_time % (1000 * 60 * 60)) / (1000 * 60));
				transit_overall_time = hours + "." + minutes

				// console.log(transit_overall_time,"transit_overall_time")
				formData.append("transit_overall_time", transit_overall_time)

			}



			if (additional_last_mile_truck.pod_status_value_last_tmile == "Not Ok") {

				var commentValue = $("#commentValuelastmile").val()
				formData.append('comment_lastmile', commentValue);

				if (this.state.files_last != '') {
					var csvdd = this.state.files_last;


					formData.append('pod_status_value_last_tmile', 1);
					// formData.append("userId", localStorage.getItem('userid'));
					// formData.append("csvcontent",this.state.csvcontent);
					for (var x = 0; x < this.state.files_last.length; x++) {
						formData.append('files_last', this.state.files_last[x])
					}



				}

			} else if (additional_last_mile_truck.pod_status_value_last_tmile == "Ok") {
				formData.append('pod_status_value_last_tmile', 0);
			}
			else {
				formData.append('pod_status_value_last_tmile', "");
			}
			// if(additional_last_mile_truck.vehicle_no_from_lastmile.length <= 15 && additional_last_mile_truck.vehicle_no_from_lastmile.length >= 5){
			if (![undefined, "undefined", null, ""].includes(additional_last_mile_truck.last_fleet_availability) && ![undefined, "undefined", null, ""].includes(additional_last_mile_truck.vehicle_type_from_lastmile) && ![undefined, "undefined", null, ""].includes(additional_last_mile_truck.vehicle_no_from_lastmile)) {
				// console.log("check-7829")
				await redirectURL.post("/consignments/uploadadditionallastmiletrucks", formData)
					.then(
						(response) => {
							// console.log(response,response.data,response.status,"7830")


							if (response.data.message == 'Truck Already Exists') {

								// console.log("Truck Already Exists",response.data.message)

								this.setState({
									//csverrmessage:response.data.status,
									show: true,
									basicType: 'danger',
									basicTitle: "Truck Already Exists",
									bulksliderforcommentlast: '',
									bulksliderforcommentlastforadditionaltrucks: '',
									overly: 'show-n',
									showDiv: 'show-n',
									loadshow: 'show-n',
									uploadFile: '',
									files_last: '',
									files_last: [],

								});

							} else {
								var { legs_columndefs } = this.state
								// console.log(response.data.status);
								var additional_last_mile_truck = response.data.data
								// console.log(response.data,additional_last_mile_truck[0].additional_last_mile_truck,"7451");
								var legs_data = []
								if (additional_last_mile_truck[0].additional_last_mile_truck != undefined && additional_last_mile_truck[0].additional_last_mile_truck != "" && additional_last_mile_truck[0].additional_last_mile_truck != null) {
									if (additional_last_mile_truck[0].additional_last_mile_truck.length > 0) {
										legs_data = additional_last_mile_truck[0].additional_last_mile_truck
									} else {
										legs_data = []
									}
								} else {
									legs_data = []
								}
								var extra_legs_data = []
								response.data.data.map((e) => {
									extra_legs_data.push({
										"consignment_code": e.consignment_code,
										"last_fleet_availability": e.last_fleet_availability,
										"vehicle_type_from_lastmile": e.vehicle_type_from_lastmile,
										"vehicle_no_from_lastmile": e.vehicle_no_from_lastmile,
										"dispatch_time_from_hub_from_lastmile": e.dispatch_time_from_hub_from_lastmile,
										"reporting_time_at_consignees_from_lastmile": e.reporting_time_at_consignees_from_lastmile,
										"delivery_time_from_lastmile": e.delivery_time_from_lastmile,
										"transit_last_mile": e.transit_last_mile,
										"transit_overall_time": e.transit_overall_time,
										"comment_lastmile": e.pod_comment_lastmile,
										"pod_status_value_last_tmile": e.pod_status_value_last_tmile,
										"pod_file_lastmile": e.file_pod_last,
										"primary_truck": 1,
										"remark_for_overall_transit_time_delay_legs" : e.remark_for_overall_transit_time_delay_main_grid
									})
								})
								var final_legs_data = extra_legs_data.concat(legs_data)
								//    console.log(final_legs_data,"6662")

								//document.getElementById("upform").reset();
								if (response.data.status == 'failure') {
									// console.log("check-7868")
									this.setState({
										//csverrmessage:response.data.status,
										show: true,
										basicType: 'danger',
										basicTitle: "failure",
										bulksliderforcommentlast: '',
										bulksliderforcommentlastforadditionaltrucks: '',
										overly: 'show-n',
										showDiv: 'show-n',
										loadshow: 'show-n',
										uploadFile: '',
										files_last: '',
										files_last: [],

									});

								}

								else {
									// update last_mile_consignment state variable to show the legs icon
									let last_mile_consignments = this.state.last_mile_consignments;
									last_mile_consignments.push(response.data.data[0]['consignment_code'])
									this.gridApi.forEachNode((node) => {
										const rowData = node.data;
										if (rowData && rowData["consignment_code"] === response.data.data[0]['consignment_code']) {
											//   console.log(rowData, "rowDAta")
											// Update the value for the specific cell
											node.setDataValue("additional_last_mile_truck", final_legs_data);
											// Redraw only the updated row
											this.gridApi.redrawRows({ rowNodes: [node] });
											// Optionally, refresh the cells for the updated row
											this.gridApi.refreshCells({ rowNodes: [node], force: true });
										}
									});
									this.setState({
										// show: true, 
										basicType: 'success',
										basicTitle: "Successfully Uploaded.",
										bulksliderforcommentlast: '',
										rowDataForLastMile: final_legs_data,
										columnwithDefsForLastMile: legs_columndefs,
										bulksliderforcommentlastforadditionaltrucks: '',
										openLastMileModal: true,
										additional_last_mile_truck_slider: "",
										bulksliderforaddingmultipletrucks: "",
										commentValuelastmile: "",
										last_cmt: '',
										overly: 'show-n',
										showDiv: 'show-n',
										uploadFile: '',
										loadshow: 'show-n',
										files_last: '',
										files_last: [],
										additional_last_mile_truck: {
											last_fleet_availability: "",
											vehicle_type_from_lastmile: "",
											vehicle_no_from_lastmile: "",
											dispatch_time_from_hub_from_lastmile: "",
											reporting_time_at_consignees_from_lastmile: "",
											delivery_time_from_lastmile: "",
											pod_status_value_last_tmile: "",
											pod_comment_lastmile: "",
											file_pod_last: "",
											commentValuelastmile: "",
											last_cmt: ""
										},
										last_mile_consignments: last_mile_consignments
									});
									$("#last_mile").val('')
									$("#files_last").val('')
									$("#uploadFiletype").val('')
									$("#commentValuelastmile").val('')

								}
								this.setState({
									files_last: []
								})
							}


							//  window.location.reload()
						}
					)
					.catch(function (error) {
						// console.log(error,"7951")
						console.log(error);
					});
			} else {
				this.setState({
					show: true,
					basicTitle: 'Please Enter required Fields',
					basicType: "danger",
					loadshow: 'show-n'
				})
			}
			// }else{
			// 	alert("Vehicle Number length should be greater than 5 and less than 15.")
			// }

		}



		// console.log("onSubmitAdditionalLastMileTruck",this.state.additional_last_mile_truck)
	}

	uploadPodForAdditionalLastMileTruck = async (event) => {
		event.preventDefault();
		var { additional_last_mile_truck, selected_row_from_grid, selected_row_from_modal_grid } = this.state
		// console.log(additional_last_mile_truck,selected_row_from_grid,selected_row_from_modal_grid,"6475")
		var formData = new FormData();





		var commentValue = $("#commentValuelastmile").val()
		if (this.state.files_last != '') {
			var csvdd = this.state.files_last;

			// params["comment_lastmile"]=commentValue

			// formData.append('uploadFile',csvdd);
			formData.append('rowId', this.state.selected_row_from_modal_grid.rowId);
			formData.append('vehicle_no_from_lastmile', this.state.selected_row_from_modal_grid.vehicle_no_from_lastmile);
			formData.append('pod_status_value_last_tmile', 1);
			formData.append('comment_lastmile', commentValue);
			// formData.append('pod_status_value_last_tmile',this.state.samplelastmilepod);
			// formData.append("userId", localStorage.getItem('userid'));
			// formData.append("csvcontent",this.state.csvcontent);
			for (var x = 0; x < this.state.files_last.length; x++) {
				formData.append('files_last', this.state.files_last[x])
			}

			// console.log(formData,"6507")

			var rparams = {
				uploadFile: this.state.files_last,
				userId: localStorage.getItem('userid'),
				rowId: (this.state.idRowlast)
				// csvcontent:this.state.csvcontent
			}

		}



		await redirectURL.post("/consignments/uploadpodadditionallastmiletrucks", formData)
			.then(
				(response) => {
					//console.log(response);
					// console.log(response.data);
					//document.getElementById("upform").reset();

					var additional_last_mile_truck = response.data.data
					// console.log(response.data,additional_last_mile_truck[0].additional_last_mile_truck,"7451");


					var legs_data = []
					if (additional_last_mile_truck[0].additional_last_mile_truck != undefined && additional_last_mile_truck[0].additional_last_mile_truck != "" && additional_last_mile_truck[0].additional_last_mile_truck != null) {
						if (additional_last_mile_truck[0].additional_last_mile_truck.length > 0) {
							legs_data = additional_last_mile_truck[0].additional_last_mile_truck
						} else {
							legs_data = []
						}
					} else {
						legs_data = []
					}
					var extra_legs_data = []
					response.data.data.map((e) => {
						extra_legs_data.push({
							"consignment_code": e.consignment_code,
							"last_fleet_availability": e.last_fleet_availability,
							"vehicle_type_from_lastmile": e.vehicle_type_from_lastmile,
							"vehicle_no_from_lastmile": e.vehicle_no_from_lastmile,
							"dispatch_time_from_hub_from_lastmile": e.dispatch_time_from_hub_from_lastmile,
							"reporting_time_at_consignees_from_lastmile": e.reporting_time_at_consignees_from_lastmile,
							"delivery_time_from_lastmile": e.delivery_time_from_lastmile,
							"transit_last_mile": e.transit_last_mile,
							"transit_overall_time": e.transit_overall_time,
							"comment_lastmile": e.pod_comment_lastmile,
							"pod_status_value_last_tmile": e.pod_status_value_last_tmile,
							"pod_file_lastmile": e.file_pod_last,
							"primary_truck": 1,
							"remark_for_overall_transit_time_delay_legs" : e.remark_for_overall_transit_time_delay_main_grid
						})
					})
					var final_legs_data = extra_legs_data.concat(legs_data)
					//    console.log(final_legs_data,"6662")

					if (response.data.status == 'failure') {
						if (response.data.message.empty != "")
							this.setState({
								//csverrmessage:response.data.status,
								show: true,
								basicType: 'danger',
								basicTitle: "Failed",
								bulksliderforcommentlast: '',
								bulksliderforcommentlastforadditionaltrucks: "",
								overly: 'show-n',
								showDiv: 'show-n',
								loadshow: 'show-n',
								uploadFile: '',
								files_last: '',
								files_last: [],

							});

					}
					else {
						//console.log("here")
						this.setState({
							show: true,
							basicType: 'success',
							basicTitle: "Successfully Uploaded.",
							bulksliderforcommentlast: '',
							bulksliderforcommentlastforadditionaltrucks: "",
							rowDataForLastMile: final_legs_data,
							overly: 'show-n',
							showDiv: 'show-n',
							uploadFile: '',
							loadshow: 'show-n',
							files_last: '',
							files_last: [],
						});
						$("#files_last").val('')
						$("#uploadFiletype").val('')
						$("#commentValuelastmile").val('')
						// console.log($("#files_last").val(''),
						// $("#uploadFiletype").val(''),
						// $("#commentValuelastmile").val(''),"8057")
						// console.log("8005",document.getElementById("uploadFiletype").value,this.state.files_last)
						// document.getElementById("uploadFiletype").value = ""
						var form = document.getElementById("form-pod-additional-truck");
						if (form) {
							form.reset();
						}
						// const clickedRowNode = this.gridApi2.getRowNode(this.state.rowIndexcount2);
						// // console.log(clickedRowNode,'clickedRowNode')
						// if (clickedRowNode) {
						// 	let updatedRowData = []
						// 	this.gridApi2.forEachNode(node => {
						// 		// console.log(node, "node")
						// 		if(node.rowIndex == this.state.rowIndexcount2){
						// 			node.data = { ...node.data, "pod_comment_lastmile": commentValue,
						// 						"pod_file_lastmile":  this.state.files_last,"pod_status_value_last_tmile":this.state.samplelastmilepod};
						// 		}
						// 		updatedRowData.push(node.data)
						// 	});
						// 	this.gridApi2.setRowData(updatedRowData);
						// 	this.gridApi2.ensureIndexVisible(this.state.rowIndexcount2,'middle');
						// }
					}
					this.setState({
						files_last: []
					})

				}
			)
			.catch(function (error) {
				console.log(error);
			});

		// console.log("onSubmitAdditionalLastMileTruck",this.state.additional_last_mile_truck)
	}

	onChangeAdditionalLastMileTruck = (filter, event) => {
		// console.log(filter,event,"6459")
		var { additional_last_mile_truck } = this.state
		// console.log(additional_last_mile_truck,"6463")
		if (filter == "last_fleet_availability") {
			// if(event.value=="Other"){
			additional_last_mile_truck.vehicle_type_from_lastmile = ""
			additional_last_mile_truck.vehicle_no_from_lastmile = ""
			// }

			additional_last_mile_truck[filter] = event.value
		}
		else if (filter == "pod_status_value_last_tmile") {
			additional_last_mile_truck[filter] = event.value
		}
		else if (filter == "vehicle_type_from_lastmile_select") {
			additional_last_mile_truck["vehicle_type_from_lastmile"] = event.value
		}
		else if (filter == "vehicle_no_from_lastmile_for_select") {
			additional_last_mile_truck["vehicle_no_from_lastmile"] = event.value
		}
		else {
			additional_last_mile_truck[filter] = event.target.value
		}

		// console.log(additional_last_mile_truck,"6463")
		this.setState({ additional_last_mile_truck })

	}
	validateTruckNo(event) {
		if (![null, undefined, ""].includes(event.target.value)) {
			const regex = /^[A-Za-z0-9]{5,15}$/;
			if (!regex.test(event.target.value)) {
				// Input does not match the required format
				this.setState({
					loadshow: "show-n",
					overly: "show-n",
					show: true,
					basicTitle: 'Invalid Vehicle Number. It should be 5 to 15 characters with uppercase letters and numbers only.',
					basicType: "danger",
					loadshow: 'show-n'
				})
				return
				// this.componentDidMount()
			}
		}
	}

	validatelatitudeInput = (event) => {
		const inputValue = event.target.value.trim();
		const floatRegex = /^\d{1,}(\.\d{0,20})?$/;
		var main_cmt = this.state.main_cmt
		var cmt_legs_data = this.state.legs_cmt_data
		var legs_cmt = 0
		if (cmt_legs_data != undefined && cmt_legs_data != null && cmt_legs_data != "") {
			cmt_legs_data.map((e) => {
				if (e.volume != undefined) {
					legs_cmt = parseFloat(e.volume) + legs_cmt
				}
			})
		} else {
			legs_cmt = 0
		}
		console.log(cmt_legs_data, "8550")

		const final_cmt = parseFloat(main_cmt) - parseFloat(legs_cmt)
		console.log(final_cmt, "check-8561")
		if (inputValue != undefined && inputValue != "") {
			if (!floatRegex.test(inputValue)) {

			} else {
				if (final_cmt > parseFloat(inputValue)) {
					this.setState({
						last_cmt: inputValue,
					});
				} else {
					this.setState({
						basicTitle: "CMT value given should be less than Total CMT",
						basicType: "danger",
						show: true
					})
				}

			}
		} else {
			this.setState({
				last_cmt: "",
			});
		}
	};
	async customExportGridAsExcel() {
		this.setState({
			loadshow: 'show-m',
			overly: 'show-m'
		});

		if (this.gridApi) {
			// var columnNames = ["truck_no","transporter_code","status","mgpa_date_time","consignment_code","consignee_code","consignee_name","eway_bill_no","consignee_city","volume","gate_out_time","first_dealer_reported","actual_arrival_at_hub","pod_1st_mile","pod_comment","dispatch_time_from_hub_from_lastmile","last_fleet_availability","vehicle_type_from_lastmile","vehicle_no_from_lastmile","reporting_time_at_consignees_from_lastmile","delivery_time_from_lastmile","pod_status_value_last_tmile","pod_comment_lastmile","transitTime1stMile","transit_last_mile","transit_overall_time","remark_for_overall_transit_time_delay_main_grid"]
			const params = {
				allColumns: true,
				onlySelected: false,
				skipHeader: false,
				columnGroups: false,
				skipFooters: false,
				skipGroups: false,
				fileName: 'consignments-export.xlsx',
				sheetName: 'Sheet1',
				processCellCallback: (params) => {
					// Exclude the 'Value' column from the export
					const excludedColumns = ['show_last_mile_trucks'];
					if (params.column && excludedColumns.includes(params.column.getColId())) {
						return null; // Returning null excludes the cell from the export
					}
					// console.log(params.value,'params.value')
					return params.value;
				},
			};

			let modifiedData = [];
			this.gridApi.forEachNode((node) => {
				let row = node.data;
				modifiedData.push(row);
			});

			let gridLength = modifiedData.filter(e => e.truck_no !== "").length;
			modifiedData = modifiedData.map((row) => {
				if (row.truck_no !== "") {
					row.truck_count = "Truck " + gridLength.toString();
					gridLength = gridLength - 1;
				}
				let consignment_rec = this.state.rowData.filter(e => e.consignment_code === row.consignment_code);
				if (consignment_rec.length) {
					let additional_last_mile_trucks = consignment_rec[0]['additional_last_mile_truck'];
					if (!["", undefined, null].includes(additional_last_mile_trucks)) {
						if (Array.isArray(additional_last_mile_trucks)) {
							additional_last_mile_trucks = additional_last_mile_trucks.map((leg, index) => {

								let mergedObject = { ...row };
								for (const key in mergedObject) {
									if (leg.hasOwnProperty(key)) {
										mergedObject[key] = leg[key];
									}
								}
								mergedObject.truck_no = "";
								mergedObject.truck_count = "";
								return mergedObject;

							}).filter(Boolean);

							return [row, ...additional_last_mile_trucks];
						}
					}
				}
				return [row];
			}).flat();


			modifiedData = [].concat(...modifiedData);
			modifiedData.additional_last_mile_truck = []
			modifiedData.pod_file = []
			modifiedData.file_pod_last = []
			modifiedData.pod_file_lastmile = []
			this.gridApi.setRowData(modifiedData);
			  console.log(modifiedData,'modifiedData')
			try {
				modifiedData.forEach(row => {
					row.additional_last_mile_truck = [];
				});
				await this.gridApi.exportDataAsExcel(params);
			} catch (error) {
				console.error('Export error:', error);
			} finally {
				this.gridApi.setRowData(this.state.rowData);
				this.setState({
					loadshow: 'show-n',
					overly: 'show-n'
				});
			}
		}
	}

	updatingremarkfromoveralltransitmaingrid(event) {
		event.preventDefault()
		this.setState({
			loadshow: 'show-m',
			overly: 'show-m'
		});
		var remark_for_overall_transit_time_delay_main_grid = $("#remark_from_overall_transit_time_main_grid").val()

		var query = this.state.collecting_total_query_main_grid

		query["type"] = "remark_for_overall_transit_time_delay_main_grid"

		query["remark_for_overall_transit_time_delay_main_grid"] = remark_for_overall_transit_time_delay_main_grid

		var reporting_time_at_consignees_from_lastmile = this.state.collecting_total_query_main_grid.reporting_time_at_consignees_from_lastmile

		// console.log(this.state.remark_for_over_time_main_grid, 'collecting_total_query')
		redirectURL.post("/consignments/updatinglastmilelegsdata", query).then((response) => {
			// console.log(response.config.data, 'response', JSON.stringify(response.config.data))
			this.setState({
				remark_for_over_time_main_grid: '',
				remarkCheck : false
			})
			// console.log(this.gridApi,'gridApi')
			const clickedRowNode = this.gridApi.getRowNode(this.state.rowIndexcount);
			// console.log(clickedRowNode,'clickedRowNode')
			if (clickedRowNode) {
				let updatedRowData = []
				this.gridApi.forEachNodeAfterFilter(node => {
					console.log(node.rowIndex, "node",this.state.rowIndexcount2)
					if (node.rowIndex === this.state.rowIndexcount) {
						node.data = {
							...node.data,
							"reporting_time_at_consignees_from_lastmile": reporting_time_at_consignees_from_lastmile,
							"transit_overall_time": this.state.collecting_total_query_main_grid.transit_overall_time,
							"remark_for_overall_transit_time_delay_main_grid": remark_for_overall_transit_time_delay_main_grid,
							"transit_last_mile": this.state.collecting_total_query_main_grid.transit_time_last_mile
						};
					}
					updatedRowData.push(node.data)
					// console.log(node.data, 'node.data')
				});
				// console.log(updatedRowData,'updatedRowData')
				this.gridApi.setRowData(updatedRowData);
				this.gridApi.ensureIndexVisible(this.state.rowIndexcount, 'middle');
			}
		});
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n'
		});
		$("#remark_from_overall_transit_time_main_grid").val("")

	}

	updatingremarkfromoveralltransit(event) {
		event.preventDefault()
		this.setState({
			loadshow: 'show-m',
			overly: 'show-m'
		});
		var remark_for_overall_transit_time_delay_legs = $("#remark_from_overall_transit_time").val()
		var query = this.state.collecting_total_query

		var reporting_time_at_consignees_from_lastmile = this.state.collecting_total_query.reporting_time_at_consignees_from_lastmile
		query["field"] = "remark_for_overall_transit_time_delay_legs"
		query["remark_for_overall_transit_time_delay_legs"] = remark_for_overall_transit_time_delay_legs
		// console.log(this.state.collecting_total_query, 'collecting_total_query')
		redirectURL.post("/consignments/updatinglastmilelegsdataforadditionalvehicle", query).then((response) => {
			// console.log(response.config.data, 'response', JSON.stringify(response.config.data))
			this.setState({
				remark_for_over_time: '',
			})
			// console.log(this.gridApi,'gridApi')
			const clickedRowNode = this.gridApi2.getRowNode(this.state.rowIndexcount);
			// console.log(clickedRowNode,'clickedRowNode')
			if (clickedRowNode) {
				let updatedRowData = []
				this.gridApi2.forEachNode(node => {
					// console.log(node.rowIndex, "node",this.state.rowIndexcount2)
					if (node.rowIndex === this.state.rowIndexcount2) {
						node.data = {
							...node.data,
							"reporting_time_at_consignees_from_lastmile": reporting_time_at_consignees_from_lastmile,
							"remark_for_overall_transit_time_delay_legs": remark_for_overall_transit_time_delay_legs,
						};
					}
					updatedRowData.push(node.data)
					// console.log(node.data, 'node.data')
				});
				// console.log(updatedRowData,'updatedRowData')
				this.gridApi2.setRowData(updatedRowData);
				this.gridApi2.ensureIndexVisible(this.state.rowIndexcount2, 'middle');
			}
		});
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n'
		});
		$("#remark_from_overall_transit_time").val("")

	}
	render() {

		const modalStyles = {
			width: '1300px !important',
		}
		const { open, openforceclose, usermanualncrmodal, usermanualmovemodal } = this.state;
		var maptransporter = '';
		var dist_header_param_name = "Dist. From Destination (km)";
		var reached_dist_name = "Reporting Time at Hub (GPS)";
		if (this.props.match.path == "/tnpconsignments" || this.props.match.path == '/tnpdeliveredconsignments') {
			if (this.state.tnpReturnConsignmentsVisibilty == true) {
				dist_header_param_name = 'Dist. From Source Plant (km)';
				reached_dist_name = 'Reached Source Plant At';
			}
		}
		try {
			if (this.state.rownode.transporter) {
				///console.log("map rownode ",this.state.rownode.transporter[0])
				maptransporter = this.state.rownode.transporter[0].transporter_name;
			}
		}
		catch (e) {
			console.log(e)
		}
		var screen_type = this.state.deptcode
		//console.log("Rendere ",this.state.deptcode)
		let transitTimeHeading = (this.state.deptcode === "LOG-TNP") ? "Transit Time (Hours)" : "Transit Time (Days)";
		var department_code = this.state.deptcode;
		let isHiddenLocalMovement = (this.state.deptcode === "LOG-PRT") ? false : true;
		//console.log("isHiddenLocalMovement = ", isHiddenLocalMovement, this.state.deptcode);
		let hideonlyPRT = (this.state.deptcode === "LOG-PRT") ? true : false;
		let hideonlyTNP = (this.state.deptcode === "LOG-TNP") ? true : false;
		// let hideTNPconsignment = (this.state.deptcode === "LOG-TNP" && this.state.tnpReturnConsignmentsVisibilty == false) ? false : true;
		let hideTNPconsignment = (this.state.deptcode === "LOG-TNP") ? false : true;
		//let hideOnlyActive = 
		let hideonlyCol = ((this.props.match.path == "/sndconsignments")
			|| (this.props.match.path == "/prtconsignments")) ? true : false;


		let showOnlyCol = false;
		let showtimestamp = false;
		if ((this.props.match.path == "/sndconsignments")
			|| (this.props.match.path == "/prtconsignments")
			|| (this.props.match.path == "/tnpconsignments")
			|| (this.props.match.path == "/railconsignments")
			|| (this.props.match.path == "/ncrconsignments")) {
			showOnlyCol = false;
			showtimestamp = false;
		}
		else if (localStorage.getItem("roles") == "PACC") {
			showOnlyCol = true;
		}
		else {
			showOnlyCol = true;
		}
		if (this.props.match.path == "/insidemsilvicinity") {
			showtimestamp = false;
		}
		else {
			if ((this.props.match.path == "/sndconsignments")
				|| (this.props.match.path == "/prtconsignments")
				|| (this.props.match.path == "/tnpconsignments")
				|| (this.props.match.path == "/railconsignments")
				|| (this.props.match.path == "/ncrconsignments")) {
				showtimestamp = false;
			}
			else {
				showtimestamp = true;
			}

		}

		let showOnlyColDeliver = ((this.props.match.path == "/snddeliveredconsignments")
			|| (this.props.match.path == "/prtdeliveredconsignments")
			|| (this.props.match.path == "/tnpdeliveredconsignments")) ? false : true;

		let showonlyactivencr = ((this.props.match.path == "/sndconsignments")
			|| (this.props.match.path == "/prtconsignments")
			|| (this.props.match.path == "/tnpconsignments")
			|| (this.props.match.path == "/ncrconsignments")) ? false : true;

		let showdistance = ((this.props.match.path == "/sndconsignments")
			|| (this.props.match.path == "/prtconsignments")
			|| (this.props.match.path == "/tnpconsignments")) ? false : true;

		let invoicedisable = (this.state.deptcode == 'SNDG') ? false : true;
		let showOperations = (this.state.deptcode === "LOG-TNP") ? false : true;
		let showonlysnd = (this.props.match.path == "/sndconsignments" || this.props.match.path == "/snddeliveredconsignments" || this.props.match.path == "sndallconsignments") ? false : true;
		//console.log("hideonlyCol ", hideonlyCol)
		var pagepath = this.props.match.path;
		if ((this.state.counter_screen == "active" && this.state.deptcode != "LOG-TNP") || this.props.match.path == "/insidemsilvicinty") {
			var checkboxSelectionval = true;
			var hideStatus = true;
			var truckNoHeader = {
				headerName: "Truck No",
				field: "truck_no",
				width: 100,
				filter: "agSetColumnFilter",
				pinned: "left",
				cellRenderer: '', resizable: true
			}
		}
		else {
			var checkboxSelectionval = false;
			var hideStatus = false;
			var truckNoHeader = {
				headerName: "Truck No",
				field: "truck_no",
				width: 100,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true
			}
		}
		var hideNCR;
		if (this.props.match.path == "/ncrconsignments" || this.state.tnpReturnConsignmentsVisibilty) {
			hideNCR = true;
		} else {
			hideNCR = false;
		}
		if (this.state.screenpage == "deliver") {
			var hideOnTime = false
		} else { var hideOnTime = true }
		var hideonlypacc = (localStorage.getItem("roles") == "PACC") ? true : false;
		var hideTransporterCode = (this.state.deptcode === 'LOG-PRT' || this.state.deptcode === 'SNDG') ? false : true;
		let hideGPSFields = ((this.props.match.path == "/snddeliveredconsignments")
			|| (this.props.match.path == "/prtdeliveredconsignments")
			|| (this.props.match.path == "/tnpdeliveredconsignments")
			|| (this.props.match.path == "/sndallconsignments")
			|| (this.props.match.path == "/prtallconsignments")
			|| (this.props.match.path == "/tnpallconsignments")) ? false : true;

		let showOnlyDeliver = ((this.props.match.path == "/snddeliveredconsignments")
			|| (this.props.match.path == "/sndallconsignments")) ? false : true;
		var hideAccidentColumn = true;
		if (this.state.deptcode == "SNDG") {
			hideAccidentColumn = false;
		}
		var hidePalletColumns = (this.props.match.path == "/tnpconsignments") ? false : true;
		var hideCritical = (this.props.match.path == "/insidemsilvicinty") ? true : false;
		var hideDuration = (this.props.match.path == "/insidemsilvicinty") ? false : true;
		var last_consignment_zone = ((this.props.match.path == "/prtallconsignments") ||
			(this.props.match.path == "/prtdeliveredconsignments") ||
			(this.props.match.path == "/prtconsignments")) ? false : true;
		var screen_url = this.props.match.path;
		var hideDealerGateIn = true;
		if (screen_type == "LOG-PRT") {
			if (screen_url != "/prtconsignments") {
				hideDealerGateIn = false;
			} else { hideDealerGateIn = true };
		} else { hideDealerGateIn = true; };
		const mgpa_fields = (this.state.deptcode == "LOG-PRT") ? false : true;
		var hideDeleteOpt = true;
		var hidetnp = true;
		var userType = localStorage.getItem('user_type');

		if (this.props.match.path == "/tnpconsignments" && this.state.tnpReturnConsignmentsVisibilty == false) {
			hidetnp = false;
			// console.log(userType,"userType")
			if (userType == 'TRANSPORTER') {
				// console.log(hideDeleteOpt,"hideDeleteOpt1")
				hideDeleteOpt = true;
			}
			else {
				// console.log(hideDeleteOpt,"hideDeleteOpt2")
				hideDeleteOpt = false;
			}
		}

		var deliveredName = ((this.props.match.path == "/prtdeliveredconsignmentshub") ? "No. of delivered consignments" : "Consignee Clubbing")

		var dispalyvehicle = this.state.lastmilevehiclenolist
		var check_edit_details = false
		if (this.props.match.path == "/prtconsignmentshub") {
			check_edit_details = true
		}
		// console.log(this.state.transporterhub, "8582")
		var columnwithDefs =
			[
				
				{
					headerName: "Serial Number",
					field: "truck_count",
					width: 90,
					pinned: 'left',
					filter: false,
					cellRenderer: (params) => {
						if (params.data.truck_no === "") {
							return "";
						} else {
							const truckNumbers = Object.keys(this.state.maxTruckCount);
							//   const sortedTruckNumbers = truckNumbers.sort((a, b) => parseInt(b) - parseInt(a)); // Sort in descending order
							//   const index = sortedTruckNumbers.indexOf(params.data.truck_no) + 1;
							//   console.log(truckNumbers,'==',this.state.maxTruckCount,'++',index)
							//   return "Truck " + index;
							const sortedTruckNumbers = truckNumbers.sort((a, b) => {
								const indexA = truckNumbers.indexOf(a);
								const indexB = truckNumbers.indexOf(b);
								return indexB - indexA;
							});
							var gate_out_time = moment.parseZone(new Date(params.data.gate_out_time)).format("YYYY-MM-DD HH")
							let uniqueKey = params.data.truck_no + "_" + gate_out_time
							const index = sortedTruckNumbers.indexOf(uniqueKey) + 1;
							//   console.log(truckNumbers,'==',this.state.maxTruckCount,'++',index,sortedTruckNumbers)
							return "Truck - " + params.data.truck_sl_no;
						}
					},
					cellClass: (params) => {

						const truckNumbers = Object.keys(this.state.maxTruckCount);
						const sortedTruckNumbers = truckNumbers.sort((a, b) => {
							const indexA = truckNumbers.indexOf(a);
							const indexB = truckNumbers.indexOf(b);
							return indexB - indexA;
						});
						var gate_out_time = moment.parseZone(new Date(params.data.gate_out_time)).format("YYYY-MM-DD HH")
						let uniqueKey = params.data.truck_no + "_" + gate_out_time

						const index = sortedTruckNumbers.indexOf(uniqueKey) + 1;


						if (params.data.truck_no === "") {
							return "";
						} else {
							if (index % 2 == 0) {
								return ["cellstylegridG", "pstBg"];
							} else {

								return ["cellstylegridG2", "pstBg"];
							}
						}
					},
					rowSpan: (params) => {
						if (params.data.truck_no === "") {
							return "";
						} else {
							var gate_out_time = moment.parseZone(new Date(params.data.gate_out_time)).format("YYYY-MM-DD HH")
							let uniqueKey = params.data.truck_no + "_" + gate_out_time
							return this.state.maxTruckCount[uniqueKey];
						}
					},
				},

				{
					headerName: "Truck No",
					field: "truck_no",
					width: 120,
					// colId:"truck_no",
					filter: false,
					pinned: 'left',
					// filter: 'agSetColumnFilter',
					// filterFramework: CustomTextFilter, // Use your custom filter component
					// filterParams: {
					// 	onFilterChanged: this.onFilterChanged,
					// 	uniqueValues: this.getColumnUniqueValues("truck_no") // Pass the onFilterChanged method
					// },
					// filter: 'agTextColumnFilter', // Use the default text filter
					// filterParams: {
					// 	textCustomComparator: (filter, value, filterText, column) => {
					// 		let isMatch = false;
					// 		this.gridApi.forEachNode((node) => {
					// 		if (node.data.original_truck_no === filterText.toUpperCase()) {
					// 				isMatch = true;
					// 			}
					// 		});
					// 		console.log(isMatch, "isMatch")
					// 		return isMatch;
					// 	  }
					// },
					// pinned: "left",
					// rowGroup: true, 
					// cellRenderer:this.rowRenderer ,
					valueGetter: (params) => {
						if (params.data.truck_no === "") {
							return "";
						} else {
							return params.data.truck_no;
						}
					},
					cellClass: (params) => {

						const truckNumbers = Object.keys(this.state.maxTruckCount);
						const sortedTruckNumbers = truckNumbers.sort((a, b) => {
							const indexA = truckNumbers.indexOf(a);
							const indexB = truckNumbers.indexOf(b);
							return indexB - indexA;
						});
						var gate_out_time = moment.parseZone(new Date(params.data.gate_out_time)).format("YYYY-MM-DD HH")
						let uniqueKey = params.data.truck_no + "_" + gate_out_time

						const index = sortedTruckNumbers.indexOf(uniqueKey) + 1;


						if (params.data.truck_no === "") {
							return "";
						} else {
							if (index % 2 == 0) {
								return ["cellstylegridG", "pstBg"];
							} else {

								return ["cellstylegridG2", "pstBg"];
							}
						}
					},
					rowSpan: (params) => {
						if (params.data.truck_no === "") {
							return "";
						} else {
							var gate_out_time = moment.parseZone(new Date(params.data.gate_out_time)).format("YYYY-MM-DD HH")
							let uniqueKey = params.data.truck_no + "_" + gate_out_time
							return this.state.maxTruckCount[uniqueKey];
						}
					},
				},

				{
					headerName: "Transporter Code",
					field: "transporter_code",
					// pinned: 'left', 
					colId: "transporter_code",
					width: 150,
					// hide : hideTransporterCode,
					cellRenderer: '',
					resizable: true,
					filter: true
					// filterFramework: CustomTextFilter, // Use your custom filter component
					// filterParams: {
					// 	onFilterChanged: this.onFilterChanged,
					// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
					// },

				},

				{
					headerName: "Status",
					field: "status",
					colId: "status",
					width: 120,
					// pinned: 'left',
					// hide : hideStatus,            
					filter: true,
					// filterFramework: CustomTextFilter, // Use your custom filter component
					// filterParams: {
					// 	onFilterChanged: this.onFilterChanged,
					// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
					// },
					resizable: true,
					valueGetter: (params) => {
						 if(params.data.delivered === 1){
							return "Delivered"
						}else
						//console.log("GPS ", params.data.gps_data_available);
						if (([undefined, "", NaN].includes(params.data.inside_parking_at) == false || [undefined, "", NaN].includes(params.data.parking_radius_entered_on) == false) && [undefined, "", NaN].includes(params.data.outside_parking_at) == true && this.props.match.path == "/tnpconsignments" && [2, 3].includes(params.data.status)) {
							return "At Parking Yard"
						}
						else if ([undefined, "", NaN].includes(params.data.inside_parking_at) == false && [undefined, "", NaN].includes(params.data.outside_parking_at) == false && [2, 3].includes(params.data.status)
							&& this.props.match.path == "/tnpconsignments") {
							return "Left Parking Yard"
						}
						else if (params.data.status == 1) {
							return "Inside Plant"
						}
						else if (params.data.status == 2) {
							return "In Transit"
						}
						else if (params.data.dest_geofence_id != undefined && screen_type == "LOG-PRT") {
							if (screen_url != "/prtconsignmentshub") {
								return "Reached Plant Gate";
							}
						}
						else if (params.data.status == 3) {
							return "Near Hub"
						}
						else if (params.data.status == 4) {
							if (this.state.tnpReturnConsignmentsVisibilty == true) {
								if (params.data.next_trip_created == 0 || params.data.next_trip_created == undefined) return 'Reached Source Plant'
							}
							return "Reached Hub"
						}

						else if (params.data.status == 5) {
							return "Left Hub"
						}
						else if (params.data.status == 6) {
							return "Force Closed"
						}
						else if (params.data.status == 7) {
							return "Cancelled As Per MSIL"
						}
						else if (params.data.status == 8 && this.state.deptcode == "LOG-PRT") {
							return "POD Received From MSIL"
						}
						else {
							return ""
						}

					}
				},
				{
					headerName: "MGPA Date Time",
					field: "mgpa_date_time",
					colId: "mgpa_date_time",
					width: 130,
					filter: true,
					// filterFramework: CustomTextFilter, // Use your custom filter component
					// filterParams: {
					// 	onFilterChanged: this.onFilterChanged,
					// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
					// },
					resizable: true,
					valueGetter: function (params) {
						return getHyphenDDMMMYYYYHHMM(params.data.mgpa_date_time)
					}
				},
				{
					headerName: "Consignment Code",
					field: "consignment_code",
					colId: "consignment_code",
					width: 120,
					// pinned: 'left',
					editable: false,
					filter: true,
					// filterFramework: CustomTextFilter, // Use your custom filter component
					// filterParams: {
					// 	onFilterChanged: this.onFilterChanged,
					// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
					// },
					resizable: true,
					//cellRenderer : "agGroupCellRenderer"
				},
				{
					headerName: "Consignee Code",
					field: "consignee_code",
					colId: "consignee_code",
					width: 150,
					filter:true,
					cellRenderer: '',
					 resizable: true,
					editable: false,
					cellEditor: 'agSelectCellEditor',
					cellEditorParams: {
						values: this.state.statusList,
					},
					valueSetter: (params) => {
						params.data.consignee_code = params.newValue
						//   console.log(params,"params from slectino")
						if (params.oldValue != params.newValue) {

							var formData = {
								_id: params.data._id,
								consignee_code: params.newValue,
							}
							params.data.consignee_code = params.newValue;
							//   console.log(formData,"params for the consignee")
							redirectURL.post("/consignments/saveTnpReturnConsignee", formData, {
								headers: {
									'Content-Type': 'application/json'
								}
							}).then((response) => {
							})
						}
					}
				},
				{
					headerName: "Consignee Name",
					field: "consignee_name",
					colId: "consignee_name",
					width: 150,
					// hide:hideonlyTNP,     
					// filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true,
					filter: true,
					// filterFramework: CustomTextFilter, // Use your custom filter component
					// filterParams: {
					// 	onFilterChanged: this.onFilterChanged,
					// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
					// },	          
				},
				{
					headerName: "Consigner Code",
					field: "consigner_code",
					width: 150,
					cellRenderer: '', resizable: true,
					filter: true,	          
				},
				{
					headerName: "Eway Bill No",
					field: "eway_bill_no",
					width: 150,
					filter: true,
					// filterFramework: CustomTextFilter, // Use your custom filter component
					// filterParams: {
					// 	onFilterChanged: this.onFilterChanged,
					// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
					// },	
					cellRenderer: '',
					resizable: true
				},
				{
					headerName: deliveredName,
					field: "truck_count",
					filter: false,
					width: 120,
					valueGetter: (params) => {
						if (params.data.truck_no === "") {
							return "";
						} else {
							var gate_out_time = moment.parseZone(new Date(params.data.gate_out_time)).format("YYYY-MM-DD HH")
							let uniqueKey = params.data.truck_no + "_" + gate_out_time
							return this.state.maxTruckCount[uniqueKey];
						}
					},
					cellClass: (params) => {

						const truckNumbers = Object.keys(this.state.maxTruckCount);
						const sortedTruckNumbers = truckNumbers.sort((a, b) => {
							const indexA = truckNumbers.indexOf(a);
							const indexB = truckNumbers.indexOf(b);
							return indexB - indexA;
						});
						var gate_out_time = moment.parseZone(new Date(params.data.gate_out_time)).format("YYYY-MM-DD HH")
						let uniqueKey = params.data.truck_no + "_" + gate_out_time

						const index = sortedTruckNumbers.indexOf(uniqueKey) + 1;


						if (params.data.truck_no === "") {
							return "";
						} else {
							if (index % 2 == 0) {
								return ["cellstylegridG", "pstBg"];
							} else {

								return ["cellstylegridG2", "pstBg"];
							}
						}
					},
					rowSpan: (params) => {
						if (params.data.truck_no === "") {
							return "";
						} else {
							var gate_out_time = moment.parseZone(new Date(params.data.gate_out_time)).format("YYYY-MM-DD HH")
							let uniqueKey = params.data.truck_no + "_" + gate_out_time
							return this.state.maxTruckCount[uniqueKey];
						}
					},
				},
				{
					headerName: "City",
					field: "consignee_city",
					filter: true,

					// filterFramework: CustomTextFilter, // Use your custom filter component
					// filterParams: {
					// 	onFilterChanged: this.onFilterChanged,
					// 	uniqueValues: this.getColumnUniqueValues("consignee_city") // Pass the onFilterChanged method
					// },
					// colId:"truckCity",					
					width: 150,
					// hide:showOnlyCol,
					// valueGetter:function(params){
					// 	//console.log(params.data.truck);
					// 	if (params.data.hasOwnProperty('truck'))
					// 	{
					// 		if (params.data.truck.length > 0)
					// 		{
					// 		  return params.data.truck[0].area;
					// 		}
					// 	}

					// }
				},


				{
					headerName: 'Total CMT',
					field: 'volume',
					// hide:false,
					width: 150,
					filter: true,
					// filterFramework: CustomTextFilter, // Use your custom filter component
					// filterParams: {
					// 	onFilterChanged: this.onFilterChanged,
					// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
					// },
					resizable: true,
					editable: ([undefined, "", null].includes(localStorage.getItem("hub_transporter_code"))
						&& localStorage.getItem("user_type") == "TRANSPORTER" || ((this.props.match.path == "/prtallconsignmentshub") && (this.props.match.path == "/prtdeliveredconsignmentshub")))
						? false : ((this.state.transporterhub == "activet") ? false : true),
					valueSetter: (params) => {
						params.data.volume = params.newValue
						// console.log(params,'params',params.data)
						const newValue = params.newValue;
						const data = params.data;
						var params = {
							rowId: params.data._id,
							volume: parseFloat(newValue),
							type: "CMT"
						}
						// console.log(params,'params for backend')
						if (newValue != "") {
							try {
								redirectURL.post("/consignments/updatinglastmilelegsdata", params);
								// this.initComponent();
							} catch (error) {
								console.error(error);
							}
						}
						return false;
					}
				},

				// {
				// 	headerName:  'Total CMT',
				// 	field: 'total_cmt_value_from_lastmile',
				// 	hide:false,
				// 	width: 150,
				// 	pinned:'left',
				// 	resizable: true,
				// 	editable: true,
				// 	valueSetter: (params) => { 
				// 		console.log(params,'params',params.data)
				// 		const newValue = params.newValue; 
				// 		const data = params.data; 
				// 		var params = {
				// 				rowId : params.data._id,
				// 				total_cmt_value_from_lastmile : parseFloat(newValue),
				// 				type:"Total CMT"
				// 					}
				// 					console.log(params,'params for backend')
				// 					if(newValue != ""){
				// 					try {
				// 							redirectURL.post("/consignments/updatinglastmilelegsdata", params);
				// 						this.initComponent();
				// 						} catch (error) {
				// 						console.error(error);
				// 						}
				// 					}
				// 					return false;
				// 					}
				// 		},






				{
					headerName: "Gate out Time",
					field: "gate_out_time",
					// colId: "gate_out_time",					
					width: 180,
					filter: true,
					// filterFramework: CustomTextFilter, // Use your custom filter component
					// filterParams: {
					// 	onFilterChanged: this.onFilterChanged,
					// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
					// },
					valueGetter: function (params) {
						return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
					},
					resizable: true
				},



				{
					headerName: reached_dist_name,
					filter: true,
					// filterFramework: CustomTextFilter, // Use your custom filter component
					// filterParams: {
					// 	onFilterChanged: this.onFilterChanged,
					// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
					// },
					field: "first_dealer_reported",
					width: 180,
					// hide:hideonlyCol,
					valueGetter: function (params) {
						if (params.data.status == 3 || params.data.status == 4 || params.data.status == 5) {
							const fieldToUse = "first_dealer_reported";
							return getHyphenDDMMMYYYYHHMM(params.data[fieldToUse]);
						} else {
							return "";
						}
					}, // Binding the context to access this.state

					resizable: true,

				},
				{
					headerName: "Actual Arrival at Hub (Manual)*",
					// headerCellRenderer:function(params){
					// 	const headerElement = document.createElement('div');
					// 	headerElement.innerHTML = `Actual Arrival at Hub (Manual)<span class="red-asterisk">*</span>`;
					// 	return headerElement;
					// },
					field: "actual_arrival_at_hub",
					width: 150,
					filter: true,
					// filterFramework: CustomTextFilter, // Use your custom filter component
					// filterParams: {
					// 	onFilterChanged: this.onFilterChanged,
					// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
					// },             
					cellEditor: DateEditor,
					editable: ([undefined, "undefined", "", null].includes(localStorage.getItem("hub_transporter_code"))
						&& localStorage.getItem("user_type") == "TRANSPORTER" || ((this.props.match.path == "/prtallconsignmentshub") ||
							(this.props.match.path == "/prtdeliveredconsignmentshub")))
						? false : ((this.state.transporterhub == "activet") ? false : true),
					valueGetter: function (params) {
						if (params.data.actual_arrival_at_hub != '' && params.data.actual_arrival_at_hub != undefined) {
							// console.log(params.data.actual_arrival_at_hub, "8835")
							let arrival_time = params.data.actual_arrival_at_hub;
							if (typeof (arrival_time) == "string") {
								if (!arrival_time.includes("T") && (arrival_time.includes("-") || arrival_time.includes("/"))) {
									let splitArray = arrival_time.split(" ");
									let splitDate = ""
									if (arrival_time.includes("-")) {
										splitDate = splitArray[0].split("-")
									} else if (arrival_time.includes("/")) {
										splitDate = splitArray[0].split("/")
									}
									if (splitDate != "" && splitDate[0].length < 4) {
										arrival_time = splitDate[2] + "/" + splitDate[1] + "/" + splitDate[0] + " " + splitArray[1]
									}
								}
							}
							// console.log(arrival_time, "arrival_time")
							return getHyphenDDMMMYYYYHHMM(arrival_time)
						}
						else {
							return "";
						}
					},
					comparator: dateComparator1,
					valueSetter: (params) => {

						const newValue = params.newValue;
						console.log(newValue, "9265")
						const data = params.data;
						// console.log(new Date(data.gate_out_time),new Date(newValue),"chejwsjdjdjd")
						console.log(moment.parseZone(new Date(data.gate_out_time)).utcOffset("-05:30")._d, "hit", moment.parseZone(new Date(newValue)).utcOffset("+00:00")._d, "hit-2")
						if (moment.parseZone(new Date(newValue)).utcOffset("+00:00")._d > moment.parseZone(new Date(data.gate_out_time)).utcOffset("-05:30")._d) {
							console.log("check-1")
							this.gridApi.forEachNode(node => {
								// console.log(node.data.original_truck_no,getHyphenYYYYMMDDHHMMSS(node.data.gate_out_time),"node")
								if (node.data.original_truck_no == params.data.original_truck_no && getHyphenYYYYMMDDHHMMSS(node.data.gate_out_time) == getHyphenYYYYMMDDHHMMSS(params.data.gate_out_time)) {
									node.data.actual_arrival_at_hub = params.newValue
									var actual_arrival_at_hub = moment.parseZone(new Date(newValue)).utcOffset("+00:00")._d
									var gate_out_time = moment.parseZone(new Date(data.gate_out_time)).utcOffset("-05:30")._d
									// console.log(actual_arrival_at_hub,"9277",gate_out_time)
									const timeDiff = actual_arrival_at_hub - gate_out_time; // Difference in milliseconds
									// console.log(timeDiff,"9278")
									const hours = Math.floor(timeDiff / (1000 * 60 * 60)); // Convert milliseconds to hours
									// console.log(hours,"9280")
									const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
									// console.log(minutes,"9282")
									node.data.transitTime1stMile = hours + "." + minutes
									// console.log("9077",node.data.gate_out_time)
								}

							})
							params.data.actual_arrival_at_hub = params.newValue
							// console.log(params,'helllllo',newValue,'---',data.truck_no);
							// let truckConsignments = [];
							//  this.state.rowData.filter((e) => {
							// 	if(e.truck_no == params.data.truck_no && e.gate_out_time == params.data.gate_out_time){
							// 		truckConsignments.push(e.consignment_code)
							// }})
							var query = {
								truck_no: params.data.original_truck_no,
								gate_out_time: getHyphenYYYYMMDDHHMMSS(params.data.gate_out_time),
								actual_arrival_at_hub: newValue,
								transporter_code: data.transporter_code,
								id: params.data._id,
								consignment_code: data.consignment_code,
								// consignment_codes: JSON.stringify(truckConsignments)
							}
							// console.log(query,'params----')
							redirectURL.post("/consignments/updatingactualarrival", query)
								.then((response) => {
									params.data.transitTime1stMile = response.data.transitTimeInHours

									this.gridApi.redrawRows();
									return true
									// this.onloadPageData();
									// this.componentDidMount()
									// this.initComponent()
									// window.location.reload()
								})
								.catch(function (error) {
									console.log(error);
								});

						}
						else {
							if (![undefined, null, ""].includes(params.data.gate_out_time) && ![undefined, null, ""].includes(newValue)) {
								this.setState({
									show: true,
									basicTitle: 'Actual Arrival Time at Hub should be greater than Gate out Time',
									basicType: "danger",
									loadshow: 'show-n'
								});
							}

						}

					}


				},

				{
					headerName: "POD First Mile*",
					field: "pod_1st_mile",
					width: 150,

					resizable: true,
					filter: true,
					// filterFramework: CustomTextFilter, // Use your custom filter component
					// filterParams: {
					// 	onFilterChanged: this.onFilterChanged,
					// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
					// },
					editable: ((params) =>
						([undefined, "", null].includes(localStorage.getItem("hub_transporter_code"))
							&& localStorage.getItem("user_type") == "TRANSPORTER" || ((this.props.match.path == "/prtallconsignmentshub")
								|| (this.props.match.path == "/prtdeliveredconsignmentshub"))) ? false : ((this.state.transporterhub == "activet") ? false : true)),
					// hide: function (params) {

					// 	return params.data.status >= 4;
					//   },
					// hide:params.data.status >= Number(4),
					cellEditor: 'agSelectCellEditor',
					cellEditorParams: {
						values: ["(Blank)", "Ok", "Not Ok"],
					},
					valueGetter: function (params) {
						const podStatusValue = params.data.pod_1st_mile;

						if (podStatusValue === "") {
							return " "; // Display an empty string
						} else if (podStatusValue === 0) {
							return "Ok";
						} else if (podStatusValue === 1) {
							return "Not Ok";
						} else {
							return podStatusValue; // Display the actual value if it's not empty, 0, or 1

						}
					},
					valueSetter: (params) => {
						// params.data.pod_1st_mile = params.newValue
						// console.log(params.node.rowIndex,"index value")
						// this.updateRowData(params.node.rowIndex)
						this.setState({
							rowIndexcount: params.node.rowIndex,
							commentValue: ''
						})
						// console.log("IN ValyeSetter, data = ",params.newValue,'---');
						if (params.data.pod_1st_mile !== params.newValue) {
							if (params.newValue === "Not Ok") {
								params.newValue = 1;
								// console.log(params.data.pod_comment,'params.data.pod_comment')
								// Set the slider and overlay when "Not Ok"

								this.setState({
									commentValue: params.data.pod_comment,
									samplepod1stmile: JSON.parse(params.newValue),
									idRow: params.data._id,
									bulksliderforcomment: "slider-translate",
									overly: 'show-m'
								});
								var form = document.getElementById("form-pod-first-mile");
								if (form) {
									form.reset();
								}
								this.gridApi.redrawRows()
							}
							else if (params.newValue === "Ok") {
								params.newValue = 0;

								if (params.newValue == "Ok") {
									params.newValue = 0
								}
								params.data.pod_1st_mile = params.newValue
								let layoutdataRole = {
									pod_1st_mile: JSON.parse(params.newValue),
									ID: params.data._id
								}
								redirectURL.post('/consignments/prtconsignmentshublegsdataPODEdit', layoutdataRole, {
									headers: {
										"Access-Control-Allow-Origin": "*",
										"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
									}
								})
									.then((response) => {



										// this.setState({
										// 	commentValue:''
										// })

									})
									.catch(function (error) {
										console.log(error);
									});
								this.gridApi.redrawRows()
								return true;
							}
							else if (params.newValue === "(Blank)") {



								params.data.pod_1st_mile = ""
								let layoutdataRole = {
									pod_1st_mile: "",
									ID: params.data._id
								}
								console.log(layoutdataRole, "9449")
								redirectURL.post('/consignments/prtconsignmentshublegsdataPODEdit', layoutdataRole, {
									headers: {
										"Access-Control-Allow-Origin": "*",
										"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
									}
								})
									.then((response) => {



										// this.setState({
										// 	commentValue:''
										// })

									})
									.catch(function (error) {
										console.log(error);
									});
								this.gridApi.redrawRows()
								return true;
							}
						} else {
							this.gridApi.redrawRows()
							return true;
						}
					}

				},
				//   {
				// 	headerName: "First Mile Comment",
				// 	field: "comment",
				// 	width: 180,
				// 	cellRenderer: function (params) {
				// 	  if (params.data.pod_1st_mile === 1) {
				// 		var htmloption = '<button class="btn btn-warning" style="padding-top:0px">comment</button>';
				// 		return htmloption;
				// 	  } else {
				// 		return "";
				// 	  }
				// 	},
				//   },
				{
					headerName: "First Mile Comment",
					field: "pod_comment",
					width: 180,
					resizable: true,
					filter: true,
					// filterFramework: CustomTextFilter, // Use your custom filter component
					// filterParams: {
					// 	onFilterChanged: this.onFilterChanged,
					// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
					// },
					cellRenderer: function (params) {
						if (params.data.pod_1st_mile === 0) {
							return ""
						}
						else if (params.data.pod_1st_mile == "") {
							return ""
						}
						else {
							return params.data.pod_comment;
						}
					}
				},
				{
					headerName: 'POD First Mile Download',
					field: 'file_pod_frist',
					filter: true,
					// filterFramework: CustomTextFilter, // Use your custom filter component
					// filterParams: {
					// 	onFilterChanged: this.onFilterChanged,
					// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
					// },
					width: 180, // Use the desired width
					cellRenderer: function (params) {

						if (params.data.pod_1st_mile != 0) {
							//   console.log((params.data.pod_file),'pod_file',params.data.file_pod_frist)
							if (params.data.pod_file) {
								var htmloption = '<a class="custom-btn label label-success"><i class="fa fa-download"></i> Download</a>';

								return htmloption;
							} else {
								return '';
							}
						} else {
							return ""
						}
					}
				},
				{
					headerName: 'Dispatch time from Hub*',
					field: 'dispatch_time_from_hub_from_lastmile',
					width: 150,
					resizable: true,
					filter: true,
					// filterFramework: CustomTextFilter, // Use your custom filter component
					// filterParams: {
					// 	onFilterChanged: this.onFilterChanged,
					// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
					// },
					editable: ([undefined, "", null].includes(localStorage.getItem("hub_transporter_code"))
						&& localStorage.getItem("user_type") == "TRANSPORTER" || ((this.props.match.path == "/prtallconsignmentshub") || (this.props.match.path == "/prtdeliveredconsignmentshub")))
						? false : ((this.state.transporterhub == "activet") ? false : true),
					// hide:this.state.istransporter,
					cellEditor: DateEditor,
					width: 150,
					valueGetter: (param) => {
						if (param.data.dispatch_time_from_hub_from_lastmile !== "" && param.data.dispatch_time_from_hub_from_lastmile != undefined && param.data.dispatch_time_from_hub_from_lastmile != null) {
							// console.log(getHyphenYYYYMMDDHHMMSS(param.data.dispatch_time_from_hub_from_lastmile),"9066")
							// console.log(param.data.dispatch_time_from_hub_from_lastmile, "8835")
							let arrival_time = param.data.dispatch_time_from_hub_from_lastmile;
							if (typeof (arrival_time) == "string") {
								if (!arrival_time.includes("T") && (arrival_time.includes("-") || arrival_time.includes("/"))) {
									let splitArray = arrival_time.split(" ");
									let splitDate = ""
									if (arrival_time.includes("-")) {
										splitDate = splitArray[0].split("-")
									} else if (arrival_time.includes("/")) {
										splitDate = splitArray[0].split("/")
									}
									if (splitDate != "" && splitDate[0].length < 4) {
										arrival_time = splitDate[2] + "/" + splitDate[1] + "/" + splitDate[0] + " " + splitArray[1]
									}
								}
							}
							return getHyphenDDMMMYYYYHHMM(arrival_time);

						} else {
							return "";
						}
					},
					comparator: dateComparator1,
					valueSetter: (params) => {
						const newValue = params.newValue;
						// console.log("9077",params.data.actual_arrival_at_hub)
						// console.log(newValue,params.data.actual_arrival_at_hub,"dafdfadfadfadfadf") 
						// console.log(moment.parseZone(new Date(newValue)).utcOffset("+00:00")._d,
						// moment.parseZone(new Date(params.data.actual_arrival_at_hub)).utcOffset("+00:00")._d,
						// moment.parseZone(new Date(newValue)).utcOffset("+00:00")._d >
						//  moment.parseZone(new Date(params.data.actual_arrival_at_hub)).utcOffset("+00:00")._d,
						// "9037"
						// )
						var actual_arrival_at_hub = params.data.actual_arrival_at_hub
						if (actual_arrival_at_hub != undefined && actual_arrival_at_hub != null && actual_arrival_at_hub != "" && actual_arrival_at_hub != "undefined") {
							if (typeof (actual_arrival_at_hub) == "string") {
								if (actual_arrival_at_hub.includes("T")) {
									var split_String = actual_arrival_at_hub.split("T")
									let date = split_String[0]
									let time = split_String[1].split(".")[0]
									actual_arrival_at_hub = date + " " + time
								}
							}
						}

						//   console.log(actual_arrival_at_hub,"9094")
						if (moment.parseZone(new Date(newValue))._d >
							moment.parseZone(new Date(actual_arrival_at_hub)).utcOffset("+00:00")._d) {
							// console.log("check-1")
							const data = params.data;
							params.data.dispatch_time_from_hub_from_lastmile = params.newValue
							// console.log('helllllo',newValue,'---',data);
							var query = {
								rowId: params.data._id,
								// truck_no : data.truck_no,
								reporting_time_at_consignees_from_lastmile: getYYYYMMDDHHMMSS(params.data.reporting_time_at_consignees_from_lastmile),
								dispatch_time_from_hub_from_lastmile: newValue,
								// transporter_code : data.transporter_code
								type: "Dispatch time from HUB"
							}
							// console.log("9096",query)
							if (newValue != "") {
								try {
									redirectURL.post("/consignments/updatinglastmilelegsdata", query).then((response) => {
										// console.log(response.data,"Gdagadgadg")
										if (response.data.transit_time_last_mile != "" && response.data.transit_time_last_mile != undefined && response.data.transit_time_last_mile != null) {
											params.data.transit_last_mile = response.data.transit_time_last_mile
										}
										this.gridApi.redrawRows();
									});
									// this.initComponent();
								} catch (error) {
									console.error(error);
								}
							}
							return true;
						} else {
							// console.log("check-2")
							if (![undefined, null, ""].includes(newValue) && ![undefined, null, ""].includes(actual_arrival_at_hub)) {
								this.setState({
									show: true,
									basicTitle: 'Dispatch Time from Hub should be greater than Actual Arrival Time At Hub',
									basicType: "danger",
									loadshow: 'show-n'
								});
							}
						}
					}


				},
				{
					headerName: "Last Mile Fleet Availability*",
					field: "last_fleet_availability",
					width: 150,
					// hide:this.state.istransporter,
					filter: true,
					// filterFramework: CustomTextFilter, // Use your custom filter component
					// filterParams: {
					// 	onFilterChanged: this.onFilterChanged,
					// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
					// },
					resizable: true,
					editable: ((params) =>
						([undefined, "", null].includes(localStorage.getItem("hub_transporter_code"))
							&& localStorage.getItem("user_type") == "TRANSPORTER" || ((this.props.match.path == "/prtallconsignmentshub") ||
								(this.props.match.path == "/prtdeliveredconsignmentshub"))) ? false : ((this.state.transporterhub == "activet") ? false : ((this.state.transporterhub == "activet") ? false : true))),

					cellEditor: 'agSelectCellEditor',
					cellEditorParams: {
						values: ["(Blank)", "Fleet Master", "Other"],

					},
					valueGetter: function (params) {
						const podStatusValue = params.data.last_fleet_availability;

						if (podStatusValue === "") {
							return ""; // Display an empty string
						}
						else if (podStatusValue === "Fleet Master") {
							return "Fleet Master";
						}
						else if (podStatusValue === "(Blank)") {
							return "";
						}
						else if (podStatusValue === "Other") {
							return "Other";
						} else {
							return podStatusValue; // Display the actual value if it's not empty, 0, or 1
						}
					},
					valueSetter: (params) => {


						// console.log("IN ValyeSetter, data = fleet ",params.newValue,'---');
						// if (params.data.last_fleet_availability == params.newValue) {
						params.data.last_fleet_availability = params.newValue
						params.data.vehicle_type_from_lastmile = ""
						params.data.vehicle_no_from_lastmile = ""
						let layoutdataRole = {
							last_fleet_availability: (params.newValue) == "(Blank)" ? "" : params.newValue,
							ID: params.data._id,
							pod: 'last_fleet_availability'
						}

						// console.log(layoutdataRole,"9112")
						redirectURL.post('/consignments/prtconsignmentshublegsdataPODEdit', layoutdataRole, {
							headers: {
								"Access-Control-Allow-Origin": "*",
								"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
							}
						})
							.then((response) => {

								// console.log(response,"9119")
								// this.initComponent();

							})
							.catch(function (error) {
								console.log(error);
							});
						if (params.newValue === "Other") {
							$("#lastmilemastervehicle").val(params.data.vehicle_no_from_lastmile)
							// Set the slider and overlay when "Not Ok"
							this.setState({
								samplefleetvalue: (params.newValue),
								rowIndexcount: params.node.rowIndex,
								last_fleet_availabilityID: params.data._id,
								last_fleet_availabilityBulk: "slider-translate",
								vehicle_type_from_lastmile: params.data.vehicle_type_from_lastmile,
								overly: 'show-m'
							});
						}
						return true;
					},

				},
				{
					headerName: 'Last Mile Vehicle Type*',
					field: 'vehicle_type_from_lastmile',
					// hide:this.state.istransporter,
					width: 150,
					resizable: true,
					filter: true,
					// filterFramework: CustomTextFilter, // Use your custom filter component
					// filterParams: {
					// 	onFilterChanged: this.onFilterChanged,
					// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
					// },
					editable: ((params) => (params.data.last_fleet_availability == 'Fleet Master') ?
						(([undefined, "", null].includes(localStorage.getItem("hub_transporter_code"))
							&& localStorage.getItem("user_type") == "TRANSPORTER" || ((this.props.match.path == "/prtallconsignmentshub") ||
								(this.props.match.path == "/prtdeliveredconsignmentshub"))) ? false : ((this.state.transporterhub == "activet") ? false : true)) : false),

					cellEditor: 'agSelectCellEditor',
					cellEditorParams: {
						values: ["(Blank)", ...this.state.lastmilevehicletypelist.map(vehicle => vehicle.value)],
					},
					valueGetter: (params) => params.data.vehicle_type_from_lastmile == "(Blank)" ? "" : params.data.vehicle_type_from_lastmile,
					valueSetter: (params) => {
						params.data.vehicle_type_from_lastmile = params.newValue
						//   console.log(params, 'params', params.data);
						const newValue = params.newValue;
						const data = params.data;
						var params = {
							rowId: params.data._id,
							vehicle_type_from_lastmile: newValue,
							type: "Vehicle Type"
						};
						//   console.log(params, 'params for backend');
						if (newValue !== "") {
							try {
								redirectURL.post("/consignments/updatinglastmilelegsdata", params);
								//   this.initComponent();
							} catch (error) {
								console.error(error);
							}
						}
						return false;
					}
				},
				{
					headerName: 'Last Mile Vehicle No*',
					field: 'vehicle_no_from_lastmile',
					width: 150,
					// hide:this.state.istransporter,
					filter: true,
					// filterFramework: CustomTextFilter, // Use your custom filter component
					// filterParams: {
					// 	onFilterChanged: this.onFilterChanged,
					// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
					// },								
					editable: ((params) => (params.data.last_fleet_availability == 'Fleet Master') ?
						(([undefined, "", null].includes(localStorage.getItem("hub_transporter_code"))
							&& localStorage.getItem("user_type") == "TRANSPORTER" || ((this.props.match.path == "/prtallconsignmentshub") ||
								(this.props.match.path == "/prtdeliveredconsignmentshub"))) ? false : ((this.state.transporterhub == "activet") ? false : true)) : false),

					// cellEditor: 'agSelectCellEditor', // Set it as a string
					cellEditorSelector: params => {

						if (params.data.last_fleet_availability === 'Other') {
							return {
								component: "agTextCellEditor",
							}
						}

						if (params.data.last_fleet_availability === 'Fleet Master') {
							console.log(this.state.totallastmilevehicledetails, 'totallastmilevehicledetails', this.state.lastmilevehiclenolist);

							const filteredVehicleNumbers = this.state.totallastmilevehicledetails
								.filter(ele => ele.vehicle_type === params.data.vehicle_type_from_lastmile)
								.map(vehicle => vehicle.vehicle_no);

							return {
								component: 'agSelectCellEditor',
								params: {
									values: ["(Blank)", ...filteredVehicleNumbers]
								}
							};
						}

						return undefined;


					},
					valueSetter: (params) => { // Use an arrow function here
						params.data.vehicle_no_from_lastmile = params.newValue == "(Blank)" ? "" : params.newValue
						const newValue = params.newValue;
						const data = params.data;

						//   console.log('hello', newValue, '---', data);
						var params = {
							rowId: params.data._id,
							vehicle_no_from_lastmile: newValue,
							type: "Vehicle No"
						};

						if (newValue != "") {
							try {
								redirectURL.post("/consignments/updatinglastmilelegsdata", params);
								// this.initComponent();
							} catch (error) {
								console.error(error);
							}
						}
						return false;
					}
				},
				
				{
					headerName: "POD-Last Mile*",
					field: "pod_status_value_last_tmile",
					width: 150,
					// hide:this.state.istransporter,
					resizable: true,
					filter: true,
					// filterFramework: CustomTextFilter, // Use your custom filter component
					// filterParams: {
					// 	onFilterChanged: this.onFilterChanged,
					// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
					// },
					editable: ((params) =>
						([undefined, "", null].includes(localStorage.getItem("hub_transporter_code"))
							&& localStorage.getItem("user_type") == "TRANSPORTER" || ((this.props.match.path == "/prtallconsignmentshub")
								|| (this.props.match.path == "/prtdeliveredconsignmentshub"))) ? false : ((this.state.transporterhub == "activet") ? false : true)),
					// hide: function (params) {

					// 	return params.data.status >= 4;
					//   },
					// hide:params.data.status >= Number(4),
					cellEditor: 'agSelectCellEditor',
					cellEditorParams: {
						values: ["(Blank)", "Ok", "Not Ok"],
					},
					valueGetter: function (params) {
						const podStatusValue = params.data.pod_status_value_last_tmile;

						if (podStatusValue === "") {
							return " "; // Display an empty string
						} else if (podStatusValue === 0) {
							return "Ok";
						} else if (podStatusValue === 1) {
							return "Not Ok";
						} else {
							return podStatusValue; // Display the actual value if it's not empty, 0, or 1
						}
					},
					valueSetter: (params) => {
						// params.data.pod_status_value_last_tmile = params.newValue
						this.setState({
							rowIndexcount: params.node.rowIndex,
							commentValuelastmile: ''
						})
						// console.log("IN ValyeSetter, data = ",params.newValue,'---');
						if (params.data.pod_status_value_last_tmile !== params.newValue) {
							if (params.newValue === "Not Ok") {
								params.newValue = 1;

								// Set the slider and overlay when "Not Ok"
								this.setState({
									samplelastmilepod: JSON.parse(params.newValue),
									commentValuelastmile: params.data.pod_comment_lastmile,
									idRowlast: params.data._id,
									bulksliderforcommentlast: "slider-translate",
									overly: 'show-m'
								});
							}
							else if (params.newValue === "Ok") {
								params.newValue = 0;
								params.data.pod_comment_lastmile = ""
								params.data.pod_status_value_last_tmile = params.newValue;
								params.data.file_pod_last = undefined
								let layoutdataRole = {
									pod_status_value_last_tmile: JSON.parse(params.newValue),
									ID: params.data._id,
									pod: '2nd_mile'
								}
								redirectURL.post('/consignments/prtconsignmentshublegsdataPODEdit', layoutdataRole, {
									headers: {
										"Access-Control-Allow-Origin": "*",
										"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
									}
								})
									.then((response) => {

									})
									.catch(function (error) {
										console.log(error);
									});
								try {
									this.gridApi.redrawRows();
								} catch (e) {

								}
								return true;
							}
							else if (params.newValue === "(Blank)") {
								// params.newValue = 0;
								params.data.pod_comment_lastmile = ""
								params.data.pod_status_value_last_tmile = "";
								params.data.file_pod_last = undefined
								let layoutdataRole = {
									pod_status_value_last_tmile: "(Blank)",
									ID: params.data._id,
									pod: '2nd_mile'
								}
								redirectURL.post('/consignments/prtconsignmentshublegsdataPODEdit', layoutdataRole, {
									headers: {
										"Access-Control-Allow-Origin": "*",
										"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
									}
								})
									.then((response) => {

									})
									.catch(function (error) {
										console.log(error);
									});
								try {
									this.gridApi.redrawRows();
								} catch (e) {

								}
								return true;
							}
							else {
							}
							this.gridApi.redrawRows();

							return true;
						}
					}

				},
				{
					headerName: "Last Mile Comment",
					field: "pod_comment_lastmile",
					width: 180,
					filter: true,
					// filterFramework: CustomTextFilter, // Use your custom filter component
					// filterParams: {
					// 	onFilterChanged: this.onFilterChanged,
					// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
					// },
					resizable: true,
					// hide:this.state.istransporter,
					cellRenderer: function (params) {
						if (params.data.pod_status_value_last_tmile === 1) {
							return params.data.pod_comment_lastmile;
						} else {
							return "";
						}
					},
				},
				{
					headerName: 'POD Last Mile Download',
					field: 'file_pod_last',
					// hide:this.state.istransporter,
					width: 180, // Use the desired width
					cellRenderer: function (params) {
						//   console.log((params.data.pod_file_lastmile),'pod_file_lastmile')
						if (params.data.pod_status_value_last_tmile != 0) {
							if (params.data.file_pod_last) {
								var htmloption = '<a class="custom-btn label label-success"><i class="fa fa-download"></i> Download</a>';
								return htmloption;
							} else {
								return '';
							}
						} else {
							return ""
						}
					}
				},
				{
					headerName: 'Reporting time at consignee*',
					field: 'reporting_time_at_consignees_from_lastmile',
					width: 150,
					filter: true,
					// filterFramework: CustomTextFilter, // Use your custom filter component
					// filterParams: {
					// 	onFilterChanged: this.onFilterChanged,
					// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
					// },
					resizable: true,
					editable: ((params) =>
						([undefined, "", null].includes(localStorage.getItem("hub_transporter_code"))
							&& localStorage.getItem("user_type") == "TRANSPORTER" || ((this.props.match.path == "/prtallconsignmentshub") ||
								(this.props.match.path == "/prtdeliveredconsignmentshub"))) ? false : ((this.state.transporterhub == "activet") ? false : true)),
					// hide:this.state.istransporter,

					cellEditor: DateEditor,
					valueGetter: (param) => {
						if (param.data.reporting_time_at_consignees_from_lastmile !== "") {
							// console.log(param.data.reporting_time_at_consignees_from_lastmile, "8835")
							let arrival_time = param.data.reporting_time_at_consignees_from_lastmile;
							if (typeof (arrival_time) == "string") {
								if (!arrival_time.includes("T") && (arrival_time.includes("-") || arrival_time.includes("/"))) {
									let splitArray = arrival_time.split(" ");
									let splitDate = ""
									if (arrival_time.includes("-")) {
										splitDate = splitArray[0].split("-")
									} else if (arrival_time.includes("/")) {
										splitDate = splitArray[0].split("/")
									}
									if (splitDate != "" && splitDate[0].length < 4) {
										arrival_time = splitDate[2] + "/" + splitDate[1] + "/" + splitDate[0] + " " + splitArray[1]
									}
								}
							}
							return getHyphenDDMMMYYYYHHMM(arrival_time);
						} else {
							return "";
						}
					},
					comparator: dateComparator1,
					valueSetter: async (params) => {
						const newValue = params.newValue;
						//   console.log(moment.parseZone(new Date(newValue)).utcOffset("+00:00")._d > moment.parseZone(new Date(params.data.dispatch_time_from_hub_from_lastmile)).utcOffset("-05:30")._d,"9326")
						//   console.log(params.data.dispatch_time_from_hub_from_lastmile,"9327 dispatch")
						var dispatch_time_from_hub_from_lastmile = params.data.dispatch_time_from_hub_from_lastmile
						if (dispatch_time_from_hub_from_lastmile != undefined && dispatch_time_from_hub_from_lastmile != "" && dispatch_time_from_hub_from_lastmile != null && dispatch_time_from_hub_from_lastmile != "undefined") {
							if (dispatch_time_from_hub_from_lastmile.includes("T")) {
								var split_String = dispatch_time_from_hub_from_lastmile.split("T")
								let date = split_String[0]
								let time = split_String[1].split(".")[0]
								dispatch_time_from_hub_from_lastmile = date + " " + time
							}
						}
						
						var check_date_time = newValue.split(" ")
						var check_date = check_date_time[0]
						var check_time = check_date_time[1]+":00"
						var final_time = check_date+" "+check_time
						// console.log(final_time,"6734")

						var split_String_gateOut = params.data.gate_out_time.split("T")
								let date = split_String_gateOut[0]
								let time = split_String_gateOut[1].split(".")[0]
							var	gate_out_time1 = date + " " + time
					
						// var split_String_dispatch = params.data.dispatch_time_from_hub_from_lastmile.split("T")
						// 		let date1 = split_String_dispatch[0]
						// 		let time1 = split_String_dispatch[1].split(".")[0]
						// 	var	dispatch_time_from_hub_from_lastmile1 = date1 + " " + time1
					
						if (moment.parseZone(new Date(final_time)).utcOffset("+05:30")._d > moment.parseZone(new Date(dispatch_time_from_hub_from_lastmile)).utcOffset("+05:30")._d) {
							// console.log("check-9334")
							// params.data.reporting_time_at_consignees_from_lastmile = params.newValue

							var reporting_time_at_consignees_from_lastmile = (moment.parseZone(new Date(final_time)).utcOffset("+05:30")._d)
							// console.log(params.data.gate_out_time,'gateOutTime')
							var gate_out_time = (moment.parseZone(new Date(gate_out_time1)).utcOffset("+05:30")._d)

							const timeDiff = reporting_time_at_consignees_from_lastmile - moment.parseZone(new Date(dispatch_time_from_hub_from_lastmile)).utcOffset("+05:30")._d;
							// console.log(timeDiff, 'timeDiff', reporting_time_at_consignees_from_lastmile, '===', dispatch_time_from_hub_from_lastmile) // Difference in milliseconds
							const timeDiff2 = reporting_time_at_consignees_from_lastmile - gate_out_time; // Difference in milliseconds
							const hours = Math.floor(timeDiff / (1000 * 60 * 60)); // Convert milliseconds to hours
							const hours2 = Math.floor(timeDiff2 / (1000 * 60 * 60)); // Convert milliseconds to hours
							const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
							const minutes2 = Math.floor((timeDiff2 % (1000 * 60 * 60)) / (1000 * 60));
							var transit_time_last_mile = hours + "." + minutes
							var transit_overall_time = hours2 + "." + minutes2

							var query = {
								rowId: params.data._id,
								//   truck_no : data.truck_no,
								reporting_time_at_consignees_from_lastmile: newValue,
								gate_out_time: getHyphenYYYYMMDDHHMMSS(params.data.gate_out_time),
								dispatch_time_from_hub: getHyphenYYYYMMDDHHMMSS(params.data.dispatch_time_from_hub_from_lastmile),

								//   transporter_code : data.transporter_code
								type: "Reporting time at consignees"
							}
							query["transit_time_last_mile"] = transit_time_last_mile
							query["transit_overall_time"] = transit_overall_time
							// console.log(transit_overall_time, 'transit_overall_time', transit_overall_time > 48,'===++',transit_time_last_mile)

							

							if (transit_overall_time > 48) {
								console.log("above 48 hours first")
								this.setState({
									remarkCheck : false
								})
								
								for (const hub of this.state.totalhubData) {
									// Split hub_serving_city into an array of cities
									const servingCities = hub.hub_serving_city.split(',');
									
									if (
										servingCities.includes(params.data.consignee_city) &&
										hub.hub_consigner_code === params.data.consigner_code
									) {
										// console.log("hello in main grid");
										this.setState({
											remarkCheck: true
										});
										break;
									}
								}
								if(this.state.remarkCheck === true){
									$("#remark_from_overall_transit_time_main_grid").val("")
								this.setState({
									remark_for_over_time_main_grid: "slider-translate",
									overly: 'show-m',
									rowIndexcount: params.node.rowIndex,
									collecting_total_query_main_grid: query,
								});
								}else {
									$("#remark_from_overall_transit_time_main_grid").val("")
								this.setState({
									remark_for_over_time_main_grid: "slider-translate",
									overly: 'show-m',
									rowIndexcount: params.node.rowIndex,
									collecting_total_query_main_grid: query,
								})

								params.data.reporting_time_at_consignees_from_lastmile = params.newValue
								// console.log('inside less then 48----')
								if (newValue != "") {
									try {
										redirectURL.post("/consignments/updatinglastmilelegsdata", query).then(response => {
											// console.log(response.data,response.data.transit_time_last_mile,response.data.transit_overall_time,"7472")
											params.data.transit_last_mile = response.data.transit_time_last_mile
											params.data.transit_overall_time = response.data.transit_overall_time
											this.gridApi.redrawRows();
											return true;
										});


										// this.initComponent();
									} catch (error) {
										console.error(error);
									}
								}
								}
								
							} else {
								params.data.reporting_time_at_consignees_from_lastmile = params.newValue
								console.log('inside less then 48----')
								if (newValue != "") {
									try {
										redirectURL.post("/consignments/updatinglastmilelegsdata", query).then(response => {
											// console.log(response.data,response.data.transit_time_last_mile,response.data.transit_overall_time,"7472")
											params.data.transit_last_mile = response.data.transit_time_last_mile
											params.data.transit_overall_time = response.data.transit_overall_time
											this.gridApi.redrawRows();
											return true;
										});


										// this.initComponent();
									} catch (error) {
										console.error(error);
									}
								}
							}
						}
						else {
							if (![undefined, null, ""].includes(newValue) && ![undefined, null, ""].includes(dispatch_time_from_hub_from_lastmile)) {
								this.setState({
									show: true,
									remarkCheck:false,
									basicTitle: 'Reporting Time should be greater than Dispatch Time from Hub',
									basicType: "danger",
									loadshow: 'show-n'
								});
							}
						}
					}
				},
				{
					headerName: 'Delivery time at Consignee*',
					field: 'delivery_time_from_lastmile',
					width: 150,
					resizable: true,
					filter: true,
					// filterFramework: CustomTextFilter, // Use your custom filter component
					// filterParams: {
					// 	onFilterChanged: this.onFilterChanged,
					// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
					// },
					editable: ((params) =>
						([undefined, "", null].includes(localStorage.getItem("hub_transporter_code"))
							&& localStorage.getItem("user_type") == "TRANSPORTER" || ((this.props.match.path == "/prtallconsignmentshub")
								|| (this.props.match.path == "/prtdeliveredconsignmentshub"))) ? false : ((this.state.transporterhub == "activet") ? false : true)),
					// hide:this.state.istransporter,

					cellEditor: DateEditor,
					valueGetter: (param) => {
						if (param.data.delivery_time_from_lastmile !== "") {
							// console.log(param.data.delivery_time_from_lastmile, "8835")
							let arrival_time = param.data.delivery_time_from_lastmile;
							if (typeof (arrival_time) == "string") {
								if (!arrival_time.includes("T") && (arrival_time.includes("-") || arrival_time.includes("/"))) {
									let splitArray = arrival_time.split(" ");
									let splitDate = ""
									if (arrival_time.includes("-")) {
										splitDate = splitArray[0].split("-")
									} else if (arrival_time.includes("/")) {
										splitDate = splitArray[0].split("/")
									}
									if (splitDate != "" && splitDate[0].length < 4) {
										arrival_time = splitDate[2] + "/" + splitDate[1] + "/" + splitDate[0] + " " + splitArray[1]
									}
								}
							}
							return getHyphenDDMMMYYYYHHMM(arrival_time);
						} else {
							return "";
						}
					},
					comparator: dateComparator1,
					valueSetter: (params) => {
						// const rowData = params.api.getRowData();
						// console.log(rowData, "rowData")
						const newValue = params.newValue;
						var reporting_time_at_consignees_from_lastmile = params.data.reporting_time_at_consignees_from_lastmile
						console.log(reporting_time_at_consignees_from_lastmile, "9645-1", newValue, "9645")
						if (reporting_time_at_consignees_from_lastmile != undefined && reporting_time_at_consignees_from_lastmile != null && reporting_time_at_consignees_from_lastmile != "" && reporting_time_at_consignees_from_lastmile != "undefined") {
							if (reporting_time_at_consignees_from_lastmile.includes("T")) {
								var split_String = reporting_time_at_consignees_from_lastmile.split("T")
								let date = split_String[0]
								let time = split_String[1].split(".")[0]
								reporting_time_at_consignees_from_lastmile = date + " " + time
							}
						}

						//   console.log("9408",reporting_time_at_consignees_from_lastmile)
						//   console.log("9409",(moment.parseZone(new Date(newValue)).utcOffset("+00:00")._d > moment.parseZone(new Date(reporting_time_at_consignees_from_lastmile)).utcOffset("+00:00")._d))
						//   console.log("9410",params.data.reporting_time_at_consignees_from_lastmile)
						if (moment.parseZone(new Date(newValue)).utcOffset("+00:00")._d >= moment.parseZone(new Date(reporting_time_at_consignees_from_lastmile)).utcOffset("+00:00")._d) {
							console.log("check-94")
							// params.data.delivery_time_from_lastmile = params.newValue
							const data = params.data;
							// console.log('helllllo',newValue,'---',data.truck_no);
							var query = {
								rowId: params.data._id,
								truck_no: params.data.original_truck_no,
								consignment_code: params.data.consignment_code,
								delivery_time_from_lastmile: newValue,
								reporting_time: params.data.reporting_time_at_consignees_from_lastmile,
								gate_out_time: getHyphenYYYYMMDDHHMMSS(params.data.gate_out_time),
								dispatch_time_from_hub: getHyphenYYYYMMDDHHMMSS(params.data.dispatch_time_from_hub_from_lastmile),
								// transporter_code : data.transporter_code
								type: 'Delivery time'
							}

							// console.log(params,'params----')
							if (newValue != "") {
								params.data.delivery_time_from_lastmile = params.newValue
								try {
									redirectURL.post("/consignments/updatinglastmilelegsdata", query).then((response) => {

										params.data.transit_overall_time = response.data.transit_overall_time

										this.gridApi.redrawRows();
										return true
									});
									// this.initComponent();
								} catch (error) {
									console.error(error);
								}
							}

							// return false;
						} else {
							if (![undefined, null, ""].includes(newValue) && ![undefined, null, ""].includes(reporting_time_at_consignees_from_lastmile)) {
								this.setState({
									show: true,
									basicTitle: 'Delivery Time at Consignee Should be greater than or equal to Reporting Time at Consignee',
									basicType: "danger",
									loadshow: 'show-n'
								});
							}

						}
					}

				},
				
				{
					headerName: "Transit time-1st Mile(Hrs.Min)",
					field: "transitTime1stMile",
					width: 150,
					filter: false,
					// filterFramework: CustomTextFilter, // Use your custom filter component
					// filterParams: {
					// 	onFilterChanged: this.onFilterChanged,
					// 	uniqueValues: this.getColumnUniqueValues("transporter_code") // Pass the onFilterChanged method
					// },
					resizable: true,
					// hide:this.state.istransporter,
					valueGetter: (param) => {
						// console.log(param.data.transitTime1stMile,"7642")
						if (param.data.transitTime1stMile !== "") {
							return param.data.transitTime1stMile;
						} else {
							return "";
						}
					},
				},
				{
					headerName: "Transit time-Last Mile(Hrs.Min)",
					field: "transit_last_mile",
					width: 150,
					resizable: true,
					filter: false,
				},
				{
					headerName: "Overall transit time(Hrs.Min)",
					field: "transit_overall_time",
					width: 150,
					resizable: true,
					filter: false,
				},
				{
					headerName: "Remark",
					field: "remark_for_overall_transit_time_delay_main_grid",
					width: 150,
					resizable: true,
					filter: false,
					editable: (params) => {
						console.log(params.data.remark_for_overall_transit_time_delay_main_grid, "3518")
						var checkForRemarkEdit = false
						if (params.data.remark_for_overall_transit_time_delay_main_grid.length != 0 &&
							params.data.remark_for_overall_transit_time_delay_main_grid != undefined) {
							checkForRemarkEdit = true
						}
						return ([undefined, "", null].includes(localStorage.getItem("hub_transporter_code"))
							&& localStorage.getItem("user_type") == "TRANSPORTER" ||
							((this.props.match.path == "/prtallconsignmentshub") ||
								(this.props.match.path == "/prtdeliveredconsignmentshub"))) ? false : ((this.state.transporterhub == "activet") ? false : checkForRemarkEdit)


					},
					valueGetter: function (params) {
						if (params.data.transit_overall_time > 48) {
							return params.data.remark_for_overall_transit_time_delay_main_grid
						} else {
							params.data.remark_for_overall_transit_time_delay_main_grid = ''
							return ""
						}
					},
					valueSetter: (params) => {
						params.data.remark_for_overall_transit_time_delay_main_grid = params.newValue
						// console.log(params,'params',params.data)
						const remark_for_overall_transit_time_delay_main_grid = params.newValue;
						const data = params.data;
						var params = {
							rowId: params.data._id,
							remark_for_overall_transit_time_delay_main_grid: remark_for_overall_transit_time_delay_main_grid,
							type: "Edit for Remark"
						}
						// console.log(params,'params for backend')
						if (remark_for_overall_transit_time_delay_main_grid != "") {
							try {
								redirectURL.post("/consignments/updatinglastmilelegsdata", params);
								// this.initComponent();
							} catch (error) {
								console.error(error);
							}
						}
						return false;
					}

				},

				{
					headerName: 'Show last Mile Trucks',
					field: 'show_last_mile_trucks',
					width: 130,
					resizable: true,
					editable: false,
					// pinned:"right",
					cellRenderer: (params) => {
						if (params.data.additional_last_mile_truck || this.state.last_mile_consignments.includes(params.data.consignment_code)) {
							var htmloption = '<a class="custom-btn label label-success"><i class="icofont icofont-site-map cus-i f20"></i></a>';
							return htmloption;
						}
						return ""


					}
				},

			];
		if (check_edit_details == true && ((localStorage.getItem("user_type") == "TRANSPORTER" && ([undefined, "", null].includes(localStorage.getItem("hub_transporter_code")) == false)) || localStorage.getItem("user_type") != "TRANSPORTER")) {
			columnwithDefs.splice(19, 0, {
				headerName: 'Additional last mile truck',
				field: 'additional_last_mile_truck',
				width: 130,
				resizable: true,
				editable: false,
				cellRenderer: function (params) {
					if (params.data.dispatch_time_from_hub_from_lastmile) {
						
						var htmloption = '<button class="btn btn-info" style="padding-top: 0px"> ADD </button>';
						return htmloption;
					}
					return ""
				}
			},
			)
		}


		// console.log(this.state.commentValue,"commentValue")
		if (this.state.longHaulBtn == 'btn-danger') {
			columnwithDefs = this.state.columnsWithDefs
		}
		const dStyles = {
			width: this.props.width,
			height: '500px',
			marginLeft: this.props.marginLeft,
		}

		var { columnwithDefsForLastMile, openLastMileModal, rowDataForLastMile } = this.state
		// console.log(openLastMileModal,columnwithDefsForLastMile,rowDataForLastMile,"8188")

		var hText = 'All';
		// var filterClassName = "form-group col-xl-6 col-lg-6";
		// var filterCardStyle1 = {};
		// var filterCardStyle2 = {padding:'10px 30px'};	
		var filterClassName = "form-group col-xl-12 col-lg-12";
		var filterCardStyle1 = { minHeight: "234px" };
		var filterCardStyle2 = { padding: '10px' };
		var mnFilterClass = { marginLeft: "0px", padding: "0px" };

		if (this.state.deptcode == 'LOG-PRT') {
			hText = 'Spare Parts';
			mnFilterClass = { marginLeft: "0px" };
		}
		const tripoptoins = [
			{ "value": "all", label: "All" },
			{ "value": "S", label: "Short Haul" },
			{ "value": "L", label: "Long Haul" }
		]

		var user_type = localStorage.getItem("user_type");
		var user_name = localStorage.getItem("username")
		return (
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}>
				</SweetAlert>

				<div className="col-xl-12 col-lg-12">
					<div className="f16" style={{ marginBottom: "10px" }}>
						<ul className="d-tabs">

							<li onClick={this.onClickTab.bind(this, "all")} className={"lttabs " + (this.state.all)}>
								<button type="button" className="btn">All</button>
							</li>

							<li onClick={this.onClickTab.bind(this, "intransit")} className={"lttabs " + (this.state.intransit)}>
								<button type="button" className="btn">In Transit</button>
							</li>

							<li onClick={this.onClickTab.bind(this, "delivered")} className={"lttabs " + (this.state.delivered)}>
								<button type="button" className="btn">Delivered</button>
							</li>
						</ul>
					</div>
				</div>


				<div className="row" style={{ marginBottom: "1%" }}>
					<div className={"col-xl-12 col-lg-12 " + this.state.showFiltersForLongHaulScreenTab}>
						<button onClick={this.reloadPageData.bind(this)} className="btn btn-info" style={{ float: "right" }}>Reset Filters</button>
					</div>
				</div>
				<div className="row">

					<div className={"col-xl-4 col-lg-4 " + this.state.showFiltersForLongHaulScreenTab}>
						<div className="card" style={filterCardStyle1}>
							<div className="row card-body" style={{ padding: "10px 30px" }}>
								<form className="row" onSubmit={this.getTruckConsignments.bind(this)}>
									<div className={filterClassName}>
										<label>Select Type</label>
										<Select
											className="border-radius-0"
											isMulti={false}
											id="selectedFilter"
											style={{ borderRadius: "0px" }}
											options={[
												{ label: "Truck", vaue: "Truck" },
												{ label: "Consignments", value: "Consignments" }
											]} required />
									</div>
									<div className={filterClassName}>
										<label className="reason">Truck / Consignment No</label>
										<input type="text" name="reason" autoComplete="off" class="form-control" id="inputTruckConsignment" onChange={this.handleChange} />
									</div>
									<div className="form-group col-xl-12 col-lg-12" style={{ textAlign: "left" }}>
										<button type="submit" className="btn btn-success">Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>

					{/* filter div for tnp part level details */}


					<div className={"col-xl-8 col-lg-8"}>
						<div className="card" style={filterCardStyle1}>
							<div className="row card-body" style={filterCardStyle2}>


								{(this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG') ?
									<div className="row col-xl-12 col-lg-12" style={mnFilterClass}>
										{(this.state.deptcode == 'LOG-PRT') ?

											<div className="form-group col-xl-2 col-lg-2 n-p-0">
												<label>Select Hub Location</label>
												<Select
													placeholder={"All"}
													closeMenuOnSelect={true}
													value={this.state.movementtype}
													className="border-radius-0"
													onChange={this.changeMovementArrayHandler.bind(this)}
													style={{ borderRadius: "0px" }}
													options={this.selectMovementTypeOptionsItems()}
													required
												/>
											</div>


											: ""}

										<div className="form-group col-xl-4 col-lg-4">
											<label>Select Consigner</label>
											<Select placeholder={"All"} closeMenuOnSelect={false} value={this.state.consigner} isMulti="true" className="border-radius-0" onChange={this.changeConsignerArrayHandler.bind(this)} style={{ borderRadius: "0px" }} options={this.selectConsignerOptionsItems()} required />

										</div>
										<div className="col-xl-3 col-lg-3 form-group">
											<label>From Date</label>

											<Datetime
												isValidDate={isValidDate}
												value={this.state.startDate}
												disableCloseOnClickOutside={false}
												closeOnSelect={true}
												inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off' }}
												dateFormat="YYYY-MM-DD"
												name="startDate"
												onChange={this.handlerStartDateTime.bind(this)} />
										</div>

										<div className="col-xl-3 col-lg-3 form-group">
											<label>To Date</label>

											<Datetime
												isValidDate={isValidDate}
												value={this.state.endDate}
												disableCloseOnClickOutside={false}
												closeOnSelect={true}
												inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off' }}
												dateFormat="YYYY-MM-DD"
												onChange={this.handlerEndDateTime.bind(this)} />
										</div>


									</div>
									: ""}

								<div className="row col-xl-12 col-lg-12" style={{ marginLeft: "0px", padding: "0px" }}>
									{(this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG') ?
										<div className="form-group col-xl-3 col-lg-3">
											<label>Hub Serving Cities</label>
											<Select
												className="border-radius-0"
												isMulti={true}
												id="selectedCity"
												style={{ borderRadius: "0px" }}
												value={this.state.flCity}
												onChange={this.newFilterservingCity.bind(this)}
												options={this.state.hubServingCities}
											/>
										</div>
										: ""}
									{((this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG') && (localStorage.getItem("user_type") != "TRANSPORTER")) ?
										<div className="form-group col-xl-3 col-lg-3">
											<label>Transporter</label>
											<Select
												className="border-radius-0"
												isMulti={false}
												id="selectedTransporter"
												style={{ borderRadius: "0px" }}
												value={this.state.flTransporter}
												onChange={this.newFilter.bind(this, "flTransporter")}
												options={this.state.filterTransporters}
											/>
										</div>
										: ""}
									{/* <div className="form-group col-xl-12 col-lg-12" style={{textAlign:"left"}}>
										<button type="submit"  className="btn btn-success">Submit</button> &nbsp;&nbsp;
										<button type="button" onClick={this.ClearNewFilter.bind(this)} className="btn btn-danger">Clear</button>
									</div> */}
								</div>
								<div className="row col-xl-12 col-lg-12" style={{ marginLeft: "0px" }}>
									<button type="button" className="btn btn-success" onClick={this.onGetConsigners.bind(this)}>Submit</button>
								</div>

							</div>
						</div>
					</div>
				</div>
				{
					<div className={this.state.showFiltersForLongHaulScreenTab}>
						{/* {console.log(this.state.countersjson,"this.state.countersjson",this.state.allRowData,'this.state.allRowData')} */}
						{(this.state.isCounterDisplay == 1) ?

							<Counters
								hide={hideonlyCol}
								context={this}
								countersjson={this.state.countersjson}
								unloadshow={hideonlyCol}
								countNoGPSData={this.state.countNoGPSData}
								countNoGPSDatalifeTime={this.state.countNoGPSDatalifeTime}
								gridData={this.state.allRowData}
								// gridData1={this.state.totaldataforcount} 
								deptcode={this.state.deptcode}
								triptype={this.state.tripvalue}
								counterScreen={this.state.counter_screen}
								path={this.props.match.path}
								returnConsignments={this.state.tnpReturnConsignmentsVisibilty}
								priority_counter_display={this.state.priority_counter_display}
								truckReportedDate={this.state.truckReportedDate.length}
							/>
							: ""}
					</div>
				}

				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header" style={{ display: 'flex' }} >
								<div>
									<h5>
										<i className="icofont icofont-vehicle-delivery-van cus-i"></i>
										{this.state.pagetitle}
										{this.state.counterTypelabelName
											? (this.state.counterTypelabelName == "All"
												? '  -  ALL Consignments'
												: '  -  ' + this.state.counterTypelabelName)
											: null}
									</h5>
								</div>

								<div>

									<div className="col-xl-12 col-lg-12">
										<div className="f16" style={{ marginBottom: "10px" }}>
											<ul className="d-tabs">
												{(localStorage.getItem("hub_transporter_code") != undefined) ?
													<li onClick={this.onClickTabShow.bind(this, "hubOwner")} className={"lttabs " + (this.state.hubOwner)}>
														<button type="button" className="btn">First + Last Mile locations</button>
													</li> : ''}
												{(localStorage.getItem("hub_transporter_code") != undefined) ?
													<li onClick={this.onClickTabShow.bind(this, "transporter")} className={"lttabs " + (this.state.transporterhub)}>
														<button type="button" className="btn">Only 1st mile locations</button>
													</li> : ''}
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div className="card-body pt-10px" >
								<div className="row">
									<div className={"col-xl-12 col-lg-12 mb-10p " + this.state.showFiltersForLongHaulScreenTab}>

										<span className="float-right" style={{ fontSize: "13px", fontWeight: "100" }}>


										</span>

									</div>
									<div className={"col-xl-12 mb-10p " + this.state.showFiltersForLongHaulScreenTab} >

										<span className="layoutbtns">
											<button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState}>
												<i className="icofont icofont-save"></i> Save Grid Layout
											</button>
											<button className="float-right custom-btn white btn-info" style={{ marginRight: "10px" }} onClick={this.resetState}>
												<i className="icofont icofont-refresh"></i> Reset Default Layout
											</button>
											<button className="float-right custom-btn white btn-success" style={{ marginRight: "10px" }} onClick={this.resetFilters}>
												Reset Filter
											</button>
											{/* <button type="button" className="float-right custom-btn btn-success" style={{ marginRight: "15px", gap: "10px", width: '100px', color: 'white' }}
												onClick={this.customExportGridAsExcel.bind(this)}
											>
												Export 
											</button> */}
										</span>
									</div>
								</div>

								<div id="hub-consignments-grid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
									<AgGridReact
										modules={this.state.modules}
										columnDefs={columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										// rowData={[]}
										enableCharts={true}
										enableRangeSelection={true}
										// autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										frameworkComponents={this.state.frameworkComponents}
										detailCellRendererParams={this.state.detailCellRendererParams}
										stopEditingWhenGridLosesFocus={true}
										enableCellChangeFlash={false}
										rowClassRules={this.state.rowClassRules}
										// onCellDoubleClicked={this.onRowClicked.bind(this)}
										gridOptions={{
											context: { componentParent: this }
										}}
										masterDetail={true}
										// onCellEditingStopped={this.onCellUpdateData.bind(this)}									
										rowSelection={this.state.rowSelection}
										onRowSelected={this.onRowSelection.bind(this)}
										suppressRowClickSelection={true}
										overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
										excelStyles={this.state.excelStyles}
										onCellClicked={this.onCellClicked}
										suppressRowTransform={true}
										suppressKeyboardNavigation={true}
										suppressNavigateToNextCellOnTab={true}
										suppressNavigateToNextCellOnEnter={true}
									// domLayout='autoHeight's
									/>


								</div>
							</div>
						</div>
					</div>

				</div>


				{this.state.sliderRouteTranslate != '' ?
					<div className={"slide-r " + (this.state.sliderRouteTranslate)} style={{ overflow: "auto" }}>
						<div className="slide-r-title">
							<h4>
								{(this.state.consignment_code)}
								<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>

							</h4>
						</div>
						<div className="slide-r-body" style={{ position: "relative" }}>

							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawMap
									context={this}
									rownode={this.state.rownode}
									mapFor={"consignment"}
									tolls={this.state.tolls}
									googleroutes={this.state.googelRoutes}
									truckno={this.state.maptruckno}
									dealer={this.state.dealer}
									mapinfo={this.state.mapinfo}
									deptcode={this.state.deptcode}
									defTransitCoords={this.state.defTransitCoords}
									baseRouteCoords={this.state.baseRouteCoords}
									geofence_coordinates={this.state.geofence_coordinates}
									parking_coordinates={this.state.parking_coordinates}
								/>
								{/*
								<div id="map" className="" style={{width:'100%',height:"70vh"}}></div>
								*/}
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											{(maptransporter != '') ?
												<div className="col route-block">
													<label className="sidebar-label">Transporter</label>
													<div>{(!isEmpty(maptransporter)) ? maptransporter : ""}</div>
												</div>

												: ""}

											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details)) ? getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time) : "-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details)) ? getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time) : "-"}</div>
											</div>

											<div className="col route-block">
												<label className="sidebar-label">GPS Distance</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details)) ? Math.round(this.state.mapinfo.route_details.distance_without_google / 1000) : "0"} Kms</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{Math.round(this.state.mapinfo.route_details.distance / 1000)} Kms</div>
											</div>





										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
					: ""
				}
				<div className={"dataLoadpage " + (this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " + (this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

				<Modal open={this.state.openLastMileModal} onClose={this.onCloseModal} classNames={{ overlay: 'customOverlay', modal: 'customModal', }}>
					<div id="myGrid" style={{ height: "580px", width: "100%", marginTop: "15px" }} className="ag-theme-balham">
						<AgGridReact
							columnDefs={columnwithDefsForLastMile}
							rowData={rowDataForLastMile}
							onCellClicked={this.onCellModalClicked}
							onGridReady={this.onGridReady2}
							onGridState={this.onGridState2}
							stopEditingWhenGridLosesFocus={true}
							statusBar={this.state.statusBar}
							sideBar={this.state.sideBar}
							paginationPageSize={this.state.paginationPageSize}
							pagination={true}
							suppressKeyboardNavigation={true}
							suppressNavigateToNextCellOnTab={true}
							suppressNavigateToNextCellOnEnter={true}
						// domLayout='autoHeight'
						// onCellEditingStopped={this.onCellUpdateData.bind(this)}									

						/>


					</div>
				</Modal>
				<div className={"slide-r " + (this.state.bulksliderforcomment)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>

							Upload POD File for First Mile
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>

					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="container-fluid">
							<form method="POST" id="form-pod-first-mile" className="theme-form" onSubmit={this.uploadPODfristmile}>
								<div className="form-group mt-20p">
									<label>Comment<span style={{ color: 'red' }}>*</span></label>
									<textarea name="commentValue" id="commentValue" value={this.state.commentValue} onChange={this.onChangeValue} className="form-control"
										required />
								</div>
								<div className="form-group mt-20p">
									<label>Upload File<span style={{ color: 'red' }}>*</span></label>
									<input type="file" name="uploadFile" id='uploadFile' onChange={this.changeFileHandlerPOD} className="form-control" required />
								</div>
								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
								</div>
							</form>

							{/* <div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/sample_upload_eway_expiry.csv')} target="_blank">Sample Template</a>
							
							</div>		 */}
						</div>
					</div>
				</div>


				<div className={"slide-r " + (this.state.bulksliderforcommentlast)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>

							Upload POD File for Last Mile
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>

					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="container-fluid">
							<form method="POST" id="form-pod-last-mile" className="theme-form" onSubmit={this.uploadPODlastmile}>
								<div className="form-group mt-20p">
									<label >Comment<span style={{ color: 'red' }}>*</span></label>
									<textarea name="commentValuelastmile" id="commentValuelastmile" value={this.state.commentValuelastmile} onChange={this.onChangelastmile} className="form-control" required />
								</div>
								<div className="form-group mt-20p">
									<label >Upload File<span style={{ color: 'red' }}>*</span></label>
									<input type="file" name="uploadFile" id='uploadFiletype' onChange={this.changeFileHandlerPODlast} className="form-control" required />
								</div>
								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
								</div>
							</form>

							{/* <div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/sample_upload_eway_expiry.csv')} target="_blank">Sample Template</a>
							
							</div>		 */}
						</div>
					</div>
				</div>
				<div className={"slide-r " + (this.state.bulksliderforcommentlastforadditionaltrucks)} style={{ overflow: "auto", zIndex: "9999" }}>
					<div className="slide-r-title">
						<h4>Upload POD File for Additional vehicles
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>

					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="container-fluid">
							<form method="POST" id="form-pod-additional-truck" className="theme-form" onSubmit={this.uploadPodForAdditionalLastMileTruck}>
								<div className="form-group mt-20p">
									<label >Comment<span style={{ color: 'red' }}>*</span></label>
									<textarea name="commentValuelastmile" id="commentValuelastmile" value={this.state.commentValuelastmile} onChange={this.onChangelastmile} className="form-control" required />
								</div>
								<div className="form-group mt-20p">
									<label >Upload File<span style={{ color: 'red' }}>*</span></label>
									<input type="file" name="uploadFile" id='uploadFiletype' onChange={this.changeFileHandlerPODlast} className="form-control" required />
								</div>
								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
								</div>
							</form>

							{/* <div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/sample_upload_eway_expiry.csv')} target="_blank">Sample Template</a>
							
							</div>		 */}
						</div>
					</div>
				</div>

				<div className={"slide-r " + (this.state.last_fleet_availabilityBulk)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>

							Enter Vehicle Number Details
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>

					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.lastmilemastervehicle.bind(this)}>

								<div className="form-group col-xl-12 col-lg-12" style={{ marginTop: '20px' }}>
									<label >Last Mile Vehicle No<span style={{ color: 'red' }}>*</span> :</label>
									<input name="lastmilemastervehicle" id="lastmilemastervehicle" className="form-control" autoComplete="off" required />
								</div>

								<div className="form-group col-xl-12 col-lg-12">
									<label >
										Last Mile Vehicle Type <span style={{ color: 'red' }}>*</span>:
									</label>
									<input
										// type="text"
										name="vehicle_type_from_lastmile"
										id="vehicle_type_from_lastmile"
										//  isMulti={true}
										//  closeMenuOnSelect={true} 
										value={this.state.vehicle_type_from_lastmile}
										className="form-control"
										// placeholder="Hub Serving Cities "
										// onKeyPress={this.handleKeyPressCodeTrans}
										onChange={this.handleChangeselectvehicletype}
										required
										options={this.state.lastmilevehicletypelist}
										autoComplete="off"
									/>

								</div>
								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									{/* <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button> */}
								</div>
							</form>

							{/* <div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/sample_upload_eway_expiry.csv')} target="_blank">Sample Template</a>
							
							</div>		 */}
						</div>
					</div>
				</div>
				<div className={"slide-r " + (this.state.additional_last_mile_truck_slider)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>Adding additional vehicles
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
						</h4>
					</div>

					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="container-fluid">
							<form id="upform" className="theme-form" onSubmit={this.onSubmitAdditionalLastMileTruck.bind(this)}>

								<div className="form-group col-xl-12 col-lg-12" style={{ marginTop: '20px' }}>
									<label >Last Mile Fleet Availability<span style={{ color: 'red' }}>*</span></label>
									<Select
										placeholder={"Select Menu"}
										closeMenuOnSelect={true}
										value={{ label: this.state.additional_last_mile_truck.last_fleet_availability, value: this.state.additional_last_mile_truck.last_fleet_availability }}
										className="border-radius-0"
										name="last_fleet_availability"
										onChange={this.onChangeAdditionalLastMileTruck.bind(this, "last_fleet_availability")}
										style={{ borderRadius: "0px" }}
										options={[
											{ label: "Fleet Master", value: "Fleet Master" },
											{ label: "Other", value: "Other" }

										]
										}

										required
									/>
								</div>
								<div className="form-group col-xl-12 col-lg-12" style={{ marginTop: '20px' }}>
									<label >Last Mile Vehicle Type<span style={{ color: 'red' }}>*</span></label>
									{this.state.additional_last_mile_truck.last_fleet_availability == "Fleet Master" ?
										<Select
											placeholder={"Select Vehicle Type"}
											closeMenuOnSelect={true}
											value={{
												label: this.state.additional_last_mile_truck.vehicle_type_from_lastmile,
												value: this.state.additional_last_mile_truck.vehicle_type_from_lastmile
											}}
											className="border-radius-0"
											name="vehicle_type_from_lastmile"
											onChange={this.onChangeAdditionalLastMileTruck.bind(this, "vehicle_type_from_lastmile_select")}
											style={{ borderRadius: "0px" }}
											options={this.state.lastmilevehicletypelist}

											required
										/> :
										<input
											value={this.state.additional_last_mile_truck.vehicle_type_from_lastmile}
											onChange={this.onChangeAdditionalLastMileTruck.bind(this, "vehicle_type_from_lastmile")}
											name="vehicle_type_from_lastmile"
											id="vehicle_type_from_lastmile"
											className="form-control"
											autoComplete="off"
											required />
									}

								</div>
								<div className="form-group col-xl-12 col-lg-12" style={{ marginTop: '20px' }}>
									<label >Last Mile Vehicle No<span style={{ color: 'red' }}>*</span></label>
									{
										this.state.additional_last_mile_truck.last_fleet_availability === "Fleet Master" ?
											<Select
												placeholder={"Select Vehicle No"}
												closeMenuOnSelect={true}
												value={{
													label: this.state.additional_last_mile_truck.vehicle_no_from_lastmile,
													value: this.state.additional_last_mile_truck.vehicle_no_from_lastmile
												}}
												className="border-radius-0"
												name="vehicle_no_from_lastmile"
												onChange={this.onChangeAdditionalLastMileTruck.bind(
													this,
													"vehicle_no_from_lastmile_for_select"
												)}
												style={{ borderRadius: "0px" }}
												options={
													this.state.additional_last_mile_truck.vehicle_type_from_lastmile
														? this.state.totallastmilevehicledetails
															.filter(each => each.vehicle_type === this.state.additional_last_mile_truck.vehicle_type_from_lastmile)
															.map(filtered => ({
																label: filtered.vehicle_no,
																value: filtered.vehicle_no
															}))
														: this.state.totallastmilevehicledetails.map(each => ({
															label: each.vehicle_no,
															value: each.vehicle_no
														}))
												}
												required
											/>
											:
											<input
												name="vehicle_no_from_lastmile"
												value={this.state.additional_last_mile_truck.vehicle_no_from_lastmile}
												onChange={this.onChangeAdditionalLastMileTruck.bind(this, "vehicle_no_from_lastmile")}
												onBlur={this.validateTruckNo.bind(this)}
												id="vehicle_no_from_lastmile"
												className="form-control"
												autoComplete="off"
												required />}
								</div>
								<div className="col-xl-12 col-lg-12 form-group">
									<label>CMT<span style={{ color: 'red' }}>*</span></label>
									<input type="text" name="last_cmt" id="last_cmt" className="form-control" value={this.state.last_cmt} onChange={this.validatelatitudeInput} autocomplete="off"
										required />
								</div>
								<div className="form-group col-xl-12 col-lg-12" style={{ marginTop: '20px' }}>
									<label >Dispatch Time From Hub (YYYY-MM-DD HH:MM)<span style={{ color: 'red' }}>*</span></label>
									<Datetime
										// isValidDate={this.isValidDate.bind(this)}
										isValidDate={isValidDate}
										inputProps={{
											name: 'dispatch_time_from_hub_from_lastmile',
											id: 'dispatch_time_from_hub_from_lastmile',
											autoComplete: 'off',
											className: 'fc-input form-control',
											// onKeyUp: this.onKeyPress.bind(this)
											onInput : this.onKeyPress.bind(this),
											onKeyDown: this.handleKeyDown.bind(this,"dispatch_time_from_hub_from_lastmile")
										}}
										value={getHyphenYYYYMMDDHHMM(this.state.additional_last_mile_truck.dispatch_time_from_hub_from_lastmile)}
										dateFormat="YYYY-MM-DD"
										timeFormat="HH:mm"
										onChange={this.handlerStartDateTimeNew.bind(this, "dispatch_time_from_hub_from_lastmile")}
										// onClose={()=>(console.log("closing"))}
										onBlur={this.validateDispatchTime.bind(this, "dispatch_time_from_hub_from_lastmile", "actual_arrival_at_hub")}
									/>

								</div>
								<div className="form-group col-xl-12 col-lg-12" style={{ marginTop: '20px' }}>
									<label >Reporting Time At Consignee (YYYY-MM-DD HH:MM)</label>
									<Datetime
										isValidDate={isValidDate}
										inputProps={{
											name: 'reporting_time_at_consignees_from_lastmile',
											id: 'reporting_time_at_consignees_from_lastmile',
											autoComplete: 'off',
											class: 'fc-input form-control',
											onInput : this.onKeyPress.bind(this),
											onKeyDown: this.handleKeyDown.bind(this,"reporting_time_at_consignees_from_lastmile")
										}}
										value={getHyphenYYYYMMDDHHMM(this.state.additional_last_mile_truck.reporting_time_at_consignees_from_lastmile)}

										dateFormat="YYYY-MM-DD"
										timeFormat="HH:mm"
										onChange={this.handlerStartDateTimeNew.bind(this, "reporting_time_at_consignees_from_lastmile")}
										// onClose={()=>(console.log("closing"))}
										onBlur={this.validateReportingTime.bind(this, "reporting_time_at_consignees_from_lastmile")}
									/>

								</div>
								{this.state.remark_for_delay_value_state === true ? 
								
								<div className="col-xl-12 col-lg-12 form-group">
									<label>Remark{this.state.remarkCheck && <span style={{ color: 'red' }}>*</span>}</label>
									<input type="text" 
									name="remark_for_delay_legs" 
									 id="remark_for_delay_legs" 
									 className="form-control"
									 required={this.state.remarkCheck}
										 />
								</div>
										 : ""}
								<div className="form-group col-xl-12 col-lg-12" style={{ marginTop: '20px' }}>
									<label >Delivery Time At Consignee (YYYY-MM-DD HH:MM)</label>
									<Datetime
										isValidDate={isValidDate}
										inputProps={{
											name: 'delivery_time_from_lastmile',
											id: 'delivery_time_from_lastmile',
											autoComplete: 'off',
											class: 'fc-input form-control',
											onInput : this.onKeyPress.bind(this),
											onKeyDown: this.handleKeyDown.bind(this,"delivery_time_from_lastmile")
										}}
										value={getHyphenYYYYMMDDHHMM(this.state.additional_last_mile_truck.delivery_time_from_lastmile)}

										dateFormat="YYYY-MM-DD"
										timeFormat="HH:mm"
										onChange={this.handlerStartDateTimeNew.bind(this, "delivery_time_from_lastmile")}
										onBlur={this.validateReportingTime.bind(this, "delivery_time_from_lastmile")}
									// onClose={()=>(console.log("closing"))}
									// onBlur={this.validateDispatchTime.bind(this,"dispatch_time_from_hub_from_lastmile","actual_arrival_at_hub")}
									/>
								</div>
								<div className="form-group col-xl-12 col-lg-12" style={{ marginTop: '20px' }}>
									<label >POD Last Mile</label>
									<Select
										placeholder={"Select Menu"}
										closeMenuOnSelect={true}
										value={{ label: this.state.additional_last_mile_truck.pod_status_value_last_tmile, value: this.state.additional_last_mile_truck.pod_status_value_last_tmile }}
										className="border-radius-0"
										name="last_fleet_availability"
										onChange={this.onChangeAdditionalLastMileTruck.bind(this, "pod_status_value_last_tmile")}
										style={{ borderRadius: "0px" }}
										options={[
											{ label: "Ok", value: "Ok" },
											{ label: "Not Ok", value: "Not Ok" }]
										}

										required
									/>
								</div>
								{this.state.additional_last_mile_truck.pod_status_value_last_tmile == "Not Ok" ?

									<>
										<div className="form-group col-xl-12 col-lg-12">
											<label >Last Mile Comment<span style={{ color: 'red' }}>*</span></label>
											<textarea name="commentValuelastmile" id="commentValuelastmile" value={this.state.commentValuelastmile} onChange={this.onChangelastmile} className="form-control" required />
										</div>
										<div className="form-group col-xl-12 col-lg-12">
											<label >Upload File<span style={{ color: 'red' }}>*</span></label>
											<input required type="file" name="uploadFile" id='uploadFiletype' onChange={this.changeFileHandlerPODlast} className="form-control" />
										</div>
									</>
									: <div></div>
								}

								<div className="form-group col-xl-12 col-lg-12">
									<button type="submit" className="btn btn-success">Submit</button>
									{/* <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button> */}
								</div>
							</form>

							{/* <div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/sample_upload_eway_expiry.csv')} target="_blank">Sample Template</a>
							
							</div>		 */}
						</div>
					</div>
				</div>

				<div className={"slide-r " + (this.state.remark_for_over_time_main_grid)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>Remark for Delay
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>

					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="container-fluid">
							<form method="POST" id="form-pod-additional-truck" className="theme-form"

								onSubmit={this.updatingremarkfromoveralltransitmaingrid.bind(this)}

							>
							<div className="form-group mt-20p">
								<label>Remark {this.state.remarkCheck && <span style={{ color: 'red' }}>*</span>}</label>
								<textarea
									name="remark_from_overall_transit_time_main_grid"
									id="remark_from_overall_transit_time_main_grid"
									// value={this.state.remark_from_overall_transit_time}
									// onChange={this.onChangelastmile}
									className="form-control"
									required
								/>
							</div>


								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>

								</div>
							</form>

						</div>
					</div>
				</div>

				<div className={"slide-r " + (this.state.remark_for_over_time)} style={{ overflow: "auto", zIndex: "9999" }}>
					<div className="slide-r-title">
						<h4>Remark for Delay
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>

					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="container-fluid">
							<form method="POST" id="form-pod-additional-truck" className="theme-form" onSubmit={this.updatingremarkfromoveralltransit.bind(this)}>
								<div className="form-group mt-20p">
									<label >Remark{this.state.remarkCheck && <span style={{ color: 'red' }}>*</span>}</label>
									<textarea name="remark_from_overall_transit_time" id="remark_from_overall_transit_time"
										// value={this.state.remark_from_overall_transit_time}
										//  onChange={this.onChangelastmile}
										className="form-control" required />
								</div>

								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>

								</div>
							</form>

						</div>
					</div>
				</div>



			</div>

		);
	}
}


function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key))
			return false;
	}
	return true;
}

var currentinfowindow = null;

function secondsToString(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}


function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName) {
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
	];
	var monthNum = months.indexOf(monthName) + 1;
	if (monthNum < 10) {
		monthNum = "0" + monthNum.toString();
	}
	return monthNum;
}

function loadDateTimeScript() {
	$('.datetimepicker_mask').datetimepicker({
		mask: '39-19-9999 29:59',
		format: 'd-m-Y H:i',
		onShow: false
	});
	$('.datetimepicker_date').datetimepicker({
		mask: '39-19-9999',
		format: 'd-m-Y',
		timepicker: false,
		onShow: false
	});
	var index = window.document.getElementsByTagName("script")[1]
	var script = window.document.createElement("script")
	script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}




function dateCheck(start, end) {
	let startDate = new Date(start);
	let endDate = new Date(end);

	if (startDate > endDate) return 1
	return 0
}

class ShowCellRenderer {
	init(params) {
		const cellBlank = !params.value;
		if (cellBlank) {
			return;
		}
	}
}
function dateComparator1(date1, date2) {
	//console.log(date1,date2);
	if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split("-")[1]);
		date1 = date1.replace(date1.split("-")[1], date_1);
		var date_2 = getMonthNumber(date2.split("-")[1]);
		date2 = date2.replace(date2.split("-")[1], date_2);
		var date1Number = monthToComparableNumber1(date1);
		var date2Number = monthToComparableNumber1(date2);
		//console.log(date1Number,date2Number);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		// console.log(date1Number, date2Number, "comparator")
		return date1Number - date2Number;
	}
}
function monthToComparableNumber1(date) {
	//console.log(date.length);
	//console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}



function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
	this.eInput = document.createElement('input');
	this.eInput.value = params.value;
	// Get today's date and format it as dd-mm-yy
	var today = new Date();
	let defaultTime = "";
	if (!["", null, undefined].includes(params.value)) {
		defaultTime = params.value.split(" ")[1]
	}

	// https://jqueryui.com/datepicker/
	$(this.eInput).datetimepicker({
		format: "Y-m-d H:i",
		changeMonth: true,
		changeYear: true,
		maxDate: today,
		defaultDate: this.originalValue ? new Date(this.originalValue) : null,
		defaultTime: defaultTime,
	});
};


// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
	return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
	this.eInput.focus();
	this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
	return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
	// but this example is simple, no cleanup, we could
	// even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
	// and we could leave this method out also, false is the default
	return false;
};


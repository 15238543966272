
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';


import Counters from '../layouts/consignmentkpicountersComponent';

import CountUp from 'react-countup';

import { AgGridReact } from "@ag-grid-community/react";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMM } from '../common/utils';
import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";

import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from "react-select";

import drilldown from 'highcharts-drilldown';

import HighchartsReact from 'highcharts-react-official';

import Highcharts from 'highcharts';

var redirectURL = require("../redirectURL");

export default class PendingPodsTVPTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
        basicTitle:'',
        basicType:"default",
      rowData: [],
      formattedLastMonth: '',
      formattedSecondToLastMonth: '',
      sidebartitle: "",
      sliderRouteTranslate: '',
      //non tvp states
      totalpendingcount: 0,
      seclastmonthcounter: 0,
      lastmonthcounter: 0,
      totalData: [],
      totalSumedUpValues: [],
    
      graphsData:[],
      overly: "show-n",
      loadshow: "show-n",
      filterType: 'All',
      facility: "All Facilities",
      facilitiesList: [{ "label": "All Facilities", "value": "All" },
      // { "label": "Haryana", "value": "H" },
      // { "label": "Bidadi", "value": "BP" }
      //   , { "label": "Gujarat", "value": "G" },
        { "label": "TVP-B", "value": "B" },
        { "label": "TVP-N", "value": "N" },
        { "label": "TVP-S", "value": "I" }
      ],
      //tvp states
   
      nontvptab:'',
      tvptab:"activet",
    }
    this.filterDataByCategory = this.filterDataByCategory.bind(this);
  }





  componentDidMount() {
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    console.log("Transporter Codetvp:", this.props.transporterCode);
    var transporterCode  = this.props.transporterCode;
    this.setState({
      transporter_code: transporterCode
    });

    
    var params2 = {
      transporter_code: transporterCode,
      source: "ALL"
    };

    redirectURL
      .post("/consignments/fetchTvpPendingPodsTripData", params2)
      .then((response) => {
      
        this.setState({
          totalpendingcount: response.data.data.tvp_total_pending_pods || 0,
          lastmonthcounter: response.data.data.tvp_curr_month_pending_pods || 0,
          seclastmonthcounter: response.data.data.tvp_last_month_pending_pods || 0,
          totalData: response.data.data || [],
          graphsData: response.data.data.tvp_graph_data,
          totalSumedUpValues: (response.data.data.tvp_graph_data) || [],
          loadshow: "show-n",
          overly: "show-n",
        })
     
    })
    

  }



  newFilter = (event) => {
    this.setState({
      filterType: event.value
    })

    var plantCode;
    // if (event.value == "H") {
    //   plantCode = "HARYANA"
    // } else if (event.value == "BP") {
    //   plantCode = "BIDADI"
    // } else if (event.value == "G") {
    //   plantCode = "GUJARAT"
    // }
     if (event.value == "B") {
      plantCode = "TVP BANGALORE"
    } else if (event.value == "N") {
      plantCode = "TVP NAGPUR"
    } else if (event.value == "I") {
      plantCode = "TVP SILIGURI"
    } 
    else if (event.value == "All") {
      plantCode = "TOTAL"
    }


    var params = {
      transporter_code: this.state.transporter_code,
      source: (event.value != "All" ? event.value : "ALL")
    };
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });

    redirectURL
      .post("/consignments/fetchTvpPendingPodsTripData", params)
      .then((response) => {
        // console.log((response.data.data),'responseforcounters_tvp2')
        this.setState({
          totalpendingcount: response.data.data.tvp_total_pending_pods || 0,
          lastmonthcounter: response.data.data.tvp_curr_month_pending_pods || 0,
          seclastmonthcounter: response.data.data.tvp_last_month_pending_pods || 0,
          totalData: response.data.data || [],
          graphsData: response.data.data.tvp_graph_data,
          totalSumedUpValues: (response.data.data.tvp_graph_data) || [],
          loadshow: "show-n",
          overly: "show-n",
        })
      })

  
  }

  onClickCounter(data) {
    if (data == "totalpendingcount") {
      if (this.state.totalData.tvp_total_pending_pods_data !== undefined && this.state.totalData.tvp_total_pending_pods_data !== null) {
        this.setState({
          rowData: JSON.parse(this.state.totalData.tvp_total_pending_pods_data),
          sidebartitle: "Total Pending PODs",
          sliderRouteTranslate: 'slider-translate-75p',
          loadshow: 'show-n',
          overly: 'show-m'
        })
      }else
        {
          this.setState({
            show:true,
            sidebartitle:"",
            sliderRouteTranslate:'',
            basicTitle:"No data to show",
            basicType:"warning",
            loadshow: 'show-n',
            overly: 'show-n'
        })
        
      }
    } else if (data == "lastmonthcounter") {
      if (this.state.totalData.tvp_curr_month_pending_pods_data !== undefined && this.state.totalData.tvp_curr_month_pending_pods_data !== null) {
        this.setState({
          rowData: JSON.parse(this.state.totalData.tvp_curr_month_pending_pods_data),
          sidebartitle: "Pending PODs in " + getNextMonthName() + " Month",
          sliderRouteTranslate: 'slider-translate-75p',
          loadshow: 'show-n',
          overly: 'show-m'
        })
      }else
        {
          this.setState({
            show:true,
            sidebartitle:"",
            sliderRouteTranslate:'',
            basicTitle:"No data to show",
            basicType:"warning",
            loadshow: 'show-n',
            overly: 'show-n'
        })
        
      }

    } else if (data == "seclastmonthcounter") {

      if (this.state.totalData.tvp_last_month_pending_pods_data !== undefined && this.state.totalData.tvp_last_month_pending_pods_data !== null) {
        this.setState({
          rowData: JSON.parse(this.state.totalData.tvp_last_month_pending_pods_data),
          sidebartitle: "Pending PODs in " + getPreviousMonthName() + " Month",
          sliderRouteTranslate: 'slider-translate-75p',
          loadshow: 'show-n',
          overly: 'show-m'
        })
      }else
        {
          this.setState({
            show:true,
            sidebartitle:"",
            sliderRouteTranslate:'',
            basicTitle:"No data to show",
            basicType:"warning",
            loadshow: 'show-n',
            overly: 'show-n'
        })
        
      }
    }
  }

  handleDrilldown = (params) => {
    console.log("Here ,", params)
  }

  onCloseRouteDiv = () => {
    this.setState({
      uploadDivWidth: '0%',
      sliderRouteTranslate: "",
      sliderCriticalTranslate: '',
      showDiv: 'show-n',
      uploadFile: '',
      file: '',
      csvmessage: '',
      csverrmessage: '',
      loadshow: "show-n",
      overly: "show-n",
    });
  }

  filterDataByCategory(seriesName) {
    let filteredData;
    if (seriesName === 'Less than or equal to 30 days') {
      filteredData = this.state.totalSumedUpValues.less_than_30_days_data
      console.log(filteredData,'filteredData1')
    } else if (seriesName === 'Greater than 30 days') {
      filteredData = this.state.totalSumedUpValues.greater_than_30_days_data
      // console.log(filteredData,'filteredData2')
    }

    console.log('Filtered Data:', filteredData);
  


        if (filteredData !== undefined && filteredData !== null && filteredData.length > 0) {
          this.setState({
            rowData: JSON.parse(filteredData) || [],
            sidebartitle: "TVP Pending POD",
            sliderRouteTranslate: 'slider-translate-75p',
            loadshow: 'show-n',
            overly: 'show-m'
          })
        }
          else{
            this.setState({
              show:true,
              sliderRouteTranslate:'',
              basicTitle:"No data to show",
              basicType:"warning",
              loadshow: 'show-n',
              overly: 'show-n'
          })
          
        }
  
    } 
  
  closeAlert = () => {
    this.setState({
        show: false
    });
}

  render() {

    const counterCoulumDef = [
      {
        headerName: "Consignment Code",
        field: "consignment_code",
        filter: true,
        resizable: true,
      },
      {
        headerName: "Truck Number",
        field: "truck_no",
        filter: true,
        resizable: true,
      },
      {
        headerName: "Gate Out Time",
        field: "gate_out_time",
        filter: true,
        resizable: true,
        valueGetter: function (params) {
          //  console.log(params.data.gate_out_time,'params.data.gate_out_time')
          if (params.data.gate_out_time != '' && params.data.gate_out_time != undefined) {
            return getDDMMYYYY(params.data.gate_out_time)
          }
          else {
            return "";
          }

        }

      },


      {
        headerName: "Dealer Name",
        field: "consignee_name",
        filter: true,
        resizable: true,
      },
      {
        headerName: "Trip Location",
        field: "consignee_city",
        filter: true,
        resizable: true,
      },

    ]

    const dStyles = {
      width: '100%',
      height: '100%'
    }
    const { totalSumedUpValues } = this.state;
    var greaterThan30 = (totalSumedUpValues.greater_than_30_days_count >= 0 ? totalSumedUpValues.greater_than_30_days_count:0);
    var lessThan30 = (totalSumedUpValues.less_than_30_days_count >= 0 ? totalSumedUpValues.less_than_30_days_count:0)
// console.log(totalSumedUpValues,'totalSumedUpValues')
    // const monthCategories = [];
    // const greaterThan30DaysData = [];
    // const lessThanOrEqual30DaysData = [];
    // const totalData = [];

    // const arrayVersion = Array.from(totalSumedUpValues);
    // arrayVersion.forEach(ele => {
    //     monthCategories.push(ele.month);

    //     if (ele.month === "Apr-24" || ele.month === "May-24") {
    //         greaterThan30DaysData.push(ele.greater_than_30_days_count || 0);
    //         lessThanOrEqual30DaysData.push(ele.less_than_or_equal_30_days_count || 0);
    //     } else {
    //         greaterThan30DaysData.push(null); // Use null to skip this month in this series
    //         lessThanOrEqual30DaysData.push(null); // Use null to skip this month in this series
    //         totalData.push(ele.TOTAL || 0); // Push TOTAL count for other months
    //     }
    // });

var highchartoptions = {
  chart: {
      type: 'column'
  },
  title: {
      text: 'TVP Pending POD'
  },
  xAxis: {
      categories: ['Current Data'],
      crosshair: true
  },
  yAxis: {
      title: {
          text: 'Number of PODs'
      },
      min: 0,
      tickInterval: 50,
      minTickInterval: 1,
  },
  tooltip: {
      shared: false,
      useHTML: true,
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.0f} PODs</b></td></tr>',
      footerFormat: '</table>',
  },
  plotOptions: {
      column: {
          pointPadding: 0.2,
          borderWidth: 0,
          dataLabels: {
              enabled: true,
              format: '{point.y:.0f}'
          },
          cursor: 'pointer',
          point: {
            events: {
              click: function (event) {
                const category = event.point.category;
                const seriesName = event.point.series.name;
                const value = event.point.y;
            
                // console.log(`Month: ${category}, Series Name: ${seriesName}, Value: ${value}`);
                
                // Call filterDataByCategory function
                this.filterDataByCategory(seriesName);
            }.bind(this)
            }
        }
      }
  },
  series: [{
    name: 'Greater than 30 days',
    data: [greaterThan30], // Wrap in array
    color: '#FF7F7F'
}, {
    name: 'Less than or equal to 30 days',
    data: [lessThan30], // Wrap in array
    color: '#92d864'
}]
};
    return (
      <div className='row'>
        <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>

        <div className="row card-body" style={{display:'flex',flexDirection:'column'}} >
        

<div style={{display:'flex',flexDirection:'row',margin:'-40px 40px -40px 0px'}}>
<div className="col-xl-4" style={{marginTop:'20px'}}>
  <label>Select Facilities</label>
  <Select
    placeholder={"All Facilities"}
    closeMenuOnSelect={true}

    value={this.state.facility.label}
    onChange={this.newFilter.bind(this)}
    style={{ borderRadius: "0px" }}
    options={this.state.facilitiesList} required
  />
</div>

</div>
</div>


<div className="col-xl-12 col-lg-12 col-sm-12 beffect">
<div className="card">
  <div className="card-body" id="dashboardCounterCardBody">
    <div className="crm-numbers pb-0">

    <div className="row">

        <div className="col " style={{ cursor: 'pointer' }} onClick={this.onClickCounter.bind(this, 'totalpendingcount')}>
          <span className="f13">
            <br />Total Pending PODs
          </span>
          <h4 className="txt-info f50">
            <span className="counter">
              <CountUp end={(this.state.totalpendingcount) ? this.state.totalpendingcount : 0} />
            </span>
          </h4>

        </div>
        <div className="col " style={{ cursor: 'pointer' }} onClick={this.onClickCounter.bind(this, 'lastmonthcounter')}>
          <span className="f13">
            <br /> Pending PODs in {getNextMonthName()} month
          </span>
          <h4 className="txt-primary f50">
            <span className="counter">
              <CountUp end={(this.state.lastmonthcounter) ? this.state.lastmonthcounter : 0} />
            </span>
          </h4>

        </div>
        <div className="col " style={{ cursor: 'pointer' }} onClick={this.onClickCounter.bind(this, 'seclastmonthcounter')}>
          <span className="f13">
            <br /> Pending PODs in {getPreviousMonthName()} month
          </span>
          <h4 className="txt-secondary-medium f50">
            <span className="counter">
              <CountUp end={(this.state.seclastmonthcounter) ? this.state.seclastmonthcounter : 0} />
            </span>
          </h4>

        </div>
      </div>
 </div>

  </div>
</div>
</div>

<div className='col-xl-12 col-lg-12'>

<HighchartsReact
  containerProps={{ style: { width: '100%', height: '100%' } }}
  highcharts={Highcharts}
  style={dStyles}
  options={highchartoptions}
  onClick={this.handleDrilldown.bind(this)}
/>
</div>

<div className={"slide-r " + (this.state.sliderRouteTranslate)} style={{ overflow: "auto" }}>
<div className="slide-r-title">
  <h4>
    {this.state.sidebartitle}
    <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>

  </h4>
</div>
<div className="slide-r-body" style={{ position: "relative" }}>

  <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">

    <div
      id="myGrid"
      style={{
        height: '600px',
        width: '100%',
      }}
      className="ag-theme-balham"
    >
      <AgGridReact
        modules={this.state.modules}
        columnDefs={counterCoulumDef}
        defaultColDef={this.state.defaultColDef}
        rowData={this.state.rowData}
        paginationPageSize={this.state.paginationPageSize}
        pagination={true}
      />
    </div>

  </div>
</div>
</div>

<div className={"dataLoadpage " + (this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
          <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part " + (this.state.overly)} onClick={this.onCloseRouteDiv.bind(this)}></div>

        
        
      </div>
    );
  }
}
// function getNextMonthName() {
//   const currentDate = new Date();
//   currentDate.setMonth(currentDate.getMonth() - 1); // Set the date one month ahead
//   return currentDate.toLocaleString('default', { month: 'long' });
// }
function getNextMonthName() {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 1); // Set the date to the next month
  return currentDate.toLocaleString('default', { month: 'long' });
}
function getPreviousMonthName() {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 2); // Set the date two months back
  return currentDate.toLocaleString('default', { month: 'long' });
}
function getNextMonth(currentMonth) {
  const nextMonth = new Date(currentMonth);
  nextMonth.setMonth(currentMonth.getMonth() + 1);
  return nextMonth;
}

// Helper function to format the date as 'Mon-YY' (e.g., 'Apr-23')
function getFormattedMonth(date) {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return `${monthNames[date.getMonth()]}-${date.getFullYear().toString().substr(2)}`;
}
// function isMonthWithinCurrentFiscalYear(month) {
//   // Define an array of valid months within the current fiscal year
//   const validMonths = [
//     'Apr-23', 'May-23', 'Jun-23', 'Jul-23', 'Aug-23', 'Sep-23',
//     'Oct-23', 'Nov-23', 'Dec-23', 'Jan-24', 'Feb-24', 'Mar-24'
//   ];

//   // Check if the input month is in the array of valid months
//   return validMonths.includes(month);
// }
// function customSort(a, b) {
//   const order = [
//     "Apr-23", "May-23", "Jun-23", "Jul-23", "Aug-23", "Sep-23", "Oct-23",
//     "Nov-23", "Dec-23", "Jan-24", "Feb-24", "Mar-24"
//   ];

//   const indexA = order.indexOf(a.month);
//   const indexB = order.indexOf(b.month);

//   return indexA - indexB;
// }

function getMonthName(monthNumber) {
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  return monthNames[monthNumber - 1];
}

function DynamicSortOfMonths() {
  const currentDate = new Date();
  const fiscalYearStartMonth = 3;
  let currentFiscalYear;
  if (currentDate.getMonth() < fiscalYearStartMonth) {
    currentFiscalYear = currentDate.getFullYear() - 1;
  } else {
    currentFiscalYear = currentDate.getFullYear();
  }

  const fiscalYearMonths = [];
  const monthNames = [
    "Apr", "May", "Jun", "Jul", "Aug", "Sep",
    "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"
  ];

  // Dynamically generate the order array based on the current fiscal year
  const order = [];
  for (let i = fiscalYearStartMonth; i < fiscalYearStartMonth + 12; i++) {
    const monthName = monthNames[i % 12];
    const year = (monthName === "Jan" || monthName === "Feb" || monthName === "Mar") ? currentFiscalYear + 1 : currentFiscalYear;
    order.push(`${monthName}-${String(year).substring(2)}`);
  }

  for (let month = fiscalYearStartMonth; month < fiscalYearStartMonth + 12; month++) {
    const monthName = monthNames[month % 12];
    const year = (monthName === "Jan" || monthName === "Feb" || monthName === "Mar") ? currentFiscalYear + 1 : currentFiscalYear;
    fiscalYearMonths.push(`${monthName}-${year}`);
  }

  // Sort fiscal year months based on the dynamically generated order
  fiscalYearMonths.sort((a, b) => {
    const [monthA, yearA] = a.split('-');
    const [monthB, yearB] = b.split('-');
    // Compare years first
    if (yearA !== yearB) {
      return parseInt(yearA) - parseInt(yearB);
    }
    // If years are the same, compare months
    return monthNames.indexOf(monthA) - monthNames.indexOf(monthB);
  });

  fiscalYearMonths.forEach((month, index) => {
    const [monthName, year] = month.split('-');
    const shortYear = year.substring(2);
    // console.log(index, 'index', monthName, shortYear)
    fiscalYearMonths[index] = `${monthName}-${shortYear}`;
  });
  // console.log(fiscalYearMonths, 'fiscalYearMonths')
  return fiscalYearMonths;
}
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import Select from 'react-select';
import UpdateItem from './updaterow';
import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import CreatableSelect from 'react-select/creatable';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import axios from 'axios';
import Modal from 'react-responsive-modal';
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');


export default class ManageRailForCodes extends Component {

	constructor(props){
		super(props);
		this.state = {
			currentDepartmentTitle: null,
			modules: AllModules,
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
      	    defaultColDef: {
      	        sortable: true,
      	        filter: true,
				editable: true,
				resizable: true
      		},
			rowData: [],

			editType: "fullRow",
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection:'multiple',
			context: { componentParent: this },
			
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
		 
			frameworkComponents: {
				updateItem:UpdateItem,
                deleteItem:DeleteItem
				//statusChange:StatusChange
			},

			rowModelType: "serverSide",
			paginationPageSize:100,
			cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters:[],
            sliderRso:"",
            sliderUpdate:"",
            transporter:"",
            truck_no:"",
            trucktype:"",
            deptcode:"",
            sources:[],
            destinations:[],
            sourceopt:"",
            oem_code:"",
            oem_name:"",
            industry_type:"",
            officer_mobile:"",
            officer_alteratemobile:"",
            officer_locations:[],
            officertype:"",
            rowid:"",
            sliderBulk1:"",
            sliderBulk2:"",
            sliderBulk3:"",
            file:"",            
			csvcontent:[],
            billing_month:"",
            remarks_history_cols:[
                {
	  	          headerName: "Billing Id",
	  	          field: "billing_id",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                
		        {
	  	          headerName: "Remark",
	  	          field: "complete_remarks",
	  	          width: 180,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
	  	          headerName: "Updated Time",
	  	          field: "remark_time",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                  valueGetter:function(params){
                    try{
                        if(params.data.remark_time != '' && params.data.remark_time != undefined)
                        {
                            return getHyphenYYYYMMDDHHMMSS(params.data.remark_time)
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
                },
                {
                    headerName: "Remarks By",
                    field: "admin_email",
                    width: 180,
                    editable:false,
                    filter: "agSetColumnFilter"
                }

            ],
            remarks_data:[],
            rowId:'',
            imm_for_code:"",
            transporter_code:"",
            destination:"",
            consigner_code:[],
            for_code:"",
            rake_loading_point:"",
            rake_unloading_point:"",
            cluster:[],

            imm_for_code1:"",
            transporter_code1:"",
            transporter_code1:'',
            destination1:"",
            consigner_code1:[],
            for_code1:"",
            rake_loading_point1:"",
            rake_unloading_point1:"",
            selectedRows:[]
		};
        
        this.updateRowHandler = this.updateRowHandler.bind(this);
        this.deleteRowHandler = this.deleteRowHandler.bind(this);
        this.onLoadData = this.onLoadData.bind(this);
	}
	getCurrentDepartmentCode(pathToMatch = null) {
		let department = null;
		let departmentName = null;
		switch(pathToMatch) {
			case "/tptoemmaster":
				department='SNDG';
				departmentName = " ";
				break;
			
			default:
				console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			currentDepartmentTitle:departmentName
		});
		return department;
	}
	componentDidMount(){
		if (googleAnalytics.page.enableGA) {
			this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

        }
        this.setState({
            loadshow:"show-m",
            overly:"show-m",
        })

       this.listDataforloadingyardandunloadingyard();
		// get department code to filter records by department
		let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
		this.setState({
			departmentCode:currentDepartmentCode
		});
		 /*Consignments List*/		
	   this.onLoadData();
    
	  
    };
    onLoadData = () =>{
        redirectURL.post('/dashboard/managerailforcodesData').then((response) => {
          var records = response.data.manageforcodeData;
        //   records = records.sort(GetSortDescOrder("billing_id"));
          console.log("records ", records)
          this.setState({
              rowData:records,
              overly: "show-n",
            loadshow: "show-n"
          });
        })
        .catch(function (error) {
          console.log(error);
      });
     
    }
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }

	onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
    };
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);
           
         
          
    }

    onClickUpdateRowHandler(event)
	{
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
           let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
           let pageTitle = "Update OEM Master Data";
           if (currentDepartmentTitle) {
               pageTitle = "Update OEM Master Data" +  currentDepartmentTitle;
           }
           
           let eventOptions = {
               "category": pageTitle,
               "action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
               "label": googleAnalytics.page.action.rowUpdated,
           }
           googleAnalytics.logEvent(eventOptions);
       }
      
       if(this.state.oem_name != "" && this.state.oem_code != ""
       && this.state.industry_type != "")
       {
           var params = {
               rowID:this.state.rowid,
               oem_code:this.state.oem_code,
               oem_name:this.state.oem_name,
               industry_type:this.state.industry_type,
               transporter_code:localStorage.getItem("transportercode")
           }
           redirectURL.post("/dashboard/updateoemitem", params,{
               headers:{
                   'Content-Type': 'application/json'
               }
           }).
           then(
               (response)=>{
                   //console.log("update response is : ",response.data);
                   //console.log("Update Response ",response.data.result)
                   if(response.data.status == "success")
                   {
                       this.setState({
                           show: true,
                           basicTitle:'Successfully update data',
                           basicType:"success",
                           sliderUpdate:"",
                           overly:"show-n"
                       });
                       this.onLoadData();
                   }
                   
                   else{
                       this.setState({
                           show: true,
                           basicTitle:'Something went wrong',
                           basicType:"danger",
                       });
                   }
                   
               }
           )
           .catch(function(error){
               console.log(error);
           });
       }
       else{
           this.setState({
               show: true,
               basicTitle:'All * fields should not be empty',
               basicType:"danger",
           });
       }

    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var startdate = getHyphenYYYYMMDD(d)

        this.setState({
            billing_month: startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }
    handlerStartDateTimeViewBill= (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var startdate =  moment.parseZone(event._d).format('YYYY-MM')

        this.setState({
            selection_month: startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }



    
    onClickNewAddRow(event)
	{
        event.preventDefault();
		 if (googleAnalytics.page.enableGA) {
			let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
			let pageTitle = "Add OEM Master Data";
			if (currentDepartmentTitle) {
				pageTitle = "Add OEM Master Data" +  currentDepartmentTitle;
			}
			
			let eventOptions = {
				"category": pageTitle,
				"action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
				"label": googleAnalytics.page.action.rowUpdated,
			}
			googleAnalytics.logEvent(eventOptions);
        }
        if(this.state.oem_name != "" && this.state.oem_code != ""
       && this.state.industry_type != "")
        {
           var params = {
                oem_code:this.state.oem_code,
                oem_name:this.state.oem_name,
                industry_type:this.state.industry_type,
                transporter_code:localStorage.getItem("transportercode")
            }
            var fdata = {
                    rownode:params,
                    transporter_code:localStorage.getItem("transportercode")
            }
            redirectURL.post("/dashboard/saveoemitem", params,{
                headers:{
                    'Content-Type': 'application/json'
                }
            }).
            then(
                (response)=>{
                    //console.log("update response is : ",response.data);
                    //console.log("Update Response ",response.data.result)
                    if(response.data.status == "success")
                    {
                        this.setState({
                            show: true,
                            basicTitle:'Successfully added data',
                            basicType:"success",
                            sliderRso:"",
                            overly:"show-n",
                            loadshow:"show-n"
                        });
                        this.onLoadData();
                    }
                    
                    else{
                        this.setState({
                            show: true,
                            basicTitle:'Something went wrong',
                            basicType:"danger",
                        });
                    }
                    
                }
            )
            .catch(function(error){
                console.log(error);
            });
        }
        else{
            this.setState({
                show: true,
                basicTitle:'All * fields should not be empty',
                basicType:"danger",
            });
        }

    }
    
    /*Alert Popups*/
	closeAlert = () => {
        if(this.state.basicTitle =="Please Upload Valid File"){
            this.setState({
                show: false
            }); 
        }else if(this.state.basicTitle == "Please Select Rows"){
            this.setState({
                show: false
            }); 
        }
        else{
            this.setState({
                show: false
            });
            window.location.reload()
        }
    }

    
	 onAddRow() {
        // var newItem = 
        // {
        //     "truck_no":"",
        //     "truck_type":"",
        //     "transporter_code":"",
        //     "dept_code":""
        // };
        // console.log("newItem",newItem);
        // var res = this.gridApi.updateRowData({ add: [newItem] });
        this.setState({
			sliderRso:'slider-translate',
            overly:'show-m',
            oem_code:"",
            oem_name:"",
            industry_type:""
		})
        
    }
    onRowClicked(cell){
        if(cell.colDef.field == "view_remarks")
        {
            this.setState({
                overly:"show-m",
            })
            let params = {billingId:cell.data.billingId}
            let tpt_code = localStorage.getItem("transportercode")
            if(tpt_code !== undefined) params.transporter_code = tpt_code
            let email = localStorage.getItem("email")
            params.admin_email = email
            redirectURL.post("/dashboard/getbillingremarks",params)
            .then((response) => {
                var records = response.data;
            //   records = records.sort(GetSortDescOrder("billing_id"));
              console.log("records ", records)
              this.setState({
                  
                sliderUpdate:'slider-translate-40p',
                remarks_data:records
              });
            })
            .catch(function (error) {
                console.log(error);
            });
        }
        if(cell.colDef.field == "edit_row"){
          console.log(cell.data,'paramd')

                this.setState({
                    overly:"show-m",
                    rowId : cell.data._id,
                    imm_for_code : cell.data.imm_for_code,
                    transporter_code1 : {value:(cell.data.transporter_code),label:(cell.data.transporter_name)},
                    for_code : cell.data.for_code,
                    consigner_code1 : {value:(cell.data.consigner_code),label:(cell.data.consigner_name)},
                    destination : cell.data.consignee_city,
                    rake_loading_point1 : {value:(cell.data.rake_loading_point),label:(cell.data.rake_loading_point)},
                     rake_unloading_point1 : {value:(cell.data.rake_unloading_point),label:(cell.data.rake_unloading_point)},
                    //  cluster : cell.data.cluster.value,
                    sliderBulk3:'slider-translate',
                })
              
        }
        if(cell.colDef.field == "delete_row"){
            this.setState({
                loadshow:"show-m",
                overly:"show-m",
            })
            var params = {
                rowId : cell.data._id
            }
            redirectURL.post('/dashboard/deleteRowfrom',params)
            .then((response) => {
                console.log(response,'response')
                if(response.data.statusText="OK"){
                    this.setState({
                        loadshow:"show-n",
                        overly:"show-n",
                        show: true,
                        basicTitle:'Successfully Deleted',
                        basicType:"success", 
                    })
                }
            })
        }
        
    }
    
    
	 updateRowHandler = async(cell) => {
       
        var rowid = cell._id;
        var oem_code = cell.oem_code;
        var oem_name = cell.oem_name;
        var industry_type = cell.industry_type;
        await this.setState({
			sliderUpdate:'slider-translate',
            overly:'show-m',
            oem_code:oem_code,
            oem_name:oem_name,
            industry_type:industry_type,
            rowid:rowid
		})
        
    }


    
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            sliderRso:"",
            sliderUpdate:"",
            sliderBulk1:"",
            sliderBulk3:"",
            sliderBulk2:"",
		});
		
    }

    onChangeTruckType(trucktype){
		this.setState(
			{ trucktype },
			() => console.log(`Option selected:`, this.state.trucktype)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
   
    onClickGenerateBill(){
        
        var billing_id= moment.parseZone().format("YYYYMMDDHHmmss");
        // console.log(billing_id,"billing_id");
        var billing_getter = this.state.billing_month;
        console.log(billing_getter,"billing_getter");
        var billing_time=billing_getter.split("-");
         console.log(billing_time,"billing time");
        var billing_year = billing_time[0];
        // console.log(billing_year,"billing_year");
        var billing_month = billing_time[1];
        // console.log(billing_month,"billing_month");
 
        if(billing_getter !== "" && billing_getter !== undefined){
            var params={
                billing_year:billing_year,
                billing_month:billing_month,
                billing_id : billing_id
            }
            console.log(params,"params")
            redirectURL.post('/dashboard/generateBillingMonth',params)
            .then((response) => {
                var records = response.data.records;
                // records = records.sort(GetSortDescOrder("billing_id"));
                //   console.log("records ", records)
                this.setState({
                    rowData: records
                });
                window.location.href="/tptlogin/billingsummary" 
            })
            .catch(function (error) {
                console.log(error);
            });
     
        }
      
    }
    
    onChangeTransporter(transporter){
		this.setState(
			{ transporter },
			() => console.log(`Option selected:`, this.state.transporter)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    onChangeDepartment(deptcode){
		this.setState(
			{ deptcode },
			() => console.log(`Option selected:`, this.state.deptcode)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    listDataforloadingyardandunloadingyard(){
        redirectURL.post("/dashboard/gettingdataforloadingandunloading",{
			headers:{
				'Content-Type': 'application/json'
			}
		}).then((response)=>{
            var resp1 = response.data.gettingLoadingData;
            var resp2 = response.data.gettingUnLoadingData;
            var resp3 = response.data.cluster;
            var resp4 = response.data.gettingTransporterList;
            console.log(resp4,"gettingTransporterList")
            this.setState({
                rake_loading_point:resp1,
                rake_unloading_point:resp2,
                cluster: resp3,
                transporter_code:resp4,
            })
        })
    }
    deleteRowHandler(cell)
	{
		//  console.log("Here",cell);
		 if (googleAnalytics.page.enableGA) {
			let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
			let pageTitle = "OEM Master Data";
			if (currentDepartmentTitle) {
				pageTitle = "OEM Master Data" +  currentDepartmentTitle;
			}
			
			let eventOptions = {
				"category": pageTitle,
				"action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
				"label": googleAnalytics.page.action.rowUpdated,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		var fdata = {
	    		rownode:cell
	    }
	    redirectURL.post("/dashboard/deleteoemitem", fdata,{
			headers:{
				'Content-Type': 'application/json'
			}
		}).
		then(
			(response)=>{
				//console.log("update response is : ",response.data);
				//console.log("Update Response ",response.data.result)
				if(response.data.status == "success")
				{
					this.setState({
						show: true,
						basicTitle:'Successfully delete item',
						basicType:"success",
                        loadshow:"show-n",
                        overly:"show-n",
                        sliderRso:""
                    });
                    this.onLoadData();
				}
				
				else{
					this.setState({
						show: true,
						basicTitle:'Something went wrong',
						basicType:"danger",
					});
				}
				
			}
		)
		.catch(function(error){
			console.log(error);
		});

    }
     
    onChangeSourceItem(sourceopt){
		this.setState(
			{ sourceopt },
			() => console.log(`Option selected:`, this.state.sourceopt)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    onChangeOfficerType(officertype){
		this.setState(
			{ officertype },
			() => console.log(`Option selected:`, this.state.officertype)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    selectsources(){
		let items = [];    
		try{
			if(this.state.sources.length > 0)
			{
				this.state.sources.map((item) =>{
					items.push({"value":item.geofence_name,"label":item.geofence_name})
				})
			}
		}
		catch(e){

		}		
		return items;
    }
    
    onClickShowBulk1()
    {
        this.setState({
            overly:"show-m",
			sliderBulk1:'slider-translate',
        })
    }
    onClickShowBulk2()
    {
        this.setState({
            overly:"show-m",
			sliderBulk2:'slider-translate',
        })
    }
    

	changeFileHandler = async (e) => {
		// console.log(e.target.files[0].name,"791")
		var dt = '';
        
            // const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'imm_for_code',
					inputName: 'imm_for_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'transporter_code',
					inputName: 'transporter_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'for_code',
					inputName: 'for_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'consignee_city',
					inputName: 'consignee_city',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'consigner_name',
					inputName: 'consigner_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'rake_loading_point',
					inputName: 'rake_loading_point',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'rake_unloading_point',
					inputName: 'rake_unloading_point',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'cluster',
					inputName: 'cluster',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
				
			]
		}
            if(e.target.files[0] != undefined && e.target.files[0] != null && e.target.files[0] != ""){
            var filename = e.target.files[0].name.split(".")
            var fileName_check = filename[filename.length-1]
                if(fileName_check == "csv" || fileName_check == "CSV"){
                    var data = CSVFileValidator(e.target.files[0], config)
                    .then((csvData) => {
                        this.setState({
                            csvcontent:csvData.data
                        });
                    })
                    .catch(err => {})
                        
                    var out = new Promise(function(reject, resolve){
                        var reader =  new FileReader();
                        reader.onload = async function(e) {
                            var contents = await e.target.result;
                            console.log("contents ", contents )
                            
                            resolve(contents);
                        };
                        var tt =  reader.readAsText(e.target.files[0]);
                        console.log("tt ",tt)
                    });
                    //console.log("Out ", out);
                    
                    this.setState({
                        file:e.target.files[0]
                    });
                }else{
                    this.setState({
                        file:"",
                        csvcontent:[],
                        basicTitle:"Please Upload Valid File",
                        basicType:"danger",
                        show:true
                    })
                    $("#bulkUploadID").val("")
                }
            }
       
	}
    selectdestinationpoints1(){
		let items = [];    
		try{
			if(this.state.rake_loading_point.length > 0)
			{
				this.state.rake_loading_point.map((item) =>{
					items.push({"value":item,"label":item})
				})
			}
		}
		catch(e){

		}
		// console.log(items,"items1")
		return items;
    }
    selectdestinationpoints2(){
		let items = [];    
		try{
			if(this.state.rake_unloading_point.length > 0)
			{
				this.state.rake_unloading_point.map((item) =>{
					items.push({"value":item,"label":item})
				})
			}
		}
		catch(e){

		}
		// console.log(items,"items2")
		return items;
    }
    selectdestinationpoints3(){
		let items = [];    
		try{
			if(this.state.cluster.length > 0)
			{
				this.state.cluster.map((item) =>{
					items.push({"value":item,"label":item})
				})
			}
		}
		catch(e){

		}
		// console.log(items,"items2")
		return items;
    }
    selectdestinationpoints4(){
		let items = [];    
		try{
			if(this.state.transporter_code.length > 0)
			{
				this.state.transporter_code.map((item) =>{
					items.push({"value":item,"label":item})
				})
			}
		}
		catch(e){

		}
		// console.log(items,"items2")
		return items;
    }
    changeSelectedConsingerCode(e){
        this.setState({
            consigner_code : {"value":e.value,"label":e.value},
        })
    }
    changeSelectedRailLoadingPoint(e){
        this.setState({
            rake_loading_point : {"value":e.value,"label":e.value},
        })
    }
    changeSelectedRailUnloadingPoint(e){
        this.setState({
            rake_unloading_point : {"value":e.value,"label":e.value},
        })
    }
    changeSelectedClusterType(e){
        this.setState({
            cluster : {"value":e.value,"label":e.value},
        })
    }
    changeSelectedTransporterCode(e){
        this.setState({
            transporter_code : e,
        })
    }
    onSubmitBulkUpload(event)
    {
        event.preventDefault()

        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Buk Upload",
				"action": "form",
				"label": "Buk Upload",
			}
			googleAnalytics.logEvent(eventOptions);
        }
       var user_email = localStorage.getItem('email');
        var params = {   
            csvcontent:this.state.csvcontent,
            created_by:user_email,
        }
        console.log("Params ", params)
        redirectURL.post("/dashboard/bulkuploadmanageRailForCode", params)
        .then((response) => {
            if(response.data.message == "Success")
				{
					this.setState({
						show: true,
						basicTitle:'Successfully added items',
						basicType:"success",
                        loadshow:"show-n",
                        overly:"show-n",
                        sliderBulk1:"",
                        file:""
                    });
                    this.onLoadData();
				}
				
				else{
					this.setState({
						show: true,
						basicTitle:'Something went wrong',
						basicType:"danger",
					});
				}
        })
    }
    onClickAddNewForCodes(event){
        event.preventDefault();
        var imm_for_code = this.state.imm_for_code;
        var transporter_code = this.state.transporter_code.value;
        var for_code = this.state.for_code;
        var consigner_code = this.state.consigner_code.value;
        var destination = this.state.destination;
        var rake_loading_point = this.state.rake_loading_point.value;
        var rake_unloading_point = this.state.rake_unloading_point.value;
        var cluster = this.state.cluster.value;
        var user_email = localStorage.getItem('email')
        var reqparams ={
            imm_for_code:imm_for_code,
            transporter_code:transporter_code,
            for_code:for_code,
            consigner_code:consigner_code,
            destination:destination,
            rake_loading_point:rake_loading_point,
            rake_unloading_point:rake_unloading_point,
            cluster:cluster,
            created_by:user_email,
        }
        console.log(reqparams,"reqparams");
        if(reqparams.consigner_code !="" && reqparams.rake_loading_point != "" && reqparams.rake_unloading_point !="" && reqparams.cluster != "" && reqparams.transporter_code != ""){
            this.setState({
                loadshow:"show-m",
                overly:"show-m",
            })
            redirectURL.post("/dashboard/insertNewForCodeData", reqparams)
            .then((response) => {
             var resp = response.data
             if(resp.message != "Already Exists"){
                this.setState({
                    loadshow:"show-n",
                    overly:"show-n",
                    show: true,
                    basicTitle:'Successfully Updated',
                    basicType:"success", 
                })
                
             }
             else{
                this.setState({
                    loadshow:"show-n",
                    overly:"show-n",
                    show: true,
                    basicTitle:'For Code Already Exists',
                    basicType:"warning", 
                })
             }
            })
        }
        else{
            this.setState({
                show: true,
                basicTitle:'All Fields Should be filled',
                basicType:"warning", 
            })
        }
       
    }
    onClickEditForCodes(event){
        event.preventDefault();
        
        var imm_for_code = this.state.imm_for_code;
        var transporter_code = this.state.transporter_code.value;
        var for_code = this.state.for_code;
        var consigner_code = this.state.consigner_code.value;
        var destination = this.state.destination;
        var rake_loading_point = this.state.rake_loading_point.value;
        var rake_unloading_point = this.state.rake_unloading_point.value;
        var cluster = this.state.cluster.value;
        var user_email = localStorage.getItem('email')
        var reqparams ={
            imm_for_code:imm_for_code,
            transporter_code:transporter_code,
            for_code:for_code,
            consigner_code:consigner_code,
            destination:destination,
            rake_loading_point:rake_loading_point,
            rake_unloading_point:rake_unloading_point,
            cluster:cluster,
            created_by:user_email,
            rowId : this.state.rowId
        }
        console.log(reqparams,"reqparams");
        if(reqparams.consigner_code !="" && reqparams.rake_loading_point != "" && reqparams.rake_unloading_point !="" && reqparams.cluster != "" && reqparams.transporter_code != ""){
            this.setState({
                loadshow:"show-m",
                overly:"show-m",
            })
            redirectURL.post("/dashboard/onClickEditForCodes", reqparams)
            .then((response) => {
             var resp = response.data
             if(resp.message != "Already Exists"){
                this.setState({
                    loadshow:"show-n",
                    overly:"show-n",
                    show: true,
                    basicTitle:'Successfully Updated',
                    basicType:"success", 
                })
                
             }
             else{
                this.setState({
                    loadshow:"show-n",
                    overly:"show-n",
                    show: true,
                    basicTitle:'For Code Already Exists',
                    basicType:"warning", 
                })
             }
            })
        }
        else{
            this.setState({
                show: true,
                basicTitle:'All Fields Should be filled',
                basicType:"warning", 
            })
        }
       
    }
    // Bulk Delete Functionality
    onRowSelection(event){
        var selectedRows=[];
        var rwCount = event.api.getSelectedNodes();
        rwCount.map((item) => {
            selectedRows.push(item.data)
        });
        // console.log(selectedRows,"selectedRows")
        this.setState({
            selectedRows:selectedRows
        })
    }
    onclickBulkDelete=()=>{
        var selectedRows = this.state.selectedRows
        if(selectedRows.length > 0){
            var cnf = window.confirm("Are you Sure, Do you really want to Delete?")
            if(cnf == true){
                this.setState({
                    loadshow:"show-m",
                    overly:"show-m"
                })
                    // console.log(selectedRows,"1188")
                    var deleteRowIds = []
                    selectedRows.map((e)=>{
                        deleteRowIds.push(e._id)
                })
                // console.log(deleteRowIds,"1193")
                var params={
                    deleteRowIds : deleteRowIds,
                    deleted_by:localStorage.getItem("username")
                }
                redirectURL.post("/dashboard/bulkDeletemanagerailforcodes",params).then((response)=>{
                    if(response.data.message == "success"){
                        this.setState({
                            basicTitle:"Bulk Data Deleted Successfully",
                            basicType:"success",
                            show:true,
                            loadshow:"show-n",
                            overly:"show-n"
                        })
                        this.onLoadData();
                    }else{
                        this.setState({
                            basicTitle:"Data Not Deleted",
                            basicType:"danger",
                            show:true,
                            loadshow:"show-n",
                            overly:"show-n"
                        })
                    }
                })
            }else{
                this.setState({
                    loadshow:"show-n",
                overly:"show-n"
                })
            }
           
        }else{
            this.setState({
                basicTitle:"Please Select Rows",
                basicType:"warning",
                show:true
            })
        }
        

    }
    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
        var transporters = []
        var trans = this.state.transporters;
        if(trans.length > 0)
        {
            trans.map((item) => {
                transporters.push(item.value)
            })
        }
		const {usermanualmodal} = this.state;
		var hideChilds = true;
		if(this.state.departmentCode == "LOG-PRT")
		{
			hideChilds = false
		}

		const columnwithDefs = [
	  	        // {
                //       //view data
	  	        //   headerName: "",
	  	        //   field: "",
	  	        //   width: 80,
                //   editable:false,
	    	    //   filter: "agSetColumnFilter",
                //   cellRenderer:function(params){
                //     let basestring = params.data.billingId;
                //     let encryptedstring = window.btoa(basestring);
                //     var htmloption = '<a href="/tptlogin/billingdownload/'+encryptedstring+'" class="custom-btn label label-success"><i class="icofont icofont-eye"></i> View</a>';
                //     return htmloption
                //   }
                // },
                // {
                //     // View Remarks
                //     headerName: "",
                //     field: "view_remarks",
                //     width: 40,
                //     filter: false,
                //     // cellRenderer:'updateItem',
                //     editable:false,
                //     cellRenderer:function(params){
                //         let basestring = params.data.billingId;
                //         let encryptedstring = window.btoa(basestring);
                //         var htmloption = '<i class="fa fa-comment f15"></i>';
                //         //var htmloption = '<a href="/tptlogin/billingdownload/'+encryptedstring+'" class="custom-btn label label-success"><i class="fa fa-comment f15"></i>View</a>';
                //         return htmloption
                //       }
		        // },
                {
                    headerName: "",
                    field: "",
                    colId: "",
                    width: 40,
                    //pinned: 'left',
                    filter: false,
                    resizable: true,
                    headerCheckboxSelection: false,
                    //headerCheckboxSelectionFilteredOnly: true,
                    checkboxSelection: true
                },
                {
                    headerName:"",
                    field:"edit_row",
                    width:90,
                    cellRenderer: function(params){
                                var htmloption = '<button class="btn btn-success" style="padding-top:0px">Edit</button>';
                                return htmloption;
                            },
                },
                {
                    headerName:"",
                    field:"delete_row",
                    width:120,
                    cellRenderer: function(params){
                                var htmloption = '<button class="btn btn-warning" style="padding-top:0px">Delete</button>';
                                return htmloption;
                            },
                },
                {
	  	          headerName: "Imm For Code",
	  	          field: "imm_for_code",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
	  	          headerName: "Transporter Name",
	  	          field: "transporter_name",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                },
                {
	  	          headerName: "Transporter Code",
	  	          field: "transporter_code",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                },
                {
	  	          headerName: "For Code",
	  	          field: "for_code",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                },
                {
	  	          headerName: "Destination",
	  	          field: "consignee_city",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                },
                {
	  	          headerName: "Consigner Code",
	  	          field: "consigner_code",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                  cellRenderer:function(params){
                            if(params.data.consigner_code == "F"){
                            return "Gurgaon"
                            }
                            else if(params.data.consigner_code == "M"){
                                return "Manesar"
                            }
                            else if(params.data.consigner_code == "G"){
                                return "Gujarat"
                            }
                            else if(params.data.consigner_code == "B"){
                                return "Banglore"
                            }
                            else{
                                return " "
                            }
                          }
                          
                   
                },
                {
	  	          headerName: "Rake Loading Point",
	  	          field: "rake_loading_point",
	  	          width: 160,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                },
                {
	  	          headerName: "Rake Unloading Point",
	  	          field: "rake_unloading_point",
	  	          width: 160,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                },
                {
	  	          headerName: "Cluster",
	  	          field: "cluster",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                },
                
	    ];

    //    if( this.state.is_admin == 1 ){
        // columnwithDefs.splice(11,0, {
        //     headerName: "System Status",
        //     field: "status",
        //     width: 180,
        //     filter: false,
        //     // cellRenderer:'updateItem',
        //     editable:false,
        //     valueGetter:function(params){
        //         if(params.data.status == 0)
        //         {
        //             return "Billing Process Success"
        //         }
        //         else if(params.data.status ==1)
        //         {
        //             return "No uptime Data from DB"
        //         }
        //         else if(params.data.status ==2)
        //         {
        //              return "No master list from DB"
        //         }
        //         else if(params.data.status ==3)
        //         {
        //              return "Registered Devices Data not available in DB"
        //         } 
        //         else if(params.data.status ==4)
        //         {
        //              return "Future Billing Date"
        //         }
        //         else if(params.data.status ==99)
        //         {
        //              return "Unknown Error"
        //         } 
        //         else{
        //             return "Status unknown"
        //         }                      
        // }
        // },)
    //    }
		return(
			<div className="container-fluid">
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        > 
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Manage Rail For Codes</span>
                                       {/* <button type="button" onClick={this.onAddRow.bind(this)} className="btn btn-warning float-right">Add New Row</button> */}
                                        {/* <button href="/tptlogin/billingsummary" type="button" onClick={this.onClickGenerateBill.bind(this)} className="btn btn-warning float-right">Generate Bill</button> */}
                                   
                                        <button type="button" style={{margin : "5px"}} onClick={() => this.onClickShowBulk1()} className="btn btn-warning float-right" >Bulk Upload</button>
                                        <button type="button" style={{margin : "5px"}} onClick={() => this.onClickShowBulk2()} className="btn btn-success float-right">Add New</button>
                                        <button type="button" style={{margin : "5px"}} onClick={() => this.onclickBulkDelete()} className="btn btn-danger float-right">Bulk Delete</button>
                                        
                                </h5>
                            </div>
                            {/* <div className="col-xl-3 col-lg-3 form-group">
                                <label>Select Billing Date</label>
                                <Datetime
                                    value={this.state.billing_month}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{
                                        placeholder: '',
                                        name: 'billing_month',
                                        autoComplete: 'off'
                                    }}
                                    dateFormat="YYYY-MM-DD"
                                    name="billing_month"
                                    onChange={this.handlerStartDateTime.bind(this)}

                                />
                            </div> */}
                            {/* <div className="col-xl-3 col-lg-3 form-group">
                            <label>Select Month</label>
                                <Datetime
                                    value={this.state.billing_month} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ 
                                        placeholder: '', 
                                        name: 'billing_month', 
                                        autoComplete:'off' 
                                    }}
                                    dateFormat="YYYY-DD" 
                                    name="billing_month" 
                                    onChange={this.handlerStartDateTime.bind(this)} 

                                    />
                            </div>				   			 */}
		            		<div className="card-body">
                                <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        deleteRowHandler={this.deleteRowHandler}
                                        onCellClicked={this.onRowClicked.bind(this)}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        onRowSelected={this.onRowSelection.bind(this)}
                                        //updateRowHandler={this.updateRowHandler}
                                        //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				 <div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
					 <div className="slide-r-title">
				 		<h4>
                            Sidebars
                        </h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
						
				 		<div className="col-xl-12 col-lg-12">
				 		<div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg "+(this.state.alerterrshow)+" "+(this.state.alerterrmg)}>{this.state.csverrmessage}</div>
	        			
				 		</div>
				 	</div>
				 </div>
				
                
                <div className={"slide-r "+(this.state.sliderRso)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Add OEM Item
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickNewAddRow.bind(this)}>
							
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">OEM Code *</label>
								<input 
									type="text" 
									name="oem_code"
									value={this.state.oem_code}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							     
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">OEM Name *</label>
								<input 
									type="text" 
									name="oem_name"
									value={this.state.oem_name}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							     
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Industry Type *</label>
								<input 
									type="text" 
									name="industry_type"
									value={this.state.industry_type}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							                       
							
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						
                        </div>
					</div>
				</div>



                <div className={"slide-r "+(this.state.sliderUpdate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Remarks History
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickUpdateRowHandler.bind(this)}>
                        <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={this.state.remarks_history_cols}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.remarks_data}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        deleteRowHandler={this.deleteRowHandler}
                                        onCellClicked={this.onRowClicked.bind(this)}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        //updateRowHandler={this.updateRowHandler}
                                        //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
                            
						</form>

						
                        </div>
					</div>
				</div>

                <div className={"slide-r "+(this.state.sliderBulk1)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Bulk Upload
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                     
                            
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitBulkUpload.bind(this)}>
                                <div className="form-group mt-20p">
                                    <label className="">Upload File *</label> 
                                    <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control" required  />
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                   
                                </div>
                                <p style={{color:"red"}}>Note: Format should be same as given in sample template***</p>
                                <div className="form-group">
                                <a className="btn btn-primary" href={require('../../assets/json/sample_manage_rail_forcode.csv')} target="_blank">Sample Template</a>
                            </div>
						</form> 
                        
                                        
                        </div>
					</div>
				</div>
                <div className={"slide-r "+(this.state.sliderBulk2)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                          Add New
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                     
                            
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickAddNewForCodes.bind(this)}>
							
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Imm For Code *</label>
								
                                <input type="text" placeholder="Imm For code" id="imm_for_code" name="imm_for_code" value={this.state.imm_for_code} className="form-control ml-15p" onChange={this.changeHandler} required/>
							
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Transporter Code *</label>
                                <Select 
                                            //  value={this.state.rake_loading_point}
                                            placeholder={"Transporter Code"}
                                            closeMenuOnSelect={true}
                                            onChange={this.changeSelectedTransporterCode.bind(this)}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            // style={{borderRadius:"0px"}}
                                            options={this.state.transporter_code}
                                            id="transporter_code"
                                            required />
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">For Code *</label>
								
                                <input type="text" placeholder="For Code" id="for_code" name="for_code" value={this.state.for_code} className="form-control ml-15p" onChange={this.changeHandler} required/>
							
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Consigner Code *</label>
                                <Select 
                                            // value={this.state.plant}
                                            placeholder={"Consigner Code"}
                                            closeMenuOnSelect={true}
                                            onChange={this.changeSelectedConsingerCode.bind(this)}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            // style={{borderRadius:"0px"}}
                                            options={[
												{ label: "Gurgaon", value:"F"},
												{ label: "Manesar", value: "M"},
												{ label: "Gujarat", value: "G"},
                                                { label: "TVP-Banglore", value: "B"},
											]}
                                            id="consigner_code"
                                            required />
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Destination *</label>
								
                                <input type="text" placeholder="Destination" id="destination" name="destination" value={this.state.destination} className="form-control ml-15p" onChange={this.changeHandler} required/>
							
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Rake Loading Point *</label>
								
                                <CreatableSelect 
                                            //  value={this.state.rake_loading_point}
                                            placeholder={"Rail Loading Point"}
                                            closeMenuOnSelect={true}
                                            onChange={this.changeSelectedRailLoadingPoint.bind(this)}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            // style={{borderRadius:"0px"}}
                                            options={this.selectdestinationpoints1()}
                                            id="rail_loading_point"
                                            required />
							
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Rake Unloading Point *</label>
								
                                <CreatableSelect 
                                            // value={this.state.rake_unloading_point}
                                            placeholder={"Rail Unloading Point"}
                                            closeMenuOnSelect={true}
                                            onChange={this.changeSelectedRailUnloadingPoint.bind(this)}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            // style={{borderRadius:"0px"}}
                                            options={this.selectdestinationpoints2()}
                                            id="rail_unloading_point"
                                            required />
							
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Cluster *</label>
                                <CreatableSelect 
                                            // value={this.state.plant}
                                            placeholder={"Cluster"}
                                            closeMenuOnSelect={true}
                                            onChange={this.changeSelectedClusterType.bind(this)}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            // style={{borderRadius:"0px"}}
                                            options={this.selectdestinationpoints3()}
                                            id="cluster"
                                            required />
							</div>
							<div className="col-xl-12 col-lg-12 c">
                            <button type="button" onClick={this.onClickAddNewForCodes.bind(this)} className="btn btn-success">Submit</button>
							</div>
                            
						    {/* <div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_oem_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div> */}
						</form> 
                
						
                        </div>
					</div>
				</div>
            
                <div className={"slide-r "+(this.state.sliderBulk3)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                          Edit Data
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                     
                            
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickEditForCodes.bind(this)}>
							
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Imm For Code *</label>
								
                                <input type="text" placeholder="Imm For code" id="imm_for_code" name="imm_for_code" value={this.state.imm_for_code} className="form-control ml-15p" onChange={this.changeHandler} required/>
							
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Transporter Code *</label>
                                <Select 
                                            //  value={this.state.rake_loading_point}
                                            placeholder={"Transporter Code"}
                                            closeMenuOnSelect={true}
                                            onChange={this.changeSelectedTransporterCode.bind(this)}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            // style={{borderRadius:"0px"}}
                                            options={this.state.transporter_code}
                                            id="transporter_code"
                                            required />
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">For Code *</label>
								
                                <input type="text" placeholder="For Code" id="for_code" name="for_code" value={this.state.for_code} className="form-control ml-15p" onChange={this.changeHandler} required/>
							
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Consigner Code *</label>
                                <Select 
                                            // value={this.state.plant}
                                            placeholder={"Consigner Code"}
                                            closeMenuOnSelect={true}
                                            onChange={this.changeSelectedConsingerCode.bind(this)}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            // style={{borderRadius:"0px"}}
                                            options={[
												{ label: "Gurgaon", value:"F"},
												{ label: "Manesar", value: "M"},
												{ label: "Gujarat", value: "G"},
                                                { label: "TVP-Banglore", value: "B"},
											]}
                                            id="consigner_code"
                                            required />
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Destination *</label>
								
                                <input type="text" placeholder="Destination" id="destination" name="destination" value={this.state.destination} className="form-control ml-15p" onChange={this.changeHandler} required/>
							
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Rake Loading Point *</label>
								
                                <CreatableSelect 
                                            //  value={this.state.rake_loading_point}
                                            placeholder={"Rail Loading Point"}
                                            closeMenuOnSelect={true}
                                            onChange={this.changeSelectedRailLoadingPoint.bind(this)}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            // style={{borderRadius:"0px"}}
                                            options={this.selectdestinationpoints1()}
                                            id="rail_loading_point"
                                            required />
							
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Rake Unloading Point *</label>
								
                                <CreatableSelect 
                                            // value={this.state.rake_unloading_point}
                                            placeholder={"Rail Unloading Point"}
                                            closeMenuOnSelect={true}
                                            onChange={this.changeSelectedRailUnloadingPoint.bind(this)}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            // style={{borderRadius:"0px"}}
                                            options={this.selectdestinationpoints2()}
                                            id="rail_unloading_point"
                                            required />
							
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Cluster *</label>
                                <CreatableSelect 
                                            // value={this.state.plant}
                                            placeholder={"Cluster"}
                                            closeMenuOnSelect={true}
                                            onChange={this.changeSelectedClusterType.bind(this)}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            // style={{borderRadius:"0px"}}
                                            options={this.selectdestinationpoints3()}
                                            id="cluster"
                                            required />
							</div>
							<div className="col-xl-12 col-lg-12 c">
                            <button type="button"
                             onClick={this.onClickEditForCodes.bind(this)} 
                             className="btn btn-success">Submit</button>
							</div>
                            
						    {/* <div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_oem_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div> */}
						</form> 
                
						
                        </div>
					</div>
				</div>

                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            

            </div>
              	
		);
	}
}
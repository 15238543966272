import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const TPTtrucklocaction = (props) => {
    //console.log("LoadingDelay ",props);
    const handleLoadingDelayClick = (e) => {
        e.stopPropagation();
       props.context.onClickShowTPTTruckLocation(props);
    };
   
    return (
        <div>
            <button onClick={handleLoadingDelayClick} 
            className="custom-btn f22 label label-success" title="View Location">
                <i className="icofont icofont-social-google-map"></i> &nbsp;</button>
            
           
        </div>
    );
};

export default TPTtrucklocaction;

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const EditmenuAction = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onShowEditMenu(props);
    };

   
    return (
        <div>
            <button onClick={handleEventClick} className="custom-btn label label-success" 
            title="Edit Menu" ><i className={"icofont icofont-edit-alt "}></i> Edit</button>
            
        </div>
    );
};

export default EditmenuAction;

import React, { Component } from "react";
import moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from "@ag-grid-community/react";
import Select from "react-select";
import { getHyphenDDMMMYYYY, getHyphenDDMMMYYYYHHMM } from '../common/utils';
import $ from "jquery";
import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import DrawMap2 from '../common/drawmap2';
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import * as Datetime from 'react-datetime';
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import download from 'js-file-download';
var redirectURL = require("../redirectURL");

export default class RestrictionImposedSystem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      eventFormAction: "formAction",
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        width: 160,
        resizable: true,
      },
      eventAction: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      bulkslide: "",
      bulkslide1: "",
      files: [],
      filesName:'',
      alert: null,
      sliderbulkupload: "",
      rowModelhighchartoptionsType: "enterprise",
      frameworkComponents: {
        consignmentActions: ConsignmentActions,
      },
      paginationPageSize: 50,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      overly: "show-n",
      loadshow: "show-n",
      file: "",
      rowData: [],
      rowId: "",
      show: false,
      basicTitle: "",
      basicType: "",
      restriction_area: '',
      restriction_area_options: [],
      restricted_type: '',
      no_of_rakes_impacted: '',
      msil_rake_id: '',
      restriction_start_date: '',
      restriction_end_date: '',
      informed_restriction_date: ''

    };
    this.restrictionFile = this.restrictionFile.bind(this);
  }

  async componentDidMount() {
    loadDateTimeScript();
    var transportercode = "";
		if (localStorage.getItem('transportercode') === undefined) {
			transportercode = "";
		}
		else {
			transportercode = localStorage.getItem('transportercode');
		}
    var params = {
      transporter_code : transportercode,
    }
    await redirectURL
      .post("/consignments/railRestrictionDataMountData",params)
      .then((response) => {
        var totalData = response.data
        const filteredData = totalData.filter(row => !row.deleted);
        this.setState({
          rowData: filteredData
        })
      })

    var restriction_area_options = []
    await redirectURL
      .post("/consignments/geofencenamesoptions")
      .then((response) => {
        // console.log(response.data, 'responseData')
        var records = response.data;
        var nameOptions = records.map((ele) => {
          restriction_area_options.push({ label: ele.geofence_name, value: ele.geofence_name })
        })
      })
    this.setState({
      restriction_area_options: restriction_area_options
    })
  }
  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      bulkslide: '',
      bulkslide1: "",
    });
    $("#uploadFile").val("")
    var form = document.getElementById("initialEditDetails");
    if (form) {
      form.reset();
    }
  }
  async onCellClicked(event) {
    if (event.colDef.field == "restriction_order") {
      var fileName = event.data.restriction_order
      // console.log(fileName,"496")
      if (fileName != "") {
        var reqparams = {
          restrictionOrder: fileName,
        }
        redirectURL.post("/consignments/downloadrestrictionOrder", reqparams, {
          responseType: 'blob'
        }).then((response) => {
          // console.log("download response hub",response)
          download(response.data, fileName[0].name)

        }).catch(function (error) {
          console.log(error);
        })
      }
    }
    if (event.colDef.field === "Edit") {
      // console.log(event.data, 'onclickvalues');
      var data = event.data;

      this.setState({
        rowId: data._id,
        restriction_area: ({ value: data.restriction_area, label: data.restriction_area }),
        informed_restriction_date: formatDate(data.informed_restriction_date),
        restricted_type: ({ label: data.restricted_type, value: data.restricted_type }),
        restriction_start_date: formatDate(data.restriction_start_date),
        restriction_end_date: formatDate(data.restriction_end_date),
        no_of_rakes_impacted: data.no_of_rakes_impacted,
        msil_rake_id: data.msil_rake_id,
        filesName: (data.restriction_order.length > 0 && data.restriction_order[0].name ? data.restriction_order[0].name : ""),
        bulkslide1: "slider-translate-30p",
      });
      $("#uploadFile").val("")
    }
    if (event.colDef.field == "delete")
    {
       const params = {
        rowId: event.data._id,
      type: 'restrictionDelete'
    };

    this.setState({ alert: (
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        // title="Are you sure?"
        onConfirm={() => {
          this.setState({ alert: null });
          redirectURL.post("/consignments/restrictiondeletefromdata", params)
            .then((response) => {
              this.setState({
                loadshow: "show-n",
                overly: "show-n",
                show: true,
                basicTitle: "Deleted Successfully",
                basicType: 'success'
              });
              this.componentDidMount(); 
            })
            .catch((error) => {
              console.error("Error deleting:", error);
            });
        }}
        onCancel={() => {
          this.setState({ alert: null });
          console.log("Deletion cancelled.");
        }}
      >
      Are you sure?<br/>Once deleted, you will not be able to recover this data
      </SweetAlert>
    )});
  }
  }

  addRestrictionImposed() {
    var form = document.getElementById("initialAddingDetails");
    if (form) {
      form.reset();
    }
    this.setState({
      rake_name: [],
      type: [],
      bulkslide: "slider-translate-30p",
      overly: "show-m",
      restriction_area: '',
      restricted_type: '',
      restriction_start_date: '',
      restriction_end_date: '',
      informed_restriction_date: '',
      no_of_rakes_impacted: '',
      msil_rake_id: ''
    })
  }

  async onClickAddDetails(e) {
    e.preventDefault();
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    })
    var restriction_area = this.state.restriction_area.value;
    var informed_restriction_date = this.state.informed_restriction_date;
    var restricted_type = this.state.restricted_type.value;
    var restriction_start_date = this.state.restriction_start_date;
    var restriction_end_date = this.state.restriction_end_date;
    var no_of_rakes_impacted = this.state.no_of_rakes_impacted
    var msil_rake_id = this.state.msil_rake_id
    var files = this.state.files[0]
    var formData = new FormData();
    formData.append('restriction_area', restriction_area)
    formData.append('informed_restriction_date', informed_restriction_date)
    formData.append('restricted_type', restricted_type)
    formData.append('restriction_start_date', restriction_start_date)
    formData.append('restriction_end_date', restriction_end_date)
    formData.append('no_of_rakes_impacted', no_of_rakes_impacted)
    formData.append('msil_rake_id', msil_rake_id)
    formData.append('files', files)
    formData.append('updated_by', localStorage.getItem("username"))
    if (restriction_area != "" && restriction_area != undefined && informed_restriction_date != "" && informed_restriction_date != undefined && restricted_type != "" && restricted_type != undefined && restriction_start_date != "" && restriction_start_date != undefined && this.state.files[0] !== undefined && this.state.files[0] !== "" && this.state.files[0] != [] && this.state.files[0] != null && restriction_end_date != "" && restriction_end_date != undefined && no_of_rakes_impacted != "" && no_of_rakes_impacted != undefined) {

      await redirectURL
        .post("/consignments/addingrestricitionimposeddata", formData)
        .then((response) => {
          this.setState({
            show: true,
            loadshow: "show-n",
            overly: "show-n",
            basicType: "success",
            basicTitle: "New restriction imposed details added Successfully",
            bulkslide: '',
            files: [],
            restriction_start_date: '',
            restriction_end_date: '',
            informed_restriction_date: ''
          })

        })
      this.componentDidMount()
    } else {
      this.setState({
        show: true,
        basicType: "warning",
        loadshow: "show-n",
        overly: "show-n",
        basicTitle: "All (*) fields are mandatory",
      })
    }
  }

  async onClickEditDetails(e) {
    e.preventDefault();
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    })
    var restriction_area = this.state.restriction_area.value;
    var informed_restriction_date = this.state.informed_restriction_date;
    var restricted_type = this.state.restricted_type.value;
    var restriction_start_date = this.state.restriction_start_date;
    var restriction_end_date = this.state.restriction_end_date;
    var no_of_rakes_impacted = this.state.no_of_rakes_impacted
    var msil_rake_id = this.state.msil_rake_id
    var files = this.state.files[0]
    var formData = new FormData();
    formData.append("rowId", this.state.rowId)
    formData.append('restriction_area', restriction_area)
    formData.append('informed_restriction_date', informed_restriction_date)
    formData.append('restricted_type', restricted_type)
    formData.append('restriction_start_date', restriction_start_date)
    formData.append('restriction_end_date', restriction_end_date)
    formData.append('no_of_rakes_impacted', no_of_rakes_impacted)
    formData.append('msil_rake_id', msil_rake_id)
    formData.append('files', files)
    formData.append('updated_by', localStorage.getItem("username"))
    if (restriction_area != "" && restriction_area != undefined && informed_restriction_date != "" && informed_restriction_date != undefined && restricted_type != "" && restricted_type != undefined && restriction_start_date != "" && restriction_start_date != undefined && restriction_end_date != "" && restriction_end_date != undefined && no_of_rakes_impacted != "" && no_of_rakes_impacted != undefined) {
    //  console.log(this.state.filesName,'this.state.filesName',this.state.files)
      if (this.state.filesName.length == 0) {
        console.log('innn')
        if (this.state.files[0] !== undefined && this.state.files[0] !== "") {
          await redirectURL
          .post("/consignments/Editingrestricitionimposeddata", formData)
          .then((response) => {
            this.setState({
              show: true,
              loadshow: "show-n",
              overly: "show-n",
              basicType: "success",
              basicTitle: "Successfully Updated",
              bulkslide1: '',
              restriction_start_date: '',
              restriction_end_date: '',
              informed_restriction_date: ''
            })
  
          })
        $("#uploadFile").val("")
        var form = document.getElementById("initialEditDetails");
        if (form) {
          form.reset();
        }
        this.componentDidMount()
      }else{
        this.setState({
          show: true,
          basicType: "warning",
          loadshow: "show-n",
          overly: "show-n",
          basicTitle: "All (*) fields are mandatory",
        })
      }
    } else if(this.state.filesName != "") {
      console.log('out')
      await redirectURL
      .post("/consignments/Editingrestricitionimposeddata", formData)
      .then((response) => {
        this.setState({
          show: true,
          loadshow: "show-n",
          overly: "show-n",
          basicType: "success",
          basicTitle: "Successfully Updated",
          bulkslide1: '',
          restriction_start_date: '',
          restriction_end_date: '',
          informed_restriction_date: ''
        })

      })
    $("#uploadFile").val("")
    var form = document.getElementById("initialEditDetails");
    if (form) {
      form.reset();
    }
    this.componentDidMount()
    }
    } else {
      this.setState({
        show: true,
        basicType: "warning",
        loadshow: "show-n",
        overly: "show-n",
        basicTitle: "All (*) fields are mandatory",
      })
    }
  }

  restrictionFile = async (e) => {
    var files = Array.from(e.target.files);
    this.setState({
      files: files
    })
  }
  restrictionAreaOptionSelection = (restriction_area) => {
    this.setState(
      { restriction_area }
    );

  }
  restrictionOptionSelection(e, name) {
    // console.log(e, 'name', name.value)
    this.setState({
      [e]: name
    });
  }
  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    // console.log(name, '---1', value)
    this.setState({ [name]: value });
  };
  handleChangeRakeId = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    // console.log(name, '---1', value)
    this.setState({ [name]: value });
  };
  closeAlert = () => {
    this.setState({
      show: false,
    });
  };
  handlerStartDateTime1 = async (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    var month = ("0" + (d.getMonth() + 1)).slice(-2);
    var day = ("0" + d.getDate()).slice(-2);
    var startdate = d.getFullYear() + "-" + month + "-" + day;
    // console.log(startdate, 'startdate1')
    await this.setState({
      informed_restriction_date: startdate,
    }, () => console.log(this.state.informed_restriction_date, 'infosed date in'));

  }
  // handlerStartDateTime = async (event, currentDate, selectedDate) => {
  //   var d = new Date(event._d);
  //   var month = ("0" + (d.getMonth() + 1)).slice(-2);
  //   var day = ("0" + d.getDate()).slice(-2);
  //   var startdate = d.getFullYear() + "-" + month + "-" + day;

  //   console.log(startdate, 'startdate')
  //   await this.setState({
  //     restriction_start_date: startdate,
  //     // restriction_end_date: ''
  //   }, () => console.log(this.state.restriction_start_date, 'startdate in'));
  //   // }
  // }

  handlerStartDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    var month = ("0" + (d.getMonth() + 1)).slice(-2);
    var day = ("0" + d.getDate()).slice(-2);
    var startdate = d.getFullYear() + "-" + month + "-" + day;
		if (this.state.restriction_end_date != undefined && this.state.restriction_end_date != "") {
			// console.log(startdate,"startdate")
			// console.log(this.state.restriction_end_date,"endDate")
			var startDate = new Date(startdate);
			var endDate = new Date(this.state.restriction_end_date);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			// console.log(startTime,endTime);
			if (startTime > endTime) {
				// this.setState({
				// 	startDate:""
				// });
				this.setState({
					show: true,
					basicTitle: 'End Date should be greater than Start Date',
					basicType: "danger",
					loadshow: 'show-n'
				});
			}
			else {
				this.setState({
					restriction_start_date: startdate
				});
			}
		}
		else {
			this.setState({
				restriction_start_date: startdate
			});
		}
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

  handlerEndDateTime = async (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    var month = ("0" + (d.getMonth() + 1)).slice(-2);
    var day = ("0" + d.getDate()).slice(-2);
    var edate = d.getFullYear() + "-" + month + "-" + day;
    await this.setState({
      restriction_end_date: edate
    }, () => console.log(this.state.restriction_end_date, 'enddate in'));
  }
  handleKeyPressCodeTrans = (event) => {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^[0-9]*$/.test(inputChar)) {
      event.preventDefault(); 
    }
  };
  handleKeyPressCodeRakeId = (event) => {
    const currentValue = event.target.value;
    const inputChar = String.fromCharCode(event.charCode);
    // if ((currentValue + inputChar).length > 4) {
    //     event.preventDefault(); 
    // }
    if (!/^[0-9,]*$/.test(inputChar)) {
        event.preventDefault(); 
    }
};
handleKeyPressCodeRakeId1 = (event) => {
  const currentValue = event.target.value;
  const inputChar = String.fromCharCode(event.charCode);
  if ((currentValue + inputChar).length > 4) {
      event.preventDefault(); 
  }
  if (!/^[0-9]*$/.test(inputChar)) {
      event.preventDefault(); 
  }
};
  onGridReady2 = params => {
    this.gridApi2 = params.api;

  };
  handleRemoveFile =  (filesName,rowId) => {
    this.setState({filesName:'',files:[]})
    console.log(filesName)
    if(filesName !='')
    {
        var rngParam = {
          rowId:rowId
        }
        redirectURL.post("/consignments/removefilefromdb",rngParam).then((response)=>{ 
        }).catch(function(error){
            console.log(error);
        })
    }
}
  render() {

    const batchwisecolumns = [
      {
        headerName: "Edit",
        field: "Edit",
        width: 110,
        resizable: true,
        editable: false,
        cellRenderer: function (params) {
          if (!params.data.not_planned_volumes) {
            var htmloption = '<button class="btn btn-warning" style="padding-top:0px">Edit</button>';
            return htmloption;
          } else {
            var htmloption = '<button class="btn btn-warning" disabled="on" style="padding-top:0px">Edit</button>';
            return htmloption;
          }
        },
        filter: false,
      },
      {
        headerName: "Delete",
        field: "delete",
        width: 110,
        resizable: true,
        editable: false,
        cellRenderer: function (params) {
          if (!params.data.not_planned_volumes) {
            var htmloption = '<button class="btn btn-primary" style="padding-top:0px">Delete</button>';
            return htmloption;
          } else {
            var htmloption = '<button class="btn btn-primary" disabled="on" style="padding-top:0px">Delete</button>';
            return htmloption;
          }
        },
      },
      {
        headerName: 'Entry Date and Time',
        field: "createdDate",
        valueGetter: (param) => {
          if (param.data.createdDate !== "") {
            return getHyphenDDMMMYYYYHHMM(param.data.createdDate);
          } else {
            return "";
          }
        },
      },
      {
        headerName: 'Transporter Name',
        field: "transporter_name",
      },
      {
        headerName: 'Transporter Code',
        field: "transporter_code",
      },
      {
        headerName: 'Restriction Area',
        field: "restriction_area",
      },
      {
        headerName: 'Informed Restriction Date',
        field: "informed_restriction_date",
        valueGetter: (param) => {
          if (param.data.informed_restriction_date !== "") {
            return getHyphenDDMMMYYYY(param.data.informed_restriction_date);
          } else {
            return "";
          }
        },
      },
      {
        headerName: 'Restricted Type',
        field: "restricted_type",
      },
      {
        headerName: 'Restriction Start Date',
        field: "restriction_start_date",
        valueGetter: (param) => {
          if (param.data.restriction_start_date !== "" && param.data.restriction_start_date !== undefined) {
            return getHyphenDDMMMYYYY(param.data.restriction_start_date);
          } else {
            return "";
          }
        },
      },
      {
        headerName: 'Restriction End Date',
        field: "restriction_end_date",
        valueGetter: (param) => {
          if (param.data.restriction_end_date !== "" && param.data.restriction_end_date !== undefined) {
            return getHyphenDDMMMYYYY(param.data.restriction_end_date);
          } else {
            return "";
          }
        },
      },
      {
        headerName: 'Restriction Days',
        field: 'restriction_days',
        cellRenderer: (params) => {
          var restriction_end_date = (moment.parseZone(new Date(params.data.restriction_end_date)).utcOffset("+00:00"));
          restriction_end_date = restriction_end_date.format("YYYY-MM-DD")
          let restriction_start_date;
          var number_of_days = 0

          restriction_start_date = (moment.parseZone(new Date(params.data.restriction_start_date)).utcOffset("+00:00")).format("YYYY-MM-DD");

          var restriction_end_date1 = moment(restriction_end_date)
          var restriction_start_date1 = moment(restriction_start_date)
          number_of_days = restriction_end_date1.diff(restriction_start_date1, 'days');
          var no_of_days = number_of_days + 1
          if (parseInt(no_of_days) > 0 && parseInt(no_of_days) != NaN && parseInt(no_of_days) != "NaN" && parseInt(no_of_days) != undefined && parseInt(no_of_days) != null) {
            var layoutdataRole = {
              totaldayscalculated: parseInt(no_of_days),
              rowId: params.data._id,
              type: 'Restriction days'
            };
            redirectURL.post('/consignments/restrictionDaysCalculsted', layoutdataRole, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
              }
            })
              .then((response) => {
              });

            return no_of_days;
          } else {
            return ""
          }
        }

      },
      {
        headerName: 'No. of Rakes Impacted',
        field: "no_of_rakes_impacted",
      },
      {
        headerName: 'Origin',
        field: "origin",
      },
      {
        headerName: 'MSIL Rake Id',
        field: "msil_rake_id",
      },

      {
        headerName: "Not Planned Volumes",
        field: "not_planned_volumes",
        editable: (params) => {
          
          return ((!(params.data.not_planned_volumes !== "" && params.data.not_planned_volumes !== undefined && params.data.not_planned_volumes !== null)) && (localStorage.getItem("username") == "Jashmeesh Singh" || localStorage.getItem("username") == "Pramod Bauddh" || localStorage.getItem("username") == "MaheshPrashad" || localStorage.getItem("username") == "SumitVishwakarma" || localStorage.getItem("username") == "Pankaj Jha" || localStorage.getItem("username") == "AdilFaruqi" || localStorage.getItem("username") == "Nanda Kishore"));
        },
        valueSetter: (params) => {
          const newValue = params.newValue;
          const regex = /^[0-9.]*$/;
      
          if (regex.test(newValue)) {
            const floatValue = newValue;
      
            var query = {
              rowId: params.data._id,
              not_planned_volumes: floatValue,
              type: "not_planned_volumes"
            };
      
            if (newValue !== "") {
              this.setState({
                alert: (
                  <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Proceed"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    // title="Are you sure?"
                    onConfirm={() => {
                      this.setState({ alert: null });
                      try {
                        params.data.not_planned_volumes = floatValue;
                        redirectURL.post("/consignments/setnotplannedvalume", query).then((response) => {
                          var resp = response.data.data;
                          this.gridApi2.redrawRows();
                        });
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                    onCancel={() => {
                      this.setState({ alert: null });
                      console.log("Operation cancelled.");
                    }}
                  >
                    Are you sure?<br/> Once confirmed, it will not be editable.
                  </SweetAlert>
                )
              });
            }
            return true;
          } else {
            this.setState({
              show: true,
              basicTitle: 'Only integers are allowed.',
              basicType: "danger",
              loadshow: 'show-n'
            });
          }
        }
      }
      ,
      {
        headerName: 'No. of Cars Invoiced',
        field: "no_of_cars",
      },
      {
        headerName: 'Type of Rakes',
        field: "type_of_rakes",
      },
      {
        headerName: 'Invoice Start Date',
        field: "invoice_start_date",
        valueGetter: (param) => {
          if (param.data.invoice_start_date !== "" && param.data.invoice_start_date !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.invoice_start_date);
          } else {
            return "";
          }
        },
      },
      {
        headerName: 'Invoice End Date',
        field: "invoice_end_date",
        valueGetter: (param) => {
          if (param.data.invoice_end_date !== "" && param.data.invoice_end_date !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.invoice_end_date);
          } else {
            return "";
          }
        },
      },
      {
        headerName: 'Departure Date',
        field: "departure_date",
        valueGetter: (param) => {
          if (param.data.departure_date !== "" && param.data.departure_date !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.departure_date);
          } else {
            return "";
          }
        },
      },
      {
        headerName: 'Delay',
        field: "delay",
      },
      {
        headerName: 'Attach Restriction Orders',
        field: "restriction_order",
        cellRenderer: function (params) {
          if (params.data.restriction_order && params.data.restriction_order.length > 0) {
            var htmloption = '<button class="btn btn-info" style="padding-top: 0px">Download</button>';
            return htmloption;
          } else {
            return '';
          }
        }
      },
    ];


    return (
      <div class="container-fluid">
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
         <div>
        {this.state.alert}
      </div>

        <div className="card col-xl-12">
          <div className="card-header">
            <h5>
              <i className="icofont icofont-vehicle-delivery-van cus-i"></i>{" "}
              <span>Restriction Imposed Screen</span>
              <button className="btn btn-info" style={{ float: "right", marginRight: "10px" }} onClick={this.addRestrictionImposed.bind(this)}>Add New Restriction Details</button>
              <div className="card-body">
                <div className="row">
                  <div
                    id="myGrid"
                    style={{ width: "100%", height: "478px" }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      modules={this.state.modules}
                      columnDefs={batchwisecolumns}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.rowData}
                      enableCharts={false}
                      onGridReady={this.onGridReady2}
                      onGridState={this.onGridState}
                      frameworkComponents={this.state.frameworkComponents}
                      onCellClicked={this.onCellClicked.bind(this)}
                      sideBar={this.state.sideBar}
                      stopEditingWhenGridLosesFocus={true}
                      paginationPageSize={this.state.paginationPageSize}
                      pagination={true}
                      gridOptions={{
                        context: { componentParent: this },
                      }}
                      enableRangeSelection={true}
                    />
                  </div>
                </div>
              </div>
            </h5>
          </div>

          <div className={"slide-r " + (this.state.bulkslide)} style={{ overflow: "scroll" }}>
            <h3 className="subH">New Restriction Imposed Details
              <span className="float-right fclose" style={{ marginRight: "12px", padding: "1px 8px", marginTop: "-2px" }} onClick={this.onClickHideAll.bind(this)}>x</span>
            </h3>
            <div className="slide-r-body" style={{ position: "relative" }}>
              <div className="container-fluid">
                <form id="initialAddingDetails"
                  onSubmit={this.onClickAddDetails.bind(this)}
                  className="row col-xl-12 col-lg-12">

                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Restriction Area<span style={{ color: 'red' }}> *</span> </label>
                    <Select
                      value={this.state.restriction_area}
                      closeMenuOnSelect={true}
                      onChange={this.restrictionAreaOptionSelection.bind(this)}
                      style={{ borderRadius: "0px" }}
                      options={this.state.restriction_area_options}
                      required
                    />
                  </div>
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Informed Restriction Date<span style={{ color: 'red' }}> *</span> </label>

                    <Datetime 
                            value={this.state.informed_restriction_date}
                            disableCloseOnClickOutside={false}
                            closeOnSelect={true}
                            inputProps={{ 
                              name: 'informed_restriction_date', 
                              autoComplete: 'off', 
                              readOnly: true, 
                              className: 'custom-datetime-input'
                            }}
                            dateFormat="DD-MM-YYYY"
                            timeFormat={false}
                            onChange={this.handlerStartDateTime1.bind(this)}
                          />
                  </div>
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Restricted Type<span style={{ color: 'red' }}> *</span> </label>
                    <Select
                      closeMenuOnSelect={true}
                      value={this.state.restricted_type}
                      name="restricted_type"
                      id="restricted_type"
                      onChange={this.restrictionOptionSelection.bind(this, "restricted_type")}
                      style={{ borderRadius: "0px" }}
                      options={[
                        { label: "Loading Station", value: "Loading Station" },
                        { label: "Unloading Station", value: "Unloading Station" }
                      ]}
                      required
                    />
                  </div>
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Restriction Start Date<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime value={this.state.restriction_start_date}
                      disableCloseOnClickOutside={false}
                      closeOnSelect={true}
                      inputProps={{ name: 'restriction_start_date', autoComplete: 'off', readOnly: true ,className: 'custom-datetime-input'}}
                      dateFormat="DD-MM-YYYY"
                      timeFormat={false}
                      onChange={this.handlerStartDateTime.bind(this)}
                      isValidDate={current => {
                        return current.isSameOrBefore(moment(), 'day');
                      }} />

                  </div>

                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Restriction End Date<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime value={this.state.restriction_end_date}
                      disableCloseOnClickOutside={false}
                      closeOnSelect={true}
                      inputProps={{ name: 'restriction_end_date', autoComplete: 'off', readOnly: true, className: 'custom-datetime-input' }}
                      dateFormat="DD-MM-YYYY"
                      timeFormat={false}
                      onChange={this.handlerEndDateTime.bind(this)}
                      isValidDate={current => {
                        return current.isSameOrAfter(moment(new Date(this.state.restriction_start_date)), 'day');
                      }} />
                  </div>
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>No. of Rakes Impacted<span style={{ color: 'red' }}> *</span> </label>
                    <input
                      type="text"
                      value={this.state.no_of_rakes_impacted}
                      name="no_of_rakes_impacted"
                      id="no_of_rakes_impacted"
                      className="form-control"
                      onKeyPress={this.handleKeyPressCodeTrans}
                      autoComplete="off"
                      onChange={this.handleChange}
                      required
                    />
                  </div>

                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>MSIL Rake Id </label>
                    <input
                      type="text"
                      value={this.state.msil_rake_id}
                      name="msil_rake_id"
                      id="msil_rake_id"
                      className="form-control"
                      onKeyPress={this.handleKeyPressCodeRakeId}
                      autoComplete="off"
                      onChange={this.handleChangeRakeId}
                    />
                  </div>
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Attach Restriction Order<span style={{ color: 'red' }}> *</span> </label>
                    <input type="file" name="uploadFile" id='uploadFile' onChange={this.restrictionFile} className="form-control" />
                  </div>

                  <div className="form-group col-xl-3 col-lg-3">
                    <button type="submit" className="btn btn-success">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className={"slide-r " + (this.state.bulkslide1)} style={{ overflow: "scroll" }}>
            <h3 className="subH">Edit Restriction Imposed Details
              <span className="float-right fclose" style={{ marginRight: "12px", padding: "1px 8px", marginTop: "-2px" }} onClick={this.onClickHideAll.bind(this)}>x</span>
            </h3>
            <div className="slide-r-body" style={{ position: "relative" }}>
              <div className="container-fluid">
                <form id="initialEditDetails"
                  onSubmit={this.onClickEditDetails.bind(this)}
                  className="row col-xl-12 col-lg-12">

                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Restriction Area<span style={{ color: 'red' }}> *</span> </label>
                    <Select
                      value={this.state.restriction_area}
                      closeMenuOnSelect={true}
                      onChange={this.restrictionAreaOptionSelection.bind(this)}
                      style={{ borderRadius: "0px" }}
                      options={this.state.restriction_area_options}
                      required
                    />
                  </div>
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Informed Restriction Date<span style={{ color: 'red' }}> *</span> </label>

                    <Datetime value={this.state.informed_restriction_date}
                      disableCloseOnClickOutside={false}
                      closeOnSelect={true}
                      inputProps={{ name: 'informed_restriction_date', autoComplete: 'off', readOnly: true,className: 'custom-datetime-input' }}
                      dateFormat="DD-MM-YYYY"
                      timeFormat={false}
                      onChange={this.handlerStartDateTime1.bind(this)}
                    //  isValidDate={current => {
                    //    return current.isSameOrBefore(moment(), 'day');
                    //  }}
                    />
                  </div>
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Restricted Type<span style={{ color: 'red' }}> *</span> </label>
                    <Select
                      closeMenuOnSelect={true}
                      value={this.state.restricted_type}
                      name="restricted_type"
                      id="restricted_type"
                      onChange={this.restrictionOptionSelection.bind(this, "restricted_type")}
                      style={{ borderRadius: "0px" }}
                      options={[
                        { label: "Loading Station", value: "Loading Station" },
                        { label: "Unloading Station", value: "Unloading Station" }
                      ]}
                      required
                    />
                  </div>
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Restriction Start Date<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime value={this.state.restriction_start_date}
                      disableCloseOnClickOutside={false}
                      closeOnSelect={true}
                      inputProps={{ name: 'restriction_start_date', autoComplete: 'off', readOnly: true ,className: 'custom-datetime-input'}}
                      dateFormat="DD-MM-YYYY"
                      timeFormat={false}
                      onChange={this.handlerStartDateTime.bind(this)}
                      isValidDate={current => {
                        return current.isSameOrBefore(moment(), 'day');
                      }} />

                  </div>

                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Restriction End Date<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime value={this.state.restriction_end_date}
                      disableCloseOnClickOutside={false}
                      closeOnSelect={true}
                      inputProps={{ name: 'restriction_end_date', autoComplete: 'off', readOnly: true,className: 'custom-datetime-input' }}
                      dateFormat="DD-MM-YYYY"
                      timeFormat={false}
                      onChange={this.handlerEndDateTime.bind(this)}
                      isValidDate={current => {
                        return current.isSameOrAfter(moment(new Date(this.state.restriction_start_date)), 'day');
                      }} />
                  </div>
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>No. of Rakes Impacted<span style={{ color: 'red' }}> *</span> </label>
                    <input
                      type="text"
                      name="no_of_rakes_impacted"
                      value={this.state.no_of_rakes_impacted}
                      id="no_of_rakes_impacted"
                      className="form-control"
                      onKeyPress={this.handleKeyPressCodeTrans}
                      autoComplete="off"
                      onChange={this.handleChange}
                      required
                    />
                  </div>

                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>MSIL Rake Id </label>
                    <input
                      type="text"
                      name="msil_rake_id"
                      value={this.state.msil_rake_id}
                      onKeyPress={this.handleKeyPressCodeRakeId1}
                      id="msil_rake_id"
                      className="form-control"
                      autoComplete="off"
                      onChange={this.handleChangeRakeId}
                    />
                  </div>
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Attach Restriction Order<span style={{ color: 'red' }}> *</span> </label>
                    <input type="file" name="uploadFile" id='uploadFile' onChange={this.restrictionFile} className="form-control" />
                    
                    {((this.state.filesName != "") && (this.state.filesName != undefined) && (this.state.filesName != [])) ?  
                          <div className="row col-xl-12">
                              <div className="col-xl-10">
                                  <p>{this.state.filesName}</p> 
                              </div>
                              
                              <div className="col-xl-1">
                                  <p  style={{cursor:"pointer"}}
                                    onClick={() => this.handleRemoveFile(this.state.filesName,this.state.rowId)}
                                  ><i title="Delete" className="fa fa-trash"></i></p>
                              </div>
                              <input type="hidden"  value={this.state.filesName} />
                          </div>
                          :
                          ""
                          }
                  </div>

                  <div className="form-group col-xl-3 col-lg-3">
                    <button type="submit" className="btn btn-success">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

        </div>


        <div className={"dataLoadpage " + (this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
          <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>
    );
  }
}



function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
  this.eInput = document.createElement('input');
  this.eInput.value = params.value;

  // Get today's date and format it as dd-mm-yy
  var today = new Date();

  // https://jqueryui.com/datepicker/
  $(this.eInput).datetimepicker({
    dateFormat: "dd-mm-yy",
    changeMonth: true,
    changeYear: true,
    // maxDate: today // Set the maxDate option to today's date
  });
};


// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
  return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
  this.eInput.focus();
  this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
  return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
  // but this example is simple, no cleanup, we could
  // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
  // and we could leave this method out also, false is the default
  return false;
};
function loadDateTimeScript() {
  // alert("timeDate");
  $(".datetimepicker_datew").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y",
    // minDate: true,
    timepicker: false,
    closeOnDateSelect: true,
    scrollMonth: false,
    scrollInput: false,
  });
  $(".datetimepicker_dateo").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y",
    minDate: true,
    timepicker: false,
    closeOnDateSelect: true,
    scrollMonth: false,
    scrollInput: false,
  });
  $(".cargo_date").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y",
    minDate: true,
    timepicker: false,
  });
}
function formatDate(dateString) {
  if (dateString != "Invalid Date" && dateString != "" && dateString != "NaN-NaN-NaN" && dateString != undefined && dateString != 'undefined') {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }
  else {
    return ""
  }
}


import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

var redirectURL = require('../redirectURL');

var criticaldata=[]
export default class RailShareChart extends Component {

    constructor(props) {
		super(props);
		this.state = {
			showgrid:'display:none',
			showtransgrid:'display:none',
			rowdata:[],
			test:"TEstng"
		}

	}
	componentDidMount = async () =>{
		
		$(".grid-"+this.props.code).hide();
		$(".grid-trans-"+this.props.code).hide();
		$(".dataLoadpagesum"+this.props.code).hide()
		$(".dataLoadpageimgsum"+this.props.code).hide()
		$(".overlay-partsum"+this.props.code).hide()

	}
	componentWillReceiveProps (newprops){
		//console.log("this.props.series ", this.props.categories)
		//document.getElementById ("clicktransport").addEventListener ("click", getData, false);
		// var el = document.getElementById('clicktransport');
		// if(el){
		//   el.addEventListener('click', getData, false);
		// }

		//console.log("asdasad",newprops.critical_data)
		//criticaldata=this.series.userOptions.critical_data;
	}
	
    render(){
    	//console.log("this.props.code) ", this.props.code)
        const dStyles={
				// width:'100%',
				// height:'100%'
		}
		const highchartoptions = {
			chart:{
				// type: 'spline',
                spacingBottom:20,
                width: 1200,
                dataLabels: true
			},
            title: {
                text: this.props.title
            },
        
            subtitle: {
                text: ''
            },
        
            yAxis: {
                title: {
                    text: this.props.yaxistitle
                }
            },
        
            xAxis: {
				// categories:this.props.categories,
				// title:{
				// 	text:this.props.xaxistitle
				// }
                type: 'category'
                // accessibility: {
                //     rangeDescription: 'Range: 2010 to 2017'
                // }
            },
        
            legend: {
                //layout: 'vertical',
                align: 'center',
				verticalAlign: 'bottom',
				y:0
            },
        
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                    },
					tooltip: {
						headerFormat: '<b>{series.name}</b><br>',
						pointFormat: '{point.value} : {point.y:.2f} %',
					},
                    enableMouseTracking: true,
                    cropThreshold: 500
                   // pointStart: 2010
                },
                spline: {
                    dataLabels: {
                        enabled: true,
                    },
                    tooltip: {
						headerFormat: '<b>{series.name}</b><br>',
						pointFormat: '{point.value} : {point.y:.2f} %',
					},
                    enableMouseTracking: true,
                    cropThreshold: 500
                },
                column: {
                    dataLabels: {
                        enabled: true,
                    },
                    tooltip: {
						headerFormat: '<b>{series.name}</b><br>',
						pointFormat: '{point.value} : {point.y:.2f} %',
					},
                    enableMouseTracking: true,
                    cropThreshold: 500
                }
            },
			
			// tooltip: {
				
			// 	shared: true
			// },
            series: this.props.series,
            drilldown: {
                series: this.props.drilldown
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            },
			credits:false
        };
        
        return ( 
			<div>
               <HighchartsReact style={{ width: '100%',}} containerProps={{ style: { width: '100%', height: '100%' } }}
                highcharts={Highcharts} style={dStyles} options={highchartoptions} />
            
			</div>
			);
		}
}

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../node_modules/react-resizable/css/styles.css';
import { WidthProvider, Responsive } from "react-grid-layout";
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import _ from "lodash";
import $ from "jquery";
var redirectURL = require('../../redirectURL');


export default class Overspeed extends Component{

	constructor(props)
	{
		super(props);
		this.state={
            speed:'',
            time:0,
            time_to_invalidate_after_notification:"",
            time_to_invalidate_before_notification:"",
            dept_code:"",
            long_haul_speed :"",
            short_haul_speed : "",
            userid:localStorage.getItem('userid'),
            email:localStorage.getItem('email'),
        };
        
		
    }
    componentDidMount(){
        // var dept_code = "SNDG";
        var dept_code = this.props.deptCode;
        var formdata = {
            config_type:'overspeed',
            dept_code : dept_code
        };
        
        redirectURL.post('/manage/kpiConfigurations',formdata, {
            headers:{
                'Content-type':'application/json'
            }
        })
        .then((response) => {
            console.log(response.data);
            if(response.data.length > 0)
            {
                if(response.data[0].min_time != 0){
                    $("#time_to_invalidate_before").val(response.data[0].time_to_invalidate_before_notification);
                    $("#time_to_invalidate_after").val(response.data[0].time_to_invalidate_after_notification);
                    $(".short_haul_speed").val(Number(response.data[0].short_haul_speed));
                    console.log(response.data[0].short_haul_speed);
                    $(".long_haul_speed").val(Number(response.data[0].long_haul_speed));
                    this.setState({
                        speed:response.data[0].speed,
                        // time:(response.data[0].threshold_time/60),
                        time : Number(response.data[0].minimum_overspeed_duration),
                        time_to_invalidate_before_notification : Number(response.data[0].time_to_invalidate_before_notification),
                        time_to_invalidate_after_notification : Number(response.data[0].time_to_invalidate_after_notification),
                        dept_code : dept_code,
                        long_haul_speed : response.data[0].long_haul_speed,
                        short_haul_speed : response.data[0].short_haul_speed,
                    });
                }
                else{
                    this.setState({
                        speed:response.data[0].speed,
                        // time:(response.data[0].threshold_time/60),
                        time : "",
                        time_to_invalidate_before_notification : "",
                        time_to_invalidate_after_notification : "",
                        long_haul_speed : "",
                        short_haul_speed : "",
                        long_haul_speed : "",
                        short_haul_speed : "",
                        dept_code : dept_code
                    });
                }
            }
            this.setState({
                dept_code : dept_code
            });

        })
    }
    changeInputHandler = (event) => {
        const re = /^[0-9\\:\b]+$/;
        if(re.test(event.target.value))
        {
            let name = event.target.name;
            let value = event.target.value;
            this.setState({[name]:value});
        }
    }
	formHandler = (event) => {
        event.preventDefault();
        if(this.state.dept_code != "LOG-TNP")
        {
            var formdata = {
                userid:this.state.userid,
                user_email:localStorage.getItem('email'),
                dept_code:this.state.dept_code,
                min_time:Number(this.state.time),
                kpitype:"overspeed",
                time_to_invalidate_after_notification : Number(this.state.time_to_invalidate_after_notification),
                time_to_invalidate_before_notification : Number(this.state.time_to_invalidate_before_notification), 
                speed:this.state.speed,
                // threshold_time:(this.state.time*60)
            }
        }
        else{
            var formdata = {
                userid:this.state.userid,
                user_email:localStorage.getItem('email'),
                dept_code:this.state.dept_code,
                min_time:this.state.time,
                kpitype:"overspeed",
                time_to_invalidate_after_notification :Number( this.state.time_to_invalidate_after_notification),
                time_to_invalidate_before_notification : Number(this.state.time_to_invalidate_before_notification), 
                // speed:this.state.speed,
                short_haul_speed : Number(this.state.short_haul_speed),
                long_haul_speed : Number(this.state.long_haul_speed)
                // threshold_time:(this.state.time*60)
            }
        }
        console.log(formdata)
        redirectURL.post('/manage/saveKPIConfigurations',formdata, {
            headers:{
                'Content-type':'application/json'
            }
        })
        .then((response) => {
            var res = response.data;
            if(res.result.result.ok == 1)
            {
                this.props.context.showAlert();
            }
            else{
                this.props.context.showErrorAlert();
            }
        })
    }
    changeDept(params){
        this.setState({
            dept_code:params.value
        })
    }

    selectDept(){
        let arr=[];
        try{
            arr.push({value:"SNDG",label:"Sales and Dispatch"});
            arr.push({value:"LOG-PRT",label:"Spare Parts"});
            arr.push({value:"LOG-TNP",label:"Train and Production"});
            return arr;
        }
        catch(e){

        } 
    }
	 render() {
        return (
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <h5>Overspeed Settings</h5>
                </div>
                <form className=" theme-form col-xl-8 col-lg-6" onSubmit={this.formHandler}>
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            {/* <label>Select Department : </label>
                            <Select 
                                placeholder={"Department"}
                                closeMenuOnSelect={true}
                                // isMulti="true"
                                // onChange={this.getRailConsginments.bind(this)} 
                                className={"border-radius-0"}
                                style={{borderRadius:"0px"}}
                                options={this.selectDept()}
                                onChange={this.changeDept.bind(this)}
                             />     */}
                        </div>        
                    </div>
                    {(this.state.dept_code != "LOG-TNP")?
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            <label className="c-lbl">Speed (Kmph) *:</label>
                            <input type="number" autoComplete="off" name="speed"  min="10" value={this.state.speed} style={{width:"30em"}} onChange={this.changeInputHandler.bind(this)} className="form-control validateNumber" placeholder="Speed Limit" required />
                        </div>
                    </div>
                    :
                    <div>
                        <div className="col-xl-12 col-lg-12">
                            <div className="form-group">
                                <label className="c-lbl">Short Haul Speed: (kmph) *:</label>
                                <input type="number" name="short_haul_speed" value={this.state.short_haul_speed} className="short_haul_speed" style={{width:"30em"}} onChange={this.changeInputHandler.bind(this)} className="form-control validateNumber"  required />
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <div className="form-group">
                                <label className="c-lbl">Long Haul Speed: (kmph) *:</label>
                                <input type="number" name="long_haul_speed" value={this.state.long_haul_speed} className="long_haul_speed"  style={{width:"30em"}} onChange={this.changeInputHandler.bind(this)} className="form-control validateNumber" required />
                            </div>
                        </div>
                    </div>
                    }
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            <label className="c-lbl">Minimum overspeed Duration (Minutes) *:</label>
                            <input type="number" name="time" value={this.state.time} min="2"  style={{width:"30em"}} onChange={this.changeInputHandler.bind(this)} className="form-control validateNumber" placeholder="Time" required />
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            <label className="c-lbl">Time to Invalidate Overspeeding Before Notification: (Minutes) *:</label>
                            <input type="number" name="time_to_invalidate_before_notification" id="time_to_invalidate_before" min="1" style={{width:"30em"}} onChange={this.changeInputHandler.bind(this)} className="form-control validateNumber"  required />
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            <label className="c-lbl">Time to Invalidate Overspeeding After Notification: (Minutes) *:</label>
                            <input type="number" name="time_to_invalidate_after_notification" id="time_to_invalidate_after" min="1" style={{width:"30em"}} onChange={this.changeInputHandler.bind(this)} className="form-control validateNumber" required />
                        </div>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-success cs-btn" id="save">Save</button>
                    </div>
                </form>
            </div>
        );
    }
}

$(document).ready(function(){
    $(document).on('keyup',".validateNumber",function(){
        // alert("click");
        if(isNaN($(this).val()) == true){
            $(this).val($(this).val().slice(0,-1));
        }
    });
    $(document).on("keyup",".validateEmail",function(e){
        if(e.which === 32){
            $(this).val($(this).val()+", ");
        }
    });
});
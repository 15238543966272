/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import DeleteItem from './deleteItem';
import EditroleAction from './editroleAction';  
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class TnpUserItems extends Component{
    constructor(props){
        super(props);
        this.state={
            show : false,
            rowSelection:'multiple',
            rowData: [],
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            basicTitle: "",
            deptCode: "",
            items: [],
            source: [],
            destination: [],
            deptCodeValue: [],
            operationsvalue : [],
            fetchItemsOptions: [],
            fetchSourceOptions: [],
            fetchDestinationOptions: [],
            operationValue : [],
            overly:"show-n",
            loadshow:"show-n",
            bulkslide:"",
            frameworkComponents: {
				// updateItem:UpdateItem,
                deleteItem:DeleteItem,
                EditroleAction:EditroleAction,
				//statusChange:StatusChange
			},
            fetchDeptCodeOptions: [],
            paginationPageSize:50,
            defaultColDef: {
              sortable: true,
              filter: true,
              resizable: true,
              pivot : true,
              enableValue: true,
              enableRowGroup: true,
            },
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
            basicType:"default",
            operationsData: [],
            filteredoperations :[],
            rowId : "",
            sliderTitleName:"Add Item",
            operation : "",

        }
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount(){
        this.getTptUserItems();
        this.getIsdDetails();  

    }

    getTptUserItems = () => {
        // var gridRowData =  []
        var data = []
        redirectURL.post("/consignments/gettptuseritems",{}).then((response)=>{
            data = response.data
            this.setState({
                rowData: data,
            })
        })
        redirectURL.post("/consignments/getuseritemmapping",{}).then((response1)=>{ 
            this.setState({                
                operationsData : response1.data
            })                       
        })        
        //dept codes 
        redirectURL.post("/consignments/getoperationassigndropdown",{}).then((response)=>{
            let {deptCode,} = response.data
            // console.log("getdetailsDrop",deptCode )
            let deptList = [];
            deptCode.map((item)=>{
                deptList.push({
                    label:item,
					value:item,
                })                
            })
            // console.log("deptCode", deptList)          
            this.setState({
                fetchDeptCodeOptions:deptList,                
            })
        })
    }

    getIsdDetails =() =>{
        redirectURL.post("/consignments/getitemssourcedestination",{}).then((response)=>{
            let {destination, items, source} = response.data
            // console.log("getdetails",destination, items, source )
            let itemsList = [];
            let sourceList = [];
            let destinationList = [];
            items.map((item)=>{
                itemsList.push({
                    label:item.toUpperCase(),
					value:item.toUpperCase(),
                })
            })
            let newArray = [];               
            // Declare an empty object 
            let totuniqueObject = {}; 
            var totobjTitle;
            // Loop for the array elements 
            for (let i in itemsList) { 
    
                // Extract the title 
                totobjTitle = itemsList[i]['value']; 
    
                // Use the title as the index 
                totuniqueObject[totobjTitle] = itemsList[i]; 
            } 
    
            // Loop to push unique object into array 
            for (var a in totuniqueObject) { 
                newArray.push(totuniqueObject[a]); 
            }
            // console.log(newArray,"newArray")
            source.map((item)=>{
                sourceList.push({
                    label:item,
					value:item,
                })
            })
            destination.map((item)=>{
                destinationList.push({
                    label:item,
					value:item,
                })
            })
            this.setState({
                fetchItemsOptions:newArray,
                fetchSourceOptions: sourceList,
                fetchDestinationOptions: destinationList
            })
        })
    }
    
    changeHandler = event => {
        // console.log("event", event)    
        this.setState({items: event});
	}

    changeSourceHandler = event => {  
        // this.setState({source: event});
        var source = $("#add_source").val();
        var destination = $("#add_destination").val();        
        if (source){
            this.setState({source})
        } else if(destination){
            this.setState({destination})
        }
        if(source !=='' && destination !=='')
        {
            // console.log("stateOPerations", this.state.operationsData)
            var items = this.state.operationsData.filter(rec => rec.source === source && rec.destination === destination);
            // console.log("itemsoperations", items)
            let filteredoperations = []
            items.map(e =>{
                filteredoperations.push({"label": e.operation_name, "value" : e.operation_name})
            })
            if (filteredoperations.length >0){
                this.setState({
                    filteredoperations
                });
            }
        }   
	}

    // changeDestinationHandler = event => {
    //     this.setState({destination: event});
	// }

    changeDeptCodeHandler =event =>{
        this.setState({deptCodeValue :event})
    }
    changeOperationsHandler = event =>{
        this.setState({operationsvalue : event})  
    }

    formHandler = (event) => {
        const {items, deptCodeValue,operationsvalue,rowId} = this.state
        event.preventDefault();
        // var letterNumber = /^[0-9a-zA-Z]+$/
        var userName = localStorage.getItem("username")
        var destination = $("#add_destination").val();
        var source = $("#add_source").val();
        var reqparam = {
            source : source,
            items : items.value,
            destination:destination,
            operationsvalue : operationsvalue.value,
            deptCodeValue: deptCodeValue.value,
            userName: userName
        }
        // console.log("reqparams", reqparam)
        var sourceVal = reqparam.source;
        var destinationVal = reqparam.destination;
        var itemsVal = reqparam.items
        var deptCodeVal = reqparam.deptCodeValue        
        if (this.state.sliderTitleName=== "Add User Item"){
            // $("#add_destination").val("");
            // $("#add_source").val("");
            if (itemsVal !== undefined && itemsVal !== "" && sourceVal !== undefined && destinationVal !== undefined && deptCodeVal !== undefined){
                if(sourceVal && destinationVal){
                    redirectURL.post("/consignments/addtptuseritems",reqparam).then((response)=>{
                        if (response.data.status === "Data Already Exists"){
                            this.setState({
                                basicType:"warning",
                                basicTitle : "Data Already Exists",
                                show: true
                            })
                        // this.getTptUserItems();
                        }else if (response.data.status === "success"){
                            $("#add_destination").val("")
                            $("#add_source").val("");
                            this.setState({
                                basicType:"success",
                                basicTitle : "Successfully Added",
                                show : true,
                                deptCode: "",
                                items: "",
                                source: "",
                                destination: "",
                                overly:"show-n",
                                loadshow:"show-n",
                                bulkslide : "",
                            });
                            this.getTptUserItems();
                        }else{
                            this.setState({
                                basicType:"danger",
                                basicTitle : "Failed to Add",
                                show : true,
                            })
                        }
                    })
                }else{
                    this.setState({
                        basicType:"warning",
                        basicTitle : "Please enter values",
                        show: true
                    })               
                }        
            }else{
                this.setState({
                    basicType:"warning",
                    basicTitle : "fields should not be empty",
                    show : true,                
                });
            }        
        }else{
            let params ={
                rowId,
                reqparam
            }
            // console.log("editbuttonsubmit", params)
            let sourceVal = params.reqparam.source;
            let destinationVal = params.reqparam.destination;
            let itemsVal = params.reqparam.items
            let deptCodeVal = params.reqparam.deptCodeValue
            // console.log('editvalue', params)
            if (this.state.sliderTitleName=== "Edit User Item"){
                if (itemsVal !== undefined && itemsVal !== "" && sourceVal !== undefined && destinationVal !== undefined && deptCodeVal !== undefined){
                    if(sourceVal && destinationVal){
                        redirectURL.post("/consignments/edittptuseritems",params).then((response)=>{
                            if (response.data.status === "Data Already Exists"){
                                this.setState({
                                    basicType:"warning",
                                    basicTitle : "Data Already Exists",
                                    show: true
                                })
                            // this.getTptUserItems();
                            }else if (response.data.status === "success"){                                
                                this.setState({
                                    basicType:"success",
                                    basicTitle : "Successfully Added",
                                    show : true,
                                    deptCode: "",                                   
                                    overly:"show-n",
                                    loadshow:"show-n",
                                    bulkslide : "",
                                    items: [],
                                    source: [],
                                    destination: [],
                                    deptCodeValue: [],                                    

                                });
                                this.getTptUserItems();
                            }else{
                                this.setState({
                                    basicType:"danger",
                                    basicTitle : "Failed to Add",
                                    show : true,
                                })
                            }
                        })
                    }else{
                        this.setState({
                            basicType:"warning",
                            basicTitle : "Please enter values",
                            show: true
                        })               
                    }        
                }else{
                    this.setState({
                        basicType:"warning",
                        basicTitle : "fields should not be empty",
                        show : true,                
                    });
                }
            }


        }
    }

    handleChange(event) {       
        // let name = event.currentTarget.name;
        // let value = event.currentTarget.value;
        // this.setState({[name]:value});
    }
   
    onCellRowClicked =e =>{
        if(e.colDef.field == "update"){
            var rowId = e.data._id;
            // console.log("e", e)
        }
    }

    onShowEditMenu(params){
        var query = {
            "_id" : params.data._id
        }
        // console.log(this.state.operationsData, "operationsData")
        redirectURL.post("/consignments/gettptuseritems",query).then((response)=>{
            // console.log("responseId", response.data[0])
            $("#add_destination").val(response.data[0].destination)
            $("#add_source").val(response.data[0].source);

            this.setState({
                items: {"value":response.data[0].items,"label":response.data[0].items},
                deptCodeValue: {"value":response.data[0].dept_code,"label":response.data[0].dept_code} ,
                rowId:params.data._id,
                sliderTitleName: "Edit User Item",
                bulkslide:'slider-translate',
                overly:'show-m',
            })
            // console.log("stateitems", this.state.items, typeof(this.state.items))
        }).catch((e)=>{
            console.log(e);
        })
    }

    deleteRowHandler = (params) =>{
        let rowData = this.state.rowData
        const rowFiltered = rowData.filter(m => m._id !== params._id);
        this.setState({ rowData :  rowFiltered});
        // console.log("rowf", rowFiltered)
        let par = {
            _id: params._id
        }
        let cnf = window.confirm("Are you sure you want to Delete?")
            if(cnf === true){
                redirectURL.post("/consignments/deleteTnpUserItem",par).then((response) =>{
                    let data = response.data
                    // console.log("dataresponse", data)
                    if (data.status === "success"){
                        this.setState({
                            basicType:"success",
                            basicTitle : "Successfully Deleted",
                            show : true,                    
                            // overly:"show-n",
                            // loadshow:"show-n",
                        })
                    }else{
                        this.setState({
                            show: true,
                            basicTitle:'Something went wrong',
                            basicType:"danger",
                        });
                    }
        
                })
            }

    }
    changeScreen = () =>{
        this.setState({
            sliderTitleName: "Add User Item",
            overly:"show-m",
            loadshow:"show-m",
            bulkslide : "slider-translate-30p",
        })
		// $("#tptUserItemGrid").css("display","none");
		// $(".user-item-card").css("display","flex");
		// $("#addUserItem").css("display","none");
		// $("#previous-screen").css("display","initial");		
	}

    previousscreen = () =>{
        this.setState({
            overly:"show-n",
            loadshow:"show-n",
            bulkslide : "",             
        })

        // $("#tptUserItemGrid").css("display","flex");
		// $(".user-item-card").css("display","none");
		// $("#addUserItem").css("display","block");
        // $("#previous-screen").css("display","none");
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
	}

    onClickHideAll(){
        $("#add_destination").val("");
        $("#add_source").val("");
        this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:"",
        items: [],
        source: [],
        destination: [],
        deptCodeValue: [],    
        });        
    }

    onGridReady = params => {
		this.gridApi = params.api;		
	    // console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;		   
	};
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);
    }


    render(){

        const columnwithDefs= [
            // {
            //     headerName : "",
            //     field : "update",
            //     width:100,
            //     editable : false,
            //     pinned:"left",
            //     cellRenderer: function(params){
            //         return "<button class='btn btn-info' style='padding:0px;width:76px'>Update</button>"
            //     }
            // },
            {
                pinned:"left",
                headerName : "Edit",
                field:"_id",
                width:100,
                resizable : true,
                editable : false,
                cellRendererSelector:function(params){       
                    var rendComponent = {
                        component: 'EditroleAction'
                    };
                    return rendComponent;
                },
            },
            {
                headerName: "Action",
                field: "_id",
                width: 100,
                filter: false,
                cellRenderer:'deleteItem',
                editable:false
            },
            {
                headerName: "View",
                field: "_id",                
                filter: true,
                width:100,
                hide: true,               
            },
              {
                headerName: "Item",
                field: "items",                
                filter: true,
                editable: true,
                width:160,
                resizable: true,               
            },
            {
                headerName: "Source",
                field: "source",                
                filter: true,
                editable: true,
                width:120,
                resizable: true,               
            },
            {
                headerName: "Destination",
                field: "destination",                
                filter: true,
                editable: true,
                width:120,
                resizable: true,               
            },
            {
                headerName: "Dept Code",
                field: "dept_code",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,                           
            }, 
            {
                headerName: "Operations",
                field: "operations",                
                filter: true,
                editable: false,
                width:300,
                resizable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: this.state.operations,
                },               
            },
           
        ]
        return(
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                    >
	             </SweetAlert>
                 {this.state.alert}
                 <div className="row">
                 <div className=" col-xl-12 col-lg-12">
                    <div className="card">
                        <div className="card-header space-between">
                             <h5 className="float-left">
                                <i className="icon-map cus-i"></i><span> TNP User Maping</span>
                             </h5>
                            <div>
                             <button className="btn btn-success pull-right cs-btn" id="addUserItem" onClick={this.changeScreen.bind(this)} style={{color:"white"}}> <i className="icon-plus"></i>Add Item </button>
                                <button className="btn btn-warning" id="previous-screen" onClick={this.previousscreen.bind(this)} style={{display:"none",padding: "9px",float: "right",width: "87px"}}><i className="fa fa-arrow-left"></i> Back</button>
                            </div>                        
                        </div>
                        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
                            <h3 className="subH">{this.state.sliderTitleName} </h3>
                            <div className="slide-r-body" style={{position:"relative"}}>             
                                <form className=" theme-form col-xl-8 col-lg-8" onSubmit={this.formHandler}>
	                    			<div className="col-xl-12 col-lg-12">
                                        <div className="form-group">
					               			<label className="c-lbl">Items:</label>		               			
					               			<Select                                              
                                               onChange={this.changeHandler.bind(this)} 
                                               style={{borderRadius:"0px", width: "80%"}} 
                                               options={this.state.fetchItemsOptions}
                                               placeholder = {"Select Item"}
                                               closeMenuOnSelect={true}
                                               value={this.state.items}
                                               required />
					               	   </div>
                                        {/* <div className="form-group">
					               			<label className="c-lbl">Source:</label>					               			
					               			<Select
                                               onChange={this.changeSourceHandler} 
                                               style={{borderRadius:"0px"}}
                                               name="source" id="add_source"
                                               options={this.state.fetchSourceOptions}
                                               closeMenuOnSelect={true}
                                               placeholder={"Select Source"}
                                               value={this.state.source}
                                               required />
					               	   </div>
                                        <div className="form-group">
					               			<label className="c-lbl">Destination:</label>					               			
					               			<Select
                                               onChange={this.changeDestinationHandler}
                                               closeMenuOnSelect={true}
                                               name="destination" id="add_destination"
                                               style={{borderRadius:"0px"}} 
                                               options={this.state.fetchDestinationOptions}
                                               placeholder={"Select Destination"}
                                               value={this.state.destination}
                                               required />
					               	    </div> */}
                            <div className="form-group mt-20p">
                                <label className="">Source</label> 
                                <select name="add_source" id="add_source" className="form-control"  onChange={this.changeSourceHandler}  required>
                                    <option value="">Select Source</option>
                                    {(this.state.fetchSourceOptions.map((sd) =>
                                        <option value={sd.value}>{sd.label}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="form-group mt-20p">
                                <label className="">Destination</label> 
                                <select name="add_destination" id="add_destination" className="form-control"  onChange={this.changeSourceHandler}  required>
                                    <option value="">Select Destination</option>
                                    {(this.state.fetchDestinationOptions.map((sd) =>
                                        <option value={sd.value}>{sd.label}</option>
                                    ))}
                                </select>
                            </div>
                                        <div className="form-group">
					               			<label className="c-lbl">User Dept Code</label>					               			
					               			<Select                                               
                                               onChange={this.changeDeptCodeHandler} 
                                               style={{borderRadius:"0px", width: "80%"}} 
                                               options={this.state.fetchDeptCodeOptions}
                                               placeholder = {"Select Dept Code"}
                                               value={this.state.deptCodeValue} 
                                               required />
					               	    </div>
                                           <div className="form-group">
					               			<label className="c-lbl">operations</label>					               			
					               			<Select                                               
                                               onChange={this.changeOperationsHandler} 
                                               style={{borderRadius:"0px", width: "80%"}} 
                                               options={this.state.filteredoperations}
                                               placeholder = {"Select operations"}
                                               value={this.state.operationsvalue} 
                                               required />
					               	    </div>
										<div className="form-group">
					               			<button type="submit" className="btn btn-success cs-btn" id="saveevent">Save</button>
					               		</div>
									</div>									
				            	</form>                      
                            </div>
                        </div>
                        <div id="tptUserItemGrid" className="card-body col-sm-11">                        
									<div id="myGrid" style={{ height: "580px",width: "100%",}}  className="ag-theme-balham">
										<AgGridReact
										modules={this.state.modules}
										columnDefs={columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										// onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
										enableCharts={true}
										enableRangeSelection={true}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
                                        frameworkComponents={this.state.frameworkComponents}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										detailCellRendererParams={this.state.detailCellRendererParams}
										stopEditingWhenGridLosesFocus={true}
										enableCellChangeFlash={true}
										suppressCellFlash={true}
										rowClassRules={this.state.rowClassRules}
										//onCellClicked={this.onRowClicked.bind(this)}
										gridOptions={{
											context: { componentParent: this }
										}}
										masterDetail={true}
										//getRowHeight={this.state.getRowHeight}
										// onCellEditingStopped={this.onCellUpdateData.bind(this)}
										onCellClicked={this.onCellRowClicked}			
										rowSelection={this.state.rowSelection}
										// onRowSelected={this.onRowSelection.bind(this)}
										suppressRowClickSelection={true}
                                        components={this.state.components}
										// suppressCellSelection= {true}
										// overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}										
										/>
									</div>								
                        </div>
                       
                     </div>
                 </div>
                 </div>
                 <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
           
        )
    }

}
import React, { Component } from "react";
import CSVFileValidator from 'csv-file-validator';
import moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MenuModule } from "@ag-grid-enterprise/menu";
import Select from "react-select";
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import $ from "jquery";
import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import DrawMap2 from '../common/drawmap2';
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import * as Datetime from 'react-datetime';
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import "@ag-grid-community/core/dist/styles/ag-grid.css";

import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { color } from "highcharts";
import download from 'js-file-download';
import { reverse } from "lodash";
// import { formatDate } from "@fullcalendar/react";
var redirectURL = require("../redirectURL");
var infoBox = require('../common/google-infowindow');

var googleAnalytics = require("../common/googleanalytics");
var map;
var markersArray;
var viewMarkersArr = [];
export default class GPSReview extends Component {
  constructor(props) {
    super(props);
    var date2 = new Date();
    var currentDateTime = moment(date2).format("YYYY-MM-DD");
    var startDate1 = moment(date2).subtract(90, 'days').format("YYYY-MM-DD")
    this.state = {
      modules: AllModules,
      eventFormAction: "formAction",
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        width: 160,
        resizable: true,
      },
      rowSelection: "single",
      eventAction: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      bulkslide: "",
      Main_rowid: "",
      slider_header: "",
      slider_type: "",
      files: [],
      files2: [],
      timelinesmarkers: [],
      truck_no: "",
      rake_name: [{ label: '', value: '' }],
      rake_names: [],
      sliderRouteTranslate: '',
      rownode: '',
      baseRouteCoords: [],
      stabling_end_map: "",
      stabling_start_map: "",
      filterlspData: [],
      lsp_name_filter: ({ value: "ALL", label: "ALL" }),
      doc_number: '',
      average_ratings:[],
      type: [],
      mapinfo: '',
      force_major: [],
      msil_rake_id: '',
      arrival_at_vicinity: '',
      stabling_start: '',
      stabling_end: '',
      mr_rr_value: '',
      lsp_remarks: "",
      bulkslide:'',
      sliderbulkupload: "",
      rowModelhighchartoptionsType: "enterprise",
      frameworkComponents: {
        consignmentActions: ConsignmentActions,
      },
      rowGroupPanelShow: "always",
      statusBar: {

      },
      paginationPageSize: 50,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      startDate: startDate1,
      endDate: currentDateTime,
      animateRows: true,
      dept_code: "",
      pagetitle: "",
      csvData: 0,
      overly: "show-n",
      loadshow: "show-n",
      pagetitle: "",
      eventGridAction: "gridAction",
      csvcontent: [],
      file: "",
      batch_name: "",
      cluster_breaks: "2",
      cluster_radius: "0.5",
      notification_emails: "",
      transporterList:[],
        transporters:{label:"ALL",value:"ALL"},
        access_transporters:{label:"ALL",value:"ALL"},
        gps_provider:{label:"ALL",value:"ALL"},
      bulkslidebulk: '',
      newValue: "",
      rowData: [],
      rowId: "",
      show: false,
      basicTitle: "",
      basicType: "",
      columnWithDefs:[],
      month:"",
      access_month:""
    };
  }
  async componentDidMount() {
    this.setState({
        overly: 'show-m',
        loadshow: 'show-m',
    });
    var review_month = moment.parseZone(new Date()).format("YYYY-MM")
    this.setState({
      month : review_month
    })
    
    await redirectURL.post("/consignments/gettransporterslistforceodashboard").then((response)=>{
        var resp = response.data
        var transporterList= []
        transporterList.push({
            label:"ALL",
            value:"ALL"
        })
        resp.map((e)=>{
            transporterList.push({
                label: e.transporter_name,
                value: e.transporter_code,
            });
        })
        this.setState({
            transporterList:transporterList,
        })
    })
    var params ={
      review_month:review_month,
      transporter:this.state.transporters.value
    }
    await redirectURL.post("/dashboard/getTransportersgpsreviewData",params).then((response)=>{
        var resp = response.data.data
        // console.log(resp,"181")
      if(resp.length > 0){
          ///////////////////////////////////////ROWDATA////////////////////////////////////////////

          var rowData = []
          var pinnedBottomRowData = {}
          var main_average_score_count = 0
          var transporterwsie_score_count = 0
          var resp1 = groupBy(resp,e=>e.parameters)
              resp1.forEach((data,check)=>{
                  var resp2 = groupBy(data, f=>f.category)
                      resp2.forEach((data2,check2)=>{
                          let rowDict = {}
                          var count = 0
                          data2.map((e) => {
                              count = (e.ratings_numbers + count)
                              rowDict[`${e.transporter_code}_remarks`] = e.remarks
                              rowDict[`${e.transporter_code}_ratings`] = e.ratings_numbers+" - "+e.ratings
                              rowDict["parameters"] = e.parameters
                              rowDict["category"] = e.category
                              rowDict["check_parameters"]= e.check_parameters
                              rowDict["description"] = e.description
                              if(check2 != "Specific Requirement"){
                                pinnedBottomRowData[`${e.transporter_code}_ratings`] = pinnedBottomRowData[`${e.transporter_code}_ratings`] || 0
                                pinnedBottomRowData[`${e.transporter_code}_ratings`] += e.ratings_numbers / 15
                              }
                          })
                          rowDict["average_score"] = (count/data2.length).toFixed(1)
                          if(check2 != "Specific Requirement"){
                            main_average_score_count = parseFloat(count/data2.length)+main_average_score_count
                          }
                          console.log(main_average_score_count,"204")
                          rowData.push(rowDict)
                      })
              })
              pinnedBottomRowData["description"] = "Total"
              pinnedBottomRowData["average_score"] = parseFloat(main_average_score_count)/15.0
              // console.log(Object.keys(pinnedBottomRowData),"218")
              Object.keys(pinnedBottomRowData).map((e) =>{
                if(e.includes("ratings") || e.includes("average")){
                  pinnedBottomRowData[e] = pinnedBottomRowData[e].toFixed(1)
                }
              })
             
          ///////////////////////////////////END/////////////////////////////////////////////////
  
          //////////////////////////////////COLUMNS//////////////////////////////////////////////
  
          var columns = []
              columns.push({
              headerName: "",
              headerClass: ["", "cellTopHeader"],
              field: "",
              width: 250,
              // chartDataType: "excluded",
              children:[
                  {
                  headerName : "Parameters",
                  field: "check_parameters",
                  width: 120,
                  filter: true,
                  cellClass: function(params){
                      if(params.data.check_parameters !=""){
                          if(params.data.check_parameters == "HARDWARE"){
                              return ["cellstylegridY","pstBg"]
                          }
                          else if(params.data.check_parameters == "SOFTWARE"){
                              return ["cellstylegridM","pstBg"]
                          }
                          else if(params.data.check_parameters == "FIELD SERVICE"){
                              return ["cellstylegridSk","pstBg"]
                          }
                              
                      }
                      return null
                  },
                  rowSpan: function(params){
                      if(params.data.check_parameters != ""){
                          if(params.data.check_parameters == "HARDWARE"){
                              return 5;
                          }else if(params.data.check_parameters == "SOFTWARE"){
                              return 6
                          }else if(params.data.check_parameters == "FIELD SERVICE"){
                              return 4
                          }
                      }
                      return 1
                  },
                  },
                  {
                      headerName : "Category",
                      field: "category",
                      width: 180,
                      filter: true,
                  },
                  {
                      headerName : "Description",
                      field: "description",
                      width: 300,
                      filter: true,
                  
                  },
                  {
                    headerName : "Average Score",
                    field: "average_score",
                    width: 100,
                    filter: true,
                    valueGetter:function(params){
                      if(params.data.category == "Specific Requirement"){
                        return ""
                      }else{
                        return params.data.average_score
                      }
                    }
                
                },
              ]
              })
              var resp3  = groupBy(resp,f=>f.transporter_code)
                  resp3.forEach((data3,check3)=>{
                    var resp4 = groupBy(data3,g=>g.transporter_name)
                      resp4.forEach((data4,check4)=>{
                        columns.push({
                          headerName: `${check3} - ${check4}`,
                          headerClass: ["", "cellTopHeader"],
                          field: "",
                          width: 360,
                          // chartDataType: "excluded",
                          children:[
                              {
                                  headerName : "Ratings",
                                  field:`${check3}_ratings` ,
                                  width: 120,
                                  filter: true,
                                  valueGetter:function(params){
                                    if(params.data.category == "Specific Requirement"){
                                      return ""
                                    }else{
                                      return params.data[`${check3}_ratings`]
                                    }
                                  }
                              },
                              {
                                  headerName : "Remarks",
                                  field: `${check3}_remarks`,
                                  width: 120,
                                  filter: true,
                              },
                          ]
                          })
                      })
                      
                  })
          ////////////////////////////////////END///////////////////////////////////////////////
                  // console.log(pinnedBottomRowData,"pinnedBottomRowData")
          this.setState({
              rowData:rowData,
              average_ratings:[pinnedBottomRowData],
              columnWithDefs:columns,
              loadshow:"show-n",
              overly:"show-n"
          })
      }else{
        this.setState({
          rowData:[],
          average_ratings:[{}],
          columnWithDefs:[],
          loadshow:"show-n",
          overly:"show-n"
      })
      }
    })
  }
  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      bulkslide:"",
      access_month:""
    });
  }

  newFilter = async (filterType, selOption) => {
    // console.log(filterType, selOption, 'selected name');
    this.setState({ [filterType]: selOption });
};
 getfilterReviewData=async(event)=>{
      event.preventDefault()
      this.setState({
        overly: 'show-m',
        loadshow: 'show-m',
      });
      var transporter_code = this.state.transporters.value
      var review_month = this.state.month
      var gps_provider = this.state.gps_provider.value
    var params = {
        "transporter_code": transporter_code,
        "review_month":review_month,
        "gps_provider":gps_provider
    };
    // console.log(params,"311")
    await redirectURL.post("/dashboard/getTransportersgpsreviewData",params).then((response)=>{
        var resp = response.data.data
       
      if(resp.length > 0){
        ///////////////////////////////////////ROWDATA////////////////////////////////////////////
        var rowData = []
       var pinnedBottomRowData = {}
       var main_average_score_count = 0
       var transporterwsie_score_count = 0
       var resp1 = groupBy(resp,e=>e.parameters)
           resp1.forEach((data,check)=>{
               var resp2 = groupBy(data, f=>f.category)
                   resp2.forEach((data2,check2)=>{
                       let rowDict = {}
                       var count =0
                       data2.map((e) => {
                           count = (e.ratings_numbers + count)
                           rowDict[`${e.transporter_code}_remarks`] = e.remarks
                           rowDict[`${e.transporter_code}_ratings`] = e.ratings_numbers+" - "+e.ratings
                           rowDict["parameters"] = e.parameters
                           rowDict["category"] = e.category
                           rowDict["check_parameters"]= e.check_parameters
                           rowDict["description"] = e.description
                           if(check2 != "Specific Requirement"){
                            pinnedBottomRowData[`${e.transporter_code}_ratings`] = pinnedBottomRowData[`${e.transporter_code}_ratings`] || 0
                                pinnedBottomRowData[`${e.transporter_code}_ratings`] += e.ratings_numbers / 15
                           }
                       }) 
                       rowDict["average_score"] = (count/data2.length).toFixed(1)
                       if(check2 != "Specific Requirement"){
                         main_average_score_count = parseFloat(count/data2.length)+main_average_score_count
                       }
                      //  console.log(main_average_score_count,"204")
                       rowData.push(rowDict)
                   })
           })
           pinnedBottomRowData["description"] = "Total"
           pinnedBottomRowData["average_score"] = parseFloat(main_average_score_count)/15.0
          //  console.log(Object.keys(pinnedBottomRowData),"417")
           Object.keys(pinnedBottomRowData).map((e) =>{
            if(e.includes("ratings") || e.includes("average")){
              pinnedBottomRowData[e] = pinnedBottomRowData[e].toFixed(1)
            }
          })
          
       ///////////////////////////////////END/////////////////////////////////////////////////

       //////////////////////////////////COLUMNS//////////////////////////////////////////////

       var columns = []
           columns.push({
           headerName: "",
           headerClass: ["", "cellTopHeader"],
           field: "",
           width: 250,
           // chartDataType: "excluded",
           children:[
               {
               headerName : "Parameters",
               field: "check_parameters",
               width: 120,
               filter: true,
               cellClass: function(params){
                   if(params.data.check_parameters !=""){
                       if(params.data.check_parameters == "HARDWARE"){
                           return ["cellstylegridY","pstBg"]
                       }
                       else if(params.data.check_parameters == "SOFTWARE"){
                           return ["cellstylegridM","pstBg"]
                       }
                       else if(params.data.check_parameters == "FIELD SERVICE"){
                           return ["cellstylegridSk","pstBg"]
                       }
                           
                   }
                   return null
               },
               rowSpan: function(params){
                   if(params.data.check_parameters != ""){
                       if(params.data.check_parameters == "HARDWARE"){
                           return 5;
                       }else if(params.data.check_parameters == "SOFTWARE"){
                           return 6
                       }else if(params.data.check_parameters == "FIELD SERVICE"){
                           return 4
                       }
                   }
                   return 1
               },
               },
               {
                   headerName : "Category",
                   field: "category",
                   width: 180,
                   filter: true,
               },
               {
                   headerName : "Description",
                   field: "description",
                   width: 300,
                   filter: true,
               
               },
               {
                 headerName : "Average Score",
                 field: "average_score",
                 width: 100,
                 filter: true,
                 valueGetter:function(params){
                   if(params.data.category == "Specific Requirement"){
                     return ""
                   }else{
                     return params.data.average_score
                   }
                 }
             
             },
           ]
           })
           var resp3  = groupBy(resp,f=>f.transporter_code)
               resp3.forEach((data3,check3)=>{
                 var resp4 = groupBy(data3,g=>g.transporter_name)
                   resp4.forEach((data4,check4)=>{
                     columns.push({
                       headerName: `${check3} - ${check4}`,
                       headerClass: ["", "cellTopHeader"],
                       field: "",
                       width: 360,
                       // chartDataType: "excluded",
                       children:[
                           {
                               headerName : "Ratings",
                               field:`${check3}_ratings` ,
                               width: 120,
                               filter: true,
                               valueGetter:function(params){
                                 if(params.data.category == "Specific Requirement"){
                                   return ""
                                 }else{
                                   return params.data[`${check3}_ratings`]
                                 }
                               }
                           },
                           {
                               headerName : "Remarks",
                               field: `${check3}_remarks`,
                               width: 120,
                               filter: true,
                           },
                       ]
                       })
                   })
                   
               })
       ////////////////////////////////////END///////////////////////////////////////////////
              //  console.log(pinnedBottomRowData,"pinnedBottomRowData")
       this.setState({
           rowData:rowData,
           average_ratings:[pinnedBottomRowData],
           columnWithDefs:columns,
           loadshow:"show-n",
           overly:"show-n"
       })
      }else{
        this.setState({
          rowData:[],
          average_ratings:[{}],
          columnWithDefs:[],
          loadshow:"show-n",
          overly:"show-n"
      })
      }
       
    })
 }
  onGridReady = params => {
    this.gridApi = params.api;
    // //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
  };
  onGridState = () => {
    //console.log(this.gridApi);
    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();
    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
    /*Get Current Pivot Columns State and Store in this.pivotColumns,
     * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
}
handlerStartDateTime = (event, currentDate, selectedDate) => {
  var d = new Date(event._d);
  var selDay = (d.getMonth() + 1);
  if(parseInt(selDay) < 10)
  {
      selDay = "0"+selDay; 
  }
  var plan_month = d.getFullYear() + "-" + selDay;
  this.setState({
      month: plan_month
  });
}
handlerStartDateTime1 = (event, currentDate, selectedDate) => {
  var d = new Date(event._d);
  var selDay = (d.getMonth() + 1);
  if(parseInt(selDay) < 10)
  {
      selDay = "0"+selDay; 
  }
  var plan_month = d.getFullYear() + "-" + selDay;
  this.setState({
      access_month: plan_month
  });
}
renderMonthContent = (month, shortMonth, longMonth, day) => {
  const fullYear = new Date(day).getFullYear();
  const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

  return <span title={tooltipText}>{shortMonth}</span>;
}
closeAlert = ()=>{
  this.setState({
    show:false
  })
}
addConditionData = ()=>{
  this.setState({
    bulkslide : "slider-translate-30p",
    overly:"show-m"
  })
}
updateFormAccessControl=(event)=>{
  event.preventDefault()
  this.setState({
    overly:"show-m",
    loadshow:"show-m"
  })
  var access_month = this.state.access_month
  // var access_transporter = this.state.access_transporters.value
  if(access_month != undefined && access_month != "" && access_month != null){
    var params ={
      access_month : moment.parseZone(new Date(access_month)).format("YYYY-MM"),
      // access_transporter : access_transporter,
      username : localStorage.getItem("username") 
    }
    // console.log(params,"621")
    redirectURL.post("/dashboard/saveReviewScreenAccessControlLogs",params).then((response)=>{
      if(response.data.message == "Success"){
        this.setState({
          basicTitle:"Access Provided Successfully",
          basicType:"success",
          show:true,
        })
        this.onClickHideAll()
      }else{
        this.setState({
          basicTitle:response.data.message,
          basicType:"warning",
          show:true,
          overly:"show-n",
          loadshow:"show-n"
        })
      }
    })
  }else{
    this.setState({
      basicTitle:"Please Enter Mandatory Fields",
      basicType:"warning",
      show:true,
      overly:"show-n",
      loadshow:"show-n"
    })
  }
  
}
  render() {
    return (
      <div class="container-fluid">
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
        <div className="row card-body" style={{display:"flex",justifyContent:"space-between"}}>
          <div className="row col-xl-12 col-lg-12" style={{marginBottom:"10px"}}>
            
          </div>
            <form method="POST" id="upform" className="theme-form col-xl-12 col-lg-12" onSubmit={this.getfilterReviewData.bind(this)}>
                <div className="row">
                  <div className="form-group col-xl-3 col-lg-3">
                    <label>Select GPS Provider<span style={{color:"#FF0000"}}>*</span></label>
                      <Select
                          placeholder={"Select GPS Provider"}
                          closeMenuOnSelect={true}
                          value={this.state.gps_provider}
                          menuPortalTarget={document.body} 
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          onChange={this.newFilter.bind(this,"gps_provider")}
                          style={{ borderRadius: "0px" }}
                          options={[{label:"ALL",value:"ALL"},{label:"ENMOVIL",value:"ENMOVIL"},{label:"AXESTRACK",value:"AXESTRACK"}]} required
                      />
                  </div>
                  <div className="form-group col-xl-3 col-lg-3">
                    <label>Select Transporter<span style={{color:"#FF0000"}}>*</span></label>
                      <Select
                          placeholder={this.state.transporters.label == "undefined" ? "Select Transporter" : ''}
                          closeMenuOnSelect={true}
                          value={this.state.transporters}
                          menuPortalTarget={document.body} 
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          onChange={this.newFilter.bind(this,"transporters")}
                          style={{ borderRadius: "0px" }}
                          options={this.state.transporterList} required
                      />
                  </div>
                  <div className="col-xl-3 col-lg-3 form-group">
                      <label>Month<span style={{color:"#FF0000"}}>*</span></label>
                      {/* <input type="text" placeholder="Select Month" id="plan_month" autocomplete="off" className="datetimepicker_date form-control"  required /> */}
                      <Datetime
                          value={this.state.month}
                          disableCloseOnClickOutside={false}
                          closeOnSelect={true}
                          inputProps={{
                              placeholder: 'Select Month',
                              name: 'month',
                              autoComplete: 'off',
                              readOnly: true
                          }}
                          renderMonthContent={this.renderMonthContent.bind(this)}
                          dateFormat="YYYY-MM"
                          name="month"
                          onChange={this.handlerStartDateTime.bind(this)}
                      />
                  </div>
                  <div class="form-group col-xl-3 col-lg-3" style={{marginTop:"31px"}}>
                          <button type="submit" class="btn btn-success">
                              Submit
                          </button>
                  </div>
                </div>
                    
            </form>   
        </div>
        <div className="card col-xl-12">
          <div className="card-header">
            <h5>
              <i className="icofont icofont-vehicle-delivery-van cus-i"></i>{" "}
              <span>Review for GPS Service Provider</span>
              {(localStorage.getItem("username") == "AlvinSubash" || localStorage.getItem("email") == "nanda@enmovil.in")
                ?
                  <a onClick={this.addConditionData} className="btn btn-info" style={{ float: "right", marginRight: "10px", color:"#ffffff" }} >Access Control</a>
                : ""
                }
              <div className="card-body">
                <div className="row">
                  <div
                    id="myGrid"
                    style={{ width: "100%", height: "500px" }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      modules={this.state.modules}
                      columnDefs={this.state.columnWithDefs}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.rowData}
                      rowSelection={this.state.rowSelection}
                      enableCharts={false}
                      onGridReady={this.onGridReady}
                      onGridState={this.onGridState}
                      frameworkComponents={this.state.frameworkComponents}
                    //   rowClassRules={rowClassRules}
                      sideBar={this.state.sideBar}
                      stopEditingWhenGridLosesFocus={true}
                      pinnedBottomRowData={this.state.average_ratings}
                    //   onCellClicked={this.onCellClicked.bind(this)}
                    //   paginationPageSize={this.state.paginationPageSize}
                    //   pagination={true}
                      enableCellChangeFlash={true}
                      suppressCellFlash={true}
                      gridOptions={{
                        context: { componentParent: this },
                        suppressRowTransform: true, 
                      }}
                      // onCellClicked={this.onCellRowClicked.bind(this)}
                      enableRangeSelection={true}
                    />
                  </div>
                </div>
              </div>
            </h5>
          </div>

          {/* work in progress Fresh Incoming rakes */}

        </div>
        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">Form Access Control
            <span className="float-right fclose" style={{marginRight: "12px",padding: "1px 8px",marginTop: "-2px",}} onClick={this.onClickHideAll.bind(this)}>x</span></h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.updateFormAccessControl}>
                      
                    {/* <div className="form-group mt-20p">
                        <label className="">Upload File</label> 
                        <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control" required  />
                    </div> */}
                    <div className="col-xl-12 col-lg-12 form-group">
                      <label>Month<span style={{color:"#FF0000"}}>*</span></label>
                      {/* <input type="text" placeholder="Select Month" id="plan_month" autocomplete="off" className="datetimepicker_date form-control"  required /> */}
                      <Datetime
                          value={this.state.access_month}
                          disableCloseOnClickOutside={false}
                          closeOnSelect={true}
                          inputProps={{
                              placeholder: 'Select Month',
                              name: 'access_month',
                              autoComplete: 'off',
                              readOnly: true
                          }}
                          renderMonthContent={this.renderMonthContent.bind(this)}
                          dateFormat="YYYY-MM"
                          name="access_month"
                          onChange={this.handlerStartDateTime1.bind(this)}
                      />
                  </div>
                  {/* <div className="form-group col-xl-12 col-lg-12">
                    <label>Select Transporter<span style={{color:"#FF0000"}}>*</span></label>
                      <Select
                          placeholder={this.state.access_transporters.label == "undefined" ? "Select Transporter" : ''}
                          closeMenuOnSelect={true}
                          value={this.state.access_transporters}
                          menuPortalTarget={document.body} 
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          onChange={this.newFilter.bind(this,"access_transporters")}
                          style={{ borderRadius: "0px" }}
                          isDisabled={true}
                          options={this.state.transporterList} required
                      />
                  </div> */}
                    <div className="form-group col-xl-3 col-lg-3">
                        <button type="submit" className="btn btn-success">Submit</button>
                    </div>
                    </form>
                    <div className="form-group">
                    </div>		
                </div>
            </div>
        </div>
        <div className={"dataLoadpage " + (this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
          <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>
    );
  }
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
/* eslint-disable array-callback-return */
/* eslint-disable no-eval */
/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
/* eslint-disable no-dupe-keys */
/* eslint-disable strict */
/* eslint-disable no-unused-vars */

"use strict";

import React, { Component, useDebugValue } from "react";
import { render } from "react-dom";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MenuModule } from "@ag-grid-enterprise/menu";
// import Consignmentforceclose from '../m';
import Select from "react-select";
import CSVFileValidator from "csv-file-validator";
import {
    AllModules,
    GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from "react-bootstrap-sweetalert";
import PrtTrainingsGrid from "../transporter/prttransportertrainingsgrid";
import FleetGrid from "../transporter/transporterfleetgrid";
import GpsGrid from "../transporter/transportergpsgrid";
import TransitGrid from "../transporter/transportertransitgrid";
import CarrierGrid from "../transporter/transportercarriergrid";
import SecondaryGrid from "../transporter/transportersecondarygrid";
import StockGrid from "../transporter/transporterstockgrid";
import AccidentsGrid from "../transporter/accidentsgrid";
import * as Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import $ from "jquery";
import {
    getDDMMYYYY,
    getHyphenYYYYMMDD,
    getHyphenDDMMMYYYYHHMM,
    getHyphenDDMMMYYYY,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenDDMMYYYYHHMMSS,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenDDMMYYYY,
} from "../common/utils";
import { first } from "lodash";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require("../redirectURL");
var moment = require("moment");
var CELL_DIMENSION_SIZE = 90;
var googleAnalytics = require("../common/googleanalytics");

export default class TransporterPerformanceDashboard extends Component {
    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            pageTitle: "Transporter Performance Dashboard",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            showform: "show-m",
            loadshow: "show-n",
            showrefresh: "show-n",
            sliderTranslate: "",
            showdata: this.displayData,
            modules: AllModules,
            radius: "15",
            rlatitude: "",
            rlongitude: "",
            latitude: "",
            longitude: "",
            defaultradius: 0,
            coordinates: [],
            alert: null,
            show: false,
            basicTitle: "",
            basicType: "default",
            contentString: "testing",
            mwidth: "col-xl-12 col-lg-12",
            dept_code: "",
            defaultColDef: {
                sortable: true,
                filter: true,
                //editable: true,
                resizable: true,
            },
            rowData: [],
            originalrowData: [],
            selectedTabLat: "",
            selectedTabLng: "",
            cluster_centroids: [],
            deftab: "all",
            rowModelhighchartoptionsType: "enterprise",
            rowGroupPanelShow: "always",
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single",
            context: { componentParent: this },
            frameworkComponents: {},
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            rowModelType: "serverSide",
            paginationPageSize: 50,
            cacheBlockSize: 50,
            maxBlocksInCache: 2,
            last_approval_date: "",
            showPlant: "show-n",
            showAddr: "show-n",
            request_type: "",
            gridHeaderName: "",
            view_type: "",
            tabplants: [],
            clickabletab: "",
            plantlist: [],
            stateFormData: "",
            sliderStateEmailsTranslate: "",
            overly: "show-n",
            rowData: [],
            rakeslist: [],
            loadingpoints: [],
            destinationpoints: [],
            userType: "",
            pinnedRegionBottomRowData: [],
            pinnedTransitBottomRowData: [],
            stockData: [],
            frgData: [],
            totalStock: 0,
            totalSales: 0,
            totalDispatches: 0,
            railShare: 0,
            recordsLength: 1,
            sliderbulkupload: "",
            csvcontent: "",
            rowClassRules: {
                colorForDangerLight: function (params) {
                    if (params.data.parameters == "growth") {
                        return true;
                    } else {
                        return false;
                    }
                },
            },
            screenView: "",
            input_funds_received: "",
            input_funds_available: "",
            transportersList: [],
            transporters: "",
            fiscalyear: "",
            financialyears: "",
            fascalyears: "",
            columnwithDefs: [],
            ontimeData: [],

            transporterId: { label: "CHETAK MOTORS", value: "GCHMS" },
            fYear: {
                label: "2021-2022",
                value: "2021-2022",
            },
            grid_type: "",
            drivertrainings: "",
            ontime: "",
            dailycarrier: "",
            first_fy: "",
            second_fy: "",
            transporterInfo: [],
            transporterInfoprt: [],
            truckInfo: [],
            nonrepofleet: [],

            trainingsData: [],

            sobtrackerData: [],
            claimData: [],
            gpsData: [],
            truckData: [],
            accidentsData: [],
            idtrData: [],
            fleetData: [],

            damageData: [],
            carrierData: [],

            transitData: [],
            pendingData: [],
            paymentData: [],
            stockData: [],
            srminfo: [],
            achievement: "",
            trainings: "",
            idtr: "",
            sobtracker: "",
            claim: "",
            gps: "",
            ops: "activet",
            truck: "",
            accidents: "",
            fleet: "",
            damage: "",
            carrier: "",
            ontime: "",
            transit: "",
            pending: "",
            payment: "",
            seniorManagmentInfo: "",
            achievementSegment: "show-m",
            trainingsSegment: "show-n",
            idtrSegment: "show-n",
            sobtrackerSegment: "show-n",
            claimSegment: "show-n",
            truckSegment: "show-n",
            accidentsSegment: "show-n",
            fleetSegment: "show-n",
            damageSegment: "show-n",
            carrierSegment: "show-n",
            sobtrackerSegment: "show-n",
            claimSegment: "show-n",
            gpsSegment: "show-n",
            opsSegment: "show-m",
            truckSegment: "show-n",
            accidentsSegment: "show-n",
            ontimeSegment: "show-n",
            transitSegment: "show-n",
            pendingSegment: "show-n",
            paymentSegment: "show-n",
            stockSegment: "show-n",
            fleetSize: [],
            uploadedFile: "",
            csvcontent: "",
            sliderTranslatebulkupload: "",
            uploadedFor: "",
            operatingClusters: {
                gurgoanClusters: [],
                gujaratClusters: [],
                tvpbClusters: [],
                tvpnClusters: [],
                siliguriClusters: [],
            },
            toggleMore: false,
            selectedPlant: { label: "HARYANA", value: "HARYANA" },
            bulkUploadDate: "",
            directorsRowData: [
                { name: "", designation: "", share: "" },
                { name: "", designation: "", share: "" },
                { name: "", designation: "", share: "" },
            ],
            mgmntLastGridRowData: [
                {
                    location: "",
                    cluster: "",
                    existing_sob: "",
                    existing_avg_volume: "",
                },
                {
                    location: "",
                    cluster: "",
                    existing_sob: "",
                    existing_avg_volume: "",
                },
                {
                    location: "",
                    cluster: "",
                    existing_sob: "",
                    existing_avg_volume: "",
                },
            ],
            quarterMonth: "",
            pageType : 'ops',
            lspCode: ''
        };
        this.createColumns = this.createColumns.bind(this);
        this.onLoadOntimeData = this.onLoadOntimeData.bind(this);
        this.onLoadClaimData = this.onLoadClaimData.bind(this);
        this.onLoadAccidentsData = this.onLoadAccidentsData.bind(this);
        this.onLoadTruckData = this.onLoadTruckData.bind(this);
        this.onLoadSobData = this.onLoadSobData.bind(this);
    }

    /*Alert Popups*/
    closeAlert = () => {
        this.setState({
            show: false,
        });
        this.onClickTransporter();
    };

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction =
                    googleAnalytics.page.actionType.gridAction +
                    " - " +
                    window.location.pathname;
                let eventCounterAction =
                    googleAnalytics.page.actionType.counterAction +
                    " - " +
                    window.location.pathname;
                let eventFormAction =
                    googleAnalytics.page.actionType.formAction +
                    " - " +
                    window.location.pathname;
                this.setState({
                    eventGridAction: eventGridAction,
                    eventCounterAction: eventCounterAction,
                    eventFormAction: eventFormAction,
                });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    };
    componentDidMount() {
        loadDateTimeScript();
        this.logPageView(); // Log page view to GA
        var currentMonth = moment.parseZone().format("M");
        var currentYear = moment.parseZone().format("YY");
        // var quarterMonth = "";
        // if(currentMonth >= 4 && currentMonth <= 6)
        // {
        //     quarterMonth = "Apr'"+currentYear+"-"+"Jun'"+currentYear;
        // }
        // else if(currentMonth >= 7 && currentMonth <= 9)
        // {
        //     quarterMonth = "Jul'"+currentYear+"-"+"Sep'"+currentYear;
        // }
        // else if(currentMonth >= 10 && currentMonth <= 12)
        // {
        //     quarterMonth = "Oct'"+currentYear+"-"+"Dec'"+currentYear;
        // }
        // else if(currentMonth >= 1 && currentMonth <= 3)
        // {
        //     quarterMonth = "Jan'"+currentYear+"-"+"Mar'"+currentYear;
        // }
        // console.log(quarterMonth,"quarterMonth");
        // this.setState({
        //     quarterMonth : quarterMonth
        // });
        var fiscalyear = "";
        var today = new Date();
        if (today.getMonth() + 1 <= 3) {
            fiscalyear = today.getFullYear() - 1 + "-" + today.getFullYear();
        } else {
            fiscalyear = today.getFullYear() + "-" + (today.getFullYear() + 1);
        }
        let transporters = "";
        //transporterdetails 
        let prtParams = {
           "transporter_name":this.state.transporterId.label
        }
        redirectURL.post("/consignments/getPrtTransporters",prtParams).then((response) => {
                var transporters = response.data.transporters;
                var financialyears = response.data.financialyears;
                let transporterdetailsData = response.data.transporterdetailsData;
                var lspCode = transporterdetailsData[0].lsp_code
                // console.log("transporters", transporters);
                var tlist = [];
                transporters.map(function (t) {
                    var transporterCode = t.transporter_code;
                    if (transporterCode != undefined && transporterCode != "") {
                        tlist.push({
                            value: t.transporter_code,
                            label: t.transporter_name,
                        });
                    }
                });
                var flist = [];
                financialyears.map(function (f) {
                    var year = f;
                    if (year != undefined && year != "" && year != "2018-2019") {
                        flist.push({
                            value: year,
                            label: year,
                        });
                    }
                });
                // console.log("fiscalyear",fiscalyear)
                // console.log("flist",flist)
                var transporterId = this.state.transporterId;
                if (transporterId != "" && transporterId != undefined) {
                    var reqparams = {
                        transporterId: transporterId.value,
                        year : fiscalyear
                    };
                    redirectURL.post("/consignments/getPRTtptInfo", reqparams)
                        .then(async (response) => {
                            var transporterInfoprt = response.data.transporterInfo;
                            var fleetSize = response.data.fleetSize;
                            var nonrepofleet = response.data.nonrepofleet;
                            await fleetSize.map(function (fz) {
                                if (
                                    fz.report_truck_type == undefined ||
                                    fz.report_truck_type == ""
                                ) {
                                    fz.report_truck_type = "Others";
                                }
                            });
                            fleetSize = await groupBy(fleetSize, (rdata) =>
                                rdata.report_truck_type.toUpperCase()
                            );
                            var lgArray = [];
                            var totalCnt = 0;
                            await fleetSize.forEach((lg, lgkey) => {
                                lgArray.push({
                                    truck_type: lgkey,
                                    truck_cnt: lg.length,
                                });
                                totalCnt = parseInt(totalCnt) + parseInt(lg.length);
                            });
                            lgArray.push({
                                truck_type: "Total",
                                truck_cnt: totalCnt,
                            });
                            // console.log(lgArray, "lgArray");
                            await this.setState({
                                transporterInfoprt: transporterInfoprt,
                                fleetSize: lgArray,
                                nonrepofleet: nonrepofleet,
                            });
                        });

                    redirectURL
                        .post("/consignments/getPRTaopData", reqparams)
                        .then(async (response) => {
                            var quarterMonth = "";
                            if (response.data.length > 0) {
                                quarterMonth = response.data[0].quarterMonth;
                            }
                            await this.setState({
                                quarterMonth: quarterMonth,
                            });
                        })
                        .catch(function (e) {
                            console.log("Error ", e);
                        });
                }
                this.setState({
                    lspCode,
                    transportersList: tlist,
                    financialyears: flist,
                });
            })
            .catch(function (e) {
                console.log("Error ", e);
            });

        var yrSpt = fiscalyear.split("-");
        var from = "Apr " + yrSpt[0];
        var to = "Mar " + yrSpt[1];
        this.getMonths(from, to);
        //this.onLoadOntimeData();
        //this.onClickTransporter();
    }

    getMonths(from, to) {
        var monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];

        var arr = [];
        var datFrom = new Date("1 " + from);
        var datTo = new Date("1 " + to);
        var fromYear = datFrom.getFullYear();
        var toYear = datTo.getFullYear();
        var diffYear = 12 * (toYear - fromYear) + datTo.getMonth();

        for (var i = datFrom.getMonth(); i <= diffYear; i++) {
            arr.push(
                monthNames[i % 12] +
                " " +
                Math.floor(fromYear + i / 12)
                    .toString()
                    .substr(-2)
            );
        }
        this.setState({
            fascalyears: arr,
        });
        // console.log(arr, "months");
    }

    onChangeTransporter(transporterId) {
        this.setState({ transporterId }, 
            () => console.log(`transporter Option selected:`, this.state.transporterId)
        );
    }
    onChangeFy(fYear) {
        this.setState({ fYear }, () => console.log(`fYear Option selected:`, this.state.fYear)
        );
    }
    onClickTransporter() {
        this.setState({
            operatingClusters: {
                gurgoanClusters: [],
                gujaratClusters: [],
                tvpbClusters: [],
                tvpnClusters: [],
                siliguriClusters: [],
            },
        });
        var transporterId = this.state.transporterId;
        var fYear = this.state.fYear;
        if (transporterId != "" && transporterId != undefined) {
            this.setState({
                loadshow: "show-m",
            });
            var reqparams = {
                transporterId: transporterId.value,
            };
            if (transporterId != "" && transporterId != undefined) {
                reqparams["year"] = fYear.value;
            }
            redirectURL.post("/consignments/getPRTtptInfo", reqparams).then(async (response) => {
                    var transporterInfoprt = response.data.transporterInfo;
                    var fleetSize = response.data.fleetSize;
                    var nonrepofleet = response.data.nonrepofleet;
                    var lspCode = response.data.transporterdetailsData[0].lsp_code

                    await fleetSize.map((fz) => {
                            if (fz.report_truck_type == undefined ||
                                fz.report_truck_type == "") {
                                fz.report_truck_type = "Others";
                            }
                    });
                    fleetSize = await groupBy(fleetSize, (rdata) =>
                        rdata.report_truck_type.toUpperCase()
                    );
                    var lgArray = [];
                    var totalCnt = 0;
                    await fleetSize.forEach((lg, lgkey) => {
                        lgArray.push({
                            truck_type: lgkey,
                            truck_cnt: lg.length,
                        });
                        totalCnt = parseInt(totalCnt) + parseInt(lg.length);
                    });
                    lgArray.push({
                        truck_type: "Total",
                        truck_cnt: totalCnt,
                    });
                    // console.log(lgArray, "lgArray");
                    await this.setState({
                        lspCode,
                        transporterInfoprt: transporterInfoprt,
                        fleetSize: lgArray,
                        nonrepofleet: nonrepofleet,
                        loadshow: "show-n",
                        ontimeSegment: "show-n",
                        ontime: "",
                        // ontimeData: [],
                        sobtracker: "",
                        claim: "",
                        trainings: "",
                        fleet: "",
                        carrier: "",
                        gps: "",
                        ops: "activet",
                        truck: "",
                        accidents: "",
                        sobtrackerSegment: "show-n",
                        claimSegment: "show-n",
                        trainingsSegment: "show-n",
                        fleetSegment: "show-n",
                        carrierSegment: "show-n",
                        gpsSegment: "show-n",
                        opsSegment: "show-m",
                        truckSegment: "show-n",
                        accidentsSegment: "show-n",
                    });
                });
            var reqparams = {
                first_fy: this.state.first_fy,
                second_fy: this.state.second_fy,
            };
            //this.onLoadOntimeData();
        } else {
            this.setState({
                basicTitle: "Please select transporter.",
                basicType: "warning",
                show: true,
            });
        }
    }
    createColumns() {
        var columns = [];
        var defs = [];
        var keys = Object.keys(this.state.rowData[0]);
        const rowLength = this.state.rowData.length;
        var cnt = 0;
        var cFydata = [];
        keys.forEach((z, k) => {
            var hideCol = false;
            if (z == "plant_code") {
                var hideCol = true;
            }
            if (z == "plant") {
                var keyVal = "PLANT";
                var hwid = "150";
                var pinnedLeft = "left";
                var isEditable = false;
            } else if (z == "parameters") {
                var keyVal = "PARAMETERS";
                var hwid = "130";
                var pinnedLeft = "left";
                var isEditable = false;
            } else {
                var keyVal = z;
                var hwid = "90";
                var pinnedLeft = "";
                var isEditable = true;
            }
            var check = 1;
            if (z.includes("FY") == true) {
                // console.log(z, cnt, "includesFY");
                if (cnt == 0) {
                    check = 1;
                    pinnedLeft = "left";
                } else {
                    check = 0;
                    cFydata.push({
                        headerName: keyVal,
                        field: z,
                        width: hwid,
                        pinned: pinnedLeft,
                        editable: isEditable,
                        hide: hideCol,
                        valueGetter: function (params) {
                            if (params.data[z] == "target") {
                                return "Target";
                            } else if (params.data[z] == "achievemnet") {
                                return "Achievement";
                            } else if (params.data[z] == "gap") {
                                return "Gap";
                            } else if (params.data[z] == "target_percentage") {
                                return "% Target Achmnt";
                            } else if (params.data[z] == "growth") {
                                return "Growth (%)";
                            } else {
                                return params.data[z];
                            }
                        },
                        cellClass: function (params) {
                            if (z == "plant") {
                                if (params.data[z] != "") {
                                    return ["cellstylegridY", "pstBg"];
                                }
                            }
                        },
                        cellStyle: (params) => {
                            if (z == "plant") {
                                if (params.data[z] != "" && params.data[z] != "GUJARAT") {
                                    return { borderBottom: "1px solid #ccc" };
                                }
                                return null;
                            }
                            return null;
                        },
                        rowSpan: function (params) {
                            if (z == "plant") {
                                if (params.data[z] != "") {
                                    return 5;
                                }
                            }
                        },
                        cellEditor: NumericCellEditor,
                    });
                }
                cnt = parseInt(cnt) + 1;
            }
            if (check == 1) {
                columns.push({
                    headerName: keyVal,
                    field: z,
                    width: hwid,
                    pinned: pinnedLeft,
                    editable: isEditable,
                    hide: hideCol,
                    valueGetter: function (params) {
                        if (params.data[z] == "target") {
                            return "Target";
                        } else if (params.data[z] == "achievemnet") {
                            return "Achievement";
                        } else if (params.data[z] == "gap") {
                            return "Gap";
                        } else if (params.data[z] == "target_percentage") {
                            return "% Target Achmnt";
                        } else if (params.data[z] == "growth") {
                            return "Growth (%)";
                        } else {
                            return params.data[z];
                        }
                    },
                    cellClass: function (params) {
                        if (z == "plant") {
                            if (params.data[z] != "") {
                                return ["cellstylegridY", "pstBg"];
                            }
                        }
                    },
                    cellStyle: (params) => {
                        if (z == "plant") {
                            if (params.data[z] != "" && params.data[z] != "GUJARAT") {
                                return { borderBottom: "1px solid #ccc" };
                            }
                            return null;
                        }
                        return null;
                    },
                    rowSpan: function (params) {
                        if (z == "plant") {
                            if (params.data[z] != "") {
                                return 5;
                            }
                        }
                    },
                    cellEditor: NumericCellEditor,
                });
            }
        });
        if (cFydata.length > 0) {
            columns.push(cFydata[0]);
        }
        // console.log("columns ", columns);
        this.setState({
            columnwithDefs: columns,
            achievement: "activet",
        });
    }
    createColumns1() {
        var columns = [];
        var defs = [];
        var fascalyears = this.state.fascalyears;
        columns.push({
            headerName: "PLANT",
            field: "plant_name",
            width: 120,
        });
        columns.push({
            headerName: "PARAMETER",
            field: "parameter",
            width: 120,
        });

        fascalyears.forEach((z, k) => {
            var fymnth = z.replace(" ", "-");
            columns.push({
                headerName: fymnth,
                field: fymnth,
                width: 90,
                //cellEditor: NumericCellEditor,
            });
        });

        // console.log("columns ", columns);
        this.setState({
            columnwithDefs: columns,
        });
    }
    createRows() {
        var rowData = this.state.rowData;
        var rowdefs = [];
        // console.log("transdata ", transdata)
        var mntKeys = [];
        rowData.forEach((mnt, index) => {
            mntKeys.push(mnt.month);
        });
    }

    onLoadFleetData() {
        var transporterId = this.state.transporterId;
        var fYear = this.state.fYear;
        if (transporterId != "" && transporterId != undefined) {
            this.setState({
                loadshow: "show-m",
            });
            var reqparams = {
                transporterId: transporterId.value,
                "grid-type": "others",
            };
            if (transporterId != "" && transporterId != undefined) {
                reqparams["year"] = fYear.value;
            }

            redirectURL
                .post("/consignments/lspFleetPerformanceForPRT", reqparams)
                .then(async (response) => {
                    var fleetData = eval(response.data.targets_list);
                    // console.log("fleetData", fleetData);
                    if (fleetData != undefined) {
                        this.setState({
                            loadshow: "show-n",
                            fleetSegment: "show-m",
                            fleetData: fleetData,
                        });
                    } else {
                        this.setState({
                            loadshow: "show-n",
                            fleetSegment: "show-m",
                            fleetData: [],
                        });
                    }
                })
                .catch(function (e) {
                    console.log("Error ", e);
                });
        } else {
            this.setState({
                basicTitle: "Please select transporter.",
                basicType: "warning",
                show: true,
            });
        }
    }
    onLoadCarrierData() {
        var transporterId = this.state.transporterId;
        var fYear = this.state.fYear;
        var dailycarrier = this.state.dailycarrier;
        if (transporterId != "" && transporterId != undefined) {
            this.setState({
                loadshow: "show-m",
            });
            var reqparams = {
                transporterId: transporterId.value,
                grid_type: dailycarrier.value,
            };
            if (transporterId != "" && transporterId != undefined) {
                reqparams["year"] = fYear.value;
            }

            redirectURL
                .post("/consignments/lspDailyRunningCarriersForPRT", reqparams)
                .then(async (response) => {
                    var carrierData = eval(response.data.targets_list);
                    // console.log("carrier", carrierData);
                    if (carrierData != undefined) {
                        this.setState({
                            loadshow: "show-n",
                            carrierSegment: "show-m",
                            carrierData: carrierData,
                        });
                    } else {
                        this.setState({
                            loadshow: "show-n",
                            carrierSegment: "show-m",
                            carrierData: [],
                        });
                    }
                })
                .catch(function (e) {
                    console.log("Error ", e);
                });
        } else {
            this.setState({
                basicTitle: "Please select transporter.",
                basicType: "warning",
                show: true,
            });
        }
    }

    onLoadGpsData() {
        var transporterId = this.state.transporterId;
        var fYear = this.state.fYear;
        var dailycarrier = this.state.dailycarrier;
        if (transporterId != "" && transporterId != undefined) {
            this.setState({
                loadshow: "show-m",
            });
            var reqparams = {
                transporterId: transporterId.value,
                grid_type: dailycarrier.value,
            };
            if (transporterId != "" && transporterId != undefined) {
                reqparams["year"] = fYear.value;
            }

            redirectURL
                .post("/consignments/lspGpsAnalysisForPRT", reqparams)
                .then(async (response) => {
                    var gpsData = eval(response.data.targets_list);
                    // console.log("gpsData", gpsData);
                    if (gpsData != undefined) {
                        this.setState({
                            loadshow: "show-n",
                            gpsSegment: "show-m",
                            gpsData: gpsData,
                        });
                    } else {
                        this.setState({
                            loadshow: "show-n",
                            gpsSegment: "show-m",
                            gpsData: [],
                        });
                    }
                })
                .catch(function (e) {
                    console.log("Error ", e);
                    this.setState({
                        loadshow: "show-n",
                        gpsSegment: "show-m",
                        gpsData: [],
                    });
                });
        } else {
            this.setState({
                basicTitle: "Please select transporter.",
                basicType: "warning",
                show: true,
            });
        }
    }

    onLoadTrainingsData() {
        var transporterId = this.state.transporterId;
        var fYear = this.state.fYear;
        var drivertrainings = this.state.drivertrainings;
        if (transporterId != "" && transporterId != undefined) {
            this.setState({
                loadshow: "show-m",
            });
            var reqparams = {
                transporterId: transporterId.value,
                grid_type: drivertrainings.value,
            };
            if (transporterId != "" && transporterId != undefined) {
                reqparams["year"] = fYear.value;
            }
            // else
            // {
            //     reqparams["year"] = fYear.value;
            // }
            redirectURL
                .post("/consignments/lspDriverTrainingsForPRT", reqparams)
                .then(async (response) => {
                    var trainingsData = eval(response.data.targets_list);
                    // console.log("trainingsData", trainingsData);
                    if (trainingsData != undefined) {
                        this.setState({
                            loadshow: "show-n",
                            trainingsSegment: "show-m",
                            trainingsData: trainingsData,
                        });
                    } else {
                        this.setState({
                            loadshow: "show-n",
                            trainingsSegment: "show-m",
                            trainingsData: [],
                        });
                    }
                })
                .catch(function (e) {
                    console.log("Error ", e);
                });
        } else {
            this.setState({
                basicTitle: "Please select transporter.",
                basicType: "warning",
                show: true,
            });
        }
    }

    onLoadOntimeData() {
        var transporterId = this.state.transporterId;
        var fYear = this.state.fYear;
        var ontime = this.state.ontime;
        if (transporterId != "" && transporterId != undefined) {
            this.setState({
                loadshow: "show-m",
                ontimeSegment: "show-n",
            });
            var reqparams = {
                transporterId: transporterId.value,
                grid_type: ontime.value,
            };
            if (transporterId != "" && transporterId != undefined) {
                reqparams["year"] = fYear.value;
            }

            redirectURL.post("/consignments/lspOnTimePerformanceForPRT", reqparams)
                .then(async (response) => {
                    var ontimeData = eval(response.data.targets_list);
                    // console.log("ontimeper", ontimeData);
                    if (ontimeData != undefined) {
                        this.setState({
                            loadshow: "show-n",
                            ontimeSegment: "show-m",
                            ontime: "activet",
                            ontimeData: ontimeData,
                            sobtracker: "",
                            claim: "",
                            trainings: "",
                            fleet: "",
                            carrier: "",
                            gps: "",
                            ops: "",
                            truck: "",
                            accidents: "",
                            sobtrackerSegment: "show-n",
                            claimSegment: "show-n",
                            trainingsSegment: "show-n",
                            fleetSegment: "show-n",
                            carrierSegment: "show-n",
                            gpsSegment: "show-n",
                            opsSegment: "show-n",
                            truckSegment: "show-n",
                            accidentsSegment: "show-n"
                        });
                    } else {
                        this.setState({
                            loadshow: "show-n",
                            ontimeSegment: "show-n",
                            ontime: "",
                            ontimeData: [],
                            sobtracker: "",
                            claim: "",
                            trainings: "",
                            fleet: "",
                            carrier: "",
                            gps: "",
                            ops: "activet",
                            truck: "",
                            accidents: "",
                            sobtrackerSegment: "show-n",
                            claimSegment: "show-n",
                            trainingsSegment: "show-n",
                            fleetSegment: "show-n",
                            carrierSegment: "show-n",
                            gpsSegment: "show-n",
                            opsSegment: "show-m",
                            truckSegment: "show-n",
                            accidentsSegment: "show-n",
                        });
                    }
                })
                .catch(function (e) {
                    console.log("Error ", e);
                    this.setState({
                        loadshow: "show-n",
                        ontime: "",
                        sobtracker: "",
                        claim: "",
                        trainings: "",
                        fleet: "",
                        carrier: "",
                        gps: "",
                        ops: "activet",
                        truck: "",
                        accidents: "",
                        sobtrackerSegment: "show-n",
                        claimSegment: "show-n",
                        trainingsSegment: "show-n",
                        fleetSegment: "show-n",
                        carrierSegment: "show-n",
                        gpsSegment: "show-n",
                        opsSegment: "show-m",
                        truckSegment: "show-n",
                        accidentsSegment: "show-n",
                    });
                });
        } else {
            this.setState({
                basicTitle: "Please select transporter.",
                basicType: "warning",
                show: true,
            });
        }
    }

    onLoadClaimData() {
        var transporterId = this.state.transporterId;
        var fYear = this.state.fYear;
        if (transporterId != "" && transporterId != undefined) {
            this.setState({
                loadshow: "show-m",
                ontimeSegment: "show-n",
            });
            var reqparams = {
                transporterId: transporterId.value,
            };
            if (transporterId != "" && transporterId != undefined) {
                reqparams["year"] = fYear.value;
            }

            redirectURL
                .post("/consignments/prtTptClaimData", reqparams)
                .then(async (response) => {
                    var claimData = eval(response.data.targets_list);
                    // console.log("claimData", claimData);
                    if (claimData != undefined) {
                        this.setState({
                            loadshow: "show-n",
                            claimData: claimData,
                        });
                    } else {
                        this.setState({
                            loadshow: "show-n",
                        });
                    }
                })
                .catch(function (e) {
                    console.log("Error ", e);
                    this.setState({
                        loadshow: "show-n",
                    });
                });
        } else {
            this.setState({
                basicTitle: "Please select transporter.",
                basicType: "warning",
                show: true,
            });
        }
    }

    onLoadAccidentsData() {
        var transporterId = this.state.transporterId;
        var fYear = this.state.fYear;
        if (transporterId != "" && transporterId != undefined) {
            this.setState({
                loadshow: "show-m",
                ontimeSegment: "show-n",
            });
            var reqparams = {
                transporterId: transporterId.value,
            };
            if (transporterId != "" && transporterId != undefined) {
                reqparams["year"] = fYear.value;
            }

            redirectURL
                .post("/consignments/prtTptAccidentsData", reqparams)
                .then(async (response) => {
                    var accidentsData = response.data;
                    // console.log("accidentsData", accidentsData);
                    if (accidentsData != undefined) {
                        this.setState({
                            loadshow: "show-n",
                            accidentsData: accidentsData,
                        });
                    } else {
                        this.setState({
                            loadshow: "show-n",
                        });
                    }
                })
                .catch(function (e) {
                    console.log("Error ", e);
                    this.setState({
                        loadshow: "show-n",
                    });
                });
        } else {
            this.setState({
                basicTitle: "Please select transporter.",
                basicType: "warning",
                show: true,
            });
        }
    }

    onLoadTruckData() {
        var transporterId = this.state.transporterId;
        var fYear = this.state.fYear;
        var dailycarrier = this.state.dailycarrier;
        if (transporterId != "" && transporterId != undefined) {
            this.setState({
                loadshow: "show-m",
            });
            var reqparams = {
                transporterId: transporterId.value,
                grid_type: dailycarrier.value,
            };
            if (transporterId != "" && transporterId != undefined) {
                reqparams["year"] = fYear.value;
            }

            redirectURL
                .post("/consignments/lspTruckDataForPRT", reqparams)
                .then(async (response) => {
                    var truckData = eval(response.data.targets_list);
                    // console.log("truckData", truckData);
                    if (truckData != undefined) {
                        this.setState({
                            loadshow: "show-n",
                            truckSegment: "show-m",
                            truckData: truckData,
                        });
                    } else {
                        this.setState({
                            loadshow: "show-n",
                            truckSegment: "show-m",
                            truckData: [],
                        });
                    }
                })
                .catch(function (e) {
                    console.log("Error ", e);
                    this.setState({
                        loadshow: "show-n",
                        gpsSegment: "show-m",
                        gpsData: [],
                    });
                });
        } else {
            this.setState({
                basicTitle: "Please select transporter.",
                basicType: "warning",
                show: true,
            });
        }
    }

    onLoadSobData() {
        var transporterId = this.state.transporterId;
        var fYear = this.state.fYear;
        if (transporterId != "" && transporterId != undefined) {
            this.setState({
                loadshow: "show-m",
                ontimeSegment: "show-n",
            });
            var reqparams = {
                transporterId: transporterId.value,
            };
            if (transporterId != "" && transporterId != undefined) {
                reqparams["year"] = fYear.value;
            }

            redirectURL
                .post("/consignments/prtTptSobData", reqparams)
                .then(async (response) => {
                    var sobtrackerData = eval(response.data);
                    // console.log("sobtrackerData", sobtrackerData);
                    if (sobtrackerData != undefined) {
                        this.setState({
                            loadshow: "show-n",
                            sobtrackerData: sobtrackerData,
                        });
                    } else {
                        this.setState({
                            loadshow: "show-n",
                        });
                    }
                })
                .catch(function (e) {
                    console.log("Error ", e);
                    this.setState({
                        loadshow: "show-n",
                    });
                });
        } else {
            this.setState({
                basicTitle: "Please select transporter.",
                basicType: "warning",
                show: true,
            });
        }
    }

    onClickHideAll() {
        $(".selected_date").val("");

        this.setState({
            overly: "show-n",
            sliderStateEmailsTranslate: "",
            sliderbulkupload: "",
            sliderTranslatebulkupload: "",
            uploadedFile: [],
            csvcontent: [],
        });
    }

    onGridReady = (params) => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };

    onClickTab(pageType) {
        if (pageType == "ontime") {
            this.onLoadOntimeData();
            var ontime = "activet";
            var sobtracker = "";
            var claim = "";
            var trainings = "";
            var fleet = "";
            var carrier = "";
            var gps = "";
            var ops = "";
            var truck = "";
            var accidents = "";
            var ontimeSegment = "show-m";
            var sobtrackerSegment = "show-n";
            var claimSegment = "show-n";
            var trainingsSegment = "show-n";
            var fleetSegment = "show-n";
            var carrierSegment = "show-n";
            var gpsSegment = "show-n";
            var opsSegment = "show-n";
            var truckSegment = "show-n";
            var accidentsSegment = "show-n";
        }
        if (pageType == "sobtracker") {
            this.onLoadSobData();
            var ontime = "";
            var sobtracker = "activet";
            var claim = "";
            var trainings = "";
            var fleet = "";
            var carrier = "";
            var gps = "";
            var ops = "";
            var truck = "";
            var accidents = "";
            var ontimeSegment = "show-n";
            var sobtrackerSegment = "show-m";
            var claimSegment = "show-n";
            var trainingsSegment = "show-n";
            var fleetSegment = "show-n";
            var carrierSegment = "show-n";
            var gpsSegment = "show-n";
            var opsSegment = "show-n";
            var truckSegment = "show-n";
            var accidentsSegment = "show-n";
        }
        if (pageType == "claim") {
            this.onLoadClaimData();
            var ontime = "";
            var sobtracker = "";
            var claim = "activet";
            var trainings = "";
            var fleet = "";
            var carrier = "";
            var gps = "";
            var ops = "";
            var truck = "";
            var accidents = "";
            var ontimeSegment = "show-n";
            var sobtrackerSegment = "show-n";
            var claimSegment = "show-m";
            var trainingsSegment = "show-n";
            var fleetSegment = "show-n";
            var carrierSegment = "show-n";
            var gpsSegment = "show-n";
            var opsSegment = "show-n";
            var truckSegment = "show-n";
            var accidentsSegment = "show-n";
        }
        if (pageType == "trainings") {
            this.onLoadTrainingsData();
            var ontime = "";
            var sobtracker = "";
            var claim = "";
            var trainings = "activet";
            var fleet = "";
            var carrier = "";
            var gps = "";
            var ops = "";
            var truck = "";
            var accidents = "";
            var ontimeSegment = "show-n";
            var sobtrackerSegment = "show-n";
            var claimSegment = "show-n";
            var trainingsSegment = "show-m";
            var fleetSegment = "show-n";
            var carrierSegment = "show-n";
            var gpsSegment = "show-n";
            var opsSegment = "show-n";
            var truckSegment = "show-n";
            var accidentsSegment = "show-n";
        }
        if (pageType == "fleet") {
            this.onLoadFleetData();
            var ontime = "";
            var sobtracker = "";
            var claim = "";
            var trainings = "";
            var fleet = "activet";
            var carrier = "";
            var gps = "";
            var ops = "";
            var truck = "";
            var accidents = "";
            var ontimeSegment = "show-n";
            var sobtrackerSegment = "show-n";
            var claimSegment = "show-n";
            var trainingsSegment = "show-n";
            var fleetSegment = "show-m";
            var carrierSegment = "show-n";
            var gpsSegment = "show-n";
            var opsSegment = "show-n";
            var truckSegment = "show-n";
            var accidentsSegment = "show-n";
        }

        if (pageType == "carrier") {
            this.onLoadCarrierData();
            var ontime = "";
            var sobtracker = "";
            var claim = "";
            var trainings = "";
            var fleet = "";
            var carrier = "activet";
            var gps = "";
            var ops = "";
            var truck = "";
            var accidents = "";
            var ontimeSegment = "show-n";
            var sobtrackerSegment = "show-n";
            var claimSegment = "show-n";
            var trainingsSegment = "show-n";
            var fleetSegment = "show-n";
            var carrierSegment = "show-m";
            var gpsSegment = "show-n";
            var opsSegment = "show-n";
            var truckSegment = "show-n";
            var accidentsSegment = "show-n";
        }
        if (pageType == "gps") {
            this.onLoadGpsData();
            var ontime = "";
            var sobtracker = "";
            var claim = "";
            var trainings = "";
            var fleet = "";
            var carrier = "";
            var gps = "activet";
            var truck = "";
            var accidents = "";
            var ontimeSegment = "show-n";
            var sobtrackerSegment = "show-n";
            var claimSegment = "show-n";
            var trainingsSegment = "show-n";
            var fleetSegment = "show-n";
            var carrierSegment = "show-n";
            var gpsSegment = "show-m";
            var opsSegment = "show-n";
            var truckSegment = "show-n";
            var accidentsSegment = "show-n";
        }
        if (pageType == "ops") {
            var ontime = "";
            var sobtracker = "";
            var claim = "";
            var trainings = "";
            var fleet = "";
            var carrier = "";
            var gps = "";
            var ops = "activet";
            var truck = "";
            var accidents = "";
            var ontimeSegment = "show-n";
            var sobtrackerSegment = "show-n";
            var claimSegment = "show-n";
            var trainingsSegment = "show-n";
            var fleetSegment = "show-n";
            var carrierSegment = "show-n";
            var gpsSegment = "show-n";
            var opsSegment = "show-m";
            var truckSegment = "show-n";
            var accidentsSegment = "show-n";
        }
        if (pageType == "truck") {
            this.onLoadTruckData();
            var ontime = "";
            var sobtracker = "";
            var claim = "";
            var trainings = "";
            var fleet = "";
            var carrier = "";
            var gps = "";
            var ops = "";
            var truck = "activet";
            var accidents = "";
            var ontimeSegment = "show-n";
            var sobtrackerSegment = "show-n";
            var claimSegment = "show-n";
            var trainingsSegment = "show-n";
            var fleetSegment = "show-n";
            var carrierSegment = "show-n";
            var gpsSegment = "show-n";
            var opsSegment = "show-n";
            var truckSegment = "show-m";
            var accidentsSegment = "show-n";
        }
        if (pageType == "accidents") {
            this.onLoadAccidentsData();
            var ontime = "";
            var sobtracker = "";
            var claim = "";
            var trainings = "";
            var fleet = "";
            var carrier = "";
            var gps = "";
            var ops = "";
            var truck = "";
            var accidents = "activet";
            var ontimeSegment = "show-n";
            var sobtrackerSegment = "show-n";
            var claimSegment = "show-n";
            var trainingsSegment = "show-n";
            var fleetSegment = "show-n";
            var carrierSegment = "show-n";
            var gpsSegment = "show-n";
            var opsSegment = "show-n";
            var truckSegment = "show-n";
            var accidentsSegment = "show-m";
        }

        this.setState({
            pageType,
            ontime: ontime,
            sobtracker: sobtracker,
            claim: claim,
            trainings: trainings,
            fleet: fleet,
            carrier: carrier,
            gps: gps,
            ops: ops,
            truck: truck,
            accidents: accidents,
            ontimeSegment: ontimeSegment,
            sobtrackerSegment: sobtrackerSegment,
            claimSegment: claimSegment,
            trainingsSegment: trainingsSegment,
            fleetSegment: fleetSegment,
            carrierSegment: carrierSegment,
            gpsSegment: gpsSegment,
            opsSegment: opsSegment,
            truckSegment: truckSegment,
            accidentsSegment: accidentsSegment,
        });
    }

    submitPerformance() {
        this.setState({
            //overly:"show-m",
            //loadshow:"show-m",
        });
        var allrows = [];
        this.gridApi.forEachNode((node, index) => {
            //console.log("node ",node)
            allrows.push(node.data);
        });
        // console.log("allrows ", allrows);
        if (allrows.length > 0) {
            allrows.forEach((item) => { });
        }
    }

    showBulkUploadSlider(uploadedFor) {
        var selectedPlant = {};
        if (uploadedFor == 4) {
            selectedPlant = { label: "HARYANA", value: "HARYANA" };
        } else {
            selectedPlant = { label: "TVP Banglore", value: "TVPB" };
        }
        this.setState({
            overly: "show-m",
            uploadedFor: uploadedFor,
            sliderTranslatebulkupload: "slider-translate-40p",
            toggleMore: false,
            selectedPlant: selectedPlant,
        });
    }

    onCellPerformanceUpdateData(e) {
        // console.log(e, "clickedData");
        var month = e.colDef.field;
        var plant_code = e.data.plant_code;
        var transporter_code = this.state.transporterId.value;
        var year = this.state.fYear.value;
        var parameter = e.data.parameters;
        var editedValue = e.value;
        if (editedValue != undefined && editedValue != "") {
            var params = {
                year: year,
                month: month,
                plant_code: plant_code,
                transporter_code: transporter_code,
                parameter: parameter,
                editedValue: editedValue,
            };
            // console.log(params, "params");
            redirectURL
                .post("/consignments/saveTransporterPerformance", params)
                .then(async (response) => { })
                .catch(function (e) {
                    console.log("Error ", e);
                });
        }
    }

    saveReviewDateForReportingFleet(e) {
        // console.log(e.target.innerText);
        // redirectURL.post("/consignments/saveReviewDate", {"review_date": e.target.innerText,
        //                                                   "transporter_code": this.state.transporterId.value}).then((response)=>{

        // })
    }

    changeFileHandler = async (e) => {
        var config = {};
        if (this.state.uploadedFor == 1) {
            config = {
                headers: [
                    {
                        name: "TPT CODE",
                        inputName: "TPT CODE",
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
                        },
                    },
                    {
                        name: "Total DEC trainings",
                        inputName: "Total DEC trainings",
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
                        },
                    },
                ],
            };
        } else if (this.state.uploadedFor == 2) {
            config = {
                headers: [
                    {
                        name: "TPT CODE",
                        inputName: "transporter_code",
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
                        },
                    },
                    {
                        name: "Plant Name",
                        inputName: "Plant Name",
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
                        },
                    },
                    {
                        name: "Total GRs",
                        inputName: "Total GRs",
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
                        },
                    },
                    {
                        name: "Total claims settled against LSP",
                        inputName: "Total claims settled against LSP",
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
                        },
                    },
                    {
                        name: "%age claims settled against LSP",
                        inputName: "%age claims settled against LSP",
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
                        },
                    },
                    {
                        name: "Open delivery",
                        inputName: "Open delivery",
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
                        },
                    },
                    {
                        name: "Short delivery",
                        inputName: "Short delivery",
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
                        },
                    },
                    {
                        name: "Damage Delivery",
                        inputName: "Damage Delivery",
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
                        },
                    },
                ],
            };
        } else if (this.state.uploadedFor == 3) {
            config = {
                headers: [
                    {
                        name: "TPT CODE",
                        inputName: "transporter_code",
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
                        },
                    },
                    {
                        name: "Total trucks inspected",
                        inputName: "Total trucks inspected",
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
                        },
                    },
                    {
                        name: "OK Trucks % age",
                        inputName: "OK Trucks % age",
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
                        },
                    },
                    {
                        name: "NOT OK trucks % age",
                        inputName: "NOT OK trucks % age",
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
                        },
                    },
                ],
            };
        } else if (this.state.uploadedFor == 4) {
            var config = {
                headers: [
                    {
                        name: "TPT CODE",
                        inputName: "TPT CODE",
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
                        },
                    },
                    {
                        name: "CARRIERS INSPECTED",
                        inputName: "CARRIERS INSPECTED",
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
                        },
                    },
                    {
                        name: "AVERAGE GPA",
                        inputName: "AVERAGE GPA",
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
                        },
                    },
                    {
                        name: "CARRIERS ON HOLD",
                        inputName: "CARRIERS ON HOLD",
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
                        },
                    },
                    {
                        name: "GATE ENTRY CANCELLED",
                        inputName: "GATE ENTRY CANCELLED",
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
                        },
                    },
                ],
            };
        }
        // console.log(config, "config");
        var data = CSVFileValidator(e.target.files[0], config)
            .then((csvData) => {
                // console.log(csvData, "cs data1");
                // console.log(csvData.data, "cs data");
                this.setState({
                    csvcontent: csvData.data,
                });
            })
            .catch((err) => { });

        var out = new Promise(function (reject, resolve) {
            var reader = new FileReader();
            reader.onload = async function (e) {
                var contents = await e.target.result;
                resolve(contents);
            };
            var tt = reader.readAsText(e.target.files[0]);
        });

        this.setState({
            uploadedFile: e.target.files[0],
        });
    };

    onSubmitUploadForm(e) {
        e.preventDefault();
        var filename = this.state.uploadedFile.name.split(".");
        if (filename[filename.length - 1].toLowerCase() == "csv") {
            this.setState({
                loadshow: "show-m",
                overly: "show-m",
                sliderTranslatebulkupload: "",
            });
            var fileData = this.state.csvcontent;
            var fYear = this.state.fYear;
            // console.log(fYear);
            // console.log(fileData, this.state.uploadedFile);
            var bulkUploadDate = $(".selected_date").val();
            // console.log(bulkUploadDate);
            // var selectedFY = this.state.fYear.value;
            if (parseInt(this.state.uploadedFor) == 1) {
                var data_type = 7;
            } else if (parseInt(this.state.uploadedFor) == 2) {
                var data_type = 5;
            } else {
                var data_type = 6;
            }
            if (fileData.length) {
                var formdata = {
                    uploadedData: JSON.stringify(fileData),
                    month: bulkUploadDate,
                    year: fYear.value,
                    data_type: data_type,
                };
                if (this.state.uploadedFor == 3 || this.state.uploadedFor == 4) {
                    formdata["plant_name"] = this.state.selectedPlant.value;
                }
                // console.log(formdata, "formdata");
                redirectURL
                    .post("/consignments/prtbulkUploadLspCard", formdata)
                    .then((response) => {
                        this.setState({
                            loadshow: "show-n",
                            overly: "show-n",
                        });

                        if (response.data.status == "Success") {
                            this.setState({
                                basicTitle: "Uploaded Successfully.",
                                basicType: "success",
                                show: true,
                            });
                        } else {
                            this.setState({
                                basicTitle: "Failed to upload",
                                basicType: "warning",
                                show: true,
                            });
                        }
                    });
            } else {
                this.setState({
                    basicTitle: "Please follow the template",
                    basicType: "warning",
                    show: true,
                });
            }
        } else {
            this.setState({
                basicTitle: "Only csv files are accepted",
                basicType: "warning",
                show: true,
            });
        }
    }

    HandleToggleMore() {
        this.setState({
            toggleMore: !this.state.toggleMore,
        });
    }

    changeHandler = (event) => {
        // console.log("Select", event.target.value);
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ quarterMonth: value });
    };
    saveaopdata = () => {
        var quarterMonth = this.state.quarterMonth;
        var reqparams = {
            quarterMonth: quarterMonth,
            transporter_code: this.state.transporterId.value,
        };
        redirectURL
            .post("/consignments/storePRTaopData", reqparams)
            .then(async (response) => {
                if (response.data.message == "Success") {
                    this.setState({
                        basicTitle: "Updated Successfully",
                        basicType: "success",
                        show: true,
                    });
                } else {
                    this.setState({
                        basicTitle: "Failed to update",
                        basicType: "warning",
                        show: true,
                    });
                }
            })
            .catch(function (e) {
                console.log("Error ", e);
            });
    };
    render() {
        if (this.state.userType == "management") {
            var showTransporter = true;
            var editField = false;
        } else {
            var showTransporter = false;
            var editField = true;
        }
        const rowLength = this.state.stockData.length;
        const directorsCols = [
            {
                headerName: "LIST OF DIRECTORS / SHARE HOLDERS / PARTNERS",
                children: [
                    {
                        headerName: "Name",
                        field: "name",
                        width: 190,
                    },
                    {
                        headerName: "Designation",
                        field: "Designation",
                        width: 170,
                    },
                    {
                        headerName: "Share %",
                        field: "share",
                        width: 130,
                    },
                ],
            },
        ];
        const mgmtLastGridCols = [
            {
                headerName: "Location",
                field: "location",
                width: 140,
            },
            {
                headerName: "Cluster",
                field: "cluster",
                width: 130,
            },
            {
                headerName: "Existing SOB",
                field: "existing_sob",
                width: 120,
            },
            {
                headerName: "Existing Average Volume Lifting",
                field: "existing_avg_volume",
                width: 140,
            },
        ];
        var columnwithDefsForOperations = [
            {
                headerName: "Area Of Operation (" + this.state.quarterMonth + ")",
                field: "area_of_operation",
                width: 300,
            },
            {
                headerName: "SOB %",
                field: "approved_volume",
                width: 120,
            },
        ];
        return (
            <div
                className="container-fluid"
                style={this.state.screenView == "Mobile" ? { marginTop: "2em" } : {}}
            >
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                ></SweetAlert>

                <div className="card col-xl-12">
                    <div className="card-header">
                        <h5 className="float-left">
                            <i className="icon-map cus-i"></i>{" "}
                            <span>TPT Performance Scorecard</span>
                        </h5>
                        <div className="float-right">
                            <div className="dropdown">
                                <button
                                    type="button"
                                    title="Send EMail"
                                    onClick={() => this.HandleToggleMore()}
                                    className={
                                        "btn se-ico btn-warning dropdown-toggle text-muted " +
                                        this.state.showMailBtnb
                                    }
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    Bulk Upload
                                </button>
                                <div
                                    className={`dropdown-menu ${this.state.toggleMore ? "show" : ""
                                        }`}
                                    aria-labelledby="dropdownMenuButton"
                                >
                                    <a
                                        className="dropdown-item"
                                        onClick={this.showBulkUploadSlider.bind(this, 1)}
                                    >
                                        Driver Trainings - DEC
                                    </a>
                                    <a
                                        className="dropdown-item"
                                        onClick={this.showBulkUploadSlider.bind(this, 2)}
                                    >
                                        Claim - Data From CL03
                                    </a>
                                    <a
                                        className="dropdown-item"
                                        onClick={this.showBulkUploadSlider.bind(this, 3)}
                                    >
                                        Truck Quality
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body col-xl-12 col-lg-12">
                        <div class="row theme-form">
                            <div class={" col-xl-4 col-lg-4 form-group"}>
                                <label className="col-xl-12 col-lg-12">
                                    Select Transporter{" "}
                                </label>
                                <Select
                                    placeholder={"Transporter"}
                                    closeMenuOnSelect={true}
                                    onChange={this.onChangeTransporter.bind(this)}
                                    className={"col-xl-12 col-lg-12 border-radius-0"}
                                    style={{ borderRadius: "0px" }}
                                    value={this.state.transporterId}
                                    options={this.state.transportersList}
                                />
                            </div>
                            <div class={" col-xl-4 col-lg-4 form-group"}>
                                <label className="col-xl-12 col-lg-12">
                                    Select Financial Year{" "}
                                </label>
                                <Select
                                    placeholder={"Financial Year"}
                                    closeMenuOnSelect={true}
                                    onChange={this.onChangeFy.bind(this)}
                                    className={"col-xl-12 col-lg-12 border-radius-0"}
                                    style={{ borderRadius: "0px" }}
                                    value={this.state.fYear}
                                    options={this.state.financialyears}
                                />
                            </div>
                            <div class="col-xl-2 col-lg-2 form-group">
                                <label>&nbsp;</label>
                                <br />
                                <button
                                    type="submit"
                                    onClick={this.onClickTransporter.bind(this)}
                                    class="btn btn-success"
                                >
                                    Get Data
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-6 col-lg-6">
                                <table className="table table-bordered tptinfo">
                                    <tr>
                                        <th>LSP CODE</th>
                                        <td>{this.state.lspCode}</td>
                                    </tr>
                                    <tr>
                                        <th>LSP NAME</th>
                                        <td>{this.state.transporterId.label}</td>
                                    </tr>
                                    {/* <tr>
                                    <th>FLEET SIZE</th>
                                    <td>{this.state.fleetSize}</td>
                                </tr> */}
                                    <tr>
                                        <th>OWNER'S NAME</th>
                                        {this.state.transporterInfoprt.length > 0 ? (
                                            <td>{this.state.transporterInfoprt[0].owner_name}</td>
                                        ) : (
                                            <td></td>
                                        )}
                                    </tr>
                                    <tr>
                                        <th>MANAGER'S NAME</th>
                                        {this.state.transporterInfoprt.length > 0 ? (
                                            <td>{this.state.transporterInfoprt[0].manager_name}</td>
                                        ) : (
                                            <td></td>
                                        )}
                                    </tr>
                                    {/* <tr>
                                    <th>OPERATING CLUSTERS (GURGAON)</th>
                                    <td>{this.state.operatingClusters.gurgoanClusters.join(", ")}</td>
                                </tr>
                                <tr>
                                    <th>OPERATING CLUSTERS (GUJARAT)</th>
                                    <td>{this.state.operatingClusters.gujaratClusters.join(", ")}</td>
                                </tr>
                                <tr>
                                    <th>OPERATING CLUSTERS (TVPB)</th>
                                    <td>{this.state.operatingClusters.tvpbClusters.join(", ")}</td>
                                </tr>
                                <tr>
                                    <th>OPERATING CLUSTERS (TVPN)</th>
                                    <td>{this.state.operatingClusters.tvpnClusters.join(", ")}</td>
                                </tr>
                                <tr>
                                    <th>OPERATING CLUSTERS (SILIGURI)</th>
                                    <td>{this.state.operatingClusters.siliguriClusters.join(", ")}</td>
                                </tr> */}
                                </table>
                            </div>

                            <div className="col-xl-6 col-lg-6">
                                <div className="row">
                                    <h4 className="col-sm-12">Fleet Details</h4>
                                    {this.state.fleetSize.length > 0 ? (
                                        <div className="col-xl-12 col-lg-12">
                                            <table className="table table-bordered tptinfo">
                                                {this.state.fleetSize.map(function (fz) {
                                                    return (
                                                        <tr>
                                                            <th>{fz.truck_type}</th>
                                                            <th>{fz.truck_cnt}</th>
                                                        </tr>
                                                    );
                                                })}
                                            </table>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {/* {this.state.nonrepofleet.length > 0 ?
                                <div className="col-xl-12 col-lg-12" style={{marginTop:"5%"}}>
                                    <table className="table table-bordered tptinfo">
                                        <tr>
                                            <th className="txt-center" colSpan="2">Areas of operation (along with approved SOB %ages) </th>
                                        </tr>
                                        {(this.state.nonrepofleet).map((key,index) => {
                                           
                                        return <tbody>
                                        <tr>
                                            <th>{key.mode}</th>
                                            <td>{key.count}</td>
                                        </tr>
                                        </tbody>
                                        })}
                                    </table>
                                </div>
                                : ""} */}
                                </div>
                            </div>
                        </div>

                        <div
                            className="row f16"
                            style={{ marginBottom: "10px", marginTop: "20px" }}
                        >
                            <ul className="d-tabs tr-tabs">
                                <li className={"lttabs " + this.state.ops}>
                                    <button
                                        type="button"
                                        className="btn"
                                        onClick={this.onClickTab.bind(this, "ops")}
                                    >
                                        Area Of Operations
                                    </button>
                                </li>
                                <li className={"lttabs " + this.state.ontime}>
                                    <button
                                        type="button"
                                        className="btn"
                                        onClick={this.onClickTab.bind(this, "ontime")}
                                    >
                                        On Time Performance
                                    </button>
                                </li>

                                <li className={"lttabs " + this.state.sobtracker}>
                                    <button
                                        type="button"
                                        className="btn"
                                        onClick={this.onClickTab.bind(this, "sobtracker")}
                                    >
                                        SOB Achievement%
                                    </button>
                                </li>

                                <li className={"lttabs " + this.state.claim}>
                                    <button
                                        type="button"
                                        className="btn"
                                        onClick={this.onClickTab.bind(this, "claim")}
                                    >
                                        Claim
                                    </button>
                                </li>

                                <li className={"lttabs " + this.state.trainings}>
                                    <button
                                        type="button"
                                        className="btn"
                                        onClick={this.onClickTab.bind(this, "trainings")}
                                    >
                                        Driver Trainings (DEC)
                                    </button>
                                </li>

                                {/* <li className={"lttabs "+(this.state.fleet)}>
                                <button type="button" className="btn" onClick={this.onClickTab.bind(this,"fleet")}>Fleet Details</button>
                            </li> */}

                                <li className={"lttabs " + this.state.carrier}>
                                    <button
                                        type="button"
                                        className="btn"
                                        onClick={this.onClickTab.bind(this, "carrier")}
                                    >
                                        Daily Running{" "}
                                    </button>
                                </li>

                                <li className={"lttabs " + this.state.gps}>
                                    <button
                                        type="button"
                                        className="btn"
                                        onClick={this.onClickTab.bind(this, "gps")}
                                    >
                                        Gps Analysis
                                    </button>
                                </li>

                                <li className={"lttabs " + this.state.truck}>
                                    <button
                                        type="button"
                                        className="btn"
                                        onClick={this.onClickTab.bind(this, "truck")}
                                    >
                                        Truck Quality
                                    </button>
                                </li>

                                <li className={"lttabs " + this.state.accidents}>
                                    <button
                                        type="button"
                                        className="btn"
                                        onClick={this.onClickTab.bind(this, "accidents")}
                                    >
                                        Accidents
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className={"row " + this.state.ontimeSegment}>
                            <h5>ON TIME PERFORMANCE</h5>

                            {/* <div id="myGrid" style={{width:"100%",height:"760px", marginBottom:"1em"}} className={"ag-theme-balham"}>
                   
                            <AgGridReact
                            modules={this.state.modules}
                            columnDefs={this.state.columnwithDefs}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.ontimeData}
                            enableCharts={false}
                            // autoGroupColumnDef={this.state.autoGroupColumnDef}
                            onGridReady={this.onGridReady}
                            onGridState={this.onGridState}
                            frameworkComponents={this.state.frameworkComponents}
                            statusBar={this.state.statusBar}
                            sideBar={this.state.sideBar}
                            stopEditingWhenGridLosesFocus= {true}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={false}
                            rowClassRules={this.state.rowClassRules}
                            pinnedBottomRowData={this.state.pinnedRegionBottomRowData}
                            gridOptions={{
                                context: { componentParent: this },
                                getRowStyle: function (params) {
                                        if (params.node.rowPinned) {
                                    return { 'font-weight': 'bold','font-size':'16px' };
                                    }
                                },
                                suppressRowTransform: true,
                            }}
                            // components={this.state.components}
                            enableRangeSelection= {true}
                            // onCellClicked={this.onCellClicked.bind(this)}
                            onCellEditingStopped={this.onCellPerformanceUpdateData.bind(this)}
                            />
                        </div> */}
                            {this.state.ontimeData.length > 0 ? (
                                <FleetGrid
                                    transporterId={this.state.transporterId}
                                    fYear={this.state.fYear}
                                    fleetData={this.state.ontimeData}
                                    dataType="3"
                                />
                            ) : (
                                <h6 style={{ marginTop: "4%", textAlign: "center" }}>
                                    No Data Avilable
                                </h6>
                            )}

                            {/* <button type="button" className="btn btn-info" onClick={this.submitPerformance.bind(this)}>Update</button> */}
                        </div>

                        <div className={"row " + this.state.sobtrackerSegment}>
                            <h5>SOB ACHIEVEMENT%</h5>
                            {this.state.sobtrackerData.length > 0 ? (
                                <FleetGrid
                                    transporterId={this.state.transporterId}
                                    fYear={this.state.fYear}
                                    fleetData={this.state.sobtrackerData}
                                    dataType="7"
                                />
                            ) : (
                                <h6 style={{ marginTop: "4%", textAlign: "center" }}>
                                    No Data Avilable
                                </h6>
                            )}
                        </div>
                        <div className={"row " + this.state.claimSegment}>
                            <h5>CLAIM</h5>
                            {this.state.claimData.length > 0 ? (
                                <CarrierGrid
                                    transporterId={this.state.transporterId}
                                    fYear={this.state.fYear}
                                    carrierData={this.state.claimData}
                                    dataType="3"
                                />
                            ) : (
                                <h6 style={{ marginTop: "4%", textAlign: "center" }}>
                                    No Data Avilable
                                </h6>
                            )}
                        </div>
                        <div className={"row " + this.state.trainingsSegment}>
                            <div className="row col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <h5>DRIVER TRAININGS (DRIVER EDUCATION CENTRE - DEC)</h5>
                                </div>
                            </div>
                            {this.state.trainingsData.length > 0 ? (
                                <PrtTrainingsGrid
                                    transporterId={this.state.transporterId}
                                    fYear={this.state.fYear}
                                    trainingsData={this.state.trainingsData}
                                    driveType="1"
                                />
                            ) : (
                                <h6 style={{ marginTop: "4%", textAlign: "center" }}>
                                    No Data Avilable
                                </h6>
                            )}
                        </div>

                        <div className={"row " + this.state.fleetSegment}>
                            <h5>FLEET PERFORMANCE</h5>
                            {this.state.fleetData.length > 0 ? (
                                <FleetGrid
                                    transporterId={this.state.transporterId}
                                    fYear={this.state.fYear}
                                    fleetData={this.state.fleetData}
                                    dataType="1"
                                />
                            ) : (
                                <h6 style={{ marginTop: "4%", textAlign: "center" }}>
                                    No Data Avilable
                                </h6>
                            )}
                        </div>

                        <div className={"row " + this.state.carrierSegment}>
                            <h5>DAILY RUNNING OF CARRIER</h5>
                            {this.state.carrierData.length > 0 ? (
                                <CarrierGrid
                                    transporterId={this.state.transporterId}
                                    fYear={this.state.fYear}
                                    carrierData={this.state.carrierData}
                                    dataType="2"
                                />
                            ) : (
                                <h6 style={{ marginTop: "4%", textAlign: "center" }}>
                                    No Data Avilable
                                </h6>
                            )}
                        </div>
                        <div className={"row " + this.state.gpsSegment}>
                            <h5>GPS ANALYSIS</h5>
                            {this.state.gpsData.length > 0 ? (
                                <GpsGrid
                                    transporterId={this.state.transporterId}
                                    fYear={this.state.fYear}
                                    fleetData={this.state.gpsData}
                                    showGrid={this.state.gpsSegment}
                                />
                            ) : (
                                <h6 style={{ marginTop: "4%", textAlign: "center" }}>
                                    No Data Avilable
                                </h6>
                            )}
                        </div>
                        <div className={"row " + this.state.opsSegment}>
                            <h5>Areas of operation (along with approved SOB %ages)</h5>
                            <div className={"row col-sm-12"}>
                                <label htmlFor="area_heading" style={{fontFamily: 'roboto',margin: '5px'}}>Period</label>
                                <input
                                    style={{ width: "20%", marginBottom: "1%" }}
                                    type="text"
                                    class="form-control"
                                    name="area_heading"
                                    // placeholder="area_heading"
                                    id="area_heading"
                                    onChange={this.changeHandler}
                                />
                                <button
                                    style={{ marginLeft: "2%", height: "35px" }}
                                    className="btn btn-info"
                                    onClick={this.saveaopdata}
                                >
                                    Update
                                </button>
                            </div>
                            {this.state.nonrepofleet.length > 0 ? (
                                <div
                                    id="myGrid"
                                    style={{
                                        width: "100%",
                                        height: "760px",
                                        marginBottom: "1em",
                                    }}
                                    className={"ag-theme-balham"}
                                >
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefsForOperations}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.nonrepofleet}
                                        enableCharts={false}
                                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        stopEditingWhenGridLosesFocus={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={false}
                                        rowClassRules={this.state.rowClassRules}
                                        pinnedBottomRowData={this.state.pinnedRegionBottomRowData}
                                        gridOptions={{
                                            context: { componentParent: this },
                                            getRowStyle: function (params) {
                                                if (params.node.rowPinned) {
                                                    return { "font-weight": "bold", "font-size": "16px" };
                                                }
                                            },
                                            suppressRowTransform: true,
                                        }}
                                        // components={this.state.components}
                                        enableRangeSelection={true}
                                    // onCellClicked={this.onCellClicked.bind(this)}
                                    />
                                </div>
                            ) : (
                                <h6 style={{ marginTop: "4%", textAlign: "center" }}>
                                    No Data Avilable
                                </h6>
                            )}
                        </div>
                        <div className={"row " + this.state.truckSegment}>
                            <h5>TRUCK QUALITY</h5>
                            {this.state.truckData.length > 0 ? (
                                <FleetGrid
                                    transporterId={this.state.transporterId}
                                    fYear={this.state.fYear}
                                    fleetData={this.state.truckData}
                                    dataType="6"
                                />
                            ) : (
                                <h6 style={{ marginTop: "4%", textAlign: "center" }}>
                                    No Data Avilable
                                </h6>
                            )}
                        </div>
                        <div className={"row " + this.state.accidentsSegment}>
                            <h5>ACCIDENTS</h5>
                            <AccidentsGrid
                                transporterId={this.state.transporterId}
                                fYear={this.state.fYear}
                                accidentsData={this.state.accidentsData}
                            />
                        </div>

                        <div
                            className={
                                "row col-xl-12 col-lg-12 col-md-12 col-sm-12 " +
                                this.state.stockSegment
                            }
                            style={{ padding: "0" }}
                        >
                            <div
                                className="col-xl-12 col-lg-12 col-md-12 col-sm-12"
                                style={{ padding: "0" }}
                            ></div>

                            <div
                                className="row col-xl-12 col-lg-12 col-md-12 col-sm-12"
                                style={{ padding: "0", marginBottom: "2%" }}
                            >
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <table className="table table-bordered tptinfo">
                                        <tr>
                                            <th>LSP CODE</th>
                                            <td>{this.state.lspCode}</td>
                                        </tr>
                                        <tr>
                                            <th>LSP NAME</th>
                                            <td>{this.state.transporterId.label}</td>
                                        </tr>
                                        <tbody>
                                            <tr>
                                                <th>YEAR OF INCEPTION</th>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>YEAR OF BUSINESS IN MSIL</th>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>TYPE OF COMPANY</th>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <table className="table table-bordered tptinfo">
                                        <thead>
                                            <tr>
                                                <th className="txt-center" colSpan="6">
                                                    Fleet Size (NO. OF Carriers)
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>Apr-16</th>
                                                <th>Apr-17</th>
                                                <th>Apr-18</th>
                                                <th>Apr-19</th>
                                                <th>Apr-20</th>
                                                <th>Apr-21</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div
                                className="row col-xl-12 col-lg-12 col-md-12 col-sm-12"
                                style={{ padding: "0", marginBottom: "2%" }}
                            >
                                <div
                                    className="col-xl-6 col-lg-6 col-md-6 col-sm-6 ag-theme-balham"
                                    style={{ width: "100%", height: "150px" }}
                                >
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={directorsCols}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.directorsRowData}
                                        enableCharts={false}
                                        statusBar={false}
                                        sideBar={false}
                                        stopEditingWhenGridLosesFocus={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={false}
                                        gridOptions={{
                                            context: { componentParent: this },
                                        }}
                                        enableRangeSelection={true}
                                    />
                                </div>

                                <div
                                    className="col-xl-6 col-lg-6 col-md-6 col-sm-6 ag-theme-balham"
                                    style={{ width: "100%", height: "150px" }}
                                >
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={mgmtLastGridCols}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.mgmntLastGridRowData}
                                        enableCharts={false}
                                        statusBar={false}
                                        sideBar={false}
                                        stopEditingWhenGridLosesFocus={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={false}
                                        gridOptions={{
                                            context: { componentParent: this },
                                        }}
                                        enableRangeSelection={true}
                                    />
                                </div>
                            </div>

                            <div
                                className="row col-xl-12 col-lg-12 col-md-12 col-sm-12"
                                style={{ padding: "0" }}
                            >
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    {this.state.stockData.length > 0 ? (
                                        <StockGrid
                                            transporterId={this.state.transporterId}
                                            stockData={this.state.stockData}
                                            gridTitle="VOLUME (NO. OF CARS)"
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    {this.state.frgData.length > 0 ? (
                                        <StockGrid
                                            transporterId={this.state.transporterId}
                                            stockData={this.state.frgData}
                                            gridTitle="FREIGHT OUTFLOW (CR.)"
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={"slide-r " + this.state.sliderTranslatebulkupload}
                    style={{ overflow: "auto" }}
                >
                    <div className="slide-r-title">
                        <h4>
                            Bulk Upload
                            <span
                                className="float-right closebtn"
                                style={{ marginRight: "25px" }}
                                onClick={this.onClickHideAll.bind(this)}
                            >
                                X
                            </span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="container-fluid">
                            <form
                                method="POST"
                                id="upform"
                                className="theme-form"
                                onSubmit={this.onSubmitUploadForm.bind(this)}
                            >
                                <div className="form-group mt-20p">
                                    <label>Select Month</label>
                                    <input
                                        type="text"
                                        className="selected_date form-control"
                                        id="dispatch_dashboard_date"
                                        required
                                        autoComplete="off"
                                    />
                                </div>

                                <div className="form-group mt-20p">
                                    <label className="">Upload File</label>
                                    <input
                                        type="file"
                                        name="uploadFile"
                                        onChange={this.changeFileHandler.bind(this)}
                                        className="form-control"
                                        required
                                    />
                                </div>

                                {this.state.uploadedFor == 4 ? (
                                    <div className="form-group mt-20p">
                                        <Select
                                            isMulti={false}
                                            options={[
                                                { label: "TVP Banglore", value: "TVPB" },
                                                { label: "TVP Nagpur", value: "TVPN" },
                                            ]}
                                            value={this.state.selectedPlant}
                                            class="form-control"
                                            onChange={(e) => {
                                                this.setState({ selectedPlant: e });
                                            }}
                                            closeMenuOnSelect={true}
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}

                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">
                                        Submit
                                    </button>
                                </div>
                            </form>

                            <div className="form-group">
                                {this.state.uploadedFor == 1 ? (
                                    <a
                                        className="btn btn-primary"
                                        href={require("../../assets/json/prt_driver_trainings.csv")}
                                        target="_blank"
                                    >
                                        Sample Template
                                    </a>
                                ) : (
                                    ""
                                )}
                                {this.state.uploadedFor == 2 ? (
                                    <a
                                        className="btn btn-primary"
                                        href={require("../../assets/json/prt_claim_data.csv")}
                                        target="_blank"
                                    >
                                        Sample Template
                                    </a>
                                ) : (
                                    ""
                                )}
                                {this.state.uploadedFor == 3 ? (
                                    <a
                                        className="btn btn-primary"
                                        href={require("../../assets/json/bulk_upload_truck_quality.csv")}
                                        target="_blank"
                                    >
                                        Sample Template
                                    </a>
                                ) : (
                                    ""
                                )}
                                {this.state.uploadedFor == 4 ? (
                                    <a
                                        className="btn btn-primary"
                                        href={require("../../assets/json/bulk_upload_fleet_performance.csv")}
                                        target="_blank"
                                    >
                                        Sample Template
                                    </a>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="form-group mt-20 col-lx-12 col-lg-12 col-md-12 col-sm-12">
                                <h6 style={{ color: "red" }}>
                                    Note: <br />
                                    * Please use the attached sample to upload Data
                                    <br />* Do not alter the header names.
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"dataLoadpage " + this.state.loadshow}></div>
                <div
                    className={"dataLoadpageimg " + this.state.loadshow}
                    style={{ position: "fixed", top: "43%" }}
                >
                    <div className="loader-box">
                        <div className="loader-box">
                            <div className="rotate dashed colored"></div>
                        </div>
                    </div>
                </div>
                <div
                    className={"overlay-part " + this.state.overly}
                    onClick={this.onClickHideAll.bind(this)}
                ></div>
            </div>
        );
    }
}

function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0];
    var script = window.document.createElement("script");
    script.src = url;
    script.async = true;
    script.defer = true;
    index.parentNode.insertBefore(script, index);
}

function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber1(date) {
    // console.log(date.length);
    // console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber, monthNumber, dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}

function loadDateTimeScript() {
    // alert("timeDate");
    // $('.datetimepicker_mask').datetimepicker({
    // //mask:'39-19-9999 29:59',
    // format:'d-m-Y'
    // });
    // $('.datetimepicker_date').datetimepicker({
    // mask:'39-19-9999',
    // format:'d-m-Y',
    // timepicker:false
    // });
    // var index  =window.document.getElementsByTagName("script")[1]
    // var script = window.document.createElement("script")
    // script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    // //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    // script.async=true
    // script.defer = true
    // index.parentNode.insertBefore(script,index)
    $("#dispatch_dashboard_date").datepicker({
        changeMonth: true,
        changeYear: true,
        showButtonPanel: false,
        dateFormat: "mm-yy",
        onClose: function (dateText, inst) {
            //Get the selected month value
            var month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();

            //Get the selected year value
            var year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();

            //set month value to the textbox
            $(this).datepicker("setDate", new Date(year, month, 1));
        },
        beforeShow: function (input, inst) {
            $("#ui-datepicker-div").addClass("dispatch_dashboard_date");
        },
    });
}

function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement("input");
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker: false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    // console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function rowdSpan(params) {
    if (params.data.loading_point) {
        return 11;
    } else {
        return 1;
    }
}

function NumericCellEditor() { }

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement("input");

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener("keypress", function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber =
        params.charPress && "1234567890".indexOf(params.charPress) < 0;
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return typeof event.which == "undefined" ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39 || event.keyCode === 37;
};

// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf("007") >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

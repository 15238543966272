import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { TabContent, TabPane } from 'reactstrap';
import CKEditor from "react-ckeditor-component";

import _ from "lodash";

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import CountUp from 'react-countup';
import Modal from 'react-responsive-modal';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import RoutemapNoGPSAction from './transportermapnogps';
import RouteNightDrivemap from './transporternightdrive';
import RouteOverspeed from './transporteroverspeed';
import RoutemapEnroute from './transporterenroute';
import Sendemailticket from './sendEmailTicket';
import { getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS, 
	getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY } from '../common/utils';
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
var infoBox = require('../common/google-infowindow');
 
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

 
var containerslist = [];

export default class TransporterTickets extends Component {

	constructor(props){
		super(props);
		this.state={
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
			basicTitle:'',
			showdiscard:'show-n',
			rownode:'',
			totaltypes:[],
			basicType:"default",
			modules: AllModules,
			defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: null,
			contactrowData:null,
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
                customLoadingCellRenderer: CustomLoadingCellRenderer,
                RoutemapNoGPSAction:RoutemapNoGPSAction,
                RouteNightDrivemap:RouteNightDrivemap,
                RouteOverspeed:RouteOverspeed,
                RoutemapEnroute:RoutemapEnroute
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			rowSelection:'multiple',
			detailCellRendererParams:{},
            sliderRouteTranslate:'',
            transporter:'',
            consignercords:[],
            consigneecords:[],
            transit:0,
            nightdrive:0,
            gps:0,
            overspeed:0,
            originalData:[],
            enroute:0,
            usergridstate:[],
			  screenurl:"",
			  screentitle:"",
			  pagetitle:"Transporter Ticket management system",
    			  eventGridAction: "gridAction",

        }

		this.onShowNoGPSRouteMap = this.onShowNoGPSRouteMap.bind(this);
        this.onShowGoogleRoute = this.onShowGoogleRoute.bind(this);
        this.onShowNightDriveRouteMap = this.onShowNightDriveRouteMap.bind(this);
		this.onShowOverspeed = this.onShowOverspeed.bind(this);
        this.onShowEnrouteRouteMap = this.onShowEnrouteRouteMap.bind(this);
        this.onShowTransitRouteMap = this.onShowTransitRouteMap.bind(this);
	}
	componentDidMount = async() => {
        await redirectURL.post('/tms/transporter', {transporter_code:localStorage.getItem("transportercode")})
        .then(async (response) =>{
            //console.log("r ", response.data[0].transporter_name)
            this.setState({
                transporter:response.data[0].transporter_name
            });
        })
        .catch(function(e){
            console.log("Error ", e)
        })

        this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});


		redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
        })
        
       // console.log(this.state.transporter)
        var reqparms = {
            transporter_name:this.state.transporter
        }
        redirectURL.post("/tms/transportersTickets", reqparms)
        .then((response) => {
            //console.log("res ", response.data)
            var responsedata = response.data
            console.log("responsedata ", responsedata)
            var totalarr = []
            var transitarr = []
            var gpsarr = []
            var overspeedarr = []
            var nightdrivearr = []
            var enroutearr = []
            var responsedataarr = []
            if(responsedata.length > 0)
            {
                responsedata.map((item) => {
                    if(item.ticket_type == 'no_gps')
                    {
                        gpsarr.push(item)
                    }

                    if(item.ticket_type == 'transit_delay')
                    {
                        transitarr.push(item)
                        responsedataarr.push(item)
                    }

                    if(item.ticket_type == 'overspeeding')
                    {
                        overspeedarr.push(item)
                        responsedataarr.push(item)
                    }

                    if(item.ticket_type == 'night_driving')
                    {
                        nightdrivearr.push(item)
                        responsedataarr.push(item)
                    }

                    if(item.ticket_type == 'enroute_stoppage')
                    {
                        enroutearr.push(item)
                        responsedataarr.push(item)
                    }
                })
            }
            this.setState({
                rowData:responsedataarr,
                originalData:responsedataarr,
                gps:gpsarr.length,
                transit:transitarr.length,
                overspeed:overspeedarr.length,
                nightdrive:nightdrivearr.length,
                enroute:enroutearr.length,
                totaltypes:responsedataarr.length
            })
            
        })
    };
    
    //NO GPS Map
	onShowNoGPSRouteMap = async(rownode) => {
		console.log("rownode", rownode);
		this.setState({
			loadshow:'show-m'
        })

        var sreqpamrs = {
            consignment_code:rownode.consignment_codes, 
            truck_no:rownode.truck_no,
            last_packet_datetime:rownode.last_packet_datetime,
            ticket_id:rownode.ticket_id
        }
        
        redirectURL.post('/tms/nogpsdata', sreqpamrs)
        .then(async (response) =>{
            console.log("Step 1 ",response.data)
            if(response.data.length > 0)
            {
                var transitedelaydata=response.data;
                var fintance = getHyphenYYYYMMDDHHMMSS(transitedelaydata[0].last_packet_datetime);
                if(typeof transitedelaydata[0].packet_received_on == 'undefined' )
                {
                    var eintance = getHyphenYYYYMMDDHHMMSS();
    
                }
                else
                {
                    var eintance = getHyphenYYYYMMDDHHMMSS(transitedelaydata[0].packet_received_on);
    
                }
                
                var params = { 
                    truck_no:transitedelaydata[0].truck_no,
                    consignment_code:transitedelaydata[0].consignment_codes,
                    //consignee_code:rownode.consignee_code,
                    first_instance:fintance,
                    recent_instance:eintance,
                    screen:"consignment"
                }
                redirectURL.post('/tms/nightdriveroutes',params)
                .then((response) => {	
                
                //var records = JSON.parse(JSON.stringify(response)).data;
                    //console.log("Inner Grid ",response.data);
                    if(response.data.coords.length == 0)
                    {
                        this.setState({
                            show: true,
                            basicTitle:'No Route data available',
                            basicType:"danger",
                            loadshow:'show-n'
                        });
                    }
                    else{
                        var sdate='';
                        var edate='';
    
                        if(transitedelaydata[0].last_packet_datetime != '' && transitedelaydata[0].last_packet_datetime != undefined)
                        {
                            sdate=getHyphenYYYYMMDDHHMMSS(transitedelaydata[0].last_packet_datetime);
                        }
                        if(transitedelaydata[0].packet_received_on != '' && transitedelaydata[0].packet_received_on != undefined)
                        {
                            edate=getHyphenYYYYMMDDHHMMSS(transitedelaydata[0].packet_received_on)
                        }
                    this.setState({
                            mapinfo:response.data,
                            dealer:transitedelaydata[0].consignee_code,
                            sliderRouteTranslate:"slider-translate-50p",
                            defTransitCoords: transitedelaydata[0].consignee_coordinates,
                            consignment_code:"Consignment : "+transitedelaydata[0].truck_no,
                            maptruckno:transitedelaydata[0].truck_no,
                            routeTruck:	{"truck_no":transitedelaydata[0].truck_no,"startTime":sdate,"endTime": edate},
                            loadshow:'show-n',
                            sidebarSubHeader:"Consignment Leg Information",
                            overly:'show-m',
                            rownode:transitedelaydata,
                            leg_no:transitedelaydata[0].leg_no
    
                    });
    
                    // this.onShowGoogleRoute(transitedelaydata.consignment_code, transitedelaydata.consignee_code, transitedelaydata.leg_no, transitedelaydata.truck_no)
                    
                    } 
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
            else
            {
                this.setState({
                    show: true,
                    basicTitle:'No Route data available',
                    basicType:"danger",
                    loadshow:'show-n'
                });
            }
            
        })
		
		  
    }

    logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }


    /*Night Driving */
    onShowNightDriveRouteMap = async (rownode) => {
        //console.log("nightdrivenode", rownode);
        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow:'show-m'
		});
        var consignercords = JSON.parse(rownode.consigner_locations[0]);
        var consigneecords = rownode.consignee_locations;
		this.setState({
            loadshow:'show-m',
            consignercords:consignercords,
            consigneecords:consigneecords
        });
        await redirectURL.post('/tms/ticketnightdriving', {
            consignment_code:rownode.consignment_codes,
            //gate_out_time:params.data.gate_out_time, 
            truck_no:rownode.truck_no,
            first_instance:rownode.first_instance
        })
        .then(async (response) =>{
            //console.log("Step 1 ",response.data)
            var row = response.data[0];
            var d = new Date(row.first_instance);
            var r = new Date(row.recent_instance);
            //var fintance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
            //var eintance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
            var fintance = getHyphenYYYYMMDDHHMMSS(row.first_instance);
            var eintance = getHyphenYYYYMMDDHHMMSS(row.recent_instance);
            
            var params = { 
            	truck_no:row.truck_no,
                first_instance:fintance,
                recent_instance:eintance,
            }
            
             redirectURL.post('/tms/nightdriveroutes',params)
              .then((response) => {
            
               //var records = JSON.parse(JSON.stringify(response)).data;
            	//console.log("nightroutedata",response.data);
                
                
               this.setState({
            		mapinfo:response.data,
            		sliderRouteTranslate:"slider-translate-50p",
            		consignment_code:row.truck_no,
            		defTransitCoords: {"lat":row.first_lat,"lng":row.first_lng},
            		maptruckno:row.truck_no,
            		routeTruck:	{"truck_no":row.truck_no,"startTime":getDDMMYYYYHHMMSS(response.data.route_details['start_time']),
            			"endTime": getDDMMYYYYHHMMSS(response.data.route_details['end_time'])},
            		loadshow:'show-n',
            		overly:'show-m'
               });
               	//this.renderMap();
            
                
              })
              .catch(function (error) {
                console.log(error);
              });
        });
    }

    /*Overspeed*/
    onShowOverspeed = async (rownode) => {
		//console.log("rownode", rownode);
        //console.log("rownode.consignment_code ", rownode.consignment_code)
        let eventLabel = googleAnalytics.page.action.overspeed;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
        redirectURL.post('/tms/ticketoverspeed', {
            consignment_code:rownode.consignment_codes,
            truck_no:rownode.truck_no,
            first_instance:rownode.first_instance
        })
        .then(async (response) =>{
            //console.log("Step 1 ",response.data)
            var row = response.data[0];
            this.setState({
                loadshow:'show-m'
            })
            var finstance =getHyphenYYYYMMDDHHMMSS(row.first_instance);
            var rinstance = getHyphenYYYYMMDDHHMMSS(row.recent_instance);
           
            var params = { 
                truck_no:row.truck_no,
                consignment_code:row.consignment_codes,
                consignee_code:row.consignee_codes,
                first_instance:finstance,
                recent_instance:rinstance,
                screen:"consignment"
            }
            //console.log("params ", params)
            
             await redirectURL.post('/tms/overspeedgpsroutes',params)
              .then((response) => {
              
               //var records = JSON.parse(JSON.stringify(response)).data;
                //console.log("Overspeed  ",rownode);
               this.setState({
                    mapinfo:response.data,
                    //dealer:rownode.consignee_code,
                    sliderRouteTranslate:"slider-translate-50p",
                    consignment_code:row.truck_no,
                    maptruckno:row.truck_no,
                    routeTruck:	{"truck_no":row.truck_no,"startTime":getDDMMYYYYHHMMSS(response.data.route_details['start_time']),
                        "endTime": getDDMMYYYYHHMMSS(response.data.route_details['end_time'])},
                    overly:'show-m',
                    loadshow:'show-n',
                    rownode:row
               });
               //this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, 0, rownode.truck_no)
                        
                   //this.renderMap();
            
                   
              })
              .catch(function (error) {
                console.log(error);
              });
        });

		  
	}

    /*Enroute Stoppages*/
    onShowEnrouteRouteMap(rownode){
		// console.log("Enroute Routemap rownode", rownode);
		// console.log("UI Step1", moment.parseZone().format('HH:mm:ss'))
        
        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        this.setState({
			loadshow:'show-m'
        })
        
        if(rownode.stoppage_start_time)
        {
            var sstartime = rownode.stoppage_start_time
        }
        else
        {
            var sstartime = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
        }
        redirectURL.post('/tms/ticketenroutes', {
            //consignment_code:params.data.consignment_codes,
            //consignee_code:params.data.consignee_codes[0],
            truck_no:rownode.truck_no,
            stoppage_start_time:sstartime
        })
        .then(async (response) =>{
            console.log("Step 1 ",response.data)
            var overspeedData=response.data;
            var endata = []
            if(overspeedData.length > 0)
            {
                overspeedData.map((item) => {
                    var cdatetime = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
                    console.log("Reset ",item.reset_datetime)
                    console.log("Stoppage ",item.stoppage_start_time)
                    //console.log(item.hasOwnProperty('reset_datetime'));
                    if(item.hasOwnProperty('reset_datetime') === false)
                    {
                        var cdatetme = moment.parseZone(cdatetime).format("X");
                        var stoptime = moment.parseZone(item.stoppage_start_time).format('YYYY-MM-DD HH:mm:ss');
                        
                        var etne = moment.parseZone(stoptime).format("X");
                        if(cdatetme < etne)
                        {
                            var brtime = etne-cdatetme;
                        
                        }
                        else
                        {
                            var brtime = cdatetme-etne;
                        
                        }
                        var breaktime = secondsToString(brtime); 
                        var break_end = moment.parseZone(cdatetime).format('DD-MM-YYYY HH:mm:ss');
                        // console.log("cdatetime ", cdatetime);
                            console.log("cdatetme ", cdatetme);
                        // console.log("stoppage_start_time ", stoptime);
                        // console.log("etne ",etne);
                    }
                    else
                    {
                        if(item.reset_datetime < item.stoppage_start_time)
                        {
                            var cdatetme = moment.parseZone(cdatetime).format("X");
                            var etne = moment.parseZone(item.stoppage_start_time).format("X");
                            var brtime = cdatetme-etne;
                            var breaktime = secondsToString(brtime); 
                            var break_end = moment.parseZone(cdatetime).format('DD-MM-YYYY HH:mm:ss');
                            console.log("cdatetme 1", cdatetme);
                            console.log("etne 1", etne);
                        }
                        else{
                            
                            var cdatetme = moment.parseZone(item.reset_datetime).format("X");
                            var etne = moment.parseZone(item.stoppage_start_time).format("X");
                            var brtime = cdatetme-etne;
                            var breaktime = secondsToString(brtime); 
                            var break_end = moment.parseZone(item.reset_datetime).format('DD-MM-YYYY HH:mm:ss');
                            console.log("cdatetme here", cdatetme);
                            console.log("etne here", etne);
                        }
                    }
                
                        console.log("breaktime ", brtime)
                        console.log("break_start ", item.stoppage_start_time)
                        console.log("break_end ", break_end)
                    endata.push({
                        stoppage_start_time:item.stoppage_start_time,
                        reset_datetime:item.reset_datetime,
                        break_time:breaktime,
                        break_end:break_end,
                        truck_no:item.truck_no,
                        truck_no:item.truck_no,
                        consignment_codes:item.consignment_codes,
                        ticket_id:item.ticket_id,
                        ticket_type:item.ticket_type,
                        ticket_status:item.ticket_status,
                        consignment_object_ids:item.consignment_object_ids,
                        dept_code:item.dept_code,
                        transporter_name:item.transporter_name
                    })
                })
            }
            //params.successCallback(endata);
            console.log("Step 2 ",endata)
            
        });
        

		var fintance =moment.parseZone(rownode.stoppage_start_time).format('YYYY-MM-DD HH:mm:ss');
		// rownode.stoppage_start_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var stime = moment.parseZone(fintance).format("X")
		if(rownode.reset_datetime && rownode.reset_datetime != '')
		{
			console.log("1")
			var etime = moment.parseZone(rownode.reset_datetime).format("X");
			console.log(stime+" > "+etime)
			if(stime > etime)
			{
				var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
			
			}
			else
			{
				console.log("2")
				//var eintance = rownode.reset_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				var eintance = moment.parseZone(rownode.reset_datetime).format('YYYY-MM-DD HH:mm:ss');
			}
		}
		else{
			console.log("3")
			var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		
		}
		//var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = { 
			truck_no:rownode.truck_no,
			//consignment_code:rownode.consignment_code,
			//consignee_code:rownode.consignee_code,
            first_instance:fintance,
			recent_instance:eintance,
			screen:"consignment"
		}
		 redirectURL.post('/tms/nightdriveroutes',params)
		  .then((response) => {	
			
		//console.log("UI Step2", moment.parseZone().format('HH:mm:ss'))
		   //var records = JSON.parse(JSON.stringify(response)).data;
			//console.log("Inner Grid ",response.data);
			if(response.data.coords.length == 0)
			{
				this.setState({
					show: true,
					basicTitle:'No Route data available',
					basicType:"danger",
					loadshow:'show-n'
				});
			}
			else{
				var sdate='';
				var edate='';

				if(rownode.leg_start != '' && rownode.leg_start != undefined)
				{
					sdate=rownode.stoppage_start_time;
				}
				if(rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined)
				{
					edate=getHyphenYYYYMMDDHHMMSS(rownode.leg_end_eta)
				}
			this.setState({
					mapinfo:response.data,
					dealer:rownode.consignee_code,
					sliderRouteTranslate:"slider-translate-50p",
					defTransitCoords: rownode.consignee_coordinates,
					consignment_code:"Consignment : "+rownode.truck_no,
					maptruckno:rownode.truck_no,
					routeTruck:	{"truck_no":rownode.truck_no,"startTime":getDDMMYYYYHHMMSS(fintance),"endTime": getDDMMYYYYHHMMSS(eintance)},
					loadshow:'show-n',
					sidebarSubHeader:"Consignment Leg Information",
					overly:'show-m',
					rownode:rownode,
					leg_no:rownode.leg_no

			});

			// this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
			//this.renderMap();
			
			} 
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
	}
    onShowGoogleRoute(consignmentcode, consigneecode, legno, truck_no){
        //console.log("GOogle params ", consignmentcode+", "+ consigneecode+", "+legno)
        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		var reqData = {
			consignment_code:consignmentcode,
			consignee_code:consigneecode,
			leg_no:legno,
			truck_no:truck_no
		}
		redirectURL.post('/consignments/googlemaproutes',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			//console.log("Google ", response)
			//var gdt = '[{	"lat": 28.367420000000003,	"lng": 76.90442},{	"lat": 28.379795,	"lng": 76.903625},{	"lat": 28.380361999999998,	"lng": 76.899445},{	"lat": 28.383162,	"lng": 76.90275},{	"lat": 28.384619,	"lng": 76.900024},{	"lat": 28.385069,	"lng": 76.900024},{	"lat": 28.384990000000002,	"lng": 76.8998}]' 
			this.setState({
				googelRoutes:response.data.googleroute
			})
		})
		.catch(function(e){
			console.log(e)
		})
	}
    changeHandler(event){
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]:value
        });
    }

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            sliderRouteTranslate:'',
            sliderTicketTranslate:'',
			tabsliderTranslate:'',
			inboxSlider:'',
			bulkslide:'',
			viewmailSlider:"",
			viewcols:"col-xl-12 col-lg-12",
			viewshow:'show-n',
			transportcont:''
		});
		
	}

	closeAlert = () => {
        this.setState({
            show: false
        });
    }

	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
    //    };
       
	    
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
    }
    onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
    }
    /*End*/
    
    ticketCellUpdateContent(params){
        //console.log("Editint params ", params)
        var reqparams = {
            transporter_response_text:params.value,
            ticket_number:params.data.ticket_number,
            userid:localStorage.getItem("userid")
        }
        redirectURL.post("/tms/transporterresponse", reqparams)
        .then((response) => {
            console.log("rescp ", response.data)
        })
    }

    onClickTicketTypeCounterShowData(params){
		//console.log("Counter Params: ", params);
		let filterInstance = null;
		let filterInstance2 = null;
		let filterActivated = false;
		try{
			
			this.gridApi.setFilterModel(null);
			this.gridApi.onFilterChanged();
			this.setState({
				showMailBtn:'show-n',
				showCommentBtn:'show-n'
			});
			filterInstance = this.gridApi.getFilterInstance("ticket_type");
			filterActivated = true;
			if(params == 'all')
			{
				filterActivated = false;
			}
			try{
			//console.log("filterActivated ",filterActivated)
				if (filterActivated) {
					
					let isFilterDataAvailable = this.state.rowData.filter(rec => {
						if (rec["ticket_type"]) {
							if(params == 'transitdelay')
							{
								return rec["ticket_type"] == "transit_delay";
							}
							if(params == 'nogps')
							{
								return rec["ticket_type"] == "no_gps";
							}
							if(params == 'overspeeding')
							{
								return rec["ticket_type"] == "overspeeding";
							}
							if(params == 'enroute')
							{
								return rec["ticket_type"] == "enroute_stoppage";
							}
							if(params == 'nightdrive')
							{
								return rec["ticket_type"] == "night_driving";
							}
							if(params == 'loadingdelay')
							{
								return rec["ticket_type"] == "loading_delay";
							}
							if(params == 'unloadingdelay')
							{
								return rec["ticket_type"] == "unloading_delay";
							}
						}
					});
					if (isFilterDataAvailable.length) {
						this.gridApi.setRowData(isFilterDataAvailable);
						this.gridApi.onFilterChanged();
					}
					else{
						// filterInstance.selectNothing();
						//filterInstance.selectValue("");
						// filterInstance.applyModel();
						this.gridApi.setRowData([]);
						this.gridApi.onFilterChanged();
					}
				}
				else{
					console.log("Here")
					this.gridApi.setRowData(this.state.rowData);
					this.gridApi.onFilterChanged();
				}
			}
			catch(e){
				console.log("Counter Filter Error ", e)
			}
		}
		catch(e){
			console.log("Counter Filter Error ", e)
		}
    }

    onShowTransitRouteMap(rownode){
       // console.log("rownode ", rownode)
        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}

        var ticketparams = {
            ticket_id:rownode.ticket_number
        }
        
        this.setState({
            loadshow:'show-m'
        })
        redirectURL.post("tms/ticket",ticketparams)
        .then((resp) => {
            //console.log("Ticket info ", resp.data);
            var row = resp.data;
            var consignercords = JSON.parse(resp.data[0].consigner_locations[0]);
            //console.log("consignercords " , consignercords);

            var consigneecords = resp.data[0].consignee_locations;
            //console.log("consignee_locations " , consigneecords)
            this.setState({
                consignercords:consignercords,
                consigneecords:consigneecords
            });


            var d = new Date(row.leg_start);
            var r = new Date(row.leg_end_eta);
            //var fintance = rownode.leg_start.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
            //var eintance = rownode.leg_end_eta.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
            
            var fintance = getHyphenYYYYMMDDHHMMSS(row.gate_out_time);
            var eintance = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
            
            var params = { 
                truck_no:row.truck_no,
                consignment_code:row.consignment_code,
                consignee_code:row.consignee_code,
                first_instance:fintance,
                recent_instance:eintance,
                screen:"consignment"
            }
             redirectURL.post('/tms/nightdriveroutes',params)
              .then((response) => {	
              
                if(response.data.coords.length == 0)
                {
                    this.setState({
                        show: true,
                        basicTitle:'No Route data available',
                        basicType:"danger",
                        loadshow:'show-n'
                    });
                }
                else{
                    var sdate='';
                    var edate='';
    
                    if(rownode.leg_start != '' && rownode.leg_start != undefined)
                    {
                        sdate=getDDMMYYYYHHMMSS(rownode.leg_start);
                    }
                    if(rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined)
                    {
                        edate=getDDMMYYYYHHMMSS(rownode.leg_end_eta)
                    }
                this.setState({
                        mapinfo:response.data,
                        dealer:row.consignee_code,
                        sliderRouteTranslate:"slider-translate-50p",
                        defTransitCoords: row.consignee_coordinates,
                        consignment_code:"Ticket : "+row.truck_no,
                        maptruckno:row.truck_no,
                        routeTruck:	{"truck_no":row.truck_no,"startTime":fintance,"endTime": eintance},
                        loadshow:'show-n',
                        sidebarSubHeader:"Consignment Leg Information",
                        overly:'show-m',
                        rownode:row,
                        leg_no:row.leg_no
    
                });
    
                // this.onShowGoogleRoute(row.consignment_code, row.consignee_code, row.leg_no, row.truck_no)
                    //this.renderMap();
                
                } 
              })
              .catch(function (error) {
                console.log(error);
              });
    
              


        })
        .catch(function(e){
            console.log("Error ",e);
        })
    }

    onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		
		let screenpage = 'Transporter Ticket Management';


		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
		try{


			if(this.state.usergridstate.length > 0)
			{
				var windowstates = this.state.usergridstate;
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
			}
		}
		catch(e){}
	}

	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};


	render(){
		const { open } = this.state;
        const columnwithDefs =[
            {
                headerName:"",
                field:"ticket_type",
                cellRendererSelector:function(params){
                    if(params.data.ticket_type == 'no_gps')
                    {
                        var rendComponent = {
                            component: 'RoutemapNoGPSAction'
                        };
                        return rendComponent;
                    }

                    else if(params.data.ticket_type == 'night_driving')
                    {
                        var rendComponent = {
                            component: 'RouteNightDrivemap'
                        };
                        return rendComponent;
                    }
                    else if(params.data.ticket_type == 'overspeeding')
                    {
                        var rendComponent = {
                            component: 'RouteOverspeed'
                        };
                        return rendComponent;
                    }
                    else if(params.data.ticket_type == 'enroute_stoppage')
                    {
                        var rendComponent = {
                            component: 'RoutemapEnroute'
                        };
                        return rendComponent;
                    }
                    else if(params.data.ticket_type == 'transit_delay')
                    {
                        var rendComponent = {
                            component: 'RoutemapEnroute'
                        };
                        return rendComponent;
                    }
                    
                    else{
                        return '';
                    }
                   
                },
                width:50,
                suppressSizeToFit: true,
            },
            {
                headerName:"Ticket Type",
                field:"ticket_type",
                width:100,
                valueGetter:function(params){
                    if(params.data.ticket_type == 'no_gps'){
                        return "No GPS Data";
                    }
                    if(params.data.ticket_type == 'transit_delay'){
                        return "Transit Delay";
                    }
                    if(params.data.ticket_type == 'overspeeding'){
                        return "Overspeed";
                    }
                    if(params.data.ticket_type == 'night_driving'){
                        return "Night Driving";
                    }
                    if(params.data.ticket_type == 'enroute_stoppage'){
                        return "Enroute Stoppage";
                    }
                }

            },
            {
                headerName:"Ticket No",
                width:100,
                field:"ticket_number"

            },
            {
                headerName:"Truck No",
                width:100,
                field:"truck_no"

            },
            {
                headerName:"Ticket Creation Date",
                field:"ticket_creation_date",
                width:180,
                valueGetter:function(params){
                    if(typeof params.data.ticket_creation_date != 'undefined')
                    {
                        return getDDMMYYYYHHMMSS(params.data.ticket_creation_date)    
                    }
                    else
                    {
                        return ''
                    }
                    
                }
            },
            {
                headerName:"Ticket Details",
                width:300,
                field:"ticket_text",
                cellClass: ['wraptext'],
                autoHeight: true,
            },
            {
                headerName:"Transporter Response",
                field:"transporter_response_text",
                editable:true,
                width:250,
                cellClass: ['wraptext'],
                autoHeight: true,
            }
        ];
		return(
			<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
				<div className="row">
                    <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                        <div className="card">
                            <div className="card-body p-10px">
                                <div className="crm-numbers pb-0">
                                    <div className="row">                                    
                                        <div className="col cpointer" onClick={this.onClickTicketTypeCounterShowData.bind(this,"all")}>
                                            <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Total Tickets</span>
                                                <h4 className="txt-pink-medium f40">
                                                <span className="counter">
                                                    <CountUp end={this.state.totaltypes}/>
                                                </span>
                                            </h4>
                                        </div>
                                        <div className="col cpointer" onClick={this.onClickTicketTypeCounterShowData.bind(this,"transitdelay")}>
                                            <span className="f13"><i className="icofont icofont-clock-time f22 redfont"></i><br /> Transit Delays</span>
                                            <h4 className="txt-danger f40">
                                                <span className="counter">
                                                    <CountUp end={this.state.transit}/>
                                                </span>
                                            </h4>
                                        </div>
{/* 
                                        <div className="col cpointer" onClick={this.onClickTicketTypeCounterShowData.bind(this,"nogps")}>
                                            <span className="f13"><i className="icofont icofont-social-google-map f24 txt-secondary"></i><br /> GPS Data NA</span>
                                                <h4 className="txt-secondary f40">
                                                <span className="counter">
                                                    <CountUp end= {this.state.gps}/>
                                                </span>
                                            </h4>
                                        </div> */}

                                        <div className="col cpointer" onClick={this.onClickTicketTypeCounterShowData.bind(this,"overspeeding")}>
                                            <span className="f13"><i className="fa fa-tachometer f22 txt-success"></i><br /> Overspeeding</span>
                                                <h4 className="txt-success f40">
                                                <span className="counter">
                                                    <CountUp end={this.state.overspeed}/>
                                                </span>
                                            </h4>
                                        </div>
                                   
                                    
                                        <div className="col cirlce-d cpointer" onClick={this.onClickTicketTypeCounterShowData.bind(this,"nightdrive")}>
                                            <span className="f13"><i className="icofont icofont-full-night f24 txt-primary"></i><br /> Night Driving</span>
                                                <h4 className="txt-primary f40">
                                                <span className="counter">
                                                    <CountUp end={this.state.nightdrive}/>
                                                </span>
                                            </h4>
                                        </div> 

                                        <div className="col cirlce-d cpointer" onClick={this.onClickTicketTypeCounterShowData.bind(this,"enroute")}>
                                            <span className="f13"><i className="icofont icofont-pause f24 maroon"></i><br /> Enroute Stoppage</span>
                                                <h4 className="f40 maroon">
                                                <span className="counter">
                                                    <CountUp end={this.state.enroute}/>
                                                </span>
                                            </h4>
                                        </div> 
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                    
					

                    <div className="col-xl-12 col-lg-12">
		            	<div className="card">
                        <div className="card-header">		            	
                            <h5>
                                <i className="icofont icofont-ticket cus-i"></i> <span>Tickets</span>
                                
                                <span className="layoutbtns float-right">
                                    <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                        <i className="icofont icofont-save"></i> Save Grid Layout
                                    </button>
                                    <button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
                                        <i className="icofont icofont-refresh"></i> Reset Default Layout
                                    </button>
                                </span>


                            </h5>
                        
                        </div>   			
		            		<div className="row card-body pt-15px" >
								
								<div id="myGrid" style={{ height: "550px",width: "100%"}}  className="col-xl-12 col-lg-12 ag-theme-balham">
		          		            <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={columnwithDefs}
		          		            defaultColDef={this.state.defaultColDef}
		          		            rowData={this.state.rowData}
		          		            enableCharts={true}
		          		            enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
									sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									//editType={this.state.editType}
									stopEditingWhenGridLosesFocus={true}
									//floatingFilter={true}
									enableCellChangeFlash={true}
									suppressCellFlash={true}
									rowClassRules={this.state.rowClassRules}
									//onCellClicked={this.onRowClicked.bind(this)}
									gridOptions={{
										context: { componentParent: this }
									}}
                                    masterDetail={true}
                                    rowSelection={this.state.rowSelection}
                                    //onRowSelected={this.onRowSelected.bind(this)}
                                    onCellEditingStopped={this.ticketCellUpdateContent.bind(this)}
                                    suppressRowClickSelection={true}
		          		          />


		          		        </div>
				   			</div>
				   		</div>	
				   	</div>

				 </div>
					

					{console.log("this.state.routeTruck ", this.state.routeTruck)}
                 { (this.state.routeTruck != '' && typeof this.state.routeTruck != 'undefined')?
                    <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								{(this.state.consignment_code)}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                                <DrawMap context={this} rownode={this.state.rownode} consignercords={this.state.consignercords} consigneecords={this.state.consigneecords} mapFor={"ticket"} googleroutes={this.state.googelRoutes} truckno={this.state.maptruckno} dealer={this.state.dealer} mapinfo={this.state.mapinfo} defTransitCoords={this.state.defTransitCoords} />
								
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '')?this.state.routeTruck.startTime:"NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeTruck.endTime != '')?this.state.routeTruck.endTime:"NA"}</div>
											</div>
											
											<div className="col route-block">
												<label className="sidebar-label">GPS Distance</label>
												<div>{(this.state.mapinfo.route_details != '')?Math.round(this.state.mapinfo.route_details.distance/1000):"0"} Kms</div>
											</div>
											{(this.state.leg_no == 0 || this.state.leg_no == null)?	""
											:
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{(this.state.rownode.actual_google_leg_distance)?parseInt(this.state.rownode.actual_google_leg_distance):"0"} Kms</div>
											</div>
											}
											
										
										</div>
									</div>
								</div>
                                
							</div>
						</div>
						
					</div>
				:""}
					
                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
			
			</div>
              	
		);
	}
}
function secondsToString(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function printResult(res) {
	console.log(res)
	if (res.remove) {
	  res.remove.forEach(function(rowNode) {
		console.log('Removed Row Node', rowNode);
	  });
	}
  }
// funciton checkforhtmltag
  function CheckForHtmlTag(){
	var rs = document.getElementById("invalid_reason");
	var reg =/<(.|\n)*?>/g; 
	if (reg.test(rs.value)) {
		var ErrorText = "Oops! HTML or Script is not allowed.";		
		rs.value=''
		//alert();
		return 1;
	}
}

function fileValidation(){
    var fileInput = document.getElementById('file');
    var filePath = fileInput.value;
    var allowedExtensions = /(\.csv)$/i;
    if(!allowedExtensions.exec(filePath)){
       
        fileInput.value = '';
        return 0;
    }
}

function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}
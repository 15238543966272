/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules, AllEnterpriseModules } from '@ag-grid-enterprise/all-modules';
// import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import CSVFileValidator from 'csv-file-validator';
import Map from '../common/drawmap';
import FileBase64 from 'react-file-base64';
import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import MapComponent from './mapLocationComponent';
import Consignmentforceclose from '../manage/consignmentforceclose';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import fastForward from 'material-ui/svg-icons/av/fast-forward';
import m from 'highcharts-drilldown';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var childJson = []

export default class TransporterGPSReviewFrom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            csvcontent: "",
            file: "",
            loadshow: 'show-n',
            overly: 'show-n',
            uploadYard: "",
            uploadDispatchDate: "",
            rowData: "",
            rowSelection: 'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize: 50,
            geofencelist: [],
            geoFenceData: "",
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            gps_data_uptime_rating_show:"show-n",
            distance_calculation_rating_show:"show-n",
            voice_box_rating_show:"show-n",
            casing_strength_rating_show:"show-n",
            harnessing_rating_show:"show-n",
            dashboard_uptime_rating_show:"show-n",
            data_accuracy_rating_show:"show-n",
            violations_reports_rating_show:"show-n",
            query_handling_rating_show:"show-n",
            upgradation_rating_show:"show-n",
            mobile_application_rating_show:"show-n",
            query_handling_rating_2_show:"show-n",
            field_executive_rating_show:"show-n",
            service_locations_rating_show:"show-n",
            resolution_time_rating_show:"show-n",
            gps_data_uptime_rating:"",
            month:""
            //components : {datepicker:getDatePicker()}
        }
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                var eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                var eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                var eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    async componentDidMount() {
        this.logPageView(); // Log page view to GA
        loadDateTimeScript();
        var month = window.atob(this.props.match.params.month)
        this.setState({
            month:month
        })
        var pagetitle = "Transporter GPS Rating Form"
        this.setState({
            pagetitle: pagetitle,
        })
        
    }
    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
        });

    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    newFilter = async(filterType, selOption) => {
		console.log(filterType, selOption)
        if(filterType == "gps_data_uptime_rating"){
            await this.setState({
                gps_data_uptime_rating_show:"show-m"
            })
        }
        if(filterType == "distance_calculation_rating"){
            await this.setState({
                distance_calculation_rating_show:"show-m"
            })
        }
        if(filterType == "voice_box_rating"){
            await this.setState({
                voice_box_rating_show:"show-m"
            })
        }
        if(filterType == "casing_strength_rating"){
            await this.setState({
                casing_strength_rating_show:"show-m"
            })
        }
        if(filterType == "harnessing_rating"){
            await this.setState({
                harnessing_rating_show:"show-m"
            })
        }
        if(filterType == "dashboard_uptime_rating"){
            await this.setState({
                dashboard_uptime_rating_show:"show-m"
            })
        }
        if(filterType == "data_accuracy_rating"){
            await this.setState({
                data_accuracy_rating_show:"show-m"
            })
        }
        if(filterType == "violations_reports_rating"){
            await this.setState({
                violations_reports_rating_show:"show-m"
            })
        }
        if(filterType == "query_handling_rating"){
            await this.setState({
                query_handling_rating_show:"show-m"
            })
        }
        if(filterType == "upgradation_rating"){
            await this.setState({
                upgradation_rating_show:"show-m" 
            })
        }
        if(filterType == "mobile_application_rating"){
            await this.setState({
                mobile_application_rating_show:"show-m" 
            })
        }
        if(filterType == "query_handling_rating_2"){
            await this.setState({
                query_handling_rating_2_show:"show-m"
            })
        }
        if(filterType == "field_executive_rating"){
            await this.setState({
                field_executive_rating_show:"show-m"
            })
        }
        if(filterType == "service_locations_rating"){
            await this.setState({
                service_locations_rating_show:"show-m"
            })
        }
        if(filterType == "resolution_time_rating"){
            await this.setState({
                resolution_time_rating_show:"show-m"
            })
        }
		await this.setState({ [filterType]: selOption });
	}
    changeHandler = (event) => {
        console.log("Select", event.target.value);

        var name = event.target.name;
        var value = event.target.value;
        const re = /^[A-Za-z]+$/;
        // if (name == 'deptName') {
        //     if (value === "" || re.test(value)) {
        //         console.log("name succ")
        //         alert('hi')
        //         //   this.setState(value);
        //     }else{
        //         alert('dont enter the numerals')
        //     }
        // }

        console.log(name);
    }
    
    async formHandler(event) {
        event.preventDefault();
        this.setState({
            loadshow : "show-m",
            overly : "show-m"
        })
        var tpt_code =""
        if(localStorage.getItem("transportercode") != undefined && localStorage.getItem("transportercode") != "" && localStorage.getItem("transportercode") != "undefined" && localStorage.getItem("transportercode") != null){
            tpt_code = JSON.parse(localStorage.getItem("transportercode"))
        }
        
        if(![undefined,"undefined",null,"null",""].includes(this.state.gps_data_uptime_rating) && ![undefined,"undefined",null,"null",""].includes(this.state.distance_calculation_rating) && ![undefined,"undefined",null,"null",""].includes(this.state.voice_box_rating) && ![undefined,"undefined",null,"null",""].includes(this.state.casing_strength_rating) && ![undefined,"undefined",null,"null",""].includes(this.state.harnessing_rating) && ![undefined,"undefined",null,"null",""].includes(this.state.dashboard_uptime_rating) && ![undefined,"undefined",null,"null",""].includes(this.state.data_accuracy_rating) && ![undefined,"undefined",null,"null",""].includes(this.state.violations_reports_rating) && ![undefined,"undefined",null,"null",""].includes(this.state.query_handling_rating) && ![undefined,"undefined",null,"null",""].includes(this.state.upgradation_rating) && ![undefined,"undefined",null,"null",""].includes(this.state.mobile_application_rating) && ![undefined,"undefined",null,"null",""].includes(this.state.query_handling_rating_2) && ![undefined,"undefined",null,"null",""].includes(this.state.field_executive_rating) && ![undefined,"undefined",null,"null",""].includes(this.state.service_locations_rating) && ![undefined,"undefined",null,"null",""].includes(this.state.resolution_time_rating)){
       var gps_data_uptime_rating = this.state.gps_data_uptime_rating.label
       var gps_data_uptime_rating_numbers = this.state.gps_data_uptime_rating.value
       var distance_calculation_rating = this.state.distance_calculation_rating.label
       var distance_calculation_rating_numbers = this.state.distance_calculation_rating.value
       var voice_box_rating = this.state.voice_box_rating.label
       var voice_box_rating_numbers = this.state.voice_box_rating.value
       var casing_strength_rating = this.state.casing_strength_rating.label
       var casing_strength_rating_numbers = this.state.casing_strength_rating.value
       var harnessing_rating = this.state.harnessing_rating.label
       var harnessing_rating_numbers = this.state.harnessing_rating.value
       var dashboard_uptime_rating = this.state.dashboard_uptime_rating.label
       var dashboard_uptime_rating_numbers = this.state.dashboard_uptime_rating.value
       var data_accuracy_rating = this.state.data_accuracy_rating.label
       var data_accuracy_rating_numbers = this.state.data_accuracy_rating.value
       var violations_reports_rating = this.state.violations_reports_rating.label
       var violations_reports_rating_numbers = this.state.violations_reports_rating.value
       var query_handling_rating = this.state.query_handling_rating.label
       var query_handling_rating_numbers = this.state.query_handling_rating.value
       var upgradation_rating = this.state.upgradation_rating.label
       var upgradation_rating_numbers = this.state.upgradation_rating.value
       var mobile_application_rating = this.state.mobile_application_rating.label
       var mobile_application_rating_numbers = this.state.mobile_application_rating.value
       var query_handling_rating_2 = this.state.query_handling_rating_2.label
       var query_handling_rating_2_numbers = this.state.query_handling_rating_2.value
       var field_executive_rating = this.state.field_executive_rating.label
       var field_executive_rating_numbers = this.state.field_executive_rating.value
       var service_locations_rating = this.state.service_locations_rating.label
       var service_locations_rating_numbers = this.state.service_locations_rating.value
       var resolution_time_rating = this.state.resolution_time_rating.label
       var resolution_time_rating_numbers = this.state.resolution_time_rating.value
       if(![undefined,"undefined",null,""].includes($("#gps_data_uptime_remarks").val())){
        var gps_data_uptime_remarks = $("#gps_data_uptime_remarks").val()
       }else{
        var gps_data_uptime_remarks =""
       }
       if(![undefined,"undefined",null,""].includes($("#distance_calculation_remarks").val())){
        var distance_calculation_remarks =$("#distance_calculation_remarks").val()    
       }else{
        var distance_calculation_remarks =""    
       }
       if(![undefined,"undefined",null,""].includes($("#voice_box_remarks").val())){
        var voice_box_remarks =$("#voice_box_remarks").val()    
       }else{
        var voice_box_remarks =""    
       } 
       if(![undefined,"undefined",null,""].includes($("#casing_strength_remarks").val())){
        var casing_strength_remarks =$("#casing_strength_remarks").val()    
       }else{
        var casing_strength_remarks =""    
       }
       if(![undefined,"undefined",null,""].includes($("#harnessing_remarks").val())){
        var harnessing_remarks =$("#harnessing_remarks").val()    
       }else{
        var harnessing_remarks =""    
       }
       if(![undefined,"undefined",null,""].includes($("#dashboard_uptime_remarks").val())){
        var dashboard_uptime_remarks =$("#dashboard_uptime_remarks").val()    
       }else{
        var dashboard_uptime_remarks =""    
       }
       if(![undefined,"undefined",null,""].includes($("#data_accuracy_remarks").val())){
        var data_accuracy_remarks =$("#data_accuracy_remarks").val()    
       }else{
        var data_accuracy_remarks =""    
       }
       if(![undefined,"undefined",null,""].includes($("#violations_reports_remarks").val())){
        var violations_reports_remarks =$("#violations_reports_remarks").val()    
       }else{
        var violations_reports_remarks =""    
       }
       if(![undefined,"undefined",null,""].includes($("#query_handling_remarks").val())){
        var query_handling_remarks =$("#query_handling_remarks").val()    
       }else{
        var query_handling_remarks =""    
       }
       if(![undefined,"undefined",null,""].includes($("#upgradation_remarks").val())){
        var upgradation_remarks =$("#upgradation_remarks").val()    
       }else{
        var upgradation_remarks =""    
       }
       if(![undefined,"undefined",null,""].includes($("#mobile_application_remarks").val())){
        var mobile_application_remarks =$("#mobile_application_remarks").val()    
       }else{
        var mobile_application_remarks =""    
       }
       if(![undefined,"undefined",null,""].includes($("#query_handling_remarks_2").val())){
        var query_handling_remarks_2 =$("#query_handling_remarks_2").val()    
       }else{
        var query_handling_remarks_2 =""    
       }
       if(![undefined,"undefined",null,""].includes($("#field_executive_remarks").val())){
        var field_executive_remarks =$("#field_executive_remarks").val()    
       }else{
        var field_executive_remarks =""    
       }
       if(![undefined,"undefined",null,""].includes($("#service_locations_remarks").val())){
        var service_locations_remarks =$("#service_locations_remarks").val()    
       }else{
        var service_locations_remarks =""    
       }
       if(![undefined,"undefined",null,""].includes($("#resolution_time_remarks").val())){
        var resolution_time_remarks =$("#resolution_time_remarks").val()    
       }else{
        var resolution_time_remarks =""    
       }
       if(![undefined,"undefined",null,""].includes($("#specific_requirements_remarks").val())){
        var specific_requirements_remarks =$("#specific_requirements_remarks").val()    
       }else{
        var specific_requirements_remarks =""    
       }
            var data = [
            {
                "parameters" : "HARDWARE",
                "check_parameters" : "HARDWARE",
                "category" : "GPS Date Uptime",
                "description" : "Regular updation of real time location",
                "ratings" : gps_data_uptime_rating,
                "ratings_numbers" : parseInt(gps_data_uptime_rating_numbers),
                "remarks" : gps_data_uptime_remarks,
                "transporter_code" : tpt_code[0],
                "transporter_name" : localStorage.getItem("username"),
                "review_month":this.state.month,
                // "review_date":moment.parseZone(new Date()).utcOffset("+05:30")._d
            },
            {
                "parameters" : "HARDWARE",
                "check_parameters" : "",
                "category" : "Distance Calculation",
                "description" : "Accuracy in Distance Calculation",
                "ratings" : distance_calculation_rating,
                "ratings_numbers" : parseInt(distance_calculation_rating_numbers),
                "remarks" : distance_calculation_remarks,
                "transporter_code" : tpt_code[0],
                "transporter_name" : localStorage.getItem("username"),
                "review_month":this.state.month,
                // "review_date":moment.parseZone(new Date()).utcOffset("+05:30")._d
            },
            {
                "parameters" : "HARDWARE",
                "check_parameters" : "",
                "category" : "Voice Box",
                "description" : "Clear and regular voice from device",
                "ratings" : voice_box_rating,
                "ratings_numbers" : parseInt(voice_box_rating_numbers),
                "remarks" : voice_box_remarks,
                "transporter_code" : tpt_code[0],
                "transporter_name" : localStorage.getItem("username"),
                "review_month":this.state.month,
                // "review_date":moment.parseZone(new Date()).utcOffset("+05:30")._d
            },
            {
                "parameters" : "HARDWARE",
                "check_parameters" : "",
                "category" : "Casing Strength",
                "description" : "Strength of device without breakage",
                "ratings" : casing_strength_rating,
                "ratings_numbers" : parseInt(casing_strength_rating_numbers),
                "remarks" : casing_strength_remarks,
                "transporter_code" : tpt_code[0],
                "transporter_name" : localStorage.getItem("username"),
                "review_month":this.state.month,
                // "review_date":moment.parseZone(new Date()).utcOffset("+05:30")._d
            },
            {
                "parameters" : "HARDWARE",
                "check_parameters" : "",
                "category" : "Harnessing",
                "description" : "Connections done as per safety standards",
                "ratings" : harnessing_rating,
                "ratings_numbers" : parseInt(harnessing_rating_numbers),
                "remarks" : harnessing_remarks,
                "transporter_code" : tpt_code[0],
                "transporter_name" : localStorage.getItem("username"),
                "review_month":this.state.month,
                // "review_date":moment.parseZone(new Date()).utcOffset("+05:30")._d
            },
            {
                "parameters" : "SOFTWARE",
                "check_parameters" : "SOFTWARE",
                "category" : "Dashboard Uptime",
                "description" : "Ease in operating dashboard for reports and Carrier location updates",
                "ratings" : dashboard_uptime_rating,
                "ratings_numbers" : parseInt(dashboard_uptime_rating_numbers),
                "remarks" : dashboard_uptime_remarks,
                "transporter_code" : tpt_code[0],
                "transporter_name" : localStorage.getItem("username"),
                "review_month":this.state.month,
                // "review_date":moment.parseZone(new Date()).utcOffset("+05:30")._d
            },
            {
                "parameters" : "SOFTWARE",
                "check_parameters" : "",
                "category" : "Data Accuracy",
                "description" : "Correctness of data available in software",
                "ratings" : data_accuracy_rating,
                "ratings_numbers" :parseInt(data_accuracy_rating_numbers),
                "remarks" : data_accuracy_remarks,
                "transporter_code" : tpt_code[0],
                "transporter_name" : localStorage.getItem("username"),
                "review_month":this.state.month,
                // "review_date":moment.parseZone(new Date()).utcOffset("+05:30")._d
            },
            {
                "parameters" : "SOFTWARE",
                "check_parameters" : "",
                "category" : "Violations Reports",
                "description" : "Availability of daily violations reports",
                "ratings" : violations_reports_rating,
                "ratings_numbers" :parseInt(violations_reports_rating_numbers),
                "remarks" : violations_reports_remarks,
                "transporter_code" : tpt_code[0],
                "transporter_name" : localStorage.getItem("username"),
                "review_month":this.state.month,
                // "review_date":moment.parseZone(new Date()).utcOffset("+05:30")._d
            },
            {
                "parameters" : "SOFTWARE",
                "check_parameters" : "",
                "category" : "Query Handling",
                "description" : "Contiuous Improvement in Software Services",
                "ratings" : query_handling_rating,
                "ratings_numbers" :parseInt(query_handling_rating_numbers),
                "remarks" : query_handling_remarks,
                "transporter_code" : tpt_code[0],
                "transporter_name" : localStorage.getItem("username"),
                "review_month":this.state.month,
                // "review_date":moment.parseZone(new Date()).utcOffset("+05:30")._d
            },
            {
                "parameters" : "SOFTWARE",
                "check_parameters" : "",
                "category" : "Mobile application",
                "description" : "Ease of use and effectiveness",
                "ratings" : mobile_application_rating,
                "ratings_numbers" :parseInt(mobile_application_rating_numbers),
                "remarks" : mobile_application_remarks,
                "transporter_code" : tpt_code[0],
                "transporter_name" : localStorage.getItem("username"),
                "review_month":this.state.month,
                // "review_date":moment.parseZone(new Date()).utcOffset("+05:30")._d
            },
            {
                "parameters" : "SOFTWARE",
                "check_parameters" : "",
                "category" : "Upgradation",
                "description" : "Continuous improvement in software services",
                "ratings" : upgradation_rating,
                "ratings_numbers" :parseInt(upgradation_rating_numbers),
                "remarks" : upgradation_remarks,
                "transporter_code" : tpt_code[0],
                "transporter_name" : localStorage.getItem("username"),
                "review_month":this.state.month,
                // "review_date":moment.parseZone(new Date()).utcOffset("+05:30")._d
            },
            {
                "parameters" : "FIELD SERVICE",
                "check_parameters" : "FIELD SERVICE",
                "category" : "Query Handling",
                "description" : "Response quality on solving query related to hardware and software",
                "ratings" : query_handling_rating_2,
                "ratings_numbers" :parseInt(query_handling_rating_2_numbers),
                "remarks" : query_handling_remarks_2,
                "transporter_code" : tpt_code[0],
                "transporter_name" : localStorage.getItem("username"),
                "review_month":this.state.month,
                // "review_date":moment.parseZone(new Date()).utcOffset("+05:30")._d
            },
            {
                "parameters" : "FIELD SERVICE",
                "check_parameters" : "",
                "category" : "Field Executives",
                "description" : "Expertise and Quality of work",
                "ratings" : field_executive_rating,
                "ratings_numbers" :parseInt(field_executive_rating_numbers),
                "remarks" : field_executive_remarks,
                "transporter_code" : tpt_code[0],
                "transporter_name" : localStorage.getItem("username"),
                "review_month":this.state.month,
                // "review_date":moment.parseZone(new Date()).utcOffset("+05:30")._d
            },
            {
                "parameters" : "FIELD SERVICE",
                "check_parameters" : "",
                "category" : "Service Locations",
                "description" : "Ample number of service location across India",
                "ratings" : service_locations_rating,
                "ratings_numbers" :parseInt(service_locations_rating_numbers),
                "remarks" : service_locations_remarks,
                "transporter_code" : tpt_code[0],
                "transporter_name" : localStorage.getItem("username"),
                "review_month":this.state.month,
                // "review_date":moment.parseZone(new Date()).utcOffset("+05:30")._d
            },
            {
                "parameters" : "FIELD SERVICE",
                "check_parameters" : "",
                "category" : "Resolution Time",
                "description" : "Time taken for resolution",
                "ratings" : resolution_time_rating,
                "ratings_numbers" :parseInt(resolution_time_rating_numbers),
                "remarks" : resolution_time_remarks,
                "transporter_code" : tpt_code[0],
                "transporter_name" : localStorage.getItem("username"),
                "review_month":this.state.month,
                // "review_date":moment.parseZone(new Date()).utcOffset("+05:30")._d
            },
            {
                "parameters" : "ADDITIONAL",
                "check_parameters" : "ADDITIONAL",
                "category" : "Specific Requirement",
                "description" : "Improvements suggested or new tools required",
                "remarks" : specific_requirements_remarks,
                "transporter_code" : tpt_code[0],
                "transporter_name" : localStorage.getItem("username"),
                "review_month":this.state.month,
                // "review_date":moment.parseZone(new Date()).utcOffset("+05:30")._d
            }
            ]
            // console.log(data,"701")
           redirectURL.post("/dashboard/addTransporterGpsReviewData",data).then((response)=>{
                if(response.data.message == "Success"){
                    this.setState({
                        basicTitle:"Data Updated Successfully",
                        basicType:"success",
                        show:true,
                        loadshow:"show-n",
                        overly:"show-n"
                    })
                    window.location.href="/transportergpsreviewscreen"
                }else{
                    this.setState({
                        basicTitle:"Data Not Updated",
                        basicType:"warning",
                        show:true,
                        loadshow:"show-n",
                        overly:"show-n"
                    })
                }
           })
       }else{
        this.setState({
            basicTitle:"Please Enter Mandatory Fields",
            basicType:"danger",
            show:true,
            loadshow:"show-n",
            overly:"show-n"
        })
       }
      
    }

    
    render() {
        
        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    <div className={"col-xl-12 col-lg-12 " + this.state.requestData}>
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle}</span>
                                    <span>
                                        <span className="float-right f12">
                                            <a href="/transportergpsreviewscreen" className="btn btn-primary">Back</a>
                                        </span>
                                    </span>
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                                <form class="aircargo-form" onSubmit={this.formHandler.bind(this)} encType="multipart/form-data">
                                    <div className='col-xl-12 col-lg-12' style={{margin:"10px"}}>
                                        <h4>HARDWARE</h4>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-6">
                                            <label className="col-xl-12 col-lg-12">GPS Data Uptime - Regular updation of real time location<span style={{color:"#FF0000"}}>*</span></label>
                                            <Select
                                                placeholder={"Select Rating"}
                                                closeMenuOnSelect={true}
                                                value={this.state.gps_data_uptime_rating}

                                                onChange={this.newFilter.bind(this,"gps_data_uptime_rating")}
                                                style={{ borderRadius: "0px" }}
                                                options={[{value:5,label:"Excellent"},{value:4,label:"Very Good"},{value:3,label:"Good"},{value:2,label:"Poor"},{value:1,label:"Very Poor"}]} required
                                            />
                                        </div>
                                        {/* {(this.state.gps_data_uptime_rating.length > 0) ? */}
                                            <div className={"col-xl-4 "+ (this.state.gps_data_uptime_rating_show)}>
                                                <label className="col-xl-12 col-lg-12">Remarks</label>
                                                <input type="text" name="gps_data_uptime_remarks" id="gps_data_uptime_remarks" className="form-control alfa-cls" autocomplete="off" onChange={this.changeHandler}/>
                                            </div>
                                        {/* } */}

                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-6">
                                            <label className="col-xl-12 col-lg-12">Distance Calculation - Accuracy in Distance Calculation<span style={{color:"#FF0000"}}>*</span></label>
                                            <Select
                                                placeholder={"Select Rating"}
                                                closeMenuOnSelect={true}
                                                value={this.state.distance_calculation_rating}

                                                onChange={this.newFilter.bind(this,"distance_calculation_rating")}
                                                style={{ borderRadius: "0px" }}
                                                options={[{value:5,label:"Excellent"},{value:4,label:"Very Good"},{value:3,label:"Good"},{value:2,label:"Poor"},{value:1,label:"Very Poor"}]} required
                                            />
                                        </div>
                                        {/* {(this.state.destination_port.value == "Other") ? */}
                                            <div className={"col-xl-4 "+(this.state.distance_calculation_rating_show)}>
                                                <label className="col-xl-12 col-lg-12">Remarks</label>
                                                <input type="text" name="distance_calculation_remarks" id="distance_calculation_remarks" className="form-control alfa-cls" autocomplete="off" onChange={this.changeHandler}/>
                                            </div> 
                                        {/* } */}

                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-6">
                                            <label className="col-xl-12 col-lg-12">Voice Box - Clear and regular voice from device<span style={{color:"#FF0000"}}>*</span></label>
                                            <Select
                                                placeholder={"Select Rating"}
                                                closeMenuOnSelect={true}
                                                value={this.state.voice_box_rating}

                                                onChange={this.newFilter.bind(this,"voice_box_rating")}
                                                style={{ borderRadius: "0px" }}
                                                options={[{value:5,label:"Excellent"},{value:4,label:"Very Good"},{value:3,label:"Good"},{value:2,label:"Poor"},{value:1,label:"Very Poor"}]} required
                                            />
                                        </div>
                                        {/* {(this.state.destination_port.value == "Other") ? */}
                                            <div className={"col-xl-4 "+(this.state.voice_box_rating_show)}>
                                                <label className="col-xl-12 col-lg-12">Remarks</label>
                                                <input type="text" name="voice_box_remarks" id="voice_box_remarks" className="form-control alfa-cls" autocomplete="off" onChange={this.changeHandler}/>
                                            </div>
                                        {/* } */}

                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-6">
                                            <label className="col-xl-12 col-lg-12">Casing Stength - Strength of device without breakage<span style={{color:"#FF0000"}}>*</span></label>
                                            <Select
                                                placeholder={"Select Rating"}
                                                closeMenuOnSelect={true}
                                                value={this.state.casing_strength_rating}
                                                onChange={this.newFilter.bind(this,"casing_strength_rating")}
                                                style={{ borderRadius: "0px" }}
                                                options={[{value:5,label:"Excellent"},{value:4,label:"Very Good"},{value:3,label:"Good"},{value:2,label:"Poor"},{value:1,label:"Very Poor"}]} required
                                            />
                                        </div>
                                        {/* {(this.state.destination_port.value == "Other") ? */}
                                            <div className={"col-xl-4 "+(this.state.casing_strength_rating_show)}>
                                                <label className="col-xl-12 col-lg-12">Remarks</label>
                                                <input type="text" name="casing_strength_remarks" id="casing_strength_remarks" className="form-control alfa-cls" autocomplete="off" onChange={this.changeHandler}/>
                                            </div>
                                        {/* } */}

                                    </div>
                                    <div className="row col-xl-12"> 
                                        <div className="col-xl-6">
                                            <label className="col-xl-12 col-lg-12">Harnessing - Connections done as per safety standards<span style={{color:"#FF0000"}}>*</span></label>
                                            <Select
                                                placeholder={"Select Rating"}
                                                closeMenuOnSelect={true}
                                                value={this.state.harnessing_rating}

                                                onChange={this.newFilter.bind(this,"harnessing_rating")}
                                                style={{ borderRadius: "0px" }}
                                                options={[{value:5,label:"Excellent"},{value:4,label:"Very Good"},{value:3,label:"Good"},{value:2,label:"Poor"},{value:1,label:"Very Poor"}]} required
                                            />
                                        </div>
                                        {/* {(this.state.destination_port.value == "Other") ? */}
                                            <div className={"col-xl-4 "+(this.state.harnessing_rating_show)}>
                                                <label className="col-xl-12 col-lg-12">Remarks</label>
                                                <input type="text" name="harnessing_remarks" id="harnessing_remarks" className="form-control alfa-cls" autocomplete="off" onChange={this.changeHandler}/>
                                            </div>
                                        {/* } */}

                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className='col-xl-12 col-lg-12' style={{margin:"10px"}}>
                                        <h4>SOFTWARE</h4>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-6">
                                            <label className="col-xl-12 col-lg-12">Dashboard Uptime - Ease in operating dashboard for reports and Carrier location updates<span style={{color:"#FF0000"}}>*</span></label>
                                            <Select
                                                placeholder={"Select Rating"}
                                                closeMenuOnSelect={true}
                                                value={this.state.dashboard_uptime_rating}

                                                onChange={this.newFilter.bind(this,"dashboard_uptime_rating")}
                                                style={{ borderRadius: "0px" }}
                                                options={[{value:5,label:"Excellent"},{value:4,label:"Very Good"},{value:3,label:"Good"},{value:2,label:"Poor"},{value:1,label:"Very Poor"}]} required
                                            />
                                        </div>
                                        {/* {(this.state.destination_port.value == "Other") ? */}
                                            <div className={"col-xl-4 "+(this.state.dashboard_uptime_rating_show)}>
                                                <label className="col-xl-12 col-lg-12">Remarks</label>
                                                <input type="text" name="dashboard_uptime_remarks" id="dashboard_uptime_remarks" className="form-control alfa-cls" autocomplete="off" onChange={this.changeHandler}/>
                                            </div> 
                                        {/* } */}

                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-6">
                                            <label className="col-xl-12 col-lg-12">Data Accuracy - Correctness of data available in software<span style={{color:"#FF0000"}}>*</span></label>
                                            <Select
                                                placeholder={"Select Rating"}
                                                closeMenuOnSelect={true}
                                                value={this.state.data_accuracy_rating}

                                                onChange={this.newFilter.bind(this,"data_accuracy_rating")}
                                                style={{ borderRadius: "0px" }}
                                                options={[{value:5,label:"Excellent"},{value:4,label:"Very Good"},{value:3,label:"Good"},{value:2,label:"Poor"},{value:1,label:"Very Poor"}]} required
                                            />
                                        </div>
                                        {/* {(this.state.destination_port.value == "Other") ? */}
                                            <div className={"col-xl-4 "+(this.state.data_accuracy_rating_show)}>
                                                <label className="col-xl-12 col-lg-12">Remarks</label>
                                                <input type="text" name="data_accuracy_remarks" id="data_accuracy_remarks" className="form-control alfa-cls" autocomplete="off" onChange={this.changeHandler}/>
                                            </div>
                                        {/* } */}

                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-6">
                                            <label className="col-xl-12 col-lg-12">Violations Reports - Availability of daily violations reports<span style={{color:"#FF0000"}}>*</span></label>
                                            <Select
                                                placeholder={"Select Rating"}
                                                closeMenuOnSelect={true}
                                                value={this.state.violations_reports_rating}

                                                onChange={this.newFilter.bind(this,"violations_reports_rating")}
                                                style={{ borderRadius: "0px" }}
                                                options={[{value:5,label:"Excellent"},{value:4,label:"Very Good"},{value:3,label:"Good"},{value:2,label:"Poor"},{value:1,label:"Very Poor"}]} required
                                            />
                                        </div>
                                        {/* {(this.state.destination_port.value == "Other") ? */}
                                            <div className={"col-xl-4 "+(this.state.violations_reports_rating_show)}>
                                                <label className="col-xl-12 col-lg-12">Remarks</label>
                                                <input type="text" name="violations_reports_remarks" id="violations_reports_remarks" autocomplete="off" className="form-control alfa-cls" onChange={this.changeHandler}/>
                                            </div>
                                        {/* } */}

                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-6">
                                            <label className="col-xl-12 col-lg-12">Query Handling - Response quality on solving query related to software<span style={{color:"#FF0000"}}>*</span></label>
                                            <Select
                                                placeholder={"Select Rating"}
                                                closeMenuOnSelect={true}
                                                value={this.state.query_handling_rating}

                                                onChange={this.newFilter.bind(this,"query_handling_rating")}
                                                style={{ borderRadius: "0px" }}
                                                options={[{value:5,label:"Excellent"},{value:4,label:"Very Good"},{value:3,label:"Good"},{value:2,label:"Poor"},{value:1,label:"Very Poor"}]} required
                                            />
                                        </div>
                                        {/* {(this.state.destination_port.value == "Other") ? */}
                                            <div className={"col-xl-4 "+(this.state.query_handling_rating_show)}>
                                                <label className="col-xl-12 col-lg-12">Remarks</label>
                                                <input type="text" name="query_handling_remarks" id="query_handling_remarks" className="form-control alfa-cls" autocomplete="off" onChange={this.changeHandler}/>
                                            </div>
                                        {/* } */}

                                    </div>
                                    <div className="row col-xl-12"> 
                                        <div className="col-xl-6">
                                            <label className="col-xl-12 col-lg-12">Upgradation - Contiuous Improvement in Software Services<span style={{color:"#FF0000"}}>*</span></label>
                                            <Select
                                                placeholder={"Select Rating"}
                                                closeMenuOnSelect={true}
                                                value={this.state.upgradation_rating}

                                                onChange={this.newFilter.bind(this,"upgradation_rating")}
                                                style={{ borderRadius: "0px" }}
                                                options={[{value:5,label:"Excellent"},{value:4,label:"Very Good"},{value:3,label:"Good"},{value:2,label:"Poor"},{value:1,label:"Very Poor"}]} required
                                            />
                                        </div>
                                        {/* {(this.state.destination_port.value == "Other") ? */}
                                            <div className={"col-xl-4 "+(this.state.upgradation_rating_show)}>
                                                <label className="col-xl-12 col-lg-12">Remarks</label>
                                                <input type="text" name="upgradation_remarks" id="upgradation_remarks" className="form-control alfa-cls" autocomplete="off" onChange={this.changeHandler}/>
                                            </div>
                                        {/* } */}

                                    </div>
                                    <div className="row col-xl-12"> 
                                        <div className="col-xl-6">
                                            <label className="col-xl-12 col-lg-12">Mobile Application - Ease of use and effectiveness<span style={{color:"#FF0000"}}>*</span></label>
                                            <Select
                                                placeholder={"Select Rating"}
                                                closeMenuOnSelect={true}
                                                value={this.state.mobile_application_rating}

                                                onChange={this.newFilter.bind(this,"mobile_application_rating")}
                                                style={{ borderRadius: "0px" }}
                                                options={[{value:5,label:"Excellent"},{value:4,label:"Very Good"},{value:3,label:"Good"},{value:2,label:"Poor"},{value:1,label:"Very Poor"}]} required
                                            />
                                        </div>
                                        {/* {(this.state.destination_port.value == "Other") ? */}
                                            <div className={"col-xl-4 "+(this.state.mobile_application_rating_show)}>
                                                <label className="col-xl-12 col-lg-12">Remarks</label>
                                                <input type="text" name="mobile_application_remarks" id="mobile_application_remarks" autocomplete="off" className="form-control alfa-cls" onChange={this.changeHandler}/>
                                            </div>
                                        {/* } */}

                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className='col-xl-12 col-lg-12' style={{margin:"10px"}}>
                                        <h4>FIELD SERVICE</h4>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-6">
                                            <label className="col-xl-12 col-lg-12">Query Handling - Response quality on solving query related to hardware and software<span style={{color:"#FF0000"}}>*</span></label>
                                            <Select
                                                placeholder={"Select Rating"}
                                                closeMenuOnSelect={true}
                                                value={this.state.query_handling_rating_2}

                                                onChange={this.newFilter.bind(this,"query_handling_rating_2")}
                                                style={{ borderRadius: "0px" }}
                                                options={[{value:5,label:"Excellent"},{value:4,label:"Very Good"},{value:3,label:"Good"},{value:2,label:"Poor"},{value:1,label:"Very Poor"}]} required
                                            />
                                        </div>
                                        {/* {(this.state.destination_port.value == "Other") ? */}
                                            <div className={"col-xl-4 "+(this.state.query_handling_rating_2_show)}>
                                                <label className="col-xl-12 col-lg-12">Remarks</label>
                                                <input type="text" name="query_handling_remarks_2" id="query_handling_remarks_2" className="form-control alfa-cls" autocomplete="off" onChange={this.changeHandler}/>
                                            </div>
                                        {/* } */}

                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-6">
                                            <label className="col-xl-12 col-lg-12">Field Executives - Expertise and Quality of work<span style={{color:"#FF0000"}}>*</span></label>
                                            <Select
                                                placeholder={"Select Rating"}
                                                closeMenuOnSelect={true}
                                                value={this.state.field_executive_rating}

                                                onChange={this.newFilter.bind(this,"field_executive_rating")}
                                                style={{ borderRadius: "0px" }}
                                                options={[{value:5,label:"Excellent"},{value:4,label:"Very Good"},{value:3,label:"Good"},{value:2,label:"Poor"},{value:1,label:"Very Poor"}]} required
                                            />
                                        </div>
                                        {/* {(this.state.destination_port.value == "Other") ? */}
                                            <div className={"col-xl-4 "+(this.state.field_executive_rating_show)}>
                                                <label className="col-xl-12 col-lg-12">Remarks</label>
                                                <input type="text" name="field_executive_remarks" id="field_executive_remarks" className="form-control alfa-cls" autocomplete="off" onChange={this.changeHandler}/>
                                            </div>
                                        {/* } */}

                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-6">
                                            <label className="col-xl-12 col-lg-12">Service Locations - Ample number of service location across India<span style={{color:"#FF0000"}}>*</span></label>
                                            <Select
                                                placeholder={"Select Rating"}
                                                closeMenuOnSelect={true}
                                                value={this.state.service_locations_rating}

                                                onChange={this.newFilter.bind(this,"service_locations_rating")}
                                                style={{ borderRadius: "0px" }}
                                                options={[{value:5,label:"Excellent"},{value:4,label:"Very Good"},{value:3,label:"Good"},{value:2,label:"Poor"},{value:1,label:"Very Poor"}]} required
                                            />
                                        </div>
                                        {/* {(this.state.destination_port.value == "Other") ? */}
                                            <div className={"col-xl-4 "+(this.state.service_locations_rating_show)}>
                                                <label className="col-xl-12 col-lg-12">Remarks</label>
                                                <input type="text" name="service_locations_remarks" id="service_locations_remarks" className="form-control alfa-cls" autocomplete="off" onChange={this.changeHandler}/>
                                            </div>
                                        {/* } */}

                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-6">
                                            <label className="col-xl-12 col-lg-12">Resolution Time - Time taken for resolution<span style={{color:"#FF0000"}}>*</span></label>
                                            <Select
                                                placeholder={"Select Rating"}
                                                closeMenuOnSelect={true}
                                                value={this.state.resolution_time_rating}

                                                onChange={this.newFilter.bind(this,"resolution_time_rating")}
                                                style={{ borderRadius: "0px" }}
                                                options={[{value:5,label:"Excellent"},{value:4,label:"Very Good"},{value:3,label:"Good"},{value:2,label:"Poor"},{value:1,label:"Very Poor"}]} required
                                            />
                                        </div>
                                        {/* {(this.state.destination_port.value == "Other") ? */}
                                            <div className={"col-xl-4 "+(this.state.resolution_time_rating_show)}>
                                                <label className="col-xl-12 col-lg-12">Remarks</label>
                                                <input type="text" name="resolution_time_remarks" id="resolution_time_remarks" className="form-control alfa-cls" autocomplete="off" onChange={this.changeHandler}/>
                                            </div>
                                        {/* } */}

                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className='col-xl-12 col-lg-12' style={{margin:"10px"}}>
                                        <h4>ADDITIONAL</h4>
                                    </div>
                                    <div className="row col-xl-12">
                                       
                                        {/* {(this.state.destination_port.value == "Other") ? */}
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12">Specific Requirement</label>
                                                <input type="text" name="specific_requirements_remarks" id="specific_requirements_remarks" className="form-control alfa-cls" autocomplete="off" onChange={this.changeHandler}/>
                                            </div>
                                        {/* } */}

                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-success" style={{ marginLeft: "45%", width: "192px", marginTop: "20px" }}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        )
    }
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_date').datetimepicker({
        //mask:'39-19-9999',
        format: 'd-m-Y',
        minDate: true,
        timepicker: false,
        closeOnDateSelect: true,
        scrollMonth: false,
        scrollInput: false
    });

    $('.cargo_date').datetimepicker({
        //mask:'39-19-9999',
        format: 'd-m-Y',
        minDate: true,
        timepicker: false,
    });

    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    script.crossOrigin = "anonymous"
    index.parentNode.insertBefore(script, index)
}

// $('body').on('focus',".datetimepicker_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false
// 	});
// });

// $('body').on('focus',".cargo_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false,

// 	});
// });

$(document).on("input", ".num-cls", function() {
    this.value = this.value.replace(/\D/g,'');
})
$(document).on("input", ".mobnum-cls", function() {
    this.value = this.value.replace(/^\d{11}$/g,'');
})
$(document).on("input", ".alfa-cls", function () {
    this.value = this.value.replace(/[^a-z\s]+$/gi, '');
  });
  $(document).on("input", ".dept-cls", function () {
    this.value = this.value.replace(/[^a-z-\s]/gi, '');
  });
  $(document).on("input", ".ringi-cls", function () {
    this.value = this.value.toUpperCase();
    this.value = this.value.replace(/[^\d[A-Z]-\s]/gi, '');
  });
$(document).on("change", "#cargoDate", function (e) {
    e.preventDefault();
    var bookingDate = $("#dateField").val();
    var cargoDate = $("#cargoDate").val();
    var bookingDate = bookingDate.split("-")[2] + "-" + bookingDate.split("-")[1] + "-" + bookingDate.split("-")[0]
    var cargoDate = cargoDate.split("-")[2] + "-" + cargoDate.split("-")[1] + "-" + cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    console.log(startTime, endTime);
    if (startTime >= endTime) {
        $("#cargoDate").val("");
        alert("Date should be greater than Booking Date");
    }
})
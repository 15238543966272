/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules, AllEnterpriseModules } from '@ag-grid-enterprise/all-modules';
// import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import CSVFileValidator from 'csv-file-validator';
import Map from '../common/drawmap';
import FileBase64 from 'react-file-base64';
import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import MapComponent from './mapLocationComponent';
import Consignmentforceclose from '../manage/consignmentforceclose';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import fastForward from 'material-ui/svg-icons/av/fast-forward';
import m from 'highcharts-drilldown';
import download from 'js-file-download';
import ReactReponsiveModal from 'react-responsive-modal';
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var childJson = []

export default class EditUrgentContainerBookingFrom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            manualInco:false,
            basicTitle: '',
            basicType: "default",
            file: "",
            loadshow: 'show-n',
            overly: 'show-n',
            bulkslide: "",
            date: "",
            deptName: "",
            indenterName: "",
            mobile: "",
            indenterEmail: "",
            erpPO: "",
            erpPoNo: [],
            erpPoDate: [],
            ringiNo: "",
            ringiDesc: "",
            cargoDeliveryPlant: "",
            itemDesc: "",
            dimensions: "",
            cargo_chargeable_weight: "",
            supplierName: "",
            supplierAddr: "",
            supplierCnctPerson: "",
            country: "",
            emailID: "",
            phone: "",
            deliveryTerms: "Ex Works",
            pickUpAddr: "",
            cargoDate: "",
            LC: "",
            LCno: "",
            nature: "",
            freeTrade: "",
            benefitCert: "",
            disableERP: "disabled",
            disableLC: "disabled",
            deliveryLabel: "Pick Up Address",
            airportName: "",
            port: "",
            erpPo_date: "",
            cargo_date: "",
            container_type: "",
            container_size: [],
            dispatch_location: "",
            dispatch_port: "",
            destination_port: "",
            other_destination_port: "",
            container_required: "",
            container_required1: "",
            incoterms: "",
            certificateOfOrigin: "",
            fta: "",
            exportIncentive: "",
            payment_terms: "",
            file1: "",
            file2: "",
            file3: "",
            file4: "",
            rows: [{}],
            ringirows: [{}],
            packetrows: [{}],
            brequest: "activet",
            prepaid: "",
            track: "",
            requestData: "show-m",
            trackData: "show-n",
            trackResData: 'show-n',
            ffsuppliers: [],
            requestFields: "show-m",
            prepaidFields: "show-n",
            addresstxt: "",
            request_type: 1,
            pagetitle: "Urgent Container Booking",
            disableBng: "disabled",
            lspallocationdetails: [],
            showUpopts: "",
            showUpload: "show-n",
            filteredincoterms:[],
            manualincoterms:'',
			filteredispatchLocations: [],
            filteredestinationPort:[],
            department_name:'',
            intender_name:'',
            mobile_number:'',
            intenderMails:'',
            ringiData:[{}],
            consignee_name:'',
            mrd_date:'',
            container_requirement_date : '',
            dgd_file_name : "",
            msds_file_name:'',
            iip_file_name:'',
            grw_file_name:'',
            remarks:'',
            rowId:'',
            ringiFileUploaded:1,
            IIPFileUploaded:1,
            MSDSFileUploaded:1,
            DGDFileUploaded:1,


            booking_id:"",
            container_details_file:"",

            vessel: "",
            container_no:"",
            truck_no:"",
            
            shipping_on_board:"",
            bill_of_lading_file:"",
            ContainerDetailsFileUpload:1,
            shippingOnBoardFileUpload:1,
            BOLFileUpload:1,

            is_approved:0,
            etd_onetime:"",

            booking_details_issued:0,
            container_issued:0,
            truck_no_issued:0,

            booking_details_changed:false,
            container_details_changed:false,
            vehicle_no_changed:false,

            
            //components : {datepicker:getDatePicker()}
        }
        // this.packetsHandler = this.packetsHandler.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                var eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                var eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                var eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    async componentDidMount() {
        this.logPageView(); // Log page view to GA
        loadDateTimeScript();

        var inputEventval = window.atob(this.props.match.params.eventname);
        console.log(inputEventval,'inputEventval')
        var params = {
            rowId : inputEventval,
        }
        redirectURL.post("/consignments/fetchdataforeditonetime",params)
        .then((response) => {        
            if(response.data[0].payment_terms == "Free of Charge"){
                this.setState({
                    showUpload: "show-m"
                })
            }
            const mrdDate = new Date(response.data[0].mrd_date);
            const containerDate = new Date(response.data[0].container_requirement_date);
    
            // Function to format a date as DD-MM-YYYY
            const formatDate = (date) => {
                const day = date.getDate().toString().padStart(2, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
                const year = date.getFullYear();
                return `${year}-${month}-${day}`;
            };


            console.log(response.data,'department_name')
            $("#deptName").val(response.data[0].department_name);
            $("#indenterName").val(response.data[0].intender_name);
            $("#mobile").val(response.data[0].mobile_number);
            $("#indenterEmail").val(response.data[0].intenderMails);
            $("#consigneeName").val(response.data[0].consignee_name);
            $("#ringiDesc_").val(response.data[0].ringiDataLength);
            $("#mrd_dateField").val(formatDate(mrdDate));
        $("#container_dateField").val(formatDate(containerDate));
        $("#container_size").val(response.data[0].container_size)
        $("#container_required").val(response.data[0].container_requirement)
            $("#remarks").val(response.data[0].remarks);
            $("#manualincoterms").val(response.data[0].manualincoterms);
            $("#dateField").val(formatDate(new Date(response.data[0].date)));
            $("#etd_onetime").val(formatDate(new Date(response.data[0].etd)));

            console.log( $("#etd_onetime"),"274")
// console.log((response.data[0].manualincoterms),'incooo')
            var ringiData=[{}];
            if(response.data[0].ringiData !=undefined && response.data[0].ringiData !='')
            {
                var ringiData = JSON.parse(response.data[0].ringiData);
            }

            var shipping_on_board=""
            if(response.data[0].shipping_on_board=="Yes" || response.data[0].shipping_on_board=="No"){
                shipping_on_board=response.data[0].shipping_on_board
            }
          
            var bill_of_lading_file=""
            if(response.data[0].bill_of_lading_file){
                bill_of_lading_file=response.data[0].bill_of_lading_file
            }
            console.log(response.data[0].bill_of_lading_file,bill_of_lading_file,"bill_of_lading_file")
            this.setState({
                rowId:response.data[0]._id,
                date:response.data[0].date,
                manualincoterms:(response.data[0].manualincoterms),
                etd_onetime:(formatDate(new Date(response.data[0].etd))),
                // department_name:response.data[0].department_name,
                // intender_name : response.data[0].intender_name,
                // mobile_number:response.data[0].mobile_number,
                // intenderMails:response.data[0].intenderMails,
                ringiData:ringiData,
                ringiDesc : (response.data[0].ringiDataLength),
                // container_required1:(response.data[0].container_requirement),
                // consignee_name:response.data[0].consignee_name,
                container_type : {value:response.data[0].container_type,label:response.data[0].container_type},
                container_size:[{value:response.data[0].container_size,label:response.data[0].container_size}],
                dispatch_location : {value:response.data[0].dispatch_location,label:response.data[0].dispatch_location},
                dispatch_port :{value:response.data[0].dispatch_port,label:response.data[0].dispatch_port},
                destination_port : {value:response.data[0].destination_port,label:response.data[0].destination_port},
                // mrd_date : response.data[0].mrd_date,
                // container_requirement_date : response.data[0].container_requirement_date,
                dgd_file_name : response.data[0].dgd_file_name,
                msds_file_name:response.data[0].msds_file_name,
                iip_file_name:response.data[0].iip_file_name,
                grw_file_name:response.data[0].grw_file_name,
                container_details_file_name:response.data[0].container_details_file,
                bill_of_lading_file_name:bill_of_lading_file,

                incoterms:{value:response.data[0].incoterms,label:response.data[0].incoterms},
                payment_terms :{value:response.data[0].payment_terms,label:response.data[0].payment_terms},
                // remarks:response.data[0].remarks,
                certificateOfOrigin:{value:response.data[0].certificate_of_origin,label:response.data[0].certificate_of_origin},   
                fta :{value:response.data[0].fta_required,label:response.data[0].fta_required},
                exportIncentive:{value:response.data[0].export_incentive,label:response.data[0].export_incentive},
                booking_id:response.data[0].booking_id,
                truck_no:response.data[0].truck_no,
                container_no:response.data[0].container_no,
                vessel:response.data[0].vessel,
                shipping_on_board:{label:shipping_on_board,value:shipping_on_board},

                is_approved:response.data[0].is_approved,

                container_issued:response.data[0].container_issued,
                booking_details_issued:response.data[0].booking_details_issued,
                truck_no_issued:response.data[0].truck_no_issued
            })
        } )

        var pagetitle = "Edit Urgent Container Booking";
        // $(".requestFields").attr("required", true);
        this.setState({
            pagetitle: pagetitle,
        })
        var d = moment.parseZone(new Date()).utcOffset("+05:30")._d;
        var date = moment.parseZone(d).format("DD-MM-YYYY");
        
        redirectURL.post("/consignments/fetchLspDetailsData")
            .then((response) => {
                var records = response.data.data
                if (records.length > 0) {
                    this.setState({
                        lspallocationdetails: records,
                    })
                }
            })
        await redirectURL.post("/consignments/getLspDetailsforexports").then((response)=>{
            // console.log(response.data,"216")
            var destinationPorts = response.data.destinationPort;
				var filteredestinationPort = [];
				if (destinationPorts.length > 0) {
					destinationPorts.map(function (cl) {
						filteredestinationPort.push({
							label: cl,
							value: cl,
						});
					});
				}
				var dispatchLocations = response.data.dispatchLocation;
				var filteredispatchLocations = [];
				if (dispatchLocations.length > 0) {
					dispatchLocations.map(function (t) {
						filteredispatchLocations.push({
							label: t,
							value: t,
						});
					});
				}
                var incoterm = response.data.incoterms;
				var filteredincoterms = [];
				if (incoterm.length > 0) {
					incoterm.map(function (e) {
						filteredincoterms.push({
							label: e,
							value: e,
						});
					});
                    filteredincoterms.push({
                        label: "other",
                        value: 'other',
                    });
				}
				this.setState({
					filteredincoterms: filteredincoterms,
					filteredispatchLocations: filteredispatchLocations,
                    filteredestinationPort: filteredestinationPort
				});
        })
    }

    async formHandler(event) {
        event.preventDefault();
        this.setState({
            loadshow : "show-m",
            overly : "show-m"
        })
        var eventTarget = event.target;
        console.log(this.state.file, "this.state.file");
        var container_date = $("#container_dateField").val();
        var requirement_created_date=this.state.date
        var dateField = $("#dateField").val();
        var department_name = $('#deptName').val();
        var intender_name = $("#indenterName").val();
        var mobile_number = $("#mobile").val();
        var consignee_name = $("#consigneeName").val();
        var certificate_of_origin = this.state.certificateOfOrigin.value;
        var fta_required = this.state.fta.value;
        var export_incentive = this.state.exportIncentive.value;
        var intender_email = $("#indenterEmail").val();
        var manualincoterms = $("#manualincoterms").val();
        var intenderMails = []
        if (intender_email.includes(",") == true) {
            intenderMails = intender_email.split(",")
        } else {
            intenderMails = [intender_email]
        }
        var rngLength = $("#rngLength").val();
        var ringiData = [];
        for (var r = 0; r < rngLength; r++) {
            var ringiFile = $("#ringi_file_" + r).val();
            ringiFile = ringiFile.substring(ringiFile.lastIndexOf("\\") + 1, ringiFile.length);
            var ringiDesc = $("#ringiDesc_" + r).val();
            if (ringiFile != '' || ringiDesc != '') {
                var rngtemp = { "ringi_file": ringiFile, "ringi_desc": ringiDesc }
                ringiData.push(rngtemp)
            }
        }
        ringiData = JSON.stringify(ringiData);
        var container_type = this.state.container_type.value;
        // var dispatch_date = $('#dispatch_dateField').val();
        var mrd_date = $('#mrd_dateField').val();
        var dispatch_location = this.state.dispatch_location.value;
        var dispatch_port = this.state.dispatch_port.value;
        var destination_port = this.state.destination_port.value;
        if (destination_port == "Other") {
            var destination_port = $('#other_destination_port').val();
        }
        var incoterms = this.state.incoterms.value;
        var payment_terms = this.state.payment_terms.value;
        var remarks = $('#remarks').val();
        var container_size = this.state.container_size[0];
        var created_by = localStorage.getItem("email")
        var request_type = 1;
        var dgd_file = this.state.file1
        var msds_file = this.state.file2
        var iip_file = this.state.file3
        var grw_file = this.state.file4
        var ringiFileUploaded=this.state.ringiFileUploaded
        var DGDFileUploaded=this.state.DGDFileUploaded
        var MSDSFileUploaded=this.state.MSDSFileUploaded
        var IIPFileUploaded=this.state.IIPFileUploaded
       
        var containerSize = [];
        // for (var i = 0; i < container_size.length; i++) {
        //     containerSize.push({
        //         "container_size": container_size[i].value,
        //         "container_required": $("#container_required_" + i).val()
        //     })
        // }
        var requirement_id = moment.parseZone().format("YYYYMMDDHHmmss");
        var lspallocationdetails = this.state.lspallocationdetails;
        var flag = 1
        var errorMessage = ""
        var mismatchData = await lspallocationdetails.filter((s) => s.dispatch_location == dispatch_location && s.destination_port == destination_port && s.incoterms == incoterms);
        // console.log(mismatchData,"463")
        console.log(container_date,'container_date')
        var date = container_date.split("-")
        var containerDate = date[2] + "-" + date[1] + "-" + date[0] + " 00:00:00"
        // if (mismatchData.length == 0) {
        //     var compare_container_date = moment.parseZone(new Date(containerDate)).utcOffset("+05:30")._d
        //     var currentDate = moment.parseZone(new Date()).add(29, 'days').utcOffset("+05:30")._d;
        //     console.log(compare_container_date > currentDate,compare_container_date , currentDate,';;;')
        //     if (compare_container_date > currentDate) {
        //         console.log("success -- mismatch")
        //         flag = 1
        //     }
        //     else {
        //         console.log("fail -- mismatch")
        //         flag = 0
        //         errorMessage = "Container Requirement Date Should be Greater than 30 days"
        //     }
        // }
        // else 
        console.log(containerDate,"containerDate")
            if (container_type == "Non Haz Dry" || container_type == "Non Haz High Cube") {
                var compare_container_date = moment.parseZone(new Date(containerDate)).utcOffset("+05:30")._d
                var currentDate = moment.parseZone(new Date(this.state.date)).add(3, 'days').utcOffset("+05:30")._d;
                console.log(compare_container_date,currentDate,"483")
                if (compare_container_date > currentDate) {
                    console.log("success -- Haz")
                    flag = 1
                }
                else {
                    console.log("fail -- Haz")
                    flag = 0
                    errorMessage = "Container Requirement Date Should be Greater than 4 days"
                }
            }
            if (container_type == "Haz High Cube" || container_type == "Haz Dry") {
                var compare_container_date = moment.parseZone(new Date(containerDate)).utcOffset("+05:30")._d
                var currentDate = moment.parseZone(new Date(this.state.date)).add(9, 'days').utcOffset("+05:30")._d;
                console.log(compare_container_date,currentDate,this.state.date,"506")
                if (compare_container_date > currentDate) {
                    console.log("success -- Haz")
                    flag = 1
                }
                else {
                    console.log("fail -- Haz")
                    flag = 0
                    errorMessage = "Container Requirement Date Should be Greater than 10 days"
                }
            }
            if (incoterms == "DDU" || incoterms == "DDP") {
                var compare_container_date = moment.parseZone(new Date(containerDate)).utcOffset("+05:30")._d
                var currentDate = moment.parseZone(new Date(this.state.date)).add(29, 'days').utcOffset("+05:30")._d;
                if (compare_container_date > currentDate) {
                    console.log("success -- Incoterms")
                    flag = 1
                }
                else {
                    console.log("fail -- Incoterms")
                    flag = 0
                    errorMessage = "Container Requirement Date Should be Greater than 30 days"
                }
            }
            if (destination_port == "Other") {
                var compare_container_date = moment.parseZone(new Date(containerDate)).utcOffset("+05:30")._d
                var currentDate = moment.parseZone(new Date(this.state.date)).add(29, 'days').utcOffset("+05:30")._d;
                if (compare_container_date > currentDate) {
                    console.log("success -- Other Destination Port")
                    flag = 1
                }
                else {
                    console.log("fail -- Other Destination Port")
                    flag = 0
                    errorMessage = "Container Requirement Date Should be Greater than 30 days"
                }
            }

            if(ringiFileUploaded==0){
                errorMessage = "Upload Ringi File"
            }
            if(DGDFileUploaded==0){
                errorMessage = "Upload DGD File"
            }
            if(MSDSFileUploaded==0){
                errorMessage = "Upload MSDS File"
            }
            if(IIPFileUploaded==0){
                errorMessage = "Upload IIPF File"
            }
           
            // else if (container_type.includes("Dry") === true) {
            //     var compare_container_date = moment.parseZone(new Date(containerDate)).utcOffset("+05:30")._d
            //     var currentDate = moment.parseZone(new Date()).add(4, 'days').utcOffset("+05:30")._d;
            //     if (compare_container_date > currentDate) {
            //         console.log("success -- Dry")
            //         flag = 1
            //     }
            //     else {
            //         console.log("fail -- Dry")
            //         flag = 0
            //         errorMessage = "Container Requirement Date Should be Greater than 4 days"
            //     }
            // }
            
            const invalidEmails = intenderMails.filter(email => !validateEmail(email.trim()));

        if (invalidEmails.length > 0) {
            // Invalid email detected, show alert or handle accordingly
            alert("Invalid email address detected!");
            this.setState({
                loadshow : "show-n",
                overly : "show-n"
            })
            return
             // Prevent further processing if there are invalid emails
        }
        var fileFlag = 1
        // if (container_type.includes("Non") == false) {
        //     if (dgd_file != "" && dgd_file != undefined && dgd_file != null && msds_file != "" && msds_file != undefined && msds_file != null && iip_file != "" && iip_file != undefined && iip_file != null) {
        //         fileFlag = 1
        //     }
        //     else {
        //         fileFlag = 0
        //         errorMessage = "Files Should be Uploaded"
        //     }
        // }
        var grwfileflag = 1
        // if (payment_terms.includes("Free of Charge")) {
        //     if (grw_file != "" && grw_file != undefined && grw_file != null) {
        //         grwfileflag = 1
        //     }
        //     else {
        //         grwfileflag = 0
        //         errorMessage = "GR waiver Should be Uploaded"
        //     }
        // }
        // console.log(flag,"check")
        if (flag == 1 && fileFlag == 1 && grwfileflag == 1 &&ringiFileUploaded==1 &&DGDFileUploaded==1 &&
            MSDSFileUploaded==1 && IIPFileUploaded==1 ) {
                console.log("hitting")
            // var params = {
            //     requirement_id : requirement_id,
            //     container_requirement_date : container_date,
            //     container_type : container_type,
            //     container_data : containerSize,
            //     dispatch_location : dispatch_location,
            //     dispatch_port : dispatch_port,
            //     destination_port : destination_port,
            //     dispatch_date : dispatch_date,
            //     mrd_date : mrd_date,
            //     incoterms : incoterms,
            //     payment_terms : payment_terms,
            //     remarks : remarks,
            //     created_by : created_by,
            //     request_type : request_type,
            // }
            // console.log(params,"params")
            var saveData = new FormData();
            // saveData.append("csvData",csvData);
            saveData.append("dgd_file", dgd_file);
            saveData.append("msds_file", msds_file);
            saveData.append("iip_file", iip_file);
            saveData.append("grw_file", grw_file);
            // saveData.append("requirement_id", requirement_id);
            saveData.append("dateField", dateField);
            saveData.append("department_name", department_name);
            saveData.append("intender_name", intender_name);
            saveData.append("mobile_number", mobile_number);
            saveData.append("consignee_name", consignee_name);
            saveData.append("certificate_of_origin", certificate_of_origin);
            saveData.append("fta_required", fta_required);
            saveData.append("export_incentive", export_incentive);
            saveData.append("intenderMails", intenderMails);
            // saveData.append("rngLength", rngLength);
            // for (var rn = 0; rn < rngLength; rn++) {
            //     var ringiFile = "ringi_file_" + rn;

            //     saveData.append(ringiFile, this.state[ringiFile]);
            //     console.log(this.state[ringiFile]);
            // }
            saveData.append("ringiData", ringiData);
            saveData.append("container_requirement_date", container_date);
            saveData.append("container_type", container_type);
            saveData.append("container_size", container_size.value);
            saveData.append("dispatch_location", dispatch_location);
            saveData.append("dispatch_port", dispatch_port);
            saveData.append("destination_port", destination_port);
            // saveData.append("dispatch_date", dispatch_date);
            saveData.append("mrd_date", mrd_date);
          
            saveData.append("incoterms", incoterms);
            saveData.append("payment_terms", payment_terms);
            saveData.append("remarks", remarks);
            saveData.append("created_by", created_by);
            saveData.append("request_type", request_type);
            saveData.append("rowId",this.state.rowId);
            saveData.append("manualincoterms", manualincoterms);
           

            if (container_date != "" && container_date != undefined && container_date != null && mrd_date != "" && mrd_date != undefined && mrd_date != null && container_type != "" && container_type != undefined && container_type != null && dispatch_location != "" && dispatch_location != undefined && dispatch_location != null && dispatch_port != "" && dispatch_port != undefined && dispatch_port != null && destination_port != "" && destination_port != undefined && destination_port != null && incoterms != "" && incoterms != undefined && incoterms != null && payment_terms != "" && payment_terms != undefined && payment_terms != null && remarks != "" && container_size != "" && dateField != "" && department_name != "" && intender_name != "" && mobile_number != "" && intenderMails != "" && consignee_name != "" && certificate_of_origin != "" && certificate_of_origin != undefined && certificate_of_origin != null && fta_required != null && fta_required != undefined && fta_required != "" && export_incentive != "" && export_incentive != undefined && export_incentive != null) {
                console.log(saveData,"saveData")
                await redirectURL.post("/consignments/updateOnetimeBookingData", saveData, {
                    headers: {
                        'content-type': 'multipart/form-data',
                        // 'Access-Control-Allow-Origin': "*",
                        // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DEvarE',
                        'Access-Control-Allow-Headers': 'Origin, Content-Type'
                    }
                })
                    .then((response) => {
                        if (response.data.message == "Success") {
                            this.setState({
                                basicTitle: "Date Successfully updated",
                                basicType: "success",
                                show: true,
                                loadshow : "show-n",
                                overly : "show-n"
                            })
                            // window.location.href = "/containerbookingsystem/onetime"
                            this.componentDidMount()
                        } else {
                            this.setState({
                                basicTitle: "Date not updated",
                                basicType: "warning",
                                show: true,
                                loadshow : "show-n",
                                overly : "show-n"
                            })
                        }
                    })
            } else {
                this.setState({
                    basicTitle: "All fields are mandatory",
                    basicType: "warning",
                    show: true,
                    loadshow : "show-n",
                    overly : "show-n"
                })
            }
        }
        else {
            // console.log("hit")
            this.setState({
                basicTitle: errorMessage,
                basicType: "danger",
                show: true,
                loadshow : "show-n",
                overly : "show-n"
            })
        }
    }

    async formHandler2(event) {
        event.preventDefault();
        this.setState({
            loadshow : "show-m",
            overly : "show-m"
        })
        var errorMessage = ""
        var ContainerDetailsFileUpload=this.state.ContainerDetailsFileUpload
        var BOLFileUpload=this.state.BOLFileUpload

        var etd_onetime = this.state.etd_onetime;
        console.log(etd_onetime,moment.parseZone(etd_onetime).format("YYYY-MM-DD"),"etd_onetime")

        // if(!etd_onetime){
        //     etd_onetime=""
        // }

        if(ContainerDetailsFileUpload==0){
            errorMessage = "Upload Container Details File"
        }
        if(BOLFileUpload==0){
            errorMessage = "Upload BOL File"
        }
        var created_by = localStorage.getItem("email")
        console.log(this.state.bill_of_lading_file,"730")
        var saveData = new FormData();
        saveData.append("etd", moment.parseZone(etd_onetime).format("DD-MM-YYYY"));
        saveData.append("booking_id", this.state.booking_id);
        saveData.append("shipping_on_board", this.state.shipping_on_board.value);
        saveData.append("container_details_file", this.state.container_details_file);
        saveData.append("bill_of_lading_file", this.state.bill_of_lading_file);
        saveData.append("vessel", this.state.vessel);
        saveData.append("container_no", this.state.container_no);
        saveData.append("truck_no", this.state.truck_no);
        saveData.append("rowId",this.state.rowId);  
        saveData.append("created_by", created_by);
        saveData.append("booking_details_changed", this.state.booking_details_changed);
        saveData.append("container_details_changed", this.state.container_details_changed);
        saveData.append("vehicle_no_changed", this.state.vehicle_no_changed);

        console.log(saveData,'saveData')

        if (ContainerDetailsFileUpload==1 && BOLFileUpload==1) {
            await redirectURL.post("/consignments/updateOnetimeBookingDataafterApproval", saveData, {
                headers: {
                    'content-type': 'multipart/form-data',
                    // 'Access-Control-Allow-Origin': "*",
                    // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DEvarE',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type'
                }
            })
                .then((response) => {
                    if (response.data.message == "Success") {
                        this.setState({
                            basicTitle: "Date Successfully updated",
                            basicType: "success",
                            show: true,
                            loadshow : "show-n",
                            overly : "show-n"
                        })
                        // window.location.href = "/containerbookingsystem/onetime"
                        this.componentDidMount()
                    } else {
                        this.setState({
                            basicTitle: "Date not updated",
                            basicType: "warning",
                            show: true,
                            loadshow : "show-n",
                            overly : "show-n"
                        })
                    }
                })
        } else {
            this.setState({
                basicTitle: "All fields are mandatory",
                basicType: "warning",
                show: true,
                loadshow : "show-n",
                overly : "show-n"
            })
        }
    
}




    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            bulkslide: ''
        });

    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }


    containertypechangeHandler = (e) => {
        // if (e.value.includes("Non") == false) {
        //     $("#DGD_file").attr("required", true);
        //     $("#MSDS_file").attr("required", true);
        //     $("#IIP_file").attr("required", true);
        //     var showUpopts = "";
        // }
        // else {
        //     $("#DGD_file").removeAttr("required", true);
        //     $("#MSDS_file").removeAttr("required", true);
        //     $("#IIP_file").removeAttr("required", true);
        //     var showUpopts = "show-n";
        // }
        this.setState({
            container_type: e,
            // showUpopts: showUpopts
        })
    }
    containersizechangeHandler = (e) => {
        console.log(e,"734")
        if (e == null) {
            e = [];
        }
        this.setState({
            container_size: [e],
        })
    }
    dispatchlocationchangeHandler = (e) => {
        this.setState({
            dispatch_location: e,
        })
    }
    newFilter = (filterType, selOption) => {
		console.log(filterType, selOption)
        if(selOption.value == "other"){
         this.setState({
            manualInco : true,
            [filterType]: selOption
         })  
        }else{

            this.setState({ [filterType]: selOption ,
                manualInco : false
            });
        }
	}
    dispatchportchangeHandler = (e) => {
        this.setState({
            dispatch_port: e,
        })
    }
    destinationportchangeHandler = (e) => {
        this.setState({
            destination_port: e,
        })
    }
    containerrequiredchangeHandler = (e) => {
        this.setState({
            container_required: e,
        })
    }
    incotermschangeHandler = (e) => {
        this.setState({
            incoterms: e,
        })
    }
    certificateOfOriginchangeHandler = (e) => {
        this.setState({
            certificateOfOrigin: e,
        })
    }
    ftachangeHandler = (e) => {
        this.setState({
            fta: e,
        })
    }
    exportIncentivechangeHandler = (e) => {
        this.setState({
            exportIncentive: e,
        })
    }
    paymentchangeHandler = (e) => {
        if (e.value.includes("Free of Charge") == false) {
            var showUpload = "show-n"
            $("#GRW_file").removeAttr("required", true);

        } else {
            var showUpload = "show-m"
            if((this.state.grw_file_name != "") && (this.state.grw_file_name != undefined)){
                $("#GRW_file").attr("required", false);
            }else{
                $("#GRW_file").attr("required", true);
            }
            
        }
        this.setState({
            payment_terms: e,
            showUpload: showUpload
        })
    }
    changeHandler1 = (event) => {
        console.log("Select", event.target.value);
        let name = event.target.name;
        console.log(name,'ringiNo');
        let value = event.target.value;
        this.setState({ [name]: value });
        if (name == "erpPO") {
            if (value == "yes") {
                $("#addMrERp").show();
                this.setState({
                    disableERP: ""
                })
            } else {
                $("#addMrERp").hide();
                this.setState({
                    disableERP: "disabled"
                })
            }
        }
        if (name == "LC") {
            if (value == "yes") {
                this.setState({
                    disableLC: ""
                })
                //$("#LCno").prop("required",true);
            } else {
                this.setState({
                    disableLC: "disabled"
                })
                //$("#LCno").removeAttr("required",true);
            }
        }
        if (name == "cargoDeliveryPlant") {
            if (value == "B") {
                $("#invoice_type").val(null);
                $("#order_type").val(null);
                $("#invoice_type").prop("required", true);
                $("#order_type").prop("required", true);
                this.setState({
                    disableBng: ""
                })
            } else {
                $("#invoice_type").removeProp("required");
                $("#order_type").removeProp("required");
                this.setState({
                    disableBng: "disabled"
                })
            }
        }
        // if(name == "deliveryTerms")
        // {
        //     $(".dterms").removeAttr("required",true);
        //     if(value == "Ex Works")
        //     {
        //         $("#pickUpAddr").prop("required",true);
        //     }
        //     else if(value == "FCA")
        //     {
        //         $("#port").prop("required",true);
        //     }
        //     else if(value == "FOB")
        //     {
        //         $("#airportName").prop("required",true);
        //     }
        // }
    }
    changeHandler = (event) => {
        console.log("Select", event.target.value);

        var name = event.target.name;
        var value = event.target.value;
        const re = /^[A-Za-z]+$/;
        // if (name == 'deptName') {
        //     if (value === "" || re.test(value)) {
        //         console.log("name succ")
        //         alert('hi')
        //         //   this.setState(value);
        //     }else{
        //         alert('dont enter the numerals')
        //     }
        // }

        console.log(name);
    }

    changeFileHandler = async (e) => {
        console.log(e.target.name,e.target.files)
        this.setState({ [e.target.name]: e.target.files[0],ringiFileUploaded:1 });
    }
    changeFileHandler1 = (e) => {
        this.setState({
            file1: e.target.files[0],
            DGDFileUploaded:1
        });
    }
    changeFileHandler2 = (e) => {
        this.setState({
            file2: e.target.files[0],
            MSDSFileUploaded:1
        });
    }
    uploadContainerDetailsFile = (e) => {
        console.log(e.target.files[0],"940")
        this.setState({
            container_details_file: e.target.files[0],
            ContainerDetailsFileUpload:1,
            booking_details_changed:true
        });
    }
    uploadBOLFile = (e) => {
        console.log(e.target.files[0],"949")
        this.setState({
            bill_of_lading_file: e.target.files[0],
            BOLFileUpload:1
        });
    }
    changeFileHandler3 = (e) => {
        this.setState({
            file3: e.target.files[0],
            IIPFileUploaded:1
        });
    }
    changeFileHandler4 = (e) => {
        this.setState({
            file4: e.target.files[0]
        });
    }


  


    downloadRingiFileoneTime =(idx) => {
        var ringiFile = $("#hiddenRingiFile_"+idx).val();
        console.log(ringiFile,'ringiFile')
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            console.log(rngParam,'rngParam')
            redirectURL.post("/consignments/downloadRingiFileoneTime",rngParam,{
                responseType: 'blob'
            }).then((response)=>{
                console.log("dowlad response ",response)
                download(response.data, ringiFile)
            }).catch(function(error){
                console.log(error);
            })
        }
    }

    handleRemoveRingiFile =  (idx) => {
        var ringiFile = $("#hiddenRingiFile_"+idx).val();
        this.setState({ringiFileUploaded:0})
        console.log(ringiFile)
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            redirectURL.post("/consignments/removeRingiFileEdit",rngParam).then((response)=>{
                $(".rnFile_"+idx).html("");
                this.hide();
            }).catch(function(error){
                console.log(error);
            })
        }
    }

    downloadRingiFileoneTime1 = () => {
        var ringiFile = $("#hiddenRingiFile_1").val();
        console.log(ringiFile,'ringiFile')
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            console.log(rngParam,'rngParam')
            redirectURL.post("/consignments/downloadRingiFileoneTime",rngParam,{
                responseType: 'blob'
            }).then((response)=>{
                console.log("dowlad response ",response)
                download(response.data, ringiFile)
            }).catch(function(error){
                console.log(error);
            })
        }
    }
    downloadRingiFileoneTime2 = () => {
        var ringiFile = $("#hiddenRingiFile_2").val();
        console.log(ringiFile,'ringiFile')
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            console.log(rngParam,'rngParam')
            redirectURL.post("/consignments/downloadRingiFileoneTime",rngParam,{
                responseType: 'blob'
            }).then((response)=>{
                console.log("dowlad response ",response)
                download(response.data, ringiFile)
            }).catch(function(error){
                console.log(error);
            })
        }
    }
    downloadRingiFileoneTime3 = () => {
        var ringiFile = $("#hiddenRingiFile_3").val();
        console.log(ringiFile,'ringiFile')
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            console.log(rngParam,'rngParam')
            redirectURL.post("/consignments/downloadRingiFileoneTime",rngParam,{
                responseType: 'blob'
            }).then((response)=>{
                console.log("dowlad response ",response)
                download(response.data, ringiFile)
            }).catch(function(error){
                console.log(error);
            })
        }
    }
    downloadRingiFileoneTime4 = () => {
        var ringiFile = $("#hiddenRingiFile_4").val();
        console.log(ringiFile,'ringiFile')
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            console.log(rngParam,'rngParam')
            redirectURL.post("/consignments/downloadRingiFileoneTime",rngParam,{
                responseType: 'blob'
            }).then((response)=>{
                console.log("dowlad response ",response)
                download(response.data, ringiFile)
            }).catch(function(error){
                console.log(error);
            })
        }
    }
    downloadRingiFileoneTime5 = () => {
        var ringiFile = $("#hiddenRingiFile_5").val();
        console.log(ringiFile,'ringiFile')
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            console.log(rngParam,'rngParam')
            redirectURL.post("/consignments/downloadRingiFileoneTime",rngParam,{
                responseType: 'blob'
            }).then((response)=>{
                console.log("dowlad response ",response)
                download(response.data, ringiFile)
            }).catch(function(error){
                console.log(error);
            })
        }
    }
    downloadRingiFileoneTime6 = () => {
        var ringiFile = $("#hiddenRingiFile_6").val();
        console.log(ringiFile,'ringiFile')
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            console.log(rngParam,'rngParam')
            redirectURL.post("/consignments/downloadRingiFileoneTime",rngParam,{
                responseType: 'blob'
            }).then((response)=>{
                console.log("dowlad response ",response)
                download(response.data, ringiFile)
            }).catch(function(error){
                console.log(error);
            })
        }
    }

   
    handleRemoveRingiFile1 =  () => {
        var ringiFile = $("#hiddenRingiFile_1").val();
        this.setState({DGDFileUploaded:0})
        console.log(ringiFile)
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            redirectURL.post("/consignments/removeRingiFileEdit",rngParam).then((response)=>{
                $(".rnFile_1").html("");
                this.hide();
            }).catch(function(error){
                console.log(error);
            })
        }
    }
    handleRemoveRingiFile2 =  () => {
        var ringiFile = $("#hiddenRingiFile_2").val();
        console.log(ringiFile)
        this.setState({MSDSFileUploaded:0})
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            redirectURL.post("/consignments/removeRingiFileEdit",rngParam).then((response)=>{
                $(".rnFile_2").html("");
                this.hide();
            }).catch(function(error){
                console.log(error);
            })
        }
    }
     handleRemoveRingiFile3 =  () => {
        var ringiFile = $("#hiddenRingiFile_3").val();
        this.setState({IIPFileUploaded:0})
        console.log(ringiFile)
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            redirectURL.post("/consignments/removeRingiFileEdit",rngParam).then((response)=>{
                $(".rnFile_3").html("");
                this.hide();
            }).catch(function(error){
                console.log(error);
            })
        }
    } 
    handleRemoveRingiFile4 =  () => {
        var ringiFile = $("#hiddenRingiFile_4").val();
        console.log(ringiFile)
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            redirectURL.post("/consignments/removeRingiFileEdit",rngParam).then((response)=>{
                $(".rnFile_4").html("");
                this.hide();
            }).catch(function(error){
                console.log(error);
            })
        }
    }
    handleRemoveRingiFile5 =  () => {
        var ringiFile = $("#hiddenRingiFile_5").val();
        this.setState({ContainerDetailsFileUpload:0})
        console.log(ringiFile)
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            redirectURL.post("/consignments/removeRingiFileEdit",rngParam).then((response)=>{
                $(".rnFile_5").html("");
                this.hide();
            }).catch(function(error){
                console.log(error);
            })
        }
    }
    handleRemoveRingiFile6 =  () => {
        var ringiFile = $("#hiddenRingiFile_6").val();
        this.setState({BOLFileUpload:0})
        console.log(ringiFile)
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            redirectURL.post("/consignments/removeRingiFileEdit",rngParam).then((response)=>{
                $(".rnFile_6").html("");
                this.hide();
            }).catch(function(error){
                console.log(error);
            })
        }
    }

    onChangeBookingDetails=(filter,event)=>{
        console.log(event,filter,"1220")
        this.setState({[filter]:event.target.value})

        if(filter=="booking_id"){
            this.setState({booking_details_changed:true})
        }else if(filter=="container_no" || filter=="vessel"){
            this.setState({container_details_changed:true})
        }else{
            this.setState({vehicle_no_changed:true})
        }

    }

    SOBchangeHandler=(e)=>{
        console.log(e,"1231")
        this.setState({
            shipping_on_board:e
        })
    }
    handlerETD = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	// var startdate = (d.getDate()<10?"0"+d.getDate():d.getDate())+"-"+(d.getMonth() +1)+"-"+d.getFullYear();
    	// console.log(startdate,"1418")
        console.log(d,"1463")
    	this.setState({
    		etd_onetime:d,
            booking_details_changed:true
    	});
    }

    render() {

        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                  
                    <div className={"col-xl-12 col-lg-12 " + this.state.requestData}  style={{borderRadius:"10px"}}>
                        <div className="card"  style={{borderRadius:"10px"}}>
                            <div className="card-header"  style={{borderRadius:"10px"}}>
                            <div className='d-flex' style={{paddingLeft:"8.5px", paddingTop:"7.5px"}}>
                                    <a href="/containerbookingsystem/urgent" >
                                        <i className="fa fa-arrow-left" style={{color:"#171c8f", fontSize:"20px", width:"20px"}}></i>
                                    </a>
                                    <h1 style={{color:"#171c8f",fontWeight:"600", paddingTop:"1px",fontSize:"16px",marginBottom:"0px",paddingLeft:"10px"}}>{this.state.pagetitle}</h1>                                   
                                </div>

                            </div>
                            <div className="card-body pt-15px">
                                     {this.state.is_approved==0 && 
                                     <form class="aircargo-form" onSubmit={this.formHandler.bind(this)} encType="multipart/form-data">
                                    
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">Date</label>
                                            <input type="text"  name="dateField" id="dateField" className="datetimepicker_date form-control form-input-box" disabled />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Department Name</label>
                                            <input type="text" name="deptName" id="deptName" 
                                            className="form-control form-input-box" onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Indenter Name</label>
                                            <input type="text" name="indenterName" id="indenterName" 
                                            className="form-control form-input-box" onChange={this.changeHandler} required />
                                        </div>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Mobile No</label>
                                            <input type="number" name="mobile" id="mobile" className="form-control mobnum-cls form-input-box"
                                            
                                             onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Indenter Email ID</label>
                                            <input type="text" name="indenterEmail" id="indenterEmail"  className="form-control form-input-box" onChange={this.changeHandler} required />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    {this.state.ringiData.map((item, idx) => (
                                        <div className={"row col-xl-12 "} key={idx}>
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12 form-input-text">*Ringi No (File)</label>
                                                <input type="file" name={"ringi_file_" + idx}
                                               
                                                 id={"ringi_file_" + idx} className="form-control requestFields form-input-box"  onChange={this.changeFileHandler}  />
                                             
                                            {((item.ringi_file != "") && (item.ringi_file != undefined)) ?  
                                                <div className="row col-xl-12">
                                                    <div className="col-xl-10">
                                                        <a className={"rnFile_"+idx}>{item.ringi_file}</a> 
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile_"+idx} style={{cursor:"pointer"}} 
                                                        onClick={() => this.downloadRingiFileoneTime(idx)}
                                                        ><i title="Download" className="fa fa-download"></i></p>
                                                        
                                                        {/* <a target="_blank" href={require("../../../../api/routes/uploads/"+(item.invoice_file))}>Test </a> */}
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile_"+idx} style={{cursor:"pointer"}}
                                                         onClick={() => this.handleRemoveRingiFile(idx)}
                                                        ><i title="Delete" className="fa fa-trash"></i></p>
                                                    </div>
                                                    <input type="hidden" id={"hiddenRingiFile_"+idx} value={item.ringi_file} />
                                                </div>
                                                :
                                                ""
                                                }
                                            </div>
                                            
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12 form-input-text">*Ringi No</label>
                                                <input type="text" name={"ringiDesc_" + idx} id={"ringiDesc_" + idx} className="form-control ringi-cls form-input-box" onChange={this.changeHandler1} 
                                                value={this.state.ringiDesc} />
                                            </div>
                                            {/* <div className="col-xl-4" style={{ display: (idx != 0) ? 'block' : 'none' }}>
                                                <h6 style={{ marginTop: "2.5em" }} onClick={() => this.handleRemoveRingiRow(idx)}>Clear</h6>
                                            </div> */}
                                        </div>
                                    ))}
                                    <input type="hidden" id="rngLength" value={this.state.ringirows.length} />
                                    {/* <div class={"row col-sm-12 "}>
                                        <div className="row col-xl-12 m-top-1">
                                            <div class="col-xl-12">
                                                <button class="btn btn-primary" type="button" onClick={this.handleAddRingiRow}>Add More Fields +</button>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div class={"hr-dashed "}></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Consingee Name</label>
                                            <input type="text" name="consigneeName"  id="consigneeName" className="form-control alfa-cls form-input-box" onChange={this.changeHandler}  />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Container Type</label>
                                            <Select
                                                placeholder={"Select Container Type"}
                                                closeMenuOnSelect={true}
                                                value={this.state.container_type}
                                                onChange={this.containertypechangeHandler.bind(this)}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderRadius: "6px",
                                                        backgroundColor: "#fff",
                                                        boxShadow: "0 0 8px #d9d9d9",
                                                        border: "1px solid #d1d3e2",
                                                    }),

                                                }}
                                                options={[{ label: "Haz Dry", value: "Haz Dry" },
                                                { label: "Haz High Cube", value: "Haz High Cube" }, { label: "Non Haz Dry", value: "Non Haz Dry" }, { label: "Non Haz High Cube", value: "Non Haz High Cube" }]} required
                                            />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Container Size</label>
                                            <Select
                                                placeholder={"Select container Size"}
                                                closeMenuOnSelect={true}
                                                value={this.state.container_size}
                                                id={"container_size"}
                                                // isMulti={true}
                                                onChange={this.containersizechangeHandler.bind(this)}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderRadius: "6px",
                                                        backgroundColor: "#fff",
                                                        boxShadow: "0 0 8px #d9d9d9",
                                                        border: "1px solid #d1d3e2",
                                                    }),

                                                }}
                                                options={[{ label: "20", value: "20" },
                                                { label: "40", value: "40" }]} required
                                            />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Dispatch Location</label>
                                            <Select
                                                placeholder={"Select Dispatch Location"}
                                                closeMenuOnSelect={true}
                                                value={this.state.dispatch_location}

                                                onChange={this.newFilter.bind(this,"dispatch_location")}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderRadius: "6px",
                                                        backgroundColor: "#fff",
                                                        boxShadow: "0 0 8px #d9d9d9",
                                                        border: "1px solid #d1d3e2",
                                                    }),

                                                }}
                                                options={this.state.filteredispatchLocations} required
                                            />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Dispatch Port</label>
                                            <Select
                                                placeholder={"Select Dispatch Port"}
                                                closeMenuOnSelect={true}
                                                value={this.state.dispatch_port}

                                                onChange={this.dispatchportchangeHandler.bind(this)}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderRadius: "6px",
                                                        backgroundColor: "#fff",
                                                        boxShadow: "0 0 8px #d9d9d9",
                                                        border: "1px solid #d1d3e2",
                                                    }),

                                                }}
                                                options={[{ label: "Pipavav", value: "Pipavav" },
                                                { label: "Mundra", value: "Mundra" }, { label: "Nhava Sheva", value: "Nhava Sheva" }, { label: "Chennai", value: "Chennai" }, { label: "Kattupali", value: "Kattupali" }, { label: "ICD Garhi", value: "ICD Garhi" }]} required
                                            />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Destination Port</label>
                                            <Select
                                                placeholder={"Select Destination Port"}
                                                closeMenuOnSelect={true}
                                                value={this.state.destination_port}

                                                onChange={this.newFilter.bind(this,"destination_port")}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderRadius: "6px",
                                                        backgroundColor: "#fff",
                                                        boxShadow: "0 0 8px #d9d9d9",
                                                        border: "1px solid #d1d3e2",
                                                    }),

                                                }}
                                                options={this.state.filteredestinationPort} required
                                            />
                                        </div>
                                        {(this.state.destination_port.value == "Other") ?
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12 form-input-text">*Other Destination Port</label>
                                                <input type="text" name="other_destination_port" id="other_destination_port" className="form-control alfa-cls form-input-box" onChange={this.changeHandler} required />
                                            </div> : ""
                                        }

                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
{/* 
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12"> Dispatch Date* : </label>
                                            <input type="text" name="dispatch_date" id="dispatch_dateField" className="datetimepicker_date form-control" readOnly />
                                        </div> */}
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*MRD at Destination</label>
                                            <input type="text" name="mrd_date" id="mrd_dateField" 
                                           
                                           className="datetimepicker_date form-control form-input-box" readOnly />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Container Requirement Date</label>
                                            <input type="text" name="container_date" 

                                            // value={this.state.container_requirement_date} 
                                            
                                            id="container_dateField" className="datetimepicker_date form-control form-input-box" readOnly />
                                        </div>
                                        {/* {this.state.container_size.map((item, idx) => (
                                            <div className="col-xl-4" key={idx}>
                                                <label className="col-xl-12 col-lg-12"> No. of Container required For  {item.label}*: </label>
                                                <input type="text" name={"container_required_" + idx} id={"container_required_" + idx} 

                                                value={this.state.container_required1} 
                                                disabled
                                                className="form-control num-cls" onChange={this.changeHandler} required />
                                            </div>
                                        ))} */}
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12 col-lg-12">

                                        <div className="col-xl-4 col-lg-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*DGD if Haz Container required<span className={this.state.showUpopts}></span></label>
                                            <input type="file" name="DGD_file" id="DGD_file"
                                            // value={this.state.dgd_file_name}
                                             className="form-control requestFields form-input-box" onChange={this.changeFileHandler1} />
                                            {/* <FileBase64
                                            onDone={ this.changeFileHandler.bind(this) } /> */}
                                        {((this.state.dgd_file_name != "") && (this.state.dgd_file_name != undefined)) ?  
                                                <div className="row col-xl-12">
                                                    <div className="col-xl-10">
                                                        <a className={"rnFile_1"}>{this.state.dgd_file_name}</a> 
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile_1"} style={{cursor:"pointer"}} 
                                                        onClick={() => this.downloadRingiFileoneTime1()}
                                                        ><i title="Download" className="fa fa-download"></i></p>
                                                        
                                                        {/* <a target="_blank" href={require("../../../../api/routes/uploads/"+(item.invoice_file))}>Test </a> */}
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile_1"} style={{cursor:"pointer"}}
                                                         onClick={() => this.handleRemoveRingiFile1()}
                                                        ><i title="Delete" className="fa fa-trash"></i></p>
                                                    </div>
                                                    <input type="hidden" id={"hiddenRingiFile_1"} value={this.state.dgd_file_name} />
                                                </div>
                                                :
                                                ""
                                                }
                                        </div>
                                            
                                        <div className="col-xl-4 col-lg-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*MSDS of the Cargo<span className={this.state.showUpopts}></span></label>
                                            <input type="file" name="MSDS_file" id="MSDS_file"
                                            //  value={this.state.msds_file_name} 
                                            className="form-control requestFields form-input-box" onChange={this.changeFileHandler2} />
                                            {/* <FileBase64
                                            onDone={ this.changeFileHandler.bind(this) } /> */}
                                             {((this.state.msds_file_name != "") && (this.state.msds_file_name != undefined)) ?  
                                                <div className="row col-xl-12">
                                                    <div className="col-xl-10">
                                                        <a className={"rnFile_2"}>{this.state.msds_file_name}</a> 
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile_2"} style={{cursor:"pointer"}} 
                                                        onClick={() => this.downloadRingiFileoneTime2()}
                                                        ><i title="Download" className="fa fa-download"></i></p>
                                                        
                                                        {/* <a target="_blank" href={require("../../../../api/routes/uploads/"+(item.invoice_file))}>Test </a> */}
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile_2"} style={{cursor:"pointer"}}
                                                         onClick={() => this.handleRemoveRingiFile2()}
                                                        ><i title="Delete" className="fa fa-trash"></i></p>
                                                    </div>
                                                    <input type="hidden" id={"hiddenRingiFile_2"} value={this.state.msds_file_name} />
                                                </div>
                                                :
                                                ""
                                                }
                                        </div>
                                        <div className="col-xl-4 col-lg-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*IIP certificate of the Cargo<span className={this.state.showUpopts}></span></label>
                                            <input type="file" name="IIP_file" id="IIP_file"
                                            //  value= {this.state.iip_file_name} 
                                             className="form-control requestFields form-input-box" onChange={this.changeFileHandler3} />
                                            {/* <FileBase64
                                            onDone={ this.changeFileHandler.bind(this) } /> */}
                                              {((this.state.iip_file_name != "") && (this.state.iip_file_name != undefined)) ?  
                                                <div className="row col-xl-12">
                                                    <div className="col-xl-10">
                                                        <a className={"rnFile_3"}>{this.state.iip_file_name}</a> 
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile_3"} style={{cursor:"pointer"}} 
                                                        onClick={() => this.downloadRingiFileoneTime3()}
                                                        ><i title="Download" className="fa fa-download"></i></p>
                                                        
                                                        {/* <a target="_blank" href={require("../../../../api/routes/uploads/"+(item.invoice_file))}>Test </a> */}
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile_3"} style={{cursor:"pointer"}}
                                                         onClick={() => this.handleRemoveRingiFile3()}
                                                        ><i title="Delete" className="fa fa-trash"></i></p>
                                                    </div>
                                                    <input type="hidden" id={"hiddenRingiFile_3"} value={this.state.iip_file_name} />
                                                </div>
                                                :
                                                ""
                                                }
                                        </div>
                                    </div>

                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Incoterms to be Used</label>
                                            <Select
                                                placeholder={"Select Incoterms"}
                                                closeMenuOnSelect={true}
                                                value={this.state.incoterms}

                                                onChange={this.newFilter.bind(this,"incoterms")}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderRadius: "6px",
                                                        backgroundColor: "#fff",
                                                        boxShadow: "0 0 8px #d9d9d9",
                                                        border: "1px solid #d1d3e2",
                                                    }),

                                                }}
                                                options={this.state.filteredincoterms} required
                                            />
                                        </div>
                                        {
                                            (this.state.incoterms.value == "other") || (this.state.manualInco == true) ? 
                                              <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">Incoterms</label>
                                            <input type="text" name="manualincoterms" id="manualincoterms" className="form-control alfa-cls form-input-box" 
                                            value={this.state.manualincoterms}
                                            // onChange={this.changeHandler}
                                             required />
                                        </div>
                                        : ""
                                        }
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Payment Terms</label>
                                            <Select
                                                placeholder={"Select Payment Terms"}
                                                closeMenuOnSelect={true}
                                                value={this.state.payment_terms}

                                                onChange={this.paymentchangeHandler.bind(this)}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderRadius: "6px",
                                                        backgroundColor: "#fff",
                                                        boxShadow: "0 0 8px #d9d9d9",
                                                        border: "1px solid #d1d3e2",
                                                    }),

                                                }}
                                                options={[{ label: "Free of Charge", value: "Free of Charge" },
                                                { label: "Payment", value: "Payment" }]} required
                                            />
                                        </div>
                                        <div className={"col-xl-4 col-lg-4 " + this.state.showUpload}>
                                            <label className="col-xl-12 col-lg-12 form-input-text">*GR waiver-In case of free of charge</label>
                                            <input type="file" name="GRW_file" id="GRW_file" className="form-control requestFields form-input-box" onChange={this.changeFileHandler4} />
                                            {/* <FileBase64
                                            onDone={ this.changeFileHandler.bind(this) } /> */}
                                              {((this.state.grw_file_name != "") && (this.state.grw_file_name != undefined)) ?  
                                                <div className="row col-xl-12">
                                                    <div className="col-xl-10">
                                                        <a className={"rnFile_4"}>{this.state.grw_file_name}</a> 
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile_4"} style={{cursor:"pointer"}} 
                                                        onClick={() => this.downloadRingiFileoneTime4()}
                                                        ><i title="Download" className="fa fa-download"></i></p>
                                                        
                                                        {/* <a target="_blank" href={require("../../../../api/routes/uploads/"+(item.invoice_file))}>Test </a> */}
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile_4"} style={{cursor:"pointer"}}
                                                         onClick={() => this.handleRemoveRingiFile4()}
                                                        ><i title="Delete" className="fa fa-trash"></i></p>
                                                    </div>
                                                    <input type="hidden" id={"hiddenRingiFile_4"} value={this.state.grw_file_name} />
                                                </div>
                                                 :
                                                ""
                                                } 
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Remarks</label>
                                            <input type="text" name="remarks" id="remarks" className="form-control alfa-cls form-input-box" onChange={this.changeHandler} required />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Certificate of Origin</label>
                                            <Select
                                                placeholder={"Select Certificate of Origin"}
                                                closeMenuOnSelect={true}
                                                value={this.state.certificateOfOrigin}

                                                onChange={this.certificateOfOriginchangeHandler.bind(this)}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderRadius: "6px",
                                                        backgroundColor: "#fff",
                                                        boxShadow: "0 0 8px #d9d9d9",
                                                        border: "1px solid #d1d3e2",
                                                    }),

                                                }}
                                                options={[{ label: "Yes", value: "Yes" },
                                                { label: "No", value: "No" }]} required
                                            />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*FTA(Free Trade Agreement)</label>
                                            <Select
                                                placeholder={"Select FTA Required"}
                                                closeMenuOnSelect={true}
                                                value={this.state.fta}

                                                onChange={this.ftachangeHandler.bind(this)}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderRadius: "6px",
                                                        backgroundColor: "#fff",
                                                        boxShadow: "0 0 8px #d9d9d9",
                                                        border: "1px solid #d1d3e2",
                                                    }),

                                                }}
                                                options={[{ label: "Yes", value: "Yes" },
                                                { label: "No", value: "No" }]} required
                                            />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Export Incentive</label>
                                            <Select
                                                placeholder={"Select Export Incentive"}
                                                closeMenuOnSelect={true}
                                                value={this.state.exportIncentive}

                                                onChange={this.exportIncentivechangeHandler.bind(this)}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderRadius: "6px",
                                                        backgroundColor: "#fff",
                                                        boxShadow: "0 0 8px #d9d9d9",
                                                        border: "1px solid #d1d3e2",
                                                    }),

                                                }}
                                                options={[{ label: "Yes", value: "Yes" },
                                                { label: "No", value: "No" }]} required
                                            />
                                        </div>
                                    </div>
                                  
                                    
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-booking" style={{ marginLeft: "45%", width: "192px", marginTop: "20px" }}>Submit</button>
                                    </div>
                                </form>

                                      } 

                                    {this.state.is_approved==1 &&
                                <form class="aircargo-form" onSubmit={this.formHandler2.bind(this)} encType="multipart/form-data">
                             {this.state.booking_details_issued==1 && 
                                <>
                                <div className="row col-xl-12">
                                <div className="col-xl-4">
                                    <label className="col-xl-12 col-lg-12 form-input-text">*Booking ID</label>
                                    <input 
                                    type="text" 
                                    name="booking_id"  
                                    id="booking_id" 
                                    className="form-control form-input-box" 
                                    value={this.state.booking_id}
                                    onChange={this.onChangeBookingDetails.bind(this,"booking_id")}  />
                                </div>

                                <div className="col-xl-4 col-lg-4">
                                    <label className="col-xl-12 col-lg-12 form-input-text">*Booking Details File<span className={this.state.showUpopts}></span></label>
                                    <input type="file" name="container_details_file" id="container_details_file"
                                    //  value={this.state.msds_file_name} 
                                    className="form-control requestFields form-input-box" onChange={this.uploadContainerDetailsFile} />
                                    {/* <FileBase64
                                    onDone={ this.changeFileHandler.bind(this) } /> */}
                                    {((this.state.container_details_file_name != "") && (this.state.container_details_file_name != undefined)) ?  
                                        <div className="row col-xl-12">
                                            <div className="col-xl-10">
                                                <a className={"rnFile_5"}>{this.state.container_details_file_name}</a> 
                                            </div>
                                            <div className="col-xl-1">
                                                <p className={"rnFile_5"} style={{cursor:"pointer"}} 
                                                onClick={() => this.downloadRingiFileoneTime5()}
                                                ><i title="Download" className="fa fa-download"></i></p>
                                                
                                                {/* <a target="_blank" href={require("../../../../api/routes/uploads/"+(item.invoice_file))}>Test </a> */}
                                            </div>
                                            <div className="col-xl-1">
                                                <p className={"rnFile_5"} style={{cursor:"pointer"}}
                                                onClick={() => this.handleRemoveRingiFile5()}
                                                ><i title="Delete" className="fa fa-trash"></i></p>
                                            </div>
                                            <input type="hidden" id={"hiddenRingiFile_5"} value={this.state.container_details_file_name} />
                                        </div>
                                        :
                                        ""
                                        }
                                </div>

                                </div>
                                <div class={"hr-dashed"}></div>
                                </>
                             }
                                   
                                    {this.state.container_issued==1 &&
                                        <>
                                           <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Container No</label>
                                            <input 
                                            type="text" 
                                            name="container_no"  
                                            id="container_no" 
                                            className="form-control form-input-box" 
                                            value={this.state.container_no}
                                            onChange={this.onChangeBookingDetails.bind(this,"container_no")}  />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Vessel</label>
                                            <input 
                                            type="text" 
                                            name="vessel"  
                                            id="vessel" 
                                            className="form-control form-input-box" 
                                            value={this.state.vessel}
                                            onChange={this.onChangeBookingDetails.bind(this,"vessel")}  />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Expected Date Of Dispatch</label>
                                            {/* <input 
                                            type="text" 
                                            name="etd_onetime" 
                                            id="etd_onetime" 
                                           autoComplete='off'
                                           className="datetimepicker_date form-control" /> */}
                                             <Datetime
                                            value={this.state.etd_onetime} 
                                            className='form-input-box'
                                            disableCloseOnClickOutside={false} 
                                            closeOnSelect={true} 
                                            inputProps={{ className:'form-control form-input-box', placeholder: 'Estimated Time Of Dispatch',autoComplete:'off' }}
                                            dateFormat="YYYY-MM-DD"
                                            inputFormat="YYYY-MM-DD"   
                                            name="etd_onetime" 
                                            timeFormat={false}
                                            onChange={this.handlerETD.bind(this)} />
                                         </div>

                                         </div>
                                         <div class={"hr-dashed"}></div>
                                        </>
                                    }
                                    
                                    {this.state.truck_no_issued==1 &&
                                    <>
                                          <div className="row col-xl-12">
                                          <div className="col-xl-4">
                                                  <label className="col-xl-12 col-lg-12 form-input-text">*Vehicle No</label>
                                                  <input 
                                                  type="text" 
                                                  name="truck_no"  
                                                  id="truck_no" 
                                                  className="form-control form-input-box" 
                                                  value={this.state.truck_no}
                                                  onChange={this.onChangeBookingDetails.bind(this,"truck_no")}  />
                                              </div>
                                         
                                         
                                       </div>
                                       <div class={"hr-dashed"}></div>
                                       </>
                                    }
                          
                                   <div className="row col-xl-12">
                                    {this.state.shipping_on_board.value.length>0 && 
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12 form-input-text">*Shipping On Board</label>
                                            <Select
                                                placeholder={"Select Shipping On Board"}
                                                closeMenuOnSelect={true}
                                                value={this.state.shipping_on_board}

                                                onChange={this.SOBchangeHandler.bind(this)}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderRadius: "6px",
                                                        backgroundColor: "#fff",
                                                        boxShadow: "0 0 8px #d9d9d9",
                                                        border: "1px solid #d1d3e2",
                                                    }),

                                                }}
                                                options={[{ label: "Yes", value: "Yes" },
                                                { label: "No", value: "No" }]} required
                                            />
                                    </div>
                                      }
                                    {console.log(this.state.bill_of_lading_file,"2023")}
                                   {this.state.bill_of_lading_file_name.length>0 &&
                                          <div className="col-xl-4 col-lg-4">
                                          <label className="col-xl-12 col-lg-12 form-input-text">*Bill Of Lading<span className={this.state.showUpopts}></span></label>
                                          <input type="file" name="bill_of_lading_file" id="bill_of_lading_file"
                                          //  value={this.state.msds_file_name} 
                                          className="form-control requestFields form-input-box" onChange={this.uploadBOLFile} />
                                          {/* <FileBase64
                                          onDone={ this.changeFileHandler.bind(this) } /> */}
                                           {((this.state.bill_of_lading_file_name != "") && (this.state.bill_of_lading_file_name != undefined)) ?  
                                              <div className="row col-xl-12">
                                                  <div className="col-xl-10">
                                                      <a className={"rnFile_6"}>{this.state.bill_of_lading_file_name}</a> 
                                                  </div>
                                                  <div className="col-xl-1">
                                                      <p className={"rnFile_6"} style={{cursor:"pointer"}} 
                                                      onClick={() => this.downloadRingiFileoneTime6()}
                                                      ><i title="Download" className="fa fa-download"></i></p>
                                                      
                                                      {/* <a target="_blank" href={require("../../../../api/routes/uploads/"+(item.invoice_file))}>Test </a> */}
                                                  </div>
                                                  <div className="col-xl-1">
                                                      <p className={"rnFile_6"} style={{cursor:"pointer"}}
                                                       onClick={() => this.handleRemoveRingiFile6()}
                                                      ><i title="Delete" className="fa fa-trash"></i></p>
                                                  </div>
                                                  <input type="hidden" id={"hiddenRingiFile_6"} value={this.state.bill_of_lading_file_name} />
                                              </div>
                                              :
                                              ""
                                              }
                                      </div>
                                   }
                                      
                                    </div>
                                    <div className="row col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-booking" style={{ marginLeft: "45%", width: "192px", marginTop: "20px" }}>Submit</button>
                                    </div>
                                </form>
                                    } 
                                    
     
                            </div>
                        </div>
                    </div>
                  
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        )
    }
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_date').datetimepicker({
        //mask:'39-19-9999',
        format: 'd-m-Y',
        minDate: true,
        timepicker: false,
        closeOnDateSelect: true,
        scrollMonth: false,
        scrollInput: false
    });

    $('.cargo_date').datetimepicker({
        //mask:'39-19-9999',
        format: 'd-m-Y',
        minDate: true,
        timepicker: false,
    });

    $('.etd_onetime').datetimepicker({
        //mask:'39-19-9999',
        format: 'd-m-Y',
        minDate: true,
        timepicker: false,
    });


    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    script.crossOrigin = "anonymous"
    index.parentNode.insertBefore(script, index)
}

// $('body').on('focus',".datetimepicker_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false
// 	});
// });

// $('body').on('focus',".cargo_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false,

// 	});
// });

$(document).on("input", ".num-cls", function() {
    this.value = this.value.replace(/\D/g,'');
})
$(document).on("input", ".mobnum-cls", function() {
    this.value = this.value.replace(/^\d{11}$/g,'');
})
$(document).on("input", ".alfa-cls", function () {
    this.value = this.value.replace(/[^a-z\s]+$/gi, '');
  });
  $(document).on("input", ".dept-cls", function () {
    this.value = this.value.replace(/[^a-z-\s]/gi, '');
  });
  $(document).on("input", ".ringi-cls", function () {
    this.value = this.value.toUpperCase();
    this.value = this.value.replace(/[^\d[A-Z]-\s]/gi, '');
  });
$(document).on("change", "#cargoDate", function (e) {
    e.preventDefault();
    var bookingDate = $("#dateField").val();
    var cargoDate = $("#cargoDate").val();
    var bookingDate = bookingDate.split("-")[2] + "-" + bookingDate.split("-")[1] + "-" + bookingDate.split("-")[0]
    var cargoDate = cargoDate.split("-")[2] + "-" + cargoDate.split("-")[1] + "-" + cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    console.log(startTime, endTime);
    if (startTime >= endTime) {
        $("#cargoDate").val("");
        alert("Date should be greater than Booking Date");
    }
})
const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email.trim());
};

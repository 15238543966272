import React, { Component } from 'react';
import Highcharts from 'highcharts';
 
class DemandBarChart extends Component {
  componentDidMount() {
    this.renderChart();
  }
  componentWillReceiveProps(){
    this.renderChart();
    // console.log(prevProps)
  }

 
  renderChart() {
    const { data } = this.props;
    // console.log(data,'prop')
 
    const chartOptions = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'Load Comparison',
      },
      xAxis: {
        categories: data.map(obj => obj.vehicle_type),
        title: {
          text: 'Vehicle Type',
        },
      },
      yAxis: {
        title: {
          text: 'Load (CMT)',
        },
      },
      series: [
        {
          name: 'Manual Load (CMT)',
          data: data.map(obj => obj.demand_manual),
        },
        {
          name: 'System Load (CMT)',
          data: data.map(obj => obj.demand_system),
        },
      ],
      credits:false
    };
 
    Highcharts.chart('chart-containerdemand', chartOptions);
  }
 
  render() {
    return <div id="chart-containerdemand" style={{ width: '100%', height: '400px' }}></div>;
  }
}
 
export default DemandBarChart;
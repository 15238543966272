/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import redirectURL from '../redirectURL';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import { getHyphenDDMMYYYY } from '../common/utils';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");


export default class AddCustomBond extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            csvcontent: "",
            file: "",
            loadshow: 'show-n',
            overly: 'show-n',
            uploadYard: "",
            uploadDispatchDate: "",
            rowData: "",
            rowSelection: 'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize: 50,
            geofencelist: [],
            geoFenceData: "",
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            dept_code: "",
            pagetitle: "",
            bulkslide: "",
            certOfOrigin: "",
            dg: "",
            date: "",
            deptName: "",
            indenterName: "",
            mobile: "",
            indenterEmail: "",
            ringiNo: "",
            party_name: "",
            cargoDeliveryPlant: "",
            itemDesc: "",
            grossWt: "",
            dimensions: "",
            cargo_chargeable_weight: "",
            supplierName: "",
            supplierAddr: "",
            supplierCnctPerson: "",
            country: "",
            destinationAirport: "",
            emailID: "",
            phone: "",
            deliveryTerms: "Ex Works",
            pickUpAddr: "",
            cargoDate: "",
            commercialInvoiceDate: "",
            commercialInvoice: "",
            nature: "",
            payment_terms: "",
            export_incentive: "",
            shipmentReturn: "",
            deliveryLabel: "Pick Up Address",
            airportName: "",
            port: "",
            cargo_date: "",
            rows: [{}],
            ringirows: [{}],
            packetrows: [{}],
            brequest: "activet",
            prepaid: "",
            track: "",
            requestData: "show-m",
            trackData: "show-n",
            trackResData: 'show-n',
            ffsuppliers: [],
            requestFields: "show-m",
            prepaidFields: "show-n",
            addresstxt: "",
            request_type: 1,
            invoice_file: "",
            no_of_bond_extensions: "",
            sqc_list_file: "",
            annex_file: "",
            gr_wavier_file: "",
            msds_file: "",
            iip_file: "",
            rowId : "",
            showDiv : "show-n"
            //components : {datepicker:getDatePicker()}
        }
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount() {
        loadDateTimeScript();
        console.log("props", this.props.match)
        let props =  window.location.pathname.split("/")
        // console.log("a", props)
        if(props[2] !== undefined){
            var rowId = window.atob(props[2]);
            // console.log("props", rowId)
        }
        if(rowId !== undefined && rowId !== ""){
            this.setState({
                rowId : rowId
            });
            var reqparams = {
                rowId : rowId,
            }
            // console.log("reqparams",reqparams)
            redirectURL.post("/consignments/getCustomBondDetails",reqparams).then(resp =>{
                let respData = resp.data
                // console.log("resp", respData)

                var bond_date = $("#bond_date").val(getHyphenDDMMYYYY(respData[0].bond_date));
                var bond_start_date = $("#bond_start_date").val(getHyphenDDMMYYYY(respData[0].bond_start_date));
                var bond_end_date  = $("#bond_end_date ").val(getHyphenDDMMYYYY(respData[0].bond_end_date));
                var bond_cancellation_date  = $("#bond_cancellation_date ").val(getHyphenDDMMYYYY(respData[0].bond_cancellation_date));
                $("#bond_no").val(respData[0].bond_no)
                $("#port_of_registration").val(respData[0].port_of_registration)
                $("#type_of_bond").val(respData[0].type_of_bond)
                $("#purpose_of_bond").val(respData[0].purpose_of_bond)
                $("#bond_val_in_rps").val(respData[0].bond_val_in_rps)
                $("#no_of_bond_extensions").val(respData[0].no_of_bond_extensions)

                this.setState({
                    bond_no : respData[0].bond_no,
                    port_of_registration : respData[0].port_of_registration,
                    type_of_bond: respData[0].type_of_bond,
                     purpose_of_bond : respData[0].purpose_of_bond,
                     bond_val_in_rps : respData[0].bond_val_in_rps,
                     no_of_bond_extensions : respData[0].no_of_bond_extensions,
                     bond_date,bond_start_date,bond_end_date,bond_cancellation_date,
                     showDiv : "show-m"
                })
            })

        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };


    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onClickHideAll = () => {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            bulkslide: ''
        });
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    changeHandler = (event) => {
        // console.log("Select", event.target.value);
        let name = event.target.name;
        // console.log("name",name);
        let value = event.target.value.replace(/[^a-z0-9 .]/gi, '')
        
        this.setState({ [name]: value });
    }
    dateValidation = (currentDate) => {
        return currentDate.isBefore(moment(this.state.deliverymaxDate)) && currentDate.isAfter(moment(this.state.deliveryminDate));
    };
    changeFileHandler = async (e) => {
        console.log(e.target.name)
        this.setState({ [e.target.name]: e.target.files[0] });
    }

    formHandler = (event) => {
        event.preventDefault();

        const {bond_no, port_of_registration,type_of_bond, purpose_of_bond,bond_val_in_rps,no_of_bond_extensions} = this.state

        var bond_date = $("#bond_date").val();
        var bond_start_date = $("#bond_start_date").val();
        var bond_end_date  = $("#bond_end_date ").val();
        // var bond_cancellation_date  = $("#bond_cancellation_date ").val();

        if(new Date(bond_start_date) < new Date(bond_date)){
            console.log("enter")
            return alert("Bond Date should be less than Bond Start Date")
        }else{
            if(new Date(bond_end_date) < new Date(bond_start_date)){
                console.log("enter")
                return alert("Bond Start Date should be less than Bond End Date")
            }else{
                // console.log("bond_no", bond_no,"\n bond_date",bond_date,"\n port_of_registration", port_of_registration,"\n type_of_bond",type_of_bond, "\n purpose_of_bond",purpose_of_bond,"\n bond_val_in_rps",bond_val_in_rps,"\n bond_start_date",bond_start_date,"\n bond_end_date",bond_end_date, "\n bond_cancellation_date",bond_cancellation_date,"\n no_of_bond_extensions", no_of_bond_extensions)
        
                var params = {      
                    bond_no, bond_date,port_of_registration,
                    type_of_bond,purpose_of_bond,bond_val_in_rps,
                    bond_start_date,bond_end_date,
                    // bond_cancellation_date,
                    no_of_bond_extensions
                }
                // console.log(params,"281")
                if (bond_no !== "" && bond_date !== "" && port_of_registration!== "" && type_of_bond !== "" &&  purpose_of_bond!== "" &&  bond_val_in_rps !=="" && bond_start_date!== "" && bond_end_date !== "") {
                    this.setState({
                        loadshow: 'show-m',
                        overly: 'show-m',
                    })
                    // console.log(ringiNo)
                    let saveData = new FormData();
                    for (let item in params){
                        saveData.append(item, params[item])
                    }
                    // we are using same api for inserting new as well as editing the existing records with rowId
                    if(this.state.rowId !== ""){
                        saveData.append("rowId",this.state.rowId)
                    }

                    saveData.append("created_by", localStorage.getItem("email"));
                    saveData.append("created_by_user", localStorage.getItem("username"));

                    redirectURL.post("/consignments/saveCustomBondDetails", saveData, {
                        headers: {
                            'content-type': 'multipart/form-data',
                            // 'Access-Control-Allow-Origin': "*",
                            // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
                            'Access-Control-Allow-Headers': 'Origin, Content-Type'
                        }
                    }).then((response) => {
                        this.setState({
                            basicTitle: "Success",
                            basicType: "success",
                            show: true,
                            loadshow: 'show-n',
                            overly: 'show-n',
                            rowId : ""
                        });
                        setTimeout(async () => {
                            window.location.href = "/customsbondmonitoring";
                        }, 2000)
                    }).catch((e) => {
                        console.log(e);
                    })

                }
                else {
                    this.setState({
                        basicTitle: "Fields Should not be Empty",
                        basicType: "warning",
                        show: true
                    })
                }
            }
        }

        
       
    }

    handleRemoveRow = (idx) => {
        this.state.rows.splice(idx, 1);
        this.setState({ rows: this.state.rows });
    };

    handleAddRingiRow = () => {
        const item = {
            party_name: "",
        };
        this.setState({
            ringirows: [...this.state.ringirows, item]
        });
    };
    handleRemoveRingiRow = (idx) => {
        this.state.ringirows.splice(idx, 1);
        this.setState({ ringirows: this.state.ringirows });
    };

    packetsHandler = (e) => {
        var noOfPackets = e.target.value;
        if (parseInt(noOfPackets) >= 1) {
            var lespcks = parseInt(noOfPackets) - 1;
            var bhanu = [{}];
            for (var n = 0; n < lespcks; n++) {
                const item = {
                    itemDesc: "",
                    grossWt: "",
                    dimensions: "",
                    cargo_chargeable_weight: "",
                };
                this.setState({
                    packetrows: [...bhanu, item]
                });
                var bhanu = [...bhanu, item];
            }
        }
        else {
            alert("No of packets should be greater than 0");
            $("#no_of_packets").val(1);
            const item = {
                itemDesc: "",
                grossWt: "",
                dimensions: "",
                cargo_chargeable_weight: "",
            };
            this.setState({
                packetrows: [item]
            });
        }
    }

    onClickTab(pageType) {
        if (pageType == "brequest") {
            var brequest = "activet";
            var ffupdates = "";
            var track = "";
            var requestData = "show-m";
            var trackData = "show-n";
            var requestFields = "show-m";
            var prepaidFields = "show-n";
            var addresstxt = "";
            var request_type = 1;
            var pagetitle = "Booking Request (Air Exports)";
            $(".requestFields").attr("required", true);
            $(".prepaidFields").removeAttr("required", true);
        }
        else if (pageType == "prepaid") {
            var brequest = "";
            var prepaid = "activet";
            var track = "";
            var requestData = "show-m";
            var trackData = "show-n";
            var requestFields = "show-n";
            var prepaidFields = "show-m";
            var addresstxt = "(To be mentioned on BOE)";
            var request_type = 2;
            var pagetitle = "Booking Request For Prepaid Shipments (Air Exports)";
            $(".requestFields").removeAttr("required", true);
            $(".prepaidFields").attr("required", true);
        }
        else if (pageType == "track") {
            var brequest = "";
            var prepaid = "";
            var track = "activet";
            var requestData = "show-n";
            var trackData = "show-m";
            var requestFields = "show-n";
            var prepaidFields = "show-n";
            var addresstxt = "";
            var request_type = "";
            var pagetitle = "";
        }
        this.setState({
            brequest: brequest,
            prepaid: prepaid,
            track: track,
            requestData: requestData,
            trackData: trackData,
            requestFields: requestFields,
            prepaidFields: prepaidFields,
            addresstxt: addresstxt,
            request_type: request_type,
            pagetitle: pagetitle
        })
    }

    trackHandler(event) {
        event.preventDefault();
        var invoice_no = $("#invoice_no").val();
        if (invoice_no != undefined && invoice_no != '') {
            this.setState({
                loadshow: 'show-m',
                overly: 'show-m',
            })
            var params = {
                invoice_no: invoice_no
            }
            redirectURL.post("/consignments/getAirCargoExportsStatus", params)
                .then((response) => {
                    var records = response.data;
                    this.setState({
                        rowData: records,
                        trackResData: 'show-m',
                        loadshow: 'show-n',
                        overly: 'show-n',
                    })
                })
                .catch(function (e) {
                    console.log("Error ", e)
                })
        }
        else {
            this.setState({
                basicTitle: "Please enter Indenter Email",
                basicType: "warning",
                show: true
            })
        }
    }

    render() {
        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    <div className={"col-xl-12 col-lg-12"}>
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <span>Add Customs Bond</span>
                                    <a href="/customsbondmonitoring" className="btn btn-danger" style={{ float: "right" }}>Back</a>
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                                <form class="aircargo-form" onSubmit={this.formHandler} encType="multipart/form-data">
                                    <div className="row col-xl-12">

                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Bond No* : </label>
                                            <input type="number" name="bond_no" id="bond_no" className="form-control" onChange={this.changeHandler} required />
                                        </div>
                                        <div className={"col-xl-4"}>
                                            <label className="col-xl-12 col-lg-12">Bond Date* : </label>
                                            <input type="text" name="bond_date" id="bond_date" className="datetimepicker_date form-control requestFields" onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Port of registration* : </label>
                                            <input type="text" name="port_of_registration" id="port_of_registration" className="form-control" onChange={this.changeHandler} required />
                                        </div>
                                    </div>

                                    <div class={"hr-dashed "}></div>
                                    <div className="row col-xl-12">                                                                  
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Type of bond* : </label>
                                            <input type="text" name="type_of_bond" id="type_of_bond" className="form-control" onChange={this.changeHandler} required />
                                            {/* <select class="form-control col-xl-12 col-lg-12" name="type_of_bond" id="type_of_bond" required onChange={this.changeHandler}>
                                                <option value="">-- Select Option --</option>
                                                <option value="N/A">N/A</option>
                                                <option value="Monthly">Monthly</option>
                                                <option value="Quarterly">Quarterly</option>
                                                <option value="Half yearly">Half yearly</option>
                                                <option value="Half yearly except 1st month">Half yearly except 1st month</option>
                                                <option value="6 months">6 months</option>
                                                <option value="8 months">8 months</option>
                                                <option value="9 months">9 months</option>
                                                <option value="Annually">Annually</option>
                                                <option value="Bi-Annual">Bi-Annual</option>
                                                <option value="2 years">2 years</option>
                                                <option value="After 2 years">After 2 years</option>
                                                <option value="After 3 years">After 3 years</option>
                                                <option value="After 5 years">After 5 years</option>
                                            </select> */}
                                        </div>
                                         <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Purpose of bond* : </label>
                                            <input type="text" name="purpose_of_bond" id="purpose_of_bond" className="form-control" onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Bond Value in Rs: </label>
                                            <input type="number" name="bond_val_in_rps" id="bond_val_in_rps" className="form-control" onChange={this.changeHandler} required />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">                                    
                                        <div className={"col-xl-4" }>
                                            <label className="col-xl-12 col-lg-12">Bond Start Date* : </label>
                                            <input type="text" name="bond_start_date" id="bond_start_date" className="datetimepicker_date form-control requestFields" onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-4"}>
                                            <label className="col-xl-12 col-lg-12">Bond End Date* </label>
                                            <input type="text" name="bond_end_date" id="bond_end_date" className="datetimepicker_date form-control requestFields" onChange={this.changeHandler} />
                                        </div>
                                        {/* <div className={"col-xl-4 " + this.state.showDiv}>
                                            <label className="col-xl-12 col-lg-12">Bond Cancellation Date </label>
                                            <input type="text" name="bond_cancellation_date" id="bond_cancellation_date" className="datetimepicker_date form-control requestFields" onChange={this.changeHandler} />
                                        </div> */}
                                          <div className={"col-xl-4 " + this.state.showDiv}>
                                            <label className="col-xl-12 col-lg-12">No. of Bond Extensions* : </label>
                                            <input type="number" name="no_of_bond_extensions" id="no_of_bond_extensions" className="form-control requestFields" onChange={this.changeHandler} readOnly />
                                        </div>   
                                    </div>
                                   {/* <div class="hr-dashed"></div>
                                        <div className="row col-xl-12">
                                                                        
                                        </div>
                                     */}
                                    <div className="row col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-success" style={{ marginLeft: "45%", width: "192px", marginTop: "20px" }}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll}></div>
            </div>
        )
    }
}

function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_date').datetimepicker({
        //mask:'39-19-9999',
        format: 'd-m-Y',
        minDate: false,
        timepicker: false,
    });

    $('.cargo_date').datetimepicker({
        //mask:'39-19-9999',
        format: 'd-m-Y',
        minDate: false,
        timepicker: false,
    });

    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    script.crossOrigin = "anonymous"
    index.parentNode.insertBefore(script, index)
}

// $('body').on('focus',".datetimepicker_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false
// 	});
// });

// $('body').on('focus',".cargo_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false,

// 	});
// });

$(document).on("input", ".num-cls", function () {
    this.value = this.value.replace(/\D/g, '');    
});

// $(document).on("change", "#bond_end_date", function (e) {
//     e.preventDefault();
//     try{
//         var startDate = $("#bond_start_date").val();
//         var bondEndDate = $("#bond_end_date").val();
//         startDate = startDate.split("-")[2] + "-" + startDate.split("-")[1] + "-" + startDate.split("-")[0]
//         bondEndDate = bondEndDate.split("-")[2] + "-" + bondEndDate.split("-")[1] + "-" + bondEndDate.split("-")[0]

//         var startDate = new Date(startDate);
//         var endDate = new Date( bondEndDate);
//         var startTime = startDate.getTime();
//         var endTime = endDate.getTime();
//         // console.log(startTime,endTime);
//         if (startTime >= endTime) {
//             $("#bond_end_date").val("");
//             alert("EndDate should be greater than Start Date");
//         }
//         else if(endDate <= new Date()){
//             $("#bond_end_date").val("");
//             alert("EndDate should be greater than Current Date");
//         }
//     }catch(e){

//     }   
// })

// $(document).on("change", "#bond_date", function (e) {
//     e.preventDefault();
//     try{
//         var startDate = $("#bond_date").val();
//         var bondEndDate = $("#bond_start_date").val();
//         startDate = startDate.split("-")[2] + "-" + startDate.split("-")[1] + "-" + startDate.split("-")[0]
//         bondEndDate = bondEndDate.split("-")[2] + "-" + bondEndDate.split("-")[1] + "-" + bondEndDate.split("-")[0]

//         var startDate = new Date(startDate);
//         var endDate = new Date( bondEndDate);
//         var startTime = startDate.getTime();
//         var endTime = endDate.getTime();
//         // console.log(startTime,endTime);
//         if (startTime > endTime) {
//             $("#bond_date").val("");
//             alert("Bond date should be Less than Start Date");
//         }
//         // else if(endDate <= new Date()){
//         //     $("#bond_end_date").val("");
//         //     alert("EndDate should be greater than Current Date");
//         // }
//     }catch(e){

//     }   
// })

// $(document).on("change", "#bond_start_date", function (e) {
//     e.preventDefault();
//     try{
//         var startDate = $("#bond_start_date").val();
//         var bondEndDate = $("#bond_end_date").val();
//         startDate = startDate.split("-")[2] + "-" + startDate.split("-")[1] + "-" + startDate.split("-")[0]
//         bondEndDate = bondEndDate.split("-")[2] + "-" + bondEndDate.split("-")[1] + "-" + bondEndDate.split("-")[0]
//         var startDate = new Date(startDate);
//         var endDate = new Date( bondEndDate);
//         var startTime = startDate.getTime();
//         var endTime = endDate.getTime();
//         // console.log(startTime,endTime);
//         if (startTime <= endTime) {
//             $("#bond_start_date").val("");
//             alert("EndDate should be greater than Start Date");
//         }
//     }catch(e){

//     }
   
// })

// $(document).on("change", "#bond_start_date", function (e) {
//     e.preventDefault();
//     try{
//         var startDate = $("#bond_date").val();
//         var bondEndDate = $("#bond_start_date").val();
//         startDate = startDate.split("-")[2] + "-" + startDate.split("-")[1] + "-" + startDate.split("-")[0]
//         bondEndDate = bondEndDate.split("-")[2] + "-" + bondEndDate.split("-")[1] + "-" + bondEndDate.split("-")[0]
//         var startDate = new Date(startDate);
//         var endDate = new Date( bondEndDate);
//         var startTime = startDate.getTime();
//         var endTime = endDate.getTime();
//         // console.log(startTime,endTime);
//         if (startTime > endTime) {
//             $("#bond_start_date").val("");
//             alert("Start Date should be greater than Bond Date");
//         }
//     }catch(e){

//     }
   
// })


// $('input').on('input', function() {
//     var c = this.selectionStart,
//         r = /[^a-z0-9 .]/gi,
//         v = $(this).val();
//     if(r.test(v)) {
//       $(this).val(v.replace(r, ''));
//       c--;
//     }
//     this.setSelectionRange(c, c);
//   });

$(document).on("input", "#port_of_registration", function (e) {
        var c = this.selectionStart,
        r = /[^a-z0-9 .]/gi,
        v = $(this).val();
        if(r.test(v)) {
        alert("No Special Characters allowed");
        // $(this).val(v.replace(r, ''));
        this.value = this.value.replace(/\D/g, '');
        console.log("c",c)
        c--;
        }
        // this.setSelectionRange(c, c);
    // var regex = new RegExp("^[0-9-!@#$%*?]");
    // var key = String.fromCharCode(e.charCode ? e.which : e.charCode);
    // if (!regex.test(key)) {
    //     e.preventDefault();
    //     return false;
    // }
});


$(document).on("input", "#type_of_bond", function (e) {
    var c = this.selectionStart,
    r = /[^a-z0-9 .]/gi,
    v = $(this).val();
    if(r.test(v)) {
    alert("No Special Characters allowed");
    // $(this).val(v.replace(r, ''));
    this.value = this.value.replace(/\D/g, '');
    console.log("c",c)
    c--;
    } 
});

$(document).on("input", "#purpose_of_bond", function (e) {
    var c = this.selectionStart,
    r = /[^a-z0-9 .]/gi,
    v = $(this).val();
    if(r.test(v)) {
    alert("No Special Characters allowed");
    // $(this).val(v.replace(r, ''));
    this.value = this.value.replace(/\D/g, '');
    console.log("c",c)
    c--;
    } 
});

// $('input').on('input', function() {
//     $(this).val($(this).val().replace(/[^a-z0-9]/gi, ''));
//   });
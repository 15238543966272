import React, { Component } from 'react';
// import {Tab, Tabs} from 'react-tabs'
import { TabContent, TabPane, Nav, NavItem, NavLink,Row, Col } from 'reactstrap';
import classnames from 'classnames';

import AccidentalProneZonesTab from './accidentalProneZones'
import AccidentalProneZoneMap from './accidentalProneZoneMap'


export default class AccidentalProneZones extends Component{

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			active_tab: '1',
            active_tab_bottom_left: '1',
            active_tab_icon: '1',
            active_tab_right_align: '1',
            active_tab_vertical: '1',
            active_tab_pill: '1',
            active_tab_pill_icon: '1',
            active_tab_dark_color: '1',
            active_tab_primary: '1',
            active_tab_secondary: '1',
			isRoleExists:0,
			adaniCheck:0,
        };
        
		this.toggle = this.toggle.bind(this);
	}

    toggle(tab) {
        if (this.state.active_tab !== tab) {
            this.setState({
                active_tab: tab
            });
        }

    }

    render(){
        return(
            <div className="container-fluid">
                <div className=" row">
                    <div className="col-xl-12 col-lg-12">                        
                            {/* <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
                                <Tab eventKey="home" title="accidentalProneZone">
                                    <AccidentalProneZonesTab />
                                </Tab>
                                <Tab eventKey="profile" title="Profile">
                                    <AccidentalProneZoneMap />
                                </Tab>                            
                            </Tabs>       */}


                         <Nav tabs className={'tabs-color'}>
                            <NavItem >
                                <NavLink
                                    className={classnames({ active: this.state.active_tab === '1' })}
                                    onClick={() => { this.toggle('1'); }} >
                                    AccidentalProneZones
                                </NavLink>
                            </NavItem>                  
                            <NavItem>                            
                                <NavLink className={classnames({ active: this.state.active_tab === '2' })}
                                            onClick={() => { this.toggle('2'); }} >
                                    Map View
                                </NavLink>
                            </NavItem>
                           
                            
                        </Nav>

                        <TabContent activeTab={this.state.active_tab}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col sm="12">
                                        <AccidentalProneZonesTab />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col sm="12">
                                    <AccidentalProneZoneMap />
                                    </Col>
                                </Row>
                            </TabPane>                           
                        </TabContent>
                    </div>
                </div >
           </div>
        );
    }
}

        
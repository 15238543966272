'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 

// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class CarrierGrid extends Component {
  constructor(props) {
    super(props);
    this.colWidth = 80;
    this.rowHeight = 18;
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        latLngArray : [],
        mapShow : 0,
        sliderTitle : "Bulk Upload",
        statesList : [],
        locInfo: "",
        pageAccess: "",
        gridApi: "",
        gridColumnApi: "",
        transporterId:{"label": "R. SAI LOGISTICS INDIA PVT LTD","value": "T268"},
        fYear:{
            "label":"2019-2020",
            "value":"2019-2020",
        },
        rowData:[],
        columnwithDefs:[],
    }
    this.onCellClicked = this.onCellClicked.bind(this)
}

componentDidMount(){
    var transporterId = this.props.transporterId;
    var fYear = this.props.fYear;
    var rowData = this.props.carrierData;
    var dataType = this.props.dataType;
    console.log(dataType,"dataType")
    this.createColumns(transporterId,fYear,rowData,dataType);
}

componentWillReceiveProps(newprops){
    var transporterId = newprops.transporterId;
    if(transporterId.value != this.state.transporterId.value)
    {
        var rowData = newprops.carrierData;
        var fYear = newprops.fYear;
        var dataType = newprops.dataType;
        console.log(rowData,"rowData111")
        for (let i = 0; i < rowData.length; i++) {
            for (const prop in rowData[i]) {
              if (typeof rowData[i][prop] === 'number' && rowData[i][prop] % 1 !== 0 && rowData[i][prop].toFixed) {
                rowData[i][prop] = Number(rowData[i][prop].toFixed(2));
              }
            }
          }
        console.log(rowData,"modifiedrowData1")
        console.log(transporterId.value,this.state.transporterId.value,"willreci")
        this.createColumns(transporterId,fYear,rowData,dataType);
    }
    else
    {
        var rowData = newprops.carrierData;
        var fYear = newprops.fYear;
        var dataType = newprops.dataType;
        console.log(rowData,"rowData2222")
        for (let i = 0; i < rowData.length; i++) {
            for (const prop in rowData[i]) {
              if (typeof rowData[i][prop] === 'number' && rowData[i][prop] % 1 !== 0 && rowData[i][prop].toFixed) {
                rowData[i][prop] = Number(rowData[i][prop].toFixed(2));
              }
            }
          }
        console.log(rowData,"modifiedrowData2")
        console.log(transporterId.value,this.state.transporterId.value,"willreci")
        this.createColumns(transporterId,fYear,rowData,dataType);
    }
}
componentDidUpdate(){
    
}

createColumns(transporterId,fYear,rowData,dataType)
{
    this.setState({
        transporterId : transporterId,
        fYear : fYear,
        rowData : rowData,
        dataType : dataType
    });
    var columns=[]
    var defs=[];
    var keys = Object.keys(rowData[0]);
    const rowLength = rowData.length;
    var cnt = 0;
    var cFydata = [];
    keys.forEach((z, k) => {
        var hideCol = false;
        if(z == "plant_code")
        {
            var hideCol = true;
        }
        if(z == "plant")
        {
            var keyVal = "PLANT";
            var hwid = "200";
            var pinnedLeft = "left";
            var isEditable=false;
        }
        else if(z == "route")
        {
            var keyVal = "ROUTE";
            var hwid = "130";
            var pinnedLeft = "left";
            var isEditable=false;
        }
        else if(z == "parameters")
        {
            var keyVal = "PARAMETERS";
            var hwid = "220";
            var pinnedLeft = "left";
            var isEditable=false;
        }
        else
        {
            var keyVal = z;
            var hwid = "90";
            var pinnedLeft="";
            var isEditable=true;
        }
        var check = 1;
        if(z.includes("FY") == true)
        {
            console.log(z,cnt,"includesFY");
            if(cnt == 0)
            {
                check = 1;
                pinnedLeft = "left";
            }
            else
            {
                check = 0;
                cFydata.push({
                    headerName:keyVal,
                    field:z,
                    width:hwid,
                    pinned:pinnedLeft,
                    editable:isEditable,
                    hide:hideCol,
                    valueGetter:function(params){
                        return params.data[z];
                    },
                    cellClass: function(params){
                        if(z == "plant")
                        {
                            if(params.data[z] != "")
                            {
                                return ["cellstylegridY","pstBg"]
                            }
                        }
                    },
                    cellStyle: params => {
                        if(z == "plant")
                        {
                            if(params.data[z] != "" && params.data[z] !="GUJARAT")
                            {
                                return {borderBottom: '1px solid #ccc'};
                            }
                            return null;
                        }
                        return null;                    
                    },
                    rowSpan: function(params){
                        if(z == "plant")
                        {
                            if(params.data[z] != "")
                            {
                                if(dataType == 2)
                                {
                                    return 7;
                                }
                                else
                                {
                                    return 6;
                                }
                            }
                        }
                    },
                    cellEditor: NumericCellEditor,
                })
            }
            cnt = parseInt(cnt)+1;
        }
        if(check == 1)
        {
            columns.push({
                headerName:keyVal,
                field:z,
                width:hwid,
                pinned:pinnedLeft,
                editable:isEditable,
                hide:hideCol,
                valueGetter:function(params){
                    return params.data[z];
                },
                cellClass: function(params){
                    if(z == "plant")
                    {
                        if(params.data[z] != "")
                        {
                            return ["cellstylegridY","pstBg"]
                        }
                    }
                },
                cellStyle: params => {
                    if(z == "plant")
                    {
                        if(params.data[z] != "" && params.data[z] !="GUJARAT")
                        {
                            return {borderBottom: '1px solid #ccc'};
                        }
                        return null;
                    }
                    return null;                    
                },
                rowSpan: function(params){
                    if(z == "plant")
                    {
                        if(params.data[z] != "")
                        {
                            if(dataType == 2)
                            {
                                return 7;
                            }
                            else
                            {
                                return 6;
                            }
                        }
                    }
                },
                cellEditor: NumericCellEditor,
            })
        }
    })          
    if(cFydata.length > 0)
    {
        columns.push(cFydata[0]);
    }
    console.log("columns ", columns)
    this.setState({
        columnwithDefs:columns
    })
    
}

onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
    this.setState({
        gridApi : params.api,
        gridColumnApi : params.columnApi,
    });
};
onGridState = () =>{
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
    * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
            
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    this.setState({
        show: false
    });
}

onCellClicked(e)
{
    if(e.colDef.field == "container_no")
    {
        console.log(e.data.container_no);
    }
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    
}

showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}

onCellTrainingUpdateData(e){
    //console.log(e,"clickedData")
    var month = e.colDef.field;
    //var plant_code = e.data.plant_code;
    var dataType = this.state.dataType;
    var transporter_code = this.state.transporterId.value;
    var year = this.state.fYear.value;
    var parameter = e.data.route;
    var editedValue = e.value;
    if(editedValue !=undefined && editedValue !='')
    {
        var params = {
            "dataType" : dataType,
            "year" : year,
            "month" : month,
            //"plant_code" : plant_code,
            "transporter_code" : transporter_code,
            "parameter" : parameter,
            "editedValue" : editedValue
        }
        console.log(params,"params")
        redirectURL.post("/consignments/saveTransporterCarrier",params)    
        .then(async (response) => {

        })
        .catch(function(e){
            console.log("Error ",e)
        })
    }
}
 
  render() {
    
    return (
        <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.showGridData}>    
            <AgGridReact
                modules={this.state.modules}
                columnDefs={this.state.columnwithDefs}
                defaultColDef={this.state.defaultColDef}
                rowData={this.state.rowData}
                enableCharts={false}
                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                onGridReady={this.onGridReady}
                onGridState={this.onGridState}
                frameworkComponents={this.state.frameworkComponents}
                statusBar={this.state.statusBar}
                sideBar={this.state.sideBar}
                stopEditingWhenGridLosesFocus= {true}
                paginationPageSize={this.state.paginationPageSize}
                pagination={false}
                rowClassRules={this.state.rowClassRules}
                gridOptions={{
                    context: { componentParent: this },
                    getRowStyle: function (params) {
                            if (params.node.rowPinned) {
                        return { 'font-weight': 'bold','font-size':'16px' };
                        }
                    },
                    suppressRowTransform: true,
                }}
                // components={this.state.components}
                enableRangeSelection= {true}
                // onCellClicked={this.onCellClicked.bind(this)}
                onCellEditingStopped={this.onCellTrainingUpdateData.bind(this)}
                />
        </div>
    );
  }
}

function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function alphaNumericEditor() {
}

// gets called once before the renderer is used
alphaNumericEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharAlphaNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedAlphaNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function isCharAlphaNumeric(charStr) {
    return !!/^[A-Za-z0-9 '.-]+$/.test(charStr);
}

function isKeyPressedAlphaNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharAlphaNumeric(charStr)
}

alphaNumericEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
alphaNumericEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
alphaNumericEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
alphaNumericEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
alphaNumericEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
alphaNumericEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
alphaNumericEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
alphaNumericEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

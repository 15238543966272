import React, { Component } from 'react';
import { render } from 'react-dom';

export default class TransitTimeAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedYear: '2024-2025', // Default year
      iframeSrc: 'https://app.powerbi.com/view?r=eyJrIjoiODVmOWM1MjktMzJhNS00YmU3LWFjYjUtYTUzMDJmZWVlNTY3IiwidCI6IjJiNzlhMzk1LWUwNDEtNDcyMy04YzBkLWJlMTA1YTY1NmI1YiJ9' // Default iframe source
    };
  }

  // Function to handle dropdown change
  handleYearChange = (event) => {
    const selectedYear = event.target.value;
    let iframeSrc = '';

    // Set iframe source based on selected year
    switch (selectedYear) {
      case '2022-2023':
        iframeSrc = 'https://app.powerbi.com/view?r=eyJrIjoiNWUzNjUxYTgtYTM0OC00ZTM5LWE5NmEtYTIzZTE3NDk2N2M1IiwidCI6IjJiNzlhMzk1LWUwNDEtNDcyMy04YzBkLWJlMTA1YTY1NmI1YiJ9';
        break;
      case '2023-2024':
        iframeSrc = 'https://app.powerbi.com/view?r=eyJrIjoiMDZmNzA1NDktOWJjNS00ZDc0LTg3NGQtMzAxZDRjOGI0ZTRjIiwidCI6IjJiNzlhMzk1LWUwNDEtNDcyMy04YzBkLWJlMTA1YTY1NmI1YiJ9';
        break;
      case '2024-2025':
        iframeSrc = 'https://app.powerbi.com/view?r=eyJrIjoiODVmOWM1MjktMzJhNS00YmU3LWFjYjUtYTUzMDJmZWVlNTY3IiwidCI6IjJiNzlhMzk1LWUwNDEtNDcyMy04YzBkLWJlMTA1YTY1NmI1YiJ9';
        break;
      default:
        iframeSrc = 'https://app.powerbi.com/view?r=eyJrIjoiODVmOWM1MjktMzJhNS00YmU3LWFjYjUtYTUzMDJmZWVlNTY3IiwidCI6IjJiNzlhMzk1LWUwNDEtNDcyMy04YzBkLWJlMTA1YTY1NmI1YiJ9';
        break;
    }

    this.setState({
      selectedYear,
      iframeSrc
    });
  };

  render() {
    return (
      <div className="container-fluid">
         <div className="col-md-5">
           <div className="form-group">                                   
           <select 
              value={this.state.selectedYear} 
              onChange={this.handleYearChange}
              className="form-control"
              style={{ width: '200px', marginLeft: '-15px' }}>
              <option value="2024-2025">2024-2025</option>
              <option value="2023-2024">2023-2024</option>
              <option value="2022-2023">2022-2023</option>
            </select>
            </div>
         </div>
        <div className="card col-xl-12">
          <div className="card-header">
            <h5>
              <i className="icofont icofont-map-pins cus-i"></i> 
              <span>Stage Wise Rail TT Dashboard ({this.state.selectedYear})</span>
            </h5>
          </div>

          <div className="card-body col-xl-12 col-lg-12">
            {/* Dynamic iframe based on selected year */}
            <iframe 
              style={{ overflow: "hidden", border: "0px", margin: "0px", width: "100%", height: "100vh" }} 
              width="100%" 
              frameBorder="0"
              height="100vh"
              allowFullScreen="true" 
              src={this.state.iframeSrc}>
            </iframe>
          </div>
        </div>
      </div>
    );
  }
}

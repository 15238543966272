import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Route from 'react-router-dom/Route'
import { BrowserRouter, Switch } from 'react-router-dom'

import { ScrollContext } from 'react-router-scroll-4';
import './index.scss';


// ** Import custom components **
import store from './store';
import App from './components/app';

// Super Admin Screens
import AdminMenus from './components/superadmin/menus'
import AdminRoles from './components/superadmin/roles'
import AdminUserRoles from './components/superadmin/userroles'
//import AdminRoleMenus from './components/superadmin/assignrolemenus'

import AdminRoleMenus from './components/superadmin/assignmenustoroles'
import AdminViewSubMenus from './components/superadmin/view-submenus'
import AdminMenuReOrder from  './components/superadmin/menu-reorder'

// Dashboards
import Default from './components/dashboard/default/index.component'
import Overview from './components/dashboard/default/overview'
import Ecommerce from './components/dashboard/ecommerce/index.component'

import Login from './components/login/login'
import EnmUserLogin from './components/login/enmuserlogin'
import Logout from './components/login/logout'
import Register  from './components/login/register'
import LmRegister  from './components/login/lmregister'
import LmLogin  from './components/login/lmlogin'
import AuthCallBack  from './components/login/authcallback'
import ForgetPassword  from './components/pages/forget-password'
import Comingsoon  from './components/pages/comingsoon'
import Token from './components/zoho/token'
import Accounts from './components/zoho/accounts'
import Folders from './components/zoho/folders'
import ZohoMessages from './components/zoho/messages'
import AttachmentInfo from './components/zoho/attachmentinfo'
import Attachment from './components/zoho/attachment'
import RefreshToken from './components/zoho/refreshToken'
import Assets from './components/assets/assets'
import GeoFence from './components/geofence/geofence-list'
import TptGeoFence from './components/tptlogin/tpt-geofence-list'
import AddGeoFence from './components/geofence/add-geofence'
import AddTptGeoFence from './components/tptlogin/add-tpt-geofence'
import EditGeoFence from './components/geofence/edit-geofence'
import EditTptGeoFence from './components/tptlogin/edit-tpt-geofence'
import ChangeGeoFence from './components/geofence/change-geofence'
import ManageTrucks from './components/manage/trucks'
import ManageConsignments from './components/manage/consignments'
import ManageSNDConsignments from './components/manage/sndconsignments'
import ManagePRTConsignments from './components/manage/prtconsignments'
import ManageMOVEConsignments from './components/manage/moveconsignments'
import ManagePRTConsigners from './components/manage/prtconsigners'
import ManagePRTTransitTimes from './components/manage/prttransittimes'
import ManageSNDTransitTimes from './components/manage/sndtransittimes'
import ManageTransporters from './components/manage/transporters'
import ManageDepartments from './components/manage/departments'
import ManageConfigurations from './components/configurations/settings'
//import VicinityScreen from './components/vicinity/view-vicinity'
import VicinityScreen from './components/layouts/truckwithingeofence'
import TptVicinityScreen from './components/tptlogin/tpttruckwithingeofence'

import ReportOverspeed from './components/reports/overspeed'
import ReportEnrouteStoppages from './components/reports/enroutestoppages'
import ManageChaformat from './components/manage/chaformat'
import ManageImportsConsignments from './components/manage/importsconsignments'
import ManageImportsConsignmentsNew from './components/manage/importsconsignmentsnew'
import ManageImportsDeliveredContainers from './components/manage/importsdeliveredcontainersComponent'
import ManageImportsConsignmentsFreightForward from './components/manage/importsconsignmentfreightforward'
import ManageNearByConsignmentTrucks from './components/manage/consignment-nearby-trucks'
import ManageNearByTransporterTrucks from './components/tptlogin/transporter-nearby-trucks'
import ManageCourierConsignments from './components/layouts/courierconsignmentsComponent'
import ConsignementDashboard from './components/layouts/dashboardconsignmentComponent'
import ConsignementComponent from './components/layouts/consignmentComponent'
import ContainerDashboard from './components/layouts/dashboardcontainerComponent'
import WeatherComponent from './components/layouts/weathercomponent';
import EditWeatherEvent from "./components/layouts/editweathercomponent";
import ConsignmentsTodayDashboard from './components/layouts/consignmentsToday.component'
import ConsignmentsGeofence from './components/layouts/consignmentgeofence'

import ConsigneeVicinity from './components/layouts/consigneevicinityComponent'
import ConsignmentsKpi from './components/layouts/consignmentskpi.component'
import ManageTruckConsignments from './components/layouts/truckswithconsignmentsComponent'
import ManageExportsConsignments from './components/manage/exportsconsignments.component'
//import TranshipmentExports from './components/manage/transhipmentexports'
import TranshipmentExports from './components/manage/chatranshipmentexports'
import ExportsDeliveredConsignments from './components/manage/deliveredconsignmentComponent';
import ExportsPRTDeliveredConsignments from './components/manage/exportsprtdeliveredconsignmentComponent';
import PipavaMudraRoadPlan from './components/layouts/pipavamundraroadplanComponent' 
import ViewPipavaMudraRoadPlan from './components/layouts/viewpipavamundraroadplanComponent' 
import IcdGarhiContainerPlan from './components/layouts/icdgarhicontainerplanComponent' 
import CHADocumentReceits from './components/layouts/chadocumentreceitComponent';
import CHANhavaSheva from  './components/layouts/chanhavashevaComponent';
import CHADestinationPort from './components/layouts/chadestinationport';
import SparesCHADestinationPort from './components/layouts/spareschadestinationportComponent';
import DCTContaierUpdates from './components/layouts/dctcontainerupdates';
import DCTSparesContaierUpdates from './components/layouts/dctsparescontainerupdates';
import DeliveredConsignmentsComponent from './components/manage/deliveredconsignments.component'
import TMS from './components/layouts/ticketmanagementsystemComponent';
import TMSInvalid from './components/layouts/ticketmanagementsystemComponent';
import ConsignmentsAllComponent from './components/manage/consignmentsall.component'
import TrucksGPSData from './components/layouts/trucksgpsdataComponent';
import TptTrucksGPSData from './components/tptlogin/tpttrucksgpsdataComponent';
import Maintenance  from './components/pages/maintenance';
import EmptyReturnedTrucks from './components/layouts/trucksemptyreturngeofenceComponent';
import OnTripGPSReport from './components/layouts/ontripgpsgpsreport';
import ManageImportsCoils from './components/manage/importscoils.component';
import CoilImports from './components/manage/coilimports';
import CoilImportsDelivered from './components/manage/coilimportsdelivered';
import ManageUserMailMap from  './components/manage/usermailmap';
import ChangePasswordComponent from './components/manage/changepassword.component';
import ResetPassword from './components/login/reset-password';
import AssignRoles from './components/superadmin/assignroles';
import AndriodMap from './components/common/andriodmap';
import DepartmentTMS from './components/layouts/departmenttmsComponent';
import CHACoilsImports from './components/layouts/chacoilsimportsComponent';
import CoilsDispatch from './components/layouts/coilsdispatchComponent';
import ManageImportsContainer from './components/manage/importscontainersComponent';
import DashboardTemplate from './components/layouts/dashboardtemplateComponent';
import DashboardTemplate1 from './components/layouts/dashboardtemplateComponentnew';
import DashboardSummary from './components/layouts/dashboardsummaryComponent';
import DashboardDelayResponse from './components/layouts/dashboardDelayResponse';
import DashboardTickets from './components/layouts/dashboardTicketsComponent';
import DepartmentDashboard from './components/layouts/dashboarddepartmentComponent';
import PRTDepartmentDashboard from './components/layouts/prtdashboardComponent';
import RakesConsignmentComponent from './components/layouts/rakeComponent';
import ForceClosureComponent from './components/layouts/forceclosureComponent';
import TranshipmentComponent from './components/layouts/transhipmentComponent';
import TranshipmentReportComponent from './components/layouts/transhipmentReport.js';
import ManageRailConsignments from './components/layouts/railconsignmentsComponent';
import RoadPickUpComponent from './components/layouts/roadPickUpComponent';
import LoadingDashboard from './components/layouts/loadingDashboardComponent';
import DailyRunningDashboard from './components/layouts/dailyrunningdashboardComponent';
import TransshipmentTransporterComponent from './components/layouts/transshipmenttransporterComponent';
import TransporterComponent from './components/layouts/transporterconsignmentsComponent';
import TransporterTickets from './components/layouts/transporterTicketComponent';
import RailTranshipment from './components/layouts/railtranshipmentComponent';
import AlloybarComponent from "./components/layouts/alloybarsComponent";
import UnLoadingDashboard from './components/layouts/unloadingdashboardComponent';
import TransitDashboardComponent from  './components/layouts/transitdashboardComponent';
import DailyRunningTransporterDashboardComponent from  './components/layouts/dailrunningtransporterdashboardComponent';
import GPSDashboard from "./components/layouts/gpsdashboardComponent";
import ManualExports from "./components/layouts/manualExportsComponent";
import SegmentSideBar from "./components/layouts/segmentComponent";
import HolidayComponent from "./components/layouts/holidayComponent";
import GPSTransporter from "./components/layouts/gpstransporterdashboardComponent";
import TransporterIntegrationDashboardComponent from './components/layouts/transporterintegrationdashboardComponent';
import Error404 from './components/pages/error-404';
import OnTimeDashboardComponent from './components/layouts/ontimedashboardComponent';
import ShiftTimings from "./components/layouts/shiftTimingsComponent";
import AirCargoListComponent from "./components/layouts/airCargoListComponent";
import AirCargoForm from "./components/layouts/airCargoComponent";
import EditCargoEvent from "./components/layouts/editaircargoform";
import EditFFNewAircargo from "./components/layouts/editffnewaircargoform";
import FFAirCargoListComponent from "./components/layouts/ffAirCargoListComponent";
import EditFFCargoEvent from "./components/layouts/editffaircargoform";
import CreateFFCargoEvent from "./components/layouts/createffaircargoform";
import ChaAirCargoListComponent from "./components/layouts/chaAirCargoListComponent";
import TransporterAirCargoListComponent from "./components/layouts/transporterAirCargoListComponent";
import EditChaCargoEvent from "./components/layouts/editchaaircargoform";
import EditTransporterCargoEvent from "./components/layouts/edittransporteraircargoform";
import EditClosureCargoEvent from "./components/layouts/editclosureaircargoform";
import LoadAttachment from "./components/layouts/loadattachmentComponent";
import PossibleTransshipments from "./components/layouts/possibleTransshipments";
import LoadAttachmentExit from "./components/layouts/loadattachmentexitComponent";
import ChaDocumentHistory from "./components/layouts/chadocumenthistory.js";
import RailVinConsignments from "./components/layouts/railvinconsignmentsComponent.js";
import RailVINTranshipment from './components/layouts/railvintranshipmentComponent';
import VinRoadPickUpComponent from './components/layouts/vinroadpickupComponent';
import StoppageReport from "./components/layouts/stoppageReport";
import ConsigneEexceptionComponent from "./components/layouts/consignmentconsigneeexceptionComponent";
import LoadArrivals from "./components/layouts/loadArrivalsComponent";
import TransitTimeSummary from "./components/layouts/transitTimeSummary";
import TransitTimeData from "./components/layouts/transittimedata.js"
import GoogleAnalytics from "./components/layouts/googelanalyticsComponent";
import ReturnPallet from "./components/layouts/returnPallets";
import SNDUnloadingDashboard from "./components/layouts/sndUnloadingDashboardComponent";
import DistanceTravelledReport from "./components/layouts/distancetravellreportComponent";
import ProbableAccidentComponent from "./components/layouts/probableAccidentComponent"
import RakesScheduleComponent from './components/layouts/rakescheduleComponent';
import DealerCompensation from "./components/layouts/dealercompensation";
import InsideMSIL from  "./components/manage/insidemislvicinity";
import ManualLocationUpdate from  "./components/layouts/manuallocationupdateComponent";
import AddManualLocationUpdate from  "./components/layouts/addlocation";
import EditManualLocationUpdate from  "./components/layouts/editlocation";
import LocalConsignments from  "./components/layouts/localconsignmentsComponent";
import PRTTurnAroundTime from "./components/layouts/prtturnaroundtime";
import UserLogReport from "./components/layouts/userlogreport";
import ACTLContainers from "./components/layouts/actlcontainersComponent";
import DealerCompensationOldData from "./components/layouts/dealerCompOld"
import AssignACTLContainers from "./components/layouts/assignactlcontainersComponent";
import LiveTruckLocationMap from "./components/layouts/livetrucklocationmapComponent";
import LiveTruckMap from "./components/layouts/livetruckmapComponent";
import BreaksClusterComponent from "./components/layouts/breakClusterComponent";
import LiveTrucksInfo from "./components/layouts/livetrucksinfoComponent";
import UniqueTrucksTrips from "./components/layouts/uniquetruckstrips";
import MSILDashboard from "./components/layouts/msildashboardtabs";
import PrimaryDashboard from "./components/layouts/primarydashboardtabs";
import SecondaryDashboard from "./components/layouts/secondarydashboardtabs";
import StockyardDashboard from "./components/layouts/stockyarddashboardtabs";
import CatalyticMissingBreakClusters from "./components/layouts/catalyticMissingBreaksCluster";
import ACTLContainerPlan from "./components/layouts/actlcontainerplanComponent";
import SndRegionWiseReport from "./components/layouts/sndregionwisereport";
import Billing from "./components/layouts/billingComponent";
import BillingSC from "./components/layouts/billingsupplychainComponent";
import BatchWiseClusters from "./components/layouts/batchwiseclusters";
import BatchWiseCatalysis from "./components/layouts/batchwisecatalysisreport";
import UpdateRailAnaytics from "./components/layouts/railanalyticsupdateComponent";
import RailAnaytics from "./components/layouts/railanalyticsdashboardComponent";
import AddLclImports from "./components/layouts/addlclimports";
import LclImports from "./components/layouts/lclimports";
import ImportsShipments from "./components/layouts/importsshipments";
import RouteDeviationMap from "./components/layouts/routeDeviationComponent";
import DeviationRoutes from "./components/layouts/routeDeviationreportComponent";
import OptimizeDeviationRoutes from "./components/layouts/routedeviationoptimizeComponent";
//import WeatherHeatMap from "./components/layouts/weatherHeatMap";
import StateBorderGeofenceMap from "./components/layouts/stateBordersGeofence"
import GoldeZoneDashboard from "./components/layouts/goldenzonedashboardComponent";
import ReturnTrips from "./components/layouts/returntripsComponent";
import NewDeviationRoutes from "./components/layouts/routeDeviationReportNewComponent";
import TruckAvailability from "./components/layouts/truckavailabilityComponent";
import ReturnTripsDashboardChart from "./components/layouts/returnTripsDashboardComponent";
import BillingDashboard from "./components/layouts/billingDashboardComponent";
import ViewBillAction from "./components/layouts/viewbillaction";
import LmStates from "./components/layouts/lmstates";
import LmVehicleCapacity from "./components/layouts/lmvehiclecapacity";
import LmFreights from "./components/layouts/lmfreights";
import LmRouteConfig from "./components/layouts/lmrouteconfig";
import LmConsignees from "./components/layouts/lmconsignees";
import LmBaseConfig from "./components/layouts/lmbaseconfig";
import LmLoadManager from "./components/layouts/lmloadmanager";
import DetentionReport from "./components/layouts/detentionReport"
import USDRates from "./components/layouts/usdratesComponent";
import ICDRates from "./components/layouts/icdratesComponent";
import AutomotiveGeoFence from "./components/geofence/automotivegeofences";
import AddAutomotiveGeoFence from "./components/geofence/addautomotivegeofence";
import EditAutomotiveGeoFence from "./components/geofence/editautomotivegeofence";
import LmTransporters from "./components/layouts/lmtransporters";
import LmAddTransporters from "./components/layouts/lmaddtransporters";
import AltoRates from "./components/layouts/altoRatesComponent";
import LmShareOfBusiness from "./components/layouts/lmshareofbusiness";
import LmCurrentShareOfBusiness from "./components/layouts/lmcurrentsob";
import LmLoadShare from "./components/layouts/lmloadshare";
import LmSobZones from "./components/layouts/lmsobzones";
import RailInventory from "./components/layouts/railinventoryComponent";
import OEMBreaks from "./components/layouts/oemBreaksComponent";
import PRTOptimizeDeviationRoutes from "./components/layouts/routedeviationprtoptimizeComponent";
import PRTDeviationRoutes from "./components/layouts/routedeviationprtreportComponent";
import MarkDeliveredRailConsignments from "./components/layouts/railconsignmentsmarkdeliverComponent";
import LmLoadSummary from "./components/layouts/lmloadsummary";
import ViewLoadSummaryDetails from "./components/layouts/viewLoadSummaryDetails";
import ReturnTripsBreakClusters from "./components/layouts/returnTripsBreaksClusters";
import RoutePlanning from "./components/layouts/routesndplanningComponent";
import TNPOptimizeDeviationRoutes from "./components/layouts/routedeviationtnpoptimizeComponent";
import TNPDeviationRoutes from "./components/layouts/routedeviationtnpreportComponent";
import RailPlanning from "./components/layouts/railplanningComponent";
import LmTruckAvailability from "./components/layouts/lmtruckavailability";
import LmLoadingPorts from "./components/layouts/lmloadingports";
import TransitAnalytics from "./components/layouts/transitAnalyticsDashboard";
import ExportsContainerTracking from "./components/layouts/exportscontainertrackingComponent";
import SparesExportsContainerTracking from "./components/layouts/sparesexportscontainertracking";
import SparesExportsContainerDelivered from "./components/layouts/sparesexportscontainerdelivered";
import SparesExportsDestinationPorts from "./components/layouts/sparesexportsdestinationpoints";
import PRTUnutilizedTrucks from "./components/layouts/prtUnutilizedTrucks";
import AnalyticsDashboard from "./components/layouts/analyticsdashboardComponent";
import RailLoadOptimizationComponent from "./components/layouts/railLoadOptmizationcomponent"
import GetLoadOptimizationReport from "./components/layouts/getOptimizedLoadReport";
import MSILVesselSchedule from "./components/layouts/msilvesselscheduleComponent";
import EmptyTrucksVicinity from "./components/layouts/emptytrucksvicinityComponent";
// import TptEmptyTrucksVicinity from "./components/tptlogin/tptemptytrucksvicinity";
import RakeArrivals from "./components/layouts/rakearrivals";
import ManagementDashboard from "./components/layouts/managementDashboard";
import RakeStockStatus from "./components/layouts/rakestockstatus";
import UnderConstruction from "./components/layouts/undeconstruction";
import PlanningStatus from "./components/layouts/planningstatus";
import SafetyReport from "./components/layouts/safetyreport";
import DivisionHealthReport from "./components/layouts/divisionhealthreportComponent";
import LoadFactors from "./components/layouts/loadFactorsComponent";
// import MLLRailSummaryComponent from "./components/mll/mllsummaryComponent";
import MLLRailSummaryComponent from "./components/mll/mllmainComponent";
import MLLConsignments from "./components/mll/mllconsignmentsComponent";
import MLLCreateBatch from "./components/mll/mllcreatebatchComponent";
import MllMarkArrivalLoadingYard from "./components/mll/mllmarkarrivalloadingyardComponent";
import LmVOutput from "./components/layouts/lmvoutput";
import LmVOutputDetails from "./components/layouts/lmvoutputdetails";
import MllWagonCheck from "./components/mll/mllwagonchecksheetComponent";
import MllTrainLoadCheck from "./components/mll/mlltrainloadingchecksheetComponent";
import MllTrainUnLoadCheck from "./components/mll/mlltrainunloadingchecksheetComponent";
import AddMllWagonCheck from "./components/mll/addmllwagonchecksheetComponent";
import AddMllTrainLoadCheck from "./components/mll/addmlltrainloadingchecksheetComponent";
import WeatherMap from "./components/layouts/weathermap";
import LmDepartments from "./components/layouts/lmdepartments";
import GeofenceMap from "./components/mll/geofencemapComponent";
import LmUsers from "./components/layouts/lmusers";
import LmRoles from "./components/layouts/lmroles";
import LmUserActivities from "./components/layouts/lmuseractivities";
import MLLAllConsignmentsComponent from "./components/mll/mllallconsignmentsComponent";
import LmWelcome from "./components/layouts/lmwelcome";
import LmPageAccess from "./components/layouts/lmpageaccess";
import LmLoadingPortAccess from "./components/layouts/lmloadingportaccess";
import MLLTransporters from "./components/mll/mlltransportersComponent";
import MLLTrucks from "./components/mll/mlltrucksComponent";
import MLLPlants from "./components/mll/mllplantComponent";
import MLLDealers from "./components/mll/mlldealerComponent";
import LmUserLogReport from "./components/layouts/lmuserlogreport";
import AddMllTrainUnLoadCheck from "./components/mll/addmlltrainunloadingchecksheetComponent";
import MLLLastMileBatch from "./components/mll/mlllastmilebatchComponent";
import MLLTrucksGPS from "./components/mll/mlltrucksgpsComponent";
import MllTrucksWithInGeofence from "./components/mll/mlltruckwithingeofenceComponent";
import MLLLoadingYardArrival from "./components/mll/mllloadingyardarrivalComponent";
import RegisteredDevices from "./components/layouts/registeredDevices";
// import TptRegisteredDevices from "./components/tptlogin/tptregisteredDevices";
// import TptPendingInstallations from "./components/tptlogin/tptpendinginstallations";
import tptGpsIntegration from "./components/tptlogin/tptGpsIntegration";
// import tptGpsIntegration from "./components/tptlogin/tptintegrationwithtabsComponent";
import BRMDashboard from "./components/layouts/brmDashboard";
import MLLLoadingOfficers from "./components/mll/mllloadingofficersComponent";
import MLLFirstMileTransshipments from "./components/mll/mllfirstmiletransshipmentComponent";
import MLLLastMileTransshipments from "./components/mll/mlllastmiletransshipmentComponent";
import MLLFirstMileRetrivals from "./components/mll/mllfirstmileretrivalComponent";
import MLLHandOverRetrivals from "./components/mll/mllgpsdeicehandretrivalComponent";
import MLLRSOPOD from "./components/mll/mllrsopodComponent";
import PRTOnTimeComponent from "./components/layouts/prtOntimeDashboard";
import MLLRakeRetrivals from "./components/mll/mllgpsdevicerakeretrivalsComponent";
import MLLRSORetrivals from "./components/mll/mllrsoretrivalsComponent";
import MLLDeviceDashboard from "./components/mll/mlldevicedashboardComponent";
import MLLIssueToBA from "./components/mll/mllissuedevicetobaComponent";
import BRMDashboardMgmt from "./components/layouts/brmdashboardmgmt";
import BRMDashboardMgmtMobile from "./components/layouts/brmdashboardmgmtmobile";
import MLLGPSDevicePlanning from "./components/mll/mllgpsdeviceplanningComponent";
import MLLNotifications from "./components/mll/mllnotificationComponent";
// import TptReportOverspeed from "./components/tptlogin/overspeed";
// import TPTGpsEodAnalysis from "./components/tptlogin/tptgpseodanalysisComponent";
import MLLFrieghtFirstMileRates from "./components/mll/mllfreightfirstmileratesComponent";
import MLLGPSDevices from "./components/mll/mllgpsdevicesComponent";
import MLLRakeShipToRSORetrivals from "./components/mll/mllgpsdevicerakeshiptorsoComponent";
import MLLShipToPlantRSO from "./components/mll/mllshiptoplantrsoComponent";
import MllRegistration from "./components/mll/mllregistration";
import MllAssignRoles from './components/mll/mllassignroles';
import MllAdminUserRoles from './components/mll/mlluserroles';
import MLLLastMileRSOBatch from './components/mll/mlllastmilersobatchComponent';
import MllConsignmentNearByTrucks from "./components/mll/mllconsignmentnearybytruckComponent";
import MllKPIDashboard from "./components/mll/mllkpidashboardComponent";
import MllDamageAnalysis from "./components/mll/mlldamageanalysisComponent";
import GPSBilling from "./components/layouts/gpsbillingComponent";
// import TPTGPSBilling from "./components/tptlogin/tptgpsbillingComponent";
// import TPTGPSBillingNonMSIL from "./components/tptlogin/tptgpsbillingnonmsilComponent";
// import TPTSubUsers from "./components/tptlogin/tptsubusers";
import SecondaryPaymentSummary from "./components/layouts/secondarypaymentsummaryComponent";
import DamageDashboard from "./components/layouts/damageDashboardComponent";
import GPSBillingDashboard from "./components/layouts/gpsbillingdashboardComponent";
import ViewGPSBillAction from "./components/layouts/viewgpsbilling";
// import TPTGPSBillingDashboard from "./components/tptlogin/tptgpsbillingdashboardComponent";
// import TPTViewGPSBillAction from "./components/tptlogin/tptviewgpsbilling";
// import TPTGPSNonMSILBillingDashboard from "./components/tptlogin/tptgpsbillingdashboardnonmsil";
// import TPTViewGPSNonMSILBillAction from "./components/tptlogin/tptviewgpsbillingnonmsil";
import AndriodGridMap from './components/common/andriodgridmap';
// import TPTTrucksDistanceMatrix from './components/tptlogin/tpttrucksdistancematrixComponent';
import LoadPlanToDashboard from './components/layouts/loadplanningtodashboardComponent';
// import Mantis from './components/common/mantis';
// import VehicleRegister from './components/tptlogin/tptvehicleregisterComponent';
// import TPTTrucks from "./components/tptlogin/tpttrucksComponent";
// import TPTCarrier from "./components/tptlogin/tptcarrierComponent";
// import TPTGroup from "./components/tptlogin/tptgroupComponent";
import TransporterPerformanceDashboard from "./components/layouts/transporterperformancedashboard";
import PrtTransporterPerformanceDashboard from "./components/layouts/prtTransporterperformancedashboard";
// import MasterPerformanceDashboard from "./components/layouts/masterperformancedashboard";
// import TPTGeofenceIncursion from "./components/tptlogin/tptgeofenceincurssionsComponent";
// import TPTDrivers from "./components/tptlogin/tptdriversComponent";
// import TPTNightDriving from "./components/tptlogin/tptnightdrivingComponent";
import TransporterInfo from "./components/transporter/transporterinfo";
import PrtTransporterInfo from "./components/transporter/prttransporterinfo";
import RailOperationsMonitoring from "./components/layouts/railoperationsmonitoring";
import ExitStatus from "./components/layouts/exitstatus";
import RetrivalExitReport from "./components/layouts/retrivalexitreport";
import CityWiseDieselPrices from "./components/layouts/cityWiseDieselPricesComponent";
// import TPTRailSummary from "./components/tptlogin/tptrailsummaryComponent";

// import TPTConsignments from "./components/tptlogin/tptrailconsignmentsComponent";
// import TPTCreateBatch from "./components/tptlogin/tptcreatebatchComponent";
// import TPTMarkArrivalLoadingYard from "./components/tptlogin/tptmarkarrivalloadingyardComponent";
// import TPTLoadingYardArrival from "./components/tptlogin/tptloadingyardarrivalComponent";
// import TPTAddWagonCheck from "./components/tptlogin/tptaddwagonchecksheetComponent";
// import TPTAddTrainLoadCheck from "./components/tptlogin/tptaddtrainloadingchecksheetComponent";
// import TPTAddTrainUnLoadCheck from "./components/tptlogin/tptaddtrainunloadingchecksheetComponent";
// import TPTLastMileBatch from "./components/tptlogin/tptlastmilebatchComponent";
// import TPTDevices from "./components/tptlogin/tptdevicesComponent";
// import TPTLoadingOfficers from './components/tptlogin/tptloadingofficersComponent';
// import TPTTransporters from "./components/tptlogin/tpttransportersComponent";
// import TPTOEMMaster from "./components/tptlogin/tptoemmasterComponent";
// import TPTOEMLocations from "./components/tptlogin/tptoemlocationComponent";
// import TPTOEMDealers from "./components/tptlogin/tptoemdealersComponent";
// import TPTOEMTransitTimes from "./components/tptlogin/tptoemtransittimesComponent";
// import TPTOEMConsignments from "./components/tptlogin/tptoemconsignmentsComponent";
// import TPTHarshBreaking from "./components/tptlogin/tptharshbreakComponent";
// import TPTGPSSingleScreen from "./components/tptlogin/tptgpsintegrationsinglescreenComponent";
import DamageAnalysis from "./components/layouts/damageanalysis";
import ImportsCoilTracking from "./components/layouts/importscoiltracking";
import AndriodTPTRailMap from "./components/common/andriodtptrailmap";
import RailLoadPlanAnalysis from "./components/layouts/railPlanComponent";
// import CreateAnalyticFrames from "./components/dashboard/analyticsdashboardComponent";
//import CreateAnalyticFrames from "./components/dashboard/createanalyticsframe";
//import EditAnalyticFrames from "./components/dashboard/editanalyticsframe";
import ShareOfBusiness from "./components/layouts/shareofbusiness"
import ZohoAnalyticDashboard from "./components/dashboard/analyticsdashboardComponent";
import FreightMaster from "./components/layouts/freightmaster";
import OverviewAnalytics from "./components/dashboard/default/analyticsdashboard";
import Railautoplaning from "./components/layouts/railautoplaning";
import Coilvendors from './components/layouts/coilvendors';
import Coilmsps from './components/layouts/coilmsps';
import SobCompleteData from './components/layouts/shareofbusinesscompletedata';
import AccidentalProneZones from "./components/layouts/accidentalProneZoneTabs";
// import AccidentalProneZonesTab from "./components/layouts/accidentalProneZonesTab";
import AddAccidentalProneZone from "./components/layouts/addAccidentalProneZoneForm"
import EditAccidentProneZone from "./components/layouts/editAccidentalProneZone"
import NoParkingZones from "./components/layouts/noparkingzones";
import AddNoParkingZones from "./components/layouts/addnoparkingzones";
import EditNoParkingZones from "./components/layouts/editnoparkingzones";
import BIDashboardRailStock from "./components/dashboard/railstockdashboardComponent";
import AirCargoExportForm from "./components/layouts/aircargoexportsform";
import AirCargoExportListComponent from "./components/layouts/aircargoexportslist";
import EditCargoExport from "./components/layouts/editaircargoexportsform";
import TransporterAirCargoExportsListComponent from "./components/layouts/transporteraircargoexportslist";
import EditTransporterExportsCargoEvent from "./components/layouts/edittransporteraircargoexportsform";
import ChaAirCargoExportsListComponent from "./components/layouts/chaaircargoexportslist";
import EditChaExportsCargoEvent from "./components/layouts/editchaaircargoexportsform";
import Error405 from './components/pages/error-405';
import SafetyIncidents from "./components/layouts/safetyincidents";
import ShareOfBusinessInterPant from "./components/layouts/shareofbusinessinterplant";
import SNDStockDashboard from "./components/dashboard/sndstockdashboardComponent";
import TnpUserItemsTabs from "./components/layouts/tnpuseritemstab";
import FFAirCargoExportsListComponent from "./components/layouts/ffaircargoexportslist";
import EditFFCargoExports from "./components/layouts/editffaircargoexportsform";
import ContractMonitoringSystem from "./components/layouts/contractmonitoring";
import AddContract from "./components/layouts/addcontract";
import AddCustomBond from "./components/layouts/addcustombond";
import EditContract from "./components/layouts/editcontract";
import PrtVolumeFreightFileUpload from "./components/layouts/prtVolumeFreightFileUpload";
import TransitReturnsBiDashboard from "./components/layouts/transitreturnsbidashboard";
import AddNewTransitReturns from "./components/layouts/addnewtransitbidashboard";
import EditNewTransitReturns from "./components/layouts/edittransitbireturns";
import SeaImportsForm from "./components/layouts/seaimportsform";
import SeaImportsListComponent from "./components/layouts/seaimportslist";
import CmsAutoMailConfig from "./components/layouts/cmsautomailconfig";
import BiTransitReturnDashboard from "./components/dashboard/transitreturnsbidashboard";
import EditSeaImports from "./components/layouts/editseaimportsform";
import FFSeaImportsList from "./components/layouts/ffseaimportsList";
import EditFFSeaImports from "./components/layouts/editffseaimportsform";
import ChaSeaImportsList from "./components/layouts/chaseaimportsList";
import EditChaSeaImports from "./components/layouts/editchaseaimportsform";
import TransporterSeaImports from "./components/layouts/transporterseaimports";
import EditTransporterSeaImports from "./components/layouts/edittransporterseaimports";
import ConsignmentsChannels from "./components/manage/consignmentschannelComponent";
import RailConsignmentsChannels from "./components/layouts/railconsignmentschannelComponent";
import lspCarrierTabs from "./components/layouts/lspcarrieruploadtabscreen";
import AddLspDashboardDoc from "./components/layouts/addLspDocumentDetails";
import WarehouseDashboard from "./components/dashboard/warehousedashboardComponent";
import RakePlanningTransporters from "./components/layouts/rakeplanningtransporters";
import RpRakeDetails from "./components/layouts/rprakedetails";
import RpRailYardDetails from './components/layouts/rprailyarddetails';
import RpBaseStationDetails from './components/layouts/rpbasestationdetails';
import RpBaseStationCityDistance from './components/layouts/rpbasestationcitydistance';
import RpTransitTimes from './components/layouts/rptransittimes';
import RpAutoRakePlanning from './components/layouts/rpautorakeplanning';
import CarsPlanningSummary from './components/layouts/carsplanning';
import RpAutoRakePlanningHistoricalData from './components/layouts/rpautorakeplanninghistoricaldata';
import RpRakePlanSummary from './components/layouts/rprakeplansummary';
import viewRakeSummaryDetails from "./components/layouts/viewRakeSummaryDetails";
import viewCarSummaryDetails from "./components/layouts/viewCarSummaryDetails";
import RakeAvailability from "./components/layouts/rakeavailability";
import CarsPlanning from "./components/layouts/createcarsplanning";
import RpCarDetails from "./components/layouts/rpcardetails";
import RpTrainDetails from "./components/layouts/rptraindetails";
import RpTrainDimensions from "./components/layouts/rptraindimensions";
import RpTransporterServing from "./components/layouts/rptransporterserving";
import PRTRouteDeviationAdhoc from "./components/layouts/prtRouteDeviationadhoc";
import AirCargoList from './components/layouts/aircargoList';
import RouteViewMap from "./components/common/routemapview";
import D22Consignment from './components/layouts/d22consignment';
import GPSIntegrationStatus from './components/dashboard/default/indexd22.component';
import D22TrucksGPSData from './components/layouts/trucksd22gpsdataComponent';
import SobAchievement from './components/layouts/sobAchievement';
import SobAchievementAdhoc from './components/layouts/sobAchievementadhoc';
import DealerAppMap from './components/common/dealerappmap';
import DealerAppStatic from "./components/common/dealerappstatic";
import TagConfiguration from './components/yardmanagement/tagconfiguration';
import AssetConfiguration from './components/yardmanagement/assetconfiguration';
import GateWayConfiguration from './components/yardmanagement/gatewayconfiguration';
import EnSafeAlertLogs from "./components/layouts/ensafealertlogs";
import AssetTagPairing from './components/yardmanagement/assettagpairing';
import GeofenceTag from './components/yardmanagement/geofencetag';
import ZoneTag from './components/yardmanagement/zonetag';
import EwayBillMonitoring from "./components/layouts/ewaybillmonitoring";
import EwayBillMonitoringForCourier from "./components/layouts/ewaybillmonitoringcourier";
import RailstageWiseDashoard from "./components/layouts/railstagewisedashboard";
import ElockDevices from './components/layouts/elockdevices';
import ElockDevicesMapTrucks from './components/layouts/assigntruckstoelock';
import MobileDeviceMapping from './components/layouts/ensafedevicePhoneMapping';
import ELockConfigurations from './components/layouts/elockConfigurations'
import OverspeedComponent from './components/layouts/overspeedComponent'
import StoppageReportData from './components/layouts/stoppagereportComponent'
import ContinousTravelReport from './components/layouts/continoustravelreportComponent'
import HarshBreakingData from './components/layouts/harshbreakComponent'
import NightDrivingReportData from './components/layouts/nightdrivingReportComponent';
// import CourierOrders from "./components/couriers/ordersComponent";
// import CourierOrderCreateShipment from "./components/couriers/create-order-shipment";
// import CourierShipments from "./components/couriers/shipments";
// import CourierVendors from "./components/couriers/vendors";
// import CourierCreateVendor from "./components/couriers/create-vendor";
import RailLspPerformanceCard from "./components/layouts/railLspPerformanceCard";
import BillingSummary from "./components/layouts/billingsummary";
import BillingDownload from "./components/layouts/billingdownload";
import uploadMasterList from "./components/layouts/uploadmasterlist";
import masterTruckEligible from "./components/layouts/mastertruckseligible";
import lspStatusBilling from "./components/layouts/lspstatusbilling";
import lspValidationStatus from "./components/layouts/lspvalidationstatus";
import masterSummaryData from "./components/layouts/mastersummarydata";
import ShareOfBusinessInterPantD22 from './components/layouts/shareOfBusinessInterPlantD22';
import NewOptimizeDeviationRoutes from './components/layouts/newRouteDeviationOptimizeComponent';
import DeviationRoutesData from './components/layouts/newDeviationRouteData';
import TopBaseRouteSummary from './components/layouts/newTopBaseRouteSummary';
import SavedBaseRoutes from './components/layouts/newSaveBaseRoutes';
import transitTimeAnalysis from "./components/layouts/transitTimeAnalysis";
import UserLogDashboard from "./components/layouts/userlogdashboard";
import stagewiseRailTTDashboard from "./components/layouts/stagewiserailttdashboard";
import ForecastDashboard from "./components/layouts/forecastdashboard";
import BidhadiSourceDestinationMap from './components/layouts/bidaditodestination';
import LogsCostEstimation from './components/layouts/logscostestimation';
import TnpTrucksAvailability from './components/layouts/tnptrucksavailability';
import TransportationRates from './components/layouts/transportationrates';
import SearchDispatches from './components/manage/searchdispatches';
import D22ReturnPallets from './components/layouts/returnpalletsD22';
import ReckwiseRoadConsignments from './components/layouts/reckwiseroadconsignments';
import CarrierRunReport from './components/layouts/carrierrunreport';
import ManageRailForCodes from './components/layouts/manageRailForCodes';
import RailConsignmentLogs from './components/layouts/railconsignmentlogs';
import DelayedRakesInfo from './components/layouts/delayedrakesinfo';
import CustomsBondMonitoringScreen from './components/layouts/custombondmonitoring';
import StageWiseTransitTimeDashboard from './components/layouts/stagewisetrasnittimedashboard';
import TptFreeWheelingReport from './components/layouts/freewheelingreport';
import IgnoredRoutes from './components/layouts/ignoredroutes';
import ScreenWiseUserLogins from './components/layouts/screenwiseuserlogins'
import RailClusterSummary from './components/layouts/railClusterSummary'
import ContainerBookingSystem from './components/layouts/containerbookingsystemcomponent';
import OneTimeContainerBookingFrom from './components/layouts/onetimecontainerbookingform';
import LSPDetails from './components/layouts/lspdetailsscreen';
import AddLspDetails from './components/layouts/addlspdetails';
import ContainerBookingffUpdates from './components/layouts/freightforwadercontainerbookingupdates';
import ContainerBookingChaUpdates from './components/layouts/containerbookingchaupdates';
import ContainerBookingTransporterUpdates from './components/layouts/containerbookingtransporterupdates';
import ContainerExportTracking from './components/layouts/containerexporttracking';
import LedRailwayLogisticsscreen from './components/layouts/ledrailwaylogisticsscreen'
import LedRailwayLogisticsscreen2 from './components/layouts/ledrailwaylogisticsscreen2';
import LedRailwayLogisticsscreen3 from './components/layouts/ledrailwaylogisticsscreen3';
import GpsIntegrationForIbl from './components/layouts/gpsIntegrationforibl';
import ConsignmentScreenForIbl from './components/layouts/consignmentsforibl';
import SparepartBIDashboard from './components/layouts/sparepartsbidashboard';
import LedRailwayLogisticsscreen4 from './components/layouts/ledrailwaylogisticsscreen4';
import LedRailwayLogisticsscreen5 from "./components/layouts/LedRailwayLogisticsscreen5.js";
import LedRailwayLogisticsscreen6 from './components/layouts/ledrailwaylogisticsscreen6';
import SampleLedScreen1 from './components/layouts/sampleledscreen1';
import SampleLedScreen2 from './components/layouts/sampleledscreen2';
import CustomsClearenceForm from './components/layouts/customclearenceForm';
import GpsUptimeReport from './components/layouts/gpsuptimereport';
import LspCeoDashboard from './components/layouts/lspceodashboard';
// import LspCeoDashboardDemo from './components/layouts/lspceodashboardDemo.js';
import FleetDetails from './components/layouts/fleetdetails';
import PendingPods from './components/layouts/pendingPods.js';
import pendingPodsTVP from './components/layouts/pendingpodsTVP.js';
import InvoiceToExitDlp from './components/layouts/invoicetoexitdlp';
import TransitReturns from './components/layouts/transitreturns';
import Violations from './components/layouts/violations';
import TargetvsAchievement from './components/layouts/targetVSachievement';
import OnTimeDelivery from './components/layouts/ontimedelivery';
import TransitDamages from './components/layouts/transitdamages';
import FreightPayments from './components/layouts/freightpayments';
import FreightAmount from './components/layouts/freightamount';
import GpsStatus from './components/layouts/gpsstatus';
import CriticalDelay from './components/layouts/criticaldelay';
import DriverTrainings from './components/layouts/drivertrainings';
import TVPFreightPaymentPending from "./components/layouts/tvpfreightpaymentpending.js";
import PendingpodsTabs from './components/layouts/pendingpodstab.js';
import LiveAbnormality from './components/layouts/liveabnormalities.js';

import FleetDetailsTest from './components/layouts/fleetdetailsTest';
import PendingPodsTest from './components/layouts/pendingPodsTest.js';
import pendingPodsTVPTest from './components/layouts/pendingpodsTVPTest.js';
import InvoiceToExitDlpTest from './components/layouts/invoicetoexitdlpTest';
import TransitReturnsTest from './components/layouts/transitreturnsTest';
import ViolationsTest from './components/layouts/violationsTest';
import TargetvsAchievementTest from './components/layouts/targetVSachievementTest';
import OnTimeDeliveryTest from './components/layouts/ontimedeliveryTest';
import TransitDamagesTest from './components/layouts/transitdamagesTest';
// import FreightPaymentsTest from './components/layouts/freightpaymentsTest.js';
import FreightAmountTest from './components/layouts/freightamountTest.js';
import GpsStatusTest from './components/layouts/gpsstatusTest';
import CriticalDelayTest from './components/layouts/criticaldelayTest';
import DriverTrainingsTest from './components/layouts/drivertrainingsTest';
import TVPFreightPaymentPendingTest from "./components/layouts/tvpfreightpaymentpendingTest.js";
import PendingpodsTabsTest from './components/layouts/pendingpodstabTest.js';
import LiveAbnormalityTest from './components/layouts/liveabnormalitiesTest.js';
// import stagewiseRailTTDashboardFy from './components/layouts/stagewiseraildashboardfy'
import LoadChangingInfo from './components/layouts/loadchanginginfo';
import WeatherReportData from './components/layouts/weatherreportdata';
import ContainerBookingOneTime from './components/layouts/onetimecontainerbooking';
import ContainerBookingOneTimeForm from './components/layouts/containerbookingonetimeform2'
import ContainerBookingWeekly from './components/layouts/weeklycontainerbooking';
// import stagewiseRailTTDashboardFy2 from './components/layouts/stagewiseraildashboardfy2'
//ARMS
import Targetpreparation from './components/arms/targetpreparation';
import EditOneTimeContainerBookingFrom from "./components/layouts/editonetimecontainerbookingform"

//Coil Tracking Requirements

import CoilStockInformation from "./components/layouts/coilStockInformation"
import CoilStockInformationScreen2 from './components/layouts/coilStockInfomationScreen2';
import TransitWarehouseCoilDispatch from './components/layouts/transitWarehouseCoilDispatch';
import GstRegisteredWarehouseCoilDispatch from './components/layouts/gstRegisteredWarehouseCoilDispatch';
import ContainerExportsTracking from './components/layouts/exportscontainertracking'


// import FutureNagareTracking from "./components/layouts/futureNagareTracking"


import LCLFrieghtManagementSystem from './components/layouts/lclFrieghtManagementSystem';
import Lclfgrieghtmanagementsystemscreen2 from './components/layouts/lclfgrieghtmanagementsystemscreen2';

import ApiRunLogs from './components/layouts/apirunlogs';

//auto emails screen
import AutomailsScreen from './components/layouts/automailsscreen';
//DCTattendancelogs
import DCTattendanceSummary from './components/layouts/dctattendancesummary'
import DCTattendancelogs from './components/layouts/dctattendancelogs';
import DCTattendanceView from './components/layouts/dctattandanceview'

import UniqueScreenLogs from "./components/layouts/uniquescreenlogs"
//HUB
import HubConsignments  from './components/manage/hubconsignments'
import LastMileHubConsigmentScreen  from './components/layouts/lastmilehubconsigment'
import HubMaster from './components/layouts/hubmaster';
import TransporterMaster from './components/layouts/transportermaster';
import LastMileFleetMaster from './components/layouts/lastmilefleetmaster';

//LSP Carrier
import LSPCarrierDataIndividul  from './components/layouts/lspcarrierdatausers'
import LSPCarrierDataAdmin  from './components/layouts/lspcarrierdataadmin'
 //Exports Container Booking
import UgrentContainerBookingFrom from "./components/layouts/urgentcontainerbookingform"
import EditWeeklyContainerBookingFrom from "./components/layouts/editweeklycontainerbookingform"
import EditUrgentContainerBookingFrom from "./components/layouts/editurgentcontainerbookingform"
import EditFFPendingOneTimeContainerBookingFrom from "./components/layouts/editffpendingonetimecontainerbookingform"
import EditFFInProccessOneTimeContainerBookingFrom from "./components/layouts/editffinprocessonetimecontainerbookingform"
import EditFFPendingWeeklyContainerBookingFrom from "./components/layouts/editffweeklypendingcontainerbookingform"
import EditFFInProccessWeeklyContainerBookingFrom from "./components/layouts/editffweeklyinprocesscontainerbookingform"
import EditFFPendingUrgentContainerBookingFrom from "./components/layouts/editffurgentpendingcontainerbookingform"
import EditFFInProccessUrgentContainerBookingFrom from "./components/layouts/editffurgentinproccesscontainerbookingform"
import EditTransporterPendingOneTimeContainerBookingFrom from "./components/layouts/edittransporterpendingonetimecontainerbookingform"
import EditTransporterPendingWeeklyContainerBookingFrom from "./components/layouts/edittransporterpendingweeklycontainerbookingform"
import EditTransporterPendingUrgentContainerBookingFrom from "./components/layouts/edittransporterpendingurgentcontainerbookingform"
import EditCHAPendingOneTimeContainerBookingFrom from "./components/layouts/editchapendingonetimecontainerbookingform"
import EditCHAInProccessOneTimeContainerBookingFrom from "./components/layouts/editchainprocessonetimecontainerbookingform"
import EditCHAPendingWeeklyContainerBookingFrom from "./components/layouts/editchaweeklypendingcontainerbookingform"
import EditCHAInProccessWeeklyContainerBookingFrom from "./components/layouts/editchaweeklyinprocesscontainerbookingform"
import EditCHAPendingUrgentContainerBookingFrom from "./components/layouts/editchaurgentpendingcontainerbookingform"
import EditCHAInProccessUrgentContainerBookingFrom from "./components/layouts/editchaurgentinproccesscontainerbookingform"
import RakeTrackingScreen from "./components/layouts/rakeTrackingScreen"
import NexaScreen from './components/layouts/lmnexascreen.js';
import LEDLiveTruckMapComponent from "./components/layouts/ledlivetruckmapcomponent.js"
import TransporterGPSReview from './components/layouts/transportergpsreview'
import TransporterGPSReviewFrom from './components/layouts/transportergpsreviewform'
import GPSReview from './components/layouts/gpsreviewscreen.js'
import ContainerImportsDataAdHoc from "./components/layouts/containerimportsdataadhoc.js"
import SustainabilityConfiguration from './components/layouts/sustainabilityconfiguration.js'
import SustainabilityBiDashboard from './components/layouts/sustainabilitybiDashboard.js'
import PowerBIScreen from './components/layouts/power_bi_dashboard.js';
import RestrictionImposedSystem from './components/layouts/restrictionimposedsystem.js';
import MSIBOperationsMonitoring from './components/layouts/msiboperationsmonitoring.js';
// import stagewiseRailTTDashboardFy23 from './components/layouts/stagewiseraildashboardfy23.js'
import ContainerTracking from './components/layouts/containerTracking.js';
import ContainerTrackingDetails from './components/layouts/ContainerTrackingDetails.js';

import FormPageForKownledgeBank from './components/layouts/knowledgebank.js';
// import SparepartsBiDashboard24_25 from './components/layouts/sparepartsbidashboard24_25.js'

//Container Imports Tracking
// import ContainerImportsTracking from './components/layouts/containerimportstracking.js'

//TVP Management Dashboard
import TVPManagementDashboard from "./components/layouts/tvpManagementDashboard.js"
import ScreenArchival from "./components/layouts/screenarchival.js"
import ExportContainers from './components/containermanagement/exportcontainers/exportcontainers.js';

import ContainerTrackingImports from './components/layouts/containerTrackingImports.js';
import ContainerTrackingImportsDetails from './components/layouts/ContainerTrackingImportsDetails.js';
import ImportContainers from './components/containermanagement/importcontainers/importcontainers.js';
import containerTrackingImports from './components/layouts/containerTrackingImports.js';
import EmissionTabs from './components/layouts/emissiontabs.js';
import Dashboard from './components/manage/consignmentdashboardnew.js';
import SustainabilityDashboard from './components/manage/sustainabilitydashboard.jsx';

import HolidayCalendar from './components/layouts/HolidayCalendar.jsx';
// import Favorites from './components/layouts/Favorites.jsx';
import NewsFeedComponent from './components/layouts/NewsFeedComponent.jsx';
import LspCeoDashboardTest from './components/layouts/lspceodashboardTest.js';
import OperatingClusters from './components/layouts/operatingClusters.js'
import FleetAvailability from './components/layouts/fleetavailability.js'
import MTDTargetvsAchievement from './components/layouts/mtdtargetVSachievement.js'
import MobileFleetAvailability from './components/layouts/mobilefleetavailability.js'
import TotalFreight from './components/layouts/totalfreight.js'

class Root extends React.Component {

    render() {
        return (
        <Provider store={store}>
            <BrowserRouter basename={'/'}>
                <ScrollContext>
                    <Switch>
 
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Login} />
                        <Route exact path={`${process.env.PUBLIC_URL}/enmuserlogin/:token`} component={EnmUserLogin} />
                        <Route exact path={`${process.env.PUBLIC_URL}/tptlogin`} component={Login} />
                        <Route exact path={`${process.env.PUBLIC_URL}/optirun`} component={Login} />
                        <Route exact path={`${process.env.PUBLIC_URL}/loadmanagement/login`} component={Login} />
                        <Route path={`${process.env.PUBLIC_URL}/loadmanagement/logout`} component={Logout} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/login`} component={Login} />
                        <Route path={`${process.env.PUBLIC_URL}/register`} component={Register} />
                        <Route path={`${process.env.PUBLIC_URL}/lmregister`} component={LmRegister} />
                        <Route path={`${process.env.PUBLIC_URL}/lmlogin`} component={LmLogin} />
                        <Route path={`${process.env.PUBLIC_URL}/authcallback`} component={AuthCallBack} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/forget-password`} component={ForgetPassword} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/reset-password`} component={ResetPassword} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/coming-soon`} component={Comingsoon} />
                        <Route path={`${process.env.PUBLIC_URL}/zoho/token`} component={Token} />
                        <Route path={`${process.env.PUBLIC_URL}/zoho/accounts`} component={Accounts} />
                        <Route path={`${process.env.PUBLIC_URL}/zoho/folders/:accountId`} component={Folders} />
                        <Route path={`${process.env.PUBLIC_URL}/zoho/messages/:accountId/:folderId/:folderName`} component={ZohoMessages} />
                        <Route path={`${process.env.PUBLIC_URL}/zoho/attachmentinfo/:accountId/:folderId/:messageId/:subject/:folderName`} component={AttachmentInfo} />
                        <Route path={`${process.env.PUBLIC_URL}/zoho/attachment/:accountId/:folderId/:messageId/:attachmentId/:attachmentName/:subject`} component={Attachment} />
                        <Route path={`${process.env.PUBLIC_URL}/zoho/refreshToken`} component={RefreshToken} />
                         
                        <Route path={`${process.env.PUBLIC_URL}/pages/maintenance`} component={Maintenance} />
                        <Route path={`${process.env.PUBLIC_URL}/registration/activation`} component={Comingsoon} />


                        <Route path={`${process.env.PUBLIC_URL}/resetpassword`} component={ResetPassword} />

                        <Route path={`${process.env.PUBLIC_URL}/usermailmap/:type/:ticketid`} component={ManageUserMailMap} />
                        <Route path={`${process.env.PUBLIC_URL}/map/:type/:code/:leg`} component={AndriodMap} />
                        <Route path={`${process.env.PUBLIC_URL}/livemaptruck`} component={LiveTruckMap} />
                        <Route path={`${process.env.PUBLIC_URL}/ledlivemaptruck`} component={LEDLiveTruckMapComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/goldenzonedashboard`} component={GoldeZoneDashboard} />

                        <Route path={`${process.env.PUBLIC_URL}/msilvesselstatus`} component={MSILVesselSchedule} />
                        <Route path={`${process.env.PUBLIC_URL}/managemsilvesselstatus`} component={MSILVesselSchedule} />
                        <Route path={`${process.env.PUBLIC_URL}/railstock`} component={RakeStockStatus} />
                        <Route path={`${process.env.PUBLIC_URL}/mgmtplanningstatus`} component={PlanningStatus} />
                        <Route path={`${process.env.PUBLIC_URL}/mgmtemptytruckvicinity`} component={EmptyTrucksVicinity} />
                        <Route path={`${process.env.PUBLIC_URL}/mgmtlivetrucksinfo`} component={LiveTrucksInfo} />
                        <Route path={`${process.env.PUBLIC_URL}/mgmtdashboardsummary`} component={DashboardSummary} />
                        <Route path={`${process.env.PUBLIC_URL}/mgmtsafetyreport`} component={SafetyReport} />
                        <Route path={`${process.env.PUBLIC_URL}/mgmtdivisionhealthreport`} component={DivisionHealthReport} />
                        <Route path={`${process.env.PUBLIC_URL}/mgmtweathermap`} component={WeatherMap} />
                        <Route path={`${process.env.PUBLIC_URL}/mgmtbrmdashboard`} component={BRMDashboardMgmtMobile} />
                        <Route path={`${process.env.PUBLIC_URL}/mobile/:screen/:user/:code/:sdate/:edate/:frequency`} component={AndriodGridMap} />
                        <Route path={`${process.env.PUBLIC_URL}/mobilemapview/:screen/:user/:dept/:code/:trip_type`} component={AndriodGridMap} />
                        <Route path={`${process.env.PUBLIC_URL}/mobilemap/:screen/:truckno`} component={AndriodGridMap} />
                        <Route path={`${process.env.PUBLIC_URL}/mapview/:screen/:user/:code/:sdate/:edate`} component={AndriodGridMap} />
                        <Route path={`${process.env.PUBLIC_URL}/railmap/:vehicle_id/:dealer_code/:truck_no/:sdate/:edate`} component={AndriodTPTRailMap} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/mantis`} component={Mantis} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/route`} component={RouteViewMap} />
                        <Route path={`${process.env.PUBLIC_URL}/dealermap`} component={DealerAppMap} />
                        <Route path={`${process.env.PUBLIC_URL}/dealerapp`} component={DealerAppStatic} />
                        <Route path={`${process.env.PUBLIC_URL}/mobilefleetavailability/:type/:transporterCode`} component={MobileFleetAvailability} />
                       <App>
                        <Route path={`${process.env.PUBLIC_URL}/dashboard/default`} component={Default} />
                        <Route path={`${process.env.PUBLIC_URL}/dashboard/snddefault`} component={Default} />
                        <Route path={`${process.env.PUBLIC_URL}/dashboard/prtdefault`} component={Default} />
                        <Route path={`${process.env.PUBLIC_URL}/dashboard/tnpdefault`} component={Default} />
                        <Route path={`${process.env.PUBLIC_URL}/dashboard/scdefault`} component={Default} />
                        <Route path={`${process.env.PUBLIC_URL}/dashboard/overview`} component={Overview} />
                        <Route path={`${process.env.PUBLIC_URL}/assets`} component={Assets} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/geofences`} component={GeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/sndgeofences`} component={GeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/prtgeofences`} component={GeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/tnpgeofences`} component={GeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/scgeofences`} component={GeoFence} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/geofences`} component={TptGeoFence} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/manage/addgeofence`} component={AddGeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/sndaddgeofence`} component={AddGeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/prtaddgeofence`} component={AddGeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/tnpaddgeofence`} component={AddGeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/scaddgeofence`} component={AddGeoFence} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/addtptgeofence`} component={AddTptGeoFence} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/editGeofence/:geofencename`} component={EditGeoFence} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/editTptGeofence/:geofencename`} component={EditTptGeoFence} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/editweatherevent/:eventname/:pageType`} component={EditWeatherEvent} />
                        <Route path={`${process.env.PUBLIC_URL}/batchwisecatalysis/:batchname`} component={BatchWiseCatalysis} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/trucks`} component={ManageTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/sndtrucks`} component={ManageTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/prttrucks`} component={ManageTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/tnptrucks`} component={ManageTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/sctrucks`} component={ManageTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/consignments`} component={ManageConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/sndconsignments`} component={ManageConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/prtconsignments`} component={ManageConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpconsignments`} component={ManageConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/insidemsilvicinty`} component={InsideMSIL} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/transporters`} component={ManageTransporters} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/sndtransporters`} component={ManageTransporters} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/prttransporters`} component={ManageTransporters} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/tnptransporters`} component={ManageTransporters} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/sctransporters`} component={ManageTransporters} />
                        <Route path={`${process.env.PUBLIC_URL}/departments`} component={ManageDepartments} />
                        <Route path={`${process.env.PUBLIC_URL}/sndconsignees`} component={ManageSNDConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/prtconsignees`} component={ManagePRTConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/moveconsignments`} component={ManageMOVEConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/prtconsigners`} component={ManagePRTConsigners} />
                        <Route path={`${process.env.PUBLIC_URL}/sndtransittimes`} component={ManageSNDTransitTimes} />
                        <Route path={`${process.env.PUBLIC_URL}/prttransittimes`} component={ManagePRTTransitTimes} />
                        <Route path={`${process.env.PUBLIC_URL}/snddepartmentvicinity/sndg`} component={VicinityScreen} />
                        <Route path={`${process.env.PUBLIC_URL}/prtdepartmentvicinity/log-prt`} component={VicinityScreen} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpdepartmentvicinity/log-tnp`} component={VicinityScreen} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/truckwithingeofence`} component={TptVicinityScreen} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/sndconfigurations`} component={ManageConfigurations} />
                        <Route path={`${process.env.PUBLIC_URL}/prtconfigurations`} component={ManageConfigurations} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpconfigurations`} component={ManageConfigurations} />
                        <Route path={`${process.env.PUBLIC_URL}/scconfigurations`} component={ManageConfigurations} />
                        <Route path={`${process.env.PUBLIC_URL}/overspeed`} component={ReportOverspeed} />
                        <Route path={`${process.env.PUBLIC_URL}/enroutestop`} component={ReportEnrouteStoppages} />
                        <Route path={`${process.env.PUBLIC_URL}/chaformat`} component={ManageChaformat} />
                        <Route path={`${process.env.PUBLIC_URL}/imports/consignments`} component={ManageImportsConsignments} /> 
                        <Route path={`${process.env.PUBLIC_URL}/imports/consignmentsnew`} component={ManageImportsConsignmentsNew} />
                        <Route path={`${process.env.PUBLIC_URL}/imports/deliveredContainers`} component={ManageImportsDeliveredContainers} />
                        <Route path={`${process.env.PUBLIC_URL}/imports/consignmentsbeforemar`} component={ManageImportsConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/imports/freightforwards`} component={ManageImportsConsignmentsFreightForward} />
                        <Route path={`${process.env.PUBLIC_URL}/nearbytrucks`} component={ManageNearByConsignmentTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/nearbysndtrucks`} component={ManageNearByConsignmentTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/nearbyprttrucks`} component={ManageNearByConsignmentTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/nearbysctrucks`} component={ManageNearByConsignmentTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/nearbytnptrucks`} component={ManageNearByConsignmentTrucks} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/carrierlocationonmap`} component={ManageNearByTransporterTrucks} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/courierconsignments`} component={ManageCourierConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/activecourierconsignments`} component={ManageCourierConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/deliveredcourierconsignments`} component={ManageCourierConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/invalidcourierconsignments`} component={ManageCourierConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/consignment-dashboard`} component={ConsignementDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/consignment/:keyword`} component={ConsignementComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/container-dashboard`} component={ContainerDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/sndconsignmentstoday`} component={ConsignmentsTodayDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/prtconsignmentstoday`} component={ConsignmentsTodayDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/prtgeofence`} component={ConsignmentsGeofence} />
                        <Route path={`${process.env.PUBLIC_URL}/sndgeofence`} component={ConsignmentsGeofence} />
                        <Route path={`${process.env.PUBLIC_URL}/scgeofence`} component={ConsignmentsGeofence} />
                        <Route path={`${process.env.PUBLIC_URL}/prtvicinity`} component={ConsigneeVicinity} />
                        <Route path={`${process.env.PUBLIC_URL}/sndvicinity`} component={ConsigneeVicinity} />
                        
                        <Route path={`${process.env.PUBLIC_URL}/prtkpiintransit`} component={ConsignmentsKpi} />
                        <Route path={`${process.env.PUBLIC_URL}/prtkpinightdriving`} component={ConsignmentsKpi} />
                        <Route path={`${process.env.PUBLIC_URL}/prtkpioverspeeding`} component={ConsignmentsKpi} />
                        <Route path={`${process.env.PUBLIC_URL}/prtkpienroute`} component={ConsignmentsKpi} />
                        <Route path={`${process.env.PUBLIC_URL}/prtkpiunloading`} component={ConsignmentsKpi} />
                        <Route path={`${process.env.PUBLIC_URL}/prtkpiloading`} component={ConsignmentsKpi} />

                        <Route path={`${process.env.PUBLIC_URL}/sndkpiintransit`} component={ConsignmentsKpi} />
                        <Route path={`${process.env.PUBLIC_URL}/sndkpinightdriving`} component={ConsignmentsKpi} />
                        <Route path={`${process.env.PUBLIC_URL}/sndkpioverspeeding`} component={ConsignmentsKpi} />
                        <Route path={`${process.env.PUBLIC_URL}/sndkpienroute`} component={ConsignmentsKpi} />
                        <Route path={`${process.env.PUBLIC_URL}/sndkpiunloading`} component={ConsignmentsKpi} />
                        <Route path={`${process.env.PUBLIC_URL}/sndkpiloading`} component={ConsignmentsKpi} />

                        <Route path={`${process.env.PUBLIC_URL}/tnpkpiintransit`} component={ConsignmentsKpi} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpkpinightdriving`} component={ConsignmentsKpi} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpkpioverspeeding`} component={ConsignmentsKpi} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpkpienroute`} component={ConsignmentsKpi} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpkpiunloading`} component={ConsignmentsKpi} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpkpiloading`} component={ConsignmentsKpi} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpnoentrytimes`} component={ConsignmentsKpi} />

                        <Route path={`${process.env.PUBLIC_URL}/superadmin/menus`} component={AdminMenus} />
                        <Route path={`${process.env.PUBLIC_URL}/superadmin/roles`} component={AdminRoles} />
                        <Route path={`${process.env.PUBLIC_URL}/superadmin/usermenus`} component={AdminUserRoles} />
                        <Route path={`${process.env.PUBLIC_URL}/assignmenus`} component={AdminRoleMenus} />
                        <Route path={`${process.env.PUBLIC_URL}/viewSubmenus/:parentmenu`} component={AdminViewSubMenus} />
                        <Route path={`${process.env.PUBLIC_URL}/superadmin/menuposition`} component={AdminMenuReOrder} />
                        <Route path={`${process.env.PUBLIC_URL}/superadmin/assignroles`} component={AssignRoles} />
                        
                        <Route path={`${process.env.PUBLIC_URL}/sndtrucks`} component={ManageTruckConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/prttrucks`} component={ManageTruckConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/tnptrucks`} component={ManageTruckConsignments} />
                        
                        <Route path={`${process.env.PUBLIC_URL}/exports/consignments`} component={ManageExportsConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/pm-road-plan`} component={PipavaMudraRoadPlan} />
                        <Route path={`${process.env.PUBLIC_URL}/view-pm-road-plan`} component={ViewPipavaMudraRoadPlan} />
                        <Route path={`${process.env.PUBLIC_URL}/icd-garhi-plan`} component={IcdGarhiContainerPlan} />
                        <Route path={`${process.env.PUBLIC_URL}/logout`} component={Logout} />
                        <Route path={`${process.env.PUBLIC_URL}/chadocumentreceits`} component={CHADocumentReceits} />
                        <Route path={`${process.env.PUBLIC_URL}/chanhavasheva`} component={CHANhavaSheva} />
                        <Route path={`${process.env.PUBLIC_URL}/chaupdatedestinationport`} component={CHADestinationPort} />
                        <Route path={`${process.env.PUBLIC_URL}/spareschadestinationport`} component={SparesCHADestinationPort} />
                        <Route path={`${process.env.PUBLIC_URL}/dctcontainerupdates`} component={DCTContaierUpdates} />
                        <Route path={`${process.env.PUBLIC_URL}/dctsparescontainerupdates`} component={DCTSparesContaierUpdates} />
                        <Route path={`${process.env.PUBLIC_URL}/deliveredconsignments`} component={DeliveredConsignmentsComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/snddeliveredconsignments`} component={ManageConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/prtdeliveredconsignments`} component={ManageConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpdeliveredconsignments`} component={ManageConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/ticketmanagementsystem`} component={TMS} />
                        
                        <Route path={`${process.env.PUBLIC_URL}/allconsignments`} component={ConsignmentsAllComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/sndallconsignments`} component={ManageConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/prtallconsignments`} component={ManageConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpallconsignments`} component={ManageConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/trucksgps`} component={TrucksGPSData} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/viewcarrierroute`} component={TptTrucksGPSData} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/sndemptyreturntrucks`} component={EmptyReturnedTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/prtemptyreturntrucks`} component={EmptyReturnedTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpemptyreturntrucks`} component={EmptyReturnedTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/sndactivetruckswithinrange`} component={EmptyReturnedTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/prtactivetruckswithinrange`} component={EmptyReturnedTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpactivetruckswithinrange`} component={EmptyReturnedTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/exports/adaniconsignments`} component={ManageExportsConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/exports/transhipmentconsignments`} component={TranshipmentExports} />
                        <Route path={`${process.env.PUBLIC_URL}/railconsignments`} component={ManageRailConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/deliveredrailconsignments`} component={ManageRailConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/ncrconsignments`} component={ManageConsignments} />
                      
                        <Route path={`${process.env.PUBLIC_URL}/sndontripgps`} component={OnTripGPSReport} />
                        
                        <Route path={`${process.env.PUBLIC_URL}/imports/coils`} component={ManageImportsCoils} />

                        <Route path={`${process.env.PUBLIC_URL}/coilimports`} component={CoilImports} />
                        <Route path={`${process.env.PUBLIC_URL}/coilimportsdelivered`} component={CoilImportsDelivered} />
                        
                        <Route path={`${process.env.PUBLIC_URL}/profile/changepassword`} component={ChangePasswordComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/sndtickets`} component={DepartmentTMS} />
                        <Route path={`${process.env.PUBLIC_URL}/tnptickets`} component={DepartmentTMS} />
                        <Route path={`${process.env.PUBLIC_URL}/prttickets`} component={DepartmentTMS} />
                        <Route path={`${process.env.PUBLIC_URL}/sctickets`} component={DepartmentTMS} />
                        
                        <Route path={`${process.env.PUBLIC_URL}/sndclosetickets`} component={DepartmentTMS} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpclosetickets`} component={DepartmentTMS} />
                        <Route path={`${process.env.PUBLIC_URL}/prtclosetickets`} component={DepartmentTMS} />
                        <Route path={`${process.env.PUBLIC_URL}/scclosetickets`} component={DepartmentTMS} />
                        <Route path={`${process.env.PUBLIC_URL}/closetickets`} component={TMS} />
                        <Route path={`${process.env.PUBLIC_URL}/invalidtickets`} component={TMSInvalid} />
                        <Route path={`${process.env.PUBLIC_URL}/chacoils`} component={CHACoilsImports} />
                        <Route path={`${process.env.PUBLIC_URL}/coilsdispatch`} component={CoilsDispatch} />
                        <Route path={`${process.env.PUBLIC_URL}/exports/deliveredconsignments`} component={ExportsDeliveredConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/exports/prtdeliveredconsignments`} component={ExportsPRTDeliveredConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/imports/containers`} component={ManageImportsContainer} />
                        <Route path={`${process.env.PUBLIC_URL}/dashboardtemplate`} component={DashboardTemplate} />
                        <Route path={`${process.env.PUBLIC_URL}/dashboardtemplate1`} component={DashboardTemplate1} />
                        <Route path={`${process.env.PUBLIC_URL}/dashboardsummary`} component={DashboardSummary} />
                        <Route path={`${process.env.PUBLIC_URL}/criticaldelayreasons`} component={DashboardDelayResponse} />
                        <Route path={`${process.env.PUBLIC_URL}/sndcriticaldelayreasons`} component={DashboardDelayResponse} />
                        <Route path={`${process.env.PUBLIC_URL}/prtcriticaldelayreasons`} component={DashboardDelayResponse} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpcriticaldelayreasons`} component={DashboardDelayResponse} />
                        <Route path={`${process.env.PUBLIC_URL}/sccriticaldelayreasons`} component={DashboardDelayResponse} />
                        <Route path={`${process.env.PUBLIC_URL}/couriercriticaldelayreasons`} component={DashboardDelayResponse} />
                        <Route path={`${process.env.PUBLIC_URL}/ticketsdashboard`} component={DashboardTickets} />
                        <Route path={`${process.env.PUBLIC_URL}/snddashboardtemplate`} component={DepartmentDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpdashboardtemplate`} component={DepartmentDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/prtdashboardtemplate`} component={DepartmentDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/scdashboardtemplate`} component={DepartmentDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/sndrakes`} component={RakesConsignmentComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/tnprakes`} component={RakesConsignmentComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/sndforceclosure`} component={ForceClosureComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/prtforceclosure`} component={ForceClosureComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpforceclosure`} component={ForceClosureComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/sndtranshipments`} component={TranshipmentComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/prttranshipments`} component={TranshipmentComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/tnptranshipments`} component={TranshipmentComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/roadpickup`} component={RoadPickUpComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/dashboardprt`} component={PRTDepartmentDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/sndweatheralerts`} component={WeatherComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/prtweatheralerts`} component={WeatherComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/allweatheralerts`} component={WeatherComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/sndtransshipments`} component={TransshipmentTransporterComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/prttransshipments`} component={TransshipmentTransporterComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/tnptransshipments`} component={TransshipmentTransporterComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/sndtransporterconsignments`} component={TransporterComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/prttransporterconsignments`} component={TransporterComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/tnptransporterconsignments`} component={TransporterComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/snddelivertransporterconsignments`} component={TransporterComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/prtdelivertransporterconsignments`} component={TransporterComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpdelivertransporterconsignments`} component={TransporterComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/transportertickets`} component={TransporterTickets} />
                        

                        <Route path={`${process.env.PUBLIC_URL}/sndtranshipmentreports`} component={TranshipmentReportComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/prttranshipmentreports`} component={TranshipmentReportComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/tnptranshipmentreports`} component={TranshipmentReportComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/railtranshipmentreports`} component={TranshipmentReportComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/sndrailtransshipments`} component={RailTranshipment} />
                        <Route path={`${process.env.PUBLIC_URL}/prtrailtransshipments`} component={RailTranshipment} />
                        <Route path={`${process.env.PUBLIC_URL}/tnprailtransshipments`} component={RailTranshipment} />
                        <Route path={`${process.env.PUBLIC_URL}/alloybarsComponent`} component={AlloybarComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/loadingdashboard`} component={LoadingDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/sndloadingdashboard`} component={LoadingDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/prtloadingdashboard`} component={LoadingDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/tnploadingdashboard`} component={LoadingDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/dailyrunningdashboard`} component={DailyRunningDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/dailyrunningmanagement`} component={DailyRunningDashboard} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/unloadingdashboard`} component={UnLoadingDashboard} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/sndtransitanalytics`} component={TransitDashboardComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/prttransitanalytics`} component={TransitDashboardComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/tnptransitanalytics`} component={TransitDashboardComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/sndtransportdashboard`} component={DailyRunningTransporterDashboardComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/prttransportdashboard`} component={DailyRunningTransporterDashboardComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/tnptransportdashboard`} component={DailyRunningTransporterDashboardComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/sndgpsdashboard`} component={GPSDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/prtgpsdashboard`} component={GPSDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpgpsdashboard`} component={GPSDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/manualexports`} component={ManualExports} />
                        <Route path={`${process.env.PUBLIC_URL}/segmentation`} component={SegmentSideBar} />
                        <Route path={`${process.env.PUBLIC_URL}/sndholidaycalendar`} component={HolidayComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/prtholidaycalendar`} component={HolidayComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpholidaycalendar`} component={HolidayComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/transportergpsdashboard`} component={GPSTransporter} />
                        <Route path={`${process.env.PUBLIC_URL}/dashboard/transporterdefault`} component={TransporterIntegrationDashboardComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/ontimedashboard`} component={OnTimeDashboardComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/error-404`} component={Error404} />
                        <Route path={`${process.env.PUBLIC_URL}/shifts`} component={ShiftTimings} />
                        
                        <Route path={`${process.env.PUBLIC_URL}/aircargoform`} component={AirCargoForm} />
                        <Route path={`${process.env.PUBLIC_URL}/aircargos`} component={AirCargoListComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/editaircargoform/:eventname`} component={EditCargoEvent} />
                        <Route path={`${process.env.PUBLIC_URL}/editffnewaircargoform/:eventname`} component={EditFFNewAircargo} />
                        <Route path={`${process.env.PUBLIC_URL}/ffaircargos`} component={FFAirCargoListComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/editffaircargoform/:eventname/:pagetype`} component={EditFFCargoEvent} />
                        <Route path={`${process.env.PUBLIC_URL}/createffaircargos`} component={CreateFFCargoEvent} />
                        <Route path={`${process.env.PUBLIC_URL}/chaaircargos`} component={ChaAirCargoListComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/transportationaircargos`} component={TransporterAirCargoListComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/editchaaircargoform/:eventname/:pagetype`} component={EditChaCargoEvent} />
                        <Route path={`${process.env.PUBLIC_URL}/edittransporteraircargoform/:eventname/:pagetype`} component={EditTransporterCargoEvent} />
                        <Route path={`${process.env.PUBLIC_URL}/editclosureaircargoform/:eventname/:pagetype`} component={EditClosureCargoEvent} />
                        <Route path={`${process.env.PUBLIC_URL}/loadattachment`} component={LoadAttachment} />
                        <Route path={`${process.env.PUBLIC_URL}/Possibletransshipments`} component={PossibleTransshipments} />
                        <Route path={`${process.env.PUBLIC_URL}/sndPossibletransshipments`} component={PossibleTransshipments} />
                        <Route path={`${process.env.PUBLIC_URL}/prtPossibletransshipments`} component={PossibleTransshipments} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpPossibletransshipments`} component={PossibleTransshipments} />
                        <Route path={`${process.env.PUBLIC_URL}/exitloadattachment`} component={LoadAttachmentExit} />
                        <Route path={`${process.env.PUBLIC_URL}/chadocumenthistory`} component={ChaDocumentHistory} />   
                        <Route path={`${process.env.PUBLIC_URL}/railvinconsignments`} component={RailVinConsignments} />                        
                        <Route path={`${process.env.PUBLIC_URL}/sndrailvintransshipments`} component={RailVINTranshipment} />
                        <Route path={`${process.env.PUBLIC_URL}/prtrailvintransshipments`} component={RailVINTranshipment} />
                        <Route path={`${process.env.PUBLIC_URL}/tnprailvintransshipments`} component={RailVINTranshipment} />
                        <Route path={`${process.env.PUBLIC_URL}/vinroadpickup`} component={VinRoadPickUpComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/sndStoppageReport`} component={StoppageReport} />
                        <Route path={`${process.env.PUBLIC_URL}/prtStoppageReport`} component={StoppageReport} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpStoppageReport`} component={StoppageReport} />
                        <Route path={`${process.env.PUBLIC_URL}/sndconsigneeexception`} component={ConsigneEexceptionComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/prtconsigneeexception`} component={ConsigneEexceptionComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/loadarrivals`} component={LoadArrivals} />
                        <Route path={`${process.env.PUBLIC_URL}/sndtransittimesummary`} component={TransitTimeSummary} />
                        <Route path={`${process.env.PUBLIC_URL}/prttransittimesummary`} component={TransitTimeSummary} />
                        <Route path={`${process.env.PUBLIC_URL}/tnptransittimesummary`} component={TransitTimeSummary} />
                        <Route path={`${process.env.PUBLIC_URL}/prttransittimedata`} component={TransitTimeData} />
                        <Route path={`${process.env.PUBLIC_URL}/analytics`} component={GoogleAnalytics} />
                        <Route path={`${process.env.PUBLIC_URL}/returnemptypallets`} component={ReturnPallet} />
                        <Route path={`${process.env.PUBLIC_URL}/sndunloadingdashboard`} component={SNDUnloadingDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/prtunloadingdashboard`} component={SNDUnloadingDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpunloadingdashboard`} component={SNDUnloadingDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/snddistancetravelreport`} component={DistanceTravelledReport} />
                        <Route path={`${process.env.PUBLIC_URL}/prtdistancetravelreport`} component={DistanceTravelledReport} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpdistancetravelreport`} component={DistanceTravelledReport} />
                        <Route path={`${process.env.PUBLIC_URL}/sndprobableaccidents`} component={ProbableAccidentComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/prtprobableaccidents`} component={ProbableAccidentComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/sndrakeschedule`} component={RakesScheduleComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/dealercompensation`} component={DealerCompensation} />
                        <Route path={`${process.env.PUBLIC_URL}/sndmanuallocations`} component={AddManualLocationUpdate} />
                        <Route path={`${process.env.PUBLIC_URL}/sndaddlocation`} component={AddManualLocationUpdate} />
                        <Route path={`${process.env.PUBLIC_URL}/editlocation/:dept/:id`} component={EditManualLocationUpdate} />
                        <Route path={`${process.env.PUBLIC_URL}/prtmanuallocations`} component={ManualLocationUpdate} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpmanuallocations`} component={ManualLocationUpdate} />
                        <Route path={`${process.env.PUBLIC_URL}/prtaddlocation`} component={AddManualLocationUpdate} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpaddlocation`} component={AddManualLocationUpdate} />
                        <Route path={`${process.env.PUBLIC_URL}/prtdailyrunningdashboard`} component={DailyRunningDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/prtlocalconsignments`} component={LocalConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/prtturnaroundtime`} component={PRTTurnAroundTime} />
                        <Route path={`${process.env.PUBLIC_URL}/userlogreport`} component={UserLogReport} />
                        <Route path={`${process.env.PUBLIC_URL}/raildefault`} component={Default} />
                        <Route path={`${process.env.PUBLIC_URL}/railloadattachment`} component={LoadAttachment} />
                        <Route path={`${process.env.PUBLIC_URL}/railexitloadattachment`} component={LoadAttachmentExit} />
                        <Route path={`${process.env.PUBLIC_URL}/actlcontainers`} component={ACTLContainers} />
                        <Route path={`${process.env.PUBLIC_URL}/dealercompolddata`} component={DealerCompensationOldData} />
                        <Route path={`${process.env.PUBLIC_URL}/assignactlcontainers`} component={AssignACTLContainers} />
                        <Route path={`${process.env.PUBLIC_URL}/livemaptrucklocation`} component={LiveTruckLocationMap} />
                        <Route path={`${process.env.PUBLIC_URL}/breakclusters`} component={BreaksClusterComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/livetrucksinfo`} component={LiveTrucksInfo} />
                        <Route path={`${process.env.PUBLIC_URL}/snduniquetrucktrips`} component={UniqueTrucksTrips} />
                        <Route path={`${process.env.PUBLIC_URL}/prtuniquetrucktrips`} component={UniqueTrucksTrips} /> 
                        <Route path={`${process.env.PUBLIC_URL}/msildashboard`} component={MSILDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/primarydashboard`} component={PrimaryDashboard} /> 
                        <Route path={`${process.env.PUBLIC_URL}/secondarydashboard`} component={SecondaryDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/stockyarddashboard`} component={StockyardDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/catalyticmissingbreakclusters`} component={CatalyticMissingBreakClusters} />
                        <Route path={`${process.env.PUBLIC_URL}/actlplanning`} component={ACTLContainerPlan} />
                        <Route path={`${process.env.PUBLIC_URL}/sndregionwisereport`} component={SndRegionWiseReport} />
                        <Route path={`${process.env.PUBLIC_URL}/sndbilling`} component={Billing} />
                        <Route path={`${process.env.PUBLIC_URL}/prtbilling`} component={Billing} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpbilling`} component={Billing} />
                        <Route path={`${process.env.PUBLIC_URL}/scimportsbilling`} component={BillingSC} />
                        <Route path={`${process.env.PUBLIC_URL}/scexportsbilling`} component={BillingSC} />
                        <Route path={`${process.env.PUBLIC_URL}/sccoilsbilling`} component={BillingSC} />
                        <Route path={`${process.env.PUBLIC_URL}/sndprimarybilling`} component={Billing} />
                        <Route path={`${process.env.PUBLIC_URL}/sndsecondarybilling`} component={Billing} />
                        <Route path={`${process.env.PUBLIC_URL}/batchwiseclusters`} component={BatchWiseClusters} />
                        <Route path={`${process.env.PUBLIC_URL}/updaterailanalytics`} component={UpdateRailAnaytics} />
                        <Route path={`${process.env.PUBLIC_URL}/railanalytics`} component={RailAnaytics} />
                        <Route path={`${process.env.PUBLIC_URL}/addlclimports`} component={AddLclImports} />
                        <Route path={`${process.env.PUBLIC_URL}/lclimports`} component={LclImports} />
                        <Route path={`${process.env.PUBLIC_URL}/importsshipments`} component={ImportsShipments} />
                        <Route path={`${process.env.PUBLIC_URL}/routedeviation`} component={RouteDeviationMap} />
                        <Route path={`${process.env.PUBLIC_URL}/newroutedeviation`} component={RouteDeviationMap} />
                        <Route path={`${process.env.PUBLIC_URL}/deviationroutes`} component={DeviationRoutes} />
                        <Route path={`${process.env.PUBLIC_URL}/optimizeroutes`} component={OptimizeDeviationRoutes} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/weatherheatmap`} component={WeatherHeatMap} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/statebordersgeofencemap`} component={StateBorderGeofenceMap} />
                        <Route path={`${process.env.PUBLIC_URL}/returntrips`} component={ReturnTrips} />
                        <Route path={`${process.env.PUBLIC_URL}/deviationroutesnew`} component={NewDeviationRoutes} />
                        <Route path={`${process.env.PUBLIC_URL}/sndtruckavailability`} component={TruckAvailability} />
                        <Route path={`${process.env.PUBLIC_URL}/prttruckavailability`} component={TruckAvailability} />
                        <Route path={`${process.env.PUBLIC_URL}/tnptruckavailability`} component={TruckAvailability} />
                        <Route path={`${process.env.PUBLIC_URL}/returnTripsDashboard`} component={ReturnTripsDashboardChart} />
                        <Route path={`${process.env.PUBLIC_URL}/prtreturnTripsDashboard`} component={ReturnTripsDashboardChart} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpreturnTripsDashboard`} component={ReturnTripsDashboardChart} />
                        <Route path={`${process.env.PUBLIC_URL}/billingdashboard`} component={BillingDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/sndbillingdashboard`} component={BillingDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/prtbillingdashboard`} component={BillingDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpbillingdashboard`} component={BillingDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/scbillingdashboard`} component={BillingDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/viewbillaction`} component={ViewBillAction} />
                        <Route path={`${process.env.PUBLIC_URL}/lmstates`} component={LmStates} />
                        <Route path={`${process.env.PUBLIC_URL}/lmvehiclecapacity`} component={LmVehicleCapacity} />
                        <Route path={`${process.env.PUBLIC_URL}/lmfreights`} component={LmFreights} />
                        <Route path={`${process.env.PUBLIC_URL}/lmrouteconfig`} component={LmRouteConfig} />
                        <Route path={`${process.env.PUBLIC_URL}/lmconsignees`} component={LmConsignees} />
                        <Route path={`${process.env.PUBLIC_URL}/lmbaseconfig`} component={LmBaseConfig} />
                        <Route path={`${process.env.PUBLIC_URL}/lmloadmanager`} component={LmLoadManager} />
                        <Route path={`${process.env.PUBLIC_URL}/detentionreport`} component={DetentionReport} />
                        <Route path={`${process.env.PUBLIC_URL}/usdrates`} component={USDRates} />
                        <Route path={`${process.env.PUBLIC_URL}/icdrates`} component={ICDRates} />
                        <Route path={`${process.env.PUBLIC_URL}/automotivegeofences`} component={AutomotiveGeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/addautomotivegeofence`} component={AddAutomotiveGeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/editautomotive/:rowid`} component={EditAutomotiveGeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/lmtransporters`} component={LmTransporters} />
                        <Route path={`${process.env.PUBLIC_URL}/lmaddtransporters`} component={LmAddTransporters} />

                        <Route path={`${process.env.PUBLIC_URL}/altorates`} component={AltoRates} />
                        <Route path={`${process.env.PUBLIC_URL}/lmshareofbusiness`} component={LmShareOfBusiness} />
                        <Route path={`${process.env.PUBLIC_URL}/lmcurrentsob`} component={LmCurrentShareOfBusiness} />
                        <Route path={`${process.env.PUBLIC_URL}/lmloadshare/:eventname`} component={LmLoadShare} />
                        <Route path={`${process.env.PUBLIC_URL}/lmsobzones`} component={LmSobZones} />
                        <Route path={`${process.env.PUBLIC_URL}/railinventory`} component={RailInventory} />
                        <Route path={`${process.env.PUBLIC_URL}/oembreaks`} component={OEMBreaks} />
                        <Route path={`${process.env.PUBLIC_URL}/prtoptimizeroutes`} component={PRTOptimizeDeviationRoutes} />
                        <Route path={`${process.env.PUBLIC_URL}/prtdeviationroutes`} component={PRTDeviationRoutes} />
                        <Route path={`${process.env.PUBLIC_URL}/markdeliveredrailconsignments`} component={MarkDeliveredRailConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/lmloadsummary`} component={LmLoadSummary} />
                        <Route path={`${process.env.PUBLIC_URL}/viewLoadSummaryDetails/:eventname`} component={ViewLoadSummaryDetails} />
                        <Route path={`${process.env.PUBLIC_URL}/sndreturnrtripclusters`} component={ReturnTripsBreakClusters} />
                        <Route path={`${process.env.PUBLIC_URL}/prtreturnrtripclusters`} component={ReturnTripsBreakClusters} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpreturnrtripclusters`} component={ReturnTripsBreakClusters} />
                        <Route path={`${process.env.PUBLIC_URL}/sndrouteplanning`} component={RoutePlanning} />
                        <Route path={`${process.env.PUBLIC_URL}/prtrouteplanning`} component={RoutePlanning} />
                        <Route path={`${process.env.PUBLIC_URL}/tnprouteplanning`} component={RoutePlanning} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpoptimizeroutes`} component={TNPOptimizeDeviationRoutes} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpdeviationroutes`} component={TNPDeviationRoutes} />
                        <Route path={`${process.env.PUBLIC_URL}/railplanning`} component={RailPlanning} />
                        <Route path={`${process.env.PUBLIC_URL}/lmtruckavailability/:eventname`} component={LmTruckAvailability} />
                        <Route path={`${process.env.PUBLIC_URL}/lmloadingports`} component={LmLoadingPorts} />
                        <Route path={`${process.env.PUBLIC_URL}/sndTransitAnalyticsDashbord`} component={TransitAnalytics} />
                        <Route path={`${process.env.PUBLIC_URL}/exportstracking`} component={ExportsContainerTracking} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/exportcontainers`} component={ContainerTracking} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/exportcontainers`} component={ExportContainers} />
                        <Route path={`${process.env.PUBLIC_URL}/tracking_details`} component={ContainerTrackingDetails} />
                        <Route path={`${process.env.PUBLIC_URL}/sparesexportstracking`} component={SparesExportsContainerTracking} />
                        <Route path={`${process.env.PUBLIC_URL}/sparesexportsdelivered`} component={SparesExportsContainerDelivered} />
                        <Route path={`${process.env.PUBLIC_URL}/sparesdestinationports`} component={SparesExportsDestinationPorts} />
                        <Route path={`${process.env.PUBLIC_URL}/prtunutilizedtrucks`} component={PRTUnutilizedTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/analyticsdashboard`} component={AnalyticsDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/railloadoptmization`} component={RailLoadOptimizationComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/getOptimizedLoadReport`} component={GetLoadOptimizationReport} />

                        <Route path={`${process.env.PUBLIC_URL}/msilvesselschedule`} component={MSILVesselSchedule} />
                        <Route path={`${process.env.PUBLIC_URL}/managemsilvesselschedule`} component={MSILVesselSchedule} />
                        <Route path={`${process.env.PUBLIC_URL}/sndemptytruckvicinity`} component={EmptyTrucksVicinity} />
                        <Route path={`${process.env.PUBLIC_URL}/sndemptytruckvicinitymgmt`} component={EmptyTrucksVicinity} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/emptytruckvicinity`} component={TptEmptyTrucksVicinity} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/rakearrivals`} component={RakeArrivals} />
                        <Route path={`${process.env.PUBLIC_URL}/lsprakearrivals`} component={RakeArrivals} />
                        <Route path={`${process.env.PUBLIC_URL}/prtemptytruckvicinity`} component={EmptyTrucksVicinity} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpemptytruckvicinity`} component={EmptyTrucksVicinity} />
                        <Route path={`${process.env.PUBLIC_URL}/managementdashboard`} component={ManagementDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/rakestockstatus`} component={RakeStockStatus} />
                        <Route path={`${process.env.PUBLIC_URL}/railstockstatus`} component={RakeStockStatus} />
                        <Route path={`${process.env.PUBLIC_URL}/sndmanagementdashboard`} component={ManagementDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/prtmanagementdashboard`} component={ManagementDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpmanagementdashboard`} component={ManagementDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/scmanagementdashboard`} component={ManagementDashboard} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/safetyreport`} component={UnderConstruction} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/sndplanningstatus`} component={PlanningStatus} />
                        <Route path={`${process.env.PUBLIC_URL}/sndplanningstatusmgmt`} component={PlanningStatus} />
                        <Route path={`${process.env.PUBLIC_URL}/logisticshealthappstatus`} component={UnderConstruction} />
                        
                        <Route path={`${process.env.PUBLIC_URL}/sndsafetyreport`} component={SafetyReport} />
                        <Route path={`${process.env.PUBLIC_URL}/scsafetyreport`} component={SafetyReport} />
                        <Route path={`${process.env.PUBLIC_URL}/safetyreportmgmt`} component={SafetyReport} />
                        <Route path={`${process.env.PUBLIC_URL}/divisionhealthreport`} component={DivisionHealthReport} />
                        <Route path={`${process.env.PUBLIC_URL}/snddivisionhealthreport`} component={DivisionHealthReport} />
                        <Route path={`${process.env.PUBLIC_URL}/prtdivisionhealthreport`} component={DivisionHealthReport} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpdivisionhealthreport`} component={DivisionHealthReport} />
                        <Route path={`${process.env.PUBLIC_URL}/scdivisionhealthreport`} component={DivisionHealthReport} />
                        <Route path={`${process.env.PUBLIC_URL}/loadfactors`} component={LoadFactors} />
                        <Route path={`${process.env.PUBLIC_URL}/mllsummary`} component={MLLRailSummaryComponent} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/mllconsignments`} component={MLLConsignments} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/mllconsignments/:refno`} component={MLLConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/createmllconsignmentbatch/:refno`} component={MLLCreateBatch} />
                        <Route path={`${process.env.PUBLIC_URL}/mllmarkarrivalloadingyard/:batchno`} component={MllMarkArrivalLoadingYard} />
                        <Route path={`${process.env.PUBLIC_URL}/lmvoutput`} component={LmVOutput} />
                        <Route path={`${process.env.PUBLIC_URL}/lmvoutputdetails/:eventname`} component={LmVOutputDetails} />
                        <Route path={`${process.env.PUBLIC_URL}/mllwagonchecksheet`} component={MllWagonCheck} />
                        <Route path={`${process.env.PUBLIC_URL}/addmllwagonchecksheet`} component={AddMllWagonCheck} />
                        <Route path={`${process.env.PUBLIC_URL}/mlltrainloadchecksheet`} component={MllTrainLoadCheck} />
                        <Route path={`${process.env.PUBLIC_URL}/addmlltrainloadchecksheet`} component={AddMllTrainLoadCheck} />
                        <Route path={`${process.env.PUBLIC_URL}/mlltrainunloadchecksheet`} component={MllTrainUnLoadCheck} />
                        <Route path={`${process.env.PUBLIC_URL}/lmdepartments`} component={LmDepartments} />

                        <Route path={`${process.env.PUBLIC_URL}/mllgeofences`} component={GeofenceMap} />
                        <Route path={`${process.env.PUBLIC_URL}/lmusers`} component={LmUsers} />
                        <Route path={`${process.env.PUBLIC_URL}/lmroles`} component={LmRoles} />                        
                        <Route path={`${process.env.PUBLIC_URL}/addmlltrainunloadchecksheet`} component={AddMllTrainUnLoadCheck} />
                        <Route path={`${process.env.PUBLIC_URL}/lmuseractivities`} component={LmUserActivities} />
                        <Route path={`${process.env.PUBLIC_URL}/mllallconsignments`} component={MLLConsignments} />
                        {/* replacing lmwelcome with powerbiscreen */}
                        <Route path={`${process.env.PUBLIC_URL}/lmwelcome`} component={PowerBIScreen} />
                        <Route path={`${process.env.PUBLIC_URL}/lmpageaccess`} component={LmPageAccess} />
                        <Route path={`${process.env.PUBLIC_URL}/lmloadingportaccess`} component={LmLoadingPortAccess} />
                        <Route path={`${process.env.PUBLIC_URL}/mlltransporters`} component={MLLTransporters} />
                        <Route path={`${process.env.PUBLIC_URL}/mlltrucks`} component={MLLTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/mllplants`} component={MLLPlants} />
                        <Route path={`${process.env.PUBLIC_URL}/mlldealers`} component={MLLDealers} />
                        <Route path={`${process.env.PUBLIC_URL}/mllsnddepartmentvicinity/sndg`} component={MllTrucksWithInGeofence} />
                        <Route path={`${process.env.PUBLIC_URL}/mllsndvicinity`} component={ConsigneeVicinity} />
                        <Route path={`${process.env.PUBLIC_URL}/mllnearbysndtrucks`} component={MllConsignmentNearByTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/mllsndgeofence`} component={ConsignmentsGeofence} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/mllsndgeofences`} component={GeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/mllsndaddgeofence`} component={AddGeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/mlleditGeofence/:geofencename`} component={EditGeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/lmuserlogreport`} component={LmUserLogReport} />
                        <Route path={`${process.env.PUBLIC_URL}/mlllastmilebatch/:refno`} component={MLLLastMileBatch} />
                        <Route path={`${process.env.PUBLIC_URL}/mlltruckgps`} component={MLLTrucksGPS} />
                        <Route path={`${process.env.PUBLIC_URL}/mllloadingyardarrival/:refno`} component={MLLLoadingYardArrival} />
                        <Route path={`${process.env.PUBLIC_URL}/deviceslist`} component={RegisteredDevices} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/deviceslist`} component={TptRegisteredDevices} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/pendinginstallations`} component={TptPendingInstallations} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/brmdashboard`} component={BRMDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/brmdashboardmgmt`} component={BRMDashboardMgmt} />
                        <Route path={`${process.env.PUBLIC_URL}/railbrmdashboard`} component={BRMDashboard} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/gpsintegrationstatus`} component={tptGpsIntegration} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/mllloadingofficers`} component={MLLLoadingOfficers} />
                        <Route path={`${process.env.PUBLIC_URL}/mllfirstmiletransshipments`} component={MLLFirstMileTransshipments} />
                        <Route path={`${process.env.PUBLIC_URL}/mlllastmiletransshipments`} component={MLLLastMileTransshipments} />
                        <Route path={`${process.env.PUBLIC_URL}/mllfirstmileretrivals`} component={MLLFirstMileRetrivals} />
                        <Route path={`${process.env.PUBLIC_URL}/mllhandoverretrivals`} component={MLLHandOverRetrivals} />
                        <Route path={`${process.env.PUBLIC_URL}/mllrsopod`} component={MLLRSOPOD} />
                        <Route path={`${process.env.PUBLIC_URL}/prtontimedashboard`} component={PRTOnTimeComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/mllrakeretrivals`} component={MLLRakeRetrivals} />
                        <Route path={`${process.env.PUBLIC_URL}/mllrsoretrivals`} component={MLLRSORetrivals} />
                        <Route path={`${process.env.PUBLIC_URL}/mlldevicedashboard`} component={MLLDeviceDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/mllissuedevicetoba`} component={MLLIssueToBA} />
                        <Route path={`${process.env.PUBLIC_URL}/gpsdeviceplanning`} component={MLLGPSDevicePlanning} />
                        <Route path={`${process.env.PUBLIC_URL}/viewdeviceplanning/:refno`} component={MLLGPSDevicePlanning} />
                        <Route path={`${process.env.PUBLIC_URL}/mllnotifications`} component={MLLNotifications} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/overspeed`} component={TptReportOverspeed} />    
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/gpseodanalysis`} component={TPTGpsEodAnalysis} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/monthlydistancetraveledreport`} component={TPTGpsEodAnalysis} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/frieghtfirstmilerates`} component={MLLFrieghtFirstMileRates} />
                        <Route path={`${process.env.PUBLIC_URL}/loadingunioncharges`} component={MLLFrieghtFirstMileRates} />
                        <Route path={`${process.env.PUBLIC_URL}/rrfreightcharges`} component={MLLFrieghtFirstMileRates} />
                        <Route path={`${process.env.PUBLIC_URL}/freightlastmilerates`} component={MLLFrieghtFirstMileRates} />
                        <Route path={`${process.env.PUBLIC_URL}/frieghtrsotodealerrates`} component={MLLFrieghtFirstMileRates} />
                        <Route path={`${process.env.PUBLIC_URL}/mllgpsdevices`} component={MLLGPSDevices} />
                        <Route path={`${process.env.PUBLIC_URL}/mllshiptorso`} component={MLLRakeShipToRSORetrivals} />
                        <Route path={`${process.env.PUBLIC_URL}/mllshippedtosource`} component={MLLShipToPlantRSO} />
                        <Route path={`${process.env.PUBLIC_URL}/createuser`} component={MllRegistration} />
                        <Route path={`${process.env.PUBLIC_URL}/mllassignroles`} component={MllAssignRoles} />
                        <Route path={`${process.env.PUBLIC_URL}/mllusermenus`} component={MllAdminUserRoles} />
                        <Route path={`${process.env.PUBLIC_URL}/mlllastmilerso/:refno`} component={MLLLastMileRSOBatch} />
                        <Route path={`${process.env.PUBLIC_URL}/mllkpidashboard`} component={MllKPIDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/mlldamageanalysis`} component={MllDamageAnalysis} />
                        <Route path={`${process.env.PUBLIC_URL}/gpsbilling/:month/:year`} component={GPSBilling} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptgpsbilling/:month/:year`} component={TPTGPSBilling} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptgpsbillingnonmsil/:month/:year`} component={TPTGPSBillingNonMSIL} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/subusers`} component={TPTSubUsers} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/secondaypaymentsummary`} component={SecondaryPaymentSummary} />
                        <Route path={`${process.env.PUBLIC_URL}/damagedashboard`} component={DamageDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/raildamagedashboard`} component={DamageDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/gpsbillingdashboard`} component={GPSBillingDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/viewgpsbillaction`} component={ViewGPSBillAction} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptgpsbillingdashboard`} component={TPTGPSBillingDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptviewgpsbillaction`} component={TPTViewGPSBillAction} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptgpsnonmsilbillingdashboard`} component={TPTGPSNonMSILBillingDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptviewgpsnonmsilbillaction`} component={TPTViewGPSNonMSILBillAction} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/trucksdistancematrix`} component={TPTTrucksDistanceMatrix} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/loadplandashboard`} component={LoadPlanToDashboard} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/vehicleregister`} component={VehicleRegister} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/trucks`} component={TPTTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/carriers`} component={TPTCarrier} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/groups`} component={TPTGroup} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/transporterperformancedashboard`} component={TransporterPerformanceDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/prtTransporterperformancedashboard`} component={PrtTransporterPerformanceDashboard} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/masterperformancedashboard`} component={MasterPerformanceDashboard} /> */}
                        {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/geofenceincursion`} component={TPTGeofenceIncursion} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/managedrivers`} component={TPTDrivers} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/nightdriving`} component={TPTNightDriving} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/transporterinformtion`} component={TransporterInfo} />
                        <Route path={`${process.env.PUBLIC_URL}/prttransporterinformtion`} component={PrtTransporterInfo} />
                        <Route path={`${process.env.PUBLIC_URL}/railoperationsmonitoring`} component={RailOperationsMonitoring} />
                        <Route path={`${process.env.PUBLIC_URL}/exitstatus`} component={ExitStatus} />
                        <Route path={`${process.env.PUBLIC_URL}/retrivalexitreport`} component={RetrivalExitReport} />
                        <Route path={`${process.env.PUBLIC_URL}/citywisedieselprices`} component={CityWiseDieselPrices} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptrailsummary`} component={TPTRailSummary} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptconsignments/:refno/:railno`} component={TPTConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/createtptconsignmentbatch/:refno`} component={TPTCreateBatch} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptmarkarrivalloadingyard/:batchno`} component={TPTMarkArrivalLoadingYard} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptloadingyardarrival/:refno`} component={TPTLoadingYardArrival} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/addtptwagonchecksheet`} component={TPTAddWagonCheck} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/addtpttrainloadchecksheet`} component={TPTAddTrainLoadCheck} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/addtpttrainunloadchecksheet`} component={TPTAddTrainUnLoadCheck} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptallconsignments`} component={TPTConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptlastmilebatch/:refno`} component={TPTLastMileBatch} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/devices`} component={TPTDevices} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/loadingofficers`} component={TPTLoadingOfficers} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/transporters`} component={TPTTransporters} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/oemmaster`} component={TPTOEMMaster} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/oemlocations`} component={TPTOEMLocations} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/oemdealers`} component={TPTOEMDealers} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/oemtransittimes`} component={TPTOEMTransitTimes} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/oemconsignments`} component={TPTOEMConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/harshbreak`} component={TPTHarshBreaking} />    
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/gpssingletracking`} component={TPTGPSSingleScreen} />     */}
                        <Route path={`${process.env.PUBLIC_URL}/damageanalysis`} component={DamageAnalysis} />
                        <Route path={`${process.env.PUBLIC_URL}/importscoiltracking`} component={ImportsCoilTracking} />
                        <Route path={`${process.env.PUBLIC_URL}/railloadplananalysis`} component={RailLoadPlanAnalysis} />
                        {/*
                        <Route path={`${process.env.PUBLIC_URL}/createanalticslayout`} component={CreateAnalyticFrames} /> 
                        <Route path={`${process.env.PUBLIC_URL}/editanalticslayout/:dashboard`} component={EditAnalyticFrames} />
                        */}
                        <Route path={`${process.env.PUBLIC_URL}/bivolumefrieghts`} component={ZohoAnalyticDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/freightmaster`} component={FreightMaster} />
                        <Route path={`${process.env.PUBLIC_URL}/overviewanalytics`} component={OverviewAnalytics} />
                        <Route path={`${process.env.PUBLIC_URL}/railautoplaning`} component={Railautoplaning} />
                        <Route path={`${process.env.PUBLIC_URL}/coilvendors`} component={Coilvendors} />
                        <Route path={`${process.env.PUBLIC_URL}/coilmsps`} component={Coilmsps} />
                        <Route path={`${process.env.PUBLIC_URL}/shareofbusiness`} component={ShareOfBusiness} />
                        <Route path={`${process.env.PUBLIC_URL}/sobcompletedata`} component={SobCompleteData} />
                        <Route path={`${process.env.PUBLIC_URL}/accidentalpronezones`} component={AccidentalProneZones} />          
                        <Route path={`${process.env.PUBLIC_URL}/addaccidentalpronezone`} component={AddAccidentalProneZone} />
                        <Route path={`${process.env.PUBLIC_URL}/editaccidentalpronezones/:rowId`} component={EditAccidentProneZone} />



                        {/* <Route path={`${process.env.PUBLIC_URL}/accidentalpronezones`} component={accidentalProneZones} /> */}
                        {/* <Route path={`${process.env.PUBLIC_URL}/addaccidentalpronezone`} component={AddAccidentalProneZone} /> */}
                        {/* <Route path={`${process.env.PUBLIC_URL}/editaccidentalpronezones/:rowId`} component={EditAccidentProneZone} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/tnpnoparkingzones`} component={NoParkingZones} />
                        <Route path={`${process.env.PUBLIC_URL}/addtnpnoparkingzones`} component={AddNoParkingZones} />
                        <Route path={`${process.env.PUBLIC_URL}/edittnpnoparkingzones/:rowId`} component={EditNoParkingZones} />
                        <Route path={`${process.env.PUBLIC_URL}/prtnoparkingzones`} component={NoParkingZones} />
                        <Route path={`${process.env.PUBLIC_URL}/addprtnoparkingzones`} component={AddNoParkingZones} />
                        <Route path={`${process.env.PUBLIC_URL}/editprtnoparkingzones/:rowId`} component={EditNoParkingZones} />
                        <Route path={`${process.env.PUBLIC_URL}/sndnoparkingzones`} component={NoParkingZones} />
                        <Route path={`${process.env.PUBLIC_URL}/addsndnoparkingzones`} component={AddNoParkingZones} />
                        <Route path={`${process.env.PUBLIC_URL}/editsndnoparkingzones/:rowId`} component={EditNoParkingZones} />
                        <Route path={`${process.env.PUBLIC_URL}/birailstockstatus`} component={BIDashboardRailStock} />
                        <Route path={`${process.env.PUBLIC_URL}/aircargoexportsform`} component={AirCargoExportForm} />
                        <Route path={`${process.env.PUBLIC_URL}/aircargoexports`} component={AirCargoExportListComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/editaircargoexportsform/:eventname`} component={EditCargoExport} />
                        <Route path={`${process.env.PUBLIC_URL}/transportationaircargoexports`} component={TransporterAirCargoExportsListComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/edittransporteraircargoexportsform/:eventname/:pagetype`} component={EditTransporterExportsCargoEvent} />
                        <Route path={`${process.env.PUBLIC_URL}/chaaircargoexports`} component={ChaAirCargoExportsListComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/editchaaircargoexportsform/:eventname/:pagetype`} component={EditChaExportsCargoEvent} />
                        <Route path={`${process.env.PUBLIC_URL}/error-405`} component={Error405} />
                        <Route path={`${process.env.PUBLIC_URL}/safetyincidents`} component={SafetyIncidents} />
                        <Route path={`${process.env.PUBLIC_URL}/shareofbusinessinterplant`} component={ShareOfBusinessInterPant} />
                        <Route path={`${process.env.PUBLIC_URL}/shareofbusinessinterplantd22`} component={ShareOfBusinessInterPantD22} />
                        <Route path={`${process.env.PUBLIC_URL}/bisndstock`} component={SNDStockDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpuseritems`} component={TnpUserItemsTabs} />
                        <Route path={`${process.env.PUBLIC_URL}/ffaircargoexports`} component={FFAirCargoExportsListComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/editffaircargoexportsform/:eventname/:pagetype`} component={EditFFCargoExports} />
                        <Route path={`${process.env.PUBLIC_URL}/contractmonitoring`} component={ContractMonitoringSystem} />                        
                        <Route path={`${process.env.PUBLIC_URL}/addcontract`} component={AddContract} />                        
                        <Route path={`${process.env.PUBLIC_URL}/editcontract/:rowId`} component={EditContract} />
                        <Route path={`${process.env.PUBLIC_URL}/prtvolumefreightupload`} component={PrtVolumeFreightFileUpload} />
                        <Route path={`${process.env.PUBLIC_URL}/transitreturnsbidashboard`} component={TransitReturnsBiDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/addnewtransitreturns`} component={AddNewTransitReturns} />
                        <Route path={`${process.env.PUBLIC_URL}/edittransitreturns/:rowId`} component={EditNewTransitReturns} />
                        <Route path={`${process.env.PUBLIC_URL}/cmsautomailconfig`} component={CmsAutoMailConfig} />
                        <Route path={`${process.env.PUBLIC_URL}/seaimportsform`} component={SeaImportsForm} />
                        <Route path={`${process.env.PUBLIC_URL}/seaimports`} component={SeaImportsListComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/bitransitreturns`} component={BiTransitReturnDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/lspcarrierdocuments`} component={lspCarrierTabs} />                 
                        <Route path={`${process.env.PUBLIC_URL}/addlspdocdetails`} component={AddLspDashboardDoc} />                 
                        <Route path={`${process.env.PUBLIC_URL}/editseaimportsform/:eventname`} component={EditSeaImports} />
                        <Route path={`${process.env.PUBLIC_URL}/ffseaimports`} component={FFSeaImportsList} />
                        <Route path={`${process.env.PUBLIC_URL}/editffseaimportsform/:eventname/:pagetype`} component={EditFFSeaImports} />
                        <Route path={`${process.env.PUBLIC_URL}/chaseaimports`} component={ChaSeaImportsList} />
                        <Route path={`${process.env.PUBLIC_URL}/editchaseaimportsform/:eventname/:pagetype`} component={EditChaSeaImports} />
                        <Route path={`${process.env.PUBLIC_URL}/transportationseaimports`} component={TransporterSeaImports} />
                        <Route path={`${process.env.PUBLIC_URL}/edittransporterseaimports/:eventname/:pagetype`} component={EditTransporterSeaImports} />                    
                        <Route path={`${process.env.PUBLIC_URL}/snddispatches`} component={ConsignmentsChannels} />                       
                        <Route path={`${process.env.PUBLIC_URL}/snddelivereddispatches`} component={ConsignmentsChannels} />                       
                        <Route path={`${process.env.PUBLIC_URL}/raildispatches`} component={RailConsignmentsChannels} />                       
                        <Route path={`${process.env.PUBLIC_URL}/raildelivereddispatches`} component={RailConsignmentsChannels} />
                        <Route path={`${process.env.PUBLIC_URL}/warehousedashboard`} component={WarehouseDashboard} />                        
                        <Route path={`${process.env.PUBLIC_URL}/rakeplanningtransporters`} component={RakePlanningTransporters} />
                        <Route path={`${process.env.PUBLIC_URL}/rprakedetails`} component={RpRakeDetails} />
                        <Route path={`${process.env.PUBLIC_URL}/rprailyarddetails`} component={RpRailYardDetails} />
                        <Route path={`${process.env.PUBLIC_URL}/rpbasestationdetails`} component={RpBaseStationDetails} />
                        <Route path={`${process.env.PUBLIC_URL}/rpbasestationcitydistance`} component={RpBaseStationCityDistance} />
                        <Route path={`${process.env.PUBLIC_URL}/rptransittimes`} component={RpTransitTimes} />                        
                        <Route path={`${process.env.PUBLIC_URL}/rpautorakeplanning`} component={RpAutoRakePlanning} />
                        <Route path={`${process.env.PUBLIC_URL}/rpautorakeplanning1`} component={RpAutoRakePlanningHistoricalData} />
                        <Route path={`${process.env.PUBLIC_URL}/rprakeplansummary`} component={RpRakePlanSummary} />
                        <Route path={`${process.env.PUBLIC_URL}/viewRakeSummaryDetails/:eventname`} component={viewRakeSummaryDetails} />                        
                        <Route path={`${process.env.PUBLIC_URL}/rakeavailability`} component={RakeAvailability} />                        
                        <Route path={`${process.env.PUBLIC_URL}/carsplanning`} component={CarsPlanning} />
                        <Route path={`${process.env.PUBLIC_URL}/viewCarSummaryDetails/:eventname`} component={viewCarSummaryDetails} />
                        <Route path={`${process.env.PUBLIC_URL}/carsplansummary`} component={CarsPlanningSummary} />
                        <Route path={`${process.env.PUBLIC_URL}/rpcardetails`} component={RpCarDetails} />
                        <Route path={`${process.env.PUBLIC_URL}/rptraindetails`} component={RpTrainDetails} />
                        <Route path={`${process.env.PUBLIC_URL}/rptraindimensions`} component={RpTrainDimensions} />
                        <Route path={`${process.env.PUBLIC_URL}/rptransporterserving`} component={RpTransporterServing} />
                        <Route path={`${process.env.PUBLIC_URL}/prtroutedeviationadhoc`} component={PRTRouteDeviationAdhoc} />     
                        <Route path={`${process.env.PUBLIC_URL}/aircargolist`} component={AirCargoList} /> 
                        <Route path={`${process.env.PUBLIC_URL}/d22consignment`} component={D22Consignment} />
                        <Route path={`${process.env.PUBLIC_URL}/d22gpsintegrationstatus`} component={GPSIntegrationStatus} />
                        <Route path={`${process.env.PUBLIC_URL}/d22trucksgps`} component={D22TrucksGPSData} />
                        <Route path={`${process.env.PUBLIC_URL}/sobachievement`} component={SobAchievement} />
                        <Route path={`${process.env.PUBLIC_URL}/sobachievementadhoc`} component={SobAchievementAdhoc} />
                        <Route path={`${process.env.PUBLIC_URL}/tagconfiguration`} component={TagConfiguration} />
                        <Route path={`${process.env.PUBLIC_URL}/assetconfiguration`} component={AssetConfiguration} />
                        <Route path={`${process.env.PUBLIC_URL}/gatewayconfiguration`} component={GateWayConfiguration} />
                        <Route path={`${process.env.PUBLIC_URL}/assettagpairing`} component={AssetTagPairing} />
                        <Route path={`${process.env.PUBLIC_URL}/geofencetag`} component={GeofenceTag} />
                        <Route path={`${process.env.PUBLIC_URL}/zonetag`} component={ZoneTag} />
                        <Route path={`${process.env.PUBLIC_URL}/ensafealertlogs`} component={EnSafeAlertLogs} />
                        <Route path={`${process.env.PUBLIC_URL}/ewaybillmonitoring`} component={EwayBillMonitoring} />
                        <Route path={`${process.env.PUBLIC_URL}/ewaybillmonitoringcourier`} component={EwayBillMonitoringForCourier} />
                        <Route path={`${process.env.PUBLIC_URL}/railstagewisedashboard`} component={RailstageWiseDashoard} />
                        <Route path={`${process.env.PUBLIC_URL}/elockdevices`} component={ElockDevices} />
                        <Route path={`${process.env.PUBLIC_URL}/assigntruckstoelock`} component={ElockDevicesMapTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/assignmobiletodevice`} component={MobileDeviceMapping} />
                        <Route path={`${process.env.PUBLIC_URL}/elockconfigurations`} component={ELockConfigurations} />
                        <Route path={`${process.env.PUBLIC_URL}/overspeedreport`} component={OverspeedComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/stoppagereport`} component={StoppageReportData} />
                        <Route path={`${process.env.PUBLIC_URL}/continoustravelreport`} component={ContinousTravelReport} />
                        <Route path={`${process.env.PUBLIC_URL}/harshbreakingreport`} component={HarshBreakingData} />
                        <Route path={`${process.env.PUBLIC_URL}/nightdrivingreportdata`} component={NightDrivingReportData} />
                        <Route path={`${process.env.PUBLIC_URL}/raillspscorecard`} component={RailLspPerformanceCard} />
                        <Route path={`${process.env.PUBLIC_URL}/billingsummary`} component={BillingSummary} />
                        <Route path={`${process.env.PUBLIC_URL}/billingdownload/:billingId`} component={BillingDownload} />
                        <Route path={`${process.env.PUBLIC_URL}/uploadmasterlist`} component={uploadMasterList} />
                        <Route path={`${process.env.PUBLIC_URL}/mastertruckeligible`} component={masterTruckEligible} />
                        <Route path={`${process.env.PUBLIC_URL}/lspstatusbilling`} component={lspStatusBilling} />
                        <Route path={`${process.env.PUBLIC_URL}/lspvalidationstatus/:billingId`} component={lspValidationStatus} />
                        <Route path={`${process.env.PUBLIC_URL}/mastersummarydata/:created_date`} component={masterSummaryData} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/orders`} component={CourierOrders} />
                        <Route path={`${process.env.PUBLIC_URL}/create-order-shipment/:orderid`} component={CourierOrderCreateShipment} />
                        <Route path={`${process.env.PUBLIC_URL}/shipments`} component={CourierShipments} />
                        <Route path={`${process.env.PUBLIC_URL}/vendors`} component={CourierVendors} />
                        <Route path={`${process.env.PUBLIC_URL}/create-vendor`} component={CourierCreateVendor} /> */}

                        
                        <Route path={`${process.env.PUBLIC_URL}/newoptimizedroutes`} component={NewOptimizeDeviationRoutes} />
                        <Route path={`${process.env.PUBLIC_URL}/deviationanalysisdata`} component={DeviationRoutesData} />
                        <Route path={`${process.env.PUBLIC_URL}/topbaseroutessummary`} component={TopBaseRouteSummary} />
                        <Route path={`${process.env.PUBLIC_URL}/msilapprovedroutes`} component={SavedBaseRoutes} />
                        <Route path={`${process.env.PUBLIC_URL}/transittimeanalysis`} component={transitTimeAnalysis} />
                        <Route path={`${process.env.PUBLIC_URL}/userlogdashboard`} component={UserLogDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/stagewiserailttdashboard`} component={stagewiseRailTTDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/forecastdashboard`} component={ForecastDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/bidaditriproutes`} component={BidhadiSourceDestinationMap} />
                        <Route path={`${process.env.PUBLIC_URL}/logscostestimation`} component={LogsCostEstimation} />
                        <Route path={`${process.env.PUBLIC_URL}/tnptrucksavailability`} component={TnpTrucksAvailability} />
                        <Route path={`${process.env.PUBLIC_URL}/transportationrates`} component={TransportationRates} />
                        <Route path={`${process.env.PUBLIC_URL}/searchdispatches`} component={SearchDispatches} />
                        <Route path={`${process.env.PUBLIC_URL}/d22returnpallets`} component={D22ReturnPallets} />
                        <Route path={`${process.env.PUBLIC_URL}/rakewiseroadconsignments`} component={ReckwiseRoadConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/carrierrunreport`} component={CarrierRunReport} />
                        <Route path={`${process.env.PUBLIC_URL}/managerailforcodes`} component={ManageRailForCodes} />
                        <Route path={`${process.env.PUBLIC_URL}/railconsignmentlogs`} component={RailConsignmentLogs} />
                        <Route path={`${process.env.PUBLIC_URL}/delayedrakesinfo`} component={DelayedRakesInfo} />
                        <Route path={`${process.env.PUBLIC_URL}/customsbondmonitoring`} component={CustomsBondMonitoringScreen} />
                        <Route path={`${process.env.PUBLIC_URL}/addcustombond`} component={AddCustomBond} />
                        <Route path={`${process.env.PUBLIC_URL}/stagewiserailtransittimedashboard`} component={StageWiseTransitTimeDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/freewheelingreport`} component={TptFreeWheelingReport} />
                        <Route path={`${process.env.PUBLIC_URL}/ignoredroutes`} component={IgnoredRoutes} />
                        <Route path={`${process.env.PUBLIC_URL}/screenwiseuserlogs`} component={ScreenWiseUserLogins} />
                        <Route path={`${process.env.PUBLIC_URL}/railclustersummary`} component={RailClusterSummary} />
                        <Route path={`${process.env.PUBLIC_URL}/containerbookingsystem/onetime`} component={ContainerBookingSystem} />
                        <Route path={`${process.env.PUBLIC_URL}/containerbookingsystem/urgent`} component={ContainerBookingSystem} />
                        <Route path={`${process.env.PUBLIC_URL}/containerbookingsystem/weekly`} component={ContainerBookingSystem} />
                       
                        <Route path={`${process.env.PUBLIC_URL}/onetimecontainerbookingform`} component={OneTimeContainerBookingFrom} />
                        <Route path={`${process.env.PUBLIC_URL}/lspdetails`} component={LSPDetails} />
                        <Route path={`${process.env.PUBLIC_URL}/addlspdetails`} component={AddLspDetails} />
                        <Route path={`${process.env.PUBLIC_URL}/containerbookingffupdates`} component={ContainerBookingffUpdates} />
                        <Route path={`${process.env.PUBLIC_URL}/containerbookingchaupdates`} component={ContainerBookingChaUpdates} />
                        <Route path={`${process.env.PUBLIC_URL}/containerbookingtransporterupdates`} component={ContainerBookingTransporterUpdates} />
                        <Route path={`${process.env.PUBLIC_URL}/containerexporttracking`} component={ContainerExportTracking} />
                        <Route path={`${process.env.PUBLIC_URL}/gpsintegrationforibl`} component={GpsIntegrationForIbl} />
                        <Route path={`${process.env.PUBLIC_URL}/consignmentsforibl`} component={ConsignmentScreenForIbl} />
                        <Route path={`${process.env.PUBLIC_URL}/sparepartsbidashboard`} component={SparepartBIDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/customsclearenceform`} component={CustomsClearenceForm} />
                        <Route path={`${process.env.PUBLIC_URL}/gpsuptimereport`} component={GpsUptimeReport} />
                        <Route path={`${process.env.PUBLIC_URL}/lspceodashboard`} component={LspCeoDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/lspceodashboardTest`} component={LspCeoDashboardTest} />
                        <Route path={`${process.env.PUBLIC_URL}/fleetdetails/:transporterCode`} component={FleetDetails} />
                        <Route path={`${process.env.PUBLIC_URL}/pendingPods/:transporterCode`} component={PendingpodsTabs} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/pendingPods/:transporterCode`} component={pendingPodsTVP} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/invoicetoexitdlp/:transporterCode`} component={InvoiceToExitDlp} />
                        <Route path={`${process.env.PUBLIC_URL}/transitreturns/:transporterCode`} component={TransitReturns} />
                        <Route path={`${process.env.PUBLIC_URL}/violations/:transporterCode`} component={Violations} />
                        <Route path={`${process.env.PUBLIC_URL}/loadchanginginfo/:transporterCode`} component={LoadChangingInfo} />
                        <Route path={`${process.env.PUBLIC_URL}/gpsstatus/:transporterCode`} component={GpsStatus} />
                        <Route path={`${process.env.PUBLIC_URL}/criticaldelay/:transporterCode`} component={CriticalDelay} />
                        <Route path={`${process.env.PUBLIC_URL}/targetvsachievement/:transporterCode`} component={TargetvsAchievement} />
                        <Route path={`${process.env.PUBLIC_URL}/ontimedelivery/:transporterCode`} component={OnTimeDelivery} />
                        <Route path={`${process.env.PUBLIC_URL}/transitdamages/:transporterCode`} component={TransitDamages} />
                        <Route path={`${process.env.PUBLIC_URL}/freightpayments/:transporterCode`} component={FreightPayments} />
                        <Route path={`${process.env.PUBLIC_URL}/freightamount/:transporterCode`} component={FreightAmount} />
                        <Route path={`${process.env.PUBLIC_URL}/drivertrainings/:transporterCode`} component={DriverTrainings} />
                        <Route path={`${process.env.PUBLIC_URL}/liveabnormalities/:transporterCode`} component={LiveAbnormality} />
                        <Route path={`${process.env.PUBLIC_URL}/tvpfreightpaymentpending/:transporterCode`} component={TVPFreightPaymentPending}/>
                        {/* <Route path={`${process.env.PUBLIC_URL}/stagewiserailttdashboardfy`} component={stagewiseRailTTDashboardFy} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/fleetdetailsTest/:transporterCode`} component={FleetDetailsTest} />
                        <Route path={`${process.env.PUBLIC_URL}/pendingPodsTest/:transporterCode`} component={PendingpodsTabsTest} />
                        <Route path={`${process.env.PUBLIC_URL}/invoicetoexitdlpTest/:transporterCode`} component={InvoiceToExitDlpTest} />
                        <Route path={`${process.env.PUBLIC_URL}/transitreturnsTest/:transporterCode`} component={TransitReturnsTest} />
                        <Route path={`${process.env.PUBLIC_URL}/violationsTest/:transporterCode`} component={ViolationsTest} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/loadchanginginfoTest/:transporterCode`} component={LoadChangingInfoTest} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/gpsstatusTest/:transporterCode`} component={GpsStatusTest} />
                        <Route path={`${process.env.PUBLIC_URL}/criticaldelayTest/:transporterCode`} component={CriticalDelayTest} />
                        <Route path={`${process.env.PUBLIC_URL}/targetvsachievementTest/:transporterCode`} component={TargetvsAchievementTest} />
                        <Route path={`${process.env.PUBLIC_URL}/ontimedeliveryTest/:transporterCode`} component={OnTimeDeliveryTest} />
                        <Route path={`${process.env.PUBLIC_URL}/transitdamagesTest/:transporterCode`} component={TransitDamagesTest} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/freightpaymentsTest/:transporterCode`} component={FreightPaymentsTest} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/freightamountTest/:transporterCode`} component={FreightAmountTest} />
                        <Route path={`${process.env.PUBLIC_URL}/drivertrainingsTest/:transporterCode`} component={DriverTrainingsTest} />
                        <Route path={`${process.env.PUBLIC_URL}/liveabnormalitiesTest/:transporterCode`} component={LiveAbnormalityTest} />
                        <Route path={`${process.env.PUBLIC_URL}/tvpfreightpaymentpendingTest/:transporterCode`} component={TVPFreightPaymentPendingTest}/>
                        <Route path={`${process.env.PUBLIC_URL}/onetimecontainerbooking`} component={ContainerBookingOneTime} />
                        <Route path={`${process.env.PUBLIC_URL}/containerBookingonetimeform`} component={ContainerBookingOneTimeForm} />
                        <Route path={`${process.env.PUBLIC_URL}/weeklycontainerbooking`} component={ContainerBookingWeekly} />
                        <Route path={`${process.env.PUBLIC_URL}/ledrailwaylogisticsscreen`} component={LedRailwayLogisticsscreen} />
                        <Route path={`${process.env.PUBLIC_URL}/ledrailwaylogisticsscreen2`} component={LedRailwayLogisticsscreen2} />
                        <Route path={`${process.env.PUBLIC_URL}/ledrailwaylogisticsscreen3`} component={LedRailwayLogisticsscreen3} />
                        <Route path={`${process.env.PUBLIC_URL}/ledrailwaylogisticsscreen4`} component={LedRailwayLogisticsscreen4} />
                        <Route path={`${process.env.PUBLIC_URL}/ledrailwaylogisticsscreen5`} component={LedRailwayLogisticsscreen5} />
                        <Route path={`${process.env.PUBLIC_URL}/ledrailwaylogisticsscreen6`} component={LedRailwayLogisticsscreen6} />
                        <Route path={`${process.env.PUBLIC_URL}/sampleledscreen1`} component={SampleLedScreen1} />
                        <Route path={`${process.env.PUBLIC_URL}/sampleledscreen2`} component={SampleLedScreen2} />
                        <Route path={`${process.env.PUBLIC_URL}/weatherreportdata`} component={WeatherReportData} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/stagewiserailttdashboardfy2`} component={stagewiseRailTTDashboardFy2} /> */}
                        {/* ARMS */}
                        <Route path={`${process.env.PUBLIC_URL}/targetpreparation`} component={Targetpreparation} />
                        {/* Coil Tracking Requirements */}

                        <Route path={`${process.env.PUBLIC_URL}/coilstockinformation`} component={CoilStockInformation} />
                        <Route path={`${process.env.PUBLIC_URL}/transitwarehousecoildispatch`} component={TransitWarehouseCoilDispatch} />
                        <Route path={`${process.env.PUBLIC_URL}/gstregisteredwarehousecoildispatch`} component={GstRegisteredWarehouseCoilDispatch} />
                        <Route path={`${process.env.PUBLIC_URL}/exportscontainertracking`} component={ContainerExportsTracking} />
                        <Route path={`${process.env.PUBLIC_URL}/coilstockinformationscreen2`} component={CoilStockInformationScreen2} />
                        <Route path={`${process.env.PUBLIC_URL}/fcsmaster`} component={LCLFrieghtManagementSystem} />
                        <Route path={`${process.env.PUBLIC_URL}/fcsbuyers`} component={Lclfgrieghtmanagementsystemscreen2} />

                        <Route path={`${process.env.PUBLIC_URL}/apirunlogs`} component={ApiRunLogs} />

                        {/* auto emails screen */}
                        <Route path={`${process.env.PUBLIC_URL}/automailerscreen`} component={AutomailsScreen} />
                        {/* DCTattendancelogs */}
                        <Route path={`${process.env.PUBLIC_URL}/dctattendancesummary`} component={DCTattendanceSummary} />
                        <Route path={`${process.env.PUBLIC_URL}/dctattendancelogs`} component={DCTattendancelogs} />
                        <Route path={`${process.env.PUBLIC_URL}/dctattendanceview`} component={DCTattendanceView} />

                        <Route path={`${process.env.PUBLIC_URL}/uniquescreenlogs`} component={UniqueScreenLogs} />
                            {/* HUB */}
                        <Route path={`${process.env.PUBLIC_URL}/prtconsignmentshub`} component={HubConsignments} />
                         <Route path={`${process.env.PUBLIC_URL}/prtallconsignmentshub`} component={HubConsignments} />
                         <Route path={`${process.env.PUBLIC_URL}/hubmaster`} component={HubMaster} />
                         <Route path={`${process.env.PUBLIC_URL}/transportermaster`} component={TransporterMaster} />
                         <Route path={`${process.env.PUBLIC_URL}/lastmilefleetmaster`} component={LastMileFleetMaster} />


                        <Route path={`${process.env.PUBLIC_URL}/prtdeliveredconsignmentshub`} component={HubConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/lastmilehubconsigment`} component={LastMileHubConsigmentScreen} />
                        {/* LSP CEO */}
                        <Route path={`${process.env.PUBLIC_URL}/lspcarrierdatauser`} component={LSPCarrierDataIndividul} />
                        <Route path={`${process.env.PUBLIC_URL}/lspcarrierdataadmin`} component={LSPCarrierDataAdmin} />
                        {/* Exports Container Booking */}
                        <Route path={`${process.env.PUBLIC_URL}/urgentcontainerbookingform`} component={UgrentContainerBookingFrom} />
                        <Route path={`${process.env.PUBLIC_URL}/editonetimecontainerbookingform/:eventname`} component={EditOneTimeContainerBookingFrom} />
                        <Route path={`${process.env.PUBLIC_URL}/editweeklycontainerbookingform/:eventname`} component={EditWeeklyContainerBookingFrom} />
                        <Route path={`${process.env.PUBLIC_URL}/editurgentcontainerbookingform/:eventname`} component={EditUrgentContainerBookingFrom} />
                        <Route path={`${process.env.PUBLIC_URL}/editffpendingonetimecontainerbookingform/:eventname`} component={EditFFPendingOneTimeContainerBookingFrom} />
                        <Route path={`${process.env.PUBLIC_URL}/editffinprocessonetimecontainerbookingform/:eventname`} component={EditFFInProccessOneTimeContainerBookingFrom} />
                        <Route path={`${process.env.PUBLIC_URL}/editffweeklypendingcontainerbookingform/:eventname`} component={EditFFPendingWeeklyContainerBookingFrom} />
                        <Route path={`${process.env.PUBLIC_URL}/editffweeklyinprocesscontainerbookingform/:eventname`} component={EditFFInProccessWeeklyContainerBookingFrom} />
                        <Route path={`${process.env.PUBLIC_URL}/editffurgentpendingcontainerbookingform/:eventname`} component={EditFFPendingUrgentContainerBookingFrom} />
                        <Route path={`${process.env.PUBLIC_URL}/editffurgentinproccesscontainerbookingform/:eventname`} component={EditFFInProccessUrgentContainerBookingFrom} />
                        <Route path={`${process.env.PUBLIC_URL}/edittransporterpendingonetimecontainerbookingform/:eventname`} component={EditTransporterPendingOneTimeContainerBookingFrom} />
                        <Route path={`${process.env.PUBLIC_URL}/edittransporterpendingweeklycontainerbookingform/:eventname`} component={EditTransporterPendingWeeklyContainerBookingFrom} />
                        <Route path={`${process.env.PUBLIC_URL}/edittransporterpendingurgentcontainerbookingform/:eventname`} component={EditTransporterPendingUrgentContainerBookingFrom} />
                        <Route path={`${process.env.PUBLIC_URL}/editchapendingonetimecontainerbookingform/:eventname`} component={EditCHAPendingOneTimeContainerBookingFrom} />
                        <Route path={`${process.env.PUBLIC_URL}/editchainprocessonetimecontainerbookingform/:eventname`} component={EditCHAInProccessOneTimeContainerBookingFrom} />
                        <Route path={`${process.env.PUBLIC_URL}/editchaweeklypendingcontainerbookingform/:eventname`} component={EditCHAPendingWeeklyContainerBookingFrom} />
                        <Route path={`${process.env.PUBLIC_URL}/editchaweeklyinprocesscontainerbookingform/:eventname`} component={EditCHAInProccessWeeklyContainerBookingFrom} />
                        <Route path={`${process.env.PUBLIC_URL}/editchaurgentpendingcontainerbookingform/:eventname`} component={EditCHAPendingUrgentContainerBookingFrom} />
                        <Route path={`${process.env.PUBLIC_URL}/editchaurgentinproccesscontainerbookingform/:eventname`} component={EditCHAInProccessUrgentContainerBookingFrom} />

                        {/* RakeTrackingScreen */}
                        <Route path={`${process.env.PUBLIC_URL}/raketrackingscreen`} component={RakeTrackingScreen} />
                        {/* new screen */}
                        <Route path={`${process.env.PUBLIC_URL}/nexascreen`} component={NexaScreen} />
                        {/*GPS Review Screen for Transporter*/}
                        <Route path={`${process.env.PUBLIC_URL}/transportergpsreviewscreen`} component={TransporterGPSReview} />
                        <Route path={`${process.env.PUBLIC_URL}/transportergpsreviewscreenform/:month`} component={TransporterGPSReviewFrom} />
                        <Route path={`${process.env.PUBLIC_URL}/gpsreviewscreen`} component={GPSReview}/>
                        <Route path={`${process.env.PUBLIC_URL}/containerimportsdataadhoc`} component={ContainerImportsDataAdHoc}/>
                        <Route path={`${process.env.PUBLIC_URL}/sustainabilityConfiguration`} component={SustainabilityConfiguration}/>
                        <Route path={`${process.env.PUBLIC_URL}/sustainabilitydashboard`} component={SustainabilityBiDashboard}/>
                         <Route path={`${process.env.PUBLIC_URL}/restrictionimposedsystem`} component={RestrictionImposedSystem}/>
                         <Route path={`${process.env.PUBLIC_URL}/msiboperationsmonitoring`} component={MSIBOperationsMonitoring}/>
                         {/* //Container Imports Tracking */}
                         {/* <Route path={`${process.env.PUBLIC_URL}/containerimportstracking`} component={ContainerImportsTracking}/> */}
                         <Route path={`${process.env.PUBLIC_URL}/tvpmanagementdashboard`} component={TVPManagementDashboard}/>
                         {/* <Route path={`${process.env.PUBLIC_URL}/stagewiserailttdashboardfy23`} component={stagewiseRailTTDashboardFy23}/> */}
                         <Route path={`${process.env.PUBLIC_URL}/screenarchival`} component={ScreenArchival}/>
                         <Route path={`${process.env.PUBLIC_URL}/knowledgebank`} component={FormPageForKownledgeBank}/>
                         <Route path={`${process.env.PUBLIC_URL}/knowledgebankpendingscreen`} component={FormPageForKownledgeBank}/>

                         <Route path={`${process.env.PUBLIC_URL}/container-tracking-imports`} component={ContainerTrackingImports} />
                        <Route path={`${process.env.PUBLIC_URL}/importcontainers`} component={containerTrackingImports} />
                        <Route path={`${process.env.PUBLIC_URL}/tracking_details_imports`} component={ContainerTrackingImportsDetails} />
                        <Route path={`${process.env.PUBLIC_URL}/emissionscreens`} component={EmissionTabs} />
                        <Route path={`${process.env.PUBLIC_URL}/consignmentdashboard`} component={Dashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/sustainabilitydashboardnew`} component={SustainabilityDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/holidaycalendar`} component={HolidayCalendar} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/sparepartsbidashboard24_25`} component={SparepartsBiDashboard24_25} /> */}
                        {/* <Route path={`${process.env.PUBLIC_URL}/favourites`} component={Favorites} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/newsfeed`} component={NewsFeedComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/operatingClusters/:transporterCode`} component={OperatingClusters} />
                        <Route path={`${process.env.PUBLIC_URL}/fleetavailability/:transporterCode`} component={FleetAvailability} />
                        <Route path={`${process.env.PUBLIC_URL}/MTDtargetVSachievement/:transporterCode`} component={MTDTargetvsAchievement} />
                        <Route path={`${process.env.PUBLIC_URL}/totalfreight/:transporterCode`} component={TotalFreight} />
                    </App>

                        
                    </Switch>
                </ScrollContext>
            </BrowserRouter>
        </Provider>
        )
    }
}



ReactDOM.render(<Root/>, document.getElementById('root'));



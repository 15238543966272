
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const ImportsWareHouseAction = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onShowConsignmentImportsEventsClick(props);
    };
    //var colr = 'grayfont'
   
    if(props.data.last_status == 'In ICD Ghari')
    {
        if(props.data.sea_transit_delay == 1)
        {
            var colr = 'redfont'
        }
        else{
            var colr = 'yellowfont'
        }
    }
    else if(props.data.last_status == 'In Transit to MSIL')
    {
        if(props.data.sea_transit_delay == 1)
        {
            var colr = 'redfont'
        }
        else{
            var colr = 'greenfont'
        }
    }
    else{
        var colr = 'grayfont'
    }

    return (
        <div>
            <button onClick={handleEventClick} className="custom-btn f22 label label-success" 
            title="In ICD Garhi" ><i className={"icofont icofont-ui-home "+(colr)}></i> &nbsp;</button>
            
        </div>
    );
};

export default ImportsWareHouseAction;

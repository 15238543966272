import React, { } from 'react';
// import ReactDOM from 'react-dom';
// var redirectURL = require('../redirectURL');

 
const lspcarrierdoc = (props) => {
//    console.log("props ", props)

    return (
        <div>
            {/* {console.log("props.value", props)} */}
		    {<p>{props.value}</p>}
        </div>
    );
};

export default lspcarrierdoc;
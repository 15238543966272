import React, { Component } from 'react';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css"; 
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import $ from 'jquery';
import Select from 'react-select';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import * as Datetime from 'react-datetime';
import ExportsTrackingCountersComponent from "./exportstrackingcounterComponent";
import SweetAlert from 'react-bootstrap-sweetalert';
// import UpdateButton from "./updatelmstates";

import CountUp from 'react-countup';
import ForceCloseButton from "./exportsforcecloseaction";
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");

export default class ViolationsTest extends Component {
    constructor(props){
    	super(props);
        var date2 = new Date();
		var currentDate = moment(date2).subtract(1, 'days').format("YYYY-MM-DD");
		// var currentStart = moment(date2).subtract(1, 'year').format("YYYY-MM-DD")
		var currentStart = "2024-04-01"

    	this.state={
    		pageTitle: "Exports Container Tracking",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show1: false,
            show: false,
            basicTitle1:'',
            basicTitle:'',
            loadshow:"show-n",
            overly:"show-n",
			basicType1:"default",
            basicType:"default",
            modules: AllModules,
            sidebarSubHeader:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: [],
              rowData1: [],
              rowData2: [],
              rowData3: [],
              rowData4: [],
            overspeedingData:[],
            nightdrivingData:[],
            continuousdrivingData:[],
            freewheelingData:[],
            harshbreakingData:[],
            startDate: currentStart,
			endDate: currentDate,
            gridshow:"show-n",
            gridshow1:"show-n",
            gridshow2:"show-n",
            gridshow3:"show-n",
            gridshow4:"show-n",
              month:[],
              pagetitle:"",
			rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            frameworkComponents: {
                // UpdateButton:UpdateButton,
                ForceCloseButton:ForceCloseButton
            },

    	}
       
    }
   
    async componentDidMount(){
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
        }
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        var transporterCode = window.atob(this.props.match.params.transporterCode)
        console.log(transporterCode,"fbjwrh")
        this.setState({
            transporterCode : transporterCode,
            startDate:this.state.startDate,
			endDate:this.state.endDate
        })
        // await this.onLoadDashboardData(transporterCode)
        // await this.onLoadDashboardData2(transporterCode)
        // await this.onLoadDashboardData3(transporterCode)
        // await this.onLoadDashboardData4(transporterCode)
        // await this.onLoadDashboardData5(transporterCode)
        var params ={
            transporterCode : transporterCode,
            start_date:this.state.startDate,
			end_date:this.state.endDate
        }
        await redirectURL.post("/consignments/getviolationsoverspeedingdata",params).then((response)=>{
            this.setState({
                overspeedingData : response.data.overspeedingdata
            })
        })
        await redirectURL.post("/consignments/getviolationsnightdrivingdata",params).then((response)=>{
            this.setState({
                nightdrivingData : response.data.nightdrivingdata
            })
        })
        await redirectURL.post("/consignments/getviolationscontinuousdrivingdata",params).then((response)=>{
            this.setState({
                continuousdrivingData : response.data.continuousdrivingdata
            })
        })
        await redirectURL.post("/consignments/getviolationsfreewheelingdata",params).then((response)=>{
            this.setState({
                freewheelingData : response.data.freewheelingdata
            })
        })
        await redirectURL.post("/consignments/getviolationsharshbreakingdata",params).then((response)=>{
            this.setState({
                harshbreakingData : response.data.harshbreakingdata
            })
        })
        await this.setState({
            loadshow:"show-n",
            overly:"show-n"
        })
	};
    onloadpagedata1 = async(event) =>{
        event.preventDefault()
        this.setState({
            loadshow:"show-m",
            overly:"show-m",
            gridshow:"show-n",
            gridshow1:"show-n",
            gridshow2:"show-n",
            gridshow3:"show-n",
            gridshow4:"show-n", 
        })
        var params ={
            transporterCode : this.state.transporterCode,
            start_date : this.state.startDate,
            end_date : this.state.endDate
        }
        await redirectURL.post("/consignments/getviolationsoverspeedingdata",params).then(async(response)=>{
            await redirectURL.post("/consignments/getviolationsnightdrivingdata",params).then(async(response2)=>{
                await redirectURL.post("/consignments/getviolationscontinuousdrivingdata",params).then(async(response3)=>{
                    await redirectURL.post("/consignments/getviolationsfreewheelingdata",params).then(async(response4)=>{
                        await redirectURL.post("/consignments/getviolationsharshbreakingdata",params).then(async(response5)=>{
                                        await this.setState({
                                            overspeedingData : response.data.overspeedingdata,
                                        nightdrivingData : response2.data.nightdrivingdata,
                                        continuousdrivingData : response3.data.continuousdrivingdata,
                                        freewheelingData : response4.data.freewheelingdata,
                                            harshbreakingData : response5.data.harshbreakingdata,
                                            loadshow:"show-n",
                                            overly:"show-n"
                                        })
                                })
                            })
                        })
                    })
                        
                })
    }
    
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}
  
	handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		var month = ("0" + (d.getMonth() + 1)).slice(-2); // Adding leading zero if month is less than 10
		var day = ("0" + d.getDate()).slice(-2); // Adding leading zero if day is less than 10
		var startdate = d.getFullYear() + "-" + month + "-" + day;
		if (this.state.endDate != undefined && this.state.endDate != "") {
			var startDate = new Date(startdate);
			var endDate = new Date(this.state.endDate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			if (startTime > endTime) {
				this.setState({
					show: true,
					basicTitle: 'To Date should be greater than From Date',
					basicType: "danger",
					loadshow: 'show-n'
				});
			} else {
				this.setState({
					startDate: startdate
				});
			}
		} else {
			this.setState({
				startDate: startdate
			});
		}
	}

	handlerEndDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		var month = ("0" + (d.getMonth() + 1)).slice(-2); // Adding leading zero if month is less than 10
		var day = ("0" + d.getDate()).slice(-2); // Adding leading zero if day is less than 10
		var edate = d.getFullYear() + "-" + month + "-" + day;
		if (this.state.startDate != undefined && this.state.startDate != "") {
			var startDate = new Date(this.state.startDate);
			var endDate = new Date(edate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			if (startTime > endTime) {
				this.setState({
					show: true,
					basicTitle: 'To Date should be greater than From Date',
					basicType: "danger",
					loadshow: 'show-n'
				});
			} else {
				this.setState({
					endDate: edate
				});
			}
		} else {
			this.setState({
				endDate: edate
			});
		}
	}
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
		});
		
	}
	closeAlert = () => {
        this.setState({
            show1: false,
            show: false,

        });
    }
    
    onCellRowClicked(e){
        
    }
    onClickCounter=(counter)=>{
      if(counter == 0){
        this.setState({
            rowData:this.state.overspeedingData,
            gridshow:"show-m",
            gridshow1:"show-n",
            gridshow2:"show-n",
            gridshow3:"show-n",
            gridshow4:"show-n",
            pagetitle:"Over Speeding Data"
        })
      }
      if(counter == 1){
        this.setState({
            rowData1:this.state.nightdrivingData,
            gridshow:"show-n",
            gridshow1:"show-m",
            gridshow2:"show-n",
            gridshow3:"show-n",
            gridshow4:"show-n",
            pagetitle : "Night Driving Data"
        })
      }
      if(counter == 2){
        this.setState({
            rowData2:this.state.continuousdrivingData,
            gridshow:"show-n",
            gridshow1:"show-n",
            gridshow2:"show-m",
            gridshow3:"show-n",
            gridshow4:"show-n",
            pagetitle:"Continuous Driving Data"
        })
      }
      if(counter == 3){
        this.setState({
            rowData3:this.state.freewheelingData,
            gridshow:"show-n",
            gridshow1:"show-n",
            gridshow2:"show-n",
            gridshow3:"show-m",
            gridshow4:"show-n",
            pagetitle:"Free Wheeling Data"
        })
      }
      if(counter == 4){
        this.setState({
            rowData4:this.state.harshbreakingData,
            gridshow:"show-n",
            gridshow1:"show-n",
            gridshow2:"show-n",
            gridshow3:"show-n",
            gridshow4:"show-m",
            pagetitle:"Harsh Breaking Data"
        })
      }

    } 
    closethegrid =()=>{
        this.setState({
            gridshow:"show-n",
            gridshow1:"show-n",
            gridshow2:"show-n",
            gridshow3:"show-n",
            gridshow4:"show-n",
        })
    }
    render(){
        const columnwithDefs = [
            {
                headerName : "Carrier No",
                field : "truck_no",
                width:150,
                editable : false,
                // pinned:"left",
                resizable:true
            },
            // {
            //     headerName:"Driver ID",
            //     field:"",
            //     width:150,
            //     editable:true,
            //     // pinned:"left",
            //     resizable:true,
            //     cellEditor: 'agSelectCellEditor',
            //     cellEditorParams: {
            //         values: this.state.polList,
            //     },
            // },
            
            {
                headerName:"Trip No",
                field:"consignment_codes",
                width:180,
                editable:false,
                // pinned:"left",
                resizable:true},
                
            // {
            //     headerName:"Consignee City",
            //     field:"consignee_city",
            //     width:180,
            //     editable:false,
            //     resizable:true,
               
               
            // },
            // {
            //     headerName:"Consignee State",
            //     field:"consignee_state",
            //     width:200,
            //     editable:false,
            //     resizable:true,
              
            // },
            {
                headerName:"Violation Start Time",
                field:"first_instance",
                width:180,
                editable:false,
                resizable:true,
                valueGetter:function(params){
                    return getDDMMYYYYHHMMSS(params.data.first_instance)
                }
            },
            {
                headerName:"Violation End Time",
                field:"recent_instance",
                width:180,
                editable:false,
                resizable:true,
                valueGetter:function(params){
                    return getDDMMYYYYHHMMSS(params.data.recent_instance)
                }
            },
            {
                headerName:"Speed (km/h)",
                field:"speed",
                width:160,
                editable:false,
                resizable:true,
            }

                
            
        ]
        const columnwithDefs1 = [
            {
                headerName : "Carrier No",
                field : "truck_no",
                width:150,
                editable : false,
                // pinned:"left",
                resizable:true
            },
            // {
            //     headerName:"Driver ID",
            //     field:"",
            //     width:150,
            //     editable:true,
            //     // pinned:"left",
            //     resizable:true,
            //     cellEditor: 'agSelectCellEditor',
            //     cellEditorParams: {
            //         values: this.state.polList,
            //     },
            // },
            
            {
                headerName:"Trip No",
                field:"consignment_codes",
                width:180,
                editable:false,
                // pinned:"left",
                resizable:true},
                
            // {
            //     headerName:"Consignee City",
            //     field:"consignee_city",
            //     width:180,
            //     editable:false,
            //     resizable:true,
               
               
            // },
            // {
            //     headerName:"Consignee State",
            //     field:"consignee_state",
            //     width:200,
            //     editable:false,
            //     resizable:true,
              
            // },
            {
                headerName:"Violation Start Time",
                field:"first_instance",
                width:180,
                editable:false,
                resizable:true,
                valueGetter:function(params){
                    return getDDMMYYYYHHMMSS(params.data.first_instance)
                }
            },
            {
                headerName:"Violation End Time",
                field:"recent_instance",
                width:180,
                editable:false,
                resizable:true,
                valueGetter:function(params){
                    return getDDMMYYYYHHMMSS(params.data.recent_instance)
                }
            },
            {
                headerName:"Distance (km)",
                field:"distance_from_first_instance",
                width:160,
                editable:false,
                resizable:true,
                hide:false,
               
            }

                
            
        ]
        const columnwithDefs2 = [
            {
                headerName : "Carrier No",
                field : "truck_no",
                width:150,
                editable : false,
                // pinned:"left",
                resizable:true
            },
            // {
            //     headerName:"Driver ID",
            //     field:"",
            //     width:150,
            //     editable:true,
            //     // pinned:"left",
            //     resizable:true,
            //     cellEditor: 'agSelectCellEditor',
            //     cellEditorParams: {
            //         values: this.state.polList,
            //     },
            // },
            
            {
                headerName:"Trip No",
                field:"consignment_codes",
                width:180,
                editable:false,
                // pinned:"left",
                resizable:true},
                
            // {
            //     headerName:"Consignee City",
            //     field:"consignee_city",
            //     width:180,
            //     editable:false,
            //     resizable:true,
               
               
            // },
            // {
            //     headerName:"Consignee State",
            //     field:"consignee_state",
            //     width:200,
            //     editable:false,
            //     resizable:true,
              
            // },
            {
                headerName:"Violation Start Time",
                field:"probable_start_date",
                width:180,
                editable:false,
                resizable:true,
                valueGetter:function(params){
                    return getDDMMYYYYHHMMSS(params.data.probable_start_date)
                }
            },
            {
                headerName:"Violation End Time",
                field:"probable_end_date",
                width:180,
                editable:false,
                resizable:true,
                valueGetter:function(params){
                    return getDDMMYYYYHHMMSS(params.data.probable_end_date)
                }
            },
            {
                headerName:"Distance (km)",
                field:"distance_travelled_km",
                width:120,
                editable:false,
                resizable:true,
                hide:false,
               
            }

                
            
        ]
        const columnwithDefs3 = [
            {
                headerName : "Carrier No",
                field : "truck_no",
                width:150,
                editable : false,
                // pinned:"left",
                resizable:true
            },
            // {
            //     headerName:"Driver ID",
            //     field:"",
            //     width:150,
            //     editable:true,
            //     // pinned:"left",
            //     resizable:true,
            //     cellEditor: 'agSelectCellEditor',
            //     cellEditorParams: {
            //         values: this.state.polList,
            //     },
            // },
            
            // {
            //     headerName:"Trip No",
            //     field:"",
            //     width:180,
            //     editable:false,
            //     // pinned:"left",
            //     resizable:true},
                
            // {
            //     headerName:"Consignee City",
            //     field:"consignee_city",
            //     width:180,
            //     editable:false,
            //     resizable:true,
               
               
            // },
            // {
            //     headerName:"Consignee State",
            //     field:"consignee_state",
            //     width:200,
            //     editable:false,
            //     resizable:true,
              
            // },
            {
                headerName:"Violation Start Time",
                field:"first_instance",
                width:180,
                editable:false,
                resizable:true,
                valueGetter:function(params){
                    return getDDMMYYYYHHMMSS(params.data.first_instance)
                }
               
            },
            {
                headerName:"Violation End Time",
                field:"recent_instance",
                width:180,
                editable:false,
                resizable:true,
                valueGetter:function(params){
                    return getDDMMYYYYHHMMSS(params.data.first_instance)
                }
            },
            {
                headerName:"Speed (km/h)",
                field:"speed",
                width:120,
                editable:false,
                resizable:true,
                hide:false,
               
            }
        ]
        const columnwithDefs4 = [
            {
                headerName : "Carrier No",
                field : "truck_no",
                width:150,
                editable : false,
                // pinned:"left",
                resizable:true
            },
            // {
            //     headerName:"Driver ID",
            //     field:"",
            //     width:150,
            //     editable:true,
            //     // pinned:"left",
            //     resizable:true,
            //     cellEditor: 'agSelectCellEditor',
            //     cellEditorParams: {
            //         values: this.state.polList,
            //     },
            // },
            
            // {
            //     headerName:"Trip No",
            //     field:"",
            //     width:180,
            //     editable:false,
            //     // pinned:"left",
            //     resizable:true},
                
            // {
            //     headerName:"Consignee City",
            //     field:"consignee_city",
            //     width:180,
            //     editable:false,
            //     resizable:true,
               
               
            // },
            // {
            //     headerName:"Consignee State",
            //     field:"consignee_state",
            //     width:200,
            //     editable:false,
            //     resizable:true,
              
            // },
            // {
            //     headerName:"Violation Start Time",
            //     field:"violation_start_time",
            //     width:180,
            //     editable:false,
            //     resizable:true,
               
            // },
            // {
            //     headerName:"Violation End Time",
            //     field:"violation_end_time",
            //     width:180,
            //     editable:false,
            //     resizable:true
            // },
            {
                headerName:"Speed (km/h)",
                field:"speed",
                width:120,
                editable:false,
                resizable:true,
                hide:false,
               
            } 
        ]
        return (
            <div className="row">
                <SweetAlert
                    show={this.state.show1}
                    type={this.state.basicType1}
                    title={this.state.basicTitle1}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}>
	        	</SweetAlert>
                {/* <div className='card col-xl-12 col-lg-12' style={{marginTop:"0px"}}>
            <div className="card-header text-center" style={{marginTop:"0px"}}>
            
            <h5 style={{ border: "1px solid #ccc", padding: "1%", fontSize:"20px" }}>
          
              <span className="" style={{position:"relative",left:"0 %"}}>Transit Returns</span>
            </h5>
          </div>
          </div> */}
              <div   style={{textAlign: "center",background: "#171C8F",width:'100%',margin:"5px",borderRadius:"5px",height:'70px',paddingBottom:'25px'}}>
                                   <h6 class="txt-dark f40" style={{marginTop:"0px"}}>
                                        <span   style={{fontSize:"20px",color:"#FFFFFF"}}>
                                            <span>GPS Linked Violations</span>
                                        
                                        </span>
                                    </h6>
                                </div>
          <div className="col-xl-12 col-lg-12" style={{display:'flex',flexDirection:'column',justifyContent:'space-between'}}>		
                    	
                            <div> 
                                <h5>
                                <span className="float-right f12 "> 
                                        <a className="btn btn-danger" style={{float:"left",marginLeft:"5%",marginBottom:"20px"}} href="/lspceodashboardTest">Back</a>
                                    </span>
                                </h5>   
                            </div>                 
                  
                   </div>
                   <div className='col-xl-12 col-lg-12' style={{paddingBottom:"15px"}}>
                                            
                                            <div className="row card-body" style={{marginLeft:"0px",paddingBottom:"0px"}}>
                                                <div className="col-xl-3 col-lg-3 form-group">
                                                    <label>From Date</label>
                                                    <Datetime
                                                    value={this.state.startDate} 
                                                    disableCloseOnClickOutside={false} 
                                                    closeOnSelect={true} 
                                                    inputProps={{ placeholder: 'From', name: 'startDate', autoComplete:'off',readOnly:true }}
                                                    dateFormat="YYYY-MM-DD" 
                                                    timeFormat={null}
                                                    name="startDate" 
                                                    onChange={this.handlerStartDateTime.bind(this)}
                                                    isValidDate={current => {
                                                        return current.isSameOrBefore(moment(), 'day');
                                                    }} />
                                                </div>  
                                                <div className="col-xl-3 col-lg-3 form-group">
                                                    <label>To Date</label>
                                                    <Datetime 
                                                    value={this.state.endDate} 
                                                    disableCloseOnClickOutside={false}
                                                    closeOnSelect={true} 
                                                    inputProps={{ placeholder: 'To', name: 'endDate', autoComplete:'off',readOnly:true}} 
                                                    dateFormat="YYYY-MM-DD" 
                                                    timeFormat={null}
                                                    onChange={this.handlerEndDateTime.bind(this)} 
                                                    isValidDate={current => {
                                                        return current.isSameOrBefore(moment(), 'day');
                                                    }}/>
                                                </div>
                                                <div className="form-group col-xl-2 col-lg-2">
                                                    <button type="button" style={{position:"absolute",right:"20px",borderRadius:"5px"}} className="mt-30p btn btn-success" onClick={this.onloadpagedata1.bind(this)}>Submit</button>
                                                </div>
                                                            
                                            </div>
                                    
                                        </div>	
                <div className="col-xl-12 col-lg-12">
                <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                    <div className="card">
                        <div className="card-body" style={{padding:"10px"}}>
                            <div className="crm-numbers pb-0">
                                <div className="row">
                                
                                <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this,0)}>
                                        <span className="f13">Over Speeding</span>
                                        <h4 className="txt-info f50"><span className="counter"><CountUp end={this.state.overspeedingData.length || 0}/></span></h4>
                                    </div> 
                                <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this,1)}>
                                    <span className="f13">Night Driving</span>
                                    <h4 className="txt-secondary-dark f50"><span className="counter"><CountUp end={this.state.nightdrivingData.length || 0}/></span></h4>
                                </div> 
                                <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this,2)}>
                                    <span className="f13">Continuous Driving</span>
                                    <h4 className="txt-pink-light f50"><span className="counter"><CountUp end={this.state.continuousdrivingData.length || 0}/></span></h4>
                                </div> 
                                <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this,3)}>
                                    <span className="f13">Free Wheeling</span>
                                    <h4 className="txt-info-medium f50"><span className="counter"><CountUp end={this.state.freewheelingData.length || 0}/></span></h4>
                                </div>  
                                <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this,4)}>
                                    <span className="f13">Harsh Braking</span>
                                    <h4 className="txt-success f50"><span className="counter"><CountUp end={this.state.harshbreakingData.length || 0}/></span></h4>
                                </div> 
                               

                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>
                    <div className={"card "+(this.state.gridshow)}>
                        <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle}</span>
                                       <span >
                                    <button style={{position:"absolute",right:"1%"}} onClick={this.closethegrid.bind(this)}>X</button></span>
                                </h5>
                                
                            </div>
                        	   			
                        <div className="card-body">
                            <div className="col-xl-12 col-lg-12">
                                <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={false}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        frameworkComponents={this.state.frameworkComponents}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        components={this.state.components}
                                        rowSelection={this.state.rowSelection}
                                        suppressRowClickSelection={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        onCellValueChanged={this.onCellRowClicked.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"card "+(this.state.gridshow1)}>
                        <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle}</span>
                                       <span >
                                    <button style={{position:"absolute",right:"1%"}} onClick={this.closethegrid.bind(this)}>X</button></span>
                                </h5>
                                
                            </div>
                        	   			
                        <div className="card-body">
                            <div className="col-xl-12 col-lg-12">
                                <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs1}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData1}
                                        enableCharts={false}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        frameworkComponents={this.state.frameworkComponents}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        components={this.state.components}
                                        rowSelection={this.state.rowSelection}
                                        suppressRowClickSelection={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        onCellValueChanged={this.onCellRowClicked.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"card "+(this.state.gridshow2)}>
                        <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle}</span>
                                       <span >
                                    <button style={{position:"absolute",right:"1%"}} onClick={this.closethegrid.bind(this)}>X</button></span>
                                </h5>
                                
                            </div>
                        	   			
                        <div className="card-body">
                            <div className="col-xl-12 col-lg-12">
                                <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs2}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData2}
                                        enableCharts={false}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        frameworkComponents={this.state.frameworkComponents}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        components={this.state.components}
                                        rowSelection={this.state.rowSelection}
                                        suppressRowClickSelection={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        onCellValueChanged={this.onCellRowClicked.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"card "+(this.state.gridshow3)}>
                        <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle}</span>
                                       <span >
                                    <button style={{position:"absolute",right:"1%"}} onClick={this.closethegrid.bind(this)}>X</button></span>
                                </h5>
                                
                            </div>
                        	   			
                        <div className="card-body">
                            <div className="col-xl-12 col-lg-12">
                                <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs3}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData3}
                                        enableCharts={false}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        frameworkComponents={this.state.frameworkComponents}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        components={this.state.components}
                                        rowSelection={this.state.rowSelection}
                                        suppressRowClickSelection={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        onCellValueChanged={this.onCellRowClicked.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"card "+(this.state.gridshow4)}>
                        <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle}</span>
                                       <span >
                                    <button style={{position:"absolute",right:"1%"}} onClick={this.closethegrid.bind(this)}>X</button></span>
                                </h5>
                                
                            </div>
                        	   			
                        <div className="card-body">
                            <div className="col-xl-12 col-lg-12">
                                <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs4}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData4}
                                        enableCharts={false}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        frameworkComponents={this.state.frameworkComponents}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        components={this.state.components}
                                        rowSelection={this.state.rowSelection}
                                        suppressRowClickSelection={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        onCellValueChanged={this.onCellRowClicked.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>   
            </div>
        );
    }
}







import React, { Component } from "react";
import moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from "@ag-grid-community/react";
import Select from "react-select";
import $ from "jquery";
import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import * as Datetime from 'react-datetime';
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
var redirectURL = require("../redirectURL");

export default class EmissionModelMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      eventFormAction: "formAction",
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        width: 160,
        resizable: true,
      },
      eventAction: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      bulkslide: "",
      bulkslide1: "",
      rowModelhighchartoptionsType: "enterprise",
      paginationPageSize: 50,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      overly: "show-n",
      loadshow: "show-n",
      file: "",
      rowData: [],
      rowId: "",
      show: false,
      basicTitle: "",
      basicType: "",
      family_code:'',
      model_code:'',
      sbvh_code:'',
      model_kerb_weight_in_kg:'',
    };
  }

  async componentDidMount() {
    this.setState({
      loadshow: "show-m",
      overly: "show-m"
    });
    await redirectURL
      .post("/consignments/emissionmodelmasterdataonmount")
      .then((response) => {
        var totalData = response.data
        // const filteredData = totalData.filter(row => !row.deleted);
        this.setState({
          rowData: totalData,
          loadshow: "show-n",
          overly: "show-n",
        })
      })
  }
  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      bulkslide: '',
      bulkslide1: "",
      family_code:'',
      model_code:'',
      sbvh_code:'',
      model_kerb_weight_in_kg:'',
    });
    $("#uploadFile").val("")
    var form = document.getElementById("initialEditDetails");
    if (form) {
      form.reset();
    }
  }
  async onCellClicked(event) {
    if (event.colDef.field === "Edit") {
      var data = event.data;
      console.log(data,'data',data.family_code)
      this.setState({
        rowId: data._id,
        family_code:data.family_code,
        model_code:data.model_code,
        sbvh_code:data.sbvh_code,
        model_kerb_weight_in_kg:data.model_kerb_weight_in_kg,
        bulkslide1: "slider-translate-30p",
      });
      console.log(this.state.family_code,'family_codefamily_code')
    }
  }

  addnewmodelmasterdata() {
    var form = document.getElementById("initialAddingDetails");
    if (form) {
      form.reset();
    }
    this.setState({
      bulkslide: "slider-translate-30p",
      overly: "show-m",
    })
  }

  async onClickAddDetails(e) {
    e.preventDefault();
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    })
    var family_code = this.state.family_code;
    var model_code = this.state.model_code;
    var sbvh_code = this.state.sbvh_code;
    var model_kerb_weight_in_kg = this.state.model_kerb_weight_in_kg;
    var formData = new FormData();
    formData.append('family_code', family_code)
    formData.append('model_code', model_code)
    formData.append('sbvh_code', sbvh_code)
    formData.append('model_kerb_weight_in_kg', model_kerb_weight_in_kg)
    formData.append('updated_by', localStorage.getItem("username"))
    if (family_code != "" && family_code != undefined && model_code != "" && model_code != undefined && sbvh_code != "" && sbvh_code != undefined && model_kerb_weight_in_kg != "" && model_kerb_weight_in_kg != undefined) {

      await redirectURL
        .post("/consignments/addemissionmodelmasterdata", formData)
        .then((response) => {
          this.setState({
            show: true,
            loadshow: "show-n",
            overly: "show-n",
            basicType: "success",
            basicTitle: "New details added Successfully",
            bulkslide: '',
            family_code:'',
            model_code:'',
            sbvh_code:'',
            model_kerb_weight_in_kg:'',
          })

        })
      this.componentDidMount()
    } else {
      this.setState({
        show: true,
        basicType: "warning",
        loadshow: "show-n",
        overly: "show-n",
        basicTitle: "All (*) fields are mandatory",
      })
    }
  }

  async onClickEditDetails(e) {
    e.preventDefault();
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    })
    var family_code = this.state.family_code;
    var model_code = this.state.model_code;
    var sbvh_code = this.state.sbvh_code;
    var model_kerb_weight_in_kg = this.state.model_kerb_weight_in_kg;
    
    var formData = new FormData();
    formData.append("rowId", this.state.rowId)
    formData.append('family_code', family_code)
    formData.append('model_code', model_code)
    formData.append('sbvh_code', sbvh_code)
    formData.append('model_kerb_weight_in_kg', model_kerb_weight_in_kg)
    formData.append('updated_by', localStorage.getItem("username"))

    if (family_code != "" && family_code != undefined && model_code != "" && model_code != undefined && sbvh_code != "" && sbvh_code != undefined && model_kerb_weight_in_kg != "" && model_kerb_weight_in_kg != undefined) {
   
          await redirectURL
          .post("/consignments/Editemissionmodelmasterdata", formData)
          .then((response) => {
            this.setState({
              show: true,
              loadshow: "show-n",
              overly: "show-n",
              basicType: "success",
              basicTitle: "Successfully Updated",
              bulkslide1: '',
              family_code:'',
              model_code:'',
              sbvh_code:'',
              model_kerb_weight_in_kg:'',
            })
  
          })
        $("#uploadFile").val("")
        var form = document.getElementById("initialEditDetails");
        if (form) {
          form.reset();
        }
        this.componentDidMount()
      }else{
        this.setState({
          show: true,
          basicType: "warning",
          loadshow: "show-n",
          overly: "show-n",
          basicTitle: "All (*) fields are mandatory",
        })
      }
  }

  closeAlert = () => {
    this.setState({
      show: false,
    });
  };

  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    // console.log(name, '---1', value)
    this.setState({ [name]: value });
  };
  
  render() {

    const batchwisecolumns = [
      {
        headerName: "Edit",
        field: "Edit",
        width: 110,
        resizable: true,
        editable: false,
        cellRenderer: function (params) {
        //   if (!params.data.not_planned_volumes) {
            var htmloption = '<button class="btn btn-warning" style="padding-top:0px">Edit</button>';
            return htmloption;
        //   } else {
        //     var htmloption = '<button class="btn btn-warning" disabled="on" style="padding-top:0px">Edit</button>';
        //     return htmloption;
        //   }
        },
        filter: false,
      },
    
      {
        headerName: 'Family Code',
        field: "family_code",
      },
      {
        headerName: 'Model Code',
        field: "model_code",
      },
      {
        headerName: 'SBVH Code',
        field: "sbvh_code",
      },
     
      {
        headerName: 'Model Kerb Weight in Kg',
        field: "model_kerb_weight_in_kg",
      },
    
    ];


    return (
      <div class="container-fluid">
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
      <h5>
              <i className="icofont icofont-vehicle-delivery-van cus-i"></i>{" "}
              <span>Model Master</span></h5>
        <div className="card col-xl-12">
          <div className="card-header" style={{display:'flex',flexDirection:'column'}}>
            <div><button className="btn btn-info" style={{ float: "right", marginRight: "10px" }} onClick={this.addnewmodelmasterdata.bind(this)}>Add New Model Master Details</button>
              </div>  
              <div className="card-body">
                <div className="row">
                  <div
                    id="myGrid"
                    style={{ width: "100%", height: "478px" }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      modules={this.state.modules}
                      columnDefs={batchwisecolumns}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.rowData}
                      enableCharts={false}
                      onGridReady={this.onGridReady2}
                      onGridState={this.onGridState}
                      frameworkComponents={this.state.frameworkComponents}
                      onCellClicked={this.onCellClicked.bind(this)}
                      sideBar={this.state.sideBar}
                      stopEditingWhenGridLosesFocus={true}
                      paginationPageSize={this.state.paginationPageSize}
                      pagination={true}
                      gridOptions={{
                        context: { componentParent: this },
                      }}
                      enableRangeSelection={true}
                    />
                  </div>
                </div>
              </div>
          </div>

          <div className={"slide-r " + (this.state.bulkslide)} style={{ overflow: "scroll" }}>
            <h3 className="subH">New Model Master Details
              <span className="float-right fclose" style={{ marginRight: "12px", padding: "1px 8px", marginTop: "-2px" }} onClick={this.onClickHideAll.bind(this)}>x</span>
            </h3>
            <div className="slide-r-body" style={{ position: "relative" }}>
              <div className="container-fluid">
                <form id="initialAddingDetails"
                  onSubmit={this.onClickAddDetails.bind(this)}
                  className="row col-xl-12 col-lg-12">

                   <div className="col-xl-12 col-lg-12 form-group">
                    <label>Family Code<span style={{ color: 'red' }}> *</span> </label>
                    <input
                      type="text"
                      value={this.state.family_code}
                      name="family_code"
                      id="family_code"
                      className="form-control"
                    //   onKeyPress={this.handleKeyPressCodeTrans}
                      autoComplete="off"
                      onChange={this.handleChange}
                      required
                    />
                  </div>

                  <div className="col-xl-12 col-lg-12 form-group">
                  <label>Model Code<span style={{ color: 'red' }}> *</span> </label>
                    <input
                      type="text"
                      value={this.state.model_code}
                      name="model_code"
                      id="model_code"
                      className="form-control"
                    //   onKeyPress={this.handleKeyPressCodeRakeId}
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>SBVH Code<span style={{ color: 'red' }}> *</span> </label>
                    <input
                      type="text"
                      value={this.state.sbvh_code}
                      name="sbvh_code"
                      id="sbvh_code"
                      className="form-control"
                    //   onKeyPress={this.handleKeyPressCodeTrans}
                      autoComplete="off"
                      onChange={this.handleChange}
                      required
                    />
                  </div>

                  <div className="col-xl-12 col-lg-12 form-group">
                  <label>Model Kerb Weight in Kg<span style={{ color: 'red' }}> *</span> </label>
                    <input
                      type="text"
                      value={this.state.model_kerb_weight_in_kg}
                      name="model_kerb_weight_in_kg"
                      id="model_kerb_weight_in_kg"
                      className="form-control"
                    //   onKeyPress={this.handleKeyPressCodeRakeId}
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </div>
                 

                  <div className="form-group col-xl-3 col-lg-3">
                    <button type="submit" className="btn btn-success">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className={"slide-r " + (this.state.bulkslide1)} style={{ overflow: "scroll" }}>
            <h3 className="subH">Edit Model Master Details
              <span className="float-right fclose" style={{ marginRight: "12px", padding: "1px 8px", marginTop: "-2px" }} onClick={this.onClickHideAll.bind(this)}>x</span>
            </h3>
            <div className="slide-r-body" style={{ position: "relative" }}>
              <div className="container-fluid">
                <form id="initialEditDetails"
                  onSubmit={this.onClickEditDetails.bind(this)}
                  className="row col-xl-12 col-lg-12">

                 
            <div className="col-xl-12 col-lg-12 form-group">
                                <label>Family Code<span style={{ color: 'red' }}> *</span> </label>
                                <input
                                  type="text"
                                  value={this.state.family_code}
                                  name="family_code"
                                  id="family_code"
                                  className="form-control"
                                //   onKeyPress={this.handleKeyPressCodeTrans}
                                  autoComplete="off"
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>

                              <div className="col-xl-12 col-lg-12 form-group">
                              <label>Model Code<span style={{ color: 'red' }}> *</span> </label>
                                <input
                                  type="text"
                                  value={this.state.model_code}
                                  name="model_code"
                                  id="model_code"
                                  className="form-control"
                                //   onKeyPress={this.handleKeyPressCodeRakeId}
                                  autoComplete="off"
                                  onChange={this.handleChange}
                                />
                              </div>
                              <div className="col-xl-12 col-lg-12 form-group">
                                <label>SBVH Code<span style={{ color: 'red' }}> *</span> </label>
                                <input
                                  type="text"
                                  value={this.state.sbvh_code}
                                  name="sbvh_code"
                                  id="sbvh_code"
                                  className="form-control"
                                //   onKeyPress={this.handleKeyPressCodeTrans}
                                  autoComplete="off"
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>

                              <div className="col-xl-12 col-lg-12 form-group">
                              <label>Model Kerb Weight in Kg<span style={{ color: 'red' }}> *</span> </label>
                                <input
                                  type="text"
                                  value={this.state.model_kerb_weight_in_kg}
                                  name="model_kerb_weight_in_kg"
                                  id="model_kerb_weight_in_kg"
                                  className="form-control"
                                //   onKeyPress={this.handleKeyPressCodeRakeId}
                                  autoComplete="off"
                                  onChange={this.handleChange}
                                />
                              </div>

                  <div className="form-group col-xl-3 col-lg-3">
                    <button type="submit" className="btn btn-success">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

        </div>


        <div className={"dataLoadpage " + (this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
          <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>
    );
  }
}

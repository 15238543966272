/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "./updatelmstates";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
// import 'jquery-ui-multidatespicker';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import { verifyext, verifycsvdata } from "../common/verifyext";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent = "";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class RailstageWiseDashoard extends Component {
    state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            frameworkComponents: {
                consignmentforceclose: Consignmentforceclose,
                consignmentActions: ConsignmentActions,
                UpdateButton: UpdateButton,
                MarkDeliver: MarkDeliver,
                commentActions: CommentActions
            },
            modified_by: "",
            basicTitle: '',
            reasonforceclose: "",
            bulkslide: "",
            file: "",
            uploadFile: "",
            basicType: "default",
            loadshow: 'show-n',
            forceCloseRowNode: "",
            forceclosedata: "",
            csvcontent: "",
            sliderForceCloseTranslate: "",
            overly: '',
            rowData: [],
            bulkslide: "",
            showDiv: "",
            uploadDivWidth: "",
            mapinfo: "",
            dealer: "",
            sliderCommentTranslate: "",
            commentsRowData: "",
            consignment_code: "",
            sliderRouteTranslate: "",
            maptruckno: "",
            routeTruck: "",
            rownode: "",
            googelRoutes: "",
            file: "",
            rowSelection: 'multiple',
            sliderForceCloseTranslate: "",
            maptruckno: "",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent",
                    statusPanelParams: {
                        // possible values are: 'count', 'sum', 'min', 'max', 'avg'
                        aggFuncs: ['sum', 'avg', "count", "min", "max"],
                      }, }
                ],
            },
            paginationPageSize: 200,
            geofencelist: [],
            geoFenceData: "",
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            dept_code: "",
            pagetitle: "",
            markDeliverRowNode: "",
            markDeliverData: "",
            sliderMarkDeliverTranslate: "",
            csvData: 0,
            overly: "show-n",
            loadshow: "show-n",
            holidayEvents: [],
            showCalendar: "show-m",
            showGridData: "show-m",
            plan_month: "",
            selectedDays: [],
            month_target: 0,
            working_days: 0,
            daily_target: 0,
            balance_days: 0,
            compledted_till_date: 0,
            actual_till_date: 0,
            achievement: 0,
            balance_to_be_planned: 0,
            achievementColor: "red",
            target_revised: 0,
            total_dispatches: 0,
            rail_share: 0,
            current_dispatch_fy: 0,
            current_rail_dispatch_fy: 0,
            current_rail_share: 0,
            vechicles_possible : 0,
            vechicles_planned : 0,
            current_vechicles_possible : 0,
            current_vechicles_planned : 0,
            vehClr : "white",
            vehPoss : 0,
            vehPlanned : 0,
            incoming_rakes : 0,
            load_capacity : 0,
            rake_capacity : 0,
            rakeClr : "white",
            no_of_rakes:0,
            total_trips:0,
            trips_per_rake:0,
            allrowData:[],
            allData:"activet", 
            hData:"",
            sData:"",
            rowData1 : [],
            newVehiclesPossible : 0,
            vinCountLength : 0,
            rakesvehiclesPossible: 0,
            rakesVinCountLength : 0,
            total_monthly_dispatchesStringValue: 0,
            vehiclesPlanned: 0,
            freshIncomingRakes: {},
            rakesPlannedToday: {},
            rakesCarriedForward: {},
            //components : {datepicker:getDatePicker()}
        }
        // this.changeFileHandler = this.changeFileHandler.bind(this);
        // this.handleDayClick = this.handleDayClick.bind(this);


    async componentDidMount() {
        loadDateTimeScript();
        var currentMonth = moment.parseZone().format("YYYY-MM");
        //console.log(currentMonth,"currentMonth")
        await this.setState({
            plan_month: currentMonth
        })
        await this.onLoadPageData();
    }
    onLoadPageData() {
        this.setState({
            loadshow : "show-m",
            overly : "show-m",
        })
        var plan_month = this.state.plan_month
        var params = {
            plan_month: plan_month
        }
        redirectURL.post("/consignments/getRailTargetByMonth", params).then((response) => {
            //console.log("response", response.data);
            var railTargetData = response.data.railtarget;
            if(railTargetData.length > 0)
            {
                var month_target = railTargetData[0].month_target;
                $("#month_target").val(month_target);
                var working_days = railTargetData[0].working_days;
                var selectedDays = []
                working_days.map(function(w){
                    if(w == '' || w == undefined)
                    {
                        return '';
                    }
                    else{
                        selectedDays.push( moment.parseZone(w)._d);
                    }
                    // console.log(moment.parseZone(w)._d,"bhanu")
                });
                this.setState({
                    selectedDays : selectedDays
                });
            }
            var actualTillDate = response.data.actualTillDate;
            var totalDispatches = response.data.totalDispatches;
            var railDispatchesShare = response.data.railDispatchesShare;
            var current_dispatch_fy = response.data.current_dispatch_fy;
            var current_rail_dispatch_fy = response.data.current_rail_dispatch_fy;
            var current_rail_share = response.data.currentrailDispatchesShare;
            try {
                railDispatchesShare = railDispatchesShare.toFixed(2)
            }
            catch (e) {

            }
            try {
                current_rail_share = current_rail_share.toFixed(2)
            }
            catch (e) {

            }
            var month_target = 0;
            var working_days = 0;
            var daily_target = 0;
            var balance_days = 0;
            var completed_days = 0;
            var compledted_till_date = 0;
            var achievement = 0;
            var balance_to_be_planned = 0;
            var achievementColor = "red";
            var target_revised = 0;

            if (railTargetData.length > 0) {
                month_target = parseInt(railTargetData[0].month_target);
                working_days = (railTargetData[0].working_days).length;
                if (month_target > 0 && working_days > 0) {
                    daily_target = Math.ceil(parseInt(month_target) / parseInt(working_days));
                    var wkDates = railTargetData[0].working_days;
                    var workingDates = [];
                    var balanceDates = [];
                    var completedDates = [];
                    wkDates.map(function (wkd) {
                        workingDates.push(moment.parseZone(new Date(wkd)).format("YYYY-MM-DD") + " 00:00:00");
                    });
                    //console.log(workingDates,"workingDates")
                    var currentDate = moment.parseZone().format("YYYY-MM-DD") + " 00:00:00";
                    currentDate = currentDate.split("+")[0];
                    // console.log(currentDate,"currentDate")
                    wkDates.map(function (wde) {
                        var comparision = moment(wde).isSameOrAfter(currentDate);
                        // console.log("moment(wde)",moment(wde))
                        // console.log("comparision",comparision)
                        if (comparision == true) {
                            balanceDates.push(wde);
                        }
                        else {
                            completedDates.push(wde);
                        }
                    });
                    //console.log(balanceDates,"balanceDates")
                    balance_days = balanceDates.length;
                    completed_days = completedDates.length;

                    compledted_till_date = parseInt(daily_target * completed_days);
                    balance_to_be_planned = parseInt(month_target) - parseInt(actualTillDate);
                    var balanceTillDate = parseInt(compledted_till_date) - parseInt(actualTillDate);                    
                    // achievement = (actualTillDate / month_target) * 100;
                    achievement = (actualTillDate / compledted_till_date) * 100; //made change 
                    try {
                        achievement = achievement.toFixed(2)
                    }
                    catch (e) {

                    }
                    if (achievement >= 95) {
                        achievementColor = "green";
                    }
                    else if (achievement < 95 && achievement >= 85) {
                        achievementColor = "yellow";
                    }
                    else {
                        achievementColor = "red";
                    }
                    if(balance_days > 0)
                    {
                        target_revised = Math.ceil(parseInt(balance_to_be_planned) / parseInt(balance_days)); //added math.ceil
                    }
                }
            }
            
            this.setState({
                month_target: month_target,
                working_days: working_days,
                daily_target: daily_target,
                balance_days: balance_days,
                compledted_till_date: compledted_till_date,
                actual_till_date: actualTillDate,
                achievement: achievement,
                achievementColor: achievementColor,
                balance_to_be_planned: balance_to_be_planned,
                target_revised: target_revised,
                total_dispatches: totalDispatches,
                // rail_share: railDispatchesShare, //changes made as per shankar mail
                current_dispatch_fy: current_dispatch_fy,
                current_rail_dispatch_fy: current_rail_dispatch_fy,
                current_rail_share: current_rail_share,
            })
            redirectURL.post("/consignments/getRailWorkInProgress", params).then((response) => {
                // console.log("WorkInProgress", response.data);
                //Carried Forward counts
                let newVehiclesPossible = response.data.newVehiclesPossible
                let vinCountLength = response.data.vinCountLength
                //Rakes Planned Today
                let rakesvehiclesPossible = response.data.rakesvehiclesPossible
                let rakesVinCountLength = response.data.rakesVinCountLength;

                var vehPoss = parseInt(response.data.vechicles_possible)+parseInt(response.data.current_vechicles_possible);
                var vehPlanned = parseInt(response.data.vechicles_planned)+parseInt(response.data.current_vechicles_planned);
                var vehClr = "white";

                console.log("color",parseInt(vehPoss) < parseInt(target_revised),parseInt(vehPoss), parseInt(target_revised))
                if((parseInt(newVehiclesPossible) + parseInt(rakesvehiclesPossible))  < parseInt(target_revised))
                {
                    vehClr = "red"; // made new changes as per mail by shankar
                }
                else
                {
                    vehClr = "green"; // made new changes as per mail by shankar
                }
                this.setState({
                    vechicles_possible : response.data.vechicles_possible,
                    vechicles_planned : response.data.vechicles_planned,
                    current_vechicles_possible : response.data.current_vechicles_possible,
                    current_vechicles_planned : response.data.current_vechicles_planned,
                    vehClr : vehClr,
                    vehPoss : vehPoss,
                    vehPlanned : vehPlanned,
                    newVehiclesPossible,
                    vinCountLength,
                    rakesvehiclesPossible,
                    rakesVinCountLength,
                    loadshow : "show-n",
                    overly : "show-n",
                    vehiclesPlanned: response.data.vehiclesPlanned
                });
            }).catch((e) => {
                console.log(e);
            })
        
            redirectURL.post("/consignments/getRailIncomingRakes", params).then((response) => {
                //console.log("incomingRakes", response.data);                
                var no_of_rakes = response.data.no_of_rakes;
                var total_trips = response.data.total_trips;
                var trips_per_rake = (parseInt(total_trips)/parseInt(no_of_rakes));
                if(trips_per_rake.toString().includes("."))
                {
                    trips_per_rake = trips_per_rake.toFixed(2);
                }
                this.setState({
                    incoming_rakes : response.data.rakesNos,
                    load_capacity : response.data.load_capacity,
                    rake_capacity : response.data.rake_capacity,
                    no_of_rakes : no_of_rakes,
                    total_trips : total_trips,
                    trips_per_rake : trips_per_rake,
                    // rakeClr : rakeClr,
                });
            }).catch((e) => {
                console.log(e);
            })
        }).catch((e) => {
            console.log(e);
        })

        redirectURL.post("/dashboard/getFreshIncomingRakes", params).then((resp)=>{
            this.setState({
                freshIncomingRakes: resp.data
            })
        })
        redirectURL.post("/dashboard/getRakesPlannedToday", params).then((resp)=>{
            this.setState({
                rakesPlannedToday: resp.data
            })
        })
        redirectURL.post("/dashboard/getRakesCarriedForward", params).then((resp)=>{
            this.setState({
                rakesCarriedForward: resp.data
            })
        })
        
        
        redirectURL.post("/consignments/fetchRailStageWiseData", params).then((response) => {
            //console.log("fetchRailStageWiseData", response.data);
            this.setState({
                rowData : response.data,
                allrowData : response.data,
            });
        }).catch((e) => {
            console.log(e);
        })

        
        redirectURL.post("/consignments/getRakeUtilizationForTheMonthData", params).then((response) => {
            // console.log("fetchRailStageWiseData", response.data);
            let rowData = response.data.final_data
            let total_monthly_dispatches = (response.data.total_monthly_dispatches)
            let total_monthly_dispatchesStringValue = (response.data.total_monthly_dispatches).toLocaleString('en-IN', {maximumFractionDigits:2})
            this.setState({
                rowData1 : rowData,
                total_monthly_dispatches,
                total_monthly_dispatchesStringValue
            });
        }).catch((e) => {
            console.log(e);
        })
    }
    handleDayClick = (day, { selected }) => {
        const selectedDays = this.state.selectedDays.concat();
        if (selected) {
            const selectedIndex = selectedDays.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day)
            );
            selectedDays.splice(selectedIndex, 1);
        } else {
            selectedDays.push(day);
        }
        console.log(selectedDays, "selectedDays")
        this.setState({ selectedDays });
    }
    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onFirstDataRendered = (event) => {
        event.api.addCellRange({
          rowStartIndex: 0,
          rowEndIndex: 4,
          columns: ['trip_per_rake'],
        });
      };

    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    // onCellClicked = (e)=> {
    //     if (e.colDef.field == "delete") {
    //         var rowId = e.data._id;
    //         console.log(e.data._id);
    //         var qry = {
    //             rowId: rowId,
    //             city: e.data.city,
    //             comp_code: this.state.comp_code,
    //             user_name: localStorage.getItem('username'),
    //             email: localStorage.getItem('email'),
    //             user_department: localStorage.getItem('lm_department'),
    //             staff_id: localStorage.getItem('staff_id'),
    //             designation: localStorage.getItem('designation'),
    //             user_role: localStorage.getItem('roles'),
    //         }
    //         //var confirm = confirm("Are You Sure! Do you want really delete?");
    //         if (window.confirm("Are You Sure! Do you want really delete?")) {
    //             redirectURL.post("/lmconsignees/deleteLmFreights", qry).then((response) => {
    //                 console.log(response.data);
    //                 if (response.data.message == "Success") {
    //                     this.setState({
    //                         basicTitle: "Deleted Successfully.",
    //                         basicType: "success",
    //                         show: true
    //                     })
    //                     //window.location.reload();
    //                     this.onLoadPageData();
    //                 }
    //                 else {
    //                     this.setState({
    //                         basicTitle: "Failed to Delete.",
    //                         basicType: "warning",
    //                         show: true
    //                     })
    //                 }
    //             }).catch((e) => {
    //                 console.log(e);
    //             })
    //         }
    //     }
    // }

    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            bulkslide: "",

        });

    }
    showBulkUpload() {
        this.setState({
            overly: "show-m",
            bulkslide: "slider-translate"
        })
    }
    resetUpload = () => {
        this.setState({
            bulkslide: '',
            overly: 'show-n',
            file: ''
        });
        document.getElementById("upform").reset();
    }
    // changeFileHandler = async (e) => {
    //     var dt = '';
    //     const config = {
    //         headers: [
    //             // { 
    //             //     name: 'id',
    //             //     inputName: 'id',
    //             //     required: false,
    //             // },
    //             {
    //                 name: 'city',
    //                 inputName: 'city',
    //                 required: true,
    //                 requiredError: function (headerName, rowNumber, columnNumber) {
    //                     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
    //                 }
    //             },
    //             {
    //                 name: 'state',
    //                 inputName: 'state',
    //                 required: true,
    //                 requiredError: function (headerName, rowNumber, columnNumber) {
    //                     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
    //                 }
    //             },
    //             {
    //                 name: 'rate_cmt',
    //                 inputName: 'rate_cmt',
    //                 required: true,
    //                 requiredError: function (headerName, rowNumber, columnNumber) {
    //                     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
    //                 }
    //             },
    //             {
    //                 name: 'vehicle_capacity',
    //                 inputName: 'vehicle_capacity',
    //                 required: true,
    //                 requiredError: function (headerName, rowNumber, columnNumber) {
    //                     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
    //                 }
    //             },
    //         ]
    //     }
    //     var data = CSVFileValidator(e.target.files[0], config)
    //         .then((csvData) => {
    //             console.log(csvData)
    //             this.setState({
    //                 csvData: csvData.data
    //             });
    //             console.log(csvData.data);
    //         })
    //         .catch(err => { })
    //     // console.log("e.target.files[0]",e.target.result);

    //     var out = new Promise(function (reject, resolve) {
    //         var reader = new FileReader();
    //         reader.onload = async function (e) {
    //             var contents = await e.target.result;
    //             resolve(contents);
    //         };
    //         var tt = reader.readAsText(e.target.files[0]);
    //     });
    //     this.setState({
    //         file: e.target.files[0]
    //     });
    //     //this.checkFileData();
    //     //console.log("e.target.files[0].type ", e.target.files[0]);
    //     var check = verifyext(e.target.files[0].name);
    //     //console.log("Check ", check)
    //     if (check == true) {
    //         if (e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
    //             // console.log(fileData);
    //             // console.log(typeof(fileData))

    //         }
    //         else {
    //             e.target.value = null;
    //             this.setState({
    //                 uploadFile: '',
    //                 show: true,
    //                 basicType: 'danger',
    //                 basicTitle: 'Please upload file having extensions .csv only.',
    //             });
    //         }
    //     }
    //     else {
    //         e.target.value = null;
    //         this.setState({
    //             uploadFile: '',
    //             show: true,
    //             basicType: 'danger',
    //             basicTitle: 'Please upload file having extensions .csv only.',
    //         });
    //     }
    // }

    // checkFileData() {

    //     var vdata = verifycsvdata(JSON.stringify(this.state.csvData));
    //     //console.log("verifycsvdata ", vdata)
    //     if (vdata == 0) {
    //         this.setState({
    //             csvData: '',
    //             show: true,
    //             basicType: 'danger',
    //             basicTitle: 'File contains invalid data',
    //             uploadFile: '',
    //             file: ""
    //         });
    //     }
    // }
    uploadBulkFormHandler = async (event) => {
        event.preventDefault();
        var flag = 0;
        var month_target = $("#month_target").val();
        var selectedDays = this.state.selectedDays;
        if (month_target != undefined && month_target != "") {
            if (selectedDays.length > 0) {
                var selDays = [];
                selectedDays.map(function (sd) {
                    var cnDate = moment.parseZone((sd)).utcOffset("+05:30");
                    selDays.push(cnDate)
                });
                this.setState({
                    overly: "show-m",
                    loadshow: "show-m",
                })
                var plan_month = this.state.plan_month;
                var reqParams = {
                    plan_month: plan_month,
                    month_target: month_target,
                    working_days: selDays
                }
                reqParams['user_name'] = localStorage.getItem('username');
                reqParams['email'] = localStorage.getItem('email');
                console.log("reqParams", reqParams)
                redirectURL.post("/consignments/saverailtarget", reqParams).then((response) => {
                    var resp = response.data.message;
                    console.log(resp)
                    this.setState({
                        basicTitle: resp,
                        basicType: "success",
                        show: true,
                        selectedDays: [],
                        bulkslide: '',
                        loadshow: "show-n",
                        overly: 'show-n',
                    })
                    //window.location.reload();
                    this.onLoadPageData();
                }).catch((e) => {
                    console.log(e);
                })
            }
            else {
                this.setState({
                    basicTitle: "Invalid File",
                    basicType: "warning",
                    show: true,
                })
            }
            $('#upform')[0].reset();
        }
        else {
            $("#bulkUploadID").val();
            this.setState({
                basicTitle: "Fields Should not be Empty",
                basicType: "warning",
                show: true,
            })
            $('#upform')[0].reset();
        }

    };




    onSelectBulkPlants = (location) => {
        var plants = [];
        try {
            location.map(function (e) {
                plants.push(e.value);
            })
            console.log(plants);
            this.setState({
                plant_name: plants
            });
        }
        catch (e) {
            console.log(e);
        }

    }

    onRowSelection(event) {
        var rowIds = [];
        var rwCount = event.api.getSelectedNodes();
        rwCount.map((item) => {
            rowIds.push(item.data._id)
        });

        this.setState({
            rowIds: rowIds
        })
    }

    onChangeFreightType() {
        var freight_type = $("#freightType").val();
        if (freight_type != '') {
            var loading_port_code = this.state.loading_port_code;
            var params = {
                loading_port_code: loading_port_code,
                freight_type: freight_type
            }
            redirectURL.post("/lmconsignees/updateFreightType", params).then((response) => {
                this.setState({
                    freight_type: freight_type
                })
                this.onLoadPageData()
            }).catch((e) => {
                console.log(e);
            })
        }
    }

    onChangeLoadingPlant = (e) => {
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m',
        })
        var selectedPlant = $("#loadingPlant").val();
        var params = {
            selectedPlant: selectedPlant,
        }
        redirectURL.post("/lmconsignees/getLoadingPlantDetails", params)
            .then((response) => {
                var res = response.data;
                this.setState({
                    loading_port_code: selectedPlant,
                    comp_code: res[0].comp_code,
                    loadshow: 'show-n',
                    overly: 'show-n',
                })
                this.pageAccess();
            })
            .catch(function (e) {
                console.log("Error ", e)
            })
    }

    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var selDay = (d.getMonth() + 1);
        if(parseInt(selDay) < 10)
        {
            selDay = "0"+selDay; 
        }
        var plan_month = d.getFullYear() + "-" + selDay;
        this.setState({
            plan_month: plan_month
        });
    }


    filterDataByMonth = async () => {
        await this.setState({
            plan_month: this.state.plan_month
        });
        await this.onLoadPageData();
    }

    changeTargetHandler = async (e) => {
        console.log(e)
    }

    onClickTab = (pageType) => {
        var allrowData = this.state.allrowData;
        if(pageType == "all")
        {
            this.setState({
                rowData : allrowData,
                allData:"activet", 
                hData:"",
                sData:"", 
            });
        }
        else if(pageType == "haryana")
        {
            var rowData = allrowData.filter((e) => e.plant_name !="Gujarat");
            this.setState({
                rowData : rowData,
                allData:"", 
                hData:"activet",
                sData:"", 
            });
        }
        else{
            var rowData = allrowData.filter((e) => e.plant_name == "Gujarat");
            this.setState({
                rowData : rowData,
                allData:"", 
                hData:"",
                sData:"activet", 
            });
        }
    }

    render() {
        var columnwithDefs = [
            {
                headerName: "TPT Name",
                field: "transporter_name",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Plant",
                field: "plant_name",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Loading Terminal",
                field: "actual_rake_loading_point",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Destination",
                field: "actual_rake_delivery_point",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Rake No",
                field: "actual_rake_no",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "MSIL Rake No",
                field: "msil_rake_no",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Invoice start date",
                field: "invoice_start_date",
                width: 150,
                filter: true,
                resizable: true,
                valueGetter: function(params){
                    if(params.data.invoice_start_date !=undefined && params.data.invoice_start_date !="")
                    {
                        return getHyphenDDMMYYYYHHMMSS(params.data.invoice_start_date);
                    }
                }
            },
            {
                headerName: "Invoice end date",
                field: "invoice_end_date",
                width: 150,
                filter: true,
                resizable: true,
                valueGetter: function(params){
                    if(params.data.invoice_end_date !=undefined && params.data.invoice_end_date !="")
                    {
                        return getHyphenDDMMYYYYHHMMSS(params.data.invoice_end_date);
                    }
                }
            },
            {
                headerName: "Planning Status",
                field: "planning_status",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Rake Capacity",
                field: "rake_capacity",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Invoice Count",
                field: "invoice_count",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Exit (VIN Count)",
                field: "invoiced_exit_count",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Pending For Exit (Count)",
                field: "pending_exit_count",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Standard TT",
                field: "standart_transit_time",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Actual TT",
                field: "actual_tt",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "First Mile (Planning To Exit)",
                field: "first_mile_count",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Rail",
                field: "middle_mile_count",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Last Mile",
                field: "last_mile_count",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Overall",
                field: "",
                width: 150,
                filter: true,
                resizable: true,
            },
        ]

        const columnwithDefs1 = [
            {
                headerName: "TPT Name",
                field: "transporter_name",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "No. of Rakes",
                field: "no_of_rakes",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Unique Rakes Utilized",
                field: "unique_rakes_utilized",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Total Trips",
                field: "total_trips",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Trips per Rake",
                field: "trip_per_rake",
                width: 150,
                filter: true,
                resizable: true,
            },
        ]
        const rakes_under_planning = this.state.freshIncomingRakes.plannable_vehicles + this.state.rakesCarriedForward.no_of_vins_possible + this.state.rakesPlannedToday.no_of_vins_possible;

        const achievementColor = {
            backgroundColor: this.state.achievementColor,
            color: "black"
        }
        const vehClr = {
            backgroundColor: (rakes_under_planning > this.state.target_revised)?"green":"red",
            color: "#fff"
        }

        var bgClr = "white";
        const rakeval = this.state.rake_capacity + this.state.newVehiclesPossible + this.state.rakesvehiclesPossible
        if(rakeval < parseInt(this.state.target_revised))
        {
            bgClr = "red";
        }
        else
        {
            bgClr = "green";
        }
        const rakeClr = {
            backgroundColor: bgClr,
            color: "#fff"
        }
        var rail_share = 0
        // console.log("actual_till_date",this.state.actual_till_date, "total_monthly_dispatches",this.state.total_monthly_dispatches)
        if(this.state.actual_till_date !== ""  && this.state.total_monthly_dispatches !== "" && this.state.total_monthly_dispatches !== undefined ){
            rail_share = ((this.state.actual_till_date/this.state.total_monthly_dispatches) * 100).toFixed(2) // changes as per shankar mail
        }


        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>

                <div className="card col-xl-12">
                    <div className="card-header">
                        <h5>
                            <i className="icofont icofont-train cus-i"></i> <span>Rail Stage Wise Dashboard</span>
                            <button className="btn btn-info" style={{ float: "right", marginRight: "10px" }} onClick={this.showBulkUpload.bind(this)}>Target Setup</button>
                        </h5>
                    </div>

                    <div className="row col-xl-12 col-lg-12" style={{ marginTop: "2%" }}>
                        <form method="POST" className="form-theme col-xl-12 col-lg-12 row">
                            <div className="col-xl-2 col-lg-2 form-group">
                                <label>Filter By Month</label>
                                {/* <input type="text" placeholder="Select Month" id="plan_month" autocomplete="off" className="datetimepicker_date form-control"  required /> */}
                                <Datetime
                                    value={this.state.plan_month}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{
                                        placeholder: 'Select Month',
                                        name: 'plan_month',
                                        autoComplete: 'off'
                                    }}
                                    dateFormat="YYYY-DD"
                                    name="plan_month"
                                    onChange={this.handlerStartDateTime.bind(this)}
                                />
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>&nbsp;</label><br />
                                <button type="button" className="btn btn-success" style={{ padding: "5px 10px", marginRight: "2%" }} onClick={this.filterDataByMonth.bind(this)}>Submit</button>

                            </div>
                        </form>
                    </div>

                    <div className="card-body col-xl-12 col-lg-12">
                        <div className="row col-xl-12 col-lg-12">
                            <div className="col-xl-10 col-lg-10">
                                <h5 style={{color:"#007bff"}}>Summary</h5>
                                <p style={{ float: "right", marginRight: "10px",background:"#bfb4c3", padding:'5px'}}>1. Achievement &gt;= 95% -&gt; <span style={{color:"green"}}>Green</span>   2. Achievement &gt;= 85% and &lt; 95% -&gt; <span style={{color:"yellow"}}>Yellow</span>   3. Achievement &lt; 85% -&gt; <span style={{color:"red"}}>Red</span></p>
                                <table className='table table-bordered rail-stage-tbl'>
                                    <tbody>
                                        <tr>
                                            <th>Target</th>
                                            <td>
                                                {(this.state.month_target)}
                                            </td>
                                            <th>Working days</th>
                                            <td>{this.state.working_days}</td>
                                        </tr>
                                        <tr>
                                            <th>Daily target</th>
                                            <td>{this.state.daily_target}</td>
                                            <th>Balance days</th>
                                            <td>{this.state.balance_days}</td>
                                        </tr>
                                        <tr>
                                            <th>Target (Till Date)</th>
                                            <td>{this.state.compledted_till_date}</td>
                                            <th>Actual (Till Date)</th>
                                            <td>{this.state.actual_till_date}</td>
                                        </tr>
                                        <tr>
                                            <th>Balance to be planned</th>
                                            <td>{this.state.balance_to_be_planned}</td>
                                            <th>Achievement</th>
                                            <td style={achievementColor}>{this.state.achievement + "%"}</td>
                                        </tr>
                                        <tr>
                                            <th>Daily Target (Revised)</th>
                                            <td>{this.state.target_revised}</td>
                                            <th>Yesterday Invoiced Count</th>
                                            <td>{this.state.rakesCarriedForward.yesterday_invoiced}</td>
                                        </tr>
                                        <tr>
                                            <th>Total dispatch (Road + Rail)</th>
                                            <td>{this.state.total_monthly_dispatchesStringValue}</td>
                                            <th>Rail share</th>
                                            <td>{`${rail_share}%`}</td>
                                        </tr>
                                        <tr>
                                            <th>Current dispatch FY (Rail +Road)</th>
                                            <td>{this.state.current_dispatch_fy.toLocaleString('en-IN', {maximumFractionDigits:2})}</td>
                                            <th></th>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>Current dispatch FY (Rail)</th>
                                            <td>{this.state.current_rail_dispatch_fy.toLocaleString('en-IN', {maximumFractionDigits:2})}</td>
                                            <th>Rail share FY</th>
                                            <td>{this.state.current_rail_share + "%"}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* work in progress code required planning html code */}
                        <div className="row col-xl-12 col-lg-12" style={{ marginTop: '4%' }}>
                            <div className="col-xl-10 col-lg-10">
                                <h5 style={{color:"#007bff"}}>Work-In-Progress (As of now)</h5>
                                <table className='table table-bordered rail-stage-tbl'>
                                    <tbody>
                                        <tr>
                                            <th>Required Planning</th>
                                            <th>Vehicles Possible (Rakes under Planning)</th>
                                            <th>No. of Vehicles Planned</th>
                                        </tr>
                                        <tr>
                                            <td>{this.state.target_revised}</td>
                                            <td style={vehClr}>{this.state.freshIncomingRakes.plannable_vehicles + this.state.rakesCarriedForward.no_of_vins_possible + this.state.rakesPlannedToday.no_of_vins_possible}</td>
                                            <td>{this.state.rakesCarriedForward.no_of_vehicles_planned +  this.state.rakesPlannedToday.no_of_vehicles_planned}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* work in progress carried forward rakes planned today*/}
                        <div className="row col-xl-12 col-lg-12" style={{ marginTop: '4%' }}>
                            <div className="col-xl-10 col-lg-10">
                                <table className='table table-bordered rail-stage-tbl'>
                                    <tbody>
                                        <tr>
                                            <th colspan="2" style={{textAlign:"center"}}>Carried Forward</th>
                                            <th colspan="2" style={{textAlign:"center"}}>Rakes Planned Today</th>
                                        </tr>
                                        <tr style={{backgroundColor: '#ffffc9'}}>
                                            <td>Vehicles Possible</td>
                                            <td>Vehicles Planned</td>
                                            <td>Vehicles Possible</td>
                                            <td>Vehicles Planned</td>
                                        </tr>
                                        <tr>
                                            {/* carried forward need to be implemented */}
                                            <td>{this.state.rakesCarriedForward.no_of_vins_possible}</td> 
                                            <td>{this.state.rakesCarriedForward.no_of_vehicles_planned}</td>
                                            <td>{this.state.rakesPlannedToday.no_of_vins_possible}</td>
                                            <td>{this.state.rakesPlannedToday.no_of_vehicles_planned}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* work in progress Fresh Incoming rakes */}
                        <div className="row col-xl-12 col-lg-12" style={{ marginTop: '4%' }}>
                            <div className="col-xl-10 col-lg-10">
                                <h5 style={{color:"#007bff"}}>Fresh Incoming Rakes</h5>
                                <table className='table table-bordered rail-stage-tbl'>
                                    <tbody>
                                        <th colspan="3" style={{textAlign:"center"}}>To Be Planned</th>
                                        <tr>
                                            <th>No. of Incoming Rakes</th>
                                            <th>Rake Capacity</th>
                                            <th>Vehicles that can be planned</th>
                                        </tr>
                                        <tr>
                                            <td>{this.state.freshIncomingRakes.total_incoming_rakes}</td>
                                            <td>{this.state.freshIncomingRakes.total_loading_capacity}</td>
                                            <td>{this.state.freshIncomingRakes.plannable_vehicles}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* rake utilization */}
                        {/* <div className="row col-xl-12 col-lg-12" style={{ marginTop: '4%' }}>
                            <div className="col-xl-10 col-lg-10">
                                <h5 style={{color:"#007bff"}}>Rake Utilization</h5>
                                <table className='table table-bordered rail-stage-tbl-20'>
                                    <tbody>
                                        <th colspan="5" style={{textAlign:"center"}}>For the Month</th>
                                        <tr>
                                            <th>AFTO</th>
                                            <th>No. of Rakes</th>
                                            <th>Unique Rakes Utilized</th>
                                            <th>Total Trips</th>
                                            <th>Trips per Rake</th>
                                        </tr>
                                        <tr>
                                            <td>A</td>
                                            <td>{this.state.no_of_rakes} </td>
                                            <td>{this.state.incoming_rakes}  </td>
                                            <td>{this.state.total_trips}  </td>
                                            <td>{this.state.trips_per_rake}  </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div> */}
                        <div className="row col-xl-12 col-lg-12" style={{ marginTop: '4%' }}>
                            <div className="col-xl-10 col-lg-10">
                                <h5 style={{color:"#007bff"}}>Rake Utilization</h5>
                                <table className='table table-bordered rail-stage-tbl-20'>
                                    <tbody>
                                        <th colspan="5" style={{textAlign:"center"}}>For the Month</th>                                        
                                    </tbody>
                                </table>
                                <div id="myGrid" style={{ width: "100%", height: "300px"}} className={"ag-theme-balham " + this.state.showGridData}>
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs1}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData1}
                                        enableCharts={false}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        stopEditingWhenGridLosesFocus={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        enableRangeSelection={true}
                                        pagination={true}
                                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        // components={this.state.components}
                                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                        
                        <div className="f16" style={{marginBottom:"10px",marginTop: '4%' }}>
                            <ul className="d-tabs">
                                <li onClick={this.onClickTab.bind(this,"all")} className={"lttabs "+(this.state.allData)}>
                                    <button type="button" className="btn">ALL</button>
                                </li>

                                <li onClick={this.onClickTab.bind(this,"haryana")} className={"lttabs "+(this.state.hData)}>
                                    <button type="button" className="btn">Haryana</button>
                                </li>

                                <li onClick={this.onClickTab.bind(this,"smg")} className={"lttabs "+(this.state.sData)}>
                                    <button type="button" className="btn">SMG</button>
                                </li>
                            </ul>
                        </div>
                        
                        <div id="myGrid" style={{ width: "100%", height: "478px"}} className={"ag-theme-balham " + this.state.showGridData}>
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus={true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                enableRangeSelection={true}
                            // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                            />
                        </div>
                    </div>
                </div>


                {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
                <div className={"slide-r " + (this.state.bulkslide)} style={{ overflow: "hidden" }}>
                    <h3 className="subH">Target Setup</h3>
                    <div className="slide-r-body" style={{ position: "relative" }}>

                        <div className="container-fluid">
                            <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>

                                <div className="form-group mt-20p">
                                    <label className="">Target</label>
                                    <input type="text" name="month_target" id="month_target" className="form-control num-cls" required />
                                </div>

                                <div className="form-group mt-20p">
                                    <label className="">Select Working Days</label>
                                    <DayPicker
                                        selectedDays={this.state.selectedDays}
                                        onDayClick={this.handleDayClick}
                                    />
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                    <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)} style={{top:"20%"}}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>

        );
    }
}

function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber1(date) {
    console.log(date.length);
    console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    console.log(yearNumber, monthNumber, dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        //mask:'39-19-9999 29:59',
        format: 'd-m-Y'
    });
    $('.datetimepicker_date').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker: false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    // console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

$(document).on("input", ".num-cls", function () {
    this.value = this.value.replace(/\D/g, '');
});
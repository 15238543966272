// 'use strict';

import React, { Component, } from 'react';
// import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
// import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
// import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
// import { MenuModule } from '@ag-grid-enterprise/menu';
import Select from 'react-select';
// import CommentActions from "./commentsComponent";
import { AllModules, } from '@ag-grid-enterprise/all-modules';
// import MarkDeliver from "./markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
// import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
// import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
// import { getHyphenDDMMMYYYYHHMM} from '../common/utils';
import lspcarrierdoc from './lspcarrierdoc'
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

export default class LspDashboard extends Component{
    constructor(props) {
        super(props);
          this.state ={
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            frameworkComponents : {
                lspcarrierdoc : lspcarrierdoc
            },
            modified_by:"",
            basicTitle:'',
            reasonforceclose:"",
            bulkslide:"",
            file:"",
            uploadFile:"",
            basicType:"default",
            loadshow:'show-m',
            forceCloseRowNode : "",
            forceclosedata:"",
            sliderForceCloseTranslate:"",
            overly:'show-m',
            showDiv:"",
            rowData:[],
            rowUpdateData:[],
            truckswithinvicinity:[],
            vicinityTrucksList : [],
            uploadDivWidth:"",            
            transList:[],
            sliderCommentTranslate:"",
            commentsRowData:"",
            consignment_code:"",
            sliderRouteTranslate:"",
            transListNo:"",
            routeTruck:	"",
            rownode:"",
            googelRoutes:"",
            rowSelection:'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
                statusBar:"",
            paginationPageSize:200,
            lspDocDetails:[],
            geoFenceData:"",
            sideBar: {
              toolPanels: [
                {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel"
                },
                {
                id: "filters",
                labelDefault: "Filters",
                labelKey: "filters",
                iconKey: "filter",
                toolPanel: "agFiltersToolPanel"
                }
              ]
            },
            animateRows: true,
            brequest:"activet",
            columns : [],
            lspCarrierDocs: [],
            trucksData: [],
            docTypedata: [],
            okDocCount : 0,
            totalTruckCount : 0,
            aboutToExpire : 0,
            expired : 0,
            expiredRow: [],
            aboutToExpireRow : [],
            userLoggedtype : false,
            transListVal: "",
            primaryontrips : [],
            secondaryontrips : [],
            onTripTrucks : 0,
            onTripTrucksCount : 0,
            ontripwithgps : []
          }
          
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    componentDidMount(){
        this.logPageView();
        let transList = []
        let initialTransCode =""
        var userLoggedtype  = false;
        var userType = localStorage.getItem('user_type');
        // this.gettruckswithinvicinity()
        if(userType === "TRANSPORTER")
        {
            var tptCode = localStorage.getItem('transportercode');
            userLoggedtype = true;
            let tcode = JSON.parse(tptCode)
            this.onLoadPageData(tcode[0])
            this.setState({
                userLoggedtype : userLoggedtype
            })
        }
        else
        {            
            redirectURL.post("/consignments/getSNDGTransporters").then(async (response)=>{
                let sndgTrans = response.data
                // console.log("sndgTrans",sndgTrans)
                // eslint-disable-next-line array-callback-return
                transList.push({
                    label: 'ALL',
                    value: 'ALL'
                })
                // eslint-disable-next-line array-callback-return
                sndgTrans.map(e =>{
                    transList.push({
                        label: e.transporter_name,
                        value: e.transporter_code
                    })
                })                
                // initialTransCode = sndgTrans[0].transporter_code;
                // console.log("initialTransCode",sndgTrans)
                this.setState({
                    transListVal :{label:"ALL", value:"ALL"},
                    transList,
                    userLoggedtype : userLoggedtype,
                    loadshow:'show-n',
                    overly:'show-n',
                })
                await this.onLoadPageData('ALL') 
                this.initialloadfunction('ALL')
            })
        }        
    }

    initialloadfunction = (transCode) =>{

        
        var formdata = {
			radius:10000,
			latitude:"20.73568784876339",
			longitude:"79.38999444931353",
			dept_code:"SNDG",
			status:0,
			trip_type : 2,
            
		}
        if(transCode !== 'ALL'){
            formdata.transporter_code= JSON.stringify([transCode])
        }
        redirectURL.post('/vicinity/getRadiusCoordinates',formdata,{
            headers:{
                'Content-Type':'application/json',
                // 'Access-Control-Allow-Origin': '*',
                // 'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
                'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"
    
            }
        }).then(async (response) =>{
            var markers = JSON.parse(JSON.parse(response.data.apiData.body).trucks_data);
            // console.log("markers",markers)
            
                var primaryontrips = []
                var secondaryontrips = []
                // eslint-disable-next-line array-callback-return
              await markers.map((rd) => {
                    // eslint-disable-next-line eqeqeq
                    if (rd.segment == "P") {
                        primaryontrips.push(rd);
                    }
                    // eslint-disable-next-line eqeqeq
                    else if (rd.segment == "S"){
                        secondaryontrips.push(rd);
                    }
                })
                this.setState({
                    ontripwithgps: markers,
                    primaryontrips : primaryontrips,
                    secondaryontrips : secondaryontrips
                })
            // console.log("secondaryontrips",secondaryontrips)
        })
        
       
        
    }

    gettruckswithinvicinity = async() =>{
        let formdata ={
            dept_code: "SNDG",
            gps_records_flag: 0,
            plant_code: '["B", "F", "G", "I", "N", "M", "MUNDRA", "MBPT"]',
            radius: "60",
            request_type: "1"
        }
        
        await redirectURL.post("/consignments/getvicinitytrucklistforlspdoc",formdata).then( (response) =>{
        //    console.log("reponse", response.data)
           this.setState({
               truckswithinvicinity : response.data
           })
        })
    }

    onLoadPageData = async (argument1) =>{
        // console.log(argument1,'argument1')
        let params ={}
        params["transporter_code"] = argument1
        // console.log("params",params)
       await redirectURL.post("/consignments/getCarrierDocumentDetails",params).then(async (response)=>{
            let rawD = response.data.details           
            let  trucksData=  response.data.trucksData;
        //    console.log("trucksDataRawD",rawD)
            await this.gettruckswithinvicinity()
            this.setState({
                lspCarrierDocs: rawD,
                trucksData: trucksData,
                docTypedata: response.data.docTypedata,
                rowData: [],
                rowUpdateData : [],
                loadshow:'show-n',
                overly:'show-n',
            })
            var waitForCols = this.createColumns();
            Promise.all([waitForCols]).then(async ()=>{
                await this.createRows();
            })
            if(this.state.userLoggedtype === false)
            {
                this.truckswithinvicinityfunction();
            }
        })
    }

    truckswithinvicinityfunction = () =>{
        const {rowData,truckswithinvicinity } = this.state
        let vicinityTrucksList = []
        // eslint-disable-next-line array-callback-return
        truckswithinvicinity.filter(e =>{
            // eslint-disable-next-line array-callback-return
            rowData.filter(item =>{
                if(e.truck_no === item.carrier_no){
                    vicinityTrucksList.push(item)
                }
            })
        })
        // console.log("vicinityTrucksList",vicinityTrucksList)
        this.setState({
            vicinityTrucksList
        })
    }

    createColumns = async () =>{
        var columns = [];
        columns.push({
            headerName:"Carrier No",
            field:"carrier_no",
            width:120,
        })
        var documnentTypes = this.state.docTypedata;
        // var trucksData = this.state.trucksData;
        // console.log("documnentTypes",documnentTypes)

        documnentTypes.map(async function(dct){
            var defs = {};
            //trucksData.map(async function(tda){            
                    defs.headerName = dct.document_name;
                    defs.field =dct.document_name;
                    defs.width = 120;
                    defs.cellRendererSelector =  function(params){
						var rendComponent = {
							component: 'lspcarrierdoc'
						};
						return rendComponent;
					}                   
                    columns.push(defs);
                    columns.push({
                        headerName:"Load File",
                        field:dct.document_name+"_validity",
                        width:120,
                        hide: true
                    })
               // })
        });

        this.setState({
           columns:  columns
        })
        // console.log("columns", columns)

    }

    createRows = async () =>{        
        var rowdefs = [];
        var trucksData = this.state.trucksData;
        // var docTypedata = this.state.docTypedata;
        var lspCarrierDocs = this.state.lspCarrierDocs;
        // console.log(trucksData,"trucksDataCreateRow")
        // console.log("lspCarrierDocs",lspCarrierDocs)
        // eslint-disable-next-line array-callback-return
        // trucksData.map((tda) =>{
        if(lspCarrierDocs.length > 0)
        {
            var carriers = groupBy(lspCarrierDocs, rdata=>rdata.truck_no);
            console.log("carriers",carriers)
            carriers.forEach((cn,cnKey)=>{
                if(cnKey == "GJ06AX5280")
                {
                    console.warn(cn, "chetak truck")
                }             
                var rows = {};
                rows["carrier_no"] = cnKey;
                // eslint-disable-next-line array-callback-return
                cn.map((icn) => {
                    
                    // if(icn.truck_no === tda)
                    // {
                    rows[icn.doc_name] = icn.is_uploaded;
                    rows[icn.doc_name+"_validity"] =icn.validity_expiry ;
                    // }
                });
                rowdefs.push(rows);
            });
        }
        // })
        // console.log("rowDatabeforefiltersdata", rowdefs)
        let docsCount = 0
        // eslint-disable-next-line array-callback-return
        rowdefs.filter(e =>{
            if(e["All India National Permit"] === 1 || e["Fitness"] === 1 || e["Insurance"] === 1 || e["PUC"] === 1 || e["Permit Authorization"] === 1 || e["RC"] === 1 || e["Rajasthan Permit"] ===1 || e["Road Tax"] === 1 || e["Spl. Road Tax"] === 1 || e["Tax Clearance"] ===1 || e["Vehicle Particulars"] === 1){
                docsCount = docsCount + 1
            }
        })

        let currentDate = moment.parseZone(new Date(moment.parseZone(new Date()).format("YYYY-MM-DD 00:00:00")))._d          
            let aboutToExpireRow =[];
            let expiredRow = [];
            // eslint-disable-next-line array-callback-return
            rowdefs.map( e => {
                let valExpDoc = ['All India National Permit_validity',"Fitness_validity","Insurance_validity","PUC_validity","Permit Authorization_validity","RC_validity","Rajasthan Permit_validity","Road Tax_validity","Spl. Road Tax_validity",'Tax Clearance_validity','Vehicle Particulars_validity']
                let flag = false;
                let flag2 = false;
                // eslint-disable-next-line array-callback-return
                valExpDoc.map((item)=>{
                    if(e[item] !== ""){
                        let ainpv = moment.parseZone(new Date(moment.parseZone(new Date(e[item])).format("YYYY-MM-DD 00:00:00")))._d;
                        let ainpvDays = moment(new Date(ainpv)).diff(moment(new Date(currentDate)), 'days');
                        if(ainpvDays <0)
                        {
                            flag2 = true;
                        }
                        if(ainpvDays >=0 && ainpvDays <= 90)
                        {
                            flag = true;
                        }                      
                    }
                })
                if(flag)
                {
                    aboutToExpireRow.push(e)
                }
                if(flag2){
                    expiredRow.push(e)
                }
            })
        
        this.setState({
            rowData : rowdefs,
            rowUpdateData : rowdefs,
            okDocCount : docsCount,
            aboutToExpireRow,
            expiredRow

        });
        console.log(rowdefs,"rowdefs")
    }
    onCellRowClicked =e =>{
        // if(e.colDef.field == "update"){
        //     var rowId = e.data._id;
        //     console.log("e", e)
        // }
    }

    onClickCounter(counterType){
        const {rowData,aboutToExpireRow,expiredRow,vicinityTrucksList,ontripwithgps} = this.state
        if(counterType === "lspTruckCount"){
            this.setState({
                rowUpdateData : rowData
            })
        }
        else if(counterType === "lspDocCount" ){
            let trucksHavingDoc = []
            // eslint-disable-next-line array-callback-return
            rowData.filter(e =>{
                if(e["All India National Permit"] === 1 || e["Fitness"] === 1 || e["Insurance"] === 1 || e["PUC"] === 1 || e["Permit Authorization"] === 1 || e["RC"] === 1 || e["Rajasthan Permit"] ===1 || e["Road Tax"] === 1 || e["Spl. Road Tax"] === 1 || e["Tax Clearance"] ===1 || e["Vehicle Particulars"] === 1){
                    trucksHavingDoc.push(e)
                }
            })
            this.setState({
                rowUpdateData : trucksHavingDoc
            })

        }
        else if(counterType === "aboutToExpire" ){          
           
            this.setState({
                rowUpdateData : aboutToExpireRow
            })
        }
        else if(counterType === "expired" ){
           
            this.setState({
                rowUpdateData : expiredRow
            })
            
        }
        else if(counterType === 'truckswithinvicinity'){
            this.setState({
                rowUpdateData : vicinityTrucksList
            })
        }
        else if(counterType === 'ontriptrucks'){
            this.setState({
                rowUpdateData : ontripwithgps
            })
        }

    }

    changeTransHandler = event =>{
        // console.log("event",event)
        this.setState({
            transListVal : event,
            transListNo: event.value,
            loadshow:'show-m',
            overly:'show-m',
        }, () =>{
            this.onLoadPageData(event.value)
            this.initialloadfunction(event.value)
        }) 
        
    }

    onClickHideAll =() =>{
        this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:"",               
        });  
    }

    onGridReady = params => {
		this.gridApi = params.api;		
	    // console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;		   
	};
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);
    }

    render(){

        // const columnwithDefs = [
        //     {
        //         headerName: "Trucks",
        //         field: "carrier_no",          
        //         filter: true,
        //         editable: true,
        //         headerHeight: 140,
        //         width:100,
        //         resizable: true,                
        //     },
        //     {
        //         headerName: "All India National Permit",
        //         field: "doc_name",          
        //         filter: true,
        //         editable: true,
        //         width:90,
        //         headerHeight: 140,
        //         resizable: true,
        //         valueGetter : (param) =>{
        //             let paramData = param.data.doc_name
        //             if(paramData.includes("All India National Permit")) return "yes"
        //             return "No"
        //         }
        //     },
        //     {
        //         headerName: "Fitness",
        //         field: "doc_name",          
        //         filter: true,
        //         editable: true,
        //         width:90,
        //         headerHeight: 140,
        //         resizable: true,
        //         valueGetter : (param) =>{
        //             let paramData = param.data.doc_name
        //             if(paramData.includes("Fitness")) return "yes"
        //             return "No"
        //         }
        //     },
        //     {
        //         headerName: "Insurance",
        //         field: "doc_name",          
        //         filter: true,
        //         editable: true,
        //         width:90,
        //         headerHeight: 140,
        //         resizable: true,
        //         valueGetter : (param) =>{
        //             let paramData = param.data.doc_name
        //             if(paramData.includes("Insurance")) return "yes"
        //             return "No"
        //         }
        //     },
        //     {
        //         headerName: "Permit Authorization",
        //         field: "doc_name",          
        //         filter: true,
        //         editable: true,
        //         width:90,
        //         headerHeight: 140,
        //         resizable: true,
        //         valueGetter : (param) =>{
        //             let paramData = param.data.doc_name
        //             if(paramData.includes("Permit Authorization")) return "yes"
        //             return "No"
        //         }
        //     },
        //     {
        //         headerName: "PUC",
        //         field: "doc_name",          
        //         filter: true,
        //         editable: true,
        //         width:90,
        //         headerHeight: 140,
        //         resizable: true,
        //         valueGetter : (param) =>{
        //             let paramData = param.data.doc_name
        //             if(paramData.includes("PUC")) return "yes"
        //             return "No"
        //         }
        //     },
        //     {
        //         headerName: "Rajasthan Permit",
        //         field: "doc_name",          
        //         filter: true,
        //         editable: true,
        //         width:90,
        //         headerHeight: 140,
        //         resizable: true,
        //         valueGetter : (param) =>{
        //             let paramData = param.data.doc_name
        //             if(paramData.includes("Rajasthan Permit")) return "yes"
        //             return "No"
        //         }
        //     },
        //     {
        //         headerName: "RC",
        //         field: "doc_name",          
        //         filter: true,
        //         editable: true,
        //         width:90,
        //         headerHeight: 140,
        //         resizable: true,
        //         valueGetter : (param) =>{
        //             let paramData = param.data.doc_name
        //             if(paramData.includes("RC")) return "yes"
        //             return "No"
        //         }
        //     },
        //     {
        //         headerName: "Road Tax",
        //         field: "doc_name",          
        //         filter: true,
        //         editable: true,
        //         width:90,
        //         headerHeight: 140,
        //         resizable: true,
        //         valueGetter : (param) =>{
        //             let paramData = param.data.doc_name
        //             if(paramData.includes("Road Tax")) return "yes"
        //             return "No"
        //         }
        //     },
        //     {
        //         headerName: "Spl. Road Tax",
        //         field: "doc_name",          
        //         filter: true,
        //         editable: true,
        //         width:90,
        //         headerHeight: 140,
        //         resizable: true,
        //         valueGetter : (param) =>{
        //             let paramData = param.data.doc_name
        //             if(paramData.includes("Spl. Road Tax")) return "yes"
        //             return "No"
        //         }
        //     },
        //     {
        //         headerName: "Tax Clearance",
        //         field: "doc_name",          
        //         filter: true,
        //         editable: true,
        //         width:90,
        //         headerHeight: 140,
        //         resizable: true,
        //         valueGetter : (param) =>{
        //             let paramData = param.data.doc_name
        //             if(paramData.includes("Tax Clearance")) return "yes"
        //             return "No"
        //         }
        //     },
        //     {
        //         headerName: "Vehicle Particulars",
        //         field: "doc_name",          
        //         filter: true,
        //         editable: true,
        //         width:90,
        //         headerHeight: 140,
        //         resizable: true,
        //         valueGetter : (param) =>{
        //             let paramData = param.data.doc_name
        //             if(paramData.includes("Vehicle Particulars")) return "yes"
        //             return "No"
        //         }
        //     },

        // ]
        const {rowData,rowUpdateData,expiredRow,aboutToExpireRow,userLoggedtype,transList,transListVal,vicinityTrucksList,ontripwithgps } = this.state
        return(
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                <div className="col-xl-12 col-lg-12">
                    {userLoggedtype ? "" :
                    <div className="card mt-2 p-1" style={{minHeight:"100px"}}>
                        <div className="col-xl-4 col-lg-4">
                            <label className="col-xl-12 col-lg-12" style={{font: "roboto"}}>Select Transporter: </label>
                            <Select 
                                value={transListVal} 
                                onChange={this.changeTransHandler} 
                                style={{borderRadius:"0px"}} 
                                options={transList}
                                placeholder="Select Transporter"
                                required />
                        </div>
                    </div>}
                    
                    <div className="card mt-2">
                        <div className="card-body" id="counterCardBody">
                            <div className="crm-numbers pb-0">
                                <div className="row">
                                    <div className="col cirlce-d cursorPointer" onClick={this.onClickCounter.bind(this,"lspTruckCount")}>
                                        <span className="f13">
                                            <br /> Total No Of Carriers</span>
                                        <h4 className="txt-info f40"><span className="counter"><CountUp end={(rowData !== '')?rowData.length:0}/></span></h4>										
                                    </div>
                                    
                                    <div className="col cirlce-d cursorPointer" onClick={this.onClickCounter.bind(this,"lspDocCount")}>
                                        <span className="f13">
                                            <br /> No Of Trucks Having Docs</span>
                                        <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.okDocCount !== '')?this.state.okDocCount:0}/></span></h4>										
                                    </div>

                                    <div className="col cirlce-d cursorPointer" onClick={this.onClickCounter.bind(this,"aboutToExpire")}>
                                        <span className="f13">
                                            <br /> About To Expire</span>
                                        <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.aboutToExpire !== '')?aboutToExpireRow.length:0}/></span></h4>										
                                    </div>

                                    <div className="col cirlce-d cursorPointer" onClick={this.onClickCounter.bind(this,"expired")}>
                                        <span className="f13">
                                            <br /> Expired</span>
                                        <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.expired !== '')?expiredRow.length:0}/></span></h4>										
                                    </div>
                                    {userLoggedtype ? "" : 
                                        <div className="col cirlce-d cursorPointer" onClick={this.onClickCounter.bind(this,"truckswithinvicinity")}>
                                            <span className="f13">
                                                <br /> Trucks Within Vicinity(Within 60 Kms)</span>
                                            <h4 className="txt-info f40"><span className="counter"><CountUp end={(vicinityTrucksList !== '')?vicinityTrucksList.length:0}/></span></h4>										
                                        </div>                                    
                                    }
                                    {userLoggedtype ? "" : 
                                    <div className="col">                                    
                                        <div className="col cirlce-d cursorPointer" onClick={this.onClickCounter.bind(this,"ontriptrucks")}>
                                            <span className="f13">
                                                <br /> On Trip Trucks</span>
                                            <h4 className="txt-info f40"><span className="counter"><CountUp end={(ontripwithgps !== '')?ontripwithgps.length:0}/></span></h4>										
                                        </div>                                    
                                        <div className="f16" style={{display:'flex'}}>
                                        <span className="counter txt-secondary-medium" style={{color:"#0077ff"}}>
                                        Primary: &nbsp;
                                            <CountUp end={(this.state.primaryontrips !== '')?this.state.primaryontrips.length:0}/>
                                        </span>&nbsp;&nbsp;
                                        <span className="counter txt-danger-medium" style={{color:"#fd2f52"}}>
                                            Secondary: &nbsp;
                                            <CountUp end={(this.state.secondaryontrips !== '')?this.state.secondaryontrips.length:0}/>
                                        </span>
                                        </div>                                    
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="card">                    
                        <div className="card-header space-between">
                            <h5 className="float-left">
                                <i className="icon-map cus-i"></i><span> Lsp Document Input</span>
                            </h5>
                            <div>
                           {userLoggedtype ? 
                               <a href="/addlspdocdetails" className="btn btn-success pull-right cs-btn"> <i className="icon-plus"></i>Add Certificate</a> : ""}
                            </div> 
                        </div>
                        
                        <div id="tptUserItemGrid" className="card-body col-sm-12">                        
                            <div id="myGrid" style={{ height: "600px",width: "100%",}}  className="ag-theme-balham">
                                <AgGridReact
                                modules={this.state.modules}
                                columnDefs= {this.state.columns}
                                defaultColDef={this.state.defaultColDef}
                                rowData={rowUpdateData}
                                // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                enableCharts={true}
                                enableRangeSelection={true}
                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                detailCellRendererParams={this.state.detailCellRendererParams}
                                stopEditingWhenGridLosesFocus={true}
                                enableCellChangeFlash={true}
                                suppressCellFlash={true}
                                rowClassRules={this.state.rowClassRules}
                                //onCellClicked={this.onRowClicked.bind(this)}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                masterDetail={true}
                                //getRowHeight={this.state.getRowHeight}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                onCellClicked={this.onCellRowClicked}			
                                rowSelection={this.state.rowSelection}
                                // onRowSelected={this.onRowSelection.bind(this)}
                                suppressRowClickSelection={true}
                                components={this.state.components}
                                // suppressCellSelection= {true}
                                // overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}										
                                />
                            </div>								
                        </div>
                    </div>
                </div>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll}></div>
            </div>
        )
    }
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}


import React, {Component} from 'react';

import { Button, Form, FormGroup, Label, Input, FormText,Alert, UncontrolledAlert } from 'reactstrap';
import _ from "lodash";

import SweetAlert from 'react-bootstrap-sweetalert';
import $ from 'jquery';
var redirectURL = require('../redirectURL');
var images = require.context('../../assets/images/', true);
var googleAnalytics = require("../common/googleanalytics");

class Register extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "New User",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            style: {},
            firstname:'',
            lastname:'',
            email:'',
            password:'',
            dept_code:'',
            role:'',
            alldepartments:[],
            alertFade:true,
            formMessage:'',
            formStatus:'',
            show: false,
            basicTitle:'',
            basicType:"default",
            show1: false,
            basicTitle1:'',
            basicType1:"default",
            sale_type:"",
            regions:[],
            region:"",
            showregion:"show-n",
            otpAuthentication:0,
            otp:"",
            userData:[]
        };
        this.changeHandler=this.changeHandler.bind(this);
        this.departmentDropdownOptions = this.departmentDropdownOptions.bind(this);
        this.formHandler = this.formHandler.bind(this);
		this.cipher =this.cipher.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount() {
        this.logPageView(); // Log page view to GA
        redirectURL.post('/overview/departments',{
			headers:{
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
			}
		})
        .then((response) => {
            this.setState({
                alldepartments:response.data
            });
        })
        .catch((error) => {
            console.log(error)
        })
        setTimeout(function () {
            this.setState({style: {display:'none'}});
        }.bind(this),1000)
    }
    departmentDropdownOptions(){
        var options=[];
        this.state.alldepartments.map((item) =>{
            options.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>)
        });
        options.push(<option key="Nexa" value="Nexa">Nexa</option>)
        options.push(<option key="Arena" value="Arena">Arena</option>)
        
        return options;
    }
    changeHandler(event){
        let name = event.target.name;
        let value = event.target.value;
       
        this.setState({
            [name]:value
        });
       
    }
    
    changeDeptHandler(event){
        let name = event.target.name;
        let value = event.target.value;
       
        this.setState({
            [name]:value
        });
        if(value === "Nexa" || value === "Arena")
        {
            var params = {
                sale_type:value
            }
            redirectURL.post("/overview/saletypesregion",params)
            .then((response) => {
                var records = response.data.records;
                    var options=[];
                    if(records.length > 0)
                    {
                        records.map((item) =>{
                            options.push(<option key={item.region} value={item.region}>{item.region_name}</option>)
                        });
                    }
                    this.setState({
                        regions:options,
                        showregion:"show-m"
                    })
            })
        }
        else
        {
            this.setState({
                showregion:"show-n",
                region:""
            })
        }
        
    }
    formHandler(event){
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": googleAnalytics.page.action.formSubmittedUserRegistration,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        //console.log(event.target);

        var data = new FormData();
        data.append('firstname',this.state.firstname)
        data.append('lastname',this.state.lastname)
        data.append('email',this.state.email)
        data.append('password',this.state.password)
        data.append('dept_code',this.state.dept_code)
   // console.log("Form data ",data);
        var email = this.state.email.replace(/\s/g,"").toLowerCase();
        var verifypwd = CheckPassword(this.state.password)
       // var fvalid =  CheckField(this.state.firstname);
        var regex = /^[A-Za-z0-9 ]+$/
 
        var fvalid = regex.test(this.state.firstname);
        var lvalid =  regex.test(this.state.lastname);
        //var evalid =  CheckField(this.state.email);
        if(fvalid === false || lvalid === false)
        {
            this.setState({
                show:true,
                basicTitle:"Please enter valid data in fields",
                basicType:"danger",
                firstname:"",
                lastname:"",
                email:"",
                dept_code:"",
                password:""
            })
        }
        else
        {
            //console.log("verifypwd ",verifypwd)
            if(verifypwd == true)
            {
                var ppwwdd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
                var usrpwd = ppwwdd(this.state.password)
                var formdata = {
                    firstname:this.state.firstname,
                    lastname:this.state.lastname,
                    email: email,
                    password:usrpwd,
                    dept_code:this.state.dept_code,
                    region:this.state.region
                }
                //console.log("formdata ", formdata);
                    redirectURL.post('/registration',formdata,{
                        headers:{
                            'Content-Disposition': 'application/x-www-form-urlencoded',
                            'Access-Control-Allow-Methods': 'POST'
                        }
                    })
                    .then((response) => {
                        var record = response.data;
                        if((this.state.dept_code === "Nexa" || this.state.dept_code === "Arena") && record.status === "Success")
                        {
                            var otpauth = 1;
                            var otpt = "Yes";
                        }
                        else{
                            var otpauth=0;
                            var otpt = "";
                        }
                        this.setState({
                            formMessage:response.data.message,
                            formStatus:response.data.status,
                            userData:record.user,
                            otpAuthentication:otpauth,
                            otp:otpt,
                            alertFade:false,
                            firstname:'',
                            lastname:'',
                            email:'',
                            password:'',
                            dept_code:'',
                            role:'',
                        });
                    $("#entered_otp").val("");
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
            else
            {
                this.setState({
                    show:true,
                    basicTitle:"Password should be between 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character",
                    basicType:"danger"
                })
            }
        }
    }
    closeAlert = async() => {
		this.setState({
			show:false,
			basicTitle:""
        })
        window.location.reload();
    }
    
    closeAlert1(){
		this.setState({
			show:false,
			basicTitle:""
		})
    }
    

	cipher = salt => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
		const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
	
		return text => text.split('')
			.map(textToChars)
			.map(applySaltToChar)
			.map(byteHex)
			.join('');
	}

    otpformHandler  = (event) => {
		event.preventDefault();
		var entered_otp = $("#entered_otp").val();
		if(entered_otp !='')
		{
            var userData = this.state.userData
            var userid = userData[0]._id;
            var params = {
                otp : entered_otp,
                userid : userid,
            }
            redirectURL.post("/registration/otpSuccess",params).then(async (response)=>{
                console.log("response ", response.data)
                if(response.data.status === "success")
                {
                    this.setState({
                        show:true,
                        basicTitle:"Registration process successfully completed",
                        basicType:"success"
                    })
                }
                else
                {
                    this.setState({
                        show1:true,
                        basicType1:"danger",
                        basicTitle1:"Invalid OTP"
                    })
                }
            })
        }
    }

    render() {
        let style = this.state.style;
        const background = require('../../assets/images/auth-layer.png')

        return (
            <div>
                <SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert.bind(this)}
	        >
	        </SweetAlert>
            <SweetAlert
	            show={this.state.show1}
	            type={this.state.basicType1}
	            title={this.state.basicTitle1}
	            onConfirm={this.closeAlert1.bind(this)}
	        >
	        </SweetAlert>
	            {this.state.alert}
                {/* Loader starts */}
                <div className="loader-wrapper" style={style}>
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Have a great day at work today <span>&#x263A;</span></h4>
                    </div>
                </div>
                {/* Loader ends */}

                {/*page-wrapper Start*/}
                <div className="page-wrapper">

                    <div className="container-fluid">

                        {/*sign up page start*/}
                        <div className="authentication-main">
                            <div className="row">
                                <div className="col-md-4 p-0">
                                    <div className="auth-innerleft" style ={{ backgroundImage: "url("+background+")" }}>
                                        <div className="text-center">
                                        <img src={require('../../assets/icons/new-logo.png')}
                                                    className="logo-login" style={{width:'75%'}} alt=""/>
                                               <hr/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 p-0">
                                    <div className="auth-innerright">
                                        <div className="authentication-box">
                                            <h3 className="text-center">NEW USER</h3>
                                            <h6 className="text-center">Enter your Username and Password For Login or Signup</h6>
                                            <div className="card mt-4 p-4">
                                            
                                                {this.state.formStatus != ""?
                                                        <div>
                                                                {(this.state.formStatus == 'Success')?
                                                                    <Alert color="secondary" fade={this.state.alertFade}>
                                                                        
                                                                    <i className="icon-thumb-up"></i>   {this.state.formMessage}
                                                                    {this.state.alertFade=true}
                                                                    {/* &nbsp; An email has been sent to you. Please Verify before logging in. */}
                                                                </Alert>
                                                                :
                                                                <Alert color="danger" fade={this.state.alertFade}>
                                                                    <i className="icon-alert"></i>  {this.state.formMessage}
                                                                    {this.state.alertFade=true}
                                                                </Alert>
                                                                }
                                                        </div>
                                                    :""
                                                }
                                            {(this.state.otpAuthentication == 1 && this.state.otp !='') ?
                                                <form className="theme-form" onSubmit={this.otpformHandler}  autoComplete="off">
                                                    {(this.state.otpMessage)?
                                                    <div className="alert alert-danger">{this.state.otpMessage}</div>
                                                        :""}
                                                    <div className="form-group">
                                                        <label className="col-form-label pt-0">Enter OTP</label>
                                                        <input autoComplete="off" required type="text" name="entered_otp" id="entered_otp"  className="form-control form-control-lg"/>
                                                    </div>
                                                    
                                                    <div className="form-group form-row mt-3">
                                                        <div className="col-md-12 textAlignCenter">
                                                            <button type="submit" id="loginButton" className="btn btn-secondary">Submit
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="form-group form-row mt-3">
                                                        <div className="col-md-12">
                                                            <a href="javascript:;" onClick={this.resendOtp} className="float-right">Resend OTP</a>
                                                        </div>
                                                    </div>
                                                </form>
                                                :
                                                <form className="theme-form" onSubmit={this.formHandler}>
                                                    <div className="form-row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="col-form-label">First Name</label>
                                                                <input required type="text" className="form-control" name="firstname" value={this.state.firstname} onChange={this.changeHandler} autoComplete='off' placeholder="Enter First Name"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="col-form-label">Last Name</label>
                                                                <input required type="text" className="form-control" name="lastname" value={this.state.lastname} onChange={this.changeHandler} autoComplete='off' placeholder="Enter Last Name"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Email Address</label>
                                                        <input type="email" required className="form-control" name="email" value={this.state.email} onChange={this.changeHandler} autoComplete='off' placeholder="Email Address"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Password</label>
                                                        <input type="password" required className="form-control" name="password" value={this.state.password} onChange={this.changeHandler} autoComplete='off' placeholder="**********"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Department</label>
                                                        <div className="form-row">
                                                            <div className="col-sm-12">
                                                                <select required className="form-control mb-1" name="dept_code" value={this.state.dept_code} onChange={this.changeDeptHandler.bind(this)}>
                                                                    <option value=''>Select</option>
                                                                    {this.departmentDropdownOptions()}
                                                                </select>
                                                            </div>
                                                             
                                                        </div>
                                                    </div>
                                                    <div className={"form-row "+(this.state.showregion)}>
                                                        
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="col-form-label">Region</label>
                                                                <select className="form-control mb-1" name="region" value={this.state.region} onChange={this.changeHandler}>
                                                                    <option value=''>Select</option>
                                                                    {this.state.regions}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="col-sm-3">
                                                            <button type="submit" className="btn btn-secondary">Register
                                                            </button>
                                                        </div>
                                                        <div className="col-sm-8">
                                                            <div className="text-left mt-2 m-l-20">
                                                                Are you already user?&nbsp;&nbsp;<a href={"/"} className="btn-link text-capitalize">Login</a>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    
                                                </form>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        {/*sign up page Ends*/}

                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

function CheckPassword(inputtxt) 
{ 
	var decimal=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
	if(inputtxt.match(decimal)) 
	{ 
		return true;
	}
	else
	{ 
		return false;
	}
} 
function CheckField(inputtxt) 
{ 
    console.log("inputtxt ", inputtxt)
    // var decimal=  /^(?=.*?[A-Za-z])(?=.*?[0-9])[A-Za-z0-9]+$/;
    // console.log("inputtxt.match(decimal ",inputtxt.match(decimal))
	// if(inputtxt.match(decimal)) 
	// { 
	// 	return true;
	// }
	// else
	// { 
	// 	return false;
    // }
    var regex = /^[A-Za-z0-9 ]+$/
 
    //Validate TextBox value against the Regex.
    var isValid = regex.test(inputtxt);
    console.log("isValid ", isValid)
    if (!isValid) {
        alert("Contains Special Characters.");
    } else {
        alert("Does not contain Special Characters.");
    }

    return isValid;
} 
export default Register;
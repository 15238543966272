import React, { Component } from "react";
import CSVFileValidator from 'csv-file-validator';
import moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MenuModule } from "@ag-grid-enterprise/menu";
import Select from "react-select";
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import $ from "jquery";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { color } from "highcharts";
import { DateTime } from "@grapecity/wijmo";
// import { formatDate } from "@fullcalendar/react";
var redirectURL = require("../redirectURL");

export default class LastMileHubConsigmentScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        width: 160,
        resizable: true,
      },
      eventAction: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      // loadshow: "show-n",
      // overly: "show-n",
      rowdata: [],
      rowdataforexwork:[],
      additionalchargesdata:[],
      sliderbulkupload: "",
      rowModelhighchartoptionsType: "enterprise",
      //   frameworkComponents: {
      //     BatchDownload: BatchDownload,
      //   },
      rowGroupPanelShow: "always",
      statusBar: {
        // statusPanels: [
        //   {
        //     statusPanel: "agTotalAndFilteredRowCountComponent",
        //     align: "left",
        //   },
        //   {
        //     statusPanel: "agTotalRowCountComponent",
        //     align: "center",
        //   },
        //   { statusPanel: "agFilteredRowCountComponent" },
        //   { statusPanel: "agSelectedRowCountComponent" },
        //   { statusPanel: "agAggregationComponent" },
        // ],
      },
      paginationPageSize: 50,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      animateRows: true,
      dept_code: "",
      pagetitle: "",
      csvData: 0,
      overly: "show-n",
      loadshow: "show-n",
      pagetitle: "",
      eventGridAction: "gridAction",
      csvcontent: [],
      file: "",
      batch_name: "",
      cluster_breaks: "2",
      cluster_radius: "0.5",
      notification_emails: "",
      bulkslide1: "",
      bulkslide2: "",
      bulkslide3: "",
      bulkslide4:'',
      bulkslidebulk:'',
      newValue: "",

      rowId: "",
      show: false,
      basicTitle: "",
      basicType: "",
      transitDate: "",
      transpoterGSTDate: "",
      unloadedDate: "",
      reachedDate: "",
      SectororCountry: "",
      location: "",
      additionalcharges:'',

      AustriaId1:"",
      ChinaId1:'',
      RepublicId1:'',

      ratesUpto:'',
      exworksCharges:'',
      plantname:'',
      transporterrate:"",
      additional_charges_cfs:0,
      additional_charges_cbm:0,
      additional_charges_shipment:0,
      usdvaluedisplay:'',
      eurovaluedisplay:'',
      Anamet_Europe_BV:0,
      Baker_Hughes_Digital_Solutions_GMBH:0,
      Kamax_Czech_Republic:0,
      Kamax_Spain:0,
      csvData: [],
            bulkMarkData: [],
            bulkslide: "",
            dispatch_time_from_hub:'',
            vehicle_type:'',
            vehicle_no:'',
            reporting_time_at_consignees:'',
            final_reporting_time:'',
            delivery_time:'',
            final_delivery_time:'',
            final_pod:'',
            selectedPOD:''

    };
    this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
  }

  componentDidMount() {
    // document.body.addEventListener("click", this.handleClickOutside);
    redirectURL
      .post("/consignments/lastmilehubconsigments")
      .then((response) => {
        console.log(response.data, "140----");
  this.setState({
    rowdata:response.data
  })
    
      })
      .catch((e) => {
        console.log(e);
      });
      loadDateTimeScript();
  }
  
  closeAlert = () => {
    this.setState({
      show: false,
    });
  };
//   updateValue1 = () => {
//     this.setState({
//       bulkslide1: "slider-translate-30p",
//       // rowId: rowId,
//     });
//   };



  
showBulkUpload() {
  this.setState({
      overly: "show-m",
      bulkslidebulk: "slider-translate"
  })
}
locationChangeHandler(e){
  console.log(e,'eeee')
  this.setState({
    selectedPOD : e.value
  })
    }
    onchangedates = (event,name) => {
      
      console.log('first',name)
console.log(event._d,'event','===',event)
this.setState({
  [name]:event._d
})

    }

OnsubmitEditData(event, field) {
    event.preventDefault();
    console.log(this.state.reporting_time_at_consignees,'==',this.state.final_reporting_time,'---',this.state.final_delivery_time)
  //  var dispatch_time_from_hub= this.state.dispatch_time_from_hub
  //  var vehicle_type= $("#vehicle_type").val()
  //  var vehicle_no= $("#vehicle_no").val()
  var  reporting_time_at_consignees= this.state.reporting_time_at_consignees
   var final_reporting_time= this.state.final_reporting_time
   var  final_delivery_time= this.state.final_delivery_time
   // var  delivery_time= this.state.delivery_time
  //  var final_pod= $("#final_pod").val()

      var params = {
        rowId : this.state.rowId,
        // dispatch_time_from_hub:dispatch_time_from_hub,
        // vehicle_type:vehicle_type,
        // vehicle_no:vehicle_no,
        reporting_time_at_consignees:reporting_time_at_consignees,
        final_reporting_time:final_reporting_time,
        // delivery_time:delivery_time,
        final_delivery_time:final_delivery_time,
        final_pod:this.state.selectedPOD
      };

      console.log(params, "params===");
if(params.reporting_time_at_consignees != "" && params.final_reporting_time != "" && params.final_delivery_time != "" && params.final_pod != ""){

  redirectURL
    .post("/consignments/editlastmilehubdata", params)
    .then((response) => {
      if (response.data.message == "success") {
        this.setState({

          loadshow: "show-n",
          overly: "show-n",
          bulkslide: "",
          show:true,
          basicTitle:"File Uploaded Successfully",
          basicType:'success'
         });
       
        this.componentDidMount();
      }
    })
    .catch((e) => {
      console.log(e);
    });
}else{
  alert("All fields are required!")
}
   
  }

 
  
  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      bulkslide: "",
      bulkslide2: "",
      bulkslide3: "",
      bulkslide4:'',
      bulkslidebulk:''
    });
  }

  // handleClickOutside = (event) => {
  //   if (!event.target.closest(".slide-r")) {
  //     this.onClickHideAll();
  //   }
  // };



resetUpload = () => {
  this.setState({
    bulkslidebulk: '',
      overly: 'show-n',
      file: '',
      bulkMarkData: [],
      bulkslide: "",
  })
  $('#upform')[0].reset();
  document.getElementById("upform").reset();
}

changeFileHandler = async (e) => {
  var dt = '';
  const config = {
      headers: [
          {
              name: 'Dispatch time from HUB',
              inputName: 'dispatch_time_from_hub',
              required: true,
              requiredError: function (headerName, rowNumber, columnNumber) {
                  return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
              }
          },
          {
            name: 'Vehicle Type',
            inputName: 'vehicle_type',
            required: true,
            requiredError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            }
        },
        {
          name: 'Vehicle No',
          inputName: 'vehicle_no',
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
          }
      },
          {
              name: 'Reporting time at consignees',
              inputName: 'reporting_time_at_consignees',
              required: false,
              requiredError: function (headerName, rowNumber, columnNumber) {
                  return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
              }
          },
          {
            name: 'Final Reporting Time',
            inputName: 'final_reporting_time',
            required: false,
            requiredError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            }
        },
        {
            name: 'Delivery time',
            inputName: 'delivery_time',
            required: false,
            requiredError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            }
        },
        {
            name: 'Final Delivery Time',
            inputName: 'final_delivery_time',
            required: false,
            requiredError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            }
        },
        {
            name: 'Final POD',
            inputName: 'final_pod',
            required: false,
            requiredError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            }
        },
      ]
  }

  var data = CSVFileValidator(e.target.files[0], config)
      .then((csvData) => {
          let csvList = csvData.data;
          var flag = 0;
       
          if (flag == 0) {
                  this.setState({
                      bulkMarkData: csvList
                  });
          }

      })
      .catch(err => {
          console.log(err, "error")
      })
  // console.log("e.target.files[0]",e.target.result);
  if (e.target.files[0] != undefined) {
      var out = new Promise(function (reject, resolve) {
          var reader = new FileReader();
          reader.onload = async function (e) {
              var contents = await e.target.result;
              resolve(contents);
          };
          var tt = reader.readAsText(e.target.files[0]);
      });
      this.setState({
          file: e.target.files[0]
      });
      // console.log("e.target.files[0].type ", e.target.files[0]);

      if (e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
          // console.log(fileData);
          // console.log(typeof(fileData))

      }
      else {
          e.target.value = null;
          this.setState({
              uploadFile: '',
              show: true,
              basicType: 'danger',
              basicTitle: 'Please upload file having extensions .csv only.',
          });
      }
  }

}

onCellClicked(e) {
  if (e.colDef.field === "Edit") {
  console.log(e.data,'dataonclicked')
  var data = e.data;
    this.setState({
      rowId : data._id,
      // dispatch_time_from_hub:(data.dispatch_time_from_hub),
      vehicle_type:data.vehicle_type,
      vehicle_no:data.vehicle_no,
      // reporting_time_at_consignees:(data.reporting_time_at_consignees),
      // final_reporting_time:(data.final_reporting_time),
      // delivery_time:(data.delivery_time),
      // final_delivery_time:(data.final_delivery_time),
      final_pod:(data.final_pod),
      bulkslide: "slider-translate-30p",
   
    });
  }
}

DateSelecter = ()=>{
  loadDateTimeScript()
  this.setState({
    // showLoginLogoutInputs: true,
    // flagForEdit:true,
    plannedDate: $("#planneddatetime").val()
  })
  
}
async uploadBulkFormHandler(e) {
  e.preventDefault();

  var fileData = this.state.bulkMarkData;

  fileData.forEach((item) => {
    if (item.dispatch_time_from_hub == "") {
      item.dispatch_time_from_hub = "";
    }
    console.log(item.reporting_time_at_consignees == '','type')
    if (item.reporting_time_at_consignees == "" ) {
      item.reporting_time_at_consignees = "";
    }
    if (item.final_reporting_time == "") {
      item.final_reporting_time = "";
    } 
    if (item.delivery_time == "") {
      item.delivery_time = "";
    } 
    if (item.final_delivery_time == "") {
      item.final_delivery_time = "";
    } 
    if (item.final_pod == "") {
      item.final_pod = "";
    }
  });
    if (fileData.length > 0) {
   console.log(fileData,'fileData')
        var reqParams = {
          csvData: fileData,
        }
        console.log(reqParams, 'paramsforbulk')

        this.setState({
          loadshow: 'show-m',
          overly: "show-m"
        });
      
        redirectURL.post("/consignments/bulkuploadlastmilehub", reqParams)
          .then((response) => {
            var resp = response.data.message;
            console.log('resp09876', response.data)

            if (response.data.message == "success") {
              this.setState({
                basicTitle1: resp,
                loadshow: 'show-n',
                overly: 'show-n',
                basicType1: "success",
                basicTitle: "Successfully Uploaded",
                basicType: "success",
                show1: true,
                show: true,
                bulkslidebulk: '',
                file: "",
                bulkMarkData: []
              })
            }

            this.componentDidMount()
            $('#upform')[0].reset();
          })
          .catch((e) => {
            console.log(e);
          });
    } 
    else {
      this.setState({
        basicTitle: "Invalid Data",
        basicType: "warning",
        show: true,
        file: "",
        bulkMarkData: []
      });
      $('#upform')[0].reset();
    }
};


handlerStartDateTime = (event, currentDate, selectedDate) => {
  var d = new Date(event._d);
  
 
  var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
 console.log(startdate,"startdate")

  // if(this.state.endDate !=undefined && this.state.endDate !="")
// {
//   // console.log(startdate,"startdate")
//   // console.log(this.state.endDate,"endDate")
//   var startDate = new Date(startdate);
//   var endDate = new Date(this.state.endDate);
//   var startTime = startDate.getTime();
//   var endTime = endDate.getTime();
//   // console.log(startTime,endTime);
//   if(startTime > endTime)
//   {
//     this.setState({
//       startDate:""
//     });
//     alert("To Date should be greater than From Date");
//   }
//   else
//   { 	
//     this.setState({
//       startDate:startdate
//     });
//   }
// }
// else
// {
//   this.setState({
//     startDate:startdate
//   });
// }
  //let value = event.target.value;
    //this.setState({'startDate':value});
}


  render() {
    const batchwisecolumns = [
        {
          pinned: "left",
          headerName: "",
          field: "Edit",
          width: 120,
          resizable: true,
          editable: false,
          cellRenderer: function (params) {
            var rendComponent =
              '<button class="btn btn-arms" id="buttonStyling" style="background-color: #00c292; color: white;">Edit</button>';
            return rendComponent;
          },
          filter: false,
        },
        {
          headerName:  'Vehicle Type',
          field: 'vehicle_type',
         with:150,  
        },
        {
          headerName:'Vehicle No',
          field: 'vehicle_no',
         with:150,
        },
        {
          headerName: 'Dispatch time from HUB',
          field: 'dispatch_time_from_hub',
          width:150,
          valueGetter: (param) => {
            if (param.data.dispatch_time_from_hub !== "") {
              return getHyphenDDMMMYYYYHHMM(param.data.dispatch_time_from_hub);
            } else {
              return "";
            }
          },
        },
        {
          headerName:  'Delivery time',
          field: 'delivery_time',
          valueGetter: (param) => {
            if (param.data.delivery_time !== "") {
              return getHyphenDDMMMYYYYHHMM(param.data.delivery_time);
            } else {
              return "";
            }
          },
         
        },
        {
          headerName: 'Reporting time at consignees',
          field: 'reporting_time_at_consignees',
          valueGetter: (param) => {
              if (param.data.reporting_time_at_consignees !== "") {
                return getHyphenDDMMMYYYYHHMM(param.data.reporting_time_at_consignees);
              } else {
                return "";
              }
            },
        },
        {
          headerName: 'Final Reporting Time',
          field: 'final_reporting_time',
          valueGetter: (param) => {
              if (param.data.final_reporting_time !== "") {
                return getHyphenDDMMMYYYYHHMM(param.data.final_reporting_time);
              } else {
                return "";
              }
            },
        },
       
        {
          headerName:  'Final Delivery Time',
          field: 'final_delivery_time',
          valueGetter: (param) => {
            if (param.data.final_delivery_time !== "") {
              return getHyphenDDMMMYYYYHHMM(param.data.final_delivery_time);
            } else {
              return "";
            }
          },
         
        },
        {
             headerName:  'Final POD',
          field: 'final_pod',
         with:150,
        },
      
    
      ];

    return (
      <div class="container-fluid">
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
           <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                        </div>
                        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                        <div className={"dataLoadpage " + (this.state.loadshow)}></div>
                    <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                        <div className="loader-box">
                            <div className="loader-box">
                                <div className="rotate dashed colored">
                                </div>
                            </div>
                        </div>
                    </div>
        <div className="card col-xl-12">
          <div className="card-header">
            <h5>
            <i className="icofont icofont-vehicle-delivery-van cus-i"></i>{" "}
              <span>Last Mile Hub Consigements</span>
              <button className="btn btn-info" style={{ float: "right", marginRight: "10px" }} onClick={this.showBulkUpload.bind(this)}>Bulk Upload</button>
              
              <div className="card-body">
                <div className="row">
                  <div
                    id="myGrid"
                    style={{ width: "100%", height: "478px" }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      modules={this.state.modules}
                      columnDefs={batchwisecolumns}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.rowdata}
                      enableCharts={false}
                      onGridReady={this.onGridReady}
                      onGridState={this.onGridState}
                      frameworkComponents={this.state.frameworkComponents}
                      rowClassRules={this.state.rowClassRules}
                      sideBar={this.state.sideBar}
                      stopEditingWhenGridLosesFocus={true}
                      onCellClicked={this.onCellClicked.bind(this)}
                      paginationPageSize={this.state.paginationPageSize}
                      pagination={true}
                      gridOptions={{
                        context: { componentParent: this },
                      }}
                      // onCellClicked={this.onCellRowClicked.bind(this)}
                      enableRangeSelection={true}
                    />
                  </div>
                </div>
              </div>
            </h5>
          </div>

          {/* work in progress Fresh Incoming rakes */}
        
        </div>
     <div className={"slide-r " + (this.state.bulkslidebulk)} style={{ overflow: "hidden" }}>
                            <h3 className="subH">Bulk Upload</h3>
                            <div className="slide-r-body" style={{ position: "relative" }}>
                                <div className="container-fluid">
                                    <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>

                                        <div className="form-group mt-20p">
                                            <label className="">Upload File</label>
                                            <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler} className="form-control" required />
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-success">Submit</button>
                                            <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                        </div>
                                        <p style={{ color: "red" }}>Note: Format should be same as given in sample template***</p>
                                    </form>
                                    <div className="form-group">
                                        <a className="btn btn-primary" href={require('../../assets/json/last_mile_sampledata.csv')} target="_blank">Sample Template</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
          className={"slide1-r " + this.state.bulkslide}
          style={{ overflow: "hidden" }}
        >
          <h3 className="subH">
            Edit Last Mile
            <span
              className="float-right fclose"
              style={{
                marginRight: "12px",
                padding: "1px 8px",
                marginTop: "-2px",
              }}
              onClick={this.onClickHideAll.bind(this)}
            >
              x
            </span>
          </h3>
         <div className="slide-r-body" style={{ position: "relative"}}>
            <div className="container-fluid">
              <form
                method="post"
                onSubmit={this.OnsubmitEditData.bind(this)}
                autocomplete="off"
                className="row col-xl-12 col-lg-12"
              >
               <div className="form-group col-xl-12 col-lg-12">
              <label className="col-xl-12 col-lg-12">
              Vehicle No:
                  </label>
                  <input
                    type="text"
                    name="vehicle_no"
                    value={this.state.vehicle_no}
                    id="vehicle_no"
                    className="form-control"
                    placeholder="Vehicle No"
                    readOnly
                  />
                   </div>
              <div className="form-group col-xl-12 col-lg-12">
              <label className="col-xl-12 col-lg-12">
              Vehicle Type:
                  </label>
                  <input
                    type="text"
                    name="vehicle_type"
                    value={this.state.vehicle_type}
                    id="vehicle_type"
                    className="form-control"
                    placeholder="Vehicle Type"
                    readOnly
                  />
                   </div>
                 
                   {/* <div className="form-group col-xl-12 col-lg-12">
            <label className="col-xl-12 col-lg-12">
                  Dispatch time from HUB:
                  </label>
                  <Datetime
                    // type="text"
                    disableCloseOnClickOutside={false} 
										closeOnSelect={true} 
                    name="dispatch_time_from_hub"
                    // value={this.state.dispatch_time_from_hub}
                    id="dispatch_time_from_hub"
                    // className="datetimepicker_datew form-control"
                    placeholder="Dispatch time from HUB"
                    onChange={(e) => this.onchangedates(e,'dispatch_time_from_hub')}
                  />

              </div> */}
                  
                <div className="form-group col-xl-12 col-lg-12">
                <label className="col-xl-12 col-lg-12">
                Reporting time at consignees:
                  </label>
                  <Datetime
                    // type="text"
                    disableCloseOnClickOutside={false} 
										closeOnSelect={true} 
                    name="reporting_time_at_consignees"
                    // value={this.state.reporting_time_at_consignees}
                    id="reporting_time_at_consignees"
                    // className="datetimepicker_datew form-control"
                    placeholder="Reporting time at consignees"
                    onChange={(e) => this.onchangedates(e,'reporting_time_at_consignees')}
                  />

                  </div>
                  <div className="form-group col-xl-12 col-lg-12">
                <label className="col-xl-12 col-lg-12">
                Final Reporting Time:
                  </label>
                  <Datetime
                    // type="text"
                    disableCloseOnClickOutside={false} 
										closeOnSelect={true} 
                    name="final_reporting_time"
                    // value={this.state.final_reporting_time}
                    id="final_reporting_time"
                    // className="datetimepicker_datew form-control"
                    placeholder="Final Reporting Time"
                    onChange={(e) => this.onchangedates(e,'final_reporting_time')}
                  />

                  </div>
                  {/* <div className="form-group col-xl-12 col-lg-12">
                <label className="col-xl-12 col-lg-12">
                Delivery time:
                  </label>
                  <Datetime
                    // type="text"
                    disableCloseOnClickOutside={false} 
										closeOnSelect={true} 
                    name="delivery_time"
                    value={this.state.delivery_time}
                    id="delivery_time"
                    // className="datetimepicker_datew form-control"
                    placeholder="Delivery time"
                    onChange={(e) => this.onchangedates(e,'delivery_time')}
                  />

                  </div> */}
                  <div className="form-group col-xl-12 col-lg-12">
                <label className="col-xl-12 col-lg-12">
                Final Delivery Time:
                  </label>
                  <Datetime
                    // type="text"
                    disableCloseOnClickOutside={false} 
										closeOnSelect={true} 
                    name="final_delivery_time"
                    // value={this.state.final_delivery_time}
                    id="final_delivery_time"
                    // className="datetimepicker_datew form-control"
                    placeholder="Final Delivery Time"
                    onChange={(e) => this.onchangedates(e,'final_delivery_time')}
                  />

                  </div>
                  {/* <div className="form-group col-xl-12 col-lg-12">
                <label className="col-xl-12 col-lg-12">
                Final POD:
                  </label>
                  <input
                    type="text"
                    name="Rates"
                    value={this.state.final_pod}
                    id="final_pod"
                    className="form-control"
                    placeholder="Final POD"
                  />

                  </div> */}
                    <div
                  className="form-group mt-20p col-xl-12 col-lg-12"
                  style={{ paddingLeft: "0px", paddingRight: "27px" }}
                >
                  <label className="col-xl-12 col-lg-12">
                  Final POD:{" "}
                  </label>
                 <Select
                    placeholder={"Final POD"}
                    closeMenuOnSelect={true}
                  
                    // value={this.state.container_name}
                    className={"col-xl-12 col-lg-12 border-radius-0"}
                    onChange={this.locationChangeHandler.bind(this)}
                    style={{ borderRadius: "0px" }}
                    // options={this.selectCountryOptionsItems()}
                    options={[
                      {
                        label: "Ok",
                        value: "Ok",
                      },
                      {
                        label: "Not Ok",
                        value: "Not Ok",
                      }
                   
                    ]}
                    
                    required
                  />
                </div>
                  <div className="form-group col-xl-3 col-lg-3">
                  <button type="submit" className="btn btn-success">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

       
      </div>
    );
  }
}


function DateEditor() {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
  // create the cell
  console.log(params, "params");
  this.eInput = document.createElement("input");
  this.eInput.value = params.value;

  // https://jqueryui.com/datepicker/
  $(this.eInput).datetimepicker({
    dateFormat: "yy-mm-dd",
    timepicker: false,
    changeMonth: true,
    changeYear: true,
  });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
  return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
  this.eInput.focus();
  this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
  return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
  // but this example is simple, no cleanup, we could
  // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
  // and we could leave this method out also, false is the default
  return false;
};

function loadDateTimeScript() {
  // alert("timeDate");
  $(".datetimepicker_datew").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y H:i",
    minDate: true,
    timepicker: true,
    closeOnDateSelect: true,
    scrollMonth: false,
    scrollInput: false,
    // onChangeDateTime: function onchangedates (e) {
    //  console.log("Selected date and time:",e);
    // }
  });
  $(".datetimepicker_dateo").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y",
    minDate: true,
    timepicker: false,
    closeOnDateSelect: true,
    scrollMonth: false,
    scrollInput: false,
  });
  $(".cargo_date").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y",
    minDate: true,
    timepicker: false,
  });
}
function formatDate(dateString) {
  if(dateString != "Invalid Date" && dateString != "" && dateString != "NaN-NaN-NaN" && dateString != undefined && dateString != 'undefined' ){
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
  }
  else {
    return ""
  }
}
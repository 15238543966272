
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { getDDMMYYYY, getDDMMYYYYHHMMSS } from '../common/utils'; 
var redirectURL = require('../redirectURL');
var moment = require('moment'); 

 
const ExportsHighSeasAction = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Hish seas ",props);
        props.context.componentParent.onShowConsignmentExportsEventsClick(props);
    };
    
    if(props.data.sea_transit_delay == 1)
    {
        var colr = 'redfont'
    }
    else{
       /* var g1 = getDDMMYYYY(props.data.msil_current_transit_eta);
        var g2 = getDDMMYYYY(props.data.msil_initial_transit_eta);
        var s1 = g1.split("-");
        var s2 = g2.split("-");
        var gdate1 = new Date(s1[2],s1[1], s1[0]);
        var gdate2 = new Date(s2[2],s2[1], s2[0]);
        //console.log("gdate1 ", gdate1.getTime())
        //console.log(g1);
        //console.log("gdate2 ", gdate2.getTime())
        //console.log(g2);
        
        if(gdate1.getTime() > gdate2.getTime())
        {
            var colr =  'redfont'
        }
        else{
            var colr =  'bluefont'
        }
        */
       if(props.data.status == 8)
       {
           if(typeof props.data.revised_etd != 'undefined' && typeof props.data.expected_etd != 'undefined')
           {
                
                var revise = props.data.revised_etd;
                var rev = revise.split("T");
                var revisedDate = rev[0];

                var expectd = props.data.expected_etd;
                var exdate = expectd.split("T");
                var expectedDate = exdate[0];

                let revisedDateOnlyMoment  = moment.parseZone(revisedDate).format("x");
                let expectedDateOnlyMoment  = moment.parseZone(expectedDate).format("x");
                //hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'); 
                // console.log("revisedDateOnlyMoment= ", revisedDateOnlyMoment); 
                // console.log("expectedDateOnlyMoment= ", expectedDateOnlyMoment);
                if(revisedDateOnlyMoment > expectedDateOnlyMoment)
                {
                    var colr = 'redfont'
                }
                else{
                       
                    var colr = 'yellowfont'
                   
                }
           }
           else{
                if(typeof props.data.pol_atd != 'undefined' && typeof props.data.expected_port_out != 'undefined')
                {
                    
                    var revise = props.data.pol_atd;
                    var rev = revise.split("T");
                    var revisedDate = rev[0];
    
                    var expectd = props.data.expected_port_out;
                    var exdate = expectd.split("T");
                    var expectedDate = exdate[0];
    
                    let revisedDateOnlyMoment  = moment.parseZone(revisedDate).format("x");
                    let expectedDateOnlyMoment  = moment.parseZone(expectedDate).format("x");
                    //hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'); 
                    // console.log("revisedDateOnlyMoment= ", revisedDateOnlyMoment); 
                    // console.log("expectedDateOnlyMoment= ", expectedDateOnlyMoment);
                    if(revisedDateOnlyMoment > expectedDateOnlyMoment)
                    {
                        var colr = 'redfont'
                    }
                    else{
                        
                            var colr = 'yellowfont'
                        
                    }
                }
                else{
                    var colr = 'yellowfont'
                }
           }
            
       }
       else{
            if(typeof props.data.revised_etd != 'undefined' && typeof props.data.expected_etd != 'undefined')
            {
                
                var revise = props.data.revised_etd;
                var rev = revise.split("T");
                var revisedDate = rev[0];

                var expectd = props.data.expected_etd;
                var exdate = expectd.split("T");
                var expectedDate = exdate[0];

                let revisedDateOnlyMoment  = moment.parseZone(revisedDate).format("x");
                let expectedDateOnlyMoment  = moment.parseZone(expectedDate).format("x");
                //hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'); 
                // console.log("revisedDateOnlyMoment= ", revisedDateOnlyMoment); 
                // console.log("expectedDateOnlyMoment= ", expectedDateOnlyMoment);
                if(revisedDateOnlyMoment > expectedDateOnlyMoment)
                {
                    var colr = 'redfont'
                }
                else{
                    if(props.data.status > 8)
                    {   
                        var colr = 'greenfont'
                    }
                    else{
                        var colr = 'grayfont'
                    }
                }
            }
            else{
                if(typeof props.data.pol_atd != 'undefined' && typeof props.data.expected_port_out != 'undefined')
                {
                    
                    var revise = props.data.pol_atd;
                    var rev = revise.split("T");
                    var revisedDate = rev[0];
    
                    var expectd = props.data.expected_port_out;
                    var exdate = expectd.split("T");
                    var expectedDate = exdate[0];
    
                    let revisedDateOnlyMoment  = moment.parseZone(revisedDate).format("x");
                    let expectedDateOnlyMoment  = moment.parseZone(expectedDate).format("x");
                    //hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'); 
                    // console.log("revisedDateOnlyMoment= ", revisedDateOnlyMoment); 
                    // console.log("expectedDateOnlyMoment= ", expectedDateOnlyMoment);
                    if(revisedDateOnlyMoment > expectedDateOnlyMoment)
                    {
                        var colr = 'redfont'
                    }
                    else{
                        if(props.data.status > 8)
                        {   
                            var colr = 'greenfont'
                        }
                        else{
                            var colr = 'grayfont'
                        }
                    }
                }
                else{
                    var colr = 'grayfont'
                }
            }
       }
        
    }
    return (
        <div>
            <button onClick={handleEventClick} className="custom-btn f22 label label-success" 
            title="High Seas" ><i className={"icofont icofont-ship-alt "+(colr)}></i> &nbsp;</button>
            
        </div>
    );
};

export default ExportsHighSeasAction;

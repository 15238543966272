
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');


const SaveWagonRow = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.formHandler(props.data);
    };

    return (
        <div>
			<button onClick={handleClick} className="custom-btn label label-success"><i className="icofont icofont-save"></i> Save </button>
       
        </div>
    );
};

export default SaveWagonRow;

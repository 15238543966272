
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const ImportsEmptyContainerAction = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onShowConsignmentImportsEventsClick(props);
    };
    

    return (
        <div>
            <button onClick={handleEventClick} className="custom-btn f22 label label-success" 
            title="Empty Return" ><i className="icofont icofont-vehicle-delivery-van grayfont"></i> &nbsp;</button>
            
        </div>
    );
};

export default ImportsEmptyContainerAction;

import React from 'react'

// Icon imports
const FavouriteFilled = require("../../../assets/icons/favourites_list_icon.svg");

const FavoritesListIcon = ({setSlideInTable}) => {

    const toggleFavoritesList = () => {
        setSlideInTable("favourites_list");
    }

    return (
        <button
            onClick={() => toggleFavoritesList()}
            style={{
                border: "none",
                backgroundColor: "transparent",
                cursor: "pointer",
                outline: "none",
                padding: "0",
            }}
        >
            <img
                src={FavouriteFilled}
                alt=""
                width={"22px"}
            />
        </button>
    )
}

export default FavoritesListIcon
import React, { Component } from 'react';
// import { useState } from 'react';

// import ReactDOM from 'react-dom';
// import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import $ from "jquery";
// import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
// import { scaleQuantile } from 'd3-scale';
// import ReactTooltip from 'react-tooltip';
import Select from "react-select";
// import LinearGradient from './LinearGradient';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
// import MarkerClusterer from "@google/markerclusterer";
import Map from './mapLocationComponent';

import redirectURL from '../redirectURL';
// import {
//     Accordion,
//     AccordionItem,
//     AccordionItemHeading,
//     AccordionItemButton,
//     AccordionItemPanel,
// } from 'react-accessible-accordion';
// import MapComponent from './mapLocationComponent';
var globForecastDay="Today";
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=5;
var coordinates=[];
var map;
var marker;
var markers = [];
var circle;
var moment = require('moment');
// const INDIA_TOPO_JSON = require('../india.topo.json');

//         const PROJECTION_CONFIG = {
//           scale: 350,
//           center: [78.9629, 22.5937] // always in [East Latitude, North Longitude]
//         };
        
//         // Red Variants
//         const COLOR_RANGE = [
//           '#ffedea',
//           '#ffcec5',
//           '#ffad9f',
//           '#ff8a75',
//           '#ff5533',
//           '#e2492d',
//           '#be3d26',
//           '#9a311f',
//           '#782618'
//         ];
        
//         const DEFAULT_COLOR = '#EEE';
        
//         const getRandomInt = () => {
//           return parseInt(Math.random() * 100);
//         };
        
//         const geographyStyle = {
//           default: {
//             outline: 'none'
//           },
//           hover: {
//             fill: '#ccc',
//             transition: 'all 250ms',
//             outline: 'none'
//           },
//           pressed: {
//             outline: 'none'
//           },
//         };
        
//         // will generate random heatmap data on every call
//         const getHeatMapData = () => {
//           return [
//             { id: 'AP', state: 'Andhra Pradesh', value: getRandomInt() },
//             { id: 'AR', state: 'Arunachal Pradesh', value: getRandomInt() },
//             { id: 'AS', state: 'Assam', value: getRandomInt() },
//             { id: 'BR', state: 'Bihar', value: getRandomInt() },
//             { id: 'CT', state: 'Chhattisgarh', value: getRandomInt() },
//             { id: 'GA', state: 'Goa', value: 21 },
//             { id: 'GJ', state: 'Gujarat', value: 22 },
//             { id: 'HR', state: 'Haryana', value: getRandomInt() },
//             { id: 'HP', state: 'Himachal Pradesh', value: 24 },
//             { id: 'JH', state: 'Jharkhand', value: 26 },
//             { id: 'KA', state: 'Karnataka', value: 27 },
//             { id: 'KL', state: 'Kerala', value: getRandomInt() },
//             { id: 'MP', state: 'Madhya Pradesh', value: getRandomInt() },
//             { id: 'MH', state: 'Maharashtra', value: getRandomInt() },
//             { id: 'MN', state: 'Manipur', value: getRandomInt() },
//             { id: 'ML', state: 'Meghalaya', value: 59 },
//             { id: 'MZ', state: 'Mizoram', value: getRandomInt() },
//             { id: 'NL', state: 'Nagaland', value: 59 },
//             { id: 'OR', state: 'Odisha', value: 59 },
//             { id: 'PB', state: 'Punjab', value: getRandomInt() },
//             { id: 'RJ', state: 'Rajasthan', value: getRandomInt() },
//             { id: 'SK', state: 'Sikkim', value: getRandomInt() },
//             { id: 'TN', state: 'Tamil Nadu', value: getRandomInt() },
//             { id: 'TG', state: 'Telangana', value: getRandomInt() },
//             { id: 'TR', state: 'Tripura', value: 14 },
//             { id: 'UT', state: 'Uttarakhand', value: getRandomInt() },
//             { id: 'UP', state: 'Uttar Pradesh', value: 15 },
//             { id: 'WB', state: 'West Bengal', value: 17 },
//             { id: 'WB', state: 'West Bengal', value: 17 },
//             { id: 'AN', state: 'Andaman and Nicobar Islands', value: getRandomInt() },
//             { id: 'CH', state: 'Chandigarh', value: getRandomInt() },
//             { id: 'DN', state: 'Dadra and Nagar Haveli', value: 19 },
//             { id: 'DD', state: 'Daman and Diu', value: 20 },
//             { id: 'DL', state: 'Delhi', value: 59 },
//             { id: 'JK', state: 'Jammu and Kashmir', value: 25 },
//             { id: 'LA', state: 'Ladakh', value: getRandomInt() },
//             { id: 'LD', state: 'Lakshadweep', value: getRandomInt() },
//             { id: 'PY', state: 'Puducherry', value: getRandomInt() }
//           ];
//         };
        


// export default className WeatherMapComponent extends Component {

//     constructor(props) {
//         super(props);
//         this.state={
//             modules: AllModules,
//             truckData:this.props.truckInfo,
//             consignmentlists:this.props.consignments,
//             defaultColDef: {
//                 sortable: true,
//                 filter: true,
//                 editable: true,
//             },
//             rowData: null,
//             stateName:"States",
// 			animateRows: true,

//         };
//     }

//     render()
//     {
        
        
        
//           var tooltipContent="State";
//           var setTooltipContent="";
//           const data = getHeatMapData();
//           const setData = getHeatMapData();
//         // console.log(data);
//           const gradientData = {
//             fromColor: COLOR_RANGE[0],
//             toColor: COLOR_RANGE[COLOR_RANGE.length - 1],
//             min: 0,
//             max: data.reduce((max, item) => (item.value > max ? item.value : max), 0)
//           };
        
//           const colorScale = scaleQuantile()
//             .domain(data.map(d => d.value))
//             .range(COLOR_RANGE);
        
//           const onMouseEnter = (geo, current = { value: 'NA' }) => {
//             return () => {
                
//                 var stateName =geo.properties.name;
//                 this.setState({
//                     stateName : stateName
//                 })
//                 // alert(stateName)

//             };
//           };
        
//           const onMouseLeave = () => {
//             tooltipContent = "";
//           };
        
//           const onChangeButtonClick = () => {
//             setData(getHeatMapData());
//           };
        
       
        
//         return(
//             <div classNameName="full-width-height container">
//       {/* <h1 classNameName="no-margin center">States and UTs</h1> */}
//       <ReactTooltip>{this.state.stateName}</ReactTooltip>
//         <ComposableMap
//           projectionConfig={PROJECTION_CONFIG}
//           projection="geoMercator"
//           width={325}
//           height={232}
//           data-tip=""
//         >
//           <Geographies geography={INDIA_TOPO_JSON}>
//             {({ geographies }) =>
//               geographies.map(geo => {
//                 //console.log(geo.id);
//                 const current = data.find(s => s.id === geo.id);
//                 return (
//                   <Geography
//                     key={geo.rsmKey}
//                     geography={geo}
//                     fill={current ? colorScale(current.value) : DEFAULT_COLOR}
//                     style={geographyStyle}
//                     onMouseEnter={onMouseEnter(geo, current)}
//                     onMouseLeave={onMouseLeave}
//                   />
//                 );
//               })
//             }
//           </Geographies>
//         </ComposableMap>
//         <LinearGradient data={gradientData} />
//         <div classNameName="center">
//           {/* <button classNameName="mt16" onClick={onChangeButtonClick}>Change</button> */}
//         </div>
//     </div>
//         )
//     }
// }


export default class WeatherMap extends Component {

      constructor(props) {
          super(props);
          this.state={
            weatherData : "",
            forecastDay : "",
            forecastOptions : [],
            weatherPlants : [],
            rowData : [],
            modules: AllModules,
            path : "",
            statesSubDivisions: [],
            hoveredState: "",
            weatherCondition: "",
            detailedWeatherMap: 0,
            forecastTypeClass: 0,
            overly:"show-n",
            loadshow: "show-n",
            last_updated_on: "",
            states_last_updated_on: "",
            district_last_updated_on: "",
            startAlert: "NA",
            endAlert: "NA",
            floodData: [],
            floodMarkers: [],
            satelliteImage:""
          };
      }
  
      async componentDidMount(){
        this.setState({
          overly: "show-m",
          loadshow: "show-m"
        })
        await redirectURL.post("/weather/getWeatherData").then((response)=>{
          console.log(response.data);
          const uniqueDAta = [...new Set(response.data.ForecastData.map(item => item.description))];
          console.log(response.data.ForecastData.length,"uniqueDAta")
          
          this.setState({
            weatherData : response.data.ForecastData,
            statesSubDivisions: response.data.statesSubDivisions
          })
          redirectURL.post("/weather/getPlants").then((response)=>{
            
            var path = window.location.href.split("//")[1].split("/")[0];
            
            this.setState({
              weatherPlants : response.data,
              forecastDay : "Today",
              path : path
            })
            this.renderMapWeather();
          })
          
        }).catch(function(error){
          console.log(error);
        })

        await redirectURL.post("/weather/getFloodData").then((response)=>{
            console.log(response.data);
            this.setState({
              floodData : response.data,
            })
        }).catch(function(error){
            console.log(error);
        })
        await redirectURL.post("/weather/getsatelliteimage").then((response)=>{
            console.log(response.data,"satelliteimage");
            this.setState({
              satelliteImage : response.data.cyclone_pdf_link,
            })
        }).catch(function(error){
            console.log(error);
        })
        // this.renderMap() 
        
      }
      // componentWillReceiveProps(newProps){
      //   this.setState({
      //     weatherData : newProps.weatherData,
      //     forecastDay : newProps.forecastDay
      //   })
      // }

      renderMapWeather = () => {    	
        loadScriptWeather("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMapWeather");
        window.initMapWeather = this.initMapWeather
       }
    
       initMapWeather = () => {
         this.setState({
          overly: "show-n",
          loadshow: "show-n"
         })
        try
          {
            //console.log("here slidebar")
            // $("#mapweather").empty();
            //var coordsvalues;
            var map_type = window.google.maps.MapTypeId.HYBRID;
            if(this.state.detailedWeatherMap == 1 || this.state.detailedWeatherMap == 2)
            {
              var map_type = window.google.maps.MapTypeId.ROADMAP;
            }
            var map = new window.google.maps.Map(document.getElementById('mapweather'), {
              zoom: 8,
              center: new window.google.maps.LatLng(28.497236,77.062363),
              mapTypeId: map_type,
              mapTypeControl: true,
              mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_RIGHT
              },            
              disableDefaultUI: true,
              fullscreenControl: false,
              zoomControl: true,
              panControl: false,
              
            });
            
            var filterRecDate = "";

            if(globForecastDay == "Today")
            {
              filterRecDate = moment.parseZone(new Date()).format("DD-MM-YYYY");
            }
            else
            {
              filterRecDate = moment.parseZone(new Date(+new Date() + 86400000)).format("DD-MM-YYYY");
            }

            // First Map Weather Plants Icons
            if(this.state.weatherPlants.length > 0 && this.state.detailedWeatherMap == 0){
              this.state.weatherPlants.map(function(params){
                var plant = {
                  url: require("../../assets/icons/"+params.icon), // url
                  scaledSize: new window.google.maps.Size(32,32), // scaled size
                  origin: new window.google.maps.Point(0,0), // origin
                  anchor: new window.google.maps.Point(0, 0) // anchor
                };
                var coords = JSON.parse(params.coordinates);
                console.log(coords);
                var marker = new window.google.maps.Marker({
                  position: new window.google.maps.LatLng(coords.lat,coords.lng),
                  map: map,
                  icon: plant,
                  title : params.plant_name
                });
                

                var cityCircle = new window.google.maps.Circle({
                  strokeColor: '#9fc9b9',
                  strokeOpacity: 0.3,
                  strokeWeight: 2,
                  fillColor: '#9fc9b9',
                  fillOpacity: 0.6,
                  map: map,
                  center:coords,
                  radius: 50 * 1000
                });
              })
            }
            // var iconObject = {"heavyrain":heavyrain,"Sandstorm":Sandstorm,"cyclone":cyclone,"wind":wind,"hailstorm":hailstorm,"thunderstorm":thunderstorm}
            var bounds = new window.google.maps.LatLngBounds();
            
            // new LatLng(23.63936, 68.14712), new LatLng(28.20453, 97.34466)

            bounds.extend(new window.google.maps.LatLng(23.63936, 68.14712));
            bounds.extend(new window.google.maps.LatLng(28.20453, 97.34466));
            // First Map
            if(this.state.weatherData.length > 0 && this.state.detailedWeatherMap == 0)
            {
              
              var filterRecords =  this.state.weatherData.filter(function(params){
                return moment.parseZone(new Date(params.forecast_date)).format("DD-MM-YYYY") == filterRecDate && params.description != "NA"
              })
              
              var district_last_updated_on = "";
              if(filterRecords.length > 0)
              {
                district_last_updated_on = filterRecords[0]['last_updated_on']
                this.setState({
                  last_updated_on: district_last_updated_on
                })
                filterRecords.map(function(params)
                {            
                  if(params.address.includes("NA") == false && params.address != "/" && params.description.includes("No warning") == false)
                  {
                    bounds.extend(new window.google.maps.LatLng(params.latitude,params.longitude));
                    var url="";
                    var iconName = "";
                    if(params.description.includes("Thunderstorm") == true){
                      url = require("../../assets/icons/thunderstorm.png")
                    }                    
                    else if(params.description.includes("Heavy Rain and Strong Winds") == true){
                      url = require("../../assets/icons/wind.png")
                    }
                    else if(params.description.includes("Heavy Rain") == true){
                      url = require("../../assets/icons/Heavyrain.png")
                    }
                    else if(params.description.includes("Cyclone") == true){
                      url = require("../../assets/icons/cyclone.png")
                    }
                    else if(params.description.includes("Hailstroms") == true){
                      url = require("../../assets/icons/hailstorm.png")
                    }
                    else if(params.description.includes("Sandstorm") == true || params.description.includes("Duststorm") == true){
                      url = require("../../assets/icons/sandstorm.png")
                    }
                    else
                    {
                      
                    }
                    console.log("weatherIcon", url )
                    var iconName = {
                      url: url, // url
                      scaledSize: new window.google.maps.Size(32, 32), // scaled size
                      origin: new window.google.maps.Point(0,0), // origin
                      anchor: new window.google.maps.Point(0, 0) // anchor
                    };
                    var marker = new window.google.maps.Marker({
                      position: new window.google.maps.LatLng(params.latitude,params.longitude),
                      map: map,
                      icon: iconName,
                      // title : ""+params.address+","+params.forecast_date+","+params.description
                    });
                    var infowindow = new window.google.maps.InfoWindow({
                      content: "<div class='weather-marker-tooltip'><div class='forecast-date'><label class='forecast-labels'> Forecast Date : </label> "+moment.parseZone(new Date(params.forecast_date)).format("DD-MMM-YYYY")+"</div><div class='address'> <label class='forecast-labels'>Address :</label> "+params.address+"</div><div class='weather-desc'><label class='forecast-labels'> Weather : </label> "+params.description+"</div><div class='last-updated'><label class='forecast-labels'> Last Updated On : </label> "+getHyphenDDMMMYYYYHHMM(params.last_updated_on)+"</div>"
                      });
                    window.google.maps.event.addListener(marker, 'mouseover', function() {
                      infowindow.open(map,marker);
                    });
                    window.google.maps.event.addListener(marker, 'mouseout', function() {
                      infowindow.close();
                    });
                  }
                // }
                })
                $("#mapweather").css("visibility","visible");
                $(".forecast-warning").css("display","none");
              }
              else
              {
                //$("#mapweather").css("visibility","hidden");
                $(".forecast-warning").css("display","block");
              }
              map.addListener('zoom_changed', function() {
                mapZoom=map.getZoom();
              });
            }
            // Second Map
            else if(this.state.detailedWeatherMap == 1)
            {
              var infowindows = [];
              this.state.statesSubDivisions.map((e, index)=>{
                var geofence_coordinates = JSON.parse(e.geofence_coordinates);
                // var optimizedCoords = [geofence_coordinates[0]];
                // var i = 2;
                // while(i < geofence_coordinates.length - 1)
                // {
                //   optimizedCoords.push(geofence_coordinates[i])
                //   // bounds.extend(new window.google.maps.LatLng(geofence_coordinates[i].lat,geofence_coordinates[i].lng));
                //   i = i + 1
                // }
                // optimizedCoords.push(geofence_coordinates[geofence_coordinates.length-1])
                var hex_color = '#000';
                try{
                  
                  if(globForecastDay == "Today")
                  {
                    var filterRecDate = moment.parseZone(new Date()).format("DD-MM-YYYY");
                    var forecast_info =  e.forecast_info.filter(function(params){
                      return moment.parseZone(new Date(params.forecast_date)).format("DD-MM-YYYY") == filterRecDate
                    })
                  }
                  else
                  {
                    var filterRecDate = moment.parseZone(new Date(+new Date() + 86400000)).format("DD-MM-YYYY");
                    var forecast_info =  e.forecast_info.filter(function(params){
                      return moment.parseZone(new Date(params.forecast_date)).format("DD-MM-YYYY") == filterRecDate;
                    })
                  }
                }
                catch(e){
                  var forecast_info = [{}]
                }
                var states_last_updated_on = "";
                if(forecast_info.length)
                {
                  states_last_updated_on = forecast_info[0]['last_updated_on']
                  hex_color =  forecast_info[0]['color']
                }
                this.setState({
                  last_updated_on: states_last_updated_on
                })
                var statePoly = new window.google.maps.Polygon({
                    paths: JSON.parse(e.geofence_coordinates),
                    editable: false,
                    strokeColor: "green",
                    strokeOpacity: 0.70,
                    strokeWeight: 1,
                    fillColor: hex_color,
                    fillOpacity: 1,
                    draggable:false,
                    draggingCursor:null,
                    
                });
                var weatherCondition = forecast_info[0].weather_condition;
                function iconUrl(i){
                  if(i.includes("rain"))
                      {
                        url = require("../../assets/icons/mausam - heavy rain.png")
                      }
                      else if(i.includes("cold day"))
                      {
                         url = require("../../assets/icons/mausam - cold day.png")
                      }
                      else if(i.includes("cold wave"))
                      {
                         url = require("../../assets/icons/mausam - cold wave.png")
                      }
                      else if(i.includes("dust raising"))
                      {
                        url = require("../../assets/icons/mausam - dust raising winds.png")
                      }
                      else if(i.includes("dust storm"))
                      {
                        url = require("../../assets/icons/mausam - dust storm.png")
                      }
                      else if(i.includes("fog"))
                      {
                        url = require("../../assets/icons/mausam - fog.png")
                      }
                      else if(i.includes("froast"))
                      {
                        url = require("../../assets/icons/mausam - ground froast.png")
                      }
                      else if(i.includes("hail"))
                      {
                        url = require("../../assets/icons/mausam - hailstorm.png")
                      }
                      else if(i.includes("heat wave"))
                      {
                        url = require("../../assets/icons/mausam - heat wave.png")
                      }
                      else if(i.includes("snow"))
                      {
                        url = require("../../assets/icons/mausam - heavy snow.png")
                      }
                      else if(i.includes("hot"))
                      {
                        url = require("../../assets/icons/mausam - hot day.png")
                      }
                      else if(i.includes("surface"))
                      {
                        url = require("../../assets/icons/mausam - strong surface winds.png")
                      }
                      else if(i.includes("thunderstorm"))
                      {
                        url = require("../../assets/icons/mausam - thunderstorm.png")
                      }
                      else if(i.includes("night"))
                      {
                        url = require("../../assets/icons/mausam - warm night.png")
                      }
                    return url
                }
                if(weatherCondition != undefined)
                {
                  weatherCondition = weatherCondition.toLowerCase();
                  var url="";
                  let wc = weatherCondition.split(",")
                  if (wc.length >1){
                    var lat = e.centroid_location.lat;
                    var lng = e.centroid_location.lng;
                    wc.map((i, index)=>{
                      url = iconUrl(i)
                      console.log("url",i, url, e)       

                      var iconName = {
                        url: url, // url
                        scaledSize: new window.google.maps.Size(32, 32), // scaled size
                        origin: new window.google.maps.Point(0,0), // origin
                        anchor: new window.google.maps.Point(0, 0) // anchor
                      };
                      
                      if(index > 0)
                      {
                        lat = lat + 0.2;
                        lng = lng + 0.2
                      }
                      let marker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng(lat, lng),
                        map: map,
                        icon: iconName
                      });

                    })                     
                  }
                  else{
                    url = iconUrl(weatherCondition)
                    var iconName = {
                      url: url, // url
                      scaledSize: new window.google.maps.Size(32, 32), // scaled size
                      origin: new window.google.maps.Point(0,0), // origin
                      anchor: new window.google.maps.Point(0, 0) // anchor
                    };
                    var marker = new window.google.maps.Marker({
                      position: new window.google.maps.LatLng(e.centroid_location.lat,e.centroid_location.lng),
                      map: map,
                      icon: iconName
                    });
                  }
                }
                infowindows[index] = new window.google.maps.InfoWindow({
                  content: e.geofence_name
                });
                var startAlert = "NA";
                var endAlert = "NA";
                if(e.alert_info != undefined && e.active_alert == 1)
                {
                  startAlert = getHyphenYYYYMMDDHHMMSS(e.alert_info.start_alert);
                  endAlert = getHyphenYYYYMMDDHHMMSS(e.alert_info.end_alert);
                }
                window.google.maps.event.addListener(statePoly, 'mouseover', ()=> {
                  console.log("clicked")
                  infowindows[index].open(map,statePoly);
                  this.setState({
                    hoveredState: e.geofence_name,
                    weatherCondition: forecast_info[0].weather_condition,
                    startAlert: startAlert,
                    endAlert: endAlert
                  })
                });
                  window.google.maps.event.addListener(statePoly, 'mouseout', ()=> {
                    infowindows[index].close();
                    this.setState({
                      hoveredState: "",
                      weatherCondition: "",
                      startAlert: "NA",
                      endAlert:"NA"
                    })
                });
                statePoly.setMap(map)

              })
            }
            // Third Map
            else if(this.state.detailedWeatherMap == 2)
            {
                var floodData = this.state.floodData;
                var floodMarkers = [];
                floodData.map((params)=>
                { 
                  //console.log(params)
                  var iconClr = "";        
                  if(params.current_status == "NORMAL")
                  {
                    iconClr = require("../../assets/icons/green-dot.png");
                  }
                  else if(params.current_status == "INFLOW")
                  {
                    iconClr = require("../../assets/icons/blue-dot.png");
                    //console.log(params.current_status,params.latitude,params.longitude)
                  }
                  else if(params.current_status == "WARNING")
                  {
                    iconClr = require("../../assets/icons/yellow-dot.png");
                  }
                  else if(params.current_status == "EXTREME")
                  {
                    iconClr = require("../../assets/icons/black-circle.png");
                  }
                  else
                  {
                    iconClr = require("../../assets/icons/red-dot.png");
                  }
                  bounds.extend(new window.google.maps.LatLng(params.latitude,params.longitude));
                  
                  var iconName = {
                    url: iconClr, // url
                    scaledSize: new window.google.maps.Size(10, 10), // scaled size
                    origin: new window.google.maps.Point(0,0), // origin
                    anchor: new window.google.maps.Point(0, 0) // anchor
                  };
                
                  var marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(params.latitude,params.longitude),
                    map: map,
                    icon: iconName,
                    // title : ""+params.address+","+params.forecast_date+","+params.description
                  });
                  floodMarkers.push({
                    "flood_marker": marker,
                    "alert": params.current_status,
                    "url": iconClr
                  })
                  var infowindow = new window.google.maps.InfoWindow({
                    content: "<div class='weather-marker-tooltip'><div class='address'> <label class='forecast-labels'>Flood Station :</label> "+params.station+"</div><div class='river'> <label class='forecast-labels'>River :</label> "+params.river+"</div><div class='river'> <label class='forecast-labels'>Division :</label> "+params.division+"</div><div class='weather-desc'><label class='forecast-labels'> Alert : </label> "+params.current_status+"</div>"
                  });
                  window.google.maps.event.addListener(marker, 'mouseover', function() {
                    infowindow.open(map,marker);
                  });
                  window.google.maps.event.addListener(marker, 'mouseout', function() {
                    infowindow.close();
                  });
                }) 
                map.addListener('zoom_changed', function() {
                  mapZoom=map.getZoom();
                });  
                this.setState({
                  floodMarkers: floodMarkers
                })            
            }
            map.fitBounds(bounds)

          }catch(e){}
          
        }
      
        forecastOption(params){
          // let forecastOptions = [];
          this.state.forecastOptions=[];
          this.state.forecastOptions.push({value:"Today",label:"Today"});
          this.state.forecastOptions.push({value:"Tomorrow",label:"Tomorrow"})
          return this.state.forecastOptions;
        }
      changeForecastData = (event) =>{
        this.setState({
          forecastDay : event.value,
          overly: "show-m",
          loadshow: "show-m"
        });
        globForecastDay = event.value;
        // console.log(event.value);
        this.renderMapWeather()
          // this.initMapWeather()
      }  
      onChangeForecastType(e){
        if(e == 2)
        {
          this.setState({
            overly: "show-m",
            loadshow: "show-m"
          });
            redirectURL.post("/weather/getFloodData").then((response)=>{
                console.log(response.data);
                this.setState({
                  floodData : response.data,
                  overly: "show-n",
                  loadshow: "show-n"
                })
            }).catch(function(error){
                console.log(error);
            })
        }
        this.setState({
          detailedWeatherMap: e,
          forecastTypeClass: e,
          floodWeatherMap: e,
          overly: "show-m",
          loadshow: "show-m"
        })
        console.log(e, "onchange")
        this.renderMapWeather()
      }
      onCloseRouteDiv = (event) => {
        this.props.context.onCloseRouteDiv();
      }
      ShowImage()
      {
      }
      onClickHideAll(){
        this.setState({
          overly: "show-n",
          loadshow: "show-n"
        })
      }
      changeFloodStatus(floodStatus){
        var floodMarkers = this.state.floodMarkers;
        if(floodStatus == 0)
        {
          floodMarkers.map((e)=>{
            e.flood_marker.setVisible(true);
          })
        }
        else if(floodStatus == 1){
          floodMarkers.map((e)=>{
            if (e.alert == "NORMAL")
            {
              e.flood_marker.setVisible(true);
            }
            else 
            {
              e.flood_marker.setVisible(false);
            }
          })
        }
        else if(floodStatus == 2)
        {
          floodMarkers.map((e)=>{
            if (e.alert == "INFLOW")
            {
              e.flood_marker.setVisible(true);
            }
            else 
            {
              e.flood_marker.setVisible(false);
            }
          })
        }
        else if(floodStatus == 3)
        {
          floodMarkers.map((e)=>{
            if (e.alert == "WARNING")
            {
              e.flood_marker.setVisible(true);
            }
            else 
            {
              e.flood_marker.setVisible(false);
            }
          })
        }
        else if(floodStatus == 4)
        {
          floodMarkers.map((e)=>{
            if (e.alert == "DANGER")
            {
              e.flood_marker.setVisible(true);
            }
            else 
            {
              e.flood_marker.setVisible(false);
            }
          })
        }
        
        else if(floodStatus == 5)
        {
          floodMarkers.map((e)=>{
            if (e.alert == "EXTREME")
            {
              e.flood_marker.setVisible(true);
            }
            else 
            {
              e.flood_marker.setVisible(false);
            }
          })
        }
      }
      render(){
        console.log(this.state.satelliteImage,"889")
        const columnwithDefs = [
          {
            headerName : "Plant Name",
            field : "plant_name",
            resizable : true,
            hide:false,
            width : 80
          },
          {
            headerName : "Forecast Today",
            field : "weather_today",
            resizable : true,
            width : 110
          },
          {
            headerName : "Forecast Tomorrow",
            field : "weather_tomorrow",
            resizable : true,
            width : 110
          }
        ]
        const dStyles={
          width:'65%',
          height:'58em',
          // marginTop : "20px"
        }
        const dStyles1={
          width:'100%',
          height:'58em',
          // marginTop : "20px"
        }
        const forecastOption = [
          { value: "Today", label: "Today" },
          { value: "Tomorrow", label: "Tomorrow" },
        ];
        const colourStyles = {
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);
            console.log("stlel");
            console.log({ data, isDisabled, isFocused, isSelected });
            return {
              ...styles,
              backgroundColor: isFocused ? "blue" : null,
              color: isFocused?"white":"black",
            };
          }
        };
        var mgmtweathermap = ""
        try{
          mgmtweathermap = (this.props.match.path == "/mgmtweathermap")?"Mobile": "";
        }
        catch(e){
          
        }
        return(
          <div style={{width:"100%",height:"100%"}}>
            {(mgmtweathermap != "Mobile")?
                <div className="slide-r slider-translate-60p weather-sidebar" style={{overflow:"auto"}}>
                  <div className="slide-r-title row col-xl-12 col-lg-12" style={{background:"#21759d",color:"#fff"}}>
                      <div className="col-sm-3 col-xs-3 col-md-3"></div>
                      <div className="col-sm-3 col-xs-3 col-md-3" style={{textAlign: "right"}}>
                        <label className="Forecast-Header" style={{paddingTop: "9px"}}>Weather Forecast For</label>
                      </div>
                      <div className="col-sm-3 col-xs-3 col-md-3" style={{paddingLeft:"0px"}}>
                          <Select
                            defaultValue={forecastOption[0]}
                            // placeholder="Today"
                            onChange={this.changeForecastData.bind(this)} 
                            closeMenuOnSelect={true}
                            options={forecastOption}
                            styles={colourStyles}
                          />
                      </div>
                      <div class="col-xl-2"></div>
                      <div class="col-xl-1">
                      <span className="float-right closebtn" style={{marginTop: "4px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
                      </div>
                  </div> 
                  <div className="slide-r-body" style={{position:"relative"}}>
                    <div className="button-group" style={{float:"left", width: "68%"}}>
                      <button className={(this.state.forecastTypeClass == 0)?"btn btn-secondary":"btn btn-default"} onClick={this.onChangeForecastType.bind(this, 0)}>District Wise Forecast</button>
                      <button className={(this.state.forecastTypeClass == 3)?"btn btn-secondary":"btn btn-default"} onClick={this.onChangeForecastType.bind(this, 3)}>Satellite Image</button>
                      <button className={(this.state.forecastTypeClass == 1)?"btn btn-secondary":"btn btn-default"} onClick={this.onChangeForecastType.bind(this, 1)}>Sub Division Wise Forecast</button>
                      <button className={(this.state.forecastTypeClass == 2)?"btn btn-secondary":"btn btn-default"} onClick={this.onChangeForecastType.bind(this, 2)}>Flood Stations</button>
                    </div>
                    {(this.state.detailedWeatherMap == 0 || this.state.detailedWeatherMap == 1)?
                    <span>
                      <h6>Last updated on: {getHyphenYYYYMMDDHHMMSS(this.state.last_updated_on)}</h6>
                    </span>
                    :""}
                    {(this.state.detailedWeatherMap == 1)?
                      <div className="row" style={{position: "absolute", top: "9.46em", left: "37em",lineHeight:"16px",
                                     fontWeight:"bold", zIndex: "100", background: "white", height: "7em",
                                    width: "19em", boxShadow: "1px 1px 7px 2px grey"}}
                      >
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12" style={{paddingTop:"1em", fontSize:"13px"}}>State : {this.state.hoveredState} </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12" style={{fontSize:"13px"}}>Forecast : {this.state.weatherCondition} </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12" style={{fontSize:"13px"}}>Start Alert: {this.state.startAlert}</div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12" style={{fontSize:"13px"}}>End Alert: {this.state.endAlert}</div>
                      </div>
                    :""}
                    {(this.state.detailedWeatherMap == 0 || this.state.detailedWeatherMap == 1)?
                    <div class="col-xl-12 col-sm-12 col-xs-12 col-md-12 row wdiv">
                          <div id="mapweather" className="col-sm-8 col-xs-12 col-md-8 two" style={dStyles}></div>
                          {(this.state.weatherPlants != '')?
                            <div className="col-sm-4 col-xs-12 col-md-4 ag-theme-balham one">
                              
                              {(this.state.detailedWeatherMap != 1)?
                                <div className="col-sm-12 col-xs-12 col-md-12 row">
                                  <div className="col-sm-12 col-xs-12 col-md-12" id="weathermapgrid" style={{width:"100%",height:"350px"}}>
                                    <AgGridReact
                                      modules={this.state.modules}
                                      columnDefs={columnwithDefs}
                                      defaultColDef={this.state.defaultColDef}
                                      rowData={this.state.weatherPlants}
                                      enableRangeSelection={true}
                                      onGridReady={this.onGridReady}
                                      onGridState={this.onGridState}
                                      paginationPageSize={this.state.paginationPageSize}
                                      pagination={true}
        
                                    />
                                  </div>
                                  <div className="col-sm-12 col-xs-12 col-md-12 forecast-warning three">
                                    <span className="error-message"><i class="icofont icofont-exclamation-square"></i> No Forecast Information Available</span>
                                  </div>
                                  <div className="col-sm-12 col-xs-12 col-md-12 map-legends row three" style={{ marginTop: "10px",marginLeft: "10px"}}>
                                    {/* <div className="col-xl-12 col-lg-12"> */}
                                      <div className="col-sm-4 col-xs-4 col-md-4">
                                        <img src={require('../../assets/icons/Heavyrain.png')}></img> <figcaption>Heavy Rain</figcaption>
                                      </div>
                                      <div className="col-sm-4 col-xs-4 col-md-4">
                                        <img src={require('../../assets/icons/wind.png')}></img>&nbsp;&nbsp; <figcaption>Heavy Rain & Winds</figcaption>
                                      </div>
                                      <div className="col-sm-4 col-xs-4 col-md-4">
                                        <img src={require('../../assets/icons/thunderstorm.png')}></img><figcaption> Thunderstorm </figcaption>
                                      </div>
                                    {/* </div> */}
                                  </div>
                                  <div className="col-sm-12 col-xs-12 col-md-12 map-legends row three" style={{ marginTop: "10px",marginLeft: "10px"}}>
                                    {/* <div className="col-xl-6"> */}
                                      <div className="col-sm-4 col-xs-4 col-md-4">
                                        <img src={require('../../assets/icons/hailstorm.png')}></img>&nbsp;<figcaption> Hailstorm </figcaption>
                                      </div>
                                      <div className="col-sm-4 col-xs-4 col-md-4">
                                        <img src={require("../../assets/icons/cyclone.png")}></img>&nbsp;&nbsp; <figcaption>Cyclone</figcaption>
                                      </div>
                                      <div className="col-sm-4 col-xs-4 col-md-4">
                                        <img src={require('../../assets/icons/sandstorm.png')}></img><figcaption> Sandstorm </figcaption>
                                      </div>
                                    {/* </div> */}
                                  </div>
                                  <div className="col-sm-12 col-xs-12 col-md-12 three" style={{marginTop:"35px"}}>
                                    <a href={this.state.satelliteImage} target="_blank" onClick={this.ShowImage.bind(this)} style={{color:"red"}}><h6><i className="icofont icofont-link-alt"></i> Click here for IMD Cyclone Information</h6></a>
                                  </div>
                                </div>
                              :
                              <div className="col-sm-12 col-xs-12 col-md-12 row">
                                <div className="col-sm-12 col-xs-12 col-md-12 map-legends row three" style={{ marginTop: "10px",marginLeft: "10px"}}>
                                  {/* <div className="col-xl-12 col-lg-12"> */}
                                    <div className="col-sm-4 col-xs-4 col-md-4">
                                      <img src={require('../../assets/icons/No warning.png')}></img> <figcaption>No Warning</figcaption>
                                    </div>
                                    <div className="col-sm-4 col-xs-4 col-md-4">
                                      <img src={require('../../assets/icons/alert.png')}></img>&nbsp;&nbsp; <figcaption>Alert</figcaption>
                                    </div>
                                    <div className="col-sm-4 col-xs-4 col-md-4">
                                      <img src={require('../../assets/icons/watch.png')}></img><figcaption> Watch </figcaption>
                                    </div>
                                  {/* </div> */}
                                </div>
                                <div className="col-sm-12 col-xs-12 col-md-12 map-legends row three" style={{display:"flex", marginTop: "10px",marginLeft: "10px"}}>
                                  {/* <div className="col-xl-12 col-lg-12"> */}
                                  <div className="col-sm-4 col-xs-4 col-md-4">
                                      <img src={require('../../assets/icons/warning.png')}></img><figcaption> Warning </figcaption>
                                    </div>
                                    <div className="col-sm-4 col-xs-4 col-md-4">
                                      <img src={require('../../assets/icons/mausam - cold wave.png')}></img>&nbsp;&nbsp; <figcaption>Cold Wave</figcaption>
                                    </div>
                                    <div className="col-sm-4 col-xs-4 col-md-4">
                                      <img src={require('../../assets/icons/mausam - dust raising winds.png')}></img><figcaption> Dust Winds </figcaption>
                                    </div>
                                  {/* </div> */}
                                </div>
                                <div className="col-sm-12 col-xs-12 col-md-12 map-legends row three" style={{ marginTop: "10px",marginLeft: "10px"}}>
                                  {/* <div className="col-xl-6"> */}
                                    <div className="col-sm-4 col-xs-4 col-md-4">
                                      <img src={require('../../assets/icons/mausam - dust storm.png')}></img>&nbsp;<figcaption> dust Storm </figcaption>
                                    </div>
                                    <div className="col-sm-4 col-xs-4 col-md-4">
                                      <img src={require("../../assets/icons/mausam - fog.png")}></img>&nbsp;&nbsp; <figcaption>Fog</figcaption>
                                    </div>
                                    <div className="col-sm-4 col-xs-4 col-md-4">
                                      <img src={require('../../assets/icons/mausam - ground froast.png')}></img><figcaption> Ground Froast </figcaption>
                                    </div>
                                  {/* </div> */}
                                </div>
                                <div className="col-sm-12 col-xs-12 col-md-12 map-legends row three" style={{ marginTop: "10px",marginLeft: "10px"}}>
                                  {/* <div className="col-xl-12 col-lg-12"> */}
                                    <div className="col-sm-4 col-xs-4 col-md-4">
                                      <img src={require('../../assets/icons/mausam - hailstorm.png')}></img> <figcaption>Hailstorm</figcaption>
                                    </div>
                                    <div className="col-sm-4 col-xs-4 col-md-4">
                                      <img src={require('../../assets/icons/mausam - heat wave.png')}></img>&nbsp;&nbsp; <figcaption>Heat wave</figcaption>
                                    </div>
                                    <div className="col-sm-4 col-xs-4 col-md-4">
                                      <img src={require('../../assets/icons/mausam - heavy rain.png')}></img><figcaption> Heavy rain </figcaption>
                                    </div>
                                  {/* </div> */}
                                </div>
                                <div className="col-sm-12 col-xs-12 col-md-12 map-legends row three" style={{ marginTop: "10px",marginLeft: "10px"}}>
                                  {/* <div className="col-xl-6"> */}
                                    <div className="col-sm-4 col-xs-4 col-md-4">
                                      <img src={require('../../assets/icons/mausam - heavy snow.png')}></img>&nbsp;<figcaption> Heavy snow </figcaption>
                                    </div>
                                    <div className="col-sm-4 col-xs-4 col-md-4">
                                      <img src={require("../../assets/icons/mausam - hot day.png")}></img>&nbsp;&nbsp; <figcaption>Hot day</figcaption>
                                    </div>
                                    <div className="col-sm-4 col-xs-4 col-md-4">
                                      <img src={require('../../assets/icons/mausam - strong surface winds.png')}></img><figcaption> Strong surface winds </figcaption>
                                    </div>
                                  {/* </div> */}
                                </div>
                                <div className="col-sm-12 col-xs-12 col-md-12 map-legends row three" style={{ marginTop: "10px",marginLeft: "10px"}}>
                                  {/* <div className="col-xl-6"> */}
                                    <div className="col-sm-4 col-xs-4 col-md-4">
                                      <img src={require('../../assets/icons/mausam - thunderstorm.png')}></img>&nbsp;<figcaption> Thunderstorm </figcaption>
                                    </div>
                                    <div className="col-sm-4 col-xs-4 col-md-4">
                                      <img src={require("../../assets/icons/mausam - warm night.png")}></img>&nbsp;&nbsp; <figcaption>Warm Night</figcaption>
                                    </div>
                                    <div className="col-sm-4 col-xs-4 col-md-4">
                                      <img src={require('../../assets/icons/mausam - cold day.png')}></img> <figcaption>Cold Day</figcaption>
                                    </div>
                                  {/* </div> */}
                                </div>
                              </div>
                              }
                          </div>
                            :""}
                          
                          </div>
                        :""}
                        {(this.state.detailedWeatherMap == 2)?
                        <div class="col-xl-12 col-sm-12 col-xs-12 col-md-12 row wdiv">
                            <div className="col-sm-12 col-xs-12 col-md-12 row one" style={{margin:"1% 0",padding:"0"}}>
                              <div className="col-sm-1 col-xs-1 col-md-1" onClick={this.changeFloodStatus.bind(this, 0)} style={{cursor:"pointer"}}>
                                  <div className="col-sm-12 col-xs-12 col-md-12 row" style={{padding:"0"}}>
                                      <div className="col-sm-12 col-xs-12 col-md-12">
                                        <figcaption> ALL </figcaption>
                                      </div>
                                  </div>
                                </div>
                                <div className="col-sm-2 col-xs-2 col-md-2" onClick={this.changeFloodStatus.bind(this, 1)} style={{cursor:"pointer"}}>
                                  <div className="col-sm-12 col-xs-12 col-md-12 row" style={{padding:"0"}}>
                                      <div className="col-sm-2 col-xs-2 col-md-2">
                                        <img src={require('../../assets/icons/green-dot.png')}></img>
                                      </div>
                                      <div className="col-sm-8 col-xs-8 col-md-8">
                                        <figcaption> NORMAL </figcaption>
                                      </div>
                                  </div>
                                </div>
                                <div className="col-sm-2 col-xs-2 col-md-2" onClick={this.changeFloodStatus.bind(this, 2)} style={{cursor:"pointer"}}>
                                  <div className="col-sm-12 col-xs-12 col-md-12 row">
                                      <div className="col-sm-2 col-xs-2 col-md-2">
                                        <img src={require('../../assets/icons/blue-dot.png')}></img>
                                      </div>
                                      <div className="col-sm-8 col-xs-8 col-md-8">
                                        <figcaption> INFLOW </figcaption>
                                      </div>
                                  </div>
                                </div>
                                <div className="col-sm-2 col-xs-2 col-md-2" onClick={this.changeFloodStatus.bind(this, 3)} style={{cursor:"pointer"}}>
                                  <div className="col-sm-12 col-xs-12 col-md-12 row">
                                      <div className="col-sm-2 col-xs-2 col-md-2">
                                        <img src={require('../../assets/icons/yellow-dot.png')}></img>
                                      </div>
                                      <div className="col-sm-8 col-xs-8 col-md-8">
                                        <figcaption> WARNING </figcaption>
                                      </div>
                                  </div>
                                </div>
                                <div className="col-sm-2 col-xs-2 col-md-2" onClick={this.changeFloodStatus.bind(this, 4)} style={{cursor:"pointer"}}>
                                  <div className="col-sm-12 col-xs-12 col-md-12 row">
                                      <div className="col-sm-2 col-xs-2 col-md-2">
                                        <img src={require('../../assets/icons/red-dot.png')}></img>
                                      </div>
                                      <div className="col-sm-8 col-xs-8 col-md-8">
                                        <figcaption> DANGER </figcaption>
                                      </div>
                                  </div>
                                </div>
                                <div className="col-sm-2 col-xs-2 col-md-2" onClick={this.changeFloodStatus.bind(this, 5)} style={{cursor:"pointer"}}>
                                  <div className="col-sm-12 col-xs-12 col-md-12 row">
                                      <div className="col-sm-2 col-xs-2 col-md-2">
                                        <img src={require('../../assets/icons/black-circle.png')}></img>
                                      </div>
                                      <div className="col-sm-8 col-xs-8 col-md-8">
                                        <figcaption> EXTREME </figcaption>
                                      </div>
                                  </div>
                                </div>
                                
                            </div>
                            <div id="mapweather" className="col-sm-12 col-xs-12 col-md-12 two" style={dStyles1}></div>
                            
                          </div>
                        :""}
                            {(this.state.detailedWeatherMap == 3)?
                    <div class="col-xl-12 col-sm-12 col-xs-12 col-md-12 row wdiv">
                          {/* <div id="mapweather" className="col-sm-8 col-xs-12 col-md-8 two" style={dStyles}></div> */}
                          <div>
                            <img src="http://autometrics.in/ml/fetchCycloneSatelliteImage" style={{height:"600px",width:"770px"}}/>
                            </div>
                          {(this.state.weatherPlants != '')?
                            <div className="col-sm-4 col-xs-12 col-md-4 ag-theme-balham one">
                              
                                <div className="col-sm-12 col-xs-12 col-md-12 row">
                                  <div className="col-sm-12 col-xs-12 col-md-12" id="weathermapgrid" style={{width:"100%",height:"350px"}}>
                                    <AgGridReact
                                      modules={this.state.modules}
                                      columnDefs={columnwithDefs}
                                      defaultColDef={this.state.defaultColDef}
                                      rowData={this.state.weatherPlants}
                                      enableRangeSelection={true}
                                      onGridReady={this.onGridReady}
                                      onGridState={this.onGridState}
                                      paginationPageSize={this.state.paginationPageSize}
                                      pagination={true}
        
                                    />
                                  </div>
                                  <div className="col-sm-12 col-xs-12 col-md-12 forecast-warning three">
                                    <span className="error-message"><i class="icofont icofont-exclamation-square"></i> No Forecast Information Available</span>
                                  </div>
                                  <div className="col-sm-12 col-xs-12 col-md-12 map-legends row three" style={{ marginTop: "10px",marginLeft: "10px"}}>
                                    {/* <div className="col-xl-12 col-lg-12"> */}
                                      <div className="col-sm-4 col-xs-4 col-md-4">
                                        <img src={require('../../assets/icons/Heavyrain.png')}></img> <figcaption>Heavy Rain</figcaption>
                                      </div>
                                      <div className="col-sm-4 col-xs-4 col-md-4">
                                        <img src={require('../../assets/icons/wind.png')}></img>&nbsp;&nbsp; <figcaption>Heavy Rain & Winds</figcaption>
                                      </div>
                                      <div className="col-sm-4 col-xs-4 col-md-4">
                                        <img src={require('../../assets/icons/thunderstorm.png')}></img><figcaption> Thunderstorm </figcaption>
                                      </div>
                                    {/* </div> */}
                                  </div>
                                  <div className="col-sm-12 col-xs-12 col-md-12 map-legends row three" style={{ marginTop: "10px",marginLeft: "10px"}}>
                                    {/* <div className="col-xl-6"> */}
                                      <div className="col-sm-4 col-xs-4 col-md-4">
                                        <img src={require('../../assets/icons/hailstorm.png')}></img>&nbsp;<figcaption> Hailstorm </figcaption>
                                      </div>
                                      <div className="col-sm-4 col-xs-4 col-md-4">
                                        <img src={require("../../assets/icons/cyclone.png")}></img>&nbsp;&nbsp; <figcaption>Cyclone</figcaption>
                                      </div>
                                      <div className="col-sm-4 col-xs-4 col-md-4">
                                        <img src={require('../../assets/icons/sandstorm.png')}></img><figcaption> Sandstorm </figcaption>
                                      </div>
                                    {/* </div> */}
                                  </div>
                                  <div className="col-sm-12 col-xs-12 col-md-12 three" style={{marginTop:"35px"}}>
                                    <a href={this.state.satelliteImage} target="_blank" onClick={this.ShowImage.bind(this)} style={{color:"red"}}><h6><i className="icofont icofont-link-alt"></i> Click here for IMD Cyclone Information</h6></a>
                                  </div>
                                </div>
                         
                          </div>
                            :""}
                          
                          </div>
                        :""}
                    </div>
                  </div>
            :
              /* MOBILE VIEW */
              <div className="col-xl-12 col-lg-12 card" style={{marginTop:"2em"}}>
                <div className="row" style={{marginBottom:"1em", marginTop:"1em"}}>
                    <div className="col-sm-2 col-xs-3 col-md-2"></div>
                    <div className="col-sm-3 col-xs-3 col-md-3" style={{textAlign: "right"}}>
                      <label className="Forecast-Header" style={{paddingTop: "9px"}}>Weather Forecast For</label>
                    </div>
                    <div className="col-sm-3 col-xs-3 col-md-3" style={{paddingLeft:"0px"}}>
                        <Select
                          defaultValue={forecastOption[0]}
                          // placeholder="Today"
                          onChange={this.changeForecastData.bind(this)} 
                          closeMenuOnSelect={true}
                          options={forecastOption}
                          styles={colourStyles}
                        />
                    </div>
                </div>
                <div class="col-xl-12 col-sm-12 col-xs-12 col-md-12 row wdiv">
                    <div id="mapweather" className="col-sm-8 col-xs-12 col-md-8 two" style={dStyles}></div>
                        {(this.state.weatherPlants != '')?
                          <div className="col-sm-4 col-xs-12 col-md-4 ag-theme-balham one">
                            <div className="col-sm-12 col-xs-12 col-md-12" id="weathermapgrid" style={{width:"100%",height:"350px"}}>
                              <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.weatherPlants}
                                enableRangeSelection={true}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                // rowSelection={this.state.rowSelection}
                                // frameworkComponents={this.state.frameworkComponents}
                                // detailCellRendererParams={this.state.detailCellRendererParams}
                                //editType={this.state.editType}
                                // stopEditingWhenGridLosesFocus={true}
                                //floatingFilter={true}
                                // enableCellChangeFlash={true}
                                // suppressCellFlash={true}
                                // rowClassRules={this.state.rowClassRules}
                                //onCellClicked={this.onRowClicked.bind(this)}
                                // gridOptions={{
                                //     context: { componentParent: this }
                                // }}
                                // onRowSelected={this.onRowSelection.bind(this)}
                                // suppressRowClickSelection={true}
  
                              />
                            </div>
                            {(this.state.detailedWeatherMap != 1)?
                            <div className="col-sm-12 col-xs-12 col-md-12 row">
                              <div className="col-sm-12 col-xs-12 col-md-12 forecast-warning three">
                                <span className="error-message"><i class="icofont icofont-exclamation-square"></i> No Forecast Information Available</span>
                              </div>
                              <div className="col-sm-12 col-xs-12 col-md-12 map-legends row three" style={{ marginTop: "10px",marginLeft: "10px"}}>
                                {/* <div className="col-xl-12 col-lg-12"> */}
                                  <div className="col-sm-4 col-xs-4 col-md-4">
                                    <img src={require('../../assets/icons/Heavyrain.png')}></img> <figcaption>Heavy Rain</figcaption>
                                  </div>
                                  <div className="col-sm-4 col-xs-4 col-md-4">
                                    <img src={require('../../assets/icons/wind.png')}></img>&nbsp;&nbsp; <figcaption>Heavy Rain & Winds</figcaption>
                                  </div>
                                  <div className="col-sm-4 col-xs-4 col-md-4">
                                    <img src={require('../../assets/icons/thunderstorm.png')}></img><figcaption> Thunderstorm </figcaption>
                                  </div>
                                {/* </div> */}
                              </div>
                              <div className="col-sm-12 col-xs-12 col-md-12 map-legends row three" style={{ marginTop: "10px",marginLeft: "10px"}}>
                                {/* <div className="col-xl-6"> */}
                                  <div className="col-sm-4 col-xs-4 col-md-4">
                                    <img src={require('../../assets/icons/hailstorm.png')}></img>&nbsp;<figcaption> Hailstorm </figcaption>
                                  </div>
                                  <div className="col-sm-4 col-xs-4 col-md-4">
                                    <img src={require("../../assets/icons/cyclone.png")}></img>&nbsp;&nbsp; <figcaption>Cyclone</figcaption>
                                  </div>
                                  <div className="col-sm-4 col-xs-4 col-md-4">
                                    <img src={require('../../assets/icons/sandstorm.png')}></img><figcaption> Sandstorm </figcaption>
                                  </div>
                                {/* </div> */}
                              </div>
                              <div className="col-sm-12 col-xs-12 col-md-12 three" style={{marginTop:"35px"}}>
                                <a href={"http://3.6.65.252/get_cyclone_report"} target="_blank" onClick={this.ShowImage.bind(this)} style={{color:"red"}}><h6><i className="icofont icofont-link-alt"></i> Click here for IMD Cyclone Information</h6></a>
                              </div>
                            </div>
                            :
                            <div className="col-sm-12 col-xs-12 col-md-12 row">
                              <div className="col-sm-12 col-xs-12 col-md-12 map-legends row three" style={{ marginTop: "10px",marginLeft: "10px"}}>
                                {/* <div className="col-xl-12 col-lg-12"> */}
                                  <div className="col-sm-4 col-xs-4 col-md-4">
                                    <img src={require('../../assets/icons/mausam - cold day.png')}></img> <figcaption>Heavy Rain</figcaption>
                                  </div>
                                  <div className="col-sm-4 col-xs-4 col-md-4">
                                    <img src={require('../../assets/icons/mausam - cold wave.png')}></img>&nbsp;&nbsp; <figcaption>Heavy Rain & Winds</figcaption>
                                  </div>
                                  <div className="col-sm-4 col-xs-4 col-md-4">
                                    <img src={require('../../assets/icons/mausam - dust raising winds.png')}></img><figcaption> Thunderstorm </figcaption>
                                  </div>
                                {/* </div> */}
                              </div>
                              <div className="col-sm-12 col-xs-12 col-md-12 map-legends row three" style={{ marginTop: "10px",marginLeft: "10px"}}>
                                {/* <div className="col-xl-6"> */}
                                  <div className="col-sm-4 col-xs-4 col-md-4">
                                    <img src={require('../../assets/icons/mausam - dust storm.png')}></img>&nbsp;<figcaption> Hailstorm </figcaption>
                                  </div>
                                  <div className="col-sm-4 col-xs-4 col-md-4">
                                    <img src={require("../../assets/icons/mausam - fog.png")}></img>&nbsp;&nbsp; <figcaption>Cyclone</figcaption>
                                  </div>
                                  <div className="col-sm-4 col-xs-4 col-md-4">
                                    <img src={require('../../assets/icons/mausam - ground froast.png')}></img><figcaption> Sandstorm </figcaption>
                                  </div>
                                {/* </div> */}
                              </div>
                              <div className="col-sm-12 col-xs-12 col-md-12 map-legends row three" style={{ marginTop: "10px",marginLeft: "10px"}}>
                                {/* <div className="col-xl-12 col-lg-12"> */}
                                  <div className="col-sm-4 col-xs-4 col-md-4">
                                    <img src={require('../../assets/icons/mausam - hailstorm.png')}></img> <figcaption>Heavy Rain</figcaption>
                                  </div>
                                  <div className="col-sm-4 col-xs-4 col-md-4">
                                    <img src={require('../../assets/icons/mausam - heat wave.png')}></img>&nbsp;&nbsp; <figcaption>Heavy Rain & Winds</figcaption>
                                  </div>
                                  <div className="col-sm-4 col-xs-4 col-md-4">
                                    <img src={require('../../assets/icons/mausam - heavy rain.png')}></img><figcaption> Thunderstorm </figcaption>
                                  </div>
                                {/* </div> */}
                              </div>
                              <div className="col-sm-12 col-xs-12 col-md-12 map-legends row three" style={{ marginTop: "10px",marginLeft: "10px"}}>
                                {/* <div className="col-xl-6"> */}
                                  <div className="col-sm-4 col-xs-4 col-md-4">
                                    <img src={require('../../assets/icons/mausam - heavy snow.png')}></img>&nbsp;<figcaption> Hailstorm </figcaption>
                                  </div>
                                  <div className="col-sm-4 col-xs-4 col-md-4">
                                    <img src={require("../../assets/icons/mausam - hot day.png")}></img>&nbsp;&nbsp; <figcaption>Cyclone</figcaption>
                                  </div>
                                  <div className="col-sm-4 col-xs-4 col-md-4">
                                    <img src={require('../../assets/icons/mausam - strong surface winds.png')}></img><figcaption> Sandstorm </figcaption>
                                  </div>
                                {/* </div> */}
                              </div>
                              <div className="col-sm-12 col-xs-12 col-md-12 map-legends row three" style={{ marginTop: "10px",marginLeft: "10px"}}>
                                {/* <div className="col-xl-6"> */}
                                  <div className="col-sm-4 col-xs-4 col-md-4">
                                    <img src={require('../../assets/icons/mausam - thunderstorm.png')}></img>&nbsp;<figcaption> Hailstorm </figcaption>
                                  </div>
                                  <div className="col-sm-4 col-xs-4 col-md-4">
                                    <img src={require("../../assets/icons/mausam - warm night.png")}></img>&nbsp;&nbsp; <figcaption>Cyclone</figcaption>
                                  </div>
                                {/* </div> */}
                              </div>
                            </div>
                            }
                            
                          </div>
                          :""}
                                                
                    </div>
                
                </div>

            }
            <div className={"overlay-part "+(this.state.overly)} style={{zIndex: "1000"}} onClick={this.onClickHideAll.bind(this)}></div>
            <div className={"dataLoadpage " +(this.state.loadshow)}></div>
            <div className={"dataLoadpageimg " +(this.state.loadshow)} style={{top: "21em"}}>
              <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            </div>
        )
      }
    }
          
function loadScript(url){
      var index  =window.document.getElementsByTagName("script")[0]
      var script = window.document.createElement("script")
      script.src=url
      script.async=true
      script.defer = true
      index.parentNode.insertBefore(script,index)
  }

  function timeConvert(n) {
    var num = n;
    var hours = (num / (3600));
    var rhours = Math.floor(hours);
    var minutes = (n) / (60);
    var rminutes = Math.round(minutes);
    return rhours + " hour(s) and " + rminutes + " minute(s).";
  }                              
  var currentinfowindow = null;
  function getTimeInfo(marker,timedet,coords)
  {
    var timeinfo = new window.google.maps.InfoWindow({
            content: "Time at this location: " + timedet
          });
  
    marker.addListener('click', function() {
      
          if (currentinfowindow != null) {
            currentinfowindow.close();
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
          }
          else
          {
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
          }
          
          });
  }
  
  
   function clearSelection() {
        if (selectedShape) {
          selectedShape.setEditable(false);
          selectedShape = null;
        }
      }
  
     function setSelection(shape) {
        clearSelection();
        selectedShape = shape;
        //console.log("selectedShape",selectedShape);
        shape.setEditable(true);
        
        selectColor(shape.get('fillColor') || shape.get('strokeColor'));
      }
  
    function deleteSelectedShape() {
      fencingLength=0;  
        if (selectedShape) {
          selectedShape.setMap(null);
        }
        // To show:
         drawingManager.setOptions({
           drawingControl: true
         });
      }
  
      
     function selectColor(color) {
          selectedColor = color;
          for (var i = 0; i < colors.length; ++i) {
            var currColor = colors[i];
            //console.log(colorButtons[currColor]);
            //colorButtons[currColor].style.border = currColor == color ? '1px solid #789' : '1px solid #fff';
          }
  
          // Retrieves the current options from the drawing manager and replaces the
          // stroke or fill color as appropriate.
          var polylineOptions = drawingManager.get('polylineOptions');
          polylineOptions.strokeColor = color;
          drawingManager.set('polylineOptions', polylineOptions);
  
          var rectangleOptions = drawingManager.get('rectangleOptions');
          rectangleOptions.fillColor = color;
          drawingManager.set('rectangleOptions', rectangleOptions);
  
          var circleOptions = drawingManager.get('circleOptions');
          circleOptions.fillColor = color;
          drawingManager.set('circleOptions', circleOptions);
  
          var polygonOptions = drawingManager.get('polygonOptions');
          polygonOptions.fillColor = color;
          drawingManager.set('polygonOptions', polygonOptions);
        }
  
    function setSelectedShapeColor(color) {
          if (selectedShape) {
            if (selectedShape.type == window.google.maps.drawing.OverlayType.POLYLINE) {
              selectedShape.set('strokeColor', color);
            } else {
              selectedShape.set('fillColor', color);
            }
          }
        }
  
      function makeColorButton(color) {
          var button = document.createElement('span');
          button.classNameName = 'color-button';
          button.style.backgroundColor = color;
          window.google.maps.event.addDomListener(button, 'click', function() {
            selectColor(color);
            setSelectedShapeColor(color);
          });
  
          return button;
        }
  
      function buildColorPalette() {
           var colorPalette = document.getElementById('color-palette');
           for (var i = 0; i < colors.length; ++i) {
             var currColor = colors[i];
             var colorButton = makeColorButton(currColor);
             colorPalette.appendChild(colorButton);
             colorButtons[currColor] = colorButton;
           }
           selectColor(colors[0]);
         }  
      
     function getSelectedShape(coords){
      var lt=[]
      //console.log("coordsLatlngs ",coords);
      for (let value of Object.values(coords)) {
        //console.log("Val is ",value); // John, then 30
        lt.push(value)
      }
      //console.log("Combine ",lt);
      document.getElementById('coords').value=lt;
      //return lt;
    }
  
    function loadScriptWeather(url){
      console.log(window.screen.availHeight,
      window.screen.availWidth)
      if(window.screen.availHeight <= 1024)
      {
        $("#mapweather").removeClass("col-md-8");
        $("#weathermapgrid").removeClass("col-md-4");
        $("#mapweather").addClass("col-md-8");
        $("#weathermapgrid").addClass("col-md-8");
      }
      var index  =window.document.getElementsByTagName("script")[1]
        var script = window.document.createElement("script")
        script.src=url
        script.async=true
        script.defer = true
        index.parentNode.insertBefore(script,index)
    }
  

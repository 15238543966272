import React, { Component } from "react";
import CSVFileValidator from 'csv-file-validator';
import moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MenuModule } from "@ag-grid-enterprise/menu";
import Select from "react-select";
import $ from "jquery";
import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { color } from "highcharts";
// import { formatDate } from "@fullcalendar/react";
var redirectURL = require("../redirectURL");

export default class LCLFrieghtManagementSystem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        width: 160,
        resizable: true,
      },
      eventAction: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      // loadshow: "show-n",
      // overly: "show-n",
      rowdata: [],
      rowdataforexwork:[],
      additionalchargesdata:[],
      sliderbulkupload: "",
      rowModelhighchartoptionsType: "enterprise",
      //   frameworkComponents: {
      //     BatchDownload: BatchDownload,
      //   },
      rowGroupPanelShow: "always",
      statusBar: {
        // statusPanels: [
        //   {
        //     statusPanel: "agTotalAndFilteredRowCountComponent",
        //     align: "left",
        //   },
        //   {
        //     statusPanel: "agTotalRowCountComponent",
        //     align: "center",
        //   },
        //   { statusPanel: "agFilteredRowCountComponent" },
        //   { statusPanel: "agSelectedRowCountComponent" },
        //   { statusPanel: "agAggregationComponent" },
        // ],
      },
      paginationPageSize: 50,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      animateRows: true,
      ApproveCompleted:false,
      dept_code: "",
      pagetitle: "",
      csvData: 0,
      overly: "show-n",
      loadshow: "show-n",
      pagetitle: "",
      eventGridAction: "gridAction",
      csvcontent: [],
      file: "",
      batch_name: "",
      cluster_breaks: "2",
      cluster_radius: "0.5",
      notification_usernames: "",
      bulkslide1: "",
      bulkslide2: "",
      bulkslide3: "",
      bulkslide4:'',
      bulkslidebulk:'',
      newValue: "",

      rowId: "",
      show: false,
      basicTitle: "",
      basicType: "",
      transitDate: "",
      transpoterGSTDate: "",
      unloadedDate: "",
      reachedDate: "",
      SectororCountry: "",
      location: "",
      additionalcharges:'',

      AustriaId1:"",
      ChinaId1:'',
      RepublicId1:'',

      ratesUpto:'',
      exworksCharges:'',
      plantname:'',
      transporterrate:"",
      additional_charges_cfs:0,
      additional_charges_cbm:0,
      additional_charges_shipment:0,
      usdvaluedisplay:'',
      eurovaluedisplay:'',
      Anamet_Europe_BV:0,
      Baker_Hughes_Digital_Solutions_GMBH:0,
      Kamax_Czech_Republic:0,
      Kamax_Spain:0,
      csvData: [],
            bulkMarkData: [],

    };
    this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener("click", this.handleClickOutside);
    redirectURL
      .post("/consignments/fmsrates")
      .then((response) => {
        console.log(response.data, "140----");
        // console.log(response.data, "140----date");

var resp=response.data[0]

console.log(resp.additional_charges,"resp====")
        this.setState({
          rowdata: response.data,
          rowdataforexwork:resp.suppliers,
          additionalchargesdata : resp.additional_charges,
          usdvaluedisplay:response.data[0].usd_inr,
          eurovaluedisplay:response.data[response.data.length - 1].euro_inr,
          transporterrate: response.data,
          // additional_charges_shipment: resp.shipment_charges,
          // additional_charges_cbm: resp.cbm_charges,
          // additional_charges_cfs: resp.cfs_charges,
          // Kamax_Czech_Republic: resp.suppliers["Kamax, Czech Republic"],
          // Kamax_Spain: resp.suppliers["Kamax, Spain"],
          // Anamet_Europe_BV: resp.suppliers["Anamet Europe BV"],
          // Baker_Hughes_Digital_Solutions_GMBH: resp.suppliers["Baker Hughes Digital Solutions GMBH"],
          loadshow: "show-n",
          overly: "show-n",
        });
    
        // console.log(this.state.rowdataforexwork,"rowdata")
      })
      .catch((e) => {
        console.log(e);
      });
      loadDateTimeScript()
  }
  
  closeAlert = () => {
    this.setState({
      show: false,
    });
  };
  updateValue1 = () => {
    this.setState({
      bulkslide1: "slider-translate-30p",
      // rowId: rowId,
    });
  };
  updateValue2 = () => {
    this.setState({
      bulkslide2: "slider-translate-30p",
      // rowId: rowId,
    });
  };
  updateValue3 = () => {
    this.setState({
      bulkslide3: "slider-translate-30p",
      // rowId: rowId,
    });
  };
  updateValue4 = () => {
    this.setState({
      bulkslide4: "slider-translate-30p",
      // rowId: rowId,
    });
  };

  oceanfreightchangeHandler = (e) => {
    // console.log(e,'e')
    this.setState({
      SectororCountry: e,
    });
  };
  transporterratesvaluechangeHandler=(e)=>{
        // console.log(e,'ratesUpto')
    this.setState({
      ratesUpto: e,
    });
  };
  exworkChargesvaluechangeHandler=(e)=>{
    // console.log(e,'ratesUpto')
this.setState({
  exworksCharges: e,
});
};

  plantvaluechangeHandler=(e)=>{
    // console.log(e,'plantname')
this.setState({
  plantname: e,
});
};
chargeschangeHandler = (e) => {
  // console.log(e,'e')
  this.setState({
    additionalcharges: e,
  });
};
  
showBulkUpload() {
  this.setState({
      overly: "show-m",
      bulkslidebulk: "slider-translate"
  })
}


  onClickShowFilterData(event, field) {
    event.preventDefault();
    var country=this.state.SectororCountry.value
    var newValue = $("#OFValue1").val()
    var rate_validity = $("#etd_weekly").val()
console.log(country,newValue,"newValue",field,'---',rate_validity)
    if (/^\d*\.?\d+$/.test(newValue)) {
      var editer = localStorage.getItem("username");

      var params = {
        country: country,
        ocean_freight: Number(newValue),
        rate_validity:rate_validity,
        editer: editer,
      };

      console.log(params, "params===");

      redirectURL
        .post("/consignments/updatefmsrates", params)
        .then((response) => {
          if (response.data.message == "success") {
            this.setState({
              loadshow: "show-n",
              overly: "show-n",
              // rowdata: response.json().data,
              
              // basicTitle: "Successfully Updated",
              // basicType: "success",
              // show: true,
              // bulkslide: "",
            });
            country=""
            $("#OFValue1").val("")
            $("#etd_weekly").val("")

            this.componentDidMount();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      this.componentDidMount();
      window.location.reload();
      alert("Enter Numeric Value!");
    }
  }

  transporterRatesFilterData(event){
    event.preventDefault()
    var ratesUpto=this.state.ratesUpto.value;
    var plantname=this.state.plantname.value;

    var rates = $("#Rates").val()
    var transporterDate = $("#transporterDate").val()

console.log(ratesUpto,plantname,"newValue",rates)
var params = {
  cbm: ratesUpto,
  plant: plantname,
  // transportation_rates:rates,
  // transporterDate : transporterDate
  requested_transportation_rates:rates,
  requested_transporterDate : transporterDate
};

console.log(params,"params====traspo")
if (/^\d*\.?\d+$/.test(rates)) {
redirectURL
        .post("/consignments/editfmsrates", params)
        .then((response) => {
          console.log(response.data.data[0].transportation_rates,"response---")
          // if (response.data.message == "success") {
            this.setState({
              loadshow: "show-n",
              overly: "show-n",
              // transporterrate:response.data,
              basicTitle: "Successfully Uploaded. Once Changes Are Approved by the Admin, the Screen Will Be Updated.",
              basicType: "success",
              show: true,
              // bulkslide: "",
            });
// console.log(this.state.transporterrate,"transporterrate"additionalcharges)
ratesUpto=""
plantname=""
$("#Rates").val("")
$("#transporterDate").val("")
            this.componentDidMount();
          // }
        })
        .catch((e) => {
          console.log(e);
        });
      } else {
        this.componentDidMount();
        window.location.reload();
        alert("Enter Numeric Value!");
      }

  }

  exworkChargesFilterData=async(event)=>{
    event.preventDefault()
    var exworksCharges=this.state.exworksCharges.value;
    // var plantname=this.state.plantname.value;

    var exworks = $("#exworks").val()
    var exworkDate = $("#exworkDate").val()

    // console.log(exworksCharges,exworks,"exworks")
    var params = {
      exworksCharges: exworksCharges,
      // exworkDate : exworkDate,
      requested_exworkdate:exworkDate,
      // exworks:(exworks),
      requested_exworkcharges:exworks
      // Kamax_Czech_Republic:(this.state.Kamax_Czech_Republic),
      // Kamax_Spain:(this.state.Kamax_Spain),
      // Anamet_Europe_BV:(this.state.Anamet_Europe_BV),
      // Baker_Hughes_Digital_Solutions_GMBH:(this.state.Baker_Hughes_Digital_Solutions_GMBH),

};

console.log(params,"params====exworks")
if (/^\d*\.?\d+$/.test(exworks)) {
await redirectURL
        .post("/consignments/exworkschargesupdate", params)
        .then((response) => {
          console.log(response,"response---")
          // if (response.data.message == "success") {
            this.setState({
              loadshow: "show-n",
              overly: "show-n",
              // transporterrate:response.data,
              basicTitle: "Successfully Uploaded. Once Changes Are Approved by the Admin, the Screen Will Be Updated.",
              basicType: "success",
              show: true,
              bulkslide4: "",
            });
// console.log(this.state.transporterrate,"transporterrate")
exworksCharges=""
$("#exworks").val("")
$("#exworkDate").val("")
            this.componentDidMount();
          // }
        })
        .catch((e) => {
          console.log(e);
        });
      } else {
          this.componentDidMount();
          window.location.reload();
          alert("Enter Numeric Value!");
        }

  }


  additionalChargesFilterData(event){
    event.preventDefault()
    // var ratesUpto=this.state.ratesUpto.value;
    var addCharges=this.state.additionalcharges.value;

    var charges = $("#charges").val()
    var additionalDate = $("#additionalDate").val()

console.log(addCharges,charges,"newValue")
var params = {
  addCharges: addCharges,
  charges:(charges),
  additionalDate : additionalDate

};

console.log(params,"params====params")
if (/^\d*\.?\d+$/.test(charges)) {
redirectURL
        .post("/consignments/additionalchargesupdate", params)
        .then((response) => {
          console.log(response,"response---")
          // if (response.data.message == "success") {
            this.setState({
              loadshow: "show-n",
              overly: "show-n",
              // transporterrate:response.data,
              basicTitle: "Successfully Uploaded. Once Changes Are Approved by the Admin, the Screen Will Be Updated.",
              basicType: "success",
              show: true,
              bulkslide3: "",
            });
// console.log(this.state.transporterrate,"transporterrate")
addCharges=""
$("#charges").val("")
$("#additionalDate").val("")
            this.componentDidMount();
          // }
        })
        .catch((e) => {
          console.log(e);
        });
      } else {
          this.componentDidMount();
          window.location.reload();
          alert("Enter Numeric Value!");
        }

  }
 

  
  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      bulkslide1: "",
      bulkslide2: "",
      bulkslide3: "",
      bulkslide4:'',
      bulkslidebulk:''
    });
  }

  handleClickOutside = (event) => {
    if (!event.target.closest(".slide-r")) {
      this.onClickHideAll();
    }
  };

  selectCountryOptionsItems(){
    var countries = ["Austria", "China", "Czech Republic", "France", "Germany", "Hong Kong", "Hungary", "Indonesia", "Italy", "Japan", "Malaysia", "Netherlands", "South Korea", "Spain", "Singapore", "Taiwan",
     "Thailand", "UK", "USA", "Kamax, Czech Republic", "Kamax, Spain", "Anamet Europe BV", "Baker Hughes Digital Solutions GMBH"];
    let items = [];     
    //items.push({value:'all',label:'All'}); 
    countries.forEach(item =>{
        items.push({value:item,label:item});
    }); 
    return items;
}
// usdvalue(event){
//   event.preventDefault()
// var usd_inr = $("#usd_inr").val()
//   console.log(usd_inr,'usd_value')
// }
// eurovalue(event){
//   event.preventDefault()
//   var euro_inr = $("#euro_inr").val()
//   console.log(euro_inr,'euro_inr')
// }

  

submitConversition(event){
  event.preventDefault()
  var usd_inr = $("#usd_inr").val()
  console.log(usd_inr,'usd_value')
  // var euro_inr = $("#euro_inr").val()
  // console.log(euro_inr,'euro_inr')
  var params = {
    usd_inr: usd_inr,
    // euro_inr:euro_inr,
  };
  console.log(params,"params====usd_inr")
  // if (/^\d*\.?\d+$/.test(charges)) {
  redirectURL
          .post("/consignments/updateusdandeurovalues", params)
          .then((response) => {
            console.log(response.usd_inr,"response---usd")

            // if (response.data.message == "success") {
              this.setState({
                overly: "show-n",
                // usdvaluedisplay:response.usd_inr,
                loadshow:'show-n',
                show:true,
                basicTitle:"Successfully updated",
                basicType : "success"
                // bulkslide: "",
              });
  // console.log(this.state.transporterrate,"transporterrate")
  $("#usd_inr").val("")
  // $("#euro_inr").val("")
              this.componentDidMount();
            // }
          })
          .catch((e) => {
            console.log(e);
          });
  
}
usdapproveChanges=()=>{
  console.log('hello1')
  this.setState({
    loadshow: "show-m",
    overly: "show-m",
  })
  redirectURL
  .post("/consignments/usdapproveChanges")
  .then((response) => {
    if(response.data.message == "success"){
      this.setState({
        loadshow: "show-n",
        show: true,
      overly: "show-n",
      basicTitle: "Successfully Approved",
      basicType: "success",
      })
    }
    this.componentDidMount()
  })
}
usdRejectChanges=()=>{
  this.setState({
    loadshow: "show-m",
    overly: "show-m",
  })
  redirectURL
  .post("/consignments/usdRejectChanges")
  .then((response) => {
    if(response.data.message == "success"){
      this.setState({
        loadshow: "show-n",
        show: true,
      overly: "show-n",
      basicTitle: "Successfully Rejected",
      basicType: "success",
      })
    }
    this.componentDidMount()
  })
}

submitConversition1(event){
  event.preventDefault()
  // var usd_inr = $("#usd_inr").val()
  // console.log(usd_inr,'usd_value')
  var euro_inr = $("#euro_inr").val()
  console.log(euro_inr,'euro_inr')
  var params = {
    // usd_inr: usd_inr,
    euro_inr:euro_inr,
  };
  console.log(params,"params====euro_inr")
  // if (/^\d*\.?\d+$/.test(charges)) {
  redirectURL
          .post("/consignments/updateusdandeurovalues1", params)
          .then((response) => {
            console.log(response.euro_inr,"response---euro")
            // if (response.data.message == "success") {
              this.setState({
                overly: "show-n",
                // eurovaluedisplay:response.usd_inr,
                loadshow:'show-n',
                show:true,
                basicTitle:"Successfully updated",
                basicType : "success"
                // bulkslide: "",
              });
  // console.log(this.state.transporterrate,"transporterrate")
  // $("#usd_inr").val("")
  $("#euro_inr").val("")
              this.componentDidMount();
            // }
          })
          .catch((e) => {
            console.log(e);
          });
  
}
euroapproveChanges=()=>{
  this.setState({
    loadshow: "show-m",
    overly: "show-m",
  })
  redirectURL
  .post("/consignments/euroapproveChanges")
  .then((response) => {
    if(response.data.message == "success"){
      this.setState({
        loadshow: "show-n",
        show: true,
      overly: "show-n",
      basicTitle: "Successfully Approved",
      basicType: "success",
      })
    }
    this.componentDidMount()
  })
}
euroRejectChanges=()=>{
  this.setState({
    loadshow: "show-m",
    overly: "show-m",
  })
  redirectURL
  .post("/consignments/euroRejectChanges")
  .then((response) => {
    if(response.data.message == "success"){
      this.setState({
        loadshow: "show-n",
        show: true,
      overly: "show-n",
      basicTitle: "Successfully Rejected",
      basicType: "success",
      })
    }
    this.componentDidMount()
  })
}

resetUpload = () => {
  this.setState({
    bulkslidebulk: '',
      overly: 'show-n',
      file: '',
      bulkMarkData: []
  })
  $('#upform')[0].reset();
  document.getElementById("upform").reset();
}

changeFileHandler = async (e) => {
  var dt = '';
  const config = {
      headers: [
          {
              name: 'country',
              inputName: 'country',
              required: true,
              requiredError: function (headerName, rowNumber, columnNumber) {
                  return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
              }
          },
          {
            name: 'ocean_freight',
            inputName: 'ocean_freight',
            required: true,
            requiredError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            }
        },
        {
          name: 'inco_term',
          inputName: 'inco_term',
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
          }
      },
          {
              name: 'rate_validity_date',
              inputName: 'rate_validity_date',
              required: false,
              requiredError: function (headerName, rowNumber, columnNumber) {
                  return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
              }
          },
      ]
  }

  var data = CSVFileValidator(e.target.files[0], config)
      .then((csvData) => {
          let csvList = csvData.data;
          var flag = 0;
       
          if (flag == 0) {
                  this.setState({
                      bulkMarkData: csvList
                  });
          }

      })
      .catch(err => {
          console.log(err, "error")
      })
  // console.log("e.target.files[0]",e.target.result);
  if (e.target.files[0] != undefined) {
      var out = new Promise(function (reject, resolve) {
          var reader = new FileReader();
          reader.onload = async function (e) {
              var contents = await e.target.result;
              resolve(contents);
          };
          var tt = reader.readAsText(e.target.files[0]);
      });
      this.setState({
          file: e.target.files[0]
      });
      // console.log("e.target.files[0].type ", e.target.files[0]);

      if (e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
          // console.log(fileData);
          // console.log(typeof(fileData))

      }
      else {
          e.target.value = null;
          this.setState({
              uploadFile: '',
              show: true,
              basicType: 'danger',
              basicTitle: 'Please upload file having extensions .csv only.',
          });
      }
  }

}


async uploadBulkFormHandler(e) {
  e.preventDefault();
  var dataType = this.state.dataType;
  var fileData = this.state.bulkMarkData;
  var flag = 0;

  fileData.map((ele) => {
    console.log(ele, '697----')
    if (ele.country == "") {
      flag = 1;
    } else if (ele.ocean_freight == "") {
      flag = 1;
    } else if (ele.inco_term == "") {
      flag = 1;
    } else if (ele.rate_validity_date == "") {
      flag = 1;
    }
  })

  if (flag == 0) {
    if (fileData.length > 0) {
      var dateObject = new Date();
      var day = String(dateObject.getDate()).padStart(2, '0');
      var month = String(dateObject.getMonth() + 1).padStart(2, '0');
      var year = dateObject.getFullYear();
      var formattedDate = `${day}-${month}-${year}`;

      console.log(formattedDate, "formattedDate---");

      var allDatesValid = fileData.every((ele) => {
        var rateValidityDate = new Date(ele.rate_validity_date.split('-').reverse().join('-'));
        var currentDate = new Date(formattedDate.split('-').reverse().join('-'));
        console.log(rateValidityDate >= currentDate,'compare---')
        return rateValidityDate >= currentDate;
      });

      if (allDatesValid) {
        var reqParams = {
          csvData: fileData,
        }
        console.log(reqParams, 'paramsforbulk')

        this.setState({
          loadshow: 'show-m',
          overly: "show-m"
        });
      
        redirectURL.post("/consignments/bulkuploadfmsrates", reqParams)
          .then((response) => {
            var resp = response.data.message;
            console.log('resp09876', response.data)

            if (response.data.message == "success") {
              this.setState({
                basicTitle1: resp,
                loadshow: 'show-n',
                overly: 'show-n',
                basicType1: "success",
                basicTitle: "Successfully Uploaded. Once Changes Are Approved by the Admin, the Screen Will Be Updated.",
                basicType: "success",
                show1: true,
                show: true,
                bulkslidebulk: '',
                file: "",
                bulkMarkData: []
              })
            }

            this.componentDidMount()
            $('#upform')[0].reset();
          })
          .catch((e) => {
            console.log(e);
          });
       
      } else {
        this.setState({
          basicTitle: "Rate Validity Date Should not be Less then Current Date",
          basicType: "warning",
          show: true,
          file: "",
          bulkMarkData: []
        });
        $('#upform')[0].reset();
      }
    } else {
      this.setState({
        basicTitle: "Invalid Data",
        basicType: "warning",
        show: true,
        file: "",
        bulkMarkData: []
      });
      $('#upform')[0].reset();
    }
  } else {
    $("#bulkUploadID").val();
    this.setState({
      basicTitle: "Fields Should not be Empty",
      basicType: "warning",
      show: true,
      file: "",
      bulkMarkData: []
    });
    $('#upform')[0].reset();
  }
};


 exworkapproveChanges = (ele) =>{
  this.setState({
    loadshow: "show-m",
    overly: "show-m",
  })
  console.log(ele,'ele')
  if(ele.suppliers_exwork_charges != ele.requested_exworkcharges){
    var params = {
      suppliers_name : ele.suppliers_name,
      suppliers_exwork_charges:ele.suppliers_exwork_charges,
      requested_exworkcharges:ele.requested_exworkcharges,
      requested_exworkdate : ele.requested_exworkdate,
    }
    redirectURL
    .post("/consignments/approvechangerequest",params)
    .then((response) => {
      console.log(response,'response')
      if(response.data.message == 'success'){
        this.setState({
          ApproveCompleted:true,
          loadshow: "show-n",
          show: true,
        overly: "show-n",
        basicTitle: "Successfully Approved",
        basicType: "success",
        })
      }
      this.componentDidMount()
    })
  }else{
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      basicTitle: "Request is upto date",
      basicType: "success",
      show: true,
    });
    this.componentDidMount()
  }
  }
  exworkRejectChanges = (ele)=>{
    console.log(ele,'ele')
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    })
    // if(ele.suppliers_exwork_charges == ele.requested_exworkcharges){
      var params = {
        suppliers_name : ele.suppliers_name,
        suppliers_exwork_charges:ele.suppliers_exwork_charges,
        requested_exworkcharges:ele.requested_exworkcharges,
        requested_exworkdate : ele.requested_exworkdate,
      }
      redirectURL
      .post("/consignments/rejectchangerequest",params)
      .then((response) => {
        console.log(response,'response')
        if(response.data.message == 'success'){
          // alert("Successfully Rejected")
          this.setState({
            ApproveCompleted:true,
            loadshow: "show-n",
          overly: "show-n",
          show: true,
          basicTitle: "Successfully Rejected",
          basicType: "success",
          })
        }
        this.componentDidMount()
      })
    // }
    }


transporterapproveChanges = (ele1,ele2) =>{
  console.log(ele1,'ele',ele2)
  this.setState({
    loadshow: "show-m",
    overly: "show-m",
  })
  var params = {
    plant_haryana:ele1,
    plant_rohtak:ele2
   
  };
  redirectURL
        .post("/consignments/transporterapproveChanges", params)
        .then((response) => {
          if(response.data.message == 'success'){
            this.setState({
              ApproveCompleted:true,
              loadshow: "show-n",
            overly: "show-n",
            show: true,
            basicTitle: "Successfully Approved",
            basicType: "success",
            })
            this.componentDidMount()
          }
        })
}


transporterRejectChanges = (ele1,ele2) =>{
  console.log(ele1,'ele',ele2)
  this.setState({
    loadshow: "show-m",
    overly: "show-m",
  })
  var params = {
    plant_haryana:ele1,
    plant_rohtak:ele2
   
  };
  redirectURL
        .post("/consignments/transporterRejectChanges", params)
        .then((response) => {
          if(response.data.message == 'success'){
            this.setState({
              ApproveCompleted:true,
              loadshow: "show-n",
            overly: "show-n",
            show: true,
            basicTitle: "Successfully Rejected",
            basicType: "success",
            })
            this.componentDidMount()
          }
        })
}


addtionalapproveChanges=(ele)=>{
  this.setState({
    loadshow: "show-m",
    overly: "show-m",
  })
  console.log(ele,'ele')
  if(ele.charges != ele.requested_charges){
    var params = {
      charges_type:ele.charges_type,
      requested_charges:ele.requested_charges,
      requested_additionalDate:ele.requested_additionalDate,
      charges:ele.charges
    }
    redirectURL
    .post("/consignments/addtionalapprovechangerequest",params)
    .then((response) => {
      console.log(response,'response')
      if(response.data.message == 'success'){
        this.setState({
          ApproveCompleted:true,
          loadshow: "show-n",
          show: true,
        overly: "show-n",
        basicTitle: "Successfully Approved",
        basicType: "success",
        })
      }
      this.componentDidMount()
    })
  }else{
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      basicTitle: "Request is upto date",
      basicType: "success",
      show: true,
    });
    this.componentDidMount()
  }
  }


addtionalRejectChanges=(ele)=>{
console.log(ele,'ele2')
this.setState({
  loadshow: "show-m",
  overly: "show-m",
})
// if(ele.suppliers_exwork_charges == ele.requested_exworkcharges){
  var params = {
    charges_type:ele.charges_type,
    requested_charges:ele.requested_charges
  }
  redirectURL
  .post("/consignments/addtionalrejectchangerequest",params)
  .then((response) => {
    console.log(response,'response')
    if(response.data.message == 'success'){
      // alert("Successfully Rejected")
      this.setState({
        ApproveCompleted:true,
        loadshow: "show-n",
      overly: "show-n",
      show: true,
      basicTitle: "Successfully Rejected",
      basicType: "success",
      })
    }
    this.componentDidMount()
  })
// }
}

bulkapproveChanges = ()=>{
  this.setState({
    loadshow: "show-m",
    overly: "show-m",
  })
  redirectURL
  .post("/consignments/bulkapproveChanges")
  .then((response) => {
    if(response.data.message == "success"){
      this.setState({
        loadshow: "show-n",
        show: true,
      overly: "show-n",
      basicTitle: "Successfully Approved",
      basicType: "success",
      })
    }
    this.componentDidMount()
  })
}

bulkRejectChanges = ()=>{
  this.setState({
    loadshow: "show-m",
    overly: "show-m",
  })
  redirectURL
  .post("/consignments/bulkRejectChanges")
  .then((response) => {
    if(response.data.message == "success"){
      this.setState({
        loadshow: "show-n",
        show: true,
      overly: "show-n",
      basicTitle: "Successfully Rejected",
      basicType: "success",
      })
    }
    this.componentDidMount()
  })
}
  render() {
    const filteredRows2 = this.state.rowdata.filter((ele) => ele.cbm === 2 && ele.plant=="Haryana");
    const filteredRows5 = this.state.rowdata.filter((ele) => ele.cbm === 5 && ele.plant=="Haryana");
    const filteredRows8 = this.state.rowdata.filter((ele) => ele.cbm === 8 && ele.plant=="Haryana");

    const filteredRows2_Roh = this.state.rowdata.filter((ele) => ele.cbm === 2 && ele.plant=="Rohtak");
    const filteredRows5_Roh = this.state.rowdata.filter((ele) => ele.cbm === 5 && ele.plant=="Rohtak");
    const filteredRows8_Roh = this.state.rowdata.filter((ele) => ele.cbm === 8 && ele.plant=="Rohtak");

    return (
      <div class="container-fluid">
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
           <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                        </div>
                        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                        <div className={"dataLoadpage " + (this.state.loadshow)}></div>
                    <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                        <div className="loader-box">
                            <div className="loader-box">
                                <div className="rotate dashed colored">
                                </div>
                            </div>
                        </div>
                    </div>
        <div className="card col-xl-12">
          <div className="card-header">
            <h5>
            <i className="icofont icofont-vehicle-delivery-van cus-i"></i>{" "}
              <span>Freight Calculation System Master</span>
              <button className="btn btn-info" style={{ float: "right", marginRight: "10px" }} onClick={this.showBulkUpload.bind(this)}>Bulk Upload</button>
              
              {localStorage.getItem("username") === "SAMEERBHATIA"  ? (
        
          <button class="btn btn-success" style={{ marginRight: "10px",float: "right" }}  onClick={() => this.bulkapproveChanges()} 
          >
            Approve
          </button>
        
      ) : (
        ""
      )}
        &nbsp;&nbsp;
       {localStorage.getItem("username") === "SAMEERBHATIA"  ? (
        
        <button class="btn btn-danger" style={{ marginRight: "10px",float: "right"  }}  onClick={() => this.bulkRejectChanges()} 
        >
          Reject
        </button>
      
    ) : (
      ""
    )}
              {/* <button
                className="btn btn-success"
                style={{ float: "right" }}
                onClick={this.updateValue1.bind(this)}
              >
                Update
              </button> */}
              &nbsp;&nbsp;
            </h5>
          </div>

          {/* work in progress Fresh Incoming rakes */}
          <div className="row col-xl-12 col-lg-12" style={{ marginTop: "4%" }}>
            <div className="col-xl-12 col-lg-12">
              {/* <h5 style={{color:"#007bff"}}>Fresh Incoming Rakes</h5> */}
         {localStorage.getItem("username") == "SAMEERBHATIA" ?    <table
  className="table table-bordered"
  style={{ width: "100%", fontSize: "14px" }}
>
  <tr>
    <th style={{ border: "1px solid #ccc", padding: "8px" }}>Period</th>
    <th style={{ border: "1px solid #ccc", padding: "8px" }}>Month List</th>
    <th style={{ border: "1px solid #ccc", padding: "8px" }}>
      Year List (Starting from 2023)
    </th>
  </tr>
  <tr>
    <th
      colSpan="15"
      className="totalHead"
      style={{ textAlign: "center", border: "1px solid #ccc", padding: "8px" }}
    >
      Rates Database (Access for entry of Sectors and Supplier to LOG - IMP)
    </th>
  </tr>
  <tr className="trowColor">
    <th
      colSpan="6"
      style={{
        textAlign: "center",
        color: "white",
        border: "1px solid #ccc",
        padding: "8px",
      }}
    >
      Ocean Freight and Exworks charges Database
    </th>
  </tr>

  <tbody>
    <tr>
   
      <th style={{ border: "1px solid #ccc", padding: "8px" }}>Sector</th>
      <th style={{ border: "1px solid #ccc", padding: "8px" }}>
        O/F (in USD)
      </th>
      {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Requested O/F (in USD) Charges</th>:''}
      <th style={{ border: "1px solid #ccc", padding: "8px" }}>Inco Term</th>
      <th style={{ border: "1px solid #ccc", padding: "8px" }}>
        Rate Validity Date
      </th>
      {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Requested Rate Validity Date</th>:''}
    </tr>

    {this.state.rowdata
      .filter(
        (ele, index, self) =>
          self.findIndex((e) => e.country === ele.country) === index
      )
      .map((ele) => {
        const formattedDate =
          ele.rate_validity_date != "Invalid date" &&
          ele.rate_validity_date != "" &&
          ele.rate_validity_date != undefined &&
          ele.rate_validity_date != "undefined"
            ? moment
                .parseZone(new Date(ele.rate_validity_date))
                .format("DD-MM-YYYY")
            : "";

            const requested_formattedDate =
            ele.requested_rate_validity_date != "Invalid date" &&
            ele.requested_rate_validity_date != "" &&
            ele.requested_rate_validity_date != undefined &&
            ele.requested_rate_validity_date != "undefined"
              ? moment
                  .parseZone(new Date(ele.requested_rate_validity_date))
                  .format("DD-MM-YYYY")
              : "";


        return (
          <tr key={ele.country}>
            <td style={{ border: "1px solid #ccc", padding: "8px" }}>
              {ele.country}
            </td>
            <td
              style={{ border: "1px solid #ccc", padding: "8px" }}
              contentEditable={false}
              onBlur={(event) => this.handleDataChange(event, ele.country)}
            >
              {ele.ocean_freight}
            </td>
            {localStorage.getItem("username") === "SAMEERBHATIA" ?  <td
              style={{ border: "1px solid #ccc", padding: "8px" }}
              // contentEditable={false}
              // onBlur={(event) => this.handleDataChange(event, ele.country)}
            >
             
              {ele.requested_ocean_freight}
            </td> : <td></td>}
            <td style={{ border: "1px solid #ccc", padding: "8px" }}>
              {ele.inco_term}
            </td>
            <td style={{ border: "1px solid #ccc", padding: "8px" }}>
              {formattedDate}
            </td>
            {localStorage.getItem("username") === "SAMEERBHATIA" ?   <td style={{ border: "1px solid #ccc", padding: "8px" }}>
              {requested_formattedDate}
            </td>: <td></td>}
          </tr>
        );
      })}
  </tbody>
</table> :      <table
  className="table table-bordered"
  style={{ width: "100%", fontSize: "14px" }}
>
  <tr>
    <th style={{ border: "1px solid #ccc", padding: "8px" }}>Period</th>
    <th style={{ border: "1px solid #ccc", padding: "8px" }}>Month List</th>
    <th style={{ border: "1px solid #ccc", padding: "8px" }}>
      Year List (Starting from 2023)
    </th>
  </tr>
  <tr>
    <th
      colSpan="15"
      className="totalHead"
      style={{ textAlign: "center", border: "1px solid #ccc", padding: "8px" }}
    >
      Rates Database (Access for entry of Sectors and Supplier to LOG - IMP)
    </th>
  </tr>
  <tr className="trowColor">
    <th
      colSpan="6"
      style={{
        textAlign: "center",
        color: "white",
        border: "1px solid #ccc",
        padding: "8px",
      }}
    >
      Ocean Freight and Exworks charges Database
    </th>
  </tr>

  <tbody>
    <tr>
   
      <th style={{ border: "1px solid #ccc", padding: "8px" }}>Sector</th>
      <th style={{ border: "1px solid #ccc", padding: "8px" }}>
        O/F (in USD)
      </th>
      {/* {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Requested O/F (in USD) Charges</th>:''} */}
      <th style={{ border: "1px solid #ccc", padding: "8px" }}>Inco Term</th>
      <th style={{ border: "1px solid #ccc", padding: "8px" }}>
        Rate Validity Date
      </th>
      {/* {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Requested Rate Validity Date</th>:''} */}
    </tr>

    {this.state.rowdata
      .filter(
        (ele, index, self) =>
          self.findIndex((e) => e.country === ele.country) === index
      )
      .map((ele) => {
        const formattedDate =
          ele.rate_validity_date != "Invalid date" &&
          ele.rate_validity_date != "" &&
          ele.rate_validity_date != undefined &&
          ele.rate_validity_date != "undefined"
            ? moment
                .parseZone(new Date(ele.rate_validity_date))
                .format("DD-MM-YYYY")
            : "";

            const requested_formattedDate =
            ele.requested_rate_validity_date != "Invalid date" &&
            ele.requested_rate_validity_date != "" &&
            ele.requested_rate_validity_date != undefined &&
            ele.requested_rate_validity_date != "undefined"
              ? moment
                  .parseZone(new Date(ele.requested_rate_validity_date))
                  .format("DD-MM-YYYY")
              : "";


        return (
          <tr key={ele.country}>
            <td style={{ border: "1px solid #ccc", padding: "8px" }}>
              {ele.country}
            </td>
            <td
              style={{ border: "1px solid #ccc", padding: "8px" }}
              contentEditable={false}
              onBlur={(event) => this.handleDataChange(event, ele.country)}
            >
              {ele.ocean_freight}
            </td>
            {/* {localStorage.getItem("username") === "SAMEERBHATIA" ?  <td
              style={{ border: "1px solid #ccc", padding: "8px" }}
              // contentEditable={false}
              // onBlur={(event) => this.handleDataChange(event, ele.country)}
            >
             
              {ele.requested_ocean_freight}
            </td> : <td></td>} */}
            <td style={{ border: "1px solid #ccc", padding: "8px" }}>
              {ele.inco_term}
            </td>
            <td style={{ border: "1px solid #ccc", padding: "8px" }}>
              {formattedDate}
            </td>
            {/* {localStorage.getItem("username") === "SAMEERBHATIA" ?   <td style={{ border: "1px solid #ccc", padding: "8px" }}>
              {requested_formattedDate}
            </td>: <td></td>} */}
          </tr>
        );
      })}
  </tbody>
</table> }


              <br />
              <br />
              <div style={{display:'flex', justifyContent:'space-around'}}>

              <span style={{marginRight:"-30px"}}>Present USD Value : {this.state.usdvaluedisplay}</span>
              <span style={{marginRight:"-30px"}}>Present EURO Value : {this.state.eurovaluedisplay}</span>
              </div>
              <div style={{ display: 'flex' , justifyContent:'space-around'}}>
  <div style={{ display: 'flex', flexDirection: 'column', margin: '20px 0' }}>
    <form className="input-form" onSubmit={this.submitConversition.bind(this)} encType="multipart/form-data">
      <div style={{ display: 'flex', marginBottom: '40px',margin:'0px 5px' }}>
        <label style={{ width: '120px' }}>USD To INR Conversion:</label>
        <input style={{ width: '150px',height:'40px'}} type="number" className="form-control" id="usd_inr" name="usd_inr" placeholder="USD To INR" required />
        <button style={{ width: '100px',height:'40px',marginBottom:'5px'}} type="submit" className="btn btn-success">Update</button>
      </div> &nbsp;
    &nbsp;
    &nbsp;
    &nbsp;
    {/* {localStorage.getItem("username") === "SAMEERBHATIA"  ? (
       
          <button class="btn btn-success" style={{ paddingTop: 0 }}  onClick={() => this.usdapproveChanges()} 
          // disabled={this.state.ApproveCompleted}
          >
            Approve
          </button>
       
      ) : (
        ''
      )}
    
      {localStorage.getItem("username") === "SAMEERBHATIA"   ? (
       
          <button class="btn btn-danger" style={{ paddingTop: 0 }} onClick={() => this.usdRejectChanges()} 
          
          // disabled={this.state.ApproveCompleted}
          >
            Reject
          </button>
      
      ) : (
       ''
      )} */}
    </form>

   
  </div>
 
  <div style={{ display: 'flex', flexDirection: 'column', margin: '20px 0' }}>
    <form className="input-form" onSubmit={this.submitConversition1.bind(this)} encType="multipart/form-data">
      <div style={{ display: 'flex', marginBottom: '40px',margin:'0px 5px' }}>
        <label style={{ width: '120px' }}>EURO To INR Conversion:</label>
        <input style={{ width: '150px' ,height:'40px'}} type="number" className="form-control" id="euro_inr" name="euro_inr" placeholder="EURO To INR" required /> 
        <button style={{ width: '100px',height:'40px',marginBottom:'5px'}} type="submit" className="btn btn-success">Update</button>
      </div>
    {/* {localStorage.getItem("username") === "SAMEERBHATIA"  ? (
       
       <button class="btn btn-success" style={{ paddingTop: 0 }}  onClick={() => this.euroapproveChanges()} 
       // disabled={this.state.ApproveCompleted}
       >
         Approve
       </button>
    
   ) : (
     ''
   )}
 
   {localStorage.getItem("username") === "SAMEERBHATIA"   ? (
    
       <button class="btn btn-danger" style={{ paddingTop: 0 }} onClick={() => this.euroRejectChanges()} 
       
       // disabled={this.state.ApproveCompleted}
       >
         Reject
       </button>
   
   ) : (
    ''
   )} */}
    </form>

  </div>
</div>
              <div>
                <h5>
                  <button
                    className="btn btn-success"
                    style={{ float: "right" }}
                    onClick={this.updateValue4.bind(this)}
                  >
                    Update
                  </button>
                </h5>
              </div>
              <br />
              <br />
             
              {localStorage.getItem("username") === "SAMEERBHATIA" ?  <table
                className="table table-bordered"
                style={{ width: "100%", fontSize: "14px" }}
              >
                <tr className="trowColor">
                  <th
                    colspan="7"
                    style={{ textAlign: "center", color: "white" }}
                  >
                    Exwork Charges (In EURO)
                  </th>
                </tr>

                <tbody>
                  <tr>
                  {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Approve</th>:''}
                  {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Reject</th>:''}
                    <th style={{ border: "1px solid #ccc", padding: "8px" }}>Suppliers</th>
                    <th style={{ border: "1px solid #ccc", padding: "8px" }}>Exworks Charges (in EURO)</th>
                    {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Requested Exworks Charges</th>:''}
                    <th style={{ border: "1px solid #ccc", padding: "8px" }}>Rate Validity Date</th>
                    {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Requested Rate Validity Date</th>:''}
                  </tr>

                  {this.state.rowdataforexwork && Array.isArray(this.state.rowdataforexwork) && this.state.rowdataforexwork.map((ele) => {
                        const formattedDate = ele.exworkDate 

    return (
      <tr>
      {localStorage.getItem("username") === "SAMEERBHATIA" && ele.requested_exworkcharges !== undefined && ele.requested_exworkcharges !== "" ? (
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          <button class="btn btn-success" style={{ paddingTop: 0 }}  onClick={() => this.exworkapproveChanges(ele)} 
          // disabled={this.state.ApproveCompleted}
          >
            Approve
          </button>
        </td>
      ) : (
        <td></td>
      )}
    
      {localStorage.getItem("username") === "SAMEERBHATIA" && ele.requested_exworkcharges !== undefined && ele.requested_exworkcharges !== ""  ? (
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          <button class="btn btn-danger" style={{ paddingTop: 0 }} onClick={() => this.exworkRejectChanges(ele)} 
          
          // disabled={this.state.ApproveCompleted}
          >
            Reject
          </button>
        </td>
      ) : (
        <td></td>
      )}
    
      <td style={{ border: "1px solid #ccc", padding: "8px" }}>{ele.suppliers_name}</td>
      <td style={{ border: "1px solid #ccc", padding: "8px" }}>{ele.suppliers_exwork_charges}</td>
    
      {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{ele.requested_exworkcharges}</td> : <td></td>}
    
      <td style={{ border: "1px solid #ccc", padding: "8px" }}>
        {formattedDate !== "Invalid date" && formattedDate !== "" && formattedDate !== undefined && formattedDate !== "undefined"
          ? moment.parseZone(new Date(ele.exworkDate)).format('DD-MM-YYYY')
          : ''}
      </td>
    
      {localStorage.getItem("username") === "SAMEERBHATIA" ? (
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {ele.requested_exworkdate !== "Invalid date" && ele.requested_exworkdate !== "" && ele.requested_exworkdate !== undefined && ele.requested_exworkdate !== "undefined"
            ? moment.parseZone(new Date(ele.requested_exworkdate)).format('DD-MM-YYYY')
            : ''}
        </td>
      ) : (
        <td></td>
      )}
    </tr>
    
    )
  }
  )
  }
   
                </tbody>
              </table> :  <table
                className="table table-bordered"
                style={{ width: "100%", fontSize: "14px" }}
              >
                <tr className="trowColor">
                  <th
                    colspan="7"
                    style={{ textAlign: "center", color: "white" }}
                  >
                    Exwork Charges (In EURO)
                  </th>
                </tr>

                <tbody>
                  <tr>
                  {/* {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Approve</th>:''}
                  {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Reject</th>:''} */}
                    <th style={{ border: "1px solid #ccc", padding: "8px" }}>Suppliers</th>
                    <th style={{ border: "1px solid #ccc", padding: "8px" }}>Exworks Charges (in EURO)</th>
                    {/* {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Requested Exworks Charges</th>:''} */}
                    <th style={{ border: "1px solid #ccc", padding: "8px" }}>Rate Validity Date</th>
                    {/* {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Requested Rate Validity Date</th>:''} */}
                  </tr>

                  {this.state.rowdataforexwork && Array.isArray(this.state.rowdataforexwork) && this.state.rowdataforexwork.map((ele) => {
                        const formattedDate = ele.exworkDate 

    return (
      <tr>
      {/* {localStorage.getItem("username") === "SAMEERBHATIA" && ele.requested_exworkcharges !== undefined && ele.requested_exworkcharges !== "" ? (
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          <button class="btn btn-success" style={{ paddingTop: 0 }}  onClick={() => this.exworkapproveChanges(ele)} 
          // disabled={this.state.ApproveCompleted}
          >
            Approve
          </button>
        </td>
      ) : (
        <td></td>
      )} */}
    
      {/* {localStorage.getItem("username") === "SAMEERBHATIA" && ele.requested_exworkcharges !== undefined && ele.requested_exworkcharges !== ""  ? (
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          <button class="btn btn-danger" style={{ paddingTop: 0 }} onClick={() => this.exworkRejectChanges(ele)} 
          
          // disabled={this.state.ApproveCompleted}
          >
            Reject
          </button>
        </td>
      ) : (
        <td></td>
      )} */}
    
      <td style={{ border: "1px solid #ccc", padding: "8px" }}>{ele.suppliers_name}</td>
      <td style={{ border: "1px solid #ccc", padding: "8px" }}>{ele.suppliers_exwork_charges}</td>
    
      {/* {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{ele.requested_exworkcharges}</td> : <td></td>} */}
    
      <td style={{ border: "1px solid #ccc", padding: "8px" }}>
        {formattedDate !== "Invalid date" && formattedDate !== "" && formattedDate !== undefined && formattedDate !== "undefined"
          ? moment.parseZone(new Date(ele.exworkDate)).format('DD-MM-YYYY')
          : ''}
      </td>
    
      {/* {localStorage.getItem("username") === "SAMEERBHATIA" ? (
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {ele.requested_exworkdate !== "Invalid date" && ele.requested_exworkdate !== "" && ele.requested_exworkdate !== undefined && ele.requested_exworkdate !== "undefined"
            ? moment.parseZone(new Date(ele.requested_exworkdate)).format('DD-MM-YYYY')
            : ''}
        </td>
      ) : (
        <td></td>
      )} */}
    </tr>
    
    )
  }
  )
  }
   
                </tbody>
              </table>}

              <br />
              <br />


              <div>
                <h5>
                  <button
                    className="btn btn-success"
                    style={{ float: "right" }}
                    onClick={this.updateValue2.bind(this)}
                  >
                    Update
                  </button>
                </h5>
              </div>
              <br />
              <br />
             {localStorage.getItem("username") === "SAMEERBHATIA" ?  <table
  className="table table-bordered"
  style={{  width: "100%", fontSize: "14px" }}
>
  <tr className="trowColor">
    <th
      colspan="11"
      style={{ textAlign: "center", color: "white", padding: "8px" }}
    >
      Transportation Rates (In INR)
    </th>
  </tr>

  <tbody>
    <tr>
    {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Approve</th>:''}
    {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Reject</th>:''}
      <th style={{ border: "1px solid #ccc", padding: "8px" }}>CBM</th>
      <th style={{ border: "1px solid #ccc", padding: "8px" }}>
        Manesar/Gurgaon
      </th>
      {localStorage.getItem("username") =="SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Requested Manesar/Gurgaon Charges</th>:''}

      <th style={{ border: "1px solid #ccc", padding: "8px" }}>
        Rate Validity Date
      </th>
      {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Requested Rate Validity Date</th>:''}
      <th style={{ border: "1px solid #ccc", padding: "8px" }}>Rohtak</th>
      {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Requested Rohtak Charges</th>:''}
      <th style={{ border: "1px solid #ccc", padding: "8px" }}>
        Rate Validity Date
      </th>
      {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Requested Rate Validity Date</th>:''}
    </tr>

    {filteredRows2.length > 0 && (
      <tr>
          {localStorage.getItem("username") === "SAMEERBHATIA" && filteredRows2[0].requested_transportation_rates !== undefined && filteredRows2[0].requested_transportation_rates !== "" ? (
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          <button class="btn btn-success" style={{ paddingTop: 0 }}  onClick={() => this.transporterapproveChanges(filteredRows2[0],filteredRows2_Roh[0])} 
          // disabled={this.state.ApproveCompleted}
          >
            Approve
          </button>
        </td>
      ) : (
        <td></td>
      )}
    
      {localStorage.getItem("username") === "SAMEERBHATIA" && filteredRows2[0].requested_transportation_rates !== undefined && filteredRows2[0].requested_transportation_rates !== ""  ? (
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          <button class="btn btn-danger" style={{ paddingTop: 0 }} onClick={() => this.transporterRejectChanges(filteredRows2[0],filteredRows2_Roh[0])} 
          
          // disabled={this.state.ApproveCompleted}
          >
            Reject
          </button>
        </td>
      ) : (
        <td></td>
      )}
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>Upto 2</td>
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {filteredRows2[0].transportation_rates}
        </td>
        {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{filteredRows2[0].requested_transportation_rates}</td> : <td></td>}
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {formatDate(filteredRows2[0].transporterDate)}
        </td>
        {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{formatDate(filteredRows2[0].requested_transporterDate)}</td> : <td></td>}
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {filteredRows2_Roh[0].transportation_rates}
        </td>
        {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{filteredRows2_Roh[0].requested_transportation_rates}</td> : <td></td>}
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {formatDate(filteredRows2_Roh[0].transporterDate)}
        </td>
        {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{formatDate(filteredRows2_Roh[0].requested_transporterDate)}</td> : <td></td>}
      </tr>
    )}
    {filteredRows5.length > 0 && (
      <tr>
            {localStorage.getItem("username") === "SAMEERBHATIA" && filteredRows5[0].requested_transportation_rates !== undefined && filteredRows5[0].requested_transportation_rates !== "" ? (
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          <button class="btn btn-success" style={{ paddingTop: 0 }}  onClick={() => this.transporterapproveChanges(filteredRows5[0],filteredRows5_Roh[0])}  
          // disabled={this.state.ApproveCompleted}
          >
            Approve
          </button>
        </td>
      ) : (
        <td></td>
      )}
    
      {localStorage.getItem("username") === "SAMEERBHATIA" && filteredRows5[0].requested_transportation_rates !== undefined && filteredRows5[0].requested_transportation_rates !== ""  ? (
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          <button class="btn btn-danger" style={{ paddingTop: 0 }} onClick={() => this.transporterRejectChanges(filteredRows5[0],filteredRows5_Roh[0])}  
          
          // disabled={this.state.ApproveCompleted}
          >
            Reject
          </button>
        </td>
      ) : (
        <td></td>
      )}
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>Upto 5</td>
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {filteredRows5[0].transportation_rates}
        </td>
        {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{filteredRows5[0].requested_transportation_rates}</td> : <td></td>}
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {formatDate(filteredRows5[0].transporterDate)}
        </td>
        {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{formatDate(filteredRows5[0].requested_transporterDate)}</td> : <td></td>}
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {filteredRows5_Roh[0].transportation_rates}
        </td>
        {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{filteredRows5_Roh[0].requested_transportation_rates}</td> : <td></td>}
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {formatDate(filteredRows5_Roh[0].transporterDate)}
        </td>
        {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{formatDate(filteredRows5_Roh[0].requested_transporterDate)}</td> : <td></td>}
      </tr>
    )}
    {filteredRows8.length > 0 && (
      <tr>
            {localStorage.getItem("username") === "SAMEERBHATIA" && filteredRows8[0].requested_transportation_rates !== undefined && filteredRows8[0].requested_transportation_rates !== "" ? (
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          <button class="btn btn-success" style={{ paddingTop: 0 }}  onClick={() => this.transporterapproveChanges(filteredRows8[0],filteredRows8_Roh[0])}  
          // disabled={this.state.ApproveCompleted}
          >
            Approve
          </button>
        </td>
      ) : (
        <td></td>
      )}
    
      {localStorage.getItem("username") === "SAMEERBHATIA" && filteredRows8[0].requested_transportation_rates !== undefined && filteredRows8[0].requested_transportation_rates !== ""  ? (
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          <button class="btn btn-danger" style={{ paddingTop: 0 }} onClick={() => this.transporterRejectChanges(filteredRows8[0],filteredRows8_Roh[0])}  
          
          // disabled={this.state.ApproveCompleted}
          >
            Reject
          </button>
        </td>
      ) : (
        <td></td>
      )}
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>Upto 8</td>
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {filteredRows8[0].transportation_rates}
        </td>
        {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{filteredRows8[0].requested_transportation_rates}</td> : <td></td>}
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {formatDate(filteredRows8[0].transporterDate)}
        </td>
        {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{formatDate(filteredRows8[0].requested_transporterDate)}</td> : <td></td>}
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {filteredRows8_Roh[0].transportation_rates}
        </td>
        {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{filteredRows8_Roh[0].requested_transportation_rates}</td> : <td></td>}
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {formatDate(filteredRows8_Roh[0].transporterDate)}
        </td>
        {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{formatDate(filteredRows8_Roh[0].requested_transporterDate)}</td> : <td></td>}
      </tr>
    )}
  </tbody>
              </table> :   <table
  className="table table-bordered"
  style={{  width: "100%", fontSize: "14px" }}
>
  <tr className="trowColor">
    <th
      colspan="11"
      style={{ textAlign: "center", color: "white", padding: "8px" }}
    >
      Transportation Rates (In INR)
    </th>
  </tr>

  <tbody>
    <tr>
    {/* {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Approve</th>:''} */}
    {/* {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Reject</th>:''} */}
      <th style={{ border: "1px solid #ccc", padding: "8px" }}>CBM</th>
      <th style={{ border: "1px solid #ccc", padding: "8px" }}>
        Manesar/Gurgaon
      </th>
      {/* {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Requested Manesar/Gurgaon Charges</th>:''} */}

      <th style={{ border: "1px solid #ccc", padding: "8px" }}>
        Rate Validity Date
      </th>
      {/* {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Requested Rate Validity Date</th>:''} */}
      <th style={{ border: "1px solid #ccc", padding: "8px" }}>Rohtak</th>
      {/* {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Requested Rohtak Charges</th>:''} */}
      <th style={{ border: "1px solid #ccc", padding: "8px" }}>
        Rate Validity Date
      </th>
      {/* {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Requested Rate Validity Date</th>:''} */}
    </tr>

    {filteredRows2.length > 0 && (
      <tr>
          {/* {localStorage.getItem("username") === "SAMEERBHATIA" && filteredRows2[0].requested_transportation_rates !== undefined && filteredRows2[0].requested_transportation_rates !== "" ? (
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          <button class="btn btn-success" style={{ paddingTop: 0 }}  onClick={() => this.transporterapproveChanges(filteredRows2[0],filteredRows2_Roh[0])} 
          // disabled={this.state.ApproveCompleted}
          >
            Approve
          </button>
        </td>
      ) : (
        <td></td>
      )} */}
    
      {/* {localStorage.getItem("username") === "SAMEERBHATIA" && filteredRows2[0].requested_transportation_rates !== undefined && filteredRows2[0].requested_transportation_rates !== ""  ? (
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          <button class="btn btn-danger" style={{ paddingTop: 0 }} onClick={() => this.transporterRejectChanges(filteredRows2[0],filteredRows2_Roh[0])} 
          
          // disabled={this.state.ApproveCompleted}
          >
            Reject
          </button>
        </td>
      ) : (
        <td></td>
      )} */}
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>Upto 2</td>
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {filteredRows2[0].transportation_rates}
        </td>
        {/* {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{filteredRows2[0].requested_transportation_rates}</td> : <td></td>} */}
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {formatDate(filteredRows2[0].transporterDate)}
        </td>
        {/* {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{formatDate(filteredRows2[0].requested_transporterDate)}</td> : <td></td>} */}
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {filteredRows2_Roh[0].transportation_rates}
        </td>
        {/* {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{filteredRows2_Roh[0].requested_transportation_rates}</td> : <td></td>} */}
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {formatDate(filteredRows2_Roh[0].transporterDate)}
        </td>
        {/* {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{formatDate(filteredRows2_Roh[0].requested_transporterDate)}</td> : <td></td>} */}
      </tr>
    )}
    {filteredRows5.length > 0 && (
      <tr>
            {/* {localStorage.getItem("username") === "SAMEERBHATIA" && filteredRows5[0].requested_transportation_rates !== undefined && filteredRows5[0].requested_transportation_rates !== "" ? (
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          <button class="btn btn-success" style={{ paddingTop: 0 }}  onClick={() => this.transporterapproveChanges(filteredRows5[0],filteredRows5_Roh[0])}  
          // disabled={this.state.ApproveCompleted}
          >
            Approve
          </button>
        </td>
      ) : (
        <td></td>
      )}
    
      {localStorage.getItem("username") === "SAMEERBHATIA" && filteredRows5[0].requested_transportation_rates !== undefined && filteredRows5[0].requested_transportation_rates !== ""  ? (
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          <button class="btn btn-danger" style={{ paddingTop: 0 }} onClick={() => this.transporterRejectChanges(filteredRows5[0],filteredRows5_Roh[0])}  
          
          // disabled={this.state.ApproveCompleted}
          >
            Reject
          </button>
        </td>
      ) : (
        <td></td>
      )} */}
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>Upto 5</td>
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {filteredRows5[0].transportation_rates}
        </td>
        {/* {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{filteredRows5[0].requested_transportation_rates}</td> : <td></td>} */}
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {formatDate(filteredRows5[0].transporterDate)}
        </td>
        {/* {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{formatDate(filteredRows5[0].requested_transporterDate)}</td> : <td></td>} */}
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {filteredRows5_Roh[0].transportation_rates}
        </td>
        {/* {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{filteredRows5_Roh[0].requested_transportation_rates}</td> : <td></td>} */}
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {formatDate(filteredRows5_Roh[0].transporterDate)}
        </td>
        {/* {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{formatDate(filteredRows5_Roh[0].requested_transporterDate)}</td> : <td></td>} */}
      </tr>
    )}
    {filteredRows8.length > 0 && (
      <tr>
            {/* {localStorage.getItem("username") === "SAMEERBHATIA" && filteredRows8[0].requested_transportation_rates !== undefined && filteredRows8[0].requested_transportation_rates !== "" ? (
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          <button class="btn btn-success" style={{ paddingTop: 0 }}  onClick={() => this.transporterapproveChanges(filteredRows8[0],filteredRows8_Roh[0])}  
          // disabled={this.state.ApproveCompleted}
          >
            Approve
          </button>
        </td>
      ) : (
        <td></td>
      )} */}
    
      {/* {localStorage.getItem("username") === "SAMEERBHATIA" && filteredRows8[0].requested_transportation_rates !== undefined && filteredRows8[0].requested_transportation_rates !== ""  ? (
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          <button class="btn btn-danger" style={{ paddingTop: 0 }} onClick={() => this.transporterRejectChanges(filteredRows8[0],filteredRows8_Roh[0])}  
          
          // disabled={this.state.ApproveCompleted}
          >
            Reject
          </button>
        </td>
      ) : (
        <td></td>
      )} */}
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>Upto 8</td>
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {filteredRows8[0].transportation_rates}
        </td>
        {/* {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{filteredRows8[0].requested_transportation_rates}</td> : <td></td>} */}
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {formatDate(filteredRows8[0].transporterDate)}
        </td>
        {/* {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{formatDate(filteredRows8[0].requested_transporterDate)}</td> : <td></td>} */}
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {filteredRows8_Roh[0].transportation_rates}
        </td>
        {/* {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{filteredRows8_Roh[0].requested_transportation_rates}</td> : <td></td>} */}
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {formatDate(filteredRows8_Roh[0].transporterDate)}
        </td>
        {/* {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{formatDate(filteredRows8_Roh[0].requested_transporterDate)}</td> : <td></td>} */}
      </tr>
    )}
  </tbody>
              </table>}


              <br />
              <br />

              <div>
                <h5>
                  <button
                    className="btn btn-success"
                    style={{ float: "right" }}
                    onClick={this.updateValue3.bind(this)}
                  >
                    Update
                  </button>
                </h5>
              </div>
              <br />
              <br />

            {localStorage.getItem("username") === "SAMEERBHATIA" ?   <table
                className="table table-bordered"
                style={{ width: "100%", fontSize: "14px" }}
              >
                <tr className="trowColor">
                  <th
                    colspan="7"
                    style={{ textAlign: "center", color: "white" }}
                  >
                    Additational Charges(In INR)
                  </th>
                </tr>

                <tbody>
                  <tr>
                  {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Approve</th>:''}
                  {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Reject</th>:''}
                    <th style={{ border: "1px solid #ccc", padding: "8px" }}>Shipment Type</th>
                    <th style={{ border: "1px solid #ccc", padding: "8px" }}>Charges</th>
                    {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Requested Charges</th>:''}
                    <th style={{ border: "1px solid #ccc", padding: "8px" }}>Rate Validity Date</th>
                    {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Requested Rate Validity Date</th>:''}
                  </tr>
                 
                 {this.state.additionalchargesdata && Array.isArray(this.state.additionalchargesdata) && this.state.additionalchargesdata.map((ele) => {
                        const formattedDate = ele.additionalDate 

                        let label = '';

                        if (ele.charges_type === 'shipment_charges') {
                          label = 'Additional Charges/Shipment (DO Charges, Agency Charges, Others)';
                        } else if (ele.charges_type === 'cbm_charges') {
                          label = 'Additional Charges/CBM (THC & IHC)';
                        } else if (ele.charges_type === 'cfs_charges') {
                          label = 'Estimated CFS Charges/CBM';
                        }
                                return (
                                  <tr>
        {localStorage.getItem("username") === "SAMEERBHATIA" && ele.requested_charges !== undefined && ele.requested_charges !== "" ? (
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          <button class="btn btn-success" style={{ paddingTop: 0 }}  onClick={() => this.addtionalapproveChanges(ele)} 
          // disabled={this.state.ApproveCompleted}
          >
            Approve
          </button>
        </td>
      ) : (
        <td></td>
      )}
    
      {localStorage.getItem("username") === "SAMEERBHATIA" && ele.requested_charges !== undefined && ele.requested_charges !== ""  ? (
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          <button class="btn btn-danger" style={{ paddingTop: 0 }} onClick={() => this.addtionalRejectChanges(ele)} 
          
          // disabled={this.state.ApproveCompleted}
          >
            Reject
          </button>
        </td>
      ) : (
        <td></td>
      )}
                                    <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                                      {label}
                                    </td >
                                    <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                                      {ele.charges}
                                    </td>
                                    {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{ele.requested_charges}</td> : <td></td>}

                                    <td style={{ border: "1px solid #ccc", padding: "8px" }}>{formattedDate != "Invalid date" && formattedDate != '' && formattedDate != undefined && formattedDate != "undefined" ? (moment
                                        .parseZone(new Date(ele.additionalDate))
                                        .format('DD-MM-YYYY'))
                                        : ''}</td>
                                         {localStorage.getItem("username") === "SAMEERBHATIA" ? (
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {ele.requested_additionalDate !== "Invalid date" && ele.requested_additionalDate !== "" && ele.requested_additionalDate !== undefined && ele.requested_additionalDate !== "undefined"
            ? moment.parseZone(new Date(ele.requested_additionalDate)).format('DD-MM-YYYY')
            : ''}
        </td>
      ) : (
        <td></td>
      )}
                                  </tr>
                                )
                              })}
                </tbody>
              </table> :   <table
                className="table table-bordered"
                style={{ width: "100%", fontSize: "14px" }}
              >
                <tr className="trowColor">
                  <th
                    colspan="7"
                    style={{ textAlign: "center", color: "white" }}
                  >
                    Additational Charges(In INR)
                  </th>
                </tr>

                <tbody>
                  <tr>
                  {/* {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Approve</th>:''} */}
                  {/* {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Reject</th>:''} */}
                    <th style={{ border: "1px solid #ccc", padding: "8px" }}>Shipment Type</th>
                    <th style={{ border: "1px solid #ccc", padding: "8px" }}>Charges</th>
                    {/* {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Requested Charges</th>:''} */}
                    <th style={{ border: "1px solid #ccc", padding: "8px" }}>Rate Validity Date</th>
                    {/* {localStorage.getItem("username") == "SAMEERBHATIA" ? <th style={{ border: "1px solid #ccc", padding: "8px" }}>Requested Rate Validity Date</th>:''} */}
                  </tr>
                 
                 {this.state.additionalchargesdata && Array.isArray(this.state.additionalchargesdata) && this.state.additionalchargesdata.map((ele) => {
                        const formattedDate = ele.additionalDate 

                        let label = '';

                        if (ele.charges_type === 'shipment_charges') {
                          label = 'Additional Charges/Shipment (DO Charges, Agency Charges, Others)';
                        } else if (ele.charges_type === 'cbm_charges') {
                          label = 'Additional Charges/CBM (THC & IHC)';
                        } else if (ele.charges_type === 'cfs_charges') {
                          label = 'Estimated CFS Charges/CBM';
                        }
                                return (
                                  <tr>
        {/* {localStorage.getItem("username") === "SAMEERBHATIA" && ele.requested_charges !== undefined && ele.requested_charges !== "" ? (
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          <button class="btn btn-success" style={{ paddingTop: 0 }}  onClick={() => this.addtionalapproveChanges(ele)} 
          // disabled={this.state.ApproveCompleted}
          >
            Approve
          </button>
        </td>
      ) : (
        <td></td>
      )}
    
      {localStorage.getItem("username") === "SAMEERBHATIA" && ele.requested_charges !== undefined && ele.requested_charges !== ""  ? (
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          <button class="btn btn-danger" style={{ paddingTop: 0 }} onClick={() => this.addtionalRejectChanges(ele)} 
          
          // disabled={this.state.ApproveCompleted}
          >
            Reject
          </button>
        </td>
      ) : (
        <td></td>
      )} */}
                                    <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                                      {label}
                                    </td >
                                    <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                                      {ele.charges}
                                    </td>
                                    {/* {localStorage.getItem("username") === "SAMEERBHATIA" ? <td style={{ border: "1px solid #ccc", padding: "8px" }}>{ele.requested_charges}</td> : <td></td>} */}

                                    <td style={{ border: "1px solid #ccc", padding: "8px" }}>{formattedDate != "Invalid date" && formattedDate != '' && formattedDate != undefined && formattedDate != "undefined" ? (moment
                                        .parseZone(new Date(ele.additionalDate))
                                        .format('DD-MM-YYYY'))
                                        : ''}</td>
                                         {/* {localStorage.getItem("username") === "SAMEERBHATIA" ? (
        <td style={{ border: "1px solid #ccc", padding: "8px" }}>
          {ele.requested_additionalDate !== "Invalid date" && ele.requested_additionalDate !== "" && ele.requested_additionalDate !== undefined && ele.requested_additionalDate !== "undefined"
            ? moment.parseZone(new Date(ele.requested_additionalDate)).format('DD-MM-YYYY')
            : ''}
        </td>
      ) : (
        <td></td>
      )} */}
                                  </tr>
                                )
                              })}
                </tbody>
              </table>}
            </div>
          </div>
        </div>
        <div
          className={"slide-r " + this.state.bulkslide1}
          style={{ overflow: "hidden" }}
        >
          <h3 className="subH">
            Update Ocean Freight Value
            <span
              className="float-right fclose"
              style={{
                marginRight: "12px",
                padding: "1px 8px",
                marginTop: "-2px",
              }}
              onClick={this.onClickHideAll.bind(this)}
            >
              x
            </span>
          </h3>

          <div className="slide-r-body" style={{ position: "relative" }}>
            <div className="container-fluid">
              <form
                method="post"
                onSubmit={this.onClickShowFilterData.bind(this)}
                autocomplete="off"
                className="row col-xl-12 col-lg-12"
              >
              
                 <div className="col-xl-12 col-lg-12 row form-group mt-20p">
                  <label className="col-xl-12 col-lg-12">
                    Sector or Country{" "}
                  </label>
                  <Select
                    placeholder={"Sector or Country"}
                    closeMenuOnSelect={true}
                  
                    value={this.state.container_name}
                    className={"col-xl-12 col-lg-12 border-radius-0"}
                    onChange={this.oceanfreightchangeHandler.bind(this)}
                    style={{ borderRadius: "0px" }}
                    options={this.selectCountryOptionsItems()}
                    
                    required
                  />
                </div>
                  <div className="col-xl-12 col-lg-12 row form-group">
                  
                  <input
                    type="text"
                    name="OFValue1"
                    id="OFValue1"
                    className="form-control"
                    placeholder="O/F (in USD)"
                  />
                </div>

                <div className="col-xl-12 col-lg-12 row form-group">
                  <label className="col-xl-12 col-lg-12">
                  Rate Validity Date:{" "}
                  </label>
                  <input
                    type="text"
                    name="etd_weekly"
                    id="etd_weekly"
                    className="datetimepicker_datew form-control"
                    placeholder="Rate Validity Date"
                  />
                </div>
               
                <div className="form-group col-xl-3 col-lg-3">
                  <button type="submit" className="btn btn-success">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>


        <div className={"slide-r " + (this.state.bulkslidebulk)} style={{ overflow: "hidden" }}>
                            <h3 className="subH">Bulk Upload</h3>
                            <div className="slide-r-body" style={{ position: "relative" }}>
                                <div className="container-fluid">
                                    <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>

                                        <div className="form-group mt-20p">
                                            <label className="">Upload File</label>
                                            <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler} className="form-control" required />
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-success">Submit</button>
                                            <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                        </div>
                                        <p style={{ color: "red" }}>Note: Format should be same as given in sample template***</p>
                                    </form>
                                    <div className="form-group">
                                        <a className="btn btn-primary" href={require('../../assets/json/fmsdetails.csv')} target="_blank">Sample Template</a>
                                    </div>
                                </div>
                            </div>
                        </div>



        <div
          className={"slide-r " + this.state.bulkslide2}
          style={{ overflow: "hidden" }}
        >
          <h3 className="subH">
            Update Transportation Rates
            <span
              className="float-right fclose"
              style={{
                marginRight: "12px",
                padding: "1px 8px",
                marginTop: "-2px",
              }}
              onClick={this.onClickHideAll.bind(this)}
            >
              x
            </span>
          </h3>

          <div className="slide-r-body" style={{ position: "relative" }}>
            <div className="container-fluid">
              <form
                method="post"
                onSubmit={this.transporterRatesFilterData.bind(this)}
                autocomplete="off"
                className="row col-xl-12 col-lg-12"
              >
              
                 <div className="col-xl-12 col-lg-12 row form-group mt-20p">
                  <label className="col-xl-12 col-lg-12">
                    CBM{" "}
                  </label>
                  <Select
                    placeholder={"CBM"}
                    closeMenuOnSelect={true}
                  
                    value={this.state.container_name}
                    className={"col-xl-12 col-lg-12 border-radius-0"}
                    onChange={this.transporterratesvaluechangeHandler.bind(this)}
                    style={{ borderRadius: "0px" }}
                    // options={this.selectCountryOptionsItems()}
                    options={[
                      {
                        label: "Upto 2",
                        value: "2",
                      },
                      {
                        label: "Upto 5",
                        value: "5",
                      },
                      {
                        label: "Upto 8",
                        value: "8",
                      },
                    ]}
                    
                    required
                  />
               </div>
               <div className="col-xl-12 col-lg-12 row form-group mt-20p">
                  <label className="col-xl-12 col-lg-12">
                    Plant{" "}
                  </label>
                  <Select
                    placeholder={"Plant"}
                    closeMenuOnSelect={true}
                  
                    value={this.state.container_name}
                    className={"col-xl-12 col-lg-12 border-radius-0"}
                    onChange={this.plantvaluechangeHandler.bind(this)}
                    style={{ borderRadius: "0px" }}
                    // options={this.selectCountryOptionsItems()}
                    options={[
                      {
                        label: "Manesar/Gurgaon",
                        value: "Haryana",
                      },
                      {
                        label: "Rohtak",
                        value: "Rohtak",
                      },
                     
                    ]}
                    
                    required
                  />
               </div>
             <div className="col-xl-12 col-lg-12 row form-group">
                  <label className="col-xl-12 col-lg-12">
                  Transporter Rates{" "}
                  </label>
                  <input
                    type="text"
                    name="Rates"
                    id="Rates"
                    className="form-control"
                    placeholder="Transporter Rates"
                  />
                </div>
               
                <div className="col-xl-12 col-lg-12 row form-group">
                  <label className="col-xl-12 col-lg-12">
                  Rate Validity Date:{" "}
                  </label>
                  <input
                    type="text"
                    name="transporterDate"
                    id="transporterDate"
                    className="datetimepicker_datew form-control"
                    placeholder="Rate Validity Date"
                  />
                </div>

                <div className="form-group col-xl-3 col-lg-3">
                  <button type="submit" className="btn btn-success">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className={"slide-r " + this.state.bulkslide4}
          style={{ overflow: "hidden" }}
        >
          <h3 className="subH">
            Update Exworks Charges
            <span
              className="float-right fclose"
              style={{
                marginRight: "12px",
                padding: "1px 8px",
                marginTop: "-2px",
              }}
              onClick={this.onClickHideAll.bind(this)}
            >
              x
            </span>
          </h3>

          <div className="slide-r-body" style={{ position: "relative" }}>
            <div className="container-fluid">
              <form
                method="post"
                onSubmit={this.exworkChargesFilterData.bind(this)}
                autocomplete="off"
                className="row col-xl-12 col-lg-12"
              >
              
                 <div className="col-xl-12 col-lg-12 row form-group mt-20p">
                  <label className="col-xl-12 col-lg-12">
                    Exworks Charges{" "}
                  </label>
                  <Select
                    placeholder={"Exworks Charges"}
                    closeMenuOnSelect={true}
                  
                    value={this.state.container_name}
                    className={"col-xl-12 col-lg-12 border-radius-0"}
                    onChange={this.exworkChargesvaluechangeHandler.bind(this)}
                    style={{ borderRadius: "0px" }}
                    // options={this.selectCountryOptionsItems()}
                    options={[
                      {
                        label: "Kamax, Czech Republic",
                        value: "Kamax, Czech Republic",
                      },
                      {
                        label: "Kamax, Spain",
                        value: "Kamax, Spain",
                      },
                      {
                        label: "Anamet Europe BV",
                        value: "Anamet Europe BV",
                      },
                      {
                        label: "Baker Hughes Digital Solutions GMBH",
                        value: "Baker Hughes Digital Solutions GMBH",
                      },
                    ]}
                    
                    required
                  />
               </div>
          
                <div className="col-xl-12 col-lg-12 row form-group">
                  <label className="col-xl-12 col-lg-12">
                  Transporter Rates{" "}
                  </label>
                  <input
                    type="text"
                    name="exworks"
                    id="exworks"
                    className="form-control"
                    placeholder="Exworks Charges"
                  />
                </div>
               
                <div className="col-xl-12 col-lg-12 row form-group">
                  <label className="col-xl-12 col-lg-12">
                  Rate Validity Date:{" "}
                  </label>
                  <input
                    type="text"
                    name="exworkDate"
                    id="exworkDate"
                    className="datetimepicker_datew form-control"
                    placeholder="Rate Validity Date"
                  />
                </div>

                <div className="form-group col-xl-3 col-lg-3">
                  <button type="submit" className="btn btn-success">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>


        <div
          className={"slide-r " + this.state.bulkslide3}
          style={{ overflow: "hidden" }}
        >
          <h3 className="subH">
            Update Additional Charges
            <span
              className="float-right fclose"
              style={{
                marginRight: "12px",
                padding: "1px 8px",
                marginTop: "-2px",
              }}
              onClick={this.onClickHideAll.bind(this)}
            >
              x
            </span>
          </h3>

          <div className="slide-r-body" style={{ position: "relative" }}>
            <div className="container-fluid">
              <form
                method="post"
                onSubmit={this.additionalChargesFilterData.bind(this)}
                autocomplete="off"
                className="row col-xl-12 col-lg-12"
              >
              
                 <div className="col-xl-12 col-lg-12 row form-group mt-20p">
                  <label className="col-xl-12 col-lg-12">
                    Sector or Country{" "}
                  </label>
                  <Select
                    placeholder={"Additional Charges"}
                    closeMenuOnSelect={true}
                  
                    value={this.state.container_name}
                    className={"col-xl-12 col-lg-12 border-radius-0"}
                    onChange={this.chargeschangeHandler.bind(this)}
                    style={{ borderRadius: "0px" }}
                    options={[
                      {
                        label: "Additional Charges/Shipment (DO Charges, Agency Charges, Others)",
                        value: "shipment_charges",
                      },
                      {
                        label: "Additional Charges/CBM (THC & IHC)",
                        value: "cbm_charges",
                      },
                      {
                        label: "Estimated CFS Charges/Shipment",
                        value: "cfs_charges",
                      },
                    ]}
                    
                    required
                  />
                </div>
                  <div className="col-xl-12 col-lg-12 row form-group">
                  
                  <input
                    type="text"
                    name="charges"
                    id="charges"
                    className="form-control"
                    placeholder="Charges"
                  />
                </div>
               
                <div className="col-xl-12 col-lg-12 row form-group">
                  <label className="col-xl-12 col-lg-12">
                  Rate Validity Date:{" "}
                  </label>
                  <input
                    type="text"
                    name="additionalDate"
                    id="additionalDate"
                    className="datetimepicker_datew form-control"
                    placeholder="Rate Validity Date"
                  />
                </div>

                <div className="form-group col-xl-3 col-lg-3">
                  <button type="submit" className="btn btn-success">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


function DateEditor() {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
  // create the cell
  console.log(params, "params");
  this.eInput = document.createElement("input");
  this.eInput.value = params.value;

  // https://jqueryui.com/datepicker/
  $(this.eInput).datetimepicker({
    dateFormat: "yy-mm-dd",
    timepicker: false,
    changeMonth: true,
    changeYear: true,
  });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
  return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
  this.eInput.focus();
  this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
  return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
  // but this example is simple, no cleanup, we could
  // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
  // and we could leave this method out also, false is the default
  return false;
};

function loadDateTimeScript() {
  // alert("timeDate");
  $(".datetimepicker_datew").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y",
    minDate: true,
    timepicker: false,
    closeOnDateSelect: true,
    scrollMonth: false,
    scrollInput: false,
  });
  $(".datetimepicker_dateo").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y",
    minDate: true,
    timepicker: false,
    closeOnDateSelect: true,
    scrollMonth: false,
    scrollInput: false,
  });
  $(".cargo_date").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y",
    minDate: true,
    timepicker: false,
  });
}
function formatDate(dateString) {
  if(dateString != "Invalid Date" && dateString != "" && dateString != "NaN-NaN-NaN" && dateString != undefined && dateString != 'undefined' ){
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
  }
  else {
    return ""
  }
}


import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 

const NonDigilock = (props) => {
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowDigiKpi(0);
    };
    

    return (
        <div>
			<button onClick={handleOverspeedClick} className="custom-btn f22 label label-success" title="digi lock" style={{color:"#ccc"}}><i className="icofont icofont-lock"></i> &nbsp;</button>
             
        </div>
    );
};

export default NonDigilock;
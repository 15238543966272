import React, { Component } from 'react';
import Highcharts from 'highcharts';
 
class TripBarChart extends Component {
  componentDidMount() {
    this.renderChart();
  }
  componentWillReceiveProps(){
    this.renderChart();
    // console.log(prevProps)
  }

 
  renderChart() {
    const { data } = this.props;
    // console.log(data,'prop')
 
    const chartOptions = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'Trips Comparison',
      },
      xAxis: {
        categories: data.map(obj => obj.vehicle_type),
        title: {
          text: 'Vehicle Type',
        },
      },
      yAxis: {
        title: {
          text: 'Number of Trips',
        },
      },
      series: [
        {
          name: 'Manual Trips',
          data: data.map(obj => obj.num_trips_manual),
        },
        {
          name: 'System Trips',
          data: data.map(obj => obj.num_trips_system),
        },
      ],
      credits:false
    };
 
    Highcharts.chart('chart-containertrip', chartOptions);
  }
 
  render() {
    return <div id="chart-containertrip" style={{ width: '100%', height: '400px' }}></div>;
  }
}
 
export default TripBarChart;
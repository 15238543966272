import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import SweetAlert from 'react-bootstrap-sweetalert';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
var redirectURL = require('../redirectURL');

export default class WarehouseDashboard extends Component {
  	  constructor(props) {
	    super(props);
	    this.state = {
            rowData:[],
            show1: false,
            basicTitle1:'',
            basicType1:"default",
            show: false,
            basicTitle:'',
            basicType:"default",
            loadshow:"show-n",
            pagetitle:"Warehouse Visualization Dashboard",
            uploadfile:""
	    };
       this.onClickShowSidebar = this.onClickShowSidebar.bind(this);
    //    this.formUploadWarehouseData = this.formUploadWarehouseData.bind(this);
    //    this.changeFileHandler = this.changeFileHandler.bind(this);
	  }

	
    componentDidMount(){
		this.setState({
            loadshow:"show-n"
        });
        // redirectURL.post("/dashboard/warehousedata")
        // .then((response) => {
        //     console.log(response.data)
        // })
        
    }
	onClickShowSidebar(){
        this.setState({
            sliderRakeTranslate:"slider-translate",
            overly:'show-m',
            formtype:"add"
        })
    }
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRakeTranslate:'',
        });
		
    }
    // changeFileHandler = async (e) => {
	// 	this.setState({
	// 		uploadfile:e.target.files[0]
	// 	});
		
		
	// }
    // formUploadWarehouseData(event){
    //     event.preventDefault();
    //     var reqparams = new FormData()
    //     reqparams.append("file", this.state.uploadfile)
    //     reqparams.append("userid", localStorage.getItem("userid"))
    //     reqparams.append("username", localStorage.getItem("username"))
    //     reqparams.append("useremail", localStorage.getItem("email"))
    //     console.log("reqparams ", reqparams)
    //     redirectURL.post("/dashboard/readSummaryData", reqparams)
    //     .then(async (response) => {
    //         console.log("response ", response.data)
    //         var records = response.data
    //         var recordsarr = []
    //         if(records.length > 0)
    //         {
    //             records.map(async (item) => {
    //                 await Object.keys(item).forEach(async function(key) {
    //                     console.log("KEY ", key)
    //                 })
    //             })
    //         }
    //     });
    // }
    render() {
	  
	    return (
	        <div className="container-fluid">
            <SweetAlert
                  show={this.state.show}
                  type={this.state.basicType}
                  title={this.state.basicTitle}
                  onConfirm={this.closeAlert}
              >
              </SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            {/* <div className="card-header">
				       			<h5>
								    <i className="icofont icofont-ui-home cus-i"></i> <span>{this.state.pagetitle} </span>
                                    <button type="button" onClick={this.onClickShowSidebar} className="float-right btn btn-warning">Upload Warehouse Data</button>
                                </h5>
				   			</div>	                       */}
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12">
                                    <iframe frameborder="0" width="100%" height="1900" src="https://autometrics.in:8443/open-view/16823/5a3b11a15907dd9c05b7b664b7f48f1d"></iframe>
                               
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>


                <div className={"slide-r "+(this.state.sliderRakeTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Upload Warehouse Data
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                                    
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formUploadWarehouseData}>
                                
                            <div className="form-group mt-20p">
								<label className="">Upload File *</label> 
								<input type="file" name="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
							</div>
                            
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
							{/* <div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/sample_mll_rake_schedule_new.csv')} target="_blank">Sample Template</a>
							
							</div> */}
						</form>

                        </div>
					</div>
				</div>
            </div>
	    );
	  }
}
  
                                
                                
                                

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const AlternateRoutes = (props) => {
    
    const alternateRouteOne = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowImpactRoutes(eval(props.data.alternate_routes)[0]);
    };
    const alternateRouteTwo = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowImpactRoutes(eval(props.data.alternate_routes)[1]);
    };
    const alternateRouteThree = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowImpactRoutes(eval(props.data.alternate_routes)[2]);
    };
    var shortestAlternateRoute = props.context.componentParent.state.shortestAlternateRoute;
    return (
        <div>
			{/* {eval(props.data.alternate_routes).map((e,index)=>{
                return <a href="#0" onClick={handleRouteClick(e)}>Route-{index+1}, </a>
            })} */}
            {(eval(props.data.alternate_routes).length == 0)?
                <span>No Routes Available</span>
            :""}
            {(eval(props.data.alternate_routes).length >= 1)?
                <button className={(shortestAlternateRoute != eval(props.data.alternate_routes)[0])?"btn btn-success": "btn-info"} style={{padding:"0px", marginRight: "2px"}} onClick={alternateRouteOne}>Route-1</button>
            :""}
            {(eval(props.data.alternate_routes).length >= 2)?
                <button className={(shortestAlternateRoute != eval(props.data.alternate_routes)[1])?"btn btn-success": "btn-info"} style={{padding:"0px", marginRight: "2px"}} onClick={alternateRouteTwo}>Route-2</button>
            :""}
            {(eval(props.data.alternate_routes).length >= 3)?
                <button className={(shortestAlternateRoute != eval(props.data.alternate_routes)[2])?"btn btn-success": "btn-info"} style={{padding:"0px", marginRight: "2px"}} onClick={alternateRouteThree}>Route-3</button>
            :""}
        </div>
    );
};

export default AlternateRoutes;

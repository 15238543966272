import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import CountUp from 'react-countup';
import _ from "lodash";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import 'react-vertical-timeline-component/style.min.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
var redirectURL = require('../redirectURL');
var moment = require('moment');
export default class TVPFreightPaymentPending extends Component {

    constructor(props) {
        super(props);
        var date2 = new Date();
        var currentDate = moment(date2).format("YYYY-MM-DD");
        var currentStart = moment(date2).subtract(90, 'days').format("YYYY-MM-DD")
        this.state = {
            pageTitle: "Primary Dashboard",
            loadshow: 'show-n',
            overly: 'show-n',
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            components: { datePicker: getDatePicker() },
            startDate: currentStart,
            endDate: currentDate,
            defaultsdate1: currentStart,
            defaultedate1: currentDate,
            defaultsdate: "",
            defaultedate: "",
            zerofifteen: [],
            fifteenthirty: [],
            thirtyonefortyfive: [],
            morefortyfive: [],
            stockzerofifteen: [],
            stockfifteenthirty: [],
            stockthirtyonefortyfive: [],
            stockmorefortyfive: [],
            zeroamt: 0,
            fifteen: 0,
            thirty: 0,
            moreforty: 0,
            stockzeroamt: 0,
            stockfifteen: 0,
            stockthirty: 0,
            stockmoreforty: 0,
        }
    }
    componentDidMount() {
        const transporterCode = this.props.match.params.transporterCode === "ALL" ? [] : [this.props.match.params.transporterCode];
        console.log(transporterCode == 0, 'ransporterCode')
        var parameters = {
            pagetype: "primary",
            transporter_code: transporterCode.length > 0 ? JSON.stringify(transporterCode) : '',
            startDate: this.state.startDate,
            endDate: this.state.endDate
        };

        this.onLoadDashboardData(parameters)
    };
    onClickHideAll() {
        this.setState({
            loadshow: "show-n",
            overly: "show-n",
        });
    }
    async onLoadDashboardData(parameters) {
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m',
        })
        var createdate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
        var s1 = moment.parseZone(createdate).format("x")
        await redirectURL.post("/consignments/secondarypaymentscounterdata", parameters)
            .then((response) => {
                var records = []
                var zerofifteen = []
                var fifteenthirty = []
                var thirtyonefortyfive = []
                var morefortyfive = []

                var stockzerofifteen = []
                var stockfifteenthirty = []
                var stockthirtyonefortyfive = []
                var stockmorefortyfive = []
                var zeroamt = 0
                var fifteen = 0
                var thirty = 0
                var moreforty = 0

                records = response.data;
                // console.log("records bhanu", records.length)
                if (records.length > 0) {
                    records.map((item) => {
                        var inv = moment.parseZone(item.sec_upload_date).format("YYYY-MM-DD HH:mm:ss");
                        //console.log("inv ", inv);
                        var s2 = moment.parseZone(inv).format("x")
                        //console.log(s2);
                        var diffreetime = moment.duration(moment(createdate).diff(inv));
                        var delaydays = Math.round(diffreetime.asDays());
                        // console.log("prim delaydays ", delaydays)
                        if (item.sec_is_payment_receive == "Yes" && item.sec_status == "Payment received") {

                        }
                        else {

                            if (delaydays >= 0 && delaydays <= 15) {
                                zerofifteen.push(item)
                                if (item.sec_status != "Payment received") {
                                    zeroamt = parseFloat(zeroamt) + parseFloat(item.sec_fret_amt)
                                }

                            }
                            if (delaydays >= 16 && delaydays <= 30) {
                                //console.log("item",item)
                                fifteenthirty.push(item)
                                stockfifteenthirty.push(item)
                                if (item.sec_status != "Payment received") {
                                    fifteen = parseFloat(fifteen) + parseFloat(item.sec_fret_amt)
                                }

                            }
                            if (delaydays >= 31 && delaydays <= 45) {
                                thirtyonefortyfive.push(item)
                                stockthirtyonefortyfive.push(item)
                                if (item.sec_status != "Payment received") {
                                    thirty = parseFloat(thirty) + parseFloat(item.sec_fret_amt)
                                }

                            }
                            if (delaydays > 45) {
                                morefortyfive.push(item)
                                stockmorefortyfive.push(item)
                                if (item.sec_status != "Payment received") {
                                    moreforty = parseFloat(moreforty) + parseFloat(item.sec_fret_amt)
                                }

                            }
                        }

                    })

                }

                this.setState({
                    zerofifteen: zerofifteen,
                    fifteenthirty: fifteenthirty,
                    thirtyonefortyfive: thirtyonefortyfive,
                    morefortyfive: morefortyfive,
                    zeroamt: zeroamt,
                    fifteen: fifteen,
                    thirty: thirty,
                    moreforty: moreforty
                })

            })

        await redirectURL.post("/consignments/stockyardpaymentscounterdata", parameters)
            .then((response) => {
                var recordstock = []

                var stockzerofifteen = []
                var stockfifteenthirty = []
                var stockthirtyonefortyfive = []
                var stockmorefortyfive = []
                var zeroamt = 0
                var fifteen = 0
                var thirty = 0
                var moreforty = 0
                recordstock = response.data;
                // console.log("recordstock ", recordstock)
                if (recordstock.length > 0) {
                    recordstock.map((item) => {

                        //stockcounter
                        // console.log("item.stockyard_upload_date ", typeof item.stockyard_upload_date)
                        if (item.stockyard_upload_date != "") {
                            if (item.stockyard_is_payment_receive == "Yes" && item.stockyard_status == "Payment received") {
                            }
                            else {
                                var inv1 = moment.parseZone(item.stockyard_upload_date).format("YYYY-MM-DD HH:mm:ss");
                                //console.log("inv ", inv);
                                var s2 = moment.parseZone(inv1).format("x")
                                //console.log(s2);
                                var diffreetime1 = moment.duration(moment(createdate).diff(inv1));
                                var delaydays1 = Math.round(diffreetime1.asDays());
                                // console.log("delaydays1 ", delaydays1)
                                if (delaydays1 >= 0 && delaydays1 <= 15) {
                                    stockzerofifteen.push(item)
                                    if (item.stockyard_status != "Payment received") {
                                        zeroamt = parseFloat(zeroamt) + parseFloat(item.tvp_stock_charges)
                                    }
                                }
                                if (delaydays1 >= 16 && delaydays1 <= 30) {
                                    stockfifteenthirty.push(item)
                                    if (item.stockyard_status != "Payment received") {
                                        fifteen = parseFloat(fifteen) + parseFloat(item.tvp_stock_charges)
                                    }

                                }
                                if (delaydays1 >= 31 && delaydays1 <= 45) {
                                    stockthirtyonefortyfive.push(item)
                                    if (item.stockyard_status != "Payment received") {
                                        thirty = parseFloat(thirty) + parseFloat(item.tvp_stock_charges)
                                    }
                                }
                                if (delaydays1 > 45) {
                                    stockmorefortyfive.push(item)
                                    if (item.stockyard_status != "Payment received") {
                                        moreforty = parseFloat(moreforty) + parseFloat(item.tvp_stock_charges)
                                    }
                                }
                            }
                        }
                    })

                }

                // console.log("stockzerofifteen ", stockzerofifteen.length)
                // console.log("stockfifteenthirty ", stockfifteenthirty.length)
                // console.log("stockthirtyonefortyfive ", stockthirtyonefortyfive.length)
                // console.log("stockmorefortyfive ", stockmorefortyfive.length)
                this.setState({
                    stockzerofifteen: stockzerofifteen,
                    stockfifteenthirty: stockfifteenthirty,
                    stockthirtyonefortyfive: stockthirtyonefortyfive.length > 0 ? stockthirtyonefortyfive : [],
                    stockmorefortyfive: stockmorefortyfive.length > 0 ? stockmorefortyfive : [],
                    stockzeroamt: zeroamt,
                    stockfifteen: fifteen,
                    stockthirty: thirty,
                    stockmoreforty: moreforty,
                    loadshow: 'show-n',
                    overly: 'show-n',
                });


            })
    }

    onClickFilterButton = async () => {
        if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
            var sDate = this.state.defaultsdate;
        }
        else {
            var sDate = this.state.startDate;
        }
        if (this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN") {
            var eDate = this.state.defaultedate;
        }
        else {
            var eDate = this.state.endDate;
        }

        const transporterCode = this.props.match.params.transporterCode === "ALL" ? [] : [this.props.match.params.transporterCode];
        console.log(transporterCode, 'transporterCode2')
        var parameters = {
            pagetype: "primary",
            transporter_code: transporterCode.length > 0 ? JSON.stringify(transporterCode) : '',
            startDate: sDate,
            endDate: eDate
        }
        this.onLoadDashboardData(parameters)
    }

    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var month = ("0" + (d.getMonth() + 1)).slice(-2); // Adding leading zero if month is less than 10
        var day = ("0" + d.getDate()).slice(-2); // Adding leading zero if day is less than 10
        var startdate = d.getFullYear() + "-" + month + "-" + day;
        if (this.state.endDate != undefined && this.state.endDate != "") {
            var startDate = new Date(startdate);
            var endDate = new Date(this.state.endDate);
            var startTime = startDate.getTime();
            var endTime = endDate.getTime();
            if (startTime > endTime) {
                this.setState({
                    show: true,
                    basicTitle: 'To Date should be greater than From Date',
                    basicType: "danger",
                    loadshow: 'show-n'
                });
            } else {
                this.setState({
                    startDate: startdate
                });
            }
        } else {
            this.setState({
                startDate: startdate
            });
        }
    }

    handlerEndDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var month = ("0" + (d.getMonth() + 1)).slice(-2); // Adding leading zero if month is less than 10
        var day = ("0" + d.getDate()).slice(-2); // Adding leading zero if day is less than 10
        var edate = d.getFullYear() + "-" + month + "-" + day;
        if (this.state.startDate != undefined && this.state.startDate != "") {
            var startDate = new Date(this.state.startDate);
            var endDate = new Date(edate);
            var startTime = startDate.getTime();
            var endTime = endDate.getTime();
            if (startTime > endTime) {
                this.setState({
                    show: true,
                    basicTitle: 'To Date should be greater than From Date',
                    basicType: "danger",
                    loadshow: 'show-n'
                });
            } else {
                this.setState({
                    endDate: edate
                });
            }
        } else {
            this.setState({
                endDate: edate
            });
        }
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    render() {
        console.log(this.state.zeroamt,'this.state.zeroamt',this.state.stockzeroamt,"this.state.stockzeroamt",this.state.fifteen,this.state.stockfifteen,"this.state.stockfifteen")
        var zeroamt = Math.round(this.state.zeroamt) + Math.round(this.state.stockzeroamt)
        var fifteen = Math.round(this.state.fifteen) + Math.round(this.state.stockfifteen)
        var thirty = Math.round(this.state.thirty) + Math.round(this.state.stockthirty)
        var moreforty = Math.round(this.state.moreforty) + Math.round(this.state.stockmoreforty)
        return (
            <div className="col-xl-12 col-lg-12">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                <div className={"dataLoadpage " + (this.state.loadshow)}></div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box">
                        <div className="loader-box">
                            <div className="rotate dashed colored">
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ textAlign: "center", background: "#171C8F", width: '100%', margin: "5px 11px 20px -12px", borderRadius: "5px", height: '70px', paddingBottom: '25px'}}>
          <h6 class="txt-dark f40" style={{ marginTop: "0px" }}>
            <span style={{ fontSize: "20px", color: "#FFFFFF" }}>
              <span>TVP Freight Payment Pending (SEC-TVP)</span>

            </span>
          </h6>
        </div>
                <div className="row" >

                    <div className="row col-xl-12 col-lg-12" style={{ display: "flex", flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div className='row' style={{justifyContent:'space-between',padding:'15px'}}>
                        <div className=" card">
                            <div className="row card-body" style={{ padding: "10px 30px" }}>
                                <div className="col-xl-6 col-lg-6 form-group">
                                    <label>From Date</label>
                                    <Datetime value={this.state.startDate}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{ placeholder: 'From Date', name: 'startDate', autoComplete: 'off', readOnly: true }}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={null} 
                                        name="startDate"
                                        onChange={this.handlerStartDateTime.bind(this)}
                                        isValidDate={current => {
                                            return current.isSameOrBefore(moment(), 'day');
                                        }} />

                                </div>

                                <div className="col-xl-6 col-lg-6 form-group">
                                    <label>To Date</label>
                                    <Datetime value={this.state.endDate}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{ placeholder: 'To Date', name: 'endDate', autoComplete: 'off', readOnly: true }}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={null} 
                                        onChange={this.handlerEndDateTime.bind(this)}
                                        isValidDate={current => {
                                            return current.isSameOrBefore(moment(), 'day');
                                        }} />

                                </div>
                                <div className="col-xl-6 col-lg-6 form-group">
                                    <button type="button" style={{ margin: "0 auto" }} className="btn btn-success" onClick={this.onClickFilterButton.bind(this)}>Submit</button>
                                </div>
                            </div>
                           
                        </div>
                    <div >
                            <h5>
                                <span >
                                    <a className="btn btn-danger" href="/lspceodashboard">Back</a>
                                </span>
                            </h5>
                        </div>
                    </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                        {/* <div className="card"> */}
                            <div className="card-body">
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        {/* {zeroamt > 0 ? <div className="col cirlce-d " style={{ backgroundColor: '#FF7F7F',margin:'-20px',padding:'15px'  }}>
                                            <span className="f13"><i className="icofont icofont-vehicle-delivery-van cus-i txt-info f24"></i><br /> 0-15 Days</span>
                                            <div className="row col-xl-12 col-lg-12 n-p-0">
                                                <div className="col-xl-12 col-lg-12 n-p-0">
                                                    <span>Secondary Bills: </span>
                                                    <span className="counter f26 txt-info"><CountUp end={(this.state.zerofifteenlength != '') ? this.state.zerofifteen.length : 0} /></span>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 n-p-0">
                                                    <span>Stockyard Bills: </span>
                                                    <span className="counter f26 greenfont"><CountUp end={(parseInt(this.state.stockzerofifteen.length) != '') ? (parseInt(this.state.stockzerofifteen.length)) : 0} /></span>
                                                </div>

                                                <div className="col-xl-12 col-lg-12 n-p-0">
                                                    <span>Amount: </span>
                                                    <span className="f26 redfont"> {zeroamt}</span>
                                                </div>
                                            </div>
                                        </div>
                                            : */}
                                            <div className="col cirlce-d " style={{ backgroundColor: '#95d5b2',margin:'-20px',padding:'15px', color:'black' }}>
                                                <span className="f16" style={{color:'black'}}><i className="icofont icofont-vehicle-delivery-van cus-i  f24"></i><br /> 0-15 Days</span>
                                                <div className="row col-xl-12 col-lg-12 n-p-0">
                                                    <div className="col-xl-12 col-lg-12 n-p-0" >
                                                        <span style={{fontSize:'16px'}}>Secondary Bills: </span>
                                                        <span className="counter f26" style={{color:'black'}}><CountUp end={(this.state.zerofifteen.length > 0) ? (this.state.zerofifteen.length) : 0} /></span>
                                                    </div>
                                                    <div className="col-xl-12 col-lg-12 n-p-0" style={{color:'black'}}>
                                                        <span style={{fontSize:'16px'}}>Stockyard Bills: </span>
                                                        <span className="counter f26 greenfont" style={{color:'black'}}><CountUp end={(parseInt(this.state.stockzerofifteen.length)  > 0) ? (parseInt(this.state.stockzerofifteen.length)) : 0} /></span>
                                                    </div>

                                                    <div className="col-xl-12 col-lg-12 n-p-0" style={{color:'black'}}>
                                                    <span style={{fontSize:'18px',fontFamily:'sans-serif'}}>Amount: &#x20B9;</span>
                                                        <span className="f26 redfont" style={{color:'black'}}> {zeroamt.toLocaleString('en-IN')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* // } */}
                                        {
                                            fifteen > 0 ? <div className="col cirlce-d " style={{ backgroundColor: '#FF7F7F',margin:'-20px',padding:'15px'   }}>
                                                <span className="f16" style={{color:'black'}}><i className="icofont icofont-vehicle-delivery-van cus-i f24"></i><br /> 16-30 Days</span>
                                                <div className="row col-xl-12 col-lg-12 n-p-0">
                                                    <div className="col-xl-12 col-lg-12 n-p-0" style={{color:'black'}}>
                                                        <span style={{fontSize:'16px'}}>Secondary Bills: </span>
                                                        <span className="counter f26 " style={{color:'black'}}><CountUp end={(this.state.fifteenthirty.length != '') ? (this.state.fifteenthirty.length): 0} /></span>
                                                    </div>
                                                    <div className="col-xl-12 col-lg-12 n-p-0" style={{color:'black'}}>
                                                        <span style={{fontSize:'16px'}}>Stockyard Bills: </span>
                                                        <span className="counter f26 greenfont" style={{color:'black'}}><CountUp end={(parseInt(this.state.stockfifteenthirty.length) != '') ? (parseInt(this.state.stockfifteenthirty.length)) : 0} /></span>
                                                    </div>

                                                    <div className="col-xl-12 col-lg-12 n-p-0" style={{color:'black'}}>
                                                    <span style={{fontSize:'18px',fontFamily:'sans-serif'}}>Amount: &#x20B9;</span>
                                                        <span className="f26 redfont" style={{color:'black'}}> {fifteen.toLocaleString('en-IN')}</span>
                                                    </div>


                                                </div>
                                            </div>
                                                :
                                                <div className="col cirlce-d " style={{ backgroundColor: '#95d5b2',margin:'-20px',padding:'15px'  }}>
                                                    <span className="f16" style={{color:'black'}}><i className="icofont icofont-vehicle-delivery-van cus-i  f24"></i><br /> 16-30 Days</span>
                                                    <div className="row col-xl-12 col-lg-12 n-p-0">
                                                        <div className="col-xl-12 col-lg-12 n-p-0" style={{color:'black'}}>
                                                            <span style={{fontSize:'16px'}}>Secondary Bills: </span>
                                                            <span className="counter f26 " style={{color:'black'}}><CountUp end={(this.state.fifteenthirty.length != '') ? (this.state.fifteenthirty.length) : 0} /></span>
                                                        </div>
                                                        <div className="col-xl-12 col-lg-12 n-p-0" style={{color:'black'}}>
                                                            <span style={{fontSize:'16px'}}>Stockyard Bills: </span>
                                                            <span className="counter f26 greenfont" style={{color:'black'}}><CountUp end={(parseInt(this.state.stockfifteenthirty.length) != '') ? (parseInt(this.state.stockfifteenthirty.length)) : 0} /></span>
                                                        </div>

                                                        <div className="col-xl-12 col-lg-12 n-p-0" style={{color:'black'}}>
                                                        <span style={{fontSize:'18px',fontFamily:'sans-serif'}}>Amount: &#x20B9;</span>
                                                            <span className="f26 redfont" style={{color:'black'}}> {fifteen.toLocaleString('en-IN')}</span>
                                                        </div>


                                                    </div>
                                                </div>
                                        }
                                        {
                                            thirty > 0 ?
                                                <div className="col cirlce-d " style={{ backgroundColor: '#FF7F7F' ,margin:'-20px',padding:'15px'  }}>
                                                    <span className="f16" style={{color:'black'}}><i className="icofont icofont-vehicle-delivery-van cus-i  f24"></i><br /> 31-45 Days</span>
                                                    <div className="row col-xl-12 col-lg-12 n-p-0">
                                                        <div className="col-xl-12 col-lg-12 n-p-0" style={{color:'black'}}>
                                                            <span style={{fontSize:'16px'}}>Secondary Bills: </span>
                                                            <span className="counter f26 " style={{color:'black'}}><CountUp end={(this.state.thirtyonefortyfive.length != '') ?( this.state.thirtyonefortyfive.length) : 0} /></span>
                                                        </div>
                                                        <div className="col-xl-12 col-lg-12 n-p-0" style={{color:'black'}}>
                                                            <span style={{fontSize:'16px'}}>Stockyard Bills: </span>
                                                            <span className="counter f26 greenfont" style={{color:'black'}}><CountUp end={(parseInt(this.state.stockthirtyonefortyfive.length) != '') ? (parseInt(this.state.stockthirtyonefortyfive.length)) : 0} /></span>
                                                        </div>

                                                        <div className="col-xl-12 col-lg-12 n-p-0" style={{color:'black'}}>
                                                        <span style={{fontSize:'18px',fontFamily:'sans-serif'}}>Amount: &#x20B9;</span>
                                                            <span className="f26 redfont" style={{color:'black'}}> {thirty.toLocaleString('en-IN')}</span>
                                                        </div>

                                                    </div>
                                                </div>
                                                : <div className="col cirlce-d " style={{ backgroundColor: '#95d5b2' ,margin:'-20px',padding:'15px'  }}>
                                                    <span className="f16" style={{color:'black'}}><i className="icofont icofont-vehicle-delivery-van cus-i  f24"></i><br /> 31-45 Days</span>
                                                    <div className="row col-xl-12 col-lg-12 n-p-0">
                                                        <div className="col-xl-12 col-lg-12 n-p-0" style={{color:'black'}}>
                                                            <span style={{fontSize:'16px'}}>Secondary Bills: </span>
                                                            <span className="counter f26 " style={{color:'black'}}><CountUp end={(this.state.thirtyonefortyfive.length != '') ? (this.state.thirtyonefortyfive.length) : 0} /></span>
                                                        </div>
                                                        <div className="col-xl-12 col-lg-12 n-p-0" style={{color:'black'}}>
                                                            <span style={{fontSize:'16px'}}>Stockyard Bills: </span>
                                                            <span className="counter f26 greenfont" style={{color:'black'}}><CountUp end={(parseInt(this.state.stockthirtyonefortyfive.length) != '') ? (parseInt(this.state.stockthirtyonefortyfive.length)) : 0} /></span>
                                                        </div>

                                                        <div className="col-xl-12 col-lg-12 n-p-0" style={{color:'black'}}>
                                                        <span style={{fontSize:'18px',fontFamily:'sans-serif'}}>Amount: &#x20B9;</span>
                                                            <span className="f26 redfont" style={{color:'black'}}> {thirty.toLocaleString('en-IN')}</span>
                                                        </div>

                                                    </div>
                                                </div>
                                        }
                                        {moreforty > 0 ? <div className="col cirlce-d " style={{ backgroundColor: '#FF7F7F',margin:'-20px',padding:'15px'  }}>
                                            <span className="f16" style={{color:'black'}}><i className="icofont icofont-vehicle-delivery-van cus-i  f24"></i><br /> More than 45 Days</span>
                                            <div className="row col-xl-12 col-lg-12 n-p-0">
                                                <div className="col-xl-12 col-lg-12 n-p-0" style={{color:'black'}}>
                                                    <span style={{fontSize:'16px'}}>Secondary Bills: </span>
                                                    <span className="counter f26 " style={{color:'black'}}><CountUp end={(this.state.morefortyfive.length != '') ? (this.state.morefortyfive.length) : 0} /></span>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 n-p-0" style={{color:'black'}}>
                                                    <span style={{fontSize:'16px'}}>Stockyard Bills: </span>
                                                    <span className="counter f26 greenfont" style={{color:'black'}}><CountUp end={(parseInt(this.state.stockmorefortyfive.length) != '') ? (parseInt(this.state.stockmorefortyfive.length)) : 0} /></span>
                                                </div>

                                                <div className="col-xl-12 col-lg-12 n-p-0" style={{color:'black'}}>
                                                <span style={{fontSize:'18px',fontFamily:'sans-serif'}}>Amount: &#x20B9;</span>
                                                    <span className="f26 redfont" style={{color:'black'}}> {moreforty.toLocaleString('en-IN')}</span>
                                                </div>
                                            </div>
                                        </div> : <div className="col cirlce-d " style={{ backgroundColor: '#95d5b2',margin:'-20px',padding:'15px'   }}>
                                            <span className="f16" style={{color:'black'}}><i className="icofont icofont-vehicle-delivery-van cus-i  f24"></i><br /> More than 45 Days</span>
                                            <div className="row col-xl-12 col-lg-12 n-p-0">
                                                <div className="col-xl-12 col-lg-12 n-p-0" style={{color:'black'}}>
                                                    <span style={{fontSize:'16px'}}>Secondary Bills: </span>
                                                    <span className="counter f26 " style={{color:'black'}}><CountUp end={(this.state.morefortyfive.length != '') ? (this.state.morefortyfive.length) : 0} /></span>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 n-p-0" style={{color:'black'}}>
                                                    <span style={{fontSize:'16px'}}>Stockyard Bills: </span>
                                                    <span className="counter f26 greenfont" style={{color:'black'}}><CountUp end={(parseInt(this.state.stockmorefortyfive.length) != '') ? (parseInt(this.state.stockmorefortyfive.length)) : 0} /></span>
                                                </div>

                                                <div className="col-xl-12 col-lg-12 n-p-0" style={{color:'black'}}>
                                                <span style={{fontSize:'18px',fontFamily:'sans-serif'}}>Amount: &#x20B9;</span>
                                                    <span className="f26 redfont" style={{color:'black'}}> {moreforty.toLocaleString('en-IN')}</span>
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                       
                                </div>
                              
                            </div>
                        {/* </div> */}
                    </div>
                    <div>
                        <span style={{marginTop:'20px', marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
                            <h5 style={{ margin: 0 }}>For more information click</h5>
                            <a href="/primarydashboard" style={{ marginLeft: '5px', fontSize: '1.25rem' }}>Primary Payment Dashboard</a>
                        </span>
                    </div>

                </div>

            </div>

        );
    }
}


function getDatePicker() {
    function Datepicker() { }
    Datepicker.prototype.init = function (params) {
        this.eInput = document.createElement("input");
        //this.eInput = this.eInput.setAttribute("class","datepicker");
        this.eInput.value = params.value;
        console.log(this.eInput)
        window.$(".datepicker").datepicker({ dateFormat: "dd/mm/yy" });
    };
    Datepicker.prototype.getGui = function () {
        return this.eInput;
    };
    Datepicker.prototype.afterGuiAttached = function () {
        this.eInput.focus();
        this.eInput.select();
    };
    Datepicker.prototype.getValue = function () {
        return this.eInput.value;
    };
    Datepicker.prototype.destroy = function () { };
    Datepicker.prototype.isPopup = function () {
        return false;
    };
    return Datepicker;
}



function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};


import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const BarChartWithCard = ({ onColumnClick, categories, yAxis, series }) => {

  // Define handlePointClick first
  const handlePointClick = (event) => {
    const { category, y } = event.point;
    onColumnClick({
      category,
      value: y,
      additionalText: 'Additional text here', // Add your additional text or data here
    });
  };

  // Then map over the series
  series = series.map(e => ({
    ...e,
    point: {
      events: {
        click: handlePointClick,
      },
    }
  }));

  const options = {
    chart: {
      type: 'column',
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: categories,
    },
    yAxis: {
      title: {
        text: yAxis,
      },
    },
    series: series,
    plotOptions: {
      column: {
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          // format: '{point.y}',
        },
      },
    },
    tooltip: {
      pointFormat: '<b>{point.y}</b>',
    },
    legend: {
      enabled: false, // Adjust as needed
      align: 'right',
      verticalAlign: 'top',
      layout: 'vertical'
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <HighchartsReact highcharts={Highcharts} options={options} />
  );
};

export default BarChartWithCard;
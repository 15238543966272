import React, { Component } from 'react';
var redirectURL = require('../redirectURL');
var alertIcon_50 = require("../../assets/icons/Accident2.png");
var alertIcon_51 = require("../../assets/icons/voltagewarning_16.jpeg");
//accident alert_16.jpeg
export default class AccidentalProneZoneMap extends Component{
    constructor(props) {
        super(props);
        this.state= {
            rowData: [],
        }
    }
    componentDidMount(){
        // loadDateTimeScript();    
        this.showMap();
    }
    
    onloadPageData(){    
       

        var params = {
        }
        redirectURL.post("/consignments/getAccidentalProneZones",params).then((response)=>{
            console.log("response.data:=>",response.data);
            var rowData = response.data;  
            console.log("rowData:=>" , rowData)      
            this.setState({
                rowData : rowData,
                // loadshow:'show-n',
                // overly:'show-n',
            })
        }).catch((e)=>{
            console.log(e);
        })
    }

    showMap =() =>{
        this.onloadPageData();
    	this.renderMap();        
    }

    renderMap = () => {
    	
        loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMap");
        window.initMap = this.initMap
    }
    
    initMap = () => {
        var mapCenter = new window.google.maps.LatLng(23.30, 80.00);
        
        var map = new window.google.maps.Map(document.getElementById('map_canvas'), {
            zoom: 5,
            center: mapCenter,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_RIGHT
              },            
            disableDefaultUI: true,
            zoomControl: true
          });

          var marker;
          var icon;
          var latitude;
          var longitude;
          for (let i = 0; i < this.state.rowData.length; i++) { 
              console.log("testLatLng : =>", this.state.rowData[i] )
            var location1 = new window.google.maps.LatLng(parseFloat(this.state.rowData[i].latitude),parseFloat(this.state.rowData[i].longitude));
            if(this.state.rowData[i].alert_type === 50){
                icon = alertIcon_50
            }
            else if (this.state.rowData[i].alert_type === 51){
                icon = alertIcon_51
            }
            marker = new window.google.maps.Marker({
                position: location1,
                map: map,
                icon: icon
            });
		}
    }

    render(){
        const dStyles={
            width:'100%',
            height:'500px',
            margin:'0',
    }
    return (
        <div className="card col-xl-12 col-lg-12" style={{padding:"0",marginTop:"2%"}}>
            <div id="map_canvas" className="" style={dStyles}></div>
        </div>
    );
    }
}

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}


// function initMap() {
//     const map = new google.maps.Map(document.getElementById("map"), {
//       zoom: 3,
//       center: { lat: -28.024, lng: 140.887 },
//     });
//     // Create an array of alphabetical characters used to label the markers.
//     const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
//     // Add some markers to the map.
//     // Note: The code uses the JavaScript Array.prototype.map() method to
//     // create an array of markers based on a given "locations" array.
//     // The map() method here has nothing to do with the Google Maps API.
//     const markers = locations.map((location, i) => {
//       return new google.maps.Marker({
//         position: location,
//         label: labels[i % labels.length],
//       });
//     });
//     // Add a marker clusterer to manage the markers.
//     new MarkerClusterer(map, markers, {
//       imagePath:
//         "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
//     });
//   }
//   const locations = [
//     { lat: -31.56391, lng: 147.154312 },
//     { lat: -33.718234, lng: 150.363181 },
//     { lat: -33.727111, lng: 150.371124 },
//     { lat: -33.848588, lng: 151.209834 },
//     { lat: -33.851702, lng: 151.216968 },
//     { lat: -34.671264, lng: 150.863657 },
//     { lat: -35.304724, lng: 148.662905 },
//     { lat: -36.817685, lng: 175.699196 },
//     { lat: -36.828611, lng: 175.790222 },
//     { lat: -37.75, lng: 145.116667 },
//     { lat: -37.759859, lng: 145.128708 },
//     { lat: -37.765015, lng: 145.133858 },
//     { lat: -37.770104, lng: 145.143299 },
//     { lat: -37.7737, lng: 145.145187 },
//     { lat: -37.774785, lng: 145.137978 },
//     { lat: -37.819616, lng: 144.968119 },
//     { lat: -38.330766, lng: 144.695692 },
//     { lat: -39.927193, lng: 175.053218 },
//     { lat: -41.330162, lng: 174.865694 },
//     { lat: -42.734358, lng: 147.439506 },
//     { lat: -42.734358, lng: 147.501315 },
//     { lat: -42.735258, lng: 147.438 },
//     { lat: -43.999792, lng: 170.463352 },
//   ];



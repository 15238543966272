'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "./updatelmstates";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';  
import TransporterGrid from "./transporteravailabilitygrid";
import RailshareGrid from "./railsharegrid";
import PivotdataGrid from "./pivotdatagrid";
import RakeAvailability from './rakeavailability';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class RpAutoRakePlanningHistoricalData extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          consignmentforceclose:Consignmentforceclose,
          consignmentActions:ConsignmentActions,
          UpdateButton:UpdateButton,
          MarkDeliver : MarkDeliver,
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        cltrData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        loadingYards:[],
        dispatches:0,
        rail_share_percentage:0,
        rail_share:10000,
        railTransporters:[],
        pinnedRegionBottomRowData:[],
        railplan:"activet",
        railshare:"",
        pivotdata:"",
        railplanseg:"show-m",
        railshareseg:"show-n",
        pivotdataseg:"show-n",
        clusterShareDf : [],
        totalVins : 0,
        clusterPlantwiseRakes : [],
        clusterPlantwiseVins : [],
        rakeAvailability : [],
        noGpsRakes : [],
        //components : {datepicker:getDatePicker()}
    }
    this.onCellClicked = this.onCellClicked.bind(this);
    //this.changeHandler = this.changeHandler.bind(this);
  }

componentDidMount(){
    loadDateTimeScript();
    var rail_share = (150000*15)/100
    this.setState({
        dispatches : 150000,
        rail_share_percentage : 15,
        rail_share : rail_share
    });
    this.loadRailPlaningSharesData()
    this.onLoadPageData()
}

onLoadPageData(){
    redirectURL.post("/rakeplanning/getRakeAvailability").then((response)=>{
        this.setState({
            rakeAvailability : response.data
        })
    }).catch((e)=>{
        console.log(e);
    })
}

loadRailPlaningSharesData(){;
    this.setState({
        loadshow:"show-m",
        overly:"show-m",
    })
    redirectURL.post("/rakeplanning/getRailPlaningHistoricalData").then((response)=>{
        var rail_cluster_share = response.data;
        //console.log(rail_cluster_share,"railShares");
        var rail_share = this.state.rail_share;
        var haryanaTotal = 0;
        var gujaratTotal = 0;
        var netTotal = 0;
        var netTotalHaryana = 0;
        var netTotalGujarat = 0;
        var netTotalDispatches = 0;
        rail_cluster_share.map(function(r){
            if(rail_share !=undefined && rail_share !='' && r.total_share !=undefined)
            {
                var harCars = Math.round(((parseFloat(rail_share)*parseFloat(r.haryana_share))/100));
                var gujCars = Math.round(((parseFloat(rail_share)*parseFloat(r.gujarat_share))/100));
                r.haryana_cars = harCars;
                r.gujarat_cars = gujCars;
                netTotalHaryana = Math.round(netTotalHaryana+harCars);
                netTotalGujarat = Math.round(netTotalGujarat+gujCars);
                r.net_rail_dispatches = Math.round(((parseFloat(rail_share)*parseFloat(r.total_share))/100));
                netTotalDispatches = netTotalDispatches + harCars + gujCars;
            }
            if(r.cluster !="Total result")
            {
                haryanaTotal = haryanaTotal + parseFloat(r.haryana_share);
                gujaratTotal = gujaratTotal + parseFloat(r.gujarat_share);
                netTotal = netTotal + parseFloat(r.total_share);
            }
        })
        var pinnedBottomRowData = {}
        pinnedBottomRowData['cluster'] = "Total";
        pinnedBottomRowData['haryana_share'] = Math.round(haryanaTotal);
        pinnedBottomRowData['gujarat_share'] = Math.round(gujaratTotal);
        pinnedBottomRowData['total_share'] = Math.round(netTotal);
        pinnedBottomRowData['haryana_cars'] = Math.round(netTotalHaryana);
        pinnedBottomRowData['gujarat_cars'] = Math.round(netTotalGujarat);
        pinnedBottomRowData['net_rail_dispatches'] = Math.round(netTotalDispatches);
        console.log(rail_cluster_share,"rail_cluster_share")
        this.setState({
            cltrData : rail_cluster_share,
            pinnedRegionBottomRowData : [pinnedBottomRowData],
            loadshow:"show-n",
            overly:"show-n",
        })
    }).catch((e)=>{
        console.log(e);
    })
}

createColumns()
{
    var columns=[]
    var railTransporters = this.state.railTransporters;
    columns.push({
        headerName:"Clusters",
        field:"cluster",
        width:160,
    })  
    
    var warehousewise = groupBy(railTransporters, rdata => rdata.plant);
    // console.log("warehousewise ", warehousewise)
    warehousewise.forEach((wares, wkeys) => {
        var defs = {
            headerName:wkeys,
            headerClass:["csgpsBg"],	
        }
        //console.log("defs ", defs)
        if(wares.length > 0)
        {
            // console.log("wares ", wares)
            var warehouses = groupBy(wares, rdata=>rdata.transporter_short_name)
            // console.log("warehouses ", warehouses)
            var warechildern = []
            warehouses.forEach((wh, ky) => {
                warechildern.push({
                    headerName:ky,
                    headerClass:["textAlignCenter"],
                    field:wh[0].plant+"_"+ky,
                    width:100,
                    cellClass:["textAlignCenter"],
                    cellStyle: function(params){
                        if(params.data[wh[0].plant+"_"+ky] == "Yes"){
                            return {borderLeft: '1px solid #ccc',backgroundColor:"yellow"}
                        }
                        else
                        {
                            return {borderLeft: '1px solid #ccc'}
                        }
                    },
                    editable: true,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ["Yes","No"],
                    },
                })
            })
        }
        defs.children = warechildern
        columns.push(defs);
    })
    // console.log("columns ", columns)
    this.setState({
        columns:columns
    })
}
createRowData(){
    var rowdefs = []
    var totalCnt = 0
    var railTransporters = this.state.railTransporters;
    var clustername = groupBy(railTransporters, rdata => rdata.cluster);
    console.log(clustername,"clustername")
    clustername.forEach((trans, transkey) =>{
        var rows = {}
        rows.cluster=transkey
        var rowcnt = 0
        if(trans.length > 0)
        {
            //console.log("trans ", trans)
            var warehousewise = groupBy(trans, rdata => rdata.plant);
            warehousewise.forEach((wares,wkey) => {
                var warehouses = groupBy(wares, rdata=>rdata.transporter_short_name)
                warehouses.forEach((wh,ky) => {
                    // rows[wh[0].warehouse+""+ky] = wh.length
                    // rowcnt = rowcnt+parseInt(wh.length)
                    var vCnt = 0
                    var avlText = "No";
                    if(wh[0].is_available == 1)
                    {
                        avlText = "Yes";
                    }
                    rows[wh[0].plant+"_"+ky] = avlText;                    
                })
            })
        }
      
        rowdefs.push(rows)
    })
    console.log(rowdefs,"rowdefs")
    this.setState({
        rowdefs:rowdefs,
        overly:"show-n",
        loadshow:"show-n",
    })
    //console.log("rowdefs ", rowdefs)
}
railShareData(){
    var qry = {
        // haryana_rakes : this.state.haryana_rakes,
        // gujarat_rakes : this.state.gujarat_rakes,
    }
    redirectURL.post("/consignments/getRailShareData",qry).then(async (response)=>{
        var railShareData = response.data;
        console.log(railShareData,"railShareData")
        var clusterShareDf = [];
        var clusterPlantwiseRakes = [];
        var clusterPlantwiseVins = [];
        if(railShareData !=undefined && railShareData !='')
        {
            var cluster_share_df = railShareData.cluster_share_df;
            if(cluster_share_df !=undefined && cluster_share_df !='')
            {
                clusterShareDf = JSON.parse(cluster_share_df);
                var totalVins = 0;
                clusterShareDf.map(function(c){
                    totalVins = parseInt(totalVins) + parseInt(c.vins_loaded)
                })
            }
            var cluster_plantwise_rake_count = railShareData.cluster_plantwise_rake_count;
            if(cluster_plantwise_rake_count !=undefined && cluster_plantwise_rake_count !='')
            {
                clusterPlantwiseRakes = JSON.parse(cluster_plantwise_rake_count);
            }
            var cluster_plantwise_vin_count = railShareData.cluster_plantwise_vin_count;
            if(cluster_plantwise_vin_count !=undefined && cluster_plantwise_vin_count !='')
            {
                clusterPlantwiseVins = JSON.parse(cluster_plantwise_vin_count);
            }
        }
        this.setState({
            clusterShareDf : clusterShareDf,
            totalVins : totalVins,
            clusterPlantwiseRakes : clusterPlantwiseRakes,
            clusterPlantwiseVins : clusterPlantwiseVins,
            loadshow:"show-n",
            overly:"show-n",
        })
    }).catch((e)=>{
        console.log(e);
    })
}
changeHandler = async (event) => {
    //console.log("Select", event.target.value);
    let name = event.target.name;
    //console.log(name);
    let value = event.target.value;
    this.setState({[name]:value});
    var dispatches = $("#dispatches").val();
    var rail_share_percentage = $("#rail_share_percentage").val();
    if(dispatches != undefined && dispatches !='' && rail_share_percentage != undefined && rail_share_percentage !='')
    {
        this.setState({
            loadshow:'show-m',
            overly:'show-m'
        })
        var rlShare = (parseFloat(dispatches)*parseFloat(rail_share_percentage))/100;
        $("#rail_share").val(rlShare);
        let clusterShares = [];
        var haryanaTotal = 0;
        var gujaratTotal = 0;
        var netTotal = 0;
        var netTotalDispatches = 0;
        var haryana_cars = 0;
        var gujarat_cars = 0;
        var netTotalHaryana = 0;
        var netTotalGujarat = 0;
        this.gridApi.forEachNode(node => clusterShares.push(node.data));
        var csdShares = [];
        clusterShares.map(function(r){
            if(rlShare !=undefined && rlShare !='' && r.total_share !=undefined)
            {
                var net_rail_dispatches = "";
                var gujarat_share = r.gujarat_share;
                if(gujarat_share !=undefined && gujarat_share !='')
                {
                    if(r.cluster !="Total result")
                    {
                        gujarat_share = parseFloat(gujarat_share);
                    }
                }
                else
                {
                    gujarat_share = 0;
                }
                var haryana_share = r.haryana_share;
                if(haryana_share !=undefined && haryana_share !='')
                {
                    if(r.cluster !="Total result")
                    {
                        haryana_share = parseFloat(haryana_share);
                    }
                }
                else
                {
                    haryana_share = 0;
                }
                console.log(haryana_share,"haryana_share")
                var total_share = (gujarat_share+haryana_share);
                if(r.cluster !="Total result")
                {
                    haryanaTotal = haryanaTotal + haryana_share;
                    gujaratTotal = gujaratTotal + gujarat_share;
                    netTotal = netTotal + total_share;
                }
                var net_share = (gujarat_share+haryana_share);
                console.log(net_share,"net_share")
                if(net_share !=undefined)
                {
                    net_rail_dispatches = (parseFloat(rlShare)*parseFloat(net_share))/100;
                }
                if(haryana_share > 0 )
                {
                    haryana_cars = Math.round(((parseFloat(rlShare)*parseFloat(haryana_share))/100));
                }
                if(gujarat_share > 0)
                {
                    gujarat_cars = Math.round(((parseFloat(rlShare)*parseFloat(gujarat_share))/100));
                }
                netTotalDispatches = netTotalDispatches + haryana_cars + gujarat_cars;
                console.log(netTotalDispatches,r.cluster,"netTotalDispatches")
                netTotalHaryana = Math.round(netTotalHaryana+haryana_cars);
                netTotalGujarat = Math.round(netTotalGujarat+gujarat_cars);
                csdShares.push({
                    cluster:r.cluster,
                    gujarat_share:gujarat_share,
                    haryana_share:haryana_share,
                    haryana_cars:haryana_cars,
                    gujarat_cars:gujarat_cars,
                    net_rail_dispatches:Math.round(net_rail_dispatches),
                    total_share:total_share,
                })
                haryana_cars = 0;
                gujarat_cars = 0;
            }
        })
        //console.log(csdShares,"csdShares");
        var pinnedBottomRowData = {}
        pinnedBottomRowData['cluster'] = "Total";
        pinnedBottomRowData['haryana_share'] = Math.round(haryanaTotal);
        pinnedBottomRowData['gujarat_share'] = Math.round(gujaratTotal);
        pinnedBottomRowData['total_share'] = Math.round(netTotal);
        pinnedBottomRowData['haryana_cars'] = Math.round(netTotalHaryana);
        pinnedBottomRowData['gujarat_cars'] = Math.round(netTotalGujarat);
        pinnedBottomRowData['net_rail_dispatches'] = Math.round(netTotalDispatches);
        this.setState({
            rail_share:rlShare,
            loadshow:'show-n',
            overly:'show-n',
            pinnedRegionBottomRowData : [pinnedBottomRowData]
        });
        this.gridApi.setRowData(csdShares)
    }
}
onCellUpdateData = async(event) => {
    let clusterShares = [];
    this.gridApi.forEachNode(node => clusterShares.push(node.data));
    var csdShares = [];
    var rail_share = this.state.rail_share;
    var haryanaTotal = 0;
    var gujaratTotal = 0;
    var netTotal = 0;
    var haryana_cars = 0;
    var gujarat_cars = 0;
    var netTotalDispatches = 0;
    var netTotalHaryana = 0;
    var netTotalGujarat = 0;
    clusterShares.map(function(r){
        var net_rail_dispatches = "";
        var gujarat_share = r.gujarat_share;
        if(gujarat_share !=undefined && gujarat_share !='')
        {
            gujarat_share = parseFloat(gujarat_share);
        }
        else
        {
            gujarat_share = 0;
        }
        var haryana_share = r.haryana_share;
        if(haryana_share !=undefined && haryana_share !='')
        {
            haryana_share = parseFloat(haryana_share);
        }
        else
        {
            haryana_share = 0;
        }
        var total_share = (gujarat_share+haryana_share);
        //console.log(total_share,"total_share")
      
        if(r.cluster !="Total result")
        {
            var net_share = (gujarat_share+haryana_share);
            console.log(net_share,"net_share")
            if(rail_share !=undefined && rail_share !='' && net_share !=undefined)
            {
                net_rail_dispatches = (parseFloat(rail_share)*parseFloat(net_share))/100;
            }
            if(rail_share !=undefined && haryana_share > 0 )
            {
                haryana_cars = Math.round(((parseFloat(rail_share)*parseFloat(haryana_share))/100));
            }
            if(rail_share !=undefined && gujarat_share > 0)
            {
                gujarat_cars = Math.round(((parseFloat(rail_share)*parseFloat(gujarat_share))/100));
            }
            console.log(net_rail_dispatches,"net_rail_dispatches")
            haryanaTotal = haryanaTotal + haryana_share;
            gujaratTotal = gujaratTotal + gujarat_share;
            netTotal = netTotal + total_share;
            netTotalDispatches = netTotalDispatches + haryana_cars + gujarat_cars;
            netTotalHaryana = Math.round(netTotalHaryana+haryana_cars);
            netTotalGujarat = Math.round(netTotalGujarat+gujarat_cars);
        }
        csdShares.push({
            cluster:r.cluster,
            gujarat_share:gujarat_share,
            haryana_share:haryana_share,
            haryana_cars:haryana_cars,
            gujarat_cars:gujarat_cars,
            net_rail_dispatches:Math.round(net_rail_dispatches),
            total_share:total_share,
        })
        haryana_cars = 0;
        gujarat_cars = 0;
    })
    console.log(csdShares,"csdShares");
    var pinnedBottomRowData = {}
    pinnedBottomRowData['cluster'] = "Total";
    pinnedBottomRowData['haryana_share'] = Math.round(haryanaTotal);
    pinnedBottomRowData['gujarat_share'] = Math.round(gujaratTotal);
    pinnedBottomRowData['total_share'] = Math.round(netTotal);
    pinnedBottomRowData['haryana_cars'] = Math.round(netTotalHaryana);
    pinnedBottomRowData['gujarat_cars'] = Math.round(netTotalGujarat);
    pinnedBottomRowData['net_rail_dispatches'] = Math.round(netTotalDispatches);
    this.gridApi.setRowData(csdShares)
    this.setState({
        pinnedRegionBottomRowData : [pinnedBottomRowData]
    })
}
checkRakeAvailability = async() => {
    var rakeAvailability = this.state.rakeAvailability;
    if(rakeAvailability.length > 0)
    {
        var noGpsRakes = [];
        var currentDate = moment.parseZone().format('YYYY-MM-DD');
        rakeAvailability.map(function(r){
            if(r.active_trip == 0)
            {
                var timestamp = r.timestamp;
                if(timestamp !=undefined && timestamp !='')
                {
                    timestamp = moment.parseZone((timestamp)).format('YYYY-MM-DD');
                    if(moment(timestamp).isSame(currentDate) != true)
                    {
                        noGpsRakes.push(r);
                    }
                }
            }
        });
        console.log(noGpsRakes,"noGpsRakes")
        if(noGpsRakes.length > 0)
        {
            this.setState({
                noGpsRakes : noGpsRakes,
                overly :"show-m",
                bulkslide : "slider-translate-40p"
            });
        }
        else
        {
            this.generateRailPlan(0);
        }
    }
}
generateRailPlan = async(event) => {
    this.setState({
        loadshow:"show-m",
        overly:"show-m",
    });
    let saveData = new FormData();
    let clusterShares = [];
    this.gridApi.forEachNode(node => clusterShares.push(node.data));
    var csdShares = [];
    var rail_share = this.state.rail_share;
    var haryanaTotal = 0;
    var gujaratTotal = 0;
    var netTotal = 0;
    var netTotalDispatches = 0;
    var rowTotal = $("#rail_share").val();
    clusterShares.map(function(r){
        var net_rail_dispatches = "";
        var gujarat_share = r.gujarat_share;
        if(gujarat_share !=undefined && gujarat_share !='')
        {
            gujarat_share = parseFloat(gujarat_share);
        }
        else
        {
            gujarat_share = 0;
        }
        var haryana_share = r.haryana_share;
        if(haryana_share !=undefined && haryana_share !='')
        {
            haryana_share = parseFloat(haryana_share);
        }
        else
        {
            haryana_share = 0;
        }
        var total_share = (gujarat_share+haryana_share);
        console.log(total_share,"total_share")
 
        if(r.cluster !="Total result")
        {
            haryanaTotal = haryanaTotal + haryana_share;
            gujaratTotal = gujaratTotal + gujarat_share;
            netTotal = netTotal + total_share;
        }
        var gujaratCars=0;
        var haryanaCars=0;
        if(rowTotal > 0)
        {
            if(gujarat_share > 0 )
            {
                gujaratCars = (rowTotal*gujarat_share)/100;
            }
            if(haryana_share > 0)
            {
                haryanaCars = (rowTotal*haryana_share)/100;
            }
        }
        if(r.cluster !="Total result")
        {
            csdShares.push({
                loading_cluster:"Gujarat",
                unloading_cluster:r.cluster,
                share:parseFloat(gujarat_share),
                cars:Math.round(gujaratCars),
            })
            csdShares.push({
                loading_cluster:"Haryana",
                unloading_cluster:r.cluster,
                share:parseFloat(haryana_share),
                cars:Math.round(haryanaCars)
            })
        }
        gujaratCars = 0;
        haryanaCars = 0;
    })
    console.log(csdShares,"csdShares");
    if(csdShares.length > 0)
    {
        var RailTotal = Math.round(netTotal);
        if(RailTotal == 100)
        {
            saveData.append("ignoreRakes", event);
            saveData.append("clusterShares", JSON.stringify(csdShares));
            saveData.append("carsTotal", rowTotal);
            saveData.append("user_name", localStorage.getItem('username'))
            saveData.append("email", localStorage.getItem('email'))
            saveData.append("user_role", localStorage.getItem('roles'))
            console.log(saveData,saveData);
            this.setState({
                loadshow:'show-m',
                overly:'show-m',
            });
            redirectURL.post("/rakeplanning/generateNewRakePlan",saveData).then((response)=>{
                console.log(response,"responseFromProcess")
                var statusCode = response.data.status;
                if(statusCode == 8)
                {
                    this.setState({
                        processErrMsg : response.data.message,
                        processErrShow : 1,
                        transactionId : response.data.transactionId,
                        droppedList : response.data.droppedList,
                        statusCode : statusCode,
                        loadshow:'show-n',
                        overly:'show-n',
                    });
                }
                else if(statusCode > 3 && statusCode != 8)
                {
                    if(statusCode == 11)
                    {
                        var processMsg = "Use the standard input data file template for uploading loads";
                    }
                    else
                    {
                        var processMsg = response.data.message;
                    }
                    this.setState({
                        processErrMsg : response.data.message,
                        processErrShow : 1,
                        transactionId : response.data.transactionId,
                        statusCode : statusCode,
                        loadshow:'show-n',
                        overly:'show-n',
                    });
                }
                else
                {
                    this.setState({                    
                        basicTitle : response.data.message,
                        basicType : "success",
                        show : true,
                        loadshow:'show-n',
                        overly:'show-n',
                        bulkslide:"",
                    });
                }
                window.location.href="/rprakeplansummary";
            });
        }
        else
        {
            this.setState({
                basicTitle:"Net Share should be 100%.",
                basicType : "warning",
                show : true,
                loadshow:"show-n",
                overly:"show-m",
            })
        }
    }
}
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}

onGridReady1 = params => {
    this.gridApi1 = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi1 = params.columnApi;
};
onGridState1 = () =>{
    //console.log(this.gridApi);
   
    /*Get  Current Columns State and Store in this.colState */
      this.colState1 = this.gridColumnApi1.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
      this.rowGroupState1 = this.gridColumnApi1.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
     * User should enable the Pivot mode.*/
      this.pivotColumns1 = this.gridColumnApi1.getPivotColumns();
               
    /*Get Current Filter State and Store in window.filterState */
      window.filterState1 = this.gridApi1.getFilterModel();
      this.gridApi1.setFilterModel(window.filterState1);
}

closeAlert = () => {
    this.setState({
        show: false
    });
}

onCellClicked(e)
{

}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    
}

addNewRow()
{
    // console.log(this.gridApi);
    this.gridApi.insertItemsAtIndex(0,[{cluster:"",haryana_share:"",gujarat_share:"",total_share:"",net_rail_dispatches:""}])
    // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}
onClickDataExport(){
    var params = {
        columnKeys: ['id', 'state', 'state_code', 'zone', 'tax']
    };
    this.gridApi.exportDataAsCsv(params);
}
showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate"
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}
onClickTab(pageType){
    if(pageType == "railplan")
    {
        var railplan = "activet";
        var railshare = "";
        var pivotdata = "";
        var railplanseg = "show-m";
        var railshareseg = "show-n";
        var pivotdataseg = "show-n";
        var pType = 1;
    }
    else if(pageType == "railshare")
    {
        var railplan = "";
        var railshare = "activet";
        var pivotdata = "";
        var railplanseg = "show-n";
        var railshareseg = "show-m";
        var pivotdataseg = "show-n";
        var pType = 2;
    }
    else if(pageType == "pivotdata")
    {
        var railplan = "";
        var railshare = "";
        var pivotdata = "activet";
        var railplanseg = "show-n";
        var railshareseg = "show-n";
        var pivotdataseg = "show-m";
        var pType = 3;
    }
    this.setState({
        railplan:railplan,
        railshare:railshare,
        pivotdata:pivotdata,
        railplanseg:railplanseg,
        railshareseg:railshareseg,
        pivotdataseg:pivotdataseg,
        pageType:pType
    })
}
  render() {
    var rail_share = this.state.rail_share;
    var columnwithDefs = [      
        {
            headerName: "Cluster",
            field: "cluster",
            width:140,
            filter: true,
            resizable: true,
            editable:true,           
        },
        {
            headerName: "Haryana Share",
            field: "haryana_share",
            width:140,
            filter: true,
            resizable: true,
            editable:true,
            valueGetter: function(params){
                if(params.data.haryana_share !=undefined && params.data.haryana_share !='')
                {
                    return (params.data.haryana_share)
                }
                else
                {
                    return params.data.haryana_share;
                }
            }
        }, 
        {
            headerName: "Gujarat Share",
            field: "gujarat_share",
            width:140,
            filter: true,
            resizable: true,
            editable:true,
            valueGetter: function(params){
                if(params.data.gujarat_share !=undefined && params.data.gujarat_share !='')
                {
                    return (params.data.gujarat_share)
                }
                else
                {
                    return params.data.gujarat_share;
                }
            }
        },
        {
            headerName: "Net Share",
            field: "total_share",
            width:140,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                if(params.data.total_share !=undefined && params.data.total_share !='')
                {
                    try{
                        return (params.data.total_share).toFixed(2)
                    }
                    catch(e){

                    }
                }
            }
        },
        {
            headerName: "Haryana Cars",
            field: "haryana_cars",
            width:140,
            filter: true,
            resizable: true,
            editable:true,
        }, 
        {
            headerName: "Gujarat Cars",
            field: "gujarat_cars",
            width:140,
            filter: true,
            resizable: true,
            editable:true,
        },
        {
            headerName: "Net Dispatches",
            field: "net_rail_dispatches",
            width:140,
            filter: true,
            resizable: true,
            editable:false,
        },
    ]

    var columnwithDefsForNoGps = [   
        {
            headerName: "Rake No",
            field: "rake_no",
            width:150,
            filter: true,
            resizable: true,
            editable:false,     
        },
        {
            headerName: "Rake Capacity",
            field: "rake_capacity",
            width:120,
            filter: true,
            resizable: true,
            editable:false,
        }
    ]
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="card col-xl-12">
            <div className="card-header">
                <h5>
                    <i className="icofont icofont-train-line cus-i"></i> <span>Auto Rake Planning</span>
                    {/* <button className="btn btn-success" style={{float:"right"}} onClick={this.addNewRow.bind(this)}>Add New Cluster</button> */}
                    {/* <button className="btn btn-info" style={{float:"right",marginRight:"10px"}} onClick={this.showBulkUpload.bind(this)}>Import Data</button>
                    <button className="btn btn-danger" style={{float:"right",marginRight:"10px"}} onClick={this.onClickDataExport.bind(this)}>Export Data</button> */}
                    <button className="btn btn-info" style={{float:"right",marginLeft:"2%"}} onClick={this.checkRakeAvailability.bind(this)}>Save and Generate Plan</button>
                    <a href="/rakeavailability" className="btn btn-danger" style={{float:"right"}}>Rake Availability</a>
                </h5>
            </div>
            <div className="card-body col-xl-12 col-lg-12">
                <div className={"row"}>
                    <div className="col-xl-12 col-lg-12">
                        <form method="POST" id="dispform" className="row col-xl-12 col-lg-12 theme-form">
                            <div className="mt-20p">
                                <div className="row mb-10p">
                                    <div className="col-xl-4 col-lg-4">
                                        <label className="col-xl-12 col-lg-12" style={{marginTop: "7px"}}>Dispatches</label> 
                                        <div className="col-xl-12 col-lg-12">
                                            <input type="text" autoComplete="off" class="form-control" name="dispatches" id="dispatches" value={this.state.dispatches} onChange={this.changeHandler.bind(this)} />
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-4">
                                        <label className="col-xl-12 col-lg-12" style={{marginTop: "7px"}}>Rail Share (%)</label> 
                                        <div className="col-xl-12 col-lg-12">
                                            <input type="text" autoComplete="off" class="form-control" name="rail_share_percentage" id="rail_share_percentage" value={this.state.rail_share_percentage} onChange={this.changeHandler.bind(this)} />
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-4">
                                        <label className="col-xl-12 col-lg-12" style={{marginTop: "7px"}}>Rail Share</label> 
                                        <div className="col-xl-12 col-lg-12">
                                            <input type="text" autoComplete="off" class="form-control" name="rail_share" id="rail_share" readOnly value={this.state.rail_share} />
                                        </div>
                                    </div>

                                    {/* <div className="col-xl-2 col-lg-2">
                                        <label className="col-xl-12 col-lg-12" style={{marginTop: "7px"}}>Haryana Frequency Rakes</label> 
                                        <div className="col-xl-12 col-lg-12">
                                            <input type="text" autoComplete="off" class="form-control" name="haryana_rakes" id="haryana_rakes" value={this.state.haryana_rakes}  onChange={this.changeHandler.bind(this)} />
                                        </div>
                                    </div>

                                    <div className="col-xl-2 col-lg-2">
                                        <label className="col-xl-12 col-lg-12" style={{marginTop: "7px"}}>Gujarat Frequency Rakes</label> 
                                        <div className="col-xl-12 col-lg-12">
                                            <input type="text" autoComplete="off" class="form-control" name="gujarat_rakes" id="gujarat_rakes" value={this.state.gujarat_rakes}  onChange={this.changeHandler.bind(this)} />
                                        </div>
                                    </div> */}
                                </div>
                            </div>                            
                        </form>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.showGridData}>    
                        <AgGridReact
                            modules={this.state.modules}
                            columnDefs={columnwithDefs}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.cltrData}
                            enableCharts={false}
                            // autoGroupColumnDef={this.state.autoGroupColumnDef}
                            onGridReady={this.onGridReady}
                            onGridState={this.onGridState}
                            frameworkComponents={this.state.frameworkComponents}
                            statusBar={this.state.statusBar}
                            sideBar={this.state.sideBar}
                            stopEditingWhenGridLosesFocus= {true}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={false}
                            pinnedBottomRowData={this.state.pinnedRegionBottomRowData}
                            gridOptions={{
                                context: { componentParent: this },
                                getRowStyle: function (params) {
                                        if (params.node.rowPinned) {
                                    return { 'font-weight': 'bold','font-size':'16px' };
                                    }
                                },
                                suppressRowTransform: true,
                            }}
                            // components={this.state.components}
                            enableRangeSelection= {true}
                            //onCellClicked={this.onCellClicked}
                            onCellEditingStopped={this.onCellUpdateData.bind(this)}
                            />
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 mt-20p">
                        <button className="btn btn-primary" style={{float:"right"}} onClick={this.checkRakeAvailability.bind(this)}>Save and Generate Plan</button>
                    </div>
                </div>
            </div>
        </div>

        
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">Rakes not having GPS Data</h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <div className="" style={{marginTop:"1em"}}>
                        <p style={{marginBottom:"1em"}}>{this.state.processErrMsg}</p>
                            <div id="myGrid" style={{width:"100%",height:"300px",marginBottom:"1em"}} className={"ag-theme-balham "+this.state.showGridData}>    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefsForNoGps}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.noGpsRakes}
                                enableCharts={false}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady1}
                                onGridState={this.onGridState1}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={false}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                enableRangeSelection= {true}
                                //onCellClicked={this.onCellClicked}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                />
                        </div>
                        
                        <a href="/sndmanuallocations" target="_blank" className="btn btn-danger">Update GPS Location</a>
                        <button type="button" style={{marginLeft:"3%"}} onClick={this.generateRailPlan.bind(this,1)} className="btn btn-success">Ignore and Generate Plan</button>                        
                    </div>
                </div>
            </div>
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import { TabContent, TabPane, Nav, NavItem, NavLink,Row, Col } from 'reactstrap';
import classnames from 'classnames';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import { getDDMMYYYY, getDDMMMYYYY,getHyphenDDMMMYYYY,
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault } from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CSVFileValidator from 'csv-file-validator'

import FullCalendar, { parseDragMeta } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from "@fullcalendar/timegrid";
import Mllbatchlegs from "./mllbatchlegs";
import SummaryDashboard from "./mllsummaryComponent";
import GPSDashboard from "./mlldevicedashboardComponent";
import KPIDashboard from "./mllkpidashboardComponent";
import DamageAnalysis from "./mlldamageanalysisComponent";
$.datetimepicker.setLocale('en');    
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class MLLRailSummaryComponent extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			active_tab: '1',
            active_tab_bottom_left: '1',
            active_tab_icon: '1',
            active_tab_right_align: '1',
            active_tab_vertical: '1',
            active_tab_pill: '1',
            active_tab_pill_icon: '1',
            active_tab_dark_color: '1',
            active_tab_primary: '1',
            active_tab_secondary: '1',
			
        };
        
		this.toggle = this.toggle.bind(this);
	}
	

	toggle(tab) {
        if (this.state.active_tab !== tab) {
            this.setState({
                active_tab: tab
            });
        }

    }

    render(){
		
        return (
            
    		<div className="container-fluid">
				
            	<div className="row">
                    <div className="col-xl-12 col-lg-12">
							
                        <Nav tabs className={'tabs-color'}>
                            <NavItem >
                                <NavLink
                                    className={classnames({ active: this.state.active_tab === '1' })}
                                    onClick={() => { this.toggle('1'); }} >
                                    Vins Dashboard
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.active_tab === '2' })}
                                            onClick={() => { this.toggle('2'); }} >
                                    GPS Dashboard
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.active_tab === '3' })}
                                            onClick={() => { this.toggle('3'); }}>
                                    KPI Dashboard
                                </NavLink>
                            </NavItem>
                            {/* Damage Analysis (Historical) */}
                            {(localStorage.getItem("roles").indexOf("MahindraAdmin")  >= 0)?
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.active_tab === '4' })}
                                            onClick={() => { this.toggle('4'); }}>
                                    Damage Analysis (Historical)
                                </NavLink>
                            </NavItem>
                            :""}
                            
                        </Nav>

                        <TabContent activeTab={this.state.active_tab}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col sm="12">
                                        <SummaryDashboard />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col sm="12">
                                        <GPSDashboard />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="3">
                                <Row>
                                    <Col sm="12">
                                        <KPIDashboard />
                                    </Col>
                                </Row>
                            </TabPane>
                            {(localStorage.getItem("roles").indexOf("MahindraAdmin")  >= 0)?
                            <TabPane tabId="4">
                                <Row>
                                    <Col sm="12">
                                        <DamageAnalysis />
                                    </Col>
                                </Row>
                            </TabPane>
                            :""}
                        </TabContent>
                    </div>	
				 </div>

            </div>
              
        );
    }
}

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';

import CountUp from 'react-countup';
import redirectURL from '../redirectURL';
import Highcharts from 'highcharts';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';
import MllColumnDrilldownChart from "./mllcolumndrilldownchart";
import MllHoldVinsColumnDrilldownChart from "./mllholdvincolumndrilldownchart";
import MllDamageColumnDrilldownChart from "./mlldamagecolumndrilldownchart";
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';

window.jQuery = $;
window.$ = $;
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");


export default class MllKPIDashboard extends Component{
    constructor(props) {
        super(props);
        this.state={
            modules: AllModules,
            // truckData:this.props.truckInfo,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            
            truckNumList:"",
            loadshow:'show-n',
            truckLocation:"",
            replaced_date_time:"",
            route_details:"",
            rowData:"",
            showTrucksList:"show-n",
            modules:AllModules,
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
            route_info:"",
			rowData: null,
			animateRows: true,
            consignment_details:"",
            dept_code:"",
            replacement_truck:"",
            pagetitle:"",
            showonlytransport:'show-m',
            reason:'',
            overly :"show-n",
            filteredData : [],
            sourceplants:[],
            sources:[],
            totaldevices:0,
            assignloadingyard:0,
            handovers:0,
            rakedevices:0,
            issueba:0,
            rsoretrivals:0,
            totaldevicesarr:[],
            intransitloading:0,
            atloading:0,
            handoversarr:[],
            rakedevicesarr:[],
            issuebaarr:[],
            rsoretrivalsarr:[],
            atplant:0,
            atplantarr:[],
            retrivedloading : 0,
            intransitplant : 0,
            atdestination : 0,
            retriveddest : 0,
            intransitrso : 0,
            intransitdealer : 0,
            atrso : 0,
            retrivedloadingarr : [],
            intransitloadingarr:[],
            atloadingarr:[],
            intransitplantarr : [],
            atdestinationarr : [],
            retriveddestarr : [],
            intransitrsoarr : [],
            intransitdealerarr : [],
            atrsoarr : [],
            shiptosource:0,
            shiptosourcearr:[],
            user_level:"ALL",
            info_level:{value:"ALL", label:"ALL"},
            transitcats:[],
            transitseries:[],
            transitseriesavg:"",
            transitseriesexp:"",
            transitdrilldownlanes:[],
            lanes:[],
            lanelegs:[],
            drilldownseries:[],
            reporttype:{value:"ALL", label:"ALL"},
            damageseries:[],
            holdvinseries:[],
            autocloseseries:[],
            autoclosecats:[],
            holdvinlegs:[],
            damagelegs:[],
            autocloselegs:[]
        };
        this.onLoadChart = this.onLoadChart.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount = async ()=>{
        loadDateTimeScript();
        this.setState({
            //overly : "show-m",
            loadshow:'show-n',
        })

        //$('#replacement-submit').prop("disabled",true);
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        var pagetitle="KPI Dashboard "
        var dept_code = "SNDG";
        var israil=0;
       
       
        this.setState({
            
            eventAction:eventAction,
            dept_code:dept_code,
            pagetitle:pagetitle
        });
        // user_level : 'ALL' - For now, will 
        // info_level : 'ALL' / 'LANE' / 'LEG' / 'BA'

        if(localStorage.getItem("roles").indexOf("Plant")  >= 0)
        {
            var userlevel = "PLANT"
        }
        else if(localStorage.getItem("roles").indexOf("Loading Officer")  >= 0)
        {
            var userlevel = "LOADING"
        }
        else if(localStorage.getItem("roles").indexOf("Unloading Officer")  >= 0)
        {
            var userlevel = "UNLOADING"
        }
        else if(localStorage.getItem("roles").indexOf("Plant")  >= 0)
        {
            var userlevel = "RSO"
        }
        else{
            var userlevel = "ALL"
        }
        this.setState({
            user_level:userlevel
        })
        var parameters={
            user_level : userlevel,
            info_level : this.state.info_level.value
        }
       
        await redirectURL.post("/mllconsignments/kpilanes", parameters)
        .then((response) => {
            console.log("Lanes ", response.data)
            try{
                var lanes = JSON.parse(response.data.transit_summary)
                this.setState({
                    lanes:lanes
                })
            }
            catch(e){

            }
            
        })
        
        await redirectURL.post("/mllconsignments/kpilanelegs", parameters)
        .then((response) => {
            console.log("Lanes Legs ", response.data)
            try{
                var lanelegs = JSON.parse(response.data.transit_summary)
                var holdvinlegs = JSON.parse(response.data.hold_summary)
                var damagelegs = JSON.parse(response.data.damage_summary)
                var autocloselegs = JSON.parse(response.data.auto_close_summary)
                
                this.setState({
                    lanelegs:lanelegs,
                    holdvinlegs:holdvinlegs,
                    damagelegs:damagelegs,
                    autocloselegs:autocloselegs
                })
            }
            catch(e){

            }
        })
        
        await this.onLoadChart(parameters)
    }

    onLoadChart(parameters)
    {
        
        redirectURL.post("/mllconsignments/kpidashboardapi",parameters)
        .then(async (response) => {
            try{

                // console.log("Resposne ", response.data)
                if(this.state.reporttype.value == "ALL")
                {
                    var lanes = this.state.lanes
                    var lanelegs = this.state.lanelegs
                }
                
                if(this.state.reporttype.value == "LANE")
                {
                    var lanelegs = this.state.lanelegs
                }
                // console.log("lanes here ",lanes)
                // console.log("lanelegs here ",lanelegs)
                var transit_summary = JSON.parse(response.data.transit_summary)
                // var transit_summary = [{"user_level":"ALL","info_level":"ALL","data_grp":"ALL","avg_transit_time":804258,"avg_transit_time_text":"9 days, 7 hours, 24 minutes","exp_transit_time":768208,"exp_transit_time_text":"8 days, 21 hours, 23 minutes","no_of_vins":58}]
                // var lanes = [{"user_level":"ALL","info_level":"ALL","data_grp":"ALL","avg_transit_time":804258,"avg_transit_time_text":"9 days, 7 hours, 24 minutes","exp_transit_time":768208,"exp_transit_time_text":"8 days, 21 hours, 23 minutes","no_of_vins":58}]
                // var lanelegs = [{"user_level":"ALL","info_level":"ALL","data_grp":"ALL","avg_transit_time":804258,"avg_transit_time_text":"9 days, 7 hours, 24 minutes","exp_transit_time":768208,"exp_transit_time_text":"8 days, 21 hours, 23 minutes","no_of_vins":58}]

                // console.log("transit_summary ", transit_summary)
                var aocats = []
                var aoseries = []
                
                if(this.state.reporttype.value == "ALL")
                {
                    if(transit_summary.length > 0)
                    {
                        aocats.push(transit_summary[0].data_grp)
                        aoseries.push({
                            name:"Average Transit Time",
                            // data:[(transit_summary[0].avg_transit_time/(60*60*24))],
                            data:[{
                                name:"Average Transit Time",
                                y:Math.floor(transit_summary[0].avg_transit_time/(60*60)),
                                test:transit_summary[0].avg_transit_time_text,
                                vins:transit_summary[0].no_of_vins,
                                drilldown:"Average Transit Time"
                            }],
                            
                            dataLabels: {
                                enabled: true
                            }
                        },{
                            name:"Expected Transit Time",
                            // data:[(transit_summary[0].exp_transit_time/(60*60*24))],
                            data:[{
                                name:"Expected Transit Time",
                                y:Math.floor(transit_summary[0].exp_transit_time/(60*60)),
                                test:transit_summary[0].exp_transit_time_text,
                                vins:transit_summary[0].no_of_vins
                            }],
                            dataLabels: {
                                enabled: true
                            }
                        })
                    }
                }
                
                if(this.state.reporttype.value == "LANE")
                {
                    if(transit_summary.length > 0)
                    {
                        aoseries.push({
                            name:transit_summary[0].data_grp,
                            // data:[(transit_summary[0].avg_transit_time/(60*60*24))],
                            data:[{
                                name:"Average Transit Time",
                                y:Math.floor(transit_summary[0].avg_transit_time/(60*60)),
                                test:transit_summary[0].avg_transit_time_text,
                                vins:transit_summary[0].no_of_vins,
                                drilldown:"Average Lane Transit Legs"
                            },
                            {
                                name:"Expected Transit Time",
                                y:Math.floor(transit_summary[0].exp_transit_time/(60*60)),
                                test:transit_summary[0].exp_transit_time_text,
                                vins:transit_summary[0].no_of_vins,
                                color:"#ff5370"
                            }],
                            
                            dataLabels: {
                                enabled: true
                            }
                        }
                        // ,{
                        //     name:"Expected Transit Time",
                        //     // data:[(transit_summary[0].exp_transit_time/(60*60*24))],
                        //     data:[{
                        //         name:"Expected Transit Time",
                        //         y:Math.floor(transit_summary[0].exp_transit_time/(60*60)),
                        //         test:transit_summary[0].exp_transit_time_text,
                        //         vins:transit_summary[0].no_of_vins
                        //     }],
                        //     dataLabels: {
                        //         enabled: true
                        //     }
                        // }
                        )
                    }
                    
                }

                if(this.state.reporttype.value == "LEG")
                {
                    if(transit_summary.length > 0)
                    {
                        transit_summary.map((item) => {
                            aoseries.push({
                                name:item.data_grp,
                                // data:[(transit_summary[0].avg_transit_time/(60*60*24))],
                                data:[{
                                    name:item.data_grp,
                                    y:Math.floor(item.avg_transit_time/(60*60)),
                                    test:item.avg_transit_time_text,
                                    vins:item.no_of_vins
                                }],
                                dataLabels: {
                                    enabled: true
                                }
                            })
                        })
                    }
                    
                }
                if(this.state.reporttype.value == "BA")
                {
                    if(transit_summary.length > 0)
                    {
                        aocats.push(transit_summary[0].ba_name)
                        aoseries.push({
                            name:transit_summary[0].ba_name,
                            // data:[(transit_summary[0].avg_transit_time/(60*60*24))],
                            data:[{
                                name:"Average Transit Time",
                                y:Math.floor(transit_summary[0].avg_transit_time/(60*60)),
                                test:transit_summary[0].avg_transit_time_text,
                                vins:transit_summary[0].no_of_vins,
                                drilldown:"Average Transit Time"
                            },
                            {
                                name:"Expected Transit Time",
                                y:Math.floor(transit_summary[0].exp_transit_time/(60*60)),
                                test:transit_summary[0].exp_transit_time_text,
                                vins:transit_summary[0].no_of_vins
                            }],
                            
                            dataLabels: {
                                enabled: true
                            }
                        }
                        // ,{
                        //     name:"Expected Transit Time",
                        //     // data:[(transit_summary[0].exp_transit_time/(60*60*24))],
                        //     data:[{
                        //         name:"Expected Transit Time",
                        //         y:Math.floor(transit_summary[0].exp_transit_time/(60*60)),
                        //         test:transit_summary[0].exp_transit_time_text,
                        //         vins:transit_summary[0].no_of_vins
                        //     }],
                        //     dataLabels: {
                        //         enabled: true
                        //     }
                        // }
                        )
                    }
                }
                //LANES DATA
                var laneseries=[]
                if(this.state.reporttype.value == "ALL")
                {
                    var lanetransit_summary = lanes
                    if(lanetransit_summary.length > 0)
                    {
                        laneseries.push({
                            name:lanetransit_summary[0].data_grp,
                            id:"Average Transit Time",
                            // data:[(transit_summary[0].avg_transit_time/(60*60*24))],
                            data:[{
                                name:"Average Lane Transit Time",
                                y:Math.floor(lanetransit_summary[0].avg_transit_time/(60*60)),
                                test:lanetransit_summary[0].avg_transit_time_text,
                                vins:lanetransit_summary[0].no_of_vins,
                                drilldown:"Average Lane Transit Legs"
                            },
                            {
                                name:"Expected Lane Transit Time",
                                y:Math.floor(lanetransit_summary[0].exp_transit_time/(60*60)),
                                test:lanetransit_summary[0].exp_transit_time_text,
                                vins:lanetransit_summary[0].no_of_vins
                            }],
                            
                            // dataLabels: {
                            //     enabled: true,
                            //     format:"{point.y}"
                            // }
                        })

                        // series:[{
                        //     name: 'Microsoft Internet Explorer',
                        //     id: 'Average Transit Time',
                        //     colorByPoint: true,
                        //     data: [{
                        //       name: 'M1',
                        //       y: 22,
                        //       drilldown: 'M1'
                        //     }, {
                        //       name: 'M2',
                        //       y: 32,
                        //       drilldown: 'M2'
                        //     }, {
                        //       name: 'M3',
                        //       y: 12,
                        //       drilldown: 'M3'
                        //     }]
                        // }]
                    }
                    // //LANELEGS DATA
                    var lanelegtransit_summary = lanelegs;
                    if(lanelegtransit_summary.length > 0)
                    {
                        var legsseries = []
                        lanelegtransit_summary.map((item) => {
                            legsseries.push({
                                name:item.data_grp,
                                y:Math.floor(item.avg_transit_time/(60*60)),
                                test:item.avg_transit_time_text,
                                vins:item.no_of_vins
                            })
                        })
                        laneseries.push({
                            name:"Average Lane Transit Legs",
                            id:"Average Lane Transit Legs",
                            // data:[(transit_summary[0].avg_transit_time/(60*60*24))],
                            data:legsseries,                                
                            dataLabels: {
                                enabled: true,
                                format:"{point.y}"
                            }
                        })
                    }
                }
                if(this.state.reporttype.value == "LANE")
                {
                    // //LANELEGS DATA
                    var lanelegtransit_summary = lanelegs;
                    if(lanelegtransit_summary.length > 0)
                    {
                        // laneseries.push({
                        //     name:"Average Lane Transit Time",
                        //     id:"Average Transit",
                        //     // data:[(transit_summary[0].avg_transit_time/(60*60*24))],
                        //     data:[{
                        //         name:"Average Legs Transit Time",
                        //         y:Math.floor(lanelegtransit_summary[0].avg_transit_time/(60*60*24)),
                        //         test:lanelegtransit_summary[0].avg_transit_time_text,
                        //         vins:lanelegtransit_summary[0].no_of_vins
                        //     },
                        //     {
                        //         name:"Expected Legs Transit Time",
                        //         y:Math.floor(lanelegtransit_summary[0].exp_transit_time/(60*60*24)),
                        //         test:lanelegtransit_summary[0].exp_transit_time_text,
                        //         vins:lanelegtransit_summary[0].no_of_vins
                        //     }],
                            
                        //     dataLabels: {
                        //         enabled: true
                        //     }
                        // })
                        var legsseries = []
                        lanelegtransit_summary.map((item) => {
                            legsseries.push({
                                name:item.data_grp,
                                y:Math.floor(item.avg_transit_time/(60*60)),
                                test:item.avg_transit_time_text,
                                vins:item.no_of_vins
                            })
                        })
                        laneseries.push({
                            name:"Average Lane Transit Legs",
                            id:"Average Lane Transit Legs",
                            // data:[(transit_summary[0].avg_transit_time/(60*60*24))],
                            data:legsseries,                                
                            dataLabels: {
                                enabled: true
                            }
                        })
                        
                    }
                }
                
                // Damage Chart
                
                var damage_summary = JSON.parse(response.data.damage_summary)
                console.log("damage_summary ", damage_summary)
                var damageserires= []
                var damagedata = []
                var damagecats=[]
                if(damage_summary.length > 0)
                {
                    if(this.state.reporttype.value == "ALL")
                    {
                        var dmtitle = "Damaged Vins"
                    }
                    else if(this.state.reporttype.value == "LANE")
                    {
                        var dmtitle = damage_summary[0].data_grp
                    }
                    else
                    {
                        var dmtitle = "Damaged Vins"
                    }
                    if(damage_summary.length > 0)
                    {
                        damage_summary.map((item) => {
                            damagecats.push(item.data_grp)
                            damagedata.push({
                                name:item.data_grp,
                                y:item.no_of_damaged_vins,
                                drilldown:this.state.reporttype.value
                            })
                        })
                        damageserires.push({
                            name:this.state.reporttype.value,
                            data:damagedata,
                            dataLabels: {
                                enabled: true
                            }
                        })
                    }
                    
                }
                
                // Hold Vins Chart
                
                var holdvins = JSON.parse(response.data.hold_summary)
                var holdvinseries= []
                
                var holdvinslegdrilldown=[]
                var hldvincats = []
                var drilldownholdvins = []
                var damagelegdrilldown = []
                var drilldowndamage = []
                var autocloselegtripsdrilldown = [] 
                var autocloselegdrilldown = [] 
                var drilldownautoclose = []
                if(holdvins.length > 0)
                {
                    if(this.state.reporttype.value == "ALL")
                    {
                        var dmtitle = "Hold Vins"
                    }
                    else if(this.state.reporttype.value == "LANE")
                    {
                        var dmtitle = holdvins[0].data_grp
                    }
                    else if(this.state.reporttype.value == "LEG")
                    {
                        var dmtitle = holdvins[0].data_grp
                    }
                    else
                    {
                        var dmtitle = "Hold Vins"
                    }
                    var holdssrs = []
                    holdvins.map((item) => {
                        holdssrs.push({
                            name:item.data_grp,
                            y:item.no_of_hold_vins,
                            drilldown:this.state.reporttype.value
                        })
                    })
                    holdvinseries.push({
                        name:holdvins[0].data_grp,
                        data:holdssrs,
                        dataLabels: {
                            enabled: true
                        }
                    })
                    
                }

                if(this.state.reporttype.value == "ALL" || this.state.reporttype.value == "LANE"
                || this.state.reporttype.value == "BA")
                {
                    var holdvinlegs = this.state.holdvinlegs;
                    console.log("holdvinlegs ", holdvinlegs)
                    if(holdvinlegs.length > 0)
                    {
                        holdvinlegs.map((item) => {
                            hldvincats.push(item.data_grp)
                            holdvinslegdrilldown.push([item.data_grp,parseInt(item.no_of_hold_vins)])
                        })
                    }
                    
                    drilldownholdvins.push({
                        name: this.state.reporttype.value,
                        id: this.state.reporttype.value,
                        data: holdvinslegdrilldown
                    })

                    
                    var damagelegs = this.state.damagelegs;
                    if(this.state.reporttype.value == "BA")
                    {
                        if(damagelegs.length > 0)
                        {
                            damagelegs.map((item) => {
                                damagelegdrilldown.push([item.ba_name,parseInt(item.no_of_damaged_vins)])
                            })
                        }
                    }
                    else
                    {
                        if(damagelegs.length > 0)
                        {
                            damagelegs.map((item) => {
                                damagelegdrilldown.push([item.data_grp,parseInt(item.no_of_damaged_vins)])
                            })
                        }
                    }
                    
                    drilldowndamage.push({
                        name: this.state.reporttype.value,
                        id: this.state.reporttype.value,
                        data: damagelegdrilldown
                    })

                    var autocloselegs = this.state.autocloselegs;
                    if(autocloselegs.length > 0)
                    {
                        autocloselegs.map((item) => {
                            autocloselegtripsdrilldown.push([item.data_grp,parseInt(item.total_trips)])
                            autocloselegdrilldown.push([item.data_grp,parseInt(item.auto_closed)])
                        })
                    }
                    drilldownautoclose.push({
                        name: this.state.reporttype.value,
                        id: "Total Trips",
                        data: autocloselegtripsdrilldown
                    },{
                        name: this.state.reporttype.value,
                        id: "Auto Closed",
                        data: autocloselegdrilldown
                    })

                }

                    // Auto Closure Chart
                
                var autoclose = JSON.parse(response.data.auto_close_summary)
                
                var autocloseseries= []
                var autoclosecats= []
                    if(autoclose.length > 0)
                    {

                    if(this.state.reporttype.value == "ALL")
                    {
                        var totaltrips = []
                        var autclose =[]
                        autoclose.map((item) => {
                            autoclosecats.push(item.data_grp)
                            totaltrips.push({
                                name:"Total Trips",
                                y:item.total_trips,
                                drilldown:"Total Trips"
                            })
                            autclose.push({
                                name:"Auto Closed",
                                y:item.auto_closed,
                                drilldown:"Auto Closed"
                            })
                        })
                        autocloseseries.push({
                            name:"Total Trips",
                            data:totaltrips,
                            dataLabel:{
                                enable:true
                            }
                        },{
                            name:"Auto Closed",
                            data:autclose,
                            dataLabel:{
                                enable:true
                            }
                        })
                    }
                    else if(this.state.reporttype.value == "LANE")
                    {
                        // autoclose.map((item) => {
                        //     autoclosecats.push(item.data_grp)
                        //     autocloseseries.push({
                        //         name:"Total Trips",
                        //         data:[item.total_trips],
                                
                        //         dataLabels: {
                        //             enabled: true
                        //         }
                        //     },{
                        //         name:"Auto Closed",
                        //         data:[item.auto_closed],
                                
                        //         dataLabels: {
                        //             enabled: true
                        //         }
                        //     },
                        //     // {
                        //     //     name:"Percentage of auto closure",
                        //     //     data:[item.percetage_of_auto_closures],
                                
                        //     //     dataLabels: {
                        //     //         enabled: true
                        //     //     }
                        //     // }
                        //     )
                        // })

                        var totaltrips = []
                        var autclose =[]
                        autoclose.map((item) => {
                            autoclosecats.push(item.data_grp)
                            totaltrips.push({
                                name:"Total Trips",
                                y:item.total_trips,
                                drilldown:"Total Trips"
                            })
                            autclose.push({
                                name:"Auto Closed",
                                y:item.auto_closed,
                                drilldown:"Auto Closed"
                            })
                        })
                        autocloseseries.push({
                            name:"Total Trips",
                            data:totaltrips,
                            dataLabel:{
                                enable:true
                            }
                        },{
                            name:"Auto Closed",
                            data:autclose,
                            dataLabel:{
                                enable:true
                            }
                        })

                    }
                    else
                    {
                        autoclose.map((item) => {
                            autoclosecats.push(item.data_grp)
                            autocloseseries.push({
                                name:item.data_grp,
                                data:[{
                                    name:"Total Trips",
                                    y:item.total_trips
                                },{
                                    name:"Auto Closed",
                                    y:item.auto_closed
                                },
                                // {
                                //     name:"Percentage of auto closure",
                                //     y:item.percetage_of_auto_closures
                                // }
                                ],
                                
                                dataLabels: {
                                    enabled: true
                                }
                            })
                        })
                    }

                    
                    
                    }
    // console.log("damageserires ", damageserires)
                if(holdvinseries.length > 0)
                {
                    var holdvindata = [{
                        categories:hldvincats,
                        series:holdvinseries,
                        drilldownseries:drilldownholdvins
                    }]
                }
                else
                {
                    var holdvindata = []
                }
                if(autocloseseries.length > 0)
                {
                    var autoclosedata = [{
                        categories:autoclosecats,
                        series:autocloseseries,
                        drilldownseries:drilldownautoclose
                    }]
                }
                else
                {
                    var autoclosedata = []
                }
               
                this.setState({
                    transitcats:aocats,
                    transitseries:[{
                        categories:aocats,
                        series:aoseries,
                        drilldownseries:laneseries
                    }],
                    damageseries:[{
                        categories:damagecats,
                        series:damageserires,
                        drilldownseries:drilldowndamage
                    }],
                    holdvinseries:holdvindata,
                    autocloseseries:autoclosedata,
                    autoclosecats:autoclosecats
                })
            
            }
            catch(e){
                
            }
        })
    }
    
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
	}
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			showTrucksList : "show-n",
        });
        
    }

    reportoptions(){
        var items = []
        if(localStorage.getItem("roles").indexOf("Plant")  >= 0 || localStorage.getItem("roles").indexOf("Loading Officer")  >= 0
        || localStorage.getItem("roles").indexOf("Unloading Officer")  >= 0 || localStorage.getItem("roles").indexOf("RSO")  >= 0)
        {            
            items.push({value:"ALL", label:"ALL"});
            items.push({value:"LANE", label:"LANE"});
            items.push({value:"BA", label:"BA"});
        }
        else
        {            
            items.push({value:"ALL", label:"ALL"});
            items.push({value:"LANE", label:"LANE"});

            items.push({value:"LEG", label:"LEG"});
            items.push({value:"BA", label:"BA"});
        }
       
        return items;
    }
    onSelectReportType(reporttype){
        this.setState({reporttype})
    }
    onClickFilterData(){
        var reporttype = this.state.reporttype
        this.setState({
            transitcats:[],
            transitseries:[],
            transitseriesavg:"",
            transitseriesexp:"",
            transitdrilldownlanes:[],
            
            drilldownseries:[],
            damageseries:[],
            holdvinseries:[],
            autocloseseries:[],
            autoclosecats:[]
        })
        console.log("reporttype ", reporttype)
        var fromdate = document.getElementById("from_date").value;
        if(fromdate != "" && fromdate != "__-__-____ __:__")
        {
            var fdate = fromdate
        }
        else
        {
            var fdate = ""
        }
        var todate = document.getElementById("from_date").value;
        if(todate != "" && todate != "__-__-____ __:__")
        {
            var tdate = todate
        }
        else
        {
            var tdate = ""
        }
        var parameters={
            user_level : this.state.user_level,
            info_level : reporttype.value,
            from_date:fdate,
            to_date:tdate,
        }
        // redirectURL.post("/mllconsignments/kpilanes", parameters)
        // .then((response) => {
        //     console.log("Lanes ", response.data)
        //     var lanes = JSON.parse(response.data.transit_summary)
        //     this.setState({
        //         lanes:lanes
        //     })
        // })
        
        // redirectURL.post("/mllconsignments/kpilanelegs", parameters)
        // .then((response) => {
        //     console.log("Lanes Legs ", response.data)
        //     var lanelegs = JSON.parse(response.data.transit_summary)
        //     var holdvinlegs = JSON.parse(response.data.hold_summary)
        //     var damagelegs = JSON.parse(response.data.damage_summary)
        //     var autocloselegs = JSON.parse(response.data.auto_close_summary)
            
        //     this.setState({
        //         lanelegs:lanelegs,
        //         holdvinlegs:holdvinlegs,
        //         damagelegs:damagelegs,
        //         autocloselegs:autocloselegs
        //     })
        // })

        this.onLoadChart(parameters)
    }

    
    render(){
        // console.log("this.state.drilldownseries ", JSON.stringify(this.state.drilldownseries))
        var drilldownseries = this.state.holdvinseries;
        console.log("drilldownseries ", drilldownseries)
        const dStyles={
            width:'100%',
            height:'100%'
        }
        
        // var transitoptions = {
        //     chart: {
        //         type: 'column'
        //     },
        //     title: {
        //         text: 'Transit Time (Days)'
        //     },
        //     subtitle: {
        //         text: ''
        //     },
        //     xAxis: {
        //         // categories: this.state.transitcats,
        //         // labels: {
        //         //     enabled:false,//default is true
        //         //     y : 20, rotation: -45, align: 'right' 
        //         // }
        //         type: 'category'
        //     },
        //     yAxis: {
        //         min: 0,
        //         title: {
        //             text: 'Days'
        //         }
        //     },
        //     tooltip: {
        //         headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                
        //         formatter: function() {
        //             var s = '<b>'+ this.point.name+': '+this.point.test +'</b><br /> No of vins: <b>'+this.point.vins+"</b>";            
        //             return s;
        //         },
        //         shared: false
        //     },
        //     legend: {
        //         enabled: true
        //     },

        //     plotOptions: {
        //         series: {
        //             borderWidth: 0,
        //             dataLabels: {
        //                 enabled: true,
        //                 format: '{point.y} Days'
        //                 // formatter: function() {
        //                 //     var s = '<b>'+ this.point.test +'</b>';            
        //                 //     return s;
        //                 // },
        //             }
        //         }
        //     },
        //     //colors:[ '#f35e82','#ff5370','#53ff7b', '#ff53ef', '#53a5ff', '#ec7bbd','#f30000','#00f0b5','#3100f3', '#fff493', '#f88fa2', '#f15c80', '#92A8CD', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
        //     series: this.state.transitseries,
        //     drilldown:{
        //         series:drilldownseries
        //         // series:[{"name":"Average Lane Transit Time","id":"Average Transit Time","data":[{"name":"Average Lane Transit Time","y":9,"test":"9 days, 7 hours, 24 minutes","vins":58,"drilldown":"Average Lane Transit Legs"},{"name":"Expected Lane Transit Time","y":8,"test":"8 days, 21 hours, 23 minutes","vins":58}]},{"name":"Average Lane Transit Legs","id":"Average Lane Transit Legs","data":[{"name":"Average Legs Transit Time","y":1},{"name":"Average Legs Transit Time","y":6},{"name":"Average Legs Transit Time","y":0},{"name":"Average Legs Transit Time","y":1}],"dataLabels":{"enabled":true,"format":"{point.y}"}}]
        //     },
		// 	credits:false
        // }

        
        var damageoptions = {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Damage Vins'
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                // categories: this.state.transitcats,
                labels: {
                    enabled:false,//default is true
                    y : 20, rotation: -45, align: 'right' 
                },
                type: 'category'
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat:'<span style="font-size:11px">{series.name} : {point.y}</span><br>',
                shared: false
            },
            legend: {
                enabled: true
            },

            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}'
                        // formatter: function() {
                        //     var s = '<b>'+ this.point.test +'</b>';            
                        //     return s;
                        // },
                    }
                }
            },
            colors:[ '#00f0b5','#f35e82','#ff5370','#53ff7b', '#ff53ef', '#53a5ff', '#ec7bbd','#f30000','#00f0b5','#3100f3', '#fff493', '#f88fa2', '#f15c80', '#92A8CD', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
            series: this.state.damageseries,
            // drilldown:{
            //     series:JSON.stringify(this.state.drilldownseries)
            //     // series:[{"name":"Average Lane Transit Time","id":"Average Transit Time","data":[{"name":"Average Lane Transit Time","y":9,"test":"9 days, 7 hours, 24 minutes","vins":58,"drilldown":"Average Lane Transit Legs"},{"name":"Expected Lane Transit Time","y":8,"test":"8 days, 21 hours, 23 minutes","vins":58}]}]
               
            // },
			credits:false
        }
        
        
        var holdvinoptions = {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Hold Vins'
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                // categories: this.state.transitcats,
                labels: {
                    enabled:false,//default is true
                    y : 20, rotation: -45, align: 'right' 
                },
                type: 'category'
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat:'<span style="font-size:11px">{series.name} : {point.y}</span><br>',
                shared: false
            },
            legend: {
                enabled: true
            },

            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}'
                        // formatter: function() {
                        //     var s = '<b>'+ this.point.test +'</b>';            
                        //     return s;
                        // },
                    }
                }
            },
            colors:['#53a5ff', '#f35e82','#ff53ef','#ff5370','#53ff7b',  '#53a5ff', '#ec7bbd','#f30000','#00f0b5','#3100f3', '#fff493', '#f88fa2', '#f15c80', '#92A8CD', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
            series: this.state.holdvinseries,
            // drilldown:{
            //     series:JSON.stringify(this.state.drilldownseries)
            //     // series:[{"name":"Average Lane Transit Time","id":"Average Transit Time","data":[{"name":"Average Lane Transit Time","y":9,"test":"9 days, 7 hours, 24 minutes","vins":58,"drilldown":"Average Lane Transit Legs"},{"name":"Expected Lane Transit Time","y":8,"test":"8 days, 21 hours, 23 minutes","vins":58}]}]
               
            // },
			credits:false
        }

        // Auto Closure
        
        var autocloseoptions = {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Auto Closure'
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                // categories: this.state.autoclosecats,
                // labels: {
                //     enabled:false,//default is true
                //     y : 20, rotation: -45, align: 'right' 
                // },
                type: 'category'
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat:'<span style="font-size:11px">{series.name} : {point.y}</span><br>',
                shared: false
            },
            legend: {
                enabled: true
            },

            plotOptions: {
                series: {
                    borderWidth: 0,
                    pointWidth:30,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}'
                        // formatter: function() {
                        //     var s = '<b>'+ this.point.test +'</b>';            
                        //     return s;
                        // },
                    }
                }
            },
            colors:['#53a5ff', '#f35e82','#53a5ff', '#ff53ef','#53ff7b', '#ff5370', '#53a5ff', '#ec7bbd','#f30000','#00f0b5','#3100f3', '#fff493', '#f88fa2', '#f15c80', '#92A8CD', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
            series: this.state.autocloseseries,
            // drilldown:{
            //     series:JSON.stringify(this.state.drilldownseries)
            //     // series:[{"name":"Average Lane Transit Time","id":"Average Transit Time","data":[{"name":"Average Lane Transit Time","y":9,"test":"9 days, 7 hours, 24 minutes","vins":58,"drilldown":"Average Lane Transit Legs"},{"name":"Expected Lane Transit Time","y":8,"test":"8 days, 21 hours, 23 minutes","vins":58}]}]
            
            // },
            credits:false
        }
    
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                   
                    <div className=" col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
                                </h5>
                            </div>
                            <div className="row card-body">
                                <div className="row col-xl-12 col-lg-12">
                                    <div className="col-xl-3 col-lg-3 form-group">
                                        <label>Report Type:</label>
                                        <Select 
											// closeMenuOnSelect={false} 
											name="reporttype" 
											placeholder="Report Type" 
											className="rselect" 
											value={this.state.reporttype} 
											onChange={this.onSelectReportType.bind(this)} 
											options={this.reportoptions()} />
                                    </div>
                                    <div className="col-xl-3 col-lg-3">
                                        <label className="col-xl-12 col-lg-12">From Date</label>
                                        <input type="text" placeholder="From " id="from_date" name="from_date" className="datetimepicker_mask form-control" required />
                                    </div>
                                    <div className="col-xl-3 col-lg-3">
                                        <label className="col-xl-12 col-lg-12">To Date</label>
                                        <input type="text" placeholder="To " id="to_date" name="to_date" className="datetimepicker_mask form-control" required />
                                    </div>
                                    <div className="col-xl-3 col-lg-3 form-group">
                                        <button type="button"
                                            onClick={this.onClickFilterData.bind(this)}
                                            className="btn btn-warning mt-30p"
                                            >Get Data</button>
                                    </div>
                                </div>
                                <div className="row col-sm-12 col-xl-12 col-lg-12 mt-40p">
                                    
                                        {/* <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={transitoptions} /> */}
                                        
                                        {(this.state.transitseries.length > 0)?
                                        this.state.transitseries.map(item => 
                                            <div className="col-xl-6 col-lg-6">
                                                <MllColumnDrilldownChart
                                                    chartTitle={"Transit Time (Hrs)"}
                                                    yAxisTitle={"Hours"}
                                                    series = {item.series}
                                                    drilldown = {item.drilldownseries}
                                                />
                                             </div>
                                        )
                                        
                                        :""}
                                   
                                    
                                        {/* <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={holdvinoptions} /> */}
                                        {(this.state.holdvinseries.length > 0)?
                                        this.state.holdvinseries.map(item => 
                                            <div className="col-xl-6 col-lg-6">
                                                <MllHoldVinsColumnDrilldownChart
                                                    chartTitle={"Hold Vins"}
                                                    yAxisTitle={"No of Vins"}
                                                    series = {item.series}
                                                    drilldown = {item.drilldownseries}
                                                />
                                            </div>
                                        )
                                        
                                        :""}
                                    
                                    
                                {/* </div>

                                <div className="row col-sm-12 col-xl-12  col-lg-12 mt-40p">
                                     */}
                                
                                    
                                        {/* <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={autocloseoptions} /> */}
                                        
                                        {(this.state.autocloseseries.length > 0)?
                                        this.state.autocloseseries.map(item => 
                                            <div className="col-xl-6 col-lg-6">
                                            <MllDamageColumnDrilldownChart
                                                chartTitle={"Auto Closed"}
                                                yAxisTitle={"No of Vins"}
                                                series = {item.series}
                                                drilldown = {item.drilldownseries}
                                            />
                                            </div>
                                        )
                                        
                                        :""}
                                    
                                    
                                        {/* <HighchartsReact containerProps={{ style: { width: '100%', height: '380px' } }} highcharts={Highcharts} style={dStyles} options={damageoptions} /> */}
                                        {(this.state.damageseries.length > 0)?
                                        this.state.damageseries.map(item => 
                                            <div className="col-xl-6 col-lg-6">
                                                <MllDamageColumnDrilldownChart
                                                    chartTitle={"Damage Vins"}
                                                    yAxisTitle={"No of Damaged Vins"}
                                                    series = {item.series}
                                                    drilldown = {item.drilldownseries}
                                                />
                                            </div>
                                        )
                                        
                                        :""}
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    

                    {/* <form method="POST" className="form-theme col-xl-12 col-lg-12 row" onSubmit={this.getTruckConsignments.bind(this)}>
                       
                            <div className={"trucks-dropdown "+this.state.showTrucksList}>
                            <ul>
                                {listData}
                            </ul>
                         </div>
                         <div className="col-xl-3 col-lg-3 form-group">
								<input type="text" name="truck_no" id="inputTruck"  placeholder="Truck No" autoComplete="off" className="form-control" onFocus={this.initalList.bind(this)} onChange={this.handlerForm.bind(this)} />
	                    </div>
                        <div className="col-xl-2 col-lg-2">
                            <button type="submit" className="btn btn-success">Get Data</button>
                        </div>
                    </form> */}
                </div> 
                
                
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                    </div>
                    <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                        <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                    </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        )
    }
}

        
function loadDateTimeScript(){
	// alert("timeDate");
	// $('.datetimepicker_mask').datetimepicker({
	// 	mask:'39-19-9999 29:59',
	// 	format:'d-m-Y H:i'
	// });
	$('#from_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
    });
    $('#to_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}
$(document).ready(function(){
    $(document).on("click",".dropdown-truck-no",function(){
        var vals = $(this).text();
         $("#inputTruck").val(vals);
         $(".trucks-dropdown").removeClass("show-m");
         $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click",function(e){
        var container = $("#inputTruck");
        if(!container.is(e.target))
        {
            try{
                if($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown")
                {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            }catch(e){}
            
        }
        
        
    })
})


var todaydateis = moment.parseZone().format("YYYY/MM/DD")

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // console.log("todaydateis ",todaydateis)
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        minDate:todaydateis
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function getLanes(parameters)
{
    redirectURL.post("/mllconsignments/kpilanes", parameters)
    .then((response) => {
        console.log("Lanes ", response.data)
        return response.data;
    })
}
function getLaneLegs(parameters)
{
    redirectURL.post("/mllconsignments/kpilanelegs", parameters)
    .then((response) => {
        console.log("Lanes legs ", response.data)
        return response.data;
    })
}
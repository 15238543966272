var moment = require('moment');
var verifyextcsv = function(fname) {
    if(fname == '' || fname == undefined)
    {
        return false;
    }
    else{
        var fn = fname.split(".");
        var fext = fn[(fn.length-1)];
        var exts = ["config","exe","js","svg","jsx","JPG","jpg","jpeg","GIF","gif","PNG","png","BMP","bmp","html","xls","xlsx","doc","docx","ppt","pptx","pdf","pdfx","html","css","sh", "php5", "pht", "phtml", "shtml", "asa", "cer", "asax", "swf","xap","php","htaccess","xml","xds","asp","aspx","java","c","c++","ctl","conf","qewe","encrypted","enc","crypted","locked","crypto","crypt","txt"];
        var flag = 0;
         fn.map((item) => {
            if(exts.indexOf(item) >= 0)
            {
                flag = 1
            }
        })
        // if(fext == "csv")
        // {
        //     arrayMatch(exts, [fext])
        //     return true;
        // }
        // else{
        //     return false;
        // }
        if(flag == 1)
        {
            return false;
        }
        else{
            return true;
        }
        // console.log("Flag ", flag)
        // return flag;s
    }
}
exports.verifyextcsv = verifyextcsv;

var verifyextimg = function(fname) {
    if(fname == '' || fname == undefined)
    {
        return false;
    }
    else{
        var fn = fname.split(".");
        var fext = fn[(fn.length-1)];
        var exts = ["config","exe","js","jsx","svg","html","doc","docx","xls","xlsx","ppt","pptx","pdf","pdfx","html","css","sh", "php5", "pht", "phtml", "shtml", "asa", "cer", "asax", "swf","xap","php","htaccess","xml","xds","asp","aspx","java","c","c++","ctl","conf","qewe","encrypted","enc","crypted","locked","crypto","crypt","txt"];
        var flag = 0;
         fn.map((item) => {
            if(exts.indexOf(item) >= 0)
            {
                flag = 1
            }
        })
        
        if(flag == 1)
        {
            return false;
        }
        else{
            return true;
        }
    }
}
exports.verifyextimg = verifyextimg;

var verifyextlm = function(fname) {
    if(fname == '' || fname == undefined)
    {
        return false;
    }
    else{
        var fn = fname.split(".");
        var fext = fn[(fn.length-1)];
        var exts = ["config","exe","js","jsx","svg","html","html","css","sh", "php5", "pht", "phtml", "shtml", "asa", "cer", "asax", "swf","xap","php","htaccess","xml","asp","aspx","java","c","c++","ctl","conf","qewe","encrypted","enc","crypted","locked","crypto","crypt","txt"];
        var flag = 0;
         fn.map((item) => {
            if(exts.indexOf(item) >= 0)
            {
                flag = 1
            }
        })
        
        if(flag == 1)
        {
            return false;
        }
        else{
            return true;
        }
    }
}
exports.verifyextlm = verifyextlm;


var verifycsvdata = function(data) {
    //console.log("data ", data)

    // || data.includes("%") || data.includes("^") || data.includes("&") || data.includes("*") || data.includes("(")
    // || data.includes(")") || data.includes("{") || data.includes("}") || data.includes("[") || data.includes("]")
    // || data.includes(":") || data.includes(";") || data.includes("/") || data.includes("?") || data.includes("'")

    if(data.includes("<script") || data.includes("/sh") || data.includes("#!")
    || data.includes("/bin") || data.includes("bin/") || data.includes("/bash") || data.includes("<html")
    || data.includes("<body") || data.includes("<title") || data.includes("<div") || data.includes("<span")
    || data.includes("<h1") || data.includes("<h2") || data.includes("<h3") || data.includes("<h4") 
    || data.includes("<h5") || data.includes("<h6") || data.includes("<a") || data.includes(".exe") 
    || data.includes("<p") || data.includes("<b") || data.includes("<i") || data.includes("<table")
    || data.includes("<tr") || data.includes("<td") || data.includes("<th") || data.includes("<ul")
    || data.includes("<li") || data.includes("<sup") || data.includes("<sub") || data.includes("<center")
    || data.includes("<head") || data.includes("<svg") || data.includes("<xml") || data.includes("<")
    || data.includes(">") || data.includes("!") || data.includes("@") || data.includes("#") || data.includes("$")
    || data.includes("`") || data.includes("~") || data.includes("<SOAP") || data.includes("<soap")
    || data.includes("<HEAD") || data.includes("<HTML") || data.includes("<BODY") || data.includes("<SPAN")
    || data.includes("<P") || data.includes("<H1") || data.includes("<H2") || data.includes("<H3") || data.includes("<H4")
    || data.includes("<H5") || data.includes("<H6") || data.includes(".EXE") || data.includes("<SVG")
    || data.includes("<A") || data.includes("<TABLE") || data.includes("<TR") || data.includes("<TH") || data.includes("<TD")
    || data.includes("<UL") || data.includes("<LI") || data.includes("<SUP") || data.includes("<SUB") || data.includes("<CENTER")
    || data.includes("<DIV") || data.includes("<TITLE"))
    {
        return true;
    }
    else{
        return false;
    }
}
exports.verifycsvdata = verifycsvdata;


var verifyext = function(fname) {
    if(fname == '' || fname == undefined)
    {
        return false;
    }
    else{
        var fn = fname.split(".");
        var fext = fn[(fn.length-1)];
        var exts = ["config","exe","js","jsx","svg","JPG","jpg","jpeg","GIF","gif","PNG","png","BMP","bmp","html","xls","xlsx","doc","docx","ppt","pptx","pdf","pdfx","html","css","sh", "php5", "pht", "phtml", "shtml", "asa", "cer", "asax", "swf","xap","php","htaccess","xml","xds","asp","aspx","java","c","c++","ctl","conf","qewe","encrypted","enc","crypted","locked","crypto","crypt","txt"];
        var flag = 0;
         fn.map((item) => {
            if(exts.indexOf(item) >= 0)
            {
                flag = 1
            }
        })
        
        if(flag == 1)
        {
            return false;
        }
        else{
            return true;
        }
    }
}
exports.verifyext = verifyext;
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import Autosuggest from 'react-autosuggest';

import Breadcrumb from '../common/breadcrumb.component';
import Select from 'react-select';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD,getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils'; 
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import $ from "jquery";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import DrawMap from '../common/mlldrawmap';
import Trucklocaction from '../layouts/trucklocaction';
var infoBox = require('../common/google-infowindow');
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`)
const ResponsiveReactGridLayout = WidthProvider(Responsive);
var map;
var viewMarkersArr=[];
export default class MLLTrucksGPS extends Component {

    constructor(props) {
        super(props);

        this.state = 
        {
            pageTitle: "Truck GPS Data",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
        	routemap:'',
        	allmarkers:'',
        	trucks:[],
            truck_no:'',
            selected_truck_no : "",
        	startDate:'',
            endDate:'',
            mapinfo:'',
            mapinfoclick:'',
			modules: AllModules,
			defTransitCoords:'',
			activeMarker: {},
			selectedPlace: {},
			dealerCode:'',
			timelinesmarkers:[],
			viewtimelinemarkers:true,
			viewgoogleroutes:true,
			googleroutepath:[],
			loadshow:'show-n',
            open: false,
            alert:null,
            show: false,
			basicTitle:'',
			defaultColDef: {
				sortable: true,
			  filter: true,
			  resizable: true

            },
            gridshow:'show-m',
            filteredData : [],
            mapshow:'show-m',
            actgridbtn:"btn-success",
            actmapbtn:"btn-default",
			rowData: null,
			showTrucksList : "show-n",
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
              customLoadingCellRenderer: CustomLoadingCellRenderer,
              trucklocaction:Trucklocaction
		  },

			animateRows: true,
			debug: true,
			showToolPanel: false,
		  uppressAggFuncInHeader: true,
			  
		  childconfs: [],
		  childrow:[],
		  rowModelType: "serverSide",
		  paginationPageSize:50,
		  cacheBlockSize: 10,
          maxBlocksInCache: 1,
          truckNoList:[],
          overly : "show-n",
		  statusBar: {
			  statusPanels: [
				  {
				  statusPanel: "agTotalAndFilteredRowCountComponent",
				  align: "left"
				  },
				  {
				  statusPanel: "agTotalRowCountComponent",
				  align: "center"
				  },
				  { statusPanel: "agFilteredRowCountComponent" },
				  { statusPanel: "agSelectedRowCountComponent" },
				  { statusPanel: "agAggregationComponent" }
			  ]
		  },
		  sideBar: {
			  toolPanels: [
				  {
				  id: "columns",
				  labelDefault: "Columns",
				  labelKey: "columns",
				  iconKey: "columns",
				  toolPanel: "agColumnsToolPanel"
				  },
				  {
				  id: "filters",
				  labelDefault: "Filters",
				  labelKey: "filters",
				  iconKey: "filter",
				  toolPanel: "agFiltersToolPanel"
				  }
			  ]
		  },
		  detailCellRendererParams:{},
		  rowClassRules: {
			  "highlitRow": "data.transit_delay == 1",
			  "green":"data.status >= 3"

          }	,
          tolls : [],
          frequency:"",
          showmarkers : 0,
          clusters:[],
          clusteroptions:[],
          clusteritem:{"value":"", "label":"Select Cluster"},
          originalrowdata:[],
          allmapinfo:{}
        		
    	      
        };
        this.setTruckno = this.setTruckno.bind(this);
        this.onClickShowTruckLocation = this.onClickShowTruckLocation.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        //console.log("Box ",window.atob(localStorage.getItem('_t')));
    	this.logPageView(); // Log page view to GA
        redirectURL.post("consignments/mlltrucks").then((response)=>{
            var records = response.data.records;
            if(records.length > 0)
            {
                var trucks = records.map(function(e)
                {
                    if(e.truck_no != "")
                    {
                        return e.truck_no;
                    }
                })
                // console.log("trucks ", trucks)
                this.setState({
                    truckNoList :trucks
                })
            }
            
        })
    	
    }
    selectOptionsItems() {
        let items = [];     
        //console.log(this.state.trucks);
        this.state.trucks.forEach(item => {
        	if(item != '' && item != null)
    		{
        		items.push(<option key={item.truck_no} value={item.truck_no}>{item.truck_no}</option>); 
    		}
        	
        });
        
        
        return items;
    }
   
    handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+d.getDate()+" "+d.getHours()+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
    	
    	this.setState({
    		startDate:getYYYYMMDDHHMMSS(d)
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }

    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+d.getDate()+" "+d.getHours()+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
    	this.setState({
    		endDate:getYYYYMMDDHHMMSS(d)
    	});
    	
    	
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
    initalList =(event)=>{
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckNoList;
        dataset = dataset.slice(0,100);
        this.setState({
            filteredData : dataset,
            showTrucksList : "show-m",
        })
    }
    handlerForm = (event) => {
    	//console.log("Select", event.target.value);
    	// let name = event.target.name;
        // let value = event.target.value;
        // this.setState({[name]:value});
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckNoList;
        var filteredResult = dataset.filter(function(e){
            if(e != "" && e != null)
            {
                return e.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
            }
            
        });
        filteredResult = filteredResult.slice(0,100);
        this.setState({
            filteredData : filteredResult,
            showTrucksList : "show-m",
        })
    }
    onClickTruck(event)
    {
         $("#inputTruck").val($(this).text());
    }
    formHandler = async (event) => {
        this.setState({
            loadshow:'show-m'
        })
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": googleAnalytics.page.action.formSubmittedGenerate,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        //var formdata = new FormData(event.target);
       // console.log($("#inputTruck").val())
       var truckno = $("#inputTruck").val().toUpperCase();
       if(this.state.startDate != "" && this.state.endDate != "" && truckno != "")
       {
            var formdata= {
                    truck_no:truckno,
                    startDate:getHyphenYYYYMMDDHHMMSS(this.state.startDate),
                    endDate:getHyphenYYYYMMDDHHMMSS(this.state.endDate),
                    frequency:this.state.frequency
            }
            console.log("formdata ", formdata)
            await redirectURL.post("/gmap",formdata,{
                headers:{
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin':"*",
                    // 'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE, TOKEN',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
                }
            })
            //.then(res => res.json())
            .then(
            async (result) => {
                // console.log("result ",result.data);
                var jsondata = result.data;
                //  console.log(JSON.parse(jsondata.data))
                 var jcoords = JSON.parse(jsondata.data);
                 var clusters  = []
                 var clusteroptions  = []
                 var copts = []
                 if(jcoords.coords.length > 0)
                 {
                    var clustersmap = groupBy(jcoords.coords, rdata => rdata.cluster);
                    // console.log("clusters ", clusters)
                    clusters.push(clustersmap);
                    clustersmap.forEach((item,key) => {
                        // console.log("Key ", key)
                        copts.push(key);
                    })
                 }
                if(copts.length > 0)
                {
                    copts = copts.sort();
                    clusteroptions.push({value:"",label:"Select Cluster"})
                    copts.map((item) => {
                        clusteroptions.push({value:item,label:item})
                    })
                }
                if(result.data.status == 'failure')
                {
                    this.setState({
                        show:true,
                        basicTitle:'No GPS data available for this period',
                        basicType:"danger",
                        loadshow:'show-n',
                        rowData:null,
                        mapinfo:'',
                    })
                }
                else if(result.data.status == 'truckno')
                {
                    this.setState({
                        show:true,
                        basicTitle:'Truck number is not valid',
                        basicType:"danger",
                        loadshow:'show-n',
                        rowData:null,
                        mapinfo:'',
                    })
                }
                else{
                    var coordinates = JSON.parse(jsondata.data);
                    //console.log("truckno ", this.props.truckno)
                    //console.log("coordinates ", coordinates)
                    var dataarr=[]
                    //console.log("coordinates ", coordinates)
                    coordinates.coords.map((item) => {
                        
                        dataarr.push({
                            "truck_no":item.truck_no, 
                            "cluster":item.cluster, 
                            "lat":item.lat, 
                            "lng":item.lng, 
                            "speed":item.speed, 
                            "stime":item.stime,
                            //"state":item.state,
                            //"area":item.area,
                            //"city":item.city,
                            //"location_type":item.location_type,
                            "dist_from_prev_point":item.dist_from_prev_point, 
                            "received_on":item.received_on, 
                            "time_from_prev_point":item.time_from_prev_point,
                            "area" : item.area,
                            "state" : item.state
                        })
                    });
                    //console.log("Modified ", dataarr)
                    this.setState({
                        rowData:dataarr,
                        originalrowdata:dataarr,
                        allmapinfo:JSON.parse(jsondata.data),
                        mapinfo:JSON.parse(jsondata.data),
                        clusters:clusters,
                        clusteroptions:clusteroptions,
                        loadshow:'show-n',
                        tolls : JSON.parse(jsondata.data).tolls
                    })
                    this.renderMap()
            
                }
                    
            }
            )
            .catch(function (error) {
                console.log(error);
            });
       }
       else
       {
           this.setState({
            show:true,
            basicTitle:'Fields should not be empty',
            basicType:"danger",
            loadshow:'show-n',

           })
       }
    	
    }

    onClickShowGrid(){
        this.setState({
            gridshow:'show-m',
            mapshow:'show-n',
            mapinfoclick:"",
            actgridbtn:"btn-success",
            actmapbtn:"btn-default"
        });
    }
    
    onClickShowMap(){
        this.setState({
            gridshow:'show-n',
            mapshow:'show-m',
            mapinfoclick:this.state.mapinfo,
            actgridbtn:"btn-default",
            actmapbtn:"btn-success"
        });
    }
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			showTrucksList : "show-n",
        });
        
    }
    hideList(){
        this.setState({
			loadshow:'show-n',
			overly:'show-n',
			showTrucksList : "show-n",
        });
    }
	closeAlert = () => {
        this.setState({
            show: false
        });
    }
    setTruckno = (event)=>{
        this.setState({
            selected_truck_no : event.target.text
        })
        console.log(event.target.text)
    }

    checkHandler = (event) => {
    	
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    renderMap = () => {   
		
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}
    initMap = () => {
        //console.log("arr ",arr)
        var currentwindow;
		var dealer='';
		var tolls = this.state.tolls;
		
		var arr=[];
        var jsondata = this.state.mapinfo;
        var locations = jsondata.breaks;
        var arr = jsondata.coords;
		if(arr.length > 0 && typeof arr.length != undefined)
		{
            var lt=arr[0].lat;
            var ln=arr[0].lng;
		}
		else{
            var lt=28.4519751;
            var ln=77.0310713;
		}
        var routeinfo ;
        try{
			var mapOptions = {
				zoom: 13,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: true,
				fullscreenControl: true,
				labels:true,
				mapTypeControlOptions: {
					mapTypeIds: ['satellite', 'roadmap'],
				},
				center: new window.google.maps.LatLng(lt,ln),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP
			};
					
		}
		catch(e){
			var mapOptions = {
				zoom: 13,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: true,
				fullscreenControl: true,
				labels:true,
				mapTypeControlOptions: {
					mapTypeIds: ['satellite', 'roadmap'],
				},
				center: new window.google.maps.LatLng(28.4519751,77.0310713),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP
			};
					
		}
        try{
			map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
		}catch(e){}
		
		var bounds = new window.google.maps.LatLngBounds();
		bounds.extend(new window.google.maps.LatLng(lt,ln));
			// Create our info window content
		var currentinfowindow = null;
		var line=new window.google.maps.Polyline(
		{
			map:map,
			strokeColor: '#157254',
			strokeOpacity: 1.0,
			strokeWeight: 2.5,
			icons: [{
					icon: {
							path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
							strokeColor:'#ff8c52',
							fillColor:'#ff8c52',
							fillOpacity:1,
							strokeWeight: 2
					},
					repeat:'100px',
					path:[]
				}]
            });
        if(arr.length > 0)
		{
            var allpoints = [];
		
			var arr1=[];
			//console.log("Total ",ratio);
			if(this.state.timelinesmarkers.length > 0)
			{
				arr1 = this.state.timelinesmarkers.filter(function (value, index, arr) {
					return (index % ratio == 0);
				} );
				
	
				//var arr1 = this.state.timelinesmarkers;
            }
            
            var arr2=[];
            var darr=[];
            if(arr.length > 0)
			{
				darr = arr.filter(function (value, index, arr) {
					//console.log("Vale ",value)
					return (value.dist_from_prev_point > 0);
				} );
				if(darr.length < 2000)
				{
					var ratio = 1;
				}
				else{
					var ratio = 20;
				}
				//console.log("darr.length ", darr.length)
				arr2 = darr.filter(function (value, index, darr) {
					return (index % ratio == 0);
				} );
				
	
				//var arr1 = this.state.timelinesmarkers;
            }
            // view markers code
            if(arr2.length > 0)
            {
                    // //console.log("allpoints ", allpoints)
                    
                    for(var a=0;a<arr2.length;a++)
                    {
                        //console.log(arr2[a],"arr2[a]");
                        var marker = new window.google.maps.Marker({
                            position: new window.google.maps.LatLng(arr2[a].lat, arr2[a].lng),
                            icon: {
                                    path: window.google.maps.SymbolPath.CIRCLE,
                                    strokeColor:'#157254',
                                    fillColor:'#157254',
                                    fillOpacity:1,
                                    strokeWeight: 5,
                                    scale:1,
                                },
                            map: map,
                            content:arr2[a]
                        });
                        marker.setVisible(false);
                        // 	//console.log("Arr ",arr1[a])
                        window.google.maps.event.addListener(marker, 'mouseover', (function(marker) {
                            return function() {
                                //console.log("Arr ",marker)
                                var contentarr = []
                            //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                            var header = "Current Status"
                            contentarr.push({"key":"Time ", "value":getDDMMYYYYHHMMSS(marker.content.stime)})
                            contentarr.push({"key":"Speed ", "value":marker.content.speed+" KMPH"})
                            
                            var contentString = infoBox("", header, contentarr,'')
                            
                            
                            infowindow.setContent(contentString);
                            currentwindow = infowindow;
                            infowindow.open(map, marker);
                            //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                            }
                        })(marker));
                        window.google.maps.event.addListener(marker, 'mouseout', function() {
                            infowindow.close();
                        });
        
                        viewMarkersArr.push(marker);
                        
                    }
            }
            var routeinfo = jsondata.route_details;
            var icon_cnt = 100
            for(var i=0;i< arr2.length-1;i++)
            {
                var locationLatLng = [];
                locationLatLng.push(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
                locationLatLng.push(new window.google.maps.LatLng(arr2[i+1].lat,arr2[i+1].lng));
                if(icon_cnt % 10 == 0)
                {
                    var lineicon = [{
                                icon: {
                                        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                        strokeColor:'#FF0000',
                                        fillColor:'#FF0000',
                                        fillOpacity:1,
                                        strokeWeight: 2
                                },
                                repeat:'100px',
                                path:[]
                            }];
                }else{lineicon=[]}
                icon_cnt = icon_cnt + 1
                var consignments_missing_route_line = new window.google.maps.Polyline({
                    map:map,
                    path : locationLatLng,
                    strokeColor: '#157254',
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    icons: lineicon
                });
                // console.log(arr2[i].msgtype)
                if(arr2[i].msgtype == "G")
                {
                    
                    consignments_missing_route_line.setOptions({
                        strokeColor : "#452a68",
                        strokeWeight: 5.5,
                    })
                }
                bounds.extend(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
            }
				
                // for (let i = 0; i < arr2.length; i++) {
                //     //if(arr2[i].speed > 0)
                //     //{
                //         var linecolr = "#157254";
                //         if(arr2[i].set  == 2)
                //         {
                //             linecolr = "#0000ff";
                //         }
                //         if(arr2[i].set  == 3)
                //         {
                //             linecolr = "#980000"
                //         }
                //         line.setOptions({strokeColor: linecolr}); 
                //     var path=line.getPath().getArray();
                            
                //     let latLng=new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng);
                //     path.push(latLng);
                //     line.setPath(path);
                        
                //     //Change line color based on map type
                //     window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
                //         if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
                //         {
                //             var line=new window.google.maps.Polyline(
                //             {
                //                 map:map,
                //                 strokeColor: '#FFFFFF',
                //                 strokeOpacity: 1.0,
                //                 strokeWeight: 2.5,
                //                 icons: [{
                //                         icon: {
                //                                 path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                //                                 strokeColor:'#ff8c52',
                //                                 fillColor:'#ff8c52',
                //                                 fillOpacity:1,
                //                                 strokeWeight: 2
                //                                 },
                //                         repeat:'100px',
                //                         path:[]
                //                     }]
                //                 });                
                //         }
                //         else
                //         {
                //             var line=new window.google.maps.Polyline(
                //             {
                //                 map:map,
                //                 strokeColor: '#157254',
                //                 strokeOpacity: 1.0,
                //                 strokeWeight: 2.5,
                //                 icons: [{
                //                         icon: {
                //                                 path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                //                                 strokeColor:'#ff8c52',
                //                                 fillColor:'#ff8c52',
                //                                 fillOpacity:1,
                //                                 strokeWeight: 2
                //                                 },
                //                         repeat:'100px',
                //                         path:[]
                //                     }]
                //                 });

                //         }

                //         for (i = 0; i < arr2.length; i++) {
                //                 var path=line.getPath().getArray();
                //                 let latLng=new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng);
                //                 path.push(latLng);
                //                 line.setPath(path);
                //                         //map.setCenter(latLng);
                //             }
                                        
                //         var x = map.getZoom();
                //         var c = map.getCenter();
                //         window.google.maps.event.trigger(map, 'resize');
                //         map.setZoom(x);
                //         map.setCenter(c);
                //     } );
                //     bounds.extend(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
                //     //}
                // }

				var infowindow = new window.google.maps.InfoWindow();
					var marker, l;

					for (l = 0; l < locations.length; l++) {
					marker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
					icon:require('../../assets/icons/cf.png'),
					map: map,
					});

					window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
						return function() {
							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time)+")</p>";
						
						var contentarr = []
						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						var header = "Break";
						try{
							if(typeof this.props.mapFor != 'undefined')
							{
                                var timeinsecs = moment.parseZone(locations[l].break_start).format("x") - moment.parseZone(locations[l].break_start).format("x")
                                contentarr.push({"key":"Break Start", "value":locations[l].break_start})
                                contentarr.push({"key":"Break End", "value":locations[l].break_end})
                                contentarr.push({"key":"Break time", "value":timeConvert(locations[l].break_time_seconds)})
							}
							else
							{
                                contentarr.push({"key":"Break Start", "value":locations[l].break_start})
                                contentarr.push({"key":"Break End", "value":locations[l].break_end})
                                contentarr.push({"key":"Break time", "value":timeConvert(locations[l].break_time_seconds)})
                            }
						}
						catch(e){
                            contentarr.push({"key":"Break Start", "value":locations[l].break_start})
                            contentarr.push({"key":"Break End", "value":locations[l].break_end})
                            contentarr.push({"key":"Break time", "value":timeConvert(locations[l].break_time_seconds)})
						}
						
						
						var contentString = infoBox(marker.icon, header, contentarr,'')
						
						infowindow.setContent(contentString);
						currentwindow = infowindow;
						infowindow.open(map, marker);
						//console.log(marker.position.toJSON());
						console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
						}
					})(marker, l));
					}
					marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
						icon:require('../../assets/icons/track_start.png'),
						map: map,	
					});
				window.google.maps.event.addListener(marker, 'click', (function(marker) {
						return function() {
							//	console.log(clusters[0])
							
							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						var contentarr = []
						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						var header = "Starting Point"
						contentarr.push({"key":"Started at", "value":routeinfo.start_time})
						
						var contentString = infoBox(marker.icon, header, contentarr,'')
							
						infowindow.setContent(contentString);
						currentwindow = infowindow;
						infowindow.open(map, marker);
						console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
						}
					})(marker));
				marker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(arr[(arr.length-1)].lat, arr[(arr.length-1)].lng),
					icon:require('../../assets/icons/truck-end.png'),
					map: map,	
				});
				window.google.maps.event.addListener(marker, 'click', (function(marker) {
						return function() {
							//	console.log(clusters[0])
							
						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
						var contentarr = []
						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						var header = "End Point"
						contentarr.push({"key":"End at", "value":routeinfo.end_time})
						
						var contentString = infoBox(marker.icon, header, contentarr,'')
							
						infowindow.setContent(contentString);	
						infowindow.setContent(contentString);
						currentwindow = infowindow;
						infowindow.open(map, marker);
						console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
						}
					})(marker));
			
            }
            try{
                if(tolls.length > 0)
                {
                    var infowindow = new window.google.maps.InfoWindow();
                    var l;
                    tolls.map(function(e,index){
                        var tollMarker = new window.google.maps.Marker({
                            position: new window.google.maps.LatLng(e.lat, e.lon),
                            icon:require('../../assets/icons/barrier.png'),
                            map: map,
                        });
                        window.google.maps.event.addListener(tollMarker, 'mouseover', (function(tollMarker, index) {
                            return function() 
                            {
                                var contentarr = []
                                var header = "Toll - "+e.name
                                contentarr.push({"key":"Address", "value":e.location})
                                var contentString = infoBox(tollMarker.icon, header, contentarr)
                                infowindow.setContent(contentString);
                                var currentwindow = infowindow;
                                infowindow.open(map, tollMarker);
                            }
                        })(tollMarker, index));
                        window.google.maps.event.addListener(tollMarker, 'mouseout', function() {
                            infowindow.close();
                            });
                    })  
                    
                }
            }catch(e){}
            
        
            map.fitBounds(bounds)

	}

    onClickShowTruckLocation(e){
        console.log("CLiclable ", e)
        var lat = parseFloat(e.data.lat);
		var lng =parseFloat(e.data.lng);
		var data =e.data;
		map.setCenter(new window.google.maps.LatLng(lat,lng));
		map.setZoom(22);
		var markerLatlng = new window.google.maps.LatLng(lat, lng);
		//var image =  require('../../assets/icons/truckblue.png');
		var image =  require('../../assets/icons/gmarker.png');
		
		var marker = new window.google.maps.Marker({
			position: markerLatlng,
			map: map,
			title:data.truck_no,
			icon:image
		});


		var contentarr = []
		var header = data.truck_no
		//contentarr.push({"key":"Reached On", "value":getDDMMYYYYHHMMSS(data.reached_on)})
		contentarr.push({"key":"City/Area", "value":data.area})
		contentarr.push({"key":"State", "value":data.state})

		var contentString = infoBox(marker.icon, header, contentarr,'')
			
		var infowindow = new window.google.maps.InfoWindow({
			content: contentString
		});
		
		marker.setAnimation(window.google.maps.Animation.DROP)
		marker.addListener('click', function() {
			infowindow.open(map, marker);
		});
		
		marker.setMap(map);
		
    }
    showmarkers(event)
    {
        console.log(event.target.value);
        if(event.target.value == 0)
        {
            viewMarkersArr.map(function(e){
				e.setVisible(true);
            });
            this.setState({
                showmarkers : 1
            })
        }
        else
        {
			viewMarkersArr.map(function(e){
				e.setVisible(false);
            });
            this.setState({
                showmarkers : 0
            })
        }
    }

    
    onChangeCluster(clusteritem){
		this.setState(
			{ clusteritem },
			() => {
                console.log(`Option selected:`, this.state.clusteritem)
                if(this.state.clusteritem.value != "" && this.state.clusteritem.value != undefined)
                {
                    var rowarray = []
                    // console.log("this.state.clusters onChange ", this.state.clusters)
                    if(this.state.clusters.length > 0)
                    {
                        var clusttrs = this.state.clusters[0];
                        // console.log("clusttrs ", clusttrs)
                        clusttrs.forEach((item,key) => {
                            // console.log("item ", item)
                            if(key == this.state.clusteritem.value)
                            {
                                if(item.length > 0)
                                {
                                    item.map((itm) => {
                                        rowarray.push(itm)
                                    })
                                }
                            }
                        })
                        this.setState({
                            rowData:rowarray,
                            mapinfo:{coords:rowarray,breaks:[]}
                        })
                    }
                    else
                    {
                        this.setState({
                            rowData:this.state.originalrowdata,
                            mapinfo:this.state.allmapinfo
                        })
                    }
                    
                   
                }
                else
                {
                    this.setState({
                        rowData:this.state.originalrowdata,
                        mapinfo:this.state.allmapinfo
                    })
                }
            }
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    

    render(){
    	let order_cnt=[];

    	const dStyles={
				width:'100%',
				height:'100%'
		}
		const modalStyles  = {
            width:'500px !important',
            }
    const { open } = this.state;
    const columnwithDefs=[
          {
              headerName:"",
              field:"",
              width:50,
              cellRenderer:'trucklocaction'
          },
          {
              headerName: "Truck no",
              field: "truck_no",
              width: 100,
              filter: true,
              resizable: true
              
          },
          {
              headerName: "Latitude",
              field: "lat",
              width: 120,
              filter: true,
              resizable: true,
              valueGetter:function(params){
                  return params.data.lat.toFixed(6);
              }
              
          },
          {
              headerName: "Longitude",
              field: "lng",
              width: 120,
              filter: true,
              resizable: true,
              valueGetter:function(params){
                  return params.data.lng.toFixed(6);
              }
              
          },
          
          {
            headerName: "Cluster",
            field: "cluster",
            width: 100,
            filter: true,
            resizable: true
            
        },
        //   {
        //       headerName: "Last Known State",
        //       field: "state",
        //       width: 140,
        //       filter: true,
        //       resizable: true
              
        //   },
        //   {
        //       headerName: "Last Know City",
        //       field: "city",
        //       width: 140,
        //       filter: true,
        //       resizable: true
              
        //   },

        //   {
        //     headerName: "Last Know Area",
        //     field: "area",
        //     width: 140,
        //     filter: true,
        //     resizable: true
            
        // },
        //   {
        //       headerName: "Location Type",
        //       field: "location_type",
        //       width: 140,
        //       filter: true,
        //       resizable: true
              
        //   },
        {
            headerName: "Area / City",
            field: "area",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
          headerName: "State",
          field: "state",
          width: 130,
          filter: true,
          resizable: true,
      },
          {
              headerName: "Speed (Kms)",
              field: "speed",
              width: 80,
              filter: true,
              resizable: true
              
          },
          {
              headerName: "Packet Time",
              field: "stime",
              width: 140,
              filter: true,
              resizable: true,
              valueGetter:function(params){
                  //console.log(params);
                  if(params.data.stime != ""){
                    return getHyphenDDMMMYYYYHHMM(params.data.stime);
                  }
                  else{
                      return "";
                  }
                  
             },
              // valueGetter:function(params){
              // 	console.log(params);
              // 	return params.data.stime;
              // }
            //   // filter: "agDateColumnFilter",
				comparator: dateComparator,
				// filterParams: {
				// 	browserDatePicker: true,
				// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
				// 	// provide comparator function
				// 	comparator: function(filteredDate,cellValue,secondDate) {
                //         cellValue = cellValue.replace(/\//g,"-")
				// 		if(cellValue != "" && cellValue != " "){
				// 			cellValue = cellValue.split(" ")[0].split("-");
				// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
				// 			var cellDate = new Date(cellValue);
				// 			if(filteredDate.getMonth() < 10){
				// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			else{
				// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             if(filterValue.split("-")[2].length < 2)
				// 			{
				// 				var temp = filterValue;
				// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
				// 			}
				// 			var filteredDate = new Date(filterValue);
				// 			cellDate = cellDate.getTime();
				// 			filteredDate = filteredDate.getTime();
                //             console.log(cellDate,filteredDate);
                //             console.log(cellValue,filterValue);
				// 			if(cellDate === filteredDate)
				// 			{
				// 				return 0;
				// 			}
				// 			if(cellDate < filteredDate){
				// 				return -1;
				// 			}
				// 			if(cellDate > filteredDate)
				// 			{
				// 				return 1;
				// 			}
				// 		}
				// 	}
				// },
          },
          {
                headerName: "Received On",
                field: "received_on",
                width: 140,
                filter: true,
                resizable: true,
                valueGetter:function(params){
                    console.log(params.data.received_on);
                    if(params.data.received_on != "NaT")
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.received_on);
                    }
                    else{
                        return "";
                    }
                   
                },
                // // filter: "agDateColumnFilter",
				comparator: dateComparator,
				// filterParams: {
				// 	browserDatePicker: true,
				// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
				// 	// provide comparator function
				// 	comparator: function(filteredDate,cellValue,secondDate) {
                //         cellValue = cellValue.replace(/\//g,"-")
				// 		if(cellValue != "" && cellValue != " "){
				// 			cellValue = cellValue.split(" ")[0].split("-");
				// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
				// 			var cellDate = new Date(cellValue);
				// 			if(filteredDate.getMonth() < 10){
				// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			else{
				// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             if(filterValue.split("-")[2].length < 2)
				// 			{
				// 				var temp = filterValue;
				// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
				// 			}
				// 			var filteredDate = new Date(filterValue);
				// 			cellDate = cellDate.getTime();
				// 			filteredDate = filteredDate.getTime();
				// 			// console.log(cellDate,filteredDate);
				// 			if(cellDate === filteredDate)
				// 			{
				// 				return 0;
				// 			}
				// 			if(cellDate < filteredDate){
				// 				return -1;
				// 			}
				// 			if(cellDate > filteredDate)
				// 			{
				// 				return 1;
				// 			}
				// 		}
				// 	}
				// },
              
          },
          {
              headerName: "Time from previous point",
              field: "time_from_prev_point",
              width: 240,
              filter: true,
              resizable: true,
              valueGetter : function(params)
              {
                return ConvertSeconds(params.data.time_from_prev_point);
              }
              
          },
          {
              headerName: "Distance from previous point (KM)",
              field: "dist_from_prev_point",
              width: 240,
              filter: true,
              resizable: true,
              valueGetter : function(params)
              {
                return  convertMeters(params.data.dist_from_prev_point);
              }
              
          },
          
    ]
        var listData = [] 
        this.state.filteredData.map(function(e){
        listData.push(<li className="dropdown-truck-no">{e}</li>)
        })
        return (
            <div >
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                    {this.state.alert}
                {/*Container-fluid starts*/} 
                <div className="container-fluid">
                	 <div className="row col-xl-12 col-lg-12">
                         <div className={"trucks-dropdown "+this.state.showTrucksList}>
                            <ul>
                                {listData}
                            </ul>
                         </div>
	                	 <form className="row theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler}>
	                    	<div className="col-xl-3 col-lg-3 form-group">
	                    		{/*<select name="truck_no" className="form-control" onChange={this.handlerForm.bind(this)}>
	                    			<option value="">Truck No</option>
	                    			{this.selectOptionsItems()}
	                    		</select>
								*/}
								<input type="text" name="truck_no" id="inputTruck"  placeholder="Truck No" autoComplete="off" className="form-control" onFocus={this.initalList.bind(this)} onChange={this.handlerForm.bind(this)} />
	                    	</div>
	                    	<div className="col-xl-3 col-lg-3 form-group">
                                <Datetime 
                                inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete:'off' }} 
                                dateFormat="YYYY-MM-DD" 
                                timeFormat="HH:mm:ss"  
                                name="startDate" 
                                onChange={this.handlerStartDateTime.bind(this)} />
	                    	</div>

	                    	<div className="col-xl-2 col-lg-2 form-group">
                                <Datetime  inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete:'off' }} 
                                dateFormat="YYYY-MM-DD" 
                                timeFormat="HH:mm:ss"
                                onChange={this.handlerEndDateTime.bind(this)} />
	                    	</div>
                            <div className="col-xl-2 col-lg-2 form-group">
	                    		<input type="number" name="frequency" id="frequency" value={this.state.frequency} placeholder="Data Interval (Mins)" className="validateNumber form-control" onChange={this.checkHandler.bind(this)} />
	                    	</div>
	                    	<div className="col-xl-2 col-lg-2 form-group">
	                    		<button type="submit" className="btn btn-success">Generate</button>
	                    	</div>
	                     </form>
                    </div>
                    {(this.state.rowData != null)?
                        <div className="row col-xl-12 col-lg-12">
                            {/* <button type="button" className={"btn "+(this.state.actgridbtn)} onClick={this.onClickShowGrid.bind(this)}>Grid</button>
                            <button type="button" className={"btn "+(this.state.actmapbtn)} onClick={this.onClickShowMap.bind(this)}>Map</button> */}
                              <div className="col-xl-6 col-lg-6">
                                    <div id="myGrid" style={{ height: "580px",width: "100%",marginTop:'10px'}}  className={"ag-theme-balham "+(this.state.gridshow)}>
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        />


                                    </div>
                                  </div>
                                  {/* {(this.state.mapinfoclick != '')?this.state.mapshow */}
                                <div className={"col-xl-6 col-lg-6 "} style={{padding:"10px"}}>
                                    <div className={"row"}>
                                        <div className={"col-xl-6 col-lg-6 "}>
                                            <input type="checkbox" style={{marginLeft: "2em",marginBottom: "1em",}} value={this.state.showmarkers} onClick={this.showmarkers.bind(this)} /> view markers
                                        </div>
                                        
                                        <div className={"col-xl-6 col-lg-6 "}>
                                            <Select 
                                                placeholder={"Select Cluster"}
                                                closeMenuOnSelect={true}
                                                onChange={this.onChangeCluster.bind(this)} 
                                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                                style={{borderRadius:"0px"}}
                                                value={this.state.clusteritem}
                                                options={this.state.clusteroptions} 
                                            /> 
                                        </div>
                                    </div>
                                    <DrawMap 
                                    context={this} 
                                    mapFor={"truckgps"} 
                                    tolls = {this.state.tolls}
                                    mapinfo={this.state.mapinfo} 
                                    defTransitCoords={""} />
                                   
                                </div> 
                                {/* :""} */}
	                	</div>
                        		
                   :""}

                    <div className={"dataLoadpage " +(this.state.loadshow)}>
                    </div>
                    <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                        <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                    </div>
                </div>
                {/*Container-fluid Ends*/}
                
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        );
    }
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}
$(document).ready(function(){
    $(document).on("click",".dropdown-truck-no",function(){
        var vals = $(this).text();
         $("#inputTruck").val(vals);
         $(".trucks-dropdown").removeClass("show-m");
         $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click",function(e){
        var container = $("#inputTruck");
        if(!container.is(e.target))
        {
            try{
                if($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown")
                {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            }catch(e){}
            
        }
        
        
    })
})

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function initOMS(){
    // Initializing OMS
    oms = new oms.OverlappingMarkerSpiderfier(map, {
      //markersWontMove: true,
      //keepSpiderfied: true,
      //nearbyDistance: 10,
      //circleFootSeparation: 60,
	  //legWeight: 1.5
	  markersWontMove: true,
	markersWontHide: true,
	keepSpiderfied: true,
	basicFormatEvents: true
    });
  }
  function timeConvert(n) {
      var num = n;
      var hours = (num / (3600));
      var rhours = parseInt(hours);
      var minutes = (num-(rhours*3600)) / (60);
      var rminutes = Math.round(minutes);
      return rhours + " hour(s) and " + rminutes + " minute(s).";
  }   

function ConvertMinutes(num){
    var d = Math.floor(num/1440); // 60*24
    var h = Math.floor((num-(d*1440))/60);
    var m = Math.round(num%60);
    return d+" Day(s) "+h+" Hrs "+m+" mins"
}

function ConvertSeconds(totalSeconds)
{
    // var days = Math.floor(num/86400);
    // var hours = Math.floor(num/3600)
    // var minutes = Math.floor(num/60);

    // var seconds = (totalSeconds % 60);
    var minutes = Math.round((totalSeconds % 3600) / 60);
    var hours = Math.round((totalSeconds % 86400) / 3600);
    var days = Math.round((totalSeconds % (86400 * 30)) / 86400);
    // let d = (new Date(t0)) - (new Date(t1));
    // let weekdays     = Math.floor(d/1000/60/60/24/7);
    // let days         = Math.floor(d/1000/60/60/24 - weekdays*7);
    // let hours        = Math.floor(d/1000/60/60    - weekdays*7*24            - days*24);
    // let minutes      = Math.ceil(d/1000/60       - weekdays*7*24*60         - days*24*60         - hours*60);
    return days+" Day(s) "+hours+" Hrs "+minutes+" mins"
}

function convertMeters(metersValue)
{
    return (metersValue/1000).toFixed(2);
}

$(document).ready(function(){
    $(document).on('keyup',".validateNumber",function(){
        // alert("click");
        if(isNaN($(this).val()) == true){
            $(this).val($(this).val().slice(0,-1));
        }
    });
});

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
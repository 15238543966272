import React, {Component} from "react";
import ReactDom,{ render } from 'react-dom';
import axios from 'axios';
import { Redirect } from 'react-router';
import TMenus from './topmenus';


//import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

var images = require.context('../../assets/images/', true);

export default class Token extends Component {

    constructor(props) {
        super(props);
        var refur='';
        
        this.state = {
            style: {},
            email:'',
            password:'',
             refreshMessage:'',
             scope:'',
             client_id:'',
             grant_type:'',
             client_secret:'',
             redirect_uri:'',
             code:''
        };
    }

    componentDidMount() {
        setTimeout(function () {
            this.setState({style: {display:'none'}});
        }.bind(this),1000)
        
          console.log("Access",localStorage.getItem('zohoToken'));
        var params= {
    		token:localStorage.getItem('zohoToken') 
       };
        console.log("PARAMS", params);
	    axios.post('http://autometrics.in/oauth2/refreshtoken',params,{
	        headers:{
	            'Content-Type': 'multipart/form-data',
	            // 'Access-Control-Allow-Origin':"*",
	            // 'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE, TOKEN',
	            'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
	           // 'authorization': 'Zoho-oauthtoken 1000.ecb6dbd17a44125ce5434f5f45148278.a54f59a27d9e9db5383f85ba3002ccf2' 
	        }
	    })
	      .then( response => {
	          localStorage.setItem('zohoToken', response.data.data.access_token);
	    	  localStorage.setItem('zohoRefreshtoken', response.data.data.refresh_token);
	          console.log("Access",localStorage.getItem('zohoToken'));
	          this.setState({
	        	  refreshMessage: "Success"
	          });
	          
	        //console.log(response);
	      })
	      .catch(function (error) {
	        console.log(error);
	      });
    }
    
    changeHandler = (event) => {

        let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
    

    render() {
        let style = this.state.style;
        const background = require('../../assets/images/auth-layer.png');
       
        
        return (
            <div>
                {/* Loader starts */}
                <div className="loader-wrapper" style={style}>
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Have a great day at work today <span>&#x263A;</span></h4>
                    </div>
                </div>
                {/* Loader ends */}

                {/*page-wrapper Start*/}
                <div className="page-wrapper">
                    <div className="container-fluid">
                        {/*login page start*/}
                        <div className="authentication-main" >
                            <div className="row">
                                <div className="col-md-4 p-0">
                                    <div className="auth-innerleft" style ={ { backgroundImage: "url("+background+")" } }>
                                        <div className="text-center">
                                            <img src={require('../../assets/icons/logo.png')}
                                                    className="logo-login" style={{width:'75%'}} alt=""/>
                                               <hr/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 p-0">
                                    <div className="auth-innerright">
                                        <div className="authentication-box">
                                        <TMenus />
                                            <h4>Generate Refresh Access Token</h4>
                                            
                                            <div className="card mt-4 p-4 mb-0">
                                            
                                            	{this.state.refreshMessage}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*login page end*/}
                    </div>
                </div>
                {/*page-wrapper Ends*/}

            </div>

        )
    }
}



/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
// import Consignmentforceclose from '../m';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import UpdateButton from "../layouts/updatelmstates";
import EditButton from "../layouts/editbutton";
//import DeleteButton from "./deleteshipmentDSR";
import Select from 'react-select';
import $ from 'jquery';
import 'react-datetime/css/react-datetime.css';
import { verifyext, verifycsvdata } from "../common/verifyext";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");




export default class AssetConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            frameworkComponents: {
                UpdateButton: UpdateButton,
                EditButton: EditButton,
                // DeleteButton:DeleteButton,
            },
            modified_by: "",
            basicTitle: '',
            reasonforceclose: "",
            bulkslide: "",
            file: "",
            uploadFile: "",
            basicType: "default",
            loadshow: 'show-n',
            forceCloseRowNode: "",
            forceclosedata: "",
            csvcontent: "",
            sliderForceCloseTranslate: "",
            overly: '',
            rowData: [],
            showDiv: "",
            uploadDivWidth: "",
            mapinfo: "",
            dealer: "",
            sliderCommentTranslate: "",
            commentsRowData: "",
            consignment_code: "",
            sliderRouteTranslate: "",
            maptruckno: "",
            routeTruck: "",
            rownode: "",
            googelRoutes: "",
            rowSelection: '',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize: 200,
            geofencelist: [],
            geoFenceData: "",
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            dept_code: "",
            pagetitle: "",
            markDeliverRowNode: "",
            markDeliverData: "",
            sliderMarkDeliverTranslate: "",
            csvData: 0,
            holidayEvents: [],
            showCalendar: "show-m",
            showGridData: "show-m",
            vehicleCap: [],
            statesList: [{ label: "ALL", value: "ALL" }],
            gateway_id: [{ label: "ALL", value: "ALL" }],
            rowIds: [],
            freight_type: 1,
            comp_code: "",
            lpNames: [],
            loading_port_code: "",
            source_name: [],
            base_station: [],
            createEventDepts: "",
            rowId: "",

            //components : {datepicker:getDatePicker()}
        }
        this.changeFileHandler = this.changeFileHandler.bind(this);
        this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
        this.checkFileData = this.checkFileData.bind(this);
        // this.onClickEditForm = this.onClickEditForm.bind(this);
    }

    componentDidMount() {
        loadDateTimeScript();
        this.onLoadPageData();


        redirectURL.post("/rakeplanning/getGateWayConfiguration").then((response) => {
            var states = response.data;
            console.log("states", states)
            var statesList = [];
            if (states.length > 0) {
                states.map((item) => {
                    statesList.push({
                        value: item.gateway_id,
                        label: item.gateway_id,
                    })
                })
            }
            this.setState({
                statesList: statesList,
            })
        }).catch((e) => {
            console.log(e);
        })
    }

    onLoadPageData() {
        redirectURL.post("/rakeplanning/getZoneTag").then((response) => {
            console.log(response.data);
            this.setState({
                rowData: response.data,
                overly: "show-n",
            })
        }).catch((e) => {
            console.log(e);
        })
    }

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }



    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onCellClicked = (e) => {
        if (e.colDef.field == "delete") {
            var rowId = e.data._id;
            console.log(e.data._id);
            var qry = {
                rowId: rowId,
                base_station: e.data.base_station,
                user_name: localStorage.getItem('username'),
                email: localStorage.getItem('email'),
                user_role: localStorage.getItem('roles'),
            }
            //var confirm = confirm("Are You Sure! Do you want really delete?");
            if (window.confirm("Are You Sure! Do you want really delete?")) {
                redirectURL.post("/rakeplanning/deleteZoneTag", qry).then((response) => {
                    console.log(response.data);
                    if (response.data.message == "Success") {
                        this.setState({
                            basicTitle: "Deleted Successfully.",
                            basicType: "success",
                            show: true
                        })
                        //window.location.reload();
                        this.onLoadPageData();
                    }
                    else {
                        this.setState({
                            basicTitle: "Failed to Delete.",
                            basicType: "warning",
                            show: true
                        })
                    }
                }).catch((e) => {
                    console.log(e);
                })
            }
        }
    }

    onClickHideAll =() => {
        this.setState({
            loadshow:'show-n',
			overly:'show-n',
            sliderRouteTranslate:'',
            bulkslide: "",
            sliderbulkupload: "",

        });

    }
    updateLmStates(params) {
        //console.log(params);
        if (params.zone_id != '' && params.zone_id != undefined && params.zone_name != '' && params.zone_name != undefined && params.gateway_id != '' && params.gateway_id != undefined) {
            params['user_name'] = localStorage.getItem('username');
            params['email'] = localStorage.getItem('email');
            params['user_role'] = localStorage.getItem('roles');
            redirectURL.post("/rakeplanning/saveZoneTag", params).then((response) => {
                console.log(response);
                if (response.data.message == "Success") {
                    this.setState({
                        basicTitle: "Updated Successfully.",
                        basicType: "success",
                        show: true
                    })
                    //window.location.reload();
                    this.onLoadPageData();
                }
                else {
                    this.setState({
                        basicTitle: "Failed to update.",
                        basicType: "warning",
                        show: true
                    })
                }
            })
        }
        else {
            this.setState({
                basicTitle: "Fields Should not be Empty.",
                basicType: "warning",
                show: true
            })
        }
    }
    // addNewRow()
    // {
    //     // console.log(this.gridApi);
    //     this.gridApi.insertItemsAtIndex(0,[{zone_id:"",zone_name:"",gateway_id:""}])
    //     // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
    // }
    onClickAddButton() {
        this.setState({
            sliderbulkupload: "slider-translate-40p",
            overly: "show-n"
        })
    }
    editForm = (e) => {
        console.log(e, "onedit")
        $("#zone_id").val(e.zone_id);
        $("#zone_name").val(e.zone_name);
        let gateValue = []
        e.gateway_id.map(item => gateValue.push({value: item, label: item}))
        this.setState({
            sliderbulkupload: "slider-translate-40p",
            gateway_id:gateValue,
            rowId: e._id
        })
    }
    showGrid() {
        this.setState({
            showCalendar: "show-n",
            showGridData: "show-m"
        })
    }

    addZoneFormHandler = (event)=> {
        event.preventDefault();

        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": googleAnalytics.page.action.formAction,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        // var bulkData = this.state.csvcontent;
        // var consignment_codes_list=[];
        // bulkData.map(function(e,index){
        //     if(index !=0 && index != (bulkData.length-1))
        //     {
        //         consignment_codes_list.push(e.consignment_code)
        //     }

        // });
        // console.log(bulkData);
        // console.log(consignment_codes_list);
        var formdata = new FormData();
        // var emails = $("input[name=notification_emails]").val();
        // emails = emails.replace(/\s/g,"");
        // if(emails.indexOf(",") != -1 )
        // {
        //     emails = emails.split(",");
        // }
        // else
        // {
        //     emails = [emails]
        // }
        var zone_id = $("#zone_id").val();
        var zone_name = $("#zone_name").val();
        var gateway_id = this.state.gateway_id;
        var rowId = this.state.rowId;

            let gateWayId = []
            gateway_id.map(e=> gateWayId.push(e.value))

        if (zone_id != '' && zone_id != undefined && zone_name != '' && zone_name != undefined && gateWayId.length > 0) {
            formdata.append("zone_id", zone_id)
            formdata.append("zone_name", zone_name)
            formdata.append("gateway_id", gateWayId)
            formdata.append("_id", rowId)

            redirectURL.post("/rakeplanning/saveAddZoneTag", formdata).then((response) => {
                console.log(response);
                if (response.data.message == "Success") {
                    $("#zone_id").val("");
                    $("#zone_name").val("");
                    this.setState({
                        gateway_id: [{ label: "ALL", value: "ALL" }],
                        basicTitle: "Updated Successfully.",
                        basicType: "success",
                        show: true,
                        rowId:"",
                        sliderbulkupload:""
                    })
                    //window.location.reload();
                    this.onLoadPageData();
                }
                else {
                    this.setState({
                        basicTitle: "Failed to update.",
                        basicType: "warning",
                        show: true
                    })
                }
            })
        }
        else {
            this.setState({
                basicTitle: "Fields should not be empty.",
                basicType: "warning",
                show: true
            })
        }
    }
    onClickDataExport() {
        var params = {
            columnKeys: ['zone_id_1', 'zone_name_1', 'gateway_id_1']
        };
        this.gridApi.exportDataAsCsv(params);
    }

    onClickBulkDelete() {
        var rowIds = this.state.rowIds;
        console.log(rowIds, "rowIds")
        if (rowIds.length > 0) {
            var qry = {
                rowIds: rowIds,
                comp_code: this.state.comp_code,
                user_name: localStorage.getItem('username'),
                email: localStorage.getItem('email'),
                user_department: localStorage.getItem('lm_department'),
                staff_id: localStorage.getItem('staff_id'),
                designation: localStorage.getItem('designation'),
                user_role: localStorage.getItem('roles'),
            }
            var confirm = confirm("Are You Sure! Do you want really delete?");
            if (window.confirm("Are You Sure! Do you want really delete?")) {
                redirectURL.post("/rakeplanning/deletebulkZoneTag", qry).then((response) => {
                    console.log(response.data);
                    if (response.data.message == "Success") {
                        this.setState({
                            basicTitle: "Deleted Successfully.",
                            basicType: "success",
                            show: true,
                            rowIds: []
                        })
                        //window.location.reload();
                        this.onLoadPageData();
                    }
                    else {
                        this.setState({
                            basicTitle: "Failed to Delete.",
                            basicType: "warning",
                            show: true
                        })
                    }
                }).catch((e) => {
                    console.log(e);
                })
            }
        }
        else {
            this.setState({
                basicTitle: "Please select the Row(s) to continue.",
                basicType: "warning",
                show: true
            })
        }
    }
    saveGateWayId = (item) => {     
        // console.log(item, "item");
    //    let data = []
    //    item.map(e=> data.push(e.value))
        this.setState({gateway_id:item})
    }
    showBulkUpload() {
        this.setState({
            overly: "show-m",
            bulkslide: "slider-translate"
        })
    }
    resetUpload = () => {
        this.setState({
            bulkslide: '',
            overly: 'show-n',
            file: ''
        });
        document.getElementById("upform").reset();
    }
    changeFileHandler = async (e) => {
        var dt = '';
        const config = {
            headers: [
                // { 
                //     name: 'id',
                //     inputName: 'id',
                //     required: false,
                // },
                {
                    name: 'Zone Id',
                    inputName: 'zone_id',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Zone Name',
                    inputName: 'zone_name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'GateWay Id',
                    inputName: 'gateway_id',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                // { 
                //     name: 'Asset Dept',
                //     inputName: 'asset_dept',
                //     required: true,
                //     requiredError: function (headerName, rowNumber, columnNumber) {
                //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                //     }
                // },
                //    { 
                //         name: 'distance',
                //         inputName: 'distance',
                //         required: true,
                //         requiredError: function (headerName, rowNumber, columnNumber) {
                //             return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                //         }
                //     },
                //     { 
                //         name: 'no_of_days',
                //         inputName: 'no_of_days',
                //         required: true,
                //         requiredError: function (headerName, rowNumber, columnNumber) {
                //             return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                //         }
                //     },
            ]
        }
        var data = CSVFileValidator(e.target.files[0], config)
            .then((csvData) => {
                console.log(csvData)
                this.setState({
                    csvData: csvData.data
                });
                console.log(csvData.data);
            })
            .catch(err => { })
        // console.log("e.target.files[0]",e.target.result);

        var out = new Promise(function (reject, resolve) {
            var reader = new FileReader();
            reader.onload = async function (e) {
                var contents = await e.target.result;
                resolve(contents);
            };
            var tt = reader.readAsText(e.target.files[0]);
        });
        this.setState({
            file: e.target.files[0]
        });
        //this.checkFileData();
        //console.log("e.target.files[0].type ", e.target.files[0]);
        var check = verifyext(e.target.files[0].name);
        //console.log("Check ", check)
        if (check == true) {
            if (e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
                // console.log(fileData);
                // console.log(typeof(fileData))

            }
            else {
                e.target.value = null;
                this.setState({
                    uploadFile: '',
                    show: true,
                    basicType: 'danger',
                    basicTitle: 'Please upload file having extensions .csv only.',
                });
            }
        }
        else {
            e.target.value = null;
            this.setState({
                uploadFile: '',
                show: true,
                basicType: 'danger',
                basicTitle: 'Please upload file having extensions .csv only.',
            });
        }
    }

    checkFileData() {

        var vdata = verifycsvdata(JSON.stringify(this.state.csvData));
        //console.log("verifycsvdata ", vdata)
        if (vdata == 0) {
            this.setState({
                csvData: '',
                show: true,
                basicType: 'danger',
                basicTitle: 'File contains invalid data',
                uploadFile: '',
                file: ""
            });
        }
    }
    uploadBulkFormHandler(event) {
        event.preventDefault();
        var fileData = this.state.csvData;
        // Defining function to get unique values from an array

        // var uniqueData = fileData.filter(function(el, i, x) {
        //     return x.some(function(obj, j) {
        //         return obj.city === el.city && (x = j);
        //     }) && i == x;
        // });
        console.log(fileData, "fileData")
        var flag = 0;
        // console.log("JSON.stringify(this.state.csvData) ", JSON.stringify(this.state.csvData))
        var vdata = verifycsvdata(JSON.stringify(this.state.csvData));
        //console.log("verifycsvdata ", vdata)
        if (vdata == true) {
            this.setState({
                csvData: '',
                show: true,
                basicType: 'danger',
                basicTitle: 'File contains invalid data',
                uploadFile: '',
                file: ""
            });
        }
        else {
            if (flag == 0) {
                if (fileData.length > 0) {
                    this.setState({
                        overly: "show-m",
                        loadshow: "show-m",
                    })
                    var reqParams = {
                        csvData: fileData,
                    }
                    reqParams['user_name'] = localStorage.getItem('username');
                    reqParams['email'] = localStorage.getItem('email');
                    reqParams['user_role'] = localStorage.getItem('roles');
                    console.log("reqParams", reqParams)
                    redirectURL.post("/rakeplanning/savebulkZoneTag", reqParams).then((response) => {
                        var resp = response.data.message;
                        console.log(resp)
                        this.setState({
                            basicTitle: resp,
                            basicType: "success",
                            show: true,
                            bulkslide: '',
                            loadshow: "show-n",
                            overly: 'show-n',
                        })
                        //window.location.reload();
                        this.onLoadPageData();
                    }).catch((e) => {
                        console.log(e);
                    })
                }
                else {
                    this.setState({
                        basicTitle: "Invalid File",
                        basicType: "warning",
                        show: true,
                    })
                }
                $('#upform')[0].reset();
            }
            else {
                $("#bulkUploadID").val();
                this.setState({
                    basicTitle: "Fields Should not be Empty",
                    basicType: "warning",
                    show: true,
                })
                $('#upform')[0].reset();
            }
        }
    };

    onRowSelection(event) {
        var rowIds = [];
        var rwCount = event.api.getSelectedNodes();
        rwCount.map((item) => {
            rowIds.push(item.data._id)
        });

        this.setState({
            rowIds: rowIds
        })
    }

    render() {
        var showActions = false;
        var showDelete = true;
        if (this.state.pageAccess == 2) {
            var showActions = true;
        }
        if (this.state.pageAccess == 3) {
            var showDelete = false;
        }
        var columnwithDefs = [

            {
                headerName: "Zone Id",
                field: "zone_id",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                //     cellEditor: 'agSelectCellEditor',
                //     cellEditorParams: {
                //         values: this.state.asset_id ,
                // },
            },
            {
                headerName: "Zone Name",
                field: "zone_name",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                //     cellEditor: 'agSelectCellEditor',
                //     cellEditorParams: {
                //         values: this.state.Asset_name ,
                // },            
            },
            {
                headerName: "GateWay Id",
                field: "gateway_id",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: this.state.statesList,
                },
            },
            // {
            //     headerName: "Asset Dept",
            //     field: "asset_dept",
            //     width:150,
            //     filter: true,
            //     resizable: true,
            //     editable:true,
            //     cellEditor: 'agSelectCellEditor',
            //     cellEditorParams: {
            //         values: this.state.asset_dept ,
            // },            
            // },
            // {
            //     headerName: "Distance",
            //     field: "distance",
            //     width:200,
            //     filter: true,
            //     resizable: true,
            //     editable:true,
            //     cellEditor: NumericCellEditor,
            // },
            // {
            //     headerName: "No Of Days",
            //     field: "no_of_days",
            //     width:200,
            //     filter: true,
            //     resizable: true,
            //     editable:true,
            //     cellEditor: NumericCellEditor,
            // },
            {
                pinned: "left",
                headerName: "",
                field: "_id",
                width: 150,
                resizable: true,
                editable: false,
                cellRendererSelector: function (params) {
                    var rendComponent = {
                        component: 'EditButton'
                    };
                    return rendComponent;
                },
                filter: false,
            },
            {
                pinned: "left",
                headerName: "",
                field: "delete",
                width: 80,
                resizable: true,
                editable: false,
                cellRenderer: function (params) {
                    return '<i class="icofont icofont-ui-delete"></i>';
                },
                filter: false,
            },

            // {
            //     pinned:"left",
            //     headerName: "id",
            //     field: "id",
            //     width:100,
            //     filter: true,
            //     resizable: true,
            //     editable:false,            
            // },
        ]

        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>

                <div className="card col-xl-12">
                    <div className="card-header">
                        <h5>
                            <i className="icofont icofont-truck-loaded cus-i"></i> <span>Zone Tag Screen</span>
                            <button className="btn btn-success" style={{ float: "right" }} onClick={this.onClickAddButton.bind(this)}>Add Zone</button>
                            <button className="btn btn-info" style={{ float: "right", marginRight: "10px" }} onClick={this.showBulkUpload.bind(this)}>Import Data</button>
                            <button className="btn btn-primary" style={{ float: "right", marginRight: "10px" }} onClick={this.onClickDataExport.bind(this)}>Export Data</button>
                            {/*<button className="btn btn-danger" style={{float:"right",marginRight:"10px"}} onClick={this.onClickBulkDelete.bind(this)}>Delete</button> */}
                        </h5>
                    </div>
                    <div className="card-body col-xl-12 col-lg-12">
                        <div id="myGrid" style={{ width: "100%", height: "478px" }} className={"ag-theme-balham " + this.state.showGridData}>
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus={true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                enableRangeSelection={true}
                                onCellClicked={this.onCellClicked}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                // components={this.state.components}
                            //rowSelection={false}
                            //onRowSelected={this.onRowSelection.bind(this)}
                            // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                            />
                        </div>
                    </div>
                </div>


                {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
                <div className={"slide-r " + (this.state.bulkslide)} style={{ overflow: "hidden" }}>
                    <h3 className="subH">Bulk Upload</h3>
                    <div className="slide-r-body" style={{ position: "relative" }}>

                        <div className="container-fluid">
                            <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>

                                <div className="form-group mt-20p">
                                    <label className="">Upload File</label>
                                    <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler} className="form-control" required />
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                    <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                </div>
                            </form>
                            <div className="form-group">
                                <a className="btn btn-primary" href={require('../../assets/json/zone_tag.csv')} target="_blank">Sample Template</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"slide-r " + (this.state.sliderbulkupload)} style={{ overflow: "hidden" }}>
                    <h3 className="subH">ADD Zone
                    <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll} >X</span>
                    </h3>

                    <div className="slide-r-body" style={{ position: "relative" }}>

                        <div className="container-fluid">
                            <form method="POST" id="upform" className="theme-form" onSubmit={this.addZoneFormHandler}>
                                <div className="form-group ">
                                    <label className="">Zone Id</label>
                                    <input type="text" name="zone_id" id="zone_id" className="form-control" />
                                </div>
                                <div className="form-group ">
                                    <label className="">Zone Name</label>
                                    <input type="text" name="zone_name" id="zone_name" className="form-control" />
                                </div>
                                {/* <div className="form-group ">
                        <label className="">GateWay Id</label> 
                        <input type="text" name="cluster_breaks"   className="form-control" />
                    </div> */}
                                <div className="form-group">
                                    <label>GateWay Id </label>
                                    <Select
                                        placeholder={"Select id"}
                                        closeMenuOnSelect={true}
                                        isMulti={true}
                                        onChange={this.saveGateWayId}
                                        className={"border-radius-0"}
                                        style={{ borderRadius: "0px" }}
                                        value={this.state.gateway_id}
                                        options={this.state.statesList} />
                                </div>

                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>
                            <div className="form-group">
                                <p style={{ color: "#ff0000" }}>* Note : All fields related to the form are mandatory and cannot be empty</p>

                                {/* <p style={{color:"green"}}>* Please use the below fields as headers while uploading</p>
                        <p style={{color:"cornflowerblue"}}> -> consignment_code</p>
                        <p style={{color:"cornflowerblue"}}> -> reason</p> */}

                            </div>
                            <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll}></div>
                            {/* <div className="form-group">
                        <a className="btn btn-primary" href={require('../../assets/json/bulk_batch_wise_sample.csv')} target="_blank">Sample Template</a>
                    </div> */}
                            <div className="form-group">
                                {/* <a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a> */}

                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll}></div>
            </div>

        );
    }
}

function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber1(date) {
    console.log(date.length);
    console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    console.log(yearNumber, monthNumber, dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        //mask:'39-19-9999 29:59',
        format: 'd-m-Y'
    });
    $('.datetimepicker_date').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker: false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    // console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';

import axios from 'axios';
import Multiselect from 'multiselect-dropdown-react';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class LmAddTransporters extends Component {
 
    constructor(props) {
        super(props);

        this.state = {
			pageTitle: "Add LM Transporters",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
        	erroMessage:'',
            successMessage:'',
            show: false,
            basicTitle:'',
			basicType:"default",
			transporters:[], 	      
			lmStates:[], 	      
			vehicleTypes:[],
			selTransporter:[],
			selStates:[],
			selVehicleTypes:[]     
        };

	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount = async () =>{
        loadDateTimeScript();
    	if (googleAnalytics.page.enableGA) {
			this.logPageView();
		}
    	await redirectURL.post('/lmconsignees/prtTransportersList')
    	.then((response) => {
			//console.log(response.data);
			var transporters = response.data;
			var items = [];
            if(transporters.length > 0)
            {
                transporters.map((item) =>{
                    items.push({"value":item.transporter_code,"label":item.transporter_name})
                })
            }
            this.setState({
				transporters:items
            })
    	})
    	.catch(function(error){
    		console.log(error)
		});

		await redirectURL.post("/lmconsignees/getLmStates").then((response)=>{
			var lmStates = response.data;
			var items = [];
            if(lmStates.length > 0)
            {
                lmStates.map((item) =>{
                    items.push({"value":item.id,"label":item.state})
                })
            }
            this.setState({
				lmStates:items
            })
		}).catch((e)=>{
			console.log(e);
		})

		await redirectURL.post("/lmconsignees/getLmVehicleCapacity").then((response)=>{
			var vehicleTypes = response.data;
			var items = [];
            if(vehicleTypes.length > 0)
            {
                vehicleTypes.map((item) =>{
                    items.push({"value":item.id,"label":item.vehicle_cap})
                })
            }
            this.setState({
				vehicleTypes:items
            })
		}).catch((e)=>{
			console.log(e);
		})
    }

    onChangeTransporter(selTransporter){
        this.setState(
            { selTransporter },
            () => console.log(`Transporter Option selected:`, this.state.selTransporter)
        );		
	}

	onChangeStates(selStates){
        this.setState(
            { selStates },
            () => console.log(`States Option selected:`, this.state.selStates)
        );
	}	
	
	onChangeVehicleTypes(selVehicleTypes){
        this.setState(
            { selVehicleTypes },
            () => console.log(`Vehicle Type Option selected:`, this.state.selVehicleTypes)
        );
	}

    closeAlert = () => {
        this.setState({
            show: false
        });
        window.location.reload();
    }
    
	formHandler = (event) => {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmitted,
			}
			googleAnalytics.logEvent(eventOptions);
        }
        var transporter_code = this.state.selTransporter.value;
        var transporter_name = this.state.selTransporter.label;
        var states = this.state.selStates;
        var vehicleTypes = this.state.selVehicleTypes;
        var params = {
            "transporter_code" : transporter_code,
            "transporter_name" : transporter_name,
            "states" : states,
            "vehicleTypes" : vehicleTypes
        };
        console.log(params);
        redirectURL.post("/lmconsignees/savelmtransporter",params).then((response) => {
            this.setState({
                basicTitle:"Success",
                basicType : "success",
                show : true
            });
            //window.location.reload();
        })
	}  
    
    render(){
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>{this.state.pageTitle}</span>
				       			</h5>
				   			</div>
			   				<div className="row card-body">
				   			
							<form className="theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler}>
                                <div className="row col-xl-12">
	                    			<div className="col-xl-4 col-lg-4">
					               		<div className="form-group">
					               			<label className="c-lbl">Transporter *:</label>
											<Select 
                                            placeholder={"Select Transporter"}
                                            closeMenuOnSelect={true}
                                            onChange={this.onChangeTransporter.bind(this)}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            style={{borderRadius:"0px"}}
                                            className="basic-multi-select"
                                            options={this.state.transporters} />
					               		</div>
				               		</div>
                                    <div className="col-xl-4 col-lg-4">
					               		<div className="form-group">
					               			<label className="c-lbl">States :</label>
					               			<Select 
                                            placeholder={"Select States"}
                                            closeMenuOnSelect={true}
                                            onChange={this.onChangeStates.bind(this)}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            style={{borderRadius:"0px"}}
											className="basic-multi-select"
											isMulti={true}
                                            options={this.state.lmStates} />
					               		</div>
				               		</div>
                                    <div className="col-xl-4 col-lg-4">
					               		<div className="form-group">
					               			<label className="c-lbl">Vehicle Types :</label>
					               			<Select 
                                            placeholder={"Select Vehicle Types"}
                                            closeMenuOnSelect={true}
                                            onChange={this.onChangeVehicleTypes.bind(this)}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            style={{borderRadius:"0px"}}
											className="basic-multi-select"
											isMulti={true}
                                            options={this.state.vehicleTypes} />
					               		</div>
				               		</div>
                                </div>

                                <div class="hr-dashed"></div>

                                <div className="row col-xl-12">
                                    <div className="col-xl-3 col-lg-3">
					           			
                                        <div className="form-group">
					               			<button type="submit" className="btn btn-success cs-btn" id="saveGeo">Submit</button>
					               			{/* <a href="/manage/geofences" className="btn btn-info cs-btn">Cancel</a> */}
					               			
					               		</div>
									</div>
                                </div>
				           		</form>
			           		</div>
		           		</div>
	           		</div>
                </div>
            </div>
        );
    }
}
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
        format:'d-m-Y',
        minDate:true,
		timepicker:false,
    });
}
import React, { Component } from 'react';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Select from 'react-select';
import CountUp from 'react-countup';
import UserLogDashboardChart from "./userlogdashboardChart";
import UserLogScreenWiseChart from "./userlogscreenwisechart";
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
    
export default class UserLogDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mcategories:[],
            monthwise:[],
            scategories:[],
            screenwise:[],
            year:{"value":"","label":"Select"},
            month:{"value":"","label":"Select"},
            dept_code:{"value":"SNDG","label":"Sales and Dispatch"},
            mcolorcodes:[],
            scolorcodes:[],
            loadshow:'show-n',
            overly:'show-n',
            overalldeptwise:[],
            odeptcategories:[],
            overallscreenwise:[],
            overallscategories:[],
            totalusers:0,
            overalllogins:0,
            yearlylogins:0,
            total_screens:0,
            overall_screens_usage:0,
            yearly_screens_usage:0
            
        }
        this.loaduserlogdata = this.loaduserlogdata.bind(this);
        this.onSubmitGetData = this.onSubmitGetData.bind(this);
    }

    componentWillMount(){
        // var year = moment.parseZone().format("YYYY");
        // this.setState({
        //     year:year
        // })
        // var parameters = {
        //     user_type:["ORGUSER"],
        //     year:year
        // }
        // this.loaduserlogdata(parameters)
    }
    
    componentDidMount(){
        var year = moment.parseZone().format("YYYY");
        this.setState({
            year:{value:year,label:year},

        })
        if(this.state.dept_code.value != "" && this.state.dept_code.value != undefined)
        {
            var dept_code = this.state.dept_code.value;
        }
        else{
            var dept_code = "SNDG";
        }
        var parameters = {
            user_type:["ORGUSER"],
            year:year.toString(),
            dept_code:"SNDG"
        }
        this.loaduserlogdata(parameters)
    }
    

    onClickHideAll(){
        this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:"",
        
        });
        
    }
    loaduserlogdata(parameters)
    {
        var cyear = moment.parseZone().format("YYYY");
        var cmonthe = moment.parseZone().format("MM");
        var currentmonth = parseInt(cmonthe)-1;
        this.setState({
            monthwise:[],
            screenwise:[],
            loadshow:'show-m',
            overly:'show-m',
        })
        var mons = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        var mcolorcodes =  ['#F02A5A', '#4F8BF0','#A24341', '#92C144','#1A9392', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce','#92A8CD','#A47D7C', '#B5CA92','#2b908f'];
        var scolorcodes =  ['#1aadce', '#910000', '#8bbc21', '#0d233a', '#f15c80', '#F02A5A', '#4F8BF0','#A24341', '#92C144','#1A9392','#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8' ];
        // console.log("mmmasd ", mons["Jan"]);
        redirectURL.post("/dashboard/userlogdata",parameters)
        .then((response) => {
            console.log("response ", response.data);
            var countersdata = response.data.countersdata;
            var screensdata = response.data.screensdata;
            try{
                var totalusers = countersdata.total_users;
                var overalllogins = countersdata.overall_logins;
                var yearlylogins = countersdata.total_yearly_logins;
                
                var total_screens = screensdata.total_screens;
                var overall_screens_usage = screensdata.overall_screens_usage;
                var yearly_screens_usage = screensdata.yearly_screens_usage;
                
            }
            catch(e){

            }
            var mwise = response.data.monthwise;
            var records = JSON.parse(mwise.monthwise);
            var overalldeptwise = JSON.parse(mwise.deptwise);
            var swise = response.data.screenwise;
            // var overalldeptwisescrns = JSON.parse(swise.deptwise_screen);
            var overalldeptwisescrns = [];
            console.log("records ", records)
            var allscreenwise = JSON.parse(swise.screenwise);
            var monthwise = [];
            var categories = [];
            var logins = []
            if(records.length > 0)
            {
                records.map((item) => {
                    // console.log(currentmonth +"<="+ mons.indexOf(item.month) +"&&"+ cyear +"=="+ item.year)
                    if(cyear == item.year)
                    {
                        if(mons.indexOf(item.month) <= currentmonth)
                        {
                            categories.push(item.month);
                            logins.push({
                                name: item.month,
                                y: item.no_of_logins
                            });
                        }
                    }
                    else{
                        categories.push(item.month);
                        logins.push({
                            name: item.month,
                            y: item.no_of_logins
                        });
                    }
                   
                })
            }
            monthwise.push({
                name: 'Logins',
                data: logins
            })


            // Overall Department wise Logins
            var overalldeptdata = [];
            var ocategories = ["SNDG", "LOG-PRT", "LOG-TNP","LOG-SC"];
            var ologins = []
            if(overalldeptwise.length > 0)
            {
                overalldeptwise.map((item) => {
                    // console.log(currentmonth +"<="+ mons.indexOf(item.month) +"&&"+ cyear +"=="+ item.year)
                    if(cyear == item.year)
                    {
                        // ocategories.push(item.dept_name);
                        ologins.push({
                            name: item.dept_name,
                            y: item.deptwise_logins
                        });
                    }
                    else{
                        ologins.push({
                            name: item.dept_name,
                            y: item.deptwise_logins
                        });
                    }
                    
                })
            }
            ologins = ologins.sort(GetSortDescOrder("y"));
            overalldeptdata.push({
                name: 'Logins',
                data: ologins
            })
            // console.log("Logins ", logins)


            // var allscreenwise = response.data.screenwise;
            
            var newArray = allscreenwise.sort(GetSortDescOrder("count"));
            var scrnwise=[]
            // Declare an empty object 
            let totuniqueObject = {}; 
            var totobjTitle;
            // Loop for the array elements 
            for (let i in newArray) { 
  
                // Extract the title 
                totobjTitle = newArray[i]['screen_name']; 
  
                // Use the title as the index 
                totuniqueObject[totobjTitle] = newArray[i]; 
            } 
  
            // Loop to push unique object into array 
            for (var a in totuniqueObject) { 
                scrnwise.push(totuniqueObject[a]); 
            } 

            scrnwise = scrnwise.sort(GetSortDescOrder("count"));
            var scategories = [];
            var screenwise = [];
            var screenwiseitems = [];
            if(scrnwise.length > 0)
            {
                scrnwise.map((item,index) => {
                    if(index < 10 )
                    {
                        scategories.push(item.screen_name)
                        screenwiseitems.push({
                            name: item.screen_name,
                            y: item.count
                        })
                    }
                })
            }
            screenwise.push({
                name:"Screens",
                data:screenwiseitems
            })

            // console.log("screenwise ", screenwise)

            // OVerall Deptwise Screens
            if(overalldeptwisescrns.length > 0)
            {
                var onewArray = overalldeptwisescrns.sort(GetSortDescOrder("count"));
                var oscrnwise=[]
                // Declare an empty object 
                let ototuniqueObject = {}; 
                var ototobjTitle;
                // Loop for the array elements 
                for (let a in onewArray) { 
    
                    // Extract the title 
                    ototobjTitle = onewArray[a]['screen_name']; 
    
                    // Use the title as the index 
                    ototuniqueObject[ototobjTitle] = onewArray[a]; 
                } 
    
                // Loop to push unique object into array 
                for (var a1 in ototuniqueObject) { 
                    oscrnwise.push(ototuniqueObject[a1]); 
                } 

                oscrnwise = oscrnwise.sort(GetSortDescOrder("count"));
                var oscategories = ["SNDG", "LOG-PRT", "LOG-TNP","LOG-SC"];
                var oscreenwise = [];
                // var oscreenwiseitemsnd = [];
                // var oscreenwiseitemprt = [];
                // var oscreenwiseitemtnp = [];
                // var oscreenwiseitemsc = [];
                var oscrnwisegrp = groupBy(oscrnwise, rdata => rdata.dept_code);
                // console.log("oscrnwisegrp ", oscrnwisegrp)
                try{
                    oscrnwisegrp.forEach((item,key) => {
                        // console.log("items ", item)
                        var oscreenwiseitemsnd = [];
                        var oscreenwiseitemprt = [];
                        var oscreenwiseitemtnp = [];
                        var oscreenwiseitemsc = [];
                        if(key == "SNDG")
                        {
                            var sndcountdata = item.sort(GetSortDescOrder("count"));
                            sndcountdata.map((im,i) => {
                                // console.log("iiii ", i)
                                if(i<10)
                                {
                                    oscreenwiseitemsnd.push({
                                        name: im.screen_name,
                                        value: im.count
                                    })
                                }
                                    
                                
                            })
                            oscreenwise.push({
                                name:"Sales and Dispatch",
                                data:oscreenwiseitemsnd
                            })
                        }
                        if(key == "LOG-PRT")
                        {
                            var prtcountdata = item.sort(GetSortDescOrder("count"));
                            prtcountdata.map((im,i) => {
                                if(i<10)
                                {
                                    oscreenwiseitemprt.push({
                                        name: im.screen_name,
                                        value: im.count
                                    })
                                }
                                
                            })
                            oscreenwise.push({
                                name:"Spare Parts",
                                data:oscreenwiseitemprt
                            })
                
                        }
                        if(key == "LOG-TNP")
                        {
                            var tnpcountdata = item.sort(GetSortDescOrder("count"));
                            tnpcountdata.map((im,i) => {
                                if(i<10)
                                {
                                    oscreenwiseitemtnp.push({
                                        name: im.screen_name,
                                        value: im.count
                                    })
                                }
                            })
                            oscreenwise.push({
                                name:"Train and Production",
                                data:oscreenwiseitemtnp
                            })
                
                        }
                        if(key == "LOG-SC")
                        {
                            var sccountdata = item.sort(GetSortDescOrder("count"));
                            sccountdata.map((im,i) => {
                                if(i<10)
                                {
                                    oscreenwiseitemsc.push({
                                        value: im.count
                                    })
                                }
                            })
                            oscreenwise.push({
                                name:"Supply Chain",
                                data:oscreenwiseitemsc
                            })
                
                        }
                        
                        
                    })
                }
                catch(e){}
            }
            // oscreenwise.push({
            //     name:"Sales and Dispatch",
            //     data:oscreenwiseitemsnd
            // },{
            //     name:"Spare Parts",
            //     data:oscreenwiseitemprt
            // },{
            //     name:"Train and Production",
            //     data:oscreenwiseitemtnp
            // },{
            //     name:"Supply Chain",
            //     data:oscreenwiseitemsc
            // })

            // console.log("oscreenwise ", oscreenwise)
            this.setState({
                monthwise:monthwise,
                mcategories:categories,
                mcolorcodes:mcolorcodes,
                screenwise:screenwise,
                scategories:scategories,
                scolorcodes:scolorcodes,
                overalldeptwise:overalldeptdata,
                odeptcategories:ocategories,
                overallscreenwise:oscreenwise,
                overallscategories:oscategories,
                loadshow:'show-n',
                overly:'show-n',
                totalusers:totalusers,
                overalllogins:overalllogins,
                yearlylogins:yearlylogins,
                total_screens:total_screens,
                overall_screens_usage:overall_screens_usage,
                yearly_screens_usage:yearly_screens_usage
            })
        })
    }
    
    yearslist(){
        var items = [];
        var year = moment.parseZone().format("Y");
        var s = parseInt(year)-1;
        for(var y = s;y<=parseInt(year);y++)
        {
            items.push({
                value:y,
                label:y
            })
        }
        // console.log("items ", items)
        return items;
    }
    
    onChangeYear(year){
        this.setState({year},()=>{
        })
    }
    onChangeMonth(month){
        this.setState({month},()=>{
        })
    }
    
    monthslist(){
        var mons = [
            {
                "value":"","label":"All"
            },
            {
                "value":"01","label":"Jan"
            },
            {
                "value":"02","label":"Feb"
            },
            {
                "value":"03","label":"Mar"
            },
            {
                "value":"04","label":"Apr"
            },
            {
                "value":"05","label":"May"
            },
            {
                "value":"06","label":"Jun"
            },
            {
                "value":"07","label":"Jul"
            },
            {
                "value":"08","label":"Aug"
            },
            {
                "value":"09","label":"Sep"
            },
            {
                "value":"10","label":"Oct"
            },
            {
                "value":"11","label":"Nov"
            },
            {
                "value":"12","label":"Dec"
            }
          ]
          return mons;
    }
    onSubmitGetData(event){
        event.preventDefault();
        var syear = this.state.year;
        var smonth = this.state.month;
        var parameters = {
            user_type:["ORGUSER"]
        };
        if(syear.value != "")
        {
            var year = syear.value;
            parameters.year = year.toString();
        }
        if(smonth.value != "")
        {
            var month = smonth.value;
            parameters.month = month;
        }
        
        if(this.state.dept_code.value != "" && this.state.dept_code.value != undefined)
        {
            var dept_code = this.state.dept_code.value;
        }
        else{
            var dept_code = "SNDG";
        }
        parameters.dept_code = dept_code;
        this.loaduserlogdata(parameters);
    }

    onChangeDept(dept_code){
        this.setState({dept_code},()=>{
        })
    }
    
    depts(){
        var mons = [
            {
                "value":"SNDG","label":"Sales and Dispatch"
            },
            {
                "value":"LOG-PRT","label":"Spare Parts"
            },
            {
                "value":"LOG-TNP","label":"Train and Production"
            },
            {
                "value":"LOG-SC","label":"Supply Chain"
            }
          ]
          return mons;
    }
    render() {
        const dStyles={
            width:'100%',
            height:'100%'
        }
    
    
    const highchartoptions = {
        chart: {
            type: 'column'
        },
        title: {
            text: "Monthwise User logs Data"
        },
        subtitle: {
            // text: this.state.subtitle
        },
        xAxis: {
            // categories:this.state.mcategories,
            // crosshair: true
            type:'category'
          },
          yAxis: {
            min: 0,
            title: {
              text: 'No Of Login'
            }
          },
          legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.y:.1f}'
                }
            }
        },
    
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b> of total<br/>'
        },
        series:this.state.monthwise,
        // colors:["#8fe97c", "#e4d354","#7bb5ec","#f7a35c","#8085e9","#f15c80"],
        credits:false
    };

    
    const highchartoptions1 = {
        chart: {
            type: 'column'
        },
        title: {
            text: "Top 10 most viewed screens"
        },
        subtitle: {
            // text: this.state.subtitle
        },
        xAxis: {
            type:'category'
          },
          yAxis: {
            min: 0,
            title: {
              text: 'No Of Views'
            }
          },
          tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
          },
          plotOptions: {
            column: {
              pointPadding: 0.2,
              borderWidth: 0
            }
          },
          series: this.state.screenwise,
        colors:["#8fe97c", "#e4d354","#7bb5ec","#f7a35c","#8085e9","#f15c80"],
        credits:false
    };
    return (
        <div class="container-fluid">
            <div className="card">
                <div className="card-header">
                    <h5>
                        <i className="icofont icofont-pie cus-i"></i> <span>User Log Dashboard</span>
                    </h5>
                </div>
                <div className="card-body">
                    <div className="col-sm-12">
                        <form className="row" onSubmit={this.onSubmitGetData}>
                        <div className="col-sm-2">
                            <label>Department:</label>
                            <Select
                                options={this.depts()}
                                onChange={this.onChangeDept.bind(this)}
                                value={this.state.dept_code}
                            />
                        </div>
                        <div className="col-sm-2">
                            <label>Year:</label>
                            <Select
                                options={this.yearslist()}
                                onChange={this.onChangeYear.bind(this)}
                                value={this.state.year}
                            />
                        </div>
                        <div className="col-sm-2">
                            <label>Month:</label>
                            <Select
                                options={this.monthslist()}
                                onChange={this.onChangeMonth.bind(this)}
                                value={this.state.month}
                            />
                        </div>
                        
                        <div className="col-sm-2 mt-30p">
                            <button type="submit" className="btn btn-warning">Get Data</button>
                        </div>
                        </form>
                    </div>
                    

                    <div className="row p-30px mt-40p" style={{backgroundColor:"#EEE"}}>
                        <div className="col-xl-6 col-lg-6 col-sm-6 beffect">
                            <h4>Overall User Logins:</h4>
                            <div className="crm-numbers pb-0" style={{borderRight:"50px solid #eee"}}>
                                <div className="row">
                                    <div className="col cirlce-d cursorPointer whiteRow p-20px" >
                                        <span className="f13"><i className="icofont icofont-ui-browser f24 txt-info"></i><br /> Total Unique Users</span>
                                        <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.totalusers != '')?this.state.totalusers:0}/></span></h4>
                                        
                                    </div>
                                    
                                    <div className="col cirlce-d cursorPointer whiteRow p-20px" >
                                        <span className="f13"><i className="icofont icofont-ui-browser f24 txt-success"></i><br /> Overall Users Login</span>
                                        <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.overalllogins != '')?this.state.overalllogins:0}/></span></h4>
                                        
                                    </div>
                                    
                                    <div className="col cirlce-d cursorPointer whiteRow p-20px" >
                                        <span className="f13"><i className="icofont icofont-ui-browser f24 txt-danger"></i><br /> Total Users logins in {this.state.year.value}</span>
                                        <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.yearlylogins != '')?this.state.yearlylogins:0}/></span></h4>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-xl-6 col-lg-6 col-sm-6 beffect">
                            <h4>Overall Screens Usage:</h4>
                            <div className="crm-numbers pb-0" style={{borderLeft:"15px solid #eee"}}>
                                <div className="row">
                                    <div className="col cirlce-d cursorPointer whiteRow p-20px" >
                                        <span className="f13"><i className="icofont icofont-ui-browser f24 txt-warning"></i><br /> Total Screens</span>
                                        <h4 className="txt-warning f40"><span className="counter"><CountUp end={(this.state.total_screens != '')?this.state.total_screens:0}/></span></h4>
                                        
                                    </div>
                                    
                                    <div className="col cirlce-d cursorPointer whiteRow p-20px" >
                                        <span className="f13"><i className="icofont icofont-ui-browser f24 txt-secondary"></i><br /> Overall Screens Usage</span>
                                        <h4 className="txt-secondary f40"><span className="counter"><CountUp end={(this.state.overall_screens_usage != '')?this.state.overall_screens_usage:0}/></span></h4>
                                        
                                    </div>
                                    
                                    <div className="col cirlce-d cursorPointer whiteRow p-20px" >
                                        <span className="f13"><i className="icofont icofont-ui-browser f24 txt-danger"></i><br /> Total Screen Usage in {this.state.year.value}</span>
                                        <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.yearly_screens_usage != '')?this.state.yearly_screens_usage:0}/></span></h4>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-40p">
                        {/* <div className="col-sm-12 mt-40p mb-40p">
                            {(this.state.monthwise.length > 0)?
                                <UserLogDashboardChart
                                series={this.state.monthwise}
                                xTitle={"Monthwise User login"}
                                yLabel={"No Of Login"}
                                colorcodes={this.state.mcolorcodes}
                                /> 
                            :""}
                        </div> */}
                        <div className="col-sm-12 mt-40p">

                            {(this.state.screenwise.length > 0)?
                                <UserLogDashboardChart
                                series={this.state.screenwise}
                                xTitle={"Top 10 most viewed screens"}
                                yLabel={"No Of Views"}
                                colorcodes={this.state.scolorcodes}
                                /> 
                            :""}
                               {/* <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions1} /> */}
                        </div>
                    </div>
                    <div className="row mt-40p">
                        <div className="col-sm-12 mt-40p mb-40p">
                            {(this.state.overalldeptwise.length > 0)?
                                <UserLogDashboardChart
                                series={this.state.overalldeptwise}
                                xTitle={"Overall Department Wise User login Year - "+(this.state.year.value)}
                                yLabel={"No Of Logins"}
                                colorcodes={this.state.mcolorcodes}
                                /> 
                            :""}
                        </div>
                        {/* <div className="col-sm-12 mt-40p">

                            {(this.state.overallscreenwise.length > 0)?
                                <UserLogScreenWiseChart
                                series={this.state.overallscreenwise}
                                xTitle={"Overall Department wise Top 3 most viewed screens"}
                                yLabel={"No Of Views"}
                                categories={this.state.overallscategories}
                                colorcodes={this.state.scolorcodes}
                                /> 
                            :""}
                        </div> */}
                    </div>
                </div>
            </div>
            <div className={"dataLoadpage " +(this.state.loadshow)}></div>
                    <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                        <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                    </div>
                    <div className={"overlay "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
        </div>

        );
    }
}

function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "./updateManualExportsBtn";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CellGraphComponent from './cellGraphComponent';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { HardwareDesktopWindows } from 'material-ui/svg-icons';
import Modal from 'react-responsive-modal';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;
var validatedBy="";



export default class PossibleTransshipments extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,

        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          consignmentforceclose:Consignmentforceclose,
          consignmentActions:ConsignmentActions,
          UpdateButton:UpdateButton,
          MarkDeliver : MarkDeliver,
          commentActions : CommentActions
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:50,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        plants:[],
        plant_name:"",
        cellPlants:[],
        consigner_coordinates:[],
        consignee_coordinates:[],
        showSelectDept:"show-m",
        usergridstate:[],
        screenurl:"",
        screentitle:"",
        pagetitle:"",
        eventGridAction: "gridAction",
        tolls : [],
        usermanualmodal:false,
        selectedValue:[{}],
        transhipment: '',
        possible_transhipment: 'activet',
        reports: '',
        activeBtn1:'',
        activeBtn2:"btn-info",
        activeBtn3:''
        
        //components : {datepicker:getDatePicker()}
    }
    this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
    this.onShowGoogleRoute = this.onShowGoogleRoute.bind(this);
  }

componentDidMount(){
    loadDateTimeScript();
    var deptcode="";
    var pagetitle='';
   if(this.props.match.path == "/sndPossibletransshipments")
   {
       deptcode = "SNDG"
        pagetitle = "Sales and Dispatch"
   }
   if(this.props.match.path == "/prtPossibletransshipments")
   {
       deptcode = "LOG-PRT"
        pagetitle = "Spare Parts"
   }
   if(this.props.match.path == "/tnpPossibletransshipments")
   {
       deptcode = "LOG-TNP"
       pagetitle = "Train and Production"
   }
   if(deptcode != "")
   {
        this.setState({
            dept_code : deptcode,
            pagetitle : pagetitle
        });
        var params = {
            dept_code : deptcode
        }
        redirectURL.post("/consignments/getPossibleShipments",params).then((response)=>{
            console.log(response.data);
            this.setState({
                rowData : response.data,
                showSelectDept : "show-n"
            })
        }).catch((e)=>{
            console.log(e);
        })
   }
   else
   {
        if(this.props.match.path == "/Possibletransshipments")
        {
            this.setState({
                dept_code : "SNDG",
                pagetitle : "Sales and Dispatch",
                selectedValue : {value:"SNDG",label:"Sales and Dispatch"}
            });
            var params = {
                dept_code : "SNDG"
            }
            redirectURL.post("/consignments/getPossibleShipments",params).then((response)=>{
                console.log(response.data);
                this.setState({
                    rowData : response.data,
                    showSelectDept : "show-m"
                })
            }).catch((e)=>{
                console.log(e);
            })
        }
   }
   this.logPageView(); // Log page view to GA
    let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
    this.setState({eventAction: eventAction});

    redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
    .then( async (resp) => {
        //console.log("resp ", resp)
        await this.setState({
            usergridstate:resp.data
        });
        await this.restoreGridStates();
    })



}
   
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	    }
 
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}
onCloseUploadDiv = () => {
    this.setState({
        uploadDivWidth:'0%',
        sliderTranslate:"",
        tabsliderTranslate:'',
        showDiv:'show-n',
        sliderTranslatesidebar:"",
        sliderCommentTranslate:"",
        uploadFile:'',
        file:'',
        csvmessage:'',
        csverrmessage:'',
        overly:'show-n',
        loadshow:'show-n'
    });
}
onCloseRouteDiv = () => {
    this.setState({
        uploadDivWidth:'0%',
        sliderRouteTranslate:"",
        sliderCommentTranslate : "",
        sliderSegmentTranslate : "",
        showDiv:'show-n',
        uploadFile:'',
        file:'',
        csvmessage:'',
        csverrmessage:'',
        overly:'show-n'
    });
}

onShowGoogleRoute(consignmentcode, consigneecode, legno,truck_no){
    console.log("GOogle params ", consignmentcode+", "+ consigneecode+", "+legno)
    var reqData = {
        consignment_code:consignmentcode,
        consignee_code:consigneecode,
        leg_no:legno,
        truck_no:truck_no
    }
    redirectURL.post('/consignments/googlemaproutes',reqData, {
        headers:{
            'content-type':'application/json'
        }
    })
    .then((response) => {
        console.log("Google ", response)
        //var gdt = '[{	"lat": 28.367420000000003,	"lng": 76.90442},{	"lat": 28.379795,	"lng": 76.903625},{	"lat": 28.380361999999998,	"lng": 76.899445},{	"lat": 28.383162,	"lng": 76.90275},{	"lat": 28.384619,	"lng": 76.900024},{	"lat": 28.385069,	"lng": 76.900024},{	"lat": 28.384990000000002,	"lng": 76.8998}]' 
        this.setState({
            googelRoutes:response.data.googleroute
        })
    })
    .catch(function(e){
        console.log(e)
    })
}

onShowRouteDiv = (e) => {
    if (googleAnalytics.page.enableGA) {
        let eventOptions = {
            "category": this.state.pagetitle,
            "action": this.state.eventGridAction,
            "label": googleAnalytics.page.action.viewRoute,
        }
        googleAnalytics.logEvent(eventOptions);
    }
    
    console.log("e ", e)
    var consignmentcodes = []
    //console.log("leginfo ", JSON.parse(e.leginfo))
    e.consignment_codes.map(function(k){
        consignmentcodes.push(k);
    })
    console.log("consignmentcodes ", consignmentcodes)
    this.setState({
        loadshow:'show-m'
    });
    var edate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
    var startdate = moment.parseZone(e.gate_out_time).format("YYYY-MM-DD HH:mm:ss")
    var diffreetime = moment.duration(moment(edate).diff(startdate));
    var delaydays =Math.round(diffreetime.asDays());
    if(parseInt(delaydays) > 15)
    {
        edate = moment(startdate).add(15, 'days').format('YYYY-MM-DD HH:mm:ss');
        //console.log(edate,"startdate-diff")
    }
    console.log(delaydays,"delaydays")
    var reqData = {
        truck_no:e.truck_no,
        consignment_codes:consignmentcodes,
        fromdate:startdate,
        todate:edate
    }
    //console.log("reqData: ", reqData);
    redirectURL.post('/trucks/truckmap',reqData)
    .then((response) => {
        var records = response.data;
        console.log("Props data ", response.data)

        this.setState({
            loadshow:'show-m'
        });
        try{
            if(records.coords.length == 0)
            {
                //console.log("records", records.coords);
                this.setState({
                    show: true,
                    basicTitle:'No Route data available',
                    basicType:"danger",
                    loadshow:'show-n'
                });
            }
            else{
                if(records != '')
                {
                    
                    this.setState({
                        sliderRouteTranslate:"slider-translate-60p",
                        showDiv:'show-m',
                        mapinfo:records,
                        dealer:"",
                        consignment_code:"Truck NO : "+e.truck_no,
                        maptruckno:e.truck_no,
                        routeTruck:	{"truck_no":e.truck_no,"startTime":response.data.route_details.start_time,"endTime": response.data.route_details.end_time},
                        loadshow:'show-n',
                        sidebarSubHeader:"Truck Route Details",
                        overly:'show-m',
                        rownode:e,
                        consigner_coordinates:records.consigner_coordinates,
                        consignee_coordinates:records.consignee_coordinates,
                        tolls : records.tolls

                    });
                }
            }
        }
        catch(e){
            if(records.message == "no data found")
            {
                this.setState({
                    show: true,
                    basicTitle:'No Route data available',
                    basicType:"danger",
                    loadshow:'show-n'
                });
            }
            else{
                console.log(e);
            }
        }
        
        
        
    })
    .catch(function (error) {
    console.log(error);
    });
}

closeAlert = () => {
    this.setState({
        show: false
    });
}




onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    
}


deptOptions(){
    var items=[];
    items.push({value:"SNDG",label:"Sales and Dispatch"}); 
    items.push({value:"LOG-PRT",label:"Spare Parts"}); 
    items.push({value:"LOG-TNP",label:"Train and Productions"}); 
    return items;
}

onSelectDept= (dept) =>{
    var deptcode = dept.value;
    if(deptcode != "" && deptcode != undefined )
    {
        this.setState({
            dept_code : deptcode,
            selectedValue : dept,
        });
        var params = {
            dept_code : deptcode
        }
        redirectURL.post("/consignments/getPossibleShipments",params).then((response)=>{
            console.log(response.data);
            this.setState({
                rowData : response.data
            })
        }).catch((e)=>{
            console.log(e);
        })
    }
    
}


updateComments = (params)=>{
    if(params.colDef.field == "transshipment_comment")
    var rowdata = params.data;
    validatedBy = localStorage.getItem("email");
    redirectURL.post("/consignments/setShipmentsData",{"rowdata":rowdata,"validatedBy":validatedBy,"update":"comments"}).then((response)=>{
        
    }).catch((e)=>{
        console.log(e);
    });
}

 
onClickSaveGridState(){
    if (googleAnalytics.page.enableGA) {
        let pageTitle = this.state.pagetitle;
        let eventOptions = {
            "category": pageTitle,
            "action": this.state.eventGridAction,
            "label": "Save Grid Layout",
        }
        googleAnalytics.logEvent(eventOptions);
    }
    window.colState = this.gridColumnApi.getColumnState();
    window.groupState = this.gridColumnApi.getColumnGroupState();
    window.sortState = this.gridApi.getSortModel();
    window.filterState = this.gridApi.getFilterModel();
    
    let screenpage = '';

    if(this.props.match.path == "/sndPossibletransshipments")
    {
        screenpage='snd possible transhipment';
    }
    else if(this.props.match.path == "/prtPossibletransshipments")
    {
        screenpage='prt possible transhipment';
    }
    else if(this.props.match.path == "/tnpPossibletransshipments")
    {
        screenpage='tnp possible transhipment';
    }

    let reqparams = {
        gridcolumns:window.colState,
        gridgroup:window.groupState,
        gridcolsort:window.sortState,
        gridcolfilter:window.filterState,
        userId:localStorage.getItem("userid"),
        screenurl:window.location.pathname,
        screentitle:screenpage
    }
    //console.log("reqparams ", reqparams)
    redirectURL.post("/consignments/saveGridStates", reqparams)
    .then((response) => {
        //console.log("State response ",response.data)
        this.setState({
            show:true,
            basicTitle:"Successfully saved grid layout",
            basicType:"success",
            screenurl:window.location.pathname,
            screentitle:screenpage
        })
    })
    .catch(function(e){
        console.log("Error ", e)
    })
}

restoreGridStates()
{

    if(this.state.usergridstate.length > 0)
    {
        var windowstates = this.state.usergridstate;
        this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
        this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
        this.gridApi.setSortModel(windowstates[0].gridcolsort);
        this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
        
    }
}

resetState = () => {
    if (googleAnalytics.page.enableGA) {
        let pageTitle = this.state.pagetitle;
        let eventOptions = {
            "category": pageTitle,
            "action": this.state.eventGridAction,
            "label": "Reset Grid Layout",
        }
        googleAnalytics.logEvent(eventOptions);
    }
    this.gridColumnApi.resetColumnState();
    this.gridColumnApi.resetColumnGroupState();
    this.gridApi.setSortModel(null);
    this.gridApi.setFilterModel(null);
    //console.log('column state reset');
    var reqparams = {
        userId:localStorage.getItem("userid"),
        screenurl:window.location.pathname,
        screentitle:this.state.screenpage
    }

    redirectURL.post("/consignments/removeUserGridState", reqparams)
    .then((response) => {
        this.setState({
            show:true,
            basicTitle:"Successfully reset default grid layout",
            basicType:"success"
        })
    })

};

    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    returnPageUrl = (btn, arr)=>{
		if(btn === "transhipment"){
			return arr[0];
		}
		else if(btn === "possible_transhipment"){
			return arr[1];
		}
		else{
			return arr[2];
		}
	}
    reloadPageData1 = (btn)=>{
		let currentUrl = this.props.match.path;

		let snd = ["/sndtranshipments", "/sndPossibletransshipments", "/sndtranshipmentreports"];
		let tnp = ["/tnptranshipments", "/tnpPossibletransshipments", "/tnptranshipmentreports"];
		let prt = ["/prttranshipments", "/prtPossibletransshipments", "/prttranshipmentreports"];

		let pageUrl = "/";

		if(snd.includes(currentUrl)){
			pageUrl = this.returnPageUrl(btn, snd);
		}
		else if(tnp.includes(currentUrl)){
			pageUrl = this.returnPageUrl(btn, tnp);
		}
		else if(prt.includes(currentUrl)){
			pageUrl = this.returnPageUrl(btn, prt);
		}
		this.props.history.push(pageUrl);       
    }
    render(){
    const modalStyles  = {
        width:'1300px !important',
    }
    
    const {usermanualmodal} = this.state;
 
    var columnwithDefs = [     
        {
            headerName: "",
            field: "_id",
            colId: "_id",
            width: 50,
            pinned: 'left',
            cellRenderer:'consignmentActions',
            filter: false,resizable: true
            
        }, 
        {
            headerName: "Truck No",
            field: "truck_no",
            width:130,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Consignment Codes",
            field: "consignment_codes",
            width:200,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter : function(params)
            {
                var consignmentCodes = params.data.consignment_codes;
                var cellValue = ""
                if(consignmentCodes.length > 0)
                {
                    consignmentCodes.map(function(e){
                        if(cellValue != "")
                        {
                            cellValue = cellValue+","+e 
                        }
                        else
                        {
                            cellValue = e
                        }
                        
                    });
                    return cellValue;
                }
                else
                {
                    return ""
                }

            }
        },
        // {
        //     headerName: "Department Code",
        //     field: "dept_code",
        //     width:130,
        //     filter: true,
        //     resizable: true,
        //     editable:false
        // },
        {
            headerName: "Transporter Code",
            field: "transporter_code",
            width:130,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Transporter Name",
            field: "transporter_name",
            width:130,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "GPS Provider",
            field: "gps_provider",
            width:130,
            filter: true,
            resizable: true,
            editable:false
        },
        
        
        {
            headerName: "Consignee Code",
            field: "consignee_code",
            width:130,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Consignee Name",
            field: "consignee_name",
            width:130,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Consignee City",
            field: "consignee_city",
            width:130,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Consignee State",
            field: "consignee_state",
            width:130,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Gate Out Time",
            field: "gate_out_time",
            width:130,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter:function(params){
                if(params.data.gate_out_time != "")
                {
                    return moment.parseZone(params.data.gate_out_time).format("DD-MMM-YYYY HH:mm")
                }
                else
                {
                    return "";
                }
                
            },
            //filter: "agDateColumnFilter",
            comparator: dateComparator,
            // filterParams: {
            //     browserDatePicker: true,
            //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
            //     // provide comparator function
            //     comparator: function(filteredDate,cellValue,secondDate) {
            //         cellValue = cellValue.replace(/\//g,"-")
            //         if(cellValue != "" && cellValue != " "){
            //             cellValue = cellValue.split(" ")[0].split("-");
            //             cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
            //             var cellDate = new Date(cellValue);
            //             if(filteredDate.getMonth() < 10){
            //             var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
            //             }
            //             else{
            //                 var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
            //             }
            //             if(filterValue.split("-")[2].length < 2)
            //             {
            //                 var temp = filterValue
            //                 filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
            //             }
            //             var filteredDate = new Date(filterValue);
            //             cellDate = cellDate.getTime();
            //             filteredDate = filteredDate.getTime();
            //             // console.log(cellDate,filteredDate);
            //             if(cellDate === filteredDate)
            //             {
            //                 return 0;
            //             }
            //             if(cellDate < filteredDate){
            //                 return -1;
            //             }
            //             if(cellDate > filteredDate)
            //             {
            //                 return 1;
            //             }
            //         }
            //     }
            // },
        },
        {
            headerName: "Possible Transshipment Flagged At",
            field: "possible_transshipment_flagged_at",
            width:200,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter:function(params){
                if(params.data.possible_transshipment_flagged_at != "")
                {
                    return moment.parseZone(params.data.possible_transshipment_flagged_at).format("DD-MMM-YYYY HH:mm")
                }
                else
                {
                    return "";
                }
                
            },
            //filter: "agDateColumnFilter",
            comparator: dateComparator,
            // filterParams: {
            //     browserDatePicker: true,
            //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
            //     // provide comparator function
            //     comparator: function(filteredDate,cellValue,secondDate) {
            //         cellValue = cellValue.replace(/\//g,"-")
            //         if(cellValue != "" && cellValue != " "){
            //             cellValue = cellValue.split(" ")[0].split("-");
            //             cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
            //             var cellDate = new Date(cellValue);
            //             if(filteredDate.getMonth() < 10){
            //             var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
            //             }
            //             else{
            //                 var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
            //             }
            //             if(filterValue.split("-")[2].length < 2)
            //             {
            //                 var temp = filterValue
            //                 filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
            //             }
            //             var filteredDate = new Date(filterValue);
            //             cellDate = cellDate.getTime();
            //             filteredDate = filteredDate.getTime();
            //             // console.log(cellDate,filteredDate);
            //             if(cellDate === filteredDate)
            //             {
            //                 return 0;
            //             }
            //             if(cellDate < filteredDate){
            //                 return -1;
            //             }
            //             if(cellDate > filteredDate)
            //             {
            //                 return 1;
            //             }
            //         }
            //     }
            // },
        },
        {
            headerName: "Valid",
            field: "valid",
            width:90,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ["Yes","No"],
            },
            valueGetter : function(params){
                if(params.data.valid == undefined)
                {
                    return "Yes";
                }
                else
                {
                    return params.data.valid
                }
            },
            valueSetter: function(params) {
                if (params.oldValue !== params.newValue) {
                    console.log(params.oldValue,params.newValue);
                    var alteredField = params.colDef.field;
                    console.log(alteredField);
                    var rowdata = params.data;
                    console.log(rowdata);
                    rowdata[alteredField] = params.newValue;
                    validatedBy = localStorage.getItem("email");
                    redirectURL.post("/consignments/setShipmentsData",{"rowdata":rowdata,"validatedBy":validatedBy,"update":"valid"}).then((response)=>{
                        
                    }).catch((e)=>{
                        console.log(e);
                    });                        

                }
            }
        },
        {
            headerName: "Comments",
            field: "transshipment_comment",
            width:130,
            filter: true,
            resizable: true,
            editable:true,
            
        },

        
    ]
    return (
      <div class="container-fluid">
            
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>
        <div className="row col-xl-12 col-lg-12">
                        
        <h5>
        <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Transshipments ({this.state.pagetitle}) </span>
        </h5>
        </div> 
        
        {this.state.dept_code == "SNDG" && this.props.match.path != "/Possibletransshipments"?
                <div className="row" style={{marginBottom:"1%"}}>
                <div className="col-xl-12 col-lg-12">
                        <button 
							onClick={this.reloadPageData1.bind(this, "transhipment")} 
							className={"btn "+this.state.activeBtn1}
							style={{
								display: (this.state.transhipment || this.state.possible_transhipment || this.state.reports) ? "inline-flex" : "none",marginRight:'3px'
							}}
						>
							Transshipment
						</button>
                        <button 
							onClick={this.reloadPageData1.bind(this,"possible_transhipment")} 
							className={"btn "+this.state.activeBtn2}
							style={{
								display: (this.state.transhipment || this.state.possible_transhipment || this.state.reports) ? "inline-flex" : "none",marginRight:'3px'
							}}
						>
							Possible Transshipment
						</button>
                        <button 
							onClick={this.reloadPageData1.bind(this,"reports")} 
							className={"btn "+this.state.activeBtn3}
							style={{
								display: (this.state.transhipment || this.state.possible_transhipment || this.state.reports) ? "inline-flex" : "none"
							}}
						>
							Reports
						</button>
					
                    </div>
				</div>
                :""}
        <div className="row">
            <div className="col-xl-12 col-lg-12">
                <div className="card">
                    {/* <div className="card-header">
                        <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Possible Transshipments </span>
                        </h5>
                    </div>				   			 */}
                    <div className="card-body pt-15px" >
                        <div className="row">
                            
                                <div className={"form-gplantwiseloadingroup col-xl-3 col-lg-3 "+this.state.showSelectDept} style={{marginBottom:"23px"}}>
                                    <label>Select Department: </label>
                                    <Select 
                                    placeholder={"All"} 
                                    className="border-radius-0" 
                                    onChange={this.onSelectDept.bind(this)} style={{borderRadius:"0px"}} 
                                    value={this.state.selectedValue}
                                    options={this.deptOptions()} required />
                                </div>
                            </div>

                        <div className="row">
                            <div className="col-xl-12">

                                <span className="layoutbtns float-right">
                                    <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                        <i className="icofont icofont-save"></i> Save Grid Layout
                                    </button>
                                    <button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
                                        <i className="icofont icofont-refresh"></i> Reset Default Layout
                                    </button>
                                </span>
                            </div>


                            <div id="myGrid" style={{width:"100%",height:"478px"}} className="ag-theme-balham">    
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    enableCharts={false}
                                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus= {true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    onCellEditingStopped = {this.updateComments.bind(this)}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    // components={this.state.components}
                                    enableRangeSelection= {true}
                                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                    />
                            </div>
                            {this.state.sliderRouteTranslate != ''?
                                    <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
                                        <div className="slide-r-title">
                                            <h4>
                                                {(this.state.consignment_code)}
                                                <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
                                                        
                                            </h4>
                                        </div>
                                        <div className="slide-r-body" style={{position:"relative"}}>
                                            
                                            <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                                                {/* <DrawMap context={this} 
                                                rownode={this.state.rownode}
                                                mapFor={"consignment"} 
                                                googleroutes={this.state.googelRoutes} 
                                                truckno={this.state.maptruckno} 
                                                dealer={this.state.dealer} 
                                                mapinfo={this.state.mapinfo} /> */}
                                                <DrawMap 
                                                mapFor={"truck"}
                                                consignercords={this.state.consigner_coordinates} 
                                                consigneecords={this.state.consignee_coordinates} 
                                                rownode={this.state.rownode} 
                                                truckno={this.state.maptruckno} 
                                                dealer={this.state.dealer} 
                                                mapinfo={this.state.mapinfo} 
                                                tolls = {this.state.tolls}
                                                defTransitCoords={this.state.defTransitCoords} />
                                                <div className="col-xl-12 col-lg-12 n-p-0">
                                                <div className="crm-numbers pb-0">
                                                    <h3 className="subH">{this.state.sidebarSubHeader}</h3>
                                                    <div className="col-xl-12 col-lg-12 row">
                                                        <div className="col route-block">
                                                            <label className="sidebar-label">Truck No</label>
                                                            <div>{this.state.routeTruck.truck_no}</div>
                                                        </div>											
                                                        <div className="col route-block">
                                                            <label className="sidebar-label">Start Time</label>
                                                            <div>{(this.state.routeTruck.startTime != '')?getDDMMYYYYHHMMSS(this.state.routeTruck.startTime):"NA"}</div>
                                                        </div>
                                                        <div className="col route-block">
                                                            <label className="sidebar-label">End Time</label>
                                                            <div>{(this.state.routeTruck.endTime != '')?getDDMMYYYYHHMMSS(this.state.routeTruck.endTime):"NA"}</div>
                                                        </div>
                                                        
                                                        <div className="col route-block">
                                                            <label className="sidebar-label">Distance</label>
                                                            <div>{(!isEmpty(this.state.mapinfo.route_details))?Math.round(this.state.mapinfo.route_details.distance/1000):"0"} Kms</div>
                                                        </div>
                                                    </div>
                                                </div>
								                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                :""	
                                }
                        <div className={"dataLoadpage " +(this.state.loadshow)}>
                        </div>
                        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                        </div>
                        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                    </div>
                </div>
            </div>
            </div>
            </div>

            
            <span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
            <Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
                <div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                    <h5 className="model-title">Possible Transhipment</h5>
                    <div className="col-xl-12 col-lg-12 mt-20p">
                        <div className="col-xl-12 col-lg-12">
                            This screen is used for those consignments which are possible cases of transhipments. Transhipments happen because of following reasons. This is available for SND, PRT and TNP departments.    
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <ul>
                                <li>
                                    1. Major breakdown
                                </li>
                                <li>
                                    2. Accident
                                </li>
                                <li>
                                    3. Invalid invoicing
                                </li>
                                <li>
                                    4. Load not fitting in original vehicle
                                </li>
                                <li>
                                    5. Transhipments done on Driver/transporter end and not communicating to MSIL
                                </li>
                            </ul>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            To catch such cases there is system in place were if a vehicle stops for certain period of time and move in other direction apart from the destination side for 15 kms, then such cases comes under possible transhipment case.
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            To access the cases follow below mentioned steps
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <ul>
                                <li>
                                    click on Possible transhipment on the left panel
                                </li>
                                <li>
                                    from the grid check the vehicle details which are available on system
                                </li>
                                <li>
                                    click on map view icon present in the first column to check the trail of the vehicle
                                </li>
                                <li>
                                    for any doubts contact transporter for clarifications and validate the trip as confirmed transhipment or no transhipment from the menu option available for each trip.
                                </li>
                            </ul>
                            
                        </div>
                    </div>
                </div>
            </Modal>
      </div>

    );
  }
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
} 


function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
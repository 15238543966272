import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';

import SweetAlert from 'react-bootstrap-sweetalert';
import DrawMap from '../common/drawmap';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

import CountUp from 'react-countup';
import MyHeaderComponent from "./deviationheaderaction";
import Showtolls from "./showtolls";
import ShowTollsList from "./showtollslist";
import TollGrid from "./deviationtollgrid"
import ApprovalDeviationRoute from "./approvaldeviationroute";
import Implementroute from "./implementroute";
import $ from 'jquery';
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class TNPOptimizeDeviationRoutes extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
            loadshow:'show-n',
            overly:'show-n',
			modules: AllModules,
        	alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
                //editable: true,
                autoHeight:true,
				resizable: true
			},
			rowData: [],
            headerHeight: 60,
            rowHeight:50,
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
            frameworkComponents: {
                MyHeaderComponent: MyHeaderComponent,
                Showtolls:Showtolls,
                ShowTollsList:ShowTollsList,
                ApprovalDeviationRoute:ApprovalDeviationRoute,
                Implementroute:Implementroute
            },
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:1000,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            pagetitle:"Route Deviations",
            originaldata:"",
            orignalresponse:"",
            mapinfo:"",
            originoption:{"value":"","label":"Select Origin"},
            destinationoption:{"value":"","label":"Select Destination"},
            destinationarr:[],
            selectedorigin:'',
            selecteddestination:'',
            columnDefs:[],
            checkedinputs:[],
            uncheckedinputs:[],
            noofroutes:3,
            tolls:[],
            tollshow:false,
            plantcoords:"",
            plantname:"",
            sliderTranslate:"",
            viewtolls:[],
            tollrowid:"",
            routeid:"",
            routeapprovestatus:"",
            open:false,
            route_reason:"",
            optimizedroutes:0,
            identifiedroutes:0,
            savingrealized:0,
            estimateannual:0,
            implemented:0,
            rejected:0,
            hold:0,
            takenimplement:0,
            implementroutedate:"",
            implementrouteid:'',
            primarydata:[],
            secondarydata:[],
            tabprim:"show-m",
            tabsec:"show-n",
            btnactive:"btn-success",
            btnsecactive:"",
            plant:"",
            plants:[],
        };
       this.onClickRouteImplement = this.onClickRouteImplement.bind(this);
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
	
    componentDidMount(){
        //this.onClickShowRoute()
		// console.log("path ", this.props.match.path)
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        this.setState({eventAction: eventAction});
        // ms.push(
        //     {value:"F", label:"Gurgoan"},
        //     {value:"G", label:"Gujarat"},
        //     {value:"M", label:"Manesar"},
        //     {value:"N", label:"Nagpur"},
        //     {value:"I", label:"Silguri"},
        //     {value:"B", label:"Banglore"}
        this.selectplants()
        this.setState({
            loadshow:'show-m'
        })
        this.onLoadShowPageData();              
    }
    onLoadShowPageData()
    {
        var query = {};
        var consigner_codes = this.state.plant;
        if(consigner_codes !='' && consigner_codes !=undefined)
        {
            if(consigner_codes.value !="all")
            {
                query.consigner_codes = [consigner_codes.value];
            }
        }
        console.log(query,"query")
        redirectURL.post("/consignments/alltnpdeviationroutes",query)
        .then((response) => {
            // console.log("Consignees ", JSON.parse(response.data.data))
            var rec = JSON.parse(response.data.data);
            var records = JSON.parse(rec.optimized_routes_data);
            var identifiedroutes = rec.total_base_routes;
            var optimizedroutes = rec.optimized_routes;
            var taken_for_implementation = rec.taken_for_implementation;
            var implemented = rec.implemented;
            var rejected = rec.rejected;
            var hold = rec.hold;
            var estimateannual = "--";
            var savingrealized = "--";
            console.log("Recr ", records) 
           var recordsarr = []
           var primarydata = [];
           var secondarydata = []
           if(records.length > 0)
           {

            records = records.sort(GetSortOrder("base_route_gps_distance_difference"));
            records.map((item)=>{
                if(parseInt(item.base_route_gps_distance_difference) < 0)
                {
                    var sourcename = ''
                    if(item.consigner_code == "MSIL-G" || item.consigner_code == "MSIL-M"
                   ||  item.consigner_code == "MSILME"){
                        sourcename="Haryana"
                    }
                    if(item.consigner_code == "MSILMC"){
                        sourcename="Casting"
                    }
                    if(item.consigner_code == "SMG"){
                        sourcename="SMG Gujarat"
                    }
                    item.sourcename = sourcename;
                    if(parseInt(item.base_route_gps_distance_difference)<0)
                    {
                        recordsarr.push(item)
                    }
                }
            })
           }
           console.log("recordsarr ", recordsarr)
           this.setState({
               rowData:recordsarr,
            //    primarydata:primarydata,
            //    secondarydata:secondarydata,
               loadshow:'show-n',
               overly:'show-n',
               optimizedroutes:optimizedroutes,
               identifiedroutes:identifiedroutes,
               takenimplement:taken_for_implementation,
               implemented:implemented,
               rejected:rejected,
               hold:hold,
               estimateannual:estimateannual,
               savingrealized:savingrealized
           })
        })
    }
	
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        
        this.gridApi.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode( (node) => {
            // console.log("node here ",node)
            // node.setSelected(true);
         
        });
        
	};
	

	onGridState = () =>{
		 console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
           

           
        
        //
    }

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			slidertranslate:''
		});
		
    }
    onClickRouteImplement(params){
        //console.log("params ", params.data)
        this.setState({
            slidertranslate:"slider-translate-30p",
            overly:'show-m',
            implementrouteid:params.data._id
		});
    }

    onCloseRouteDiv = () => {
		this.setState({
			slidertranslate:"",
			overly:'show-n'
		});
	}

    handlerStartDateTime = (event) => {
        var d = new Date(event._d);
        
        var startdate = d.getFullYear()+"-"+((d.getMonth() +1) >= 10?(d.getMonth() +1):"0"+(d.getMonth() +1))+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
        
        this.setState({
            implementroutedate:startdate
        });
    }
    onClickShowUpdateData(){
        var implementroutedate = this.state.implementroutedate;
        var implementrouteid = this.state.implementrouteid;
        var parameters = {
            effective_date:implementroutedate,
            id:implementrouteid
        }
        //console.log("parameters ", parameters)
        redirectURL.post("/consignments/setRouteEffectDateInDeviation",parameters)
        .then((response)=>{
           this.setState({
               show:true,
               basicType:"success",
               basicTitle:"Successfully update data",
               slidertranslate:"",
			   overly:'show-n'
           })
        }).catch((e)=>{
    
        })

    }

    // onClickTab(tab){
    //     if(tab == "prim")
    //     {
    //         this.setState({
    //             rowData:this.state.primarydata,
    //             btnactive:"btn-success",
    //             btnsecactive:""
    //         })
    //     }
    //     if(tab == "second")
    //     {
    //         this.setState({
    //             rowData:this.state.secondarydata,
    //             btnactive:"",
    //             btnsecactive:"btn-success"
    //         })
    //     }
    // }
    selectplants(){
        var plants = [];
        plants.push({"value":"all","label":"All"})
        plants.push({"value":"haryana","label":"Haryana"})
        plants.push({"value":"MSILMC","label":"Casting"})
        plants.push({"value":"MSILME","label":"Power Train"})
        plants.push({"value":"SMG","label":"SMG Gujarat"})
        plants.push({"value":"MUL","label":"SMIPL"})
        this.setState({
            plants:plants,
        });
    }
    onChangePlantItem(plant){
		this.setState(
			{ plant },
			() => console.log(`Plant Option selected:`, this.state.plant)
        );
    }
    formPlantHandler = async (event) => {
        event.preventDefault()
        this.setState({
            "loadshow" : "show-m",
            "overly" :'show-m',
        });
        this.onLoadShowPageData();
    }
    render(){
       const columnDefs = [
            {
                headerName:"",
                field:"",
                width:110,
                cellRenderer: function(params) {
                    var sourval = params.data.consigner_code;
                    var sourcename = params.data.sourcename;
                    var destination = params.data.consignee_code;
                    return '<a href="/tnpdeviationroutes?source='+sourval+'&sourcename='+sourcename+'&destination='+destination+'" target="_blank" class="btn btn-warning" rel="noopener">View</a>'
                }
            },
            {
                headerName:"Source",
                field:"sourcename",
                width:120,
            },{
                headerName:"Destination",
                field:"consignee_code",
                width:140
            },

            {
                headerName:"Total Source Destination Trips",
                field:"total_trips_btw_source_dest",
                width:120
            },
            
            {
                headerName:"Trips Considered For Analysis",
                field:"trips_considered_for_analysis",
                width:120
            },
            
            // {
            //     headerName:"No Of Reference Trips",
            //     field:"no_of_trips_travelled",
            //     width:120
            // },
            {
                headerName:"Standard Distance (Kms)",
                field:"expected_distance",
                width:120,
                valueGetter:function(params){
                    try{
                        return params.data.expected_distance
                    }
                    catch(e){
                        return "";
                    }
                    
                }
            },{
                headerName:"Average Distance (Kms)",
                field:"ref_route_avg_gps_distance",
                width:100,
                valueGetter:function(params){
                    console.log("params ", params.data)
                    try{
                        return params.data.ref_route_avg_gps_distance
                    }
                    catch(e){
                        return "";
                    }                    
                }
            },
            {
                headerName:"Difference Distance (Kms)",
                field:"base_route_gps_distance_difference",
                width:100,
                valueGetter:function(params){
                    try{
                        return params.data.base_route_gps_distance_difference
                    }
                    catch(e){
                        return "";
                    }
                    
                }
            },
            {
                headerName:"Estimated Annual Savings (Rs)",
                field:"aprox_annual_savings",
                width:100,
                valueGetter:function(params){
                    try{
                        // console.log("params.data.aprox_annual_savings ",params.data.aprox_annual_savings)
                        // console.log("aa", typeof params.data.aprox_annual_savings)
                       
                        if(params.data.aprox_annual_savings != undefined || params.data.aprox_annual_savings != "undefined")
                        {
                            console.log("params.data.aprox_annual_savings ",params.data.aprox_annual_savings)
                        
                            return params.data.aprox_annual_savings;       
                        }
                        else{
                            console.log("Here params.data.aprox_annual_savings ",params.data.aprox_annual_savings)
                        
                            return "";
                        }
                        
                    }
                    catch(e){
                        return "";
                    }
                    
                }
            },
            
            {
                headerName:"Alto Rate Considered Month (Rs)",
                field:"alto_rate_considered_month",
                width:100,
                valueGetter:function(params){
                    try{
                        return params.data.alto_rate_considered_month
                    }
                    catch(e){
                        return "";
                    }
                    
                }
            },

            {
                headerName:"Route ID",
                field:"route_id",
                width:80
            },
            {
                headerName:"Cluster",
                field:"cluster",
                width:100
            },
            { 
                headerName:"Status",
                field:"route_approval_status",
                width:160,
                valueGetter:function(params){
                    try{
                        if(params.data.route_approval_status != undefined)
                        {
                            if(params.data.route_approval_status == "approved")
                            {
                                return "Taken for implemetation";
                            }
                            else if(params.data.route_approval_status == "rejected")
                            {
                                return "Rejected";
                            }
                            else if(params.data.route_approval_status == "hold")
                            {
                                return "Hold";
                            }
                            else
                            {
                                return "";
                            }
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Reason",
                field:"route_reason",
                width:160
            },
            {
                headerName:"Done By",
                field:"route_approved_by",
                width:160
            },
            
            {
                headerName:"",
                field:"route_approval_status_value",
                width:160,
                //cellRendererFramework:Implementroute
                cellRendererSelector:function(params){
                    try{
                        var rendComponent = {
                            component: 'Implementroute'
                        };
                        return rendComponent
                        
                    }
                    catch(e){
                        return "";
                    }
                    
                }
            }

        ]
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
                    
                    <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                        <div className="row" style={{marginTop:"10px"}}>
                            <div className="col-xl-4 col-lg-4 row form-group">
                                <label className="col-xl-12 col-lg-12">Select Plant</label>
                                <Select 
                                placeholder={"Select Plant"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangePlantItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                //value={transporter}
                                options={this.state.plants} />
                            </div>
                            <div className="col-xl-3 col-lg-3 form-group" style={{marginTop:"2.3%"}}>
                                <button onClick={this.formPlantHandler.bind(this)} type="button" className="btn btn-success">Submit</button>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body" id="counterCardBody">
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        {/* <div className="col cirlce-d cursorPointer">
                                            <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Identified Routes</span>
                                            <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.identifiedroutes != '')?this.state.identifiedroutes:0}/></span></h4>
                                        </div> */}
                                        <div className="col cirlce-d cursorPointer">
                                            <span className="f13"><i className="icofont icofont-map-pins f24 txt-success"></i><br /> Possible Identified Routes</span>
                                            <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.optimizedroutes != '')?this.state.optimizedroutes:0}/></span></h4>
                                        </div>
                                        <div className="col cirlce-d cursorPointer">
                                            <span className="f13"><i className="icofont icofont-ui-browser f24 txt-primary"></i><br /> Taken for Implementation</span>
                                            <h4 className="txt-primary f40"><span className="counter"><CountUp end={(this.state.takenimplement != '')?this.state.takenimplement:0}/></span></h4>
                                        </div>
                                        <div className="col cirlce-d cursorPointer">
                                            <span className="f13"><i className="icofont icofont-ui-close f24 txt-danger"></i><br /> Rejected</span>
                                            <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.rejected != '')?this.state.rejected:0}/></span></h4>
                                        </div>
                                        <div className="col cirlce-d cursorPointer">
                                            <span className="f13"><i className="icofont icofont-ui-check f24 txt-secondary"></i><br /> Implemented</span>
                                            <h4 className="txt-secondary f40"><span className="counter"><CountUp end={(this.state.implemented != '')?this.state.implemented:0}/></span></h4>
                                        </div>
                                        <div className="col cirlce-d cursorPointer">
                                            <span className="f13"><i className="icofont icofont-hand f24 txt-danger"></i><br /> Hold</span>
                                            <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.hold != '')?this.state.hold:0}/></span></h4>
                                        </div>
                                        <div className="col cirlce-d cursorPointer">
                                            <span className="f13"><i className="icofont icofont-ui-calculator f24 txt-warning"></i><br /> Estimated Annual Savings</span>
                                            <h4 className="txt-warning f40"><span className="counter"><CountUp end={(this.state.estimateannual != '')?this.state.estimateannual:0}/></span></h4>
                                        </div>
                                        <div className="col cirlce-d cursorPointer">
                                            <span className="f13"><i className="icofont icofont-ui-clip-board f24 txt-info"></i><br /> Saving Realized</span>
                                            <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.savingrealized != '')?this.state.savingrealized:0}/></span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>Optimized Routes  </span>
				       			</h5>
				   			</div>
				   			<div className="card-body row">
                               {/* <div className="row col-xl-12 col-lg-12">
                                    <button 
                                        name="primary" 
                                        type="button"
                                        onClick={this.onClickTab.bind(this,"prim")}
                                        className={"btn "+this.state.btnactive}
                                    >
                                        Primary
                                    </button>
                                    &nbsp;
                                    <button 
                                        name="primary" 
                                        type="button"
                                        onClick={this.onClickTab.bind(this,"second")}
                                        className={"btn "+this.state.btnsecactive}
                                    >
                                        Secondary
                                    </button>
                                </div> */}
{/* 							  
								<div className="row col-xl-12 col-lg-12">
                                    <div className="form-group col-xl-3 col-lg-3">
                                        <label>Select Origin: </label>
                                        <Select 
                                            name="originoption"
                                            closeMenuOnSelect={true} 
                                            value={this.state.originoption} 
                                            className="border-radius-0" 
                                            onChange={this.changeOriginHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={this.origins()} required
                                        />
                                    </div>
                                    <div className="form-group col-xl-3 col-lg-3">
                                        <label>Select Destination: </label>
                                        <Select 
                                            name="destinationoption"
                                            closeMenuOnSelect={true} 
                                            value={this.state.destinationoption} 
                                            className="border-radius-0" 
                                            onChange={this.changeDestinationHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={this.destinations()} required
                                        />
                                    </div>
                                    
                                    <div className="form-group col-xl-3 col-lg-3">
                                        <button style={{marginTop:"30px"}} onClick={this.formHandler.bind(this)} type="button" className="btn btn-success">Submit</button>
                                    </div>
                                   
                                </div> */}

								<div className="row col-xl-12 col-lg-12">
								   
                                        <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={false}
                                                enableRangeSelection={false}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                enableRangeSelection={true}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                frameworkComponents={this.state.frameworkComponents}
                                                //onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                               //onCellClicked={this.popmarker}
                                                headerHeight={this.state.headerHeight}
                                                rowHeight={this.state.rowHeight}
                                            />
                                        </div>

                                    <div className="row">
                                        <div className="f11 col-xl-12 col-lg-12" style={{fontWeight:"600"}}>Disclaimer:</div>
                                        
                                        <div className="f11 col-xl-12 col-lg-12" style={{lineHeight:"20px"}}>
                                            * All Trips from May 1st 2020 that have delivered within 5KM of the dealer location have been considered for the analysis
                                        </div>
                                    </div>
								</div>
							   		
                            </div>
				   		</div>	
				   	</div>
				 </div>


                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
			
                <div className={"slide-r "+(this.state.slidertranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                            Implement Route
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12">
                            <div className="form-group col-xl-12 col-lg-12">
                                <label>Implement Route From:</label>
                                <Datetime
                                    value={this.state.implementroutedate} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: '', name: 'implementroutedate', autoComplete:'off' }}
                                    dateFormat="YYYY-MM-DD" 
                                    name="implementroutedate" 
                                    onChange={this.handlerStartDateTime.bind(this)} 
                                />
                            </div>
                            
                            <div className="form-group col-xl-3 col-lg-3">
                                <button style={{marginTop:"30px"}} onClick={this.onClickShowUpdateData.bind(this)} type="button" className="btn btn-success">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
              
        );
    }
}



function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
  } 

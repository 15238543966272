import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
var redirectURL = require('../redirectURL');
let Components = {};
Components['ontimedeliver'] = require('./forms/ontimedeliver').default;
Components['routedeviate'] = require('./forms/routedeviation').default;
Components['overspeed'] = require('./forms/overspeed').default;
Components['localmovement'] = require('./forms/localmovement').default;
Components['unusualenroute'] = require('./forms/unusualenroute').default;
Components['nightdriving'] = require('./forms/nightdriving').default;
Components['nogpsdata'] = require('./forms/nogpsdata').default;
Components['enroute'] = require('./forms/enroutestoppages').default;
Components['transitdelay'] = require('./forms/transitdelay').default;
Components['loadingdelay'] = require('./forms/loadingdelay').default;
Components['unloadingdelay'] = require('./forms/unloadingdelay').default;
Components['dealerradius'] = require('./forms/dealerradius').default;
Components['noentrytimes'] = require('./forms/noentrytimes').default;
Components['transittimes'] = require('./forms/transittimes').default;
Components['truckavailabilitydistance'] = require('./forms/truckavailabilitydistance').default;
Components['tnpdealerradius'] = require('./forms/tnpdealerradius').default;
Components['tnpreturndealerradius'] = require('./forms/returndealerradius').default;
export default Components;
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types'
import { isArray } from 'highcharts';
import $ from 'jquery';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");


export default class AuthCallBack extends Component{
	constructor(props) {
		super(props);
		var pageTitle = "Login";
		this.state = {
			//pageTitle: pageTitle,
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			style: {},
			email:'',
			password:'',
			loginMessage:'',
			currentDepartmentCode: null,
			firstname : "", 
			lastname : "", 
			email : "", 
			is_load_management : 1,
		};
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}

	componentDidMount(){
		this.logPageView(); // Log page view to GA	
		
		let pathName = window.location.pathname;
    	let search = window.location.search;
		console.log(pathName,search,"pathName,search")
		let totalPath = pathName + search;
		var sptPath = totalPath.split("=");
		console.log(sptPath,"sptPath")
		var id_token = sptPath[1];
		console.log(id_token,"id_token")
		var params = {
			id_token : id_token
		}
		// redirectURL.post("/lmConsignees/authCallBack",params).then((response)=>{
		// 	console.log(response.data);
		// 	this.setState({
		// 		firstname : response.data[0].firstname,
		// 		lastname : response.data[0].lastname,
		// 		email : response.data[0].email
		// 	});
		// }).catch(function(error){
		// 	console.log("Error");
		// })
		redirectURL.post('/lmconsignees/getMSILUsersData',params,{
		//redirectURL.post('/applogin', fdata,{
			headers:{
			//       'Content-Disposition': 'application/x-www-form-urlencoded',
				'Content-Type':'application/json'
			}
		})
			.then( async (response) => {
				console.log("After login ",response);
				if(response.statusText == 'OK')
				{
					if(response.data.auth == true)
					{
						console.log(response.data.user,"response.data.user")
						var comp_code = "";
						
						//	console.log("In  LoginForm response.data.user.dept_code = ", response.data.user.dept_code);
						var encrypted_token=window.btoa(response.data.token)

						localStorage.setItem('_t', encrypted_token);
											
						localStorage.setItem('userid', response.data.userid);
						let deptCode = JSON.stringify(response.data.user.dept_code);
						//console.log("In  LoginForm, response.data = ", response.data);
						localStorage.setItem('dept_code', deptCode);
						localStorage.setItem('is_admin', response.data.user.is_admin);
						localStorage.setItem('is_dept_admin', response.data.user.is_dept_admin);
						localStorage.setItem('is_global', response.data.user.is_global);
						localStorage.setItem('firstname', response.data.user.firstname);
						localStorage.setItem('username', response.data.user.username);
						localStorage.setItem('lastname', response.data.user.lastname);
						localStorage.setItem('email', response.data.user.email);
						localStorage.setItem('user_type', response.data.user.user_type);
						//console.log("response.data.menus ", response.data.menus);
						localStorage.setItem('usermenus', JSON.stringify(response.data.menus));
						//console.log("response.data.usermenucodes ", response.data.usermenucodes);
						localStorage.setItem('usermenucodes', JSON.stringify(response.data.usermenucodes));
						localStorage.setItem('transportercode', JSON.stringify(response.data.user.transporter_code));
						
						localStorage.setItem('roles', response.data.user.roles);
						localStorage.setItem('is_enmovil_transporter', this.state.is_enmovil_transporter);
						localStorage.setItem('is_load_management', this.state.is_load_management);
						localStorage.setItem('is_sub_user', response.data.user.is_sub_user);
						localStorage.setItem('cha_code', response.data.user.cha_code);
						try{
							if(response.data.user.vehicle_group != undefined)
							{
								localStorage.setItem('vehicle_group', response.data.user.vehicle_group);
							}
							else{
								localStorage.setItem('vehicle_group', "");
							}
						}
						catch(e){}
						
						//console.log('users roles', typeof response.data.user.roles);
						sessionStorage.setItem('ref', 1);
						

						var userloggparams = {
							token:response.data.token,
							userId:response.data.userid,
							comp_code:comp_code,
						}
						await redirectURL.post("/manage/saveuserlogged",userloggparams)
						.then((resp) => {
							console.log("resp ", resp)
						})

						if(response.data.user.is_load_management !=undefined)
						{
							localStorage.setItem('is_load_management', response.data.user.is_load_management);
							if(comp_code !='' && comp_code !=undefined)
							{
								var userloggparams = {
									token:response.data.token,
									userId:response.data.userid,
									comp_code:comp_code
								}
								await redirectURL.post("/manage/updateUserCompCode",userloggparams)
								.then((resp) => {
									console.log("resp ", resp)
								})
							}
						}
						if(response.data.user.lm_department !=undefined)
						{
							localStorage.setItem('lm_department', response.data.user.lm_department);
						}
						if(response.data.user.designation !=undefined)
						{
							localStorage.setItem('designation', response.data.user.designation);
						}
						if(response.data.user.active_status !=undefined)
						{
							localStorage.setItem('active_status', response.data.user.active_status);
						}
						if(response.data.user.is_department_admin !=undefined)
						{
							localStorage.setItem('is_department_admin', response.data.user.is_department_admin);
						}
						if(response.data.user.staff_id !=undefined)
						{
							localStorage.setItem('staff_id', response.data.user.staff_id);
						}
						if(response.data.user.dept_head_id !=undefined)
						{
							localStorage.setItem('dept_head_id', response.data.user.dept_head_id);
						}
						if(response.data.user.report_manager_id !=undefined)
						{
							localStorage.setItem('report_manager_id', response.data.user.report_manager_id);
						}
						this.setState({
							currentDepartmentCode: response.data.user.dept_code
						});
						if (response.data.token) {
						axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
						} else {
						delete axios.defaults.headers.common['Authorization'];
						}

						this.setState({
							username:'',
							email:'',
							password:'',
							loginMessage:response.data.msg
						});
					}
				else{
				
					this.setState({
						username:'',
						email:'',
						password:'',
						loginMessage:response.data.msg
					});
					
				}
					
					
				}
				else
				{
					this.setState({
						username:'',
						email:'',
						password:'',
						loginMessage:response.data.msg
					});
				}
			//console.log(window.atob(localStorage.getItem('_t')));
			})
			.catch(function (error) {
				console.log(error);
			});
		
			
			// another call to find menus for the user
			redirectURL.post('/menus/getmenus', {},{
			headers:{
				'Content-Type':'application/json'
			}
		})
		.then( response => {
			//console.log("Menus ",response);
			if (response.statusText == 'OK') {
				//console.log("In  SIDEBAR LoginForm MENUS response.data = ", response.data);
				let menuText = JSON.stringify(response.data);
				localStorage.setItem('menuText', menuText);

			} else {
				this.setState({
					username:'',
					email:'',
					password:'',
					loginMessage:response.data.msg
				});
			}
			let MENUITEMS = getMenuLocalStorage();

			//console.log("IN Sidebar componentWillMount, From DB MENUITEMS menuList ", MENUITEMS);
		})
		.catch(function (error) {
			console.log(error);
		});
	};
	getUserDashboardUrl(pathToMatch = null) {
 
		var uroles = localStorage.getItem("roles");
		var arr = []
				
		
		// redirectURL.post("/menus/roleinfo",{role:uroles})
		// .then((res) => {
		// 	//console.log("repson ", response.data)
		// 	var rec = res.data
		// 	if(rec.length > 0)
		// 	{
		// 		rec.map((item) => {
		// 			if(item.dashboard_url != '' && item.dashboard_url != undefined)
		// 			{
		// 				arr.push(item.dashboard_url)
		// 			}
		// 		})
				
		// 	}
		// 	else
		// 	{
		// 		arr.push("/dashboard/default")
		// 	}
		// 	if(arr.length > 0)
		// 	{
				
		// 		this.setState({
		// 			landingpage:arr[0]
		// 		});
		// 	}
		// 	else
		// 	{
		// 		this.setState({
		// 			landingpage:"/dashboard/default"
		// 		});
		// 	}
		// 	//return dashboardUrl;
		// })

		
		let dashboardUrl = "/lmwelcome";
		let currentDepartmentCode = null;
		let departmentCode = JSON.parse(localStorage.getItem("dept_code"));

		//console.log("IN render, departmentCode ", departmentCode);
		if (Array.isArray(departmentCode)) {
			currentDepartmentCode = departmentCode[0]; 
		} else {
			currentDepartmentCode = departmentCode; 
		}

		//console.log("IN render, currentDepartmentCode ", currentDepartmentCode);
		//console.log("localStorage roles ", localStorage.getItem("roles"))
		
		console.log("dashboardUrl Login", dashboardUrl)
		return dashboardUrl;
	}
	componentWillReceiveProps(){
		var pageTitle = "Login";
		this.setState({
			pageTitle: pageTitle,
		});
	};
	changeHandler = (event) => {
		let name = event.target.name;
		let value = event.target.value;
		this.setState({[name]:value});
	}
	formHandler = (event) => {
		console.log(this.state.login_id);
		console.log(this.state.password);
	}

	
	render(){
		if(localStorage.getItem('_t') != '' && localStorage.getItem('_t') != null && localStorage.getItem('_t') != "null")
		{
			let dashboardUrl = this.getUserDashboardUrl();
			//let dashboardUrl = this.state.landingpage;
			// return <Redirect to="dashboard/snddefault" push={true} />
			return <Redirect to={dashboardUrl} push={true} />
			console.log(localStorage.getItem('_t'),"----_ttttt")
		}
		return (
			this.state.loginMessage
		)
	}
};

function getMenuLocalStorage() {
    let menuText = localStorage.getItem('menuText');
    let MENUITEMS = null;
    //   console.log("deptCodes = ", deptCodes);
    //   console.log("Type of deptCodes = ", typeof deptCodes);
       try {
         MENUITEMS = JSON.parse(menuText);
       } catch (error) {
           console.log('Error while Parsing menuText', error);
       }
       return MENUITEMS;
}
'use strict';
import React, { Component} from 'react';
import PowerBIReport from './power_bi';

export default class PowerBIScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render(){
        return (
            <div class="container-fluid">

                <div className="card col-xl-12">
                    {/* <div className="card-header">
                        <h5>
                            <i className="icofont icofont-users-alt-1 cus-i"></i> <span>Dashboard</span>
                        </h5>
                    </div> */}

                    {/* <PowerBIReport url={'https://app.powerbi.com/view?r=eyJrIjoiMDQ1OTBkNDktMzQyYS00M2FiLTkxMmMtN2NlYTlmMTc2ZjFkIiwidCI6IjJiNzlhMzk1LWUwNDEtNDcyMy04YzBkLWJlMTA1YTY1NmI1YiJ9'}/> */}
                    <iframe 
                    width="100%" 
                    frameborder="0"
                    height="1150"
                    allowFullScreen="true" 
                    src={"https://app.powerbi.com/view?r=eyJrIjoiZmJjMmJhYjItMmFmZi00OGE2LTg0MzAtN2RjY2M3Y2NiNTFkIiwidCI6IjJiNzlhMzk1LWUwNDEtNDcyMy04YzBkLWJlMTA1YTY1NmI1YiJ9"}>
                    
                </iframe>
                </div>
                
            </div>
        )
    }


}
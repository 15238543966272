'use strict';
import {Map, InfoWindow, Marker, GoogleApiWrapper, Polygon, Polyline} from 'google-maps-react';
import React, { Component, useDebugValue } from 'react';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import { getHyphenDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Select from 'react-select';

var infoBox = require('../common/google-infowindow');
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var map;



export default class PRTRouteDev extends Component {
  constructor(props) {
    super(props);
      this.state = {
        basicType:"default",
        loadshow:'show-n',
        show: false,
        mapsData: [],
        selectedRoute: [],
        dropDownOptions: [],
    }
}
componentDidMount(){
    redirectURL.post("/dashboard/getAdhocPrtDeviationRoutes").then((response)=>{
        var dropDownOptions = [];
        response.data.map((e)=>{
            var consignment_codes = e.consignment_codes;
            consignment_codes.map((k)=>{
                dropDownOptions.push({"label": k, "value": k})
            })
        })
        this.setState({
            mapsData: response.data,
            dropDownOptions: dropDownOptions,
        })
        this.renderMap()
    })
}


renderMap = () => {   	
    loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMap");
    window.initMap = this.initMap
}

initMap = () => 
{
    var lt=28.4519751;
	var ln=77.0310713;
    var mapOptions = {
        zoom: 13,
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true,
        labels:true,
        mapTypeControlOptions: {
            mapTypeIds: ['satellite', 'roadmap'],
        },
        center: new window.google.maps.LatLng(lt,ln),
        mapTypeId: window.google.maps.MapTypeId.ROADMAP
    };
    var bounds = new window.google.maps.LatLngBounds();
	bounds.extend(new window.google.maps.LatLng(lt,ln));
    map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
    
    this.state.selectedRoute.map((k)=>{
        var line = new window.google.maps.Polyline(
            {
                map:map,
                strokeColor: '#157254',
                strokeOpacity: 1.0,
                strokeWeight: 2.5,
                icons: [{
                        icon: {
                                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                strokeColor:'#ff8c52',
                                fillColor:'#ff8c52',
                                fillOpacity:1,
                                strokeWeight: 2
                        },
                        repeat:'100px',
                        path:[]
                    }]
            });
        var path = line.getPath().getArray();
        k.map((e,index)=>{
            if(index == 0)
            {
                // Starting point
                var marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(e.lat, e.lng),
                    icon:require('../../assets/icons/track_start.png'),
                    map: map,	
                });
            }
            if(index + 1 == k.length)
            {
                var marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(e.lat, e.lng),
                    icon:require('../../assets/icons/truck-end.png'),
                    map: map,	
                });
            }
            bounds.extend(new window.google.maps.LatLng(e.lat, e.lng));
            let latLng=new window.google.maps.LatLng(e.lat, e.lng);
            path.push(latLng);
        })
        line.setPath(path);
    })		
    map.fitBounds(bounds)
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    invoiceData:[]
    
    });
    
}

onChangeRoute(e)
{
    try{
        var routes = []
        var cons_codes = [];
        e.map((k)=>{
            cons_codes.push(k.value)
        })
        var route = this.state.mapsData.filter((k)=>{
            var flag = 0
            var filteredArray = k.consignment_codes.filter((e)=>{
                if(cons_codes.indexOf(e) >= 0)
                {
                    flag = 1
                    return true
                }
            })
            if(flag == 1){
                routes.push(eval(k['route_coords']))
            }
        });
        console.log(routes, "routes")
        this.setState({
            selectedRoute: routes,
            selectedValue: e
        })
        this.renderMap()
    }
    catch(e){}
   
}

closeAlert = () => {
    this.setState({
        show: false
    });
}


  render() {
        
    return (
        <div class="row">
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}
            >
            </SweetAlert>
            <Select 
                placeholder="Select Consignments"  
                isMulti={true}
                closeMenuOnSelect={true} 
                className="border-radius-0 col-xl-3 col-lg-3 col-sm-3 col-md-3" 
                value={this.state.selectedValue}
                onChange={this.onChangeRoute.bind(this)} 
                style={{borderRadius:"0px"}} 
                options={this.state.dropDownOptions} 
            />
            <div className="card col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="card-body">
                    <div id="map" className="" style={{width:'100%',height:"80vh"}}></div>
                </div>
            </div>
        </div>
    );
  }
}

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import download from 'js-file-download';
import CarSummaryColumnChart from "./sndUnloadingColumnChart";
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import { getDDMMYYYY} from '../common/utils'; 
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class viewCarSummaryDetails extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
            // currentAddAccidentProneZone: null,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        holidayEvents : [],
        maxNodes: [],
        telRoutes: [],
        deviation: [],
        showCalendar : "show-m",
        showGridData : "show-m",
        comp_code : "",
        lpNames : [],
        loading_port_code : "",
        showGridData : "show-m",
        vechicleTypes : [],
        dept_code : "LOG-TNP",
        coordinates : [],
        hrefLink : "",
        transactionId: "",
        seriesData: [],
        categories: [],
        drildown: [],
        apiData: [],
        columnwithDefs:[],
        totalCarsPlanned:[],
        carPlanDetails:[],
        recoShow:"show-n",
        carData:[],
        pinnedRegionBottomRowData:[],
        downloadOutPutData:[],
        //components : {datepicker:getDatePicker()}
    }
    this.onCellClicked = this.onCellClicked.bind(this)
    this.processRecommendations = this.processRecommendations.bind(this)
  }

  

componentDidMount(){
    var transactionId = window.atob(this.props.match.params.eventname);
    console.log(transactionId,"transactionId")
    var query = {
        "transactionId" : transactionId
    }
    this.setState({
        transactionId : transactionId
    });
    // redirectURL.post("/rakeplanning/getTransactionConsolidatedFile",query).then((response1)=>{
    //     var consolidatedFile = response1.data;
    //     this.setState({
    //         consolidatedFile : consolidatedFile
    //     });
    // });
    redirectURL.post("/rakeplanning/getCarOutData",query).then((response1)=>{
        var downloadOutPutData = response1.data;
        this.setState({
            downloadOutPutData : downloadOutPutData
        });
    });

    redirectURL.post("/rakeplanning/getCarPlanDetailsByTransactionId",query).then(async (response)=>{
        var recoShow = "show-m";
        var recOpt = 0;
        var inputCars = [];
        if(response.data.carPlanDetails.length > 0)
        {
            inputCars = JSON.parse(response.data.carPlanDetails[0].carDetails);
            try{
                if(response.data.carPlanDetails[0].reco_processed == 0)
                {
                    recoShow = "show-m";
                    recOpt = 0;
                }
                else
                {
                    recOpt = 1;
                }
            }
            catch(e){

            }
        }
        var summaryDetails = response.data.summaryDetails;
        var carData = [];
        var totalInput = 0;
        var totalOriginal = 0;
        var totalReco = 0;
        var totalLoadFactor = 0;
        if(summaryDetails.length > 0)
        {
            var groupData = groupBy(summaryDetails, rdata => rdata.car_name);
            groupData.forEach(async (gData, gKey) => {
                var recoType = groupBy(gData, rdata1 => rdata1.remarks);
                var originalLength = 0;
                var recoLength = 0;
                recoType.forEach(async (gData1, gKey1) => {
                    if(gKey1 == "ORIGINAL")
                    {
                        originalLength = gData1.length;
                        //console.log(gKey,gKey1,gData1.length,"original");
                    }
                    if(gKey1 == "RECOMMENDED")
                    {
                        recoLength = gData1.length;
                        totalReco = parseInt(totalReco)+parseInt(gData1.length);
                        //console.log(gKey,gKey1,gData1.length,"RECOMMENDED");
                    }
                })
                var load_factor = parseFloat(((gData[0].load_factor)*(gData.length))).toFixed(2);
                var input_cars = 0;
                inputCars.map(function(ic){
                    if(ic.car_model == gKey)
                    {
                        input_cars = ic.total_cars;
                    }
                });
                carData.push({
                    "car_name" : gKey,
                    "car_code" : gData[0].car_code,
                    "cars_count" : gData.length,
                    "input_cars" : input_cars,
                    "cars_original_count" : originalLength,
                    "cars_reco_count" : recoLength,
                    "car_load_factor" : gData[0].load_factor,
                    "load_factor" : load_factor,
                });
                recoLength = 0;
                totalLoadFactor = parseFloat(totalLoadFactor)+parseFloat(load_factor);
                totalOriginal = parseInt(totalOriginal)+parseInt(originalLength);
                totalInput = parseInt(totalInput)+parseInt(input_cars);
            });
        }
        carData = carData.filter(e => e.car_name !="EMPTY");
        var ids = new Set(carData.map(({ car_code }) => car_code));
        inputCars = inputCars.filter(({ family_code }) => !ids.has(family_code));
        //console.log(inputCars,"inputCars");
        inputCars.map(function(np){
            carData.push({
                "car_name" : np.car_model,
                "car_code" : np.family_code,
                "cars_count" : 0,
                "input_cars" : np.total_cars,
                "cars_original_count" : 0,
                "cars_reco_count" : 0,
                "car_load_factor" : np.load_factor,
                "load_factor" : 0,
            });
            totalInput = parseInt(totalInput)+parseInt(np.total_cars)
        })
        var pinnedBottomRowData = {}
        pinnedBottomRowData['car_code'] = "";
        pinnedBottomRowData['car_name'] = "";
        pinnedBottomRowData['input_cars'] = totalInput;
        pinnedBottomRowData['cars_original_count'] = totalOriginal;
        pinnedBottomRowData['cars_reco_count'] = totalReco;
        pinnedBottomRowData['car_load_factor'] = "";
        pinnedBottomRowData['load_factor'] = (totalLoadFactor).toFixed(2);
        
        var summaryDetails = response.data.summaryDetails;
        summaryDetails = summaryDetails.filter(e => e.car_name !="EMPTY");
        this.setState({
            totalCarsPlanned : summaryDetails,
            carPlanDetails : response.data.carPlanDetails,
            recoShow : recoShow,
            carData : carData,
            pinnedRegionBottomRowData : [pinnedBottomRowData],
            totalLoadFactor : (totalLoadFactor).toFixed(2),
        });        
        //await this.loadChartData();
        query.recOpt = recOpt;
        redirectURL.post("/rakeplanning/getCarPlanDetailsFromAPI",query).then(async (response)=>{
            var apiData = response.data;
            //apiData = apiData.sort(SortByName);
            apiData.map(function(e){
                e.sr_no = parseInt(e.sr_no);
            });
            await this.setState({
                apiData : apiData
            });
            await this.createGridColumns();
        });
    });

    // redirectURL.post("/rakeplanning/getCarDetails").then((response)=>{
    //     this.setState({
    //         carData : response.data
    //     })
    // }).catch((e)=>{
    //     console.log(e);
    // })
}
createGridColumns = async() =>{
    var apiData = this.state.apiData;
    console.log(apiData,"apiData")
    var columns = []; 
    if(apiData.length > 0)
    {
        var keys = Object.keys(apiData[0]);
        await keys.forEach(async (z, k) => {
            if(z == "sr_no" || z == "Wagon Type" || z == "Upper Deck height" || z == "Lower Deck height")
            {          
                var keyVal=z;
                var hwid = 70;
                var pinnedLeft = "";
                if(z == "sr_no")
                {
                    keyVal="S.No";
                    hwid = 80;
                    pinnedLeft = "left";
                }
                if(z == "Wagon Type")
                {
                    hwid = 120;
                }
                if(z == "Upper Deck height" || z == "Lower Deck height")
                {
                    keyVal=z.replace("Deck","");
                    hwid = 100;
                }
                columns.push({
                    headerName:keyVal,
                    colId:z,
                    field:z,
                    width:hwid,
                    pinned:pinnedLeft,
                    editable: false,
                    resizable:true,
                    filter:true,
                    suppressMenu:true,
                    sortable:false,
                    cellStyle:function(params){
                        if(z == "Wagon Type")
                        {
                            if(params.data.sr_no <= 8)
                            {
                                return {backgroundColor: '#ff8181'};
                            }
                            else if(params.data.sr_no > 8 && params.data.sr_no <= 17)
                            {
                                return {backgroundColor: '#c1b6b6'};
                            }
                            else
                            {
                                return {backgroundColor: '#7a5e5e'};
                            }
                        }
                    },
                    valueGetter:function(params){
                        if(z == "Wagon Type")
                        {
                            var wType = params.data[z];
                            return wType.split("_")[1];
                        }
                        else if(z == "sr_no")
                        {
                            return parseInt(params.data.sr_no)+1
                        }
                        else
                        {
                            return params.data[z];
                        }
                    }
                })
            }
        })
        var defs = {};
        defs.headerName = "Lower deck";
        defs.headerClass = ["cellstylegridB","cellTopHeader"];
        var cdefs = [];
        await keys.forEach(async (b, t) => {
            if(b.includes("type") == false)
            {
                if(b.includes("lower") == true)
                {
                    var childdefs = {};
                    childdefs.headerName = b.split("_")[1];
                    childdefs.field = b;
                    childdefs.width = 50;
                    childdefs.cellStyle = function(params){
                        if(params.data[b+"_type"] == "ORIGINAL")
                        {
                            return {backgroundColor: '#26c6da',borderLeft: '1px solid #ccc'};
                        }
                        else if(params.data[b+"_type"] == "EMPTY")
                        {
                            return {backgroundColor: '#8b8080',borderLeft: '1px solid #ccc'};
                        }
                        else{
                            return {backgroundColor: 'orange',borderLeft: '1px solid #ccc'};
                        }
                    }
                    childdefs.valueGetter = function(params){
                        if(params.data[b] !="EMPTY")
                        {
                            return params.data[b]
                        }
                        else
                        {
                            return "";
                        }
                    }
                    cdefs.push(childdefs)
                }
            }
        })
        defs.children = cdefs
        columns.push(defs)

        
        var defs1 = {};
        defs1.headerName = "Upper deck";
        defs1.headerClass = ["cellstylegridB","cellTopHeader"];
        var cdefs1 = [];
        await keys.forEach(async (c, k) => {
            if(c.includes("type") == false)
            {
                if(c.includes("upper") == true)
                {
                    var childdefs1 = {};
                    childdefs1.headerName = c.split("_")[1];
                    childdefs1.field = c;
                    childdefs1.width = 50;
                    childdefs1.cellStyle = function(params){
                        if(params.data[c+"_type"] == "ORIGINAL")
                        {
                            return {backgroundColor: '#26c6da',borderLeft: '1px solid #ccc'};
                        }
                        else if(params.data[c+"_type"] == "EMPTY")
                        {
                            return {backgroundColor: '#8b8080',borderLeft: '1px solid #ccc'};
                        }
                        else{
                            return {backgroundColor: 'orange',borderLeft: '1px solid #ccc'};
                        }
                    }
                    childdefs1.valueGetter = function(params){
                        if(params.data[c] !="EMPTY")
                        {
                            return params.data[c]
                        }
                        else
                        {
                            return "";
                        }
                    }
                    cdefs1.push(childdefs1)
                }
            }
        })
        defs1.children = cdefs1
        columns.push(defs1);

        console.log(columns,"columns")
        this.setState({
            columnwithDefs:columns,
            loadshow : "show-n",
            overly : "show-n",
        });
    }
}
loadChartData = () =>{
    var rowData = this.state.rowData;
    var wagonType = groupBy(rowData, rdata => rdata.wagon_type);
    var keyData = [];
    wagonType.forEach((wagonData, wtKey) =>{
        var carType = groupBy(wagonData, rdata => rdata.car_name);
        var uniqueCars = [];
        carType.forEach((carData, carKey) =>{
            uniqueCars.push({
                carType : carKey,
                carsLength : carData.length,
            })
        })
        keyData.push({
            wagonType:wtKey,
            wagonData:wagonData,
            uniqueCars:uniqueCars
        })
        uniqueCars = [];
    })
    console.log(keyData,"keyData")
    var respData = [{}];
    var categories = [];  
    var drills=[]
    keyData.map(function(kd){
        respData.push({
            "name" : kd.wagonType,
            "showTooltip": 1,
            "y" : parseFloat(kd.wagonData.length),
            drilldown: kd.wagonType
        });
        var sdril = []
        kd.uniqueCars.map(function(uc){
            sdril.push([uc.carType, uc.carsLength])
        })
        drills.push({
            name: kd.wagonType,
            id: kd.wagonType,
            data: sdril
        })
        categories.push(kd.wagonType);
    });
    this.setState({
        seriesData : respData,
        categories : categories,
        drildown:drills,
    });
}

onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
    var sort = [
        {
          colId: 'sr_no',
          sort: 'asc',
        },
      ];
    this.gridApi.setSortModel(sort);
};
onGridState = () =>{
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
    * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
            
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
}


onGridReady1 = params => {
    this.gridApi1 = params.api;
    
    //console.log("Grid is: ", params);
    this.gridColumnApi1 = params.columnApi;

    
};


onGridState1 = () =>{
     //console.log(this.gridApi);
    
     /*Get  Current Columns State and Store in this.colState */
       this.colState1 = this.gridColumnApi1.getColumnState();

     /*Get Current RowGroup Columns State and Store in this.rowGroupState */
       this.rowGroupState1 = this.gridColumnApi1.getRowGroupColumns();

     /*Get Current Pivot Columns State and Store in this.pivotColumns, 
      * User should enable the Pivot mode.*/
       this.pivotColumns1 = this.gridColumnApi1.getPivotColumns();
                
     /*Get Current Filter State and Store in window.filterState */
       window.filterState1 = this.gridApi1.getFilterModel();
       this.gridApi1.setFilterModel(window.filterState1);
       
       
}

onGridReady2 = params => {
    this.gridApi2 = params.api;
    
    //console.log("Grid is: ", params);
    this.gridColumnApi2 = params.columnApi;

    
};


onGridState2 = () =>{
     //console.log(this.gridApi);
    
     /*Get  Current Columns State and Store in this.colState */
       this.colState2 = this.gridColumnApi2.getColumnState();

     /*Get Current RowGroup Columns State and Store in this.rowGroupState */
       this.rowGroupState2 = this.gridColumnApi2.getRowGroupColumns();

     /*Get Current Pivot Columns State and Store in this.pivotColumns, 
      * User should enable the Pivot mode.*/
       this.pivotColumns2 = this.gridColumnApi2.getPivotColumns();
                
     /*Get Current Filter State and Store in window.filterState */
       window.filterState2 = this.gridApi1.getFilterModel();
       this.gridApi1.setFilterModel(window.filterState2);
       
       
}

closeAlert = () => {
    this.setState({
        show: false
    });
}

onCellClicked(e)
{
    if(e.colDef.field == "map")
    {
        var coordinates = e.data.coordinates;
        this.setState({
            coordinates : coordinates,
            //loadshow : "show-m",
            overly :"show-m",
            bulkslide : "slider-translate-60p"
        })
    }
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}
showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate-60p"
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}

handleDownloadOutputFile = (idx) => {
    var outputFile = idx;
    console.log(outputFile)
    if(outputFile !='')
    {
        var rngParam = {
            outputFile : outputFile,
        }
        redirectURL.post("/rakeplanning/downloadOutputFile",rngParam).then((response)=>{
            console.log("download response ",response)
            download(response.data, outputFile)
            if(response.data.message == "success")
            {
                alert("success");
            }
            
        }).catch(function(error){
            console.log(error);
        })
    }
}

downloadOutputFile = (transactionId) => {
    var params = {
        columnKeys: ['wagon_no', 'wagon_type', 'deck_type', 'deck_height', 'car_name', 'car_code', 'car_length', 'car_height', 'load_factor', 'remarks'],
        fileName: transactionId+"_output",
    };
    this.gridApi2.exportDataAsCsv(params);
}

processRecommendations = () => {
    var carPlanDetails = this.state.carPlanDetails;
    var params = {
        transaction_id : this.state.transactionId,
        transporter_code : carPlanDetails[0].transporter_code,
        rake_type : carPlanDetails[0].rake_type,
        loading_plant : carPlanDetails[0].loading_plant,
    }
    this.setState({
        loadshow:'show-m',
        overly:'show-m',
    });
    redirectURL.post("/rakeplanning/processRecommendationsForCarPlan",params).then((response)=>{
        console.log(response,"responseFromProcess")
        var statusCode = response.data.status;
        if(statusCode == 1)
        {
            this.setState({                    
                basicTitle : response.data.message,
                basicType : "success",
                show : true,
                loadshow:'show-n',
                overly:'show-n',
                bulkslide:"",
            });
            window.location.reload();
        }
        else if(statusCode == 2)
        {
            this.setState({                    
                basicTitle : response.data.message,
                basicType : "warning",
                show : true,
                loadshow:'show-n',
                overly:'show-n',
                bulkslide:"",
            });
        }
        else
        {
            this.setState({                    
                basicTitle : response.data.message,
                basicType : "warning",
                show : true,
                loadshow:'show-n',
                overly:'show-n',
                bulkslide:"",
            });            
        }
    });
}
  render(props) {
   

    var columnwithDefs = [      
        {
            headerName: "Wagon Type",
            field: "wagon_type",
            width:150,
            filter: true,
            resizable: true,
            editable:false,   
        },
        {
            headerName: "Car Name",
            field: "car_name",
            width:150,
            filter: true,
            resizable: true,
            editable:false,     
        },
        {
            headerName: "Load Factor",
            field: "load_factor",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
        },
    ]
    var columnwithDefs1 = [   
        {
            headerName: "Car Code",
            field: "car_code",
            width:80,
            filter: true,
            resizable: true,
            editable:true,      
        },    
        {
            headerName: "Car Model",
            field: "car_name",
            width:130,
            filter: true,
            resizable: true,
            editable:true,      
        },   
        {
            headerName: "Input Cars",
            field: "input_cars",
            width:100,
            filter: true,
            resizable: true,
            editable:true,      
        },   
        {
            headerName: "Planned Cars",
            field: "cars_original_count",
            width:100,
            filter: true,
            resizable: true,
            editable:true,      
        },  
        {
            headerName: "Recomended Cars",
            field: "cars_reco_count",
            width:120,
            filter: true,
            resizable: true,
            editable:true,      
        },
        {
            headerName: "Load Factor",
            field: "car_load_factor",
            width:100,
            filter: true,
            resizable: true,
            editable:true,
        },
        {
            headerName: "Total Load Factor",
            field: "load_factor",
            width:100,
            filter: true,
            resizable: true,
            editable:true,
        },
    ]

    var columnwithDefs2 = [
        {
            headerName: "wagon_no",
            field: "wagon_no",
            valueGetter: function(params){
                return parseInt(params.data.wagon_no)+1;
            }
        },
        {
            headerName: "wagon_type",
            field: "wagon_type",
        },
        {
            headerName: "deck_type",
            field: "deck_type",
        },
        {
            headerName: "deck_height",
            field: "deck_height",
        },
        {
            headerName: "car_name",
            field: "car_name",
        },
        {
            headerName: "car_code",
            field: "car_code",
        },
        {
            headerName: "car_length",
            field: "car_length",
        },
        {
            headerName: "car_height",
            field: "car_height",
        },
        {
            headerName: "load_factor",
            field: "load_factor",
        },
        {
            headerName: "remarks",
            field: "remarks",
        },
    ];
    
    var basestring = this.state.transactionId;
    var encryptedstring = window.btoa(basestring);
    var htmloption = "/carsplanning/"+encryptedstring;
    return (
      <div class="container-fluid" style={{marginTop:"2%"}}>
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="card col-xl-12">
            <div className="card-header">
                <h5>
                    <i className="icofont icofont-train-line cus-i"></i> <span>#{(this.state.transactionId)}</span>
                    <a href="/carsplansummary" className="float-right"><button className="btn btn-warning">Back</button></a>
                    <a href="javascript:;" onClick={() => this.downloadOutputFile(this.state.transactionId)} style={{float:"right",marginRight:"10px"}} className="btn btn-info"> Download Output File</a>
                    <a href="javascript:;" onClick={() => this.handleDownloadOutputFile(this.state.transactionId+"_reco_output_file.csv")} style={{float:"right",marginRight:"10px"}} className="btn btn-danger"> Download Recommendations File</a>
                    <a className={this.state.recoShow+" btn btn-info"} href="javascript:;" onClick={() => this.processRecommendations()} style={{float:"right",marginRight:"10px"}} > Recommendations</a>
                </h5>
            </div>
            <div className="card-body col-xl-12 col-lg-12">
                {/* <div className="col-xl-12">
                    <div>
                        <CarSummaryColumnChart
                        title={"Cars Data"}
                        subtitle={""}
                        context={this}
                        series={this.state.seriesData}
                        categories={this.state.categories}
                        seriesName=""
                        yaxistitle="No. of Cars"
                        drilldownseries={{"series":this.state.drildown}}
                        />
                    </div>
                </div> */}
                <div className="row col-xl-12 col-lg-12">
                    <p className="col-sm-8" style={{fontSize:"15px"}}>Total Cars Planned - <b>{this.state.totalCarsPlanned.length}</b></p>
                    <div className="col-sm-2">
                        <a href={htmloption} className="btn btn-primary" style={{float:"right",marginRight:"10px"}} >Edit</a>
                    </div>
                    <div className="col-sm-2">
                        <button className="btn btn-info" style={{float:"right",marginRight:"10px"}} onClick={this.showBulkUpload.bind(this)}>View Summary</button>
                    </div>
                </div>
                <div id="myGrid" style={{width:"100%",height:"600px"}} className={"ag-theme-balham "+this.state.showGridData}>    
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={this.state.columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.apiData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClicked}
                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                    />
            </div>
            </div>
        </div>

        
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">Cars Summary</h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                <div id="myGrid1" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.showGridData}>
                    <AgGridReact
                        modules={this.state.modules}
                        columnDefs={columnwithDefs1}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.carData}
                        enableCharts={false}
                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                        onGridReady={this.onGridReady1}
                        onGridState={this.onGridState1}
                        frameworkComponents={this.state.frameworkComponents}
                        statusBar={this.state.statusBar}
                        sideBar={this.state.sideBar}
                        stopEditingWhenGridLosesFocus= {true}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={true}
                        pinnedBottomRowData={this.state.pinnedRegionBottomRowData}
                        gridOptions={{
                            context: { componentParent: this },
                            getRowStyle: function (params) {
                                    if (params.node.rowPinned) {
                                return { 'font-weight': 'bold','font-size':'16px' };
                                }
                            },
                            suppressRowTransform: true,
                        }}
                        // components={this.state.components}
                        enableRangeSelection= {true}
                        //rowSelection={false}
                        //onRowSelected={this.onRowSelection.bind(this)}
                        // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                        />
                </div>
            </div>
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
        <div id="myGrid2" style={{width:"100%",height:"478px"}} className={"ag-theme-balham show-n"}>
            <AgGridReact
                modules={this.state.modules}
                columnDefs={columnwithDefs2}
                defaultColDef={this.state.defaultColDef}
                rowData={this.state.downloadOutPutData}
                enableCharts={false}
                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                onGridReady={this.onGridReady2}
                onGridState={this.onGridState2}
                frameworkComponents={this.state.frameworkComponents}
                statusBar={this.state.statusBar}
                sideBar={this.state.sideBar}
                stopEditingWhenGridLosesFocus= {true}
                paginationPageSize={this.state.paginationPageSize}
                pagination={true}
                gridOptions={{
                    context: { componentParent: this }
                }}
                // components={this.state.components}
                enableRangeSelection= {true}
                />
        </div>
      </div>

    );
  }
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

// function SortByName(a, b){
//     var aName = a.name.toLowerCase();
//     var bName = b.name.toLowerCase(); 
//     return ((aName < bName) ? -1 : ((aName > bName) ? 1 : 0));
// }
  
import React, { Component } from 'react';
import _ from "lodash";
// import MarkerClusterer from "@google/markerclusterer";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

import { 
	getHyphenDDMMMYYYYHHMM} from '../common/utils'; 
var redirectURL = require('../redirectURL');

var infoBox = require('../common/google-infowindow');

var marker;
var circle;
var map;
var rad = function(x) {
	return x * Math.PI / 180;
  };
var rlatitude;
var rlongitude;

export default class LEDLiveTruckMapComponent extends Component {
    constructor(props) {
		super(props);
		this.displayData = [];
        this.state = {
			pageTitle: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			radius:'5000',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			alldepts:[],
			transportervalue:'ALL',
			alltransporters:[],
			truckstatus:'0',
			kpicode:'0',
			deptcode:'',
			truckNoList:[],
			alltrucks:[],
			truckno:'',
			showTrucksList : "show-n",
			filteredData : [],
			triptype :{"value":1,"label":"ALL"},
			plantLocations : [],
			totalTrucksCount : 0,
			onTripCounter : 0,
			typeCount:0,
			typeText:'',
			total_trucks:0,
            screenType: ""
			
		};
		this.popmarker=this.popmarker.bind(this);
	}
	
	componentDidMount(){
		
       console.log("Props ",this.props)
        var loc = this.props.location.search;
        var splitloc = loc.split("&")
        console.log("splitloc ", splitloc)
        var stype = splitloc[0].split("=")
        var type = stype[1]

        var sdept = splitloc[1].split("=")
		var dept = sdept[1]
		
        var sevt = splitloc[2].split("=")
		var slide = sevt[1]
		

        var scevt = splitloc[3].split("=")
		var cslide = scevt[1]
		
		var screen = "laptop";
		if(splitloc.length > 4)
		{
			screen = splitloc[4].split("=")
			screen = screen[1]
			console.log(screen,"screen")
		}
		this.setState({
			screenType: screen,
			loadshow: 'show-m'
		})
		

        
        if(dept == 'prt')
        {
            var deptcode = "LOG-PRT"
        }
        if(dept == 'tnp')
        {
            var deptcode = "LOG-TNP"
        }
       
		if(type == 2)
		{
			var typetext = "On Trip";
		}
		
		if(dept == 'tnp' && type == 5){
			var typetext = "At Shop";
		}else if(type == 5)
		{
			var typetext = "At dealer";
		}

		
		this.setState({
			typeText:typetext
		})
        var formdata = {
			radius:10000,
			latitude:"20.73568784876339",
			longitude:"79.38999444931353",
			dept_code:deptcode,
			status:0,
			trip_type : type
		}
		rlatitude="20.73568784876339";
		rlongitude="79.38999444931353";
		if(parseInt(slide)-1 == cslide)
		{
			redirectURL.post('/vicinity/getRadiusCoordinates',formdata,{
				headers:{
					'Content-Type':'application/json',
					'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"
	
				}
			})
			.then(
				(response) =>{
			
				var markers = JSON.parse(JSON.parse(response.data.apiData.body).trucks_data);
				var total_trucks = JSON.parse(JSON.parse(response.data.apiData.body).total_trucks);
	
				if(markers.length > 0)
				{
					this.setState({
						defaultradius:formdata.radius,
						coordinates:markers,
						onTripCounter : JSON.parse(response.data.apiData.body).gps_trucks,
						mwidth:"col-xl-12 col-lg-12",
						rowData:markers,
						radius:formdata.radius,
						// loadshow:'show-n',
						showform:'show-n',
						showrefresh:'show-m',
						rlatitude:formdata.latitude,
						rlongitude:formdata.longitude,
						typeCount:markers.length,
						total_trucks:total_trucks
					});
					redirectURL.post("/weather/getPlants").then((rsp)=>{
						this.setState({
							plantLocations : rsp.data,
						});
						//console.log(rsp.data);
						this.renderMap();
						})
					
					
				}
					
			})
			.catch(function(error){
				console.log(error);
			});
			
			this.renderMap();
		}
		


    }

   
	renderMap = () => {
    	
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
 	}
	
	 initMap = () => {
		var allcords=[];
		this.state.coordinates.map((marker) => {
			allcords.push(marker);
		});
		
		var contentdata = this.state.contentString;
		if(this.state.rlatitude == '' && this.state.rlongitude == '')
		{
			var lt=21.144644112601775;
			var ln=79.08860126768066;
		}
		else{
			var lt=this.state.rlatitude;
			var ln=this.state.rlongitude;
		}
		try{
			map = new window.google.maps.Map(document.getElementById('map_canvas'), {
				zoom: 4,
				center: new window.google.maps.LatLng(lt,ln),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP,
				
			});
		}
		catch(e){
			map = new window.google.maps.Map(document.getElementById('map_canvas'), {
				zoom: 4,
				center: new window.google.maps.LatLng(parseFloat(21.144644112601775),parseFloat(79.08860126768066)),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP,
		
			});
		}
    	
		circle = new window.google.maps.Circle({
            map: map
		});
		
		marker = new window.google.maps.Marker({
			map: map,
			draggable: true,
			animation: window.google.maps.Animation.DROP,
			
		});
		
		marker.addListener('click', function(){
			marker.setAnimation(window.google.maps.Animation.BOUNCE)			
		});
		
  		window.google.maps.event.addListener(map, 'click', function(event) {
			
			marker.setPosition(event.latLng);
			
		});
		circle.bindTo('center', marker, 'position');
		var markerLatlng1;
		var markers = allcords.map((marker) => {
			 if(marker.status == 2)
			{
				var truckText =  "Going to Dealer";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_going_24.png');
			}
			else if(marker.status == 5)
			{
				var truckText =  "Left Destination Location";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_leftdealer_24.png');
			}
		
			else{
				var truckText =  marker.truck_no;
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_na_24.png');
			}
			var image =  require('../../assets/icons/truck_na_24.png');
			var contentarr = []
			var headerTitle = truckText
			//contentarr.push({"key":"Truck No", "value":marker.truck_no});
			contentarr.push({"key":"Transporter Name", "value":marker.transporter_name});
			//contentarr.push({"key":"Distance from geofence", "value":marker.distance});
			contentarr.push({"key":"GPS Provider", "value":marker.actual_lspuser});
			contentarr.push({"key":"Last Packet Time", "value":getHyphenDDMMMYYYYHHMM(marker.timestamp)});
			contentarr.push({"key":"Speed (km/h)", "value":Math.ceil(marker.speed)});
			contentarr.push({"key":"Address", "value":marker.area+", "+marker.state});
			
			var contentString = infoBox(image, headerTitle, contentarr, '')

			
			
			var infowindow = new window.google.maps.InfoWindow({
				content: contentString
			});
			
			var markerLatlng = new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude));
			
			
			 if(marker.status == 2)
			{
				var image =  require('../../assets/icons/truck_going_24.png');
			}
			else if(marker.status == 3 || marker.status == 4) 
			{
				var image =  require('../../assets/icons/truck_atdealer_24.png');
			}
			
			else{
				var image =  require('../../assets/icons/truck_na_24.png');
			}
			var image =  require('../../assets/icons/truck_na_24.png');
		
			
			
			var mark = new window.google.maps.Marker({
                position: markerLatlng,
				map: map,
				title:"Status: "+truckText+" #"+marker.truck_no,
				icon: image
            });
			mark.addListener('click', function() {
				infowindow.open(map, mark);
			  });
			return mark
            mark.setMap(map);
		});
		var eventtrigger = document.getElementsByClassName('truck');
	
		const markerCluster = new MarkerClusterer({ map, markers });

		  // var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
		  //map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
		if(this.state.plantLocations.length > 0){
			this.state.plantLocations.map(function(params){
				var plant = {
				url: require("../../assets/icons/"+params.icon), // url
				scaledSize: new window.google.maps.Size(32,32), // scaled size
				origin: new window.google.maps.Point(0,0), // origin
				anchor: new window.google.maps.Point(0, 0) // anchor
				};
				var coords = JSON.parse(params.coordinates);
				var plantmarker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(coords.lat,coords.lng),
					map: map,
					icon: plant,
					title : params.plant_name
				});
				
			})
		}
		this.setState({
			loadshow: 'show-n'
		});
		 
	}
	toggleBounce() {
		if (marker.getAnimation() !== null) {
		  marker.setAnimation(null);
		} else {
		  marker.setAnimation(window.google.maps.Animation.BOUNCE);
		}
		
	  }
	
	getDistance = (p1, p2) => {
		//console.log("p1",p1);
		//console.log("p2",p2);
		var R = 6378137; // Earth’s mean radius in meter
		var dLat = rad(p2.lat - p1.lat);
		var dLong = rad(p2.lng - p1.lng);
		var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c;
		return d/1000; // returns the distance in meter
	  };
	onShowInfo = (e) =>{
		console.log(JSON.parse(e.target.id));
		this.setState({
			contentString:"Here Success"
		});
		var ourMarker = e.target.id;
 			window.google.maps.event.trigger(ourMarker, 'click');
		var infowindow = new window.google.maps.InfoWindow({
			content: "Here Success"
		  });
		infowindow.open(map, marker);

	}
	popmarker(e)
	{
		//console.log("OnClick Table ",e.data)
		var lat = parseFloat(e.data.latitude);
		var lng =parseFloat(e.data.longitude);
		var data =e.data;
		map.setCenter(new window.google.maps.LatLng(lat,lng));
		map.setZoom(22);
		var markerLatlng = new window.google.maps.LatLng(lat, lng);
		var image =  require('../../assets/icons/truckblue.png');
		var marker = new window.google.maps.Marker({
			position: markerLatlng,
			map: map,
			title:data.truck_no,
			icon:image
		});
		
		var contentarr = []
		var headerTitle = data.truck_no
		//contentarr.push({"key":"Truck No", "value":marker.truck_no});
		contentarr.push({"key":"Transporter Name", "value":data.transporter_name});
		//contentarr.push({"key":"Distance from geofence", "value":marker.distance});
		contentarr.push({"key":"GPS Provider", "value":data.actual_lspuser});
		contentarr.push({"key":"Last Packet Time", "value":getHyphenDDMMMYYYYHHMM(data.timestamp)});
		contentarr.push({"key":"Speed (km/h)", "value":Math.ceil(data.speed)});
		contentarr.push({"key":"Address", "value":data.area+", "+data.state});
		
		var contentString = infoBox(image, headerTitle, contentarr, '')
		var infowindow = new window.google.maps.InfoWindow({
			content: contentString
		});
		
		marker.setAnimation(window.google.maps.Animation.DROP)
		marker.addListener('click', function() {
			infowindow.open(map, marker);
		});
		
		marker.setMap(map);
		
		
	}

    render(){
		var screenType = this.state.screenType;
		console.log(screenType,"screenType")
		var screenHeight = (screenType != "laptop")?window.screen.availHeight.toString()+"px":"340px";
			
    	const dStyles={
			width:'100%',
			marginLeft:'0px',
			paddingLeft:'0px',
			height: screenHeight
		}
		const btstye={
			marginBottom:"10px"
		}
		
        return (
            
    		<div className="container-fluid">
				<div className="col-xl-12 n-p-0">
					<h3 className="f16">
						{this.state.typeText} - Total Trucks: <span className="redfont f24">{this.state.total_trucks}</span> / With GPS (Past 24Hrs): <span className="redfont f24">{this.state.typeCount}</span>
						
				
					</h3>
				</div>
                <div id="map_canvas" style={dStyles}>Loading....</div>
                                   
            </div>
              
        );
    }
}


function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}





import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';


import Counters from '../layouts/consignmentkpicountersComponent';

import CountUp from 'react-countup';

import { AgGridReact } from "@ag-grid-community/react";

import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";

import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from "react-select";

import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';

import Highcharts from 'highcharts';

var redirectURL = require("../redirectURL");


export default class TargetvsAchievement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterType: 'All',
      monthFilterSelected: 'All',
      totalSumedUpValues: [],
      monthSelected: [],
      loadshow: "show-m",
      overly: "show-m",
      totalSumedUpValuesFromMonth: [],
      facility: "All Facilities",
      facilitiesList: [{ "label": "All Facilities", "value": "All" },
      { "label": "Haryana", "value": "H" },
      { "label": "Bidadi", "value": "BP" }
        , { "label": "Gujarat", "value": "G" },
      { "label": "TVP-B", "value": "B" },
      { "label": "TVP-N", "value": "N" },
      { "label": "TVP-S", "value": "I" }],
      selectMonth: 'All',
      monthList: [
        { label: "All", value: "All" },
        { label: "Apr-24", value: "Apr-24" },
        { label: "May-24", value: "May-24" },
        { label: "Jun-24", value: "Jun-24" },
        { label: "Jul-24", value: "Jul-24" },
        { label: "Aug-24", value: "Aug-24" },
        { label: "Sep-24", value: "Sep-24" },
        { label: "Oct-24", value: "Oct-24" },
        { label: "Nov-24", value: "Nov-24" },
        { label: "Dec-24", value: "Dec-24" },
        { label: "Jan-25", value: "Jan-25" },
        { label: "Feb-25", value: "Feb-25" },
        { label: "Mar-25", value: "Mar-25" }
      ]

    }

  }

  componentDidMount() {
    this.setState({
      loadshow: "show-m",
      overly: "show-m"
    })
    const { transporterCode } = this.props.match.params;
    console.log(transporterCode, 'transporterCode');
    this.setState({
      transporter_code: transporterCode
    });

    var params = {
      transporter_code: transporterCode
    };

    this.getDataForFirstChart(params)
   this.monthfilter({ label: "All", value: "All" })
  }

  getDataForFirstChart = async (params) => {
    await redirectURL
      .post("/consignments/getTargetAchievementData", params)
      .then((response) => {
        console.log(response.data);

        // Filter data to include only entries where the month starts with "FY"
        const filteredData = response.data.filter(item => item.month.startsWith('FY'));
        // console.log(filteredData, 'filteredData');

        // Group data by the "month" field and sum up "ontime_delivery" values for each FY year
        const aggregatedData = filteredData.reduce((result, item) => {

          const year = item.month; // Assuming the "month" field contains the FY year
          if (!result[year]) {
            result[year] = {
              month: year,
              target: 0,
              achievement: 0,
              target_percentage: 0,
              count: 0
            };
          }
          // Check if ontime_delivery is not null, then add it to the sum
          if (item.parameters) {
            result[year].target += item.parameters.target;
            result[year].achievement += item.parameters.achievement;
            result[year].target_percentage += item.parameters.target_percentage;
            result[year].count += 1;
          }
          return result;
        }, {});
        console.log(aggregatedData, "aggregatedData")
        // Convert the aggregated data object into an array
        const aggregatedDataArray = Object.values(aggregatedData);
console.log(aggregatedDataArray,'aggregatedDataArray')
        
        let sortedAndFilteredArray = aggregatedDataArray;
        console.log(sortedAndFilteredArray, 'sortedAndFilteredArray');
        sortedAndFilteredArray = sortedAndFilteredArray.map((e) => {
          return ({
            month: e.month,
            target: e.target,
            achievement: e.achievement,
            target_percentage: e.target == 0 ? 0 : parseFloat(((e.achievement / e.target) * 100).toFixed(2))
          })
        })
        console.log(sortedAndFilteredArray, 'sortedAndFilteredArray');

        // Initialize an object to store data for individual months within the current fiscal year
        const currentFiscalYearMonthsData = {};
        // var month_ontime_delivery = 0
        var month_total_trips_delivery = 0
        var month_late_trips_delivery = 0
        response.data.forEach((item) => {
          const month = item.month;
          // Check if the month is within the current fiscal year ("Apr-23" to "Mar-24")
          if (!month.startsWith('FY')) {
            if (!currentFiscalYearMonthsData[month]) {
              currentFiscalYearMonthsData[month] = [];
            }
            // Add the ontime_delivery value for this month
            if (item.parameters) {
              currentFiscalYearMonthsData[month].push({
                "target": item.parameters.target,
                "achievement": item.parameters.achievement,
                "target_percentage": item.parameters.target_percentage
              });
              // month_ontime_delivery += parseInt(item.parameters.ontime_delivery)
              // month_late_trips_delivery += parseInt(item.parameters.late_trips)
              // month_total_trips_delivery += parseInt(item.parameters.total_trips)
            }
          }
        });

        // Sort the data as needed
        console.log(currentFiscalYearMonthsData, 'currentFiscalYearMonthsData')

        const monthsToExtract = DynamicSortOfMonths()

        console.log(monthsToExtract, "monthsToExtract")

        const filteredDataArray = [];
        monthsToExtract.forEach(month => {
          if (currentFiscalYearMonthsData.hasOwnProperty(month)) {
            let target = 0;
            let achievement = 0;
            let target_percentage = 0
            let count = 0
            currentFiscalYearMonthsData[month].map(e => {
              // if(e.ontime_delivery!=0){
              target += e.target;
              achievement += e.achievement;
              target_percentage += e.target_percentage
              count += 1
              // }

            })
          if(target != 0 || achievement != 0){
            filteredDataArray.push({
              month: month,
              target: parseFloat(target.toFixed(2)),
              achievement: parseFloat(achievement.toFixed(2)),
              target_percentage: target == 0 ? 0 : parseFloat(((achievement / target) * 100).toFixed(2))
            });
          }
          }
        });

        // console.log(filteredDataArray,"158")
        // Print the filtered data array or use it as needed
        // console.log(filteredDataArray, 'filteredDataArray',sortedAndFilteredArray,'sortedAndFilteredArray');
        const joinedArray = sortedAndFilteredArray.concat(filteredDataArray);
        // console.log(joinedArray,'joinedArray')
        joinedArray.sort((a, b) => {

          const [aMonth, aYear] = a.month.split('-');
          const [bMonth, bYear] = b.month.split('-');

          const monthMap = {
            'Jan': 1, 'Feb': 2, 'Mar': 3, 'Apr': 4, 'May': 5, 'Jun': 6,
            'Jul': 7, 'Aug': 8, 'Sep': 9, 'Oct': 10, 'Nov': 11, 'Dec': 12
          };

          if (aYear !== bYear) {
            return aYear.localeCompare(bYear);
          }

          return monthMap[aMonth] - monthMap[bMonth];
        });

        this.setState({
          totalSumedUpValues: joinedArray,
          loadshow: 'show-n',
          overly: 'show-n',
        });
      })
      .catch((error) => {
        console.error(error);
      });

  }


  newFilter(event) {
    this.setState({
      filterType: event.value,
      overly: "show-m",
      loadshow: "show-m"
    })
    var params = {
      transporter_code: this.state.transporter_code,
      plant_code: event.value,
      type: 'targetAchievement'
    }
    //  console.log(params,'params')
    this.newFilter2(event)
    redirectURL
      .post("/consignments/onselectfacilityfilter", params)
      .then((response) => {
        // console.log(response.data,'filtereddata');

        // Filter data to include only entries where the month starts with "FY"
        const filteredData = response.data.filter(item => item.month.startsWith('FY'));
        // console.log(filteredData, 'filteredData');

        // Initialize an object to store data for individual months within the current fiscal year
        const currentFiscalYearMonthsData = {};

        // Iterate through the data to accumulate "target" and "achievement" values for each month within the current fiscal year
        response.data.forEach((item) => {
          const month = item.month;
          // Check if the month is within the current fiscal year ("Apr-23" to "Mar-24")
          if (!month.startsWith('FY')) {
            if (!currentFiscalYearMonthsData[month]) {
              currentFiscalYearMonthsData[month] = {
                target: [],
                achievement: [],
              };
            }
            // Add the "target" and "achievement" values for this month
            if (item.parameters) {
              if (item.parameters.target !== null) {
                currentFiscalYearMonthsData[month].target.push(item.parameters.target);
              }
              if (item.parameters.achievement !== null) {
                currentFiscalYearMonthsData[month].achievement.push(item.parameters.achievement);
              }
            }
          }
        });

        // Sort the data as needed
        // console.log(currentFiscalYearMonthsData,'currentFiscalYearMonthsData')

        // const monthsToExtract = [
        //   "Apr-23", "May-23", "Jun-23", "Jul-23", "Aug-23", "Sep-23", "Oct-23", "Nov-23", "Dec-23",
        //   "Jan-24", "Feb-24", "Mar-24"
        // ];
        const monthsToExtract = DynamicSortOfMonths()

        const filteredDataArray = [];
        monthsToExtract.forEach(month => {
          if (currentFiscalYearMonthsData.hasOwnProperty(month)) {
            if(currentFiscalYearMonthsData[month].target != 0 || currentFiscalYearMonthsData[month].achievement != 0){
            filteredDataArray.push({
              month: month,
              target: currentFiscalYearMonthsData[month].target,
              achievement: currentFiscalYearMonthsData[month].achievement,
            });
          }
          }
        });

        // Print the filtered data array or use it as needed
        // console.log(filteredDataArray, 'filteredDataArray');

        const joinedArray = filteredDataArray;

       

        this.setState({
          // totalSumedUpValues: joinedArray,
          overly: "show-n",
          loadshow: "show-n"
        });
        this.monthfilter(this.state.monthSelected)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  newFilter2 = (event) => {
    this.setState({
      filterType: event.value,
      loadshow: 'show-m',
      overly: 'show-m',
    })
    var params = {
      transporter_code: this.state.transporter_code,
      plant_code: event.value,
      type: 'targetVsAchievement'
    }
    console.log(params, 'params')
    redirectURL
      .post("/consignments/onselectfacilityfilter", params)
      .then((response) => {
        console.log(response.data, "439");

        // Filter data to include only entries where the month starts with "FY"
        const filteredData = response.data.filter(item => item.month.startsWith('FY'));
        // console.log(filteredData, 'filteredData');

        // Group data by the "month" field and sum up "ontime_delivery" values for each FY year
        const aggregatedData = filteredData.reduce((result, item) => {

          const year = item.month; // Assuming the "month" field contains the FY year
          if (!result[year]) {
            result[year] = {
              month: year,
              target: 0,
              achievement: 0,
              target_percentage: 0,
              count: 0
            };
          }
          // Check if ontime_delivery is not null, then add it to the sum
          if (item.parameters) {
            result[year].target += item.parameters.target;
            result[year].achievement += item.parameters.achievement;
            result[year].target_percentage += item.parameters.target_percentage;
            result[year].count += 1;
          }
          return result;
        }, {});
        console.log(aggregatedData, "aggregatedData")
        // Convert the aggregated data object into an array
        const aggregatedDataArray = Object.values(aggregatedData);

        // Sort the data as needed
        // aggregatedDataArray.sort((a, b) => {
        //   // Your sorting logic here
        // });
        let sortedAndFilteredArray = aggregatedDataArray;
        console.log(sortedAndFilteredArray, 'sortedAndFilteredArray');
        sortedAndFilteredArray = sortedAndFilteredArray.map((e) => {
          return ({
            month: e.month,
            target: e.target,
            achievement: e.achievement,
            target_percentage: e.target == 0 ? 0 : parseFloat(((e.achievement / e.target) * 100).toFixed(2))
          })
        })
        console.log(sortedAndFilteredArray, 'sortedAndFilteredArray');

        // Initialize an object to store data for individual months within the current fiscal year
        const currentFiscalYearMonthsData = {};
        // var month_ontime_delivery = 0
        var month_total_trips_delivery = 0
        var month_late_trips_delivery = 0
        response.data.forEach((item) => {
          const month = item.month;
          // Check if the month is within the current fiscal year ("Apr-23" to "Mar-24")
          if (!month.startsWith('FY')) {
            if (!currentFiscalYearMonthsData[month]) {
              currentFiscalYearMonthsData[month] = [];
            }
            // Add the ontime_delivery value for this month
            if (item.parameters) {
              currentFiscalYearMonthsData[month].push({
                "target": item.parameters.target,
                "achievement": item.parameters.achievement,
                "target_percentage": item.parameters.target_percentage
              });
              // month_ontime_delivery += parseInt(item.parameters.ontime_delivery)
              // month_late_trips_delivery += parseInt(item.parameters.late_trips)
              // month_total_trips_delivery += parseInt(item.parameters.total_trips)
            }
          }
        });

        // Sort the data as needed
        console.log(currentFiscalYearMonthsData, 'currentFiscalYearMonthsData')

        const monthsToExtract = DynamicSortOfMonths()

        console.log(monthsToExtract, "monthsToExtract")

        const filteredDataArray = [];
        monthsToExtract.forEach(month => {
          if (currentFiscalYearMonthsData.hasOwnProperty(month)) {
            let target = 0;
            let achievement = 0;
            let target_percentage = 0
            let count = 0
            currentFiscalYearMonthsData[month].map(e => {
              // if(e.ontime_delivery!=0){
              target += e.target;
              achievement += e.achievement;
              target_percentage += e.target_percentage
              count += 1
              // }

            })
            
            if(target !== 0 || achievement !== 0){
            filteredDataArray.push({
              month: month,
              target: parseFloat(target.toFixed(2)),
              achievement: parseFloat(achievement.toFixed(2)),
              target_percentage: target == 0 ? 0 : parseFloat(((achievement / target) * 100).toFixed(2))
            });
          }
          }
        });

        // console.log(filteredDataArray,"158")
        // Print the filtered data array or use it as needed
        console.log(filteredDataArray, 'filteredDataArray');
        const joinedArray = sortedAndFilteredArray.concat(filteredDataArray);

        console.log(joinedArray, 'joinedArray')
        joinedArray.sort((a, b) => {

          const [aMonth, aYear] = a.month.split('-');
          const [bMonth, bYear] = b.month.split('-');

          const monthMap = {
            'Jan': 1, 'Feb': 2, 'Mar': 3, 'Apr': 4, 'May': 5, 'Jun': 6,
            'Jul': 7, 'Aug': 8, 'Sep': 9, 'Oct': 10, 'Nov': 11, 'Dec': 12
          };

          if (aYear !== bYear) {
            return aYear.localeCompare(bYear);
          }

          return monthMap[aMonth] - monthMap[bMonth];
        });
        this.setState({
          totalSumedUpValues: joinedArray,
          loadshow: 'show-n',
          overly: 'show-n',
        });
      })
      .catch((error) => {
        console.error(error);
      });

  }

  onClickCounter(data) {
    //this.props.context.onClickCounterShowData(data)

  }




  monthfilter(event) {
    this.setState({
      monthFilterSelected: event.value,
      monthSelected: event,
      overly: "show-m",
      loadshow: "show-m"
    });
    // console.log(this.state.monthSelected,'monthSelected')
    var params = {
      transporter_code: this.props.match.params.transporterCode,
      plant_code: this.state.filterType,
      month: event.value,
    };

    // console.log(params, 'params');

    redirectURL
      .post("/consignments/monthdatafilter", params)
      .then((response) => {
        // console.log(response.data, 'monthfilterdata');
        const filteredData = response.data.filter(item => item.month.startsWith('FY'));
        // console.log(filteredData, 'filteredDatainmonth');

        const currentFiscalYearClustersData = {};

        response.data.forEach((item) => {
          const cluster = item.cluster;
          if (!item.month.startsWith('FY')) {
            if (!currentFiscalYearClustersData[cluster]) {
              currentFiscalYearClustersData[cluster] = {
                target: [],
                achievement: [],
              };
            }
            if (item.parameters) {
              if (item.parameters.target !== null) {
                currentFiscalYearClustersData[cluster].target.push(item.parameters.target);
              }
              if (item.parameters.achievement !== null) {
                currentFiscalYearClustersData[cluster].achievement.push(item.parameters.achievement);
              }
            }
          }
        });

        // console.log(currentFiscalYearClustersData, 'currentFiscalYearClustersDatainmonth');

        const filteredDataArray = [];
        for (const cluster in currentFiscalYearClustersData) {
          if (currentFiscalYearClustersData.hasOwnProperty(cluster)) {
            if(currentFiscalYearClustersData[cluster].target != 0 || currentFiscalYearClustersData[cluster].achievement != 0){
              filteredDataArray.push({
                cluster: cluster,
                target: currentFiscalYearClustersData[cluster].target,
                achievement: currentFiscalYearClustersData[cluster].achievement,
              });
            }
          }
        }

        const joinedArray = filteredDataArray;


        this.setState({
          totalSumedUpValuesFromMonth: joinedArray,
          overly: "show-n",
          loadshow: "show-n"
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }




  handleDrilldown = (params) => {
    console.log("Here ,", params)
  }
  handleDrilldown1 = (params) => {
    console.log("Here ,", params)
  }
  onClickHideAll() {
    this.setState({
      loadshow: 'show-n',
      overly: 'show-n',
      bulkslide: "",
      bulkslidebulk: '',
    });

  }

  render() {
    const currentFY = "FY 24-25";
    const previousFY = "FY 23-24";
    const dStyles2 = {
      width: '100%',
      height: '100%'
    };
  
    const totalSumedUpValues = this.state.totalSumedUpValues.filter(item => {
      return item.target !== 0 || item.achievement !== 0;
  });
    const monthfilterPercentageData = this.state.totalSumedUpValuesFromMonth.map(ele => {
      if (ele.target && ele.achievement) {
        const totalTarget = ele.target.reduce((acc, value) => acc + value, 0);
        const totalAchievement = ele.achievement.reduce((acc, value) => acc + value, 0);
        const targetAverage = Math.round(totalTarget);
        const achievementAverage = Math.round(totalAchievement);
        const achievementPercentageincluster = (achievementAverage / totalTarget) * 100;
        return { targetAverage, achievementAverage, achievementPercentageincluster };
      } else {
        return { targetAverage: 0, achievementAverage: 0, achievementPercentageincluster: 0 };
      }
    });
    const clusterCategories = this.state.totalSumedUpValuesFromMonth.map((ele) => ele.cluster);
    const targetDatamonth = monthfilterPercentageData.map(data => data.targetAverage);
    const achievementDatamonth = monthfilterPercentageData.map(data => data.achievementAverage);
    const achievementPercentageinclusterend = monthfilterPercentageData.map(data => data.achievementPercentageincluster);

  
    const historicalOptions = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'Historical Volume Handling - Achievement Only (Cars)'
      },
      xAxis: {
        categories: totalSumedUpValues.filter(data => data.month.startsWith("FY")).map(data => data.month),
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Total Volume'
        }
      },
      tooltip: {
        shared: true,
        useHTML: true,
        formatter: function () {
          return this.points.reduce((s, point) => {
            return s + '<br/>' + point.series.name + ': ' + point.y.toLocaleString('en-IN');
          }, '<b>' + this.x + '</b>');
        }
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.y.toLocaleString('en-IN');
            },
            align: 'center',
            verticalAlign: 'top',
            inside: false,
          },
        }
      },
      series: [{
        name: 'Achievement',
        data: totalSumedUpValues
          .filter(data => data.month.startsWith("FY") && data.month !== currentFY )
          .map(data => data.achievement),
        color: '#92d864'
      }]
    };
  
    const currentFYOptions = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'Fiscal Year Volume Handling - Target vs Achievement (Cars)'
      },
      xAxis: {
        categories: [
          previousFY, 
          currentFY,
          ...totalSumedUpValues
            .filter(data => !data.month.startsWith("FY"))
            .map(data => data.month)
        ],
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Total Volume'
        }
      },
      tooltip: {
        shared: true,
        useHTML: true,
        formatter: function () {
          let tooltip = '<span style="font-size:10px">' + this.x + '</span><table>';
          this.points.forEach(point => {
            tooltip += '<tr><td style="color:' + point.color + ';padding:0">' + point.series.name + ': </td>' +
                       '<td style="padding:0"><b>' + Math.round(point.y).toLocaleString('en-IN') + '</b></td></tr>';
            if (point.series.name === 'Achievement') {
              const correspondingValue = point.point.achievementPercentage;
              if (correspondingValue !== undefined) {
                tooltip += '<tr><td style="color:#FF7F7F;padding:0">Achievement Percentage: </td>' +
                           '<td style="padding:0"><b>' + correspondingValue.toFixed(2) + '%' + '</b></td></tr>';
              }
            }
          });
          tooltip += '</table>';
          return tooltip;
        }
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            formatter: function () {
              let label = Math.round(this.y).toLocaleString('en-IN'); // Format the label for display only
              if (this.series.name === 'Achievement') {
                const correspondingValue = this.point.achievementPercentage;
                label += '<br>' + (correspondingValue !== undefined ? correspondingValue.toFixed(2) + '%' : '');
              }
              return label;
            },
            align: 'center',
            verticalAlign: 'top',
            inside: false,
          },
        }
      },
      series: [{
        name: 'Target',
        data: [
          totalSumedUpValues.find(data => data.month === previousFY) ? totalSumedUpValues.find(data => data.month === previousFY).target : 0,
          totalSumedUpValues.find(data => data.month === currentFY) ? totalSumedUpValues.find(data => data.month === currentFY).target : 0,
          ...totalSumedUpValues
            .filter(data => !data.month.startsWith("FY"))
            .map(data => data.target)
        ],
        color: '#2E75B6',
        showInLegend: true
      }, {
        name: 'Achievement',
        data: [
          totalSumedUpValues.find(data => data.month === previousFY) ? {
            y: totalSumedUpValues.find(data => data.month === previousFY).achievement,
            achievementPercentage: totalSumedUpValues.find(data => data.month === previousFY).target_percentage,
            color: totalSumedUpValues.find(data => data.month === previousFY).achievement >= totalSumedUpValues.find(data => data.month === previousFY).target ? '#92d864' : '#FF7F7F'
        } : { y: 0, achievementPercentage: 0, color: '#FF7F7F' },
    
          totalSumedUpValues.find(data => data.month === currentFY) ? {
              y: totalSumedUpValues.find(data => data.month === currentFY).achievement,
              achievementPercentage: totalSumedUpValues.find(data => data.month === currentFY).target_percentage,
              color: totalSumedUpValues.find(data => data.month === currentFY).achievement >= totalSumedUpValues.find(data => data.month === currentFY).target ? '#92d864' : '#FF7F7F'
          } : { y: 0, achievementPercentage: 0, color: '#FF7F7F' },
      
          // Handle the previous fiscal year data
        
          // Handle other months that do not start with "FY"
          ...totalSumedUpValues
              .filter(data => !data.month.startsWith("FY"))
              .map(data => ({
                  y: data.achievement,
                  achievementPercentage: data.target_percentage,
                  color: data.achievement >= data.target ? '#92d864' : '#FF7F7F' // Green if achievement >= target, else red
              }))
      ],
      
        showInLegend: true,
        color: '#92d864'
      }]
    };
  
    var highchartoptions2 = {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Target vs Achievement Cluster Wise Month (Cars)'
      },
      xAxis: {
        categories: clusterCategories,
        crosshair: true
      },
      yAxis: {
        title: {
          text: 'Target vs Achievement'
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        formatter: function () {
          let tooltip = '<table>';
          this.points.forEach(point => {
            tooltip += `<tr><td style="color:${point.color};padding:0">${point.series.name}: </td>` +
              `<td style="padding:0"><b>${point.y.toLocaleString('en-IN')}</b></td></tr>`;
            if (point.series.name === 'Achievement') {
              const correspondingValue = achievementPercentageinclusterend[point.point.index];
              tooltip += `<tr><td style="color:#FF7F7F;padding:0">Achievement Percentage: </td>` +
                `<td style="padding:0"><b>${correspondingValue.toFixed(2)}%</b></td></tr>`;
            }
          });
          return tooltip + '</table>';
        },
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          dataLabels: {
            enabled: false // Disable data labels on bars
          }
        }
      },
      series: [{
        name: 'Target',
        data: targetDatamonth.map(value => value !== null ? Math.round(value) : null),
        color: '#396BC5',
        showInLegend: true
      }, {
        name: 'Achievement',
        data: achievementDatamonth.map((value, index) => {
          return {
            y: value !== null ? Math.round(value) : null,
            color: achievementPercentageinclusterend[index] >= 100 ? '#92d864' : '#FF7F7F'
          };
        }),
        showInLegend: true,
        color: '#92d864'
      }]
    };
    return (
      <div className='row'>
        <div className={"dataLoadpage " + (this.state.loadshow)}></div>
        <div className={"dataLoadpageimg " + (this.state.loadshow)} style={{ position: "fixed", top: "43%" }}>
          <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
        <div style={{ textAlign: "center", background: "#171C8F", width: '100%', margin: "5px", borderRadius: "5px", height: '70px', paddingBottom: '25px' }}>
          <h6 class="txt-dark f40" style={{ marginTop: "0px" }}>
            <span style={{ fontSize: "20px", color: "#FFFFFF" }}>
              <span>Target vs Achievement</span>
            </span>
          </h6>
        </div>
  
        <div className="row card-body">
          <div className="col-xl-4">
            <label>Select Facility</label>
            <Select
              placeholder={"All Facility"}
              closeMenuOnSelect={true}
              value={this.state.facility.label}
              onChange={this.newFilter.bind(this)}
              style={{ borderRadius: "0px" }}
              options={this.state.facilitiesList} required
            />
          </div>
          <div className="col-xl-8 col-lg-8">
            <h5>
              <span className="float-right f12">
                <a className="btn btn-danger" style={{ float: "left", marginLeft: "10%" }} href="/lspceodashboard">Back</a>
              </span>
            </h5>
          </div>
        </div>
  
       
        <div className="col-xl-12 col-lg-12">
          <HighchartsReact
            highcharts={Highcharts}
            options={currentFYOptions}
            containerProps={{ style: { width: '100%', height: '100%' } }}
            onClick={this.handleDrilldown.bind(this)}
          />
        </div>
        <div className="col-xl-4" style={{ margin: '30px 30px 30px 0px' }}>
          <label>Select Month</label>
          <Select
            placeholder={"All"}
            closeMenuOnSelect={true}

            value={this.state.selectMonth.label}
            onChange={this.monthfilter.bind(this)}
            style={{ borderRadius: "0px" }}
            options={this.state.monthList} required
          />
        </div>
        <div className='col-xl-12 col-lg-12'>

          <HighchartsReact
            containerProps={{ style: { width: '100%', height: '100%' } }}
            highcharts={Highcharts}
            style={dStyles2}
            options={highchartoptions2}
            onClick={this.handleDrilldown1.bind(this)}
          />
        </div>
        <div className="col-xl-12 col-lg-12" style={{marginTop:'20px'}}>
          <HighchartsReact
            highcharts={Highcharts}
            options={historicalOptions}
            containerProps={{ style: { width: '100%', height: '100%' } }}
            onClick={this.handleDrilldown.bind(this)}
          />
        </div>
      </div>
    );
  }
  
  
}

function DynamicSortOfMonths() {
  const currentDate = new Date();
  const fiscalYearStartMonth = 3;
  let currentFiscalYear;
  if (currentDate.getMonth() < fiscalYearStartMonth) {
    currentFiscalYear = currentDate.getFullYear() - 1;
  } else {
    currentFiscalYear = currentDate.getFullYear();
  }

  const fiscalYearMonths = [];
  const monthNames = [
    "Apr", "May", "Jun", "Jul", "Aug", "Sep",
    "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"
  ];

  // Dynamically generate the order array based on the current fiscal year
  const order = [];
  for (let i = fiscalYearStartMonth; i < fiscalYearStartMonth + 12; i++) {
    const monthName = monthNames[i % 12];
    const year = (monthName === "Jan" || monthName === "Feb" || monthName === "Mar") ? currentFiscalYear + 1 : currentFiscalYear;
    order.push(`${monthName}-${String(year).substring(2)}`);
  }

  for (let month = fiscalYearStartMonth; month < fiscalYearStartMonth + 12; month++) {
    const monthName = monthNames[month % 12];
    const year = (monthName === "Jan" || monthName === "Feb" || monthName === "Mar") ? currentFiscalYear + 1 : currentFiscalYear;
    fiscalYearMonths.push(`${monthName}-${year}`);
  }

  // Sort fiscal year months based on the dynamically generated order
  fiscalYearMonths.sort((a, b) => {
    const [monthA, yearA] = a.split('-');
    const [monthB, yearB] = b.split('-');
    // Compare years first
    if (yearA !== yearB) {
      return parseInt(yearA) - parseInt(yearB);
    }
    // If years are the same, compare months
    return monthNames.indexOf(monthA) - monthNames.indexOf(monthB);
  });

  fiscalYearMonths.forEach((month, index) => {
    const [monthName, year] = month.split('-');
    const shortYear = year.substring(2);
    console.log(index, 'index', monthName, shortYear)
    fiscalYearMonths[index] = `${monthName}-${shortYear}`;
  });
  console.log(fiscalYearMonths, 'fiscalYearMonths')
  return fiscalYearMonths;
}


function DynamicListOfMonths() {
  const currentDate = new Date();
  const fiscalYearStartMonth = 3;
  let currentFiscalYear;
  if (currentDate.getMonth() < fiscalYearStartMonth) {
    currentFiscalYear = currentDate.getFullYear() - 1;
  } else {
    currentFiscalYear = currentDate.getFullYear();
  }

  const fiscalYearMonths = [];
  const monthNames = [
    "Apr", "May", "Jun", "Jul", "Aug", "Sep",
    "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"
  ];

  // Dynamically generate the order array based on the current fiscal year
  const order = [];
  for (let i = fiscalYearStartMonth; i < fiscalYearStartMonth + 12; i++) {
    const monthName = monthNames[i % 12];
    const year = (monthName === "Jan" || monthName === "Feb" || monthName === "Mar") ? currentFiscalYear + 1 : currentFiscalYear;
    order.push(`${monthName}-${String(year).substring(2)}`);
  }

  for (let month = fiscalYearStartMonth; month < fiscalYearStartMonth + 12; month++) {
    const monthName = monthNames[month % 12];
    const year = (monthName === "Jan" || monthName === "Feb" || monthName === "Mar") ? currentFiscalYear + 1 : currentFiscalYear;
    fiscalYearMonths.push(`${monthName}-${year}`);
  }

  // Sort fiscal year months based on the dynamically generated order
  fiscalYearMonths.sort((a, b) => {
    const [monthA, yearA] = a.split('-');
    const [monthB, yearB] = b.split('-');
    // Compare years first
    if (yearA !== yearB) {
      return parseInt(yearA) - parseInt(yearB);
    }
    // If years are the same, compare months
    return monthNames.indexOf(monthA) - monthNames.indexOf(monthB);
  });

  var monthsName = [];
  fiscalYearMonths.forEach((month, index) => {
    const [monthName, year] = month.split('-');
    const shortYear = year.substring(2);
    console.log(index, 'index', monthName, shortYear)
    monthsName.push({ label: `${monthName}-${shortYear}`, value: `${monthName}-${shortYear}` })
  });
  monthsName.unshift({ label: "All", value: "All" })
  console.log(monthsName, 'monthsName')

  return monthsName;
}
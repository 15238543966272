import React from 'react';

const Exportcontainerdashboard = (props) => {
  var {value}= props
  const dashboardStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    padding: "10px 25px 25px",
  };

  const boldTextStyle = {
    fontWeight: 'bold',
  };

  return (
    <div class="card mb-20p ml-15p mr-15p " style={dashboardStyle}>
      <div style={boldTextStyle}>{value}</div>
    </div>
  );
};

export default Exportcontainerdashboard;
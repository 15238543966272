import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";

import axios from 'axios';

import Grid from '../layouts/gridnosidebarComponent';
import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react';
import Modal from 'react-responsive-modal';
 
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
 


export default class ManageSNDTransitTimes extends Component {

	constructor(props){
		super(props);
		this.state={
			pageTitle: "Manage Transit Times",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			rowData:null,
			uploadDivWidth:'0%',
			sliderTranslate:'',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			usermanualmodal:false
			
		}
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
	componentDidMount(){
		this.logPageView(); // Log page view to GA
		 /*Consignments List*/	
	    redirectURL.post('/consignments/sndtransittimes')    
		  .then((response) => {
            var records = JSON.parse(JSON.stringify(response)).data;
            //console.log("records", records);
            this.setState({
                rowData:records
            });
		  })
		  .catch(function (error) {
		    console.log(error);
            });
	};
	onShowUploadDiv = () => {
		console.log("Click Upload Button")
		this.setState({
			uploadDivWidth:'30%',
			sliderTranslate:"slider-translate",
			showDiv:'show-m',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };
    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
		
		const {usermanualmodal} = this.state;
		const columnwithDefs = [
            {
                headerName: "Consigner Code",
                field: "consigner_code",
                width: 150,
                  editable:false,
                  filter: "agSetColumnFilter"
            },
            {
                headerName: "Consigner Type",
                field: "consigner_type",
                width: 150,
                  editable:false,
                  filter: "agSetColumnFilter"
            },
            {
                headerName: "Truck Transit Time",
                field: "truck_transit_time",
                width: 150,
                editable:false,
               
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Trailer Transit Time",
                field: "trailer_transit_time",
                width: 150,
                editable:false,
                
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Distance (Kms)",
                field: "distance_in_km",
                width: 150,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "City",
                field: "for_city",
                width: 150,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Code",
                field: "for_code",
                width: 150,
                editable:false,
                filter: "agSetColumnFilter"
            },
            
	  	        
	    ];
		return(
			<div className="container-fluid">
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        > 
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pageTitle}</span>
								</h5>
				   			</div>				   			
		            		<div className="card-body">
				   				   <Grid 
								   ashow={this.state.show} 
								   atype={this.state.basicType} 
								   atitle={this.state.basicTitle}
								   actionCall={"manage"}
									actionMethod={"updateTruckRow"}  
									table={"trucklocation"} 
									columnyaxis={this.state.rowData} 
									columnaxis={columnwithDefs} 

									/>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				 <div className={"slide-r "+(this.state.sliderTranslate)} style={{overflow:"auto"}}>
				 	
					 <div className="slide-r-title">
				 		<h4>
                            Sidebars
                        </h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
						
				 		<div className="col-xl-12 col-lg-12">
				 		<div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg "+(this.state.alerterrshow)+" "+(this.state.alerterrmg)}>{this.state.csverrmessage}</div>
						 <Timeline lineColor={'#ddd'}>
							<TimelineItem
								key="001"
								dateText="11/2010 – Present"
								dateInnerStyle={{ background: '#76bb7f', color: '#fff' }}
								bodyContainerStyle={{
								background: '#ddd',
								padding: '20px',
								borderRadius: '8px',
								boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
								}}
							>
								<p>
								123Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
								exercitation.</p>
							</TimelineItem>
							<TimelineItem
								key="002"
								dateText="04/2009 – 11/2010"
								dateInnerStyle={{ background: '#76bb7f', color: '#fff' }}
								bodyContainerStyle={{
								background: '#ddd',
								padding: '20px',
								borderRadius: '8px',
								boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
								}}
							>
								<p>
								456Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
								exercitation.</p>
							</TimelineItem>
							<TimelineItem
								key="003"
								dateComponent={(
								<div
									style={{
									display: 'block',
									float: 'left',
									padding: '10px',
									background: 'rgb(150, 150, 150)',
									color: '#fff',
									}}
								>
									11/2008 – 04/2009
								</div>
								)}
							>
								<p>
								789Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
								exercitation.</p>
							</TimelineItem>
							<TimelineItem
								key="004"
								dateText="08/2008 – 11/2008"
								dateInnerStyle={{ background: '#76bb7f' }}
							>
								<p>
								012Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
								exercitation.</p>
							</TimelineItem>
							
							</Timeline>
							<div className="row col-xl-12 col-lg-12 mb-30p">
								<button type="button" onClick={this.onCloseUploadDiv.bind(this)} className="btn btn-default cs-btn"> Cancel </button>
							</div>
				 		</div>
				 	</div>
				 </div>
				<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
				<Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
					<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<h5 className="model-title">Transit Times</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							<div className="col-xl-12 col-lg-12">
								This screen consists of transit time master from various origin points to all possible destination locations.
							</div>
							
							<div className="col-xl-12 col-lg-12">								
								<table className="table table-border">
									<tr>
										<th>Column</th>
										<th>
											Remarks
										</th>
									</tr>
									<tr>
										<td>
										Consigner Code
										</td>
										<td>
										Destination code as per MSIL
										</td>
									</tr>
									<tr>
										<td>
										Consigner Type
										</td>
										<td>
										Type of Origin point
										</td>
									</tr>
									
									<tr>
										<td>
										Truck Transit Time
										</td>
										<td>
										Transit time if Truck mode is used
										</td>
									</tr>
									
									<tr>
										<td>
										Trailer Transit Time
										</td>
										<td>
										Transit time if Trailer mode is used
										</td>
									</tr>
									
									<tr>
										<td>
										Distance (Kms)
										</td>
										<td>
										Distance between origin and destination
										</td>
									</tr>
									
									<tr>
										<td>
										City
										</td>
										<td>
										Destination City
										</td>
									</tr>
									
									<tr>
										<td>
										Code
										</td>
										<td>
										MSIL code for destination
										</td>
									</tr>
									
								</table>
							</div>
							
							<div className="col-xl-12 col-lg-12">
								This option is not applicable for TNP department
							</div>
						</div>
					</div>
				</Modal>
            </div>
              	
		);
	}
}
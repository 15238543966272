import React, { Component } from "react";
import redirectURL from '../redirectURL';

export default class NewsFeedComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            news: []
        };
    }

    componentDidMount() {
        this.fetchNews();
    }

    fetchNews = async () => {
        redirectURL.post('/dashboard/getAllNews').then((response) => {
            // console.log("News: ", response.data)
            this.setState({
                news: response.data
            })
        });
    };

    render() {

        return (
            <div className="container-fluid" style={{ backgroundColor: "#FFFFFF", padding: "20px" }}>
                <div className="row">
                    <h5 className="col-sm-12 mb-15p mt-15p fbold" style={{ fontSize: "28px" }}>
                        News Feed
                    </h5>
                </div>

                <div className="col-xl-12 col-lg-12" style={{ padding: "40px 20px", paddingTop: "20px" }}>
                    <ul>
                        {
                            this.state.news.map((item, index) => {
                                return <li>
                                    <div className="news_link_container" key={index} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <a href={item.news_link} target="_blank" rel="noopener noreferrer">{item.news_title}</a>
                                        <p>{new Date(item.published_on).toLocaleString()}</p>
                                    </div>
                                </li>
                            })
                        }
                    </ul>
                </div>
            </div>
        );
    }
}
'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
// const $ = window.$;
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class StageWiseTransitTimeDashboard extends Component {
  constructor(props) {
    super(props);
      this.state = {
        
        //components : {datepicker:getDatePicker()}
    }
  }

componentDidMount(){
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    
}
 
  render() {
    return (
      <div class="container-fluid">
        <div className="card col-xl-12">
            <div className="card-header">
                <h5>
                    <i className="icofont icofont-map-pins cus-i"></i> <span>Stage Wise Rail Transit Time Dashboard</span>
                </h5>
            </div>
            <div className="card-body col-xl-12 col-lg-12">
                <iframe 
                    style={{overflow:"hidden",border:"0px", margin:"0px", width:"100%", height:"100vh"}} 
                    width="100%" 
                    frameborder="0"
                    height="100vh"
                    allowFullScreen="true" 
                    src={"https://app.powerbi.com/view?r=eyJrIjoiYTAwYTE3NWItYzAzYy00ODg1LWFmOGQtOTI2OTk1NzY5N2JmIiwidCI6IjJiNzlhMzk1LWUwNDEtNDcyMy04YzBkLWJlMTA1YTY1NmI1YiJ9"}>
                    
                </iframe>
                
            </div>
        </div>
      </div>

    );
  }
}
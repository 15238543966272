import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from "lodash";
import Select from 'react-select';

import axios from 'axios';
import Multiselect from 'multiselect-dropdown-react';
import Breadcrumb from '../common/breadcrumb.component';


export default class ChangeGeoFence extends Component {

    constructor(props) {
        super(props);

        this.state = {
        
    	      
        };
    }
    componentDidMount(){
    	var formdata = {
    			'isactive' : this.props.match.params.status,
    			'rowid' : this.props.match.params.rowid
    	}
    	console.log(formdata);
    	axios.post('http://autometrics.in/geofence/changeStatus', formdata,{
			headers:{
				'Content-Type': 'application/json'
			}
		}).then((response) => {
    		//console.log(response.data);
    		this.setState({
    			departments:response.data
    		});
    	})
    	.catch(function(error){
    		console.log(error)
    	});
    	
    	this.showMap();
    }
   
  
    render(){
   
        return (
            <div className="container-fluid">
            	
            </div>
                
        );
    }
}

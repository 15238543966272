import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../node_modules/react-resizable/css/styles.css';
import { WidthProvider, Responsive } from "react-grid-layout";
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import _ from "lodash";
import $ from "jquery";
var redirectURL = require('../../redirectURL');



export default class Overspeed extends Component{

	constructor(props)
	{
		super(props);
		this.state={
            short_haul_plant:"",
            long_haul_plant:"",
            short_haul_radius : "",
            long_haul_radius:"",
            dept_code:"",
            trip_auto_closure_radius_km:"",
            userid:localStorage.getItem('userid'),
            email:localStorage.getItem('email'),
        };
        
		
    }
    componentDidMount(){
        var dept_code = this.props.deptCode;
        // var dept_code = "SNDG";
        var formdata = {
            config_type:'dealerradius',
            dept_code : dept_code
        };
        
        redirectURL.post('/manage/kpiConfigurations',formdata, {
            headers:{
                'Content-type':'application/json'
            }
        })
        .then((response) => {
            console.log(response.data);
            if(response.data.length > 0)
            {
                this.setState({
                    short_haul_plant : response.data[0].short_haul_dealer_dist_km,
                    long_haul_plant:response.data[0].long_haul_plant,
                    short_haul_radius : response.data[0].short_haul_dealer_radius_km,
                    long_haul_radius:response.data[0].long_haul_dealer_radius_km,
                    trip_auto_closure_radius_km : response.data[0].trip_auto_closure_radius_km,
                    dept_code : dept_code
                });
            }
            else
            {
                this.setState({
                    short_haul_plant : "",
                    long_haul_plant:"",
                    short_haul_radius : "",
                    long_haul_radius:"",
                    dept_code : dept_code
                });
            }
            console.log(typeof(this.state.duration),this.state.distance,this.state.nightRules);

        })
    }
   
	formHandler = (event) => {
        event.preventDefault(); 
        var shortplantID = document.getElementById("shortplantID").value;
        // var longplantID = document.getElementById("longplantID").value;
        var shortdealerID = document.getElementById("shortdealerID").value;
        var longdealerID = document.getElementById("longdealerID").value;
        var formdata = {
            userid:this.state.userid,
            user_email:localStorage.getItem('email'),
            dept_code:this.state.dept_code,
            config_type:"dealerradius",
            short_haul_plant : shortplantID,
            // long_haul_plant:longplantID,
            short_haul_radius : shortdealerID,
            long_haul_radius: longdealerID,
            trip_auto_closure_radius_km : Number(this.state.trip_auto_closure_radius_km)
        }
        console.log(formdata)
        redirectURL.post('/manage/saveDRKPIConfigurations',formdata, {
            headers:{
                'Content-type':'application/json'
            }
        })
        .then((response) => {
            var res = response.data;
            if(res.result.result.ok == 1)
            {
                this.props.context.showAlert();
            }
            else{
                this.props.context.showErrorAlert();
            }
        })
    }
    shortHaulFunc = (event) =>{
        this.setState({
            // distance : event.target.value,
            short_haul_plant : event.target.value
        })
    }
    longHaulFunc = (event) =>{
        this.setState({
            // distance : event.target.value,
            long_haul_plant : event.target.value
        })
    }
    shortdealerFunc = (event) =>{
        this.setState({
            // distance : event.target.value,
            short_haul_radius : event.target.value
        })
    }
    autoclosure = (event) =>{
        this.setState({
            trip_auto_closure_radius_km : event.target.value
        })
    }
    longdealerFunc = (event) =>{
        this.setState({
            // distance : event.target.value,
            long_haul_radius : event.target.value
        })
    }
    
    changeDept(params){
        this.setState({
            dept_code:params.value
        })
    }

    selectDept(){
        let arr=[];
        try{
            arr.push({value:"SNDG",label:"Sales and Dispatch"});
            arr.push({value:"LOG-PRT",label:"Spare Parts"});
            arr.push({value:"LOG-TNP",label:"Train and Production"});
            return arr;
        }
        catch(e){

        } 
    }
	 render() {
         let nightDrivingRules;
        return (
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <h5>Dealer Radius Settings</h5>
                </div>
                <form className=" theme-form col-xl-6 col-lg-6" onSubmit={this.formHandler}>
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            {/* <label>Select Department : </label>
                            <Select 
                                placeholder={"Department"}
                                closeMenuOnSelect={true}
                                // isMulti="true"
                                // onChange={this.getRailConsginments.bind(this)} 
                                className={"border-radius-0"}
                                style={{borderRadius:"0px"}}
                                options={this.selectDept()}
                                onChange={this.changeDept.bind(this)}
                             />     */}
                        </div>        
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            <label className="c-lbl">Short Haul Distance Radius From Plant (KM) * : </label>
                            <input type="number" min="10" value = {this.state.short_haul_plant} id="shortplantID" className="form-control validateNumber" autoComplete="off" onChange={this.shortHaulFunc.bind(this)} required />
                        </div>
                    </div>
                    {/* {(this.props.deptCode == "LOG-TNP")?
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            <label className="c-lbl">Long Haul Distance Radius From Plant (KM) * : </label>
                            <input type="number" min="10" value = {this.state.long_haul_plant} id="longplantID" className="form-control validateNumber" autoComplete="off" onChange={this.longHaulFunc.bind(this)} required />
                        </div>
                    </div>:""} */}
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            <label className="c-lbl">Short Haul Dealer Proximity Radius (KM) * : </label>
                            <input type="number" min="1" value = {this.state.short_haul_radius} id="shortdealerID" className="form-control validateNumber" autoComplete="off" onChange={this.shortdealerFunc.bind(this)} required />
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            <label className="c-lbl">Long Haul Dealer Proximity Radius (KM) * : </label>
                            <input type="number" min="1" value = {this.state.long_haul_radius} id="longdealerID" className="form-control validateNumber" autoComplete="off" onChange={this.longdealerFunc.bind(this)} required />
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            <label className="c-lbl">Trip Auto Closure Radius (KM)* : </label>
                            <input type="number" min="5" value = {this.state.trip_auto_closure_radius_km} id="autoclosure" className="form-control validateNumber" autoComplete="off" onChange={this.autoclosure.bind(this)} required />
                        </div>
                    </div>
                    
                    <div className="form-group">
                        <button type="submit" className="btn btn-success cs-btn" id="save">Save</button>
                    </div>
                </form>
            </div>
        );
    }
}
$(document).ready(function(){
    $(document).on('keyup',".validateNumber",function(){
        // alert("click");
        if(isNaN($(this).val()) == true){
            $(this).val($(this).val().slice(0,-1));
        }
    });
    $(document).on("keyup",".validateEmail",function(e){
        if(e.which === 32){
            $(this).val($(this).val()+", ");
        }
    });
    $(document).on("keyup","#shortplantID",function(){
        if($(this).val() == 0){
            // alert("Value Should be Greater than 0");
            // $(".error-msg").empty();
            // $(".error-msg").append("Value Should be greater than 0");
            $(this).val(" ");
        }
    });
    $(document).on("keyup","#shortdealerID",function(){
        if($(this).val() == 0){
            // alert("Value Should be Greater than 0");
            // $(".error-msg").empty();
            // $(".error-msg").append("Value Should be greater than 0");
            $(this).val(" ");
        }
    })
    $(document).on("keyup","#longdealerID",function(){
        if($(this).val() == 0){
            // alert("Value Should be Greater than 0");
            // $(".error-msg").empty();
            // $(".error-msg").append("Value Should be greater than 0");
            $(this).val(" ");
        }
    })
    $(document).on("keyup","#longplantID",function(){
        if($(this).val() == 0){
            // alert("Value Should be Greater than 0");
            // $(".error-msg").empty();
            // $(".error-msg").append("Value Should be greater than 0");
            $(this).val(" ");
        }
    })
});


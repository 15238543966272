import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import redirectURL from '../redirectURL';


export default class ManageChaformat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            	modules: AllModules,
      	        defaultColDef: {
      	            sortable: true,
				    filter: true,
                    resizable: true,
                    editable:true
                },
      	        rowData: null,
      	      
                rowModelhighchartoptionsType: 'enterprise',
                rowGroupPanelShow: 'always',
                animateRows: true,
                debug: true,
                showToolPanel: false,
				suppressAggFuncInHeader: true,
				
                rowModelType: "serverSide",
                paginationPageSize:50,
                cacheBlockSize: 10,
                maxBlocksInCache: 1,
                statusBar: {
                    statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                    ]
                },
                sideBar: {
                    toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                    ]
                },
                columnDefs: [
                    {
                      headerName: "Vessel arrival date",
                      field: "vessel_arrival_date"
                    },
                    {
                      headerName: "Vessel Name ",
                      field: "vessel_name",
                      cellStyle: function(params) {
                        return { "background-color": "#defff4" };
                      }
                    },
                    {
                      headerName: "Bl NO.",
                      field: "bl_no"
                    },
                    {
                      headerName: "Bl date",
                      field: "bl_date",
                      cellStyle: function(params) {
                        return { "background-color": "#defff4" };
                      }
                    },
                    {
                      headerName: "Coil Size",
                      field: "coil_size"
                    },
                    {
                      headerName: "Item Code",
                      field: "item_code",
                      cellStyle: function(params) {
                        return { "background-color": "#defff4" };
                      }
                    },
                    {
                      headerName: "Net Weight",
                      field: "net_weight"
                    },
                    {
                      headerName: "Gross Weight",
                      field: "gross_weight",
                      cellStyle: function(params) {
                        return { "background-color": "#defff4" };
                      }
                    },
                    {
                      headerName: "Voyage No.",
                      field: "voyage_no"
                    },
                    {
                      headerName: "BOE Date",
                      field: "boe_date",
                      cellStyle: function(params) {
                        return { "background-color": "#defff4" };
                      }
                    },
                    {
                      headerName: "Boe NO.",
                      field: "boe_no"
                    },
                    {
                      headerName: "BOE Amount",
                      field: "boe_amount",
                      cellStyle: function(params) {
                        return { "background-color": "#defff4" };
                      }
                    },
                    {
                      headerName: "Sims regestration details (SIMS)",
                      field: "clothessims_restration"
                    },
                    {
                      headerName: "COC Date",
                      field: "coc_date",
                      cellStyle: function(params) {
                        return { "background-color": "#defff4" };
                      }
                    },
                    {
                      headerName: "Original invoice copy",
                      field: "original_invoice_copy"
                    },
                    {
                      headerName: "Packing List",
                      field: "packing_list",
                      cellStyle: function(params) {
                        return { "background-color": "#defff4" };
                      }
                    },
                    {
                      headerName: "Bl Copy ",
                      field: "bl_copy"
                    },
                    {
                      headerName: "CEPA/ FTA ",
                      field: "cepa_fta",
                      cellStyle: function(params) {
                        return { "background-color": "#defff4" };
                      }
                    },
                    {
                      headerName: "FREIGHT BILL",
                      field: "freight_bill"
                    },
                    {
                      headerName: "Mill MTC",
                      field: "mill_mtc",
                      cellStyle: function(params) {
                        return { "background-color": "#defff4" };
                      }
                    },
                    {
                      headerName: "BIS Certificate",
                      field: "bis_certificate"
                    },
                    {
                      headerName: "Action",
                      field: "_id"
                    }
                  ],
                rowData: [
                    {
                      	vessel_arrival_date: "",
                 	      vessel_name: "",
                        bl_no:"",
                        bl_date:"",
                        coil_size:"",
                        item_code:"",
                        net_weight:"",
                        gross_weight:"",
                        voyage_no:"",
                        boe_date:"",
                        boe_no:"",
                        boe_amount:"",
                        clothessims_restration:"",
                        coc_date:"",
                        original_invoice_copy:"",
                        packing_list:"",
                        bl_copy:"",
                        cepa_fta:"",
                        freight_bill:"",
                        mill_mtc:"",
                        bis_certificate:""
                    }
                  ],
                
            };
    }
	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;
        //this.gridApi.tabToNextCell();
        
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
    
       this.gridApi.startEditingCell({
            rowIndex: 0,
            colKey: "vessel_arrival_date",
           // rowPinned: pinned,
            //keyPress: key,
            //charPress: char
        });
	};
	
	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
           
		   
	 }
	 onCloseUploadDiv(){
		this.props.context.onCloseUploadDiv();
	 }
     onAddRow() {
        var newItem = createNewRowData();
        console.log("newItem",newItem);
        var res = this.gridApi.updateRowData({ add: [newItem] });
        console.log("res",res.add[0].id);
        this.gridApi.startEditingCell({
            rowIndex: res.add[0].id,
            colKey: "vessel_arrival_date",
           // rowPinned: pinned,
            //keyPress: key,
            //charPress: char
        });
      }
	
    render(){
        return ( 
    		 <div className="col-xl-12 col-lg-12 beffect n-p-0">
                 <div className="card height-equal equal-height-lg">
                    <div className="card-header">
                        <h5>
                            <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Manage ChasFormats </span>
                                <button type="button" onClick={this.onAddRow.bind(this)} className="btn btn-success pull-right cs-btn"> <i className="icofont icofont-plus"></i> Add New Item</button>
                        </h5>
                    </div>	
                     <div className="card-body" style={{padding:'10px'}}>
                       <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={this.state.columnDefs}
		          		            defaultColDef={this.state.defaultColDef}
		          		            rowData={this.state.rowData}
		          		            enableCharts={false}
		          		            enableRangeSelection={false}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
		          	                sideBar={this.state.sideBar}
		          	                paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									enableRangeSelection={false}
									floatingFilter={true}
									        
		          		          />
                            </div>
                     </div>
                     
                 </div>
                
             </div>
        );
    }
}

var newCount = 1;
function createNewRowData() {
  var newData = {
    vessel_arrival_date: "",
    vessel_name: "",
    bl_no:"",
    bl_date:"",
    coil_size:"",
    item_code:"",
    net_weight:"",
    gross_weight:"",
    voyage_no:"",
    boe_date:"",
    boe_no:"",
    boe_amount:"",
    clothessims_restration:"",
    coc_date:"",
    original_invoice_copy:"",
    packing_list:"",
    bl_copy:"",
    cepa_fta:"",
    freight_bill:"",
    mill_mtc:"",
    bis_certificate:""
  };
  newCount++;
  return newData;
}













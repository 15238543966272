
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
 
const ImportsCoilsArrivedAtIndianPortAction = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onShowConsignmentExportsEventsClick(props);
    };
    
    // if(props.data.rail_transit_delay == 5)
    // {
    //     var colr = 'redfont'
    // }
    // else{
       if(props.data.status == 2)
       {
            var colr = 'greenfont'
       }
       else{
            var colr = 'grayfont'
       }
   // }
    return (
        <div>
            <button onClick={handleEventClick} className="custom-btn f22 label label-success" 
            title="Arrived at Indian Port" ><i className={"icofont icofont-ship-alt "+(colr)}></i> &nbsp;</button>
            
        </div>
    );
};

export default ImportsCoilsArrivedAtIndianPortAction;

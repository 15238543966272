import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../node_modules/react-resizable/css/styles.css';
import { WidthProvider, Responsive } from "react-grid-layout";
import axios from 'axios';
import _ from "lodash";
var redirectURL = require('../../redirectURL');

export default class LocalMovement extends Component{

	constructor(props)
	{
		super(props);
		this.state={
            speed:'',
            time:''
		};
		
    }
    changeInputHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
	formHandler = (event) => {
        console.log(event);
        event.preventDefault();
    }
	 render() {
        return (
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <h5>Local Movement</h5>
                </div>
                <form className=" theme-form col-xl-6 col-lg-6" onSubmit={this.formHandler}>
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            <label className="c-lbl">Speed Limit *:</label>
                            <input type="text" name="speed" value={this.state.speed} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Speed Limit" />
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            <label className="c-lbl">Speed Limit *:</label>
                            <input type="text" name="speed" value={this.state.speed} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Speed Limit" />
                        </div>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-success cs-btn" id="save">Save</button>
                    </div>
                </form>
            </div>
        );
    }
}


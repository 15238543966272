
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';


import Counters from '../layouts/consignmentkpicountersComponent';

import CountUp from 'react-countup';

import { AgGridReact } from "@ag-grid-community/react";

import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";

import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from "react-select";

import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';

import Highcharts from 'highcharts';

var redirectURL = require("../redirectURL");


export default class MTDTargetvsAchievement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterType: 'All',
      monthFilterSelected: 'All',
      totalSumedUpValues: [],
      monthSelected: [],
      loadshow: "show-m",
      overly: "show-m",
      totalSumedUpValuesFromMonth: [],
      mtd_total_target: 0,
      mtd_total_achievement: 0,
      mtd_target_percentage : 0,
      totalTarget:0,
      totalAchievement:0,
      totalAchievementPercentage:0,
      facility: "All Facilities",
      facilitiesList: [{ "label": "All Facilities", "value": "ALL" },
      { "label": "Haryana", "value": "H" },
      { "label": "Bidadi", "value": "BP" }
      ,{ "label": "Gujarat", "value": "G" },
      { "label": "TVP-B", "value": "B" },
      { "label": "TVP-N", "value": "N" },
      { "label": "TVP-S", "value": "I" }
    ],
    
    }

  }

  async componentDidMount() {
    this.setState({
      loadshow: "show-m",
      overly: "show-m"
    })
    var transporterCode = window.atob(this.props.match.params.transporterCode)
    console.log(transporterCode, 'transporterCode');
    this.setState({
      transporter_code: transporterCode
    });

    var params = {
      transporter_code: transporterCode
    };

    this.getDataForFirstChart(params)
  }

  getDataForFirstChart = async (params) => {
    await redirectURL
      .post("/dashboard/MTDTargetvsAchievement", params)
      .then((response) => {
        // console.log(response.data.data,'data on mount');
        var totalData = response.data.data
        this.setState({
          mtd_total_target: totalData.mtd_total_target,
          mtd_total_achievement: totalData.mtd_total_achievement,
          mtd_target_percentage : totalData.mtd_target_percentage,
          totalSumedUpValues: response.data.data.cluster_graph_data,
          loadshow: 'show-n',
          overly: 'show-n',
        });
      })
      .catch((error) => {
        console.error(error);
      });

  }


  async newFilter(event) {
    this.setState({
      filterType: event.value,
      overly: "show-m",
      loadshow: "show-m"
    })
    var params = {
      transporter_code: this.state.transporter_code,
      plant_code: event.value,
    }
   await redirectURL
      .post("/dashboard/MTDTargetvsAchievementOnFilter", params)
      .then((response) => {
      console.log(response.data,'total data on filter')
        var totalData = response.data.data
        this.setState({
          mtd_total_target: totalData.mtd_total_target,
          mtd_total_achievement: totalData.mtd_total_achievement,
          mtd_target_percentage : totalData.mtd_target_percentage,
          totalSumedUpValues: totalData.cluster_graph_data,
          overly: "show-n",
          loadshow: "show-n"
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

 

  onClickCounter(data) {
    //this.props.context.onClickCounterShowData(data)

  }
  handleDrilldown = (params) => {
    console.log("Here ,", params)
  }
  handleDrilldown1 = (params) => {
    console.log("Here ,", params)
  }
  onClickHideAll() {
    this.setState({
      loadshow: 'show-n',
      overly: 'show-n',
      bulkslide: "",
      bulkslidebulk: '',
    });

  }

  render() {
    const cellStyle = {
      padding: '8px',
      textAlign: 'center',
      border: '1px solid #ddd',
      fontSize: '14px',
    };
    const currentFY = "FY 24-25";
    const previousFY = "FY 23-24";
    const dStyles2 = {
      width: '100%',
      height: '100%'
    };
  var clusterData = this.state.totalSumedUpValues ? this.state.totalSumedUpValues : []
//     const totalSumedUpValues = this.state.totalSumedUpValues.filter(item => {
//       return item.target !== 0 || item.achievement !== 0;
//   });
//     const monthfilterPercentageData = this.state.totalSumedUpValuesFromMonth.map(ele => {
//       if (ele.target && ele.achievement) {
//         const totalTarget = ele.target.reduce((acc, value) => acc + value, 0);
//         const totalAchievement = ele.achievement.reduce((acc, value) => acc + value, 0);
//         const targetAverage = Math.round(totalTarget);
//         const achievementAverage = Math.round(totalAchievement);
//         const achievementPercentageincluster = (achievementAverage / totalTarget) * 100;
//         return { targetAverage, achievementAverage, achievementPercentageincluster };
//       } else {
//         return { targetAverage: 0, achievementAverage: 0, achievementPercentageincluster: 0 };
//       }
//     });
//     const clusterCategories = this.state.totalSumedUpValuesFromMonth.map((ele) => ele.cluster);
//     const targetDatamonth = monthfilterPercentageData.map(data => data.targetAverage);
//     const achievementDatamonth = monthfilterPercentageData.map(data => data.achievementAverage);
//     const achievementPercentageinclusterend = monthfilterPercentageData.map(data => data.achievementPercentageincluster);

 
  
var highchartoptions2 = {
    chart: {
      type: 'column'
    },
    title: {
      text: 'MTD Target vs Achievement: Cluster Wise (Cars)'
    },
    xAxis: {
      categories: clusterData.cluster || [],
      crosshair: true
    },
    yAxis: {
      title: {
        text: 'Target vs Achievement'
      }
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      formatter: function () {
        let tooltip = '<table>';
        this.points.forEach(point => {
          tooltip += `<tr><td style="color:${point.color};padding:0">${point.series.name}: </td>` +
            `<td style="padding:0"><b>${point.y.toLocaleString('en-IN')}</b></td></tr>`;
          
          // For 'Achievement' series, add the corresponding achievement percentage
          if (point.series.name === 'Achievement') {
            const achievementPercentage = clusterData.achievement_percentage[point.point.index];  // Use point.index to get the correct percentage
            tooltip += `<tr><td style="color:#FF7F7F;padding:0">Achievement Percentage: </td>` +
              `<td style="padding:0"><b>${achievementPercentage}%</b></td></tr>`;
          }
        });
        return tooltip + '</table>';
      },
      shared: true,
      useHTML: true
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        dataLabels: {
          enabled: false // Disable data labels on bars
        }
      }
    },
    series: [{
        name: 'Target',
        data: clusterData.total_target || [],
        color: '#396BC5',
        showInLegend: true
      }, {
        name: 'Achievement',
        // Check if total_achievement and total_target are defined and have data
        data: (clusterData.total_achievement && clusterData.total_target) ? clusterData.total_achievement.map((achievement, index) => {
          const color = achievement > clusterData.total_target[index] ? '#92d864' : '#FF7F7F';
          return { y: achievement, color: color };
        }) : [],
        showInLegend: true,
        color: '#92d864'
      }]
  };
  
    return (
      <div className='row'>
        <div className={"dataLoadpage " + (this.state.loadshow)}></div>
        <div className={"dataLoadpageimg " + (this.state.loadshow)} style={{ position: "fixed", top: "43%" }}>
          <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
        <div style={{ textAlign: "center", background: "#171C8F", width: '100%', margin: "5px", borderRadius: "5px", height: '70px', paddingBottom: '25px' }}>
          <h6 class="txt-dark f40" style={{ marginTop: "0px" }}>
            <span style={{ fontSize: "20px", color: "#FFFFFF" }}>
              <span>MTD Target vs Achievement</span>
            </span>
          </h6>
        </div>
  
        <div className="row card-body">
          <div className="col-xl-4">
            <label>Select Facilities</label>
            <Select
              placeholder={"All Facilities"}
              closeMenuOnSelect={true}
              value={this.state.facility.label}
              onChange={this.newFilter.bind(this)}
              style={{ borderRadius: "0px" }}
              options={this.state.facilitiesList} required
            />
          </div>
          <div className="col-xl-8 col-lg-8">
            <h5>
              <span className="float-right f12">
                <a className="btn btn-danger" style={{ float: "left", marginLeft: "10%" }} href="/lspceodashboardTest">Back</a>
              </span>
            </h5>
          </div>
          <div className="col-xl-4 col-lg-4" style={{ marginTop: '12px'}}>
        <table style={{ backgroundColor: 'white', width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr style={cellStyle}>
              <th style={cellStyle}>Target</th>
              <th style={cellStyle}>Achievement</th>
              <th style={cellStyle}>Achievement %</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={cellStyle}>
                {this.state.mtd_total_target > 0 ? this.state.mtd_total_target.toFixed(2) : 0}
              </td>
              <td style={cellStyle}>
                {this.state.mtd_total_achievement > 0 ? this.state.mtd_total_achievement.toFixed(2) : 0}
              </td>
              <td style={cellStyle}>
                {this.state.mtd_target_percentage > 0 ? this.state.mtd_target_percentage.toFixed(2) : 0}%
              </td>
            </tr>
          </tbody>
        </table>
      </div>
                      </div>
  
       
        
     
        <div className='col-xl-12 col-lg-12'>

          <HighchartsReact
            containerProps={{ style: { width: '100%', height: '100%' } }}
            highcharts={Highcharts}
            style={dStyles2}
            options={highchartoptions2}
            onClick={this.handleDrilldown1.bind(this)}
          />
        </div>
        </div>
    
    );
  }
  
  
}

function DynamicSortOfMonths() {
  const currentDate = new Date();
  const fiscalYearStartMonth = 3;
  let currentFiscalYear;
  if (currentDate.getMonth() < fiscalYearStartMonth) {
    currentFiscalYear = currentDate.getFullYear() - 1;
  } else {
    currentFiscalYear = currentDate.getFullYear();
  }

  const fiscalYearMonths = [];
  const monthNames = [
    "Apr", "May", "Jun", "Jul", "Aug", "Sep",
    "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"
  ];

  // Dynamically generate the order array based on the current fiscal year
  const order = [];
  for (let i = fiscalYearStartMonth; i < fiscalYearStartMonth + 12; i++) {
    const monthName = monthNames[i % 12];
    const year = (monthName === "Jan" || monthName === "Feb" || monthName === "Mar") ? currentFiscalYear + 1 : currentFiscalYear;
    order.push(`${monthName}-${String(year).substring(2)}`);
  }

  for (let month = fiscalYearStartMonth; month < fiscalYearStartMonth + 12; month++) {
    const monthName = monthNames[month % 12];
    const year = (monthName === "Jan" || monthName === "Feb" || monthName === "Mar") ? currentFiscalYear + 1 : currentFiscalYear;
    fiscalYearMonths.push(`${monthName}-${year}`);
  }

  // Sort fiscal year months based on the dynamically generated order
  fiscalYearMonths.sort((a, b) => {
    const [monthA, yearA] = a.split('-');
    const [monthB, yearB] = b.split('-');
    // Compare years first
    if (yearA !== yearB) {
      return parseInt(yearA) - parseInt(yearB);
    }
    // If years are the same, compare months
    return monthNames.indexOf(monthA) - monthNames.indexOf(monthB);
  });

  fiscalYearMonths.forEach((month, index) => {
    const [monthName, year] = month.split('-');
    const shortYear = year.substring(2);
    console.log(index, 'index', monthName, shortYear)
    fiscalYearMonths[index] = `${monthName}-${shortYear}`;
  });
  console.log(fiscalYearMonths, 'fiscalYearMonths')
  return fiscalYearMonths;
}


function DynamicListOfMonths() {
  const currentDate = new Date();
  const fiscalYearStartMonth = 3;
  let currentFiscalYear;
  if (currentDate.getMonth() < fiscalYearStartMonth) {
    currentFiscalYear = currentDate.getFullYear() - 1;
  } else {
    currentFiscalYear = currentDate.getFullYear();
  }

  const fiscalYearMonths = [];
  const monthNames = [
    "Apr", "May", "Jun", "Jul", "Aug", "Sep",
    "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"
  ];

  // Dynamically generate the order array based on the current fiscal year
  const order = [];
  for (let i = fiscalYearStartMonth; i < fiscalYearStartMonth + 12; i++) {
    const monthName = monthNames[i % 12];
    const year = (monthName === "Jan" || monthName === "Feb" || monthName === "Mar") ? currentFiscalYear + 1 : currentFiscalYear;
    order.push(`${monthName}-${String(year).substring(2)}`);
  }

  for (let month = fiscalYearStartMonth; month < fiscalYearStartMonth + 12; month++) {
    const monthName = monthNames[month % 12];
    const year = (monthName === "Jan" || monthName === "Feb" || monthName === "Mar") ? currentFiscalYear + 1 : currentFiscalYear;
    fiscalYearMonths.push(`${monthName}-${year}`);
  }

  // Sort fiscal year months based on the dynamically generated order
  fiscalYearMonths.sort((a, b) => {
    const [monthA, yearA] = a.split('-');
    const [monthB, yearB] = b.split('-');
    // Compare years first
    if (yearA !== yearB) {
      return parseInt(yearA) - parseInt(yearB);
    }
    // If years are the same, compare months
    return monthNames.indexOf(monthA) - monthNames.indexOf(monthB);
  });

  var monthsName = [];
  fiscalYearMonths.forEach((month, index) => {
    const [monthName, year] = month.split('-');
    const shortYear = year.substring(2);
    console.log(index, 'index', monthName, shortYear)
    monthsName.push({ label: `${monthName}-${shortYear}`, value: `${monthName}-${shortYear}` })
  });
  monthsName.unshift({ label: "All", value: "All" })
  console.log(monthsName, 'monthsName')

  return monthsName;
}
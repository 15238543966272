import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';
var redirectURL = require('../redirectURL');

export default class ExportsConsignmentsCountersComponent extends Component {
    constructor(props){
    	super(props);
    	
    	// this.state={
        //     records:[],
        //     allRowDatacount:0,
    	// 	highseascount:0,
    	// 	inportcount:0,
        //     intransiticdcount:0,
        //     inicdcount:0,
        //     transitmsilcount:0
    	// }
    	this.state={
            records:[],
            allRowDatacount:0,
            totalConsignmentsCount:0,
            
            inTransitToMsilCount:0,
            inTransitToIcdCount:0,
    		insideIcdCount:0,
            inTransitToPortCount:0,            
            insidePortCount:0,
            highSeasCount:0
    	}
    
    }
    // <ExportsConsignmentsCounters 
	// 				context={this}
	// 				allRowDatacount={this.state.allRowData.length}
	// 				inTransitToMsilCount={this.state.inTransitToMsilCount.length}
	// 				insideIcdCount={this.state.insideIcdCount.length}
	// 				inTransitToMsilCount={this.state.inTransitToMsilCount.length}
	// 				inTransitToPortCount={this.state.inTransitToPortCount.length}
	// 				totalConsignmentsCount={this.state.totalConsignmentsCount.length}
	// 			 />
    componentWillReceiveProps(newprops){
       this.setState({
            allRowDatacount:newprops.allRowDatacount,
            totalConsignmentsCount:newprops.totalConsignmentsCount,
            inTransitToMsilCount:newprops.inTransitToMsilCount,
            inTransitToIcdCount:newprops.inTransitToIcdCount,
            insideIcdCount:newprops.insideIcdCount,
            inTransitToPortCount:newprops.inTransitToPortCount,
            insidePortCount:newprops.insidePortCount,
            highSeasCount:newprops.highSeasCount
       })

    }
    onClickCounter(data){
        this.props.context.onClickCounterShowData(data)
    }
    render(){
        return (
            <div className="row">

            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="card">
                    <div className="card-body">
                        <div className="crm-numbers pb-0">
                            <div className="row">
                               
                                <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this,0)}>
                                    <span className="f13"><i className="icofont icofont-vehicle-delivery-van cus-i txt-info f24"></i><br /> All</span>
                                    <h4 className="txt-info f50"><span className="counter"><CountUp end={(this.state.totalConsignmentsCount != '')?this.state.totalConsignmentsCount:0}/></span></h4>
                                </div> 

                                <div className="col cpointer" onClick={this.onClickCounter.bind(this,1)}>
                                    <span className="f13"><i className="icofont icofont-truck-alt f22 txt-primary-dark"></i><br /> ICD to MSIL</span>
                                    <h4 className="txt-primary f50"><span className="counter">
                                        <CountUp end= {this.state.inTransitToMsilCount}/>
                                        </span></h4>
                                </div>
                                <div className="col cpointer" onClick={this.onClickCounter.bind(this,3)}>
                                    <span className="f13"><i className="icofont icofont-van-alt f25 txt-secondary-dark"></i><br /> MSIL to ICD</span>
                                    <h4 className="txt-secondary f50"><span className="counter">
                                        <CountUp end= {this.state.inTransitToIcdCount}/>
                                        </span></h4>
                                </div>

                                <div className="col cpointer" onClick={this.onClickCounter.bind(this,4)}>
                                    <span className="f13"><i className="icofont icofont-ui-home f24 txt-pink-light"></i><br /> Inside ICD </span>
                                    <h4 className="txt-pink-medium f50"><span className="counter"><CountUp end={(this.state.insideIcdCount)?this.state.insideIcdCount:0}/></span></h4>
                                </div>
                                
                                <div className="col cpointer" onClick={this.onClickCounter.bind(this,5)}>
                                    <span className="f13"><i className="icofont icofont-train-line f22 txt-info-medium"></i><br />Instransit to Port</span>
                                    <h4 className="txt-info-dark f50"><span className="counter"><CountUp end={(this.state.inTransitToPortCount)?this.state.inTransitToPortCount:0}/></span></h4>
                                </div>

                                <div className="col cpointer" onClick={this.onClickCounter.bind(this,6)}>
                                    <span className="f13"><i className="icofont icofont-ship f24 txt-success"></i><br /> Inside Port</span>
                                    <h4 className="txt-success f50"><span className="counter"><CountUp end={(this.state.insidePortCount)?this.state.insidePortCount:0}/></span></h4>
                                </div>
                               
                                <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this,7)}>
                                    <span className="f13"><i className="icofont icofont-ship-alt txt-info f24"></i><br /> High Seas</span>
                                    <h4 className="txt-info f50"><span className="counter"><CountUp end={(this.state.highSeasCount != '')?this.state.highSeasCount:0}/></span></h4>
                                </div>

                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
           
            </div>
        );
    }
}








/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import Select from 'react-select';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import {
    getDDMMYYYYHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenDDMMYYYYHHMMSS,
} from '../common/utils';
import redirectURL from '../redirectURL';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';

var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class LogsCostEstimation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            csvcontent: "",
            file: "",
            loadshow: 'show-n',
            overly: 'show-n',
            uploadYard: "",
            uploadDispatchDate: "",
            sliderTranslate: '',
            sliderRouteTranslate: '',
            sliderCommentTranslate: "",
            rowData: [],
            rowSelection: 'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize: 50,
            vehicleList: [],
            geoFenceData: "",
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            dept_code: "",
            pagetitle: "",
            bulkslide: "",
            consignee: "",
            truck_no: "",
            startDate: "",
            endDate: "",
            countersjson: "",
            // components : {datepicker:getDatePicker()},
            frameworkComponents: {
            },
            showDiv: 'show-n',
            packetrows: [{}],
            dimensionsTotal: 0,
            grossWeightTotal: 0,
            logs_cost: "",
            typeOfCargo:'',
            selectionOfCountryOnCBU:false,
            incoTermFOB:false,
            deliveryTermsState:false,
            grossWt:"",
        }      
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
       
    }
    async componentDidMount() {
        loadDateTimeScript()
        this.logPageView();
     
        $("#no_of_packets").val(1);
        this.onLoadPageData()
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        let pagetitle = 'Estimation Of Logs Cost';
        
    }
     onLoadPageData = () => {
       
        this.setState({
            loadshow:"show-m",
            overly:"show-m",
        })
        var reqparams = {
        }
        redirectURL.post("/consignments/getRowDataFord22consignments", reqparams).then((response) => {
            let responseData = response.data
            //console.log("responseData", responseData)
            this.setState({
                rowData: responseData.data,
                //countersjson: pagecounters,
                loadshow:"show-n",
                overly:"show-n",
            })
        })
    }

    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onClickCargo = (e)=>{
e.preventDefault();

console.log(e.target.value,'cargoValue111')
if(e.target.value == "CBU"){
    this.setState({
        selectionOfCountryOnCBU:true,
        incoTermFOB:true,
        deliveryTermsState:true,
        typeOfCargo:e.target.value,
        logs_cost:0,
        grossWeightTotal: 0,
        grossWt:''
    })
}else{
console.log(e.target.value,'cargoValue222')
this.setState({
    selectionOfCountryOnCBU:false,
    incoTermFOB:false,
    deliveryTermsState:false,
    typeOfCargo:e.target.value,
    logs_cost:0,
    grossWeightTotal: 0,
    grossWt:''
})
}
    }
    
    estimateLogsCost = (event) => {
        event.preventDefault();
        // console.log(this.state.typeOfCargo,'typeOfCargo')
        let eventLabel = googleAnalytics.page.action.estimateLogsCost;
        let eventOptions = {
            "category": this.state.pagetitle,
            "action": this.state.eventAction,
            "label": eventLabel,
        }
        // googleAnalytics.logEvent(eventOptions);
        var logs_cost = this.state.logs_cost;
        if(logs_cost !="" && logs_cost !=undefined)
        {
            var dateField = $("#dateField").val();
            var departmentname = $("#departmentname").val();
            var indentername = $("#indentername").val();
            var mobile = $("#mobile").val();
            var indenterEmail = $("#indenterEmail").val();
            var destination_country = $("#country").val();
            var deliveryTerms = $("#deliveryTerms").val();
            var no_of_packets = $("#no_of_packets").val();
            var cargoDeliveryPlant = $("#cargoDeliveryPlant").val();
            var cargoData=[];
            for(var p=0;p<no_of_packets;p++)
            {
                var volumeWeight=$("#volume_weight_"+p).val();
                var grossWt=$("#grossWt_"+p).val();
                var dimensions=$("#dimensions_"+p).val();
                var ccw=$("#cargo_chargeable_weight_"+p).val();
                if(volumeWeight !='' || grossWt !='' || dimensions !='')
                {
                    var temp = {"volume_weight":volumeWeight,"cargo_gross_weight":Number(grossWt),"dimensions":dimensions}
                    cargoData.push(temp)
                }
            }
            if(destination_country !="" && destination_country !=undefined && deliveryTerms !="" && deliveryTerms !=undefined && no_of_packets > 0 && cargoData.length > 0 && cargoDeliveryPlant !="")
            {
                cargoData=JSON.stringify(cargoData);
                this.setState({
                    loadshow: 'show-m',
                    overly: "show-m",
                })
                var type_of_cargo = this.state.typeOfCargo
                
                var reqparams = {
                    dateField : dateField,
                    departmentname : departmentname,
                    indentername : indentername,
                    mobile : mobile,
                    indenterEmail : indenterEmail,
                    destination_country : destination_country,
                    deliveryTerms : deliveryTerms,
                    no_of_packets : no_of_packets,
                    cargoData : cargoData,
                    logs_cost : logs_cost,
                    type_of_cargo:type_of_cargo,
                    cargo_delivery_plant : cargoDeliveryPlant
                }
                // console.log(reqparams,"reqparams for the data");
                redirectURL.post("/consignments/storeEstimationOfLogsCost", reqparams).then((response) => {
                    this.setState({
                        basicTitle: "Success",
                        basicType: "success",
                        show: true,
                        loadshow: 'show-n',
                        overly: "show-n",
                        logs_cost: "",
                        country : "",
                        deliveryTerms : "",
                    })
                    $("#country").val("");
                    $("#grossWt_").val("")
                    $("#deliveryTerms").val("");
                    $("#no_of_packets").val(1);
                    var cargoData=[];
                    for(var p=0;p<no_of_packets;p++)
                    {
                        $("#volume_weight_"+p).val("");
                        $("#grossWt_"+p).val("");
                        $("#dimensions_"+p).val("");
                    }
                }).catch((e) => {
                    console.log(e);
                })
            }
            else {
                console.log("empty check")
                this.setState({
                    basicTitle: "Fields Should not be Empty",
                    basicType: "warning",
                    show: true
                })
            }
        }
        else
        {
            this.setState({
                basicTitle: "Logs Cost Can not be Empty",
                basicType: "warning",
                show: true
            })
        }
    }
    
    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            bulkslide: '',
        });
    }
    changeHandler = (event) => {
        console.log($("#deliveryTerms").val(),'$("#deliveryTerms").val()')
       if((this.state.selectionOfCountryOnCBU == true)
        && ($("#deliveryTerms").val() == "Free on Board (FOB)")){
            this.setState({
                deliveryTermsState:true
            })
        }
        else {
            this.setState({
                deliveryTermsState:false
            })
        }
        console.log("Select", event.target.value);
        let name = event.target.name;
        console.log(name);
        let value = event.target.value;
        this.setState({[name]:value});
        if(name.includes("dimensions") == true)
        {
            var pktLength=$("#no_of_packets").val();
            var dimensionsTotal=0;
            for(var p=0;p<pktLength;p++)
            {
                var dimensions=$("#dimensions_"+p).val();
                var ccw=$("#cargo_chargeable_weight_"+p).val();
                if(dimensions !='' && dimensions !=undefined)
                {
                    dimensions = dimensions.toUpperCase();
                    if(dimensions.includes("X") == true)
                    {                        
                        var itemWeight = dimensions.split("X");
                        console.log(itemWeight,"itemWeight")
                        var volume = 1;
                        if(itemWeight.length == 3)
                        {
                            itemWeight.map(function(iw){
                                volume = parseInt(volume)*parseInt(iw)
                                console.log(volume,"iw")
                            });
                            console.log(volume,"volume")
                            if(volume > 0)
                            {        
                                var weight = (volume)/6000;
                                weight = weight.toString();
                                if(weight.includes(".") == true)
                                {
                                    weight = parseFloat(weight);
                                    weight =  weight.toFixed(2)
                                }
                                else
                                {
                                    weight = parseInt(weight);
                                }
                                console.log(weight,"weight")
                                $("#volume_weight_"+p).val(weight);
                                dimensionsTotal = parseFloat(dimensionsTotal)+parseFloat(weight)
                            }
                        }
                    }
                }
            }
            console.log(dimensionsTotal,"dimensionsTotal")
            this.setState({
                dimensionsTotal : dimensionsTotal
            })
        }
        if(name.includes("grossWt") == true)
        {
            var pktLength=$("#no_of_packets").val();
            var grossWeightTotal=0;
            for(var k=0;k<pktLength;k++)
            {
                var grossWeight=$("#grossWt_"+k).val();
                this.setState({
                    grossWt:grossWeight
                })
                if(grossWeight !=undefined && grossWeight !="")
                {
                    grossWeightTotal = parseFloat(grossWeightTotal)+parseFloat(grossWeight);
                }
            }
            console.log(grossWeightTotal,"grossWeightTotal")
            this.setState({
                grossWeightTotal : grossWeightTotal
            })
        }
    }
    packetsHandler = (e)=> {
        var noOfPackets = e.target.value;
        console.log((e.target.value == "1" || e.target.value == 1),e.target.value,'target')
       if(e.target.value == "1" || e.target.value == 1){
        $("#no_of_packets").val(1);
        const item = {
            grossWt: "",
            dimensions: "",
            volume_weight:"",
        };
        this.setState({
            packetrows: [item]
        });
       }
        if(parseInt(noOfPackets) >= 1)
        {
            var lespcks = parseInt(noOfPackets)-1;
            var multiplepkt=[{}];
            for(var n=0;n<lespcks;n++)
            {
                const item = {
                    grossWt: "",
                    dimensions: "",
                    volume_weight:"",
                };
                this.setState({
                    packetrows: [...multiplepkt, item]
                });
                var multiplepkt=[...multiplepkt, item];
            }
        }
        else
        {
            alert("No of packets should be greater than 0");
            $("#no_of_packets").val(1);
            const item = {
                grossWt: "",
                dimensions: "",
                volume_weight:"",
            };
            this.setState({
                packetrows: [item]
            });
        }
    }

    calculateLogsCost = () => {
        this.setState({
            loadshow: 'show-m',
            overly: "show-m",
        })
        var type_of_cargo = this.state.typeOfCargo
        var dimensionsTotal = this.state.dimensionsTotal;
        var grossWeightTotal = this.state.grossWeightTotal;
        var no_of_packets = $("#no_of_packets").val()
        var deliveryTerms = $("#deliveryTerms").val();
        if(dimensionsTotal > 0 || grossWeightTotal > 0)
        {
            dimensionsTotal = parseFloat(dimensionsTotal);
            grossWeightTotal = parseFloat(grossWeightTotal);
            var cargoWeight = "";
            if(dimensionsTotal > grossWeightTotal)
            {
                cargoWeight = dimensionsTotal;
            }
            else
            {
                cargoWeight = grossWeightTotal;                
            }
        }
        var country = $("#country").val();
        var cargoDeliveryPlant = $("#cargoDeliveryPlant").val();
        if(country !="" && cargoDeliveryPlant !=""){
            if(type_of_cargo != ''  && deliveryTerms != ""){
                // if(cargoWeight != undefined){
                 var reqparams = {
                "cargoWeight" : cargoWeight,
                "country" : country,
                "cargoDeliveryPlant" : cargoDeliveryPlant,
                "deliveryTerms":deliveryTerms,
                "type_of_cargo":type_of_cargo,
                "no_of_packets" : no_of_packets
            }
            console.log(reqparams,'reqparams--calculate')
            redirectURL.post("/consignments/calculateLogsCost", reqparams).then((response) => {
                var totalCharges = response.data.totalCharges;
                if(totalCharges !="" && totalCharges !=undefined)
                {
                    totalCharges = totalCharges.toString();
                    if(totalCharges.includes(".") == true)
                    {
                        totalCharges = parseFloat(totalCharges);
                        totalCharges =  totalCharges.toFixed(2)
                    }
                    else
                    {
                        totalCharges = parseInt(totalCharges);
                    }
                }
                this.setState({
                    loadshow: 'show-n',
                    overly: "show-n",
                    logs_cost : totalCharges
                });
            }).catch((e) => {
                console.log(e);
            })
        // }else {
        //       this.setState({
        //         loadshow:'show-n',
        //         overly:'show-n',
        //         basicTitle: "Please enter Dimensions and Gross Weight",
        //         basicType: "warning",
        //         show: true,
        //     })
        // }
        }else{
            this.setState({
                loadshow:'show-n',
                overly:'show-n',
                basicTitle: "Please enter Delivery/Inco Terms and Type Of Cargo",
                basicType: "warning",
                show: true,
            })
        }
        }else{
            this.setState({
                loadshow:'show-n',
                overly:'show-n',
                basicTitle: "Please enter Destination Country and MSIL Plant and Dimensions",
                basicType: "warning",
                show: true,
            })
        }
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    render() {
        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>

                
                <div className="row col-xl-12 col-lg-12">
                    <div className="card col-xl-12 col-lg-12">
                        <div className="card-header">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Estimation of LOGS Cost</span>
                            </h5>
                        </div>

                        <div className="card-body">
                            <div className="card-body col-xl-12 col-lg-12">
                                <form method="POST" id="upform" className="" onSubmit={this.estimateLogsCost}>
                                    <div className="row">
                                        <div className="form-group col-xl-4">
                                            <label>Material Dispatch Date : </label>
                                            <input type="text" name="date" id="dateField"
                                             className="datetimepicker_datew form-control" 
                                             autocomplete="off"
                                             readOnly
                                             />

                                        </div>
                                        <div className="form-group col-xl-4">
                                            <label>Department Name* : </label>
                                            <input type="text" name="deptName" id="departmentname" className="form-control"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="form-group col-xl-4">
                                            <label>Indenter Name* : </label>
                                            <input type="text" name="indenterName" id="indentername" className="form-control"  onChange={this.changeHandler} required/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-xl-4">
                                            <label>Mobile No* : </label>
                                            <input type="tel" name="mobile" id="mobile" className="form-control num-cls" pattern="[0-9]{10}"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="form-group col-xl-4">
                                            <label>Indenter Email ID* : </label>
                                            <input type="email" name="indenterEmail" id="indenterEmail" className="form-control"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className={"form-group col-xl-4 col-lg-4"}>
                                            <label >Type Of Cargo</label>
                                            <select class="form-control col-xl-12 col-lg-12" name="type_of_cargo" id="type_of_cargo" required 
                                            
                                            onChange={this.onClickCargo.bind(this)}
                                            
                                            >
                                                <option value="">-- Type of Cargo --</option>
                                                <option value="CBU">CBU</option>
                                                <option value="NonCBU">Non CBU</option>
                                               
                                            </select>                                            
                                        </div>
                                    </div>
                                    <div className="row">
                                       {this.state.selectionOfCountryOnCBU == false ? <div className="form-group col-xl-4 col-lg-4">
                                            <label >Destination Country : </label>
                                            <select name="country" id="country" className="form-control"  onChange={this.changeHandler} required>
                                                <option value="">Select Country</option>
                                                <option value="Algeria">Algeris Port, Algeria</option>
                                                <option value="China">Shanghai/ Chongqing, China</option>
                                                <option value="France">Charles de Gaulle, France</option>
                                                <option value="Germany">Frankfurt, Germany</option>
                                                <option value="Hungary">Budapest, Hungary</option>
                                                <option value="Indonesia">Jakarta, Indonesia</option>
                                                <option value="Italy">Milan/Turin, Italy</option>
                                                <option value="Japan">Narita, Japan</option>
                                                <option value="Korea">Incheon, Korea</option>
                                                <option value="Malaysia">Kaulalumpur, Malaysia</option>
                                                <option value="Myanmar">Yangoon, Myanmar</option>
                                                <option value="Nigeria">Murtala Muhammed Port, Nigeria</option>
                                                <option value="Thailand">Bangkok, Thailand</option>
                                                <option value="Vietnam">Ho Chi Minh City, Vietnam</option>
                                            </select> 
                                        </div> :
                                        <div className="form-group col-xl-4 col-lg-4">
                                        <label >Destination Country : </label>
                                        <select name="country" id="country" className="form-control"  onChange={this.changeHandler} required>
                                            <option value="">Select Country</option>
                                           
                                            <option value="Indonesia">Jakarta, Indonesia</option>
                                            
                                            <option value="Japan">Narita, Japan</option>
                                           
                                        </select> 
                                    </div>
                                        }
                                        <div className="form-group col-xl-4 col-lg-4">
                                            <label >Delivery/Inco Terms : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="deliveryTerms" id="deliveryTerms" onChange={this.changeHandler}  required>changeHandler
                                                <option value="">-- Select Delivery Terms --</option>
                                                <option value="Free on Board (FOB)">Free on Board (FOB)</option>
                                                {/* <option value="Cost and Freight (CFR)">Cost and Freight (CFR)</option> */}
                                                <option value="Cost Insurance Freight (CIF)">Cost Insurance Freight (CIF)</option>
                                                {/* <option value="Delivered at Place (DAP)">Delivered at Place (DAP)</option>
                                                <option value="Delivery Duty Paid (DDP)">Delivery Duty Paid (DDP)</option>
                                                <option value="Delivered Duty Unpaid (DDU)">Delivered Duty Unpaid (DDU)</option> */}
                                            </select>
                                        </div>
                                      {this.state.selectionOfCountryOnCBU == false ?  <div className="form-group col-xl-4 col-lg-4">
                                            <label >No Of Packets : </label>
                                            <input type="text" name="no_of_packets" id="no_of_packets" className="form-control num-cls" 

                                            onBlur={this.packetsHandler}
                                             required/>
                                        </div> :
                                        <div className="form-group col-xl-4 col-lg-4">
                                        <label >No Of Packets  </label>
                                        <input type="text" name="no_of_packets" id="no_of_packets" className="form-control num-cls" 

                                        // onBlur={this.packetsHandler}
                                         required/>
                                    </div> }
                                    </div>
                                    {this.state.packetrows.map((item, idx) => (
                                    <div className="row">
                                        {this.state.selectionOfCountryOnCBU == false ?
                                        <div className="form-group col-xl-4 col-lg-4">
                                            <label >Dimensions (cms) (For NON-ODC Cargo)*</label>
                                            <input type="text" name={"dimensions_"+idx} id={"dimensions_"+idx} placeholder="l x b x h" className="form-control requestFields" onChange={this.changeHandler} required/>
                                        </div> : ""}
                                      { this.state.selectionOfCountryOnCBU == false ? <div className="form-group col-xl-4 col-lg-4">
                                            <label>Gross Weight(KG)* : </label>
                                            <input type="text" name={"grossWt_"+idx} id={"grossWt_"+idx}className="form-control"  onChange={this.changeHandler}
                                            // value={this.state.selectionOfCountryOnCBU ? Number(5600) : this.state.grossWt} 
                                            required/>
                                        </div> 
                                      : <div></div>
                                        }
                                      {this.state.selectionOfCountryOnCBU == false ?  <div className="form-group col-xl-4 col-lg-4">
                                            <label>Volume Weight(KG)* : </label>
                                            <input type="text" readOnly name={"volume_weight_"+idx} id={"volume_weight_"+idx}className="form-control num-cls"  onChange={this.changeHandler} required/>
                                        </div> : ""}
                                    </div>
                                    ))}
                                    {(this.state.selectionOfCountryOnCBU == true && this.state.deliveryTermsState==false ?
                                      <div className="form-group col-xl-4 col-lg-4">
                                      <label>Gross Weight(KG)* : </label>
                                      <input type="text" name={"grossWt_"} id={"grossWt_"}className="form-control"  onChange={this.changeHandler}
                                      value={Number(5600) } 
                                      required/>
                                  </div>:null)}
                                    <div className="row">
                                        <div className={"form-group col-xl-4 col-lg-4"}>
                                            <label >MSIL Plant</label>
                                            <select class="form-control col-xl-12 col-lg-12" name="cargoDeliveryPlant" id="cargoDeliveryPlant" required onChange={this.changeHandler}>
                                                <option value="">-- Select Cargo Pickup Plant --</option>
                                                <option value="msilg">MSIL Gurgaon</option>
                                                <option value="msilm">MSIL Manesar</option>
                                                {/* <option value="smg">MSIL Gujarat</option> */}
                                            </select>                                            
                                        </div>
                                        <button type="button" style={{padding:"0 1%",height:"30px",width:"100px",margin:"3% 2%"}} class="btn btn-warning" onClick={this.calculateLogsCost}>Calculate
                                        </button>
                                    </div>
                                    <div className="row">
                                        <div className={"form-group col-xl-4 col-lg-4"}>
                                            <label >LOGS Cost (INR)</label>
                                            <input type="text" name="logs_cost" id="logs_cost" className="form-control" value={this.state.logs_cost} onChange={this.changeHandler} required/>                                         
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-12 col-lg-12" style={{ textAlign: "center" }}>
                                        <button type="submit" class="btn btn-primary" style={{ width: "14em" }}>
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>

                    
                        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                        </div>

                    <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                </div>
            </div>
        )
    }
}

function removeSplChars(inputString) {
    // console.log(inputString);
    if (inputString != "" && inputString != null && inputString != undefined) {
        return inputString.replace(/[^\w]/g, "");
    }
    else {
        return "";
    }

}



function DateEditor() {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
  // create the cell
  // console.log(params, "params");
  this.eInput = document.createElement("input");
  this.eInput.value = params.value;

  // https://jqueryui.com/datepicker/
  $(this.eInput).datetimepicker({
    dateFormat: "yy-mm-dd",
    timepicker: false,
    changeMonth: true,
    changeYear: true,
  });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
  return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
  this.eInput.focus();
  this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
  return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
  // but this example is simple, no cleanup, we could
  // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
  // and we could leave this method out also, false is the default
  return false;
};

function loadDateTimeScript() {
  // alert("timeDate");
  $(".datetimepicker_datew").datetimepicker({
    format: "d-m-Y",
    minDate: 0,  // Set minimum date to today
    maxDate: new Date(new Date().getFullYear(), 8, 30),  // Set maximum date to September 30 of the current year
    timepicker: false,
    closeOnDateSelect: true,
    scrollMonth: false,
    scrollInput: false,
    onSelectDate: function (ct, $i) {
      // Check if selected date is within the allowed range
      if (ct.getTime() > new Date(new Date().getFullYear(), 8, 30).setHours(23, 59, 59, 999)) {
        alert("Please select a date within this month (from today to September 30).");
        $i.val('');  // Clear the input field
      }
    }
  });
  $(".datetimepicker_dateo").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y",
    minDate: true,
    timepicker: false,
    closeOnDateSelect: true,
    scrollMonth: false,
    scrollInput: false,
  });
  $(".cargo_date").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y",
    minDate: true,
    timepicker: false,
  });
}
function formatDate(dateString) {
  if(dateString != "Invalid Date" && dateString != "" && dateString != "NaN-NaN-NaN" && dateString != undefined && dateString != 'undefined' ){
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
  }
  else {
    return ""
  }
}

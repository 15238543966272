'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import LoadSummaryDrawmap from '../layouts/loadSummaryDrawmap';
import CommentActions from "../layouts/commentsComponent";
import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "./updatelmstates";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CellGraphComponent from './cellGraphComponent';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { HardwareDesktopWindows } from 'material-ui/svg-icons';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from "@fullcalendar/timegrid";
import LoadSummaryColumnChart from "./loadSummaryColumnChart";
import download from 'js-file-download';
import LoadSummaryRecommondations from "./loadSummaryRecommondations";
import TripBarChart from './adherenceTripCharts.js';
import CostBarChart from './adherenceCostCharts.js';
import DemandBarChart from './adherenceDemandCharts.js';
import AdherencePercentBarChart from './adherencePercent.js';

// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class ViewLoadSummaryDetails extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          consignmentforceclose:Consignmentforceclose,
          consignmentActions:ConsignmentActions,
          UpdateButton:UpdateButton,
          MarkDeliver : MarkDeliver,
          commentActions : CommentActions
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        bulkslide1:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck: "",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-m",
        loadshow:"show-m",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        loadFile : "",
        config : "",
        summary: "activet",
        trucks: "",
        courier: "",
        recommendations: "",
        dropped: "",
        adherence: "",
        configData: "show-n",
        summaryData: "",
        trucksData: "show-n",
        courierData: "show-n",
        recommendationsData: "show-n",
        droppedData: "show-n",
        adherenceData:'show-n',
        seriesData: [],
        categories: [],
        costData: [],
        costCategories: [],
        actcostData: [],
        actcostCategories: [],
        drpData : [],
        drpCategories : [],
        fileName : "",
        configurationData : [],
        truckAvailabilityData : [],
        clustersData : [],
        couriersData : [],
        recommData : [],
        droppedPData : [],
        transactionId: "",
        latLngArray: [],
        mapType: "",
        sliderTitle: "",
        loadingLocation:[],
        comp_short_name:"",
        loading_port_code:"",
        sliderType:0,
        clusterDetails:[],
        consolidatedFile:"",
        data_type:1,
        // vehicleType:"",
        filteredTruckDataforTruckAvailability:[],
        adherenceTransactionDetails:[],
        adherenceTripData:[],
        adherenceDemandData:[],
        adherenceCostData:[],
        adherencePercentData:[],
        cost_manual:0,
        cost_system:0,
        load:0,
        percent_adhered_trips:0,
        percent_truck_adherence:0,
        manualVsSystemData:[],
        demand_manual:0,
        demand_system:0,
        mgpdata:'show-n',
        mgp:'',
        mgprowdata:[],
        total_pick_tickets:0,
        pick_tickets_received:0,
        pick_tickets_remaining:0,
        last_calculated_date:'',
        mgp_total_pick_tickets:0,
        mgp_pick_tickets_received:0,
        mgp_pick_tickets_remaining:0,
        last_synced_at:''
        //components : {datepicker:getDatePicker()}
    }
}
async componentDidMount(){
    var transactionId = window.atob(this.props.match.params.eventname);
    // console.log(transactionId,"transactionId")
    var query = {
        "transactionId" : transactionId
    }

    redirectURL.post("/lmconsignees/getAdherenceTransactionDetails",query).then((response)=>{
        var adherenceTransactionDetails = response.data;
        if(adherenceTransactionDetails.length>0){
        let cost_manual = adherenceTransactionDetails[0].cost_manual;
        let cost_system = adherenceTransactionDetails[0].cost_system;
        let load = adherenceTransactionDetails[0].demand;
        let percent_adhered_trips = adherenceTransactionDetails[0].percent_adhered_trips;
        let percent_truck_adherence = adherenceTransactionDetails[0].percent_truck_adherence;
        let demand_manual = adherenceTransactionDetails[0].demand_manual
        let demand_system = adherenceTransactionDetails[0].demand_system
        let total_pick_tickets = adherenceTransactionDetails[0].total_pick_tickets
        let pick_tickets_received = adherenceTransactionDetails[0].pick_tickets_received
        let pick_tickets_remaining = adherenceTransactionDetails[0].pick_tickets_remaining
        let last_calculated_date = moment.parseZone(adherenceTransactionDetails[0].created_date).format("DD-MM-YYYY HH:mm")
        cost_manual = formatIndianNumber(cost_manual)
        cost_system = formatIndianNumber(cost_system)
        this.setState({
            adherenceTransactionDetails : adherenceTransactionDetails,
            cost_manual,cost_system,load,percent_adhered_trips,percent_truck_adherence,demand_manual,demand_system,
            total_pick_tickets,pick_tickets_received,pick_tickets_remaining,last_calculated_date
        });
    }
    });

    redirectURL.post("/lmconsignees/getTransactionConsolidatedFile",query).then((response1)=>{
        var consolidatedFile = response1.data;
        this.setState({
            consolidatedFile : consolidatedFile
        });
    });

    await redirectURL.post("/lmconsignees/getLoadProcessRunDetailsByTransactionId",query).then((response)=>{
        var loadprocess = response.data;
        // console.log(loadprocess,"215")
        var configurationData = [];
        var truckAvailabilityData = []
        var comp_short_name =  "";
        var loading_port_code =  "";
        var cdArray = [];
        var data_type = 1;
        if(loadprocess.length > 0)
        {
            var fileName = loadprocess[0].filename;
            var configurationData = loadprocess[0].vehicleConfig;
            if(loadprocess[0].sobConfig !=undefined && loadprocess[0].sobConfig !="")
            {
                var truckAvailabilityData = JSON.parse(loadprocess[0].sobConfig);
            }
            var truckConfig = loadprocess[0].truckConfig;
            var comp_short_name = loadprocess[0].comp_short_name;
            var loading_port_code = loadprocess[0].loading_port_code;
            data_type = loadprocess[0].data_type
            if(configurationData !=undefined)
            {
                configurationData.map(function(cd){
                    if(truckConfig !=undefined)
                    {
                        truckConfig.map(function(tc){
                            if(cd.vehicle_cap == tc.vehicle_capacity)
                            {
                                console.log(cd.vehicle_cap,tc.vehicle_capacity,"vech")
                                cdArray.push({
                                    vehicle_cap : cd.vehicle_cap,
                                    min_capacity_cmt : cd.min_capacity_cmt,
                                    max_capacity_cmt : cd.max_capacity_cmt,
                                    min_courier : cd.min_courier,
                                    nodes_per_truck : cd.nodes_per_truck,
                                    allowed_distance : cd.allowed_distance,
                                    deviation_allowed : cd.deviation_allowed,
                                    telescopic_route : cd.telescopic_route,
                                    vehicle_count : tc.vehicle_count,
                                });
                                console.log(cdArray,"cdArray")
                            }
                        });
                    }
                    else
                    {
                        cdArray.push({
                            vehicle_cap : cd.vehicle_cap,
                            min_capacity_cmt : cd.min_capacity_cmt,
                            max_capacity_cmt : cd.max_capacity_cmt,
                            min_courier : cd.min_courier,
                            nodes_per_truck : cd.nodes_per_truck,
                            allowed_distance : cd.allowed_distance,
                            deviation_allowed : cd.deviation_allowed,
                            telescopic_route : cd.telescopic_route,
                            vehicle_count : "",
                        });
                    }
                })
            }
        }
        this.setState({
            rowData : loadprocess,
            fileName : fileName,
            transactionId : transactionId,
            configurationData : cdArray,
            truckAvailabilityData : truckAvailabilityData,
            comp_short_name : comp_short_name,
            loading_port_code : loading_port_code,
            data_type : data_type
        })
    }).catch((e)=>{
        console.log(e);
    })
    await this.onLoadPageData(transactionId);
    // await this.onLoadSeriesData(transactionId);
    // await this.onLoadCostSummaryData(transactionId);
    // await this.getRecommondationsData(transactionId);
}
onLoadPageData(transactionId){
    var data_type = this.state.data_type;
    var params = {
        "transactionId" : transactionId,
        "data_type" : data_type
    }
    redirectURL.post("/lmconsignees/getLoadSummaryDetails",params).then((response)=>{
        console.log(response.data,"chartData");
        if(data_type == 2)
        {
            var respData = [{
                    "name" : "Total Order CMT",
                    "showTooltip": 1,
                    "y" : parseFloat(response.data.total_orders.toFixed(2)),
                },
                {
                    "name" : "Loads Planned",
                    "showTooltip": 1,
                    "y" : parseFloat(response.data.total_truck_orders.toFixed(2)),
                },
                {
                    "name" : "Dropped Orders(CMT)",
                    "showTooltip": 1,
                    "y" : parseFloat(response.data.total_dropped_orders.toFixed(2)),
                },
            ];
            var categories = ["Total Order CMT","Loads Planned","Dropped Orders(CMT)"];
        }
        else
        {
            var respData = [{
                    "name" : "Total Order CMT",
                    "showTooltip": 1,
                    "y" : parseFloat(response.data.total_orders.toFixed(2)),
                },
                {
                    "name" : "Planned By Truck(CMT)",
                    "showTooltip": 1,
                    "y" : parseFloat(response.data.total_truck_orders.toFixed(2)),
                },
                {
                    "name" : "Planned By Courier(CMT)",
                    "showTooltip": 1,
                    "y" : parseFloat(response.data.total_courier_orders.toFixed(2)),
                    "color": "#4c923e"
                },
                {
                    "name" : "Dropped Orders(CMT)",
                    "showTooltip": 1,
                    "y" : parseFloat(response.data.total_dropped_orders.toFixed(2)),
                },
            ];
            var categories = ["Total Order CMT","Planned By Truck(CMT)","Planned By Courier(CMT)","Dropped Orders(CMT)"];
        }
        var droppedReasons = Object.values(response.data.droppedReasons);
        var drpData = [];
        var drpCategories = [];
        if(droppedReasons.length>0){
            droppedReasons.map(function(k){
                drpData.push({
                    "name": k.dropped_reason,
                    "showTooltip": 1,
                    "y": parseFloat(k.dropped_count.toFixed(2)),
                    color:'#ec6b56'
                })
                drpCategories.push(k.dropped_reason)
            });
            }else{
                drpData.push({
                    "name": 'No Invalid Orders',
                    "showTooltip": 1,
                    "y": parseFloat(0),
                    color:'	#47b39c'
                }) 
            }
            drpData.push({
                "name": 'Accepted Orders',
                "showTooltip": 1,
                "y": parseFloat(response.data.total_accepted_count),
                color:'	#47b39c'
            })
            drpCategories.push('Accepted Orders')
        var costResData = Object.values(response.data.cost_summary);
        var costData = [];
        var costCategories = [];
        var totalCost = 0;
        var actcostData = [];
        var actcostCategories = [];
        var acttotalCost = 0;
        costResData.map(function(k){
            totalCost = parseFloat(totalCost) + parseFloat(k.vehicleCost);
            acttotalCost = parseFloat(acttotalCost) + parseFloat(k.actual_cost);
        });
        costData.push({
            "name": "Total Freight Cost",
            "showTooltip": 1,
            "y": parseFloat(totalCost.toFixed(2)),
        })
        
        actcostData.push({
            "name": "Total Freight Cost",
            "showTooltip": 1,
            "y": parseFloat(acttotalCost.toFixed(2)),
        })
        costResData.map(function(e){
            if(e.truck_capacity == "Courier")
            {
                costData.push({
                    "name": e.truck_capacity,
                    "showTooltip": 1,
                    "y": parseFloat(e.vehicleCost.toFixed(2)),
                    "color": "#4c923e"
                })
                actcostData.push({
                    "name": e.truck_capacity,
                    "showTooltip": 1,
                    "y": parseFloat(e.actual_cost.toFixed(2)),
                    "color": "#4c923e"
                })
            }
            else
            {
                costData.push({
                    "name": e.truck_capacity,
                    "showTooltip": 1,
                    "y": parseFloat(e.vehicleCost.toFixed(2)),
                })
                actcostData.push({
                    "name": e.truck_capacity,
                    "showTooltip": 1,
                    "y": parseFloat(e.actual_cost.toFixed(2)),
                })
            }
            costCategories.push(e.truck_capacity)
            actcostCategories.push(e.truck_capacity)
        });
        this.setState({
            seriesData : respData,
            categories : categories,
            drpData : drpData,
            drpCategories : drpCategories,
            costData : costData,
            costCategories : costCategories,
            actcostData : actcostData,
            actcostCategories : actcostCategories,
            overly:"show-n",
            loadshow:"show-n",
        })
    }).catch((e)=>{
        console.log(e);
    })
}
onLoadSeriesData(transactionId){
    var data_type = this.state.data_type;
    var params = {
        "transactionId" : transactionId,
        "data_type" : data_type
    }
    redirectURL.post("/lmconsignees/getLmChartData",params).then((response)=>{
        console.log(response.data,"chartData");
        if(data_type == 2)
        {
            var respData = [{
                    "name" : "Total Order CMT",
                    "showTooltip": 1,
                    "y" : parseFloat(response.data.total_orders.toFixed(2)),
                },
                {
                    "name" : "Loads Planned",
                    "showTooltip": 1,
                    "y" : parseFloat(response.data.total_truck_orders.toFixed(2)),
                },
                {
                    "name" : "Dropped Orders(CMT)",
                    "showTooltip": 1,
                    "y" : parseFloat(response.data.total_dropped_orders.toFixed(2)),
                },
            ];
            var categories = ["Total Order CMT","Loads Planned","Dropped Orders(CMT)"];
        }
        else
        {
            var respData = [{
                    "name" : "Total Order CMT",
                    "showTooltip": 1,
                    "y" : parseFloat(response.data.total_orders.toFixed(2)),
                },
                {
                    "name" : "Planned By Truck(CMT)",
                    "showTooltip": 1,
                    "y" : parseFloat(response.data.total_truck_orders.toFixed(2)),
                },
                {
                    "name" : "Planned By Courier(CMT)",
                    "showTooltip": 1,
                    "y" : parseFloat(response.data.total_courier_orders.toFixed(2)),
                    "color": "#4c923e"
                },
                {
                    "name" : "Dropped Orders(CMT)",
                    "showTooltip": 1,
                    "y" : parseFloat(response.data.total_dropped_orders.toFixed(2)),
                },
            ];
            var categories = ["Total Order CMT","Planned By Truck(CMT)","Planned By Courier(CMT)","Dropped Orders(CMT)"];
        }
        var droppedReasons = response.data.droppedReasons;
        var drpData = [];
        var drpCategories = [];
        droppedReasons.map(function(k){
            drpData.push({
                "name": k.dropped_reason,
                "showTooltip": 1,
                "y": parseFloat(k.dropped_count.toFixed(2)),
            })
            drpCategories.push(k.dropped_reason)
        });
        this.setState({
            seriesData : respData,
            categories : categories,
            drpData : drpData,
            drpCategories : drpCategories,
            overly:"show-n",
            loadshow:"show-n",
        })
    }).catch((e)=>{
        console.log(e);
    })
}
onLoadCostSummaryData(transactionId){
    var params = {
        "transactionId" : transactionId,
        "data_type" : this.state.data_type
    }
    redirectURL.post("/lmconsignees/getLmChartCostSummaryData",params).then((response)=>{
        console.log(response.data,"costData");
        var costResData = response.data;
        var costData = [];
        var costCategories = [];
        var totalCost = 0;
        costResData.map(function(k){
            totalCost = parseFloat(totalCost) + parseFloat(k.vehiCost);
        });
        costData.push({
            "name": "Total Freight Cost",
            "showTooltip": 1,
            "y": parseFloat(totalCost.toFixed(2)),
        })
        costResData.map(function(e){
            if(e.truck_capacity == "Courier")
            {
                costData.push({
                    "name": e.truck_capacity,
                    "showTooltip": 1,
                    "y": parseFloat(e.vehiCost.toFixed(2)),
                    "color": "#4c923e"
                })
            }
            else
            {
                costData.push({
                    "name": e.truck_capacity,
                    "showTooltip": 1,
                    "y": parseFloat(e.vehiCost.toFixed(2)),
                })
            }
            costCategories.push(e.truck_capacity)
        });
        //console.log(costData,"costData");
        //console.log(costCategories,"costCategories");
        this.setState({
            costData : costData,
            costCategories : costCategories,
        })
    }).catch((e)=>{
        console.log(e);
    })

    redirectURL.post("/lmconsignees/getLmChartActualCostSummaryData",params).then((response)=>{
        console.log(response.data,"actualcostData");
        var actcostResData = response.data;
        var actcostData = [];
        var actcostCategories = [];
        var totalCost = 0;
        actcostResData.map(function(k){
            totalCost = parseFloat(totalCost) + parseFloat(k.vehiCost);
        });
        actcostData.push({
            "name": "Total Freight Cost",
            "showTooltip": 1,
            "y": parseFloat(totalCost.toFixed(2)),
        })
        actcostResData.map(function(e){
            if(e.truck_capacity == "Courier")
            {
                actcostData.push({
                    "name": e.truck_capacity,
                    "showTooltip": 1,
                    "y": parseFloat(e.vehiCost.toFixed(2)),
                    "color": "#4c923e"
                })
            }
            else
            {
                actcostData.push({
                    "name": e.truck_capacity,
                    "showTooltip": 1,
                    "y": parseFloat(e.vehiCost.toFixed(2)),
                })
            }
            actcostCategories.push(e.truck_capacity)
        });
        //console.log(actcostData,"actcostData");
        //console.log(costCategories,"costCategories");
        this.setState({
            actcostData : actcostData,
            actcostCategories : actcostCategories,
        })
    }).catch((e)=>{
        console.log(e);
    })
}
getClustersData(transactionId){
    var params = {
        "transactionId" : transactionId,
        "data_type" : this.state.data_type
    }
    redirectURL.post("/lmconsignees/getClustersData",params).then((response)=>{
        console.log(response.data,"clustersData");
        this.setState({
            clustersData : response.data,
            overly:"show-n",
            loadshow:"show-n",
        })
    }).catch((e)=>{
        console.log(e);
    })
}    
getCouriersData(transactionId){
    var params = {
        "transactionId" : transactionId
    }
    redirectURL.post("/lmconsignees/getCouriersData",params).then((response)=>{
        console.log(response.data,"couriersData");        
        this.setState({
            couriersData : response.data,
            overly:"show-n",
            loadshow:"show-n",
        })
    }).catch((e)=>{
        console.log(e);
    })
}
getRecommondationsData(transactionId){
    var params = {
        "transactionId" : transactionId
    }
    redirectURL.post("/lmconsignees/getRecommondationsData",params).then((response)=>{
        console.log(response.data,"recommData");        
        this.setState({
            recommData : response.data,
            overly:"show-n",
            loadshow:"show-n",
        })
    }).catch((e)=>{
        console.log(e);
    })
}
getDroppedData(transactionId){
    var params = {
        "transactionId" : transactionId
    }
    redirectURL.post("/lmconsignees/getDroppedData",params).then((response)=>{
        console.log(response.data,"droppedPData");
        this.setState({
            droppedPData : response.data,
            overly:"show-n",
            loadshow:"show-n",
        })
    }).catch((e)=>{
        console.log(e);
    })
}
onClickSendRecommend(){
    var transactionId = this.state.transactionId;
    console.log(transactionId)
    var rowData = [];
    this.gridApi.forEachNode(node => rowData.push(node.data));
    //return rowData;
    console.log(rowData,"rowData")
} 
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
    var sort = [
        {
          colId: 'cluster',
          sort: 'asc',
        },
      ];
    this.gridApi.setSortModel(sort);
};
onGridState = () =>{
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
    * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
            
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
}

onRecommGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
    var sort = [
        {
          colId: 'cluster',
          sort: 'asc',
        },
      ];
    this.gridApi.setSortModel(sort);
};
onRecommGridState = () =>{
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
    * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
            
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
}


closeAlert = () => {
    this.setState({
        show: false
    });
}

onCellClicked(e)
{
    if(e.colDef.field == "container_no")
    {
        console.log(e.data.container_no);
    }
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    bulkslide1:""
    });
    
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}

handleDownloadOutputFile = (idx) => {
    var outputFile = idx;
    console.log(outputFile)
    if(outputFile !='')
    {
        var rngParam = {
            outputFile : outputFile,
        }
        redirectURL.post("/lmconsignees/downloadOutputFile",rngParam).then((response)=>{
            console.log("download response ",response)
            download(response.data, outputFile)
            if(response.data.message == "success")
            {
                alert("success");
            }
            
        }).catch(function(error){
            console.log(error);
        })
    }
}

onClickTab(pageType){
    if(pageType == "config")
    {
        var config = "activet";
        var summary = "";
        var trucks = "";
        var courier = "";
        var recommendations = "";
        var dropped = "";
        var adherence = "";
        var configData = "";
        var summaryData = "show-n";
        var trucksData = "show-n";
        var courierData = "show-n";
        var recommendationsData = "show-n";
        var droppedData = "show-n";
        var adherenceData = "show-n";
        var mgp = "";
        var mgpdata = "show-n"
    }
    if(pageType == "summary")
    {
        var config = "";
        var summary = "activet";
        var trucks = "";
        var courier = "";
        var recommendations = "";
        var dropped = "";
        var adherence = "";
        var configData = "show-n";
        var summaryData = "";
        var trucksData = "show-n";
        var courierData = "show-n";
        var recommendationsData = "show-n";
        var droppedData = "show-n";
        var adherenceData = "show-n";
        var mgp = "";
        var mgpdata = "show-n"
    }
    if(pageType == "trucks")
    {
        var config = "";
        var summary = "";
        var trucks = "activet";
        var courier = "";
        var recommendations = "";
        var dropped = "";
        var adherence = "";
        var configData = "show-n";
        var summaryData = "show-n";
        var trucksData = "";
        var courierData = "show-n";
        var recommendationsData = "show-n";
        var droppedData = "show-n";
        var adherenceData = "show-n";
        var mgp = "";
        var mgpdata = "show-n"
        if(this.state.clustersData.length == 0)
        {
            this.setState({
                overly:"show-m",
                loadshow:"show-m",
            })
            this.getClustersData(this.state.transactionId)
        }        
    }
    if(pageType == "courier")
    {
        var config = "";
        var summary = "";
        var trucks = "";
        var courier = "activet";
        var recommendations = "";
        var dropped = "";
        var adherence = "";
        var configData = "show-n";
        var summaryData = "show-n";
        var trucksData = "show-n";
        var courierData = "";
        var recommendationsData = "show-n";
        var droppedData = "show-n";
        var adherenceData = "show-n";
        var mgp = "";
        var mgpdata = "show-n"
        if(this.state.couriersData.length == 0)
        {
            this.setState({
                overly:"show-m",
                loadshow:"show-m",
            })
            this.getCouriersData(this.state.transactionId)
        }
    }
    if(pageType == "recommendations")
    {
        var config = "";
        var summary = "";
        var trucks = "";
        var courier = "";
        var recommendations = "activet";
        var dropped = "";
        var adherence = "";
        var configData = "show-n";
        var summaryData = "show-n";
        var trucksData = "show-n";
        var courierData = "show-n";
        var recommendationsData = "";
        var droppedData = "show-n";
        var adherenceData = "show-n";
        var mgp = "";
        var mgpdata = "show-n"
        if(this.state.recommData.length == 0)
        {
            this.setState({
                overly:"show-m",
                loadshow:"show-m",
            })
            this.getRecommondationsData(this.state.transactionId)
        }
    }
    if(pageType == "dropped")
    {
        var config = "";
        var summary = "";
        var trucks = "";
        var courier = "";
        var recommendations = "";
        var dropped = "activet";
        var adherence = "";
        var configData = "show-n";
        var summaryData = "show-n";
        var trucksData = "show-n";
        var courierData = "show-n";
        var recommendationsData = "show-n";
        var droppedData = "";
        var adherenceData = "show-n";
        var mgp = "";
        var mgpdata = "show-n"
        if(this.state.droppedPData.length == 0)
        {
            this.setState({
                overly:"show-m",
                loadshow:"show-m",
            })
            this.getDroppedData(this.state.transactionId)
        }
    }
    if(pageType == "adherence")
    {
        var config = "";
        var summary = "";
        var trucks = "";
        var courier = "";
        var recommendations = "";
        var dropped = "";
        var adherence = "activet";
        var configData = "show-n";
        var summaryData = "show-n";
        var trucksData = "show-n";
        var courierData = "show-n";
        var recommendationsData = "show-n";
        var droppedData = "show-n";
        var adherenceData = "";
        var mgp = "";
        var mgpdata = "show-n"
        this.setState({
            overly:"show-m",
            loadshow:"show-m",
        })
        this.getAdherenceChartData(this.state.transactionId)
        this.getLmManualVsSystemData(this.state.transactionId)
    }
    if(pageType == "mgp")
        {
            var config = "";
            var summary = "";
            var trucks = "";
            var courier = "";
            var recommendations = "";
            var dropped = "";
            var adherence = "";
            var configData = "show-n";
            var summaryData = "show-n";
            var trucksData = "show-n";
            var courierData = "show-n";
            var recommendationsData = "show-n";
            var droppedData = "show-n";
            var adherenceData = "show-n";
            var mgp = "activet";
            var mgpdata = ""
            this.setState({
                overly:"show-m",
                loadshow:"show-m",
            })
            this.getMGPData()
        }
    this.setState({
        config: config,
        summary: summary,
        trucks: trucks,
        courier: courier,
        recommendations: recommendations,
        dropped: dropped,
        adherence:adherence,
        configData: configData,
        summaryData: summaryData,
        trucksData: trucksData,
        courierData: courierData,
        recommendationsData: recommendationsData,
        droppedData: droppedData,
        adherenceData:adherenceData,
        mgp:mgp,
        mgpdata:mgpdata
    })
}
onCellClickedGrid(row){
    if(row.colDef.field == "_id"){
        var cluster = row.data.cluster;
        var zone = row.data.zone;
        var tripDistance = row.data.tripDistance;
        var truck_capacity = row.data.truck_capacity;
        var transactionId = this.state.transactionId;        
        var dealer_code = row.data.dealerCode;        
        var load_cmt = row.data.load_cmt;
        // if(truck_capacity == "Courier")
        // {
        //     var latLngArray = [{
        //         lat : row.data.lat,
        //         lng : row.data.lng,
        //         dealer_code : dealer_code,
        //         truck_capacity : truck_capacity,
        //         load_cmt : load_cmt,
        //     }]
        //     this.setState({
        //         latLngArray : latLngArray,
        //         mapType : 2,
        //         bulkslide : "slider-translate-60p",
        //         overly: "show-m",
        //         sliderTitle : sliderTitle
        //     })
        // }
        // else
        // {
            
        // }
        var params = {
            "transactionId" : transactionId,
            "cluster" : cluster,
        }
        redirectURL.post("/lmconsignees/getClustersLatData",params).then(async (response)=>{
            //console.log(response.data,"latLngData");
            var latLngInfo = response.data;
            var latLngArray = [];
            var latLngData = groupBy(latLngInfo, rdata => rdata.dealer_code);
            await latLngData.forEach(async (det, zonskey) =>{
                //console.log(det,"det")
                await det.map(async function(e,index){
                    if(index == 0)
                    {
                        latLngArray.push({
                            lat : e.lat,
                            lng : e.lng,
                            dealer_code : e.dealer_code,
                            truck_capacity : e.truck_capacity,
                            load_cmt : e.load_cmt,
                            loading_sequence : e.loading_sequence
                        })
                    }
                })
            })
            latLngArray = latLngArray.sort(GetSortDescOrder("loading_sequence"));
            console.log(latLngArray,"latLngArray")
            var mapType = 1;
            if(truck_capacity == "Courier")
            {
                var mapType = 2;
                var sliderTitle = "Cluster Name : "+cluster+" Total Distance :0 Km";
            }
            else{
                var sliderTitle = "Cluster Name : "+cluster+" Total Distance : "+tripDistance+" Km";
            }
            var loading_port_code = this.state.loading_port_code;
            var aparams = {
                loading_port_code : loading_port_code
            }
            await redirectURL.post("/lmconsignees/getLmLoadingPortName",aparams).then((response1)=>{
                //console.log(response1.data,"LLPN");
                var loadingLocation = [];
                 loadingLocation.push({
                    lat : response1.data[0].lat,
                    lng : response1.data[0].lng,
                })
                this.setState({
                    loadingLocation : loadingLocation,
                })
            }).catch((e)=>{
                console.log(e);
            })
            this.setState({
                sliderType : 0,
                mapType : mapType,
                latLngArray : latLngArray,
                bulkslide : "slider-translate-60p",
                overly: "show-m",
                sliderTitle : sliderTitle
            })
        }).catch((e)=>{
            console.log(e);
        })
    }
    if(row.colDef.field == "details"){
        this.setState({
            loadshow:"show-m"
        })
        var cluster = row.data.cluster;
        var zone = row.data.zone;
        var tripDistance = row.data.tripDistance;
        var truck_capacity = row.data.truck_capacity;
        var transactionId = this.state.transactionId;        
        var dealer_code = row.data.dealerCode;        
        var load_cmt = row.data.load_cmt;
        var params = {
            "transactionId" : transactionId,
            "cluster" : cluster,
        }
        redirectURL.post("/lmconsignees/getClustersDetails",params).then(async (response)=>{
            //console.log(response.data,"latLngData");
            var clusterDetails = response.data;
            //console.log(clusterDetails,"clusterDetails")
            var sliderTitle = "Cluster Name : "+cluster;
            this.setState({
                sliderType : 1,
                clusterDetails : clusterDetails,
                bulkslide : "slider-translate-60p",
                overly: "show-m",
                sliderTitle : sliderTitle,
                loadshow:"show-n"
            })
        }).catch((e)=>{
            console.log(e);
        })
    }
    if(row.colDef.field == "vehicle_count"){
        var vehicle_type = row.data.vehicle_cap
        var truckAvailabilityData = this.state.truckAvailabilityData
        var filteredTruckData = truckAvailabilityData.filter((e)=>e.vehicle_type == vehicle_type)
        this.setState({
            // vehicleType : vehicle_type,
            filteredTruckDataforTruckAvailability : filteredTruckData,
            bulkslide1 : "slider-translate-60p",
            // loadshow:"show-m",
            overly: "show-m",
            sliderTitle : "Truck Availability"
        })
    }
}
onClickShowAllCouriers(){          
    this.setState({
        bulkslide : "slider-translate-60p",
        loadshow:"show-m",
        overly: "show-m",
        sliderTitle : "All Couriers"
    })
    if(this.state.latLngArray.length > 0)
    {          
        this.setState({
            latLngArray : this.state.latLngArray,
            mapType : 2,
            loadshow:"show-n"
        })
    }
    else
    {
        var transactionId = this.state.transactionId;
        var params = {
            "transactionId" : transactionId,
        }
        redirectURL.post("/lmconsignees/getAllCouriersLatData",params).then((response)=>{
            //console.log(response.data,"latLngData");
            var latLngData = response.data;
            var latLngArray = [];
            
            latLngData.map(function(e){
                latLngArray.push({
                    lat : e.lat,
                    lng : e.lng,
                    dealer_code : e.dealer_code,
                    truck_capacity : e.truck_capacity,
                    load_cmt : e.load_cmt,
                })
            })
            console.log(latLngArray,"latLngArray")
          
            this.setState({
                latLngArray : latLngArray,
                mapType : 2,
                loadshow:"show-n"
            })
        }).catch((e)=>{
            console.log(e);
        })
    }
}

getAdherenceChartData(transactionId){
    var params = {
        "transactionId" : transactionId
    }
    redirectURL.post("/lmconsignees/getAdherenceChartData",params).then((response)=>{
        console.log(response.data,"getAdherenceChartData");

        const originalArray = response.data;
       
          // Use the map function to create a new array with only the desired keys
          const tripsArray = originalArray.map(obj => ({
            vehicle_type: obj.vehicle_type,
            num_trips_system: obj.num_trips_system,
            num_trips_manual: obj.num_trips_manual,
          }));

          const demandArray = originalArray.map(obj => ({
            vehicle_type: obj.vehicle_type,
            demand_manual: parseInt(obj.demand_manual),
            demand_system: parseInt(obj.demand_system),
          }));

          const costArray = originalArray.map(obj => ({
            vehicle_type: obj.vehicle_type,
            cost_manual: parseInt(obj.cost_manual),
            cost_system: parseInt(obj.cost_system),
          }));

          const percentArray = originalArray.map(obj => ({
            vehicle_type: obj.vehicle_type,
            percent_adhered_trips: obj.percent_adhered_trips,
          }));
        
        this.setState({
            // adherenceChartData : response.data,
            // seriesData : respData,
            // categories : categories,
            adherenceTripData:tripsArray,
            adherenceDemandData:demandArray,
            adherenceCostData:costArray,
            adherencePercentData:percentArray,
            overly:"show-n",
            loadshow:"show-n",
        })
    }).catch((e)=>{
        console.log(e);
    })
}

getLmManualVsSystemData(transactionId){
    var params = {
        "transactionId" : transactionId
    }
    redirectURL.post("/lmconsignees/getLmManualVsSystemData",params).then((response)=>{
        // console.log(response.data,"getLmManualVsSystemData");
        
        response.data.sort((a, b) => a.trip_no_system - b.trip_no_system);
        this.setState({
            manualVsSystemData:response.data
        })

    })
}

calculateadherencedata = () =>{
    let transaction_id = this.state.transactionId;
    let loading_port_code = this.state.loading_port_code;
    let params = {
        transaction_id : transaction_id,
        loading_port_code : loading_port_code
    }
    redirectURL.post("/lmconsignees/fetchMGPAPIDataAndtriggerScriptForInputPlanData", params)
    this.setState({
        show:true,
        type:'success',
        basicTitle:'Process Triggered'
    })
}

getMGPData = () =>{
    let transaction_id = this.state.transactionId;
    let loading_port_code = this.state.loading_port_code;
    let params = {
        transaction_id : transaction_id,
        loading_port_code : loading_port_code
    }
    redirectURL.post("/lmconsignees/getMGPData", params)
    .then(res =>{
        console.log(res,'ress')
        if(res.data.data.status == undefined){
            this.setState({
                mgprowdata:JSON.parse(res.data.data.data),
                mgp_total_pick_tickets:res.data.data.total_pick_tickets,
                mgp_pick_tickets_received:res.data.data.pick_tickets_received,
                mgp_pick_tickets_remaining:res.data.data.pick_tickets_remaining,
                last_synced_at: moment.parseZone(res.data.data.last_synced_at).format("DD-MM-YYYY HH:mm"),
                overly:"show-n",
                loadshow:"show-n",
            })
        }else{
            this.setState({
                overly:"show-n",
                loadshow:"show-n",
                show:true,
                type:'warning',
                basicTitle:'No Data'
            })
        }

    })
    
}

  render() {
    
    var columnwithDefsForConfigs = [      
        {
            headerName: "Vehicle Type",
            field: "vehicle_cap",
            width:160,
            filter: true,
            resizable: true,
            editable:false,            
        },
        {
            headerName: "Vehicle Count",
            field: "vehicle_count",
            width:150,
            filter: true,
            resizable: true,
            editable:false,            
        },
        {
            headerName: "Min Capacity (CMT)",
            field: "min_capacity_cmt",
            width:160,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Max Capacity (CMT)",
            field: "max_capacity_cmt",
            width:160,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Min CMT per Consignee",
            field: "min_courier",
            width:160,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Maximum Nodes",
            field: "nodes_per_truck",
            width:150,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Max Distance",
            field: "allowed_distance",
            width:150,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Deviation Distance (%)",
            field: "deviation_allowed",
            width:180,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Telescopic Route",
            field: "telescopic_route",
            width:180,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter : function(params){
                if(params.data.telescopic_route == 1)
                {
                    return "Enabled";
                }
                else
                {
                    return "Disabled";
                }
            }
        },
    ]
    var data_type = this.state.data_type;
    var hiddenRegular = false;
    var hiddenCourier = true;
    if(data_type == 2)
    {
        hiddenRegular = true;
        hiddenCourier = false;
    }
    var columnwithDefs = [  
        {
            headerName: "",
            field: "_id",
            width:50,
            filter: true,
            resizable: true,
            editable:false,
            cellRenderer: function(e){
                return '<i class="icofont icofont-map-pins f25"></i>'
            }

        },
        {
            headerName: "Details",
            field: "details",
            width:80,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter:function(params){
                return "View";
            },
        },  
        {
            headerName: "Cluster",
            field: "cluster",
            width:100,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                return params.data.cluster
            }
        },
        {
            headerName: "Transporter",
            field: "transporter_name",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                if(params.data.transporter_name !=undefined && params.data.transporter_name !='')
                {
                    return params.data.transporter_name                   
                }
                else
                {
                    return "Not Assigned";
                }
            }
        },
        {
            headerName: "Sequence No",
            field: "sequence_no",
            width:100,
            filter: true,
            resizable: true,
            editable:false,
            hide:hiddenCourier
        },
        {
            headerName: "No Of Orders",
            field: "total_consignments",
            width:100,
            filter: true,
            resizable: true,
            editable:false,
            hide:hiddenRegular
        },
        {
            headerName: "Total Load (CMT)",
            field: "tripVolume",
            width:160,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Total Cost",
            field: "tripCost",
            width:180,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Transport Mode",
            field: "truck_capacity",
            width:200,
            filter: true,
            resizable: true,
            editable:false
        },  
        {
            headerName: "Distance (KM)",
            field: "tripDistance",
            width:180,
            filter: true,
            resizable: true,
            editable:false,
            hide:hiddenRegular
        },  
        {
            headerName: "Zone",
            field: "zone",
            width:160,
            filter: true,
            resizable: true,
            editable:false,
            hide:hiddenCourier
        },        
    ]

    var columnwithDefsForCouriers = [ 
        {
            headerName: "",
            field: "_id",
            width:50,
            filter: true,
            resizable: true,
            editable:false,
            cellRenderer: function(e){
                return '<i class="icofont icofont-map-pins f25"></i>'
            }

        },
        {
            headerName: "Details",
            field: "details",
            width:80,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter:function(params){
                return "View";
            },
        }, 
        {
            headerName: "Cluster",
            field: "cluster",
            width:100,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                return params.data.cluster
            }
        },
        {
            headerName: "Zone",
            field: "zone",
            width:80,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                return params.data.zone
            }
        },
        {
            headerName: "Dealer Code",
            field: "dealerCode",
            width:200,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Total Load (CMT)",
            field: "tripVolume",
            width:200,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Total Cost",
            field: "tripCost",
            width:200,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Transport Mode",
            field: "truck_capacity",
            width:250,
            filter: true,
            resizable: true,
            editable:false
        },
    ]

    var columnwithDefsForRecommended = [      
        {
            headerName: "Cluster",
            field: "cluster",
            width:100,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                return params.data.cluster+" - "+params.data.zone
            }
        },
        {
            headerName: "No Of Orders",
            field: "total_consignments",
            width:100,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Total Load (CMT)",
            field: "tripVolume",
            width:100,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Total Cost",
            field: "tripCost",
            width:100,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Current Transport Mode",
            field: "truck_capacity",
            width:130,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Recommended Transport Mode",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "recommendation",
            width:130,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Proposed Total CMT by Truck",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "prop_truck",
            width:150,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: NumericCellEditor,
            valueGetter: function(params){
                if(params.data.prop_truck == undefined)
                {
                    return 0;       
                }
                else
                {
                    return params.data.prop_truck
                }
            },
            valueSetter: function(params){
                //console.log(params)
                var newValue= params.newValue;
                var initial_load= params.data.tripVolume;
                if (parseFloat(newValue) > parseFloat(initial_load))
                {
                    return false;
                }
                else
                {
                    params.data.prop_truck = newValue;
                    var courier_cmt= Math.round((initial_load - newValue) * 100)/100;
                    
                    var cost_percmtcourier = params.data.courier_cost;
                    var courier_cost = courier_cmt * 212 * cost_percmtcourier;
                    var cost_percmt = params.data.proposed_truck_cost;
                    var truck_cost = newValue * cost_percmt;
                    var total_cost = courier_cost + truck_cost;

                    params.data.rec_courier = courier_cmt;
                    params.data.rec_courier_cost = Math.round(courier_cost * 100)/100;                   
                    params.data.rec_truck_cost = Math.round(truck_cost * 100)/100;
                    params.data.rec_total_cost = Math.round(total_cost * 100)/100;
                    return true;
                }
            }
        },
        {
            headerName: "Proposed Cost/CMT by Truck",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "proposed_truck_cost",
            width:150,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Proposed Total CMT by Courier",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "rec_courier",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Proposed Cost/Kg by Courier",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "courier_cost",
            width:150,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Proposed Cost Truck",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "rec_truck_cost",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            // valueGetter: function(params){
            //     return 0;
            // }
            valueGetter: function(params){
                if(params.data.rec_truck_cost == undefined)
                {
                    return 0;       
                }
                else
                {
                    return params.data.rec_truck_cost
                }
            },
        },
        {
            headerName: "Proposed Cost by Courier",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "rec_courier_cost",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Proposed Total Cost",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "rec_total_cost",
            width:130,
            filter: true,
            resizable: true,
            editable:false,
        },
    ]

    var columnwithDefsForDropped = [     
        
        {
            headerName: "Dealer Code",
            field: "dealerCode",
            width:350,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Total Load (CMT)",
            field: "tripVolume",
            width:350,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Dropped Reasons",
            field: "dropped_reasons",
            width:420,
            filter: true,
            resizable: true,
            editable:false
        }
    ]
    var columnwithDefsForClusterDetails = [ 
        {
            headerName: "S.No",
            field: "sno",
            width:80,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(e){
                return e.node.rowIndex+1;
            }

        },
        {
            headerName: "Consignee Code",
            field: "dealer_code",
            width:120,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "State",
            field: "state",
            width:120,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "City",
            field: "city",
            width:120,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Load CMT",
            field: "load_cmt",
            width:120,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Load CMT",
            field: "load_cmt",
            width:120,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Truck Capacity",
            field: "truck_capacity",
            width:120,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Transporter Name",
            field: "transporter_name",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
        },
    ]
    var columnwithDefsForTruckAvailability =[
        {
            headerName: "Transporter Name",
            field: "transporter_name",
            width:180,
            filter: true,
            resizable: true,
            editable:false,            
        },
        {
            headerName: "Transporter Code",
            field: "transporter_code",
            width:130,
            filter: true,
            resizable: true,
            editable:false,            
        },
        {
            headerName: "Vehicle Type",
            field: "vehicle_type",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Vehicle Number",
            field: "vehicle_number",
            width:130,
            filter: true,
            resizable: true,
            editable:false,
            cellClass:"cellstylegrid"
        },
        {
            headerName: "Punching Date",
            field: "punching_date",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter : function(params){
                if(params.data.punching_date !=undefined && params.data.punching_date !='')
                {
                    return getHyphenDDMMYYYYHHMMSS(params.data.punching_date);
                }
            }
        },
    ]

    var columnwithDefsFormanualVsSystemData =[
        {
            headerName: "Trip No (system)",
            field: "trip_no_system",
            // width:120,
            filter: true,
            resizable: true,
            editable:false,     
            cellClass:function(params){
                try {
                    if(params.data.adherence_status == true)
                    {
                        return "light-bg";
                    }
                    else{
                        return "light-err-bg";
                    }
                } catch (error) {
                    
                }
            },       
        },
        {
            headerName: "Trip No (manual)",
            field: "trip_no_manual",
            // width:120,
            filter: true,
            resizable: true,
            editable:false,
            cellClass:function(params){
                try {
                    if(params.data.adherence_status == true)
                    {
                        return "light-bg";
                    }
                    else{
                        return "light-err-bg";
                    }
                } catch (error) {
                    
                }
            },
        },
        {
            headerName: "Vehicle Type (system)",
            field: "vehicle_type_system",
            // width:130,
            filter: true,
            resizable: true,
            editable:false,     
            cellClass:function(params){
                try {
                    if(params.data.adherence_status == true)
                    {
                        return "light-bg";
                    }
                    else{
                        return "light-err-bg";
                    }
                } catch (error) {
                    
                }
            },       
        },
        {
            headerName: "Vehicle Type(manual)",
            field: "vehicle_type_manual",
            // width:150,
            filter: true,
            resizable: true,
            editable:false,
            cellClass:function(params){
                try {
                    if(params.data.adherence_status == true)
                    {
                        return "light-bg";
                    }
                    else{
                        return "light-err-bg";
                    }
                } catch (error) {
                    
                }
            },
        },
        // {
        //     headerName: "Loading Sequences (system)",
        //     field: "loading_sequence_system",
        //     // width:150,
        //     filter: true,
        //     resizable: true,
        //     editable:false,
        //     cellClass:function(params){
        //         try {
        //             if(params.data.adherence_status == true)
        //             {
        //                 return "light-bg";
        //             }
        //             else{
        //                 return "light-err-bg";
        //             }
        //         } catch (error) {
                    
        //         }
        //     },
        // },
        // {
        //     headerName: "Loading Sequence (manual)",
        //     field: "loading_sequence_manual",
        //     // width:150,
        //     filter: true,
        //     resizable: true,
        //     editable:false,
        //     cellClass:function(params){
        //         try {
        //             if(params.data.adherence_status == true)
        //             {
        //                 return "light-bg";
        //             }
        //             else{
        //                 return "light-err-bg";
        //             }
        //         } catch (error) {
                    
        //         }
        //     },
        // },
        {
            headerName: "Dealer Code",
            field: "dealer_code",
            // width:130,
            filter: true,
            resizable: true,
            editable:false,
            cellClass:function(params){
                try {
                    if(params.data.adherence_status == true)
                    {
                        return "light-bg";
                    }
                    else{
                        return "light-err-bg";
                    }
                } catch (error) {
                    
                }
            },
        },
        {
            headerName: "Planned Load",
            field: "demand_system",
            // width:130,
            filter: true,
            resizable: true,
            editable:false,
            cellClass:function(params){
                try {
                    if(params.data.adherence_status == true)
                    {
                        return "light-bg";
                    }
                    else{
                        return "light-err-bg";
                    }
                } catch (error) {
                    
                }
            },
        },
        {
            headerName: "Actual Load Dispatched",
            field: "demand_manual",
            // width:130,
            filter: true,
            resizable: true,
            editable:false,
            cellClass:function(params){
                try {
                    if(params.data.adherence_status == true)
                    {
                        return "light-bg";
                    }
                    else{
                        return "light-err-bg";
                    }
                } catch (error) {
                    
                }
            },
        },
        {
            headerName: "Pick Ticket",
            field: "pick_ticket",
            // width:130,
            filter: true,
            resizable: true,
            editable:false,
            cellClass:function(params){
                try {
                    if(params.data.adherence_status == true)
                    {
                        return "light-bg";
                    }
                    else{
                        return "light-err-bg";
                    }
                } catch (error) {
                    
                }
            },
        },
        {
            headerName: "Adherence Status",
            field: "adherence_status",
            // width:130,
            filter: true,
            resizable: true,
            editable:false,
            cellClass:function(params){
                try {
                    if(params.data.adherence_status == true)
                    {
                        return "light-bg";
                    }
                    else{
                        return "light-err-bg";
                    }
                } catch (error) {
                    
                }
            },
            valueGetter : function(params){
                if(params.data.adherence_status == true)
                {
                    return 'Adhered';
                }else{
                    return 'Not Adhered';
                }
            }
        },
    ]


    var columnwithDefsForMGPData =[
        {
            headerName: "Pick Ticket",
            field: "pict_no",
            // width:120,
            filter: true,
            resizable: true,
            editable:false, 
        },
        {
            headerName: "Consignee Code",
            field: "cons_id",
            // width:120,
            filter: true,
            resizable: true,
            editable:false, 
        },
        {
            headerName: "City",
            field: "city",
            // width:120,
            filter: true,
            resizable: true,
            editable:false, 
        },
        {
            headerName: "Pick Ticket Demand (CMT)",
            field: "pict_cmt",
            // width:120,
            filter: true,
            resizable: true,
            editable:false, 
        },
        {
            headerName: "MGP No.",
            field: "mgp_no",
            // width:120,
            filter: true,
            resizable: true,
            editable:false, 
        },
        {
            headerName: "Truck No.",
            field: "truck_no",
            // width:120,
            filter: true,
            resizable: true,
            editable:false, 
        },
        {
            headerName: "Vehicle Type",
            field: "vehicle_type",
            // width:120,
            filter: true,
            resizable: true,
            editable:false, 
        },
        {
            headerName: "Transporter Id",
            field: "tptr_id",
            // width:120,
            filter: true,
            resizable: true,
            editable:false, 
        },  
    ]

    var chkOutputFile = 0;
    var loading_port_code = this.state.loading_port_code;
    if(loading_port_code == "GUR-3")
    {
        if(this.state.rowData.length > 0)
        {        
            var data_processed = this.state.rowData[0].data_processed;
            console.log(data_processed,"data_processed")
            if(data_processed == 2)
            {
                var chkOutputFile = 1;
            }
            else
            {
                if(this.state.recommData.length == 0)
                {
                    var chkOutputFile = 1;
                }
            }
        }
    }
    else
    {
        var chkOutputFile = 1;
    }
    console.log(chkOutputFile,"chkOutputFile")
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="card col-xl-12">
            <div class="card-header">
                <h5>
                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Load Manager - {this.state.fileName}</span>
                    {(chkOutputFile == 1 && this.state.consolidatedFile == 1) ?
                    <a href="javascript:;" onClick={() => this.handleDownloadOutputFile(this.state.transactionId+"_"+"finalAssignFile_summary.csv")} style={{float:"right",marginRight:"10px"}} className="btn btn-warning"> Download Summary File</a>
                    : ""}
                    {(chkOutputFile == 1) ?
                    <a href="javascript:;" onClick={() => this.handleDownloadOutputFile(this.state.transactionId+"_"+"finalAssignFile.csv")} style={{float:"right",marginRight:"10px"}} className="btn btn-danger"> Download Output File</a>
                    : ""}
                    {(chkOutputFile == 1) ?
                    <a href={"/lmtruckavailability/"+window.btoa(this.state.transactionId)} style={{float:"right",marginRight:"10px"}} className="btn btn-info"> Truck Availability</a>
                    : 
                    <button type="button" className="btn btn-danger" onClick={this.onClickTab.bind(this,"recommendations")} style={{float:"right",marginRight:"10px"}}>Check Recommendations</button>
                    }
                </h5>
            </div>
            <div className="card-body col-xl-12 col-lg-12">
                <div className="row col-xl-12 col-lg-12" style={{marginBottom:"1%"}}>
                    <span style={{fontSize:"16px"}}>Loading Plant : {(this.state.comp_short_name)}</span>
                </div>
                <div className="f16" style={{marginBottom:"10px"}}>
                    <ul className="d-tabs">

                        <li onClick={this.onClickTab.bind(this,"config")} className={"lttabs "+(this.state.config)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"config")}>Configuration</button>
                        </li>

                        <li onClick={this.onClickTab.bind(this,"summary")} className={"lttabs "+(this.state.summary)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"summary")}>Summary</button>
                        </li>
                        {(this.state.data_type == 2) ?
                        <li onClick={this.onClickTab.bind(this,"trucks")} className={"lttabs "+(this.state.trucks)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"trucks")} href="">Planed Loads</button>
                        </li>
                        :
                        <li onClick={this.onClickTab.bind(this,"trucks")} className={"lttabs "+(this.state.trucks)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"trucks")} href="">Planed Loads By Trucks</button>
                        </li>
                        }
                        {(this.state.data_type != 2) ?
                        <li onClick={this.onClickTab.bind(this,"courier")} className={"lttabs "+(this.state.courier)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"courier")} href="">Planed Loads By Courier</button>
                        </li>
                        :""}
                        {(this.state.data_type != 2) ?
                        <li onClick={this.onClickTab.bind(this,"recommendations")} className={"lttabs "+(this.state.recommendations)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"recommendations")} href="">Recommendations</button>
                        </li>
                        :""}
                        <li onClick={this.onClickTab.bind(this,"dropped")} className={"lttabs "+(this.state.dropped)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"dropped")} href="">Loads Dropped</button>
                        </li>
                        
                        <li onClick={this.onClickTab.bind(this,"adherence")} className={"lttabs "+(this.state.adherence)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"adherence")}>Adherence</button>
                        </li>

                        <li onClick={this.onClickTab.bind(this,"mgp")} className={"lttabs "+(this.state.mgp)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"mgp")}>MGP Data</button>
                        </li>
                       
                    </ul>
                </div>
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.configData}>    
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefsForConfigs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.configurationData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClickedGrid.bind(this)}
                    />
                </div>
                <div className={"row mt-40p"+" "+this.state.summaryData}>
                    <div className="col-xl-6">
                        <div>
                            <LoadSummaryColumnChart
                            chartType="column"
                            title={"Orders Planned For Load"}
                            subtitle={""}
                            context={this}
                            series={this.state.seriesData}
                            categories={this.state.categories}
                            seriesName=""
                            yaxistitle="Orders For Load Plan"
                            drilldownseries={[]}
                            // dept_code={this.state.dept_code}
                            />
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div>
                            <LoadSummaryColumnChart
                            chartType="column"
                            title={"Cost Summary"}
                            subtitle={""}
                            context={this}
                            series={this.state.costData}
                            categories={this.state.costCategories}
                            seriesName=""
                            yaxistitle="Orders For Load Plan"
                            drilldownseries={[]}
                            // dept_code={this.state.dept_code}
                            />
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div>
                            <LoadSummaryColumnChart
                            chartType="pie"
                            title={"Dropped Nodes Summary"}
                            subtitle={""}
                            context={this}
                            series={this.state.drpData}
                            categories={this.state.drpCategories}
                            seriesName=""
                            yaxistitle="Orders For Load Plan"
                            drilldownseries={[]}
                            // dept_code={this.state.dept_code}
                            />
                        </div>
                    </div>
                    {(this.state.data_type != 2) ?
                    <div className="col-xl-6">
                        <div>
                            <LoadSummaryColumnChart
                            chartType="column"
                            title={"Actual Payable Cost Summary"}
                            subtitle={""}
                            context={this}
                            series={this.state.actcostData}
                            categories={this.state.actcostCategories}
                            seriesName=""
                            yaxistitle="Orders For Load Plan"
                            drilldownseries={[]}
                            // dept_code={this.state.dept_code}
                            />
                        </div>
                    </div>
                    :""}
                </div>
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.trucksData}>    
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.clustersData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClickedGrid.bind(this)}
                    />
                </div>
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.courierData}>
                <button type="button" className="btn btn-info" onClick={this.onClickShowAllCouriers.bind(this)} style={{marginBottom:"1em"}}>View All Couriers</button>  
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefsForCouriers}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.couriersData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClickedGrid.bind(this)}
                    />
                </div>
                
                <div class={this.state.recommendationsData}>
                    <LoadSummaryRecommondations recommData={this.state.recommData} transactionId={this.state.transactionId} loading_port_code={this.state.loading_port_code} chkOutputFile={chkOutputFile}/>
                </div>

                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.droppedData}>    
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefsForDropped}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.droppedPData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    />
                </div>
            </div>
        </div>
        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">{this.state.sliderTitle} <span className="ttlDuration"></span></h3>
            <div className="slide-r-body" style={{position:"relative"}}>     
                {(this.state.sliderType == 1) ?
                <div id="cluterDetailsGrid" style={{width:"100%",height:"450px"}} className={"ag-theme-balham"}>
                    <AgGridReact
                        modules={this.state.modules}
                        columnDefs={columnwithDefsForClusterDetails}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.clusterDetails}
                        enableCharts={false}
                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                        onGridReady={this.onGridReady}
                        onGridState={this.onGridState}
                        frameworkComponents={this.state.frameworkComponents}
                        statusBar={this.state.statusBar}
                        sideBar={this.state.sideBar}
                        stopEditingWhenGridLosesFocus= {true}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={true}
                        gridOptions={{
                            context: { componentParent: this }
                        }}
                        // components={this.state.components}
                        enableRangeSelection= {true}
                    />
                </div>
                :           
                <LoadSummaryDrawmap
                context={this} coords={this.state.latLngArray} loadingLocation={this.state.loadingLocation} stepcoords={1} mapType={this.state.mapType} mapFor={"load_management"}  /> 
                }
            </div>
        </div>
        <div className={"slide-r "+(this.state.bulkslide1)} style={{overflow:"hidden"}}>
            <h3 className="subH">{this.state.sliderTitle} <span className="ttlDuration"></span></h3>
            <div className="slide-r-body" style={{position:"relative"}}>     
               
                <div id="cluterDetailsGrid" style={{width:"100%",height:"450px"}} className={"ag-theme-balham"}>
                    <AgGridReact
                        modules={this.state.modules}
                        columnDefs={columnwithDefsForTruckAvailability}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.filteredTruckDataforTruckAvailability}
                        enableCharts={false}
                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                        onGridReady={this.onGridReady}
                        onGridState={this.onGridState}
                        frameworkComponents={this.state.frameworkComponents}
                        statusBar={this.state.statusBar}
                        sideBar={this.state.sideBar}
                        stopEditingWhenGridLosesFocus= {true}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={true}
                        gridOptions={{
                            context: { componentParent: this }
                        }}
                        // components={this.state.components}
                        enableRangeSelection= {true}
                    />
                </div>
                
            </div>
        </div>

        <div className={"row mt-10p"+" "+this.state.adherenceData}>
        <div className='col-sm-12 d-flex justify-content-between pb-5px'>
        { this.state.adherenceTransactionDetails.length > 0 ? <span>Last Calculated Stats : {this.state.last_calculated_date}</span>:''}
        <button onClick={this.calculateadherencedata} className=" btn btn-primary">Calculate Adherence Stats</button>
        </div>
        {(this.state.adherenceTransactionDetails.length > 0) ?
            <>      
                {/* <div className="col-sm-12 mt-20p mb-3">
                  <div className="col-xl-12 col-lg-12 col-sm-12 beffect ">
                    <div className="card">
                      <div className="card-body">
                        <div className="crm-numbers pb-0">
                          <div className="row">
                            <div className="col cursorPointer">
                              <span className="f13">
                                
                                <br /> Trips Adherence %
                              </span>
                              <h4 className="darkgreen f30">
                                <span className="counter">
                                  {this.state.percent_adhered_trips.toFixed(2)}
                                </span>
                              </h4>
                            </div>
                            <div className="col cursorPointer">
                              <span className="f13">
                                <br /> Truck Adherence %
                              </span>
                              <h4 className="darkgreen f30">
                                <span className="counter">
                                  {this.state.percent_truck_adherence.toFixed(2)}
                                </span>
                              </h4>
                            </div>

                            <div className="col cursorPointer">
                              <span className="f13">
                                <br /> Manual Cost
                              </span>
                              <h4 className=" txt-primary f30">
                                <span className="counter">
                                  {this.state.cost_manual.toFixed(2)}
                                </span>
                              </h4>
                            </div>
                            <div className="col cursorPointer">
                              <span className="f13">
                                <br /> System Cost
                              </span>
                              <h4 className=" darkgreen f30">
                                <span className="counter">
                                  {this.state.cost_system.toFixed(2)}
                                </span>
                              </h4>
                            </div>
                            <div className="col cursorPointer">
                              <span className="f13">
                                <br /> Load (CMT)
                              </span>
                              <h4 className=" txt-success f30">
                                <span className="counter">
                                  {this.state.load.toFixed(2)}
                                </span>
                              </h4>
                            </div>
                            {this.state.demand_system != undefined ?
                            <div className="col cursorPointer" title='Load planned as per system output for dispatched pick tickets'>
                              <span className="f13">
                                <br /> Planned Load
                              </span>
                              <h4 className="darkgreen f30">
                                <span className="counter">
                                  {this.state.demand_system.toFixed(2)}
                                </span>
                              </h4>
                            </div>
                            :''}
                            
                            {this.state.demand_manual != undefined ?
                            <div className="col cursorPointer" title='Actual Load through dispatched pick tickets as per MGP Data'>
                              <span className="f13">
                                <br /> Actual Load Dispatched
                              </span>
                              <h4 className="darkgreen f30">
                                <span className="counter">
                                  {this.state.demand_manual.toFixed(2)}
                                </span>
                              </h4>
                            </div>
                            :''}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    </div>     */}


                <div className="col-xl-12 col-lg-12 col-sm-12 beffect ">
                    <div className="card"><div className="card-body"><div className="crm-numbers pb-0">
                        
                <div className="row">
                <div className= {this.state.total_pick_tickets == undefined ? "col-md-6" : "col-md-4"}>
                    <table className="table table-bordered">
                        <thead>
                            
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{borderRight:"1px solid lightgrey"}}><div className="col cursorPointer"><span className="f13"><br/> Trips Adherence %</span><h4 className="txt-pink-light f30"><span className="counter">{this.state.percent_adhered_trips.toFixed(2)}</span></h4></div></td>
                                <td style={{borderRight:"1px solid lightgrey"}}><div className="col cursorPointer"><span className="f13"><br/> Manual Cost (₹)</span><h4 className="txt-primary f30"><span className="counter">{this.state.cost_manual}</span></h4></div></td>
                            </tr>
                            <tr>
                                <td style={{borderRight:"1px solid lightgrey"}}><div className="col cursorPointer"><span className="f13"><br/>Truck Adherence % </span><h4 className="txt-pink-light f30"><span className="counter">{this.state.percent_truck_adherence.toFixed(2)}</span></h4></div></td>
                                <td style={{borderRight:"1px solid lightgrey"}}><div className="col cursorPointer"><span className="f13"><br/> System Cost (₹)</span><h4 className="txt-primary f30"><span className="counter">{this.state.cost_system}</span></h4></div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <div className= {this.state.total_pick_tickets == undefined ? "col-md-6" : "col-md-4"} style={{borderRight:"1px solid lightgrey"}}>
                    <table className="table table-bordered">
                        <thead>
                            
                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="2"><div className="col cursorPointer"><span className="f13"><br/> Load (CMT)</span><h4 className="txt-success f30"><span className="counter">{this.state.load.toFixed(2)}</span></h4></div></td>
                            </tr>
                            <tr>
                            {this.state.demand_system != undefined ?
                                <td><div className="col cursorPointer"><span className="f13"><br/> Planned Load (CMT)</span><h4 className="txt-success f30"><span className="counter">{this.state.demand_system.toFixed(2)}</span></h4></div></td>
                              :<td><div className="col cursorPointer"><span className="f13"><br/> Planned Load (CMT)</span><h4 className="txt-success f30"><span className="counter">{this.state.load.toFixed(2)}</span></h4></div></td>}  
                              {this.state.demand_manual != undefined ?
                                <td><div className="col cursorPointer"><span className="f13"><br/> Actual Load Dispatched (CMT)</span><h4 className="txt-success f30"><span className="counter">{this.state.demand_manual.toFixed(2)}</span></h4></div></td>
                                :<td><div className="col cursorPointer"><span className="f13"><br/> Actual Load Dispatched (CMT)</span><h4 className="txt-success f30"><span className="counter">{this.state.load.toFixed(2)}</span></h4></div></td>}
                                </tr>
                        </tbody>
                    </table>
                </div>
                {this.state.total_pick_tickets != undefined ?
                <div className="col-md-4">
                    <table className="table table-bordered">
                        <thead> 
                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="2"><div className="col cursorPointer"><span className="f13"><br/> Total Pick Tickets</span><h4 className="txt-secondary f30"><span className="counter">{this.state.total_pick_tickets}</span></h4></div></td>
                            </tr>
                            <tr>
                                <td><div className="col cursorPointer"><span className="f13"><br/> Pick Tickets Received</span><h4 className="txt-secondary f30"><span className="counter">{this.state.pick_tickets_received}</span></h4></div></td>
                                <td><div className="col cursorPointer"><span className="f13"><br/>Pick Tickets Remaining</span><h4 className="txt-secondary f30"><span className="counter">{this.state.pick_tickets_remaining}</span></h4></div></td>
                            </tr>
                        </tbody>
                        
                    </table>
                </div>
                :''}
                </div>
                </div></div></div></div>                         

                    <div id='percentChart1' className="col-sm-6">
                        <div id='percentChart'>
                        <AdherencePercentBarChart data={this.state.adherencePercentData} />
                        </div>
                    </div>
                    <div id="tripChart1" className="col-sm-6">
                        <div id="tripChart">
                        <TripBarChart data={this.state.adherenceTripData} />
                        </div>
                    </div>
                    
                    <div id='costChart1' className="col-sm-6 mt-2">
                        <div id='costChart'>
                        <CostBarChart data={this.state.adherenceCostData} />
                        </div>
                    </div>

                    <div id='demandChart1' className="col-sm-6 mt-2">
                        <div id='demandChart'>
                        <DemandBarChart data={this.state.adherenceDemandData} />
                        </div>
                    </div>
                    <div className='col-sm-12 mt-10p'>
                        <h4>Adherence Data Table</h4>
                    <div id="cluterDetailsGrid" style={{width:"100%",height:"550px",marginTop:'10px'}} className={"ag-theme-balham"}>
                    <AgGridReact
                        modules={this.state.modules}
                        columnDefs={columnwithDefsFormanualVsSystemData}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.manualVsSystemData}
                        enableCharts={false}
                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                        onGridReady={this.onGridReady}
                        onGridState={this.onGridState}
                        frameworkComponents={this.state.frameworkComponents}
                        statusBar={this.state.statusBar}
                        // sideBar={this.state.sideBar}
                        stopEditingWhenGridLosesFocus= {true}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={true}
                        gridOptions={{
                            context: { componentParent: this }
                        }}
                        // components={this.state.components}
                        enableRangeSelection= {true}
                    />
                </div>
                </div>
            </>
            : <div className='col-sm-12'> <h4>No Data</h4></div>}
                    
        </div>

            <div className={"row mt-10p"+" "+this.state.mgpdata}>
            <span className='col'>Last Synced Time : {this.state.last_synced_at}</span>
               <div className="col-sm-12 mt-20p mb-3">
                  <div className="beffect ">
                    <div className="card">
                      <div className="card-body">
                        <div className="crm-numbers pb-0">
                          <div className="row">
                            <div className="col cursorPointer">
                              <span className="f13">
                                
                                <br /> Total Pick Tickets
                              </span>
                              <h4 className="darkgreen f30">
                                <span className="counter">
                                  {this.state.mgp_total_pick_tickets}
                                </span>
                              </h4>
                            </div>
                            <div className="col cursorPointer">
                              <span className="f13">
                                <br /> Pick Tickets Received
                              </span>
                              <h4 className="darkgreen f30">
                                <span className="counter">
                                  {this.state.mgp_pick_tickets_received}
                                </span>
                              </h4>
                            </div>

                            <div className="col cursorPointer">
                              <span className="f13">
                                <br /> Pick Tickets Remaining
                              </span>
                              <h4 className=" txt-primary f30">
                                <span className="counter">
                                  {this.state.mgp_pick_tickets_remaining}
                                </span>
                              </h4>
                            </div>                                                    
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    </div>
                <div className='col-sm-12 mt-10p'>
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "}>
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefsForMGPData}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.mgprowdata}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClickedGrid.bind(this)}
                    />
                </div>
                </div>
            </div>
                
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
        </div>

    );
  }
}

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

function GetSortDescOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return -1;    
        } else if (a[prop] < b[prop]) {    
            return 1;    
        }    
        return 0;
    }    
  }

  function formatIndianNumber(number) {
    let x = number.toFixed(0).toString();
    let lastThree = x.substring(x.length - 3);
    let otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers !== '') {
        lastThree = ',' + lastThree;
    }
    let result = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return result;
  }
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

export default class UserLogDashboardChart extends Component {

    constructor(props) {
        super(props);
    }

    render(){
    	
        const dStyles={
				width:'100%',
				height:'100%'
		}
		const highchartoptions = {
	      		  chart: {
	      		    type: 'column'
	      		  },
	      		  plotOptions: {
		      	      column: {
		      	          colorByPoint: true
		      	      },
		      	      series: {
		      	    	maxPointWidth: 20
		              }
	      		  },
	      		  title:{
	      			  text:this.props.xTitle
	      		  },
	      		xAxis: {
					type:'category'
	      	     },
				yAxis: {
					min: 0,
					title: {
						text: this.props.yLabel
					}
				},
				  legend: {
					enabled: false
				},
				plotOptions: {
					series: {
						borderWidth: 0,
						colorByPoint:true,
						dataLabels: {
							enabled: true,
							format: '{point.y}'
						}
					}
				},
				series: this.props.series,
	      		colors:this.props.colorcodes,
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: false
	                        }
	                    }
	                }]
	      		  }
	      		};
        
        return ( 
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />

			);
		}
}













import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import CreatableSelect from 'react-select/creatable';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import Select from 'react-select';
import UpdateItem from './updaterow';
import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import axios from 'axios';
import Modal from 'react-responsive-modal';
import $ from 'jquery';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');


export default class LSPDetails extends Component {

	constructor(props){
		super(props);
		this.state = {
			currentDepartmentTitle: null,
			modules: AllModules,
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
      	    defaultColDef: {
      	        sortable: true,
      	        filter: true,
				editable: true,
				resizable: true
      		},
			rowData: [],

			editType: "fullRow",
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
		 
			frameworkComponents: {
				updateItem:UpdateItem,
                deleteItem:DeleteItem
				//statusChange:StatusChange
			},

			rowModelType: "serverSide",
			paginationPageSize:100,
			cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters:[],
            sliderRso:"",
            sliderUpdate:"",
            transporter:"",
            truck_no:"",
            trucktype:"",
            deptcode:"",
            sources:[],
            destinations:[],
            sourceopt:"",
            oem_code:"",
            oem_name:"",
            industry_type:"",
            officer_mobile:"",
            officer_alteratemobile:"",
            officer_locations:[],
            officertype:"",
            rowid:"",
            cha:{ "value": "Select CHA", "label": "Select CHA" },
            freight_forwader:{"value":"Select Freight Forwader", "label":"Select Freight Forwader"},
            transporter:{"value":"Select Transporter", "label":"Select Transporter"},
            sliderBulk:"",
            file:"",
            Chalist:[],
            freight_list:[],
            transporter_list:[],
            incoterms :"",
            email : "",
            ff_email_id : "",
            transporter_email_id : "",
            destination_port:"",
            dispatch_location:"",
            rowIdData:"",
			csvcontent:[],
            name:'',
            billing_month:"",
            remarks_history_cols:[
                {
	  	          headerName: "Billing Id",
	  	          field: "billing_id",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                
		        {
	  	          headerName: "Remark",
	  	          field: "complete_remarks",
	  	          width: 180,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
	  	          headerName: "Updated Time",
	  	          field: "remark_time",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                  valueGetter:function(params){
                    try{
                        if(params.data.remark_time != '' && params.data.remark_time != undefined)
                        {
                            return getHyphenYYYYMMDDHHMMSS(params.data.remark_time)
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
                },

            ],
            remarks_data:[],

		};
        
        this.updateRowHandler = this.updateRowHandler.bind(this);
        this.deleteRowHandler = this.deleteRowHandler.bind(this);
        // this.onLoadData = this.onLoadData.bind(this);
	}
	getCurrentDepartmentCode(pathToMatch = null) {
		let department = null;
		let departmentName = null;
		switch(pathToMatch) {
			case "/tptoemmaster":
				department='SNDG';
				departmentName = " ";
				break;
			
			default:
				console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			currentDepartmentTitle:departmentName
		});
		return department;
	}
	componentDidMount(){
		if (googleAnalytics.page.enableGA) {
			this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

        }
        
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        redirectURL.post("/consignments/fetchLspDetailsData")
        .then((response) => {
            console.log(response, 'response from new collection');
            var records = response.data.data;
    
            if (records.length > 0) {
                // Filter out rows where the remark is not 'delete'
                const filteredRecords = records.filter(record => record.remark !== 'delete');
    
                this.setState({
                    rowData: filteredRecords,
                    loadshow: "show-n",
                    overly: "show-n"
                });
            } else {
                this.setState({
                    loadshow: "show-n",
                    overly: "show-n"
                });
            }
        });
    
        // redirectURL.post("/consignments/getCHAData")
        // .then((reponse)=>{
        //     console.log(reponse.data.data,"177")
        //     var records = reponse.data.data;
        //     var cha_data = []
        //     if(records.length >0){
        //         records.map((item)=>{
        //             cha_data.push({
        //                 value : item.supplier_code,
        //                 label : item.supplier_name
        //             })
                   
        //         })
        //     }
        //     this.setState({
        //         Chalist:cha_data,
        //         freight_list:cha_data,
        //         transporter_list:cha_data,
        //     })
        // })
       
		// get department code to filter records by department
		let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
		this.setState({
			departmentCode:currentDepartmentCode
		});
		 /*Consignments List*/		
	//    this.onLoadData();
	  
    };
    // onLoadData(){
    //     this.setState({
    //         overly: "show-m",
    //         loadshow: "show-m"
    //     })
    //     var transporter_code = localStorage.getItem('transportercode')
    //     console.log(transporter_code,"transporter_code");
        
    //     let bmonth = {
            
    //     }
    //     if(transporter_code.length !== 0 && transporter_code !== undefined && transporter_code !==""){
    //         bmonth.transporter_code=transporter_code;
    //     }
    //     redirectURL.post('/dashboard/billingSummary',bmonth).then((response) => {
    //       var records = response.data;
    //     //   records = records.sort(GetSortDescOrder("billing_id"));
    //     let respData = JSON.parse(records.otpt)
    //       console.log("records ",respData )
    //       if(respData.length !== 0){
    //           this.setState({
    //               rowData:respData,
    //               overly: "show-n",
    //               loadshow: "show-n"
    //           });
    //       }
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //   });
     
    // }
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }

	onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
    };
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);
           
         
          
    }

    onClickUpdateRowHandler(event)
	{
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
           let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
           let pageTitle = "Update OEM Master Data";
           if (currentDepartmentTitle) {
               pageTitle = "Update OEM Master Data" +  currentDepartmentTitle;
           }
           
           let eventOptions = {
               "category": pageTitle,
               "action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
               "label": googleAnalytics.page.action.rowUpdated,
           }
           googleAnalytics.logEvent(eventOptions);
       }
      
       if(this.state.oem_name != "" && this.state.oem_code != ""
       && this.state.industry_type != "")
       {
           var params = {
               rowID:this.state.rowid,
               oem_code:this.state.oem_code,
               oem_name:this.state.oem_name,
               industry_type:this.state.industry_type,
               transporter_code:localStorage.getItem("transportercode")
           }
           redirectURL.post("/dashboard/updateoemitem", params,{
               headers:{
                   'Content-Type': 'application/json'
               }
           }).
           then(
               (response)=>{
                   //console.log("update response is : ",response.data);
                   //console.log("Update Response ",response.data.result)
                   if(response.data.status == "success")
                   {
                       this.setState({
                           show: true,
                           basicTitle:'Successfully update data',
                           basicType:"success",
                           sliderUpdate:"",
                           overly:"show-n"
                       });
                    //    this.onLoadData();
                   }
                   
                   else{
                       this.setState({
                           show: true,
                           basicTitle:'Something went wrong',
                           basicType:"danger",
                       });
                   }
                   
               }
           )
           .catch(function(error){
               console.log(error);
           });
       }
       else{
           this.setState({
               show: true,
               basicTitle:'All * fields should not be empty',
               basicType:"danger",
           });
       }

    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
        console.log(event,"event");
        var d = new Date(event._d);
        // console.log('startDate',d, 'changed date', moment.parseZone(event._d).format('YYYY-MM'))
        var startdate =  moment.parseZone(event._d).format('YYYY-MM')

        this.setState({
            selection_month: startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }



    
    onClickNewAddRow(event)
	{
        event.preventDefault();
		 if (googleAnalytics.page.enableGA) {
			let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
			let pageTitle = "Add OEM Master Data";
			if (currentDepartmentTitle) {
				pageTitle = "Add OEM Master Data" +  currentDepartmentTitle;
			}
			
			let eventOptions = {
				"category": pageTitle,
				"action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
				"label": googleAnalytics.page.action.rowUpdated,
			}
			googleAnalytics.logEvent(eventOptions);
        }
        if(this.state.oem_name != "" && this.state.oem_code != ""
       && this.state.industry_type != "")
        {
           var params = {
                oem_code:this.state.oem_code,
                oem_name:this.state.oem_name,
                industry_type:this.state.industry_type,
                transporter_code:localStorage.getItem("transportercode")
            }
            var fdata = {
                    rownode:params,
                    transporter_code:localStorage.getItem("transportercode")
            }
            redirectURL.post("/dashboard/saveoemitem", params,{
                headers:{
                    'Content-Type': 'application/json'
                }
            }).
            then(
                (response)=>{
                    //console.log("update response is : ",response.data);
                    //console.log("Update Response ",response.data.result)
                    if(response.data.status == "success")
                    {
                        this.setState({
                            show: true,
                            basicTitle:'Successfully added data',
                            basicType:"success",
                            sliderRso:"",
                            overly:"show-n",
                            loadshow:"show-n"
                        });
                        // this.onLoadData();
                    }
                    
                    else{
                        this.setState({
                            show: true,
                            basicTitle:'Something went wrong',
                            basicType:"danger",
                        });
                    }
                    
                }
            )
            .catch(function(error){
                console.log(error);
            });
        }
        else{
            this.setState({
                show: true,
                basicTitle:'All * fields should not be empty',
                basicType:"danger",
            });
        }

    }
    
    /*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

    
	 onAddRow() {
        // var newItem = 
        // {
        //     "truck_no":"",
        //     "truck_type":"",
        //     "transporter_code":"",
        //     "dept_code":""
        // };
        // console.log("newItem",newItem);
        // var res = this.gridApi.updateRowData({ add: [newItem] });
        this.setState({
			sliderRso:'slider-translate',
            overly:'show-m',
            oem_code:"",
            oem_name:"",
            industry_type:""
		})
        
    }
    onRowClicked(cell){
        if(cell.colDef.field == "edit_data")
        {
            var rowId = cell.data._id
            var email  = cell.data.email
            var name = cell.data.name
            // var transporter_email_id = cell.data.transporter_email_id
            var cha  = cell.data.cha
            var freight_forwader = cell.data.freight_forwader
            var transporter = cell.data.transporter
            // var cha_name = cell.data.cha
            // var freight_forwader_name = cell.data.freight_forwader_name
            // var transporter_name = cell.data.transporter_name
          this.setState({
            sliderBulk : "slider-translate",
            overly : "show-m",
            rowIdData : rowId,
            name:name,
            cha : {"label":cha,"value":cha},
            freight_forwader :{"label":freight_forwader,"value":freight_forwader},
            transporter : {"label":transporter,"value":transporter},
            email :email,
            // ff_email_id :ff_email_id,
            // transporter_email_id : transporter_email_id,
          })
        }
        if(cell.colDef.field == "delete_data")
        {
            console.log(cell.data._id,'cell.data._id')
            var rowId = cell.data._id
            var remark = "delete"
            var deleted_by = localStorage.getItem("email")
          var params= {
            rowId : rowId,
            remark : remark,
            deleted_by : deleted_by
          }
          redirectURL.post('/consignments/deletelsprowData',params).then((response)=>{
                if(response.data.message =="success"){
                    this.setState({
                        basicTitle :"Data successfully Deleted",
                        show:true,
                        basicType:"success"
                    })
                    this.componentDidMount()
                }else{
                    this.setState({
                        basicTitle :"Data not Deleted",
                        show:true,
                        basicType:"warning"
                    })
                }
          })
        }
        
        
    }
    
    
	 updateRowHandler = async(cell) => {
       
        var rowid = cell._id;
        var oem_code = cell.oem_code;
        var oem_name = cell.oem_name;
        var industry_type = cell.industry_type;
        await this.setState({
			sliderUpdate:'slider-translate',
            overly:'show-m',
            oem_code:oem_code,
            oem_name:oem_name,
            industry_type:industry_type,
            rowid:rowid
		})
        
    }


    
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            sliderRso:"",
            sliderUpdate:"",
            sliderBulk:""
		});
		
    }

    onChangeTruckType(trucktype){
		this.setState(
			{ trucktype },
			() => console.log(`Option selected:`, this.state.trucktype)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    changeHandler = (event) => {
		// console.log(event.target.name,"Select", event.target.value);
		let name = event.target.name;
		let value = event.target.value;
		if(name == "driver_mobile_num" || name == "transit_days" )
        {
            if(value !="" && value !=undefined)
            {
                if(value > 0)
                {
                    this.setState({[name]:value.replace(/\D/g,'')});
                }
            }
            else
            {
                this.setState({[name]:value.replace(/\D/g,'')});
            }
        }
        else
        {
            this.setState({[name]:value});
    	}
		// this.setState({ [name]: value });
	}
   
    onClickGenerateBill(){
        this.setState({
            overly: "show-m",
            loadshow: "show-m"
        })
       
        var billing_getter = this.state.selection_month;
        console.log(billing_getter,"billing_getter");
        
        if(billing_getter !== "" && billing_getter !== undefined){
            var params={                
                billing_month:billing_getter,              
            }
            console.log(params,"params");
            redirectURL.post('/dashboard/selectingbillingmonth',params)
            .then((response) => {

                var records = JSON.parse(response.data.otpt);
                // records = records.sort(GetSortDescOrder("billing_id"));
                console.log("recordsbilling ", records)
                
                this.setState({
                    rowData: records,
                    sliderBulk: "",
                    overly: "show-n",
                    loadshow: "show-n"
                });
                // window.location.href="/billingsummary" 
            })
            .catch(function (error) {
                console.log(error);
            });
     
        // }
    }
}
onChangePlantItem(cha) {
    this.setState(
        { cha },
        // () => console.log(`Plant Option selected:`, this.state.plant_code)
    );

    this.setState({
        errorMessage: '',
        successMessage: ''
    })
}
onChangeConsigneeItem(freight_forwader) {
    this.setState(
        { freight_forwader },
        // () => console.log(`Code  Option selected:`, this.state.consignee_code)
    );

    this.setState({
        errorMessage: '',
        successMessage: ''
    })
}
onChangeTransporterItem(transporter) {
    this.setState(
        { transporter },
        // () => console.log(`Code  Option selected:`, this.state.consignee_code)
    );

    this.setState({
        errorMessage: '',
        successMessage: ''
    })
} 
onChangeRecks = (e) => {
    let quantity = $("#Quantity").val();
    // console.log("e",e)
    this.setState({ reck_number: e })
    // if(e !== null){
    //     if(e.length == quantity){
    //         this.setState({ vin_no: e })
    //     }       
    //     else{
    //         this.setState({
    //             basicTitle : "Please Enter VinNo's matching Quantity",
    //             basicType : "warning",
    //             show : true
    //         })
    //     }
    // }
} 
    onChangeTransporter(transporter){
		this.setState(
			{ transporter },
			() => console.log(`Option selected:`, this.state.transporter)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    onChangeDepartment(deptcode){
		this.setState(
			{ deptcode },
			() => console.log(`Option selected:`, this.state.deptcode)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    deleteRowHandler(cell)
	{
		//  console.log("Here",cell);
		 if (googleAnalytics.page.enableGA) {
			let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
			let pageTitle = "OEM Master Data";
			if (currentDepartmentTitle) {
				pageTitle = "OEM Master Data" +  currentDepartmentTitle;
			}
			
			let eventOptions = {
				"category": pageTitle,
				"action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
				"label": googleAnalytics.page.action.rowUpdated,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		var fdata = {
	    		rownode:cell
	    }
	    redirectURL.post("/dashboard/deleteoemitem", fdata,{
			headers:{
				'Content-Type': 'application/json'
			}
		}).
		then(
			(response)=>{
				//console.log("update response is : ",response.data);
				//console.log("Update Response ",response.data.result)
				if(response.data.status == "success")
				{
					this.setState({
						show: true,
						basicTitle:'Successfully delete item',
						basicType:"success",
                        loadshow:"show-n",
                        overly:"show-n",
                        sliderRso:""
                    });
                    // this.onLoadData();
				}
				
				else{
					this.setState({
						show: true,
						basicTitle:'Something went wrong',
						basicType:"danger",
					});
				}
				
			}
		)
		.catch(function(error){
			console.log(error);
		});

    }
     
    onChangeSourceItem(sourceopt){
		this.setState(
			{ sourceopt },
			() => console.log(`Option selected:`, this.state.sourceopt)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    onChangeOfficerType(officertype){
		this.setState(
			{ officertype },
			() => console.log(`Option selected:`, this.state.officertype)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    selectsources(){
		let items = [];    
		try{
			if(this.state.sources.length > 0)
			{
				this.state.sources.map((item) =>{
					items.push({"value":item.geofence_name,"label":item.geofence_name})
				})
			}
		}
		catch(e){

		}		
		return items;
    }
    
    onClickShowBulk()
    {
        this.setState({
            overly:"show-m",
			sliderBulk:'slider-translate',
        })
    }
    

	changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'oem_name',
					inputName: 'oem_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'oem_code',
					inputName: 'oem_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'industry_type',
					inputName: 'industry_type',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		
	}
    changeHandlercondition = (event) => {
        const inputChar = String.fromCharCode(event.charCode);
        console.log(event.target.value,'valueeee',inputChar)
        if (!/^[A-Z\s]*$/.test(inputChar)) {
            event.preventDefault(); // Prevent the character from being entered
        }
    };
    onSubmiteditDetails(event)
    {
        event.preventDefault()
        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Inserting LSP Details",
				"action": "form",
				"label": "Inserting LSP Details",
			}
			googleAnalytics.logEvent(eventOptions);
        }
        this.setState({
                loadshow:"show-m",
        })

        function createShortName(name) {
            const nameArray = name.split(' '); // Split the name into an array of words
            const shortNameArray = nameArray.map(word => word.charAt(0)); // Extract the first letter of each word
            const shortName = shortNameArray.join(''); // Join the first letters back together
            return shortName;
        }
        
       
        var name = this.state.name
        var short_name = createShortName(name);
        
        var cha = this.state.cha.value
        // var cha_name = this.state.cha.label
        var freight_forwader = this.state.freight_forwader.value
        // var freight_forwader_name = this.state.freight_forwader.label
        var transporter = this.state.transporter.value
        // var transporter_name = this.state.transporter.label
        var email = this.state.email
        // var ff_email_id = this.state.ff_email_id
        // var transporter_email_id = this.state.transporter_email_id
        var rowIdData = this.state.rowIdData
        var formData ={
            name : name,
            cha : cha,
            short_name:short_name,
            // cha_name : cha_name,
            freight_forwader : freight_forwader,
            // freight_forwader_name : freight_forwader_name,
            transporter : transporter,
            // transporter_name : transporter_name,
            email : email,
            // ff_email_id : ff_email_id,
            // transporter_email_id : transporter_email_id,
            rowIdData : rowIdData,
        }
        console.log(formData,"formData")
        // if(cha != "" && cha != "Select CHA" &&  freight_forwader != "" && freight_forwader != "Select Freight Forwader" && transporter != "Select Transporter" && transporter != "" && email != "" && ff_email_id != "" && transporter_email_id != ""){
        redirectURL.post("/consignments/editLspDetails",formData).then((response)=>{
            console.log(response.data,"901")
            if(response.data.message == "Success"){
                this.setState({
                    show: true,
                    basicType: "success",
                    basicTitle: "Data Successfully Updated",
                    loadshow:"show-n",
                    overly:"show-n",
                    sliderBulk :"",
                })
                this.componentDidMount()
            }else{
                this.setState({
                    show: true,
                    basicType: "warning",
                    basicTitle: "Data Not Updated",
                    loadshow:"show-n",
                    overly:"show-n",
                    sliderBulk:""
                })
            }
        })

        // }else{
        //     this.setState({
        //         show: true,
        //         basicType: "danger",
        //         basicTitle: "All fields are mandatory",
        //         loadshow:"show-n",
        //         overly:"show-n",
        //     })
        // }
    }
    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
        var transporters = []
        var trans = this.state.transporters;
        if(trans.length > 0)
        {
            trans.map((item) => {
                transporters.push(item.value)
            })
        }
		const {usermanualmodal} = this.state;
		var hideChilds = true;
		if(this.state.departmentCode == "LOG-PRT")
		{
			hideChilds = false
		}
		const columnwithDefs = [
                {
	  	          headerName: "Name",
	  	          field: "name",
	  	          width: 200,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                // {
	  	        //   headerName: "Destination Port",
	  	        //   field: "destination_port",
	  	        //   width: 170,
                //   editable:false,
	    	    //   filter: "agSetColumnFilter",
                // },
                // {
	  	        //   headerName: "Incoterms",
	  	        //   field: "incoterms",
	  	        //   width: 210,
                //   editable:false,
	    	    //   filter: "agSetColumnFilter",
                // },
                // {
	  	        //   headerName: "Country",
	  	        //   field: "country",
	  	        //   width: 210,
                //   editable:false,
	    	    //   filter: "agSetColumnFilter",
                // },
		        {
	  	          headerName: "CHA Type",
	  	          field: "cha",
	  	          width: 170,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
	  	          headerName: "Freight Forwader Type",
	  	          field: "freight_forwader",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
	  	          headerName: "Transporter Type",
	  	          field: "transporter",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
                    headerName: "Email Id's",
                    field: "email",
                    width: 180,
                    filter: "agSetColumnFilter",
                    editable:false
		        },
                // {
                //     headerName: "Freight Forwader Email Id's",
                //     field: "ff_email_id",
                //     width: 220,
                //     filter: "agSetColumnFilter",
                //     editable:false
		        // },
                // {
                //     headerName: "Transporter Email Id's",
                //     field: "transporter_email_id",
                //     width: 200,
                //     filter: "agSetColumnFilter",
                //     editable:false
		        // },
                {
                    headerName:"Edit Data",
                    field:"edit_data",
                    width:140,
                    cellRenderer: function(params){
                                let basestring = params.data.batch_name;
                                let encryptedstring = window.btoa(basestring);
                                var htmloption = '<button class="btn btn-warning" style="padding-top:0px">Edit</button>';
                                return htmloption;
                            },
                },
                {
                    headerName:"Delete Data",
                    field:"delete_data",
                    width:150,
                    cellRenderer: function(params){
                                let basestring = params.data.batch_name;
                                let encryptedstring = window.btoa(basestring);
                                var htmloption = '<button class="btn btn-danger" style="padding-top:0px">Delete</button>';
                                return htmloption;
                            },
                },
	    ];
		return(
			<div className="container-fluid">
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        > 
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span> LSP Details </span>
                                       {/* <button type="button" onClick={this.onAddRow.bind(this)} className="btn btn-warning float-right">Add New Row</button> */}
                                        {/* <button href="/tptlogin/billingsummary" type="button" onClick={this.onClickGenerateBill.bind(this)} className="btn btn-warning float-right">Generate Bill</button> */}
                                        <a href="/addlspdetails" className="btn btn-success float-right">Add LSP Details</a>
                                </h5>
                            </div>
                            {/* <div className="col-xl-3 col-lg-3 form-group">
                                <label>Select Billing Date</label>
                                <Datetime
                                    value={this.state.billing_month}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{
                                        placeholder: '',
                                        name: 'billing_month',
                                        autoComplete: 'off'
                                    }}
                                    dateFormat="YYYY-MM-DD"
                                    name="billing_month"
                                    onChange={this.handlerStartDateTime.bind(this)}

                                />
                            </div> */}
                            {/* <div className="col-xl-3 col-lg-3 form-group">
                            <label>Select Month</label>
                                <Datetime
                                    value={this.state.billing_month} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ 
                                        placeholder: '', 
                                        name: 'billing_month', 
                                        autoComplete:'off' 
                                    }}
                                    dateFormat="YYYY-DD" 
                                    name="billing_month" 
                                    onChange={this.handlerStartDateTime.bind(this)} 

                                    />
                            </div>				   			 */}
		            		<div className="card-body">
                                <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        deleteRowHandler={this.deleteRowHandler}
                                        onCellClicked={this.onRowClicked.bind(this)}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        //updateRowHandler={this.updateRowHandler}
                                        //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				 <div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
					 <div className="slide-r-title">
				 		<h4>
                            Sidebars
                        </h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
						
				 		<div className="col-xl-12 col-lg-12">
				 		<div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg "+(this.state.alerterrshow)+" "+(this.state.alerterrmg)}>{this.state.csverrmessage}</div>
	        			
				 		</div>
				 	</div>
				 </div>
				
                
                <div className={"slide-r "+(this.state.sliderRso)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Add OEM Item
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickNewAddRow.bind(this)}>
							
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">OEM Code *</label>
								<input 
									type="text" 
									name="oem_code"
									value={this.state.oem_code}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							     
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">OEM Name *</label>
								<input 
									type="text" 
									name="oem_name"
									value={this.state.oem_name}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							     
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Industry Type *</label>
								<input 
									type="text" 
									name="industry_type"
									value={this.state.industry_type}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							                       
							
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						
                        </div>
					</div>
				</div>



                <div className={"slide-r "+(this.state.sliderUpdate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Remarks History
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickUpdateRowHandler.bind(this)}>
                        <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={this.state.remarks_history_cols}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.remarks_data}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        deleteRowHandler={this.deleteRowHandler}
                                        onCellClicked={this.onRowClicked.bind(this)}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        //updateRowHandler={this.updateRowHandler}
                                        //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
                            
						</form>

						
                        </div>
					</div>
				</div>

                <div className={"slide-r "+(this.state.sliderBulk)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Edit LSP Details
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                     
                            
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmiteditDetails.bind(this)}>
                        <div className="col-xl-12 col-lg-12">
                                        <label className="col-xl-12 col-lg-12">Name* :</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            onKeyPress={this.changeHandlercondition.bind(this)}
                                            onChange={this.changeHandler}
                                            value={this.state.name}
                                            name="name"
                                            
                                            autoComplete='off'
                                            required/>
                                        </div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">CHA* :</label>
									<Select
										placeholder={"Select CHA"}
										closeMenuOnSelect={true}
										onChange={this.onChangePlantItem.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										name="plant_code"
										value={this.state.cha}
                                        options={[{ label: "Yes", value: "Yes" },
                                        { label: "No", value: "No" },
                                       ]}  />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Freight Forwader* :</label>
									<Select
										placeholder={"Select Freight Forwader"}
										closeMenuOnSelect={true}
										onChange={this.onChangeConsigneeItem.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										name="consignee_code"
										value={this.state.freight_forwader}
                                        options={[{ label: "Yes", value: "Yes" },
                                        { label: "No", value: "No" },
                                       ]} />
								</div>
                                <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Transporter* :</label>
									<Select
										placeholder={"Select Transporter"}
										closeMenuOnSelect={true}
										onChange={this.onChangeTransporterItem.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										name="consignee_code"
										value={this.state.transporter}
                                        options={[{ label: "Yes", value: "Yes" },
                                        { label: "No", value: "No" },
                                       ]}/>
								</div>
                                <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12"> Email Id* :</label>
									<input
										type="text"
										className="form-control"
										onChange={this.changeHandler}
										value={this.state.email}
										name="email"
										autoComplete='off'
                                        required/>
								</div>
                                {/* <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Enter Freight Forwader Email Id* :</label>
									<input
										type="text"
										className="form-control"
										onChange={this.changeHandler}
										value={this.state.ff_email_id}
										name="ff_email_id"
										autoComplete='off'
                                        required/>
								</div>
                                <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Enter Transporter Email Id* :</label>
									<input
										type="text"
										className="form-control"
										onChange={this.changeHandler}
										value={this.state.transporter_email_id}
										name="transporter_email_id"
										autoComplete='off'
                                        required/>
								</div> */}
                                <h5 style={{fontWeight:"bold",color:"red",fontSize:"14px",marginTop:"2%"}}>Note: Emails should be provided in comma ' , ' delimited only</h5>
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
                                        
							
                            
						    {/* <div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_oem_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div> */}
						</form> 
                    
						
                        </div>
					</div>
				</div>
                {/* <div className={"slide-r "+(this.state.sliderBulk)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           View Bill
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                     
                            
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitBulkUpload.bind(this)}>
							
                            <div className="col-xl-12 col-lg-12 row form-group">
                            <div className="col-xl-12 col-lg-12 form-group">
                                <label>Select Billing Date</label>
                                <Datetime
                                    value={this.state.billing_month}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{
                                        placeholder: '',
                                        name: 'billing_month',
                                        autoComplete: 'off'
                                    }}
                                    dateFormat="YYYY-MM-DD"
                                    name="billing_month"
                                    onChange={this.handlerStartDateTime.bind(this)}

                                />
                            </div>  
                            </div>
							 
							<div className="col-xl-12 col-lg-12 c">
                            <button href="/tptlogin/billingsummary" type="button" onClick={this.onClickGenerateBill.bind(this)} className="btn btn-success">Generate Bill</button>
							</div>
                            
						    <div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_oem_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div> 
						</form> 
                
						
                        </div>
					</div>
				</div> */}
                


                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            

            </div>
              	
		);
	}
}
function GetSortDescOrder(prop) {    
    return function(a, b) {    
        if (a[prop] < b[prop]) {    
            return 1;    
        } else if (a[prop] > b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
}
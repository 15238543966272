"use strict";

import React, { Component, useDebugValue } from "react";
// import { render } from 'react-dom';
import { AgGridReact } from "@ag-grid-community/react";
// import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
// import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
// import { MenuModule } from '@ag-grid-enterprise/menu';
// import Select from 'react-select';
// import DrawMap from '../common/drawmap';
// import CommentActions from "../layouts/commentsComponent";
// import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
// import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
// import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
// import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
// import MarkDeliver from "../layouts/markDeliveredButton.js";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
// import ConsignmentActions from '../manage/ConsignmentActionsComponent';
// import CountUp from 'react-countup';
// import SweetAlert from 'react-bootstrap-sweetalert';
// import CSVFileValidator from 'csv-file-validator';
// import Consignmentforceclose from '../manage/consignmentforceclose';
// import UpdateButton from "./updateManualExportsBtn";
// import * as Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
// import Counters from "./apirunlogscounters";

// import CellGraphComponent from './cellGraphComponent';
import $ from "jquery";
// import ColumnGroup from './columngroupComponent';
import {
  getDDMMYYYY,
  getHyphenYYYYMMDD,
  getHyphenDDMMMYYYYHHMM,
  getHyphenDDMMMYYYY,
  getHyphenYYYYMMDDHHMMSS,
  getHyphenDDMMYYYYHHMMSS,
  getDDMMYYYYHHMMSS,
  getYYYYMMDD,
  getYYYYMMDDHHMMSS,
  getDDMMMYYYYHHMMDefault,
} from "../common/utils";
// import Highcharts from 'highcharts/highstock';
// import HighchartsReact from 'highcharts-react-official';
// import { HardwareDesktopWindows } from 'material-ui/svg-icons';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require("../redirectURL");
// var moment = require('moment');
// var filecontent="";
// var googleAnalytics = require("../common/googleanalytics");
// var CELL_DIMENSION_SIZE = 90;

export default class ApiRunLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: true,
      },
      eventAction: null,
      show: false,

      modified_by: "",
      basicTitle: "",
      reasonforceclose: "",
      bulkslide: "",
      file: "",
      uploadFile: "",
      basicType: "default",
      loadshow: "show-n",
      forceCloseRowNode: "",
      forceclosedata: "",
      csvcontent: "",
      sliderForceCloseTranslate: "",
      overly: "",
      rowData: [],
      bulkslide: "",
      showDiv: "",
      uploadDivWidth: "",
      mapinfo: "",
      dealer: "",
      sliderCommentTranslate: "",
      commentsRowData: "",
      consignment_code: "",
      sliderRouteTranslate: "",
      maptruckno: "",
      routeTruck: "",
      rownode: "",
      googelRoutes: "",
      file: "",
      rowSelection: "multiple",
      sliderForceCloseTranslate: "",
      maptruckno: "",
      rowModelhighchartoptionsType: "enterprise",
      rowGroupPanelShow: "always",
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      paginationPageSize: 50,
      geofencelist: [],
      geoFenceData: "",
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      animateRows: true,
      dept_code: "",
      pagetitle: "",
      markDeliverRowNode: "",
      markDeliverData: "",
      sliderMarkDeliverTranslate: "",
      csvData: 0,
      overly: "show-m",
      loadshow: "show-m",
      showUserType: "show-m",
      plants: [],
      plant_name: "",
      cellPlants: [],
      sliderTranslate: "",
      selectedDate: "",

      sndgsucessCount: 0,
      sndgfailureCount: 0,
      logTNPsucessCount: 0,
      logTNPfailureCount: 0,
      logPRTsucessCount: 0,
      logPRTfailureCount: 0,
      originalData: [],
      //components : {datepicker:getDatePicker()}
    };
  }

  componentDidMount() {
    //    loadDateTimeScript();
    //     var successCount = 0;
    // var failureCount = 0;
    var sndgsucessCount = 0;
    var sndgfailureCount = 0;
    var logTNPsucessCount = 0;
    var logTNPfailureCount = 0;
    var logPRTsucessCount = 0;
    var logPRTfailureCount = 0;

    redirectURL
      .post("/consignments/msilapirunlogs")
      .then((response) => {
        var records = response.data.data;
        console.log("records-------", records);

        records.forEach((item) => {
          console.log(item, "itemsss");
          if (item.dept_code === "SNDG") {
            if (item.last_api_response === "Success") {
              sndgsucessCount++;
            } else if (item.last_api_response === "Failure") {
              sndgfailureCount++;
            }
          } else if (item.dept_code === "LOG-PRT") {
            if (item.last_api_response === "Success") {
              logPRTsucessCount++;
            } else if (item.last_api_response === "Failure") {
              logPRTfailureCount++;
            }
          } else if (item.dept_code === "LOG-TNP") {
            if (item.last_api_response === "Success") {
              logTNPsucessCount++;
            } else if (item.last_api_response === "Failure") {
              logTNPfailureCount++;
            }
          }
        });
        console.log(
          sndgsucessCount,
          sndgfailureCount,
          logPRTsucessCount,
          logPRTfailureCount,
          logTNPsucessCount,
          logTNPfailureCount,
          "countofall"
        );
        this.setState({
          rowData: records,
          originalData:records,
          sndgsucessCount: sndgsucessCount,
          sndgfailureCount: sndgfailureCount,
          logTNPsucessCount: logTNPsucessCount,
          logTNPfailureCount: logTNPfailureCount,
          logPRTsucessCount: logPRTsucessCount,
          logPRTfailureCount: logPRTfailureCount,
          loadshow: "show-n",
          overly: "show-n",
        });
      })
      .catch((e) => {
        console.log(e);
      });
    console.log("first");
  }
  onClickCounterShowData = (deptCode, status) => {
    console.log(deptCode, status, "params");
    
    if (this.state.originalData.length === 0) {
      // Store the current rowData into originalData only if it's empty
      this.setState({ originalData: this.state.rowData });
    }
  
    // Filter the data based on deptCode and status
    var filterData = this.state.originalData.filter((ele) => {
      return ele.dept_code === deptCode && ele.last_api_response === status;
    });
  
    console.log(filterData, 'filterData');
    
    // Update the rowData state with the filtered data
    this.setState({ rowData: filterData });
  }

  render() {
    var columnwithDefs = [
      {
        headerName: "Dept Code",
        field: "dept_code",
        width: 150,
        filter: true,
        resizable: true,
        editable: false,
      },
      {
        headerName: "Feild Names",
        field: "fields_names",
        width: 150,
        filter: true,
        resizable: true,
        editable: false,
      },
      {
        headerName: "API Description",
        field: "api_description",
        width: 250,
        filter: true,
        resizable: true,
        editable: false,
      },
      {
        headerName: "API URL",
        field: "api_url",
        width: 250,
        filter: true,
        resizable: true,
        editable: false,
      },
      {
        headerName: "API Payload",
        field: "api_payload",
        width: 250,
        filter: true,
        resizable: true,
        editable: false,
        hide: true,
      },
      {
        headerName: "Last API Response",
        field: "last_api_response",
        width: 150,
        filter: true,
        resizable: true,
        editable: false,
      },
      {
        headerName: "Average API Duration In Seconds",
        field: "average_api_duration_in_seconds",
        width: 140,
        filter: true,
        resizable: true,
        editable: false,
        hide: true,
      },
      {
        headerName: "API Hitting Frequency",
        field: "api_frequency",
        width: 140,
        filter: true,
        resizable: true,
        editable: false,
      },
    
      {
        headerName: "Last API Record Received On",
        field: "last_record_received_on",
        width: 140,
        filter: true,
        resizable: true,
        editable: false,
        valueGetter: function(params) {
          try {
            return getDDMMMYYYYHHMMDefault(params.data.last_record_received_on);
          } catch (e) {
            return "";
          }
        },
     
      },
        {
        headerName: "Last API Record Processed On",
        field: "last_processed_on",
        width: 150,
        filter: true,
        resizable: true,
        editable: false,

        valueGetter: function(params) {
          try {
            return getDDMMMYYYYHHMMDefault(params.data.last_processed_on);
          } catch (e) {
            return "";
          }
        },
      },

     
    ];

    return (
      <div class="container-fluid">
        {/* <div className="col-xl-12 col-lg-12">
          <Counters
            context={this}
            sndgsucessCount={this.state.sndgsucessCount}
            sndgfailureCount={this.state.sndgfailureCount}
            logTNPsucessCount={this.state.logTNPsucessCount}
            logTNPfailureCount={this.state.logTNPfailureCount}
            logPRTsucessCount={this.state.logPRTsucessCount}
            logPRTfailureCount={this.state.logPRTfailureCount}
            screen={"loads"}
          />
        </div> */}
        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <div class="card">
              <div class="card-header">
                <h5>
                  <i className="icofont icofont-vehicle-delivery-van cus-i"></i>{" "}
                  <span>MSIL API Running Logs</span>
                </h5>
              </div>
              <div class="card-body">
                <div class="row theme-form">
                  <div
                    id="myGrid"
                    style={{ width: "100%", height: "478px" }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      modules={this.state.modules}
                      columnDefs={columnwithDefs}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.rowData}
                      enableCharts={false}
                      onGridReady={this.onGridReady}
                      onGridState={this.onGridState}
                      statusBar={this.state.statusBar}
                      sideBar={this.state.sideBar}
                      stopEditingWhenGridLosesFocus={true}
                      paginationPageSize={this.state.paginationPageSize}
                      pagination={true}
                      gridOptions={{
                        context: { componentParent: this },
                        getRowStyle: params => {
                          const rowIndex = params.node.rowIndex;
                          if (rowIndex < 5) {
                            return { background: 'lightblue' }; 
                          }
                          return null;
                        },
                      }}
                      enableRangeSelection={true}
                    />
                  </div>

                  <div className={"dataLoadpage " + this.state.loadshow}></div>
                  <div className={"dataLoadpageimg " + this.state.loadshow}>
                    <div className="loader-box">
                      <div className="loader-box">
                        <div className="rotate dashed colored"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function DateEditor() {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
  // create the cell
  this.eInput = document.createElement("input");
  this.eInput.value = params.value;

  // https://jqueryui.com/datepicker/
  $(this.eInput).datetimepicker({
    dateFormat: "yy-mm-dd",
    changeMonth: true,
    changeYear: true,
  });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
  return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
  this.eInput.focus();
  this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
  // console.log(this.eInput.value);
  return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
  // but this example is simple, no cleanup, we could
  // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
  // and we could leave this method out also, false is the default
  return false;
};

"use strict";

import React, { Component, useDebugValue } from "react";
import { render } from "react-dom";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MenuModule } from "@ag-grid-enterprise/menu";
import Select from "react-select";
import DrawMap from "../common/drawmap";
import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import MarkDeliver from "../layouts/markDeliveredButton.js";
import ProgressBar from "@ramonak/react-progress-bar";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from "react-bootstrap-sweetalert";
import CSVFileValidator from "csv-file-validator";
import CountUp from "react-countup";
import UpdateButton from "./updatelmstates";
import * as Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import $ from "jquery";
import LedScreenPieChartComponent from "./ledscreenpirchartcomponent";
import ColumnGroup from "./columngroupComponent";
import {
  getDDMMYYYY,
  getHyphenYYYYMMDD,
  getHyphenDDMMMYYYYHHMM,
  getHyphenDDMMMYYYY,
  getHyphenYYYYMMDDHHMMSS,
  getHyphenDDMMYYYYHHMMSS,
  getDDMMYYYYHHMMSS,
  getYYYYMMDD,
  getYYYYMMDDHHMMSS,
} from "../common/utils";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var drawingManager;
var redirectURL = require("../redirectURL");
var moment = require("moment");
var filecontent = "";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;
var infoBox = require("../common/google-infowindow");
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var marker;
var markers = [];
var circle;
var selectlat;
var selectlng;
var allcords = [];
var map;
var doOnce = true;
var rad = function(x) {
  return (x * Math.PI) / 180;
};
var rlatitude;
var rlongitude;
var globForecastDay="Today";
var mapZoom=5;
var fencingLength = 0;
var geoFencingArea;
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};

export default class SampleLedScreen1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showform: "show-m",
      loadshow: "show-n",
      showrefresh: "show-n",
      sliderTranslate: "",
      showdata: this.displayData,
      modules: AllModules,
      radius: "15",
      rlatitude: "",
      rlongitude: "",
      latitude: "",
      longitude: "",
      defaultradius: 0,
      coordinates: [],
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      contentString: "testing",
      mwidth: "col-xl-12 col-lg-12",
      dept_code: "",
      defaultColDef: {
        
        
        sortable: true,
        filter: true,
        //editable: true,
        resizable: true,
      },
      rowData: [],

      rowModelhighchartoptionsType: "enterprise",
      rowGroupPanelShow: "always",
      animateRows: true,
      debug: true,
      showToolPanel: false,
      rowSelection: "single",
      context: { componentParent: this },
      loadshow: "show-n",
      overly: "show-n",
      loadingCellRenderer: "customLoadingCellRenderer",
      loadingCellRendererParams: { loadingMessage: "One moment please..." },
      rowModelType: "serverSide",
      paginationPageSize: 50,
      cacheBlockSize: 50,
      maxBlocksInCache: 2,
      totalSales: 0,
      totalDispatches: 0,
      railShare: 0,
      month_target: 0,
      actual_till_date: 0,
      achievement: 0,
      balance_days: 0,
      daily_target: 0,
      current_rail_dispatch_fy: 0,
      pending_for_exit: 0,
      Pending_for_rail_out: 0,
      rail_in_transit: 0,
      last_mile: 0,
      delivered: 0,
      otwrtsr: 0,
      otwrtrtt: 0,
      otwrtrrat: 0,
      pagetitle: "Rake Positions",
      plan_month: "",
      stockData: [],
      statecheck: [],
      chartSeriesData: [],
      chartSeriesCategories: [],
      to_current_Date: "",
      from_current_Date: "",
      completed_days: 0,
      intransitRakeList: [],
      invoice_status_data:[],
      export_achievement:0,
      domestic_achievement:0,
      total_achievement:0,
      domestic_monthly_target:70000,
      export_monthly_target:15000,
      total_monthly_target:0,
      total_monthly_achievement:0,
      remaining_days:1,
      stock_yard_list:[],
      stock_status_data:[],
      lastRunTime : "",
      gridHeaderName: "",
      detailedWeatherMap:0,
      scrowData:[]
    };
    // this.popmarker=this.popmarker.bind(this);
  }

  getLatestValues() {
    redirectURL
      .post("/consignments/getLatestRailStatusValues")
      .then((response) => {
        // console.log(response.data,"214")
        var total_share_value = parseInt(
          response.data.total_sales_till_yesterday
        );
        var total_dispatches_tr = this.state.actual_till_date;
        var rail_share_percentage =
          (total_dispatches_tr / total_share_value) * 100;
        rail_share_percentage = rail_share_percentage.toFixed(2);
        this.setState({
          totalSales: response.data.total_sales_till_yesterday,
          totalDispatches: response.data.total_rail_sales_till_yesterday,
          railShare: rail_share_percentage,
          loadshow: "show-n",
          overly: "show-n",
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  logPageView = () => {
    try {
      if (googleAnalytics.page.enableGA) {
        googleAnalytics.logPageView();
        let eventGridAction =
          googleAnalytics.page.actionType.gridAction +
          " - " +
          window.location.pathname;
        let eventCounterAction =
          googleAnalytics.page.actionType.counterAction +
          " - " +
          window.location.pathname;
        let eventFormAction =
          googleAnalytics.page.actionType.formAction +
          " - " +
          window.location.pathname;
        this.setState({
          eventGridAction: eventGridAction,
          eventCounterAction: eventCounterAction,
          eventFormAction: eventFormAction,
        });
      } else {
        console.log("GA disabled...");
      }
    } catch (error) {
      console.error("Error occured while logging to GA, error = ", error);
    }
  };
  async componentDidMount() {
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });


    redirectURL.post("/consignments/getexportachievement").then((response)=>{
      console.log(response.data.data,"222");
      var total1=this.state.export_monthly_target+this.state.domestic_monthly_target
      var total2=response.data.data.export+response.data.data.domestic
      console.log(total2,response.data.data.export)
      this.setState({
        export_achievement:response.data.data.export,
        domestic_achievement:response.data.data.domestic,
        total_monthly_target:total1,
        total_monthly_achievement:total2
      })
      
  }).catch(function(error){
      console.log(error);
  })
    


  redirectURL.post("/consignments/getstockyardDatafromsndplanningstatus").then(async(response)=>{
    console.log(response.data,"251")
   
    const newData=(response.data).map((each)=>{
      if (each.plant_name==="Bangalore"){
        var stock_capacity =(each.stock/3300)*100
        const newEach={...each,capacity:3300,plant_name:"BANGLORE",stock_capacity_data:parseFloat(stock_capacity.toFixed(1))}
        return newEach 
      }else if (each.plant_name==="Gurgaon"){
        var stock_capacity =(each.stock/7000)*100
        const newEach={...each,capacity:7000,plant_name:"GURGAON + TPA",stock_capacity_data:parseFloat(stock_capacity.toFixed(1))}
        return newEach 
      }else if (each.plant_name==="Gujarat"){
        var stock_capacity =(each.stock/25000)*100
        const newEach={...each,capacity:25000,plant_name:"GUJARAT",stock_capacity_data:parseFloat(stock_capacity.toFixed(1))}
        return newEach 
      }else if (each.plant_name==="Manesar"){
         var stock_capacity =(each.stock/25000)*100
        const newEach={...each,capacity:25000,plant_name:"MANESAR",stock_capacity_data:parseFloat(stock_capacity.toFixed(1))}
        return newEach 
      }else{
        return each
      }
    })

    const rrr=newData.filter(each=>(each.plant_name!=="Siliguri"))

    console.log(rrr,newData,"247");

    this.setState({stock_yard_list:rrr})
    console.log(this.state.stock_yard_list)
}).catch(function(error){
    console.log(error);
})

redirectURL.post("/consignments/getmonthlytargets").then((response)=>{
  console.log(response.data,"256");

  this.setState({domestic_monthly_target:response.data[0].monthly_domestic_target,export_monthly_target:response.data[0].monthly_export_target,total_monthly_target:response.data[0].total_target})
  
}).catch(function(error){
  console.log(error);
})
  


  var plan_month =moment.parseZone().format("YYYY-MM");
  var params = {
      plan_month: plan_month
  }

  redirectURL.post("/consignments/getRailTargetByMonthforLED", params).then((response) => {
    //console.log("response", response.data);
    var railTargetData = response.data.railtarget;
    var wkDates = railTargetData[0].working_days;
    var workingDates = [];
    var balanceDates = [];
    var completedDates = [];
    wkDates.map(function (wkd) {
        workingDates.push(moment.parseZone(new Date(wkd)).format("YYYY-MM-DD") + " 00:00:00");
    });
    //console.log(workingDates,"workingDates")
    var currentDate = moment.parseZone().format("YYYY-MM-DD") + " 00:00:00";
    currentDate = currentDate.split("+")[0];
    // console.log(currentDate,"currentDate")
    wkDates.map(function (wde) {
        var comparision = moment(wde).isSameOrAfter(currentDate);
        // console.log("moment(wde)",moment(wde))
        // console.log("comparision",comparision)
        if (comparision == true) {
            balanceDates.push(wde);
        }
        else {
            completedDates.push(wde);
        }
    });
    
    this.setState({remaining_days:completedDates.length})
    console.log(completedDates.length,"260")
   
}).catch((e) => {
    console.log(e);
})


    redirectURL.post("/weather/getWeatherData").then((response)=>{
        console.log(response.data);
        const uniqueDAta = [...new Set(response.data.ForecastData.map(item => item.description))];
        console.log(response.data.ForecastData.length,"uniqueDAta")
        
        this.setState({
          weatherData : response.data.ForecastData,
          statesSubDivisions: response.data.statesSubDivisions
        })
        redirectURL.post("/weather/getPlants").then((response)=>{
          
          var path = window.location.href.split("//")[1].split("/")[0];
          
          this.setState({
            weatherPlants : response.data,
            forecastDay : "Today",
            path : path
          })
          this.renderMapWeather();
        })
        
      }).catch(function(error){
        console.log(error);
      })

      redirectURL.post("/weather/getFloodData").then((response)=>{
          console.log(response.data);
          this.setState({
            floodData : response.data,
          })
      }).catch(function(error){
          console.log(error);
      })
      

      
      var dept_code = "";
      console.log(this.props.match.path,"path")
      var screenView = "";
    
      if(this.props.match.path == "/sampleledscreen1")
      {
          var screen = "user";

          if(this.props.match.path == "/sampleledscreen1")
          {
              dept_code = "SNDG";
          }else{ dept_code = "LOG-SC"}
          this.setState({
              suppressClickEdit: false,

          })
      }
      
      // console.log(screenView, "screenView")
      this.setState({
          dept_code:  dept_code,
      })
      var sort_order = {
          "Gurgaon": 1, "SMG Plant": 2, "Manesar": 3, "IMT Manesar": 4, "TVP Bangalore": 5,
          "TVP Nagpur": 6, "Siliguri": 7, "Mundra Port": 8, "Mumbai Port": 9
      }


      await redirectURL.post("/dashboard/getstockDataforPlants",{"screen": screen}).then((response)=>{
        var data = response.data;
        // console.log(data,"182");
       
    
      var newData=data.map(each=>{
        if (each.railyard_name=="D22 Bengaluru (Bidadi)"){
          return ({...each,railyard_name:"Bidadi"})
        }
        return each
      })
    
      console.log(newData,"192")
    
        this.setState({
          scrowData:newData
    
      })
    
      
        
    })
    

      // redirectURL.post("/dashboard/getSafetyReport",{"screen": screen}).then((response)=>{
      //     var data = response.data;
      //     console.log(data);
      //     var check = data.map(function(e){
      //         e['sort_order'] = sort_order[e.plant_location]
      //     })
      //     Promise.all([check]).then(()=>{
      //         console.log(data);
      //         data = data.sort(function(a, b){return a['sort_order'] - b['sort_order']})
      //         var snddata = data.filter(function(e){
      //             return e.dept_code == "SNDG"
      //         });
      //         var scdata = data.filter(function(e){
      //             return e.dept_code != "SNDG"
      //         });
      //         console.log(screen, dept_code, "detcode")
      //         this.setState({
      //             rowData: snddata,
      //             scrowData: scdata,
      //             screenType: screen,
      //             dept_code: dept_code,
      //             screenView: screenView,


      //         })
      //     })
          
      // })


    await this.getLatestValues();

    loadDateTimeScript();
    this.logPageView(); // Log page view to GA
    if (this.props.match.path == "/sndplanningstatusmgmt") {
      var userType = "management";
    }
    if (this.props.match.path == "/sampleledscreen1") {
      var userType = "user";
    }
    var screenView = "";
    if (this.props.match.path == "/mgmtplanningstatus") {
      var screenView = "Mobile";
      var userType = "management";
    }
    this.setState({
      userType: userType,
      screenView: screenView,
    });
    this.sndPlanningStatus(userType);



    await redirectURL.post("/dashboard/getstockDataforPlants").then((response)=>{
      var data = response.data;
      // console.log(data,"346");
     
      this.setState({
        invoice_status_data:data
})

    // console.log(this.state.invoice_status_data)
      
  })


  var lastRunTime = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
      this.setState({
          lastRunTime:lastRunTime,
          overly: "show-n",
          loadshow: "show-n"
      })


  }


  renderMapWeather = () => {    	
    loadScriptWeather("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMapWeather");
    window.initMapWeather = this.initMapWeather
   }

   initMapWeather = () => {
     
    try
      {
        //console.log("here slidebar")
        // $("#mapweather").empty();
        //var coordsvalues;
        var map_type = window.google.maps.MapTypeId.HYBRID;
        // if(this.state.detailedWeatherMap == 0)
        // {
        //   var map_type = window.google.maps.MapTypeId.ROADMAP;
        // }
        var map = new window.google.maps.Map(document.getElementById('mapweather'), {
          zoom: 8,
          // center: new window.google.maps.LatLng(28.497236,77.062363),23.581046, 78.776465
          center: new window.google.maps.LatLng(23.581046,78.776465),
          mapTypeId: map_type,
          mapTypeControl: true,
          mapTypeControlOptions: {
            style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: window.google.maps.ControlPosition.TOP_RIGHT
          },            
          disableDefaultUI: true,
          fullscreenControl: false,
          zoomControl: true,
          panControl: false,
          
        });
        
        var filterRecDate = "";

        if(globForecastDay == "Today")
        {
          filterRecDate = moment.parseZone(new Date()).format("DD-MM-YYYY");
        }
        else
        {
          filterRecDate = moment.parseZone(new Date(+new Date() + 86400000)).format("DD-MM-YYYY");
        }

        // First Map Weather Plants Icons
        if(this.state.weatherPlants.length > 0 && this.state.detailedWeatherMap == 0){
          this.state.weatherPlants.map(function(params){
            var plant = {
              url: require("../../assets/icons/"+params.icon), // url
              scaledSize: new window.google.maps.Size(24,24), // scaled size
              origin: new window.google.maps.Point(0,0), // origin
              anchor: new window.google.maps.Point(0, 0) // anchor
            };
            var coords = JSON.parse(params.coordinates);
            // console.log(coords);
            var marker = new window.google.maps.Marker({
              position: new window.google.maps.LatLng(coords.lat,coords.lng),
              map: map,
              icon: plant,
              title : params.plant_name
            });
            

            var cityCircle = new window.google.maps.Circle({
              strokeColor: '#9fc9b9',
              strokeOpacity: 0.3,
              strokeWeight: 2,
              fillColor: '#9fc9b9',
              fillOpacity: 0.6,
              map: map,
              center:coords,
              radius: 50 * 1000
            });
          })
        }
        // var iconObject = {"heavyrain":heavyrain,"Sandstorm":Sandstorm,"cyclone":cyclone,"wind":wind,"hailstorm":hailstorm,"thunderstorm":thunderstorm}
        var bounds = new window.google.maps.LatLngBounds();
        
        // new LatLng(23.63936, 68.14712), new LatLng(28.20453, 97.34466)

        // bounds.extend(new window.google.maps.LatLng(23.63936, 68.14712));
        // bounds.extend(new window.google.maps.LatLng(28.20453, 97.34466));


        bounds.extend(new window.google.maps.LatLng(12.63936, 58.14712));
        bounds.extend(new window.google.maps.LatLng(30.20453, 97.34466));


        // First Map
        // if(this.state.weatherData.length > 0 && this.state.detailedWeatherMap == 0)
        // {
          
        //   var filterRecords =  this.state.weatherData.filter(function(params){
        //     return moment.parseZone(new Date(params.forecast_date)).format("DD-MM-YYYY") == filterRecDate && params.description != "NA"
        //   })
          
        //   var district_last_updated_on = "";
        //   if(filterRecords.length > 0)
        //   {
        //     district_last_updated_on = filterRecords[0]['last_updated_on']
        //     this.setState({
        //       last_updated_on: district_last_updated_on
        //     })
        //     filterRecords.map(function(params)
        //     {            
        //       if(params.address.includes("NA") == false && params.address != "/" && params.description.includes("No warning") == false)
        //       {
        //         bounds.extend(new window.google.maps.LatLng(params.latitude,params.longitude));
        //         var url="";
        //         var iconName = "";
        //         if(params.description.includes("Thunderstorm") == true){
        //           url = require("../../assets/icons/thunderstorm.png")
        //         }                    
        //         else if(params.description.includes("Heavy Rain and Strong Winds") == true){
        //           url = require("../../assets/icons/wind.png")
        //         }
        //         else if(params.description.includes("Heavy Rain") == true){
        //           url = require("../../assets/icons/Heavyrain.png")
        //         }
        //         else if(params.description.includes("Cyclone") == true){
        //           url = require("../../assets/icons/cyclone.png")
        //         }
        //         else if(params.description.includes("Hailstroms") == true){
        //           url = require("../../assets/icons/hailstorm.png")
        //         }
        //         else if(params.description.includes("Sandstorm") == true || params.description.includes("Duststorm") == true){
        //           url = require("../../assets/icons/sandstorm.png")
        //         }
        //         else
        //         {
                  
        //         }
        //         // console.log("weatherIcon", url )
        //         var iconName = {
        //           url: url, // url
        //           scaledSize: new window.google.maps.Size(32, 32), // scaled size
        //           origin: new window.google.maps.Point(0,0), // origin
        //           anchor: new window.google.maps.Point(0, 0) // anchor
        //         };
        //         var marker = new window.google.maps.Marker({
        //           position: new window.google.maps.LatLng(params.latitude,params.longitude),
        //           map: map,
        //           icon: iconName,
        //           // title : ""+params.address+","+params.forecast_date+","+params.description
        //         });
        //         var infowindow = new window.google.maps.InfoWindow({
        //           content: "<div class='weather-marker-tooltip'><div class='forecast-date'><label class='forecast-labels'> Forecast Date : </label> "+moment.parseZone(new Date(params.forecast_date)).format("DD-MMM-YYYY")+"</div><div class='address'> <label class='forecast-labels'>Address :</label> "+params.address+"</div><div class='weather-desc'><label class='forecast-labels'> Weather : </label> "+params.description+"</div><div class='last-updated'><label class='forecast-labels'> Last Updated On : </label> "+getHyphenDDMMMYYYYHHMM(params.last_updated_on)+"</div>"
        //           });
        //         window.google.maps.event.addListener(marker, 'mouseover', function() {
        //           infowindow.open(map,marker);
        //         });
        //         window.google.maps.event.addListener(marker, 'mouseout', function() {
        //           infowindow.close();
        //         });
        //       }
        //     // }
        //     })
        //     $("#mapweather").css("visibility","visible");
        //     $(".forecast-warning").css("display","none");
        //   }
        //   else
        //   {
        //     //$("#mapweather").css("visibility","hidden");
        //     $(".forecast-warning").css("display","block");
        //   }
        //   map.addListener('zoom_changed', function() {
        //     mapZoom=map.getZoom();
        //   });
        // }
        // // Second Map
        // else if(this.state.detailedWeatherMap == 1)
        // {
        //   var infowindows = [];
        //   this.state.statesSubDivisions.map((e, index)=>{
        //     var geofence_coordinates = JSON.parse(e.geofence_coordinates);
        //     // var optimizedCoords = [geofence_coordinates[0]];
        //     // var i = 2;
        //     // while(i < geofence_coordinates.length - 1)
        //     // {
        //     //   optimizedCoords.push(geofence_coordinates[i])
        //     //   // bounds.extend(new window.google.maps.LatLng(geofence_coordinates[i].lat,geofence_coordinates[i].lng));
        //     //   i = i + 1
        //     // }
        //     // optimizedCoords.push(geofence_coordinates[geofence_coordinates.length-1])
        //     var hex_color = '#000';
        //     try{
              
        //       if(globForecastDay == "Today")
        //       {
        //         var filterRecDate = moment.parseZone(new Date()).format("DD-MM-YYYY");
        //         var forecast_info =  e.forecast_info.filter(function(params){
        //           return moment.parseZone(new Date(params.forecast_date)).format("DD-MM-YYYY") == filterRecDate
        //         })
        //       }
        //       else
        //       {
        //         var filterRecDate = moment.parseZone(new Date(+new Date() + 86400000)).format("DD-MM-YYYY");
        //         var forecast_info =  e.forecast_info.filter(function(params){
        //           return moment.parseZone(new Date(params.forecast_date)).format("DD-MM-YYYY") == filterRecDate;
        //         })
        //       }
        //     }
        //     catch(e){
        //       var forecast_info = [{}]
        //     }
        //     var states_last_updated_on = "";
        //     if(forecast_info.length)
        //     {
        //       states_last_updated_on = forecast_info[0]['last_updated_on']
        //       hex_color =  forecast_info[0]['color']
        //     }
        //     this.setState({
        //       last_updated_on: states_last_updated_on
        //     })
        //     var statePoly = new window.google.maps.Polygon({
        //         paths: JSON.parse(e.geofence_coordinates),
        //         editable: false,
        //         strokeColor: "green",
        //         strokeOpacity: 0.70,
        //         strokeWeight: 1,
        //         fillColor: hex_color,
        //         fillOpacity: 1,
        //         draggable:false,
        //         draggingCursor:null,
                
        //     });
        //     var weatherCondition = forecast_info[0].weather_condition;
        //     function iconUrl(i){
        //       if(i.includes("rain"))
        //           {
        //             url = require("../../assets/icons/mausam - heavy rain.png")
        //           }
        //           else if(i.includes("cold day"))
        //           {
        //              url = require("../../assets/icons/mausam - cold day.png")
        //           }
        //           else if(i.includes("cold wave"))
        //           {
        //              url = require("../../assets/icons/mausam - cold wave.png")
        //           }
        //           else if(i.includes("dust raising"))
        //           {
        //             url = require("../../assets/icons/mausam - dust raising winds.png")
        //           }
        //           else if(i.includes("dust storm"))
        //           {
        //             url = require("../../assets/icons/mausam - dust storm.png")
        //           }
        //           else if(i.includes("fog"))
        //           {
        //             url = require("../../assets/icons/mausam - fog.png")
        //           }
        //           else if(i.includes("froast"))
        //           {
        //             url = require("../../assets/icons/mausam - ground froast.png")
        //           }
        //           else if(i.includes("hail"))
        //           {
        //             url = require("../../assets/icons/mausam - hailstorm.png")
        //           }
        //           else if(i.includes("heat wave"))
        //           {
        //             url = require("../../assets/icons/mausam - heat wave.png")
        //           }
        //           else if(i.includes("snow"))
        //           {
        //             url = require("../../assets/icons/mausam - heavy snow.png")
        //           }
        //           else if(i.includes("hot"))
        //           {
        //             url = require("../../assets/icons/mausam - hot day.png")
        //           }
        //           else if(i.includes("surface"))
        //           {
        //             url = require("../../assets/icons/mausam - strong surface winds.png")
        //           }
        //           else if(i.includes("thunderstorm"))
        //           {
        //             url = require("../../assets/icons/mausam - thunderstorm.png")
        //           }
        //           else if(i.includes("night"))
        //           {
        //             url = require("../../assets/icons/mausam - warm night.png")
        //           }
        //         return url
        //     }
        //     if(weatherCondition != undefined)
        //     {
        //       weatherCondition = weatherCondition.toLowerCase();
        //       var url="";
        //       let wc = weatherCondition.split(",")
        //       if (wc.length >1){
        //         var lat = e.centroid_location.lat;
        //         var lng = e.centroid_location.lng;
        //         wc.map((i, index)=>{
        //           url = iconUrl(i)
        //           console.log("url",i, url, e)       

        //           var iconName = {
        //             url: url, // url
        //             scaledSize: new window.google.maps.Size(32, 32), // scaled size
        //             origin: new window.google.maps.Point(0,0), // origin
        //             anchor: new window.google.maps.Point(0, 0) // anchor
        //           };
                  
        //           if(index > 0)
        //           {
        //             lat = lat + 0.2;
        //             lng = lng + 0.2
        //           }
        //           let marker = new window.google.maps.Marker({
        //             position: new window.google.maps.LatLng(lat, lng),
        //             map: map,
        //             icon: iconName
        //           });

        //         })                     
        //       }
        //       else{
        //         url = iconUrl(weatherCondition)
        //         var iconName = {
        //           url: url, // url
        //           scaledSize: new window.google.maps.Size(32, 32), // scaled size
        //           origin: new window.google.maps.Point(0,0), // origin
        //           anchor: new window.google.maps.Point(0, 0) // anchor
        //         };
        //         var marker = new window.google.maps.Marker({
        //           position: new window.google.maps.LatLng(e.centroid_location.lat,e.centroid_location.lng),
        //           map: map,
        //           icon: iconName
        //         });
        //       }
        //     }
        //     infowindows[index] = new window.google.maps.InfoWindow({
        //       content: e.geofence_name
        //     });
        //     var startAlert = "NA";
        //     var endAlert = "NA";
        //     if(e.alert_info != undefined && e.active_alert == 1)
        //     {
        //       startAlert = getHyphenYYYYMMDDHHMMSS(e.alert_info.start_alert);
        //       endAlert = getHyphenYYYYMMDDHHMMSS(e.alert_info.end_alert);
        //     }
        //     window.google.maps.event.addListener(statePoly, 'mouseover', ()=> {
        //       console.log("clicked")
        //       infowindows[index].open(map,statePoly);
        //       this.setState({
        //         hoveredState: e.geofence_name,
        //         weatherCondition: forecast_info[0].weather_condition,
        //         startAlert: startAlert,
        //         endAlert: endAlert
        //       })
        //     });
        //       window.google.maps.event.addListener(statePoly, 'mouseout', ()=> {
        //         infowindows[index].close();
        //         this.setState({
        //           hoveredState: "",
        //           weatherCondition: "",
        //           startAlert: "NA",
        //           endAlert:"NA"
        //         })
        //     });
        //     statePoly.setMap(map)

        //   })
        // }
        // // Third Map
        // else if(this.state.detailedWeatherMap == 2)
        // {
        //     var floodData = this.state.floodData;
        //     var floodMarkers = [];
        //     floodData.map((params)=>
        //     { 
        //       //console.log(params)
        //       var iconClr = "";        
        //       if(params.current_status == "NORMAL")
        //       {
        //         iconClr = require("../../assets/icons/green-dot.png");
        //       }
        //       else if(params.current_status == "INFLOW")
        //       {
        //         iconClr = require("../../assets/icons/blue-dot.png");
        //         //console.log(params.current_status,params.latitude,params.longitude)
        //       }
        //       else if(params.current_status == "WARNING")
        //       {
        //         iconClr = require("../../assets/icons/yellow-dot.png");
        //       }
        //       else if(params.current_status == "EXTREME")
        //       {
        //         iconClr = require("../../assets/icons/black-circle.png");
        //       }
        //       else
        //       {
        //         iconClr = require("../../assets/icons/red-dot.png");
        //       }
        //       bounds.extend(new window.google.maps.LatLng(params.latitude,params.longitude));
              
        //       var iconName = {
        //         url: iconClr, // url
        //         scaledSize: new window.google.maps.Size(10, 10), // scaled size
        //         origin: new window.google.maps.Point(0,0), // origin
        //         anchor: new window.google.maps.Point(0, 0) // anchor
        //       };
            
        //       var marker = new window.google.maps.Marker({
        //         position: new window.google.maps.LatLng(params.latitude,params.longitude),
        //         map: map,
        //         icon: iconName,
        //         // title : ""+params.address+","+params.forecast_date+","+params.description
        //       });
        //     //   floodMarkers.push({
        //     //     "flood_marker": marker,
        //     //     "alert": params.current_status,
        //     //     "url": iconClr
        //     //   })
        //       var infowindow = new window.google.maps.InfoWindow({
        //         content: "<div class='weather-marker-tooltip'><div class='address'> <label class='forecast-labels'>Flood Station :</label> "+params.station+"</div><div class='river'> <label class='forecast-labels'>River :</label> "+params.river+"</div><div class='river'> <label class='forecast-labels'>Division :</label> "+params.division+"</div><div class='weather-desc'><label class='forecast-labels'> Alert : </label> "+params.current_status+"</div>"
        //       });
        //       window.google.maps.event.addListener(marker, 'mouseover', function() {
        //         infowindow.open(map,marker);
        //       });
        //       window.google.maps.event.addListener(marker, 'mouseout', function() {
        //         infowindow.close();
        //       });
        //     }) 
        //     map.addListener('zoom_changed', function() {
        //       mapZoom=map.getZoom();
        //     });  
        //     // this.setState({
        //     //   floodMarkers: floodMarkers
        //     // })            
        // }
        map.fitBounds(bounds)

      }catch(e){}
      
    }
    changeForecastData = (event) =>{
        this.setState({
          forecastDay : event.value,
          overly: "show-m",
          loadshow: "show-m"
        });
        globForecastDay = event.value;
        // console.log(event.value);
        this.renderMapWeather()
          // this.initMapWeather()
      } 
    //   onChangeForecastType(e){
    //     if(e == 1)
    //     {
    //       this.setState({
    //         overly: "show-m",
    //         loadshow: "show-m"
    //       });
    //         redirectURL.post("/weather/getFloodData").then((response)=>{
    //             console.log(response.data);
    //             this.setState({
    //               floodData : response.data,
    //               overly: "show-n",
    //               loadshow: "show-n"
    //             })
    //         }).catch(function(error){
    //             console.log(error);
    //         })
    //     }
    //     this.setState({
    //       detailedWeatherMap: e,
    //       forecastTypeClass: e,
    //       floodWeatherMap: e,
    //       overly: "show-m",
    //       loadshow: "show-m"
    //     })
    //     console.log(e, "onchange")
    //     this.renderMapWeather()
    //   }


      onChangeDomesticMonthlyTarget=(event)=>{
        // console.log(typeof(parseInt(event.target.value)))
        // if (Number.isInteger(event.target.value)){
        var total1=parseInt(event.target.value)+parseInt(this.state.export_monthly_target)
        
        this.setState({domestic_monthly_target:event.target.value,total_monthly_target:total1})
        // }
      }


      keyDownDt=(event)=>{
        if (event.key==="Enter"){
          event.target.blur();
          const qry={monthly_domestic_target:parseInt(event.target.value),monthly_export_target:parseInt(this.state.export_monthly_target),total_target:parseInt(this.state.total_monthly_target)}
          redirectURL.post("/consignments/setmonthlytargets",qry).then((response)=>{
            console.log(response)
          }).catch(function(error){
            console.log(error);
          })
        }
        console.log(event.key,event.keyCode,"920")
      }

      keyDownEt=(event)=>{
        if (event.key==="Enter"){
          event.target.blur();
          const qry={monthly_domestic_target:parseInt(this.state.domestic_monthly_target),monthly_export_target:parseInt(event.target.value),total_target:parseInt(this.state.total_monthly_target)}
          redirectURL.post("/consignments/setmonthlytargets",qry).then((response)=>{
            console.log(response)
          }).catch(function(error){
            console.log(error);
          })
        }
        console.log(event.key,event.keyCode,"920")
      }


      onChangeExportMonthlyTarget=(event)=>{
        // console.log(event.key,event.keyCode,"920")
       
        var total2=parseInt(event.target.value)+parseInt(this.state.domestic_monthly_target)
        // console.log(typeof(event.target.value))
        // console.log(typeof(this.state.export_monthly_target))
        
        this.setState({export_monthly_target:event.target.value,total_monthly_target:total2})
        
      }

  onClickShowBulkUpload() {
    this.setState({
      sliderbulkupload: "slider-translate-40p",
      input_funds_received: "",
      input_funds_available: "",
      csvcontent: [],
      overly: "show-m",
    });
  }
  sndPlanningStatus(userType) {
    var params = {
      userType: userType,
    };
    redirectURL.post("/consignments/getsndPlanningStatus",params).then((response)=>{
      var stockData = response.data.records;
      console.log(stockData,"197")
      stockData.map(function(e, index){
          if(index != 0){delete e['funds_available']; delete e['funds_received'] }
      })
      var keyslist = Object.keys(stockData[0]);
      var total_dict = {};
      keyslist.map(function(key){
          if(key != "plant_name" && key != "plant_code" && key != "approved_by" && key != "approval_done_at" 
              && key != "approver_email" && key != "funds_available" && key != "funds_received")
          {
              var temp = 0;
              stockData.map(function(e){
                  temp = temp + e[key]
              });
              total_dict[key] = temp
          }
      })
      total_dict['plant_name'] = "Total";
      total_dict['funds_available'] = "total-"+stockData[0]['funds_available'].toString()
      total_dict['funds_received'] = "total-"+stockData[0]['funds_received'].toString()
      console.log(total_dict)
      stockData.push(total_dict)
      if(userType == "user")
      {
          this.setState({
              gridHeaderName: "Last Data Updated On  "+getHyphenDDMMMYYYYHHMM(stockData[0]['report_generated_at'])
          })
      }
      this.setState({
          stockData : stockData,
          last_approval_date: stockData[0]['approval_done_at'],
          recordsLength : response.data.records.length
      })
  }).catch((e)=>{
      console.log(e);
  })
    // redirectURL
    //   .post("/consignments/getsndPlanningStatus", params)
    //   .then((response) => {
    //     var stockData = response.data.records;
    //     console.log(stockData,"994")
    //     stockData.map(function(e, index) {
    //       if (index != 0) {
    //         delete e["funds_available"];
    //         delete e["funds_received"];
    //       }
    //     });
    //     var keyslist = Object.keys(stockData[0]);
    //     var total_dict = {};
    //     keyslist.map(function(key) {
    //       if (
    //         key != "plant_name" &&
    //         key != "plant_code" &&
    //         key != "approved_by" &&
    //         key != "approval_done_at" &&
    //         key != "approver_email" &&
    //         key != "funds_available" &&
    //         key != "funds_received"
    //       ) {
    //         var temp = 0;
    //         stockData.map(function(e) {
    //           temp = temp + e[key];
    //         });
    //         total_dict[key] = temp;
    //       }
    //     });
    //     total_dict["plant_name"] = "Total";
    //     total_dict["funds_available"] =
    //       "total-" + stockData[0]["funds_available"].toString();
    //     total_dict["funds_received"] =
    //       "total-" + stockData[0]["funds_received"].toString();
    //     console.log(total_dict);
    //     stockData.push(total_dict);
    //     if (userType == "user") {
    //       this.setState({
    //         gridHeaderName:
    //           "Last Data Updated On  " +
    //           getHyphenDDMMMYYYYHHMM(stockData[0]["report_generated_at"]),
    //       });
    //     }
    //     this.setState({
    //       stockData: stockData,
    //       last_approval_date: stockData[0]["approval_done_at"],
    //       recordsLength: response.data.records.length,
    //     });
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  }

  onClickHideAll() {
    this.setState({
      overly: "show-n",
      sliderStateEmailsTranslate: "",
      sliderbulkupload: "",
    });
  }

  onGridReady = (params) => {
    this.gridApi = params.api;

    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
  };

  updateLmStates(params) {
    params["userid"] = localStorage.getItem("userid");
    params["email"] = localStorage.getItem("email");
    params["username"] = localStorage.getItem("username");
    redirectURL
      .post("/consignments/updateRakeUnloadingTerminals", params)
      .then((response) => {
        console.log(response);
        if (response.data.message == "Success") {
          this.setState({
            basicTitle: "Updated Successfully.",
            basicType: "success",
            show: true,
          });
          this.railunloadingterminals(this.state.userType);
        } else {
          this.setState({
            basicTitle: "Failed to update.",
            basicType: "warning",
            show: true,
          });
        }
      });
  }

  approveAllData() {
    var params = {};
    params["userid"] = localStorage.getItem("username");
    params["email"] = localStorage.getItem("email");
    params["username"] = localStorage.getItem("username");
    redirectURL
      .post("/consignments/approvePlanningStatusData", params)
      .then((response) => {
        console.log(response);
        if (response.data.message == "Success") {
          this.setState({
            basicTitle: "Approved.",
            basicType: "success",
            show: true,
          });
          window.location.reload();
        } else {
          this.setState({
            basicTitle: "Failed to approve.",
            basicType: "warning",
            show: true,
          });
        }
      });
  }
  updateAllRows() {
    let rowData = [];
    this.gridApi.forEachNode((node) => rowData.push(node.data));
    console.log(rowData);
    var originalRowData = rowData;

    var check = originalRowData.map(function(e, index) {
      if (index != originalRowData.length - 1) {
        if (e.funds_available == undefined) {
          e["funds_available"] = parseInt(
            originalRowData[0]["funds_available"]
          );
        }
        if (e.funds_received == undefined) {
          e["funds_received"] = parseInt(originalRowData[0]["funds_received"]);
        }
        var updatedata = e;
        var flag = 0;

        for (var key in updatedata) {
          if (
            key != "plant_name" &&
            key != "plant_code" &&
            key != "report_generated_at" &&
            key != "approved_by" &&
            key != "approval_done_at" &&
            key != "approver_email"
          ) {
            if (
              updatedata[key] == "" ||
              updatedata[key] == undefined ||
              updatedata[key] == null
            ) {
              updatedata[key] = 0;
            }
            if (isNaN(parseInt(updatedata[key])) == false) {
              updatedata[key] = parseInt(updatedata[key]);
            } else {
              flag = 1;
              break;
            }
          }
        }

        // if(flag == 0)
        // {
        redirectURL
          .post("/consignments/updatePlanningStatusPlantData", {
            formdata: updatedata,
          })
          .then((response) => {
            //         this.setState({
            //             basicType : "success",
            //             basicTitle: "Updated Successfully",
            //             show: true
            //         })
            //         window.location.reload();
          })
          .catch((e) => console.log(e));

        // }
        // else
        // {
        //     this.setState({
        //         basicType : "warning",
        //         basicTitle: "Invalid Values Entered",
        //         show: true
        //     })
        // }
      }
    });
    Promise.all([check]).then(() => {
      this.setState({
        basicType: "success",
        basicTitle: "Updated Successfully",
        show: true,
      });
      window.location.reload();
    });
  }
  onCellClicked(cell) {
    if (cell.colDef.field == "update" && cell.data.plant_name != "Total") {
      var originalRowData = this.state.stockData;
      if (cell.data.funds_available == undefined) {
        cell.data["funds_available"] = parseInt(
          originalRowData[0]["funds_available"]
        );
      }
      if (cell.data.funds_received == undefined) {
        cell.data["funds_received"] = parseInt(
          originalRowData[0]["funds_received"]
        );
      }
      var updatedata = cell.data;
      var flag = 0;
      console.log(updatedata);
      for (var key in updatedata) {
        if (
          key != "plant_name" &&
          key != "plant_code" &&
          key != "report_generated_at" &&
          key != "approved_by" &&
          key != "approval_done_at" &&
          key != "approver_email"
        ) {
          if (
            updatedata[key] == "" ||
            updatedata[key] == undefined ||
            updatedata[key] == null
          ) {
            updatedata[key] = 0;
          }
          if (isNaN(parseInt(updatedata[key])) == false) {
            updatedata[key] = parseInt(updatedata[key]);
          } else {
            flag = 1;
            break;
          }
        }
      }
      console.log(updatedata);

      console.log(flag, "flag");
      if (flag == 0) {
        redirectURL
          .post("/consignments/updatePlanningStatusPlantData", {
            formdata: updatedata,
          })
          .then((response) => {
            this.setState({
              basicType: "success",
              basicTitle: "Updated Successfully",
              show: true,
            });
            window.location.reload();
          })
          .catch((e) => console.log(e));
      } else {
        this.setState({
          basicType: "warning",
          basicTitle: "Invalid Values Entered",
          show: true,
        });
      }
    }
  }
  changeFileHandler = async (e) => {
    const config = {
      headers: [
        {
          name: "plant_name",
          inputName: "plant_name",
          required: true,
          requiredError: function(headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "stock",
          inputName: "stock",
          required: true,
          requiredError: function(headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "q_hold_stock",
          inputName: "q_hold_stock",
          required: true,
          requiredError: function(headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "dispatchable_stock",
          inputName: "dispatchable_stock",
          required: true,
          requiredError: function(headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "retrieval",
          inputName: "retrieval",
          required: true,
          requiredError: function(headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },

        {
          name: "vehicles_planned",
          inputName: "vehicles_planned",
          required: true,
          requiredError: function(headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "vehicles_invoiced",
          inputName: "vehicles_invoiced",
          required: true,
          requiredError: function(headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "vehicles_exit",
          inputName: "vehicles_exit",
          required: true,
          requiredError: function(headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "opening_pending_exit",
          inputName: "opening_pending_exit",
          required: true,
          requiredError: function(headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "current_pending_exit",
          inputName: "current_pending_exit",
          required: true,
          requiredError: function(headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
      ],
    };
    var data = CSVFileValidator(e.target.files[0], config)
      .then((csvData) => {
        console.log(csvData);
        this.setState({
          csvcontent: csvData.data,
        });
      })
      .catch((err) => {});

    this.setState({
      file: e.target.files[0],
    });

    // if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
    // {

    // }
    // else{
    //     e.target.value = null;
    //     this.setState({
    //         uploadFile:'',
    //         file:"",
    //         show: true,
    //         basicType:'danger',
    //         basicTitle:'Please upload file having extensions .csv only.',
    //     });
    // }
  
  };

  // getStockYardsData=()=>{
  //   const data=this.state.stock_yard_list
  //   const newData=data.map((each)=>{
  //     return (<tr>
  //       <th>{each.plant_name}</th>
  //       <td>0</td>
  //       <td>{each.stock}</td>
  //       <td style={{ padding: 0 }}>
  //         <ProgressBar
  //           height="42px"
  //           borderRadius="0px"
  //           bgColor="#4099ff"
  //           completed={60}
  //         />
  //       </td>
  //       <td>0</td>
  //       <td style={{ padding: 0 }}>
  //         <ProgressBar
  //           height="42px"
  //           borderRadius="0px"
  //           bgColor="#4099ff"
  //           completed={"0"}
  //         />
  //       </td>
  //       <td>0</td>
  //       <td>0</td>
  //       <td>0</td>
  //     </tr>)
  //   })
  //   console.log(newData,"1486")
  //   return newData
  // }


  uploadBulkForceFormHandler(event) {
    event.preventDefault();
    var funds_available = this.state.input_funds_available;
    var funds_received = this.state.input_funds_received;
    var check = this.state.csvcontent.map(function(updatedata) {
      updatedata["funds_available"] = funds_available;
      updatedata["funds_received"] = funds_received;
      for (var key in updatedata) {
        if (
          key != "plant_name" &&
          key != "plant_code" &&
          key != "report_generated_at" &&
          key != "approved_by" &&
          key != "approval_done_at" &&
          key != "approver_email"
        ) {
          if (
            updatedata[key] == "" ||
            updatedata[key] == undefined ||
            updatedata[key] == null
          ) {
            updatedata[key] = 0;
          }
          if (isNaN(parseInt(updatedata[key])) == false) {
            updatedata[key] = parseInt(updatedata[key]);
          }
        }
      }
      console.log(updatedata);
      redirectURL
        .post("/consignments/bulkUpdatePlanningStatusPlantData", {
          formdata: updatedata,
        })
        .then((response) => {})
        .catch((e) => console.log(e));
    });
    Promise.all([check]).then(() => {
      this.setState({
        basicType: "success",
        basicTitle: "Updated Successfully",
        show: true,
      });
      window.location.reload();
    });
  }


  onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  getFleets=(each)=>{
    const scrowData=this.state 
    return(
        <>
            <tr>
                <td style={{border:"solid 2px lightgrey",padding:"4px"}}>{each.railyard_name}</td>
                {/* <td style={{border:"solid 2px lightgrey",padding:"4px"}}>{each.vehicle_stocks}</td> */}
                <td style={{border:"solid 2px lightgrey",padding:"4px"}}>0</td>
            </tr>
        </>
    )
  }


  render() {


    let data=this.state.stock_yard_list
    data=data.filter(each=>each!=="undefined")
    console.log(data,"1560")
    // const newData=data.map((each)=>{
    //   console.log(each,"1561")
    //   return (<tr>
    //     <th>{each.plant_name}</th>
    //     <td>0</td>
    //     <td>{each.stock}</td>
    //     <td style={{ padding: 0 }}>
    //       <ProgressBar
    //         height="42px"
    //         borderRadius="0px"
    //         bgColor="#4099ff"
    //         completed={each.stock_capacity_data}
    //       />
    //     </td>
    //     <td>0</td>
    //     <td style={{ padding: 0 }}>
    //       <ProgressBar
    //         height="42px"
    //         borderRadius="0px"
    //         bgColor="#4099ff"
    //         completed={"0"}
    //       />
    //     </td>
    //     <td>0</td>
    //     <td>0</td>
    //     <td>0</td>
    //   </tr>)
    // })
    // console.log(newData)

    const dStyles={
        width:'100%',
        marginLeft:'20px',
        paddingLeft:'20px',
        marginRight:'0px',
        paddingRight:'0px',
        height:'340px'
    }

    if (this.state.userType == "management") {
      var showTransporter = true;
      var editField = false;
    } else {
      var showTransporter = false;
      var editField = true;
    }
    const rowLength = this.state.stockData.length;


var columnwithDefs = [
    // {
    //     headerName: "update",
    //     field: "update",
    //     width: 130,
    //     cellRenderer: function(params){
    //         if(params.data.plant_name != "Total")
    //         {
    //             return "<button class='btn btn-success' style='padding:0px 25px 5px 27px'>update</button>"
    //         }
    //         else
    //         {
    //             return ""
    //         }
    //     },
    //     hide: showTransporter
    // },
    {
      headerName: "Location",
      field: "plant_name",
      
      filter: true,
      resizable: true,
      editable: false,
      sortable: true,
      cellStyle: { "border-right-color": "#e2e2e2" },
      cellClass: function(params) {
        if (params.data.plant_name != "Total") {
          return ["cellstylegridB", "cellBorder"];
        } else {
          return "";
        }
      },
    },
    {
      headerName: "Funds Available",
      field: "funds_available",
     
      filter: true,
      resizable: true,
      editable: editField,
      sortable: true,
      enableCellChangeFlash: true,
      cellClass: function(params) {
        if (params.data.plant_name != "Total") {
          return ["cellstylegridY", "pstBg"];
        } else {
          return "textAlignCenter";
        }
      },
      valueGetter: function(params) {
        if (params.data.funds_available != undefined) {
          var funds_available = params.data.funds_available.toString();
        } else {
          var funds_available = params.data.funds_available;
        }
        if (funds_available != undefined) {
          if (funds_available.includes("total")) {
            return funds_available.split("-")[1];
          } else {
            return funds_available;
          }
        } else {
          return "";
        }
      },
      rowSpan: function(params) {
        var check = true;
        if (params.data.funds_available != undefined) {
          check = params.data.funds_available.toString().includes("total");
        } else {
          check = false;
        }
        console.log(rowLength,params.data.funds_available,"1345")
        if (params.data.funds_available != undefined && check == false) {
          return 5;
        } else {
          return 0;
        }
      },
    },
    {
      headerName: "Funds Received",
      field: "funds_received",
      
      filter: true,
      resizable: true,
      enableCellChangeFlash: true,
      editable: editField,
      sortable: true,
      cellClass: function(params) {
        if (params.data.plant_name != "Total") {
          return ["bglightblue", "pstBg"];
        } else {
          return "textAlignCenter";
        }
      },
      valueGetter: function(params) {
        if (params.data.funds_received != undefined) {
          var funds_received = params.data.funds_received.toString();
        } else {
          var funds_received = params.data.funds_received;
        }
        if (funds_received != undefined) {
          if (funds_received.includes("total")) {
            return funds_received.split("-")[1];
          } else {
            return funds_received;
          }
        } else {
          return "";
        }
      },
      rowSpan: function(params) {
        var check = true;
        if (params.data.funds_received != undefined) {
          check = params.data.funds_received.toString().includes("total");
        } else {
          check = false;
        }
        if (params.data.funds_received != undefined && check == false) {
          return 5;
        } else {
          return 0;
        }
      },
    },
    {
      headerName: "Retrieval",
      field: "retrieval",
     
      filter: true,
      resizable: true,
      editable: editField,
      sortable: true,
      cellClass: function(params) {
        if (params.data.plant_name != "Total") {
          return ["", "cellBorder"];
        } else {
          return "";
        }
      },
    },
    {
      headerName: "Vehicles Planned",
      field: "vehicles_planned",
      
      filter: true,
      resizable: true,
      editable: editField,
      sortable: true,
      cellClass: function(params) {
        if (params.data.plant_name != "Total") {
          return ["", "cellBorder"];
        } else {
          return "";
        }
      },
    },
    {
      headerName: "Vehicles Invoiced",
      field: "vehicles_invoiced",
      
      filter: true,
      resizable: true,
      editable: editField,
      sortable: true,
      cellClass: function(params) {
        if (params.data.plant_name != "Total") {
          return ["", "cellBorder"];
        } else {
          return "";
        }
      },
    },
    {
      headerName: "Vehicles Exit",
      field: "vehicles_exit",
     
      filter: true,
      resizable: true,
      editable: editField,
      sortable: true,
      cellClass: function(params) {
        if (params.data.plant_name != "Total") {
          return ["", "cellBorder"];
        } else {
          return "";
        }
      },
    },
    {
      headerName: "Opening Pending Exit",
      field: "opening_pending_exit",
     
      filter: true,
      resizable: true,
      editable: editField,
      sortable: true,
      cellClass: function(params) {
        if (params.data.plant_name != "Total") {
          return ["", "cellBorder"];
        } else {
          return "";
        }
      },
    },
    {
      headerName: "Current Pending Exit",
      field: "current_pending_exit",
    
      filter: true,
      resizable: true,
      editable: editField,
      sortable: true,
      cellClass: function(params) {
        if (params.data.plant_name != "Total") {
          return ["", "cellBorder"];
        } else {
          return "";
        }
      },
    },
  ];


    var achievement2=(this.state.export_achievement/this.state.export_monthly_target)*100
    var achievement1=(this.state.domestic_achievement/this.state.domestic_monthly_target)*100
    var achievement3=((this.state.total_monthly_achievement/this.state.total_monthly_target)*100)

    var overall_rate2=(this.state.export_monthly_target-this.state.export_achievement)/this.state.remaining_days
    var overall_rate1=(this.state.domestic_monthly_target-this.state.domestic_achievement)/this.state.remaining_days

    var overall_rate3=(this.state.total_monthly_target-this.state.total_achievement)/this.state.remaining_days

   const {domestic_monthly_target}=this.state
   const {export_monthly_target}=this.state
    return (
      <div class="container-fluid">
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
        <div className="card col-xl-12">
          <div className="card-header text-center">
            
            <h5 style={{ border: "1px solid #ccc", padding: "1%", fontSize:"20px" }}>
            <span style={{position:"relative",left:"-30%",fontSize:"15px",color:"#171C8F"}}>
                        Updated on : {this.state.lastRunTime}
                    </span>
              <span className="" style={{position:"relative",left:"-5%"}}>SALES AND DISPATCH</span>
            </h5>
          </div>

          < div className="row">
          
            
          <div className="col-xl-6 col-lg-6">
            <h5 style={{fontSize:"13px",fontWeight:"bold",textDecoration:"underline"}}>Invoice Status:</h5>
            <div
              className="col-xl-12 col-lg-12"
              style={{ padding: "0px 0px 0px 0px",marginRight:"0px" }}
            >
              <table className="table table-bordered rail-stage-tbl text-center" style={{fontSize:"10px"}}>
                <tr>
                  <th rowSpan={2}>{"Parameters"}</th>
                  <th colSpan={3}>{"Dispatch Summary"}</th>
                </tr>
                <tr>
                  <th>{"Domestic"}</th>
                  <th>{"Export"}</th>
                  <th>{"Total"}</th>
                </tr>

                <tr >
                  <th>Monthly Target</th>
                  <td ><input type="number"  id="monthly_target_domestic" onChange={this.onChangeDomesticMonthlyTarget} onKeyPress={this.keyDownDt} style={{textAlign:"center",border:"none",marginLeft:"20px"}} value={domestic_monthly_target}/></td>
                  <td><input type="number" id="monthly_target_export" onChange={this.onChangeExportMonthlyTarget} onKeyPress={this.keyDownEt} style={{textAlign:"center",border:"none",marginLeft:"20px"}} value={export_monthly_target}/></td>
                  <td>{this.state.total_monthly_target}</td>
                </tr>

                <tr>
                  <th>Achievement</th>
                  <td>{this.state.domestic_achievement}</td>
                  <td>{this.state.export_achievement}</td>
                  <td>{this.state.total_monthly_achievement}</td>
                </tr>

                <tr>
                  <th>%Achieved Till Date</th>
                  <td style={{ padding: 0 }}>
                    <ProgressBar
                      height="42px"
                      borderRadius="0px"
                      bgColor="#4099ff"
                      completed={achievement1.toFixed(2)}
                    />
                  </td>
                  <td style={{ padding: 0 }}>
                    <ProgressBar
                      height="42px"
                      borderRadius="0px"
                      bgColor="#4099ff"
                      completed={achievement2.toFixed(2)}
                    />
                  </td>
                  <td style={{ padding: 0 }}>
                    <ProgressBar
                      height="42px"
                      borderRadius="0px"
                      bgColor="#4099ff"
                      completed={achievement3.toFixed(2)}
                    />
                  </td>
                </tr>

                <tr>
                  <th>Asking Rate Overall</th>
                  <td>{overall_rate1.toFixed(2)}</td>
                  <td>{overall_rate2.toFixed(2)}</td>
                  <td>{overall_rate3.toFixed(2)}</td>
                </tr>
              </table>
            </div>
          </div>  
          

          
            
            <div className="col-xl-6 col-lg-6">
            <span style={{fontSize:"13px",fontWeight:"bold",textDecoration:"underline",width:"100%"}}>Planning Status:
            </span>
            <span style={{fontSize:"13px",color:"#171C8F",textDecoration:"none",fontWeight:"bold",marginLeft:"350px"}}>
                        {this.state.gridHeaderName}
                    </span>
           
           
            <div
              id="myGrid"
              style={{ width: "100%", height: "250px",fontSize:"10px",marginBottom:"15px" }}
              className={"ag-theme-balham"}
            >
               
              
              <AgGridReact
                        modules={this.state.modules}
                        columnDefs={columnwithDefs}
                        headerHeight={70}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.stockData}
                        enableCharts={false}
                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                        onGridReady={this.onGridReady}
                        onGridState={this.onGridState}
                        frameworkComponents={this.state.frameworkComponents}
                        statusBar={this.state.statusBar}
                        sideBar={this.state.sideBar}
                        stopEditingWhenGridLosesFocus= {true}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={false}
                        rowClassRules={this.state.rowClassRules}
                        pinnedBottomRowData={this.state.pinnedRegionBottomRowData}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        gridOptions={{
                            context: { componentParent: this },
                            getRowStyle: function (params) {
                                if (params.node.rowPinned) {
                                  return { 'font-weight': 'bold','font-size':'8px' };
                                }
                            },
                            suppressRowTransform: true,
                        }}
                        // components={this.state.components}
                        enableRangeSelection= {true}
                        // onCellClicked={this.onCellClicked.bind(this)}
                        // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                        />
            </div>
            
            </div>


            

            <div className="col-xl-12 col-lg-12 row" style={{marginBottom:"0px",paddingBottom:"0px"}}>

           
          <div className="col-xl-6 col-lg-6">
          <div className="col-xl-12 col-lg-12" style={{marginLeft:"0px",paddingLeft:"0px",marginRight:"0px",paddingRight:"0px"}}>
              <span style={{fontSize:"13px",fontWeight:"bold",textDecoration:"underline"}}>Stock Status:</span>
              <span style={{fontSize:"13px",color:"#171C8F",textDecoration:"none",fontWeight:"bold",marginLeft:"350px"}}>
                        {this.state.gridHeaderName}
                    </span>
              <table className="table table-bordered text-center" style={{fontSize:"10px"}}>
                <tr>
                  <th>Location</th>
                  <th>Capacity</th>
                  <th>Stock</th>
                  <th>Stockyard Utilization</th>
                  {/* <th>Hail Net Stock</th>
                  <th>%Stock under Hail Net</th> */}
                  <th>DISPATCHABLE STOCK</th>
                  <th>Q HOLD</th>
                  <th>OTHER HOLD</th>
                </tr>
                {data.map((each)=>{
                  let o_hold=each.stock-each.dispatchable_stock-each.q_hold_stock
      return (<tr>
        <th>{each.plant_name}</th>
        <td>{each.capacity}</td>
        <td>{each.stock}</td>
        <td style={{ padding: 0 }}>
          <ProgressBar
            height="42px"
            borderRadius="0px"
            bgColor="#4099ff"
            labelSize="8px"
            labelAlignment="left"
            completed={each.stock_capacity_data}
          />
        </td>
        {/* <td>0</td>
        <td style={{ padding: 0 }}>
          <ProgressBar
            height="42px"
            borderRadius="0px"
            bgColor="#4099ff"
            completed={"0"}
          />
        </td> */}
        <td>{each.dispatchable_stock}</td>
        <td>{each.q_hold_stock}</td>
        <td>{o_hold}</td>
      </tr>)
    })}
                
               
              </table>
            </div>
            <div className="col-xl-12 col-lg-12" style={{marginLeft:"0px",paddingLeft:"0px",marginRight:"0px",paddingRight:"0px"}}>
          {/* <div className="col-xl-12 col-lg-12" style={{marginLeft:"0px",paddingLeft:"0px",marginRight:"0px",paddingRight:"0px"}}>
          
          <h5 style={{fontSize:"13px",fontWeight:"bold",textDecoration:"underline"}}>Transit Vehicle Park (TVP):</h5>
          
          <table className="table table-bordered rail-stage-tbl text-center" style={{marginBottom:"20px",fontSize:"9px"}}>
                  <tr style={{fontSize:"12px"}}>
                      <th  rowSpan={2}>Stock Yard Loction</th>
                      <th colSpan={2}>Realtime Status</th>
                      <th colSpan={4}>MTD Status</th>
                  </tr>
                  <tr>
                      <th>Stock</th>
                      <th>Transit</th>
                      <th>Factory Invoice</th>
                      <th>Receipt</th>
                      <th>Fund Validation</th>
                      <th>Exit</th>
                  </tr>
                  <tr>
                      <th>TVP banglore</th>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                  </tr>
                  <tr>
                      <th>TVP Nagpur</th>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                  </tr>
                  <tr>
                      <th>TVP Siliguri</th>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                  </tr>
                  
              </table>
              </div> */}
              {/* <div className="col-xl-12 col-lg-12">
              <table className="table table-bordered rail-stage-tbl text-center" style={{fontSize:"9px"}}>
                
                    <tr>
                    <th colSpan={2}>Fund Reciept</th>
                    <th colSpan={3}>Funded Stock Status</th>
                    </tr>
                    <tr>
                        <th>Stock</th>
                        <th>Transit</th>
                        <th>Planned</th>
                        <th>Unplanned</th>
                        <th>Total</th>
                    </tr>
                    <tr>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                </table>
                </div> */}
                </div>
                </div>
            <div className="col-xl-6 col-lg-6" style={{marginRight:"0px",paddingRight:"0px"}}>
            <h5 style={{fontSize:"13px",fontWeight:"bold",textDecoration:"underline",marginLeft:"20px"}}>Fleet Status: MSIL Loading Locations
</h5>
                <div class="col-xl-12 col-sm-12 col-xs-12 col-md-12 row wdiv" style={{marginRight:"0px",paddingRight:"0px"}}>
                <div id="mapweather" className="col-lg-12 col-xl-12" style={dStyles}></div>
                </div>
                <div style={{position:"absolute",bottom:"50px",marginLeft:"30px",backgroundColor:"white",fontSize:"8px",border:"0.5px solid dark grey"}}>
                    
                    {/* <tabel className="tabel text-center">
                        <tr>
                            <th style={{border:"solid 2px lightgrey",padding:"4px"}}>MSIL Locations</th>
                        <th  style={{border:"solid 2px lightgrey",padding:"4px"}}>Fleet nos</th>
                        </tr>
                        <tr>
                            <td style={{border:"solid 2px lightgrey",padding:"4px"}}>Gurgaon</td>
                            <td style={{border:"solid 2px lightgrey",padding:"4px"}}>325</td>
                        </tr>
                        <tr>
                            <td style={{border:"solid 2px lightgrey",padding:"4px"}}>Gurgaon</td>
                            <td style={{border:"solid 2px lightgrey",padding:"4px"}}>325</td>
                        </tr>
                        <tr>
                            <td style={{border:"solid 2px lightgrey",padding:"4px"}}>Gurgaon</td>
                            <td style={{border:"solid 2px lightgrey",padding:"4px"}}>325</td>
                        </tr>

                        <tr>
                            <td style={{border:"solid 2px lightgrey",padding:"4px"}}>Gurgaon</td>
                            <td style={{border:"solid 2px lightgrey",padding:"4px"}}>325</td>
                        </tr>
                        <tr>
                            <td style={{border:"solid 2px lightgrey",padding:"4px"}}>Gurgaon</td>
                            <td style={{border:"solid 2px lightgrey",padding:"4px"}}>325</td>
                        </tr>
                        <tr>
                            <td style={{border:"solid 2px lightgrey",padding:"4px"}}>Gurgaon</td>
                            <td style={{border:"solid 2px lightgrey",padding:"4px"}}>325</td>
                        </tr>
                        <tr>
                            <td style={{border:"solid 2px lightgrey",padding:"5px"}}>Gurgaon</td>
                            <td style={{border:"solid 2px lightgrey",padding:"5px"}}>325</td>
                        </tr>
                    </tabel> */}
                     <tabel className="tabel text-center">
                        <tbody>
                            <tr>
                                <th style={{border:"solid 2px lightgrey",padding:"4px"}}>MSIL Locations</th>
                                <th  style={{border:"solid 2px lightgrey",padding:"4px"}}>Fleet nos</th>
                            </tr>
                            <>
                            {(this.state.scrowData).map((each)=>this.getFleets(each))}
                        </>
                        </tbody>
                    </tabel>
                </div>
                {/* <div className="col-xl-12 col-lg-12">
              <table className="table table-bordered rail-stage-tbl text-center" style={{fontSize:"9px",marginTop:"15px"}}>
                
                    <tr>
                    <th colSpan={2}>Fund Reciept</th>
                    <th colSpan={3}>Funded Stock Status</th>
                    </tr>
                    <tr>
                        <th>Stock</th>
                        <th>Transit</th>
                        <th>Planned</th>
                        <th>Unplanned</th>
                        <th>Total</th>
                    </tr>
                    <tr>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                </table>
                </div> */}
              </div>
              {/* <div className="col-xl-6 col-lg-6">
          <div className="col-xl-12 col-lg-12">
          
          <h5 style={{fontSize:"17px",fontWeight:"bold",textDecoration:"underline"}}>Transit Vehicle Park (TVP):</h5>
          
          <table className="table table-bordered rail-stage-tbl text-center" style={{marginBottom:"20px",fontSize:"9px"}}>
                  <tr style={{fontSize:"12px"}}>
                      <th  rowSpan={2}>Stock Yard Loction</th>
                      <th colSpan={2}>Realtime Status</th>
                      <th colSpan={4}>MTD Status</th>
                  </tr>
                  <tr>
                      <th>Stock</th>
                      <th>Transit</th>
                      <th>Factory Invoice</th>
                      <th>Receipt</th>
                      <th>Fund Validation</th>
                      <th>Exit</th>
                  </tr>
                  <tr>
                      <th>TVP banglore</th>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                  </tr>
                  <tr>
                      <th>TVP banglore</th>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                  </tr>
                  <tr>
                      <th>TVP banglore</th>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                  </tr>
                  
              </table>
              </div>
              <div className="col-xl-12 col-lg-12">
              <table className="table table-bordered rail-stage-tbl text-center" style={{fontSize:"9px"}}>
                
                    <tr>
                    <th colSpan={2}>Fund Reciept</th>
                    <th colSpan={3}>Funded Stock Status</th>
                    </tr>
                    <tr>
                        <th>Stock</th>
                        <th>Transit</th>
                        <th>Planned</th>
                        <th>Unplanned</th>
                        <th>Total</th>
                    </tr>
                    <tr>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                </table>
                </div>
                </div> */}
              </div>
            
            
            </div>
          </div>
        </div>
     
    );
  }
}

function loadDateTimeScript() {
  // alert("timeDate");
  $(".datetimepicker_mask").datetimepicker({
    //mask:'39-19-9999 29:59',
    format: "d-m-Y",
  });
  $(".datetimepicker_date").datetimepicker({
    mask: "39-19-9999",
    format: "d-m-Y",
    timepicker: false,
  });
  var index = window.document.getElementsByTagName("script")[1];
  var script = window.document.createElement("script");
  script.src =
    "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js";
  //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
  script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}


function loadScriptWeather(url){
    console.log(window.screen.availHeight,
    window.screen.availWidth)
    if(window.screen.availHeight <= 1024)
    {
      $("#mapweather").removeClass("col-md-8");
      $("#weathermapgrid").removeClass("col-md-4");
      $("#mapweather").addClass("col-md-8");
      $("#weathermapgrid").addClass("col-md-8");
    }
    var index  =window.document.getElementsByTagName("script")[1]
      var script = window.document.createElement("script")
      script.src=url
      script.async=true
      script.defer = true
      index.parentNode.insertBefore(script,index)
  }

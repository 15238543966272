
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const ImportsNykCoilsInfoReceivedAction = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onShowImportsCoilLegs(props);
    };
    

    let colr = 'greenfont';
    return (
        <div>
            <button onClick={handleEventClick} className="custom-btn f22 label label-success" 
            title="High Seas" ><i className={"icofont icofont-ship "+(colr)}></i> &nbsp;</button>
            
        </div>
    );
};

export default ImportsNykCoilsInfoReceivedAction;

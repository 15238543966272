import React, { Component } from "react";
import moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from "@ag-grid-community/react";
import Select from "react-select";
import $ from "jquery";
import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import "@ag-grid-community/core/dist/styles/ag-grid.css";

import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
// import { formatDate } from "@fullcalendar/react";
var redirectURL = require("../redirectURL");
var infoBox = require('../common/google-infowindow');

var googleAnalytics = require("../common/googleanalytics");
var map;
var markersArray;
var viewMarkersArr = [];
export default class SustainabilityConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      eventFormAction: "formAction",
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        width: 160,
        resizable: true,
      },
      rowSelection: "single",
      eventAction: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      bulkslide: "",
      editslide: "",
      truck_type:"",
      average_payload:"",
      mileage:"",
      edit_truck_type:"",
      edit_average_payload:"",
      edit_mileage:"",
      sliderbulkupload: "",
      main_id:"",
      rowModelhighchartoptionsType: "enterprise",
      frameworkComponents: {
        consignmentActions: ConsignmentActions,
      },
      rowGroupPanelShow: "always",
      statusBar: {

      },
      paginationPageSize: 50,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
    };
  }
  async componentDidMount() {
    await this.setState({
        loadshow:"show-m",
        overly:"show-m"
    })
    this.onLoadPageData()
     
  }
  onLoadPageData = ()=>{
    redirectURL.post("/dashboard/getsustainabilityconfigurationdata").then(async(response)=>{
      await this.setState({
          rowData:response.data,
          overly:"show-n",
          loadshow:"show-n"
      })
  }) 
  }
  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      bulkslide:"",
      truck_type:"",
      mileage:"",
      average_payload:"",
      editslide:""
    });
  }

  async onCellClicked(event) {
    // console.log(event,"100")
    if(event.colDef.field == "delete"){
      await this.setState({
        loadshow:"show-m",
        overly:"show-m"
      })
      var cnf = window.confirm("Are You Sure! Do you really want to delete?")
      if(cnf === true){
        var rowId = event.data._id
        var params ={
          rowId : rowId,
          email : localStorage.getItem("email")
        }
        redirectURL.post("/dashboard/deleteSustainabilityConfigurationData",params).then((response)=>{
            if(response.data.message == "Success"){
              this.setState({
                show:true,
                basicTitle:"Successfully Deleted",
                basicType:"success",
                loadshow:"show-n",
                overly:"show-n"
              })
              this.onLoadPageData()
            }else{
              this.setState({
                show:true,
                basicTitle:response.data.message,
                basicType:"warning",
                loadshow:"show-n",
                overly:"show-n"
              })
              this.onLoadPageData()
            }
        })

      }else{
        this.setState({
          loadshow:"show-n",
          overly:"show-n"
        })
      }
    }
    if(event.colDef.field == "edit"){
      this.setState({
        overly:"show-m",
        edit_average_payload:event.data.average_payload,
        edit_mileage : event.data.mileage,
        main_id : event.data._id,
        edit_truck_type : event.data.truck_type,
        editslide : "slider-translate-30p"
      })
    }
  }
 
  onGridReady = params => {
    this.gridApi = params.api;
    // //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
  };
  closeAlert = () =>{
    this.setState({
        show:false
    })
  }
  onGridState = () => {
    //console.log(this.gridApi);
    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();
    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
    /*Get Current Pivot Columns State and Store in this.pivotColumns,
     * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
}
addSustainabilityConfigurationData = ()=>{
    this.setState({
        bulkslide : "slider-translate-30p",
        overly:"show-m"
    })
}
addConfigurationData = (event) =>{
    event.preventDefault()
    this.setState({
        loadshow:"show-m",
        overly:"show-m"
    })
    var truck_type = this.state.truck_type
    var average_payload = this.state.average_payload
    var mileage = this.state.mileage
    if(![undefined,"undefined",null,""].includes(truck_type) && ![undefined,"undefined",null,""].includes(average_payload) && ![undefined,"undefined",null,""].includes(mileage)){
        var params ={
            truck_type : truck_type,
            average_payload : average_payload,
            mileage : mileage,
            email : localStorage.getItem("email")
        }
        redirectURL.post("/dashboard/addSustainabilityConfigurationData",params).then((response)=>{
            if(response.data.message == "Success"){
                this.setState({
                    basicTitle:"Data Successfully Added",
                    basicType:"success",
                    show:true,
                })
                this.onLoadPageData()
                this.onClickHideAll()
            }else{
                this.setState({
                    basicTitle:response.data.message,
                    basicType:"warning",
                    show:true,
                })
                this.onClickHideAll()
            }
        })
    }else{
        this.setState({
            loadshow:"show-n",
            overly:"show-n",
            basicTitle:"Please Enter Mandatory Fields",
            basicType:"danger",
            show:true
        })
    }
}
editConfigurationData=(event)=>{
  event.preventDefault()
  this.setState({
    loadshow:"show-m",
    overly:"show-m"
  })
  var row_id = this.state.main_id
  var average_payload = this.state.edit_average_payload
  var mileage = this.state.edit_mileage
  var truck_type = this.state.edit_truck_type
  var params = {
    row_id : row_id,
    average_payload : average_payload,
    mileage : mileage,
    truck_type : truck_type,
    email : localStorage.getItem("email")
  }
  redirectURL.post("/dashboard/editSustainabilityConfigurationData",params).then((response)=>{
    if(response.data.message == "Success"){
      this.setState({
        basicTitle:"Data Successfully Updated",
        basicType:"success",
        show:true,
      })
      this.onLoadPageData()
      this.onClickHideAll()
    }else{
      this.setState({
        basicTitle:response.data.message,
        basicType:"warning",
        show:true,
    })
    this.onClickHideAll()
    }
  })
}
changeHandler1 = (event) => {
    var value = event.target.value.slice(0,4)
    var name = event.target.name
    const alphanumericRegex = /^[a-zA-Z0-9]*$/;
    if (alphanumericRegex.test(value) || value === '') {
        var final_value = value.split("")
        var number_arr = ["1","2","3","4","5","6","7","8","9","0"]
        var main_string = ''
        final_value.map((e)=>{
            if(number_arr.includes(e)){
                main_string += e
            }else{
                main_string += e.toUpperCase()
            }
        })
      this.setState({
        [name] :main_string
      })
    }
}
validateMileageInput = (event) => {
    const inputValue = event.target.value.trim();
    const floatRegex = /^\d{0,2}(\.\d{0,2})?$/;
    if(inputValue !=undefined && inputValue !="")
    {
        if (!floatRegex.test(inputValue)) {
        
        } else {
        this.setState({
            mileage: inputValue,
        });
        }
    }else{
        this.setState({
            mileage: "",
        });
    }
  };
  editvalidateMileageInput = (event) => {
    const inputValue = event.target.value.trim();
    const floatRegex = /^\d{0,2}(\.\d{0,2})?$/;
    if(inputValue !=undefined && inputValue !="")
    {
        if (!floatRegex.test(inputValue)) {
        
        } else {
        this.setState({
            edit_mileage: inputValue,
        });
        }
    }else{
        this.setState({
            edit_mileage: "",
        });
    }
  };
  validateAveragePayloadInput = (event) => {
    const inputValue = event.target.value.trim();
    const floatRegex = /^\d{0,5}(\.\d{0,3})?$/;
    if(inputValue !=undefined && inputValue !="")
    {
        if (!floatRegex.test(inputValue)) {
        
        } else {
            this.setState({
                average_payload: inputValue,
            });
        }
    }
    else
    {
        this.setState({
            average_payload: "",
        });
    }
  };
  editvalidateAveragePayloadInput = (event) => {
    const inputValue = event.target.value.trim();
    const floatRegex = /^\d{0,5}(\.\d{0,3})?$/;
    if(inputValue !=undefined && inputValue !="")
    {
        if (!floatRegex.test(inputValue)) {
        
        } else {
            this.setState({
                edit_average_payload: inputValue,
            });
        }
    }
    else
    {
        this.setState({
          edit_average_payload: "",
        });
    }
  };
  render() {

    const columnWithDefs = [
        {
            headerName : "Truck Type",
            field: "truck_type",
            width: 160,
            filter: true,
        },
        {
            headerName : "Mileage (Kmpl)",
            field: "mileage",
            width: 160,
            filter: true,
        },
        {
            headerName : "Average Payload (Kg)",
            field: "average_payload",
            width: 160,
            filter: true,
        },
        {
          pinned:"left",
          headerName : "",
          field:"delete",
          width:40,
          resizable : true,
          editable : false,
          cellRenderer:function(params){       
              return '<i class="icofont icofont-ui-delete"></i>';
          },
          filter:false,
      },   
      {
        pinned:"left",
        headerName : "",
        field:"edit",
        width:40,
        resizable : true,
        editable : false,
        cellRenderer:function(params){       
            return '<i class="icofont icofont-ui-edit"></i>';
        },
        filter:false,
    },        
    ];
    var month = window.btoa(this.state.month)
    const addFormPath = "/transportergpsreviewscreenform/"+month
    const allrowData = this.state.allRowData
    return (
      <div class="container-fluid">
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
        <div className="card col-xl-12">
          <div className="card-header">
                <h5>
                <i className="icofont icofont-ui-settings" style={{marginLeft:"15px"}}></i>{" "}
                <span>Sustainability Configuration</span>
                <button onClick={this.addSustainabilityConfigurationData} className="btn btn-info" style={{ float: "right", marginRight: "10px", color:"#ffffff" }} >ADD</button>
                </h5>
              <div className="card-body">
                <div className="row">
                  <div
                    id="myGrid"
                    style={{ width: "100%", height: "500px" }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      modules={this.state.modules}
                      columnDefs={columnWithDefs}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.rowData}
                      rowSelection={this.state.rowSelection}
                      enableCharts={false}
                      onGridReady={this.onGridReady}
                      onGridState={this.onGridState}
                      frameworkComponents={this.state.frameworkComponents}
                    //   rowClassRules={rowClassRules}
                      sideBar={this.state.sideBar}
                      stopEditingWhenGridLosesFocus={true}
                      onCellClicked={this.onCellClicked.bind(this)}
                    //   paginationPageSize={this.state.paginationPageSize}
                    //   pagination={true}
                      enableCellChangeFlash={true}
                      suppressCellFlash={true}
                      gridOptions={{
                        context: { componentParent: this },
                        suppressRowTransform: true, 
                      }}
                      // onCellClicked={this.onCellRowClicked.bind(this)}
                      enableRangeSelection={true}
                    />
                  </div>
                </div>
              </div>
         
          </div>
          <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">Add Configuration Details
            <span className="float-right fclose" style={{marginRight: "12px",padding: "1px 8px",marginTop: "-2px",}} onClick={this.onClickHideAll.bind(this)}>x</span></h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.addConfigurationData}>
                    <div className="col-xl-12 col-lg-12 form-group">
                        <label>Truck Type<span style={{color:"#FF0000"}}>*</span></label>
                        <input type="text" name="truck_type" id="truck_type" value = {this.state.truck_type} className="form-control" autoComplete="off" onChange={this.changeHandler1} required />
                    </div>
                    <div className="col-xl-12 col-lg-12 form-group">
                        <label>Mileage (Kmpl)<span style={{color:"#FF0000"}}>*</span></label>
                        <input type="text" name="mileage" id="mileage" value = {this.state.mileage} className="form-control" autoComplete="off" onChange={this.validateMileageInput} required />
                    </div>
                    <div className="col-xl-12 col-lg-12 form-group">
                        <label>Average Payload (Kg)<span style={{color:"#FF0000"}}>*</span></label>
                        <input type="text" name="average_payload" id="average_payload" value = {this.state.average_payload} className="form-control" autoComplete="off" onChange={this.validateAveragePayloadInput} required />
                    </div>
                    <div className="form-group col-xl-3 col-lg-3">
                        <button type="submit" className="btn btn-success">Submit</button>
                    </div>
                    </form>
                    <div className="form-group">
                    </div>		
                </div>
            </div>
        </div>
        <div className={"slide-r "+(this.state.editslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">Edit Configuration Details
            <span className="float-right fclose" style={{marginRight: "12px",padding: "1px 8px",marginTop: "-2px",}} onClick={this.onClickHideAll.bind(this)}>x</span></h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.editConfigurationData}>
                    <div className="col-xl-12 col-lg-12 form-group">
                        <label>Truck Type<span style={{color:"#FF0000"}}>*</span></label>
                        <input type="text" name="edit_truck_type" id="edit_truck_type" value = {this.state.edit_truck_type} className="form-control" autoComplete="off" onChange={this.changeHandler1} disabled={true} required />
                    </div>
                    <div className="col-xl-12 col-lg-12 form-group">
                        <label>Mileage (Kmpl)<span style={{color:"#FF0000"}}>*</span></label>
                        <input type="text" name="edit_mileage" id="edit_mileage" value = {this.state.edit_mileage} className="form-control" autoComplete="off" onChange={this.editvalidateMileageInput} required />
                    </div>
                    <div className="col-xl-12 col-lg-12 form-group">
                        <label>Average Payload (Kg)<span style={{color:"#FF0000"}}>*</span></label>
                        <input type="text" name="edit_average_payload" id="edit_average_payload" value = {this.state.edit_average_payload} className="form-control" autoComplete="off" onChange={this.editvalidateAveragePayloadInput} required />
                    </div>
                    <div className="form-group col-xl-3 col-lg-3">
                        <button type="submit" className="btn btn-success">Submit</button>
                    </div>
                    </form>
                    <div className="form-group">
                    </div>		
                </div>
            </div>
        </div>
          {/* work in progress Fresh Incoming rakes */}

        </div>
        <div className={"dataLoadpage " + (this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
          <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>
    );
  }
}



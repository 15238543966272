import React, { Component } from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
var redirectURL = require("../redirectURL");
export default class EmissionFactors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      diesel_truck: 0,
      diesel_trailer: 0,
      cng_truck: 0,
      cng_trailer: 0,
      overly: "show-n",
      loadshow: "show-n",
      show: false,
      mainType:'',
      rail_co2_emission:0
    };
    this.handleChange = this.handleChange.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.onClickHideAll = this.onClickHideAll.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
  }
  async componentDidMount() {
    var mainType = this.props.mainType
    this.setState({
      mainType:mainType,
      loadshow: "show-m",
      overly: "show-m"
    });
    await redirectURL
      .post("/consignments/emissionFactoronMount")
      .then((response) => {
        var totalData = response.data
        console.log(totalData,'totalData',totalData[0].cng_trailer)
        this.setState({
          diesel_truck: totalData[0].diesel_truck,
          diesel_trailer: totalData[0].diesel_trailer,
          cng_truck: totalData[0].cng_truck,
          cng_trailer: totalData[0].cng_trailer,
          rail_co2_emission:totalData[1].rail_co2_emission,
          loadshow: "show-n",
          overly: "show-n",
        })
      })
  }
  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
    });
  }

  handleChange(event) {
    const { name, value } = event.target;
    console.log(name, value,'name, value')
    this.setState({
      [name]: parseFloat(value)
    });
  }
  async saveChanges(e) {
    e.preventDefault();
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    })
    if(this.state.mainType == "road"){
      var diesel_truck = this.state.diesel_truck;
      var diesel_trailer = this.state.diesel_trailer;
      var cng_truck = this.state.cng_truck;
      var cng_trailer = this.state.cng_trailer;
      console.log(diesel_truck == NaN ,diesel_truck == 'NaN',diesel_truck == null,diesel_truck == 0,'1',diesel_trailer,'2',cng_truck,'3',cng_trailer,'4')
      var formData = new FormData();
      formData.append('diesel_truck', diesel_truck)
      formData.append('diesel_trailer', diesel_trailer)
      formData.append('cng_truck', cng_truck)
      formData.append('cng_trailer', cng_trailer)
      formData.append('mainType', this.state.mainType)
      formData.append('updated_by', localStorage.getItem("username"))
      if (diesel_truck != "" && diesel_truck != undefined && diesel_truck != NaN   && diesel_trailer != "" && diesel_trailer != undefined && diesel_trailer != NaN  && cng_truck != "" && cng_truck != undefined && cng_truck != NaN  && cng_trailer != "" && cng_trailer != undefined && cng_trailer != NaN) {
  
        await redirectURL
          .post("/consignments/updateemissionFactor", formData)
          .then((response) => {
            this.setState({
              show: true,
              loadshow: "show-n",
              overly: "show-n",
              basicType: "success",
              basicTitle: "Updated Successfully",
            })
  
          })
        this.componentDidMount()
      } else {
        this.setState({
          show: true,
          basicType: "warning",
          loadshow: "show-n",
          overly: "show-n",
          basicTitle: "All fields are mandatory",
        })
      }
    }else if(this.state.mainType == 'rail'){
      var rail_co2_emission = this.state.rail_co2_emission;
    
      var formData = new FormData();
      formData.append('rail_co2_emission', rail_co2_emission)
      formData.append('mainType', this.state.mainType)
      formData.append('updated_by', localStorage.getItem("username"))
      if (rail_co2_emission != "" && rail_co2_emission != undefined && rail_co2_emission != NaN ) {
        await redirectURL
          .post("/consignments/updateemissionFactor", formData)
          .then((response) => {
            this.setState({
              show: true,
              loadshow: "show-n",
              overly: "show-n",
              basicType: "success",
              basicTitle: "Updated Successfully",
            })
  
          })
        this.componentDidMount()
      } else {
        this.setState({
          show: true,
          basicType: "warning",
          loadshow: "show-n",
          overly: "show-n",
          basicTitle: "All fields are mandatory",
        })
      }
    }
  }
  handleKeyPressCode = (event) => {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^[0-9.]*$/.test(inputChar)) {
      event.preventDefault(); // Prevent the character from being entered
    }
  };
  
  closeAlert = () => {
    this.setState({
      show: false,
    });
  };

  render() {
    var styleForP = {
        fontSize : "larger",
        fontWeight:'bolder',
        margin:"20px 100px"
    }
    const { diesel_truck, diesel_trailer, cng_truck, cng_trailer } = this.state;
    return (
      <div  class="container-fluid" >
          <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
        <h4> 
              <i className="icofont icofont-vehicle-delivery-van cus-i"></i>{"   "}
              <span>Emission factor by fuel type</span>
        </h4>
        <div className="card col-xl-12">
       {this.state.mainType == 'road' ? <div className="card-body">
          <div>
          <div><button className="btn btn-info" style={{ float: "right", marginRight: "10px" }}
           onClick={this.saveChanges.bind(this)}
           >Save Changes</button>
          </div> 
          <h5 style={{fontWeight:'bold'}}>Emissions Factor : (Diesel)</h5>
          <p style={styleForP}>
            Truck    :{"    "}
            <input
              type="number"
              name="diesel_truck"
              id='diesel_truck'
              value={diesel_truck}
              onKeyPress={this.handleKeyPressCode}
              onChange={this.handleChange}
              required

            /> {"    "} kgCO2e/t-km
          </p>
          <p style={styleForP}>
             Trailer    :{"    "}
            <input
              type="number"
              name="diesel_trailer"
              id='diesel_trailer'
              value={diesel_trailer}
              onKeyPress={this.handleKeyPressCode}
              onChange={this.handleChange}
              required
            /> {"    "} kgCO2e/t-km
          </p>
          </div>
        <div>
          <h5 style={{fontWeight:'bold'}}>Emissions Factor : (CNG)</h5>
          <p style={styleForP}>
            Truck     :{"    "}
            <input
              type="number"
              name="cng_truck"
              id='cng_truck'
              value={cng_truck}
              onKeyPress={this.handleKeyPressCode}
              onChange={this.handleChange}
              required
            /> {"    "}kgCO2e/t-km
          </p>
          <p style={styleForP}>
            Trailer     :{"    "}
            <input
              type="number"
              name="cng_trailer"
              id='cng_trailer'
              value={cng_trailer}
              onKeyPress={this.handleKeyPressCode}
              onChange={this.handleChange}
              required
            /> {"    "}kgCO2e/t-km
          </p>
        </div>
</div> : <div className="card-body">
          <div>
          <div><button className="btn btn-info" style={{ float: "right", marginRight: "10px" }}
           onClick={this.saveChanges.bind(this)}
           >Save Changes</button>
          </div> 
          <h5 style={{fontWeight:'bold'}}>Emissions Factor : (Rail)</h5>
          <p style={styleForP}>
            Rail    :{"    "}
            <input
              type="number"
              name="rail_co2_emission"
              id='rail_co2_emission'
              value={this.state.rail_co2_emission}
              onKeyPress={this.handleKeyPressCode}
              onChange={this.handleChange}
              required

            /> {"    "} kgCO2e/t-km
          </p>
         
          </div></div> }
</div>
<div className={"dataLoadpage " + (this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
          <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>
    );
  }
}


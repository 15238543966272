'use strict';
import React, { Component, useDebugValue } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import CommentActions from "./commentsComponent.js";
import { AllModules } from '@ag-grid-enterprise/all-modules';
import MarkDeliver from "./markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent.js';
import SweetAlert from 'react-bootstrap-sweetalert';
import Consignmentforceclose from '../manage/consignmentforceclose.js';
import UpdateButton from "./updatelmstates.js";
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import EditroleAction from './editroleAction.js';
import { indexOf } from 'lodash';
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL.js');




export default class NexaScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            frameworkComponents: {
                consignmentforceclose: Consignmentforceclose,
                consignmentActions: ConsignmentActions,
                UpdateButton: UpdateButton,
                MarkDeliver: MarkDeliver,
                commentActions: CommentActions,
                EditroleAction: EditroleAction,
            },
            basicTitle: '',
            bulkslide: "",
            basicType: "default",
            loadshow: 'show-n',
            rowData: [],
            paginationPageSize: 200,
            overly: "show-n",
            loadshow: "show-n",
            portNames: [],
            lpNames: [],
            selectedPort: '',
            oldConsignVlaue: '',
            oldDeliveryCode: '',
            filteredData: []
        }
        this.onCellClicked = this.onCellClicked.bind(this)
        this.onSubmitConsignee = this.onSubmitConsignee.bind(this)
    }

    componentDidMount = async () => {

        await redirectURL.post("/lmconsignees/getConsigneeDeliveryMap")
            .then(async (response) => {
                var consignments = response.data;
                var portNames = []
                var names = []
                if (consignments.length > 0) {
                    consignments.map((item) => {
                        var portCode = item.loading_port_code
                        if (!names.includes(portCode)) {
                            portNames.push({ portCode: portCode, port: portCode })
                            names.push(portCode)
                        }
                    })
                }
                await this.setState({
                    rowData: consignments,
                    lpNames: portNames,
                    selectedPort: portNames[0].portCode
                })


            }).catch((e) => {
                console.log(e);
            })

        await this.getDetailsData()
    }

    getDetailsData = async () => {
        var consignments = []
        await redirectURL.post("/lmconsignees/getConsigneeDeliveryMap")
            .then(async (response) => {
                consignments = response.data;
                var portNames = []
                var names = []
                if (consignments.length > 0) {
                    consignments.map((item) => {
                        var portCode = item.loading_port_code
                        if (!names.includes(portCode)) {
                            portNames.push({ portCode: portCode, port: portCode })
                            names.push(portCode)
                        }
                    })
                }
                await this.setState({
                    rowData: consignments,
                    lpNames: portNames,
                    selectedPort: portNames[0].portCode
                })


            }).catch((e) => {
                console.log(e);
            })

        var selectedPort = $("#loadingPort").val();
        var filteredData = consignments.filter((item) => {
            if (item.status && item.loading_port_code === selectedPort) {
                return item
            }
        })
        await this.setState({ filteredData })
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }



    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onCellClicked(e) {
        if (e.colDef.field == "delete") {
            // console.log(e.data.delivery_consignee_code, 'hello')
            var params = { consignee_code: e.data.consignee_code, delivery_consignee_code: e.data.delivery_consignee_code }
            if (window.confirm("Are You Sure! you want to delete?")) {
                redirectURL.post("/lmconsignees/statusChangeofConsignee", params)
                    .then((response) => {
                        if (response.status == "200") {
                            this.setState({
                                basicTitle: "Deleted Successfully.",
                                basicType: "success",
                                show: true
                            })
                        }
                        else {
                            this.setState({
                                basicTitle: "Failed to Delete.",
                                basicType: "warning",
                                show: true
                            })
                        }
                        this.getDetailsData()
                    }).catch((e) => {
                        console.log(e);
                    })
            }
        }
    }

    onClickHideAll = async () => {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            bulkslide: "",
            oldConsignVlaue: '',
            oldDeliveryCode: ''
        });

        $("#consignee_code").val("");
        $("#delivery_code").val("");
    }
    showAddConsignee = () => {
        this.setState({
            overly: "show-m",
            bulkslide: "slider-translate"
        })
        
    }
    resetUpload = () => {
        this.setState({
            bulkslide: '',
            overly: 'show-n',
        });
        document.getElementById("upform").reset();
    }

    onShowEditMenu = (params) => {
        this.setState({
            bulkslide: 'slider-translate',
            overly: 'show-m',
            oldDeliveryCode: params.data.delivery_consignee_code,
            oldConsignVlaue: params.data.consignee_code
        })

        $("#consignee_code").val(params.data.consignee_code)
        $("#delivery_code").val(params.data.delivery_consignee_code)
    }

    onSubmitConsignee = (event) => {
        event.preventDefault();
        var consignee_code = ($("#consignee_code").val()).toUpperCase();
        var delivery_code = ($("#delivery_code").val()).toUpperCase();
        var codes = [], deliveryCodes = []
        this.state.filteredData.map((item) => {
            codes.push(item.consignee_code)
            deliveryCodes.push(item.delivery_consignee_code)
        })
        if (this.state.oldConsignVlaue !== '' && this.state.oldDeliveryCode !== '') {
            var index = codes.indexOf(this.state.oldConsignVlaue)
            if (index !== -1) {
                codes.splice(index, 1)
            }
        }
        if (!codes.includes(consignee_code) && !deliveryCodes.includes(consignee_code) && !codes.includes(delivery_code) && consignee_code !== delivery_code) {
            var selectedPort = $("#loadingPort").val();
            var params = { consignee_code, delivery_consignee_code: delivery_code, loading_port_code: selectedPort }
            redirectURL.post("/lmconsignees/saveConsigneeDetails", params)
                .then((response) => {

                    $("#consignee_code").val("");
                    $("#delivery_code").val("");

                    this.setState({
                        basicTitle: 'Added Successfully',
                        basicType: "success",
                        show: true,
                        bulkslide: '',
                        overly: 'show-n',
                    })
                    this.getDetailsData()
                })

            if (this.state.oldConsignVlaue !== '' && this.state.oldDeliveryCode !== '') {

                var params = { consignee_code: this.state.oldConsignVlaue, delivery_consignee_code: this.state.oldDeliveryCode }
                redirectURL.post("/lmconsignees/statusChangeofConsignee", params)

            }
        } else {
            this.setState({
                basicTitle: "Consignee code should be unique",
                basicType: "warning",
                show: true,
            })
            $("#consignee_code").val("");
            $("#delivery_code").val("");
        }
        
        $("#consignee_code").val("");
        $("#delivery_code").val("");
    }


    onChangeLoadingPort = (e) => {
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m',
        })
        var selectedPort = $("#loadingPort").val();
        this.setState({ selectedPort })
        this.getDetailsData()
    }

    render() {

        var columnwithDefs = [
            {
                headerName: "Code",
                field: "consignee_code",
                width: 200,
                filter: true,
                resizable: true,
                editable: true,
            },
            {
                headerName: "Delivery Code",
                field: "delivery_consignee_code",
                width: 200,
                filter: true,
                resizable: true,
                editable: true,
            },
            {
                pinned: "left",
                headerName: "Edit",
                field: "_id",
                width: 100,
                resizable: true,
                editable: false,
                cellRendererSelector: function (params) {
                    var rendComponent = {
                        component: 'EditroleAction'
                    };
                    return rendComponent;
                },
            },
            {
                pinned: "left",
                headerName: "Delete",
                field: "delete",
                width: 80,
                resizable: true,
                editable: false,
                cellRenderer: function (params) {
                    return '<i class="icofont icofont-ui-delete"></i>';
                },
            },
        ]
        // console.log(this.state.lpNames, 'helloooo')
        var selectedPort = $("#loadingPort").val();
        var filteredData = this.state.rowData.filter((item) => {
            if (item.status && item.loading_port_code === selectedPort) {
                return item
            }
        })
        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>

                <div className="card col-xl-12">
                    <div className="card-header">
                        <h5>
                            <i className="icofont icofont-users-alt-1 cus-i"></i> <span>Nexa Consignee Mapping</span>
                            <button className="btn btn-success" style={{ float: "right" }} onClick={this.showAddConsignee.bind(this)}>Add Consignee</button>
                        </h5>
                    </div>
                    {(this.state.lpNames.length > 0) ?
                        <div className="row col-xl-12 col-lg-12" style={{ marginTop: "2%" }}>
                            <div className="col-xl-12 col-lg-12">
                                {this.state.lpNames.length == 1 ?
                                    <span style={{ fontSize: "16px" }}>Loading Port :
                                        <select id="loadingPort" onChange={this.onChangeLoadingPort.bind(this)}>
                                            {(this.state.lpNames).map(function (obj) {
                                                return <option value={obj.portCode}>{obj.port}</option>
                                            })}
                                        </select>
                                    </span>
                                    :
                                    <span style={{ fontSize: "16px" }}>Loading Port :
                                        <select id="loadingPort" onChange={this.onChangeLoadingPort.bind(this)}>
                                            {(this.state.lpNames).map(function (obj) {
                                                return <option value={obj.portCode}>{obj.port}</option>
                                            })}
                                        </select>
                                    </span>
                                }
                            </div>
                        </div>
                        : ""}
                    <div className="card-body col-xl-12 col-lg-12">
                        <div id="myGrid" style={{ width: "100%", height: "478px" }} className={"ag-theme-balham "}>
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.filteredData}
                                enableCharts={false}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                // statusBar={this.state.statusBar}
                                // sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus={true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }, rowSelection: 'multiple'
                                }}
                                enableRangeSelection={true}
                                onCellClicked={this.onCellClicked}
                            />
                        </div>
                    </div>
                </div>


                <div className={"slide-r " + (this.state.bulkslide)} style={{ overflow: "hidden" }}>
                    <h3 className="subH">Add Consignee</h3>
                    <div className="slide-r-body" style={{ position: "relative" }}>

                        <div className="container-fluid">
                            <form id="upform" className="theme-form" onSubmit={this.onSubmitConsignee}>

                                <div className="form-group mt-20p">
                                    <input type="text" name="consignee_code" id="consignee_code" placeholder="Consignee Code" className="form-control" required />
                                </div>

                                <div className="form-group mt-20p">
                                    <input type="text" name="delivery_code" id="delivery_code" placeholder="Delivery Consignee Code" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                    <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>

        );
    }
}

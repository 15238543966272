'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "./updatelmstates";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CellGraphComponent from './cellGraphComponent';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { HardwareDesktopWindows } from 'material-ui/svg-icons';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from "@fullcalendar/timegrid";    
import Showlmvalue from "./showlmvalue";
import {verifyext, verifycsvdata} from "../common/verifyext";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class RpTransporterServing extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          consignmentforceclose:Consignmentforceclose,
          consignmentActions:ConsignmentActions,
          UpdateButton:UpdateButton,
          MarkDeliver : MarkDeliver,
          commentActions : CommentActions,
          Showlmvalue:Showlmvalue
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
        //   {
        //   statusPanel: "agTotalAndFilteredRowCountComponent",
        //   align: "left"
        //   },
        //   {
        //   statusPanel: "agTotalRowCountComponent",
        //   align: "center"
        //   },
        //   { statusPanel: "agFilteredRowCountComponent" },
        //   { statusPanel: "agSelectedRowCountComponent" },
        //   { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            // {
            // id: "columns",
            // labelDefault: "Columns",
            // labelKey: "columns",
            // iconKey: "columns",
            // toolPanel: "agColumnsToolPanel"
            // },
            // {
            // id: "filters",
            // labelDefault: "Filters",
            // labelKey: "filters",
            // iconKey: "filter",
            // toolPanel: "agFiltersToolPanel"
            // }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-m",
        loadshow:"show-m",
        trnsportersList : [],
        statesList : [],
        vehicleList: [],
        showCalendar : "show-m",
        showGridData : "show-m",
        transdata:[],
        zonesList:[],
        zoneStates:[],
        sliderType:1,
        comp_code : "",
        lpNames : [],
        loading_port_code : "",
        filter_start_date : "",
        filter_end_date : "",
        valid_from:"",
        valid_to:"",
        data_valid_from:"",
        data_valid_to:"",
        data_type:1,
        regularActive : "activet",
        truckActive : "",
        courierActive : "",
        transporter_code : "",
        transporterServing : [],
        source : [],
        destination : [],
        transportersList : [],
        //components : {datepicker:getDatePicker()}
    }
    this.changeFileHandler = this.changeFileHandler.bind(this);
    this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this)
    this.onLoadShowData = this.onLoadShowData.bind(this);
    this.createColumns = this.createColumns.bind(this);
    this.createRows = this.createRows.bind(this);
    this.checkFileData = this.checkFileData.bind(this);

  }

async componentDidMount(){
    loadDateTimeScript();
    var currentDate = moment.parseZone().format("YYYY-MM-DD");
    //console.log(currentMonth,"currentMonth")
    await redirectURL.post("/rakeplanning/getRakeTransporters").then(async(response)=>{
        var response = response.data;
        //console.log(response,"response")
        if(response.length > 0)
        {
            this.setState({
                transportersList : response,
                transporter_code : response[0].transporter_code
            }) 
            $("#transporter_code").val(response[0].transporter_code);           
        }
    }).catch((e)=>{
        console.log(e);
    })
    await this.onLoadShowData();
}
onLoadShowData = async() =>{
    this.setState({
        overly : "show-m",
        loadshow : "show-m",
    })
    var transporter_code = this.state.transporter_code;
    var params = {
        transporter_code : transporter_code
    }
    await redirectURL.post("/rakeplanning/getTransporterServing",params).then((response)=>{
        //console.log(response,"response")      
        this.setState({
            transporterServing : response.data.transporterServing,
            source : response.data.source,
            destination : response.data.destination,
            overly : "show-n",
            loadshow : "show-n",
        })
    }).catch((e)=>{
        console.log(e);
    })

    await this.createColumns();
    await this.createRows();
} 
createColumns(){   
    var transporterServing = this.state.transporterServing;
    var source = this.state.source;
    var destination = this.state.destination;
    var groupsource = groupBy(source, rdata => rdata.state);
    //console.log(groupsource,"groupsource")
    var columns = []
    columns.push({
        headerName:"Destinations",
        field:"destination",
        width:180,
        editable:false
    })
    groupsource.forEach((lc, lckey) =>{
        var defs = {}
        defs.headerName = lckey;
        defs.headerClass = ["cellstylegridB","cellTopHeader"];
        var cdefs = [];
        lc.map((ste) => {
        //  console.log("ste ", ste)
            var childdefs = {};
            var ss = ste.railyard_name
            var dd = ste.railyard_name
            childdefs.headerName = ss;
            childdefs.field = ss;
            childdefs.width = 140;
            childdefs.editable = true;                           
            childdefs.cellEditor = 'agSelectCellEditor';
            childdefs.cellEditorParams = {
                values: ["Yes","No"]
            };
        
            cdefs.push(childdefs)
        })
        defs.children = cdefs
        columns.push(defs)
    })
    console.log("Columns ", columns)
    this.setState({
        columnwithDefs:columns,
        overly:"show-n",
        loadshow:"show-n",
    })
}
createRows(){   
    var transporterServing = this.state.transporterServing;
    var source = this.state.source;
    var destination = this.state.destination;
    var rowdefs = []
    destination.map(function(ste){
        //console.log(ste,"ste")
        var rows = {}
        rows.destination=ste.railyard_name;
        if(transporterServing.length > 0)
        {
            var transportername = groupBy(transporterServing, rdata => rdata.loading_station);
            transportername.forEach((trans, transkey) =>{
                //console.log(trans,"trans")
                trans.map((t) => {  
                    //console.log("Here t ", t)
                    if(t.unloading_station == ste.railyard_name)
                    {
                        rows[t.loading_station] = t.serving_status
                    }
                })
            })
        }
       // console.log("rows ", rows)
        rowdefs.push(rows)
    })
    console.log("rowdefs ", rowdefs)
    this.setState({
        rowData:rowdefs,
        overly:'show-n',
        load:'show-n',
    })
}
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
    var sort = [
        {
          colId: 'transporter_name',
          sort: 'asc',
        },
      ];
    this.gridApi.setSortModel(sort);
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}




closeAlert = async () => {
    await this.setState({
        show: false
    });
    await window.location.reload();
}

onCellClicked(e)
{
    //console.log(e);
    if(e.value == "View States")
    {
        var zone = e.colDef.field;
        if(zone !=undefined && zone !='')
        {
            var zonesList = this.state.zonesList;
            var zoneStates = zonesList.filter(function(e){return e.zone == zone});
            console.log(zonesList,"zonesList")
            console.log(zoneStates,"zoneStates")
            $(".subH").html(zone+" States");
            this.setState({
                sliderType:2,
                zoneStates:zoneStates,
                overly :"show-m",
                bulkslide : "slider-translate"
            })
        }
    }
}

onCellUpdateData = (params) => {
    //console.log("Params ", params)
    // var colData = params.data;
    // var objects = Object.keys(colData);
    // var rowTotal = 0;
    // objects.map(function(e){
    //     if(parseInt(params.data[e]).toString() != "NaN")
    //     {
    //         rowTotal = parseInt(rowTotal)+parseInt(params.data[e]); 
    //     }
    // })
    // if(parseInt(rowTotal) > parseInt(100))
    // {
    //     alert("Share Of Business Should not be greater than 100%");
    //     var currentField = params.colDef.field;
    //     params.data[currentField] = 0
    // }
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    zoneStates:[],
    sliderType:1,
    bulkslide:"",
    
    });
    
}
updateLmStates(params)
{
    //console.log(params);
    if(params.transporter_name !='' && params.transporter_name !=undefined && params.state !='' && params.state !=undefined && params.sob !='' && params.sob !=undefined)
    {
        params.data_type = this.state.data_type;
        redirectURL.post("/lmconsignees/savelmshareofbusiness",params).then((response)=>{
            console.log(response);
            if(response.data.message == "Success")
            {
                this.setState({
                    basicTitle:"Updated Successfully.",
                    basicType : "success",
                    show : true
                })
                window.location.reload();
            }
            else
            {
                this.setState({
                    basicTitle:"Failed to update.",
                    basicType : "warning",
                    show : true
                })
            }
        })
    }
    else
    {
        this.setState({
            basicTitle:"Fields Should not be Empty.",
            basicType : "warning",
            show : true
        })
    }
}
addNewRow()
{
    // console.log(this.gridApi);
    this.gridApi.insertItemsAtIndex(0,[{transporter_name:"",state:"",sob:""}])
    // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}
onClickDataExport(){
    var params = {
        columnKeys: ['id', 'transporter_name', 'state', 'sob']
    };
    this.gridApi.exportDataAsCsv(params);
}
showBulkUpload(){
    $(".subH").html("Bulk Upload");
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate"
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        zoneStates:[],
        sliderType:1,
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}
changeFileHandler = async (e) => {
    var dt = '';
    const config = {
        headers: [
            { 
                name: 'Transporter Name',
                inputName: 'transporter_name',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Transporter Code',
                inputName: 'transporter_code',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Zone',
                inputName: 'zone',
                required: false,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Approved SOB',
                inputName: 'sob',
                required: false,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },                   
        ]
    }
    var data = CSVFileValidator(e.target.files[0], config)
    .then((csvData) => {
        this.setState({
            csvData:csvData.data
        });
        console.log(csvData.data);
    })
    .catch(err => {})
    // console.log("e.target.files[0]",e.target.result);
    
    var out = new Promise(function(reject, resolve){
        var reader =  new FileReader();
        reader.onload = async function(e) {
            var contents = await e.target.result;
            resolve(contents);
        };
        var tt =  reader.readAsText(e.target.files[0]);
    });
    this.setState({
        file:e.target.files[0]
    });
    // console.log("e.target.files[0].type ", e.target.files[0]);
    var check = verifyext(e.target.files[0].name);
    //console.log("Check ", check)
    if(check == true)
    {
        if(e.target.files[0].type == '' ||e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
        {
            // console.log(fileData);
            // console.log(typeof(fileData))
            
        }
        else{
            e.target.value = null;
            this.setState({
                uploadFile:'',
                show: true, 
                basicType:'danger', 
                basicTitle:'Please upload file having extensions .csv only.',
            });
        }
    }
    else{
        e.target.value = null;
        this.setState({
            uploadFile:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'Please upload file having extensions .csv only.',
        });
    }
    
}

checkFileData()
{
   
    var vdata = verifycsvdata(JSON.stringify(this.state.csvData));
    //console.log("verifycsvdata ", vdata)
    if(vdata == 0)
    {
        this.setState({
            csvData:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'File contains invalid data',
            uploadFile:'',
            file:""
        });
    }
}
uploadBulkFormHandler(event){
    event.preventDefault();
    var fileData = this.state.csvData;
    var flag=0;
    console.log(fileData,"fileData");
    var vdata = verifycsvdata(JSON.stringify(this.state.csvData));
   // console.log("verifycsvdata ", vdata)
    if(vdata == true)
    {
        this.setState({
            csvData:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'File contains invalid data',
            uploadFile:'',
            file:""
        });
    }
    else{
        if(flag == 0)
        {
            if(fileData.length > 0)
            {
                var valid_from = this.state.valid_from;
                var valid_to = this.state.valid_to;
                if(valid_from !=undefined && valid_from !='' && valid_to !=undefined && valid_to !='')
                {
                    var reqParams = {
                        loading_port_code : this.state.loading_port_code,
                        valid_from : valid_from,
                        valid_to : valid_to,
                        csvData : fileData,
                        data_type : this.state.data_type,
                    }
                    console.log("reqParams",reqParams)
                    redirectURL.post("/lmconsignees/savebulklmapprovedsob",reqParams).then((response)=>{
                        var resp=response.data.message;
                        console.log(resp)
                        this.setState({
                            basicTitle : resp,
                            basicType : "success",
                            show : true,
                            bulkslide:'',
                            overly:'show-m',
                            load:'show-m',
                        })
                        this.onLoadShowData();
                        //window.location.reload();
                    }).catch((e)=>{
                        console.log(e);
                    })
                }
                else
                {
                    this.setState({
                        basicTitle : "Select Valid From & Valid to",
                        basicType : "warning",
                        show : true,
                    })
                }
            }
            else
            {
                this.setState({
                    basicTitle : "Invalid File",
                    basicType : "warning",
                    show : true,
                })
            }
            $('#upform')[0].reset();
        }
        else
        {
            $("#bulkUploadID").val();
            this.setState({
                basicTitle : "Fields Should not be Empty",
                basicType : "warning",
                show : true,
            })
            $('#upform')[0].reset();
        }
    }
};




onSelectBulkPlants= (location) =>{
    var plants=[];
    try{
        location.map(function(e){
            plants.push(e.value);
        })
        console.log(plants);
        this.setState({
            plant_name : plants
        });
    }
    catch(e)
    {
        console.log(e);
    }
    
}

updateGridData()
{
    var allrows = []
    this.gridApi.forEachNode((node, index) => {
        //console.log("node ",node)
        allrows.push(node.data)
    })
    var records = []
    var transporter_code = this.state.transporter_code;
    if(allrows.length > 0)
    {
        allrows.map((item) => {
            var rowKeys = Object.keys(item);
            rowKeys.map((k) => {
                if(k !="destination")
                {
                    if(item[k] !="No")
                    {
                        records.push({
                            transporter_code : transporter_code,
                            loading_station : k,
                            unloading_station : item.destination,
                            serving_status : 1
                        });
                    }
                }
            });
        })
    }
    console.log("records ",records)
    var parameters = {
        records : records,
        transporter_code : transporter_code,
    }
    redirectURL.post("/rakeplanning/updateTransporterServing", parameters)
    .then((response) => {
        console.log(response);
            if(response.data.message == "Success")
            {
                this.setState({
                    basicTitle:"Updated Successfully.",
                    basicType : "success",
                    show : true
                })
                this.onLoadShowData()
            }
            else
            {
                this.setState({
                    basicTitle:"Failed to update.",
                    basicType : "warning",
                    show : true
                })
            }
    })
}

onChangeLoadingPlant = (e) =>{
    this.setState({
        loadshow:'show-m',
        overly:'show-m',
    })
    var selectedPlant = $("#loadingPlant").val();
    var params = {
        selectedPlant : selectedPlant,
    }
    redirectURL.post("/lmconsignees/getLoadingPlantDetails",params)    
    .then((response) => {
        var res = response.data;
        this.setState({
            loading_port_code : selectedPlant,
            comp_code : res[0].comp_code,
            loadshow:'show-n',
            overly:'show-n',
        })
        this.pageAccess();
    })
    .catch(function(e){
        console.log("Error ",e)
    })
}  

handlerStartDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);

    var filter_start_date = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate());
    
    this.setState({
        filter_start_date:filter_start_date
    });
}

handlerEndDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    
    var filter_end_date = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate());
    
    this.setState({
        filter_end_date:filter_end_date
    });
}

handlerStartDateTimeForNewTransporter = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    
    var valid_from = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate());
    
    this.setState({
        valid_from:valid_from
    });
}

handlerEndDateTimeForNewTransporter = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    
    var valid_to = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate());
    
    this.setState({
        valid_to:valid_to
    });
}

filterDataByTransporter = async() => {
    var transporter_code = $("#transporter_code").val();
    if(transporter_code !="")
    {
        await this.setState({
            transporter_code : transporter_code
        });
        await this.onLoadShowData();
    }
}
async onClickTab(data_type)
{
    var regularActive = "";
    var truckActive = "";
    var courierActive = "";
    if(data_type == 1)
    {
        regularActive = "activet";
    }
    else if(data_type == 2)
    {
        truckActive = "activet";
    }
    else
    {
        courierActive = "activet";
    }
    await this.setState({
        data_type : data_type,
        regularActive : regularActive,
        truckActive : truckActive,
        courierActive : courierActive,
    });
    await this.onLoadShowData();
}
  render() {
    
    var columnwithDefs = [      
        {
            headerName: "Transporter",
            field: "transporter_name",
            width:150,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: this.state.trnsportersList,
            },            
        },

        {
            headerName: "State",
            field: "state",
            width:290,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: this.state.statesList,
            },
        },         
        {
            headerName: "Share Of Business",
            field: "sob",
            width:100,
            filter: true,
            resizable: true,
            editable:true,
        },         
        {
            pinned:"left",
            headerName : "Update",
            field:"_id",
            width:150,
            resizable : true,
            editable : false,
            cellRendererSelector:function(params){       
                var rendComponent = {
                    component: 'UpdateButton'
                };
                return rendComponent;
            },
        },
        {
            pinned:"left",
            headerName: "id",
            field: "id",
            width:100,
            filter: true,
            resizable: true,
            editable:false,            
        },
    ]
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="card col-xl-12">
            <div className="card-header">
                <h5>                
                    <span>Operating Routes</span>
                    {/* <button className="btn btn-info" style={{float:"right",marginRight:"10px"}} onClick={this.showBulkUpload.bind(this)}>Import Data</button> */}
                </h5>
            </div>
            <div className="row col-xl-12 col-lg-12" style={{marginTop:"2%"}}>
                <form method="POST" className="form-theme col-xl-12 col-lg-12 row">
                    <div className="col-xl-3 col-lg-3 form-group">
                        <select className="form-control" id="transporter_code">
                            <option value="">Select Transporter</option>
                            {(this.state.transportersList.map(function(t){
                                return <option value={t.transporter_code}>{t.transporter_name}</option>
                            }))}
                        </select>
                    </div>
                    <div className="form-group col-xl-2 col-lg-2">
                        <button type="button" className="btn btn-success" style={{padding:"5px 10px",marginRight:"2%"}} onClick={this.filterDataByTransporter.bind(this)}>Submit</button>
                    
                    </div>
                </form>
            </div>
            <div className="card-body col-xl-12 col-lg-12">
                {(this.state.data_valid_from !="" && this.state.data_valid_from !=undefined) ?
                <h6>Data Valid From <span style={{color:"green",fontWeight:"600"}}>{getHyphenDDMMMYYYY(this.state.data_valid_from)}</span> to <span style={{color:"red",fontWeight:"600"}}>{getHyphenDDMMMYYYY(this.state.data_valid_to)}</span></h6>  
                : ""}             
                <div id="myGrid" style={{width:"100%",height:"470px"}} className={"ag-theme-balham "+this.state.showGridData}>    
                    <AgGridReact
                        modules={this.state.modules}
                        columnDefs={this.state.columnwithDefs}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.rowData}
                        enableCharts={false}
                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                        onGridReady={this.onGridReady}
                        onGridState={this.onGridState}
                        frameworkComponents={this.state.frameworkComponents}
                        statusBar={this.state.statusBar}
                        sideBar={this.state.sideBar}
                        stopEditingWhenGridLosesFocus= {true}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={false}
                        gridOptions={{
                            context: { componentParent: this }
                        }}
                        // components={this.state.components}
                        enableRangeSelection= {true}
                        onCellClicked={this.onCellClicked.bind(this)}
                        onCellEditingStopped={this.onCellUpdateData.bind(this)}
                        />
                </div>
                <div style={{float:"right",marginTop:"1%"}}>
                    <button type="button" onClick={this.updateGridData.bind(this)} className="btn btn-warning">Update Data</button>
                </div>
            </div>
        </div>

        
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">Bulk Upload</h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    {(this.state.sliderType == 2) ?
                    <div className="row">
                        <table className="table">
                            <tbody>
                                {this.state.zoneStates.map(function(zs){
                                return <tr>
                                    <td style={{padding:"5px",border:"none"}}>{zs.state}</td>
                                </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                    :
                    <div className="row">
                        <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>
                            <div className="form-group mt-20p">
                                <label className="">Valid From</label> 
                                <Datetime 
                                    value={this.state.valid_from} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ 
                                        placeholder: 'Select Date', 
                                        name: 'valid_from', 
                                        autoComplete:'off' 
                                    }}
                                    dateFormat="YYYY-MM-DD" 
                                    name="valid_from" 
                                    onChange={this.handlerStartDateTimeForNewTransporter.bind(this)} 
                                />
                            </div>
                            <div className="form-group mt-20p">
                                <label className="">Valid To</label> 
                                <Datetime 
                                    value={this.state.valid_to} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ 
                                        placeholder: 'Select Date', 
                                        name: 'valid_to', 
                                        autoComplete:'off' 
                                    }}
                                    dateFormat="YYYY-MM-DD" 
                                    name="valid_to" 
                                    onChange={this.handlerEndDateTimeForNewTransporter.bind(this)} 
                                />
                            </div>
                            
                            <div className="form-group mt-20p">
                                <label className="">Upload File</label> 
                                <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control" required  />
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-success">Submit</button>
                                <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                            </div>
                        </form>
                        <div className="form-group">
                            <a className="btn btn-primary" href={require('../../assets/json/sample_lmapprovedsob.csv')} target="_blank">Sample Template</a>
                        </div>	
                    </div>	
                    }
                </div>
            </div>
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

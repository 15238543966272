'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "./updateManualExportsBtn";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CellGraphComponent from './cellGraphComponent';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { HardwareDesktopWindows } from 'material-ui/svg-icons';
import { toPlainObject, truncate } from 'lodash';
import Modal from 'react-responsive-modal';
import TransitAnalyticsColumnChart from "./transitAnalyticsColumnChart";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;
var validatedBy="";



export default class UniqueTrucksTrips extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,

        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          consignmentforceclose:Consignmentforceclose,
          consignmentActions:ConsignmentActions,
          UpdateButton:UpdateButton,
          MarkDeliver : MarkDeliver,
          commentActions : CommentActions
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:50,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        plants:[],
        plant_name:"",
        cellPlants:[],
        consigner_coordinates:[],
        consignee_coordinates:[],
        showSelectDept:"show-m",
        usergridstate:[],
        screenurl:"",
        screentitle:"",
        pagetitle:"",
        eventGridAction: "gridAction",
        tolls : [],
        startDate : "",
        endDate : "",
        selectedConsginers : {"label" : "ALL", "value" : "ALL"},
        selectedTransporters : {"label" : "ALL", "value" : "ALL"},
        transporterOptions : [],
        consignerOptions : [],
        defaultStartDate : "",
        defaultEndDate : "",
        usermanualmodal:false,
        avgtripdistancechart: [{series: []}],
        avgtripchart: [{"series": []}],
        showGridData: "show-m",
        showChartData: "show-n"
        //components : {datepicker:getDatePicker()}
    }
    this.formatChartData= this.formatChartData.bind(this);
  }

componentDidMount(){
    this.setState({
        loadshow : "show-m",
        overly : "show-m"
    })
    loadDateTimeScript();
    var deptcode="";
    if(this.props.match.path == "/snduniquetrucktrips")
    {
        deptcode = "SNDG"
    }
    if(this.props.match.path == "/prtuniquetrucktrips")
    {
        deptcode = "LOG-PRT"
    }
    this.logPageView(); // Log page view to GA
    let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
    this.setState({eventAction: eventAction});

    redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
    .then( async (resp) => {
        //console.log("resp ", resp)
        await this.setState({
            usergridstate:resp.data
        });
        await this.restoreGridStates();
    });


    redirectURL.post("/dashboard/getInitialUniqueTruckTripsData",{dept_code:deptcode}).then((response)=>{
        var consigners = [];
        consigners.push({"label" : "ALL", "value" : "ALL"});
        response.data.plants.map(function(e){
            if(e != "")
            {
                consigners.push({"label" : e, "value" : e});
            }
            
        });
        var transporters = [];
        transporters.push({"label" : "ALL", "value" : "ALL"});
        response.data.transporters.map(function(e){
            if(e != "")
            {
                transporters.push({"label" : e, "value" : e})
            }   
        });
        var startDate = moment.parseZone().subtract(30, 'days').format('YYYY-MM-DD');
        var endDate = moment.parseZone().format('YYYY-MM-DD');
        var formdata = {
            from_date : startDate+" 00:00:00",
            to_date : endDate+" 23:59:59",
            dept_code : deptcode,
        }
        redirectURL.post("/dashboard/getUniqueTripsData",{"formdata" : formdata}).then((response)=>{
            console.log(JSON.parse(response.data.trucks_data));
            var avgtripchart = this.formatChartData(JSON.parse(response.data.avg_trip));
            console.log(avgtripchart)
            var avgtripdistancechart = this.formatChartData(JSON.parse(response.data.avg_trip_distance));
            console.log(avgtripdistancechart);
            this.setState({
                rowData : JSON.parse(response.data.trucks_data),
                loadshow : "show-n",
                overly : "show-n",
                transporterOptions : transporters,
                consignerOptions : consigners,
                startDate : startDate,
                endDate : endDate,
                defaultStartDate : startDate,
                defaultEndDate : endDate,
                dept_code : deptcode,
                avgtripchart: avgtripchart,
                avgtripdistancechart: avgtripdistancechart
            })
        }).catch((e)=>{
            console.log(e);
        })
        
    }).catch((e)=>{console.log(e)})

}

formatChartData(chartdata){
    var categories = [];
    var seriesData = [];
    chartdata.map(function(e){
        categories.push(e['transporter_name']);
        var keys = Object.keys(e);
        seriesData.push({
            name: e['transporter_name'],
            y: Math.round(e[keys[1]]),
        });
        
    });
    seriesData = seriesData.sort( (a, b) => {
        return b["y"] - a["y"]
    })
    return [{"categories": categories, "series": seriesData}];
}
logPageView = () => {
    try {
        if (googleAnalytics.page.enableGA) {
            googleAnalytics.logPageView();
            let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
            let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
            this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
        } else {
            console.log("GA disabled...");
        }
    } catch(error) {
        console.error("Error occured while logging to GA, error = ", error);
    }
}
 
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    this.setState({
        show: false
    });
}




onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    
}



 
onClickSaveGridState(){
    if (googleAnalytics.page.enableGA) {
        let pageTitle = this.state.pagetitle;
        let eventOptions = {
            "category": pageTitle,
            "action": this.state.eventGridAction,
            "label": "Save Grid Layout",
        }
        googleAnalytics.logEvent(eventOptions);
    }
    window.colState = this.gridColumnApi.getColumnState();
    window.groupState = this.gridColumnApi.getColumnGroupState();
    window.sortState = this.gridApi.getSortModel();
    window.filterState = this.gridApi.getFilterModel();
    
    let screenpage = '';

    if(this.props.match.path == "/sndPossibletransshipments")
    {
        screenpage='snd possible transhipment';
    }
    else if(this.props.match.path == "/prtPossibletransshipments")
    {
        screenpage='prt possible transhipment';
    }
    else if(this.props.match.path == "/tnpPossibletransshipments")
    {
        screenpage='tnp possible transhipment';
    }

    let reqparams = {
        gridcolumns:window.colState,
        gridgroup:window.groupState,
        gridcolsort:window.sortState,
        gridcolfilter:window.filterState,
        userId:localStorage.getItem("userid"),
        screenurl:window.location.pathname,
        screentitle:screenpage
    }
    //console.log("reqparams ", reqparams)
    redirectURL.post("/consignments/saveGridStates", reqparams)
    .then((response) => {
        //console.log("State response ",response.data)
        this.setState({
            show:true,
            basicTitle:"Successfully saved grid layout",
            basicType:"success",
            screenurl:window.location.pathname,
            screentitle:screenpage
        })
    })
    .catch(function(e){
        console.log("Error ", e)
    })
}

restoreGridStates()
{

    if(this.state.usergridstate.length > 0)
    {
        var windowstates = this.state.usergridstate;
        this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
        this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
        this.gridApi.setSortModel(windowstates[0].gridcolsort);
        this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
        
    }
}

resetState = () => {
    if (googleAnalytics.page.enableGA) {
        let pageTitle = this.state.pagetitle;
        let eventOptions = {
            "category": pageTitle,
            "action": this.state.eventGridAction,
            "label": "Reset Grid Layout",
        }
        googleAnalytics.logEvent(eventOptions);
    }
    this.gridColumnApi.resetColumnState();
    this.gridColumnApi.resetColumnGroupState();
    this.gridApi.setSortModel(null);
    this.gridApi.setFilterModel(null);
    //console.log('column state reset');
    var reqparams = {
        userId:localStorage.getItem("userid"),
        screenurl:window.location.pathname,
        screentitle:this.state.screenpage
    }

    redirectURL.post("/consignments/removeUserGridState", reqparams)
    .then((response) => {
        this.setState({
            show:true,
            basicTitle:"Successfully reset default grid layout",
            basicType:"success"
        })
    })

};


handlerStartDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    
    var startdate = d.getFullYear()+"-"+((d.getMonth() +1) >= 10?(d.getMonth() +1):"0"+(d.getMonth() +1))+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    
    this.setState({
        startDate:startdate
    });
}

handlerEndDateTime  = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    
    var edate = d.getFullYear()+"-"+((d.getMonth() +1) >= 10?(d.getMonth() +1):"0"+(d.getMonth() +1))+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
    this.setState({
        endDate:edate
    });
}

onSelectConsigners(e)
{
    this.setState({
        selectedConsginers : e
    })
}
onSelectTransporter(e)
{
    this.setState({
        selectedTransporters : e
    })
}

onClickSubmit()
{   
    this.setState({
        loadshow : "show-m",
        overly : "show-m"
    })
    if(this.state.startDate.includes("NaN") == false && this.state.startDate != "" && this.state.endDate.includes("NaN") == false && this.state.endDate != "")
    {
        var from_date = this.state.startDate+" 00:00:00";
        var to_date = this.state.endDate+" 23:59:59";
        var startDate = this.state.startDate;
        var endDate = this.state.endDate
    }
    else
    {
        var from_date = this.state.defaultStartDate+" 00:00:00";
        var to_date = this.state.defaultEndDate+" 23:59:59";
        var startDate = this.state.defaultStartDate;
        var endDate = this.state.defaultEndDate;
    }
    
    var consigners = [];
    var transporters = [];
    try{
        if(this.state.selectedConsginers.length > 0)
        {
            console.log(this.state.selectedConsginers.indexOf("ALL"))
            this.state.selectedConsginers.map(function(e){
                consigners.push(e.value);
            });
            if(consigners.indexOf("ALL") == -1)
            {
                consigners = JSON.stringify(consigners);
            }
            else
            {
                consigners = []
            }
            
        }
        
    }catch(e){console.log(e)}

    try{
        console.log(this.state.selectedTransporters)
        if(this.state.selectedTransporters.length > 0 && this.state.selectedTransporters.indexOf("ALL") == -1)
        {
            console.log(this.state.selectedTransporters.indexOf("ALL"))
            this.state.selectedTransporters.map(function(e){
                transporters.push(e.value);
            });
            if(transporters.indexOf("ALL") == -1)
            {
                transporters = JSON.stringify(transporters);
            }
            else
            {
                transporters = [];
            }
            
        }
        
    }catch(e){console.log(e)}
    
    var formdata = {
        from_date : from_date,
        to_date : to_date,
        plant_codes : consigners,
        transporter_names : transporters,
        dept_code : this.state.dept_code
    };
    if(formdata['plant_codes'].length == 0)
    {
        delete formdata['plant_codes']
    }
    if(formdata['transporter_names'].length == 0)
    {
        delete formdata["transporter_names"]
    }
    redirectURL.post("/dashboard/getUniqueTripsData",{"formdata" : formdata}).then((response)=>{
        var avgtripchart = this.formatChartData(JSON.parse(response.data.avg_trip));
        console.log(avgtripchart)
        var avgtripdistancechart = this.formatChartData(JSON.parse(response.data.avg_trip_distance));
        console.log(avgtripdistancechart);
        this.setState({
            rowData : JSON.parse(response.data.trucks_data),
            startDate : startDate,
            endDate : endDate,
            loadshow : "show-n",
            overly : "show-n",
            avgtripchart: avgtripchart,
            avgtripdistancechart: avgtripdistancechart
        })
    }).catch((e)=>{
        console.log(e);
    })
    
}


onCloseUserManualModal = () => {
    this.setState({ usermanualmodal: false });
};

onClickShowUserManaul = () => {
    this.setState({ usermanualmodal: true });
};
displayLoadingData(statevar, statevar1)
{
    this.setState({
        [statevar]: "show-m",
        [statevar1]: "show-n"
    })
}
render(){
    const modalStyles  = {
        width:'1300px !important',
    }
    
    const {usermanualmodal} = this.state;
    var hidedistances = (this.props.match.path == "/prtuniquetrucktrips")?false:true;
    var columnwithDefs = [     
        // {
        //     headerName: "",
        //     field: "_id",
        //     colId: "_id",
        //     width: 50,
        //     pinned: 'left',
        //     cellRenderer:'consignmentActions',
        //     filter: false,resizable: true
            
        // }, 
        {
            headerName: "Truck No",
            field: "truck_no",
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Transporter Name",
            field: "transporter_name",
            filter: true,
            resizable: true,
            editable:false
        },
        // {
        //     headerName: "Consigner Code",
        //     field: "consigner_code",
        //     width:130,
        //     filter: true,
        //     resizable: true,
        //     editable:false
        // },
        {
            headerName: "No. of Trips",
            field: "no_of_trips",
            filter: true,
            resizable: true,
            editable:false
        },
        // {
        //     headerName: "Gate Out Time",
        //     field: "gate_out_time",
        //     filter: true,
        //     resizable: true,
        //     editable:false,
        //     comparator : dateComparator,
        //     valueGetter : function(params)
        //     {
        //         return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time)
        //     },
        //     hide : hidedistances
        // },
        {
            headerName: "Trip Distance (KM)",
            field: "expected_distance_km",
            filter: true,
            resizable: true,
            editable:false,
            hide : hidedistances
        },
        // {
        //     headerName: "No. of Trips Considered",
        //     field: "trips_dist_considered",
        //     filter: true,
        //     resizable: true,
        //     editable:false,
        //     hide : hidedistances
        // },
        // {
        //     headerName: "Expected Distance (KM)",
        //     field: "expected_distance_km",
        //     filter: true,
        //     resizable: true,
        //     editable:false,
        //     hide : hidedistances
        // },

        

        
    ]

    return (
      <div class="container-fluid">
            
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>
        <div className="row col-xl-12 col-lg-12">
            <div className="row col-xl-12 col-lg-12">
                <div className="form-group col-xl-3 col-lg-3">
                    <label>Select Transporters : </label>
                    <Select 
                        placeholder={"All"}
                        isMulti={truncate}
                        value={this.state.selectedTransporters} 
                        className="border-radius-0" 
                        onChange={this.onSelectTransporter.bind(this)} 
                        style={{borderRadius:"0px"}} 
                        options={this.state.transporterOptions} required 
                    />
                </div>
                <div className="form-group col-xl-3 col-lg-3">
                    <label>Select Consigner : </label>
                    <Select 
                        placeholder={"All"} 
                        closeMenuOnSelect={false} 
                        value={this.state.selectedConsginers} 
                        isMulti="true" 
                        className="border-radius-0" 
                        onChange={this.onSelectConsigners.bind(this)} 
                        style={{borderRadius:"0px"}} 
                        options={this.state.consignerOptions} 
                        required 
                    />
                </div>
                <div className="col-xl-2 col-lg-2 form-group">
                    <label>From Date</label>
                    <Datetime 
                        value={this.state.startDate} 
                        disableCloseOnClickOutside={false} 
                        closeOnSelect={true} 
                        inputProps={{ placeholder: 'From', name: 'startDate', autoComplete:'off' }}
                        dateFormat="YYYY-MM-DD" 
                        name="startDate" 
                        onChange={this.handlerStartDateTime.bind(this)} 
                    />

                </div>

                <div className="col-xl-2 col-lg-2 form-group">
                    <label>To Date</label>
                    <Datetime 
                        value={this.state.endDate} 
                        disableCloseOnClickOutside={false} 
                        closeOnSelect={true} 
                        inputProps={{ placeholder: 'To', name: 'endDate', autoComplete:'off'}} 
                        dateFormat="YYYY-MM-DD" 
                        onChange={this.handlerEndDateTime.bind(this)} 
                    />
                </div>
                <div className="form-group col-xl-2 col-lg-2">
                    <label>&nbsp;</label><br />
                    <button type="button" className="btn btn-success" autoComplete="off" onClick={this.onClickSubmit.bind(this)}>Submit</button>
                </div>
                
            </div>
        </div>
        {(this.state.dept_code == "LOG-PRT")?
            <span>
                <button className="btn btn-primary" onClick={this.displayLoadingData.bind(this, "showGridData", "showChartData")}>Grid Data</button>
                <button className="btn btn-success" onClick={this.displayLoadingData.bind(this, "showChartData", "showGridData")}>Chart Data</button>
            </span>
        :""}
        
        <div className="row">
            <div className={"col-xl-12 col-lg-12 "+this.state.showGridData}>
                <div className="card">
                    <div className="card-header">
                        <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Trips For Unique Trucks </span>
                        </h5>
                    </div>				   			
                    <div className="card-body pt-15px" >

                        <div className="row">
                            <div className="col-xl-12">

                                <span className="layoutbtns float-right">
                                    <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                        <i className="icofont icofont-save"></i> Save Grid Layout
                                    </button>
                                    <button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
                                        <i className="icofont icofont-refresh"></i> Reset Default Layout
                                    </button>
                                </span>
                            </div>


                            <div id="myGrid" style={{width:"100%",height:"478px"}} className="ag-theme-balham">    
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    enableCharts={false}
                                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus= {true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    // components={this.state.components}
                                    enableRangeSelection= {true}
                                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                    />
                            </div>
                           
                        <div className={"dataLoadpage " +(this.state.loadshow)}>
                        </div>
                        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                        </div>
                        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                    </div>
                </div>
                </div>
                </div>
            </div>
            <div className={"row card col-lg-12 col-md-12 col-sm-12 "+this.state.showChartData} >
                <div className={"row mt-40p"}>
                    {this.state.avgtripchart.map((chart)=>{
                        return <div className="col-xl-12">
                                    {(chart.series.length > 0)?
                                        <div>
                                            <TransitAnalyticsColumnChart
                                            title={"Transporter wise Avg. Trips"}
                                            subtitle={""}
                                            context={this}
                                            series={[
                                                {
                                                    name: "Average Trips",
                                                    colorByPoint: true,
                                                    data: chart.series
                                                }
                                            ]}
                                            categories={chart.categories}
                                            seriesName=""
                                            showHourFormat={2}
                                            yaxistitle="Average No. of Trips"
                                            drilldownseries={{"series": []}}
                                            // dept_code={this.state.dept_code}
                                            />
                                        </div>
                                    :
                                        
                                        <span>
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>Transporter wise Avg. Trips.</h5>
                                            <span style={{position:"absolute",top:"30%",left:"43%"}}>No Data</span></div>
                                        </span>
                            
                                    }
                                    
                                </div>
                    })}
                
                </div>
                <div className={"row mt-40p"}>
                    {this.state.avgtripdistancechart.map((chart)=>{
                        return <div className="col-xl-12">
                                    {(chart.series.length > 0)?
                                        <div>
                                            <TransitAnalyticsColumnChart
                                            title={"Transporter wise Avg. Trip Distance (KM)"}
                                            subtitle={""}
                                            context={this}
                                            series={[
                                                {
                                                    name: "",
                                                    colorByPoint: true,
                                                    data: chart.series
                                                }
                                            ]}
                                            categories={chart.categories}
                                            seriesName=""
                                            showHourFormat={1}
                                            yaxistitle="Average Trip Distance (KM)"
                                            drilldownseries={{"series": []}}
                                            // dept_code={this.state.dept_code}
                                            />
                                        </div>
                                    :
                                        
                                        <span>
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>Transporter wise Avg. Trip Distance</h5>
                                            <span style={{position:"absolute",top:"30%",left:"43%"}}>No Data</span></div>
                                        </span>
                            
                                    }
                                    
                                </div>
                    })}
                
                </div>
            </div>
            
            
            <span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
            <Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
                <div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                    <h5 className="model-title">Trips for unique Trucks</h5>
                    <div className="col-xl-12 col-lg-12 mt-20p">
                        <div className="col-xl-12 col-lg-12">
                            This utility is for SND and PRT department which provides list of trips done by a particular vehicle in a stipulated time. The utility provides list of total number of trips for a particular vehicle of a transporter and its total distance travelled.
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            Image here
                        </div>
                    </div>
                </div>
            </Modal>    
        </div>

    );
  }
}


function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
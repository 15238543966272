/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
// 'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "./updatelmstates";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
// import 'jquery-ui-multidatespicker';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import { verifyext, verifycsvdata } from "../common/verifyext";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent = "";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class RailClusterSummary extends Component {
    state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            frameworkComponents: {
                consignmentforceclose: Consignmentforceclose,
                consignmentActions: ConsignmentActions,
                UpdateButton: UpdateButton,
                MarkDeliver: MarkDeliver,
                commentActions: CommentActions
            },
            modified_by: "",
            basicTitle: '',
            reasonforceclose: "",
            bulkslide: "",
            file: "",
            uploadFile: "",
            basicType: "default",
            loadshow: 'show-n',
            forceCloseRowNode: "",
            forceclosedata: "",
            csvcontent: "",
            sliderForceCloseTranslate: "",
            overly: '',
            rowData: [],
            bulkslide: "",
            showDiv: "",
            uploadDivWidth: "",
            mapinfo: "",
            dealer: "",
            sliderCommentTranslate: "",
            commentsRowData: "",
            consignment_code: "",
            sliderRouteTranslate: "",
            maptruckno: "",
            routeTruck: "",
            rownode: "",
            gridTitle: "",
            file: "",
            rowSelection: 'multiple',
            sliderForceCloseTranslate: "",
            maptruckno: "",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent",
                    statusPanelParams: {
                        // possible values are: 'count', 'sum', 'min', 'max', 'avg'
                        aggFuncs: ['sum', 'avg', "count", "min", "max"],
                      }, }
                ],
            },
            paginationPageSize: 200,
            geofencelist: [],
            geoFenceData: "",
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            dept_code: "",
            pagetitle: "",
            markDeliverRowNode: "",
            markDeliverData: "",
            sliderMarkDeliverTranslate: "",
            csvData: 0,
            overly: "show-n",
            loadshow: "show-n",
            holidayEvents: [],
            showCalendar: "show-m",
            showGridData: "show-m",
            plan_month: "",
            selectedDays: [],
            month_target: 0,
            working_days: 0,
            daily_target: 0,
            balance_days: 0,
            compledted_till_date: 0,
            actual_till_date: 0,
            achievement: 0,
            balance_to_be_planned: 0,
            achievementColor: "red",
            target_revised: 0,
            total_dispatches: 0,
            rail_share: 0,
            current_dispatch_fy: 0,
            current_rail_dispatch_fy: 0,
            current_rail_share: 0,
            vechicles_possible : 0,
            vechicles_planned : 0,
            current_vechicles_possible : 0,
            current_vechicles_planned : 0,
            vehClr : "white",
            vehPoss : 0,
            vehPlanned : 0,
            incoming_rakes : 0,
            load_capacity : 0,
            rake_capacity : 0,
            rakeClr : "white",
            no_of_rakes:0,
            total_trips:0,
            trips_per_rake:0,
            allrowData:[],
            allData:"activet", 
            hData:"",
            sData:"",
            rowData1 : [],
            newVehiclesPossible : 0,
            vinCountLength : 0,
            rakesvehiclesPossible: 0,
            rakesVinCountLength : 0,
            total_monthly_dispatchesStringValue: 0,
            vehiclesPlanned: 0,
            freshIncomingRakes: {},
            rakesPlannedToday: {},
            rakesCarriedForward: {},
            pinnedRowData : []
            //components : {datepicker:getDatePicker()}
        }
        // this.changeFileHandler = this.changeFileHandler.bind(this);
        // this.handleDayClick = this.handleDayClick.bind(this);


    componentDidMount() {
        loadDateTimeScript();
        var currentMonth = moment.parseZone().format("YYYY-MM");
        //console.log(currentMonth,"currentMonth")
        const monthNames = ["January", "February", "March", "April", "May", "June",
                            "July", "August", "September", "October", "November", "December"
                            ];
        const year_month = `${new Date().getFullYear()}-${monthNames[new Date().getMonth()]}`;
        
        this.setState({
            plan_month: currentMonth,
            gridTitle: year_month
        })
        setTimeout(() =>{
            this.onLoadPageData();
        }, 400)
    }
    onLoadPageData = () => {
        this.setState({
            loadshow : "show-m",
            overly : "show-m",
        })
        var plan_month = this.state.plan_month
        var params = {
            month: plan_month
        }
        // getRailTargetByMonth
        // redirectURL.post("/consignments/getRailClusterData", params).then((response) => {
        //     // console.log("response", response.data);
        //     let respData = []
        //     try{
        //         respData = response.data
        //         const pinnedRowData = [respData.pop()]
        //         console.log("response", respData);
        //         console.log("pinnedRowData", pinnedRowData);
        //         this.setState({
        //             rowData : respData,
        //             pinnedRowData,
        //             loadshow : "show-n",
        //             overly : "show-n",
        //         })
        //     }
        redirectURL.post("/consignments/getRailClusterData", params).then((response) => {
            let respData = []
            try {
                respData = response.data
        
                // Extract "PAN India" cluster
                const panIndiaIndex = respData.findIndex(cluster => cluster.cluster === "PAN India")
                let pinnedRowData = []
                if (panIndiaIndex !== -1) {
                    pinnedRowData = [respData.splice(panIndiaIndex, 1)[0]]
                }
        
                // Extract "Bhubaneshwar" cluster
                const bhubaneshwarIndex = respData.findIndex(cluster => cluster.cluster === "Bhubaneshwar")
                let bhubaneshwarData = []
                if (bhubaneshwarIndex !== -1) {
                    bhubaneshwarData = respData.splice(bhubaneshwarIndex, 1)
                }
        
                // Extract "Lucknow" cluster
                const lucknowIndex = respData.findIndex(cluster => cluster.cluster === "Lucknow")
                let lucknowData = []
                if (lucknowIndex !== -1) {
                    lucknowData = respData.splice(lucknowIndex, 1)
                }
        
                console.log("Bhubaneshwar Index:", bhubaneshwarIndex, "Bhubaneshwar Data:", bhubaneshwarData)
                console.log("Lucknow Index:", lucknowIndex, "Lucknow Data:", lucknowData)
        
                // Find the index of the "Ranchi" cluster again after splicing
                const ranchiIndex = respData.findIndex(cluster => cluster.cluster === "Ranchi")
        
                // Insert "Bhubaneshwar" cluster after "Ranchi"
                if (ranchiIndex !== -1 && bhubaneshwarData.length > 0) {
                    respData.splice(ranchiIndex + 1, 0, ...bhubaneshwarData)
                }
        
                // Find the index of the "TVPB" cluster again after splicing
                const tvpbIndex = respData.findIndex(cluster => cluster.cluster === "TVPN")
        
                console.log("TVPB Index:", tvpbIndex)
        
                // Insert "Lucknow" cluster before "TVPB"
                if (tvpbIndex !== -1 && lucknowData.length > 0) {
                    respData.splice(tvpbIndex, 0, ...lucknowData)
                }
        
                console.log("Response Data:", respData)
                console.log("Pinned Row Data:", pinnedRowData)
        
                this.setState({
                    rowData: respData,
                    pinnedRowData,
                    loadshow: "show-n",
                    overly: "show-n",
                })
            } catch (error) {
                console.error("Error processing response data", error)
            }
        })
            
    }

    handleDayClick = (day, { selected }) => {
        const selectedDays = this.state.selectedDays.concat();
        if (selected) {
            const selectedIndex = selectedDays.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day)
            );
            selectedDays.splice(selectedIndex, 1);
        } else {
            selectedDays.push(day);
        }
        console.log(selectedDays, "selectedDays")
        this.setState({ selectedDays });
    }
    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onFirstDataRendered = (event) => {
        event.api.addCellRange({
          rowStartIndex: 0,
          rowEndIndex: 4,
          columns: ['trip_per_rake'],
        });
      };

    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    // onCellClicked = (e)=> {
    //     if (e.colDef.field == "delete") {
    //         var rowId = e.data._id;
    //         console.log(e.data._id);
    //         var qry = {
    //             rowId: rowId,
    //             city: e.data.city,
    //             comp_code: this.state.comp_code,
    //             user_name: localStorage.getItem('username'),
    //             email: localStorage.getItem('email'),
    //             user_department: localStorage.getItem('lm_department'),
    //             staff_id: localStorage.getItem('staff_id'),
    //             designation: localStorage.getItem('designation'),
    //             user_role: localStorage.getItem('roles'),
    //         }
    //         //var confirm = confirm("Are You Sure! Do you want really delete?");
    //         if (window.confirm("Are You Sure! Do you want really delete?")) {
    //             redirectURL.post("/lmconsignees/deleteLmFreights", qry).then((response) => {
    //                 console.log(response.data);
    //                 if (response.data.message == "Success") {
    //                     this.setState({
    //                         basicTitle: "Deleted Successfully.",
    //                         basicType: "success",
    //                         show: true
    //                     })
    //                     //window.location.reload();
    //                     this.onLoadPageData();
    //                 }
    //                 else {
    //                     this.setState({
    //                         basicTitle: "Failed to Delete.",
    //                         basicType: "warning",
    //                         show: true
    //                     })
    //                 }
    //             }).catch((e) => {
    //                 console.log(e);
    //             })
    //         }
    //     }
    // }

    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            bulkslide: "",

        });

    }
    showBulkUpload() {
        this.setState({
            overly: "show-m",
            bulkslide: "slider-translate"
        })
    }
    resetUpload = () => {
        this.setState({
            bulkslide: '',
            overly: 'show-n',
            file: ''
        });
        document.getElementById("upform").reset();
    }
    // changeFileHandler = async (e) => {
    //     var dt = '';
    //     const config = {
    //         headers: [
    //             // { 
    //             //     name: 'id',
    //             //     inputName: 'id',
    //             //     required: false,
    //             // },
    //             {
    //                 name: 'city',
    //                 inputName: 'city',
    //                 required: true,
    //                 requiredError: function (headerName, rowNumber, columnNumber) {
    //                     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
    //                 }
    //             },
    //             {
    //                 name: 'state',
    //                 inputName: 'state',
    //                 required: true,
    //                 requiredError: function (headerName, rowNumber, columnNumber) {
    //                     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
    //                 }
    //             },
    //             {
    //                 name: 'rate_cmt',
    //                 inputName: 'rate_cmt',
    //                 required: true,
    //                 requiredError: function (headerName, rowNumber, columnNumber) {
    //                     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
    //                 }
    //             },
    //             {
    //                 name: 'vehicle_capacity',
    //                 inputName: 'vehicle_capacity',
    //                 required: true,
    //                 requiredError: function (headerName, rowNumber, columnNumber) {
    //                     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
    //                 }
    //             },
    //         ]
    //     }
    //     var data = CSVFileValidator(e.target.files[0], config)
    //         .then((csvData) => {
    //             console.log(csvData)
    //             this.setState({
    //                 csvData: csvData.data
    //             });
    //             console.log(csvData.data);
    //         })
    //         .catch(err => { })
    //     // console.log("e.target.files[0]",e.target.result);

    //     var out = new Promise(function (reject, resolve) {
    //         var reader = new FileReader();
    //         reader.onload = async function (e) {
    //             var contents = await e.target.result;
    //             resolve(contents);
    //         };
    //         var tt = reader.readAsText(e.target.files[0]);
    //     });
    //     this.setState({
    //         file: e.target.files[0]
    //     });
    //     //this.checkFileData();
    //     //console.log("e.target.files[0].type ", e.target.files[0]);
    //     var check = verifyext(e.target.files[0].name);
    //     //console.log("Check ", check)
    //     if (check == true) {
    //         if (e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
    //             // console.log(fileData);
    //             // console.log(typeof(fileData))

    //         }
    //         else {
    //             e.target.value = null;
    //             this.setState({
    //                 uploadFile: '',
    //                 show: true,
    //                 basicType: 'danger',
    //                 basicTitle: 'Please upload file having extensions .csv only.',
    //             });
    //         }
    //     }
    //     else {
    //         e.target.value = null;
    //         this.setState({
    //             uploadFile: '',
    //             show: true,
    //             basicType: 'danger',
    //             basicTitle: 'Please upload file having extensions .csv only.',
    //         });
    //     }
    // }

    // checkFileData() {

    //     var vdata = verifycsvdata(JSON.stringify(this.state.csvData));
    //     //console.log("verifycsvdata ", vdata)
    //     if (vdata == 0) {
    //         this.setState({
    //             csvData: '',
    //             show: true,
    //             basicType: 'danger',
    //             basicTitle: 'File contains invalid data',
    //             uploadFile: '',
    //             file: ""
    //         });
    //     }
    // }
    uploadBulkFormHandler = async (event) => {
        event.preventDefault();
        var flag = 0;
        var month_target = $("#month_target").val();
        var selectedDays = this.state.selectedDays;
        if (month_target != undefined && month_target != "") {
            if (selectedDays.length > 0) {
                var selDays = [];
                selectedDays.map(function (sd) {
                    var cnDate = moment.parseZone((sd)).utcOffset("+05:30");
                    selDays.push(cnDate)
                });
                this.setState({
                    overly: "show-m",
                    loadshow: "show-m",
                })
                var plan_month = this.state.plan_month;
                var reqParams = {
                    plan_month: plan_month,
                    month_target: month_target,
                    working_days: selDays
                }
                reqParams['user_name'] = localStorage.getItem('username');
                reqParams['email'] = localStorage.getItem('email');
                console.log("reqParams", reqParams)
                redirectURL.post("/consignments/saverailtarget", reqParams).then((response) => {
                    var resp = response.data.message;
                    console.log(resp)
                    this.setState({
                        basicTitle: resp,
                        basicType: "success",
                        show: true,
                        selectedDays: [],
                        bulkslide: '',
                        loadshow: "show-n",
                        overly: 'show-n',
                    })
                    //window.location.reload();
                    this.onLoadPageData();
                }).catch((e) => {
                    console.log(e);
                })
            }
            else {
                this.setState({
                    basicTitle: "Invalid File",
                    basicType: "warning",
                    show: true,
                })
            }
            $('#upform')[0].reset();
        }
        else {
            $("#bulkUploadID").val();
            this.setState({
                basicTitle: "Fields Should not be Empty",
                basicType: "warning",
                show: true,
            })
            $('#upform')[0].reset();
        }

    };

    onRowSelection(event) {
        var rowIds = [];
        var rwCount = event.api.getSelectedNodes();
        rwCount.map((item) => {
            rowIds.push(item.data._id)
        });

        this.setState({
            rowIds: rowIds
        })
    }

    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var selDay = (d.getMonth() + 1);
        if(parseInt(selDay) < 10)
        {
            selDay = "0"+selDay; 
        }
        var plan_month = d.getFullYear() + "-" + selDay;
        this.setState({
            plan_month: plan_month
        });
    }

    filterDataByMonth = async () => {
        await this.setState({
            plan_month: this.state.plan_month
        });
        await this.onLoadPageData();
    }

    changeTargetHandler = async (e) => {
        console.log(e)
    }

    onClickTab = (pageType) => {
        var allrowData = this.state.allrowData;
        if(pageType == "all")
        {
            this.setState({
                rowData : allrowData,
                allData:"activet", 
                hData:"",
                sData:"", 
            });
        }
        else if(pageType == "haryana")
        {
            var rowData = allrowData.filter((e) => e.plant_name !="Gujarat");
            this.setState({
                rowData : rowData,
                allData:"", 
                hData:"activet",
                sData:"", 
            });
        }
        else{
            var rowData = allrowData.filter((e) => e.plant_name == "Gujarat");
            this.setState({
                rowData : rowData,
                allData:"", 
                hData:"",
                sData:"activet", 
            });
        }
    }

    isValidDate(current) {
        // Get the current year and month
        const currentYear = current.year();
        const currentMonth = current.month();
    
        // Define the minimum allowed date (January 2023)
        const minYear = 2023;
        const minMonth = 0; // January is represented as 0 in JavaScript date months
    
        // Allow dates starting from January 2023
        return currentYear > minYear || (currentYear === minYear && currentMonth >= minMonth);
    }
    render() {
        const columnwithDefs = [
            { 
                headerName: "",
                headerClass: ["", "cellTopHeader"],
                field: "",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 100,
                chartDataType: "excluded",
                children:[
                    {
                        headerName: "Cluster",
                        field: "cluster",
                        width: 100,
                        filter: true,
                        resizable: true,
                    },
                ]
            },
            {
                headerName: "MSIL",
                headerClass: ["cellstylegridTPFM", "cellTopHeader"],
                field: "",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 200,
                chartDataType: "excluded",
                
                
                children: [
                    {
                        headerName: "For Cities",
                        headerClass: "cellstylegridTPFM",
                        cellClass:"cellstylegridTPFM",
                        field: "msil_for_cities",
                        width: 80,
                        filter: true,
                        resizable: true,
                    },
                    {
                        headerName: "Total Invoice",
                        headerClass: "cellstylegridTPFM",
                        cellClass:"cellstylegridTPFM",
                        field: "msil_total_invoice",
                        width: 80,
                        filter: true,
                        resizable: true,
                    },
                    {
                        headerName: "Road Count",
                        headerClass: "cellstylegridTPFM",
                        cellClass:"cellstylegridTPFM",
                        field: "msil_road_count",
                        width: 80,
                        filter: true,
                        resizable: true,
                    },
                    {
                        headerName: "Rail Count",
                        headerClass: "cellstylegridTPFM",
                        cellClass:"cellstylegridTPFM",
                        field: "msil_rail_count",
                        width: 80,
                        filter: true,
                        resizable: true,
                    },  
                    {
                        headerName: "Rail Wrt Sales",
                        headerClass: "cellstylegridTPFM",
                        cellClass:"cellstylegridTPFM",
                        field: "msil_rail%_wrt_sales",
                        width: 80,
                        filter: true,
                        resizable: true,
                      
                    },
                    {
                        headerName: "Rail Contribution of City",
                        headerClass: "cellstylegridTPFM",
                        cellClass:"cellstylegridTPFM",
                        field: "msil_rail_contribution_of_city_%",
                        width: 80,
                        filter: true,
                        resizable: true,               
                    },     
                                                                 

                ]
                
            },
            {
                headerName: "SMG",
                headerClass: ["cellstylegridTPSMG", "cellTopHeader"],
                field: "",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 200,
                chartDataType: "excluded",
                children: [
                    {
                        headerName: "Total Invoice",
                        headerClass:"cellstylegridTPSMG",
                        cellClass:"cellstylegridTPSMG",
                        field: "smg_total_invoice",
                        width: 80,
                        filter: true,
                        resizable: true,
                    },
                    {
                        headerName: "Road Count",
                        headerClass:"cellstylegridTPSMG",
                        cellClass:"cellstylegridTPSMG",
                        field: "smg_road_count",
                        width: 80,
                        filter: true,
                        resizable: true,
                    },
                    {
                        headerName: "Rail Count",
                        headerClass:"cellstylegridTPSMG",
                        cellClass:"cellstylegridTPSMG",
                        field: "smg_rail_count",
                        width: 80,
                        filter: true,
                        resizable: true,
                    },
                    {
                        headerName: "Rail Wrt Sales",
                        headerClass:"cellstylegridTPSMG",
                        cellClass:"cellstylegridTPSMG",
                        field: "smg_rail%_wrt_sales",
                        width: 80,
                        filter: true,
                        resizable: true,
                    },
                    {
                        headerName: "Rail Contribution Of City",
                        headerClass:"cellstylegridTPSMG",
                        cellClass:"cellstylegridTPSMG",
                        field: "smg_rail_contribution_of_city_%",
                        width: 80,
                        filter: true,
                        resizable: true,
                    },
                ]
            },
            {
                headerName: "Bangalore",
                headerClass: ["cellstylegridTPBNG", "cellTopHeader"],
                field: "",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 200,
                chartDataType: "excluded",
                children: [

                    {
                        headerName: "Total Invoice",
                        headerClass:"cellstylegridTPBNG",
                        cellClass:"cellstylegridTPBNG",
                        field: "banglore_total_invoice",
                        width: 80,
                        filter: true,
                        resizable: true,
                    },
                    {
                        headerName: "Road Count",
                        headerClass:"cellstylegridTPBNG",
                       cellClass:"cellstylegridTPBNG",
                        field: "banglore_road_count",
                        width: 80,
                        filter: true,
                        resizable: true,
                    },
                    {
                        headerName: "Rail Count",
                        headerClass:"cellstylegridTPBNG",
                        cellClass:"cellstylegridTPBNG",
                        field: "banglore_rail_count",
                        width: 80,
                        filter: true,
                        resizable: true,
                    },    
                    {
                        headerName: "Rail Wrt sales",
                        headerClass:"cellstylegridTPBNG",
                        cellClass:"cellstylegridTPBNG",
                        field: "banglore_rail%_wrt_sales",
                        width: 80,
                        filter: true,
                        resizable: true,
                    },
                    {
                        headerName: "Rail Contribution of City %",
                        headerClass:"cellstylegridTPBNG",
                        cellClass:"cellstylegridTPBNG",
                        field: "banglore_rail_contribution_of_city_%",
                        width: 80,
                        filter: true,
                        resizable: true,
                    },                             
                  
                ]
            },
            {
                headerName: "Total",
                headerClass: ["cellstylegrid", "cellTopHeader"],
                field: "",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 200,
                chartDataType: "excluded",
                children: [
                    {
                        headerName: "Total Invoice",
                        field: "total_total_invoice",
                        width: 80,
                        filter: true,
                        resizable: true,
                    },
                    {
                        headerName: "Road Count",
                        field: "total_road_count",
                        width: 80,
                        filter: true,
                        resizable: true,
                    },
                    {
                        headerName: "Rail Count",
                        field: "total_rail_count",
                        width: 80,
                        filter: true,
                        resizable: true,
                    },  
                    {
                        headerName: "Rail Wrt Sales",
                        field: "total_rail%_wrt_sales",
                        width: 80,
                        filter: true,
                        resizable: true,
                    },
                    {
                        headerName: "Rail Contribution of City",
                        field: "total_rail_contribution_of_city_%",
                        width: 80,
                        filter: true,
                        resizable: true,
                    },
                ]
            },
        ]

        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>

                <div className="card col-xl-12">
                    <div className="card-header">
                        <h5>
                            <i className="icofont icofont-train cus-i"></i> <span>Rail Cluster Summary</span>
                            {/* <button className="btn btn-info" style={{ float: "right", marginRight: "10px" }} onClick={this.showBulkUpload.bind(this)}>Target Setup</button> */}
                        </h5>
                    </div>

                    <div className="row col-xl-12 col-lg-12" style={{ marginTop: "2%" }}>
                        <form method="POST" className="form-theme col-xl-12 col-lg-12 row">
                            <div className="col-xl-2 col-lg-2 form-group">
                                <label>Filter By Month</label>
                                {/* <input type="text" placeholder="Select Month" id="plan_month" autocomplete="off" className="datetimepicker_date form-control"  required /> */}
                                <Datetime
                                        value={this.state.plan_month}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{
                                            placeholder: 'Select Month',
                                            name: 'plan_month',
                                            autoComplete: 'off',
                                            readOnly:true
                                        }}
                                        dateFormat="YYYY-DD"
                                        timeFormat={false}
                                        name="plan_month"
                                        isValidDate={this.isValidDate} // Add this line
                                        onChange={this.handlerStartDateTime.bind(this)}
                                       
                                    />
                                   
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>&nbsp;</label><br />
                                <button type="button" className="btn btn-success" style={{ padding: "5px 10px", marginRight: "2%" }} onClick={this.filterDataByMonth.bind(this)}>Submit</button>

                            </div>
                        </form>
                    </div>

                    <div className="card-body col-xl-12 col-lg-12">   
                            {/* <div className="card-header" style={{ padding: '10px' }}>
                                <h5>{this.state.gridTitle}</h5>
                            </div>                        */}
                        <div id="myGrid" style={{ width: "100%", height: "930px"}} className={"ag-theme-balham"}>
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus={true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                pinnedBottomRowData={this.state.pinnedRowData}
                                gridOptions={{
                                    context: { componentParent: this },
                                    getRowStyle: function (params) {
                                        if (params.node.rowPinned) {
                                          return { 'font-weight': 'bold','font-size':'16px' };
                                        }
                                      },
                                }}
                                // components={this.state.components}
                                enableRangeSelection={true}
                            // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                            />
                        </div>
                    </div>
                </div>


                {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
                <div className={"slide-r " + (this.state.bulkslide)} style={{ overflow: "hidden" }}>
                    <h3 className="subH">Target Setup</h3>
                    <div className="slide-r-body" style={{ position: "relative" }}>

                        <div className="container-fluid">
                            <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>

                                <div className="form-group mt-20p">
                                    <label className="">Target</label>
                                    <input type="text" name="month_target" id="month_target" className="form-control num-cls" required />
                                </div>

                                <div className="form-group mt-20p">
                                    <label className="">Select Working Days</label>
                                    <DayPicker
                                        selectedDays={this.state.selectedDays}
                                        onDayClick={this.handleDayClick}
                                    />
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                    <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)} style={{top:"20%"}}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>

        );
    }
}

function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber1(date) {
    console.log(date.length);
    console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    console.log(yearNumber, monthNumber, dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        //mask:'39-19-9999 29:59',
        format: 'd-m-Y'
    });
    $('.datetimepicker_date').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker: false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    // console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

$(document).on("input", ".num-cls", function () {
    this.value = this.value.replace(/\D/g, '');
});
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';

import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
import Modal from 'react-responsive-modal';
 
import axios from 'axios';
import Autosuggest from 'react-autosuggest';


import ConsignmentsTodayTopCounter from './consignmentsTodayTopCounter.component';
import ConsignmentsTodayCounter from './consignmentsTodayCounter.component';
import ConsignmentsOverdueCounter from './consignmentsOverdueCounter.component';

import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import redirectURL from '../redirectURL';
import ComponentIndex from '../common/appendComponents';
import DateFormater from '../layouts/dateComponent';
import { getDDMMYYYY, getDDMMYYYYHHMMSS } from '../common/utils'; 
var moment = require('moment'); 
 
const ResponsiveReactGridLayout = WidthProvider(Responsive);

class Default extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			activeConsignmentsCount: 0,
			gateInConsignmentsCount: 0,
			inTransitConsignmentsCount: 0,
			atDealerConsignmentsCount: 0,

			totalDeliveriesTodayCount: 0,
			earlyDeliveriesTodayCount: 0,
			delayedDeliveriesTodayCount: 0,
			onTimeDeliveriesTodayCount: 0,

			totalOverdueDeliveriesCount: 0,
			oneDayOverdueDeliveriesCount: 0,
			threeDaysOverdueDeliveriesCount: 0,
			moreThanThreeDaysOverdueDeliveriesCount: 0,
			currentDepartmentCode: null,
			currentDepartmentTitle: null,

			showdata : this.displayData,
			truckInfo:'',
			consignments:'',
			sndshow:'show-n',
			departmentchrtshow:'show-m',
			currenttrucklocation:'',
			truckoperates:0,
			truckgpslifetime:0,
			lspInfo:0,
			deptServes:0,
			transrecordserves:0,
			gpssendtoday:0,
			nopetransporters:0,
			gpstoday:0,
        	alldepts:[],
        	deptvalue:'all',
			sliderTranslate:'',
        	truckscnt:0,
        	trucklocations:0,
        	notrans:0,
        	trucktoday:0,
        	trucknogps:0,
            data: "grouped",
            legendPosition: "top-left",
            interpolate: "linear",
            xLabel: "M",
            yLabel: "GGGGGGGGGG",
            hidePoints: false,  
            hideLines: false,
            yMin: null,
            yMax: null,
            xMax: null,
            xMin: null,
            loadsData:[], 
            transportschart:[],
            transaxis:[],
			transyaxis:[],
			trans_no_gps_axis:[],
			trans_no_gps_yaxis:[],
            deptaxis:[],
            deptyaxis:[],
            lspaxis:[],
            lspyaxis:[],
            lsp_no_gps_axis:[],
            lsp_no_gps_yaxis:[],
            gpsintervalaxis:[],
            gpsintervalyaxis:[],
            modules: AllModules,
  	        open: false,
  	        filterstatess:{},
  	        columnsdatas:{},
  	        rowgroupdcols:[],
  	        valuecolumns:[],
  	        pivotmodeis:false,
  	        pivotcols:[],
  	        columnsDeliveriesToday: [
				{
					headerName: "Consignment Code",
					field: "consignment_code",
					width: 150,
				},
				{
					headerName: "Consigner Code",
					field: "consigner_code",
					width: 150,
				},
				{
					headerName: "Consignee Code",
					field: "consignee_code",
					width: 150,
				},
				{
					headerName: "Original ETA",
					field: "expected_trip_end",
					width: 200,
				},
				{
					headerName: "Revised ETA",
					field: "revised_trip_end",
					width: 200,
				},
				{
					headerName: "Difference In ETA (Days)",
					field: "difference_in_eta",
					width: 200,
				},
				{
					headerName: "Truck No",
					field: "truck_no",
					width: 200,
				},
				{
					headerName: "Transporter Name",
					field: "transporter_name",
					width: 200,
				},
  	        ],
  	        columnsOverdueDeliveries: [
				{
					headerName: "Overdue",
					field: "overdue_days",
					width: 100,
					filter: true,
					pivot: false,
				},
				{
					headerName: "Consignment Code",
					field: "consignment_code",
					width: 150,
				},
				{
					headerName: "Consigner Code",
					field: "consigner_code",
					width: 150,
				},
				{
					headerName: "Consignee Code",
					field: "consignee_code",
					width: 150,
				},
				{
					headerName: "Original ETA",
					field: "expected_trip_end",
					width: 200,
				},
				{
					headerName: "Revised ETA",
					field: "revised_trip_end",
					width: 200,
				},
				{
					headerName: "Difference In ETA (Days)",
					field: "difference_in_eta",
					width: 200,
				},
				{
					headerName: "Truck No",
					field: "truck_no",
					width: 100,
				},
				{
					headerName: "Transporter Name",
					field: "transporter_name",
					width: 200,
				},
			],
			rowClassRulesDeliveriesToday: {
				"bgColorDangerLight": "data.difference_in_eta > 0",
				"bgColorSuccessMedium": "data.difference_in_eta < 0",
			},
			/**
			 * let delayedDeliveriesByOneDay = totalOverdueDeliveries.filter(rec => {
				return rec.expected_trip_end > oneDayBeforeStart && rec.expected_trip_end < currentDateStart;
			});
			let delayedDeliveriesByThreeDays = totalOverdueDeliveries.filter(rec => {
				return rec.expected_trip_end > threeDaysBeforeStart && rec.expected_trip_end < oneDayBeforeStart;
			});
			let delayedDeliveriesByMoreThanThreeDays = totalOverdueDeliveries.filter(rec => {
				return rec.expected_trip_end < threeDaysBeforeStart;
			});
			 */

			rowClassRulesOverdueDeliveriesOLD: {
				"bgColorDangerLight": function(params) {
					let currentDateStart = moment().format("YYYY-MM-DDT00:00:00.000Z");  
					let oneDayBeforeStart = moment().subtract(1, 'days').startOf('day').format("YYYY-MM-DDT00:00:00.000Z");
					return params.data.expected_trip_end > oneDayBeforeStart && params.data.expected_trip_end < currentDateStart
				},
				"bgColorDangerMedium": function(params) {
					let oneDayBeforeStart = moment().subtract(1, 'days').startOf('day').format("YYYY-MM-DDT00:00:00.000Z");
					let threeDaysBeforeStart = moment().subtract(3, 'days').startOf('day').format("YYYY-MM-DDT00:00:00.000Z");
					return params.data.expected_trip_end > threeDaysBeforeStart && params.data.expected_trip_end < oneDayBeforeStart
				},
				"bgColorDangerDark": function(params) {
					let threeDaysBeforeStart = moment().subtract(3, 'days').startOf('day').format("YYYY-MM-DDT00:00:00.000Z");
					return params.data.expected_trip_end < threeDaysBeforeStart
				},
			},
			rowClassRulesOverdueDeliveriesMINUS: {
				"bgColorDangerLight": function(params) {
					return params.data.difference_in_eta === -1;

					// let currentDateStart = moment().startOf('day');
					// let oneDayBeforeStart = moment().subtract(1, 'days').startOf('day').format("YYYY-MM-DDT00:00:00.000Z");
					// let threeDaysBeforeStart = moment().subtract(3, 'days').startOf('day').format("YYYY-MM-DDT00:00:00.000Z");
					// let revisedDateOnly = params.data.revised_trip_end.toString().slice(0, 10);
					// let expectedDateOnly = params.data.expected_trip_end.toString().slice(0, 10);
					// let revisedDateOnlyMoment  = moment(revisedDateOnly);
					// let expectedDateOnlyMoment = moment(expectedDateOnly);
			
					// let timeDifferece = revisedDateOnly.diff(expectedDateOnlyMoment, 'days');  // calculating time difference in days between two dates
			
					// console.log("getOverdueDays, timeDifferece = ", timeDifferece, params.data.truck_no);
					// let overdueDays = null;
					// if (timeDifferece == 1) {
					// 	overdueDays = "1 Day";
					// } else if (timeDifferece > 1 && timeDifferece <= 3) {
					// 	overdueDays = "3 Days";
					// } else if (timeDifferece > 3) {
					// 	overdueDays = "> 3 Days";
					// }
					// console.log("overdueDays = ", overdueDays);
					// return true;
				

					// let currentDateStart = moment().format("YYYY-MM-DDT00:00:00.000Z");  
					// let oneDayBeforeStart = moment().subtract(1, 'days').startOf('day').format("YYYY-MM-DDT00:00:00.000Z");
					// return params.data.expected_trip_end > oneDayBeforeStart && params.data.expected_trip_end < currentDateStart
				},
				"bgColorDangerMedium": function(params) {
					return params.data.difference_in_eta < -1 && params.data.difference_in_eta >= -3
					// let oneDayBeforeStart = moment().subtract(1, 'days').startOf('day').format("YYYY-MM-DDT00:00:00.000Z");
					// let threeDaysBeforeStart = moment().subtract(3, 'days').startOf('day').format("YYYY-MM-DDT00:00:00.000Z");
					// return params.data.expected_trip_end > threeDaysBeforeStart && params.data.expected_trip_end < oneDayBeforeStart
				},
				"bgColorDangerDark": function(params) {
					return params.data.difference_in_eta  <= -3;
					// let threeDaysBeforeStart = moment().subtract(3, 'days').startOf('day').format("YYYY-MM-DDT00:00:00.000Z");
					// return params.data.expected_trip_end < threeDaysBeforeStart
				},
			},
			rowClassRulesOverdueDeliveriesWithDiffETA: {
				"bgColorDangerLight": function(params) {
					return params.data.difference_in_eta === 1;
				},
				"bgColorDangerMedium": function(params) {
					return params.data.difference_in_eta > 1 && params.data.difference_in_eta <= 3
				},
				"bgColorDangerDark": function(params) {
					return params.data.difference_in_eta  > 3;
				},
			},
			rowClassRulesOverdueDeliveries: {
				"bgColorDangerLight": function(params) {
					return params.data.overdue_days === "1 Day";
				},
				"bgColorDangerMedium": function(params) {
					return params.data.overdue_days === "3 Days"
				},
				"bgColorDangerDark": function(params) {
					return params.data.overdue_days === "> 3 Days";
				},
			},
			currentDateStart: null,
			  
  	        defaultColDef: {
  	        sortable: true,
			  filter: true,
			  resizable: true

			},
			frameworkComponents: {
			dateFormater:DateFormater
		    },

			rowData: null,
			rowwithoutData: null,
			rowgpspacketData:null,
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: true,
			suppressAggFuncInHeader: true,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			}    
        };
    }
    componentDidMount(){
		
		// Determine department for which we need to fetch data
		let urlPath = null;
		let departmentCode = null;
		let departmentTitle = null;

		if (this.props.match.path == "/sndconsignmentstoday") {
			urlPath = '/consignments/sndconsignments';
			departmentCode='SNDG';
			departmentTitle=' (Sales and Dispatch) ';
		} else if (this.props.match.path == "/prtconsignmentstoday") {
			urlPath = '/consignments/prtconsignments';
			departmentCode='LOG-PRT';
			departmentTitle=' (Spare Parts) ';
		}

		// Save the information to state for displaying title on the page as per department
		this.setState({
			currentDepartmentCode: departmentCode,
			currentDepartmentTitle: departmentTitle,
		});

		// START Fetching data from consignments
		redirectURL.post(urlPath, layoutdata)
		.then((response) => {
			var records = JSON.parse(JSON.stringify(response)).data;

			// Calculate values for top counters
			let statusLessThan5 = records.filter(rec => rec.status < 5);
			let statusEqualsOne = records.filter(rec => rec.status === 1);
			let statusEqualsTwo = records.filter(rec => rec.status === 2);
			let statusEqualsThreeORFour = records.filter(rec => rec.status === 3 || rec.status === 4);
			// assign counter values for top counters
			let activeConsignmentsCount = (statusLessThan5) ? statusLessThan5.length : 0;
			let gateInConsignmentsCount = (statusEqualsOne) ? statusEqualsOne.length : 0;
			let inTransitConsignmentsCount = (statusEqualsTwo) ? statusEqualsTwo.length : 0;
			let atDealerConsignmentsCount = (statusEqualsThreeORFour) ? statusEqualsThreeORFour.length : 0;

			// Calculate values for Counters under Deliveries Today
			let currentDateStart = moment().format("YYYY-MM-DDT00:00:00.000Z"); 
			let currentDateEnd = moment().format("YYYY-MM-DDT23:59:59.000Z"); 

			let totalDeliveriesToday = records.filter(rec => {
				if (rec.expected_trip_end) {
					return (rec.expected_trip_end >= currentDateStart ||  rec.expected_trip_end <= currentDateEnd) && (rec.status === 2);
				}
			});
			// let earlyDeliveriesToday = totalDeliveriesToday.filter(rec => {
			// 	return rec.revised_trip_end < rec.expected_trip_end;
			// });
			let delayedDeliveriesToday = totalDeliveriesToday.filter(rec => {
				return rec.revised_trip_end > rec.expected_trip_end;
			});
			// Assign counter values under Deliveries Today 
			// Early arriving count is calculated per record later, OnTime Deliveries = Total-(Delayed+Early)
			let totalDeliveriesTodayCount = (totalDeliveriesToday) ? totalDeliveriesToday.length : 0;
			let delayedDeliveriesTodayCount = (delayedDeliveriesToday) ? delayedDeliveriesToday.length : 0;
			
			
			//Calculate counter values for Overdue Deliveries

			// Date of Yesterday, with start of the day
			let oneDayBeforeStart = moment().subtract(1, 'days').startOf('day').format("YYYY-MM-DDT00:00:00.000Z");
			// 3 days back date with start of the day
			let threeDaysBeforeStart = moment().subtract(3, 'days').startOf('day').format("YYYY-MM-DDT00:00:00.000Z");

			// console.log("currentDateStart = ", currentDateStart);
			// console.log("oneDayBeforeStart = ", oneDayBeforeStart);
			// console.log("threeDaysBeforeStart = ", threeDaysBeforeStart);
			let totalOverdueDeliveries = records.filter(rec => {
				if (rec.expected_trip_end && rec.revised_trip_end) {
					let revisedDateOnly = rec.revised_trip_end.toString().slice(0, 10);
					let expectedDateOnly = rec.expected_trip_end.toString().slice(0, 10);
					let revisedDateOnlyMoment  = moment(revisedDateOnly);
					let expectedDateOnlyMoment = moment(expectedDateOnly);

					return (rec.expected_trip_end < currentDateStart) && (rec.status === 2) && (revisedDateOnlyMoment > expectedDateOnlyMoment);
				}
			});
			let delayedDeliveriesByOneDay = totalOverdueDeliveries.filter(rec => {
				return rec.expected_trip_end > oneDayBeforeStart && rec.expected_trip_end < currentDateStart;
			});
			let delayedDeliveriesByThreeDays = totalOverdueDeliveries.filter(rec => {
				return rec.expected_trip_end > threeDaysBeforeStart && rec.expected_trip_end < oneDayBeforeStart;
			});
			let delayedDeliveriesByMoreThanThreeDays = totalOverdueDeliveries.filter(rec => {
				return rec.expected_trip_end < threeDaysBeforeStart;
			});

			// Assign counter values under Delayed Deliveries
			let totalOverdueDeliveriesCount = (totalOverdueDeliveries) ? totalOverdueDeliveries.length : 0;
			let delayedDeliveriesByOneDayCount = (delayedDeliveriesByOneDay) ? delayedDeliveriesByOneDay.length : 0;
			let delayedDeliveriesByThreeDaysCount = (delayedDeliveriesByThreeDays) ? delayedDeliveriesByThreeDays.length : 0;
			let delayedDeliveriesByMoreThanThreeDaysCount = (delayedDeliveriesByMoreThanThreeDays) ? delayedDeliveriesByMoreThanThreeDays.length : 0;
			
			// prepare data for Deliveries Today Grid
			let deliveriesToday = [...totalDeliveriesToday];
			let earlyArrivingCount = 0;
			deliveriesToday.forEach(element => {
				element.difference_in_eta = null;
				element.transporter_name = null;
				if(element.revised_trip_end && element.expected_trip_end) {
					// let revisedDate = moment(element.revised_trip_end);
					// let revisedDateWithFormat = moment(element.revised_trip_end, "MM-DD-YYYY");
					let revisedDateOnly = element.revised_trip_end.toString().slice(0, 10);
					let revisedDateOnlyMoment  = moment(revisedDateOnly);
					//console.log("revisedDateOnly = ", revisedDateOnly);
					//console.log(" revisedDateOnlyMoment =  ",revisedDateOnlyMoment);
					// console.log("element.revised_trip_end = ", element.revised_trip_end);
					// console.log("revisedDate = ", revisedDate);
					// console.log("revisedDateWithFormat = ", revisedDateWithFormat);
					//let expectedDate = moment(element.expected_trip_end, "MM-DD-YYYY");
					let expectedDateOnly = element.expected_trip_end.toString().slice(0, 10);
					let expectedDateOnlyMoment = moment(expectedDateOnly);
					//let timeDifferece = revisedDate.diff(expectedDate, 'days');  // calculating time difference in hours between two dates
					//let timeDifferece = expectedDate.diff(revisedDate, 'days');  // calculating time difference in days between two dates
					let timeDifferece = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'days');  // calculating time difference in days between two dates
					element.difference_in_eta = timeDifferece;
					//console.log("element N Diff = ", element, timeDifferece);
					// console.log("element.revised_trip_end = ", element.revised_trip_end);
					// console.log("element.expected_trip_end = ", element.expected_trip_end);
					// console.log("revisedDate = ", revisedDate);
					// console.log("expectedDate = ", expectedDate);
					// console.log("timeDifferece = ", timeDifferece);
					if (timeDifferece < 0) {
						earlyArrivingCount++;
					}
				}
				if (element.transporter) {
					let transporterName = null;
					element.transporter.forEach(obj => {
						transporterName = obj.transporter_name;
					});
					element.transporter_name = transporterName;
				}
			});

			// Assign value for "Early" and "On Time" arriving counters for Deliveries Today
			let earlyDeliveriesTodayCount = earlyArrivingCount;
			let onTimeDeliveriesTodayCount = totalDeliveriesTodayCount - delayedDeliveriesTodayCount - earlyArrivingCount;

			// prepare data for Overdue Deliveries Grid
			let overdueDeliveries = [...totalOverdueDeliveries];
			overdueDeliveries.forEach(element => {
				element.difference_in_eta = null;
				element.transporter_name = null;
				let timeDifferece = null;
				let overdueDifferece = null;

				// Add difference_in_eta column and its value
				if(element.revised_trip_end && element.expected_trip_end) {
					let revisedDateOnly = element.revised_trip_end.toString().slice(0, 10);
					let expectedDateOnly = element.expected_trip_end.toString().slice(0, 10);
					let revisedDateOnlyMoment  = moment(revisedDateOnly);
					let expectedDateOnlyMoment = moment(expectedDateOnly);
					let currentDateStart = moment().startOf('day');
				    timeDifferece = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'days');  // calculating time difference in days between two dates
				    overdueDifferece = currentDateStart.diff(expectedDateOnlyMoment, 'days');  // calculating time difference in days between two dates
					element.difference_in_eta = timeDifferece;
				}

				// Add transporter_name column and its value
				if (element.transporter) {
					let transporterName = null;
					element.transporter.forEach(obj => {
						transporterName = obj.transporter_name;
					});
					element.transporter_name = transporterName;
				}
				element.overdue_days = null;
				
				// Add difference_in_eta column and its value
				// if(element.expected_trip_end) {
				// 	let revisedDateOnly = element.revised_trip_end.toString().slice(0, 10);
				// 	let expectedDateOnly = element.expected_trip_end.toString().slice(0, 10);
				// 	let revisedDateOnlyMoment  = moment(revisedDateOnly);
				// 	let expectedDateOnlyMoment = moment(expectedDateOnly);
				//     timeDifferece = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'days');  // calculating time difference in days between two dates
				// 	element.difference_in_eta = timeDifferece;
				// }
				if (overdueDifferece === 1) {
					element.overdue_days = "1 Day";
				} else if (overdueDifferece > 1 && overdueDifferece <= 3) {
					element.overdue_days = "3 Days";
				} else if (overdueDifferece > 3) {
					element.overdue_days = "> 3 Days";
				}
				// if (timeDifferece === -1) {
				// 	element.overdue_days = "1 Day";
				// } else if (timeDifferece <= -1 && timeDifferece >= -3) {
				// 	element.overdue_days = "3 Days";
				// } else if (timeDifferece <= -3) {
				// 	element.overdue_days = "> 3 Days";
				// }

				// Add overdue_days column and its value
				//element.overdue_days = this.getOverdueDays(element);
			});

			// Setting state to reflect above calculated values
			this.setState({
				rowData: deliveriesToday,
				rowwithoutData: overdueDeliveries,

				activeConsignmentsCount: activeConsignmentsCount,
				gateInConsignmentsCount: gateInConsignmentsCount,
				inTransitConsignmentsCount: inTransitConsignmentsCount,
				atDealerConsignmentsCount: atDealerConsignmentsCount,

				totalDeliveriesTodayCount: totalDeliveriesTodayCount,
				earlyDeliveriesTodayCount: earlyDeliveriesTodayCount,
				delayedDeliveriesTodayCount: delayedDeliveriesTodayCount,
				onTimeDeliveriesTodayCount: onTimeDeliveriesTodayCount,

				totalOverdueDeliveriesCount: totalOverdueDeliveriesCount,
				oneDayOverdueDeliveriesCount: delayedDeliveriesByOneDayCount,
				threeDaysOverdueDeliveriesCount: delayedDeliveriesByThreeDaysCount,
				moreThanThreeDaysOverdueDeliveriesCount: delayedDeliveriesByMoreThanThreeDaysCount,
			});
		})
		.catch(function (error) {
		  console.log(error);
		});
		// END Fetching data from consignments
		const layoutdata = {
			token:window.atob(localStorage.getItem('_t')),
		}
	}
	getOverdueDays = rowElement => {
		// Calculate value for column overdue_days for each record
		//console.log("PARAMS IN getOverDueDays = ", rowElement);
	
		
		//rowElement.difference_in_eta = timeDifferece;
		//console.log("element N Diff = ", rowElement, timeDifferece);


		//let currentDateStart = moment().format("YYYY-MM-DDT00:00:00.000Z");  
		//let currentDateStart = moment().startOf('day').format("YYYY-MM-DDT00:00:00.000Z");
		let currentDateStart = moment().startOf('day');
		let oneDayBeforeStart = moment().subtract(1, 'days').startOf('day').format("YYYY-MM-DDT00:00:00.000Z");
		let threeDaysBeforeStart = moment().subtract(3, 'days').startOf('day').format("YYYY-MM-DDT00:00:00.000Z");
		let revisedDateOnly = rowElement.revised_trip_end.toString().slice(0, 10);
		let expectedDateOnly = rowElement.expected_trip_end.toString().slice(0, 10);
		let revisedDateOnlyMoment  = moment(revisedDateOnly);
		let expectedDateOnlyMoment = moment(expectedDateOnly);

		let timeDifferece = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'days');  // calculating time difference in days between two dates

		console.log("getOverdueDays, timeDifferece = ", timeDifferece, rowElement.truck_no);
		let overdueDays = null;
		if (timeDifferece  === 1) {
			overdueDays = "1 Day";
		} else if (timeDifferece > 1 && timeDifferece <= 3) {
			overdueDays = "3 Days";
		} else if (timeDifferece > 3) {
			overdueDays = "> 3 Days";
		}
		console.log("overdueDays = ", overdueDays);
		return overdueDays;
	}
	getOverdueDaysOLD = rowElement => {
		// Calculate value for column overdue_days for each record
		//console.log("PARAMS IN getOverDueDays = ", rowElement);
		let currentDateStart = moment().format("YYYY-MM-DDT00:00:00.000Z");  
		let oneDayBeforeStart = moment().subtract(1, 'days').startOf('day').format("YYYY-MM-DDT00:00:00.000Z");
		let threeDaysBeforeStart = moment().subtract(3, 'days').startOf('day').format("YYYY-MM-DDT00:00:00.000Z");
		let overdueDays = null;
		if (rowElement.expected_trip_end > oneDayBeforeStart && rowElement.expected_trip_end < currentDateStart) {
			overdueDays = "1 Day";
		} else if (rowElement.expected_trip_end > threeDaysBeforeStart && rowElement.expected_trip_end < oneDayBeforeStart) {
			overdueDays = "3 Days";
		} else if (rowElement.expected_trip_end < threeDaysBeforeStart) {
			overdueDays = "> 3 Days";
		}
		return overdueDays;
	}
	onGridReady = params => {
			this.gridApi = params.api;
			//console.log("Grid is: ", this.gridOptionsWrapper);
			this.gridColumnApi = params.columnApi;

			
			
		// ChartMenu.prototype.saveChart = function (e) {
		// 	var event = {
		// 		data: this.gridOptionsWrapper.getApi(),
		// 		type: ChartMenu.EVENT_DOWNLOAD_CHART
		// 		};
		// 	//console.log("Testing", event);
		// 	// console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
		// 	this.dispatchEvent(event);
		// 	var chrtdata = event.data.chartService.rangeController.cellRanges;
			
		// };
		
	};
	onGridState = () => {
		//console.log(this.gridApi);
		
		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		* User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();
				
		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);
			
		
		
	}
    onScatterHover(point) {     
        return `<b>Date: </b>${point.x}<br /><b>Value: </b>${point.y}`;
    }
    createSelectItems() {
        let items = [];         
        //console.log(this.state.alldepts);
        items.push(<option key='all' value='all'>All</option>);  
        this.state.alldepts.map((item, key) => {
        	//console.log("Here "+item,key);
        	items.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>);  
		});
        return items;
    } 
    handleChange(e) {
    	//console.log("V",e.target.value);
        this.setState({ deptvalue: e.target.value });
    }
	onGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		//this.gridApi.sizeColumnsToFit();
	}
	/*Truck Sidebar Component End*/
	onCloseUploadDiv = () => {
		this.setState({
			sliderTranslate:"",
		});
	}
	
    render(){
    	let order_cnt=[];

    	rendJsonData(this.state.loadsData);

        return (
            <div >
                {/*Container-fluid starts*/} 
                <div className="container-fluid">
					
					<ConsignmentsTodayTopCounter 
						activeConsignmentsCount={this.state.activeConsignmentsCount} 
						gateInConsignmentsCount={this.state.gateInConsignmentsCount} 
						inTransitConsignmentsCount={this.state.inTransitConsignmentsCount} 
						atDealerConsignmentsCount={this.state.atDealerConsignmentsCount} />
                    <div className="row">
						<div className="col-xl-6 col-lg-12">
	                        <div className="card height-equal equal-height-lg">
	                            <div className="card-header" style={{padding:'10px'}}>
	                                <h5>Deliveries Today {this.state.currentDepartmentTitle}</h5>
									<div style={{"paddingTop":"10px"}}>
										<ConsignmentsTodayCounter 
											totalDeliveriesTodayCount={this.state.totalDeliveriesTodayCount} 
											earlyDeliveriesTodayCount={this.state.earlyDeliveriesTodayCount} 
											delayedDeliveriesTodayCount={this.state.delayedDeliveriesTodayCount} 
											onTimeDeliveriesTodayCount={this.state.onTimeDeliveriesTodayCount} />
									</div>
	                            </div>
	                            <div className="card-body" style={{padding:'10px'}}>
	                                <div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
	    		          		        <AgGridReact
											context={this}
											//onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
	    		          		            modules={this.state.modules}
	    		          		            columnDefs={this.state.columnsDeliveriesToday}
	    		          		            defaultColDef={this.state.defaultColDef}
											rowData={this.state.rowData}
											rowClassRules = {this.state.rowClassRulesDeliveriesToday}
	    		          		            enableCharts={false}
	    		          		            enableRangeSelection={false}
	    		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
	    		          		            frameworkComponents={this.state.frameworkComponents}
											onGridReady={this.onGridReady}
	    		          		            onGridState={this.onGridState}
											statusBar={this.state.statusBar}
											//sideBar={this.state.sideBar}
											pagination={true} />
	    		          		    </div>
	                            </div>
	                            
	                        </div>
	                        
	                    </div>

	                    
	                    

                        <div className="col-xl-6 col-lg-12" id="consignment-overdue">
	                        <div className="card height-equal equal-height-lg">
	                            <div className="card-header" style={{padding:'10px'}}>
	                                <h5>Overdue Deliveries {this.state.currentDepartmentTitle}</h5>
									<div>
										<ConsignmentsOverdueCounter 
											totalOverdueDeliveriesCount={this.state.totalOverdueDeliveriesCount} 
											oneDayOverdueDeliveriesCount={this.state.oneDayOverdueDeliveriesCount} 
											threeDaysOverdueDeliveriesCount={this.state.threeDaysOverdueDeliveriesCount} 
											moreThanThreeDaysOverdueDeliveriesCount={this.state.moreThanThreeDaysOverdueDeliveriesCount} />
									</div>
	                            </div>
	                            <div className="card-body" style={{padding:'10px'}}>
	                                <div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
	    		          		        <AgGridReact
	    		          		            modules={this.state.modules}
											//onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
	    		          		            columnDefs={this.state.columnsOverdueDeliveries}
	    		          		            defaultColDef={this.state.defaultColDef}
											rowData={this.state.rowwithoutData}
											rowClassRules = {this.state.rowClassRulesOverdueDeliveries}
	    		          		            enableCharts={false}
	    		          		            enableRangeSelection={false}
	    		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
	    		          		            frameworkComponents={this.state.frameworkComponents}
											onGridReady={this.onGridReady}
	    		          		            onGridState={this.onGridState}
											statusBar={this.state.statusBar}
											//sideBar={this.state.sideBar}
											pagination={true} />
	    		          		    </div>
	                            </div>
	                        </div>
	                    </div>
                    </div>

					<div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
					 	{this.displayData}
				 	</div>


                </div>
                {/*Container-fluid Ends*/}
            </div>
        );
    }
}
	function rendJsonData(data)
	{
		//console.log(data);
		var order_cnt=[];
		order_cnt=data.map( (seris) => 
			seris.key
		)
		//console.log(order_cnt)
	}
export default Default;
                                
                                
                                
                                
                                
                                